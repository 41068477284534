import {
  useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { UserTwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type TwoFactorStats } from '~/store/userData/repository/userData.repository';
import { userDataTwoFactorSuccess } from '~/store/userData/userData.actionCreators';
import {
  twoFactorQRCodeRequest,
  type TwoFactorViaEmailData, twoFactorViaEmailRequest,
  type TwoFactorViaGoogleData, twoFactorViaGoogleRequest,
} from './twoFactor.repository';

export const useTwoFactorEmailData = () => {
  const { isLoading: isLoadingGoogle, error: errorGoogle, invokeAjax: invokeGoogle } = useAjaxCall(
    () => twoFactorViaGoogleRequest({ status: 0 }),
  );
  const { isLoading: isLoadingEmail, error: errorEmail, invokeAjax: invokeEmail } = useAjaxCall(
    (request: TwoFactorViaEmailData) => twoFactorViaEmailRequest(request)
  );

  const updateTwoFactorEmailData = async (request: TwoFactorViaEmailData, onSuccess: () => void) => {
    try {
      await invokeGoogle();
      await invokeEmail(request);
      onSuccess();
    }
    // eslint-disable-next-line no-empty
    catch (e) {}
  };

  return {
    isLoading: isLoadingGoogle || isLoadingEmail,
    error: errorGoogle ?? errorEmail ?? null,
    updateTwoFactorEmailData,
  };
};

export const useTwoFactorViaGoogleData = () => {
  const { isLoading, error, invokeAjax: getTwoFactorQRCodeRequest, data } = useAjaxCall(
    () => twoFactorQRCodeRequest()
  );
  const [qrError, setQrError] = useState<ApiError | null>(null);

  const { isLoading: isLoadingEmail, error: errorEmail, invokeAjax: invokeEmail } = useAjaxCall(
    () => twoFactorViaEmailRequest({ status: 0 })
  );
  const { isLoading: isLoadingGoogle, error: errorGoogle, invokeAjax: invokeGoogle } = useAjaxCall(
    (request: TwoFactorViaGoogleData) => twoFactorViaGoogleRequest(request),
  );

  const updateTwoFactorGoogleData = async (request: TwoFactorViaGoogleData, onSuccess: () => void) => {
    try {
      await invokeEmail();
      const response = await invokeGoogle(request);
      if (response.message === 'Updated!') {
        onSuccess();
      }
      else {
        setQrError({
          name: 'QR Code error',
          message: response.message,
          responseStatus: 500,
        });
      }
    }
    // eslint-disable-next-line no-empty
    catch (e) {}
  };

  return {
    isLoading: isLoading || isLoadingEmail || isLoadingGoogle,
    error: error ?? qrError ?? errorEmail ?? errorGoogle ?? null,
    qrCode: data?.data?.qrcode ?? null,
    getTwoFactorQRCodeRequest,
    updateTwoFactorGoogleData,
  };
};

export const useNoTwoFactorData = () => {
  const { isLoading: isLoadingGoogle, error: errorGoogle, invokeAjax: invokeGoogle } = useAjaxCall(
    () => twoFactorViaGoogleRequest({ status: 0 }),
  );
  const { isLoading: isLoadingEmail, error: errorEmail, invokeAjax: invokeEmail } = useAjaxCall(
    () => twoFactorViaEmailRequest({ status: 0 })
  );

  const updateNoTwoFactorData = async (onSuccess: () => void) => {
    try {
      await invokeEmail();
      await invokeGoogle();
      onSuccess();
    }
    // eslint-disable-next-line no-empty
    catch (e) {}
  };

  return {
    isLoading: isLoadingGoogle || isLoadingEmail,
    error: errorEmail ?? errorGoogle,
    updateNoTwoFactorData,
  };
};

export const useTwoFactorMethod = () => {
  const dispatch = useDispatch();
  const twoFaType = useSelector<TwoFactorStats>(state => state.userData['2fa']);
  const [selectedTwoFactorMode, setSelectedTwoFactorMode] = useState<UserTwoFactorMode>(UserTwoFactorMode.NoTwoFactor);
  const [isTwoFactorOn, setIsTwoFactorOn] = useState<boolean>(false);

  useEffect(() => {
    if (twoFaType.email) {
      setIsTwoFactorOn(true);
      setSelectedTwoFactorMode(UserTwoFactorMode.TwoFactorViaEmail);
    }
    else if (twoFaType.google) {
      setIsTwoFactorOn(true);
      setSelectedTwoFactorMode(UserTwoFactorMode.TwoFactorViaGoogle);
    }
    else {
      setIsTwoFactorOn(false);
      setSelectedTwoFactorMode(UserTwoFactorMode.NoTwoFactor);
    }
  }, [twoFaType]);

  const dispatchTwoFactorType = (type: UserTwoFactorMode) => {
    const body: TwoFactorStats = {
      email: false,
      google: false,
    };
    if (type === UserTwoFactorMode.TwoFactorViaEmail) {
      body.email = true;
    }
    else if (type === UserTwoFactorMode.TwoFactorViaGoogle) {
      body.google = true;
    }
    dispatch(
      userDataTwoFactorSuccess(body)
    );
  };

  return { isTwoFactorOn,
    setIsTwoFactorOn,
    selectedTwoFactorMode,
    setSelectedTwoFactorMode, dispatchTwoFactorType };
};
