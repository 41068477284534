import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { deleteBoundaryGroupItem } from '~/store/boundaries/boundaries.repository';
import { boundaryItemRemoveSuccess } from '~/store/boundaryItems/boundaryItems.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../../../../_shared/components/modal/confirmation/useConfirmationModal';

export const useRemoveCustomBoundary = () => {
  const clientId = useClientIdSelector();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const removeCustomBoundary = useCallback(async (customBoundaryId: number, onSuccess?: () => void) => {
    if (!clientId) {
      return;
    }

    const deleteResult = await deleteBoundaryGroupItem(clientId, customBoundaryId);
    dispatch(boundaryItemRemoveSuccess(customBoundaryId, deleteResult));
    onSuccess?.();
  }, [clientId, dispatch]);

  const { invokeAjax, isLoading, error } = useAjaxCall(removeCustomBoundary);

  const removeCustomBoundaryRequest = useCallback((customBoundaryId: number, onSuccess?: () => void) => {
    openConfirmationModal({
      title: t('Delete territory'),
      confirmCaption: t('Proceed'),
      text: t('You are about to delete a territory. This action is irreversible. Do you want to proceed?'),
      permanentConfirmSettings: {
        id: 'remove-boundary',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        closeConfirmationModal();
        invokeAjax(customBoundaryId, onSuccess);
      },
    });
  }, [invokeAjax, openConfirmationModal, closeConfirmationModal, t]);

  return {
    removeCustomBoundary: removeCustomBoundaryRequest,
    isLoading,
    isError: !!error,
  };
};
