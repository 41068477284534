import type { MapInfo } from '~/_shared/types/map';
import { select } from '~/_shared/utils/saga/effects';
import { MAP_SETTINGS_READY_SET } from '~/store/mapSettings/ready/mapSettingsReady.actionTypes';
import { areMapSettingsReadySelector } from '~/store/mapSettings/ready/mapSettingsReady.selectors';
import { isGeocodingRunningOrRequiredSelector } from '../geocoding/geocoding.selectors';
import {
  mapInfoDataSelector, mapInfoIsLoadingSelector,
} from '../mapInfo/mapInfo.selectors';
import { spreadsheetDataStateSelector } from './spreadsheetData.selectors';
import { type SpreadsheetDataState } from './spreadsheetData.state';

export const SPREADSHEET_FETCH_DATA_STARTED = 'SPREADSHEET/FETCH_DATA_STARTED';
export const SPREADSHEET_FETCH_DATA_ERROR = 'SPREADSHEET/FETCH_DATA_ERROR';
export const SPREADSHEET_FETCH_DATA_SUCCESS = 'SPREADSHEET/FETCH_DATA_SUCCESS';

export const SPREADSHEET_RESET_STATE_AND_REFETCH_DATA = 'SPREADSHEET/RESET_STATE_AND_REFETCH_DATA';

export const SPREADSHEET_ADD_FILTER_ITEM = 'SPREADSHEET/ADD_FILTER_DATA';

// If using this list, always call conditionSaga in your sagas that verify the spreadsheet data and map settings are both loaded.
export const spreadsheetDataCheckForDeletedRows = {
  triggerActions: [
    SPREADSHEET_FETCH_DATA_SUCCESS,
    MAP_SETTINGS_READY_SET,
  ],
  *conditionSaga() {
    const isMapInfoLoading: boolean = yield select<boolean>(mapInfoIsLoadingSelector);
    const mapInfoData: MapInfo | null = yield select<MapInfo | null>(mapInfoDataSelector);
    const spreadsheetDataState: SpreadsheetDataState = yield select<SpreadsheetDataState>(spreadsheetDataStateSelector);
    const isGeocodingInProgress: boolean = yield select(isGeocodingRunningOrRequiredSelector);
    const mapSettingsReady: boolean = yield select<boolean>(areMapSettingsReadySelector);

    const spreadsheetDataLoaded = !!spreadsheetDataState.data && !spreadsheetDataState.isLoading;
    const mapInfoLoaded = !!mapInfoData && !isMapInfoLoading;

    return mapInfoLoaded && spreadsheetDataLoaded && mapSettingsReady && !isGeocodingInProgress;
  },
};
