import { useMemo } from 'react';
import { useBoundaryTerritoryDetailsSelector } from '../../../../store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';

export const useBoundaryDetailsMarkersCount = (boundaryTerritoryGroupId: number, boundaryIds: number[]) => {
  const boundaryTerritoryDetails = useBoundaryTerritoryDetailsSelector();

  const markersCount = useMemo(() => {
    const details = boundaryTerritoryDetails.get(boundaryTerritoryGroupId);

    if (!details) {
      return 0;
    }

    let markersSum = 0;

    for (const boundaryId of boundaryIds) {
      if (details.counts[boundaryId] !== undefined) {
        markersSum += details.counts[boundaryId] ?? 0;
      }
    }

    return markersSum;
  }, [boundaryTerritoryDetails, boundaryTerritoryGroupId, boundaryIds]);

  return {
    markersCount,
  };
};
