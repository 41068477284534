import { copy } from '../../_shared/utils/collections/collections';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { type BoundaryDragEditAction } from '../frontendState/mapTools/boundary/boundaryPolygonUpdate/boundaryPolygonUpdate.action';
import { BOUNDARY_POLYGON_UPDATE_REQUEST } from '../frontendState/mapTools/boundary/boundaryPolygonUpdate/boundaryPolygonUpdate.actionTypes';
import { type BoundaryItemsAction } from './boundaryItems.action';
import {
  BOUNDARY_ITEMS_FETCH_CANCEL,
  BOUNDARY_ITEMS_FETCH_ERROR,
  BOUNDARY_ITEMS_FETCH_REQUEST,
  BOUNDARY_ITEMS_FETCH_SUCCESS,
  BOUNDARY_ITEMS_FETCHED_DATA,
  BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS,
  BOUNDARY_ITEMS_RESET,
  BOUNDARY_ITEMS_UPDATE_ITEM,
  BOUNDARY_ITEMS_UPDATE_ITEM_NAME_SUCCESS,
} from './boundaryItems.actionTypes';
import {
  type BoundaryItemsState, type BoundaryStateItem, CUSTOM_BOUNDARY_ZOOM_LEVEL,
} from './boundaryItems.state';

export const CONTINUATION_TOKEN_ALL_ITEMS_LOADED = 'CONTINUATION_TOKEN_ALL_ITEMS_LOADED';

const boundaryInitialState: BoundaryItemsState = {
  isError: false,
  isLoading: false,
  boundaries: new Map(),
  continuationToken: new Map(),
};

export const boundaryItemsReducer = (
  state: BoundaryItemsState = boundaryInitialState,
  action: BoundaryItemsAction | BoundaryDragEditAction
): BoundaryItemsState => {
  switch (action.type) {
    case BOUNDARY_ITEMS_FETCH_REQUEST: {
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    }

    case BOUNDARY_POLYGON_UPDATE_REQUEST: {
      const updatePath = (boundaryItem: BoundaryStateItem) => ({ ...boundaryItem, paths: new Map([[CUSTOM_BOUNDARY_ZOOM_LEVEL, action.payload.multiPolygon]]) });

      return updateBoundary(state, action.payload.boundaryGroupId, action.payload.boundaryId, updatePath);
    }

    case BOUNDARY_ITEMS_UPDATE_ITEM: {
      const currentBoundaries = state.boundaries.get(action.payload.boundaryGroupId) ?? new Map();
      const boundaryCopy = new Map(currentBoundaries);
      action.payload.boundaryStateItems.forEach(item => {
        boundaryCopy.set(item.id, item);
      });
      const updatedBoundaries = copy.andReplace(state.boundaries, action.payload.boundaryGroupId, boundaryCopy);

      return {
        ...state,
        isLoading: false,
        isError: false,
        boundaries: updatedBoundaries,
      };
    }

    case BOUNDARY_ITEMS_UPDATE_ITEM_NAME_SUCCESS: {
      const updateName = (boundaryItem: BoundaryStateItem) => {
        return { ...boundaryItem, displayName: action.payload.newName };
      };
      return updateBoundary(state, action.payload.boundaryGroupId, action.payload.itemId, updateName);
    }

    case BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS: {
      return removeBoundaryFromState(state, action.payload.boundaryId);
    }

    case BOUNDARY_ITEMS_FETCHED_DATA: {
      const newBoundaries = action.payload.items.length ? updateBoundaries(state, action) : state.boundaries;
      const newTokenValue = action.payload.done ? CONTINUATION_TOKEN_ALL_ITEMS_LOADED : action.payload.continuationToken;

      // continuation token
      const boundaryGroupContinuationToken: ReadonlyMap<number, string> = state.continuationToken.get(action.payload.boundaryGroupId) ?? new Map();

      let newContinuationToken: ReadonlyMap<number, ReadonlyMap<number, string>> = state.continuationToken;

      if (newTokenValue) {
        const newBoundaryGroupContinuationToken = copy.andReplace<string, number>(
          boundaryGroupContinuationToken,
          action.payload.responseZoomLevel,
          newTokenValue
        );

        newContinuationToken = copy.andReplace(state.continuationToken, action.payload.boundaryGroupId, newBoundaryGroupContinuationToken);
      }

      return {
        ...state,
        boundaries: newBoundaries,
        continuationToken: newContinuationToken,
      };
    }

    case BOUNDARY_ITEMS_FETCH_CANCEL:
    case BOUNDARY_ITEMS_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case BOUNDARY_ITEMS_FETCH_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }

    case BOUNDARY_ITEMS_RESET: {
      return boundaryInitialState;
    }

    default:
      return state;
  }
};

const removeBoundaryFromState = (state: BoundaryItemsState, boundaryId: number): BoundaryItemsState => {
  const newBoundaries = new Map(state.boundaries);

  state.boundaries.forEach((boundaryIds, boundaryGroupId) => {
    if (!boundaryIds.has(boundaryId)) {
      return;
    }

    const newBoundaryIds = copy.andRemove(boundaryIds, [boundaryId]);
    newBoundaries.set(boundaryGroupId, newBoundaryIds);
  });

  return {
    ...state,
    boundaries: newBoundaries,
  };
};

const updateBoundary = (state: BoundaryItemsState, boundaryGroupId: number, boundaryId: number, updateFunction: (boundary: BoundaryStateItem) => BoundaryStateItem): BoundaryItemsState => {
  const boundaryGroup = state.boundaries.get(boundaryGroupId);
  const boundaryItem = boundaryGroup?.get(boundaryId);
  if (!boundaryItem || !boundaryGroup) {
    return state;
  }
  const updatedItem: BoundaryStateItem = updateFunction(boundaryItem);
  const updatedGroup = copy.andReplace(boundaryGroup, boundaryId, updatedItem);
  const updatedBoundaries = copy.andReplace(state.boundaries, boundaryGroupId, updatedGroup);

  return {
    ...state,
    boundaries: updatedBoundaries,
  };
};

const updateBoundaries = (state: BoundaryItemsState, action: PickAction<BoundaryItemsAction, 'BOUNDARY_ITEMS_FETCHED_DATA'>) => {
  const currentBoundaryGroupItems = state.boundaries.get(action.payload.boundaryGroupId);
  const newBoundaryGroupItems: Map<number, BoundaryStateItem> = new Map(currentBoundaryGroupItems ?? []);

  for (const item of action.payload.items) {
    const currentItem = newBoundaryGroupItems.get(item.id);

    if (currentItem) {
      newBoundaryGroupItems.set(item.id, {
        ...currentItem,
        ...item,
        paths: new Map([...currentItem.paths, ...item.paths]),
      });
    }
    else {
      newBoundaryGroupItems.set(item.id, item);
    }
  }

  return copy.andReplace(state.boundaries, action.payload.boundaryGroupId, newBoundaryGroupItems);
};
