import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const iconStyle = css({
  marginRight: 4,
});

const messageStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  marginTop: 8,
});

export const DeleteClientConfirmationBodyComponent: React.FC = _ => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <>
      {t('Are you sure you want to delete your account along with all data associated with it, including your license?')}
      <div css={messageStyle({ theme })}>
        <FontAwesomeIcon
          css={iconStyle}
          icon={faExclamationTriangle}
        />
        {t('SubscriptionManagement.ReactivateOptions.Impossible')}
      </div>
    </>
  );
};
