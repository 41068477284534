import { type MapSettingsStylesChangeData } from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesDark: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#212121',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#212121',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.country'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#9e9e9e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName.all,
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.locality'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#bdbdbd',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#181818',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#616161',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#1b1b1b',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['geometry.fill'],
    stylers: {
      color: {
        value: '#2c2c2c',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#8a8a8a',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#373737',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#3c3c3c',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#4e4e4e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#616161',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#000000',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#3d3d3d',
        isActive: true,
      },
    },
  },
];
