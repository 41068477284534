/*
 * Object-keys and object-entries functions that work for string or number keys and PRESERVE KEY TYPE.
 * They replace Object.keys/Object.entries:
*/

export const stringObjectKeys = <K extends string, V>(obj: Partial<Record<K, V>>) => (
  Object.keys(obj) as K[]
);
export const stringObjectEntries = <K extends string, V>(obj: Partial<Record<K, V>>) => (
  Object.entries(obj) as Array<[key: K, value: V]>
);

export const numberObjectKeys = <K extends number, V>(obj: Partial<Record<K, V>>) => (
  Object.keys(obj).map(Number).filter(i => !isNaN(i)) as K[]
);
export const numberObjectEntries = <K extends number, V>(obj: Partial<Record<K, V>>) => (
  Object.entries(obj).map(([keyString, value]) => [Number(keyString), value] as [key: K, value: V]).filter(i => !isNaN(i[0]))
);
