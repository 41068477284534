import { css } from '@emotion/react';
import {
  faGripLines, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type MutableRefObject,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { getInputCommonColors } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import type { Theme } from '~/_shared/themes/theme.model';
import {
  getDirectionsTileColors, getDirectionsTilePosition,
} from './directionsPanelInput.helpers';

const dragButtonWidth = 45;

const wrapperStyle = (theme: Theme) => {
  const { borderColorActive } = getInputCommonColors(theme);

  return css({
    borderRadius: 4,
    overflow: 'hidden',
    position: 'relative',

    '&:after': {
      pointerEvents: 'none',
      borderRadius: 4,
      content: '" "',
      inset: 0,
      position: 'absolute',
      '--box-shadow-color': 'transparent',
      boxShadow: 'inset 0 0 0 1px var(--box-shadow-color)',
    },
    '&:focus-within::after': {
      '--box-shadow-color': borderColorActive,
    },
  });
};

const dragButtonStyle = (theme: Theme) => css({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: dragButtonWidth,
  background: theme.backgroundColors.secondaryHover,
  color: theme.iconColors.highContrast,
  border: `1px solid ${theme.borderColors.primary}`,
  borderRadius: '4px 0 0 4px',
  padding: 0,
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});

const inputStyle = (isClearButtonShown: boolean) => css({
  paddingLeft: dragButtonWidth + 6,
  paddingRight: isClearButtonShown ? 30 : undefined,

  '&:focus-within': {
    borderWidth: '0 0 0 1px', // leave the left (not visible) border to prevent jumpiness
  },
});

const clearButtonStyle = (theme: Theme) => css({
  position: 'absolute',
  right: 0,
  top: '50%',
  marginTop: -18,
  boxSizing: 'border-box',
  fontSize: '16px',
  lineHeight: 1,
  padding: '10px 12px',
  border: 'none',
  background: 'none',
  color: theme.iconColors.primary,
});

const numberStyle = (props: { index: number; itemsLength: number; routeColor: string }) => (theme: Theme) => {
  const position = getDirectionsTilePosition(props.index, props.itemsLength);
  const buttonColors = getDirectionsTileColors(position, theme, props.routeColor);

  return css({
    minWidth: 16,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: buttonColors.background,
    color: buttonColors.textColor,
  });
};

const iconWrapperStyle = css({
  display: 'block',
  width: '100%',
});

type DirectionsPanelInputProps = Readonly<{
  className?: string;
  dragRef?: MutableRefObject<HTMLButtonElement>;
  index: number;
  itemsLength: number;
  placeholder: string;
  routeColor: string;
  showCleanButton: boolean;
  value: string;

  onChange: (value: string) => void;
  onCleanClick: () => void;
}>;

export const DirectionsPanelInputComponent: FC<DirectionsPanelInputProps> = (props) => {
  return (
    <div
      css={wrapperStyle}
      className={props.className}
    >
      <button
        css={dragButtonStyle}
        ref={props.dragRef}
        tabIndex={-1}
      >
        <span css={numberStyle({ index: props.index, itemsLength: props.itemsLength, routeColor: props.routeColor })}>
          {props.index + 1}
        </span>

        <span css={iconWrapperStyle}>
          <FontAwesomeIcon icon={faGripLines} />
        </span>
      </button>

      <TextInputComponent
        css={inputStyle(props.value.length > 0 || props.showCleanButton)}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        icon={null}
      />

      {props.showCleanButton && (
        <button
          tabIndex={-1}
          onClick={props.onCleanClick}
          css={clearButtonStyle}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}
    </div>
  );
};
