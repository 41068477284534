import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  type FetchableResource, FetchableResourceStatus,
} from '~/_shared/types/fetchableResource/fetchableResource';
import { select } from '~/_shared/utils/saga/effects';
import { placesSearchToPlacesSearchRectangle } from '~/places/places.helpers';
import {
  getPlacesByTextSearch, type GetPlacesByTextSearchResponse,
} from '~/places/places.repository';
import { mapSettingsPlacesAddSearch } from '~/store/mapSettings/places/mapSettingsPlaces.actionCreators';
import { mapSettingsPlacesSearchesSelector } from '~/store/mapSettings/places/mapSettingsPlaces.selectors';
import type {
  PlacesSearch, PlacesSearchId,
} from '~/store/mapSettings/places/mapSettingsPlaces.state';
import { clientIdSelector } from '~/store/selectors/useClientIdSelector';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../../mapSettings/data/mapSettingsData.actionTypes';
import {
  frontendStatePlacesPlacesSearchesRequestNextPage,
  frontendStatePlacesPlacesSearchesSetLoading,
  frontendStatePlacesSetPlacesSearchResults,
} from './places.actionCreators';
import { placesSearchResultsSelector } from './places.selectors';
import type { PlacesSearchResult } from './places.state';

export function* placesSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, fetchPlacesSearchesData);
  yield takeLatest(mapSettingsPlacesAddSearch, fetchPlacesSearchesData);
  yield takeLatest(frontendStatePlacesPlacesSearchesRequestNextPage, fetchPlacesSearchesData);
}

function* fetchPlacesSearchesData() {
  const clientId: number | null = yield select(clientIdSelector);
  const searches: ReadonlyArray<PlacesSearch> = yield select(mapSettingsPlacesSearchesSelector);
  const searchesResults: ReadonlyMap<PlacesSearchId, FetchableResource<PlacesSearchResult>> = yield select(placesSearchResultsSelector);

  const searchesToFetch: PlacesSearch[] = [];
  const searchIdsToGetNextPage = new Set<PlacesSearchId>();

  searches.forEach((search) => {
    const searchResult = searchesResults.get(search.id);
    if (!searchResult) {
      searchesToFetch.push(search);
    }
    else if (searchResult.status === FetchableResourceStatus.NextPageRequested) {
      searchesToFetch.push(search);
      searchIdsToGetNextPage.add(search.id);
    }
  });

  if (!searchesToFetch.length || !clientId) {
    return;
  }

  yield put(frontendStatePlacesPlacesSearchesSetLoading(new Set(searchesToFetch.map((search) => search.id))));

  const requestsList = searchesToFetch.map((search) => ({
    text: search.text,
    location: {
      rectangle: placesSearchToPlacesSearchRectangle(search),
    },
    preference: search.preference,
    more: searchIdsToGetNextPage.has(search.id) ? true : undefined,
  }));

  try {
    const response: GetPlacesByTextSearchResponse = yield call(getPlacesByTextSearch, clientId, { params: requestsList });

    yield put(frontendStatePlacesSetPlacesSearchResults(
      new Map(searchesToFetch.map((searchToFetch, index) => {
        const responseForSearch = response.data?.[index];

        return [
          searchToFetch.id,
          responseForSearch
            ? {
              status: FetchableResourceStatus.Loaded,
              value: {
                id: searchToFetch.id,
                items: responseForSearch.items || [],
                hasMore: !!responseForSearch.hasMore,
                error: responseForSearch.code,
              },
            }
            : { status: FetchableResourceStatus.NotFound },
        ];
      }))
    ));
  }
  catch (e) {
    console.error(e);
  }
}
