import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type PropsWithChildren,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  inlineWarningStyle, warningIconStyle,
} from '~/_shared/baseComponents/warning/warning.styles';

type InlineWarningProps = PropsWithChildren<{ readonly className?: string }>;

export const InlineWarningComponent: FC<InlineWarningProps> = ({ children, className }) => {
  return (
    <span
      className={className}
      css={inlineWarningStyle}
    >
      <FontAwesomeIcon
        css={warningIconStyle}
        icon={faExclamationTriangle}
      />
      {children}
    </span>
  );
};
