import { useCallback } from 'react';
import { createTerritoryBoundaryGroupFromResponse } from '../store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { getBoundaryTerritoryGroups } from '../store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { type BoundaryTerritoryGroup } from '../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useClientIdSelector } from '../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../store/selectors/useMapIdSelector';

export const useFetchBoundaryTerritoryGroup = () => {
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();

  const fetchBoundaryTerritoryGroup = useCallback((boundaryTerritoryGroupId: number): Promise<BoundaryTerritoryGroup | null> => {
    if (clientId === null || mapId === null) {
      throw Error('Missing clientId or mapId');
    }

    return getBoundaryTerritoryGroups(clientId, {
      map_id: mapId,
    })
      .then(response => {
        const boundaryTerritoryGroupResponse = response.boundary_territory_groups.find(
          group => group.boundary_territory_group_id === boundaryTerritoryGroupId
        );

        if (!boundaryTerritoryGroupResponse) {
          return null;
        }

        return createTerritoryBoundaryGroupFromResponse(boundaryTerritoryGroupResponse);
      });
  }, [mapId, clientId]);

  return {
    fetchBoundaryTerritoryGroup,
  };
};
