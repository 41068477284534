import { flatten } from '~/_shared/utils/collections/collections';
import { useGeolocatedSearchItemsSelector } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.selectors';
import { getSearchGeolocatedPointUuid } from '../../search/mapSearch.helpers';
import { useMap } from '../mapContext';
import { RadarMarkerOverlay } from '../mapOverlays/radarMarker/radarMarker.component.overlay';

export const SearchOverlayContainer: React.FC = () => {
  const map = useMap();
  const allGeolocatedSearchItems = useGeolocatedSearchItemsSelector();
  // when these items will be rendered using WebGL in the future, use the zIndexes
  // const zIndexes = useSearchItemsWithLatLngZIndexes(allGeolocatedSearchItems);

  return (
    <>
      {flatten(allGeolocatedSearchItems.map(item => (
        (item.latLng).map(latLng => (
          <RadarMarkerOverlay
            key={getSearchGeolocatedPointUuid(item, latLng)}
            position={latLng}
            map={map}
          />
        ))
      )))}
    </>
  );
};
