import { css } from '@emotion/react';
import {
  faComments, faEllipsisVertical, faGear,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type Ref, useCallback, useMemo, useState,
} from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { type FaIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { blinkLoaderStyle } from '~/_shared/components/loader/blinkLoader.styles';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type UsageCounter } from '~/_shared/types/countersAndLimits.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { formatDate } from '~/_shared/utils/date/date.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { commafy } from '~/_shared/utils/number/number.helpers';
import { Trans } from '~/translations/Trans';
import { HomepageInfoStatisticsCardComponent } from './sections/infoCards/homepageInfoStatisticsCard.component';
import { HomepageInfoThemeCardContainer } from './sections/infoCards/homepageInfoThemeCard.container';

const UNIFIED_HOMEPAGE_MARGIN = 20;
const MQ_BREAKPOINT_SM = 600;
const MQ_BREAKPOINT_MD = 700;
const MQ_BREAKPOINT_LG = 1020;

const contentStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `2px solid ${theme.borderColors.primary}`,
  cursor: 'default',
  maxWidth: 1100,
  minWidth: 500,
  position: 'relative',
  width: '100%',
});

const rowStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: UNIFIED_HOMEPAGE_MARGIN,
  position: 'relative',
  width: '100%',
});

const columnStyle = ({ width }: { width: number }) => css({
  display: 'flex',
  boxSizing: 'border-box',
  padding: 0,
  position: 'relative',
  width: '100%',
  marginBottom: 10,
  justifyContent: 'center',
  alignItems: 'center',

  ...(width >= MQ_BREAKPOINT_SM ? {
    width: '49%',
  } : {}),

  ...(width >= MQ_BREAKPOINT_MD ? {
    marginBottom: 0,
    width: 'auto',
  } : {}),
});

const licenceColumnStyle = ({ width }: { width: number }) => css({
  textAlign: 'center',
  ...(width >= MQ_BREAKPOINT_SM ? {
    textAlign: 'left',
    width: '60%',
  } : {}),
  ...(width >= MQ_BREAKPOINT_MD ? {
    marginBottom: 15,
    width: '100%',
  } : {}),
  ...(width >= MQ_BREAKPOINT_LG ? {
    marginBottom: 0,
    width: 'auto',
  } : {}),
});

const themeSwitchColumnStyle = ({ width }: { width: number }) => css({
  ...(width >= MQ_BREAKPOINT_SM ? {
    width: '40%',
    justifyContent: 'flex-end',
  } : {}),
  ...(width >= MQ_BREAKPOINT_MD ? {
    width: 'auto',
    justifyContent: 'center',
  } : {}),
  ...(width >= MQ_BREAKPOINT_LG ? {
    marginLeft: 'auto',
  } : {}),
});

const liveChatColumnStyle = ({ width }: { width: number }) => css({
  ...(width >= MQ_BREAKPOINT_SM ? {
    marginBottom: 0,
  } : {}),
  ...(width >= MQ_BREAKPOINT_MD ? {
    marginLeft: 20,
    ...(width < MQ_BREAKPOINT_LG ? {
      flexGrow: 1,
      justifyContent: 'flex-end',
    } : {}),
  } : {}),
  ...(width >= MQ_BREAKPOINT_LG ? {
    marginLeft: 30,
  } : {}),
});

const accountSettingsColumnStyle = ({ width }: { width: number }) => css({
  marginBottom: 0,
  ...(width >= MQ_BREAKPOINT_MD ? {
    marginLeft: 20,
  } : {}),
  ...(width >= MQ_BREAKPOINT_LG ? {
    marginLeft: 30,
  } : {}),
});

const statisticsWrapperRowStyle = ({ theme }: ThemeProps) => css({
  padding: '10px 0',
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const statisticsRowStyle = css({
  width: '100%',
  maxWidth: 830,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
});

const statisticsColumnStyle = ({ width }: { width: number }) => css({
  width: '49%',
  ...(width >= MQ_BREAKPOINT_MD ? {
    marginBottom: 0,
    width: 368,
  } : {}),
});

const periodInfoStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: 20,
  fontWeight: 500,
  textTransform: 'uppercase',
  width: '100%',
});

const periodInfoValueStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.success,
});

const statisticsSectionWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%',
});

const accountSettingsStyle = ({ width }: { width: number }) => css({
  boxSizing: 'border-box',
  display: 'flex',
  gap: 0,
  maxWidth: 320,
  width: '100%',
  ...(width >= MQ_BREAKPOINT_SM ? {
    maxWidth: 'none',
  } : {}),
});

const liveChatWrapperStyle = ({ width }: { width: number }) => css({
  display: 'flex',
  maxWidth: 320,
  width: '100%',
  ...(width >= MQ_BREAKPOINT_SM ? {
    maxWidth: 'none',
  } : {}),
  ...(width >= MQ_BREAKPOINT_MD ? {
    width: undefined,
  } : {}),
});

const liveChatButtonStyle = ({ width }: { width: number }) => css({
  width: '100%',
  ...(width >= MQ_BREAKPOINT_MD ? {
    width: 132,
  } : {}),
});

const managementButtonStyle = ({ width }: { width: number }) => css({
  width: '100%',
  minWidth: 0,
  ...(width >= MQ_BREAKPOINT_SM ? {
    minWidth: 0,
  } : {}),
  ...(width >= MQ_BREAKPOINT_MD ? {
    minWidth: 275, // to prevent dropdown size change when selecting the 'Two Factor Authentication' item
  } : {}),
});

const blinkLoaderContainerStyle = css({
  borderRadius: 4,
  height: 32,
  width: '100%',
});

const tooltipWrapperStyle = css({
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const noMarginStyle = css({ margin: 0 });

export type HomepageStatistics = Readonly<{
  locationsMapped: UsageCounter;
  publicMapViews: UsageCounter;
}>;

type SettingsQuickAccessOptions = ReadonlyArray<{
  icon: FaIcon;
  isDisabled?: boolean;
  name: string;
  onClick: () => void;
}>;

type Licence = {
  isLoading?: boolean;
  licenseData?: {
    start: Date | null;
    end: Date | null;
  };
};

type PaymentPortalData = {
  isLoading: boolean;
  url?: string;
  getPaymentPortalUrl: () => void;
};

const locationsMappedStatExplanationTooltipComponents = {
  p: <p css={noMarginStyle} />,
  ul: <ul css={noMarginStyle} />,
  li: <li css={noMarginStyle} />,
};

const locationsMappedStatExplanationTooltip = (
  <div
    css={tooltipWrapperStyle}
  >
    <Trans
      i18nKey="locationsMappedStatisticsExplanation"
      components={locationsMappedStatExplanationTooltipComponents}
    />
  </div>
);

type HomepageInfoProps = Readonly<{
  accountSettingsOptions: SettingsQuickAccessOptions;
  currentLicence: Licence;
  isChatLoading?: boolean;
  paymentPortalData: PaymentPortalData;
  statistics: HomepageStatistics;

  onChatClick: () => void;
}>;

export const HomepageInfoComponent: FC<HomepageInfoProps> = (props) => {
  const [isManagementMenuVisible, setIsManagementMenuVisible] = useState<boolean>(false);

  const [t] = useTranslation();
  const theme = useTheme();
  const { ref, width } = useElementDimensions();

  const widthStyleProps = useMemo(() => ({ width: width || MQ_BREAKPOINT_MD }), [width]);

  let licencePeriod = '-';
  if (props.currentLicence.licenseData && props.currentLicence.licenseData.start !== null && props.currentLicence.licenseData.end !== null) {
    licencePeriod = t('{{valueFrom}} to {{valueTo}}', {
      valueFrom: formatDate(props.currentLicence.licenseData.start),
      valueTo: formatDate(props.currentLicence.licenseData.end),
    });
  }

  const toggleManagementOption = useCallback(() => setIsManagementMenuVisible(state => !state), []);

  const hideToggleManamgementOption = useCallback(() => setIsManagementMenuVisible(false), []);

  return (
    <div
      ref={ref}
      css={contentStyle({ theme })}
      data-testid="homepage-info"
    >
      <div css={statisticsSectionWrapperStyle}>
        <div css={rowStyle}>
          <div css={[columnStyle(widthStyleProps), licenceColumnStyle(widthStyleProps)]}>
            <div css={periodInfoStyle({ theme })}>
              {t('Current License Period')}
              {props.currentLicence.isLoading && (
                <div css={[blinkLoaderContainerStyle, blinkLoaderStyle(6000, 100)]} />
              )}
              {!props.currentLicence.isLoading && licencePeriod && (
                <div css={periodInfoValueStyle({ theme })}>
                  {licencePeriod}
                </div>
              )}
            </div>
          </div>

          <div css={[columnStyle(widthStyleProps), themeSwitchColumnStyle(widthStyleProps)]}>
            <HomepageInfoThemeCardContainer />
          </div>

          <div css={[columnStyle(widthStyleProps), liveChatColumnStyle(widthStyleProps)]}>
            <div css={liveChatWrapperStyle(widthStyleProps)}>
              {props.isChatLoading && <OverlayLoaderComponent loaderSize={30} />}
              <ButtonComponent
                buttonStyle={ButtonStyle.Secondary}
                css={liveChatButtonStyle(widthStyleProps)}
                isDisabled={props.isChatLoading}
                onClick={props.onChatClick}
                prefixIcon={faComments}
                text={t('Live Chat')}
              />
            </div>
          </div>

          <div css={[columnStyle(widthStyleProps), accountSettingsColumnStyle(widthStyleProps)]}>
            <div css={accountSettingsStyle(widthStyleProps)}>
              <ContextMenuComponent
                isVisible={isManagementMenuVisible}
                onHide={hideToggleManamgementOption}
                preferredPlacement="bottom-end"
                renderMenuContent={() => (
                  <>
                    {props.accountSettingsOptions.map((option, optionIndex) => (
                      <ContextMenuItemCoreComponent
                        key={optionIndex}
                      >
                        <ContextMenuDefaultItem
                          icon={option.icon}
                          isDisabled={option.isDisabled}
                          onClick={option.onClick}
                        >
                          {option.name}
                        </ContextMenuDefaultItem>
                      </ContextMenuItemCoreComponent>
                    ))}
                  </>
                )}
              >
                {ref => (
                  <ButtonComponent
                    css={managementButtonStyle(widthStyleProps)}
                    onClick={toggleManagementOption}
                    prefixIcon={faGear}
                    ref={ref as Ref<HTMLButtonElement>}
                    suffixIcon={faEllipsisVertical}
                    text={t('Select Management Option')}
                  />
                )}
              </ContextMenuComponent>
            </div>
          </div>
        </div>

        <div css={[rowStyle, statisticsWrapperRowStyle({ theme })]}>
          <div css={statisticsRowStyle}>
            <div css={statisticsColumnStyle(widthStyleProps)}>
              <HomepageInfoStatisticsCardComponent
                amountLimit={props.statistics.locationsMapped.limit}
                amountUsed={props.statistics.locationsMapped.total}
                title={t('Total Unique Locations Mapped')}
                titleTooltip={locationsMappedStatExplanationTooltip}
                text={t('of {{amountLimit}} available', {
                  amountLimit: props.statistics.locationsMapped.limit !== null ? commafy(props.statistics.locationsMapped.limit) : t('unlimited'),
                })}
              />
            </div>

            <div css={statisticsColumnStyle(widthStyleProps)}>
              <HomepageInfoStatisticsCardComponent
                amountLimit={props.statistics.publicMapViews.limit}
                amountUsed={props.statistics.publicMapViews.total}
                title={t('Total Public Map Views')}
                text={t('of {{amountLimit}} available', {
                  amountLimit: props.statistics.publicMapViews.limit !== null ? commafy(props.statistics.publicMapViews.limit) : t('unlimited'),
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
