import {
  useCallback, useMemo,
} from 'react';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { MapFeature } from '~/_shared/types/toolsAndFeatures/mapFeatures.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useLassoActions } from './useLassoActions';

enum LassoToolAction {
  ExportContainedBoundariesToFile = 'ExportContainedBoundariesToFile',
  ExportLocationsToFile = 'ExportLocationsToFile',
  BatchEditData = 'BatchEditData',
  OptimizeRoute = 'OptimizeRoute',
  DeleteAllLocations = 'DeleteAllLocations',
}

const containsLassoAction = (lassoToolAction: LassoToolAction, lassoActionsList: LassoToolAction[]) => (
  lassoActionsList.includes(lassoToolAction)
);

export const useLassoToolModal = () => {
  const { openModal } = useModal(ModalType.OptionsModal);
  const [t] = useTranslation();
  const { createLassoActions, loading } = useLassoActions();
  const publicMapSettings = usePublicMapSettingsSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const isMapPresentational = useIsMapPresentationalSelector();

  const isExportLocationsAllowed = useMemo(() => (
    hasFeatureOrToolAllowedPresentationalMapExport(
      MapFeature.Lasso, publicMapSettings.allowedExportDataTools
    )
  ), [publicMapSettings]);

  const allowedActions = useMemo(() => ([
    ...(isMapPresentational && !isExportLocationsAllowed ? [] : [LassoToolAction.ExportLocationsToFile]),
    ...(isMapPresentational || isLayeredMapConnected ? [] : [LassoToolAction.BatchEditData]),
    ...(isMapPresentational && !publicMapSettings.routingDirections ? [] : [LassoToolAction.OptimizeRoute]),
    ...(isMapPresentational || isLayeredMapConnected ? [] : [LassoToolAction.DeleteAllLocations]),
    ...(isMapPresentational ? [] : [LassoToolAction.ExportContainedBoundariesToFile]),
  ]), [isExportLocationsAllowed, isLayeredMapConnected, isMapPresentational, publicMapSettings.routingDirections]);

  const openLassoToolModal = useCallback((polygon: Polygon) => {
    const lassoActions = createLassoActions(polygon);
    const modalOptions = [
      ...(!containsLassoAction(LassoToolAction.ExportLocationsToFile, allowedActions) ? [] : [
        {
          name: t('Export Locations to File'),
          onChosen: () => {
            lassoActions.openExportDataModal();
          },
        },
      ]),
      ...(!containsLassoAction(LassoToolAction.BatchEditData, allowedActions) ? [] : [
        {
          name: t('Edit Data as Group (Batch)'),
          onChosen: async () => {
            await lassoActions.openEditLocationsModal();
          },
        },
      ]),
      ...(!containsLassoAction(LassoToolAction.OptimizeRoute, allowedActions) ? [] : [
        {
          name: t('Optimize Route To All Locations'),
          onChosen: async () => {
            await lassoActions.optimizeRoute();
          },
        },
      ]),
      ...(!containsLassoAction(LassoToolAction.ExportContainedBoundariesToFile, allowedActions) ? [] : [{
        name: t('Export Contained Boundaries to File'),
        onChosen: () => {
          lassoActions.openExportContainedBoundaryDataModal();
        },
      }]),
      ...(!containsLassoAction(LassoToolAction.DeleteAllLocations, allowedActions) ? [] : [
        {
          isDestructive: true,
          name: t('Delete all locations'),
          onChosen: async () => {
            await lassoActions.openDeleteConfirmationModal();
          },
        },
      ]),
    ];
    openModal({
      allowedPages: ALL_MAP_RELATED_PAGES,
      title: t('Lasso Tool Options'),
      instructions: t('What would you like to do with the markers in the lasso area you have just drawn'),
      options: modalOptions,
      width: 600,
    });
  }, [createLassoActions, allowedActions, t, openModal]);

  return {
    allowedLassoActions: allowedActions,
    loading,
    openLassoToolModal,
  };
};
