import {
  css, keyframes,
} from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

const MAX_SPEED = 10;

const bounce = keyframes({
  '30%, 50%': {
    transform: 'translateY(0)',
  },
  '40%': {
    transform: 'translateY(-20px)',
  },
});

const wrapperStyle = ({ dimensions, offset, speed }: {
  dimensions: QuadrilateralDimensions;
  offset?: {
    left: number;
    top: number;
  };
  speed: number;
}) => {
  return css({
    height: dimensions.height,
    left: offset ? offset.left - dimensions.width / 2 : 0,
    position: 'relative',
    top: offset ? -offset.top - dimensions.height : 0,
    width: dimensions.width,
    ...(speed === 0 ? {} : { animation: `${bounce} ${MAX_SPEED * ((100 - (speed === 100 ? 99 : speed)) / 100)}s infinite` }),
  });
};

type ActiveMarkerIndicatorProps = {
  className?: string;
  children: ReactNode;
  dimensions: QuadrilateralDimensions;
  offset?: {
    left: number;
    top: number;
  };
  speed: number;
};

export const ActiveMarkerBouncingIndicatorComponent: FC<ActiveMarkerIndicatorProps> = (props) => (
  <div
    css={wrapperStyle({
      dimensions: props.dimensions,
      offset: props.offset,
      speed: props.speed,
    })}
    className={props.className}
  >
    {props.children}
  </div>
);
