export type FileAttachmentId = string;
export type FileId = number;

export enum UserFileType{
  MarkerTexture = 'marker-texture',
  Drawing = 'drawing',
  MarkerAttachment = 'marker-attachment',
}

export enum FileAttachmentTool {
  CustomMarker = 'custom-marker',
  Drawing = 'drawing',
  MarkerGallery = 'marker-gallery',
}

export const FileAttachmentTools: ReadonlyArray<string> = [
  FileAttachmentTool.CustomMarker,
  FileAttachmentTool.Drawing,
  FileAttachmentTool.MarkerGallery,
];

export type FileAttachment = Readonly<{
  id: FileAttachmentId;
  fileId: number;
  tool: FileAttachmentTool;
}>;

export type FileLink = Readonly<{
  url: string;
  resolution: {
    width: number;
    height: number;
  };
}>;

export type UserFile = Readonly<{
  id: FileId;
  type: UserFileType;
  clientId: number;
}>;
