import {
  type MemberRole, MemberRoles,
} from './teamManagementModal/memberRole.type';

const manageRoles: ReadonlyArray<MemberRole> = [MemberRoles.COMPANY_OWNER, MemberRoles.COMPANY_ADMIN];
const manageSubscriptionRoles: ReadonlyArray<MemberRole> = [MemberRoles.COMPANY_OWNER];

export const canMemberManageClient = (role: MemberRole | null) =>
  !!role && manageRoles.includes(role);

export const canMemberManageSubscription = (role: MemberRole | null) =>
  !!role && manageSubscriptionRoles.includes(role);

export const isClientAdmin = (member: {role: MemberRole | null}) => member.role === MemberRoles.COMPANY_ADMIN;
export const isClientOwner = (member: {role: MemberRole | null}) => member.role === MemberRoles.COMPANY_OWNER;
