import {
  type FC, useEffect, useMemo, useState,
} from 'react';
import { CheckboxGroupComponent } from '~/_shared/baseComponents/checkbox/checkboxGroup.component';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '~/_shared/components/exportDataModal/exportDataModal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { notNull } from '~/_shared/utils/typeGuards';
import { ExportDataFileType } from '~/data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';
import { useExportItemizedSpreadsheetData } from '~/data/exportDataModal/useExportItemizedSpreadsheetData';
import { type ModalProps } from '~/modal/modalType.enum';
import { useRoutesUiDataSelector } from '~/store/frontendState/mapTools/directions/directions.selectors';
import { useMapSettingsDirectionsRoutesSelector } from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { prepareAsyncCopyToClipboard } from '../../_shared/utils/clipboard/clipboard.helpers';
import { getRouteUiDataForWaypoint } from '../directions.helper';

export type DirectionsExportContainerProps = ModalProps<{
  showSelectRoutesSection: boolean;
  selectedRouteIds: ReadonlyArray<string>;
}>;

export const DirectionsExportContainer: FC<DirectionsExportContainerProps> = (props) => {
  const [selectedRouteIds, setSelectedRouteIds] = useState<ReadonlyArray<string>>(props.selectedRouteIds);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSelectionSectionExpanded, setIsSelectionSectionExpanded] = useState(true);

  const routes = useMapSettingsDirectionsRoutesSelector();
  const routesUiData = useRoutesUiDataSelector();
  const [t] = useTranslation();
  const { getExportedItemizedSpreadsheetData, handleGetItemizedSpreadsheetDataResponse, isLoading, isError } = useExportItemizedSpreadsheetData();

  const postfixColumns = useMemo(() => ([
    t('Route Name'), t('Stop'), t('Google\'s Predicted Location'), t('Distance to Next Stop'), t('Travel Time to Next Stop'),
  ]), [t]);

  const onSubmit = (results: ExportDataModalResults) => {
    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    const method = results.method;
    let fileType = ExportDataFileType.Csv;
    if (results.method === ExportDataMethod.File) {
      fileType = results.fileType;
    }

    const routeItems = selectedRouteIds.map(routeId => {
      const route = routes.findLast(r => r.id === routeId);
      if (route) {
        return {
          rows: route.waypoints.map((w, wIndex) => {
            const googleLegData = getRouteUiDataForWaypoint(wIndex, routeId, routesUiData, t);
            return {
              postfixColumnsValues: [
                route.name,
                (wIndex + 1).toString(),
                googleLegData?.address || w.address || '',
                googleLegData?.distanceToNext || '',
                googleLegData?.travelTimeToNext || '',
              ],
              rowId: w.markerId,
            };
          }),
        };
      }
      return null;
    }).filter(notNull);

    return getExportedItemizedSpreadsheetData({
      additionalPostfixColumns: postfixColumns.map(col => ({ columnName: col })),
      extension: method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : fileType,
      exportAsText: method === ExportDataMethod.Clipboard || undefined,
      items: routeItems,
    })
      .then(response => {
        handleGetItemizedSpreadsheetDataResponse({
          exportDataMethod: method,
          responseData: response,
          setErrorMessage,
          copyToClipboard: asyncCopyToClipboard,
          showCopiedToClipboardMessage,
        });
      });
  };

  useEffect(() => {
    setErrorMessage(null);
  }, [selectedRouteIds]);

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  return (
    <ExportDataModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={t('Export Directions Locations')}
      onSubmit={onSubmit}
      sectionsBeforeExportMethod={props.showSelectRoutesSection ? [{
        header: t('Choose the directions you want to export'),
        isExpanded: isSelectionSectionExpanded,
        onHeadingClick: () => setIsSelectionSectionExpanded(!isSelectionSectionExpanded),
        child: (
          <CheckboxGroupComponent
            onSelectionChanged={setSelectedRouteIds}
            selectedValues={selectedRouteIds}
            items={routes.map(route => ({
              value: route.id,
              text: route.name,
            }))}
          />
        ),
      }] : undefined}
      isSubmitDisabled={selectedRouteIds.length === 0}
      isLoading={isLoading}
      error={errorMessage}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
    />
  );
};
