export const MapAreaColors = {
  highlightCombinedAreas: {
    fillColor: '#555555',
  },
  highlight: {
    borderColor: '#22FF00',
    fillColor: '#1fe600',
  },
  active: {
    fillColor: '#7474f6',
    borderColor: '#f6f674',
    borderColorAlternative: '#74f6f6',
  },
  isolated: {
    borderColor: '#000',
  },
} as const;
