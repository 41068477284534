import { css } from '@emotion/react';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { MAPTIVE_SUPPORT_EMAIL } from '~/_shared/constants/maptive';
import { useOnMount } from '~/_shared/hooks/useOnMount';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useQuery } from '~/_shared/utils/hooks/useQuery';
import { useReloadUserData } from '~/store/userData/useReloadUserData.hook';
import {
  s, Trans,
} from '~/translations/Trans';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import {
  EmailChangeConfirmErrorCodes, getEmailChangeConfirmRequest,
} from './confirmEmailChange.repository';

const listStyle = css({
  textAlign: 'left',
});

export const ErrorMessage = ({ error }: { error: ApiError }) => {
  const [t] = useTranslation();

  switch (error.errorCode) {
    case EmailChangeConfirmErrorCodes.CONFIRMATION_NOT_FOUND:
      return <>{t('Email address change has already been confirmed.')}</>;
    case EmailChangeConfirmErrorCodes.USER_MISMATCH:
      return (
        <div>
          {t('EmailChangeVerification.intro')}
          <ol css={listStyle}>
            <li>{t('EmailChangeVerification.step1')}</li>
            <li>{t('EmailChangeVerification.step2')}</li>
            <li>{t('EmailChangeVerification.step3')}</li>
          </ol>

          <Trans
            i18nKey="EmailChangeVerification.ending"
            tOptions={{ email: MAPTIVE_SUPPORT_EMAIL }}
          >
            {s('If you need additional support please contact us at ')}
            <SupportLinkComponent type={SupportLinkType.info} />
          </Trans>
        </div>
      );
    case EmailChangeConfirmErrorCodes.EMAIL_NOT_UNIQUE:
      return <>{t('The requested email address is already assigned to another account.')}</>;
    default:
      return <>{t('Something went wrong, please try again.')}</>;
  }
};

export const ConfirmEmailChangePage: FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const query = useQuery();
  const expires = query.get('expires');
  const hash = query.get('hash');
  const id = query.get('id');
  const signature = query.get('signature');

  const { isLoading: emailChangeLoading, error, data, invokeAjax: emailChange } = useAjaxCall(getEmailChangeConfirmRequest);
  const { reloadUserData, isLoading: userDataLoading } = useReloadUserData();

  const isLoading = emailChangeLoading || userDataLoading;

  useOnMount(() => {
    if (expires && hash && id && signature) {
      emailChange({ expires, hash, id, signature });
    }
  });

  return (
    <AuthorizationPageWrapperComponent>
      {isLoading && <OverlayLoaderComponent />}
      <div css={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {data && (
          <AlertComponent
            type="success"
          >
            {t('Your email address has been successfully changed.')}
          </AlertComponent>
        )}
        {error && (
          <AlertComponent
            type="danger"
          >
            <ErrorMessage error={error} />
          </AlertComponent>
        )}

        <ButtonComponent
          onClick={async () => {
            await reloadUserData();
            navigate('/');
          }}
          text={t('Back to App')}
        />
      </div>
    </AuthorizationPageWrapperComponent>
  );
};
