import {
  faDirections, faExternalLink, faEye, faEyeSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { type TranslationFnc } from '../../../../_shared/utils/hooks';
import { type ContextMenuItemProps } from '../../../../sidebar/sidebarApps/rightSidebar/sidebarContextMenu/sidebarContextMenu.types';

enum ToolsContextMenuStructure {
  HideProximity = 'Hide Proximity',
  ShowProximity = 'Show Proximity',
  OptimizeDrivingRoute = 'Optimize Driving Route',
  ExportLocations = 'Export Locations',
  ExportContainedBoundaries = 'Export Contained Boundaries',
}

type ToolsCallbacks = Readonly<{
  hideProximityCallback?: () => void;
  showProximityCallback?: () => void;
  exportLocationsCallback?: () => void;
  exportContainedBoundariesCallback?: () => void;
  optimizeDrivingRouteCallback?: () => void;
}>;

const hideProximityStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.HideProximity),
  icon: faEyeSlash,
  onClick,
});

const showProximityStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.ShowProximity),
  icon: faEye,
  onClick,
});

const exportLocationsStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.ExportLocations),
  icon: faExternalLink,
  onClick,
});

const exportContainedBoundariesStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.ExportContainedBoundaries),
  icon: faExternalLink,
  onClick,
});

const optimizeDrivingRouteStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.OptimizeDrivingRoute),
  icon: faDirections,
  onClick,
});

export const additionalActionsContextMenuStructure = (
  callbacks: ToolsCallbacks, t: TranslationFnc
): ReadonlyArray<ContextMenuItemProps> => {
  const menuItems = [];

  if (callbacks.showProximityCallback) {
    menuItems.push(showProximityStructure(callbacks.showProximityCallback, t));
  }

  if (callbacks.hideProximityCallback) {
    menuItems.push(hideProximityStructure(callbacks.hideProximityCallback, t));
  }

  if (callbacks.exportLocationsCallback) {
    menuItems.push(exportLocationsStructure(callbacks.exportLocationsCallback, t));
  }

  if (callbacks.exportContainedBoundariesCallback) {
    menuItems.push(exportContainedBoundariesStructure(callbacks.exportContainedBoundariesCallback, t));
  }

  if (callbacks.optimizeDrivingRouteCallback) {
    menuItems.push(optimizeDrivingRouteStructure(callbacks.optimizeDrivingRouteCallback, t));
  }

  return menuItems;
};
