import {
  type SpreadsheetHeaderColumn, type SpreadsheetItem, type SpreadsheetRow,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import { notNullsy } from '~/_shared/utils/typeGuards';
import type { SpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';

export const sortRowValuesByColumns = (row: SpreadsheetRow, columns: SpreadsheetHeaderColumn[]) => {
  const columnValueMap = row.values.reduce((map, column) => {
    map[column.columnId] = column;
    return map;
  }, {} as Record<string, SpreadsheetItem>);

  const sortedRow: SpreadsheetRow = {
    ...row,
    values: columns.map(column => columnValueMap[column.id]).filter(notNullsy),
  };

  return sortedRow;
};

export const convertRowDataToRow = (row: SpreadsheetRowData, virtualSpreadsheetId: number): SpreadsheetRow => {
  return {
    ...row,
    values: Object.entries(row.columnsData).map(([columnId, value]) => {
      return {
        value,
        columnId,
      } as SpreadsheetItem;
    }),
    virtualSpreadsheetId,
  };
};
