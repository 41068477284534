import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  type SpreadsheetTableDataUpdateCoordinatesRequest, updateSpreadsheetCoordinates,
} from '~/spreadsheet/spreadsheet.repository';
import { deactivateMoveMarkers } from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import { useMoveMarkersMarkersPositionsSelector } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import {
  anyMarkersMoved, getAllNewMarkerPositions,
} from '~/store/frontendState/moveMarkers/moveMarkers.state';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';

export const useMoveMarkersSave = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { openModal: showLoader, closeModal: hideLoader } = useModal(ModalType.Loader);
  const { openModal: showError } = useModal(ModalType.AppError);
  const newMarkerPositions = useMoveMarkersMarkersPositionsSelector();
  const allMarkerPositions = getAllNewMarkerPositions(newMarkerPositions);
  const markersMoved = anyMarkersMoved(newMarkerPositions);
  const spreadsheetId = usePrimarySpreadsheetId();
  const clientId = useClientIdSelector();

  const updateCoordinates = async (request: SpreadsheetTableDataUpdateCoordinatesRequest) => {
    if (!clientId || !spreadsheetId) {
      return;
    }
    try {
      showLoader();
      await updateSpreadsheetCoordinates(clientId, spreadsheetId, request);
      dispatch(spreadsheetResetStateAndRefetchData());
      dispatch(deactivateMoveMarkers());
    }
    catch {
      showError({ title: t('Failed to move markers'), content: t('Please try again. If this problem persists, please contact the customer support.'), type: AppErrorType.General });
      dispatch(deactivateMoveMarkers());
    }
    finally {
      hideLoader();
    }
  };
  const submit = () => {
    if (!clientId || !spreadsheetId) {
      return;
    }

    let updateRequest: SpreadsheetTableDataUpdateCoordinatesRequest = { params: [] };
    for (const position of allMarkerPositions) {
      updateRequest = { params: [...updateRequest.params, { lat: position.lat, lng: position.lng, row_id: position.rowId }] };
    }
    updateCoordinates(updateRequest);
  };

  const discard = useCallback(() => {
    dispatch(deactivateMoveMarkers());
  }, [dispatch]);

  return {
    discard,
    submit,
    markersMoved,
  };
};
