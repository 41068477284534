import {
  useCallback, useMemo, useState,
} from 'react';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { sortAlphabetically } from '~/_shared/utils/sort/sort.helpers';
import {
  ADDITIONAL_COLUMN_NAMES_KEYWORDS,
  ADDRESS_2_COLUMN_NAMES_KEYWORDS,
  ADDRESS_COLUMN_NAMES_KEYWORDS,
  LATITUDE_COLUMN_NAMES_KEYWORDS,
  LONGITUDE_COLUMN_NAMES_KEYWORDS,
} from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueAddressColumns';
import { type ColumnsWithValuesDataType } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueColumns';
import { getColumnsByKeywords } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueColumns.helpers';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBulkFetchExtra,
  type SpreadsheetDataBulkRequestGetter,
} from '../../../spreadsheet/spreadsheet.repository';
import {
  type DataSource, type DataSourceColumnInfo, DataSourceError,
} from './dataSource';

const CANDIDATE_KEYWORDS = [
  'id', ...ADDRESS_COLUMN_NAMES_KEYWORDS, ...ADDITIONAL_COLUMN_NAMES_KEYWORDS,
  ...ADDRESS_2_COLUMN_NAMES_KEYWORDS, ...LATITUDE_COLUMN_NAMES_KEYWORDS, ...LONGITUDE_COLUMN_NAMES_KEYWORDS,
];

const COLUMN_INFO_NOT_FOUND = 'COLUMN_INFO_NOT_FOUND';

export const useGetSpreadsheetDataAsColumnsWithValues = (): DataSource => {
  const spreadsheetId = usePrimarySpreadsheetId();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const isMountedRef = useIsComponentMountedRef();

  const [headerAndData, setHeaderAndData] = useState<ColumnsWithValuesDataType | null>(null);
  const [header, setHeader] = useState<DataSourceColumnInfo[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<DataSourceError | null>(null);

  const getHeaderAndData = useCallback((additionalColumnIds?: string[]): Promise<ColumnsWithValuesDataType | null> => {
    if (spreadsheetId && spreadsheetData && mapId && clientId) {
      const matchupColumns = matchupData[spreadsheetId]?.data?.columns;
      if (matchupColumns) {
        const headerColumns = Object.entries(matchupColumns)
          .filter((column): column is [string, string] => !!column[0] && !!column[1])
          .map(([columnId, columnName]) => ({
            id: columnId,
            name: columnName,
          }));
        setHeader(headerColumns);

        let requiredColumnIds: string[];
        if (additionalColumnIds) {
          requiredColumnIds = additionalColumnIds;
        }
        else {
          requiredColumnIds = getColumnsByKeywords(
            CANDIDATE_KEYWORDS,
            headerColumns.map(headerCol => headerCol.name)
          ).map(columnIndex => headerColumns[columnIndex].id);
        }

        const columnIdsToFetch = requiredColumnIds.filter(columnId => !headerAndData || !headerAndData[columnId]);

        if (columnIdsToFetch.length) {
          const groupTypeColumnsToFetchInfo = columnIdsToFetch.reduce(
            (result: {[id: string]: SpreadsheetDataBulkFetchExtra}, id) => ({ ...result, [id]: {} }), {}
          );
          const params: SpreadsheetDataBulkRequestGetter[] = [{
            columns_to_fetch: {
              [DataType.GROUP]: groupTypeColumnsToFetchInfo,
            },
            exclude_basic_data: false,
            include_ungeocoded: true,
            map_id: mapId,
            spreadsheet_id: spreadsheetId,
          }];

          setIsLoading(true);
          setError(null);
          return getSpreadsheetBulkData(clientId, { params })
            .then(response => {
              const columnComposition = response?.data?.[0]?.result?.columns_to_fetch?.[DataType.GROUP];
              if (!columnComposition) {
                return Promise.reject({
                  responseStatus: 200,
                  response: COLUMN_INFO_NOT_FOUND,
                });
              }

              const data = Object.keys(columnComposition).reduce((result: ColumnsWithValuesDataType, columnId) => {
                const values = columnComposition[columnId].row_values;
                const uniqueValues = columnComposition[columnId].unique_values;

                const literalValues = Object.keys(values)
                  .sort(sortAlphabetically)
                  .map(key => uniqueValues[Number(values[key])]);

                return { ...result, [columnId]: literalValues };
              }, {});

              const freshData: ColumnsWithValuesDataType = { ...headerAndData, ...data };

              if (isMountedRef.current) {
                setHeaderAndData(freshData);
                setIsLoading(false);
              }

              return freshData;
            })
            .catch((_err: ApiError) => {
              const newErr = DataSourceError.General;
              if (isMountedRef.current) {
                setError(newErr);
              }
              return Promise.reject(newErr);
            });
        }
        else {
          return Promise.resolve(headerAndData);
        }
      }
    }
    return Promise.reject();
  }, [clientId, headerAndData, isMountedRef, mapId, matchupData, spreadsheetData, spreadsheetId]);

  return useMemo(() => ({
    fetch: getHeaderAndData,
    error,
    header,
    data: headerAndData,
    isLoading,
  }), [error, getHeaderAndData, header, headerAndData, isLoading]);
};
