import { type TemplateLayers } from '~/map/map/markers/manager/mapMarkerTemplates';
import {
  type LabelVisualizerArrowProps,
  type LabelVisualizerBodyProps,
  type LabelVisualizerBorderProps,
  type LabelVisualizerOffsetProps,
} from '../components/labelVisualizer/labelVisualizer.types';
import { notNullsy } from '../utils/typeGuards';
import {
  type AxisOffsets, type QuadrilateralDimensions,
} from './coordinateSystem/coordinateSystem';
import { type FileAttachmentId } from './file.types';
import { type SpreadsheetRowId } from './spreadsheetData/spreadsheetRow';

export enum MarkerStyleType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export enum MarkerAnchorPosition {
  BOTTOM_CENTER = 'bottom-center',
  CENTER = 'center',
}

export enum StandardMarkerTypes {
  NORMAL = 1,
  DROP = 2,
  BUCKET = 3,
  CIRCLE = 4,
  TEXT = 5,
  NUMBER = 6,
}

export const MarkerColor = {
  Black: [0, 0, 0, 1],
  White: [255, 255, 255, 1],
  Red: [255, 0, 0, 1],
  Green: [0, 255, 0, 1],
  Blue: [0, 0, 255, 1],
  Grey: [82, 82, 82, 1],
  LightGray: [200, 200, 200, 1],
} as const;

export type MarkerVisualSettingsColor = Readonly<{
  opacity?: number;
  selectedColor: string;
}>;

type CommonMarkerVisualSettings = Partial<MarkerVisualSettingsColor> & {
  labelText?: string;
  size: number;
};

// when saving the data in the redux state we use this format, with attachmentId
export type CustomMarkerVisualSettings = CommonMarkerVisualSettings & Readonly<{
  fileAttachmentId: FileAttachmentId;
  styleType: MarkerStyleType.CUSTOM;
}>;

// this is for use cases where attachmentId is not defined yet
// for example because it's only for preview purposes
export type TemporaryCustomMarkerVisualSettings = CommonMarkerVisualSettings & Readonly<{
  fileId: number;
  styleType: MarkerStyleType.CUSTOM;
  anchor: MarkerAnchorPosition;
}>;

export type StandardMarkerVisualSettings = CommonMarkerVisualSettings & Readonly<{
  styleId: number;
  styleType: MarkerStyleType.STANDARD;
}>;

export type MarkerVisualSettings = CustomMarkerVisualSettings | StandardMarkerVisualSettings;

export const isStandardMarkerVisualSettings = (style: MarkerVisualSettings): style is StandardMarkerVisualSettings =>
  style.styleType === MarkerStyleType.STANDARD;

export const isCustomMarkerVisualSettings = (style: MarkerVisualSettings): style is CustomMarkerVisualSettings =>
  style.styleType === MarkerStyleType.CUSTOM;

export type TemporaryMarkerVisualSettings = (TemporaryCustomMarkerVisualSettings | StandardMarkerVisualSettings) & {
  fileAttachmentId?: FileAttachmentId;
  fileId?: number;
  styleId?: number;
  anchor?: MarkerAnchorPosition;
  styleType: MarkerStyleType;
};

export const isTemporaryCustomMarkerVisualSettings = (style: TemporaryMarkerVisualSettings): style is TemporaryCustomMarkerVisualSettings =>
  style.styleType === MarkerStyleType.CUSTOM && notNullsy(style.fileId) && notNullsy(style.anchor);

export const isTemporaryStandardMarkerVisualSettings = (style: TemporaryMarkerVisualSettings): style is StandardMarkerVisualSettings =>
  style.styleType === MarkerStyleType.STANDARD && notNullsy(style.styleId);

export type CustomMarkerSettings = Readonly<{
  fileId: number;
  dimensions: QuadrilateralDimensions;
  path: string;
  anchor: MarkerAnchorPosition;
}>;

export type MarkerSpritesheetSettings = Readonly<{
  layerOne: string;
  layerTwo: string;
  layerTwoSVG: string;
  layerThree: string;
  dimensions: QuadrilateralDimensions;
  anchorOffsets: AxisOffsets;
}>;

export type StandardMarkerSettings = Readonly<{
  id: number;
  spritesheetSettings: MarkerSpritesheetSettings;
  title: string;
  offsets: {
    bottomOffset: number;
    leftOffset: number;
    rightOffset: number;
    topOffset: number;
  };
  counter: Readonly<{
    top: number;
    left: number;
    color: string;
    width: number;
  }>;
}>;

export const isStandardMarkerStyle = (style: StandardMarkerStyle | CustomMarkerStyle | MarkerEntityStyle | undefined): style is StandardMarkerStyle =>
  !!style && style.type === 'marker' && style.markerType === MarkerStyleType.STANDARD;

export const isCustomMarkerStyle = (style: StandardMarkerStyle | CustomMarkerStyle | MarkerEntityStyle | undefined): style is CustomMarkerStyle =>
  !!style && style.type === 'marker' && style?.markerType === MarkerStyleType.CUSTOM;

export enum MarkerSegment {
  MAIN = 'main',
  SHADOW = 'shadow',
}
export type MarkerSegments = Readonly<{
  [MarkerSegment.MAIN]?: TemplateLayers;
  [MarkerSegment.SHADOW]?: TemplateLayers;
}>;

export type MarkerEntityStyle = MarkerStyle | MarkerLabelStyles;

export type MarkerStyle = (StandardMarkerStyle | CustomMarkerStyle) & MarkerStyleCommon;
export type StackedMarkerStyle = (StandardMarkerStyle & MarkerStyleCommon); // MarkerStyle without custom style type

export type StandardMarkerStyle = Partial<MarkerVisualSettingsColor> & Readonly<{
  type: 'marker';
  marker: StandardMarkerSettings;
  markerType: MarkerStyleType.STANDARD;
}>;

export type CustomMarkerStyle = Partial<MarkerVisualSettingsColor> & Readonly<{
  type: 'marker';
  marker: CustomMarkerSettings;
  markerType: MarkerStyleType.CUSTOM;
}>;

type MarkerStyleCommon = Readonly<{
  size: number;
  segments?: MarkerSegments;
}>;

export type MarkerLabelStyles = Readonly<{
  type: 'label';
  arrowProps: LabelVisualizerArrowProps;
  bodyProps: LabelVisualizerBodyProps;
  borderProps: LabelVisualizerBorderProps;
  offsetProps: LabelVisualizerOffsetProps;
}>;

export const isMarkerStyle = (style: MarkerEntityStyle | undefined): style is MarkerStyle =>
  !!style && style.type === 'marker';

export const isLabelStyle = (style: MarkerEntityStyle | undefined): style is MarkerLabelStyles =>
  !!style && style.type === 'label';

export type MarkersMap<T extends StandardMarkerSettings | CustomMarkerSettings> = { [key: number]: T | undefined };

export type ActiveMarker = Readonly<{
  rowIds: SpreadsheetRowId[];
}>;
