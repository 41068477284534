import { useCallback } from 'react';
import { checkIfModalIsAlreadyConfirmed } from '~/_shared/components/modal/confirmation/confirmationModal.helpers';
import { PermanentConfirmStrategy } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useStorageService } from '~/_shared/utils/storageService';
import { HIDE_BOUNDARY_ZOOM_DISCLAIMER_SESSION_KEY } from '~/map/map/boundary/boundaryView/mapBoundaryPolygons/useBoundaryZoomDisclaimer';
import type { BoundaryZoomLevelDisclaimerProps } from '~/map/map/boundary/boundaryZoomLevelDisclaimer/boundaryZoomLevelDisclaimerModal.component';
import { ModalType } from '~/modal/modalType.enum';
import type { WithoutModalProps } from '~/modal/renderModal';
import { useModal } from '~/modal/useModal.hook';

export const useBoundaryZoomDisclaimerModal = () => {
  const { openModal, closeModal } = useModal(ModalType.BoundaryZoomLevelDisclaimer);
  const storageService = useStorageService();

  const handleOpenModal = useCallback(({ items }: WithoutModalProps<BoundaryZoomLevelDisclaimerProps>) => {
    const isModalConfirmed = checkIfModalIsAlreadyConfirmed({
      id: HIDE_BOUNDARY_ZOOM_DISCLAIMER_SESSION_KEY,
      strategy: PermanentConfirmStrategy.Session,
    }, storageService);

    if (isModalConfirmed || items.length === 0) {
      return;
    }

    openModal({ items });
  }, [openModal, storageService]);

  return {
    openModal: handleOpenModal,
    closeModal,
  };
};
