import { type GroupId } from '~/store/spreadsheetData/spreadsheetData.state';
import { type SpreadsheetColumnId } from '../spreadsheetData/spreadsheetColumn';
import {
  type HeatMapSample,
  type HeatMapStyle,
  type HeatMapType,
} from './heatMap.enum';

type HeatMapVisuals = Readonly<{
  radius: number;
  opacity: number;
  threshold: number;
  isGradient: boolean;
  denseColor: string;
  mediumColor: string;
  lightColor: string;
  singleColor: string;
}>;

type HeatMapTypeVariants = {
  readonly type: HeatMapType.AllMarkersDensity;
  readonly style: HeatMapStyle.MarkerDensity;
  readonly sample: HeatMapSample.AllMarkers;
} | {
  readonly type: HeatMapType.AllMarkersNumericalData;
  readonly style: HeatMapStyle.NumericalData;
  readonly numericalColumnId: SpreadsheetColumnId | null;
  readonly sample: HeatMapSample.AllMarkers;
} | {
  readonly type: HeatMapType.GroupDensity;
  readonly style: HeatMapStyle.MarkerDensity;
  readonly sample: HeatMapSample.SpecificGroup;
  readonly selectedGroupId: GroupId;
  readonly selectedGroupName: string;
  readonly selectedGroupColumn: SpreadsheetColumnId | null;
} | {
  readonly type: HeatMapType.GroupNumericalData;
  readonly style: HeatMapStyle.NumericalData;
  readonly numericalColumnId: SpreadsheetColumnId | null;
  readonly sample: HeatMapSample.SpecificGroup;
  readonly selectedGroupId: GroupId;
  readonly selectedGroupName: string;
  readonly selectedGroupColumn: SpreadsheetColumnId | null;
};

export type HeatMap = HeatMapTypeVariants & HeatMapVisuals & Readonly<{
  id: string;
  dissipate: boolean;
  unlinkFromOtherTools: boolean;
  selectedGroupName?: string;
  selectedGroupId?: GroupId;
  selectedGroupColumn?: SpreadsheetColumnId | null;
  numericalColumnId?: SpreadsheetColumnId | null;
}>;

const isColumnIdIdentical = (column?: SpreadsheetColumnId | null, other?: SpreadsheetColumnId | null) =>
  column?.columnId === other?.columnId && column?.spreadsheetId === other?.spreadsheetId;

export const isHeatMapIdentical = (heatMap: HeatMap, other: HeatMap) =>
  heatMap.type === other.type
  && heatMap.selectedGroupId === other.selectedGroupId
  && isColumnIdIdentical(heatMap.selectedGroupColumn, other.selectedGroupColumn)
  && isColumnIdIdentical(heatMap.numericalColumnId, other.numericalColumnId);
