import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  type GroupingColumn, GroupingType, type GroupMarkerSettings,
} from '~/_shared/types/grouping/grouping';
import type { MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  convertColorToWebGLColor,
  convertRGBColorToWebGLColor, mixColorMemoized,
} from '~/_shared/utils/colors/colors.helpers';
import {
  getNumberColumnBucketId,
  getTextColumnGroupIndex, getUniqueGroupId,
} from '~/_shared/utils/grouping/grouping.helpers';
import {
  getNumberGroupingColumnAllBucketsVisuals, getTextGroupingColumnGroupVisuals,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import {
  isNullOrUndefined, notUndefined,
} from '~/_shared/utils/typeGuards';
import { type MatchupDataState } from '~/store/matchupData/matchupData.state';
import {
  getGroupingColumnsUniqueGroups,
  NON_NUMERICAL_VALUE,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { type SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';
import { translate } from '~/translations/Trans';
import { PRESET_COLORS_PRIMARY } from '../../../constants/colors.constants';
import {
  MARKER_SIZE_MAX, MARKER_SIZE_MIN,
} from '../../markers/markers.constants';
import { type PieChartItem } from './pieCharts.types';

const PIE_CHART_SLICES_LIMIT = 7;
export const PIE_CHART_SLICES_OVER_LIMIT_ID = 'PIE_CHART_SLICES_OVER_LIMIT_ID';

export type CreatePieChartItemsParams = Readonly<{
  markerIds: ReadonlyArray<SpreadsheetRowId>;
  activeGroupColumns: readonly GroupingColumn[];
  spreadsheetData: SpreadsheetDataData;
  perGroupVisualSettings: GroupMarkerSettings;
  matchupData: MatchupDataState;
  hierarchy: number;
}>;

const getGroupColor = (visuals: MarkerSettings | undefined): string | undefined => {
  if (!visuals) {
    return undefined;
  }
  if (visuals.useMarker) {
    return visuals.marker?.selectedColor ?? undefined;
  }

  return visuals.label?.bodyProps.backgroundColor.selectedColor ?? undefined;
};

export const createPieChartItems = (params: CreatePieChartItemsParams): ReadonlyArray<PieChartItem> => {
  const { markerIds, activeGroupColumns, spreadsheetData, perGroupVisualSettings, matchupData, hierarchy } = params;
  const groupingColumn = activeGroupColumns[hierarchy];

  if (!groupingColumn) {
    return [];
  }

  const { type, columnId } = groupingColumn;
  const dataGetter = type === GroupingType.Numeric
    ? ({ rowId, spreadsheetId }: SpreadsheetRowId) => {
      const bucket = getNumberColumnBucketId(spreadsheetData, spreadsheetId, columnId, rowId, groupingColumn);
      return { groupIndex: bucket.id };
    }
    : ({ rowId, spreadsheetId }: SpreadsheetRowId) => {
      const groupIndex = getTextColumnGroupIndex(spreadsheetData, spreadsheetId, columnId, rowId);
      return { groupIndex };
    };

  type PieChartItemData = Readonly<{
    count: number; // number of markers in that bucket / for that group
    groupId: number | string; // index of bucket if numerical column, unique string id if text column
    groupIndex: number; // index of bucket if numerical column, uniqueGroups array index if text column
    size?: number; // marker size for group represented by this chart item
    rank?: number; // rank of the group in case grouping is numerical
  }>;

  const itemsMap: ReadonlyMap<number, PieChartItemData> = markerIds
    .reduce((result, markerId) => {
      const data = dataGetter(markerId);
      const existing = result.get(data.groupIndex);

      if (existing) {
        result.set(data.groupIndex, { ...existing, count: existing.count + 1 });
        return result;
      }

      const groupId = type === GroupingType.Numeric
        ? data.groupIndex
        : getUniqueGroupId(data.groupIndex, groupingColumn, spreadsheetData);

      if (isNullOrUndefined(groupId)) {
        return result;
      }

      result.set(data.groupIndex, {
        count: 1,
        groupId,
        groupIndex: data.groupIndex,
      });
      return result;
    }, new Map<number, PieChartItemData>());

  const groupNames = getGroupingColumnsUniqueGroups(spreadsheetData, matchupData, groupingColumn);

  const pieChartItems = Array.from(itemsMap.values())
    .map(({ count, groupId, groupIndex }) => {
      let visuals: MarkerSettings | undefined = undefined;
      let adjustColorByMarkerSize = false;

      if (groupingColumn.type === GroupingType.Text) {
        visuals = getTextGroupingColumnGroupVisuals(groupIndex, activeGroupColumns, hierarchy, perGroupVisualSettings, spreadsheetData);
      }

      if (groupingColumn.type === GroupingType.Numeric) {
        const bucketVisuals = getNumberGroupingColumnAllBucketsVisuals(activeGroupColumns, hierarchy, perGroupVisualSettings);
        if (bucketVisuals) {
          visuals = bucketVisuals[groupIndex];
          const allBucketsColors = Object.values(bucketVisuals).map(v => v?.marker?.selectedColor).filter(notUndefined);
          adjustColorByMarkerSize = groupIndex !== NON_NUMERICAL_VALUE && new Set(allBucketsColors).size !== allBucketsColors.length;
        }
      }

      const selectedColor = getGroupColor(visuals);
      let adjustedColor = selectedColor;
      if (adjustColorByMarkerSize && selectedColor) {
        const size = visuals?.marker?.size ?? 0;
        if (size > 0) {
          const colorShiftUnit = 225 / (MARKER_SIZE_MAX - MARKER_SIZE_MIN);
          const mixColorBase = Math.round(255 - size * colorShiftUnit).toString(16);
          adjustedColor = mixColorMemoized(selectedColor, Array(3).fill(mixColorBase).join('')).toHex();
        }
      }

      const fallbackColor = groupIndex === NON_NUMERICAL_VALUE
        ? '#000000'
        : PRESET_COLORS_PRIMARY[groupIndex % PRESET_COLORS_PRIMARY.length] as string;

      const finalColor = createColor(adjustedColor ?? fallbackColor).rgb;

      return ({
        id: groupId,
        count,
        title: groupNames.find(g => g.id === groupId)?.name ?? translate('Unknown'),
        value: (count / markerIds.length) * 100,
        color: convertRGBColorToWebGLColor(finalColor),
        rgbColor: finalColor,
        originalColor: convertColorToWebGLColor(selectedColor ?? fallbackColor),
        opacity: visuals?.marker?.opacity ?? 1,
        size: visuals?.marker?.size,
        rank: groupingColumn.type === GroupingType.Numeric ? groupIndex : undefined,
      });
    });

  const pieChartItemsSorted = pieChartItems.sort((itemA, itemB) => itemB.value - itemA.value);

  if (pieChartItemsSorted.length <= PIE_CHART_SLICES_LIMIT) {
    return pieChartItemsSorted;
  }

  const mostPrevalentItems = pieChartItemsSorted.slice(0, PIE_CHART_SLICES_LIMIT);
  const otherItems = pieChartItemsSorted.slice(PIE_CHART_SLICES_LIMIT);

  const otherItemsValuesAndCounts = otherItems.reduce((result, item) => ({
    count: result.count + item.count,
    value: result.value + item.value,
  }), { count: 0, value: 0 });

  const rgbColor = otherItems[0]?.color
    ? otherItems[0].rgbColor
    : { r: 0, g: 0, b: 0, a: 0.5 };

  const color = convertRGBColorToWebGLColor(rgbColor);

  return [
    ...mostPrevalentItems,
    {
      id: PIE_CHART_SLICES_OVER_LIMIT_ID,
      color,
      rgbColor,
      originalColor: color,
      opacity: 1,
      count: otherItemsValuesAndCounts.count,
      title: translate('other'),
      value: otherItemsValuesAndCounts.value,
    },
  ];
};
