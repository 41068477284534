import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkTypes } from '~/_shared/baseComponents/links/anchorLink/link.types';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import {
  AVAILABILITY_FROM_HOUR, AVAILABILITY_TO_HOUR, MAPTIVE_BUG_REPORT_EMAIL, MAPTIVE_PHONE_NUMBER,
  MAPTIVE_SALES_SUPPORT_EMAIL, MAPTIVE_SUPPORT_EMAIL,
} from '~/_shared/constants/maptive';
import { useTranslation } from '~/_shared/utils/hooks';
import { ContactUsValueComponent } from '~/help/contact/contactUsValue.component';
import type { ModalProps } from '~/modal/modalType.enum';

const availabilityProps = { fromHour: AVAILABILITY_FROM_HOUR, toHour: AVAILABILITY_TO_HOUR };

const modalStyle = css({
  width: 400,
});

const centerStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const wrapperStyle = css(centerStyle, {
  gap: 40,
  padding: 24,
});

const contactTypeStyle = css(centerStyle, {
  gap: 8,
});

const callTypeStyle = css(centerStyle, {
  gap: 4,
});

const titleStyle = css({
  fontSize: 26,
  fontWeight: 'bold',
});

const subTitleStyle = css({
  fontSize: 16,
});

const valuesStyle = css(centerStyle, {
  gap: 12,
});

const availabilityStyle = css(centerStyle, {
  fontSize: 12,
});

type ContactUsModalProps = ModalProps;

export const ContactUsModalComponent: FC<ContactUsModalProps> = props => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      caption={t('contactUs.title')}
      containerStyle={modalStyle}
      contentStyle={wrapperStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <div css={callTypeStyle}>
        <span css={titleStyle}>{t('contactUs.call.title')}</span>
        <ContactUsValueComponent
          type={LinkTypes.tel}
          value={MAPTIVE_PHONE_NUMBER}
        />
        <div css={availabilityStyle}>
          <span>{t('mondayToFriday')}</span>
          <span>{t('contactUs.availability.hours', availabilityProps)}</span>
        </div>
      </div>

      {/*TODO: adjust the chat links and behaviour once ready*/}
      {/*<div css={contactTypeStyle}>*/}
      {/*  <span css={titleStyle}>{t('contactUs.chat.title')}</span>*/}
      {/*  <div css={valuesStyle}>*/}
      {/*    <ContactUsValueComponent type={LinkTypes.href}>*/}
      {/*      {t('contactUs.topic.salesQuestions')}*/}
      {/*    </ContactUsValueComponent>*/}
      {/*    <ContactUsValueComponent type={LinkTypes.href}>*/}
      {/*      {t('contactUs.topic.generalSupport')}*/}
      {/*    </ContactUsValueComponent>*/}
      {/*    <ContactUsValueComponent type={LinkTypes.href}>*/}
      {/*      {t('contactUs.topic.bugReport')}*/}
      {/*    </ContactUsValueComponent>*/}
      {/*  </div>*/}
      {/*  <div css={availabilityStyle}>*/}
      {/*    <span>{t('contactUs.availability.days')}</span>*/}
      {/*    <span>{t('contactUs.availability.hours', availabilityProps)}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div css={contactTypeStyle}>
        <span css={titleStyle}>{t('contactUs.email.title')}</span>
        <div css={valuesStyle}>
          <div css={centerStyle}>
            <span css={subTitleStyle}>{t('contactUs.topic.salesQuestions')}</span>
            <ContactUsValueComponent
              type={LinkTypes.href}
              value={MAPTIVE_SALES_SUPPORT_EMAIL}
            />
          </div>
          <div css={centerStyle}>
            <span css={subTitleStyle}>{t('contactUs.topic.generalSupport')}</span>
            <ContactUsValueComponent
              type={LinkTypes.href}
              value={MAPTIVE_SUPPORT_EMAIL}
            />
          </div>
          <div css={centerStyle}>
            <span css={subTitleStyle}>{t('contactUs.topic.bugReport')}</span>
            <ContactUsValueComponent
              type={LinkTypes.href}
              value={MAPTIVE_BUG_REPORT_EMAIL}
            />
          </div>
          <span css={availabilityStyle}>{t('contactUs.availability.email')}</span>
        </div>
      </div>

    </ModalComponent>
  );
};
