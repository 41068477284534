import { css } from '@emotion/react';
import { type Theme } from '../../../themes/theme.model';
import { type InputSize } from './textInput.component';
import {
  getTextInputHeight, getTextInputIconFontSize,
} from './textInput.helpers';

export const getInputCommonColors = (theme: Theme) => ({
  backgroundColor: theme.backgroundColors.secondary,
  backgroundColorActive: theme.backgroundColors.highlight,
  borderColor: theme.borderColors.primary,
  borderColorActive: theme.borderColors.activeItem,
  color: theme.textColors.primary,
});

export const inputCommonStyle = (theme: Theme, isFocusable: boolean) => {
  const { backgroundColorActive, borderColorActive, borderColor, backgroundColor, color } = getInputCommonColors(theme);
  return css({
    backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: 4,
    color,
    overflow: 'hidden',

    '&:focus-within': isFocusable ? {
      background: backgroundColorActive,
      borderColor: borderColorActive,
    } : {},
  });
};

export const wrapperStyle = ({ size, isDisabled, isReadonly }: Readonly<{
  size: InputSize;
  isDisabled?: boolean;
  isReadonly?: boolean;
}>) => (theme: Theme) => css(inputCommonStyle(theme, !(isReadonly || isDisabled)), {
  alignItems: 'center',
  boxSizing: 'border-box',
  cursor: 'text',
  display: 'flex',
  fontFamily: 'inherit',
  fontSize: 16,
  fontWeight: 500,
  height: getTextInputHeight(size),
  opacity: isDisabled ? 0.5 : 1,
  padding: '5px 8px',
  width: '100%',

  ...isReadonly ? {
    color: theme.textColors.secondary,
    cursor: 'default',
  } : {},
});

export const inputStyles = css({
  color: 'inherit',
  outline: 'none',
  border: 'none',
  minWidth: 0,
  width: '100%',
  flexGrow: 100,
  backgroundColor: 'transparent',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  '::placeholder': {
    opacity: .4,
    color: 'inherit',
  },
  padding: 0,
});

export const getIconStyles = (size: InputSize) => (theme: Theme) => css({
  color: theme.iconColors.secondary,
  display: 'inline-flex',
  fontSize: getTextInputIconFontSize(size),
  padding: '0 6px 0 4px',
});

export const showHideIconContainerStyle = ({ size }: { size: InputSize }) => css({
  fontSize: getTextInputIconFontSize(size),
  minWidth: 36,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

export const additionalContentStyle = css({
  height: '100%',
  display: 'flex',
});
