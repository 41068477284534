import { getLatLngFromAddress3rdPartyApi } from '../../_shared/utils/geolocation/geolocation.helpers';
import { type Waypoint } from '../../store/frontendState/mapTools/directions/directions.state';
import { BpTspSolver } from './BpTspSolver';

let solverInstance: BpTspSolver | null = null;
const solver = () => {
  if (!solverInstance) {
    solverInstance = new BpTspSolver();
  }
  return solverInstance;
};

export const orderWaypointsByApproximation = async (waypoints: ReadonlyArray<Waypoint>): Promise<ReadonlyArray<Waypoint>> => {
  solver().startOver();
  const latLngs = await Promise.all(
    waypoints.map(i => Promise.resolve(i.latLng ?? getLatLngFromAddress3rdPartyApi(i.address).then(r => r[0].latLng)))
  );

  latLngs.forEach((w, i) => solver().addWaypointWithLabel(new google.maps.LatLng(w.lat, w.lng), i));

  return new Promise(resolve =>
    solver().solveAtoZOnlyDurations(tsp => resolve(tsp.getOrder().map(i => waypoints[i])))
  );
};
