import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyExampleFeatureMapOptions = (
  state: MapComponentOptionsState,
  action: Action,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {

    case 'SOME_FEATURE_ACTIVATED':
      return push(state, {
        changeId: 'SOME_FEATURE_ACTIVE',
        priority,
        change: {
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case 'SOME_FEATURE_STOPPED':
      return pop(state, 'SOME_FEATURE_ACTIVE');

    default:
      return undefined; // return undefined if not matched
  }
};
