import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '~/_shared/utils/hooks';

export enum BoundaryTerritoryEditSubmitAction {
  CreateNew,
  AddToExisting,
  RemoveFromExisting
}

export const getBoundaryTerritoryEditSubmitActionOptions = (
  t: TranslationFnc, includeAddToExistingGroup: boolean, includeRemoveBoundariesFromGroup: boolean,
): ReadonlyArray<DropdownOption<BoundaryTerritoryEditSubmitAction>> => ([
  ...includeAddToExistingGroup ? [{
    name: t('Add boundaries to existing fill group'),
    value: BoundaryTerritoryEditSubmitAction.AddToExisting,
  }] : [],
  ...includeRemoveBoundariesFromGroup ? [{
    name: t('Remove boundaries from fill group'),
    value: BoundaryTerritoryEditSubmitAction.RemoveFromExisting,
  }] : [], {
    name: t('Create new fill group'),
    value: BoundaryTerritoryEditSubmitAction.CreateNew,
  }]);
