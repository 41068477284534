export enum MapOutlinePosition {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}

export type MapOutlinePositionInstance = {
  id: string;
  position: MapOutlinePosition;
  lat: number;
  lng: number;
  offsetX: number;
  offsetY: number;
};
