import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyles } from '~/_shared/baseComponents/buttons/button/button.styles';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { MatchUpDataTableComponent } from '~/_shared/components/matchUpDataModal/matchUpDataTable.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { filterAddedSpreadsheetColumns } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { createUuid } from '~/_shared/utils/createUuid';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';

const DELETE_COLUMN_ID = createUuid();

// source columnId, value is target columnId, ignore (delete) or
type ReplaceDataMatchupItem = {
  ignore: true;
  newColumnId: null;
} | {
  ignore: false;
  newColumnId: ColumnId | null;
};

export type ReplaceDataMatchup = {
  [oldColumnId: ColumnId]: ReplaceDataMatchupItem;
};

const contentStyle = css({
  padding: 0,
});

type ReplaceDataMatchupModalProps = ModalProps<{
  matchup: ReplaceDataMatchup;
  newColumns: ReadonlyArray<DropdownOption<string>>;
  newColumnsCaption?: string;
  noIgnoredColumns?: boolean;
  oldColumns: ReadonlyArray<DropdownOption<string>>;
  oldColumnsCaption?: string;
  isSubmitDisabled: boolean;
  isLoading?: boolean;

  onMatchupChange: (newMatchUp: ReplaceDataMatchup) => void;
  onSubmit: () => void;
}>;

const getUnusedColumns = (newColumns: ReadonlyArray<DropdownOption<string>>, matchUp: ReplaceDataMatchup): ReadonlyArray<DropdownOption<string>> => {
  const usedColumnIds: ReadonlySet<string | null> = new Set<string | null>(Object.values(matchUp).map(v => v.newColumnId));
  return newColumns.filter(c => !usedColumnIds.has(c.value));
};

const deleteColumnOptionStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
});

const triggerIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
});

export const ReplaceDataMatchupModalComponent: FC<ReplaceDataMatchupModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  // if we ever for some reason have less final columns than props.oldColumns.length, need to update isSubmitDisabled in the container
  const rows = useMemo(() => filterAddedSpreadsheetColumns(props.oldColumns).map((column, index) => {
    const selectedValue = props.matchup[column.value];
    const selectedColumnId = selectedValue?.newColumnId ?? null;

    const unusedColumns = getUnusedColumns(props.newColumns, props.matchup);
    const selectedColumn = selectedColumnId !== null && props.newColumns.find(c => c.value === selectedColumnId);

    const dropdownOptions: DropdownOption<string | undefined>[] = [
      ...(props.noIgnoredColumns ? [] : [{
        name: t('Ignore'),
        value: DELETE_COLUMN_ID,
        style: deleteColumnOptionStyle({ theme }),
      }]),
      ...(selectedColumn ? [selectedColumn] : []),
      ...unusedColumns,
    ];

    const triggerStyle = selectedValue?.ignore ? deleteColumnOptionStyle({ theme }) : undefined;

    const onMatchupChange = props.onMatchupChange;

    return {
      isAutoMatched: false,
      onChange: (selectedColumnId: string) => {
        const newMatchup = { ...props.matchup };
        newMatchup[column.value] = selectedColumnId === DELETE_COLUMN_ID ? {
          newColumnId: null,
          ignore: true,
        } : {
          newColumnId: selectedColumnId,
          ignore: false,
        };
        onMatchupChange(newMatchup);
      },
      rowName: column.name || t('Column {{number}}', { number: index + 1 }),
      dropdownOptions,
      selectedOptionId: selectedValue?.ignore ? DELETE_COLUMN_ID : selectedColumnId,
      triggerStyle,
      triggerIconStyle: triggerIconStyle({ theme }),
    };
  }), [props.matchup, props.newColumns, props.noIgnoredColumns, props.oldColumns, props.onMatchupChange, t, theme]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      contentStyle={contentStyle}
      maxWidth={547}
      caption={t('Map old spreadsheet columns to new')}
      leftFooterContent={(
        <ButtonComponent
          text={t('Cancel')}
          onClick={() => props.onClose()}
          css={[ButtonStyles(theme).Secondary]}
        />
      )}
      confirmButton={(
        <ButtonComponent
          text={t('Done')}
          isDisabled={props.isSubmitDisabled}
          onClick={props.onSubmit}
          prefixIcon={faCheck}
        />
      )}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <MatchUpDataTableComponent
        primaryColumnHeading={props.oldColumnsCaption ?? t('Old Columns')}
        secondaryColumnHeading={props.newColumnsCaption ?? t('New Columns')}
        rows={rows}
      />
    </ModalComponent>
  );
};
