import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIsBoundaryComplexForManualEdit } from '~/store/boundaryItems/boundaryItems.selectors';
import {
  boundaryDrawCloseModal, boundaryDrawPolygonDragEditOpenModal,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { useBoundaryDrawPolygonEditModalOpenSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { CUSTOM_BOUNDARY_ZOOM_LEVEL } from '../../../../../store/boundaryItems/boundaryItems.state';
import { useBoundaryStateItem } from '../hooks/useBoundaryStateItem';

export const useBoundaryEditPolygon = () => {
  const boundaryDragEdit = useBoundaryDrawPolygonEditModalOpenSelector();
  const { getBoundaryStateItem } = useBoundaryStateItem();
  const { isBoundaryComplexForManualEdit } = useIsBoundaryComplexForManualEdit();
  const dispatch = useDispatch();

  const editBoundaryPath = useCallback((boundaryId: number, boundaryGroupId: number, boundaryTerritoryGroupId: number) => {
    const boundary = getBoundaryStateItem(boundaryId, boundaryGroupId);
    const multiPolygon = boundary?.paths.get(CUSTOM_BOUNDARY_ZOOM_LEVEL);

    if (!multiPolygon) {
      return;
    }

    const { reason: complexReason } = isBoundaryComplexForManualEdit({ boundaryId, boundaryGroupId });

    // Drag edit doesn't support holes at the moment. Only polygon path is used.
    const paths = multiPolygon.map(polygon => polygon.path);

    dispatch(boundaryDrawPolygonDragEditOpenModal({ paths, boundaryId, boundaryGroupId, boundaryTerritoryGroupId, disabledReason: complexReason }));
  }, [dispatch, getBoundaryStateItem, isBoundaryComplexForManualEdit]);

  const stopBoundaryEditing = useCallback(() => {
    dispatch(boundaryDrawCloseModal());
  }, [dispatch]);

  const toggleBoundaryPolygonEdit = useCallback((boundaryId: number, boundaryGroupId: number, boundaryTerritoryGroupId: number) => {
    if (boundaryDragEdit) {
      stopBoundaryEditing();

      if (boundaryDragEdit.drawModeData.boundaryId === boundaryId && boundaryDragEdit.boundaryGroupId === boundaryGroupId) {
        return;
      }
    }

    editBoundaryPath(boundaryId, boundaryGroupId, boundaryTerritoryGroupId);
  }, [boundaryDragEdit, editBoundaryPath, stopBoundaryEditing]);

  return {
    toggleBoundaryPolygonEdit,
    isEditingBoundaryPath: !!boundaryDragEdit,
  };
};
