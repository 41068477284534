import {
  MOVE_MARKERS_ACTIVATE,
  MOVE_MARKERS_CLOSE_MODAL,
  MOVE_MARKERS_DEACTIVATE,
  MOVE_MARKERS_DISCARD_CHANGES,
  MOVE_MARKERS_DRAG_START,
  MOVE_MARKERS_DRAG_STOP,
  MOVE_MARKERS_OPEN_MODAL,
  MOVE_MARKERS_UPDATE_MARKER_POSITION,
} from './moveMarkers.actionTypes';

export const activateMoveMarkers = () => ({
  type: MOVE_MARKERS_ACTIVATE,
}) as const;

export const moveMarkersOpenModal = () => ({
  type: MOVE_MARKERS_OPEN_MODAL,
}) as const;

export const moveMarkersCloseModal = () => ({
  type: MOVE_MARKERS_CLOSE_MODAL,
}) as const;

export const deactivateMoveMarkers = () => ({
  type: MOVE_MARKERS_DEACTIVATE,
}) as const;

export const discardMoveMarkers = () => ({
  type: MOVE_MARKERS_DISCARD_CHANGES,
}) as const;

export const updateMarkerPosition = (props: {lat: number; lng: number; rowId: string}) => ({
  type: MOVE_MARKERS_UPDATE_MARKER_POSITION,
  payload: props,
});

export const startMoveMarkerDrag = () => ({
  type: MOVE_MARKERS_DRAG_START,
}) as const;

export const stopMoveMarkerDrag = () => ({
  type: MOVE_MARKERS_DRAG_STOP,
}) as const;
