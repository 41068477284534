import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import {
  type ActiveGroupFilters,
  type GroupingColumn,
  type GroupingColumnNumeric,
  type GroupingColumnValues,
  GroupingType,
} from '~/_shared/types/grouping/grouping';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { GroupingItemBodyContainer } from './groupingItemBody.container';
import { GroupingItemHeaderComponent } from './groupingItemHeader.component';

const createGroupStatusTitle = (groupIndex: number, t: TranslationFnc) => {
  switch (groupIndex) {
    case 0:
      return t('Primary Group');
    case 1:
      return t('Secondary Group');
    default:
      //TODO: do we want some kind of error logging (outside the client)
      console.error('Grouping tool supports only two groups right now.');
      return t('Secondary Group');
  }
};

const accordionHeaderStyle = css({
  marginRight: 0,
  position: 'relative',
  width: '100%',
});

type ColumnScopedFilter = GroupingColumnValues<1>;

const areGroupsCheckedPartiallyChecked = (filter: ColumnScopedFilter) => Object.keys(filter.text ?? {}).length > 0 || Object.keys(filter.numeric ?? {}).length > 0;
const getShowAllCheckmark = (filter: ColumnScopedFilter) => areGroupsCheckedPartiallyChecked(filter) ? TriStateRange.Partial : TriStateRange.Full;

const bodyRootStyle = css({
  padding: 0,
});

type GroupingItemsAccordionProps = Readonly<{
  activeColumns: ReadonlyArray<GroupingColumn>;
  activeFilters: ActiveGroupFilters;
  allColumns: ReadonlyArray<SpreadsheetColumn>;
  isGroupEditingDisabled: boolean;
  isFilteringDisabled?: boolean;

  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, group: GroupingColumnValues<1>) => void;
  onGroupColumnRemove?: (column: GroupingColumn) => void;
  onNumericGroupColumnSettingsClick: (column: GroupingColumnNumeric) => void;
}>;

export const GroupingItemsAccordionComponent: FC<GroupingItemsAccordionProps> = props => {
  const [t] = useTranslation();

  const { activeColumns, onFilterChange, onNumericGroupColumnSettingsClick, onGroupColumnRemove } = props;

  const createToggleShowAll = useCallback((column: GroupingColumn) => () => {
    const spreadsheetColumnId = {
      spreadsheetId: column.spreadsheetId,
      columnId: column.columnId,
    };

    props.onFilterChange(spreadsheetColumnId, {});
  }, [props]);

  const accordionData = useMemo(() =>
    activeColumns.map((column, index) => {
      const columnScopedFilter = props.activeFilters[column.spreadsheetId]?.[column.columnId] ?? {};

      return ({
        header: (
          <GroupingItemHeaderComponent
            groupName={props.allColumns.find(c => c.id === column.columnId)?.name ?? 'Unknown column'}
            groupStatusTitle={createGroupStatusTitle(index, t)}
            isEditingDisabled={props.isGroupEditingDisabled}
            isFilteringDisabled={props.isFilteringDisabled}
            showAll={getShowAllCheckmark(columnScopedFilter)}
            onCogClick={() => {
              if (column.type === GroupingType.Numeric) {
                onNumericGroupColumnSettingsClick(column);
              }
            }}
            onRemove={onGroupColumnRemove ? () => onGroupColumnRemove(column) : undefined}
            onToggleShowAll={createToggleShowAll(column)}
            showCogButton={column.type === GroupingType.Numeric}
          />
        ),
        child: (
          <GroupingItemBodyContainer
            activeFilters={props.activeFilters}
            currentGroupColumn={column}
            isEditingDisabled={props.isGroupEditingDisabled}
            isFilteringDisabled={props.isFilteringDisabled}
            onFilterChange={onFilterChange}
          />
        ),
        isExpanded: true,
        isLocked: true,
      });
    }), [activeColumns, createToggleShowAll, onFilterChange, onGroupColumnRemove,
    onNumericGroupColumnSettingsClick, props.activeFilters, props.allColumns, props.isFilteringDisabled,
    props.isGroupEditingDisabled, t,
  ]);

  return (
    <OutlinePanelComponent>
      <AccordionComponent
        data={accordionData}
        headerSize={AccordionHeaderSize.Large}
        headerWrapperStyle={accordionHeaderStyle}
        panelStyle={bodyRootStyle}
        testid="groupingList"
      />
    </OutlinePanelComponent>
  );
};
