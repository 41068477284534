import 'rc-slider/assets/index.css';
import {
  css, type SerializedStyles,
} from '@emotion/react';
import numeral from 'numeral';
import {
  type CSSProperties, useMemo,
} from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import {
  SliderComponent, type SliderConstraint, type SliderMarks, type SliderValue,
} from '../slider.component';

const containerWithInlineLabelsStyle = css({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '5px',
});

const containerWithVerticalSliderStyle = css({
  position: 'relative',
  height: '100%',
});

const containerWithLabelsBelowStyle = css({
  position: 'relative',
  paddingBottom: '22px',
});

const commonRangeLabelStyle = css({
  cursor: 'default',
  position: 'absolute',
  bottom: 0,
  transition: 'opacity .2s',
});

const currentValueRightLabelStyle = ({ theme }: ThemeProps) => ({
  color: theme.textColors.tertiary,
  cursor: 'default',
  marginLeft: 12,
});

const currentValueLabelStyle = ({ theme, left }: ThemeProps<{left: number}>) => ({
  left: `${left}%`,
  transform: 'translateX(-50%)',
  zIndex: 1,
  color: theme.textColors.secondary,
});

const minValueLabelStyle = ({ theme, isHidden }: ThemeProps<{isHidden: boolean}>) => ({
  left: 0,
  transform: 'translateX(-50%)',
  color: theme.textColors.tertiary,
  opacity: isHidden ? 0 : 1,
});

const maxValueLabelStyle = ({ theme, isHidden }: ThemeProps<{isHidden: boolean}>) => ({
  left: '100%',
  transform: 'translateX(-50%)',
  color: theme.textColors.tertiary,
  opacity: isHidden ? 0 : 1,
});

type SliderWithValuesLabelsProps = Readonly<{
  className?: string;
  constraints?: SliderConstraint | SliderConstraint[];
  currentValueOnTheRight?: boolean;
  disabled?: boolean;
  dotStyle?: CSSProperties | ((value: number) => CSSProperties);
  handleLabelStyleCommon?: SerializedStyles;
  included?: boolean;
  labelStyleCommon?: SerializedStyles;
  leftLabelStyle?: SerializedStyles;
  marks?: SliderMarks;
  max?: number;
  min?: number;
  noLabel?: boolean;
  reverse?: boolean;
  rightLabelStyle?: SerializedStyles;
  sidesLabelStyleCommon?: SerializedStyles;
  step?: number;
  value: SliderValue;
  valuePrefix?: string;
  valueSuffix?: string;
  vertical?: boolean;
  withDots?: boolean;

  labelNumberFormat?: (value: number) => number | string;
  onChange: (value: SliderValue) => void;
}>;

const defaultFormatNumber = (value: number) => numeral(value).format('0a');

export const SliderWithValueLabelsComponent = ({
  className,
  constraints,
  currentValueOnTheRight,
  disabled,
  dotStyle,
  handleLabelStyleCommon,
  included,
  labelNumberFormat = defaultFormatNumber,
  labelStyleCommon,
  leftLabelStyle,
  marks,
  max = 100,
  min = 0,
  noLabel,
  onChange,
  reverse,
  rightLabelStyle,
  sidesLabelStyleCommon,
  step = 1,
  value,
  valuePrefix = '',
  valueSuffix = '',
  vertical,
  withDots,
  ...restProps
}: SliderWithValuesLabelsProps) => {
  const theme = useTheme();

  const valueLabelStyle = value.map(value => currentValueOnTheRight ? css(
    currentValueRightLabelStyle({ theme }),
    labelStyleCommon,
  ) : css(
    commonRangeLabelStyle,
    currentValueLabelStyle({ theme, left: Math.round((value - min) / (max - min) * 100) }),
    labelStyleCommon,
    handleLabelStyleCommon,
  ));

  const firstValue = value[0] ?? 0;
  const lastValue = value[value.length - 1] ?? 0;

  const LabelComponent = (
    <>
      {!currentValueOnTheRight && (
        <span
          css={css(
            commonRangeLabelStyle,
            minValueLabelStyle({ theme, isHidden: (firstValue / max) < .15 }),
            labelStyleCommon,
            sidesLabelStyleCommon,
            leftLabelStyle,
          )}
        >
          {valuePrefix}{labelNumberFormat(min)}{valueSuffix}
        </span>
      )}

      {value.map((value, index) => (
        <span
          key={index}
          css={valueLabelStyle[index]}
        >
          {valuePrefix}{labelNumberFormat(value)}{valueSuffix}
        </span>
      ))}

      {!currentValueOnTheRight && (
        <span
          css={css(
            commonRangeLabelStyle,
            maxValueLabelStyle({ theme, isHidden: (lastValue / max) > .85 }),
            labelStyleCommon,
            sidesLabelStyleCommon,
            rightLabelStyle,
          )}
        >
          {valuePrefix}{labelNumberFormat(max)}{valueSuffix}
        </span>
      )}
    </>
  );

  const containerStyle = useMemo(() => {
    if (vertical) {
      return containerWithVerticalSliderStyle;
    }
    if (currentValueOnTheRight || noLabel) {
      return containerWithInlineLabelsStyle;
    }

    return containerWithLabelsBelowStyle;
  }, [currentValueOnTheRight, noLabel, vertical]);

  return (
    <SliderComponent
      css={[
        containerStyle,
        css({ opacity: disabled ? theme.opacity.disabled : undefined }),
      ]}
      className={className}
      constraints={constraints}
      disabled={disabled}
      dotStyle={dotStyle}
      included={included}
      marks={marks}
      max={max}
      min={min}
      onChange={onChange}
      reverse={reverse}
      step={step}
      value={value}
      vertical={vertical}
      withDots={withDots}
      {...restProps}
    >
      {!noLabel && LabelComponent}
    </SliderComponent>
  );
};
