import {
  faLasso, faMousePointer, faOverline,
} from '@fortawesome/pro-solid-svg-icons';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { notNull } from '~/_shared/utils/typeGuards';
import { BoundarySelectType } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';

export const getSelectBoundaryTypeOptions = (t: TranslationFnc, selectOptions: ReadonlyArray<BoundarySelectType>): ReadonlyArray<DropdownOption<BoundarySelectType>> => {
  return selectOptions
    .map(selectType => {
      switch (selectType) {
        case BoundarySelectType.Click:
          return {
            name: t('On Click'),
            value: BoundarySelectType.Click,
            icon: faMousePointer,
          };
        case BoundarySelectType.HoverIdle:
          return {
            name: t('On Hover'),
            value: BoundarySelectType.HoverIdle,
            icon: faOverline,
          };
        case BoundarySelectType.Lasso:
          return {
            name: t('Lasso tool'),
            value: BoundarySelectType.Lasso,
            icon: faLasso,
          };
        default: return null;
      }
    })
    .filter(notNull);
};
