import {
  useEffect, useState,
} from 'react';
import { useStorageService } from '~/_shared/utils/storageService';
import {
  restoreSplitPanelHeight, storeSplitPanelState,
} from '~/store/userData/settings/splitPanel/splitPanel.helpers';
import { useSplitPanelState } from './useSplitPanelState';

export const useSplitPanel = (mapId: number | null) => {
  const [height, setHeight] = useState<number | null>(null);
  const storageService = useStorageService();
  const splitPanelState = useSplitPanelState(mapId);

  useEffect(() => {
    if (mapId === null) {
      return;
    }

    const newHeight = restoreSplitPanelHeight(storageService, mapId);
    setHeight(newHeight);
  }, [mapId, storageService]);

  useEffect(() => {
    if (mapId === null || height === null) {
      return;
    }

    storeSplitPanelState({
      mapId,
      height,
    }, storageService);
  }, [height, mapId, storageService]);

  return {
    isActive: splitPanelState.isActive,
    height,
    setHeight,
    openPanel: splitPanelState.openPanel,
    closePanel: splitPanelState.closePanel,
  };
};
