import type { MemberRole } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import type { Member } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import type {
  CurrentClient, GoogleApiOauthStatus, TwoFactorStats,
} from './repository/userData.repository';
import type { UserSettingsState } from './settings/userDataSettings.state';

export type UserDataState = Readonly<{
  '2fa': TwoFactorStats;
  clientId: number | null;
  clientMembers: Member[];
  clientName: string | null;
  clientRole: MemberRole;
  createdAt: string;
  currentClient: CurrentClient | null;
  email: string;
  errorMessage: string;
  firstName: string;
  googleApiOauth: GoogleApiOauthStatus | null;
  id: number | null;
  intercomHash: string;
  isLoading: boolean;
  isPublicApiActive: boolean;
  isReady: boolean;
  isSignedIn: boolean;
  lastName: string;
  limits: UserDataLimits;
  previousLogin: Date | null;
  role: Role;
  settings: UserSettingsState | null;
  teamId: number | null;
  usage: UserDataStateUsage;
}>;

export type UserDataStateUsage = Readonly<{
  distanceMatrixRequests: number;
  realGeocodes: number;
  publicMapViews: number;
}>;

export type UserDataLimits = Readonly<{
  cachedGeocodes: number;
  canExportSharedMapsData: boolean;
  directionsApiRequests: number;
  distanceMatrixRequests: number;
  googlePlacesRequests: number;
  mapView: number;
  maps: number;
  markers: number;
  realGeocodes: number;
  userLimit: number;
  wmsEnabled: boolean;
}>;

export const Roles = {
  SUPERUSER: 'Superuser',
  CLIENT: 'Client',
  NA: 'N/A',
} as const;

export type Role = typeof Roles[keyof typeof Roles];
