import { takeEvery } from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import { trackUserEvent } from '~/store/userEvents/userEvents.actionCreator';
import { evaluateSequence } from '~/store/userEvents/userEventSequenceEvaluation';

export function* userEventsSagas() {
  yield takeEvery(trackUserEvent, onEventTracked);
}

function* onEventTracked() {
  const {
    events,
    lastEventsOfType,
  } = yield select(state => state.userEvents);

  evaluateSequence(events, lastEventsOfType);
}
