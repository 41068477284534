import { useDispatch } from 'react-redux';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { createMapLimitReachedAppError } from '~/appError/specificErrors/mapLimitReached/mapLimitReached.helpers';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { MAP_LIMIT_REACHED_MESSAGE } from '../map.repository';
import {
  type CreateLayeredMapData,
  createLayeredMapRequest,
} from './layering.repository';

export const useCreateLayeredMap = () => {
  const { isLoading, error, invokeAjax } = useAjaxCall(
    ({ clientId, request }: {clientId: number; request: CreateLayeredMapData}) => createLayeredMapRequest(clientId, request)
  );
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const createLayeredMap = async (clientId: number, request: CreateLayeredMapData, onSuccess?: (mapId: number) => void) => {
    try {
      const response = await invokeAjax({ clientId, request });
      onSuccess?.(response.data.id);
    }
    catch (e) {
      const apiError = e as ApiError;
      const message = apiError?.errors?.maps_limit?.[0] ?? apiError.message;
      if (message === MAP_LIMIT_REACHED_MESSAGE) {
        dispatch(createMapLimitReachedAppError(t));
      }
      else {
        dispatch(createAppError({
          type: AppErrorType.General,
          title: t('Failed to Create a Layered Map'),
          text: t('We were unable to create a layered map for you. Please try again. If this problem persists, please contact the customer support.'),
        }));
      }
    }
  };

  return {
    isLoading,
    error,
    createLayeredMap,
  };
};
