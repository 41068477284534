import { css } from '@emotion/react';
import {
  type CSSProperties, forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from 'react';

type InlineProps = PropsWithChildren<{
  readonly gap: CSSProperties['gap'];
  readonly wrap?: CSSProperties['flexWrap'];
  readonly className?: string;
}> & HTMLAttributes<HTMLDivElement>;

const inlineWrapperStyles = css({
  display: 'inline-flex',
});

export const Inline = forwardRef<HTMLDivElement, InlineProps>(({
  children,
  gap,
  wrap = 'wrap',
  className,
  ...restProps
}, ref) => {
  return (
    <div
      className={className}
      ref={ref}
      css={[inlineWrapperStyles, {
        flexWrap: wrap,
        gap,
      }]}
      {...restProps}
    >
      {children}
    </div>
  );
});

Inline.displayName = 'Inline';
