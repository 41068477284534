import { useCallback } from 'react';
import { type ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  numberObjectEntries, stringObjectEntries,
} from '~/_shared/utils/object/objectEnumerators';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type ColumnsMapSettingsResponse } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.types';
import { type ColumnsSettingsModalProps } from './columnsSettingsModal.component';

export const useConfirmColumnsSettingsErased = () => {
  const { openModal, closeModal } = useModal(ModalType.ColumnsSettings);

  return useCallback((props: Readonly<{
    columnsMapSettings?: ColumnsMapSettingsResponse; // all the settings for all the columns
    description?: string;
    description2?: string;
    usedColumns: readonly ColumnId[]; // indicate which columns were mapped, so they won't be erased
    onConfirm: () => void;
    onReject?: () => void;
  }>) => {

    const allUnusedColumnsSettingsList =
      stringObjectEntries(props.columnsMapSettings?.columns ?? {}).filter(([columnId]) => (
        !props.usedColumns.includes(columnId)
      ));

    if (allUnusedColumnsSettingsList.length === 0) {
      props.onConfirm();
      return;
    }

    const basicMapInfo = numberObjectEntries(props.columnsMapSettings?.maps ?? {})
      .reduce<ColumnsSettingsModalProps['basicMapInfo']>((acc, [mapId, mapInfo]) => {
        acc[mapId] = { name: mapInfo.name, parentMapId: mapInfo.parent_map_id };
        return acc;
      }, {});

    const onSubmit = () => {
      closeModal();
      props.onConfirm();
    };

    openModal({
      columnSettings: allUnusedColumnsSettingsList,
      description: props.description,
      description2: props.description2,
      basicMapInfo,
      onClose: props.onReject,
      onSubmit,
    });
  }, [closeModal, openModal]);
};
