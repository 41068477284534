import { css } from '@emotion/react';
import { faTurnDownRight } from '@fortawesome/pro-regular-svg-icons/faTurnDownRight';
import {
  type PropsWithChildren, useContext,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { ThemeProps } from '~/_shared/types/themeProps';
import { TableContext } from '~/map/mapMigration/table/table.context';
import { rowStyle } from '~/map/mapMigration/table/table.styles';

type TableNoResultsRowProps = PropsWithChildren;

const getAuxiliaryRowStyles = ({ theme, columnCount }: ThemeProps<{readonly columnCount: number}>) => css({
  paddingBlock: 10,
  paddingLeft: 10,
  textAlign: 'left',
  background: theme.backgroundColors.tertiary,
  gridColumn: `span ${columnCount}`,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const getAuxRowIconCellStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.tertiary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 10,
});

export const TableAuxiliaryRowComponent = ({ children }: TableNoResultsRowProps) => {
  const theme = useTheme();
  const { columnCount } = useContext(TableContext);

  return (
    <div
      css={rowStyle}
    >
      <div
        css={getAuxRowIconCellStyle({ theme })}
      >
        <FontAwesomeIcon icon={faTurnDownRight} />
      </div>
      <div css={getAuxiliaryRowStyles({ theme, columnCount: columnCount - 1 })}>
        {children}
      </div>
    </div>
  );
};
