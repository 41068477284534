import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import {
  AccordionComponent, type AccordionData, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { AlternatingStripesComponent } from '~/_shared/baseComponents/alternatingStripes/alternatingStripes';
import {
  ButtonComponent, ButtonStyle, ButtonStyles,
} from '~/_shared/baseComponents/buttons';
import { OneDimensionalTableWithHeader } from '~/_shared/baseComponents/oneDimensionalTable/oneDimensionalTableWithHeader';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { flatten } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { numberObjectKeys } from '~/_shared/utils/object/objectEnumerators';
import { notNull } from '~/_shared/utils/typeGuards';
import { type ModalProps } from '~/modal/modalType.enum';
import { allColumnSettingsTools } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.constants';
import { getColumnMapSettingsToolName } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.helpers';
import { type ColumnMapSettings } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.types';

const contentStyle = css({
  padding: 0,
});

const descriptionStyle = css({
  boxSizing: 'border-box',
  cursor: 'default',
  fontSize: 14,
  padding: '10px 20px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const accordionItemHeaderStyle = css({
  textTransform: 'uppercase',
});

const accordionPanelStyle = css({
  padding: 0,
});

const accordionHeaderStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const accordionItemWrapperStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  borderTop: `1px solid ${theme.borderColors.primary}`,
  flexDirection: 'column',
  gap: 6,
  '&:last-of-type': {
    borderBottom: 'none',
  },
});

const itemStyle = css({
  padding: '10px 15px',
});

export type ColumnsSettingsModalProps = ModalProps<Readonly<{
  columnSettings: [ColumnId, ColumnMapSettings][];
  description?: string;
  description2?: string;
  basicMapInfo: {
    [mapId: number]: Readonly<{
      name: string;
      parentMapId: number;
    }>;
  };

  onSubmit: () => void;
}>>;

export const ColumnsSettingsModalComponent = (props: ColumnsSettingsModalProps) => {
  const { basicMapInfo, columnSettings, description, description2 } = props;

  const [t] = useTranslation();
  const theme = useTheme();

  const allUsedMapIds = useMemo(() => new Set(flatten(
    columnSettings.map(([_, column]) => (
      numberObjectKeys(column.maps)
    ))
  )), [columnSettings]);

  const allUsedMaps = useMemo(() => (
    [...allUsedMapIds].map((mapId) => {
      const mapInfo = basicMapInfo[mapId];
      if (!mapInfo) {
        return null;
      }
      return [mapId, mapInfo] as const;
    }).filter(notNull)
  ), [allUsedMapIds, basicMapInfo]);

  const items: AccordionData[] = useMemo(() => allUsedMaps.map(([mapId, map]) => {
    const possibleTools = allColumnSettingsTools;
    const possibleColumnsForPaths = possibleTools.map(item => (
      columnSettings.map(([_, column]) => {
        if (column.maps[mapId]?.includes(item)) {
          return column.name;
        }
        return null;
      }).filter(notNull)
    ));
    const parentMapName = map.parentMapId ? basicMapInfo[map.parentMapId]?.name : null;

    return {
      header: (parentMapName ? t('Map View Name') : t('Map Name')) + ': ' + map.name + (parentMapName ? ` (${parentMapName})` : ''),
      child: (
        <AlternatingStripesComponent
          css={accordionItemWrapperStyle({ theme })}
        >
          {possibleTools.map((item, index) => (
            possibleColumnsForPaths[index]?.length ?? 0 > 0 ? (
              <div
                css={itemStyle}
                key={index}
              >
                <OneDimensionalTableWithHeader
                  heading={getColumnMapSettingsToolName(item, t)}
                  subheader={t('Columns')}
                  body={{
                    rows: possibleColumnsForPaths[index]?.map((column) => (
                      column ? {
                        name: column,
                      } : null
                    )).filter(notNull) || [],
                  }}
                />
              </div>
            ) : null
          )).filter(notNull)}
        </AlternatingStripesComponent>
      ),
    };
  }), [allUsedMaps, basicMapInfo, columnSettings, t, theme]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      contentStyle={contentStyle}
      maxWidth={547}
      caption={t('columnSettingsModal.title')}
      leftFooterContent={(
        <ButtonComponent
          text={t('Cancel')}
          onClick={() => props.onClose()}
          css={[ButtonStyles(theme).Secondary]}
        />
      )}
      confirmButton={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Danger}
          text={t('Proceed')}
          onClick={props.onSubmit}
          prefixIcon={faCheck}
        />
      )}
    >
      <div css={descriptionStyle}>
        {description &&
          <div>{description}</div>
        }
        {description2 &&
          <div>{description2}</div>
        }
        <div>{t('The following maps will be affected:')}</div>
      </div>

      <AccordionComponent
        data={items}
        itemButtonStyle={accordionItemHeaderStyle}
        showCount
        panelStyle={accordionPanelStyle}
        headerSize={AccordionHeaderSize.Medium}
        headerWrapperStyle={accordionHeaderStyle}
      />
    </ModalComponent>
  );
};
