import { type BoundaryDrawAction } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.action';
import {
  BOUNDARY_DRAW_DRAWING_START,
  BOUNDARY_DRAW_DRAWING_STOP,
  BOUNDARY_DRAW_OUTLINE_HOVER, BOUNDARY_DRAW_OUTLINE_MOUSEOUT, BOUNDARY_DRAW_REMOVE_BUTTON_HOVER, BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyBoundaryDrawMapOptions = (
  state: MapComponentOptionsState,
  action: BoundaryDrawAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case BOUNDARY_DRAW_OUTLINE_HOVER: {
      return push(state, {
        changeId: 'BOUNDARY_DRAW_OUTLINE_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });
    }

    case BOUNDARY_DRAW_OUTLINE_MOUSEOUT:
      return pop(state, 'BOUNDARY_DRAW_OUTLINE_HOVER');

    case BOUNDARY_DRAW_REMOVE_BUTTON_HOVER:
      return push(state, {
        changeId: 'BOUNDARY_DRAW_REMOVE_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT:
      return pop(state, 'BOUNDARY_DRAW_REMOVE_HOVER');

    case BOUNDARY_DRAW_DRAWING_START:
      return push(state, {
        changeId: 'BOUNDARIES_DRAWING',
        priority,
        change: {
          draggableCursor: 'crosshair',
          disableDoubleClickZoom: true,
          clickableIcons: false,
        },
      });

    case BOUNDARY_DRAW_DRAWING_STOP: {
      return pop(state, 'BOUNDARIES_DRAWING');
    }

    default:
      return undefined;
  }
};
