import { type QuadrilateralDimensions } from '../../../../_shared/types/coordinateSystem/coordinateSystem';
import { type MapTextAreaCalloutInstance } from './mapTextAreaCalloutModel';

export const getCalloutDimensions = (callout: MapTextAreaCalloutInstance): QuadrilateralDimensions => {
  const arrowDimensions = getCalloutArrowDimensions(callout);
  const width = callout.textBoxWidth + arrowDimensions.width + callout.strokeWidth;
  const height = callout.textBoxHeight + arrowDimensions.height + callout.strokeWidth;

  return { width, height };
};

export const getCalloutArrowDimensions = (callout: MapTextAreaCalloutInstance) => {
  const width = callout.arrowToTexboxPercentage * (callout.textBoxWidth / (100 - callout.arrowToTexboxPercentage));
  const height = callout.arrowToTexboxPercentage * (callout.textBoxHeight / (100 - callout.arrowToTexboxPercentage));
  return { width, height };
};

export const getCalloutCoordsSvg = (callout: MapTextAreaCalloutInstance, borderRadius: number) => {
  const arrowDimensions = getCalloutArrowDimensions(callout);
  const numberOfCornerPoints = 10;
  let borderRadiusSize = callout.textBoxWidth * (borderRadius / 100);

  const calloutFromPx = new google.maps.Point(arrowDimensions.width, arrowDimensions.height);
  const calloutToPx = new google.maps.Point(arrowDimensions.width + callout.textBoxWidth, arrowDimensions.height + callout.textBoxHeight);
  if (callout.textBoxWidth > callout.textBoxHeight) {
    borderRadiusSize = callout.textBoxHeight * (borderRadius / 100);
  }

  const totalCalloutHeight = callout.textBoxHeight + arrowDimensions.height + Math.round(callout.strokeWidth / 2);
  let calloutSvg = 'M0 ' + totalCalloutHeight;
  calloutSvg += 'L' + arrowDimensions.width + ' ' + (-(arrowDimensions.height * 2) + totalCalloutHeight) + ' ';
  let borderRadiusFirst = borderRadiusSize;

  const minBorderRadius = Math.abs((arrowDimensions.height * 2) - calloutToPx.y);

  if (borderRadiusFirst >= minBorderRadius) {
    borderRadiusFirst = minBorderRadius;
  }

  const topLeftCornerCoords = new google.maps.Point(calloutFromPx.x + borderRadiusFirst, calloutToPx.y - borderRadiusFirst);
  for (let i = 0; i <= numberOfCornerPoints; i++) {
    const x = Math.cos(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusFirst;
    const y = Math.sin(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusFirst;
    calloutSvg += 'L' + (topLeftCornerCoords.x - x) + ' ' + (-(topLeftCornerCoords.y + y) + totalCalloutHeight) + ' ';
  }

  const topRightCornerCoords = new google.maps.Point(calloutToPx.x - borderRadiusSize, calloutToPx.y - borderRadiusSize);
  for (let i = numberOfCornerPoints; i >= 0; i--) {
    const x = Math.cos(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusSize;
    const y = Math.sin(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusSize;
    calloutSvg += 'L' + (topRightCornerCoords.x + x) + ' ' + (-(topRightCornerCoords.y + y) + totalCalloutHeight) + ' ';
  }

  const bottomRightCornerCoords = new google.maps.Point(calloutToPx.x - borderRadiusSize, calloutFromPx.y + borderRadiusSize);
  for (let i = 0; i <= numberOfCornerPoints; i++) {
    const x = Math.cos(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusSize;
    const y = Math.sin(((3.14 / 2) / numberOfCornerPoints) * i) * borderRadiusSize;
    calloutSvg += 'L' + (bottomRightCornerCoords.x + x) + ' ' + (-(bottomRightCornerCoords.y - y) + totalCalloutHeight) + ' ';
  }

  calloutSvg += 'L' + calloutFromPx.x + ' ' + (-calloutFromPx.y + totalCalloutHeight) + ' ';
  calloutSvg += 'Z';

  return calloutSvg;
};
