import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

const mapSettingsConcurrencySelector = (state: AppState) => state.map.mapSettings.concurrency;
export const useMapSettingsConcurrencySelector = () => useSelector(mapSettingsConcurrencySelector);

export const mapSettingsConcurrencyIsOfflineSelector = (state: AppState) => state.map.mapSettings.concurrency.isOffline;
export const useMapSettingsConcurrencyIsOfflineSelector = () => useSelector(mapSettingsConcurrencyIsOfflineSelector);

export const mapSettingsConcurrencyEtagSelector = (state: AppState) => state.map.mapSettings.concurrency.etag;
