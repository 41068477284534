import { css } from '@emotion/react';
import {
  type FC, type FormEvent, useEffect, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { type ModalProps } from '../../modal/modalType.enum';
import { getAbsolutePresentationalMapUrl } from '../map.helpers';
import { validateCustomMapUrl } from './customizeMapUrl.helpers';

type CustomizeMapUrlProps = ModalProps & {
  onSaveClick: (url: string) => void;
  onSaveAndShareClick: (url: string) => void;
  isLoading: boolean;
  errorMessage: string;
  onErrorMessageClose: () => void;
  currentMapUrl: string;
};

const containerStyle = css({
  overflow: 'hidden',
});

const formStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const urlPrefixStyle = css({
  margin: '0',
  whiteSpace: 'nowrap',
});

const saveAndShareButtonStyle = css({
  marginLeft: 10,
});

const alertStyle = css({
  marginTop: 20,
});

const noteAlertStyle = css({
  marginTop: 20,
});

export const CustomizeMapUrlComponent: FC<CustomizeMapUrlProps> = (props) => {
  const [mapUrl, setMapUrl] = useState(props.currentMapUrl);
  const [isValidationError, setIsValidationError] = useState(false);
  const [t] = useTranslation();

  const isSubmitDisabled = props.currentMapUrl === mapUrl || props.isLoading || isValidationError;

  const validationErrorMessage = isValidationError ? t('The custom map url format is invalid.') : '';
  const errorMessage = props.errorMessage || validationErrorMessage;

  const clearErrorMessages = () => {
    setIsValidationError(false);
    props.onErrorMessageClose();
  };

  const submitForm = (share: boolean) => {
    if (!validateCustomMapUrl(mapUrl)) {
      setIsValidationError(true);
      return;
    }

    if (isSubmitDisabled) {
      return;
    }

    if (share) {
      props.onSaveAndShareClick(mapUrl);
    }
    else {
      props.onSaveClick(mapUrl);
    }
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    submitForm(false);
  };

  useEffect(() => {
    setMapUrl(props.currentMapUrl);
  }, [props.currentMapUrl]);

  useEffect(() => {
    if (validateCustomMapUrl(mapUrl)) {
      setIsValidationError(false);
    }
  }, [mapUrl]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Customize Map Link (URL)')}
      confirmButton={(
        <>
          <ButtonComponent
            text={t('Save')}
            isDisabled={isSubmitDisabled}
            onClick={() => submitForm(false)}
          />
          <ButtonComponent
            css={saveAndShareButtonStyle}
            text={t('Save & Share')}
            onClick={() => submitForm(true)}
            isDisabled={isSubmitDisabled}
          />

          {props.isLoading &&
            <OverlayLoaderComponent />
          }
        </>
      )}
    >
      <div css={containerStyle}>
        <p>{t('Create your own map link using the form below:')}</p>

        <form
          onSubmit={onFormSubmit}
          css={formStyle}
        >
          <p css={urlPrefixStyle}>{getAbsolutePresentationalMapUrl()}/</p>

          <TextInputComponent
            value={mapUrl}
            onChange={setMapUrl}
            icon={null}
          />
        </form>

        {props.currentMapUrl !== mapUrl && (
          <AlertComponent
            type="warning"
            css={noteAlertStyle}
          >
            {t('Please note that after changing the URL address, the previous link will not work.')}
          </AlertComponent>
        )}
      </div>

      {errorMessage && (
        <AlertComponent
          css={alertStyle}
          type="danger"
          onClose={clearErrorMessages}
        >
          {errorMessage}
        </AlertComponent>
      )}
    </ModalComponent>
  );
};
