import { type MapSettingsStylesChangeData } from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import { BaseMapStylerVisibility } from '../baseMap.enums';

export const makeSliderChangeDataVisible = (data: MapSettingsStylesChangeData): MapSettingsStylesChangeData =>
  data.map(d => ({
    ...d,
    stylers: {
      ...d.stylers,
      visibility: BaseMapStylerVisibility.inherit,
    },
  }));
