import { type ZIndexedEntity } from '../../../map/zIndexes/zIndexRanges';
import {
  FRONTEND_STATE_ZINDEX_ENTITIES_RELEASED,
  FRONTEND_STATE_ZINDEX_ENTITIES_WITH_ORDER_ENSURED,
} from './mapZIndexes.actionTypes';

export const reserveZIndex = (id: string, entity: ZIndexedEntity) => ensureZIndexesWithOrder([id], entity);
export const releaseZIndex = (id: string, entity: ZIndexedEntity) => releaseZIndexes([id], entity);

export const ensureZIndexesWithOrder = (ids: ReadonlyArray<string>, entity: ZIndexedEntity) => ({
  type: FRONTEND_STATE_ZINDEX_ENTITIES_WITH_ORDER_ENSURED,
  payload: {
    entity,
    ids,
  },
}) as const;

export const releaseZIndexes = (ids: ReadonlyArray<string>, entity: ZIndexedEntity) => ({
  type: FRONTEND_STATE_ZINDEX_ENTITIES_RELEASED,
  payload: {
    entity,
    ids,
  },
}) as const;
