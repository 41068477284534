import { css } from '@emotion/react';
import { type FC } from 'react';
import { LottieAnimationComponent } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import {
  type LottieAnimationConfig, type LottieAnimationSegment, type LottieAnimationViewDetails,
} from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';

export type TopUnderbarSavingStatusComponentProps = {
  className?: string;
  animation?: LottieAnimationConfig & {
    autoplay?: boolean;
    loop?: boolean;
    bothDirections?: boolean;
    progress?: number;
    speed?: number;
    segment?: LottieAnimationSegment;
    view?: LottieAnimationViewDetails;
  };
  text: {
    color?: string;
    value: string;
  };
  suffixText?: {
    color?: string;
    value: string;
  };
};

const textStyle = (color?: string) => css({
  color,
  fontSize: 16,
});

const containerStyle = css({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
});

export const TopUnderbarStatusItemComponent: FC<TopUnderbarSavingStatusComponentProps> = (props) => {
  const { text, animation, suffixText, className } = props;

  return (
    <div
      css={containerStyle}
      className={className}
    >
      <span css={textStyle(text.color)}>{text.value}</span>
      {animation && (
        <LottieAnimationComponent
          css={{ flexShrink: 0 }}
          size={20}
          {...animation}
        />
      )}
      {suffixText &&
        <span css={[textStyle(suffixText.color), { flexShrink: 10 }]}>{suffixText.value}</span>
      }
    </div>
  );
};
