import { GeometryFactory } from 'jsts/org/locationtech/jts/geom';
import { GeoJSONReader } from 'jsts/org/locationtech/jts/io';
import { DistanceOp } from 'jsts/org/locationtech/jts/operation/distance';
import { LineMerger } from 'jsts/org/locationtech/jts/operation/linemerge';
import { RelateOp } from 'jsts/org/locationtech/jts/operation/relate';
import { type Geometry } from './polygon.types';

const geometryFactory = new GeometryFactory();

const geometryParser = new GeoJSONReader(geometryFactory);

export const jtsParse = (geometry: Geometry) => geometryParser.read(geometry);

export const operations = {
  relate: {
    intersects: RelateOp.intersects,
    touches: RelateOp.touches,
    equalsTopo: RelateOp.equalsTopo,
    relate: RelateOp.relate,
    overlaps: RelateOp.overlaps,
    crosses: RelateOp.crosses,
    contains: RelateOp.contains,
    covers: RelateOp.covers,
  },
  distance: {
    distance: DistanceOp.distance,
    isWithinDistance: DistanceOp.isWithinDistance,
    nearestPoints: DistanceOp.nearestPoints,
  },
  lineMerger: LineMerger,
};
