import {
  type BaseMap,
  type BaseMapsUpdateModalAction, type BaseMapsUpdateModalActionForMap,
} from './baseMapsUpdateModal.types';

export const getFlatActions = (
  actionsMap: ReadonlyMap<number, BaseMapsUpdateModalAction | undefined>,
  baseMaps: BaseMap[],
): BaseMapsUpdateModalActionForMap[] => {
  return baseMaps.map(map => {
    const action = actionsMap.get(map.id);
    if (!action) {
      throw Error(`Unexpected unfilled action for map id ${map.id}`);
    }

    return {
      baseMap: map,
      action,
    };
  });
};
