export const convertStringDateToUnixTimestamp = (date: string) => {
  const parsedDate = Date.parse(date);
  return Math.floor(parsedDate / 1000);
};

export const getUserNameForIntercom = (firstName: string, lastName: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  // Spaces in the first name are replaced by ' ' (NBSP) for Intercom to detect the whole first name as the first name.
  return `${firstName.replaceAll(/\s/g, ' ')} ${lastName}`;
};
