import { useCallback } from 'react';
import { ModalType } from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';
import { type SpreadsheetColumnId } from '../../types/spreadsheetData/spreadsheetColumn';

type OpenChooseColumnModalResults = {
  selectedSpreadsheetColumnId: SpreadsheetColumnId;
  displayRowIdIfColumnEmpty: boolean;
};

export const useChooseColumnModal = () => {
  const { openModal: openChooseSpreadsheetColumnModal, closeModal: closeChooseSpreadsheetColumnModal } = useModal(ModalType.ChooseSpreadsheetColumn);

  const openModal = useCallback(
    (showDisplayRowIdIfColumnEmptyCheckbox: boolean, initialColumnValue?: SpreadsheetColumnId):
    Promise<OpenChooseColumnModalResults> => new Promise((resolve, reject) => {
      openChooseSpreadsheetColumnModal({
        showDisplayRowIdIfColumnEmptyCheckbox,
        initialColumnValue,
        onClose: () => {
          closeChooseSpreadsheetColumnModal();
          reject();
        },
        onSubmit: (selectedSpreadsheetColumnId, displayRowIdIfColumnEmpty) => {
          resolve({
            selectedSpreadsheetColumnId,
            displayRowIdIfColumnEmpty,
          });
          closeChooseSpreadsheetColumnModal();
        },
      });
    }), [openChooseSpreadsheetColumnModal, closeChooseSpreadsheetColumnModal]);

  return {
    openModal,
  };
};
