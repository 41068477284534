import { useCallback } from 'react';
import {
  type PermanentConfirmSettings,
  PermanentConfirmStrategy,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { noop } from '../../../_shared/utils/function.helpers';
import { useTranslation } from '../../../_shared/utils/hooks';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { ModalType } from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useBulkDelete } from './bulkDelete/useBulkDelete';
import { useProcessMarkersForRouting } from './optimizeRouteBetweenPoints/useProcessMarkersForRouting';
import { type RowsPerSpreadsheetWithBasicData } from './rowsPerSpreadsheet';
import { useAffectedMarkersInvisibleWarning } from './useAffectedMarkersInvisibleWarning';
import { useGetMarkersInArea } from './useGetMarkersInArea';

const bulkDeletePermanentConfirmSettings: PermanentConfirmSettings = {
  id: 'lassoToolBulkDelete',
  strategy: PermanentConfirmStrategy.Session,
};

export const useLassoActions = () => {
  const { getMarkersInArea } = useGetMarkersInArea();
  const { openModal: openBulkEditLocationsModal } = useModal(ModalType.BulkEditLocationsData);
  const { openModal: openExportDataModal } = useModal(ModalType.ExportLassoData);
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);
  const { openModal: openExportContainedBoundaryDataModal } = useModal(ModalType.ExportContainedBoundaryData);
  const clientId = useClientIdSelector();
  const {
    processSelectedData: processSelectedDataForOptimizeRoute,
    loading: optimizeRoutesLoading,
  } = useProcessMarkersForRouting();
  const { confirmAllMarkersVisible } = useAffectedMarkersInvisibleWarning();
  const [t] = useTranslation();

  const bulkDelete = useBulkDelete({ permanentConfirmSettings: bulkDeletePermanentConfirmSettings });
  const { openConfirmationModal: openBulkDeleteConfirmationModal, loading: bulkDeleteLoading } = bulkDelete;

  const showFetchError = useCallback(() => {
    openAppErrorModal({
      type: AppErrorType.General,
      title: t('Failed to fetch data'),
      content: t('Something went wrong, please try again.'),
    });
  }, [openAppErrorModal, t]);

  const showNoLocationsError = useCallback(() => {
    openAppErrorModal({
      type: AppErrorType.General,
      title: t('No Markers Selected'),
      content: t('There are no markers in the selected area.'),
    });
  }, [openAppErrorModal, t]);

  const createLassoActions = useCallback((polygon: Polygon) => {
    if (!clientId) {
      return {
        openExportContainedBoundaryDataModal: noop,
        openExportDataModal: noop,
        openDeleteConfirmationModal: () => Promise.resolve(),
        openEditLocationsModal: () => Promise.resolve(),
        optimizeRoute: () => Promise.resolve(),
      };
    }

    const getSelectedMarkersPromise = getMarkersInArea({
      useMainFilters: true,
      multiPolygons: [[polygon]],
    });

    const startMarkersAction = async (
      markersPromise: Promise<ReadonlyArray<RowsPerSpreadsheetWithBasicData>>,
      action: (markers: ReadonlyArray<RowsPerSpreadsheetWithBasicData>) => Promise<void> | void,
      confirmAllAffectedMarkersAreVisible: boolean
    ) => {
      const markers = await markersPromise;
      if (markers.length === 0) {
        showNoLocationsError();
      }
      else {
        if (confirmAllAffectedMarkersAreVisible) {
          const canProceed = await confirmAllMarkersVisible(markers, true);

          if (!canProceed) {
            return;
          }
        }

        await action(markers);
      }
    };

    return ({
      openExportContainedBoundaryDataModal: () => {
        openExportContainedBoundaryDataModal({ filter: { type: 'polygon', polygon } });
      },
      openExportDataModal: () => {
        openExportDataModal({ polygon });
      },
      openDeleteConfirmationModal: async () => {
        try {
          await startMarkersAction(
            getSelectedMarkersPromise,
            markers => openBulkDeleteConfirmationModal(clientId, markers),
            true,
          );
        }
        catch {
          showFetchError();
          return;
        }
      },
      openEditLocationsModal: async () => {
        try {
          await startMarkersAction(
            getSelectedMarkersPromise,
            markers => openBulkEditLocationsModal({ affectedItems: markers, useMainFilters: true }),
            true
          );
        }
        catch {
          showFetchError();
        }
      },
      optimizeRoute: async () => {
        try {
          await startMarkersAction(
            getSelectedMarkersPromise,
            markers => processSelectedDataForOptimizeRoute(markers),
            false
          );
        }
        catch (e) {
          showFetchError();
        }
      },
    });
  }, [clientId, getMarkersInArea, showNoLocationsError, openExportDataModal, openBulkDeleteConfirmationModal,
    showFetchError, openBulkEditLocationsModal, processSelectedDataForOptimizeRoute, confirmAllMarkersVisible, openExportContainedBoundaryDataModal]);

  return {
    createLassoActions,
    loading: bulkDeleteLoading || optimizeRoutesLoading,
  };
};
