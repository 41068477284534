import {
  useCallback,
  useState,
} from 'react';
import { useIsComponentMountedRef } from '../../../_shared/utils/hooks/useIsComponentMountedRef';
import { getSpreadsheetBulkData } from '../../../spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import {
  createGetSpreadsheetBulkDataNumericParams,
  getValidNumericColumnIds,
} from './useIsNumericColumnCheck.helpers';

export const useIsNumericColumnCheck = () => {
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsComponentMountedRef();

  const checkIsNumeric = useCallback(async (columnId: string | null, spreadsheetId: number | null) => {
    if (clientId === null || spreadsheetId === null || columnId === null || mapId === null) {
      console.error(`Either clientId (${clientId}) or spreadsheetId (${spreadsheetId}) is null when checking column is numeric.`);
      return Promise.reject('Something went wrong, please try to reload or contact our support.');
    }

    const params = [createGetSpreadsheetBulkDataNumericParams(mapId, spreadsheetId, [columnId])];

    setIsLoading(true);
    try {
      const response = await getSpreadsheetBulkData(clientId, { params });
      const responseData = response.data[0];
      if (!responseData) {
        console.error(`Failed to find data asked for in getSpreadsheetBulkData. Response: ${response}`);
        return Promise.reject('Something went wrong, please try to reload or contact our support.');
      }
      const validNumericColumnIds = getValidNumericColumnIds(responseData);

      return Promise.resolve(validNumericColumnIds.includes(columnId));
    }
    catch {
      return Promise.reject('Something went wrong, please try to reload or contact our support.');
    }
    finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [clientId, isMountedRef, mapId]);

  return {
    isLoading,
    checkIsNumeric,
  };
};
