import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import {
  selectFilteredLatLngSpreadsheetData,
  type SpreadsheetLatLngRowData,
} from '../../selectors/spreadsheetDataMemoizedSelectors';
import { type MapSettingsRoute } from './mapSettingsDirections.state';

export const selectRoutesWithVisibleMarkers = createStateSelector(
  [
    state => state.map.mapSettings.data.directions.routes,
    state => selectFilteredLatLngSpreadsheetData(state).visible,
  ],
  (
    routes: ReadonlyArray<MapSettingsRoute>,
    visibleMarkers: SpreadsheetLatLngRowData
  ): ReadonlyArray<MapSettingsRoute> => {
    return routes.map((route) => ({
      ...route,
      waypoints: route.waypoints.map((waypoint) => ({
        ...waypoint,
        markerId: visibleMarkers.getRow({
          spreadsheetId: waypoint.markerId?.spreadsheetId ?? 0,
          rowId: waypoint.markerId?.rowId ?? '',
        }),
      })),
    }));
  });
