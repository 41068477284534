import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD, UNCLUSTER_BELOW_LIMITS,
} from '~/_shared/utils/markers/markers.constants';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import type { AppState } from '~/store/app.store';
import { mapSettingsAreAnyLabelsActiveSelector } from '../makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  mapSettingsMarkerClusterDenseMarkersStateSelector, mapSettingsMarkerUnclusterBelowNStateSelector,
  mapSettingsUnstackMarkerStateSelector,
} from './mapSettingsMarkers.selectors';

export const isMapSettingsUnstackMarkersForcedSelector = createStateSelector([
  mapSettingsMarkerClusterDenseMarkersStateSelector,
  mapSettingsMarkerUnclusterBelowNStateSelector,
  mapSettingsAreAnyLabelsActiveSelector,
], (clusterMarkers, unclusterBelowNLevel, anyLabelsActive) => {
  const activeUnclusterBelowNLevel = clusterMarkers ? unclusterBelowNLevel : null;

  return !!(
    activeUnclusterBelowNLevel
    && UNCLUSTER_BELOW_LIMITS[activeUnclusterBelowNLevel].markers >= UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD
    && !anyLabelsActive
  );
});

export const mapSettingsUnstackMarkerSelector = (state: AppState) => {
  if (isMapSettingsUnstackMarkersForcedSelector(state)) {
    return true;
  }
  return mapSettingsUnstackMarkerStateSelector(state);
};
export const useMapSettingsUnstackMarkerSelector = () => useSelector(mapSettingsUnstackMarkerSelector);
