import { css } from '@emotion/react';
import { type FC } from 'react';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import { CheckboxComponent } from '../../../../../_shared/baseComponents/checkbox';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import {
  type ActiveGroupFilters, type GroupingColumnValues,
  GroupingType,
  type PerSpreadsheetUniqueGroups,
} from '../../../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import {
  getActiveGroupFiltersValueForGroupIdToggle,
  mapSpreadsheetDataGroups,
} from '../../../../../_shared/utils/grouping/grouping.helpers';

export type GroupFilterSettings = Readonly<{
  allGroups: PerSpreadsheetUniqueGroups;
}>;

export type GroupFilterValues = Readonly<{
  activeGroups: ActiveGroupFilters;
  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, group: GroupingColumnValues<1>) => void;
}>;

type GroupFilterProps = GroupFilterSettings & GroupFilterValues;

const lineSize = 45;
const attributeLineStyle = ({ theme }: ThemeProps) => css({
  padding: '0 8px',
  height: lineSize,
  display: 'flex',
  alignItems: 'center',

  '& + &': {
    borderTop: `1px solid ${theme.borderColors.primary}`,
  },
});

const checkboxTextStyle = css({
  marginLeft: 8,
  fontSize: 14,
  lineHeight: 1.15,
});

export const GroupFilterComponent: FC<GroupFilterProps> = props => {
  const theme = useTheme();

  return (
    <>
      {mapSpreadsheetDataGroups(props.allGroups, (group, columnId, spreadsheetId) => {
        let isChecked: boolean;

        if (group.type === GroupingType.Numeric) {
          isChecked = props.activeGroups[spreadsheetId]?.[columnId]?.[GroupingType.Numeric]?.[group.id] === 1;
        }
        else {
          isChecked = props.activeGroups[spreadsheetId]?.[columnId]?.[GroupingType.Text]?.[group.id] === 1;
        }

        return (
          <div
            key={group.id}
            css={attributeLineStyle({ theme })}
          >
            <CheckboxComponent
              isChecked={isChecked}
              textStyle={checkboxTextStyle}
              checkedSetter={() => {
                const groupingColumn = {
                  spreadsheetId,
                  columnId,
                };

                const newValues = getActiveGroupFiltersValueForGroupIdToggle(props.activeGroups, groupingColumn, group);
                props.onFilterChange(groupingColumn, newValues);
              }}
              text={(
                <LimitNumberOfLinesComponent maxNumberOfLines={2}>
                  {group.name}
                </LimitNumberOfLinesComponent>
              )}
            />
          </div>
        );
      })}
    </>
  );
};
