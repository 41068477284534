export const FRONTEND_STATE_DIRECTIONS_ADD_EMPTY_WAYPOINT = 'FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINT';
export const FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT = 'FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT';
export const FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS = 'FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS';
export const FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS = 'FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS';
export const FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS = 'FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS';
export const FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS = 'FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS';
export const FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT = 'FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT';
export const FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA = 'FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA';
export const FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED = 'FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED';

export const FRONTEND_STATE_ROUTE_UI_DATA_LOADED = 'FRONTEND_STATE_ROUTE_UI_DATA_LOADED';
export const FRONTEND_STATE_ROUTE_LEG_EXPANDED = 'FRONTEND_STATE_ROUTE_LEG_EXPANDED';
export const FRONTEND_STATE_ROUTE_LEG_COLLAPSED = 'FRONTEND_STATE_ROUTE_LEG_COLLAPSED';
export const FRONTEND_STATE_ROUTE_EXPANDED = 'FRONTEND_STATE_ROUTE_EXPANDED';
export const FRONTEND_STATE_ROUTE_COLLAPSED = 'FRONTEND_STATE_ROUTE_COLLAPSED';
