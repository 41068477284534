import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { MapPrivacyLevel } from '~/_shared/types/map';
import { copy } from '~/_shared/utils/collections/collections';
import { getAbsolutePresentationalMapUrl } from '~/map/map.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  useMapInfoPrivacySelector, useSharedMapIdSelector,
} from '~/store/mapInfo/mapInfo.selectors';
import { useManageMapPrivacy } from '../mapPrivacySelector/useManageMapPrivacy.hook';
import {
  EmbedMapModalComponent, EmbedMapSection,
} from './embedMapModal.component';

const getEmbedMapLink = (sharedMapId: string,) => {
  const absoluteUrl = getAbsolutePresentationalMapUrl(sharedMapId);
  return `<iframe src="${absoluteUrl}" frameborder="0" width="700" height="500" allow="geolocation"></iframe>`;
};

type EmbedMapModalContainerProps = ModalProps;

export const EmbedMapModalContainer: FC<EmbedMapModalContainerProps> = (props) => {
  const sharedMapId = useSharedMapIdSelector();
  const mapPrivacyInfo = useMapInfoPrivacySelector();
  const [firstRenderDone, setFirstRenderDone] = useState(false);

  const [expandedSections, setExpandedSections] = useState<ReadonlySet<EmbedMapSection>>(new Set());

  const mapPrivacyManagerProps = useMemo(() => ({
    mapPrivacyInfo,
    onSuccess: (newPrivacyLevel: MapPrivacyLevel) => {
      if (newPrivacyLevel !== MapPrivacyLevel.Private) {
        setExpandedSections(new Set([EmbedMapSection.EmbedCode]));
      }
    },
  }), [mapPrivacyInfo]);

  const mapPrivacyManager = useManageMapPrivacy(mapPrivacyManagerProps);

  const isMapPrivacyValidForEmbedding = useMemo(() => (
    mapPrivacyInfo && (mapPrivacyInfo.mapPrivacyLevel !== MapPrivacyLevel.Private)
  ), [mapPrivacyInfo]);

  const embedMapLink = useMemo(() => sharedMapId ? (
    getEmbedMapLink(sharedMapId)
  ) : '', [sharedMapId]);

  const handleToggleSection = useCallback(((section: EmbedMapSection) => {
    if (section === EmbedMapSection.EmbedCode && !isMapPrivacyValidForEmbedding) {
      return;
    }
    const newExpandedSections = copy.andTogglePresence(expandedSections, section);
    if (newExpandedSections.size) {
      setExpandedSections(copy.andTogglePresence(expandedSections, section));
    }
  }), [expandedSections, isMapPrivacyValidForEmbedding]);

  useEffect(() => {
    if (mapPrivacyManager.isFormValid) {
      mapPrivacyManager.onSave();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPrivacyManager.isFormValid]);

  useEffect(() => {
    setExpandedSections(new Set());
  }, []);

  useEffect(() => {
    if (!firstRenderDone) {
      setFirstRenderDone(true);
      setExpandedSections(new Set(
        isMapPrivacyValidForEmbedding ? [EmbedMapSection.EmbedCode] : [EmbedMapSection.PrivacyLevel]
      ));
    }
  }, [firstRenderDone, isMapPrivacyValidForEmbedding]);

  return (
    <EmbedMapModalComponent
      {...props}
      embedMapLink={embedMapLink}
      isLoading={mapPrivacyManager.isLoading || !mapPrivacyInfo || !embedMapLink}
      isFormValid={mapPrivacyManager.isFormValid}
      manageMapPrivacyProps={mapPrivacyManager}
      expandedSections={expandedSections}
      onToggleSection={handleToggleSection}
    />
  );
};
