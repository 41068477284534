import {
  type FC, memo, type ReactNode,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { type MapObjectZIndex } from '../mapObject.types';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { type MapShapeInstance } from './mapShapeModel';
import { MapShapeContextProvider } from './private/mapShapeContext';
import { MapShapeLines } from './private/mapShapeLines.component';

type MapShapeProps = {
  shape: MapShapeInstance;
  zIndex: MapObjectZIndex;
  isPolygon?: boolean;
  renderOutline?: (key: string, outline: MapObjectOutlineInstance) => ReactNode;
  renderLine?: (key: string, start: LatLng, end: LatLng) => ReactNode;
  renderPath?: (key: string, points: ReadonlyArray<LatLng>) => ReactNode;
  renderRemoveButton?: () => ReactNode;
  renderPolygon?: () => ReactNode;
};

const MapShape: FC<MapShapeProps> = (props) => {
  const { shape, zIndex, isPolygon = false } = props;
  const { renderOutline, renderLine, renderPath, renderRemoveButton, renderPolygon } = props;

  const manager = useMapObjectManager();

  return (
    <MapObjectContextProvider value={{ manager, zIndex }}>
      <MapShapeContextProvider value={{ shape, isPolygon }}>
        {renderOutline && shape.outlines.map(outline => renderOutline(outline.id, outline))}
        {renderLine && (
          <MapShapeLines
            renderLine={renderLine}
          />
        )}
        {!renderLine && renderPath?.(`path-${shape.id}`, shape.outlines)}
        {renderRemoveButton?.()}
        {isPolygon && renderPolygon?.()}
      </MapShapeContextProvider>
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(MapShape);
export { pureComponent as MapShape };
