import { css } from '@emotion/react';
import { type FC } from 'react';
import { AnchorLink } from '~/_shared/baseComponents/links';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { LINK_TO_V4_ACCOUNT_SETTINGS } from '~/_shared/constants/maptive';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  s, Trans,
} from '~/translations/Trans';

const subscriptionNotAvailableWrapperStyle = css({
  cursor: 'default',
  padding: '16px 32px',
});

export const SubscriptionManagementNotAvailableBodyComponent: FC = _ => (
  <Trans i18nKey="SubscriptionManagement.Unavailable">
    {s('Please use the ')}
    <AnchorLink
      href={LINK_TO_V4_ACCOUNT_SETTINGS}
      target="_blank"
    >
      {s('previous')}
    </AnchorLink>
    {s(' version of Maptive to make any subscription related changes, as this functionality is temporarily unavailable in the current version you are using.')}
  </Trans>
);

export const SubscriptionManagementUnavailableModalComponent: FC<ModalProps> = props => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      caption={t('Subscription Management Unavailable')}
      contentStyle={subscriptionNotAvailableWrapperStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      skipCloseOnMapIdChange
    >
      <SubscriptionManagementNotAvailableBodyComponent />
    </ModalComponent>
  );
};
