import { useMemo } from 'react';
import {
  dummyMapObjectZindex, mapObjectEntities, type MapObjectZIndex,
} from '../map/mapObjects/mapObject.types';
import { useZIndexSeries } from './useZIndexSeries.hook';
import { ZIndexedEntity } from './zIndexRanges';

const idMapper = (entity: string) => entity;

export const useBoundaryDragEditPolygonZIndex = (instanceId?: Uuid): MapObjectZIndex => {
  const orderedEntities = useMemo(() => instanceId ? [instanceId] : [], [instanceId]);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.BoundaryDrawPolygon,
  }), [orderedEntities]);

  return instanceId ? entities.subEntityZIndexes[0] : dummyMapObjectZindex;
};

export const useBoundaryDrawPolygonZIndex = (): MapObjectZIndex => {
  const orderedEntities = useMemo(() => ['boundaryDrawPolygon'], []);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.BoundaryDrawPolygon,
  }), [orderedEntities]).subEntityZIndexes[0];

  return entities;
};

export const useBoundarySelectionLassoInstanceZIndex = (): MapObjectZIndex => {
  const orderedEntities = useMemo(() => ['boundarySelectionLasso'], []);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.BoundarySelectionLasso,
  }), [orderedEntities]).subEntityZIndexes[0];

  return entities;
};
