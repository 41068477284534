import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { getMapUrl } from '~/map/map.helpers';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { frontendStateSearchHide } from '~/store/frontendState/mapTools/search/search.actionCreators';
import {
  hideMobileTopBar,
  showMobileTopBar,
} from '~/store/frontendState/mobileUI/mobileUI.actionCreators';
import { useMobileTopBarOpenSelector } from '~/store/frontendState/mobileUI/mobileUI.selectors';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useActiveMobileNavigationItem } from './mobileNavigation/useActiveMobileNavigationItem';
import { MobileTopBarComponent } from './mobileTopBar.component';

export const MobileTopBarContainer: FC = () => {
  const dispatch = useDispatch();
  const mapInfo = useMapInfoDataSelector();
  const clientName = useSelector(state => state.userData.clientName);
  const mapViewName = useSelector(state => state.map.mapInfo.data?.name ?? undefined);
  const parentMapName = useSelector(state => state.map.mapInfo.data?.parentMap?.name ?? undefined);
  const parentMapId = mapInfo?.parentMap?.id;
  const mapLink = parentMapId ? getMapUrl(parentMapId) : null;
  const activeNavigationItem = useActiveMobileNavigationItem();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const location = useLocation();
  const mobileTopBarOpenSelector = useMobileTopBarOpenSelector();
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();

  const hasAvailableTools = useMemo(() => (
    !!(!isMapPresentational || publicMapSettings?.displaySearchBox
        || publicMapSettings?.groupingTool || publicMapSettings?.filterTool
        || publicMapSettings?.radiusProximity || publicMapSettings?.routingDirections
        || publicMapSettings?.locationList || publicMapSettings?.locationFinder)
  ), [isMapPresentational, publicMapSettings]);

  const hideSearchBar = useCallback(() => {
    dispatch(frontendStateSearchHide());
  }, [dispatch]);

  const toggleTopBar = useCallback((isOpen: boolean) => {
    if (isOpen && isMobileScreen) {
      dispatch(showMobileTopBar());
    }
    else {
      dispatch(hideMobileTopBar());
    }
  }, [dispatch, isMobileScreen]);

  useEffect(() => {
    hideSearchBar();
    dispatch(hideMobileTopBar());
  }, [location.pathname, hideSearchBar, dispatch]);

  useEffect(() => {
    if (isMapPresentational && publicMapSettings?.mapToolsMenuOpen && hasAvailableTools) {
      toggleTopBar(true);
    }
  }, [hasAvailableTools, isMapPresentational, publicMapSettings?.mapToolsMenuOpen, toggleTopBar]);

  return (
    <MobileTopBarComponent
      accountName={clientName ?? undefined}
      activeNavigationItem={activeNavigationItem}
      hasAvailableTools={hasAvailableTools}
      hideSearchBar={hideSearchBar}
      isMapPresentational={isMapPresentational}
      key={location.pathname}
      mapLink={mapLink ?? undefined}
      mapName={parentMapName ?? mapViewName}
      mapViewName={parentMapName && !exportImageSettings?.mode ? mapViewName : undefined}
      publicMapSettings={isMapPresentational ? publicMapSettings : undefined}
      toggleTopBar={toggleTopBar}
      topUnderbarOpen={mobileTopBarOpenSelector}
    />
  );
};
