import { useMemo } from 'react';
import {
  type BoundaryTerritoryFilterGroups,
  type BoundaryTerritoryFilterRequest,
} from '~/spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  type BoundaryTerritoryGroupId,
  type BoundaryTerritoryId,
} from '../../../store/boundaries/boundaryIdentifier.type';
import {
  createRequestFromBoundaryTerritoryGroupSettings,
  getBoundaryTerritoryGroupMatchupRequest,
} from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { useBoundaryTerritoryGroupsSelector } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useBoundaryMetrics } from './useBoundaryMetrics';
import { useMetricsData } from './useMetricsData';

export const useBoundaryTerritoryMetricsData = (
  boundaryTerritoryId: BoundaryTerritoryId,
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId,
  ignoreFilters?: boolean
) => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();

  const boundaryTerritoryGroup = useMemo(() => {
    return boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === boundaryTerritoryGroupId);
  }, [boundaryTerritoryGroups, boundaryTerritoryGroupId]);

  const selectedMetrics = useBoundaryMetrics(boundaryTerritoryGroup);

  const boundaryTerritoryFilterRequest: BoundaryTerritoryFilterRequest | null = useMemo(() => {
    if (!boundaryTerritoryGroup) {
      return null;
    }

    const boundaryTerritoryFilterGroup: BoundaryTerritoryFilterGroups = {
      type: 'or',
      boundary_territories: [boundaryTerritoryId],
      definition: {
        boundary_territory_group_id: boundaryTerritoryGroup.boundaryTerritoryGroupId,
        boundary_group_id: boundaryTerritoryGroup.boundaryGroupId,
        matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
        settings: createRequestFromBoundaryTerritoryGroupSettings(boundaryTerritoryGroup.settings),
      },
    };

    return {
      type: 'or',
      boundary_territory_groups: [boundaryTerritoryFilterGroup],
    };
  }, [boundaryTerritoryGroup, boundaryTerritoryId]);

  return useMetricsData({
    selectedMetrics,
    boundaryTerritoryFilterRequest: boundaryTerritoryFilterRequest ?? undefined,
    ignoreFilterTree: ignoreFilters,
  });
};
