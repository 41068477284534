import { useMemo } from 'react';
import {
  calculateZIndex, Z_INDEXES_PER_BOUNDARY_GROUP,
  ZIndexedEntity,
} from '~/map/zIndexes/zIndexRanges';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryZIndexes } from '../../mapBoundary.manager';

export const useMapBoundaryZIndexes = () => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();

  return useMemo(() => {
    const result: {
      [boundaryGroupId: number]: BoundaryZIndexes | undefined;
      primaryBoundary: BoundaryZIndexes;
      activeBoundary: Pick<BoundaryZIndexes, 'border' | 'polygonOutline' | 'polygonOutlineBorder'>;
      highlightedBoundary: Pick<BoundaryZIndexes, 'border' | 'polygonOutline' | 'polygonOutlineBorder'>;
    } = {
      primaryBoundary: {
        polygon: calculateZIndex(0, ZIndexedEntity.PrimaryBoundaryPolygon),
        border: calculateZIndex(1 * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.PrimaryBoundaryPolygon),

        label: calculateZIndex(0, ZIndexedEntity.PrimaryBoundaryLabel),
      },
      highlightedBoundary: {
        border: calculateZIndex(0, ZIndexedEntity.BoundaryHoverPolygonBorder), // highlight is below active

        polygonOutline: calculateZIndex(0, ZIndexedEntity.BoundaryOutlinePolygon),
        polygonOutlineBorder: calculateZIndex(1 * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryOutlinePolygon),
      },
      activeBoundary: {
        border: calculateZIndex(1 * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryHoverPolygonBorder), // active is above highlight

        polygonOutline: calculateZIndex(0, ZIndexedEntity.BoundaryOutlinePolygon),
        polygonOutlineBorder: calculateZIndex(1 * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryOutlinePolygon),
      },
    };

    boundaryTerritoryGroups.forEach((group, i) => {
      result[group.boundaryGroupId] = {
        polygon: calculateZIndex((i * 2) * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryPolygon),
        border: calculateZIndex(((i * 2) + 1) * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryPolygon),

        label: calculateZIndex((i * 2) * Z_INDEXES_PER_BOUNDARY_GROUP, ZIndexedEntity.BoundaryLabel),
      };
    });

    return result;
  }, [boundaryTerritoryGroups]);
};

// Solves rare problem when two boundaries from the same group would have overlapping
// label bounds. Solution is not perfect, zIndex is still not unique, but given this is
// a rare occurrence, it should be sufficient.
export const spreadBoundaryGroupZIndex = (entityZIndex: number, boundaryId: number) =>
  entityZIndex + (boundaryId % (Z_INDEXES_PER_BOUNDARY_GROUP));
export const spreadBoundaryGroupLabelZIndex = (entityZIndex: number, boundaryId: number, type: 'callout' | 'text') =>
  entityZIndex + ((boundaryId * 2) % (2 * Z_INDEXES_PER_BOUNDARY_GROUP)) + (type === 'text' ? 1 : 0);
