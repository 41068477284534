import { createAction } from '@reduxjs/toolkit';
import type { Page } from '~/_shared/constants/routes';
import type { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import type { SidebarApp } from '~/sidebar/sidebarApp.enum';
import type { PageWithMapTools } from '~/store/frontendState/leftSidebar/leftSidebar.state';

export const openLeftSidebarApp = createAction('FRONTEND_STATE-LEFT_SIDEBAR-APP_OPENED',
  (app: SidebarApp, sourcePage: Page) => ({
    type: 'FRONTEND_STATE-LEFT_SIDEBAR-APP_OPENED',
    payload: {
      app,
      sourcePage,
    },
  })
);

export const closeLeftSidebar = createAction('FRONTEND_STATE-LEFT_SIDEBAR-CLOSED');

export const openMapTool = createAction(
  'FRONTEND_STATE-LEFT_SIDEBAR-MAP_TOOL-OPENED',
  (tool: MapTool) => ({
    payload: {
      tool,
    },
  })
);

export const openMapToolExclusively = createAction(
  'FRONTEND_STATE-LEFT_SIDEBAR-MAP_TOOL-OPENED_EXCLUSIVELY',
  (tool: MapTool) => ({
    payload: { tool },
  })
);

export const clearLastOpenedMapPageTool = createAction(
  'FRONTEND_STATE-LEFT_SIDEBAR-LAST_MAP_PAGE_TOOL-CLEAR',
  (page: PageWithMapTools) => ({
    payload: { page },
  })
);

export const closeMapTool = createAction('FRONTEND_STATE-LEFT_SIDEBAR-MAP_TOOL-CLOSED');
