import { css } from '@emotion/react';
import {
  type FC, useMemo, useState,
} from 'react';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  BoundaryTerritoryType, type CalculateBucketFunctionNumeric,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { ActiveBoundaryFillEditComponent } from '../../activeBoundary/edit/activeBoundaryFillEditComponent';
import { useActiveBoundaryFillEdit } from '../../activeBoundary/edit/useActiveBoundaryFillEdit';
import { BoundarySelectedOption } from '../../fill/boundaryFill.enums';
import { LoadBoundaryPaneSubmitButtonComponent } from '../loadBoundaryPaneSubmitButton/boundaryCreateSubmitButton.component';

export type LoadGlobalBoundaryGroupPaneComponentProps = Readonly<{
  globalBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  spreadsheetColumnOptions: DropdownOption<SpreadsheetColumnId>[];
  isBoundaryGroupUsedOnMap: (boundaryGroupId: BoundaryGroupId) => boolean;
  onAddBoundary: (data: LoadGlobalBoundaryGroupData) => void;
}>;

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const dropdownTriggerStyle = ({ theme, isPlaceholder }: ThemeProps<{ isPlaceholder: boolean }>) => css({
  color: isPlaceholder ? theme.textColors.disabled : undefined,
  height: 40,
});

const addBoundaryTooltipStyle = css({
  display: 'block',
});

export type LoadGlobalBoundaryGroupData = Readonly<{
  boundaryGroupId: number;
  boundaryTerritoryType: BoundaryTerritoryType;
  boundaryFillData: SpreadsheetColumnId | null;
  demographicId: string | null;
  numericCalculateBucketFunction: CalculateBucketFunctionNumeric | null;
}>;

export const LoadGlobalBoundaryGroupPaneComponent: FC<LoadGlobalBoundaryGroupPaneComponentProps> = (props) => {
  const [boundaryGroupId, setBoundaryGroupId] = useState<BoundaryGroupId | null>(null);

  const {
    isUpdateDisabled, boundaryFillModel, ...boundaryFillProps
  } = useActiveBoundaryFillEdit({
    boundaryGroupId,
    initialBoundaryTerritoryType: BoundaryTerritoryType.Manual,
  });

  const boundaryTerritoryType = boundaryFillModel.boundaryTerritoryType;

  const { isBoundaryGroupUsedOnMap } = props;

  const [t] = useTranslation();
  const theme = useTheme();

  const globalBoundaryGroupsListOptions: DropdownOption<number>[] = useMemo(() =>
    props.globalBoundaryGroups.map(item => ({
      name: item.name,
      value: item.id,
    })), [props.globalBoundaryGroups]);

  const isBoundaryGroupAlreadyUsed = useMemo(() =>
    boundaryGroupId === null ? false : isBoundaryGroupUsedOnMap(boundaryGroupId),
  [isBoundaryGroupUsedOnMap, boundaryGroupId]);

  const isAddDisabled =
    boundaryGroupId === null ||
    boundaryTerritoryType === null ||
    isBoundaryGroupAlreadyUsed ||
    isUpdateDisabled;

  const addGlobalBoundaryGroupsDisabledReason = isBoundaryGroupAlreadyUsed
    ? t('Boundary Group is already loaded')
    : boundaryFillProps.updateDisabledReason;

  return (
    <div>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>2. {t(BoundarySelectedOption.SelectBoundaryType)}</div>
        <RegularDropdownComponent
          onChange={setBoundaryGroupId}
          options={globalBoundaryGroupsListOptions}
          placeholder={t(BoundarySelectedOption.SelectBoundaryType)}
          triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: boundaryGroupId === null })}
          value={boundaryGroupId}
          inPortal
        />
      </div>

      {boundaryGroupId !== null && (
        <ActiveBoundaryFillEditComponent
          labelCountStart={3}
          {...boundaryFillProps}
          {...boundaryFillModel}
        />
      )}

      <TooltipComponent
        css={addBoundaryTooltipStyle}
        tooltipContent={addGlobalBoundaryGroupsDisabledReason}
        placement="bottom"
      >
        <LoadBoundaryPaneSubmitButtonComponent
          text={t('Load Boundaries')}
          onClick={isAddDisabled ? undefined : () => props.onAddBoundary({
            boundaryGroupId,
            boundaryTerritoryType,
            boundaryFillData: boundaryFillModel.boundaryFillData,
            demographicId: boundaryFillModel.selectedDemographicId,
            numericCalculateBucketFunction: boundaryFillModel.numericCalculateBucketFunction,
          })}
          isDisabled={isAddDisabled}
        />
      </TooltipComponent>
    </div>
  );
};
