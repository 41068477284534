import { css } from '@emotion/react';
import { type FC } from 'react';

type FloatingBoxContainer = Readonly<{
  className?: string;
  children: React.ReactNode;
}>;

const wrapperStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
});

export const FloatingBoxContainer: FC<FloatingBoxContainer> = (props) => {
  return (
    <div
      css={wrapperStyle}
      className={props.className}
    >
      {props.children}
    </div>
  );
};
