import {
  type FC, memo, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE, DrawingTool,
} from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { useFileAttachmentUrl } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemImage } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemSizeForCurrentZoom } from '../hooks/useDrawingToolItemSizeForCurrentZoom';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolImageInstanceMarkerContainer } from './drawingToolImageInstanceMarker.container';
import { getTemplateFromFile } from './drawingToolImages.helpers';

const SCALING_MIN_VISIBLE_SIZE = 10;

type MapImageInstanceProps = {
  instance: DrawingItemImage;
};

const DrawingToolImageInstanceContainer: FC<MapImageInstanceProps> = ({ instance }) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut, onMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();

  const templateFile = useFileAttachmentUrl(instance.attachmentId);
  const markerTemplate = useMemo(() => templateFile ? getTemplateFromFile(templateFile) : null, [templateFile]);

  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);
  const size = useDrawingToolItemSizeForCurrentZoom({
    itemSize: instance.settings.imageSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.to,
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
  });

  const onImageClick = useCallback((id: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      id,
      {
        type: DrawingTool.Image,
        value: { ...instance },
      })
    );
  }, [drawingEventsEnabledRef, dispatch, instance]);

  const onImageRightClick = useCallback((_id: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Image,
      value: instance,
    });
  }, [onRightClick, instance, drawingEventsEnabledRef]);

  if (size === null || markerTemplate === null || size < SCALING_MIN_VISIBLE_SIZE) {
    return null;
  }

  return (
    <DrawingToolImageInstanceMarkerContainer
      markerTemplate={markerTemplate}
      size={size}
      zIndex={zIndex}
      instance={instance}
      onClick={onImageClick}
      onRightClick={onImageRightClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    />
  );
};

const pureComponent = memo(DrawingToolImageInstanceContainer);
export { pureComponent as DrawingToolImageInstanceContainer };
