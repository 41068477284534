import { css } from '@emotion/react';
import {
  faFlag, faLocationArrow,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputWithSuffixComponent } from '~/_shared/baseComponents/inputs';
import { OverlayLoaderComponent } from '../../../../_shared/components/overlay/overlayLoader.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ProximityPanelErrorComponent } from './proximityPanelError.component';
import { ProximityPanelInputLabelComponent } from './proximityPanelInputLabel.component';

type ProximityPanelGeolocationInputProps = Readonly<{
  value: string;
  onChange: (newValue: string) => void;
  isLoading?: boolean;
  hasError?: boolean;
  onGetUserLocation: () => void;
}>;

const getUserLocalizationButtonStyle = ({ theme }: ThemeProps) => css({
  padding: '0 8px',
  width: '100%',
  height: '100%',
  background: 'none',
  border: 'none',
  color: 'inherit',
  transition: 'color .15s',
  '&:hover': {
    color: theme.textColors.link,
  },
});

const inputSuffixIconStyle = css({
  fontSize: '20px',
});

const loaderContainerStyle = css({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const ProximityPanelGeolocationInputComponent: FC<ProximityPanelGeolocationInputProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <>
      <ProximityPanelInputLabelComponent>{t('Starting Location')}</ProximityPanelInputLabelComponent>
      <TextInputWithSuffixComponent
        value={props.value}
        onChange={props.onChange}
        isReadOnly={props.isLoading}
        icon={faFlag}
        suffix={(
          <div css={loaderContainerStyle}>
            <button
              css={getUserLocalizationButtonStyle({ theme })}
              onClick={props.onGetUserLocation}
            >
              <FontAwesomeIcon
                icon={faLocationArrow}
                css={inputSuffixIconStyle}
              />
            </button>
            {props.isLoading && (
              <OverlayLoaderComponent
                loaderSize={30}
              />
            )}
          </div>
        )}
      />
      <ProximityPanelErrorComponent isDisplayed={!!props.hasError}>
        {t('An error occurred while getting the location')}
      </ProximityPanelErrorComponent>
    </>
  );
};
