import { useSelector } from 'react-redux';
import { type AppState } from '~/store/app.store';
import { primarySpreadsheetRealSpreadsheetsSelector } from '~/store/selectors/usePrimarySpreadsheetRealSpreadsheets';

const geocodingStateSelector = (state: AppState) => state.spreadsheet.geocoding;
export const useGeocodingState = () => useSelector(geocodingStateSelector);

const isGoogleScriptInitializedSelector = (state: AppState) => state.frontendState.processing.isGoogleScriptInitialized;
export const useIsGoogleScriptInitialized = () => useSelector(isGoogleScriptInitializedSelector);

export const currentSpreadsheetGeocodingStateSelector = (state: AppState) => {
  const realSpreadsheetIds = primarySpreadsheetRealSpreadsheetsSelector(state);
  const geocodingState = geocodingStateSelector(state);
  const currentSpreadsheetId = realSpreadsheetIds?.[0]?.id;

  return currentSpreadsheetId
    ? geocodingState.perSpreadsheetGeocodingRequest[currentSpreadsheetId]
    : null;
};
export const useCurrentSpreadsheetGeocodingState = () => useSelector(currentSpreadsheetGeocodingStateSelector);

export const isGeocodingRunningOrRequiredSelector = (state: AppState) => {
  const currentSpreadsheetGeocodingState = currentSpreadsheetGeocodingStateSelector(state);
  return !!currentSpreadsheetGeocodingState?.inProgress || !!currentSpreadsheetGeocodingState?.isRequired;
};
export const useIsGeocodingRunningOrRequired = () => useSelector(isGeocodingRunningOrRequiredSelector);
