export type PerSpreadsheet<T> = {
  // prevents assignment of incompatible types into this type
  // otherwise all types fits into indexer type that returns undefined
  // perSpreadsheetMap: true;
  [spreadsheetId: number]: T;
};

export type ReadonlyPerSpreadsheet<T> = PerSpreadsheet<T> & {
  readonly [spreadsheetId: number]: T;
};

export type PerSpreadsheetFilteredRowsIdsMap = PerSpreadsheet<{[rowId: string]: 1}>;
export type ReadonlyPerSpreadsheetFilteredRowsIdsMap = ReadonlyPerSpreadsheet<{ readonly [rowId: string]: 1}>;

export const newPerSpreadsheetMap = () => ({
  // perSpreadsheetMap: true,
} as const);
