import { copy } from '~/_shared/utils/collections/collections';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  type MapShapeInstance, pathToMapShapeOutlines,
} from '~/map/map/mapObjects/mapShape/mapShapeModel';
import { type BoundaryDrawAction } from './boundaryDraw.action';
import {
  BOUNDARY_DRAW_ADD_SHAPE,
  BOUNDARY_DRAW_CLOSE_MODAL,
  BOUNDARY_DRAW_DEACTIVATE,
  BOUNDARY_DRAW_EDIT_ACTIVATE,
  BOUNDARY_DRAW_EDIT_OPEN_MODAL,
  BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL,
  BOUNDARY_DRAW_RESET_SHAPES,
  BOUNDARY_DRAW_SET_DRAW_TYPE,
  BOUNDARY_DRAW_UPDATE_SHAPE,
} from './boundaryDraw.actionTypes';
import {
  type BoundaryDrawInactiveState, BoundaryDrawMode, type BoundaryDrawState, DrawType,
} from './boundaryDraw.state';

const initialState: BoundaryDrawInactiveState = {
  isModalOpen: false,
  isActive: false,
  boundaryGroupId: null,
  drawType: null,
  mapShapes: null,
  initialShapes: null,
};

export const boundaryDrawReducer = (state: BoundaryDrawState = initialState, action: BoundaryDrawAction): BoundaryDrawState => {
  switch (action.type) {
    case BOUNDARY_DRAW_CLOSE_MODAL: {
      return initialState;
    }

    case BOUNDARY_DRAW_DEACTIVATE:
      return {
        ...state,
        isActive: false,
      };

    case BOUNDARY_DRAW_EDIT_ACTIVATE: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        isActive: true,
      };
    }

    case BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL: {
      const mapShapes: ReadonlyArray<MapShapeInstance> = action.payload.paths.map(
        path => ({ id: createUuid(), outlines: pathToMapShapeOutlines(path) }));

      return {
        isModalOpen: true,
        isActive: false,
        boundaryGroupId: action.payload.boundaryGroupId,
        drawType: mapShapes.length ? DrawType.Drag : DrawType.Draw,
        drawModeData: {
          type: BoundaryDrawMode.BoundaryPolygonEdit,
          boundaryId: action.payload.boundaryId,
          boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
        },
        mapShapes,
        initialShapes: mapShapes,
        disabledReason: action.payload.disabledReason,
      };
    }

    case BOUNDARY_DRAW_EDIT_OPEN_MODAL: {
      const mapShapes = [] as const;

      return {
        isModalOpen: true,
        isActive: action.payload.startAsActive ?? false,
        boundaryGroupId: action.payload.boundaryGroupId,
        drawType: DrawType.Draw,
        drawModeData: {
          type: BoundaryDrawMode.BoundaryDrawEdit,
          action: action.payload.action,
          boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
          boundaryId: action.payload.boundaryId,
        },
        mapShapes,
        initialShapes: mapShapes,
      };
    }

    case BOUNDARY_DRAW_UPDATE_SHAPE: {
      const mapShapeIndex = state.mapShapes?.findIndex(shape => shape.id === action.payload.shape.id);

      if (!state.isActive || mapShapeIndex === undefined) {
        return state;
      }

      return {
        ...state,
        mapShapes: copy.andReplace(state.mapShapes, mapShapeIndex, action.payload.shape),
      };
    }

    case BOUNDARY_DRAW_ADD_SHAPE: {
      if (!state.isActive) {
        return state;
      }

      return {
        ...state,
        mapShapes: [...state.mapShapes, action.payload.shape],
        drawType: DrawType.Drag,
      };
    }

    case BOUNDARY_DRAW_RESET_SHAPES: {
      if (!state.isModalOpen) {
        return state;
      }

      const mapShapes = state.initialShapes.map(shape => ({ ...shape, id: createUuid() }));
      return {
        ...state,
        isActive: false,
        mapShapes,
        initialShapes: mapShapes,
        drawType: !mapShapes.length ? DrawType.Draw : state.drawType,
      };
    }

    case BOUNDARY_DRAW_SET_DRAW_TYPE: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        drawType: action.payload.drawType,
      };
    }

    default:
      return state;
  }
};
