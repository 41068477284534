import {
  newPerSpreadsheetMap,
  type PerSpreadsheet,
  type PerSpreadsheetFilteredRowsIdsMap,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import { BoundaryMatchingType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  type BoundaryLocationsFilters, getBoundaryTerritoryGroupLocationsFiltersMemoized,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { type BoundaryLocationsFiltersState } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import { type BoundaryBoundaryTerritoryFilterRequest } from '../filtering/spreadsheetDataFiltering.helpers';
import {
  generateHash,
  type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import { type SpreadsheetFilters } from '../spreadsheetData.state';

const getHashForBoundaryLocationsFilter = (
  spreadsheetIds: ReadonlyArray<number>,
  boundaryLocationsFilter: BoundaryLocationsFilters,
): PerSpreadsheet<string> => {
  const hashList = [
    'boundary',
    ...boundaryLocationsFilter.entries.map(({ boundaryGroupId, filter }) => (
      [boundaryGroupId, ...filter.filteredBoundaries, ...filter.filteredBoundaryTerritories]
    )),
  ];

  return spreadsheetIds.reduce<PerSpreadsheet<string>>((acc, spreadsheetId) => {

    if (hashList.length > 1) {
      acc[spreadsheetId] = generateHash(hashList.join('-'));
    }

    return acc;
  }, newPerSpreadsheetMap());
};

export const getDataRowsForBoundaryLocationsFilter = (params: {
  spreadsheetIds: ReadonlyArray<number>;
  filters: SpreadsheetFilters;
  boundaryLocationsFilters: BoundaryLocationsFilters;
}): PerSpreadsheetFilteredRowsIdsMap => {
  const results: PerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

  const perSpreadsheetFilterHash = getHashForBoundaryLocationsFilter(
    params.spreadsheetIds,
    params.boundaryLocationsFilters,
  );

  Object.keys(perSpreadsheetFilterHash).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const spreadsheetFilterHash = perSpreadsheetFilterHash[spreadsheetId];

    const data = spreadsheetFilterHash && params.filters[spreadsheetId]?.[spreadsheetFilterHash];

    if (!data) {
      // this way we prevent do display all the available data in case the filtering data isn't fetched yet
      results[spreadsheetId] = {};
      return;
    }

    results[spreadsheetId] = data;
  });

  return results;
};

const createBoundaryFilterFilterTreeRequestFromMapSettings = (
  params: BoundaryLocationsFiltersState,
  spreadsheetIds: number[],
): BoundaryBoundaryTerritoryFilterRequest => {

  const boundaryLocationsFilters = getBoundaryTerritoryGroupLocationsFiltersMemoized(params);
  const perSpreadsheetFilterHash = getHashForBoundaryLocationsFilter(
    spreadsheetIds,
    boundaryLocationsFilters,
  );

  const results: BoundaryBoundaryTerritoryFilterRequest = newPerSpreadsheetMap();

  spreadsheetIds.forEach(spreadsheetId => {
    const filterHash = perSpreadsheetFilterHash[spreadsheetId];

    if (filterHash && boundaryLocationsFilters.entries.length > 0) {
      results[spreadsheetId] = {
        filterHash,
        boundaryFilter: {
          type: 'or',
          boundaries: Object.fromEntries(boundaryLocationsFilters.entries.map(entry => [
            entry.boundaryGroupId, {
              boundary_ids: entry.filter.filteredBoundaries,
              matchings: { matching_type: BoundaryMatchingType.Geometry },
            },
          ])),
        },
        // TODO: add boundaryTerritoryFilter entry here and fill it with data from
        // map settings and BoundaryLocationsFiltersState if we wanted to support
        // filteredBoundaryTerritories in the future
      };
    }
  });

  return results;
};

export const getMissingBoudnaryLocationsFilterSpreadsheetData = (
  spreadsheetIds: number[],
  mapSettings: MapSettingsState,
  spreadsheetFilters: SpreadsheetFilters
): MissingSpreadsheetData => {
  const missingSearchSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  const boundaryLocationsFilters = (
    mapSettings.data.toolsState.boundary.locationsFilter
  );
  const request = createBoundaryFilterFilterTreeRequestFromMapSettings(
    boundaryLocationsFilters, spreadsheetIds
  );

  Object.keys(request).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const requestItem = request[spreadsheetId];

    if (requestItem && !spreadsheetFilters[spreadsheetId]?.[requestItem.filterHash]) {
      missingSearchSpreadsheetData.boundaryFilter = {
        ...missingSearchSpreadsheetData.boundaryFilter,
        [spreadsheetId]: {
          ...missingSearchSpreadsheetData.boundaryFilter?.[spreadsheetId],
          [requestItem.filterHash]: {
            boundaryFilter: requestItem.boundaryFilter,
            boundaryTerritoryFilter: requestItem.boundaryTerritoryFilter,
          },
        },
      };
    }
  });

  return missingSearchSpreadsheetData;
};
