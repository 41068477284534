import { css } from '@emotion/react';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useEffect, useState,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { useTranslation } from '../../../_shared/utils/hooks';
import { isTextEmpty } from '../../../_shared/utils/text/text.helpers';

type BoundarySettingsGroupNameProps = Readonly<{
  groupName: string;
  onGroupNameChange: (newName: string) => void;
}>;

const headingStyle = css({
  textTransform: 'uppercase',
});

const wrapperStyle = css({
  padding: '18px 24px 24px',
});

const inputStyle = ({ theme }: ThemeProps) => css({
  marginTop: 5,
  background: theme.backgroundColors.secondary,
});

export const BoundarySettingsGroupNameComponent: FC<BoundarySettingsGroupNameProps> = (props) => {
  const [name, setName] = useState(props.groupName);
  const [t] = useTranslation();
  const theme = useTheme();

  const isSubmitEnabled = props.groupName !== name && !isTextEmpty(name);

  const submitNameChange = () => {
    if (!isSubmitEnabled) {
      return;
    }

    props.onGroupNameChange(name);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    submitNameChange();
  };

  useEffect(() => {
    setName(props.groupName);
  }, [props.groupName]);

  return (
    <form
      onSubmit={onFormSubmit}
      css={wrapperStyle}
    >
      <span css={headingStyle}>{t('Name')}</span>

      <TextInputComponent
        css={inputStyle({ theme })}
        onChange={setName}
        value={name}
        placeholder={t('Group Name')}
        onBlur={submitNameChange}
        icon={faGlobe}
      />
    </form>
  );
};
