import {
  useCallback, useState,
} from 'react';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '~/_shared/components/exportDataModal/exportDataModal.component';
import { prepareAsyncCopyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import {
  BoundaryEditSelectFrom,
  getBoundaryEditSelectFromRadioGroupOptions,
} from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import { useGetBoundaryNameOverrides } from '~/boundary/useGetBoundaryNameOverrides';
import { ExportDataFileType } from '~/data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';
import {
  labelStyle,
  radioGroupStyle,
  radioItemStyle,
} from '~/map/map/lassoTool/exportContainedBoundaries/exportContainedBoundariesModal.styles';
import {
  type FilterInputs, useAreaQueryFilters,
} from '~/map/map/lassoTool/exportContainedBoundaries/useAreaQueryFilters';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  useCustomBoundaryGroupsSelector,
  useGlobalBoundaryGroups,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { getMapBoundaryAreaQuery } from '~/store/boundaryItems/boundaryItems.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';

export type ExportContainedBoundariesModalComponentProps = Readonly<{
  filter: FilterInputs;
} & ModalProps>;

enum SelectLassoOverlap {
  Partial = 'partial',
  Contains = 'contains',
}

export const ExportContainedBoundariesModal: React.FC<ExportContainedBoundariesModalComponentProps> = (props) => {
  const { filter, onClose } = props;
  const [selectFrom, setSelectFrom] = useState(BoundaryEditSelectFrom.Boundary);
  const [isSelectGroupExpanded, setIsSelectGroupExpanded] = useState(true);
  const [isIncludeOnlyExpanded, setIsIncludeOnlyExpanded] = useState(true);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const [error, setError] = useState<string>();

  const globalBoundaryGroups = useGlobalBoundaryGroups();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const [selectedGlobalBoundaryGroupId, setSelectedGlobalBoundaryGroupId] = useState<number | null>(globalBoundaryGroups[0]?.id ?? null);
  const [selectedCustomBoundaryGroupId, setSelectedCustomBoundaryGroupId] = useState<number | null>(customBoundaryGroups[0]?.id ?? null);
  const [selectedLassoOverlap, setSelectedLassoOverlap] = useState(SelectLassoOverlap.Partial);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const areaQueryFilters = useAreaQueryFilters(filter);
  const { getBoundaryNameOverrides } = useGetBoundaryNameOverrides();

  const isSubmitDisabled =
    (selectFrom === BoundaryEditSelectFrom.Territory && selectedCustomBoundaryGroupId === null) ||
    (selectFrom === BoundaryEditSelectFrom.Boundary && selectedGlobalBoundaryGroupId === null);

  const selectedBoundaryGroupId = selectFrom === BoundaryEditSelectFrom.Territory
    ? selectedCustomBoundaryGroupId
    : selectedGlobalBoundaryGroupId;

  const [t] = useTranslation();

  const { isLoading, invokeAjax: handleSubmit } = useAjaxCall(useCallback(async (results: ExportDataModalResults) => {
    if (!clientId || !mapId || !selectedBoundaryGroupId) {
      return;
    }
    try {
      setError(undefined);
      const asyncCopyToClipboard = prepareAsyncCopyToClipboard();

      const nameOverrides = selectFrom === BoundaryEditSelectFrom.Territory
        ? await getBoundaryNameOverrides(selectedBoundaryGroupId)
        : undefined;

      const response = await getMapBoundaryAreaQuery(clientId, {
        map_id: mapId,
        exclude_artificial: true,
        format: results.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : results.fileType,
        as_text: results.method === ExportDataMethod.Clipboard,
        overlap_type: selectedLassoOverlap,
        boundary_group_id: selectedBoundaryGroupId,
        name_overrides: nameOverrides,
        ...areaQueryFilters,
      });

      if (results.method === ExportDataMethod.Clipboard) {
        if (!('data' in response)) {
          throw new Error('Server returned unexpected response');
        }

        if (!response.data) {
          setError(t('No boundaries to export'));
          return;
        }

        const exportData = response.data;
        asyncCopyToClipboard(exportData);
        showCopiedToClipboardMessage();
      }
      else {
        if (!('download' in response)) {
          throw new Error('Server returned unexpected response');
        }

        if (!response.download) {
          setError(t('No boundaries to export'));
          return;
        }

        const exportUrl = getExportUrl(response.download);
        downloadFile(exportUrl);

        onClose();
        openDownloadResultsModal({
          downloadUrl: exportUrl,
        });
      }
    }
    catch (e) {
      console.error(e);
      setError(t('Error exporting data'));
    }
  }, [
    clientId,
    mapId,
    selectedBoundaryGroupId,
    selectFrom,
    getBoundaryNameOverrides,
    selectedLassoOverlap,
    areaQueryFilters,
    showCopiedToClipboardMessage,
    t,
    onClose,
    openDownloadResultsModal,
  ]));

  return (
    <ExportDataModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Export Contained Boundaries')}
      onSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      error={error}
      isLoading={isLoading}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
      sectionsBeforeExportMethod={[{
        header: t('Select Boundaries to Export'),
        isExpanded: isSelectGroupExpanded,
        onHeadingClick: () => setIsSelectGroupExpanded(!isSelectGroupExpanded),
        child: (
          <div>
            <div css={labelStyle}>
              {t('Select From')}
            </div>
            <RadioGroupComponent
              css={radioGroupStyle}
              itemCommonStyle={radioItemStyle}
              onValueChange={(value: BoundaryEditSelectFrom) => setSelectFrom(value)}
              selectedValue={selectFrom}
              items={getBoundaryEditSelectFromRadioGroupOptions(t, false)}
            />

            <div css={labelStyle}>
              {t('Select Boundary Type')}
            </div>
            {selectFrom === BoundaryEditSelectFrom.Boundary ? (
              <AutocompleteComponent
                onChange={setSelectedGlobalBoundaryGroupId}
                options={globalBoundaryGroups.map(group => ({
                  name: group.name,
                  value: group.id,
                }))}
                value={selectedGlobalBoundaryGroupId}
                inPortal
                isClearable
              />
            ) : (
              <AutocompleteComponent
                onChange={setSelectedCustomBoundaryGroupId}
                options={customBoundaryGroups.map(group => ({
                  name: group.name,
                  value: group.id,
                }))}
                value={selectedCustomBoundaryGroupId}
                inPortal
                isClearable
              />
            )}
          </div>
        ),
      }, {
        header: t('Include only'),
        isExpanded: isIncludeOnlyExpanded,
        onHeadingClick: () => setIsIncludeOnlyExpanded(!isIncludeOnlyExpanded),
        child: (
          <RadioGroupComponent
            onValueChange={(value: SelectLassoOverlap) => setSelectedLassoOverlap(value)}
            selectedValue={selectedLassoOverlap}
            items={[{
              value: SelectLassoOverlap.Contains,
              label: selectFrom === BoundaryEditSelectFrom.Territory ? t('Full territories only') : t('Full boundaries only'),
            }, {
              value: SelectLassoOverlap.Partial,
              label: selectFrom === BoundaryEditSelectFrom.Territory ? t('Full & partial territories') : t('Full & partial boundaries'),
            }]}
          />
        ),
      }]}
    />
  );
};
