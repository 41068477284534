import {
  useCallback, useState,
} from 'react';
import { type MapInfo } from '~/_shared/types/map';
import { type PerSpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { filterAddedSpreadsheetColumns } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import {
  type MapListingItem, type SpreadsheetMapInfo,
} from '~/map/listing/item/mapListingItem';
import {
  guessMapIntersects, type MapIntersect,
} from '~/map/map.repository';
import {
  getSpreadsheetMatchup, type GetSpreadsheetMatchupDataRequestItem,
} from '~/spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  getMapFromRealSpreadsheetId, getMapIdFromPrimarySpreadsheetId, getPrimarySpreadsheetIdFromMapId,
  getRealSpreadsheetIdFromMapId, getSpreadsheetIdFromMapId,
} from './matchupColumnsSection.helpers';

export const useMatchupColumns = () => {
  const clientId = useClientIdSelector();
  const [isLoading, setIsLoading] = useState(false);
  const [intersects, setIntersects] = useState<MapIntersect[]>([]);
  const [columnData, setColumnData] = useState<PerSpreadsheetMatchupData>();

  const getPreselectionColumns = useCallback((mapList: MapListingItem[], layeringMapInfo?: MapInfo | null) => {
    if (!clientId) {
      return;
    }
    const primarySpreadsheetIds = mapList.map((map: MapListingItem) => {
      const spreadsheets = map.spreadSheets || [];
      const primarySpreadsheet = spreadsheets.filter((spreadsheet: SpreadsheetMapInfo) => spreadsheet.isPrimary);
      return primarySpreadsheet?.[0]?.spreadsheetId;
    }).filter(item => !!item);
    const requestMatchUpDataItems: GetSpreadsheetMatchupDataRequestItem[] = primarySpreadsheetIds.map((spreadsheetId, index) => ({
      spreadsheet_id: spreadsheetId,
      map_id: mapList[index].id,
    }));
    if (layeringMapInfo) {
      primarySpreadsheetIds.push(layeringMapInfo.spreadsheets[0].spreadSheetId);
      requestMatchUpDataItems.push({
        spreadsheet_id: layeringMapInfo.spreadsheets[0].spreadSheetId,
        map_id: layeringMapInfo.id,
      });
    }
    setIsLoading(true);
    guessMapIntersects(clientId, [], primarySpreadsheetIds)
      .then((response) => {
        setIntersects(filterAddedSpreadsheetColumns(response.list));
      })
      .finally(() => {
        getSpreadsheetMatchup(clientId, requestMatchUpDataItems)
          .then((response) => {
            setColumnData(response.data);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  }, [clientId]);

  return { isLoading, intersects, columnData, getMapIdFromPrimarySpreadsheetId, getMapFromRealSpreadsheetId,
    getPreselectionColumns, getSpreadsheetIdFromMapId, getRealSpreadsheetIdFromMapId, getPrimarySpreadsheetIdFromMapId };
};
