import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { setModalConfirmedToStorage } from '~/_shared/components/modal/confirmation/confirmationModal.helpers';
import { PermanentConfirmStrategy } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { useStorageService } from '~/_shared/utils/storageService';
import { HIDE_BOUNDARY_ZOOM_DISCLAIMER_SESSION_KEY } from '~/map/map/boundary/boundaryView/mapBoundaryPolygons/useBoundaryZoomDisclaimer';
import type { ModalProps } from '~/modal/modalType.enum';

type BoundaryZoomLevelDisclaimerItem = {
  groupName: string;
  zoomInCount: number;
};

export type BoundaryZoomLevelDisclaimerProps = ModalProps<{
  items: BoundaryZoomLevelDisclaimerItem[];
}>;

const modalContentStyle = css({
  padding: '20px 25px',
});

const descriptionStyle = css({
  padding: 0,
  margin: 0,
  fontSize: 18,
  fontWeight: 400,
});

const headingStyle = css({
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 15,
  display: 'block',
});

const checkboxStyle = (theme: Theme) => css({
  marginTop: 30,
  fontSize: 12,
  color: theme.textColors.secondary,
});

const listStyle = css({
  width: 280,
  listStyle: 'none',
  padding: '0 32px 0 20px',
  margin: 0,
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  rowGap: 10,
});

const listItemStyle = css({
  display: 'contents',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.25,
});

const checkboxTextStyle = css({
  marginLeft: 6,
});

const listWrapperStyle = css({
  marginTop: 20,
});

const groupNameStyle = css({
  minWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const clicksCountStyle = (theme: Theme) => css({
  whiteSpace: 'nowrap',
  paddingLeft: 5,
  color: theme.textColors.secondary,
});

export const BoundaryZoomLevelDisclaimerModalComponent: FC<BoundaryZoomLevelDisclaimerProps> = (props) => {
  const [showAgain, setShowAgain] = useState(true);
  const [t] = useTranslation();
  const storageService = useStorageService();

  const onClose = () => {
    if (!showAgain) {
      setModalConfirmedToStorage(storageService, {
        id: HIDE_BOUNDARY_ZOOM_DISCLAIMER_SESSION_KEY,
        strategy: PermanentConfirmStrategy.Session,
      });
    }

    props.onClose();
  };

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={onClose}
      caption={t('Zooming required to see boundary')}
      contentStyle={modalContentStyle}
    >
      <p css={descriptionStyle}>{t('At this map zoom level, some boundaries are not visible on the map. To see your boundaries you must zoom in a few clicks.')}</p>
      <div css={listWrapperStyle}>
        <strong css={headingStyle}>{t('Boundaries requiring zoom')}</strong>
        <ScrollBarComponent
          translateContentHeightToHolder
          translateContentWidthToHolder
          type={ScrollbarType.Vertical}
          maxHeight={80}
        >
          <ul css={listStyle}>
            {props.items.map((item, index) => (
              <li css={listItemStyle} key={index}>
                <span css={groupNameStyle}>
                  {item.groupName}
                </span>
                <span css={clicksCountStyle}>
                  {t('{{count}} clicks', {
                    count: item.zoomInCount,
                  })}
                </span>
              </li>
            ))}
          </ul>
        </ScrollBarComponent>

        <CheckboxComponent
          css={checkboxStyle}
          textStyle={checkboxTextStyle}
          checkedSetter={() => {
            setShowAgain(!showAgain);
          }}
          isChecked={!showAgain}
          text={t('Do not show again for this session')}
        />
      </div>
    </ModalComponent>
  );
};
