import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import {
  activeMapElementsClearState,
  activeMapElementsSetActiveLocationList,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import {
  closeLeftSidebar, openMapTool,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import {
  frontendStateSearchHide,
  frontendStateSearchShow,
} from '~/store/frontendState/mapTools/search/search.actionCreators';
import { setLocationFinderToolsState } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.actionCreators';
import { useLocationFinderSelector } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.selectors';
import { useIsLocationListOpen } from '../../store/frontendState/activeMapElements/activeMapElements.selectors';
import { MobileTopUnderbarComponent } from './mobileTopUnderbar.component';
import { useItemsVisibleInMobileTopUnderbar } from './useItemsVisibleInMobileTopUnderbar.hook';

export const MobileTopUnderbarContainer: FC = () => {
  const dispatch = useDispatch();
  const { openModal: openLocationFinderModal } = useModal(ModalType.LocationFinder);
  const searchActive = useSelector(state => state.frontendState.mapTools.search.searchActive);
  const locationFinder = useLocationFinderSelector();
  const isLocationListOpen = useIsLocationListOpen();
  const leftSidebarState = useSelector(state => state.frontendState.leftSidebar);
  const [isMapToolsSelected, setIsMapToolsSelected] = useState(false);

  const activeItem = useMemo<MainMenuItem | undefined>(() => {
    if (locationFinder.latLng !== null) {
      return MainMenuItems.NearMe;
    }
    if (searchActive) {
      return MainMenuItems.Search;
    }
    if (isLocationListOpen) {
      return MainMenuItems.Location;
    }
    if (leftSidebarState.openedMapTool) {
      const mainMenuFromMapTool = Object.values(MainMenuItems).find(item => item === leftSidebarState.openedMapTool);
      if (mainMenuFromMapTool) {
        return mainMenuFromMapTool;
      }
    }
    if (leftSidebarState.openedApp === SidebarApp.MapTools) {
      return MainMenuItems.MapTools;
    }
    return undefined;
  }, [locationFinder.latLng, searchActive, isLocationListOpen,
    leftSidebarState.openedMapTool, leftSidebarState.openedApp]);

  const visibleItems = useItemsVisibleInMobileTopUnderbar();

  const closeMapToolsSelector = useCallback(() => {
    setIsMapToolsSelected(false);
  }, []);

  const onItemActivate = useCallback((item: MainMenuItem) => {
    switch (item) {
      case (MainMenuItems.Search): {
        dispatch(closeLeftSidebar());
        dispatch(frontendStateSearchShow());
        break;
      }
      case (MainMenuItems.NearMe): {
        if (locationFinder.latLng === null) {
          openLocationFinderModal({});
        }
        else {
          dispatch(setLocationFinderToolsState(null, null));
        }
        break;
      }
      case (MainMenuItems.Grouping): {
        dispatch(openMapTool(MapTool.Grouping));
        break;
      }
      case (MainMenuItems.Filter): {
        dispatch(openMapTool(MapTool.Filter));
        break;
      }
      case (MainMenuItems.Routing): {
        dispatch(openMapTool(MapTool.Routing));
        break;
      }
      case (MainMenuItems.Proximity): {
        dispatch(openMapTool(MapTool.Proximity));
        break;
      }
      case (MainMenuItems.MapTools): {
        setIsMapToolsSelected(true);
        dispatch(closeLeftSidebar());
        dispatch(activeMapElementsClearState());
        break;
      }
      case (MainMenuItems.Location): {
        dispatch(activeMapElementsSetActiveLocationList());
        break;
      }
      case (MainMenuItems.MapLegend): {
        dispatch(openMapTool(MapTool.MapLegend));
        break;
      }
      case (MainMenuItems.Boundary): {
        dispatch(openMapTool(MapTool.Boundary));
        break;
      }

      default: {
        return;
      }
    }
  }, [dispatch, locationFinder.latLng, openLocationFinderModal]);

  const onItemDeactivate = useCallback((item: MainMenuItem) => {
    dispatch(closeLeftSidebar());

    switch (item) {
      case (MainMenuItems.Search): {
        dispatch(frontendStateSearchHide());
        break;
      }
      case (MainMenuItems.NearMe): {
        dispatch(activeMapElementsClearState());
        dispatch(setLocationFinderToolsState(null, null));
        break;
      }
      case (MainMenuItems.Location): {
        dispatch(activeMapElementsClearState());
        break;
      }

      default: {
        return;
      }
    }
  }, [dispatch]);

  const onItemClickCallback = useCallback((item: MainMenuItem) => {
    closeMapToolsSelector();

    if (activeItem) {
      onItemDeactivate(activeItem);
    }

    if (activeItem === item) {
      // Clicking the same item deactivates it
      return;
    }

    onItemActivate(item);
  }, [activeItem, closeMapToolsSelector, onItemActivate, onItemDeactivate]);

  const selectedItems = useMemo(() =>
    new Set(activeItem ? [activeItem] : []),
  [activeItem]);

  return (
    <MobileTopUnderbarComponent
      availableMobileItems={visibleItems}
      onItemClick={onItemClickCallback}
      selectedItems={selectedItems}
      isMapToolsSelected={isMapToolsSelected}
      handleMapToolsClose={closeMapToolsSelector}
    />
  );
};
