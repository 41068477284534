import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, useCallback, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  itemHeight, itemWidth,
} from '../../_shared/constants/mainMenuUI.constants';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { useClickOutsideListenerRef } from '../../_shared/utils/hooks/useClickOutsideListenerRef';
import { MainMenuItemInnerMenuComponent } from './mainMenuItemInnerMenu.component';

const MainMenuHeading = styled.div({
  fontSize: '10px',
  textAlign: 'center',
  position: 'absolute',
  padding: '0 4px',
  bottom: -20,
  transition: 'bottom .2s, opacity .2s',
  opacity: 0,
  left: 0,
  width: '100%',
  boxSizing: 'border-box',
});

const IconWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  bottom: 0,
  transition: 'bottom .2s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const itemStyle = (props: ThemeProps<{ isHighlighted: boolean; isDisabled: boolean; isDanger: boolean; labelLinesCount: number }>) => {
  let color = props.isDanger ? props.theme.textColors.danger : props.theme.textColors.primary;
  if (props.isDisabled) {
    color = props.theme.textColors.disabled;
  }

  return css({
    position: 'relative',
    flexShrink: 0,
    backgroundColor: props.isHighlighted ? props.theme.backgroundColors.highlight : 'inherit',
    borderRadius: 4,
    boxShadow: props.isHighlighted ? `0 0 0 1px ${props.theme.borderColors.activeItem} inset` : 'none',
    boxSizing: 'border-box',
    color,
    width: itemWidth,
    overflow: 'hidden',
    height: '100%',
    userSelect: 'none',
    '&:hover': {
      boxShadow: props.isHighlighted ? `0 0 0 2px ${props.theme.borderColors.activeItem} inset` : 'none',
      [`${IconWrapper}`]: {
        bottom: props.labelLinesCount * 12,
      },
      [`${MainMenuHeading}`]: {
        bottom: 7,
        opacity: 1,
      },
    },
  });
};

const wrapperStyle = ({ theme, isDisabled, fontSize }: ThemeProps<{ isDisabled: boolean; fontSize?: string }>) => css({
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: 4,
  cursor: isDisabled ? 'default' : 'pointer',
  fontSize,
  height: itemHeight,
  display: 'flex',

  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const iconStyle = css({
  fontSize: '20px',
});

export type SingleItemProps = Readonly<{
  icon: IconProp;
  title: string;
  onClick: () => void;
  isDisabled: boolean;
  fontSize?: string;
  testId?: string;
}>;

export type MainMenuItemProps = Readonly<SingleItemProps & {
  innerMenu?: ReadonlyArray<SingleItemProps>;
  isHighlighted?: boolean;
  isDisabled: boolean;
  isDanger?: boolean;
  isLoading?: boolean;
  className?: string;
  onMouseEnter?: () => void;
}>;

export const MainMenuItemComponent: FC<MainMenuItemProps> = props => {
  const [isInnerItemShown, setIsInnerItemShown] = useState(false);

  const renderMenu = !!(props.innerMenu && isInnerItemShown);

  const clickOutsideRef = useClickOutsideListenerRef(() => {
    setIsInnerItemShown(false);
  }, renderMenu);
  const theme = useTheme();

  const { onClick } = props;

  const handleClick = useCallback((callback: () => void, isShown: boolean, isDisabled: boolean) => () => {
    if (!isDisabled) {
      setIsInnerItemShown(isShown);
      callback();
    }
  },
  []);

  return (
    <div
      className={props.className}
      css={wrapperStyle({ theme, isDisabled: props.isDisabled, fontSize: props.fontSize })}
    >
      <div
        css={itemStyle({
          theme,
          isHighlighted: !!props.isHighlighted,
          isDisabled: props.isDisabled,
          isDanger: !!props.isDanger,
          labelLinesCount: Math.ceil(props.title.length / 10),
        })}
        onClick={handleClick(onClick, !isInnerItemShown, props.isDisabled)}
        ref={clickOutsideRef}
      >
        {props.isLoading && <OverlayLoaderComponent loaderSize={30} />}
        <IconWrapper>
          <FontAwesomeIcon
            css={iconStyle}
            icon={props.icon}
          />
        </IconWrapper>

        <MainMenuHeading>
          {props.title}
        </MainMenuHeading>
      </div>

      {renderMenu && (
        <MainMenuItemInnerMenuComponent
          innerMenu={props.innerMenu}
          onItemClick={handleClick}
        />
      )}
    </div>
  );
};
