import { css } from '@emotion/react';
import {
  memo, useCallback,
} from 'react';
import { CheckboxComponent } from '../../../../../_shared/baseComponents/checkbox';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';

const itemStyle = (props: ThemeProps) => css({
  display: 'flex',
  paddingBottom: '8px',
  paddingTop: '8px',
  cursor: 'default',
  borderBottom: `1px solid ${props.theme.lineColors.basePrimary}`,
});

const entryStyle = css({
  marginLeft: 4,
  marginRight: 4,
});

const rowNumberStyle = css({
  flex: 1,
});

const checkBoxStyle = css({
  flex: 1,
});

const nameStyle = css({
  flex: 5,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const addressStyle = css({
  flex: 5,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

type RowProps = {
  rowId: string;
  rowNumber: number;
  isChecked: boolean;
  name: string;
  address: string | null;

  onToggle: (rowId: string) => void;
};

export const RowComponent: React.FC<RowProps> = memo(({ rowId, rowNumber, onToggle, address, name, isChecked }) => {
  const theme = useTheme();
  const onToggleMemoized = useCallback(() => onToggle(rowId), [onToggle, rowId]);
  return (
    <div
      css={itemStyle({ theme })}
      key={rowId}
    >
      <div css={[entryStyle, rowNumberStyle]}>
        {`${rowNumber}.`}
      </div>
      <div css={[entryStyle, checkBoxStyle]}>
        <CheckboxComponent
          checkedSetter={onToggleMemoized}
          isChecked={isChecked}
        />
      </div>
      <div
        css={[entryStyle, nameStyle]}
        onClick={onToggleMemoized}
      >
        {name}
      </div>
      {address && (
        <div
          css={[entryStyle, addressStyle]}
          onClick={onToggleMemoized}
        >
          {address}
        </div>
      )
      }
    </div>
  );
});
