import { type ReactNode } from 'react';
import {
  type InputProps, TextInputComponent,
} from '../index';
import {
  SuffixContainer, type SuffixContainerProps,
} from './components';
import { suffixInputStyle } from './textInputWithSuffix.styles';

export type TextInputWithSuffixProps = {
  isInvalid?: boolean;
  suffix: ReactNode;
  suffixWidth?: SuffixContainerProps['width'];
} & Omit<InputProps, 'rightContent'>;

export const TextInputWithSuffixComponent = ({
  autoComplete,
  className,
  icon,
  iconStyle,
  id,
  inputMode,
  inputSize,
  inputStyle,
  isDisabled,
  isInvalid,
  isReadOnly,
  leftContent,
  max,
  min,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyUp,
  placeholder,
  role,
  size,
  suffix,
  suffixWidth,
  title,
  type,
  value,
  ...restProps
}: TextInputWithSuffixProps) => {
  return (
    <TextInputComponent
      {...restProps}
      autoComplete={autoComplete}
      className={className}
      css={[suffixInputStyle({ hasError: isInvalid }), inputStyle]}
      icon={icon}
      iconStyle={iconStyle}
      id={id}
      inputMode={inputMode}
      inputSize={inputSize}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      leftContent={leftContent}
      max={max}
      min={min}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      rightContent={<SuffixContainer width={suffixWidth}>{suffix}</SuffixContainer>}
      role={role}
      size={size}
      title={title}
      type={type}
      value={value}
    />
  );
};
