import type { ReactNode } from 'react';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { AppPageStylesWrapper } from './appPageStylesWrapper.component';

export const PageWithLoaderComponent = ({ isLoading, children }: { isLoading: boolean; children: ReactNode }) => {
  return (
    <AppPageStylesWrapper>
      <div css={{ height: '100%', width: '100%' }}>
        {isLoading ? <OverlayLoaderComponent /> : children}
      </div>
    </AppPageStylesWrapper>
  );
};
