import { css } from '@emotion/react';
import {
  type FC, type FormEvent,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { MapBasicInfoComponent } from '../basicInfo/mapBasicInfo.component';

const accordionContentStyle = css({
  padding: '2px 10px',
});

const continueButtonStyle = (alignContinueButtonToRight: boolean) => css({
  marginTop: 12,
  width: alignContinueButtonToRight ? undefined : '100%',
});

const buttonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

type MapNameAndDescriptionComponentProps = Readonly<{
  alignContinueButtonToRight: boolean;
  isContinueEnabled: boolean;
  isMapLayered?: boolean;
  isNewSalesRepMap?: boolean;
  mapDescription: string;
  mapName: string;

  onSubmit: (e: FormEvent) => void;
  setMapDescription: (isContinueEnabled: string) => void;
  setMapName: (mapName: string) => void;
  setMapNameInputRef: (inputRef: HTMLInputElement) => void;
}>;

export const MapNameAndDescriptionComponent: FC<MapNameAndDescriptionComponentProps> = (props) => {
  const [t] = useTranslation();

  return (
    <form
      css={accordionContentStyle}
      onSubmit={props.onSubmit}
    >
      <MapBasicInfoComponent
        customLabel={props.isNewSalesRepMap ? t('Map With Sales People Name') : t('Map Name')}
        description={props.mapDescription}
        isMapLayered={props.isMapLayered}
        isSnapshot={false}
        name={props.mapName}
        namePlaceholder={t('Map Name')}
        onDescriptionChange={props.setMapDescription}
        onNameChange={props.setMapName}
        setMapNameInputRef={props.setMapNameInputRef}
      />

      <div css={buttonWrapperStyle}>
        <ButtonComponent
          isSubmit
          isDisabled={!props.isContinueEnabled}
          css={continueButtonStyle(props.alignContinueButtonToRight)}
          text={t('Continue')}
        />
      </div>
    </form>
  );
};
