import {
  css, type Interpolation,
} from '@emotion/react';
import {
  autoUpdate, type Placement, shift, size, useFloating,
} from '@floating-ui/react';
import {
  type ReactNode, type Ref, type RefObject, useCallback, useEffect, useRef,
} from 'react';
import { useResizeObserver } from '~/_shared/hooks/useResizeObserver';
import { useCombinedRefs } from '~/_shared/utils/hooks/useCombinedRefs';
import { FixedStickyContainerPortalComponent } from '../../components/fixedStickyContainer/fixedStickyContainerPortal.component';
import { useOnVisibilityLost } from '../../components/fixedStickyContainer/useOnVisibilityLost';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../components/scrollbar/scrollbar.component';
import { zIndexGeneral } from '../../constants/zIndexGeneral.enum';
import { useTheme } from '../../themes/theme.hooks';
import { areShallowEqual } from '../../utils/equality/shallowEqual.helper';
import { useClickOutsideListenerRefs } from '../../utils/hooks/useClickOutsideListenerRef';
import {
  dropdownMenuStyle, MENU_BORDER_WIDTH,
} from './menuStyles';

export enum DropdownPlacement {
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
}

export const DEFAULT_DROPDOWN_PLACEMENT = DropdownPlacement.BottomStart;

const containerStyle = css({
  display: 'contents',
});

const wrapperStyle = ({ isOpen, isDisabled }: { isOpen: boolean; isDisabled?: boolean }) => css({
  position: 'relative',
  zIndex: isOpen ? 10 : undefined,
  pointerEvents: isDisabled ? 'none' : undefined,
});

export type DropdownProps = {
  children: ReactNode;
  className?: string;
  closeOnInsideClick?: boolean;
  inPortal?: boolean;
  isDisabled?: boolean;
  isOpen: boolean;
  menuStyle?: Interpolation;
  triggerComponent: ReactNode;
  dropdownPlacement?: Placement;
  disableAutoHeight?: boolean;
  floatingRef?: Ref<HTMLElement | null>;

  onClose: () => void;
};

export const DropdownComponent = ({
  closeOnInsideClick = true,
  dropdownPlacement = DEFAULT_DROPDOWN_PLACEMENT,
  disableAutoHeight,
  floatingRef,
  menuStyle,
  children,
  className,
  triggerComponent,
  isDisabled,
  isOpen,
  onClose,
  inPortal,
  ...restProps
}: DropdownProps) => {
  const theme = useTheme();

  const scrollBarContainerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLElement>(document.body);
  const clickOutsideCallback = useCallback(() => {
    if (!isOpen) {
      return;
    }
    onClose();
  }, [isOpen, onClose]);

  const {
    refs,
    floatingStyles,
    update,
  } = useFloating({
    placement: dropdownPlacement,
    middleware: [
      shift(),
      size({
        padding: 10,
        apply({ availableWidth, availableHeight, elements, rects }) {
          const sizeLimits = {
            minWidth: `calc(${rects.reference.width}px - ${2 * MENU_BORDER_WIDTH}px)`,
            maxWidth: `min(${availableWidth}px, 500px)`,
            maxHeight: disableAutoHeight ? undefined : `${availableHeight}px`,
          };
          Object.assign(elements.floating.style, sizeLimits);
          if (
            scrollBarContainerRef.current &&
            !areShallowEqual(scrollBarContainerRef.current.style, sizeLimits)
          ) {
            Object.assign(scrollBarContainerRef.current.style, sizeLimits);
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const triggerRef = refs.reference as RefObject<HTMLElement | null>;
  useClickOutsideListenerRefs(clickOutsideCallback, isOpen, [triggerRef, refs.floating]);
  useResizeObserver({ ref: bodyRef, onResize: update });
  useEffect(() => {
    if (isOpen) {
      update();
    }
  }, [isOpen, update]);
  useOnVisibilityLost(triggerRef.current, () => {
    if (isOpen) {
      onClose();
    }
  });

  const combinedFloatingRef = useCombinedRefs<HTMLElement>(refs.setFloating, floatingRef);

  const dropdownContent = (
    <div
      ref={combinedFloatingRef}
      style={floatingStyles}
      css={[
        dropdownMenuStyle({ theme, placement: dropdownPlacement }),
        { zIndex: zIndexGeneral.PopperTooltip },
        menuStyle,
      ]}
    >
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        translateContentHeightToHolder
        translateContentWidthToHolder
        maxHeight="inherit"
      >
        <div
          onClick={closeOnInsideClick ? onClose : undefined}
          ref={scrollBarContainerRef}
        >
          {children}
        </div>
      </ScrollBarComponent>
    </div>
  );

  return (
    <div {...restProps} css={containerStyle}>
      <div
        css={wrapperStyle({ isOpen, isDisabled })}
        className={className}
        ref={refs.setReference}
      >
        {triggerComponent}
      </div>

      {!isDisabled && isOpen && (inPortal ? (
        <FixedStickyContainerPortalComponent>
          {dropdownContent}
        </FixedStickyContainerPortalComponent>
      ) : (
        <>{dropdownContent}</>
      ))}
    </div>
  );
};
