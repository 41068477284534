import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type MouseEvent, type ReactNode, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { type Theme } from '../../../themes/theme.model';
import { usePointerClickEvent } from '../../utils/usePointerClickEvent';

const iconStyles = css({
  paddingRight: 8,
  fontWeight: 900,
});

export const linkStyle = ({ isDestructive, isDisabled }: {
  isDestructive: boolean;
  isDisabled?: boolean;
}) => (theme: Theme) => {
  const color = isDestructive ? theme.textColors.danger : theme.textColors.link;
  const opacity = isDisabled ? theme.opacity.disabled : 1;

  return css({
    color,
    cursor: 'pointer',
    fontSize: '14px',
    opacity,
    textDecoration: 'none',
    '&:hover': {
      color: (isDisabled || isDestructive) ? color : theme.textColors.linkOnHover,
      opacity: (isDisabled || !isDestructive) ? opacity : 0.8,
    },
  });
};

export type LinkProps = Readonly<{
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  getStyle?: typeof linkStyle;
  icon?: IconProp;
  isDestructive?: boolean;
  path: string | null;
  target?: string;
  title?: string;
  tooltip?: string;
  tooltipStyle?: SerializedStyles;

  onClick?: (e: MouseEvent) => void;
}>;

export const LinkComponent: FC<LinkProps> = ({
  children,
  className,
  disabled,
  getStyle = linkStyle,
  icon,
  isDestructive = false,
  onClick,
  path,
  target,
  title,
  tooltip,
  tooltipStyle,
  ...restProps
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  const {
    onClick: onPointerClick,
    onPointerDown,
    onPointerUp,
  } = usePointerClickEvent(onClick, () => linkRef.current?.click());

  if (path === null) {
    return (
      <TooltipComponent tooltipContent={tooltip}>
        <span
          {...restProps}
          className={className}
          css={getStyle({ isDestructive, isDisabled: disabled })}
          onClick={disabled ? undefined : onPointerClick}
          onPointerDown={disabled ? undefined : onPointerDown}
          onPointerUp={disabled ? undefined : onPointerUp}
        >
          {icon && (
            <FontAwesomeIcon
              css={iconStyles}
              icon={icon}
            />
          )}
          {children}
        </span>
      </TooltipComponent>
    );
  }

  return (
    <TooltipComponent
      tooltipContent={tooltip}
      css={tooltipStyle}
    >
      <Link
        {...restProps}
        className={className}
        css={getStyle({ isDestructive, isDisabled: disabled })}
        onClick={onPointerClick}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        ref={linkRef}
        target={target}
        title={title}
        to={path}
      >
        {icon && (
          <FontAwesomeIcon
            css={iconStyles}
            icon={icon}
          />
        )}
        {children}
      </Link>
    </TooltipComponent>
  );
};
