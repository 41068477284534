import { type FC } from 'react';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { getDrawingDeleteAllItemsLabelForDrawingType } from '../../../../drawingTool/drawingTool.helpers';
import { useDrawingItemsRemove } from '../../../../drawingTool/hooks/useDrawingItemsRemove';
import { useDrawingItemsRemoveAll } from '../../../../drawingTool/hooks/useDrawingItemsRemoveAll';
import { useMapToolsDrawingActiveToolSelector } from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { useDrawingItemsCountSelector } from '../../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { DrawingToolFooterComponent } from './drawingToolFooter.component';

export const DrawingToolFooterContainer: FC = () => {
  const activeDrawingTool = useMapToolsDrawingActiveToolSelector();
  const drawingItemsCount = useDrawingItemsCountSelector(activeDrawingTool);
  const { removeDrawingItems } = useDrawingItemsRemove();
  const { removeAllDrawings } = useDrawingItemsRemoveAll();
  const [t] = useTranslation();

  const isRemoveEnabled = drawingItemsCount > 0;

  let onRemoveClick: () => void = () => null;
  let removeDrawingLabel: string = '';

  // entry drawing tool state -> remove all functionality
  if (activeDrawingTool === DrawingTool.Management) {
    removeDrawingLabel = t('Delete All Drawings');
    onRemoveClick = removeAllDrawings;
  }
  // some tool is selected and drawing tool is in paused/adding mode
  else {
    removeDrawingLabel = getDrawingDeleteAllItemsLabelForDrawingType(activeDrawingTool, t);
    onRemoveClick = () => removeDrawingItems(activeDrawingTool);
  }

  return (
    <DrawingToolFooterComponent
      removeDrawingsLabel={removeDrawingLabel}
      onRemove={onRemoveClick}
      isRemoveDisabled={!isRemoveEnabled}
    />
  );
};
