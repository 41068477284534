import { type HTMLProps } from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import { linkStyle } from './anchorLink.styles';

export type AnchorLinkProps = HTMLProps<HTMLAnchorElement>;

export const AnchorLink = ({ children, ...restProps }: AnchorLinkProps) => {
  const theme = useTheme();

  return (
    <a
      css={linkStyle({ theme })}
      {...restProps}
    >
      {children}
    </a>
  );
};
