import { type MarkerSettings } from '../../_shared/types/markers/visualSettings.types';
import { createUuid } from '../../_shared/utils/createUuid';
import { type AppError } from '../../appError/appError.type';
import { type BucketMarkerVisualType } from '../../grouping/settings/numeric/numericalGroupSettingsNumeric.helpers';
import {
  type NumericalGroupSettingsInitial,
  type NumericalGroupSettingsResults,
} from '../../grouping/settings/numericalGroupSettings.component';
import { type NumericalGroupSettingsContainerProps } from '../../grouping/settings/numericalGroupSettings.container';
import { type GetMarkerVisualWithBakedDataProps } from '../../grouping/useGroupColumnData';
import { ModalType } from '../../modal/modalType.enum';
import {
  type ModalTypeWithArgs, type WithoutModalProps,
} from '../../modal/renderModal';
import {
  MODAL_CLOSE,
  MODAL_CLOSE_ALL,
  MODAL_CLOSE_LAST,
  MODAL_OPEN,
  MODAL_OPEN_AND_CLOSE_LAST,
} from './modal.actionTypes';

export const replaceCurrentModalWithAndUseCustomId = (id: string, ...typeWithArgs: ModalTypeWithArgs) => ({
  type: MODAL_OPEN_AND_CLOSE_LAST,
  payload: {
    typeWithArgs,
    id,
  },
}) as const;

export const replaceCurrentModalWith = (...typeWithArgs: ModalTypeWithArgs) => ({
  type: MODAL_OPEN_AND_CLOSE_LAST,
  payload: {
    typeWithArgs,
    id: createUuid(),
  },
}) as const;

export const openModalWithId = (id: string, ...typeWithArgs: ModalTypeWithArgs) => {
  return ({
    type: MODAL_OPEN,
    payload: {
      typeWithArgs,
      id,
    },
  }) as const;
};

export const openModal = (...typeWithArgs: ModalTypeWithArgs) => ({
  type: MODAL_OPEN,
  payload: {
    typeWithArgs,
    id: createUuid(),
  },
}) as const;

export const closeModal = (id: string) => ({
  type: MODAL_CLOSE,
  payload: {
    id,
  },
}) as const;

export const closeLastModal = () => ({
  type: MODAL_CLOSE_LAST,
}) as const;

export const closeAllModals = () => ({
  type: MODAL_CLOSE_ALL,
}) as const;

export const createAppError = (error: AppError, id?: string) => ({
  type: MODAL_OPEN,
  payload: {
    typeWithArgs: [ModalType.AppError, error] as ModalTypeWithArgs,
    id: id ?? createUuid(),
  },
}) as const;

export const openNumericalGroupSettings = (data: {
  getExistingGroupDataIfAny: (bucketId: number, numberOfBuckets: number) => MarkerSettings | null;
  getMarkerVisuals: (props: GetMarkerVisualWithBakedDataProps) => MarkerSettings;
  initialSettings?: NumericalGroupSettingsInitial;
  isDecimal: boolean;
  markerVisualRadioOptions: ReadonlyArray<BucketMarkerVisualType>;
  max: number;
  min: number;
  onCancel: () => void;
  onSubmit: (results: NumericalGroupSettingsResults) => void;
}) => {
  const { min, max, isDecimal, onSubmit, onCancel, getMarkerVisuals, markerVisualRadioOptions,
    getExistingGroupDataIfAny, initialSettings } = data;

  const numericalGroupSettingsProps: WithoutModalProps<NumericalGroupSettingsContainerProps> = {
    getExistingGroupVisualsIfAny: getExistingGroupDataIfAny,
    getMarkerVisuals,
    isDecimal,
    markerVisualRadioOptions,
    max,
    min,
    onCancel,
    onSubmit,
    initialSettings,
  };

  return {
    type: MODAL_OPEN,
    payload: {
      typeWithArgs: [ModalType.NumericalGroupSettings, numericalGroupSettingsProps] as ModalTypeWithArgs,
      id: createUuid(),
    },
  } as const;
};
