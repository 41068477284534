import type { Waypoint } from '~/store/frontendState/mapTools/directions/directions.state';
import { useZIndexSeries } from './useZIndexSeries.hook';
import { ZIndexedEntity } from './zIndexRanges';

export const directionsWaypointsSubEntities = ['background', 'text'] as const;
export type DirectionsWaypointZIndexSubEntity = typeof directionsWaypointsSubEntities[number];

const idMapper = (w: Waypoint) => w.id;

export const useDirectionsRouteZIndexes = (waypoints: ReadonlyArray<Waypoint>) =>
  useZIndexSeries(() => ({
    orderedEntities: waypoints,
    orderedSubEntities: directionsWaypointsSubEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.DirectionsWaypoint,
  }), [waypoints]).subEntityZIndexes;
