import {
  MAP_SETTINGS_VISIBILITY_HIDE_HEATMAP,
  MAP_SETTINGS_VISIBILITY_HIDE_PROXIMITY,
  MAP_SETTINGS_VISIBILITY_SHOW_HEATMAP,
  MAP_SETTINGS_VISIBILITY_SHOW_PROXIMITY,
} from './visibility.actionTypes';

export const showProximity = (proximityId: string) => ({
  type: MAP_SETTINGS_VISIBILITY_SHOW_PROXIMITY,
  payload: {
    proximityId,
  },
}) as const;

export const hideProximity = (proximityId: string) => ({
  type: MAP_SETTINGS_VISIBILITY_HIDE_PROXIMITY,
  payload: {
    proximityId,
  },
}) as const;

export const showHeatMap = (heatMapId: string) => ({
  type: MAP_SETTINGS_VISIBILITY_SHOW_HEATMAP,
  payload: {
    heatMapId,
  },
}) as const;

export const hideHeatMap = (heatMapId: string) => ({
  type: MAP_SETTINGS_VISIBILITY_HIDE_HEATMAP,
  payload: {
    heatMapId,
  },
}) as const;
