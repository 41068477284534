import { css } from '@emotion/react';
import { type FC } from 'react';
import { InputWithSpinnersComponent } from '~/_shared/baseComponents/inputs';
import { useTranslation } from '../../../_shared/utils/hooks';

type LineWidthProps = Readonly<{
  className?: string;
  lineWidth: number;
  onLineWidthChange: (width: number) => void;
}>;

const dropdownHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '16px',
  lineHeight: 1.2,
});

export const LineWidthComponent: FC<LineWidthProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={props.className}>
      <p css={dropdownHeadingStyle}>{t('Line Width')}</p>
      <InputWithSpinnersComponent
        onChange={props.onLineWidthChange}
        value={props.lineWidth}
        valueMax={10}
        valueMin={0}
        width={87}
      />
    </div>
  );
};
