export const stringTemplate = <TKey extends string>(strings: TemplateStringsArray, ...keys: ReadonlyArray<TKey>) => {
  return (dict: Record<TKey, any>) => {
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
};
