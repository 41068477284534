import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../_shared/utils/hooks';
import { removeProximity } from '../store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { useMapIdSelector } from '../store/selectors/useMapIdSelector';
import { useProximities } from './useProximities';

export const useProximityRemove = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { proximities: proximityList } = useProximities();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onProximityRemove = useCallback((proximityId: string) => {
    if (!mapId) {
      return;
    }

    const proximity = proximityList.find(proximity => proximity.id === proximityId);

    if (!proximity) {
      return;
    }

    dispatch(removeProximity(proximity, mapId));
  }, [dispatch, proximityList, mapId]);

  const openProximityRemoveModal = useCallback((proximityId: string) => {
    openConfirmationModal({
      title: t('Delete Proximity'),
      confirmCaption: t('Proceed'),
      text: t('You are about to delete a proximity. You can undo this action any time with the Undo modal. Do you want to proceed?'),
      permanentConfirmSettings: {
        id: 'remove-proximity',
        strategy: PermanentConfirmStrategy.Local,
      },
      isConfirmButtonDestructive: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        onProximityRemove(proximityId);
        closeConfirmationModal();
      },
    });
  }, [openConfirmationModal, onProximityRemove, closeConfirmationModal, t]);

  return {
    onProximityRemove: openProximityRemoveModal,
  };
};
