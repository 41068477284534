import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {
  type FC, type FormEvent, useMemo, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { EmailSentAlertComponent } from '~/_shared/components/emailSent/emailSentAlert.component';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import { FormFieldType } from '~/_shared/utils/form/formValidation.helpers';
import { useFormFieldValidation } from '~/_shared/utils/form/useFormFieldValidation';
import { useTranslation } from '~/_shared/utils/hooks';
import { validateEmail } from '~/_shared/utils/throttle/validation/validation.helpers';
import {
  authorizationPagesAlertStyle,
  authorizationPagesErrorStyle,
  authorizationPagesLinkStyle,
} from '../authorizationPages.styles';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import { AuthorizationSubmitButtonComponent } from '../submitButton/authorizationSubmitButton.component';

const inputWrapperStyle = css({
  marginBottom: '15px',
});

const linksWrapperStyle = css({
  marginTop: 10,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const alertStyle = css(authorizationPagesAlertStyle, {
  marginTop: 10,
});

type ForgottenPasswordPageProps = Readonly<{
  isLoading: boolean;
  onSubmit: (email: string, callback: () => void) => void;
  errorMessage: string;
  isMobileScreen: boolean;
}>;

export const ForgottenPasswordPageComponent: FC<ForgottenPasswordPageProps> = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [t] = useTranslation();

  const { onBlur: onEmailBlur, onChange: onEmailChange, errors: emailErrors, value: email } = useFormFieldValidation({
    type: FormFieldType.Email,
  });

  const isFormValid = useMemo(() => validateEmail(email) && !props.isLoading, [email, props.isLoading]);

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }

    props.onSubmit(email, () => {
      setIsSuccess(true);
    });
  };

  return (
    <AuthorizationPageWrapperComponent>
      <form
        onSubmit={onFormSubmit}
      >
        {!isSuccess ? (
          <div>
            <div css={inputWrapperStyle}>
              <TextInputAuthComponent
                icon={faEnvelope}
                placeholder={t('Email')}
                type="text"
                autoComplete="email"
                value={email}
                onChange={onEmailChange}
                onBlur={onEmailBlur}
                isDisabled={props.isLoading}
                hasError={emailErrors.length > 0}
              />

              {emailErrors.length > 0 && (
                <FormErrorMessageComponent
                  css={authorizationPagesErrorStyle}
                  messages={emailErrors}
                />
              )}
            </div>

            <AuthorizationSubmitButtonComponent
              isLoading={props.isLoading}
              isDisabled={!isFormValid}
            >
              {t('Send Email')}
            </AuthorizationSubmitButtonComponent>
          </div>
        ) : (
          <div>{t('password.reset.instructions', { email })}</div>
        )}

        {props.errorMessage && (
          <AlertComponent
            css={alertStyle}
            type="danger"
          >
            {props.errorMessage}
          </AlertComponent>
        )}

        <div css={linksWrapperStyle}>
          <LinkComponent
            css={authorizationPagesLinkStyle}
            path={LOGIN_ROUTE}
          >
            {t('Back to Login')}
          </LinkComponent>

          {isSuccess && (
            <LinkComponent
              css={authorizationPagesLinkStyle}
              path={null}
              onClick={() => {
                setIsSuccess(false);
              }}
            >
              {t('Resend Email')}
            </LinkComponent>
          )}
        </div>
        {isSuccess && (
          <div css={alertStyle}>
            <EmailSentAlertComponent resetFlag={isSuccess} />
          </div>
        )}
      </form>
    </AuthorizationPageWrapperComponent>
  );
};
