import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import {
  type ComponentProps, type FC,
} from 'react';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';

type HintTooltipComponentProps = Partial<Pick<ComponentProps<typeof TooltipComponent>, 'tooltipContent'|'placement'|'delayShow'>>
& Pick<ComponentProps<typeof HoverableIconComponent>, 'size'> & {
  readonly className?: string;
};

export const HintTooltipComponent: FC<HintTooltipComponentProps> = ({
  className,
  tooltipContent,
  delayShow,
  placement,
  size = 14,
}) => {
  const theme = useTheme();

  return (
    <TooltipComponent
      tooltipContent={tooltipContent}
      placement={placement}
      delayShow={delayShow}
    >
      <HoverableIconComponent
        className={className}
        size={size}
        icon={faCircleQuestion}
        hoverColor={theme.iconColors.borderPrimaryHover}
      />
    </TooltipComponent>
  );
};
