import {
  type FC, type ReactNode, useCallback, useState,
} from 'react';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { type ProximityType } from '~/_shared/types/proximity/proximity.enums';
import {
  isDriveTimePolygon, isRadiusProximity, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import { formatDistance } from '~/_shared/utils/distance/distance.helpers';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import {
  ProximityListingItemComponent, type ProximityListingItemContextCallbacks,
} from '~/proximity/listing/proximityListingItem.component';
import { type DriveTimeErrorResponseMessage } from '~/proximity/proximity.repository';
import {
  s, Trans,
} from '~/translations/Trans';
import { getDriveTimePolygonLabels } from '../proximity.helpers';

const getDriveTimePolygonError = (message: DriveTimeErrorResponseMessage | null | undefined, t: TranslationFnc) => {
  switch (message) {
    case 'TRAVEL_TIME_INVALID_AREA':
    case 'TRAVEL_TIME_INVALID_START':
      return t('proximity.dtp.error.wrongStartingPoint');
    case 'TRAVEL_TIME_EMPTY_TERRITORIES':
    case 'TRAVEL_TIME_API_FAILED':
      return t('proximity.dtp.error.badParameters');
    case 'TRAVEL_TIME_LIMIT_REACHED': {
      return (
        <div>
          <Trans i18nKey="proximity.dtp.error.limitReached" >
            {s('messagePart1')}
            <SupportLinkComponent
              type={SupportLinkType.danger}
              subject={t('proximity.dtp.error.limitReached.subject')}
            />
            {s('messagePart2')}
          </Trans>
        </div>
      );
    }
    default:
      return t('proximity.dtp.error.unexpected');
  }
};

type ProximityListingProps = Readonly<{
  hiddenProximityIds: ReadonlyArray<string>;
  proximityList: ReadonlyArray<Proximity>;

  getOnExportLocationsClick: (proximityType: ProximityType) => undefined | ((proximityId: string) => void);
  onExportContainedBoundaries?: (proximityId: string) => void;
  onProximityEdit?: (proximityId: string) => void;
  onProximityGetRoute: (proximityId: string) => void;
  onProximityZoom: (proximityId: string) => void;
  onRemoveProximity: (proximityId: string) => void;
  toggleRadiusVisibility: (proximityId: string) => void;
}>;

export const ProximityListingComponent: FC<ProximityListingProps> = (props) => {
  const [proximityVisibleSettingsIndex, setProximityVisibleSettingsIndex] = useState<null | number>(null);
  const [t] = useTranslation();
  const { proximityList, onExportContainedBoundaries } = props;

  const memoizeContextCallback = useCallback((itemId: string, callback?: (itemId: string) => void) => {
    if (!callback) {
      return undefined;
    }
    return () => callback(itemId);
  },
  []);

  return (
    <div>
      {proximityList.map((item, index) => {
        let distance: ReactNode = null;
        let driveTimeLabels: ReactNode = <></>;
        let isDriveTimeDataMissing = false;

        const contextCallbacks: ProximityListingItemContextCallbacks = {
          hideSettingsMenu: () => setProximityVisibleSettingsIndex(null),
          onExportClick: memoizeContextCallback(item.id, props.getOnExportLocationsClick(item.type)),
          onExportContainedBoundaries: memoizeContextCallback(item.id, onExportContainedBoundaries),
          onProximityEdit: memoizeContextCallback(item.id, props.onProximityEdit),
          onProximityGetRoute: memoizeContextCallback(item.id, props.onProximityGetRoute),
          onProximityZoom: memoizeContextCallback(item.id, props.onProximityZoom),
          onRemoveProximity: memoizeContextCallback(item.id, props.onRemoveProximity),
          openSettingsMenu: () => setProximityVisibleSettingsIndex(index),
        };

        if (isRadiusProximity(item)) {
          distance = formatDistance(item.data.radius, 0, item.data.unit);
        }

        if (isDriveTimePolygon(item)) {
          const timeLabels = getDriveTimePolygonLabels(item.data.hours, item.data.minutes, t);
          isDriveTimeDataMissing = item.data.paths === null;

          if (timeLabels.hours) {
            driveTimeLabels = <>{timeLabels.hours}<br /></>;
          }

          if (timeLabels.minutes) {
            driveTimeLabels = <>{driveTimeLabels}{timeLabels.minutes}</>;
          }
        }

        const error = isDriveTimePolygon(item) && isDriveTimeDataMissing
          ? getDriveTimePolygonError(item.data.message, t)
          : null;

        const disabled = isDriveTimeDataMissing;

        return (
          <ProximityListingItemComponent
            key={item.id}
            contextCallbacks={contextCallbacks}
            disabled={disabled}
            distance={distance}
            driveTimeLabels={driveTimeLabels}
            error={error}
            isHidden={props.hiddenProximityIds.includes(item.id) || disabled}
            isSettingsMenuVisible={proximityVisibleSettingsIndex === index}
            item={item}
            ordinalPosition={index + 1}
            toggleVisibility={!disabled ? () => props.toggleRadiusVisibility(item.id) : undefined}
          />
        );
      })}
    </div>
  );
};
