import {
  useCallback, useMemo, useState,
} from 'react';
import { copy } from '~/_shared/utils/collections/collections';
import { useMapListingSearchQuery } from '~/store/frontendState/mapListing/mapListing.hook';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { type MapListSortType } from './mapListing.helpers';
import { useMapListingItems } from './useMapListingItems';

export const useMapListingToggleManagement = (sortType: MapListSortType) => {
  const currentUserId = useUserIdSelector();

  const [selectedMaps, setSelectedMaps] = useState<ReadonlyArray<{ id: number; name: string }>>([]);
  const [selectedSnapshotIds, setSelectedSnapshotIds] = useState<ReadonlySet<number>>(new Set());

  const { searchQuery, onSearchQueryChange } = useMapListingSearchQuery();

  const {
    mapListingItems, onToggleMapIdsChange, toggledMapIds, pagination, onPageChange, isLoading: isMapListingDataLoading,
    errorMessage, forceRefresh, changePrivacyLevel, allMapListingItems,
  } = useMapListingItems({ isActive: true, sortType, includeCurrentMapOnTop: true, searchQuery });

  const selectedMapIds: readonly number[] = useMemo(() => selectedMaps.map(map => map.id), [selectedMaps]);

  const setSelectedMapIds = useCallback((ids: readonly number[]) => {
    setSelectedMaps(ids.map(id => ({ id, name: mapListingItems.find(map => map.id === id)?.name ?? '' })));
  }, [mapListingItems]);

  const onToggleMapSelect = useCallback((mapId: number) => {
    const map = mapListingItems.find(m => m.id === mapId);
    if (!map) {
      return;
    }

    if (!selectedMapIds.includes(mapId)) {
      setSelectedSnapshotIds(copy.andAdd(selectedSnapshotIds, map.snapshots.map(s => s.id)));
    }
    else {
      setSelectedSnapshotIds(copy.andRemove(selectedSnapshotIds, map.snapshots.map(s => s.id)));
    }

    setSelectedMapIds(copy.andTogglePresence(selectedMapIds, mapId));
  }, [mapListingItems, selectedMapIds, setSelectedMapIds, selectedSnapshotIds]);

  const onToggleSnapshotSelect = useCallback((
    { mapId, snapshotId }: Readonly<{ mapId: number; snapshotId: number }>
  ) => {
    let newSnapshotIds = copy.andTogglePresence(selectedSnapshotIds, snapshotId);

    if (selectedSnapshotIds.has(snapshotId)) {
      const mainMapOfSnapshot = mapListingItems.find(m => m.id === mapId);
      if (mainMapOfSnapshot) {
        newSnapshotIds = copy.andRemove(newSnapshotIds, mainMapOfSnapshot.snapshots.filter(
          s => s.ownerId !== currentUserId
        ).map(s => s.id));
      }
      setSelectedMapIds(selectedMapIds.filter(id => id !== mapId));
    }

    setSelectedSnapshotIds(newSnapshotIds);
  }, [currentUserId, mapListingItems, selectedMapIds, selectedSnapshotIds, setSelectedMapIds]);

  const onToggleShowMapSnapshots = useCallback((mapId: number) =>
    onToggleMapIdsChange(copy.andTogglePresence(toggledMapIds, mapId)),
  [onToggleMapIdsChange, toggledMapIds]);

  return {
    errorMessage,
    isLoading: isMapListingDataLoading,
    mapListingItems,
    onPageChange,
    onToggleMapSelect,
    onToggleShowMapSnapshots,
    onToggleSnapshotSelect,
    pagination,
    selectedMapIds,
    selectedSnapshotIds,
    selectedMaps,
    setSelectedMapIds,
    setSelectedSnapshotIds,
    toggledMapIds,
    forceRefresh,
    searchQuery,
    onSearchQueryChange,
    changePrivacyLevel,
    allMapListingItems,
  };
};
