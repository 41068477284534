import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.People]: {
  views: {
    default: {
      canvasHeight: 1000,
      canvasWidth: 1000,
      iconWidth: 660,
      iconHeight: 450,
      offsetTop: 290,
    },
  },
  colors: {
    frontPerson: '#FF1616',
    backgroundPeople: '#000000',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 90] as const,
    visible: [0, 59] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/84959-people" */
      './84959-people.json'
    ),
} };

export const { People } = config;
