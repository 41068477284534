import { faUser } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  AutocompleteComponent,
  type AutocompleteCustomOption,
  AutocompleteItem,
} from '~/_shared/baseComponents/autocomplete';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type Member } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { isMobileScreen } from '../../utils/deviceDetect/deviceDetect.helpers';
import { useGetMemberListings } from './useGetMemberListings';

type ClientUsersDropdownContainerProps = Readonly<{
  onItemsChange: (newItems: Member[]) => void;
  excludedMembersFilter: (item: Member) => boolean;
  selectedItems: Member[];
}>;

export const ClientUsersDropdownContainer: FC<ClientUsersDropdownContainerProps> = (props) => {
  const { onItemsChange, excludedMembersFilter, selectedItems } = props;
  const [t] = useTranslation();
  const clientId = useClientIdSelector();

  const {
    isLoading,
    members,
  } = useGetMemberListings(clientId);

  const noItemsOption: AutocompleteCustomOption = useMemo(() => {
    return ({
      render: (inputSize, itemSize) => (
        <AutocompleteItem
          name={t('No results')}
          size={inputSize}
          subSize={itemSize}
        />
      ),
    });
  }, [t]);

  const computedMemberOptions = useMemo(() => {
    const withoutExcludedMembers = members
      .filter((member) => excludedMembersFilter(member))
      .map((member) => ({ value: member.id.toString(), name: member.name, aliases: [member.email] }));

    if (withoutExcludedMembers.length === 0) {
      return [noItemsOption];
    }

    return withoutExcludedMembers;
  }, [members, excludedMembersFilter, noItemsOption]);

  const selectedMembersToItems: string[] = useMemo(() =>
    selectedItems.map((member) => member.id.toString()),
  [selectedItems]);

  const handleItemsChange = useCallback((newMembersIds: string[]) => {
    const newMembers = newMembersIds.map((memberId) => {
      return members.find((member) => member.id.toString() === memberId);
    }).filter(notNullsy);

    onItemsChange(newMembers);
  }, [members, onItemsChange]);

  return (
    <AutocompleteComponent
      placeholder={selectedMembersToItems.length === 0 ? t('Select User...') : t('Select Another User...')}
      onChange={handleItemsChange}
      options={computedMemberOptions}
      value={selectedMembersToItems}
      isLoading={isLoading}
      icon={faUser}
      maxSelectedVisible={isMobileScreen() ? 1 : 4}
      multiple
      inPortal
      checkSelected
      isClearable
    />
  );
};
