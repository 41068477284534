import { apiPost } from '~/_shared/utils/api/api.helpers';
import type { PasswordErrorType } from '~/authorization/passwordResetPage/passwordErrorType';

type AuthenticationResponse = {
  message: string;
  refresh?: string;
  access_token_expiration?: string;
};

type CompleteRegistrationResponse = {
  message: string;
  errors?: {
    password?: (PasswordErrorType | string)[];
  };
};

export const authenticateSalesforceUser = (token: string): Promise<AuthenticationResponse> => {
  const url = `api/auth/sign-in-link/${token}`;

  return apiPost(url);
};

export const completeSalesforceRegistration = (token: string, password: string, passwordConfirmation: string): Promise<CompleteRegistrationResponse> => {
  const url = '/api/auth/register/salesforce/complete';

  return apiPost(url, {
    token,
    password,
    password_confirmation: passwordConfirmation,
  });
};
