import { useCallback } from 'react';
import { useGetBoundaryGroup } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { type BoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const useBoundaryTerritoryGroupDisplayName = () => {
  const { getBoundaryGroupById } = useGetBoundaryGroup();

  const getBoundaryTerritoryGroupName = useCallback((group: BoundaryTerritoryGroup): string => {
    const boundaryGroup = getBoundaryGroupById(group.boundaryGroupId);
    return group.settings.displayName ?? boundaryGroup?.name ?? '';
  }, [getBoundaryGroupById]);

  return {
    getBoundaryTerritoryGroupName,
  };
};
