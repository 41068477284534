import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { TinyMapColorTileComponent } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { hexAndOpacityToRGBA } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { BoundaryEditSubmitModalSelectBoundaryComponent } from '~/boundary/editMode/boundaryEditSubmitModal/boundaryEditSubmitModalSelectBoundary.component';
import { type ModalProps } from '~/modal/modalType.enum';
import { type BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import { displayNameComparer } from '~/store/boundaryItems/boundaryItems.helpers';
import { type BoundaryStateItem } from '~/store/boundaryItems/boundaryItems.state';
import { type BoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { type BoundaryEditManualMethod } from '../boundaryEditModal/boundaryEditModal.helpers';
import {
  BoundaryEditSubmitModalAction,
  getBoundaryEditMethodParam,
  getBoundaryEditSubmitModalActionOptions,
} from './boundaryEditSubmitModal.helpers';

type BoundaryEditSubmitModalProps = ModalProps<{
  isLoading: boolean;
  onSubmit: (results: BoundaryEditSubmitResults, editAnother: boolean) => void;
  boundaries: ReadonlyArray<BoundaryStateItem>;
  method: BoundaryEditManualMethod;
  selectedBoundaryId?: number;
  editedBoundaryTerritoryGroup: BoundaryTerritoryGroup;
  geometry: BoundaryCombineGeometryRequestItem[];
  submitAction?: BoundaryEditSubmitModalAction;
  isBoundarySelectDisabled: boolean;
  hideEditAnother: boolean;
  onSelectBoundaryFromMapClick: (action: BoundaryEditSubmitModalAction) => void;
}>;

type BoundaryEditResultsRemoveBoundariesFromGroup = {
  action: BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory;
  selectedBoundaryId: number;
};

type BoundaryEditResultsAddToExistingTerritory = {
  action: BoundaryEditSubmitModalAction.AddToExistingTerritory;
  selectedBoundaryId: number;
};

type BoundaryEditResultsReassignTerritory = {
  action: BoundaryEditSubmitModalAction.ReassignTerritory;
  targetBoundaryId: number;
};

export type BoundaryEditSubmitResults =
  BoundaryEditResultsRemoveBoundariesFromGroup |
  BoundaryEditResultsAddToExistingTerritory |
  BoundaryEditResultsReassignTerritory;

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
});

const submitButtonStyles = css({
  height: 40,
});

const contentStyle = css({
  overflow: 'hidden',
});

const modalContentStyle = css({
  padding: '15px 20px 10px',
});

const getMapMiniature = (color: string, lineColor: string, lineWidth: number) => (
  <TinyMapColorTileComponent
    color={hexAndOpacityToRGBA(color)}
    lineColor={hexAndOpacityToRGBA(lineColor)}
    lineWidth={Math.ceil(lineWidth / 2)}
    css={{ marginRight: 8 }}
    size="Small"
  />
);

export const BoundaryEditSubmitModalComponent: FC<BoundaryEditSubmitModalProps> = (props) => {
  const [action, setAction] = useState<BoundaryEditSubmitModalAction | null>(props.submitAction ?? null);
  const [selectedBoundaryId, setSelectedBoundaryId] = useState<number | null>(props.selectedBoundaryId ?? null);
  const [t] = useTranslation();
  const { onSelectBoundaryFromMapClick } = props;

  const isSubmitDisabled =
    props.isLoading ||
    action === null ||
    (action === BoundaryEditSubmitModalAction.AddToExistingTerritory && selectedBoundaryId === null) ||
    (action === BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory && selectedBoundaryId === null) ||
    (action === BoundaryEditSubmitModalAction.ReassignTerritory && selectedBoundaryId === null);

  const onSubmit = (editAnother: boolean) => {
    switch (action) {
      case BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory: {
        if (selectedBoundaryId === null) {
          break;
        }

        props.onSubmit({
          action: BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory,
          selectedBoundaryId,
        }, editAnother);
        break;
      }

      case BoundaryEditSubmitModalAction.AddToExistingTerritory: {
        if (selectedBoundaryId === null) {
          break;
        }

        props.onSubmit({
          action: BoundaryEditSubmitModalAction.AddToExistingTerritory,
          selectedBoundaryId,
        }, editAnother);
        break;
      }

      case BoundaryEditSubmitModalAction.ReassignTerritory: {
        if (selectedBoundaryId === null) {
          return;
        }

        props.onSubmit({
          action: BoundaryEditSubmitModalAction.ReassignTerritory,
          targetBoundaryId: selectedBoundaryId,
        }, editAnother);
        break;
      }

      default: {
        return;
      }
    }
  };

  const selectBoundaryFromMap = useCallback(() => {
    if (!action || props.isBoundarySelectDisabled) {
      return;
    }

    onSelectBoundaryFromMapClick(action);
  }, [action, props.isBoundarySelectDisabled, onSelectBoundaryFromMapClick]);

  const territorySelectOptions = useMemo(() =>
    [...props.boundaries]
      .sort(displayNameComparer)
      .map(boundary => {
        const lineColor = props.editedBoundaryTerritoryGroup.settings.style.lineColor;
        const lineWidth = props.editedBoundaryTerritoryGroup.settings.style.lineWidth;
        const fillColor = props.editedBoundaryTerritoryGroup.settings.boundaryStyle.get(boundary.id);
        const prefix = fillColor ? getMapMiniature(fillColor.color, lineColor, lineWidth) : undefined;
        return ({
          name: boundary.displayName,
          value: boundary.id,
          prefix,
        });
      }),
  [props.boundaries, props.editedBoundaryTerritoryGroup],
  );

  const selectedBoundaryName: string | null = props.boundaries.find(item => item.id === selectedBoundaryId)?.displayName ?? null;

  let labelCount = 1;
  const sameGroupBoundaryId = props.geometry?.[0]?.type === 'boundary' && props.geometry?.[0]?.boundary_group_id === props.editedBoundaryTerritoryGroup.boundaryGroupId;
  const allowReassign = props.boundaries.length >= 2 && !sameGroupBoundaryId;

  return (
    <ModalComponent
      caption={t('Territory Editing')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      contentStyle={modalContentStyle}
      leftFooterContent={props.hideEditAnother ? null : (
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          isDisabled={isSubmitDisabled}
          onClick={() => onSubmit(true)}
          text={t('Save and Keep Editing')}
        />
      )}
      confirmButton={(
        <ButtonComponent
          css={submitButtonStyles}
          text={t('Done')}
          onClick={() => onSubmit(false)}
          isDisabled={isSubmitDisabled}
        />
      )}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
    >
      <div css={contentStyle}>
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t('What would you like to do with the {{method}} areas?', getBoundaryEditMethodParam(t, props.method))}
          </div>

          <RegularDropdownComponent
            onChange={setAction}
            options={getBoundaryEditSubmitModalActionOptions(
              t,
              props.method,
              props.boundaries.length > 0,
              allowReassign
            )}
            value={action}
            inPortal
            placeholder={t('Select Action')}
          />
        </div>

        {props.boundaries.length > 0 && (action === BoundaryEditSubmitModalAction.AddToExistingTerritory || action === BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory) && (
          <div css={panelDropdownSectionStyle}>
            <div css={labelStyle}>
              {labelCount++}. {t('Select Territory')}
            </div>

            <BoundaryEditSubmitModalSelectBoundaryComponent
              placeholder={t('Select Territory')}
              isDisabled={props.isBoundarySelectDisabled}
              onGoToMapClick={selectBoundaryFromMap}
              selectedBoundaryId={selectedBoundaryId}
              onSelectedBoundaryIdChange={setSelectedBoundaryId}
              selectedBoundaryIdOptions={territorySelectOptions}
              selectedBoundaryName={selectedBoundaryName}
            />
          </div>
        )}

        {allowReassign && action === BoundaryEditSubmitModalAction.ReassignTerritory && (
          <>
            <div css={panelDropdownSectionStyle}>
              <div css={labelStyle}>
                {labelCount++}. {t('Select Target Territory')}
              </div>

              <BoundaryEditSubmitModalSelectBoundaryComponent
                placeholder={t('Select Target Territory')}
                isDisabled={props.isBoundarySelectDisabled}
                onGoToMapClick={selectBoundaryFromMap}
                selectedBoundaryId={selectedBoundaryId}
                onSelectedBoundaryIdChange={setSelectedBoundaryId}
                selectedBoundaryIdOptions={territorySelectOptions}
                selectedBoundaryName={selectedBoundaryName}
              />
            </div>
          </>
        )}
      </div>
    </ModalComponent>
  );
};
