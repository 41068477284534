import {
  type FC, memo, useMemo,
} from 'react';
import {
  LottieAnimationComponent, LottieAnimations, LottieAnimationTypes, useLottieAnimationDefaultColors,
} from '../../lottieAnimation';

export enum LoaderType {
  Filter = 1,
}

export const standardLoaderAnimationSettings = {
  type: LottieAnimationTypes.DrippingSpinner,
  segment: LottieAnimations.DrippingSpinner.segments.fastStart,
  speed: 1.5,
} as const;

export type LoaderProps = {
  className?: string;
  size: number;
  type?: LoaderType;
  color?: string;
};

const LoaderComponent: FC<LoaderProps> = (props) => {
  const defaultColors = useLottieAnimationDefaultColors();

  const commonProps = useMemo(() => ({
    className: props.className,
    loop: true,
    autoplay: true,
    size: props.size,
  }), [props.className, props.size]);

  switch (props.type) {
    case LoaderType.Filter:
      return (
        <LottieAnimationComponent
          {...commonProps}
          type={LottieAnimationTypes.Filter}
          colors={defaultColors[LottieAnimationTypes.Filter]}
        />
      );
    default:
      return (
        <LottieAnimationComponent
          {...commonProps}
          {...standardLoaderAnimationSettings}
          type={LottieAnimationTypes.DrippingSpinner}
          colors={{ fill: props.color ?? defaultColors.DrippingSpinner?.fill }}
        />
      );
  }
};

const pureComponent = memo(LoaderComponent);
export { pureComponent as LoaderComponent };
