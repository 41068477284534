import {
  type FC,
  useCallback,
} from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../_shared/utils/hooks/useSelector';
import { closeModal } from '../store/modal/modal.actionCreators';
import { createRenderModal } from './renderModal';

/***
 * Don't use this component. It should be rendered once in the app.
 */
export const AppModalRendererContainer: FC = () => {
  const dispatch = useDispatch();
  const modalStack = useSelector(s => s.modal.stack);
  const openedModal = modalStack[modalStack.length - 1];

  const onClose = useCallback(() => {
    if (!openedModal) {
      return;
    }
    openedModal.typeWithArgs?.[1]?.onClose?.();
    return dispatch(closeModal(openedModal.id));
  }, [dispatch, openedModal]);

  if (!openedModal) {
    return null;
  }

  return (
    <>
      {modalStack.map(modal => {
        return (
          <React.Fragment key={modal.id}>
            {createRenderModal({ isOpen: modal === openedModal, onClose }, modal.id)(...modal.typeWithArgs)}
          </React.Fragment>
        );
      })}
    </>
  );
};
