import { blankBoundaryStyle } from '~/boundary/settings/defaultBoundarySettings';
import { type BoundaryTerritoryGroup } from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const getBoundaryItemStyle = (boundaryTerritoryGroup: BoundaryTerritoryGroup | undefined, boundaryId?: number) => {
  if (boundaryId === undefined) {
    return blankBoundaryStyle;
  }

  return boundaryTerritoryGroup?.settings.boundaryStyle.get(boundaryId) ?? blankBoundaryStyle;
};
