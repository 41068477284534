import { FPS0_66 } from '../throttle/throttle';

const IDLE_ANIMATION_DURATION_MS = 1500;
const IDLE_ANIMATION_STEP = FPS0_66;

export type AnimationCallback = (animationPercent: number) => void;

let animationIntervalId: null | number = null;
const registeredCallbacks = new Set<AnimationCallback>();

const startAnimation = () => {
  let animationPercent = 0;
  let animationDirection = 1;

  animationIntervalId = window.setInterval(() => {

    animationPercent = animationPercent
      + animationDirection * (IDLE_ANIMATION_STEP / IDLE_ANIMATION_DURATION_MS);

    if (animationPercent >= 1) {
      animationPercent = 1 - animationPercent + 1;
      animationDirection = -1;
    }
    else if (animationPercent <= 0) {
      animationPercent = -1 * animationPercent;
      animationDirection = 1;
    }

    for (const callback of registeredCallbacks) {
      callback(animationPercent);
    }

  }, IDLE_ANIMATION_STEP);
};

const stopAnimation = () => {
  if (animationIntervalId) {
    clearInterval(animationIntervalId);
    animationIntervalId = null;
  }
};

export const registerAnimationCallback = (callback: AnimationCallback) => {
  registeredCallbacks.add(callback);

  if (animationIntervalId === null) {
    startAnimation();
  }
};

export const removeAnimationCallback = (callback: AnimationCallback) => {
  registeredCallbacks.delete(callback);

  if (registeredCallbacks.size === 0) {
    stopAnimation();
  }
};
