import {
  type FC, useMemo,
} from 'react';
import { notNull } from '../../../../../_shared/utils/typeGuards';
import { DRAWING_TOOLS_LIST } from '../../../../../drawingTool/drawingTool.enums';
import { useDrawingItemsRemove } from '../../../../../drawingTool/hooks/useDrawingItemsRemove';
import { useDrawingToolVisibility } from '../../../../../drawingTool/hooks/useDrawingToolVisibility';
import { useDrawingItemsSelector } from '../../../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import {
  DrawingToolItemsManagementComponent, type DrawingToolManagementItem,
} from './drawingToolItemsManagement.component';
import { DrawingToolNoItemsComponent } from './drawingToolNoItems.component';

export const DrawingToolItemsManagementContainer: FC = () => {
  const drawingItems = useDrawingItemsSelector();
  const { removeDrawingItems } = useDrawingItemsRemove();
  const { setDrawingToolVisibility, checkDrawingToolVisibility } = useDrawingToolVisibility();

  const items = useMemo<DrawingToolManagementItem[]>(() => {
    return DRAWING_TOOLS_LIST
      .map((drawingTool) => {
        const itemsCount = drawingItems.filter(item => item.type === drawingTool).length;

        if (itemsCount === 0) {
          return null;
        }

        return {
          drawingTool,
          itemsCount,
          areItemsVisible: checkDrawingToolVisibility(drawingTool),
        };
      })
      .filter(notNull);
  }, [drawingItems, checkDrawingToolVisibility]);

  if (items.length === 0) {
    return (
      <DrawingToolNoItemsComponent />
    );
  }

  return (
    <DrawingToolItemsManagementComponent
      onRemove={removeDrawingItems}
      onVisibilitySet={setDrawingToolVisibility}
      items={items}
    />
  );
};
