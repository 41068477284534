import { css } from '@emotion/react';
import { type FC } from 'react';

const wrapperStyle = ({ radius, leftOffset, topOffset }: {
  leftOffset?: number;
  radius?: number;
  topOffset?: number;
}) => css({
  height: radius,
  left: leftOffset ? -leftOffset : undefined,
  position: 'relative',
  top: topOffset ? -topOffset : undefined,
  width: radius,
});

const imageWrapperStyle = ({ radius }: { radius?: number}) => css({
  position: 'relative',
  height: radius,
  width: radius,
});

type ActiveMarkerIndicatorProps = {
  className?: string;
  leftOffset?: number;
  diameter?: number;
  topOffset?: number;
  children: React.ReactNode;
};

export const ActiveMarkerCircleIndicatorComponent: FC<ActiveMarkerIndicatorProps> = (props) => (
  <div
    css={wrapperStyle({
      leftOffset: props.leftOffset,
      radius: props.diameter,
      topOffset: props.topOffset,
    })}
  >
    <div
      css={imageWrapperStyle({ radius: props.diameter })}
      className={props.className}
    >
      {props.children}
    </div>
  </div>
);
