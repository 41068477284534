import { useState } from 'react';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { removeMap } from '~/map/map.repository';
import { type MapListingItem } from './item/mapListingItem';
import { getBaseMapsWithDependenciesFromResponse } from './layered/resolveLayeredMapDeleteConflicts/baseMapDeleteDependenciesResolve.helper';
import { useBaseMapDeleteDependenciesResolve } from './layered/resolveLayeredMapDeleteConflicts/useBaseMapDeleteDependenciesResolve';

export const useMapDelete = (clientId: number | null, allMapListingItems: ReadonlyArray<MapListingItem>) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsComponentMountedRef();
  const { resolveDependencies } = useBaseMapDeleteDependenciesResolve(allMapListingItems);

  const deleteMap = (maps: readonly { id: number; name: string }[], snapshots: number[], onSuccess: () => void) => {
    if (clientId === null) {
      return;
    }
    const mapIdsToRemove = maps.map(map => map.id);
    const idsToRemove = [
      ...mapIdsToRemove,
      ...removeSnapshotIdsBelongingToMapsToBeDeleted(snapshots, mapIdsToRemove, allMapListingItems),
    ];

    setIsLoading(true);
    removeMap(clientId, {
      maps: idsToRemove.map(id => ({ id })),
    })
      .then((response) => {
        const mapsToResolve = getBaseMapsWithDependenciesFromResponse(maps, response);
        resolveDependencies(mapsToResolve, onSuccess);
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    deleteMap,
  };
};

// when main map is deleted, we don't need to send it's snapshot ids as well (they will all be deleted together)
const removeSnapshotIdsBelongingToMapsToBeDeleted = (snapshotIds: number[], mapIds: number[], allMapListingItems: ReadonlyArray<MapListingItem>) => (
  snapshotIds.filter(snapshotId => (
    !allMapListingItems.find(mapItem => (
      mapIds.includes(mapItem.id)
      && !!mapItem.snapshots.find(mapsSnaphot => mapsSnaphot.id === snapshotId)
    ))
  ))
);
