import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  faCaretDown, faMap,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type MouseEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DropdownComponent, DropdownItemComponent, dropdownItemsContainerStyle, DropDownItemSize,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  InputSize, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { type MapListingItem } from '../../../map/listing/item/mapListingItem';
import { type Pagination } from '../../types/pagination/pagination';
import { useTranslation } from '../../utils/hooks';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '../overlay/overlayWrapper.component';

const DropdownTriggerButton = styled.button(({ disabled }: { disabled?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0',
  transition: 'background .1s',
  outline: 'none',
  width: '100%',
  border: 'none',
  background: 'none',
  opacity: disabled ? 0.7 : 1,
}));

const caretDownIconStyle = (isDropdownOpen: boolean) => css({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: isDropdownOpen ? 'rotate(-180deg)' : undefined,
  transition: 'transform .2s',
  width: 30,
  height: '100%',
  marginLeft: 'auto',
});

const dropdownContainerStyle = css({
  display: 'flex',
  flexGrow: 1,
});

const selectedOptionStyle = (isOpen: boolean) => css({
  borderRadius: isOpen ? '4px 4px 0 0' : 4,
});

const selectedOptionInputStyle = ({ theme, highlight }: ThemeProps<{ highlight?: boolean }>) => css({
  color: highlight ? theme.textColors.warning : undefined,
});

const loadMoreButtonStyle = css({
  fontStyle: 'italic',
});

export type MapPickerDropdownComponentProps = Readonly<{
  higlightedMapIds?: Set<number>;
  inputSize?: InputSize;
  isLoading: boolean;
  itemSize?: DropDownItemSize;
  options: ReadonlyArray<MapListingItem>;
  pagination: Pagination | null;
  placeholder?: string;
  selectedMap: MapListingItem | null;

  onMapSelected: (map: MapListingItem) => void;
  onPageChange: (page: number) => void;
  onSearchQueryChange: (query: string) => void;

  additionalAction?: ReactNode;
}>;

export const MapPickerDropdownComponent = (props: MapPickerDropdownComponentProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeMapName, setActiveMapName] = useState('');
  const isEmpty = useMemo(() => props.options.length === 0, [props.options]);
  const theme = useTheme();
  const [t] = useTranslation();

  const size = props.inputSize ?? InputSize.Medium;
  const itemSize = props.itemSize ?? DropDownItemSize.Default;

  const onMapNameChange = useCallback((mapName: string) => {
    const onSearchQueryChange = props.onSearchQueryChange;
    setActiveMapName(mapName);
    onSearchQueryChange(mapName);
  }, [props.onSearchQueryChange]);

  const toggleDropdown = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setIsDropdownOpen(isOpen => !isOpen);
  }, []);

  const onTextInputClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setIsDropdownOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    if (props.isLoading) {
      return;
    }
    setIsDropdownOpen(false);
  }, [props.isLoading]);

  useEffect(() => {
    if (props.selectedMap) {
      setActiveMapName(props.selectedMap.name);
    }
  }, [props.selectedMap]);

  const DropdownTrigger = (
    <DropdownTriggerButton>
      <TextInputComponent
        css={selectedOptionStyle(isDropdownOpen)}
        inputStyle={selectedOptionInputStyle({ theme, highlight: props.selectedMap?.id ? !!props.higlightedMapIds?.has(props.selectedMap.id) : false })}
        value={activeMapName}
        onChange={onMapNameChange}
        type="text"
        placeholder={props.placeholder}
        onClick={onTextInputClick}
        size={size}
        icon={faMap}
        rightContent={(
          <div
            css={caretDownIconStyle(isDropdownOpen)}
            onClick={toggleDropdown}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        )}
      />
    </DropdownTriggerButton>
  );

  const onChange = useCallback((item: MapListingItem) => {
    props.onMapSelected(item);
    setActiveMapName(item.name);
  }, [props]);

  const isLoadMore = useMemo(() => !isEmpty && props.pagination && props.pagination.currentPage < props.pagination.lastPage, [isEmpty, props.pagination]);

  const onLoadMore = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!props.pagination) {
      return;
    }
    props.onPageChange(props.pagination.currentPage + 1);
  }, [props]);

  return (
    <DropdownComponent
      isOpen={isDropdownOpen}
      onClose={closeDropdown}
      css={dropdownContainerStyle}
      triggerComponent={DropdownTrigger}
      inPortal
    >
      <div css={dropdownItemsContainerStyle}>
        {props.additionalAction}
        {props.options.map((item, index) => (
          <DropdownItemComponent
            key={JSON.stringify(item.id) + index}
            size={size}
            subSize={itemSize}
            onClick={() => {
              onChange(item);
            }}
            isDisabled={props.isLoading}
            name={item.name}
            highlight={props.higlightedMapIds?.has(item.id)}
          />
        ))}
        {isLoadMore && (
          <DropdownItemComponent
            css={loadMoreButtonStyle}
            size={size}
            subSize={itemSize}
            onClick={onLoadMore}
            isDisabled={props.isLoading}
            name={t('Load More...')}
          />
        )}
        {props.isLoading && (
          <OverlayWrapperComponent minHeight={100} >
            <OverlayLoaderComponent
              loaderSize={45}
            />
          </OverlayWrapperComponent>
        )}
      </div>
    </DropdownComponent>
  );
};
