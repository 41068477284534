import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '~/_shared/components/exportDataModal/exportDataModal.component';
import { convertBoundingBoxToGeometry } from '~/_shared/types/polygon/polygon.utils';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { type PolygonFilterRequest } from '~/spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { prepareAsyncCopyToClipboard } from '../../_shared/utils/clipboard/clipboard.helpers';
import {
  type ModalProps, ModalType,
} from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { useMapComponentLastBoundsSelector } from '../../store/frontendState/mapComponent/mapComponent.selectors';
import { usePrimarySpreadsheetId } from '../../store/selectors/usePrimarySpreadsheetId';
import { ExportDataFileType } from './exportDataFileType.enum';
import { ExportDataMethod } from './exportDataMethod.enum';
import {
  createSectionLabel, ExportDataSection,
} from './exportDataSection.enum';
import {
  FilterTreeType, useExportSpreadsheetData,
} from './useExportSpreadsheetData';

export type ExportDataModalContainerProps = ModalProps<{
  availableExportSections: ExportDataSection[];
}>;

export const ExportSpreadsheetDataContainer: FC<ExportDataModalContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { getExportedSpreadsheetData, isLoading, isError } = useExportSpreadsheetData();
  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds ?? null;
  const [exportMapSection, setExportMapSection] = useState(props.availableExportSections[0] || ExportDataSection.All);
  const [isSelectionSectionExpanded, setIsSelectionSectionExpanded] = useState(true);
  const [t] = useTranslation();

  const onChange = useCallback((value: string) => {
    switch (value) {
      case ExportDataSection.All:
      case ExportDataSection.Filtered:
      case ExportDataSection.FilteredTableSearch:
      case ExportDataSection.OnlyVisible:
        setExportMapSection(value);
        break;
      default:
    }
  }, []);

  const onSubmit = (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId) {
      return;
    }

    setErrorMessage(null);

    let polygonFilter: PolygonFilterRequest | null = null;
    if (exportMapSection === ExportDataSection.OnlyVisible) {
      if (lastBounds) {
        const geometries = convertBoundingBoxToGeometry(lastBounds);
        polygonFilter = {
          type: 'or',
          polygons: geometries.map(g => ({ path: g })),
        };
      }
    }

    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();

    return getExportedSpreadsheetData({
      virtualSpreadsheetId,
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      polygonFilter: polygonFilter || undefined,
      exportAsText: arg.method === ExportDataMethod.Clipboard,
      filterTreeType: exportMapSection === ExportDataSection.All
        ? FilterTreeType.None
        : exportMapSection === ExportDataSection.FilteredTableSearch
          ? FilterTreeType.TableSearch
          : undefined,
    })
      .then(response => {
        if (arg.method === ExportDataMethod.Clipboard) {
          const fileContent = response.data[0]?.result?.content;

          if (!fileContent) {
            setErrorMessage(t('No locations to export'));
            return;
          }
          asyncCopyToClipboard(fileContent);
          showCopiedToClipboardMessage();
        }
        else {
          const fileName = response.data[0]?.result?.file_name;

          if (!fileName) {
            setErrorMessage(t('No locations to export'));
            return;
          }

          const exportUrl = getExportUrl(fileName);
          downloadFile(exportUrl);

          props.onClose();
          openDownloadResultsModal({
            downloadUrl: exportUrl,
          });
        }
      });
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  useEffect(() => {
    setErrorMessage(null);
  }, [exportMapSection, lastBounds]);

  return (
    <ExportDataModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      error={errorMessage}
      sectionsBeforeExportMethod={props.availableExportSections.length > 1 ? [{
        header: t('Select Data to Export'),
        isExpanded: isSelectionSectionExpanded,
        onHeadingClick: () => setIsSelectionSectionExpanded(!isSelectionSectionExpanded),
        child: (
          <RadioGroupComponent
            onValueChange={onChange}
            selectedValue={exportMapSection}
            items={props.availableExportSections.map(section => ({
              value: section,
              label: createSectionLabel(section, t),
            }))}
          />),
      }] : undefined}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
    />
  );
};
