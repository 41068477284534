import { type UndoAction } from './undo.action';
import {
  ALL_UNDO_ITEMS_FETCH_DATA_SUCCESS,
  DELETE_UNDO_ITEM,
  DELETED_MAPS_FETCH_DATA_ERROR, DELETED_MAPS_FETCH_DATA_REQUEST,
} from './undo.actionTypes';
import { type UndoState } from './undo.state';

const initialState: UndoState = {
  isProcessing: false,
  data: [],
};

export const undoReducer = (state = initialState, action: UndoAction): UndoState => {
  switch (action.type) {
    case DELETED_MAPS_FETCH_DATA_REQUEST: {
      return {
        ...state,
        isProcessing: true,
        data: [],
      };
    }

    case DELETED_MAPS_FETCH_DATA_ERROR: {
      return {
        ...state,
        isProcessing: false,
      };
    }

    case ALL_UNDO_ITEMS_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        data: action.payload.data,
      };
    }

    case DELETE_UNDO_ITEM: {
      return {
        ...state,
        data: state.data.filter(item => item !== action.payload.data),
      };
    }

    default:
      return state;
  }
};
