import {
  put, takeEvery,
} from 'redux-saga/effects';
import { mutuallyExclusiveMapTools } from './mutuallyExclusiveMapTools';

type ConflictingAction = Action & { mutuallyExclusiveMapToolProcessed?: boolean };

function* disableMutuallyExclusiveMapTools(action: ConflictingAction) {
  for (const feature of mutuallyExclusiveMapTools) {
    if (feature.activator !== action.type && !feature.ignore?.includes(action.type)) {
      yield put(feature.off());
    }
  }

  action.mutuallyExclusiveMapToolProcessed = true;
  yield put(action);
}

const mutuallyExclusiveMapToolPattern = (action: ConflictingAction) =>
  !action.mutuallyExclusiveMapToolProcessed && !!mutuallyExclusiveMapTools.find(f => f.activator === action.type);

export function* mutuallyExclusiveMapToolsSagas() {
  yield takeEvery(mutuallyExclusiveMapToolPattern, disableMutuallyExclusiveMapTools);
}
