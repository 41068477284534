import {
  useCallback, useState,
} from 'react';
import { MFAErrorCode } from '~/_shared/types/responseErrors/_shared/MFAErrorCodes.types';
import type {
  UpdateUserErrorResponse, UpdateUserSuccessResponse,
} from '~/store/userData/repository/updateUserData.types';
import {
  updateUserData, type UpdateUserRequest, type UserResponse,
} from '~/store/userData/repository/userData.repository';
import { useAjaxCall } from '../../utils/hooks/useAjaxCall';

export function is2FactorCodeRequired(code?: string): code is MFAErrorCode {
  const MFAErrorCodesSet = new Set(Object.values(MFAErrorCode));
  return !!code && MFAErrorCodesSet.has(code as MFAErrorCode);
}

export const useUpdateUserData = () => {
  const { isLoading, invokeAjax } = useAjaxCall(updateUserData);
  const [error, setError] = useState<UpdateUserErrorResponse | null>(null);

  const changeUserData = useCallback((request: UpdateUserRequest): Promise<{
    data?: UserResponse;
    error?: UpdateUserErrorResponse;
  }> =>
    invokeAjax(request)
      .then((data: UpdateUserSuccessResponse) => {
        setError(null);
        if (is2FactorCodeRequired(data.code)) {
          const twoFAError: UpdateUserErrorResponse = { errorCode: data.code };
          setError(twoFAError);
          return {
            error: twoFAError,
          };
        }
        else {
          return {};
        }
      }).catch((e: UpdateUserErrorResponse) => {
        setError(e);
        return { error: e };
      }), [invokeAjax]);

  return {
    isLoading,
    error,
    changeUserData,
  };
};
