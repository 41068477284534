export type BaseMap = {
  id: number;
  version: number;
  snapshot: number;
  name: string;
  newUpdates: number;
};

export enum BaseMapsUpdateModalAction {
  Apply = 'Apply',
  AskMeLater = 'AskMeLater',
  SkipUpdate = 'SkipUpdate',
  NeverForThisBaseMap = 'NeverForThisBaseMap',
}

export type BaseMapsUpdateModalActionForMap = {
  baseMap: BaseMap;
  action: BaseMapsUpdateModalAction;
};
