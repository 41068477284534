import { css } from '@emotion/react';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import React, { type PropsWithChildren } from 'react';
import {
  ACCORDION_HEADER_SIZE_LARGE, AccordionComponent,
} from '~/_shared/baseComponents/accordion';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const searchHeaderWrapperStyle = css({
  boxSizing: 'border-box',
  height: 'unset',
  marginRight: 0,
  minHeight: ACCORDION_HEADER_SIZE_LARGE,
  padding: '12px 16px',
});

const accordionHeaderWrapperStyle = css({
  marginRight: 0,
});

const listingQueryInputStyle = ({ isOpen, theme }: ThemeProps<{ isOpen: boolean }>) => css({
  height: isOpen ? undefined : 0,
  border: isOpen ? undefined : 0,
  marginTop: isOpen ? 17 : 0,
  paddingTop: isOpen ? undefined : 0,
  paddingBottom: isOpen ? undefined : 0,
  transition: 'visibility .25s, opacity .25s, height .25s, margin .25s, padding .25s',
  visibility: isOpen ? 'visible' : 'hidden',
  opacity: isOpen ? 1 : 0,

  '&:focus-within': {
    background: theme.backgroundColors.primary,
  },
});

const listingHeaderIconStyle = ({ theme }: ThemeProps) => css({
  fontSize: '20px',

  '&:hover': {
    color: theme.iconColors.quinary,
  },
});

const listingQueryTriggerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 1, //to prevent the text from jumping
  textTransform: 'uppercase',
  width: '100%',
});

const childWrapperStyle = css({
  padding: 0,
});

type SidebarSearchHeaderProps = Readonly<{
  isOpen: boolean;
  title: string;
  searchPhrase: string;
  searchPlaceholder: string;

  onChangeSearchPhrase: (phrase: string) => void;
  onClick: () => void;
}>;

export const SidebarSearchHeaderComponent: React.FC<PropsWithChildren<SidebarSearchHeaderProps>> = props => {
  const theme = useTheme();

  return (
    <AccordionComponent
      itemButtonStyle={searchHeaderWrapperStyle}
      data={[{
        child: props.children,
        header: (
          <>
            <div
              css={listingQueryTriggerStyle}
              onClick={props.onClick}
            >
              {props.title}
              <FontAwesomeIcon
                css={listingHeaderIconStyle({ theme })}
                icon={faMagnifyingGlass}
              />
            </div>

            <TextInputComponent
              css={listingQueryInputStyle({ theme, isOpen: props.isOpen })}
              onChange={props.onChangeSearchPhrase}
              value={props.searchPhrase}
              placeholder={props.searchPlaceholder}
              icon={faMagnifyingGlass}
            />
          </>
        ),
        isExpanded: true,
        isLocked: true,
      }]}
      headerWrapperStyle={accordionHeaderWrapperStyle}
      panelStyle={childWrapperStyle}
    />
  );
};
