import { isTextEmpty } from '~/_shared/utils/text/text.helpers';

export const handleNumericInputChange = (newValue: string, setter: (value: number | null) => void) => {
  if (newValue === '') {
    setter(null);
  }

  const parsedValue = parseInt(newValue, 10);

  if (!isNaN(parsedValue)) {
    setter(parsedValue);
  }
};

export const autoFocus = (input: HTMLInputElement | null) => {
  input?.focus();
};

export const validateNumericInputValue = (value: string) => {
  return validateNumberInputValue(value, { isDecimal: false });
};

export const validateDecimalInputValue = (value: string) => {
  return validateNumberInputValue(value, { isDecimal: true });
};

const validateNumberInputValue = (value: string, { isDecimal }: {isDecimal: boolean }) => {
  if (isTextEmpty(value) || value.endsWith('.')) {
    return false;
  }
  const parsedValueTo = isDecimal ? parseFloat(value) : parseInt(value, 10);
  return !isNaN(parsedValueTo);
};

export const validateValueHasOnlyNumericCharacters = (value: string, { isDecimal }: { isDecimal: boolean }): boolean => {
  if (/[^0-9\-.]/.test(value)) {
    return false;
  }

  const splitValue = value.split('');
  const dotsCount = splitValue.filter(sign => sign === '.').length;
  if (dotsCount > 1 || (!isDecimal && dotsCount > 0)) {
    return false;
  }

  if (splitValue.slice(1).includes('-')) {
    return false;
  }

  return true;
};
