import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import { mq } from '~/_shared/style.mixins';
import { MOBILE_VERSION_WIDTH_THRESHOLD } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const markerFrameStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 4,
});

const titleStyle = css({
  display: 'none',

  [mq(MOBILE_VERSION_WIDTH_THRESHOLD)]: {
    display: 'inherit',
  },
});

type BubbleMarkerLabelFrameProps = Readonly<{
  children: ReactNode;
  title: string;
}>;

export const BubbleMarkerLabelFrameComponent: React.FC<BubbleMarkerLabelFrameProps> = props => (
  <div css={wrapperStyle} >
    <div css={markerFrameStyle} >
      {props.children}
    </div>
    <span css={titleStyle}> {props.title} </span>
  </div>
);
