import { type MapSettingsSearchItemsAction } from './mapSettingsSearchItems.action';
import {
  MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM,
  MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ITEM,
  MAP_SETTINGS_SEARCH_ITEMS_UPDATE_ITEMS,
} from './mapSettingsSearchItems.actionTypes';
import { type MapSettingsSearchItemsState } from './mapSettingsSearchItems.state';

export const mapSettingsSearchItemsInitialState: MapSettingsSearchItemsState = {
  items: [],
};

export const mapSettingsSearchItemsReducer = (state = mapSettingsSearchItemsInitialState,
  action: MapSettingsSearchItemsAction): MapSettingsSearchItemsState => {
  switch (action.type) {
    case MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM: {
      return {
        ...state,
        items: [...state.items, action.payload.item],
      };
    }

    case MAP_SETTINGS_SEARCH_ITEMS_UPDATE_ITEMS: {
      const finalItems = state.items.map(item =>
        action.payload.updatedItems.find(updatedItem => updatedItem.id === item.id) ?? item);

      return {
        ...state,
        items: finalItems,
      };
    }

    case MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ITEM: {
      const updatedItems = state.items.filter(item => item.id !== action.payload.id);
      return updatedItems.length === state.items.length ? state : { ...state, items: updatedItems };
    }

    case MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ALL_ITEMS:
      return {
        ...state,
        items: mapSettingsSearchItemsInitialState.items,
      };

    default:
      return state;
  }
};
