import { type FC } from 'react';
import { type UploadStatus } from '~/_shared/components/uploadStatus/uploadStatus.component';
import { ImportMethod } from '~/_shared/types/importMethod/importMethod';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { ImportDataComponent } from './importData.component';
import { useImportFileData } from './useImportFileData';

export type ImportDataContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  initialImportMethod?: ImportMethod;
}>;

export const ImportDataContainer: FC<ImportDataContainerProps> = (props) => {
  const clientId = useClientIdSelector();
  const userId = useUserIdSelector();
  const spreadsheetId = usePrimarySpreadsheetId();
  const mapId = useMapIdSelector();

  const { replaceCurrentModal: replaceCurrentModalWithImportDataModal } = useModal(ModalType.ImportData);
  const { importFileData, isLoading, error, clearErrorMessage } = useImportFileData();

  if (!clientId || !userId || !mapId) {
    return null;
  }

  const onImportDataSubmit = (importMethod: ImportMethod, file: File,
    onProgressChange: (progress: number) => void, onStatusChange: (status: UploadStatus) => void) => {
    if (!spreadsheetId) {
      return;
    }

    importFileData({
      clientId, userId, mapId, spreadsheetId, importMethod, file, onProgressChange, onStatusChange,
    })
      .then(() => {
        props.onClose();
      })
      .catch(() => null);
  };

  const onReplaceImportMethodSelect = () => {
    replaceCurrentModalWithImportDataModal({
      initialImportMethod: ImportMethod.Replace,
    });
  };

  return (
    <ImportDataComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={onImportDataSubmit}
      initialImportMethod={props.initialImportMethod}
      isLoading={isLoading}
      error={error}
      onErrorMessageClose={clearErrorMessage}
      onReplaceImportMethodSelect={onReplaceImportMethodSelect}
    />
  );
};
