import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  AccordionComponent, type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { MapPrivacySelectorComponent } from '~/_shared/components/mapPrivacySelector/mapPrivacySelector.component';
import { type MapPrivacyRestrictions } from '~/_shared/components/mapPrivacySelector/useMapPrivacyRestrictions.hook';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { type MapPrivacyLevel } from '~/_shared/types/map';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ShareMapEmailResult } from '~/map/map.repository';
import { MapNameSectionComponent } from '../basicInfo/mapNameSection';
import {
  type ShareMethod,
  ShareSectionComponent,
} from './sections/shareSection.component';
import {
  StartingMapViewSectionComponent,
  type StartingView,
} from './sections/startingMapViewSection.component';
import { ShareInputField } from './shareInputField.enum';

export enum ShareMapSection {
  PrivacyLevel = 'PrivacyLevel',
  StartingMap = 'StartingMap',
  Share = 'Share',
}

const modalContentStyle = css({
  padding: 0,
});

const accordionLabelStyle = css({
  textTransform: 'uppercase',
  fontSize: '16px',
});

const radioGroupStyle = css({
  gap: 30,
});

const radioItemStyle = css({
  flexDirection: 'column',
  flexWrap: 'nowrap', //the default wrap makes the element taller
  lineHeight: 1,
});

const accordionPanelStyle = css({
  padding: '30px 25px',
});

const accordionItemStyle = css({
  textTransform: 'uppercase',
});

type ShareMapProps = Readonly<{
  expandedSections: ReadonlySet<ShareMapSection>;
  fieldErrors: ReadonlyMap<ShareInputField, ReadonlyArray<string>>;
  globalError: string | null;
  isCalledFromMap: boolean;
  isLoading: boolean;
  isOpen: boolean;
  isSnapshot: boolean;
  lockedSections: ReadonlySet<ShareMapSection>;
  mapName: string;
  mapPrivacyRestrictions: MapPrivacyRestrictions;
  privacyLevel: MapPrivacyLevel | null;
  shareId: string;
  shareMethod: ShareMethod | null;
  startingMap: StartingView | null;
  sendEmailResult: ShareMapEmailResult | null;

  onChangeShareMethod: (method: ShareMethod) => void;
  onChangeStartingMap: (map: StartingView) => void;
  onClearFieldError: (field: ShareInputField) => void;
  onClearGlobalError: () => void;
  onClose: () => void;
  onMapFormSubmit: (mapName: string) => void;
  onPrivacyLevelChange: (level: MapPrivacyLevel, password?: string) => void;
  onToggleSection: (section: ShareMapSection) => void;
  onSubmitSendEmail: (emails: ReadonlyArray<string>, optionalMessage: string) => void;
}>;

export const ShareMapComponent: FC<ShareMapProps> = (props) => {
  const [t] = useTranslation();

  const privacyLevelSection: AccordionData = {
    isExpanded: props.expandedSections.has(ShareMapSection.PrivacyLevel),
    onHeadingClick: () => props.onToggleSection(ShareMapSection.PrivacyLevel),
    header: t('Select Privacy Level'),
    child: (
      <MapPrivacySelectorComponent
        disabledPrivacyLevels={props.mapPrivacyRestrictions.disabledPrivacyLevels}
        globalError={props.mapPrivacyRestrictions.warning}
        onChange={props.onPrivacyLevelChange}
        onClearPasswordError={() => props.onClearFieldError(ShareInputField.Password)}
        passwordError={props.fieldErrors.get(ShareInputField.Password)?.[0] ?? undefined}
        privacyLevel={props.privacyLevel}
        radioGroupStyle={radioGroupStyle}
      />
    ),
  };

  const startingMapSection: AccordionData = {
    isExpanded: !props.lockedSections.has(ShareMapSection.StartingMap) && props.expandedSections.has(ShareMapSection.StartingMap),
    isLocked: props.lockedSections.has(ShareMapSection.StartingMap),
    onHeadingClick: () => props.onToggleSection(ShareMapSection.StartingMap),
    header: t('Select Starting Map View'),
    child: (
      <StartingMapViewSectionComponent
        accordionLabelStyle={accordionLabelStyle}
        isCalledFromMap={props.isCalledFromMap}
        key={`${props.isOpen}`}
        mapNameErrors={props.fieldErrors.get(ShareInputField.MapName) ?? null}
        onChange={props.onChangeStartingMap}
        onClearMapNameErrors={() => props.onClearFieldError(ShareInputField.MapName)}
        onMapNameFormSubmitted={props.onMapFormSubmit}
        radioGroupStyle={radioGroupStyle}
        value={props.startingMap}
      />
    ),
  };

  const sharingMethodSection: AccordionData = {
    isExpanded: !props.lockedSections.has(ShareMapSection.Share) && props.expandedSections.has(ShareMapSection.Share),
    isLocked: props.lockedSections.has(ShareMapSection.Share),
    onHeadingClick: () => props.onToggleSection(ShareMapSection.Share),
    header: t('Select Sharing Method'),
    child: (
      <ShareSectionComponent
        accordionLabelStyle={accordionLabelStyle}
        key={`${props.isOpen}`}
        onChange={props.onChangeShareMethod}
        onSubmitSendEmail={props.onSubmitSendEmail}
        radioGroupStyle={radioGroupStyle}
        radioItemStyle={radioItemStyle}
        sendEmailResult={props.sendEmailResult}
        shareId={props.shareId}
        value={props.shareMethod}
      />
    ),
  };

  return (
    <ModalComponent
      caption={t('Share Your Map')}
      contentStyle={modalContentStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      {props.globalError && (
        <AlertComponent
          type="danger"
          onClose={props.onClearGlobalError}
        >
          {props.globalError}
        </AlertComponent>
      )}

      <MapNameSectionComponent
        isSnapshot={props.isSnapshot}
        name={props.mapName}
      />
      <AccordionComponent
        data={[privacyLevelSection, startingMapSection, sharingMethodSection]}
        itemButtonStyle={accordionItemStyle}
        showCount
        panelStyle={accordionPanelStyle}
      />
    </ModalComponent>
  );
};
