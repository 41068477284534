import { useCallback } from 'react';
import { type FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { ErrorTypes } from '~/_shared/constants/error.constants';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { type UploadItem } from '../../../map/createNew/createNewMap.component';
import { createAppError } from '../../../store/modal/modal.actionCreators';
import { type FileError } from '../../types/fileError.types';
import { type ImportMethod } from '../../types/importMethod/importMethod';
import {
  type TranslationFnc, useTranslation,
} from '../../utils/hooks';
import { UploadStatus } from '../uploadStatus/uploadStatus.component';

export const isFileAccepted = (filename: string, acceptedFileExtensions?: ReadonlyArray<string>): boolean => {
  if (!acceptedFileExtensions) {
    return true;
  }

  for (const extension of acceptedFileExtensions) {
    const ext = '.' + extension.toLowerCase();
    ext.replace('..', '.');

    if (filename.toLowerCase().includes(ext)) {
      return true;
    }
  }

  return false;
};

export const isMapNowButtonEnabled = (importMethod: ImportMethod | null, uploadItems: UploadItem[]): boolean => {
  if (!importMethod) {
    return false;
  }

  let hasAtLeastOneUploaded = false;

  for (const item of uploadItems) {
    if (item.status === UploadStatus.Success) {
      hasAtLeastOneUploaded = true;
    }

    if (item.status === UploadStatus.InProgress) {
      return false;
    }
  }

  return hasAtLeastOneUploaded;
};

export const translateDropzoneError = (errorMessage: string, t: TranslationFnc, allowedFileExtensions?: ReadonlyArray<string>): string => {
  switch (errorMessage) {
    case ErrorTypes.Extension:
      return t('Invalid file type.')
        + (allowedFileExtensions ? ' ' + t('Allowed') + ': ' + allowedFileExtensions.join(', ') : '');
    default:
      return errorMessage;
  }
};

export const useFileUploadError = (allowedFileExtensions: ReadonlyArray<string>) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const showFileUploadError = useCallback((rejections: ReadonlyArray<FileRejection>) => {
    if (rejections.length === 0) {
      return;
    }
    const errorList = rejections.map(reject => {
      const error: FileError = {
        fileName: reject.file.name,
        errorMessage: reject.errors.map((err) =>
          translateDropzoneError(err.message, t, allowedFileExtensions)
        ),
      };
      return error;
    }).map((error, index) => (
      <li key={index}>
        {error.fileName && error.fileName + ': '}
        {error.errorMessage && error.errorMessage.join(', ')}
      </li>
    ));

    dispatch(createAppError({
      type: AppErrorType.General,
      title: t('Uploading File Problem'),
      content: (
        <div>
          <p>{t('We were unable to proceed with uploading your files. Please fix these issues and try again')}:</p>
          <ul>{errorList}</ul>
        </div>
      ),
    }));
  }, [allowedFileExtensions, dispatch, t]);

  return {
    showFileUploadError,
  };
};
