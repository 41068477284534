import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { guaranteeHash } from '../_shared/components/colorPicker/colorPicker.helpers';
import { GroupRadiusEditLevel } from '../_shared/types/proximity/proximity.enums';
import {
  type DriveTimePolygonProximitySettings,
  type GroupRadiusProximity,
  type IndividualRadiusProximity,
  isDriveTimePolygon,
  isDriveTimePolygonSettings,
  isGroupRadius,
  isIndividualRadius,
  type Proximity,
  type ProximityStyles,
  type RadiusProximityBasicData,
} from '../_shared/types/proximity/proximity.types';
import { type SpreadsheetColumnId } from '../_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '../_shared/types/spreadsheetData/spreadsheetRow';
import { type TranslationFnc } from '../_shared/utils/hooks';
import { type RadiusFilterRequestArguments } from '../spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import { type SpreadsheetLatLngRowData } from '../store/selectors/spreadsheetDataMemoizedSelectors';
import {
  DataType, type GroupId, type SpreadsheetDataData, Unfiltered,
} from '../store/spreadsheetData/spreadsheetData.state';
import { type ProximityEditProperties } from './edit/proximityEdit.component';

export const getDriveTimePolygonLabels = (hours: number, minutes: number, t: TranslationFnc): {minutes: string; hours: string } => {
  const results = {
    hours: '',
    minutes: '',
  };

  if (hours > 0) {
    if (hours === 1) {
      results.hours = t('{{hour}} hr', { hour: hours });
    }
    else {
      results.hours = t('{{hours}} hrs', { hours });
    }
  }

  if (minutes > 0) {
    if (minutes === 1) {
      results.minutes = t('{{minute}} min', { minute: minutes });
    }
    else {
      results.minutes = t('{{minutes}} mins', { minutes });
    }
  }

  return results;
};

export const getDriveTimePolygonShortLabel = (hours: number, minutes: number): string => {
  const timeLabels = [];

  if (hours > 0) {
    timeLabels.push(`${hours}H`);
  }
  if (minutes > 0) {
    timeLabels.push(`${minutes}M`);
  }

  return timeLabels.join(':');
};

export const getGroupRadiusProximityDetails = (
  proximity: GroupRadiusProximity,
  spreadsheetRowId: SpreadsheetRowId | undefined
): RadiusProximityBasicData => {
  if (!spreadsheetRowId) {
    return {
      name: proximity.name,
      radius: proximity.data.radius,
      styles: proximity.styles,
      unit: proximity.data.unit,
    };
  }

  const spreadsheetRowIdSpecificData = proximity.data.data.individualOverride[spreadsheetRowId.spreadsheetId]?.[spreadsheetRowId.rowId];

  return {
    name: spreadsheetRowIdSpecificData?.name ?? proximity.name,
    radius: spreadsheetRowIdSpecificData?.radius ?? proximity.data.radius,
    unit: spreadsheetRowIdSpecificData?.unit ?? proximity.data.unit,
    styles: spreadsheetRowIdSpecificData?.styles ?? proximity.styles,
  };
};

export const applyIndividualDetailsToProximity = (proximity: GroupRadiusProximity, spreadsheetRowId: SpreadsheetRowId | undefined): GroupRadiusProximity => {
  const details = getGroupRadiusProximityDetails(proximity, spreadsheetRowId);

  return {
    ...proximity,
    name: details.name,
    styles: details.styles,
    data: {
      ...proximity.data,
      radius: details.radius,
      unit: details.unit,
    },
  };
};

const createProximityStylesFromEditProperties = (proximityProperties: ProximityEditProperties): ProximityStyles => {
  return {
    borderColor: guaranteeHash(proximityProperties.borderLineColor.hex),
    borderWidth: proximityProperties.borderLineWidth,
    color: guaranteeHash(proximityProperties.fillColor.hex),
    fillOpacity: proximityProperties.fillOpacity,
    borderOpacity: proximityProperties.borderOpacity,
  };
};

const getUpdatedIndividualProximity = (
  proximity: IndividualRadiusProximity,
  proximityProperties: ProximityEditProperties
): IndividualRadiusProximity => {
  return {
    ...proximity,
    data: {
      ...proximity.data,
      unit: proximityProperties.unit,
      radius: proximityProperties.distance ?? 0,
    },
    name: proximityProperties.name,
    styles: createProximityStylesFromEditProperties(proximityProperties),
  };
};

export const parseDriveTime = (hours: number, minutes: number) => {
  const resultMinutes = minutes % 60;
  const resultHours = hours + Math.floor(minutes / 60);

  return {
    hours: resultHours,
    minutes: resultMinutes,
  };
};

const getUpdatedDriveTimeProximitySettings = (
  proximity: DriveTimePolygonProximitySettings,
  proximityProperties: ProximityEditProperties
): DriveTimePolygonProximitySettings => {

  const { hours, minutes } = parseDriveTime(proximityProperties.hours ?? 0, proximityProperties.minutes ?? 0);

  return {
    ...proximity,
    data: {
      ...proximity.data,
      hours,
      minutes,
    },
    name: proximityProperties.name,
    styles: createProximityStylesFromEditProperties(proximityProperties),
  };
};

const getUpdatedGroupRadiusProximity = (
  proximity: GroupRadiusProximity,
  proximityProperties: ProximityEditProperties,
  spreadsheetRowId?: SpreadsheetRowId
): GroupRadiusProximity => {
  const newStyles = createProximityStylesFromEditProperties(proximityProperties);

  if (proximityProperties.radiusApplyTo === GroupRadiusEditLevel.IndividualLocation) {
    if (!spreadsheetRowId) {
      return proximity;
    }

    return {
      ...proximity,
      data: {
        ...proximity.data,
        data: {
          ...proximity.data.data,
          individualOverride: {
            ...proximity.data.data.individualOverride,
            [spreadsheetRowId.spreadsheetId]: {
              ...proximity.data.data.individualOverride[spreadsheetRowId.spreadsheetId] ?? {},
              [spreadsheetRowId.rowId]: {
                ...proximity.data.data.individualOverride[spreadsheetRowId.spreadsheetId]?.[spreadsheetRowId.rowId] ?? {},
                radius: proximityProperties.distance ?? 0,
                name: proximityProperties.name,
                styles: newStyles,
                unit: proximityProperties.unit,
              },
            },
          },
        },
      },
    };
  }
  else {
    return {
      ...proximity,
      name: proximityProperties.name,
      styles: newStyles,
      data: {
        ...proximity.data,
        radius: proximityProperties.distance ?? 0,
        unit: proximityProperties.unit,
        data: {
          // clear out any individual proximity overrides so no spread operator here
          columnId: proximity.data.data.columnId,
          group: proximity.data.data.group,
          individualOverride: {},
        },
      },
    };
  }
};

export const getUpdatedProximity = (
  proximity: Proximity,
  proximityProperties: ProximityEditProperties,
  spreadsheetRowId?: SpreadsheetRowId
): Proximity => {
  if (isIndividualRadius(proximity)) {
    return getUpdatedIndividualProximity(proximity, proximityProperties);
  }

  if (isDriveTimePolygonSettings(proximity)) {
    return getUpdatedDriveTimeProximitySettings(proximity, proximityProperties);
  }

  if (isGroupRadius(proximity)) {
    return getUpdatedGroupRadiusProximity(proximity, proximityProperties, spreadsheetRowId);
  }

  // eslint-disable-next-line no-console
  console.log('Proximity not recognized!');
  return proximity;
};

const EMPTY_ARGUMENTS = { circles: [], multiPolygons: [] };

export const createProximityFilterArgumentsFromProximity = (
  activeProximity: Proximity,
  latLngLookup: SpreadsheetLatLngRowData,
  // if group radius and selected row is provided only given radius is added to the filter
  selectedSpreadsheetRowId: SpreadsheetRowId | null,
  // if group radius and single selected row is not provided, pass full data to use radiuses of all group rows
  spreadsheetData?: SpreadsheetDataData
): {
  circles: RadiusFilterRequestArguments[];
  multiPolygons: MultiPolygon[];
} => {
  if (isIndividualRadius(activeProximity)) {
    return {
      ...EMPTY_ARGUMENTS,
      circles: [{
        radius: activeProximity.data.radius,
        unit: activeProximity.data.unit,
        center: {
          lat: activeProximity.data.data.lat,
          lng: activeProximity.data.data.lng,
        },
      }],
    };
  }

  if (isGroupRadius(activeProximity)) {
    const results = [];

    let spreadsheetRowIds: SpreadsheetRowId[] = [];

    if (selectedSpreadsheetRowId) {
      spreadsheetRowIds.push(selectedSpreadsheetRowId);
    }
    else {
      if (!spreadsheetData) {
        return EMPTY_ARGUMENTS;
      }

      spreadsheetRowIds = findRowIdsToRenderForColumnIdInGroupMemoized(spreadsheetData, activeProximity.data.data.columnId, activeProximity.data.data.group)
        .map(rowId => ({
          rowId,
          spreadsheetId: activeProximity.data.data.columnId.spreadsheetId,
        }));
    }

    for (const spreadsheetRowId of spreadsheetRowIds) {
      const center = latLngLookup.getRow(spreadsheetRowId);

      if (!center) {
        return EMPTY_ARGUMENTS;
      }

      const radiusDetails = getGroupRadiusProximityDetails(activeProximity, spreadsheetRowId);
      results.push({
        radius: radiusDetails.radius,
        unit: radiusDetails.unit,
        center,
      });
    }

    return { ...EMPTY_ARGUMENTS, circles: results };
  }

  if (isDriveTimePolygon(activeProximity)) {
    const paths = activeProximity.data.paths;
    const multiPolygons: MultiPolygon[] = paths ? [paths] : [];

    return { ...EMPTY_ARGUMENTS, multiPolygons };
  }

  return EMPTY_ARGUMENTS;
};

const findRowIdsToRenderForColumnIdInGroup = (
  columnId: SpreadsheetColumnId, groupId: GroupId, spreadsheetData: SpreadsheetDataData
): ReadonlyArray<CombinedRowId> => {
  const data = spreadsheetData.values[columnId.spreadsheetId]?.[Unfiltered]?.[columnId.columnId]?.[DataType.GROUP];
  const groupIndex = data?.extra?.uniqueGroups?.findIndex(g => g.id === groupId) ?? -1;

  return Object.entries(data?.values ?? {})
    .filter(([, groupIndexRef]) => groupIndexRef === groupIndex)
    .map(([rowId]) => rowId)
    .filter(rowId => !!rowId);
};

const findRowIdsToRenderForColumnIdInGroupMemoized = memoizeWeak((
  // Use spreadsheet data as "weak-reference" (first argument).
  // It's very large data set, we don't want older copies in the memory.
  spreadsheetData: SpreadsheetDataData, columnId: SpreadsheetColumnId, groupId: GroupId
) => findRowIdsToRenderForColumnIdInGroup(columnId, groupId, spreadsheetData));

export { findRowIdsToRenderForColumnIdInGroupMemoized as findRowIdsToRenderForColumnIdInGroup };
