import { useCallback } from 'react';
import {
  createUuid, type Uuid,
} from '~/_shared/utils/createUuid';
import { useMap } from '~/map/map/mapContext';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { createCyclicArray } from './cyclicArray';
import { useCalculateMidpoint } from './useCalculateMidpoint';

export type GhostPathOutline = {
  id: Uuid;
  lat: number;
  lng: number;
  isGhost: boolean;
};

export type GhostPath = { outlines: Array<GhostPathOutline> };

export const useAddGhostOutlines = () => {
  const map = useMap();
  const { calculateMidpoint } = useCalculateMidpoint(map);

  const addGhostsToOutlines = useCallback((outlines: MapObjectOutlineInstance[], isPolygon: boolean) => {
    const cyclicOutlines = createCyclicArray(outlines);

    const pathWithGhosts = outlines.reduce((acc: GhostPath, outline, currentIndex) => {
      const isLastOutline = currentIndex === outlines.length - 1;
      const nextOutline = cyclicOutlines.getByIndex(currentIndex + 1);
      const ghostOutlinePosition = calculateMidpoint(nextOutline, outline);

      if (!ghostOutlinePosition || (!isPolygon && isLastOutline)) {
        return {
          outlines: [...acc.outlines, { ...outline, isGhost: false }],
        };
      }

      const id = createUuid();
      const ghostOutline = { lat: ghostOutlinePosition.lat, lng: ghostOutlinePosition.lng, id, isGhost: true };

      return {
        outlines: [...acc.outlines, { ...outline, isGhost: false }, ghostOutline],
      };
    }, {
      outlines: [],
    });

    return pathWithGhosts;
  }, [calculateMidpoint]);

  return { addGhostsToOutlines };
};
