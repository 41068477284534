import { useState } from 'react';
import { useTranslation } from '../../_shared/utils/hooks';
import { parsePasswordErrorType } from './passwordErrorType.helpers';
import {
  sendPasswordResetEmailRequest, sendPasswordResetRequest,
} from './passwordReset.repository';

export const usePasswordReset = () => {
  const [t] = useTranslation();
  const [isPasswordResetEmailSending, setIsPasswordResetEmailSending] = useState(false);
  const [isPasswordResetLoading, setIsPasswordResetLoading] = useState(false);
  const [passwordResetEmailError, setPasswordResetEmailError] = useState<string>('');
  const [passwordResetError, setPasswordResetError] = useState<string>('');

  const sendPasswordResetEmail = (email: string, callback: () => void) => {
    setIsPasswordResetEmailSending(true);
    sendPasswordResetEmailRequest(email).then(() => {
      setIsPasswordResetEmailSending(false);
      callback();
    }).catch((error) => {
      setIsPasswordResetEmailSending(false);
      let errorMessage = error.message;
      if (error.errors?.email && error.errors?.email.length > 0) {
        errorMessage = error.errors.email[0];
      }
      setPasswordResetEmailError(errorMessage);
    });

  };

  const resetPassword = (email: string, expires: number, hash: string, signature: string, password: string, confirmPassword: string, callback: () => void) => {
    setIsPasswordResetLoading(true);
    sendPasswordResetRequest(email, expires, hash, signature, password, confirmPassword).then(() => {
      setIsPasswordResetLoading(false);
      callback();
    }).catch(e => {
      setIsPasswordResetLoading(false);
      setPasswordResetError(parsePasswordErrorType(e.message, t, t('password.reset.error.unableToReset')));
    });
  };

  return { isPasswordResetEmailSending,
    isPasswordResetLoading, passwordResetEmailError, passwordResetError, sendPasswordResetEmail, resetPassword };
};
