import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Saving]: {
  views: {
    default: {
      canvasHeight: 20,
      canvasWidth: 20,
      iconWidth: 20,
      iconHeight: 14,
    },
  },
  colors: {
    arrow: '#999999',
    cloud: '#999999',
  },
  gradients: {},
  frameRate: 29.9400024414062,
  segments: {
    default: [0, 31] as const,
  },
  getJson: () => import(
    /* webpackChunkName: "animations/44340-saving" */
    './44340-saving.json'
  ),
} };

export const { Saving } = config;
