import { createContext } from 'react';
import { noop } from '../../utils/function.helpers';

export type ContextMenuClosingContextType = Readonly<{
  closeContextMenu: () => void;
}>;

const initialValue: ContextMenuClosingContextType = {
  closeContextMenu: noop,
};

export const ContextMenuClosingContext = createContext(initialValue);
