export const POLYGON_GROUP_CREATE_REQUEST = 'POLYGON_GROUP_CREATE_REQUEST';
export const POLYGON_GROUP_CREATE_SUCCESS = 'POLYGON_GROUP_CREATE_SUCCESS';
export const POLYGON_GROUP_CREATE_ERROR = 'POLYGON_GROUP_CREATE_ERROR';

export const POLYGON_GROUP_FETCH_ALL_REQUEST = 'POLYGON_GROUP_FETCH_ALL_REQUEST';
export const POLYGON_GROUP_FETCH_ALL_SUCCESS = 'POLYGON_GROUP_FETCH_ALL_SUCCESS';
export const POLYGON_GROUP_FETCH_ALL_ERROR = 'POLYGON_GROUP_FETCH_ALL_ERROR';

export const POLYGON_GROUP_DELETE_REQUEST = 'POLYGON_GROUP_DELETE_REQUEST';
export const POLYGON_GROUP_DELETE_SUCCESS = 'POLYGON_GROUP_DELETE_SUCCESS';
export const POLYGON_GROUP_DELETE_ERROR = 'POLYGON_GROUP_DELETE_ERROR';

export const POLYGON_GROUP_FETCH_ACTIVE_REQUEST = 'POLYGON_GROUP_FETCH_ACTIVE_REQUEST';
export const POLYGON_GROUP_FETCH_ACTIVE_SUCCESS = 'POLYGON_GROUP_FETCH_ACTIVE_SUCCESS';
export const POLYGON_GROUP_FETCH_ACTIVE_ERROR = 'POLYGON_GROUP_FETCH_ACTIVE_ERROR';

export const POLYGON_GROUP_ACTIVATE_REQUEST = 'POLYGON_GROUP_ACTIVATE_REQUEST';
export const POLYGON_GROUP_ACTIVATE_SUCCESS = 'POLYGON_GROUP_ACTIVATE_SUCCESS';
export const POLYGON_GROUP_ACTIVATE_ERROR = 'POLYGON_GROUP_ACTIVATE_ERROR';

export const POLYGON_GROUP_DEACTIVATE_REQUEST = 'POLYGON_GROUP_DEACTIVATE_REQUEST';
export const POLYGON_GROUP_DEACTIVATE_SUCCESS = 'POLYGON_GROUP_DEACTIVATE_SUCCESS';
export const POLYGON_GROUP_DEACTIVATE_ERROR = 'POLYGON_GROUP_DEACTIVATE_ERROR';
