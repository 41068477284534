import {
  faCaretDown, faCaretUp,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useRef,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  arrowIconStyle, arrowStyle, bottomArrowStyle, spinnersStyle, topArrowStyle,
} from './inputSpinners.styles';

type InputSpinnersProps = Readonly<{
  className?: string;
  isDisabled?: boolean;
  value: number;
  min?: number;
  max?: number;

  valueSetter: (value: number) => void;
}>;

export const InputSpinnersComponent: FC<InputSpinnersProps> = ({
  valueSetter,
  ...props
}) => {
  const theme = useTheme();
  const countRef = useRef(props.value);
  countRef.current = props.value;

  const autoDelayHandler = useRef<number | undefined>(undefined);
  const autoIntervalHandler = useRef<number | undefined>(undefined);

  const resetTimer = useCallback(() => {
    clearTimeout(autoDelayHandler.current);
    clearInterval(autoIntervalHandler.current);
  }, []);

  const calcStep = useCallback((step: number) => {
    const max = props.max ?? Number.MAX_VALUE;
    const min = props.min ?? Number.MIN_VALUE;
    const newValue = clamp(countRef.current + step, { min, max });

    valueSetter(newValue);
  }, [valueSetter, props.min, props.max]);

  const handleValueChange = useCallback((step: number) => {
    if (props.isDisabled) {
      return;
    }

    calcStep(step);
    resetTimer();

    autoDelayHandler.current = window.setTimeout(function () {
      autoIntervalHandler.current = window.setInterval(function () {
        calcStep(step);
      }, 50);
    }, 500);
  }, [calcStep, props.isDisabled, resetTimer]);

  const onIncrementStart = useCallback(() => {
    handleValueChange(1);
  }, [handleValueChange]);

  const onDecrementStart = useCallback(() => {
    handleValueChange(-1);
  }, [handleValueChange]);

  useEffect(() => {
    const body = document.body;

    if (!body) {
      return;
    }

    const onSpinnerEnd = () => {
      resetTimer();
    };

    body.addEventListener('mouseup', onSpinnerEnd);
    body.addEventListener('mouseleave', onSpinnerEnd);
    body.addEventListener('touchend', onSpinnerEnd);

    return () => {
      resetTimer();
      body.removeEventListener('mouseup', onSpinnerEnd);
      body.removeEventListener('mouseleave', onSpinnerEnd);
      body.removeEventListener('touchend', onSpinnerEnd);
    };
  }, [resetTimer]);

  return (
    <div css={spinnersStyle} >
      <button
        type="button"
        css={[arrowStyle({ theme, isDisabled: !!props.isDisabled }), topArrowStyle]}
        onPointerDown={onIncrementStart}
      >
        <FontAwesomeIcon
          icon={faCaretUp}
          css={arrowIconStyle}
        />
      </button>

      <button
        type="button"
        css={[arrowStyle({ theme, isDisabled: !!props.isDisabled }), bottomArrowStyle]}
        onPointerDown={onDecrementStart}
      >
        <FontAwesomeIcon
          icon={faCaretDown}
          css={arrowIconStyle}
        />
      </button>
    </div>
  );
};
