import {
  type Settings, SettingsSelectedOption,
} from './baseMapsUpdate/settings.types';
import { type MapSettingsLayeredState } from './mapSettingsLayered.state';

export const getSettingsForLayeredMapBaseMapPair = (state: MapSettingsLayeredState) => (baseMapId: number): Settings | null => {
  const perBaseMapSettings = state.perBaseMapSettings;

  const baseMapSettings = perBaseMapSettings[baseMapId];
  if (!baseMapSettings) {
    return null;
  }

  if (baseMapSettings.selectedOption === SettingsSelectedOption.AskMeLater) {
    return {
      selectedOption: SettingsSelectedOption.AskMeLater,
      postponedVersion: baseMapSettings.postponedVersion,
      postponedSnapshot: baseMapSettings.postponedSnapshot,
      postponedAt: baseMapSettings.postponedAt,
    };
  }

  return baseMapSettings;
};
