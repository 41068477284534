import {
  FRONTEND_STATE_SEARCH_HIDE, FRONTEND_STATE_SEARCH_SET_SEARCH_TEXT,
  FRONTEND_STATE_SEARCH_SHOW,
} from './search.actionTypes';

export const frontendStateSearchShow = () => ({
  type: FRONTEND_STATE_SEARCH_SHOW,
}) as const;

export const frontendStateSearchHide = () => ({
  type: FRONTEND_STATE_SEARCH_HIDE,
}) as const;

export const frontendStateSearchSetSearchText = (searchText: string) => ({
  type: FRONTEND_STATE_SEARCH_SET_SEARCH_TEXT,
  payload: {
    searchText,
  },
}) as const;
