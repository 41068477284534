import { type MapInitializationFailedAction } from './mapInitializationFailed.action';
import { FRONTEND_STATE_MAP_INITIALIZATION_FAILED_SET } from './mapInitializationFailed.actionTypes';
import { type MapInitializationFailedState } from './mapInitializationFailed.state';

const initialState: MapInitializationFailedState = false;

export const mapInitializationReducer = (state = initialState, action: MapInitializationFailedAction): boolean => {
  switch (action.type) {
    case FRONTEND_STATE_MAP_INITIALIZATION_FAILED_SET: {
      return action.payload.hasInitializationFailed;
    }

    default:
      return state;
  }
};
