import { type AxisOffsets } from '~/_shared/types/coordinateSystem/coordinateSystem';
import {
  MarkerAnchorPosition, type StandardMarkerStyle,
} from '~/_shared/types/marker.types';
import {
  type ActiveMarkerIndicator, ActiveMarkerIndicators,
} from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.types';
import { type ActiveMarkerIndicatorSettings } from '~/store/mapSettings/markers/mapSettingsMarkers.state';

const INDICATORS_ANCHORED_AT_MARKERS_ANCHOR = new Set<ActiveMarkerIndicator>([ActiveMarkerIndicators.radarCircle]);

export const getIndicatorOffsets = ({ marker, markerSet, indicator, aboveMarkerOffset }: {
  indicator: ActiveMarkerIndicatorSettings;
  marker: {
    anchor: MarkerAnchorPosition | null;
    size: number;
  };
  markerSet?: StandardMarkerStyle;
  aboveMarkerOffset?: number;
}): AxisOffsets | null => {
  const markerInfo = markerSet?.marker;
  let xOffset: number = 0;
  let yOffset: number = 0;

  if (INDICATORS_ANCHORED_AT_MARKERS_ANCHOR.has(indicator.type)) { // round indicator
    if (markerInfo) { // standard marker
      const { bottomOffset, leftOffset, rightOffset, topOffset } = markerInfo.offsets; // offsets from the side of the texture to the side of the marker
      const markerDimensions = markerInfo.spritesheetSettings.dimensions;
      const originalNetMarkerHeight = markerDimensions.height - topOffset - bottomOffset;
      const ratio = marker.size / originalNetMarkerHeight;

      const anchorOffsets = markerInfo.spritesheetSettings.anchorOffsets; // offsets from the side of the texture to the anchor of the marker
      const ratioedAnchorYOffset = anchorOffsets.yOffset * ratio;
      const ratioedAnchorXOffset = anchorOffsets.xOffset * ratio;
      const ratioedWidth = (markerDimensions.width - leftOffset - rightOffset) * ratio;
      const ratioedHeight = originalNetMarkerHeight * ratio;

      xOffset = Math.floor(ratioedWidth / 2 + leftOffset * ratio - ratioedAnchorXOffset);
      yOffset = Math.floor(ratioedHeight / 2 + topOffset * ratio - ratioedAnchorYOffset);
      yOffset -= (aboveMarkerOffset || 0) / 2;
    }
    else {// custom marker
      switch (marker.anchor) {
        case MarkerAnchorPosition.CENTER: {
          break;
        }

        case MarkerAnchorPosition.BOTTOM_CENTER:
        default: {
          yOffset = -marker.size / 2;
          break;
        }
      }
    }
  }
  else { // standard indicator
    return null;
  }

  return { xOffset, yOffset };
};
