import {
  type FC,
  memo,
  useEffect,
  useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { useMapObjectContext } from '../private/mapObjectContext';

export type MapShapePolylineVisualsConfig = {
  readonly color: WebglColor;
  readonly style: 'solid' | 'dash';
  readonly width: number;
};

type MapShapePathProps = {
  id: string;
  points: ReadonlyArray<LatLng>;
  visuals: MapShapePolylineVisualsConfig;

  onClick?: MapObjectClickEvent;
  onRightClick?: MapObjectClickEvent;
  onDragEnd?: () => void;
  onDragMove?: (latLng: LatLng) => void;
  onDragStart?: (latLng: LatLng) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
};

const MapShapePath: FC<MapShapePathProps> = ({
  id, points, visuals, onClick, onRightClick,
  onMouseOut, onMouseOver, onDragMove,
  onDragStart, onDragEnd,
}) => {
  const [mapLine, setMapLine] = useState<WebglOverlayPolyline | null>(null);
  const { manager, zIndex } = useMapObjectContext();

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: mapLine,
    onDragMove,
    onDragStart,
    onDragEnd,
    onMouseOut,
    onMouseOver,
  }), [manager.map, mapLine, onDragEnd, onDragMove, onDragStart, onMouseOut, onMouseOver]);

  // Draw line
  useEffect(() => {
    const line = manager.upsertLine(id, points, { ...visuals, zIndex: zIndex.line });

    if (!line) {
      return;
    }

    setMapLine(line);
  }, [manager, id, points, visuals, zIndex.line]);

  // Registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addPolylineEventListener(id, 'click', onClick);

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, id]);

  // Registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseRightClickCleanup = manager.addPolylineEventListener(id, 'rightclick', onRightClick);

    return () => {
      mouseRightClickCleanup();
    };
  }, [manager, onRightClick, id]);

  // Unmount
  useEffect(() => {
    return () => {
      manager.removeLine(id);
    };
  }, [id, manager]);

  return null;
};

const pureComponent = memo(MapShapePath);
export { pureComponent as MapShapePath };
