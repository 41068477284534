import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  boundaryItemsUpdateItem, boundaryItemsUpdateItems,
} from '~/store/boundaryItems/boundaryItems.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { type Geometry } from '../../../_shared/types/polygon/polygon.types';
import {
  type BoundaryCombineGeometryRequest,
  type BoundaryCombineGeometryRequestItem,
  getBoundaryCombineGeometry,
  updateBoundaryGeometry,
} from '../../../store/boundaries/boundaries.repository';
import { createBoundaryStateItemFromBoundaryResponse } from '../../../store/boundaryItems/boundaryItems.factory';
import { type BoundaryStateItem } from '../../../store/boundaryItems/boundaryItems.state';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { type BoundaryEditSubmitResults } from './boundaryEditSubmitModal.component';
import { BoundaryEditSubmitModalAction } from './boundaryEditSubmitModal.helpers';

export const useBoundaryEditActions = () => {
  const [isLoading, setIsLoading] = useState(false);

  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector() ?? undefined;
  const dispatch = useDispatch();

  const boundaryCombineGeometry = useCallback(async (request: BoundaryCombineGeometryRequest) => {
    if (!clientId) {
      throw Error('Missing clientId');
    }

    return await getBoundaryCombineGeometry(clientId, request);
  }, [clientId]);

  const bulkSubtractGeometry = async (boundaries: BoundaryStateItem[], boundaryGroupId: number, geometry: BoundaryCombineGeometryRequestItem[]) => {
    if (!clientId) {
      throw Error('Missing clientId');
    }

    const newBoundaryStateItems: BoundaryStateItem[] = [];
    try {
      for (const boundary of boundaries) {
        const newSourceGeometry = await boundaryCombineGeometry({
          include: [{
            type: 'boundary',
            boundary_id: boundary.id,
            boundary_group_id: boundaryGroupId,
          }],
          subtract: geometry,
        });

        const response = await updateBoundaryGeometry(clientId, boundary.id, newSourceGeometry?.geometry, mapId);
        newBoundaryStateItems.push(createBoundaryStateItemFromBoundaryResponse(response));

      }
    }
    catch (e) {
      // skip updates with error
    }
    return newBoundaryStateItems;
  };

  const updateBoundary = async (boundaryId: number, newGeometry: Geometry) => {

    if (!clientId) {
      throw Error('Missing clientId');
    }

    const response = await updateBoundaryGeometry(clientId, boundaryId, newGeometry, mapId);
    return createBoundaryStateItemFromBoundaryResponse(response);
  };

  const editBoundary = async (
    results: BoundaryEditSubmitResults,
    editedBoundaryBoundaryGroupId: number,
    editedBoundaryItems: BoundaryStateItem[],
    geometry: BoundaryCombineGeometryRequestItem[],
    _onSuccess: () => void, _onError: () => void
  ) => {
    setIsLoading(true);

    const onSuccess = (boundaryStateItem: BoundaryStateItem) => {
      dispatch(boundaryItemsUpdateItem(editedBoundaryBoundaryGroupId, boundaryStateItem));
      setIsLoading(false);
      _onSuccess();
    };

    const onBulkSuccess = (boundaryStateItems: BoundaryStateItem[]) => {
      dispatch(boundaryItemsUpdateItems(editedBoundaryBoundaryGroupId, boundaryStateItems));
      setIsLoading(false);
      _onSuccess();
    };

    const onError = () => {
      setIsLoading(false);
      _onError();
    };

    if (results.action === BoundaryEditSubmitModalAction.AddToExistingTerritory) {
      const newGeometry = await boundaryCombineGeometry({
        include: [...geometry, {
          type: 'boundary',
          boundary_id: results.selectedBoundaryId,
          boundary_group_id: editedBoundaryBoundaryGroupId,
        }],
        subtract: [],
      });

      updateBoundary(results.selectedBoundaryId, newGeometry?.geometry)
        .then(onSuccess)
        .catch(onError);
    }

    if (results.action === BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory) {
      const newGeometry = await boundaryCombineGeometry({
        include: [{
          type: 'boundary',
          boundary_id: results.selectedBoundaryId,
          boundary_group_id: editedBoundaryBoundaryGroupId,
        }],
        subtract: geometry,
      });

      updateBoundary(results.selectedBoundaryId, newGeometry?.geometry)
        .then(onSuccess)
        .catch(onError);
    }

    if (results.action === BoundaryEditSubmitModalAction.ReassignTerritory) {

      const subtractedBoundaryStateItems = await bulkSubtractGeometry(editedBoundaryItems.filter(boundary => boundary.id !== results.targetBoundaryId), editedBoundaryBoundaryGroupId, geometry);

      const newTargetGeometry = await boundaryCombineGeometry({
        include: [...geometry, {
          type: 'boundary',
          boundary_id: results.targetBoundaryId,
          boundary_group_id: editedBoundaryBoundaryGroupId,
        }],
        subtract: [],
      });

      updateBoundary(results.targetBoundaryId, newTargetGeometry?.geometry)
        .then((updated) => onBulkSuccess([updated, ...subtractedBoundaryStateItems]))
        .catch(onError);
    }
  };

  return {
    isLoading,
    editBoundary,
  };
};
