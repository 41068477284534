import { css } from '@emotion/react';
import { type FC } from 'react';
import { FadingDots } from '~/_shared/baseComponents/loaders';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { BoundaryCreateOptimizedStatus } from '../../../../store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import { type Theme } from '../../../themes/theme.model';
import { getOptimizedTerritoryStatusTranslation } from './optimizedTerritoriesProgressBar.helpers';

const stepText = (t: TranslationFnc, number: number) => `${t('Step')}: (${number}/7)`;

const statusStyle = (theme: Theme) => css({
  color: theme.textColors.success,
});

export const Step: FC<OptimizedTerritoriesTextComponentProps> = ({ status }) => {
  const [t] = useTranslation();
  const stepNumber = Object.values(BoundaryCreateOptimizedStatus).indexOf(status) + 1;
  return <>{stepText(t, stepNumber)}</>;
};

const textStyle = css({
  fontSize: 14,
  textAlign: 'center',
});

type OptimizedTerritoriesTextComponentProps = {
  status: BoundaryCreateOptimizedStatus;
};

export const OptimizedTerritoriesTextComponent: FC<OptimizedTerritoriesTextComponentProps> = ({ status }) => {
  const theme = useTheme();
  const [t] = useTranslation();
  return (
    <span css={textStyle}>
      <Step status={status} />
      {' '}
      <span css={statusStyle(theme)}>
        {getOptimizedTerritoryStatusTranslation(status, t)}
        <FadingDots />
      </span>
    </span>
  );
};
