import { faLasso } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../_shared/utils/hooks';
import {
  startLassoTool,
  stopLassoTool,
} from '../../../../store/frontendState/mapTools/lasso/lasso.actionCreators';
import { useLassoIsActiveSelector } from '../../../../store/frontendState/mapTools/lasso/lasso.selectors';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideLassoToolMenuItem: FC = () => {
  const [t] = useTranslation();

  const isLassoActive = useLassoIsActiveSelector();

  const dispatch = useDispatch();
  const toggleLasso = () =>
    isLassoActive
      ? dispatch(stopLassoTool())
      : dispatch(startLassoTool());

  return (
    <RightSideMapToolsMenuItem
      icon={faLasso}
      active={isLassoActive}
      onClick={toggleLasso}
      tooltip={t('Lasso Tool')}
    />
  );
};
