import { combineReducers } from 'redux';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import { drawingItemsContextMenuReducer } from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.reducer';
import { mapListingReducer } from '~/store/frontendState/mapListing/mapListing.reducer';
import { mobileUIReducer } from '~/store/frontendState/mobileUI/mobileUI.reducer';
import { MAP_RESET } from '../map/map.actionTypes';
import { activeMapElementsReducer } from './activeMapElements/activeMapElements.reducer';
import { clientReducer } from './client/client.reducer';
import { deviceInfoReducer } from './deviceInfo/deviceInfo.reducer';
import { fileUrlsReducer } from './fileUrls/fileUrls.reducer';
import { graphicSettingsReducer } from './graphicSettings/graphicSettings.reducer';
import {
  leftSidebarEmptyState, leftSidebarReducer,
} from './leftSidebar/leftSidebar.reducer';
import { mapComponentReducer } from './mapComponent/mapComponent.reducer';
import { mapInitializationReducer } from './mapInitializationFailed/mapInitializationFailed.reducer';
import { mapPresentationalReducer } from './mapPresentational/mapPresentational.reducer';
import { mapToolsReducer } from './mapTools/mapTools.reducer';
import { mapZIndexesReducer } from './mapZIndexes/mapZIndexes.reducer';
import { markersContextMenuReducer } from './markersContextMenu/markersContextMenu.reducer';
import { moveMarkerLabelsReducer } from './moveMarkerLabels/moveMarkerLabels.reducer';
import { moveMarkersReducer } from './moveMarkers/moveMarkers.reducer';
import { placesReducer } from './places/places.reducer';
import { processingReducer } from './processing/processing.reducer';
import { proximityDriveTimePathsReducer } from './proximityDriveTimePaths/proximityDriveTimePaths.reducer';
import { proximityToolReducer } from './proximityTool/proximityTool.reducer';
import { spreadsheetTableReducer } from './spreadsheetTable/spreadsheetTable.reducer';

const regularReducer = combineReducers({
  activeMapElements: activeMapElementsReducer,
  client: clientReducer,
  deviceInfo: deviceInfoReducer,
  drawingItemsContextMenu: drawingItemsContextMenuReducer,
  fileUrls: fileUrlsReducer,
  leftSidebar: leftSidebarReducer,
  mapComponent: mapComponentReducer,
  mapInitializationFailed: mapInitializationReducer,
  mapPresentational: mapPresentationalReducer,
  mapTools: mapToolsReducer,
  mapZIndexes: mapZIndexesReducer,
  mapListing: mapListingReducer,
  markersContextMenu: markersContextMenuReducer,
  mobileUI: mobileUIReducer,
  moveMarkers: moveMarkersReducer,
  moveMarkerLabels: moveMarkerLabelsReducer,
  places: placesReducer,
  processing: processingReducer,
  proximityDriveTimePaths: proximityDriveTimePathsReducer,
  proximityTool: proximityToolReducer,
  spreadsheetTable: spreadsheetTableReducer,
  graphicSettings: graphicSettingsReducer,
});

type FrontendStateState = ReturnType<typeof regularReducer>;

export const frontendStateReducer = (state: FrontendStateState | undefined, action: Action): FrontendStateState => {
  if (action.type === MAP_RESET) {

    let newState = {} as Partial<FrontendStateState>;

    if (state?.deviceInfo) {
      newState = {
        ...newState,
        deviceInfo: state.deviceInfo,
      };
    }

    if (state?.leftSidebar?.openedApp && state?.leftSidebar?.openedApp !== SidebarApp.MapTools) {
      const initialLeftSidebarTest = {
        ...leftSidebarEmptyState,
        openedApp: state.leftSidebar.openedApp,
      };

      newState = {
        ...newState,
        leftSidebar: initialLeftSidebarTest,
      };
    }

    (state as Partial<FrontendStateState>) = newState;
  }

  return regularReducer(state, action);
};
