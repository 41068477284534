import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { BoundingBox } from '../../../map/map/boundingBox';
import { type LatLngBounds } from '../../types/latLng';
import { validateLatLngBounds } from '../gis/boundingBox.helpers';

export const createLatLngBoundsFromBoundingBox = (boundingBox: BoundingBox): LatLngBounds => {
  return {
    ne: boundingBox.getNorthEast(),
    sw: boundingBox.getSouthWest(),
  };
};

export const createLatLngBoundsFromPolygonPaths = (path: PolygonPath): LatLngBounds | null => {
  if (path.length === 0) {
    return null;
  }

  const bounds = new BoundingBox();
  path.forEach(point => bounds.extend(point));

  const resultsBounds: LatLngBounds = {
    ne: bounds.getNorthEast(),
    sw: bounds.getSouthWest(),
  };

  if (validateLatLngBounds(resultsBounds)) {
    return resultsBounds;
  }
  else {
    return null;
  }
};

export const getPolygonPathFromLatLngBounds = (boundingBox: BoundingBox): ReadonlyArray<PolygonPath> => {
  const bounds = createLatLngBoundsFromBoundingBox(boundingBox);

  if (bounds.sw.lng <= bounds.ne.lng) {
    return [[
      bounds.sw,
      { lat: bounds.sw.lat, lng: bounds.ne.lng },
      bounds.ne,
      { lat: bounds.ne.lat, lng: bounds.sw.lng },
    ]];
  }
  else {
    // bounds cross the antimeridian (lng -180/180)
    // we need to split bounds into two polygons
    return [
      [
        bounds.sw,
        { lat: bounds.sw.lat, lng: 180 },
        { lat: bounds.ne.lat, lng: 180 },
        { lat: bounds.ne.lat, lng: bounds.sw.lng },
      ],
      [
        { lat: bounds.sw.lat, lng: -180 },
        { lat: bounds.sw.lat, lng: bounds.ne.lng },
        bounds.ne,
        { lat: bounds.ne.lat, lng: -180 },
      ],
    ];
  }
};
