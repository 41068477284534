import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.CircleDownload]: {
  views: {
    default: {
      canvasHeight: 256,
      canvasWidth: 256,
      iconWidth: 175,
      iconHeight: 175,
      offsetTop: 42,
      offsetLeft: 37,
    },
  },
  colors: {
    tick: '#FFFFFF',
    fillFrom: '#FEFEFE',
    fillTo: '#59B765',
    downloadIcon: '#939393',
    borderFrom: '#E3E3E3',
    borderTo: '#baf1bb',
  },
  gradients: {},
  frameRate: 29.9700012207031,
  segments: {
    default: [0, 90] as const,
    progress: [10, 56] as const,
    success: [56, 90] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/18897-circle-download-animation" */
      './18897-circle-download-animation.json'
    ),
} };

export const { CircleDownload } = config;
