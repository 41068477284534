import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import { SettingsModalComponent } from '../../_shared/components/settingsModal/settingsModal.component';
import {
  type ChangeableSettingRow,
  type SettingColumn,
  type SettingRow,
} from '../../_shared/components/settingsTable/settingsTable.types';
import { useTranslation } from '../../_shared/utils/hooks';

type FilterSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
  settingRows: SettingRow[];
  onPublicSet: (rowIndex: number, newValue: boolean) => void;
  onOnToggle: (rowIndex: number) => void;
  onDataTypeChange: (rowIndex: number, newDataType: DataType) => void;
  isLoading: boolean;
};

const footerNoteStyles = css({
  fontSize: '14px',
  paddingRight: 10,
  display: 'block',
});

const isDataType = (value: unknown): value is DataType => {
  return !!value && Object.values(DataType).includes(value as any);
};

export const FilterSettingsComponent: FC<FilterSettingsProps> = (props) => {
  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors();

  const onSettingsRowChange = (rowIndex: number, newRow: ChangeableSettingRow) => {
    const currentRow = props.settingRows[rowIndex];

    if (currentRow) {
      // filter type
      const newFilterType = newRow.data[1]?.value;
      if ((currentRow.data[1] !== newRow.data[1]) && isDataType(newFilterType)) {
        props.onDataTypeChange(rowIndex, newFilterType);
      }

      // public
      if ((typeof newRow.data[2]?.value === 'boolean') && (currentRow.data[2] !== newRow.data[2])) {
        props.onPublicSet(rowIndex, newRow.data[2].value);
      }

      // on/off
      if (currentRow.data[3] !== newRow.data[3]) {
        props.onOnToggle(rowIndex);
      }
    }
  };

  const filterTableColumns: SettingColumn[] = useMemo(() => [{
    caption: t('Data Column Name'),
    width: '35%',
  }, {
    caption: t('Filter Type'),
    align: 'right',
    width: '25%',
  }, {
    caption: t('Show on Public Map'),
    align: 'center',
    width: '20%',
  }, {
    caption: t('* Filter On/Off'),
    align: 'center',
    width: '20%',
  }], [t]);

  return (
    <SettingsModalComponent
      caption={t('Create Your Filters')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      leftFooterContent={(
        <span css={footerNoteStyles}>{t('filter.settings.note.on.off')}</span>
      )}
      settingColumns={filterTableColumns}
      settingRows={props.settingRows}
      onChange={onSettingsRowChange}
      onSubmit={props.onClose}
      isLoading={props.isLoading}
      customSaveButton={null}
      customLoader={(
        <OverlayLottieAnimationComponent
          type={LottieAnimationTypes.Filter}
          colors={animationColors[LottieAnimationTypes.Filter]}
          size={60}
          autoplay
          loop
        />
      )}
    />
  );
};
