import {
  type FC, memo, type ReactNode,
} from 'react';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '../../../../_shared/types/latLng';
import { type MapObjectZIndex } from '../mapObject.types';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { type MapCircleInstance } from './mapCircleModel';
import { useMapCircleOutlines } from './useMapCircleOutlines';

type MapCircleProps = {
  zIndex: MapObjectZIndex;
  circle: MapCircleInstance;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  renderCircle?: (key: string, circle: MapCircleInstance) => ReactNode;
  renderCenter?: (key: string, center: LatLng) => ReactNode;
};

const MapCircleContainer: FC<MapCircleProps> = (props) => {
  const { circle, zIndex } = props;
  const { renderCircle, renderOutline, renderCenter } = props;

  const manager = useMapObjectManager();

  const outlines = useMapCircleOutlines(circle, {
    predicate: !!renderOutline,
    hideDuringZoomChangeAnimation: circle.scalesWithMapZoom,
  });

  return (
    <MapObjectContextProvider value={{ manager, zIndex }}>
      {renderCircle?.(circle.id, circle)}
      {renderCenter?.(`${circle.id}-center`, {
        lat: circle.lat,
        lng: circle.lng,
      })}
      {renderOutline && outlines.map(
        outline => renderOutline(outline.id, outline))
      }
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(MapCircleContainer);
export { pureComponent as MapCircleContainer };
