import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { type GeocodingRequest } from '~/store/geocoding/geocoding.state';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import { GeocodingProgressBarComponent } from '../../geocodingProgressBar/geocodingProgressBar.component';

const containerStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '0 16px',
});

const titleStyle = ({ theme }: ThemeProps) => css({
  width: '50%',
  color: theme.textColors.primary,
});

type GeocodingProgressAlertProps = {
  isMobileVersion?: boolean;
  isPaused: boolean;
  percentage: number;
  title: string;

  onPauseToggle: (newValue: boolean) => void;
  onProcessFrozen: () => void;
};

export const TopUnderbarGeocodingComponent: FC<GeocodingProgressAlertProps> = (props) => {
  const theme = useTheme();

  return (
    <div css={containerStyle}>
      {!props.isMobileVersion && <span css={titleStyle({ theme })}>{props.title}</span>}

      <GeocodingProgressBarComponent
        percentage={props.percentage}
        isPaused={props.isPaused}
        onPauseToggle={props.onPauseToggle}
        onProcessFrozen={props.onProcessFrozen}
      />
    </div>
  );
};

export const renderGeocodingState = (
  { isMobileVersion, state, togglePause, t }: {
    state: GeocodingRequest;
    isMobileVersion?: boolean;
    togglePause: (flag: boolean) => void;
    t: TranslationFnc;
  }) => {

  return ({
    children: (
      <TopUnderbarGeocodingComponent
        title={t('It may take 1-2 minutes before you start to see your data on the map.')}
        percentage={state.progress}
        isMobileVersion={isMobileVersion}
        isPaused={state.paused.isPaused}
        onPauseToggle={togglePause}
        onProcessFrozen={() => refreshPage()}
      />
    ),
    icon: faExclamationTriangle,
    isWarningIcon: true,
    key: 'geocoding',
  });
};
