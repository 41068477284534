import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { SquareIconButtonComponent } from '~/_shared/baseComponents/buttons';
import {
  TooltipBehavior, type TooltipOffset, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';

const itemStyle = css({
  position: 'relative',
  marginBottom: 12,
  breakInside: 'avoid',
  '&:last-of-type': {
    margin: 0,
  },
});

const tooltipOffset: TooltipOffset = {
  right: 20,
};

const tooltipContentStyle = css({
  fontSize: '14px',
});

type RoundButtonToolsMenuItemProps = Readonly<{
  active: boolean;
  className?: string;
  icon: IconProp;
  isLoading?: boolean;
  tooltip: string;
  tooltipAlwaysShow?: boolean;

  onClick: () => void;
}>;

export const RoundButtonToolsMenuItem: FC<RoundButtonToolsMenuItemProps> = ({
  tooltipAlwaysShow,
  onClick,
  active,
  className,
  icon,
  isLoading,
  tooltip,
  ...restProps
}) => (
  <div css={itemStyle}>
    <SquareIconButtonComponent
      {...restProps}
      onClick={onClick}
      className={className}
      icon={icon}
      isLoading={isLoading}
      tooltip={tooltip}
      isActive={active}
      tooltipBehavior={tooltipAlwaysShow ? TooltipBehavior.ShowAlways : undefined}
      tooltipOffset={tooltipOffset}
      tooltipContentStyle={tooltipContentStyle}
      tooltipPlacement={TooltipPlacement.Top}
      useDefaultHoverStyles
      buttonSize={40}
      borderRadius="50%"
    />
  </div>
);
