import distanceCalculatorCursor from '../../../../../../../assets/images/cursors/distance-calculator.png';
import { type DistanceCalculatorAction } from '../../../mapTools/distanceCalculator/distanceCalculator.action';
import {
  FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT,
  FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER,
  FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT,
  FRONTEND_STATE_DISTANCE_CALCULATOR_STOP,
} from '../../../mapTools/distanceCalculator/distanceCalculator.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyDistanceCalculatorMapOptions = (
  state: MapComponentOptionsState,
  action: DistanceCalculatorAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED:
      return push(state, {
        changeId: 'DISTANCE_CALCULATOR_DRAWING',
        priority,
        change: {
          draggableCursor: `url("${distanceCalculatorCursor}") 15 15, default`,
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_DISTANCE_CALCULATOR_STOP:
      return pop(state, 'DISTANCE_CALCULATOR_DRAWING');

    case FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER:
      return push(state, {
        changeId: 'DISTANCE_CALCULATOR_REMOVE_BUTTON_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT:
      return pop(state, 'DISTANCE_CALCULATOR_REMOVE_BUTTON_HOVER');

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER:
      return push(state, {
        changeId: 'DISTANCE_CALCULATOR_OUTLINE_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT:
      return pop(state, 'DISTANCE_CALCULATOR_OUTLINE_HOVER');

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START:
      return push(state, {
        changeId: 'DISTANCE_CALCULATOR_OUTLINE_DRAG',
        priority,
        change: {
          draggableCursor: 'move',
        },
      });

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP:
      return pop(state, 'DISTANCE_CALCULATOR_OUTLINE_DRAG');

    default:
      return undefined;
  }
};
