import { ButtonComponent } from '~/_shared/baseComponents/buttons';
import { useTranslation } from '~/_shared/utils/hooks';
import { useLogOutUser } from '~/authorization/logOutUser.hook';
import { ErrorPageContentGuardianDogComponent } from '../../errorPageContent/guardianDog';

export const AppErrorPageLogOutRequiredComponent = () => {
  const [t] = useTranslation();
  const logout = useLogOutUser({ redirectToLogin: false });

  return (
    <ErrorPageContentGuardianDogComponent>
      {t('You are currently logged in as active user. To access this page, you need to sign out first.')}
      <div css={{ marginTop: 10 }}>
        <ButtonComponent
          onClick={logout}
          text={t('Log Out')}
        />
      </div>
    </ErrorPageContentGuardianDogComponent>
  );
};
