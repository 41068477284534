import { faLocation } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { useFindMyLocationWithErrorCooldown } from '~/_shared/utils/hooks/useFindMyLocationWithErrorCooldown';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { RoundButtonToolsMenuItem } from './roundButtonToolsMenuItem.component';

export const MapToolsMyLocationMenuItem: FC = _ => {
  const [t] = useTranslation();
  const isMobileScreen = useIsMobileScreenSelector();
  const { openModal: openErrorModal } = useModal(ModalType.AppError);

  const {
    isLoading, locationTrackingEnabled, handleFindLocationClick, isErrorTooltipShown,
  } = useFindMyLocationWithErrorCooldown();

  const prevIsErrorTooltipShown = usePrevious(isErrorTooltipShown);

  useEffect(() => {
    if (isMobileScreen && prevIsErrorTooltipShown === false && isErrorTooltipShown) {
      openErrorModal({
        type: AppErrorType.General,
        title: t('Unable to find location'),
        text: t('We were not able to find your location. Please check your browser settings.'),
      });
    }
  }, [isErrorTooltipShown, isMobileScreen, prevIsErrorTooltipShown, openErrorModal, t]);

  return (
    <RoundButtonToolsMenuItem
      active={locationTrackingEnabled}
      icon={faLocation}
      isLoading={isLoading || isErrorTooltipShown}
      onClick={handleFindLocationClick}
      tooltip={!isErrorTooltipShown ? t('My Location') : t('We were not able to find your location. Please check your browser settings.')}
      tooltipAlwaysShow={isErrorTooltipShown}
    />
  );
};
