import { css } from '@emotion/react';
import { SupportLinkType } from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import type { Theme } from '~/_shared/themes/theme.model';

export const supportLinkStyle = (type?: SupportLinkType) => (theme: Theme) => {
  let color: string;
  let hoverColor: string;

  switch (type) {
    case SupportLinkType.danger: {
      color = theme.textColors.danger;
      hoverColor = theme.textColors.dangerOnHover;
      break;
    }
    case SupportLinkType.info: {
      color = theme.textColors.link;
      hoverColor = theme.textColors.linkOnHover;
      break;
    }
    case SupportLinkType.success: {
      color = theme.textColors.success;
      hoverColor = theme.textColors.successOnHover;
      break;
    }
    default: {
      color = 'inherit';
      hoverColor = 'inherit';
    }
  }

  return css({
    fontWeight: 'bold',

    color,
    '&:hover': {
      color: hoverColor,
    },
  });
};
