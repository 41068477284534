export const pick = <T extends Record<string, unknown>, K extends keyof T>(
  originalObject: T,
  keysToPick: ReadonlyArray<K>,
): Pick<T, K> => {
  const newObject = {} as any;

  for (const path of keysToPick) {
    newObject[path] = originalObject[path];
  }

  return newObject;
};
