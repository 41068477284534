import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import {
  type ModalProps, ModalType,
} from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';
import { isBoundaryTerritoryGroupCustom } from '../../../sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import { useBoundaryGroupsSelector } from '../../../store/boundaryGroups/boundaryGroups.selectors';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { boundarySelectEditDeactivate } from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import {
  BoundaryTerritoryEditSubmitModalComponent,
  type BoundaryTerritoryEditSubmitResults,
} from './boundaryTerritoryEditSubmitModal.component';
import { BoundaryTerritoryEditSubmitAction } from './boundaryTerritoryEditSubmitModal.helpers';
import { useBoundaryAssignmentsUpdate } from './useBoundaryAssignmentsUpdate';

export type BoundaryTerritoryEditSubmitModalContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  boundaryIds: number[];
}>;

export const BoundaryTerritoryEditSubmitModalContainer: FC<BoundaryTerritoryEditSubmitModalContainerProps> = (props) => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const { openModal: openBoundaryTerritoryCreateModal } = useModal(ModalType.BoundaryTerritoryCreate);
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const isBoundaryTerritoryGroupsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const boundaryTerritoryGroup = boundaryTerritoryGroups
    .find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);
  const dispatch = useDispatch();

  const { removeBoundaryTerritoryAssignments, addBoundaryTerritoryAssignments } = useBoundaryAssignmentsUpdate();

  if (
    !boundaryTerritoryGroup ||
    isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups) ||
    props.boundaryIds.length === 0
  ) {
    return null;
  }

  const enableManualAssignments = boundaryTerritoryGroup.settings.boundaryTerritoryType === BoundaryTerritoryType.Groups;
  const editableBoundaryTerritories = boundaryTerritoryGroup.settings.boundaryTerritories
    .filter(territory => territory.custom || enableManualAssignments);
  const btgBucketColumnId = boundaryTerritoryGroup.settings.bucketColumnId ?? undefined;

  const onSubmit = (results: BoundaryTerritoryEditSubmitResults) => {
    const onSuccess = () => {
      dispatch(boundarySelectEditDeactivate());
      props.onClose();
    };

    if (results.action === BoundaryTerritoryEditSubmitAction.RemoveFromExisting) {
      const boundaryTerritoryAndColumnIds = editableBoundaryTerritories.map(territory => {
        return {
          boundaryTerritoryId: territory.boundaryTerritoryId,
          columnId: territory.custom ? undefined : btgBucketColumnId,
        };
      });

      removeBoundaryTerritoryAssignments({
        boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
        removeBoundaryIds: props.boundaryIds,
        boundaryTerritoryAndColumnIds,
        onSuccess,
      });
    }
    if (results.action === BoundaryTerritoryEditSubmitAction.AddToExisting) {
      const isSelectedBTCustom = editableBoundaryTerritories.find(boundaryTerritory =>
        boundaryTerritory.boundaryTerritoryId === results.selectedBoundaryTerritoryId)?.custom ?? true;

      addBoundaryTerritoryAssignments({
        boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
        setBoundaryIds: props.boundaryIds,
        columnId: (enableManualAssignments && !isSelectedBTCustom) ? btgBucketColumnId : undefined,
        boundaryTerritoryId: results.selectedBoundaryTerritoryId,
        onSuccess,
      });
    }
    if (results.action === BoundaryTerritoryEditSubmitAction.CreateNew) {
      openBoundaryTerritoryCreateModal({
        boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
        onCreateBoundaryTerritorySuccess: (newBoundaryTerritoryId: string) => {
          addBoundaryTerritoryAssignments({
            boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
            setBoundaryIds: props.boundaryIds,
            boundaryTerritoryId: newBoundaryTerritoryId,
          });
          onSuccess();
        },
      });
    }
  };

  return (
    <BoundaryTerritoryEditSubmitModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={isBoundaryTerritoryGroupsLoading}
      onSubmit={onSubmit}
      boundaryTerritories={editableBoundaryTerritories}
      boundaryTerritoryGroupSettings={boundaryTerritoryGroup.settings}
    />
  );
};
