import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemIconMarker } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolMarkerInstanceMarkerContainer } from './drawingToolIconMarkerInstanceMarker.container';

type DrawingToolIconMarkerInstanceProps = {
  instance: DrawingItemIconMarker;
};

const DrawingToolIconMarkerInstanceContainer: FC<DrawingToolIconMarkerInstanceProps> = ({ instance }) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut, onMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const onClick = useCallback((_id: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.IconMarker,
        value: {
          id: instance.id,
          center: instance.center,
          placement: instance.placement,
          settings: instance.settings,
        },
      })
    );
  }, [dispatch, instance.settings, instance.center, instance.id, instance.placement, drawingEventsEnabledRef]);

  const onMarkerRightClick = useCallback((_markerId: Uuid, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.IconMarker,
      value: instance,
    });
  }, [instance, onRightClick, drawingEventsEnabledRef]);

  return (
    <DrawingToolMarkerInstanceMarkerContainer
      instance={instance}
      zIndex={zIndex}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      onRightClick={onMarkerRightClick}
    />
  );
};

const pureComponent = memo(DrawingToolIconMarkerInstanceContainer);
export { pureComponent as DrawingToolIconMarkerInstanceContainer };
