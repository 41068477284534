import { css } from '@emotion/react';
import {
  type FC, useEffect,
} from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useHookWithRefCallback } from '../../utils/hooks/useHookWithRefCallback';

export const DEFAULT_TAB_HEIGHT = 42;

const tabStyle = ({ theme, isActive }: ThemeProps<{ isActive?: boolean }>) => css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  listStyle: 'none',
  flexGrow: 1,
  flexBasis: 0,
  height: DEFAULT_TAB_HEIGHT,
  background: isActive ? theme.backgroundColors.highlight : theme.backgroundColors.secondary,
  border: isActive ? `solid ${theme.borderColors.activeItem}` : `solid ${theme.borderColors.primary}`,
  borderWidth: isActive ? 2 : '1px 1px 1px 0',
  '&:last-of-type': {
    borderRightWidth: isActive ? 2 : 0,
  },
  cursor: 'default',
  textAlign: 'center',
});

export type TabProps = Readonly<{
  activeTab: number;
  className?: string;
  index: number;
  header: React.ReactElement | string;
  onClick: (index: number) => void;
  setTabsHeaderRef?: (tabHeaderRef: HTMLLIElement) => void;
  testid?: string;
}>;

export const TabComponent: FC<TabProps> = (props) => {
  const { activeTab, index, header, onClick } = props;
  const theme = useTheme();
  const [tabRef, setTabRef] = useHookWithRefCallback<HTMLLIElement>();

  const { setTabsHeaderRef } = props;

  useEffect(() => {
    if (tabRef && setTabsHeaderRef) {
      setTabsHeaderRef(tabRef);
    }
  }, [setTabsHeaderRef, tabRef]);

  return (
    <li
      className={props.className}
      css={tabStyle({ theme, isActive: activeTab === index })}
      onClick={() => onClick(index)}
      ref={setTabRef}
      data-testid={props.testid}
    >
      {header}
    </li>
  );
};
