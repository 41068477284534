import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';
import { isDrawingItemsContextMenuActive } from './drawingItemsContextMenu.state';

const drawingItemsContextMenuSelector = (state: AppState) => state.frontendState.drawingItemsContextMenu;

const drawingItemsContextMenuActiveStateSelector = (state: AppState) => {
  const markersContextMenuState = drawingItemsContextMenuSelector(state);
  return isDrawingItemsContextMenuActive(markersContextMenuState) ? markersContextMenuState : null;
};
export const useDrawingItemsContextMenuActiveStateSelector = () => useSelector(drawingItemsContextMenuActiveStateSelector);
