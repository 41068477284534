import {
  type FC, useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type UploadStatus } from '~/_shared/components/uploadStatus/uploadStatus.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { createMapLimitReachedAppError } from '~/appError/specificErrors/mapLimitReached/mapLimitReached.helpers';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { closeAllModals } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { getMapUrl } from '../map.helpers';
import { MAP_LIMIT_REACHED_MESSAGE } from '../map.repository';
import { CreateNewMapComponent } from './createNewMap.component';
import { useCreateBlankMap } from './useCreateBlankMap';
import { useCreateMapFromSpreadsheet } from './useCreateMapFromSpreadsheet';

export type CreateNewMapContainerProps = ModalProps<{
  prefillFormData?: {[key: string]: string | boolean};
  isNewSalesRepMap?: boolean;
}>;

export const CreateNewMapContainer: FC<CreateNewMapContainerProps> = (props) => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const userId = useUserIdSelector();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { replaceCurrentModal: closeCurrentModalAndOpenGoogleSheetMapCreate } = useModal(ModalType.CreateGooogleSheetMap);
  const { openModal: onCreateNewSalesRepMapSuccessModal } = useModal(ModalType.CreateNewSalesRepMapSuccess);

  const {
    createBlankMap,
    resetState: resetBlankMapState,
    errorMessage: blankMapErrorMessage,
    isLoading: isBlankMapLoading,
  } = useCreateBlankMap(clientId);

  const {
    resetState: resetSpreadsheetMapState,
    isLoading: isSpreadsheetMapLoading,
    errorMessage: spreadsheetMapErrorMessage,
    closeErrorMessage: closeSpreadsheetMapErrorMessage,
    createMapFromSpreadsheet,
  } = useCreateMapFromSpreadsheet(clientId, userId);

  const onStartGoogleSheetsClick = useCallback((mapName: string, mapDescription: string) =>
    closeCurrentModalAndOpenGoogleSheetMapCreate(({
      initialMapInfo: {
        name: mapName,
        description: mapDescription,
      },
    })), [closeCurrentModalAndOpenGoogleSheetMapCreate]);

  const onCreateMapSuccess = useCallback((mapId: number) => {
    props.onClose();
    if (props.isNewSalesRepMap) {
      onCreateNewSalesRepMapSuccessModal({
        mapUrl: getMapUrl(mapId),
      });
    }
    else {
      navigate(getMapUrl(mapId));
    }
  }, [navigate, onCreateNewSalesRepMapSuccessModal, props]);

  const onCreateBlankMap = useCallback((mapName: string, mapDescription: string) =>
    createBlankMap(mapName, mapDescription, onCreateMapSuccess),
  [createBlankMap, onCreateMapSuccess]);

  const onCreateMapFromSpreadsheet = useCallback((
    file: File,
    mapName: string,
    mapDescription: string,
    onProgressChange: (progress: number) => void,
    onStatusChange: (status: UploadStatus) => void
  ) => createMapFromSpreadsheet(file, mapName, mapDescription, onProgressChange, onStatusChange, onCreateMapSuccess),
  [createMapFromSpreadsheet, onCreateMapSuccess]);

  useEffect(() => {
    if (!props.isOpen) {
      resetBlankMapState();
      resetSpreadsheetMapState();
    }
  }, [props.isOpen, resetBlankMapState, resetSpreadsheetMapState]);

  useEffect(() => {
    if (!isBlankMapLoading) {
      return;
    }

    resetSpreadsheetMapState();
  }, [isBlankMapLoading, resetSpreadsheetMapState]);

  useEffect(() => {
    if (!isSpreadsheetMapLoading) {
      return;
    }

    resetBlankMapState();
  }, [isSpreadsheetMapLoading, resetBlankMapState]);

  useEffect(() => {
    if (blankMapErrorMessage === MAP_LIMIT_REACHED_MESSAGE || spreadsheetMapErrorMessage === MAP_LIMIT_REACHED_MESSAGE) {
      dispatch(closeAllModals());
      dispatch(createMapLimitReachedAppError(t));
    }
  }, [blankMapErrorMessage, dispatch, spreadsheetMapErrorMessage, t]);

  if (clientId === null || userId === null) {
    return null;
  }

  return (
    <CreateNewMapComponent
      createBlankMapErrorMessage={blankMapErrorMessage}
      createSpreadsheetMapErrorMessage={spreadsheetMapErrorMessage}
      isLoading={isSpreadsheetMapLoading || isBlankMapLoading}
      isNewSalesRepMap={props.isNewSalesRepMap}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onCreateBlankMap={onCreateBlankMap}
      onCreateMapFromSpreadsheet={onCreateMapFromSpreadsheet}
      onCreateSpreadsheetMapErrorMessageClose={closeSpreadsheetMapErrorMessage}
      onStartGoogleSheetsClick={onStartGoogleSheetsClick}
      prefillFormData={props.prefillFormData}
    />
  );
};
