import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  type SpreadsheetHeaderColumn, type SpreadsheetRow, type SpreadsheetSortDescriptor,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import { type FilterTree } from '~/_shared/types/filterTree.types';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { createCancelToken } from '~/_shared/utils/api/api.helpers';
import {
  type ApiError, isCancelError,
} from '~/_shared/utils/api/apiError.helpers';
import { type Uuid } from '~/_shared/utils/createUuid';
import { useDebounce } from '~/_shared/utils/hooks/useDebounce';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import {
  convertRowDataToRow, sortRowValuesByColumns,
} from '~/data/table/sortRowValuesByColumns';
import {
  type BaseMapInfos, getBaseMapNameFromId,
} from '~/map/layered/layering.helpers';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import {
  convertTableDataErrorServerToClient, getSpreadsheetTableData, type SpreadsheetDataRequestSortObject,
  type SpreadsheetTableData, SpreadsheetTableDataError, type SpreadsheetTableDataErrorResponse,
  type SpreadsheetTableDataUpdateItemServerModel, updateSpreadsheetRows,
} from '~/spreadsheet/spreadsheet.repository';
import { useSpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { mapInfoUpdateBadDataCount } from '~/store/mapInfo/mapInfo.actionCreators';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import {
  getSpreadsheetDataRowChanges, type SpreadsheetDataRowChange,
} from './data.helpers';
import { useSpreadsheetDataOutOfSync } from './useSpreadsheetDataOutOfSync';

const getRequestSortParam = (sortDescriptor?: SpreadsheetSortDescriptor): SpreadsheetDataRequestSortObject[] => {
  if (!sortDescriptor) {
    return [];
  }

  const results: SpreadsheetDataRequestSortObject[] = [];
  Object.keys(sortDescriptor).forEach(columnId => {
    const sortType = sortDescriptor[columnId];
    if (!sortType) {
      return;
    }

    results.push({
      id: columnId,
      type: sortType,
    });
  });

  return results;
};

const createUpdateParams = (changes: SpreadsheetDataRowChange[]): SpreadsheetTableDataUpdateItemServerModel[] =>
  changes.map(({ rowId, ...change }) => ({
    ...change,
    row_id: rowId,
  } satisfies SpreadsheetTableDataUpdateItemServerModel));

export const useSpreadsheetDataRaw = ({
  activeMarkerId,
  applyDebounce,
  baseMapsNames,
  clientId,
  columns,
  filterTree,
  initialPerPage,
  sortDescriptor,
  spreadsheetId,
  spreadsheetTableDataETag,
}: {
  activeMarkerId: SpreadsheetRowId | null | undefined;
  applyDebounce?: boolean;
  baseMapsNames: Readonly<BaseMapInfos>;
  clientId: number | null;
  columns: SpreadsheetHeaderColumn[];
  filterTree?: FilterTree;
  initialPerPage?: number;
  sortDescriptor: SpreadsheetSortDescriptor | undefined;
  spreadsheetId: number | null;
  spreadsheetTableDataETag: Uuid;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<SpreadsheetTableDataError | null>(null);
  const [dataResponse, setDataResponse] = useState<SpreadsheetTableData>();
  const [completeData, setCompleteData] = useState<SpreadsheetRow[]>([]);
  const [data, setData] = useState<SpreadsheetRow[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    perPage: initialPerPage || 100,
    lastPage: 1,
    currentPage: 1,
    total: 0,
  });
  const isMountedRef = useIsComponentMountedRef();
  const { onConcurrencyError } = useSpreadsheetDataOutOfSync();
  const dispatch = useDispatch();
  const { oneRow: activeMarkerSpreadsheetRowData } = useSpreadsheetRowData(activeMarkerId);

  const onPageSelect = useCallback((newPage: number) => {
    setPagination(prevPagination => ({
      ...prevPagination,
      currentPage: newPage,
    }));
  }, []);

  const onPerPageChange = useCallback((perPage: number) => {
    setPagination(prevPagination => ({
      ...prevPagination,
      perPage,
    }));
  }, []);

  const updateSpreadsheetData = useCallback((
    clientId: number,
    virtualSpreadsheetId: number,
    changes: SpreadsheetDataRowChange[],
  ) => {
    return updateSpreadsheetRows(clientId, virtualSpreadsheetId, {
      params: createUpdateParams(changes),
    })
      .then(response => {
        if (response.type === 'concurrency-error') {
          return onConcurrencyError();
        }
        else {
          return response;
        }
      });
  }, [onConcurrencyError]);

  const onDataChange = useCallback((newData: SpreadsheetRow[]) => {
    if (!spreadsheetId || !clientId) {
      return;
    }

    const changes = getSpreadsheetDataRowChanges(completeData, newData);

    if (changes.length > 0) {
      setIsLoading(true);

      updateSpreadsheetData(clientId, spreadsheetId, changes)
        .then((response) => {
          if (!response) {
            return;
          }

          if (isMountedRef.current) {
            setData(newData);
            dispatch(spreadsheetResetStateAndRefetchData());
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        });
    }
  }, [clientId, completeData, dispatch, isMountedRef, spreadsheetId, updateSpreadsheetData]);

  const loadSpreadsheetTableData = useCallback(() => {
    if (!clientId || !spreadsheetId) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const cancelToken = createCancelToken();

    getSpreadsheetTableData(
      clientId,
      spreadsheetId,
      {
        page: pagination.currentPage,
        per_page: pagination.perPage,
        sort_object: getRequestSortParam(sortDescriptor),
        ...filterTree ? {
          filter: {
            only_get_filtered: true,
            filter_tree: filterTree,
          },
        } : {},
      }, {
        cancelToken: cancelToken.token,
      },
    )
      .then(response => {
        if (isMountedRef.current && response.data.list.length === 0 && pagination.currentPage > 1) {
          setPagination(prevPagination => ({
            ...prevPagination,
            currentPage: 1,
          }));
          return;
        }

        setDataResponse(response.data);
        dispatch(mapInfoUpdateBadDataCount(spreadsheetId, response.data.geocoding.badData));
        if (isMountedRef.current) {
          setIsLoading(false);

        }
      })
      .catch((e: ApiError) => {
        if (isCancelError(e)) {
          return;
        }

        if (isMountedRef.current) {
          if (e.responseStatus === 422) {
            const errorResponse = e as SpreadsheetTableDataErrorResponse;
            setError(convertTableDataErrorServerToClient(errorResponse));
          }
          else {
            setError(SpreadsheetTableDataError.General);
          }
          setIsLoading(false);
          console.error(e);
        }
      });

    return () => {
      cancelToken.cancel();
    };
  }, [clientId, pagination.currentPage, pagination.perPage, spreadsheetId, sortDescriptor, isMountedRef, filterTree, dispatch]);

  const loadSpreadsheetTableDataDebounced = useDebounce(loadSpreadsheetTableData, 1200);

  useEffect(() => {
    if (clientId && spreadsheetId) {
      if (applyDebounce) {
        loadSpreadsheetTableDataDebounced();
      }
      else {
        loadSpreadsheetTableData();
      }
    }
  }, [spreadsheetTableDataETag, clientId, spreadsheetId, loadSpreadsheetTableData, applyDebounce, loadSpreadsheetTableDataDebounced]);

  useEffect(() => {
    if (!dataResponse || !spreadsheetId) {
      return;
    }

    setData(dataResponse.list.map(row => {
      const rowItems: SpreadsheetRow = {
        baseMapId: null,
        values: [],
        rowId: row.rowId,
        virtualSpreadsheetId: spreadsheetId,
        version: row.version,
      };

      Object.keys(row.columnData).forEach(columnId => {
        if (columnId === BASE_MAPS_COLUMN_ID) {
          const baseMapId = row.columnData[BASE_MAPS_COLUMN_ID];
          if (baseMapId) {
            const baseMapName = getBaseMapNameFromId(baseMapId, baseMapsNames);
            rowItems.baseMapId = +baseMapId;
            rowItems.values.push({
              value: baseMapName,
              columnId: BASE_MAPS_COLUMN_ID,
            });
          }
          return;
        }

        const rowColumnData = row.columnData[columnId];
        if (rowColumnData !== undefined) {
          rowItems.values.push({
            value: rowColumnData,
            columnId,
          });
        }
      });

      return rowItems;
    }).map((row) => sortRowValuesByColumns(row, columns)));

    setPagination(dataResponse.pagination);
  }, [baseMapsNames, columns, dataResponse, spreadsheetId]);

  const newCompleteData = useMemo(() => {
    if (!activeMarkerId || !activeMarkerSpreadsheetRowData.rowData || !spreadsheetId) {
      return data;
    }

    const currentPageContainsActiveMarker = data.some(row => row.rowId === activeMarkerId.rowId);
    if (currentPageContainsActiveMarker) {
      return data;
    }

    const cookedRowData = sortRowValuesByColumns(convertRowDataToRow(activeMarkerSpreadsheetRowData.rowData, spreadsheetId), columns);
    return [cookedRowData, ...data];
  }, [activeMarkerId, activeMarkerSpreadsheetRowData.rowData, spreadsheetId, columns, data]);

  const completeIsLoading = activeMarkerId ? isLoading && activeMarkerSpreadsheetRowData.isLoading : isLoading;

  useEffect(() => {
    setCompleteData(newCompleteData);
  }, [newCompleteData]);

  return {
    data: newCompleteData,
    onDataChange,
    isLoading: completeIsLoading,
    error,
    pagination,
    onPageSelect,
    onPerPageChange,
  };
};
