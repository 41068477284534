import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';

type BoundarySettingsRestoreButtonProps = Readonly<{
  className?: string;
  isDisabled?: boolean;
  onClick: () => void;
  prefixIcon: IconProp;
  text: string;
}>;

const restoreButtonStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled?: boolean }>) => css({
  background: 'none',
  border: 'none',
  color: theme.buttonColors.secondaryBackground,
  fontSize: '18px',
  '&:hover': isDisabled ? undefined : {
    background: 'none',
    textDecoration: 'underline',
  },
});

const restoreButtonIconStyle = css({
  fontSize: '16px',
  marginRight: 5,
});

export const BoundarySettingsRestoreButtonComponent: FC<BoundarySettingsRestoreButtonProps> = (props) => {
  const theme = useTheme();

  return (
    <ButtonComponent
      buttonStyle={ButtonStyle.Custom}
      isDisabled={props.isDisabled}
      className={props.className}
      css={restoreButtonStyle({ theme, isDisabled: props.isDisabled })}
      prefixIcon={props.prefixIcon}
      text={props.text}
      onClick={props.onClick}
      prefixIconStyle={restoreButtonIconStyle}
    />
  );
};
