import {
  useCallback, useState,
} from 'react';
import { type Pagination } from '../../types/pagination/pagination';

export const usePagination = (initPagination?: Pagination) => {
  const [pagination, setPagination] = useState<Pagination>(initPagination || {
    lastPage: 1,
    currentPage: 1,
    perPage: 10,
    total: 0,
  });

  const setPage = useCallback((page: number) => {
    setPagination(currentPagination => ({
      ...currentPagination,
      currentPage: page,
    }));
  }, []);

  return {
    pagination,
    setPage,
    setPagination,
  };
};
