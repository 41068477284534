import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  DRAWING_TOOL_NUMBER_MAX_RADIUS, DRAWING_TOOL_NUMBER_MIN_RADIUS,
} from '~/drawingTool/drawingTool.constants';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import {
  DrawingToolMode, useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useDrawingSettingsSelector } from '~/store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { drawingSettingsSetSettings } from '~/store/mapSettings/drawing/settings/drawingSettings.actionCreators';
import { type DrawingNumberSettingsState } from '~/store/mapSettings/drawing/settings/number/drawingNumberSettings.state';
import { type DrawingToolManager } from '../drawingToolManager';

type DrawingToolNumberCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingNumberSettingsState;
}>;

export const DrawingToolNumberCreateNewInstanceContainer: FC<DrawingToolNumberCreateNewInstanceProps> = ({
  manager, settings,
}) => {
  const drawingToolMode = useDrawingToolModeSelector();
  const drawingSettings = useDrawingSettingsSelector();
  const dispatch = useDispatch();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (!event.latLng) {
      return;
    }

    const id = createUuid();

    const ratio = settings.scalesWithMapZoom ? sizePerPixelRatio : 1;

    const radius = clamp(settings.radius, { min: DRAWING_TOOL_NUMBER_MIN_RADIUS, max: DRAWING_TOOL_NUMBER_MAX_RADIUS }) * ratio;

    dispatch(drawingItemsAddItem(
      id,
      {
        type: DrawingTool.Number,
        value: {
          id,
          center: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
          placement: DrawingItemPlacement.Default,
          settings: {
            ...settings,
            radius,
          },
        },
      }));

    dispatch(drawingSettingsSetSettings(DrawingTool.Number, {
      ...drawingSettings,
      number: {
        ...settings,
        counter: settings.counter + 1,
      },
    }));
  }, [dispatch, settings, drawingSettings, sizePerPixelRatio]);

  useEffect(() => {
    if (!onMapClick || drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);

    return () => {
      clickListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode]);

  return null;
};
