import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  faCopy,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { copyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { getAbsolutePresentationalMapUrl } from '~/map/map.helpers';
import { type ShareMapEmailResult } from '~/map/map.repository';
import { ShareMapSendEmailComponent } from '../sendEmail/shareMapSendEmail.component';

export enum ShareMethod {
  Email = 'email',
  Copy = 'copy'
}

const copyShareContainerStyle = css({
  display: 'flex',
  marginTop: 22,
});

const copyEmailContainerStyle = css({
  marginTop: 20,
});

const copyShareInputStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.disabled,
  flex: 1,
  height: 48,
  borderRadius: '4px 0 0 4px',
  borderRight: 'none',
});

const shareButtonStyle = ({ theme }: ThemeProps) => css({
  borderRadius: '0 4px 4px 0',
  overflow: 'hidden',
  background: theme.buttonColors.primaryBackground,
  color: theme.buttonColors.primaryText,
  padding: '0 12px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  transition: 'background .2s',
  textDecoration: 'none',
  '&:hover': {
    background: theme.buttonColors.primaryBackgroundOnHover,
  },
});

const shareIconStyle = css({
  marginRight: 8,
});

const copyAlertStyle = css({
  marginTop: 10,
});

type ShareSectionProps = Readonly<{
  value: ShareMethod | null;
  accordionLabelStyle: SerializedStyles;
  radioGroupStyle: SerializedStyles;
  radioItemStyle?: SerializedStyles;
  shareId: string;
  sendEmailResult: ShareMapEmailResult | null;

  onChange: (method: ShareMethod) => void;
  onSubmitSendEmail: (emails: ReadonlyArray<string>, optionalMessage: string) => void;
}>;

export const ShareSectionComponent: FC<ShareSectionProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);

  const copyLink = getAbsolutePresentationalMapUrl(props.shareId);

  const onCopyButtonClick = () => {
    copyToClipboard(copyLink);
    setIsCopyAlertOpen(true);
  };

  return (
    <>
      <RadioGroupComponent
        css={props.radioGroupStyle}
        itemCommonStyle={props.radioItemStyle}
        selectedValue={props.value}
        onValueChange={props.onChange}
        items={[{
          label: <span css={props.accordionLabelStyle}>{t('Email Map Link')}</span>,
          value: ShareMethod.Email,
          icon: <FontAwesomeIcon icon={faPaperPlane} />,
          children: props.value === ShareMethod.Email ? (
            <div css={copyEmailContainerStyle}>
              <ShareMapSendEmailComponent
                onSubmit={props.onSubmitSendEmail}
                sendEmailResult={props.sendEmailResult}
              />
            </div>
          ) : null,
        }, {
          label: <span css={props.accordionLabelStyle}>{t('Copy Map Link')}</span>,
          value: ShareMethod.Copy,
          icon: <FontAwesomeIcon icon={faCopy} />,
          children: props.value === ShareMethod.Copy ? (
            <div css={copyShareContainerStyle}>
              <TextInputComponent
                onChange={() => null}
                isReadOnly
                value={copyLink}
                css={copyShareInputStyle({ theme })}
                icon={null}
              />

              <button
                css={shareButtonStyle({ theme })}
                onClick={onCopyButtonClick}
              >
                <FontAwesomeIcon
                  css={shareIconStyle}
                  icon={faCopy}
                />
                {t('Copy')}
              </button>
            </div>
          ) : null,
        }]}
      />

      {isCopyAlertOpen && props.value === ShareMethod.Copy && (
        <AlertComponent
          css={copyAlertStyle}
          type="success"
          onClose={() => setIsCopyAlertOpen(false)}
          autoCloseTimeout={5000}
        >
          {t('Link copied')}
        </AlertComponent>
      )}
    </>
  );
};
