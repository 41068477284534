import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

const mapComponentLastBoundsSelector = (state: AppState) => state.frontendState.mapComponent.lastBounds;
export const useMapComponentLastBoundsSelector = () => useSelector(mapComponentLastBoundsSelector);

const mapComponentCenterSelector = (state: AppState) => state.frontendState.mapComponent.center;
export const useMapComponentCenterSelector = () => useSelector(mapComponentCenterSelector);

const mapComponentZoomSelector = (state: AppState) => state.frontendState.mapComponent.zoom;
export const useMapComponentZoomSelector = () => useSelector(mapComponentZoomSelector);

const mapComponentZoomToBoundsSelector = (state: AppState) => state.frontendState.mapComponent.zoomToBounds;
export const useMapComponentZoomToBoundsSelector = () => useSelector(mapComponentZoomToBoundsSelector);

const mapComponentZoomToBoundsPreferredZoomSelector = (state: AppState) => state.frontendState.mapComponent.zoomToBoundsPreferredZoom;
export const useMapComponentZoomToBoundsPreferredZoomSelector = () => useSelector(mapComponentZoomToBoundsPreferredZoomSelector);

const mapComponentResetMapOnChangeSelector = (state: AppState) => state.frontendState.mapComponent.resetMap;
export const useResetMapOnChangeSelector = () => useSelector(mapComponentResetMapOnChangeSelector);

const mapComponentMapOptionsCurrentSelector = (state: AppState) => state.frontendState.mapComponent.mapOptions.current;
export const useMapComponentMapOptionsCurrentSelector = () => useSelector(mapComponentMapOptionsCurrentSelector);
