import { type SpreadsheetRowId } from '../../types/spreadsheetData/spreadsheetRow';

const key = (id: SpreadsheetRowId) => `${id.spreadsheetId}|${id.rowId}`;

export interface ReadonlySpreadsheetRowIdMap<V> extends ReadonlyMap<SpreadsheetRowId, V> {
  isSpreadsheetRowIdMap: true;
}

export class SpreadsheetRowIdMap<V> implements ReadonlySpreadsheetRowIdMap<V>, Map<SpreadsheetRowId, V> {
  private lookup: Map<string, {value: V; id: SpreadsheetRowId}> = new Map();

  public readonly isSpreadsheetRowIdMap = true;

  constructor(data?: Iterable<readonly [SpreadsheetRowId, V | undefined]>) {
    if (data) {
      for (const item of data) {
        if (item[1] !== undefined) {
          this.set(item[0], item[1]);
        }
      }
    }
  }

  public get size() {
    return this.lookup.size;
  }

  public get = (id: SpreadsheetRowId) => this.lookup.get(key(id))?.value;

  public has = (id: SpreadsheetRowId) => this.lookup.has(key(id));

  public set = (id: SpreadsheetRowId, value: V) => {
    this.lookup.set(key(id), { value, id });
    return this;
  };

  public delete = (id: SpreadsheetRowId) => this.lookup.delete(key(id));

  public clear = () => this.lookup.clear();

  public forEach = (callback: (value: V, id: SpreadsheetRowId, map: Map<SpreadsheetRowId, V>) => void) =>
    this.lookup.forEach((v) => callback(v.value, v.id, this as Map<SpreadsheetRowId, V>));

  public toJSON = () => JSON.stringify(this.lookup);

  public entries = (): MapIterator<[SpreadsheetRowId, V]> => {
    const iterator = this.lookup.values();

    return {
      [Symbol.iterator]: this.entries,
      next: () => {
        const next = iterator.next();
        return {
          done: next.done,
          value: next.value
            ? [next.value.id, next.value.value]
            : (undefined as any),
        };
      },
    } as MapIterator<[SpreadsheetRowId, V]>;
  };

  public values = (): MapIterator<V> => {
    const iterator = this.lookup.values();

    return {
      [Symbol.iterator]: this.values,
      next: () => {
        const next = iterator.next();
        return {
          done: next.done,
          value: next.value?.value,
        };
      },
    } as MapIterator<V>;
  };

  public keys = (): MapIterator<SpreadsheetRowId> => {
    const iterator = this.lookup.values();

    return {
      [Symbol.iterator]: this.keys,
      next: () => {
        const next = iterator.next();
        return {
          done: next.done,
          value: next.value?.id,
        };
      },
    } as MapIterator<SpreadsheetRowId>;
  };

  public asReadonly: () => ReadonlySpreadsheetRowIdMap<V> = () => this;

  [Symbol.iterator] = this.entries;

  get [Symbol.toStringTag]() {
    return 'SpreadsheetRowIdMap';
  }
}
