import { type MapOptions } from '../../../../_shared/types/googleMaps/googleMaps.types';
import {
  defaultMapOptions,
  type MapComponentOptionsState, type MapComponentOptionsStateChange,
} from './mapComponentOptions.state';

export type MapOptionsConfig = {
  priority: number;
};

const aggregateChanges = (changes: MapComponentOptionsStateChange[]) => {
  return changes
    .map((change, index) => ({ ...change, index }))
    // order by priority ASC, index ASC
    .sort((a, b) => a.priority !== b.priority ? a.priority - b.priority : a.index - b.index)
    .reduce<MapOptions>((aggregated, current) => ({
      ...aggregated,
      ...current.change,
    }), defaultMapOptions);
};

export const push = (state: MapComponentOptionsState, change: MapComponentOptionsStateChange) => {
  const context = [
    ...state.context,
    change,
  ];

  return {
    current: aggregateChanges(context),
    context,
  };
};

export const pop = (state: MapComponentOptionsState, changeIds: string | ReadonlyArray<string>) => {
  const changeArray = Array.isArray(changeIds) ? changeIds : [changeIds];

  const context = state.context.filter(item => changeArray.every(removeId => removeId !== item.changeId));

  return {
    current: aggregateChanges(context),
    context,
  };
};
