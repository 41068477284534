import { combineReducers } from 'redux';
import { boundarySidebarStateReducer } from '~/store/frontendState/mapTools/boundary/boundarySidebarState/boundarySidebarState.reducer';
import { boundaryCreateOptimizedReducer } from './boundaryCreateOptimized/boundaryCreateOptimized.reducer';
import { boundaryDrawReducer } from './boundaryDraw/boundaryDraw.reducer';
import { boundaryFormReducer } from './boundaryForm/boundaryForm.reducer';
import { boundaryHighlightReducer } from './boundaryHighlight/boundaryHighlight.reducer';
import { boundaryPolygonUpdateReducer } from './boundaryPolygonUpdate/boundaryPolygonUpdate.reducer';
import { boundaryRenderingReducer } from './boundaryRendering/boundaryRendering.reducer';
import { boundarySelectReducer } from './boundarySelect/boundarySelect.reducer';
import { convertBoundariesReducer } from './convertBoundaries/convertBoundaries.reducer';

export const boundaryToolStateReducer = combineReducers({
  boundaryHighlight: boundaryHighlightReducer,
  boundarySelect: boundarySelectReducer,
  boundaryDraw: boundaryDrawReducer,
  convertBoundaries: convertBoundariesReducer,
  boundaryRendering: boundaryRenderingReducer,
  boundaryPolygonUpdate: boundaryPolygonUpdateReducer,
  boundaryCreateOptimized: boundaryCreateOptimizedReducer,
  boundaryForm: boundaryFormReducer,
  boundarySidebarHighlight: boundarySidebarStateReducer,
});
