import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  faLock,
  faShieldCheck,
  faUnlock,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { useTheme } from '../../themes/theme.hooks';
import { MapPrivacyLevel } from '../../types/map';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import {
  createPasswordErrorMessage,
  validatePassword,
  validatePasswords,
} from '../../utils/throttle/validation/validation.helpers';
import { PasswordStrengthIndicatorComponent } from '../passwordStrengthIndicator/passwordStrengthIndicator.component';

const EMPTY_PASSWORD_ERRORS: string[] = [];

const accordionLabelStyle = css({
  textTransform: 'uppercase',
  fontSize: '16px',
});

const inputStyle = ({ theme, isValid }: ThemeProps<{ isValid: boolean }>) => css({
  borderColor: isValid ? theme.lineColors.green : undefined,
});

const confirmPasswordContainerStyle = css({
  marginTop: 5,
});

const iconStyle = css({
  fontSize: 28,
  width: 25,
});

const containerStyle = css({
  width: '100%',
});

const alertContainerStyle = css({
  marginTop: 15,
  width: '100%',
});

const passwordFormStyle = css({
  marginTop: 10,
});

type MapPrivacySelectorProps = Readonly<{
  disabledPrivacyLevels?: ReadonlyArray<MapPrivacyLevel>;
  globalError?: string;
  passwordError?: string;
  privacyLevel: MapPrivacyLevel | null;
  radioGroupStyle?: SerializedStyles;
  showPrivate?: boolean;

  onChange: (newPrivacyLevel: MapPrivacyLevel, password?: string) => void;
  onClearGlobalError?: () => void;
  onClearPasswordError?: () => void;
  onIsFormValidChanged?: (isDisabled: boolean) => void;
}>;

export const MapPrivacySelectorComponent: FC<MapPrivacySelectorProps> = (props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showIfPasswordsMatch, setShowIfPasswordsMatch] = useState(false);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>([]);
  const [t] = useTranslation();
  const theme = useTheme();
  const [originalPrivacyLevel] = useState(props.privacyLevel);

  const validateConfirmPassword = useCallback(() => {
    if (password !== confirmPassword) {
      setShowIfPasswordsMatch(true);
    }
  }, [confirmPassword, password]);

  const checkPassword = useCallback(() =>
    setPasswordErrorMessages(createPasswordErrorMessage(password, t)),
  [password, t]);

  const submitPasswordIfValid = useCallback(({ password, confirmPassword }: Readonly<{ password: string; confirmPassword: string }>) => {
    const onIsFormValidChanged = props.onIsFormValidChanged;
    onIsFormValidChanged?.(password === confirmPassword && validatePasswords(password, confirmPassword));
    const onClearPasswordError = props.onClearPasswordError;
    onClearPasswordError?.();

    if (props.privacyLevel && password === confirmPassword && validatePasswords(password, confirmPassword)) {
      const onChange = props.onChange;
      onChange(props.privacyLevel, password);
    }
  }, [props.onIsFormValidChanged, props.onClearPasswordError, props.privacyLevel, props.onChange]);

  const onPrivacyLevelChange = useCallback((privacyLevel: string) => {
    let newPrivacyLevel;
    setShowIfPasswordsMatch(true);

    switch (privacyLevel) {
      case MapPrivacyLevel.Public.toString():
        newPrivacyLevel = (MapPrivacyLevel.Public);
        break;
      case MapPrivacyLevel.PasswordProtected.toString():
        newPrivacyLevel = (MapPrivacyLevel.PasswordProtected);
        break;
      case MapPrivacyLevel.Private.toString():
        newPrivacyLevel = (MapPrivacyLevel.Private);
        break;
      default:
        return;
    }
    const onChange = props.onChange;
    const onClearPasswordError = props.onClearPasswordError;
    const onIsFormValidChanged = props.onIsFormValidChanged;

    onChange(newPrivacyLevel, password);
    onClearPasswordError?.();
    onIsFormValidChanged?.(newPrivacyLevel !== MapPrivacyLevel.PasswordProtected);
  }, [password, props.onIsFormValidChanged, props.onChange, props.onClearPasswordError]);

  const errorMessages = useMemo(() =>
    createPasswordErrorMessage(password, t),
  [password, t]);

  useEffect(() => {
    if (!password.length || validatePassword(password)) {
      setPasswordErrorMessages(EMPTY_PASSWORD_ERRORS);
    }
    else {
      setPasswordErrorMessages(errorMessages);
    }
  }, [errorMessages, password, passwordErrorMessages, t]);

  const passwordErrors = useMemo(() =>
    props.passwordError ? [props.passwordError, ...passwordErrorMessages] : passwordErrorMessages,
  [passwordErrorMessages, props.passwordError]);

  useEffect(() => {
    if (showIfPasswordsMatch && password === confirmPassword) {
      setShowIfPasswordsMatch(false);
    }
  }, [showIfPasswordsMatch, password, confirmPassword]);

  const isValid = validatePasswords(password, confirmPassword);

  const passwordInputPlaceholder = originalPrivacyLevel === MapPrivacyLevel.PasswordProtected ? '******' : t('Enter password');

  const Password = (
    <form css={passwordFormStyle}>
      <FormTextInputComponent
        inputStyle={inputStyle({ theme, isValid })}
        onChange={newPassword => {
          setPassword(newPassword);
          submitPasswordIfValid({ password: newPassword, confirmPassword });
        }}
        value={password}
        type="password"
        placeholder={passwordInputPlaceholder}
        onBlur={checkPassword}
        errorMessages={passwordErrors}
        autoComplete="new-password"
        icon={faLock}
      />
      <PasswordStrengthIndicatorComponent password={password} />

      <div css={confirmPasswordContainerStyle}>
        <FormTextInputComponent
          inputStyle={inputStyle({ theme, isValid })}
          onChange={newConfirmPassword => {
            setConfirmPassword(newConfirmPassword);
            submitPasswordIfValid({ password, confirmPassword: newConfirmPassword });
          }}
          value={confirmPassword}
          type="password"
          placeholder={passwordInputPlaceholder}
          onBlur={validateConfirmPassword}
          errorMessages={showIfPasswordsMatch ? [t('Passwords do not match')] : EMPTY_PASSWORD_ERRORS}
          icon={faLock}
        />
      </div>
    </form>
  );

  const items = [{
    label: <span css={accordionLabelStyle}>{t('Password Protected')}</span>,
    description: t('Only people with the password can view.'),
    value: MapPrivacyLevel.PasswordProtected.toString(),
    icon: (
      <FontAwesomeIcon
        css={iconStyle}
        icon={faLock}
      />
    ),
    children: props.privacyLevel === MapPrivacyLevel.PasswordProtected
     && (!props.disabledPrivacyLevels || !props.disabledPrivacyLevels.includes(MapPrivacyLevel.PasswordProtected))
      ? Password : null,
    isDisabled: props.disabledPrivacyLevels && props.disabledPrivacyLevels.includes(MapPrivacyLevel.PasswordProtected),
  }, {
    label: <span css={accordionLabelStyle}>{t('Public')}</span>,
    description: t('Anyone with the link can view.'),
    value: MapPrivacyLevel.Public.toString(),
    icon: (
      <FontAwesomeIcon
        css={iconStyle}
        icon={faUnlock}
      />
    ),
    isDisabled: props.disabledPrivacyLevels && props.disabledPrivacyLevels.includes(MapPrivacyLevel.Public),
  }];

  if (props.showPrivate) {
    items.splice(0, 0, {
      label: <span css={accordionLabelStyle}>{t('Private')}</span>,
      description: t('Only people with the login to your Maptive account can view the map.'),
      value: MapPrivacyLevel.Private.toString(),
      icon: (
        <FontAwesomeIcon
          css={iconStyle}
          icon={faShieldCheck}
        />
      ),
      isDisabled: props.disabledPrivacyLevels && props.disabledPrivacyLevels.includes(MapPrivacyLevel.Private),
    });
  }
  return (
    <div css={containerStyle}>
      <RadioGroupComponent
        css={props.radioGroupStyle}
        selectedValue={props.privacyLevel?.toString() || ''}
        onValueChange={onPrivacyLevelChange}
        items={items}
      />
      {!!props.globalError && (
        <div css={alertContainerStyle}>
          <AlertComponent
            type="danger"
            onClose={props.onClearGlobalError}
          >
            {props.globalError}
          </AlertComponent>
        </div>
      )}
    </div>
  );
};
