import React, { useCallback } from 'react';
import { SUBSCRIPTION_ROUTE } from '~/_shared/constants/routes';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  useUserCurrentClientSelector,
  useUserDataSelector,
} from '~/store/userData/userData.selectors';
import { useManageClientSubscriptionRenewal } from '~/subscription/useManageClientSubscriptionRenewal.hook';
import { useDeleteClientOrRemoveSubscription } from '~/subscription/useOpenConfirmationForDelete.hook';
import { SubscriptionManagementComponent } from './subscriptionManagement.component';

type SubscriptionManagementProps = ModalProps;

export const SubscriptionManagementContainer: React.FC<SubscriptionManagementProps> = props => {
  const userData = useUserDataSelector();
  const license = useUserCurrentClientSelector()?.license;
  const { restoreRenewal, isLoading: isUpdateRenewalLoading } = useManageClientSubscriptionRenewal();
  const { deleteOrCancel, isLoading: isDeleteClientLoading } = useDeleteClientOrRemoveSubscription();

  const launchPaymentPortal = useCallback(() => openUrlInNewTab(SUBSCRIPTION_ROUTE), []);

  if (!license) {
    return null;
  }

  return (
    <SubscriptionManagementComponent
      {...props}
      isLoading={isDeleteClientLoading || isUpdateRenewalLoading || userData.isLoading}
      isAutoRenewalOn={license.autoRenewal}
      billed={license.type}
      onCancelSubscription={deleteOrCancel}
      onLaunchPaymentPortal={launchPaymentPortal}
      licenseName={license.name}
      licenseStatus={license.state}
      nextBillingDate={license.end}
      onRestoreSubscription={restoreRenewal}
    />
  );
};
SubscriptionManagementContainer.displayName = 'SubscriptionManagementContainer';
