import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { CheckboxComponent } from '../../baseComponents/checkbox';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

type DisclaimerProps = {
  className?: string;
  checkboxLabel?: string;
  onClose: (isPermanent: boolean) => void;
  children?: React.ReactNode;
};

const closeButtonStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  background: 'none',
  border: 'none',
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  right: 0,
  fontSize: 20,
  height: 34,
  width: 34,
  lineHeight: '34px',
  textAlign: 'center',
  outline: 'none',
});

const wrapperStyle = ({ theme }: ThemeProps) => css({
  position: 'relative',
  padding: '23px 23px 13px',
  borderRadius: 4,
  background: theme.backgroundColors.secondary,
  border: '1px solid ' + theme.lineColors.basePrimary,
  boxShadow: `0 0 0 2px ${theme.buttonColors.secondaryBackground}`,
});

const checkboxWrapperStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  paddingTop: 14,
});

export const DisclaimerComponent: FC<DisclaimerProps> = (props) => {
  const [isClosePermanent, setIsClosePermanent] = useState(false);
  const theme = useTheme();

  const onConfirmPermanentCheckboxClick = () => {
    setIsClosePermanent(isPermanent => !isPermanent);
  };

  return (
    <div
      css={wrapperStyle({ theme })}
      className={props.className}
    >
      <button
        css={closeButtonStyle({ theme })}
        onClick={() => props.onClose(isClosePermanent)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {props.children}

      {props.checkboxLabel && (
        <div css={checkboxWrapperStyle({ theme })}>
          <CheckboxComponent
            isChecked={isClosePermanent}
            checkedSetter={onConfirmPermanentCheckboxClick}
            text={props.checkboxLabel}
          />
        </div>
      )}
    </div>
  );
};
