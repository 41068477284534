import { getLabelPaddingsValues } from '~/_shared/components/labelVisualizer/labelVisualizer.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { getLabelStroke } from '~/_shared/utils/labels/labels.helpers';
import { type MarkerEntityOffsets } from '~/_shared/utils/markers/offsets.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import {
  createLabelWithBodyVirtualLayer, createWebglOverlayLabelWithBody, type LabelWithBodyVirtualLayer,
  type WebglOverlayLabelWithBody,
} from '~/_shared/utils/webgl/labelWithBody';
import {
  getSingleZIndex, ZIndexedEntity,
} from '~/map/zIndexes/zIndexRanges';
import { type ExtendsWebglLayers } from '../../webgl/useWebGL';

type RequiredLayers = ExtendsWebglLayers<{
  MarkerHoverLabelText: LabelsLayer;
  MarkerHoverLabelBackground: TextBoxCalloutLayer;
}>;

export class MarkerHoverEffectsManager {
  private readonly labelsVirtualLayer: LabelWithBodyVirtualLayer;
  private aboveMarkerLabel: WebglOverlayLabelWithBody | null = null;

  constructor(layers: RequiredLayers) {
    this.labelsVirtualLayer = createLabelWithBodyVirtualLayer(layers.MarkerHoverLabelText, layers.MarkerHoverLabelBackground);
  }

  public updateAboveMarkerLabel = (latLng: LatLng | null, text?: string | null, labelSettings?: LabelVisualSetting, offsets?: MarkerEntityOffsets) => {
    if (this.aboveMarkerLabel) {
      this.labelsVirtualLayer.remove(this.aboveMarkerLabel);
      this.aboveMarkerLabel = null;
    }

    if (latLng && notNullsy(text) && text !== '' && labelSettings) {
      this.aboveMarkerLabel = this.addAboveMarkerLabel(latLng, text, labelSettings, offsets);
      this.labelsVirtualLayer.add(this.aboveMarkerLabel);
    }
  };

  private addAboveMarkerLabel = (latLng: LatLng, text: string, labelSettings: LabelVisualSetting, offsets?: MarkerEntityOffsets) => {
    const zIndexBackground = getSingleZIndex(ZIndexedEntity.HoverLabelBackground);
    const zIndexText = getSingleZIndex(ZIndexedEntity.HoverLabelText);

    const paddings = getLabelPaddingsValues(labelSettings.bodyProps);
    const stroke = getLabelStroke(labelSettings.bodyProps);

    return createWebglOverlayLabelWithBody({
      lat: latLng.lat,
      lng: latLng.lng,
      text: {
        value: text,
        strokeWidth: stroke.strokeWidth,
        strokeColor: stroke.strokeColor,
        fillColor: convertColorToWebGLColor(labelSettings.bodyProps.fontColor.selectedColor),
        fontSize: labelSettings.bodyProps.fontSize,
      },
      borderColor: convertColorToWebGLColor(labelSettings.borderProps.color.selectedColor),
      borderWidth: labelSettings.borderProps.width,
      borderRadius: labelSettings.borderProps.radius,
      fillColor: convertColorToWebGLColor(labelSettings.bodyProps.backgroundColor.selectedColor, labelSettings.bodyProps.backgroundColor.opacity),
      triangle: true,
      triangleWidth: labelSettings.arrowProps.dimensions.width,
      triangleHeight: labelSettings.arrowProps.dimensions.height,
      offset: {
        y: -(labelSettings.arrowProps.dimensions.height || 0) - (offsets?.topOffset || 0),
        x: offsets?.leftOffset || 0,
      },
      padding: {
        t: paddings.top,
        r: paddings.right,
        l: paddings.left,
        b: paddings.bottom,
      },
      autoHideWhenCollide: false,
      interactive: false,
    }, {
      text: zIndexText,
      background: zIndexBackground,
    });
  };

  public destroy = () => {
    if (this.aboveMarkerLabel) {
      this.labelsVirtualLayer.remove(this.aboveMarkerLabel);
      this.aboveMarkerLabel = null;
    }
  };
}
