import { type MapSettingsProximityAction } from '../../../../mapSettings/proximity/mapSettingsProximity.action';
import { type ProximityToolAction } from '../../../proximityTool/proximityTool.action';
import {
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED,
} from '../../../proximityTool/proximityTool.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop,
  push,
} from '../mapComponentOptionsStateOperations';

const individualRadiusDraggingChangeId = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAGGING';
const individualRadiusDnDModeChangeId = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE';
const individualRadiusCenterHoveredChangeId = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED';
const individualRadiusHoveredInDnDChangeId = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVERED_IN_DND';

const proximityHoverId = 'PROXIMITY_HOVERED';

export const applyProximityMapOptions = (
  state: MapComponentOptionsState,
  action: ProximityToolAction | MapSettingsProximityAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED:
      return push(state, {
        changeId: individualRadiusDraggingChangeId,
        priority,
        change: {
          gestureHandling: 'none',
          clickableIcons: false,
        },
      });

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED:
      return pop(state, individualRadiusDraggingChangeId);

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED:
      return push(state, {
        changeId: individualRadiusCenterHoveredChangeId,
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
        },
      });

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED:
      return pop(state, individualRadiusCenterHoveredChangeId);

    case PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED:
      return push(state, {
        changeId: proximityHoverId,
        priority,
        change: {
          draggableCursor: 'pointer',
        },
      });

    case PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED:
      return pop(state, proximityHoverId);

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED: {
      const withoutCenterHover = pop(state, individualRadiusCenterHoveredChangeId);

      return push(withoutCenterHover, {
        changeId: individualRadiusDnDModeChangeId,
        priority,
        change: {
          clickableIcons: false,
        },
      });
    }

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED: {
      return pop(state, [
        individualRadiusDnDModeChangeId,
        individualRadiusCenterHoveredChangeId,
        individualRadiusHoveredInDnDChangeId,
      ]);
    }

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED:
      return push(state, {
        changeId: individualRadiusHoveredInDnDChangeId,
        priority,
        change: {
          draggableCursor: 'move',
        },
      });

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED:
      return pop(state, individualRadiusHoveredInDnDChangeId);

    default:
      return undefined;
  }
};
