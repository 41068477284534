import {
  type LatLng,
  type LatLngBounds,
} from '../../_shared/types/latLng';
import { areLatLngEqual } from '../../_shared/utils/latLng/latLng.helpers';

// In the future can be rewritten to use geometry.LatLngBounds from spherical-geometry-js
export class BoundingBox {
  private boundingBox: google.maps.LatLngBounds;

  constructor(googleBounds?: google.maps.LatLngBounds) {
    this.boundingBox = googleBounds
      ? new google.maps.LatLngBounds(googleBounds.getSouthWest(), googleBounds.getNorthEast())
      : new google.maps.LatLngBounds();
  }

  getGoogleMapsLatLng(): google.maps.LatLngBounds {
    return this.boundingBox;
  }

  extend(latLng: Readonly<LatLng>) {
    this.boundingBox.extend(latLng);
  }

  getNorthEast(): LatLng {
    const northEast = this.boundingBox.getNorthEast();
    return {
      lat: northEast.lat(),
      lng: northEast.lng(),
    };
  }

  getSouthWest(): LatLng {
    const southWest = this.boundingBox.getSouthWest();
    return {
      lat: southWest.lat(),
      lng: southWest.lng(),
    };
  }

  getBounds(): LatLngBounds {
    return {
      ne: this.getNorthEast(),
      sw: this.getSouthWest(),
    };
  }

  contains(latLng: Readonly<LatLng>): boolean {
    return this.boundingBox.contains(latLng);
  }

  containsBoundingBox(bb: BoundingBox): boolean {
    if (this.isEmpty() && bb.isEmpty()) {
      return true;
    }

    if (!this.isEmpty()) {
      if (bb.isEmpty()) {
        return true;
      }

      const newBB = this.clone();
      newBB.extend(bb.getNorthEast());
      newBB.extend(bb.getSouthWest());
      if (areLatLngEqual(this.getNorthEast(), newBB.getNorthEast())
        && areLatLngEqual(this.getSouthWest(), newBB.getSouthWest())) {
        return true;
      }
    }
    return false;
  }

  isEmpty(): boolean {
    return this.getGoogleMapsLatLng().isEmpty();
  }

  clone(): BoundingBox {
    return new BoundingBox(this.getGoogleMapsLatLng());
  }

  toString(): string {
    return this.getGoogleMapsLatLng().toString();
  }

  intersects(bounds: LatLngBounds): boolean {
    const other = new google.maps.LatLngBounds(bounds.sw, bounds.ne);
    return this.getGoogleMapsLatLng().intersects(other);
  }
}
