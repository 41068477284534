import { ThemeProvider } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { useCurrentThemeTypeSelector } from '~/store/theme/theme.selectors';
import { getThemeForType } from './themeWrapper.helpers';

export const ThemeWrapperComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const themeType = useCurrentThemeTypeSelector();

  return (
    <ThemeProvider theme={getThemeForType(themeType)}>
      {children}
    </ThemeProvider>
  );
};
