import { type DrawingEditAction } from '../drawingEdit/drawingEdit.action';
import {
  FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES,
  FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
} from '../drawingEdit/drawingEdit.actionTypes';
import { type DrawingImageSelectorAction } from './drawingImageSelector.action';
import {
  FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_EDITED_FILE_ID,
  FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_NEW_INSTANCE_FILE_ID,
} from './drawingImageSelector.actionTypes';
import { type DrawingImageSelectorState } from './drawingImageSelector.state';

const initialState: DrawingImageSelectorState = {
  newInstanceFileId: null,
  editedDrawingFileId: null,
  editedDrawingInitialFileId: null,
};

export const drawingImageSelectorReducer = (
  state = initialState, action: DrawingImageSelectorAction | DrawingEditAction
): DrawingImageSelectorState => {
  switch (action.type) {
    case FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_NEW_INSTANCE_FILE_ID:
      return {
        ...state,
        newInstanceFileId: action.payload.imageFileId,
      };

    case FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_EDITED_FILE_ID:
      return {
        ...state,
        editedDrawingFileId: action.payload.imageFileId,
        editedDrawingInitialFileId: state.editedDrawingInitialFileId ?? action.payload.imageFileId,
      };

    case FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING:
    case FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES:
      return {
        ...state,
        editedDrawingFileId: null,
        editedDrawingInitialFileId: null,
      };

    default:
      return state;
  }
};
