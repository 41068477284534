import { css } from '@emotion/react';
import { type FC } from 'react';
import maptiveLogo from '../../../../../assets/images/maptiveSVGfromEPS.svg';

type LogoProps = Readonly<{
  width: string;
  className?: string;
}>;

const wrapperStyle = ({ width }: { width: string}) => css({
  width,
});

const logoStyle = css({
  display: 'block',
  width: '100%',
  height: 'auto',
});

export const LogoComponent: FC<LogoProps> = (props: LogoProps) => (
  <div
    css={wrapperStyle({ width: props.width })}
    className={props.className}
  >
    <img
      css={logoStyle}
      src={maptiveLogo}
      alt="Maptive logo"
      className={props.className}
    />
  </div>
);
