import { useMemo } from 'react';
import { type SpreadsheetRowId } from '../_shared/types/spreadsheetData/spreadsheetRow';
import { type PerSpreadsheetRowIds } from './useAreaSpreadsheetRowIds';

export const useAreaMarkers = (
  perSpreadsheetRowIds: PerSpreadsheetRowIds, spreadsheetRowId?: SpreadsheetRowId
): SpreadsheetRowId[] => {
  return useMemo(() => {
    if (spreadsheetRowId) {
      return (perSpreadsheetRowIds[spreadsheetRowId.spreadsheetId] ?? []).map(rowId => {
        return {
          spreadsheetId: spreadsheetRowId.spreadsheetId,
          rowId,
        };
      });
    }

    return Object.keys(perSpreadsheetRowIds).reduce<SpreadsheetRowId[]>((acc, spreadsheetIdString) => {
      const spreadsheetId = +spreadsheetIdString;

      const newSpreadsheetRows = perSpreadsheetRowIds[spreadsheetId]?.map(rowId => ({ spreadsheetId, rowId }));
      if (newSpreadsheetRows) {
        acc = acc.concat(newSpreadsheetRows);
      }

      return acc;
    }, []);
  }, [perSpreadsheetRowIds, spreadsheetRowId]);
};
