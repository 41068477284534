export const loadGoogleApiScript = (callback: () => void) => {
  const existingScript = document.getElementById('googleApi');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.id = 'googleApi';
    script.id = 'googleApi';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      if (callback) {
        callback();
      }
    };
    script.onerror = () => {
      console.error('Error loading google api.');
    };

    document.body.appendChild(script);
  }

  if (existingScript && callback) {
    callback();
  }
};
