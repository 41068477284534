import { MapFeature } from './mapFeatures.types';
import { MapTool } from './mapTools.types';

export const TOOLS_AND_FEATURES_WITH_DATA_EXPORT = [
  MapFeature.Lasso,
  MapFeature.LocationFinder,
  MapFeature.ExportData,
  MapTool.Routing,
  MapTool.Proximity,
  MapTool.Boundary,
] as const;

export type ToolOrFeatureWithDataExport = typeof TOOLS_AND_FEATURES_WITH_DATA_EXPORT[number];
