import { css } from '@emotion/react';
import {
  faCheck,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { type ModalProps } from '../../../modal/modalType.enum';
import {
  LottieAnimationComponent,
  LottieAnimationTypes,
  useLottieAnimationDefaultColors,
} from '../../baseComponents/lottieAnimation';
import { getLottieAnimationDuration } from '../../baseComponents/lottieAnimation/lottieAnimation.helpers';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

const ANIMATION_SIZE = 40;

const centerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const contentWrapperStyle = css({
  padding: '17px 33px',
});

export type ChangesPendingModalProps = ModalProps<{
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
}>;

export const ChangesPendingModalComponent: React.FC<ChangesPendingModalProps> = props => {
  const [playSuccessAnimation, setPlaySuccessAnimation] = useState(false);
  const [playTrashAnimation, setPlayTrashAnimation] = useState(false);
  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors();

  const handleSave = useCallback(() => {
    const onSave = props.onSaveChanges;
    onSave();
    setPlaySuccessAnimation(true);
  }, [props.onSaveChanges]);

  const handleDiscardButton = useCallback(() => {
    const onDiscard = props.onDiscardChanges;
    onDiscard();
    setPlayTrashAnimation(true);
  }, [props.onDiscardChanges]);

  const modalBody = useMemo(() => {
    if (playSuccessAnimation) {
      return (
        <div css={centerStyle}>
          <LottieAnimationComponent
            colors={animationColors.Success}
            type={LottieAnimationTypes.Success}
            size={ANIMATION_SIZE}
            autoplay
          />
        </div>
      );
    }

    if (playTrashAnimation) {
      return (
        <div css={centerStyle}>
          <LottieAnimationComponent
            colors={animationColors.Delete}
            type={LottieAnimationTypes.Delete}
            size={ANIMATION_SIZE}
            autoplay
          />
        </div>
      );
    }

    return (
      <div>
        {t('ChangesPendingMessage')}
      </div>
    );
  }, [animationColors.Delete, animationColors.Success, playSuccessAnimation, playTrashAnimation, t]);

  useEffect(() => {
    if (!playSuccessAnimation && !playTrashAnimation) {
      return;
    }

    let timeoutId: number | null = null;
    const close = props.onClose;
    if (playSuccessAnimation) {
      const animationDuration = getLottieAnimationDuration(LottieAnimationTypes.Success);
      timeoutId = window.setTimeout(() => {
        setPlaySuccessAnimation(false);
        close();
      }, animationDuration);
    }
    else if (playTrashAnimation) {
      const animationDuration = getLottieAnimationDuration(LottieAnimationTypes.Delete);
      timeoutId = window.setTimeout(() => {
        setPlayTrashAnimation(false);
        close();
      }, animationDuration);
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [playSuccessAnimation, playTrashAnimation, props.onClose]);

  const disabled = playSuccessAnimation || playTrashAnimation;

  return (
    <ModalComponent
      caption={t('Changes Pending')}
      confirmButton={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Primary}
          isDisabled={disabled}
          onClick={handleSave}
          prefixIcon={faCheck}
          text={t('Save')}
        />
      )}
      contentStyle={contentWrapperStyle}
      isOpen={props.isOpen}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Danger}
          isDisabled={disabled}
          prefixIcon={faTrashCan}
          text={t('Trash Em')}
          onClick={handleDiscardButton}
        />
      )}
      onClose={props.onClose}
    >
      {modalBody}
    </ModalComponent>
  );
};
