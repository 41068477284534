import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { useTranslation } from '../../_shared/utils/hooks';

type DataTableBadLocationsProps = Readonly<{
  badLocationsCount: number;
  className?: string;
  onBadLocationsClick: () => void;
}>;

const wrapperStyle = ({ theme }: ThemeProps) => css({
  fontSize: 12,
  fontWeight: 400,
  color: theme.textColors.primary,
});

const buttonStyle = ({ theme }: ThemeProps) => css({
  fontSize: 12,
  fontWeight: 400,
  background: 'none',
  border: 'none',
  display: 'inline-block',
  color: theme.textColors.danger,
  '&:hover': {
    color: theme.textColors.dangerOnHover,
    cursor: 'pointer',
  },
});

export const DataTableBadLocationsComponent: FC<DataTableBadLocationsProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div
      css={wrapperStyle({ theme })}
      className={props.className}
    >
      <button
        css={buttonStyle({ theme })}
        onClick={props.onBadLocationsClick}
      >
        {t('{{count}} locations', { count: props.badLocationsCount })}
      </button>

      {t('could not be found using the Google API.')}
    </div>
  );
};
