import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  convertLatLngPathToGeometry, removeGeometryPolygonIntersections,
} from '~/_shared/types/polygon/polygon.utils';
import { flatten } from '~/_shared/utils/collections/collections';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import {
  boundaryDrawCloseModal, boundaryDrawEditOpenModal, boundaryDrawResetShapes,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { useBoundaryDrawEditActiveSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import {
  BoundaryEditAction, BoundaryEditManualMethod,
} from '../boundaryEditModal/boundaryEditModal.helpers';

export const useBoundaryDrawSubmitModal = () => {
  const dispatch = useDispatch();
  const { openModal: openBoundaryEditSubmitModal } = useModal(ModalType.BoundaryEditSubmit);
  const { openModal: openBoundaryCreateSubmitModal } = useModal(ModalType.BoundaryCreateSubmit);
  const drawState = useBoundaryDrawEditActiveSelector();
  const boundaryGroups = useBoundaryGroupsSelector();

  const openBoundaryDrawSubmitModal = useCallback(() => {
    if (!drawState) {
      return;
    }

    const polygonsWithoutIntersections = flatten(drawState.mapShapes.map(shape =>
      removeGeometryPolygonIntersections(convertLatLngPathToGeometry(shape.outlines)).features));

    const geometry: BoundaryCombineGeometryRequestItem[] = polygonsWithoutIntersections.map(polygon => ({
      type: 'geometry',
      value: polygon.geometry,
    }));

    if (drawState.drawModeData.action === BoundaryEditAction.CreateNewBoundary) {
      const editedBoundaryGroup = boundaryGroups.find(group => group.id === drawState.boundaryGroupId);

      if (!editedBoundaryGroup) {
        return;
      }

      openBoundaryCreateSubmitModal({
        geometry,
        editedBoundaryTerritoryGroupId: drawState.drawModeData.boundaryTerritoryGroupId,
        onCreate: (createAnother: boolean) => {
          if (createAnother) {
            dispatch(boundaryDrawResetShapes());
          }
          else {
            dispatch(boundaryDrawCloseModal());
          }
        },
      });
    }
    else {
      dispatch(boundaryDrawCloseModal());
      const isEditingSpecificBoundary = drawState.drawModeData.boundaryId !== undefined;

      openBoundaryEditSubmitModal({
        geometry,
        editedBoundaryTerritoryGroupId: drawState.drawModeData.boundaryTerritoryGroupId,
        selectedBoundaryId: drawState.drawModeData.boundaryId,
        isBoundarySelectDisabled: isEditingSpecificBoundary,
        hideEditAnother: isEditingSpecificBoundary,
        onSubmit: (editAnother: boolean) => {
          if (editAnother) {
            dispatch(boundaryDrawEditOpenModal({
              boundaryGroupId: drawState.boundaryGroupId,
              boundaryTerritoryGroupId: drawState.drawModeData.boundaryTerritoryGroupId,
              action: BoundaryEditAction.EditBoundaries,
            }));
          }
        },
        method: BoundaryEditManualMethod.Draw,
      });
    }
  }, [drawState, boundaryGroups, openBoundaryCreateSubmitModal, dispatch, openBoundaryEditSubmitModal]);

  return { openBoundaryDrawSubmitModal };
};
