import { type ColumnRole } from '../../types/columnRole.enum';
import { type LatLng } from '../../types/latLng';

type AddressPartToComponentKeys = Pick<typeof ColumnRole, 'City' | 'State' | 'PostCode' | 'Country'> & {
  County: string;
  Street: string;
  StreetNumber: string;
};

export type AddressPartComponentNames = Readonly<{
  [key in keyof AddressPartToComponentKeys]: string;
}>;

export const addressPartToComponentNames: AddressPartComponentNames = {
  City: 'locality',
  Country: 'country',
  County: 'administrative_area_level_2',
  PostCode: 'postal_code',
  State: 'administrative_area_level_1',
  Street: 'route',
  StreetNumber: 'street_number',
};

export type AddressComponents = Partial<AddressPartComponentNames>;

export type GeoLocation = {
  address: string;
  latLng: LatLng;
  addressComponents?: AddressComponents;
};

export const googleLatLngToLocal = (latLng: google.maps.LatLng): LatLng => ({
  lng: latLng.lng(),
  lat: latLng.lat(),
});

export const localLatLngToGoogle = (latLng: LatLng): google.maps.LatLng => new google.maps.LatLng(latLng);
