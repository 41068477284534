import {
  useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { boundaryGroupFilterChanged } from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import {
  boundaryRenderingStart,
  boundaryRenderingStop,
} from '~/store/frontendState/mapTools/boundary/boundaryRendering/boundaryRendering.actionCreators';
import { frontedStateProcessingBoundariesInitialized } from '~/store/frontendState/processing/processing.actionCreators';
import { useBoundaryFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { type WebglLayers } from '../../webgl/useWebGL';
import { MapBoundaryManager } from '../mapBoundary.manager';
import { useMapBoundaryPolygons } from './mapBoundaryPolygons/useMapBoundaryPolygons';
import { useMapBoundaryRemove } from './mapBoundaryRemove/useMapBoundaryRemove';
import { useMapBoundaryBaseStyles } from './mapBoundaryStyles/useMapBoundaryBaseStyles';
import { useMapBoundaryExtraStyles } from './mapBoundaryStyles/useMapBoundaryExtraStyles';
import { useMapBoundaryStyles } from './mapBoundaryStyles/useMapBoundaryStyles';
import { useMapBoundaryTerritoryStyles } from './mapBoundaryStyles/useMapBoundaryTerritoryStyles';
import { useMapBoundaryMouseEvents } from './useMapBoundaryMouseEvents';
import { useMapIsolateBoundaries } from './useMapIsolateBoundaries';

type UseBoundariesParams = {
  webglLayers: WebglLayers | undefined;
  map: google.maps.Map | undefined;
};

export const useMapBoundaries = ({ webglLayers, map }: UseBoundariesParams) => {
  const manager = useManager(webglLayers);

  const baseStyles = useMapBoundaryBaseStyles();
  const boundaryTerritoryStyles = useMapBoundaryTerritoryStyles(baseStyles);
  const boundaryExtraStyles = useMapBoundaryExtraStyles(baseStyles);

  // order matters => we want to compute styles before polygons
  // this way polygons already have proper visuals when inserted
  useMapBoundaryStyles({
    manager,
    baseStyles,
    boundaryTerritoryStyles,
    extra: boundaryExtraStyles,
  });
  useMapBoundaryPolygons(manager, map);
  useMapBoundaryMouseEvents(manager);
  useMapBoundaryRemove(manager);
  useMapIsolateBoundaries(manager);

  useEffect(() => {
    if (!manager) {
      return;
    }

    return () => {
      manager.dispose();
    };
  }, [manager]);

  useWatchForFilterChange();
};

const useManager = (webglLayers: WebglLayers | undefined) => {
  const dispatch = useDispatch();

  return useMemo(() => {
    if (!webglLayers) {
      return undefined;
    }

    const manager = new MapBoundaryManager(webglLayers);

    manager.eventEmitter.on('onBusy', () => dispatch(boundaryRenderingStart()));
    manager.eventEmitter.on('onIdle', () => dispatch(boundaryRenderingStop()));
    manager.eventEmitter.on('onFirstRender', () => dispatch(frontedStateProcessingBoundariesInitialized()));

    return manager;
  }, [dispatch, webglLayers]);
};

const useWatchForFilterChange = () => {
  const dispatch = useDispatch();
  const filters = useBoundaryFilters();
  const previousFilters = usePrevious(filters);

  useEffect(() => {
    if (!previousFilters) {
      return;
    }

    filters.entries.forEach(({ boundaryGroupId, filter }) => {
      const previousFilter = previousFilters.get(boundaryGroupId);

      if (filter !== previousFilter) {
        dispatch(boundaryGroupFilterChanged(boundaryGroupId, previousFilter, filter));
      }
    });
  }, [dispatch, filters, previousFilters]);
};
