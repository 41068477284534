import { type FC } from 'react';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
} from '../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { DrawingToolAddConfirmContainer } from './drawingToolAddConfirmContainer';

export const DrawingToolConfirmContainer: FC = () => {
  const drawingToolMode = useDrawingToolModeSelector();

  if (drawingToolMode !== DrawingToolMode.Inactive) {
    return <DrawingToolAddConfirmContainer />;
  }

  return null;
};
