import { type FC } from 'react';
import { useTranslation } from '../../_shared/utils/hooks';
import { getHomeNavigationItem } from '../../topBar/navigation/navigation.helpers';
import { MobileNavigationComponent } from './mobileNavigation.component';
import { useActiveMobileNavigationItem } from './useActiveMobileNavigationItem';

export const MobileNavigationContainer: FC = () => {
  const activeNavigationItem = useActiveMobileNavigationItem();
  const [t] = useTranslation();

  return (
    <MobileNavigationComponent
      activeNavigationItem={activeNavigationItem}
      homePageNavigationItem={getHomeNavigationItem(t)}
    />
  );
};
