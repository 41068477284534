import { combineReducers } from 'redux';
import {
  drawingArrowSettingsInitialState,
  drawingArrowSettingsReducer,
} from './arrow/drawingArrowSettings.reducer';
import {
  drawingCalloutSettingsInitialState,
  drawingCalloutSettingsReducer,
} from './callout/drawingCalloutSettings.reducer';
import {
  drawingCircleSettingsInitialState,
  drawingCircleSettingsReducer,
} from './circle/drawingCircleSettings.reducer';
import { type DrawingSettingsState } from './drawingSettings.state';
import {
  drawingIconMarkerSettingsInitialState,
  drawingIconMarkerSettingsReducer,
} from './iconMarker/drawingIconMarkerSettings.reducer';
import {
  drawingImageSettingsInitialState,
  drawingImageSettingsReducer,
} from './image/drawingImageSettings.reducer';
import {
  drawingLabelSettingsInitialState,
  drawingLabelSettingsReducer,
} from './label/drawingLabelSettings.reducer';
import {
  drawingNumberSettingsInitialState, drawingNumberSettingsReducer,
} from './number/drawingNumberSettings.reducer';
import {
  drawingPolygonSettingsInitialState,
  drawingPolygonSettingsReducer,
} from './polygon/drawingPolygonSettings.reducer';
import {
  drawingPolylineSettingsInitialState,
  drawingPolylineSettingsReducer,
} from './polyline/drawingPolylineSettings.reducer';
import {
  drawingRectangleSettingsInitialState,
  drawingRectangleSettingsReducer,
} from './rectangle/drawingRectangleSettings.reducer';
import {
  drawingTextSettingsInitialState,
  drawingTextSettingsReducer,
} from './text/drawingTextSettings.reducer';

export const drawingSettingsStateInitialState: DrawingSettingsState = {
  arrow: drawingArrowSettingsInitialState,
  callout: drawingCalloutSettingsInitialState,
  circle: drawingCircleSettingsInitialState,
  iconMarker: drawingIconMarkerSettingsInitialState,
  image: drawingImageSettingsInitialState,
  label: drawingLabelSettingsInitialState,
  number: drawingNumberSettingsInitialState,
  polygon: drawingPolygonSettingsInitialState,
  polyline: drawingPolylineSettingsInitialState,
  rectangle: drawingRectangleSettingsInitialState,
  text: drawingTextSettingsInitialState,
};

export const drawingSettingsReducer = combineReducers({
  arrow: drawingArrowSettingsReducer,
  callout: drawingCalloutSettingsReducer,
  circle: drawingCircleSettingsReducer,
  iconMarker: drawingIconMarkerSettingsReducer,
  image: drawingImageSettingsReducer,
  label: drawingLabelSettingsReducer,
  number: drawingNumberSettingsReducer,
  polygon: drawingPolygonSettingsReducer,
  polyline: drawingPolylineSettingsReducer,
  rectangle: drawingRectangleSettingsReducer,
  text: drawingTextSettingsReducer,
}) satisfies (...args: unknown[]) => DrawingSettingsState;
