import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { type MapSettingsDataAction } from '~/store/mapSettings/data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '~/store/mapSettings/data/mapSettingsData.actionTypes';
import { type DrawingItemsAction } from './drawingItems.action';
import {
  MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM,
  MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM,
} from './drawingItems.actionTypes';
import { type DrawingItemsState } from './drawingItems.state';

export const drawingItemsInitialState: DrawingItemsState = [];

export const drawingItemsReducer = (
  state = drawingItemsInitialState, action: DrawingItemsAction | MapSettingsDataAction
): DrawingItemsState => {
  switch (action.type) {
    case MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS: {
      return [];
    }

    case MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE: {
      return state.filter(item => item.type !== action.payload.type);
    }

    case MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM: {
      return state.filter(item => item.value.id !== action.payload.id);
    }

    case MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM: {
      const newItems = [...state];
      newItems.push(action.payload.drawingItem);

      return newItems;
    }

    case MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM: {
      const itemIndex = state.findIndex(item => item.value.id === action.payload.drawingItem.value.id);

      if (itemIndex === -1) {
        return state;
      }

      const newItems = [...state];
      newItems[itemIndex] = action.payload.drawingItem;

      return newItems;
    }

    case MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS: {
      return state.filter((item) => item.type !== DrawingTool.Image || !action.payload.attachmentIds.includes(item.value.attachmentId));
    }

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS: {
      return drawingItemsInitialState;
    }

    default: {
      return state;
    }
  }
};
