import { type TranslationFnc } from '~/_shared/utils/hooks';
import { type ValidateTerritoryConstraintResultItem } from './constraints.repository';

export const getConstraintValidationErrorMessages = (vtcri: ValidateTerritoryConstraintResultItem, t: TranslationFnc): string[] => {
  const errors = [];

  if (vtcri && !vtcri.isValid) {
    if (vtcri.limits.lowestMax !== null && vtcri.limits.lowestMax > vtcri.value.max) {
      errors.push(t('boundaryAI.Constraints.Error.MaximumLowerThan{{LowestMaximum}}',
        { LowestMaximum: vtcri.limits.lowestMax }));
    }
    if (vtcri.limits.highestMin !== null && vtcri.limits.highestMin < vtcri.value.min) {
      errors.push(t('boundaryAI.Constraints.Error.MinimumHigherThan{{HighestMinimum}}',
        { LowestMaximum: vtcri.limits.lowestMax }));
    }
  }

  return errors;
};
