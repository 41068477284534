export enum UserFieldName {
  first_name = 'first_name',
  code = 'code',
  email = 'email',
  last_name = 'last_name',
  mfa = 'mfa',
  old_password = 'old_password',
  password = 'password',
  phone_number = 'phone_number',
}

export enum UserMessage {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  FORBIDDEN = 'FORBIDDEN',
}
