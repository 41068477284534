import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import {
  s, Trans,
} from '../../../../../translations/Trans';
import {
  anyDelete,
  anyDisconnect,
  calculateDeleteCount,
  calculateDisconnectCount, type HandleBaseMapDeletionModalHelper,
} from './handleBaseMapDeletionModal.helper';

type HandleBaseMapDeletionConfirmTextProps = Readonly<{
  selectedResolutions: HandleBaseMapDeletionModalHelper;
}>;

const confirmationInformationStyle = css({
  padding: '25px 24px 15px',
});

const confirmationBasicInformationStyle = css({
  fontSize: '18px',
});

const disconnectConfirmationStyle = ({ theme }: ThemeProps) => css({
  fontSize: '14px',
  marginTop: 25,
  color: theme.alertColors.warningPrimary,
});

const deleteConfirmationStyle = ({ theme }: ThemeProps) => css({
  fontSize: '14px',
  color: theme.alertColors.dangerPrimary,
  marginTop: 14,
});

export const HandleBaseMapDeletionConfirmTextComponent: FC<HandleBaseMapDeletionConfirmTextProps> = ({ selectedResolutions }) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const disconnectedCount = calculateDisconnectCount(selectedResolutions);
  const deletedCount = calculateDeleteCount(selectedResolutions);

  return (
    <div css={confirmationInformationStyle}>
      <div css={confirmationBasicInformationStyle}>{t('Are you sure you want to proceed?')}</div>
      {anyDisconnect(selectedResolutions) && (
        <div css={disconnectConfirmationStyle({ theme })}>
          <Trans
            i18nKey="You are going to <1>disconnect</1> {{count}} layered maps. This means the layered maps will look the same, but the source of the markers will be different."
            count={disconnectedCount}
          >
            {s('You are going to ')}
            <strong>disconnect</strong>
            {s(' {{count}} layered maps. This means the layered maps will look the same, but the source of the markers will be different.')}
          </Trans>
        </div>
      )}

      {anyDelete(selectedResolutions) && (
        <div css={deleteConfirmationStyle({ theme })}>
          <Trans
            i18nKey="You are going to <1>delete</1> {{count}} layered maps. The layered maps will get deleted but no data is lost since they are still available in the single base maps."
            count={deletedCount}
          >
            {s('You are going to ')}
            <strong>delete</strong>
            {s(' {{count}} layered maps. The layered maps will get deleted but no data is lost since they are still available in the single base maps.')}
          </Trans>
        </div>
      )}
    </div>
  );
};
