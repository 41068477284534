import { useEffect } from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { type AutoClosableModalProps } from '~/modal/modalType.enum';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';

export const useAutoClosableModal = (props: AutoClosableModalProps) => {
  const closeModal = props.onClose;
  const currentPage = useGetCurrentPageInfo().page;
  const currentMapId = useMapIdSelector();
  const prevMapId = usePrevious(currentMapId);

  useEffect(() => {
    if (props.allowedPages && !props.allowedPages.has(currentPage)) {
      closeModal();
    }
  }, [closeModal, currentPage, props.allowedPages, props.isOpen]);

  useEffect(() => {
    if (!props.skipCloseOnMapIdChange && prevMapId !== undefined && prevMapId !== currentMapId) {
      closeModal();
    }
  }, [closeModal, currentMapId, prevMapId, props.skipCloseOnMapIdChange]);
};
