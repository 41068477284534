import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.DoubleDownArrow]: {
  views: {
    default: {
      canvasHeight: 568,
      canvasWidth: 568,
      iconWidth: 146,
      iconHeight: 226,
      offsetTop: 158,
      offsetLeft: 215,
    },
  },
  colors: {
    primary: '#FE6600',
    secondary: '#D8D8D8',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 151] as const,
    arrows: [35, 151] as const,
    noFade: [0, 120] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/120567-down-arrow" */
      './120567-down-arrow.json'
    ),
} };

export const { DoubleDownArrow } = config;
