import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '~/_shared/utils/hooks';

export enum LoadCustomBoundaryFillType {
  DefaultFill = 'DefaultFill',
  NoTerritoryFill = 'NoTerritoryFill',
  RandomizedFill = 'RandomizedFill',
  FillBasedOnData = 'FillBasedOnData',
}

export const getBoundaryFillTypeOptions = (t: TranslationFnc): ReadonlyArray<DropdownOption<LoadCustomBoundaryFillType>> => {
  return [{
    name: t('Default Fill'),
    value: LoadCustomBoundaryFillType.DefaultFill,
  }, {
    name: t('No Territory Fill'),
    value: LoadCustomBoundaryFillType.NoTerritoryFill,
  }, {
    name: t('Randomized Fill'),
    value: LoadCustomBoundaryFillType.RandomizedFill,
  }, {
    name: t('Fill Based on Data'),
    value: LoadCustomBoundaryFillType.FillBasedOnData,
  }];
};
