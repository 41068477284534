import * as geometry from 'spherical-geometry-js';
import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { type LatLng } from '~/_shared/types/latLng';
import {
  convertGeometryToMultiPolygon,
  convertLatLngPathToGeometry, removeGeometryPolygonIntersections,
} from '~/_shared/types/polygon/polygon.utils';
import { getUnitSymbols } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { getDistanceLabel } from '~/directions/listing/directionsListing.helpers';
import { type DistanceCalculatorOutline } from '~/store/mapSettings/toolsState/distanceCalculator/mapSettingsDistanceCalculator.state';

const SQUARE_SIGN = '²';
const METERS_IN_MILE = 1_609.344;
const SQUARE_METERS_IN_SQUARE_MILE = METERS_IN_MILE * METERS_IN_MILE;
const FEET_IN_MILE = 5_280;
const SQUARE_FEET_IN_SQUARE_MILE = FEET_IN_MILE * FEET_IN_MILE;
const SQUARE_METERS_IN_SQUARE_KILOMETER = 1000 * 1000;

export const getAreaText = (areaInMSq: number, unitSystem: UnitSystem) => {
  const units = getUnitSymbols(unitSystem);
  if (unitSystem === UnitSystem.imperial) {
    const sqMiles = areaInMSq / SQUARE_METERS_IN_SQUARE_MILE;
    const sqFeet = sqMiles * SQUARE_FEET_IN_SQUARE_MILE;
    if (sqFeet < 10_000) {
      return `${sqFeet.toFixed(0)} ${units.subunit}${SQUARE_SIGN}`;
    }
    if (sqFeet < 1_000_000) {
      return `${(sqFeet / 1000).toFixed(2)}k ${units.subunit}${SQUARE_SIGN}`;
    }
    else {
      return `${sqMiles.toFixed(2)} ${units.unit}${SQUARE_SIGN}`;
    }
  }
  else {
    if (areaInMSq < 1000) {
      return `${areaInMSq.toFixed(0)} ${units.subunit}${SQUARE_SIGN}`;
    }
    if (areaInMSq < SQUARE_METERS_IN_SQUARE_KILOMETER) {
      return `${(areaInMSq / 1000).toFixed(2)}k ${units.subunit}${SQUARE_SIGN}`;
    }

    const km = areaInMSq / SQUARE_METERS_IN_SQUARE_KILOMETER; // Convert square meters to square kilometers
    return `${km.toFixed(2)} ${units.unit}${SQUARE_SIGN}`;
  }
};

export const calculateDistances = (unitSystem: UnitSystem, outlines: DistanceCalculatorOutline[], cursorPosition?: LatLng) => {
  let distance = 0;
  const points = cursorPosition ? outlines.concat({ ...cursorPosition, id: 'cursor' }) : outlines;

  return points.map((point, index) => {
    const previousPoint = points[index - 1];
    distance = (!previousPoint || (index === 0)) ? 0 : distance + geometry.computeDistanceBetween(point, previousPoint);

    return {
      outlineId: point.id,
      distance: getDistanceLabel(distance, unitSystem),
    };
  });
};

export const calculatePolygonDistance = (unitSystem: UnitSystem, outlines: DistanceCalculatorOutline[]) => {
  const distance = outlines.reduce((total, outline, index) => {
    const previousOutline = outlines[index - 1];
    return total + geometry.computeDistanceBetween(outline, (index && previousOutline) ? previousOutline : outlines.lastItem);
  }, 0);

  return getDistanceLabel(distance, unitSystem);
};

export const calculateArea = (unitSystem: UnitSystem, outlines: LatLng[]) => {
  const separatePolygons = removeGeometryPolygonIntersections(convertLatLngPathToGeometry(outlines));
  const areaMultiPolygon = convertGeometryToMultiPolygon(separatePolygons);

  const area = areaMultiPolygon
    .map(polygon => {
      return geometry.computeArea(polygon.path);
    })
    .reduce((total, area) => total + area, 0);

  return getAreaText(area, unitSystem);
};
