import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemArrow } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolArrowInstanceArrowContainer } from './drawingToolArrowInstanceArrow.container';

type MapArrowInstanceProps = {
  instance: DrawingItemArrow;
};

const DrawingToolArrowInstanceContainer: FC<MapArrowInstanceProps> = ({
  instance,
}) => {
  const { onMouseOver, onMouseOut, onRightClick } = useDrawingToolItemMouseEvents();
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dispatch = useDispatch();

  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const onArrowClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Arrow,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  const onArrowRightClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Arrow,
      value: instance,
    });
  }, [onRightClick, instance, drawingEventsEnabledRef]);

  return (
    <DrawingToolArrowInstanceArrowContainer
      instance={instance}
      zIndex={zIndex}
      onClick={onArrowClick}
      onRightClick={onArrowRightClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};

const pureComponent = memo(DrawingToolArrowInstanceContainer);
export { pureComponent as DrawingToolArrowInstanceContainer };
