export class StrictBroadcastChannel<MessageType extends Record<string, any>> extends BroadcastChannel {
  public postMessage(message: MessageType): void {
    return super.postMessage(message);
  }

  public addEventListener(type: 'message', listener: (this: BroadcastChannel, ev: MessageEvent<MessageType>) => any, options?: boolean | AddEventListenerOptions | undefined): void;
  public addEventListener<K extends keyof BroadcastChannelEventMap>(type: K, listener: (this: BroadcastChannel, ev: BroadcastChannelEventMap[K]) => any, options?: boolean | AddEventListenerOptions | undefined): void {
    return super.addEventListener(type, listener, options);
  }
}
