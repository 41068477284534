import { css } from '@emotion/react';
import { type FC } from 'react';
import { ImageComponent } from '../image/image.component';

const notFoundImageStyle = css({
  width: 80,
  flexShrink: 0,
});

export type ImageGalleryImageComponentProps = Readonly<{
  // Image gallery items are rendered as list/array thus key property is always required
  key: string;
  src: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
}>;

export const ImageGalleryImageComponent: FC<ImageGalleryImageComponentProps> = (props) => {
  return (
    <ImageComponent
      className={props.className}
      notFoundImageStyle={notFoundImageStyle}
      onClick={props.onClick}
      src={props.src}
    />
  );
};
