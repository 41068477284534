const BASE_PATH = '/maps';

const MAP_PATH = ':mapShareId';
const EXPIRED_PATH = 'expired';

export const PublicMapPaths = {
  Base: `${BASE_PATH}/*`,
  Map: MAP_PATH,
  Expired: EXPIRED_PATH,
} as const;

const _PUBLIC_MAP_ABSOLUTE_ROUTE = `${BASE_PATH}/${MAP_PATH}` as const;

export type PublicMapRoute = typeof _PUBLIC_MAP_ABSOLUTE_ROUTE;
