import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_THEME } from '~/_shared/themes/themeType';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import { useSnapshotSettingsOverrides } from '~/sidebar/sidebarApps/saveMapView/useSnapshotSettingsOverrides.hook';
import {
  useActiveMarkerIdSelector, useIsLocationListOpen,
} from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import {
  exportScreenShot, getScreenShotExportStatus,
} from '~/store/frontendState/imageExport/imageExport.repository';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { frontendStateProcessingExportImageExportInProgress } from '~/store/frontendState/processing/processing.actionCreators';
import { useIsImageExportInProgressSelector } from '~/store/frontendState/processing/processing.selectors';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { getPublicMapSettingsInitialState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.reducer';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { type MapSettingsToolsState } from '~/store/mapSettings/toolsState/mapSettingsToolsState';
import { useMapSettingsToolsStateSelector } from '~/store/mapSettings/toolsState/mapSettingsToolsState.selectors';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useCurrentClientUserSettingsSelector } from '~/store/userData/userData.selectors';
import {
  ExportImageMode, type ExportImageParams,
} from '../../types/exportImage/exportImage';
import {
  EXPORT_LEGEND_COMPONENT_ID, EXPORT_LOCATION_LIST_COMPONENT_ID,
} from '../../types/exportImage/exportImage.constants';
import { useTranslation } from '../../utils/hooks';
import { useSelector } from '../../utils/hooks/useSelector';
import {
  getImageResolution, imageTypeToExportImageExtension,
} from './exportImage.helpers';

export const useExportImage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useIsImageExportInProgressSelector();
  const mapInfo = useMapInfoDataSelector();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds;
  const currentToolsState = useMapSettingsToolsStateSelector();
  const activeMarker = useActiveMarkerIdSelector();
  const isLocationListOpen = useIsLocationListOpen();
  const userSettings = useCurrentClientUserSettingsSelector();
  const leftSidebarState = useSelector(s => s.frontendState.leftSidebar);
  const { getSnapshotSettingsOverrides } = useSnapshotSettingsOverrides();

  const showError = useCallback(() => {
    dispatch(frontendStateProcessingExportImageExportInProgress(false));
    dispatch(createAppError({
      type: AppErrorType.General,
      title: t('Error Exporting an Image'),
      text: t('We were unable to export your image. Please try again. If this problem persists, please contact the customer support'),
    }));
  }, [dispatch, t]);

  const submitExportImageRequest = (params: ExportImageParams) => {
    if (!mapInfo || !currentToolsState || !lastBounds) {
      return;
    }
    dispatch(frontendStateProcessingExportImageExportInProgress(true));

    const shareId: string = mapInfo.shareId;
    const newToolsState: MapSettingsToolsState = {
      ...currentToolsState,
      exportImageSettings: {
        mode: params.exportMode,
        legendMode: params.exportMode === ExportImageMode.Legend || params.exportMode === ExportImageMode.MapAndLegend
          ? params.exportLegendMode : null,
        locationListMode: params.exportMode === ExportImageMode.LocationList ? params.exportLocationListMode : null,
        activeMarker: activeMarker ?? null,
        sidebarTool: (params.exportMode === ExportImageMode.Screenshot
          && leftSidebarState.openedApp === SidebarApp.MapTools && leftSidebarState.openedMapTool
        ) ? leftSidebarState.openedMapTool : null,
        isLocationListOpen: params.exportMode === ExportImageMode.Screenshot ? isLocationListOpen : false,
      },
    };

    const newPublicSettings: MapSettingsPublicMapSettingsState = {
      ...getPublicMapSettingsInitialState(),
      theme: userSettings?.theme ?? DEFAULT_THEME,
    };

    let exportSectionId = undefined;
    if (params.exportMode === ExportImageMode.LocationList) {
      exportSectionId = EXPORT_LOCATION_LIST_COMPONENT_ID;
    }
    else if (params.exportMode === ExportImageMode.Legend) {
      exportSectionId = EXPORT_LEGEND_COMPONENT_ID;
    }
    exportScreenShot(shareId, {
      extension: imageTypeToExportImageExtension(params.imageFileType),
      double_pixel_ratio: true,
      section: exportSectionId,
      resolution: getImageResolution(params.imageSize),
      settings_override: getSnapshotSettingsOverrides(newToolsState, lastBounds, newPublicSettings),
    }).then((screenShotResponse) => {
      getExportStatusWithDelay(shareId, screenShotResponse.hash);
    }).catch(() => {
      showError();
    });
  };

  const getExportStatusWithDelay = (shareId: string, hash: string) => {
    setTimeout(() => checkExportStatus(shareId, hash), 5000);
  };

  const checkExportStatus = (shareId: string, hash: string) => {
    getScreenShotExportStatus(shareId, hash)
      .then((response) => {
        if (response.data) {
          dispatch(frontendStateProcessingExportImageExportInProgress(false));
          window.location.href = response.data.path;
        }
        else if (response.message === 'Failed!') {
          showError();
        }
        else {
          getExportStatusWithDelay(shareId, hash);
        }
      })
      .catch(() => {
        showError();
      });
  };

  return {
    isLoading,
    exportImage: submitExportImageRequest,
  };
};
