import {
  type FC,
  useMemo,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { MapBasicInfoComponent } from '../basicInfo/mapBasicInfo.component';
import { LayeredMapSectionHolderComponent } from './layeredMapSectionHolderComponent';

type LayeredMapNameAndDescriptionComponentProps = Readonly<{
  isContinueEnabled: boolean;
  mapDescription: string;
  mapName: string;

  onContinue: () => void;
  setMapDescription: (isContinueEnabled: string) => void;
  setMapName: (mapName: string) => void;
  setMapNameInputRef: (inputRef: HTMLInputElement) => void;
}>;

export const LayeredMapNameAndDescriptionComponent: FC<LayeredMapNameAndDescriptionComponentProps> = (props) => {
  const [t] = useTranslation();

  const buttonProps = useMemo(() => ({
    onClick: props.onContinue,
    isDisabled: !props.isContinueEnabled,
  }), [props.isContinueEnabled, props.onContinue]);

  return (
    <LayeredMapSectionHolderComponent
      buttonProps={buttonProps}
    >
      <MapBasicInfoComponent
        customLabel={t('Map Name')}
        description={props.mapDescription}
        isMapLayered
        isSnapshot={false}
        name={props.mapName}
        namePlaceholder={t('Map Name')}
        onDescriptionChange={props.setMapDescription}
        onNameChange={props.setMapName}
        setMapNameInputRef={props.setMapNameInputRef}
      />
    </LayeredMapSectionHolderComponent>
  );
};
