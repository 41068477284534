import { type HeatMapItem } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { PermanentConfirmStrategy } from '../../../_shared/components/modal/confirmation/useConfirmationModal';
import { type FilterAction } from '../../../_shared/types/filter/filter';
import {
  isDriveTimePolygon,
  isGroupRadius,
  isIndividualRadius,
  type Proximity,
} from '../../../_shared/types/proximity/proximity.types';
import { type SpreadsheetColumn } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type TranslationFnc } from '../../../_shared/utils/hooks';
import { getShortUnitSystemLabel } from '../../../_shared/utils/unitSystem/unitSystem.helpers';
import { getDefaultOrCustomRouteName } from '../../../directions/panel/directionsPanel.helpers';
import { getHeatMapItemName } from '../../../heatMap/listing/heatMapListing.helpers';
import { getDriveTimePolygonShortLabel } from '../../../proximity/proximity.helpers';
import { createTextPredicateFromFilterAction } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/textFilter/textFilter.factory';
import { renderTextFilterPredicate } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/textFilter/textFilterPredicate.enum';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const createMapStatusBarItemFromGroupingColumn = ({ columnName, hierarchyIndicator, totalGroupingColumns, t, onRemove, onClick }: {
  columnName: string;
  hierarchyIndicator: number;
  totalGroupingColumns: number;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  const isPrimaryOrSecondarySuffix = totalGroupingColumns > 1
    ? ' (' + (hierarchyIndicator === 0 ? t('Primary Group') : t('Secondary Group')) + ')'
    : '';
  return {
    label: t('Grouping Column: {{columnName}}', {
      columnName,
    }) + isPrimaryOrSecondarySuffix,
    removeStrategy: null,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromActiveGroupingColumnFilter = ({ groupName, t, onRemove, onClick }: {
  groupName: string;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  return {
    label: t('Selected Group: {{groupName}}', {
      groupName,
    }),
    removeStrategy: PermanentConfirmStrategy.Session,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromGroupFilter = ({ columnName, selectedGroupName, t, onRemove, onClick }: {
  columnName: string;
  selectedGroupName: string;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  return {
    label: t('Selected Filter: {{columnName}}>{{selectedGroupName}}', {
      selectedGroupName,
      columnName,
    }),
    removeStrategy: PermanentConfirmStrategy.Session,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromNumberFilter = ({ columnName, selectedGroupName, t, onRemove, onClick }: {
  columnName: string;
  selectedGroupName: string;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  return {
    label: t('Selected Filter: {{columnName}}>{{selectedGroupName}}', {
      selectedGroupName,
      columnName,
    }),
    removeStrategy: PermanentConfirmStrategy.Session,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromTextFilter = ({ columnName, filterAction, text, t, onRemove, onClick }: {
  columnName: string;
  filterAction: FilterAction;
  text: string | null;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  const textPredicate = createTextPredicateFromFilterAction(filterAction);

  const translatedFilterAction = renderTextFilterPredicate(textPredicate, t);
  let label;

  if (text) {
    label = t('Selected Filter: {{columnName}}>{{filterAction}}>{{text}}', {
      columnName,
      filterAction: translatedFilterAction,
      text,
    });
  }
  else {
    label = t('Selected Filter: {{columnName}}>{{filterAction}}', {
      columnName,
      filterAction: translatedFilterAction,
      text,
    });
  }

  return {
    label,
    removeStrategy: PermanentConfirmStrategy.Session,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarFromProximity = ({ proximity, isProximityVisible, index, t, onRemove, onVisibilityChange, onClick }: {
  proximity: Proximity;
  isProximityVisible: boolean;
  index: number;
  t: TranslationFnc;
  onRemove: () => void;
  onVisibilityChange:
  (visibility: boolean) => void;
  onClick: () => void;
}): MapStatusBarItem => {
  let label = '';

  if (isDriveTimePolygon(proximity)) {
    label = t('Proximity: {{index}}. {{name}}, {{time}}', {
      index,
      name: proximity.name,
      time: getDriveTimePolygonShortLabel(proximity.data.hours, proximity.data.minutes),
    });
  }

  if (isGroupRadius(proximity) || isIndividualRadius(proximity)) {
    label = t('Proximity: {{index}}. {{name}}, {{distance}}{{unit}}', {
      index,
      name: isGroupRadius(proximity) ? `${t('Group').toUpperCase()}: ${proximity.name}` : proximity.name,
      distance: proximity.data.radius,
      unit: getShortUnitSystemLabel(proximity.data.unit, t),
    });
  }

  return {
    label,
    isVisible: isProximityVisible,
    removeStrategy: PermanentConfirmStrategy.Local,
    onVisibilityChange,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromRoute = ({ routeName, t, onRemove, onClick }: {
  routeName: string;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
}): MapStatusBarItem => {
  return {
    label: getDefaultOrCustomRouteName(routeName, t),
    removeStrategy: PermanentConfirmStrategy.Local,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromHeatMap = ({ item, columns, t, onRemove, onVisibilityChange, onClick }: {
  item: HeatMapItem;
  columns: SpreadsheetColumn[];
  t: TranslationFnc;
  onRemove: () => void;
  onVisibilityChange: (visibility: boolean) => void;
  onClick: () => void;
}): MapStatusBarItem => {
  let heatMapItemName = getHeatMapItemName(item.heatmap, columns, t);
  if (heatMapItemName) {
    heatMapItemName = heatMapItemName.replace(':', ' >');
  }

  heatMapItemName = t('Heat Map: {{heatMapName}}', {
    heatMapName: heatMapItemName,
  });

  return {
    label: heatMapItemName,
    isVisible: item.otherOptions.visible,
    removeStrategy: PermanentConfirmStrategy.Local,
    onVisibilityChange,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemForHeatMapMarkerFilter = ({ item, columns, t, onRemove, onClick }: {
  item: HeatMapItem;
  columns: SpreadsheetColumn[];
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
},
): MapStatusBarItem => {
  let heatMapItemName = getHeatMapItemName(item.heatmap, columns, t);
  if (heatMapItemName) {
    heatMapItemName = heatMapItemName.replace(':', ' >');
  }

  heatMapItemName = t('Show Markers For Heat Map: {{heatMapName}}', {
    heatMapName: heatMapItemName,
  });

  return {
    label: heatMapItemName,
    removeStrategy: PermanentConfirmStrategy.Local,
    onRemove,
    onClick,
  };
};

export const createMapStatusBarItemFromBoundaryTerritoryGroupName = ({ territoryGroupName, isCustom, t, isVisible, onShowAllChange, onRemove, onClick }: {
  territoryGroupName: string;
  isCustom: boolean;
  t: TranslationFnc;
  isVisible: boolean;
  onShowAllChange: (showAll: boolean) => void;
  onRemove: () => void;
  onClick: () => void; }
): MapStatusBarItem => {
  let label;
  if (isCustom) {
    label = t('Territory Group: {{territoryGroupName}}', { territoryGroupName });
  }
  else {
    label = t('Boundary: {{boundaryName}}', {
      boundaryName: territoryGroupName,
    });
  }

  return {
    label,
    onRemove,
    isVisible,
    onVisibilityChange: onShowAllChange,
    removeStrategy: PermanentConfirmStrategy.Session,
    onClick,
  };
};

export const createMapStatusBarItemForIsolatePolygons = ({ name, t, onRemove, onClick }: {
  name: string;
  t: TranslationFnc;
  onRemove: () => void;
  onClick: () => void;
}): MapStatusBarItem => {
  return {
    label: t('Isolated: {{name}}', { name }),
    removeStrategy: PermanentConfirmStrategy.Local,
    onRemove,
    onClick,
  };
};
