import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { isArray } from '~/_shared/utils/collections/collections';
import { useModalIsOpenSelector } from '~/store/modal/modal.selectors';

export enum KeyboardKeys {
  Backspace = 'Backspace',
  Delete = 'Delete',
  Enter = 'Enter',
  Escape = 'Escape',
}

export const ERASURE_KEYS = [KeyboardKeys.Backspace, KeyboardKeys.Delete];

type UseKeyPressProps = Readonly<{
  targetKeys: KeyboardKeys | ReadonlyArray<KeyboardKeys>;
  options?: { allowInputs?: boolean; allowActionWhenModalOpen?: boolean };
  callbacks?: {
    onKeyPress?: (key: string) => void;
    onKeyRelease?: (key: string) => void;
  };
}>;

export const useKeyPress = ({ targetKeys, options, callbacks }: UseKeyPressProps) => {
  const isModalOpen = useModalIsOpenSelector();

  const pressedKey = useRef<string | null>(null);

  const onKeyPressRef = useRef(callbacks?.onKeyPress);
  const onKeyReleaseRef = useRef(callbacks?.onKeyRelease);
  onKeyPressRef.current = callbacks?.onKeyPress;
  onKeyReleaseRef.current = callbacks?.onKeyRelease;

  const targetKeysArray = useMemo(() =>
    isArray(targetKeys) ? targetKeys : [targetKeys],
  [targetKeys]);

  const targetKeysRef = useRef(targetKeysArray);
  targetKeysRef.current = targetKeysArray;

  const isTargetKeyPressValid = useCallback((targetKey: KeyboardKeys, key: string, target: EventTarget | null) =>
    key === targetKey
    && (options?.allowInputs || isNonTextOrInputElement(target))
    && (!isModalOpen || options?.allowActionWhenModalOpen),
  [options?.allowInputs, options?.allowActionWhenModalOpen, isModalOpen]);

  const downHandler = useCallback(({ key, target }: { key: string; target: EventTarget | null }) => {
    const isSomePressed = targetKeysRef.current.some(tk => isTargetKeyPressValid(tk, key, target));
    if (isSomePressed) {
      pressedKey.current = key;
      onKeyPressRef.current?.(key);
    }
  }, [isTargetKeyPressValid]);

  const upHandler = useCallback(({ key }: { key: string }) => {
    if (targetKeysRef.current.some(tk => key === tk)) {
      pressedKey.current = null;
      onKeyReleaseRef.current?.(key);
    }
  }, []);

  const blurHandler = useCallback(() => {
    if (pressedKey.current) {
      upHandler({ key: pressedKey.current });
    }
  }, [upHandler]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    window.addEventListener('blur', blurHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, [blurHandler, downHandler, upHandler]);
};

const isNonTextOrInputElement = (target: EventTarget | null) =>
  (target as HTMLElement)?.nodeName !== 'INPUT'
  && (target as HTMLElement)?.nodeName !== 'TEXTAREA';
