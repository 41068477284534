import { useEffect } from 'react';
import { MarkerColor } from '../../_shared/types/marker.types';
import { useMapComponentLastBoundsSelector } from '../../store/frontendState/mapComponent/mapComponent.selectors';
import { useMapContext } from './mapContext';

export const useDebugBounds = ({ enabled }: { enabled: boolean}) => {
  const { webglLayers } = useMapContext();
  const bounds = useMapComponentLastBoundsSelector()?.bounds;

  useEffect(() => {
    if (enabled && webglLayers && bounds) {
      webglLayers.MapObjectPolygons.add(new WebGLOverlay.Polygon({
        fillColor: MarkerColor.Red,
        opacity: 1,
      }, [[[
        bounds.getNorthEast(),
        { lat: bounds.getNorthEast().lat, lng: bounds.getSouthWest().lng },
        bounds.getSouthWest(),
        { lat: bounds.getSouthWest().lat, lng: bounds.getNorthEast().lng },
      ]]]));
    }
  }, [bounds, enabled, webglLayers]);
};
