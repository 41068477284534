import {
  type LottieAnimationConfig,
  type LottieAnimationSegment, type LottieAnimationViewDetails,
} from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

export const SvgActiveMarkerIndicators = {
  basicCircleArrow: 'basicCircleArrow',
  chevronArrow: 'chevronArrow',
  fatCircleArrow: 'fatCircleArrow',
  neonArrow: 'neonArrow',
  radarCircle: 'radarCircle',
  simpleArrow: 'simpleArrow',
  slimCircleArrow: 'slimCircleArrow',
  tripleNeonArrow: 'tripleNeonArrow',
} as const;

export const LottieActiveMarkerIndicators = {
  lottieChevron: 'lottieChevron',
  lottieChevronBothDirections: 'lottieChevronBothDirections',
  lottieCaretDown: 'lottieCaretDown',
  lottieCaretDownShort: 'lottieCaretDownShort',
  lottieDoubleDownArrow: 'lottieDoubleDownArrow',
  lottieDoubleDownArrowBothDirections: 'lottieDoubleDownArrowBothDirections',
  lottieScrollDownArrowChain: 'lottieScrollDownArrowChain',
  lottieScrollDownArrow: 'lottieScrollDownArrow',
} as const;

// order of indicators here defines the order in customize indicator component
export const ActiveMarkerIndicators = {
  ...LottieActiveMarkerIndicators,
  ...SvgActiveMarkerIndicators,
} as const;

// each entry here should have a translation
export const NamedActiveMarkerIndicators = [
  LottieActiveMarkerIndicators.lottieChevron,
  LottieActiveMarkerIndicators.lottieCaretDownShort,
  LottieActiveMarkerIndicators.lottieDoubleDownArrow,
  LottieActiveMarkerIndicators.lottieScrollDownArrowChain,
  SvgActiveMarkerIndicators.basicCircleArrow,
  SvgActiveMarkerIndicators.chevronArrow,
  SvgActiveMarkerIndicators.fatCircleArrow,
  SvgActiveMarkerIndicators.neonArrow,
  SvgActiveMarkerIndicators.simpleArrow,
  SvgActiveMarkerIndicators.slimCircleArrow,
  SvgActiveMarkerIndicators.tripleNeonArrow,
  SvgActiveMarkerIndicators.radarCircle,
] as const;

export type SvgActiveMarkerIndicator = typeof SvgActiveMarkerIndicators[keyof typeof SvgActiveMarkerIndicators];
export type LottieActiveMarkerIndicator = typeof LottieActiveMarkerIndicators[keyof typeof LottieActiveMarkerIndicators];
export type ActiveMarkerIndicator = typeof ActiveMarkerIndicators[keyof typeof ActiveMarkerIndicators];
export type NamedActiveMarkerIndicator = typeof NamedActiveMarkerIndicators[number];

export type SvgActiveMarkerIndicatorsProps = Readonly<{
  dimensions: QuadrilateralDimensions;
  path: string;
}>;

export type LottieActiveMarkerIndicatorProps = Readonly<{
  size: number;
  position: 'anchor' | 'above';
  offset?: Readonly<{ top: number; left: number }>;
  configFactory: (color: string) => LottieAnimationConfig;
  segment?: LottieAnimationSegment;
  view?: LottieAnimationViewDetails;
  speed?: number;
  bothDirections?: boolean;
}>;
