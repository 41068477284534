import { useSelector } from 'react-redux';
import {
  type FileAttachment, type FileAttachmentId, FileAttachmentTool,
} from '~/_shared/types/file.types';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { type AppState } from '~/store/app.store';
import { mapSettingsFileAttachmentsListSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import { type FileUrl } from './fileUrls.state';

export const fileUrlsSelector = (state: AppState) => state.frontendState.fileUrls.files;
export const useFileUrls = () => useSelector(fileUrlsSelector);

export const fileAttachmentsUrlsSelector = (state: AppState) => {
  const fileAttachments = mapSettingsFileAttachmentsListSelector(state);
  const fileUrls = fileUrlsSelector(state);

  return getFileAttachmentsUrlsMemoized(fileAttachments, fileUrls);
};

export const useFileAttachmentsUrls = () => useSelector(fileAttachmentsUrlsSelector);
export const useFileAttachmentUrl = (id: FileAttachmentId) => useSelector(fileAttachmentsUrlsSelector).get(id);

const getFileAttachmentsUrlsMemoized = memoizeWeak((
  fileAttachments: ReadonlyArray<FileAttachment>,
  fileUrls: ReadonlyMap<number, FileUrl>
) => {
  const urlsArray = fileAttachments
    .filter(attachment => attachment.tool === FileAttachmentTool.Drawing)
    .map(attachment => [attachment.id, fileUrls.get(attachment.fileId)])
    .filter((tuple): tuple is [FileAttachmentId, FileUrl] => !!tuple[1]);

  return new Map<FileAttachmentId, FileUrl>(urlsArray);
});
