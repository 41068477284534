import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useBoundaryBatchEditLocations } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryBatchEditLocations';
import { useGetBoundaryTerritoryDisplayNames } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useGetBoundaryTerritoryDisplayName';
import { useIsBoundaryGroupCustom } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { deactivateActiveBoundary } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { usePrevious } from '../../../../_shared/utils/hooks/usePrevious';
import { useBoundaryTerritoryMetricsData } from '../../../../_shared/utils/metric/useBoundaryTerritoryMetricsData';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import {
  useBoundaryTerritoryAssignments,
  useBoundaryTerritoryDetailsIsLoadingSelector,
} from '../../../../store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryTerritoryGroupsSelector } from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup,
} from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useIsConnectedLayeredMapSelector } from '../../../../store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '../../../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../../../store/selectors/useMapInfoSelectors';
import { useBoundaryDirections } from '../../mapTools/boundary/hooks/useBoundaryDirections';
import { useBoundaryExportData } from '../../mapTools/boundary/hooks/useBoundaryExportData';
import { useRemoveBoundaryTerritory } from '../../mapTools/boundary/hooks/useRemoveBoundaryTerritory';
import { BoundaryDetailsComponent } from '../boundaryDetails/boundaryDetails.component';
import { useBoundaryDetailsMarkersCount } from '../boundaryDetails/useBoundaryDetailsMarkersCount';

type BoundaryTerritoryDetailsContainerProps = {
  boundaryTerritoryId: string;
  boundaryTerritoryGroupId: number;

  onClose: () => void;
};

export const BoundaryTerritoryDetailsContainer: FC<BoundaryTerritoryDetailsContainerProps> = (props) => {
  const dispatch = useDispatch();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const isBoundaryTerritoryDetailsLoading = useBoundaryTerritoryDetailsIsLoadingSelector();
  const { getBoundaryTerritoryDisplayName } = useGetBoundaryTerritoryDisplayNames();
  const { exportBoundaryTerritoryData } = useBoundaryExportData();
  const { removeTerritory } = useRemoveBoundaryTerritory();
  const { batchEditLocationsInBoundaryTerritory } = useBoundaryBatchEditLocations();
  const { addBoundaryTerritoryDirections } = useBoundaryDirections();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const { openModal: openBoundaryCustomizeMetricsModal } = useModal(ModalType.BoundaryCustomizeMetrics);
  const { openModal: openExportContainedBoundaryDataModal } = useModal(ModalType.ExportContainedBoundaryData);
  const { isBoundaryGroupCustom } = useIsBoundaryGroupCustom();
  const isMobileScreen = useIsMobileScreenSelector();

  const boundaryTerritoryGroup: BoundaryTerritoryGroup | null = useMemo(() =>
    boundaryTerritoryGroups.find(g => g.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId) ?? null
  , [boundaryTerritoryGroups, props.boundaryTerritoryGroupId]);

  const boundaryTerritory: BoundaryTerritory | null = useMemo(() => {
    const boundaryTerritoryGroup = boundaryTerritoryGroups
      .find(g => g.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);

    const boundaryTerritory = boundaryTerritoryGroup?.settings.boundaryTerritories
      .find(territory => territory.boundaryTerritoryId === props.boundaryTerritoryId);

    return boundaryTerritory ?? null;
  }, [props.boundaryTerritoryId, props.boundaryTerritoryGroupId, boundaryTerritoryGroups]);

  const { metricsResults, isLoading } = useBoundaryTerritoryMetricsData(
    props.boundaryTerritoryId,
    props.boundaryTerritoryGroupId,
    boundaryTerritoryGroup?.settings.ignoreFilters
  );

  const closeActiveBoundary = useCallback(() => {
    dispatch(deactivateActiveBoundary());
  }, [dispatch]);

  useEffect(() => {
    if (!boundaryTerritory) {
      closeActiveBoundary();
    }
  });

  const boundaryIds = useMemo(() => {
    const boundaryIdsSet = boundaryTerritoryAssignments.get(props.boundaryTerritoryGroupId)
      ?.territoryAssignments.get(props.boundaryTerritoryId);

    if (!boundaryIdsSet) {
      return [];
    }

    return Array.from(boundaryIdsSet);
  }, [boundaryTerritoryAssignments, props.boundaryTerritoryGroupId, props.boundaryTerritoryId]);

  const { markersCount } = useBoundaryDetailsMarkersCount(props.boundaryTerritoryGroupId, boundaryIds);

  const previousBoundaryTerritory = usePrevious(boundaryTerritory);

  const onExportDataClick = !isMapPresentational
    ? () => exportBoundaryTerritoryData(props.boundaryTerritoryGroupId, props.boundaryTerritoryId)
    : undefined;

  const onExportContainedBoundariesClick = !isMapPresentational
    ? () => openExportContainedBoundaryDataModal({
      filter: {
        type: 'boundary_territory',
        boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
        boundaryTerritoryId: props.boundaryTerritoryId,
      },
    }) : undefined;

  const onRemoveTerritoryClick = (boundaryTerritory?.custom && !isMapPresentational)
    ? () => removeTerritory(props.boundaryTerritoryGroupId, props.boundaryTerritoryId)
    : undefined;

  const onBatchEditLocations = !isMapPresentational
    ? () => batchEditLocationsInBoundaryTerritory(props.boundaryTerritoryGroupId, props.boundaryTerritoryId)
    : undefined;

  const onDirectionsClick = !isMapPresentational || publicMapSettings.routingDirections ?
    () => addBoundaryTerritoryDirections(props.boundaryTerritoryGroupId, props.boundaryTerritoryId)
    : undefined;

  const onEditMetrics = () => boundaryTerritoryGroup ? openBoundaryCustomizeMetricsModal({
    boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
    isBoundaryGroupCustom: isBoundaryGroupCustom(boundaryTerritoryGroup?.boundaryGroupId),
  }) : undefined;

  useEffect(() => {
    if (previousBoundaryTerritory && !boundaryTerritory) {
      closeActiveBoundary();
    }
  }, [previousBoundaryTerritory, boundaryTerritory, closeActiveBoundary]);

  return (
    <BoundaryDetailsComponent
      boundaryName={getBoundaryTerritoryDisplayName(props.boundaryTerritoryId, props.boundaryTerritoryGroupId)}
      isCustom={false}
      isArtificial={false}
      isMobileVersion={isMobileScreen}
      boundaryPolygonsTotal={0}
      isEditingBoundary={false}
      isLoading={isLoading || isBoundaryTerritoryDetailsLoading}
      markersCount={markersCount}
      metrics={metricsResults}
      ignoreFilters={!!boundaryTerritoryGroup?.settings.ignoreFilters}
      onClose={closeActiveBoundary}
      onExportLocationsClick={onExportDataClick}
      onExportContainedBoundariesClick={onExportContainedBoundariesClick}
      onBoundaryItemRemoveClick={onRemoveTerritoryClick}
      onBatchEditLocationsClick={onBatchEditLocations}
      onDirectionsClick={onDirectionsClick}
      isLayeredMapConnected={isLayeredMapConnected}
      onCustomizeMetricsClick={onEditMetrics}
    />
  );
};
