import {
  type FC, useEffect, useState,
} from 'react';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useTranslation } from '~/_shared/utils/hooks';
import { useQuery } from '~/_shared/utils/hooks/useQuery';
import { getRelativeUrl } from '~/_shared/utils/url/url.helpers';
import { ImpersonationService } from '~/authorization/impersonation';
import { useUserRoleSelector } from '~/store/userData/userData.selectors';
import { Roles } from '~/store/userData/userData.state';
import { impersonate } from './impersonation.repository';
import { ImpersonationStartPageComponent } from './ImpersonationStartPageComponent';

export const ImpersonationStartPageContainer: FC = () => {
  const [t] = useTranslation();
  const currentUserSystemRole = useUserRoleSelector();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const query = useQuery();
  const expires = query.get('expires');
  const hash = query.get('hash');
  const signature = query.get('signature');
  const viaMap = getRelativeUrl(query.get('viaMap'));

  useEffect(() => {
    setErrorMessage('');

    if (currentUserSystemRole !== Roles.SUPERUSER) {
      setErrorMessage('Error: Functionality reserved for Superuser');
      return;
    }

    setIsLoading(true);
    if (expires && hash && signature) {
      impersonate(expires, hash, signature)
        .then((response) => {
          ImpersonationService.start(response.access_token_expiration);
          setIsSuccess(true);
          location.href = viaMap || HOME_ROUTE;
        })
        .catch(() => {
          setErrorMessage(t('UserImpersonation.Start.Failed'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentUserSystemRole, expires, hash, signature, t, viaMap]);

  return (
    <ImpersonationStartPageComponent
      isLoading={isLoading}
      errorMessage={errorMessage}
      showHomePageLink={!!errorMessage}
      showSuccessful={isSuccess}
    />
  );
};
