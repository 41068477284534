import {
  useEffect, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { type LatLng } from '~/_shared/types/latLng';
import { GoogleMapsOverlayView } from './googleMapsOverlayView';

const createOverlayElement = () => {
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.display = 'inline-block';
  el.style.width = '9999px';
  return el;
};

const createGoogleMapsOverlayView = (position: LatLng, content: HTMLElement, onAdd?: () => void) => (
  new GoogleMapsOverlayView({
    position: new google.maps.LatLng(position.lat, position.lng),
    content,
    onAdd,
  })
);

type GoogleMapsOverlayViewContainerProps = Readonly<{
  children: React.ReactNode;
  map?: google.maps.Map;
  noMouseEvents?: boolean;
  position: LatLng;
  width?: number;

  onAdd?: () => void;
}>;

export const GoogleMapsOverlayViewContainer: React.FC<GoogleMapsOverlayViewContainerProps> = (props) => {
  const [el] = useState(createOverlayElement);
  const [overlay, setOverlay] = useState<GoogleMapsOverlayView | null>(null);

  useEffect(() => {
    if (props.width !== undefined) {
      el.style.width = `${props.width}px`;
    }
  }, [props.width, el]);

  useEffect(() => {
    el.style.pointerEvents = props.noMouseEvents ? 'none' : 'auto';
  }, [el, props.noMouseEvents]);

  useEffect(() => {
    if (!props.map) {
      return;
    }

    if (overlay) {
      overlay.setMap(props.map);
    }
    else {
      setOverlay(createGoogleMapsOverlayView(props.position, el, props.onAdd));
    }

    return () => {
      overlay?.setMap(null);
      el?.parentElement?.removeChild(el);
    };
  }, [props.position, props.map, overlay, el, props.onAdd]);

  useEffect(() => {
    if (!overlay?.position || areLatLngsEqual(overlay.position, props.position)) {
      return;
    }

    overlay.position = new google.maps.LatLng(props.position);
  }, [overlay, props.position]);

  return createPortal(props.children, el);
};

const areLatLngsEqual = (googleLatLng: google.maps.LatLng, ourLatLng: LatLng) =>
  googleLatLng.lat() === ourLatLng.lat && googleLatLng.lng() === ourLatLng.lng;
