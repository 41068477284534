import { type TOptions } from 'i18next';
import { type FC } from 'react';
import { Trans as I18nTrans } from 'react-i18next';
import { i18n } from '../i18nextSetup';
import { type Translations } from './translations.type';

type TransType = typeof I18nTrans;
type TransProps<TKey extends keyof Translations> = Parameters<TransType>[0] & {
  i18nKey: TKey;
  children?: Translations[TKey];
};

export const Trans: <TKey extends keyof Translations>(props: React.PropsWithChildren<TransProps<TKey>>) => ReturnType<FC> = props => {
  return (
    <I18nTrans
      {...props}
    >
      {props.children}
    </I18nTrans>
  );
};

export const s = <T extends string>(input: T): T => input;

export const translate = (key: keyof Translations, defaultValue?: string, options?: TOptions): string => {
  return i18n.t(key, defaultValue ?? `-MISSING_TRANSLATION-key:${key}`, options);
};
