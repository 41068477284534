import { css } from '@emotion/react';
import { faObjectGroup } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode,
} from 'react';
import { EXPORT_LEGEND_COMPONENT_ID } from '~/_shared/types/exportImage/exportImage.constants';
import { useTranslation } from '~/_shared/utils/hooks';
import { MapToolHeaderButtonComponent } from '../../mapToolWrapper/mapTooHeaderButton.component';
import { MapToolWrapperComponent } from '../../mapToolWrapper/mapToolWrapper.component';
import { ActiveBoundariesComponent } from './activeBoundary/activeBoundaries.component';
import { type BoundaryAction } from './boundaryTools.types';
import { ConvertBoundariesContainer } from './convertBoundaries/convertBoundaries.container';
import { LoadBoundaryPaneContainer } from './loadBoundaryPane/loadBoundaryPane.container';

type BoundaryToolProps = Readonly<{
  legendMode?: boolean;
  actions: ReadonlyArray<BoundaryAction>;
  isLoading: boolean;
  showBoundaryLoadOptions: boolean;
  exportBtn: ReactNode;
  onManageBoundaryGroupsClick?: () => void;
  checkIsNumeric: (columnId: string | null, spreadsheetId: number | null) => Promise<boolean>;
  showAdminControls: boolean;
}>;

// added to hide last boundary item's border bottom when starts touching bottom of the container
const scrollbarContentStyle = css({
  overflow: 'hidden',
});

export const BoundaryToolComponent: FC<BoundaryToolProps> = props => {
  const [t] = useTranslation();

  if (props.legendMode) {
    return (
      <div id={props.legendMode ? EXPORT_LEGEND_COMPONENT_ID : undefined}>
        <ActiveBoundariesComponent legendMode />
      </div>
    );
  }

  return (
    <>
      <MapToolWrapperComponent
        scrollbarContentStyle={scrollbarContentStyle}
        header={(props.showAdminControls && props.onManageBoundaryGroupsClick) ? (
          <MapToolHeaderButtonComponent
            prefixIcon={faObjectGroup}
            onClick={props.onManageBoundaryGroupsClick}
            text={t('Manage Territory Groups')}
          />
        ) : null}
        isLoading={props.isLoading}
        footer={props.exportBtn}
      >
        {props.showBoundaryLoadOptions && (
          <LoadBoundaryPaneContainer
            actions={props.actions}
            checkIsNumeric={props.checkIsNumeric}
          />
        )}
        <div>
          <ActiveBoundariesComponent />
        </div>
      </MapToolWrapperComponent>

      <ConvertBoundariesContainer />
    </>
  );
};
