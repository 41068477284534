import { type PerSpreadsheet } from '../../_shared/types/spreadsheet/spreadsheet.types';

export enum DataType {
  GROUP = 'group',
  TEXT = 'text',
  NUMBER = 'number',
  ATTRIBUTE = 'attribute',
  DATE = 'date'
}

export type GroupId = string;

export type GroupData = Readonly<{
  id: GroupId;
  label: string;
}>;

export type UniqueGroupsDataTypeData = ReadonlyArray<GroupData>;

export type GroupDataTypeData = Readonly<{
  uniqueGroups: UniqueGroupsDataTypeData;
}>;

type NumberDataTypeData = Readonly<{
  //TODO:: add rangeInclMax filter on BE
  max: number;
  min: number;
  maxFiltered: number;
  minFiltered: number;
  numericPercent: number;
  numericAndEmptyPercent: number;
}>;

export type SpreadsheetDataItemGroup = Readonly<{
  values: { // values vary depending on DataType
    [rowId: string]: number; // value number refers to an index in extra.uniqueGroup
  };
  extra: GroupDataTypeData;
}>;

export type SpreadsheetDataItemNumber = Readonly<{
  values: {
    [rowId: string]: number | null | undefined;
  };
  extra: NumberDataTypeData;
}>;

export type SpreadsheetDataItemText = {
  values: {
    [rowId: string]: string; // actual values from the spreadsheet
  };
};

export type SpreadsheetDataItemAttribute = Readonly<{
  values: Readonly<{
    [rowId: number]: ReadonlyArray<number>; // value number is an index in unique_values
  }>;
  extra: {
    uniqueValues: ReadonlyArray<string>;
  };
}>;

export type SpreadsheetDataItemDate = Readonly<{
  values: Readonly<{
    [rowId: number]: string;
  }>;
  extra: Readonly<{
    min: string;
    max: string;
    minFiltered: string;
    maxFiltered: string;
  }>;
}>;

export type SpreadsheetDataItem = Readonly<{
  [columnId: string]: {
    [DataType.GROUP]?: SpreadsheetDataItemGroup;
    [DataType.TEXT]?: SpreadsheetDataItemText;
    [DataType.NUMBER]?: SpreadsheetDataItemNumber;
    [DataType.ATTRIBUTE]?: SpreadsheetDataItemAttribute;
    [DataType.DATE]?: SpreadsheetDataItemDate;
  };
}>;

export type SpreadsheetFilters = PerSpreadsheet<{
  [filerHash: string]: {
    [rowId: number]: 1;
  };
}>;

export const emptySpreadsheetData: SpreadsheetDataData = { isSpreadsheetDataData: true, values: {} };

export type SpreadsheetDataData = Readonly<{
  isSpreadsheetDataData: true; // for type check, otherwise record is assignable to any type
  values: Readonly<{
    [spreadsheetId: number]: Readonly<{
      [filterHashOrUnfiltered: string]: SpreadsheetDataItem;
    }>;
  }>;
}>;

export type SpreadsheetDataState = {
  isLoading: boolean;
  isReady: boolean;
  data: SpreadsheetDataData | null;
  filters: SpreadsheetFilters | null;
  etag: string;
};

export const Unfiltered: string = 'unfiltered';
