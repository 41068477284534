import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { ModalComponent } from '../../../../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../../../../_shared/components/overlay/overlayLoader.component';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { type ModalProps } from '../../../../../modal/modalType.enum';
import {
  type BoundaryTerritoryType,
  type CalculateBucketFunctionNumeric,
} from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { ActiveBoundaryFillEditComponent } from '../activeBoundary/edit/activeBoundaryFillEditComponent';
import { useActiveBoundaryFillEdit } from '../activeBoundary/edit/useActiveBoundaryFillEdit';

type CustomizeBoundaryFillProps = ModalProps<{
  boundaryFillData: SpreadsheetColumnId | null;
  boundaryTerritoryType: BoundaryTerritoryType;
  boundaryGroupId: number;
  initialDemographicId: string | null;
  isLoading: boolean;
  initialNumericCalculateBucketFunction: CalculateBucketFunctionNumeric | null;
  onUpdate: (
    territoryType: BoundaryTerritoryType,
    fillData: SpreadsheetColumnId | null,
    demographicId: string | null,
    numericCalculateBucketFunction: CalculateBucketFunctionNumeric | null,
  ) => void;
}>;

const editWrapperStyle = css({
  padding: '5px 16px',
});

export const CustomizeBoundaryFillComponent: FC<CustomizeBoundaryFillProps> = (props) => {
  const {
    isUpdateDisabled, boundaryFillModel, ...boundaryFillProps
  } = useActiveBoundaryFillEdit({
    initialBoundaryTerritoryType: props.boundaryTerritoryType,
    initialBoundaryFillData: props.boundaryFillData,
    initialDemographicId: props.initialDemographicId,
    boundaryGroupId: props.boundaryGroupId,
    initialNumericCalculateBucketFunction: props.initialNumericCalculateBucketFunction,
  });
  const [t] = useTranslation();

  const onUpdateSubmit = () => {
    if (isUpdateDisabled || boundaryFillModel.boundaryTerritoryType === null) {
      return;
    }

    props.onUpdate(
      boundaryFillModel.boundaryTerritoryType,
      boundaryFillModel.boundaryFillData,
      boundaryFillModel.selectedDemographicId,
      boundaryFillModel.numericCalculateBucketFunction
    );
  };

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Customize Fill by data')}
      confirmButton={(
        <TooltipDeprComponent
          tooltipContent={boundaryFillProps.updateDisabledReason}
          placement="bottom"
        >
          <ButtonComponent
            prefixIcon={faCheck}
            text={t('Save')}
            onClick={onUpdateSubmit}
            isDisabled={isUpdateDisabled || props.isLoading}
          />
        </TooltipDeprComponent>
      )}
      leftFooterContent={props.isLoading ? <OverlayLoaderComponent /> : null}
    >
      <div css={editWrapperStyle}>
        <ActiveBoundaryFillEditComponent
          labelCountStart={1}
          {...boundaryFillProps}
          {...boundaryFillModel}
        />
      </div>
    </ModalComponent>
  );
};
