import { css } from '@emotion/react';
import {
  type ChangeEvent, forwardRef, type KeyboardEvent, useMemo,
} from 'react';
import { inputCommonStyle } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import type { Theme } from '~/_shared/themes/theme.model';

export type TextareaProps = Readonly<{
  id?: string;
  value?: string;
  className?: string;
  placeholder?: string;
  isReadOnly?: boolean;
  testid?: string;

  onChange?: (newValue: string) => void;
  onBlur?: () => void;
  onKeyUp?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
}>;

const textareaStyle = (readonly: boolean) => (theme: Theme) => css(inputCommonStyle(theme, !readonly), {
  width: '100%',
  padding: '12px 8px',
  fontSize: 16,
  fontWeight: 500,
  boxSizing: 'border-box',
  resize: 'vertical',
  outline: 'none',
});

export const TextareaComponent = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { onChange } = props;

  const handleChange = useMemo(() => (onChange
    ? (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)
    : undefined
  ), [onChange]);

  return (
    <textarea
      ref={ref}
      css={textareaStyle(!!props.isReadOnly)}
      className={props.className}
      id={props.id}
      data-testid={props.testid}
      value={props.value}
      placeholder={props.placeholder}
      readOnly={props.isReadOnly}
      onBlur={props.onBlur}
      onChange={handleChange}
      onKeyUp={props.onKeyUp}
    />
  );
});
