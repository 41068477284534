import { useCallback } from 'react';
import { darkTheme } from '~/_shared/themes/dark.theme';
import { lightTheme } from '~/_shared/themes/light.theme';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useUpdateUserTheme } from '~/store/theme/useUpdateUserTheme.hook';
import { HomepageInfoThemeCardComponent } from './homepageInfoThemeCard.component';

export const HomepageInfoThemeCardContainer: React.FC = _ => {
  const theme = useTheme();
  const { updateUserTheme, themeChangeAllowed } = useUpdateUserTheme();

  const onThemeChange = useCallback((isDarkTheme: boolean) => {
    updateUserTheme(isDarkTheme ? darkTheme.name : lightTheme.name);
  }, [updateUserTheme]);

  return (
    <HomepageInfoThemeCardComponent
      currentTheme={theme.name}
      onThemeChange={themeChangeAllowed ? onThemeChange : undefined}
    />
  );
};
