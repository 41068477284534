import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import { V4MapListingOrdering } from '~/store/mapMigration/types/v4MapListingOrdering.enum';

type V4MapInfoComparer = (mapA: V4MapInfo, mapB: V4MapInfo) => number;

export const v4MapInfoComparers: Record<V4MapListingOrdering, V4MapInfoComparer> = {
  [V4MapListingOrdering.Updated_DESC]: (mapA, mapB) => mapB.updatedAt.valueOf() - mapA.updatedAt.valueOf(),
  [V4MapListingOrdering.Updated_ASC]: (mapA, mapB) => mapA.updatedAt.valueOf() - mapB.updatedAt.valueOf(),
  [V4MapListingOrdering.Created_DESC]: (mapA, mapB) => mapB.createdAt.valueOf() - mapA.createdAt.valueOf(),
  [V4MapListingOrdering.Created_ASC]: (mapA, mapB) => mapA.createdAt.valueOf() - mapB.createdAt.valueOf(),
  [V4MapListingOrdering.Migrated_DESC]: (mapA, mapB) => {
    if (mapA.migratedAt && mapB.migratedAt) {
      return mapB.migratedAt.valueOf() - mapA.migratedAt.valueOf();
    }
    if (mapA.migratedAt === mapB.migratedAt) {
      return 0;
    }
    if (mapA.migratedAt) {
      return -1;
    }
    return 1;
  },
  [V4MapListingOrdering.Migrated_ASC]: (mapA, mapB) => {
    if (mapA.migratedAt && mapB.migratedAt) {
      return mapA.migratedAt.valueOf() - mapB.migratedAt.valueOf();
    }
    if (mapA.migratedAt === mapB.migratedAt) {
      return 0;
    }
    if (mapB.migratedAt) {
      return -1;
    }
    return 1;
  },
  [V4MapListingOrdering.Name_DESC]: (mapA, mapB) => mapB.title.localeCompare(mapA.title),
  [V4MapListingOrdering.Name_ASC]: (mapA, mapB) => mapA.title.localeCompare(mapB.title),
};
