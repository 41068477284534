import {
  faApple, faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCircleLocationArrow, faEnvelope, faMapSigns, faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { ColumnRole } from '~/_shared/types/columnRole.enum';
import {
  ExportImageLocationListMode, ExportImageMode,
} from '~/_shared/types/exportImage/exportImage';
import { EXPORT_LOCATION_LIST_COMPONENT_ID } from '~/_shared/types/exportImage/exportImage.constants';
import {
  type FetchableResource, FetchableResourceStatus,
} from '~/_shared/types/fetchableResource/fetchableResource';
import {
  getLoadingStateFetchableResource, isResourceLoaded,
} from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { MapFeature } from '~/_shared/types/toolsAndFeatures/mapFeatures.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { uniqueValues } from '~/_shared/utils/array/array.helpers';
import { copyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import {
  getIsAppleMobileDevice, isMobileDevice as getIsMobileDevice,
} from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import {
  getAppleMapsNavigateToAddressLink, getGoogleMapsNavigateToAddressLink,
} from '~/_shared/utils/link/gisLink';
import {
  ZOOM_TO_MARKER_MAX_ZOOM_LEVEL, ZOOM_TO_MARKER_MIN_ZOOM_LEVEL,
} from '~/_shared/utils/markers/markers.constants';
import { useGetMarkerVisualsForSpreadsheetRowIds } from '~/_shared/utils/markers/useGetMarkerVisualsForSpreadsheetRowIds.hook';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  ADDRESS_ROLES, useAddressInfoByRowIds,
} from '~/_shared/utils/spreadsheet/useAddressInfoByRowIds.hook';
import { useCategoryToColumnIdsMap } from '~/_shared/utils/spreadsheet/useCategoryValueResolver';
import { useMarkerNameResolver } from '~/_shared/utils/spreadsheet/useMarkerNameResolver';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { notNull } from '~/_shared/utils/typeGuards';
import {
  convertMetersToUnit, getShortUnitSystemLabel,
} from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { useItemsVisibleOnPresentationalMap } from '~/app/maptiveApp/useItemsVisibleOnPresentationalMap.hook';
import { MainMenuItems } from '~/mainMenu/mainMenuItem.enum';
import { getDirectionsLabelMarkerSettings } from '~/map/map/directions/mapDirectionsManager';
import { useMarkerLabelData } from '~/map/map/markers/useMarkers/useMarkerLabelData.hook';
import {
  useFilteredSpreadsheetRowIds, useLatLngSpreadsheetData,
} from '~/map/map/useSpreadsheetData.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { MAXIMUM_RAW_DATA_MARKERS } from '~/spreadsheet/spreadsheet.repository';
import { useSpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import {
  mapComponentSetCenter, mapComponentSetZoom,
} from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { frontendStateDirectionsAddMarkerWaypoint } from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import { rightPanelHoverMarker } from '~/store/frontendState/mapTools/locationFinder/locationFinder.actionCreators';
import {
  useMapSettingsDirectionsRoutesSpreadsheetRowsSelector, useMapSettingsDirectionsShouldBeginAtUserLocationSelector,
} from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapSettingsSettingsUnitSystemSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { setLocationFinderToolsState } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.actionCreators';
import {
  useIsLocationFinderSelectorActive, useLocationFinderSelector,
} from '~/store/mapSettings/toolsState/locationFinder/locationFinder.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { type FetchableSpreadsheetRow } from '~/store/spreadsheetCellData/spreadsheetCellData.state';
import {
  checkIfSpreadsheetRowIdMeetsFilteringCriteria, getDistancesFromPointForSpreadsheetData,
} from '~/store/spreadsheetData/spreadsheetData.helpers';
import { LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS } from '../locationFinderModal/locationFinderModal.component';
import { useExportLocationFinderLocations } from '../locationFinderModal/useExportLocationFinderLocations';
import { type LocationListingActions } from './item/locationListingItem.component';
import { LocationListingComponent } from './locationListing.component';

export const MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS = MAXIMUM_RAW_DATA_MARKERS;

const MARKER_INFO_COLUMN_ROLES_FIRST_LINE = [ColumnRole.Address1, ColumnRole.Address2];
const MARKER_INFO_COLUMN_ROLES_SECOND_LINE = [ColumnRole.City, ColumnRole.State, ColumnRole.PostCode];
const MARKER_INFO_COLUMN_ROLES = MARKER_INFO_COLUMN_ROLES_FIRST_LINE.concat(MARKER_INFO_COLUMN_ROLES_SECOND_LINE);

const GROUPING_HIERARCHY_INDICATOR = 0;

type ActionsProps = {
  latLng: LatLng;
  address: string;
  markerId: SpreadsheetRowId;
};

type LocationListingContainerProps = {
  onClose: () => void;
};

const getZoomToLocationZoomLevel = (currentZoomLevel: number|undefined): number => {
  if (!currentZoomLevel) {
    return ZOOM_TO_MARKER_MIN_ZOOM_LEVEL;
  }

  const desiredZoomLevel = currentZoomLevel + 1;
  return clamp(desiredZoomLevel, { min: ZOOM_TO_MARKER_MIN_ZOOM_LEVEL, max: ZOOM_TO_MARKER_MAX_ZOOM_LEVEL });
};

export const LocationListingContainer: FC<LocationListingContainerProps> = ({ onClose }) => {
  const isMobileDevice = getIsMobileDevice();
  const isAppleMobileDevice = getIsAppleMobileDevice();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const theme = useTheme();
  const allowedPresentationalMapTools = usePublicMapSettingsAllowedExportDataToolsSelector();
  const directionsUseUserLocation = useMapSettingsDirectionsShouldBeginAtUserLocationSelector();
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds;
  const locationData = useLatLngSpreadsheetData();
  const locationFinder = useLocationFinderSelector();
  const isLocationFinderActive = useIsLocationFinderSelectorActive();
  const unitSystem = useMapSettingsSettingsUnitSystemSelector();
  const { exportLocationFinderLocations } = useExportLocationFinderLocations();
  const { filteredRowIds } = useFilteredSpreadsheetRowIds();
  const { markerNameResolver } = useMarkerNameResolver();
  const itemsVisibleOnPresentationalMap = useItemsVisibleOnPresentationalMap();
  const latLngSpreadsheetData = useLatLngSpreadsheetData();
  const currentZoomLevel = useMapComponentLastBoundsSelector()?.zoomLevel;
  const markerWaypointsInSpreadsheets = useMapSettingsDirectionsRoutesSpreadsheetRowsSelector();

  const [isGoogleLinkCopiedToClipboard, showGoogleLinkCopiedToClipboard] = useTimeout(3000);
  const [isAppleLinkCopiedToClipboard, showAppleLinkCopiedToClipboard] = useTimeout(3000);

  const { openModal: openSendNavigationLinksModal } = useModal(ModalType.SendNavigationLinks);

  const filteredLocationData = useMemo(() =>
    locationData.data.filter(d => checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredRowIds, d)),
  [locationData, filteredRowIds]);

  const spreadsheetId = filteredLocationData?.[0]?.spreadsheetId;

  const categoryToColumnIdsMap = useCategoryToColumnIdsMap()[spreadsheetId || 0];

  const hasOneOrMoreMarkerInfoColumns = useMemo(() => (
    spreadsheetId && MARKER_INFO_COLUMN_ROLES.some(role => categoryToColumnIdsMap?.[role])
  ), [categoryToColumnIdsMap, spreadsheetId]);

  const markerInfoColumnIds = useMemo(() => (
    spreadsheetId
      ? uniqueValues(MARKER_INFO_COLUMN_ROLES.concat(ADDRESS_ROLES))
        .map(role => (categoryToColumnIdsMap?.[role] ? {
          spreadsheetId,
          columnId: categoryToColumnIdsMap[role],
        } : null))
        .filter(notNull)
      : []
  ), [categoryToColumnIdsMap, spreadsheetId]);

  const isExportLocationListMode = useMemo(() => (
    isMapPresentational && exportImageSettings.mode === ExportImageMode.LocationList
  ), [isMapPresentational, exportImageSettings.mode]);

  const isExportLocationListAllFilteredMode = isExportLocationListMode
    && exportImageSettings.locationListMode === ExportImageLocationListMode.AllFiltered;

  const filteredLocationsInBounds = useMemo(() => (
    filteredLocationData.filter(item => lastBounds?.contains(item))
  ), [filteredLocationData, lastBounds]);

  const markerLimitExceeded = !isLocationFinderActive
    && !isExportLocationListAllFilteredMode
    && filteredLocationsInBounds.length > MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS;

  const locationsToRender = useMemo(() => {
    const filteredLocations = isExportLocationListAllFilteredMode ? filteredLocationData : filteredLocationsInBounds;

    const locations = isLocationFinderActive && locationFinder.latLng !== null //because of ts limitations
      ? getDistancesFromPointForSpreadsheetData(filteredLocationData, locationFinder.latLng)
      : filteredLocations;

    const limit = isLocationFinderActive
      ? (locationFinder.locationLimit ?? LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS)
      : MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS;

    return locations.slice(0, limit);
  }, [filteredLocationData, filteredLocationsInBounds, isExportLocationListAllFilteredMode,
    isLocationFinderActive, locationFinder.latLng, locationFinder.locationLimit]);

  const locationLabelData = useMarkerLabelData(locationsToRender);

  const fetchExtraInfoColumns = !markerLimitExceeded && markerInfoColumnIds.length;
  const { spreadsheetCellData: data } = useSpreadsheetRowData(
    fetchExtraInfoColumns ? locationsToRender : undefined,
    markerInfoColumnIds,
  );

  const addressInfoByRowId = useAddressInfoByRowIds({ spreadsheetRowIds: filteredLocationsInBounds, spreadsheetCellData: data });

  const visualMarkerSettingsLookup = useGetMarkerVisualsForSpreadsheetRowIds({
    spreadsheetRowIds: filteredLocationData,
    isLegend: false,
    groupHierarchyIndicator: GROUPING_HIERARCHY_INDICATOR,
  });

  const title = isLocationFinderActive ? t('Location Finder') : t('Locations List');

  const listElementId = useMemo(() => (
    isExportLocationListMode ? EXPORT_LOCATION_LIST_COMPONENT_ID : undefined
  ), [isExportLocationListMode]);

  const onMarkerClick = useCallback((latLng: LatLng) => {
    dispatch(mapComponentSetCenter(latLng));
    dispatch(mapComponentSetZoom(getZoomToLocationZoomLevel(currentZoomLevel)));
  }, [currentZoomLevel, dispatch]);

  const clearLocationListHover = useCallback(() => {
    dispatch(rightPanelHoverMarker(null));
  }, [dispatch]);

  const onMarkerHover = useCallback((rowId: SpreadsheetRowId | null) => {
    dispatch(rightPanelHoverMarker(rowId));
  }, [dispatch]);

  const getActions = useCallback((props: ActionsProps): LocationListingActions[] => {
    const areDirectionsAvailable = !isMapPresentational || itemsVisibleOnPresentationalMap.has(MainMenuItems.Routing);
    const locationAddress = addressInfoByRowId.get(props.markerId.spreadsheetId)?.get(props.markerId.rowId)?.addressAsString;
    return [
      {
        icon: faSearch,
        text: t('Zoom to Location'),
        isDisabled: false,
        isDestructive: false,
        onClick: () => onMarkerClick(props.latLng),
      },
      ...(areDirectionsAvailable ? [{
        icon: faMapSigns,
        text: t('Get Directions'),
        isDisabled: false,
        isDestructive: false,
        onClick: () => dispatch(frontendStateDirectionsAddMarkerWaypoint({
          address: props.address,
          latLng: props.latLng,
          startsFromUserLocation: directionsUseUserLocation,
        })),
      }] : []),
      ...(isMobileDevice && locationAddress ? [{
        icon: faCircleLocationArrow,
        text: t('NavigateToGoogle'),
        isDisabled: false,
        isDestructive: false,
        onClick: () => {
          window.location.href = getGoogleMapsNavigateToAddressLink(locationAddress);
        },
      }] : []),
      ...(isAppleMobileDevice && locationAddress ? [{
        icon: faApple,
        text: t('NavigateToApple'),
        isDisabled: false,
        isDestructive: false,
        onClick: () => {
          window.location.href = getAppleMapsNavigateToAddressLink(locationAddress);
        },
      }] : []),
      ...(isMapPresentational && locationAddress ? [{
        icon: faGoogle,
        text: isGoogleLinkCopiedToClipboard ? t('Copied to Clipboard!') : t('Copy Google Maps Link'),
        isDisabled: isGoogleLinkCopiedToClipboard,
        isDestructive: false,
        preventCloseOnClick: true,
        onClick: () => {
          copyToClipboard(getGoogleMapsNavigateToAddressLink(locationAddress));
          showGoogleLinkCopiedToClipboard();
        },
      }] : []),
      ...(isMapPresentational && locationAddress ? [{
        icon: faApple,
        text: isAppleLinkCopiedToClipboard ? t('Copied to Clipboard!') : t('Copy Apple Maps Link'),
        isDisabled: isAppleLinkCopiedToClipboard,
        isDestructive: false,
        preventCloseOnClick: true,
        onClick: () => {
          copyToClipboard(getAppleMapsNavigateToAddressLink(locationAddress));
          showAppleLinkCopiedToClipboard();
        },
      }] : []),
      ...(!isMapPresentational ? [{
        icon: faEnvelope,
        text: t('Email Navigation Link'),
        isDisabled: false,
        isDestructive: false,
        onClick: () => {
          openSendNavigationLinksModal({
            destinationLatLng: props.latLng,
            locationName: props.address,
          });
          clearLocationListHover();
        },
      }] : []),
    ];
  }, [clearLocationListHover, directionsUseUserLocation, dispatch, isAppleLinkCopiedToClipboard, isGoogleLinkCopiedToClipboard,
    isMapPresentational, isMobileDevice, itemsVisibleOnPresentationalMap, onMarkerClick, addressInfoByRowId,
    openSendNavigationLinksModal, showAppleLinkCopiedToClipboard, showGoogleLinkCopiedToClipboard, t, isAppleMobileDevice,
  ]);

  const closeLocationsList = useCallback(() => {
    clearLocationListHover();
    onClose();
    if (isLocationFinderActive) {
      dispatch(setLocationFinderToolsState(null, null));
    }
  }, [clearLocationListHover, dispatch, isLocationFinderActive, onClose]);

  const getColumnDataByRolesAsFetchableResource = useCallback((
    row: FetchableSpreadsheetRow | null | undefined,
    roles: ColumnRole[],
    delimiter: string,
  ): FetchableResource<string | null> => {
    if (row) {
      if (isResourceLoaded(row)) {
        let isACellLoading = false;
        const firstLineCellValues = roles.map(role => {
          const mappedColumnId = categoryToColumnIdsMap?.[role];
          if (mappedColumnId) {
            const cellValue = row.value?.columnsData[mappedColumnId];
            if (isResourceLoaded(cellValue)) {
              return cellValue.value;
            }
            else if (cellValue && cellValue.status === FetchableResourceStatus.Loading) {
              isACellLoading = true;
            }
          }
          return null;
        }).filter(notNull).join(delimiter);
        if (isACellLoading) {
          return getLoadingStateFetchableResource();
        }
        else {
          return {
            status: FetchableResourceStatus.Loaded,
            value: firstLineCellValues,
          };
        }
      }
      else if (row.status === FetchableResourceStatus.Loading) {
        return getLoadingStateFetchableResource();
      }
    }
    return { status: FetchableResourceStatus.NotFound };

  }, [categoryToColumnIdsMap]);

  const locationItems = useMemo(() => {
    return locationsToRender.map(item => {
      const unit = getShortUnitSystemLabel(unitSystem, t);
      const latLng: LatLng = {
        lat: item.lat,
        lng: item.lng,
      };
      const markerName = markerNameResolver(item.spreadsheetId, item.rowId, true);
      const waypointInfo = markerWaypointsInSpreadsheets.get(item.spreadsheetId)?.get(item.rowId);
      const waypointLabelSettings = waypointInfo && getDirectionsLabelMarkerSettings({
        index: waypointInfo.waypointIndex,
        theme,
        totalWaypoints: waypointInfo.route.waypoints.length,
        routeColor: waypointInfo.route.color,
      });
      const waypointLabelText = waypointInfo ? (waypointInfo.waypointIndex + 1).toString(10) : undefined;
      const markerVisualSettings = waypointLabelSettings ?? visualMarkerSettingsLookup[item.spreadsheetId]?.[item.rowId];
      if (!markerVisualSettings) {
        return null;
      }

      let distance = null;
      let header = '';
      let lines: FetchableResource<string[]> = { status: FetchableResourceStatus.Loaded, value: [] };

      if (hasDistanceFromPoint(item)) {
        // location finder
        const locationItem = data?.[item.spreadsheetId]?.get(item.rowId);
        const secondLineText = getColumnDataByRolesAsFetchableResource(locationItem, MARKER_INFO_COLUMN_ROLES_SECOND_LINE, ' ');

        distance = convertMetersToUnit(item.distanceFromPoint, unitSystem);
        header = t('Distance') + ': ' + distance.toFixed(2) + unit;
        if (secondLineText.status === FetchableResourceStatus.Loading) {
          lines = getLoadingStateFetchableResource();
        }
        else {
          lines = {
            status: FetchableResourceStatus.Loaded, value: [
              markerName,
              isResourceLoaded(secondLineText) ? secondLineText.value ?? '' : '',
            ],
          };
        }
      }
      else {
        // location list
        header = markerName;
        const locationItem = data?.[item.spreadsheetId]?.get(item.rowId);

        if (!hasOneOrMoreMarkerInfoColumns) {
          const rowLatLng = latLngSpreadsheetData.getRow({ rowId: item.rowId, spreadsheetId: item.spreadsheetId });
          if (rowLatLng) {
            lines = {
              status: FetchableResourceStatus.Loaded,
              value: [`${t('Lat')}: ${rowLatLng.lat.toFixed(6)}`, `${t('Lng')}: ${rowLatLng.lng.toFixed(6)}`],
            };
          }
        }
        else {
          const firstLineText = getColumnDataByRolesAsFetchableResource(locationItem, MARKER_INFO_COLUMN_ROLES_FIRST_LINE, ', ');
          const secondLineText = getColumnDataByRolesAsFetchableResource(locationItem, MARKER_INFO_COLUMN_ROLES_SECOND_LINE, ' ');
          if (firstLineText.status === FetchableResourceStatus.Loading
          || secondLineText.status === FetchableResourceStatus.Loading
          ) {
            lines = getLoadingStateFetchableResource();
          }
          else {
            lines = {
              status: FetchableResourceStatus.Loaded,
              value: [
                isResourceLoaded(firstLineText) ? firstLineText.value ?? '' : '',
                isResourceLoaded(secondLineText) ? secondLineText.value ?? '' : '',
              ].filter(line => line && !isTextEmpty(line)),
            };
          }
        }
      }

      return {
        actions: getActions({ latLng, address: markerName, markerId: item }),
        latLng,
        lines,
        distance,
        header,
        markerVisualSettings,
        labelText: waypointLabelText ?? locationLabelData.get(item),
        rowId: {
          spreadsheetId: item.spreadsheetId,
          rowId: item.rowId,
        },
        onClick: () => onMarkerClick(latLng),
        onHover: () => onMarkerHover(item),
        onMouseLeave: () => onMarkerHover(null),
      };
    })
      .filter(notNull)
      .filter(item => !!item?.header);
  }, [locationsToRender, unitSystem, markerNameResolver, markerWaypointsInSpreadsheets, theme, visualMarkerSettingsLookup,
    getActions, locationLabelData, t, data, hasOneOrMoreMarkerInfoColumns, latLngSpreadsheetData,
    getColumnDataByRolesAsFetchableResource, onMarkerClick, onMarkerHover]);

  const onExportFileClick = useMemo(() => (
    isLocationFinderActive && (!isMapPresentational
      || hasFeatureOrToolAllowedPresentationalMapExport(MapFeature.LocationFinder, allowedPresentationalMapTools)
    ) ? () => {
        const rows = locationItems.map(item => ({
          rowId: item.rowId,
          distance: item.distance || 0,
        }));
        exportLocationFinderLocations({ rows });
      }
      : undefined
  ), [allowedPresentationalMapTools, exportLocationFinderLocations, isLocationFinderActive, isMapPresentational, locationItems]);

  return (
    <LocationListingComponent
      listElementId={listElementId}
      closeLocationsList={closeLocationsList}
      items={locationItems}
      limitExceeded={markerLimitExceeded}
      readOnlyMode={isExportLocationListMode}
      title={title}
      onExportFileClick={onExportFileClick}
    />
  );
};

type LatLngRowId = Readonly<LatLng & SpreadsheetRowId>;
const hasDistanceFromPoint = (item: LatLngRowId | LatLngRowId & { distanceFromPoint: number }): item is LatLngRowId & {
  distanceFromPoint: number;
} =>
  item.hasOwnProperty('distanceFromPoint');
