import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { clientTeamManagementSagas } from '~/clientTeamManagement/clientTeamManagement.sagas';
import { activeMapElementsSagas } from '~/store/frontendState/activeMapElements/activeMapElements.sagas';
import { boundarySidebarStateSagas } from '~/store/frontendState/mapTools/boundary/boundarySidebarState/boundarySidebarState.sagas';
import { mutuallyExclusiveMapToolsSagas } from '~/store/frontendState/mapTools/mutuallyExclusiveMapTools/mutuallyExclusiveMapToolsSagas';
import { mapMigrationSagas } from '~/store/mapMigration/mapMigration.sagas';
import { mapSettingsSettingsSagas } from '~/store/mapSettings/settings/mapSettingsSettings.sagas';
import { userEventsSagas } from '~/store/userEvents/userEvents.sagas';
import { announcementsSagas } from './announcements/announcements.sagas';
import { appReducer } from './app.reducer';
import { boundaryGroupsSagas } from './boundaryGroups/boundaryGroups.sagas';
import { boundaryItemsSagas } from './boundaryItems/boundaryItems.sagas';
import { boundaryTerritoryDetailsSagas } from './boundaryTerritoryDetails/boundaryTerritoryDetails.sagas';
import { boundaryTerritoryGroupsSagas } from './boundaryTerritoryGroups/boundaryTerritoryGroups.sagas';
import { demographicsListGlobalSagas } from './demographics/demographicsListGlobal/demographicsListGlobal.sagas';
import { actionSanitizer } from './devTools/actionSanitizer';
import { stateSanitizer } from './devTools/stateSanitizer';
import { fileUrlsSaga } from './frontendState/fileUrls/fileUrls.saga';
import { mapInitializationFailedSagas } from './frontendState/mapInitializationFailed/mapInitializationFailed.sagas';
import { mapPresentationalSagas } from './frontendState/mapPresentational/mapPresentational.sagas';
import { boundaryCreateOptimizedSagas } from './frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.sagas';
import { boundaryDrawSagas } from './frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.sagas';
import { boundariesEditingSagas } from './frontendState/mapTools/boundary/boundaryPolygonUpdate/boundaryPolygonUpdate.sagas';
import { boundarySelectSagas } from './frontendState/mapTools/boundary/boundarySelect/boundarySelect.sagas';
import { directionsSaga } from './frontendState/mapTools/directions/directions.saga';
import { drawingEditSagas } from './frontendState/mapTools/drawing/drawingEdit/drawingEdit.sagas';
import { myLocationSagas } from './frontendState/mapTools/myLocation/myLocation.sagas';
import { placesSagas } from './frontendState/places/places.sagas';
import { proximityDriveTimePathsSagas } from './frontendState/proximityDriveTimePaths/proximityDriveTimePaths.sagas';
import { spreadsheetTableSagas } from './frontendState/spreadsheetTable/spreadsheetTable.sagas';
import { geocodingSagas } from './geocoding/geocoding.sagas';
import { mapSagas } from './map/map.sagas';
import { mapInfoSagas } from './mapInfo/mapInfo.sagas';
import { mapSettingsBoundariesSagas } from './mapSettings/boundaries/mapSettingsBoundaries.sagas';
import { mapSettingsColumnsFiltersSagas } from './mapSettings/columnsFilter/mapSettingsColumnsFilter.sagas';
import { mapSettingsDataSagas } from './mapSettings/data/mapSettingsData.sagas';
import { mapSettingsDirectionsSaga } from './mapSettings/directions/mapSettingsDirections.saga';
import { fileAttachmentsSaga } from './mapSettings/fileAttachments/fileAttachments.saga';
import { mapSettingsFilteringSagas } from './mapSettings/filtering/mapSettingsFiltering.sagas';
import { heatMapsSagas } from './mapSettings/heatmaps/mapSettingsHeatmaps.saga';
import { mapSettingsLayeredSagas } from './mapSettings/layeredMap/mapSettingsLayered.sagas';
import { mapSettingsLoadingSagas } from './mapSettings/loading/mapSettingsLoading.sagas';
import { mapSettingsMarkersGeneralSaga } from './mapSettings/makersGeneral/mapSettingsMarkersGeneral.saga';
import { mapSettingsMarkersSaga } from './mapSettings/markers/mapSettingsMarkers.saga';
import { proximitySaga } from './mapSettings/proximity/mapSettingsProximity.saga';
import { mapSettingsReadySagas } from './mapSettings/ready/mapSettingsReady.sagas';
import { mapSettingsSearchItemsSagas } from './mapSettings/searchItems/mapSettingsSearchItems.sagas';
import { mapSettingsToolsStateBoundarySagas } from './mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.sagas';
import { exportImageSagas } from './mapSettings/toolsState/exportImageSettings/exportImageSettings.sagas';
import { matchupDataSagas } from './matchupData/matchupData.sagas';
import { polygonGroupsSagas } from './polygonGroups/polygonGroups.sagas';
import { presentationalColumnsRestrictionsSagas } from './presentationalColumnsRestrictions/presentationalColumnsRestrictions.sagas';
import { spreadsheetDataSagas } from './spreadsheetData/spreadsheetData.sagas';
import { themeSagas } from './theme/theme.sagas';
import { undoSagas } from './undo/undo.sagas';
import { userDataSettingsSagas } from './userData/settings/userDataSettings.saga';
import { userDataSagas } from './userData/userData.sagas';

export type AppState = ReturnType<typeof appReducer>;

const sagaMiddleware = createSagaMiddleware();

export const appStore = configureStore<AppState, Action<typeof appReducer>>({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
    actionCreatorCheck: false,
    thunk: false,
  }).concat(sagaMiddleware),
  devTools: {
    trace: true,
    serialize: true,
    actionSanitizer,
    stateSanitizer,
    maxAge: 200,
  },
});

sagaMiddleware.run(themeSagas);
sagaMiddleware.run(userDataSagas);
sagaMiddleware.run(userEventsSagas);
sagaMiddleware.run(polygonGroupsSagas);
sagaMiddleware.run(mapSagas);
sagaMiddleware.run(mapInfoSagas);
sagaMiddleware.run(spreadsheetDataSagas);
sagaMiddleware.run(matchupDataSagas);
sagaMiddleware.run(geocodingSagas);
sagaMiddleware.run(mapSettingsDataSagas);
sagaMiddleware.run(mapSettingsLoadingSagas);
sagaMiddleware.run(mapSettingsReadySagas);
sagaMiddleware.run(mapInitializationFailedSagas);
sagaMiddleware.run(undoSagas);
sagaMiddleware.run(announcementsSagas);
sagaMiddleware.run(clientTeamManagementSagas);
sagaMiddleware.run(mapMigrationSagas);
sagaMiddleware.run(mapPresentationalSagas);
sagaMiddleware.run(mapSettingsFilteringSagas);
sagaMiddleware.run(heatMapsSagas);
sagaMiddleware.run(mapSettingsMarkersGeneralSaga);
sagaMiddleware.run(mapSettingsMarkersSaga);
sagaMiddleware.run(proximitySaga);
sagaMiddleware.run(mapSettingsDirectionsSaga);
sagaMiddleware.run(directionsSaga);
sagaMiddleware.run(spreadsheetTableSagas);
sagaMiddleware.run(mapSettingsColumnsFiltersSagas);
sagaMiddleware.run(mutuallyExclusiveMapToolsSagas);
sagaMiddleware.run(boundaryGroupsSagas);
sagaMiddleware.run(boundarySidebarStateSagas);
sagaMiddleware.run(presentationalColumnsRestrictionsSagas);
sagaMiddleware.run(userDataSettingsSagas);
sagaMiddleware.run(proximityDriveTimePathsSagas);
sagaMiddleware.run(boundaryTerritoryGroupsSagas);
sagaMiddleware.run(boundaryTerritoryDetailsSagas);
sagaMiddleware.run(fileAttachmentsSaga);
sagaMiddleware.run(fileUrlsSaga);
sagaMiddleware.run(boundaryItemsSagas);
sagaMiddleware.run(boundaryDrawSagas);
sagaMiddleware.run(boundarySelectSagas);
sagaMiddleware.run(boundariesEditingSagas);
sagaMiddleware.run(mapSettingsBoundariesSagas);
sagaMiddleware.run(mapSettingsLayeredSagas);
sagaMiddleware.run(demographicsListGlobalSagas);
sagaMiddleware.run(drawingEditSagas);
sagaMiddleware.run(mapSettingsToolsStateBoundarySagas);
sagaMiddleware.run(myLocationSagas);
sagaMiddleware.run(exportImageSagas);
sagaMiddleware.run(boundaryCreateOptimizedSagas);
sagaMiddleware.run(placesSagas);
sagaMiddleware.run(mapSettingsSearchItemsSagas);
sagaMiddleware.run(activeMapElementsSagas);
sagaMiddleware.run(mapSettingsSettingsSagas);
