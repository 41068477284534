import '@fortawesome/fontawesome-svg-core/styles.css';
import { css } from '@emotion/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import {
  // eslint-disable-next-line no-restricted-imports
  FontAwesomeIcon as FAIcon,
  type FontAwesomeIconProps as FaIconProps,
} from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false;

export type FaIcon = FaIconProps['icon'];

const iconStyle = css({ useSelect: 'none' });

export type FontAwesomeIconProps = FaIconProps;

export const FontAwesomeIcon = forwardRef<SVGSVGElement, FontAwesomeIconProps>((props, ref) => (
  <FAIcon
    ref={ref}
    css={iconStyle}
    {...props}
  />
));

FontAwesomeIcon.displayName = 'FontAwesomeIcon';
