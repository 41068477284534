import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type ModalProps } from '~/modal/modalType.enum';
import { isBoundaryTerritoryGroupCustom } from '~/sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import {
  isCustomGroup,
  useBoundaryGroupsSelector,
  useCustomBoundaryGroupsSelector,
  useGetBoundaryGroup,
  useGlobalBoundaryGroups,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { boundaryDrawEditOpenModal } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { boundarySelectEditCustomBoundaryOpenModal } from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { clearBoundaryLocationsFilter } from '../../../store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import {
  BoundaryEditModalComponent, type BoundaryEditResults,
} from './boundaryEditModal.component';
import {
  BoundaryEditAction, BoundaryEditManualMethod, BoundaryEditSelectFrom,
} from './boundaryEditModal.helpers';

export type BoundaryEditModalContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  boundaryId?: number;
}>;

export const BoundaryEditModalContainer: FC<BoundaryEditModalContainerProps> = (props) => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryTerritoryGroup = boundaryTerritoryGroups
    .find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);
  const dispatch = useDispatch();
  const globalBoundaryGroups = useGlobalBoundaryGroups();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const boundaryItems = useMapBoundariesSelector();
  const { getBoundaryGroupById } = useGetBoundaryGroup();

  const isAdvancedEdit = props.boundaryId !== undefined;

  const defaultBoundaryGroup = useMemo(() => {
    if (!boundaryTerritoryGroup) {
      return undefined;
    }

    const preferredBG = boundaryTerritoryGroup.settings.selectionEditPreferredBoundaryGroup?.id;
    const originBG = getBoundaryGroupById(boundaryTerritoryGroup.boundaryGroupId)?.createdFrom?.boundaryGroupIds?.[0];

    const defaultBgId = preferredBG ?? originBG;
    const defaultBg = getBoundaryGroupById(defaultBgId);

    if (!defaultBg) {
      return undefined;
    }

    return defaultBg?.visible
      ? defaultBg
      : boundaryGroups.find(bg => bg.visible && bg.editBoundaryGroupFallback === defaultBgId);
  }, [boundaryGroups, boundaryTerritoryGroup, getBoundaryGroupById]);

  const isDefaultBoundaryGroupCustom = useMemo(() => {
    if (defaultBoundaryGroup === undefined) {
      return false;
    }

    return isCustomGroup(defaultBoundaryGroup);
  }, [defaultBoundaryGroup]);

  const defaultManualMethod = useMemo(() => {
    if (defaultBoundaryGroup !== undefined) {
      return BoundaryEditManualMethod.Selection;
    }

    if (isAdvancedEdit) {
      return BoundaryEditManualMethod.Draw;
    }

    return undefined;
  }, [defaultBoundaryGroup, isAdvancedEdit]);

  const defaultSelectFrom = useMemo(() => {
    if (!defaultBoundaryGroup) {
      return undefined;
    }

    return isDefaultBoundaryGroupCustom ? BoundaryEditSelectFrom.Territory : BoundaryEditSelectFrom.Boundary;
  }, [defaultBoundaryGroup, isDefaultBoundaryGroupCustom]);

  const defaultBoundaryGroupParams = useMemo(() => {
    if (!defaultBoundaryGroup) {
      return undefined;
    }

    return isDefaultBoundaryGroupCustom ? ({
      custom: defaultBoundaryGroup?.id,
    }) : ({
      global: defaultBoundaryGroup?.id,
    });
  }, [defaultBoundaryGroup, isDefaultBoundaryGroupCustom]);

  const onSubmit = useCallback((results: BoundaryEditResults) => {
    if (!boundaryTerritoryGroup) {
      return;
    }

    dispatch(clearBoundaryLocationsFilter());

    if (results.manualMethod === BoundaryEditManualMethod.Selection) {
      const selectedBoundaryGroup = getBoundaryGroupById(results.selectedBoundaryGroupId);
      if (!selectedBoundaryGroup) {
        return;
      }

      dispatch(boundarySelectEditCustomBoundaryOpenModal({
        actionType: results.action,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
        selectBoundaryGroupId: selectedBoundaryGroup.editBoundaryGroupFallback ?? selectedBoundaryGroup.id,
        boundaryId: props.boundaryId,
      }));
    }

    if (results.manualMethod === BoundaryEditManualMethod.Draw) {
      dispatch(boundaryDrawEditOpenModal({
        boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
        action: results.action,
        boundaryId: props.boundaryId,
      }));
    }

    const onClose = props.onClose;
    onClose();
  }, [boundaryTerritoryGroup, dispatch, getBoundaryGroupById, props.boundaryId, props.onClose]);

  if (!boundaryTerritoryGroup || !isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups)) {
    return null;
  }

  const boundaryGroupIsEmpty = !boundaryItems.get(boundaryTerritoryGroup.boundaryGroupId)?.size;

  return (
    <BoundaryEditModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={onSubmit}
      boundaryGroupIsEmpty={boundaryGroupIsEmpty}
      globalBoundaryGroups={globalBoundaryGroups}
      customBoundaryGroups={customBoundaryGroups}
      defaultAction={isAdvancedEdit ? BoundaryEditAction.EditBoundaries : undefined}
      defaultManualMethod={defaultManualMethod}
      defaultSelectFrom={defaultSelectFrom}
      defaultBoundaryGroup={defaultBoundaryGroupParams}
    />
  );
};
