import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { CONFIG } from '~/_shared/constants/config';
import {
  type MapPrivacyLevel, type MapSource,
} from '~/_shared/types/map';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { mapReset } from '~/store/map/map.actionCreators';
import { openModal } from '~/store/modal/modal.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useUserCurrentClientSelector } from '~/store/userData/userData.selectors';
import { useConfirmationModal } from '../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../_shared/utils/hooks';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';
import { MapListingDeleteStatus } from './actionsHeader/actionsHeader.component';
import { MapListingComponent } from './mapListing.component';
import { useMapDropDown } from './useMapDropDown.hook';
import { useMapListingDeleteWithStatus } from './useMapListingDeleteWithStatus';
import { useMapListingToggleManagement } from './useMapListingToggleManagement.hook';

export const MapListingContainer: FC = () => {
  const clientId = useClientIdSelector();
  const { sortType, setSortType } = useMapDropDown(clientId);
  const { openModal: openMapPrivacySelectorModal } = useModal(ModalType.MapPrivacySelector);
  const {
    onToggleMapSelect, onToggleSnapshotSelect, selectedMapIds, selectedMaps, selectedSnapshotIds, onToggleShowMapSnapshots,
    mapListingItems, pagination, onPageChange, isLoading: isMapListingDataLoading, errorMessage, toggledMapIds,
    setSelectedMapIds, setSelectedSnapshotIds, forceRefresh: forceRefreshListingData, searchQuery,
    onSearchQueryChange, changePrivacyLevel, allMapListingItems,
  } = useMapListingToggleManagement(sortType);
  const currentMapId = useMapIdSelector();
  const dispatch = useDispatch();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const [t] = useTranslation();
  const currentClient = useUserCurrentClientSelector();
  const migrationFeatureEnabled = CONFIG.ENABLE_MIGRATION;
  const hasMapsToMigrate = useSelector(state => state.mapMigration.v4MapInfos.length !== 0);
  const canUserInitiateMapMigration = migrationFeatureEnabled && (currentClient?.migrationDone === false) && hasMapsToMigrate;

  const onCreateNewMap = useCallback(() => dispatch(openModal(ModalType.CreateNewMap, {})), [dispatch]);
  const onMigrationButtonClick = useCallback(() => dispatch(openModal(ModalType.MigrateMaps, {})), [dispatch]);

  const onDeleteSuccess = () => {
    setSelectedMapIds([]);
    setSelectedSnapshotIds(new Set());
    forceRefreshListingData();

    const currentMapDeleted = currentMapId !== null
        && (selectedMaps.map((map) => map.id === currentMapId) || selectedSnapshotIds.has(currentMapId));

    if (currentMapDeleted) {
      dispatch(mapReset());
    }
  };

  const { status, resetStatus, onDelete } = useMapListingDeleteWithStatus({
    selectedMaps, selectedMapIds, selectedSnapshotIds, onSuccess: onDeleteSuccess, allMapListingItems,
  });

  const handleMapDelete = useCallback(() => {
    openConfirmationModal({
      confirmCaption: t('Proceed'),
      isConfirmButtonDestructive: true,
      onCancel: closeConfirmationModal,
      onConfirm: () => {
        onDelete();
        closeConfirmationModal();
      },
      title: t('Delete Maps'),
      text: t('MapsDeletionConfirmation'),
    });
  }, [closeConfirmationModal, onDelete, openConfirmationModal, t]);

  const onMapPrivacyClick = useCallback((data: {
    mapId: number;
    mapName: string;
    privacyLevel: string;
    parentMapId?: number;
    parentMapPrivacy?: string;
    mapSource: MapSource;
  }) => {
    const onSuccess = (newPrivacyLevel: MapPrivacyLevel) => {
      changePrivacyLevel({
        mapId: data.mapId,
        newPrivacyLevel,
        parentMapId: data.parentMapId,
      });
    };

    openMapPrivacySelectorModal({
      isSnapshot: !!data.parentMapId,
      mapName: data.mapName,
      mapPrivacyInfo: {
        mapId: data.mapId, mapPrivacyLevel: data.privacyLevel as MapPrivacyLevel,
        parentMapId: data.parentMapId,
        parentMapPrivacy: data.parentMapPrivacy as MapPrivacyLevel,
        mapSource: data.mapSource,
      },
      onSuccess,
    });
  }, [changePrivacyLevel, openMapPrivacySelectorModal]);

  useEffect(() => {
    setSelectedMapIds([]);
    setSelectedSnapshotIds(new Set());
  }, [pagination?.currentPage, setSelectedMapIds, setSelectedSnapshotIds]);

  return (
    <MapListingComponent
      deleteStatus={status}
      errorMessage={errorMessage}
      isMapListingDataLoading={isMapListingDataLoading || status === MapListingDeleteStatus.Loading}
      onDelete={handleMapDelete}
      onDeleteSuccessAnimationFinish={resetStatus}
      onMapPrivacyClick={onMapPrivacyClick}
      onMigrationButtonClick={canUserInitiateMapMigration ? onMigrationButtonClick : undefined}
      onCreateNewMap={onCreateNewMap}
      onPageChange={onPageChange}
      onSearchQueryChange={onSearchQueryChange}
      onSortTypeChange={setSortType}
      pagination={pagination}
      processedItems={mapListingItems}
      searchQuery={searchQuery}
      selectedMapIds={selectedMapIds}
      selectedSnapshotIds={selectedSnapshotIds}
      sortType={sortType}
      toggleMapSelection={onToggleMapSelect}
      toggleSnapshotSelection={onToggleSnapshotSelect}
      toggleSnapshotsVisibility={onToggleShowMapSnapshots}
      toggledMapIds={toggledMapIds}
      onShareSuccess={changePrivacyLevel}
    />
  );
};
