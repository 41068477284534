import { css } from '@emotion/react';
import {
  type ReactNode, useState,
} from 'react';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';

const footerWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export type MigrateMapsConfirmationComponentProps = ModalProps<{
  onConfirm: () => void;
  warningText: ReactNode;
  requiresConfirmation?: boolean;
}>;

export const MigrateMapsConfirmationComponent = ({
  isOpen,
  onClose,
  onConfirm,
  requiresConfirmation,
  warningText,
}: MigrateMapsConfirmationComponentProps) => {
  const [t] = useTranslation();
  const [isManuallyConfirmed, setIsManuallyConfirmed] = useState(false);
  const agreementPhrase = t('MapMigrationConfirmationPhrase').toLowerCase();

  return (
    <ModalComponent
      onClose={onClose}
      isOpen={isOpen}
      isCloseDisabled
      caption={t('MapMigrationConfirmationHeading')}
      maxWidth={800}
      footer={(
        <div css={footerWrapperStyle}>
          <ButtonComponent
            buttonStyle={ButtonStyle.Secondary}
            text={t('Cancel')}
            onClick={onClose}
          />
          <ButtonComponent
            isDisabled={requiresConfirmation && !isManuallyConfirmed}
            buttonStyle={ButtonStyle.Primary}
            text={t('Confirm')}
            onClick={onConfirm}
          />
        </div>
      )}
    >
      {warningText}
      {requiresConfirmation && (
        <TextInputComponent onChange={newValue => setIsManuallyConfirmed(newValue.toLowerCase() === agreementPhrase)} />
      )}
    </ModalComponent>
  );
};
