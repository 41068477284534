import type { CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type MapSettingsFilterTextValue } from '../../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import {
  SPREADSHEET_TABLE_REMOVE_ROWS_REQUEST,
  SPREADSHEET_TABLE_REMOVE_SELECTED_ROWS_REQUEST,
  SPREADSHEET_TABLE_SEARCH_SET_QUERY,
  SPREADSHEET_TABLE_SET_IS_ROWS_REMOVE_IN_PROGRESS,
  SPREADSHEET_TABLE_SET_SELECTED_ROWS,
} from './spreadsheetTable.actionTypes';
import { type SpreadsheetSelectedRow } from './spreadsheetTable.state';

export const spreadsheetTableSetSelectedRows = (selectedRows: { readonly [rowId: CombinedRowId]: SpreadsheetSelectedRow }) => ({
  type: SPREADSHEET_TABLE_SET_SELECTED_ROWS,
  payload: {
    selectedRows,
  },
}) as const;

export const spreadsheetTableRemoveSelectedDataRowsRequest = () => ({
  type: SPREADSHEET_TABLE_REMOVE_SELECTED_ROWS_REQUEST,
}) as const;

export const spreadsheetTableRemoveRowsRequest = (rowsToRemove: SpreadsheetSelectedRow[]) => ({
  type: SPREADSHEET_TABLE_REMOVE_ROWS_REQUEST,
  payload: {
    rowsToRemove,
  },
}) as const;

export const spreadsheetTableSetIsRowsRemoveInProgress = (inProgress: boolean) => ({
  type: SPREADSHEET_TABLE_SET_IS_ROWS_REMOVE_IN_PROGRESS,
  payload: {
    inProgress,
  },
}) as const;

export const spreadsheetTableSearchSetQuery = (
  spreadsheetColumnId: SpreadsheetColumnId, values: ReadonlyArray<MapSettingsFilterTextValue>
) => ({
  type: SPREADSHEET_TABLE_SEARCH_SET_QUERY,
  payload: {
    spreadsheetColumnId,
    values,
  },
}) as const;
