import {
  type FC, useEffect, useState,
} from 'react';
import {
  ExportDataModalComponent, type ExportDataModalResults,
} from '~/_shared/components/exportDataModal/exportDataModal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { type ModalProps } from '~/modal/modalType.enum';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { prepareAsyncCopyToClipboard } from '../../_shared/utils/clipboard/clipboard.helpers';
import { ExportDataFileType } from './exportDataFileType.enum';
import { ExportDataMethod } from './exportDataMethod.enum';
import {
  type ItemizedSpreadsheetDataItemRow, useExportItemizedSpreadsheetData,
} from './useExportItemizedSpreadsheetData';

export type ExportItemizedSpreadsheetDataModalContainerProps = ModalProps<{
  postfixColumns?: string[];
  prefixColumns?: string[];
  items: {
    rows: ItemizedSpreadsheetDataItemRow[];
  }[];
}>;

export const ExportItemizedSpreadsheetDataModalContainer: FC<ExportItemizedSpreadsheetDataModalContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);

  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const [t] = useTranslation();
  const { getExportedItemizedSpreadsheetData, handleGetItemizedSpreadsheetDataResponse, isLoading, isError } = useExportItemizedSpreadsheetData();

  const onSubmit = (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId) {
      return;
    }

    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    return getExportedItemizedSpreadsheetData({
      additionalPostfixColumns: props.postfixColumns?.map(col => ({ columnName: col })),
      additionalPrefixColumns: props.prefixColumns?.map(col => ({ columnName: col })),
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      exportAsText: arg.method === ExportDataMethod.Clipboard || undefined,
      items: props.items,
    })
      .then(response => {
        handleGetItemizedSpreadsheetDataResponse({
          exportDataMethod: arg.method,
          responseData: response,
          setErrorMessage,
          copyToClipboard: asyncCopyToClipboard,
          showCopiedToClipboardMessage,
        });
      });
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  return (
    <ExportDataModalComponent
      error={errorMessage}
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={onSubmit}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
    />
  );
};
