import { type MatchupDataAction } from './matchupData.action';
import {
  MATCHUP_FETCH_DATA_ERROR,
  MATCHUP_FETCH_DATA_REQUEST,
  MATCHUP_FETCH_DATA_SUCCESS,
  MATCHUP_UPDATE_ERROR, MATCHUP_UPDATE_REQUEST, MATCHUP_UPDATE_SUCCESS,
} from './matchupData.actionTypes';
import { type MatchupDataState } from './matchupData.state';

const initialState: MatchupDataState = {};

export const matchupDataReducer = (state = initialState, action: MatchupDataAction): MatchupDataState => {
  switch (action.type) {
    case MATCHUP_FETCH_DATA_REQUEST: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: true,
        },
      };
    }

    case MATCHUP_FETCH_DATA_ERROR: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: false,
        },
      };
    }

    case MATCHUP_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: false,
          data: action.payload.data,
        },
      };
    }

    case MATCHUP_UPDATE_REQUEST: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: true,
        },
      };
    }

    case MATCHUP_UPDATE_ERROR: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: false,
        },
      };
    }

    case MATCHUP_UPDATE_SUCCESS: {
      return {
        ...state,
        [action.payload.spreadsheetVirtualId]: {
          ...state[action.payload.spreadsheetVirtualId],
          isLoading: false,
          data: action.payload.data,
        },
      };
    }

    default:
      return state;
  }
};
