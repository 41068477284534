const REFRESH_TOKEN_LOCK = 'REFRESH_TOKEN_LOCK';

export const RefreshTokenLockService = {
  inProgress: async () => {
    const lockState = await navigator.locks.query();
    return lockState.held?.some((lock) => lock.name === REFRESH_TOKEN_LOCK) ?? false;
  },
  call: (cb: LockGrantedCallback) => navigator.locks.request(REFRESH_TOKEN_LOCK, cb),
  waitForFinish: () => navigator.locks.request(REFRESH_TOKEN_LOCK, () => null),
};
