import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { useTranslation } from '~/_shared/utils/hooks';

const menuTriggerStyle = ({ x, y }: {x: number; y: number}) => css({
  position: 'absolute',
  top: y,
  left: x,
});

const menuItemStyle = css({
  fontSize: '12px',
});

type MapContextMenuProps = Readonly<{
  map: google.maps.Map;
  menuPosition: {x: number; y: number};

  onHide: () => void;

  onMoveMarkers?: () => void;
  onMoveMarkerLabels?: () => void;
}>;

export const MapMarkerContextMenuComponent: FC<MapContextMenuProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div>
      <ContextMenuComponent
        isVisible
        onHide={props.onHide}
        preferredPlacement="bottom-start"
        renderMenuContent={() => (
          <>
            {props.onMoveMarkers && (
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                  onClick={props.onMoveMarkers}
                >
                  {t('Move Markers')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}
            {props.onMoveMarkerLabels && (
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                  onClick={props.onMoveMarkerLabels}
                >
                  {t('Reposition Labels')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}
          </>
        )}
      >
        {ref => (
          <span
            ref={ref}
            css={menuTriggerStyle(props.menuPosition)}
          />
        )}
      </ContextMenuComponent>
    </div>
  );
};
