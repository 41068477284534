import { css } from '@emotion/react';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';

type UploadMethodProps = Readonly<{
  buttonLabel: string;
  buttonStyle: ButtonStyle;
  className?: string;
  isDisabled?: boolean;
  title: string;

  onClick?: () => void;
}>;

const containerStyles = ({ theme }: ThemeProps) => css({
  padding: 20,
  border: `1px solid ${theme.borderColors.primary}`,
  background: theme.backgroundColors.secondary,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const titleStyles = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  padding: 0,
  margin: 0,
});

const iconStyles = css({
  fontSize: '16px',
  lineHeight: 1.5,
  margin: 0,
  width: 40,
});

const buttonStyle = css({
  marginTop: 30,
  display: 'flex',
  alignItems: 'center',
});

const buttonLabelStyles = css({
  flexGrow: 1,
});

export const UploadMethodComponent: FC<UploadMethodProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={containerStyles({ theme })}
      className={props.className}
    >
      <p css={titleStyles({ theme })}>{props.title}</p>
      <ButtonComponent
        buttonStyle={props.buttonStyle}
        css={buttonStyle}
        isDisabled={props.isDisabled}
        onClick={props.onClick}
        prefixIcon={faFile}
        prefixIconStyle={iconStyles}
        text={(
          <span css={buttonLabelStyles}>
            {props.buttonLabel}
          </span>
        )}
      />
    </div>
  );
};
