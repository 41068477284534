import { useCallback } from 'react';
import { useDeleteClient } from '~/store/userData/useDeleteClient.hook';
import { useManageClientSubscriptionRenewal } from '~/subscription/useManageClientSubscriptionRenewal.hook';
import { SubscriptionManagementCancelOption } from './subscriptionManagement.constants';

export const useDeleteClientOrRemoveSubscription = () => {
  const { isLoading: isDeleteClientLoading, openDeleteClientConfirmation } = useDeleteClient();
  const { openCancelRenewalConfirmation, isLoading: isUpdateRenewalLoading } = useManageClientSubscriptionRenewal();

  const deleteOrCancel = useCallback((option: SubscriptionManagementCancelOption) => {
    if (option === SubscriptionManagementCancelOption.AtEnd && openCancelRenewalConfirmation) {
      openCancelRenewalConfirmation();
    }
    else if (option === SubscriptionManagementCancelOption.NowAndDeleteData) {
      openDeleteClientConfirmation();
    }
  }, [openCancelRenewalConfirmation, openDeleteClientConfirmation]);

  return {
    deleteOrCancel,
    isLoading: isUpdateRenewalLoading || isDeleteClientLoading,
  };
};
