import { css } from '@emotion/react';
import type { Theme } from '~/_shared/themes/theme.model';

export const inputSuffixCommonStyle = (theme: Theme) => css({
  borderStyle: 'solid',
  borderWidth: '0 0 0 1px',
  borderRadius: '0 3px 3px 0',
  color: theme.textColors.primary,
  borderColor: theme.borderColors.primary,
  background: theme.backgroundColors.tertiary,
});
