import {
  type FC, type ReactNode, useMemo,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { type MapListingItemWithPath } from '~/map/listing/item/mapListingItem';
import { useMapListingSearchQuery } from '~/store/frontendState/mapListing/mapListing.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapDropDown } from '../listing/useMapDropDown.hook';
import { useMapListingItems } from '../listing/useMapListingItems';
import { getMapUrl } from '../map.helpers';
import { MapDropdownComponent } from './mapDropdown.component';

type MapDropdownContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  triggerComponent: ReactNode;
}>;

export const MapDropdownContainer: FC<MapDropdownContainerProps> = (props) => {
  const clientId = useClientIdSelector();
  const [t] = useTranslation();
  const { sortType, setSortType } = useMapDropDown(clientId);
  const { onSearchQueryChange, searchQuery } = useMapListingSearchQuery();
  const { mapListingItems, pagination, onPageChange, isLoading, toggledMapIds, onToggleMapIdsChange }
    = useMapListingItems({ isActive: props.isOpen, sortType, includeCurrentMapOnTop: true, searchQuery });

  const mapListingItemsWithPath: ReadonlyArray<MapListingItemWithPath> = useMemo(() =>
    mapListingItems.map(i => ({ ...i, path: getMapUrl(i.id) })),
  [mapListingItems]);

  return (
    <MapDropdownComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      triggerComponent={props.triggerComponent}
      mapListingItems={mapListingItemsWithPath}
      searchQuery={searchQuery}
      onSearchQueryChange={onSearchQueryChange}
      sortType={sortType}
      onSortTypeChange={setSortType}
      isLoading={isLoading}
      onPageChange={onPageChange}
      pagination={pagination}
      title={`${t('My Maps')} / ${t('Saved Map Views')}`}
      toggledMapIds={toggledMapIds}
      onToggledMapIdsChange={onToggleMapIdsChange}
    />
  );
};
