import { isIndividualRadius } from '../../../_shared/types/proximity/proximity.types';
import { changeIndividualProximityLatLng } from '../../../_shared/utils/proximity';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../data/mapSettingsData.actionTypes';
import { type MapSettingsProximityAction } from './mapSettingsProximity.action';
import {
  MAP_SETTINGS_PROXIMITY_ADD_METRICS,
  MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS,
  MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_SET_HIDE_LABELS,
  MAP_SETTINGS_PROXIMITY_SET_HIDE_SMALL_RADII,
  MAP_SETTINGS_PROXIMITY_SET_METRICS,
} from './mapSettingsProximity.actionTypes';
import { type MapSettingsProximityState } from './mapSettingsProximity.state';

export const mapSettingsProximityInitialState: MapSettingsProximityState = {
  proximities: [],
  hideLabels: false,
  hideSmallRadii: false,
  metrics: [],
};

export const mapSettingsProximityReducer = (
  state = mapSettingsProximityInitialState,
  action: MapSettingsProximityAction | MapSettingsDataAction
): MapSettingsProximityState => {
  switch (action.type) {
    case MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY: {
      return {
        ...state,
        proximities: [...state.proximities, action.payload.proximity],
      };
    }

    case MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY:
      return {
        ...state,
        proximities: state.proximities.map(p => p.id === action.payload.proximity.id ? action.payload.proximity : p),
      };

    case MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY: {
      return {
        ...state,
        proximities: state.proximities.filter(proximity => proximity.id !== action.payload.proximity.id),
      };
    }

    case MAP_SETTINGS_PROXIMITY_SET_HIDE_LABELS: {
      return {
        ...state,
        hideLabels: action.payload.hideLabels,
      };
    }

    case MAP_SETTINGS_PROXIMITY_SET_HIDE_SMALL_RADII: {
      return {
        ...state,
        hideSmallRadii: action.payload.hideSmallRadii,
      };
    }

    case MAP_SETTINGS_PROXIMITY_SET_METRICS: {
      return {
        ...state,
        metrics: action.payload.metrics,
      };
    }

    case MAP_SETTINGS_PROXIMITY_ADD_METRICS: {
      return {
        ...state,
        metrics: [
          ...state.metrics,
          ...action.payload.metrics,
        ],
      };
    }

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS: {
      return {
        ...state,
        proximities: [],
      };
    }

    case MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS: {
      return {
        ...state,
        proximities: state.proximities.map(p =>
          p.id === action.payload.proximityId && isIndividualRadius(p)
            ? changeIndividualProximityLatLng(p, action.payload.newLatLng)
            : p
        ),
      };
    }

    default:
      return state;
  }
};
