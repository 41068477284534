/**
 * Copyright 2019 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This is an adjusted version that doesn't render markers directly.
 * Instead it just provides information about the markers and whether they should be render
 * or not and cluster icon should be provided instead.
 * Also data structure for the markers was changed for performance reasons.
 */

import { type LatLng } from '../../../../_shared/types/latLng';
import {
  type ReadonlySpreadsheetRowIdMap,
  SpreadsheetRowIdMap,
} from '../../../../_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { createStackedMarkerId } from '../../markers/useStacksAndClusters/StackedMarkerId.type';
import { type ClusterableMarker } from './ClusterableMarker.type';
import { type MarkerClusterer } from './markerclusterer';

/**
 * Creates a single cluster that manages a group of proximate markers.
 *  Used internally, do not call this constructor directly.
 */
export class Cluster {
  private map_: google.maps.Map;
  private minClusterSize_: number;
  private averageCenter_: boolean;
  private markers_: SpreadsheetRowIdMap<ClusterableMarker> = new SpreadsheetRowIdMap();
  private center_: google.maps.LatLng | null = null;
  private bounds_: google.maps.LatLngBounds | null = null;
  private showMarkers: boolean = true;
  private shouldRecalculateShowMarkersWithStackedMarkers = true;

  /**
   *
   * @param markerClusterer_ The `MarkerClusterer` object with which this
   *  cluster is associated.
   */
  constructor(private markerClusterer_: MarkerClusterer) {
    this.map_ = this.markerClusterer_.getMap() as google.maps.Map;
    this.minClusterSize_ = this.markerClusterer_.getMinimumClusterSize();
    this.averageCenter_ = this.markerClusterer_.getAverageCenter();
  }

  /**
   * Returns the number of markers managed by the cluster. You can call this from
   * a `click`, `mouseover`, or `mouseout` event handler for the `MarkerClusterer` object.
   *
   * @return The number of markers in the cluster.
   */
  public getSize(): number {
    return this.markers_.size;
  }

  /**
   * Returns the array of markers managed by the cluster. You can call this from
   * a `click`, `mouseover`, or `mouseout` event handler for the `MarkerClusterer` object.
   *
   * @return The array of markers in the cluster.
   */
  public getMarkers(): ReadonlySpreadsheetRowIdMap<ClusterableMarker> {
    return new SpreadsheetRowIdMap(this.markers_);
  }

  /**
   * Returns the center of the cluster. You can call this from
   * a `click`, `mouseover`, or `mouseout` event handler
   * for the `MarkerClusterer` object.
   *
   * @return The center of the cluster.
   */
  public getCenter(): LatLng | null {
    if (!this.center_) {
      return null;
    }

    return { lat: this.center_.lat(), lng: this.center_.lng() };
  }

  /**
   * Returns the map with which the cluster is associated.
   *
   * @return The map.
   * @ignore
   */
  public getMap(): google.maps.Map {
    return this.map_;
  }

  /**
   * Returns the `MarkerClusterer` object with which the cluster is associated.
   *
   * @return The associated marker clusterer.
   * @ignore
   */
  public getMarkerClusterer(): MarkerClusterer {
    return this.markerClusterer_;
  }

  /**
   * Returns the bounds of the cluster.
   *
   * @return the cluster bounds.
   * @ignore
   */
  public getBounds(): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds(this.center_ ?? undefined, this.center_ ?? undefined);
    this.markers_.forEach(marker => bounds.extend(marker));
    return bounds;
  }

  public getShowMarkers(): boolean {
    if (this.shouldRecalculateShowMarkersWithStackedMarkers && !this.showMarkers) {
      this.showMarkers = this.hasEnoughMarkersWithStackedMarkers();
      this.shouldRecalculateShowMarkersWithStackedMarkers = false;
    }

    return this.showMarkers;
  }

  private hasEnoughMarkersWithStackedMarkers = (): boolean =>
    Array.from(this.markers_.values())
      .reduce((acc, marker) => acc.add(createStackedMarkerId(marker)), new Set<string>())
      .size < this.minClusterSize_;

  /**
   * Removes the cluster from the map.
   *
   * @ignore
   */
  public remove(): void {
    // this.clusterIcon_.setMap(null);
    this.markers_ = new SpreadsheetRowIdMap();
  }

  /**
   * Adds a marker to the cluster.
   *
   * @param marker The marker to be added.
   * @return True if the marker was added.
   * @ignore
   */
  public addMarker(marker: ClusterableMarker): boolean {
    if (this.isMarkerAlreadyAdded_(marker)) {
      return false;
    }
    this.shouldRecalculateShowMarkersWithStackedMarkers = true;

    if (!this.center_) {
      this.center_ = new google.maps.LatLng(marker);
      this.calculateBounds_();
    }
    else {
      if (this.averageCenter_) {
        const l = (this.markers_.size || 0) + 1;
        const lat =
          (this.center_.lat() * (l - 1) + marker.lat) / l;
        const lng =
          (this.center_.lng() * (l - 1) + marker.lng) / l;
        this.center_ = new google.maps.LatLng(lat, lng);
        this.calculateBounds_();
      }
    }

    // marker.isAdded = true;
    this.markers_.set(marker, marker);

    const mCount = this.markers_.size || 0;
    const mz = this.markerClusterer_.getMaxZoom();
    if (mz !== null && (this.map_.getZoom() || 0) > mz) {
      // Zoomed in past max zoom, so show the marker.
      this.showMarkers = true;
    }
    else if (mCount < this.minClusterSize_) {
      // Min cluster size not reached so show the marker.
      this.showMarkers = true;
    }
    else if (mCount === this.minClusterSize_) {
      // Hide the markers that were showing.
      this.showMarkers = false;
    }
    else {
      this.showMarkers = false;
    }

    return true;
  }

  /**
   * Determines if a marker lies within the cluster's bounds.
   *
   * @param marker The marker to check.
   * @return True if the marker lies in the bounds.
   * @ignore
   */
  public isMarkerInClusterBounds(marker: ClusterableMarker): boolean {
    return this.bounds_?.contains(marker) || false;
  }

  /**
   * Calculates the extended bounds of the cluster with the grid.
   */
  private calculateBounds_(): void {
    const bounds = new google.maps.LatLngBounds(this.center_ || undefined, this.center_ || undefined);
    this.bounds_ = this.markerClusterer_.getExtendedBounds(bounds);
  }

  /**
   * Determines if a marker has already been added to the cluster.
   *
   * @param marker The marker to check.
   * @return True if the marker has already been added.
   */
  private isMarkerAlreadyAdded_(marker: ClusterableMarker): boolean {
    return this.markers_.has(marker);
  }
}
