import { apiPost } from '~/_shared/utils/api/api.helpers';

export const MAX_RESOLUTION_WIDTH = 3840;
export const MAX_RESOLUTION_HEIGHT = 2160;
export const MIN_RESOLUTION_WIDTH = 320;
export const MIN_RESOLUTION_HEIGHT = 240;

export type ExportScreenShotRequest = {
  extension: string;
  resolution?: {
    width: number;
    height: number;
  };
  double_pixel_ratio?: boolean;
  type?: string;
  section?: string;
  settings_override?: string[];
};

export type ExportScreenShotResponse = {
  hash: string;
  message: string;
};

export type GetScreenShotExportStatusReponse = {
  message: string;
  data?: {
    path: string;
  };
};

export const exportScreenShot = (shareId: string, request: ExportScreenShotRequest): Promise<ExportScreenShotResponse> =>
  apiPost(`/api/${shareId}/screenshots/export`, request);

export const getScreenShotExportStatus = (shareId: string, hash: string): Promise<GetScreenShotExportStatusReponse> =>
  apiPost(`/api/${shareId}/screenshots/status`, { hash });
