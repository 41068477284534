import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useMapContext } from '~/map/map/mapContext';
import {
  startMoveMarkerDrag, stopMoveMarkerDrag,
} from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import { useMoveMarkersIsActiveSelector } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { type SpreadsheetRowId } from '../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import {
  type MarkerWebglMapObjects, updatePositionOfMarkerMapObjects,
} from '../manager/mapMarkerManager.helpers';
import { type MapMarkerEventCallback } from '../useMarkerEvents/markerEventCallbacks.type';
import { useMarkerTemporaryPositionStore } from './useMarkerTemporaryPositionStore';

type HoveredMarker = {
  markerMapObjects: MarkerWebglMapObjects;
  spreadsheetId: number;
  rowId: string;
};

export const useMoveMarkers = () => {
  const { map } = useMapContext();
  const moveMarkersActive = useMoveMarkersIsActiveSelector();
  const [hoveredMarker, setHoveredMarker] = useState<HoveredMarker | null>(null);
  const { replaceMarkerPositions, saveNewMarkerPosition } = useMarkerTemporaryPositionStore();
  const [draggedMarkerPosition, setDraggedMarkerPosition] = useState<{rowId: string; lat: number; lng: number} | null>(null);
  const dispatch = useDispatch();

  useMapObjectDragAndDrop(() => ({
    map,
    addDragHandlerInstantly: true,
    dragDisabled: !(moveMarkersActive && hoveredMarker),
    onDragStart: () => {
      dispatch(startMoveMarkerDrag());
    },
    onDragMove: (latLng => {
      if (!hoveredMarker) {
        return;
      }
      updatePositionOfMarkerMapObjects(hoveredMarker.markerMapObjects, latLng);
      setDraggedMarkerPosition({ rowId: hoveredMarker.rowId, lat: latLng.lat, lng: latLng.lng });
    }),
    onDragEnd: () => {
      if (draggedMarkerPosition) {
        saveNewMarkerPosition(draggedMarkerPosition.rowId, draggedMarkerPosition.lat, draggedMarkerPosition.lng);
      }

      setDraggedMarkerPosition(null);
      dispatch(stopMoveMarkerDrag());
    },
  }), [dispatch, draggedMarkerPosition, hoveredMarker, map, moveMarkersActive, saveNewMarkerPosition]);

  const onMouseOver: MapMarkerEventCallback = useCallback((
    spreadsheetRowIds: SpreadsheetRowId[],
    isStacked: boolean,
    getMapObjects: () => MarkerWebglMapObjects | null
  ) => {
    if (!isStacked) {
      const isNotSingle = spreadsheetRowIds.length > 1;
      if (isNotSingle) {
        console.error('Something went wrong, calling drag and drop on stacked marker');
        return;
      }

      const markerMapObjects = getMapObjects();
      if (!markerMapObjects) {
        return;
      }

      const markerSpreadsheetEntry = spreadsheetRowIds[0];
      setHoveredMarker({ markerMapObjects, rowId: markerSpreadsheetEntry.rowId, spreadsheetId: markerSpreadsheetEntry.spreadsheetId });
    }
  }, []);

  const onMouseOut: MapMarkerEventCallback = useCallback((
    _spreadsheetRowIds: SpreadsheetRowId[],
    isStacked: boolean,
  ) => {
    if (!isStacked) {
      setHoveredMarker(null);
    }
  }, []);

  const moveMarkerCallbacks = useMemo(() => ({
    onMouseOver,
    onMouseOut,
  }), [onMouseOut, onMouseOver]);

  return {
    moveMarkersActive,
    moveMarkerCallbacks,
    moveMarkersReplaceMarkerPositions: replaceMarkerPositions,
  };
};
