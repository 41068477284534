export const FRONTEND_STATE_DRAWING_TOOL_SET_IS_ACTIVE = 'FRONTEND_STATE_DRAWING_TOOL_SET_IS_ACTIVE';

export const FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL = 'FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL';
export const FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE = 'FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE';
export const FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE = 'FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE';

export const FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL = 'FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL';
export const FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED = 'FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED';
export const FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED = 'FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED';

export const FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED = 'FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED';
export const FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED = 'FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED';

export const FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED = 'FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED';
export const FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED = 'FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED';

export const FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY = 'FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY';
export const FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY = 'FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY';
