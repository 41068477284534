import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { MapFeature } from '~/_shared/types/toolsAndFeatures/mapFeatures.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { type StreetViewState } from '../../../store/frontendState/mapTools/streetView/streetView.state';
import { type MapSettingsPublicMapSettingsState } from '../../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { RightSideMyLocationMenuItem } from './MenuItems/myLocation/rightSideMyLocationMenuItem.container';
import { RightSideAddMarkerMenuItem } from './MenuItems/rightSideAddMarkerMenuItem.container';
import { RightSideCloseStreetViewMenuItemContainer } from './MenuItems/rightSideCloseStreetViewMenuItem.container';
import { RightSideExportDataViewMenuItem } from './MenuItems/rightSideExportDataMenuItem.container';
import { RightSideExportImageMenuItem } from './MenuItems/rightSideExportImageMenuItem.container';
import { RightSideLassoToolMenuItem } from './MenuItems/rightSideLassoToolMenuItem.container';
import { RightSideLocationFinderMenuItem } from './MenuItems/rightSideLocationFinderMenuItem.container';
import { RightSideLocationListMenuItemContainer } from './MenuItems/rightSideLocationListMenuItem.container';
import { RightSideMapDistanceCalculatorMenuItem } from './MenuItems/rightSideMapDistanceCalculatorMenuItem.container';
import { RightSideMapSearchMenuItem } from './MenuItems/rightSideMapSearchMenuItem.container';
import { RightSideSatelliteViewMenuItem } from './MenuItems/rightSideSatelliteViewMenuItem.container';

const rightSideMapToolsStyle = css({
  position: 'absolute',
  top: 5,
  right: 5,
  direction: 'rtl',
  columns: '32px',
  columnGap: 12,
  maxHeight: 'calc(100% - 60px)',
  zIndex: 1,
});

type RightSideMapToolsProps = Readonly<{
  className?: string;
  forceHideLasso?: boolean;
  isMapPresentational: boolean;
  isLayeredMapConnected: boolean;
  isMobileScreen: boolean;
  publicMapSettings?: MapSettingsPublicMapSettingsState;
  streetView: StreetViewState;
}>;

export const RightSideMapToolsComponent: FC<RightSideMapToolsProps> = (props) => {
  const isAdminMap = !props.isMapPresentational;

  const isAdminMapOr = useCallback((other: boolean | undefined | null) => (isAdminMap || other), [isAdminMap]);

  const showExportData = useMemo(() => (
    isAdminMapOr(
      props.publicMapSettings
      && hasFeatureOrToolAllowedPresentationalMapExport(MapFeature.ExportData, props.publicMapSettings.allowedExportDataTools)
    )
  ), [isAdminMapOr, props.publicMapSettings]);

  if (props.streetView.active) {
    return (
      <div css={rightSideMapToolsStyle}>
        <RightSideCloseStreetViewMenuItemContainer />
      </div>
    );
  }

  if (!props.isMobileScreen) {
    return (
      <div css={rightSideMapToolsStyle}>
        <div>
          {isAdminMapOr(props.publicMapSettings?.locationList) && <RightSideLocationListMenuItemContainer />}
          {isAdminMapOr(props.publicMapSettings?.displaySearchBox && !props.publicMapSettings?.searchBarAlwaysVisible) && <RightSideMapSearchMenuItem />}
          {isAdminMapOr(props.publicMapSettings?.allowSatelliteView) && <RightSideSatelliteViewMenuItem />}
          {isAdminMapOr(props.publicMapSettings?.showMyLocation) && <RightSideMyLocationMenuItem />}
          {isAdminMap && <RightSideExportImageMenuItem />}
          {isAdminMapOr(props.publicMapSettings?.distanceCalculator) && <RightSideMapDistanceCalculatorMenuItem />}
          {isAdminMapOr(props.publicMapSettings?.locationFinder) && <RightSideLocationFinderMenuItem />}
          {!props.forceHideLasso && isAdminMapOr(props.publicMapSettings?.lassoTool) && <RightSideLassoToolMenuItem />}
          {isAdminMap && !props.isLayeredMapConnected && <RightSideAddMarkerMenuItem />}
          {showExportData && <RightSideExportDataViewMenuItem />}
        </div>
      </div>
    );
  }

  return null;
};
