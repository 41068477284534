import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.ScrollDownArrow]: {
  views: {
    default: {
      canvasHeight: 512,
      canvasWidth: 512,
      iconWidth: 120,
      iconHeight: 418,
    },
    arrow: {
      canvasHeight: 512,
      canvasWidth: 512,
      iconWidth: 120,
      iconHeight: 295,
      offsetTop: 170,
    },
  },
  colors: {
    arrow: '#FFA000',
    reflection: '#FFFFFF',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 90] as const,
    chain: [11, 70] as const,
    arrow: [35, 70] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/46170-scroll-down" */
      './46170-scroll-down.json'
    ),
} };

export const { ScrollDownArrow } = config;
