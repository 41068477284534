import { type LatLng } from '~/_shared/types/latLng';
import {
  type WebglOverlayLabelWithCallout, type WebglOverlayLabelWithCustomCallout, type WebglOverlayLabelWithTextBoxCallout,
} from '~/_shared/utils/webgl/labelWithCallout';

export type WebglMarkerObject = {
  entity: 'marker';
  object: WebglOverlayMarker;
} | {
  entity: 'label';
  object: WebglOverlayLabelWithTextBoxCallout | WebglOverlayLabelWithCustomCallout;
};

export type MarkerWebglMapObjects = Readonly<{
  marker: WebglMarkerObject | null;
  shadow: WebglOverlayMarker | null;
  labelAbove: WebglOverlayLabelWithTextBoxCallout | null;
  subMarker: WebglMarkerObject | null;
}>;

export const isMarkerEntity = (entity: WebglOverlayMarker | WebglOverlayLabelWithCallout): entity is WebglOverlayMarker =>
  entity instanceof WebGLOverlay.Marker;

const updateMapObjectPositon = (mapObject: { lat: number; lng: number} | null | undefined, newPosition: LatLng) => {
  if (mapObject) {
    mapObject.lat = newPosition.lat;
    mapObject.lng = newPosition.lng;
  }
};

const updateMarkerPosition = (marker: WebglMarkerObject | null, newPosition: LatLng) => {
  if (marker) {
    updateMapObjectPositon(marker?.entity === 'marker' ? marker.object : marker.object.label, newPosition);
  }
};

export const updatePositionOfMarkerMapObjects = (mapObjects: MarkerWebglMapObjects, newPosition: LatLng) => {
  updateMarkerPosition(mapObjects.marker, newPosition);
  updateMarkerPosition(mapObjects.subMarker, newPosition);
  updateMapObjectPositon(mapObjects.shadow, newPosition);
  updateMapObjectPositon(mapObjects.labelAbove?.label, newPosition);
};
