import { type FC } from 'react';
import { ToggleSize } from '~/_shared/baseComponents/toggle';
import { type WithRequired } from '~/_shared/utils/types/withRequired.type';
import { type MapStatusBarItem } from '~/map/statusBar/mapStatusBarItem.type';
import { useMarkersVisibilityStatusBarItem } from '../../statusBar/hooks/useMarkersVisibilityStatusBarItem';
import { MapStatusBarToggleItemComponent } from '../../statusBar/item/mapStatusBarToggleItem.component';

export const MapMarkersVisibilityToggleContainer: FC = () => {
  const markersVisibilityItems = useMarkersVisibilityStatusBarItem();

  if (!markersVisibilityItems.length) {
    return null;
  }

  return (
    <MapStatusBarToggleItemComponent
      item={markersVisibilityItems[0] as WithRequired<MapStatusBarItem, 'switch'>}
      size={ToggleSize.L}
    />
  );
};
