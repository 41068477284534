import { css } from '@emotion/react';
import { useMemo } from 'react';
import { lightTheme } from '../../../themes/light.theme';
import { type QuadrilateralDimensions } from '../../../types/coordinateSystem/coordinateSystem';
import {
  type MarkerVisualSettingsColor, type StandardMarkerSettings,
} from '../../../types/marker.types';
import { MARKER_SIZE_MAX } from '../../../utils/markers/markers.constants';
import { guaranteeHash } from '../../colorPicker/colorPicker.helpers';
import {
  ensureMaxDimensions, type MarkerTextureOffsets,
} from '../marker.helpers';

const centerChildrenStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions }) => css({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: Math.max(dimensions.height, dimensions.width),
  width: Math.max(dimensions.height, dimensions.width),
});

const referenceStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions }) => css({
  position: 'relative',
  width: dimensions.width,
  height: dimensions.height,
});

const imagePositionStyle = ({ dimensions, textureOffsets }: {dimensions: QuadrilateralDimensions; textureOffsets: MarkerTextureOffsets}) => css({
  position: 'absolute',
  height: dimensions.height,
  left: (textureOffsets.right - textureOffsets.left) / 2,
  top: (textureOffsets.bottom - textureOffsets.top) / 2,
  width: dimensions.width,
});

const imageStyle = ({ dimensions, zIndex }: { dimensions: QuadrilateralDimensions;zIndex: number}) => css({
  position: 'absolute',
  height: dimensions.height,
  width: dimensions.width,
  zIndex,
});

//TODO: this component is not currently used, but was kept in the code, in case we wanted to return to rendering the dot on number circle marker as a separate entity
//
// const positionDotStyle = (props: { zIndex: number}) => css({
//   position: 'absolute',
//   left: 0,
//   bottom: 0,
//   right: 0,
//   top: 0,
//   zIndex: props.zIndex,
// });

const colorLayerStyle = (props: {
  dimensions: QuadrilateralDimensions; path: string; color: string; opacity?: number;
}) => css({
  backgroundColor: guaranteeHash(props.color),
  maskImage: `url(${props.path})`,
  maskRepeat: 'no-repeat',
  maskSize: `${props.dimensions.width}px ${props.dimensions.height}px`,
  opacity: props.opacity ?? 1,
}, imageStyle({
  dimensions: props.dimensions,
  zIndex: 2,
}));

type StandardMarkerComponentProps = MarkerVisualSettingsColor & {
  className?: string;
  marker: StandardMarkerSettings;
  maxDimensions: QuadrilateralDimensions;
  maxSize?: number;
  maxSizeEasing?: boolean;
  size?: number;
};

export const StandardMarkerComponent: React.FC<StandardMarkerComponentProps> = props => {
  const markerSettings = props.marker.spritesheetSettings;

  const [dimensions, _, textureOffsets] = useMemo(() => ensureMaxDimensions({
    anchorOffsets: markerSettings.anchorOffsets,
    dimensions: markerSettings.dimensions,
    markerTextureOffsets: {
      bottom: props.marker.offsets.bottomOffset,
      left: props.marker.offsets.leftOffset,
      right: props.marker.offsets.rightOffset,
      top: props.marker.offsets.topOffset,
    },
    maxDimensions: props.maxDimensions,
    ...(props.size ? {
      sizeData: {
        size: props.size,
        maxSize: props.maxSize ?? MARKER_SIZE_MAX,
        applyEasing: props.maxSizeEasing,
      },
    } : {}),
  }), [markerSettings.anchorOffsets, markerSettings.dimensions, props.marker.offsets, props.maxDimensions, props.maxSize, props.maxSizeEasing, props.size]);

  //TODO: this component is not currently used, but was kept in the code, in case we wanted to return to rendering the dot on number circle marker as a separate entity
  //
  // const [centreDimensions] = useMemo(() => {
  //   if (props.marker.id === ID_OF_NUMERICAL_MARKER) {
  //     return ensureMaxDimensions({
  //       dimensions: markerSettings.dimensions,
  //       maxDimensions: { height: SIZE_OF_NUMERICAL_MARKER_CENTRE, width: SIZE_OF_NUMERICAL_MARKER_CENTRE },
  //       offsets: markerSettings.offsets,
  //     });
  //   }
  //
  //   return [undefined, undefined];
  // }, [markerSettings.dimensions, markerSettings.offsets, props.marker.id]);

  return (
    <div
      css={centerChildrenStyle({ dimensions })}
      className={props.className}
    >
      <div css={referenceStyle({ dimensions })}>
        <div css={imagePositionStyle({ dimensions, textureOffsets })}>

          {markerSettings.layerTwo && markerSettings.layerTwo !== 'null' && (
            <>
              <div
                css={(colorLayerStyle({
                  path: `${markerSettings.layerTwo}`,
                  color: lightTheme.backgroundColors.primary,
                  opacity: 0.5,
                  dimensions,
                }))}
              />
              <div
                css={colorLayerStyle({
                  path: `${markerSettings.layerTwo}`,
                  color: props.selectedColor,
                  opacity: props.opacity,
                  dimensions,
                })}
              />
            </>
          )}

          {markerSettings.layerThree && markerSettings.layerThree !== 'null' && (
            <img
              css={imageStyle({
                zIndex: 3,
                dimensions,
              })}
              alt={`standard marker ${props.marker.id} layer 3`}
              src={`${markerSettings.layerThree}`}
              height={dimensions.height}
              width={dimensions.width}
            />
          )}

          {/*TODO: this component is not currently used, but was kept in the code, in case we wanted to return to rendering the dot on number circle marker as a separate entity*/}
          {/*{props.marker.id === ID_OF_NUMERICAL_MARKER && centreDimensions && (*/}
          {/*  <div css={positionDotStyle({ zIndex: 4 })} >*/}
          {/*    <div css={centerChildrenStyle} >*/}
          {/*      <img*/}
          {/*        alt={`standard marker ${ID_OF_NUMERICAL_MARKER} centre layer`}*/}
          {/*        src={`${PATH_TO_NUMERICAL_MARKER_CENTRE}`}*/}
          {/*        height={centreDimensions.height}*/}
          {/*        width={centreDimensions.width}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}

        </div>
      </div>
    </div>
  );
};
