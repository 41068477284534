import { combineReducers } from 'redux';
import {
  mapSettingsColumnsFilterInitialState,
  mapSettingsColumnsFilterReducer,
} from '../columnsFilter/mapSettingsColumnsFilter.reducer';
import {
  mapSettingsSearchItemsInitialState,
  mapSettingsSearchItemsReducer,
} from '../searchItems/mapSettingsSearchItems.reducer';
import {
  mapSettingsToolsStateBoundaryInitialState, mapSettingsToolsStateBoundaryReducer,
} from './boundary/mapSettingsToolsStateBoundary.reducer';
import {
  mapSettingsDistanceCalculatorInitialState, mapSettingsDistanceCalculatorReducer,
} from './distanceCalculator/mapSettingsDistanceCalculator.reducer';
import {
  exportImageSettingsInitialState,
  exportImageSettingsReducer,
} from './exportImageSettings/exportImageSettings.reducer';
import {
  heatmapsToolStateInitialState,
  heatmapToolStateReducer,
} from './heatmaps/heatmapToolState.reducer';
import {
  locationFinderToolStateReducer, mapSettingsLocationFinderInitialToolsState,
} from './locationFinder/locationFinder.reducer';
import type { MapSettingsToolsState } from './mapSettingsToolsState';
import {
  mapSettingsVisibilityInitialState,
  visibilityReducer,
} from './visibility/visibility.reducer';

export const mapSettingsToolsStateInitialState: MapSettingsToolsState = {
  boundary: mapSettingsToolsStateBoundaryInitialState,
  columnsFilter: mapSettingsColumnsFilterInitialState,
  distanceCalculator: mapSettingsDistanceCalculatorInitialState,
  exportImageSettings: exportImageSettingsInitialState,
  heatmaps: heatmapsToolStateInitialState,
  searchItems: mapSettingsSearchItemsInitialState,
  visibility: mapSettingsVisibilityInitialState,
  locationFinder: mapSettingsLocationFinderInitialToolsState,
};

export const mapSettingsToolsStateReducer = combineReducers({
  boundary: mapSettingsToolsStateBoundaryReducer,
  columnsFilter: mapSettingsColumnsFilterReducer,
  distanceCalculator: mapSettingsDistanceCalculatorReducer,
  exportImageSettings: exportImageSettingsReducer,
  heatmaps: heatmapToolStateReducer,
  searchItems: mapSettingsSearchItemsReducer,
  visibility: visibilityReducer,
  locationFinder: locationFinderToolStateReducer,
});
