import { type PropsWithChildren } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import type { MemberRole } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { AppErrorPageAccessForbiddenComponent } from '../pages/errorPage/accessForbidden/appErrorPageAccessForbidden.component';

type AuthorizedRouteProps = PropsWithChildren<{
  readonly allowedRoles?: MemberRole[];
}>;

export const AuthorizedRoute = ({
  allowedRoles = [],
}: AuthorizedRouteProps) => {
  const userData = useUserDataSelector();
  const location = useLocation();

  if (!userData.isSignedIn) {
    return (
      <Routes>
        <Route
          path="/*"
          element={(
            <Navigate
              to={{ pathname: LOGIN_ROUTE }}
              state={{ from: location }}
            />
          )}
        />
      </Routes>
    );
  }

  if (allowedRoles.length && !allowedRoles.includes(userData.clientRole)) {
    return <AppErrorPageAccessForbiddenComponent />;
  }

  return (
    <Outlet />
  );
};
