import { type BoundaryTerritoryGroupsAction } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { type MapSettingsBoundariesAction } from './mapSettingsBoundaries.action';
import {
  MAP_SETTINGS_BOUNDARIES_SET_ISOLATE_COLOR,
  MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID,
} from './mapSettingsBoundaries.actionTypes';
import { type MapSettingsBoundariesState } from './mapSettingsBoundaries.state';

export const mapSettingsBoundariesInitialState: MapSettingsBoundariesState = {
  primaryBoundaryGroupId: null,
  isolateColor: '#ffffff',
};

type Action = MapSettingsBoundariesAction | BoundaryTerritoryGroupsAction;

export const mapSettingsBoundariesReducer = (state = mapSettingsBoundariesInitialState, action: Action): MapSettingsBoundariesState => {
  switch (action.type) {
    case MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID:
      return {
        ...state,
        primaryBoundaryGroupId: action.payload.primaryBoundaryGroupId,
      };

    case MAP_SETTINGS_BOUNDARIES_SET_ISOLATE_COLOR:
      return {
        ...state,
        isolateColor: action.payload.color,
      };

    default:
      return state;
  }
};
