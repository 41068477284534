import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import {
  type DrawingItemCallout, DrawingItemPlacement,
} from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingCalloutSettingsState } from '~/store/mapSettings/drawing/settings/callout/drawingCalloutSettings.state';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { type DrawingToolManager } from '../drawingToolManager';
import { getDrawingToolTexAreaFullSize } from '../textAreaCommon/useDrawingToolTextAreaSize';

type DrawingToolCalloutCreateNewInstanceProps = Readonly<{
  settings: DrawingCalloutSettingsState;
  manager: DrawingToolManager;
}>;

export const DrawingToolCalloutCreateNewInstanceContainer: FC<DrawingToolCalloutCreateNewInstanceProps> = ({
  settings, manager,
}) => {
  const dispatch = useDispatch();
  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    if (!e.latLng) {
      return;
    }

    const id = createUuid();

    let textBoxDimensions = getDrawingToolTexAreaFullSize({
      widthWithoutPadding: 5 * settings.fontSize,
      heightWithoutPadding: settings.fontSize,
      borderRadius: settings.borderRadius,
      strokeWidth: settings.strokeWeight,
    });

    const newInstanceSettings = { ...settings };

    if (settings.scalesWithMapZoom) {
      const currentZoom = manager.getZoom() || 0;

      textBoxDimensions = {
        width: convertPxDistanceToDifferentZoom({ distance: textBoxDimensions.width, originalZoom: currentZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS }),
        height: convertPxDistanceToDifferentZoom({ distance: textBoxDimensions.height, originalZoom: currentZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS }),
      };

      newInstanceSettings.fontSize = convertPxDistanceToDifferentZoom({ distance: newInstanceSettings.fontSize, originalZoom: currentZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS });
    }

    const newInstance: DrawingItemCallout = {
      id,
      startPoint: googleLatLngToLocal(e.latLng),
      placement: DrawingItemPlacement.Default,
      settings: newInstanceSettings,
      textBoxDimensions,
      text: '',
    };

    dispatch(drawingItemsAddItem(id, {
      type: DrawingTool.Callout,
      value: newInstance,
    }));

    dispatch(drawingEditSetSelectedDrawing(
      newInstance.id,
      {
        type: DrawingTool.Callout,
        value: newInstance,
      })
    );
  }, [dispatch, manager, settings]);

  useEffect(() => {
    const listener = manager.addMapClickListener(onMapClick);

    return () => listener.remove();
  }, [manager, onMapClick]);

  return null;
};
