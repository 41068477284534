import { BoundaryCreateOptimizedStatus } from '../../../../store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import { type TranslationFnc } from '../../../utils/hooks';

export const getOptimizedTerritoriesProgressForStatus = (status: BoundaryCreateOptimizedStatus): number => {
  switch (status) {
    case BoundaryCreateOptimizedStatus.ValidatingRequest:
      return 5;
    case BoundaryCreateOptimizedStatus.ProcessingStarted:
      return 20;
    case BoundaryCreateOptimizedStatus.InitializingTerritories:
      return 30;
    case BoundaryCreateOptimizedStatus.BalancingTerritories:
      return 40;
    case BoundaryCreateOptimizedStatus.RebalancingTerritories:
      return 50;
    case BoundaryCreateOptimizedStatus.FinalizingTheBalancingOfTerritories:
      return 60;
    case BoundaryCreateOptimizedStatus.ProcessingFinished:
      return 80;
    case BoundaryCreateOptimizedStatus.Finished:
      return 100;
    default:
      return 0;
  }
};

export const getOptimizedTerritoryStatusTranslation = (status: BoundaryCreateOptimizedStatus, t: TranslationFnc): string => {
  switch (status) {
    case BoundaryCreateOptimizedStatus.ProcessingStarted:
      return t('Processing Started');
    case BoundaryCreateOptimizedStatus.ValidatingRequest:
      return t('Validating Request');
    case BoundaryCreateOptimizedStatus.InitializingTerritories:
      return t('Initializing Territories');
    case BoundaryCreateOptimizedStatus.BalancingTerritories:
      return t('Balancing Territories');
    case BoundaryCreateOptimizedStatus.RebalancingTerritories:
      return t('Rebalancing Territories');
    case BoundaryCreateOptimizedStatus.FinalizingTheBalancingOfTerritories:
      return t('Finalizing the Balancing of Territories');
    case BoundaryCreateOptimizedStatus.ProcessingFinished:
      return t('Receiving finalized Territories');
    case BoundaryCreateOptimizedStatus.Finished:
      return t('Processing Finished');
    default:
      return '';
  }
};
