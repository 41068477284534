import {
  faCheck, faTimes, faWarning,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import { useQuery } from '~/_shared/utils/hooks/useQuery';
import {
  useIsUserSignedInSelector, useUserCurrentClientSelector,
} from '~/store/userData/userData.selectors';
import {
  HOME_ROUTE, LOGIN_ROUTE,
} from '../../_shared/constants/routes';
import { isPaidLicense } from '../../_shared/types/client/license';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  InvitationPageComponent, InvitationResultStatus, type LinkInfo, UIType,
} from './invitationPage.component';
import {
  InvitationAcceptErrorCodes, InviteStatus,
} from './invitationPage.helpers';
import {
  useAcceptInvitation, useGetInvitationData, useRejectInvitation,
} from './useInvitation';

export const InvitationPageContainer: FC = () => {
  const [t] = useTranslation();

  const query = useQuery();
  const email = query.get('email') || '';
  const hash = query.get('hash') || '';
  const signature = query.get('signature') || '';
  const expires = query.get('expires') || '';

  const [inviteStatus, setInviteStatus] = useState<InviteStatus>(InviteStatus.PENDING);

  const isUserSignedIn = useIsUserSignedInSelector();
  const currentClient = useUserCurrentClientSelector();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isLoading: isGettingInvitationData,
    error: getInvitationError,
    data: invitationData,
    invokeAjax: getInvitationData,
  } = useGetInvitationData(email, hash, signature, expires);

  const {
    invoke: acceptInvitation,
    error: acceptInvitationError,
    isLoading: isAcceptingInvitation,
  } = useAcceptInvitation();

  const {
    invoke: rejectInvitation,
    isLoading: isRejectingInvitation,
  } = useRejectInvitation();

  const handleAcceptInvitation = useCallback(async (password?: string, passwordConfirmation?: string) => {
    await acceptInvitation(email, hash, signature, expires, password, passwordConfirmation);
    setInviteStatus(InviteStatus.ACCEPTED);
  }, [acceptInvitation, email, expires, hash, signature]);

  const handleRejectInvitation = useCallback(() => {
    rejectInvitation(email, hash, signature, expires)
      .then(() => setInviteStatus(InviteStatus.REJECTED));
  }, [email, expires, hash, rejectInvitation, signature]);

  useEffect(() => {
    getInvitationData();
  }, [getInvitationData]);

  const defaultLinkInfo: LinkInfo = useMemo(() => {
    if (isUserSignedIn) {
      return {
        onClick: () => {
          navigate(HOME_ROUTE);
        },
        text: t('To Homepage'),
      };
    }
    else if (getInvitationError?.errorCode === InvitationAcceptErrorCodes.USER_MISMATCH) {
      return {
        onClick: () => {
          navigate(LOGIN_ROUTE, { state: { from: location } });
        },
        text: t('Continue to Login'),
      };
    }
    else {
      return {
        onClick: () => {
          navigate(LOGIN_ROUTE, { state: { from: HOME_ROUTE } });
        },
        text: t('Back to Login'),
      };
    }
  }, [getInvitationError?.errorCode, isUserSignedIn, location, navigate, t]);

  const uiInfo = useMemo(() => {
    if (isGettingInvitationData) {
      return {
        type: UIType.Loading,
      };
    }

    if (inviteStatus === InviteStatus.PENDING) {
      if (getInvitationError) {
        let text: string = '';
        switch (getInvitationError.errorCode) {
          case InvitationAcceptErrorCodes.EXPIRED:
            text = t('inviteeInvitation.expired');
            break;
          case InvitationAcceptErrorCodes.USER_MISMATCH:
            text = isUserSignedIn ? t('inviteeInvitation.notMeantForLoggedUser') : t('inviteeInvitation.existingUserMustLogIn');
            break;
          case InvitationAcceptErrorCodes.ACCEPTED:
            text = t('inviteeInvitation.accepted');
            break;
          case InvitationAcceptErrorCodes.REJECTED:
            text = t('inviteeInvitation.rejected');
            break;
          case InvitationAcceptErrorCodes.SERVER_ERROR:
          default:
            text = t('inviteeInvitation.unknownError');
        }
        return {
          type: UIType.InvitationResult,
          invitationResult: {
            icon: faTimes,
            linkInfo: defaultLinkInfo,
            status: InvitationResultStatus.Danger,
            text,
          },
        };
      }

      if (!getInvitationError && invitationData) {
        if (currentClient && isPaidLicense(currentClient.license.type)) {
          return {
            type: UIType.UnderPaidClientInvitation,
          };
        }
        if (currentClient && !isPaidLicense(currentClient.license.type)) {
          return {
            type: UIType.TrialUserInvitation,
          };
        }
        if (invitationData && invitationData.client && !isUserSignedIn) {
          return {
            type: UIType.NewUserInvitation,
          };
        }
      }

      return {
        type: UIType.Loading,
      };
    }

    if (inviteStatus === InviteStatus.ACCEPTED) {
      return {
        type: UIType.InvitationResult,
        invitationResult: {
          icon: faCheck,
          linkInfo: defaultLinkInfo,
          status: InvitationResultStatus.Success,
          text: isUserSignedIn ? t('Invitation accepted!') : t('Your account has been created. Please login to continue.'),
        },
      };
    }

    if (inviteStatus === InviteStatus.REJECTED) {
      return {
        type: UIType.InvitationResult,
        invitationResult: {
          icon: faCheck,
          linkInfo: defaultLinkInfo,
          status: InvitationResultStatus.Normal,
          text: t('Invitation successfully declined!'),
        },
      };
    }

    return {
      type: UIType.InvitationResult,
      invitationResult: {
        icon: faWarning,
        linkInfo: defaultLinkInfo,
        status: InvitationResultStatus.Normal,
        text: t('inviteeInvitation.unknownError'),
      },
    };
  }, [currentClient, defaultLinkInfo, getInvitationError, invitationData, inviteStatus,
    isGettingInvitationData, isUserSignedIn, t]);

  return (
    <InvitationPageComponent
      invitation={isGettingInvitationData ? null : invitationData}
      invitationResult={uiInfo.invitationResult}
      isAcceptingInvitation={isAcceptingInvitation}
      isRejectingInvitation={isRejectingInvitation}
      uiType={uiInfo.type}
      rejectInvitation={handleRejectInvitation}
      acceptInvitation={handleAcceptInvitation}
      acceptInvitationError={acceptInvitationError?.message || ''}
    />
  );
};
