import { type DeepPartial } from 'ts-essentials';
import { type LottieAnimations } from './animations/lottieAnimations';

export const LottieAnimationTypes = {
  ArrowSpinner: 'ArrowSpinner',
  CaretDown: 'CaretDown',
  CheckingPlatform: 'CheckingPlatform',
  Chevron: 'Chevron',
  CircleArrowDown: 'CircleArrowDown',
  CircleDownload: 'CircleDownload',
  Copy: 'Copy',
  Delete: 'Delete',
  DotSpinner: 'DotSpinner',
  DoubleDownArrow: 'DoubleDownArrow',
  DrippingSpinner: 'DrippingSpinner',
  Failed: 'Failed',
  Filter: 'Filter',
  GradientLimitIndicator: 'GradientLimitIndicator',
  Layer: 'Layer',
  LocationPin3D: 'LocationPin3D',
  MailChecked: 'MailChecked',
  People: 'People',
  Saving: 'Saving',
  ScrollDownArrow: 'ScrollDownArrow',
  SearchSonar: 'SearchSonar',
  Spinner: 'Spinner',
  Success: 'Success',
  UploadingImage: 'UploadingImage',
  Confetti: 'Confetti',
  SmudgeDog: 'SmudgeDog',
} as const;

export type LottieAnimationType = typeof LottieAnimationTypes[keyof typeof LottieAnimationTypes];

export type LottieAnimationViewDetails = {
  canvasWidth: number;
  canvasHeight: number;
  iconWidth: number;
  iconHeight: number;
  offsetTop?: number;
  offsetLeft?: number;
};

export type LottieAnimationGradientDetails = {
  startOpacity?: number;
  endOpacity?: number;
  offset?: number;
  color: string;
};

export type LottieAnimationSegment = Readonly<[number, number]>;
export type LottieJsonConfig = {
  type: LottieAnimationType;
  view: LottieAnimationViewDetails;
  colors: Record<string, string>;
  gradients: Record<string, LottieAnimationGradientDetails[]>;
  getJson: () => Promise<unknown>;
};

export type LottieAnimationConfig = {
  [AnimationType in keyof typeof LottieAnimations]: {
    type: AnimationType;
    colors?: Partial<typeof LottieAnimations[AnimationType]['colors']>;
    gradients?: DeepPartial<typeof LottieAnimations[AnimationType]['gradients']>;
  };
}[keyof typeof LottieAnimations];
