import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { type Resolution } from '../baseMapDeleteDependenciesResolve.helper';

type ResolutionComponentProps = {
  isDisabled: boolean;
  selectedOption: Resolution;
  resolutionOptions: DropdownOption<Resolution>[];

  onSelect: (option: Resolution) => void;
};

const dropdownTriggerStyle = css({
  minWidth: 240,
});

export const ResolutionComponent: FC<ResolutionComponentProps> = (props) => {
  const { onSelect, selectedOption } = props;

  return (
    <div>
      <RegularDropdownComponent
        triggerStyle={dropdownTriggerStyle}
        value={selectedOption}
        onChange={onSelect}
        options={props.resolutionOptions}
        isDisabled={props.isDisabled}
        inPortal
      />
    </div>
  );
};
