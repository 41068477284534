import { css } from '@emotion/react';
import { type FC } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { InputWithSpinnersComponent } from '~/_shared/baseComponents/inputs';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerAlignment } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';
import { ColorPickerWithColorTileComponent } from '~/_shared/components/colorPicker/colorPickerWithColorTile/colorPickerWithColorTile.component';
import { ColorPickerWithWheelComponent } from '~/_shared/components/colorPicker/colorPickerWithWheel/colorPickerWithWheel.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE, DRAWING_TOOL_SLIDER_PERCENTAGE_SUFFIX, DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
  type DrawingTool, drawingToolEditableBooleanOptionsName, drawingToolEditableColorOptionsName,
  drawingToolEditableOtherOptionsName, drawingToolEditableSliderOptionsName,
} from '../drawingTool.enums';
import {
  type DrawingToolBooleanOptionName, type DrawingToolBooleanOptions, type DrawingToolColorOptionName,
  type DrawingToolColorOptions, type DrawingToolOptions, type DrawingToolOtherOptionNames, type DrawingToolOtherOptions,
  type DrawingToolScaledSliderOptionName, type DrawingToolSliderOptionName,
} from '../drawingTool.types';
import { useDrawingToolSizePerPixelRatio } from '../hooks/useDrawingToolSizePerPixelRatio';
import { DrawingToolIconMarkerOptions } from './specialOptionPanels/iconMarker/drawingToolIconMarkerOptions.component';
import { DrawingToolImageOptionsContainer } from './specialOptionPanels/image/drawingToolImageOptions.container';

const COLOR_TILE_DIMENSION = 16;

const optionsWrapperStyle = ({ padding }: { padding: number }) => css({ padding });
const numberStyle = css({ marginBottom: '16px' });
const numberLabelStyle = css({ marginBottom: '8px' });

const checkmarkStyle = ({ theme }: ThemeProps) => css({
  borderColor: theme.lineColors.blue,
  height: '20px',
  width: '20px',
});

const checkboxLabelStyle = css({
  marginLeft: '6px',
  fontSize: '14px',
  fontWeight: 500,
});

const sliderOptionsStyle = css({
  cursor: 'default',
  fontSize: '14px',
  fontWeight: 500,
});

const colorOptionsStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'default',
  padding: '16px 0',
});

const pickerWithWheelStyle = css({
  width: '22px',
  height: '22px',
});

const pickerWithTileStyle = css({
  width: '150px',
});

const checkboxStyle = css({
  paddingBottom: '22px',
});

const sliderStyle = css({
  padding: '8px 0',
});

const sliderLabelStyle = css({
  minWidth: 36,
  textAlign: 'right',
  flexShrink: 0,
});

type DrawingToolOptionsProps = Readonly<{
  selectedTool: DrawingTool;
  toolOptions: Partial<DrawingToolOptions>;
  initialFileAttachmentId?: string;

  onToolOptionsChange: (options: Partial<DrawingToolOptions>) => void;
  onFilesLoadingChange: (filesLoading: boolean) => void;
}>;

export const DrawingToolOptionsComponent: FC<DrawingToolOptionsProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const {
    otherOptions,
    colorOptions,
    booleanOptions,
    sliderOptions,
    scaledSliderOptions,
  } = props.toolOptions;
  const panelPadding = 16;

  const handleSetBooleanOptions = (options: Partial<DrawingToolBooleanOptions>): void =>
    props.onToolOptionsChange({ booleanOptions: options });

  const handleSetColorOptions = (options: Partial<DrawingToolColorOptions>): void =>
    props.onToolOptionsChange({ colorOptions: options });

  const handleSetSliderOptions = (name: DrawingToolSliderOptionName, newValue: number) => {
    props.onToolOptionsChange({
      sliderOptions: {
        [name]: {
          ...props.toolOptions.sliderOptions?.[name] ?? {},
          value: newValue,
        },
      },
    });
  };

  const handleSetScaledSliderOptions = (name: DrawingToolScaledSliderOptionName, newDisplayValue: number) => {
    props.onToolOptionsChange({
      scaledSliderOptions: {
        [name]: {
          ...props.toolOptions.scaledSliderOptions?.[name] ?? {},
          value: newDisplayValue * sizePerPixelRatio,
        },
      },
    });
  };

  const handleSetOtherOptions = (options: Partial<DrawingToolOtherOptions>): void => {
    props.onToolOptionsChange({ otherOptions: options });
  };

  const setNumericalOptions = (
    value: string | number,
    toolOption: DrawingToolOtherOptionNames,
    min: number = Number.MIN_VALUE,
    max: number = Number.MAX_VALUE
  ): void => {
    value = value.toString();
    const maxValueLength = min.toString().length;
    const minValueLength = max.toString().length;

    if (value.startsWith('-') && value.length > minValueLength) {
      // if number is too long then replace the last char of valid number with lastly used number
      const lastChar = value.charAt(value.length - 1);
      value = value.slice(0, minValueLength - 1);
      value += lastChar;
    }
    else if (!value.startsWith('-') && value.length > maxValueLength) {
      const lastChar = value.charAt(value.length - 1);
      value = value.slice(0, maxValueLength - 1);
      value += lastChar;
    }

    const newNumber = Number(value);

    if (!isNaN(newNumber)) {
      handleSetOtherOptions({ [toolOption]: { value: newNumber } });
    }
  };

  return (
    <div css={optionsWrapperStyle({ padding: panelPadding })}>
      {otherOptions?.number !== undefined && (
        <div css={numberStyle}>
          <div css={numberLabelStyle}>
            {drawingToolEditableOtherOptionsName(t).number}
          </div>

          <InputWithSpinnersComponent
            value={otherOptions.number.value}
            valueMin={otherOptions.number?.min}
            valueMax={otherOptions.number?.max}
            onChange={(value: number) => setNumericalOptions(value, 'number', otherOptions.number?.min, otherOptions.number?.max)}
          />
        </div>
      )}

      {booleanOptions && Object.keys(booleanOptions)
        .filter((name: DrawingToolBooleanOptionName) => booleanOptions[name] !== undefined)
        .map((name: DrawingToolBooleanOptionName) => (
          <CheckboxComponent
            checkedSetter={() => handleSetBooleanOptions({ [name]: !booleanOptions[name] })}
            checkmarkStyle={checkmarkStyle({ theme })}
            css={checkboxStyle}
            isChecked={!!booleanOptions[name]}
            key={name}
            text={drawingToolEditableBooleanOptionsName(t)[name]}
            textStyle={checkboxLabelStyle}
          />
        ))}

      {sliderOptions && Object.keys(sliderOptions)
        .filter((name: DrawingToolSliderOptionName) => sliderOptions[name] !== undefined)
        .map((name: DrawingToolSliderOptionName) => {
          const item = sliderOptions[name];
          const min = item?.range?.from ?? DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE.from;
          const max = item?.range?.to ?? DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE.to;
          const value = item?.value ?? 0;

          return (
            <div key={name}>
              <div css={sliderOptionsStyle}>
                {drawingToolEditableSliderOptionsName(t)[name]}
              </div>
              <SliderWithValueLabelsComponent
                labelStyleCommon={sliderLabelStyle}
                withDots={max - min <= 10}
                css={sliderStyle}
                currentValueOnTheRight
                onChange={([value]) => handleSetSliderOptions(name, value)}
                value={[value]}
                valueSuffix={item?.suffix ?? DRAWING_TOOL_SLIDER_PERCENTAGE_SUFFIX}
                min={min}
                max={max}
              />
            </div>
          );
        })
      }

      {scaledSliderOptions && Object.keys(scaledSliderOptions)
        .filter((name: DrawingToolScaledSliderOptionName) => scaledSliderOptions[name] !== undefined)
        .map((name: DrawingToolScaledSliderOptionName) => {
          const item = scaledSliderOptions[name];

          const rangeMin = item?.normalizedRange?.from ?? DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE.from;
          const rangeMax = item?.normalizedRange?.to ?? DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE.to;
          let displayValue = item?.displayValue ?? 0;

          if (item?.ensureValueInRange) {
            displayValue = clamp(displayValue, { min: rangeMin, max: rangeMax });
          }

          return (
            <div key={name}>
              <div css={sliderOptionsStyle}>
                {drawingToolEditableSliderOptionsName(t)[name]}
              </div>
              <SliderWithValueLabelsComponent
                css={sliderStyle}
                currentValueOnTheRight
                onChange={([value]) => handleSetScaledSliderOptions(name, value)}
                value={[displayValue]}
                valueSuffix={item?.suffix ?? DRAWING_TOOL_SLIDER_PIXEL_SUFFIX}
                min={Math.min(rangeMin, displayValue)}
                max={Math.max(rangeMax, displayValue)}
              />
            </div>
          );
        })
      }

      {colorOptions && Object.keys(colorOptions)
        .filter((name: DrawingToolColorOptionName) => colorOptions[name] !== undefined)
        .map((name: DrawingToolColorOptionName) => {
          const selectedColor = createColor(colorOptions[name] || '');
          const onChange = (color: ColorResult) => handleSetColorOptions({ [name]: guaranteeHash(color.hex) });
          return (
            <div
              key={name}
              css={colorOptionsStyle}
            >
              <div css={pickerWithTileStyle}>
                <ColorPickerWithColorTileComponent
                  displayAlpha={false}
                  height={COLOR_TILE_DIMENSION}
                  isFixed
                  label={drawingToolEditableColorOptionsName(t)[name]}
                  onChange={onChange}
                  selectedColor={selectedColor}
                  width={COLOR_TILE_DIMENSION}
                  alignment={ColorPickerAlignment.BottomLeft}
                />
              </div>
              <div css={pickerWithWheelStyle}>
                <ColorPickerWithWheelComponent
                  selectedColor={selectedColor}
                  onChange={onChange}
                  displayAlpha={false}
                  isFixed
                  alignment={ColorPickerAlignment.BottomRight}
                />
              </div>
            </div>
          );
        })
      }

      {otherOptions?.icon !== undefined && (
        <DrawingToolIconMarkerOptions
          onChangeSelect={(id: string) => handleSetOtherOptions({
            icon: id,
          })}
          selectedIconId={otherOptions.icon}
        />
      )}

      {otherOptions?.image !== undefined && (
        <DrawingToolImageOptionsContainer
          onImageChanged={() => handleSetOtherOptions({})}
          onFilesLoadingChange={props.onFilesLoadingChange}
          initialFileAttachmentId={otherOptions.image}
        />
      )}
    </div>
  );
};
