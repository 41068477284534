import {
  type FC, useCallback, useMemo,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { ExportImageLocationListMode } from '../../types/exportImage/exportImage';
import { useTranslation } from '../../utils/hooks';
import { OptionsDropdownComponent } from './optionsDropdown.component';

export type LocationListModeSelectionProps = Readonly<{
  availableOptions: ExportImageLocationListMode[];
  selectedOption: ExportImageLocationListMode | null;

  onSelectOption: (option: ExportImageLocationListMode) => void;
}>;

export const LocationListModeSelectionComponent: FC<LocationListModeSelectionProps> = props => {
  const [t] = useTranslation();

  const getModeName = useCallback((mode: ExportImageLocationListMode) => {
    switch (mode) {
      case ExportImageLocationListMode.AllFiltered:
        return t('All Filtered Locations');
      case ExportImageLocationListMode.FilteredInBounds:
        return t('Locations Currently Visible On The Map');
      default:
        return '';
    }
  }, [t]);

  const options: DropdownOption<ExportImageLocationListMode>[] = useMemo(() =>
    props.availableOptions.map((mode: ExportImageLocationListMode) => ({
      value: ExportImageLocationListMode[mode],
      name: getModeName(mode),
    })), [getModeName, props.availableOptions]);

  return (
    <OptionsDropdownComponent
      onSelectOption={props.onSelectOption}
      availableOptions={options}
      selectedOption={props.selectedOption || ExportImageLocationListMode.FilteredInBounds}
      width={365}
    />
  );
};
