import { css } from '@emotion/react';
import {
  faCheck, faGlobe,
} from '@fortawesome/pro-solid-svg-icons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { type Theme } from '~/_shared/themes/theme.model';
import { ModalComponent } from '../../../../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../../../../_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '../../../../../_shared/utils/hooks';

type BoundarySetNameProps = {
  filledName: string;
  submitDisabled: boolean;
  isLoading?: boolean;
  title: string;
  buttonLabel: string;

  onSubmit: () => void;
  onClose: () => void;
  onUpdateName: (newName: string) => void;
};

const wrapperStyle = css({
  padding: '24px 16px',
});

const modalContentStyle = css({
  padding: 0,
});

const warningWrapperStyle = (theme: Theme) => css({
  marginTop: 24,
  color: theme.textColors.warning,
});

export const BoundarySetNameComponent: React.FC<BoundarySetNameProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen
      caption={props.title}
      confirmButton={(
        <ButtonComponent
          text={props.buttonLabel}
          isDisabled={props.submitDisabled}
          prefixIcon={faCheck}
          onClick={props.onSubmit}
        />
      )}
      contentStyle={modalContentStyle}
      additionalContent={props.isLoading &&
        <OverlayLoaderComponent />}
    >
      <div css={wrapperStyle}>
        <FormTextInputComponent
          label={t('Name Territory Group')}
          onChange={props.onUpdateName}
          value={props.filledName}
          icon={faGlobe}
        />

        <div css={warningWrapperStyle}>
          {t('Note: You are about to convert your fill groups to a territory group. The original boundaries / territories will get removed from your map by doing so. You can re-add them any time.')}
        </div>
      </div>
    </ModalComponent>
  );
};
