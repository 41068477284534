import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { noop } from '~/_shared/utils/function.helpers';
import {
  mapObjectEntities, type MapObjectZIndex,
} from '~/map/map/mapObjects/mapObject.types';
import { xMarkMarkerOffsets } from '~/map/map/mapObjects/private/mapObjectManager';
import { useMapObjectManager } from '~/map/map/mapObjects/private/useMapObjectManager';
import { useZIndexSeries } from '~/map/zIndexes/useZIndexSeries.hook';
import { ZIndexedEntity } from '~/map/zIndexes/zIndexRanges';
import {
  hoverMoveMarkerLabelsXMarkButton, stopMoveMarkerLabelsXMarkButtonHover,
} from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionCreators';

const idMapper = (entity: string) => entity;
const useXMarkZIndex = (): MapObjectZIndex => {
  const orderedEntities = useMemo(() => ['MoveMarkerLabelsXMark'], []);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.MoveMarkerLabelsXMark,
  }), [orderedEntities]);

  return entities.subEntityZIndexes[0];
};

type XMarkParams = {
  anchor?: LatLng;
  onXMarkClick: () => void;
};

export const useMoveMarkerLabelsXMark = ({ anchor, onXMarkClick }: XMarkParams) => {
  const mapObjectManager = useMapObjectManager();
  const [xMarkHovered, setXMarkHovered] = useState(false);
  const [xMarkId] = useState(createUuid());
  const dispatch = useDispatch();

  const zIndex = useXMarkZIndex();

  const onMouseOut = useCallback(() => {
    setXMarkHovered(false);
    dispatch(stopMoveMarkerLabelsXMarkButtonHover());
  }, [dispatch]);

  const cleanup = () => {
    mapObjectManager.removeXMarkMarker(xMarkId);
    onMouseOut();
  };
  const cleanUpRef = useRef(cleanup);
  cleanUpRef.current = cleanup;

  // upsert xmark when anchor is provided
  useEffect(() => {
    if (anchor) {
      const color: WebglColor = xMarkHovered ? [0, 0, 255, 1] : [0, 0, 0, 1];
      mapObjectManager.upsertXMarkMarker(xMarkId, anchor, zIndex.removeButton, xMarkMarkerOffsets.topRight, color);
    }
  }, [anchor, mapObjectManager, xMarkHovered, xMarkId, zIndex.removeButton]);

  // remove xmark when there is no anchor
  useEffect(() => {
    if (!anchor) {
      cleanUpRef.current();
    }
  }, [anchor]);

  // update mouse events
  useEffect(() => {
    if (anchor) {
      const cleanMouseOver = mapObjectManager.addXMarkMarkerEventListener(xMarkId, 'mouseover', () => {
        setXMarkHovered(true);
        dispatch(hoverMoveMarkerLabelsXMarkButton());
      });

      const cleanMouseOut = mapObjectManager.addXMarkMarkerEventListener(xMarkId, 'mouseout', onMouseOut);
      const cleanMouseClick = mapObjectManager.addXMarkMarkerEventListener(xMarkId, 'click', onXMarkClick);

      return () => {
        cleanMouseOver();
        cleanMouseOut();
        cleanMouseClick();
      };
    }

    return noop;
  }, [anchor, dispatch, mapObjectManager, onMouseOut, onXMarkClick, xMarkId]);

  // unmount
  useEffect(() => {
    return () => {
      cleanUpRef.current();
    };
  }, []);
};
