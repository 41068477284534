import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { matchUpDataAllDataSections } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { useIntercom } from '~/_shared/utils/3rdPartyIntegrations/intercom/intercom.context';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { useLogOutUser } from '~/authorization/logOutUser.hook';
import { ExportDataSection } from '~/data/exportDataModal/exportDataSection.enum';
import { useSyncGoogleSheet } from '~/data/syncData/useSyncGoogleSheet.hook';
import { getRelativeMapPresentationalUrl } from '~/map/map.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  openMapTool, openMapToolExclusively,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useIsPresentationalMenuShownSelector } from '~/store/frontendState/mapPresentational/mapPresentational.selectors';
import { useSpreadsheetTableSelector } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { useSpreadsheetTableRowsDelete } from '~/store/frontendState/spreadsheetTable/useSpreadsheetTableRowsDelete';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import {
  userSettingsSplitPanelClose, userSettingsSplitPanelOpen,
} from '~/store/userData/settings/userDataSettings.actionCreators';
import { useSplitItemSettingsSelector } from '~/store/userData/settings/userDataSettings.selectors';
import { MainMenuComponent } from './mainMenu.component';
import {
  type MainMenuItem, MainMenuItems,
} from './mainMenuItem.enum';

type MainMenuContainer = Readonly<{
  menuHeight: number;
  selectedItem: MainMenuItem | null;
  visibleMenuItems: ReadonlySet<MainMenuItem>;

  onItemClick: (newApp: MainMenuItem) => void;
}>;

export const MainMenuContainer: FC<MainMenuContainer> = (props) => {
  const dispatch = useDispatch();
  const isMapInfoLoading = useSelector(s => s.map.mapInfo.isLoading || typeof s.map.mapInfo.data?.id !== 'number');
  const isPresentationalMenuOpen = useIsPresentationalMenuShownSelector();
  const mapShareId = useSelector(state => state.map.mapInfo.data?.shareId);
  const { openModal: openImportDataModal } = useModal(ModalType.ImportData);
  const { openModal: openMatchLocationModal } = useModal(ModalType.MatchupData);
  const { openModal: openAddNewDataColumnModal } = useModal(ModalType.AddDataColumn);
  const { openModal: openAddNewDataRowModal } = useModal(ModalType.AddMultipleLocations);
  const { openModal: openExportDataModal } = useModal(ModalType.ExportSpreadsheetData);
  const syncGoogleSheet = useSyncGoogleSheet();
  const spreadsheetTableState = useSpreadsheetTableSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const isViewPresentational = useIsMapPresentationalSelector();
  const logOutUser = useLogOutUser();
  const deleteRows = useSpreadsheetTableRowsDelete();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const panelItemSettings = useSplitItemSettingsSelector(clientId, mapId);
  const { show: showIntercom } = useIntercom();
  const isSplitScreenActive = panelItemSettings.isActive;

  const { onItemClick } = props;

  const onSplitClick = useCallback(() => {
    if (clientId === null || mapId === null) {
      return;
    }

    if (isSplitScreenActive) {
      dispatch(userSettingsSplitPanelClose(clientId, mapId));
    }
    else {
      dispatch(userSettingsSplitPanelOpen(clientId, mapId));
    }
  }, [dispatch, clientId, isSplitScreenActive, mapId]);

  const openExportSpreadsheetDataModal = useCallback(() => {
    openExportDataModal({
      availableExportSections: [ExportDataSection.FilteredTableSearch],
    });
  }, [openExportDataModal]);

  const additionalOnClickHandlers: Partial<{ [key in MainMenuItem]: () => void }> = useMemo(() => ({
    SplitScreen: onSplitClick,
    LogOut: logOutUser,
    Present: () => mapShareId && openUrlInNewTab(getRelativeMapPresentationalUrl(mapShareId)),
    FilterData: () => dispatch(openMapTool(MapTool.FilterData)),
    ImportNewData: () => openImportDataModal({}),
    MatchMyLocationData: () => openMatchLocationModal({
      ensureLocationDataIsSelected: false,
      sections: matchUpDataAllDataSections,
    }),
    AddNewDataColumn: openAddNewDataColumnModal,
    AddNewDataRow: openAddNewDataRowModal,
    ExportData: openExportSpreadsheetDataModal,
    SyncData: () => syncGoogleSheet({}),
    RemoveDataRow: deleteRows,
    Boundary: () => dispatch(openMapToolExclusively(MapTool.Boundary)),
    Proximity: () => dispatch(openMapToolExclusively(MapTool.Proximity)),
    Filter: () => dispatch(openMapToolExclusively(MapTool.Filter)),
    Grouping: () => dispatch(openMapToolExclusively(MapTool.Grouping)),
    HeatMap: () => dispatch(openMapToolExclusively(MapTool.HeatMap)),
    MapLegend: () => dispatch(openMapToolExclusively(MapTool.MapLegend)),
    Routing: () => dispatch(openMapToolExclusively(MapTool.Routing)),
    Places: () => dispatch(openMapToolExclusively(MapTool.Places)),
  }), [logOutUser, openAddNewDataColumnModal, openAddNewDataRowModal, openExportSpreadsheetDataModal, deleteRows,
    dispatch, mapShareId, openImportDataModal, openMatchLocationModal, syncGoogleSheet, onSplitClick]);

  const additionalOnItemClick = useCallback((item: MainMenuItem): void => {
    onItemClick(item);
    additionalOnClickHandlers[item]?.();
  }, [additionalOnClickHandlers, onItemClick]);

  const additionalSelectedItems: ReadonlyArray<MainMenuItem> = useMemo(() => ([
    [MainMenuItems.SplitScreen, isSplitScreenActive],
  ] as const)
    .filter(([, isSelected]) => isSelected)
    .map(([item]) => item)
  , [isSplitScreenActive]);

  const selectedItems = useMemo(() =>
    new Set(props.selectedItem ? [props.selectedItem, ...additionalSelectedItems] : additionalSelectedItems),
  [additionalSelectedItems, props.selectedItem]);

  const disabledItems = useMemo(() => {
    const items = new Set<MainMenuItem>();

    if (isMapInfoLoading) {
      items.add(MainMenuItems.ShareMap);
    }

    if (spreadsheetTableState.isRemoveInProgress || Object.keys(spreadsheetTableState.selectedRows).length === 0) {
      items.add(MainMenuItems.RemoveDataRow);
    }

    return items;
  }, [isMapInfoLoading, spreadsheetTableState.isRemoveInProgress, spreadsheetTableState.selectedRows]);

  const dangerItems = new Set<MainMenuItem>([MainMenuItems.RemoveDataRow]);

  if (isMobileScreen) {
    return null;
  }

  return (
    <MainMenuComponent
      dangerMenuItems={dangerItems}
      disabledItems={disabledItems}
      isPresentationalMenuOpen={isPresentationalMenuOpen}
      isViewPresentational={isViewPresentational}
      menuHeight={props.menuHeight}
      onItemClick={additionalOnItemClick}
      selectedItems={selectedItems}
      visibleMenuItems={props.visibleMenuItems}
      openChat={showIntercom}
    />
  );
};
