import { css } from '@emotion/react';
import { type Property } from 'csstype';
import {
  createContext,
  type FC, useContext, useMemo, useState,
} from 'react';

type CursorOverlayStackItem = { transactionId: string; cursor: Property.Cursor };

export type CursorOverlayContextModel = {
  push: (transactionId: string, cursor: Property.Cursor) => void;
  pop: (transactionId: string) => void;
};

const ReactCursorOverlayContext = createContext<CursorOverlayContextModel|null>(null);

const cursorOverlayStyle = (cursor: Property.Cursor) => css({
  position: 'absolute',
  zIndex: 5000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  cursor,
});

export const CursorOverlayContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [stack, setStack] = useState<CursorOverlayStackItem[]>([]);

  const context = useMemo(() => {
    return {
      push: (transactionId: string, cursor: Property.Cursor) =>
        setStack(previous => [...previous.filter(i => i.transactionId !== transactionId), { transactionId, cursor }]),
      pop: (transactionId: string) =>
        setStack(previous => previous.filter(i => i.transactionId !== transactionId)),
    };
  }, []); // no dependencies, it's better if context won't change when stack is updated so it won't trigger hooks (when listed as dependency)

  const cursor = stack.lastItem?.cursor;

  return (
    <ReactCursorOverlayContext.Provider value={context} >
      {children}
      {cursor && <div css={cursorOverlayStyle(cursor)} />}
    </ReactCursorOverlayContext.Provider>
  );
};

/*
When the nearest <MyContext.Provider> above the component updates, this Hook will trigger a rerender
with the latest context value passed to that MyContext provider. Even if an ancestor uses React.memo
or shouldComponentUpdate, a rerender will still happen starting at the component itself using useContext.
*/
export const useCursorOverlayContext = (): CursorOverlayContextModel => {
  const context = useContext(ReactCursorOverlayContext);

  if (!context) {
    throw new Error('CursorOverlayContext: components cannot be used without the context provider.');
  }

  return context;
};
