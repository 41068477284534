import { useMemo } from 'react';
import { useMapInfoSpreadsheetsSelector } from '../mapInfo/mapInfo.selectors';

export const useSpreadsheetIdsSelector = (): number[] => {
  const stateSpreadsheetIds = useMapInfoSpreadsheetsSelector();

  return useMemo(() => {
    return [...(stateSpreadsheetIds ?? [])]
      .sort((a, b) => a.isPrimary && b.isPrimary ? 0 : a.isPrimary ? -1 : 1)
      .map(item => item.spreadSheetId);
  }, [stateSpreadsheetIds]);
};
