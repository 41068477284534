import { css } from '@emotion/react';
import {
  type ChangeEvent, type FC, memo, type MouseEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { stopPropagationAnd } from '~/_shared/utils/dom/dom.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { roundNumberDecimalPlaces } from '~/_shared/utils/number/number.helpers';
import { throttle } from '~/_shared/utils/throttle/throttle';
import { useFocusAttachedTextArea } from '~/map/map/mapObjects/useFocusAttachedTextArea.hook';
import { useMap } from '../../mapContext';
import { GoogleMapsOverlayViewContainer } from '../../mapOverlays/_shared/googleMapsOverlayViewContainer';
import {
  getCalloutArrowDimensions, getCalloutCoordsSvg, getCalloutDimensions,
} from './mapTextAreaCallout.helpers';
import {
  type MapTextAreaCalloutInstance, type MapTextAreaCalloutVisualsConfig,
} from './mapTextAreaCalloutModel';

const svgHolderStyle = (top: number) => css({
  overflow: 'visible',
  position: 'relative',
  top,
  left: 0,
  transform: 'translateY(-100%)',
});

type TextAreaStyleArguments = QuadrilateralDimensions & Readonly<{
  top: number;
  left: number;
  fontSize: number;
  padding: number;
  color: string;
  cursor?: string;
  disabled: boolean;
  selectionDisabled: boolean;
}>;

const textAreaStyle = ({ height, width, left, fontSize, padding, color, cursor, disabled, selectionDisabled, top }: TextAreaStyleArguments) => css({
  left,
  width,
  height,
  color,
  cursor,
  position: 'absolute',
  top,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  overflow: 'hidden',
  fontSize: `${fontSize}px`,
  resize: 'none',
  padding,
  boxSizing: 'border-box',
  pointerEvents: disabled ? 'none' : undefined,
  userSelect: selectionDisabled ? 'none' : undefined,
  textAlign: 'center',

  '::selection': selectionDisabled ? {
    backgroundColor: 'transparent',
  } : undefined,
});

const areaTextSizeTester = (
  { fontSize, width, padding }: Readonly<{ fontSize: number; width: number; padding: number }>
) => css({
  width,
  height: 0,
  padding,
  fontSize: `${fontSize}px`,
  resize: 'none',
  border: 'none',
  outline: 'none',
  opacity: 0,
  position: 'absolute',
  top: -99999,
  zIndex: 0,
  overflow: 'hidden',
  textAlign: 'center',
  boxSizing: 'border-box',
});

const ignoreDimensionsRoundingErrors = (currentValue: number, newValue: number) =>
  Math.abs(currentValue - newValue) < 1 ? currentValue : newValue;

  type MapTextAreaCalloutProps = Readonly<{
    callout: MapTextAreaCalloutInstance;
    visuals: MapTextAreaCalloutVisualsConfig;
    // focus doesn't work on Safari due to security policy
    autoFocusOnInit?: boolean;
    disabled?: boolean;
    selectionDisabled?: boolean;
    etag?: string;

    onClick?: (event: MapObjectClickEventArgs) => void;
    onRightClick?: (event: MouseEvent) => void;
    onMouseDown?: () => void;
    onMouseOut?: () => void;
    onMouseOver?: () => void;
    onTextChange?: (newText: string, textBoxSize: QuadrilateralDimensions) => void;
  }>;

const MapTextAreaCalloutComponent: FC<MapTextAreaCalloutProps> = (props) => {
  const { onTextChange } = props;
  const [text, setText] = useState(props.callout.text);

  const map = useMap();

  const callout: MapTextAreaCalloutInstance = useMemo(() => ({
    ...props.callout,
    textBoxWidth: roundNumberDecimalPlaces(props.callout.textBoxWidth, 2),
    textBoxHeight: roundNumberDecimalPlaces(props.callout.textBoxHeight, 2),
  }), [props.callout]);

  const textBoxDimensionsRef = useRef({ width: callout.textBoxWidth, height: callout.textBoxHeight });
  textBoxDimensionsRef.current = { width: callout.textBoxWidth, height: callout.textBoxHeight };
  const textSizeTesterRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { focusTextArea, markTextAreaAsAttached } = useFocusAttachedTextArea(textAreaRef);

  const onTextChangeRef = useRef(onTextChange);
  onTextChangeRef.current = onTextChange;

  const dimensions = getCalloutDimensions(callout);
  const arrowDimensions = getCalloutArrowDimensions(callout);
  const textAreaLeftMargin = arrowDimensions.width;

  const visuals: MapTextAreaCalloutVisualsConfig = useMemo(() => ({
    ...props.visuals,
    textAreaPadding: roundNumberDecimalPlaces(props.visuals.textAreaPadding, 2),
  }), [props.visuals]);

  const onTextChangeThrottled = useMemo(() => throttle((newValue: string) => {
    if (!textSizeTesterRef.current) {
      return;
    }

    textSizeTesterRef.current.value = newValue;
    const textBoxDimensions = {
      height: ignoreDimensionsRoundingErrors(textBoxDimensionsRef.current.height, textSizeTesterRef.current.scrollHeight),
      width: ignoreDimensionsRoundingErrors(textBoxDimensionsRef.current.width, textSizeTesterRef.current.scrollWidth),
    };

    onTextChangeRef.current?.(newValue, textBoxDimensions);
  }, 200, { leading: false, trailing: true }),
  []);

  const prevEtag = usePrevious(props.etag);

  const handleTextChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setText(newValue);

    onTextChangeThrottled(newValue);
  }, [onTextChangeThrottled]);

  const svg = useMemo(() => getCalloutCoordsSvg(callout, visuals.borderRadius), [callout, visuals.borderRadius]);

  const handleOnClick = useCallback((e: MapObjectClickEventArgs) => {
    const onClick = props.onClick;
    onClick?.(e);
  }, [props.onClick]);

  useEffect(() => {
    if (props.autoFocusOnInit) {
      focusTextArea();
    }
  }, [focusTextArea, props.autoFocusOnInit]);

  useEffect(() => {
    if (props.etag !== prevEtag) {
      onTextChangeThrottled.cancel();
      setText(props.callout.text);
    }
  }, [onTextChangeThrottled, prevEtag, props.callout.text, props.etag]);

  return (
    <GoogleMapsOverlayViewContainer
      map={map}
      onAdd={markTextAreaAsAttached}
      position={callout.startPoint}
      width={0}
    >
      <div
        css={svgHolderStyle(callout.strokeWidth)}
        onMouseDown={props.onMouseDown}
        onMouseEnter={props.onMouseOver}
        onMouseLeave={props.onMouseOut}
        onContextMenu={props.onRightClick}
      >
        <textarea
          ref={textSizeTesterRef}
          css={areaTextSizeTester({
            fontSize: callout.fontSize,
            width: callout.textBoxWidth,
            padding: visuals.textAreaPadding,
          })}
          readOnly
        />

        <svg
          height={dimensions.height}
          onClick={handleOnClick}
          width={dimensions.width}
        >
          <path
            d={svg}
            stroke={visuals.strokeColor}
            strokeWidth={callout.strokeWidth}
            strokeOpacity={visuals.strokeOpacity}
            fill={visuals.fillColor}
            fillOpacity={visuals.fillOpacity}
          />
        </svg>

        <textarea
          css={textAreaStyle({
            color: visuals.fontColor,
            cursor: onTextChange ? undefined : 'pointer',
            disabled: props.disabled ?? false,
            fontSize: callout.fontSize,
            height: callout.textBoxHeight,
            left: textAreaLeftMargin,
            padding: visuals.textAreaPadding,
            selectionDisabled: props.selectionDisabled ?? false,
            top: callout.strokeWidth / 2,
            width: callout.textBoxWidth,
          })}
          onChange={handleTextChange}
          onClick={handleOnClick}
          onMouseDown={stopPropagationAnd(noop)}
          readOnly={!onTextChange}
          ref={textAreaRef}
          spellCheck={false}
          value={text}
        />
      </div>
    </GoogleMapsOverlayViewContainer>
  );
};

const pureComponent = memo(MapTextAreaCalloutComponent);
export { pureComponent as MapTextAreaCalloutComponent };
