import { type SpreadsheetColumnId } from './spreadsheetColumn';

export const SERVER_ADDED_COLUMN_NAMES: ReadonlySet<string> = new Set(['bg_accuracy']);

export const compareSpreadsheetColumnIds = (a: SpreadsheetColumnId, b: SpreadsheetColumnId): boolean => {
  return (
    a.columnId === b.columnId &&
    a.spreadsheetId === b.spreadsheetId
  );
};

export const checkIsColumnNameSame = (columnNameA: string, columnNameB: string): boolean => (
  columnNameA !== null && columnNameB !== null
  && columnNameA.toString().toLowerCase() === columnNameB.toString().toLowerCase()
);

export const filterAddedSpreadsheetColumns = <T extends { name: string }>(columns?: ReadonlyArray<T>, returnAdded?: boolean): T[] => {
  if (!columns?.length) {
    return [];
  }

  return columns.filter(col => {
    const isAdded = SERVER_ADDED_COLUMN_NAMES.has(col.name);
    return returnAdded ? isAdded : !isAdded;
  });
};
