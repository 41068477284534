import { type Theme } from './theme.model';

export const darkTheme: Theme = {
  name: 'dark',
  accordionColors: {
    borderColor: '#424A51',
    countBackground: '#FFFFFF',
    countColor: '#383E42',
    headingBackground: '#111B22',
    headingBackgroundActive: '#0C3756',
    headingBackgroundActiveHover: '#104b75',
    headingBackgroundHover: '#424A51',
    headingBorderActive: '#0078CE',
    itemBackground: '#2e3438',
    itemBackgroundHover: '#424A51',
  },
  alertColors: {
    dangerPrimary: '#DC3545',
    dangerSecondary: '#FBE5E7',
    successPrimary: '#28A745',
    successSecondary: '#EDF7E9',
    infoPrimary: '#0078CE',
    infoSecondary: '#E0F2FF',
    warningPrimary: '#E76900',
    warningSecondary: '#FFEEE0',
  },
  backgroundColors: {
    secondaryAlternative: '#324552',
    primary: '#2E3438',
    primaryDragged: 'rgba(0, 0, 0, 0.8)',
    secondary: '#111B22',
    secondaryHover: '#424A51',
    tertiary: '#383E42',
    quaternary: '#0078CE',
    quinary: '#111B22',
    highlight: '#0C3756',
    highlightHover: '#104b75',
    infoBackground: '#DFF0D8',
    overlay: 'rgba(0, 0, 0, 0.8)',
    warning: '#E76900',
    warningHover: '#FF9033',
  },
  borderColors: {
    activeItem: '#0078CE',
    error: '#DC3545',
    highContrast: '#FFFFFF',
    highlight: '#111B22',
    primary: '#424A51',
    primaryFocused: '#57a5de',
    quaternary: '#AED3EE',
    secondary: '#DAE2E8',
    tertiary: '#424A51',
    warningHover: '#FFB980',
  },
  buttonColors: {
    defaultBorder: '#ACBAC3',
    primaryBackground: '#0078CE',
    primaryBackgroundOnHover: '#3195de',
    primaryBorderOnHover: '#48d2f3',
    primaryText: '#FFFFFF',
    primaryBorder: '#ACBAC3',
    tertiaryBackground: '#2E3438',
    tertiaryText: '#FFFFFF',
    tertiaryActiveText: '#FFFFFF',
    tertiaryBorder: '#566976',
    tertiaryBorderOnHover: '#48d2f3',
    quaternaryBackground: '#2E3438',
    quaternaryBackgroundOnHover: '#566976',
    quaternaryBorder: '#566976',
    quaternaryBorderOnHover: '#7D92A1',
    quaternaryText: '#ACBAC3',
    quaternaryActiveText: '#FFFFFF',
    successBackground: '#28A745',
    successBorderOnHover: '#00BC2B',
    successActiveBackground: '#128C2D',
    successText: '#FFFFFF',
    successBorder: '#ACBAC3',
    dangerBackground: '#DC3545',
    dangerBackgroundHover: '#BD1818',
    dangerBorderOnHover: '#FF3F51',
    dangerText: '#FFFFFF',
    upArrowBackground: '#4098D0',
    upArrowText: '#FFFFFF',
    secondaryActiveBackground: '#3E5362',
    secondaryBackground: '#7D92A1',
    secondaryBackgroundHover: '#566976',
    secondaryBorderOnHover: '#7D92A1',
    secondaryText: '#FFFFFF',
    inheritedBackgroundOnHover: '#566976',
  },
  checkboxColors: {
    checkedFill: '#0078CE',
    checkedFillHover: 'rgba(0, 120, 206, 0.4)',
    checkedText: '#111B22',
    uncheckedFill: '#2E3438',
    uncheckedBorder: '#0078CE',
    uncheckedBorderSecondary: '#424A51',
    uncheckedBorderTertiary: '#ACBAC3',
  },
  colorPickerColors: {
    backgroundColor: '#111B22',
    backgroundColorInput: '#111B22',
    defaultSelectedColor: '#FFFFFF',
    separatorColor: '#464646',
    shadowColorInput: '#464646',
    shadowColorPrimary: 'rgba(255, 255, 255, 0.15)',
    shadowColorSecondary: 'rgba(255, 255, 255, 0.15)',
    textColor: '#FFFFFF',
  },
  contrasts: {
    selectedAccent2: '#0078CE',
    selectedAccent2Faded: 'rgba(0, 120, 206, 0.3)',
    selectedAccent2Background: '#C7E7FF',
    success: '#28A745',
    successSecondary: '#94D3A2',
    successBackground: '#DFF0D8',
  },
  directionsPanelColors: {
    exportFileButtonIconBackground: '#0078CE',
    routeStepBackground: '#383E42',
  },
  dropdownColors: {
    optionBackground: '#50565A',
    optionBackgroundHover: '#383E42',
    shadow: 'rgba(0, 0, 0, 0.3)',
    triggerBackground: '#111B22',
    triggerBackgroundHover: '#0d141a',
  },
  iconColors: {
    backgroundPrimary: '#383E42',
    backgroundPrimaryHover: '#566976',
    borderPrimaryHover: '#7D92A1',
    backgroundPrimaryInverted: '#111B22',
    backgroundPrimaryInvertedHover: '#566976',
    borderPrimaryInvertedHover: '#7D92A1',
    backgroundSecondary: '#111B22',
    backgroundSecondaryHover: '#566976',
    borderSecondaryHover: '#7D92A1',
    backgroundSecondaryInverted: '#383E42',
    backgroundSecondaryInvertedHover: '#566976',
    borderSecondaryInvertedHover: '#7D92A1',
    backgroundTertiary: '#2E3438',
    backgroundTertiaryHover: '#566976',
    borderTertiaryHover: '#7D92A1',
    backgroundQuaternary: '#0078CE',
    backgroundQuaternaryHover: '#3195de',
    borderQuaternaryHover: '#48d2f3',
    backgroundDangerInverted: '#DC3545',
    backgroundDangerInvertedHover: '#BD1818',
    borderDangerInvertedHover: '#FF3F51',
    backgroundSuccessInverted: '#28A745',
    backgroundSuccessInvertedHover: '#128C2D',
    borderSuccessInvertedHover: '#00BC2B',
    focused: '#57a5de',
    primary: '#ACBAC3',
    primaryInverted: '#ACBAC3',
    secondary: '#ACBAC3',
    secondaryHover: '#ACBAC3',
    secondaryInverted: '#ACBAC3',
    tertiary: '#FFFFFF',
    quaternary: '#FFFFFF',
    contrast: '#0078CE',
    highContrast: '#FFFFFF',
    lowContrast: '#566976',
    highlight: '#0C3756',
    danger: '#DC3545',
    accentedBackground: '#0078CE',
    accentedBorderHover: '#3195de',
    accented: '#FFFFFF',
    success: '#28A745',
    warning: '#FCE141',
    quinary: '#3196DE',
    active: '#B3D7F1',
  },
  lineColors: {
    basePrimary: '#424A51',
    contrastGreen: '#17E915',
    green: '#28A745',
    blue: '#0078CE',
  },
  modalColors: {
    contentBackground: '#2E3438',
    contentColor: '#FFFFFF',
    overlayBackground: 'rgb(0, 0, 0, .7)',
    closeButton: '#FFFFFF',
    closeButtonBackground: '#111B22',
    titleText: '#FFFFFF',
    titleBackground: '#111B22',
    footerBackground: '#2E3438',
    footerText: '#FFFFFF',
  },
  percentageIndicatorColors: {
    centerText: '#ACBAC3',
    donutBase: '#0078CE',
    donutEntry: '#9db0bf',
    labelBackground: '#2E3438',
    labelTextKeyEntry: '#7D92A1',
    labelTextValueEntry: '#9db0bf',
  },
  radioColors: {
    checkedBorder: '#0078CE',
    checkedFillHover: 'rgba(0, 120, 206, 0.4)',
    uncheckedBorder: '#ACBACB',
    checkedFill: '#111B22',
    uncheckedFill: 'none',
    text: '#FFFFFF',
    icon: '#FFFFFF',
  },
  shadowColors: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(105, 112, 117, 0.2)',
  },
  sliderColors: {
    railBackground: '#424A51',
    trackBackground: '#0078CE',
    handleShadow: 'rgba(18, 27, 33, 0.5)',
    dotColor: '#FFF',
    activeDotColor: '#FFF',
    dotBorder: '#424A51',
    activeDotBorder: '#0078CE',
    contrastColor: '#31AAB7',
    danger: '#DC3545',
  },
  roundButtonStyle: {
    boxShadow: 'none',
  },
  switchColors: {
    primaryBackground: '#111B22',
    primaryBorder: '#566976',
    primaryOn: '#FFFFFF',
    secondaryOn: '#0078CE',
    primaryOff: '#ACBAC3',
  },
  textColors: {
    primary: '#FFFFFF',
    secondary: '#ACBAC3',
    tertiary: '#ACBAC3',
    quaternary: '#FFF',
    link: '#0078CE',
    linkOnHover: '#3195de',
    danger: '#DC3545',
    dangerOnHover: '#BD1818',
    warning: '#E76900',
    disabled: '#7D92A1',
    success: '#28A745',
    successOnHover: '#128C2D',
    contrast: '#FFFFFF',
    light: '#324552',
    info: '#57a5de',
  },
  imageGalleryColors: {
    navigationArrowsColor: '#ACBAC3',
  },
  scrollbarColors: {
    thumbColor: '#7D92A1',
    trackColor: '#424A51',
  },
  tooltipColors: {
    backgroundColor: '#F8FAFB',
    textColor: '#324552',
  },
  tagColors: {
    backgroundColor: 'rgba(0, 120, 206, 0.2)',
    textColor: '#FFFFFF',
    textColorOnHover: '#0078CE',
    primaryBackgroundColor: '#0078CE',
    primaryTextColor: '#FFFFFF',
    primaryTextColorOnHover: '#7D92A1',
    secondaryTextColor: '#FFFFFF',
    secondaryBackgroundColor: 'rgba(0, 120, 206, 0.7)',
  },
  tabberColors: {
    activeTabColor: '#0078CE',
    inActiveTabColor: '#566976',
    activeTabBorderColor: '#0078CE',
  },
  loadingOverlay: {
    backgroundColor: 'rgba(76, 77, 82, 0.5)',
    textColor: '#FFFFFF',
  },
  opacity: {
    disabled: 0.6,
  },
  invoicedColors: {
    background: '#2E3438',
    text: '#FFFFFF',
  },
};
