import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { moveMarkerLabelsCloseModal } from '../../moveMarkerLabels/moveMarkerLabels.actionCreators';
import { MOVE_MARKER_LABELS_OPEN_MODAL } from '../../moveMarkerLabels/moveMarkerLabels.actionTypes';
import { moveMarkersCloseModal } from '../../moveMarkers/moveMarkers.actionCreators';
import { MOVE_MARKERS_OPEN_MODAL } from '../../moveMarkers/moveMarkers.actionTypes';
import { endIndividualRadiusDnDMode } from '../../proximityTool/proximityTool.actionCreators';
import { PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED } from '../../proximityTool/proximityTool.actionTypes';
import { stopAddLocationTool } from '../addLocation/addLocation.actionCreators';
import { FRONTEND_STATE_ADD_LOCATION_START } from '../addLocation/addLocation.actionTypes';
import { boundaryDrawCloseModal } from '../boundary/boundaryDraw/boundaryDraw.actionCreators';
import {
  BOUNDARY_DRAW_EDIT_OPEN_MODAL, BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL,
} from '../boundary/boundaryDraw/boundaryDraw.actionTypes';
import { boundarySelectEditCloseModal } from '../boundary/boundarySelect/boundarySelect.actionCreators';
import {
  BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL,
} from '../boundary/boundarySelect/boundarySelect.actionTypes';
import { stopDistanceCalculator } from '../distanceCalculator/distanceCalculator.actionCreators';
import { FRONTEND_STATE_DISTANCE_CALCULATOR_START } from '../distanceCalculator/distanceCalculator.actionTypes';
import { drawingEditClearSelectedDrawing } from '../drawing/drawingEdit/drawingEdit.actionCreators';
import { FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING } from '../drawing/drawingEdit/drawingEdit.actionTypes';
import {
  drawingToolClearActiveTool, drawingToolDisableDrawingMode,
} from '../drawingTool/drawingTool.actionCreators';
import {
  FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE, FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL,
} from '../drawingTool/drawingTool.actionTypes';
import { stopLassoTool } from '../lasso/lasso.actionCreators';
import {
  FRONTEND_STATE_LASSO_START, FRONTEND_STATE_ROUTING_LASSO_START,
} from '../lasso/lasso.actionTypes';
import { markersSelectDeactivate } from '../markersSelect/markersSelect.actionCreators';
import { MARKERS_SELECT_ACTIVATE } from '../markersSelect/markersSelect.actionTypes';
import { frontendStateSearchHide } from '../search/search.actionCreators';
import { FRONTEND_STATE_SEARCH_SHOW } from '../search/search.actionTypes';

type MutuallyExclusiveMapTools = {
  activator: string;
  off: () => Action;
  ignore?: ReadonlyArray<string>;
};

// Some map features conflicts with each other. When one feature activates, other needs to be turned off.
// For instance activating lasso tool sould turn off distance calculator since both are 'drawing' tools.

// There is currently only one set of conflicting funcions, multiple sets can be created if needed.

// Also see
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
useIsMapInteractionActive;

export const mutuallyExclusiveMapTools: MutuallyExclusiveMapTools[] = [
  {
    activator: FRONTEND_STATE_SEARCH_SHOW,
    off: frontendStateSearchHide,
  },
  {
    activator: FRONTEND_STATE_DISTANCE_CALCULATOR_START,
    off: stopDistanceCalculator,
  },
  {
    activator: FRONTEND_STATE_LASSO_START,
    off: stopLassoTool,
  },
  {
    activator: FRONTEND_STATE_ROUTING_LASSO_START,
    off: stopLassoTool,
  },
  {
    activator: FRONTEND_STATE_ADD_LOCATION_START,
    off: stopAddLocationTool,
  },
  {
    activator: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED,
    off: endIndividualRadiusDnDMode,
  },
  {
    activator: BOUNDARY_DRAW_EDIT_OPEN_MODAL,
    off: boundaryDrawCloseModal,
  },
  {
    activator: BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL,
    off: boundaryDrawCloseModal,
  },
  {
    activator: BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
    off: boundarySelectEditCloseModal,
  },
  {
    activator: BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL,
    off: boundarySelectEditCloseModal,
  },
  {
    activator: BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
    off: boundarySelectEditCloseModal,
  },
  {
    activator: MARKERS_SELECT_ACTIVATE,
    off: markersSelectDeactivate,
  },
  {
    activator: FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL,
    off: drawingToolClearActiveTool,
    ignore: [
      FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
      FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
    ],
  },
  {
    activator: FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
    off: drawingToolDisableDrawingMode,
    ignore: [FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL],
  },
  {
    activator: FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
    off: drawingEditClearSelectedDrawing,
    ignore: [FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL],
  },
  {
    activator: MOVE_MARKERS_OPEN_MODAL,
    off: moveMarkersCloseModal,
  },
  {
    activator: MOVE_MARKER_LABELS_OPEN_MODAL,
    off: moveMarkerLabelsCloseModal,
  },
];
