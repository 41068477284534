import {
  type FC, useMemo,
} from 'react';
import {
  GroupButtonComponent, type GroupButtonItem,
} from '~/_shared/baseComponents/buttons/groupButton/groupButton.component';
import { type Page } from '~/_shared/constants/routes';
import { type NavigationItem } from './navigation.helpers';

type NavigationProps = {
  activeNavigationItemIndex: number;
  navigationToMapAllowed: boolean;
  navigationItems: NavigationItem[];

  onSelectNavigationItem: (itemPath: string) => void;
};

export const NavigationComponent: FC<NavigationProps> = (props) => {

  const buttonItems: GroupButtonItem<Page>[] = useMemo(() => {
    const onSelectNavigationItem = props.onSelectNavigationItem;

    return props.navigationItems.map(item => ({
      icon: item.icon,
      id: item.page,
      disabledMessage: item.isDisabledWithMessage,
      onClick: () => onSelectNavigationItem(item.path),
      text: item.text,
      testid: item.testid,
    }));
  }, [props.navigationItems, props.onSelectNavigationItem]);

  return (
    <GroupButtonComponent
      buttonItems={buttonItems}
      selectedButtonItemId={props.navigationItems[props.activeNavigationItemIndex]?.page ?? ''}
    />
  );
};
