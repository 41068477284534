import {
  useEffect,
  useMemo, useState,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import {
  type GroupingColumn, isGroupingColumnNumeric, isGroupingColumnText,
  isNumericTypeGroup,
  isTextTypeGroup,
  type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { getMatchupDataColumnName } from '~/store/matchupData/matchupData.helpers';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { getGroupingColumnsUniqueGroups } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  emptySpreadsheetData, type GroupId,
} from '~/store/spreadsheetData/spreadsheetData.state';

export type SelectGroupProps = Readonly<{
  availableGroupingColumns: DropdownOption<GroupingColumn>[];
  availableUniqueGroups: DropdownOption<UniqueGroup>[];
  isSelectionValid: boolean;
  selectedGroupingColumn: GroupingColumn | null;
  selectedGroupingColumnHierarchyIndicator: number | null;
  selectedUniqueGroup: UniqueGroup | null;
  selectedBucketId: number | null;
  selectedGroupId: GroupId | null;
  setSelectedGroupingColumn?: (columnId: GroupingColumn | null) => void;
  setSelectedUniqueGroup?: (bucketId: UniqueGroup | null) => void;
}>;

export const useSelectGroupingToolGroup = (): SelectGroupProps => {
  const matchupData = useMatchupDataSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const groupingColumns = useMapSettingsGroupingActiveGroupColumnsSelector();

  const [selectedGroupingColumn, setSelectedGroupingColumn] = useState<GroupingColumn | null>(null);
  const [selectedUniqueGroup, setSelectedUniqueGroup] = useState<UniqueGroup | null>(null);

  const indexOfSelectedGroupingColumn = useMemo(() => (
    selectedGroupingColumn ? groupingColumns.indexOf(selectedGroupingColumn) : -1
  ), [groupingColumns, selectedGroupingColumn]);

  const selectedGroupingColumnHierarchyIndicator = indexOfSelectedGroupingColumn > -1 ? indexOfSelectedGroupingColumn : null;

  const isSelectionValid = useMemo(() => !!(
    selectedGroupingColumn && selectedUniqueGroup && (
      (isGroupingColumnNumeric(selectedGroupingColumn) && isNumericTypeGroup(selectedUniqueGroup))
      || (isGroupingColumnText(selectedGroupingColumn) && isTextTypeGroup(selectedUniqueGroup))
    )
  ), [selectedGroupingColumn, selectedUniqueGroup]);

  const uniqueGroups = useMemo(() => getGroupingColumnsUniqueGroups(
    spreadsheetData ?? emptySpreadsheetData,
    matchupData,
    selectedGroupingColumn || undefined,
  ), [matchupData, selectedGroupingColumn, spreadsheetData]);

  const availableGroupingColumns: DropdownOption<GroupingColumn>[] = useMemo(() => groupingColumns.map(column => ({
    name: getMatchupDataColumnName(matchupData, { columnId: column.columnId, spreadsheetId: column.spreadsheetId }) || '',
    value: column,
  })), [groupingColumns, matchupData]);

  const availableUniqueGroups: DropdownOption<UniqueGroup>[] = useMemo(() => uniqueGroups.map(uniqueGroup => ({
    name: uniqueGroup.name,
    value: uniqueGroup,
  })), [uniqueGroups]);

  useEffect(() => {
    if (groupingColumns.length === 1) {
      setSelectedGroupingColumn(groupingColumns[0] || null);
    }
  }, [groupingColumns, selectedGroupingColumn]);

  useEffect(() => {
    setSelectedUniqueGroup(null);
  }, [selectedGroupingColumn]);

  return useMemo(() => ({
    availableGroupingColumns,
    availableUniqueGroups,
    isSelectionValid,
    selectedGroupingColumn,
    selectedGroupingColumnHierarchyIndicator,
    selectedUniqueGroup,
    selectedBucketId: selectedUniqueGroup && isNumericTypeGroup(selectedUniqueGroup) ? selectedUniqueGroup.id : null,
    selectedGroupId: selectedUniqueGroup && isTextTypeGroup(selectedUniqueGroup) ? selectedUniqueGroup.id : null,
    setSelectedGroupingColumn,
    ...(selectedGroupingColumn ? { setSelectedUniqueGroup } : {}),
  }), [availableGroupingColumns, availableUniqueGroups, isSelectionValid, selectedGroupingColumn,
    selectedGroupingColumnHierarchyIndicator, selectedUniqueGroup,
  ]);
};
