import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Failed]: {
  views: {
    default: {
      canvasHeight: 80,
      canvasWidth: 80,
      iconWidth: 52,
      iconHeight: 52,
    },
  },
  colors: {
    fill: '#E71E4F',
    border: '#E71E4F',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 40] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/9965-loading-spinner" */
      './121635-failed.json'
    ),
} };

export const { Failed } = config;
