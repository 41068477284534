import axios, { type CancelToken } from 'axios';
import {
  useCallback, useEffect, useState,
} from 'react';
import { createCancelToken } from '~/_shared/utils/api/api.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { type MetricValue } from '../../createBoundaryGroupFromAIModal.types';
import { getConstraintValidationErrorMessages } from './constraints.helpers';
import {
  validateTerritoryConstraints, type ValidateTerritoryConstraintsItem,
} from './constraints.repository';

type ConstraintsValidationParams = {
  optimizationConstraints: boolean;
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  zipCodesSubset?: ReadonlyArray<string>;
  statesSubset?: ReadonlyArray<string>;
};

type ConstraintsValidationData = {
  errorMessages: string[];
  highestMin: number | null;
  lowestMax: number | null;
  max: number;
  min: number;
};

export type ConstraintsValidationDataMap = Map<string, ConstraintsValidationData>;

export const useConstraintsValidation = ({
  optimizationConstraints,
  demographicsValues,
  dataMetricsValues,
  zipCodesSubset,
  statesSubset,
}: ConstraintsValidationParams) => {
  const [validationData, setValidationData] = useState<ConstraintsValidationDataMap>(new Map());
  const [generalError, setGeneralError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const spreadsheetId = usePrimarySpreadsheetId();
  const mapId = useMapIdSelector();

  const validateConstraints = useCallback(async (cancelToken: CancelToken) => {
    if (!clientId || mapId === null || spreadsheetId === null) {
      return;
    }

    const constraints: ValidateTerritoryConstraintsItem[] = [
      ...dataMetricsValues.filter(dataMetricsValue => dataMetricsValue.isConstraint && dataMetricsValue.checked).map(dataMetricsValue => ({
        metric: dataMetricsValue.id,
        min: dataMetricsValue.minConstraint ?? 1,
        max: dataMetricsValue.maxConstraint ?? Number.MAX_SAFE_INTEGER,
      })),
      ...demographicsValues.filter(demographicsValue => demographicsValue.isConstraint && demographicsValue.checked).map(demographicsValue => ({
        metric: demographicsValue.id,
        min: demographicsValue.minConstraint ?? 1,
        max: demographicsValue.maxConstraint ?? Number.MAX_SAFE_INTEGER,
      })),
    ];

    if (constraints.length === 0) {
      return;
    }

    setIsLoading(true);

    try {
      const validationResult = await validateTerritoryConstraints(clientId, {
        map_id: mapId,
        constraints,
        spreadsheet_id: spreadsheetId,
        dataset_states: statesSubset,
        dataset_zip_codes: zipCodesSubset,
      }, cancelToken);

      setGeneralError('');

      if (validationResult && validationResult.results.length) {
        const newValidationData: ConstraintsValidationDataMap = new Map();

        for (const validationItem of validationResult.results) {
          const errorMessages = getConstraintValidationErrorMessages(validationItem, t);

          newValidationData.set(validationItem.metric, {
            errorMessages,
            highestMin: validationItem.limits.highestMin,
            lowestMax: validationItem.limits.lowestMax,
            max: validationItem.limits.max,
            min: validationItem.limits.min,
          });
        }

        setValidationData(newValidationData);
      }
    }
    catch (err) {
      if (!axios.isCancel(err)) {
        setValidationData(new Map());
        setGeneralError(t('Something went wrong, please try again.'));
      }
    }
    finally {
      setIsLoading(false);
    }
  }, [clientId, mapId, spreadsheetId, dataMetricsValues, demographicsValues, zipCodesSubset, statesSubset, t]);

  useEffect(() => {
    if (!optimizationConstraints) {
      return;
    }

    const cancelToken = createCancelToken();

    const timeoutId = setTimeout(() => {
      validateConstraints(cancelToken.token);
    }, 750);

    return () => {
      clearTimeout(timeoutId);
      cancelToken.cancel();
    };
  }, [optimizationConstraints, validateConstraints]);

  return {
    areConstraintsValid: !isLoading && (optimizationConstraints
      ? Array.from(validationData.values()).every(validation => validation.errorMessages.length === 0)
      : true),
    generalError,
    isLoading,
    validationData,
  };
};
