import { useMemo } from 'react';
import { isLabelStyle } from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { getMarkerEntityStyle } from '~/_shared/utils/markers/markerEntityStyle.helpers';
import { getFallbackStandardMarkerSettings } from '~/_shared/utils/markers/markersVisualSettings.helpers';
import {
  calculateOffsetsForMarkerEntity, type MarkerEntityOffsets,
} from '~/_shared/utils/markers/offsets.helpers';
import { useMoveMarkerLabelOffsets } from '~/map/map/markers/useMoveMarkerLabels/useMoveMarkerLabelOffsets';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useMarkerSets } from '~/store/frontendState/markerSets/markerSets.selectors';
import { useMapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import {
  useAreMarkerLabelsDisabled, useMapSettingsMarkersGeneralSelector, useMapSettingsMarkersTextureAnchors,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';

export type MarkerEntityOffsetsProps = Readonly<{
  markerVisualSettings?: MarkerSettings;
  applyLabelAboveOffset?: boolean;
  customizedLabelOffsetRowId?: SpreadsheetRowId;
}>;

export const useMarkerEntityOffsets = ({
  markerVisualSettings,
  applyLabelAboveOffset,
  customizedLabelOffsetRowId,
}: MarkerEntityOffsetsProps) => {
  const fileAttachments = useMapSettingsFileAttachmentsMapSelector();
  const files = useFileUrls();
  const generalMarkerSettings = useMapSettingsMarkersGeneralSelector();
  const markerSets = useMarkerSets();
  const { applyCustomLabelOffsets } = useMoveMarkerLabelOffsets();
  const { getMarkerTextureAnchor } = useMapSettingsMarkersTextureAnchors();
  const markerLabelsDisabled = useAreMarkerLabelsDisabled();

  const fallbackMarkerSettings = useMemo(() => (
    getFallbackStandardMarkerSettings(generalMarkerSettings)
  ), [generalMarkerSettings]);

  return useMemo<MarkerEntityOffsets | undefined>(() => {
    if (!markerVisualSettings) {
      return;
    }

    let markerEntityStyle = getMarkerEntityStyle({
      fallbackMarkerSettings,
      fileAttachments,
      fileMap: files,
      getMarkerTextureAnchor,
      markerSets,
      markerSettings: markerVisualSettings,
      markerLabelsDisabled,
    });

    if (customizedLabelOffsetRowId && isLabelStyle(markerEntityStyle)) {
      markerEntityStyle = applyCustomLabelOffsets(customizedLabelOffsetRowId, markerEntityStyle);
    }

    return calculateOffsetsForMarkerEntity(
      markerEntityStyle,
      applyLabelAboveOffset ? markerVisualSettings.aboveLabel : undefined,
    );
  }, [markerVisualSettings, fallbackMarkerSettings, fileAttachments, files, getMarkerTextureAnchor, markerSets,
    markerLabelsDisabled, customizedLabelOffsetRowId, applyLabelAboveOffset, applyCustomLabelOffsets]);
};
