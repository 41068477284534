import { type PickNonNullable } from './types/common.type';

export const notNull = <T>(input: T | null): input is T => input !== null;
export const notUndefined = <T>(input: T | undefined): input is T => input !== undefined;
export const notNullOrUndefined = <T>(input: Maybe<T>): input is T => notNull(input) && notUndefined(input);
export const objectEntryValueNotUndefined = <T1, T2>(entry: [T1, T2 | undefined]): entry is [T1, T2] => notUndefined(entry[1]);

export const isNullOrUndefined = <T>(input: Maybe<T>): input is null | undefined => !notNullOrUndefined(input);

export const hasNotNull = <T, K extends keyof T>(input: T, field: K): input is PickNonNullable<T, K> =>
  input[field] !== null && input[field] !== undefined;

export const isString = <T>(input: Maybe<T> | string): input is string => typeof input === 'string';
export const isNumber = <T>(input: Maybe<T> | number): input is number => typeof input === 'number' && Number.isFinite(input);

export function ensureIsNotNullsy<T>(value: Maybe<T>): asserts value is T {
  if (value === null || value === undefined) {
    throw Error('Unexpected nullsy value');
  }
}

export const notNullsy = notNullOrUndefined;

export const isNullsy = isNullOrUndefined;

export const isTruthy = <TValue>(value: Maybe<TValue> | false | '' | 0): value is TValue => !!value;

export const isError = (object: unknown): object is Error => {
  const error = object as Partial<Error> | undefined;
  return error?.message !== undefined && error?.name !== undefined;
};

export const assertNever = (value: never) => {
  throw new Error(`Unexpected value: '${value}'.`);
};
