import {
  useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type WebglLayers } from '~/map/map/webgl/useWebGL';
import {
  boundarySelectRenderingStart, boundarySelectRenderingStop,
} from '~/store/frontendState/mapTools/boundary/boundaryRendering/boundaryRendering.actionCreators';
import { useMapBoundaryStyles } from '../boundaryView/mapBoundaryStyles/useMapBoundaryStyles';
import { MapBoundaryManager } from '../mapBoundary.manager';
import { useSelectionMapBoundaryMapMouseEvents } from './useSelectionMapBoundaryMapMouseEvents';
import { useSelectionMapBoundaryMouseEvents } from './useSelectionMapBoundaryMouseEvents';
import { useSelectionMapBoundaryPolygons } from './useSelectionMapBoundaryPolygons';
import { useSelectionMapBoundaryStyles } from './useSelectionMapBoundaryStyles';

type UseBoundariesParams = {
  webglLayers: WebglLayers | undefined;
  map: google.maps.Map | undefined;
};

export const useSelectionMapBoundary = ({ webglLayers, map }: UseBoundariesParams) => {
  const manager = useManager(webglLayers);

  const { baseStyles, selectedBoundariesStyles } = useSelectionMapBoundaryStyles();

  // order matters => we want to compute styles before polygons
  // this way polygons already have proper visuals when inserted
  useMapBoundaryStyles({
    manager,
    baseStyles,
    extra: selectedBoundariesStyles,
  });
  useSelectionMapBoundaryPolygons(manager, map);
  useSelectionMapBoundaryMouseEvents(manager);
  useSelectionMapBoundaryMapMouseEvents(map);

  useEffect(() => {
    if (!manager) {
      return;
    }

    return () => {
      manager.dispose();
    };
  }, [manager]);
};

const useManager = (webglLayers: WebglLayers | undefined) => {
  const dispatch = useDispatch();

  return useMemo(() => {
    if (!webglLayers) {
      return undefined;
    }

    const manager = new MapBoundaryManager({
      BoundaryPolygons: webglLayers.BoundarySelectPolygons,
      BoundaryPolygonLabelsBackground: webglLayers.BoundarySelectPolygonLabelsBackground,
      BoundaryPolygonLabelsText: webglLayers.BoundarySelectPolygonLabelsText,
    });

    manager.eventEmitter.on('onBusy', () => dispatch(boundarySelectRenderingStart()));
    manager.eventEmitter.on('onIdle', () => dispatch(boundarySelectRenderingStop()));

    return manager;
  }, [dispatch, webglLayers]);
};
