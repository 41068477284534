import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';

const linkStyle = css({
  display: 'inline',
  textDecoration: 'underline',
  fontWeight: 700,
  cursor: 'pointer',
});

export type UnderlinedLinkComponentProps = Readonly<{
  children: ReactNode;
  className?: string;

  onClick?: () => void;
}>;

export const UnderlinedLinkComponent: FC<UnderlinedLinkComponentProps> = props => {
  return (
    <div
      css={linkStyle}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
