import {
  type FC, useMemo,
} from 'react';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useGetFinalMarkerSettingsForSpreadsheetRow } from '~/_shared/utils/markers/useGetFinalMarkerSettingsForSpreadsheetRow.hook';
import { areEqualSpreadsheetRowIds } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { useHoveredMarkerSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useMapSettingsUseLabelsAboveMarkersSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { useMapSettingsActiveMarkerIndicatorSettingsSelector } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import type { StackedMarker } from '../../markers/manager/mapMarkerManager';
import { GoogleMapsOverlayViewContainer } from '../_shared/googleMapsOverlayViewContainer';
import { useMarkerHoverLabelSettings } from '../markerHoverEffects/useMarkerHoverLabelSettings';
import { ActiveMarkerIndicatorComponent } from './activeMarkerIndicator.component';

type ActiveMarkerIndicatorProps = {
  color?: string;
  map: google.maps.Map;
  position: LatLng;
  activeMarkerId: SpreadsheetRowId;
  stackInfo?: StackedMarker;
};

export const ActiveMarkerIndicatorOverlay: FC<ActiveMarkerIndicatorProps> = (props) => {
  const { activeMarkerId, stackInfo } = props;

  const hoveredMarker = useHoveredMarkerSelector();
  const activeMarkerIndicatorSettings = useMapSettingsActiveMarkerIndicatorSettingsSelector();
  const areLabelsAboveEnabled = useMapSettingsUseLabelsAboveMarkersSelector();
  const hoveredMarkerSettings = useMarkerHoverLabelSettings(hoveredMarker);

  const activeMarkerVisualSettings = useGetFinalMarkerSettingsForSpreadsheetRow(activeMarkerId, stackInfo);
  const isActiveMarkerHovered = useMemo(() => (
    hoveredMarker?.rowIds.findIndex(rowId => areEqualSpreadsheetRowIds(rowId, activeMarkerId)) !== -1
  ), [hoveredMarker, activeMarkerId]);

  const applyLabelAboveOffset = useMemo(() => !!(
    (activeMarkerVisualSettings && activeMarkerVisualSettings.marker?.labelText)
      || areLabelsAboveEnabled
      || (isActiveMarkerHovered && hoveredMarkerSettings.shouldBeDisplayed)
  ), [activeMarkerVisualSettings, areLabelsAboveEnabled, hoveredMarkerSettings.shouldBeDisplayed, isActiveMarkerHovered]);

  return (
    <GoogleMapsOverlayViewContainer
      position={props.position}
      map={props.map}
      noMouseEvents
    >
      {activeMarkerId && (
        <ActiveMarkerIndicatorComponent
          applyLabelAboveOffset={applyLabelAboveOffset}
          color={activeMarkerIndicatorSettings.color}
          customizedLabelOffsetRowId={activeMarkerId}
          currentActiveIndicator={activeMarkerIndicatorSettings.type}
          markerVisualSettings={activeMarkerVisualSettings}
          size={activeMarkerIndicatorSettings.size}
          speed={activeMarkerIndicatorSettings.speed}
        />
      )}
    </GoogleMapsOverlayViewContainer>
  );
};
