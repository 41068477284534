import 'react-datepicker/dist/react-datepicker.css';
import {
  css, Global,
} from '@emotion/react';
import {
  autoUpdate, FloatingPortal, offset, type Placement, useFloating,
} from '@floating-ui/react';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { getInputCommonColors } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import type { Theme } from '~/_shared/themes/theme.model';
import { KeyboardKeys } from '~/_shared/utils/hooks/useKeyPress';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import {
  dateToLocaleDateString, fromCurrentTimezoneDate12PMtoUTC12PM, fromUTCDate12PMToCurrentTimezone12PM, parseDate,
} from '../../utils/date/date.helpers';
import { noop } from '../../utils/function.helpers';
import { createRenderDatepickerHeader } from './createDatepickerHeaderRenderer.util';

export enum DatepickerRole {
  Standalone = 'Standalone',
  RangeStartSelector = 'RangeStartSelector',
  RangeEndSelector = 'RangeEndSelector',
}

const calendarClassName = 'calendar-className';
const pickerStyle = ({ theme }: ThemeProps) => css`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .${calendarClassName} .react-datepicker__day {
    color: ${theme.textColors.primary};
  }

  .${calendarClassName} .react-datepicker__day--disabled {
    color: ${theme.textColors.disabled}
  }

  .${calendarClassName} .react-datepicker__day--keyboard-selected,
  .${calendarClassName} .react-datepicker__day:hover {
    background-color: ${theme.backgroundColors.secondaryHover};
  }

  .${calendarClassName} .react-datepicker__day--in-selecting-range {
    color: ${theme.textColors.quaternary};
  }
  .${calendarClassName} .react-datepicker__day--in-selecting-range:hover{
    color: ${theme.textColors.primary};
  }

  .${calendarClassName} .react-datepicker__day--in-selecting-range.react-datepicker__day--selected:hover,
  .${calendarClassName} .react-datepicker__day--selected {
    background-color: ${theme.backgroundColors.quaternary};
    color: ${theme.textColors.quaternary};
    outline: none;
  }

  .${calendarClassName} {
    border: 1px solid ${theme.borderColors.primary};
    background-color: ${theme.backgroundColors.primary};
    box-sizing: border-box;
    display: flex;
    z-index: 2000;
  }
  .${calendarClassName} .react-datepicker__header {
    background-color: ${theme.backgroundColors.secondary};
    border-bottom: 1px solid ${theme.borderColors.primary};
  }
  .${calendarClassName} .react-datepicker__current-month,
  .${calendarClassName} .react-datepicker__day-name {
    color: ${theme.textColors.secondary};
  }
`;

const pickerContainerStyle = css({
  zIndex: 1100,
});

const inputStyle = (isActive: boolean) => (theme: Theme) => {
  const { borderColor, borderColorActive, backgroundColorActive, backgroundColor } = getInputCommonColors(theme);

  return css({
    backgroundColor: isActive ? backgroundColorActive : backgroundColor, // override
    borderColor: isActive ? borderColorActive : borderColor, // override
  });
};

const applyDateConstrains = (date: Date | null, minDate?: Date, maxDate?: Date) => {
  if (!date) {
    return null;
  }

  if (minDate && date < minDate) {
    return minDate;
  }
  else if (maxDate && date > maxDate) {
    return maxDate;
  }

  return date;
};

type RoleProps =
  Readonly<{ role: DatepickerRole.Standalone }
  | { role: DatepickerRole.RangeStartSelector; end: string }
  | { role: DatepickerRole.RangeEndSelector; start: string }>;

export type DatepickerProps = Readonly<Partial<RoleProps> & {
  className?: string;
  closeOnSelect?: boolean;
  end?: string;
  locale?: Locale | string;
  maxDate?: string;
  minDate?: string;
  preferredPlacement?: Placement;
  selectedDate: string | null;
  start?: string;

  onChange: (newDate: string) => void;
}>;

export const DatePickerComponent = ({
  className,
  closeOnSelect = true,
  end,
  locale,
  maxDate,
  minDate,
  preferredPlacement = 'bottom-start',
  role,
  selectedDate,
  start,
  onChange,
  ...restProps
}: DatepickerProps) => {

  const theme = useTheme();

  const [isVisible, setIsVisible] = useState(false);
  const [textValue, setTextValue] = useState(selectedDate || '');

  const { refs, floatingStyles } = useFloating({
    placement: preferredPlacement,
    middleware: [offset({ mainAxis: -1 })],
    whileElementsMounted: autoUpdate,
  });

  const minDateInLocalTimeZone = useMemo(() => {
    return fromUTCDate12PMToCurrentTimezone12PM(minDate);
  }, [minDate]);

  const maxDateInLocalTimeZone = useMemo(() => {
    return fromUTCDate12PMToCurrentTimezone12PM(maxDate);
  }, [maxDate]);

  const selectedDateInLocalTimeZone = useMemo(() => {
    return fromUTCDate12PMToCurrentTimezone12PM(selectedDate);
  }, [selectedDate]);

  const startDateOrUndefined = role === DatepickerRole.RangeEndSelector ? start : undefined;
  const startDate = useMemo(() => {
    return fromUTCDate12PMToCurrentTimezone12PM(startDateOrUndefined);
  }, [startDateOrUndefined]);

  const endDateOrUndefined = role === DatepickerRole.RangeStartSelector ? end : undefined;
  const endDate = useMemo(() => {
    return fromUTCDate12PMToCurrentTimezone12PM(endDateOrUndefined);
  }, [endDateOrUndefined]);

  const setTextToDate = useCallback((newDate: Date | null) => {
    const newTextValue = newDate ? dateToLocaleDateString(newDate) : '';
    if (newTextValue.trim() !== textValue.trim()) {
      setTextValue(newTextValue);
    }
  }, [textValue]);

  const handleChange = useCallback((date: Date) => {
    const isoDate = fromCurrentTimezoneDate12PMtoUTC12PM(date);
    if (!isoDate) {
      return;
    }
    onChange(isoDate);
  }, [onChange]);

  const onSelect = useCallback((date: Date) => {
    handleChange(date);
    if (closeOnSelect) {
      setIsVisible(false);
    }
  }, [closeOnSelect, handleChange]);

  const onTextInputExecute = useCallback(() => {
    const dateConstrained = applyDateConstrains(parseDate(textValue),
      minDateInLocalTimeZone || undefined,
      maxDateInLocalTimeZone || undefined);

    if (dateConstrained) {
      setTextToDate(dateConstrained);
      handleChange(dateConstrained);
    }
    else {
      setTextToDate(selectedDateInLocalTimeZone);
    }
  }, [handleChange, maxDateInLocalTimeZone, minDateInLocalTimeZone, selectedDateInLocalTimeZone, setTextToDate,
    textValue]);

  const onTextBlur = useCallback(() => {
    if (!isVisible) {
      onTextInputExecute();
    }
  }, [isVisible, onTextInputExecute]);

  const onCalendarOpen = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onCalendarClose = useCallback(() => {
    onTextInputExecute();
    setIsVisible(false);
  }, [onTextInputExecute]);

  useEffect(() => {
    const keyPressHandler = ({ key }: KeyboardEvent) => {
      if (key === KeyboardKeys.Enter) {
        onTextInputExecute();
      }
    };

    const referenceElement = refs.reference.current as HTMLInputElement | null;
    referenceElement?.addEventListener('keyup', keyPressHandler);

    return () => {
      referenceElement?.removeEventListener('keyup', keyPressHandler);
    };
  }, [onTextInputExecute, refs.reference]);

  useEffect(() => {
    setTextToDate(selectedDateInLocalTimeZone);
    // we don't want to call this on textValue state change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateInLocalTimeZone]);

  return (
    <div {...restProps} className={className}>
      <Global styles={pickerStyle({ theme })} />
      <TextInputComponent
        css={inputStyle(isVisible)}
        onBlur={onTextBlur}
        onChange={setTextValue}
        onFocus={onCalendarOpen}
        ref={refs.setReference}
        value={textValue}
      />
      {isVisible && (
        <FloatingPortal>
          <div
            css={pickerContainerStyle}
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <DatePicker
              selected={selectedDateInLocalTimeZone}
              onChange={noop}
              onSelect={onSelect}
              calendarClassName={calendarClassName}
              renderCustomHeader={createRenderDatepickerHeader({ theme })}
              minDate={minDateInLocalTimeZone ?? undefined}
              maxDate={maxDateInLocalTimeZone ?? undefined}
              onClickOutside={onCalendarClose}
              selectsStart={role === DatepickerRole.RangeStartSelector}
              selectsEnd={role === DatepickerRole.RangeEndSelector}
              startDate={startDate ?? undefined}
              endDate={endDate ?? undefined}
              inline
              locale={locale}
            />
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
