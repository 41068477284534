export const removeUndefined = <T extends Record<string, unknown>>(obj: T): T => {
  const cloned = { ...obj };

  Object.keys(cloned).forEach(key => {
    if (cloned[key] === undefined) {
      delete cloned[key];
    }
  });

  return cloned;
};
