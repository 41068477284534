import { css } from '@emotion/react';
import {
  faBan,
  faCreditCard,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import {
  type ClientLicenseName,
  ClientLicenseState,
} from '~/_shared/types/client/license';
import { getClientLicenseStateTranslation } from '~/_shared/types/client/license.helpers';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { formatDate } from '~/_shared/utils/date/date.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { SubscriptionManagementCancelOption } from './subscriptionManagement.constants';

const HORIZONTAL_PADDING = 32;

const contentStyle = css({
  cursor: 'default',
  padding: 0,
});

const infoWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: `16px ${HORIZONTAL_PADDING}px`,
});

const infoRowStyle = css({
  display: 'flex',
  gap: 16,
});

const infoItemStyle = (isSmall?: boolean) => css({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  width: isSmall ? '40%' : '60%',
});

const uppercaseStyle = css({
  textTransform: 'uppercase',
});

const titleStyle = (theme: Theme) => css(uppercaseStyle, {
  color: theme.textColors.secondary,
  fontSize: 14,
});

const valueStyle = ({ theme, color }: ThemeProps<{color?: string}>) => css({
  color: color || theme.textColors.primary,
  fontSize: 16,
  textTransform: 'capitalize',
});

const sectionWrapperStyle = css({
  padding: `16px ${HORIZONTAL_PADDING}px`,
});

const dividerStyle = (theme: Theme) => css(uppercaseStyle, {
  border: `solid ${theme.borderColors.primary}`,
  borderWidth: '1px 0',
  color: theme.textColors.primary,
  fontSize: 18,
  padding: `8px ${HORIZONTAL_PADDING}px`,
});

const optionsStyle = css({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  gap: 8,
  textTransform: 'uppercase',
});

const cancellationWarningStyle = ({ theme, reactivable: reactivatable }: ThemeProps<{reactivable: boolean}>) => css({
  color: reactivatable ? theme.textColors.warning : theme.textColors.danger,
  fontSize: 14,
  margin: '16px 0 16px',
});

const cancellationIconStyle = css({ marginRight: 4 });

type SubscriptionManagementProps = ModalProps<{
  isLoading: boolean;
  isAutoRenewalOn: boolean;
  billed: string;
  licenseName: ClientLicenseName;
  licenseStatus: ClientLicenseState;
  nextBillingDate: Date | null;

  onCancelSubscription: (option: SubscriptionManagementCancelOption) => void;
  onLaunchPaymentPortal: () => void;
  onRestoreSubscription: () => void;
  onUpgradeSubscription?: () => void;
}>;

export const SubscriptionManagementComponent: React.FC<SubscriptionManagementProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const [cancelOptionsExpanded, setCancelOptionsExpanded] = useState(false);

  const [cancelationOption, setCancelationOption] = useState<SubscriptionManagementCancelOption>(
    props.isAutoRenewalOn ? SubscriptionManagementCancelOption.AtEnd : SubscriptionManagementCancelOption.NowAndDeleteData,
  );

  const onCancelSubscription = useCallback(() => {
    const cancelSubscription = props.onCancelSubscription;
    cancelSubscription(cancelationOption);
  }, [cancelationOption, props.onCancelSubscription]);

  const handleLaunchPaymentPortal = useCallback(() => {
    const launch = props.onLaunchPaymentPortal;
    const close = props.onClose;
    launch();
    close();
  }, [props.onLaunchPaymentPortal, props.onClose]);

  const licenseStatusColor = useMemo(() => {
    switch (props.licenseStatus) {
      case ClientLicenseState.ACTIVE: {
        if (props.isAutoRenewalOn) {
          return theme.textColors.success;
        }
        else {
          return theme.textColors.danger;
        }
      }

      case ClientLicenseState.EXPIRED:
        return theme.textColors.danger;

      case ClientLicenseState.FAILED_PAY_PERIOD:
      case ClientLicenseState.GRACE_PERIOD:
        return theme.textColors.warning;

      default:
        return theme.textColors.primary;
    }
  }, [props.isAutoRenewalOn, props.licenseStatus, theme.textColors.danger, theme.textColors.primary,
    theme.textColors.success, theme.textColors.warning]);

  const licenseStatus = useMemo(() => {
    if (!props.isAutoRenewalOn && props.licenseStatus === ClientLicenseState.ACTIVE) {
      return t('SubscriptionManagement.LicenseState.renewalCanceled');
    }
    return getClientLicenseStateTranslation(props.licenseStatus, t);
  }, [props.isAutoRenewalOn, props.licenseStatus, t]);

  const isLicenseReactivable = cancelationOption === SubscriptionManagementCancelOption.AtEnd;

  const actionsData: AccordionData[] = useMemo(() => {
    return [{
      child: (
        <>
          <div css={optionsStyle}>
            <RadioGroupComponent<SubscriptionManagementCancelOption>
              items={[{
                isDisabled: !props.isAutoRenewalOn || props.licenseStatus !== ClientLicenseState.ACTIVE,
                label: t('SubscriptionManagement.CancelOptions.End'),
                value: SubscriptionManagementCancelOption.AtEnd,
              }, /*{ Will be added in the future
                label: t('SubscriptionManagement.CancelOptions.Now'),
                value: SubscriptionManagementCancelOptions.Now,
              },*/ {
                label: t('SubscriptionManagement.CancelOptions.NowAndDeleteData'),
                value: SubscriptionManagementCancelOption.NowAndDeleteData,
              }]}
              onValueChange={setCancelationOption}
              selectedValue={cancelationOption}
            />
          </div>
          <div css={cancellationWarningStyle({ theme, reactivable: isLicenseReactivable })}>
            {!isLicenseReactivable ? (
              <>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color={theme.textColors.danger}
                  css={cancellationIconStyle}
                />
                {t('SubscriptionManagement.ReactivateOptions.Impossible')}
              </>
            ) : t('SubscriptionManagement.ReactivateOptions.Possible')}
          </div>

          <ButtonComponent
            buttonStyle={ButtonStyle.Danger}
            onClick={onCancelSubscription}
            prefixIcon={faBan}
            text={t('Cancel Subscription')}
          />
        </>
      ),
      header: <div css={uppercaseStyle}>{t('SubscriptionManagement.CancelOptions')}</div>,
      isExpanded: cancelOptionsExpanded,
      onHeadingClick: () => setCancelOptionsExpanded(state => !state),
    }];
  }, [props.isAutoRenewalOn, props.licenseStatus, t, cancelationOption, theme, isLicenseReactivable,
    onCancelSubscription, cancelOptionsExpanded]);

  return (
    <ModalComponent
      contentStyle={contentStyle}
      caption={t('Manage Subscription')}
      onClose={props.onClose}
      isOpen={props.isOpen}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
      skipCloseOnMapIdChange
    >
      <div>
        <div css={infoWrapperStyle}>
          <div css={infoRowStyle}>
            <div css={infoItemStyle()}>
              <div css={titleStyle(theme)}>{t('License Type')}</div>
              <div css={valueStyle({ theme })}>{props.licenseName}</div>
              {props.onUpgradeSubscription && (
                <div>
                  <ButtonComponent
                    onClick={props.onUpgradeSubscription}
                    size="Small"
                    text={t('Upgrade')}
                  />
                </div>
              )}
            </div>
            <div css={infoItemStyle(true)}>
              <div css={titleStyle(theme)}>{t('License Status')}</div>
              <div css={valueStyle({ theme, color: licenseStatusColor })}>{licenseStatus}</div>
              {!props.isAutoRenewalOn && props.licenseStatus === ClientLicenseState.ACTIVE && (
                <div>
                  <ButtonComponent
                    isDisabled={props.isLoading}
                    onClick={props.onRestoreSubscription}
                    size="Small"
                    text={t('Restore')}
                  />
                </div>
              )}
            </div>
          </div>

          <div css={infoRowStyle}>
            <div css={infoItemStyle()}>
              <div css={titleStyle(theme)}>{t('Billed')}</div>
              <div css={valueStyle({ theme })}>{props.billed}</div>
            </div>
            <div css={infoItemStyle(true)}>
              <div css={titleStyle(theme)}>{t('Next Billing Date')}</div>
              <div css={valueStyle({ theme })}>
                {props.nextBillingDate ? formatDate(props.nextBillingDate) : t('N/A')}
              </div>
            </div>
          </div>
        </div>

        <div css={dividerStyle}>{t('Actions')}</div>

        <div css={sectionWrapperStyle}>
          <ButtonComponent
            onClick={handleLaunchPaymentPortal}
            prefixIcon={faCreditCard}
            text={t('Launch Payment Portal')}
          />
        </div>

        <OutlinePanelComponent>
          <AccordionComponent
            data={actionsData}
            panelStyle={sectionWrapperStyle}
          />
        </OutlinePanelComponent>
      </div>
    </ModalComponent>
  );
};
SubscriptionManagementComponent.displayName = 'SubscriptionManagementComponent';
