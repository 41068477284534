export const hasNativeElementsFromPoint = document && document.elementsFromPoint;

export function getDropTargetElementsAtPoint(x: number, y: number, dropTargets: any[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (document.msElementsFromPoint) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return document.msElementsFromPoint(x, y);
  }
  return dropTargets.filter((t) => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left &&
      x <= rect.right &&
      y <= rect.bottom &&
      y >= rect.top
    );
  });
}
