import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingPolygonSettingsState } from './drawingPolygonSettings.state';

export const drawingPolygonSettingsInitialState: DrawingPolygonSettingsState = {
  strokeOpacity: 100,
  fillOpacity: 50,
  strokeWeight: 2,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
};

export const drawingPolygonSettingsReducer = (
  state = drawingPolygonSettingsInitialState, action: DrawingSettingsAction
): DrawingPolygonSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Polygon) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.polygon,
      };
    }

    default:
      return state;
  }
};
