import { useSelector } from '../../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../../app.store';
import {
  isBoundarySelectActive,
  isBoundarySelectModalOpen,
} from './boundarySelect.state';

export const boundarySelectSelector = (state: AppState) => state.frontendState.mapTools.boundary.boundarySelect;
export const useBoundarySelectSelector = () => useSelector(boundarySelectSelector);

const boundarySelectIsLassoLoadingSelector = (state: AppState) => state.frontendState.mapTools.boundary.boundarySelect.isLassoSelectLoading;
export const useBoundarySelectIsLassoLoadingSelector = () => useSelector(boundarySelectIsLassoLoadingSelector);

const boundarySelectActiveSelector = (state: AppState) => {
  const { boundarySelect } = state.frontendState.mapTools.boundary;
  return isBoundarySelectActive(boundarySelect) ? boundarySelect : null;
};
export const useBoundarySelectActiveSelector = () => useSelector(boundarySelectActiveSelector);

const boundarySelectModalOpenSelector = (state: AppState) => {
  const { boundarySelect } = state.frontendState.mapTools.boundary;
  return isBoundarySelectModalOpen(boundarySelect) ? boundarySelect : null;
};
export const useBoundarySelectModalOpenSelector = () => useSelector(boundarySelectModalOpenSelector);
