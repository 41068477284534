export enum Breakpoint {
  xSmall='xSmall',
  small='small',
  mid='mid',
  large='large',
  xLarge='xLarge',
  xxLarge='xxLarge'
}

export const breakpoints: { [key in Breakpoint]: number} = {
  [Breakpoint.xSmall]: 400,
  [Breakpoint.small]: 480,
  [Breakpoint.mid]: 768,
  [Breakpoint.large]: 992,
  [Breakpoint.xLarge]: 1200,
  [Breakpoint.xxLarge]: 1600,
};

export const mq = (query: Breakpoint | number) => {
  const screenWidth = breakpoints[query as Breakpoint] || query;

  return `@media (min-width: ${screenWidth}px)`;
};
