import { css } from '@emotion/react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

type IntegratedColumnProps = Readonly<{
  mapName: string;
  columnName: string;
}>;

const mapColumnItemStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'flex-start',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  height: 60,
  justifyContent: 'center',
  padding: '0 12px',
});

const labelValueContainerStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
});

const labelStyle = css({
  fontWeight: 400,
  marginRight: 12,
});

const valueStyle = css({
  fontWeight: 700,
});

export const IntegratedColumnComponent: React.FC<IntegratedColumnProps> = ({
  mapName,
  columnName,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={mapColumnItemStyle({ theme })}>
      <div css={labelValueContainerStyle}>
        <span css={labelStyle}>{t('Map Name').toUpperCase()}:</span>
        <span css={valueStyle}>{mapName}</span>
      </div>
      <div css={labelValueContainerStyle}>
        <span css={labelStyle}>{t('Column Name').toUpperCase()}:</span>
        <span css={valueStyle}>{columnName}</span>
      </div>
    </div>
  );
};
