import { useCallback } from 'react';
import { type MarkerLabelStyles } from '~/_shared/types/marker.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useMoveMarkerLabelOffsetsSelector } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.selectors';
import { useMapSettingsLabelMarkerOffsets } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';

export const useMoveMarkerLabelOffsets = () => {
  const customMarkerLabelOffsets = useMapSettingsLabelMarkerOffsets();
  const temporaryMarkerLabelOffsets = useMoveMarkerLabelOffsetsSelector();

  const applyCustomLabelOffsets = useCallback(
    (id: SpreadsheetRowId, label: MarkerLabelStyles): MarkerLabelStyles => {
      const mapSettingsOffset = customMarkerLabelOffsets[id.spreadsheetId]?.[id.rowId];
      const temporaryOffset = temporaryMarkerLabelOffsets.get(id);

      const customOffset = temporaryOffset ?? (mapSettingsOffset ? {
        type: 'custom',
        x: mapSettingsOffset.x,
        y: mapSettingsOffset.y,
      } : null);

      if (!customOffset) {
        return label;
      }

      return {
        ...label,
        offsetProps: customOffset,
      };
    }, [customMarkerLabelOffsets, temporaryMarkerLabelOffsets]);

  return {
    applyCustomLabelOffsets,
  };
};
