import { type LatLng } from '~/_shared/types/latLng';
import { MarkerColor } from '~/_shared/types/marker.types';
import { arrayEquals } from '~/_shared/utils/array/array.helpers';
import { createUuid } from '~/_shared/utils/createUuid';
import { noop } from '~/_shared/utils/function.helpers';
import {
  createLabelWithTextBoxCalloutLayer,
  type LabelWithTextBoxCalloutConfig,
  type LabelWithTextBoxCalloutlLayer,
  type WebglOverlayLabelWithTextBoxCallout,
} from '~/_shared/utils/webgl/labelWithCallout';
import { webglColorChanged } from '~/_shared/utils/webgl/webglColor.helpers';
import { PredefinedTemplate } from '../../markers/manager/mapMarkerTemplates';
import { type MapArrowInstance } from '../mapArrow/mapArrowModel';
import { type MapCircleInstance } from '../mapCircle/mapCircleModel';
import { type MapMarkerInstance } from '../mapMarker/mapMarkerModel';
import {
  type MapObjectArrowConfig,
  type MapObjectCircleAreaConfig,
  type MapObjectCustomMarkerConfig,
  MapObjectLabelLetterSpacing,
  MapObjectLabelTextSize,
  MapObjectLabelType,
  MapObjectMarkerType,
  type MapObjectOutlineCircleConfig,
  type MapObjectOutlineConfig,
  MapObjectOutlineSize,
  MapObjectOutlineThinCircleSize,
  type MapObjectPolygonConfig,
  type MapObjectPolylineConfig,
} from '../mapObject.types';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';
import { type MapShapeInstance } from '../mapShape/mapShapeModel';

const dotMarkerProps = {
  color: MarkerColor.Black,
  colorOverlay: true,
  size: MapObjectOutlineSize.Small,
  template: PredefinedTemplate.Dot,
  offset: { x: 0, y: 0 },
};

const transparentDotMarkerProps = {
  ...dotMarkerProps,
  template: PredefinedTemplate.TransparentDot,
};

const xMarkMarkerProps = {
  color: MarkerColor.Black,
  size: 17.5,
  template: PredefinedTemplate.XMark,
};

const circleMarkerProps = {
  colorOverlay: true,
  size: MapObjectOutlineThinCircleSize.Default,
  template: PredefinedTemplate.CircleThick,
  offset: { x: 0, y: 0 },
};

// Ordered by priority
export const xMarkMarkerOffsets = {
  topRight: { x: 15, y: -15 },
  topLeft: { x: -15, y: -15 },
  right: { x: 20, y: 0 },
  left: { x: -20, y: 0 },
  bottomRight: { x: 15, y: 15 },
  bottomLeft: { x: -15, y: 15 },
} as const;
type XMarkPosition = keyof typeof xMarkMarkerOffsets;
const xMarkPositions = Object.keys(xMarkMarkerOffsets) as [XMarkPosition];

export const createMapObjectLayers = () => ({
  MapObjectMarkers: new WebGLOverlay.MarkerLayer(),
  MapObjectPolylines: new WebGLOverlay.PolylineLayer(),
  MapObjectPolygons: new WebGLOverlay.PolygonLayer(),
  MapObjectLabels: new WebGLOverlay.LabelLayer('MapObjectLabels', { autoHide: false }),
  MapObjectTextBoxCallouts: new WebGLOverlay.TextBoxCalloutLayer(),
  MapObjectCirles: new WebGLOverlay.AreaLayer(),
  MapObjectArrows: new WebGLOverlay.ArrowLayer('MapObjectArrows'),
}) as const;

type MapObjectWebglLayers = ReturnType<typeof createMapObjectLayers>;

type AddEventListenerParams = [MapElementEventName, () => void] | ['click', MapObjectClickEvent] | ['rightclick', MapObjectClickEvent];

const registerListener = <T extends MapObject>(getMapObject: () => T | undefined | null, ...params: AddEventListenerParams) => {
  emitterCall(getMapObject(), 'addEventListener', ...params);
  return () => emitterCall(getMapObject(), 'removeEventListener', ...params);
};

const emitterCall = <T extends MapObject,
  OP extends keyof EventEmitter>(object: T | undefined | null, operation: OP, ...params: AddEventListenerParams) => {
  if (params[0] === 'click') {
    object?.[operation]?.(params[0], params[1]);
  }
  else {
    object?.[operation]?.(params[0], params[1]);
  }
};

export class MapObjectManager {
  public readonly map: google.maps.Map;
  private readonly overlayLayers: MapObjectWebglLayers;
  private readonly labelWithCalloutLayer: LabelWithTextBoxCalloutlLayer;

  private readonly lookUp = new MapObjectIdLookup();

  constructor(map: google.maps.Map, requiredLayers: MapObjectWebglLayers) {
    this.map = map;
    this.overlayLayers = requiredLayers;
    this.labelWithCalloutLayer = createLabelWithTextBoxCalloutLayer(
      requiredLayers.MapObjectLabels,
      requiredLayers.MapObjectTextBoxCallouts
    );
  }

  //#region Outline Markers
  //#################

  public createOutlineFromLatLng = (latLng: LatLng) => ({
    id: createUuid(),
    lat: latLng.lat,
    lng: latLng.lng,
  });

  public insertOutlineMarker = (outline: MapObjectOutlineInstance, config?: MapObjectOutlineConfig) => {
    if (this.lookUp.findMarker(MapObjectMarkerType.Outline, outline.id)) {
      return;
    }

    const marker = new WebGLOverlay.Marker({
      ...(config?.transparent ? transparentDotMarkerProps : dotMarkerProps),
      ...outline,
      ...config,
      offset: {
        x: config?.offsetX ?? 0,
        y: config?.offsetY ?? 0,
      },
    });

    this.overlayLayers.MapObjectMarkers.add(marker);
    this.lookUp.setMarker(MapObjectMarkerType.Outline, outline.id, marker);

    return marker;
  };

  public updateOutlineMarker = (outline: MapObjectOutlineInstance, config?: MapObjectOutlineConfig) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outline.id);

    if (!marker) {
      throw new Error(
        `updateOutlineMarker: Cannot update marker. Marker for outline ${outline.id} was not found.`);
    }

    if (marker.lat !== outline.lat) {
      marker.lat = outline.lat;
    }

    if (marker.lng !== outline.lng) {
      marker.lng = outline.lng;
    }

    if (config && webglColorChanged(marker.color, config.color)) {
      marker.color.set(...config.color);
    }

    if ((config?.offsetX ?? 0) !== marker.offset.x || (config?.offsetY ?? 0) !== marker.offset.y) {
      marker.offset = {
        x: config?.offsetX ?? 0,
        y: config?.offsetY ?? 0,
      };
    }

    if (config?.size && marker.size !== config.size) {
      marker.size = config.size;
    }

    if (config?.zIndex && marker.zIndex !== config.zIndex) {
      marker.zIndex = config.zIndex;
    }

    const newTemplate = (config?.transparent ? transparentDotMarkerProps : dotMarkerProps).template;
    if (marker.template !== newTemplate) {
      marker.template = newTemplate;
    }

    this.updateOutlineLabelPosition(outline);

    return marker;
  };

  public upsertOutlineMarker = (outline: MapObjectOutlineInstance, config?: MapObjectOutlineConfig) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outline.id);

    if (marker) {
      return this.updateOutlineMarker(outline, config);
    }
    else {
      return this.insertOutlineMarker(outline, config);
    }
  };

  public removeOutlineMarker = (outlineId: Uuid) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outlineId);
    if (marker) {
      this.lookUp.removeMarker(MapObjectMarkerType.Outline, outlineId);
      this.overlayLayers.MapObjectMarkers.remove(marker);
    }

    this.removeOutlineLabel(outlineId);
  };

  public setOutlineMarkerColor = (outlineId: Uuid, color: WebglColor = dotMarkerProps.color) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outlineId);
    if (marker && webglColorChanged(marker.color, color)) {
      marker.color.set(...color);
    }
  };

  public setOutlineMarkerSize = (outlineId: Uuid, size: number = dotMarkerProps.size) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outlineId);
    if (marker && marker.size !== size) {
      marker.size = size;
    }
  };

  public addOutlineMarkerEventListener = (outlineId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findMarker(MapObjectMarkerType.Outline, outlineId), ...params);

  //#endregion

  //#region Arrow
  //#################
  public upsertArrow = (arrowData: MapArrowInstance, visuals: MapObjectArrowConfig) => {
    const arrow = this.lookUp.findArrow(arrowData.id);

    if (arrow) {
      return this.updateArrow(arrowData, visuals);
    }
    else {
      return this.insertArrow(arrowData, visuals);
    }
  };

  private insertArrow(arrowData: MapArrowInstance, visuals: MapObjectArrowConfig) {
    const arrow = new WebGLOverlay.Arrow({
      tipLatLng: arrowData.tipLatLng,
      tailLatLng: arrowData.tailLatLng,
      tipLength: visuals.tipLength,
      tipWidth: visuals.tipWidth,
      width: visuals.width,
      color: visuals.color,
      strokeColor: visuals.strokeColor,
      strokeWidth: visuals.strokeWidth,
      staticLength: arrowData.staticLength,
      zIndex: visuals.zIndex,
      sizeOnLevel: visuals.sizeOnLevel,
    });

    this.overlayLayers.MapObjectArrows.add(arrow);
    this.lookUp.setArrow(arrowData.id, arrow);

    return arrow;
  }

  private updateArrow(arrowData: MapArrowInstance, visuals: MapObjectArrowConfig) {
    const arrow = this.lookUp.findArrow(arrowData.id);

    if (!arrow) {
      throw new Error(
        `updateArrow: Cannot update arrow. Arrow of id ${arrowData.id} was not found.`);
    }

    if (arrow.tipLatLng.lat !== arrowData.tipLatLng.lat) {
      arrow.tipLatLng.lat = arrowData.tipLatLng.lat;
    }
    if (arrow.tipLatLng.lng !== arrowData.tipLatLng.lng) {
      arrow.tipLatLng.lng = arrowData.tipLatLng.lng;
    }
    if (arrow.tailLatLng.lat !== arrowData.tailLatLng.lat) {
      arrow.tailLatLng.lat = arrowData.tailLatLng.lat;
    }
    if (arrow.tailLatLng.lng !== arrowData.tailLatLng.lng) {
      arrow.tailLatLng.lng = arrowData.tailLatLng.lng;
    }

    if (arrowData.staticLength !== arrow.staticLength) {
      arrow.staticLength = arrowData.staticLength;
    }

    if (visuals.zIndex !== arrow.zIndex) {
      arrow.zIndex = visuals.zIndex;
    }

    if (visuals.tipLength !== undefined && visuals.tipLength !== arrow.tipLength) {
      arrow.tipLength = visuals.tipLength;
    }

    if (visuals.tipWidth !== undefined && visuals.tipWidth !== arrow.tipWidth) {
      arrow.tipWidth = visuals.tipWidth;
    }

    if (visuals.width !== undefined && visuals.width !== arrow.width) {
      arrow.width = visuals.width;
    }

    if (visuals.sizeOnLevel !== undefined && visuals.sizeOnLevel !== arrow.sizeOnLevel) {
      arrow.sizeOnLevel = visuals.sizeOnLevel;
    }

    if (webglColorChanged(arrow.color, visuals.color)) {
      arrow.color.set(...visuals.color);
    }

    if (webglColorChanged(arrow.strokeColor, visuals.strokeColor)) {
      arrow.strokeColor.set(...visuals.strokeColor);
    }

    if (visuals.strokeWidth !== undefined && visuals.strokeWidth !== arrow.strokeWidth) {
      arrow.strokeWidth = visuals.strokeWidth;
    }

    return arrow;
  }

  public removeArrow(arrowId: Uuid) {
    const arrow = this.lookUp.findArrow(arrowId);

    if (arrow) {
      this.lookUp.removeArrow(arrowId);
      this.overlayLayers.MapObjectArrows.remove(arrow);
    }
  }

  public addArrowEventListener = (arrowId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findArrow(arrowId), ...params);

  //#endregion

  //#region Custom Markers
  //#################
  public upsertCustomMarker = (markerData: MapMarkerInstance, visuals: MapObjectCustomMarkerConfig, zIndex: number) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Custom, markerData.id);

    if (marker) {
      return this.updateCustomMarker(markerData, visuals, zIndex);
    }
    else {
      return this.insertCustomMarker(markerData, visuals, zIndex);
    }
  };

  private insertCustomMarker(markerData: MapMarkerInstance, visuals: MapObjectCustomMarkerConfig, zIndex: number) {
    const customMarker = new WebGLOverlay.Marker({
      lat: markerData.lat,
      lng: markerData.lng,
      zIndex,
      anchor: markerData.anchor,
      size: markerData.size,
      ...visuals,
    });

    this.overlayLayers.MapObjectMarkers.add(customMarker);
    this.lookUp.setMarker(MapObjectMarkerType.Custom, markerData.id, customMarker);

    return customMarker;
  }

  private updateCustomMarker(markerData: MapMarkerInstance, visuals: MapObjectCustomMarkerConfig, zIndex: number) {
    const customMarker = this.lookUp.findMarker(MapObjectMarkerType.Custom, markerData.id);

    if (!customMarker) {
      throw new Error(
        `updateSpriteMarker: Cannot update marker. Marker of id ${markerData.id} was not found.`);
    }

    if (customMarker.template !== visuals.template) {
      customMarker.template = visuals.template;
    }

    if (customMarker.lat !== markerData.lat) {
      customMarker.lat = markerData.lat;
    }

    if (customMarker.lng !== markerData.lng) {
      customMarker.lng = markerData.lng;
    }

    if (customMarker.zIndex !== zIndex) {
      customMarker.zIndex = zIndex;
    }

    if (customMarker.anchor !== markerData.anchor) {
      customMarker.anchor = markerData.anchor;
    }

    if (customMarker.size !== markerData.size) {
      customMarker.size = markerData.size;
    }

    if (webglColorChanged(customMarker.color, visuals.color)) {
      customMarker.color.set(...visuals.color);
    }

    if ((visuals.colorOverlay ?? false) !== customMarker.colorOverlay) {
      customMarker.colorOverlay = visuals.colorOverlay ?? false;
    }

    if ((visuals.offset?.x ?? 0) !== customMarker.offset.x || (visuals.offset?.y ?? 0) !== customMarker.offset.y) {
      customMarker.offset = {
        x: visuals.offset?.x ?? 0,
        y: visuals.offset?.y ?? 0,
      };
    }

    if ((visuals.textureOffset?.x ?? 0) !== customMarker.textureOffset.x || (visuals.textureOffset?.y ?? 0) !== customMarker.textureOffset.y) {
      customMarker.textureOffset = {
        x: visuals.textureOffset?.x ?? 0,
        y: visuals.textureOffset?.y ?? 0,
      };
    }

    if (visuals.opacity !== undefined && customMarker.opacity !== visuals.opacity) {
      customMarker.opacity = visuals.opacity;
    }

    if (visuals.opacityBackground !== undefined && customMarker.backgroundOpacity !== visuals.opacityBackground) {
      customMarker.backgroundOpacity = visuals.opacityBackground;
    }

    if (visuals.interactive !== undefined && customMarker.interactive !== visuals.interactive) {
      customMarker.interactive = visuals.interactive;
    }

    return customMarker;
  }

  public removeCustomMarker(markerId: Uuid) {
    const iconMarker = this.lookUp.findMarker(MapObjectMarkerType.Custom, markerId);

    if (iconMarker) {
      this.lookUp.removeMarker(MapObjectMarkerType.Custom, markerId);
      this.overlayLayers.MapObjectMarkers.remove(iconMarker);
    }
  }

  public addCustomMarkerEventListener = (markerId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findMarker(MapObjectMarkerType.Custom, markerId), ...params);

  //#endregion

  //#region X-Mark Markers
  //#################

  private getNeighboringOutlineIndexes = (params: { isPolygon: boolean; isLast: boolean; isFirst: boolean; currentIndex: number; total: number }): number[] => {
    if (params.isFirst) {
      if (!params.isPolygon) {
        return [1];
      }

      return [1, params.total - 1];
    }

    if (params.isLast) {
      if (!params.isPolygon) {
        return [params.total - 2];
      }

      return [params.total - 2, 0];
    }

    return [params.currentIndex - 1, params.currentIndex + 1];
  };

  private calculateMarkerPosition = (instance: MapShapeInstance, anchor: MapObjectOutlineInstance, isPolygon: boolean) => {
    if (instance.outlines.length <= 1) {
      return this.calculateXMarkMarkerOffset(anchor, []);
    }

    const anchorIndex = instance.outlines.findIndex(outline => outline === anchor);
    const isLast = anchorIndex === instance.outlines.length - 1;
    const isFirst = anchorIndex === 0;
    const total = instance.outlines.length;
    const indexes = this.getNeighboringOutlineIndexes({ isPolygon, currentIndex: anchorIndex, total, isLast, isFirst });

    return this.calculateXMarkMarkerOffset(anchor, indexes.map(index => instance.outlines[index]));
  };

  public upsertXMarkMarker = (instanceId: Uuid, anchor: LatLng, zIndex: number,
    offset: { x: number; y: number }, color: WebglColor = xMarkMarkerProps.color) => {
    const existing = this.lookUp.findMarker(MapObjectMarkerType.XMark, instanceId);

    if (existing) {
      if (existing.lat !== anchor.lat) {
        existing.lat = anchor.lat;
      }

      if (existing.lng !== anchor.lng) {
        existing.lng = anchor.lng;
      }

      if (existing.zIndex !== zIndex) {
        existing.zIndex = zIndex;
      }

      if (webglColorChanged(existing.color, color)) {
        existing.color.set(...color);
      }

      if ((offset.x ?? 0) !== existing.offset.x || (offset.y ?? 0) !== existing.offset.y) {
        existing.offset = {
          x: offset.x,
          y: offset.y,
        };
      }
    }
    else {
      const marker = new WebGLOverlay.Marker({
        ...xMarkMarkerProps,
        ...anchor,
        offset,
        zIndex,
        color,
      });

      this.overlayLayers.MapObjectMarkers.add(marker);
      this.lookUp.setMarker(MapObjectMarkerType.XMark, instanceId, marker);
    }
  };

  public upsertMapShapeXMarkMarker = (instance: MapShapeInstance, anchor: MapObjectOutlineInstance, zIndex: number,
    isPolygon: boolean = false, color: WebglColor = xMarkMarkerProps.color) => {
    if (!instance.outlines.length) {
      return;
    }

    const xMarkerPosition = this.calculateMarkerPosition(instance, anchor, isPolygon);
    const offset = xMarkMarkerOffsets[xMarkerPosition];

    this.upsertXMarkMarker(instance.id, anchor, zIndex, offset, color);
  };

  public removeXMarkMarker = (instanceId: Uuid) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.XMark, instanceId);
    if (marker) {
      this.lookUp.removeMarker(MapObjectMarkerType.XMark, instanceId);
      this.overlayLayers.MapObjectMarkers.remove(marker);
    }
  };

  public setXMarkMarkerColor = (instanceId: Uuid, color: WebglColor = xMarkMarkerProps.color) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.XMark, instanceId);
    if (marker) {
      marker.color.set(...color);
    }
  };

  public addXMarkMarkerEventListener = (instanceId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findMarker(MapObjectMarkerType.XMark, instanceId), ...params);

  private calculateXMarkMarkerOffset = (anchor: MapObjectOutlineInstance, other: MapObjectOutlineInstance[]) => {
    const allAvailablePositions = [...xMarkPositions];
    if (other.length === 0) {
      return allAvailablePositions[0];
    }

    const suitablePositions = other.reduce((remainingSuitablePositions: [XMarkPosition], otherOutline) => {
      return remainingSuitablePositions.filter(this.suitableXMarkPositionsPredicate(anchor, otherOutline));
    }, allAvailablePositions);

    return suitablePositions[0];
  };

  private suitableXMarkPositionsPredicate = (anchor: LatLng, other: LatLng) => (position: XMarkPosition) => {
    switch (position) {
      case 'right':
        return !(anchor.lng < other.lng);
      case 'left':
        return !(anchor.lng > other.lng);
      case 'topRight':
        return !(anchor.lng < other.lng && anchor.lat < other.lat);
      case 'topLeft':
        return !(anchor.lng > other.lng && anchor.lat < other.lat);
      case 'bottomRight':
        return !(anchor.lng < other.lng && anchor.lat > other.lat);
      case 'bottomLeft':
        return !(anchor.lng > other.lng && anchor.lat > other.lat);
      default:
        return true;
    }
  };

  //#endregion

  //#region Circle Markers
  //#################

  public upsertOutlineCircleMarker = (outline: MapObjectOutlineInstance, config?: MapObjectOutlineCircleConfig) => {
    const existing = this.lookUp.findMarker(MapObjectMarkerType.Cirle, outline.id);

    if (existing) {
      if (existing.lat !== outline.lat) {
        existing.lat = outline.lat;
      }

      if (existing.lng !== outline.lng) {
        existing.lng = outline.lng;
      }

      if (config?.zIndex !== undefined && existing.zIndex !== config.zIndex) {
        existing.zIndex = config.zIndex;
      }

      if (config && webglColorChanged(existing.color, config.color)) {
        existing.color.set(...config.color);
      }

      if (config?.size !== undefined && existing.size !== config.size) {
        existing.size = config.size;
      }

      if ((config?.offsetX ?? 0) !== existing.offset.x || (config?.offsetY ?? 0) !== existing.offset.y) {
        existing.offset = {
          x: config?.offsetX ?? 0,
          y: config?.offsetY ?? 0,
        };
      }

      const newTemplate = config?.style === 'thick' ? PredefinedTemplate.CircleThick : PredefinedTemplate.Circle;
      if (existing.template !== newTemplate) {
        existing.template = newTemplate;
      }
    }
    else {
      const marker = new WebGLOverlay.Marker({
        ...circleMarkerProps,
        ...outline,
        ...config,
        template: config?.style === 'thick' ? PredefinedTemplate.CircleThick : PredefinedTemplate.Circle,
        offset: {
          x: config?.offsetX ?? 0,
          y: config?.offsetY ?? 0,
        },
      });

      this.overlayLayers.MapObjectMarkers.add(marker);
      this.lookUp.setMarker(MapObjectMarkerType.Cirle, outline.id, marker);
    }
  };

  public removeOutlineCircleMarker = (outlineId: Uuid) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Cirle, outlineId);

    if (marker) {
      this.lookUp.removeMarker(MapObjectMarkerType.Cirle, outlineId);
      this.overlayLayers.MapObjectMarkers.remove(marker);
    }
  };

  //#endregion

  //#region Labels
  //#################
  public upsertLabel = (id: Uuid, config: LabelWithTextBoxCalloutConfig) => {
    const labelWithCallout = this.lookUp.findLabel(MapObjectLabelType.Main, id);

    if (!labelWithCallout) {
      return this.insertLabel(id, config);
    }
    else {
      return this.updateLabel(id, config);
    }
  };

  private insertLabel = (id: Uuid, config: LabelWithTextBoxCalloutConfig) => {
    const labelWithCallout = this.labelWithCalloutLayer.create(config);

    this.labelWithCalloutLayer.add(labelWithCallout);

    this.lookUp.setLabel(MapObjectLabelType.Main, id, labelWithCallout);
    return labelWithCallout;
  };

  private updateLabel = (id: Uuid, config: LabelWithTextBoxCalloutConfig) => {
    const labelWithCallout = this.lookUp.findLabel(MapObjectLabelType.Main, id);
    if (!labelWithCallout) {
      throw new Error(
        `updateTextLabel: Cannot update label. Label ${id} was not found.`);
    }

    this.labelWithCalloutLayer.update(labelWithCallout, config);

    return labelWithCallout;
  };

  public removeLabel = (labelId: string) => this.removeLabelInternal(MapObjectLabelType.Main, labelId);

  public insertOutlineLabel = (outlineId: Uuid, text: string, zIndex: number) => {
    const marker = this.lookUp.findMarker(MapObjectMarkerType.Outline, outlineId);
    if (!marker) {
      return;
    }

    const label = this.labelWithCalloutLayer.create({
      label: {
        text: {
          value: text,
          fillColor: [0, 0, 0, 1],
          fontSize: MapObjectLabelTextSize.Default,
          letterSpacing: MapObjectLabelLetterSpacing.Default,
          strokeColor: [0, 0, 0, 1],
          strokeWidth: 0.55,
        },
        offset: { x: 0, y: 30 },
        lat: marker.lat,
        lng: marker.lng,
        zIndex,
        interactive: false,
      },
    });

    this.labelWithCalloutLayer.add(label);

    this.lookUp.setLabel(MapObjectLabelType.Outline, outlineId, label);
  };

  public updateOutlineLabel = (outlineId: Uuid, text: string, zIndex: number) => {
    const label = this.lookUp.findLabel(MapObjectLabelType.Outline, outlineId);
    if (!label) {
      throw new Error(
        `updateOutlineLabel: Cannot update label. Label for outline ${outlineId} was not found.`);
    }

    this.labelWithCalloutLayer.update(label, {
      label: {
        text: {
          value: text,
        },
        zIndex,
      },
    });
  };

  public upsertOutlineLabel = (outlineId: Uuid, text: string, zIndex: number) => {
    const label = this.lookUp.findLabel(MapObjectLabelType.Outline, outlineId);

    if (!label) {
      this.insertOutlineLabel(outlineId, text, zIndex);
    }
    else {
      this.updateOutlineLabel(outlineId, text, zIndex);
    }
  };

  public removeOutlineLabel = (outlineId: Uuid) => this.removeLabelInternal(MapObjectLabelType.Outline, outlineId);

  private updateOutlineLabelPosition = (outline: MapObjectOutlineInstance) => {
    const label = this.lookUp.findLabel(MapObjectLabelType.Outline, outline.id);
    if (!label) {
      return;
    }

    this.labelWithCalloutLayer.update(label, { label: { lat: outline.lat, lng: outline.lng } });
  };

  public upsertCursorLabel = (position: LatLng, text: string, zIndex: number) => {
    const existing = this.lookUp.findLabel(MapObjectLabelType.Outline, 'cursor');
    if (existing) {
      this.labelWithCalloutLayer.update(existing, {
        label: {
          text: {
            value: text,
          },
          ...position,
          zIndex,
        },
      });
    }
    else {
      const label = this.labelWithCalloutLayer.create({
        label: {
          text: {
            value: text,
            fillColor: [0, 0, 0, 1],
            fontSize: MapObjectLabelTextSize.Default,
            letterSpacing: MapObjectLabelLetterSpacing.Default,
            strokeColor: [0, 0, 0, 1],
            strokeWidth: 0.55,
          },
          offset: { x: 0, y: 40 },
          ...position,
          zIndex,
        },
      });

      this.labelWithCalloutLayer.add(label);

      this.lookUp.setLabel(MapObjectLabelType.Outline, 'cursor', label);
    }
  };

  public removeCursorLabel = () => this.removeOutlineLabel('cursor');

  private removeLabelInternal = (labelType: MapObjectLabelType, id: Uuid) => {
    const label = this.lookUp.findLabel(labelType, id);
    if (!label) {
      return;
    }

    this.labelWithCalloutLayer.remove(label);
    this.lookUp.removeLabel(labelType, id);
  };

  public addLabelEventListener = (labelId: Uuid, ...params: AddEventListenerParams) => {
    const removeLabelListener = registerListener(() => this.lookUp.findLabel(MapObjectLabelType.Main, labelId)?.label, ...params);
    const removeCalloutListener = registerListener(() => this.lookUp.findLabel(MapObjectLabelType.Main, labelId)?.callout, ...params);

    return () => {
      removeLabelListener();
      removeCalloutListener();
    };
  };

  //#endregion

  //#region Polylines
  //#################

  public insertLine = (id: string, points: ReadonlyArray<LatLng>, config: MapObjectPolylineConfig) => {
    if (this.lookUp.findPolyline(id)) {
      return;
    }

    const line = new WebGLOverlay.Polyline({
      ...config,
      width: config.width,
      visible: true,
      stroke: false,
    }, points.map(p => ({ ...p }))); // copy points, webgl is updating LatLng the objects

    this.overlayLayers.MapObjectPolylines.add(line);
    this.lookUp.setPolyline(id, line);

    return line;
  };

  public updateLine = (id: string, points: ReadonlyArray<LatLng>, config: MapObjectPolylineConfig) => {
    const line = this.lookUp.findPolyline(id);

    if (!line) {
      throw new Error(
        `updateLine: Cannot update line. Line with id ${id} was not found.`);
    }

    if (!arrayEquals(line.points, points)) {
      line.points = points.map(p => ({ ...p })); // copy points, webgl is updating the LatLng objects
    }

    if (webglColorChanged(line.color, config.color)) {
      line.color.set(...config.color);
    }

    if (line.width !== config.width) {
      line.width = config.width;
    }

    if (line.zIndex !== config.zIndex) {
      line.zIndex = config.zIndex;
    }

    return line;
  };

  public upsertLine = (id: string, points: ReadonlyArray<LatLng>, config: MapObjectPolylineConfig) => {
    const line = this.lookUp.findPolyline(id);

    if (!line) {
      return this.insertLine(id, points, config);
    }
    else {
      return this.updateLine(id, points, config);
    }
  };

  public removeLine = (id: string) => {
    const line = this.lookUp.findPolyline(id);

    if (!line) {
      return;
    }

    this.overlayLayers.MapObjectPolylines.remove(line);
    this.lookUp.removePolyline(id);
  };

  public addPolylineEventListener = (polylineId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findPolyline(polylineId), ...params);

  //#endregion

  //#region Polygons
  //#################

  public insertPolygon = (instance: MapShapeInstance, config: MapObjectPolygonConfig) => {
    if (this.lookUp.findPolygon(instance.id)) {
      return;
    }

    const polygon = new WebGLOverlay.Polygon(
      {
        ...config,
        hasSelfIntersections: true,
        borderWidth: config.borderWidth ?? 0,
        dynamic: true,
      },
      [[instance.outlines]]
    );

    this.overlayLayers.MapObjectPolygons.add(polygon);
    this.lookUp.setPolygon(instance.id, { id: instance.id, polygon, points: instance.outlines, eventListeners: [] });

    return polygon;
  };

  public updatePolygon = (instance: MapShapeInstance, config: MapObjectPolygonConfig) => {
    const polygonData = this.lookUp.findPolygon(instance.id);

    if (!polygonData) {
      throw new Error(
        `updatePolygon: Cannot update polygon. Polygon for map shape with id ${instance.id} was not found.`);
    }

    const { polygon, points } = polygonData;

    if (points !== instance.outlines) {
      polygon.points = [[instance.outlines]];
    }
    if (webglColorChanged(polygon.fillColor, config.fillColor)) {
      polygon.fillColor.set(...config.fillColor);
    }
    if (webglColorChanged(polygon.borderColor, config.borderColor)) {
      polygon.borderColor.set(...config.borderColor ?? [0, 0, 0, 0]);
    }

    if (polygon.borderWidth !== config.borderWidth) {
      polygon.borderWidth = config.borderWidth ?? 0;
      polygon.border = !!config.borderWidth;
    }

    if (polygon.zIndex !== config.zIndex) {
      polygon.zIndex = config.zIndex;
    }

    if (polygon.borderZIndex !== config.borderZIndex) {
      polygon.borderZIndex = config.borderZIndex;
    }

    return polygon;
  };

  public upsertPolygon = (instance: MapShapeInstance, config: MapObjectPolygonConfig) => {
    const polygon = this.lookUp.findPolygon(instance.id);

    if (!polygon) {
      return this.insertPolygon(instance, config);
    }
    else {
      return this.updatePolygon(instance, config);
    }
  };

  public removePolygon = (mapShapeInstanceId: string) => {
    const polygonData = this.lookUp.findPolygon(mapShapeInstanceId);

    if (!polygonData) {
      return;
    }

    this.overlayLayers.MapObjectPolygons.remove(polygonData.polygon, false);
    this.lookUp.removePolygon(mapShapeInstanceId);
  };

  public addPolygonEventListener = (polygonId: Uuid, ...params: AddEventListenerParams) => {
    const polygonData = this.lookUp.findPolygon(polygonId);

    if (polygonData) {
      polygonData.eventListeners = [...polygonData.eventListeners, params];
      const removeListenerCallback = registerListener(() => this.lookUp.findPolygon(polygonId)?.polygon, ...params);

      return () => {
        const data = this.lookUp.findPolygon(polygonId);

        if (data) {
          data.eventListeners = data.eventListeners.filter(listener => listener !== params);
        }

        removeListenerCallback();
      };
    }

    return noop;
  };

  //#endregion

  //#region CircleArea
  //#################

  public insertCircleArea = (circle: MapCircleInstance, config?: MapObjectCircleAreaConfig) => {
    if (this.lookUp.findCircleArea(circle.id)) {
      return;
    }

    const circleArea = new WebGLOverlay.CircleArea({
      ...circle,
      ...config,
    });

    this.overlayLayers.MapObjectCirles.add(circleArea);
    this.lookUp.setCircleArea(circle.id, circleArea);

    return circleArea;
  };

  public updateCircleArea = (circle: MapCircleInstance, config?: MapObjectCircleAreaConfig) => {
    const circleArea = this.lookUp.findCircleArea(circle.id);

    if (!circleArea) {
      throw new Error(
        `updateCircleArea: Cannot update circleArea. CircleArea ${circle.id} was not found.`);
    }

    if (circleArea.lat !== circle.lat) {
      circleArea.lat = circle.lat;
    }

    if (circleArea.lng !== circle.lng) {
      circleArea.lng = circle.lng;
    }

    if (circleArea.radius !== circle.radius) {
      circleArea.radius = circle.radius;
    }

    if (circleArea.sizeOnLevel !== (circle.sizeOnLevel ?? 0)) {
      circleArea.sizeOnLevel = circle.sizeOnLevel ?? 0;
    }

    if (config) {
      if (webglColorChanged(circleArea.fillColor, config.fillColor)) {
        circleArea.fillColor.set(...config.fillColor);
      }
      if (webglColorChanged(circleArea.borderColor, config.borderColor)) {
        circleArea.borderColor.set(...config.borderColor);
      }

      if (circleArea.borderWidth !== config.borderWidth) {
        circleArea.borderWidth = config.borderWidth;
      }

      if (circleArea.zIndex !== config.zIndex) {
        circleArea.zIndex = config.zIndex;
      }

      if (config.units && circleArea.units !== config.units) {
        circleArea.units = config.units;
      }

      if (config.staticSize !== undefined && circleArea.staticSize !== config.staticSize) {
        circleArea.staticSize = config.staticSize;
      }

      if (config.autoScale !== undefined && circleArea.autoScale !== config.autoScale) {
        circleArea.autoScale = config.autoScale;
      }
    }

    return circleArea;
  };

  public upsertCircleArea = (circle: MapCircleInstance, config?: MapObjectCircleAreaConfig) => {
    const circleArea = this.lookUp.findCircleArea(circle.id);

    if (circleArea) {
      return this.updateCircleArea(circle, config);
    }
    else {
      return this.insertCircleArea(circle, config);
    }
  };

  public removeCircleArea = (circleAreaId: Uuid) => {
    const circleArea = this.lookUp.findCircleArea(circleAreaId);
    if (circleArea) {
      this.lookUp.removeCircleArea(circleAreaId);
      this.overlayLayers.MapObjectCirles.remove(circleArea);
    }
  };

  public addCircleAreaEventListener = (circleAreaId: Uuid, ...params: AddEventListenerParams) =>
    registerListener(() => this.lookUp.findCircleArea(circleAreaId), ...params);

  //#endregion

  //#region Map events
  //#################

  public addMapClickListener = (listener: (e: google.maps.MapMouseEvent) => void) =>
    google.maps.event.addListener(this.map, 'click', listener);

  public addMapMouseMoveListener = (listener: (e: google.maps.MapMouseEvent) => void) =>
    google.maps.event.addListener(this.map, 'mousemove', listener);

  //#endregion
}

type PolygonLookupData = {
  id: string;
  polygon: WebglOverlayPolygon;
  eventListeners: AddEventListenerParams[];
  points: Points;
};

class MapObjectIdLookup {
  private readonly markerLookup: Map<string, WebglOverlayMarker> = new Map();
  private readonly labelLookup: Map<string, WebglOverlayLabelWithTextBoxCallout> = new Map();
  private readonly polylineLookup: Map<string, WebglOverlayPolyline> = new Map();
  private readonly polygonLookup: Map<string, PolygonLookupData> = new Map();
  private readonly circleAreaLookup: Map<string, WebglOverlayCircleArea> = new Map();
  private readonly arrowLookup: Map<string, WebglOverlayArrow> = new Map();

  public findMarker = (markerType: MapObjectMarkerType, id: string) => this.markerLookup.get(`${markerType}|${id}`);
  public setMarker = (markerType: MapObjectMarkerType, id: string, marker: WebglOverlayMarker) => this.markerLookup.set(`${markerType}|${id}`, marker);
  public removeMarker = (markerType: MapObjectMarkerType, id: string) => this.markerLookup.delete(`${markerType}|${id}`);

  public findLabel = (labelType: MapObjectLabelType, id: string) => this.labelLookup.get(`${labelType}|${id}`);
  public setLabel = (labelType: MapObjectLabelType, id: string, label: WebglOverlayLabelWithTextBoxCallout) => this.labelLookup.set(`${labelType}|${id}`, label);
  public removeLabel = (labelType: MapObjectLabelType, id: string) => this.labelLookup.delete(`${labelType}|${id}`);

  public findPolyline = (id: string) => this.polylineLookup.get(id);
  public setPolyline = (id: string, polyline: WebglOverlayPolyline) => this.polylineLookup.set(id, polyline);
  public removePolyline = (id: string) => this.polylineLookup.delete(id);

  public findPolygon = (id: string) => this.polygonLookup.get(id);
  public setPolygon = (id: string, polygon: PolygonLookupData) => this.polygonLookup.set(id, polygon);
  public removePolygon = (id: string) => this.polygonLookup.delete(id);

  public findCircleArea = (id: string) => this.circleAreaLookup.get(id);
  public setCircleArea = (id: string, circleArea: WebglOverlayCircleArea) => this.circleAreaLookup.set(id, circleArea);
  public removeCircleArea = (id: string) => this.circleAreaLookup.delete(id);

  public findArrow = (id: string) => this.arrowLookup.get(id);
  public setArrow = (id: string, arrow: WebglOverlayArrow) => this.arrowLookup.set(id, arrow);
  public removeArrow = (id: string) => this.arrowLookup.delete(id);
}
