import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { MapAreaColors } from '~/_shared/constants/mapObjects/mapAreaColors.constants';
import { getValueOfAnimatableColorProperty } from '~/_shared/types/animation/animatableProperty.helpers';
import { type Proximity } from '~/_shared/types/proximity/proximity.types';
import {
  registerAnimationCallback,
  removeAnimationCallback,
} from '~/_shared/utils/colorAnimationTicker/colorAnimationTicker';
import { changeProximityStyle } from '~/_shared/utils/proximity';
import { useProximityStates } from './useProximityStates';

const { borderColor, borderColorAlternative } = MapAreaColors.active;

export const useProximityExtraStyles = <T extends Proximity>(proximity: T) => {
  const { isActive, isHovered } = useProximityStates(proximity);
  const [currentActiveBorderColor, setCurrentActiveBorderColor] = useState<string>();

  const computeActiveBorderColor = useCallback((animationPercent: number) => {
    const color = getValueOfAnimatableColorProperty({ value: '', animation: { from: borderColor, to: borderColorAlternative } }, animationPercent);
    setCurrentActiveBorderColor(color);
  }, []);

  useEffect(() => {
    registerAnimationCallback(computeActiveBorderColor);

    return () => {
      removeAnimationCallback(computeActiveBorderColor);
    };
  }, [computeActiveBorderColor]);

  const currentBorderColor = isActive ? currentActiveBorderColor : undefined;

  return useMemo(() => {
    return changeProximityStyle(proximity, isHovered, isActive, currentBorderColor);
  }, [isActive, isHovered, currentBorderColor, proximity]);
};
