import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  CheckboxComponent, TriStateCheckboxComponent,
} from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  getIconForMapToolOrFeature, getTranslationForMapToolOrFeature,
} from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import {
  type ToolOrFeatureWithDataExport,
  TOOLS_AND_FEATURES_WITH_DATA_EXPORT,
} from '~/_shared/types/toolsAndFeatures/toolsAndFeaturesWithDataExport.types';
import { copy } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';

const warningStyle = ({ theme }: ThemeProps) => css({
  fontWeight: 'bold',
  color: theme.textColors.danger,
});

const importantStyle = css({
  textDecoration: 'underline',
});

const checkboxesContainerStyle = css({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  flexWrap: 'wrap',
  height: 114,
});

const checkboxItemStyle = css({
  width: '50%',
  height: 28,
});

const checkboxItemCheckboxStyle = css({
  display: 'inline-block',
  verticalAlign: 'middle',
});

const checkboxItemIconStyle = css({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: 5,
  width: 19,
});

const checkboxItemLabelStyle = css({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: 3,
});

export const containerStyle = css({
  overflow: 'hidden',
});

export const descriptionStyle = css({
  paddingTop: 0,
});

export type PresentationalDataExportAllowToolsModalProps = ModalProps<{
  initialSelectedMapTools: ReadonlySet<ToolOrFeatureWithDataExport>;

  onProceed: (selectedTools: ReadonlySet<ToolOrFeatureWithDataExport>) => void;
}>;

export const PresentationalDataExportAllowToolsModalComponent: FC<PresentationalDataExportAllowToolsModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [selectedTools, setSelectedTools] = useState<ReadonlySet<ToolOrFeatureWithDataExport>>(props.initialSelectedMapTools);

  const currentAllCheckboxState = useMemo(() => (
    selectedTools.size === TOOLS_AND_FEATURES_WITH_DATA_EXPORT.length ?
      TriStateRange.Full : selectedTools.size === 0 ? TriStateRange.None : TriStateRange.Partial
  ), [selectedTools.size]);

  const allCheckboxSetter = useCallback(() => {
    switch (currentAllCheckboxState) {
      case TriStateRange.Full:
        setSelectedTools(new Set());
        break;
      case TriStateRange.Partial:
      case TriStateRange.None:
      default:
        setSelectedTools(new Set(TOOLS_AND_FEATURES_WITH_DATA_EXPORT));
    }
  }, [currentAllCheckboxState]);

  const getCheckedSetter = useCallback((toolOrFeature: ToolOrFeatureWithDataExport) => {
    return () => {
      setSelectedTools(prev => copy.andTogglePresence(prev, toolOrFeature));
    };
  }, []);

  const handleSubmit = useCallback(() => {
    props.onProceed(selectedTools);
  }, [props, selectedTools]);

  const getItem = useCallback((props: {
    index: number; label: string; isChecked?: boolean;
    checkedSetter: () => void; icon?: IconProp; triState?: TriStateRange;
  }) => (
    <div
      css={checkboxItemStyle}
      key={props.index}
    >
      {props.triState && (
        <TriStateCheckboxComponent
          css={checkboxItemCheckboxStyle}
          checkmark={props.triState}
          checkedSetter={props.checkedSetter}
        />
      )}
      {!props.triState && (
        <CheckboxComponent
          css={checkboxItemCheckboxStyle}
          isChecked={props.isChecked || false}
          checkedSetter={props.checkedSetter}
        />
      )}
      {props.icon && (
        <FontAwesomeIcon
          css={checkboxItemIconStyle}
          icon={props.icon}
        />
      )}
      <div css={checkboxItemLabelStyle}>{props.label}</div>
    </div>
  ), []);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('exportPresentationalMapData.activateTools.caption')}
      confirmButton={(
        <ButtonComponent
          text={t('Activate Export Feature')}
          onClick={handleSubmit}
        />
      )}
    >
      <div css={containerStyle}>
        <p css={descriptionStyle}>{t('exportPresentationalMapData.activateTools.desc')}</p>
        <p>
          <span css={importantStyle}>{t('Important')}:</span>&nbsp;
          <span css={warningStyle({ theme })}>{t('exportPresentationalMapData.activateTools.warning')}</span>
        </p>
        <div css={checkboxesContainerStyle}>
          {getItem({
            index: -1,
            label: t('All Tools and Features'),
            triState: currentAllCheckboxState,
            checkedSetter: allCheckboxSetter,
          })}
          {TOOLS_AND_FEATURES_WITH_DATA_EXPORT.map((item, index) => (
            getItem({
              index,
              icon: getIconForMapToolOrFeature(item),
              label: t(getTranslationForMapToolOrFeature(item)),
              isChecked: selectedTools.has(item),
              checkedSetter: getCheckedSetter(item),
            })
          ))}
        </div>
      </div>
    </ModalComponent>
  );
};
