import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';

type BoundarySelectConfirmStepProps = Readonly<{
  isHighlighted: boolean;
  step?: number;
  maxWidth?: number;
  children: React.ReactNode;
}>;

const wrapperStyle = ({ theme, isHighlighted, maxWidth }: ThemeProps<{ isHighlighted: boolean; maxWidth?: number }>) => css({
  display: 'inline-block',
  margin: 0,
  padding: '4px 5px',
  borderRadius: 4,
  border: `1px solid ${isHighlighted ? theme.contrasts.selectedAccent2 : 'transparent'}`,
  background: isHighlighted ? theme.contrasts.selectedAccent2Faded : undefined,
  fontWeight: isHighlighted ? 700 : 400,
  maxWidth,
});

const disclaimerStepTextStyle = css({
  display: 'inline-block',
  marginRight: 8,
});

export const DisclaimerStepComponent: FC<BoundarySelectConfirmStepProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { isHighlighted, maxWidth } = props;

  return (
    <div>
      <div css={wrapperStyle({ theme, isHighlighted, maxWidth })}>
        {props.step !== undefined && (
          <span css={disclaimerStepTextStyle}>
            {t('Step {{step}}', { step: props.step })}:
          </span>
        )}

        {props.children}
      </div>
    </div>
  );
};
