import {
  type FC, useCallback, useMemo,
} from 'react';
import { useFilteredLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { type ModalProps } from '~/modal/modalType.enum';
import { useBoundaryActiveItems } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryActiveItems';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import {
  ExportImageLegendMode, type ExportImageParams,
} from '../../types/exportImage/exportImage';
import { ExportImageModalComponent } from './exportImageModal.component';
import { useExportImage } from './useExportImage';

export const ExportImageModalContainer: FC<ModalProps> = (props) => {
  const activeBoundaryTerritoryGroups = useBoundaryActiveItems();
  const activeColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const filteredMarkersData = useFilteredLatLngSpreadsheetData();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds;
  const { isLoading, exportImage } = useExportImage();

  const totalFilteredLocations = filteredMarkersData.visible.data.length;

  const filteredMarkersInBounds = useMemo(() => (
    filteredMarkersData.visible.data.filter(item => lastBounds?.contains(item))
  ), [filteredMarkersData.visible.data, lastBounds]);

  const onContinue = useCallback((exportProps: ExportImageParams) => {
    exportImage(exportProps);
  }, [exportImage]);

  const activeBTGsExcludingNoFill = useMemo(() => (
    activeBoundaryTerritoryGroups.filter(
      btg => btg.boundaryTerritoryType !== BoundaryTerritoryType.Manual
    )
  ), [activeBoundaryTerritoryGroups]);

  const availableLegendOptions = useMemo(() => ([
    ...((activeColumns.length) ? [ExportImageLegendMode.Grouping] : []),
    ...((activeBTGsExcludingNoFill.length) ? [ExportImageLegendMode.Boundary] : []),
  ]), [activeBTGsExcludingNoFill.length, activeColumns.length]);

  return (
    <ExportImageModalComponent
      {...props}
      availableLegendOptions={availableLegendOptions}
      totalFilteredLocations={totalFilteredLocations}
      totalInBoundsLocations={filteredMarkersInBounds.length}
      onContinue={onContinue}
      isLoading={isLoading}
    />
  );
};
