import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { createMapLimitReachedAppError } from '~/appError/specificErrors/mapLimitReached/mapLimitReached.helpers';
import { createMapRestoreAppError } from '~/appError/specificErrors/mapRestoreFail/mapRestoreFail.helpers';
import { MAP_LIMIT_REACHED_MESSAGE } from '~/map/map.repository';
import { Page } from '../_shared/constants/routes';
import { useSelector } from '../_shared/utils/hooks/useSelector';
import { usePrimarySpreadsheetId } from '../store/selectors/usePrimarySpreadsheetId';
import { deletedMapsFetchDataRequest } from '../store/undo/undo.actionCreators';
import { useGetCurrentPageInfo } from '../topBar/navigation/navigation.helpers';
import {
  type RestoreResult, useRestoreMap,
} from '../undo/useRestoreMap';
import { UndoSelectorComponent } from './undoSelector.component';
import { UndoSelectorType } from './undoSelector.constants';
import { useUndoSelectorItems } from './useUndoSelectorItems.hook';

type UndoSelectorProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const UndoSelectorContainer: FC<UndoSelectorProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const clientId = useSelector(state => state.userData.clientId);
  const isLoadingUndos = useSelector(state => state.map.undo.isProcessing);
  const spreadsheetId = usePrimarySpreadsheetId();
  const currentPageInfo = useGetCurrentPageInfo();
  const [undoCompleted, setUndoCompleted] = useState<boolean>(false);
  const raiseUndoCompleted = useCallback(() => setUndoCompleted(true), []);
  const { restoreMap, isLoading: isRestoringMapsSnaps, error } = useRestoreMap(clientId);
  const { selectorItems, onUndoModifying, onRollback, isInProgress: isUndoInProgress } = useUndoSelectorItems({
    clientId,
    onAfterUndoComplete: raiseUndoCompleted,
    restoreMap,
    spreadsheetId,
  });

  useEffect(() => {
    if (undoCompleted && !error) {
      onClose();
    }
  }, [onClose, undoCompleted, error]);

  useEffect(() => {
    if (error) {
      const message = error?.errors?.maps_limit?.[0] ?? error.message;
      if (message === MAP_LIMIT_REACHED_MESSAGE) {
        dispatch(createMapLimitReachedAppError(t));
      }
      const restoreFailures: readonly RestoreResult[] = error?.errorData?.restore_failures ?? [];
      dispatch(createMapRestoreAppError(restoreFailures, t));
    }
  }, [dispatch, error, t]);

  useEffect(() => {
    if (isOpen && clientId) {
      dispatch(deletedMapsFetchDataRequest(clientId));
    }

  }, [isOpen, clientId, dispatch]);

  const undoSections = useMemo(() => {
    const sections = new Set<UndoSelectorType>();
    sections.add(UndoSelectorType.undoMapDeletion);
    if (currentPageInfo.page === Page.ADMIN_MAP) {
      sections.add(UndoSelectorType.undoSpecificAction);
    }
    if (currentPageInfo.page !== Page.ADMIN_HOME) {
      sections.add(UndoSelectorType.resetToVersion);
    }
    return sections;
  }, [currentPageInfo.page]);

  return (
    <UndoSelectorComponent
      isLoading={isLoadingUndos || isRestoringMapsSnaps || isUndoInProgress}
      isOpen={isOpen}
      items={selectorItems}
      onClose={onClose}
      onRollback={onRollback}
      onSaveNoItemsSelected={onClose}
      onUndoModifying={onUndoModifying}
      sections={undoSections}
    />
  );
};
