import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY,
  DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingRectangleSettingsState } from './drawingRectangleSettings.state';

export const drawingRectangleSettingsInitialState: DrawingRectangleSettingsState = {
  strokeOpacity: 100,
  fillOpacity: 50,
  strokeWeight: 2,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
};

export const drawingRectangleSettingsReducer = (
  state = drawingRectangleSettingsInitialState, action: DrawingSettingsAction
): DrawingRectangleSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Rectangle) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.rectangle,
      };
    }

    default:
      return state;
  }
};
