import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { DividerComponent } from '~/_shared/baseComponents/divider';
import {
  FormLabel, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { DEFAULT_GOOGLE_MAPS_SETTINGS } from '../../../_shared/components/google-map/google-map.component';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type ModalProps } from '../../../modal/modalType.enum';
import { mapComponentSetCenterAndZoom } from '../../../store/frontendState/mapComponent/mapComponent.actionCreators';
import {
  useMapComponentCenterSelector, useMapComponentLastBoundsSelector,
  useMapComponentZoomSelector,
} from '../../../store/frontendState/mapComponent/mapComponent.selectors';
import { mapSettingsSettingsUpdateSettings } from '../../../store/mapSettings/settings/mapSettingsSettings.actionCreators';

enum Options {
  CurrentMap = 'CurrentMap',
  Manual = 'Manual'
}

const Divider = () => (
  <DividerComponent
    marginRight={-10}
    marginLeft={-10}
  />
);

const getLngInRange = (lng: number) => {
  let lngInRange = lng;
  while (lngInRange > 180) {
    lngInRange -= 360;
  }
  while (lngInRange < -180) {
    lngInRange += 360;
  }
  return lngInRange;
};

export const InitialMapPositionModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const lastBounds = useMapComponentLastBoundsSelector();

  const mapCenter = useMapComponentCenterSelector();
  const mapZoom = useMapComponentZoomSelector() ?? DEFAULT_GOOGLE_MAPS_SETTINGS.zoom;

  const [selectedOption, setSelectedOption] = useState(Options.CurrentMap);

  const [lat, setLat] = useState(mapCenter.lat);
  const [lng, setLng] = useState(getLngInRange(mapCenter.lng));
  const [zoom, setZoom] = useState(mapZoom);

  const mapViewName = useSelector(state => state.map.mapInfo.data?.name);

  const radioGroup = useMemo(() => [{
    value: Options.CurrentMap,
    label: <span style={{ textTransform: 'uppercase' }}>{t('Use current map view to set the starting map position')}</span>,
    description: <span style={{ fontSize: '12px' }}>{t('Set the latitude, longitude and map zoom for me.')}</span>,
  }, {
    value: Options.Manual,
    label: <span style={{ textTransform: 'uppercase' }}>{t('I will set the latitude, longitude and map zoom myself')}</span>,
  },
  ], [t]);

  const onSubmit = useCallback(() => {
    if (selectedOption === Options.CurrentMap) {
      const centerAndZoom = { center: mapCenter, zoom: mapZoom };
      dispatch(mapSettingsSettingsUpdateSettings({ initialMapCenterAndZoom: centerAndZoom, initialBounds: lastBounds?.bounds.getBounds() }));
    }
    else {
      const centerAndZoom = { center: { lat, lng }, zoom };
      dispatch(mapComponentSetCenterAndZoom({ lat, lng }, zoom));
      dispatch(mapSettingsSettingsUpdateSettings({ initialMapCenterAndZoom: centerAndZoom, initialBounds: null }));
    }
    onClose();
  }, [dispatch, lastBounds?.bounds, lat, lng, mapCenter, mapZoom, onClose, selectedOption, zoom]);

  return (
    <ModalComponent
      caption={t('Initial map position')}
      isOpen={isOpen}
      onClose={onClose}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          onClick={onClose}
          text={t('Cancel')}
        />
      )}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          onClick={onSubmit}
          text={t('Save')}
        />
      )}
    >
      <div css={css({ paddingBottom: '10px' })}>
        {t('Map Name')}: {mapViewName}
      </div>
      <Divider />
      <div css={css({ padding: '20px 0' })}>
        <div css={css({ fontSize: '18px', marginBottom: '20px' })}>
          {t('How do you want to set your initial map position?')}
        </div>
        <RadioGroupComponent<Options>
          onValueChange={setSelectedOption}
          selectedValue={selectedOption}
          items={radioGroup}
        />
      </div>
      {selectedOption === Options.Manual && (
        <>
          <Divider />
          <div
            css={css({ display: 'flex', gap: '6px', padding: '10px 0' })}
          >
            <div>
              <FormLabel>
                {t('Latitude')}
              </FormLabel>
              <InputWithSpinnersComponent
                value={lat}
                onChange={setLat}
                valueMin={-90}
                valueMax={90}
                css={css({ width: '220px' })}
              />
            </div>
            <div>
              <FormLabel>
                {t('Longitude')}
              </FormLabel>
              <InputWithSpinnersComponent
                value={lng}
                onChange={setLng}
                valueMin={-180}
                valueMax={180}
                css={css({ width: '220px' })}
              />
            </div>
            <div>
              <FormLabel>
                {t('Zoom')}
              </FormLabel>
              <InputWithSpinnersComponent
                value={zoom}
                onChange={setZoom}
                valueMin={0}
                valueMax={30}
                css={css({ width: '70px' })}
              />
            </div>
          </div>
        </>
      )}
    </ModalComponent>
  );
};
