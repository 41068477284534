import {
  ControlPosition, type MapOptions,
} from '~/_shared/types/googleMaps/googleMaps.types';

export type MapComponentOptionsStateChange = {
  changeId: string;
  priority: number; // higher number = higher priority
  change: Partial<MapOptions>;
};

export type MapComponentOptionsState = {
  current: MapOptions;
  context: MapComponentOptionsStateChange[];
};

export const STATE_EXAMPLE: MapComponentOptionsState = {
  current: {
    draggableCursor: 'pointer',
    clickableIcons: false,
    disableDoubleClickZoom: true,
  },
  context: [
    {
      changeId: 'DISTANCE_CALCULATOR_DRAWING',
      priority: 0,
      change: {
        draggableCursor: 'url("path") 15 15, default',
        clickableIcons: false,
        disableDoubleClickZoom: true,
      },
    },
    {
      changeId: 'CLICKABLE_MARKER_HOVER',
      priority: 0,
      change: {
        draggableCursor: 'pointer',
        clickableIcons: false,
      },
    },
  ],
};

export const defaultMapOptions: MapOptions = {
  // Used
  gestureHandling: 'greedy',
  draggableCursor: '',
  clickableIcons: false,
  disableDefaultUI: true,
  disableDoubleClickZoom: false,
  zoomControl: false,
  scrollwheel: true,
  streetViewControl: false,
  scaleControl: true,
  streetViewControlOptions: {
    position: ControlPosition.RIGHT_BOTTOM,
  },

  // Unused
  // backgroundColor: undefined,
  // center: undefined,
  // controlSize: undefined,
  // disableDefaultUI: undefined,
  // draggable: undefined, // deprecated
  // draggingCursor: undefined,
  // fullscreenControl: undefined,
  // fullscreenControlOptions: undefined,
  // heading: undefined,
  // keyboardShortcuts: undefined,
  // mapTypeControl: undefined,
  // mapTypeControlOptions: undefined,
  // mapTypeId: undefined,
  // maxZoom: undefined,
  // minZoom: undefined,
  // noClear: undefined,
  // panControl: undefined,
  // panControlOptions: undefined,
  // restriction: undefined,
  // rotateControl: undefined,
  // rotateControlOptions: undefined,
  // scaleControlOptions: undefined,
  // scrollwheel: undefined,
  // streetView: undefined,
  // styles: undefined,
  // tilt: undefined,
  // zoom: undefined,
};
