import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { memoizeOne } from '../../../../_shared/utils/memoize/memoize';
import { updateMarkerPosition } from '../../../../store/frontendState/moveMarkers/moveMarkers.actionCreators';
import {
  getMarkerNewPosition,
  type MarkerPositions,
  markerWasMoved,
} from '../../../../store/frontendState/moveMarkers/moveMarkers.state';
import { type StackedMarker } from '../manager/mapMarkerManager';
import { type StackedMarkerId } from '../useStacksAndClusters/StackedMarkerId.type';

const replaceMarkerPositionWithDragged = (originalMarkers: ReadonlyMap<StackedMarkerId, StackedMarker>, newMarkerPositions: MarkerPositions): ReadonlyMap<StackedMarkerId, StackedMarker> => {
  const updatedPositions = new Map<StackedMarkerId, StackedMarker>();
  originalMarkers.forEach((value, key) => {
    const wasDragged = markerWasMoved(newMarkerPositions, value.rowId);
    if (!wasDragged) {
      updatedPositions.set(key, value);
    }
    const newPosition = getMarkerNewPosition(newMarkerPositions, value.rowId);
    if (!newPosition) {
      return;
    }

    const newValue = { ...value, lat: newPosition.lat, lng: newPosition.lng };
    updatedPositions.set(key, newValue);
  });

  return updatedPositions;
};

const replaceMarkerPositionWithDraggedMemoized = memoizeOne(replaceMarkerPositionWithDragged);

export const useMarkerTemporaryPositionStore = () => {
  const markersPosition = useSelector(state => state.frontendState.moveMarkers.markersPositions);
  const dispatch = useDispatch();

  const saveNewMarkerPosition = useCallback((rowId: string, lat: number, lng: number) => {
    dispatch(updateMarkerPosition({ lat, lng, rowId }));
  }, [dispatch]);

  const replaceMarkerPositions = useCallback(
    (originalMarkerPosition: ReadonlyMap<StackedMarkerId, StackedMarker>) => {
      return replaceMarkerPositionWithDraggedMemoized(originalMarkerPosition, markersPosition);
    },
    [markersPosition],
  );

  return {
    saveNewMarkerPosition,
    replaceMarkerPositions,
  };
};
