import { css } from '@emotion/react';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';

type ToggleWithDescriptionProps = Readonly<{
  isToggled: boolean;
  title: string;
  description: string;
  onToggled: () => void;
}>;

const rootStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,

  '&:first-of-type': {
    marginTop: 30,
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
});

const titleStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
});

const descriptionStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
});

const textContainerStyle = css({
  marginLeft: 5,
});

const toggleStyle = css({
  flexShrink: 0,
});

export const ToggleWithDescriptionComponent: React.FC<ToggleWithDescriptionProps> = props => {
  const theme = useTheme();

  return (
    <label css={rootStyle}>
      <ToggleComponent
        css={toggleStyle}
        onChange={props.onToggled}
        isOn={props.isToggled}
      />
      <div css={textContainerStyle}>
        <div css={titleStyle({ theme })}>{props.title}</div>
        <div css={descriptionStyle({ theme })}>{props.description}</div>
      </div>
    </label>
  );
};
