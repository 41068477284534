import { css } from '@emotion/react';
import numeral from 'numeral';
import { type FC } from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import {
  DISTANCE_MATRIX_MAX_SEARCH_ITEMS, DISTANCE_MATRIX_MAX_TO_LOCATIONS,
} from '~/_shared/repositories/useDistanceMatrixService';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  ProximityDistanceCalculateMethod, ProximityDistanceDisabledReason,
} from '~/proximityDetails/export/useExportProximityDistanceCalculate';
import {
  s, Trans,
} from '~/translations/Trans';

const sectionStyle = (theme: Theme) => css({
  padding: 14,
  border: `0px solid ${theme.borderColors.primary}`,
  borderWidth: '0 0 1px',
  '&:last-of-type': {
    borderWidth: 0,
  },
});

const sectionContentStyle = css({
  marginTop: 15,
});

const overLimitWarningMessage = (theme: Theme) => css({
  color: theme.textColors.danger,
  margin: '0 0 0 28px',
  fontSize: '13px',
});

const supportLinkStyle = css({
  color: 'inherit',
});

export type ExportProximityDistanceCalculate = {
  calculateMethod: ProximityDistanceCalculateMethod;
  drivingDisabledReason: string | null;
};

export type ExportProximityDistanceCalculateMethodSectionProps = Readonly<ExportProximityDistanceCalculate> & Readonly<{
  onCalculateMethodChange: (method: ProximityDistanceCalculateMethod) => void;
}>;

export const ExportProximityDistanceCalculateMethodSectionComponent: FC<ExportProximityDistanceCalculateMethodSectionProps> = (props) => {
  const [t] = useTranslation();

  const markerInclusionItems = [
    {
      value: ProximityDistanceCalculateMethod.StraightLine,
      label: t('Straight Line (as the crow flies)'),
    },
    ...(props.drivingDisabledReason !== ProximityDistanceDisabledReason.PresentationalMap ? [{
      value: ProximityDistanceCalculateMethod.DrivingDistance,
      label: t('Driving Distance (Driving + Ferry)'),
      isDisabled: !!props.drivingDisabledReason,
    }] : []),
  ];

  return (
    <div>
      <div css={sectionStyle}>
        <RadioGroupComponent<ProximityDistanceCalculateMethod>
          css={sectionContentStyle}
          selectedValue={props.calculateMethod}
          onValueChange={props.onCalculateMethodChange}
          items={markerInclusionItems}
        />
        {!!props.drivingDisabledReason && (
          <p css={overLimitWarningMessage}>
            {props.drivingDisabledReason === ProximityDistanceDisabledReason.MarkersCountExceeded && (
              <>
                {t('The Driving Distance calculation currently supports a maximum of {{limit}} markers closest to the center.', {
                  limit: numeral(DISTANCE_MATRIX_MAX_TO_LOCATIONS).format('0,0'),
                })}
              </>
            )}
            {props.drivingDisabledReason === ProximityDistanceDisabledReason.ProximitySelectedCountExceeded && (
              <>
                {t('This feature is available only when {{ count }} or fewer proximities are selected', {
                  count: DISTANCE_MATRIX_MAX_SEARCH_ITEMS,
                })}
              </>
            )}
            {props.drivingDisabledReason === ProximityDistanceDisabledReason.LimitExceededTrial && (
              <>
                {t('Your current license does not permit additional Driving Distance exports. To increase your quota, please upgrade your license.')}
              </>
            )}
            {props.drivingDisabledReason === ProximityDistanceDisabledReason.LimitExceededPaid && (
              <Trans i18nKey="Your current license does not permit additional Driving Distance exports. To increase your quota, please contact <1>customer support</1>.">
                {s('Your current license does not permit additional Driving Distance exports. To increase your quota, please contact ')}
                <a css={supportLinkStyle} href="mailto:support@maptive.com?subject=Increase%20the%20limit%20of%20Driving%20Distance%20Matrix">customer support</a>
                {s('.')}
              </Trans>
            )}
          </p>
        )}
      </div>
    </div>
  );
};
