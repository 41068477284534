import { type UnclusterBelowLevel } from '~/_shared/utils/markers/markers.constants';
import { type MapSettingsMarkersResponse } from '~/map/map.repository';
import { ensurePanelSettingsCompatibility } from './compatibility/ensurePanelSettingsCompatibility';
import { mapSettingsMarkersInitialState } from './mapSettingsMarkers.reducer';
import { type MapSettingsMarkersState } from './mapSettingsMarkers.state';

export const mapSettingsMarkersSerializer = {
  jsonToState: (json: MapSettingsMarkersResponse & {
    unclusterBelowN?: UnclusterBelowLevel | boolean | null;
  }): MapSettingsMarkersState => {
    const { panelSettings: jsonPanelSettings, ...jsonRest } = json;

    return {
      ...mapSettingsMarkersInitialState,
      ...jsonRest,
      unclusterBelowN: json.unclusterBelowN
        ? typeof json.unclusterBelowN === 'boolean' && json.unclusterBelowN
          ? 'medium'
          : json.unclusterBelowN
        : json.unclusterBelowN === null ? null : mapSettingsMarkersInitialState.unclusterBelowN,
      panelSettings: ensurePanelSettingsCompatibility(jsonPanelSettings),
    };
  },
  stateToJson: (state: MapSettingsMarkersState): MapSettingsMarkersResponse => {
    return state;
  },
};
