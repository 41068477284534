import { type EmailErrorCode } from '~/_shared/types/responseErrors/_shared/emailErrorCodes.types';
import { type GeneralErrorCode } from '~/_shared/types/responseErrors/_shared/generalErrorCodes.types';
import { type MFAErrorCode } from '~/_shared/types/responseErrors/_shared/MFAErrorCodes.types';

export type UserLoginErrorCode = UserLoginErrorSpecialCode | MFAErrorCode | EmailErrorCode | GeneralErrorCode;

export enum UserLoginErrorSpecialCode {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  REMEMBER_ME_DISABLED = 'REMEMBER_ME_DISABLED',
  USER_CLIENT_IS_DISABLED = 'USER_CLIENT_IS_DISABLED',
  USER_WAS_DISABLED = 'USER_WAS_DISABLED',
  SUCCESS = 'SUCCESS',
  UNPROCESSABLE_CONTENT = 'UNPROCESSABLE_CONTENT',
  FAILED_TO_GENERATE_SALT = 'FAILED_TO_GENERATE_SALT',
  FAILED_TO_GENERATE_TOKEN = 'FAILED_TO_GENERATE_TOKEN',
}
