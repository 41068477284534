import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import {
  Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel, AccordionItemState,
} from 'react-accessible-accordion';
import { AccordionStepNumber } from '~/_shared/baseComponents/accordion/stepNumber.component';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { OverlayLoaderComponent } from '../../components/overlay/overlayLoader.component';
import { useTheme } from '../../themes/theme.hooks';
import {
  accordionStyle, caretStyle, getHeaderItemButtonStyle, headerWrapperStyle, panelStyle,
} from './accordion.styles';
import {
  type AccordionData, AccordionHeaderSize,
} from './accordion.types';

export type AccordionProps = Readonly<{
  accordionStyle?: SerializedStyles;
  data: readonly AccordionData[];
  expandedItemStyle?: SerializedStyles;
  headerWrapperStyle?: SerializedStyles;
  isLoading?: boolean;
  itemButtonStyle?: SerializedStyles;
  itemStyle?: SerializedStyles;
  panelStyle?: SerializedStyles;
  showCount?: boolean;
  headerSize?: AccordionHeaderSize;
  testid?: string;
}>;

export const AccordionComponent: FC<AccordionProps> = props => {
  const theme = useTheme();

  return (
    <Accordion
      css={[accordionStyle({ theme }), props.accordionStyle]}
      allowZeroExpanded
      allowMultipleExpanded
      data-testid={props.testid}
    >
      {props.isLoading && <OverlayLoaderComponent />}
      {props.data.map((data: AccordionData, index) => (
        <AccordionItem
          key={index}
          dangerouslySetExpanded={data.isExpanded}
          css={props.itemStyle}
          data-testid={data.testId}
        >
          {data.header && (
            <AccordionItemHeading onClick={!data.isLocked ? data.onHeadingClick : undefined}>
              <AccordionItemButton
                css={[
                  getHeaderItemButtonStyle({
                    theme,
                    hiddenBorderBottom: !data.isExpanded,
                    hiddenBorderTop: !index,
                    isExpanded: data.isExpanded,
                    isLocked: data.isLocked,
                    size: props.headerSize ?? AccordionHeaderSize.Medium,
                  }),
                  props.itemButtonStyle, data.isExpanded && props.expandedItemStyle,
                ]}
              >
                <span css={css([headerWrapperStyle, props.headerWrapperStyle])}>
                  {(data.showCount ?? props.showCount) &&
                    <AccordionStepNumber number={index + 1} />
                  }

                  {data.header}
                </span>

                <AccordionItemState>
                  {({ expanded }) => (
                    <FontAwesomeIcon
                      css={caretStyle({ isToggled: expanded, isLocked: data.isLocked })}
                      icon={faCaretUp}
                    />
                  )}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
          )}
          <AccordionItemPanel
            css={[panelStyle({ theme }), props.panelStyle]}
          >
            {data.child}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
