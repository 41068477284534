import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';

export type SquareComponentProps = {
  color: string;
  size?: number;
  style?: SerializedStyles;
};

export const SquareComponent: FC<SquareComponentProps> = ({ color, size = 16, style }) => {
  return (<div css={css({ backgroundColor: color, height: size, width: size, borderRadius: 4 }, style)} />);
};
