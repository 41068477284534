import { css } from '@emotion/react';
import {
  faCheck,
  faExpandArrows, faPlay, faStop, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { DisclaimerContainer } from '~/_shared/components/disclaimer/disclaimer.container';
import { DisclaimerStepComponent } from '~/_shared/components/disclaimer/disclaimerStep.component';
import { useDisclaimerSteps } from '~/_shared/components/disclaimer/disclaimerSteps';
import { DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';
import { MapConfirmComponent } from '../../../../_shared/mapConfirm/mapConfirm.component';
import { noop } from '../../../../_shared/utils/function.helpers';
import { useTranslation } from '../../../../_shared/utils/hooks';

type MoveMarkerLabelsSaveProps = Readonly<{
  isMoveActive: boolean;
  labelsMoved: boolean;

  onConfirmClick: () => void;
  onClose: () => void;
  onResetClick: () => void;
  onStartClick: () => void;
}>;

const disclaimerStyle = css({
  marginLeft: 20,
  fontSize: '14px',
});

const mapConfirmStyle = css({
  minWidth: 267,
});

const buttonsWrapperStyle = ({ buttonsCount }: { buttonsCount: number }) => css({
  display: 'flex',
  justifyContent: buttonsCount <= 1 ? 'flex-end' : 'space-between',
});

export const MoveMarkerLabelsSaveComponent: FC<MoveMarkerLabelsSaveProps> = (props) => {
  const [t] = useTranslation();
  const disclaimerSteps = useDisclaimerSteps();

  const buttons = useMemo(() => {
    if (!props.isMoveActive) {
      return [{
        icon: faPlay,
        buttonStyle: ButtonStyle.Success,
        onClick: props.onStartClick,
        label: t('Start'),
      }];
    }
    else {
      if (!props.labelsMoved) {
        return [{
          icon: faStop,
          buttonStyle: ButtonStyle.Danger,
          onClick: props.onResetClick,
          label: t('Stop'),
        }, {
          icon: faCheck,
          buttonStyle: ButtonStyle.Primary,
          onClick: noop,
          label: t('Save'),
          isDisabled: true,
        }];
      }
      else {
        return [{
          icon: faTrash,
          buttonStyle: ButtonStyle.Danger,
          onClick: props.onResetClick,
          label: t('Reset'),
        }, {
          icon: faCheck,
          buttonStyle: ButtonStyle.Primary,
          onClick: props.onConfirmClick,
          label: t('Save'),
        }];
      }
    }
  }, [props.isMoveActive, props.labelsMoved, props.onConfirmClick,
    props.onResetClick, props.onStartClick, t]);

  return (
    <MapConfirmComponent
      isOpen
      buttonsWrapperStyle={buttonsWrapperStyle({ buttonsCount: buttons.length })}
      buttons={buttons}
      dropdownOptions={[{
        name: t('Edit'),
        value: 'Drag',
        icon: faExpandArrows,
      }]}
      selectedOptionId={DrawType.Drag}
      onClose={props.onClose}
      heading={t('Move Labels')}
      css={mapConfirmStyle}
    >
      <DisclaimerContainer
        css={disclaimerStyle}
        permanentConfirmSettings={{
          id: 'move-marker-labels',
          text: t('Do not show again'),
        }}
      >
        <DisclaimerStepComponent
          step={1}
          isHighlighted={!props.isMoveActive}
        >
          {disclaimerSteps.Start}
        </DisclaimerStepComponent>

        <DisclaimerStepComponent
          step={2}
          isHighlighted={props.isMoveActive}
          maxWidth={400}
        >
          {t('You can now drag and drop one of your marker labels to a new position. It will remain in the new position permanently if you save the change.')}
        </DisclaimerStepComponent>
      </DisclaimerContainer>
    </MapConfirmComponent>
  );
};
