import { useCallback } from 'react';
import { useConfirmationModal } from '../_shared/components/modal/confirmation/useConfirmationModal';
import { refreshPage } from '../_shared/utils/document/document.helpers';
import { useTranslation } from '../_shared/utils/hooks';

export const useSpreadsheetDataOutOfSync = () => {
  const [t] = useTranslation();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const onConcurrencyError = useCallback(() => {
    return new Promise<null>((resolve) => {
      openConfirmationModal({
        isConfirmButtonDestructive: true,
        title: t('Spreadsheet Data Out Of Sync'),
        text: t('Markers have been recently changed by another user. Please refresh your page in order to be able to make spreadsheet data changes.'),
        confirmCaption: t('Refresh Page'),
        onCancel: () => {
          closeConfirmationModal();
          resolve(null);
        },
        onConfirm: () => {
          resolve(null);
          refreshPage();
        },
      });
    });
  }, [t, openConfirmationModal, closeConfirmationModal]);

  return {
    onConcurrencyError,
  };
};
