import { useMemo } from 'react';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { type BoundaryStateItem } from '~/store/boundaryItems/boundaryItems.state';
import {
  useBoundaryTerritoryAssignments, useBoundaryTerritoryDetailsSelector,
} from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { type BoundaryTerritoryGroupDetailsItem } from '~/store/boundaryTerritoryDetails/boundaryTerritoryGroups.state';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritory } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useBoundaryDrawPolygonEditActiveSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { useBoundaryFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { shouldOnlyShowBoundariesWithFill } from '../mapBoundary.helpers';

const emptyAssignments: ReadonlyMap<BoundaryId, BoundaryTerritoryId> = new Map();

export type BoundaryPolygonFilterMap = ReadonlyMap<BoundaryGroupId, (boundary: BoundaryStateItem) => boolean>;

export const useMapBoundaryPolygonFilter = () => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryFilters = useBoundaryFilters();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const boundaryDetails = useBoundaryTerritoryDetailsSelector();
  const mapZoom = useMapComponentZoomSelector();
  const boundaryDragEdit = useBoundaryDrawPolygonEditActiveSelector();

  return useMemo<BoundaryPolygonFilterMap>(() => {
    const result = new Map<BoundaryGroupId, (boundary: BoundaryStateItem) => boolean>();

    if (!mapZoom) {
      return result;
    }

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const boundaryFilter = boundaryFilters.get(boundaryTerritoryGroup.boundaryGroupId);
      const { showAll, filteredBoundaries, filteredBoundaryTerritories } = boundaryFilter;
      const assignments = boundaryTerritoryAssignments.get(boundaryTerritoryGroup.boundaryTerritoryGroupId);
      const details = boundaryDetails.get(boundaryTerritoryGroup.boundaryTerritoryGroupId);

      const boundaryGroup = boundaryGroups.find(g => g.id === boundaryTerritoryGroup.boundaryGroupId);
      if (!boundaryGroup) {
        continue;
      }

      const showOnlyBoundariesWithFill = shouldOnlyShowBoundariesWithFill(mapZoom, boundaryGroup, boundaryTerritoryGroup.settings.boundaryTerritoryType);

      const editedBoundaryGroupId = boundaryDragEdit?.boundaryGroupId;
      const editedBoundaryId = editedBoundaryGroupId === boundaryGroup.id ? boundaryDragEdit?.drawModeData.boundaryId ?? null : null;

      const boundaryTerritoriesWithoutFill = getBoundaryTerritoryIdsWithoutFillMemoized(boundaryTerritoryGroup.settings.boundaryTerritories);

      const filter = getMapBoundaryPolygonFilterMemoized(
        assignments?.boundaryAssignments ?? emptyAssignments,
        details,
        showAll === TriStateRange.Full,
        boundaryTerritoryGroup.settings.hideWithoutData,
        filteredBoundaries,
        filteredBoundaryTerritories,
        showOnlyBoundariesWithFill,
        editedBoundaryId,
        boundaryTerritoriesWithoutFill
      );

      result.set(boundaryTerritoryGroup.boundaryGroupId, filter);
    }

    return result;
  }, [boundaryDetails, boundaryDragEdit?.boundaryGroupId, boundaryDragEdit?.drawModeData.boundaryId, boundaryGroups,
    boundaryTerritoryAssignments, boundaryFilters, boundaryTerritoryGroups, mapZoom]);
};

const getMapBoundaryPolygonFilter = (
  assignments: ReadonlyMap<BoundaryId, BoundaryTerritoryId>,
  details: BoundaryTerritoryGroupDetailsItem | undefined,
  showAll: boolean,
  hideWithoutData: boolean,
  filteredBoundaries: ReadonlyArray<BoundaryId>,
  filteredBoundaryTerritories: ReadonlyArray<BoundaryTerritoryId>,
  showOnlyBoundariesWithFill: boolean,
  editedBoundaryId: BoundaryId | null,
  boundaryTerritoriesWithoutFill: ReadonlyArray<BoundaryTerritoryId>
) => {
  return (boundary: BoundaryStateItem) => {
    if (boundary.id === editedBoundaryId) {
      return false;
    }

    const assignedBoundaryTerritoryId = assignments.get(boundary.id);

    if (showOnlyBoundariesWithFill) {
      if (!assignedBoundaryTerritoryId) {
        return false;
      }

      if (boundaryTerritoriesWithoutFill.includes(assignedBoundaryTerritoryId)) {
        return false;
      }
    }

    if (hideWithoutData && !details?.counts[boundary.id]) {
      return false;
    }

    if (filteredBoundaryTerritories.length && assignedBoundaryTerritoryId) {
      return !!filteredBoundaryTerritories.find(id => id === assignedBoundaryTerritoryId);
    }
    else if (filteredBoundaries.length) {
      return !!filteredBoundaries.find(id => id === boundary.id);
    }

    return showAll;
  };
};
const getMapBoundaryPolygonFilterMemoized = memoizeWeak(getMapBoundaryPolygonFilter);

const getBoundaryTerritoryIdsWithoutFill = (boundaryTerritories: ReadonlyArray<BoundaryTerritory>): ReadonlyArray<BoundaryTerritoryId> =>
  boundaryTerritories.filter(bt => bt.style.opacity === 0).map(bt => bt.boundaryTerritoryId);
const getBoundaryTerritoryIdsWithoutFillMemoized = memoizeWeak(getBoundaryTerritoryIdsWithoutFill);
