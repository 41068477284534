import { type Announcement } from '../../_shared/types/announcement.types';
import { type MarkAsSeenData } from '../../announcements/announcements.repository';
import {
  ANNOUNCEMENT_MARK_AS_SEEN_ERROR,
  ANNOUNCEMENT_MARK_AS_SEEN_REQUEST,
  ANNOUNCEMENT_MARK_AS_SEEN_SUCCESS,
  ANNOUNCEMENTS_FETCH_DATA_ERROR,
  ANNOUNCEMENTS_FETCH_DATA_REQUEST,
  ANNOUNCEMENTS_FETCH_DATA_SUCCESS,
} from './announcements.actionTypes';

export const announcementsFetchDataRequest = (clientId: number, userId: number) => ({
  type: ANNOUNCEMENTS_FETCH_DATA_REQUEST,
  payload: {
    clientId,
    userId,
  },
}) as const;

export const announcementsFetchDataError = () => ({
  type: ANNOUNCEMENTS_FETCH_DATA_ERROR,
}) as const;

export const announcementsFetchDataSuccess = (data: Announcement[]) => ({
  type: ANNOUNCEMENTS_FETCH_DATA_SUCCESS,
  payload: {
    data,
  },
}) as const;

export const announcementsMarkAsSeenRequest = (clientId: number, userId: number, announcementId: number) => ({
  type: ANNOUNCEMENT_MARK_AS_SEEN_REQUEST,
  payload: {
    clientId,
    userId,
    announcementId,
  },
}) as const;

export const announcementsMarkAsSeenError = () => ({
  type: ANNOUNCEMENT_MARK_AS_SEEN_ERROR,
}) as const;

export const announcementsMarkAsSeenSuccess = (data: MarkAsSeenData) => ({
  type: ANNOUNCEMENT_MARK_AS_SEEN_SUCCESS,
  payload: {
    data,
  },
}) as const;
