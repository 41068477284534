import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../data/mapSettingsData.actionTypes';

export const MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_BUCKET_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS';

export const MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS = 'MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS';
export const MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS = 'MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS';

export const MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR = 'MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR';
export const MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR = 'MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR';

export const MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS = 'MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS';

// Consider adding an action to the list if it can update / remove file attachment from marker settings.
export const MAP_SETTINGS_MARKERS_GENERAL_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS = [
  MAP_SETTINGS_FETCH_DATA_SUCCESS,
  MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS,
];
