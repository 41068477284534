import {
  type FC, memo, useEffect, useState,
} from 'react';
import { type DriveTimePolygonProximity } from '~/_shared/types/proximity/proximity.types';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useCenterInteraction } from '~/map/map/proximity/useCenterMarkerInteraction';
import { useIndividualOrDriveTimeProximityZIndex } from '../../zIndexes/useIndividualOrDriveTimeProximityZIndex.hook';
import { type MapProximityManager } from './mapProximityManager';
import { useProximityStates } from './useProximityStates';
import { useProximityExtraStyles } from './useProximityStyles';

type ProximityInstancePolygonProps = {
  manager: MapProximityManager;
  proximity: DriveTimePolygonProximity;
  isVisible: boolean;
};

const ProximityInstancePolygon: FC<ProximityInstancePolygonProps> = ({ manager, proximity, isVisible }) => {
  const hideLabel = useSelector(s => s.map.mapSettings.data.proximity.hideLabels);
  const zIndex = useIndividualOrDriveTimeProximityZIndex(proximity.id, 'polygon');
  const proximityWithExtraStyles = useProximityExtraStyles(proximity);
  const { isActive, isHovered } = useProximityStates(proximity);
  const previousPaths = usePrevious(proximityWithExtraStyles.data.paths);

  const [renderedCenter, setRenderedCenter] = useState<WebglOverlayMarker | null>(null);

  const { isMouseOverCenter } = useCenterInteraction(renderedCenter, proximity.id);

  // Draw proximity
  useEffect(() => {
    if (previousPaths !== proximityWithExtraStyles.data.paths) {
      manager.removeProximityPolygon(proximity.id);
    }

    const rendered = manager.renderProximityDriveTimePolygon({
      isMarkerHovered: isMouseOverCenter,
      isOutlineVisible: isActive || isHovered,
      isVisible,
      proximity: proximityWithExtraStyles,
      hideLabel,
      zIndex,
    });

    setRenderedCenter(rendered?.centerMarker ?? null);
  }, [manager, zIndex, hideLabel, isVisible, proximityWithExtraStyles, proximity, isActive, isHovered, previousPaths, setRenderedCenter, isMouseOverCenter]);

  // Unmount cleanup
  useEffect(() => {
    return () => {
      manager.removeProximityPolygon(proximity.id);
    };
  }, [manager, proximity.id]);

  return null;
};

const pureComponent = memo(ProximityInstancePolygon);
export { pureComponent as ProximityInstancePolygon };
