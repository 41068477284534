import { useCallback } from 'react';
import { type FileAttachmentId } from '~/_shared/types/file.types';
import { MarkerAnchorPosition } from '~/_shared/types/marker.types';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { areMarkerLabelsDisabled } from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { type AppState } from '../../app.store';

export const mapSettingsMarkersGeneralSelector = (state: AppState) => state.map.mapSettings.data.markersGeneral;
export const useMapSettingsMarkersGeneralSelector = () => useSelector(mapSettingsMarkersGeneralSelector);

export const mapSettingsUseLabelsAboveMarkersSelector = (state: AppState) => state.map.mapSettings.data.markersGeneral.useLabelsAboveMarkers;
export const useMapSettingsUseLabelsAboveMarkersSelector = () => useSelector(mapSettingsUseLabelsAboveMarkersSelector);

export const areNumericalLabelsActiveSelector = (state: AppState) => state.map.mapSettings.data.markersGeneral.useNumericLabel;
export const useAreNumericalLabelsActive = () => useSelector(areNumericalLabelsActiveSelector);

export const mapSettingsAreAnyLabelsActiveSelector = (state: AppState) => {
  const markersGeneral = mapSettingsMarkersGeneralSelector(state);
  return !!(markersGeneral.useNumericLabel || markersGeneral.useTextLabel || markersGeneral.useLabelsAboveMarkers);
};
export const useMapSettingsAreAnyLabelsActiveSelector = () => useSelector(mapSettingsAreAnyLabelsActiveSelector);

export const mapSettingsAreLabelsInsideMarkersActiveSelector = (state: AppState) => {
  const markersGeneral = mapSettingsMarkersGeneralSelector(state);
  return !!(markersGeneral.useNumericLabel || markersGeneral.useTextLabel);
};
export const useLabelsInsideMarkersActiveSelector = () => useSelector(mapSettingsAreLabelsInsideMarkersActiveSelector);

const mapSettingsMarkersTextureAnchorsSelector = (state: AppState) => mapSettingsMarkersGeneralSelector(state).markerTextureSettings?.anchors;
export const useMapSettingsMarkersTextureAnchorsSelector = () => useSelector(mapSettingsMarkersTextureAnchorsSelector);

export const useMapSettingsMarkersTextureAnchors = () => {
  const anchors = useMapSettingsMarkersTextureAnchorsSelector();

  const getMarkerTextureAnchor = useCallback((fileAttachmentId: FileAttachmentId) =>
    anchors?.[fileAttachmentId] ?? MarkerAnchorPosition.BOTTOM_CENTER,
  [anchors]);

  return { getMarkerTextureAnchor };
};

export const useAreMarkerLabelsDisabled = () => {
  const generalSettings = useMapSettingsMarkersGeneralSelector();
  return areMarkerLabelsDisabled(generalSettings);
};

export const hideAllMarkersSelector = (state: AppState) => state.map.mapSettings.data.markersGeneral.hideAllMarkers;
export const useHideAllMarkersSelector = () => useSelector(hideAllMarkersSelector);

export const mapSettingsMarkerStyleIdSelector = (state: AppState) => state.map.mapSettings.data.markersGeneral.defaultMarkerSetId;
export const useMapSettingsMarkerStyleIdSelector = () => useSelector(mapSettingsMarkerStyleIdSelector);
