import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback,
  useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  type TabData, type TabsData,
} from '~/_shared/components/tabs/tabs.types';
import { type UndoSelectorItem } from '~/_shared/types/undo/undoSelectorItem';
import { notNull } from '~/_shared/utils/typeGuards';
import {
  MODAL_DEFAULT_CONFIRM_BUTTON_HEIGHT,
  ModalComponent,
} from '../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../_shared/components/overlay/overlayLoader.component';
import { TabsComponent } from '../_shared/components/tabs/tabs.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type ThemeProps } from '../_shared/types/themeProps';
import { useTranslation } from '../_shared/utils/hooks';
import { RestoreVersionSelectorComponent } from './restoreVersionSelector.component';
import {
  UNDO_MODAL_WIDTH, UndoSelectorType,
} from './undoSelector.constants';
import { UndoSpecificActionSelectorComponent } from './undoSpecificActionSelector.component';
import { type UndoSelectorItems } from './useUndoSelectorItems.hook';

const UNDO_TAB_BODY_PADDING_VERTICAL = 16;

const modalContentContainerStyle = css({
  padding: 0,
});

const modalContentStyle = (props: ThemeProps) => css({
  padding: 0,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  color: props.theme.textColors.primary,
});

const tabBodyStyle = css({
  position: 'relative',
  height: '100%',
  padding: `${UNDO_TAB_BODY_PADDING_VERTICAL}px 24px`,
});

const buttonStyle = css({
  height: MODAL_DEFAULT_CONFIRM_BUTTON_HEIGHT,
});

type UndoSelectorProps = Readonly<{
  isLoading: boolean;
  sections: Set<UndoSelectorType>;
  isOpen: boolean;
  items: UndoSelectorItems;

  onClose: () => void;
  onRollback: (selectedVersionId: string) => void;
  onUndoModifying: (selectedItemsIndexes: ReadonlyArray<string | number>) => void;
  onSaveNoItemsSelected: () => void;
}>;

export const UndoSelectorComponent: React.FC<UndoSelectorProps> = props => {
  const { sections, items, onRollback, onUndoModifying, onSaveNoItemsSelected } = props;

  const [selectedTab, setSelectedTab] = useState<UndoSelectorType>(
    !props.sections.has(UndoSelectorType.undoSpecificAction) && sections.has(UndoSelectorType.resetToVersion)
      ? UndoSelectorType.resetToVersion : sections.has(UndoSelectorType.undoSpecificAction)
        ? UndoSelectorType.undoSpecificAction : UndoSelectorType.undoMapDeletion
  );
  const [selectedModificationIds, setSelectedModificationIds] = useState<ReadonlySet<string | number>>(new Set());
  const [selectedDeletionIds, setSelectedDeletionIds] = useState<ReadonlySet<string | number>>(new Set());
  const [selectedVersionId, setSelectedVersionId] = useState<null | string>(null);

  const [t] = useTranslation();

  const handleSave = useCallback(() => {
    let relevantItems: UndoSelectorItem[] = [];
    let relevantSelectedIds: ReadonlySet<string | number>;

    switch (selectedTab) {
      case UndoSelectorType.undoSpecificAction: {
        const specificActions = items.mapModifications;
        if (specificActions) {
          relevantItems = specificActions;
          relevantSelectedIds = selectedModificationIds;
        }
        break;
      }
      case UndoSelectorType.undoMapDeletion: {
        relevantItems = items.mapDeletions;
        relevantSelectedIds = selectedDeletionIds;
        break;
      }
      case UndoSelectorType.resetToVersion: {
        if (selectedVersionId) {
          onRollback(selectedVersionId);
        }
        return;
      }
      default:
        return;
    }

    const selectedIndexes = relevantItems
      .map((item, index) => relevantSelectedIds.has(item.id) ? index : null)
      .filter(i => i !== null);

    if (selectedIndexes.length > 0) {
      onUndoModifying(selectedIndexes as ReadonlyArray<string | number>);
    }
    else {
      onSaveNoItemsSelected();
    }
  }, [onRollback, onUndoModifying, items.mapDeletions, items.mapModifications, selectedDeletionIds,
    selectedModificationIds, selectedTab, selectedVersionId, onSaveNoItemsSelected,
  ]);

  return (
    <ModalComponent
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
      caption={t('Undo Previous Actions')}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          buttonStyle={ButtonStyle.Primary}
          css={buttonStyle}
          text={t('Done')}
          onClick={handleSave}
          isDisabled={props.isLoading}
        />
      )}
      contentStyle={modalContentContainerStyle}
      isOpen={props.isOpen}
      maxWidth={UNDO_MODAL_WIDTH}
      onClose={props.onClose}
    >
      <UndoSelectorContent
        items={props.items}
        sections={props.sections}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedDeletionIds={selectedDeletionIds}
        setSelectedDeletionIds={setSelectedDeletionIds}
        selectedModificationIds={selectedModificationIds}
        setSelectedModificationIds={setSelectedModificationIds}
        setSelectedVersionId={setSelectedVersionId}
      />
    </ModalComponent>
  );
};

type UndoSelectorContentProps = Readonly<{
  items: UndoSelectorItems;
  sections: Set<UndoSelectorType>;
  selectedTab: UndoSelectorType;
  selectedDeletionIds: ReadonlySet<string | number>;
  selectedModificationIds: ReadonlySet<string | number>;

  setSelectedTab: (tab: UndoSelectorType) => void;
  setSelectedDeletionIds: (ids: ReadonlySet<string | number>) => void;
  setSelectedModificationIds: (ids: ReadonlySet<string | number>) => void;
  setSelectedVersionId: (versionId: null | string) => void;
}>;

export const UndoSelectorContent = (props: UndoSelectorContentProps) => {
  const {
    items, sections, selectedTab, selectedDeletionIds, selectedModificationIds,
    setSelectedTab, setSelectedDeletionIds, setSelectedModificationIds, setSelectedVersionId,
  } = props;

  const [t] = useTranslation();
  const theme = useTheme();

  const tabsData: TabsData = useMemo(() => {
    const undoActionTab: TabData | null = sections.has(UndoSelectorType.undoSpecificAction) ? {
      index: UndoSelectorType.undoSpecificAction,
      header: t('undoSpecificAction'),
      child: (
        <div css={tabBodyStyle}>
          <UndoSpecificActionSelectorComponent
            items={items.mapModifications}
            onSelectItemIds={setSelectedModificationIds}
            selectedItemIds={selectedModificationIds}
            title={{
              title: t('SELECT THE ACTIONS YOU WISH TO UNDO'),
              emptyTitle: t('THERE IS NO ACTION TO UNDO'),
            }}
          />
        </div>
      ),
    } : null;

    const undoMapTab: TabData = {
      index: UndoSelectorType.undoMapDeletion,
      header: t('undoMapDeletion'),
      child: (
        <div css={tabBodyStyle}>
          <UndoSpecificActionSelectorComponent
            items={items.mapDeletions}
            onSelectItemIds={setSelectedDeletionIds}
            selectedItemIds={selectedDeletionIds}
            title={{
              title: t('Select the maps you wish to restore'),
              emptyTitle: t('There are no deleted maps'),
            }}
          />
        </div>
      ),
    };

    const resetToVersionTab: TabData | null = items.versions && sections.has(UndoSelectorType.resetToVersion) ? {
      index: UndoSelectorType.resetToVersion,
      header: t('resetToVersion'),
      child: (
        <div css={tabBodyStyle}>
          <RestoreVersionSelectorComponent
            items={items.versions
              .sort((vA, vB) => +vB.createdAt - +vA.createdAt)}
            onSelectVersion={setSelectedVersionId}
            height={500 - 2 * UNDO_TAB_BODY_PADDING_VERTICAL}
          />
        </div>
      ),
    } : null;

    const notEmptyTabs: TabsData = [undoMapTab, ...[resetToVersionTab].filter(notNull)];
    return undoActionTab ? [undoActionTab, ...notEmptyTabs] : notEmptyTabs;
  }, [sections, t, items.mapModifications, items.mapDeletions, items.versions, setSelectedModificationIds,
    selectedModificationIds, setSelectedDeletionIds, selectedDeletionIds, setSelectedVersionId,
  ]);

  return (
    <div css={modalContentStyle({ theme })}>
      <TabsComponent
        activeTabIndex={selectedTab}
        data={tabsData}
        setActiveTabIndex={setSelectedTab}
      />
    </div>
  );
};
