import { css } from '@emotion/react';
import { faTrash as faTrashRegular } from '@fortawesome/pro-regular-svg-icons/faTrash';
import {
  faMap,
  faMinus,
  faQuestion,
  faTrash as faTrashSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useMemo,
} from 'react';
import {
  ButtonComponent, ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { ProgressBarComponent } from '~/_shared/baseComponents/loaders';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { MapPickerDropdownContainer } from '~/_shared/components/mapPickerDropdown/mapPickerDropdown.container';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BaseMapInfos } from '~/map/layered/layering.helpers';
import { type MapListingItem } from '~/map/listing/item/mapListingItem';
import { MapListFilter } from '~/map/map.repository';
import { type CombinedGeocodingProgressInfo } from '~/store/geocoding/useSpreadsheetGeocodingState.hook';
import { LayeredMapSectionHolderComponent } from '../../layeredMapSectionHolderComponent';

const mapRowContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const geocodingStatusRowStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.textColors.warning,
});

const geocodingProgressStyle = css({
  width: 300,
});

const dropdownStyle = css({
  alignItems: 'center',
  display: 'flex',
  marginTop: 6,
  marginBottom: 6,
  position: 'relative',
});

const selectActionContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
});

const questionIconStyle = ({ theme }: ThemeProps) => css({
  borderRadius: '50%',
  background: theme.iconColors.primary,
  width: 15,
  height: 14,
  padding: 5,
  color: theme.backgroundColors.primary,
  marginLeft: 8,
});

const selectActionTitleStyle = css({
  textTransform: 'uppercase',
  display: 'block',
  fontSize: 18,
});

const selectActionStyle = css({
  flexDirection: 'row',
  marginBottom: 20,
  width: '100%',
});

const selectActionItemStyle = css({
  '&:last-of-type > *': {
    justifyContent: 'end',
  },
});

const removeIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.primary,
  fontSize: 18,
  marginRight: 8,
  '&:hover': {
    color: theme.iconColors.backgroundDangerInvertedHover,
  },
});

const trashIconStyle = ({ theme, isSelected }: ThemeProps<{isSelected: boolean}>) => css(removeIconStyle({ theme }), {
  ...(isSelected ? { color: theme.iconColors.backgroundDangerInverted } : {}),
});

const labelStyle = css({
  textTransform: 'uppercase',
  '&:last-of-type': {
    justifyContent: 'end',
  },
});

const contentContainer = css({
  paddingLeft: '14px',
});

const addMoreStyle = css({
  marginTop: 6,
});

export enum SelectMapAddOrRemoveOperation {
  ADD = 'add',
  REMOVE = 'remove',
}

type SelectMapsSectionAddOrRemoveProps = {
  operation: SelectMapAddOrRemoveOperation;
  onChangeOperation?: (value: SelectMapAddOrRemoveOperation) => void;
};

export type SelectMapsRemoveBaseMapsProps = SelectMapsSectionAddOrRemoveProps & {
  deletedMaps: ReadonlySet<number>;
  operation: SelectMapAddOrRemoveOperation.REMOVE;
  onToggleBaseMap: (mapId: number) => void;
};

export type SelectMapsAddBaseMapProps = SelectMapsSectionAddOrRemoveProps & {
  operation: SelectMapAddOrRemoveOperation.ADD;
};

type SelectMapsSectionComponentProps = Readonly<{
  currentBaseMaps?: Readonly<BaseMapInfos>;
  eligibleMapsFilter: (currentMapIndex: number, mapItem: MapListingItem) => boolean;
  getGeocodingStateForMapListingItem: (mapItem: MapListingItem) => CombinedGeocodingProgressInfo | null;
  isDisabled: boolean;
  selectMapsAddOrRemoveProps?: SelectMapsRemoveBaseMapsProps | SelectMapsAddBaseMapProps;
  selectedMaps: Array<MapListingItem | null>;

  onAddNewMap: () => void;
  onMapSelected: (map: MapListingItem, mapIndex: number) => void;
  onRemoveNewMap: (index: number) => void;
  onSkipToMatchUp?: () => void;
  onStartGeocoding: (realSpreadsheetId: number) => void;
  onSubmit: () => void;
}>;

export const SelectMapsSectionComponent: FC<SelectMapsSectionComponentProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const {
    currentBaseMaps,
    eligibleMapsFilter,
    getGeocodingStateForMapListingItem,
    isDisabled,
    selectMapsAddOrRemoveProps,
    selectedMaps,
    onAddNewMap,
    onMapSelected,
    onRemoveNewMap,
    onSkipToMatchUp,
    onStartGeocoding,
    onSubmit,
  } = props;

  const buttonProps = useMemo(() => ({
    isDisabled,
    onClick: onSubmit,
  }), [isDisabled, onSubmit]);

  const secondaryButtonProps = useMemo(() => {
    if (onSkipToMatchUp) {
      return {
        isDisabled: false,
        onClick: onSkipToMatchUp,
        text: t('Skip to Match-up map columns'),
      };
    }

    return undefined;
  }, [onSkipToMatchUp, t]);

  const highlightedMapsFilter = useCallback((mapItem: MapListingItem) => {
    const geocodingState = getGeocodingStateForMapListingItem(mapItem);

    // first lets check geocoding state, as it receives also realtime Websockets info
    if (geocodingState) {
      return !!geocodingState.inProgress || !!geocodingState.pausedRealSpreadsheetIds.length;
    }

    if (mapItem.spreadSheets.some(spreadsheetMapInfo => !spreadsheetMapInfo.geocoding)) {
      return false;
    }

    // if we don't have info from geocoding state, we'll use the MapListingItem geocoding information
    const mapListingItemGeocodingProgress = mapItem.spreadSheets.reduce((accum, spreadsheetMapInfo) => {
      const geocoding = spreadsheetMapInfo.geocoding;
      if (geocoding) {
        Object.entries(geocoding.geocodings).forEach(([realSpreadsheetIdString, realSpreadsheetGeocoding]) => {
          accum.numberOfRealSpreadsheets += 1;
          if (geocoding.in_progress) {
            accum.inProgress = true;
            accum.progress += realSpreadsheetGeocoding.percentage;
          }
          else {
            accum.progress += 100;
          }
          if (geocoding.is_paused) {
            accum.pausedRealSpreadsheetIds.push(+realSpreadsheetIdString);
          }
        });
      }
      return accum;
    }, {
      progress: 0,
      inProgress: false,
      pausedRealSpreadsheetIds: [] as number[],
      numberOfRealSpreadsheets: 0,
    });

    const combinedMapListingItemGeocodingProgress = {
      progress: mapListingItemGeocodingProgress.progress / mapListingItemGeocodingProgress.numberOfRealSpreadsheets,
      inProgress: mapListingItemGeocodingProgress.inProgress,
      pausedRealSpreadsheetIds: mapListingItemGeocodingProgress.pausedRealSpreadsheetIds,
    };

    if (combinedMapListingItemGeocodingProgress) {
      return !!combinedMapListingItemGeocodingProgress.inProgress ||
        !!combinedMapListingItemGeocodingProgress.pausedRealSpreadsheetIds.length;
    }

    return false;
  }, [getGeocodingStateForMapListingItem]);

  return (
    <LayeredMapSectionHolderComponent
      buttonProps={buttonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <div>
        {selectMapsAddOrRemoveProps?.onChangeOperation && (
          <>
            <div css={selectActionContainerStyle}>
              <span css={selectActionTitleStyle}>{t('Select action')}</span>
              <TooltipDeprComponent tooltipContent={t('In this step you may add OR...')}>
                <HoverableIconComponent
                  baseColor={theme.iconColors.primary}
                  css={questionIconStyle({ theme })}
                  hoverColor={theme.backgroundColors.highlight}
                  icon={faQuestion}
                />
              </TooltipDeprComponent>
            </div>

            <div css={contentContainer}>
              <RadioGroupComponent
                css={selectActionStyle}
                itemCommonStyle={selectActionItemStyle}
                onValueChange={selectMapsAddOrRemoveProps.onChangeOperation}
                selectedValue={selectMapsAddOrRemoveProps.operation}
                items={[{
                  label: <span css={labelStyle}>{t('Add base maps')}</span>,
                  value: SelectMapAddOrRemoveOperation.ADD,
                }, {
                  label: <span css={labelStyle}>{t('Remove base maps')}</span>,
                  value: SelectMapAddOrRemoveOperation.REMOVE,
                }]}
              />
            </div>
          </>
        )
        }

        <div css={selectActionContainerStyle}>
          <span css={selectActionTitleStyle}>{t('Maps')}</span>
        </div>
        <div css={contentContainer}>
          {
            currentBaseMaps ? Object.values(currentBaseMaps).map((baseMap) => {
              return (
                <div
                  css={dropdownStyle}
                  key={baseMap.id}
                >
                  {
                    selectMapsAddOrRemoveProps?.operation === SelectMapAddOrRemoveOperation.REMOVE && (
                      <FontAwesomeIcon
                        icon={selectMapsAddOrRemoveProps.deletedMaps.has(baseMap.id) ? faTrashSolid : faTrashRegular}
                        css={trashIconStyle({
                          theme, isSelected: selectMapsAddOrRemoveProps.deletedMaps.has(baseMap.id),
                        })}
                        onClick={() => selectMapsAddOrRemoveProps.onToggleBaseMap(baseMap.id)}
                      />
                    )
                  }
                  <TextInputComponent
                    icon={faMap}
                    isDisabled
                    onChange={() => null}
                    type="text"
                    value={baseMap.name}
                  />
                </div>
              );
            }) : null
          }
          {selectedMaps.map((map, index) => {
            const selectedMapGeocodingState = map ? getGeocodingStateForMapListingItem(map) : null;

            return (
              <div
                css={mapRowContainerStyle}
                key={map?.id ?? index}
              >
                <div css={dropdownStyle}>
                  {selectMapsAddOrRemoveProps?.operation === SelectMapAddOrRemoveOperation.ADD && (currentBaseMaps || selectedMaps.length > 2) && (
                    <FontAwesomeIcon
                      icon={faMinus}
                      css={removeIconStyle({ theme })}
                      onClick={() => onRemoveNewMap(index)}
                    />
                  )}
                  <MapPickerDropdownContainer
                    onMapSelected={(map) => onMapSelected(map, index)}
                    highlightedMapsFilter={highlightedMapsFilter}
                    postFetchFilter={(map) => eligibleMapsFilter(index, map)}
                    preFetchFilter={MapListFilter.NonLayered}
                    selectedMap={map}
                  />
                </div>
                {!!selectedMapGeocodingState?.inProgress && !selectedMapGeocodingState?.pausedRealSpreadsheetIds.length && (
                  <div css={geocodingStatusRowStyle({ theme })}>
                    <span>{t('Geocoding in progress')}...</span>
                    <ProgressBarComponent
                      css={geocodingProgressStyle}
                      progress={selectedMapGeocodingState.progress}
                    />
                  </div>
                )}
                {!!selectedMapGeocodingState?.pausedRealSpreadsheetIds.length && (
                  <div css={geocodingStatusRowStyle({ theme })}>
                    <span>{t('Your data were never fully imported to the map.')}</span>
                    <ButtonComponent
                      buttonStyle={ButtonStyle.Tertiary}
                      text={t('Finish Geocoding')}
                      size={ButtonSize.Small}
                      onClick={() => onStartGeocoding(selectedMapGeocodingState.pausedRealSpreadsheetIds[0] as number)}
                      isDisabled={selectedMapGeocodingState.changePauseRequestSent}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {
            (selectMapsAddOrRemoveProps?.operation === SelectMapAddOrRemoveOperation.ADD && (
              <AddMorePlaceholderComponent
                css={addMoreStyle}
                onClick={onAddNewMap}
                title={t('Select another Map')}
              />
            ))
          }
        </div>
      </div>
    </LayeredMapSectionHolderComponent>
  );
};
