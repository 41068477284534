import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { type MetricValue } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import {
  createOptimizedTerritories,
  type CreateOptimizedTerritoriesRequestParams, type TargetMetric,
} from '~/store/boundaries/boundaries.repository';

export const useGenerateOptimizedTerritories = () => {
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, params: CreateOptimizedTerritoriesRequestParams) =>
    createOptimizedTerritories(clientId, params)
  );

  const createOptimizedTerritoriesRequest = useCallback(({
    clientId,
    dataMetricsValues,
    demographicsValues,
    ...restData
  }: {
    clientId: number;
    dataMetricsValues: MetricValue[];
    demographicsValues: MetricValue[];
  } & Omit<CreateOptimizedTerritoriesRequestParams, 'targetMetrics' | 'constraints'>
  ) => {
    const targetMetrics: TargetMetric[] = [
      ...dataMetricsValues.filter(dataMetricsValue => dataMetricsValue.checked).map(dataMetricsValue => ({
        name: dataMetricsValue.id,
        weighting: dataMetricsValue.weight,
      })),
      ...demographicsValues.filter(demographicsValue => demographicsValue.checked).map(demographicsValue => ({
        name: demographicsValue.id,
        weighting: demographicsValue.weight,
      })),
    ];
    const constraints: Array<{
      metric: string;
      operator: string;
      value: number;
    }> = [
      ...dataMetricsValues.filter(dataMetricsValue => dataMetricsValue.isConstraint).map(dataMetricsValue => ({
        metric: dataMetricsValue.id,
        operator: 'gte',
        value: dataMetricsValue.minConstraint ?? dataMetricsValue.min,
      })),
      ...dataMetricsValues.filter(dataMetricsValue => dataMetricsValue.isConstraint).map(dataMetricsValue => ({
        metric: dataMetricsValue.id,
        operator: 'lte',
        value: dataMetricsValue.maxConstraint ?? dataMetricsValue.max,
      })),
      ...demographicsValues.filter(demographicsValue => demographicsValue.isConstraint).map(demographicsValue => ({
        metric: demographicsValue.id,
        operator: 'gte',
        value: demographicsValue.minConstraint ?? demographicsValue.min,
      })),
      ...demographicsValues.filter(demographicsValue => demographicsValue.isConstraint).map(demographicsValue => ({
        metric: demographicsValue.id,
        operator: 'lte',
        value: demographicsValue.maxConstraint ?? demographicsValue.max,
      })),
    ];
    return invokeAjax(clientId, {
      targetMetrics,
      constraints,
      ...restData,
    });
  }, [invokeAjax]);

  return {
    isLoading,
    createOptimizedTerritoriesRequest,
  };
};
