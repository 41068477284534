import {
  type FC, useCallback,
} from 'react';
import { useQuery } from '../../_shared/utils/hooks/useQuery';
import { useVerifyEmail } from './useVerifyEmail';
import { VerifyEmailPageComponent } from './verifyEmailPage.component';

export const VerifyEmailPageContainer: FC = () => {
  const { isLoading, isEmailSending, errorMessage, getEmailVerification, resendEmailVerification } = useVerifyEmail();
  const query = useQuery();
  const expires = query.get('expires');
  const hash = query.get('hash');
  const id = query.get('id');
  const userEmail = query.get('email');
  const signature = query.get('signature');

  const startVerification = useCallback((successCallback: () => void, failureCallback: () => void) => {
    if (expires && hash && id && userEmail && signature) {
      getEmailVerification({ expires, hash, id, userEmail, signature, successCallback, failureCallback });
    }
  }, [expires, hash, id, userEmail, signature, getEmailVerification]);

  const onResend = useCallback((successCallback: () => void, failureCallback: () => void) => {
    if (userEmail) {
      resendEmailVerification(userEmail, successCallback, failureCallback);
    }
  }, [resendEmailVerification, userEmail]);

  return (
    <VerifyEmailPageComponent
      startVerification={startVerification}
      onResend={onResend}
      isLoading={isLoading}
      isEmailSending={isEmailSending}
      errorMessage={errorMessage}
    />
  );
};
