import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  type GroupingColumn, isGroupingColumnNumeric, isGroupingColumnText,
} from '~/_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '~/_shared/types/grouping/numericActiveGroupColumnType.helpers';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { filterNonNumericBuckets } from '~/_shared/utils/grouping/grouping.helpers';
import { useGetSpreadsheetRowIdsByGroupInfo } from '~/_shared/utils/grouping/useGetSpreadsheetRowIdsByGroupInfo.hook';
import {
  createNumericBucketKey, createTextGroupKey,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { isGroupPrimary } from '~/grouping/groupingColumns.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import {
  removeAllIndividualMarkerSettings,
  removeAllMarkerAboveLabelSettings,
  removeAllMarkerLabelSettings,
  removeAllMarkerSettings,
  removeIndividualMarkerSettings,
  setNumericGroupBucketMarkerSettings,
  setTextGroupMarkerSettings,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { getGroupingColumnsUniqueGroups } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { emptySpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.state';
import { MarkerSettingType } from '../../_shared/types/markers/visualSettings.enums';
import { RestoreDefaultMarkerCustomizationsModalComponent } from './restoreDefaultMarkerCustomizationsModal.component';

export type RestoreDefaultMarkerCustomizationsContainerProps = ModalProps<{
  groupInfo?: Readonly<{
    bucketId?: number;
    groupId?: string;
    groupingColumn: GroupingColumn;
    hierarchyIndicator?: number;
  }>;
  spreadsheetRowId?: SpreadsheetRowId;
  restoreMode?: Readonly<MarkerSettingType[]>;
}>;

export const RestoreDefaultMarkerCustomizationsModalContainer: FC<RestoreDefaultMarkerCustomizationsContainerProps> = ({ groupInfo, spreadsheetRowId, restoreMode = [], isOpen, onClose }) => {
  const dispatch = useDispatch();
  const groupingColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const groupInfoForFetchGroupMarkers = useMemo(() => {
    if (groupInfo) {
      if (isGroupingColumnNumeric(groupInfo.groupingColumn)) {
        return {
          groupingColumn: groupInfo.groupingColumn,
          bucketId: groupInfo.bucketId ?? -1,
        };
      }
      if (isGroupingColumnText(groupInfo.groupingColumn)) {
        return {
          groupingColumn: groupInfo.groupingColumn,
          groupId: groupInfo.groupId ?? '-1',
        };
      }
    }
    return null;
  }, [groupInfo]);

  const allIndividualMarkersInGroup = useGetSpreadsheetRowIdsByGroupInfo(groupInfoForFetchGroupMarkers);

  const uniqueGroupColumns = useMemo(() => getGroupingColumnsUniqueGroups(
    spreadsheetData ?? emptySpreadsheetData,
    matchupData,
    groupInfo?.groupingColumn || undefined,
  ), [matchupData, groupInfo?.groupingColumn, spreadsheetData]);

  const restoreIndividual = useCallback(() => {
    if (spreadsheetRowId) {
      dispatch(removeIndividualMarkerSettings([spreadsheetRowId]));
    }
  }, [dispatch, spreadsheetRowId]);

  const restoreAllIndividual = useCallback(() => {
    dispatch(removeAllIndividualMarkerSettings());
  }, [dispatch]);

  const restoreGroup = useCallback(() => {
    if (groupInfo) {
      if (allIndividualMarkersInGroup.length) {
        dispatch(removeIndividualMarkerSettings(allIndividualMarkersInGroup));
      }
      if (isGroupingColumnNumeric(groupInfo.groupingColumn) && groupInfo.bucketId !== undefined) {
        const numericActiveGroupColumnType = getNumericActiveGroupColumnType(groupingColumns);
        if (numericActiveGroupColumnType) {
          const bucketLocationString = createNumericBucketKey(
            numericActiveGroupColumnType,
            groupInfo.hierarchyIndicator || 0,
            filterNonNumericBuckets(uniqueGroupColumns).length,
          );

          dispatch(setNumericGroupBucketMarkerSettings({
            spreadsheetId: groupInfo.groupingColumn.spreadsheetId,
            columnId: groupInfo.groupingColumn.columnId,
            bucketKey: bucketLocationString,
            bucketId: groupInfo.bucketId,
            settings: null,
          }));
        }
      }
      if (isGroupingColumnText(groupInfo.groupingColumn) && groupInfo.groupId) {
        dispatch(setTextGroupMarkerSettings({
          spreadsheetId: groupInfo.groupingColumn.spreadsheetId,
          columnId: groupInfo.groupingColumn.columnId,
          groupKey: createTextGroupKey(groupInfo.groupId, isGroupPrimary(groupInfo.hierarchyIndicator)),
          settings: null,
        }));
      }
    }
  }, [groupInfo, allIndividualMarkersInGroup, dispatch, groupingColumns, uniqueGroupColumns]);

  const restoreAll = useCallback(() => {
    if (restoreMode.length === 0 || restoreMode.includes(MarkerSettingType.Marker)) {
      dispatch(removeAllMarkerSettings());
      // removeAllMarkerSettings will remove whole section also with label + labelAbove and more, we can return
      return;
    }
    if (restoreMode.includes(MarkerSettingType.Label)) {
      dispatch(removeAllMarkerLabelSettings());
    }
    if (restoreMode.includes(MarkerSettingType.LabelAbove)) {
      dispatch(removeAllMarkerAboveLabelSettings());
    }

  }, [dispatch, restoreMode]);

  return (
    <RestoreDefaultMarkerCustomizationsModalComponent
      isOpen={isOpen}
      onClose={onClose}
      onRestoreAll={restoreAll}
      onRestoreAllIndividual={!(restoreMode.length === 1 && restoreMode.includes(MarkerSettingType.LabelAbove)) ? restoreAllIndividual : undefined}
      onRestoreGroup={groupInfo ? restoreGroup : undefined}
      onRestoreIndividual={spreadsheetRowId ? restoreIndividual : undefined}
    />
  );
};
