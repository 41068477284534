import {
  css,
  type SerializedStyles,
} from '@emotion/react';
import {
  forwardRef,
  type RefObject,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  mapListingTableHeaderLastStyle,
  mapListingTableQuaternaryStyle,
  mapListingTableQuinaryStyle,
  mapListingTableSecondaryStyle,
  mapListingTableTertiaryStyle,
} from '~/map/listing/mapListing.styles';

const tableRowStyle = ({ theme }: ThemeProps) => css({
  height: 28,
  borderRadius: '8px 8px 0 0',
  overflow: 'hidden',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const tableHeaderStyle = ({ theme, index, columnWidths }: ThemeProps<{width?: number; index: number; columnWidths?: ReadonlyArray<number>}>) => css({
  width: columnWidths?.[index] ?? 0,
  whiteSpace: 'nowrap',
  fontWeight: 400,
  fontSize: 14,
  color: theme.textColors.quaternary,
  textTransform: 'uppercase',
  textAlign: 'left',
  padding: '0 10px',
  boxSizing: 'border-box',
  background: theme.backgroundColors.quaternary,
  borderRadius: columnWidths?.findLastIndex(width => !!width) === index ? '0 8px 0 0' : undefined,
  '&:first-of-type': {
    borderRadius: '8px 0 0 0',
  },
  '&:last-of-type': {

  },
});

type TableHeaderContentProps = Readonly<{
  columnWidths?: ReadonlyArray<number>;
  refSetter?: RefObject<HTMLTableRowElement>;
  className?: string;
  dataStyle?: SerializedStyles;
  rowStyle?: SerializedStyles;
  width: number;
}>;

export const TableHeaderComponent = forwardRef<HTMLTableRowElement, TableHeaderContentProps>((props, ref) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <thead
      className={props.className}
    >
      <tr
        css={[tableRowStyle({ theme }), props.rowStyle]}
        ref={ref}
      >
        <th
          css={[tableHeaderStyle({ theme, index: 0, columnWidths: props.columnWidths }), props.dataStyle]}
        >
          {t('My Maps')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 1, columnWidths: props.columnWidths }), mapListingTableSecondaryStyle(props.width), props.dataStyle]}
        >
          {t('Saved Map Views')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 2, columnWidths: props.columnWidths }), mapListingTableQuinaryStyle(props.width), props.dataStyle]}
        >
          {t('Date created')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 3, columnWidths: props.columnWidths }), mapListingTableQuinaryStyle(props.width), props.dataStyle]}
        >
          {t('Last updated')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 4, columnWidths: props.columnWidths }), mapListingTableQuaternaryStyle(props.width), props.dataStyle]}
        >
          {t('Views')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 5, columnWidths: props.columnWidths }), mapListingTableTertiaryStyle(props.width), props.dataStyle]}
        >
          {t('Map Security')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 6, columnWidths: props.columnWidths }), mapListingTableHeaderLastStyle(props.width), props.dataStyle]}
        >
          {t('Map Control')}
        </th>
        <th
          css={[tableHeaderStyle({ theme, index: 7, columnWidths: props.columnWidths }), props.dataStyle]}
        />
      </tr>
    </thead>
  );
});
