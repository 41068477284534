import { css } from '@emotion/react';
import {
  type FC, type FormEvent, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { InputWithSpinnersComponent } from '~/_shared/baseComponents/inputs';
import { FormSubmitEnablerComponent } from '../../_shared/components/formSubmitEnabler/formSubmitEnabler.component';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { type ModalProps } from '../../modal/modalType.enum';

export const RESTRICT_ZOOM_LEVEL_MIN = 0;
export const RESTRICT_ZOOM_LEVEL_MAX = 30;

const containerStyle = css({
  overflow: 'hidden',
});

const headingStyle = css({
  margin: '0 0 15px',
});

const labelStyle = css({
  display: 'block',
  textTransform: 'uppercase',
  marginBottom: 10,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

type RestrictZoomLevelModalProps = ModalProps<{
  initialZoomLevelFrom?: number;
  initialZoomLevelTo?: number;
  onSubmit: (newLevelFrom: number, newLevelTo: number) => void;
}>;

export const RestrictZoomLevelModalComponent: FC<RestrictZoomLevelModalProps> = (props) => {
  const [t] = useTranslation();
  const [levelFrom, setLevelFrom] = useState(props.initialZoomLevelFrom ?? RESTRICT_ZOOM_LEVEL_MIN);
  const [levelTo, setLevelTo] = useState(props.initialZoomLevelTo ?? RESTRICT_ZOOM_LEVEL_MAX);

  const validateForm = () => {
    return levelFrom >= RESTRICT_ZOOM_LEVEL_MIN &&
      levelTo <= RESTRICT_ZOOM_LEVEL_MAX &&
      levelFrom <= levelTo;
  };

  const onSubmit = (e?: FormEvent) => {
    e?.preventDefault();

    if (!validateForm()) {
      return;
    }

    props.onSubmit(levelFrom, levelTo);
  };

  const onLevelFromChange = (newLevelFrom: number) => {
    if (isNaN(newLevelFrom)) {
      return;
    }

    if (newLevelFrom < RESTRICT_ZOOM_LEVEL_MIN) {
      return;
    }

    setLevelFrom(Math.floor(newLevelFrom));
  };

  const onLevelToChange = (newLevelTo: number) => {
    if (isNaN(newLevelTo)) {
      return;
    }

    if (newLevelTo > RESTRICT_ZOOM_LEVEL_MAX) {
      return;
    }

    setLevelTo(Math.floor(newLevelTo));
  };

  useEffect(() => {
    if (props.initialZoomLevelTo === undefined) {
      return;
    }

    setLevelTo(props.initialZoomLevelTo ?? RESTRICT_ZOOM_LEVEL_MAX);
  }, [props.initialZoomLevelTo]);

  useEffect(() => {
    if (props.initialZoomLevelFrom === undefined) {
      return;
    }

    setLevelFrom(props.initialZoomLevelFrom ?? RESTRICT_ZOOM_LEVEL_MIN);
  }, [props.initialZoomLevelFrom]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Restrict Zoom Levels')}
      confirmButton={(
        <ButtonComponent
          text={t('Save')}
          onClick={onSubmit}
          isDisabled={!validateForm()}
        />
      )}
    >
      <form
        css={containerStyle}
        onSubmit={onSubmit}
      >
        <p css={headingStyle}>{t('Allow zoom levels')}</p>

        <label css={labelStyle}>
          {t('From')}
          <InputWithSpinnersComponent
            value={levelFrom}
            onChange={value => onLevelFromChange(value)}
            valueMin={RESTRICT_ZOOM_LEVEL_MIN}
            valueMax={levelTo}
          />
        </label>

        <label css={labelStyle}>
          {t('To')}
          <InputWithSpinnersComponent
            value={levelTo}
            onChange={value => onLevelToChange(value)}
            valueMin={levelFrom}
            valueMax={RESTRICT_ZOOM_LEVEL_MAX}
          />
        </label>

        <FormSubmitEnablerComponent />
      </form>
    </ModalComponent>
  );
};
