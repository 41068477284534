import {
  BOUNDARY_CREATE_OPTIMIZED_ADD_ITEM,
  BOUNDARY_CREATE_OPTIMIZED_REMOVE_ITEM, BOUNDARY_CREATE_OPTIMIZED_UPDATE_ITEM,
} from './boundaryCreateOptimized.actionTypes';
import { type BoundaryCreateOptimizedItem } from './boundaryCreateOptimized.state';

export const boundaryCreateOptimizedAddItem = (item: BoundaryCreateOptimizedItem) => ({
  type: BOUNDARY_CREATE_OPTIMIZED_ADD_ITEM,
  payload: {
    item,
  },
}) as const;

export const boundaryCreateOptimizedUpdateItem = (updatedItem: Omit<BoundaryCreateOptimizedItem, 'startedOnCurrentTab'>) => ({
  type: BOUNDARY_CREATE_OPTIMIZED_UPDATE_ITEM,
  payload: {
    updatedItem,
  },
}) as const;

export const boundaryCreateOptimizedRemoveItem = (id: number) => ({
  type: BOUNDARY_CREATE_OPTIMIZED_REMOVE_ITEM,
  payload: {
    id,
  },
}) as const;
