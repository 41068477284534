import type { Writable } from 'ts-essentials';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import type { ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { getLabelPaddingsValues } from '~/_shared/components/labelVisualizer/labelVisualizer.constants';
import { WebglOverlayTextBoxCalloutTriangleSide } from '~/_shared/constants/webgl.constants';
import { type LatLng } from '~/_shared/types/latLng';
import {
  isCustomMarkerStyle, isLabelStyle, isMarkerStyle, isStandardMarkerStyle, MarkerAnchorPosition, MarkerColor,
  type MarkerEntityStyle, type MarkerLabelStyles, MarkerSegment, type MarkerSpritesheetSettings, type MarkerStyle,
} from '~/_shared/types/marker.types';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  arrayEquals, notEmpty,
} from '~/_shared/utils/array/array.helpers';
import {
  convertColorToWebGLColor, getContrastColor, webGLColorToString,
} from '~/_shared/utils/colors/colors.helpers';
import { getLabelStroke } from '~/_shared/utils/labels/labels.helpers';
import { extractLatLng } from '~/_shared/utils/latLng/latLng.helpers';
import { logError } from '~/_shared/utils/logError';
import type { PieChartItem } from '~/_shared/utils/maptive/pieCharts/pieCharts.types';
import { calculateOffsetsForMarkerEntity } from '~/_shared/utils/markers/offsets.helpers';
import { parseCombinedRowId } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { type ReadonlySpreadsheetRowIdMap } from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import {
  createLabelWithCustomCalloutLayer, createLabelWithTextBoxCalloutLayer, type LabelWithCalloutConfig,
  type LabelWithCustomCalloutConfig, type LabelWithCustomCalloutlLayer, type LabelWithTextBoxCalloutConfig,
  type LabelWithTextBoxCalloutlLayer, type WebglOverlayLabelWithTextBoxCallout,
} from '~/_shared/utils/webgl/labelWithCallout';
import { opaqueBlack } from '~/_shared/utils/webgl/webglColor.helpers';
import { mapFont } from '~/map/map/mapLabelFonts';
import { MARKER_ALPHA_TEST } from '~/map/map/useGraphicSettings.hook';
import type { MarkerZIndex } from '~/map/zIndexes/markerZIndex';
import { type SpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type LatLngRowData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import {
  type MapMarkerEventCallback, type MapMarkerEventListeners,
} from '../useMarkerEvents/markerEventCallbacks.type';
import {
  type MarkerEntitiesStyle, type MarkerEntitiesStyles, type StackedMarkerStyles, type StandardMarkerStyleWithSize,
} from '../useMarkers/useMarkerStyles.hook';
import { type StackedMarkerId } from '../useStacksAndClusters/StackedMarkerId.type';
import {
  type MarkerWebglMapObjects, type WebglMarkerObject,
} from './mapMarkerManager.helpers';
import { PredefinedTemplate } from './mapMarkerTemplates';
import { createDynamicTemplateName } from './markerTemplateManager';

const HIGHLIGHT_MARKER_SIZE = 50;
const GLOW_MARKER_SIZE = 60;

const markerLabelsFont = mapFont.arial;

//#region TYPES

type RegisteredCallbacks = Readonly<{
  eventName: MapElementEventName;
  originalCallback: MapMarkerEventCallback;
  boundCallback: (e: MapObjectClickEventArgs) => void;
}>;

export type StackedMarker = LatLngRowData & Readonly<{
  stackedMarkers?: ReadonlyArray<LatLngRowData>;
  pieChartItems?: ReadonlyArray<MarkerPieChartItem>;
  secondaryPieChartItems?: ReadonlyArray<MarkerPieChartItem>;
  mainColor?: WebglColor;
  opacity?: number;
  subMarkerColor?: WebglColor;
  subMarkerOpacity?: number;
  subMarkerSize?: number;
  groupId?: string;
}>;

export type MarkerPieChartItem = WebglPieChartItem & Readonly<{
  id: number | string;
}>;

type MarkerData = Readonly<{
  id: StackedMarkerId;
  registeredCallbacks: ReadonlyArray<RegisteredCallbacks>;
  originalMarker: StackedMarker;
  marker: WebglMarkerObject | null;
  stackedMarkerNumberLabel: WebglOverlayLabel | null;
  stackedMarkerPieChart: WebglPieChart | null;
  labelAbove: WebglOverlayLabelWithTextBoxCallout | null;
  subMarker: WebglMarkerObject | null;
  subMarkerPieChart: WebglPieChart | null;
  highlight: WebglOverlayMarker | null;
  glow: WebglOverlayMarker | null;
  shadow: WebglOverlayMarker | null;
  isHidden: boolean;
}>;
type MarkersMap = Map<StackedMarkerId, MarkerData>;

type MarkerLabelTexts = Readonly<{
  labelMarkerTexts: ReadonlySpreadsheetRowIdMap<string>;
  labelAboveTexts: ReadonlySpreadsheetRowIdMap<string>;
}>;
type WithLabelZIndex = Readonly<{ base: number; labelText: number }>;

type MarkerWebGlLayers = ReturnType<typeof createMarkerLayers>;
type MarkerManagerLayers = MarkerWebGlLayers & Readonly<{
  MarkerTextBoxLabels: LabelWithTextBoxCalloutlLayer;
  MarkerCustomLabels: LabelWithCustomCalloutlLayer;
}>;

//#endregion

export class MapMarkerManager {
  private readonly overlayLayers: MarkerManagerLayers;
  private markers: MarkersMap = new Map();

  private eventListeners: MapMarkerEventListeners = [];

  constructor(overlayLayers: MarkerWebGlLayers) {
    this.overlayLayers = {
      ...overlayLayers,
      MarkerTextBoxLabels: createLabelWithTextBoxCalloutLayer(overlayLayers.MarkerLabelsText, overlayLayers.MarkerLabelsTextBoxBackground),
      MarkerCustomLabels: createLabelWithCustomCalloutLayer(overlayLayers.MarkerLabelsText, overlayLayers.MarkerLabelsCustomBackground),
    };
  }

  //#region EVENT LISTENERS

  private getMarkerWebglMapObjects = (id: StackedMarkerId): MarkerWebglMapObjects | null => {
    const data = this.markers.get(id);
    return data ? {
      marker: data.marker,
      shadow: data.shadow,
      labelAbove: data.labelAbove,
      subMarker: data.subMarker,
    } : null;
  };

  public addMarkersEventListener = (eventName: MapElementEventName, callback: MapMarkerEventCallback) => {
    this.eventListeners = [...this.eventListeners, { eventName, callback }];
    this.markers.forEach((data, id) => {
      const newData = this.addMarkerEventListener(data, eventName, callback);

      this.markers.set(id, newData);
    });
  };

  public removeMarkersEventListener = (eventName: MapElementEventName, callback: MapMarkerEventCallback) => {
    this.eventListeners = this.eventListeners.filter(e => e.eventName !== eventName || e.callback !== callback);
    this.markers.forEach((data, id) => {
      const newData = this.removeMarkerEventListener(data, eventName, callback);

      this.markers.set(id, newData);
    });
  };

  private addMarkerEventListener = (data: MarkerData, eventName: MapElementEventName, callback: MapMarkerEventCallback): MarkerData => {
    const target = data.marker?.entity === 'marker' ? data.marker.object : data.marker?.object.callout;

    if (!target) {
      return data;
    }

    const registeredCallback = {
      eventName,
      originalCallback: callback,
      boundCallback: (e: MapObjectMouseEventArgs) => {
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        callEventListenerForStack(callback, data.originalMarker, this.getMarkerWebglMapObjects.bind(this, data.id));
      },
    };

    target?.addEventListener(eventName, registeredCallback.boundCallback);

    return {
      ...data,
      registeredCallbacks: registeredCallback ? [...data.registeredCallbacks, registeredCallback] : data.registeredCallbacks,
    };
  };

  private removeMarkerEventListener = (data: MarkerData, eventName: MapElementEventName, callback: MapMarkerEventCallback): MarkerData => {
    const registeredCallback = data.registeredCallbacks.find(c => c.originalCallback === callback);

    if (!registeredCallback) {
      return data;
    }

    if (data.marker?.entity === 'marker') {
      data.marker.object.removeEventListener(eventName, registeredCallback.boundCallback);
    }
    else if (data.marker?.entity === 'label') {
      data.marker.object.callout?.removeEventListener(eventName, registeredCallback.boundCallback);
    }

    return { ...data, registeredCallbacks: data.registeredCallbacks.filter(c => c !== registeredCallback) };
  };

  private attachExistingEventListeners = (
    id: StackedMarkerId,
    entity: WebglMarkerObject,
    listeners: MapMarkerEventListeners,
    rowIds: readonly SpreadsheetRowId[],
    isStacked: boolean
  ): ReadonlyArray<RegisteredCallbacks> =>
    listeners.map(({ eventName, callback }) => {
      const target = entity?.entity === 'marker' ? entity.object : entity.object.callout;

      const registeredCallback = {
        eventName,
        originalCallback: callback,
        boundCallback: (e: MapObjectClickEventArgs) => {
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          callback(rowIds, isStacked, this.getMarkerWebglMapObjects.bind(this, id));
        },
      };

      target?.addEventListener(eventName, registeredCallback.boundCallback);

      return registeredCallback;
    });

  //#endregion

  //#region MARKERS

  public removeAllMarkers = () => {
    this.markers.forEach((data, id) => this.removeMarker(id, data));
    this.markers = new Map();
  };

  public updateMarkers = (
    rowsData: ReadonlyMap<StackedMarkerId, StackedMarker>,
    zIndexes: ReadonlySpreadsheetRowIdMap<MarkerZIndex>,
    markerStyles: MarkerEntitiesStyles,
    specialStyles: StackedMarkerStyles,
    markerLabelTexts: MarkerLabelTexts,
    highlightedRowIds: SpreadsheetLatLngRowData,
    glowRowIds: SpreadsheetLatLngRowData,
    visibleMarkerIds: ReadonlySet<StackedMarkerId>,
    hideMarkersOnRemove: boolean,
  ) => {
    this.removeExistingExceptNew(rowsData);
    this.updateExistingIntersectNew(rowsData, zIndexes, markerStyles, specialStyles, markerLabelTexts, highlightedRowIds, glowRowIds, visibleMarkerIds, hideMarkersOnRemove);
    this.addNewExceptExisting(rowsData, zIndexes, markerStyles, specialStyles, markerLabelTexts, highlightedRowIds, glowRowIds, visibleMarkerIds, hideMarkersOnRemove);
  };

  private removeExistingExceptNew = (newData: ReadonlyMap<StackedMarkerId, LatLngRowData>) => {
    this.markers.forEach((data, id) => {
      if (newData.has(id)) {
        return;
      }
      this.removeMarker(id, data);
    });
  };

  private updateExistingIntersectNew = (
    newData: ReadonlyMap<StackedMarkerId, StackedMarker>,
    zIndexes: ReadonlySpreadsheetRowIdMap<MarkerZIndex>,
    markerStyles: MarkerEntitiesStyles,
    specialStyles: StackedMarkerStyles,
    markerLabelTexts: MarkerLabelTexts,
    highlightedRowIds: SpreadsheetLatLngRowData,
    glowRowIds: SpreadsheetLatLngRowData,
    visibleMarkerIds: ReadonlySet<StackedMarkerId>,
    hideMarkersOnRemove: boolean,
  ) => {
    this.markers.forEach((data, id) => {
      const isHidden = !visibleMarkerIds.has(id);
      if (data.isHidden && isHidden) {
        // Skip updating hidden markers
        return;
      }

      if (!hideMarkersOnRemove && isHidden) {
        // Remove hidden marker instead of toggling visibility
        this.removeMarker(id, data);
        return;
      }

      const row = newData.get(id);
      const zIndex = zIndexes.get(row ?? data.originalMarker);
      const style = markerStyles.get(row ?? data.originalMarker);

      if (!zIndex || !style) {
        logError('mapMarkerManager: zIndex or marker style not found, this is most likely a mistake');
        return;
      }
      if (!row) {
        return;
      }

      const markerLabelText = markerLabelTexts.labelMarkerTexts.get(row) ?? null;
      const labelAboveText = markerLabelTexts.labelAboveTexts.get(row) ?? null;

      this.updateMarker(id, data, row, zIndex, style, specialStyles, markerLabelText, labelAboveText, !!highlightedRowIds.getRow(row), !!glowRowIds.getRow(row), isHidden);
    });
  };

  private addNewExceptExisting = (
    newData: ReadonlyMap<StackedMarkerId, StackedMarker>,
    zIndexes: ReadonlySpreadsheetRowIdMap<MarkerZIndex>,
    markerStyles: MarkerEntitiesStyles,
    specialStyles: StackedMarkerStyles,
    markerLabelTexts: MarkerLabelTexts,
    highlightedRowIds: SpreadsheetLatLngRowData,
    glowRowIds: SpreadsheetLatLngRowData,
    visibleMarkerIds: ReadonlySet<StackedMarkerId>,
    hideMarkersOnRemove: boolean,
  ) => {
    newData.forEach((row, id) => {
      if (this.markers.has(id)) {
        return;
      }

      const isHidden = !visibleMarkerIds.has(id);
      if (!hideMarkersOnRemove && isHidden) {
        // Skip adding hidden markers when hiding is disabled
        return;
      }

      const zIndex = zIndexes.get(row);
      const style = markerStyles.get(row);
      if (!zIndex || !style) {
        logError('mapMarkerManager: zIndex or marker style not found, this is most likely a mistake');
        return;
      }

      const markerLabelText = markerLabelTexts.labelMarkerTexts.get(row) ?? null;
      const labelAboveText = markerLabelTexts.labelAboveTexts.get(row) ?? null;

      this.addMarker(id, row, zIndex, style, specialStyles, markerLabelText, labelAboveText, !!highlightedRowIds.getRow(row), !!glowRowIds.getRow(row), isHidden);
    });
  };

  private removeMarker = (id: StackedMarkerId, data: MarkerData) => {
    if (data.marker?.entity === 'marker') {
      this.overlayLayers.Markers.remove(data.marker.object);
    }
    else if (data.marker?.entity === 'label') {
      if (data.marker.object.type === 'text') {
        this.overlayLayers.MarkerTextBoxLabels.remove(data.marker.object);
      }
      else if (data.marker.object.type === 'custom') {
        this.overlayLayers.MarkerCustomLabels.remove(data.marker.object);
      }
    }

    if (data.shadow) {
      this.overlayLayers.MarkersShadows.remove(data.shadow);
    }

    if (data.subMarker?.entity === 'marker') {
      this.overlayLayers.Markers.remove(data.subMarker.object);
    }
    else if (data.subMarker?.entity === 'label') {
      if (data.subMarker.object.type === 'text') {
        this.overlayLayers.MarkerTextBoxLabels.remove(data.subMarker.object);
      }
      else if (data.subMarker.object.type === 'custom') {
        this.overlayLayers.MarkerCustomLabels.remove(data.subMarker.object);
      }
    }

    if (data.labelAbove) {
      this.overlayLayers.MarkerTextBoxLabels.remove(data.labelAbove);
    }

    if (data.stackedMarkerNumberLabel) {
      this.overlayLayers.MarkerLabelsText.remove(data.stackedMarkerNumberLabel);
    }

    if (data.stackedMarkerPieChart) {
      this.overlayLayers.PieChartClusters.remove(data.stackedMarkerPieChart);
    }

    if (data.subMarkerPieChart) {
      this.overlayLayers.PieChartClusters.remove(data.subMarkerPieChart);
    }

    if (data.highlight) {
      this.overlayLayers.Markers.remove(data.highlight);
    }
    if (data.glow) {
      this.overlayLayers.Markers.remove(data.glow);
    }
    this.markers.delete(id);
  };

  private updateMarker = (
    id: StackedMarkerId,
    data: MarkerData,
    newMarker: StackedMarker,
    zIndex: MarkerZIndex,
    style: MarkerEntitiesStyle,
    stackedMarkerStyles: StackedMarkerStyles,
    markerLabelText: string | null,
    labelAboveMarkerText: string | null,
    isHighlighted: boolean,
    glow: boolean,
    isHidden: boolean,
  ) => {
    let result: Writable<typeof data> = { ...data, isHidden };

    // Update main marker
    const mainMarkerStyle = chooseMarkerStyle(newMarker, style.mainMarker, stackedMarkerStyles);
    const [updatedMain, newEventListeners] = this.updateWebGLMarkerLikeEntity({
      id: data.id,
      oldEntity: data.marker,
      style: mainMarkerStyle,
      segment: MarkerSegment.MAIN,
      marker: newMarker,
      zIndex: { base: zIndex.marker, labelText: zIndex.markerLabelText },
      labelText: markerLabelText ?? parseCombinedRowId(newMarker.rowId)?.rowId?.toString() ?? '',
      eventListeners: this.eventListeners,
      isHidden,
      getColor: getColorForMarker,
      getSize: getSizeForMarker,
    });

    result.marker = updatedMain;
    if (newEventListeners) {
      result.registeredCallbacks = newEventListeners;
    }

    //update shadow marker
    const [updatedShadowMarker] = this.updateWebGLMarkerLikeEntity({
      id: data.id,
      oldEntity: data.shadow ? { entity: 'marker', object: data.shadow } : null,
      style: isMarkerStyle(mainMarkerStyle) ? mainMarkerStyle : undefined,
      segment: MarkerSegment.SHADOW,
      marker: newMarker,
      zIndex: { base: zIndex.shadow, labelText: zIndex.markerLabelText },
      labelText: '',
      eventListeners: [],
      isHidden,
      getColor: getColorForMarker,
      getSize: getSizeForMarker,
    });
    result.shadow = updatedShadowMarker?.entity === 'marker' ? updatedShadowMarker.object : null;

    // Update number label of stacked markers
    const useStackedMarkerNumberLabel = isStacked(newMarker) && isStandardMarkerStyle(mainMarkerStyle) && !isHidden;
    const stackedMarkerWithNumberStyles = isStandardMarkerStyle(mainMarkerStyle) ? mainMarkerStyle : stackedMarkerStyles.stackedMarkerStyle;
    result.stackedMarkerNumberLabel = updateMarkerOptionalEntity({
      entity: result.stackedMarkerNumberLabel,
      shouldRender: useStackedMarkerNumberLabel,
      functions: { create: createWebGLLabelAsStackedMarkerNumber, update: updateWebGLLabelAsStackedMarkerNumber },
      params: [newMarker, stackedMarkerWithNumberStyles, getStackedIds(newMarker).length, zIndex.labelAboveText, isHidden],
      layer: this.overlayLayers.MarkerLabelsText,
    });

    // Update pie chart of stacked markers
    const useMarkerPieCharts = isStacked(newMarker) && hasPieChart(newMarker) && isStandardMarkerStyle(mainMarkerStyle) && !isHidden;
    result.stackedMarkerPieChart = updateMarkerOptionalEntity({
      entity: result.stackedMarkerPieChart,
      shouldRender: useMarkerPieCharts,
      functions: { create: createWebGLPieChart, update: updateWebGLPieChart },
      params: [{
        chartItems: newMarker.pieChartItems ?? [],
        latLng: extractLatLng(newMarker),
        placement: useMarkerPieCharts ? getStackedMarkerPieChartPlacement(mainMarkerStyle) : { offset: { x: 0, y: 0 }, radius: 0 },
        zIndex: zIndex.labelAbove,
        isHidden,
      }],
      layer: this.overlayLayers.PieChartClusters,
    });

    const useSubMarkerPieCharts = useMarkerPieCharts && hasSecondaryPieChart(newMarker);

    // Update sub-marker
    const [updatedSubMarker] = this.updateWebGLMarkerLikeEntity({
      id: data.id,
      shouldRender: style.subMarker && !useSubMarkerPieCharts,
      oldEntity: data.subMarker,
      style: style.subMarker,
      segment: MarkerSegment.MAIN,
      marker: newMarker,
      zIndex: { base: zIndex.subMarker, labelText: zIndex.markerLabelText },
      labelText: '',
      eventListeners: [],
      isHidden,
      getColor: getColorForSubMarker,
      getSize: getSizeForSubMarker,
    });
    result.subMarker = updatedSubMarker;

    result.subMarkerPieChart = updateMarkerOptionalEntity({
      entity: result.subMarkerPieChart,
      shouldRender: useSubMarkerPieCharts,
      functions: { create: createWebGLPieChart, update: updateWebGLPieChart },
      params: [{
        chartItems: newMarker.secondaryPieChartItems ?? [],
        latLng: extractLatLng(newMarker),
        placement: getSubMarkerPieChartPlacement(),
        ...getSubMarkerPieChartStyle(),
        zIndex: zIndex.subMarker,
        isHidden,
      }],
      layer: this.overlayLayers.PieChartClusters,
    });

    // Update label above
    const renderAboveLabel = !!style.labelAbove && (!!style.aboveLabelText || !!labelAboveMarkerText) && !isHidden;
    if (renderAboveLabel) {
      const labelOffsets = getOffsetsForLabelAbove(mainMarkerStyle, style.labelAbove);
      result.labelAbove = updateMarkerOptionalEntity({
        entity: result.labelAbove,
        shouldRender: renderAboveLabel,
        functions: {
          create: this.overlayLayers.MarkerTextBoxLabels.create,
          update: this.overlayLayers.MarkerTextBoxLabels.update,
        },
        params: [
          createLabelWithTextBoxConfig({
            latLng: extractLatLng(newMarker),
            zIndex: { base: zIndex.labelAbove, labelText: zIndex.labelAboveText },
            style: style.labelAbove,
            text: style.aboveLabelText || labelAboveMarkerText || '',
            verticalOffset: labelOffsets.bottom,
            horizontalOffset: labelOffsets.left,
            isHidden,
            labelType: 'text',
          }),
        ],
        layer: this.overlayLayers.MarkerTextBoxLabels,
      });
    }
    else if (result.labelAbove) {
      this.overlayLayers.MarkerTextBoxLabels.remove(result.labelAbove);
      result.labelAbove = null;
    }

    // Update highlight marker
    result.highlight = updateMarkerOptionalEntity({
      entity: result.highlight,
      shouldRender: isHighlighted,
      functions: { create: createHighlight, update: updateHighlight },
      params: [newMarker, zIndex.highlight],
      layer: this.overlayLayers.Markers,
    });

    // Update glow marker
    result.glow = updateMarkerOptionalEntity({
      entity: result.glow,
      shouldRender: glow,
      functions: { create: createGlow, update: updateGlow },
      params: [newMarker, zIndex.highlight],
      layer: this.overlayLayers.Markers,
    });

    // Update event listeners for changed stacked markers
    const oldStackedIds = getStackedIds(data.originalMarker).map(getRowId);
    const newStackedIds = getStackedIds(newMarker).map(getRowId);

    // We need to reassign listeners if the stack changes as the listener receives all stacked marker ids
    if (!arrayEquals(oldStackedIds, newStackedIds)) {
      result = { ...result, originalMarker: newMarker };
      const callbacks = data.registeredCallbacks;

      const withoutListeners = callbacks
        .reduce((reducedData, callback) => this.removeMarkerEventListener(reducedData, callback.eventName, callback.originalCallback), result);

      const withListeners = callbacks
        .reduce((reducedData, callback) => this.addMarkerEventListener(reducedData, callback.eventName, callback.originalCallback), withoutListeners);

      this.markers.set(id, withListeners);
      return;
    }

    this.markers.set(id, result);
  };

  private addMarker = (
    id: StackedMarkerId,
    newMarker: StackedMarker,
    zIndex: MarkerZIndex,
    style: MarkerEntitiesStyle,
    stackedMarkerStyles: StackedMarkerStyles,
    markerLabelText: string | null,
    labelAboveText: string | null,
    isHighlighted: boolean,
    glow: boolean,
    isHidden: boolean,
  ) => {
    // Main marker
    const mainMarkerStyle = chooseMarkerStyle(newMarker, style.mainMarker, stackedMarkerStyles);

    const main = this.addMarkerWebGlEntity({
      marker: newMarker,
      style: mainMarkerStyle,
      zIndex: { base: zIndex.marker, labelText: zIndex.markerLabelText },
      markerOptions: { segment: MarkerSegment.MAIN },
      labelOptions: {
        text: markerLabelText ?? '',
        labelType: isLabelStyle(mainMarkerStyle) && mainMarkerStyle.offsetProps.type === 'custom' ? 'custom' : 'text',
        verticalOffset: 0,
        horizontalOffset: 0,
      },
      isHidden,
      getColor: getColorForMarker,
      getSize: getSizeForMarker,
    });

    // Main marker shadow
    const markerShadow = isMarkerStyle(mainMarkerStyle) ? this.addMarkerWebGlEntity({
      marker: newMarker,
      style: mainMarkerStyle,
      zIndex: { base: zIndex.shadow, labelText: zIndex.markerLabelText },
      markerOptions: { segment: MarkerSegment.SHADOW },
      isHidden,
      getColor: getColorForMarker,
      getSize: getSizeForMarker,
    }) : null;

    // Stacked markers count label
    // this reuses labelAboveText zIndex, since there should never be a use case where they overlap, and saves zIndex range
    const stackedMarkersCount = getStackedIds(newMarker).length;
    const stackedMarkerNumberLabel = stackedMarkersCount > 1 && isStandardMarkerStyle(mainMarkerStyle)
      ? createWebGLLabelAsStackedMarkerNumber(newMarker, mainMarkerStyle, stackedMarkersCount, zIndex.labelAboveText, isHidden)
      : null;
    if (stackedMarkerNumberLabel) {
      this.overlayLayers.MarkerLabelsText.add(stackedMarkerNumberLabel);
    }

    // Stacked markers pie chart
    // this reuses labelAbove zIndex, since there should never be a use case where they overlap, and saves zIndex range
    const useMarkerPieCharts = !isHidden
      && hasPieChart(newMarker)
      && isStandardMarkerStyle(mainMarkerStyle);

    const stackedMarkerPieChart = !useMarkerPieCharts ? null : createWebGLPieChart({
      chartItems: newMarker.pieChartItems,
      latLng: extractLatLng(newMarker),
      placement: getStackedMarkerPieChartPlacement(mainMarkerStyle),
      zIndex: zIndex.labelAbove,
      isHidden,
    });

    if (stackedMarkerPieChart) {
      this.overlayLayers.PieChartClusters.add(stackedMarkerPieChart);
    }

    // Sub-marker
    const useSubMarkerPieCharts = useMarkerPieCharts && hasSecondaryPieChart(newMarker);
    const subMarkerPieChart = !useSubMarkerPieCharts ? null : createWebGLPieChart({
      chartItems: newMarker.secondaryPieChartItems,
      latLng: extractLatLng(newMarker),
      placement: getSubMarkerPieChartPlacement(),
      ...getSubMarkerPieChartStyle(),
      zIndex: zIndex.subMarker,
      isHidden,
    });

    if (subMarkerPieChart) {
      this.overlayLayers.PieChartClusters.add(subMarkerPieChart);
    }

    const subMarker = useSubMarkerPieCharts ? null : this.addMarkerWebGlEntity({
      marker: newMarker,
      style: style.subMarker,
      zIndex: { base: zIndex.subMarker, labelText: zIndex.markerLabelText },
      markerOptions: { segment: MarkerSegment.MAIN },
      isHidden,
      getColor: getColorForSubMarker,
      getSize: getSizeForSubMarker,
    });

    // Label above
    const labelAboveFinalText = style.aboveLabelText || labelAboveText;
    const labelOffsets = style.labelAbove && labelAboveFinalText ?
      getOffsetsForLabelAbove(mainMarkerStyle, style.labelAbove) : null;
    const labelAbove = style.labelAbove && labelAboveFinalText && labelOffsets ? this.addMarkerWebGlEntity({
      marker: newMarker,
      style: style.labelAbove,
      zIndex: { base: zIndex.marker, labelText: zIndex.markerLabelText },
      labelOptions: {
        text: labelAboveFinalText,
        verticalOffset: labelOffsets.bottom,
        horizontalOffset: labelOffsets.left,
        labelType: 'text',
      },
      isHidden,
      getColor: getColorForMarker,
      getSize: getSizeForMarker,
    }) : null;

    const highlight = isHighlighted ? createHighlight(newMarker, zIndex.highlight) : null;
    if (highlight) {
      this.overlayLayers.Markers.add(highlight);
    }

    const glowEffect = glow ? createGlow(newMarker, zIndex.highlight) : null;
    if (glowEffect) {
      this.overlayLayers.Markers.add(glowEffect);
    }

    const newData: MarkerData = {
      id,
      originalMarker: newMarker,
      marker: main,
      registeredCallbacks: [],
      stackedMarkerNumberLabel,
      stackedMarkerPieChart,
      subMarker,
      subMarkerPieChart,
      labelAbove: labelAbove?.entity === 'label' && labelAbove.object.type === 'text' ? labelAbove.object : null,
      highlight,
      glow: glowEffect,
      shadow: markerShadow?.entity === 'marker' ? markerShadow.object : null,
      isHidden,
    };

    const withEventListeners = this.eventListeners
      .reduce((reducedData, e) => this.addMarkerEventListener(reducedData, e.eventName, e.callback), newData);

    this.markers.set(id, withEventListeners);
  };

  private addMarkerWebGlEntity = ({ marker, style, markerOptions, labelOptions, zIndex, isHidden, getColor, getSize }: Readonly<{
    marker: StackedMarker;
    style?: MarkerEntityStyle;
    zIndex: WithLabelZIndex;
    markerOptions?: { readonly segment: MarkerSegment};
    labelOptions?: Readonly<{ text: string; verticalOffset: number; horizontalOffset: number; labelType: 'text' | 'custom' }>;
    isHidden: boolean;
    getColor: (marker: StackedMarker, style: MarkerStyle) => WebglColorWithOpacity;
    getSize: (marker: StackedMarker, style: MarkerStyle) => number;
  }>): WebglMarkerObject | null => {
    if (isMarkerStyle(style) && markerOptions) {
      const entity = style?.segments?.[markerOptions.segment] ? {
        entity: 'marker',
        object: createWebGLMarker({
          data: marker,
          zIndex: zIndex.base,
          style,
          segment: markerOptions.segment,
          isHidden,
          color: getColor(marker, style),
          size: getSize(marker, style),
        }),
      } as const : null;

      if (entity) {
        if (markerOptions.segment === MarkerSegment.SHADOW) {
          this.overlayLayers.MarkersShadows.add(entity.object);
        }
        else {
          this.overlayLayers.Markers.add(entity.object);
        }
      }

      return entity;
    }
    else if (isLabelStyle(style) && labelOptions && !isHidden) {
      const entity = this.createLabelEntity({ style, latLng: extractLatLng(marker), isHidden, zIndex, ...labelOptions });

      if (entity.object.type === 'text') {
        this.overlayLayers.MarkerTextBoxLabels.add(entity.object);
      }
      else if (entity.object.type === 'custom') {
        this.overlayLayers.MarkerCustomLabels.add(entity.object);
      }

      return entity;
    }

    return null;
  };

  private updateWebGLMarkerLikeEntity = ({ id, oldEntity, style, segment, marker, zIndex, labelText, eventListeners, isHidden, shouldRender = true, getColor, getSize }: Readonly<{
    id: StackedMarkerId;
    oldEntity: WebglMarkerObject | null;
    style: MarkerEntityStyle | undefined;
    segment: MarkerSegment;
    marker: StackedMarker;
    zIndex: WithLabelZIndex;
    labelText: string;
    eventListeners: MapMarkerEventListeners;
    isHidden: boolean; // hidden marker is still added to webgl layer but not visible
    shouldRender?: boolean; // if false, the entity is removed from webgl layer
    getColor: (marker: StackedMarker, style: MarkerStyle) => WebglColorWithOpacity;
    getSize: (marker: StackedMarker, style: MarkerStyle) => number;
  }>): readonly [WebglMarkerObject | null, ReadonlyArray<RegisteredCallbacks> | null] => {
    const newEntityType = !style ? undefined : isMarkerStyle(style) ? 'marker' : 'label';
    const newLabelType = !isLabelStyle(style) ? undefined : style.offsetProps.type === 'default' ? 'text' : 'custom';

    // Remove old entity if the marker is removed or changed to different type
    if (!shouldRender || !style
      || (oldEntity?.entity === 'marker' && newEntityType !== 'marker')
      || (isMarkerStyle(style) && !style.segments?.[segment])
      || (oldEntity?.entity === 'label' && newEntityType === 'marker')
      || (oldEntity?.entity === 'label' && isHidden) // remove label object if not visible due to limit on max label instances
      || (oldEntity?.entity === 'label' && oldEntity.object.type !== newLabelType)
    ) {
      if (oldEntity?.entity === 'marker') {
        if (segment === MarkerSegment.SHADOW) {
          this.overlayLayers.MarkersShadows.remove(oldEntity.object);
        }
        else {
          this.overlayLayers.Markers.remove(oldEntity.object);
        }
      }
      else if (oldEntity?.entity === 'label') {
        if (oldEntity?.object.type === 'text') {
          this.overlayLayers.MarkerTextBoxLabels.remove(oldEntity.object);
        } if (oldEntity?.object.type === 'custom') {
          this.overlayLayers.MarkerCustomLabels.remove(oldEntity.object);
        }
      }
    }

    // Do nothing if the entity is not rendered
    if (!shouldRender) {
      return [null, null];
    }

    // Create marker entity if created as marker or changed to marker from label or from non-shadow to shadow
    if (isMarkerStyle(style) && !!style.segments?.[segment] && (oldEntity?.entity !== 'marker')) {
      const newEntity = {
        entity: 'marker',
        object: createWebGLMarker({ data: marker, zIndex: zIndex.base, style, segment, isHidden, color: getColor(marker, style), size: getSize(marker, style) }),
      } as const;

      const registeredListeners = eventListeners.length > 0 && segment === MarkerSegment.MAIN
        ? this.attachExistingEventListeners(id, newEntity, eventListeners, getStackedIds(marker), isStacked(marker))
        : [];
      if (segment === MarkerSegment.SHADOW) {
        this.overlayLayers.MarkersShadows.add(newEntity.object);
      }
      else {
        this.overlayLayers.Markers.add(newEntity.object);
      }

      return [newEntity, registeredListeners];
    }

    // Create label entity if created as label or changed to label from marker or from different label type
    // Only when label is visible, hidden labels shouldn't have map object instance
    else if (
      isLabelStyle(style)
      && (oldEntity?.entity !== 'label' || oldEntity.object.type !== newLabelType)
      && !isHidden
    ) {
      const labelType = style.offsetProps.type === 'custom' ? 'custom' : 'text';
      const newEntity = this.createLabelEntity({ style, latLng: extractLatLng(marker), isHidden,
        zIndex, text: labelText, verticalOffset: 0, horizontalOffset: 0, labelType });

      const registeredListeners = eventListeners.length > 0
        ? this.attachExistingEventListeners(id, newEntity, eventListeners, getStackedIds(marker), isStacked(marker))
        : [];

      if (newEntity.object.type === 'text') {
        this.overlayLayers.MarkerTextBoxLabels.add(newEntity.object);
      }
      else if (newEntity.object.type === 'custom') {
        this.overlayLayers.MarkerCustomLabels.add(newEntity.object);
      }

      return [newEntity, registeredListeners];
    }

    // Update if marker
    else if (oldEntity && isMarkerStyle(style) && oldEntity?.entity === 'marker' && !!style.segments?.[segment]) {
      updateWebGLMarker({ existing: oldEntity.object, data: marker, zIndex: zIndex.base, style, segment, isHidden, color: getColor(marker, style), size: getSize(marker, style) });

      return [oldEntity, null];
    }

    // Update if label and visible
    else if (oldEntity && style && !isMarkerStyle(style) && oldEntity?.entity === 'label' && !isHidden) {
      const labelType = style.offsetProps.type === 'custom' ? 'custom' : 'text';
      this.updateLabelEntity(oldEntity, {
        latLng: extractLatLng(marker), zIndex, style, text: labelText, verticalOffset: 0, horizontalOffset: 0, isHidden, labelType,
      });
      return [oldEntity, null];
    }

    // Do nothing when the entity is removed
    else if (!style || (style && isMarkerStyle(style) && !style.segments?.[segment]) || (newEntityType === 'label' && isHidden)) {
      return [null, null];
    }

    // Log unreachable/unexpected cases
    else {
      logError('mapMarkerManager: no condition used during marker update, style: ', style, ' oldEntity: ', oldEntity);
      return [null, null];
    }
  };

  private createLabelEntity(
    params: CreateLabelConfigParams
  ): WebglMarkerObject & { entity: 'label' } {

    return {
      entity: 'label',
      object: params.labelType === 'text'
        ? this.overlayLayers.MarkerTextBoxLabels.create(
          createLabelWithTextBoxConfig(params))
        : this.overlayLayers.MarkerCustomLabels.create(
          createLabelWithCustomCalloutConfig(params)),
    };
  }

  private updateLabelEntity(
    existing: WebglMarkerObject & { entity: 'label' },
    params: CreateLabelConfigParams
  ) {

    if (existing.object.type === 'text') {
      this.overlayLayers.MarkerTextBoxLabels.update(existing.object, createLabelWithTextBoxConfig(params));
    }
    else if (existing.object.type === 'custom') {
      this.overlayLayers.MarkerCustomLabels.update(existing.object, createLabelWithCustomCalloutConfig(params));
    }
  }

  //#endregion
}

//#region HELPERS

const chooseMarkerStyle = (marker: StackedMarker, markerStyle: MarkerEntityStyle, stackedStyles: StackedMarkerStyles): MarkerEntityStyle => {
  if (isStacked(marker) && hasPieChart(marker)) {
    return stackedStyles.pieChartHolderStyle;
  }
  else if (isStacked(marker)) {
    const groupStyle = marker.groupId ? stackedStyles.perGroup[marker.groupId] : undefined;
    return groupStyle ?? stackedStyles.stackedMarkerStyle;
  }
  else {
    return markerStyle;
  }
};

const updateWebGLMarker = ({ existing, data, zIndex, style, segment, isHidden, color: colorWithOpacity, size }: Readonly<{
  existing: WebglOverlayMarker;
  data: StackedMarker;
  zIndex: number;
  style: MarkerStyle;
  segment: MarkerSegment;
  isHidden: boolean;
  color: WebglColorWithOpacity;
  size: number;
}>) => {
  existing.visible = !isHidden;
  if (isHidden) {
    return;
  }
  const isStandard = isStandardMarkerStyle(style);
  existing.color.set(...colorWithOpacity.color);
  existing.lat = data.lat;
  existing.lng = data.lng;
  existing.size = size;
  existing.template = createDynamicTemplateName(style, style.segments?.[segment]);
  existing.anchor = isStandard ? MarkerAnchorPosition.BOTTOM_CENTER : style.marker.anchor;
  existing.zIndex = zIndex;
  existing.opacity = colorWithOpacity.opacity;
  existing.backgroundOpacity = colorWithOpacity.backgroundOpacity;
  existing.textureOffset.x = isStandard ? calculateMarkerXTextureOffset(style.marker.spritesheetSettings) : 0;
  existing.textureOffset.y = isStandard ? calculateMarkerYTextureOffset(style.marker.spritesheetSettings) : 0;
};

const createWebGLMarker = ({ data, zIndex, style, segment, isHidden, color: colorWithOpacity, size }: Readonly<{
  data: StackedMarker;
  zIndex: number;
  style: MarkerStyle;
  color: WebglColorWithOpacity;
  segment: MarkerSegment;
  isHidden: boolean;
  size: number;
}>) => {
  return new WebGLOverlay.Marker({
    color: colorWithOpacity.color,
    ...extractLatLng(data),
    textureOffset: {
      x: isStandardMarkerStyle(style) ? calculateMarkerXTextureOffset(style.marker.spritesheetSettings) : 0,
      y: isStandardMarkerStyle(style) ? calculateMarkerYTextureOffset(style.marker.spritesheetSettings) : 0,
    },
    size,
    template: createDynamicTemplateName(style, style.segments?.[segment]),
    anchor: isCustomMarkerStyle(style) ? style.marker.anchor : MarkerAnchorPosition.BOTTOM_CENTER,
    zIndex,
    opacity: colorWithOpacity.opacity,
    backgroundOpacity: colorWithOpacity.backgroundOpacity,
    visible: !isHidden,
    interactive: segment === MarkerSegment.MAIN,
  });
};

const calculateMarkerXTextureOffset = (settings: MarkerSpritesheetSettings) =>
  settings.dimensions.width / 2 - settings.anchorOffsets.xOffset;

const calculateMarkerYTextureOffset = (settings: MarkerSpritesheetSettings) =>
  settings.dimensions.height - settings.anchorOffsets.yOffset;

type CreateLabelConfigParams = Readonly<{
  latLng: LatLng;
  zIndex: WithLabelZIndex;
  style: MarkerLabelStyles;
  text: string;
  verticalOffset: number;
  horizontalOffset: number;
  isHidden: boolean;
  labelType: 'text' | 'custom';
}>;

const createLabelWithCalloutConfig = (
  { latLng, zIndex, style, text: labelText, verticalOffset, horizontalOffset, isHidden }: CreateLabelConfigParams
): LabelWithCalloutConfig => {
  const offset = {
    y: (style.offsetProps.type === 'custom'
      ? style.offsetProps.y
      : (style.arrowProps.dimensions.height ?? 0) * -1
    ) + verticalOffset,
    x: (style.offsetProps.type === 'custom' ? style.offsetProps.x : 0) + horizontalOffset,
  };

  const paddings = getLabelPaddingsValues(style.bodyProps);
  const isOneLetter = (labelText ?? '1').toString().length === 1;
  const extraPaddingOnSides = isOneLetter ? 1.5 : 0;
  const stroke = getLabelStroke(style.bodyProps);

  return ({
    label: {
      ...latLng,
      text: {
        fillColor: convertColorToWebGLColor(style.bodyProps.fontColor.selectedColor),
        fontSize: style.bodyProps.fontSize,
        value: labelText?.length ? labelText : '  ', // empty spaces prevent callout from collapsing
        font: markerLabelsFont.name,
        strokeWidth: stroke.strokeWidth,
        strokeColor: stroke.strokeColor,
      },
      padding: {
        t: paddings.top,
        r: paddings.right + extraPaddingOnSides,
        l: paddings.left + extraPaddingOnSides,
        b: paddings.bottom,
      },
      offset,
      autoHideWhenCollide: false,
      interactive: false,
      verticalAnchor: 'bottom',
      horizontalAnchor: 'center',
      visible: !isHidden,
      zIndex: zIndex.labelText,
    },
    callout: {
      ...latLng,
      interactive: true,
      visible: !isHidden,
      fillColor: convertColorToWebGLColor(
        style.bodyProps.backgroundColor.selectedColor,
        getAdjustedWebGLOpacity(style.bodyProps.backgroundColor.opacity ?? 1),
      ),
      borderWidth: style.borderProps.width,
      borderColor: convertColorToWebGLColor(style.borderProps.color.selectedColor),
      borderRadius: 5,
      triangle: true,
      triangleHeight: style.arrowProps.dimensions.height,
      triangleWidth: style.arrowProps.dimensions.width,
      zIndex: zIndex.base,
    },
  });
};

export const createMarkerLayers = () => ({
  MarkersShadows: new WebGLOverlay.MarkerLayer(),
  Markers: new WebGLOverlay.MarkerLayer(),
  PieChartClusters: new WebGLOverlay.PieChartLayer(),
  MarkerLabelsTextBoxBackground: new WebGLOverlay.TextBoxCalloutLayer(),
  MarkerLabelsCustomBackground: new WebGLOverlay.CustomCalloutLayer(),
  MarkerLabelsText: new WebGLOverlay.LabelLayer('MarkerLabelsText', { autoHide: false }),
  MarkerHoverLabelBackground: new WebGLOverlay.TextBoxCalloutLayer(),
  MarkerHoverLabelText: new WebGLOverlay.LabelLayer('MarkerHoverLabelText', { autoHide: false }),
}) as const;

const getRowId = (rowIdContainer: { readonly rowId: string }) => rowIdContainer.rowId;

const createLabelWithTextBoxConfig = (params: CreateLabelConfigParams): LabelWithTextBoxCalloutConfig => {
  const baseConfig = createLabelWithCalloutConfig(params);

  return {
    ...baseConfig,
    callout: baseConfig.callout && {
      ...baseConfig.callout,
      triangleSide: WebglOverlayTextBoxCalloutTriangleSide.bottom,
    },
  };
};

const createLabelWithCustomCalloutConfig = (params: CreateLabelConfigParams): LabelWithCustomCalloutConfig => {
  const baseConfig = createLabelWithCalloutConfig(params);

  return {
    ...baseConfig,
    callout: baseConfig.callout && {
      ...baseConfig.callout,
      placeTriangleEndpoint: true,
    },
  };
};

const updateWebGLLabelAsStackedMarkerNumber = (label: WebglOverlayLabel, data: StackedMarker, style: StandardMarkerStyleWithSize, count: number, zIndex: number, isHidden: boolean) => {
  label.visible = !isHidden;
  if (isHidden) {
    return;
  }

  const contrastTextColor = getColorForStackedMarkerNumber(data, style);

  label.text.value = count.toString();
  label.text.fillColor.set(...convertColorToWebGLColor(contrastTextColor.hex));
  label.text.strokeColor.set(...convertColorToWebGLColor(
    getContrastColor(createColor('#fff'), contrastTextColor).hex, 1)
  );
  label.offset.y = calculateStackedLabelYOffset(style);
  label.offset.x = calculateStackedLabelXOffset(style);
  label.zIndex = zIndex;
  label.lat = data.lat;
  label.lng = data.lng;
};

const createWebGLLabelAsStackedMarkerNumber = (data: StackedMarker, style: StandardMarkerStyleWithSize, count: number, zIndex: number, isHidden: boolean) => {
  const contrastTextColor = getColorForStackedMarkerNumber(data, style);

  return new WebGLOverlay.Label({
    text: {
      value: count.toString(),
      fontSize: 14,
      fillColor: convertColorToWebGLColor(contrastTextColor.hex),
      fillWidth: 0.52,
      strokeColor: convertColorToWebGLColor(getContrastColor(createColor('#fff'), contrastTextColor).hex, 1),
      strokeWidth: 0.75,
      font: mapFont.arialBold.name,
    },
    offset: {
      y: calculateStackedLabelYOffset(style),
      x: calculateStackedLabelXOffset(style),
    },
    verticalAnchor: 'center',
    horizontalAnchor: 'center',
    opacity: 1,
    zIndex,
    ...extractLatLng(data),
    interactive: false,
    visible: !isHidden,
  });
};

const calculateStackedLabelYOffset = (style: StandardMarkerStyleWithSize) => {
  return (-style.marker.spritesheetSettings.dimensions.height + style.marker.counter.top + calculateMarkerYTextureOffset(style.marker.spritesheetSettings)) *
    (style.size / style.marker.spritesheetSettings.dimensions.height);
};

const calculateStackedLabelXOffset = (style: StandardMarkerStyleWithSize) => {
  const ratio = style.size / style.marker.spritesheetSettings.dimensions.width;

  return (style.marker.counter.left - style.marker.spritesheetSettings.dimensions.width / 2) * ratio +
    calculateMarkerXTextureOffset(style.marker.spritesheetSettings) * ratio;
};

const calculatePieChartRadius = (style: StandardMarkerStyleWithSize) =>
  (style.marker.counter.width * (style.size / style.marker.spritesheetSettings.dimensions.width)) / 2;

type PieChartPlacement = { radius: number; offset: { x: number; y: number } };
const getStackedMarkerPieChartPlacement = (style: StandardMarkerStyleWithSize): PieChartPlacement => ({
  radius: calculatePieChartRadius(style),
  offset: {
    x: 0,
    y: calculateStackedLabelYOffset(style) - 1,
  },
});

const getSubMarkerPieChartPlacement = (): PieChartPlacement => ({
  radius: 20,
  offset: { x: 0, y: 0 },
});

const getSubMarkerPieChartStyle = (): {
  borderWidth: number;
  borderColor: WebglColor;
} => ({
  borderWidth: 1,
  borderColor: opaqueBlack,
});

const updateWebGLPieChart = (pieChartEntity: WebglPieChart, { chartItems, latLng, placement, zIndex, isHidden }: Readonly<{
  chartItems: ReadonlyArray<PieChartItem>;
  latLng: LatLng;
  placement: PieChartPlacement;
  zIndex: number;
  isHidden: boolean;
}>) => {
  pieChartEntity.visible = !isHidden;
  if (isHidden) {
    return;
  }
  pieChartEntity.lat = latLng.lat;
  pieChartEntity.lng = latLng.lng;
  pieChartEntity.radius = placement.radius;
  pieChartEntity.offset.x = placement.offset.x;
  pieChartEntity.offset.y = placement.offset.y;
  pieChartEntity.zIndex = zIndex;
  pieChartEntity.chart = chartItems;
};

const createWebGLPieChart = ({ chartItems, latLng, placement, zIndex, isHidden, borderWidth, borderColor }: Readonly<{
  chartItems: ReadonlyArray<MarkerPieChartItem>;
  latLng: LatLng;
  placement: PieChartPlacement;
  zIndex: number;
  isHidden: boolean;
  borderWidth?: number;
  borderColor?: WebglColor;
}>) => {
  return new WebGLOverlay.PieChart({
    ...extractLatLng(latLng),
    ...placement,
    zIndex,
    border: true,
    borderWidth: borderWidth ?? 2,
    borderColor: borderColor ?? opaqueBlack,
    interactive: false,
    staticSize: true,
    visible: !isHidden,
  }, chartItems);
};

const getOffsetsForLabelAbove = (marker: MarkerEntityStyle, labelAboveStyle: MarkerLabelStyles) => {
  const offsets = calculateOffsetsForMarkerEntity(marker);
  const addArrow = (offset: number) => offset * -1 - (labelAboveStyle.arrowProps.dimensions.height ?? 0);
  return {
    bottom: Math.ceil(addArrow(offsets.topOffset)),
    left: offsets.leftOffset,
  };
};

const createHighlight = (latLng: LatLng, zIndex: number) =>
  new WebGLOverlay.Marker({
    ...extractLatLng(latLng),
    zIndex,
    size: HIGHLIGHT_MARKER_SIZE,
    template: PredefinedTemplate.RadarMarker,
  });

const createGlow = (latLng: LatLng, zIndex: number) =>
  new WebGLOverlay.Marker({
    ...extractLatLng(latLng),
    zIndex,
    size: GLOW_MARKER_SIZE,
    template: PredefinedTemplate.RadarMarkerGlow,
  });

const updateHighlight = (existing: WebglOverlayMarker, latLng: LatLng, zIndex: number) => {
  existing.zIndex = zIndex;
  existing.lat = latLng.lat;
  existing.lng = latLng.lng;
};

const updateGlow = (existing: WebglOverlayMarker, latLng: LatLng, zIndex: number) => {
  existing.zIndex = zIndex;
  existing.lat = latLng.lat;
  existing.lng = latLng.lng;
};

const callEventListenerForStack = (
  listener: MapMarkerEventCallback, marker: StackedMarker, getMapObjects: () => MarkerWebglMapObjects | null
) =>
  listener(getStackedIds(marker), isStacked(marker), getMapObjects);

const getStackedIds = (marker: StackedMarker) =>
  marker.stackedMarkers?.map(m => m) ?? [marker];

type MarkerWebGlEntity = WebglOverlayMarker | WebglOverlayLabelWithTextBoxCallout | WebglOverlayLabel | WebglPieChart;
type Create<TEntity extends MarkerWebGlEntity, TParams extends ReadonlyArray<unknown>> = (...params: TParams) => TEntity;
type UpdateMarkerOptionalEntityArgs<TEntity extends MarkerWebGlEntity, TParams extends ReadonlyArray<unknown>> = {
  entity: TEntity | null;
  shouldRender: boolean;
  functions: Readonly<{
    create: Create<TEntity, TParams>;
    update: (existing: TEntity, ...params: Parameters<Create<TEntity, TParams>>) => void;
  }>;
  params: Parameters<Create<TEntity, TParams>>;
  layer: Readonly<{ add: (e: TEntity) => void; remove: (e: TEntity) => void }>;
};
const updateMarkerOptionalEntity = <TEntity extends MarkerWebGlEntity, TParams extends ReadonlyArray<unknown>>(args: UpdateMarkerOptionalEntityArgs<TEntity, TParams>): TEntity | null => {
  const { entity, shouldRender, params, layer, functions: { create, update } } = args;

  if (entity && !shouldRender) {
    layer.remove(entity);
    return null;
  }
  if (!entity && shouldRender) {
    const newEntity = create(...params as unknown as TParams);
    layer.add(newEntity);
    return newEntity;
  }
  if (entity && shouldRender) {
    update(entity, ...params);
    return entity;
  }
  return entity;
};

const isStacked = (marker: StackedMarker): marker is StackedMarker & Required<Pick<StackedMarker, 'stackedMarkers'>> =>
  Array.isArray(marker.stackedMarkers) && marker.stackedMarkers.length > 1;

const hasPieChart = (marker: StackedMarker): marker is StackedMarker & { pieChartItems: NonEmptyArray<MarkerPieChartItem> } =>
  Array.isArray(marker.pieChartItems) && notEmpty(marker.pieChartItems);

const hasSecondaryPieChart = (marker: StackedMarker): marker is StackedMarker & Required<Pick<StackedMarker, 'secondaryPieChartItems'>> =>
  Array.isArray(marker.secondaryPieChartItems) && marker.secondaryPieChartItems.length > 0;

const getOpacityForMarker = (
  opacity: number | undefined, { isStandardMarker }: { isStandardMarker: boolean },
): { backgroundOpacity: number; opacity: number } => {
  opacity = getAdjustedWebGLOpacity(opacity ?? 1);
  if (isStandardMarker) {
    return { backgroundOpacity: opacity, opacity: 1 };
  }
  return { backgroundOpacity: 1, opacity: 1 };
};

/* Currently this function exists because in WebGL opacity works differently
 * Mainly it renders things as transparent below some value, in the future we can get rid of it if we find a way around in WebGL
 */
const getAdjustedWebGLOpacity = (opacity: number): number => {
  if (opacity < 0.1) {
    return 0;
  }
  return MARKER_ALPHA_TEST + opacity * (1 - MARKER_ALPHA_TEST);
};

type WebglColorWithOpacity = { color: WebglColor; opacity: number; backgroundOpacity?: number };
const getColorForMarker = (data: StackedMarker, style: MarkerStyle): WebglColorWithOpacity => {
  const isStandardMarker = isStandardMarkerStyle(style);

  if (isStacked(data)) {
    if (hasPieChart(data)) {
      return { color: opaqueBlack, ...getOpacityForMarker(1, { isStandardMarker }) };
    }
    else if (data.mainColor) {
      return { color: data.mainColor, ...getOpacityForMarker(data.opacity, { isStandardMarker }) };
    }
  }
  return {
    color: style.selectedColor ? convertColorToWebGLColor(style.selectedColor) : MarkerColor.Green,
    ...getOpacityForMarker(style.opacity, { isStandardMarker }),
  };
};

const getColorForStackedMarkerNumber = (data: StackedMarker, style: StandardMarkerStyleWithSize): ColorResult => {
  if (isStacked(data) && hasPieChart(data)) {
    return getContrastColor(
      createColor(style.marker.counter.color),
      createColor(webGLColorToString(data.pieChartItems[0].color)),
      { tolerance: 125 }
    );
  }

  return getContrastColor(
    createColor(style.marker.counter.color),
    createColor(webGLColorToString(getColorForMarker(data, style).color)),
    { tolerance: 125 }
  );
};

const getColorForSubMarker = (data: StackedMarker, style: MarkerStyle): WebglColorWithOpacity => {
  return isStacked(data) && data.subMarkerColor
    ? {
      color: data.subMarkerColor,
      ...getOpacityForMarker(data.subMarkerOpacity, { isStandardMarker: true }),
    }
    : {
      color: style.selectedColor ? convertColorToWebGLColor(style.selectedColor) : MarkerColor.Green,
      ...getOpacityForMarker(style.opacity, { isStandardMarker: true }),
    };
};

const getSizeForMarker = (data: StackedMarker, style: MarkerStyle): number => style.size;
const getSizeForSubMarker = (data: StackedMarker, style: MarkerStyle): number => data.subMarkerSize ?? style.size;

export const calculateMarkerLabelBodyHeight = (label: LabelVisualSetting) => {
  const numOfLines = 1;
  const sizeCoef = label.bodyProps.fontSize / markerLabelsFont.base;
  const lineHeight = sizeCoef * (numOfLines * markerLabelsFont.lineHeight + markerLabelsFont.padding);

  const paddingValues = getLabelPaddingsValues(label.bodyProps);
  const labelPadding = (paddingValues.bottom ?? 0) + (paddingValues.top ?? 0);

  return lineHeight + label.borderProps.width * 2 + labelPadding;
};

//#endregion
