import {
  type FC, useCallback, useState,
} from 'react';
import { type RouteProps } from 'react-router-dom';
import { getIsPageRefreshed } from '~/_shared/utils/url/url.helpers';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { LoginPageComponent } from './loginPage.component';

type LoginPageContainer = RouteProps;

export const LoginPageContainer: FC<LoginPageContainer> = (props) => {
  const userData = useUserDataSelector();
  const isPageRefreshed = getIsPageRefreshed();

  const [loginFormSubmitted, setLoginFormSubmitted] = useState<boolean>(false);

  const onLoginFormSubmit = useCallback(() => {
    setLoginFormSubmitted(true);
  }, []);

  return (
    <LoginPageComponent
      {...props}
      isLoading={isPageRefreshed && userData.isLoading && !loginFormSubmitted}
      isUserSignedIn={userData.isSignedIn}
      onLoginFormSubmit={onLoginFormSubmit}
    />
  );
};
