import { faBars } from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type MainMenuItem,
  MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import {
  containerStyle,
  headingSectionStyle,
  primarySectionStyle,
  rightIconStyle,
  rightSectionStyle,
  UNDERBAR_HEADER_HEIGHT,
  underbarStyle,
} from '~/mobileTopBar/mobileTopBar.styles';
import { useSlidingAnimationDuration } from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { HeadingComponent } from '~/topBar/heading/heading.component';
import { type NavigationItem } from '~/topBar/navigation/navigation.helpers';
import { MobileNavigationContainer } from './mobileNavigation/mobileNavigation.container';
import { MobileTopUnderbarContainer } from './mobileTopUnderbar/mobileTopUnderbar.container';

export const mobileMapToolsAsMenuItems = new Set<MainMenuItem>([
  MainMenuItems.Grouping,
  MainMenuItems.Filter,
  MainMenuItems.Routing,
  MainMenuItems.Proximity,
]);

export const mobileRightSideButtonsMenuItems = new Set<MainMenuItem>([
  MainMenuItems.Location,
  MainMenuItems.Search,
  MainMenuItems.NearMe,
]);

type AppBarProps = Readonly<{
  accountName?: string;
  activeNavigationItem: NavigationItem;
  hasAvailableTools: boolean;
  isMapPresentational: boolean;
  mapLink?: string;
  mapName?: string;
  mapViewName?: string;
  topUnderbarOpen: boolean;
  publicMapSettings?: MapSettingsPublicMapSettingsState;

  hideSearchBar: () => void;
  toggleTopBar: (isOpen: boolean) => void;
}>;

export const MobileTopBarComponent: FC<AppBarProps> = ({ toggleTopBar, ...props }) => {
  const theme = useTheme();

  const slidingAnimationDuration = useSlidingAnimationDuration();
  const animationConfig = useMemo(() => ({ duration: slidingAnimationDuration, clamp: true }), [slidingAnimationDuration]);
  const closedUnderbarStyle = useMemo(() => ({ height: 0, config: animationConfig, overflow: 'hidden' }), [animationConfig]);
  const openedUnderbarStyle = useMemo(() => ({ height: UNDERBAR_HEADER_HEIGHT, config: animationConfig, overflow: 'visible' }), [animationConfig]);
  const [animatedUnderbarStyle, animatedUnderbarApi] = useSpring(() => closedUnderbarStyle);

  const toggleBottom = useCallback(() => {
    const hideSearchBar = props.hideSearchBar;
    if (props.topUnderbarOpen) {
      hideSearchBar();
    }
    toggleTopBar(!props.topUnderbarOpen);
  }, [props.hideSearchBar, props.topUnderbarOpen, toggleTopBar]);

  useEffect(() => {
    animatedUnderbarApi.start(props.topUnderbarOpen ? openedUnderbarStyle : closedUnderbarStyle);
  }, [closedUnderbarStyle, props.topUnderbarOpen, openedUnderbarStyle, animatedUnderbarApi]);

  return (
    <div>
      <div css={containerStyle({ theme })}>
        <div css={[primarySectionStyle, { flexShrink: 0 }]} >
          {!props.isMapPresentational && (<MobileNavigationContainer />)}
        </div>

        <div css={[headingSectionStyle, { flexShrink: 1 }]} >
          {(!props.publicMapSettings || props.publicMapSettings.displayTitleAndDescription) && (
            <HeadingComponent
              accountName={props.mapName ? '' : props.accountName}
              mapName={props.mapName}
              mapLink={props.mapLink}
              mapViewName={props.mapViewName}
            />
          )}
        </div>

        <div css={[rightSectionStyle, { flexShrink: 0 }]} >
          {(props.hasAvailableTools && props.isMapPresentational && !props.publicMapSettings?.mapToolsMenuOpen
              || props.activeNavigationItem.path !== HOME_ROUTE) &&
            (
              <div onClick={toggleBottom}>
                <FontAwesomeIcon
                  icon={faBars}
                  css={rightIconStyle}
                />
              </div>
            )}
        </div>
      </div>

      <animated.div
        css={underbarStyle({ theme, isVisible: props.topUnderbarOpen })}
        style={animatedUnderbarStyle}
      >
        <MobileTopUnderbarContainer />
      </animated.div>
    </div>
  );
};
