import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { ButtonSize } from '~/_shared/baseComponents/buttons';
import {
  ButtonComponent, type ButtonProps,
} from '~/_shared/baseComponents/buttons/button/button.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import {
  ScrollBarComponent, ScrollbarType,
} from '../scrollbar/scrollbar.component';

const MIN_WIDTH_TO_COLALPSE_BUTTONS = 800;
const COLLAPSED_BUTTONS_WIDTH = 31;

const rootStyle = css({
  transform: 'translateX(0)',
});

const containerStyle = css({
  cursor: 'default',
  display: 'flex',
  padding: 10,
  position: 'relative',
});

const contentStyle = css({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
});

const buttonWrapperStyle = ({ isCollapsed }: { isCollapsed?: boolean }) => css({
  flexShrink: 0,
  marginLeft: 4,
  width: isCollapsed ? COLLAPSED_BUTTONS_WIDTH : undefined,
});

const buttonsWrapperStyle = css({
  position: 'sticky',
  top: 10,
  right: 10,
  display: 'flex',
  flexDirection: 'column',
});

const scrollbarContentStyle = {
  display: 'block',
  minWidth: 'none',
};

type ScrollAndStickyHorizontalSplitComponentProps = Readonly<{
  maximumHeight?: number;
  children?: ReactNode;
  buttons: (Omit<ButtonProps, 'text'> & { label: string })[];
}>;

export const ScrollAndStickyHorizontalSplitComponent: FC<ScrollAndStickyHorizontalSplitComponentProps> = (props) => {
  const { ref, width } = useElementDimensions();

  const collapseButtons = width !== undefined && width <= MIN_WIDTH_TO_COLALPSE_BUTTONS;

  return (
    <div
      ref={ref}
      css={rootStyle}
    >
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        contentStyle={scrollbarContentStyle}
        translateContentHeightToHolder
        maxHeight={props.maximumHeight || 100}
      >
        <div css={containerStyle}>
          <div css={contentStyle}>
            {props.children}
          </div>
          <div css={buttonWrapperStyle({ isCollapsed: collapseButtons })}>
            <div css={buttonsWrapperStyle}>
              {props.buttons.map((button, index) => (
                <div key={index}>
                  <TooltipDeprComponent tooltipContent={collapseButtons ? button.label : ''}>
                    <ButtonComponent
                      {...button}
                      prefixIconStyle={css(collapseButtons ? { marginRight: 0 } : {})}
                      size={ButtonSize.Small}
                      text={collapseButtons ? '' : button.label}
                    />
                  </TooltipDeprComponent>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ScrollBarComponent>
    </div>
  );
};
