import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.DotSpinner]: {
  views: {
    default: {
      canvasHeight: 650,
      canvasWidth: 650,
      iconWidth: 600,
      iconHeight: 565,
      offsetTop: 55,
    },
  },
  colors: {
    colorA: '#328EF5',
    colorB: '#3DC8FF',
  },
  gradients: {},
  frameRate: 29.9700012207031,
  segments: {
    default: [0, 45] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/9965-loading-spinner" */
      './9965-loading-spinner.json'
    ),
} };

export const { DotSpinner } = config;
