import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { ModalSectionComponent } from '../../_shared/components/modal/modalSection.component';
import {
  createRouteCollapsingLevelDescription,
  createRouteCollapsingLevelLabel,
  RouteCollapsingLevel,
} from '../../_shared/constants/routeCollapsingLevel.enum';
import {
  type TranslationFnc, useTranslation,
} from '../../_shared/utils/hooks';
import { useDebounce } from '../../_shared/utils/hooks/useDebounce';
import { ToggleWithDescriptionComponent } from './toggleWithDescription.component';

export type onChangeParams = Readonly<{
  collapsingLevel: RouteCollapsingLevel;
  canDragRoutes: boolean;
  shouldBeginAtUserLocation: boolean;
}>;

type MapDirectionsModalProps = Readonly<{
  onChange: (params: onChangeParams) => void;
  isOpen: boolean;
  onClose: () => void;
  selectedCollapsing: RouteCollapsingLevel;
  canDragRoutes: boolean;
  shouldBeginAtUserLocation: boolean;
}>;

const createRouteCollapsingItems = (t: TranslationFnc) => Object.values(RouteCollapsingLevel)
  .map(l => ({
    value: l,
    label: createRouteCollapsingLevelLabel(t, l),
    description: createRouteCollapsingLevelDescription(t, l),
  }));

const modalContentStyle = css({
  padding: 0,
});

const radioGroupStyle = css({
  gap: 20,
});

export const DirectionsSettingsComponent: FC<MapDirectionsModalProps> = (props) => {
  const [selectedCollapsing, setSelectedCollapsing] = useState<RouteCollapsingLevel>(props.selectedCollapsing);
  const [canDragRoutes, setCanDragRoutes] = useState<boolean>(props.canDragRoutes);
  const [shouldBeginAtUserLocation, setShouldBeginAtUserLocation] = useState<boolean>(props.shouldBeginAtUserLocation);
  const onChangeDebounced = useDebounce(props.onChange, 1000);
  const [t] = useTranslation();

  useEffect(() => {
    if (
      props.shouldBeginAtUserLocation !== shouldBeginAtUserLocation ||
      props.canDragRoutes !== canDragRoutes ||
      props.selectedCollapsing !== selectedCollapsing
    ) {
      onChangeDebounced({
        shouldBeginAtUserLocation,
        canDragRoutes,
        collapsingLevel: selectedCollapsing,
      });
    }
  }, [shouldBeginAtUserLocation, canDragRoutes, selectedCollapsing, props.shouldBeginAtUserLocation,
    props.canDragRoutes, props.selectedCollapsing, onChangeDebounced]);

  useEffect(() => {
    return () => {
      onChangeDebounced.now();
    };
  }, [onChangeDebounced]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Map Directions / Routing Menu Default Settings')}
      contentStyle={modalContentStyle}
    >
      <ModalSectionComponent title={t('SELECT DEFAULT VIEW')}>
        <RadioGroupComponent
          css={radioGroupStyle}
          selectedValue={selectedCollapsing}
          onValueChange={newValue => {
            const newCollapsing = Object.values(RouteCollapsingLevel).find(l => l === newValue) ?? selectedCollapsing;
            setSelectedCollapsing(newCollapsing);
          }}
          items={createRouteCollapsingItems(t)}
        />

        <ToggleWithDescriptionComponent
          isToggled={canDragRoutes}
          onToggled={() => {
            const newDragRoutes = !canDragRoutes;
            setCanDragRoutes(newDragRoutes);
          }}
          title={t('ENABLE ROUTE DRAGGING')}
          description={t('mapDirectionsModalRouteDraggingDescription')}
        />

        <ToggleWithDescriptionComponent
          isToggled={shouldBeginAtUserLocation}
          onToggled={() => {
            const newShouldBeginAtUserLocation = !shouldBeginAtUserLocation;
            setShouldBeginAtUserLocation(newShouldBeginAtUserLocation);
          }}
          title={t('DIRECTIONS BEGIN AT YOUR LOCATION')}
          description={t('mapDirectionsModalBeginAtUserLocationDescription')}
        />
      </ModalSectionComponent>
    </ModalComponent>
  );
};
