import { css } from '@emotion/react';
import {
  faChevronRight, faFillDrip,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { SIDEBAR_WIDTH } from '../../../../sidebar.component';
import { getAvailableItemSpacesWithMinimumWidths } from './activeBoundaryFillInfo.helpers';

const WRAPPER_PADDING = 14;
const IN_BETWEEN_ITEMS_GAP = 6;
const AVAILABLE_WRAPPER_WIDTH = SIDEBAR_WIDTH - 2 * WRAPPER_PADDING - IN_BETWEEN_ITEMS_GAP;

const tooltipStyle = css({
  width: '100%',
});

const fillInfoWrapperStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.highlight,
  border: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  cursor: 'default',
  display: 'flex',
  fontSize: 12,
  gap: IN_BETWEEN_ITEMS_GAP,
  height: 22,
  padding: `0 ${WRAPPER_PADDING}px`,
  whiteSpace: 'nowrap',
});

const elementStyle = ({ maxWidth }: {maxWidth?: number}) => css({
  alignItems: 'center',
  display: 'flex',
  maxWidth,
});

const ellipsisStyle = css({
  textOverflow: 'ellipsis',
  flex: '1 0 0',
  overflow: 'hidden',
});

const iconStyle = css({
  marginRight: 6,
});

export type ActiveBoundaryFillInfoEntities = Readonly<{
  mainEntity: string;
  subEntity?: string;
}>;

const MIN_MAIN_WIDTH = 58;

type ActiveBoundaryFillInfoComponentProps = Readonly<{
  fillEntities: ActiveBoundaryFillInfoEntities;
}>;

export const ActiveBoundaryFillInfoComponent: FC<ActiveBoundaryFillInfoComponentProps> = props => {
  const theme = useTheme();
  const [itemsOriginalWidth, setItemsOriginalWidth] = useState<number[]>([]);
  const mainEntityRef = useRef<HTMLSpanElement>(null);
  const subEntityRef = useRef<HTMLSpanElement>(null);
  const [didMeasureFullText, setDidMeasureFullText] = useState(true);

  const [mainEntityAvailableWidth, subEntityAvailableWidth] = useMemo(() =>
    getAvailableItemSpacesWithMinimumWidths(AVAILABLE_WRAPPER_WIDTH, itemsOriginalWidth, MIN_MAIN_WIDTH),
  [itemsOriginalWidth]);

  const { shortenedMain, shortenedSub } = useMemo(() => ({
    shortenedMain: props.fillEntities.mainEntity === 'Citizenship Status' ? 'Status' : props.fillEntities.mainEntity,
    shortenedSub: props.fillEntities.subEntity && props.fillEntities.subEntity.replace('Percentage', '%'),
  }), [props.fillEntities.mainEntity, props.fillEntities.subEntity]);

  useEffect(() => {
    setDidMeasureFullText(false);

    return () => {
      setItemsOriginalWidth([]);
    };
  }, [props.fillEntities.mainEntity, props.fillEntities.subEntity]);

  useLayoutEffect(() => {
    if (!didMeasureFullText && (mainEntityRef.current || subEntityRef.current)) {
      setDidMeasureFullText(true);

      const mainEntityWidth = mainEntityRef.current?.getBoundingClientRect().width || 0;
      const subEntityWidth = subEntityRef.current?.getBoundingClientRect().width || 0;
      setItemsOriginalWidth([mainEntityWidth, subEntityWidth]);
    }
  }, [didMeasureFullText]);

  return (
    <TooltipDeprComponent
      css={tooltipStyle}
      delayShow={500}
      tooltipContent={`${props.fillEntities.mainEntity} - ${props.fillEntities.subEntity}`}
    >
      <div css={fillInfoWrapperStyle({ theme })}>

        <span
          css={elementStyle({ maxWidth: didMeasureFullText ? mainEntityAvailableWidth || undefined : undefined })}
          ref={mainEntityRef}
        >
          <span css={ellipsisStyle}>
            <FontAwesomeIcon
              css={iconStyle}
              icon={faFillDrip}
            />
            {shortenedMain}
          </span>
        </span>

        {props.fillEntities.subEntity && (
          <span
            css={elementStyle({ maxWidth: subEntityAvailableWidth || undefined })}
            ref={subEntityRef}
          >
            <span css={ellipsisStyle}>
              <FontAwesomeIcon
                css={iconStyle}
                icon={faChevronRight}
              />
              {shortenedSub}
            </span>
          </span>
        )}
      </div>
    </TooltipDeprComponent>
  );
};
