import { useMemo } from 'react';
import {
  type MapPrivacyLevel, type VersionDescriptor,
} from '~/_shared/types/map';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { assertNever } from '~/_shared/utils/typeGuards';
import {
  type BaseMapInfos, getLayeredMapBaseMaps,
} from '~/map/layered/layering.helpers';
import { type AppState } from '../app.store';
import { type LinkedBaseMap } from '../mapSettings/layeredMap/baseMapsUpdate/getBaseMapsRequiringUpdate';

export const mapInfoSpreadsheetsSelector = (state: AppState) => state.map.mapInfo.data?.spreadsheets;
export const useMapInfoSpreadsheetsSelector = () => useSelector(mapInfoSpreadsheetsSelector);

export const mapInfoIsLoadingSelector = (state: AppState) => state.map.mapInfo.isLoading;
export const useMapInfoIsLoadingSelector = () => useSelector(mapInfoIsLoadingSelector);

export const mapInfoDataSelector = (state: AppState) => state.map.mapInfo.data;
export const useMapInfoDataSelector = () => useSelector(mapInfoDataSelector);

export const mapInfoSnapshotsSelector = (state: AppState) => mapInfoDataSelector(state)?.snapshots;
export const useMapInfoSnapshotsSelector = () => useSelector(mapInfoSnapshotsSelector);

export const sharedMapIdSelector = (state: AppState) => state.map.mapInfo.data?.shareId;
export const useSharedMapIdSelector = () => useSelector(sharedMapIdSelector);

export enum TargetLayeredState {
  Any = 'Any',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

const mapInfoIsMapLayeredSelector = (state: AppState) => !!state.map.mapInfo.data?.isLayered && !!state.map.mapInfo.data?.layering;
const mapInfoIsMapLayeredConnectedSelector = (state: AppState) => !!state.map.mapInfo.data?.layering?.connected;

export const isMapLayeredSelector = createStateSelector([
  mapInfoIsMapLayeredSelector,
  mapInfoIsMapLayeredConnectedSelector,
  (_state, targetState: TargetLayeredState) => targetState,
], (isLayered, isConnected, targetState) => {
  switch (targetState) {
    case TargetLayeredState.Any:
      return isLayered;
    case TargetLayeredState.Connected:
      return isLayered && isConnected;
    case TargetLayeredState.Disconnected:
      return isLayered && !isConnected;
    default:
      assertNever(targetState);
      return false;
  }
});

export const useIsMapLayeredSelector = (targetState: TargetLayeredState) => useSelector((state: AppState) => isMapLayeredSelector(state, targetState));

const isConnectedLayeredMapSelector = (state: AppState) => isMapLayeredSelector(state, TargetLayeredState.Connected);
export const useIsConnectedLayeredMapSelector = () => useSelector(isConnectedLayeredMapSelector);

export const getLinkedBaseMapsSelector = (state: AppState) => {
  const mapInfoData = state.map.mapInfo.data;
  if (!mapInfoData) {
    return [];
  }

  const spreadsheetVersions = mapInfoData.versions;
  if (!spreadsheetVersions) {
    return [];
  }
  const updatedMaps = spreadsheetVersions
    .map((spreadsheet): LinkedBaseMap => ({
      id: spreadsheet.realSpreadSheetId,
      name: spreadsheet.name,
      wasUpdated: !spreadsheetVersionsMatch(spreadsheet.versions.current, spreadsheet.versions.latest),
      latestSnapshot: spreadsheet.versions.latest.snapshot,
      latestVersion: spreadsheet.versions.latest.version,
      currentVersion: spreadsheet.versions.current.version,
    }));

  return updatedMaps;
};

export const useMapInfoPrivacySelector = () => {
  const mapInfo = useMapInfoDataSelector();

  return useMemo(() => (mapInfo ? {
    mapPrivacyLevel: mapInfo.privacy,
    mapId: mapInfo.id,
    parentMapId: mapInfo.parentMap?.id,
    parentMapPrivacy: mapInfo.parentMap?.privacy as MapPrivacyLevel,
    mapSource: mapInfo.source,
  } : undefined), [mapInfo]);
};

export const useBaseMapNames = () => {
  const isLayered = useIsMapLayeredSelector(TargetLayeredState.Any);
  const layering = useMapInfoDataSelector()?.layering;

  return useMemo<Readonly<BaseMapInfos>>(() => {
    if (isLayered) {
      return getLayeredMapBaseMaps(layering);
    }
    return {};
  }, [isLayered, layering]);
};

const spreadsheetVersionsMatch = (ver1: VersionDescriptor, ver2: VersionDescriptor) => {
  return ver1.version === ver2.version && ver1.snapshot === ver2.snapshot;
};
