import * as geometry from 'spherical-geometry-js';
import { type DeepWritable } from 'ts-essentials';
import { SearchMatchingBehaviour } from '~/_shared/constants/searchMatchingBehaviour.enum';
import { type FilterTree } from '~/_shared/types/filterTree.types';
import { type LatLng } from '~/_shared/types/latLng';
import {
  newPerSpreadsheetMap, type PerSpreadsheetFilteredRowsIdsMap, type ReadonlyPerSpreadsheetFilteredRowsIdsMap,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import { type SpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  cloneDeep, mergeDeep,
} from '~/_shared/utils/object/deepMerge';
import { hasNotNull } from '~/_shared/utils/typeGuards';
import { type BoundaryFilterRequest } from '~/spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '~/spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  type SpreadsheetColumnsToFetchAttributeResponse, type SpreadsheetColumnsToFetchDateResponse,
  type SpreadsheetColumnsToFetchGroupResponse, type SpreadsheetColumnsToFetchNumberResponse,
  type SpreadsheetColumnsToFetchResponse, type SpreadsheetColumnsToFetchTextResponse,
  type SpreadsheetDataBulkFetchExtra, type SpreadsheetDataBulkRequestDescriptor, type SpreadsheetDataBulkRequestGetter,
  type SpreadsheetDataBulkResponse, type SpreadsheetDataColumnsToFetch,
} from '~/spreadsheet/spreadsheet.repository';
import { type BoundaryLocationsFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { type MapSettingsColumnsFilterState } from '../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { type MapSettingsFilteringState } from '../mapSettings/filtering/mapSettingsFiltering.state';
import { type MapSettingsGroupingState } from '../mapSettings/grouping/mapSettingsGrouping.state';
import { type MapSettingsState } from '../mapSettings/mapSettings.state';
import { type MapSettingsSearchItemsState } from '../mapSettings/searchItems/mapSettingsSearchItems.state';
import { type MatchupDataState } from '../matchupData/matchupData.state';
import {
  getDataRowsForBoundaryLocationsFilter, getMissingBoudnaryLocationsFilterSpreadsheetData,
} from './boundaries/spreadsheetData.boundaries.helpers';
import {
  getDataRowsForActiveFilters, getMissingFilteredSpreadsheetData, getMissingFilteringSpreadsheetData,
} from './filtering/spreadsheetDataFiltering.helpers';
import {
  getMissingGroupingSpreadsheetData, getRowIdsHashMapForActiveGroupingColumnsAndFilters,
} from './grouping/spreadsheetData.grouping.helpers';
import { getMissingHeatmapsSpreadsheetData } from './heatmaps/spreadsheetData.heatmaps.helpers';
import { getMissingMarkerInfoSpreadsheetData } from './markerLabels/spreadsheetData.markerLabels.helpers';
import { getMissingProximitySpreadsheetData } from './proximity/spreadsheetData.proximity.helpers';
import {
  getDataRowsForSearch, getMissingSearchSpreadsheetData,
} from './search/spreadsheetData.search.helpers';
import {
  createSpreadsheetDataItemAttributeFromResponse, createSpreadsheetDataItemDateFromResponse,
  createSpreadsheetDataItemGroupFromResponse, createSpreadsheetDataItemNumberFromResponse,
  createSpreadsheetDataItemTextFromResponse,
} from './spreadsheetData.factory';
import {
  DataType, type SpreadsheetDataData, type SpreadsheetDataItem, type SpreadsheetDataItemAttribute,
  type SpreadsheetDataItemDate, type SpreadsheetDataItemGroup, type SpreadsheetDataItemNumber,
  type SpreadsheetDataItemText, type SpreadsheetFilters, Unfiltered,
} from './spreadsheetData.state';

export type MissingSpreadsheetData = {
  filterDetails?: {
    [spreadsheetId: number]: {
      [filterHash: string]: FilterTree;
    };
  };
  search?: {
    [spreadsheetId: number]: {
      [filterHash: string]: FilterTree;
    };
  };
  boundaryFilter?: {
    [spreadsheetId: number]: {
      [filterHash: string]: {
        boundaryFilter?: BoundaryFilterRequest;
        boundaryTerritoryFilter?: BoundaryTerritoryFilterRequest;
      };
    };
  };
  data: {
    [spreadsheetId: number]: {
      [filterHashOrUnfiltered: string]: {
        [dataType in DataType]?: {
          [columnId: string]: SpreadsheetDataBulkFetchExtra;
        }
      };
    };
  };
};

export const getMissingSpreadsheetDataToFetch = (
  mainMapSpreadsheetsIds: number[],
  mapSettings: MapSettingsState,
  spreadsheetState: SpreadsheetDataData,
  spreadsheetFilters: SpreadsheetFilters,
  matchupDataState: MatchupDataState,
  isLayered: boolean,
): MissingSpreadsheetData => {
  let missingSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  missingSpreadsheetData = mergeDeep(
    missingSpreadsheetData,
    getMissingGroupingSpreadsheetData(mapSettings, spreadsheetState, isLayered, mainMapSpreadsheetsIds),
    getMissingProximitySpreadsheetData(mapSettings, spreadsheetState),
    getMissingFilteringSpreadsheetData(mapSettings, spreadsheetState), // this requests the values in the columns on which filters are applied
    getMissingHeatmapsSpreadsheetData(mapSettings, spreadsheetState),
    getMissingFilteredSpreadsheetData(mapSettings, spreadsheetFilters), // this requests the list of rows which satisfy each filter that is applied
    getMissingSearchSpreadsheetData(mainMapSpreadsheetsIds, mapSettings, spreadsheetFilters),
    getMissingBoudnaryLocationsFilterSpreadsheetData(mainMapSpreadsheetsIds, mapSettings, spreadsheetFilters),
    getMissingMarkerInfoSpreadsheetData(mapSettings, spreadsheetState, matchupDataState),
  );

  for (const spreadsheetId of mainMapSpreadsheetsIds) {
    if (!spreadsheetState.values[spreadsheetId] && !missingSpreadsheetData.data[spreadsheetId]) {
      missingSpreadsheetData.data[spreadsheetId] = { [Unfiltered]: {} };
    }
  }
  return missingSpreadsheetData;
};

export const addMissingSpreadsheetData = (current: MissingSpreadsheetData, added: MissingSpreadsheetDataToAdd) => {
  if (!current.data[added.spreadsheetId]) {
    current.data[added.spreadsheetId] = {};
  }

  const spreadsheetData = current.data[added.spreadsheetId];
  if (spreadsheetData && !spreadsheetData[added.filterHashOrUnfiltered]) {
    spreadsheetData[added.filterHashOrUnfiltered] = {};
  }

  const addedData = spreadsheetData?.[added.filterHashOrUnfiltered];
  if (addedData && !addedData[added.dataType]) {
    addedData[added.dataType] = {};
  }

  const dataTypeTreePart = addedData?.[added.dataType];
  if (dataTypeTreePart && !dataTypeTreePart[added.columnId]) {
    dataTypeTreePart[added.columnId] = {};
  }
};

export const missingColumnsToSpreadsheetBulkGetters = (
  mapId: number,
  missingSpreadsheetData: MissingSpreadsheetData,
  request: Partial<SpreadsheetDataBulkRequestGetter> = {}
): readonly SpreadsheetDataBulkRequestDescriptor[] => {
  return Object.keys(missingSpreadsheetData.data).map((spreadsheetIdString) => {
    const spreadsheetId = +spreadsheetIdString;
    const dataForSpreadsheet = missingSpreadsheetData.data[spreadsheetId]?.[Unfiltered];
    // TODO: in the future we will need to loop through filters here, not just take 'unfiltered'
    return {
      requestType: 'basicData',
      requestGetter: {
        map_id: mapId,
        spreadsheet_id: spreadsheetId,
        columns_to_fetch: Object.entries(dataForSpreadsheet || {}).length > 0 ? dataForSpreadsheet : undefined,
        ...request,
      },
    };
  });
};

export const missingFilteredDataBulkGetters = (mapId: number, missingSpreadsheetData: MissingSpreadsheetData):
readonly SpreadsheetDataBulkRequestDescriptor[] => {
  const results: SpreadsheetDataBulkRequestDescriptor[] = [];

  if (missingSpreadsheetData.filterDetails) {
    Object.keys(missingSpreadsheetData.filterDetails).forEach(spreadsheetIdString => {
      const spreadsheetId = +spreadsheetIdString;

      const filterTree = missingSpreadsheetData.filterDetails?.[spreadsheetId];

      if (!filterTree) {
        return;
      }

      Object.keys(filterTree).forEach(filterHash => {
        results.push({
          requestType: 'filter',
          filterHash,
          requestGetter: {
            map_id: mapId,
            spreadsheet_id: spreadsheetId,
            exclude_basic_data: true,
            filter: {
              only_get_filtered: true,
              filter_tree: filterTree[filterHash],
            },
          },
        });
      });
    });
  }

  return results;
};

export const missingSearchDataBulkRequestGetters = (missingSpreadsheetData: MissingSpreadsheetData):
SpreadsheetDataBulkRequestDescriptor[] => {
  const results: SpreadsheetDataBulkRequestDescriptor[] = [];

  if (missingSpreadsheetData.search) {
    Object.keys(missingSpreadsheetData.search).forEach(spreadsheetIdString => {
      const spreadsheetId = +spreadsheetIdString;

      const filterTree = missingSpreadsheetData.search?.[spreadsheetId];

      if (!filterTree) {
        return;
      }

      Object.keys(filterTree).forEach(filterHash => {
        results.push({
          requestType: 'search',
          filterHash,
          requestGetter: {
            spreadsheet_id: spreadsheetId,
            exclude_basic_data: true,
            filter: {
              only_get_filtered: true,
              filter_tree: filterTree[filterHash],
            },
          },
        });
      });
    });
  }

  return results;
};

export const missingBoundaryLocationsFilterDataBulkRequestGetters = (
  missingSpreadsheetData: MissingSpreadsheetData,
): SpreadsheetDataBulkRequestDescriptor[] => {
  const results: SpreadsheetDataBulkRequestDescriptor[] = [];

  if (missingSpreadsheetData.boundaryFilter) {
    Object.entries(missingSpreadsheetData.boundaryFilter).forEach(([spreadsheetId, hashedItem]) => {

      Object.entries(hashedItem).forEach(([filterHash, filter]) => {
        results.push({
          requestType: 'boundary-locations-filter',
          filterHash,
          requestGetter: {
            spreadsheet_id: +spreadsheetId,
            exclude_basic_data: true,
            filter: {
              only_get_filtered: true,
              boundary_filter: filter.boundaryFilter,
              boundary_territory_filter: filter.boundaryTerritoryFilter,
            },
          },
        });
      });
    });
  }

  return results;
};

export const isAllBasicDataFetched = (): boolean => {

  return true;
};

export const isMissingDataAlreadySet = (current: MissingSpreadsheetData, added: MissingSpreadsheetDataToAdd) =>
  !!current.data[added.spreadsheetId]?.[added.filterHashOrUnfiltered]?.[added.dataType]?.[added.columnId];

export const applyColumnToFetchToState = (columnsToFetch: SpreadsheetColumnsToFetchResponse, item: SpreadsheetDataItem): SpreadsheetDataItem => {
  const newItem = { ...item };

  for (const dataType in columnsToFetch) {
    if (!columnsToFetch.hasOwnProperty(dataType)) {
      continue;
    }

    const columns = columnsToFetch[dataType as DataType];

    for (const columnId in columns) {
      if (!columns.hasOwnProperty(columnId)) {
        continue;
      }

      newItem[columnId] = {
        ...newItem[columnId],
        ...dataType === DataType.GROUP ? {
          [DataType.GROUP]: createSpreadsheetDataItemGroupFromResponse(columns[columnId] as SpreadsheetColumnsToFetchGroupResponse) || undefined,
        } : {},
        ...dataType === DataType.NUMBER ? {
          [DataType.NUMBER]: createSpreadsheetDataItemNumberFromResponse(columns[columnId] as SpreadsheetColumnsToFetchNumberResponse),
        } : {},
        ...dataType === DataType.ATTRIBUTE ? {
          [DataType.ATTRIBUTE]: createSpreadsheetDataItemAttributeFromResponse(columns[columnId] as SpreadsheetColumnsToFetchAttributeResponse),
        } : {},
        ...dataType === DataType.TEXT ? {
          [DataType.TEXT]: createSpreadsheetDataItemTextFromResponse(columns[columnId] as SpreadsheetColumnsToFetchTextResponse),
        } : {},
        ...dataType === DataType.DATE ? {
          [DataType.DATE]: createSpreadsheetDataItemDateFromResponse(columns[columnId] as SpreadsheetColumnsToFetchDateResponse),
        } : {},
      };
    }
  }

  return newItem;
};

export const createUnfilteredBasicDataFromSpreadsheetResponse = (
  response: SpreadsheetDataBulkResponse, matchup: SpreadsheetMatchupData
): DeepWritable<SpreadsheetDataItem> => {
  const results: DeepWritable<SpreadsheetDataItem> = {};

  const latitudeValues: Record<CombinedRowId, string> = {};
  const longitudeValues: Record<CombinedRowId, string> = {};
  const nameValues: Record<CombinedRowId, string> = {};

  if (!response.result.basic_data) {
    return {};
  }

  for (const row of response.result.basic_data) {
    latitudeValues[row.row_id] = row.latitude;
    longitudeValues[row.row_id] = row.longitude;
    if (row.name !== null) {
      nameValues[row.row_id] = row.name;
    }
  }

  const latitudeColumnId = matchup.categories.latitude.match || 'lat';
  const longitudeColumnId = matchup.categories.longitude.match || 'lng';
  const nameColumnId = matchup.categories.name.match;

  if (latitudeColumnId) {
    results[latitudeColumnId] = {
      [DataType.TEXT]: {
        values: latitudeValues,
      },
    };
  }

  if (longitudeColumnId) {
    results[longitudeColumnId] = {
      [DataType.TEXT]: {
        values: longitudeValues,
      },
    };
  }

  if (nameColumnId) {
    results[nameColumnId] = {
      [DataType.TEXT]: {
        values: nameValues,
      },
    };
  }

  return results;
};

export type MissingSpreadsheetDataToAdd = {
  spreadsheetId: number;
  filterHashOrUnfiltered: string;
  columnId: string;
  dataType: DataType;
  spreadsheetDataToFetchExtra: SpreadsheetDataBulkFetchExtra;
};

export type LatLngRowData = Readonly<LatLng & SpreadsheetRowId>;
export type LatLngRowDataWithDistance = Readonly<LatLngRowData & { distanceFromPoint: number }>;
export const getLatLngFromSpreadsheetData = (spreadsheetId: number, spreadsheetData: SpreadsheetDataData, matchupData: MatchupDataState): ReadonlyArray<LatLngRowData> => {
  const latColumnName = matchupData[spreadsheetId]?.data?.categories.latitude.match || 'lat';
  const lngColumnName = matchupData[spreadsheetId]?.data?.categories.longitude.match || 'lng';

  if (!latColumnName || !lngColumnName || !spreadsheetData.values[spreadsheetId]) {
    return [];
  }

  const unfilteredValues = spreadsheetData.values[spreadsheetId]?.[Unfiltered];
  const latValues = unfilteredValues?.[latColumnName]?.[DataType.TEXT]?.values;
  const lngValues = unfilteredValues?.[lngColumnName]?.[DataType.TEXT]?.values;

  if (!latValues || !lngValues) {
    console.warn('missing lat and/or lng values');
    return [];
  }

  const filteredForProperty = Object.keys(latValues).filter(latRowId => lngValues.hasOwnProperty(latRowId));
  if (filteredForProperty.length !== Object.keys(latValues).length) {
    console.warn('There\'s some row in the spreadsheet that is present in lat, but missing in lng.');
  }
  const result = filteredForProperty
    .map(id => {
      const rowId = id;
      return ({
        lat: Number(latValues[rowId]),
        lng: Number(lngValues[rowId]),
        rowId,
        spreadsheetId,
      });
    })
    .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng));

  if (result.length !== filteredForProperty.length) {
    console.warn('There\'s some row in the spreadsheet with invalid number in lat or lng.');
  }

  return result;
};

export const getPerSpreadsheetPerRowLookupFromLatLngRowData = (
  latLngRowData: ReadonlyArray<LatLngRowData>,
): Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>> => {
  const lookup = new Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>();
  latLngRowData.forEach(row => {
    let lookupSpreadsheet = lookup.get(row.spreadsheetId);
    if (!lookupSpreadsheet) {
      lookupSpreadsheet = new Map<CombinedRowId, LatLngRowData>();
      lookup.set(row.spreadsheetId, lookupSpreadsheet);
    }
    lookupSpreadsheet.set(row.rowId, row);
  });
  return lookup;
};

export const intersectionOfPerSpreadsheetDataRows = (perSpreadsheetDataRows: Array<PerSpreadsheetFilteredRowsIdsMap>):
PerSpreadsheetFilteredRowsIdsMap => {
  const results: PerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

  const spreadsheetIdMapOfAllDataRows: { [spreadsheetId: number]: true } = {};

  for (const dataRow of perSpreadsheetDataRows) {
    Object.keys(dataRow).forEach(spreadsheetIdString => {
      const spreadsheetId = +spreadsheetIdString;

      spreadsheetIdMapOfAllDataRows[spreadsheetId] = true;
    });
  }

  Object.keys(spreadsheetIdMapOfAllDataRows).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const result: { [rowId: string]: 1 } = {};

    // find items that has any keys for specific spreadsheetId
    const indexOfItemWithData = perSpreadsheetDataRows.findIndex(item => !!item[spreadsheetId]);

    if (indexOfItemWithData === -1) {
      return;
    }

    Object.keys(perSpreadsheetDataRows[indexOfItemWithData]?.[spreadsheetId] ?? {}).forEach(rowId => {
      let isRowIdInEveryObject = true;

      // find in next items if have any filtering that can be applied to spreadData item
      for (let i = indexOfItemWithData; i < perSpreadsheetDataRows.length; i++) {
        const spreadData = perSpreadsheetDataRows[i]?.[spreadsheetId];

        if (!spreadData) {
          // if one of the items has no data for specific spreadsheet id then
          // it means that there's no filtering applied for that spreadsheet
          continue;
        }
        if (spreadData[rowId] !== 1) {
          isRowIdInEveryObject = false;
          break;
        }
      }

      if (isRowIdInEveryObject) {
        result[rowId] = 1;
      }
    });

    results[spreadsheetId] = result;
  });

  return results;
};

export const checkIfSpreadsheetRowIdMeetsFilteringCriteria = (filtersMap: PerSpreadsheetFilteredRowsIdsMap,
  spreadsheetRow: SpreadsheetRowId): boolean => {
  const spreadsheetFilterMap = filtersMap[spreadsheetRow.spreadsheetId];
  if (!spreadsheetFilterMap) {
    // if filtersMap has no data for spreadsheetId then it means that all of it's data if not filtered
    return true;
  }

  return spreadsheetFilterMap[spreadsheetRow.rowId] === 1;
};

export type FilteredRowIdsResults = {
  readonly filteredRowIds: ReadonlyPerSpreadsheetFilteredRowsIdsMap;
  readonly searchRowIds: ReadonlyPerSpreadsheetFilteredRowsIdsMap;
  readonly boundaryLocationsFilterRowIds: ReadonlyPerSpreadsheetFilteredRowsIdsMap;
};

export type GetSpreadSheetFilteredRowIdsParams = {
  spreadsheetIds: ReadonlyArray<number>;
  spreadsheetData: SpreadsheetDataData | null;
  filters: SpreadsheetFilters | null;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
  searchItems: MapSettingsSearchItemsState;
  selectedMatchingBehaviour: SearchMatchingBehaviour;
  boundaryLocationsFilters: BoundaryLocationsFilters;
};

// if there's no filtering applied then there are no results for spreadsheetId
export const getPerSpreadsheetFilteredRowIds = (params: GetSpreadSheetFilteredRowIdsParams): FilteredRowIdsResults => {
  if (!hasNotNull(params, 'spreadsheetData')) {
    return {
      filteredRowIds: newPerSpreadsheetMap(),
      searchRowIds: newPerSpreadsheetMap(),
      boundaryLocationsFilterRowIds: newPerSpreadsheetMap(),
    };
  }

  // undefined as a result for spreadsheet means that there's no filtering applied for it
  // GROUPING
  const rowIdsHashMapForActiveGroupingColumns = getRowIdsHashMapForActiveGroupingColumnsAndFilters({
    // TODO: Check if it still works after introducing Integrated Layered Maps
    spreadsheetId: params.spreadsheetIds[0],
    columnsFilter: params.columnsFilter,
    filtering: params.filtering,
    grouping: params.grouping,
    filters: params.filters,
  });

  // FILTERS BESIDES GROUPING
  const dataRowsForActiveFilters: PerSpreadsheetFilteredRowsIdsMap = hasNotNull(params, 'filters') ?
    getDataRowsForActiveFilters({ ...params, excludedDataTypes: [DataType.GROUP] }) : newPerSpreadsheetMap();

  // SEARCH
  const dataRowsForSearch: PerSpreadsheetFilteredRowsIdsMap = hasNotNull(params, 'filters') ?
    getDataRowsForSearch(params) : newPerSpreadsheetMap();

  // BOUNDARY LOCATIONS FILTER
  const dataRowsForBoundaryLocationsFilter: PerSpreadsheetFilteredRowsIdsMap = hasNotNull(params, 'filters') ?
    getDataRowsForBoundaryLocationsFilter(params) : newPerSpreadsheetMap();

  const intersectionResults = intersectionOfPerSpreadsheetDataRows([
    rowIdsHashMapForActiveGroupingColumns,
    dataRowsForActiveFilters,
    params.selectedMatchingBehaviour === SearchMatchingBehaviour.ShowOnlyMatches ? dataRowsForSearch : newPerSpreadsheetMap(),
  ]);

  return {
    filteredRowIds: intersectionResults,
    searchRowIds: dataRowsForSearch,
    boundaryLocationsFilterRowIds: dataRowsForBoundaryLocationsFilter,
  };
};

export const generateHash = (string: string): string => {
  // TODO: restore MD5
  // return md5(string);
  return string;
};

export const getDistancesFromPointForSpreadsheetData = (latLngSpreadsheetData: ReadonlyArray<LatLngRowData>, point: LatLng): ReadonlyArray<LatLngRowDataWithDistance> => {
  const latLngWithDistance = latLngSpreadsheetData.map((rowWithLatLng) => {
    const rowPoint: LatLng = {
      lat: rowWithLatLng.lat,
      lng: rowWithLatLng.lng,
    };
    const distanceFromPoint = geometry.computeDistanceBetween(rowPoint, point);
    return {
      ...rowWithLatLng,
      distanceFromPoint,
    };
  });

  latLngWithDistance.sort((a, b) => a.distanceFromPoint - b.distanceFromPoint);
  return latLngWithDistance;
};

type SpreadsheetDataObjectType<T> =
  T extends DataType.GROUP ? SpreadsheetDataItemGroup :
    T extends DataType.NUMBER ? SpreadsheetDataItemNumber :
      T extends DataType.ATTRIBUTE ? SpreadsheetDataItemAttribute :
        T extends DataType.DATE ? SpreadsheetDataItemDate :
          T extends DataType.TEXT ? SpreadsheetDataItemText:
            never;

export const getSpreadsheetDataForDataType = <T extends DataType>(
  dataType: T,
  spreadsheetData: SpreadsheetDataData,
  { spreadsheetId, columnId }: SpreadsheetColumnId
): SpreadsheetDataObjectType<T> | null => {
  const data = spreadsheetData.values[spreadsheetId]?.[Unfiltered]?.[columnId];

  if (!data) {
    return null;
  }

  switch (dataType) {
    case DataType.NUMBER:
      return (data[DataType.NUMBER] ?? null) as SpreadsheetDataObjectType<T> | null;
    case DataType.GROUP:
      return (data[DataType.GROUP] ?? null) as SpreadsheetDataObjectType<T> | null;
    case DataType.TEXT:
      return (data[DataType.TEXT] ?? null) as SpreadsheetDataObjectType<T> | null;
    case DataType.ATTRIBUTE:
      return (data[DataType.ATTRIBUTE] ?? null) as SpreadsheetDataObjectType<T> | null;
    case DataType.DATE:
      return (data[DataType.DATE] ?? null) as SpreadsheetDataObjectType<T> | null;
    default:
      return null;
  }
};

export const getEmptyColumnsForRequestDescriptorColumns = (descriptorColumns: SpreadsheetDataColumnsToFetch) => {
  return Object.fromEntries(Object.entries(descriptorColumns).map(([dataType, value]) => {
    const emptyColumns = Object.fromEntries(Object.keys(value).map(key => {
      return [key, {}];
    }));

    return [dataType, emptyColumns];
  }));
};

export type WritableSpreadsheetData = {
  readonly isSpreadsheetDataData: true;
  values: {
    [spreadsheetId: number]: {
      [filterHashOrUnfiltered: string]: SpreadsheetDataItem;
    };
  };
};

export const cloneSpreadsheetData = (spreadsheetData: SpreadsheetDataData): WritableSpreadsheetData => cloneDeep<WritableSpreadsheetData>(spreadsheetData);
