import { Global } from '@emotion/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { appStyle } from '~/_shared/baseStyles/appStyles/appStyles';
import { hideReCaptchaBadgeStyles } from '~/_shared/components/reCaptcha/reCaptchaBranding.component';
import { CONFIG } from '~/_shared/constants/config';
import { useSubscriptionChecker } from '~/_shared/types/client/useSubscriptionChecker';
import { isPublicDomain } from '~/_shared/utils/url/url.helpers';
import { AuthProvider } from '~/authorization/authContext';
import { useScreenAndDeviceDetect } from '~/map/page/useScreenAndDeviceDetect';
import { useMobileMenuManager } from '~/mobileTopBar/useMobileMenuManager';
import { AppModalRendererContainer } from '~/modal/appModalRenderer.container';
import { useMigrationProgressObserver } from '~/store/mapMigration/hooks/migrationProgressObserver.hook';
import { testingModeEnabled } from '~/testingMode/testingMode';
import {
  disableReactSpringAnimations, useExposeTestingFunctions,
} from '~/testingMode/testingModeFunctions';
import { AppDomainRoutes } from '../routes/appDomainRoutes';
import { PublicDomainRoutes } from '../routes/publicDomainRoutes';
import { useMapTitleResolver } from './useMapTitleResolver.hook';

export const MaptiveApp = () => {

  useMapTitleResolver();
  useScreenAndDeviceDetect();
  useMobileMenuManager();
  useSubscriptionChecker();
  useMigrationProgressObserver();

  const exposeTestingFunctions = useExposeTestingFunctions();

  if (testingModeEnabled()) {
    disableReactSpringAnimations();
    exposeTestingFunctions();
  }

  // TODO: the sitekey is working only on maptive.test, need to use a valid one on production!
  // 6Ld7f_shAAAAAKBRdI-w40rizL7MtHZMkIbDgc4k - provided by Matt for dev
  // 6Lds8sUiAAAAANhhb9cli2pKIBFishEwqdk6HfhN - provided by Matt for production
  // 6Ld8yUojAAAAAJIGu33P9O52_w9yY6l9ewwrNkB8 - used on Backend

  return (
    <>
      <Global styles={hideReCaptchaBadgeStyles} />
      <GoogleReCaptchaProvider
        reCaptchaKey={CONFIG.RECAPTCHA_KEY}
      >
        <AuthProvider>
          <div css={appStyle}>
            {isPublicDomain() ? (
              <PublicDomainRoutes />
            ) : (
              <AppDomainRoutes />
            )}
            <AppModalRendererContainer />
          </div>
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};
