import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.UploadingImage]: {
  views: {
    default: {
      canvasHeight: 1200,
      canvasWidth: 1200,
      iconWidth: 680,
      iconHeight: 440,
    },
  },
  colors: {
    visor: '#37052E',
    tick: '#37052E',
    watermark: '#1C1C1C',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 180] as const,
    visor: [0, 80] as const,
    upload: [0, 90] as const,
    fadeOut: [90, 180] as const,
  },
  getJson: () => import(
    /* webpackChunkName: "animations/91792-uploading-image" */
    './91792-uploading-image.json'
  ),
} };

export const { UploadingImage } = config;
