import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import type { DropdownOption } from '~/_shared/baseComponents/dropdown';
import type {
  SpreadsheetRow,
  SpreadsheetSortDescriptor, SpreadsheetSortType,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import type { FilterTree } from '~/_shared/types/filterTree.types';
import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import type {
  SpreadsheetId, SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import { SpreadsheetRowIdMap } from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { getBaseMapColumnIdFromLayeredSpreadsheetColumnId } from '~/map/layered/layering.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SpreadsheetTableDataError } from '~/spreadsheet/spreadsheet.repository';
import { useSpreadsheetTableDataETagSelector } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import {
  useBaseMapNames, useMapInfoDataSelector,
} from '~/store/mapInfo/mapInfo.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useSpreadsheetDataRaw } from '../useSpreadsheetDataRaw';
import { useSpreadsheetDataRawColumns } from '../useSpreadsheetDataRawColumns';

type ManageDataTableSpreadsheetProps = Readonly<{
  debounceGetSpreadsheetData?: boolean;
  filterTree?: FilterTree;
  initialPerPage?: number;
  spreadsheetId: SpreadsheetId | null;
  activeMarkerId: SpreadsheetRowId | null | undefined;

  onDataError?: () => void;
}>;

const perPageOptions: Array<DropdownOption<number>> = [{
  value: 100,
  name: '100',
}, {
  value: 200,
  name: '200',
}, {
  value: 300,
  name: '300',
}];

export const useManageDataTableSpreadsheet = ({
  debounceGetSpreadsheetData,
  filterTree,
  initialPerPage,
  spreadsheetId,
  onDataError,
  activeMarkerId,
}: ManageDataTableSpreadsheetProps) => {

  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const spreadsheetTableDataETag = useSpreadsheetTableDataETagSelector();
  const mapInfo = useMapInfoDataSelector();
  const baseMapsNames = useBaseMapNames();
  const { openModal: openBadDataModal } = useModal(ModalType.BadDataTable);

  const [sortDescriptor, setSortDescriptor] = useState<PerSpreadsheet<SpreadsheetSortDescriptor>>(newPerSpreadsheetMap());

  const layering = mapInfo?.layering;
  const isLayeredConnected = layering?.connected ?? false;
  const isLayeringRealtime = layering?.realTime ?? false;

  const spreadsheetSortDescriptor = spreadsheetId ? sortDescriptor[spreadsheetId] : undefined;

  const { isLoading: isColumnsDataLoading, columns } = useSpreadsheetDataRawColumns({
    spreadsheetId,
    sortDescriptor: spreadsheetSortDescriptor,
  });

  const {
    isLoading: isRowsDataLoading, data, onDataChange, pagination, onPageSelect, onPerPageChange, error,
  } = useSpreadsheetDataRaw({
    clientId, spreadsheetId, sortDescriptor: spreadsheetSortDescriptor,
    spreadsheetTableDataETag, columns, baseMapsNames, initialPerPage, filterTree,
    applyDebounce: debounceGetSpreadsheetData,
    activeMarkerId,
  });

  const allowCellSelect = !isLayeredConnected;
  const badLocationsCount = isLayeredConnected ? 0 : (mapInfo?.spreadsheets?.[0]?.geocoding?.bad_data ?? 0);
  const columnWidthsLocalStorageKey = spreadsheetId ? `data-table-raw-custom-columns-width|spreadsheet-${spreadsheetId}` : undefined;

  const paginationProps = useMemo(() => ({
    pagination,
    onPageSelect,
    onPerPageChange,
    perPageOptions,
  }), [onPageSelect, onPerPageChange, pagination]);

  const dataLookupBySpreadsheetRowId = useMemo(() => (
    new SpreadsheetRowIdMap<SpreadsheetRow>(
      (data ?? []).map(row => ([{ rowId: row.rowId, spreadsheetId: row.virtualSpreadsheetId }, row]))
    ).asReadonly()
  ), [data]);

  const onBadLocationsClick = useCallback(() => {
    openBadDataModal({});
  }, [openBadDataModal]);

  const onSortChange = useCallback(({ columnId, newSort }: { columnId: string; newSort: SpreadsheetSortType | null }) => {
    if (!spreadsheetId) {
      return;
    }

    const newPerSpreadsheetDescriptor = {
      ...sortDescriptor,
    };
    const currentDescriptor = sortDescriptor[spreadsheetId];

    const spreadsheetDescriptor = {
      ...currentDescriptor ?? {},
    };

    if (newSort === null) {
      delete spreadsheetDescriptor[columnId];
    }
    else {
      spreadsheetDescriptor[columnId] = newSort;
    }

    newPerSpreadsheetDescriptor[spreadsheetId] = spreadsheetDescriptor;

    setSortDescriptor(newPerSpreadsheetDescriptor);
  }, [sortDescriptor, spreadsheetId]);

  const getSpreadsheetCellDisabledMessage = useCallback((spreadsheetRowId: SpreadsheetRowId, columnId?: ColumnId) => {
    if (layering && isLayeredConnected) {
      if (!isLayeringRealtime) {
        return t('layeredMap.cannotEditMapEditBaseWhenNotRealtime');
      }

      if ((mapInfo?.sharedUsers.length || 1) > 1) {
        return t('layeredMap.cannotEditShared');
      }

      const baseMapId = dataLookupBySpreadsheetRowId.get(spreadsheetRowId)?.baseMapId;
      const baseMap = layering.baseMaps.find(b => b.id === baseMapId);

      if (baseMap && baseMap.sharedUsers.length > 1) {
        return t('layeredMap.cannotEditBaseMapShared');
      }

      if (columnId) {
        const columnName = columns.find(c => c.id === columnId)?.name;

        if (baseMap && columnName) {
          const baseMapColumnId = getBaseMapColumnIdFromLayeredSpreadsheetColumnId(baseMap.id, columnId, layering);
          if (!baseMapColumnId) {
            return t('layeredMap.cannotEditBaseNoIntersectForColumn', { columnName, baseMapName: baseMap.name });
          }
        }
      }
    }
    return null;
  }, [columns, dataLookupBySpreadsheetRowId, isLayeredConnected, isLayeringRealtime, layering,
    mapInfo?.sharedUsers.length, t]);

  useEffect(() => {
    if (error) {
      if (error === SpreadsheetTableDataError.SortDescriptor) {
        setSortDescriptor(newPerSpreadsheetMap());
      }
      else {
        onDataError?.();
      }
    }
  }, [error, onDataError]);

  return {
    allowCellSelect,
    badLocationsCount,
    columnWidthsLocalStorageKey,
    columns,
    data,
    isLoading: isColumnsDataLoading || isRowsDataLoading,
    layering,
    paginationProps,
    getSpreadsheetCellDisabledMessage,
    onBadLocationsClick,
    onDataChange,
    onSortChange,
  };
};
