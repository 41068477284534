import { css } from '@emotion/react';
import { type CSSInterpolation } from '@emotion/serialize';
import { type PropsWithChildren } from 'react';

const MAX_TABLE_DATA_WIDTH = 341;

const tableDataStyle = css({
  boxSizing: 'border-box',
  maxWidth: MAX_TABLE_DATA_WIDTH,
  overflow: 'hidden',
  padding: 8,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    paddingLeft: 16,
  },
});

const loadMoreCellStyle = {
  paddingInline: 16,
  textAlign: 'center',
} satisfies CSSInterpolation;

export const BoundarySettingsTableCellWrapperComponent = ({ children, ...rest }: PropsWithChildren<{
  readonly className?: string;
  readonly colspan?: number;
}>) => {
  return (
    <td
      css={tableDataStyle}
      {...rest}
    >
      {children}
    </td>
  );
};

export const BoundarySettingsTableLoadMoreCellWrapper = ({ children }: PropsWithChildren) => {
  return (
    <td
      css={loadMoreCellStyle}
      colSpan={1000}
    >
      {children}
    </td>
  );
};
