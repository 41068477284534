import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

const isMapSettingsSyncInProgressSelector = (state: AppState) => state.frontendState.processing.mapSettingsSyncInProgress;
export const useIsMapSettingsSyncInProgressSelector = () => useSelector(isMapSettingsSyncInProgressSelector);

const mapSettingsSyncFailedSelector = (state: AppState) => state.frontendState.processing.mapSettingsSyncFailed;
export const useMapSettingsSyncFailedSelector = () => useSelector(mapSettingsSyncFailedSelector);

const isSpreadsheetDataFetchingInProgressSelector = (state: AppState) => state.frontendState.processing.spreadsheetDataFetchingInProgress;
export const useIsSpreadsheetDataFetchingInProgressSelector = () => useSelector(isSpreadsheetDataFetchingInProgressSelector);

const isImageExportInProgressSelector = (state: AppState) => state.frontendState.processing.imageExportInProgress;
export const useIsImageExportInProgressSelector = () => useSelector(isImageExportInProgressSelector);

const initialMarkersRenderedSelector = (state: AppState) => state.frontendState.processing.initialMarkersRendered;
export const useInitialMarkersRenderedSelector = () => useSelector(initialMarkersRenderedSelector);

const renderingMarkersSelector = (state: AppState) => state.frontendState.processing.renderingMarkers;
export const useRenderingMarkersSelector = () => useSelector(renderingMarkersSelector);

export const firstZoomLevelSelector = (state: AppState) => state.frontendState.processing.firstZoomLevel;
export const useFirstZoomLevelSelector = () => useSelector(firstZoomLevelSelector);
export const firstZoomDoneSelector = (state: AppState) => firstZoomLevelSelector(state) !== null;
export const useFirstZoomDoneSelector = () => useSelector(firstZoomDoneSelector);

export const exportImageModeInitializedSelector = (state: AppState) => state.frontendState.processing.exportImageModeInitialized;
export const useExportImageModeInitializedSelector = () => useSelector(exportImageModeInitializedSelector);
