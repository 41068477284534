import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import type { AccordionData } from '~/_shared/baseComponents/accordion';
import { CheckboxGroupComponent } from '~/_shared/baseComponents/checkbox/checkboxGroup.component';
import {
  ExportDataModalComponent, type ExportDataModalResults,
} from '~/_shared/components/exportDataModal/exportDataModal.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { ProximityType } from '~/_shared/types/proximity/proximity.enums';
import {
  isGroupRadius, isIndividualRadius,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { prepareAsyncCopyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { ExportDataFileType } from '~/data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useProximities } from '~/proximity/useProximities';
import { ExportProximityDistanceCalculateMethodSectionComponent } from '~/proximityDetails/export/exportProximityDistanceCalculateMothodSection.component';
import { ExportProximityLimitResultsSectionComponent } from '~/proximityDetails/export/exportProximityLimitResultsSection.component';
import {
  ProximityDistanceDisabledReason, useExportProximityDistanceCalculate,
} from '~/proximityDetails/export/useExportProximityDistanceCalculate';
import {
  ProximityLimitMarkersType, useExportProximityLimitResults,
} from '~/proximityDetails/export/useExportProximityLimitResults';
import { getGroupProximityLocations } from '~/proximityDetails/proximityDetails.helpers';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  ExportProximityResultType, useExportProximityData,
} from './useExportProximityData';

export type ExportProximityDataContainerProps = ModalProps<{
  selectedProximityIds: ReadonlyArray<string>;
  spreadsheetRowId?: SpreadsheetRowId;
  showSelectProximitiesSection: boolean;
}>;

const limitResultsSectionStyle = css({
  margin: -14,
});

const radiusLimitExceededStyle = (theme: Theme) => css({
  margin: '10px 0 0 0',
  color: theme.textColors.danger,
});

const PROXIMITY_EXPORT_CIRCLES_PLUS_POLYGONS_LIMIT = 50;

export const ExportProximityDataContainer: FC<ExportProximityDataContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedProximityIds, setSelectedProximityIds] = useState(props.selectedProximityIds);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const [isSelectionSectionExpanded, setIsSelectionSectionExpanded] = useState(true);
  const [isDistanceCalculationMethodExpanded, setIsDistanceCalculationMethodExpanded] = useState(true);
  const [isLimitResultsSectionExpanded, setIsLimitResultsSectionExpanded] = useState(true);
  const [selectedRadiusAndDtpsCount, setSelectedRadiusAndDtpsCount] = useState(0);
  const isRadiusCountLimitExceeded = selectedRadiusAndDtpsCount > PROXIMITY_EXPORT_CIRCLES_PLUS_POLYGONS_LIMIT;

  const [t] = useTranslation();
  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const { isLoading, isError, getExportedProximityData } = useExportProximityData();
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { proximities } = useProximities();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const exportLimits = useExportProximityLimitResults();
  const { replaceCurrentModal } = useModal(ModalType.AppError);

  const selectedProximities = proximities
    .filter(proximity => selectedProximityIds.includes(proximity.id));

  const distanceCalculate = useExportProximityDistanceCalculate(
    selectedRadiusAndDtpsCount,
    exportLimits.markersLimitType === ProximityLimitMarkersType.ClosestToCenter ? exportLimits.markersCount : null
  );

  const isAnySelectedProximityRadius = selectedProximities.filter(proximity => {
    return proximity.type === ProximityType.DistanceRadius;
  }).length > 0;

  const onSave = (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId || selectedProximityIds.length === 0) {
      return;
    }

    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    return getExportedProximityData({
      spreadsheetId: virtualSpreadsheetId,
      proximityList: selectedProximities,
      selectedSpreadsheetRowId: props.spreadsheetRowId ?? null,
      exportAsText: arg.method === ExportDataMethod.Clipboard,
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      exportLimits,
      distanceCalculate,
    })
      .then(response => {
        if (response.type === ExportProximityResultType.Cancelled) {
          props.onClose();
          return;
        }

        if (arg.method === ExportDataMethod.Clipboard) {
          const fileContent = response.data.data.data;

          if (!fileContent) {
            setErrorMessage(t('No locations to export'));
            return;
          }

          asyncCopyToClipboard(fileContent);
          showCopiedToClipboardMessage();
        }
        else {
          const fileName = response.data.data.file;

          if (!fileName) {
            setErrorMessage(t('No locations to export'));
            return;
          }

          const exportUrl = getExportUrl(fileName);
          downloadFile(exportUrl);

          props.onClose();
          openDownloadResultsModal({
            downloadUrl: exportUrl,
          });
        }
      });
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  useEffect(() => {
    setErrorMessage(null);
  }, [selectedProximityIds]);

  useEffect(() => {
    if (!spreadsheetData) {
      return;
    }

    const radiusCount = proximities.reduce<number>((acc, proximity) => {
      if (!selectedProximityIds.includes(proximity.id)) {
        return acc;
      }

      if (isIndividualRadius(proximity)) {
        return acc + 1;
      }
      else if (isGroupRadius(proximity)) {
        const proximityLocations = getGroupProximityLocations(spreadsheetData, proximity);
        return acc + proximityLocations.length;
      }
      else {
        return acc + 1;
      }
    }, 0);

    setSelectedRadiusAndDtpsCount(radiusCount);

    // show modal if radius proximity exceeds the circles limit instead of a warning
    if (radiusCount > PROXIMITY_EXPORT_CIRCLES_PLUS_POLYGONS_LIMIT && !props.showSelectProximitiesSection) {
      replaceCurrentModal({
        type: AppErrorType.General,
        title: t('Error'),
        content: t('proximityExport.overCirclesLimitError', { limit: PROXIMITY_EXPORT_CIRCLES_PLUS_POLYGONS_LIMIT, current: radiusCount }),
      });
    }
  }, [selectedProximityIds, props.selectedProximityIds, proximities, spreadsheetData, props.showSelectProximitiesSection, replaceCurrentModal, t]);

  const sectionsAfterExportMethod: AccordionData[] = [{
    header: t('Limit Results'),
    isExpanded: isLimitResultsSectionExpanded,
    onHeadingClick: () => setIsLimitResultsSectionExpanded(isExpanded => !isExpanded),
    child: (
      <div css={limitResultsSectionStyle}>
        <ExportProximityLimitResultsSectionComponent {...exportLimits} showMarkersLimitOptions={isAnySelectedProximityRadius} />
      </div>
    ),
  }];

  if (selectedRadiusAndDtpsCount > 0 && distanceCalculate.drivingDisabledReason !== ProximityDistanceDisabledReason.PresentationalMap) {
    sectionsAfterExportMethod.push({
      header: t('Distance Calculation Method'),
      isExpanded: isDistanceCalculationMethodExpanded,
      onHeadingClick: () => setIsDistanceCalculationMethodExpanded(isExpanded => !isExpanded),
      child: (
        <div css={limitResultsSectionStyle}>
          <ExportProximityDistanceCalculateMethodSectionComponent {...distanceCalculate} />
        </div>
      ),
    });
  }

  const sectionsBeforeExportMethod = !props.showSelectProximitiesSection ? [] : [{
    header: t('Choose the proximities you want to export'),
    isExpanded: isSelectionSectionExpanded,
    onHeadingClick: () => setIsSelectionSectionExpanded(!isSelectionSectionExpanded),
    child: (
      <div>
        <CheckboxGroupComponent
          onSelectionChanged={setSelectedProximityIds}
          selectedValues={selectedProximityIds}
          items={proximities.map(proximity => ({
            value: proximity.id,
            text: proximity.name,
          }))}
        />
        {isRadiusCountLimitExceeded && (
          <p css={radiusLimitExceededStyle}>
            {t('proximityExport.overCirclesLimitError', { limit: PROXIMITY_EXPORT_CIRCLES_PLUS_POLYGONS_LIMIT, current: selectedRadiusAndDtpsCount })}
          </p>
        )}
      </div>
    ),
  }];

  return (
    <ExportDataModalComponent
      isLoading={isLoading}
      sectionsAfterExportMethod={sectionsAfterExportMethod}
      sectionsBeforeExportMethod={sectionsBeforeExportMethod}
      onSubmit={onSave}
      onClose={props.onClose}
      isOpen={props.isOpen}
      error={errorMessage}
      isSubmitDisabled={selectedProximityIds.length === 0 || isRadiusCountLimitExceeded || !distanceCalculate.isValid}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
      showCount={false}
    />
  );
};
