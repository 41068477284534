import {
  type GroupingColumn, type GroupingColumnNumeric, type GroupingColumnValues, GroupingType,
  type PerSpreadsheetUniqueGroups, type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import {
  newPerSpreadsheetMap, type PerSpreadsheet, type PerSpreadsheetFilteredRowsIdsMap,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import {
  formatValue, getPercentageValueFromNumerical, type NumericalRange, RangeType,
} from '~/_shared/utils/range/range.helpers';
import {
  isNullOrUndefined, notNullsy,
} from '~/_shared/utils/typeGuards';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { type Attribute } from '~/sidebar/sidebarApps/mapTools/filterTool/filters/attributeFilter.component';
import { getColumnsFilterDataForDataType } from '~/sidebar/sidebarApps/mapTools/filterTool/filters/filters.helpers';
import { translate } from '~/translations/Trans';
import { type MapSettingsColumnsFilterState } from '../../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { type MapSettingsFilteringState } from '../../mapSettings/filtering/mapSettingsFiltering.state';
import { type MapSettingsGroupingState } from '../../mapSettings/grouping/mapSettingsGrouping.state';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import { getMatchupDataColumnName } from '../../matchupData/matchupData.helpers';
import { type MatchupDataState } from '../../matchupData/matchupData.state';
import {
  addMissingSpreadsheetData, generateHash, type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType, type SpreadsheetDataData, type SpreadsheetDataItemAttribute, type SpreadsheetDataItemDate,
  type SpreadsheetDataItemNumber, type SpreadsheetFilters, Unfiltered, type UniqueGroupsDataTypeData,
} from '../spreadsheetData.state';

export const NON_NUMERICAL_COLOR = '1E1E1E';
export const NON_NUMERICAL_VALUE = -1;
export const NO_GROUPS = '%?%';
export const NO_ATTRIBUTES = '%?%';

export const nonNumericalBucketWithRange: NumericBucketWithRange = {
  name: translate('Non-numerical Values'),
  id: NON_NUMERICAL_VALUE,
  range: null,
};

export const getMissingDataTypesForGroup = (spreadsheetData: SpreadsheetDataData, spreadsheetId: number,
  filterHash: string, columnId: string
): DataType[] => {
  const results: DataType[] = [];
  const groupDataTypes = [DataType.GROUP, DataType.NUMBER];

  for (const dataType of groupDataTypes) {
    if (!checkIfDataExistsForDataType(spreadsheetData, spreadsheetId, filterHash, columnId, dataType)) {
      results.push(dataType);
    }
  }

  return results;
};

export const checkIfDataExistsForDataType = (spreadsheetData: SpreadsheetDataData, spreadsheetId: number,
  filterHash: string, columnId: string, dataType: DataType): boolean => {
  const data = spreadsheetData?.values[spreadsheetId]?.[filterHash]?.[columnId]?.[dataType];
  return !!data && Object.keys(data).length > 0;
};

export const getColumnGroupUniqueGroupsCount = (spreadsheetData: SpreadsheetDataData, spreadsheetId: number,
  filterHash: string, columnId: string): number | null => {
  return spreadsheetData.values[spreadsheetId]?.[filterHash]?.[columnId]?.[DataType.GROUP]?.extra.uniqueGroups.length ?? null;
};

export const checkIfIsNumericalColumn = (min: number | null, max: number | null): boolean => {
  if (min === null || max === null) {
    return false;
  }

  return max - min > 0;
};

export const getNumericGroupData = (spreadsheetData: SpreadsheetDataData, spreadsheetId: number,
  filterHash: string, columnId: string): SpreadsheetDataItemNumber | null => {
  return spreadsheetData.values[spreadsheetId]?.[filterHash]?.[columnId]?.[DataType.NUMBER] ?? null;
};

export const validateSpreadsheetDataItemDate = (data: SpreadsheetDataItemDate): boolean => {
  return data.extra.min !== '' && data.extra.max !== '';
};

export const validateSpreadsheetDataItemAttribute = (data: SpreadsheetDataItemAttribute): boolean => {
  return !!data;
};

export const generateGroupIdByName = (groupName: string): string => {
  if (groupName === '') {
    return NO_GROUPS;
  }
  return groupName.toLowerCase();
};

const getAttributeId = (attributeName: string): string => {
  if (attributeName === '') {
    return NO_ATTRIBUTES;
  }

  return attributeName.toLowerCase();
};

export const getMissingGroupingSpreadsheetData = (
  mapSettings: MapSettingsState,
  spreadsheetData: SpreadsheetDataData,
  isLayered: boolean,
  spreadsheetIds: number[],
): MissingSpreadsheetData => {
  const missingGroupingSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  // get data for activeGroupColumns
  for (const activeGroupColumn of mapSettings.data.grouping.activeGroupColumns) {
    const dataType = getDataTypeFromGroupType(activeGroupColumn.type);

    if (!spreadsheetData.values[activeGroupColumn.spreadsheetId] || !spreadsheetData.values[activeGroupColumn.spreadsheetId]?.[Unfiltered]?.[activeGroupColumn.columnId]?.[dataType]) {
      addMissingSpreadsheetData(missingGroupingSpreadsheetData, {
        spreadsheetId: activeGroupColumn.spreadsheetId,
        columnId: activeGroupColumn.columnId,
        filterHashOrUnfiltered: Unfiltered,
        dataType,
        spreadsheetDataToFetchExtra: {},
      });
    }
  }

  if (isLayered) {
    for (const spreadsheetId of spreadsheetIds) {
      if (
        !spreadsheetData.values[spreadsheetId] ||
        !spreadsheetData.values[spreadsheetId][Unfiltered]?.[BASE_MAPS_COLUMN_ID]?.[DataType.GROUP]
      ) {
        addMissingSpreadsheetData(missingGroupingSpreadsheetData, {
          spreadsheetId,
          columnId: BASE_MAPS_COLUMN_ID,
          filterHashOrUnfiltered: Unfiltered,
          dataType: DataType.GROUP,
          spreadsheetDataToFetchExtra: {},
        });
      }
    }
  }

  return missingGroupingSpreadsheetData;
};

export const getRowIdsHashMapForActiveGroupingColumnsAndFilters = (params: {
  spreadsheetId: number;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
  filters: SpreadsheetFilters | null;
}): PerSpreadsheetFilteredRowsIdsMap => {
  const dataRowsForActiveGroupings = getDataRowsForActiveGroupingFilters(params);

  const results: PerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

  Object.keys(dataRowsForActiveGroupings).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const filteredRows = dataRowsForActiveGroupings[spreadsheetId];

    if (filteredRows) {
      results[spreadsheetId] = filteredRows.values;
    }
  });

  return results;
};

type FilteredRows = {
  filterHash: string;
  values: { [rowId: string]: 1 };
};

export const getDataRowsForActiveGroupingFilters = (params: {
  spreadsheetId: number;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
  filters: SpreadsheetFilters | null;
}): PerSpreadsheet<FilteredRows> => {
  const resultsFilteredRows: PerSpreadsheet<FilteredRows> = newPerSpreadsheetMap();

  const groupingsWithValues = getActiveGroupingFilters(params);
  const perSpreadsheetFilterHash = getHashForTurnedOnGroupingFilters(
    groupingsWithValues,
    params.columnsFilter
  );

  if (params.filters) {
    Object.keys(perSpreadsheetFilterHash).forEach(spreadsheetIdString => {
      const spreadsheetId = +spreadsheetIdString;

      const filterHash = perSpreadsheetFilterHash[spreadsheetId] || '';
      const storeFilterValuesForFilterHash = params.filters?.[spreadsheetId]?.[filterHash];

      if (storeFilterValuesForFilterHash) {
        resultsFilteredRows[spreadsheetId] = {
          filterHash,
          values: storeFilterValuesForFilterHash,
        };
      }
    });
  }

  return resultsFilteredRows;
};

export const calculateDataRowsForActiveGroupingFilters = (
  spreadsheetData: SpreadsheetDataData,
  params: {
    spreadsheetId: number;
    columnsFilter: MapSettingsColumnsFilterState;
    filtering: MapSettingsFilteringState;
    grouping: MapSettingsGroupingState;
  }): PerSpreadsheet<FilteredRows> => {
  const perSpreadsheetValues: PerSpreadsheet<{
    values: Array<{
      [rowId: string]: 1;
    }>;
  }> = newPerSpreadsheetMap();

  const resultsFilteredRows: PerSpreadsheet<FilteredRows> = newPerSpreadsheetMap();

  const groupingsWithValues = getActiveGroupingFilters(params);
  const perSpreadsheetFilterHash = getHashForTurnedOnGroupingFilters(
    groupingsWithValues,
    params.columnsFilter
  );

  // if data doesn't exist at this point then it has to be calculated
  for (const grouping of groupingsWithValues) {
    const groupRowIds: { [rowId: string]: 1 } = {};

    if (grouping.type === GroupingType.Text) {
      const selectedGroups = params.columnsFilter[grouping.spreadsheetId]?.[grouping.columnId]
        ?.[DataType.GROUP]?.[GroupingType.Text];
      const spreadsheetGroupValues = spreadsheetData.values[grouping.spreadsheetId]?.[Unfiltered]?.[grouping.columnId]
        ?.[DataType.GROUP];

      if (!selectedGroups || !spreadsheetGroupValues) {
        continue;
      }

      Object.keys(spreadsheetGroupValues.values).forEach(rowId => {
        const emptyGroup = spreadsheetGroupValues.extra.uniqueGroups.find(group => group.label === '');
        const rowUniqueGroupId = spreadsheetGroupValues.values[rowId];

        const groupId = notNullsy(rowUniqueGroupId) ? spreadsheetGroupValues.extra.uniqueGroups[rowUniqueGroupId]?.id : emptyGroup?.id;

        if (groupId === undefined) {
          return;
        }

        if (selectedGroups[groupId] === 1) {
          groupRowIds[rowId] = 1;
        }
      });
    }

    if (grouping.type === GroupingType.Numeric) {
      const selectedGroups = params.columnsFilter[grouping.spreadsheetId]?.[grouping.columnId]
        ?.[DataType.GROUP]?.[GroupingType.Numeric];
      const spreadsheetDataGroupVales = spreadsheetData.values[grouping.spreadsheetId]?.[Unfiltered]?.[grouping.columnId]
        ?.[DataType.NUMBER];

      if (!selectedGroups || !spreadsheetDataGroupVales) {
        continue;
      }

      const numericBucketsWithRange = getNumericalGroupBuckets(
        grouping,
        spreadsheetDataGroupVales.extra.min,
        spreadsheetDataGroupVales.extra.max,
        true
      );

      Object.keys(spreadsheetDataGroupVales.values).forEach(rowId => {
        const value = spreadsheetDataGroupVales.values[rowId];

        const bucketIdForValue = getItemNumericBucketFromBuckets(
          value,
          grouping.valueType,
          spreadsheetDataGroupVales.extra.min,
          spreadsheetDataGroupVales.extra.max,
          numericBucketsWithRange
        );

        if (selectedGroups[bucketIdForValue.id] === 1) {
          groupRowIds[rowId] = 1;
        }
      });
    }

    perSpreadsheetValues[grouping.spreadsheetId] = {
      values: [
        ...perSpreadsheetValues[grouping.spreadsheetId]?.values ?? [],
        groupRowIds,
      ],
    };
  }

  // apply intersection of spreadsheet object array
  Object.keys(perSpreadsheetValues).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const spreadsheetValues = perSpreadsheetValues[spreadsheetId]?.values;
    if (!spreadsheetValues || !spreadsheetValues.length) {
      return;
    }

    const resultRowValues: FilteredRows = {
      filterHash: perSpreadsheetFilterHash[spreadsheetId] || '',
      values: {},
    };
    const values = spreadsheetValues[0];
    if (values) {
      Object.keys(values).forEach(rowId => {
        let isRowIdInEveryObject = true;

        for (let i = 1; i < spreadsheetValues.length; i++) {
          if (spreadsheetValues?.[i]?.[rowId] !== 1) {
            isRowIdInEveryObject = false;
            break;
          }
        }

        if (isRowIdInEveryObject) {
          resultRowValues.values[rowId] = 1;
        }
      });
    }

    resultsFilteredRows[spreadsheetId] = resultRowValues;
  });

  return resultsFilteredRows;
};

type GroupingColumnWithValues = {
  column: GroupingColumn;
  values: GroupingColumnValues<1>;
};

const getActiveGroupingFiltersWithValues = (params: {
  spreadsheetId: number;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
}): GroupingColumnWithValues[] => {
  // add groups from grouping tool (activeGroupColumns)
  const turnedOnGroupings: GroupingColumn[] = [...params.grouping.activeGroupColumns];

  // add groups from filter tool
  Object.keys(params.filtering.settings).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const filterSettings = params.filtering.settings[spreadsheetId];

    if (filterSettings) {
      Object.keys(filterSettings).forEach(columnId => {
        const item = filterSettings[columnId];

        if (item?.type === DataType.GROUP) {
          turnedOnGroupings.push({
            spreadsheetId,
            columnId,
            type: GroupingType.Text,
          });
        }
      });
    }
  });

  return turnedOnGroupings
    .map(grouping => {
      const groupingValues = getGroupingColumnValues(grouping, params.columnsFilter);

      if (!groupingValues) {
        return null;
      }

      return {
        column: grouping,
        values: groupingValues,
      };
    })
    .filter((grouping): grouping is GroupingColumnWithValues => grouping !== null);
};

export const getGroupingColumnValues = (
  groupingColumn: GroupingColumn,
  columnsFilter: MapSettingsColumnsFilterState,
): GroupingColumnValues<1> | null => {
  const columnValues = getColumnsFilterDataForDataType(DataType.GROUP, columnsFilter, groupingColumn);
  const groupingValues = columnValues?.[groupingColumn.type];

  if (!groupingValues || Object.keys(groupingValues).length === 0) {
    return null;
  }

  return groupingValues;
};

// return turned on grouping filters from filter settings and activeGroupingColumns that have any selected value
export const getActiveGroupingFilters = (params: {
  spreadsheetId: number;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
}): GroupingColumn[] => {
  return getActiveGroupingFiltersWithValues(params)
    .map(item => item.column);
};

export const getHashForTurnedOnGroupingFilters = (turnedOnGroupingFilters: GroupingColumn[],
  filtersState: MapSettingsColumnsFilterState): { [spreadsheetId: number]: string } => {
  const perSpreadsheetHashList: PerSpreadsheet<string[]> = newPerSpreadsheetMap();

  for (const grouping of turnedOnGroupingFilters) {
    const groupingValues = filtersState[grouping.spreadsheetId]?.[grouping.columnId]
      ?.[DataType.GROUP]?.[grouping.type];

    if (!groupingValues) {
      continue;
    }

    const spreadsheetHashList = perSpreadsheetHashList[grouping.spreadsheetId] ?? [];
    spreadsheetHashList.push(grouping.columnId, grouping.type);
    spreadsheetHashList.push(JSON.stringify(groupingValues));
    perSpreadsheetHashList[grouping.spreadsheetId] = spreadsheetHashList;
  }

  return Object.keys(perSpreadsheetHashList).reduce<{ [spreadsheetId: number]: string }>(
    (acc, spreadsheetIdString) => {
      const spreadsheetId = +spreadsheetIdString;
      const hashListItem = perSpreadsheetHashList[spreadsheetId];

      if (hashListItem) {
        acc[spreadsheetId] = generateHash('grouping' + hashListItem.join('-'));
      }

      return acc;
    }, {});
};

export const getTextGroupUniqueColumns = (uniqueGroups: UniqueGroupsDataTypeData, columnName: string): UniqueGroup[] => {
  return uniqueGroups.map((group) => {
    if (group.label !== '') {
      return ({
        type: GroupingType.Text,
        name: group.label,
        id: group.id,
      });
    }

    return {
      type: GroupingType.Text,
      name: translate('No {{columnName}}', undefined, {
        columnName,
      }),
      id: NO_GROUPS,
    };
  });
};

export const getAttributeColumnUniqueAttributes = (
  uniqueAttributeNames: ReadonlyArray<string>,
  columnName: string,
): Attribute<string>[] => {
  return uniqueAttributeNames.map(attributeName => {
    if (attributeName !== '') {
      return {
        id: getAttributeId(attributeName),
        value: attributeName,
      };
    }

    return {
      id: NO_ATTRIBUTES,
      value: translate('No {{columnName}}', undefined, {
        columnName,
      }),
    };
  });
};

export const getGroupingColumnsUniqueGroups = (
  data: SpreadsheetDataData,
  matchupData: MatchupDataState,
  groupColumn?: GroupingColumn,
): UniqueGroup[] => {
  let results: UniqueGroup[] = [];

  if (!groupColumn) {
    return results;
  }

  Object.keys(data.values).forEach((spreadsheetIdString) => {
    const spreadsheetId: number = +spreadsheetIdString;

    if (groupColumn.spreadsheetId !== spreadsheetId) {
      return;
    }

    const unfilteredValued = data.values?.[spreadsheetId]?.[Unfiltered];
    if (unfilteredValued) {
      Object.keys(unfilteredValued).forEach(columnId => {
        if (groupColumn.columnId !== columnId) {
          return;
        }

        let uniqueGroups: UniqueGroupsDataTypeData = [];
        const spreadData = data.values[spreadsheetId]?.[Unfiltered]?.[columnId] ?? {};

        // text unique groups
        if (groupColumn.type === GroupingType.Text) {
          uniqueGroups = spreadData?.[DataType.GROUP]?.extra.uniqueGroups ?? [];
          const columnName = getMatchupDataColumnName(matchupData, {
            columnId,
            spreadsheetId,
          });

          results = getTextGroupUniqueColumns(uniqueGroups, columnName ?? '');
        }
        // numerical unique groups
        else {
          const numberSpreadData = spreadData[DataType.NUMBER];
          if (!numberSpreadData || groupColumn.buckets.length === 0) {
            return;
          }

          const groupValues = getGroupingColumnRangesFromBuckets(groupColumn, numberSpreadData.extra.min, numberSpreadData.extra.max);

          uniqueGroups = groupValues.map(item => {
            const groupName = getNumericalBucketRangeName(item, groupColumn.valueType, numberSpreadData.extra.min, numberSpreadData.extra.max);
            return {
              id: generateGroupIdByName(groupName),
              label: groupName,
            };
          });

          results = uniqueGroups.map((group, index) => ({
            type: GroupingType.Numeric,
            id: index,
            name: group.label,
          }));

          results.push({
            type: GroupingType.Numeric,
            id: NON_NUMERICAL_VALUE,
            name: translate('Non-numerical Values'),
          });
        }
      });
    }
  });

  return results;
};

export const getNumericalBucketRangeName = (range: NumericalRange, rangeType: RangeType, min: number, max: number): string => {
  const valueSuffix = rangeType === RangeType.Percentage ? '%' : '';

  const valueFrom = range.from === min && range.to <= min ? '-∞' : `${formatValue(range.from)}${valueSuffix}+`;
  const valueTo = range.to === max && range.from >= max ? '∞' : `${formatValue(range.to)}${valueSuffix}`;

  return translate('{{valueFrom}} to {{valueTo}}', undefined, { valueFrom, valueTo });
};

export const getPerSpreadsheetUniqueGroups = (
  data: SpreadsheetDataData, groupColumn: GroupingColumn, matchupData: MatchupDataState
): PerSpreadsheetUniqueGroups => {
  const uniqueColumns = getGroupingColumnsUniqueGroups(data, matchupData, groupColumn);

  return {
    [groupColumn.spreadsheetId]: {
      [groupColumn.columnId]: uniqueColumns,
    },
  };
};

export type NumericBucketWithRange = {
  range: NumericalRange | null;
  name: string;
  id: number;
};

export const getNumericalGroupBuckets = (
  groupColumn: GroupingColumnNumeric,
  min: number,
  max: number,
  includeNonNumericalBucket: boolean
): NumericBucketWithRange[] => {
  const uniqueGroups: NumericBucketWithRange[] = [];
  const groupValueRanges = getGroupingColumnRangesFromBuckets(groupColumn, min, max);

  for (let i = 0; i < groupValueRanges.length; i++) {
    const itemRange = groupValueRanges[i];

    if (itemRange) {
      uniqueGroups.push({
        range: itemRange,
        id: i,
        name: getNumericalBucketRangeName(itemRange, groupColumn.valueType, min, max),
      });
    }
  }

  if (includeNonNumericalBucket) {
    uniqueGroups.push(nonNumericalBucketWithRange);
  }

  return uniqueGroups;
};

export type NumericBucket = {
  id: number;
  name: string;
};

export const getItemNumericBucketFromBuckets = (
  value: number | null | undefined,
  rangeType: RangeType,
  min: number,
  max: number,
  bucketsWithRange: NumericBucketWithRange[]
): NumericBucket => {
  if (isNullOrUndefined(value)) {
    return nonNumericalBucketWithRange;
  }

  const percentageValue = getPercentageValueFromNumerical(value, min, max);

  return getNumericBucketForValueFromList(
    bucketsWithRange,
    rangeType === RangeType.Percentage ? percentageValue : value
  );
};

export const getNumericalGroupBucketsForSpreadsheetData = (
  column: GroupingColumn, spreadsheetData: SpreadsheetDataData
): NumericBucketWithRange[] => {
  if (column.type === GroupingType.Numeric && spreadsheetData) {
    const spreadsheetDataGroupValues = spreadsheetData.values[column.spreadsheetId]?.[Unfiltered]?.[column.columnId]?.[DataType.NUMBER];

    if (spreadsheetDataGroupValues) {
      return getNumericalGroupBuckets(
        column,
        spreadsheetDataGroupValues.extra.min,
        spreadsheetDataGroupValues.extra.max,
        true
      );
    }
  }

  return [];
};

const getNumericBucketForValueFromList = (allBuckets: NumericBucketWithRange[], value: number):
NumericBucketWithRange => {
  // Filter out non numerial buckets
  const bucketsWithRange = allBuckets.filter(((i): i is NumericBucketWithRange & { range: NumericalRange } => !!i.range));

  // We use non inclusive ranges (FROM, TO], but on first item TO value is incusive [FROM, TO]
  if (bucketsWithRange[0] && bucketsWithRange[0].range.from === value) {
    return bucketsWithRange[0];
  }

  for (const bucket of bucketsWithRange) {
    if (value > bucket.range.from && value <= bucket.range.to) {
      return bucket;
    }
  }

  return nonNumericalBucketWithRange;
};

export const getGroupingColumnRangesFromBuckets = (
  groupColumn: GroupingColumnNumeric, minValue: number, maxValue: number
): NumericalRange[] => {
  const ranges: NumericalRange[] = [];

  const min = groupColumn.valueType === RangeType.Value ? minValue : 0;
  const max = groupColumn.valueType === RangeType.Value ? maxValue : 100;

  const firstBucket = groupColumn.buckets[0];
  if (notNullsy(firstBucket)) {
    ranges.push({
      from: min,
      to: firstBucket,
    });
  }

  for (let i = 0; i < groupColumn.buckets.length; i++) {
    const itemFrom = groupColumn.buckets[i];
    const itemTo = i === groupColumn.buckets.length - 1 ? max : groupColumn.buckets[i + 1];

    if (notNullsy(itemFrom) && notNullsy(itemTo)) {
      ranges.push({
        from: itemFrom,
        to: itemTo,
      });
    }
  }

  return ranges;
};

const getDataTypeFromGroupType = (groupType: GroupingType): DataType => {
  switch (groupType) {
    case GroupingType.Numeric:
      return DataType.NUMBER;
    case GroupingType.Text:
      return DataType.GROUP;
    default:
      return DataType.GROUP;
  }
};

// get unique groups that are selected for groupingColumn
export const getGroupingColumnSelectedUniqueGroups = (
  groupingColumn: GroupingColumn,
  columnsFilter: MapSettingsColumnsFilterState,
  spreadsheetData: SpreadsheetDataData,
  matchupData: MatchupDataState,
): UniqueGroup[] => {
  const groupValues = getGroupingColumnValues(groupingColumn, columnsFilter);

  if (!groupValues) {
    return [];
  }

  const columnUniqueGroups = getGroupingColumnsUniqueGroups(
    spreadsheetData ?? {},
    matchupData,
    groupingColumn,
  );

  return Object.keys(groupValues).reduce<UniqueGroup[]>(
    (acc, groupId) => {
      const columnUniqueGroup = columnUniqueGroups.find(item => item.id.toString() === groupId);

      if (!columnUniqueGroup) {
        return acc;
      }

      acc.push(columnUniqueGroup);

      return acc;
    }, []);
};
