import { compareSpreadsheetColumnIds } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import {
  SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE, SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.constants';
import { SidebarTitleType } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.enums';
import {
  type SidebarTitle,
  type SidebarTitleSettings, type SidebarTitleStyle,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { type SpreadsheetColumnId } from '../_shared/types/spreadsheetData/spreadsheetColumn';
import { type SpreadsheetRowId } from '../_shared/types/spreadsheetData/spreadsheetRow';
import { type ThemeProps } from '../_shared/types/themeProps';
import { insertBeforeInArray } from '../_shared/utils/dnd/dnd.helpers';
import { addOrUpdateColumnPerSpreadsheetSettings } from '../_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { type MatchupDataState } from '../store/matchupData/matchupData.state';
import { getColumnItemById } from './locationData/locationData.helpers';
import { type BubbleItemMap } from './locationData/locationData.types';
import { type PanelSettingsColumnItemMap } from './locationData/panelSettings.types';

export const updateStyle = <T extends { [key: string]: any}>(oldStyle: T, newStyle: Partial<T>): T => {
  const updatedStyle = Object.keys(newStyle)
    .filter(key => newStyle[key] !== undefined)
    .reduce((style, key) => ({ ...style, [key]: newStyle[key] }), {});

  return ({ ...oldStyle, ...updatedStyle });
};

export const computeItemsOrder = (
  array: ReadonlyArray<SpreadsheetColumnId>,
  sourceId: SpreadsheetColumnId,
  targetId?: SpreadsheetColumnId
): ReadonlyArray<SpreadsheetColumnId> => {
  if (targetId !== undefined) {
    return insertBeforeInArray({
      source: sourceId, target: targetId, array: [...array],
      equalityComparator: compareSpreadsheetColumnIds,
    });
  }
  else {
    return [...array, sourceId];
  }
};

export const toggleActiveStatus = (
  props: {
    itemId: SpreadsheetColumnId;
    status: boolean;
    columnSettings: PanelSettingsColumnItemMap;
  }
): PanelSettingsColumnItemMap => {
  const column = getColumnItemById(props.columnSettings, props.itemId);

  if (!column) {
    return props.columnSettings;
  }

  return addOrUpdateColumnPerSpreadsheetSettings(props.columnSettings, props.itemId, {
    ...column,
    isActive: props.status,
  });
};

export const changeDescriptionSettings = (
  props: ThemeProps<{
    settings: Partial<SidebarTitleSettings>;
    itemName: SidebarTitleType;
    currentLocationDescriptionStyle?: SidebarTitleStyle;
  }>
): SidebarTitleStyle => {
  let guaranteedSettings: SidebarTitleSettings;

  switch (props.itemName) {
    case SidebarTitleType.header: {
      guaranteedSettings = {
        ...SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE(props.theme),
        ...props.currentLocationDescriptionStyle?.header,
      };
      break;
    }
    case SidebarTitleType.semiHeader: {
      guaranteedSettings = {
        ...SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE(props.theme),
        ...props.currentLocationDescriptionStyle?.semiHeader,
      };
      break;
    }
    case SidebarTitleType.description:
    default: {
      guaranteedSettings = {
        ...SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE(props.theme),
        ...props.currentLocationDescriptionStyle?.description,
      };
      break;
    }
  }

  const newSettings = updateStyle(guaranteedSettings, props.settings);

  return ({
    ...props.currentLocationDescriptionStyle,
    [props.itemName]: {
      ...(props.currentLocationDescriptionStyle && props.currentLocationDescriptionStyle[props.itemName]),
      ...newSettings,
    },
  });
};

export const filterOutInactiveBubbleItemIds = (items: BubbleItemMap, itemIds: ReadonlyArray<SpreadsheetColumnId>) =>
  itemIds.filter(id => getColumnItemById(items, id)?.itemData.isActive);

export const filterOutNotExistingBubbleItemIds = (items: BubbleItemMap, itemIds: ReadonlyArray<SpreadsheetColumnId>) =>
  itemIds.filter(id => !!getColumnItemById(items, id));

type LocationDescriptionProperties = Partial<Readonly<{
  address: string;
  addressLine2: string;
  city: string;
  country: string;
  markerName: string;
  state: string;
  zipCode: string;
}>>;

export const getLocationDescription = (data: LocationDescriptionProperties): SidebarTitle => {
  const descriptionList = [data.city, data.state, data.zipCode, data.country].filter(d => d);

  return {
    header: data.markerName || '',
    description: [data.addressLine2 || '', descriptionList.join(', ')],
    semiHeader: data.address || '',
  };
};

export const getLocationDescriptionProperties = (data: {
  bubbleItems: BubbleItemMap;
  matchupData: MatchupDataState;
  spreadsheetRowId: SpreadsheetRowId;
}): LocationDescriptionProperties => {
  const spreadsheetId = data.spreadsheetRowId.spreadsheetId;
  const rowMatchupCategories = data.matchupData[spreadsheetId]?.data?.categories;

  const columnNameAddress = rowMatchupCategories?.address.match;
  const columnNameAddressLine2 = rowMatchupCategories?.address_line_2.match;
  const columnNameCity = rowMatchupCategories?.city.match;
  const columnNameCountry = rowMatchupCategories?.country.match;
  const columnNameState = rowMatchupCategories?.state.match;
  const columnNameZip = rowMatchupCategories?.zip.match;

  const rowValues = data.bubbleItems?.[spreadsheetId];

  return {
    address: columnNameAddress ? rowValues?.[columnNameAddress]?.value : undefined,
    addressLine2: columnNameAddressLine2 ? rowValues?.[columnNameAddressLine2]?.value : undefined,
    city: columnNameCity ? rowValues?.[columnNameCity]?.value : undefined,
    country: columnNameCountry ? rowValues?.[columnNameCountry]?.value : undefined,
    state: columnNameState ? rowValues?.[columnNameState]?.value : undefined,
    zipCode: columnNameZip ? rowValues?.[columnNameZip]?.value : undefined,
  };
};
