export enum V4MapListingOrdering {
  Name_ASC = 'Name_ASC',
  Name_DESC = 'Name_DESC',
  Updated_DESC = 'Updated_DESC',
  Updated_ASC = 'Updated_ASC',
  Created_DESC = 'Created_DESC',
  Created_ASC = 'Created_ASC',
  Migrated_DESC = 'Migrated_DESC',
  Migrated_ASC = 'Migrated_ASC',
}
