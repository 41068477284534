import { useState } from 'react';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { useIsComponentMountedRef } from '../../../../../_shared/utils/hooks/useIsComponentMountedRef';
import { type ExportDataFileType } from '../../../../../data/exportDataModal/exportDataFileType.enum';
import { type BoundaryFilterBoundariesRequest } from '../../../../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterGroups } from '../../../../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBoundariesRequest,
  type SpreadsheetDataBulkFetchExtra,
  type SpreadsheetDataBulkRequest,
} from '../../../../../spreadsheet/spreadsheet.repository';
import { useSpreadsheetColumns } from '../../../../../store/matchupData/matchupDataSelectors.hook';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../../../store/selectors/useMapIdSelector';
import { createAreaRequestGetterFilter } from '../../../../../store/spreadsheetData/area/spreadsheetData.area.helpers';
import { DataType } from '../../../../../store/spreadsheetData/spreadsheetData.state';

type GetExportedBoundaryTerritoryGroupDataArguments = {
  spreadsheetId: number;
  boundariesFilter?: BoundaryFilterBoundariesRequest;
  boundaryTerritoriesFilter?: BoundaryTerritoryFilterGroups[];
  boundariesRequest?: SpreadsheetDataBoundariesRequest;
  exportAsText: boolean;
  extension: ExportDataFileType;
  useMainFilters: boolean;
};

export const useExportBoundaryTerritoryGroupData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const filterTreeParams = useFilterTreeMapSettingsParams();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const columns = useSpreadsheetColumns();
  const isMountedRef = useIsComponentMountedRef();

  const getExportedBoundaryTerritoryGroupData = ({
    spreadsheetId,
    boundariesFilter,
    boundaryTerritoriesFilter,
    boundariesRequest,
    exportAsText,
    extension,
    useMainFilters,
  }: GetExportedBoundaryTerritoryGroupDataArguments
  ) => {
    if (!clientId || !mapId || columns.length === 0) {
      return Promise.reject();
    }

    setIsLoading(true);
    setIsError(false);

    const filter = createAreaRequestGetterFilter(
      useMainFilters,
      'and',
      filterTreeParams,
      spreadsheetId,
      null,
      null,
      boundariesFilter,
      boundaryTerritoriesFilter,
    );

    const columnsToFetch = columns.reduce<{[columnId: string]: SpreadsheetDataBulkFetchExtra}>(
      (acc, column) => {
        acc[column.id] = {};

        return acc;
      }, {}
    );

    const request: SpreadsheetDataBulkRequest = {
      export: true,
      as_text: exportAsText ? true : undefined,
      extension,
      params: [{
        spreadsheet_id: spreadsheetId,
        boundaries: boundariesRequest,
        map_id: mapId,
        exclude_row_data: true,
        exclude_basic_data: true,
        filter,
        columns_to_fetch: {
          [DataType.TEXT]: columnsToFetch,
        },
      }],
    };

    return getSpreadsheetBulkData(clientId, request)
      .catch(error => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        throw error;
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    isError,
    getExportedBoundaryTerritoryGroupData,
  };
};
