import {
  useCallback, useMemo,
} from 'react';
import {
  Route, Routes, useLocation,
} from 'react-router-dom';
import { itemHeight } from '~/_shared/constants/mainMenuUI.constants';
import { copy } from '~/_shared/utils/collections/collections';
import { useWindowHeight } from '~/_shared/utils/hooks/useWindowHeight';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { checkMapCanBeShared } from '~/map/listing/mapListing.helpers';
import { SidebarContainer } from '~/sidebar/sidebar.container';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePrimaryGoogleSpreadsheetInfo } from '~/store/selectors/useMapInfoSelectors';
import { topBarHeaderHeight } from '~/topBar/desktopTopBar.styles';
import { LeftSidebar } from '../../maptiveApp/leftSidebar';
import { AppNavigationPaths } from './appNavigation.constants';

export const LeftSidebarRoutes = () => {
  const windowHeight = useWindowHeight();
  const location = useLocation();
  const isFromGoogleSheet = !!usePrimaryGoogleSpreadsheetInfo();
  const mapInfo = useMapInfoDataSelector();

  const getSizeSensitiveMenuPart = useCallback((defaultItems: ReadonlySet<MainMenuItem>, fewItems: ReadonlySet<MainMenuItem>) => {
    const isEnoughVerticalSpace = windowHeight - topBarHeaderHeight > defaultItems.size * itemHeight;

    return isEnoughVerticalSpace ? defaultItems : fewItems;
  }, [windowHeight]);

  const itemsVisibleOnDataPageBasedOnMapType = useMemo(() => {
    const layering = mapInfo?.layering;
    if (layering?.connected) {
      return itemsVisibleOnLayeredMapsDataPage;
    }
    else if (isFromGoogleSheet) {
      return getSizeSensitiveMenuPart(itemsVisibleOnDataPageMapFromGoogleSheet, itemsVisibleOnDataPageSmallMapFromGoogleSheet);
    }
    else if (layering) {
      return getSizeSensitiveMenuPart(itemsVisibleOnDataPageBase, itemsVisibleOnDataPageSmallBase);
    }
    else {
      return getSizeSensitiveMenuPart(itemsVisibleOnDataPage, itemsVisibleOnDataPageSmall);
    }
  }, [getSizeSensitiveMenuPart, isFromGoogleSheet, mapInfo?.layering]);

  const mapPageVisibleItems = useMemo(() => {
    if (!mapInfo || checkMapCanBeShared(mapInfo)) {
      return getSizeSensitiveMenuPart(itemsVisibleOnMapPage, itemsVisibleOnMapPageSmall);
    }

    return getSizeSensitiveMenuPart(
      copy.andRemove(itemsVisibleOnMapPage, [MainMenuItems.ShareMap]),
      copy.andRemove(itemsVisibleOnMapPageSmall, [MainMenuItems.ShareMap])
    );
  }, [getSizeSensitiveMenuPart, mapInfo]);

  return (
    <LeftSidebar>
      <Routes>
        <Route
          index
          element={(
            <SidebarContainer
              key={location.pathname}
              visibleMenuItems={itemsVisibleOnHomepage}
            />
          )}
        />
        <Route
          path={AppNavigationPaths.Map}
          element={(
            <SidebarContainer
              visibleMenuItems={mapPageVisibleItems}
              key={location.pathname}
            />
          )}
        />
        <Route
          path={AppNavigationPaths.Data}
          element={(
            <SidebarContainer
              visibleMenuItems={itemsVisibleOnDataPageBasedOnMapType}
              key={location.pathname}
            />
          )}
        />
        {/* fallback to hide sidebar */}
        <Route path="*" element={<></>} />
      </Routes>
    </LeftSidebar>
  );
};

const itemsVisibleOnHomepage: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.AccountSettings,
  MainMenuItems.Help,
  MainMenuItems.LogOut,
]);

const itemsVisibleOnMapPage: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.MapTools,
  MainMenuItems.MapSettings,
  MainMenuItems.SaveMapView,
  MainMenuItems.Present,
  MainMenuItems.ShareMap,
  MainMenuItems.SplitScreen,
  MainMenuItems.AccountSettings,
  MainMenuItems.Help,
  MainMenuItems.LogOut,
]);

const itemsVisibleOnMapPageSmall: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.MapTools,
  MainMenuItems.MapSettings,
  MainMenuItems.SaveMapView,
  MainMenuItems.Present,
  MainMenuItems.ShareMap,
  MainMenuItems.SplitScreen,
  MainMenuItems.Help,
  MainMenuItems.Account,
]);

const itemsVisibleOnDataPageBase: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.FilterData,
  MainMenuItems.ExportData,
  MainMenuItems.AddNewDataRow,
  MainMenuItems.AddNewDataColumn,
  MainMenuItems.RemoveDataRow,
  MainMenuItems.MatchMyLocationData,
  MainMenuItems.AccountSettings,
  MainMenuItems.Help,
  MainMenuItems.LogOut,
]);

const itemsVisibleOnDataPage: ReadonlySet<MainMenuItem> = new Set(
  [...itemsVisibleOnDataPageBase, MainMenuItems.ImportNewData],
);

const itemsVisibleOnDataPageMapFromGoogleSheet: ReadonlySet<MainMenuItem> = new Set(
  [...itemsVisibleOnDataPageBase, MainMenuItems.SyncData],
);

const itemsVisibleOnLayeredMapsDataPage: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.FilterData,
  MainMenuItems.ExportData,
  MainMenuItems.MatchMyLocationData,
  MainMenuItems.AccountSettings,
  MainMenuItems.Help,
  MainMenuItems.LogOut,
]);

const itemsVisibleOnDataPageSmallBase: ReadonlySet<MainMenuItem> = new Set([
  MainMenuItems.FilterData,
  MainMenuItems.ExportData,
  MainMenuItems.AddNewDataRow,
  MainMenuItems.AddNewDataColumn,
  MainMenuItems.RemoveDataRow,
  MainMenuItems.MatchMyLocationData,
  MainMenuItems.Help,
  MainMenuItems.Account,
]);

const itemsVisibleOnDataPageSmall: ReadonlySet<MainMenuItem> = new Set([
  ...itemsVisibleOnDataPageSmallBase, MainMenuItems.ImportNewData,
]);

const itemsVisibleOnDataPageSmallMapFromGoogleSheet: ReadonlySet<MainMenuItem> = new Set(
  [...itemsVisibleOnDataPageSmallBase, MainMenuItems.SyncData],
);
