import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { filterNonNumericBuckets } from '~/_shared/utils/grouping/grouping.helpers';
import {
  ID_OF_GROWING_NUMERIC_MARKER,
  ID_OF_NUMERICAL_MARKER,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import { RangeType } from '~/_shared/utils/range/range.helpers';
import {
  NON_NUMERICAL_COLOR,
  NON_NUMERICAL_VALUE,
  nonNumericalBucketWithRange,
  type NumericBucketWithRange,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { type GetMarkerVisualWithBakedDataProps } from '../useGroupColumnData';
import { NumericalGroupSettingsModalComponent } from './modal/numericalGroupSettingsModal.component';
import {
  type NumericalGroupSettingsDataRow,
  NumericalGroupSettingsNumericComponent,
} from './numeric/numericalGroupSettingsNumeric.component';
import { BucketMarkerVisualType } from './numeric/numericalGroupSettingsNumeric.helpers';

export type NumericalGroupSettingsResults = Readonly<{
  buckets: number[];
  isDecimal: boolean;
  markers: MarkerSettings[];
  valueType: RangeType;
}>;

export type NumericalGroupSettingsInitial = {
  numberOfGroups: number;
  valueType: RangeType;
  dataRows: NumericalGroupSettingsDataRow[];
};

type NumericalGroupSettingsProps = Readonly<{
  isDecimal: boolean;
  isOpen: boolean;
  markerVisualRadioOptions: ReadonlyArray<BucketMarkerVisualType>;
  max: number;
  min: number;

  getExistingGroupVisualsIfAny: (bucketId: number, numberOfBuckets: number) => MarkerSettings | null;
  getMarkerVisuals: (props: GetMarkerVisualWithBakedDataProps) => MarkerSettings;
  onClose: () => void;
  onSubmit: (results: NumericalGroupSettingsResults) => void;
  initialSettings?: NumericalGroupSettingsInitial;
}>;

/*
 * Note about markers and colors:
 * - The marker styles and colors can be taken from:
 *  - The initialSettings (if they exist) or from dataRows (highest priority)
 *  - The existing group visuals, retrieved by getExistingGroupVisualsIfAny (if they exist)
 *  - Default, retrieved via getMarkerVisuals, for example when double grouping was activated and it wasn't customized yet (lowest priority)
 * Because of this, and because this is handled accross two components, there are multiple places where style/color is calculated
 * TODO: needs refactor
*/
export const NumericalGroupSettingsComponent: FC<NumericalGroupSettingsProps> = (props) => {
  const { getMarkerVisuals, getExistingGroupVisualsIfAny, markerVisualRadioOptions } = props;

  const [restoreButtonClicked, setRestoreButtonClicked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [dataRows, setDataRows] = useState<NumericalGroupSettingsDataRow[]>([]);
  const [valueType, setValueType] = useState<RangeType>(RangeType.Value);
  const [bucketMarkerType, setBucketMarkerType] = useState<BucketMarkerVisualType | null>(null);

  const bucketsWithRange: NumericBucketWithRange[] = useMemo(() => [
    ...dataRows.map((row, index) => ({
      name: '',
      id: index,
      range: { from: +row.fromValue, to: +row.toValue },
    } as NumericBucketWithRange)),
    nonNumericalBucketWithRange,
  ], [dataRows]);

  const getBubbleOrPinVisuals = useCallback((markerVisualSettings: MarkerSettings): MarkerSettings => {
    if (!markerVisualSettings.marker) {
      return markerVisualSettings;
    }

    let markerSettingsOverride = {};

    if (bucketMarkerType === BucketMarkerVisualType.GrowingPin) {
      markerSettingsOverride = {
        styleId: ID_OF_GROWING_NUMERIC_MARKER,
        styleType: MarkerStyleType.STANDARD,
      };
    }

    if (bucketMarkerType === BucketMarkerVisualType.BlueBubble || bucketMarkerType === BucketMarkerVisualType.MultiColorBubble) {
      markerSettingsOverride = {
        styleId: ID_OF_NUMERICAL_MARKER,
        styleType: MarkerStyleType.STANDARD,
      };
    }

    return {
      ...markerVisualSettings,
      marker: {
        ...markerVisualSettings.marker,
        ...markerSettingsOverride,
        size: markerVisualSettings.marker.size,
      },
    };
  }, [bucketMarkerType]);

  const markerVisualSettings = useMemo(() => {
    return [
      ...dataRows.map((row, index) => {
        const visualSettings = getMarkerVisuals({
          bucketId: index,
          buckets: filterNonNumericBuckets(bucketsWithRange),
          opacity: row.color?.rgb.a ?? null,
          selectedColor: row.color?.hex ?? null,
        });

        return getBubbleOrPinVisuals(visualSettings);
      }),
      getMarkerVisuals({
        bucketId: NON_NUMERICAL_VALUE,
        buckets: bucketsWithRange,
        selectedColor: NON_NUMERICAL_COLOR,
        opacity: null,
      })];
  }, [bucketsWithRange, dataRows, getBubbleOrPinVisuals, getMarkerVisuals]);

  const onSubmitClick = () => {
    const buckets: number[] = dataRows.slice(1).map(row => +row.fromValue);

    props.onSubmit({
      markers: markerVisualSettings,
      isDecimal: props.isDecimal,
      buckets,
      valueType,
    });

    props.onClose();
  };

  return (
    <NumericalGroupSettingsModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      isSubmitDisabled={!isFormValid}
      onSubmitClick={onSubmitClick}
      onRestoreClick={() => setRestoreButtonClicked(true)}
    >
      <NumericalGroupSettingsNumericComponent
        initialSettings={props.initialSettings}
        isInitiallyDecimal={props.isDecimal}
        getExistingGroupVisualsIfAny={getExistingGroupVisualsIfAny}
        markerVisualRadioOptions={markerVisualRadioOptions}
        markerVisualSettings={markerVisualSettings}
        max={props.max}
        min={props.min}
        onDataRowsChange={setDataRows}
        onRangeTypeChange={setValueType}
        restoreButtonClicked={restoreButtonClicked}
        selectedMarkerVisualRadioOption={bucketMarkerType}
        setIsFormValid={setIsFormValid}
        setRestoreButtonClicked={setRestoreButtonClicked}
        setSelectedMarkerVisualRadioOption={setBucketMarkerType}
      />
    </NumericalGroupSettingsModalComponent>
  );
};
