import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type ThemeType } from '~/_shared/themes/themeType';
import { useClientIdSelector } from '../selectors/useClientIdSelector';
import { userSettingsThemeUpdate } from '../userData/settings/userDataSettings.actionCreators';
import { changeThemeType } from './theme.actionCreators';
import { useCurrentThemeSelector } from './theme.selectors';

export const useUpdateUserTheme = () => {
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();

  const themeChangeAllowed = !useCurrentThemeSelector().isForced;

  const updateUserTheme = useCallback((newTheme: ThemeType) => {
    if (!themeChangeAllowed) {
      return;
    }

    if (clientId) {
      dispatch(userSettingsThemeUpdate(clientId, newTheme));
    }
    dispatch(changeThemeType(newTheme));
  }, [clientId, dispatch, themeChangeAllowed]);

  return {
    updateUserTheme,
    themeChangeAllowed,
  };
};
