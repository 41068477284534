import {
  forwardRef, useCallback, useState,
} from 'react';
import { useStorageService } from '../../../utils/storageService';
import {
  getPresetColors, storePresetColor,
} from '../colorPicker.helpers';
import {
  ColorPickerHolderComponent, type ColorPickerHolderMethods,
  type ColorPickerHolderProps as ColorPickerHolderComponentProps,
} from './colorPickerHolder.component';

type ColorPickerHolderProps = Omit<ColorPickerHolderComponentProps, 'presetColors'>;

export const ColorPickerHolderWithPresetColorsContainer = forwardRef<ColorPickerHolderMethods, ColorPickerHolderProps>(({
  alignment,
  children,
  className,
  contentStyle,
  displayAlpha,
  displayNumericalColorsRepresentation,
  editable = true,
  isFixed,
  onChange,
  onClose,
  onOpen,
  selectedColor,
  ...restProps
}, ref) => {
  const storageService = useStorageService();
  const [presetColors, setPresetColors] = useState(getPresetColors(storageService));

  const handleSaveNewPresetColor = useCallback(() => {
    if (selectedColor) {
      storePresetColor(storageService, selectedColor.hex);
      setPresetColors(getPresetColors(storageService));
    }
    onClose?.();
  }, [storageService, onClose, selectedColor]);

  return (
    <ColorPickerHolderComponent
      ref={ref}
      {...restProps}
      alignment={alignment}
      className={className}
      contentStyle={contentStyle}
      displayAlpha={displayAlpha}
      displayNumericalColorsRepresentation={displayNumericalColorsRepresentation}
      editable={editable}
      isFixed={isFixed}
      onChange={onChange}
      onClose={handleSaveNewPresetColor}
      onOpen={onOpen}
      presetColors={presetColors}
      selectedColor={selectedColor}
    >
      {children}
    </ColorPickerHolderComponent>
  );
});
