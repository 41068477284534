import { ToggleSize } from './toggle.component';

export const animationLengthInMS = 200;
export const BORDER_WIDTH = 2;
export const PADDING_CIRCLE = 2;
export const PADDING_TEXT = 6;

export const getToggleHeight = (size?: ToggleSize) => {
  switch (size) {
    case ToggleSize.L:
      return 29;
    case ToggleSize.M:
    default:
      return 26;
  }
};

export const getToggleFontSize = (size?: ToggleSize) => {
  switch (size) {
    case ToggleSize.L:
      return 14;
    case ToggleSize.M:
    default:
      return 12;
  }
};

export const getSliderSize = (size?: ToggleSize) => {
  const height = getToggleHeight(size);
  return height - 2 * BORDER_WIDTH - 2 * PADDING_CIRCLE;
};

export const truncate = (label: string, n: number) => {
  return (label.length > n) ? label.slice(0, n) + '.' : label;
};
