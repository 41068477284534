import { type CallEffect } from '@redux-saga/core/effects';
import {
  all,
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { type CombinedRowId } from '../../../_shared/types/spreadsheetData/spreadsheetRow';
import { select } from '../../../_shared/utils/saga/effects';
import { realSpreadsheetRowIdToRealSpreadsheetAndRowId } from '../../../_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import { removeSpreadsheetRows } from '../../../spreadsheet/spreadsheet.repository';
import { type SpreadsheetTableAction } from './spreadsheetTable.action';
import {
  spreadsheetTableRemoveRowsRequest,
  spreadsheetTableSetIsRowsRemoveInProgress,
  spreadsheetTableSetSelectedRows,
} from './spreadsheetTable.actionCreators';
import {
  SPREADSHEET_TABLE_REMOVE_ROWS_REQUEST,
  SPREADSHEET_TABLE_REMOVE_SELECTED_ROWS_REQUEST,
} from './spreadsheetTable.actionTypes';
import { type SpreadsheetSelectedRow } from './spreadsheetTable.state';

export function* spreadsheetTableSagas() {
  yield takeLatest(SPREADSHEET_TABLE_REMOVE_ROWS_REQUEST, onSpreadsheetRowsRemoveRequest);
  yield takeLatest(SPREADSHEET_TABLE_REMOVE_SELECTED_ROWS_REQUEST, onSpreadsheetSelectedRowsRemoveRequest);
}

type GroupedRowIds = {
  [virtualSpreadsheetId: number]: {
    [realSpreadsheetId: string]: CombinedRowId[];
  };
};

function* onSpreadsheetSelectedRowsRemoveRequest() {
  const selectedRowsState: Record<CombinedRowId, SpreadsheetSelectedRow> =
    yield select<Record<CombinedRowId, SpreadsheetSelectedRow>>(
      state => state.frontendState.spreadsheetTable.selectedRows
    );

  const selectedRows: SpreadsheetSelectedRow[] = [];

  Object.keys(selectedRowsState).forEach(rowId => {
    selectedRows.push(selectedRowsState[rowId]);
  });

  yield put(spreadsheetTableRemoveRowsRequest(selectedRows));
}

function* onSpreadsheetRowsRemoveRequest(action: PickAction<SpreadsheetTableAction, typeof SPREADSHEET_TABLE_REMOVE_ROWS_REQUEST>) {
  const clientId: number | null = yield select<number | null>(
    state => state.userData.clientId,
  );

  if (action.payload.rowsToRemove.length === 0 || !clientId) {
    return;
  }

  const groupedRowIds: GroupedRowIds = {};

  for (const row of action.payload.rowsToRemove) {
    if (!groupedRowIds[row.virtualSpreadsheetId]) {
      groupedRowIds[row.virtualSpreadsheetId] = {};
    }

    const realSpreadsheetRowId = realSpreadsheetRowIdToRealSpreadsheetAndRowId(row.rowId)[0];
    if (!groupedRowIds[row.virtualSpreadsheetId][realSpreadsheetRowId]) {
      groupedRowIds[row.virtualSpreadsheetId][realSpreadsheetRowId] = [];
    }

    groupedRowIds[row.virtualSpreadsheetId][realSpreadsheetRowId].push(row.rowId);
  }

  yield put(spreadsheetTableSetIsRowsRemoveInProgress(true));

  try {
    const removeCalls: CallEffect[] = [];

    for (const spreadsheetIdString in groupedRowIds) {
      if (!groupedRowIds.hasOwnProperty(spreadsheetIdString)) {
        continue;
      }

      const virtualSpreadsheetId = +spreadsheetIdString;

      // TODO: find out if this splitting per real spreadsheet is still needed,
      // or this call accepts rows from multiple real spreadsheet ids
      Object.keys(groupedRowIds[virtualSpreadsheetId]).forEach(realSpreadsheetId => {
        removeCalls.push(
          call(removeSpreadsheetRows, clientId, virtualSpreadsheetId, {
            rows: groupedRowIds[virtualSpreadsheetId][realSpreadsheetId].map(rowId => ({
              row_id: rowId,
            })),
          })
        );
      });
    }
    yield all(removeCalls);
    yield put(spreadsheetTableSetSelectedRows({}));
    yield put(spreadsheetTableSetIsRowsRemoveInProgress(false));
    yield put(spreadsheetResetStateAndRefetchData());
  }
  catch (e) {
    yield put(spreadsheetTableSetIsRowsRemoveInProgress(false));
  }
}
