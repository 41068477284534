import { useEffect } from 'react';
import { usePrevious } from '../../../../../_shared/utils/hooks/usePrevious';
import { useCustomBoundaryGroupsSelector } from '../../../../../store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '../../../../../store/boundaryItems/boundaryItems.selectors';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type MapBoundaryManager } from '../../mapBoundary.manager';

export const useMapBoundaryRemove = (manager: MapBoundaryManager | undefined) => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const boundaryItems = useMapBoundariesSelector();
  const prevBoundaryItems = usePrevious(boundaryItems);

  useEffect(() => {
    if (!manager || !boundaryItems || !prevBoundaryItems || boundaryItems === prevBoundaryItems) {
      return;
    }

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const customBoundaryGroup = customBoundaryGroups.find(group => group.id === boundaryTerritoryGroup.boundaryGroupId);

      if (!customBoundaryGroup) {
        continue;
      }

      const prevGroupBoundaries = prevBoundaryItems.get(boundaryTerritoryGroup.boundaryGroupId);
      const groupBoundaries = boundaryItems.get(boundaryTerritoryGroup.boundaryGroupId);

      if (!prevGroupBoundaries || !groupBoundaries || groupBoundaries === prevGroupBoundaries) {
        continue;
      }

      prevGroupBoundaries.forEach((_, boundaryId) => {
        if (groupBoundaries.has(boundaryId)) {
          return;
        }

        manager.removeBoundary({
          boundaryId,
          boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId,
        });
      });
    }
  }, [manager, boundaryItems, prevBoundaryItems, customBoundaryGroups, boundaryTerritoryGroups]);
};
