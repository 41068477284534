import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { type Theme } from '~/_shared/themes/theme.model';
import { hexColorWithOpacityToString } from '../../components/colorPicker/colorPicker.helpers';

export const getAlternatingColor = (theme: Theme) => hexColorWithOpacityToString(theme.backgroundColors.secondaryHover, .5);

const alternatingStripesComponentStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.primary,
  '--oddLineColor': `var(--nestedOddLineColor, ${getAlternatingColor(theme)})`,
  '--pairLineColor': 'var(--nestedPairLineColor, transparent)',

  '& >:nth-of-type(2n +1)': {
    backgroundColor: 'var(--oddLineColor)',
    // Flip colors in odd rows so that nested lists start with a different color than the background color of this item.
    '--nestedOddLineColor': 'var(--pairLineColor)',
    '--nestedPairLineColor': 'var(--oddLineColor)',
  },
  '& >:nth-of-type(2n)': {
    backgroundColor: 'var(--pairLineColor)',
    '--nestedOddLineColor': 'var(--oddLineColor)',
    '--nestedPairLineColor': 'var(--pairLineColor)',
  },
});

type AlternatingStripesComponentProps = Readonly<{
  className?: string;
  children: ReactNode;
}>;

export const AlternatingStripesComponent: FC<AlternatingStripesComponentProps> = (props) => {
  return (
    <div
      className={props.className}
      css={alternatingStripesComponentStyle}
    >
      {props.children}
    </div>
  );
};
