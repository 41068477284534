import { Global } from '@emotion/react';
import type { FC } from 'react';
import { MaptiveApp } from '../maptiveApp/maptiveApp';
import { AppContextAndStoreWrapper } from './appContextAndStoreWrapper';
import { globalStyles } from './globalStyles';

export const AppRootComponent: FC = () => {
  return (
    <>
      <Global
        styles={globalStyles}
      />
      <AppContextAndStoreWrapper>
        <MaptiveApp />
      </AppContextAndStoreWrapper>
    </>
  );
};
