import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';

const appPageStyle = ({ theme }: ThemeProps) => css({
  flex: '1',
  display: 'flex',
  background: theme.backgroundColors.primary,
  overflow: 'hidden',
});

export const AppPageStylesWrapper = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <div css={appPageStyle({ theme })}>
      {children}
    </div>
  );
};
