import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { type UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  GroupRadiusEditLevel, ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import {
  isDriveTimePolygonSettings, isIndividualRadius, type Proximity, type ProximityModel,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type GeoLocation } from '~/_shared/utils/geolocation/geolocation';
import {
  getAddressFromLatLng, getLatLngFromAddress3rdPartyApi, getUserPosition,
} from '~/_shared/utils/geolocation/geolocation.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  setApplyTo, setBorderColor, setBorderLineWidth, setProximityColor, setProximityWithin, setSelectedGroup,
  setSelectedGroupColumn, setStartingLocation, setTravelTimeHours, setTravelTimeMinutes, setType, setUnit,
} from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { useProximityToolSelector } from '~/store/frontendState/proximityTool/proximityTool.selectors';
import {
  addProximity, setHideLabels, setHideSmallRadii,
} from '~/store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { type GroupData } from '~/store/spreadsheetData/spreadsheetData.state';
import { type ProximityRequestCreateObject } from '../../proximity.factory';
import { useZoomToProximity } from '../../useZoomToProximity';
import { ProximityPanelFormComponent } from './proximityPanelForm.component';
import { createProximityRequestFromPropsWithDefaults } from './proximityPanelForm.helpers';

const DEFAULT_PROXIMITY_BG_OPACITY = 0.35;
const DEFAULT_PROXIMITY_BORDER_OPACITY = 1;

type ProximityPanelContainerProps = {
  className?: string;
  showLabelsHiddenWarningDueToPerformance: boolean;
  showLabelsHiddenWarningDueToTooSmall: boolean;
  proximityList: ReadonlyArray<Proximity>;
  proximityType: ProximityType;
};

export const ProximityPanelFormContainer: FC<ProximityPanelContainerProps> = (props) => {
  const { proximityList } = props;

  const [address, setAddress] = useState('');
  const [isFetchingGeoLocation, setIsFetchingGeoLocation] = useState(false);
  const [isGeoError, setIsGeoError] = useState(false);
  const [wasKeyDown, setWasKeyDown] = useState(false);

  const isMapPresentational = useIsMapPresentationalSelector();
  const hideLabels = useSelector(state => state.map.mapSettings.data.proximity.hideLabels);
  const hideSmallRadii = useSelector(state => state.map.mapSettings.data.proximity.hideSmallRadii);
  const columns = useSpreadsheetColumns();
  const zoomToProximity = useZoomToProximity();
  const dispatch = useDispatch();

  const { applyTo, unit: proximityToolUnit, travelTimeHours, travelTimeMinutes, proximityWithin, selectedGroup,
    selectedGroupColumn, proximityColor, borderColor, borderLineWidth, startingLocation } = useProximityToolSelector();

  const proximityType = isMapPresentational ? ProximityType.DistanceRadius : props.proximityType;

  const onSetStartingLocation = useCallback((startingLocation: GeoLocation) => {
    dispatch(setStartingLocation(startingLocation));
    setWasKeyDown(false);
  }, [dispatch]);

  const onGetUserLocalizationClick = useCallback(() => {
    setIsFetchingGeoLocation(true);
    getUserPosition()
      .then(position => {
        getAddressFromLatLng(position.coords.latitude, position.coords.longitude)
          .then((response) => {
            setAddress(response.address);
            setIsFetchingGeoLocation(false);
            onSetStartingLocation(response);
          })
          .catch(() => {
            setIsFetchingGeoLocation(false);
            setIsGeoError(true);
          });
      })
      .catch(() => {
        setIsFetchingGeoLocation(false);
        setIsGeoError(true);
      });
  }, [onSetStartingLocation]);

  const onProximityAdd = useCallback((proximity: ProximityModel) => {
    if (isDriveTimePolygonSettings(proximity)) {
      dispatch(addProximity(proximity, true));
    }
    else {
      dispatch(addProximity(proximity));
      if (isIndividualRadius(proximity)) {
        zoomToProximity.zoomToRadius(proximity);
      }
    }
  }, [dispatch, zoomToProximity]);

  const addProximityRadius = useCallback(() => {
    const innerSelectedGroupColumn = columns.find(column => (
      column.id === selectedGroupColumn?.columnId
      && column.spreadsheetId === selectedGroupColumn?.spreadsheetId
    ));

    const proximityRequest: ProximityRequestCreateObject = {
      type: proximityType,
      applyTo,
      unit: proximityToolUnit,
      startingLocation,
      distance: proximityWithin,
      borderColor: borderColor.hex,
      borderWidth: borderLineWidth,
      borderOpacity: DEFAULT_PROXIMITY_BORDER_OPACITY,
      selectedGroupColumn: innerSelectedGroupColumn,
      fillColor: proximityColor.hex,
      fillOpacity: DEFAULT_PROXIMITY_BG_OPACITY,
      selectedGroup,
      travelTimeMinutes,
      travelTimeHours,
    };

    if (wasKeyDown && applyTo !== GroupRadiusEditLevel.Group) {
      setIsFetchingGeoLocation(true);
      getLatLngFromAddress3rdPartyApi(address)
        .then((response) => {
          if (!response[0]) {
            setIsGeoError(true);
            return;
          }

          setAddress(response[0].address);
          onSetStartingLocation(response[0]);

          proximityRequest.startingLocation = response[0];
          const newProximity = createProximityRequestFromPropsWithDefaults(proximityRequest);

          onProximityAdd(newProximity);
        })
        .catch(() => {
          setIsGeoError(true);
        })
        .finally(() => {
          setIsFetchingGeoLocation(false);
        });
    }
    else {
      const newProximity = createProximityRequestFromPropsWithDefaults(proximityRequest);
      onProximityAdd(newProximity);
    }
  }, [address, applyTo, columns, onProximityAdd, onSetStartingLocation, proximityColor.hex, proximityToolUnit,
    proximityWithin, selectedGroup, selectedGroupColumn?.columnId, selectedGroupColumn?.spreadsheetId, startingLocation,
    travelTimeHours, travelTimeMinutes, proximityType, wasKeyDown, borderColor, borderLineWidth]);

  const onAddressChange = useCallback((address: string) => {
    setAddress(address);
    setWasKeyDown(true);
  }, []);

  const onSetHideLabels = useCallback((hideLabels: boolean) =>
    dispatch(setHideLabels(hideLabels)), [dispatch]);

  const onSetHideSmallRadii = useCallback((hideSmallRadii: boolean) =>
    dispatch(setHideSmallRadii(hideSmallRadii)), [dispatch]);

  const onSetType = useCallback((type: ProximityType) => {
    dispatch(setType(type));
  }, [dispatch]);

  const onSetApplyTo = useCallback((applyTo: GroupRadiusEditLevel) =>
    dispatch(setApplyTo(applyTo)), [dispatch]);

  const onSetUnit = useCallback((unit: UnitSystem) =>
    dispatch(setUnit(unit)), [dispatch]);

  const onSetTravelTimeHours = useCallback((travelTimeHours: number | null) =>
    dispatch(setTravelTimeHours(travelTimeHours)), [dispatch]);
  const onSetTravelTimeMinutes = useCallback((travelTimeMinutes: number | null) =>
    dispatch(setTravelTimeMinutes(travelTimeMinutes)), [dispatch]);

  const onSetProximityWithin = useCallback((proximityWithin: number | null) =>
    dispatch(setProximityWithin(proximityWithin)), [dispatch]);

  const onSetSelectedGroup = useCallback((selectedGroup: GroupData | null) =>
    dispatch(setSelectedGroup(selectedGroup)), [dispatch]);

  const onSetSelectedGroupColumn = useCallback((selectedGroupColumnId: SpreadsheetColumnId) =>
    dispatch(setSelectedGroupColumn(selectedGroupColumnId)), [dispatch]);

  const onSetProximityColor = useCallback((proximityColor: ColorResult) =>
    dispatch(setProximityColor(proximityColor)), [dispatch]);

  const onSetBorderColor = useCallback((borderColor: ColorResult) =>
    dispatch(setBorderColor(borderColor)), [dispatch]);

  const onSetBorderLineWidth = useCallback((borderLineWidth: number) =>
    dispatch(setBorderLineWidth(borderLineWidth)), [dispatch]);

  useEffect(() => {
    setIsGeoError(false);
  }, [startingLocation]);

  useEffect(() => {
    if (startingLocation.address) {
      setAddress(startingLocation.address);
    }
  }, [startingLocation]);

  return (
    <ProximityPanelFormComponent
      {...props}
      addProximityRadius={addProximityRadius}
      address={address}
      applyTo={applyTo}
      hideLabels={hideLabels}
      hideSmallRadii={hideSmallRadii}
      isFetchingGeoLocation={isFetchingGeoLocation}
      isGeoError={isGeoError}
      onAddressChange={onAddressChange}
      onGetUserLocalizationClick={onGetUserLocalizationClick}
      onSetApplyTo={onSetApplyTo}
      onSetHideLabels={onSetHideLabels}
      onSetHideSmallRadii={onSetHideSmallRadii}
      onSetProximityColor={onSetProximityColor}
      onSetBorderColor={onSetBorderColor}
      onSetBorderLineWidth={onSetBorderLineWidth}
      onSetProximityWithin={onSetProximityWithin}
      onSetSelectedGroup={onSetSelectedGroup}
      onSetSelectedGroupColumn={onSetSelectedGroupColumn}
      onSetTravelTimeHours={onSetTravelTimeHours}
      onSetTravelTimeMinutes={onSetTravelTimeMinutes}
      onSetType={!isMapPresentational ? onSetType : undefined}
      onSetUnit={onSetUnit}
      proximityColor={proximityColor}
      borderColor={borderColor}
      borderLineWidth={borderLineWidth}
      proximityList={proximityList}
      proximityWithin={proximityWithin}
      selectedGroup={selectedGroup}
      selectedGroupColumnId={selectedGroupColumn}
      spreadsheetColumns={columns}
      travelTimeHours={travelTimeHours}
      travelTimeMinutes={travelTimeMinutes}
      type={proximityType}
      unit={proximityToolUnit}
    />
  );
};
