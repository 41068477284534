import {
  useCallback, useEffect, useState,
} from 'react';
import { type SpreadsheetRow } from '~/_shared/components/spreadsheet/spreadsheet.types';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { useAjaxCall } from '../../_shared/utils/hooks/useAjaxCall';
import {
  getExportedSpreadsheetData, type SpreadsheetDataExportRequest,
} from '../../spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';

export const useSpreadsheetExportedData = () => {
  const [data, setData] = useState<SpreadsheetRow[]>([]);
  const clientId = useClientIdSelector();

  const exportDataCall = useCallback((spreadsheetId: number, request: SpreadsheetDataExportRequest): Promise<SpreadsheetRow[]> => {
    if (!clientId) {
      return Promise.reject('Missing clientId');
    }

    return getExportedSpreadsheetData(
      clientId,
      spreadsheetId,
      request,
    ).then(response => {
      return response.data.list.map(readOnlyItem => {
        const { row_id: rowId, ...item } = readOnlyItem;

        const rowValues = Object.entries(item).map(([columnId, value]) => {
          return {
            columnId,
            value,
          };
        });

        const baseMapIdStr = rowValues.find(({ columnId, value }) =>
          (columnId === BASE_MAPS_COLUMN_ID && value),
        )?.value;
        const baseMapId = baseMapIdStr ? parseInt(baseMapIdStr, 10) : null;

        return {
          baseMapId,
          values: rowValues,
          rowId: rowId ?? '',
          virtualSpreadsheetId: spreadsheetId,
        };
      });
    });
  }, [clientId]);
  const { isLoading, error, data: initialData, invokeAjax } = useAjaxCall(exportDataCall);

  useEffect(() => {
    setData(initialData ?? []);
  }, [initialData]);

  return {
    isLoading,
    error,
    initialData,
    data,
    getExportedData: invokeAjax,
    onDataChange: setData,
  };
};
