import {
  type GroupingColumn, GroupingType,
} from '~/_shared/types/grouping/grouping';

export const isGroupPrimary = (hierarchyIndicator?: number): boolean => {
  return (hierarchyIndicator ?? 0) === 0;
};

export const isGroupLabelCustomizationDisabled = (groupColumn: GroupingColumn, hierarchyIndicator?: number) =>
  groupColumn.type === GroupingType.Numeric || !isGroupPrimary(hierarchyIndicator);
