import { useCallback } from 'react';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsWMSEnabledSelector } from '~/store/userData/userData.selectors';

export const useCreateBoundaryGroupFromAIWithSubscriptionCheck = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const isWMSEnabled = useIsWMSEnabledSelector();
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.CreateBoundaryGroupFromAI);

  const createBoundaryGroupFromAI = useCallback(() => {
    if (isWMSEnabled) {
      openModal({});
      return;
    }

    openConfirmationModal({
      title: t('Auto Territory Builder - trial'),
      text: (
        <div>
          {t('Auto Territory Builder requires a subscription which your account does not have active. You can try this tool with trial limitations:')}

          <ul>
            <li>{t('Generated territories are not permanent')}</li>
            <li>{t('Data export is disabled')}</li>
          </ul>
        </div>
      ),
      confirmCaption: t('Continue'),
      onConfirm: () => {
        closeConfirmationModal();
        openModal({});
      },
      permanentConfirmSettings: {
        id: 'WMS_TRIAL',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [t, isWMSEnabled, openModal, openConfirmationModal, closeConfirmationModal]);

  return {
    createBoundaryGroupFromAI,
  };
};
