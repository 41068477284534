import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import settingsMapPlaceholderImage from '~/_shared/../../../assets/images/boundarySettingsMapPlaceholder.svg';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  createColor, rgbColorToString,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  GroupRadiusEditLevel,
  ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import {
  isDriveTimePolygonSettings,
  isGroupRadius,
  isIndividualRadius,
  type Proximity,
  type RadiusData,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { changeColorAlpha } from '~/_shared/utils/colors/colors.helpers';
import {
  validateDecimalInputValue, validateValueHasOnlyNumericCharacters,
} from '~/_shared/utils/form/form.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  mapPlaceholderWrapperStyle,
  mapPromixityPreviewStyle,
  modalContentStyle,
  wrapperStyle,
} from '~/proximity/edit/proximityEdit.styles';
import { ProximityEditBodyComponent } from '~/proximity/edit/proximityEditBody.component';
import { validateDriveTimePolygonTime } from '../panel/form/proximityPanelForm.helpers';
import { getGroupRadiusProximityDetails } from '../proximity.helpers';

export type ProximityEditProperties = {
  borderLineColor: ColorResult;
  borderLineWidth: number;
  borderOpacity: number;
  distance?: number;
  fillColor: ColorResult;
  fillOpacity: number;
  hours?: number;
  minutes?: number;
  name: string;
  radiusApplyTo: GroupRadiusEditLevel;
  unit: UnitSystem;
};

export type ProximityEditProps = ModalProps<{
  proximity: Proximity;
  spreadsheetRowId?: SpreadsheetRowId;

  onSubmit: (args: ProximityEditProperties) => void;
}>;

export const ProximityEditComponent: FC<ProximityEditProps> = (props) => {
  const [t] = useTranslation();
  const [name, setName] = useState('');
  const [fillColor, setFillColor] = useState<ColorResult>(createColor('#FFF'));
  const [fillOpacity, setFillOpacity] = useState(0);
  const [proximityWithin, setProximityWithin] = useState<string | null>(null);
  const [hours, setHours] = useState<null | number>(null);
  const [minutes, setMinutes] = useState<null | number>(null);
  const [unit, setUnit] = useState<UnitSystem>(UnitSystem.imperial);
  const [borderLineColor, setBorderLineColor] = useState<ColorResult>(createColor('#FFF'));
  const [borderOpacity, setBorderOpacity] = useState(1);
  const [borderLineWidth, setBorderLineWidth] = useState(0);
  const [radiusApplyTo, setRadiusApplyTo] = useState<GroupRadiusEditLevel>(
    props.spreadsheetRowId ? GroupRadiusEditLevel.IndividualLocation : GroupRadiusEditLevel.Group
  );

  const onProximityWithinChange = (newProximityWithin: string) => {
    if (!validateValueHasOnlyNumericCharacters(newProximityWithin, { isDecimal: true })) {
      return;
    }

    setProximityWithin(newProximityWithin);
  };

  const changeFillColorOpacity = useCallback((alpha: readonly number[]) => {
    const newFillColor = changeColorAlpha(fillColor.rgb, (alpha[0] ?? 0) / 100);
    setFillColor(newFillColor);
    setFillOpacity((alpha[0] ?? 0) / 100);
  }, [fillColor.rgb]);

  const changeFillColor = useCallback((color: ColorResult) => {
    setFillColor(changeColorAlpha(color.rgb, fillOpacity));
  }, [fillOpacity]);

  const changeBorderLineOpacity = useCallback((alpha: readonly number[]) => {
    const newBorderLineColor = changeColorAlpha(borderLineColor.rgb, (alpha[0] ?? 0) / 100);
    setBorderLineColor(newBorderLineColor);
    setBorderOpacity((alpha[0] ?? 0) / 100);
  }, [borderLineColor.rgb]);

  const changeBorderLineColor = useCallback((color: ColorResult) => {
    setBorderLineColor(changeColorAlpha(color.rgb, borderOpacity));
  }, [borderOpacity]);

  const submitEdit = useCallback(() => {
    let distance: null | number = null;
    if (proximityWithin !== null) {
      distance = parseFloat(proximityWithin);
      if (isNaN(distance)) {
        throw Error('invalid distance value');
      }
    }

    const submit = props.onSubmit;
    submit({
      name,
      fillColor,
      fillOpacity,
      borderOpacity,
      distance: distance ?? undefined,
      unit,
      borderLineColor,
      borderLineWidth,
      radiusApplyTo: props.spreadsheetRowId ? radiusApplyTo : GroupRadiusEditLevel.Group,
      hours: hours ?? undefined,
      minutes: minutes ?? undefined,
    });
  }, [borderLineColor, borderLineWidth, borderOpacity, fillColor, fillOpacity, hours, minutes, name,
    props.onSubmit, props.spreadsheetRowId, proximityWithin, radiusApplyTo, unit,
  ]);

  useEffect(() => {
    let proximityName = props.proximity.name;
    let proximityStyle = props.proximity.styles;

    if (isIndividualRadius(props.proximity)) {
      setUnit(props.proximity.data.unit);
      setProximityWithin(props.proximity.data.radius.toString());
    }

    if (isGroupRadius(props.proximity)) {
      const groupRadiusDetails = getGroupRadiusProximityDetails(props.proximity, props.spreadsheetRowId);

      proximityName = groupRadiusDetails.name;
      proximityStyle = groupRadiusDetails.styles;
      setUnit(groupRadiusDetails.unit);
      setProximityWithin(groupRadiusDetails.radius.toString());
    }

    setName(proximityName);
    setFillColor(
      changeColorAlpha(createColor(proximityStyle.color).rgb, proximityStyle.fillOpacity)
    );
    setBorderLineColor(
      changeColorAlpha(createColor(proximityStyle.borderColor).rgb, proximityStyle.borderOpacity)
    );
    setBorderLineWidth(proximityStyle.borderWidth);
    setFillOpacity(proximityStyle.fillOpacity);
    setBorderOpacity(proximityStyle.borderOpacity);

    if (isDriveTimePolygonSettings(props.proximity)) {
      setHours(props.proximity.data.hours);
      setMinutes(props.proximity.data.minutes);
    }
  }, [props.proximity, props.spreadsheetRowId]);

  let isFormValid = !isTextEmpty(name);

  if (props.proximity.type === ProximityType.DriveTimePolygon && !validateDriveTimePolygonTime(hours, minutes)) {
    isFormValid = false;
  }

  if (
    props.proximity.type === ProximityType.DistanceRadius &&
    ((props.proximity.data as RadiusData).applyTo === GroupRadiusEditLevel.IndividualLocation) &&
    (proximityWithin === null || !validateDecimalInputValue(proximityWithin))
  ) {
    isFormValid = false;
  }

  const isRadiusGroup = props.proximity.type === ProximityType.DistanceRadius
    && ((props.proximity.data as RadiusData).applyTo === GroupRadiusEditLevel.Group)
    && props.spreadsheetRowId;

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={t(props.proximity.type === ProximityType.DriveTimePolygon ? 'Edit Drive Time Polygon' : 'Edit Radius')}
      contentStyle={modalContentStyle}
      confirmButton={(
        <ButtonComponent
          isDisabled={!isFormValid}
          prefixIcon={faCheck}
          text={t('Save')}
          onClick={submitEdit}
        />
      )}
    >
      <div css={mapPlaceholderWrapperStyle}>
        <img
          src={settingsMapPlaceholderImage}
          alt={t('Map Placeholder')}
        />
        <div
          css={mapPromixityPreviewStyle({
            bgColor: rgbColorToString(fillColor.rgb),
            borderColor: rgbColorToString(borderLineColor.rgb),
            borderWidth: `${borderLineWidth}px`,
          })}
        />
      </div>
      <div css={wrapperStyle}>
        <ProximityEditBodyComponent
          setUnit={setUnit}
          setMinutes={setMinutes}
          borderLineColor={borderLineColor}
          borderLineWidth={borderLineWidth}
          borderOpacity={borderOpacity}
          distance={proximityWithin ?? undefined}
          fillColor={fillColor}
          fillOpacity={fillOpacity}
          hours={hours || undefined}
          minutes={minutes || undefined}
          name={name}
          radiusApplyTo={radiusApplyTo}
          unit={unit}
          setBorderLineOpacity={changeBorderLineOpacity}
          setBorderLineColor={changeBorderLineColor}
          setBorderLineWidth={setBorderLineWidth}
          setDistance={props.proximity.type === ProximityType.DistanceRadius ? onProximityWithinChange : undefined}
          setRadiusApplyTo={isRadiusGroup ? setRadiusApplyTo : undefined}
          setHours={props.proximity.type === ProximityType.DriveTimePolygon ? setHours : undefined}
          setFillColor={changeFillColor}
          setFillOpacity={changeFillColorOpacity}
          setName={setName}
        />
      </div>
    </ModalComponent>
  );
};
