import {
  useEffect, useMemo, useState,
} from 'react';
import { FetchableResourceStatus } from '~/_shared/types/fetchableResource/fetchableResource';
import { delay } from '~/_shared/utils/delay';
import { useDebounce } from '~/_shared/utils/hooks/useDebounce';
import { useSpreadsheetCellDataSelector } from '~/store/spreadsheetCellData/spreadsheetCellData.selectors';
import { useSelector } from '../../_shared/utils/hooks/useSelector';

(window as any).isFullyLoaded = false;

export const isExportImageMapReady = () => !!(window as any).isFullyLoaded;

/*
 * The purpose of this component is to make sure that map is ready for taking screenshot
 * That means that all the data needs to be loaded, all markers need to be visible, etc.
 */
export const useIsMapFullyInitialized = () => {
  const initialMarkersRendered = useSelector(s => s.frontendState.processing.initialMarkersRendered);
  const initialBoundariesRendered = useSelector(s => s.frontendState.processing.initialBoundariesRendered);
  const cachedSpreadsheetCellData = useSpreadsheetCellDataSelector();

  const isSpreadsheetCellDataLoaded = useMemo(() => !(
    Object.values(cachedSpreadsheetCellData).some((perRowSpreadsheetData) =>
      Array.from(perRowSpreadsheetData?.values() || []).some(rowData => (
        rowData.status === FetchableResourceStatus.Loading
        || Object.values(rowData.value?.columnsData || {}).some(columnData => columnData?.status === FetchableResourceStatus.Loading)
      ))
    )
  ), [cachedSpreadsheetCellData]);

  const allItemsInitialized = initialMarkersRendered && initialBoundariesRendered && isSpreadsheetCellDataLoaded;

  const [isLoaded, setIsLoaded] = useState(false);

  const setIsLoadedDebounced = useDebounce(() => {
    setIsLoaded(true);

    //let's give it a couple of more seconds for stability, in case something is not fully rendered yet
    delay(3000).then(() => (window as any).isFullyLoaded = true);
  }, 2000);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    if (allItemsInitialized) {
      setIsLoadedDebounced();
    }
    else {
      return;
    }
  }, [allItemsInitialized, isLoaded, setIsLoadedDebounced]);
};
