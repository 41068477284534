import { css } from '@emotion/react';
import {
  forwardRef, useMemo,
} from 'react';
import { type QuadrilateralDimensions } from '../../types/coordinateSystem/coordinateSystem';
import {
  guaranteeHash, hexColorWithOpacityToString,
} from '../colorPicker/colorPicker.helpers';
import {
  getLabelPaddingsValues, initialLabelVisualizerArrowProps, initialLabelVisualizerBodyProps,
  initialLabelVisualizerBorderProps,
} from './labelVisualizer.constants';
import {
  type LabelVisualizerArrowProps, type LabelVisualizerBodyProps, type LabelVisualizerBorderProps,
} from './labelVisualizer.types';

const wrapperStyle = css({
  position: 'relative',
});

const arrowStyle = ({ borderColor, height, halfWidth, leftIndent, bottomInset }: {
  borderColor: string;
  height: number;
  leftIndent: string;
  halfWidth: number;
  bottomInset: number;
}) => css({
  borderLeft: `${halfWidth}px solid transparent`,
  borderRight: `${halfWidth}px solid transparent`,
  borderTop: `${height}px solid ${borderColor}`,
  content: '""',
  display: 'block',
  height: '0',
  left: leftIndent,
  position: 'absolute',
  bottom: bottomInset - height,
  width: '0',
});

const bodyStyle = ({ bodyProps, arrowProps, borderProps, maxDimensions, isTextOnly }: {
  arrowProps: LabelVisualizerArrowProps;
  bodyProps: LabelVisualizerBodyProps;
  borderProps: LabelVisualizerBorderProps;
  maxDimensions?: QuadrilateralDimensions;
  isTextOnly: boolean;
}) => {
  const paddings = getLabelPaddingsValues(bodyProps);
  const { bottom, left, right, top } = paddings;
  const arrowWidth = arrowProps.dimensions?.width || 0;
  const arrowHeight = arrowProps.dimensions?.height || 0;
  const arrowHalfWidth = arrowWidth / 2;
  const arrowLeftDistance = `calc(50% - ${arrowWidth / 2}px)`;
  return css({
    position: isTextOnly ? 'absolute' : 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isTextOnly ? 'transparent ' : hexColorWithOpacityToString(bodyProps.backgroundColor.selectedColor, 1),
    opacity: isTextOnly ? 1 : bodyProps.backgroundColor.opacity,
    border: `${borderProps.width}px solid ${isTextOnly ? 'transparent' : guaranteeHash(borderProps.color.selectedColor)}`,
    borderRadius: borderProps.radius,
    boxSizing: 'border-box',
    color: isTextOnly ? guaranteeHash(bodyProps.fontColor.selectedColor) : 'transparent',
    fontSize: bodyProps.fontSize,
    left: 0,
    minHeight: bodyProps.dimensions?.height,
    paddingTop: top,
    paddingBottom: bottom,
    paddingLeft: left,
    paddingRight: right,
    top: 0,
    zIndex: isTextOnly ? 1 : 0,
    ...(bodyProps.dimensions?.width && { minWidth: bodyProps.dimensions?.width }),
    ...(maxDimensions && { maxHeight: maxDimensions.height, maxWidth: maxDimensions.width }),
    marginBottom: arrowHeight,

    //this is the bigger triangle representing the arrow-border
    '&:before': arrowStyle({
      leftIndent: `calc(${arrowLeftDistance} - ${borderProps.width}px)`,
      halfWidth: arrowHalfWidth + borderProps.width,
      height: arrowHeight + borderProps.width,
      borderColor: isTextOnly ? 'transparent' : guaranteeHash(borderProps.color.selectedColor),
      bottomInset: 0,
    }),

    //this is the smaller triangle representing the arrow fill itself
    '&:after': arrowStyle({
      leftIndent: `calc(${arrowLeftDistance} - ${borderProps.width}px)`,
      halfWidth: arrowHalfWidth + borderProps.width,
      height: arrowHeight + borderProps.width,
      borderColor: isTextOnly ? 'transparent' : guaranteeHash(bodyProps.backgroundColor.selectedColor),
      bottomInset: Math.sqrt(2) * borderProps.width,
    }),
  });
};

type LabelVisualizerProps = Readonly<{
  arrowProps?: Partial<LabelVisualizerArrowProps>;
  bodyProps?: Partial<LabelVisualizerBodyProps>;
  borderProps?: Partial<LabelVisualizerBorderProps>;
  label?: string;
  maxDimensions?: QuadrilateralDimensions;
}>;

export const LabelVisualizerComponent = forwardRef<HTMLDivElement, LabelVisualizerProps>((props, ref) => {
  const initialBodyProps = initialLabelVisualizerBodyProps;
  const initialArrowProps = initialLabelVisualizerArrowProps;
  const initialBorderProps = initialLabelVisualizerBorderProps;

  const { maxDimensions } = props;

  const bodyProps: LabelVisualizerBodyProps = useMemo(() => ({
    ...initialBodyProps,
    ...props.bodyProps,
    padding: {
      ...initialBodyProps.padding,
      ...(props.bodyProps && props.bodyProps.padding),
    },
    dimensions: {
      ...initialBodyProps.dimensions,
      ...(props.bodyProps && props.bodyProps.dimensions),
    },
  }), [initialBodyProps, props.bodyProps]);

  const arrowProps: LabelVisualizerArrowProps = useMemo(() => ({
    ...initialArrowProps,
    ...props.arrowProps,
    dimensions: {
      ...initialArrowProps.dimensions,
      ...(props.arrowProps && props.arrowProps.dimensions),
    },
  }), [initialArrowProps, props.arrowProps]);

  const borderProps: LabelVisualizerBorderProps = useMemo(() => ({
    ...initialBorderProps,
    ...props.borderProps,
  }), [initialBorderProps, props.borderProps]);

  return (
    <div css={wrapperStyle} ref={ref}>
      <div css={bodyStyle({ bodyProps, arrowProps, borderProps, maxDimensions, isTextOnly: false })}>
        {props.label}
      </div>
      {props.label && (
        <div css={bodyStyle({ bodyProps, arrowProps, borderProps, maxDimensions, isTextOnly: true })}>
          {props.label}
        </div>
      )}
    </div>
  );
});

LabelVisualizerComponent.displayName = 'LabelVisualizerComponent';
