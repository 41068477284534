export const FRONTEND_STATE_LASSO_START = 'FRONTEND_STATE_LASSO_START';
export const FRONTEND_STATE_LASSO_DRAWING_FINISHED = 'FRONTEND_STATE_LASSO_DRAWING_FINISHED';
export const FRONTEND_STATE_LASSO_STOP = 'FRONTEND_STATE_LASSO_STOP';
export const FRONTEND_STATE_ROUTING_LASSO_START = 'FRONTEND_STATE_ROUTING_LASSO_START';

export const FRONTEND_STATE_LASSO_OUTLINE_HOVER = 'FRONTEND_STATE_LASSO_OUTLINE_HOVER';
export const FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT = 'FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT';

export const FRONTEND_STATE_LASSO_POLYGON_HOVER = 'FRONTEND_STATE_LASSO_POLYGON_HOVER';
export const FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT = 'FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT';

export const FRONTEND_STATE_LASSO_REMOVE_HOVER = 'FRONTEND_STATE_LASSO_REMOVE_HOVER';
export const FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT = 'FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT';
