import { useEffect } from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useMap } from '../../mapContext';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { type ArrowPoints } from './drawingToolArrow.types';

type DrawingToolScalingArrowProps = Readonly<{
  arrowPoints: ArrowPoints;
  scalesWithMapZoom: boolean;
  onStaticLengthChange: (newStaticLength: number) => void;
}>;

export const useDrawingToolArrowScalingToggle = (
  props: DrawingToolScalingArrowProps,
) => {
  const { arrowPoints, scalesWithMapZoom, onStaticLengthChange } = props;
  const prevScalesWithMapZoom = usePrevious(scalesWithMapZoom);
  const map = useMap();
  const { getDistanceBetweenPoints } = useProjectionOverlay(map);

  // recalculate the arrow static length when switching from scalesWithMapZoom to !scaledWithMapZoom
  useEffect(() => {
    if (prevScalesWithMapZoom === undefined || scalesWithMapZoom || scalesWithMapZoom === prevScalesWithMapZoom) {
      return;
    }

    const newStaticLength = getDistanceBetweenPoints(arrowPoints.tipPoint, arrowPoints.tailPoint);

    if (newStaticLength === null) {
      return;
    }

    onStaticLengthChange(newStaticLength);
  }, [onStaticLengthChange, getDistanceBetweenPoints, arrowPoints.tipPoint, arrowPoints.tailPoint, prevScalesWithMapZoom, scalesWithMapZoom]);
};
