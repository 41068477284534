import {
  useEffect,
  useState,
} from 'react';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import {
  getSpreadsheetBulkData,
  type SpreadsheetColumnsToFetchNumberResponse,
  type SpreadsheetDataBulkRequestGetter,
} from '~/spreadsheet/spreadsheet.repository';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';

export type ColumnConstraints = {
  [columnId: string]: {
    name: string;
    min: number;
    max: number;
    numericPercent: number;
  };
};

export const useGetColumnsConstraints = (spreadsheetId: number | null) => {
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsComponentMountedRef();
  const matchupColumns = useSpreadsheetColumns();
  const [columnsConstraints, setColumnsConstraints] = useState<ColumnConstraints>({});
  const [rowsCount, setRowsCount] = useState(0);

  useEffect(() => {
    if (spreadsheetId) {
      if (clientId === null || mapId === null) {
        console.error(`Either clientId (${clientId}) or spreadsheetId (${spreadsheetId}) is null when checking column is numeric.`);
      }
      else {
        const params: SpreadsheetDataBulkRequestGetter[] = [{
          map_id: mapId,
          spreadsheet_id: spreadsheetId,
          exclude_basic_data: true,
          exclude_row_data: true,
          columns_to_fetch: {
            [DataType.NUMBER]: Object.fromEntries(
              matchupColumns
                .filter(column => column.id !== BASE_MAPS_COLUMN_ID)
                .map((column) => ([
                  column.id, {
                    include_constraints: {},
                  },
                ]))
            ),
          },
        }];

        setIsLoading(true);
        getSpreadsheetBulkData(clientId, { params })
          .then(response => {
            setColumnsConstraints(
              Object.fromEntries(matchupColumns
                .map(column => ({ id: column.id, details: response?.data?.[0]?.result?.columns_to_fetch?.[DataType.NUMBER]?.[column.id] }))
                .filter((column): column is { id: string; details: SpreadsheetColumnsToFetchNumberResponse } => {
                  const { details } = column;
                  return !!details && typeof details.min === 'number' && typeof details.max === 'number' && details.min <= details.max;
                })
                .map(column => [
                  column.id,
                  {
                    name: matchupColumns.find(c => c.id === column.id)?.name ?? '',
                    min: column.details.constraints?.min ?? 0,
                    max: column.details.constraints?.max ?? 0,
                    numericPercent: column.details.numeric_and_empty_percent,
                  },
                ])
              )
            );
            setRowsCount(response?.data?.[0]?.result?.order_list?.length ?? 0);
          })
          .finally(() => {
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          });
      }
    }
  }, [spreadsheetId, clientId, isMountedRef, mapId, matchupColumns]);

  return {
    isLoading,
    columnsConstraints,
    rowsCount,
  };
};
