import {
  useCallback, useMemo,
} from 'react';
import {
  getAppleMapsNavigateToAddressLink, getGoogleMapsNavigateToAddressLink,
} from '~/_shared/utils/link/gisLink';
import { createRightSidebarContextMenuCallbackProps } from '~/customizeLocationPanel/roundButtonMenu/contextMenuStructure/editLocationCallbacks.helpers';
import { useProcessMarkersForRouting } from '~/map/map/lassoTool/optimizeRouteBetweenPoints/useProcessMarkersForRouting';
import { type RowsPerSpreadsheet } from '~/map/map/lassoTool/rowsPerSpreadsheet';
import { useDrawRadiusOnMarker } from '~/map/map/proximity/useDrawRadiusOnMarker';
import { useActiveMarkerIdSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

export const useToolLocationCallbacks = (locationAddress: string | null) => {
  const activeMarkerId = useActiveMarkerIdSelector();
  const { processSelectedData } = useProcessMarkersForRouting();
  const { addMarkerToProximityTool } = useDrawRadiusOnMarker();
  const isMapPresentational = useIsMapPresentationalSelector();
  const { routingDirections: isRoutingAllowed, radiusProximity: isProximityAllowed } = usePublicMapSettingsSelector();

  const navigate = useCallback(() => {
    if (locationAddress) {
      window.location.href = getGoogleMapsNavigateToAddressLink(locationAddress);
    }
  }, [locationAddress]);

  const navigateApple = useCallback(() => {
    if (locationAddress) {
      window.location.href = getAppleMapsNavigateToAddressLink(locationAddress);
    }
  }, [locationAddress]);

  const addToRouteCallback = useCallback(
    () => {
      if (!activeMarkerId?.spreadsheetId || !activeMarkerId?.rowId) {
        return;
      }
      const rowsPerSpreadsheets: RowsPerSpreadsheet[] = [
        {
          spreadsheetId: activeMarkerId.spreadsheetId,
          rows: [{
            id: activeMarkerId.rowId,
          }],
        },
      ];
      processSelectedData(rowsPerSpreadsheets);
    },
    [processSelectedData, activeMarkerId],
  );

  const drawRadiusCallback = useCallback(() => {
    if (!activeMarkerId?.spreadsheetId || !activeMarkerId?.rowId) {
      return;
    }
    addMarkerToProximityTool(activeMarkerId);

  }, [addMarkerToProximityTool, activeMarkerId]);

  return useMemo(() => ({
    addToRouteCallback: (!isMapPresentational || isRoutingAllowed) ? addToRouteCallback : undefined,
    drawRadiusCallback: (!isMapPresentational || isProximityAllowed) ? drawRadiusCallback : undefined,
    navigate: locationAddress ? createRightSidebarContextMenuCallbackProps({ callback: navigate }) : undefined,
    navigateApple: locationAddress ? createRightSidebarContextMenuCallbackProps({ callback: navigateApple }) : undefined,
  }), [addToRouteCallback, drawRadiusCallback, isMapPresentational, isProximityAllowed, isRoutingAllowed, locationAddress, navigate, navigateApple]);
};
