import { useCallback } from 'react';
import type { MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type BoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type AppState } from '../app.store';
import { CUSTOM_BOUNDARY_ZOOM_LEVEL } from './boundaryItems.state';

const BOUNDARY_EDIT_OUTLINES_LIMIT = 500;

type ComplexBoundaryReason = { isComplex: false; reason?: undefined } | { isComplex: true; reason: string };

export const mapBoundariesSelector = (state: AppState) => state.boundaries.items.boundaries;
export const useMapBoundariesSelector = () => useSelector(mapBoundariesSelector);

const mapBoundariesIsLoadingSelector = (state: AppState) => state.boundaries.items.isLoading;
export const useMapBoundariesIsLoadingSelector = () => useSelector(mapBoundariesIsLoadingSelector);

export const useIsBoundaryComplexForManualEdit = () => {
  const [t] = useTranslation();
  const boundaries = useMapBoundariesSelector();

  const isBoundaryComplexForManualEdit = useCallback((id: BoundaryIdentifier): ComplexBoundaryReason => {
    const boundary = boundaries.get(id.boundaryGroupId)?.get(id.boundaryId);
    const multiPolygon = boundary?.paths.get(CUSTOM_BOUNDARY_ZOOM_LEVEL);

    if (!multiPolygon) {
      return { isComplex: false };
    }

    const reason = getBoundaryComplexReason(multiPolygon, t);

    return notNullsy(reason) ? { isComplex: true, reason } : { isComplex: false };
  }, [boundaries, t]);

  return { isBoundaryComplexForManualEdit };
};

const getBoundaryComplexReason = (multiPolygon: MultiPolygon, t: TranslationFnc) => {
  if (multiPolygon.find(polygon => polygon.holes.length)) {
    return t('boundaryEdit.complexBoundaryReason.holes');
  }

  const totalOutlineCount = multiPolygon.reduce((agg, polygon) => agg + polygon.path.length, 0);
  if (totalOutlineCount > BOUNDARY_EDIT_OUTLINES_LIMIT) {
    return t('boundaryEdit.complexBoundaryReason.outlineCount', { count: totalOutlineCount, limit: BOUNDARY_EDIT_OUTLINES_LIMIT });
  }

  return null;
};
