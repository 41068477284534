import { useMemo } from 'react';
import {
  dummyMapObjectZindex, type MapObjectZIndex,
} from '~/map/map/mapObjects/mapObject.types';
import { useZIndexSeries } from './useZIndexSeries.hook';
import { ZIndexedEntity } from './zIndexRanges';

export const mapPolygonBaseEntities = [
  'polygon', 'polygonBorder', 'polygonLabelBackground', 'polygonLabelText', 'circleArea', 'customMarkerBackground', 'arrow',
] as const;

export const mapPolygonPriorityEntities = [
  'line', 'customMarker', 'outlineCircle', 'transparentOutlineDot', 'outlineDot', 'outlineLabel', 'labelCallout', 'labelText', 'removeButton',
] as const;

const idMapper = (entity: string) => entity;

const useMapPolygonInstanceZIndex = (instanceId?: Uuid): MapObjectZIndex => {
  const orderedEntities = useMemo(() => instanceId ? [instanceId] : [], [instanceId]);

  const baseEntities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapPolygonBaseEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.MapPolygonBaseEntities,
  }), [orderedEntities]).subEntityZIndexes[0];

  const priorityEntities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapPolygonPriorityEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.MapPolygonPriorityEntities,
  }), [orderedEntities]).subEntityZIndexes[0];

  return useMemo(() => (baseEntities && priorityEntities) ? {
    ...baseEntities,
    ...priorityEntities,
  } : dummyMapObjectZindex,
  [baseEntities, priorityEntities]);
};

export const useDistanceCalculatorInstanceZIndex = (instanceId?: Uuid): MapObjectZIndex => useMapPolygonInstanceZIndex(instanceId);
export const useLassoToolPolygonZIndex = (instanceId?: Uuid): MapObjectZIndex => useMapPolygonInstanceZIndex(instanceId);
