import { type RightSidebarOpeneableApps } from './rightSidebarConfiguration';
import {
  BASIC_PANEL_WIDTH, rightSidebarSizePerOpenedApp,
} from './rightSidebarContent.constants';

export const decideSidebarWidth = (isOpen: boolean, openedApps: Set<RightSidebarOpeneableApps>): number => {
  if (!isOpen) {
    return 0;
  }

  let appsWidth = 0;
  openedApps.forEach(app => appsWidth += rightSidebarSizePerOpenedApp[app]);

  return appsWidth + BASIC_PANEL_WIDTH;
};
