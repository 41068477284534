import { css } from '@emotion/react';
import { type Theme } from '../../../themes/theme.model';
import { type ThemeProps } from '../../../types/themeProps';
import {
  type ButtonSize,
  ButtonStyle, type ButtonStyleProps,
} from './button.types';

const primaryStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.primaryBackground,
  textColor: theme.buttonColors.primaryText,
  backgroundHoverColor: theme.buttonColors.primaryBackgroundOnHover,
  borderHoverColor: theme.buttonColors.primaryBorderOnHover,
  borderColor: theme.buttonColors.primaryBorder,
});

const tertiaryButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.tertiaryBackground,
  textColor: theme.buttonColors.tertiaryText,
  backgroundHoverColor: theme.buttonColors.primaryBackgroundOnHover,
  textHoverColor: theme.buttonColors.tertiaryActiveText,
  borderColor: theme.buttonColors.tertiaryBorder,
  borderHoverColor: theme.buttonColors.tertiaryBorderOnHover,
});

const successButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.successBackground,
  textColor: theme.buttonColors.successText,
  backgroundHoverColor: theme.buttonColors.successActiveBackground,
  borderHoverColor: theme.buttonColors.successBorderOnHover,
  borderColor: theme.buttonColors.successBorder,
});

const dangerButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.dangerBackground,
  textColor: theme.buttonColors.dangerText,
  backgroundHoverColor: theme.buttonColors.dangerBackgroundHover,
  borderHoverColor: theme.buttonColors.dangerBorderOnHover,
});

const dangerInvertedButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: 'none',
  textColor: theme.buttonColors.dangerBackground,
  borderColor: theme.buttonColors.dangerBackground,
  backgroundHoverColor: theme.buttonColors.dangerBackgroundHover,
  textHoverColor: theme.buttonColors.dangerText,
  borderHoverColor: theme.buttonColors.dangerBorderOnHover,
});

const secondaryButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.secondaryBackground,
  textColor: theme.buttonColors.secondaryText,
  backgroundHoverColor: theme.buttonColors.secondaryBackgroundHover,
  borderHoverColor: theme.buttonColors.secondaryBorderOnHover,
});

const customStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.primaryBackground,
  backgroundHoverColor: theme.buttonColors.primaryBackgroundOnHover,
  textColor: theme.buttonColors.primaryText,
});

const quaternaryButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.buttonColors.quaternaryBackground,
  backgroundHoverColor: theme.buttonColors.quaternaryBackgroundOnHover,
  borderColor: theme.buttonColors.quaternaryBorder,
  borderHoverColor: theme.buttonColors.quaternaryBorderOnHover,
  textColor: theme.buttonColors.quaternaryText,
  textHoverColor: theme.buttonColors.quaternaryActiveText,
});

export const ButtonStyles = (theme: Theme): { readonly [key in ButtonStyle]: ButtonStyleProps} => ({
  [ButtonStyle.Custom]: customStyle(theme),
  [ButtonStyle.Primary]: primaryStyle(theme),
  [ButtonStyle.Secondary]: secondaryButtonStyle(theme),
  [ButtonStyle.Tertiary]: tertiaryButtonStyle(theme),
  [ButtonStyle.Quaternary]: quaternaryButtonStyle(theme),
  [ButtonStyle.Success]: successButtonStyle(theme),
  [ButtonStyle.Danger]: dangerButtonStyle(theme),
  [ButtonStyle.DangerInverted]: dangerInvertedButtonStyle(theme),
});

const computeButtonPadding = ({ size }: { size: ButtonSize }) => {
  switch (size) {
    case 'Small':
      return '4px 8px';
    case 'Large':
      return '12px 8px';
    case 'Medium':
    default:
      return 8;
  }
};
const computeButtonFontSize = ({ size }: { size: ButtonSize }) => {
  switch (size) {
    case 'Small':
      return '14px';
    case 'Large':
      return '20px';
    case 'Medium':
    default:
      return '16px';
  }
};

export const buttonStyle = ({
  btnStyle, isDisabled, theme, isActive, size, uppercase,
}: ThemeProps<{
  btnStyle: ButtonStyleProps;
  isDisabled?: boolean;
  isActive?: boolean;
  size: ButtonSize;
  uppercase?: boolean;
}>) => {
  const activeStyles = {
    boxShadow: !isDisabled ? `${btnStyle.borderHoverColor} 0px 0px 6px 2px` : undefined,
  };

  return css({
    background: btnStyle.backgroundColor,
    color: btnStyle.textColor,
    opacity: isDisabled ? .5 : 1,
    fontWeight: 500,
    border: `2px solid ${btnStyle.borderColor || theme.buttonColors.defaultBorder}`,
    borderRadius: 4,
    padding: computeButtonPadding({ size }),
    fontSize: computeButtonFontSize({ size }),
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    transition: 'color .2s, background .2s, border .2s, boxShadow .2s',
    textTransform: uppercase ? 'uppercase' : undefined,
    ...(isActive ? activeStyles : undefined),
    '&:active': activeStyles,
    '&:hover': {
      background: !isDisabled ? btnStyle.backgroundHoverColor : undefined,
      color: !isDisabled ? btnStyle.textHoverColor : undefined,
      border: !isDisabled ? `2px solid ${btnStyle.borderHoverColor}` : undefined,
    },
  });
};
