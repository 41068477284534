import {
  getLast, notEmpty,
} from '~/_shared/utils/array/array.helpers';
import { sortAlphanumerically } from '../../_shared/utils/sort';
import { type BoundaryGroup } from '../../boundary/boundary.types';
import { type BoundaryStateItems } from './boundaryItems.state';

export const getBoundaryRequestMapZoom = (currentMapZoom: number, boundaryGroupId: number, boundaryGroups: ReadonlyArray<BoundaryGroup>): number => {
  const boundaryGroup = boundaryGroups.find(group => group.id === boundaryGroupId);

  if (!boundaryGroup) {
    return currentMapZoom;
  }

  return getRequestMapZoomFromZoomLevels(currentMapZoom, boundaryGroup.zoomLevels);
};

export const getRequestMapZoomFromZoomLevels = (currentMapZoom: number, zoomLevels: number[]): number => {
  const sortedZoomLevels = [...zoomLevels].sort((a, b) => a - b);

  if (!notEmpty(sortedZoomLevels)) {
    return currentMapZoom;
  }

  const filteredLevels = sortedZoomLevels.filter(zoom => zoom <= currentMapZoom);

  if (notEmpty(filteredLevels)) {
    return getLast(filteredLevels);
  }
  else {
    return sortedZoomLevels[0];
  }
};

export const hasZoomLevelDetailChanged = (boundaryGroup: BoundaryGroup, zoomLevel: number | null, previousZoomLevel?: number | null) => {
  if (!boundaryGroup) {
    return false;
  }

  if (!!zoomLevel && !previousZoomLevel) {
    return true;
  }
  else if (!previousZoomLevel || !zoomLevel) {
    return false;
  }

  const previousDetailLevel = getRequestMapZoomFromZoomLevels(previousZoomLevel, boundaryGroup.zoomLevels);
  const currentDetailLevel = getRequestMapZoomFromZoomLevels(zoomLevel, boundaryGroup.zoomLevels);

  return previousDetailLevel !== currentDetailLevel;
};

export const hasBoundaryItemChanged = (boundaryGroupId: number, currentItems: BoundaryStateItems, previousItems?: BoundaryStateItems) => {
  const current = currentItems.get(boundaryGroupId);
  const previous = previousItems?.get(boundaryGroupId);

  return !!current && current !== previous;
};

type HasDisplayName = Readonly<{ displayName: string }>;
export const displayNameComparer = (a: HasDisplayName, b: HasDisplayName) => sortAlphanumerically(a.displayName, b.displayName);
