import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemPolygon } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import { MapShapePolygon } from '../../mapObjects/mapShape/mapShapePolygon.component';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';

type MapPolygonInstanceProps = {
  instance: DrawingItemPolygon;
};

const DrawingToolPolygonInstanceContainer: FC<MapPolygonInstanceProps> = ({ instance }) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut: onPolygonMouseOut, onMouseOver: onPolygonMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const onPolygonClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Polygon,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  const onPolygonRightClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Polygon,
      value: instance,
    });
  }, [onRightClick, instance, drawingEventsEnabledRef]);

  const renderPolygon = useCallback(() => {
    const fillColor = convertColorToWebGLColor(instance.settings.fillColor, instance.settings.fillOpacity / 100);
    const borderColor = convertColorToWebGLColor(instance.settings.strokeColor, instance.settings.strokeOpacity / 100);

    return (
      <MapShapePolygon
        visuals={{
          fillColor,
          borderWidth: instance.settings.strokeWeight,
          borderColor,
        }}
        onClick={onPolygonClick}
        onRightClick={onPolygonRightClick}
        onMouseOver={onPolygonMouseOver}
        onMouseOut={onPolygonMouseOut}
      />
    );
  }, [instance.settings.fillColor, instance.settings.fillOpacity, onPolygonClick, onPolygonRightClick, onPolygonMouseOver,
    onPolygonMouseOut, instance.settings.strokeWeight, instance.settings.strokeOpacity, instance.settings.strokeColor]);

  return (
    <MapShape
      shape={instance}
      zIndex={zIndex}
      renderPolygon={renderPolygon}
      isPolygon
    />
  );
};

const pureComponent = memo(DrawingToolPolygonInstanceContainer);
export { pureComponent as DrawingToolPolygonInstanceContainer };
