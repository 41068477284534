import { type FC } from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '../scrollbar/scrollbar.component';
import { TopUnderbarItemComponent } from './item/topUnderbarItem.component';
import { type TopUnderbarItem } from './item/topUnderbarItem.types';

type TopUnderbarProps = Readonly<{
  items: TopUnderbarItem[];
  isMobileVersion?: boolean;
  maximumHeight?: number;
}>;

export const TopUnderbarComponent: FC<TopUnderbarProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }

  return (
    <>
      {props.items.filter(item => item.sticky).map(item => (
        <TopUnderbarItemComponent
          key={item.key}
          item={item}
          isMobileVersion={props.isMobileVersion}
        />
      ))}
      {!!props.items.filter(item => !item.sticky).length && (
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
          contentStyle={{
            display: 'block',
            minWidth: 'none',
          }}
          translateContentHeightToHolder
          maxHeight={props.maximumHeight || 210}
        >
          {props.items.filter(item => !item.sticky).map(item => (
            <TopUnderbarItemComponent
              key={item.key}
              item={item}
              isMobileVersion={props.isMobileVersion}
            />
          ))}
        </ScrollBarComponent>
      )}
    </>
  );
};
