import { css } from '@emotion/react';
import { type FC } from 'react';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { noop } from '../../../../../_shared/utils/function.helpers';
import {
  s, Trans,
} from '../../../../../translations/Trans';
import { type HandledLayeredMap } from './handleBaseMapDeletionModal.container';
import {
  getResolutionByMapId, type HandleBaseMapDeletionModalHelper, setResolution,
} from './handleBaseMapDeletionModal.helper';
import { ResolutionComponent } from './resolution.component';

type HandleBaseMapDeletionItemProps = Readonly<{
  map: HandledLayeredMap;
  onSelectedResolutionsChange: (selectedResolutions: HandleBaseMapDeletionModalHelper) => void;
  selectedResolutions: HandleBaseMapDeletionModalHelper;
}>;

const mapItemStyle = (theme: Theme) => css({
  display: 'flex',
  padding: '4px 32px',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:last-of-type': {
    border: 'none',
  },
});

const mapNameStyle = css({
  fontSize: 18,
  flex: '0 0 200px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '22px',
});

const resolutionOptionsWrapper = css({
  marginLeft: 'auto',
});

const hintStyle = (theme: Theme) => css({
  color: theme.iconColors.primary,
});

const tooltipContentStyle = css({
  maxWidth: 500,
});

const tooltipTextStyle = css({
  margin: '0 0 20px',
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const HandleBaseMapDeletionItemComponent: FC<HandleBaseMapDeletionItemProps> = (props) => {
  const { map, onSelectedResolutionsChange, selectedResolutions } = props;

  return (
    <Inline gap={12} css={mapItemStyle}>
      <div
        css={mapNameStyle}
        title={map.name}
      >
        {map.name}
      </div>
      <div css={resolutionOptionsWrapper}>
        {map.previousResolution ? (
          <ResolutionComponent
            isDisabled
            onSelect={noop}
            selectedOption={map.previousResolution}
            resolutionOptions={map.resolutionOptions}
          />
        ) : (
          <ResolutionComponent
            isDisabled={false}
            onSelect={(option => {
              onSelectedResolutionsChange(setResolution(selectedResolutions, map.id, option));
            })}
            selectedOption={getResolutionByMapId(selectedResolutions, map.id)}
            resolutionOptions={map.resolutionOptions}
          />
        )}
      </div>

      <HintTooltipComponent
        css={hintStyle}
        size={25}
        placement={TooltipPlacement.Left}
        tooltipContent={(
          <div css={tooltipContentStyle}>
            <p css={tooltipTextStyle}>
              <Trans i18nKey="A <1>Layered Map</1> is composed of several base maps - it consists of the union of the data of the base maps." >
                {s('A ')}
                <strong>Layered Map</strong>
                {s(' is composed of several base maps - it consists of the union of the data of the base maps.')}
              </Trans>
            </p>

            <p css={tooltipTextStyle}>
              <Trans i18nKey="<0>Delete Layered Map:</0> By completing this action, the base map will be deleted. However, please be aware that the layered map in which the base map was used will also be deleted as a result. If you have any questions or need assistance, feel free to contact our support team for help." >
                <strong>Delete Layered Map:</strong>
                {s(' By completing this action, the base map will be deleted. However, please be aware that the layered map in which the base map was used will also be deleted as a result. If you have any questions or need assistance, feel free to contact our support team for help.')}
              </Trans>
            </p>

            <p css={tooltipTextStyle}>
              <Trans i18nKey="<0>Make Map Disconnected:</0> will copy the spreadsheet data from all the single base maps composing the layered map into one new spreadsheet.  From now on, the layered map  look the same but the source of the markers are different - the data will not get updated based on the original source." >
                <strong>Make Map Disconnected:</strong>
                {s(' will copy the spreadsheet data from all the single base maps composing the layered map into one new spreadsheet.  From now on, the layered map  look the same but the source of the markers are different - the data will not get updated based on the original source.')}
              </Trans>
            </p>

            <p css={tooltipTextStyle}>
              <Trans i18nKey="<0>Delete layer:</0> will remove the current base map from the layered map. This means the markers associated with this base map will not be shown in the layered map anymore." >
                <strong>Delete layer:</strong>
                {s(' will remove the current base map from the layered map. This means the markers associated with this base map will not be shown in the layered map anymore.')}
              </Trans>
            </p>
          </div>
        )}
      />
    </Inline>
  );
};
