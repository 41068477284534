import {
  compare, type Operation,
} from 'fast-json-patch';
import {
  call,
  delay,
  put, race,
  take, takeLatest,
} from 'redux-saga/effects';
import {
  userDataIsReadySelector, userDataSettingsSelector,
} from '~/store/userData/settings/userDataSettings.selectors';
import { select } from '../../../_shared/utils/saga/effects';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import {
  USER_DATA_SETTING_READY_SET, USER_GET_DATA_SUCCESS,
} from '../userData.actionTypes';
import { type UserDataSettingsAction } from './userDataSettings.action';
import {
  userSettingsSyncRequest, userSettingsSyncRequestError, userSettingsSyncRequestSuccess,
} from './userDataSettings.actionCreators';
import { USER_SETTINGS_SYNC_REQUEST } from './userDataSettings.actionTypes';
import { getPathForUserDataSettingsTree } from './userDataSettings.helpers';
import { userDataSettingsUpdateSetting } from './userDataSettings.repository';
import { type UserSettingsState } from './userDataSettings.state';

let userDataSettingsResponse: UserSettingsState = {};

export function* userDataSettingsSagas() {
  yield takeLatest(USER_SETTINGS_SYNC_REQUEST, syncUserDataSettings);
  yield takeLatest(USER_GET_DATA_SUCCESS, watchUserDataSettingsDiff);
}

export function* syncUserDataSettings(action: PickAction<UserDataSettingsAction, typeof USER_SETTINGS_SYNC_REQUEST>) {
  try {
    const mappedDiff: Operation[] = getPathForUserDataSettingsTree(action.payload.diff);

    if (mappedDiff.length === 0) {
      yield put(userSettingsSyncRequestError());
      return;
    }

    yield call(userDataSettingsUpdateSetting, mappedDiff.map(item => JSON.stringify(item)));
    yield put(userSettingsSyncRequestSuccess());
    userDataSettingsResponse = yield select(userDataSettingsSelector);
  }
  catch (e) {
    yield put(userSettingsSyncRequestError());
  }
}

export function* watchUserDataSettingsDiff() {
  while (true) {
    let isUserDataSettingsReady: boolean = yield select(userDataIsReadySelector);

    while (!isUserDataSettingsReady) {
      yield take(USER_DATA_SETTING_READY_SET);
      isUserDataSettingsReady = yield select(userDataIsReadySelector);
    }

    const action: { type: string } = yield take('*');

    if (!action.type.startsWith('USER/SETTINGS_UPDATE_')) {
      continue;
    }

    while (true) {
      const { debounced } = yield race({
        debounced: delay(1000),
        latestAction: take(action.type), // debounce for latest taken action like MAP_SETTINGS_MARKERS_TOGGLE
      });

      if (debounced) {
        break;
      }
    }

    isUserDataSettingsReady = yield select(userDataIsReadySelector);

    if (!isUserDataSettingsReady) {
      continue;
    }

    const nextState: UserSettingsState = yield select(userDataSettingsSelector);
    const diff = compare(userDataSettingsResponse, nextState);
    if (diff.length > 0) {
      yield put(userSettingsSyncRequest(diff));
    }
  }
}
