import {
  call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  type GeocodingEventBroadcastData, type GeocodingStartedEventBroadcastData,
  registerGeocodingEventBroadcastListener,
} from '../../_shared/utils/api/broadcast.helpers';
import { noop } from '../../_shared/utils/function.helpers';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { appStore } from '../app.store';
import { mapInfoUpdateBadDataCount } from '../mapInfo/mapInfo.actionCreators';
import { USER_WEB_SOCKET_BROADCAST_INITIALIZED } from '../userData/userData.actionTypes';
import { type GeocodingAction } from './geocoding.action';
import {
  geocodingPauseError,
  geocodingPauseSuccess,
  geocodingProcessingSuccess,
  geocodingResumeError,
  geocodingResumeSuccess,
  geocodingSetIsPauseResumeLoading,
  geocodingSetProgress,
  geocodingStartTracking,
} from './geocoding.actionCreators';
import {
  GEOCODING_PAUSE_REQUEST, GEOCODING_RESUME_REQUEST,
} from './geocoding.actionTypes';
import {
  pauseGeocoding, startGeocoding,
} from './geocoding.repository';

export function* geocodingSagas() {
  yield takeLatest(USER_WEB_SOCKET_BROADCAST_INITIALIZED, registerGeocodingSocketEventsProcessor);
  yield takeEvery(GEOCODING_PAUSE_REQUEST, onGeocodingPauseRequest);
  yield takeEvery(GEOCODING_RESUME_REQUEST, onGeocodingResumeRequest);
}

function* geocodingChangeConcurrencyPreventionWrapper(realSpreadsheetId: number, change: Generator) {
  const isChangeInProgress: boolean | undefined = yield select(
    state => state.spreadsheet.geocoding.perSpreadsheetGeocodingRequest[realSpreadsheetId]?.paused.changeRequestSent
  );

  if (isChangeInProgress) {
    return;
  }

  yield put(geocodingSetIsPauseResumeLoading(realSpreadsheetId));
  yield* change;

  return;
}

function* pause(action: PickAction<GeocodingAction, typeof GEOCODING_PAUSE_REQUEST>) {
  const realSpreadsheetId = action.payload.realSpreadsheetId;
  try {
    yield call(pauseGeocoding, action.payload.clientId, realSpreadsheetId);
    yield put(geocodingPauseSuccess(realSpreadsheetId));
  }
  catch (e) {
    yield put(geocodingPauseError(realSpreadsheetId));
  }
}

function* resume(action: PickAction<GeocodingAction, typeof GEOCODING_RESUME_REQUEST>) {
  const realSpreadsheetId = action.payload.realSpreadsheetId;
  try {
    yield call(startGeocoding, action.payload.clientId, realSpreadsheetId);
    yield put(geocodingResumeSuccess(realSpreadsheetId));
  }
  catch (e) {
    yield put(geocodingResumeError(realSpreadsheetId));
  }
}

function* onGeocodingPauseRequest(action: PickAction<GeocodingAction, typeof GEOCODING_PAUSE_REQUEST>) {
  yield* geocodingChangeConcurrencyPreventionWrapper(action.payload.realSpreadsheetId, pause(action));
}

function* onGeocodingResumeRequest(action: PickAction<GeocodingAction, typeof GEOCODING_RESUME_REQUEST>) {
  yield* geocodingChangeConcurrencyPreventionWrapper(action.payload.realSpreadsheetId, resume(action));
}

function startTrackingProgress(event: GeocodingStartedEventBroadcastData) {
  appStore.dispatch(geocodingStartTracking(event.realSpreadsheetId, event.userId, event.percentage_progress));
}

function updateProgress(event: GeocodingEventBroadcastData) {
  appStore.dispatch(geocodingSetProgress({
    [event.real_spreadsheet_id]: {
      realSpreadsheetId: event.real_spreadsheet_id, progress: event.percentage, triggeredByUserId: event.user_id,
    },
  }));

  if (event.percentage === 100) {
    appStore.dispatch(geocodingProcessingSuccess(new Set([event.real_spreadsheet_id])));
  }

  appStore.dispatch(mapInfoUpdateBadDataCount(event.virtual_id, event.counters.processed.failed));
}

function registerGeocodingSocketEventsProcessor() {
  registerGeocodingEventBroadcastListener({
    onEvent: updateProgress,
    onStarted: startTrackingProgress,
    onError: noop,
  });
}
