import Intercom, {
  onUnreadCountChange, show,
  shutdown, update,
} from '@intercom/messenger-js-sdk';
import {
  useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { CONFIG } from '~/_shared/constants/config';
import {
  convertStringDateToUnixTimestamp, getUserNameForIntercom,
} from '~/_shared/utils/3rdPartyIntegrations/intercom/utils';
import { useUserDataSelector } from '~/store/userData/userData.selectors';

/**
 * @restricted Only use this hook in a single instance component like {@link IntercomProvider}.
 */
export const useIntercomInternal = () => {
  const user = useUserDataSelector();
  const location = useLocation();
  const [isShown, setIsShown] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isBooted, setIsBooted] = useState(false);

  useEffect(() => {
    if (user.isSignedIn) {
      Intercom({
        app_id: CONFIG.INTERCOM_APP_ID,
        hide_default_launcher: true,
        onShow: () => {
          setIsShown(true);
        },
        onHide: (() => {
          setIsShown(false);
        }),

        email: user.email,
        name: getUserNameForIntercom(user.firstName, user.lastName),
        company: user.clientName,
        user_hash: user.intercomHash,
        created_at: convertStringDateToUnixTimestamp(user.createdAt),
      });
      onUnreadCountChange(setUnreadCount);
      setIsBooted(true);

      return () => {
        setIsBooted(false);
        shutdown();
      };
    }

    return undefined;
  }, [user]);

  useEffect(() => {
    if (isBooted) {
      // Update intercom when location changes.
      update({});
    }
  }, [isBooted, location]);

  return {
    isBooted,
    isInitialized: true,
    isShown,
    show,
    unreadCount,
  };
};
