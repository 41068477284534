import { SpreadsheetRowIdMap } from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import type { MarkersSelectAction } from './markersSelect.action';
import {
  MARKERS_SELECT_ACTIVATE,
  MARKERS_SELECT_ADD_MARKERS,
  MARKERS_SELECT_DEACTIVATE,
  MARKERS_SELECT_REMOVE_MARKERS,
  MARKERS_SELECT_RESET,
  MARKERS_SELECT_SAVE,
  MARKERS_SELECT_SET_LASSO_IS_LOADING,
  MARKERS_SELECT_SET_TYPE,
} from './markersSelect.actionTypes';
import type {
  MarkersSelectInactiveState, MarkersSelectState,
} from './markersSelect.state';
import {
  isMarkersSelectActive, MarkersSelectType,
} from './markersSelect.state';

export const defaultMarkersSelectOptions = {
  selectType: MarkersSelectType.Click,
  isLassoSelectLoading: false,
  hoveredBoundaryId: null,
} as const;

const initialState: MarkersSelectInactiveState = {
  isActive: false,
  lastSavedMarkerIds: null,
  selectedMarkerIds: null,
  ...defaultMarkersSelectOptions,
};

export const markersSelectReducer = (state: MarkersSelectState = initialState, action: MarkersSelectAction): MarkersSelectState => {
  switch (action.type) {

    case MARKERS_SELECT_DEACTIVATE: {
      return initialState;
    }

    case MARKERS_SELECT_SAVE: {
      return {
        ...initialState,
        lastSavedMarkerIds: state.selectedMarkerIds,
      };
    }

    case MARKERS_SELECT_ACTIVATE: {
      return {
        ...state,
        isActive: true,
        selectedMarkerIds: [],
      };
    }

    case MARKERS_SELECT_RESET: {
      return {
        ...state,
        isActive: true,
        selectedMarkerIds: [],
      };
    }

    case MARKERS_SELECT_SET_TYPE: {
      return {
        ...state,
        selectType: action.payload.selectType,
      };
    }

    case MARKERS_SELECT_SET_LASSO_IS_LOADING: {
      if (!state.isActive) {
        return state;
      }

      return {
        ...state,
        isLassoSelectLoading: action.payload.isLoading,
      };
    }

    case MARKERS_SELECT_ADD_MARKERS: {
      if (!isMarkersSelectActive(state)) {
        return state;
      }

      const uniqueMap = new SpreadsheetRowIdMap(
        state.selectedMarkerIds.concat(action.payload.markersIds).map(id => [id, id])
      );

      return {
        ...state,
        selectedMarkerIds: Array.from(uniqueMap.keys()),
      };
    }

    case MARKERS_SELECT_REMOVE_MARKERS: {
      if (!isMarkersSelectActive(state)) {
        return state;
      }

      const uniqueMap = new SpreadsheetRowIdMap(
        state.selectedMarkerIds.map(id => [id, id])
      );
      action.payload.markersIds.forEach(id => uniqueMap.delete(id));

      return {
        ...state,
        selectedMarkerIds: Array.from(uniqueMap.keys()),
      };
    }

    default:
      return state;
  }
};
