import { type FC } from 'react';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  s, Trans,
} from '~/translations/Trans';
import { AppPageStylesWrapper } from '../../appPageStylesWrapper.component';
import { ErrorPageContentGuardianDogComponent } from '../../errorPageContent/guardianDog';

export const AppErrorPagePresentationalMapUnavailable: FC = () => {
  const [t] = useTranslation();
  return (
    <AppPageStylesWrapper>
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        contentStyle={{
          height: '100%',
          display: 'block',
        }}
      >
        <ErrorPageContentGuardianDogComponent>
          <Trans i18nKey="MapTemporaryUnavailable" >
            {s('This map is temporarily unavailable, please contact the map owner or ')}
            <SupportLinkComponent
              type={SupportLinkType.info}
              subject={t('Public Map Unavailable')}
            />
            {s(' to resolve the issue.')}
          </Trans>
        </ErrorPageContentGuardianDogComponent>
      </ScrollBarComponent>
    </AppPageStylesWrapper>
  );
};
