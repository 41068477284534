import {
  type FC, useCallback,
  useMemo,
} from 'react';
import { SelectGroupComponent } from '~/_shared/components/selectGroup/selectGroup.component';
import { type UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { GroupRadiusEditLevel } from '~/_shared/types/proximity/proximity.enums';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GroupData } from '~/store/spreadsheetData/spreadsheetData.state';
import { ProximityPanelDropdownComponent } from '../inputs/proximityPanelDropdown.component';
import { ProximityPanelInputLabelComponent } from '../inputs/proximityPanelInputLabel.component';
import { createProximityApplyToDropdownOption } from '../proximityPanelForm.helpers';
import { UnitDropdownComponent } from './unitDropdown.component';

type GroupRadiusFormFieldsProps = Readonly<{
  applyTo: GroupRadiusEditLevel;
  columns: ReadonlyArray<SpreadsheetColumn>;
  proximityWithin: string;
  selectedColumnId: SpreadsheetColumnId | null;
  selectedGroup: GroupData | null;
  unit: UnitSystem;

  onChangeApplyTo: (applyTo: GroupRadiusEditLevel) => void;
  onChangeProximityWithin: (proximityWithin: string) => void;
  onChangeSelectedColumnId: (columnId: SpreadsheetColumnId) => void;
  onChangeSelectedGroup: (group: GroupData | null) => void;
  onUnitChange: (unit: UnitSystem) => void;
}>;

export const GroupRadiusFormFieldsComponent: FC<GroupRadiusFormFieldsProps> = props => {
  const [t] = useTranslation();
  const applyToDropdownOptions = useMemo(() => Object.values(GroupRadiusEditLevel).map(v => createProximityApplyToDropdownOption(v, t)), [t]);
  const convertColumnToColumnId = useCallback((column: SpreadsheetColumn) => ({
    columnId: column.id,
    spreadsheetId: column.spreadsheetId,
  }), []);
  const indexOfSelectedColumnId = useMemo(() => props.columns
    .findIndex(c => c.id === props.selectedColumnId?.columnId && c.spreadsheetId === props.selectedColumnId?.spreadsheetId),
  [props.columns, props.selectedColumnId]);
  const createDropdownOptions = useMemo(() => props.columns.map((column, index) => (
    { name: column.name, value: index }
  )), [props.columns]);

  const onSelectedGroupChange = useCallback((group: GroupData | null) => {
    const onChange = props.onChangeSelectedGroup;
    onChange(group ?? null);
  }, [props.onChangeSelectedGroup]);

  const onSelectedColumnChange = useCallback((index: number) => {
    const onChange = props.onChangeSelectedColumnId;
    const column = props.columns[index];

    if (column) {
      onChange(convertColumnToColumnId(column));
    }
  }, [convertColumnToColumnId, props.columns, props.onChangeSelectedColumnId]);

  return (
    <>
      <ProximityPanelInputLabelComponent>{t('Apply to')}</ProximityPanelInputLabelComponent>
      <ProximityPanelDropdownComponent
        onChange={props.onChangeApplyTo}
        value={props.applyTo}
        options={applyToDropdownOptions}
      />

      <ProximityPanelInputLabelComponent>{t('Select Group Column')}</ProximityPanelInputLabelComponent>
      <ProximityPanelDropdownComponent
        onChange={onSelectedColumnChange}
        value={indexOfSelectedColumnId}
        options={createDropdownOptions}
        placeholder={t('Select Group Column')}
      />

      <SelectGroupComponent
        spreadsheetId={props.selectedColumnId?.spreadsheetId || null}
        selectedGroupColumn={props.selectedColumnId?.columnId || null}
        selectedGroup={props.selectedGroup}
        onSelectedGroupChange={onSelectedGroupChange}
        label={t('Select Group')}
      />

      <ProximityPanelInputLabelComponent>{t('Proximity Within')}</ProximityPanelInputLabelComponent>
      <UnitDropdownComponent
        onChange={props.onChangeProximityWithin}
        onUnitChange={props.onUnitChange}
        unit={props.unit}
        value={props.proximityWithin}
      />
    </>
  );
};
