import {
  apiGet, apiPatch, apiPost,
} from '../_shared/utils/api/api.helpers';

export type DeletedMapsApiData = {
  deleted_at: string;
  id: number;
  name: string;
  type: string;
};

export const fetchDeletedMaps = (clientId: number): Promise<{data: any}> => {
  const requestUrl = `/api/clients/${clientId}/maps/undo`;

  return apiGet(requestUrl);
};

export const restoreMapData = (clientId: number | null, ids: ReadonlyArray<number>) => {
  const requestUrl = `/api/clients/${clientId}/maps/restore`;

  return apiPatch(requestUrl, { id: ids });
};

export const fetchVersions = (clientId: number | null, virtualSpreadsheetId: number | null) => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${virtualSpreadsheetId}/versions`;

  return apiGet(requestUrl);
};

export type RollbackVersionParams = Readonly<{
  realSpreadsheetId: number;
  snapshotIndex: number;
  versionIndex: number;
}>;

export const rollbackVersions = (clientId: number | null, virtualSpreadsheetId: number | null, params: RollbackVersionParams) => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${virtualSpreadsheetId}/versions/rollback`;

  const requestParams = {
    real_spreadsheet_id: params.realSpreadsheetId,
    snapshot: params.snapshotIndex,
    version: params.versionIndex,
  };

  return apiPost(requestUrl, requestParams);
};
