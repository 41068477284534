import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../_shared/utils/hooks';
import { isRouteActive } from '../../_shared/utils/tests/router.helpers';
import { useMapIdSelector } from '../../store/selectors/useMapIdSelector';
import {
  getAdminMapPageNavigationItem,
  getHomeNavigationItem,
  type NavigationItem,
} from '../../topBar/navigation/navigation.helpers';

export const useActiveMobileNavigationItem = (): NavigationItem => {
  const location = useLocation();
  const mapId = useMapIdSelector() ?? null;
  const [t] = useTranslation();
  const adminMapPageNavigationItem = getAdminMapPageNavigationItem(mapId, t);

  return useMemo(() => (
    isRouteActive(location.pathname, adminMapPageNavigationItem.path)
      ? adminMapPageNavigationItem : getHomeNavigationItem(t)
  ), [location.pathname, adminMapPageNavigationItem, t]);
};
