import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { getRandomColor } from '~/_shared/constants/grouping.constants';
import { type BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import {
  createColorWithOpacity, guaranteeHash,
} from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { copy } from '../../../_shared/utils/collections/collections';
import { useTranslation } from '../../../_shared/utils/hooks';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { type ModalProps } from '../../../modal/modalType.enum';
import { boundaryTerritoryGroupsUpdateRequest } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useBoundaryTerritoryGroupsSelector } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { createAppError } from '../../../store/modal/modal.actionCreators';
import { BoundaryItemEditSettingsComponent } from '../../boundaryItemSettings/boundaryItemEditSettings.component';
import { useFetchBoundaryTerritoryGroup } from '../../useBoundaryTerritoryGroupsServerDetails';
import { useCustomBoundaryCreate } from './useCustomBoundaryCreate';

export type BoundaryCreateSubmitModalProps = ModalProps<{
  editedBoundaryTerritoryGroupId: number;
  geometry: BoundaryCombineGeometryRequestItem[];
  onCreate: (createAnother: boolean) => void;
}>;

export const BoundaryCreateSubmitModalContainer: FC<BoundaryCreateSubmitModalProps> = (props) => {
  const { isLoading, createBoundary } = useCustomBoundaryCreate();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const { fetchBoundaryTerritoryGroup } = useFetchBoundaryTerritoryGroup();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = ({ displayName, fillColor, createAnother }: { displayName: string; fillColor: ColorResult; createAnother: boolean }) => {
    const boundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.editedBoundaryTerritoryGroupId);

    if (!boundaryTerritoryGroup) {
      return;
    }

    createBoundary({
      fillColor,
      displayName,
      boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId,
      onSuccess: async (newBoundaryId) => {
        // fetch the boundary territory group details and update them with new settings to allow user
        // to create new boundary if BTG is outdated (if BTG was changed by another user in the meantime)
        try {
          const serverBoundaryTerritoryGroup = await fetchBoundaryTerritoryGroup(boundaryTerritoryGroup.boundaryTerritoryGroupId);

          if (!serverBoundaryTerritoryGroup) {
            throw Error('boundary territory group doesn\'t exist anymore');
          }

          dispatch(boundaryTerritoryGroupsUpdateRequest({
            ...serverBoundaryTerritoryGroup,
            settings: {
              ...serverBoundaryTerritoryGroup.settings,
              boundaryStyle: copy.andAdd(serverBoundaryTerritoryGroup.settings.boundaryStyle, [[newBoundaryId, {
                color: guaranteeHash(fillColor.hex),
                opacity: fillColor.rgb.a ? fillColor.rgb.a * 100 : 0,
              }]]),
            },
          }));
          props.onCreate(createAnother);
          props.onClose();
        }
        catch (e) {
          props.onClose();
        }
      },
      onError: () => {
        dispatch(createAppError({
          type: AppErrorType.General,
          title: t('Error creating new territory'),
        }));
      },
      boundaryItemsBoundaryRequests: props.geometry,
    });
  };

  return (
    <BoundaryItemEditSettingsComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      heading={t('Create New Territory')}
      displayName={t('Untitled territory')}
      initialFillColor={createColorWithOpacity(getRandomColor(), .5)}
      isLoading={isLoading}
      onSubmit={onSubmit}
      showCreateAnotherButton
    />
  );
};
