import {
  type FileAttachment,
  type FileAttachmentId,
  type FileAttachmentTool,
  FileAttachmentTools,
} from '../../types/file.types';

export const getFileAttachmentId = (fileId: number, tool: FileAttachmentTool): FileAttachmentId => `${tool}|${fileId}`;

export const createNewFileAttachment = (fileId: number, tool: FileAttachmentTool): FileAttachment => {
  return {
    id: getFileAttachmentId(fileId, tool),
    fileId,
    tool,
  };
};

const containsBothSegments = (segments: readonly string[]): segments is readonly[string, string] => {
  return (typeof segments[0] === 'string') && (typeof segments[1] === 'string');
};

export const recreateFileAttachment = (id: FileAttachmentId): FileAttachment | null => {
  const segments = id.split('|');

  if (!containsBothSegments(segments)) {
    return null;
  }

  const [tool, fileIdString] = segments;

  if (!FileAttachmentTools.includes(tool)) {
    return null;
  }

  const fileId = Number.parseInt(fileIdString, 10);
  if (Number.isNaN(fileId)) {
    return null;
  }

  return {
    id,
    fileId,
    tool: tool as FileAttachmentTool,
  };
};
