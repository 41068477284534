import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type LayeringType } from '~/map/map.repository';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { LayeredMapSectionHolderComponent } from '../../layeredMapSectionHolderComponent';
import { MapConnectSettings } from '../../mapConnectSettings';

const connectionSettingLabelStyle = css({
  fontSize: 16,
  fontWeight: 700,
  textTransform: 'uppercase',
});

const connectionSettingsStyle = css({
  gap: 20,
});

const connectionSettingOptionDescriptionStyle = css({
  cursor: 'default',
  fontSize: 12,
  margin: '8px 0 0 28px',
});

const warningStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
  cursor: 'default',
  margin: '8px 0 0',
});

type ConnectivitySettingsSectionComponentProps = Readonly<{
  layering?: LayeringType;
  selected: MapConnectSettings;

  onChange: (selectedConnectivity: MapConnectSettings) => void;
  onContinue: () => void;
}>;

export const ConnectivitySettingsSectionComponent: FC<ConnectivitySettingsSectionComponentProps> = (props) => {
  const { layering } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const connectivitySettingItems = useMemo(() => [{
    value: MapConnectSettings.ConnectedInRealTime,
    label: <span css={connectionSettingLabelStyle}>{t('Stay connected to base map - in real time')}</span>,
    children: (
      <div css={connectionSettingOptionDescriptionStyle}>
        {t('Any changes done to the base map spreadsheet data will be automatically reflected in the layered map spreadsheet data.')}
      </div>
    ),
    isDisabled: layering ? !layering?.connected : false,
  }, {
    value: MapConnectSettings.ConnectedWithApproval,
    label: <span css={connectionSettingLabelStyle}>{t('Stay connected to base map - updates need to be approved')}</span>,
    children: (
      <div css={connectionSettingOptionDescriptionStyle}>
        {t('Any change made to the base map data will only be reflected on the layered map data after you approve the update. Layered map data will remain in its previous state if base map data updates are NOT approved by you.')}
      </div>
    ),
    isDisabled: layering ? !layering?.connected : false,
  }, {
    value: MapConnectSettings.Disconnected,
    label: <span css={connectionSettingLabelStyle}>{t('Disconnected')}</span>,
    children: (
      <div css={connectionSettingOptionDescriptionStyle}>
        {t('Changes done to the base map data, after the layered map is created, will never update the layered map data or be reflected on the layered map.')}
        <p css={warningStyle({ theme })}>
          {t('Note: Once disconnected, data cannot be connected again.')}
        </p>
      </div>
    ),
    isDisabled: layering ? !layering?.connected : false,
  }], [t, layering, theme]);

  const buttonProps = useMemo(() => {
    const onContinue = props.onContinue;
    return {
      isDisabled: !props.selected,
      onClick: onContinue,
    };
  }, [props.onContinue, props.selected]);

  return (
    <LayeredMapSectionHolderComponent
      buttonProps={buttonProps}
    >
      <RadioGroupComponent
        css={connectionSettingsStyle}
        items={connectivitySettingItems}
        onValueChange={props.onChange}
        selectedValue={props.selected}
      />
    </LayeredMapSectionHolderComponent>
  );
};
