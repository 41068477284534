import { type TranslationFnc } from '~/_shared/utils/hooks';
import { type ImpersonationStatusChange } from './types';

export const getTitle = (reason: ImpersonationStatusChange, t: TranslationFnc) => {
  switch (reason) {
    case 'expired':
      return t('UserImpersonation.Expired');
    case 'started':
      return t('UserImpersonation.Started');
    case 'ended':
      return t('UserImpersonation.Ended');
    default:
      return '';
  }
};

export const getSubTitle = (requireRefresh: boolean, t: TranslationFnc) => {
  if (requireRefresh) {
    return t('UserImpersonation.RefreshRequired');
  }
  else {
    return t('UserImpersonation.LoggedBackToPreviousUserTitle');
  }
};

export const getText = (requireRefresh: boolean, t: TranslationFnc) => {
  if (!requireRefresh) {
    return t('UserImpersonation.LoggedBackToPreviousUser');
  }
  else {
    return undefined;
  }
};
