import { type TranslationFnc } from '~/_shared/utils/hooks';

export enum MapSearchFilterBy {
  OnlyUserData = 'OnlyUserData',
  OnlyGeolocated = 'OnlyGeolocated',
  UserDataAndGeolocated = 'UserDataAndGeolocated',
}

export const createDisplayName = (filter: MapSearchFilterBy, t: TranslationFnc): string => {
  switch (filter) {
    case MapSearchFilterBy.OnlyGeolocated:
      return t(MapSearchFilterBy.OnlyGeolocated);
    case MapSearchFilterBy.OnlyUserData:
      return t(MapSearchFilterBy.OnlyUserData);
    case MapSearchFilterBy.UserDataAndGeolocated:
      return t(MapSearchFilterBy.UserDataAndGeolocated);
    default:
      throw new Error(`Unknown MapSearchFilterBy '${filter}'`);
  }
};
