import { useMemo } from 'react';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

export const useIsBoundaryLocationsExportAllowed = (): boolean => {
  const allowedPresentationalMapTools = usePublicMapSettingsAllowedExportDataToolsSelector();
  const isMapPresentational = useIsMapPresentationalSelector();

  return useMemo((): boolean => (
    !isMapPresentational || hasFeatureOrToolAllowedPresentationalMapExport(
      MapTool.Boundary,
      allowedPresentationalMapTools
    )
  ), [allowedPresentationalMapTools, isMapPresentational]);
};
