import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ProgressBarComponent } from '~/_shared/baseComponents/loaders';
import { SuccessAnimationComponent } from '~/_shared/components/animation/success/sucessAnimation.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { splitArrayIntoChunks } from '~/_shared/utils/array/array.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useDefaultMetrics } from '~/_shared/utils/metric/defaultMetric.hook';
import { mapMetricsToServerModel } from '~/_shared/utils/metric/metrics.factory';
import { setMapV4MigrationDone } from '~/map/map.repository';
import { type ModalProps } from '~/modal/modalType.enum';
import { boundaryTerritoryGroupUpdateSuccess } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { mapInfoSetV4MigrationDone } from '~/store/mapInfo/mapInfo.actionCreators';
import { setProximityMetrics } from '~/store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import {
  useProximitesSelector,
  useProximityMetricsSelector,
} from '~/store/mapSettings/proximity/mapSettingsProximity.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import {
  contentStyle, textStyle,
} from './afterMigrationMapPrepareModal.styles';
import { updateBTGMetric } from './updateBTGMetric';
import { useAwaitMapSettingsUpdate } from './useAwaitMapSettingsUpdate';
import { useProgressTracker } from './useProgressTracker';
import { useWaitForDataReady } from './useWaitForDataReady';

type InitializationStatus = 'NOT_STARTED' | 'RUNNING' | 'FINISHED';

export type AfterMigrationMapPrepareModalProps = Readonly<ModalProps>;

const WAIT_FOR_FINISH_TIMEOUT = 500;
const WAIT_FOR_CLOSE_TIMEOUT = 2000;
const MAX_BTG_METRICS_TO_GENERATE = 20;

export const AfterMigrationMapPrepareModal = (props: AfterMigrationMapPrepareModalProps) => {
  const { onClose } = props;
  const [t] = useTranslation();
  const waitForSettingsUpdateFinished = useAwaitMapSettingsUpdate();

  const dispatch = useDispatch();
  const [initializationStatus, setInitializationStatus] = useState<InitializationStatus>('NOT_STARTED');
  const [closeTimeout, setCloseTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

  const { computeDefaultMetrics } = useDefaultMetrics();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const proximities = useProximitesSelector();
  const proximitiesMetrics = useProximityMetricsSelector();

  const isDataReady = useWaitForDataReady();

  const bTGsWithoutMetrics = useMemo(() =>
    boundaryTerritoryGroups.filter((btg) => btg.settings.metrics.length === 0),
  [boundaryTerritoryGroups]
  );
  const shouldInitializeMetricsForBTGs = bTGsWithoutMetrics.length <= MAX_BTG_METRICS_TO_GENERATE;
  const shouldInitializeMetricsForProximities = (proximities.length > 0) && (proximitiesMetrics.length === 0);

  //Steps: Wait for data ready, update proximities metrics(0-1), every BTG without metrics(0-n), flag update
  const progressSteps = 1 + (shouldInitializeMetricsForProximities ? 1 : 0) + (shouldInitializeMetricsForBTGs ? bTGsWithoutMetrics.length : 0) + 1;
  const { currentProgress, updateProgressByStep } = useProgressTracker(progressSteps);

  const initializeMetrics = useCallback(async () => {
    if (clientId === null || mapId === null) {
      return;
    }

    setInitializationStatus('RUNNING');
    updateProgressByStep();

    const {
      defaultDemographicsMetrics,
      defaultSpreadsheetColumnMetrics,
    } = await computeDefaultMetrics();

    if (shouldInitializeMetricsForProximities) {
      await waitForSettingsUpdateFinished(() => dispatch(setProximityMetrics(defaultSpreadsheetColumnMetrics)));
      updateProgressByStep();
    }

    if (shouldInitializeMetricsForBTGs) {
      const serverModelMetrics = mapMetricsToServerModel([
        ...defaultDemographicsMetrics,
        ...defaultSpreadsheetColumnMetrics,
      ]);
      const chunkedBTGsWithoutMetrics = splitArrayIntoChunks(bTGsWithoutMetrics, 5);
      for (const chunk of chunkedBTGsWithoutMetrics) {
        await Promise.all(chunk.map((btg) =>
          updateBTGMetric(clientId, btg, serverModelMetrics).then((updatedGroup) => {
            if (updatedGroup) {
              dispatch(boundaryTerritoryGroupUpdateSuccess(
                updatedGroup,
                { fetchBoundaryDetails: false }
              ));
              updateProgressByStep();
            }
          }, updateProgressByStep)
        ));
      }
    }

    try {
      await setMapV4MigrationDone(clientId, mapId);
      dispatch(mapInfoSetV4MigrationDone(true));
    }
    catch (e) {
      console.error('Failed to set map migration done!');
    }
    updateProgressByStep();

    setTimeout(() => setInitializationStatus('FINISHED'), WAIT_FOR_FINISH_TIMEOUT);
  }, [clientId, mapId, updateProgressByStep, computeDefaultMetrics, shouldInitializeMetricsForProximities,
    shouldInitializeMetricsForBTGs, dispatch, waitForSettingsUpdateFinished, bTGsWithoutMetrics]);

  const closeModal = useCallback(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, WAIT_FOR_CLOSE_TIMEOUT);

    setCloseTimeout(timeout);
  }, [onClose]);

  useEffect(() => {
    if (isDataReady && initializationStatus === 'NOT_STARTED') {
      initializeMetrics();
    }
  }, [isDataReady, initializeMetrics, initializationStatus]);

  useEffect(() => {
    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, [closeTimeout]);

  return (
    <ModalComponent
      caption={t('AfterMigrationMapPrepareModal.Header')}
      isCloseDisabled={initializationStatus !== 'FINISHED'}
      contentStyle={contentStyle}
      {...props}
    >

      <div css={textStyle}>
        {initializationStatus !== 'FINISHED' ? (
          <>
            {t('AfterMigrationMapPrepareModal.Headline.InProgress')}
            <br />
            {t('AfterMigrationMapPrepareModal.Subheading')}
          </>
        ) : t('AfterMigrationMapPrepareModal.Headline.Finished')}
      </div>

      {initializationStatus !== 'FINISHED' ? (
        <ProgressBarComponent
          progress={currentProgress}
        />
      ) : (
        <SuccessAnimationComponent
          size={90}
          onAnimationEnd={closeModal}
        />
      )}
    </ModalComponent>
  );
};
