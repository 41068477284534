import {
  type FC, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  DropdownLabelComponent, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { StackedWarningsComponent } from '~/_shared/baseComponents/warning/stackedWarnings.component';
import {
  headingStyle, optionsWrapperStyle, submitButtonStyle,
} from '~/_shared/components/customizeMetrics/selectData/customizeMetricsSelectData.styles';
import { MIN_PERCENTAGE_NUMERIC_EMPTY } from '~/heatMap/panel/form/useIsNumericColumnCheck.helpers';
import { useGetColumnsConstraints } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/useGetColumnsConstraints';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { type SpreadsheetColumn } from '../../../types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../utils/hooks';
import { type MetricsDataAction } from '../../../utils/metric/metrics.types';
import { OverlayLoaderComponent } from '../../overlay/overlayLoader.component';
import { customizeMetricsDropdownTriggerStyle } from '../customizeMetrics.component';
import {
  getDataActionOptions, requiresNumericalColumn,
} from '../customizeMetrics.helpers';
import {
  type SelectedDataMetric, SelectedDataType,
} from '../selectedDataMetric.type';

type CustomizeMetricsSelectDataMetricsProps = Readonly<{
  addSelectionButtonText: string;
  className?: string;
  columnDataSources: ReadonlyArray<SpreadsheetColumn>;
  onSubmit: (newData: SelectedDataMetric[]) => void;
  selectedData: SelectedDataMetric[];
}>;

export const CustomizeMetricsSelectDataMetricsComponent: FC<CustomizeMetricsSelectDataMetricsProps> = (props) => {
  const [selectedSpreadsheetColumnId, setSelectedSpreadsheetColumnId] = useState<string | null>(null);
  const [dataAction, setDataAction] = useState<MetricsDataAction | null>(null);
  const [t] = useTranslation();
  const spreadsheetId = usePrimarySpreadsheetId();

  const columnsConstraints = useGetColumnsConstraints(spreadsheetId);

  const { columnDataSources, addSelectionButtonText, selectedData, onSubmit } = props;

  const dataActionOptions: DropdownOption<MetricsDataAction>[] = useMemo(() => getDataActionOptions(t), [t]);

  const columnNameOptions: DropdownOption<string>[] = useMemo(() => columnDataSources.map((dataSource) => ({
    value: dataSource.id,
    name: dataSource.name,
  })), [columnDataSources]);

  const selectedSpreadsheetColumn = columnDataSources.find(column => column.id === selectedSpreadsheetColumnId);

  const isItemAlreadySelected = useMemo(() => (
    selectedData.some(item => item.type === SelectedDataType.SpreadsheetColumn &&
      item.dataAction === dataAction &&
      item.selectedSpreadsheetColumn.id === selectedSpreadsheetColumn?.id &&
      item.selectedSpreadsheetColumn.spreadsheetId === selectedSpreadsheetColumn.spreadsheetId
    )
  ), [dataAction, selectedData, selectedSpreadsheetColumn?.id, selectedSpreadsheetColumn?.spreadsheetId]);

  const isSelectedColumnNumerical =
    !!selectedSpreadsheetColumnId &&
    (columnsConstraints.columnsConstraints[selectedSpreadsheetColumnId]?.numericPercent ?? 0) > MIN_PERCENTAGE_NUMERIC_EMPTY;

  const selectedDataActionRequiresNumerical =
    dataAction !== null &&
    requiresNumericalColumn(dataAction);

  const isSubmitDisabled: boolean =
    !dataAction ||
    selectedSpreadsheetColumnId === null ||
    isItemAlreadySelected ||
    (selectedDataActionRequiresNumerical && !isSelectedColumnNumerical);

  const addSelectedItem = () => {
    if (
      isSubmitDisabled ||
      !dataAction ||
      !selectedSpreadsheetColumn
    ) {
      return;
    }

    const dataItem: SelectedDataMetric = {
      type: SelectedDataType.SpreadsheetColumn,
      dataAction,
      selectedSpreadsheetColumn,
    };

    const newData = [...props.selectedData];
    newData.push(dataItem);

    onSubmit(newData);

    setDataAction(null);
    setSelectedSpreadsheetColumnId(null);
  };

  const metricsWarnings = useMemo(() => {
    const warnings = [];
    if (selectedDataActionRequiresNumerical && !isSelectedColumnNumerical) {
      warnings.push(t('notNumericalColumnWarning'));
    }
    if (isItemAlreadySelected) {
      warnings.push(t('This combination is already on the list'));
    }

    return warnings;
  }, [isItemAlreadySelected, isSelectedColumnNumerical, selectedDataActionRequiresNumerical, t]);

  return (
    <div className={props.className}>
      {columnsConstraints.isLoading && <OverlayLoaderComponent />}
      <strong css={headingStyle}>{t('Data Metrics')}</strong>

      <div css={optionsWrapperStyle}>
        <div>
          <DropdownLabelComponent>{t('Select Column Name')}</DropdownLabelComponent>
          <RegularDropdownComponent
            onChange={setSelectedSpreadsheetColumnId}
            value={selectedSpreadsheetColumnId}
            options={columnNameOptions}
            triggerStyle={customizeMetricsDropdownTriggerStyle({ isPlaceholder: selectedSpreadsheetColumnId === null })}
            placeholder={t('Select Column Name')}
            inPortal
          />
        </div>

        <div>
          <DropdownLabelComponent>{t('Data Action')}</DropdownLabelComponent>
          <RegularDropdownComponent
            onChange={setDataAction}
            value={dataAction}
            options={dataActionOptions}
            triggerStyle={customizeMetricsDropdownTriggerStyle({ isPlaceholder: dataAction === null })}
            placeholder={t('Data Action')}
            inPortal
          />
        </div>

        <StackedWarningsComponent warnings={metricsWarnings} />
      </div>

      <ButtonComponent
        text={addSelectionButtonText}
        onClick={addSelectedItem}
        css={submitButtonStyle}
        isDisabled={isSubmitDisabled}
      />
    </div>
  );
};
