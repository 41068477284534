import { useCallback } from 'react';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ColumnsMapSettingsResponse } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.types';
import {
  GOOGLE_AUTH_ERROR, GOOGLE_TO_MAPTIVE_SYNC_COLUMNS_CHANGED, type GoogleSheetManualSyncConflictFailResponse,
} from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { useConfirmColumnsSettingsErased } from '../columnsSettingsModal/useConfirmColumnsSettingsErased.hook';

export const useResolveGoogleToMaptiveSyncError = () => {
  const [t] = useTranslation();
  const confirmColumnMapSettingsErased = useConfirmColumnsSettingsErased();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const openSyncMethodChangeAffectsMapSettingsWarning = useCallback((
    onConfirm: () => void,
    columnsMapSettings?: ColumnsMapSettingsResponse,
  ) => {
    if (columnsMapSettings) {
      confirmColumnMapSettingsErased({
        columnsMapSettings,
        description: t('googleSheets.googleSheet.columnsChanged.description'),
        description2: t('columnSettings.warnSettingsLost.googleSync'),
        usedColumns: [],
        onConfirm,
      });
    }
    else {
      openConfirmationModal({
        confirmCaption: t('Proceed'),
        isConfirmButtonDestructive: true,
        onCancel: closeConfirmationModal,
        onConfirm,
        text: (
          <div>
            <div>{t('googleSheets.googleSheet.columnsChanged.description')}</div>
            <div>&nbsp;</div>
            <div>{t('googleSheets.googleSheet.columnsChanged.overwritten')}</div>
          </div>
        ),
        title: t('googleSheets.googleSheet.columnsChanged.caption'),
      });
    }
  }, [closeConfirmationModal, confirmColumnMapSettingsErased, openConfirmationModal, t]);

  const handleOneWayGoogleSheetError = useCallback((
    error: ApiError,
    onSyncConflictResolved: () => void,
    onAuthError: () => void,
    onGeneralError: () => void,
  ) => {
    if (error.message === GOOGLE_AUTH_ERROR) {
      onAuthError();
      return;
    }
    else if (error.message === GOOGLE_TO_MAPTIVE_SYNC_COLUMNS_CHANGED) {
      openSyncMethodChangeAffectsMapSettingsWarning(
        onSyncConflictResolved,
        (error.response as GoogleSheetManualSyncConflictFailResponse).columns_map_settings,
      );
      return;
    }
    onGeneralError();
  }, [openSyncMethodChangeAffectsMapSettingsWarning]);

  return {
    handleOneWayGoogleSheetError,
  };
};
