import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { mapSettingsDrawingSyncSettings } from '../../store/mapSettings/drawing/mapSettingsDrawing.actionCreators';
import { drawingSettingsSetSettings } from '../../store/mapSettings/drawing/settings/drawingSettings.actionCreators';
import { type DrawingSettingsState } from '../../store/mapSettings/drawing/settings/drawingSettings.state';
import { type DrawingTool } from '../drawingTool.enums';

export const useDrawingSettingsUpdate = () => {
  const dispatch = useDispatch();

  const updateDrawingToolGlobalSettings = useCallback((drawingTool: DrawingTool, newSettings: DrawingSettingsState) => {
    dispatch(drawingSettingsSetSettings(drawingTool, newSettings));

    dispatch(mapSettingsDrawingSyncSettings());
  }, [dispatch]);

  return {
    updateDrawingToolGlobalSettings,
  };
};
