import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useFileUploadError } from '~/_shared/components/dropzone/dropzone.helpers';
import {
  type FileAttachmentId, UserFileType,
} from '~/_shared/types/file.types';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { useUserFiles } from '~/_shared/utils/files/useUserFiles';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import {
  useFileAttachmentsUrls, useFileUrls,
} from '~/store/frontendState/fileUrls/fileUrls.selector';
import {
  drawingToolImageSelectorSetEditedFileId,
  drawingToolImageSelectorSetNewInstanceFileId,
} from '~/store/frontendState/mapTools/drawing/drawingImageSelector/drawingImageSelector.actionCreators';
import {
  useDrawingImageSelectorEditedDrawingFileId,
  useDrawingImageSelectorNewInstanceFileId,
} from '~/store/frontendState/mapTools/drawing/drawingImageSelector/drawingImageSelector.selectors';
import { uploadFile } from '~/store/mapSettings/fileAttachments/fileApi.helpers';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import {
  DrawingToolImageOptionsComponent, type DrawingToolSelectorImage,
} from './drawingToolImageOptions.component';

type DrawingToolImageOptionsContainerProps = Readonly<{
  initialFileAttachmentId?: FileAttachmentId | null;

  onFilesLoadingChange: (filesLoading: boolean) => void;
  onImageChanged: () => void;
}>;

export const DRAWING_ALLOWED_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];

export const DrawingToolImageOptionsContainer: React.FC<DrawingToolImageOptionsContainerProps> = props => {
  const { onFilesLoadingChange, onImageChanged: handleImageChanged, initialFileAttachmentId } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const [isUploading, setUploading] = useState(false);
  const { getUserFiles, deleteFile, userFiles, isLoading, filesLoaded } = useUserFiles(UserFileType.Drawing);
  const fileUrls = useFileUrls();
  const userId = useUserIdSelector();
  const fileAttachmentUrls = useFileAttachmentsUrls();
  const { showFileUploadError } = useFileUploadError(DRAWING_ALLOWED_FILE_EXTENSIONS);

  const editingExistingDrawing = !!initialFileAttachmentId;

  const newInstanceFileId = useDrawingImageSelectorNewInstanceFileId();
  const existingDrawingFileId = useDrawingImageSelectorEditedDrawingFileId();
  const selectedImageFileId = editingExistingDrawing ? existingDrawingFileId : newInstanceFileId;

  const attachmentFileId = useMemo(() => {
    return initialFileAttachmentId ? fileAttachmentUrls.get(initialFileAttachmentId)?.fileId : undefined;
  }, [fileAttachmentUrls, initialFileAttachmentId]);

  const fileIds = useMemo(() => {
    const userFileIds = userFiles.map(file => file.id);

    if (attachmentFileId !== undefined && !userFileIds.includes(attachmentFileId)) {
      return [attachmentFileId, ...userFileIds];
    }

    return userFileIds;
  }, [userFiles, attachmentFileId]);

  // Preselect file when nothing is selected or file is deleted
  useEffect(() => {
    if (selectedImageFileId !== null) {
      const currentImage = fileIds.includes(selectedImageFileId);
      if (currentImage) {
        return;
      }
    }

    const setFileId = (fileId: number) => editingExistingDrawing
      ? dispatch(drawingToolImageSelectorSetEditedFileId(fileId))
      : dispatch(drawingToolImageSelectorSetNewInstanceFileId(fileId));

    if (attachmentFileId !== undefined) {
      setFileId(attachmentFileId);
      return;
    }

    if (notEmpty(fileIds)) {
      setFileId(fileIds[0]);
      return;
    }
  }, [attachmentFileId, dispatch, editingExistingDrawing, fileIds, selectedImageFileId]);

  const onFileAccept = useCallback(async (files: File[]) => {
    setUploading(true);
    for (const file of files) {
      try {
        await uploadFile(file, clientId, UserFileType.Drawing, (percent: number) => {
          // eslint-disable-next-line no-console
          console.log('Uploading Drawing Progress:', percent);
        });
      }
      catch (error) {
        dispatch(createAppError({
          type: AppErrorType.General,
          title: t('Uploading File Problem'),
          text: t('There was an error when uploading your file. Please check your connection and try again. If this problem persists please contact our customer support.'),
        }));
      }
    }
    setUploading(false);
    getUserFiles();
  }, [clientId, dispatch, t, getUserFiles]);

  const userImages = useMemo(() => {
    const images: DrawingToolSelectorImage[] = [];
    if (!userId) {
      return images;
    }

    for (const fileId of fileIds) {
      const fileUrl = fileUrls.get(fileId);
      if (fileUrl) {
        images.push({
          height: fileUrl['200p'].resolution.height,
          width: fileUrl['200p'].resolution.width,
          id: fileId,
          url: fileUrl['200p'].url,
        });
      }
    }
    return images;
  }, [fileIds, fileUrls, userId]);

  const handleDeleteImage = useCallback((fileId: number) => {
    deleteFile(fileId);
  }, [deleteFile]);

  // Load files when needed
  useEffect(() => {
    if (userId && !filesLoaded) {
      getUserFiles();
    }
  }, [filesLoaded, getUserFiles, userId]);

  useEffect(() => {
    onFilesLoadingChange(isLoading || isUploading);
  }, [isLoading, isUploading, onFilesLoadingChange]);

  const onImageChange = useCallback((fileId: number) => {
    if (fileId <= 0) {
      // ignore dummy and placeholder values
      return;
    }

    if (editingExistingDrawing) {
      dispatch(drawingToolImageSelectorSetEditedFileId(fileId));
      handleImageChanged(); //important to call this after the file id was changed in DrawingImageSelectorState
    }
    else {
      dispatch(drawingToolImageSelectorSetNewInstanceFileId(fileId));
    }
  }, [dispatch, editingExistingDrawing, handleImageChanged]);

  return (
    <DrawingToolImageOptionsComponent
      onSelectImage={onImageChange}
      onFileAccept={onFileAccept}
      onFileReject={showFileUploadError}
      selectedImageId={selectedImageFileId}
      images={userImages}
      onImageDelete={handleDeleteImage}
      acceptFileExtensions={DRAWING_ALLOWED_FILE_EXTENSIONS}
    />
  );
};
