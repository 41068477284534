import { createAction } from '@reduxjs/toolkit';
import type { FetchableResource } from '~/_shared/types/fetchableResource/fetchableResource';
import type { PlaceId } from '~/places/places.types';
import type { PlacesSearchId } from '~/store/mapSettings/places/mapSettingsPlaces.state';
import type { PlacesSearchResult } from './places.state';

export const frontendStatePlacesSetActivePlace = createAction(
  'FRONTEND_STATE_PLACES_SET_ACTIVE_PLACE',
  (placeId: PlaceId | null) => ({
    payload: { placeId },
  })
);

export const frontendStatePlacesPlacesSearchesSetLoading = createAction(
  'FRONTEND_STATE_PLACES_PLACES_SEARCHES_SET_LOADING',
  (searchIds: ReadonlySet<PlacesSearchId>) => ({
    payload: { searchIds },
  })
);

export const frontendStatePlacesPlacesSearchesRequestNextPage = createAction(
  'FRONTEND_STATE_PLACES_PLACES_SEARCHES_REQUEST_NEXT_PAGE_STATE',
  (searchIds: ReadonlySet<PlacesSearchId>) => ({
    payload: { searchIds },
  })
);

export const frontendStatePlacesSetPlacesSearchResults = createAction(
  'FRONTEND_STATE_PLACES_SET_PLACES_SEARCH_RESULTS',
  (results: ReadonlyMap<PlacesSearchId, FetchableResource<PlacesSearchResult>>) => ({
    payload: { results },
  })
);
