import { ColumnRole } from '../../../../_shared/types/columnRole.enum';
import { type CombinedRowId } from '../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { useCategoryValueResolver } from '../../../../_shared/utils/spreadsheet/useCategoryValueResolver';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { type RowWithName } from './useProcessMarkersForRouting';

interface DisplayMarkerSelectionDialogParams {
  rows: RowWithName[];
  onSubmit: (rows: RowWithName[]) => void;
}

export const useDisplayMarkerSelectionDialog = () => {
  const { openModal: openSelectionModal, closeModal: closeSelectionModal } = useModal(ModalType.SelectMarkersForOptimizeRoute);
  const { categoryValueResolver } = useCategoryValueResolver();
  const displayMarkerSelectionDialog = ({ rows, onSubmit }: DisplayMarkerSelectionDialogParams) => {
    const submit = (selectedRowIds: CombinedRowId[]) => {
      const selectedRowsWithNames = rows.filter(row => selectedRowIds.find(selectedRowId => selectedRowId === row.rowId));
      onSubmit(selectedRowsWithNames);
      closeSelectionModal();
    };

    const rowsWithAddress = rows.map(row => {
      const address = categoryValueResolver(ColumnRole.Address1, row.spreadsheetId, row.rowId);
      return { ...row, address };
    });

    openSelectionModal({ rows: rowsWithAddress, onSubmit: submit });
  };

  return {
    displayMarkerSelectionDialog,
  };
};
