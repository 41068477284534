import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useDistanceCalculatorInstanceZIndex } from '~/map/zIndexes/useMapPolygonInstanceZIndex.hook';
import { WEBGL_LABEL_NEW_LINE } from '../../../_shared/constants/webgl.constants';
import { type UnitSystem } from '../../../_shared/types/googleMaps/googleMaps.types';
import { type LatLng } from '../../../_shared/types/latLng';
import { useIsMapInteractionActive } from '../../../_shared/utils/hooks/useIsMapInteractionActive';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import {
  hoverDistanceCalculatorOutline,
  hoverDistanceCalculatorRemoveButton,
  startDistanceCalculatorOutlineDrag,
  stopDistanceCalculatorOutlineDrag,
  stopDistanceCalculatorOutlineHover,
  stopDistanceCalculatorRemoveButtonHover,
} from '../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.actionCreators';
import {
  deleteDistanceCalculatorInstance,
  moveDistanceCalculatorOutline,
} from '../../../store/mapSettings/toolsState/distanceCalculator/mapSettingsDistanceCalculator.actionCreators';
import { type DistanceCalculatorInstance } from '../../../store/mapSettings/toolsState/distanceCalculator/mapSettingsDistanceCalculator.state';
import { MapOutline } from '../mapObjects/mapOutline/mapOutline.component';
import { type MapObjectOutlineInstance } from '../mapObjects/mapOutline/mapOutlineModel';
import { MapShape } from '../mapObjects/mapShape/mapShape.container';
import { MapShapeLine } from '../mapObjects/mapShape/mapShapeLine.component';
import { MapShapePolygon } from '../mapObjects/mapShape/mapShapePolygon.component';
import { MapShapeRemoveButton } from '../mapObjects/mapShape/mapShapeRemoveButton.component';
import {
  calculateArea,
  calculateDistances,
  calculatePolygonDistance,
} from './calculateDistance';
import {
  dcLineRemoveVisuals,
  dcLineVisuals,
  dcOutlineHoverVisuals,
  dcOutlineRemoveVisuals,
  dcOutlineVisuals,
  dcPolygonVisuals,
  dcRemoveButtonHoverVisuals,
  dcRemoveButtonVisuals,
} from './distanceCalculatorVisuals';

const getPolygonLabel = (unitSystem: UnitSystem, instance: DistanceCalculatorInstance) => {
  const distance = calculatePolygonDistance(unitSystem, instance.outlines);
  const area = calculateArea(unitSystem, instance.outlines);
  return `${distance}${WEBGL_LABEL_NEW_LINE}${area}`;
};

type DCInstanceProps = {
  instance: DistanceCalculatorInstance;
};

const DCInstance: React.FC<DCInstanceProps> = ({ instance }) => {
  const dispatch = useDispatch();
  const unitSystem = useSelector(state => state.map.mapSettings.data.settings.unitSystem);
  const [removeHovered, setRemoveHovered] = useState(false);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<Uuid>();
  const zIndex = useDistanceCalculatorInstanceZIndex(instance.id);

  const mapInteractionActive = useIsMapInteractionActive();
  const removeEnabled = !mapInteractionActive;

  // Optimization. By using ref, we don't create new mouse-over / mouse-out callbacks.
  // If new callback would be created it would need to remove the old one and register new one
  // on every outline on the map.
  const dragEnabledRef = useRef<boolean>(false);
  dragEnabledRef.current = !mapInteractionActive;

  const [isDragging, setIsDragging] = useState(false);
  const isDraggingRef = useRef<boolean>(false);

  const outlineDistances = useMemo(() => instance?.outlines ? calculateDistances(unitSystem, instance.outlines) : undefined,
    [unitSystem, instance?.outlines]);

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (dragEnabledRef.current) {
      setHoveredOutlineId(outlineId);
      dispatch(hoverDistanceCalculatorOutline());
    }
  }, [dispatch]);

  const onOutlineMouseOut = useCallback(() => {
    setHoveredOutlineId(undefined);
    dispatch(stopDistanceCalculatorOutlineHover());
  }, [dispatch]);

  const onOutlineDragStart = useCallback(() => {
    if (dragEnabledRef.current) {
      setIsDragging(true);
      isDraggingRef.current = true;
      dispatch(startDistanceCalculatorOutlineDrag(instance.id));
    }
  }, [dispatch, instance.id]);

  const onOutlineDragEnd = useCallback(() => {
    setIsDragging(false);
    isDraggingRef.current = false;
    dispatch(stopDistanceCalculatorOutlineDrag());
  }, [dispatch]);

  const onOutlineDragMove = useCallback((outlineId: Uuid, latLng: LatLng) => {
    if (isDraggingRef.current) {
      dispatch(moveDistanceCalculatorOutline(instance.id, outlineId, latLng));
    }
  }, [dispatch, instance.id]);

  const renderOutline = useCallback((id: string, outline: MapObjectOutlineInstance) => (
    <MapOutline
      key={id}
      outline={outline}
      visuals={removeHovered ? dcOutlineRemoveVisuals : hoveredOutlineId === outline.id ? dcOutlineHoverVisuals : dcOutlineVisuals}
      label={outlineDistances?.find(item => item.outlineId === outline.id)?.distance}
      onMouseOver={onOutlineMouseOver}
      onMouseOut={onOutlineMouseOut}
      onDragStart={onOutlineDragStart}
      onDragEnd={onOutlineDragEnd}
      onDragMove={onOutlineDragMove}
    />
  ), [hoveredOutlineId, onOutlineDragEnd, onOutlineDragMove, onOutlineDragStart,
    onOutlineMouseOut, onOutlineMouseOver, outlineDistances, removeHovered]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => (
    <MapShapeLine
      key={id}
      id={id}
      start={start}
      end={end}
      visuals={removeHovered ? dcLineRemoveVisuals : dcLineVisuals}
    />
  ), [removeHovered]);

  const onRemoveMouseOver = useCallback(() => {
    setRemoveHovered(true);
    dispatch(hoverDistanceCalculatorRemoveButton());
  }, [dispatch]);

  const onRemoveMouseOut = useCallback(() => {
    setRemoveHovered(false);
    dispatch(stopDistanceCalculatorRemoveButtonHover());
  }, [dispatch]);

  const onRemoveClick = useCallback(() => dispatch(deleteDistanceCalculatorInstance(instance.id)),
    [dispatch, instance.id]);

  const renderRemoveButton = useCallback(() => (
    <MapShapeRemoveButton
      visuals={removeHovered ? dcRemoveButtonHoverVisuals : dcRemoveButtonVisuals}
      onClick={onRemoveClick}
      onMouseOver={onRemoveMouseOver}
      onMouseOut={onRemoveMouseOut}
    />
  ), [onRemoveClick, onRemoveMouseOut, onRemoveMouseOver, removeHovered]);

  const renderPolygon = useCallback(() => (
    <MapShapePolygon
      label={getPolygonLabel(unitSystem, instance)}
      visuals={dcPolygonVisuals}
    />
  ), [instance, unitSystem]);

  return (
    <MapShape
      shape={instance}
      zIndex={zIndex}
      isPolygon={instance.isPolygon}
      renderOutline={renderOutline}
      renderLine={renderLine}
      renderPolygon={(instance.isPolygon && !isDragging) ? renderPolygon : undefined}
      renderRemoveButton={removeEnabled ? renderRemoveButton : undefined}
    />
  );
};

const pureComponent = memo(DCInstance);
export { pureComponent as DCInstance };
