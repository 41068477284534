import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  ExportImageLegendMode, ExportImageMode,
} from '~/_shared/types/exportImage/exportImage';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import {
  activeMapElementsSetActiveLocationList,
  clickSpreadsheetMarker,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { frontendStateProcessingExportImageModeInitialized } from '~/store/frontendState/processing/processing.actionCreators';
import { type MapSettingsDataAction } from '../../data/mapSettingsData.action';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../../data/mapSettingsData.actionTypes';
import { mapSettingsExportImageSettingsSelector } from './exportImageSettings.selectors';
import { type MapSettingsExportImageSettingsState } from './exportImageSettings.state';

export function* exportImageSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, initializeForImageExportSaga);
}

function* initializeForImageExportSaga(_action: PickAction<MapSettingsDataAction, typeof MAP_SETTINGS_FETCH_DATA_SUCCESS>) {
  const isMapPresentational: boolean = yield select<boolean>(state => state.map.mapInfo.isPresentational);
  const exportImageSettings: MapSettingsExportImageSettingsState = yield select(mapSettingsExportImageSettingsSelector);

  if (isMapPresentational) {
    if (exportImageSettings?.legendMode === ExportImageLegendMode.Grouping) {
      yield put(openMapTool(MapTool.MapLegend));
    }
    else if (exportImageSettings?.legendMode === ExportImageLegendMode.Boundary) {
      yield put(openMapTool(MapTool.Boundary));
    }
    else if (exportImageSettings?.mode === ExportImageMode.LocationList) {
      yield put(activeMapElementsSetActiveLocationList());
    }
    else {
      if (exportImageSettings.mode === ExportImageMode.Screenshot) {
        if (exportImageSettings.sidebarTool) {
          yield put(openMapTool(exportImageSettings.sidebarTool));
        }
        if (exportImageSettings.activeMarker) {
          yield put(clickSpreadsheetMarker({ rowIds: [exportImageSettings.activeMarker] }));
        }
        else if (exportImageSettings.isLocationListOpen) {
          yield put(activeMapElementsSetActiveLocationList());
        }
      }
    }
  }

  if (isMapPresentational && !!exportImageSettings?.mode) {
    yield put(frontendStateProcessingExportImageModeInitialized());
  }
}
