import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import {
  frontendStateSearchHide, frontendStateSearchShow,
} from '../../../../store/frontendState/mapTools/search/search.actionCreators';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideMapSearchMenuItem: FC = () => {
  const [t] = useTranslation();

  const searchActive = useSelector(state => state.frontendState.mapTools.search.searchActive);

  const dispatch = useDispatch();
  const toggleSearch = () =>
    searchActive
      ? dispatch(frontendStateSearchHide())
      : dispatch(frontendStateSearchShow());

  return (
    <RightSideMapToolsMenuItem
      icon={faSearch}
      active={searchActive}
      onClick={toggleSearch}
      tooltip={t('Search')}
      UITestId="search-tool-trigger"
    />
  );
};
