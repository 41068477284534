import { useMemo } from 'react';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroupStyle } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useBoundarySelectActiveSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { type BoundaryItemStyle } from '../../mapBoundary.manager';
import { hideBoundaryBordersOnZoomLevel } from '../mapBoundary.helpers';
import { useMapBoundaryZIndexes } from './useMapBoundaryZIndexes';

const defaultBoundaryStyle: BoundaryItemStyle = {
  fillColor: '#000',
  fillOpacity: { value: 0 },
  borderColor: { value: '#000' },
  borderOpacity: { value: .6 },
  borderWidth: .5,
  zIndex: {
    polygon: 0,
    border: 1,
    label: 2,
  },
  showPolygonOutline: false,
};

export const useMapBoundaryBaseStyles = () => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const primaryBoundaryGroupId = useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector();
  const zIndexes = useMapBoundaryZIndexes();
  const mapZoom = useMapComponentZoomSelector();
  const boundarySelect = useBoundarySelectActiveSelector();

  return useMemo<ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>>(() => {
    const result = new Map<BoundaryGroupId, BoundaryItemStyle>();

    if (!mapZoom) {
      return result;
    }

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const boundaryGroup = boundaryGroups.find(g => g.id === boundaryTerritoryGroup.boundaryGroupId);
      if (!boundaryGroup) {
        continue;
      }

      const isPrimary = boundaryTerritoryGroup.boundaryGroupId === primaryBoundaryGroupId;
      const zIndex = isPrimary ? zIndexes.primaryBoundary : zIndexes[boundaryTerritoryGroup.boundaryGroupId] ?? defaultBoundaryStyle.zIndex;

      // check if boundarySelect?.selectedBoundaryGroupId is set to prevent condition undefined === undefined
      const boundaryUsedInSelect =
        boundarySelect?.selectBoundaryGroupId !== undefined &&
        (boundarySelect.selectBoundaryGroupId === boundaryGroup.id || boundarySelect.selectBoundaryGroupId === boundaryGroup.editBoundaryGroupFallback);
      const hideBorders = hideBoundaryBordersOnZoomLevel(mapZoom, boundaryGroup, boundaryTerritoryGroup) || boundaryUsedInSelect;

      // Memoization ensures styles are the same object unless inputs have changed
      const styles = getBoundaryBaseStylesMemoized(
        boundaryTerritoryGroup.settings.style, isPrimary && !boundarySelect?.isActive,
        zIndex.polygon, zIndex.border, zIndex.label, hideBorders
      );
      result.set(boundaryTerritoryGroup.boundaryGroupId, styles);
    }

    return result;
  }, [mapZoom, boundaryTerritoryGroups, boundaryGroups, primaryBoundaryGroupId, zIndexes,
    boundarySelect?.selectBoundaryGroupId, boundarySelect?.isActive]);
};

const getBoundaryBaseStyles = (
  style: BoundaryTerritoryGroupStyle,
  isPrimary: boolean,
  polygonZIndex: number,
  borderZIndex: number,
  labelZIndex: number,
  hideBorders: boolean
) => ({
  ...defaultBoundaryStyle,
  borderWidth: hideBorders ? 0 : style.lineWidth,
  borderColor: { value: style.lineColor },
  borderOpacity: hideBorders ? { value: 0 } : isPrimary ? { value: 1 } : { value: .6 },
  zIndex: {
    polygon: polygonZIndex,
    border: borderZIndex,
    label: labelZIndex,
  },
});
const getBoundaryBaseStylesMemoized = memoizeWeak(getBoundaryBaseStyles);
