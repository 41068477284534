import {
  useCallback, useState,
} from 'react';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import {
  newPerSpreadsheetMap,
  type PerSpreadsheet,
} from '../_shared/types/spreadsheet/spreadsheet.types';
import { type CombinedRowId } from '../_shared/types/spreadsheetData/spreadsheetRow';
import { createCancelToken } from '../_shared/utils/api/api.helpers';
import { isCancelError } from '../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import { useClientIdSelector } from '../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../store/selectors/useMapIdSelector';
import { createAreaRequestGetterFilter } from '../store/spreadsheetData/area/spreadsheetData.area.helpers';
import { type BoundaryFilterBoundariesRequest } from './filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterGroups } from './filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type RadiusFilterRequestArguments } from './filter/radius/spreadsheetFilterRadius.factory';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkRequest,
} from './spreadsheet.repository';

export type PerSpreadsheetRowIds = PerSpreadsheet<readonly CombinedRowId[]>;

type UseSpreadsheetIdsArguments = Readonly<{
  boundaries?: BoundaryFilterBoundariesRequest;
  boundaryTerritories?: ReadonlyArray<BoundaryTerritoryFilterGroups>;
  circles?: ReadonlyArray<RadiusFilterRequestArguments>;
  polygons?: ReadonlyArray<MultiPolygon>;
  spreadsheetIds: number[];
  useMainFilters: boolean;
}>;

export const useAreaSpreadsheetRowIds = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [perSpreadsheetRowIds, setPerSpreadsheetRowIds] = useState<PerSpreadsheetRowIds>(newPerSpreadsheetMap());
  const filterTreeParams = useFilterTreeMapSettingsParams();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const isMountedRef = useIsComponentMountedRef();

  const getAreaData = useCallback(({ useMainFilters, circles, polygons, spreadsheetIds,
    boundaries, boundaryTerritories }: UseSpreadsheetIdsArguments) => {
    if (!clientId || !mapId || spreadsheetIds.length === 0) {
      return () => null;
    }

    const cancelToken = createCancelToken();

    setIsLoading(true);
    setIsError(false);

    const request: SpreadsheetDataBulkRequest = {
      params: spreadsheetIds.map(spreadsheetId => {
        const filter = createAreaRequestGetterFilter(
          useMainFilters,
          'and',
          filterTreeParams,
          spreadsheetId,
          circles,
          polygons,
          boundaries,
          boundaryTerritories,
        );

        return {
          exclude_row_data: true,
          exclude_basic_data: true,
          map_id: mapId,
          spreadsheet_id: spreadsheetId,
          filter,
        };
      }),
    };

    getSpreadsheetBulkData(clientId, request, cancelToken.token)
      .then(response => {
        const newResults: PerSpreadsheetRowIds = newPerSpreadsheetMap();

        response.data.forEach(data => {
          if (data.result.filtered_rows) {
            newResults[data.spreadsheet_id] = data.result.filtered_rows;
          }
        });

        setPerSpreadsheetRowIds(newResults);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return;
        }
        if (isMountedRef.current) {
          setIsError(true);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });

    return () => {
      cancelToken.cancel();
    };
  }, [clientId, mapId, filterTreeParams, isMountedRef]);

  return {
    isLoading,
    isError,
    perSpreadsheetRowIds,
    getAreaData,
  };
};
