import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { ColorPickerAlignment } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';
import { boundaryFillDefaultOpacity } from '~/boundary/settings/defaultBoundarySettings';
import { LineWidthComponent } from '~/boundary/settings/lineWidth/lineWidth.component';
import {
  createColor, createColorWithOpacity,
} from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { SliderWithValueLabelsComponent } from '../../_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { changeColorAlpha } from '../../_shared/utils/colors/colors.helpers';
import { useTranslation } from '../../_shared/utils/hooks';
import { isTextEmpty } from '../../_shared/utils/text/text.helpers';
import { type ModalProps } from '../../modal/modalType.enum';
import { ColorPickerComponent } from '../settings/colorPicker/colorPicker.component';
import { LineColorComponent } from '../settings/lineColor/lineColor.component';
import { LineExampleComponent } from '../settings/lineExample/lineExample.component';

const tableInputStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  minWidth: 50,
});

const settingsContainerStyle = css({
  display: 'flex',
  margin: '15px -10px 0',
});

const settingsColumnStyle = css({
  width: '50%',
  padding: '0 10px',
});

const headingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
});

const territorySampleContainerStyle = css({
  marginTop: 20,
});

const fillOpacityHeadingStyle = css({
  marginTop: 18,
});

const opacitySliderStyle = css({
  marginTop: 13,
  marginLeft: 13,
});

type BoundaryItemEditSettingsProps = ModalProps<{
  heading: string;
  displayName?: string;
  initialFillColor?: ColorResult;
  showLineCustomization?: boolean;
  initialLineColor?: ColorResult;
  initialLineWidth?: number;
  isLoading?: boolean;
  onSubmit: (results: {
    displayName: string;
    fillColor: ColorResult;
    lineColor: ColorResult;
    lineWidth: number;
    createAnother: boolean;
  }) => void;
  showCreateAnotherButton: boolean;
}>;

export const BoundaryItemEditSettingsComponent: FC<BoundaryItemEditSettingsProps> = (props) => {
  const [territoryName, setTerritoryName] = useState(props.displayName ?? '');
  const [lineWidth, setLineWidth] = useState(props.initialLineWidth ?? 2);
  const [lineColor, setLineColor] = useState(() => props.initialLineColor ?? createColor('#000'));
  const [fillColor, setFillColor] = useState(() => props.initialFillColor ?? createColorWithOpacity('#81b2e1', boundaryFillDefaultOpacity));
  const [t] = useTranslation();
  const theme = useTheme();

  const changeFillColorOpacity = (alpha: readonly [number, ...number[]]) => {
    const newColor = changeColorAlpha(fillColor.rgb, alpha[0] / 100);
    setFillColor(newColor);
  };

  const isSubmitDisabled = isTextEmpty(territoryName) || props.isLoading;

  const onSubmit = (createAnother: boolean) => {
    props.onSubmit({
      displayName: territoryName,
      fillColor,
      lineColor,
      lineWidth,
      createAnother,
    });
  };

  useEffect(() => {
    if (props.displayName === undefined) {
      return;
    }

    setTerritoryName(props.displayName);
  }, [props.displayName]);

  return (
    <ModalComponent
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={props.heading}
      leftFooterContent={props.showCreateAnotherButton && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          isDisabled={isSubmitDisabled}
          onClick={() => onSubmit(true)}
          text={t('Save and Create Another')}
        />
      )}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          text={t('Save')}
          onClick={() => onSubmit(false)}
          isDisabled={isSubmitDisabled}
        />
      )}
    >
      <div>
        <p css={headingStyle}>{t('Name')}</p>
        <TextInputComponent
          css={tableInputStyle({ theme })}
          onChange={setTerritoryName}
          placeholder={t('Name')}
          value={territoryName}
        />
      </div>

      <div css={territorySampleContainerStyle}>
        <LineExampleComponent
          lineWidth={lineWidth}
          lineColor={lineColor}
          fillColor={fillColor}
        />
      </div>

      <div css={settingsContainerStyle}>
        <div css={settingsColumnStyle}>
          <p css={headingStyle}>{t('Fill Color')}</p>
          <ColorPickerComponent
            label={t('Fill Color')}
            selectedColor={fillColor}
            onChange={(newColor) => {
              setFillColor(prevColor => createColorWithOpacity(newColor.hex, prevColor.rgb.a));
            }}
            displayAlpha={false}
            alignment={ColorPickerAlignment.Right}
          />

          <p css={[headingStyle, fillOpacityHeadingStyle]}>{t('Fill Opacity')}</p>
          <SliderWithValueLabelsComponent
            css={opacitySliderStyle}
            value={[(fillColor.rgb?.a ?? 1) * 100]}
            onChange={changeFillColorOpacity}
            currentValueOnTheRight
            valueSuffix="%"
          />
        </div>

        {props.showLineCustomization ? (
          <div css={settingsColumnStyle}>
            <LineWidthComponent
              lineWidth={lineWidth}
              onLineWidthChange={setLineWidth}
            />

            <LineColorComponent
              lineColor={lineColor}
              onLineColorChange={setLineColor}
            />
          </div>
        ) : null}
      </div>
    </ModalComponent>
  );
};
