import {
  useCallback, useState,
} from 'react';
import { type ApiError } from '../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import { restoreMapData } from './undo.repository';

export type RestoreResult = {
  readonly success: boolean;
  readonly type: 'map' | 'snapshot';
  readonly message: 'RESTORED' | 'PARENT_MAP_NOT_FOUND' | 'NOT_FOUND' | 'FAILED';
  readonly id: number;
};

type RestoreSuccessResponseModel = {
  readonly message: 'PROCESSED';
  readonly data: {
    readonly affected: number;
    readonly results: readonly RestoreResult[];
  };
};

export const useRestoreMap = (clientId: number | null) => {
  const isMountedRef = useIsComponentMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const restoreMap = useCallback((ids: ReadonlyArray<number>, onSuccess?: () => void) => {
    setIsLoading(true);
    setError(null);

    return restoreMapData(clientId, ids)
      .then((data: RestoreSuccessResponseModel) => {
        const failures = data?.data?.results?.filter(result => !result.success) ?? [];
        if ((failures.length > 0) && isMountedRef.current) {
          setError({
            message: data.message,
            responseStatus: 200,
            errors: { restore_failures: failures.map(failure => failure.message) },
            errorData: { restore_failures: failures },
            name: data.message,
          });
        }
        else {
          onSuccess?.();
        }
      })
      .catch((e: ApiError) => {
        if (isMountedRef.current) {
          setError(e);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [clientId, isMountedRef]);

  return {
    isLoading,
    error,
    restoreMap,
  };
};
