import { css } from '@emotion/react';

export const globalStyles = css({
  html: {
    height: '100%',
    minHeight: '100%',
  },
  body: {
    padding: 0,
    margin: 0,
    fontFamily: '"Work Sans", sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '100%',
    minHeight: '100%',
  },
  button: {
    outline: 'none',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
  },
  '.root': {
    height: '100%',
  },
});
