import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { blankBoundaryStyle } from '~/boundary/settings/defaultBoundarySettings';
import { boundaryItemsUpdateItem } from '~/store/boundaryItems/boundaryItems.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { guaranteeHash } from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { isTextEmpty } from '../../../_shared/utils/text/text.helpers';
import {
  type BoundaryCombineGeometryRequestItem,
  type BoundaryResponse, createBoundary, getBoundaryCombineGeometry,
} from '../../../store/boundaries/boundaries.repository';
import { createBoundaryStateItemFromBoundaryResponse } from '../../../store/boundaryItems/boundaryItems.factory';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';

type BoundaryCreateSubmitArguments ={
  displayName: string;
  fillColor: ColorResult;
  boundaryGroupId: number;
  onSuccess: (newBoundaryId: number) => void;
  onError: () => void;
  boundaryItemsBoundaryRequests: BoundaryCombineGeometryRequestItem[];
};

export const useCustomBoundaryCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ displayName, boundaryGroupId, fillColor, boundaryItemsBoundaryRequests, onSuccess, onError }:
    BoundaryCreateSubmitArguments
    ) => {
      if (!clientId || !mapId || isTextEmpty(displayName)) {
        return;
      }

      setIsLoading(true);
      try {
        const newGeometry = await getBoundaryCombineGeometry(clientId, {
          include: [...boundaryItemsBoundaryRequests],
          subtract: [],
        });

        const response: BoundaryResponse = await createBoundary(clientId, {
          boundaryGroupId,
          displayName,
          geometry: newGeometry.geometry,
          mapId,
          settings: {
            style: {
              line_width: blankBoundaryStyle.lineWidth,
              color: guaranteeHash(fillColor.hex),
              line_color: blankBoundaryStyle.lineColor,
              opacity: fillColor.rgb.a ? fillColor.rgb.a * 100 : blankBoundaryStyle.opacity,
            },
          },
        });

        const boundaryStateItem = createBoundaryStateItemFromBoundaryResponse(response);
        dispatch(boundaryItemsUpdateItem(boundaryGroupId, boundaryStateItem));
        onSuccess(response.boundary_id);
      }
      catch (e) {
        onError();
      }

      setIsLoading(false);
    }, [clientId, dispatch, mapId]);

  return {
    isLoading,
    createBoundary: onSubmit,
  };
};
