import {
  type FC, memo, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapOutlinePositionInstance } from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '~/_shared/types/latLng';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { ZOOM_LEVEL_FOR_SCALED_ITEMS } from '~/_shared/utils/distance/distance.helpers';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  DRAWING_TOOL_NUMBER_MAX_RADIUS, DRAWING_TOOL_NUMBER_MIN_RADIUS,
} from '~/drawingTool/drawingTool.constants';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type DrawingItemNumber } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { mapFont } from '../../mapLabelFonts';
import { MapCircleComponent } from '../../mapObjects/mapCircle/mapCircle.component';
import { MapCircleContainer } from '../../mapObjects/mapCircle/mapCircle.container';
import { type MapCircleInstance } from '../../mapObjects/mapCircle/mapCircleModel';
import { MapLabelComponent } from '../../mapObjects/mapLabel/mapLabel.component';
import { drawingNumberLabelProps } from './drawingToolNumber.constants';
import { getDrawingNumberFontSizeToCounterLengthRatio } from './drawingToolNumber.helpers';

const DEBUG_LABEL_BOUNDS = false;

type MapNumberInstanceNumberProps = {
  instance: DrawingItemNumber;
  zIndex: MapObjectZIndex;
  onClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
  onRightClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  onDragStart?: (circleId: string, latLng: LatLng) => void;
  onDragEnd?: () => void;
  onDragMove?: (circleId: string, latLng: LatLng) => void;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
};

const DrawingToolNumberInstanceNumberContainer: FC<MapNumberInstanceNumberProps> = ({
  instance, zIndex, onClick, onRightClick, onMouseOut,
  onMouseOver, onDragStart, onDragEnd,
  onDragMove, renderOutline,
}) => {
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();
  const scalingRatio = instance.settings.scalesWithMapZoom ? sizePerPixelRatio : 1;

  const radius = clamp(instance.settings.radius, { min: DRAWING_TOOL_NUMBER_MIN_RADIUS * scalingRatio, max: DRAWING_TOOL_NUMBER_MAX_RADIUS * scalingRatio });

  const fillColor = useMemo<WebglColor>(() => {
    return convertColorToWebGLColor(instance.settings.fillColor, instance.settings.fillOpacity / 100);
  }, [instance.settings.fillColor, instance.settings.fillOpacity]);

  const borderColor = useMemo<WebglColor>(() => {
    return convertColorToWebGLColor(instance.settings.strokeColor, instance.settings.strokeOpacity / 100);
  }, [instance.settings.strokeColor, instance.settings.strokeOpacity]);

  const renderCenter = useCallback((id: string, center: LatLng) => {
    const counterText = instance.settings.counter.toString();
    const diameter = radius * 2;
    const limitFontSizeToNumberLengthRatio = getDrawingNumberFontSizeToCounterLengthRatio(counterText.length);
    const fontSize = diameter * instance.settings.fontSizePercentage / 100 * limitFontSizeToNumberLengthRatio;

    return (
      <MapLabelComponent
        key={id}
        isNumber
        label={{
          id,
          lat: center.lat,
          lng: center.lng,
        }}
        visuals={{
          label: {
            text: counterText,
            color: convertColorToWebGLColor(instance.settings.fontColor),
            fillWidth: 0.50,
            fontSize,
            staticSize: !instance.settings.scalesWithMapZoom,
            sizeOnLevel: instance.settings.scalesWithMapZoom ? ZOOM_LEVEL_FOR_SCALED_ITEMS : undefined,
            ...drawingNumberLabelProps,
          },
          callout: DEBUG_LABEL_BOUNDS ? {
            fillColor: [0, 0, 0, 1],
          } : undefined,
          font: mapFont.azeretMonoNumbers,
        }}
      />
    );
  }, [instance.settings.counter, instance.settings.fontColor, instance.settings.fontSizePercentage,
    instance.settings.scalesWithMapZoom, radius]);

  const renderCircle = useCallback((id: string, circle: MapCircleInstance) => (
    <MapCircleComponent
      key={id}
      circle={circle}
      visuals={{
        circleArea: {
          fillColor,
          borderWidth: instance.settings.strokeWeight,
          borderColor,
          units: 'pixels',
          staticSize: !instance.settings.scalesWithMapZoom,
          autoScale: instance.settings.scalesWithMapZoom,
        },
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onClick={onClick}
      onRightClick={onRightClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    />
  ), [borderColor, fillColor, instance.settings.scalesWithMapZoom, instance.settings.strokeWeight,
    onDragMove, onClick, onRightClick, onDragEnd, onDragStart, onMouseOver, onMouseOut]);

  const mapCircle: MapCircleInstance = useMemo(() => {
    return {
      id: instance.id,
      lat: instance.center.lat,
      lng: instance.center.lng,
      radius,
      sizeOnLevel: instance.settings.scalesWithMapZoom ? ZOOM_LEVEL_FOR_SCALED_ITEMS : undefined,
      scalesWithMapZoom: instance.settings.scalesWithMapZoom,
      strokeWeight: instance.settings.strokeWeight,
    };
  }, [instance.center.lat, instance.center.lng, instance.id, radius,
    instance.settings.scalesWithMapZoom, instance.settings.strokeWeight]);

  return (
    <MapCircleContainer
      circle={mapCircle}
      zIndex={zIndex}
      renderCircle={renderCircle}
      renderCenter={renderCenter}
      renderOutline={renderOutline}
    />
  );
};

const pureComponent = memo(DrawingToolNumberInstanceNumberContainer);
export { pureComponent as DrawingToolNumberInstanceNumberContainer };
