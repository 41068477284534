export const USER_RESTORE_DATA = 'USER/RESTORE_DATA_REQUEST';

export const USER_AUTH_EXPIRE = 'USER/AUTH_EXPIRE';

export const USER_SIGN_IN_REQUEST = 'USER/SIGN_IN_REQUEST';
export const USER_SIGN_IN_ERROR = 'USER/SIGN_IN_ERROR';

export const USER_GET_DATA_REQUEST = 'USER/GET_DATA_REQUEST';
export const USER_GET_DATA_SUCCESS = 'USER/GET_DATA_SUCCESS';
export const USER_GET_DATA_ERROR = 'USER/GET_DATA_ERROR';

export const USER_SIGN_OUT_REQUEST = 'USER/SIGN_OUT_REQUEST';
export const USER_SIGN_OUT_SUCCESS = 'USER/SIGN_OUT_SUCCESS';

export const USER_SET_CLIENT_ID = 'USER/SET_CLIENT_ID';

export const USER_DATA_SETTING_READY_SET = 'USER/DATA_SETTING_READY_SET';

export const USER_UPDATE_TWO_FACTOR_SUCCESS = 'USER/UPDATE_TWO_FACTOR_SUCCESS';

export const USER_GOOGLE_UNAUTHORIZED = 'USER/GOOGLE_UNAUTHORIZED';

export const USER_WEB_SOCKET_BROADCAST_INITIALIZED = USER_GET_DATA_SUCCESS;

export const USER_SET_CURRENT_CLIENT_LICENSE_AUTO_RENEWAL = 'USER/CURRENT_CLIENT/LICENSE/AUTO_RENEWAL/SET';

export const USER_SET_CLIENT_LIMIT_WMS_ENABLED = 'USER/SET_LIMIT/WMS_ENABLED';

export const USER_SET_USAGE_DISTANCE_MATRIX = 'USER/SET_USAGE/DISTANCE_MATRIX';
