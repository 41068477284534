import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useConfirmationModal } from '../../../../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { AppErrorType } from '../../../../../appError/appErrorType.enum';
import {
  type ModalProps, ModalType,
} from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import {
  type BaseMapDeleteDependenciesResolveHelper, type DeletedBaseMapWithDependenciesOtherUsersMap,
  type LayeredMap,
  Resolution,
} from '../baseMapDeleteDependenciesResolve.helper';
import { HandleBaseMapDeletionConfirmTextComponent } from './handleBaseMapDeletionConfirmText.component';
import { HandleBaseMapDeletionModalComponent } from './handleBaseMapDeletionModal.component';
import {
  anyDelete,
  anyDisconnect,
  createDefaultSelectedResolutions,
  getDeletedMapIds,
  getDisconnectedMapIds,
  type HandleBaseMapDeletionModalHelper,
} from './handleBaseMapDeletionModal.helper';
import { useConflictingMapDelete } from './useDeleteConflictedMap';

export type HandledLayeredMap = LayeredMap & {
  previousResolution?: Resolution.Delete | Resolution.Disconnect;
  resolutionOptions: DropdownOption<Resolution>[];
};

export type HandleBaseMapDeletionModalContainerProps = {
  mapId: number;
  mapName: string;
  mapIndex: number;
  mapsTotal: number;
  layeredMaps: HandledLayeredMap[];
  otherUsersUsingMap: DeletedBaseMapWithDependenciesOtherUsersMap[];

  onSubmit: (resolved: BaseMapDeleteDependenciesResolveHelper) => void;
};

const confirmationModalContentStyle = css({
  padding: 0,
});

export const HandleBaseMapDeletionModalContainer: FC<ModalProps<HandleBaseMapDeletionModalContainerProps>> = (props) => {
  const clientId = useClientIdSelector();
  const { layeredMaps, otherUsersUsingMap, onSubmit, mapId, mapIndex, onClose, isOpen } = props;
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);
  const unresolvedMaps = useMemo(
    () => layeredMaps.filter(map => !map.previousResolution),
    [layeredMaps]
  );
  const defaultSelectedResolutions = useMemo(() => {
    return createDefaultSelectedResolutions(unresolvedMaps.map(map => map.id), Resolution.Disconnect);
  }, [unresolvedMaps]);
  const { deleteMap, isLoading } = useConflictingMapDelete(clientId);
  const mapNumber = useMemo(() => mapIndex + 1, [mapIndex]);
  const [t] = useTranslation();

  const deleteMaps = useCallback((selectedResolutions: HandleBaseMapDeletionModalHelper) => {
    const exec = async () => {
      onClose();

      try {
        await deleteMap({ mapId, selectedResolutions, allowCopiesForDependantUsers: otherUsersUsingMap.length > 0 });
      }
      catch (e) {
        openAppErrorModal({
          type: AppErrorType.General,
          title: t('Error removing map'),
          errorTitle: t('Sorry, we encountered a problem removing the map. Please try again later.'),
        });
      }

      onSubmit({
        deletedMapIds: getDeletedMapIds(selectedResolutions),
        disconnectedMapIds: getDisconnectedMapIds(selectedResolutions),
      });
    };
    exec();
  }, [deleteMap, onSubmit, mapId, onClose, openAppErrorModal, t, otherUsersUsingMap.length]);

  const onDeleteFormSubmit = useCallback((selectedResolutions: HandleBaseMapDeletionModalHelper) => {
    openConfirmationModal({
      title: t('Layered Map Dependencies - Confirm Actions'),
      text: (
        <HandleBaseMapDeletionConfirmTextComponent selectedResolutions={selectedResolutions} />
      ),
      onConfirm: () => {
        closeConfirmationModal();
        deleteMaps(selectedResolutions);
      },
      contentStyle: confirmationModalContentStyle,
      isConfirmButtonDestructive: true,
      onCancel: closeConfirmationModal,
      confirmCaption: (anyDisconnect(selectedResolutions) || anyDelete(selectedResolutions)) ? t('Yes, continue') : t('Proceed'),
    });
  }, [openConfirmationModal, closeConfirmationModal, t, deleteMaps]);

  return (
    <HandleBaseMapDeletionModalComponent
      key={props.mapId}
      isOpen={isOpen}
      mapName={props.mapName}
      mapId={props.mapId}
      mapNumber={mapNumber}
      onClose={props.onClose}
      onSubmit={onDeleteFormSubmit}
      selectedResolutions={defaultSelectedResolutions}
      layeredMaps={props.layeredMaps}
      otherUsersUsingMap={otherUsersUsingMap}
      mapsTotal={props.mapsTotal}
      submitting={isLoading}
    />
  );
};
