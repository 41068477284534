import { useBoundaryZoomDisclaimer } from '~/map/map/boundary/boundaryView/mapBoundaryPolygons/useBoundaryZoomDisclaimer';
import { useMapContext } from '../mapContext';
import { useSelectionMapBoundary } from './boundarySelection/useSelectionMapBoundary';
import { useMapBoundaries } from './boundaryView/useMapBoundaries';

export const useMapBoundary = () => {
  const context = useMapContext();
  useSelectionMapBoundary(context);
  useMapBoundaries(context);
  useBoundaryZoomDisclaimer();
};
