import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type MatchUpDataSection } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import {
  type ColumnRole, type ColumnsMatchUp, publicApiRelatedRoles,
} from '~/_shared/types/columnRole.enum';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type ModalProps } from '~/modal/modalType.enum';
import { matchupUpdateRequest } from '~/store/matchupData/matchupData.actionCreators';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { MatchupDataComponent } from './matchupData.component';

export type MatchupDataContainerProps = ModalProps<{
  sections: ReadonlyArray<MatchUpDataSection>;
  hideHeading?: boolean;
  ensureLocationDataIsSelected: boolean;
}>;

export const MatchupDataContainer: FC<MatchupDataContainerProps> = ({ onClose, isOpen, ensureLocationDataIsSelected, sections, hideHeading }) => {
  const matchup = useSelector(state => state.spreadsheet.matchupData);

  const isPublicApiActive = useUserDataSelector().isPublicApiActive;
  const spreadsheetId: number | null = usePrimarySpreadsheetId();
  const dispatch = useDispatch();

  const matchupColumns = spreadsheetId !== null ? matchup[spreadsheetId]?.data?.columns : null;
  const matchupCategories = spreadsheetId !== null ? matchup[spreadsheetId]?.data?.categories : null;
  const isLoading = spreadsheetId !== null ? matchup[spreadsheetId]?.isLoading : false;

  const initialMatchUp = useMemo<ColumnsMatchUp | null>(() => {
    if (!matchupCategories) {
      return null;
    }

    const results: ColumnsMatchUp = {
      address: [null, false],
      address_line_2: [null, false],
      city: [null, false],
      country: [null, false],
      email: [null, false],
      image_url: [null, false],
      index: [null, false],
      latitude: [null, false],
      longitude: [null, false],
      name: [null, false],
      zip: [null, false],
      state: [null, false],
      website_url: [null, false],
      phone: [null, false],
    };

    for (const key in matchupCategories) {
      if (matchupCategories.hasOwnProperty(key)) {
        const value = matchupCategories[key as ColumnRole].match;
        results[key as ColumnRole] = [value, value !== null];
      }
    }

    return results;
  }, [matchupCategories]);

  const mappedColumns: Array<[string, string]> = [];
  Object.entries(matchupColumns ?? {})
    .filter((column): column is [string, string] => !!column[0] && !!column[1])
    .forEach(([key, name]) => mappedColumns.push([name, key]));

  const onSubmit = useCallback((matchup: ColumnsMatchUp) => {
    onClose?.();
    if (spreadsheetId !== null) {
      dispatch(matchupUpdateRequest(
        spreadsheetId,
        isPublicApiActive || !initialMatchUp
          ? matchup
          : {
            // if public api is not active for the current user,
            // however this map has public api columns matching (e.g. it was team-shared to a non-api user)
            // we still want to send those matchings
            ...matchup,
            ...Array.from(publicApiRelatedRoles.values()).reduce((acc, value) => {
              if (initialMatchUp[value][0] !== null) {
                acc[value] = initialMatchUp[value];
              }
              return acc;
            }, {} as ColumnsMatchUp),
          }
      ));
    }
  }, [dispatch, initialMatchUp, isPublicApiActive, onClose, spreadsheetId]);

  if (!initialMatchUp) {
    return null;
  }

  return (
    <MatchupDataComponent
      columns={mappedColumns}
      ensureLocationDataIsSelected={ensureLocationDataIsSelected}
      hideHeading={hideHeading}
      initialColumnsMatchup={initialMatchUp}
      isLoading={!!isLoading}
      isOpen={isOpen}
      isPublicApiActive={isPublicApiActive}
      sections={sections}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
