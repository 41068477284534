import { css } from '@emotion/react';
import {
  faCog, faCompress, faEraser, faExpand, faFilter,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RoundButtonComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { LoaderType } from '~/_shared/baseComponents/loaders';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { togglePresence } from '~/_shared/utils/collections/collections';
import { range } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { MapToolWrapperComponent } from '../../mapToolWrapper/mapToolWrapper.component';

const headerStyle = ({ theme }: ThemeProps) => css({
  width: '100%',
  height: 64,
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  boxSizing: 'border-box',
  backgroundColor: theme.backgroundColors.secondary,
});

const createFiltersButtonStyle = css({
  width: '100%',
});

const rightHeaderStyle = css({
  marginLeft: 'auto',
  display: 'flex',
  gap: 8,
});

const accordionHeaderStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const accordionChildrenStyle = css({
  padding: 0,
});

export type FilterItem = Readonly<{
  title: ReactNode;
  body: ReactNode;
}>;

type FilterToolProps = Readonly<{
  areFiltersOpen: boolean;
  children: ReadonlyArray<FilterItem>;
  isLoading: boolean;
  showAdminControls: boolean;
  onClear: () => void;
  onFilterSetup: () => void;
}>;

export const FilterToolComponent: FC<FilterToolProps> = ({
  areFiltersOpen,
  children,
  isLoading,
  showAdminControls,
  onClear,
  onFilterSetup,
}) => {
  const fullRange = useMemo(() => range(children.length), [children.length]);
  const [expandedIndices, setExpandedIndices] = useState<ReadonlySet<number>>(
    new Set(areFiltersOpen ? fullRange : [])
  );
  const [t] = useTranslation();
  const theme = useTheme();

  const toggleExpandAll = useCallback(() =>
    setExpandedIndices(prev => prev.size === 0 ? new Set(fullRange) : new Set()),
  [fullRange]);

  const accordionData = useMemo(() =>
    children.map((filter, index) => ({
      header: filter.title,
      child: filter.body,
      isExpanded: expandedIndices.has(index),
      onHeadingClick: () => setExpandedIndices(prev => togglePresence(prev, index)),
    })),
  [children, expandedIndices]);

  useEffect(() => {
    setExpandedIndices(new Set(areFiltersOpen ? fullRange : []));
  }, [areFiltersOpen, children.length, fullRange]);

  return (
    <MapToolWrapperComponent
      isLoading={isLoading}
      loaderType={LoaderType.Filter}
      header={(
        <div css={headerStyle({ theme })}>
          {showAdminControls && children.length === 0 && (
            <ButtonComponent
              css={createFiltersButtonStyle}
              text={t('Create Filters')}
              onClick={onFilterSetup}
              prefixIcon={faFilter}
            />
          )}

          {children.length !== 0 && (
            <div css={rightHeaderStyle}>
              <RoundButtonComponent
                icon={expandedIndices.size === 0 ? faExpand : faCompress}
                tooltipLabel={expandedIndices.size === 0 ? t('Expand All') : t('Collapse All')}
                tooltipPlacement={TooltipPlacement.Bottom}
                onClick={toggleExpandAll}
              />

              <RoundButtonComponent
                icon={faEraser}
                onClick={onClear}
                buttonStyle={RoundButtonStyle.Danger}
                tooltipLabel={t('Reset Filters')}
                tooltipPlacement={TooltipPlacement.Bottom}
              />

              {showAdminControls && (
                <RoundButtonComponent
                  icon={faCog}
                  onClick={onFilterSetup}
                  tooltipLabel={t('Edit Filters')}
                  tooltipPlacement={TooltipPlacement.Bottom}
                />
              )}
            </div>
          )}
        </div>
      )}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Bottom]}>
        <AccordionComponent
          data={accordionData}
          headerSize={AccordionHeaderSize.Large}
          headerWrapperStyle={accordionHeaderStyle}
          panelStyle={accordionChildrenStyle}
        />
      </OutlinePanelComponent>
    </MapToolWrapperComponent>
  );
};
