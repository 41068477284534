import { type TranslationFnc } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { MapLimitReachedContentComponent } from '~/appError/specificErrors/mapLimitReachedContent.component';
import { createAppError } from '~/store/modal/modal.actionCreators';

export const createMapLimitReachedAppError = (t: TranslationFnc) => createAppError({
  type: AppErrorType.General,
  title: t('Map creation failed'),
  errorTitle: t('Your Map Limit Has Been Reached.'),
  text: (<MapLimitReachedContentComponent />),
});
