import { css } from '@emotion/react';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  type MainMenuItem,
  MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';

export const MIN_ICON_WIDTH = 61;
export const MIN_ICON_DELIMETER_WIDTH = 20;

export const containerStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  height: '100%',
  width: '100%',
  zIndex: 1000,
});

export const HORIZONTAL_PADDING = 2;
export const menuItemContainerStyle = ({ isMenuSmall }: {isMenuSmall: boolean}) => css({
  display: 'flex',
  justifyContent: isMenuSmall ? 'flex-start' : 'space-between',
  height: '100%',
  padding: `0 ${HORIZONTAL_PADDING}%`,
  width: '100%',

  '& > div': {
    marginRight: isMenuSmall ? 45 : 'inherit',
    height: '100%',
  },
});

export const toolContainerStyle = css({
  paddingLeft: `${HORIZONTAL_PADDING}%`,
  paddingRight: `calc(${HORIZONTAL_PADDING}% + 10px)`,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

export const dropdownContainerStyle = css({
  width: '100%',
});

export const closeIconContainerStyle = css({
  width: '10%',
  justifyContent: 'flex-end',
  display: 'flex',
});

export const closeIconStyle = css({
  fontSize: '20px',
});

export const menuItemFontSize: { [key in MainMenuItem]?: string } = {
  [MainMenuItems.FilterData]: '14px',
  [MainMenuItems.ImportNewData]: '14px',
  [MainMenuItems.ExportData]: '14px',
  [MainMenuItems.SyncData]: '14px',
  [MainMenuItems.AddNewDataRow]: '14px',
  [MainMenuItems.RemoveDataRow]: '14px',
  [MainMenuItems.AddNewDataColumn]: '14px',
  [MainMenuItems.MatchMyLocationData]: '14px',
};
