import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { copy } from '~/_shared/utils/collections/collections';
import {
  createColorWithOpacity, guaranteeHash,
} from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { isNotFoundApiError } from '../../_shared/utils/api/apiError.helpers';
import { useTranslation } from '../../_shared/utils/hooks';
import { AppErrorType } from '../../appError/appErrorType.enum';
import { type ModalProps } from '../../modal/modalType.enum';
import { getBoundaryItemStyle } from '../../sidebar/sidebarApps/mapTools/boundary/boundaryItem.helpers';
import { isBoundaryTerritoryGroupCustom } from '../../sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import { useBoundaryUpdate } from '../../sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryUpdate';
import { BoundaryDeleteResult } from '../../store/boundaries/boundaries.repository';
import { type ExtendedBoundaryIdentifier } from '../../store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '../../store/boundaryGroups/boundaryGroups.selectors';
import { boundaryItemRemoveSuccess } from '../../store/boundaryItems/boundaryItems.actionCreators';
import { useMapBoundariesSelector } from '../../store/boundaryItems/boundaryItems.selectors';
import { boundaryTerritoryGroupsUpdateRequest } from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { createAppError } from '../../store/modal/modal.actionCreators';
import { BoundaryItemEditSettingsComponent } from './boundaryItemEditSettings.component';

export type BoundaryItemSettingsProps = ModalProps<ExtendedBoundaryIdentifier>;

export const BoundaryItemSettingsContainer: FC<BoundaryItemSettingsProps> = (props) => {
  const boundaryItems = useMapBoundariesSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { updateBoundary, isLoading: isBoundaryNameSaveLoading } = useBoundaryUpdate();
  const groupBoundaries = boundaryItems.get(props.boundaryGroupId);
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundary = groupBoundaries?.get(props.boundaryId);
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);
  const isBoundaryTerritoryGroupsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const { onClose } = props;

  const boundaryStyle = getBoundaryItemStyle(boundaryTerritoryGroup, boundary?.id);

  const onSubmit = useCallback(async ({ displayName, fillColor }: { displayName: string; fillColor: ColorResult }) => {
    if (!boundary || !boundaryTerritoryGroup) {
      return;
    }

    if (displayName !== boundary.displayName) {
      try {
        await updateBoundary({
          boundaryId: props.boundaryId,
          boundaryGroupId: props.boundaryGroupId,
          name: displayName,
        });
      }
      catch (e) {
        if (isNotFoundApiError(e)) {
          const isGroupCustom = isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups);
          // boundary was already removed at this point
          onClose();
          dispatch(createAppError({
            type: AppErrorType.General,
            title: t(isGroupCustom ? 'Territory was already removed' : 'Boundary was already removed'),
            errorTitle: t(isGroupCustom ? 'Territory was already removed from the map' : 'Boundary was already removed from the map'),
          }));
          dispatch(boundaryItemRemoveSuccess(props.boundaryId, BoundaryDeleteResult.AlreadyRemoved));

          return;
        }
      }
    }

    const newStyle = {
      color: guaranteeHash(fillColor.hex),
      opacity: fillColor.rgb.a ? fillColor.rgb.a * 100 : 0,
    };

    if (newStyle.color === boundaryStyle.color && newStyle.opacity === boundaryStyle.opacity) {
      onClose();
      return;
    }

    dispatch(boundaryTerritoryGroupsUpdateRequest(
      {
        ...boundaryTerritoryGroup,
        settings: {
          ...boundaryTerritoryGroup.settings,
          boundaryStyle: copy.andReplace(boundaryTerritoryGroup.settings.boundaryStyle, props.boundaryId, newStyle),
        },
      },
      {
        onDone: () => onClose(),
      }
    ));
  }, [boundary, boundaryStyle.color, boundaryStyle.opacity, boundaryTerritoryGroup,
    dispatch, updateBoundary, boundaryGroups, onClose, props.boundaryId, props.boundaryGroupId, t]);

  if (!boundary) {
    return null;
  }

  return (
    <BoundaryItemEditSettingsComponent
      {...props}
      heading={t('Territory Settings')}
      initialFillColor={createColorWithOpacity(boundaryStyle.color, boundaryStyle.opacity / 100)}
      displayName={boundary.displayName}
      onSubmit={onSubmit}
      isLoading={isBoundaryNameSaveLoading || isBoundaryTerritoryGroupsLoading}
      showCreateAnotherButton={false}
    />
  );
};
