import { type TranslationFnc } from '../utils/hooks';

export enum ColumnRole {
  Address1 = 'address',
  Address2 = 'address_line_2',
  City = 'city',
  State = 'state',
  PostCode = 'zip',
  Country = 'country',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  URL = 'website_url',
  Image = 'image_url',
  Email = 'email',
  Phone = 'phone',
  Index = 'index',
}

export const CONTACT_COLUMNS_ROLES = [ColumnRole.Email, ColumnRole.URL, ColumnRole.Phone];

export type PerColumnRole<T> = { [key in ColumnRole]: T };

const optionalColumnRoles: Set<ColumnRole> = new Set([
  ColumnRole.Name,
  ColumnRole.URL,
  ColumnRole.Image,
  ColumnRole.Email,
  ColumnRole.Index,
  ColumnRole.Phone,
]);

export const isOptionalColumnRole = (columnRole: ColumnRole) => optionalColumnRoles.has(columnRole);

export const publicApiRelatedRoles: Set<ColumnRole> = new Set([
  ColumnRole.Index,
]);

export const isPublicApiRelatedRole = (columnRole: ColumnRole) => publicApiRelatedRoles.has(columnRole);

export type ColumnsMatchUp = PerColumnRole<[null | string, boolean]>;

export const createColumnRoleName = (columnRole: ColumnRole, t: TranslationFnc): string => {
  switch (columnRole) {
    case ColumnRole.Address1:
      return t('Address');
    case ColumnRole.Address2:
      return t('Address Line 2');
    case ColumnRole.Index:
      return t('Api Id');
    case ColumnRole.Phone:
      return t('Phone Number');
    case ColumnRole.City:
      return t('City');
    case ColumnRole.Country:
      return t('Country');
    case ColumnRole.Email:
      return t('E-mail Address Column');
    case ColumnRole.Image:
      return t('Image URL Column');
    case ColumnRole.Latitude:
      return t('Latitude');
    case ColumnRole.Longitude:
      return t('Longitude');
    case ColumnRole.Name:
      return t('Location Name / Title');
    case ColumnRole.PostCode:
      return t('Zip / Postal Code');
    case ColumnRole.State:
      return t('State / Province');
    case ColumnRole.URL:
      return t('Website URL Column');
    default:
      throw new Error(`columnRole enum: Unknown column role: "${columnRole}"`);
  }
};
