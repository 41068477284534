import {
  useCallback, useMemo, useState,
} from 'react';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { getItemizedExportBroadcast } from '~/_shared/utils/api/broadcast.helpers';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  getItemizedSpreadsheetData, getItemizedSpreadsheetDataResults, ItemizedExportStatus,
  type ItemizedSpreadsheetDataResponse,
  type ItemizedSpreadsheetDataTextResponse,
} from '~/spreadsheet/itemizeExport.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { type ExportDataFileType } from './exportDataFileType.enum';
import { ExportDataMethod } from './exportDataMethod.enum';

type ItemizedSpreadsheetDataAdditionalColumn = {
  columnName: string;
};

export type ItemizedSpreadsheetDataItemRow = {
  postfixColumnsValues?: string[];
  prefixColumnsValues?: string[];
  rowId?: SpreadsheetRowId;
};

export type ItemizedSpreadsheetDataParams = {
  additionalPostfixColumns?: ItemizedSpreadsheetDataAdditionalColumn[];
  additionalPrefixColumns?: ItemizedSpreadsheetDataAdditionalColumn[];
  exportAsText?: true;
  extension: ExportDataFileType;
  items: {
    rows: ItemizedSpreadsheetDataItemRow[];
  }[];
};

export const useExportItemizedSpreadsheetData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [t] = useTranslation();
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const isMountedRef = useIsComponentMountedRef();
  const isPresentational = useIsMapPresentationalSelector();
  const { replaceCurrentModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);

  const getExportedItemizedSpreadsheetData = useCallback((params: ItemizedSpreadsheetDataParams) => {
    if (!mapId || !clientId) {
      return Promise.reject();
    }

    setIsLoading(true);
    setIsError(false);

    const request = {
      additional_prefix_columns: params.additionalPrefixColumns?.map(col => ({ column_name: col.columnName })),
      additional_postfix_columns: params.additionalPostfixColumns?.map(col => ({ column_name: col.columnName })),
      as_text: params.exportAsText || undefined,
      extension: params.extension,
      items: params.items.map(item => ({
        rows: item.rows.map(row => ({
          prefix_columns_values: row.prefixColumnsValues || [],
          postfix_columns_values: row.postfixColumnsValues || [],
          ...(row.rowId ? {
            row_id: {
              row_id: row.rowId.rowId,
              spreadsheet_id: row.rowId.spreadsheetId,
            },
          } : {}),
        })),
      })),
    };

    return new Promise<ItemizedSpreadsheetDataResponse>((resolve, reject) => {
      const onReject = (error?: string) => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        reject(error);
      };

      const onResultsReady = (hash: string) => {
        getItemizedSpreadsheetDataResults(clientId, hash, isPresentational ? mapId : undefined)
          .then(results => {
            if (results.message === ItemizedExportStatus.PROCESSED) {
              resolve(results);
            }
            else {
              onReject();
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          });
      };

      const checkIfResultsReadyOrTryAgain = (hash: string, attemptNumber: number) => {
        getItemizedSpreadsheetDataResults(clientId, hash, isPresentational ? mapId : undefined).then(results => {
          if (results.message === ItemizedExportStatus.PROCESSING && attemptNumber < 15) {
            setTimeout(() => checkIfResultsReadyOrTryAgain(hash, attemptNumber++), 5000);
          }
          else if (results.message === ItemizedExportStatus.PROCESSED) {
            resolve(results);
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          }
          else {
            onReject();
            if (isMountedRef.current) {
              setIsLoading(false);
            }
          }
        });
      };

      getItemizedSpreadsheetData(clientId, request, isPresentational ? mapId : undefined)
        .then(response => {
          if (response.message === ItemizedExportStatus.INITIALIZED) {
            getItemizedExportBroadcast(e => { // if user is subscribed to WS broadcast let's use it
              if (e.hash === response.hash) {
                if (e.status === ItemizedExportStatus.SUCCESS) {
                  onResultsReady(response.hash);
                }
                else {
                  onReject();
                }
              }
            }, () => { // if user is not subscribed to WS broadcast, we will attempt to get results every 5 seconds
              checkIfResultsReadyOrTryAgain(response.hash, 0);
            });
          }
          else {
            onReject();
          }
        })
        .catch(error => {
          onReject(error);
        });
    });
  }, [clientId, isMountedRef, isPresentational, mapId]);

  const handleGetItemizedSpreadsheetDataResponse = useCallback((props: {
    exportDataMethod: ExportDataMethod;
    responseData: ItemizedSpreadsheetDataResponse;
    setErrorMessage: (errorMessage: string) => void;
    copyToClipboard: (text: string) => void;
    showCopiedToClipboardMessage: () => void;
  }) => {
    if (props.exportDataMethod === ExportDataMethod.Clipboard && (props.responseData as ItemizedSpreadsheetDataTextResponse).data) {
      const fileContent = (props.responseData as ItemizedSpreadsheetDataTextResponse).data.data;

      if (!fileContent) {
        props.setErrorMessage(t('No locations to export'));
        return;
      }
      props.copyToClipboard(fileContent);
      props.showCopiedToClipboardMessage();
    }
    else if (props.responseData.data.file) {
      const fileName = props.responseData.data.file;

      if (!fileName) {
        props.setErrorMessage(t('No locations to export'));
        return;
      }

      const exportUrl = getExportUrl(fileName);
      downloadFile(exportUrl);

      openDownloadResultsModal({
        downloadUrl: exportUrl,
      });
    }
  }, [openDownloadResultsModal, t]);

  return useMemo(() => ({
    isLoading,
    isError,
    getExportedItemizedSpreadsheetData,
    handleGetItemizedSpreadsheetDataResponse,
  }), [getExportedItemizedSpreadsheetData, handleGetItemizedSpreadsheetDataResponse, isError, isLoading]);
};
