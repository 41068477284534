import { css } from '@emotion/react';
import {
  forwardRef,
  type ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { type AxisOffsets } from '../../types/coordinateSystem/coordinateSystem';
import { getNodeScrollableParents } from '../../utils/dom/dom.helpers';
import { useHookWithRefCallback } from '../../utils/hooks/useHookWithRefCallback';
import { FixedStickyContainerPortalComponent } from './fixedStickyContainerPortal.component';
import { useOnVisibilityLost } from './useOnVisibilityLost';

type FixedStickyContainerProps = Readonly<{
  isOpen: boolean;
  triggerRef: HTMLElement | null;
  onClose: () => void;
  children: ({ maxHeight }: { maxHeight: number }) => ReactNode;
  offsets?: AxisOffsets;
  forcePosition?: 'top' | 'bottom';
  containerMinHeight?: number;
}>;

type ContainerDescriptor = {
  top: number;
  left: number;
  width: number;
  maxHeight: number;
};

const containerStyle = ({ top, left, bottom, width }: { top?: number; left?: number; bottom?: number; width: number }) => css({
  position: 'fixed',
  top,
  left,
  width,
  bottom,
  right: 'auto',
  zIndex: 10010,
});

export const FixedStickyContainerComponent = forwardRef<HTMLElement, FixedStickyContainerProps>((props, ref) => {
  const { onClose } = props;
  const [containerRef, setContainerRef] = useHookWithRefCallback<HTMLDivElement>();

  const [containerDescriptor, setListPosition] = useState<ContainerDescriptor>({
    left: -99999,
    top: 0,
    width: 0,
    maxHeight: 0,
  });

  const scrollableElements = useMemo(() => {
    if (!props.triggerRef || !props.isOpen) {
      return [];
    }
    else {
      return getNodeScrollableParents(props.triggerRef);
    }
  }, [props.triggerRef, props.isOpen]);

  const updateListPosition = useCallback(() => {
    if (!props.triggerRef) {
      return;
    }

    const rect = props.triggerRef.getBoundingClientRect();

    let maxHeight = document.documentElement.clientHeight - rect.bottom - 10;

    if (props.containerMinHeight && props.containerMinHeight > maxHeight) {
      maxHeight = props.containerMinHeight;
    }

    setListPosition({
      top: Math.round(rect.bottom) + (props.offsets?.yOffset || 0),
      left: Math.ceil(rect.left) + (props.offsets?.xOffset || 0),
      width: Math.round(rect.width),
      maxHeight,
    });
  }, [props.triggerRef, props.offsets, props.containerMinHeight]);

  useOnVisibilityLost(props.triggerRef, () => !props.isOpen && onClose());

  useEffect(() => {
    if (!props.isOpen || !props.triggerRef) {
      return;
    }

    let ticking = false;
    const callback = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateListPosition();
          ticking = false;
        });

        ticking = true;
      }
    };

    for (const node of scrollableElements) {
      node.addEventListener('scroll', callback);
    }

    return () => {
      for (const node of scrollableElements) {
        node.removeEventListener('scroll', callback);
      }
    };
  }, [props.isOpen, props.triggerRef, scrollableElements, updateListPosition]);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen) {
      updateListPosition();
    }
  }, [props.isOpen, updateListPosition]);

  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!containerRef) {
      setContainerHeight(undefined);
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (props.isOpen) {
        setContainerHeight(containerRef.scrollHeight);
      }
    });
    resizeObserver.observe(containerRef);
    return () => resizeObserver.disconnect();
  }, [containerRef, props.isOpen]);

  const bottomOrTopCondition = containerHeight && containerHeight > containerDescriptor.maxHeight;
  const top = (!bottomOrTopCondition || props.forcePosition === 'top') ? containerDescriptor.top : undefined;
  const bottom = (bottomOrTopCondition || props.forcePosition === 'bottom') ? 0 : undefined;

  return (
    <FixedStickyContainerPortalComponent ref={ref}>
      <div
        ref={setContainerRef}
        css={containerStyle({
          width: containerDescriptor.width,
          top,
          bottom,
          left: containerDescriptor.left,
        })}
      >
        {props.children({ maxHeight: containerDescriptor.maxHeight })}
      </div>
    </FixedStickyContainerPortalComponent>
  );
});
