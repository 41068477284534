import {
  type FC, useEffect, useState,
} from 'react';

export type CounterProps = Readonly<{
  className?: string;
  startValue: number;
  endValue: number;
  renderText: (progress: number) => string;
  duration: number;
}>;

export const CounterComponent: FC<CounterProps> = (props) => {
  const [progress, setProgress] = useState(props.startValue);

  useEffect(() => {
    const step = props.endValue - props.startValue;

    if (step === 0) {
      return;
    }

    let counter = 0;
    const interval = Math.floor(props.duration / step);

    const intervalId = setInterval(() => {
      let value = props.startValue + Math.round(counter * interval / props.duration * 100);
      value = Math.min(props.endValue, value);
      setProgress(value);
      counter++;

      if (value >= props.endValue) {
        clearInterval(intervalId);
        return;
      }
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.startValue, props.endValue, props.duration]);

  return (
    <div css={props.className}>
      {props.renderText(progress)}
    </div>
  );
};
