import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  HOME_ROUTE, LOGIN_ROUTE,
} from '~/_shared/constants/routes';
import { delay } from '~/_shared/utils/delay';
import { useAuthContext } from '~/authorization/authContext/authContext';
import { ImpersonationService } from '~/authorization/impersonation';
import { userSignOutRequest } from '~/store/userData/userData.actionCreators';

type LogOutOptions = {
  redirectToLogin: boolean;
};

export const useLogOutUser = (options: LogOutOptions = { redirectToLogin: true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { removeAuthTokens } = useAuthContext();

  return useCallback(async () => {
    if (ImpersonationService.isImpersonated()) {
      await ImpersonationService.end();
    }
    else {
      dispatch(userSignOutRequest(async () => {
        if (options.redirectToLogin) {
          // move navigate event to the end of the event loop queue
          // to prevent race conditions with other Routes
          await delay(0);

          navigate(LOGIN_ROUTE, { state: { from: HOME_ROUTE } });
        }
        removeAuthTokens();
      }));
    }
  }, [dispatch, navigate, options.redirectToLogin, removeAuthTokens]);
};
