import { css } from '@emotion/react';
import {
  forwardRef, useMemo,
} from 'react';
import { type TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type FaIcon, FontAwesomeIcon,
} from '../../icon/fontAwesomeIcon.component';
import { buttonStyle } from './iconButton.styles';
import {
  type IconButtonSize, type IconButtonStyle,
} from './iconButton.types';

const CSS_CLASSNAME = 'icon-button-component';

type IconButtonDispatchProps = Readonly<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;
type IconButtonStateProps = Readonly<{
  buttonStyle?: IconButtonStyle;
  className?: string;
  icon: FaIcon;
  isDisabled?: boolean;
  size?: IconButtonSize;
  tooltipLabel?: string;
  tooltipPlacement?: TooltipPlacement;
  children?: React.ReactNode;
}>;

export type IconButtonProps = IconButtonStateProps & IconButtonDispatchProps;

export const IconButtonComponent = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const theme = useTheme();

  const buttonClassname = useMemo(() => `${CSS_CLASSNAME} ${props.className || ''}`, [props.className]);

  return (
    <TooltipDeprComponent
      tooltipContent={props.tooltipLabel}
      placement={props.tooltipPlacement}
    >
      <button
        ref={ref}
        type="button"
        css={buttonStyle({
          theme,
          btnStyle: props.buttonStyle,
          size: props.size,
          isDisabled: props.isDisabled,
        })}
        onClick={props.onClick}
        className={buttonClassname}
        disabled={props.isDisabled}
      >
        <FontAwesomeIcon
          css={css({ margin: 0 })}
          icon={props.icon}
        />
        {props.children}
      </button>
    </TooltipDeprComponent>
  );
});
