import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHome, faLockAlt, faMap, faTable,
} from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DATA_ROUTE, HOME_ROUTE, LOGIN_ROUTE, MAP_ROUTE, Page, PageTitle, SUBSCRIBE_ROUTE, SUBSCRIBED_ROUTE,
} from '~/_shared/constants/routes';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { isRouteActive } from '~/_shared/utils/tests/router.helpers';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { getDataUrl } from '../../data/data.helpers';
import {
  getAbsolutePresentationalMapUrl,
  getMapUrl,
} from '../../map/map.helpers';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';

export const HOME_NAVIGATION_TEST_ID = 'navigate-home';

export type NavigationItem = {
  path: string;
  page: Page;
  icon: IconProp;
  text: string;
  isDisabledWithMessage: null | string;
  testid?: string;
};

export const getHomeNavigationItem = (t: TranslationFnc): NavigationItem => ({
  path: HOME_ROUTE,
  page: Page.ADMIN_HOME,
  icon: faHome,
  text: t(PageTitle.HOME),
  isDisabledWithMessage: null,
  testid: HOME_NAVIGATION_TEST_ID,
});

const getSubscribeNavigationItem = (t: TranslationFnc): NavigationItem => ({
  path: SUBSCRIBE_ROUTE,
  page: Page.SUBSCRIBE,
  icon: faHome,
  text: t(PageTitle.SUBSCRIBE),
  isDisabledWithMessage: null,
  testid: 'navigate-subscribe',
});

const getSubscribedNavigationItem = (t: TranslationFnc): NavigationItem => ({
  path: SUBSCRIBED_ROUTE,
  page: Page.SUBSCRIBED,
  icon: faHome,
  text: t(PageTitle.CONGRATULATIONS),
  isDisabledWithMessage: null,
  testid: 'navigate-subscribed',
});

export const getLoginNavigationItem = (t: TranslationFnc): NavigationItem => ({
  path: LOGIN_ROUTE,
  page: Page.LOGIN,
  icon: faLockAlt,
  text: t(PageTitle.LOGIN),
  isDisabledWithMessage: null,
  testid: 'navigate-login',
});

const getMissingMapIdDisabledMessage = (t: TranslationFnc) => t(
  'Select a map from {{mapList}}',
  { mapList: t('My Maps') });

const getDataMapPageNavigationItem = (mapId: number | null, t: TranslationFnc): NavigationItem => ({
  path: mapId !== null ? getDataUrl(mapId) : DATA_ROUTE,
  page: Page.ADMIN_DATA,
  icon: faTable,
  text: t(PageTitle.DATA),
  isDisabledWithMessage: mapId === null ? getMissingMapIdDisabledMessage(t) : null,
  testid: 'navigate-data',
});

export const getAdminMapPageNavigationItem = (mapId: number | null, t: TranslationFnc): NavigationItem => ({
  path: mapId !== null ? getMapUrl(mapId) : MAP_ROUTE,
  page: Page.ADMIN_MAP,
  icon: faMap,
  text: t(PageTitle.MAP),
  isDisabledWithMessage: mapId === null ? getMissingMapIdDisabledMessage(t) : null,
  testid: 'navigate-map',
});

export const getNavigationItems = (mapId: null | number, t: TranslationFnc): NavigationItem[] => [
  getHomeNavigationItem(t),
  getAdminMapPageNavigationItem(mapId, t),
  getDataMapPageNavigationItem(mapId, t),
];

export const getActiveNavigationItem = (pathname: string, navigationItems: NavigationItem[]): NavigationItem | undefined => {
  return navigationItems.find(item => isRouteActive(pathname, item.path));
};

export const useGetCurrentPageInfo = (): NavigationItem => {
  const location = useLocation();
  const mapInfo = useMapInfoDataSelector();
  const isPresentationalView = useIsMapPresentationalSelector();
  const [t] = useTranslation();

  const navigationItems = useMemo(
    () => getNavigationItems(mapInfo?.id || null, t),
    [t, mapInfo]
  );

  return useMemo(() => {
    if (isPresentationalView) {
      return {
        path: mapInfo?.shareId ? getAbsolutePresentationalMapUrl(mapInfo?.shareId) : '',
        page: Page.PRESENTATIONAL,
        icon: faMap,
        text: t(PageTitle.MAP),
        isDisabledWithMessage: null,
      };
    }
    if (isRouteActive(location.pathname, SUBSCRIBE_ROUTE)) {
      return getSubscribeNavigationItem(t);
    }
    if (isRouteActive(location.pathname, SUBSCRIBED_ROUTE)) {
      return getSubscribedNavigationItem(t);
    }
    if (isRouteActive(location.pathname, LOGIN_ROUTE)) {
      return getLoginNavigationItem(t);
    }
    return getActiveNavigationItem(location.pathname, navigationItems) ?? getHomeNavigationItem(t);
  }, [isPresentationalView, location.pathname, mapInfo, navigationItems, t]);
};
