import { css } from '@emotion/react';
import {
  type FC, type FormEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { debounce } from '~/_shared/utils/debounce';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { isTextEmpty } from '../../../_shared/utils/text/text.helpers';
import { type CustomBoundaryTerritory } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { translateBoundaryTerritoryDisplayName } from '../boundarySettings.helpers';

type BoundarySettingsCustomTerritoryProps = Readonly<{
  territory: CustomBoundaryTerritory;
  onTerritoryNameChange: (boundaryTerritoryId: string, newName: string) => void;
}>;

const tableInputStyle = ({ theme, isValid }: ThemeProps<{ isValid: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  minWidth: 50,
  borderColor: isValid ? undefined : theme.borderColors.error,
});

export const BoundarySettingsCustomTerritoryDataComponent: FC<BoundarySettingsCustomTerritoryProps> = (props) => {
  const { territory } = props;
  const onTerritoryNameChangeRef = useRef(props.onTerritoryNameChange);
  const [t] = useTranslation();
  const [displayName, setDisplayName] = useState(translateBoundaryTerritoryDisplayName(territory, t));
  const theme = useTheme();

  const submitNameChangeDebounced = useMemo(() =>
    debounce((name: string) => onTerritoryNameChangeRef.current(territory.boundaryTerritoryId, name), 1000),
  [territory.boundaryTerritoryId]);

  const onDisplayNameChange = (newDisplayName: string) => {
    setDisplayName(newDisplayName);
    submitNameChangeDebounced(newDisplayName);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (territory.displayName === displayName) {
      return;
    }

    onTerritoryNameChangeRef.current(territory.boundaryTerritoryId, displayName);
  };

  useEffect(() => {
    setDisplayName(territory.displayName);
  }, [territory.displayName]);

  const isValid = !isTextEmpty(displayName);

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <TextInputComponent
          css={tableInputStyle({ theme, isValid })}
          onChange={onDisplayNameChange}
          value={displayName}
          title={displayName}
        />
      </form>
    </>
  );
};
