import { type WebglOverlayTextBoxCalloutTriangleSide } from '~/_shared/constants/webgl.constants';

export enum MapObjectOutlineSize {
  Small = 7.5,
  Default = 10,
  Enlarged = 12,
}

export enum MapObjectOutlineCircleToDotRatio {
  Thin = 1.25,
  Thick = 1.5,
}

export enum MapObjectOutlineThinCircleSize {
  Small = MapObjectOutlineSize.Small * MapObjectOutlineCircleToDotRatio.Thin,
  Default = MapObjectOutlineSize.Default * MapObjectOutlineCircleToDotRatio.Thin,
  Enlarged = MapObjectOutlineSize.Enlarged * MapObjectOutlineCircleToDotRatio.Thin,
}

export enum MapObjectOutlineThickCircleSize {
  Small = MapObjectOutlineSize.Small * MapObjectOutlineCircleToDotRatio.Thick,
  Default = MapObjectOutlineSize.Default * MapObjectOutlineCircleToDotRatio.Thick,
  Enlarged = MapObjectOutlineSize.Enlarged * MapObjectOutlineCircleToDotRatio.Thick,
}

export enum MapObjectLabelTextSize {
  Default = 12,
  AreaLabelMin = 2,
}

export enum MapObjectLabelLetterSpacing {
  Default = 0,
}

export type MapObjectOutlineConfig = {
  readonly size?: number;
  readonly color?: WebglColor;
  readonly zIndex: number;
  readonly transparent?: boolean;
  readonly offsetX?: number;
  readonly offsetY?: number;
};

export type MapObjectOutlineCircleConfig = {
  readonly size?: number;
  readonly color?: WebglColor;
  readonly zIndex: number;
  readonly style?: 'thick' | 'thin';
  readonly offsetX?: number;
  readonly offsetY?: number;
};

export type MapObjectArrowConfig = {
  readonly color?: WebglColor;
  readonly cornerBorderVerticesNum?: number;
  readonly minVisibleLength?: number;
  readonly shadow?: number;
  readonly strokeColor?: WebglColor;
  readonly strokeWidth?: number;
  readonly tipLength?: number | string;
  readonly tipWidth?: number | string;
  readonly width?: number;
  readonly sizeOnLevel?: number;
  readonly zIndex: number;
};

export type MapObjectLabelConfig = {
  readonly text: string;
  readonly boundaries?: WebglOverlayLabelBoundariesConfig;
  readonly color: WebglColor;
  readonly opacity?: number;
  readonly fillWidth?: number;
  readonly font?: string;
  readonly fontSize?: number;
  readonly letterSpacing?: number;
  readonly verticalAnchor?: 'center' | 'top' | 'bottom';
  readonly horizontalAnchor?: 'center' | 'left' | 'right';
  readonly staticSize?: boolean;
  readonly sizeOnLevel?: number;
  readonly interactive?: boolean;
  readonly offsetX?: number;
  readonly offsetY?: number;
};

export type MapObjectLabelCalloutConfig = {
  readonly borderColor?: WebglColor;
  readonly borderOpacity?: number;
  readonly borderWidth?: number;
  readonly borderRadius?: number;
  readonly fillColor?: WebglColor;
  readonly fillOpacity?: number;
  readonly triangle?: boolean;
  readonly triangleHeight?: number;
  readonly triangleSide?: WebglOverlayTextBoxCalloutTriangleSide;
  readonly triangleWidth?: number;
  readonly offset?: { x: number; y: number };
  readonly width?: number;
  readonly height?: number;
  readonly sizeOnLevel?: number;
  readonly staticSize?: boolean;
  readonly interactive?: boolean;
};

export type MapObjectPolylineConfig = {
  readonly color: WebglColor;
  readonly style: 'solid' | 'dash';
  readonly width: number;
  readonly zIndex: number;
};

export type MapObjectPolygonConfig = {
  readonly fillColor: WebglColor;
  readonly borderWidth?: number;
  readonly borderColor?: WebglColor;
  readonly zIndex: number;
  readonly borderZIndex: number;
};

export type MapObjectCircleAreaConfig = {
  readonly borderColor: WebglColor;
  readonly borderWidth: number;
  readonly fillColor: WebglColor;
  readonly zIndex: number;
  readonly units?: CircleAreaUnit;
  readonly staticSize?: boolean;
  readonly autoScale?: boolean;
};

export type MapObjectCustomMarkerConfig = {
  readonly template: string;
  readonly color?: WebglColor;
  readonly colorOverlay?: boolean;
  readonly offset?: { x: number; y: number };
  readonly textureOffset?: { x: number; y: number };
  readonly opacity?: number;
  readonly opacityBackground?: number;
  readonly interactive?: boolean;
};

export enum MapObjectMarkerType {
  Outline = 'outline',
  XMark = 'x-mark',
  Cirle = 'circle',
  Custom = 'custom'
}

export enum MapObjectLabelType {
  Outline = 'outline',
  Main = 'main'
}

export const mapObjectEntities = [
  'polygon', 'polygonBorder', 'circleArea', 'customMarkerBackground', 'line', 'customMarker', 'arrow',
  'labelCallout', 'labelText', 'outlineCircle', 'transparentOutlineDot', 'outlineDot', 'outlineLabel', 'removeButton',
] as const;
export type MapObjectEntity = typeof mapObjectEntities[number];
export type MapObjectZIndex = {
  [P in MapObjectEntity]: number;
};

export const dummyMapObjectZindex = mapObjectEntities.reduce((zIndex, entity) => {
  zIndex[entity] = 0;
  return zIndex;
}, {} as Partial<MapObjectZIndex>) as MapObjectZIndex;
