import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { mapInfoUpdateRequest } from '../../../store/mapInfo/mapInfo.actionCreators';
import {
  useIsConnectedLayeredMapSelector,
  useMapInfoDataSelector,
} from '../../../store/mapInfo/mapInfo.selectors';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { SaveMapInfoComponent } from './saveMapInfo.component';

export const SaveMapInfoContainer: FC = () => {
  const mapName = useSelector<string>(state => state.map.mapInfo.data?.name || '');
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const mapDescription = useSelector<string | null>(state => state.map.mapInfo.data?.description || null);
  const mapId = useMapIdSelector() ?? null;
  const clientId = useClientIdSelector();
  const errors = useSelector(state => state.map.mapInfo.updateErrors);
  const mapInfo = useMapInfoDataSelector();

  const dispatch = useDispatch();

  const updateMapName = (name: string) => {
    if (clientId === null || mapId === null || mapName === name) {
      return;
    }

    dispatch(mapInfoUpdateRequest(clientId, mapId, {
      name,
    }));
  };

  const updateMapDescription = (description: string) => {
    if (clientId === null || mapId === null || mapDescription === description) {
      return;
    }

    dispatch(mapInfoUpdateRequest(clientId, mapId, {
      description,
    }));
  };

  return (
    <SaveMapInfoComponent
      errors={errors}
      isMapLayered={isLayeredMapConnected}
      isSnapshot={!!mapInfo?.parentMap}
      mapDescription={mapDescription}
      mapName={mapName}
      onDescriptionUpdate={updateMapDescription}
      onNameUpdate={updateMapName}
    />
  );
};
