export const refreshPage = () => {
  window.location.reload();
};

export const downloadFile = (fileUri: string, options?: { fileName?: string }) => {
  const element = document.createElement('a');
  element.setAttribute('href', fileUri);
  if (options?.fileName) {
    element.setAttribute('download', options.fileName);
  }

  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};
