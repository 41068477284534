import {
  faLocationDot, faLocationDotSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  hideAllMarkers, showAllMarkers,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { useHideAllMarkersSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useMarkersVisibilityStatusBarItem = () => {
  const areAllMarkersHidden = useHideAllMarkersSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  return useMemo<MapStatusBarItem[]>(() => {
    return [{
      isVisible: !areAllMarkersHidden,
      label: t('Map Markers'),
      onVisibilityChange: (shouldMarkersBeVisible) => {
        if (shouldMarkersBeVisible) {
          dispatch(showAllMarkers());
        }
        else {
          dispatch(hideAllMarkers());
        }
      },
      switch: {
        onIcon: faLocationDot,
        offIcon: faLocationDotSlash,
      },
    }];
  }, [areAllMarkersHidden, dispatch, t]);
};
