import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';

export const drawingImageSelectorEditedDrawingFileIdSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingImageSelector.editedDrawingFileId;
export const useDrawingImageSelectorEditedDrawingFileId = () => useSelector(drawingImageSelectorEditedDrawingFileIdSelector);

export const drawingImageSelectorNewInstanceFileIdSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingImageSelector.newInstanceFileId;
export const useDrawingImageSelectorNewInstanceFileId = () => useSelector(drawingImageSelectorNewInstanceFileIdSelector);

export const drawingImageSelectorEditedDrawingInitialFileIdSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingImageSelector.editedDrawingInitialFileId;
