export enum LassoConfiguration {
  LassoTool = 'LassoTool',
  RoutingSelection = 'RoutingSelection'
}

export type LassoState = Readonly<{
  active: false;
} | {
  active: true;
  configuration: LassoConfiguration;
}>;
