import {
  convertColorToWebGLColor, mixColorMemoized,
} from '../colors/colors.helpers';

export const getLabelStroke = (
  labelProps: Readonly<{
    backgroundColor: Readonly<{
      opacity?: number;
      selectedColor: string;
    }>;
    fontColor: Readonly<{
      opacity?: number;
      selectedColor: string;
    }>;
  }>
) => {
  const strokeColor = mixColorMemoized(
    labelProps.fontColor.selectedColor,
    labelProps.backgroundColor.selectedColor,
    0.5
  );

  return {
    strokeWidth: 0.45,
    strokeColor: convertColorToWebGLColor(
      strokeColor.toHex(),
      (labelProps.backgroundColor.opacity || 1) / 3
    ),
  };
};

const MAX_MARKER_LABEL_TEXT_LENGTH = 50;
export const truncateMarkerLabelText = (text: string) => {
  if (typeof text === 'string' && text.length > MAX_MARKER_LABEL_TEXT_LENGTH) {
    return text.substring(0, MAX_MARKER_LABEL_TEXT_LENGTH - 3).trimEnd() + '...';
  }
  return text;
};
