import { apiPost } from '../utils/api/api.helpers';

export const shareNavigationLink = (
  email: string,
  locationName: string,
  lat: number,
  lng: number,
): Promise<void> => {
  const requestUrl = '/api/navigation-link/share';

  return apiPost(requestUrl, {
    email,
    location_name: locationName,
    lat,
    lng,
  });
};
