import {
  faCar, faEarthAmericas, faGlobe, faRobot,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { ToolboxItemComponent } from '~/_shared/components/toolboxContainer/toolboxItemComponent';
import { CONFIG } from '~/_shared/constants/config';
import { ProximityType } from '~/_shared/types/proximity/proximity.enums';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  boundaryFormSetType,
  boundaryFormSetTypeAndMode,
} from '~/store/frontendState/mapTools/boundary/boundaryForm/boundaryForm.actionCreators';
import { setType } from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { CreateCustomBoundaryMode } from './boundary/loadBoundaryPane/createCustomBoundaryPane/createCustomBoundaryGroupPane.component';
import { BoundaryActionType } from './boundary/loadBoundaryPane/loadBoundaryPane.component';
import {
  mapToolIconMap, mapToolTitleMap,
} from './mapTools.helpers';

const MapToolToolboxItemComponent = ({ tool, onClick }: {tool: MapTool; onClick: (tool: MapTool) => void}) => {
  const [t] = useTranslation();

  return (
    <ToolboxItemComponent
      icon={mapToolIconMap[tool]}
      title={t(mapToolTitleMap[tool])}
      onClick={() => onClick(tool)}
    />
  );
};

const GeographicBoundaryToolboxItemContainer = ({ onClick }: {onClick: (tool: MapTool) => void}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(boundaryFormSetType(BoundaryActionType.LoadGeographicBoundary));
    onClick(MapTool.Boundary);
  }, [dispatch, onClick]);

  return (
    <ToolboxItemComponent
      icon={faEarthAmericas}
      title={t('Geographic Boundary')}
      onClick={handleClick}
    />
  );
};

const TerritoriesToolboxItemContainer = ({ onClick }: {onClick: (tool: MapTool) => void}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(boundaryFormSetType(BoundaryActionType.CreateTerritories));
    onClick(MapTool.Boundary);
  }, [dispatch, onClick]);

  return (
    <ToolboxItemComponent
      icon={faGlobe}
      title={t('Territories')}
      onClick={handleClick}
    />
  );
};

const AiTerritoriesToolboxItemContainer = ({ onClick }: {onClick: (tool: MapTool) => void}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(boundaryFormSetTypeAndMode(BoundaryActionType.CreateTerritories, CreateCustomBoundaryMode.FromAI));
    onClick(MapTool.Boundary);
  }, [dispatch, onClick]);

  return (
    <ToolboxItemComponent
      icon={faRobot}
      title={t('Auto Territory Builder')}
      onClick={handleClick}
    />
  );
};

const DistanceRadiusCirclesToolboxItemContainer = ({ onClick }: {onClick: (tool: MapTool) => void}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(setType(ProximityType.DistanceRadius));
    onClick(MapTool.Proximity);
  }, [dispatch, onClick]);

  return (
    <ToolboxItemComponent
      icon={mapToolIconMap[MapTool.Proximity]}
      title={t('Distance Radius Circles')}
      onClick={handleClick}
    />
  );
};

const DriveTimePolygonToolboxItemContainer = ({ onClick }: {onClick: (tool: MapTool) => void}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleClick = useCallback(() => {
    dispatch(setType(ProximityType.DriveTimePolygon));
    onClick(MapTool.Proximity);
  }, [dispatch, onClick]);

  return (
    <ToolboxItemComponent
      icon={faCar}
      title={t('Drive Time Polygon')}
      onClick={handleClick}
    />
  );
};

type ToolSelectorProps = Readonly<{
  selectTool: (tool: MapTool) => void;
}>;

export const ToolSelectorComponent: FC<ToolSelectorProps> = ({ selectTool }) => {
  return (
    <div>
      <MapToolToolboxItemComponent
        tool={MapTool.Grouping}
        onClick={selectTool}
      />
      <MapToolToolboxItemComponent
        tool={MapTool.Filter}
        onClick={selectTool}
      />
      <MapToolToolboxItemComponent
        tool={MapTool.Layering}
        onClick={selectTool}
      />
      <MapToolToolboxItemComponent
        tool={MapTool.Routing}
        onClick={selectTool}
      />
      <GeographicBoundaryToolboxItemContainer onClick={selectTool} />
      <TerritoriesToolboxItemContainer onClick={selectTool} />
      <AiTerritoriesToolboxItemContainer onClick={selectTool} />
      <DistanceRadiusCirclesToolboxItemContainer onClick={selectTool} />
      <DriveTimePolygonToolboxItemContainer onClick={selectTool} />
      <MapToolToolboxItemComponent
        tool={MapTool.HeatMap}
        onClick={selectTool}
      />
      <MapToolToolboxItemComponent
        tool={MapTool.Drawing}
        onClick={selectTool}
      />
      {CONFIG.ENABLE_PLACES && (
        <MapToolToolboxItemComponent
          tool={MapTool.Places}
          onClick={selectTool}
        />
      )}
    </div>
  );
};
