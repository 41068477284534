import {
  type FC, memo, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapOutlinePositionInstance } from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { MapLabelComponent } from '~/map/map/mapObjects/mapLabel/mapLabel.component';
import { MapMarkerComponent } from '~/map/map/mapObjects/mapMarker/mapMarker.component';
import { MapMarkerContainer } from '~/map/map/mapObjects/mapMarker/mapMarker.container';
import {
  type MapMarkerInstance, MarkerOutlineMode,
} from '~/map/map/mapObjects/mapMarker/mapMarkerModel';
import { MapMarkerTemplateLoaderContainer } from '~/map/map/mapObjects/mapMarker/mapMarkerTemplateLoader.container';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type MarkerTemplate } from '~/map/map/markers/manager/mapMarkerTemplates';
import { type DrawingItemImage } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type LatLng } from '../../../../_shared/types/latLng';

type MapImageMarkerProps = {
  instance: DrawingItemImage;
  size: number | null;
  zIndex: MapObjectZIndex;
  markerTemplate: MarkerTemplate;
  onClick?: (id: Uuid, event: MapObjectClickEventArgs) => void;
  onRightClick?: (id: Uuid, event: MapObjectClickEventArgs) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  onDragStart?: (markerId: Uuid, latLng: LatLng) => void;
  onDragMove?: (markerId: Uuid, latLng: LatLng) => void;
  onDragEnd?: (markerId: Uuid) => void;
};

const DrawingToolImageInstanceMarkerContainer: FC<MapImageMarkerProps> = (props) => {
  const { instance, size, zIndex, markerTemplate } = props;
  const { onClick, onRightClick, onMouseOut, onMouseOver, onDragStart, onDragMove, onDragEnd } = props;

  const mapMarker: MapMarkerInstance | null = useMemo(() => {
    if (!size) {
      return null;
    }

    return ({
      id: instance.id,
      anchor: 'center',
      size,
      ...instance.center,
      aspectRatio: markerTemplate ? markerTemplate?.size.w / markerTemplate?.size.h : undefined,
    });
  }, [instance.center, instance.id, markerTemplate, size]);

  const renderImageMarker = useCallback((key: string, markerInstance: MapMarkerInstance) => {
    if (!markerTemplate) {
      return null;
    }

    return (
      <MapMarkerComponent
        key={key}
        marker={markerInstance}
        visuals={{
          template: markerTemplate.name,
          offset: { x: 0, y: 0 },
          interactive: true,
        }}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
      />
    );
  }, [markerTemplate, onClick, onRightClick, onMouseOut, onMouseOver, onDragStart, onDragMove, onDragEnd]);

  const renderFrame = useMemo(() => {
    if (!instance.settings.showImageBorders) {
      return;
    }

    return (key: string, marker: MapMarkerInstance) => {
      const aspectRatio = marker.aspectRatio ?? 1;
      const width = aspectRatio < 1 ? marker.size * aspectRatio : marker.size;
      const height = aspectRatio > 1 ? marker.size / aspectRatio : marker.size;

      const frameSize = marker.size < 75 ? 1.5 : 3;
      const borderRadius = marker.size < 75 ? 0 : 10;

      return (
        <MapLabelComponent
          key={key}
          label={marker}
          visuals={{
            callout: {
              width: width + frameSize * 2,
              height: height + frameSize * 2,
              fillColor: [0, 0, 0, 1],
              fillOpacity: 1,
              borderRadius,
              interactive: false,
            },
          }}
        />
      );
    };
  }, [instance.settings.showImageBorders]);

  if (!markerTemplate || !mapMarker) {
    return null;
  }

  return (
    <MapMarkerTemplateLoaderContainer
      template={markerTemplate}
    >
      <MapMarkerContainer
        marker={mapMarker}
        zIndex={zIndex}
        outlineMode={MarkerOutlineMode.Offset}
        renderMarker={renderImageMarker}
        renderOutline={props.renderOutline}
        renderBackground={renderFrame}
      />
    </MapMarkerTemplateLoaderContainer>
  );
};

const pureComponent = memo(DrawingToolImageInstanceMarkerContainer);
export { pureComponent as DrawingToolImageInstanceMarkerContainer };
