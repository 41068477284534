import { css } from '@emotion/react';
import {
  useMemo, useState,
} from 'react';
import { type FileRejection } from 'react-dropzone';
import { TabsComponent } from '~/_shared/components/tabs/tabs.component';
import { type TabsData } from '~/_shared/components/tabs/tabs.types';
import { type SelectGroupProps } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import {
  type CustomMarkerSettings,
  type StandardMarkerSettings,
  type TemporaryMarkerVisualSettings,
} from '~/_shared/types/marker.types';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { ActiveIndicatorListComponent } from '../activeIndicator/activeIndicatorList.component';
import { type CustomizationLevelProps } from '../customizeLabelAndMarkerModal.container';
import {
  CustomizationOptionsComponent, type MarkerOptionsChange,
} from './customizationOptions/customizationOptions.component';
import {
  MarkerStyleListComponent, type MarkerTextureChange,
} from './markerStyleList/markerStyleList.component';

export enum CustomizeMarkerTab {
  Markers = 0,
  Indicator = 1,
}

const tabsWrapperStyle = css({
  width: '100%',
});

const tabContentWrapperStyle = css({
  paddingTop: 10,
  paddingLeft: 25,
  paddingRight: 25,
});

const contentStyle = css({
  position: 'relative',
});

export type CustomizeMarkerProps = Readonly<{
  colorCustomizationDisabled?: boolean;
  customMarkers: ReadonlyArray<CustomMarkerSettings>;
  customizationLevelProps: CustomizationLevelProps;
  defaultTab?: CustomizeMarkerTab;
  labelAboveVisualSettings: LabelVisualSetting;
  markerVisualSettings: TemporaryMarkerVisualSettings;
  standardMarkers: ReadonlyArray<StandardMarkerSettings>;
  optionalLabelDisabled?: boolean;
  acceptFileExtensions: ReadonlyArray<string>;
  selectGroupProps?: SelectGroupProps;

  onTextureChange: (settings: MarkerTextureChange) => void;
  onMarkerOptionsChange: (settings: MarkerOptionsChange) => void;

  onFileError: (rejections: FileRejection[]) => void;
  onFileAccept: (files: File[]) => void;
  isUploading: boolean;
  onImageDelete: (fileId: number) => void;
}>;

export const CustomizeMarkerComponent: React.FC<CustomizeMarkerProps> = props => {
  const { defaultTab, isUploading } = props;

  const [t] = useTranslation();

  const [activeTab, setActiveTab] = useState<number>(defaultTab ?? CustomizeMarkerTab.Markers);

  const tabsData: TabsData = useMemo(() => ([{
    index: CustomizeMarkerTab.Markers,
    header: t('Markers'),
    testid: 'marker-texture-tab',
    child: (
      <div css={tabContentWrapperStyle}>
        <MarkerStyleListComponent
          customMarkers={props.customMarkers}
          markerSettings={props.markerVisualSettings}
          onFileReject={props.onFileError}
          onFileAccept={props.onFileAccept}
          onMarkerSelect={props.onTextureChange}
          selectedColor={props.markerVisualSettings.selectedColor}
          standardMarkers={props.standardMarkers}
          isUploading={isUploading}
          onImageDelete={props.onImageDelete}
          acceptFileExtensions={props.acceptFileExtensions}
        />
      </div>
    ),
  }, {
    index: CustomizeMarkerTab.Indicator,
    header: t('Active Indicators'),
    testid: 'active-indicator-tab',
    child: (
      <div css={tabContentWrapperStyle}>
        <ActiveIndicatorListComponent />
      </div>
    ),
  }]), [isUploading, props.acceptFileExtensions, props.customMarkers, props.markerVisualSettings, props.onFileAccept,
    props.onFileError, props.onImageDelete, props.onTextureChange, props.standardMarkers, t]);

  return (
    <div css={contentStyle}>
      <CustomizationOptionsComponent
        activeTab={activeTab}
        colorCustomizationDisabled={props.colorCustomizationDisabled}
        customMarkers={props.customMarkers}
        customizationLevelProps={props.customizationLevelProps}
        labelAbove={props.labelAboveVisualSettings}
        visualSettings={props.markerVisualSettings}
        onModifyMarker={props.onMarkerOptionsChange}
        selectedColor={props.markerVisualSettings.selectedColor}
        standardMarkers={props.standardMarkers}
        opacity={props.markerVisualSettings.opacity}
        optionalLabelDisabled={props.optionalLabelDisabled}
        selectGroupProps={props.selectGroupProps}
      />

      <div css={tabsWrapperStyle} >
        <TabsComponent
          activeTabIndex={activeTab}
          data={tabsData}
          setActiveTabIndex={setActiveTab}
        />
      </div>
    </div>
  );
};
