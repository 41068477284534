import {
  useCallback, useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { noop } from '~/_shared/utils/function.helpers';
import {
  markersSelectAdd, markersSelectRemove,
} from '~/store/frontendState/mapTools/markersSelect/markersSelect.actionCreators';
import { useMarkersSelectActiveSelector } from '~/store/frontendState/mapTools/markersSelect/markersSelect.selectors';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';
import {
  type MapMarkerEventCallback, type MarkerEventCallbacks,
} from './markerEventCallbacks.type';

export const useMarkersSelectEventCallbacks = (): MarkerEventCallbacks => {
  const dispatch = useDispatch();
  const selection = useMarkersSelectActiveSelector();
  const selectionRef = useRef(selection); // so we don't unnecessarily re-attach listeners
  selectionRef.current = selection;

  const onClick: MapMarkerEventCallback = useCallback((rowIds: SpreadsheetRowId[]) => {
    const selectedRowId = rowIds[0]; // selecting only one marker from stacked is fine for now
    const isClickSelection = selectionRef.current?.selectType === MarkersSelectType.Click;

    if (isClickSelection && selectionRef.current && selectedRowId) {
      const alreadySelected = selectionRef.current.selectedMarkerIds.find(rowId =>
        rowId.rowId === selectedRowId.rowId && rowId.spreadsheetId === selectedRowId.spreadsheetId
      );
      if (alreadySelected) {
        dispatch(markersSelectRemove([selectedRowId]));
      }
      else {
        dispatch(markersSelectAdd([selectedRowId]));
      }
    }
  }, [dispatch]);

  return useMemo(() => ({
    onClick,
    // during markers select we don't register any other events except click
    onRightClick: noop,
    onMouseOver: noop,
    onMouseOut: noop,
  }), [onClick]);
};
