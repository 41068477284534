import {
  type FC, type KeyboardEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import { usePrevious } from '../../../utils/hooks/usePrevious';
import { isNullOrUndefined } from '../../../utils/typeGuards';
import {
  type InputProps, InputSize, TextInputComponent,
} from '../textInput';
import { InputSpinnersComponent } from './inputSpinners/inputSpinners.component';
import {
  inputStyle, suffixWrapperStyle,
} from './inputWithSpinner.styles';

export type InputWithSpinnersProps = Readonly<{
  isDisabled?: boolean;
  value?: number;
  valueMax?: number;
  valueMin?: number;
  width?: number;
  className?: string;
  hideSpinners?: boolean;
  size?: InputSize;
  placeholder?: string;
  useLocaleFormat?: boolean;

  onChange: (num: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
}>;

export const InputWithSpinnersComponent: FC<InputWithSpinnersProps> = (props) => {
  // we need to keep the value here, because users should be able to input non-final number
  // which might be outside the min-max range. e.g. they input 2 wanting to type 20 and the valueMin is 4
  const [internalValue, setInternalValue] = useState(props.value);
  const [hasFocus, setHasFocus] = useState(false);
  const previousPropsValue = usePrevious(props.value);
  const theme = useTheme();

  const { onKeyUp } = props;

  const size = props.size ?? InputSize.Medium;

  const applyMinMaxBoundariesToValue = useCallback((value: string | number): number | null => {
    const maxValue = props.valueMax ?? Number.MAX_VALUE;
    const minValue = props.valueMin ?? Number.MIN_VALUE;
    let newNumber = Number(value);

    if (!isNaN(newNumber)) {
      if (newNumber > maxValue) {
        newNumber = maxValue;
      }
      if (newNumber < minValue) {
        newNumber = minValue;
      }
      return newNumber;
    }
    return null;
  }, [props.valueMax, props.valueMin]);

  const onNewValue = useCallback((value: string | number): void => {
    const newValue = Number(value);

    if (isNaN(newValue)) {
      setInternalValue(props.value ?? 0);
      return;
    }

    if (value === '') {
      setInternalValue(undefined);
      return;
    }

    const onChange = props.onChange;
    const newNumber = applyMinMaxBoundariesToValue(value);
    if (newNumber !== null && newNumber === Number(value)) {
      onChange(newNumber);
    }
    setInternalValue(newValue);
  }, [applyMinMaxBoundariesToValue, props.onChange, props.value]);

  const isInternalValueValid = useMemo(() => (
    (isNullOrUndefined(internalValue) && props.placeholder) ||
    (!isNullOrUndefined(internalValue) && applyMinMaxBoundariesToValue(internalValue ?? 0) === internalValue)
  ), [applyMinMaxBoundariesToValue, internalValue, props.placeholder]);

  const onBlur = useCallback(() => {
    // if user moved away from the input without finishing typing in a valid value, use default
    if (!isInternalValueValid) {
      setInternalValue(props.value);
    }

    const propagate = props.onBlur;
    propagate?.();

    setHasFocus(false);
  }, [isInternalValueValid, props.onBlur, props.value]);

  const onFocus = useCallback(() => {
    const propagate = props.onFocus;
    propagate?.();

    setHasFocus(true);
  }, [props.onFocus]);

  const inputProps: InputProps = useMemo(() => ({
    onChange: (value: string) => onNewValue(value),
    value: (props.useLocaleFormat && !hasFocus) ? internalValue?.toLocaleString() : internalValue?.toString() ?? '',
    isDisabled: props.isDisabled,
    onBlur,
    onFocus,
    size,
    icon: null,
    onKeyUp,
  }), [props.useLocaleFormat, props.isDisabled, hasFocus, internalValue, onBlur, onFocus, size, onKeyUp, onNewValue]);

  useEffect(() => {
    if (previousPropsValue !== props.value) {
      setInternalValue(props.value);
    }
  }, [previousPropsValue, props.value]);

  return (
    <TextInputComponent
      {...inputProps}
      className={props.className}
      placeholder={props.placeholder}
      css={inputStyle({
        theme,
        width: props.width,
        hasError: !isInternalValueValid,
      })}
      rightContent={props.hideSpinners ? null : (
        <span css={suffixWrapperStyle({ theme, inputSize: size })}>
          <InputSpinnersComponent
            min={props.valueMin}
            max={props.valueMax}
            isDisabled={props.isDisabled}
            value={internalValue ?? 0}
            valueSetter={(value: number) => onNewValue(value)}
          />
        </span>
      )}
    />
  );
};
