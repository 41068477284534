import { type Interpolation } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEye, faEyeSlash, faKeyboard,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type AriaRole, type FocusEvent, forwardRef, type HTMLAttributes, type KeyboardEventHandler, type MouseEvent,
  type ReactNode, useCallback, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useCombinedRefs } from '~/_shared/utils/hooks/useCombinedRefs';
import { type autoCompleteDisabled } from '../../../utils/dom/dom.helpers';
import {
  additionalContentStyle, getIconStyles, inputStyles, showHideIconContainerStyle, wrapperStyle,
} from './textInput.styles';

export type InputType = 'email' | 'password' | 'text' | 'number';

export enum InputSize {
  Xs = 'Xs',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

export type InputProps = Readonly<{
  autoComplete?: string | typeof autoCompleteDisabled;
  className?: string;
  icon?: IconProp | null;
  iconStyle?: Interpolation;
  id?: string;
  inputSize?: number;
  inputStyle?: Interpolation;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  isDisabled?: boolean;
  isReadOnly?: boolean;
  leftContent?: ReactNode;
  max?: number;
  min?: number;
  name?: string;
  placeholder?: string;
  rightContent?: ReactNode;
  role?: AriaRole;
  size?: InputSize;
  title?: string;
  type?: InputType;
  value?: string;

  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string) => void;
  onClick?: (e?: MouseEvent) => void;
  onFocus?: () => void;
  onKeyUp?: KeyboardEventHandler;
}>;

export const TextInputComponent = forwardRef<HTMLInputElement, InputProps>(({
  autoComplete,
  className,
  icon = faKeyboard,
  iconStyle,
  id,
  inputMode,
  inputSize,
  inputStyle,
  isDisabled,
  isReadOnly,
  leftContent,
  max,
  min,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyUp,
  placeholder,
  rightContent,
  role,
  size = InputSize.Medium,
  title,
  type = 'text',
  value,
  ...restProps
}, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefs = useCombinedRefs<HTMLInputElement>(inputRef, ref);

  const hasShowHideIcon = type === 'password';

  const onToggleShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div
      {...restProps}
      css={wrapperStyle({ size, isDisabled, isReadonly: isReadOnly })}
      className={className}
      onClick={focusInput}
    >
      {icon !== null && (
        <span css={[getIconStyles(size), iconStyle]}>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}

      <span css={additionalContentStyle}>
        {leftContent}
      </span>

      <input
        ref={inputRefs}
        css={[inputStyles, inputStyle]}
        id={id}
        type={hasShowHideIcon && showPassword ? 'text' : type}
        value={value}
        disabled={isDisabled}
        readOnly={isReadOnly}
        placeholder={placeholder}
        onChange={e => onChange?.(e.target.value)}
        onBlur={onBlur}
        title={title}
        onClick={onClick}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        size={inputSize}
        min={min}
        max={max}
        autoComplete={autoComplete}
        role={role}
        name={name}
        inputMode={inputMode}
      />

      {hasShowHideIcon && (
        <div css={showHideIconContainerStyle({ size })}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={onToggleShowPasswordClick}
          />
        </div>
      )}

      <span css={additionalContentStyle}>
        {rightContent}
      </span>
    </div>
  );
});

TextInputComponent.displayName = 'TextInputComponent';
