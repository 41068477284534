export const sortAlphabetically = (a: string, b: string) => a.localeCompare(b);

export const objectsAlphabetically = <Item>(propGetter: (item: Item) => string) =>
  (a: Item, b: Item) =>
    propGetter(a).localeCompare(propGetter(b));

export const sortAlphanumerically = (a: string, b: string) =>
  a.localeCompare(b, undefined, { numeric: true });

export const sortNumbersAsc = (a: number, b: number) => a - b;
