import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { useTranslation } from '../../../utils/hooks';
import { SelectedItemComponent } from '../../selectedItem/selectedItem.component';
import { getDataActionShortLabels } from '../customizeMetrics.helpers';
import {
  type SelectedDataMetric, SelectedDataType,
} from '../selectedDataMetric.type';

type ResultsSectionProps = Readonly<{
  selectedData: SelectedDataMetric[];
  onRemoveItem: (index: number) => void;
}>;

const noDataMessageStyle = css({
  margin: '0 0 5px',
});

const selectedItemsContainerStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
});

const selectedItemStyle = css({
  margin: '0 10px 10px 0',
});

const containerStyle = css({
  padding: '10px 16px 0',
  marginBottom: -15,
});

export const CustomizeMetricsResultsComponent: FC<ResultsSectionProps> = (props) => {
  const [t] = useTranslation();
  const dataActionLabels = useMemo(() => getDataActionShortLabels(t), [t]);
  const { selectedData } = props;

  return (
    <div css={containerStyle}>
      {selectedData.length === 0 ?
        <p css={noDataMessageStyle}>{t('No data sources present.')}</p>
        : (
          <div css={selectedItemsContainerStyle}>
            {selectedData.map((data, index) => {
              let dataName = '';
              let dataActionLabel: string | null = null;

              if (data.type === SelectedDataType.SpreadsheetColumn) {
                dataName = data.selectedSpreadsheetColumn.name;
                dataActionLabel = dataActionLabels[data.dataAction];
              }
              if (data.type === SelectedDataType.Demographic) {
                dataName = data.selectedDemographic.name;
              }

              return (
                <SelectedItemComponent
                  css={selectedItemStyle}
                  onRemove={() => props.onRemoveItem(index)}
                  key={index + dataName}
                >
                  {dataName} {dataActionLabel === null ? null : `(${dataActionLabel})`}
                </SelectedItemComponent>
              );
            })}
          </div>
        )}
    </div>
  );
};
