import { type RadioGroupItem } from '~/_shared/baseComponents/radio/radioGroup.component';
import { createColorWithOpacity } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { NumericActiveGroupColumnType } from '~/_shared/types/grouping/grouping';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { BlueBubbleMarkerLabelComponent } from './blueBubbleMarkerLabel.component';
import { GreenPinMarkerLabelComponent } from './greenPinMarkerLabel.component';
import { MultiColorBubbleMarkerLabelComponent } from './multiColorBubbleMarkerLabel.component';
import { type NumericalGroupSettingsDataRow } from './numericalGroupSettingsNumeric.component';

export enum BucketMarkerVisualType {
  BlueBubble = 'BlueBubble',
  MultiColorBubble = 'MultiColorBubble',
  GrowingPin = 'GrowingPin',
}

export const DEFAULT_BUCKET_MARKER_VISUAL_TYPE = BucketMarkerVisualType.MultiColorBubble;

export const markerVisualRadioDataLookup: {[markerType in keyof typeof BucketMarkerVisualType]: RadioGroupItem<BucketMarkerVisualType>} = {
  [BucketMarkerVisualType.BlueBubble]: {
    value: BucketMarkerVisualType.BlueBubble,
    label: <BlueBubbleMarkerLabelComponent />,
  },
  [BucketMarkerVisualType.MultiColorBubble]: {
    value: BucketMarkerVisualType.MultiColorBubble,
    label: <MultiColorBubbleMarkerLabelComponent />,
  },
  [BucketMarkerVisualType.GrowingPin]: {
    value: BucketMarkerVisualType.GrowingPin,
    label: <GreenPinMarkerLabelComponent />,
  },
};

export const getNumericDataRowSettingsWithFinalColors = (data: {
  defaultDataRows: NumericalGroupSettingsDataRow[];
  getExistingGroupVisualsIfAny: (bucketId: number, numberOfBuckets: number) => MarkerSettings | null;
  initialDataRowsSettings?: NumericalGroupSettingsDataRow[];
}): NumericalGroupSettingsDataRow[] => {
  const existingVisualsIfAny = data.defaultDataRows.map(
    (_, index) => data.getExistingGroupVisualsIfAny(index, data.defaultDataRows.length)
  );

  if (!existingVisualsIfAny) {
    return data.initialDataRowsSettings || data.defaultDataRows;
  }

  return data.defaultDataRows.map((row, index) => {
    const predefinedMarker = existingVisualsIfAny[index]?.marker;

    const fromValue = data.initialDataRowsSettings?.[index]?.fromValue ?? row.fromValue;
    const toValue = data.initialDataRowsSettings?.[index]?.toValue ?? row.toValue;

    return {
      ...row,
      fromValue,
      toValue,
      ...(predefinedMarker && predefinedMarker.selectedColor && {
        color: createColorWithOpacity(predefinedMarker.selectedColor, predefinedMarker.opacity),
      }),
    };
  });
};

export const getMarkerVisualSettingsRadioOptions = (data: {
  hierarchyIndicator: number | null;
  numericActiveGroupColumnType: NumericActiveGroupColumnType | null;
}): ReadonlyArray<BucketMarkerVisualType> => {
  const { hierarchyIndicator, numericActiveGroupColumnType } = data;
  let markerVisualRadioOptions: ReadonlyArray<BucketMarkerVisualType>;

  if (hierarchyIndicator === 1) {
    markerVisualRadioOptions = [BucketMarkerVisualType.BlueBubble, BucketMarkerVisualType.MultiColorBubble];
  }
  else {
    if (numericActiveGroupColumnType === NumericActiveGroupColumnType.DoubleNumeric) {
      markerVisualRadioOptions = [BucketMarkerVisualType.GrowingPin];
    }
    else {
      markerVisualRadioOptions = [BucketMarkerVisualType.BlueBubble, BucketMarkerVisualType.MultiColorBubble, BucketMarkerVisualType.GrowingPin];
    }
  }

  return markerVisualRadioOptions;
};
