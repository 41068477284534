import { css } from '@emotion/react';
import { faCommentExclamation } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useMemo,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Announcement } from '~/_shared/types/announcement.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useHookWithRefCallback } from '../../../utils/hooks/useHookWithRefCallback';
import { guaranteeHash } from '../../colorPicker/colorPicker.helpers';
import { ImageComponent } from '../../image/image.component';

const IMAGE_DIMENSION = 30;
const ICON_TEXT_GAP = 20;
const MAX_ICON_DIMENSION = 28;

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const announcementWrapperStyle = ({ theme, backgroundColor, color }: ThemeProps & { backgroundColor?: string; color?: string }) => css({
  display: 'flex',
  justifyContent: 'space-between',
  color: color ? guaranteeHash(color) : theme.textColors.light,
  backgroundColor: backgroundColor ? guaranteeHash(backgroundColor) : undefined,
  width: '100%',
});

const contentWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
});

const iconTextStyle = css({
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  justifyContent: 'center',
});

const iconTitleStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: ICON_TEXT_GAP,
  justifyContent: 'start',
});

const iconWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
});

const titleStyle = ({ fontSize }: {fontSize?: number}) => css({
  fontSize: fontSize || 16,
  fontWeight: 'bolder',
});

const detailsStyle = ({ fontSize, iconOffset, isMobileVersion }: { fontSize?: number; iconOffset?: number; isMobileVersion?: boolean }) => css({
  fontSize: fontSize || 14,
  marginLeft: isMobileVersion ? 0 : (iconOffset || 0) + ICON_TEXT_GAP,

  '& div > p, div > pre,  div > ul, div > ol': {
    margin: '8px 0', // the announcement rich text editor doesn't allow us to specify styles, but we want the margins smaller
  },

  '& div * p': {
    margin: 0,
  },
});

const iconStyle = ({ color }: { color: string }) => css({
  color,
  fontSize: MAX_ICON_DIMENSION,
});

const svgIconStyle = ({ color }: { color: string }) => css({
  color,
  fontSize: MAX_ICON_DIMENSION,
  maxHeight: MAX_ICON_DIMENSION,
  maxWidth: MAX_ICON_DIMENSION,
});

const linkStyle = css({
  textDecoration: 'none',
});

const buttonStyle = css({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'end',
  marginRight: ICON_TEXT_GAP,
  textDecoration: 'none',
});

const imageStyle = css({
  background: 'none',
});

type TopUnderbarAnnouncementsTextProps = Readonly<{
  fontSize: number;
  text: string;
}>;

type TopUnderbarAnnouncementsIconProps = Readonly<{
  color?: string;
  iconSVG?: string | null;
}>;

type TopUnderbarAnnouncementsButtonProps = Readonly<{
  iconSVG?: string;
  link: string;
  style: ButtonStyle;
  title: string;
}>;

type TopUnderbarAnnouncementsProps = Readonly<{
  backgroundColor?: string;
  button?: TopUnderbarAnnouncementsButtonProps;
  color?: string;
  details?: TopUnderbarAnnouncementsTextProps;
  isMobileVersion?: boolean;
  icon?: TopUnderbarAnnouncementsIconProps;
  imageUrl?: string | null;
  subject: TopUnderbarAnnouncementsTextProps;
}>;

export const TopUnderbarAnnouncementComponent: FC<TopUnderbarAnnouncementsProps> = (props) => {
  const theme = useTheme();
  const DEFAULT_COLOR = theme.alertColors.warningPrimary;
  const [iconRef, setIconRef] = useHookWithRefCallback<HTMLDivElement>();

  const color = useMemo(() => (props.color || DEFAULT_COLOR), [DEFAULT_COLOR, props.color]);

  const fullLink = useMemo(() => {
    const baseLink = props.button?.link;

    if (baseLink && !baseLink.startsWith('https://') && !baseLink.startsWith('http://')) {
      return 'https://' + baseLink;
    }

    return baseLink;
  }, [props.button?.link]);

  return (
    <div css={containerStyle}>
      <div css={announcementWrapperStyle({ theme, color, backgroundColor: props.backgroundColor })}>

        <div css={contentWrapperStyle}>
          <div css={iconTextStyle}>

            <div css={iconTitleStyle}>
              {!props.isMobileVersion && !props.icon?.iconSVG && (
                <div
                  css={iconWrapperStyle}
                  ref={setIconRef}
                >
                  <FontAwesomeIcon
                    css={iconStyle({ color: props.icon?.color || color })}
                    icon={faCommentExclamation}
                  />
                </div>
              )}

              {!props.isMobileVersion && props.icon?.iconSVG && (
                <div
                  ref={setIconRef}
                  css={svgIconStyle({ color: props.icon.color || color })}
                  dangerouslySetInnerHTML={{ __html: props.icon.iconSVG }}
                />
              )}

              <div css={titleStyle({ fontSize: props.subject.fontSize })}>
                {props.subject.text}
              </div>
            </div>

            {props.details?.text ? (
              <div
                css={detailsStyle({
                  fontSize: props.details.fontSize,
                  iconOffset: Math.min(iconRef?.scrollWidth || 0, MAX_ICON_DIMENSION),
                  isMobileVersion: props.isMobileVersion,
                })}
              >
                <div dangerouslySetInnerHTML={{ __html: props.details.text }} />
              </div>
            ) : null}
          </div>

          {props.button && (
            <div css={buttonStyle}>
              <a
                css={linkStyle}
                href={fullLink}
                rel="noreferrer"
                target="_blank"
              >
                <ButtonComponent
                  text={props.button.title}
                  buttonStyle={props.button.style}
                  prefixIcon={undefined}
                />
              </a>
            </div>
          )}

          {props.imageUrl && (
            <ImageComponent
              css={imageStyle}
              height={IMAGE_DIMENSION}
              src={props.imageUrl}
              width={IMAGE_DIMENSION}
            />
          )}
        </div>

      </div>
    </div>
  );
};

export const renderAnnouncement = ({ announcement, isMobileVersion, markAnnouncementAsSeen }: {
  isMobileVersion?: boolean;
  announcement: Announcement;
  markAnnouncementAsSeen: (announcementId: number) => void;
}) => {
  const subject: TopUnderbarAnnouncementsTextProps = {
    fontSize: announcement.titleFontSize,
    text: announcement.subject,
  };

  const details: TopUnderbarAnnouncementsTextProps = {
    fontSize: announcement.messageFontSize,
    text: announcement.message,
  };

  const icon: TopUnderbarAnnouncementsIconProps | undefined = {
    iconSVG: announcement.icon,
    color: announcement.colors?.iconColor,
  };

  const button: TopUnderbarAnnouncementsButtonProps | undefined = announcement.button ? {
    iconSVG: announcement.button.icon,
    link: announcement.button.link,
    style: announcement.button.style,
    title: announcement.button.title,
  } : undefined;

  return ({
    key: `a_${announcement.id}`,
    children: (
      <TopUnderbarAnnouncementComponent
        details={details}
        button={button}
        icon={icon}
        color={announcement.colors?.color}
        backgroundColor={announcement.colors?.backgroundColor}
        imageUrl={announcement.image}
        isMobileVersion={isMobileVersion}
        subject={subject}
      />
    ),
    onClose: () => markAnnouncementAsSeen(announcement.id),
    colors: announcement.colors ?? undefined, padding: '6px 16px', marging: 0,
  });
};
