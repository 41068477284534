/***
 * Log errors that don't need to bring down the app, but are still unexpected and worth to investigate.
 * @param error
 * @param messages
 */
export const logError = (error: Error | string, ...messages: unknown[]): void => {
  // Might want to send logs into some remote log service
  // logService.send({ ...error, additionalMessages: messages.map(JSON.stringify) });

  console.error(error, ...messages);
};

export const logWarning = (warn: Error | string, ...messages: unknown[]): void => {
  console.warn(warn, ...messages);
};
