import {
  type UndoHeatmapData,
  type UndoItemType,
} from '~/_shared/types/undo/undoItem';
import { type UNDO_MODEL_VERSIONS } from '../undo.constants';

export type AllHeatmapVersions = typeof UNDO_MODEL_VERSIONS[UndoItemType.REMOVE_HEATMAP][number];

const undoHeatmapDataFromAnyVersionToCurrentVersionConverters: {
  [key in AllHeatmapVersions]: (anyVersionUndoData: any) => UndoHeatmapData
} = {
  ['1']: (undoData) => undoData as UndoHeatmapData,
};

export const convertUndoHeatmapDataAnyVersionToCurrent = (
  anyVersionUndoData: any,
  version: AllHeatmapVersions,
): UndoHeatmapData => (
  undoHeatmapDataFromAnyVersionToCurrentVersionConverters[version](anyVersionUndoData)
);
