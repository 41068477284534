import { useMemo } from 'react';
import { useMapInfoSpreadsheetsSelector } from '../mapInfo/mapInfo.selectors';

export const usePrimarySpreadsheetId = () => {
  const spreadsheets = useMapInfoSpreadsheetsSelector();

  return useMemo(() => {
    if (!spreadsheets) {
      return null;
    }

    const primarySpreadsheet = spreadsheets.find(item => item.isPrimary);

    return primarySpreadsheet?.spreadSheetId ?? null;
  }, [spreadsheets]);
};
