import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import type { Pagination } from '~/_shared/types/pagination/pagination';
import { useTranslation } from '~/_shared/utils/hooks';
import { formatValue } from '~/_shared/utils/range/range.helpers';

const fontStyle = (theme: Theme) => css({
  fontSize: 14,
  color: theme.textColors.secondary,
});

type PaginationViewInfoProps = Readonly<{
  className?: string;
  pagination: Pagination;
}>;

export const PaginationViewInfoComponent: FC<PaginationViewInfoProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const formatNum = useCallback((num: number) => formatValue(num), []);

  const { from, to, total } = useMemo(() => {
    const { currentPage, perPage, total } = props.pagination;
    const from = (currentPage - 1) * perPage + 1;
    const to = Math.min((currentPage * perPage), total || Number.MAX_SAFE_INTEGER);

    return {
      from: formatNum(from),
      to: formatNum(to),
      total: total ? formatNum(total) : t('Unknown'),
    };
  }, [formatNum, props.pagination, t]);

  return (
    <div
      className={props.className}
      css={fontStyle(theme)}
    >
      {t('ViewFromToOf', { from, to, total })}
    </div>
  );
};
