import { css } from '@emotion/react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LOGIN_ROUTE } from '../../../_shared/constants/routes';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { useTranslation } from '../../../_shared/utils/hooks';
import { authorizationPagesLinkStyle } from '../../authorizationPages.styles';

type RegisterFormSuccessProps = Readonly<{
  onClose: () => void;
}>;

const alertStyle = css({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
});

const linkStyle = css(authorizationPagesLinkStyle, {
  display: 'inline-block',
  fontWeight: 700,
  marginTop: 30,
});

const textStyle = css({
  fontSize: 18,
  fontWeight: 600,
  margin: 0,
});

const iconStyle = ({ theme }: ThemeProps) => css({
  width: 26,
  height: 26,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  fontSize: 20,
  border: `1px solid ${theme.iconColors.success}`,
});

const iconWrapperStyle = css({
  marginRight: 6,
});

export const RegisterFormSuccessComponent: FC<RegisterFormSuccessProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div>
      <AlertComponent
        type="success"
        css={alertStyle}
        onClose={props.onClose}
      >
        <div css={iconWrapperStyle}>
          <span css={iconStyle({ theme })}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
        </div>
        <div>
          <p css={textStyle}>{t('Your account was created successfully!')}</p>
          <p css={textStyle}>{t('Check your email to log in.')}</p>
        </div>
      </AlertComponent>

      <LinkComponent
        css={linkStyle}
        path={LOGIN_ROUTE}
      >
        {t('Back to Login')}
      </LinkComponent>
    </div>
  );
};
