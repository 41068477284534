import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  CheckboxGroupComponent, type CheckboxGroupItem,
} from '~/_shared/baseComponents/checkbox/checkboxGroup.component';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { UnderlinedLinkComponent } from '~/_shared/baseComponents/links';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import { useGetBoundaryNameOverrides } from '~/boundary/useGetBoundaryNameOverrides';
import { trialMessageStyle } from '~/sidebar/sidebarApps/mapTools/boundary/export/exportBoundaryTerritoryGroup.styles';
import { type SpreadsheetDataBoundariesRequest } from '~/spreadsheet/spreadsheet.repository';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { closeAllModals } from '~/store/modal/modal.actionCreators';
import {
  s, Trans,
} from '~/translations/Trans';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '../../../../../_shared/components/exportDataModal/exportDataModal.component';
import { prepareAsyncCopyToClipboard } from '../../../../../_shared/utils/clipboard/clipboard.helpers';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useTimeout } from '../../../../../_shared/utils/hooks/useTimeout';
import { ExportDataFileType } from '../../../../../data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '../../../../../data/exportDataModal/exportDataMethod.enum';
import {
  type ModalProps, ModalType,
} from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { usePrimarySpreadsheetId } from '../../../../../store/selectors/usePrimarySpreadsheetId';
import { useIsWMSEnabledSelector } from '../../../../../store/userData/userData.selectors';
import { isBoundaryTerritoryGroupCustom } from '../boundaryTerritoryGroup.helpers';
import { useBoundaryTerritoryGroupDisplayName } from '../hooks/useBoundaryTerritoryGroupDisplayName';
import { useExportBoundaryTerritoryGroupData } from '../hooks/useExportBoundaryTerritoryGroupData';

export type ExportBoundaryTerritoryGroupContainerProps = ModalProps<{
  selectedBoundaryTerritoryGroupIds: ReadonlyArray<number>;
  showSelectBoundaryTerritoryGroupsSection: boolean;
}>;

export const ExportBoundaryTerritoryGroupContainer: FC<ExportBoundaryTerritoryGroupContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState(props.selectedBoundaryTerritoryGroupIds);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { isLoading, isError, getExportedBoundaryTerritoryGroupData } = useExportBoundaryTerritoryGroupData();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const { getBoundaryTerritoryGroupName } = useBoundaryTerritoryGroupDisplayName();
  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const { getBoundaryNameOverrides } = useGetBoundaryNameOverrides();
  const [t] = useTranslation();
  const isWMSEnabled = useIsWMSEnabledSelector();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { openModal: openUpgradeSubscriptionModal } = useModal(ModalType.WMSUpgradeSubscription);
  const [isSelectionSectionExpanded, setIsSelectionSectionExpanded] = useState(true);

  const onSave = async (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId || selectedGroupsIds.length === 0) {
      return;
    }
    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    const selectedBoundaryTerritoryGroups = boundaryTerritoryGroups
      .filter(group => selectedGroupsIds.includes(group.boundaryTerritoryGroupId));

    const nameOverridesList = await Promise.all(selectedBoundaryTerritoryGroups.map(
      group => isBoundaryTerritoryGroupCustom(group, boundaryGroups)
        ? getBoundaryNameOverrides(group.boundaryGroupId)
        : Promise.resolve({})
    ));
    const boundariesRequest = nameOverridesList.reduce((acc: SpreadsheetDataBoundariesRequest, overrides, index) => {
      const boundary = selectedBoundaryTerritoryGroups[index];
      if (!boundary) {
        return acc;
      }
      return {
        ...acc,
        [boundary.boundaryGroupId]: { name_overrides: overrides },
      };
    }, {});

    const response = await getExportedBoundaryTerritoryGroupData({
      spreadsheetId: virtualSpreadsheetId,
      boundariesRequest,
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      exportAsText: arg.method === ExportDataMethod.Clipboard,
      useMainFilters: true,
    });

    if (arg.method === ExportDataMethod.Clipboard) {
      const fileContent = response.data[0]?.result?.content;

      if (!fileContent) {
        setErrorMessage(t('No locations to export'));
        return;
      }

      asyncCopyToClipboard(fileContent);
      showCopiedToClipboardMessage();
    }
    else {
      const fileName = response.data[0]?.result?.file_name;

      if (!fileName) {
        setErrorMessage(t('No locations to export'));
        return;
      }

      const exportUrl = getExportUrl(fileName);
      downloadFile(exportUrl);

      props.onClose();
      openDownloadResultsModal({
        downloadUrl: exportUrl,
      });
    }
  };

  const isBoundaryTerritoryGroupAIGenerated = useCallback((btg: BoundaryTerritoryGroup) => {
    const matchingBoundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === btg.boundaryGroupId);
    return !!matchingBoundaryGroup?.wms.territoriesCount;
  }, [boundaryGroups]);

  const boundaryTerritoryGroupOptions = useMemo<CheckboxGroupItem<number>[]>(() => {
    return boundaryTerritoryGroups.map(group => {
      const isTemporaryTerritory = !isWMSEnabled && isBoundaryTerritoryGroupAIGenerated(group);

      return ({
        value: group.boundaryTerritoryGroupId,
        text: getBoundaryTerritoryGroupName(group),
        isDisabled: isTemporaryTerritory,
        extraContent: isTemporaryTerritory && (
          <div css={trialMessageStyle({ theme })}>
            <FontAwesomeIcon
              icon={faRobot}
            />
            <Trans i18nKey="<0>Upgrade here</0> to enable export">
              <UnderlinedLinkComponent
                onClick={() => {
                  dispatch(closeAllModals());
                  openUpgradeSubscriptionModal();
                }}
              >
                Upgrade here
              </UnderlinedLinkComponent>
              {s(' to enable export')}
            </Trans>
          </div>
        ),
      });
    });
  }, [boundaryTerritoryGroups, isWMSEnabled, isBoundaryTerritoryGroupAIGenerated, getBoundaryTerritoryGroupName, theme, dispatch, openUpgradeSubscriptionModal]);

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  useEffect(() => {
    setErrorMessage(null);
  }, [selectedGroupsIds]);

  const sections = props.showSelectBoundaryTerritoryGroupsSection ? [{
    header: t('Choose the boundary / territory groups you want to export'),
    isExpanded: isSelectionSectionExpanded,
    onHeadingClick: () => setIsSelectionSectionExpanded(!isSelectionSectionExpanded),
    child: (
      <CheckboxGroupComponent
        onSelectionChanged={setSelectedGroupsIds}
        selectedValues={selectedGroupsIds}
        items={boundaryTerritoryGroupOptions}
      />),
  }] : undefined;

  return (
    <ExportDataModalComponent
      isLoading={isLoading}
      sectionsBeforeExportMethod={sections}
      onSubmit={onSave}
      onClose={props.onClose}
      isOpen={props.isOpen}
      error={errorMessage}
      isSubmitDisabled={selectedGroupsIds.length === 0}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
    />
  );
};
