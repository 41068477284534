export enum ImageFileType {
  PNG = 'Png',
  PDF = 'Pdf',
  JPEG = 'Jpeg',
}

export const renderImageType = (imageType: ImageFileType) => {
  switch (imageType) {
    case ImageFileType.JPEG:
      return '.jpg';
    case ImageFileType.PDF:
      return '.pdf';
    case ImageFileType.PNG:
      return '.png';
    default:
      throw new Error(`imageType.enum.ts: unknown imageType '${imageType}'.`);
  }
};
