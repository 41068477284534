import { useCallback } from 'react';
import { getBoundaryGroupItemsList } from '~/store/boundaries/boundaries.repository';
import { type BoundaryId } from '~/store/boundaries/boundaryIdentifier.type';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';

export function useGetBoundaryNameOverrides() {
  const boundaryData = useMapBoundariesSelector();
  const clientId = useClientIdSelector();

  const getBoundaryNameOverrides = useCallback(async (boundaryGroupId: number) => {

    const boundaryItems = boundaryData.get(boundaryGroupId)?.values();

    if (boundaryItems) {
      const namesOverrides: Record<BoundaryId, string> = {};
      for (const boundaryItem of boundaryItems) {
        if (boundaryItem.settings.translate) {
          namesOverrides[boundaryItem.id] = boundaryItem.displayName;
        }
      }
      return namesOverrides;
    }
    else {
      if (clientId === null) {
        return {};
      }

      const fetchedBoundaryItems = await getBoundaryGroupItemsList(clientId, boundaryGroupId);

      return fetchedBoundaryItems.reduce((namesOverrides: Record<BoundaryId, string>, boundaryItem) => {
        if (boundaryItem.settings.translate) {
          namesOverrides[boundaryItem.id] = boundaryItem.name;
        }
        return namesOverrides;
      }, {});
    }
  }, [boundaryData, clientId]);

  return {
    getBoundaryNameOverrides,
  };
}
