import 'react-image-gallery/styles/css/image-gallery.css';
import {
  css,
  Global,
} from '@emotion/react';
import {
  type FC,
  useState,
} from 'react';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

type ImageViewItem = {
  original: string;
  thumbnail: string;
};

export type ImageViewModalProps = Readonly<{
  startingImageIndex?: number;
  images: ImageViewItem[];
  onSlide?: (showedImageIndex: number) => void;
  isOpen: boolean;
  onClose: () => void;
}>;

const emptyStateHeight = 70;
const emptyStateStyle = css({
  height: emptyStateHeight,
  lineHeight: `${emptyStateHeight}px`,
  width: 'fit-content',
  margin: '0 auto',
});

const maxThumbnailHeight = 150;

export const ImageViewModalComponent: FC<ImageViewModalProps> = props => {
  const [t] = useTranslation();
  const [imageIndex, setImageIndex] = useState(props.startingImageIndex || 0);

  return (
    <>
      <Global
        styles={{
          '.image-gallery': {
            minHeight: 0,
            maxHeight: '100%',
          },
          '.image-gallery-content': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 0,
            maxHeight: 'calc(100vh - 150px)',
            height: '100%',
          },
          '.image-gallery-slide-wrapper': {
            minHeight: 0,
            maxHeight: 'calc(70vh - 150px)',
          },
          '.image-gallery-slides': {
            minHeight: 0,
            maxHeight: 'calc(70vh - 150px)',
          },
          '.image-gallery-slides .image-gallery-slide, .image-gallery-swipe': {
            minHeight: 0,
            height: '100%',
            maxHeight: 'calc(70vh - 150px)',
          },
          '.image-gallery-thumbnail': {
            minHeight: 0,
            maxHeight: maxThumbnailHeight,
          },
          '.image-gallery-content .image-gallery-slide .image-gallery-image': {
            minHeight: 0,
            maxHeight: 'calc(70vh - 150px)',
          },
        }}
      />
      <ModalComponent
        isOpen={props.isOpen}
        onClose={props.onClose}
        caption={`${props.images.length > 0 ? imageIndex + 1 : 0} ${t('ofATotalOf')} ${props.images.length}`}
        maxWidth={props.images.length > 0 ? '80vw' : '50vw'}
        contentMaxHeight="100%"
        scrollbarContentStyle={{
          maxHeight: '100%',
          minHeight: 0,
        }}
        contentStyle={css({
          maxHeight: '100%',
          minHeight: 0,
        })}
      >
        {props.images.length > 0 ? (
          <ImageGallery
            items={props.images}
            startIndex={props.startingImageIndex}
            onSlide={setImageIndex}
          />
        ) : (
          <p css={emptyStateStyle}>{t('Your image gallery is empty.')}</p>
        )}
      </ModalComponent>
    </>
  );
};
