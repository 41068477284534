import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback,
  useEffect, useState,
} from 'react';
import { ContextMenuComponent } from '~/_shared/baseComponents/contextMenu';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  MemberStatus, type TeamMemberLimitsData,
} from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { usePasswordReset } from '../../../authorization/passwordResetPage/usePasswordReset';
import { type InvitationAndMemberData } from '../teamManagementModal.container';
import {
  useChangeRole,
  useEnableMember,
  useLoginAsMember,
  useRequestDeleteUser,
  useRevokeInvitation,
} from '../useTeamManagement';
import { TeamManagementMenuContainer } from './teamManagementMenu.container';
import { useMembersErrorModal } from './useMembersErrorModal';

const statusIconStyle = ({ theme, isSelected, isDisabled }: ThemeProps<{isSelected: boolean; isDisabled: boolean}>) => css({
  color: isDisabled ? theme.textColors.disabled : isSelected ? theme.iconColors.backgroundQuaternary : theme.iconColors.highContrast,
  fontSize: '16px',
  padding: '5px 10px',

  '&:hover': !isDisabled ? {
    color: isSelected ? theme.iconColors.focused : theme.iconColors.primary,
  } : undefined,
});

type MembersTableItemContextMenuProps = Readonly<{
  member: InvitationAndMemberData;
  limits: TeamMemberLimitsData;
}>;

export const useMembersTableItemContextMenu = ({ member, limits }: MembersTableItemContextMenuProps) => {
  const theme = useTheme();
  const userId = useUserIdSelector();
  const [showMenu, setShowMenu] = useState(false);

  const { update: requestDeleteUser, isLoading: isRequestingDeleteUser } = useRequestDeleteUser();
  const { update: enableMember, isLoading: isEnablingUser } = useEnableMember();
  const { update: changeRole, isLoading: isChangingRole } = useChangeRole();
  const { update: revokeInvite, isLoading: isRevokingInvite } = useRevokeInvitation();
  const { update: loginAsMember, isLoading: isLoggingInAsMember } = useLoginAsMember();
  const { sendPasswordResetEmail, isPasswordResetEmailSending, passwordResetEmailError } = usePasswordReset();
  const { openErrorModal } = useMembersErrorModal();

  useEffect(() => {
    if (passwordResetEmailError) {
      openErrorModal(passwordResetEmailError);
    }
  }, [openErrorModal, passwordResetEmailError]);

  const isLoading = isRequestingDeleteUser || isEnablingUser || isChangingRole
      || isRevokingInvite || isLoggingInAsMember || isPasswordResetEmailSending;

  const memberIsCurrentUserAndDisabled = member.id === userId && member.status === MemberStatus.DISABLED;

  const toggleContextMenu = useCallback(() => {
    if (memberIsCurrentUserAndDisabled) {
      return;
    }

    setShowMenu(!showMenu);
  }, [memberIsCurrentUserAndDisabled, showMenu]);

  return {
    isLoading,
    error: passwordResetEmailError,
    contextMenu:
  <ContextMenuComponent
    isFixed
    isVisible={showMenu}
    onHide={() => setShowMenu(false)}
    renderMenuContent={() => (
      <TeamManagementMenuContainer
        member={member}
        close={() => setShowMenu(false)}
        requestDeleteUser={requestDeleteUser}
        enableMember={enableMember}
        changeRole={changeRole}
        revokeInvite={revokeInvite}
        loginAsMember={loginAsMember}
        sendPasswordResetEmail={sendPasswordResetEmail}
        limits={limits}
      />
    )}
  >
    <FontAwesomeIcon
      css={statusIconStyle({ theme, isSelected: showMenu, isDisabled: memberIsCurrentUserAndDisabled })}
      icon={faEllipsisV}
      onClick={toggleContextMenu}
    />
  </ContextMenuComponent>,
  };
};
