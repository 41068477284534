import {
  type FilterTree, type FilterTreeItemLiteral,
} from '../../../../_shared/types/filterTree.types';
import { isArrayFilterAction } from '../../../../sidebar/sidebarApps/mapTools/filterTool/filters/filters.helpers';
import { type MapSettingsFilterTextValue } from '../../../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { DataType } from '../../spreadsheetData.state';

export const createFilterItemFromTextValue = (
  textValue: MapSettingsFilterTextValue, columnId: string
): FilterTree | FilterTreeItemLiteral => {
  const filterValue = textValue.value ?? '';
  const filterAction = textValue.action;

  if (isArrayFilterAction(filterAction)) {
    const items = filterValue.split(',');
    return {
      type: 'or',
      children: items.map(item => ({
        type: 'literal',
        ref: {
          col_id: columnId,
          action: filterAction,
          data_type: DataType.ATTRIBUTE,
          value: item ?? '',
        },
      })),
    };
  }
  else {
    return ({
      type: 'literal',
      ref: {
        col_id: columnId,
        action: filterAction,
        data_type: DataType.TEXT,
        value: filterValue,
      },
    });
  }
};
