import { type TranslationFnc } from '~/_shared/utils/hooks';
import { mapToolTitleMap } from '~/sidebar/sidebarApps/mapTools/mapTools.helpers';
import {
  getMapSettingsDataPathItemName, mapSettingsDataPathMap,
} from '~/store/mapSettings/data/mapSettingsData.helpers';
import {
  type ColumnSettingsTool, extraColumnSettingsTools,
} from './columnsMapSettings.types';

export const getColumnMapSettingsToolName = (tool: ColumnSettingsTool, t: TranslationFnc): string => {
  const possiblyExtraTool = Object.values(extraColumnSettingsTools).find(i => i === tool);
  const possiblyMapSettingsTool = Object.values(mapSettingsDataPathMap).find(i => i === tool);

  if (possiblyExtraTool) {
    switch (possiblyExtraTool) {
      case extraColumnSettingsTools.Boundary:
        return t(mapToolTitleMap.Boundary);
      case extraColumnSettingsTools.BoundaryMetrics:
        return t(mapToolTitleMap.Boundary) + ' - ' + t('Data Metrics');
      case extraColumnSettingsTools.ProximityMetrics:
        return t(mapToolTitleMap.Proximity) + ' - ' + t('Data Metrics');
      default:
        return t('Map Tools');
    }
  }
  else if (possiblyMapSettingsTool) {
    return getMapSettingsDataPathItemName(possiblyMapSettingsTool, t);
  }
  else {
    return t('Map Tools');
  }
};
