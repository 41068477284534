import {
  useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { isMobileScreen } from '../../_shared/utils/deviceDetect/deviceDetect.helpers';
import { deviceInfoUpdate } from '../../store/frontendState/deviceInfo/deviceInfo.actionCreator';

export const useScreenAndDeviceDetect = () => {
  const dispatch = useDispatch();
  const onResize = useCallback(() => {
    dispatch(deviceInfoUpdate(isMobileScreen()));
  }, [dispatch]);

  const screenAndDeviceDetect = useCallback(
    () => {
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    },
    [onResize],
  );

  useEffect(() => {
    screenAndDeviceDetect();
  }, [screenAndDeviceDetect]);
};
