import { type DrawingLabelSettingsState } from '../../store/mapSettings/drawing/settings/label/drawingLabelSettings.state';
import {
  DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
  DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

type CreateLabelDrawingOptionsArguments = {
  settings: DrawingLabelSettingsState;
  sizePerPixelRatio?: number;
};

export const createLabelDrawingGlobalOptionsFromSettings = (settings: DrawingLabelSettingsState) =>
  createLabelDrawingOptionsFromSettings({ settings });

export const createSelectedLabelDrawingOptionsFromSettings = (settings: DrawingLabelSettingsState, sizePerPixelRatio: number) =>
  createLabelDrawingOptionsFromSettings({ settings, sizePerPixelRatio });

const createLabelDrawingOptionsFromSettings = (
  { settings, sizePerPixelRatio }: CreateLabelDrawingOptionsArguments
): Partial<DrawingToolOptions> => {
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    booleanOptions: {
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    sliderOptions: {
      dotSize: {
        value: settings.dotSize,
      },
      ...!useScaledSlider ? {
        fontSize: {
          value: settings.fontSize,
          range: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
        },
      } : undefined,
    },
    ...useScaledSlider ? {
      scaledSliderOptions: {
        fontSizeOnCurrentMapZoom: {
          value: settings.fontSize,
          displayValue: Math.round((settings.fontSize) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
          ensureValueInRange: true,
        },
      },
    } : undefined,
    colorOptions: {
      dotColor: settings.dotColor,
      backgroundColor: settings.backgroundColor,
      fontColor: settings.fontColor,
    },
  };
};

export const createLabelDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingLabelSettingsState
): DrawingLabelSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    fontSize: toolOptions.sliderOptions?.fontSize?.value ?? toolOptions.scaledSliderOptions?.fontSizeOnCurrentMapZoom?.value ?? currentSettings.fontSize,
    dotSize: toolOptions.sliderOptions?.dotSize?.value ?? currentSettings.dotSize,
    dotColor: toolOptions.colorOptions?.dotColor ?? currentSettings.dotColor,
    backgroundColor: toolOptions.colorOptions?.backgroundColor ?? currentSettings.backgroundColor,
    fontColor: toolOptions.colorOptions?.fontColor ?? currentSettings.fontColor,
  };
};
