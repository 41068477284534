import { type FC } from 'react';
import {
  ExportFileButtonComponent,
  type PrintExportFileProps,
} from '~/_shared/components/exportFileButton/exportFileButton.component';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';

export const ExportFileButtonContainer: FC<PrintExportFileProps> = (props) => {
  const isMobileScreen = useIsMobileScreenSelector();

  if (isMobileScreen) {
    return null;
  }

  return (
    <ExportFileButtonComponent
      {...props}
    />
  );
};
