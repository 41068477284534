import { type DeepWritable } from 'ts-essentials';
import { type FileAttachmentId } from '~/_shared/types/file.types';
import {
  type CustomMarkerVisualSettings,
  MarkerStyleType, type MarkerVisualSettings,
} from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { type MapSettingsMarkersGeneralState } from './mapSettingsMarkersGeneral.state';
import {
  getIndividualMarkerSettings,
  getNumericGroupMarkerSettings,
  getTextGroupMarkerSettings,
} from './mapSettingsMarkersGeneralSettings.helpers';

type MutableState = DeepWritable<MapSettingsMarkersGeneralState>;

export const updateMarkerSettingsOnAttachmentDelete = (
  state: MapSettingsMarkersGeneralState,
  fileAttachmentIds: ReadonlyArray<FileAttachmentId>
): MapSettingsMarkersGeneralState => {
  const clonedMutableState = cloneDeep<MutableState>(state);

  const { globalMarkerSettings } = clonedMutableState;

  if (globalMarkerSettings) {
    const marker = globalMarkerSettings.marker;
    if (markerUsesAttachment(marker, fileAttachmentIds)) {
      globalMarkerSettings.marker = {
        ...marker,
        styleType: MarkerStyleType.STANDARD,
        styleId: state.defaultMarkerSetId,
      };
    }
  }

  getTextGroupMarkerSettings(clonedMutableState).forEach(item => {
    const { groupId, markerSettings, textGroupsSettings: textGroupSettings } = item;
    const updated = updateMarkerSettings(markerSettings, fileAttachmentIds);
    if (validateMarkerSettings(updated)) {
      textGroupSettings[groupId] = updated;
    }
    else {
      delete textGroupSettings[groupId];
    }
  });

  getNumericGroupMarkerSettings(clonedMutableState).forEach(item => {
    const { bucketId, markerSettings, numericGroupBucketsSettings } = item;
    const updated = updateMarkerSettings(markerSettings, fileAttachmentIds);
    if (validateMarkerSettings(updated)) {
      numericGroupBucketsSettings[bucketId] = updated;
    }
    else {
      delete numericGroupBucketsSettings[bucketId];
    }
  });

  getIndividualMarkerSettings(clonedMutableState).forEach(item => {
    const { rowId, markerSettings, spreadsheetMarkerSettings } = item;
    const updated = updateMarkerSettings(markerSettings, fileAttachmentIds);
    if (validateMarkerSettings(updated)) {
      spreadsheetMarkerSettings[rowId] = updated;
    }
    else {
      delete spreadsheetMarkerSettings[rowId];
    }
  });

  return clonedMutableState;
};

const markerUsesAttachment = (marker: MarkerVisualSettings | undefined, fileAttachmentIds: ReadonlyArray<FileAttachmentId>): marker is CustomMarkerVisualSettings =>
  !!(marker?.styleType === MarkerStyleType.CUSTOM && fileAttachmentIds.find(id => id === marker.fileAttachmentId));

const validateMarkerSettings = (settings: MarkerSettings | undefined): settings is MarkerSettings => !!settings && (
  !!settings.marker || !!settings.label
);

const updateMarkerSettings = (markerSettings: MarkerSettings | undefined, fileAttachmentIds: ReadonlyArray<FileAttachmentId>): MarkerSettings | undefined => {
  if (markerSettings && markerUsesAttachment(markerSettings.marker, fileAttachmentIds)) {
    return {
      ...markerSettings,
      marker: undefined,
      useMarker: false,
    };
  }

  return markerSettings;
};
