import {
  useCallback, useState,
} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReCaptchaActions } from '~/_shared/constants/reCaptchaActions';
import {
  shareMapEmail, ShareMapEmailResults,
} from '~/map/map.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';

export const useShareMapSendEmail = (mapId: number) => {
  const clientId = useClientIdSelector();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const sendShareMapEmail = useCallback(async (emails: readonly string[], message: string) => {
    if (!clientId || !executeRecaptcha) {
      return ShareMapEmailResults.InvalidData;
    }

    setIsSendingEmail(true);
    try {
      const token = await executeRecaptcha(ReCaptchaActions.ShareMap);
      const res = await shareMapEmail(clientId, mapId, {
        emails, message, token,
      });

      return res.result;
    }
    finally {
      setIsSendingEmail(false);
    }
  }, [clientId, executeRecaptcha, mapId]);

  return {
    sendShareMapEmail,
    isSendingEmail,
  };
};
