import m1l1png from '~/_shared/../../../assets/images/markers/primary/1/l1.png';
import m1l2png from '~/_shared/../../../assets/images/markers/primary/1/l2.png';
import m1l2svg from '~/_shared/../../../assets/images/markers/primary/1/l2.svg';
import m1l3png from '~/_shared/../../../assets/images/markers/primary/1/l3.png';
import m2l1png from '~/_shared/../../../assets/images/markers/primary/2/l1.png';
import m2l2png from '~/_shared/../../../assets/images/markers/primary/2/l2.png';
import m2l2svg from '~/_shared/../../../assets/images/markers/primary/2/l2.svg';
import m2l3png from '~/_shared/../../../assets/images/markers/primary/2/l3.png';
import m3l1png from '~/_shared/../../../assets/images/markers/primary/3/l1.png';
import m3l2png from '~/_shared/../../../assets/images/markers/primary/3/l2.png';
import m3l2svg from '~/_shared/../../../assets/images/markers/primary/3/l2.svg';
import m3l3png from '~/_shared/../../../assets/images/markers/primary/3/l3.png';
import m4l1png from '~/_shared/../../../assets/images/markers/primary/4/l1.png';
import m4l2png from '~/_shared/../../../assets/images/markers/primary/4/l2.png';
import m4l2svg from '~/_shared/../../../assets/images/markers/primary/4/l2.svg';
import m4l3png from '~/_shared/../../../assets/images/markers/primary/4/l3.png';
import m5l1png from '~/_shared/../../../assets/images/markers/primary/5/l1.png';
import m5l2png from '~/_shared/../../../assets/images/markers/primary/5/l2.png';
import m5l2svg from '~/_shared/../../../assets/images/markers/primary/5/l2.svg';
import m5l3png from '~/_shared/../../../assets/images/markers/primary/5/l3.png';
import m6l1png from '~/_shared/../../../assets/images/markers/primary/6/l1.png';
import m6l2png from '~/_shared/../../../assets/images/markers/primary/6/l2.png';
import m6l2svg from '~/_shared/../../../assets/images/markers/primary/6/l2.svg';
import m6l3png from '~/_shared/../../../assets/images/markers/primary/6/l3.png';
import m7l1png from '~/_shared/../../../assets/images/markers/primary/7/l1.png';
import m7l2png from '~/_shared/../../../assets/images/markers/primary/7/l2.png';
import m7l2svg from '~/_shared/../../../assets/images/markers/primary/7/l2.svg';
import m7l3png from '~/_shared/../../../assets/images/markers/primary/7/l3.png';
import m8l1png from '~/_shared/../../../assets/images/markers/primary/8/l1.png';
import m8l2png from '~/_shared/../../../assets/images/markers/primary/8/l2.png';
import m8l2svg from '~/_shared/../../../assets/images/markers/primary/8/l2.svg';
import m8l3png from '~/_shared/../../../assets/images/markers/primary/8/l3.png';
import m9l1png from '~/_shared/../../../assets/images/markers/primary/9/l1.png';
import m9l2png from '~/_shared/../../../assets/images/markers/primary/9/l2.png';
import m9l2svg from '~/_shared/../../../assets/images/markers/primary/9/l2.svg';
import m9l3png from '~/_shared/../../../assets/images/markers/primary/9/l3.png';
import m10l1png from '~/_shared/../../../assets/images/markers/primary/10/l1.png';
import m10l2png from '~/_shared/../../../assets/images/markers/primary/10/l2.png';
import m10l2svg from '~/_shared/../../../assets/images/markers/primary/10/l2.svg';
import m10l3png from '~/_shared/../../../assets/images/markers/primary/10/l3.png';
import m11l1png from '~/_shared/../../../assets/images/markers/primary/11/l1.png';
import m11l2png from '~/_shared/../../../assets/images/markers/primary/11/l2.png';
import m11l2svg from '~/_shared/../../../assets/images/markers/primary/11/l2.svg';
import m11l3png from '~/_shared/../../../assets/images/markers/primary/11/l3.png';
import m12l1png from '~/_shared/../../../assets/images/markers/primary/12/l1.png';
import m12l2png from '~/_shared/../../../assets/images/markers/primary/12/l2.png';
import m12l2svg from '~/_shared/../../../assets/images/markers/primary/12/l2.svg';
import m12l3png from '~/_shared/../../../assets/images/markers/primary/12/l3.png';
import m13l1png from '~/_shared/../../../assets/images/markers/primary/13/l1.png';
import m13l2png from '~/_shared/../../../assets/images/markers/primary/13/l2.png';
import m13l2svg from '~/_shared/../../../assets/images/markers/primary/13/l2.svg';
import m13l3png from '~/_shared/../../../assets/images/markers/primary/13/l3.png';
import m14l1png from '~/_shared/../../../assets/images/markers/primary/14/l1.png';
import m14l2png from '~/_shared/../../../assets/images/markers/primary/14/l2.png';
import m14l2svg from '~/_shared/../../../assets/images/markers/primary/14/l2.svg';
import m14l3png from '~/_shared/../../../assets/images/markers/primary/14/l3.png';
import m15l1png from '~/_shared/../../../assets/images/markers/primary/15/l1.png';
import m15l2png from '~/_shared/../../../assets/images/markers/primary/15/l2.png';
import m15l2svg from '~/_shared/../../../assets/images/markers/primary/15/l2.svg';
import m15l3png from '~/_shared/../../../assets/images/markers/primary/15/l3.png';
import m16l1png from '~/_shared/../../../assets/images/markers/primary/16/l1.png';
import m16l2png from '~/_shared/../../../assets/images/markers/primary/16/l2.png';
import m16l2svg from '~/_shared/../../../assets/images/markers/primary/16/l2.svg';
import m16l3png from '~/_shared/../../../assets/images/markers/primary/16/l3.png';
import m17centerpng from '~/_shared/../../../assets/images/markers/primary/17/center.png';
import m17l2png from '~/_shared/../../../assets/images/markers/primary/17/l2.png';
import m17l3png from '~/_shared/../../../assets/images/markers/primary/17/l3.png';
import m18l1png from '~/_shared/../../../assets/images/markers/primary/18/l1.png';
import m18l2png from '~/_shared/../../../assets/images/markers/primary/18/l2.png';
import m18l2svg from '~/_shared/../../../assets/images/markers/primary/18/l2.svg';
import m18l3png from '~/_shared/../../../assets/images/markers/primary/18/l3.png';
import m19l1png from '~/_shared/../../../assets/images/markers/primary/19/l1.png';
import m19l2png from '~/_shared/../../../assets/images/markers/primary/19/l2.png';
import m19l2svg from '~/_shared/../../../assets/images/markers/primary/19/l2.svg';
import m19l3png from '~/_shared/../../../assets/images/markers/primary/19/l3.png';
import m20l1png from '~/_shared/../../../assets/images/markers/primary/20/l1.png';
import m20l2png from '~/_shared/../../../assets/images/markers/primary/20/l2.png';
import m20l2svg from '~/_shared/../../../assets/images/markers/primary/20/l2.svg';
import m20l3png from '~/_shared/../../../assets/images/markers/primary/20/l3.png';
import m21l1png from '~/_shared/../../../assets/images/markers/primary/21/l1.png';
import m21l2png from '~/_shared/../../../assets/images/markers/primary/21/l2.png';
import m21l2svg from '~/_shared/../../../assets/images/markers/primary/21/l2.svg';
import m21l3png from '~/_shared/../../../assets/images/markers/primary/21/l3.png';
import mbucketl2png from '~/_shared/../../../assets/images/markers/primary/bucket/l2.png';
import mbucketl3png from '~/_shared/../../../assets/images/markers/primary/bucket/l3.png';
import {
  MarkerStyleType, type StandardMarkerStyle,
} from '~/_shared/types/marker.types';
import { DEFAULT_MARKER_STYLE_ID } from '~/_shared/utils/markers/markerVisualSettings.constants';

export type MarkerSets = {
  [id: number]: StandardMarkerStyle;
};
const MARKER_SETS = {
  5: {
    selectedColor: '000',
    marker: {
      title: 'Medium',
      id: 5,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m20l1png,
        layerTwo: m20l2png,
        layerTwoSVG: m20l2svg,
        layerThree: m20l3png,
        anchorOffsets: {
          xOffset: 75,
          yOffset: 167,
        },
      },
      counter: {
        width: 83,
        color: '000',
        left: 76,
        top: 78,
      },
      offsets: {
        bottomOffset: 33,
        leftOffset: 27,
        rightOffset: 77,
        topOffset: 32,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  6: {
    selectedColor: '000',
    marker: {
      title: 'Medium Colored',
      id: 6,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m19l1png,
        layerTwo: m19l2png,
        layerTwoSVG: m19l2svg,
        layerThree: m19l3png,
        anchorOffsets: {
          xOffset: 75,
          yOffset: 168,
        },
      },
      counter: {
        width: 83,
        color: '000',
        left: 76,
        top: 78,
      },
      offsets: {
        bottomOffset: 33,
        leftOffset: 27,
        rightOffset: 77,
        topOffset: 32,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  7: {
    selectedColor: '000',
    marker: {
      title: 'Maptive',
      id: 7,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m18l1png,
        layerTwo: m18l2png,
        layerTwoSVG: m18l2svg,
        layerThree: m18l3png,
        anchorOffsets: {
          xOffset: 72,
          yOffset: 166,
        },
      },
      counter: {
        width: 59,
        color: '000',
        left: 72,
        top: 70,
      },
      offsets: {
        bottomOffset: 36,
        leftOffset: 37,
        rightOffset: 95,
        topOffset: 35,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  8: {
    selectedColor: '000',
    marker: {
      title: 'House',
      id: 8,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m15l1png,
        layerTwo: m15l2png,
        layerTwoSVG: m15l2svg,
        layerThree: m15l3png,
        anchorOffsets: {
          xOffset: 97,
          yOffset: 97,
        },
      },
      counter: {
        width: 50,
        color: '000',
        left: 97,
        top: 97,
      },
      offsets: {
        bottomOffset: 47,
        leftOffset: 31,
        rightOffset: 32,
        topOffset: 38,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  9: {
    selectedColor: '000',
    marker: {
      title: 'Billboard',
      id: 9,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m14l1png,
        layerTwo: m14l2png,
        layerTwoSVG: m14l2svg,
        layerThree: m14l3png,
        anchorOffsets: {
          xOffset: 79,
          yOffset: 154,
        },
      },
      counter: {
        width: 139,
        color: '000',
        left: 80,
        top: 79,
      },
      offsets: {
        bottomOffset: 46,
        leftOffset: 5,
        rightOffset: 47,
        topOffset: 39,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  10: {
    selectedColor: 'FFF',
    marker: {
      title: 'Ball Pin',
      id: 10,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m13l1png,
        layerTwo: m13l2png,
        layerTwoSVG: m13l2svg,
        layerThree: m13l3png,
        anchorOffsets: {
          xOffset: 72,
          yOffset: 181,
        },
      },
      counter: {
        width: 59,
        color: 'FFF',
        left: 72,
        top: 70,
      },
      offsets: {
        bottomOffset: 20,
        leftOffset: 37,
        rightOffset: 95,
        topOffset: 34,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  11: {
    selectedColor: '000',
    marker: {
      title: 'Radar Circle',
      id: 11,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m16l1png,
        layerTwo: m16l2png,
        layerTwoSVG: m16l2svg,
        layerThree: m16l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 100,
        },
      },
      counter: {
        width: 64,
        color: '000',
        left: 100,
        top: 100,
      },
      offsets: {
        bottomOffset: 60,
        leftOffset: 13,
        rightOffset: 13,
        topOffset: 60,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  12: {
    selectedColor: '000',
    marker: {
      title: 'Circle with Dot',
      id: 12,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: '',
        layerTwo: mbucketl2png,
        layerTwoSVG: '',
        layerThree: mbucketl3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 100,
        },
      },
      counter: {
        width: 84,
        color: '000',
        left: 100,
        top: 100,
      },
      offsets: {
        bottomOffset: 0,
        leftOffset: 0,
        rightOffset: 0,
        topOffset: 0,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  13: {
    selectedColor: '000',
    marker: {
      title: 'Dot',
      id: 13,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m21l1png,
        layerTwo: m21l2png,
        layerTwoSVG: m21l2svg,
        layerThree: m21l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 100,
        },
      },
      counter: {
        width: 32,
        color: '000',
        left: 100,
        top: 100,
      },
      offsets: {
        bottomOffset: 61,
        leftOffset: 60,
        rightOffset: 60,
        topOffset: 58,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  14: {
    selectedColor: '000',
    marker: {
      title: 'Standard Thin',
      id: 14,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m1l1png,
        layerTwo: m1l2png,
        layerTwoSVG: m1l2svg,
        layerThree: m1l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 164,
        },
      },
      counter: {
        width: 80,
        color: '000',
        left: 100,
        top: 87,
      },
      offsets: {
        bottomOffset: 36,
        leftOffset: 52,
        rightOffset: 52,
        topOffset: 36,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  18: {
    selectedColor: '000',
    marker: {
      title: 'Round with Stem',
      id: 18,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m8l1png,
        layerTwo: m8l2png,
        layerTwoSVG: m8l2svg,
        layerThree: m8l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 165,
        },
      },
      counter: {
        width: 94,
        color: '000',
        left: 100,
        top: 86,
      },
      offsets: {
        bottomOffset: 16,
        leftOffset: 47,
        rightOffset: 47,
        topOffset: 32,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  23: {
    selectedColor: '000',
    marker: {
      title: 'Basic Circle',
      id: 23,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: '',
        layerTwo: m17l2png,
        layerTwoSVG: '',
        layerThree: '',
        anchorOffsets: {
          xOffset: 100,
          yOffset: 100,
        },
      },
      counter: {
        width: 84,
        color: '000',
        left: 100,
        top: 100,
      },
      offsets: {
        bottomOffset: 52,
        leftOffset: 52,
        rightOffset: 52,
        topOffset: 54,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  24: {
    selectedColor: '000',
    marker: {
      title: 'Standard Thin Stem',
      id: 24,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m2l1png,
        layerTwo: m2l2png,
        layerTwoSVG: m2l2svg,
        layerThree: m2l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 166,
        },
      },
      counter: {
        width: 82,
        color: '000',
        left: 100,
        top: 86,
      },
      offsets: {
        bottomOffset: 34,
        leftOffset: 51,
        rightOffset: 50,
        topOffset: 37,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  25: {
    selectedColor: '000',
    marker: {
      title: 'Thick',
      id: 25,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m3l1png,
        layerTwo: m3l2png,
        layerTwoSVG: m3l2svg,
        layerThree: m3l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 163,
        },
      },
      counter: {
        width: 95,
        color: '000',
        left: 100,
        top: 84,
      },
      offsets: {
        bottomOffset: 36,
        leftOffset: 46,
        rightOffset: 46,
        topOffset: 30,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  26: {
    selectedColor: '000',
    marker: {
      title: 'Round with Arrow',
      id: 26,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m4l1png,
        layerTwo: m4l2png,
        layerTwoSVG: m4l2svg,
        layerThree: m4l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 162,
        },
      },
      counter: {
        width: 92,
        color: '000',
        left: 100,
        top: 85,
      },
      offsets: {
        bottomOffset: 37,
        leftOffset: 47,
        rightOffset: 47,
        topOffset: 33,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  27: {
    selectedColor: '000',
    marker: {
      title: 'Standard Skewed',
      id: 27,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m5l1png,
        layerTwo: m5l2png,
        layerTwoSVG: m5l2svg,
        layerThree: m5l3png,
        anchorOffsets: {
          xOffset: 47,
          yOffset: 162,
        },
      },
      counter: {
        width: 95,
        color: '000',
        left: 107,
        top: 93,
      },
      offsets: {
        bottomOffset: 37,
        leftOffset: 44,
        rightOffset: 40,
        topOffset: 38,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  28: {
    selectedColor: '000',
    marker: {
      title: 'Square',
      id: 28,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m6l1png,
        layerTwo: m6l2png,
        layerTwoSVG: m6l2svg,
        layerThree: m6l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 164,
        },
      },
      counter: {
        width: 84,
        color: '000',
        left: 100,
        top: 75,
      },
      offsets: {
        bottomOffset: 30,
        leftOffset: 57,
        rightOffset: 57,
        topOffset: 30,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  29: {
    selectedColor: '000',
    marker: {
      title: 'Square with Arrow',
      id: 29,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m7l1png,
        layerTwo: m7l2png,
        layerTwoSVG: m7l2svg,
        layerThree: m7l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 162,
        },
      },
      counter: {
        width: 100,
        color: '000',
        left: 100,
        top: 79,
      },
      offsets: {
        bottomOffset: 37,
        leftOffset: 50,
        rightOffset: 50,
        topOffset: 31,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  30: {
    selectedColor: '000',
    marker: {
      title: 'Road Sign',
      id: 30,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m9l1png,
        layerTwo: m9l2png,
        layerTwoSVG: m9l2svg,
        layerThree: m9l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 166,
        },
      },
      counter: {
        width: 100,
        color: '000',
        left: 100,
        top: 81,
      },
      offsets: {
        bottomOffset: 33,
        leftOffset: 50,
        rightOffset: 50,
        topOffset: 37,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  31: {
    selectedColor: '000',
    marker: {
      title: 'Hexagon',
      id: 31,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m10l1png,
        layerTwo: m10l2png,
        layerTwoSVG: m10l2svg,
        layerThree: m10l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 163,
        },
      },
      counter: {
        width: 104,
        color: '000',
        left: 100,
        top: 88,
      },
      offsets: {
        bottomOffset: 37,
        leftOffset: 47,
        rightOffset: 47,
        topOffset: 27,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  32: {
    selectedColor: '000',
    marker: {
      title: 'Push Pin',
      id: 32,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m11l1png,
        layerTwo: m11l2png,
        layerTwoSVG: m11l2svg,
        layerThree: m11l3png,
        anchorOffsets: {
          xOffset: 48,
          yOffset: 169,
        },
      },
      counter: {
        width: 60,
        color: '000',
        left: 86,
        top: 112,
      },
      offsets: {
        bottomOffset: 30,
        leftOffset: 42,
        rightOffset: 54,
        topOffset: 37,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  33: {
    selectedColor: '000',
    marker: {
      title: 'Flag',
      id: 33,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m12l1png,
        layerTwo: m12l2png,
        layerTwoSVG: m12l2svg,
        layerThree: m12l3png,
        anchorOffsets: {
          xOffset: 62,
          yOffset: 178,
        },
      },
      counter: {
        width: 72,
        color: '000',
        left: 90,
        top: 66,
      },
      offsets: {
        bottomOffset: 20,
        leftOffset: 52,
        rightOffset: 52,
        topOffset: 37,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
  35: {
    selectedColor: 'fff',
    marker: {
      title: 'Standard Circle',
      id: 35,
      spritesheetSettings: {
        dimensions: {
          height: 200,
          width: 200,
        },
        layerOne: m17centerpng,
        layerTwo: m17l2png,
        layerTwoSVG: m17l2png,
        layerThree: m17l3png,
        anchorOffsets: {
          xOffset: 100,
          yOffset: 100,
        },
      },
      counter: {
        width: 78,
        color: 'fff',
        left: 100,
        top: 100,
      },
      offsets: {
        bottomOffset: 50,
        leftOffset: 52,
        rightOffset: 52,
        topOffset: 50,
      },
    },
    type: 'marker',
    markerType: MarkerStyleType.STANDARD,
  },
} as const;

export const DEFAULT_MARKER_STYLE = MARKER_SETS[DEFAULT_MARKER_STYLE_ID];

export const useMarkerSets = (): MarkerSets => {
  return MARKER_SETS;
};
