import { type GeocodingResponse } from '~/_shared/types/geocoding/geocoding.response';
import {
  type MapInfo, type MapPrivacyLevel, type MapSnapshot, type MapSource, type SpreadsheetDescriptor,
} from '~/_shared/types/map';
import { type LayeringType } from '../../map.repository';

export type SpreadsheetMapInfo = {
  spreadsheetId: number;
  isEncrypted: boolean;
  isPrimary: boolean;
  privileges: 'modify';
  realSpreadsheets: ReadonlyArray<{ realSpreadsheetId: number }>;
  geocoding?: GeocodingResponse;
};

export type MapListingItem = {
  dateCreated: Date;
  dateUpdated: Date;
  id: number;
  isImporting?: boolean;
  isLayered: boolean;
  isMatchupRequired?: boolean;
  isSample?: boolean;
  layering?: LayeringType;
  mapViews: number;
  name: string;
  ownerId: number;
  privacy: MapPrivacyLevel;
  shareId: string;
  snapshots: ReadonlyArray<MapListingItem>;
  spreadSheets: ReadonlyArray<SpreadsheetMapInfo>;
  source: MapSource;
};

export type MapListingItemWithPath = MapListingItem & {
  path: string;
};

const convertSpreadsheetDescriptorToSpreadsheetMapInfo = (
  spreadsheets: ReadonlyArray<SpreadsheetDescriptor>
): ReadonlyArray<SpreadsheetMapInfo> => (
  spreadsheets.map(spreadsheet => ({
    ...spreadsheet,
    realSpreadsheets: spreadsheet.realSpreadsheets.map(realSpreadsheet => ({
      realSpreadsheetId: realSpreadsheet.realSpreadSheetId,
    })),
    spreadsheetId: spreadsheet.spreadSheetId,
  }))
);

export const convertMapSnapshotsToMapListingItems = (snapshots: ReadonlyArray<MapSnapshot>): ReadonlyArray<MapListingItem> => {
  return snapshots.map(s => ({
    dateCreated: new Date(s.created.date),
    dateUpdated: new Date(s.updated.date),
    id: s.id,
    isImporting: s.importing,
    isLayered: false,
    mapViews: 0,
    name: s.name,
    ownerId: s.created.id,
    privacy: s.privacy,
    shareId: s.shareId,
    snapshots: [],
    spreadSheets: convertSpreadsheetDescriptorToSpreadsheetMapInfo(s.spreadsheets),
    source: s.source ?? null,
  }));
};

export const convertMapInfoToMapListingItem = (mapInfo: MapInfo): MapListingItem => {
  const defaultMapListingItem: MapListingItem = {
    dateCreated: new Date(mapInfo.created.date),
    dateUpdated: new Date(mapInfo.updated.date),
    id: mapInfo.id,
    isImporting: mapInfo.importing,
    isLayered: mapInfo.isLayered,
    mapViews: mapInfo.views,
    name: mapInfo.name,
    ownerId: mapInfo.created.id,
    privacy: mapInfo.privacy,
    shareId: mapInfo.shareId,
    snapshots: mapInfo.snapshots ? convertMapSnapshotsToMapListingItems(mapInfo.snapshots) : [],
    spreadSheets: convertSpreadsheetDescriptorToSpreadsheetMapInfo(mapInfo.spreadsheets),
    source: mapInfo.source ?? null,
  };

  if (mapInfo.parentMap) {
    return {
      ...defaultMapListingItem,
      dateCreated: new Date(mapInfo.parentMap.created.date),
      dateUpdated: new Date(mapInfo.parentMap.updated.date),
      id: mapInfo.parentMap.id,
      name: mapInfo.parentMap.name,
      ownerId: mapInfo.parentMap.created.id,
      privacy: mapInfo.parentMap.privacy,
      shareId: mapInfo.parentMap.shareId,
      snapshots: convertMapSnapshotsToMapListingItems(mapInfo.parentMap.snapshots),
    };
  }

  return defaultMapListingItem;
};
