import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';

type ProximityPanelErrorProps = Readonly<{
  children: string;
  isDisplayed: boolean;
}>;

const warningStyle = ({ theme }: ThemeProps) => css({
  margin: '5px 0 0',
  fontSize: '14px',
  color: theme.textColors.danger,
});

export const ProximityPanelErrorComponent: FC<ProximityPanelErrorProps> = props => {
  const theme = useTheme();

  if (!props.isDisplayed) {
    return null;
  }

  return (
    <p css={warningStyle({ theme })}>{props.children}</p>
  );
};
