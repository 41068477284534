import {
  createContext, type ReactNode, useContext, useMemo,
} from 'react';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';

const TooltipContext = createContext<{
  isMobileScreen: boolean;
}>({
  isMobileScreen: false,
});

export const useTooltipContext = () => useContext(TooltipContext);

export const TooltipContextProvider = ({ children }: { children: ReactNode }) => {
  const isMobileScreen = useIsMobileScreenSelector();

  const value = useMemo(() => ({
    isMobileScreen,
  }), [isMobileScreen]);

  return (
    <TooltipContext.Provider value={value}>
      {children}
    </TooltipContext.Provider>
  );
};
