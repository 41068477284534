import { css } from '@emotion/react';
import { type ReactNode } from 'react';

const rowStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
});

const titleStyle = (topPadding: number) => css({
  width: 140,
  paddingTop: topPadding,
});

const contentStyle = css({
  flexGrow: 1,
  minHeight: 75,
  minWidth: 210,
});

type ExportImageRowProps = Readonly<{
  title: string;
  content: ReactNode;
  titleTopPadding?: number;
}>;

export const ExportImageRowComponent = ({
  titleTopPadding = 9,
  title,
  content,
  ...restProps
}: ExportImageRowProps) => {

  return (
    <div {...restProps} css={rowStyle}>
      <div css={titleStyle(titleTopPadding)}>{title}</div>
      <div css={contentStyle}>{content}</div>
    </div>
  );
};
