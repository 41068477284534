import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { ToolboxContainerComponent } from '~/_shared/components/toolboxContainer/toolboxContainer.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type MapSnapshot } from '~/_shared/types/map';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsOpen } from '~/_shared/utils/hooks/useIsOpen';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { MapBasicInfoComponent } from '~/map/basicInfo/mapBasicInfo.component';
import { getMapUrl } from '~/map/map.helpers';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../../_shared/components/modal/confirmation/useConfirmationModal';
import { type SidebarAppProps } from '../../sidebarAppComponentProps.type';
import { SidebarSearchHeaderComponent } from '../mapTools/sidebarSearchHeader.component';
import {
  EditorState,
  renderEditorState,
} from './editorState.enum';
import { SaveMapViewEmptyStateComponent } from './saveMapViewEmptyState.component';
import { SaveMapViewFieldName } from './saveMapViewFieldName.enum';

const PADDING_AROUND = 16;

const rootStyle = css({
  padding: PADDING_AROUND,
});

const dropdownStyle = css({
  height: 40,
});

const dropdownContainerStyle = css({
  marginBottom: 16,
});

const tooltipStyle = css({
  width: '100%',
});

const buttonWrapperStyle = ({ marginTop }: { marginTop: number }) => css({
  width: '100%',
  margin: `${marginTop}px 0 16px`,
});

const buttonStyle = css({
  width: '100%',
  height: 48,
});

const snapshotLineSize = 45;

const iconStyle = ({ theme, disabled }: ThemeProps<{disabled: boolean}>) => css({
  color: disabled ? theme.textColors.disabled : theme.textColors.danger,

  '&:hover': {
    color: disabled ? theme.textColors.disabled : theme.textColors.dangerOnHover,
  },
});

const snapshotStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  display: 'flex',
  height: snapshotLineSize,
  justifyContent: 'space-between',
  lineHeight: `${snapshotLineSize}px`,
  padding: `0 ${PADDING_AROUND}px`,
});

const snapshotTitleStyle = css({
  maxWidth: `calc(100% - ${snapshotLineSize}px)`,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 'inherit',
});

type SaveMapProps = Readonly<SidebarAppProps & {
  currentUserId: number | null;
  description: string;
  editorState?: EditorState;
  fieldErrors: ReadonlyMap<SaveMapViewFieldName, ReadonlyArray<string>>;
  isLoading: boolean;
  isSaveDisabled: boolean;
  name: string;
  snapshotModificationDisabled?: boolean;
  snapshots: ReadonlyArray<MapSnapshot>;

  onDescriptionChange: (newDesc: string) => void;
  onEditorStateChange?: (newState: EditorState) => void;
  onNameChange: (newName: string) => void;
  onSave: () => void;
  onSnapshotDelete: (id: number) => void;
}>;

export const SaveMapViewComponent: FC<SaveMapProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const [query, setQuery] = useState('');
  const queryInputIsOpen = useIsOpen();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const isMapNameValid = props.name.trim().length === 0;

  const onQueryTriggerClick = useCallback(() => {
    if (queryInputIsOpen.isOpen) {
      setQuery('');
    }
    queryInputIsOpen.toggleOpen();
  }, [queryInputIsOpen]);

  const snapshotList = useMemo(() => {
    if (isTextEmpty(query)) {
      return props.snapshots;
    }

    return props.snapshots.filter(snapshot => snapshot.name.toLowerCase().includes(query.toLowerCase()));
  }, [props.snapshots, query]);

  const handleRemove = useCallback((snapshotId: number) => () => {
    const deleteSnapshot = props.onSnapshotDelete;

    openConfirmationModal({
      title: t('Delete Map View'),
      text: t('You are about to delete this map view. You can undo this action any time with the Undo modal.'),
      onConfirm: () => {
        deleteSnapshot(snapshotId);
        closeConfirmationModal();
      },
      isConfirmButtonDestructive: true,
      onCancel: closeConfirmationModal,
      confirmCaption: t('Proceed'),
      permanentConfirmSettings: {
        id: 'map-view-remove-view',
        strategy: PermanentConfirmStrategy.Local,
      },
    });
  }, [closeConfirmationModal, openConfirmationModal, props.onSnapshotDelete, t]);

  return (
    <ToolboxContainerComponent
      title={t('Save Map View')}
      onClose={props.onClose}
    >
      <ScrollBarComponent type={ScrollbarType.Vertical} >
        <div css={rootStyle}>

          {props.editorState && (
            <TooltipDeprComponent
              css={tooltipStyle}
              tooltipContent={props.snapshotModificationDisabled ? t('Only the owner is allowed to modify their map views.') : undefined}
            >
              <RegularDropdownComponent
                isDisabled={props.snapshotModificationDisabled}
                css={dropdownContainerStyle}
                triggerStyle={dropdownStyle}
                onChange={props.onEditorStateChange ?? noop}
                value={props.editorState}
                options={Object.values(EditorState).map(value => ({ value, name: renderEditorState(value, t) }))}
              />
            </TooltipDeprComponent>
          )}

          <MapBasicInfoComponent
            description={props.description}
            descriptionErrorMessage={props.fieldErrors.get(SaveMapViewFieldName.Description)?.[0] ?? undefined}
            isSnapshot
            name={props.name}
            nameErrorMessage={props.fieldErrors.get(SaveMapViewFieldName.Name)}
            namePlaceholder={t('Map View Name')}
            onDescriptionChange={props.onDescriptionChange}
            onNameChange={props.onNameChange}
          />

          <div css={buttonWrapperStyle({ marginTop: props.fieldErrors.has(SaveMapViewFieldName.Description) ? 0 : 21 })}>
            <TooltipComponent
              css={tooltipStyle}
              tooltipContent={isMapNameValid ? t('Map View Name Is Empty') : undefined}
            >
              <ButtonComponent
                css={buttonStyle}
                text={t('Save Map View')}
                onClick={props.onSave}
                isDisabled={isMapNameValid || props.isSaveDisabled}
              />
            </TooltipComponent>
          </div>
        </div>

        <SidebarSearchHeaderComponent
          isOpen={queryInputIsOpen.isOpen}
          onChangeSearchPhrase={setQuery}
          onClick={onQueryTriggerClick}
          searchPhrase={query}
          searchPlaceholder={t('Find Map View')}
          title={t('Saved Map Views')}
        >
          {!snapshotList.length ? (
            <SaveMapViewEmptyStateComponent
              isSearchActive={!isTextEmpty(query) && !!props.snapshots.length}
            />
          ) :
            snapshotList.map(snapshot => {
              const ownedByCurrentUser = snapshot.created.id === props.currentUserId;

              return (
                <div
                  key={snapshot.id}
                  css={snapshotStyle({ theme })}
                >
                  <LinkComponent
                    path={getMapUrl(snapshot.id)}
                    css={snapshotTitleStyle}
                  >
                    {snapshot.name}
                  </LinkComponent>

                  <TooltipDeprComponent
                    tooltipContent={!ownedByCurrentUser && t('Only the owner is allowed to delete their map views.')}
                  >
                    <FontAwesomeIcon
                      css={iconStyle({ theme, disabled: !ownedByCurrentUser })}
                      icon={faTrash}
                      onClick={ownedByCurrentUser ? handleRemove(snapshot.id) : undefined}
                    />
                  </TooltipDeprComponent>
                </div>
              );
            })}
        </SidebarSearchHeaderComponent>

      </ScrollBarComponent>
      {props.isLoading && <OverlayLoaderComponent />}
    </ToolboxContainerComponent>
  );
};
