// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.data-grid-container, span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition : background-color 0ms ease ;
}

.data-grid-container .data-grid .cell {
  user-select: none;
  cursor: cell;
}

.data-grid-container .data-grid .cell[data-disabled=true] {
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./resources/js/src/_shared/components/spreadsheet/react-datasheet.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yCAAyC;EACzC,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["span.data-grid-container, span.data-grid-container:focus {\n  outline: none;\n}\n\n.data-grid-container .data-grid .cell.updated {\n  background-color: rgba(0, 145, 253, 0.16);\n  transition : background-color 0ms ease ;\n}\n\n.data-grid-container .data-grid .cell {\n  user-select: none;\n  cursor: cell;\n}\n\n.data-grid-container .data-grid .cell[data-disabled=true] {\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
