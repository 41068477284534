import {
  type FC,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { updateHeatmapToolStateData } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.actionCreators';
import { type HeatmapToolStateData } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.state';
import {
  hideHeatMap,
  showHeatMap,
} from '~/store/mapSettings/toolsState/visibility/visibility.actionCreators';
import {
  type HeatMap,
  isHeatMapIdentical,
} from '../../_shared/types/heatmap/heatMap.types';
import { useTranslation } from '../../_shared/utils/hooks';
import { useThrottle } from '../../_shared/utils/hooks/useThrottle';
import { AppErrorType } from '../../appError/appErrorType.enum';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import {
  addHeatMap,
  removeHeatMap,
  resetHeatMaps,
  updateHeatMap,
} from '../../store/mapSettings/heatmaps/mapSettingsHeatmaps.actionCreators';
import { useHeatmapItems } from '../../store/mapSettings/heatmaps/useHeatmapItems.selector';
import { useSpreadsheetColumns } from '../../store/matchupData/matchupDataSelectors.hook';
import { useMapIdSelector } from '../../store/selectors/useMapIdSelector';
import { HeatMapPanelComponent } from './heatMapPanel.component';

export const HeatMapPanelContainer: FC = () => {
  const heatMapItems = useHeatmapItems();
  const dispatch = useDispatch();
  const { openModal } = useModal(ModalType.AppError);
  const columns = useSpreadsheetColumns();
  const mapId = useMapIdSelector();
  const [t] = useTranslation();

  const onResetHeatmapItems = useCallback(() => dispatch(resetHeatMaps()), [dispatch]);

  const addHeatMapItem = useCallback((heatmap: HeatMap) => {
    const hmIndex = heatMapItems.findIndex(other => isHeatMapIdentical(heatmap, other.heatmap));

    if (hmIndex === -1) {
      dispatch(addHeatMap(heatmap));
    }
    else {
      openModal({
        type: AppErrorType.General,
        title: t('Same heatmap already exists'),
        content: t('This heatmap already exists on the map. Heatmaps can\'t be duplicated.'),
      });
    }
  }, [dispatch, heatMapItems, openModal, t]);

  const removeHeatMapItem = useCallback((heatmap: HeatMap) => {
    if (mapId) {
      dispatch(removeHeatMap(heatmap, mapId));
    }
  }, [dispatch, mapId]);

  const updateHeatMapItem = useThrottle((newItem: HeatMap) => dispatch(updateHeatMap(newItem)), [dispatch], 600);

  const updateHeatMapItemData = useCallback((heatmapId: string, data: Partial<HeatmapToolStateData>) =>
    dispatch(updateHeatmapToolStateData(heatmapId, data)), [dispatch]);

  const updateHeatMapVisibility = useCallback((heatmapId: string, visibility: boolean) =>
    dispatch(visibility ? showHeatMap(heatmapId) : hideHeatMap(heatmapId)), [dispatch]);

  return (
    <HeatMapPanelComponent
      items={heatMapItems}
      onHeatMapItemAdd={addHeatMapItem}
      onHeatMapItemRemove={removeHeatMapItem}
      updateHeatMapItem={updateHeatMapItem}
      resetHeatMapItems={onResetHeatmapItems}
      updateHeatMapItemData={updateHeatMapItemData}
      updateHeatMapVisibility={updateHeatMapVisibility}
      columns={columns}
    />
  );
};
