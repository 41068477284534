import {
  useCallback, useEffect, useState,
} from 'react';
import { type TinyMapColorTileComponentProps } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { hexAndOpacityToRGBA } from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { getValueOfAnimatableColorProperty } from '../../../_shared/types/animation/animatableProperty.helpers';
import { type AnimatableColorProperty } from '../../../_shared/types/animation/animatableProperty.types';
import {
  registerAnimationCallback,
  removeAnimationCallback,
} from '../../../_shared/utils/colorAnimationTicker/colorAnimationTicker';

export type ActiveObjectStyles = {
  fillColor: string;
  fillOpacity: number;
  borderWidth: number;
  borderColor: AnimatableColorProperty;
};

export const useSidebarIndicatorStyles = (activeStyles: ActiveObjectStyles | undefined) => {
  const [indicatorStyles, setIndicatorStyles] = useState<TinyMapColorTileComponentProps>();

  const computeIndicatorStyles = useCallback((animationPercent: number) => {

    if (!activeStyles) {
      setIndicatorStyles(undefined);
      return;
    }

    const { fillColor, fillOpacity, borderColor, borderWidth } = activeStyles;

    const animatedBorderColor = getValueOfAnimatableColorProperty(borderColor, animationPercent);

    setIndicatorStyles({
      color: hexAndOpacityToRGBA(fillColor, fillOpacity),
      lineColor: hexAndOpacityToRGBA(animatedBorderColor),
      lineWidth: borderWidth,
    });
  }, [activeStyles]);

  useEffect(() => {
    computeIndicatorStyles(0);
    registerAnimationCallback(computeIndicatorStyles);

    return () => {
      removeAnimationCallback(computeIndicatorStyles);
    };
  }, [computeIndicatorStyles]);

  return indicatorStyles;
};
