import { css } from '@emotion/react';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faPlus,
  faSyncAlt,
  faTrash,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { type FileRejection } from 'react-dropzone';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { RoundButtonComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { delay } from '~/_shared/utils/delay';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { DropzoneComponent } from '../dropzone/dropzone.component';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import {
  UploadStatus, UploadStatusComponent,
} from '../uploadStatus/uploadStatus.component';
import {
  SideOptionsFrame, type SideOptionsFrameIcon,
} from './sideOptionsFrame.component';

const modalContentStyle = css({
  padding: 0,
});

const contentStyle = css({
  padding: 20,
});

const libraryStyle = css({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
});

const libraryItemStyle = css({
  marginBottom: 10,
});

const statuWrapperStyle = css({
  marginTop: 10,
});

const imageStyle = (src: Url) => css({
  position: 'absolute',
  width: '104px',
  height: '104px',
  right: '14px',
  top: '14px',
  backgroundImage: `url(${src})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const areaStyle = css({
  marginTop: 20,
});

const uploadStatusStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  border: `1px solid ${theme.borderColors.primary}`,
  borderRadius: 4,
  overflow: 'hidden',
});

const uploadStatusItemStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '6px 0',
});

const uploadStatusSuccessStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 96,
  padding: '0 27px',
});

const successBadgeStyle = ({ theme }: ThemeProps) => css({
  background: theme.iconColors.success,
  boxShadow: '0px 2px 4px rgba(105, 112, 117, 20%)',
  '&:hover': {
    background: theme.iconColors.success,
  },
  color: '#FFFFFF',
});

const expandButtonStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.buttonColors.quaternaryBackground,
  border: `2px solid ${theme.buttonColors.quaternaryBorder}`,
  color: theme.buttonColors.quaternaryText,
  height: '40px',
  right: '0px',
  width: '40px',
  '&:hover': {
    backgroundColor: theme.buttonColors.quaternaryBackgroundOnHover,
    borderColor: theme.buttonColors.quaternaryBorderOnHover,
    color: theme.buttonColors.quaternaryActiveText,
  },
});

const prefixIconForExpandButtonStyle = css({
  marginRight: 0,
});

const titleStyle = css({
  cursor: 'default',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '16px',
  textTransform: 'uppercase',
});

const imageLibraryTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}, titleStyle);

export type CustomImage = Readonly<{
  id: number;
  url: string;
  alt?: string;
}>;

export type UploadingFileDetails = Readonly<{
  file: File;
  status: UploadStatus;
  uploadProgress?: number;
}>;

type ImageLibraryComponentProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  images: ReadonlyArray<CustomImage>;
  currentMarkerImages: number[];
  isLoading: boolean;
  uploadingFilesDetails: UploadingFileDetails[];
  onImageDelete: (customImageId: number) => void;
  onImageRotate: (customImageId: number) => void;
  onImageRemoveFromActive: (customImageId: number) => void;
  onImageAddToActive: (customImageId: number) => void;
  onDropZoneFilesSelect: (files: File[]) => void;
  onRetryInDropzoneClick: (file: UploadingFileDetails) => void;
  onRemoveFromDropzoneClick: (file: UploadingFileDetails) => void;
  acceptFileExtensions: ReadonlyArray<string>;
  onFileReject: (rejections: ReadonlyArray<FileRejection>) => void;
}>;

export const ImageLibraryComponent: React.FC<ImageLibraryComponentProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [isImageLibraryExpanded, setIsImageLibraryExpanded] = useState<boolean>(true);
  const [showComplete, setShowComplete] = useState(false);
  const {
    uploadingFilesDetails, onImageRemoveFromActive, onImageAddToActive,
    onImageRotate, onImageDelete, onRetryInDropzoneClick, onRemoveFromDropzoneClick,
    isLoading, currentMarkerImages,
  } = props;
  const allFilesCompletedUpload = !!uploadingFilesDetails.length
    && uploadingFilesDetails.every(item => item.status === UploadStatus.Success);

  const currentMarkerImagesSet = useMemo(() => new Set<number>(currentMarkerImages), [currentMarkerImages]);

  useEffect(() => {
    if (allFilesCompletedUpload) {
      setShowComplete(true);
      delay(5000).then(() => {
        setShowComplete(false);
      });
    }
  }, [allFilesCompletedUpload]);

  const handleImageButton = useCallback((id: number, callback: (id: number) => void) =>
    () => callback(id),
  []);

  const handleSetIsImageLibraryExpanded = useCallback(() =>
    setIsImageLibraryExpanded(state => !state)
  , [setIsImageLibraryExpanded]);

  const selectedImages = useMemo(() => {
    return props.images.filter(image => currentMarkerImagesSet.has(image.id))
      .map(image => {
        const rightTopButton: SideOptionsFrameIcon = {
          icon: faXmark,
          style: RoundButtonStyle.PrimaryInverted,
          callback: handleImageButton(image.id, onImageRemoveFromActive),
        };
        const rightBottomButton: SideOptionsFrameIcon = {
          icon: faTrash,
          style: RoundButtonStyle.DangerInverted,
          callback: handleImageButton(image.id, onImageDelete),
        };

        // Image rotation is not implemented yet
        const _leftTopButton: SideOptionsFrameIcon = {
          icon: faSyncAlt,
          style: RoundButtonStyle.ConfirmInverted,
          callback: handleImageButton(image.id, onImageRotate),
        };

        return ({
          id: image.id,
          render: () => (
            <div
              key={image.id}
              css={libraryItemStyle}
            >
              <SideOptionsFrame
                rightTopButton={rightTopButton}
                rightBottomButton={rightBottomButton}
              >
                <div css={imageStyle(image.url)} />
              </SideOptionsFrame>
            </div>
          ),
        });
      });
  }
  ,
  [props.images, currentMarkerImagesSet, handleImageButton, onImageRemoveFromActive, onImageDelete, onImageRotate]);

  const imageLibraryImages = useMemo(() => {
    return props.images.filter(image => !currentMarkerImagesSet.has(image.id))
      .map(image => {
        const rightTopButton: SideOptionsFrameIcon = {
          icon: faPlus,
          style: RoundButtonStyle.ConfirmInverted,
          callback: handleImageButton(image.id, onImageAddToActive),
        };
        const rightBottomButton: SideOptionsFrameIcon = {
          icon: faTrash,
          style: RoundButtonStyle.DangerInverted,
          callback: handleImageButton(image.id, onImageDelete),
        };

        return ({
          id: image.id,
          render: () => (
            <div
              key={image.id}
              css={libraryItemStyle}
            >
              <SideOptionsFrame
                rightTopButton={rightTopButton}
                rightBottomButton={rightBottomButton}
              >
                <div css={imageStyle(image.url)} />
              </SideOptionsFrame>
            </div>
          ),
        });
      });
  },
  [props.images, currentMarkerImagesSet, handleImageButton, onImageAddToActive, onImageDelete]);

  return (
    <ModalComponent
      maxWidth={620}
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Location Images')}
      contentStyle={modalContentStyle}
      additionalContent={(
        <>
          {isLoading &&
            <OverlayLoaderComponent />
          }
        </>
      )}
    >
      <div css={contentStyle} >
        <div>
          <div css={titleStyle}>
            {t('Images selected for this location')}
          </div>
          <div css={libraryStyle} >
            {selectedImages.map(image => image.render())}
          </div>
        </div>

        <div css={areaStyle} >
          <div css={titleStyle}>
            {t('Upload / select additional images')}
          </div>

          <div css={statuWrapperStyle}>
            {(props.uploadingFilesDetails.length === 0 || (allFilesCompletedUpload && !showComplete)) && (
              <DropzoneComponent
                onFilesSelect={props.onDropZoneFilesSelect}
                acceptFileExtensions={props.acceptFileExtensions}
                onFilesReject={props.onFileReject}
                maxSize={2000000}
                primaryLabel={t('Upload an image')}
                secondaryLabel={t(' or drop an image here.')}
                isDisabled={false}
                isMulti
              />
            )}

            {!allFilesCompletedUpload && props.uploadingFilesDetails.length > 0 && (
              <div css={uploadStatusStyle({ theme })}>
                {props.uploadingFilesDetails
                  .map((fileDetails, index) => (
                    <UploadStatusComponent
                      css={uploadStatusItemStyle({ theme })}
                      iconSize={20}
                      iconMargin={8}
                      file={fileDetails.file}
                      key={index}
                      onRemoveClick={() => onRemoveFromDropzoneClick(fileDetails)}
                      onRetryClick={() => onRetryInDropzoneClick(fileDetails)}
                      orderIndex={index}
                      progress={fileDetails.uploadProgress ?? 0}
                      status={fileDetails.status}
                    />
                  ))}
              </div>
            )}

            {allFilesCompletedUpload && showComplete && (
              <div css={uploadStatusSuccessStyle({ theme })} >
                {t('Complete')}
                <RoundButtonComponent
                  css={successBadgeStyle({ theme })}
                  icon={faCheck}
                  buttonStyle={RoundButtonStyle.Primary}
                />
              </div>
            )}
          </div>
        </div>

        <div css={areaStyle} >
          <div css={imageLibraryTitleStyle}>
            {t('Image library')}
            <ButtonComponent
              buttonStyle={ButtonStyle.Custom}
              css={expandButtonStyle({ theme })}
              prefixIcon={isImageLibraryExpanded ? faChevronUp : faChevronDown}
              onClick={handleSetIsImageLibraryExpanded}
              text=""
              prefixIconStyle={prefixIconForExpandButtonStyle}
            />
          </div>
          {isImageLibraryExpanded && (
            <div css={libraryStyle} >
              {imageLibraryImages.map(image => image.render())}
            </div>
          )}
        </div>

      </div>
    </ModalComponent>
  );
};
