import { css } from '@emotion/react';
import {
  faCircleInfo, faCopy,
} from '@fortawesome/pro-solid-svg-icons';
import React, {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  AccordionComponent, type AccordionData,
} from '~/_shared/baseComponents/accordion';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import { TextareaComponent } from '~/_shared/baseComponents/textarea/textarea.component';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { InlineWarningComponent } from '~/_shared/baseComponents/warning/inlineWarning.component';
import { warningColorStyle } from '~/_shared/baseComponents/warning/warning.styles';
import { SuccessAnimationComponent } from '~/_shared/components/animation/success/sucessAnimation.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { copyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { MapMigrationResultStackComponent } from '~/map/mapMigration/components/mapMigrationResultStack.component';
import { v4MapImportDialogLayout } from '~/map/mapMigration/migrateMapsDialog.styles';
import type { ModalProps } from '~/modal/modalType.enum';
import { mapListingChanged } from '~/store/frontendState/mapListing/mapListing.actionCreators';
import { v4MapMigrationDismissed } from '~/store/mapMigration/mapMigration.actionCreators';
import { useMapMigrationErrorSummarySelector } from '~/store/mapMigration/selectors/mapMigrationErrorSummary.selector';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';

type CreateNewMapProps = ModalProps;

const footerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  minWidth: '100%',
});

const successContentLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '100%',
  gap: 16,
});

const textAreaStyle = css({
  width: 'initial',
  height: 102,
  resize: 'none',
  flex: '1 1',
});

const copyButtonContainerStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'stretch',
  gap: 16,
});

const fullWidthHorizontalLineStyles = (theme: Theme) => css({
  marginInline: -16,
  marginBlock: 0,
  width: 'calc(100% + 32px)',
  border: 'none',
  height: 1,
  color: theme.borderColors.primary,
  backgroundColor: theme.borderColors.primary,
});

const centerContentVertically = css({
  alignItems: 'center',
});

const accordionItemButtonStyles = css({ marginInline: -16, textTransform: 'uppercase' });
const accordionPanelStyle = css({ paddingInline: 0, fontSize: 16 });

const toggle = (x: boolean) => !x;

export const MigrateMapsFinishedComponent: FC<CreateNewMapProps> = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const {
    status,
    queueId,
    mapMigrationResults,
  } = useSelector(state => state.mapMigration.migrationStatus);
  const [partiallyFailedSegmentIsExpanded, setPartiallyFailedSegmentIsExpanded] = useState(true);
  const [completeFailedSegmentIsExpanded, setCompleteFailedSegmentIsExpanded] = useState(true);
  const clientId = useClientIdSelector();
  const { developerInfo } = useMapMigrationErrorSummarySelector();
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);

  const partiallyFailedMaps = useMemo(() =>
    mapMigrationResults.filter(result => result.map.error === 'SPREADSHEET_PARTIALLY_RECONSTRUCTED')
      .map(result => result.map),
  [mapMigrationResults]);

  const failedMaps = useMemo(() =>
    mapMigrationResults.filter(result =>
      (result.result === 'FAILED') && (result.map.error !== 'SPREADSHEET_PARTIALLY_RECONSTRUCTED'))
      .map(result => result.map),
  [mapMigrationResults]);

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(developerInfo);
    showCopiedToClipboardMessage();
  }, [developerInfo, showCopiedToClipboardMessage]);

  const dismissQueue = useCallback(() => {
    if (clientId) {
      dispatch(v4MapMigrationDismissed(clientId, queueId));
      dispatch(mapListingChanged());
      props.onClose();
    }
  }, [clientId, dispatch, props, queueId]);

  const partialMigrationFails: AccordionData | null = useMemo(() => partiallyFailedMaps.length > 0 ? {
    header: t('MapMigrationPartialFailHeading'),
    child: (
      <MapMigrationResultStackComponent
        maps={partiallyFailedMaps}
        warning={t('MapMigrationPartialFailWarning')}
        instructions={<p>{t('MapMigrationPartialFailInfo')}</p>}
      />
    ),
    isExpanded: partiallyFailedSegmentIsExpanded,
    onHeadingClick: () => setPartiallyFailedSegmentIsExpanded(toggle),
  } : null,
  [partiallyFailedMaps, partiallyFailedSegmentIsExpanded, t]);

  const completeMigrationFails: AccordionData | null = useMemo(() => failedMaps.length > 0 ? {
    header: t('MapMigrationCompleteFailHeading'),
    child: (
      <MapMigrationResultStackComponent
        maps={failedMaps}
        warning={partiallyFailedMaps.length > 0 ? t('MapMigrationCompleteFailWarning') : t('MapMigrationCompleteFailNoPartialsWarning')}
        instructions={<p>{t('MapMigrationCompleteFailInfo')}</p>}
      />
    ),
    isExpanded: completeFailedSegmentIsExpanded,
    onHeadingClick: () => setCompleteFailedSegmentIsExpanded(toggle),
  } : null,
  [completeFailedSegmentIsExpanded, failedMaps, partiallyFailedMaps, t]);

  const migrationAccordionResults = useMemo(() =>
    [partialMigrationFails, completeMigrationFails].filter(notNullsy),
  [completeMigrationFails, partialMigrationFails]);

  return (
    <ModalComponent
      onClose={noop}
      isCloseDisabled
      isOpen={props.isOpen}
      caption={t('Map Migration Finished')}
      maxWidth={800}
      footer={(
        <div css={footerStyles}>
          <ButtonComponent text={t('OK')} onClick={dismissQueue} />
        </div>
      )}
    >
      {status === 'FAILED' ? (
        <div css={v4MapImportDialogLayout}>
          <h1>
            <InlineWarningComponent /> {t('An error occurred while migrating your maps')}
          </h1>
          <p>
            {t('MapMigrationFailedIntro')}
          </p>
          <AccordionComponent
            data={migrationAccordionResults}
            itemButtonStyle={accordionItemButtonStyles}
            panelStyle={accordionPanelStyle}
          />

          <hr
            css={fullWidthHorizontalLineStyles}
          />
          <Inline
            gap={8}
            wrap="nowrap"
            css={[warningColorStyle, centerContentVertically]}
          >
            <FontAwesomeIcon icon={faCircleInfo} size="xl" />
            <p>
              {t('MapMigrationDevErrorInfoInstructions')}
            </p>
          </Inline>

          <div css={copyButtonContainerStyle}>
            <TextareaComponent
              css={textAreaStyle}
              value={developerInfo}
              isReadOnly
            />
            <TooltipComponent
              tooltipContent={isCopiedToClipboard ? t('Copied to Clipboard!') : null}
              behavior={TooltipBehavior.ShowAlways}
            >
              <ButtonComponent
                prefixIcon={faCopy}
                text={t('Copy')}
                onClick={handleCopyToClipboard}
                buttonStyle={ButtonStyle.Secondary}
              />
            </TooltipComponent>
          </div>
        </div>
      ) : (
        <div css={v4MapImportDialogLayout}>
          <h1>
            {t('Your maps have been transferred successfully')}
          </h1>
          <div css={successContentLayoutStyles}>
            <SuccessAnimationComponent size={80} />
          </div>
        </div>
      )}
    </ModalComponent>
  );
};
