import {
  FilterDataTypes, type FilterTree, type FilterTreeItemLiteral,
} from '~/_shared/types/filterTree.types';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { convertPolygonToGeometry } from '~/_shared/types/polygon/polygon.utils';
import { flatten } from '~/_shared/utils/collections/collections';
import { newPerSpreadsheetMap } from '../../../_shared/types/spreadsheet/spreadsheet.types';
import {
  type BoundaryFilterBoundariesRequest,
  type BoundaryFilterRequest,
} from '../../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import {
  type BoundaryTerritoryFilterGroups,
  type BoundaryTerritoryFilterRequest,
} from '../../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  createRadiusFilterItemRequest,
  type RadiusFilterRequestArguments,
} from '../../../spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import { type SpreadsheetDataBulkRequestGetterFilter } from '../../../spreadsheet/spreadsheet.repository';
import {
  type FilterTreeRequest,
  getAllFilterTreeRequestsFromMapSettings,
  mergeFilterTrees,
} from '../filtering/spreadsheetDataFiltering.helpers';
import { type FilterTreeMapSettingsParams } from '../filtering/useFilterTreeMapSettingsParams';

export const createAreaRequestGetterFilter = (
  useMainFilters: boolean,
  filtersCombine: 'and' | 'or', // set to true when also using main filters
  filterTreeParams: FilterTreeMapSettingsParams,
  spreadsheetId: number,
  circles?: ReadonlyArray<RadiusFilterRequestArguments> | null,
  multiPolygons?: ReadonlyArray<MultiPolygon> | null,
  boundaries?: BoundaryFilterBoundariesRequest | null,
  boundaryTerritories?: ReadonlyArray<BoundaryTerritoryFilterGroups> | null,
): SpreadsheetDataBulkRequestGetterFilter => {
  let filterTree: FilterTreeRequest = newPerSpreadsheetMap();
  let searchFilterTree: FilterTreeRequest = newPerSpreadsheetMap();

  if (useMainFilters) {
    const allFilterTreeRequests = getAllFilterTreeRequestsFromMapSettings(filterTreeParams, [spreadsheetId]);
    filterTree = allFilterTreeRequests.filterTree;
    searchFilterTree = allFilterTreeRequests.searchFilterTree;
  }

  const polygonFilter = flatten(multiPolygons ?? []).map<FilterTreeItemLiteral>(polygon => ({
    type: 'literal',
    ref: {
      data_type: FilterDataTypes.POLYGON,
      value: convertPolygonToGeometry(polygon),
    },
  }));

  const radiusFilter = (circles ?? []).map<FilterTreeItemLiteral>(circle => ({
    type: 'literal',
    ref: {
      data_type: FilterDataTypes.RADIUS,
      value: createRadiusFilterItemRequest(circle),
    },
  }));

  const polygonAndCirclesFilter: FilterTree | undefined = polygonFilter.length || radiusFilter.length ? {
    type: 'and',
    children: [{
      type: 'or',
      children: [
        ...polygonFilter,
        ...radiusFilter,
      ],
    }],
  } : undefined;

  const combinedFilterTree = mergeFilterTrees([
    filterTree[spreadsheetId]?.filterTree,
    searchFilterTree[spreadsheetId]?.filterTree,
    polygonAndCirclesFilter,
  ], 'and');

  let boundaryFilterRequest: BoundaryFilterRequest | undefined;
  if (boundaries) {
    boundaryFilterRequest = {
      type: 'or',
      boundaries,
    };
  }

  let boundaryTerritoryFilterRequest: BoundaryTerritoryFilterRequest | undefined;
  if (boundaryTerritories && boundaryTerritories.length > 0) {
    boundaryTerritoryFilterRequest = {
      type: 'or',
      boundary_territory_groups: boundaryTerritories,
    };
  }

  return {
    only_get_filtered: true,
    filter_combine: filtersCombine,
    filter_tree: combinedFilterTree ?? undefined,
    boundary_filter: boundaryFilterRequest,
    boundary_territory_filter: boundaryTerritoryFilterRequest,
  };
};
