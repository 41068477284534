import { useState } from 'react';
import {
  updateMapData,
  updateMapSnapshotData,
} from '../../../map/map.repository';
import { type MapPrivacyLevel } from '../../types/map';
import { type ApiError } from '../../utils/api/apiError.helpers';
import { copy } from '../../utils/collections/collections';
import { MapPrivacySelectInputField } from './mapPrivacySelectorInputField.enum';

export type MapPrivacyFieldErrors = ReadonlyMap<MapPrivacySelectInputField, ReadonlyArray<string>>;

export type MapPrivacySelectRequestParams = Readonly<{
  mapId: number | null;
  clientId: number | null;
  snapshotId?: number;
  privacyLevel: MapPrivacyLevel;
  password: string;
  password_confirmation: string;
}>;

const printUnknownFieldErrorsMessage = (apiError: ApiError) => {
  const unknownFieldErrors = Object.values(MapPrivacySelectInputField).reduce((errors, field) => {
    delete errors[field];
    return errors;
  }, { ...apiError.errors });
  if (Object.values(unknownFieldErrors).length > 0) {

    console.error('mapPrivacySelect: got ApiError with unknown fieldErrors: ', unknownFieldErrors);
  }
};

export const useUpdateMapPrivacy = () => {
  const [fieldErrors, setFieldErrors] = useState<MapPrivacyFieldErrors>(new Map());
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleError = (apiError: ApiError) => {
    const knownFieldErrors = new Map(Object.values(MapPrivacySelectInputField)
      .filter(field => {
        return apiError.errors?.hasOwnProperty(field);
      })
      .map(field => [field, apiError.errors?.[field] ?? []] as const));
    setFieldErrors(knownFieldErrors);

    if (knownFieldErrors.size === 0) {
      console.error('mapPrivacySelect: got ApiError without any known fieldErrors, showing global error.');
      printUnknownFieldErrorsMessage(apiError);
      setGlobalError(apiError.message);
    }
    return { fieldErrors: knownFieldErrors, globalError: apiError.message };
  };

  const submitMapPrivacySelectRequest = (params: MapPrivacySelectRequestParams): Promise<{ fieldErrors: MapPrivacyFieldErrors; globalError: string | null }> => {
    if (params.mapId === null || params.clientId === null) {
      throw new Error('Cannot set map privacy when map with mapId or clientId is not loaded.');
    }
    setIsLoading(true);

    return updateMapData(params.clientId, params.mapId, {
      privacy_level: {
        type: params.privacyLevel,
        password: params.password,
        password_confirmation: params.password_confirmation,
      },
    })
      .then(() => {
        setGlobalError(null);
        setFieldErrors(new Map());
        return { fieldErrors: new Map(), globalError: null };
      })
      .catch(e => {
        const apiError = e as ApiError;
        return handleError(apiError);
      })
      .finally(() => setIsLoading(false));
  };

  const submitMapSnapshotPrivacySelectRequest = (params: MapPrivacySelectRequestParams): Promise<{ fieldErrors: MapPrivacyFieldErrors; globalError: string | null }> => {
    if (params.mapId === null || params.clientId === null || params.snapshotId === undefined) {
      throw new Error('Cannot set map snapshot privacy when map with mapId, map snapshotId or clientId is not loaded.');
    }
    setIsLoading(true);

    return updateMapSnapshotData(params.clientId, params.mapId, params.snapshotId, {
      privacy_level: {
        type: params.privacyLevel,
        password: params.password,
        password_confirmation: params.password_confirmation,
      },
    })
      .then(() => {
        setGlobalError(null);
        setFieldErrors(new Map());
        return { fieldErrors: new Map(), globalError: null };
      })
      .catch(e => {
        const apiError = e as ApiError;
        return handleError(apiError);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    fieldErrors,
    globalError,
    isLoading,
    updateMapPrivacy: submitMapPrivacySelectRequest,
    updateSnapshotPrivacy: submitMapSnapshotPrivacySelectRequest,
    clearGlobalError: () => setGlobalError(null),
    clearFieldError: (field: MapPrivacySelectInputField) => setFieldErrors(prev => copy.andRemove(prev, [field])),
  };
};
