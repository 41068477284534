import {
  css,
  type SerializedStyles,
} from '@emotion/react';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  getTextInputHeight,
  InputSize,
} from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';

const ADD_MORE_PLACEHOLDER_ICON_CLASSNAME = 'add-more-placeholder-icon';

const canAddMoreStyle = (props: ThemeProps<{
  size: InputSize;
  width: number | string;
}>) => css({
  alignItems: 'center',
  backgroundColor: props.theme.backgroundColors.secondary,
  border: `1px dashed ${props.theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color: props.theme.textColors.contrast,
  cursor: 'default',
  display: 'flex',
  fontSize: 12,
  gap: 10,
  height: getTextInputHeight(props.size) ?? undefined,
  justifyContent: 'center',
  padding: 8,
  width: props.width,
  '&:hover': {
    backgroundColor: props.theme.backgroundColors.highlight,
    borderColor: props.theme.borderColors.primaryFocused,
    [`.${ADD_MORE_PLACEHOLDER_ICON_CLASSNAME}`]: {
      color: props.theme.iconColors.success,
    },
  },
});

const iconStyle = (theme: Theme) => css({
  color: theme.iconColors.primary,
  fontSize: 16,
});

type AddMorePlaceholderProps = Readonly<{
  className?: string;
  iconStyle?: SerializedStyles;
  size?: InputSize;
  title: string;
  width?: number | string;

  onClick: () => void;
}>;

export const AddMorePlaceholderComponent: React.FC<AddMorePlaceholderProps> = props => {
  const theme = useTheme();
  const size = props.size || InputSize.Medium;
  const width = props.width || '100%';

  return (
    <div
      className={props.className}
      css={canAddMoreStyle({ theme, size, width })}
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        className={ADD_MORE_PLACEHOLDER_ICON_CLASSNAME}
        css={[iconStyle(theme), props.iconStyle]}
        icon={faCirclePlus}
      />
      {props.title}
    </div>
  );
};
