import { faCar } from '@fortawesome/pro-regular-svg-icons/faCar';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';
import React, { useMemo } from 'react';
import { EmptyStateComponent } from '../../_shared/components/emptyState/emptyState.component';
import { ProximityType } from '../../_shared/types/proximity/proximity.enums';
import { useTranslation } from '../../_shared/utils/hooks';

type ProximityEmptyStateProps = Readonly<{
  isSearchActive: boolean;
  proximityType: ProximityType;
}>;

export const ProximityEmptyStateComponent: React.FC<ProximityEmptyStateProps> = props => {
  const [t] = useTranslation();

  const { title, description } = useMemo(() => {
    if (props.isSearchActive) {
      return {
        title: t('No Saved Proximities Match Your Search'),
        description: t('Refine your search and try again'),
      };
    }

    return {
      title: t('No Saved Proximities'),
      description: t('Your saved proximities will be listed here'),
    };
  }, [props.isSearchActive, t]);

  return (
    <EmptyStateComponent
      css={{ padding: '50px 30px 16px' }}
      icon={props.proximityType === ProximityType.DriveTimePolygon ? faCar : faCircleDot}
      title={title}
      description={description}
    />
  );
};
