import { css } from '@emotion/react';
import {
  faMoon, faSun,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { darkTheme } from '~/_shared/themes/dark.theme';
import { type ThemeType } from '~/_shared/themes/themeType';
import { useTranslation } from '~/_shared/utils/hooks';

type HomepageInfoThemeCardProps = Readonly<{
  currentTheme: ThemeType;
  onThemeChange?: (isDarkTheme: boolean) => void;
}>;

const headingStyle = css({
  fontSize: 20,
  fontWeight: 500,
  textTransform: 'uppercase',
});

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const iconStyle = css({
  marginLeft: 8,
});

export const HomepageInfoThemeCardComponent: FC<HomepageInfoThemeCardProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={wrapperStyle}>
      <span css={headingStyle}>{t('Theme Style')}</span>

      <ToggleComponent
        css={iconStyle}
        isOn={props.currentTheme === darkTheme.name}
        offIcon={faSun}
        onChange={props.onThemeChange}
        onIcon={faMoon}
        isDisabled={!props.onThemeChange}
      />
    </div>
  );
};
