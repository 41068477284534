import { MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA } from './heatmapToolState.actionTypes';
import { type HeatmapToolStateData } from './heatmapToolState.state';

export const updateHeatmapToolStateData = (heatmapId: string, data: Partial<HeatmapToolStateData>) => ({
  type: MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA,
  payload: {
    heatmapId,
    data,
  },
}) as const;
