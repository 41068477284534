import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  destroyBroadcast, initializeBroadcast,
} from '~/_shared/utils/api/broadcast.helpers';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { AuthBroadcastChannel } from '~/authorization/authContext/auth.utils';
import { CLIENT_SUBSCRIPTION_CHANGED } from '~/store/frontendState/client/client.actionTypes';
import { select } from '../../_shared/utils/saga/effects';
import { StorageService } from '../../_shared/utils/storageService';
import {
  authenticateUser,
  getUserData,
  signOutUser,
  type UserDataResponse,
} from './repository/userData.repository';
import { type UserDataAction } from './userData.action';
import {
  userGetDataError,
  userGetDataRequest,
  userGetDataSuccess,
  userSignInError,
  userSignOutSuccess,
} from './userData.actionCreators';
import {
  USER_GET_DATA_REQUEST,
  USER_RESTORE_DATA,
  USER_SIGN_IN_REQUEST,
  USER_SIGN_OUT_REQUEST,
} from './userData.actionTypes';
import { userDataServerToClientModel } from './userData.dataMapping';

function* onUserSignInRequest(action: PickAction<UserDataAction, typeof USER_SIGN_IN_REQUEST>) {
  try {
    yield call(authenticateUser, action.payload.email, action.payload.password, action.payload.rememberMe);
    yield put(userGetDataRequest());
  }
  catch (e) {
    yield put(userSignInError(e.message || 'Error while logging in'));
  }
}

function* onRefreshUserData() {
  yield put(userGetDataRequest());
}

function* onUserGetDataRequest(action: PickAction<UserDataAction, typeof USER_GET_DATA_REQUEST>) {
  try {
    const userData: UserDataResponse = yield call(getUserData);
    initializeBroadcast({
      userId: userData.data.id,
    });

    const clientModel = userDataServerToClientModel(userData);
    yield put(userGetDataSuccess(clientModel, action.payload.options));
  }
  catch (e) {
    yield put(userGetDataError(e.message));
  }
}

function* onUserSignOut(action: PickAction<UserDataAction, typeof USER_SIGN_OUT_REQUEST>) {
  destroyBroadcast();

  try {
    yield call(signOutUser);
    AuthBroadcastChannel.postMessage({ type: 'logout' });
    action.payload.onSuccess?.();
    yield put(userSignOutSuccess());
  }
  catch (e) {
    console.error('Error while signing out', e);
  }
}

export function* getUserStorageService() {
  const userId: number | null = yield select<number | null>(state => state.userData.id);

  return new StorageService(userId);
}

export function* userDataSagas() {
  yield takeLatest(USER_RESTORE_DATA, onRefreshUserData);
  yield takeLatest(CLIENT_SUBSCRIPTION_CHANGED, onRefreshUserData);
  yield takeLatest(USER_SIGN_IN_REQUEST, onUserSignInRequest);
  yield takeLatest(USER_GET_DATA_REQUEST, onUserGetDataRequest);
  yield takeLatest(USER_SIGN_OUT_REQUEST, onUserSignOut);
}
