import { ColumnRole } from '~/_shared/types/columnRole.enum';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import { type SpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import {
  type ColumnPerSpreadsheetSettings, type PerColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { SERVER_ADDED_COLUMN_NAMES } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import {
  cloneDeep, mergeDeep,
} from '~/_shared/utils/object/deepMerge';
import {
  createDataWithColumnPerSpreadsheetSettings, getDataFromColumnPerSpreadsheetSettings,
} from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import {
  type BubbleEditorItem, type BubbleItemMap, type ItemColumnSettings,
} from './locationData.types';
import {
  type PanelColumnSettings, type PanelSettingsColumnItemMap,
} from './panelSettings.types';

export const getColumnItemById = getDataFromColumnPerSpreadsheetSettings;

export const createBubbleItemInLookup = createDataWithColumnPerSpreadsheetSettings;

// This method is used for the modification of individual items of a ColumnPerSpreadsheetSettings structure
// It can therefore be also used for the conversion between ColumnPerSpreadsheetSettings structures with different item types
// The relevantItemGetter function decides what the item contained in the ColumnPerSpreadsheetSettings structure will be
const modifyColumnPerSpreadsheetSettingsItems = <T extends BubbleEditorItem | PanelColumnSettings, U extends BubbleEditorItem | PanelColumnSettings>(
  settings: ColumnPerSpreadsheetSettings<T>,
  relevantItemGetter: (spreadsheetColumnId: SpreadsheetColumnId) => U | undefined
): ColumnPerSpreadsheetSettings<U> =>
  Object.keys(settings)
    .map((spreadsheetIdString) => {
      const columns = settings[+spreadsheetIdString];

      if (!columns) {
        return { [spreadsheetIdString]: undefined };
      }

      return Object.keys(columns)
        .reduce((itemMap, key) => {
          const columnId = columns[key]?.id;
          if (!columnId) {
            return itemMap;
          }

          const resultItem = relevantItemGetter({
            columnId: columnId.columnId,
            spreadsheetId: columnId.spreadsheetId,
          });

          return mergeDeep(itemMap, createDataWithColumnPerSpreadsheetSettings(resultItem, columnId));
        }, {});
    })
    .reduce((itemFinalMap, partialItemMap) => mergeDeep(itemFinalMap, partialItemMap), {});

// This method converts BubbleItemMap to PanelSettingsBubbleItemMap
export const convertBubbleItemToPanelSettingsMap = (settings: BubbleItemMap): PanelSettingsColumnItemMap => {
  const relevantGetter = (spreadsheetColumnId: SpreadsheetColumnId): PanelColumnSettings | undefined => {
    const bubbleItemData = getColumnItemById(settings, spreadsheetColumnId)?.itemData;

    if (bubbleItemData) {
      const { name: _, ...result } = bubbleItemData;
      return result;
    }

    return undefined;
  };

  return modifyColumnPerSpreadsheetSettingsItems(settings, relevantGetter);
};

export const convertPanelSettingsToBubbleItemMap = (data: {
  categoriesPerColumnLookup: {[columnId: string]: ColumnRole} | null;
  itemMatchupData: SpreadsheetMatchupData | null;
  columnSettingsMap?: PanelSettingsColumnItemMap;
  spreadsheetId: number;
  values?: PerColumn<string | null>;
}): BubbleItemMap => {
  const { categoriesPerColumnLookup, itemMatchupData, values, columnSettingsMap, spreadsheetId } = data;
  const guaranteedSettings = ensureAllColumns(columnSettingsMap ?? newPerSpreadsheetMap(), spreadsheetId, values);

  const relevantGetter = (spreadsheetColumnId: SpreadsheetColumnId): BubbleEditorItem | undefined => {
    const name = itemMatchupData?.columns[spreadsheetColumnId.columnId];
    const bubbleItem = getColumnItemById(guaranteedSettings, spreadsheetColumnId);
    const value = values?.[spreadsheetColumnId.columnId];
    const columnRole = categoriesPerColumnLookup?.[spreadsheetColumnId.columnId] || null;
    // some items should always be hidden, like image column, as images are displayed in the gallery instead
    const isColumnForceHidden =
      columnRole === ColumnRole.Image ||
      (name !== undefined && SERVER_ADDED_COLUMN_NAMES.has(name));

    return bubbleItem && value && !isColumnForceHidden && name ? ({
      id: spreadsheetColumnId,
      itemData: { ...bubbleItem, name },
      itemType: columnRole,
      value,
    }) : undefined;
  };

  return modifyColumnPerSpreadsheetSettingsItems(guaranteedSettings, relevantGetter);
};

// This method returns the same structure it got (BubbleItemMap) with modified bubbleEditorItems
export const modifyBubbleItemMap = (data: {
  bubbleItems: BubbleItemMap;
  itemDataModifier: (item: BubbleEditorItem, spreadsheetColumnId: SpreadsheetColumnId) => BubbleEditorItem;
}): BubbleItemMap => {
  const { bubbleItems, itemDataModifier } = data;

  const itemModifier = (spreadsheetColumnId: SpreadsheetColumnId): ItemColumnSettings | undefined => {
    const bubbleEditorItem = getColumnItemById(bubbleItems, spreadsheetColumnId);

    return bubbleEditorItem ? itemDataModifier(bubbleEditorItem, spreadsheetColumnId).itemData : undefined;
  };

  const relevantGetter = (spreadsheetColumnId: SpreadsheetColumnId): BubbleEditorItem | undefined => {
    const bubbleItem = getColumnItemById(bubbleItems, spreadsheetColumnId);
    const modifiedItem = itemModifier(spreadsheetColumnId);

    return bubbleItem && modifiedItem ? ({
      ...bubbleItem,
      itemData: modifiedItem,
    }) : undefined;
  };

  return modifyColumnPerSpreadsheetSettingsItems(bubbleItems, relevantGetter);
};

const ensureAllColumns = (settings: PanelSettingsColumnItemMap, spreadsheetId: number, values?: PerColumn<string | null>): PanelSettingsColumnItemMap => {
  let result = settings;

  if (values) {
    Object.keys(values).forEach(columnId => {
      if (!result[spreadsheetId]?.[columnId]) {

        // clone current settings before first update
        if (result === settings) {
          result = cloneDeep(settings);
        }

        const perSpreadsheet = result[spreadsheetId] = result[spreadsheetId] ?? {};

        perSpreadsheet[columnId] = {
          id: { columnId, spreadsheetId },
          isActive: true,
          editable: true,
        };
      }
    });
  }

  return result;
};

export const modifyPanelSettingsColumnItemMap = (data: {
  columnSettingItems: PanelSettingsColumnItemMap;
  itemDataModifier: (item: PanelColumnSettings, spreadsheetColumnId: SpreadsheetColumnId) => PanelColumnSettings;
}): PanelSettingsColumnItemMap => {
  const { columnSettingItems, itemDataModifier } = data;

  const relevantGetter = (spreadsheetColumnId: SpreadsheetColumnId): PanelColumnSettings | undefined => {
    const columnSettings = getColumnItemById(columnSettingItems, spreadsheetColumnId);
    return columnSettings ? itemDataModifier(columnSettings, spreadsheetColumnId) : undefined;
  };

  return modifyColumnPerSpreadsheetSettingsItems(columnSettingItems, relevantGetter);
};
