import { type SpreadsheetColumnId } from '../_shared/types/spreadsheetData/spreadsheetColumn';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkResponse,
} from '../spreadsheet/spreadsheet.repository';
import {
  addMissingSpreadsheetData,
  applyColumnToFetchToState,
  cloneSpreadsheetData,
  missingColumnsToSpreadsheetBulkGetters,
  type MissingSpreadsheetData,
} from '../store/spreadsheetData/spreadsheetData.helpers';
import {
  DataType, emptySpreadsheetData, type SpreadsheetDataData,
  Unfiltered,
} from '../store/spreadsheetData/spreadsheetData.state';

export const getSpreadsheetDataForRequestedGroupingColumn = async (clientId: number, mapId: number, requestedColumn: SpreadsheetColumnId): Promise<SpreadsheetDataData> => {
  const missingGroupingSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  // get spreadsheet data for requested new column
  for (const missingDataType of [DataType.NUMBER, DataType.GROUP]) {
    addMissingSpreadsheetData(missingGroupingSpreadsheetData, {
      spreadsheetId: requestedColumn.spreadsheetId,
      columnId: requestedColumn.columnId,
      filterHashOrUnfiltered: Unfiltered,
      dataType: missingDataType,
      spreadsheetDataToFetchExtra: {},
    });
  }

  const spreadsheetDataBulkRequestGetters = missingColumnsToSpreadsheetBulkGetters(
    mapId,
    missingGroupingSpreadsheetData,
    {
      exclude_basic_data: true,
      exclude_row_data: true,
    }
  );

  let spreadsheetDataResponse: SpreadsheetDataBulkResponse[] = [];

  try {
    spreadsheetDataResponse = await getSpreadsheetBulkData(
      clientId,
      {
        params: spreadsheetDataBulkRequestGetters.map(item => ({
          ...item.requestGetter,
          map_id: mapId,
        })),
      }
    )
      .then(response => response.data);
  }
  catch (e) {
    return emptySpreadsheetData;
  }

  const spreadsheetData = cloneSpreadsheetData(emptySpreadsheetData);

  // process spreadsheetData into spreadsheet data State
  for (const spreadsheet of spreadsheetDataResponse) {
    if (spreadsheet.result.columns_to_fetch) {
      if (!spreadsheetData.values[spreadsheet.spreadsheet_id]) {
        spreadsheetData.values[spreadsheet.spreadsheet_id] = {};
      }

      spreadsheetData.values[spreadsheet.spreadsheet_id] = {
        [Unfiltered]: applyColumnToFetchToState(spreadsheet.result.columns_to_fetch, spreadsheetData.values[spreadsheet.spreadsheet_id]?.unfiltered ?? {}),
      };
    }
  }

  return spreadsheetData;
};
