import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type RGBColor } from '~/_shared/components/colorPicker/colorPicker.types';
import { validateValueHasOnlyNumericCharacters } from '~/_shared/utils/form/form.helpers';
import { limitValueToMaxPossibleLength } from '~/_shared/utils/number/number.helpers';
import {
  getDecimalPlaceFromValue,
  getDefaultResolution,
  getLowestRequiredResolution,
  getResolutionFromDecimalPlace,
  type NumericalRange,
  recalculateRanges,
  roundValueToResolution,
} from '~/_shared/utils/range/range.helpers';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { isNullsy } from '~/_shared/utils/typeGuards';
import { type BoundaryTerritory } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  BOUNDARY_MAX_VALUE_ID,
  BOUNDARY_MIN_VALUE_ID,
  BOUNDARY_VALUES_SEPARATOR,
  isBoundaryTerritorySpecialOrCustom,
} from '../boundarySettings.helpers';

type BoundaryTerritoryValues = {
  valueFrom: string | typeof BOUNDARY_MIN_VALUE_ID;
  valueTo: string | typeof BOUNDARY_MAX_VALUE_ID;
};

export const generateTerritoryGradientColor = (
  position: number, count: number, gradientLowColor: string, gradientMediumColor: string, gradientHighColor: string
): string => {
  if (count <= 1) {
    return gradientLowColor;
  }

  const value = position * (100 / (count - 1));
  let step: number;
  let low: string;
  let high: string;

  if (value < 50) {
    step = value / 50;
    low = gradientLowColor;
    high = gradientMediumColor;
  }
  else {
    step = (value - 50) / 50;
    low = gradientMediumColor;
    high = gradientHighColor;
  }

  const lowColor = createColor(low);
  const highColor = createColor(high);

  return '#' + generateColorPart(lowColor.rgb, highColor.rgb, 'r', step) +
    generateColorPart(lowColor.rgb, highColor.rgb, 'g', step) +
    generateColorPart(lowColor.rgb, highColor.rgb, 'b', step);
};

const generateColorPart = (color1: RGBColor, color2: RGBColor, key: 'r' | 'g' | 'b', step: number): string => {
  const diff = color2[key] - color1[key];
  const colorPart = (Math.round(diff * step) + color1[key]).toString(16);

  return colorPart.length === 1 ? '0' + colorPart : colorPart;
};

export const getBoundaryMaxRange = (min: number | typeof BOUNDARY_MIN_VALUE_ID, max: number | typeof BOUNDARY_MAX_VALUE_ID): number => {
  if (min === BOUNDARY_MIN_VALUE_ID || max === BOUNDARY_MAX_VALUE_ID) {
    return 10;
  }

  const rangesCount = max - min + 1;

  if (rangesCount < 10) {
    return rangesCount;
  }
  else {
    return 10;
  }
};

export const handleBoundaryTerritoryValueToChange = (
  index: number,
  valueTo: string,
  dataRows: ReadonlyArray<BoundaryTerritory>,
  min: number | typeof BOUNDARY_MIN_VALUE_ID,
  max: number | typeof BOUNDARY_MAX_VALUE_ID,
  isDecimal: boolean
): ReadonlyArray<BoundaryTerritory> => {
  if (!validateValueHasOnlyNumericCharacters(valueTo, { isDecimal })) {
    return dataRows;
  }

  const currentBoundaryTerritory = dataRows[index];
  if (!currentBoundaryTerritory) {
    return dataRows;
  }

  const currentBoundaryValues = getBoundaryTerritoryRangeValues(currentBoundaryTerritory);

  if (!currentBoundaryValues) {
    return dataRows;
  }

  const parsedValueTo: '' | number = isTextEmpty(valueTo) ? '' : +valueTo;

  if (parsedValueTo === '' || isNaN(parsedValueTo) || valueTo.endsWith('.')) {
    const newRowData = {
      ...currentBoundaryTerritory,
      bucket: getBoundaryTerritoryBucketFromValues({
        valueFrom: currentBoundaryValues.valueFrom,
        valueTo,
      }),
    };

    const newDataRows = [...dataRows];
    newDataRows[index] = newRowData;

    return newDataRows;
  }

  valueTo = limitValueToMaxPossibleLength(valueTo);

  let resolution = isDecimal ? getDefaultResolution(min, max) : 1;

  if (isDecimal) {
    const currentResolutionDecimalPlace = getDecimalPlaceFromValue(resolution);
    const valueToDecimalPlace = getDecimalPlaceFromValue(parsedValueTo);
    const lowestRequiredResolution = getLowestRequiredResolution(parsedValueTo, max, index, dataRows.length);

    if (valueToDecimalPlace > currentResolutionDecimalPlace) {
      resolution = getResolutionFromDecimalPlace(valueToDecimalPlace);
    }
    else {
      // check for all the previous values if the resolution shouldn't be smaller
      // (checking only previous values as the next ones will be recalculated)
      let biggestDecimalPlaceOfPreviousValues = valueToDecimalPlace;
      for (const item of dataRows.slice(0, index)) {
        const itemValues = getBoundaryTerritoryRangeValues(item);
        if (!itemValues || isNaN(+itemValues.valueTo)) {
          continue;
        }
        const valueToDecimalPlace = getDecimalPlaceFromValue(+itemValues.valueTo);
        if (valueToDecimalPlace > biggestDecimalPlaceOfPreviousValues) {
          biggestDecimalPlaceOfPreviousValues = valueToDecimalPlace;
        }
      }

      resolution = getResolutionFromDecimalPlace(biggestDecimalPlaceOfPreviousValues);
    }

    if (lowestRequiredResolution !== null && lowestRequiredResolution < resolution) {
      resolution = lowestRequiredResolution;
    }
  }

  const numericalDataRows = dataRows.filter(row => !isBoundaryTerritorySpecialOrCustom(row));
  const specialDataRows = dataRows.filter(row => isBoundaryTerritorySpecialOrCustom(row));

  const newNumericalDataRows = recalculateDataRowsForNewValueTo(numericalDataRows, index, valueTo, min, max, resolution);

  return newNumericalDataRows.concat(specialDataRows);
};

export const recalculateBoundaryTerritoriesValues = (
  dataRows: ReadonlyArray<BoundaryTerritory>,
  newMin: number,
  newMax: number,
  prevMin: number,
  prevMax: number,
  isDecimalMode: boolean
): BoundaryTerritory[] => {
  // get rows with valid values
  const territoriesWithValues = dataRows.filter(row => !!getBoundaryTerritoryRangeValues(row));

  // get rows with null values (meaning these are special rows)
  const nonRangeTerritories = dataRows.filter(row => !getBoundaryTerritoryRangeValues(row));

  const ranges: NumericalRange[] = territoriesWithValues.map(territory => {
    const values = getBoundaryTerritoryRangeValues(territory);
    if (!values) {
      throw Error('Missing territory range values');
    }

    return {
      from: values.valueFrom === BOUNDARY_MIN_VALUE_ID ? prevMin : +values.valueFrom,
      to: values.valueTo === BOUNDARY_MAX_VALUE_ID ? prevMax : +values.valueTo,
    };
  });

  const newRanges = recalculateRanges(ranges, newMin, newMax, isDecimalMode);

  // recalculate ranges for valid values rows
  const recalculatedBoundariesWithValues: BoundaryTerritory[] = territoriesWithValues.map((row, index) => {
    const boundaryValues = getBoundaryTerritoryRangeValues(row);
    const newRange = newRanges[index];

    if (!boundaryValues || !newRange) {
      throw Error(`Missing territory range values: boundaryValues: '${boundaryValues}, newRange: ${newRange}.`);
    }

    const valueFrom: number = newRange.from;
    const valueTo: number = newRange.to;

    return {
      ...row,
      bucket: getBoundaryTerritoryBucketFromValues({
        valueFrom: valueFrom === newMin ? BOUNDARY_MIN_VALUE_ID : valueFrom.toString(),
        valueTo: valueTo === newMax ? BOUNDARY_MAX_VALUE_ID : valueTo.toString(),
      }),
    };
  });

  return recalculatedBoundariesWithValues.concat(nonRangeTerritories);
};

export const recalculateDataRowsForNewValueTo = (
  dataRows: ReadonlyArray<BoundaryTerritory>,
  index: number,
  newToValue: string,
  rangeMin: number | typeof BOUNDARY_MIN_VALUE_ID,
  rangeMax: number | typeof BOUNDARY_MAX_VALUE_ID,
  resolution: number
): ReadonlyArray<BoundaryTerritory> => {
  if (dataRows.length === 0) {
    return [];
  }

  let minFromData: number | null = null;
  let maxFromData: number | null = null;

  for (const row of dataRows) {
    const boundaryValues = getBoundaryTerritoryRangeValues(row);

    if (boundaryValues && !isNaN(+boundaryValues.valueFrom)) {
      const fromValue = +boundaryValues.valueFrom;
      if (minFromData === null || fromValue < minFromData) {
        minFromData = fromValue;
      }
      if (maxFromData === null || fromValue > maxFromData) {
        maxFromData = fromValue;
      }
    }

    if (boundaryValues && !isNaN(+boundaryValues.valueTo)) {
      const toValue = +boundaryValues.valueTo;
      if (minFromData === null || toValue < minFromData) {
        minFromData = toValue;
      }
      if (maxFromData === null || toValue > maxFromData) {
        maxFromData = toValue;
      }
    }
  }

  if (!isNaN(+newToValue) && maxFromData !== null && +newToValue > maxFromData) {
    maxFromData = +newToValue;
  }

  if (!isNaN(+newToValue) && minFromData !== null && +newToValue < minFromData) {
    minFromData = +newToValue;
  }

  const actualMinValue: number | null = rangeMin !== BOUNDARY_MIN_VALUE_ID ? rangeMin : minFromData;
  const actualMaxValue: number | null = rangeMax !== BOUNDARY_MAX_VALUE_ID ? rangeMax : maxFromData;

  if (actualMinValue === null || actualMaxValue === null) {
    return dataRows;
  }

  const dataRow = dataRows[index];
  const boundaryValues = dataRow && getBoundaryTerritoryRangeValues(dataRow);

  if (!boundaryValues) {
    return dataRows;
  }

  const valueFrom = boundaryValues.valueFrom === BOUNDARY_MIN_VALUE_ID ? BOUNDARY_MIN_VALUE_ID : +boundaryValues.valueFrom;

  const isValueValid = validateBoundaryValue(rangeMin, rangeMax, index, dataRows.length, valueFrom, +newToValue, resolution);

  const newRowData = {
    ...dataRow,
    bucket: getBoundaryTerritoryBucketFromValues({
      valueFrom: boundaryValues.valueFrom,
      valueTo: newToValue,
    }),
  };

  const newDataRows: BoundaryTerritory[] = [...dataRows];
  newDataRows[index] = newRowData;

  if (isValueValid) {
    const distanceValue = (actualMaxValue - +newToValue - (dataRows.length - 1 - index) * resolution) / (dataRows.length - 1 - index);
    const distanceRounded = roundValueToResolution(distanceValue, resolution);

    const distance = Math.abs(distanceRounded);

    // recalculate previous rows valueFrom to have the same resolution as new value
    for (let i = 1; i <= index; i++) {
      const currentDataRow = newDataRows[i];
      if (!currentDataRow) {
        continue;
      }
      const boundaryValues = getBoundaryTerritoryRangeValues(currentDataRow);
      const previousBoundaryValues = getBoundaryTerritoryRangeValues(newDataRows[i - 1]);

      if (!boundaryValues || !previousBoundaryValues) {
        continue;
      }

      const fromValue = previousBoundaryValues.valueTo !== BOUNDARY_MAX_VALUE_ID ?
        roundValueToResolution(+previousBoundaryValues.valueTo, resolution) : BOUNDARY_MAX_VALUE_ID;

      const newRowData = {
        ...currentDataRow,
        bucket: getBoundaryTerritoryBucketFromValues({
          valueFrom: fromValue.toString(),
          valueTo: boundaryValues.valueTo.toString(),
        }),
      };

      newDataRows[i] = newRowData;
    }

    // recalculate next values
    for (let i = index + 1; i < dataRows.length; i++) {
      const currentDataRow = newDataRows[i];
      if (!currentDataRow) {
        continue;
      }
      const boundaryValues = getBoundaryTerritoryRangeValues(currentDataRow);
      const previousBoundaryValues = getBoundaryTerritoryRangeValues(newDataRows[i - 1]);
      const nextBoundaryValues = newDataRows[i + 1] ? getBoundaryTerritoryRangeValues(newDataRows[i + 1]) : null;

      if (!boundaryValues || !previousBoundaryValues || previousBoundaryValues.valueTo === BOUNDARY_MAX_VALUE_ID) {
        continue;
      }

      const fromValue = roundValueToResolution(+previousBoundaryValues.valueTo, resolution);

      const newRowData = {
        ...currentDataRow,
        bucket: getBoundaryTerritoryBucketFromValues({
          valueFrom: fromValue.toString(),
          valueTo: !nextBoundaryValues ? BOUNDARY_MAX_VALUE_ID : roundValueToResolution(fromValue + distance, resolution).toString(),
        }),
      };

      newDataRows[i] = newRowData;
    }
  }

  return newDataRows;
};

export const getBoundaryTerritoryRangeValues = (territory: Maybe<BoundaryTerritory>): BoundaryTerritoryValues | null => {
  if (!territory || territory.custom) {
    return null;
  }

  const [valueFrom, valueTo] = territory.bucket.split(BOUNDARY_VALUES_SEPARATOR);
  if (isNullsy(valueFrom) || isNullsy(valueTo) || isBoundaryTerritorySpecialOrCustom(territory)) {
    return null;
  }

  return {
    valueFrom,
    valueTo,
  };
};

export const getBoundaryTerritoryBucketFromValues = (values: BoundaryTerritoryValues): string => {
  return `${values.valueFrom}${BOUNDARY_VALUES_SEPARATOR}${values.valueTo}`;
};

export const validateBoundarySettingsNumericValue = (
  territory: BoundaryTerritory,
  previousTerritory: BoundaryTerritory | null,
  nextTerritory: BoundaryTerritory | null,
  min: number | typeof BOUNDARY_MIN_VALUE_ID,
  max: number | typeof BOUNDARY_MAX_VALUE_ID,
): boolean => {
  if (isBoundaryTerritorySpecialOrCustom(territory)) {
    return true;
  }

  const territoryValues = getBoundaryTerritoryRangeValues(territory);

  if (!territoryValues || territoryValues.valueTo === BOUNDARY_MAX_VALUE_ID) {
    return true;
  }

  // make sure there is any value and there are no dots at the end of the valueTo
  if (territoryValues.valueTo === '' || /\.+$/.test(territoryValues.valueTo)) {
    return false;
  }

  const parsedValueTo = +territoryValues.valueTo;
  const parsedValueFrom: typeof BOUNDARY_MIN_VALUE_ID | number =
    territoryValues.valueFrom === BOUNDARY_MIN_VALUE_ID ? BOUNDARY_MIN_VALUE_ID : +territoryValues.valueFrom;

  if (isNaN(parsedValueTo)) {
    return false;
  }

  if (min !== BOUNDARY_MIN_VALUE_ID && parsedValueTo < min) {
    return false;
  }

  if (max !== BOUNDARY_MAX_VALUE_ID && parsedValueTo > max) {
    return false;
  }

  if (parsedValueFrom !== BOUNDARY_MIN_VALUE_ID && parsedValueTo < parsedValueFrom) {
    return false;
  }

  if (parsedValueFrom === BOUNDARY_MIN_VALUE_ID && min !== BOUNDARY_MIN_VALUE_ID && parsedValueTo < min) {
    return false;
  }

  if (previousTerritory) {
    if (isBoundaryTerritorySpecialOrCustom(previousTerritory) || territoryValues.valueFrom === BOUNDARY_MIN_VALUE_ID) {
      // all is good in this case
    }
    else {
      const previousTerritoryValues = getBoundaryTerritoryRangeValues(previousTerritory);

      if (previousTerritoryValues && isNaN(+previousTerritoryValues.valueTo)) {
        return true;
      }

      if (previousTerritoryValues && +territoryValues.valueFrom < +previousTerritoryValues.valueTo) {
        return false;
      }

      if (previousTerritoryValues && +territoryValues.valueFrom <= +previousTerritoryValues.valueFrom) {
        return false;
      }
    }
  }

  if (nextTerritory) {
    if (isBoundaryTerritorySpecialOrCustom(nextTerritory) || territoryValues.valueTo === BOUNDARY_MAX_VALUE_ID) {
      // all is good in this case
    }
    else {
      const nextTerritoryValues = getBoundaryTerritoryRangeValues(nextTerritory);

      if (nextTerritoryValues && +territoryValues.valueTo > +nextTerritoryValues.valueFrom) {
        return false;
      }

      if (nextTerritoryValues && +territoryValues.valueTo >= +nextTerritoryValues.valueTo) {
        return false;
      }
    }
  }

  return true;
};

const validateBoundaryValue = (
  min: number | typeof BOUNDARY_MIN_VALUE_ID,
  max: number | typeof BOUNDARY_MAX_VALUE_ID,
  itemIndex: number,
  itemsCount: number,
  valueFrom: number | typeof BOUNDARY_MIN_VALUE_ID,
  valueTo: number,
  resolution: number
): boolean => {
  if (min === BOUNDARY_MIN_VALUE_ID && max === BOUNDARY_MAX_VALUE_ID) {
    return true;
  }

  if (valueFrom !== BOUNDARY_MIN_VALUE_ID && valueTo < valueFrom) {
    return false;
  }

  if (min === BOUNDARY_MIN_VALUE_ID && max !== BOUNDARY_MAX_VALUE_ID) {
    // check only max value
    const maxValue = max - (itemsCount - itemIndex - 2) * resolution;
    return valueTo <= maxValue;
  }

  if (max === BOUNDARY_MAX_VALUE_ID && min !== BOUNDARY_MIN_VALUE_ID) {
    // check only min value
    return valueTo >= min;
  }

  if (min !== BOUNDARY_MIN_VALUE_ID && max !== BOUNDARY_MAX_VALUE_ID) {
    const maxValue = max - (itemsCount - itemIndex - 2) * resolution;
    return valueTo >= min && valueTo <= maxValue;
  }

  return false; // unreachable
};
