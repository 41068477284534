import {
  type PropsWithChildren, useContext,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { TableContext } from '~/map/mapMigration/table/table.context';
import { getNoResultsRowStyles } from '~/map/mapMigration/table/table.styles';

type TableNoResultsRowProps = PropsWithChildren;

export const TableNoResultsRowComponent = ({ children }: TableNoResultsRowProps) => {
  const theme = useTheme();
  const { columnCount } = useContext(TableContext);

  return (
    <div css={getNoResultsRowStyles({ theme, columnCount })}>
      {children}
    </div>
  );
};
