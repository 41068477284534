import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useAccountSettingsModalPhase } from '~/_shared/components/accountSettings/modalDialogs/hooks/useAccountSettingsModalPhase.hook';
import {
  is2FactorCodeRequired, useUpdateUserData,
} from '~/_shared/components/accountSettings/useUpdateUserData';
import { PasswordErrorCode } from '~/_shared/types/responseErrors/_shared/passwordErrorCodes.types';
import {
  type ErrorsTranslated, getFilteredResponseErrorCodes, translateErrorCode, translateErrorCodesInError,
} from '~/_shared/types/responseErrors/responseErrorCodes.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { UserFieldName } from '~/store/userData/repository/userData.types';

export const useUpdateAccountInformation = () => {
  const [t] = useTranslation();
  const { isLoading, error: userDataError, changeUserData } = useUpdateUserData();
  const { phase, setPhase } = useAccountSettingsModalPhase();
  const [generalError, setGeneralError] = useState<string | null| undefined>(null);

  const enhancedChangeUserData: typeof changeUserData = useCallback(async (...args) => {
    const result = await changeUserData(...args);

    if (!result.error) {
      setPhase('Success');
    }

    const mfaCode = result.error?.errorCode;
    if (is2FactorCodeRequired(mfaCode) || (
      (phase === 'Success') && mfaCode
    )) {
      setPhase('MFA');
      setGeneralError(null);
    }

    return result;
  }, [changeUserData, phase, setPhase]);

  const enhancedSetPhase = (newPhase: typeof phase) => {
    if ((newPhase === 'Form') && (phase === 'MFA')) {
      setGeneralError(null);
    }

    setPhase(newPhase);
  };

  const translatedErrorsPerField: ErrorsTranslated<UserFieldName> | null = useMemo(() => {
    if (userDataError?.errors) {
      return translateErrorCodesInError(userDataError.errors, t);
    }
    else if (userDataError?.errorCode === PasswordErrorCode.OLD_PASSWORD_INVALID) {
      return { [UserFieldName.old_password]: [t(userDataError.errorCode)] };
    }
    else {
      return null;
    }
  }, [t, userDataError]);

  useEffect(() => {
    if (userDataError) {
      const displayErrorCode = getFilteredResponseErrorCodes(userDataError);
      setGeneralError(displayErrorCode ? translateErrorCode(displayErrorCode, t) : null);
    }
  }, [t, userDataError]);

  return {
    fieldErrors: translatedErrorsPerField,
    phase,
    setPhase: enhancedSetPhase,
    generalError,
    isLoading,
    changeUserData: enhancedChangeUserData,
  };
};
