import {
  useCallback, useMemo, useState,
} from 'react';
import { mergeDeep } from '~/_shared/utils/object/deepMerge';

export enum UniqueIdentifierType {
  IdColumn = 'IdColumn',
  AddressColumns = 'AddressColumns',
}

export type UniqueIdentifierData = {
  addressColumnIndexes: (number | null)[];
  idColumnIndexes: (number | null)[];
  uIdType: UniqueIdentifierType | null;

  removeAddressIdentifierColumnByIndex: (index: number) => void;
  setAddressColumnIndexes: (newValue: (number | null)[]) => void;
  setIdColumnIndexes: (newValue: (number | null)[]) => void;
  setUIdType: (uIdType: UniqueIdentifierType | null) => void;
};

const getUidTypeFromIndexes = (indexes?: Readonly<number[]>): UniqueIdentifierType | null => {
  if (indexes?.length === 1) {
    return UniqueIdentifierType.IdColumn;
  }
  if (indexes && indexes.length > 1) {
    return UniqueIdentifierType.AddressColumns;
  }
  return null;
};

export const useUniqueIdentifierSelectorManager = (initialIndexes?: Readonly<number[]>) => {
  const initialUid = getUidTypeFromIndexes(initialIndexes);

  const [uIdType, setUIdType] = useState<UniqueIdentifierType | null>(initialUid);
  const [idColumnIndexes, setIdColumnIndexes] = useState<(number | null)[]>(
    initialIndexes && initialUid === UniqueIdentifierType.IdColumn ? mergeDeep({}, initialIndexes) : [null]
  );
  const [addressColumnIndexes, setAddressColumnIndexes] = useState<(number | null)[]>(
    initialIndexes && initialUid === UniqueIdentifierType.AddressColumns ? mergeDeep({}, initialIndexes) : [null]
  );

  const removeAddressIdentifierColumnByIndex = useCallback((index: number) => {
    const ids = addressColumnIndexes.filter((_, i) => i !== index);
    setAddressColumnIndexes(ids);
  }, [addressColumnIndexes]);

  return useMemo((): UniqueIdentifierData => ({
    uIdType,
    setUIdType,
    idColumnIndexes,
    setIdColumnIndexes,
    addressColumnIndexes,
    setAddressColumnIndexes,
    removeAddressIdentifierColumnByIndex,
  }), [addressColumnIndexes, idColumnIndexes, removeAddressIdentifierColumnByIndex, uIdType]);
};
