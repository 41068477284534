import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { type DriveTimeErrorResponseMessage } from '../../../proximity/proximity.repository';
import { PROXIMITY_DRIVE_TIME_SET_PROXIMITY_PATHS } from './proximityDriveTimePaths.actionTypes';

export const proximityDriveTimeSetProximityPaths = (paths: { proximityPathId: string; paths: MultiPolygon | null; message: DriveTimeErrorResponseMessage | null }[]) => ({
  type: PROXIMITY_DRIVE_TIME_SET_PROXIMITY_PATHS,
  payload: {
    paths,
  },
}) as const;
