import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  TooltipBehavior, TooltipComponent, type TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '../../themes/theme.hooks';
import { type Theme } from '../../themes/theme.model';

type ContextMenuItemSize = 's' | 'm';

type ContextMenuDefaultItemProps = Readonly<{
  icon?: IconProp;
  children: ReactNode;
  isDisabled?: boolean;
  isDestructive?: boolean;
  onClick?: () => void;
  iconStyle?: SerializedStyles;
  itemStyle?: SerializedStyles;
  size?: ContextMenuItemSize;
  tooltipLabel?: string;
  tooltipPlacement?: TooltipPlacement;
}>;

const createColor = (theme: Theme, isDisabled: boolean | undefined, isDestructive: boolean | undefined) => {
  if (isDisabled) {
    return theme.textColors.disabled;
  }
  if (isDestructive) {
    return theme.textColors.danger;
  }

  return theme.textColors.primary;
};

const getItemPaddingForSize = (size: ContextMenuItemSize) => {
  switch (size) {
    case 's':
      return '10px 8px';
    default:
      return '16px 8px';
  }
};

const getItemFontSizeForSize = (size: ContextMenuItemSize) => {
  switch (size) {
    case 's':
      return '14px';
    default:
      return '16px';
  }
};

const itemStyle = (
  theme: Theme,
  isDisabled: boolean | undefined,
  isDestructive: boolean | undefined,
  size: ContextMenuItemSize,
  additionalStyle?: SerializedStyles,
) => css({
  cursor: isDisabled ? 'not-allowed' : 'pointer',
  color: createColor(theme, isDisabled, isDestructive),
  fontSize: getItemFontSizeForSize(size),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  padding: getItemPaddingForSize(size),

  ':hover': {
    backgroundColor: theme.dropdownColors.optionBackgroundHover,
  },
}, additionalStyle);

const iconStyle = (theme: Theme, isDestructive: boolean | undefined, additionalStyle?: SerializedStyles) =>
  css({
    color: isDestructive ? theme.iconColors.danger : theme.iconColors.secondary,
    paddingRight: 8,
  }, additionalStyle);

export const ContextMenuDefaultItem: FC<ContextMenuDefaultItemProps> = props => {
  const theme = useTheme();

  return (
    <TooltipComponent
      tooltipContent={props.tooltipLabel}
      placement={props.tooltipPlacement}
      behavior={TooltipBehavior.ShowOnHover}
    >
      <div
        css={itemStyle(theme, props.isDisabled, props.isDestructive, props.size ?? 'm', props.itemStyle)}
        onClick={props.onClick}
      >
        {props.icon && (
          <FontAwesomeIcon
            css={iconStyle(theme, props.isDestructive, props.iconStyle)}
            icon={props.icon}
          />
        )}
        {props.children}
      </div>
    </TooltipComponent>
  );
};
