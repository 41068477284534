import { css } from '@emotion/react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS, type ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import {
  type FC, useMemo, useState,
} from 'react';
import {
  Bar, Doughnut, Pie, PolarArea, Radar,
} from 'react-chartjs-2';
import {
  DropDownItemSize,
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import { rgbColorToString } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { ModalComponent } from '../_shared/components/modal/modal.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { useTranslation } from '../_shared/utils/hooks';
import { type PieChartItem } from '../_shared/utils/maptive/pieCharts/pieCharts.types';
import { type ModalProps } from '../modal/modalType.enum';
import {
  barChartOptionsStyle,
  chartOptionsStyle,
  horizontalBarChartOptionsStyle,
  spiderWebChartOptionsStyle,
} from './chartsVisualizer.helpers';
import {
  type ChartsInfo, MaptiveChartType,
} from './chartsVisualizer.types';

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, Filler, LineElement, CategoryScale,
  LinearScale,
  BarElement,
  Title,);

const modalContentStyle = css({
  padding: '0px',
  width: 547,
});

const dropdownStyle = css({
  marginTop: 6,
  width: 236,
});

const contentWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 535,
  padding: 16,
});

const dropdownLabelStyle = css({
  fontSize: 16,
  textTransform: 'capitalize',
});

const chartSectionStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 450,
  width: '100%',
});

const chartHolderStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative',
  width: 440,
});

type ChartsModalProps = ModalProps & Readonly<{
  chartTitle: string;
  data: {
    chartItems: ReadonlyArray<PieChartItem> | null;
    secondaryChartItems: ReadonlyArray<PieChartItem> | null;
  } | null;
  modalCaption: string;
}>;

export const ChartsVisualizerComponent: FC<ChartsModalProps> = props => {
  const [selectedChartType, setSelectedChartType] = useState<MaptiveChartType>(MaptiveChartType.DoughnutChart);
  const [hierarchy, setHierarchy] = useState(0);
  const [t] = useTranslation();
  const theme = useTheme();

  const chartTypes: DropdownOption<MaptiveChartType>[] = useMemo(() => Object.keys(MaptiveChartType)
    .sort((s1, s2) => s1 > s2 ? 1 : -1)
    .map((chartName: MaptiveChartType) => ({
      name: t(chartName),
      value: chartName,
    })),
  [t]);

  const chartItems = hierarchy === 0 ? props.data?.chartItems : props.data?.secondaryChartItems;
  const data: ChartsInfo | null = useMemo(() => {
    if (!chartItems) {
      return null;
    }
    const borderColors: string[] = [];
    const colors: string[] = [];
    const labels: string[] = [];
    const values: number[] = [];

    chartItems.forEach(item => {
      borderColors.push(rgbColorToString({ ...item.rgbColor, a: 1 }));
      colors.push(rgbColorToString({ ...item.rgbColor, a: item.rgbColor.a === 1 ? 0.8 : item.rgbColor.a }));
      labels.push(item.title);
      values.push(item.count);
    });

    return {
      datasets: [{
        backgroundColor: colors,
        borderColor: borderColors,
        borderWidth: 1,
        data: values,
        label: props.chartTitle,
      }],
      labels,
    };
  }, [chartItems, props.chartTitle]);

  const barChartOptionsS = useMemo(() => barChartOptionsStyle({ theme }), [theme]);
  const baseChartOptions = useMemo(() => chartOptionsStyle({ theme }), [theme]);
  const horizontalChartOptions = useMemo(() => horizontalBarChartOptionsStyle({ theme }), [theme]);
  const spiderWebChartOptions = useMemo(() => spiderWebChartOptionsStyle({ theme }), [theme]);

  return (
    <ModalComponent
      caption={props.modalCaption}
      contentMaxHeight="580px"
      contentStyle={modalContentStyle}
      isOpen={props.isOpen}
      maxWidth={547}
      onClose={props.onClose}

    >
      <div css={contentWrapperStyle}>
        <Inline gap={20}>
          <div>
            <span css={dropdownLabelStyle}>
              {t('Select a chart type')}
            </span>
            <RegularDropdownComponent
              css={dropdownStyle}
              inputSize={InputSize.Medium}
              itemSize={DropDownItemSize.Large}
              onChange={setSelectedChartType}
              options={chartTypes}
              value={selectedChartType}
            />
          </div>
          {!!props.data?.secondaryChartItems?.length && (
            <div>
              <span css={dropdownLabelStyle}>
                {t('Select Source')}
              </span>
              <RegularDropdownComponent
                css={dropdownStyle}
                inputSize={InputSize.Medium}
                itemSize={DropDownItemSize.Large}
                onChange={setHierarchy}
                options={[{ name: t('Primary Group'), value: 0 }, { name: t('Secondary Group'), value: 1 }]}
                value={hierarchy}
              />
            </div>
          )}
        </Inline>

        {!data ? t('This pie chart has no data, try clicking another one') : (
          <div css={chartSectionStyle}>
            <div css={chartHolderStyle}>
              {selectedChartType === MaptiveChartType.DoughnutChart && (
                <Doughnut
                  data={data}
                  options={baseChartOptions as ChartOptions<'doughnut'>}
                />
              )}
              {selectedChartType === MaptiveChartType.PieChart && (
                <Pie
                  data={data}
                  options={baseChartOptions as ChartOptions<'pie'>}
                />
              )}
              {selectedChartType === MaptiveChartType.PolarAreaChart && (
                <PolarArea
                  data={data}
                  options={spiderWebChartOptions as ChartOptions<'polarArea'>}
                />
              )}
              {selectedChartType === MaptiveChartType.RadarChart && (
                <Radar
                  data={data}
                  options={spiderWebChartOptions as ChartOptions<'radar'>}
                />
              )}
              {selectedChartType === MaptiveChartType.VerticalBarChart && (
                <Bar
                  data={data}
                  options={barChartOptionsS as ChartOptions<'bar'>}
                />
              )}
              {selectedChartType === MaptiveChartType.HorizontalBarChart && (
                <Bar
                  data={data}
                  options={horizontalChartOptions as ChartOptions<'bar'>}
                />
              )}
            </div>
          </div>
        )}

      </div>
    </ModalComponent>
  );
};
