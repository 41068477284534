import { css } from '@emotion/react';
import {
  faCheck, faSync,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../../_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { BoundarySettingsRestoreButtonComponent } from '../restoreButton/boundarySettingsRestoreButton.component';

type BoundarySettingsModalProps = Readonly<{
  caption: string;
  isLoading: boolean;
  isOpen: boolean;
  showRecalculateRangesButton: boolean;
  submitTooltipContent: ReactNode;
  children?: React.ReactNode;
  isSubmitDisabled: boolean;
  onClose: () => void;
  onRecalculateRangesClick?: () => void;
  onSubmitClick: () => void;
}>;

const modalContentStyle = css({
  padding: 0,
});

export const BoundarySettingsModalComponent: FC<BoundarySettingsModalProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={props.caption}
      contentStyle={modalContentStyle}
      confirmButton={(
        <TooltipDeprComponent tooltipContent={props.submitTooltipContent}>
          <ButtonComponent
            prefixIcon={faCheck}
            text={t('Save')}
            onClick={props.onSubmitClick}
            isDisabled={props.isSubmitDisabled}
          />
        </TooltipDeprComponent>
      )}
      leftFooterContent={(
        <>
          {props.showRecalculateRangesButton && !!props.onRecalculateRangesClick && (
            <BoundarySettingsRestoreButtonComponent
              prefixIcon={faSync}
              text={t('Recalculate Equal Ranges')}
              onClick={props.onRecalculateRangesClick}
            />
          )}

          {props.isLoading && (
            <OverlayLoaderComponent />
          )}
        </>
      )}
    >
      {props.children}
    </ModalComponent>
  );
};
