import { AccountSettingsContainer } from '../../_shared/components/accountSettings/accountSettings.container';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../_shared/components/scrollbar/scrollbar.component';
import { ToolboxContainerComponent } from '../../_shared/components/toolboxContainer/toolboxContainer.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { type SidebarAppProps } from '../sidebarAppComponentProps.type';

export const AccountSettingsAppComponent: React.FC<SidebarAppProps> = props => {
  const [t] = useTranslation();

  return (
    <ToolboxContainerComponent
      title={t('Account Settings')}
      onClose={props.onClose}
    >
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
      >
        <AccountSettingsContainer />
      </ScrollBarComponent>
    </ToolboxContainerComponent>
  );
};
