import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useTranslation } from '~/_shared/utils/hooks';

export type PrintFileButtonProps = Readonly<{
  onPrintClick: () => void;
}>;

export const PrintFileButtonComponent: FC<PrintFileButtonProps> = (props) => {
  const [t] = useTranslation();

  return (
    <RoundButtonWithTextComponent
      buttonStyle={RoundButtonStyle.Primary}
      icon={faPrint}
      onClick={props.onPrintClick}
      text={t('Print')}
    />
  );
};

PrintFileButtonComponent.displayName = 'PrintFileButtonComponent';
