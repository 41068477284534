import { type ThemeType } from '~/_shared/themes/themeType';
import { THEME_CHANGE_TYPE } from './theme.actionTypes';

export const changeThemeType = (type: ThemeType, { persist }: {
  persist: boolean;
} = { persist: true }) => ({
  type: THEME_CHANGE_TYPE,
  payload: {
    type,
    persist,
  },
}) as const;
