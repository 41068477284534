import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  addNewRow, type ColumnNamePerColumnId, type ColumnNamePerMapId, createColumnIdFromNumber, createKeyFromRealSpreadsheetId,
  createRecordWithDefaultForMaps, createUsedColumnKeyForMap, extractRealSpreadsheetIdFromKey,
  getMapFromRealSpreadsheetId, getRowUsedOtherData, getSpreadsheetIdFromMapId, getTargetColumnForColumn,
  hasRowOnlyOneSourceMap, type IntersectsMapItem, removeRow, type Row, type RowWithExtraData, selectColumn,
} from 'resources/js/src/map/layered/createLayeredMapModal/MatchupColumnsSection/matchupColumnsSection.helpers';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type MapInfo } from '~/_shared/types/map';
import {
  type PerSpreadsheetMatchupData, type SpreadsheetMatchupColumns,
} from '~/_shared/types/spreadsheetData/matchupData';
import {
  filterAddedSpreadsheetColumns, SERVER_ADDED_COLUMN_NAMES,
} from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { notUndefined } from '~/_shared/utils/typeGuards';
import {
  type ColumnIdPerSpreadsheet, type MapIntersect,
} from '~/map/map.repository';

// Fills the passed sourceColumnsPerMap under the mapId key with column names extracted from map intersects.
const populateMapIdsWithColumnNames = (props: {
  columnData?: PerSpreadsheetMatchupData;
  intersect: MapIntersect;
  maps: IntersectsMapItem [];
  sourceColumnsPerMap: ColumnNamePerMapId;
  spreadsheetKey: string;
}) => {
  const realSpreadsheetId = extractRealSpreadsheetIdFromKey(props.spreadsheetKey);
  const map = getMapFromRealSpreadsheetId(props.maps, realSpreadsheetId);

  if (map) {
    const columnId = props.intersect.intersects[props.spreadsheetKey];
    if (columnId) {
      const spreadsheetId = getSpreadsheetIdFromMapId(props.maps, map.id);
      if (spreadsheetId) {
        props.sourceColumnsPerMap[map.id] = props.columnData?.[spreadsheetId]?.columns?.[columnId] || null;
      }
    }
  }
};

type MatchupColumnDropdownOption = DropdownOption<string | null>;

type UseMatchupColumnsSectionProps = {
  maps: IntersectsMapItem[];
  onSubmit: (data: MapIntersect[]) => void;
  isLoading: boolean;
  intersects: MapIntersect[];
  columnData?: PerSpreadsheetMatchupData;

  getMapIdFromPrimarySpreadsheetId: (mapList: IntersectsMapItem[], spreadsheetId: number) => number | null;
  getMapFromRealSpreadsheetId: (mapList: IntersectsMapItem[], spreadsheetId: number) => IntersectsMapItem | null;
  getSpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;
  getRealSpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;
  getPrimarySpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;

  layeringMapInfo?: MapInfo | null;
};

export const useMatchupColumnsSection = (props: UseMatchupColumnsSectionProps) => {
  const {
    maps,
    onSubmit,
    isLoading,
    intersects,
    columnData,
    getMapIdFromPrimarySpreadsheetId,
    getMapFromRealSpreadsheetId,
    getSpreadsheetIdFromMapId,
    getPrimarySpreadsheetIdFromMapId,
    getRealSpreadsheetIdFromMapId,
    layeringMapInfo,
  } = props;

  const [t] = useTranslation();
  const [rows, setRows] = useState<Row[]>([]);

  const isEditing = Boolean(layeringMapInfo?.layering);
  const isConnected = Boolean(layeringMapInfo?.layering?.connected);
  const sourceColumns = layeringMapInfo?.layering?.sourceColumns;

  const baseAvailableDropdownOptions = useMemo(() => ([{ name: t('None'), value: null }]), [t]);

  const createNullsPerMap = useCallback(() =>
    createRecordWithDefaultForMaps(maps, null),
  [maps]);

  const addToIntersectsAndUsedKeys = useCallback((props: {
    columns?: SpreadsheetMatchupColumns;
    intersectValue?: string | null;
    intersects: Record<string, string | null>;
    mapId: number;
    realSpreadsheetId?: number;
    usedKeys: Set<string>;
  }) => {
    const { intersects, intersectValue, mapId, usedKeys } = props;
    const realSpreadsheetId = props.realSpreadsheetId ?? getRealSpreadsheetIdFromMapId(maps, mapId);
    const primarySpreadsheetId = props.realSpreadsheetId ?? getPrimarySpreadsheetIdFromMapId(maps, mapId);
    if (realSpreadsheetId && primarySpreadsheetId) {
      const realSpreadsheetKey: string = createKeyFromRealSpreadsheetId(realSpreadsheetId);
      const columns = props.columns || columnData?.[primarySpreadsheetId]?.columns || {};
      const targetColumn = getTargetColumnForColumn({
        column: intersectValue || null, columns, mapId, usedKeys,
      });

      if (targetColumn) {
        usedKeys.add(createUsedColumnKeyForMap(mapId, targetColumn));
      }

      if (realSpreadsheetKey) {
        intersects[realSpreadsheetKey] = targetColumn;
      }
    }
  }, [columnData, getPrimarySpreadsheetIdFromMapId, getRealSpreadsheetIdFromMapId, maps]);

  const getAvailableTargetColumnNamesWithoutNone = useCallback((columnOptions: ColumnNamePerMapId | SpreadsheetMatchupColumns) => {
    const options: MatchupColumnDropdownOption[] = [];
    const valueSet: Set<string> = new Set<string>();

    Object.values(columnOptions).forEach(value => {
      if (!value) {
        return;
      }
      const isServerValue = SERVER_ADDED_COLUMN_NAMES.has(value);
      if (!valueSet.has(value) && !isServerValue) {
        options.push({
          value,
          name: value,
        });
        valueSet.add(value);
      }
    });

    options.sort((optionA, optionB) => {
      const lowerCaseNameA = optionA.name.toLowerCase();
      const lowerCaseNameB = optionB.name.toLowerCase();

      return lowerCaseNameA < lowerCaseNameB ? -1 : lowerCaseNameB < lowerCaseNameA ? 1 : 0;
    });

    return options;
  },
  [],
  );

  const getAvailableTargetColumnNames = useCallback(
    (columnOptions: ColumnNamePerMapId | SpreadsheetMatchupColumns) => {
      return [...baseAvailableDropdownOptions, ...getAvailableTargetColumnNamesWithoutNone(columnOptions)];
    },
    [baseAvailableDropdownOptions, getAvailableTargetColumnNamesWithoutNone],
  );

  const sourceColumnsPerMap: Record<number, MatchupColumnDropdownOption[]> = useMemo(() => {
    const options: Record<number, MatchupColumnDropdownOption[]> = {};
    for (const key in columnData) {
      if (columnData?.hasOwnProperty(key)) {
        const spreadsheetId: number = Number(key);
        const mapId = getMapIdFromPrimarySpreadsheetId(maps, spreadsheetId);
        const columns = columnData?.[spreadsheetId]?.columns || {};
        if (mapId) {
          options[mapId] = getAvailableTargetColumnNames(columns);
        }
      }
    }
    if (isEditing) {
      for (const map of maps) {
        if (map.isCurrentMap && map.realSpreadsheetId) {
          const spreadsheetKey = createKeyFromRealSpreadsheetId(map.realSpreadsheetId);
          const columns = sourceColumns?.[spreadsheetKey] || {};
          options[map.id] = getAvailableTargetColumnNames(columns);
        }
      }
    }
    return options;
  }, [isEditing, sourceColumns, columnData, getMapIdFromPrimarySpreadsheetId, maps, getAvailableTargetColumnNames]);

  const onSourceColumnChange = useCallback((column: string | null, mapId: number, index: number) => {
    setRows(selectColumn(rows, column, mapId, index, sourceColumnsPerMap, maps));
  }, [rows, sourceColumnsPerMap, maps]);

  const onTargetColumnNameChange = useCallback((value: string | null, index: number) => {
    const row = rows[index];
    if (!row) {
      return;
    }

    const newValue = value?.length ? value : null;

    let newOptions: Row[] = [...rows.slice(0, index), {
      ...row,
      targetColumnName: newValue,
    }, ...rows.slice(index + 1)];
    if (newValue) {
      const prevIndex = getRowUsedOtherData(rows, newValue, index);
      if (prevIndex !== -1) {
        const newOption = newOptions[prevIndex];
        if (!newOption) {
          return;
        }

        newOptions = [...newOptions.slice(0, prevIndex), {
          ...newOption,
          targetColumnName: null,
        }, ...newOptions.slice(prevIndex + 1)];
      }
    }
    setRows(newOptions);
  }, [rows]);

  const onRemoveRow = useCallback((index: number) => {
    setRows(rows => removeRow(rows, index, sourceColumnsPerMap, maps));
  }, [sourceColumnsPerMap, maps]);

  const onAddRow = useCallback(
    () => {
      if (isLoading) {
        return;
      }
      setRows(rows => addNewRow(rows, maps));
    },
    [isLoading, maps],
  );

  const handleGenerateIntersectsAndSubmit = useCallback(() => {
    const usedKeys = new Set<string>();
    if (layeringMapInfo?.isLayered && !layeringMapInfo.layering?.connected) {
      const layeredMapColumns = layeringMapInfo.layering?.columns;
      const realSpreadsheetId = layeringMapInfo.spreadsheets[0]?.realSpreadsheets[0]?.realSpreadSheetId ?? 0;
      const layeredMapSpreadsheet = createKeyFromRealSpreadsheetId(realSpreadsheetId);
      const data: MapIntersect[] = rows
        .filter(combinedOption => combinedOption.targetColumnName)
        .map((combinedOption, index) => {
          const layeredMapColumnId = createColumnIdFromNumber(index);
          const newIntersects: ColumnNamePerColumnId = {
            [layeredMapSpreadsheet]: layeredMapColumns?.hasOwnProperty(layeredMapColumnId)
              ? layeredMapColumnId
              : null,
          };
          maps.forEach(map => {
            const intersectValue = combinedOption.selectedSourceColumnPerMap[map.id];
            if (notUndefined(intersectValue) && !map.isCurrentMap) {
              addToIntersectsAndUsedKeys({
                mapId: map.id, intersectValue, intersects: newIntersects, usedKeys,
              });
            }
          });
          return {
            //the targetColumnName is a 100% there, as the values without it got filtered out
            name: combinedOption.targetColumnName || '',
            intersects: newIntersects,
          };
        });
      onSubmit(data);
    }
    else {
      const data: MapIntersect[] = rows
        .filter(combinedOption => combinedOption.targetColumnName)
        .map(combinedOption => {
          const intersects = combinedOption.selectedSourceColumnPerMap;
          const newIntersects: ColumnIdPerSpreadsheet = {};

          maps.forEach(map => {
            const intersectValue = intersects[map.id];
            if (notUndefined(intersectValue)) {
              if (map.isCurrentMap && map.realSpreadsheetId) {
                const realSpreadsheetKey = createKeyFromRealSpreadsheetId(map.realSpreadsheetId);
                const columns = sourceColumns?.[realSpreadsheetKey] ?? {};
                addToIntersectsAndUsedKeys({
                  columns,
                  intersects: newIntersects,
                  intersectValue,
                  mapId: map.id,
                  usedKeys,
                  realSpreadsheetId: map.realSpreadsheetId,
                });
              }
              else {
                addToIntersectsAndUsedKeys({
                  mapId: map.id, intersectValue, intersects: newIntersects, usedKeys,
                });
              }
            }
          });

          return {
            name: combinedOption.targetColumnName || '',
            intersects: newIntersects,
          };
        });

      onSubmit(data);
    }
  },
  [layeringMapInfo?.isLayered, layeringMapInfo?.layering?.connected, layeringMapInfo?.layering?.columns,
    layeringMapInfo?.spreadsheets, rows, onSubmit, maps, addToIntersectsAndUsedKeys, sourceColumns],
  );

  // This calculates the rows in editing layered map mode
  useEffect(() => {
    if (isEditing) {
      const newRows: Row[] = [];
      const currentIntersects = filterAddedSpreadsheetColumns(layeringMapInfo?.layering?.matches) ?? [];
      const newIntersectsAdded: boolean[] = currentIntersects.map(() => false);
      let isRemovable = true;
      // These are the current LM intersects, generated with the initial POST LM call
      // or the last edit LM call. In this block we try to match the new intersects with
      // these ones, in order to match on the same rows old and new base maps
      currentIntersects.forEach((intersect, intersectIndex) => {
        if (intersect) {
          const spreadsheetKeys = Object.keys(intersect.intersects);
          const sourceColumnsPerMap: ColumnNamePerMapId = createNullsPerMap();
          let targetColumnName: string | null = null;
          for (const spreadsheetKey of spreadsheetKeys) {
            const realSpreadsheetId = extractRealSpreadsheetIdFromKey(spreadsheetKey);
            const map = getMapFromRealSpreadsheetId(maps, realSpreadsheetId);
            if (map) {
              targetColumnName = intersect.name;
              const columnId = intersect?.intersects?.[spreadsheetKey];
              if (columnId) {
                sourceColumnsPerMap[map.id] = sourceColumns?.[spreadsheetKey]?.[columnId] ?? null;
              }
              const layeredMapColumnId = createColumnIdFromNumber(intersectIndex);
              const layeredMapSpreadsheetId = layeringMapInfo?.spreadsheets?.[0]?.realSpreadsheets?.[0]?.realSpreadSheetId ?? 0;
              const layeredMapRealSpreadsheetId = createKeyFromRealSpreadsheetId(layeredMapSpreadsheetId);

              // We add all the newly added maps (if any) to the current intersects, when matching
              intersects.forEach((newIntersect, i) => {
                if (columnId === null) {
                  return;
                }
                let isNewIntersectsAdded = false;
                // If the LM is connected, the new intersects will be calculated based on current
                // base maps spreadsheets
                if (isConnected) {
                // We search for the intersect in the new intersects that matches the same column
                // in the current layered map intersects, so we can merge the new maps there
                  if (newIntersect?.intersects[spreadsheetKey] === columnId) {
                    isNewIntersectsAdded = true;
                  }
                }
                else {
                  if (newIntersect?.intersects[layeredMapRealSpreadsheetId] === layeredMapColumnId) {
                    isRemovable = false;
                    isNewIntersectsAdded = true;
                  }
                }

                if (isNewIntersectsAdded) {
                // We flag this new intersect as "added", this means that in this newIntersect there is at least
                // one spreadsheet (and associated map) that is already present in the current
                // layered map matches, so we'll attach the new spreadsheet keys into the same
                // intersect (in the sourceColumnsPerMap object).
                  newIntersectsAdded[i] = true;

                  const newSpreadsheetKeys = Object.keys(newIntersect.intersects);
                  for (const newSpreadsheetKey of newSpreadsheetKeys) {
                  // I ignore spreadsheets/maps that are already matched/layered in the layered map
                    if (!intersect.intersects[newSpreadsheetKey]) {
                      const newRealSpreadsheetId = extractRealSpreadsheetIdFromKey(newSpreadsheetKey);
                      const newMap = getMapFromRealSpreadsheetId(maps, newRealSpreadsheetId);
                      if (newMap) {
                        const newSpreadsheetId = getSpreadsheetIdFromMapId(maps, newMap.id);
                        if (newSpreadsheetId) {
                          const newColumnId = newIntersect?.intersects?.[newSpreadsheetKey];
                          if (newColumnId) {
                            sourceColumnsPerMap[newMap.id] = columnData?.[newSpreadsheetId]?.columns?.[newColumnId] || null;
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }

          const newCombinedOption: Row = {
            selectedSourceColumnPerMap: sourceColumnsPerMap,
            targetColumnName,
            isRemovable,
          };
          newRows.push(newCombinedOption);
        }
      });

      // We cycle again the intersects looking for brand new ones, that are those
      // without a spreadsheet and its associated map already matched in the layered map
      intersects.forEach((intersect, i) => {
        if (!newIntersectsAdded[i] && intersect) {
          const spreadsheetKeys = Object.keys(intersect.intersects);
          const sourceColumnsPerMap: ColumnNamePerMapId = createNullsPerMap();
          spreadsheetKeys.forEach(spreadsheetKey => {
            populateMapIdsWithColumnNames({ columnData, maps, intersect, sourceColumnsPerMap, spreadsheetKey });
          });

          const availableTargetColumnNames = getAvailableTargetColumnNames(sourceColumnsPerMap);

          const targetColumnName = availableTargetColumnNames[1]?.value || null; // Because 0 is none;

          const newCombinedOption: Row = {
            selectedSourceColumnPerMap: sourceColumnsPerMap,
            targetColumnName,
            isRemovable: true,
          };
          newRows.push(newCombinedOption);
        }
      });

      setRows(newRows);
    }
  }, [isEditing, isConnected, sourceColumns, layeringMapInfo, columnData, maps, getMapFromRealSpreadsheetId,
    getSpreadsheetIdFromMapId, getAvailableTargetColumnNames, createNullsPerMap, intersects]);

  // This calculates the rows in creating layered map mode
  useEffect(() => {
    if (!isEditing) {
      const newRows: Row[] = [];
      for (const intersect of intersects) {
        let targetColumnName: string | null = null;
        const sourceColumnsPerMap = createNullsPerMap();
        const spreadsheetKeys = Object.keys(intersect.intersects);

        spreadsheetKeys.forEach(spreadsheetKey => {
          populateMapIdsWithColumnNames({ columnData, maps, intersect, sourceColumnsPerMap, spreadsheetKey });
        });

        const availableTargetColumnNames = getAvailableTargetColumnNames(sourceColumnsPerMap);

        targetColumnName = availableTargetColumnNames.length >= 2 ? availableTargetColumnNames[1]?.value || null : null; // Because 0 is none;

        const newCombinedOption: Row = {
          selectedSourceColumnPerMap: sourceColumnsPerMap,
          targetColumnName,
          isRemovable: true,
        };
        newRows.push(newCombinedOption);
      }

      setRows(newRows);
    }
  }, [isEditing, sourceColumns, columnData, maps, getAvailableTargetColumnNames, getMapFromRealSpreadsheetId,
    getSpreadsheetIdFromMapId, createNullsPerMap, intersects]);

  const finalizedRows: RowWithExtraData[] = useMemo(() => rows.map(row => ({
    ...row,
    onlyOneSourceMap: hasRowOnlyOneSourceMap(row, props.maps),
    availableTargetColumnNames: getAvailableTargetColumnNamesWithoutNone(row.selectedSourceColumnPerMap),
  })), [getAvailableTargetColumnNamesWithoutNone, props.maps, rows]);

  const areAllRowsSelected = useMemo(() => !finalizedRows.some(row => row.targetColumnName === null),
    [finalizedRows]);

  return {
    rows: finalizedRows,
    sourceColumnsPerMap,
    onSourceColumnChange,
    onRemoveRow,
    onTargetColumnNameChange,
    onAddRow,
    handleGenerateIntersectsAndSubmit,
    areAllRowsSelected,
  };
};
