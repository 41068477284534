import { type MapSettingsStylesChangeData } from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesSilver: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#f5f5f5',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#616161',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#f5f5f5',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#bdbdbd',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#eeeeee',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#e5e5e5',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#9e9e9e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#ffffff',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#757575',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#dadada',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#616161',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#9e9e9e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#e5e5e5',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.station'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#eeeeee',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#c9c9c9',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#9e9e9e',
        isActive: true,
      },
    },
  },
];
