import { css } from '@emotion/react';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  LottieAnimations, LottieAnimationTypes, useLottieAnimationDefaultColors,
} from '~/_shared/baseComponents/lottieAnimation';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import {
  getExportImageModeDescription, getExportImageModeLabel,
} from '~/_shared/types/exportImage/exportImage.constants';
import { delay } from '~/_shared/utils/delay';
import {
  KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import {
  s, Trans,
} from '~/translations/Trans';
import { getLottieAnimationDuration } from '../../baseComponents/lottieAnimation/lottieAnimation.helpers';
import {
  getImageSizeDescription, getImageSizeLabel, ImageSize,
} from '../../constants/imageSize.enum';
import {
  ImageFileType, renderImageType,
} from '../../constants/imageType.enum';
import { useTheme } from '../../themes/theme.hooks';
import { type Theme } from '../../themes/theme.model';
import {
  ExportImageLegendMode, ExportImageLocationListMode, ExportImageMode, type ExportImageParams,
} from '../../types/exportImage/exportImage';
import {
  type TranslationFnc, useTranslation,
} from '../../utils/hooks';
import { ImageSelectorComponent } from '../imageSelector/imageSelector.component';
import { MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS } from '../locationListingPanel/locationListing.container';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLottieAnimationComponent } from '../overlay/overlayLottieAnimation.component';
import { ExportImageRowComponent } from './exportImageRow.component';
import {
  LegendModeSelectionComponent, type LegendModeSelectionProps,
} from './legendModeSelection.component';
import {
  LocationListModeSelectionComponent, type LocationListModeSelectionProps,
} from './locationListModeSelection.component';

export const exportImageTimeRange = { fromMinutes: 5, toMinutes: 10 } as const;

const getAvailableLocationListModes = (totalFilteredLocations: number, totalInBoundsLocations: number) => ([
  ...(totalInBoundsLocations <= MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS ? [ExportImageLocationListMode.FilteredInBounds] : []),
  ...(totalFilteredLocations <= MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS ? [ExportImageLocationListMode.AllFiltered] : []),
]);

const fileTypeItems = Object.values(ImageFileType)
  .map((type, id) => ({ id, render: () => renderImageType(type) }));

const createImageStyles = (
  props: {
    t: TranslationFnc;
    selectedStyle: ExportImageMode;
    legendModeSelectionProps: LegendModeSelectionProps;
    locationListModeSelectionProps: LocationListModeSelectionProps;
    totalFilteredLocations: number;
    totalInBoundsLocations: number;
  }): RadioGroupItem<ExportImageMode>[] =>
  Object.values(ExportImageMode).map(exportStyle => {
    const numOfAvailableOptions = props.legendModeSelectionProps.availableOptions.length;
    const isDisabledDueToNoAvailableLegendOptions = (exportStyle === ExportImageMode.MapAndLegend || exportStyle === ExportImageMode.Legend)
      && !numOfAvailableOptions;
    const isDisabledDueToNoVisibleMarkerOnMap = exportStyle === ExportImageMode.LocationList && !props.totalFilteredLocations;
    const isAllFilteredLocationsDisabledDueToTooMany = props.totalFilteredLocations > MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS;
    const isInBoundsLocationsDisabledDueToTooMany = props.totalInBoundsLocations > MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS;
    const isDisabledDueToTooManyMarkerOnMap = exportStyle === ExportImageMode.LocationList
      && isAllFilteredLocationsDisabledDueToTooMany && isInBoundsLocationsDisabledDueToTooMany;
    const showLegendDropdown = props.selectedStyle === exportStyle && numOfAvailableOptions
      && (exportStyle === ExportImageMode.MapAndLegend || exportStyle === ExportImageMode.Legend);
    const showLocationListModeDropdown = props.selectedStyle === exportStyle && exportStyle === ExportImageMode.LocationList
      && props.locationListModeSelectionProps.availableOptions.length;

    return {
      isDisabled: isDisabledDueToNoAvailableLegendOptions || isDisabledDueToNoVisibleMarkerOnMap || isDisabledDueToTooManyMarkerOnMap,
      label: getExportImageModeLabel(props.t, exportStyle),
      description: getExportImageModeDescription(props.t, exportStyle),
      tooltip: isDisabledDueToNoAvailableLegendOptions ? props.t('ExportImageMode.DisabledOption.LegendNotAvailable')
        : isDisabledDueToNoVisibleMarkerOnMap ? props.t('ExportImageMode.DisabledOption.NoVisibleMarkersOnMap')
          : isDisabledDueToTooManyMarkerOnMap ? props.t('ExportImageMode.DisabledOption.TooManyMarkers')
            : undefined,
      value: exportStyle,
      children: showLegendDropdown ? <LegendModeSelectionComponent {...props.legendModeSelectionProps} />
        : showLocationListModeDropdown ? <LocationListModeSelectionComponent {...props.locationListModeSelectionProps} />
          : null,
      style: showLegendDropdown || showLocationListModeDropdown ? exportImageStyleRowStyle : undefined,
    };
  });

const createImageSizes = (t: TranslationFnc) => Object.values(ImageSize)
  .map(size => ({
    label: getImageSizeLabel(t, size),
    description: getImageSizeDescription(t, size),
    value: size,
  }));

const exportImageStyleRowStyle = css({
  flexDirection: 'column',
});

const noteStyle = css({
  marginTop: 36,
  marginBottom: 30,
  lineHeight: '24px',
});

const noteHighlightStyle = (theme: Theme) => css({
  color: theme.textColors.danger,
});

const exportImageRowWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 35,
  marginTop: 20,
});

const LoadingAnimation = {
  type: LottieAnimationTypes.UploadingImage,
  segment: LottieAnimations.UploadingImage.segments.upload,
};

type ExportImageModalProps = Readonly<{
  availableLegendOptions: ExportImageLegendMode[];
  isLoading: boolean;
  isOpen: boolean;
  totalFilteredLocations: number;
  totalInBoundsLocations: number;

  onClose: () => void;
  onContinue: (result: ExportImageParams) => void;
}>;

export const ExportImageModalComponent: React.FC<ExportImageModalProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors()[LoadingAnimation.type];
  const [selectedSize, setSelectedSize] = useState(ImageSize.Normal);
  const [selectedFileType, setSelectedFileType] = useState(ImageFileType.PNG);
  const [selectedMode, setSelectedMode] = useState(ExportImageMode.Screenshot);
  const [selectedLegendMode, setSelectedLegendMode] = useState<ExportImageLegendMode>(
    props.availableLegendOptions[0] ?? ExportImageLegendMode.Grouping
  );
  const [selectedLocationListMode, setSelectedLocationListMode] = useState<ExportImageLocationListMode | null>(
    getAvailableLocationListModes(props.totalFilteredLocations, props.totalInBoundsLocations)[0] ?? null
  );

  const onContinue = useCallback(() => {
    const continueCallback = props.onContinue;
    continueCallback({
      imageSize: selectedSize,
      exportMode: selectedMode,
      imageFileType: selectedFileType,
      exportLegendMode: (selectedMode === ExportImageMode.MapAndLegend || selectedMode === ExportImageMode.Legend)
        ? selectedLegendMode || null : null,
      exportLocationListMode: selectedMode === ExportImageMode.LocationList ? selectedLocationListMode || null : null,
    });
  }, [props.onContinue, selectedFileType, selectedLegendMode, selectedLocationListMode, selectedMode, selectedSize]);

  const availableLocationListModes = useMemo(() => (
    getAvailableLocationListModes(props.totalFilteredLocations, props.totalInBoundsLocations)
  ), [props.totalFilteredLocations, props.totalInBoundsLocations]);

  const legendModeSelectionProps = useMemo(() => ({
    availableOptions: props.availableLegendOptions,
    onSelectOption: setSelectedLegendMode,
    selectedOption: selectedLegendMode,
  }), [props.availableLegendOptions, selectedLegendMode]);

  const locationListModeSelectionProps = useMemo(() => ({
    availableOptions: availableLocationListModes,
    onSelectOption: setSelectedLocationListMode,
    selectedOption: selectedLocationListMode,
  }), [availableLocationListModes, selectedLocationListMode]);

  const imageSelector = useMemo(() => (
    <ImageSelectorComponent
      images={fileTypeItems}
      selectedImagesIds={[Object.values(ImageFileType).indexOf(selectedFileType)]}
      onSelectionChanged={s => setSelectedFileType((s[0] !== undefined && Object.values(ImageFileType)[s[0]]) || selectedFileType)}
      imageHeight={64}
      imageWidth={64}
    />
  ), [selectedFileType]);

  const styleSelector = useMemo(() => (
    <RadioGroupComponent
      selectedValue={selectedMode}
      onValueChange={v => setSelectedMode(Object.values(ExportImageMode).find(s => s === v) ?? selectedMode)}
      items={createImageStyles({
        t, selectedStyle: selectedMode, legendModeSelectionProps, locationListModeSelectionProps,
        totalFilteredLocations: props.totalFilteredLocations,
        totalInBoundsLocations: props.totalInBoundsLocations,
      })}
    />
  ), [legendModeSelectionProps, locationListModeSelectionProps, selectedMode, t, props.totalFilteredLocations, props.totalInBoundsLocations]);

  const sizeSelector = useMemo(() => (
    <RadioGroupComponent
      selectedValue={selectedSize}
      onValueChange={v => setSelectedSize(Object.values(ImageSize).find(s => s === v) ?? selectedSize)}
      items={createImageSizes(t)}
    />
  ), [selectedSize, t]);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: onContinue },
    options: { allowActionWhenModalOpen: true, allowInputs: true },
    targetKeys: KeyboardKeys.Enter,
  }), [onContinue]));

  useEffect(() => {
    if (selectedLocationListMode === null) {
      return;
    }
    if (availableLocationListModes[0]) {
      if (!availableLocationListModes.includes(selectedLocationListMode)) {
        setSelectedLocationListMode(availableLocationListModes[0]);
      }
    }
    else {
      setSelectedLocationListMode(null);
    }
  }, [selectedLocationListMode, availableLocationListModes]);

  // Close modal when loading animation is done
  useEffect(() => {
    if (props.isLoading) {
      delay(getLottieAnimationDuration(LoadingAnimation.type, LoadingAnimation.segment) + 1000)
        .then(props.onClose);
    }
  }, [props.isLoading, props.onClose]);

  return (
    <ModalComponent
      allowedPages={ALL_MAP_RELATED_PAGES}
      onClose={props.onClose}
      isOpen={props.isOpen}
      confirmButton={(
        <ButtonComponent
          text={t('Continue')}
          onClick={onContinue}
        />
      )}
      caption={t('Export Map Image for Printing')}
      maxWidth={700}
      contentStyle={css({ padding: '0 24px' })}
      additionalContent={props.isLoading && (
        <OverlayLottieAnimationComponent
          type={LoadingAnimation.type}
          segment={LoadingAnimation.segment}
          size={120}
          colors={animationColors}
          autoplay
        />
      )}
    >

      <div css={exportImageRowWrapperStyle}>
        <ExportImageRowComponent
          title={t('Export File Type') + ':'}
          content={imageSelector}
          titleTopPadding={25}
        />
        <ExportImageRowComponent
          title={t('Export Style') + ':'}
          content={styleSelector}
        />
        <ExportImageRowComponent
          title={t('Export Size') + ':'}
          content={sizeSelector}
        />
      </div>

      <div css={noteStyle}>
        <span css={css({ textTransform: 'uppercase' })}>{t('note')}: </span>
        <Trans
          i18nKey="This process can take up to <1>{{fromMinutes}}-{{toMinutes}} minutes</1> depending on the queue and complexity of your map."
          tOptions={exportImageTimeRange}
        >
          {s('This process can take up to')}<span css={noteHighlightStyle(theme)}>x-y minutes</span>{s(' depending on the queue and complexity of your map.')}
        </Trans>
      </div>
    </ModalComponent>
  );
};
