import { css } from '@emotion/react';
import React from 'react';
import { Stack } from '~/_shared/baseComponents/layout/Stack.componment';
import { warningColorStyle } from '~/_shared/baseComponents/warning/warning.styles';
import { TableComponent } from '~/map/mapMigration/table';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import { MapMigrationResultRowComponent } from './mapMigrationResultRow.component';

const columnWidths = ['auto'];

const migrationResultTableStyles = css({
  marginInline: -16,
  width: 'calc(100% + 32px)',
  boxShadow: 'none',
});

type MapMigrationResultStackProps = Readonly<{
  maps: ReadonlyArray<V4MapInfo>;
  warning: React.ReactNode;
  instructions: React.ReactNode;
}>;

export const MapMigrationResultStackComponent = ({ maps, warning, instructions }: MapMigrationResultStackProps) => {
  return (
    <Stack gap={16}>
      <p css={warningColorStyle}>
        {warning}
      </p>
      <TableComponent columnWidths={columnWidths} css={migrationResultTableStyles}>
        {maps.map(map => (
          <MapMigrationResultRowComponent key={map.id} map={map} />
        ))}
      </TableComponent>
      {instructions}
    </Stack>
  );
};
