import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { useProcessMarkersForRouting } from '../optimizeRouteBetweenPoints/useProcessMarkersForRouting';
import { useGetMarkersInArea } from '../useGetMarkersInArea';

export const useLassoAndFill = () => {
  const { getMarkersInArea } = useGetMarkersInArea();
  const { processSelectedData, loading } = useProcessMarkersForRouting();

  const sendToRouting = async (polygon: Polygon) => {
    const markersInLasso = await getMarkersInArea({
      multiPolygons: [[polygon]],
      useMainFilters: true,
    });
    processSelectedData(markersInLasso);
  };

  return {
    processToRouting: sendToRouting,
    loading,
  };
};
