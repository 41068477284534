export type ChartsInfoData = Readonly<{
  label: string;
  data: ReadonlyArray<number>;
  backgroundColor: ReadonlyArray<string>;
  borderColor?: ReadonlyArray<string>;
  borderWidth?: number;
}>;

export type ChartsInfo = Readonly<{
  labels: string[];
  datasets: ChartsInfoData[];
}>;

export enum MaptiveChartType {
  DoughnutChart = 'DoughnutChart',
  HorizontalBarChart = 'HorizontalBarChart',
  PieChart = 'PieChart',
  PolarAreaChart = 'PolarAreaChart',
  RadarChart = 'RadarChart',
  VerticalBarChart = 'VerticalBarChart',
}
