import {
  type FC, memo, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapOutlinePositionInstance } from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '~/_shared/types/latLng';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { ZOOM_LEVEL_FOR_SCALED_ITEMS } from '~/_shared/utils/distance/distance.helpers';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type DrawingItemCircle } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapCircleComponent } from '../../mapObjects/mapCircle/mapCircle.component';
import { MapCircleContainer } from '../../mapObjects/mapCircle/mapCircle.container';
import { type MapCircleInstance } from '../../mapObjects/mapCircle/mapCircleModel';

type MapCircleInstanceProps = {
  instance: DrawingItemCircle;
  zIndex: MapObjectZIndex;
  onCircleClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
  onCircleRightClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
  onCircleMouseOut?: () => void;
  onCircleMouseOver?: () => void;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  onCircleDragStart?: () => void;
  onCircleDragEnd?: () => void;
  onCircleDragMove?: (circleId: Uuid, latLng: LatLng) => void;
};

const DrawingToolCircleInstanceCircleContainer: FC<MapCircleInstanceProps> = ({
  instance, zIndex, onCircleClick, onCircleMouseOut, onCircleMouseOver,
  renderOutline, onCircleDragStart, onCircleDragEnd, onCircleDragMove,
  onCircleRightClick,
}) => {
  const fillColor = convertColorToWebGLColor(instance.settings.fillColor, instance.settings.fillOpacity / 100);
  const borderColor = convertColorToWebGLColor(instance.settings.strokeColor, instance.settings.strokeOpacity / 100);

  const renderCircle = useCallback((id: string, circle: MapCircleInstance) => (
    <MapCircleComponent
      key={id}
      circle={circle}
      visuals={{
        circleArea: {
          fillColor,
          borderWidth: instance.settings.strokeWeight,
          borderColor,
          units: 'pixels',
          staticSize: !instance.settings.scalesWithMapZoom,
          autoScale: instance.settings.scalesWithMapZoom,
        },
      }}
      onDragStart={onCircleDragStart}
      onDragEnd={onCircleDragEnd}
      onDragMove={onCircleDragMove}
      onClick={onCircleClick}
      onRightClick={onCircleRightClick}
      onMouseOut={onCircleMouseOut}
      onMouseOver={onCircleMouseOver}
    />
  ), [borderColor, fillColor, instance.settings.strokeWeight, instance.settings.scalesWithMapZoom,
    onCircleMouseOver, onCircleMouseOut, onCircleClick, onCircleDragStart, onCircleDragEnd,
    onCircleDragMove, onCircleRightClick]);

  const mapCircle: MapCircleInstance = useMemo(() => {
    return {
      id: instance.id,
      lat: instance.center.lat,
      lng: instance.center.lng,
      radius: instance.settings.radius,
      scalesWithMapZoom: instance.settings.scalesWithMapZoom,
      strokeWeight: instance.settings.strokeWeight,
      sizeOnLevel: instance.settings.scalesWithMapZoom ? ZOOM_LEVEL_FOR_SCALED_ITEMS : undefined,
    };
  }, [instance.center.lat, instance.center.lng, instance.id, instance.settings.radius,
    instance.settings.scalesWithMapZoom, instance.settings.strokeWeight]);

  return (
    <MapCircleContainer
      circle={mapCircle}
      zIndex={zIndex}
      renderCircle={renderCircle}
      renderOutline={renderOutline}
    />
  );
};

const pureComponent = memo(DrawingToolCircleInstanceCircleContainer);
export { pureComponent as DrawingToolCircleInstanceCircleContainer };
