import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { RoundButtonComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../../../_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { type DrawingToolOptions } from '../../../../drawingTool/drawingTool.types';
import { DrawingToolOptionsComponent } from '../../../../drawingTool/drawingToolOptions/drawingToolOptions.component';
import { type DrawingItem } from '../../../../store/mapSettings/drawing/items/drawingItems.state';
import { type DrawingSettingsState } from '../../../../store/mapSettings/drawing/settings/drawingSettings.state';
import { DrawingToolDetailsFooterComponent } from './drawingToolDetailsFooter.component';

type DrawingToolDetailsContentProps = Readonly<{
  drawingSettings: DrawingSettingsState;
  isResetButtonDisabled: boolean;
  selectedDrawingItem: DrawingItem;
  selectedItemOptions: Partial<DrawingToolOptions>;

  onDeleteClick: () => void;
  onResetClick: () => void;
  onToolOptionsChange: (options: Partial<DrawingToolOptions>) => void;
}>;

const editToolStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  cursor: 'default',
  padding: '12px 16px',
  justifyContent: 'space-between',
});

const wrapperStyle = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const contentWrapperStyle = css({
  flex: 1,
});

const footerStyle = ({ theme }: ThemeProps) => css({
  padding: '10px 10px',
  background: theme.backgroundColors.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const headerStyle = ({ theme }: ThemeProps) => css({
  width: '100%',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const overlayStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.backgroundColors.overlay,
});

export const DrawingToolDetailsContentComponent: FC<DrawingToolDetailsContentProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [filesLoading, setFilesLoading] = useState(false);

  return (
    <div css={wrapperStyle}>
      <div css={headerStyle({ theme })}>
        <div css={editToolStyle({ theme })}>
          {t('Edit {{drawingToolName}}', {
            drawingToolName: t(props.selectedDrawingItem.type),
          })}

          <RoundButtonComponent
            icon={faTrash}
            buttonStyle={RoundButtonStyle.Danger}
            onClick={props.onDeleteClick}
            tooltipLabel={t('Delete')}
            tooltipPlacement={TooltipPlacement.Top}
          />
        </div>
      </div>

      <div css={contentWrapperStyle}>
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
        >
          <DrawingToolOptionsComponent
            initialFileAttachmentId={props.selectedDrawingItem.type === DrawingTool.Image ? props.selectedDrawingItem.value.attachmentId : undefined}
            onToolOptionsChange={props.onToolOptionsChange}
            selectedTool={props.selectedDrawingItem.type}
            toolOptions={props.selectedItemOptions}
            onFilesLoadingChange={setFilesLoading}
          />
        </ScrollBarComponent>
      </div>

      {filesLoading && (
        <OverlayLoaderComponent css={overlayStyle({ theme })} />
      )}

      <div css={footerStyle({ theme })}>
        <DrawingToolDetailsFooterComponent
          isResetDisabled={props.isResetButtonDisabled}
          onReset={props.onResetClick}
          selectedTool={props.selectedDrawingItem.type}
        />
      </div>
    </div>
  );
};
