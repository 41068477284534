import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { type ModalProps } from '../../modal/modalType.enum';
import { publicMapSettingsSetZoomRestrictLevels } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.actionCreators';
import { usePublicMapSettingsZoomRestrictLevelsSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { RestrictZoomLevelModalComponent } from './restrictZoomLevelModal.component';

type RestrictZoomLevelModalContainer = ModalProps;

export const RestrictZoomLevelModalContainer: FC<RestrictZoomLevelModalContainer> = (props) => {
  const zoomRestrictLevels = usePublicMapSettingsZoomRestrictLevelsSelector();
  const dispatch = useDispatch();

  const onSubmit = (newRestrictLevelFrom: number, newRestrictLevelTo: number) => {
    dispatch(publicMapSettingsSetZoomRestrictLevels([newRestrictLevelFrom, newRestrictLevelTo]));
    props.onClose();
  };

  return (
    <RestrictZoomLevelModalComponent
      {...props}
      onSubmit={onSubmit}
      initialZoomLevelFrom={zoomRestrictLevels[0]}
      initialZoomLevelTo={zoomRestrictLevels[1]}
    />
  );
};
