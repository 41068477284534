import { type OperationResultResponse } from './api.helpers';

const CANCEL_ERROR_STATUS = -4;

// both 404 and 403 are used with our backend
// 404 most often when entity never exitsted, 403 when it was later removed
const NOT_FOUND_STATUS_CODES = [404, 403];

export enum ApiErrorCode {
  DIFFERENT_STRUCTURE = 'DIFFERENT_STRUCTURE'
}

export const isNotFoundApiError = (e: any) => isApiError(e) ? NOT_FOUND_STATUS_CODES.includes(e.responseStatus) : false;

export interface ApiError extends Error {
  responseStatus: number;
  errorCode?: string;
  errors?: { readonly [key: string]: ReadonlyArray<string> };
  errorData?: {readonly [key: string]: ReadonlyArray<any> };
  response?: unknown;
}

export const isApiError = (e: any): e is ApiError => {
  const apiError = e as Partial<ApiError> | undefined;
  return apiError?.responseStatus !== undefined && apiError?.message !== undefined;
};

export const isApiConcurrencyError = (e: any): boolean => isApiError(e) && e.responseStatus === 412;

export const getApiErrorErrors = (e: ApiError): string[] | null => {
  if (!e.errors) {
    return null;
  }

  return Object.values(e.errors).reduce<string[]>((acc, errors) => {
    acc = acc.concat(errors);

    return acc;
  }, []);
};

export const createConnectionApiError = (): ApiError => {
  const e = new Error();
  return {
    ...e,
    responseStatus: -1,
    message: 'Connection Error',
  };
};

export const createUnknownError = (message: string): ApiError => {
  const e = new Error();
  return {
    ...e,
    responseStatus: -2,
    message,
  };
};

export const createHttpError = (status: number, response: OperationResultResponse): ApiError => {
  const e = new Error();
  return {
    ...e,
    responseStatus: status,
    message: response.message ?? `HTTP Error ${status}`,
    errorCode: response.code ?? undefined,
    errors: response.errors,
    response,
  };
};

export const isCancelError = (e: ApiError) => {
  return e?.responseStatus === CANCEL_ERROR_STATUS;
};

export const createCancelError = (): ApiError => {
  const e = new Error();
  return {
    ...e,
    responseStatus: CANCEL_ERROR_STATUS,
    message: '',
  };
};

export const catchApiError = <T>(onCatch: (apiError: ApiError) => T | PromiseLike<T>): (e: any) => T | PromiseLike<T> =>
  (e: any) => {
    if (isApiError(e)) {
      return onCatch(e);
    }
    else {
      throw e;
    }
  };
