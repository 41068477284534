import {
  select as _select, take,
} from 'redux-saga/effects';
import { type AppState } from '../../../store/app.store';

export const select = <TResult>(selector: (state: AppState) => TResult) => _select(selector);

export function* waitForStateChange<TResult>(selector: (state: AppState) => TResult, expectedValue: TResult) {
  const result: TResult = yield select<TResult>(selector);

  if (result === expectedValue) {
    return;
  }

  while (true) {
    yield take('*');
    const result: TResult = yield select<TResult>(selector);
    if (result === expectedValue) {
      return;
    }
  }
}
