import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';

const buttonBarStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type InvitationPageCtaProps = {
  onDeclineClick: () => void;
  onAcceptClick?: () => void;
  acceptButtonCaption?: string;
  isCreateButtonSubmit?: boolean;
};

export const InvitationPageCtaComponent: FC<InvitationPageCtaProps> = (props) => {
  const [t] = useTranslation();

  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const onDeclineClick = () => {
    openConfirmationModal({
      title: t('Decline invitation'),
      text: t('Are you sure you want to decline the invitation?'),
      onConfirm: () => {
        closeConfirmationModal();
        props.onDeclineClick();
      },
      onCancel: closeConfirmationModal,
      isConfirmButtonDestructive: true,
      showLoaderAfterConfirmClick: true,
      confirmCaption: t('Decline'),
    });
  };

  return (
    <div css={buttonBarStyle}>
      <ButtonComponent
        buttonStyle={ButtonStyle.Danger}
        size={ButtonSize.Large}
        text={t('Decline')}
        onClick={(_event) => onDeclineClick()}
      />

      <ButtonComponent
        size={ButtonSize.Large}
        isSubmit={props.isCreateButtonSubmit}
        text={props.acceptButtonCaption ?? t('Accept')}
        onClick={(_event) => props.onAcceptClick?.()}
      />
    </div>
  );
};
