import {
  type FC, memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { getDraggableCursorForOutlinePosition } from '~/_shared/utils/mapObjectOutline/outline.helpers';
import {
  MapOutlinePosition,
  type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '../../../../_shared/types/latLng';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { drawingEditPushNewSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered,
  drawingToolItemHoverStopped,
  drawingToolItemMoveStarted,
  drawingToolItemMoveStopped,
  drawingToolItemResizeStarted,
  drawingToolItemResizeStopped,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemArrow } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { useActiveDrawingInstanceZIndex } from '../../../zIndexes/useDrawingInstanceZIndex.hook';
import { useMap } from '../../mapContext';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { type ArrowPoints } from './drawingToolArrow.types';
import { DrawingToolArrowInstanceArrowContainer } from './drawingToolArrowInstanceArrow.container';
import { useDrawingToolArrowScalingToggle } from './useDrawingToolArrowScalingToggle';

type MapArrowSelectedInstanceProps = {
  instance: DrawingItemArrow;
};

const DrawingToolArrowSelectedInstanceContainer: FC<MapArrowSelectedInstanceProps> = ({
  instance,
}) => {
  const [isArrowDragging, setIsArrowDragging] = useState(false);
  const [arrowPoints, setArrowPoints] = useState<ArrowPoints>({
    tipPoint: instance.tipPoint,
    tailPoint: instance.tailPoint,
  });
  const [staticLength, setStaticLength] = useState(instance.staticLength);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const map = useMap();
  const { fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom, getDistanceBetweenPoints } = useProjectionOverlay(map);
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();

  const dragStartDataRef = useRef<{ startDragPosition: LatLng; initialArrowPoints: ArrowPoints } | null>(null);

  useDrawingToolArrowScalingToggle({
    arrowPoints,
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    onStaticLengthChange: setStaticLength,
  });

  const onMapShapeChange = useCallback((tipPoint: LatLng, tailPoint: LatLng, newStaticLength?: number) => {
    setArrowPoints({
      tipPoint,
      tailPoint,
    });

    if (newStaticLength !== undefined) {
      setStaticLength(newStaticLength);
    }

    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Arrow,
      value: {
        ...instance,
        staticLength: newStaticLength ?? instance.staticLength,
        tipPoint,
        tailPoint,
      },
    }));
  }, [dispatch, instance]);

  const onResizeControllerDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      const newStaticLength = getDistanceBetweenPoints(arrowPoints.tipPoint, arrowPoints.tailPoint) ?? 0;
      onMapShapeChange(arrowPoints.tipPoint, arrowPoints.tailPoint, newStaticLength);

      dispatch(drawingToolItemResizeStopped());
    }
  }, [dispatch, arrowPoints, drawingEventsEnabledRef, onMapShapeChange, getDistanceBetweenPoints]);

  const onResizeControllerDragStart = useCallback((_outlineId: string, position: MapOutlinePosition) => {
    if (drawingEventsEnabledRef.current) {
      setHoveredOutlineId(null);

      dispatch(drawingToolItemResizeStarted(getDraggableCursorForOutlinePosition(position)));
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onResizeControllerMouseOver = useCallback((outlineId: string) => {
    dispatch(drawingToolItemHovered());
    setHoveredOutlineId(outlineId);
  }, [dispatch]);

  const onResizeControllerMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
    setHoveredOutlineId(null);
  }, [dispatch]);

  const onResizeControllerDragMove = useCallback((
    _outlineId: string, position: MapOutlinePosition, latLng: LatLng
  ) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    // tip outline
    if (position === MapOutlinePosition.Top) {
      setArrowPoints((prevPosition) => {
        const newStartPoint = latLng;
        const newEndPoint = prevPosition.tailPoint;

        const newStaticLength = getDistanceBetweenPoints(newStartPoint, newEndPoint) ?? 0;
        setStaticLength(newStaticLength);

        return {
          tipPoint: newStartPoint,
          tailPoint: newEndPoint,
        };
      });
    }

    // tail outline
    if (position === MapOutlinePosition.Bottom) {
      setArrowPoints((prevPosition) => {
        const newStartPoint = prevPosition.tipPoint;
        const newEndPoint = latLng;

        const newStaticLength = getDistanceBetweenPoints(newStartPoint, newEndPoint) ?? 0;
        setStaticLength(newStaticLength);

        return {
          tipPoint: newStartPoint,
          tailPoint: newEndPoint,
        };
      });
    }
  }, [drawingEventsEnabledRef, getDistanceBetweenPoints]);

  const renderResizeController = useCallback((id: string, resizeController: MapOutlinePositionInstance) => {
    return (
      <MapOutline
        key={id}
        outline={resizeController}
        visuals={hoveredOutlineId === id ? outlineHoverVisuals : outlineVisuals}
        onDragMove={(outlineId, latLng) => onResizeControllerDragMove(outlineId, resizeController.position, latLng)}
        onDragEnd={onResizeControllerDragEnd}
        onDragStart={(outlineId) => onResizeControllerDragStart(outlineId, resizeController.position)}
        onMouseOver={onResizeControllerMouseOver}
        onMouseOut={onResizeControllerMouseOut}
      />
    );
  }, [hoveredOutlineId, onResizeControllerDragEnd, onResizeControllerMouseOver,
    onResizeControllerMouseOut, onResizeControllerDragMove, onResizeControllerDragStart]);

  const onArrowDragStart = useCallback((position: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      dragStartDataRef.current = { startDragPosition: position, initialArrowPoints: arrowPoints };
      setIsArrowDragging(true);
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef, arrowPoints]);

  const onArrowDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      onMapShapeChange(arrowPoints.tipPoint, arrowPoints.tailPoint);

      setIsArrowDragging(false);
      dispatch(drawingToolItemMoveStopped());
    }
  }, [dispatch, arrowPoints, drawingEventsEnabledRef, onMapShapeChange]);

  const onArrowDragMove = useCallback((position: LatLng) => {
    if (!dragStartDataRef.current) {
      return;
    }

    const { startDragPosition, initialArrowPoints } = dragStartDataRef.current;

    const offsetStartPoint = fromLatLngToDivPixel(startDragPosition);
    const offsetEndPoint = fromLatLngToDivPixel(position);

    if (!offsetStartPoint || !offsetEndPoint) {
      return;
    }

    const offsetPoint = new google.maps.Point(
      offsetEndPoint.x - offsetStartPoint.x,
      offsetEndPoint.y - offsetStartPoint.y
    );

    if (!offsetPoint) {
      return;
    }

    const newTipPoint = moveLatLngByPixelsOnCurrentZoom(initialArrowPoints.tipPoint, offsetPoint);
    const newTailPoint = moveLatLngByPixelsOnCurrentZoom(initialArrowPoints.tailPoint, offsetPoint);

    if (!newTipPoint || !newTailPoint) {
      return;
    }

    setArrowPoints({
      tipPoint: newTipPoint,
      tailPoint: newTailPoint,
    });
  }, [fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom]);

  const onArrowClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const onArrowMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onArrowMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  useEffect(() => {
    setArrowPoints({
      tipPoint: instance.tipPoint,
      tailPoint: instance.tailPoint,
    });
  }, [instance.tipPoint, instance.tailPoint]);

  return (
    <>
      <DrawingToolArrowInstanceArrowContainer
        instance={{
          id: instance.id,
          tipPoint: arrowPoints.tipPoint,
          tailPoint: arrowPoints.tailPoint,
          staticLength,
          placement: instance.placement,
          settings: instance.settings,
        }}
        onClick={onArrowClick}
        zIndex={zIndex}
        onMouseOut={onArrowMouseOut}
        onMouseOver={onArrowMouseOver}
        onDragStart={onArrowDragStart}
        onDragEnd={onArrowDragEnd}
        onDragMove={onArrowDragMove}
        renderOutline={!isArrowDragging ? renderResizeController : undefined}
      />
    </>
  );
};

const pureComponent = memo(DrawingToolArrowSelectedInstanceContainer);
export { pureComponent as DrawingToolArrowSelectedInstanceContainer };
