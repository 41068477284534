import {
  useCallback, useMemo, useState,
} from 'react';
import { DeleteClientConfirmationBodyComponent } from '~/_shared/components/accountSettings/deleteClientConfirmationBody.component';
import { type AuthConfirmationErrorArgs } from '~/_shared/components/modal/confirmationWithAuthentication/confirmationWithAuthenticationModal.component';
import { type TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { MFAErrorCode } from '~/_shared/types/responseErrors/_shared/MFAErrorCodes.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useLogOutUser } from '~/authorization/logOutUser.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  DeleteClientCodeError, deleteClientData, type DeleteClientResponse, DeleteClientResponseCode,
  type DeleteClientResponseCodes,
} from './repository/userData.repository';

export type DeleteClientReturnValue = Readonly<{
  errorType?: DeleteClientResponseCodes;
  isSuccess: boolean;
  required2FAMode?: TwoFactorMode;
}>;

export const useDeleteClient = () => {
  const clientId = useClientIdSelector();
  const { openModal: openConfirmationWithAuth } = useModal(ModalType.ConfirmationWithAuthentication);
  const [t] = useTranslation();
  const logOutUser = useLogOutUser();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const deleteClientCall = useCallback((password: string, mfaMode?: TwoFactorMode, mfaCode?: string) => {
    if (clientId) {
      return deleteClientData(clientId, { mfaMode, mfaCode, password });
    }
    return Promise.reject();
  }, [clientId]);

  const { invokeAjax: invokeDelete } = useAjaxCall(deleteClientCall);

  const deleteClient = useCallback((password: string, mfaMode?: TwoFactorMode, mfaCode?: string): Promise<DeleteClientReturnValue> => {
    setIsLoading(true);

    return new Promise<DeleteClientReturnValue>((resolve, reject) => {
      invokeDelete(password, mfaMode, mfaCode)
        .then(_ => {
          // after user is successfully deleted, they cannot stay where they are
          // navigate them to login page
          resolve({ isSuccess: true });
          logOutUser();
        })
        .catch((error: { response?: DeleteClientResponse }) => {
          if (error.response?.code === MFAErrorCode.MFA_CODE_IS_REQUIRED || error.response?.code === MFAErrorCode.MFA_REQUIRED) {
            const beMFAMode = error.response?.data?.list?.[0];
            invokeDelete(password, beMFAMode, mfaCode)
              .then(response => {
                if (response.code === MFAErrorCode.MFA_CODE_SENT_TO_EMAIL) {
                  setError(MFAErrorCode.MFA_CODE_SENT_TO_EMAIL);
                  reject({
                    isSuccess: false,
                    errorCode: MFAErrorCode.MFA_REQUIRED,
                    required2FAMode: beMFAMode,
                  });
                }
                else {
                  resolve({ isSuccess: true });
                  logOutUser();
                }
              })
              .catch((e => {
                setError(e.errorCode);
                reject({
                  isSuccess: false,
                  errorCode: e.errorCode,
                  required2FAMode: beMFAMode,
                });
              }));
          }
          else if (error.response?.errors?.code?.[0] === DeleteClientCodeError.CODE_WRONG_NUMBER_OF_CHARACTERS) {
            setError(MFAErrorCode.MFA_CODE_IS_INVALID);
            reject({
              isSuccess: false,
              errorCode: MFAErrorCode.MFA_CODE_IS_INVALID,
              required2FAMode: mfaMode,
            });
          }
          else {
            setError(error.response?.code);
            reject(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [invokeDelete, logOutUser]);

  const openDeleteClientConfirmation = useCallback(() => {
    openConfirmationWithAuth({
      content: (<DeleteClientConfirmationBodyComponent />),
      isSubmitDestructive: true,
      onSubmit: (args: {
        mfaCode?: string;
        onError: (errorArgs: AuthConfirmationErrorArgs) => void;
        password: string;
      }) => {
        deleteClient(args.password, undefined, args.mfaCode)
          .catch(e => {
            args.onError({
              mfaMessage: e.infoMessage,
              required2FAMode: e.required2FAMode,
              wrongMFACode: e.errorCode === MFAErrorCode.MFA_CODE_IS_INVALID,
              wrongPassword: e.errorCode === DeleteClientResponseCode.BAD_REQUEST,
            });
          });
      },
      skipCloseOnMapIdChange: true,
      submitCaption: t('Delete Client Account'),
      title: t('Delete Client Account'),
    });
  }, [deleteClient, openConfirmationWithAuth, t]);

  return useMemo(() => ({
    deleteClient,
    error,
    isLoading,
    openDeleteClientConfirmation,
  }), [deleteClient, error, isLoading, openDeleteClientConfirmation]);
};
