import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  drawingToolSetAllToolsVisibility,
  drawingToolSetToolVisibility,
} from '../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { useHiddenDrawingToolsSelector } from '../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import {
  DRAWING_TOOLS_LIST, type DrawingTool,
} from '../drawingTool.enums';

export const useDrawingToolVisibility = () => {
  const hiddenDrawingTools = useHiddenDrawingToolsSelector();
  const dispatch = useDispatch();

  const checkDrawingToolVisibility = useCallback((drawingTool: DrawingTool) => {
    return !hiddenDrawingTools.has(drawingTool);
  }, [hiddenDrawingTools]);

  const setDrawingToolVisibility = useCallback((drawingTool: DrawingTool, isVisible: boolean) => {
    dispatch(drawingToolSetToolVisibility(drawingTool, isVisible));
  }, [dispatch]);

  const setAllToolsVisibility = useCallback((areVisible: boolean) => {
    dispatch(drawingToolSetAllToolsVisibility(areVisible));
  }, [dispatch]);

  const areAllDrawingToolsHidden = useMemo(() => {
    return hiddenDrawingTools.size === DRAWING_TOOLS_LIST.length;
  }, [hiddenDrawingTools]);

  return {
    checkDrawingToolVisibility,
    areAllDrawingToolsHidden,
    setDrawingToolVisibility,
    setAllToolsVisibility,
  };
};
