import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import { ACCORDION_HEADER_SIZE_LARGE } from '~/_shared/baseComponents/accordion';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { ImageSelectorComponent } from '~/_shared/components/imageSelector/imageSelector.component';
import { OverlayComponent } from '~/_shared/components/overlay/overlay.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  DRAWING_TOOLS_LIST, DrawingTool, drawingToolIcons,
} from '~/drawingTool/drawingTool.enums';
import {
  getDrawingToolItemDescription, getDrawingToolItemLabel,
} from '~/drawingTool/drawingTool.helpers';
import { type DrawingToolOptions } from '~/drawingTool/drawingTool.types';
import { DrawingToolOptionsComponent } from '~/drawingTool/drawingToolOptions/drawingToolOptions.component';
import { DrawingToolMode } from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { MapToolWrapperComponent } from '../../mapToolWrapper/mapToolWrapper.component';
import { DrawingToolFooterContainer } from './drawingToolFooter.container';
import { DrawingToolItemsManagementContainer } from './itemsManagement/drawingToolItemsManagement.container';

const headerStyle = css({
  width: '100%',
});

const drawingToolOptionsWrapperStyle = css({
  position: 'relative',
  minHeight: '100%',
});

const tileDimensions = 40;
const tilePadding = 5;

const headerMenuItemStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean}>) => css({
  alignItems: 'center',
  borderColor: isDisabled ? theme.iconColors.primary : undefined,
  color: isDisabled ? theme.iconColors.tertiary : theme.iconColors.highContrast,
  display: 'flex',
  height: tileDimensions,
  justifyContent: 'center',
  width: tileDimensions,
});

const imageSelectorWrapperStyle = ({ height }: { height: number }) => css({
  boxSizing: 'border-box',
  height,
  padding: '14px 4px 8px',
});

const imageSelectorStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.primary,
  fontSize: '24px',
  fontWeight: 900,
  display: 'flex',
  justifyContent: 'center',
});

const selectedImageStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  borderColor: isDisabled ? theme.iconColors.primary : theme.iconColors.backgroundQuaternary,
  ...(isDisabled ? { color: theme.textColors.tertiary } : {}),
});

const tipStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  cursor: 'default',
  height: '66px',
  fontSize: '14px',
  padding: '0 24px',
});

const tooltipStyle = css({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const addToolStyle = ({ theme }: ThemeProps) => css({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.highlight,
  border: `solid ${theme.lineColors.blue}`,
  borderWidth: '1px 0',
  cursor: 'default',
  height: ACCORDION_HEADER_SIZE_LARGE,
  padding: '0 16px',
  textTransform: 'uppercase',
});

const overlayStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.backgroundColors.overlay,
});

// create a handler for the 'fixed' positioned overlay. This prevents wrongly positioned tooltip when
// drawing tool has a scrollbar and we scroll down in it. Without this, the tooltip would move around which looks odd
const contentWrapperStyle = css({
  transform: 'translateX(0)',
});

const scrollbarContentStyle = css({
  height: '100%',
});

type DrawingToolProps = Readonly<{
  drawingToolMode: DrawingToolMode;
  isManagementDisabled: boolean;
  selectedDrawingTool: DrawingTool;
  showEditLockOverlay: boolean;
  toolOptions: Partial<DrawingToolOptions>;

  onDrawingToolChange: (tool: DrawingTool) => void;
  onToolOptionsChange: (options: Partial<DrawingToolOptions>) => void;
}>;

export const DrawingToolComponent: FC<DrawingToolProps> = (props) => {
  const [filesLoading, setFilesLoading] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();
  const { toolOptions } = props;

  const handleToolSelect = (selectedIds: ReadonlyArray<DrawingTool>): void => {
    const tool = selectedIds[0];

    if (tool === DrawingTool.Management && props.isManagementDisabled) {
      return;
    }

    if (tool) {
      props.onDrawingToolChange(tool);
    }
  };

  return (
    <MapToolWrapperComponent
      footer={(
        <DrawingToolFooterContainer />
      )}
      contentWrapperStyle={contentWrapperStyle}
      scrollbarContentStyle={scrollbarContentStyle}
      header={(
        <div css={headerStyle}>
          <div css={imageSelectorWrapperStyle({ height: 32 + 2 * tileDimensions + tilePadding })} >
            <ImageSelectorComponent
              css={imageSelectorStyle({ theme })}
              imageHeight={tileDimensions}
              imageWidth={tileDimensions}
              selectedImagesIds={[props.selectedDrawingTool]}
              images={DRAWING_TOOLS_LIST.map((drawingTool: DrawingTool) => ({
                id: drawingTool,
                render: () => {
                  const isDisabled = drawingTool === DrawingTool.Management && props.isManagementDisabled;

                  return (
                    <TooltipDeprComponent tooltipContent={isDisabled ? null : getDrawingToolItemLabel(drawingTool, t)}>
                      <span css={headerMenuItemStyle({ theme, isDisabled })}>
                        <FontAwesomeIcon icon={drawingToolIcons[drawingTool]} />
                      </span>
                    </TooltipDeprComponent>
                  );
                },
              }))}
              onSelectionChanged={handleToolSelect}
              paddingHorizontal={tilePadding}
              paddingVertical={tilePadding}
              selectedImageStyles={selectedImageStyle({
                theme,
                isDisabled: false,
              })}
            />
          </div>

          {props.selectedDrawingTool !== null && (
            <>
              <div css={tipStyle({ theme })}>
                {getDrawingToolItemDescription(props.selectedDrawingTool, t).map(tip => (
                  <span key={tip}>{tip}&nbsp;</span>
                ))}
              </div>

              <div css={addToolStyle({ theme })}>
                {props.selectedDrawingTool !== DrawingTool.Management ?
                  t('Add {{drawingToolName}}', {
                    drawingToolName: t(props.selectedDrawingTool),
                  })
                  : t('Manage Drawing Elements')
                }
              </div>
            </>
          )}
        </div>
      )}
    >
      {props.selectedDrawingTool === DrawingTool.Management && (
        <DrawingToolItemsManagementContainer />
      )}

      {(props.drawingToolMode !== DrawingToolMode.Inactive && props.selectedDrawingTool !== null) && (
        <div css={drawingToolOptionsWrapperStyle}>
          <DrawingToolOptionsComponent
            onToolOptionsChange={props.onToolOptionsChange}
            selectedTool={props.selectedDrawingTool}
            toolOptions={toolOptions}
            onFilesLoadingChange={setFilesLoading}
          />

          {props.showEditLockOverlay && (
            <TooltipDeprComponent
              tooltipContent={t('Elements cannot be added while editing mode is active')}
              css={tooltipStyle}
              placement={TooltipPlacement.Top}
            >
              <OverlayComponent css={overlayStyle({ theme })} />
            </TooltipDeprComponent>
          )}
        </div>
      )}

      {filesLoading && (
        <OverlayLoaderComponent css={overlayStyle({ theme })} />
      )}
    </MapToolWrapperComponent>
  );
};
