import {
  useCallback, useEffect, useState,
} from 'react';
import { copy } from '~/_shared/utils/collections/collections';
import { type Uuid } from '~/_shared/utils/createUuid';
import { ensureVertexNotOverlapping } from '~/_shared/utils/webgl/mapVertices.helpers';
import { type MapShapeInstance } from '../mapShapeModel';
import {
  type GhostPathOutline, useAddGhostOutlines,
} from './useAddGhostOutlines';

type UseEditMapShapeProps = {
  readonly initialShape: MapShapeInstance;
  readonly isPolygon: boolean;
};

export const useEditMapShape = ({ initialShape, isPolygon }: UseEditMapShapeProps) => {
  const { addGhostsToOutlines } = useAddGhostOutlines();

  const extendShapeWithGhosts = useCallback((shape: MapShapeInstance, isPolygon: boolean) => {
    const outlinesWithGhosts = addGhostsToOutlines(shape.outlines, isPolygon);

    return ({
      outlines: outlinesWithGhosts.outlines,
      lookUp: new Map(outlinesWithGhosts.outlines.map(outline => [outline.id, outline])),
      id: shape.id,
    });
  }, [addGhostsToOutlines]);

  const [shape, setShape] = useState(initialShape);
  const [shapeWithGhosts, setShapeWithGhosts] = useState(extendShapeWithGhosts(shape, isPolygon));

  useEffect(() => {
    setShapeWithGhosts(extendShapeWithGhosts(shape, isPolygon));
  }, [addGhostsToOutlines, extendShapeWithGhosts, initialShape.id, isPolygon, shape, shape.outlines]);

  const updateOutlinePosition = useCallback((outlineId: Uuid, lat: number, lng: number) => {
    const outline = shapeWithGhosts.lookUp.get(outlineId);
    if (!outline) {
      return;
    }

    const notOverlappingVertex = ensureVertexNotOverlapping({ lat, lng }, shape.outlines);

    const outlineWithNewPosition: GhostPathOutline = { ...outline, ...notOverlappingVertex };

    const updatedOutlineIndex = shape.outlines.findIndex(outline => outline.id === outlineId);
    if (updatedOutlineIndex < 0) {
      return;
    }

    const updatedOutlines = copy.andReplace(shape.outlines, updatedOutlineIndex, outlineWithNewPosition);
    setShape(prevState => ({ ...prevState, outlines: updatedOutlines }));
  }, [shape.outlines, shapeWithGhosts.lookUp]);

  const getPreviousOutlineId = useCallback((currentOutlineId: Uuid) => {
    const ghostIndex = shapeWithGhosts.outlines.findIndex(outline => outline.id === currentOutlineId);
    const previousOutline = shapeWithGhosts.outlines[ghostIndex - 1];

    return previousOutline?.id;
  }, [shapeWithGhosts.outlines]);

  const copyGhostOutlineToShape = useCallback((ghostOutlineId: Uuid) => {
    const previousOutlineId = getPreviousOutlineId(ghostOutlineId);
    const previousOutlineIndex = shape.outlines.findIndex(outline => outline.id === previousOutlineId);

    const ghostOutline = shapeWithGhosts.lookUp.get(ghostOutlineId);

    if (previousOutlineIndex === -1 || !ghostOutline) {
      return;
    }

    const copiedOutline = {
      id: ghostOutline.id,
      lat: ghostOutline.lat,
      lng: ghostOutline.lng,
    };

    setShape(prevState => {
      prevState.outlines.splice(previousOutlineIndex + 1, 0, copiedOutline);

      return {
        ...prevState,
        outlines: [...prevState.outlines],
      };
    });
  }, [getPreviousOutlineId, shape.outlines, shapeWithGhosts.lookUp]);

  const deleteOutline = useCallback((outlineId: Uuid) => {
    setShape(prev => {
      const outlineIndex = prev.outlines.findIndex(outline => outline.id === outlineId);
      if (outlineIndex === -1) {
        return prev;
      }

      const updatedOutlines = [...prev.outlines.slice(0, outlineIndex), ...prev.outlines.slice(outlineIndex + 1)];

      return {
        id: prev.id,
        outlines: updatedOutlines,
        circleAreas: [],
      };
    });
  }, []);

  return {
    shape,
    shapeWithGhosts,
    copyGhostOutlineToShape,
    updateOutlinePosition,
    deleteOutline,
  };
};
