import {
  createSelectorCreator,
  weakMapMemoize,
} from 'reselect';
import type { AppState } from '~/store/app.store';

/**
 * Creates a tree of `WeakMap`-based cache nodes based on the identity of the arguments it's been called with.
 * This allows `weakMapMemoize` to have an effectively infinite cache size.
 * Cache results will be kept in memory as long as references to the arguments still exist,
 * and then cleared out as the arguments are garbage-collected.
 *
 * Tree root is the function cache node, which contains a WeakMap of cache nodes for the first arguments.
 * Argument cache node contains another WeakMap of next arguments, until the last one where the tree is terminated.
 * The leaf (terminated node) contains the cached selector result. When any cache node is removed from weak map (because the argument is garbage-collected),
 * then the entire chain till the terminated node is removed.
 * **/
export const createStateSelector = createSelectorCreator({
  memoize: weakMapMemoize,
  argsMemoize: weakMapMemoize,
}).withTypes<AppState>();
