import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import {
  getSpreadsheetDatasetOptimizedTerritories,
  type SpreadsheetDatasetOptimizedTerritoriesRequestParams,
} from '~/store/boundaries/boundaries.repository';

export const useGetDatasetSpreadsheetOptimizedTerritories = () => {
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, params: SpreadsheetDatasetOptimizedTerritoriesRequestParams) =>
    getSpreadsheetDatasetOptimizedTerritories(clientId, params)
  );

  const getDatasetSpreadsheetOptimizedTerritoriesRequest = useCallback(({
    clientId,
    spreadsheetId,
    zips,
    states,
  }: {
    clientId: number;
    spreadsheetId: number;
    zips?: string;
    states?: string;
  }
  ) => {
    return invokeAjax(clientId, {
      spreadsheetId,
      zips,
      states,
    });
  }, [invokeAjax]);

  return {
    isLoading,
    getDatasetSpreadsheetOptimizedTerritoriesRequest,
  };
};
