import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {
  faBuildings, faFaceGrinWide, faFaceSunglasses, faLockAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useMemo, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import { PasswordStrengthIndicatorComponent } from '~/_shared/components/passwordStrengthIndicator/passwordStrengthIndicator.component';
import { TermsAndPrivacyNoticeComponent } from '~/_shared/components/termsAndPrivacyNotice/termsAndPrivacyNotice.component';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import {
  Breakpoint, mq,
} from '~/_shared/style.mixins';
import { FormFieldType } from '~/_shared/utils/form/formValidation.helpers';
import { useFormFieldValidation } from '~/_shared/utils/form/useFormFieldValidation';
import { useTranslation } from '~/_shared/utils/hooks';
import { type UserRegisterErrors } from '~/store/userData/repository/userData.repository';
import { authorizationPagesErrorStyle } from '../../authorizationPages.styles';
import { AuthorizationSubmitButtonComponent } from '../../submitButton/authorizationSubmitButton.component';
import { validateRegisterForm } from './registerForm.helpers';
import { type UserRegisterData } from './useUserRegister';

const ITEMS_GAP = 15;

const alertStyle = css({
  marginTop: 10,
});

const headingPrimaryStyle = css({
  fontSize: 24,
  margin: 0,
});

const formBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: ITEMS_GAP,
});

const headingSecondaryStyle = css({
  fontSize: 16,
  fontWeight: 700,
  margin: '8px 0 30px',
});

const linksWrapperStyle = css({
  fontSize: 14,
  marginTop: 10,
});

const passwordWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const rowWithColumnsStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: ITEMS_GAP,
  [mq(Breakpoint.mid)]: {
    flexDirection: 'row',
    gap: 0,
    justifyContent: 'space-between',
  },
});

const columnStyle = css({
  width: '100%',
  [mq(Breakpoint.mid)]: {
    width: '49%',
  },
});

const passwordStrengthWrapperStyle = css({
  fontSize: 14,
  width: '80%',
  margin: '0 auto',
});

const optionalLabelStyle = css({
  fontSize: 12,
});

const buttonWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',

  [mq(Breakpoint.mid)]: {
    flexDirection: 'row',
  },
});

const alreadyUserSectionStyle = css({
  fontSize: 18,
  fontWeight: 700,
  textAlign: 'start',
  width: '100%',

  [mq(Breakpoint.mid)]: {
    width: 'unset',
  },
});

const sigUpButtonStyle = css({
  width: '85%',

  [mq(Breakpoint.mid)]: {
    width: 'unset',
  },
});

const alreadyUserLinkStyle = css({
  fontSize: 18,
});

type RegisterFormProps = Readonly<{
  errorMessage?: string;
  errors?: UserRegisterErrors | null;
  isLoading: boolean;

  closeErrorMessage: () => void;
  onSubmit: (data: UserRegisterData) => void;
}>;

export const RegisterFormComponent: FC<RegisterFormProps> = (props) => {
  const [companyName, setCompanyName] = useState('');
  const [t] = useTranslation();

  const { value: firstName, onBlur: onFirstNameBlur, onChange: onFirstNameChange, errors: firstNameErrors } = useFormFieldValidation({
    type: FormFieldType.Required,
    requiredFieldErrorMessage: t('First name must not be empty'),
  });
  const { value: lastName, onBlur: onLastNameBlur, onChange: onLastNameChange, errors: lastNameErrors } = useFormFieldValidation({
    type: FormFieldType.Required,
    requiredFieldErrorMessage: t('Last name must not be empty'),
  });
  const { value: email, onBlur: onEmailBlur, onChange: onEmailChange, errors: emailErrors } = useFormFieldValidation({
    type: FormFieldType.Email,
    additionalErrors: props.errors?.email,
  });
  const { value: confirmEmail, onBlur: onConfirmEmailBlur, onChange: onConfirmEmailChange, errors: confirmEmailErrors } = useFormFieldValidation({
    type: FormFieldType.ConfirmEmail,
    confirmValue: email,
  });
  const { value: password, onBlur: onPasswordBlur, onChange: onPasswordChange, errors: passwordErrors } = useFormFieldValidation({
    type: FormFieldType.Password,
    additionalErrors: props.errors?.password,
  });
  const { value: confirmPassword, onBlur: onConfirmPasswordBlur, onChange: onConfirmPasswordChange, errors: confirmPasswordErrors } = useFormFieldValidation({
    type: FormFieldType.ConfirmPassword,
    confirmValue: password,
  });

  const isFormValid = useMemo(() =>
    validateRegisterForm({ companyName, firstName, lastName, email, password, passwordConfirmation: confirmPassword }) &&
    email === confirmEmail,
  [companyName, confirmPassword, email, firstName, lastName, password, confirmEmail]
  );

  const onCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    const userRegisterData: UserRegisterData = {
      firstName,
      lastName,
      passwordConfirmation: confirmPassword,
      email,
      password,
      companyName,
    };

    if (!validateRegisterForm(userRegisterData)) {
      return;
    }

    props.onSubmit(userRegisterData);
  };

  return (
    <form
      onSubmit={onFormSubmit}
      autoComplete="off"
    >
      <p css={headingPrimaryStyle}>{t('Start your free, no risk, 10 day trial.')}</p>
      <p css={headingSecondaryStyle}>{t('No credit card required. No surprises. Just results.')}</p>

      <div css={formBodyStyle}>
        <div css={rowWithColumnsStyle}>
          <div css={columnStyle}>
            <TextInputAuthComponent
              label={t('First Name')}
              placeholder={t('First Name')}
              autoComplete="given-name"
              value={firstName}
              onChange={onFirstNameChange}
              onBlur={onFirstNameBlur}
              hasError={firstNameErrors.length > 0}
              icon={faFaceGrinWide}
              isDisabled={props.isLoading}
            />

            {firstNameErrors.length > 0 && (
              <FormErrorMessageComponent
                css={authorizationPagesErrorStyle}
                messages={firstNameErrors}
              />
            )}
          </div>

          <div css={columnStyle}>
            <TextInputAuthComponent
              label={t('Last Name')}
              placeholder={t('Last Name')}
              autoComplete="family-name"
              value={lastName}
              onChange={onLastNameChange}
              onBlur={onLastNameBlur}
              hasError={lastNameErrors.length > 0}
              icon={faFaceSunglasses}
              isDisabled={props.isLoading}
            />

            {lastNameErrors.length > 0 && (
              <FormErrorMessageComponent
                css={authorizationPagesErrorStyle}
                messages={lastNameErrors}
              />
            )}
          </div>
        </div>

        <div css={rowWithColumnsStyle}>
          <div css={columnStyle}>
            <TextInputAuthComponent
              label={t('Email')}
              placeholder={t('Email')}
              value={email}
              onChange={onEmailChange}
              onBlur={onEmailBlur}
              hasError={emailErrors.length > 0}
              icon={faEnvelope}
              isDisabled={props.isLoading}
            />

            {emailErrors.length > 0 && (
              <FormErrorMessageComponent
                css={authorizationPagesErrorStyle}
                messages={emailErrors}
              />
            )}
          </div>

          <div css={columnStyle}>
            <TextInputAuthComponent
              label={t('Confirm Email')}
              placeholder={t('Confirm Email')}
              value={confirmEmail}
              onChange={onConfirmEmailChange}
              onBlur={onConfirmEmailBlur}
              hasError={confirmEmailErrors.length > 0}
              icon={faEnvelope}
              isDisabled={props.isLoading}
            />
            {confirmEmailErrors.length > 0 && (
              <FormErrorMessageComponent
                css={authorizationPagesErrorStyle}
                messages={confirmEmailErrors}
              />
            )}
          </div>
        </div>

        <div css={passwordWrapperStyle}>
          <div>
            <TextInputAuthComponent
              label={t('Password')}
              placeholder={t('Password')}
              value={password}
              type="password"
              autoComplete="new-password"
              onChange={onPasswordChange}
              onBlur={onPasswordBlur}
              hasError={passwordErrors.length > 0}
              icon={faLockAlt}
              isDisabled={props.isLoading}
            />

            {passwordErrors.length > 0 && (
              <FormErrorMessageComponent
                css={authorizationPagesErrorStyle}
                messages={passwordErrors}
              />
            )}
          </div>

          <div css={passwordStrengthWrapperStyle}>
            <PasswordStrengthIndicatorComponent password={password} />
          </div>
        </div>

        <div>
          <TextInputAuthComponent
            label={t('Confirm Password')}
            placeholder={t('Confirm Password')}
            value={confirmPassword}
            type="password"
            autoComplete="new-password"
            onChange={onConfirmPasswordChange}
            onBlur={onConfirmPasswordBlur}
            hasError={confirmPasswordErrors.length > 0}
            icon={faLockAlt}
            isDisabled={props.isLoading}
          />

          {confirmPasswordErrors.length > 0 && (
            <FormErrorMessageComponent
              css={authorizationPagesErrorStyle}
              messages={confirmPasswordErrors}
            />
          )}
        </div>

        <div css={rowWithColumnsStyle}>
          <div css={columnStyle}>
            <TextInputAuthComponent
              label={(
                <span>
                  {t('Company Name') + ' '}
                  <span css={optionalLabelStyle}>{t('(optional)')}</span>
                </span>
              )}
              placeholder={t('Company Name')}
              value={companyName}
              onChange={onCompanyNameChange}
              icon={faBuildings}
              isDisabled={props.isLoading}
            />
          </div>
        </div>

        <TermsAndPrivacyNoticeComponent css={linksWrapperStyle} />

        <div css={buttonWrapperStyle}>

          <p css={alreadyUserSectionStyle}>
            {t('Already a user?') + ' '}
            <LinkComponent
              css={alreadyUserLinkStyle}
              path={LOGIN_ROUTE}
            >
              {t('Log in here')}
            </LinkComponent>
          </p>

          <div css={sigUpButtonStyle}>
            <AuthorizationSubmitButtonComponent
              isLoading={props.isLoading}
              isDisabled={!isFormValid}
            >
              {t('Create Account')}
            </AuthorizationSubmitButtonComponent>
          </div>

        </div>
      </div>

      {props.errorMessage && !props.errors && (
        <AlertComponent
          css={alertStyle}
          type="danger"
          onClose={props.closeErrorMessage}
        >
          {props.errorMessage}
        </AlertComponent>
      )}
    </form>
  );
};
