import { css } from '@emotion/react';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { memo } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { LabelOrMarkerVisualizerContainer } from '~/_shared/labelOrMarkerVisualizer/labelOrMarkerVisualizer.container';
import type { Theme } from '~/_shared/themes/theme.model';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import type { UniqueGroup } from '~/_shared/types/grouping/grouping';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  GROUPING_ITEM_ROW_HEIGHT, GroupingItemBodyRowWrapperComponent,
} from '~/grouping/components/groupingItemBodyRowWrapper.component';

const ICON_SIZE = 20;
const MARKER_CONTAINER_DIMENSIONS = { height: GROUPING_ITEM_ROW_HEIGHT, width: GROUPING_ITEM_ROW_HEIGHT };
const MARKER_DIMENSIONS = { height: GROUPING_ITEM_ROW_HEIGHT - 5, width: GROUPING_ITEM_ROW_HEIGHT - 5 };

const groupDescriptionStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: `calc(100% - ${ICON_SIZE}px)`,
});

const checkboxStyle = css({
  width: 16,
  height: 16,
  paddingRight: 4,
});

const markerWrapperStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions }) => css({
  alignItems: 'center',
  display: 'flex',
  height: dimensions.height,
  justifyContent: 'center',
});

const groupNameStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const iconStyle = (theme: Theme) => css({
  fontSize: ICON_SIZE,
  '&:hover': {
    color: theme.iconColors.contrast,
  },
});

type GroupingItemBodyRowProps = Readonly<{
  group: UniqueGroup;
  isFilteringDisabled: boolean;
  isMarkerEditable: boolean;
  isSelected: boolean;
  markerSettings?: MarkerSettings;
  onEditMarker: (group: UniqueGroup) => void;
  onSelectGroup: (group: UniqueGroup) => void;
}>;

/**
 * Use stable (memoized) props for this component.
 * These are potentially rendered in great numbers and as such need to be stable.
 */
export const GroupingItemBodyRowComponent = memo<GroupingItemBodyRowProps>(({
  group,
  isFilteringDisabled,
  isMarkerEditable,
  isSelected,
  markerSettings,
  onEditMarker,
  onSelectGroup,
}: GroupingItemBodyRowProps) => {
  const [t] = useTranslation();
  const checkboxId = `GroupingItemCheckbox${group.id}`;

  return (
    <GroupingItemBodyRowWrapperComponent>
      <label
        css={groupDescriptionStyle}
        htmlFor={checkboxId}
        data-testid={`grouping-item-checkbox-${group.id.toString().toLowerCase()}`}
      >
        {!isFilteringDisabled && (
          <CheckboxComponent
            id={checkboxId}
            css={checkboxStyle}
            checkedSetter={() => onSelectGroup(group)}
            isChecked={isSelected}
          />
        )}

        {markerSettings && (
          <div css={markerWrapperStyle({ dimensions: MARKER_CONTAINER_DIMENSIONS })}>
            <LabelOrMarkerVisualizerContainer
              maxDimensions={MARKER_DIMENSIONS}
              maxSizeEasing
              markerSettings={markerSettings}
            />
          </div>
        )}

        <div css={groupNameStyle}>
          {group.name}
        </div>
      </label>

      {isMarkerEditable && (
        <TooltipComponent tooltipContent={t('Open Group Marker Settings')}>
          <FontAwesomeIcon
            css={iconStyle}
            icon={faMapMarkerAlt}
            onClick={() => onEditMarker(group)}
          />
        </TooltipComponent>
      )}
    </GroupingItemBodyRowWrapperComponent>
  );
});
