import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import {
  type AnnouncementResponse,
  getAnnouncements,
  markAsSeen,
  type MarkAsSeenData,
} from '../../announcements/announcements.repository';
import { type UserDataAction } from '../userData/userData.action';
import { USER_GET_DATA_SUCCESS } from '../userData/userData.actionTypes';
import { type AnnouncementsAction } from './announcements.action';
import {
  announcementsFetchDataError, announcementsFetchDataRequest,
  announcementsFetchDataSuccess,
  announcementsMarkAsSeenError,
  announcementsMarkAsSeenSuccess,
} from './announcements.actionCreators';
import {
  ANNOUNCEMENT_MARK_AS_SEEN_REQUEST, ANNOUNCEMENTS_FETCH_DATA_REQUEST,
} from './announcements.actionTypes';
import { announcementsButtonStyleResponseToViewModel } from './announcements.helpers';

export function* announcementsSagas() {
  yield all([
    announcementsFetchSaga(),
    announcementMarkAsSeenSaga(),
  ]);
}

function* announcementsFetchSaga() {
  yield takeLatest(ANNOUNCEMENTS_FETCH_DATA_REQUEST, onAnnouncementsFetchDataRequest);
  yield takeLatest(USER_GET_DATA_SUCCESS, fetchAnnouncements);
}

function* fetchAnnouncements(action: PickAction<UserDataAction, typeof USER_GET_DATA_SUCCESS>) {
  if (!action.payload.options.fetchAnnouncements) {
    return;
  }

  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const userId: number | null = yield select<number | null>(state => state.userData.id);

  if (clientId === null || userId === null) {
    return;
  }

  yield put(announcementsFetchDataRequest(clientId, userId));
}

function* onAnnouncementsFetchDataRequest(action: PickAction<AnnouncementsAction, typeof ANNOUNCEMENTS_FETCH_DATA_REQUEST>) {
  try {
    const announcementsResponse: { data: AnnouncementResponse[] } = yield call(getAnnouncements, action.payload.clientId, action.payload.userId);
    yield put(announcementsFetchDataSuccess(announcementsResponse.data.map((item) => ({
      ...(item.button ? {
        button: {
          icon: item.button.icon || undefined,
          link: item.button.link,
          style: announcementsButtonStyleResponseToViewModel(item.button.style),
          title: item.button.title,
        },
      } : undefined),
      colors: {
        color: item.colors.overriden_color || item.colors.color,
        backgroundColor: item.colors.backgroundColor,
        iconColor: item.colors.icon_color || undefined,
      },
      icon: item.icon,
      id: item.id,
      image: undefined, //currently not returned by the backend, but FE support is in place
      message: item.message,
      messageFontSize: item.message_font_size,
      subject: item.subject,
      titleFontSize: item.title_font_size,
    }))));
  }
  catch (e) {
    yield put(announcementsFetchDataError());
  }
}

function* onAnnouncementMarkAsSeenRequest(action: PickAction<AnnouncementsAction, typeof ANNOUNCEMENT_MARK_AS_SEEN_REQUEST>) {
  try {
    const announcementMarkAsSeenResponse: { data: MarkAsSeenData } = yield call(markAsSeen, action.payload.clientId, action.payload.userId, action.payload.announcementId);
    yield put(announcementsMarkAsSeenSuccess(announcementMarkAsSeenResponse.data));
  }
  catch (e) {
    yield put(announcementsMarkAsSeenError());
  }
}

function* announcementMarkAsSeenSaga() {
  yield takeLatest(ANNOUNCEMENT_MARK_AS_SEEN_REQUEST, onAnnouncementMarkAsSeenRequest);
}
