import {
  ID_OF_NUMERICAL_MARKER,
  ID_OF_SECONDARY_GROUP_TEXT_MARKER,
} from '~/_shared/utils/markers/markerVisualSettings.constants';

export const getOpacityForDropdownMarkers = (markerId: number) => {
  switch (markerId) {
    case ID_OF_NUMERICAL_MARKER:
    case ID_OF_SECONDARY_GROUP_TEXT_MARKER: {
      return 0.5;
    }

    default: {
      return 1;
    }
  }
};
