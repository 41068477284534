import { css } from '@emotion/react';
import { type FC } from 'react';
import settingsMapPlaceholderImage from '../../../../../assets/images/boundarySettingsMapPlaceholder.svg';
import { rgbColorToString } from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { useTranslation } from '../../../_shared/utils/hooks';

type LineExampleProps = Readonly<{
  lineWidth: number;
  lineColor: ColorResult;
  fillColor?: ColorResult;
}>;

const mapWrapperStyle = css({
  position: 'relative',
});

const mapImageStyle = css({
  maxWidth: '100%',
});

const lineExampleStyle = ({ lineWidth, lineColor, fillColor }: { lineWidth: number; lineColor: string; fillColor?: string }) => css({
  position: 'absolute',
  border: `${lineWidth}px solid ${lineColor}`,
  background: fillColor,
  top: 25,
  left: 25,
  bottom: 25,
  right: 25,
});

export const LineExampleComponent: FC<LineExampleProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={mapWrapperStyle}>
      <div css={lineExampleStyle({ lineWidth: props.lineWidth, lineColor: rgbColorToString(props.lineColor.rgb), fillColor: props.fillColor && rgbColorToString(props.fillColor.rgb) })} />
      <img
        css={mapImageStyle}
        src={settingsMapPlaceholderImage}
        alt={t('Map Placeholder')}
      />
    </div>
  );
};
