import {
  type FC,
  memo,
  useMemo,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { MapShapePath } from './mapShapePath.component';

export type MapShapePolylineVisualsConfig = {
  readonly color: WebglColor;
  readonly style: 'solid' | 'dash';
  readonly width: number;
};

type MapShapeLineProps = {
  end: LatLng;
  id: string;
  start: LatLng;
  visuals: MapShapePolylineVisualsConfig;

  onClick?: MapObjectClickEvent;
  onDragEnd?: () => void;
  onDragMove?: (latLng: LatLng) => void;
  onDragStart?: (latLng: LatLng) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
};

const MapShapeLine: FC<MapShapeLineProps> = (props) => {
  const points = useMemo(() => [props.start, props.end], [props.start, props.end]);

  return (
    <MapShapePath
      points={points}
      {...props}
    />
  );
};

const pureComponent = memo(MapShapeLine);
export { pureComponent as MapShapeLine };
