import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useTransition,
} from '@react-spring/web';
import {
  type FC, type ReactNode, useState,
} from 'react';
import type Scrollbar from 'react-scrollbars-custom';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { MapToolWrapperComponent } from '../../../sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';
import { useTheme } from '../../themes/theme.hooks';
import { type Theme } from '../../themes/theme.model';
import { type ThemeProps } from '../../types/themeProps';
import { nameOf } from '../../utils/nameOf';
import { defaultMultilevelMenuItemRenderer } from './defaultMultilevelMenuItemRenderer';
import {
  type BreadcrumbItem, MultilevelMenuBreadcrumbs,
} from './multilevelMenuBreadcrumbs.component';

type MultilevelMenuItemCommon = Readonly<{
  name: string;
  indent?: number;
  renderSelf?: (name: string, onClick: () => void, index: number) => ReactNode;
  style?: SerializedStyles;
  onClick?: () => void;
}>;

type MultilevelMenuItemFinal = Readonly<MultilevelMenuItemCommon & {
  renderChild: () => ReactNode;
}>;

type MultilevelMenuItemMiddle = Readonly<MultilevelMenuItemCommon & {
  children: ReadonlyArray<MultilevelMenuItemMiddle | MultilevelMenuItemFinal>;
}>;

export type MultilevelMenuItem = MultilevelMenuItemMiddle | MultilevelMenuItemFinal;

type MultilevelMenuProps = Readonly<{
  className?: string;
  breadcrumbsStyle?: SerializedStyles;
  footer?: ReactNode;
  items: ReadonlyArray<MultilevelMenuItem>;
  rootBreadcrumbName: string;

  onBack?: () => void;
}>;

const isFinalItem = (item: MultilevelMenuItem): item is MultilevelMenuItemFinal =>
  item.hasOwnProperty(nameOf<MultilevelMenuItemFinal>('renderChild'));

const renderMenu = (items: ReadonlyArray<MultilevelMenuItem>, onClick: (index: number) => void, theme: Theme) =>
  items.map((item, index) =>
    item?.renderSelf?.(item.name, () => onClick(index), index) ?? defaultMultilevelMenuItemRenderer({
      theme,
      name: item.name,
      onClick: () => {
        onClick(index);
        item.onClick?.();
      },
      index,
      style: item.style,
      indent: item.indent,
    })
  );

const findCurrentPage = (root: MultilevelMenuItem, path: ReadonlyArray<number>): MultilevelMenuItem =>
  path.reduce((currentPage: MultilevelMenuItem, currentIndex) =>
    isFinalItem(currentPage) ? currentPage : currentPage.children[currentIndex] || currentPage,
  root,
  );

const renderPage = (page: MultilevelMenuItem, onClick: (index: number) => void, theme: Theme): ReactNode =>
  isFinalItem(page) ? page.renderChild() : renderMenu(page.children, onClick, theme);

const createBreadcrumbs = (
  root: MultilevelMenuItem,
  path: ReadonlyArray<number>,
  setNewPath: (newPath: ReadonlyArray<number>) => void,
  onClick?: () => void,
): ReadonlyArray<BreadcrumbItem> =>
  [...path, 0].map((_, index) => {
    const item = findCurrentPage(root, path.slice(0, index));

    return { name: item.name, onClick: () => {
      setNewPath(path.slice(0, index));
      onClick?.();
    } };
  });

const displayGridStyle = css(`
  display: -ms-grid;
  display: grid;
`);

const rootStyle = css(displayGridStyle, {
  gridTemplateColumns: '100%',
  msGridColumns: '100%',
  gridTemplateRows: '0 100%',
  msGridRows: '0 100%',
  overflow: 'hidden',
});

const secondaryPageCommonStyle = css({
  position: 'relative',
  gridArea: '2/1/2/1',
  msGridRow: 2,
  msGridColumn: 1,
});

const breadCrumbsGroupStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const backIconStyle = ({ theme }: ThemeProps) => css({
  borderRadius: 5,
  cursor: 'pointer',
  marginLeft: 5,
  padding: 5,

  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const secondaryPageAwayStyle = { left: -100 };
const secondaryPageOnStyle = { left: 0 };

export const MultiLevelMenuComponent: FC<MultilevelMenuProps> = props => {
  const theme = useTheme();
  const [path, setPath] = useState<ReadonlyArray<number>>([]);
  const [scrollbarRef, setScrollbarRef] = useState<Scrollbar>();
  const transitions = useTransition([path], {
    keys: p => JSON.stringify(p),
    from: secondaryPageAwayStyle,
    enter: secondaryPageOnStyle,
    leave: secondaryPageAwayStyle,
  });

  const rootPage = { name: props.rootBreadcrumbName, children: props.items };

  const handleClickItem = (index: number, item: ReadonlyArray<number>) => {
    scrollbarRef?.scrollToTop();
    setPath([...item, index]);
  };

  return (
    <MapToolWrapperComponent
      className={props.className}
      footer={props.footer}
      getScrollbarReference={setScrollbarRef}
      header={(
        <div css={breadCrumbsGroupStyle} >
          {props.onBack && (
            <FontAwesomeIcon
              css={backIconStyle({ theme })}
              icon={faChevronLeft}
              onClick={props.onBack}
            />
          )}
          <MultilevelMenuBreadcrumbs
            breadcrumbs={createBreadcrumbs(rootPage, path, setPath, scrollbarRef?.scrollToTop)}
            wrapperStyle={props.breadcrumbsStyle}
          />
        </div>
      )}
    >
      <div css={rootStyle}>
        {transitions((props, item) =>
          (
            <animated.div
              css={secondaryPageCommonStyle}
              style={{ ...props, left: props.left?.to(l => `${l}%`) }}
            >
              {renderPage(findCurrentPage(rootPage, item), i => handleClickItem(i, item), theme)}
            </animated.div>
          ))}
      </div>
    </MapToolWrapperComponent>
  );
};
