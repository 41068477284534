import { css } from '@emotion/react';
import {
  type ComponentProps, useCallback, useState,
} from 'react';
import { type FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { TwoFAConfirmationComponent } from '~/_shared/components/accountSettings/modalDialogs/components/TwoFAConfirmation.component';
import { useTwoFactorMethod } from '~/_shared/components/accountSettings/twoFactorModal/useTwoFactorSettingData';
import { EmailSentAnimatedTextComponent } from '~/_shared/components/emailSent/emailSentAnimatedText.component';
import { UserTwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';

const mfaSentStyle = (theme: Theme) => css({
  color: theme.textColors.success,
  cursor: 'default',
  display: 'flex',
  gap: '8px',
});

const mfaFieldWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

type MFAFieldProps = Pick<ComponentProps<typeof FormTextInputComponent>, 'onChange'|'setInputRef'|'value'|'isDisabled'> &
Pick<ComponentProps<typeof TwoFAConfirmationComponent>, 'onSubmit'> &
Readonly<{
  isResendEnabled: boolean;
  onResendEmail: () => void;
}>;

export const MFAField = ({
  isDisabled,
  isResendEnabled,
  onChange,
  onResendEmail,
  onSubmit,
  setInputRef,
  value,
}: MFAFieldProps) => {
  const [t] = useTranslation();
  const { selectedTwoFactorMode } = useTwoFactorMethod();
  const mfaByEmail = selectedTwoFactorMode === UserTwoFactorMode.TwoFactorViaEmail;
  const mfaByGoogle = selectedTwoFactorMode === UserTwoFactorMode.TwoFactorViaGoogle;
  const [preventFastClicks, setPreventFastClicks] = useState(false);

  const onResend = useCallback(() => {
    setPreventFastClicks(true);
    onResendEmail();
  }, [onResendEmail]);

  const enableResend = useCallback(() => {
    setPreventFastClicks(false);
  }, [setPreventFastClicks]);

  return (
    <div css={mfaFieldWrapperStyle}>
      <TwoFAConfirmationComponent
        isDisabled={isDisabled}
        onChange={onChange}
        onSubmit={onSubmit}
        setInputRef={setInputRef}
        value={value}
      />
      {mfaByEmail && (
        <EmailSentAnimatedTextComponent
          onAnimationEnd={enableResend}
          onResend={onResend}
          playAnimation={preventFastClicks && !isResendEnabled}
          text={t('A 2FA code was sent to your email address')}
        />
      )}
      {mfaByGoogle && (
        <div css={mfaSentStyle}>
          {t('Please enter the one-time code found in your Google Authenticator')}
        </div>
      )}
    </div>
  );
};
