import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const iconWrapperStyle = (isDropdownOpen: boolean, isDisabled?: boolean) => css({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: isDropdownOpen ? 'rotate(-180deg)' : undefined,
  transition: 'transform .2s',
  width: 30,
  height: '100%',
  marginLeft: 'auto',
  cursor: isDisabled ? 'default' : 'pointer',
});

export const autocompleteTriggerInputStyle = (isOpen: boolean) => css({
  borderRadius: isOpen ? '4px 4px 0 0' : 4,
});

export const autocompleteTextInputStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flexBasis: '30%',
});

export const clearIconStyle = ({ isClearable }: {isClearable: boolean}) => css({
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  display: 'flex',
  cursor: 'pointer',
  visibility: isClearable ? 'visible' : 'hidden',
  opacity: isClearable ? 1 : 0,
});

export const DropdownTrigger = styled.div(({ disabled }: { disabled?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0',
  transition: 'background .1s',
  outline: 'none',
  width: '100%',
  border: 'none',
  background: 'none',
  cursor: disabled ? 'initial' : 'text',
  opacity: disabled ? 0.7 : 1,
}));
