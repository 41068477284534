import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIsLocationListOpen } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useTranslation } from '../../../../_shared/utils/hooks';
import {
  activeMapElementsClearState,
  activeMapElementsSetActiveLocationList,
} from '../../../../store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideLocationListMenuItemContainer: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isLocationListOpen = useIsLocationListOpen();

  const toggleListVisibility = () => {
    if (isLocationListOpen) {
      dispatch(activeMapElementsClearState());
    }
    dispatch(activeMapElementsSetActiveLocationList());
  };

  return (
    <RightSideMapToolsMenuItem
      icon={faKey}
      active={isLocationListOpen}
      onClick={toggleListVisibility}
      tooltip={t('Location List')}
    />
  );
};
