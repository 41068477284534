import { css } from '@emotion/react';
import { useMemo } from 'react';
import { ImageComponent } from '~/_shared/components/image/image.component';
import { ImageSelectorComponent } from '~/_shared/components/imageSelector/imageSelector.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { OverlayLoaderComponent } from '../../../_shared/components/overlay/overlayLoader.component';
import { BaseMapThemeName } from './baseMap/baseMapThemes/baseMapThemes.enums';
import { baseMapImages } from './baseMap/useBaseMapSettings';

const modalContainerStyle = css({
  maxWidth: '600px',
  padding: 0,
});

const modalContentStyle = css({ padding: '20px 4px 4px 20px' });

const imageSelectorStyle = ({ imageNum, imageHeight, padding }: {imageNum: number; imageHeight: number; padding: number}) => css({
  height: `${Math.ceil(imageNum / 3) * (imageHeight + padding)}px`,
  width: '600px',
});

const fullDimensionStyle = css({
  height: '100%',
  width: '100%',
});

const imageHolderStyle = css({
  height: '100%',
  position: 'relative',
  width: '100%',
});

const imageLabelStyle = ({ theme, isSelected }: ThemeProps<{isSelected: boolean}>) => css({
  background: isSelected ? theme.alertColors.infoPrimary : theme.modalColors.titleBackground,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.modalColors.titleText,
  left: '2px',
  opacity: isSelected ? 1 : 0.7,
  padding: '10px',
  position: 'absolute',
  top: '2px',
});

const selectedImageStyle = ({ theme }: ThemeProps) => css({
  border: `5px solid ${theme.borderColors.activeItem}`,
  borderRadius: 8,
});

type MapStylesProps = Readonly<{
  isOpen: boolean;
  isLoading: boolean;
  selectedId: number;

  onClose: () => void;
  onImageSelect: (id: number) => void;
}>;

export const MapStylesComponent: React.FC<MapStylesProps> = props => {
  const imageDimension = 176;
  const imagePadding = 16;
  const [t] = useTranslation();
  const theme = useTheme();

  const mapStylesImages = useMemo(() => {
    return baseMapImages.map(image => ({
      id: image.id,
      render: () => (
        <div
          css={imageHolderStyle}
          key={image.id}
        >
          <div css={imageLabelStyle({ theme, isSelected: image.id === props.selectedId })} >
            {BaseMapThemeName[image.id]}
          </div>
          <ImageComponent
            css={fullDimensionStyle}
            src={image.url}
          />
        </div>
      ),
    }));
  }, [props.selectedId, theme]);

  return (
    <ModalComponent
      contentStyle={modalContentStyle}
      containerStyle={modalContainerStyle}
      caption={t('Map styles')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <div
        css={imageSelectorStyle({
          imageHeight: imageDimension, imageNum: mapStylesImages.length, padding: imagePadding,
        })}
      >
        <ImageSelectorComponent
          imageHeight={imageDimension}
          imageWidth={imageDimension}
          images={mapStylesImages}
          onSelectionChanged={([id]) => id !== undefined && props.onImageSelect(id)}
          paddingHorizontal={imagePadding}
          paddingVertical={imagePadding}
          selectedImageStyles={selectedImageStyle({ theme })}
          selectedImagesIds={[props.selectedId]}
        />
      </div>
    </ModalComponent>
  );
};
