import {
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import {
  releaseZIndex,
  reserveZIndex,
} from '../../store/frontendState/mapZIndexes/mapZIndexes.actionCreators';
import {
  calculateZIndex,
  type ZIndexedEntityWithoutSubEntities,
} from './zIndexRanges';

/**
 * Hook can be used with a specific combination of entity and id params in only one component at the same time.
 * On unmout the index is released. If two components would use the hook with the same parameters, one component
 * could trigger release while other is still mounted. The mounted one would reserve another index for itself, however,
 * one effect cycle would be stuck with default z-index. That is maximum z-index in the respective entity's range.
 */
export const useZIndex = (id: string | number | undefined, entity: ZIndexedEntityWithoutSubEntities) => {
  const layerEntityRef = useRef(entity);
  const layerEntity = layerEntityRef.current;
  const slot = useSelector(s => id ? s.frontendState.mapZIndexes[layerEntity].usedZIndexes.get(id.toString()) : null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && slot === undefined) {
      dispatch(reserveZIndex(id.toString(), layerEntity));
    }
  }, [id, dispatch, slot, layerEntity]);

  useEffect(() => () => {
    if (id) {
      dispatch(releaseZIndex(id.toString(), layerEntity));
    }
  }, [layerEntity, id, dispatch]);

  return slot ? slot : calculateZIndex(0, layerEntity);
};
