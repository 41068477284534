import {
  useEffect, useRef, useState,
} from 'react';
import { useMap } from '~/map/map/mapContext';
import { useMapComponentZoomSelector } from '../../../store/frontendState/mapComponent/mapComponent.selectors';
import { createUuid } from '../createUuid';

export const useMapZoomChangeFinished = (isActive: boolean = true) => {
  const map = useMap();
  const mapZoom = useMapComponentZoomSelector();
  const mapZoomRef = useRef(mapZoom);
  mapZoomRef.current = mapZoom;
  const lastZoomRef = useRef<number | null>(null);
  const [zoomChangeEtag, setZoomChangeEtag] = useState<Uuid | null>(null);

  useEffect(() => {
    if (!map || !isActive) {
      return;
    }

    setZoomChangeEtag(createUuid());
    lastZoomRef.current = mapZoomRef.current;

    const listener = google.maps.event.addListener(map, 'idle', () => {
      if (mapZoomRef.current !== null && lastZoomRef.current !== mapZoomRef.current) {
        lastZoomRef.current = mapZoomRef.current;
        setZoomChangeEtag(createUuid());
      }
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map, isActive]);

  return {
    zoomChangeEtag,
    lastZoom: lastZoomRef.current,
  };
};
