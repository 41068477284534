import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type KeyboardEvent, useCallback,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import {
  createQuerySearchItem,
  isSearchItemWithColumn, isSingleGeolocatedSearchItem, type SearchItem,
} from '../../../map/search/mapSearch.helpers';
import { LottieAnimationTypes } from '../../baseComponents/lottieAnimation/lottieAnimation.types';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { removeWhitespaces } from '../../utils/text/text.helpers';
import { OverlayLottieAnimationComponent } from '../overlay/overlayLottieAnimation.component';

const iconStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
});

const createRightIcon = (onClick: () => void) => (
  <div
    css={iconStyle}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faXmark} />
  </div>
);

const wrapperStyle = css({
  position: 'relative',
  width: '100%',
});

const suggestionsPaneStyle = ({ theme, isLoading }: ThemeProps<{ isLoading: boolean }>) => css({
  position: 'absolute',
  width: '100%',
  backgroundColor: theme.backgroundColors.primary,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  overflow: 'hidden',
  minHeight: isLoading ? 100 : undefined,
  boxShadow: `-5px 5px 8px ${theme.dropdownColors.shadow}, 5px 5px 8px ${theme.dropdownColors.shadow}`,
});

const searchSuggestStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  display: 'block',
  height: 40,
  width: '100%',
  padding: '0 8px',
  lineHeight: 1,
  boxSizing: 'border-box',
  margin: 0,
  border: `1px solid ${theme.borderColors.primary}`,
  borderWidth: '0 1px 1px 1px',
  background: theme.backgroundColors.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',

  '&:hover': {
    background: theme.dropdownColors.optionBackgroundHover,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
});

const inputStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.backgroundColors.secondary,
  color: theme.textColors.primary,
});

const inputWithSuggestionsStyle = (themeProps: ThemeProps) => css(inputStyle(themeProps), {
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
});

type TextSearchBoxProps = Readonly<{
  areSearchSuggestsLoading: boolean;
  className?: string;
  inputStyle?: SerializedStyles;
  searchSuggests: SearchItem[];
  value: string;

  onChange: (newValue: string) => void;
  onClear: () => void;
  onSearchSuggestSubmit: (suggest: SearchItem) => void;
}>;

export const TextSearchBoxComponent: FC<TextSearchBoxProps> = props => {
  const { value, searchSuggests, areSearchSuggestsLoading, onSearchSuggestSubmit, onChange, onClear } = props;

  const theme = useTheme();
  const [t] = useTranslation();

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    //if users press enter, even if suggests are still loading, we want to go with just the text
    if (e.key === 'Enter' && removeWhitespaces(value).length > 0) {
      onSearchSuggestSubmit(createQuerySearchItem(value));
    }
  }, [value, onSearchSuggestSubmit]);

  return (
    <div
      css={wrapperStyle}
      className={props.className}
    >
      <TextInputComponent
        css={[
          searchSuggests.length > 0 || areSearchSuggestsLoading
            ? inputWithSuggestionsStyle({ theme })
            : inputStyle({ theme }),
          props.inputStyle,
        ]}
        onKeyUp={onKeyUp}
        value={value}
        onChange={onChange}
        rightContent={value.length > 0 ? createRightIcon(onClear) : undefined}
        placeholder={t('Search...')}
        icon={faSearch}
      />

      {(searchSuggests.length > 0 || areSearchSuggestsLoading) && (
        <div css={suggestionsPaneStyle({ theme, isLoading: areSearchSuggestsLoading })}>
          {searchSuggests.map((suggestion, index) => (
            <button
              key={index}
              css={searchSuggestStyle({ theme })}
              onClick={() => onSearchSuggestSubmit(suggestion)}
            >
              {suggestion.text}&nbsp;
              {isSearchItemWithColumn(suggestion) && suggestion.columnName && <>({suggestion.columnName})</>}
              {isSingleGeolocatedSearchItem(suggestion) && <>({t('Geographic Location')})</>}
            </button>
          ))}

          {areSearchSuggestsLoading && (
            <OverlayLottieAnimationComponent
              size={48}
              type={LottieAnimationTypes.SearchSonar}
              loop
              autoplay
              colors={{
                arm: theme.iconColors.primary,
                border: theme.iconColors.contrast,
                dot: theme.iconColors.contrast,
              }}
              speed={0.5}
              gradients={{
                sonar: [{
                  offset: 25,
                  color: theme.backgroundColors.highlight,
                  startOpacity: 0,
                  endOpacity: 0,
                }, {
                  offset: 100,
                  color: theme.backgroundColors.quaternary,
                  startOpacity: 100,
                  endOpacity: 100,
                }],
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
