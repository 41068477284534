import { css } from '@emotion/react';
import {
  faCreditCard, faKey, faListCheck, faLock, faTrashCanXmark, faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons/button/button.types';
import { SUBSCRIPTION_ROUTE } from '~/_shared/constants/routes';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SubscriptionManagementCancelOption } from '~/subscription/subscriptionManagement.constants';
import { useDeleteClientOrRemoveSubscription } from '~/subscription/useOpenConfirmationForDelete.hook';
import { useTranslation } from '../../utils/hooks';

const actionsContainerStyle = css({
  width: '100%',
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  alignItems: 'stretch',
});

type AccountSettingsActionsComponentProps = {
  isPaidLicence: boolean;
  allowManageClient: boolean;
  allowManageSubscription: boolean;
  isClientMigrationInProgress: boolean;
};

export const AccountSettingsActionsComponent: FC<AccountSettingsActionsComponentProps> = (props) => {
  const [t] = useTranslation();
  const { deleteOrCancel } = useDeleteClientOrRemoveSubscription();

  const { openModal: openTwoFaModal } = useModal(ModalType.TwoFa);
  const { openModal: openTeamManagementModal } = useModal(ModalType.TeamManagement);
  const { openModal: openManageSubscriptionModal } = useModal(ModalType.SubscriptionManagement);
  const { openModal: openChangePasswordModal } = useModal(ModalType.ChangePassword);

  const handleLaunchPaymentPortal = useCallback(() => openUrlInNewTab(SUBSCRIPTION_ROUTE), []);

  return (
    <div css={actionsContainerStyle}>
      <ButtonComponent
        buttonStyle={ButtonStyle.Tertiary}
        size={ButtonSize.Small}
        text={t('Change Password')}
        prefixIcon={faLock}
        onClick={() => openChangePasswordModal()}
        isDisabled={props.isClientMigrationInProgress}
      />
      {props.allowManageClient && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Tertiary}
          size={ButtonSize.Small}
          text={t('Manage Team Users')}
          prefixIcon={faUsers}
          onClick={() => openTeamManagementModal()}
          isDisabled={props.isClientMigrationInProgress}
        />
      )}
      {props.allowManageSubscription && props.isPaidLicence && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Tertiary}
          size={ButtonSize.Small}
          text={t('Launch Payment Portal')}
          prefixIcon={faCreditCard}
          onClick={handleLaunchPaymentPortal}
          isDisabled={props.isClientMigrationInProgress}
        />
      )}

      <ButtonComponent
        buttonStyle={ButtonStyle.Tertiary}
        size={ButtonSize.Small}
        text={t('Two Factor Authentication')}
        prefixIcon={faKey}
        onClick={() => openTwoFaModal()}
      />

      {props.allowManageSubscription && props.isPaidLicence && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Tertiary}
          size={ButtonSize.Small}
          text={t('Manage Subscription')}
          prefixIcon={faListCheck}
          onClick={() => openManageSubscriptionModal()}
          isDisabled={props.isClientMigrationInProgress}
        />
      )}

      {props.allowManageClient && !props.isPaidLicence && (
        <ButtonComponent
          buttonStyle={ButtonStyle.DangerInverted}
          size={ButtonSize.Small}
          text={t('Cancel Now / Delete my Data')}
          prefixIcon={faTrashCanXmark}
          onClick={() => deleteOrCancel(SubscriptionManagementCancelOption.NowAndDeleteData)}
          isDisabled={props.isClientMigrationInProgress}
        />
      )}
    </div>
  );
};
