import { css } from '@emotion/react';
import {
  faCirclePlay, faPause,
} from '@fortawesome/pro-solid-svg-icons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  FadingDots, ProgressBarComponent,
} from '~/_shared/baseComponents/loaders';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '../../baseComponents/lottieAnimation';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';

export type GeocodingProgressBarComponentProps = Readonly<{
  percentage: number;
  isPaused: boolean;
  onPauseToggle: (newValue: boolean) => void;
  onProcessFrozen: () => void;
  className?: string;
}>;

const rootStyle = css({
  display: 'flex',
  width: '100%',
});

const iconButtonStyle = css({
  fontSize: 20,
  border: 'none',
  background: 'transparent',
  width: '40px',
  '&:hover': {
    border: 'none',
    background: 'transparent',
  },
});

const pauseIconStyle = ({ theme, isPaused }: ThemeProps<{isPaused: boolean}>) => css({
  margin: 0,
  color: isPaused ? theme.textColors.danger : theme.textColors.primary,
  '&:hover': {
    color: theme.textColors.success,
  },
});

const progressContainerStyle = css({
  margin: '0 8px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

const progressStyle = css({
  margin: '2px 0',
});

const linkButtonStyle = ({ theme }: ThemeProps) => css({
  border: 'none',
  color: theme.textColors.link,
  padding: 0,
  background: 'transparent',
  '&:hover': {
    border: 'none',
    background: 'transparent',
    textDecoration: 'underline',
  },
});

const progressBarWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const locationPinAnimationStyle = css({
  flexShrink: 0,
  marginLeft: 20,
  width: 34,
});

const textStyle = css({
  fontSize: 14,
  textAlign: 'center',
});

export const GeocodingProgressBarComponent = (props: GeocodingProgressBarComponentProps) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <div
      css={rootStyle}
      className={props.className}
    >
      <ButtonComponent
        text=""
        css={iconButtonStyle}
        prefixIcon={faPause}
        prefixIconHovered={props.isPaused ? faCirclePlay : undefined}
        prefixIconStyle={pauseIconStyle({ theme, isPaused: props.isPaused })}
        onClick={() => props.onPauseToggle(!props.isPaused)}
      />

      <div css={progressContainerStyle}>
        <div css={progressBarWrapperStyle}>
          <span css={textStyle}>{t('Geocoding in progress')}<FadingDots /></span>

          <ProgressBarComponent
            css={progressStyle}
            progress={props.percentage}
          />

          <span css={textStyle}>
            <ButtonComponent
              text={t('Click here')}
              css={linkButtonStyle({ theme })}
              onClick={props.onProcessFrozen}
            /> {t('if it looks like the process is frozen')}
          </span>
        </div>

        <LottieAnimationComponent
          css={locationPinAnimationStyle}
          size={46}
          type={LottieAnimationTypes.LocationPin3D}
          colors={{
            pin: theme.iconColors.contrast,
          }}
          autoplay
          loop
          speed={2.5}
        />
      </div>
    </div>
  );
};
