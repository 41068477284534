import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import {
  convertPolygonToGeometry, removeGeometryPolygonIntersections,
} from '~/_shared/types/polygon/polygon.utils';
import { flatten } from '~/_shared/utils/collections/collections';
import { boundaryItemsUpdateItem } from '~/store/boundaryItems/boundaryItems.actionCreators';
import { select } from '../../../../../_shared/utils/saga/effects';
import { type PickAction } from '../../../../../_shared/utils/types/action.type';
import {
  type BoundaryCombineGeometryRequest,
  type BoundaryCombineGeometryResponse,
  type BoundaryResponse,
  getBoundaryCombineGeometry,
  updateBoundaryGeometry,
} from '../../../../boundaries/boundaries.repository';
import { createBoundaryStateItemFromBoundaryResponse } from '../../../../boundaryItems/boundaryItems.factory';
import { type BoundaryDragEditAction } from './boundaryPolygonUpdate.action';
import {
  boundaryPolygonSaveError,
  boundaryPolygonSaveFinished,
  boundaryPolygonSaveStarted,
} from './boundaryPolygonUpdate.actionCreators';
import { BOUNDARY_POLYGON_UPDATE_REQUEST } from './boundaryPolygonUpdate.actionTypes';

export function* boundariesEditingSagas() {
  yield takeLatest(BOUNDARY_POLYGON_UPDATE_REQUEST, updateBoundary);
}

function* updateBoundary(action: PickAction<BoundaryDragEditAction, typeof BOUNDARY_POLYGON_UPDATE_REQUEST>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const mapId: number | undefined = yield select<number | null>(state => state.map.mapId) ?? undefined;

  if (!clientId) {
    return;
  }

  const { multiPolygon, boundaryId, boundaryGroupId } = action.payload;

  if (!multiPolygon.length) {
    return;
  }

  yield put(boundaryPolygonSaveStarted());

  try {
    const polygonsWithoutIntersections = flatten(multiPolygon.map(polygon =>
      removeGeometryPolygonIntersections(convertPolygonToGeometry(polygon)).features));

    const combineGeometryRequest: BoundaryCombineGeometryRequest = {
      include: polygonsWithoutIntersections.map(polygon => ({ type: 'geometry', value: polygon.geometry })),
      subtract: [],
    };

    const newGeometry: BoundaryCombineGeometryResponse = yield call(getBoundaryCombineGeometry, clientId, combineGeometryRequest);

    const response: BoundaryResponse = yield call(updateBoundaryGeometry, clientId, boundaryId, newGeometry.geometry, mapId);
    const boundaryStateItem = createBoundaryStateItemFromBoundaryResponse(response);
    yield put(boundaryItemsUpdateItem(boundaryGroupId, boundaryStateItem));
    yield put(boundaryPolygonSaveFinished());
  }
  catch {
    yield put(boundaryPolygonSaveError());
  }
}
