import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons/faCircleDot';
import {
  faFilter,
  faFilterList,
  faFireAlt,
  faGlobe,
  faGripHorizontal,
  faLayerGroup,
  faMapSigns,
  faPalette,
  faShapes,
  faThumbtack,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { DirectionsPanelContainer } from '../../../directions/panel/directionsPanel.container';
import { GroupingSimpleLegendContainer } from '../../../grouping/groupingSimpleLegend.container';
import { HeatMapPanelContainer } from '../../../heatMap/panel/heatMapPanel.container';
import { ProximityPanelContainer } from '../../../proximity/panel/proximityPanel.container';
import { type Translations } from '../../../translations/translations.type';
import { BoundaryToolContainer } from './boundary/boundaryTool.container';
import { DrawingToolContainer } from './drawingTool/drawingTool.container';
import { FilterDataToolContainer } from './filterDataTool/filterDataTool.container';
import { FilterToolContainer } from './filterTool.container';
import { GroupingToolContainer } from './groupingTool.container';
import { LayeringToolContainer } from './layeringTool.container';
import { PlacesToolContainer } from './placesTool/placesTool.container';

export const mapToolTitleMap: { [key in keyof typeof MapTool]: keyof Translations} = {
  FilterData: 'Search Data Set',
  Grouping: 'Grouping / Colored Pins',
  MapLegend: 'Map Legend',
  Filter: 'Filter Data / Locations',
  Layering: 'Layering Maps',
  Routing: 'Routing / Directions',
  Boundary: 'Boundary / Territory',
  Proximity: 'Radius / Drive Time',
  HeatMap: 'Heat Mapping',
  Drawing: 'Drawing',
  Places: 'Places',
};

export const mapToolComponentMap: { [key in keyof typeof MapTool]: FC} = {
  FilterData: FilterDataToolContainer,
  Grouping: () => GroupingToolContainer({ areGroupSettingsVisible: true }),
  MapLegend: () => GroupingSimpleLegendContainer({}),
  Filter: FilterToolContainer,
  Layering: LayeringToolContainer,
  Proximity: ProximityPanelContainer,
  Routing: DirectionsPanelContainer,
  Boundary: BoundaryToolContainer,
  HeatMap: HeatMapPanelContainer,
  Drawing: DrawingToolContainer,
  Places: PlacesToolContainer,
};

export const standaloneMapTools = [MapTool.MapLegend, MapTool.FilterData];

export const mapToolIconMap: { [key in keyof typeof MapTool]: IconProp} = {
  FilterData: faFilterList,
  Grouping: faShapes,
  MapLegend: faGripHorizontal,
  Filter: faFilter,
  Layering: faLayerGroup,
  Proximity: faCircleDot,
  Routing: faMapSigns,
  Boundary: faGlobe,
  HeatMap: faFireAlt,
  Drawing: faPalette,
  Places: faThumbtack,
};
