import { type TOptions } from 'i18next';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  type ColorResult, type RGBColor,
} from '~/_shared/components/colorPicker/colorPicker.types';
import { DEFAULT_NUMERIC_ROUND_MARKER_OPACITY } from '~/_shared/utils/markers/markerVisualSettings.constants';
import {
  formatValue, RangeType,
} from '~/_shared/utils/range/range.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryBucketType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritory, type GeneratedBoundaryTerritory,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { getBoundaryTerritoryRangeValues } from './numeric/boundarySettingsNumeric.helpers';

export const BOUNDARY_NO_LOCATIONS_ID = '%no_locations%';
export const BOUNDARY_NO_NUMERICAL_ID = '%no_numerical%';
export const BOUNDARY_GROUP_TIE_ID = '%group_tie%';
export const BOUNDARY_NO_DATA_ID = '%no_data%';
export const BOUNDARY_MAX_VALUE_ID = 'max';
export const BOUNDARY_MIN_VALUE_ID = 'min';
export const BOUNDARY_VALUES_SEPARATOR = ':';

const specialBoundaryIds = [BOUNDARY_NO_LOCATIONS_ID, BOUNDARY_NO_DATA_ID, BOUNDARY_NO_NUMERICAL_ID, BOUNDARY_GROUP_TIE_ID];

const blendColors = (color1: RGBColor, color2: RGBColor, weight: number): RGBColor => {
  const w = weight * 2 - 1;
  const w1 = (w + 1) / 2;
  const w2 = 1 - w1;
  return {
    r: Math.round(color1.r * w1 + color2.r * w2),
    g: Math.round(color1.g * w1 + color2.g * w2),
    b: Math.round(color1.b * w1 + color2.b * w2),
  };
};

export const calculateGroupColor = (
  index: number, itemsCount: number, lowValueColor: ColorResult,
  mediumValueColor: ColorResult, highValueColor: ColorResult, opacity?: number
): ColorResult => {
  const indicator = index / (itemsCount - 1);
  const firstBlendColor = indicator < .5 ? lowValueColor : mediumValueColor;
  const secondBlendColor = indicator < .5 ? mediumValueColor : highValueColor;

  const mediumValue = (itemsCount - 1) / 2;
  const ratio = indicator < .5 ? 1 - index / mediumValue : 1 - (index - mediumValue) / (itemsCount - 1 - mediumValue);

  const result = blendColors(firstBlendColor.rgb, secondBlendColor.rgb, ratio);

  return createColor({ ...result, a: opacity ?? DEFAULT_NUMERIC_ROUND_MARKER_OPACITY });
};

export const getBoundaryTerritoryRegularGroupsCount = (boundaryTerritories: ReadonlyArray<BoundaryTerritory>) => {
  return boundaryTerritories.filter(territory => !isBoundaryTerritorySpecialOrCustom(territory)).length;
};

export const isBoundaryTerritoryGenerated = (territory: BoundaryTerritory): territory is GeneratedBoundaryTerritory =>
  !territory.custom;

export const isBoundaryTerritorySpecialOrCustom = (territory: BoundaryTerritory) => {
  return territory.custom || isBoundaryTerritorySpecial(territory);
};

export const isBoundaryTerritorySpecial = (territory: BoundaryTerritory) => {
  return !territory.custom && specialBoundaryIds.includes(territory.boundaryTerritoryId);
};

export const isBoundaryTerritoryHidden = (boundaryTerritory: BoundaryTerritory, isEmpty: boolean, type: BoundaryTerritoryType) => {
  // Hide boundary territories that are empty and are generated from data (not custom)
  return isEmpty && !boundaryTerritory.custom && (
    // Only special and group types. Numerical types (ranges) are always visible.
    // Otherwise there will be gaps in the legend. Ranges should cover -inf to +inf.
    isBoundaryTerritorySpecial(boundaryTerritory) || // like NO_LOCATIONS
    type === BoundaryTerritoryType.Groups
  );
};

export const translateBoundaryTerritoryDisplayName = (
  territory: BoundaryTerritory,
  t: (key: string, options?: TOptions) => string,
  rangeParameters?: {bucketType: BoundaryBucketType | null;
    min: number | null;
    max: number | null;
  }
): string => {
  if (!territory.translate) {
    return territory.displayName;
  }

  const params = getTranslationParameters(territory, rangeParameters);
  return t(territory.displayName, params);
};

const getTranslationParameters = (
  territory: BoundaryTerritory,
  rangeParameters?: {
    bucketType: BoundaryBucketType | null;
    min: number | null;
    max: number | null;
  }
): TOptions | undefined => {
  const bucketType = rangeParameters?.bucketType;
  const min = rangeParameters?.min;
  const max = rangeParameters?.max;

  const rangeType = bucketTypeToRangeType(bucketType);

  if (!rangeType) {
    return undefined;
  }

  const range = getBoundaryTerritoryRangeValues(territory);

  if (!range) {
    return undefined;
  }

  if (notNullsy(min) && range.valueFrom === BOUNDARY_MIN_VALUE_ID) {
    const valueTo = Number(range.valueTo);
    range.valueFrom = !isNaN(valueTo) && min > valueTo ? (valueTo).toString() : min.toString();
  }

  if (notNullsy(max) && range.valueTo === BOUNDARY_MAX_VALUE_ID) {
    const valueFrom = Number(range.valueFrom);
    range.valueTo = !isNaN(valueFrom) && max < valueFrom ? (valueFrom).toString() : max.toString();
  }

  const valueSuffix = rangeType === RangeType.Percentage ? '%' : '';

  const valueFrom = range.valueFrom !== BOUNDARY_MIN_VALUE_ID ?
    `${formatValue(range.valueFrom)}${valueSuffix}` : '-∞';

  const valueTo = range.valueTo !== BOUNDARY_MAX_VALUE_ID ?
    `${formatValue(range.valueTo)}${valueSuffix}` : '∞';

  return { valueFrom, valueTo };
};

export const bucketTypeToRangeType = (bucketType?: BoundaryBucketType | null) =>
  bucketType === 'percentage-range' ? RangeType.Percentage
    : bucketType === 'value-range' ? RangeType.Value
      : undefined;

export const rangeTypeToBucketType = (rangeType: RangeType): BoundaryBucketType =>
  rangeType === RangeType.Percentage ? 'percentage-range' : 'value-range';
