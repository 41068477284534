import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { select } from '../../../_shared/utils/saga/effects';
import { clientIdSelector } from '../../selectors/useClientIdSelector';
import { createDemographicsRegionFromResponse } from '../demographics.factory';
import {
  type DemographicsGlobalListResponse, getDemographicsListGlobal,
} from '../demographics.repository';
import {
  demographicsListFetchDataSuccess,
  demographicsListGlobalFetchDataError, demographicsListGlobalFetchDataStart,
} from './demographicsListGlobal.actionCreators';
import {
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_REQUEST,
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START,
} from './demographicsListGlobal.actionTypes';

export function* demographicsListGlobalSagas() {
  yield takeLatest(DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_REQUEST, onDemographicsListGlobalFetchDataRequest);
  yield takeLatest(DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START, fetchDemographicsListGlobal);
}

function* onDemographicsListGlobalFetchDataRequest() {
  const clientId: number | null = yield select<number | null>(clientIdSelector);
  const isDemographicsListGlobalFetched: boolean = yield select<boolean>(state => state.demographics.listGlobal.isDataFetched);

  if (clientId === null || isDemographicsListGlobalFetched) {
    return;
  }

  yield put(demographicsListGlobalFetchDataStart());
}

function* fetchDemographicsListGlobal() {
  const clientId: number | null = yield select<number | null>(clientIdSelector);

  if (clientId === null) {
    return;
  }

  try {
    const demographicsListResponse: DemographicsGlobalListResponse = yield call(getDemographicsListGlobal, clientId);
    yield put(demographicsListFetchDataSuccess(demographicsListResponse.regions.map((region) =>
      createDemographicsRegionFromResponse(region))
    ));
  }
  catch (e) {
    yield put(demographicsListGlobalFetchDataError());
  }
}
