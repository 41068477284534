import { createAction } from '@reduxjs/toolkit';
import {
  type BoundaryTerritoryIdentifier, type ExtendedBoundaryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import { type ActiveMarker } from '../../../_shared/types/marker.types';
import { type SpreadsheetRowId } from '../../../_shared/types/spreadsheetData/spreadsheetRow';
import {
  ACTIVE_MAP_ELEMENTS_CLEAR_STATE,
  ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED,
  ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED,
  ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_MARKER_NAVIGATION_INDEX,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY, ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST,
  SPREADSHEET_MARKER_CLICKED,
  SPREADSHEET_MARKER_HOVER_STOPPED,
  SPREADSHEET_MARKER_HOVERED,
} from './activeMapElements.actionTypes';

export const clickSpreadsheetMarker = (activeMarker: ActiveMarker) => ({
  type: SPREADSHEET_MARKER_CLICKED,
  payload: activeMarker,
}) as const;

export const hoverSpreadsheetMarker = (activeMarker: ActiveMarker) => ({
  type: SPREADSHEET_MARKER_HOVERED,
  payload: activeMarker,
}) as const;

export const stopSpreadsheetMarkerHover = () => ({
  type: SPREADSHEET_MARKER_HOVER_STOPPED,
}) as const;

export const deactivateActiveMarker = () => ({
  type: ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER,
}) as const;

export const deactivateActiveProximity = createAction('ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_PROXIMITY');

export const deactivateActiveBoundary = createAction('ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_BOUNDARY');

export const activeMapElementsClearState = () => ({
  type: ACTIVE_MAP_ELEMENTS_CLEAR_STATE,
}) as const;

export const activeMapElementsSetActiveMarkerNavigationIndex = (index: number) => ({
  type: ACTIVE_MAP_ELEMENTS_SET_ACTIVE_MARKER_NAVIGATION_INDEX,
  payload: {
    index,
  },
}) as const;

export const activeMapElementsSetActiveBoundary = ({ boundaryGroupId, boundaryTerritoryGroupId, boundaryId }: ExtendedBoundaryIdentifier) => ({
  type: ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY,
  payload: {
    boundaryGroupId,
    boundaryTerritoryGroupId,
    boundaryId,
  },
}) as const;

export const activeMapElementsSetActiveBoundaryTerritory = ({ boundaryTerritoryId, boundaryGroupId, boundaryTerritoryGroupId }: BoundaryTerritoryIdentifier) => ({
  type: ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY,
  payload: {
    boundaryTerritoryId,
    boundaryGroupId,
    boundaryTerritoryGroupId,
  },
}) as const;

export const activeMapElementsClusterHovered = () => ({
  type: ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED,
}) as const;

export const activeMapElementsClusterHoverStopped = () => ({
  type: ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED,
}) as const;

export const activeMapElementsSetActiveProximity = (proximityId: string, spreadsheetRowId?: SpreadsheetRowId) => ({
  type: ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY,
  payload: {
    proximityId,
    spreadsheetRowId,
  },
}) as const;

export const activeMapElementsSetActiveLocationList = () => ({
  type: ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST,
}) as const;
