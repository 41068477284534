import { useState } from 'react';
import { UploadStatus } from '../../_shared/components/uploadStatus/uploadStatus.component';
import { type ImportMethod } from '../../_shared/types/importMethod/importMethod';
import {
  type ApiError, isApiError,
} from '../../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { uploadMapSpreadsheet } from '../../map/map.repository';
import { useImportData } from './useImportData';

type ImportFileDataArguments = {
  clientId: number;
  userId: number;
  mapId: number;
  spreadsheetId: number;
  importMethod: ImportMethod;
  file: File;
  onProgressChange: (progress: number) => void;
  onStatusChange: (status: UploadStatus) => void;
};

export const useImportFileData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const { importData } = useImportData();
  const isMountedRef = useIsComponentMountedRef();

  const clearErrorMessage = () => {
    setError(undefined);
  };

  const importFileData = async ({
    clientId, userId, mapId, spreadsheetId, importMethod, file, onProgressChange, onStatusChange,
  }: ImportFileDataArguments): Promise<unknown> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const uploadedSpreadsheet = await uploadMapSpreadsheet(clientId, file, {
        onProgress: ((progress: number) => {
          onProgressChange(progress / 3);
        }),
      });

      return await importData({
        onImportSuccess: () => {
          onProgressChange(67);
        },
        clientId,
        importMethod,
        mapId,
        source: uploadedSpreadsheet.data.file_name,
        spreadsheetId,
        type: 'file',
        userId,
      })
        .then(() => {
          if (isMountedRef.current) {
            setIsLoading(false);
            onProgressChange(100);
            onStatusChange(UploadStatus.Success);
          }
        });
    }
    catch (e: unknown) {
      if (isMountedRef.current) {
        setIsLoading(false);
        onStatusChange(UploadStatus.Error);

        if (isApiError(e)) {
          setError(e);
        }
      }
      throw e;
    }
  };

  return {
    isLoading,
    error,
    importFileData,
    clearErrorMessage,
  };
};
