import {
  type ComponentProps, type ReactNode,
} from 'react';
import { ChangeEmailModalComponent } from '~/_shared/components/accountSettings/modalDialogs/changeEmailModal.component';
import { ChangePasswordModalComponent } from '~/_shared/components/accountSettings/modalDialogs/changePasswordModal.component';
import { TwoFactorModalContainer } from '~/_shared/components/accountSettings/twoFactorModal/twoFactorModal.container';
import { AddMultipleLocationsModalContainer } from '~/_shared/components/addMultipleLocationsModal/addMultipleLocationsModal.container';
import {
  ChangesPendingModalComponent, type ChangesPendingModalProps,
} from '~/_shared/components/changesPending/changesPendingModal.component';
import {
  ChooseColumnModalContainer, type ChooseColumnModalContainerProps,
} from '~/_shared/components/chooseColumnModal/chooseColumnModal.container';
import {
  CopyMapModalContainer, type CopyMapModalContainerProps,
} from '~/_shared/components/copyMapModal/copyMapModal.container';
import {
  DownloadResultsModalComponent, type DownloadResultsModalProps,
} from '~/_shared/components/downloadResults/downloadResultsModal.component';
import {
  EditDataModalContainer, type EditDataModalContainerProps,
} from '~/_shared/components/editDataModal/editDataModal.container';
import {
  SendNavigationLinksModal, type SendNavigationLinksModalProps,
} from '~/_shared/components/emailForms/shareNavigationLinksModal.component';
import {
  EmailSentNotificationModalContainer, type EmailSentNotificationModalProps,
} from '~/_shared/components/emailSent/emailSentNotificationModal.container';
import { EmbedMapModalContainer } from '~/_shared/components/embedMap/embedMapModal.container';
import { ExportImageModalContainer } from '~/_shared/components/exportImageModal/exportImageModal.container';
import {
  ImageLibraryContainer, type ImageLibraryContainerProps,
} from '~/_shared/components/imageLibrary/imageLibrary.container';
import {
  ImageViewModalComponent, type ImageViewModalProps,
} from '~/_shared/components/imageViewModal/imageViewModal.component';
import {
  LocationFinderModalContainer, type LocationFinderModalContainerProps,
} from '~/_shared/components/locationFinderModal/locationFinderModal.container';
import {
  MapPrivacySelectorModalContainer, type MapPrivacySelectorModalContainerProps,
} from '~/_shared/components/mapPrivacySelectorModal/mapPrivacySelectorModal.container';
import {
  ConfirmationModalComponent, type ConfirmationModalProps,
} from '~/_shared/components/modal/confirmation/confirmationModal.component';
import {
  ConfirmationWithAuthenticationModalComponent, type ConfirmationWithAuthenticationModalProps,
} from '~/_shared/components/modal/confirmationWithAuthentication/confirmationWithAuthenticationModal.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import {
  NotificationModalComponent, type NotificationModalProps,
} from '~/_shared/components/modal/notificationModal.component';
import {
  OptionsModalComponent, type OptionsModalComponentProps,
} from '~/_shared/components/optionsModal/optionsModal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  SearchSettingsModalContainer, type SearchSettingsModalContainerProps,
} from '~/_shared/components/searchSettingsModal/searchSettingsModal.container';
import { WelcomeModalComponent } from '~/_shared/components/welcomeModal/welcomeModal.component';
import {
  CloneBoundaryTerritoryGroupModal, type CloneBoundaryTerritoryGroupModalProps,
} from '~/boundary/cloneBoundaryTerritoryGroupModal/cloneBoundaryTerritoryGroupModal';
import {
  BoundaryEditModalContainer, type BoundaryEditModalContainerProps,
} from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.container';
import {
  BoundaryEditSubmitModalContainer, type BoundaryEditSubmitModalContainerProps,
} from '~/boundary/editMode/boundaryEditSubmitModal/boundaryEditSubmitModal.container';
import {
  BoundarySettingsContainer, type BoundarySettingsContainerProps,
} from '~/boundary/settings/boundarySettings.container';
import { TeamManagementModalContainer } from '~/clientTeamManagement/teamManagementModal/teamManagementModal.container';
import {
  RemoveMemberFromMapModalContainer, type RemoveMemberFromMapModalContainerProps,
} from '~/clientTeamManagement/teamShareMapModal/removeMemberFromMapModal/removeMemberFromMapModal.container';
import {
  TeamShareMapModalContainer, type TeamShareMapModalContainerProps,
} from '~/clientTeamManagement/teamShareMapModal/teamShareMapModal.container';
import {
  EditLocationModalContainer, type EditLocationModalContainerProps,
} from '~/customizeLocationPanel/editLocationModal/editLocationModal.container';
import {
  CustomizeLabelAndMarkerModalContainer, type CustomizeLabelAndMarkerModalContainerProps,
} from '~/customizeMarkerPopup/customizeLabelAndMarkerModal.container';
import { AddDataColumnContainer } from '~/data/addDataColumn/addDataColumn.container';
import {
  BadDataModalContainer, type DataTableModalProps,
} from '~/data/badDataModal/badDataModal.container';
import {
  ColumnsSettingsModalComponent, type ColumnsSettingsModalProps,
} from '~/data/columnsSettingsModal/columnsSettingsModal.component';
import {
  ExportItemizedSpreadsheetDataModalContainer, type ExportItemizedSpreadsheetDataModalContainerProps,
} from '~/data/exportDataModal/exportItemizedSpreadsheetData.container';
import {
  type ExportDataModalContainerProps, ExportSpreadsheetDataContainer,
} from '~/data/exportDataModal/exportSpreadsheetData.container';
import {
  ImportDataContainer, type ImportDataContainerProps,
} from '~/data/importData/importData.container';
import {
  RowSettingsResolveModalComponent, type RowSettingsResolveModalComponentProps,
} from '~/data/replaceData/replaceDataRowSettingsResolveModal.component';
import {
  SyncDataModalForReconnectingGoogleSheetContainer, type SyncDataModalForReconnectingGoogleSheetContainerProps,
} from '~/data/syncData/syncDataModalForReconnectingGoogleSheet.container';
import {
  DirectionsEditRouteComponent, type DirectionsPanelEditProps,
} from '~/directions/panel/editRoute/directionsEditRoute.component';
import {
  DirectionsPanelFormAddModal, type DirectionsPanelFormAddModalProps,
} from '~/directions/panel/form/directionsPanelFormAddModal.component';
import {
  DirectionSettingsModalContainer, type DirectionSettingsModalContainerProps,
} from '~/directions/settings/directionsSettings.container';
import { FilterSettingsContainer } from '~/filter/settings/filterSettings.container';
import { GroupingToolHintComponent } from '~/grouping/components/GroupingToolHint.component';
import {
  NumericalGroupSettingsContainer, type NumericalGroupSettingsContainerProps,
} from '~/grouping/settings/numericalGroupSettings.container';
import { AboutUsModalContainer } from '~/help/aboutUs/aboutUsModal.container';
import { ContactUsModalComponent } from '~/help/contact/contactUsModal.component';
import { AfterMigrationMapPrepareModal } from '~/map/afterMigrationMapPrepareModal/afterMigrationMapPrepareModal';
import {
  CreateNewMapContainer, type CreateNewMapContainerProps,
} from '~/map/createNew/createNewMap.container';
import {
  CreateNewSalesRepMapSuccessComponent, type CreateNewSalesRepMapSuccessComponentProps,
} from '~/map/createNew/createNewSalesRepMapSuccess.component';
import { CustomizeMapUrlContainer } from '~/map/customizeMapUrl/customizeMapUrl.container';
import {
  BaseMapsUpdateModalContainer, type BaseMapsUpdateModalContainerProps,
} from '~/map/layered/baseMapUpdatedModal/baseMapsUpdateModal.container';
import {
  CreateLayeredMapModalContainer, type CreateLayeredMapModalContainerProps,
} from '~/map/layered/createLayeredMapModal/createLayeredMapModal.container';
import { EditLayeredMapModalContainer } from '~/map/layered/editLayeredMapModal/editLayeredMapModal.container';
import {
  HandleBaseMapDeletionModalContainer, type HandleBaseMapDeletionModalContainerProps,
} from '~/map/listing/layered/resolveLayeredMapDeleteConflicts/handleBaseMapDeletionModal/handleBaseMapDeletionModal.container';
import {
  BoundaryZoomLevelDisclaimerModalComponent, type BoundaryZoomLevelDisclaimerProps,
} from '~/map/map/boundary/boundaryZoomLevelDisclaimer/boundaryZoomLevelDisclaimerModal.component';
import {
  type BulkEditLocationsDataModalContainerProps, BulkEditLocationsModalContainer,
} from '~/map/map/lassoTool/bulkEditLocations/bulkEditLocationsModal.container';
import {
  ExportContainedBoundariesModal, type ExportContainedBoundariesModalComponentProps,
} from '~/map/map/lassoTool/exportContainedBoundaries/exportContainedBoundariesModal.component';
import {
  ExportLassoDataModalContainer, type ExportLassoDataModalContainerProps,
} from '~/map/map/lassoTool/exportData/exportLassoDataModal.container';
import {
  SelectionDialogComponent, type SelectionDialogProps,
} from '~/map/map/lassoTool/optimizeRouteBetweenPoints/selectionDialog/selectionDialog.component';
import { MigrateMapsComponent } from '~/map/mapMigration/migrateMaps.component';
import { MigrateMapsConfirmationComponent } from '~/map/mapMigration/migrateMapsConfirmation.component';
import { MigrateMapsFinishedComponent } from '~/map/mapMigration/migrateMapsFinished.component';
import { MigrateMapsInProgressComponent } from '~/map/mapMigration/migrateMapsInProgress.component';
import {
  MatchupDataContainer, type MatchupDataContainerProps,
} from '~/map/page/matchup/matchupData.container';
import { RestrictZoomLevelModalContainer } from '~/map/restrictZoomLevels/restrictZoomLevelModal.container';
import {
  ShareMapSelectComponent, type ShareMapSelectProps,
} from '~/map/share/select/shareMapSelect.component';
import {
  ShareMapContainer, type ShareMapContainerProps,
} from '~/map/share/shareMap.container';
import { ExportDataFunctionalitiesColumnsDisplaySettingsContainer } from '~/presentationalColumnsRestrictions/exportDataFunctionalitiesColumnsDisplaySettings/exportDataFunctionalitiesColumnsDisplaySettings.container';
import { MapMarkerColumnsDisplaySettingsContainer } from '~/presentationalColumnsRestrictions/mapMarkerColumnsDisplaySettings/mapMarkerColumnsDisplaySettings.container';
import { PublicMapColumnsDisplaySettingsContainer } from '~/presentationalColumnsRestrictions/publicMapColumnsDisplaySettings/publicMapColumnsDisplaySettings.container';
import {
  ProximityEditComponent, type ProximityEditProps,
} from '~/proximity/edit/proximityEdit.component';
import { ProximitySettingsContainer } from '~/proximity/settings/proximitySettings.container';
import {
  ExportProximityDataContainer, type ExportProximityDataContainerProps,
} from '~/proximityDetails/export/exportProximityData.container';
import {
  CreateBoundaryGroupFromAIModalContainer, type CreateBoundaryGroupFromAIModalContainerProps,
} from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.container';
import { SubscriptionManagementUnavailableModalComponent } from '~/subscription/subscriptionManagementUnavailableModal.component';
import {
  TrialExportNoticeModal, type TrialExportNoticeModalProps,
} from '../_shared/components/exportDataModal/trialExportNoticeModal.component';
import { SubscriptionExpiredModal } from '../_shared/types/client/SubscriptionExpiredModal';
import { AppErrorComponent } from '../appError/appError.component';
import { type AppError } from '../appError/appError.type';
import {
  ImpersonationChangedModal, type ImpersonationChangedModalProps,
} from '../authorization/impersonation';
import {
  BoundaryItemSettingsContainer, type BoundaryItemSettingsProps,
} from '../boundary/boundaryItemSettings/boundaryItemSettings.container';
import {
  BoundaryTerritoryCreateContainer, type BoundaryTerritoryCreateContainerProps,
} from '../boundary/boundaryItemSettings/boundaryTerritoryCreate.container';
import {
  BoundaryCustomizeMetricsContainer, type BoundaryCustomizeMetricsContainerProps,
} from '../boundary/customizeMetrics/boundaryCustomizeMetrics.container';
import {
  BoundaryCreateSubmitModalContainer, type BoundaryCreateSubmitModalProps,
} from '../boundary/editMode/boundaryCreateSubmitModal/boundaryCreateSubmitModal.container';
import {
  BoundaryTerritoryEditSubmitModalContainer, type BoundaryTerritoryEditSubmitModalContainerProps,
} from '../boundary/editMode/boundaryTerritoryEditSubmitModal/boundaryTerritoryEditSubmitModal.container';
import {
  ChartsModalContainer, type ChartsModalProps,
} from '../charts/chartsVisualizer.container';
import {
  type RestoreDefaultMarkerCustomizationsContainerProps, RestoreDefaultMarkerCustomizationsModalContainer,
} from '../customizeMarkerPopup/restoreDefault/restoreDefaultMarkerCustomizationsModal.container';
import {
  ReplaceDataMatchupModalContainer, type ReplaceDataMatchupModalContainerProps,
} from '../data/replaceData/replaceDataMatchupModal/replaceDataMatchupModal.container';
import {
  ResolveUnableToTwoWaySyncModal, type ResolveUnableToTwoWaySyncModalProps,
} from '../data/syncData/resolveUnableToTwoWaySyncModal.component';
import {
  SyncDataModalForCreatingContainer, type SyncDataModalForCreatingContainerProps,
} from '../data/syncData/syncDataModalForCreating.container';
import {
  SyncDataModalForSyncingContainer, type SyncDataModalForSyncingContainerProps,
} from '../data/syncData/syncDataModalForSyncing.container';
import {
  DirectionsExportContainer, type DirectionsExportContainerProps,
} from '../directions/export/directionsExport.container';
import {
  GoogleSpreadsheetSelectorContainer, type GoogleSpreadsheetSelectorContainerProps,
} from '../map/createNew/createMapFromGoogleSpreadsheet/googleSpreadsheetSelector.container';
import { InitialMapPositionModal } from '../map/settings/accordion/InitialMapPositionModal';
import {
  PresentationalDataExportAllowToolsModalComponent, type PresentationalDataExportAllowToolsModalProps,
} from '../map/settings/accordion/sharedPresentationalMaps/presentationalDataExportAllowToolsModal.component';
import {
  MapSettingsOutOfSyncContainer, type MapSettingsOutOfSyncContainerProps,
} from '../map/settings/outOfSync/mapSettingsOutOfSync.container';
import { BoundaryGroupsListingContainer } from '../sidebar/sidebarApps/mapTools/boundary/boundaryGroupsListing/boundaryGroupsListing.container';
import {
  CustomizeBoundaryFillContainer, type CustomizeBoundaryFillContainerProps,
} from '../sidebar/sidebarApps/mapTools/boundary/customizeFill/customizeBoundaryFill.container';
import {
  ExportBoundaryContainer, type ExportBoundaryContainerProps,
} from '../sidebar/sidebarApps/mapTools/boundary/export/exportBoundary.container';
import {
  ExportBoundaryTerritoryContainer, type ExportBoundaryTerritoryContainerProps,
} from '../sidebar/sidebarApps/mapTools/boundary/export/exportBoundaryTerritory.container';
import {
  ExportBoundaryTerritoryGroupContainer, type ExportBoundaryTerritoryGroupContainerProps,
} from '../sidebar/sidebarApps/mapTools/boundary/export/exportBoundaryTerritoryGroup.container';
import { SubscriptionManagementContainer } from '../subscription/subscriptionManagement.container';
import { UpgradeWMSSubscriptionModalComponent } from '../subscription/upgradeWMSSubscriptionModal.component';
import { UndoSelectorContainer } from '../undoSelector/undoSelector.container';
import {
  type ModalProps, ModalType,
} from './modalType.enum';

export type ModalTypeWithArgs = AddOnClose<
[ModalType.AboutUs] |
[ModalType.AppError, AppError] |
[ModalType.ChangesPending, WithoutModalProps<ChangesPendingModalProps>] |
[ModalType.ChangePassword] |
[ModalType.ChangeEmail] |
[ModalType.Confirmation, WithoutModalProps<ConfirmationModalProps>] |
[ModalType.ContactUs] |
[ModalType.ChartsDetails, WithoutModalProps<ChartsModalProps>] |
[ModalType.CreateNewMap, WithoutModalProps<CreateNewMapContainerProps>] |
[ModalType.CreateNewSalesRepMapSuccess, WithoutModalProps<CreateNewSalesRepMapSuccessComponentProps>] |
[ModalType.ConfirmationWithAuthentication, WithoutModalProps<ConfirmationWithAuthenticationModalProps>] |
[ModalType.Notification, WithoutModalProps<NotificationModalProps>] |
[ModalType.EditLayeredMap] |
[ModalType.CopyMap, WithoutModalProps<CopyMapModalContainerProps>] |
[ModalType.MatchupData, WithoutModalProps<MatchupDataContainerProps>] |
[ModalType.MapPrivacySelector, WithoutModalProps<MapPrivacySelectorModalContainerProps>] |
[ModalType.ExportImage] |
[ModalType.AdHoc, Readonly<{ caption?: string; content: ReactNode | ((onClose: () => void) => ReactNode)}>] |
[ModalType.DirectionsEditRoute, WithoutModalProps<DirectionsPanelEditProps>] |
[ModalType.DirectionsPanelFormAdd, WithoutModalProps<DirectionsPanelFormAddModalProps>] |
[ModalType.ShareMap, WithoutModalProps<ShareMapContainerProps>] |
[ModalType.ShareMapSelect, WithoutModalProps<ShareMapSelectProps>] |
[ModalType.SendNavigationLinks, WithoutModalProps<SendNavigationLinksModalProps>] |
[ModalType.SelectMarkersForOptimizeRoute, WithoutModalProps<SelectionDialogProps>] |
[ModalType.FilterSettings] |
[ModalType.DirectionSettings, WithoutModalProps<DirectionSettingsModalContainerProps>] |
[ModalType.SearchSettings, WithoutModalProps<SearchSettingsModalContainerProps>] |
[ModalType.ProximityEdit, WithoutModalProps<ProximityEditProps>] |
[ModalType.ProximitySettings] |
[ModalType.Undo] |
[ModalType.LabelAndMarkerSettings, WithoutModalProps<CustomizeLabelAndMarkerModalContainerProps>] |
[ModalType.Loader] |
[ModalType.BoundarySettings, WithoutModalProps<BoundarySettingsContainerProps>] |
[ModalType.BoundaryGroupsListing] |
[ModalType.BoundaryTerritoryEditSubmit, WithoutModalProps<BoundaryTerritoryEditSubmitModalContainerProps>] |
[ModalType.BoundaryEditSubmit, WithoutModalProps<BoundaryEditSubmitModalContainerProps>] |
[ModalType.NumericalGroupSettings, WithoutModalProps<NumericalGroupSettingsContainerProps>] |
[ModalType.OptionsModal, WithoutModalProps<OptionsModalComponentProps>] |
[ModalType.ImpersonationChanged, WithoutModalProps<ImpersonationChangedModalProps>] |
[ModalType.ImportData, WithoutModalProps<ImportDataContainerProps>] |
[ModalType.AddDataColumn] |
[ModalType.EditData, WithoutModalProps<EditDataModalContainerProps>] |
[ModalType.AddMultipleLocations] |
[ModalType.LocationFinder, WithoutModalProps<LocationFinderModalContainerProps>] |
[ModalType.EditLocationData, WithoutModalProps<EditLocationModalContainerProps>] |
[ModalType.BulkEditLocationsData, WithoutModalProps<BulkEditLocationsDataModalContainerProps>] |
[ModalType.ExportSpreadsheetData, WithoutModalProps<ExportDataModalContainerProps>] |
[ModalType.SyncGoogleSheet, WithoutModalProps<SyncDataModalForSyncingContainerProps>] |
[ModalType.ReconnectGoogleSheet, WithoutModalProps<SyncDataModalForReconnectingGoogleSheetContainerProps>] |
[ModalType.CreateGooogleSheetMap, WithoutModalProps<SyncDataModalForCreatingContainerProps>] |
[ModalType.ExportProximityData, WithoutModalProps<ExportProximityDataContainerProps>] |
[ModalType.ExportLassoData, WithoutModalProps<ExportLassoDataModalContainerProps>] |
[ModalType.CustomizeMapUrl] |
[ModalType.ChooseSpreadsheetColumn, WithoutModalProps<ChooseColumnModalContainerProps>] |
[ModalType.RestrictZoomLevels] |
[ModalType.PublicMapColumnsDisplaySettings] |
[ModalType.MapMarkerColumnsDisplaySettings] |
[ModalType.ExportDataFunctionalitiesColumnsDisplaySettings] |
[ModalType.ImageLibrary, WithoutModalProps<ImageLibraryContainerProps>] |
[ModalType.PublicMapFiltersDisplaySettings] |
[ModalType.BaseMapsUpdate, WithoutModalProps<BaseMapsUpdateModalContainerProps>] |
[ModalType.TwoFa] |
[ModalType.GoogleSpreadsheetSelector, WithoutModalProps<GoogleSpreadsheetSelectorContainerProps>] |
[ModalType.InitialMapPosition] |
[ModalType.ImagesView, WithoutModalProps<ImageViewModalProps>] |
[ModalType.BoundaryTerritoryCreate, WithoutModalProps<BoundaryTerritoryCreateContainerProps>] |
[ModalType.BoundaryItemSettings, WithoutModalProps<BoundaryItemSettingsProps>] |
[ModalType.BaseMapDeletionConflictResolution, WithoutModalProps<HandleBaseMapDeletionModalContainerProps>] |
[ModalType.CreateLayeredMap, WithoutModalProps<CreateLayeredMapModalContainerProps>] |
[ModalType.CreateBoundaryGroupFromAI, WithoutModalProps<CreateBoundaryGroupFromAIModalContainerProps>] |
[ModalType.BoundaryCustomizeMetrics, WithoutModalProps<BoundaryCustomizeMetricsContainerProps>] |
[ModalType.BoundaryEdit, WithoutModalProps<BoundaryEditModalContainerProps>] |
[ModalType.BoundaryCreateSubmit, WithoutModalProps<BoundaryCreateSubmitModalProps>] |
[ModalType.BoundaryCustomizeFill, WithoutModalProps<CustomizeBoundaryFillContainerProps>] |
[ModalType.BoundaryZoomLevelDisclaimer, WithoutModalProps<BoundaryZoomLevelDisclaimerProps>] |
[ModalType.TeamManagement] |
[ModalType.TeamShareMap, WithoutModalProps<TeamShareMapModalContainerProps>] |
[ModalType.RemoveMemberFromMap, WithoutModalProps<RemoveMemberFromMapModalContainerProps>] |
[ModalType.ExportDirections, WithoutModalProps<DirectionsExportContainerProps>] |
[ModalType.ExportBoundaryTerritoryGroupData, WithoutModalProps<ExportBoundaryTerritoryGroupContainerProps>] |
[ModalType.ExportTerritoryData, WithoutModalProps<ExportBoundaryTerritoryContainerProps>] |
[ModalType.ExportBoundaryData, WithoutModalProps<ExportBoundaryContainerProps>] |
[ModalType.ExportContainedBoundaryData, WithoutModalProps<ExportContainedBoundariesModalComponentProps>] |
[ModalType.DownloadResults, WithoutModalProps<DownloadResultsModalProps>] |
[ModalType.MapSettingsOutOfSync, WithoutModalProps<MapSettingsOutOfSyncContainerProps>] |
[ModalType.ReplaceSpreadsheetDataMatchup, WithoutModalProps<ReplaceDataMatchupModalContainerProps>] |
[ModalType.ResolveUnableToTwoWaySync, WithoutModalProps<ResolveUnableToTwoWaySyncModalProps>] |
[ModalType.EmailNotification, WithoutModalProps<EmailSentNotificationModalProps>] |
[ModalType.RestoreDefaultMarkerCustomizations, WithoutModalProps<RestoreDefaultMarkerCustomizationsContainerProps>] |
[ModalType.PresentationalDataExportAllowTool, WithoutModalProps<PresentationalDataExportAllowToolsModalProps>] |
[ModalType.BadDataTable, WithoutModalProps<DataTableModalProps>] |
[ModalType.ExportItemizedSpreadsheetData, WithoutModalProps<ExportItemizedSpreadsheetDataModalContainerProps>] |
[ModalType.SubscriptionManagement] |
[ModalType.SubscriptionManagementUnavailable] |
[ModalType.EmbedMap] |
[ModalType.RowSettingsResolve, WithoutModalProps<RowSettingsResolveModalComponentProps>] |
[ModalType.SubscriptionExpired] |
[ModalType.WMSUpgradeSubscription] |
[ModalType.TrialExportNotice, WithoutModalProps<TrialExportNoticeModalProps>] |
[ModalType.CloneBoundaryTerritoryGroup, WithoutModalProps<CloneBoundaryTerritoryGroupModalProps>] |
[ModalType.ColumnsSettings, WithoutModalProps<ColumnsSettingsModalProps>] |
[ModalType.AfterMigrationMapPrepare] |
[ModalType.MigrateMaps, WithoutModalProps<Record<string, never>>] |
[ModalType.MigrationInProgress, WithoutModalProps<Record<string, never>>] |
[ModalType.MigrationConfirmation, WithoutModalProps<ComponentProps<typeof MigrateMapsConfirmationComponent>>] |
[ModalType.MigrationFinished, WithoutModalProps<Record<string, never>>] |
[ModalType.GroupingToolHint, WithoutModalProps<Record<string, never>>] |
[ModalType.Welcome]
>;

export type WithoutModalProps<T extends Record<string, unknown>> = Omit<T, keyof ModalProps> & Partial<Pick<ModalProps, 'onClose'>>;

type CheckArgs<T extends [ModalType] | [ModalType, Record<string, unknown>]> =
  ModalType extends T[0] ? T : ['Some ModalType enum members are not included in the type. Please include them.'];

export const createRenderModal = (modalProps: ModalProps, _id: string) => (...parameters: CheckArgs<ModalTypeWithArgs>) => {
  switch (parameters[0]) {

    case ModalType.GroupingToolHint: {
      return (
        <GroupingToolHintComponent
          {...modalProps}
        />
      );
    }

    case ModalType.MigrationFinished: {
      return (
        <MigrateMapsFinishedComponent
          {...modalProps}
        />
      );
    }

    case ModalType.MigrationConfirmation: {
      return (
        <MigrateMapsConfirmationComponent
          {...modalProps}
          {...parameters[1]}
        />
      );
    }

    case ModalType.MigrationInProgress: {
      return (
        <MigrateMapsInProgressComponent
          {...modalProps}
        />
      );
    }

    case ModalType.MigrateMaps: {
      return (
        <MigrateMapsComponent
          {...modalProps}
        />
      );
    }

    case ModalType.AppError:
      return (
        <AppErrorComponent
          appError={parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ChangePassword:
      return (
        <ChangePasswordModalComponent
          {...modalProps}
        />
      );

    case ModalType.ChangeEmail:
      return (
        <ChangeEmailModalComponent
          {...modalProps}
        />
      );

    case ModalType.ChangesPending:
      return (
        <ChangesPendingModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ChartsDetails:
      return (
        <ChartsModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.Undo:
      return (
        <UndoSelectorContainer
          {...modalProps}
        />
      );

    case ModalType.OptionsModal:
      return (
        <OptionsModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.CreateNewMap:
      return (
        <CreateNewMapContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.CreateNewSalesRepMapSuccess:
      return (
        <CreateNewSalesRepMapSuccessComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ConfirmationWithAuthentication:
      return (
        <ConfirmationWithAuthenticationModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.CopyMap:
      return (
        <CopyMapModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.MatchupData:
      return (
        <MatchupDataContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.MapPrivacySelector:
      return (
        <MapPrivacySelectorModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.LocationFinder:
      return (
        <LocationFinderModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportImage:
      return (
        <ExportImageModalContainer
          {...modalProps}
        />
      );

    case ModalType.EditData:
      return (
        <EditDataModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.EditLocationData:
      return (
        <EditLocationModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.BulkEditLocationsData:
      return (
        <BulkEditLocationsModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.AddMultipleLocations:
      return (
        <AddMultipleLocationsModalContainer
          {...modalProps}
        />
      );

    case ModalType.BoundarySettings:
      return (
        <BoundarySettingsContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.BoundaryGroupsListing:
      return (
        <BoundaryGroupsListingContainer {...modalProps} />
      );

    case ModalType.BoundaryTerritoryEditSubmit:
      return (
        <BoundaryTerritoryEditSubmitModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.BoundaryEditSubmit:
      return (
        <BoundaryEditSubmitModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.BoundaryZoomLevelDisclaimer:
      return (
        <BoundaryZoomLevelDisclaimerModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.NumericalGroupSettings:
      return (
        <NumericalGroupSettingsContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportSpreadsheetData:
      return (
        <ExportSpreadsheetDataContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.SyncGoogleSheet:
      return (
        <SyncDataModalForSyncingContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ReconnectGoogleSheet:
      return (
        <SyncDataModalForReconnectingGoogleSheetContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.CreateGooogleSheetMap:
      return (
        <SyncDataModalForCreatingContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportProximityData:
      return (
        <ExportProximityDataContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportBoundaryTerritoryGroupData:
      return (
        <ExportBoundaryTerritoryGroupContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportTerritoryData:
      return (
        <ExportBoundaryTerritoryContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportBoundaryData:
      return (
        <ExportBoundaryContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportDirections:
      return (
        <DirectionsExportContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ExportLassoData:
      return (
        <ExportLassoDataModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.AdHoc:
      return (
        <ModalComponent
          caption={parameters[1].caption}
          {...modalProps}
        >
          {typeof parameters[1].content === 'function'
            ? parameters[1].content(modalProps.onClose)
            : parameters[1].content}
        </ModalComponent>
      );

    case ModalType.DirectionsEditRoute:
      return (
        <DirectionsEditRouteComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.DirectionsPanelFormAdd:
      return (
        <DirectionsPanelFormAddModal
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ShareMap:
      return (
        <ShareMapContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ShareMapSelect:
      return (
        <ShareMapSelectComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.SelectMarkersForOptimizeRoute:
      return (
        <SelectionDialogComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ProximityEdit:
      return (
        <ProximityEditComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ProximitySettings:
      return (
        <ProximitySettingsContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.FilterSettings:
      return <FilterSettingsContainer {...modalProps} />;

    case ModalType.DirectionSettings:
      return (
        <DirectionSettingsModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.SearchSettings:
      return (
        <SearchSettingsModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.LabelAndMarkerSettings:
      return (
        <CustomizeLabelAndMarkerModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.InitialMapPosition:
      return (
        <InitialMapPositionModal
          {...modalProps}
        />
      );

    case ModalType.ImpersonationChanged:
      return (
        <ImpersonationChangedModal
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.ImportData:
      return (
        <ImportDataContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.AddDataColumn:
      return (
        <AddDataColumnContainer
          {...modalProps}
        />
      );

    case ModalType.Confirmation:
      return (
        <ConfirmationModalComponent
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.ContactUs:
      return (
        <ContactUsModalComponent
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.AboutUs:
      return (
        <AboutUsModalContainer
          {...modalProps}
        />
      );

    case ModalType.Notification:
      return (
        <NotificationModalComponent
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.ChooseSpreadsheetColumn:
      return (
        <ChooseColumnModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.ImageLibrary:
      return (
        <ImageLibraryContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.CustomizeMapUrl:
      return (
        <CustomizeMapUrlContainer
          {...modalProps}
        />
      );

    case ModalType.RestrictZoomLevels:
      return (
        <RestrictZoomLevelModalContainer
          {...modalProps}
        />
      );

    case ModalType.PublicMapColumnsDisplaySettings:
      return (
        <PublicMapColumnsDisplaySettingsContainer
          {...modalProps}
        />
      );

    case ModalType.MapMarkerColumnsDisplaySettings:
      return (
        <MapMarkerColumnsDisplaySettingsContainer
          {...modalProps}
        />
      );

    case ModalType.ExportDataFunctionalitiesColumnsDisplaySettings:
      return (
        <ExportDataFunctionalitiesColumnsDisplaySettingsContainer
          {...modalProps}
        />
      );

    case ModalType.TwoFa:
      return (
        <TwoFactorModalContainer
          {...modalProps}
        />
      );

    case ModalType.ImagesView:
      return (
        <ImageViewModalComponent
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryTerritoryCreate:
      return (
        <BoundaryTerritoryCreateContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryItemSettings:
      return (
        <BoundaryItemSettingsContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryCreateSubmit:
      return (
        <BoundaryCreateSubmitModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryCustomizeMetrics:
      return (
        <BoundaryCustomizeMetricsContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BaseMapsUpdate:
      return (
        <BaseMapsUpdateModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.CreateLayeredMap:
      return (
        <CreateLayeredMapModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.CreateBoundaryGroupFromAI:
      return (
        <CreateBoundaryGroupFromAIModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.EditLayeredMap:
      return (
        <EditLayeredMapModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryEdit:
      return (
        <BoundaryEditModalContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BoundaryCustomizeFill:
      return (
        <CustomizeBoundaryFillContainer
          {...modalProps}
          {...parameters[1]}
        />
      );

    case ModalType.BaseMapDeletionConflictResolution:
      return (
        <HandleBaseMapDeletionModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.GoogleSpreadsheetSelector:
      return (
        <GoogleSpreadsheetSelectorContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.DownloadResults:
      return (
        <DownloadResultsModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ReplaceSpreadsheetDataMatchup:
      return (
        <ReplaceDataMatchupModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.MapSettingsOutOfSync:
      return (
        <MapSettingsOutOfSyncContainer
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.ResolveUnableToTwoWaySync:
      return (
        <ResolveUnableToTwoWaySyncModal
          {...parameters[1]}
          {...modalProps}
        />
      );

    case ModalType.Loader: {
      if (!modalProps.isOpen) {
        return null;
      }

      return <OverlayLoaderComponent />;
    }

    case ModalType.TeamManagement: {
      return (
        <TeamManagementModalContainer
          {...modalProps}
        />
      );
    }

    case ModalType.TeamShareMap: {
      return (
        <TeamShareMapModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.RemoveMemberFromMap: {
      return (
        <RemoveMemberFromMapModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.RestoreDefaultMarkerCustomizations: {
      return (
        <RestoreDefaultMarkerCustomizationsModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.EmailNotification: {
      return (
        <EmailSentNotificationModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.PresentationalDataExportAllowTool: {
      return (
        <PresentationalDataExportAllowToolsModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.ExportItemizedSpreadsheetData: {
      return (
        <ExportItemizedSpreadsheetDataModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.SubscriptionManagement: {
      return (
        <SubscriptionManagementContainer
          {...modalProps}
        />
      );
    }

    case ModalType.SubscriptionManagementUnavailable: {
      return (
        <SubscriptionManagementUnavailableModalComponent
          {...modalProps}
        />
      );
    }

    case ModalType.EmbedMap: {
      return (
        <EmbedMapModalContainer
          {...modalProps}
        />
      );
    }

    case ModalType.BadDataTable: {
      return (
        <BadDataModalContainer
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.RowSettingsResolve: {
      return (
        <RowSettingsResolveModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.SubscriptionExpired: {
      return (
        <SubscriptionExpiredModal
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.WMSUpgradeSubscription: {
      return (
        <UpgradeWMSSubscriptionModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.TrialExportNotice: {
      return (
        <TrialExportNoticeModal
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.ExportContainedBoundaryData: {
      return (
        <ExportContainedBoundariesModal
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.SendNavigationLinks: {
      return (
        <SendNavigationLinksModal
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.CloneBoundaryTerritoryGroup: {
      return (
        <CloneBoundaryTerritoryGroupModal
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.AfterMigrationMapPrepare: {
      return (
        <AfterMigrationMapPrepareModal
          {...modalProps}
        />
      );
    }

    case ModalType.ColumnsSettings: {
      return (
        <ColumnsSettingsModalComponent
          {...parameters[1]}
          {...modalProps}
        />
      );
    }

    case ModalType.Welcome: {
      return (
        <WelcomeModalComponent
          {...modalProps}
        />
      );
    }

    default:
      throw new Error(`renderModal: Unknown ModalType '${parameters[0]}'.`);
  }
};

type AddOnClose<T extends [ModalType] | [ModalType, Record<string, unknown>]> =
  T extends [infer T0, infer T1]
    ? [T0, T1 & { onClose?: () => void }]
    : T extends [infer T0]
      ? [T0] | [T0, { onClose: () => void }]
      : never;
