import type {
  DataRoute, HomeRoute, MapRoute,
} from '~/app/routes/appNavigation/appNavigation.constants';
import { type PublicMapRoute } from '~/app/routes/publicMaps/publicMaps.constants';

export const HOME_ROUTE = '/' satisfies HomeRoute;
export const MAP_ROUTE = '/map/:mapId' satisfies MapRoute;
export type MapRouteParams = Readonly<{ mapId: string }>;
export const DATA_ROUTE = '/data/:mapId' satisfies DataRoute;
export type DataRouteParams = MapRouteParams;

export const PUBLIC_MAP_ROUTE = '/maps/:mapShareId' satisfies PublicMapRoute;
export type PublicMapRouteParams = Readonly<{ mapShareId: string }>;

export const SUBSCRIPTION_ROUTE = '/manage-payments';
export const SUBSCRIBE_ROUTE = '/subscribe';
export const SUBSCRIBED_ROUTE = '/subscribed';
export const SUBSCRIPTION_SUCCESSFUL_ROUTE = '/subscribe-success';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const FORGOTTEN_PASSWORD_ROUTE = '/forgot';
export const PASSWORD_RESET_ROUTE = '/password-reset';
export const UNBLOCK_ACCOUNT_ROUTE = '/unblock-account';
export const VERIFY_EMAIL_ROUTE = '/verify-email';
export const CONFIRM_EMAIL_CHANGE_ROUTE = '/confirm-email-change';
export const ACCEPT_INVITATION_ROUTE = '/accept-invitation';
export const LOTTIE_SANDBOX_ROUTE = '/lottie-sandbox';
export const IMPERSONATE_ROUTE = '/impersonate';
export const SALESFORCE_LOGIN_ROUTE = '/salesforce-login';
export const SALESFORCE_REGISTER_FINISH_ROUTE = '/salesforce-register-finish';

export const YOUTUBE_VIDEO_ROUTE = 'https://www.youtube.com/embed';

export const sandboxRoutes = [
  LOTTIE_SANDBOX_ROUTE,
];

export const excludedRoutesFromLicenseCheck = [
  SUBSCRIPTION_ROUTE,
  SUBSCRIBE_ROUTE,
  SUBSCRIBED_ROUTE,
  CONFIRM_EMAIL_CHANGE_ROUTE,
  LOGIN_ROUTE,
];

export enum Page {
  ADMIN_HOME = 'ADMIN_HOME',
  ADMIN_MAP= 'ADMIN_MAP',
  ADMIN_DATA= 'ADMIN_DATA',
  LOGIN= 'LOGIN',
  PRESENTATIONAL= 'PRESENTATIONAL',
  SUBSCRIBE = 'SUBSCRIBE',
  SUBSCRIBED = 'SUBSCRIBED',
}

export enum PageTitle {
  DATA = 'DATA',
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  MAP = 'MAP',
  PRESENTATIONAL = 'PRESENTATIONAL',
  SUBSCRIBE = 'SUBSCRIBE',
  CONGRATULATIONS = 'CONGRATULATIONS',
}
