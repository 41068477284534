import {
  FRONTEND_STATE_STREETVIEW_OFF, FRONTEND_STATE_STREETVIEW_ON, FRONTEND_STATE_STREETVIEW_SET_LOCATION,
} from './streetView.actionTypes';
import { type StreetViewLocation } from './streetView.state';

export const frontendStateStreetViewSet = (streetViewLocation: StreetViewLocation) => ({
  type: FRONTEND_STATE_STREETVIEW_SET_LOCATION,
  payload: {
    streetViewLocation,
  },
}) as const;

export const frontendStateStreetViewOff = () => ({
  type: FRONTEND_STATE_STREETVIEW_OFF,
}) as const;

export const frontendStateStreetViewOn = () => ({
  type: FRONTEND_STATE_STREETVIEW_ON,
}) as const;
