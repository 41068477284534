import {
  areGroupRadiusData, areRadiusData,
} from '../../../_shared/types/proximity/proximity.types';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import {
  addMissingSpreadsheetData, type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '../spreadsheetData.state';

export const getMissingProximitySpreadsheetData = (mapSettings: MapSettingsState, spreadsheetData: SpreadsheetDataData): MissingSpreadsheetData => {
  const missingSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  if (!mapSettings.data.proximity.proximities || mapSettings.data.proximity.proximities.length === 0) {
    return missingSpreadsheetData;
  }

  return mapSettings.data.proximity.proximities
    .reduce((accum, proximity) => {
      if (!areRadiusData(proximity.data) || !areGroupRadiusData(proximity.data.data)) {
        return accum;
      }
      const data = proximity.data.data;
      const currentSpreadsheetData = spreadsheetData.values[data.columnId.spreadsheetId];
      if (!currentSpreadsheetData || !currentSpreadsheetData[Unfiltered]?.[data.columnId.columnId]?.[DataType.GROUP]) {
        addMissingSpreadsheetData(accum, {
          spreadsheetId: data.columnId.spreadsheetId,
          columnId: data.columnId.columnId,
          filterHashOrUnfiltered: Unfiltered,
          dataType: DataType.GROUP,
          spreadsheetDataToFetchExtra: {},
        });
      }

      return accum;
    }, missingSpreadsheetData);
};
