import numeral from 'numeral';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import {
  DISTANCE_MATRIX_MAX_TO_LOCATIONS, useDistanceMatrixService,
} from '~/_shared/repositories/useDistanceMatrixService';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { ProximityType } from '~/_shared/types/proximity/proximity.enums';
import { type Proximity } from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { isNullOrUndefined } from '~/_shared/utils/typeGuards';
import { getShortUnitSystemLabel } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { ExportDataFileType } from '~/data/exportDataModal/exportDataFileType.enum';
import { useExportItemizedSpreadsheetData } from '~/data/exportDataModal/useExportItemizedSpreadsheetData';
import {
  useFilteredLatLngSpreadsheetData, useLatLngSpreadsheetData, useSpreadSheetData,
} from '~/map/map/useSpreadsheetData.hook';
import type { ExportProximityDistanceCalculate } from '~/proximityDetails/export/exportProximityDistanceCalculateMothodSection.component';
import type { ExportProximityLimits } from '~/proximityDetails/export/exportProximityLimitResultsSection.component';
import { ProximityDistanceCalculateMethod } from '~/proximityDetails/export/useExportProximityDistanceCalculate';
import { type RadiusFilterRequestArguments } from '~/spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import type { ItemizedSpreadsheetDataResponse } from '~/spreadsheet/itemizeExport.repository';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkFetchExtra,
} from '~/spreadsheet/spreadsheet.repository';
import { useMapSettingsSettingsUnitSystemSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { createAreaRequestGetterFilter } from '~/store/spreadsheetData/area/spreadsheetData.area.helpers';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  applyProximityResultsLimitsToRowsWithTravelData,
  createFrom1ToNSearchItemsFromProximitySpreadsheetData, getRequestsFromProximity,
  getRowsPerSpreadsheetResponseForInclusionType as getRowsPerProximityFromBulkGetterResponseForInclusionType,
  proximityExportAddDistanceMatrixTravelDataToRows,
  proximityExportCalculateDistanceFromCenterForRows,
} from './exportProximityData.helpers';

export type ProximityExportFilter = {
  type: ProximityType;
  proximity: Proximity;
  polygons?: MultiPolygon[];
  circle?: RadiusFilterRequestArguments;
};

export enum ExportProximityResultType {
  Success = 'Success',
  Cancelled = 'Cancelled',
}

export type SpreadsheetRowWithTravelData = Readonly<{
  rowId: SpreadsheetRowId;
  distanceFromCenter?: number;
  distanceColumnValue?: string;
  travelTimeColumnValue?: string;
}>;

export type PerProximitySpreadsheetRowsWithTravelData = ReadonlyArray<ReadonlyArray<SpreadsheetRowWithTravelData>>;

type ExportProximityDataParams = {
  spreadsheetId: number;
  proximityList: Proximity[];
  selectedSpreadsheetRowId: SpreadsheetRowId | null;
  exportAsText: boolean;
  extension: ExportDataFileType;
  exportLimits: ExportProximityLimits;
  distanceCalculate: ExportProximityDistanceCalculate;
};

type ExportProximityResult = {
  type: ExportProximityResultType.Success;
  data: ItemizedSpreadsheetDataResponse;
} | {
  type: ExportProximityResultType.Cancelled;
  data: null;
};

export const useExportProximityData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const filterTreeParams = useFilterTreeMapSettingsParams();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const columns = useSpreadsheetColumns();
  const isMountedRef = useIsComponentMountedRef();
  const latLngLookup = useLatLngSpreadsheetData();
  const { spreadsheetData } = useSpreadSheetData();
  const unitSystem = useMapSettingsSettingsUnitSystemSelector();
  const [t] = useTranslation();
  const allFilteredSpreadsheetRows = useFilteredLatLngSpreadsheetData().filter;
  const { getDistanceMatrixFast, prepareDistanceMatrixSearchRequest } = useDistanceMatrixService();
  const { openConfirmationModalAndWait, closeConfirmationModal } = useConfirmationModal();

  const {
    getExportedItemizedSpreadsheetData,
    isLoading: isItemizedExportLoading,
    isError: isItemizedExportError,
  } = useExportItemizedSpreadsheetData();

  const getExportedProximityData = ({
    spreadsheetId, proximityList, selectedSpreadsheetRowId, exportAsText, extension, exportLimits, distanceCalculate,
  }: ExportProximityDataParams) => {
    if (!clientId || !mapId || columns.length === 0
      || (distanceCalculate.calculateMethod === ProximityDistanceCalculateMethod.DrivingDistance && !!distanceCalculate.drivingDisabledReason)
    ) {
      return Promise.reject();
    }

    setIsLoading(true);
    setIsError(false);

    const proximitiesFilters: ProximityExportFilter[] = [];

    for (const proximity of proximityList) {
      const proximityRequests = getRequestsFromProximity(proximity, latLngLookup, spreadsheetData, selectedSpreadsheetRowId);

      if (proximityRequests.circles) {
        proximityRequests.circles.forEach(circle => {
          proximitiesFilters.push({
            type: ProximityType.DistanceRadius,
            proximity,
            circle,
          });
        });
      }

      if (proximityRequests.polygons && proximityRequests.polygons.length) {
        proximitiesFilters.push({
          type: ProximityType.DriveTimePolygon,
          proximity,
          polygons: proximityRequests.polygons,
        });
      }
    }

    const columnsToFetch = columns.reduce<{[columnId: string]: SpreadsheetDataBulkFetchExtra}>(
      (acc, column) => {
        acc[column.id] = {};
        return acc;
      }, {}
    );

    const requestGetters = proximitiesFilters.map(proximityFilter => ({
      spreadsheet_id: spreadsheetId,
      map_id: mapId,
      exclude_row_data: true,
      exclude_basic_data: true,
      filter: createAreaRequestGetterFilter(
        true,
        'or',
        filterTreeParams,
        spreadsheetId,
        proximityFilter.type === ProximityType.DistanceRadius && proximityFilter.circle ? [proximityFilter.circle] : null,
        proximityFilter.type === ProximityType.DriveTimePolygon && proximityFilter.polygons ? proximityFilter.polygons : null,
        null,
        null,
      ),
      columns_to_fetch: {
        [DataType.TEXT]: columnsToFetch,
      },
    }));

    return new Promise<ExportProximityResult>((resolve, reject) => {
      getSpreadsheetBulkData(clientId, { params: requestGetters })
        .then(async (spreadsheetData) => {
          const perProximityRows = getRowsPerProximityFromBulkGetterResponseForInclusionType(allFilteredSpreadsheetRows, spreadsheetId, spreadsheetData.data, exportLimits.markersInclusionType);

          let perProximityrowsWithTravelData: PerProximitySpreadsheetRowsWithTravelData = [];

          // get distance matrix drive time data if ProximityDistanceCalculateMethod.DrivingDistance was selected
          if (distanceCalculate.calculateMethod === ProximityDistanceCalculateMethod.DrivingDistance && !distanceCalculate.drivingDisabledReason) {
            const {
              searchItems,
              searchIdsPerProximityFilter,
            } = createFrom1ToNSearchItemsFromProximitySpreadsheetData(proximitiesFilters, perProximityRows);
            const distanceMatrixRequest = prepareDistanceMatrixSearchRequest(searchItems);

            if (distanceMatrixRequest.searchItemsOverLimit.length) {
              const shouldContinue = await openConfirmationModalAndWait({
                text: t('We have observed that one or more of your proximities contain over {{limit}} markers. The Driving Distance calculation currently supports a maximum of {{limit}} markers. Would you like to proceed with exporting up to {{limit}} markers per proximity, or would you prefer to cancel the export?', {
                  limit: numeral(DISTANCE_MATRIX_MAX_TO_LOCATIONS).format('0,0'),
                }),
                title: t('Exceeded proximity limit of {{limit}} markers', {
                  limit: numeral(DISTANCE_MATRIX_MAX_TO_LOCATIONS).format('0,0'),
                }),
                confirmCaption: t('Proceed'),
              });

              if (!shouldContinue) {
                // when user chooses to cancel the export, close the confirmation modal and cancel the process
                closeConfirmationModal();
                resolve({
                  type: ExportProximityResultType.Cancelled,
                  data: null,
                });
                return;
              }
              else {
                // when user confirms they want to trim to limits, just get back to earlier modal
                closeConfirmationModal();
              }
            }

            const distanceMatrixResults = await getDistanceMatrixFast(distanceMatrixRequest);

            perProximityrowsWithTravelData = proximityExportAddDistanceMatrixTravelDataToRows({
              proximityExportFilter: proximitiesFilters,
              distanceMatrixResults,
              searchIdsPerProximityFilter,
              proximitySpreadsheetData: perProximityRows,
              unitSystem,
              t,
            });
          }
          else {
            // just get crow distance
            perProximityrowsWithTravelData = proximityExportCalculateDistanceFromCenterForRows(perProximityRows, proximitiesFilters, spreadsheetId, unitSystem);
          }

          // limit the results according to the exportLimits
          perProximityrowsWithTravelData = applyProximityResultsLimitsToRowsWithTravelData(perProximityrowsWithTravelData, proximitiesFilters, exportLimits);

          // remove proximities with no results
          perProximityrowsWithTravelData = perProximityrowsWithTravelData.filter(item => item.length > 0);

          // should we show travel time and distance columns? do they have values?
          const showTravelTime = perProximityrowsWithTravelData.some(rows => rows.some(row => !isNullOrUndefined(row.travelTimeColumnValue)));
          const showDistanceColumn = perProximityrowsWithTravelData.some(rows => rows.some(row => !isNullOrUndefined(row.distanceColumnValue)));

          // adding additional column values (proximity name and distance if enabled)
          const itemizedExportItems = perProximityrowsWithTravelData.map((rows, index) => {
            const proximity = proximitiesFilters[index];

            return {
              rows: rows.map(item => {
                const distanceColumnValue = item.distanceColumnValue;
                const travelTimeColumnValue = item.travelTimeColumnValue;

                return {
                  rowId: item.rowId,
                  postfixColumnsValues: [
                    proximity?.proximity.name || '',
                    ...(showDistanceColumn && !isNullOrUndefined(distanceColumnValue) ? ['' + distanceColumnValue] : []),
                    ...(showTravelTime && !isNullOrUndefined(travelTimeColumnValue) ? [travelTimeColumnValue] : []),
                  ],
                };
              }),
            };
          });

          // TODO: this is to be removed after MD-5327 && MD-5459 is implemented
          const maxItems = 49999;
          const totalItemsToExport = itemizedExportItems.reduce((acc, item) => acc + item.rows.length, 0);
          if (totalItemsToExport > maxItems) {
            const error = `We are sorry for the inconvenience, but we are currently unable to export more than ${maxItems} locations. Your export contains ${totalItemsToExport} locations.`;
            dispatch(createAppError({
              type: AppErrorType.General,
              title: 'Too many locations to export.',
              content: error,
            }));
            setIsLoading(false);
            reject(error);
            return;
          }

          return getExportedItemizedSpreadsheetData({
            additionalPostfixColumns: [
              { columnName: t('Proximity Name') },
              ...(showDistanceColumn ? [{ columnName: t('Distance To Center') + ' (' + getShortUnitSystemLabel(unitSystem, t) + ')' }] : []),
              ...(showTravelTime ? [{ columnName: t('Travel Time') }] : []),
            ],
            extension: exportAsText ? ExportDataFileType.Csv : extension,
            exportAsText: exportAsText ? true : undefined,
            items: itemizedExportItems,
          })
            .then(response => {
              if (isMountedRef.current) {
                setIsLoading(false);
              }
              resolve({
                type: ExportProximityResultType.Success,
                data: response,
              });
            })
            .catch(error => {
              if (isMountedRef.current) {
                setIsError(true);
                setIsLoading(false);
              }
              reject(error);
            });
        })
        .catch(error => {
          if (isMountedRef.current) {
            // cancellation doesn't need any additional error handling
            if (error !== ExportProximityResultType.Cancelled) {
              setIsError(false);
            }
            else {
              setIsError(true);
            }
            setIsLoading(false);
          }
          reject(error);
        });
    });
  };

  return {
    isLoading: isLoading || isItemizedExportLoading,
    isError: isError || isItemizedExportError,
    getExportedProximityData,
  };
};
