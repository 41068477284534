import { type CSSInterpolation } from '@emotion/serialize';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { type MouseEventHandler } from 'react';
import {
  ButtonComponent, ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { useTranslation } from '~/_shared/utils/hooks';
import { BoundarySettingsTableLoadMoreCellWrapper } from '~/boundary/settings/table/row/BoundarySettingsTableCellWrapper.component';
import { BoundarySettingsTableRowWrapperComponent } from '~/boundary/settings/table/row/BoundarySettingsTableRowWrapper.component';

const stretchButtonWidthStyle = {
  width: '100%',
  maxWidth: 380,
} satisfies CSSInterpolation;

export const BoundarySettingsLoadMoreRowComponent = ({ onClick }: { readonly onClick: MouseEventHandler }) => {
  const [t] = useTranslation();

  return (
    <BoundarySettingsTableRowWrapperComponent>
      <BoundarySettingsTableLoadMoreCellWrapper>
        <ButtonComponent
          buttonStyle={ButtonStyle.Quaternary}
          css={stretchButtonWidthStyle}
          onClick={onClick}
          prefixIcon={faArrowDown}
          size={ButtonSize.Small}
          text={t('Load More')}
        />
      </BoundarySettingsTableLoadMoreCellWrapper>
    </BoundarySettingsTableRowWrapperComponent>
  );
};
