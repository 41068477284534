import { css } from '@emotion/react';
import { type FC } from 'react';

const submitEnablerInputStyle = css({
  display: 'none',
});

export const FormSubmitEnablerComponent: FC = () => {
  return (
    <input
      css={submitEnablerInputStyle}
      type="submit"
    />
  );
};
