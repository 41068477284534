import { css } from '@emotion/react';
import { useMemo } from 'react';
import { SwitchOptionsComponent } from '~/_shared/components/switchOptions/switchOptions.component';
import { type MapInfo } from '~/_shared/types/map';
import { useTranslation } from '~/_shared/utils/hooks';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '12px 16px',
});

const containerDisabled = css({
  opacity: .5,
});

const mapNameLabel = css({
  marginBottom: 15,
  fontWeight: 800,
});

const updatesLabel = css({
  margin: '10px 0 0',
});

type ConnectivitySettingsProps = Readonly<{
  mapInfo: MapInfo | null;
  onDisconnectMap: () => void;
}>;

export const ConnectivitySettingsComponent: React.FC<ConnectivitySettingsProps> = ({
  mapInfo,
  onDisconnectMap,
}) => {
  const containerCss = useMemo(() => [
    container,
    !mapInfo?.layering?.connected ? containerDisabled : undefined,
  ], [mapInfo]);
  const [t] = useTranslation();

  return (
    <div
      css={containerCss}
    >
      <span css={mapNameLabel}>{mapInfo?.name}</span>
      <SwitchOptionsComponent
        disabled={!mapInfo?.layering?.connected}
        option1={t('Connected').toUpperCase()}
        option2={t('Disconnect').toUpperCase()}
        selected={mapInfo?.layering?.connected ? 0 : 1}
        onChange={(value) => value === 1 ? onDisconnectMap() : null}
      />
      {
        mapInfo?.layering?.connected && (
          <p css={updatesLabel}>{
            mapInfo?.layering?.realTime ? t('UPDATES: in real time') : t('UPDATES: need to be approved')
          }
          </p>
        )
      }
    </div>
  );
};
