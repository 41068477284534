import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorPageWrapperComponent } from '~/app/pages/errorPage/wrapper/appErrorPageWrapper.component';

const refreshButtonStyle = css({
  margin: '12px 12px',
});

type SubscriptionPageComponentProps = {
  isError?: boolean;
};

export const SubscriptionPageComponent: FC<SubscriptionPageComponentProps> = ({ isError }) => {
  const [t] = useTranslation();

  if (isError) {
    return (
      <AppErrorPageWrapperComponent>
        {t('We encountered an error, please refresh your page and try accessing the payment portal again. If the problem persists, please contact our Customer Support')}
        <ButtonComponent
          css={refreshButtonStyle}
          text={t('Refresh Page')}
          onClick={refreshPage}
        />
      </AppErrorPageWrapperComponent>
    );
  }

  return <OverlayLoaderComponent />;
};
