import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { BoundaryGroupFromAIModalSteps } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import { type BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import type {
  BoundaryGroupId, BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { boundaryTerritoryGroupPatchSettings } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useGetBoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { boundaryDrawEditOpenModal } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import {
  boundarySelectEditCloseModal, boundarySelectEditCustomBoundaryOpenModal, boundarySelectEditDeactivate,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { useBoundarySelectSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import {
  isBoundarySelectCreateOptimizedTerritoriesSubset,
  isBoundarySelectEditBoundaryTerritory,
  isBoundarySelectEditCustomBoundary,
  isBoundarySelectEditCustomBoundarySubmit,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import {
  BoundaryEditAction, BoundaryEditManualMethod,
} from '../boundaryEditModal/boundaryEditModal.helpers';

export const useBoundarySelectSubmitModal = () => {
  const dispatch = useDispatch();
  const { openModal: openBoundaryTerritoryEditSubmitModal } = useModal(ModalType.BoundaryTerritoryEditSubmit);
  const { openModal: openBoundaryEditSubmitModal } = useModal(ModalType.BoundaryEditSubmit);
  const { openModal: openBoundaryCreateSubmitModal } = useModal(ModalType.BoundaryCreateSubmit);
  const { openModal: openBoundaryCreateGroupFromAIModal } = useModal(ModalType.CreateBoundaryGroupFromAI);
  const selectState = useBoundarySelectSelector();
  const { getBoundaryTerritoryGroupById } = useGetBoundaryTerritoryGroup();

  const closeBoundarySelectModal = useCallback(() => {
    if (selectState && isBoundarySelectCreateOptimizedTerritoriesSubset(selectState)) {
      openBoundaryCreateGroupFromAIModal({
        initialConfig: selectState.selectionModeData.configuration,
        initialStep: BoundaryGroupFromAIModalSteps.BuildingBlock,
      });
    }

    dispatch(boundarySelectEditCloseModal());
  }, [dispatch, openBoundaryCreateGroupFromAIModal, selectState]);

  const onBoundaryEditSubmit = useCallback(({ editAnother, editedBoundaryTerritoryGroupId, method, selectMethodBoundaryGroupId }:
  {
    editAnother: boolean;
    editedBoundaryTerritoryGroupId: BoundaryTerritoryGroupId;
    method: BoundaryEditManualMethod;
    selectMethodBoundaryGroupId?: BoundaryGroupId;
  }) => {
    if (method === BoundaryEditManualMethod.Selection && notNullsy(selectMethodBoundaryGroupId)) {
      dispatch(boundaryTerritoryGroupPatchSettings({
        boundaryTerritoryGroupId: editedBoundaryTerritoryGroupId,
        patcher: (currentSettings) => ({
          ...currentSettings,
          selectionEditPreferredBoundaryGroup: {
            id: selectMethodBoundaryGroupId,
          },
        }),
      }));

      if (editAnother) {
        dispatch(boundarySelectEditCustomBoundaryOpenModal({
          boundaryTerritoryGroupId: editedBoundaryTerritoryGroupId,
          selectBoundaryGroupId: selectMethodBoundaryGroupId,
          actionType: BoundaryEditAction.EditBoundaries,
        }));
      }
    }
    else if (method === BoundaryEditManualMethod.Draw) {
      if (editAnother) {
        const btg = getBoundaryTerritoryGroupById(editedBoundaryTerritoryGroupId);
        if (btg) {
          dispatch(boundaryDrawEditOpenModal({
            boundaryGroupId: btg.boundaryGroupId,
            boundaryTerritoryGroupId: editedBoundaryTerritoryGroupId,
            action: BoundaryEditAction.EditBoundaries,
          }));
        }
      }
    }
    else {
      dispatch(boundarySelectEditCloseModal());
    }
  }, [dispatch, getBoundaryTerritoryGroupById]);

  const openBoundarySelectSubmitModal = useCallback(() => {
    if (!selectState.isActive) {
      return;
    }

    if (isBoundarySelectEditCustomBoundary(selectState)) {
      const geometry: BoundaryCombineGeometryRequestItem[] = Array.from(selectState.selectedBoundaryIds).map(boundaryId => ({
        type: 'boundary',
        boundary_group_id: selectState.selectBoundaryGroupId,
        boundary_id: boundaryId,
      }));

      if (selectState.selectionModeData.action === BoundaryEditAction.CreateNewBoundary) {
        openBoundaryCreateSubmitModal({
          geometry,
          editedBoundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
          onCreate: (createAnother: boolean) => {
            if (createAnother) {
              dispatch(boundarySelectEditDeactivate());
            }
            else {
              dispatch(boundarySelectEditCloseModal());
            }
          },
        });
      }
      else {
        dispatch(boundarySelectEditCloseModal());

        const isEditingSpecificBoundary = selectState.selectionModeData.boundaryId !== undefined;

        openBoundaryEditSubmitModal({
          geometry,
          editedBoundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
          selectBoundaryGroupId: selectState.selectBoundaryGroupId,
          selectedBoundaryId: selectState.selectionModeData.boundaryId,
          method: BoundaryEditManualMethod.Selection,
          isBoundarySelectDisabled: isEditingSpecificBoundary,
          hideEditAnother: isEditingSpecificBoundary,
          onSubmit: (editAnother) => {
            onBoundaryEditSubmit({
              editAnother,
              editedBoundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
              selectMethodBoundaryGroupId: selectState.selectBoundaryGroupId,
              method: BoundaryEditManualMethod.Selection,
            });
          },
        });
      }
    }
    else if (isBoundarySelectEditBoundaryTerritory(selectState)) {
      openBoundaryTerritoryEditSubmitModal({
        boundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
        boundaryIds: Array.from(selectState.selectedBoundaryIds.keys()),
      });
    }
    else if (isBoundarySelectCreateOptimizedTerritoriesSubset(selectState)) {
      openBoundaryCreateGroupFromAIModal({
        initialConfig: {
          ...selectState.selectionModeData.configuration,
          selectedBoundaryIds: [
            ...selectState.selectedBoundaryIds,
          ],
        },
        initialStep: BoundaryGroupFromAIModalSteps.BuildingBlock,
      });
      dispatch(boundarySelectEditCloseModal());
    }
    else if (isBoundarySelectEditCustomBoundarySubmit(selectState)) {
      const boundaryId = Array.from(selectState.selectedBoundaryIds.keys())[0] ?? null;

      if (boundaryId === null) {
        return;
      }

      openBoundaryEditSubmitModal({
        editedBoundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
        selectedBoundaryId: boundaryId,
        method: selectState.selectionModeData.configuration.method,
        geometry: selectState.selectionModeData.configuration.geometry,
        submitAction: selectState.selectionModeData.configuration.submitAction,
        isBoundarySelectDisabled: false,
        selectBoundaryGroupId: selectState.selectionModeData.configuration.previousBoundaryGroupId,
        onSubmit: (editAnother) => {
          onBoundaryEditSubmit({
            editAnother,
            editedBoundaryTerritoryGroupId: selectState.selectionModeData.boundaryTerritoryGroupId,
            selectMethodBoundaryGroupId: selectState.selectionModeData.configuration.previousBoundaryGroupId,
            method: selectState.selectionModeData.configuration.method,
          });
        },
      });
      dispatch(boundarySelectEditCloseModal());
    }
  }, [
    dispatch,
    openBoundaryEditSubmitModal,
    openBoundaryTerritoryEditSubmitModal,
    selectState,
    openBoundaryCreateSubmitModal,
    openBoundaryCreateGroupFromAIModal,
    onBoundaryEditSubmit,
  ]);

  return { openBoundarySelectSubmitModal, closeBoundarySelectModal };
};
