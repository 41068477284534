import { combineReducers } from 'redux';
import {
  mapSettingsBoundariesInitialState,
  mapSettingsBoundariesReducer,
} from '../boundaries/mapSettingsBoundaries.reducer';
import {
  mapSettingsDirectionsInitialState,
  mapSettingsDirectionsReducer,
} from '../directions/mapSettingsDirections.reducer';
import { mapSettingsDrawingReducer } from '../drawing/mapSettingsDrawing.reducer';
import { mapSettingsDrawingSerializer } from '../drawing/mapSettingsDrawing.serializer';
import { mapSettingsFileAttachmentsReducer } from '../fileAttachments/fileAttachments.reducer';
import { fileAttachmentSerializer } from '../fileAttachments/fileAttachments.serializer';
import {
  mapSettingsFilteringInitialState, mapSettingsFilteringReducer,
} from '../filtering/mapSettingsFiltering.reducer';
import {
  mapSettingsGroupingInitialState, mapSettingsGroupingReducer,
} from '../grouping/mapSettingsGrouping.reducer';
import { mapSettingsHeatmapsReducer } from '../heatmaps/mapSettingsHeatmaps.reducer';
import { mapSettingsHeatmapSerializer } from '../heatmaps/mapSettingsHeatmaps.serializer';
import {
  layeredInitialState, mapSettingsLayeredReducer,
} from '../layeredMap/mapSettingsLayered.reducer';
import {
  mapSettingsMarkersGeneralInitialState,
  mapSettingsMarkersGeneralReducer,
} from '../makersGeneral/mapSettingsMarkersGeneral.reducer';
import {
  mapSettingsMapStylesInitialState,
  mapSettingsMapStylesReducer,
} from '../mapStyles/mapSettingsMapStyles.reducer';
import { mapSettingsMarkersReducer } from '../markers/mapSettingsMarkers.reducer';
import { mapSettingsMarkersSerializer } from '../markers/mapSettingsMarkers.serializer';
import {
  mapSettingsPlacesInitialState, mapSettingsPlacesReducer,
} from '../places/mapSettingsPlaces.reducer';
import { mapSettingsProximityReducer } from '../proximity/mapSettingsProximity.reducer';
import { mapSettingsProximitySerializer } from '../proximity/mapSettingsProximity.serializer';
import {
  getPublicMapSettingsInitialState, mapSettingsPublicMapSettingsReducer,
} from '../publicMapSettings/mapSettingsPublicMapSettings.reducer';
import {
  mapSettingsSearchInitialState, mapSettingsSearchReducer,
} from '../search/mapSettingsSearch.reducer';
import {
  mapSettingsSettingsInitialState, mapSettingsSettingsReducer,
} from '../settings/mapSettingsSettings.reducer';
import { mapSettingsToolsStateReducer } from '../toolsState/mapSettingsToolsState.reducer';
import { mapSettingsToolsStateSerializer } from '../toolsState/mapSettingsToolsState.serializer';
import { type MapSettingsDataAction } from './mapSettingsData.action';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from './mapSettingsData.actionTypes';

const regularReducer = combineReducers({
  settings: mapSettingsSettingsReducer,
  grouping: mapSettingsGroupingReducer,
  fileAttachments: mapSettingsFileAttachmentsReducer,
  filtering: mapSettingsFilteringReducer,
  proximity: mapSettingsProximityReducer,
  layered: mapSettingsLayeredReducer,
  directions: mapSettingsDirectionsReducer,
  boundaries: mapSettingsBoundariesReducer,
  heatmaps: mapSettingsHeatmapsReducer,
  drawing: mapSettingsDrawingReducer,
  mapStyles: mapSettingsMapStylesReducer,
  markers: mapSettingsMarkersReducer,
  markersGeneral: mapSettingsMarkersGeneralReducer,
  toolsState: mapSettingsToolsStateReducer,
  search: mapSettingsSearchReducer,
  publicMapSettings: mapSettingsPublicMapSettingsReducer,
  places: mapSettingsPlacesReducer,
});

export const mapSettingsDataReducer = (
  state: ReturnType<typeof regularReducer> | undefined,
  action: MapSettingsDataAction
) => {
  if (action.type === MAP_SETTINGS_FETCH_DATA_SUCCESS) {
    const actionData = action.payload.mapSettingsData;

    state = {
      boundaries: {
        ...mapSettingsBoundariesInitialState,
        ...actionData.boundaries,
      },
      directions: {
        ...mapSettingsDirectionsInitialState,
        ...actionData.directions,
      },
      drawing: mapSettingsDrawingSerializer.jsonToState(actionData.drawing),
      fileAttachments: fileAttachmentSerializer.jsonToState(actionData.fileAttachments),
      filtering: {
        ...mapSettingsFilteringInitialState,
        ...actionData.filtering,
      },
      grouping: {
        ...mapSettingsGroupingInitialState,
        ...actionData.grouping,
      },
      heatmaps: mapSettingsHeatmapSerializer.jsonToState(actionData.heatmaps),
      layered: {
        ...layeredInitialState,
        ...actionData.layered,
      },
      mapStyles: {
        ...mapSettingsMapStylesInitialState,
        ...actionData.mapStyles,
        sliders: {
          ...mapSettingsMapStylesInitialState.sliders,
          ...actionData.mapStyles.sliders,
        },
        styling: {
          ...mapSettingsMapStylesInitialState.styling,
          ...actionData.mapStyles.styling,
        },
      },
      markersGeneral: {
        ...mapSettingsMarkersGeneralInitialState,
        ...actionData.markersGeneral,
      },
      markers: mapSettingsMarkersSerializer.jsonToState(actionData.markers),
      proximity: mapSettingsProximitySerializer.jsonToState(actionData.proximity),
      search: {
        ...mapSettingsSearchInitialState,
        ...actionData.search,
      },
      settings: {
        ...mapSettingsSettingsInitialState,
        ...actionData.settings,
      },
      toolsState: mapSettingsToolsStateSerializer.jsonToState(actionData.toolsState),
      publicMapSettings: {
        ...getPublicMapSettingsInitialState(),
        ...actionData.publicMapSettings,
      },
      places: {
        ...mapSettingsPlacesInitialState,
        ...actionData.places,
      },
    };
  }

  return regularReducer(state, action);
};
