import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import {
  childrenStyles,
  headerStyles,
  iconsStyles,
  interactiveIconStyles,
  leftHeaderStyles,
  mainStyles,
  rightHeaderStyles,
  titleStyle, titleStyles,
} from '~/_shared/components/toolboxContainer/toolboxContainer.styles';
import { useMobileTopBarOpenSelector } from '~/store/frontendState/mobileUI/mobileUI.selectors';
import { useTheme } from '../../themes/theme.hooks';

type AdditionalAction = Readonly<{
  icon: IconProp;
  onClick: () => void;
}>;

type ToolboxContainerProps = Readonly<{
  title: string;
  titleIcon?: IconProp;
  onClose?: () => void;
  additionalAction?: AdditionalAction;
  className?: string;
  onTitleClick?: () => void;
  children?: React.ReactNode;
}>;

export const ToolboxContainerComponent: FC<ToolboxContainerProps> = props => {
  const theme = useTheme();
  const isMobileTopBarOpen = useMobileTopBarOpenSelector();

  const adjustedTitle = useMemo(() =>
    props.title.match(/.*?\/|.+$/g)?.map((line, index) => <span key={index}>{line}</span>), //insures text breaks after "/"
  [props.title]);

  return (
    <div css={mainStyles({ theme })}>
      <div
        css={headerStyles({ theme })}
        onClick={props.onTitleClick}
      >
        <div css={leftHeaderStyles}>
          {props.titleIcon && (
            <FontAwesomeIcon
              css={iconsStyles}
              icon={props.titleIcon}
            />
          )}
          <LimitNumberOfLinesComponent
            css={titleStyles({ needsIndent: !props.additionalAction })}
            maxNumberOfLines={2}
          >

            <span css={titleStyle}>
              {adjustedTitle}
            </span>
          </LimitNumberOfLinesComponent>
        </div>
        <div css={rightHeaderStyles}>
          {props.additionalAction && (
            <FontAwesomeIcon
              css={interactiveIconStyles}
              icon={props.additionalAction.icon}
              onClick={props.additionalAction.onClick}
            />
          )}
          {props.onClose && (
            <FontAwesomeIcon
              css={interactiveIconStyles}
              icon={faXmark}
              onClick={props.onClose}
              data-testid="close-sidebar"
            />
          )}
        </div>
      </div>

      <div css={childrenStyles({ theme, isSpaceReduced: isMobileTopBarOpen })}>
        {props.children}
      </div>
    </div>
  );
};
