import { type TranslationFnc } from '../../hooks';

export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};

export const MIN_PASSWORD_LENGTH = 8;

export const validatePasswords = (password: string, confirmPassword: string): boolean => {
  return password.length >= MIN_PASSWORD_LENGTH && validatePassword(password) && password === confirmPassword;
};

const passwordLettersLowerCaseRegex = /[a-z]/;
const passwordLettersUpperCaseRegex = /[A-Z]/;
const passwordNumericRegex = /[0-9]/;
const specialRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const validatePassword = (password: string): boolean =>
  password.length >= MIN_PASSWORD_LENGTH &&
  passwordLettersLowerCaseRegex.test(password) &&
  passwordLettersUpperCaseRegex.test(password) &&
  passwordNumericRegex.test(password) &&
  specialRegex.test(password);

export const createPasswordErrorMessage = (password: string, t: TranslationFnc): string[] => {
  const errorMessages: string[] = [];
  if (password.length < MIN_PASSWORD_LENGTH) {
    errorMessages.push(t('Password minimum 8 characters'));
  }
  if (!passwordLettersLowerCaseRegex.test(password) || !passwordLettersUpperCaseRegex.test(password)) {
    errorMessages.push(t('Password minimum 1 capital and 1 lowercase letter.'));
  }
  if (!passwordNumericRegex.test(password) || !specialRegex.test(password)) {
    errorMessages.push(t('Password minimum 1 number and 1 special symbol.'));
  }
  return errorMessages;
};
