import {
  useCallback, useState,
} from 'react';

export const useProgressTracker = (steps: number) => {
  const [currentProgress, setCurrentProgress] = useState(0);

  const progressStep = 100 / steps;
  const updateProgressByStep = useCallback(() => setCurrentProgress((curr) => {
    const newProgress = curr + progressStep;
    if (newProgress > 100) {
      return 100;
    }
    return newProgress;
  }), [progressStep]);

  return {
    updateProgressByStep,
    currentProgress,
  };
};
