import { css } from '@emotion/react';
import type { FC } from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { UserTwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { TwoFactorCommonComponent } from './twoFactorCommon.component';
import { TwoFactorGoogleComponent } from './twoFactorGoogle.component';

type TwoFactorModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  selectedTwoFactorMode: UserTwoFactorMode | null;
  setSelectedTwoFactorMode: (mode: UserTwoFactorMode | null) => void;
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  isAlertOpen: boolean;
  qrCode: string | null;
  closeAlert: () => void;
  onTwoFactorGoogleSubmit: (code: string) => void;
  toggleTwoFactorCallBack: (toggle: boolean) => void;
  isTwoFactorOn: boolean;
}>;

const rootStyle = css({
  fontSize: '14px',
  padding: 0,
});

const twoFactorStyle = css({
  padding: 20,
});

const radioGroupStyle = css({
  gap: 30,
});

const labelStyle = css({
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: 30,
});

const accordionLabelStyle = css({
  fontSize: '16px',
});

const headerStyle = (theme: Theme) => css({
  alignItems: 'center',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  cursor: 'default',
  display: 'flex',
  minHeight: 56,
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%',
  opacity: 1,
  fontWeight: 'bold',

  '&:first-of-type': {
    borderTop: 'none',
  },
});

export const TwoFactorModalComponent: FC<TwoFactorModalProps> = (props) => {
  const [t] = useTranslation();

  const items = [{
    label: <span css={accordionLabelStyle}>{t('Email')}</span>,
    value: UserTwoFactorMode.TwoFactorViaEmail,
    children: props.selectedTwoFactorMode === UserTwoFactorMode.TwoFactorViaEmail ? (
      <TwoFactorCommonComponent
        isLoading={props.isLoading}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
        isAlertOpen={props.isAlertOpen}
        closeAlert={props.closeAlert}
      />
    ) : null,
  }, {
    label: <span css={accordionLabelStyle}>{t('Google Authenticator')}</span>,
    value: UserTwoFactorMode.TwoFactorViaGoogle,
    children: props.selectedTwoFactorMode === UserTwoFactorMode.TwoFactorViaGoogle ? (
      <TwoFactorGoogleComponent
        isLoading={props.isLoading}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
        isAlertOpen={props.isAlertOpen}
        closeAlert={props.closeAlert}
        qrCode={props.qrCode}
        onTwoFactorGoogleSubmit={props.onTwoFactorGoogleSubmit}
      />
    ) : null,
  }];

  return (
    <ModalComponent
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
      caption={t('Two Factor Authentication')}
      contentStyle={rootStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      skipCloseOnMapIdChange
    >
      <div css={twoFactorStyle}>
        <div css={headerStyle}>
          <span>
            {t('Enable Two-Factor Authentication')}
          </span>
          <ToggleComponent
            onChange={(value) => {
              props.toggleTwoFactorCallBack(value);
            }}
            isOn={props.isTwoFactorOn}
          />
        </div>
        {props.isTwoFactorOn && (
          <>
            <div css={labelStyle}>{t('Choose a Two-Factor authentication method')}</div>
            <RadioGroupComponent
              css={radioGroupStyle}
              items={items}
              onValueChange={newValue => props.setSelectedTwoFactorMode(Object.values(UserTwoFactorMode).find(b => b === newValue) || null)}
              selectedValue={props.selectedTwoFactorMode}
            />
          </>
        )}
      </div>
    </ModalComponent>
  );
};
