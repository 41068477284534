import {
  type SelectedDataMetric, SelectedDataType,
} from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type SpreadsheetColumn } from '../../types/spreadsheetData/spreadsheetColumn';
import { type MetricsDataAction } from './metrics.types';

export type SpreadsheetColumnMetricModel = {
  type: SelectedDataType.SpreadsheetColumn;
  spreadsheetColumn: SpreadsheetColumn;
  dataAction: MetricsDataAction;
};

export const createSpreadsheetMetricFromSelectedData = (selectedData: SelectedDataMetric): SpreadsheetColumnMetricModel | null => {
  if (selectedData.type !== SelectedDataType.SpreadsheetColumn) {
    return null;
  }

  return ({
    type: SelectedDataType.SpreadsheetColumn,
    dataAction: selectedData.dataAction,
    spreadsheetColumn: selectedData.selectedSpreadsheetColumn,
  });
};

export const createSelectedDataFromSpreadsheetColumnMetric = (metric: SpreadsheetColumnMetricModel): SelectedDataMetric => {
  return ({
    type: SelectedDataType.SpreadsheetColumn,
    dataAction: metric.dataAction,
    selectedSpreadsheetColumn: metric.spreadsheetColumn,
  });
};
