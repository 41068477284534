import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const tableDataRowStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
  height: 56,
});

export const BoundarySettingsTableRowWrapperComponent = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <tr css={tableDataRowStyle({ theme })}>
      {children}
    </tr>
  );
};
