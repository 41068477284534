import { combineReducers } from 'redux';
import { addLocationReducer } from './addLocation/addLocation.reducer';
import { boundaryToolStateReducer } from './boundary/boundaryToolState.state';
import { directionsReducer } from './directions/directions.reducer';
import { distanceCalculatorReducer } from './distanceCalculator/distanceCalculator.reducer';
import { mapToolsDrawingReducer } from './drawing/mapToolsDrawing.reducer';
import { lassoReducer } from './lasso/lasso.reducer';
import { locationFinderReducer } from './locationFinder/locationFinder.reducer';
import { markersSelectReducer } from './markersSelect/markersSelect.reducer';
import { myLocationReducer } from './myLocation/myLocation.reducer';
import { searchReducer } from './search/search.reducer';
import { streetViewReducer } from './streetView/streetView.reducer';

export const mapToolsReducer = combineReducers({
  addLocation: addLocationReducer,
  boundary: boundaryToolStateReducer,
  directions: directionsReducer,
  distanceCalculator: distanceCalculatorReducer,
  drawing: mapToolsDrawingReducer,
  lasso: lassoReducer,
  locationFinder: locationFinderReducer,
  markersSelect: markersSelectReducer,
  myLocation: myLocationReducer,
  search: searchReducer,
  streetView: streetViewReducer,
});
