import { type MapAction } from '~/store/map/map.action';
import { type MapIdAction } from './mapId.action';
import { MAP_ID_SET } from './mapId.actionTypes';
import { type MapIdState } from './mapId.state';

const initialState: MapIdState = null;

export const mapIdReducer = (state = initialState, action: MapIdAction | MapAction): MapIdState => {
  switch (action.type) {
    case MAP_ID_SET: {
      return action.payload.mapId;
    }

    default: {
      return state;
    }
  }
};
