import { useMemo } from 'react';
import type { LatLng } from '~/_shared/types/latLng';
import type { GroupRadiusProximity } from '~/_shared/types/proximity/proximity.types';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { getGroupRadiusProximityDetails } from '~/proximity/proximity.helpers';
import { isProximityBigEnough } from './isProximityBigEnough.helper';
import { useProximityLocation } from './useProximityLocation';

type VisibleGroupProximityLocationsProps = Readonly<{
  proximities: ReadonlyArray<GroupRadiusProximity>;
  zoom: number;
}>;

export const useVisibleGroupProximityLocations = ({
  proximities,
  zoom,
}: VisibleGroupProximityLocationsProps) => {
  const hideSmallRadii = useSelector(s => s.map.mapSettings.data.proximity.hideSmallRadii);

  const { getGroupRadiusProximityLocations } = useProximityLocation();

  // we combine hideSmallRadii and zoom to one variable
  // so when hideSmallRadii is not enabled, we don't do unnecessary processing
  const hideSmallRadiiZoom = hideSmallRadii ? zoom : null;

  const locations = useMemo(() => proximities.map(proximity =>
    getGroupRadiusProximityLocations(proximity)
  ), [getGroupRadiusProximityLocations, proximities]);

  const visibleLocations = useMemo(() => locations.map((locationsPerProximity, index) => {
    const proximity = proximities[index];
    if (proximity) {
      return locationsPerProximity.filter((loc): loc is Readonly<{ spreadsheetRowId: SpreadsheetRowId; latLng: LatLng }> => !!loc.latLng)
        .filter(loc => hideSmallRadiiZoom === null || isProximityBigEnough(getGroupRadiusProximityDetails(proximity, loc.spreadsheetRowId), hideSmallRadiiZoom));
    }
    return [];
  }), [hideSmallRadiiZoom, locations, proximities]);

  return visibleLocations;
};
