import { type Theme } from '../../../themes/theme.model';
import { type ButtonStyleProps } from '../button/button.types';

const primaryStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundPrimary,
  backgroundHoverColor: theme.iconColors.backgroundPrimaryHover,
  borderHoverColor: theme.iconColors.borderPrimaryHover,
  textColor: theme.iconColors.primary,
});

const primaryInvertedStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundPrimaryInverted,
  backgroundHoverColor: theme.iconColors.backgroundPrimaryInvertedHover,
  borderHoverColor: theme.iconColors.borderPrimaryInvertedHover,
  textColor: theme.iconColors.primaryInverted,
});

const secondaryStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundSecondary,
  backgroundHoverColor: theme.iconColors.backgroundSecondaryHover,
  borderHoverColor: theme.iconColors.borderSecondaryHover,
  textColor: theme.iconColors.secondary,
  textHoverColor: theme.iconColors.secondaryHover,
});

const secondaryInvertedStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundSecondaryInverted,
  backgroundHoverColor: theme.iconColors.backgroundSecondaryInvertedHover,
  borderHoverColor: theme.iconColors.secondaryInverted,
  textColor: theme.iconColors.secondaryInverted,
});

const tertiaryStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundTertiary,
  backgroundHoverColor: theme.iconColors.backgroundTertiaryHover,
  borderHoverColor: theme.iconColors.borderTertiaryHover,
  textColor: theme.iconColors.highContrast,
});

const quaternaryStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundQuaternary,
  backgroundHoverColor: theme.iconColors.backgroundQuaternaryHover,
  borderHoverColor: theme.iconColors.borderQuaternaryHover,
  textColor: theme.iconColors.quaternary,
});

const disabledStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundPrimaryHover,
  backgroundHoverColor: theme.iconColors.backgroundPrimaryHover,
  textColor: theme.iconColors.primary,
});

const dangerButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundPrimary,
  backgroundHoverColor: theme.iconColors.backgroundPrimaryHover,
  borderHoverColor: theme.iconColors.danger,
  textColor: theme.iconColors.danger,
});

const dangerInvertedButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundDangerInverted,
  backgroundHoverColor: theme.iconColors.backgroundDangerInvertedHover,
  textColor: theme.iconColors.tertiary,
  borderHoverColor: theme.iconColors.borderDangerInvertedHover,
});

const confirmButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundPrimary,
  backgroundHoverColor: theme.iconColors.backgroundPrimaryHover,
  borderHoverColor: theme.iconColors.success,
  textColor: theme.iconColors.success,
});

const confirmInvertedButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.iconColors.backgroundSuccessInverted,
  backgroundHoverColor: theme.iconColors.backgroundSuccessInvertedHover,
  borderHoverColor: theme.iconColors.borderSuccessInvertedHover,
  textColor: theme.iconColors.tertiary,
});

const restrictedButtonStyle = (theme: Theme): ButtonStyleProps => ({
  ...primaryStyle(theme),
  borderHoverColor: theme.alertColors.warningPrimary,
  border: `2px solid ${theme.alertColors.warningPrimary}`,
});

const restrictedInvertedButtonStyle = (theme: Theme): ButtonStyleProps => ({
  backgroundColor: theme.backgroundColors.warning,
  backgroundHoverColor: theme.backgroundColors.warningHover,
  borderHoverColor: theme.borderColors.warningHover,
  textColor: theme.iconColors.tertiary,
});

const emptyStylesForCustomButton = (): ButtonStyleProps => ({
  backgroundColor: 'inherit',
  backgroundHoverColor: 'inherit',
  textColor: 'inherit',
});

export const RoundButtonStyles = (theme: Theme): Readonly<{ [key in RoundButtonStyle]: ButtonStyleProps}> => ({
  [RoundButtonStyle.Primary]: primaryStyle(theme),
  [RoundButtonStyle.Secondary]: secondaryStyle(theme),
  [RoundButtonStyle.Tertiary]: tertiaryStyle(theme),
  [RoundButtonStyle.Quaternary]: quaternaryStyle(theme),
  [RoundButtonStyle.Disabled]: disabledStyle(theme),
  [RoundButtonStyle.Danger]: dangerButtonStyle(theme),
  [RoundButtonStyle.Confirm]: confirmButtonStyle(theme),
  [RoundButtonStyle.PrimaryInverted]: primaryInvertedStyle(theme),
  [RoundButtonStyle.SecondaryInverted]: secondaryInvertedStyle(theme),
  [RoundButtonStyle.DangerInverted]: dangerInvertedButtonStyle(theme),
  [RoundButtonStyle.ConfirmInverted]: confirmInvertedButtonStyle(theme),
  [RoundButtonStyle.Restricted]: restrictedButtonStyle(theme),
  [RoundButtonStyle.RestrictedInverted]: restrictedInvertedButtonStyle(theme),
  [RoundButtonStyle.Custom]: emptyStylesForCustomButton(),
});

export enum RoundButtonStyle {
  Confirm = 'Confirm',
  Danger = 'Danger',
  Disabled = 'Disabled',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Quaternary = 'Quaternary',
  PrimaryInverted = 'PrimaryInverted',
  SecondaryInverted = 'SecondaryInverted',
  DangerInverted = 'DangerInverted',
  ConfirmInverted = 'ConfirmInverted',
  Restricted = 'Restricted',
  RestrictedInverted = 'RestrictedInverted',
  Custom = 'Custom',
}
