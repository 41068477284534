import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

export type BreadcrumbItem = Readonly<{
  name: string;
  onClick: () => void;
}>;

type Props = Readonly<{
  breadcrumbs: ReadonlyArray<BreadcrumbItem>;
  wrapperStyle?: SerializedStyles;
}>;

const separatorStyle = css({
  margin: '0 5px',
});

const createBreadcrumbSeparator = (key: string) => (
  <FontAwesomeIcon
    key={key}
    css={separatorStyle}
    icon={faChevronRight}
  />
);

const rootStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  height: 56,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  width: '100%',
  alignItems: 'center',
  padding: '10px 10px 10px 5px',
  boxSizing: 'border-box',
  backgroundColor: theme.backgroundColors.primary,
  color: theme.textColors.primary,
});

const itemStyle = ({ theme }: ThemeProps) => css({
  borderRadius: 5,
  cursor: 'pointer',
  overflow: 'hidden',
  padding: 5,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const lastItemStyle = css({
  cursor: 'default',
  pointerEvents: 'none',
});

export const MultilevelMenuBreadcrumbs: FC<Props> = props => {
  const theme = useTheme();

  return (
    <nav css={css(rootStyle({ theme }), props.wrapperStyle)}>
      {
        ([] as readonly ReactNode[])
          .concat(...props.breadcrumbs.map((breadcrumb, index) => [
            createBreadcrumbSeparator(`icon-${index}`),
            (
              <div
                key={index}
                css={css(itemStyle({ theme }), index === props.breadcrumbs.length - 1 && lastItemStyle)}
                onClick={breadcrumb.onClick}
              >
                {breadcrumb.name}
              </div>
            ),
          ]))
          .slice(1)
      }
    </nav>
  );
};
