import { css } from '@emotion/react';
import {
  type FC, useCallback, useContext,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import { SpreadsheetContext } from '~/_shared/components/spreadsheet/spreadsheetContext';
import type { CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { StickyComponent } from '../../sticky/sticky.component';
import { SpreadsheetHeaderComponent } from '../header/spreadsheetHeader.component';
import { type GridElement } from '../spreadsheet.types';
import SheetRendererProps = ReactDataSheet.SheetRendererProps;

type SpreadsheetSheetProps = SheetRendererProps<GridElement, string>;

const stickyComponentStyle = css({
  width: '100%',
  transition: 'top 150ms',
});

export const SpreadsheetSheetComponent: FC<SpreadsheetSheetProps> = (props) => {
  const {
    columns,
    data,
    getColumnWidth,
    onResizerPointerDown,
    onSelectedRowsChange,
    onSortChange,
    selectableRows,
    selectedRows,
    useResizePlaceholder,
  } = useContext(SpreadsheetContext);

  const handleSelectAllChanged = useCallback(() => {
    const newSelectedRows: Record<CombinedRowId, true> = {};

    if (Object.keys(selectedRows).length < data.length) {
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        const rowId = data[rowIndex]?.rowId;

        if (rowId === undefined) {
          continue;
        }

        newSelectedRows[rowId] = true;
      }
    }

    onSelectedRowsChange(newSelectedRows);
  }, [onSelectedRowsChange, selectedRows, data]);

  if (columns.length === 0) {
    return null;
  }

  const Header = (
    <SpreadsheetHeaderComponent
      areAllRowsSelected={Object.keys(selectedRows).length === data.length && data.length > 0}
      columns={columns}
      getColumnWidth={getColumnWidth}
      onResizerPointerDown={onResizerPointerDown}
      onSelectAll={handleSelectAllChanged}
      onSortChange={onSortChange}
      selectableRows={selectableRows}
      useResizePlaceholder={useResizePlaceholder}
    />
  );

  return (
    <div className={props.className}>
      <StickyComponent
        css={stickyComponentStyle}
      >
        {Header}
      </StickyComponent>

      <div className="data-body">
        {props.children}
      </div>
    </div>
  );
};
