import {
  type FC,
  memo,
  useEffect,
} from 'react';
import { useMapObjectContext } from '../private/mapObjectContext';
import { useMapShapeContext } from './private/mapShapeContext';

export type MapShapeRemoveButtonVisualsConfig = {
  color: WebglColor;
};

export type MapShapeRemoveButtonProps = {
  visuals: MapShapeRemoveButtonVisualsConfig;
  anchorOutlineId?: string;
  onClick: (e: MapObjectClickEventArgs) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

const MapShapeRemoveButton: FC<MapShapeRemoveButtonProps> = ({ visuals, anchorOutlineId, onClick, onMouseOver, onMouseOut }) => {
  const { manager, zIndex } = useMapObjectContext();
  const { shape, isPolygon } = useMapShapeContext();

  // Show button
  useEffect(() => {
    if (!shape.outlines) {
      return;
    }

    const predefinedAnchor = shape.outlines.find(outline => outline.id === anchorOutlineId);
    const anchor = predefinedAnchor ? predefinedAnchor : isPolygon ? shape.outlines[0] : shape.outlines.lastItem;

    manager.upsertMapShapeXMarkMarker(shape, anchor, zIndex.removeButton, isPolygon, visuals.color);
  }, [manager, shape, visuals, isPolygon, zIndex.removeButton, anchorOutlineId]);

  // Remove button on unmount
  useEffect(() => {
    return () => {
      manager.removeXMarkMarker(shape.id);
    };
  }, [manager, shape.id]);

  // Register / remove mouse events
  // Must be after remove of Xmark marker on unmount, so that 'mouse out' handler
  // is still registered and called if mouse was over the button when marker was removed
  useEffect(() => {
    const cleanUpClick = manager.addXMarkMarkerEventListener(shape.id, 'click', onClick);

    const cleanMouseOver = manager.addXMarkMarkerEventListener(shape.id, 'mouseover', () => {
      onMouseOver?.();
    });

    const cleanMouseOut = manager.addXMarkMarkerEventListener(shape.id, 'mouseout', () => {
      onMouseOut?.();
    });

    return () => {
      cleanUpClick();
      cleanMouseOver();
      cleanMouseOut();
    };
  }, [manager, onClick, onMouseOut, onMouseOver, shape.id]);

  return null;
};

const pureComponent = memo(MapShapeRemoveButton);
export { pureComponent as MapShapeRemoveButton };
