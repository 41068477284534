import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Fragment, useCallback,
  useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyles } from '~/_shared/baseComponents/buttons/button/button.styles';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { copy } from '~/_shared/utils/collections/collections';
import {
  type TranslationFnc,
  useTranslation,
} from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import {
  s, Trans,
} from '~/translations/Trans';
import { getFlatActions } from './baseMapsUpdateModal.helpers';
import {
  type BaseMap,
  BaseMapsUpdateModalAction, type BaseMapsUpdateModalActionForMap,
} from './baseMapsUpdateModal.types';

const ContentWrapper = styled.div<{theme: Theme}>`
  height: 250px;
`;

const InformationPane = styled.div`
  margin-left: 33px;
  margin-right: 33px;
  padding-top: 23px;    // Dont change to margin or scrollbar will appear
  margin-bottom: 67px;
`;

const MainInformation = styled.div`
  font-size: 18px;
`;

const Note = styled.div<ThemeProps>`
  font-size: 16px;
  color: ${props => props.theme.textColors.secondary};
  margin-top: 46px;
`;

const NoteLine = styled.div`
  margin-bottom: 18px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 140px 200px;
  grid-template-rows: 37px;
  grid-auto-rows: 47px;
  grid-column-gap: 37px;
  margin-left: 33px;
  margin-right: 33px;
`;

const modalStyle = css({
  padding: 0,
});

const Header = styled.span`
  font-size: 22px;
  font-weight: 400;
  white-space: nowrap;
`;

const MapNameHeader = styled.div`
  grid-row: 1 /2;
  grid-column: 1/2;
`;

const NewUpdatesHeader = styled.div`
  grid-row: 1 /2;
  grid-column: 2/3;
`;

const ActionHeader = styled.div`
  grid-row: 1 /2;
  grid-column: 3/4;
`;

const MapNameWrapper = styled.div`
  grid-column: 1/2;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const MapName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const NewUpdates = styled.div`
  grid-column: 2/3;
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const dropdownContainerStyle = css({
  width: '100%',
});

const CancelButtonStyle = (theme: Theme) => css({
  backgroundColor: theme.buttonColors.secondaryBackground,
  '&:hover': {
    background: theme.buttonColors.secondaryBackgroundHover,
  },
});

const HeaderStyle = (theme: Theme) => css({
  gridRow: '1/2',
  gridColumn: '1/5',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  paddingBottom: '10.5px',
});

const ApplyChangesLabel = styled.span<ThemeProps>`
  color: ${props => props.theme.textColors.success};
`;

const NeverForThisBaseMapLabel = styled.span<ThemeProps>`
  color: ${props => props.theme.textColors.danger};
`;

const DropdownOptionStyle = (action: BaseMapsUpdateModalAction | undefined, theme: Theme) => css({
  color: action === BaseMapsUpdateModalAction.Apply ?
    theme.textColors.success
    : action === BaseMapsUpdateModalAction.NeverForThisBaseMap
      ? theme.textColors.danger
      : 'default',
});

const createActionOptions = (t: TranslationFnc): DropdownOption<BaseMapsUpdateModalAction>[] => ([{
  name: t('Apply changes'),
  value: BaseMapsUpdateModalAction.Apply,
}, {
  name: t('Ask me later'),
  value: BaseMapsUpdateModalAction.AskMeLater,
}, {
  name: t('Skip this update'),
  value: BaseMapsUpdateModalAction.SkipUpdate,
}, {
  name: t('Never for this base map'),
  value: BaseMapsUpdateModalAction.NeverForThisBaseMap,
}]);

export type BaseMapsUpdateModalComponentProps = ModalProps<{
  baseMaps: BaseMap[];
  isLoading: boolean;

  onSubmit: (actionsForMap: BaseMapsUpdateModalActionForMap[]) => void;
}>;

export const BaseMapsUpdateModalComponent: React.FC<BaseMapsUpdateModalComponentProps> = (props) => {
  const theme = useTheme();
  const [t] = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<ReadonlyMap<number, BaseMapsUpdateModalAction | undefined>>(
    new Map<number, BaseMapsUpdateModalAction | undefined>(
      props.baseMaps.map(map => ([map.id, undefined]))
    )
  );
  const getSelectedOption = useCallback((mapId: number) => selectedOptions.get(mapId), [selectedOptions]);
  const setSelectedOption = useCallback((selectedValue: BaseMapsUpdateModalAction | undefined, mapId: number) => {
    setSelectedOptions(prevState => {
      return copy.andReplace(prevState, mapId, selectedValue);
    });
  }, []);
  const anyUnselected = useMemo(() => [...selectedOptions.values()].some(action => !action), [selectedOptions]);
  const actionOptions = useMemo(() => createActionOptions(t), [t]);
  const applyItemCustomStyle = useCallback((item: BaseMapsUpdateModalAction) => DropdownOptionStyle(item, theme), [theme]);

  return (
    <ModalComponent
      isOpen={props.isOpen}
      isCloseDisabled
      onClose={props.onClose}
      contentStyle={modalStyle}
      caption={t('Base Map(s) Updated')}
      maxWidth={850}
      leftFooterContent={(
        <ButtonComponent
          text={t('Not Now')}
          onClick={props.onClose}
          css={[ButtonStyles(theme).Tertiary, CancelButtonStyle(theme)]}
        />
      )}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
      confirmButton={(
        <ButtonComponent
          text={t('Confirm')}
          isDisabled={anyUnselected}
          onClick={() => props.onSubmit(getFlatActions(selectedOptions, props.baseMaps))}
        />
      )}
    >
      <InformationPane>
        <MainInformation>
          {t('The data that makes up this layered maps has been updated on the following base maps. Please select what you wish to do for each base map.')}
        </MainInformation>
        <Note theme={theme}>
          <NoteLine>
            <Trans i18nKey="NOTE: <1>Applying changes</1> will permanently replace the data on this existing layered map and refresh the page.">
              {s('NOTE: ')}<ApplyChangesLabel theme={theme}>Applying changes</ApplyChangesLabel>{
                s(' will permanently replace the data on this existing layered map and refresh the page.')
              }
            </Trans>
          </NoteLine>
          <NoteLine>
            {t('You can also postpone your decision about a base map until later, or the next time the base map is updated.')}
          </NoteLine>
          <NoteLine>
            <Trans i18nKey="Selecting the option <1>Never for this base map</1> will skip all future updates of this base map.">
              {s('Selecting the option ')}
              <NeverForThisBaseMapLabel theme={theme}>Never for this base map</NeverForThisBaseMapLabel>
              {s(' will skip all future updates of this base map.')}
            </Trans>
          </NoteLine>
        </Note>
      </InformationPane>
      <ContentWrapper theme={theme}>
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
        >
          <Table>
            <div css={HeaderStyle(theme)} />
            <MapNameHeader>
              <Header>{t('Map Name')}</Header>
            </MapNameHeader>
            <NewUpdatesHeader>
              <Header>{t('New Updates')}</Header>
            </NewUpdatesHeader>
            <ActionHeader>
              <Header>{t('Action')}</Header>
            </ActionHeader>
            {props.baseMaps.map((map) => (
              <Fragment key={map.id}>
                <MapNameWrapper>
                  <MapName key={map.id + 'name'}>
                    {map.name}
                  </MapName>
                </MapNameWrapper>
                <NewUpdates key={map.id + 'updates'}>
                  {map.newUpdates < 0 ? t('All data replaced') : map.newUpdates}
                </NewUpdates>
                <Action key={map.id + 'action'}>
                  <RegularDropdownComponent
                    onChange={(selectedOption) => setSelectedOption(selectedOption, map.id)}
                    value={getSelectedOption(map.id)}
                    options={actionOptions}
                    placeholder={t('Select action')}
                    inPortal
                    dropdownContainerStyle={dropdownContainerStyle}
                    itemCustomStyle={applyItemCustomStyle}
                  />
                </Action>
              </Fragment>
            ))}
          </Table>
        </ScrollBarComponent>
      </ContentWrapper>
    </ModalComponent>
  );
};
