export enum HeatMapStyle {
  MarkerDensity = 'MarkerDensity',
  NumericalData = 'NumericalData'
}

export enum HeatMapSample {
  AllMarkers = 'AllMarkers',
  SpecificGroup = 'SpecificGroup'
}

export enum HeatMapType {
  AllMarkersDensity = 'AllMarkersDensity',
  GroupDensity = 'GroupDensity',
  AllMarkersNumericalData = 'AllMarkersNumericalData',
  GroupNumericalData = 'GroupNumericalData'
}
