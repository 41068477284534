import {
  css, Global,
} from '@emotion/react';
import {
  type FC, useMemo, useState,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { SliderComponent } from '../../components/slider/slider.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { LottieAnimations } from './animations/lottieAnimations';
import { LottieAnimationComponent } from './lottieAnimation.component';
import {
  type LottieAnimationSegment,
  type LottieAnimationType, LottieAnimationTypes, type LottieAnimationViewDetails,
} from './lottieAnimation.types';

const typeOptions = Object.keys(LottieAnimationTypes).map(k => ({ name: k, value: k as LottieAnimationType }));

const getSegmentOptions = (type: LottieAnimationType) => Object
  .entries(LottieAnimations[type].segments)
  .map(([name, segment]) => ({ name, value: segment }));

const getViewOptions = (type: LottieAnimationType) => Object
  .entries(LottieAnimations[type].views)
  .map(([name, view]) => ({ name, value: view }));

const pageStyle = css({
  maxWidth: '1100px',
  margin: '0 auto',
  padding: 10,
});

const headingStyle = css({
  width: 120,
  flexShrink: 0,
});

const animationPaneStyle = css({
  display: 'flex',
  gap: 20,
  alignItems: 'baseline',
});

const animationStyle = css({
  border: 'solid 2px black',
  width: 'fit-content',
  boxSizing: 'content-box',
});

export const LottieAnimationSandboxComponent: FC = () => {
  const [type, setType] = useState<LottieAnimationType>(LottieAnimationTypes.Failed);
  const [segment, setSegment] = useState<LottieAnimationSegment>(LottieAnimations[type].segments.default);
  const [view, setView] = useState<LottieAnimationViewDetails>(LottieAnimations[type].views.default);
  const [progress, setProgress] = useState(0);
  const [progressString, setProgressString] = useState(progress.toString());
  const [autoplay, setAutoplay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [bothDirections, setBothDirections] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [speedString, setSpeedString] = useState(speed.toString());
  const [colors, setColors] = useState<any>(LottieAnimations[type].colors);
  const [colorsString, setColorsString] = useState(JSON.stringify(LottieAnimations[type].colors, null, 4));
  const [gradients, setGradients] = useState<any>(LottieAnimations[type].gradients);
  const [gradientsString, setGradientsString] = useState(JSON.stringify(LottieAnimations[type].gradients, null, 4));

  const segmentOptions = useMemo(() => getSegmentOptions(type), [type]);
  const viewOptions = useMemo(() => getViewOptions(type), [type]);

  const onProgressInputSubmit = () => {
    const number = Number.parseInt(progressString, 10);
    if (!isNaN(number) && number >= 0 && number <= 100) {
      setProgress(number);
    }
  };

  const onSpeedInputSubmit = () => {
    const number = Number.parseFloat(speedString);
    if (!isNaN(number)) {
      setSpeed(number);
    }
  };

  const onColorsInputSubmit = () => {
    try {
      const colors = JSON.parse(colorsString);
      setColors(colors);
    }
    catch {
      //
    }
  };

  const onGradientsInputSubmit = () => {
    try {
      const gradients = JSON.parse(gradientsString);
      setGradients(gradients);
    }
    catch {
      //
    }
  };

  const renderAnimation = (size: number) => (
    <div css={animationStyle}>
      <LottieAnimationComponent
        type={type as any}
        size={size}
        progress={!autoplay ? progress : undefined}
        segment={segment}
        autoplay={autoplay}
        loop={loop}
        bothDirections={bothDirections}
        speed={speed}
        colors={colors}
        gradients={gradients}
        view={view}
      />
    </div>
  );

  return (
    <>
      <Global
        styles={{
          '#root': {
            overflow: 'auto !important',
          },
        }}
      />
      <div css={pageStyle}>
        <div css={animationPaneStyle}>
          {renderAnimation(400)}
          {renderAnimation(200)}
          {renderAnimation(100)}
          {renderAnimation(50)}
          {renderAnimation(25)}
        </div>

        <div
          css={css({
            marginTop: 25,
            display: 'flex',
            gap: 50,
          })}
        >
          <CheckboxComponent
            text="Autoplay"
            isChecked={autoplay}
            checkedSetter={() => setAutoplay(!autoplay)}
          />

          <CheckboxComponent
            text="Loop"
            isChecked={loop}
            checkedSetter={() => setLoop(!loop)}
          />

          <CheckboxComponent
            text="Both Directions"
            isChecked={bothDirections}
            checkedSetter={() => setBothDirections(!bothDirections)}
          />
        </div>

        <div
          css={css({
            display: 'flex',
            gap: 50,
          })}
        >
          <div css={css({ width: '100%' })}>
            <h2 css={headingStyle}>Type</h2>
            <RegularDropdownComponent
              options={typeOptions}
              onChange={type => {
                setType(type);
                setSegment(LottieAnimations[type].segments.default);
                setView(LottieAnimations[type].views.default);
                setColors(LottieAnimations[type].colors);
                setColorsString(JSON.stringify(LottieAnimations[type].colors, null, 4));
                setGradients(LottieAnimations[type].gradients);
                setGradientsString(JSON.stringify(LottieAnimations[type].gradients, null, 4));
              }}
              value={type}
            />
          </div>

          <div css={css({ width: '100%' })}>
            <h2 css={headingStyle}>Segment</h2>
            <RegularDropdownComponent
              options={segmentOptions}
              onChange={setSegment}
              value={segment}
            />
          </div>

          <div css={css({ width: '100%' })}>
            <h2 css={headingStyle}>View</h2>
            <RegularDropdownComponent
              options={viewOptions}
              onChange={setView}
              value={view}
            />
          </div>
        </div>

        <div
          css={css({
            display: 'flex',
            gap: 50,
            alignItems: 'baseline',
          })}
        >
          <h2 css={[css({ color: autoplay ? 'grey' : 'inherit' }), headingStyle]}>Progress</h2>
          <SliderComponent
            css={css({ width: '100%' })}
            min={0}
            max={100}
            value={[progress]}
            onChange={([p]) => {
              setProgress(p);
              setProgressString(p.toString());
            }}
            step={5}
            disabled={autoplay}
          />
          <TextInputComponent
            css={css({ width: 200 })}
            value={progressString}
            onChange={setProgressString}
            onBlur={onProgressInputSubmit}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                onProgressInputSubmit();
              }
            }}
            type="number"
            isDisabled={autoplay}
            icon={null}
          />
        </div>

        <div
          css={css({
            display: 'flex',
            gap: 50,
            alignItems: 'baseline',
          })}
        >
          <h2 css={headingStyle}>Speed</h2>
          <SliderComponent
            css={css({ width: '100%' })}
            min={0}
            max={5}
            value={[speed]}
            onChange={([p]) => {
              setSpeed(p);
              setSpeedString(p.toString());
            }}
            step={0.1}
          />
          <TextInputComponent
            css={css({ width: 200 })}
            value={speedString}
            onChange={setSpeedString}
            onBlur={onSpeedInputSubmit}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                onSpeedInputSubmit();
              }
            }}
            type="number"
            icon={null}
          />
        </div>

        <div
          css={css({
            display: 'flex',
            gap: 50,
            alignItems: 'baseline',
          })}
        >
          <h2 css={headingStyle}>Colors</h2>
          <TextareaComponent
            css={css({ height: 200 })}
            value={colorsString}
            onChange={setColorsString}
            onBlur={onColorsInputSubmit}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                onColorsInputSubmit();
              }
            }}
          />
        </div>

        {!!Object.keys(LottieAnimations[type].gradients).length && (
          <div
            css={css({
              display: 'flex',
              gap: 50,
              alignItems: 'baseline',
            })}
          >
            <h2 css={headingStyle}>Gradients</h2>
            <TextareaComponent
              css={css({ height: 450 })}
              value={gradientsString}
              onChange={setGradientsString}
              onBlur={onGradientsInputSubmit}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  onGradientsInputSubmit();
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
