import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useOnMount } from '~/_shared/hooks/useOnMount';
import {
  ClientLicenseCode, ClientLicenseState,
} from '~/_shared/types/client/license';
import { MemberRoles } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import {
  useUserCurrentClientSelector, useUserDataSelector,
} from '~/store/userData/userData.selectors';
import {
  getSignupPageIdMap, useGenerateSignUpPageUrl,
} from './useSubscribe.hook';

export const SubscribePageContainer: FC = () => {
  const license = useUserCurrentClientSelector()?.license;
  const role = useUserDataSelector().clientRole;

  const { generateSignUpPageUrl } = useGenerateSignUpPageUrl();

  const navigate = useNavigate();

  const onError = () => {
    // attempt to close the window if it was opened programmatically
    window.close();

    // navigate to the home page if the window was not closed
    navigate('/');
  };

  useOnMount(() => {
    const asyncEffect = async () => {
      const licenseAlreadyPurchased = license?.state === ClientLicenseState.ACTIVE && license?.code !== ClientLicenseCode.TRIAL;

      if (role === MemberRoles.COMPANY_OWNER && !licenseAlreadyPurchased) {
        const signUpPageId = getSignupPageIdMap(license?.code);

        try {
          const subscribeUrl = (await generateSignUpPageUrl(signUpPageId))?.sign_up_page;
          self.location.href = subscribeUrl;
        }
        catch (e) {
          console.error(e);
          onError();
        }
      }
      else {
        console.error('User is not eligible to subscribe', { role, license });
        onError();
      }
    };
    asyncEffect();
  });

  return (
    <OverlayLoaderComponent />
  );
};
