import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type DataType } from '../../spreadsheetData/spreadsheetData.state';
import {
  MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE, MAP_SETTINGS_FILTERING_SET_SETTINGS,
  MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF, MAP_SETTINGS_FILTERING_TURN_FILTER_ON,
} from './mapSettingsFiltering.actionTypes';
import { type MapSettingsFilterSettings } from './mapSettingsFiltering.state';

export const mapSettingsFilteringChangeDataType = (spreadsheetColumnId: SpreadsheetColumnId, dataType: DataType) => ({
  type: MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE,
  payload: {
    dataType,
    spreadsheetColumnId,
  },
}) as const;

export const mapSettingsFilteringToggleOnOff = (spreadsheetColumnId: SpreadsheetColumnId) => ({
  type: MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF,
  payload: {
    spreadsheetColumnId,
  },
}) as const;

export const mapSettingsFilteringTurnFilterOn = (spreadsheetColumnId: SpreadsheetColumnId, dataType: DataType) => ({
  type: MAP_SETTINGS_FILTERING_TURN_FILTER_ON,
  payload: {
    spreadsheetColumnId,
    dataType,
  },
}) as const;

export const mapSettingsFilteringSetSettings = (settings: MapSettingsFilterSettings) => ({
  type: MAP_SETTINGS_FILTERING_SET_SETTINGS,
  payload: {
    settings,
  },
}) as const;
