import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { DropzoneComponent } from '../../_shared/components/dropzone/dropzone.component';
import { UploadStatusComponent } from '../../_shared/components/uploadStatus/uploadStatus.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { type UploadItem } from '../createNew/createNewMap.component';
import { createUploadItemFromFile } from '../createNew/createNewMap.helpers';
import { MAXIMUM_SPREADSHEET_FILE_SIZE_MB } from './withError/selectSpreadsheetWithError.component';

type UploadSpreadsheetsProps = Readonly<{
  uploadItems: UploadItem[];
  onUploadItemsSelect: (items: UploadItem[]) => void;
  onUploadItemRetry: (itemId: string) => void;
  onUploadItemRemove: (itemId: string) => void;
  isRemoveDisabled?: boolean;
  isDisabled?: boolean;
  isMulti: boolean;
}>;

const dropzoneStyle = css({
  marginTop: 16,
});

const uploadStatusStyle = css({
  marginTop: 5,
});

const fileRejectedErrorStyle = css({
  marginTop: 10,
});

const uploadStatusContainerStyle = css({
  marginTop: 10,
});

export const SelectSpreadsheetComponent: FC<UploadSpreadsheetsProps> = (props) => {
  const [isFileRejectedError, setIsFileRejectedError] = useState(false);
  const [t] = useTranslation();
  const { uploadItems } = props;

  const addUploadItems = (files: File[]) => {
    setIsFileRejectedError(false);

    const uploadItems: UploadItem[] = files.map(file => createUploadItemFromFile(file));

    props.onUploadItemsSelect(uploadItems);
  };

  const retryUpload = (id: string) => {
    props.onUploadItemRetry(id);
  };

  const removeUploadItem = (id: string) => {
    props.onUploadItemRemove(id);
  };

  return (
    <div>
      <DropzoneComponent
        isDisabled={props.isDisabled}
        css={dropzoneStyle}
        onFilesSelect={addUploadItems}
        acceptFileExtensions={['xls', 'xlsx', 'csv', 'tsv']}
        onFilesReject={() => setIsFileRejectedError(true)}
        maxSize={MAXIMUM_SPREADSHEET_FILE_SIZE_MB * 1024 * 1024}
        isMulti={props.isMulti}
      />

      {isFileRejectedError && (
        <AlertComponent
          type="danger"
          css={fileRejectedErrorStyle}
          onClose={() => setIsFileRejectedError(false)}
        >
          {t('Provide a supported file (xls, xlsx, csv, tsv) no larger than {{fileSize}}MB', {
            fileSize: MAXIMUM_SPREADSHEET_FILE_SIZE_MB,
          })}
        </AlertComponent>
      )}

      <div css={uploadStatusContainerStyle}>
        {uploadItems.map((item, index) => (
          <UploadStatusComponent
            key={item.id}
            css={uploadStatusStyle}
            iconSize={42}
            iconMargin={20}
            file={item.file}
            status={item.status}
            onRemoveClick={() => removeUploadItem(item.id)}
            onRetryClick={() => retryUpload(item.id)}
            orderIndex={index}
            progress={item.progress}
            isRemoveDisabled={props.isRemoveDisabled}
          />
        ))}
      </div>
    </div>
  );
};
