import { css } from '@emotion/react';
import { type FC } from 'react';
import { linkStyle } from '~/_shared/baseComponents/links';
import SpreadsheetTemplate from '../../../../../assets/files/New_York_Apple_Stores.xls';
import {
  s, Trans,
} from '../../../translations/Trans';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';

const modalFooterContainerStyle = css({
  margin: 0,
  fontSize: 14,
  lineHeight: 1.2,
});

const modalFooterHeadingStyle = ({ theme }: ThemeProps) => css({
  margin: 0,
  color: theme.textColors.success,
});

const modalFooterParagraphStyle = css({
  margin: 0,
});

export const ModalFooterSampleExcelComponent: FC = () => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={modalFooterContainerStyle}>
      <p css={modalFooterHeadingStyle({ theme })}>{t('Need Help?')}</p>
      <p css={modalFooterParagraphStyle}>
        <Trans i18nKey="View our sample excel <1>Spreadsheet Template</1>.">
          {s('View our sample excel ')}
          <a
            css={linkStyle({ isDestructive: false })}
            href={SpreadsheetTemplate}
            download="New_York_Apple_Stores.xls"
          >
            Spreadsheet Template
          </a>
          {s('.')}
        </Trans>
      </p>
      <p css={modalFooterParagraphStyle}>{t('Map our sample data set by clicking Map Now.')}</p>
    </div>
  );
};
