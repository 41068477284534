import { faMapPlus } from '@awesome.me/kit-77e1f5874f/icons/kit/custom';
import { faBriefcaseArrowRight } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useLayoutEffect, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import { PaginationComponent } from '~/_shared/components/pagination/pagination.component';
import { StickyComponent } from '~/_shared/components/sticky/sticky.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import { useTranslation } from '~/_shared/utils/hooks';
import { useCombinedRefs } from '~/_shared/utils/hooks/useCombinedRefs';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { useWindowWidth } from '~/_shared/utils/hooks/useWindowWidth';
import {
  alertWrapperStyle, containerStyle, ctaWrapperStyle, originalTableHeaderDataStyle, originalTableHeaderRowStyle,
  originalTableHeaderStyle, originalTableWrapperStyle, stickyComponentStyle, tableStyle, titleActionContainer,
  titleBlockStyle, titleStyle,
} from '~/map/listing/mapListing.styles';
import { MapListingEmptyStateComponent } from '~/map/listing/mapListingEmptyState.component';
import type { MapPrivacyClickParams } from '~/map/listing/tableData/mapRow/mapRow.component';
import {
  ActionsHeaderComponent, type MapListingDeleteStatus,
} from './actionsHeader/actionsHeader.component';
import { type MapListingItem } from './item/mapListingItem';
import { type MapListSortType } from './mapListing.helpers';
import { TableDataContainer } from './tableData/tableData.container';
import { TableHeaderComponent } from './tableHeader/tableHeader.component';
import { getColumnWidths } from './tableHeader/tableHeader.helpers';
import { type ChangePrivacyLevelProps } from './useMapListingItems';

type MapListingProps = Readonly<{
  deleteStatus: MapListingDeleteStatus;
  errorMessage: string | null;
  isMapListingDataLoading: boolean;
  pagination: Pagination | null;
  processedItems: ReadonlyArray<MapListingItem>;
  searchQuery: string;
  selectedMapIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  sortType: MapListSortType;
  toggledMapIds: ReadonlyArray<number>;

  onCreateNewMap: () => void;
  onDelete: () => void;
  onDeleteSuccessAnimationFinish: () => void;
  onMapPrivacyClick: (data: MapPrivacyClickParams) => void;
  onMigrationButtonClick?: () => void;
  onPageChange: (page: number) => void;
  onSearchQueryChange: (query: string) => void;
  onSortTypeChange: (sort: MapListSortType) => void;
  toggleMapSelection: (mapId: number) => void;
  toggleSnapshotSelection: (params: Readonly<{ mapId: number; snapshotId: number }>) => void;
  toggleSnapshotsVisibility: (mapId: number) => void;
  onShareSuccess: (props: ChangePrivacyLevelProps) => void;
}>;

export const MapListingComponent: FC<MapListingProps> = (props) => {
  const [tableColumnWidths, setTableColumnsWidths] = useState<ReadonlyArray<number>>([]);
  const { width: originalHeaderWidth, ref: setOriginalHeaderWidthRef } = useElementDimensions();
  const [originalHeaderRef, setOriginalHeaderRef] = useHookWithRefCallback<HTMLTableRowElement>();
  const originalHeaderRefs = useCombinedRefs<HTMLTableRowElement>(setOriginalHeaderWidthRef, setOriginalHeaderRef);
  const [t] = useTranslation();
  const theme = useTheme();
  const windowWidth = useWindowWidth();

  const guaranteedOriginalHeaderWidth = originalHeaderWidth || window.outerWidth;

  useLayoutEffect(() => {
    if (!originalHeaderRef) {
      return;
    }

    setTableColumnsWidths(getColumnWidths(originalHeaderRef));
  }, [windowWidth, originalHeaderRef, props.isMapListingDataLoading]);

  return (
    <section
      css={containerStyle}
      data-testid="map-listing"
    >
      <div css={titleBlockStyle}>
        <h1 css={titleStyle}>{t('My Maps')}</h1>
        <div css={titleActionContainer}>
          {props.onMigrationButtonClick && (
            <ButtonComponent
              buttonStyle={ButtonStyle.Secondary}
              onClick={props.onMigrationButtonClick}
              prefixIcon={faBriefcaseArrowRight}
              text={t('MapMigrationMigrateMapsCaption')}
            />
          )}
          <ButtonComponent
            prefixIcon={faMapPlus}
            text={t('Create New Map')}
            buttonStyle={ButtonStyle.Success}
            onClick={props.onCreateNewMap}
          />
        </div>
      </div>
      <StickyComponent css={stickyComponentStyle({ theme })}>
        <div css={ctaWrapperStyle}>
          <ActionsHeaderComponent
            deleteStatus={props.deleteStatus}
            onDeleteSuccessAnimationFinish={props.onDeleteSuccessAnimationFinish}
            onDeleteClick={props.onDelete}
            searchQuery={props.searchQuery}
            onSearchQueryChange={props.onSearchQueryChange}
            sortType={props.sortType}
            setSortType={props.onSortTypeChange}
          />
        </div>

        <table css={tableStyle}>
          <TableHeaderComponent
            columnWidths={tableColumnWidths}
            width={guaranteedOriginalHeaderWidth}
          />
        </table>
      </StickyComponent>

      <OverlayWrapperComponent minHeight={props.isMapListingDataLoading ? 150 : 0}>
        <div css={originalTableWrapperStyle({ theme })}>
          <table css={tableStyle}>
            <TableHeaderComponent
              ref={originalHeaderRefs}
              css={originalTableHeaderStyle}
              dataStyle={originalTableHeaderDataStyle}
              rowStyle={originalTableHeaderRowStyle}
              width={guaranteedOriginalHeaderWidth}
            />
            <TableDataContainer
              data={props.processedItems}
              onMapPrivacyClick={props.onMapPrivacyClick}
              onMapSelectToggle={props.toggleMapSelection}
              onShowSnapshotsToggle={props.toggleSnapshotsVisibility}
              onSnapshotSelectToggle={props.toggleSnapshotSelection}
              selectedMapsIds={props.selectedMapIds}
              toggledMapIds={props.toggledMapIds}
              selectedSnapshotIds={props.selectedSnapshotIds}
              width={guaranteedOriginalHeaderWidth}
              onShareSuccess={props.onShareSuccess}
            />
          </table>

          {props.errorMessage && (
            <div css={alertWrapperStyle}>
              <AlertComponent type="danger">{props.errorMessage}</AlertComponent>
            </div>
          )}

          {props.isMapListingDataLoading &&
            <OverlayLoaderComponent />
          }
        </div>
      </OverlayWrapperComponent>

      {!props.isMapListingDataLoading && (
        <MapListingEmptyStateComponent
          maps={props.processedItems}
        />
      )}

      {props.pagination && (
        <PaginationComponent
          currentPage={props.pagination.currentPage}
          pageCount={props.pagination.lastPage}
          onPageSelect={props.onPageChange}
        />
      )}
    </section>
  );
};
