import { css } from '@emotion/react';
import { colorStyle } from '~/_shared/baseComponents/buttons';
import type { Theme } from '~/_shared/themes/theme.model';
import { homePageZIndexes } from '~/homepage/homepage.constants';

export const topBarHeaderHeight = 48;

export const containerStyle = (theme: Theme) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  cursor: 'default',
  height: topBarHeaderHeight,
  maxWidth: '100%',
  padding: '0 16px',
  position: 'relative',
  zIndex: homePageZIndexes.topBar,
});

export const sectionStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 16,
});

export const rightSectionStyle = css(
  sectionStyle, {
    justifyContent: 'flex-end',
  });

export const headingSectionStyle = css({
  flexGrow: 1,
  overflow: 'hidden',
  height: 'inherit',
});

export const buttonStyle = (isSelected?: boolean) =>
  colorStyle({ isSelected });

export const undoButtonWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
});

export const purchaseButtonStyle = css({
  fontSize: 14,
  height: 32,
  padding: '4px 8px',
  whiteSpace: 'nowrap',
});

export const toolsIconSpaceStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const toolIconStyle = (isActive: boolean) => (theme: Theme) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: isActive ? theme.iconColors.backgroundQuaternary : 'inherit',
  color: isActive ? theme.textColors.quaternary : theme.textColors.primary,
  borderRadius: 4,
  fontSize: 20,
  height: 40,
  width: 40,

  '&:hover': {
    backgroundColor: isActive ? theme.iconColors.backgroundQuaternaryHover : theme.backgroundColors.tertiary,
  },
});
