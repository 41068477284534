import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Delete]: {
  views: {
    default: {
      canvasHeight: 48,
      canvasWidth: 48,
      iconWidth: 48,
      iconHeight: 48,
    },
  },
  colors: {
    trash: '#30D864',
    tick: '#30D864',
    borderFrom: '#E3F9FA',
    // background is made opacity 0 in the animation JSON file (line 2760)
    // the alpha value from the color value is ignored since the background uses the opacity animation
    background: '#E3F9FA',
    borderTo: '#B8E986',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 60] as const,
  },
  getJson: () => import(
    /* webpackChunkName: "animations/87807-save" */
    './87807-save.json'
  ),
} };

export const { Delete } = config;
