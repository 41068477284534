import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '../spreadsheet/spreadsheet.types';
import {
  type Geocoding, type RealSpreadsheetGeocodingData,
} from './geocoding';
import { type GeocodingResponse } from './geocoding.response';

export const createGeocodingFromResponse = (response: GeocodingResponse): Geocoding => ({
  badData: response.bad_data,
  geocodings: Object.entries(response.geocodings).reduce<PerSpreadsheet<RealSpreadsheetGeocodingData>>((acc, [spreadsheetId, value]) => {
    if (value) {
      acc[+spreadsheetId] = {
        total: value.total,
        processed: value.processed,
        percentage: value.percentage,
        lastCalledBy: {
          id: value.caller.id,
          name: value.caller.name,
        },
      };
    }
    return acc;
  }, newPerSpreadsheetMap()),
  isRequired: response.is_required,
  inProgress: response.in_progress,
  isPaused: response.is_paused,
});
