import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.MailChecked]: {
  views: {
    default: {
      canvasHeight: 192,
      canvasWidth: 192,
      iconWidth: 181,
      iconHeight: 181,
    },
  },
  colors: {},
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 60] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/89309-mail-checked" */
      './89309-mail-checked.json'
    ),
} };

export const { MailChecked } = config;
