import { type FC } from 'react';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import { AppErrorPageAccessForbiddenComponent } from './accessForbidden/appErrorPageAccessForbidden.component';
import { AppErrorPageContactSupportComponent } from './contactSupport/appErrorPageContactSupport.component';
import { AppErrorPageGeneralComponent } from './general/appErrorPageGeneral.component';
import { AppErrorPageMapNotFoundComponent } from './mapNotFound/appErrorPageMapNotFound.component';
import { AppErrorPageNotFoundComponent } from './notFound/appErrorPageNotFound.component';
import { AppErrorPagePresentationalMapUnavailable } from './publicMapUnavailable/appErrorPagePresentationalMapUnavailable';
import { AppErrorPageInvalidQueryString } from './queryStringMissing/appErrorPageInvalidQueryString.component';

export type AppErrorPageProps = Readonly<{
  error: ErrorPageType;
  isUserSignedIn: boolean;
  clearErrorPage: () => void;
}>;

export const AppErrorPageComponent: FC<AppErrorPageProps> = (props) => {
  if (props.error === ErrorPageType.PageNotFound) {
    return (
      <AppErrorPageNotFoundComponent />
    );
  }

  if (props.error === ErrorPageType.MapNotFound) {
    return (
      <AppErrorPageMapNotFoundComponent />
    );
  }

  if (props.error === ErrorPageType.ContactSupport) {
    return (
      <AppErrorPageContactSupportComponent onTryAgainClick={props.clearErrorPage} />
    );
  }

  if (props.error === ErrorPageType.PublicMapUnavailable) {
    return (
      <AppErrorPagePresentationalMapUnavailable />
    );
  }

  if (props.error === ErrorPageType.AccessForbidden) {
    return (
      <AppErrorPageAccessForbiddenComponent />
    );
  }

  if (props.error === ErrorPageType.InvalidQueryString) {
    return (
      <AppErrorPageInvalidQueryString />
    );
  }

  return (
    <AppErrorPageGeneralComponent />
  );
};
