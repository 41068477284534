import { type DrawingTextSettingsState } from '../../store/mapSettings/drawing/settings/text/drawingTextSettings.state';
import {
  DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE, DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

type CreateTextDrawingOptionsArguments = {
  settings: DrawingTextSettingsState;
  sizePerPixelRatio?: number;
};
export const createTextDrawingGlobalOptionsFromSettings = (settings: DrawingTextSettingsState) =>
  createTextDrawingOptionsFromSettings({ settings });

export const createSelectedTextDrawingOptionsFromSettings = (settings: DrawingTextSettingsState, sizePerPixelRatio: number) =>
  createTextDrawingOptionsFromSettings({ settings, sizePerPixelRatio });

const createTextDrawingOptionsFromSettings = (
  { settings, sizePerPixelRatio }: CreateTextDrawingOptionsArguments
): Partial<DrawingToolOptions> => {
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    booleanOptions: {
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    sliderOptions: {
      ...!useScaledSlider ? {
        fontSize: {
          value: settings.fontSize,
          range: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
        },
      } : undefined,
    },
    ...useScaledSlider ? {
      scaledSliderOptions: {
        fontSizeOnCurrentMapZoom: {
          value: settings.fontSize,
          displayValue: Math.round((settings.fontSize) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
          ensureValueInRange: true,
        },
      },
    } : undefined,
    colorOptions: {
      fontColor: settings.fontColor,
    },
  };
};

export const createTextDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingTextSettingsState
): DrawingTextSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    fontSize: toolOptions.sliderOptions?.fontSize?.value ?? toolOptions.scaledSliderOptions?.fontSizeOnCurrentMapZoom?.value ?? currentSettings.fontSize,
    fontColor: toolOptions.colorOptions?.fontColor ?? currentSettings.fontColor,
  };
};
