import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type ModalProps } from '~/modal/modalType.enum';
import { submitLayeredMapSettings } from '~/store/mapSettings/layeredMap/mapSettingsLayered.actionCreators';
import { refreshPage } from '../../../_shared/utils/document/document.helpers';
import { BaseMapsUpdateModalComponent } from './baseMapsUpdateModal.component';
import {
  type BaseMap,
  BaseMapsUpdateModalAction, type BaseMapsUpdateModalActionForMap,
} from './baseMapsUpdateModal.types';
import { useApplyChanges } from './useApplyChanges';

export type BaseMapsUpdateModalContainerProps = ModalProps<{
  layeredMapId: number;
  baseMaps: BaseMap[];
}>;

export const BaseMapsUpdateModalContainer: React.FC<BaseMapsUpdateModalContainerProps> = ({
  onClose, layeredMapId, baseMaps, isOpen,
}) => {
  const dispatch = useDispatch();
  const { applyChanges, isLoading } = useApplyChanges(layeredMapId);
  const updateSettings = useCallback((actionForMaps: BaseMapsUpdateModalActionForMap[]) => {
    const now = new Date();
    dispatch(submitLayeredMapSettings(actionForMaps, now));
  }, [dispatch]);

  const handleSubmit = useCallback((actionForMaps: BaseMapsUpdateModalActionForMap[]) => {
    const exec = async () => {
      const appliedMaps = actionForMaps.filter(map => map.action === BaseMapsUpdateModalAction.Apply);
      if (appliedMaps.length > 0) {
        await applyChanges(appliedMaps);
        refreshPage();
      }

      updateSettings(actionForMaps);
      onClose();
    };
    exec();
  }, [onClose, updateSettings, applyChanges]);

  return (
    <BaseMapsUpdateModalComponent
      baseMaps={baseMaps}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};
