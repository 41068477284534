import { type BoundaryFilter } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { type BoundaryGroup } from '../../boundary/boundary.types';
import {
  type BoundaryTerritoryGroup,
  type BoundaryTerritoryGroupModel,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  BOUNDARY_GROUP_CHANGE_NAME,
  BOUNDARY_GROUP_CHANGE_NAME_ERROR,
  BOUNDARY_GROUP_CHANGE_NAME_SUCCESS,
  BOUNDARY_GROUP_CREATE_ERROR,
  BOUNDARY_GROUP_CREATE_REQUEST,
  BOUNDARY_GROUP_CREATE_SUCCESS,
  BOUNDARY_GROUP_FETCH_ALL_ERROR,
  BOUNDARY_GROUP_FETCH_ALL_REQUEST,
  BOUNDARY_GROUP_FETCH_ALL_SUCCESS,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED,
  BOUNDARY_GROUPS_DELETE_ERROR,
  BOUNDARY_GROUPS_DELETE_REQUEST,
  BOUNDARY_GROUPS_DELETE_SUCCESS,
  BOUNDARY_GROUPS_FILTER_CHANGED,
} from './boundaryGroups.actionTypes';

export const boundaryGroupsRequest = () => ({
  type: BOUNDARY_GROUP_FETCH_ALL_REQUEST,
}) as const;

export const boundaryGroupsSuccess = (boundaryGroups: ReadonlyArray<BoundaryGroup>) => ({
  type: BOUNDARY_GROUP_FETCH_ALL_SUCCESS,
  payload: {
    boundaryGroups,
  },
}) as const;

export const boundaryGroupsError = (error: Error) => ({
  type: BOUNDARY_GROUP_FETCH_ALL_ERROR,
  payload: {
    error,
  },
}) as const;

export const boundaryGroupsCreateRequest = (groupName: string, onBoundaryTerritoryGroupCreateSuccess?: (boundaryTerritoryGroup: BoundaryTerritoryGroup) => void) => ({
  type: BOUNDARY_GROUP_CREATE_REQUEST,
  payload: {
    groupName,
    onBoundaryTerritoryGroupCreateSuccess,
  },
}) as const;

export const boundaryGroupsCreateSuccess = (boundaryGroup: BoundaryGroup, onBoundaryTerritoryGroupCreateSuccess?: (boundaryTerritoryGroup: BoundaryTerritoryGroup) => void) => ({
  type: BOUNDARY_GROUP_CREATE_SUCCESS,
  payload: {
    boundaryGroup,
    onBoundaryTerritoryGroupCreateSuccess,
  },
}) as const;

export const boundaryGroupsCreateError = (error: Error) => ({
  type: BOUNDARY_GROUP_CREATE_ERROR,
  payload: {
    error,
  },
}) as const;

export const boundaryGroupsChangeName = (groupId: number, newName: string) => ({
  type: BOUNDARY_GROUP_CHANGE_NAME,
  payload: {
    groupId,
    newName,
  },
}) as const;

export const boundaryGroupsChangeNameSuccess = (groupId: number, newName: string) => ({
  type: BOUNDARY_GROUP_CHANGE_NAME_SUCCESS,
  payload: {
    groupId,
    newName,
  },
}) as const;

export const boundaryGroupsChangeNameError = () => ({
  type: BOUNDARY_GROUP_CHANGE_NAME_ERROR,
}) as const;

export const boundaryGroupsDelete = (groupIds: ReadonlySet<number>) => ({
  type: BOUNDARY_GROUPS_DELETE_REQUEST,
  payload: {
    groupIds,
  },
}) as const;

export const boundaryGroupsDeleteSuccess = (groupIds: ReadonlySet<number>) => ({
  type: BOUNDARY_GROUPS_DELETE_SUCCESS,
  payload: {
    groupIds,
  },
}) as const;

export const boundaryGroupsDeleteError = () => ({
  type: BOUNDARY_GROUPS_DELETE_ERROR,
}) as const;

export const convertBoundaryTerritoriesToBoundaries = (boundaryTerritoryGroup: BoundaryTerritoryGroupModel, name: string, archiveBoundaryTerritoryGroup: boolean) => ({
  type: BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST,
  payload: {
    boundaryTerritoryGroup,
    archiveBoundaryTerritoryGroup,
    name,
  },
}) as const;

export const convertBoundaryTerritoriesToBoundariesError = () => ({
  type: BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR,
}) as const;

export const convertBoundaryTerritoriesToBoundariesScheduled = () => ({
  type: BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED,
}) as const;

export const boundaryGroupFilterChanged = (boundaryGroupId: number, previousFilter: BoundaryFilter, currentFilter: BoundaryFilter) => ({
  type: BOUNDARY_GROUPS_FILTER_CHANGED,
  payload: {
    boundaryGroupId,
    previousFilter,
    currentFilter,
  },
}) as const;
