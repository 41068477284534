import { useMemo } from 'react';
import {
  type GroupingColumnNumeric, type GroupingColumnText, isGroupingColumnNumeric, isGroupingColumnText,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { type NumericBucket } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  DataType,
  type GroupData, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';
import {
  getNumberColumnBucketId, getTextColumnGroupIndex,
} from './grouping.helpers';

export const isTextGroupInfo = (gi: GroupInfoBySpreadsheetRowId): gi is TextGroupInfo => isGroupingColumnText(gi.groupingColumn);
export const isNumericGroupInfo = (gi: GroupInfoBySpreadsheetRowId): gi is NumericGroupInfo => isGroupingColumnNumeric(gi.groupingColumn);

type TextGroupInfo = {
  groupingColumn: GroupingColumnText;
  group: {
    uniqueGroupId: number;
    groupData: GroupData;
  };
};

type NumericGroupInfo = {
  groupingColumn: GroupingColumnNumeric;
  bucket: NumericBucket;
};

export type GroupInfoBySpreadsheetRowId = TextGroupInfo | NumericGroupInfo;

export const useGetGroupInfoBySpreadsheetRowId = (spreadsheetRowId?: SpreadsheetRowId, hierarchyIndicator?: number): GroupInfoBySpreadsheetRowId | null => {
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();

  const groupingColumn = useMemo(() => (
    activeGroupColumns?.[hierarchyIndicator || 0] || null
  ), [activeGroupColumns, hierarchyIndicator]);

  return useMemo(() => {
    if (!spreadsheetData || !groupingColumn || !spreadsheetRowId || groupingColumn.spreadsheetId !== spreadsheetRowId.spreadsheetId) {
      return null;
    }

    const spreadsheetId = groupingColumn.spreadsheetId;
    const columnId = groupingColumn.columnId;

    if (isGroupingColumnNumeric(groupingColumn)) {
      return {
        groupingColumn,
        bucket: getNumberColumnBucketId(spreadsheetData, spreadsheetId, columnId, spreadsheetRowId.rowId, groupingColumn),
      };
    }

    if (isGroupingColumnText(groupingColumn)) {
      const uniqueGroupId = getTextColumnGroupIndex(spreadsheetData, spreadsheetId, columnId, spreadsheetRowId.rowId);
      const groupData = uniqueGroupId !== null && spreadsheetData.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP]?.extra.uniqueGroups[uniqueGroupId]
        ? spreadsheetData.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP]?.extra.uniqueGroups[uniqueGroupId]
        : null;

      if (uniqueGroupId !== null && groupData) {
        return {
          groupingColumn,
          group: {
            uniqueGroupId,
            groupData,
          },
        };
      }
    }
    return null;
  }, [groupingColumn, spreadsheetData, spreadsheetRowId]);
};
