import {
  type FC, useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { flatten } from '~/_shared/utils/collections/collections';
import { createUuid } from '~/_shared/utils/createUuid';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { LassoSelectContainer } from '~/map/lassoSelect/lassoSelect.container';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  markersSelectAdd, markersSelectDeactivate, markersSelectSetLassoIsLoading,
} from '~/store/frontendState/mapTools/markersSelect/markersSelect.actionCreators';
import { useMarkersSelectActiveSelector } from '~/store/frontendState/mapTools/markersSelect/markersSelect.selectors';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useGetMarkersInArea } from '../../lassoTool/useGetMarkersInArea';

export const MarkersLassoSelectContainer: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const markersSelect = useMarkersSelectActiveSelector();
  const { openModal: openErrorModal } = useModal(ModalType.AppError);
  const { getMarkersInArea } = useGetMarkersInArea();

  const [shapeKey, setShapeKey] = useState<Uuid>(createUuid());

  const resetShape = useCallback(() => {
    setShapeKey(createUuid());
  }, []);

  const handleOnDrawingStop = useCallback(() => {
    dispatch(markersSelectDeactivate());
  }, [dispatch]);

  const handleAddShape = useCallback((path: PolygonPath) => {
    if (!markersSelect || mapId === null || clientId === null) {
      return;
    }

    dispatch(markersSelectSetLassoIsLoading(true));

    getMarkersInArea({
      useMainFilters: true,
      multiPolygons: [[{ path, holes: [] }]],
    })
      .then(markersPerSpreadsheets => {
        dispatch(markersSelectAdd(flatten(
          markersPerSpreadsheets.map(markersInSpreadsheet => (
            markersInSpreadsheet.rows.map(row => ({
              spreadsheetId: markersInSpreadsheet.spreadsheetId,
              rowId: row.id,
            }))
          ))
        )));
      })
      .catch(() => {
        openErrorModal({
          type: AppErrorType.General,
          errorTitle: t('Error selecting area using lasso'),
          title: t('Error'),
        });
      })
      .finally(() => {
        resetShape();
        dispatch(markersSelectSetLassoIsLoading(false));
      });
  }, [markersSelect, mapId, clientId, dispatch, getMarkersInArea, openErrorModal, t, resetShape]);

  const isLassoActive = markersSelect?.isActive && markersSelect.selectType === MarkersSelectType.Lasso;

  if (!isLassoActive) {
    return null;
  }

  return (
    <LassoSelectContainer
      key={shapeKey}
      onAddShape={handleAddShape}
      onDrawingStop={handleOnDrawingStop}
    />
  );
};
