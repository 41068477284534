import { ensureIsNotNullsy } from '~/_shared/utils/typeGuards';
import { BaseMapsUpdateModalAction } from '~/map/layered/baseMapUpdatedModal/baseMapsUpdateModal.types';
import {
  createAskMeLaterSetting,
  createDontAskAgainSetting,
  createSkipUpdateSetting,
  type Settings,
} from './baseMapsUpdate/settings.types';
import { type MapSettingsLayeredAction } from './mapSettingsLayered.action';
import { MAP_SETTINGS_LAYERED_MAP_SETTINGS_SUBMITTED } from './mapSettingsLayered.actionTypes';
import { type MapSettingsLayeredState } from './mapSettingsLayered.state';

export const layeredInitialState: MapSettingsLayeredState = {
  perBaseMapSettings: {},
};

export const mapSettingsLayeredReducer = (
  state = layeredInitialState,
  action: MapSettingsLayeredAction
): MapSettingsLayeredState => {
  switch (action.type) {
    case MAP_SETTINGS_LAYERED_MAP_SETTINGS_SUBMITTED: {
      const actionsPerMap = action.payload.baseMapActions
        .map((baseMapAction): ([number, Settings] | null) => {
          switch (baseMapAction.action) {
            case BaseMapsUpdateModalAction.NeverForThisBaseMap:
              return [baseMapAction.baseMap.id, createDontAskAgainSetting()];
            case BaseMapsUpdateModalAction.SkipUpdate:
              return [baseMapAction.baseMap.id, createSkipUpdateSetting({
                skippedVersion: baseMapAction.baseMap.version,
                skippedSnapshot: baseMapAction.baseMap.snapshot,
              })];
            case BaseMapsUpdateModalAction.AskMeLater:
              return [baseMapAction.baseMap.id, createAskMeLaterSetting({
                postponedVersion: baseMapAction.baseMap.version,
                postponedSnapshot: baseMapAction.baseMap.snapshot,
                postponedAt: action.payload.currentDate.toISOString(),
              })];
            default:
              return null;
          }
        })
        .filter((pair) => !!pair)
        .map(value => {
          ensureIsNotNullsy(value);
          return value;
        });
      const baseMapActions = new Map<number, Settings>(actionsPerMap);
      const existingBaseMapActions = state.perBaseMapSettings ?? new Map<number, Settings>();
      const updatedBaseMapActions = [...baseMapActions.keys()].reduce((acc, key) => {
        const updatedValue = baseMapActions.get(key);
        ensureIsNotNullsy(updatedValue);
        return { ...acc, [key]: updatedValue };
      }, existingBaseMapActions);

      return { perBaseMapSettings: updatedBaseMapActions };
    }

    default:
      return state;
  }
};
