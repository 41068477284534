import { ModalType } from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';

export const useBoundaryEdit = () => {
  const { openModal } = useModal(ModalType.BoundaryEdit);

  const startBoundaryEdit = (boundaryTerritoryGroupId: number) => {
    openModal({
      boundaryTerritoryGroupId,
    });
  };

  return {
    startBoundaryEdit,
  };
};
