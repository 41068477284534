import { type FC } from 'react';
import { useSpreadsheetColumns } from '../../../store/matchupData/matchupDataSelectors.hook';
import {
  HeatMapPanelFormComponent,
  type HeatMapPanelSubmitItem,
} from './heatMapPanelForm.component';

type HeatMapPanelFormProps = Readonly<{
  onSubmit: (item: HeatMapPanelSubmitItem) => void;
}>;

export const HeatMapPanelFormContainer: FC<HeatMapPanelFormProps> = props => {
  const columns = useSpreadsheetColumns();

  return (
    <HeatMapPanelFormComponent
      onSubmit={props.onSubmit}
      selectableColumns={columns}
    />
  );
};
