import {
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  mapSettingsFileAttachmentsAdd, mapSettingsFileAttachmentsRemove,
} from '../../../store/mapSettings/fileAttachments/fileAttachments.actionCreators';
import { useMapSettingsFileAttachmentsMapSelector } from '../../../store/mapSettings/fileAttachments/fileAttachments.selectors';
import { mapSettingsMarkersUpdateMarkerImages } from '../../../store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import { useMapSettingsMarkerImages } from '../../../store/mapSettings/markers/mapSettingsMarkers.selectors';
import {
  type FileAttachmentId,
  FileAttachmentTool,
} from '../../types/file.types';
import { type SpreadsheetRowId } from '../../types/spreadsheetData/spreadsheetRow';
import {
  createNewFileAttachment, getFileAttachmentId,
} from '../../utils/files/fileAttachments.helpers';

type useMarkerLibraryImagesProps = Readonly<{
  markerId: SpreadsheetRowId | undefined;
}>;

export const useMarkerLibraryImages = ({ markerId }: useMarkerLibraryImagesProps) => {
  const dispatch = useDispatch();
  const fileAttachmentMap = useMapSettingsFileAttachmentsMapSelector();

  const allMarkerImages = useMapSettingsMarkerImages();
  const currentMarkerImages: ReadonlyArray<FileAttachmentId> = useMemo(() => {
    if (!markerId || !markerId.spreadsheetId || !markerId.rowId) {
      return [];
    }
    return allMarkerImages[markerId.spreadsheetId]?.[markerId.rowId] || [];
  }, [allMarkerImages, markerId]);

  const fileIdToFileAttachment = useCallback((fileId: number) =>
    fileAttachmentMap.get(getFileAttachmentId(fileId, FileAttachmentTool.MarkerGallery)), [fileAttachmentMap]);

  const fileAttachmentIdToAttachment = useCallback((attachmendId: FileAttachmentId) =>
    fileAttachmentMap.get(attachmendId), [fileAttachmentMap]);

  const activateImage = useCallback(
    (fileId: number) => {
      const existingAttachment = fileIdToFileAttachment(fileId);
      if (existingAttachment) {
        if (markerId && markerId.rowId && markerId.spreadsheetId) {
          dispatch(mapSettingsMarkersUpdateMarkerImages(
            markerId, [...(currentMarkerImages.filter(a => a !== existingAttachment.id) || []), existingAttachment.id]
          ));
        }
      }
      else {
        const newFileAttachment = createNewFileAttachment(fileId, FileAttachmentTool.MarkerGallery);
        dispatch(mapSettingsFileAttachmentsAdd(newFileAttachment));
        if (markerId && markerId.rowId && markerId.spreadsheetId) {
          dispatch(mapSettingsMarkersUpdateMarkerImages(
            markerId, [...(currentMarkerImages || []), newFileAttachment.id])
          );
        }
      }
    },
    [markerId, currentMarkerImages, dispatch, fileIdToFileAttachment],
  );

  const isFileAttachmentUsedByOtherMarkers = useCallback((fileAttachmentId: string): boolean => {
    const allspreadsheetIds: number[] = Object.keys(allMarkerImages).map(item => Number(item));
    for (const spreadsheetId of allspreadsheetIds) {
      const spreadsheetImages = allMarkerImages[spreadsheetId] || {};
      for (const rowId of Object.keys(spreadsheetImages)) {
        if (rowId === markerId?.rowId && spreadsheetId === markerId.spreadsheetId) {
          continue;
        }
        let imageIds = spreadsheetImages[rowId] || [];
        imageIds = imageIds.filter((item: string) => item === fileAttachmentId);
        if (imageIds.length) {
          return true;
        }
      }
    }
    return false;
  }, [allMarkerImages, markerId]);

  const deactivateImage = useCallback((fileAttachmentId: string) => {
    const newMarkerImages: string[] = currentMarkerImages.filter(fileAttachmentIdItem => fileAttachmentIdItem !== fileAttachmentId);

    // checking whether fileAttachment is used by other markers
    const isAttachmentUsedByOtherMarkers = isFileAttachmentUsedByOtherMarkers(fileAttachmentId);

    if (markerId && markerId.rowId && markerId.spreadsheetId) {
      dispatch(mapSettingsMarkersUpdateMarkerImages(markerId, newMarkerImages));
    }
    if (!isAttachmentUsedByOtherMarkers) {
      dispatch(mapSettingsFileAttachmentsRemove(new Set([fileAttachmentId])));
    }

  }, [markerId, currentMarkerImages, dispatch, isFileAttachmentUsedByOtherMarkers]);

  return {
    activateImage, deactivateImage,
    fileIdToFileAttachment, fileAttachmentIdToAttachment,
    currentMarkerImages,
  };
};
