import { css } from '@emotion/react';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import {
  createColor, createColorWithOpacity, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  formatValue, RangeType,
} from '~/_shared/utils/range/range.helpers';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { BoundaryTerritoryListingLimit } from '~/boundary/constants';
import { useLoadMoreListing } from '~/boundary/hooks/useLoadMoreListing';
import { BoundarySettingsLoadMoreRowComponent } from '~/boundary/settings/table/row/BoundarySettingsLoadMoreRow.component';
import { isBoundaryTerritoryEmpty } from '~/sidebar/sidebarApps/mapTools/boundary/fill/boundaryFill.helpers';
import type { BoundaryTerritoryId } from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryTerritoryAssignments } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup, type BoundaryTerritoryGroupStyle,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { BOUNDARY_SETTINGS_MIN_RANGES_COUNT } from '../boundarySettings.common';
import {
  BOUNDARY_GROUP_TIE_ID, BOUNDARY_MAX_VALUE_ID, BOUNDARY_MIN_VALUE_ID, BOUNDARY_NO_DATA_ID, bucketTypeToRangeType,
  isBoundaryTerritoryHidden, isBoundaryTerritorySpecial, isBoundaryTerritorySpecialOrCustom, rangeTypeToBucketType,
  translateBoundaryTerritoryDisplayName,
} from '../boundarySettings.helpers';
import { BoundarySettingsCustomTerritoryDataComponent } from '../custom/boundarySettingsCustomTerritoryDataComponent';
import {
  boundaryTerritoryGroupSettingsStyleDefaults, restoreBTGDefaultColors,
} from '../defaultBoundarySettings';
import { LineColorComponent } from '../lineColor/lineColor.component';
import { LineExampleComponent } from '../lineExample/lineExample.component';
import { LineWidthComponent } from '../lineWidth/lineWidth.component';
import { NumberOfRangesComponent } from '../numberOfRanges/numberOfRanges.component';
import { RangeColorsComponent } from '../rangeColors/rangeColors.component';
import { BoundarySettingsRestoreButtonComponent } from '../restoreButton/boundarySettingsRestoreButton.component';
import { BoundarySettingsTableComponent } from '../table/boundarySettingsTable.component';
import { BoundarySettingsTableRowComponent } from '../table/row/boundarySettingsTableRow.component';
import { DEFAULT_RANGES_COUNT } from '../useBoundarySettingsPropsVariants';
import {
  generateTerritoryGradientColor, getBoundaryMaxRange, getBoundaryTerritoryRangeValues,
  handleBoundaryTerritoryValueToChange, recalculateBoundaryTerritoriesValues, validateBoundarySettingsNumericValue,
} from './boundarySettingsNumeric.helpers';

const boundarySettingsWrapperStyle = css({
  display: 'flex',
  padding: '20px 24px',
});

const rangesWrapperStyle = css({
  paddingTop: 5,
});

const boundaryColumnStyle = css({
  width: '50%',
  paddingLeft: 10,
  '&:first-of-type': {
    padding: 0,
  },
});

const lineColorWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

const lineColorStyle = css({
  minWidth: 150,
});

const rangeTypeRadioGroupStyle = css({
  gap: 25,
  marginTop: 25,
});

const dataLabelStyle = css({
  textTransform: 'uppercase',
});

const tablePrimaryDataStyle = css({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const tableToLabelStyle = css({
  padding: '0 5px',
});

const tableInputStyle = ({ theme, isValid }: ThemeProps<{ isValid: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  minWidth: 50,
  borderColor: isValid ? undefined : theme.borderColors.error,
});

const toValueStyle = css({
  maxWidth: 120,
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
});

const fromValueStyle = css({
  maxWidth: 120,
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
});

const valueToWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

const boundarySettingsActionsWrapperStyle = (showDelimiter: boolean) => (theme: Theme) => css({
  padding: '5px 24px 20px',
  borderBottom: showDelimiter ? `1px solid ${theme.borderColors.primary}` : undefined,
  display: 'flex',
  justifyContent: 'space-between',
});

const restoreColorsButtonStyle = css({
  padding: 0,
});

type BoundarySettingsNumericProps = Readonly<{
  boundaryTerritoryGroup: BoundaryTerritoryGroup;
  valueIsInitial: boolean;
  disableRangeTypeChange: boolean;
  isDecimal: boolean;
  max: number | typeof BOUNDARY_MAX_VALUE_ID;
  min: number | typeof BOUNDARY_MIN_VALUE_ID;
  onBoundaryTerritoryGroupChange: (territoryGroup: BoundaryTerritoryGroup) => void;

  onNumberOfRangesChange: (numberOfRanges: number, style: BoundaryTerritoryGroupStyle, rangeType: RangeType) => void;
  setIsFormValid: (isFormValid: boolean) => void;
}>;

export const DEFAULT_TERRITORY_OPACITY = .5;

export const BoundarySettingsNumericComponent: FC<BoundarySettingsNumericProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { boundaryTerritoryGroup, onBoundaryTerritoryGroupChange } = props;
  const setIsFormValidRef = useRef(props.setIsFormValid);
  const style = boundaryTerritoryGroup.settings.style;
  const boundaryTerritories = boundaryTerritoryGroup.settings.boundaryTerritories;

  const lineWidth = style.lineWidth;
  const lineColor = createColor(style.lineColor);
  const lowValueColor = createColor(style.gradientColors.low.color);
  const mediumValueColor = createColor(style.gradientColors.medium.color);
  const highValueColor = createColor(style.gradientColors.high.color);

  const rangeType = bucketTypeToRangeType(boundaryTerritoryGroup.settings.bucketType) ?? RangeType.Value;
  const prevRangeType = usePrevious(rangeType);

  const [{ adjustedMin, adjustedMax }, setAdjustedMinMax] = useState(adjustRangeMinMaxForExistingBoundaryTerritories(props.min, props.max, boundaryTerritories));

  const rangeMin = rangeType === RangeType.Value ? adjustedMin : 0;
  const rangeMax = rangeType === RangeType.Value ? adjustedMax : 100;
  const maxNumberOfRanges = useMemo(() => {
    if (props.isDecimal) {
      return 10;
    }

    const boundaryMaxRange = getBoundaryMaxRange(rangeMin, rangeMax);
    return Math.max(boundaryMaxRange, BOUNDARY_SETTINGS_MIN_RANGES_COUNT);
  }, [props.isDecimal, rangeMin, rangeMax]);
  const numberOfRanges = boundaryTerritoryGroup.settings.rangesCount ?? DEFAULT_RANGES_COUNT;

  const prevRangeMin = usePrevious(rangeMin);
  const prevRangeMax = usePrevious(rangeMax);

  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();

  const isDecimalMode = props.isDecimal && rangeType === RangeType.Value;
  const dataTableValueSuffix = rangeType === RangeType.Percentage ? '%' : '';

  const handleChangeTerritoryValue = useCallback((territories: ReadonlyArray<BoundaryTerritory>) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritories: territories,
      },
    });
  }, [boundaryTerritoryGroup, onBoundaryTerritoryGroupChange]);

  const {
    limit: boundarySettingListCountLimit,
    increaseLimit: increaseBoundarySettingListCountLimit,
  } = useLoadMoreListing(BoundaryTerritoryListingLimit);

  const handleChangeRangeType = (rangeType: RangeType) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        bucketType: rangeTypeToBucketType(rangeType),
      },
    });
  };

  const handleChangeLineWidth = (width: number) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        style: {
          ...boundaryTerritoryGroup.settings.style,
          lineWidth: width,
        },
      },
    });
  };

  const handleRestoreDefaultColors = () => {
    const gradientColors = boundaryTerritoryGroupSettingsStyleDefaults.gradientColors;
    let newBoundaryTerritories: ReadonlyArray<BoundaryTerritory> = resetSpecialBoundaryTerritoriesColors(boundaryTerritoryGroup.settings.boundaryTerritories);
    newBoundaryTerritories = recalculateBoundaryTerritoryColors(
      newBoundaryTerritories,
      gradientColors.low.color,
      gradientColors.medium.color,
      gradientColors.high.color
    );

    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritories: newBoundaryTerritories,
        style: {
          ...restoreBTGDefaultColors(boundaryTerritoryGroup).settings.style,
          gradientColors,
        },
      },
    });
  };

  const recalculateBoundaryTerritoryColors = (
    boundaryTerritories: ReadonlyArray<BoundaryTerritory>, gradientLow: string, gradientMedium: string, gradientHigh: string
  ): ReadonlyArray<BoundaryTerritory> => {
    const numericalTerritories = boundaryTerritories.filter(bt => !isBoundaryTerritorySpecialOrCustom(bt));
    const specialTerritories = boundaryTerritories.filter(bt => isBoundaryTerritorySpecialOrCustom(bt));

    const newNumericalTerritories = numericalTerritories.map((bt, index) => ({
      ...bt,
      style: {
        ...bt.style,
        color: generateTerritoryGradientColor(index, numericalTerritories.length, gradientLow, gradientMedium, gradientHigh),
        opacity: 50,
      },
    }));

    return newNumericalTerritories.concat(specialTerritories);
  };

  const resetSpecialBoundaryTerritoriesColors = (boundaryTerritories: ReadonlyArray<BoundaryTerritory>) => boundaryTerritories.map(bt => {
    if (!isBoundaryTerritorySpecial(bt)) {
      return bt;
    }

    let style: { color: string; opacity: number };

    switch (bt.boundaryTerritoryId) {
      case BOUNDARY_GROUP_TIE_ID: {
        style = { color: '#000', opacity: 50 };
        break;
      }
      case BOUNDARY_NO_DATA_ID: {
        style = { color: '#ccc', opacity: 50 };
        break;
      }
      default:
        style = { color: '#fff', opacity: 0 };
    }

    return {
      ...bt,
      style: {
        ...bt.style,
        ...style,
      },
    };
  });

  const updateGroupGradientColors = (gradientLow: string, gradientMedium: string, gradientHigh: string) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritories: recalculateBoundaryTerritoryColors(
          boundaryTerritoryGroup.settings.boundaryTerritories, gradientLow, gradientMedium, gradientHigh
        ),
        style: {
          ...boundaryTerritoryGroup.settings.style,
          gradientColors: {
            low: { color: gradientLow },
            medium: { color: gradientMedium },
            high: { color: gradientHigh },
          },
        },
      },
    });
  };

  const handleChangeLineColor = (color: ColorResult) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        style: {
          ...boundaryTerritoryGroup.settings.style,
          lineColor: guaranteeHash(color.hex),
        },
      },
    });
  };

  const handleChangeLowValueColor = (color: ColorResult) => {
    updateGroupGradientColors(
      guaranteeHash(color.hex),
      boundaryTerritoryGroup.settings.style.gradientColors.medium.color,
      boundaryTerritoryGroup.settings.style.gradientColors.high.color,
    );
  };

  const handleChangeMediumValueColor = (color: ColorResult) => {
    updateGroupGradientColors(
      boundaryTerritoryGroup.settings.style.gradientColors.low.color,
      guaranteeHash(color.hex),
      boundaryTerritoryGroup.settings.style.gradientColors.high.color,
    );
  };

  const handleChangeHighValueColor = (color: ColorResult) => {
    updateGroupGradientColors(
      boundaryTerritoryGroup.settings.style.gradientColors.low.color,
      boundaryTerritoryGroup.settings.style.gradientColors.medium.color,
      guaranteeHash(color.hex),
    );
  };

  const handleChangeBoundaryTerritoryColor = useCallback((id: BoundaryTerritoryId) => (color: ColorResult) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritories: boundaryTerritories.map((bt) => {
          if (id !== bt.boundaryTerritoryId) {
            return bt;
          }
          else {
            return {
              ...bt,
              style: {
                ...bt.style,
                color: guaranteeHash(color.hex),
                opacity: (color.rgb?.a ?? DEFAULT_TERRITORY_OPACITY) * 100,
              },
            };
          }
        }),
      },
    });
  }, [boundaryTerritories, boundaryTerritoryGroup, onBoundaryTerritoryGroupChange]);

  const handleChangeBoundaryTerritoryName = (id: BoundaryTerritoryId, name: string) => {
    onBoundaryTerritoryGroupChange({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritories: boundaryTerritoryGroup.settings.boundaryTerritories.map(
          bt => bt.boundaryTerritoryId === id ?
            {
              ...bt,
              displayName: name,
              translate: false,
            } : bt
        ),
      },
    });
  };

  const handleNumberOfRangesChange = (numberOfRanges: number) => {
    props.onNumberOfRangesChange(numberOfRanges, {
      lineWidth,
      lineColor: guaranteeHash(lineColor.hex),
      gradientColors: {
        low: {
          color: guaranteeHash(lowValueColor.hex),
        },
        medium: {
          color: guaranteeHash(mediumValueColor.hex),
        },
        high: {
          color: guaranteeHash(highValueColor.hex),
        },
      },
    }, rangeType);
  };

  // validate boundary territories, notify parent on validation results
  useEffect(() => {
    for (let i = 0; i < boundaryTerritories.length; i++) {
      const bt = boundaryTerritories[i];
      if (!bt) {
        return;
      }

      if (!validateBoundarySettingsNumericValue(
        bt,
        boundaryTerritories[i - 1] ?? null,
        boundaryTerritories[i + 1] ?? null,
        rangeMin,
        rangeMax,
      )) {
        setIsFormValidRef.current(false);
        return;
      }

      if (isTextEmpty(bt.displayName)) {
        setIsFormValidRef.current(false);
        return;
      }

      setIsFormValidRef.current(true);
    }
  }, [boundaryTerritories, rangeMax, rangeMin]);

  // recalculate range min / range max when min or max props are changed
  const boundaryTerritoriesRef = useRef(boundaryTerritories);
  boundaryTerritoriesRef.current = boundaryTerritories;
  useEffect(() => {
    const adjustedBounds = rangeType !== prevRangeType
      ? { adjustedMax: props.max, adjustedMin: props.min }
      : adjustRangeMinMaxForExistingBoundaryTerritories(props.min, props.max, boundaryTerritoriesRef.current);
    setAdjustedMinMax(adjustedBounds);
  }, [prevRangeType, props.max, props.min, rangeType]);

  // recalculate boundary territories values on range type change
  useEffect(() => {
    if (props.valueIsInitial) {
      return;
    }

    if (prevRangeMin === undefined || prevRangeMax === undefined) {
      return;
    }

    if (prevRangeMin === BOUNDARY_MIN_VALUE_ID || prevRangeMax === BOUNDARY_MAX_VALUE_ID) {
      return;
    }

    if (props.min === BOUNDARY_MIN_VALUE_ID || props.max === BOUNDARY_MAX_VALUE_ID) {
      return;
    }

    if (rangeType === prevRangeType) {
      return;
    }

    if (rangeType === RangeType.Value) {
      // was change from RangeType.Percentage to RangeType.Value so prev min was 0 and prev max was 100
      handleChangeTerritoryValue(recalculateBoundaryTerritoriesValues(boundaryTerritories, props.min, props.max, 0, 100, isDecimalMode));
    }
    else {
      handleChangeTerritoryValue(recalculateBoundaryTerritoriesValues(boundaryTerritories, 0, 100, prevRangeMin, prevRangeMax, isDecimalMode));
    }
  }, [rangeMin, rangeMax, prevRangeMin, prevRangeMax, rangeType, isDecimalMode, handleChangeTerritoryValue, boundaryTerritories,
    prevRangeType, props.min, props.max, props.valueIsInitial]);

  return (
    <div>
      <LineExampleComponent
        lineWidth={lineWidth}
        lineColor={lineColor}
      />

      <div css={boundarySettingsWrapperStyle}>
        <div css={boundaryColumnStyle}>
          <LineWidthComponent
            lineWidth={lineWidth}
            onLineWidthChange={handleChangeLineWidth}
          />
        </div>

        <div css={[boundaryColumnStyle, lineColorWrapperStyle]}>
          <LineColorComponent
            css={lineColorStyle}
            lineColor={lineColor}
            onLineColorChange={handleChangeLineColor}
          />
        </div>
      </div>

      <div css={[boundarySettingsWrapperStyle, rangesWrapperStyle]}>
        <div css={boundaryColumnStyle}>
          <RangeColorsComponent
            highValueColor={highValueColor}
            onHighValueColorChange={handleChangeHighValueColor}
            mediumValueColor={mediumValueColor}
            onMediumValueColorChange={handleChangeMediumValueColor}
            lowValueColor={lowValueColor}
            onLowValueColorChange={handleChangeLowValueColor}
          />
        </div>

        <div css={boundaryColumnStyle}>
          <NumberOfRangesComponent
            maxNumberOfRanges={maxNumberOfRanges}
            numberOfRanges={Math.min(numberOfRanges, maxNumberOfRanges)}
            onNumberOfRangesChange={handleNumberOfRangesChange}
          />

          {!props.disableRangeTypeChange && (
            <RadioGroupComponent
              css={rangeTypeRadioGroupStyle}
              selectedValue={rangeType}
              onValueChange={handleChangeRangeType}
              items={[{
                value: RangeType.Percentage,
                label: t('Percentage Ranges'),
              }, {
                value: RangeType.Value,
                label: t('Value Ranges'),
              }]}
            />
          )}
        </div>
      </div>

      <div css={boundarySettingsActionsWrapperStyle(!!boundaryTerritories.length)}>
        <BoundarySettingsRestoreButtonComponent
          css={restoreColorsButtonStyle}
          prefixIcon={faSync}
          text={t('Restore Default Colors')}
          onClick={handleRestoreDefaultColors}
        />
      </div>

      {boundaryTerritories.length > 0 && (
        <BoundarySettingsTableComponent
          dataHeadingLabel={t('Number Ranges')}
          dataRows={(
            <>
              {boundaryTerritories.map((item, index) => {
                const isEmpty = isBoundaryTerritoryEmpty(boundaryTerritoryAssignments, props.boundaryTerritoryGroup.boundaryTerritoryGroupId, item);
                const isHidden = isBoundaryTerritoryHidden(item, isEmpty, boundaryTerritoryGroup.settings.boundaryTerritoryType);
                if (isHidden || (index >= boundarySettingListCountLimit)) {
                  return null;
                }

                const values = getBoundaryTerritoryRangeValues(item);
                const fromValue = values && values.valueFrom !== BOUNDARY_MIN_VALUE_ID ? values.valueFrom : rangeMin;
                const toValue = values && values.valueTo !== BOUNDARY_MAX_VALUE_ID ? values.valueTo : rangeMax;

                const fromValueFormatted = fromValue !== BOUNDARY_MIN_VALUE_ID ? formatValue(fromValue.toString()) + dataTableValueSuffix : t('min');
                const toValueFormatted = toValue !== BOUNDARY_MAX_VALUE_ID ? formatValue(toValue.toString()) + dataTableValueSuffix : t('max');
                const isSpecial = isBoundaryTerritorySpecial(item);

                const isRowValid = validateBoundarySettingsNumericValue(
                  item,
                  boundaryTerritories[index - 1] ?? null,
                  boundaryTerritories[index + 1] ?? null,
                  rangeMin,
                  rangeMax,
                );

                const color = createColorWithOpacity(
                  item.style.color,
                  item.style.opacity / 100
                );

                return (
                  <BoundarySettingsTableRowComponent
                    key={item.boundaryTerritoryId}
                    index={index + 1}
                    data={item.custom ? (
                      <BoundarySettingsCustomTerritoryDataComponent
                        territory={item}
                        onTerritoryNameChange={handleChangeBoundaryTerritoryName}
                      />
                    ) : isSpecial ? (
                      <span css={dataLabelStyle}>{translateBoundaryTerritoryDisplayName(item, t)}</span>
                    ) : (
                      <div css={tablePrimaryDataStyle}>
                        <span
                          title={fromValueFormatted}
                          css={fromValueStyle}
                        >
                          {t('{{value}}+', { value: fromValueFormatted })}
                        </span>
                        <div css={valueToWrapperStyle}>
                          <span css={tableToLabelStyle}>{t('toNumber')}</span>
                          {values && values.valueTo !== BOUNDARY_MAX_VALUE_ID ? (
                            <TextInputComponent
                              css={tableInputStyle({ theme, isValid: isRowValid })}
                              onChange={value => handleChangeTerritoryValue(
                                handleBoundaryTerritoryValueToChange(index, value, boundaryTerritories, rangeMin, rangeMax, isDecimalMode))}
                              value={values.valueTo}
                              title={toValueFormatted}
                              icon={null}
                            />
                          ) : (
                            <span
                              title={toValueFormatted}
                              css={toValueStyle}
                            >
                              {toValueFormatted}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    lineColor={lineColor}
                    lineWidth={lineWidth}
                    color={color}
                    onColorChange={handleChangeBoundaryTerritoryColor(item.boundaryTerritoryId)}
                  />
                );
              })}
              {(boundaryTerritories.length > boundarySettingListCountLimit) && (
                <BoundarySettingsLoadMoreRowComponent onClick={increaseBoundarySettingListCountLimit} />
              )}
            </>
          )}
        />
      )}
    </div>
  );
};

const adjustRangeMinMaxForExistingBoundaryTerritories = (
  min: number | typeof BOUNDARY_MIN_VALUE_ID,
  max: number | typeof BOUNDARY_MAX_VALUE_ID,
  boundaryTerritories: readonly BoundaryTerritory[]
) => {
  const rangeBoundaryTerritories = boundaryTerritories.filter(t => !isBoundaryTerritorySpecial(t));

  if (!rangeBoundaryTerritories.length) {
    return { adjustedMin: min, adjustedMax: max };
  }

  let adjustedMin = min;

  if (min !== BOUNDARY_MIN_VALUE_ID) {
    const first = rangeBoundaryTerritories[0];
    const firstValues = first && getBoundaryTerritoryRangeValues(first);
    const valueTo: number = notNullsy(firstValues?.valueTo) ? Number(firstValues.valueTo) : NaN;
    adjustedMin = !isNaN(valueTo) && valueTo < min ? valueTo : min;
  }

  let adjustedMax = max;

  if (max !== BOUNDARY_MAX_VALUE_ID) {
    const last = rangeBoundaryTerritories.lastItem;
    const lastValues = getBoundaryTerritoryRangeValues(last);
    const valueFrom: number = notNullsy(lastValues?.valueFrom) ? Number(lastValues.valueFrom) : NaN;
    adjustedMax = !isNaN(valueFrom) && valueFrom > max ? valueFrom : max;
  }

  return { adjustedMin, adjustedMax };
};
