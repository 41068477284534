import {
  type FC,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SearchMatchingBehaviour } from '~/_shared/constants/searchMatchingBehaviour.enum';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  frontendStateSearchHide,
  frontendStateSearchSetSearchText,
} from '~/store/frontendState/mapTools/search/search.actionCreators';
import { useSearchTextSelector } from '~/store/frontendState/mapTools/search/search.selectors';
import {
  setFilterBy, setMapSearchSettings,
} from '~/store/mapSettings/search/mapSettingsSearch.actionCreators';
import { mapSettingsSearchItemsAddItem } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.actionCreators';
import { useSearchItemsSelector } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useSpreadsheetIdsSelector } from '~/store/selectors/useSpreadsheetIdsSelector';
import { ALL_COLUMNS_ID } from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.constants';
import { usePublicMapSettingsSearchBarAlwaysVisibleSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { MapSearchFilterBy } from '../filter/mapSearchFilterBy.enum';
import { MapSearchComponent } from './mapSearch.component';
import {
  areSearchItemsSame, type SearchItem,
} from './mapSearch.helpers';
import { useMapSearchSuggests } from './useMapSearchSuggests';

type MapSearchContainerProps = Readonly<{
  className?: string;
}>;

const filterBySelector = (state: AppState) => state.map.mapSettings.data.search.filterBy;
const shouldMatchExactWordsSelector = (state: AppState) => state.map.mapSettings.data.search.shouldMatchExactWords;

export const MapSearchContainer: FC<MapSearchContainerProps> = (props) => {
  const dispatch = useDispatch();
  const filterBy = useSelector(filterBySelector);
  const shouldMatchExactWords = useSelector(shouldMatchExactWordsSelector);
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const spreadsheetIds = useSpreadsheetIdsSelector();
  const searchText = useSearchTextSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const mapPresentational = useIsMapPresentationalSelector();
  const searchBarAlwaysVisible = usePublicMapSettingsSearchBarAlwaysVisibleSelector();
  const existingSearchItems = useSearchItemsSelector();

  const searchSuggestsParams = useMemo(() => ({
    query: searchText,
    spreadsheetIds,
    exact: shouldMatchExactWords,
    filterBy,
  }), [filterBy, searchText, shouldMatchExactWords, spreadsheetIds]);

  const { suggests, isLoading } = useMapSearchSuggests(clientId, mapId, searchSuggestsParams);

  const dispatchProps = useMemo(() => ({
    onSetMapSearchSettings: (selectedMatchingBehaviour: SearchMatchingBehaviour, shouldMatchExactWords: boolean, shouldZoomOnMatches: boolean) => {
      dispatch(setMapSearchSettings(selectedMatchingBehaviour, shouldMatchExactWords, shouldZoomOnMatches));
    },
    onSetFilterBy: (filterBy: MapSearchFilterBy) => {
      dispatch(setFilterBy(filterBy));
    },
    addSearchSuggest: (searchSuggest: SearchItem) => {
      dispatch(mapSettingsSearchItemsAddItem(searchSuggest));
    },
    setSearchText: (newSearchText: string) => {
      dispatch(frontendStateSearchSetSearchText(newSearchText));
    },
    closeSearchBar: () => {
      dispatch(frontendStateSearchHide());
    },
  }), [dispatch]);

  const handleCloseSearchBar = useMemo(() => {
    if (!mapPresentational || !searchBarAlwaysVisible) {
      return dispatchProps.closeSearchBar;
    }

    return undefined;
  }, [dispatchProps, mapPresentational, searchBarAlwaysVisible]);

  const onSearchSuggestSubmit = useCallback((searchSuggest: SearchItem) => {
    const setSearch = dispatchProps.setSearchText;
    const addSearchSuggest = dispatchProps.addSearchSuggest;
    setSearch('');

    const exactWords = shouldMatchExactWords && !searchSuggest.latLng && !searchSuggest.columnName;
    const includeAllGeolocated = filterBy && !searchSuggest.latLng && (!searchSuggest.columnId || searchSuggest.columnId === ALL_COLUMNS_ID)
      ? [MapSearchFilterBy.OnlyGeolocated, MapSearchFilterBy.UserDataAndGeolocated].includes(filterBy) : false;

    const newSearchSuggest = {
      ...searchSuggest,
      exactWords,
      includeAllGeolocated,
    };

    if (filterBy === MapSearchFilterBy.OnlyGeolocated) {
      delete newSearchSuggest.columnId;
      delete newSearchSuggest.columnName;
    }

    if (!existingSearchItems.find(item => areSearchItemsSame(item, newSearchSuggest))) {
      addSearchSuggest(newSearchSuggest);
    }
  }, [dispatchProps.addSearchSuggest, dispatchProps.setSearchText, existingSearchItems, filterBy, shouldMatchExactWords]);

  return (
    <MapSearchComponent
      {...props}
      onSetMapSearchSettings={dispatchProps.onSetMapSearchSettings}
      onSetMapSearchText={dispatchProps.setSearchText}
      searchText={searchText}
      filterBy={filterBy}
      onSetFilterBy={dispatchProps.onSetFilterBy}
      searchSuggests={suggests}
      areSearchSuggestsLoading={isLoading}
      onSearchSuggestSubmit={onSearchSuggestSubmit}
      onCloseSearchBar={handleCloseSearchBar}
      isMobileScreen={isMobileScreen}
    />
  );
};
