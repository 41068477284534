import { LabelOrMarkerVisualizerContainer } from '~/_shared/labelOrMarkerVisualizer/labelOrMarkerVisualizer.container';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  DEFAULT_NUMERIC_GREEN_MARKER_COLOR, ID_OF_GROWING_NUMERIC_MARKER,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import { BubbleMarkerLabelFrameComponent } from './bubbleMarkerLabelFrame.component';

const GREEN_GROWING_MARKER_DIMENSION = 30;

export const GreenPinMarkerLabelComponent: React.FC = _ => {
  const [t] = useTranslation();

  return (
    <BubbleMarkerLabelFrameComponent title={t('Growing Pin Markers')}>
      <LabelOrMarkerVisualizerContainer
        maxDimensions={{ height: GREEN_GROWING_MARKER_DIMENSION, width: GREEN_GROWING_MARKER_DIMENSION }}
        markerSettings={{
          useMarker: true,
          marker: {
            opacity: 1,
            selectedColor: DEFAULT_NUMERIC_GREEN_MARKER_COLOR,
            size: 100,
            styleId: ID_OF_GROWING_NUMERIC_MARKER,
            styleType: MarkerStyleType.STANDARD,
          },
        }}
        visualSettingsType={MarkerSettingType.Marker}
      />
    </BubbleMarkerLabelFrameComponent>
  );
};
