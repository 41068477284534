import {
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '../_shared/utils/createUuid';
import {
  closeModal,
  openModalWithId,
  replaceCurrentModalWithAndUseCustomId,
} from '../store/modal/modal.actionCreators';
import { type ModalType } from './modalType.enum';
import { type ModalTypeWithArgs } from './renderModal';

type ModalTypeArgument<T extends ModalType, TArgs = ModalTypeWithArgs> = TArgs extends [infer T0, infer T1]
  ? T0 extends T ? T1 : never
  : TArgs extends [infer T0]
    ? T0 extends T ? undefined : never
    : never;

type ModalOpener<T extends ModalType> = undefined extends ModalTypeArgument<T>
  ? (arg?: ModalTypeArgument<T>) => void
  : (arg: ModalTypeArgument<T>) => void;

export const useModal = <T extends ModalType>(type: T, customId?: string) => {
  const dispatch = useDispatch();
  const [id] = useState(customId ?? createUuid());

  const openModalLocal: ModalOpener<T> = useCallback((arg: ModalTypeArgument<T>) => dispatch(openModalWithId(id, type as any, arg as any)), [dispatch, type, id]) as any;
  const replaceCurrentModal: ModalOpener<T> = useCallback((arg: ModalTypeArgument<T>) => dispatch(replaceCurrentModalWithAndUseCustomId(id, type as any, arg as any)), [dispatch, type, id]) as any;

  const closeModalLocal = useCallback(() => dispatch(closeModal(id)), [dispatch, id]);

  return {
    closeModal: closeModalLocal,
    openModal: openModalLocal,
    replaceCurrentModal,
  };
};
