import { useMemo } from 'react';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { type BoundaryTerritoryFilterGroups } from '~/spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  type FilterTreeMapSettingsParams,
  useFilterTreeMapSettingsParams,
} from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { isCancelError } from '../../../_shared/utils/api/apiError.helpers';
import { notNull } from '../../../_shared/utils/typeGuards';
import { type BoundaryFilterBoundariesRequest } from '../../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type RadiusFilterRequestArguments } from '../../../spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBulkRequest,
  type SpreadsheetDataBulkResponse,
} from '../../../spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { useSpreadsheetIdsSelector } from '../../../store/selectors/useSpreadsheetIdsSelector';
import { createAreaRequestGetterFilter } from '../../../store/spreadsheetData/area/spreadsheetData.area.helpers';
import { type RowsPerSpreadsheetWithBasicData } from './rowsPerSpreadsheet';

type GetRowsInPolygonArguments = {
  useMainFilters: boolean;
  multiPolygons?: MultiPolygon[];
  circles?: RadiusFilterRequestArguments[];
  boundaries?: BoundaryFilterBoundariesRequest;
  boundaryTerritories?: BoundaryTerritoryFilterGroups[];
};

const filterEmptySpreadsheets = (input: SpreadsheetDataBulkResponse[]) => {
  return input.filter(input => !!input.result.filtered_rows);
};

const createGetRowsInArea = (filterTreeParams: FilterTreeMapSettingsParams, spreadsheetIds: number[], clientId: number, mapId: number) =>
  async ({ useMainFilters, multiPolygons, circles, boundaries, boundaryTerritories }: GetRowsInPolygonArguments): Promise<ReadonlyArray<RowsPerSpreadsheetWithBasicData>> => {
    const request: SpreadsheetDataBulkRequest = {
      params: spreadsheetIds.map(spreadsheetId => {
        const filter = createAreaRequestGetterFilter(
          useMainFilters,
          'and',
          filterTreeParams,
          spreadsheetId,
          circles,
          multiPolygons,
          boundaries,
          boundaryTerritories
        );

        return {
          exclude_row_data: true,
          exclude_basic_data: false,
          map_id: mapId,
          spreadsheet_id: spreadsheetId,
          filter,
        };
      }),
    };

    const noRows: ReadonlyArray<RowsPerSpreadsheetWithBasicData> = [];

    try {
      const response = await getSpreadsheetBulkData(clientId, request);

      const rowsInPolygonPerSpreadsheet: Array<RowsPerSpreadsheetWithBasicData> = filterEmptySpreadsheets(response.data)
        .map((data) => {
          const rows = data.result.filtered_rows?.map(rowId => {
            const rowBasicData = data.result.basic_data?.find(item => item.row_id === rowId);

            if (!rowBasicData) {
              return null;
            }

            const lat = +rowBasicData.latitude;
            const lng = +rowBasicData.longitude;

            if (isNaN(lat) || isNaN(lng)) {
              return null;
            }

            return {
              id: rowBasicData.row_id,
              lat,
              lng,
            };
          }).filter(notNull) ?? [];

          return {
            rows,
            spreadsheetId: data.spreadsheet_id,
          };
        });

      return rowsInPolygonPerSpreadsheet;
    }
    catch (error) {
      if (isCancelError(error)) {
        return noRows;
      }
      throw error;
    }
  };

export const useGetMarkersInArea = () => {
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const filterTreeParams = useFilterTreeMapSettingsParams();

  const spreadsheetIds = useSpreadsheetIdsSelector();

  const getMarkersInArea = useMemo(() => {
    if (!clientId || !mapId) {
      return () => Promise.resolve([]);
    }

    return createGetRowsInArea(filterTreeParams, spreadsheetIds, clientId, mapId);
  }, [clientId, filterTreeParams, spreadsheetIds, mapId]);

  return {
    getMarkersInArea,
  };
};
