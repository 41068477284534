import { type SpreadsheetSortType } from './spreadsheet.types';

const sortTypes: ReadonlyArray<SpreadsheetSortType | null> = [null, 'asc', 'desc'];

export const getNextSpreadsheetSortType = (currentSortType: SpreadsheetSortType | null): SpreadsheetSortType | null => {
  const indexOfCurrentSort = sortTypes.indexOf(currentSortType);
  const indexOfNewSortType = (indexOfCurrentSort + 1) % sortTypes.length;

  return sortTypes[indexOfNewSortType] ?? null;
};
