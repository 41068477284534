import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { spreadsheetTableRemoveSelectedDataRowsRequest } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.actionCreators';

export const useSpreadsheetTableRowsDelete = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { openConfirmationModal: openDeleteConfirmationModal, closeConfirmationModal: closeDeleteConfirmationModal } = useConfirmationModal();

  return useCallback(() => {
    openDeleteConfirmationModal({
      onCancel: closeDeleteConfirmationModal,
      onConfirm: () => {
        closeDeleteConfirmationModal();
        dispatch(spreadsheetTableRemoveSelectedDataRowsRequest());
      },
      text: t('deleteConfirmation.dataRows', {
        modalName: t('Undo'),
        tabName: t('resetToVersion'),
      }),
      title: t('Delete Rows'),
      confirmCaption: t('Proceed'),
      isConfirmButtonDestructive: true,
      permanentConfirmSettings: {
        id: 'dataRowsDelete',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeDeleteConfirmationModal, dispatch, openDeleteConfirmationModal, t]);
};
