import {
  faEdit, faEllipsisVertical, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useMemo, useRef,
} from 'react';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useTranslation } from '~/_shared/utils/hooks';
import { type SidebarRoundMenuButtonProps } from '../../sidebar/sidebarApps/rightSidebar/roundButtonMenu/sidebarRoundButtonMenu.types';
import { additionalActionsContextMenuStructure } from './contextMenuStructure/structures/proximityToolsContextMenuStructure';

export enum ProximityMenuButtons {
  AdditionalActions = 'Additional Actions',
  Directions = 'Directions',
  Exports = 'Exports',
  EditRadius = 'Edit Radius',
  EditDriveTimePolygon = 'Edit Drive Time Polygon',
  Delete = 'Delete',
}

export type ProximityMenuCallbacks = {
  onExportLocationsClick?: () => void;
  onExportContainedBoundariesClick?: () => void;
  onDirectionsClick?: () => void;
  onProximityEditClick?: () => void;
  onProximityHideClick?: () => void;
  onProximityShowClick?: () => void;
  onDeleteProximityClick?: () => void;
};

export const useRightProximityDetailsMenu = (params: Readonly<{
  isDriveTimePolygon: boolean;
  isProximityVisible: boolean;
  menuButtonsCallbacks: ProximityMenuCallbacks;
  markersCount: number;
}>
): ReadonlyArray<SidebarRoundMenuButtonProps<ProximityMenuButtons>> => {
  const { menuButtonsCallbacks: callbacks, isProximityVisible, markersCount } = params;
  const [t] = useTranslation();
  const toolsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  return useMemo(() => {
    const buttons: SidebarRoundMenuButtonProps<ProximityMenuButtons>[] = [];

    if (callbacks.onProximityEditClick) {
      buttons.push({
        buttonName: params.isDriveTimePolygon ? ProximityMenuButtons.EditDriveTimePolygon : ProximityMenuButtons.EditRadius,
        buttonStyle: RoundButtonStyle.Primary,
        onClick: callbacks.onProximityEditClick,
        icon: faEdit,
        tooltipLabel: t(params.isDriveTimePolygon ? ProximityMenuButtons.EditDriveTimePolygon : ProximityMenuButtons.EditRadius),
      });
    }

    const contextItemMenuStructure = additionalActionsContextMenuStructure({
      hideProximityCallback: isProximityVisible ? callbacks.onProximityHideClick : undefined,
      showProximityCallback: !isProximityVisible ? callbacks.onProximityShowClick : undefined,
      exportLocationsCallback: markersCount ? callbacks.onExportLocationsClick : undefined,
      exportContainedBoundariesCallback: callbacks.onExportContainedBoundariesClick,
      optimizeDrivingRouteCallback: markersCount ? callbacks.onDirectionsClick : undefined,
    }, t);

    if (contextItemMenuStructure.length) {
      buttons.push({
        buttonName: ProximityMenuButtons.AdditionalActions,
        buttonStyle: RoundButtonStyle.Primary,
        contextItemMenuStructure,
        icon: faEllipsisVertical,
        ref: toolsMenuButtonRef,
        tooltipLabel: t(ProximityMenuButtons.AdditionalActions),
      });
    }

    if (callbacks.onDeleteProximityClick) {
      buttons.push({
        buttonName: ProximityMenuButtons.Delete,
        buttonStyle: RoundButtonStyle.Danger,
        icon: faTrash,
        onClick: callbacks.onDeleteProximityClick,
        tooltipLabel: t(ProximityMenuButtons.Delete),
      });
    }
    return buttons;
  }, [
    callbacks.onProximityEditClick,
    callbacks.onProximityHideClick,
    callbacks.onProximityShowClick,
    callbacks.onExportLocationsClick,
    callbacks.onExportContainedBoundariesClick,
    callbacks.onDirectionsClick,
    callbacks.onDeleteProximityClick,
    isProximityVisible,
    markersCount,
    t,
    params.isDriveTimePolygon,
  ]);
};
