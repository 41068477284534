import {
  faCaretDown, faCaretUp, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type PropsWithChildren, type ReactNode, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { HeightTransitionComponent } from '../../components/heightTransition/heightTransition.component';
import { useTheme } from '../../themes/theme.hooks';
import {
  alertContainerStyle, buttonStyle,
} from './alert.styles';

export type AlertType = 'danger' | 'info' | 'warning' | 'success';

export type AlertSize = 'small' | 'default';

export type AlertProps = Readonly<PropsWithChildren<{
  autoCloseTimeout?: number;
  className?: string;
  collapsedContent?: ReactNode;
  transparentBackground?: boolean;
  reverseColors?: boolean;
  size?: AlertSize;
  type: AlertType;

  onClose?: () => void;
}>>;

export const AlertComponent: FC<AlertProps> = (props) => {
  const theme = useTheme();
  const { onClose, autoCloseTimeout } = props;
  const size = props.size ?? 'default';
  const collapseEnabled = !!props.collapsedContent;

  const [collapsed, setCollapsed] = useState(collapseEnabled);

  useEffect(() => {
    if (!autoCloseTimeout || !onClose) {
      return;
    }

    const timeoutId = setTimeout(() => {
      onClose();
    }, autoCloseTimeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onClose, autoCloseTimeout]);

  return (
    <div
      className={props.className}
      css={alertContainerStyle({ theme, type: props.type, size, reverseColors: !!props.reverseColors, transparentBackground: !!props.transparentBackground, hasCloseButton: !!props.onClose })}
    >
      {collapseEnabled && (
        <HeightTransitionComponent animateOnMount={false}>
          {collapsed ? props.collapsedContent : props.children}
        </HeightTransitionComponent>
      )}

      {!collapseEnabled && props.children}

      {props.collapsedContent && (
        <button
          css={buttonStyle}
          onClick={() => setCollapsed((previous) => !previous)}
        >
          <FontAwesomeIcon icon={collapsed ? faCaretDown : faCaretUp} />
        </button>
      )}

      {props.onClose && (
        <button
          css={buttonStyle}
          onClick={props.onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}
    </div>
  );
};
