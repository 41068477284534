import {
  type FC, useEffect, useState,
} from 'react';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { useTheme } from '../../../themes/theme.hooks';
import {
  CONTAINER_BORDER_WIDTH, INIT_ANIMATION_DURATION_MS, TRACK_OFFSET,
} from './progressbar.helpers';
import {
  containerStyles, dotStyle, indeterminate, trackStyles, trackWrapperStyle,
} from './progressBar.styles';

export type ProgressBarProps = Readonly<{
  progress?: number;
  height?: number;
  className?: string;
  borderWidth?: number;
  trackOffset?: number;
}>;

export const ProgressBarComponent: FC<ProgressBarProps> = (props) => {
  const animationDisabled = testingModeEnabled();
  const [isInitiated, setIsInitiated] = useState(animationDisabled);
  const theme = useTheme();
  const isIndeterminate = typeof props.progress === 'undefined' || props.progress === null;

  const containerHeight = props.height ?? 24;
  const borderWidth = props.borderWidth ?? CONTAINER_BORDER_WIDTH;
  const trackOffset = props.trackOffset ?? TRACK_OFFSET;
  const trackHeight = containerHeight - 2 * borderWidth - 2 * trackOffset;

  useEffect(() => {
    if (isInitiated) {
      return;
    }

    setTimeout(() => {
      setIsInitiated(true);
    }, INIT_ANIMATION_DURATION_MS);
  }, [isInitiated]);

  return (
    <div
      css={containerStyles({ theme, height: containerHeight, borderWidth })}
      className={props.className}
    >
      <div css={trackWrapperStyle({ trackHeight, trackOffset })}>
        <div
          css={[
            trackStyles({
              theme,
              progress: isInitiated && props.progress !== undefined ? props.progress : 0,
              trackHeight,
              animationDisabled,
            }),
            isIndeterminate ? indeterminate : null,
          ]}
        />

        {!isIndeterminate && (
          <div css={dotStyle({ theme, size: trackHeight })} />
        )}
      </div>
    </div>
  );
};
