export enum ErrorPageType {
  General = 'General',
  PageNotFound = 'PageNotFound',
  MapNotFound = 'MapNotFound',
  ContactSupport = 'ContactSupport',
  PublicMapUnavailable = 'PublicMapUnavailable',
  AccessForbidden = 'AccessForbidden',
  InvalidQueryString = 'InvalidQueryString',
}

// this can't stay under frontendState as we replace the router with error page if there's an error
// and the mapContextHolding place triggers MAP_RESET action on reload which clears
// the frontendState therefore clearing AppState if was placed under frontendState
export type ErrorPageState = {
  error: ErrorPageType | null;
};
