import { useMemo } from 'react';
import { filterAddedSpreadsheetColumns } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { type SpreadsheetColumn } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { flatten } from '../../_shared/utils/collections/collections';
import { useMapInfoSpreadsheetsSelector } from '../mapInfo/mapInfo.selectors';
import { useMatchupDataSelector } from './matchupData.selectors';
import { type MatchupDataState } from './matchupData.state';

const selectColumns = (matchupData: MatchupDataState, spreadsheetIds: number[]) =>
  flatten(
    Object.entries(matchupData)
      .map(([spreadsheetId, value]) => ({ spreadsheetId: parseInt(spreadsheetId, 10), columns: value.data?.columns ?? {} }))
      .filter(({ spreadsheetId }) => !isNaN(spreadsheetId) && spreadsheetIds.includes(spreadsheetId))
      .map(({ spreadsheetId, columns }) => Object.entries(columns)
        .filter((column): column is [string, string] => !!column[0] && !!column[1])
        .map(([id, name]) => ({ id, name, spreadsheetId })))
  );

export const useSpreadsheetColumns = ({ withServerColumns }:
{ withServerColumns?: boolean } = {}
): SpreadsheetColumn[] => {
  const matchupData = useMatchupDataSelector();
  const mapInfoSpreadsheets = useMapInfoSpreadsheetsSelector();

  return useMemo(() => {
    if (!mapInfoSpreadsheets) {
      return [];
    }

    const spreadsheetIds = mapInfoSpreadsheets.map(spreadsheet => spreadsheet.spreadSheetId);

    const allColumns = selectColumns(matchupData, spreadsheetIds);
    return withServerColumns ? allColumns : filterAddedSpreadsheetColumns(allColumns);
  },
  [matchupData, mapInfoSpreadsheets, withServerColumns]);
};
