import { css } from '@emotion/react';
import {
  faCheck, faLasso, faMousePointer, faPlay, faStop, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  markersSelectActivate,
  markersSelectDeactivate,
  markersSelectReset, markersSelectSave, markersSelectSetSelectType,
} from '~/store/frontendState/mapTools/markersSelect/markersSelect.actionCreators';
import { useMarkersSelectActiveSelector } from '~/store/frontendState/mapTools/markersSelect/markersSelect.selectors';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';
import { MapMarkersSelectOverlayComponent } from './mapMarkersSelectOverlay.component';

const buttonsWrapperStyle = (buttonsCount: number) => css({
  display: 'flex',
  justifyContent: buttonsCount <= 1 ? 'flex-end' : 'space-between',
});

export const MapMarkersSelectOverlayContainer: FC = () => {
  const mapMarkersSelectState = useMarkersSelectActiveSelector();
  const isMarkersSelectLassoLoading = mapMarkersSelectState?.isLassoSelectLoading;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isStartingView = !mapMarkersSelectState?.isActive;

  const options = useMemo(() => [
    {
      name: t('On Click'),
      value: MarkersSelectType.Click,
      icon: faMousePointer,
    }, {
      name: t('Lasso tool'),
      value: MarkersSelectType.Lasso,
      icon: faLasso,
    },
  ], [t]);

  const onApply = useCallback(() => {
    if (mapMarkersSelectState) {
      dispatch(markersSelectSave());
    }
  }, [dispatch, mapMarkersSelectState]);

  const onResetClick = useCallback(() => {
    dispatch(markersSelectReset());
  }, [dispatch]);

  const onSelectTypeChange = useCallback((selectType: MarkersSelectType) => {
    dispatch(markersSelectSetSelectType(selectType));
  }, [dispatch]);

  const onStartButtonClick = useCallback(() => {
    dispatch(markersSelectActivate());
  }, [dispatch]);

  const onCancel = useCallback(() => {
    dispatch(markersSelectDeactivate());
  }, [dispatch]);

  const buttons = useMemo(() => {
    if (!mapMarkersSelectState) {
      return [];
    }

    if (isStartingView) {
      return [{
        icon: faPlay,
        buttonStyle: ButtonStyle.Success,
        onClick: onStartButtonClick,
        label: t('Start'),
      }];
    }

    if (mapMarkersSelectState.selectedMarkerIds.length === 0) {
      return [{
        icon: faStop,
        buttonStyle: isMarkersSelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Danger,
        onClick: onCancel,
        label: t('Stop'),
        isDisabled: isMarkersSelectLassoLoading,
      }, {
        icon: faCheck,
        buttonStyle: isMarkersSelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Primary,
        label: t('Save'),
        isDisabled: true,
      }];
    }

    return [{
      icon: faTrash,
      buttonStyle: isMarkersSelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Danger,
      onClick: onResetClick,
      label: t('Reset'),
      isDisabled: isMarkersSelectLassoLoading,
    }, {
      icon: faCheck,
      buttonStyle: isMarkersSelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Primary,
      onClick: onApply,
      label: t('Save'),
      isDisabled: false,
    }];
  }, [isMarkersSelectLassoLoading, isStartingView, mapMarkersSelectState, onApply, onCancel, onResetClick, onStartButtonClick, t]);

  if (!mapMarkersSelectState) {
    return null;
  }

  const heading = mapMarkersSelectState.selectedMarkerIds.length
    ? t('Select & Save')
    : t('Start Selecting Now');

  let highlightedStepNumber = 1;
  if (mapMarkersSelectState.selectedMarkerIds.length > 0) {
    if (mapMarkersSelectState.selectType === MarkersSelectType.Click) {
      highlightedStepNumber = 2;
    }
    else if (mapMarkersSelectState.selectType === MarkersSelectType.Lasso) {
      highlightedStepNumber = 4;
    }
  }

  return (
    <MapMarkersSelectOverlayComponent
      dropdownHeading={t('Choose Selection Method')}
      isOpen
      highlightedStepNumber={highlightedStepNumber}
      onClose={onCancel}
      dropdownOptions={options}
      selectedOptionId={mapMarkersSelectState.selectType}
      onDropdownSelect={onSelectTypeChange}
      buttonsWrapperStyle={buttonsWrapperStyle(buttons.length)}
      buttons={buttons}
      heading={heading}
    />
  );
};
