import { GoogleSheetMapSyncType } from '~/map/map.repository';

export enum GoogleApiScope {
  DriveFile = 'https://www.googleapis.com/auth/drive.file',
  Spreadsheet = 'https://www.googleapis.com/auth/spreadsheets',
}

export enum MaptiveGoogleApiScope {
  Drive = 'drive',
  Sheets = 'sheets',
}

export enum GoogleAPIOperation {
  CREATE_MAP_FROM_SHEET = 'create_map_from_sheet',
  RECONNECT_GOOGLE_SPREADSHEET = 'reconnect_google_spreadsheet',
  SYNC_SPREADSHEETS = 'sync_spreadsheets',
}

/*
 * Due to recent changes on the Google APIs side, for our current set of functionality,
 * we now only require the drive.file permission for all operations.
 * However if we were adding more functionality, this might change.
 * So that's why this functionality is left here.
 */
export const determineRequiredMaptiveScopes = (syncMethod: GoogleSheetMapSyncType) => {
  switch (syncMethod) {
    case GoogleSheetMapSyncType.TwoWaySync:
    case GoogleSheetMapSyncType.MaptiveToGoogle:
      return [MaptiveGoogleApiScope.Drive];

    case GoogleSheetMapSyncType.GoogleToMaptive:
      return [MaptiveGoogleApiScope.Drive];

    default:
      return [MaptiveGoogleApiScope.Drive];
  }
};

const convertGoogleToMaptiveScope = (scopes: GoogleApiScope[] | null) => {
  const scopesUnique = new Set(scopes);

  return [
    ...(scopesUnique.has(GoogleApiScope.Spreadsheet) ? [MaptiveGoogleApiScope.Sheets] : []),
    ...(scopesUnique.has(GoogleApiScope.DriveFile) ? [MaptiveGoogleApiScope.Drive] : []),
  ];
};

export const areGoogleUserScopesSufficient = (
  requiredPermissions: MaptiveGoogleApiScope[] | null,
  googleScope: GoogleApiScope[] | null
) => {
  if (!googleScope && requiredPermissions) {
    return false;
  }

  const maptiveFromGoogleScope = convertGoogleToMaptiveScope(googleScope);

  return requiredPermissions?.reduce((arePermissionIncluded, val) =>
    arePermissionIncluded && maptiveFromGoogleScope?.includes(val), true
  );
};
