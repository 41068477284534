import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  DRAWING_TOOL_NUMBER_MAX_RADIUS, DRAWING_TOOL_NUMBER_MIN_RADIUS,
} from '~/drawingTool/drawingTool.constants';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemNumber } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemSizeForCurrentZoom } from '../hooks/useDrawingToolItemSizeForCurrentZoom';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolNumberInstanceNumberContainer } from './drawingToolNumberInstanceNumber.container';

type MapCircleInstanceProps = {
  instance: DrawingItemNumber;
};

const DrawingToolNumberInstanceContainer: FC<MapCircleInstanceProps> = ({ instance }) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut, onMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const radiusOnCurrentZoom = useDrawingToolItemSizeForCurrentZoom({
    itemSize: instance.settings.radius,
    min: 0,
    max: DRAWING_TOOL_NUMBER_MAX_RADIUS,
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
  });

  const onNumberClick = useCallback((circleAreaId: Uuid, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      circleAreaId,
      {
        type: DrawingTool.Number,
        value: {
          id: circleAreaId,
          center: instance.center,
          placement: instance.placement,
          settings: instance.settings,
        },
      })
    );
  }, [dispatch, instance.settings, instance.center, instance.placement, drawingEventsEnabledRef]);

  const onNumberRightClick = useCallback((_circleAreaId: Uuid, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Number,
      value: instance,
    });
  }, [onRightClick, instance, drawingEventsEnabledRef]);

  if (radiusOnCurrentZoom < DRAWING_TOOL_NUMBER_MIN_RADIUS) {
    return null;
  }

  return (
    <DrawingToolNumberInstanceNumberContainer
      instance={instance}
      zIndex={zIndex}
      onClick={onNumberClick}
      onRightClick={onNumberRightClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    />
  );
};

const pureComponent = memo(DrawingToolNumberInstanceContainer);
export { pureComponent as DrawingToolNumberInstanceContainer };
