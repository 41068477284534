import { type FC } from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import { useSpreadsheetColumnsOptions } from '../../../sidebar/sidebarApps/mapTools/boundary/hooks/useSpreadsheetColumnsOptions';
import { useMapSettingsMarkersGeneralSelector } from '../../../store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { type SpreadsheetColumnId } from '../../types/spreadsheetData/spreadsheetColumn';
import { ChooseColumnModalComponent } from './chooseColumnModal.component';

export type ChooseColumnModalContainerProps = ModalProps<{
  showDisplayRowIdIfColumnEmptyCheckbox: boolean;
  onSubmit: (selectedColumn: SpreadsheetColumnId, displayRowIfColumnEmpty: boolean) => void;
  initialColumnValue?: SpreadsheetColumnId;
}>;

export const ChooseColumnModalContainer: FC<ChooseColumnModalContainerProps> = (props) => {
  const markersSettingsGeneral = useMapSettingsMarkersGeneralSelector();

  const mappedColumns = useSpreadsheetColumnsOptions();

  return (
    <ChooseColumnModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      showDisplayRowIdIfColumnEmptyCheckbox={props.showDisplayRowIdIfColumnEmptyCheckbox}
      columnOptions={mappedColumns}
      initialColumnValue={props.initialColumnValue}
      onSubmit={props.onSubmit}
      initialDisplayRowIdIfColumnEmpty={markersSettingsGeneral.labelsAboveMarkersDisplayRowIdIfEmpty}
    />
  );
};
