import {
  computeArea,
  computeOffset, type LatLngLike,
} from 'spherical-geometry-js';
import { BoundingBox } from '../../../map/map/boundingBox';
import { type LatLngRowData } from '../../../store/spreadsheetData/spreadsheetData.helpers';
import {
  type LatLng, type LatLngBounds,
} from '../../types/latLng';
import { type MultiPolygon } from '../../types/polygon/polygon.types';
import { sphericalLatLngToLatLng } from './conversion.helpers';
import { getLatLngsOfMultipolygonPoints } from './polygons.helpers';

export const getBoundingBoxOfLatLngRows = (latLng: LatLng, items: ReadonlyArray<LatLngRowData>) => {
  return getBoundingBox([latLng, ...items]);
};

export const getBoundingBoxOfMultipolygon = (multiPolygon: MultiPolygon): BoundingBox => {
  const allLatLngs = getLatLngsOfMultipolygonPoints(multiPolygon);
  return getBoundingBox(allLatLngs);
};

export const getBoundingBox = (latLngItems: ReadonlyArray<LatLng>): BoundingBox => {
  const newBounds = new BoundingBox();

  for (const item of latLngItems) {
    newBounds.extend({
      lat: item.lat,
      lng: item.lng,
    });
  }

  return newBounds;
};

export const validateLatLngBounds = (bounds: LatLngBounds) => {
  // bounds.sw.lng < bounds.ne.lng is not valid assumpion
  // sw.lng can be higher if our view spans across antimeridian (-180/180)

  if (Math.abs(bounds.ne.lng) > 180) {
    return false;
  }

  if (Math.abs(bounds.sw.lng) > 180) {
    return false;
  }

  return bounds.sw.lat < bounds.ne.lat;
};

export const validateBoundingBox = (box: BoundingBox) =>
  validateLatLngBounds(box.getBounds());

export const getBoundingBoxOfCircle = (center: LatLngLike, radiusInMeters: number) => {
  const targetNorthEast = computeOffset(center, radiusInMeters * Math.sqrt(2), 45);
  const targetSouthWest = computeOffset(center, radiusInMeters * Math.sqrt(2), 225);
  const bb = new BoundingBox();
  bb.extend(sphericalLatLngToLatLng(targetNorthEast));
  bb.extend(sphericalLatLngToLatLng(targetSouthWest));
  return bb;
};

export const computeLatLngBoundsArea = (bounds: LatLngBounds) =>
  computeArea([
    { lat: bounds.sw.lat, lng: bounds.sw.lng },
    { lat: bounds.ne.lat, lng: bounds.sw.lng },
    { lat: bounds.ne.lat, lng: bounds.ne.lng },
    { lat: bounds.sw.lat, lng: bounds.ne.lng },
  ]);
