import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { createAppError } from '~/store/modal/modal.actionCreators';

export type UseVerifyNumericFillDataParams = {
  checkIsNumeric: (columnId: string | null, spreadsheetId: number | null) => Promise<boolean>;
};

export const useVerifyNumericFillData = ({ checkIsNumeric }: UseVerifyNumericFillDataParams) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const verifyNumericFillData = useCallback(async (boundaryFillData: SpreadsheetColumnId | null) => {

    const onNonNumericalColumnError = () => {
      dispatch(createAppError({
        type: AppErrorType.General,
        errorTitle: t('Selected data is not numerical'),
        title: t('Invalid Data Column'),
      }));
    };

    if (!boundaryFillData) {
      onNonNumericalColumnError();
      return false;
    }

    try {
      const isNumeric = await checkIsNumeric(boundaryFillData.columnId, boundaryFillData.spreadsheetId);
      if (!isNumeric) {
        onNonNumericalColumnError();
        return false;
      }
    }
    catch (e) {
      onNonNumericalColumnError();
      return false;
    }

    return true;
  }, [checkIsNumeric, dispatch, t]);

  return {
    verifyNumericFillData,
  };
};
