import {
  css, type SerializedStyles,
} from '@emotion/react';
import { stringify } from '~/_shared/utils/string/stringify';
import { type Theme } from '../../themes/theme.model';
import { insertBeforeInArray } from '../../utils/dnd/dnd.helpers';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '../scrollbar/scrollbar.component';
import { ImageSelectorDragLayerComponent } from './imageSelectorDragLayer.component';
import {
  type ImageSelectorItem,
  ImageSelectorItemComponent,
} from './imageSelectorItem.component';

const rootStyles = css({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
});

type imageStylesProps = 'imageWidth' | 'imageHeight' | 'paddingHorizontal' | 'paddingVertical' | 'isDisabled';
const itemStyle = <T extends unknown>(props: Required<Pick<ImageSelectorProps<T>, imageStylesProps>>) => (theme: Theme) => css({
  width: props.imageWidth,
  height: props.imageHeight,
  objectFit: 'scale-down',
  marginRight: props.paddingHorizontal,
  marginBottom: props.paddingVertical,
  borderRadius: 4,
  backgroundColor: theme.backgroundColors.secondary,
  cursor: props.isDisabled ? 'not-allowed' : 'default',
  boxSizing: 'border-box',

  '&:hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

const getSelectedImageStyles = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.highlight,
  border: `1px solid ${theme.borderColors.activeItem}`,

  '&:hover': {
    backgroundColor: theme.backgroundColors.highlightHover,
  },
});

type ImageSelectorProps<T> = Readonly<{
  className?: string;
  dontUseScrollbar?: boolean;
  imageHeight?: number;
  imageWidth?: number;
  images: ReadonlyArray<ImageSelectorItem<T>>;
  isDisabled?: boolean;
  isDraggable?: boolean;
  multiselect?: boolean;
  paddingHorizontal?: number;
  paddingVertical?: number;
  selectedImageStyles?: SerializedStyles;
  selectedImagesIds: ReadonlyArray<T>;

  onDrop?: (newImageIdsOrder: ReadonlyArray<T>) => void;
  onSelectionChanged: (selectedImagesIds: ReadonlyArray<T>) => void;
  stringifyId?: (id: T) => string;
}>;

export const ImageSelectorComponent = <T extends unknown>({
  className,
  dontUseScrollbar,
  imageHeight = 50,
  imageWidth = 50,
  images,
  isDisabled,
  isDraggable,
  onDrop,
  paddingHorizontal = 5,
  paddingVertical = 5,
  selectedImageStyles,
  selectedImagesIds,
  stringifyId = stringify,
  multiselect,
  onSelectionChanged,
  ...restProps
}: ImageSelectorProps<T>) => {
  const createOnImageClick = (imageId: T) => () => {
    if (isDisabled) {
      return;
    }
    if (selectedImagesIds.includes(imageId)) {
      return onSelectionChanged(multiselect ? selectedImagesIds.filter(id => id !== imageId) : []);
    }

    return onSelectionChanged(multiselect ? [...selectedImagesIds, imageId] : [imageId]);
  };

  const dropHandler = (sourceId: T, targetId: T) =>
    onDrop?.(insertBeforeInArray({
      source: sourceId, target: targetId, array: images.map(i => i.id),
    }));

  const computedItemStyle = itemStyle({
    imageWidth,
    imageHeight,
    paddingHorizontal,
    paddingVertical,
    isDisabled: !!isDisabled,
  });

  const content = (
    <>
      <ImageSelectorDragLayerComponent
        previewHeight={imageHeight}
        previewWidth={imageWidth}
        css={computedItemStyle}
      />
      {images.map(i => (
        <ImageSelectorItemComponent
          css={[
            computedItemStyle,
            selectedImagesIds.includes(i.id) && getSelectedImageStyles,
            selectedImagesIds.includes(i.id) && selectedImageStyles,
          ]}
          item={i}
          width={imageWidth}
          height={imageHeight}
          key={stringifyId(i.id)}
          onClick={createOnImageClick(i.id)}
          onDrop={isDraggable && onDrop ? dropHandler : undefined}
        />
      ))}
    </>
  );

  if (dontUseScrollbar) {
    return (
      <div
        {...restProps}
        css={rootStyles}
        className={className}
      >
        {content}
      </div>
    );
  }

  return (
    <ScrollBarComponent
      {...restProps}
      css={rootStyles}
      className={className}
      type={ScrollbarType.Vertical}
    >
      {content}
    </ScrollBarComponent>
  );
};
