import {
  type LocalStoredUndoItemAnyVersion, type TimestampedLocalStoredUndoItem, UndoItemType,
} from '~/_shared/types/undo/undoItem';
import { UNDO_MODEL_VERSIONS } from '../undo.constants';
import { convertUndoHeatmapDataAnyVersionToCurrent } from './undoHeatmapVersionConverters';
import {
  type AllProximityVersions, convertUndoProximityDataAnyVersionToCurrent,
} from './undoProximityVersionConverters';
import {
  type AllRouteVersions, convertUndoRouteDataAnyVersionToCurrent,
} from './undoRouteVersionConverters';

// first we check if we support the version of undo item
// if yes we will proceed to conversion, if not we will return null
export const anyVersionUndoItemToCurrentVersionUndoItem = (
  anyVersionedUndoItem: LocalStoredUndoItemAnyVersion,
): TimestampedLocalStoredUndoItem | null => {

  if (UNDO_MODEL_VERSIONS[anyVersionedUndoItem.type]?.includes(anyVersionedUndoItem.version as any)) {
    if (anyVersionedUndoItem.type === UndoItemType.REMOVE_PROXIMITY) {
      return {
        ...anyVersionedUndoItem,
        data: convertUndoProximityDataAnyVersionToCurrent(
          anyVersionedUndoItem.data,
          anyVersionedUndoItem.version as AllProximityVersions,
        ),
      };
    }
    if (anyVersionedUndoItem.type === UndoItemType.REMOVE_ROUTE) {
      return {
        ...anyVersionedUndoItem,
        data: convertUndoRouteDataAnyVersionToCurrent(
          anyVersionedUndoItem.data,
          anyVersionedUndoItem.version as AllRouteVersions,
        ),
      };
    }
    if (anyVersionedUndoItem.type === UndoItemType.REMOVE_HEATMAP) {
      return {
        ...anyVersionedUndoItem,
        data: convertUndoHeatmapDataAnyVersionToCurrent(
          anyVersionedUndoItem.data,
          anyVersionedUndoItem.version as AllRouteVersions,
        ),
      };
    }
  }

  return null;
};
