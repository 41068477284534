import { type BoundaryGroupsActions } from '../../../../boundaryGroups/boundaryGroups.actions';
import { BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED } from '../../../../boundaryGroups/boundaryGroups.actionTypes';
import { type ConvertBoundariesAction } from './convertBoundaries.action';
import {
  CONVERT_BOUNDARIES_HIDE_NAME_MODAL, CONVERT_BOUNDARIES_SHOW_NAME_MODAL, CONVERT_BOUNDARIES_START,
  CONVERT_BOUNDARIES_STOP,
} from './convertBoundaries.actionTypes';

type ConvertBoundariesState = {
  showBoundaryConvertModal: boolean;
  boundaryTerritoryGroupId: number | null;
  inProgress: boolean;
};

const initialState: ConvertBoundariesState = {
  showBoundaryConvertModal: false,
  boundaryTerritoryGroupId: null,
  inProgress: false,
};

export const convertBoundariesReducer = (
  state: ConvertBoundariesState = initialState, action: ConvertBoundariesAction | BoundaryGroupsActions
): ConvertBoundariesState => {
  switch (action.type) {
    case CONVERT_BOUNDARIES_SHOW_NAME_MODAL:
      return {
        ...state,
        showBoundaryConvertModal: true,
        boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
      };

    // when boundary groups convert request happens, show the loader immediately,
    // without waiting for actual websocket event to occur so the user is not confused
    case BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED:
    case CONVERT_BOUNDARIES_START:
      return {
        ...state,
        inProgress: true,
      };

    case CONVERT_BOUNDARIES_HIDE_NAME_MODAL:
      return {
        ...state,
        showBoundaryConvertModal: false,
      };

    case CONVERT_BOUNDARIES_STOP:
      return {
        ...state,
        inProgress: false,
        boundaryTerritoryGroupId: null,
      };

    default:
      return state;
  }
};
