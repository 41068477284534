import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { RestoreOption } from './restoreDefaultMarkerCustomizations.enums';

const modalContentStyle = css({
  padding: '24px 24px',
});

const labelStyle = css({
  textTransform: 'uppercase',
  fontWeight: 'bold',
});

const radioGroupStyle = css({
  marginTop: 4,
});

type RestoreDefaultMarkerCustomizationsComponentProps = ModalProps<{
  onRestoreAll?: () => void;
  onRestoreAllIndividual?: () => void;
  onRestoreGroup?: () => void;
  onRestoreIndividual?: () => void;
}>;

export const RestoreDefaultMarkerCustomizationsModalComponent: FC<RestoreDefaultMarkerCustomizationsComponentProps> = (props) => {
  const [t] = useTranslation();

  const [restoreOption, setRestoreOption] = useState<RestoreOption | null>(null);

  const handleSave = useCallback(() => {
    switch (restoreOption) {
      case RestoreOption.Individual:
        props.onRestoreIndividual?.();
        break;
      case RestoreOption.AllIndividual:
        props.onRestoreAllIndividual?.();
        break;
      case RestoreOption.Group:
        props.onRestoreGroup?.();
        break;
      case RestoreOption.All:
        props.onRestoreAll?.();
        break;
      default:
    }
    props.onClose();
  }, [props, restoreOption]);

  const radioItems: ReadonlyArray<RadioGroupItem<RestoreOption>> = useMemo(() => [
    ...(props.onRestoreIndividual ? [{
      value: RestoreOption.Individual,
      label: t(RestoreOption.Individual),
      description: '',
    }] : []),
    ...(props.onRestoreAllIndividual ? [{
      value: RestoreOption.AllIndividual,
      label: t(RestoreOption.AllIndividual),
      description: '',
    }] : []),
    ...(props.onRestoreGroup ? [{
      value: RestoreOption.Group,
      label: t(RestoreOption.Group),
      description: '',
    }] : []),
    ...(props.onRestoreAll ? [{
      value: RestoreOption.All,
      label: t(RestoreOption.All),
      description: '',
    }] : []),
  ], [t, props.onRestoreAll, props.onRestoreAllIndividual, props.onRestoreGroup, props.onRestoreIndividual]);

  const handleChangeRadioOption = useCallback((newValue: RestoreOption) => {
    setRestoreOption(newValue);
  }, []);

  return (
    <ModalComponent
      {...props}
      confirmButton={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Danger}
          isDisabled={restoreOption === null}
          onClick={handleSave}
          prefixIcon={faCheck}
          text={t('Restore Default')}
        />
      )}
      contentStyle={modalContentStyle}
      maxWidth={540}
      caption={t('Restore Default')}
    >
      <div css={labelStyle}>{t('Restore Default For')}</div>
      <RadioGroupComponent<RestoreOption>
        css={radioGroupStyle}
        onValueChange={handleChangeRadioOption}
        selectedValue={restoreOption}
        items={radioItems}
      />
    </ModalComponent>
  );
};
