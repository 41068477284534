import { css } from '@emotion/react';

export const errorStyle = css({
  width: '100%',
  lineHeight: '175%',
  minHeight: 21,
  marginTop: 8,
});

export const buttonWrapperStyle = css({
  height: '40px',
  display: 'flex',
});

export const textInputStyles = ({ button }: {button: boolean}) => css({
  paddingRight: 0,
  ...(button ? {
    borderRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  } : undefined),
});
