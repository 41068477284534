import { css } from '@emotion/react';
import type { CSSProperties } from 'react';

export const formStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  width: '100%',
});

export const alertStyle = css({
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  minHeight: 24,
});

export const hiddenStyle = css({ display: 'none' });

export const footerButtonStyle = css({ marginLeft: 'auto' });

export const accountSettingsModalContentStyle = css({
  paddingBlock: 16,
  paddingInline: 32,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

export const scrollContentStyle: CSSProperties = {
  display: 'flex',
};
