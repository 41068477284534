import {
  DropDownItemSize, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { InputSize } from '~/_shared/baseComponents/inputs';

type HeatMapDropdownProps<T> = Readonly<{
  value: T;
  onChange: (newValue: T) => void;
  options: ReadonlyArray<{ name: string; value: T }>;
  isDisabled?: boolean;
  placeholder?: string;
}>;

export const HeatMapDropdownComponent = <T extends unknown>(props: HeatMapDropdownProps<T>) => {

  return (
    <RegularDropdownComponent
      onChange={props.onChange}
      value={props.value}
      options={props.options}
      inputSize={InputSize.Medium}
      itemSize={DropDownItemSize.Large}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder}
      inPortal
    />
  );
};
