import {
  type KeyboardEvent,
  useCallback, useMemo, useRef,
  useState,
} from 'react';
import {
  AutocompleteComponent,
  type AutocompleteRegularOption,
} from '~/_shared/baseComponents/autocomplete';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';

type OptionType = AutocompleteRegularOption<string>;

type SectionAutocompleteComponentProps = {
  isLoading: boolean;
  options: OptionType[];
  onChange: (value: string | null) => void;
  value: string | null;
  isDisabled?: boolean;
};

const inputStyle = ({ showError }: { showError: boolean }) => (theme: Theme) => ({
  borderColor: showError ? theme.borderColors.error : undefined,
});

export const SectionAutocompleteComponent = (props: SectionAutocompleteComponentProps) => {
  const { onChange, value, isDisabled } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputValue = useRef('');
  const [t] = useTranslation();
  const triggerInputRef = useRef<HTMLInputElement>(null);

  const customOption = useMemo(() => {
    const isInitialAmongOptions = props.options.some(option => option.value === value);

    if (isInitialAmongOptions || !value?.length) {
      return null;
    }

    return { value, name: value };
  }, [props.options, value]);

  const options = useMemo(() => {
    return [...props.options, ...(customOption ? [customOption] : [])];
  }, [props.options, customOption]);

  const addCustomValue = useCallback((inputValue: string) => {
    const customValue = inputValue.trim();

    if (options.some(option => option.value.trim() === customValue)) {
      return;
    }

    onChange(customValue);
  }, [onChange, options]);

  const onKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addCustomValue(inputValue.current);
      triggerInputRef.current?.blur();
      setIsOpen(false);
    }
  }, [addCustomValue]);

  // accept new column name on click outside the input
  const onBlur = useCallback(() => {
    addCustomValue(inputValue.current);
  }, [addCustomValue]);

  const handleInputChange = useCallback((value: string) => {
    inputValue.current = value;
  }, []);

  return (
    <AutocompleteComponent
      triggerInputRef={triggerInputRef}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={t('Select Column Name') + ' ...'}
      onInputChange={handleInputChange}
      inPortal
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      inputStyle={inputStyle({ showError: (!value && !props.isLoading) })}
      hideChevron
      isDisabled={isDisabled}
      hideNoResultsMessage // hide "No results" while typing custom column name
    />
  );
};
