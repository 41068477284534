import { type WithoutModalProps } from '../../../../modal/renderModal';
import { type TranslationFnc } from '../../../utils/hooks';
import { type StorageService } from '../../../utils/storageService';
import { type ConfirmationModalProps } from './confirmationModal.component';
import {
  type PermanentConfirmSettings, PermanentConfirmStrategy, type UseConfirmationModalParams,
} from './useConfirmationModal';

const getConfirmationModalPermanentConfigKey = (id: string) => (`permanent-confirm-${id}`);

const getModalConfirmedFromStorage = (storageKey: string, strategy: PermanentConfirmStrategy, storageService: StorageService) => {
  const storageReadValue = strategy === PermanentConfirmStrategy.Session ?
    storageService.getSessionStorageItem(storageKey) : storageService.getLocalStorageItem(storageKey);
  return storageReadValue === 'true';
};

export const setModalConfirmedToStorage = (storageService: StorageService, permanentSettings?: PermanentConfirmSettings) => {
  if (!permanentSettings) {
    return;
  }

  const storageKey = getConfirmationModalPermanentConfigKey(permanentSettings.id);

  if (permanentSettings.strategy === PermanentConfirmStrategy.Session) {
    storageService.setSessionStorageItem(storageKey, 'true');
  }
  else {
    storageService.setLocalStorageItem(storageKey, 'true');
  }
};

export const checkIfModalIsAlreadyConfirmed = (permanentSettings: PermanentConfirmSettings, storageService: StorageService) => {
  if (!permanentSettings) {
    return false;
  }

  const storageKey = getConfirmationModalPermanentConfigKey(permanentSettings.id);

  if (storageKey === null) {
    return false;
  }

  return getModalConfirmedFromStorage(storageKey, permanentSettings.strategy, storageService);
};

export const buildConfirmationModalPropsFromParams = (
  params: UseConfirmationModalParams,
  t: TranslationFnc,
  storageService: StorageService
): WithoutModalProps<ConfirmationModalProps> => {
  let checkboxLabel;

  switch (params.permanentConfirmSettings?.strategy) {
    case 'session': {
      checkboxLabel = t('Do not show again for this session');
      break;
    }

    case 'local': {
      checkboxLabel = t('Do not show again');
      break;
    }

    default: {
      break;
    }
  }

  const onConfirm = (isPermanent: boolean) => {
    if (isPermanent) {
      setModalConfirmedToStorage(storageService, params.permanentConfirmSettings);
    }

    params.onConfirm();
  };

  return {
    ...params,
    children: params.text,
    onConfirm,
    checkboxLabel,
  };
};
