import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { type BoundaryEditAction } from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { type MapShapeInstance } from '../../../../../map/map/mapObjects/mapShape/mapShapeModel';
import {
  BOUNDARY_DRAW_ADD_SHAPE, BOUNDARY_DRAW_CLOSE_MODAL,
  BOUNDARY_DRAW_DEACTIVATE,
  BOUNDARY_DRAW_DRAWING_START,
  BOUNDARY_DRAW_DRAWING_STOP,
  BOUNDARY_DRAW_EDIT_ACTIVATE, BOUNDARY_DRAW_EDIT_OPEN_MODAL,
  BOUNDARY_DRAW_OUTLINE_HOVER,
  BOUNDARY_DRAW_OUTLINE_MOUSEOUT, BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL,
  BOUNDARY_DRAW_REMOVE_BUTTON_HOVER,
  BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT,
  BOUNDARY_DRAW_RESET_SHAPES,
  BOUNDARY_DRAW_SET_DRAW_TYPE,
  BOUNDARY_DRAW_UPDATE_SHAPE,
} from './boundaryDraw.actionTypes';
import { type DrawType } from './boundaryDraw.state';

type BoundaryDrawPolygonDragEditArguments = {
  paths: ReadonlyArray<PolygonPath>;
  boundaryId: BoundaryId;
  boundaryGroupId: BoundaryGroupId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  disabledReason?: string;
};

type BoundaryDrawEditArguments = {
  boundaryGroupId: BoundaryGroupId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  action: BoundaryEditAction;
  boundaryId?: BoundaryId;
  startAsActive?: boolean;
};

export const boundaryDrawPolygonDragEditOpenModal = (payload: BoundaryDrawPolygonDragEditArguments) => ({
  type: BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL,
  payload,
}) as const;

export const boundaryDrawEditOpenModal = (payload: BoundaryDrawEditArguments) => ({
  type: BOUNDARY_DRAW_EDIT_OPEN_MODAL,
  payload,
}) as const;

export const boundaryDrawCloseModal = () => ({
  type: BOUNDARY_DRAW_CLOSE_MODAL,
}) as const;

export const boundaryDrawEditActivate = () => ({
  type: BOUNDARY_DRAW_EDIT_ACTIVATE,
}) as const;

export const boundaryDragEditUpdate = (shape: MapShapeInstance) => ({
  type: BOUNDARY_DRAW_UPDATE_SHAPE,
  payload: {
    shape,
  },
}) as const;

export const boundaryDrawAddShape = (shape: MapShapeInstance) => ({
  type: BOUNDARY_DRAW_ADD_SHAPE,
  payload: {
    shape,
  },
}) as const;

export const boundaryDrawSetDrawType = (drawType: DrawType) => ({
  type: BOUNDARY_DRAW_SET_DRAW_TYPE,
  payload: {
    drawType,
  },
}) as const;

export const boundaryDrawResetShapes = () => ({
  type: BOUNDARY_DRAW_RESET_SHAPES,
}) as const;

export const boundaryDrawDeactivate = () => ({
  type: BOUNDARY_DRAW_DEACTIVATE,
}) as const;

export const boundaryDrawOutlineHover = () => ({
  type: BOUNDARY_DRAW_OUTLINE_HOVER,
}) as const;

export const boundaryDrawOutlineMouseOut = () => ({
  type: BOUNDARY_DRAW_OUTLINE_MOUSEOUT,
}) as const;

export const boundaryDrawRemoveButtonHover = () => ({
  type: BOUNDARY_DRAW_REMOVE_BUTTON_HOVER,
}) as const;

export const boundaryDrawRemoveButtonMouseOut = () => ({
  type: BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT,
}) as const;

export const boundaryDrawStartDrawing = () => ({
  type: BOUNDARY_DRAW_DRAWING_START,
}) as const;

export const boundaryDrawStopDrawing = () => ({
  type: BOUNDARY_DRAW_DRAWING_STOP,
}) as const;
