import {
  put, takeLatest,
} from 'redux-saga/effects';
import { select } from '../../../_shared/utils/saga/effects';
import {
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS, BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
} from '../../boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { type BoundaryTerritoryGroup } from '../../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { MAP_SETTINGS_READY_SET } from '../ready/mapSettingsReady.actionTypes';
import { mapSettingsBoundariesSetPrimaryBoundaryGroupId } from './mapSettingsBoundaries.actionCreators';

export function* mapSettingsBoundariesSagas() {
  yield takeLatest([
    BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
    BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
    MAP_SETTINGS_READY_SET,
  ], validatePrimaryBoundaryGroupId);
}

function* validatePrimaryBoundaryGroupId() {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> =
    yield select<ReadonlyArray<BoundaryTerritoryGroup>>(state => state.map.boundaryTerritoryGroups.groups);

  const currentPrimaryBoundaryGroupId: number | null = yield select<number | null>(
    state => state.map.mapSettings.data.boundaries.primaryBoundaryGroupId
  );

  const isMapSettingsReady: boolean = yield select<boolean>(state => state.map.mapSettings.isReady);

  if (!isMapSettingsReady) {
    return;
  }

  const boundaryTerritoryGroupsIds = boundaryTerritoryGroups.map(territoryGroup => territoryGroup.boundaryGroupId);

  if (!boundaryTerritoryGroupsIds.length) {
    return;
  }

  if (!currentPrimaryBoundaryGroupId || !boundaryTerritoryGroupsIds.includes(currentPrimaryBoundaryGroupId)) {
    yield put(mapSettingsBoundariesSetPrimaryBoundaryGroupId(boundaryTerritoryGroupsIds[0]));
  }
}
