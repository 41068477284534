import {
  apiGet, apiPost,
} from '../../_shared/utils/api/api.helpers';

export type VerifyEmailResponse = {
  message: string;
};

export const getEmailVerificationRequest = (expires: string, hash: string, id: string, email: string, signature: string): Promise<VerifyEmailResponse> => {
  const requestUrl = '/api/auth/verify';
  return apiGet(requestUrl, {
    expires,
    hash,
    id,
    email: encodeURIComponent(email),
    signature,
  });
};

export const resendEmailVerificationRequest = (userEmail: string): Promise<VerifyEmailResponse> => {
  const requestUrl = '/api/auth/verify/resend';
  return apiPost(requestUrl, {
    email: userEmail,
  });
};
