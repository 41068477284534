import { type FileAttachmentId } from '~/_shared/types/file.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { flatten } from '~/_shared/utils/collections/collections';
import {
  type MapSettingsMarkersLabelOffset,
  type MarkerLabelCustomOffsets, type ReadonlyMarkerImages,
} from './mapSettingsMarkers.state';

export type FlatMarkerImages = SpreadsheetRowId & {
  images: FileAttachmentId[];
  rowImages: { [rowId: string]: FileAttachmentId[] };
};

export const flattenMarkerImages = (markerImages: ReadonlyMarkerImages) =>
  flatten(Object.entries(markerImages).map(([spreadsheetId, rowImages]) =>
    rowImages ? Object.entries(rowImages).map(([rowId, images]) => ({
      spreadsheetId: Number(spreadsheetId),
      rowId,
      images,
      rowImages,
    })).filter((item): item is FlatMarkerImages => !!item.images) : []));

export type FlatLabelMarkerOffsets = SpreadsheetRowId & {
  offset: MapSettingsMarkersLabelOffset;
  rowOffsets: { [rowId: string]: MapSettingsMarkersLabelOffset };
};

export const flattenLabelMarkerOffsets = (labelOffsets: MarkerLabelCustomOffsets) =>
  flatten(Object.entries(labelOffsets).map(([spreadsheetId, rowOffsets]) =>
    rowOffsets ? Object.entries(rowOffsets).map(([rowId, offset]) => ({
      spreadsheetId: Number(spreadsheetId),
      rowId,
      offset,
      rowOffsets,
    })).filter((item): item is FlatLabelMarkerOffsets => !!item.offset) : []));
