import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isColumnNumeric } from '~/heatMap/panel/form/useIsNumericColumnCheck.helpers';
import { MAX_GROUP_COUNT } from '../_shared/constants/grouping.constants';
import {
  type GroupingColumn,
  type GroupingColumnNumeric,
  GroupingType,
} from '../_shared/types/grouping/grouping';
import { type MarkerSettings } from '../_shared/types/markers/visualSettings.types';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import { getHierarchyIndicatorIndex } from '../_shared/utils/markers/markersVisualSettings.helpers';
import { AppErrorType } from '../appError/appErrorType.enum';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { createAppError } from '../store/modal/modal.actionCreators';
import { useClientIdSelector } from '../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../store/selectors/useMapIdSelector';
import {
  checkIfIsNumericalColumn,
  getColumnGroupUniqueGroupsCount,
  getNumericGroupData,
  type NumericBucketWithRange,
} from '../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  DataType,
  type SpreadsheetDataData, Unfiltered,
} from '../store/spreadsheetData/spreadsheetData.state';
import { translate } from '../translations/Trans';
import { getSpreadsheetDataForRequestedGroupingColumn } from './useGroupsColumnsChange.helpers';
import { useNumericalGroupSettings } from './useNumericalGroupSettings';

export type RequestedGroupColumn = {
  groupColumnToReplace: GroupingColumn | null;
  newColumn: GroupingColumn;
};

export type GetMarkerVisualWithBakedDataProps = {
  bucketId: number;
  buckets?: NumericBucketWithRange[];
  opacity: number | null;
  selectedColor: string | null;
};

export type GroupChangeRequest = Readonly<{
  spreadsheetId: number;
  columnId: string;
  type: GroupingType;
}>;

export const useGroupColumnData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const activeColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const { openNumericalGroupSettingsModal } = useNumericalGroupSettings();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();
  const isMountedRef = useIsComponentMountedRef();

  const processData = async (requestedColumn: GroupChangeRequest, columnToReplace: GroupingColumn | null): Promise<[GroupingColumn, MarkerSettings[]] | null> => {
    if (!clientId || !mapId) {
      return null;
    }

    const spreadsheetData = await getSpreadsheetDataForRequestedGroupingColumn(clientId, mapId, requestedColumn);

    const numericInfo = spreadsheetData.values[requestedColumn.spreadsheetId]?.[Unfiltered]?.[requestedColumn.columnId]?.[DataType.NUMBER];
    const columnGroupDataCount = getColumnGroupUniqueGroupsCount(spreadsheetData,
      requestedColumn.spreadsheetId, Unfiltered, requestedColumn.columnId);

    if (!columnGroupDataCount) {
      return null;
    }

    // if we are adding or switching to a new column and it is numerical (by data), let's add it as numerical
    if (
      (
        !columnToReplace
        || columnToReplace.columnId !== requestedColumn.columnId
        || columnToReplace.spreadsheetId !== requestedColumn.spreadsheetId
        || columnToReplace.type !== GroupingType.Numeric
      )
      && (
        numericInfo
        && isColumnNumeric(numericInfo.extra.numericAndEmptyPercent, numericInfo.extra.numericPercent)
      )
    ) {
      return proceedWithNumericalColumnGroupRequest(columnGroupDataCount, requestedColumn, spreadsheetData);
    }

    // add new or change to GroupingType.Text
    if (requestedColumn.type === GroupingType.Text) {
      if (columnGroupDataCount <= MAX_GROUP_COUNT) {
        return [{
          columnId: requestedColumn.columnId,
          spreadsheetId: requestedColumn.spreadsheetId,
          type: GroupingType.Text,
        }, []];
      }
      else if (columnToReplace) {
        dispatch(createAppError({
          type: AppErrorType.General,
          errorTitle: translate('groupingTool.invalidColumn.limits{{MAX_GROUP_COUNT}}Explanation.replaceColumn', undefined, { MAX_GROUP_COUNT }),
          title: translate('Invalid Column for Grouping'),
        }));
        return null;
      }
      else {
        return proceedWithNumericalColumnGroupRequest(columnGroupDataCount, requestedColumn, spreadsheetData);
      }
    }
    else if (columnGroupDataCount <= MAX_GROUP_COUNT && columnToReplace && (
      columnToReplace.columnId !== requestedColumn.columnId || columnToReplace.spreadsheetId !== requestedColumn.spreadsheetId)
    ) {
      return [{
        columnId: requestedColumn.columnId,
        spreadsheetId: requestedColumn.spreadsheetId,
        type: GroupingType.Text,
      }, []];
    }
    // change to GroupingType.Numeric
    else {
      return proceedWithNumericalColumnGroupRequest(columnGroupDataCount, requestedColumn, spreadsheetData);
    }
  };

  const proceedWithNumericalColumnGroupRequest = async (
    columnGroupDataCount: number,
    requestedColumn: GroupChangeRequest,
    spreadsheetData: SpreadsheetDataData
  ): Promise<[GroupingColumn, MarkerSettings[]] | null> => {
    const numericData = getNumericGroupData(spreadsheetData, requestedColumn.spreadsheetId,
      Unfiltered, requestedColumn.columnId
    );

    if (!numericData || !checkIfIsNumericalColumn(numericData.extra.min, numericData.extra.max)) {
      dispatch(createAppError({
        type: AppErrorType.General,
        errorTitle: translate('groupingTool.invalidColumn.limits{{MAX_GROUP_COUNT}}Explanation', undefined, { MAX_GROUP_COUNT }),
        title: translate('Invalid Column for Grouping'),
      }));

      return null;
    }

    const isDecimal: boolean = (numericData.extra.max - numericData.extra.min) < columnGroupDataCount;
    const requestedNumericColumn = { ...requestedColumn, type: GroupingType.Numeric, isDecimal } as GroupingColumnNumeric;

    const hierarchyIndicator = getHierarchyIndicatorIndex(activeColumns, requestedNumericColumn);
    const newActiveColumns = [...activeColumns];
    newActiveColumns[hierarchyIndicator] = requestedNumericColumn;

    try {
      // everything that was set in the numerical settings modal will be stored in the following variable
      const { newNumericalColumn, markerSettings } = await openNumericalGroupSettingsModal({
        numericColumn: requestedNumericColumn,
        numericData,
        groupingColumns: newActiveColumns,
      });

      return [newNumericalColumn, markerSettings];
    }
    catch (e) {
      console.error('error processing numerical group settings modal', e);

      return null;
    }
  };

  const getGroupingColumnData = async (newColumn: GroupChangeRequest, columnToReplace: GroupingColumn | null): Promise<[GroupingColumn, MarkerSettings[]] | null> => {
    if (isMountedRef.current) {
      setIsLoading(true);
    }

    const results = await processData(newColumn, columnToReplace);

    if (isMountedRef.current) {
      setIsLoading(false);
    }

    return results;
  };

  return {
    isLoading,
    getGroupingColumnData,
  };
};
