import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../_shared/utils/hooks';
import { drawingItemsRemoveAllItems } from '../../store/mapSettings/drawing/items/drawingItems.actionCreators';

export const useDrawingItemsRemoveAll = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const removeAllDrawingsRequest = useCallback(() => {
    const removeAllDrawings = () => {
      dispatch(drawingItemsRemoveAllItems());
    };

    const onConfirm = () => {
      removeAllDrawings();
      closeConfirmationModal();
    };

    openConfirmationModal({
      cancelCaption: t('Cancel'),
      isConfirmButtonDestructive: true,
      confirmCaption: t('Proceed'),
      onCancel: closeConfirmationModal,
      title: t('Remove All Drawings'),
      text: t('You are about to delete all drawings from the map. This action is irreversible. Do you wish to continue?'),
      onConfirm,
      permanentConfirmSettings: {
        id: 'remove-drawings-all',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, dispatch, openConfirmationModal, t]);

  return {
    removeAllDrawings: removeAllDrawingsRequest,
  };
};
