import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const paneStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
  boxSizing: 'border-box',
  height: 45,
  padding: '0 10px 0 16px',
  width: '100%',
});

export const BoundaryRowPaneComponent = ({ children, ...rest }: PropsWithChildren<{ readonly className?: string }>) => {
  const theme = useTheme();

  return (
    <div
      css={paneStyle({ theme })}
      {...rest}
    >
      {children}
    </div>
  );
};
