import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  faEye, faEyeSlash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { TooltipBehavior } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { FormSubmitEnablerComponent } from '../formSubmitEnabler/formSubmitEnabler.component';
import {
  type ChangeableSettingRow,
  type ChangeableSettingsData,
  type SettingCheckboxData,
  type SettingColumn,
  type SettingData,
  type SettingDropdownData,
  type SettingInputData,
  type SettingRow,
  type SettingToggleData,
  SettingType,
  type SettingVisibilityData,
} from './settingsTable.types';

const tableStyle = ({ theme, fixedLayout }: ThemeProps<{ fixedLayout?: boolean }>) => css({
  color: theme.textColors.primary,
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'collapse',
  tableLayout: fixedLayout ? 'fixed' : 'auto',
});

const tableHeaderStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  height: 62,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const tableHeaderCellStyle = ({ align, width }: { align?: 'left' | 'center' | 'right'; width?: string }) => css({
  fontSize: '12px',
  textTransform: 'uppercase',
  padding: '0 12px',
  width,
  textAlign: align ?? 'left',
});

export const baseTableCellStyle = ({ isCentered, isDisabled }: ThemeProps<{
  isCentered?: boolean;
  isDisabled: boolean;
}>) => css({
  padding: 0,
  textAlign: isCentered ? 'center' : 'left',
  opacity: isDisabled ? .5 : undefined,
  cursor: isDisabled ? 'not-allowed' : undefined,
});

const tableDataCellStyle = ({ theme, isCentered, isDisabled }: ThemeProps<{
  isCentered?: boolean;
  isDisabled: boolean;
}>) => css({
  ...baseTableCellStyle({ theme, isCentered, isDisabled }),
  '&:nth-of-type(2n)': {
    background: theme.backgroundColors.secondary,
  },
  '*:hover': {
    cursor: isDisabled ? 'not-allowed' : undefined,
  },
  position: 'relative',
});

const tableDataRowStyle = ({ theme }: ThemeProps) => css({
  height: 48,
  position: 'relative',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:last-of-type': {
    border: 'none',
  },
});

const textSettingDataStyle = ({ overflowEllipsis }: { overflowEllipsis?: boolean }) => css({
  boxSizing: 'border-box',
  fontSize: '14px',
  padding: '0 12px',
  ...(overflowEllipsis ? {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  } : {}),
});

const visibilityIconStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  fontSize: '16px',
  color: theme.iconColors.contrast,
  '&:hover': {
    color: isDisabled ? theme.iconColors.contrast : theme.iconColors.focused,
  },
});

const inputDataWrapperStyle = css({
  padding: '0 12px',
  fontSize: '14px',
});

const togglePublicButtonStyle = css({
  border: 'none',
  background: 'none',
  padding: 5,
});

const checkboxStyle = css({
  display: 'inline-block',
});

const toggleStyle = css({
  display: 'inline-flex',
});

const checkmarkStyle = css({
  width: 20,
  height: 20,
});

const dropdownTriggerStyle = ({ theme }: ThemeProps) => css({
  background: 'none',
  padding: '0 12px',
  borderRadius: 0,
  border: 'none',
  height: 48,

  '&:hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

const dropdownListStyle = ({ theme }: ThemeProps) => css({
  boxShadow: `0 5px 15px ${theme.shadowColors.primary}`,
  border: 'none',
  borderRadius: '0 0 5px 5px',
});

const dropdownItemStyle = css({
  height: 40,
  transition: 'background .2s',
  '&:first-of-type': {
    border: 'none',
  },
  '&:last-of-type': {
    borderRadius: '0 0 5px 5px',
  },
});

const fullWidthStyle = css({
  width: '100%',
});

type SettingsTableProps = Readonly<{
  settingColumns?: SettingColumn[];
  settingRows: SettingRow[];
  onChange?: (index: number, newSettingRow: ChangeableSettingRow) => void;
  className?: string;
  customDataCellStyle?: ({ isCentered, isDisabled }: ThemeProps<{
    isCentered?: boolean;
    isDisabled: boolean;
  }>) => SerializedStyles;
  customDataRowStyle?: ({ theme }: ThemeProps) => SerializedStyles;
  onFormSubmit?: () => void;
}>;

export const SettingsTableComponent: FC<SettingsTableProps> = (props) => {
  const theme = useTheme();
  const areColumnsWidthsSet = !!props.settingColumns;

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onFormSubmit?.();
  };

  const replaceDataValue = (newSettingData: ChangeableSettingsData, dataIndex: number, rowIndex: number) => {
    const row = props.settingRows[rowIndex];
    if (!props.onChange || newSettingData.disabled || !row) {
      return;
    }

    const newRow: SettingRow = {
      data: { ...row.data },
    };
    newRow.data[dataIndex] = newSettingData;

    props.onChange(rowIndex, newRow as ChangeableSettingRow);
  };

  const toggleCheckbox = (settingData: SettingCheckboxData, dataIndex: number, rowIndex: number) => {
    const newData: SettingData = {
      ...settingData,
      value: !settingData.value,
    };

    replaceDataValue(newData, dataIndex, rowIndex);
  };

  const setToggleValue = (isSelected: boolean, settingsData: SettingToggleData, dataIndex: number, rowIndex: number) => {
    const newData: SettingData = {
      ...settingsData,
      value: isSelected,
    };

    replaceDataValue(newData, dataIndex, rowIndex);
  };

  const toggleVisibility = (settingData: SettingVisibilityData, dataIndex: number, rowIndex: number) => {
    const newData = {
      ...settingData,
      value: !settingData.value,
    };

    replaceDataValue(newData, dataIndex, rowIndex);
  };

  const changeDropdownValue = (settingData: SettingDropdownData, newValue: string, dataIndex: number, rowIndex: number) => {
    const newData = {
      ...settingData,
      value: newValue,
    };

    replaceDataValue(newData, dataIndex, rowIndex);
  };

  const changeInputValue = (settingData: SettingInputData, newValue: string, dataIndex: number, rowIndex: number) => {
    const newData = {
      ...settingData,
      value: newValue,
    };

    replaceDataValue(newData, dataIndex, rowIndex);
  };

  return (
    <form onSubmit={onFormSubmit}>
      <table
        css={tableStyle({ theme, fixedLayout: areColumnsWidthsSet })}
        className={props.className}
      >
        {props.settingColumns && props.settingColumns?.length > 0 && (
          <thead>
            <tr css={tableHeaderStyle({ theme })}>
              {props.settingColumns.map((item, index) => (
                <th
                  key={index}
                  css={tableHeaderCellStyle({ align: item.align, width: item.width })}
                >
                  {item.caption}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {props.settingRows.map((settingRow, rowIndex) => (
            <tr
              key={rowIndex}
              css={props.customDataRowStyle ?
                props.customDataRowStyle({ theme }) :
                tableDataRowStyle({ theme })}
            >
              {settingRow.data.map((settingData, dataIndex) => {
                const isCellDisabled = settingData.disabled !== null;

                return (
                  <td
                    key={dataIndex}
                    css={props.customDataCellStyle ?
                      props.customDataCellStyle({ theme, isCentered: settingData.isCentered, isDisabled: isCellDisabled }) :
                      tableDataCellStyle({ theme, isCentered: settingData.isCentered, isDisabled: isCellDisabled })}
                  >
                    <TooltipDeprComponent
                      behavior={TooltipBehavior.ShowOnHover}
                      css={fullWidthStyle}
                      tooltipContent={settingData.disabled}
                    >
                      <>
                        {settingData.type === SettingType.Text && (
                          <div css={textSettingDataStyle({ overflowEllipsis: areColumnsWidthsSet })}>
                            {settingData.value}
                          </div>
                        )}

                        {settingData.type === SettingType.Dropdown && (
                          <RegularDropdownComponent
                            options={settingData.options}
                            value={settingData.value}
                            placeholder={settingData.placeholder}
                            onChange={(value) => changeDropdownValue(settingData, value, dataIndex, rowIndex)}
                            triggerStyle={dropdownTriggerStyle({ theme })}
                            listStyle={dropdownListStyle({ theme })}
                            itemStyle={dropdownItemStyle}
                            inPortal
                            isDisabled={isCellDisabled}
                          />
                        )}

                        {settingData.type === SettingType.Toggle && (
                          <ToggleComponent
                            onChange={(isSelected) => setToggleValue(isSelected, settingData, dataIndex, rowIndex)}
                            isOn={settingData.value}
                            onLabel={settingData.onLabel}
                            offLabel={settingData.offLabel}
                            css={toggleStyle}
                            isDisabled={isCellDisabled}
                          />
                        )}

                        {settingData.type === SettingType.Visibility && (
                          <button
                            css={togglePublicButtonStyle}
                            onClick={() => toggleVisibility(settingData, dataIndex, rowIndex)}
                            disabled={isCellDisabled}
                          >
                            {settingData.value ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                css={visibilityIconStyle({ theme, isDisabled: isCellDisabled })}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                css={visibilityIconStyle({ theme, isDisabled: isCellDisabled })}
                              />
                            )}
                          </button>
                        )}

                        {settingData.type === SettingType.Checkbox && (
                          <CheckboxComponent
                            checkedSetter={() => toggleCheckbox(settingData, dataIndex, rowIndex)}
                            isChecked={settingData.value}
                            css={checkboxStyle}
                            checkmarkStyle={checkmarkStyle}
                            isDisabled={isCellDisabled}
                          />
                        )}

                        {settingData.type === SettingType.Input && (
                          <div css={inputDataWrapperStyle}>
                            <TextInputComponent
                              value={settingData.value}
                              onChange={value => changeInputValue(settingData, value, dataIndex, rowIndex)}
                              icon={null}
                            />
                          </div>
                        )}
                        {settingData.type === SettingType.Button &&
                        (
                          <ButtonComponent
                            isSubmit
                            text={settingData.buttonText}
                            isDisabled={isCellDisabled}
                            onClick={() => settingData.callback(rowIndex)}
                          />
                        )}
                      </>
                    </TooltipDeprComponent>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <FormSubmitEnablerComponent />
    </form>
  );
};
