import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.LocationPin3D]: {
  views: {
    default: {
      canvasHeight: 1080,
      canvasWidth: 1080,
      iconWidth: 767,
      iconHeight: 1070,
    },
  },
  colors: {
    pin: '#000000',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 90] as const,
  },
  getJson: () => import(
    /* webpackChunkName: "animations/101010-3d-location-pin" */
    './101010-3d-location-pin.json'
  ),
} };

export const { LocationPin3D } = config;
