import {
  type FC, useCallback, useMemo,
} from 'react';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsProximityExportAllowed } from '~/proximity/useIsProximityExportAllowed';
import { useProximityToolSelector } from '~/store/frontendState/proximityTool/proximityTool.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useProximities } from '../useProximities';
import { ProximityPanelComponent } from './proximityPanel.component';

export const ProximityPanelContainer: FC = () => {
  const isMapPresentational = useIsMapPresentationalSelector();
  const isExportAllowed = useIsProximityExportAllowed();
  const { proximities: proximityList } = useProximities();
  const { type: proximityType } = useProximityToolSelector();
  const { openModal: openExportModal } = useModal(ModalType.ExportProximityData);
  const { openModal: openProximitySettingsModal } = useModal(ModalType.ProximitySettings);

  const onExportFile = useMemo(() => (
    isExportAllowed ? () => {
      openExportModal({
        selectedProximityIds: [],
        showSelectProximitiesSection: true,
      });
    } : undefined
  ), [isExportAllowed, openExportModal]);

  const onSettingsButtonClick = useCallback(() => {
    openProximitySettingsModal();
  }, [openProximitySettingsModal]);

  return (
    <ProximityPanelComponent
      onExportFile={onExportFile}
      proximityList={proximityList}
      onSettingsButtonClick={onSettingsButtonClick}
      showAdminControls={!isMapPresentational}
      proximityType={proximityType}
    />
  );
};
