import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { useMapSettingsDataSelector } from '~/map/map/useMapSettings.hook';
import { type MapSettingsDataState } from '~/store/mapSettings/data/mapSettingsData.state';

export type FilterTreeMapSettingsParams = {
  columnsFilter: MapSettingsDataState['toolsState']['columnsFilter'];
  filtering: MapSettingsDataState['filtering'];
  grouping: MapSettingsDataState['grouping'];
  search: MapSettingsDataState['search'];
  searchItems: MapSettingsDataState['toolsState']['searchItems'];
};

export const useFilterTreeMapSettingsParams = (): FilterTreeMapSettingsParams => {
  const mapSettings = useMapSettingsDataSelector();

  return getFilterTreeParamsFromMapSettings(mapSettings);
};

export const getFilterTreeParamsFromMapSettings = (mapSettings: MapSettingsDataState) =>
  getFilterTreeParamsMemoized(
    mapSettings.toolsState.columnsFilter,
    mapSettings.filtering,
    mapSettings.grouping,
    mapSettings.search,
    mapSettings.toolsState.searchItems
  );

const getFilterTreeParamsMemoized = memoizeOne((
  columnsFilter: FilterTreeMapSettingsParams['columnsFilter'],
  filtering: FilterTreeMapSettingsParams['filtering'],
  grouping: FilterTreeMapSettingsParams['grouping'],
  search: FilterTreeMapSettingsParams['search'],
  searchItems: FilterTreeMapSettingsParams['searchItems']
) => {
  return {
    columnsFilter,
    filtering,
    grouping,
    search,
    searchItems,
  };
});
