import { css } from '@emotion/react';
import {
  faCheck,
  faLockAlt,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { TwoFAConfirmationComponent } from '~/_shared/components/accountSettings/modalDialogs/components/TwoFAConfirmation.component';
import { EmailSentAnimatedTextComponent } from '~/_shared/components/emailSent/emailSentAnimatedText.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { autoFocus } from '~/_shared/utils/form/form.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { type AutoClosableModalProps } from '~/modal/modalType.enum';

const buttonStyle = css({
  padding: '10px 12px',
});

const contentWrapperStyle = css({
  padding: '17px 33px',
});

const MFAStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const emailWithMfaSentStyle = (theme: Theme) => css({
  alignItems: 'center',
  color: theme.textColors.success,
  display: 'flex',
  gap: 8,
});

const authStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginTop: 16,
});

const alertStyle = css({
  alignItems: 'center',
  display: 'flex',
  marginTop: 10,
  minHeight: 24,
});

export type AuthConfirmationErrorArgs = Readonly<{
  mfaMessage?: string;
  required2FAMode?: TwoFactorMode;
  wrongMFACode: boolean;
  wrongPassword: boolean;
}>;

type ConfirmAuthenticatedActionArgs = Readonly<{
  mfaCode?: string;
  onError: (args: AuthConfirmationErrorArgs) => void;
  password: string;
}>;

export type ConfirmationWithAuthenticationModalProps = AutoClosableModalProps<{
  content: string | ReactNode;
  isSubmitDestructive: boolean;
  submitCaption: string;
  title: string;

  onSubmit: (args: ConfirmAuthenticatedActionArgs) => void;
}>;

export const ConfirmationWithAuthenticationModalComponent: React.FC<ConfirmationWithAuthenticationModalProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [mfaMode, setMfaMode] = useState<TwoFactorMode | undefined>(undefined);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [mfaMessage, setMFAMessage] = useState('');
  const [mfaError, setMFAError] = useState('');
  const [t] = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (!password) {
      setPasswordError(t('This field is required'));
    }
    else {
      setPasswordError('');
    }
  }, [password, t]);

  const handleAuthError = useCallback((args: AuthConfirmationErrorArgs) => {
    setIsLoading(false);
    if (args.required2FAMode || !args.wrongMFACode) { // Only update the mode if it explicitly changed or is not needed at all
      setMfaMode(args.required2FAMode);
      if (args.required2FAMode === TwoFactorMode.email) {
        setMFAMessage(t('A 2FA code was sent to your email address'));
      }
      else if (args.required2FAMode === TwoFactorMode.google) {
        setMFAMessage(t('Please enter the one-time code found in your Google Authenticator'));
      }
    }

    if (args.wrongPassword) {
      setPasswordError(t('Wrong password'));
    }

    if (args.wrongMFACode) {
      setMFAError(t('Invalid 2FA code. Please check and try again.'));
    }
  }, [t]);

  const onSubmit = useCallback((mfaCode?: string) => {
    setIsLoading(true);
    const submit = props.onSubmit;

    if (password) {
      submit({
        mfaCode,
        onError: handleAuthError,
        password,
      });
    }
  }, [handleAuthError, password, props.onSubmit]);

  return (
    <ModalComponent
      {...props}
      additionalContent={isLoading ? <OverlayLoaderComponent /> : null}
      caption={props.title}
      confirmButton={mfaMode ? undefined : (
        <ButtonComponent
          buttonStyle={props.isSubmitDestructive ? ButtonStyle.Danger : ButtonStyle.Primary}
          css={buttonStyle}
          isDisabled={!password}
          onClick={() => onSubmit()}
          prefixIcon={faCheck}
          text={props.submitCaption}
        />
      )}
      contentStyle={contentWrapperStyle}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          css={buttonStyle}
          onClick={props.onClose}
          prefixIcon={faXmark}
          text={t('Back')}
        />
      )}
    >

      {props.content}

      <div css={authStyle}>
        {!mfaMode && (
          <FormTextInputComponent
            autoComplete="current-password"
            errorMessages={[passwordError]}
            icon={faLockAlt}
            label={t('Current Password')}
            labelTooltip={t('To delete your data please confirm your current password. This way we can provide you with the most security.')}
            marginBottom={24}
            marginTop={16}
            onChange={setPassword}
            type="password"
            value={password}
          />
        )}

        {mfaMode && (
          <div css={MFAStyle}>
            {mfaMessage && (
              <div css={emailWithMfaSentStyle(theme)}>
                {mfaMode === TwoFactorMode.email && (
                  <EmailSentAnimatedTextComponent
                    onResend={() => onSubmit('')}
                    playAnimation={isLoading}
                    text={mfaMessage}
                  />
                )}
              </div>
            )}
            <div>
              <TwoFAConfirmationComponent
                onSubmit={onSubmit}
                setInputRef={autoFocus}
              />
              {mfaError && (
                <AlertComponent
                  css={alertStyle}
                  type="danger"
                >
                  {mfaError}
                </AlertComponent>
              )}
            </div>
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

ConfirmationWithAuthenticationModalComponent.displayName = 'ConfirmationWithAuthenticationModalComponent';
