import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { useTranslation } from '../../../_shared/utils/hooks';
import { MapBasicInfoComponent } from '../../basicInfo/mapBasicInfo.component';

const wrapperStyle = css({
  padding: 16,
});

type SaveMapInfoProps = Readonly<{
  errors?: { readonly [key: string]: ReadonlyArray<string> };
  isMapLayered?: boolean;
  isSnapshot: boolean;
  mapDescription: string | null;
  mapName: string;

  onDescriptionUpdate: (description: string) => void;
  onNameUpdate: (name: string) => void;
}>;

export const SaveMapInfoComponent: FC<SaveMapInfoProps> = (props) => {
  const [name, setName] = useState(props.mapName);
  const [description, setDescription] = useState<null | string>(props.mapDescription);
  const [t] = useTranslation();

  const updateMapName = () => {
    props.onNameUpdate(name);
  };

  const updateMapDescription = () => {
    if (description === null) {
      return;
    }

    props.onDescriptionUpdate(description);
  };

  useEffect(() => {
    setName(props.mapName);
  }, [props.mapName]);

  useEffect(() => {
    setDescription(props.mapDescription);
  }, [props.mapDescription]);

  return (
    <div css={wrapperStyle}>
      <MapBasicInfoComponent
        description={description}
        descriptionErrorMessage={props.errors?.['description']?.[0]}
        isMapLayered={props.isMapLayered}
        isSnapshot={props.isSnapshot}
        name={name}
        nameErrorMessage={props.errors?.['name']}
        namePlaceholder={t('Map Name')}
        onDescriptionBlur={updateMapDescription}
        onDescriptionChange={setDescription}
        onNameBlur={updateMapName}
        onNameChange={setName}
      />
    </div>
  );
};
