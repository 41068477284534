import {
  type FC, useCallback, useMemo,
} from 'react';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { emptySpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  type ActiveGroupFilters,
  type GroupingColumn,
  type GroupingColumnValues,
  GroupingType, type UniqueGroup,
} from '../../_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '../../_shared/types/grouping/numericActiveGroupColumnType.helpers';
import { type SpreadsheetColumnId } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { CustomizationLevel } from '../../customizeMarkerPopup/customizeLabelAndMarker.types';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { usePerGroupVisualSettings } from '../../store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import { useMatchupDataSelector } from '../../store/matchupData/matchupData.selectors';
import { getGroupingColumnsUniqueGroups } from '../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '../../store/spreadsheetData/spreadsheetData.selectors';
import { GroupingItemBody } from './groupingItemBody.component';

type GroupingItemBodyContainerProps = Readonly<{
  activeFilters: ActiveGroupFilters;
  currentGroupColumn: GroupingColumn;
  isEditingDisabled: boolean;
  isFilteringDisabled?: boolean;

  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, groups: GroupingColumnValues<1>) => void;
}>;

export const GroupingItemBodyContainer: FC<GroupingItemBodyContainerProps> = props => {
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const perGroupVisualSettings = usePerGroupVisualSettings();

  const { openModal: openMarkerEdit } = useModal(ModalType.LabelAndMarkerSettings);

  const uniqueGroupColumns = useMemo(() => {
    const groups = getGroupingColumnsUniqueGroups(
      spreadsheetData ?? emptySpreadsheetData,
      matchupData,
      props.currentGroupColumn
    );

    // Base_maps groups are sorted by map id. Map name is only added to be displayed in the UI.
    if (props.currentGroupColumn.columnId === BASE_MAPS_COLUMN_ID) {
      // Sort the groups by the map name.
      return groups.sort((a, b) => a.name.localeCompare(b.name));
    }

    return groups;
  }, [matchupData, props.currentGroupColumn, spreadsheetData]);

  const numericActiveGroupColumnType = useMemo(() => (
    getNumericActiveGroupColumnType(activeGroupColumns)
  ), [activeGroupColumns]);

  const activeColumnVisualSettings = useMemo(() =>
    perGroupVisualSettings[props.currentGroupColumn.spreadsheetId]?.[props.currentGroupColumn.columnId],
  [perGroupVisualSettings, props.currentGroupColumn.columnId, props.currentGroupColumn.spreadsheetId]
  );

  const hierarchyIndicator = useMemo(() => activeGroupColumns.findIndex(
    column => column.spreadsheetId === props.currentGroupColumn.spreadsheetId
    && column.columnId === props.currentGroupColumn.columnId
  ), [activeGroupColumns, props.currentGroupColumn.columnId, props.currentGroupColumn.spreadsheetId]);

  const handleEditMarker = useCallback((group: UniqueGroup) => {
    if (props.currentGroupColumn.type === GroupingType.Text) {
      openMarkerEdit({
        customizationLevel: CustomizationLevel.Group,
        groupInfo: {
          groupId: group.id as string,
          hierarchyIndicator,
          groupingColumn: props.currentGroupColumn,
        },
        markerSettingsTypes: [MarkerSettingType.Marker],
      });
    }
    else {
      openMarkerEdit({
        customizationLevel: CustomizationLevel.Group,
        groupInfo: {
          bucketId: group.id as number,
          groupingColumn: props.currentGroupColumn,
          hierarchyIndicator,
        },
        markerSettingsTypes: [MarkerSettingType.Marker],
      });
    }
  }, [hierarchyIndicator, openMarkerEdit, props.currentGroupColumn]);

  return (
    <GroupingItemBody
      {...props}
      areMarkersEditable={!props.isEditingDisabled}
      isFilteringDisabled={!!props.isFilteringDisabled}
      groupMarkers={activeColumnVisualSettings}
      hierarchyIndicator={hierarchyIndicator}
      numericActiveGroupColumnType={numericActiveGroupColumnType}
      onMarkerEdit={handleEditMarker}
      uniqueGroupColumns={uniqueGroupColumns}
    />
  );
};
