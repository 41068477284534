import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  MAP_SETTINGS_BOUNDARIES_SET_ISOLATE_COLOR,
  MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID,
} from './mapSettingsBoundaries.actionTypes';

export const mapSettingsBoundariesSetPrimaryBoundaryGroupId = (primaryBoundaryGroupId: BoundaryGroupId | null) => ({
  type: MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID,
  payload: {
    primaryBoundaryGroupId,
  },
}) as const;

export const mapSettingsBoundariesSetIsolateColor = (color: string) => ({
  type: MAP_SETTINGS_BOUNDARIES_SET_ISOLATE_COLOR,
  payload: {
    color,
  },
}) as const;
