import { css } from '@emotion/react';

export const v4MapImportDialogLayout = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  paddingInline: 6,

  '& h1, & p': {
    margin: 0,
  },
});
