import { css } from '@emotion/react';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { DATA_ROUTE } from '~/_shared/constants/routes';
import { STORED_GOOGLE_MAPTIVE_SYNC_METHOD } from '~/_shared/hooks/appLoad/useGoogleOauthAppLoadResolver.hook';
import { type GoogleSheetBasedRealSpreadsheetInfo } from '~/_shared/types/map';
import {
  checkIsColumnNameSame, filterAddedSpreadsheetColumns,
} from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import {
  areGoogleUserScopesSufficient,
  determineRequiredMaptiveScopes,
  GoogleAPIOperation,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { GoogleSheetMapSyncType } from '~/map/map.repository';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { usePrimarySpreadsheetRealSpreadsheets } from '~/store/selectors/usePrimarySpreadsheetRealSpreadsheets';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { useGoogleApiOauthUserDataSelector } from '~/store/userData/userData.selectors';
import { useStorageService } from '../../_shared/utils/storageService';
import { AppErrorType } from '../../appError/appErrorType.enum';
import {
  getMaptiveGoogleSheetsColumnsMatchesFromMatchupAndAdded,
  getSpreadsheetSyncDuplicates,
  hasSpreadsheetSyncMatchingErrors,
  type MaptiveToGoogleSheetColumnInfo,
  processTwoWaySyncIntersectionsResponse,
  type SyncDuplicateRow,
} from '../../spreadsheet/googleSpreadsheet/googleSheet.helpers';
import {
  convertMaptiveGoogleSheetsColumnsMatchesClientToServer,
  GOOGLE_AUTH_ERROR,
  GOOGLE_AUTH_ERROR_2,
  GOOGLE_SPREADSHEET_NOT_FOUND,
  guessMaptiveAndGoogleSpreadsheetIntersections,
  importGoogleSpreadsheetData,
  invokeGoogleOauth,
  type MaptiveGoogleSheetColumnsAndMatchupData,
  type MaptiveGoogleSpreadsheetSyncInfo,
  syncMaptiveGoogleSheetsTwoWay, type SyncSpreadsheetResponse,
} from '../../spreadsheet/googleSpreadsheet/googleSheet.repository';
import { useGetMaptiveGoogleSpreadsheetMatches } from '../../spreadsheet/googleSpreadsheet/googleSpreadsheet.hooks';
import { type ReplaceDataMatchup } from '../replaceData/replaceDataMatchupModal/replaceDataMatchupModal.component';
import { useGetGoogleSheetDataAsColumnsWithValues } from './dataSource/useGetGoogleSheetDataAsColumnsWithValues.hook';
import { useGetSpreadsheetDataAsColumnsWithValues } from './dataSource/useGetSpreadsheetDataAsColumnsWithValues.hook';
import { useHandleDataSourceErrors } from './dataSource/useHandleDataSourceErrors.hook';
import { ResolveTwoWaySyncFailedOption } from './resolveUnableToTwoWaySyncModal.component';
import { SyncDataContainer } from './syncData.container';
import { useResolveGoogleToMaptiveSyncError } from './useResolveGoogleToMaptiveSyncError.hook';

type GoogleSheetBasedRealSpreadsheetInfoWithAccount = GoogleSheetBasedRealSpreadsheetInfo & {
  googleAccountId: string;
};

const duplicatesPromptContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
});

const getInitialSyncMethod = (props: {
  googleSheetMatchesData: MaptiveGoogleSheetColumnsAndMatchupData | null;
  googleSheetSpreadsheetInfo: GoogleSheetBasedRealSpreadsheetInfoWithAccount;
  preselectedSyncMethod: GoogleSheetMapSyncType | undefined;
  realSpreadsheetId: number | undefined;
}) => {
  const matchesDataForRealSpreadsheet = props.realSpreadsheetId ? props.googleSheetMatchesData?.[props.realSpreadsheetId] : null;
  if (props.preselectedSyncMethod) {
    return props.preselectedSyncMethod;
  }
  if (matchesDataForRealSpreadsheet) {
    return matchesDataForRealSpreadsheet.lastSyncType;
  }
  return props.googleSheetSpreadsheetInfo.lastSyncType;
};

export type SyncDataModalForSyncingContainerProps = Readonly<{
  preselectedSyncMethod?: GoogleSheetMapSyncType;
  googleSheetSpreadsheetInfo: GoogleSheetBasedRealSpreadsheetInfoWithAccount;
  skipSyncSelectStep?: boolean;
  forceUniqueIndexSelection?: boolean;
}>;

export const SyncDataModalForSyncingContainer: FC<ModalProps<SyncDataModalForSyncingContainerProps>> = props => {
  const { onClose, preselectedSyncMethod, googleSheetSpreadsheetInfo } = props;

  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const dataRouteWithMapId = DATA_ROUTE.replace(':mapId', mapId?.toString() || '');
  const maptiveDataSource = useGetSpreadsheetDataAsColumnsWithValues();
  const googleUserScopes = useGoogleApiOauthUserDataSelector()?.scopes;
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const realSpreadsheetId = usePrimarySpreadsheetRealSpreadsheets()?.[0]?.id;
  const { handleOneWayGoogleSheetError } = useResolveGoogleToMaptiveSyncError();
  const { isLoading: isLoadingMatches, data: googleSheetMatchesData, error: googleSheetMatchesError } = useGetMaptiveGoogleSpreadsheetMatches(clientId, primarySpreadsheetId);
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { replaceCurrentModal: reopenSyncDataModal } = useModal(ModalType.SyncGoogleSheet);
  const { replaceCurrentModal: closeCurrentAndOpenResolveUnableToTwoWaySyncModal } = useModal(ModalType.ResolveUnableToTwoWaySync);
  const { replaceCurrentModal: closeCurrentAndOpenMapMaptiveToGoogleColumnsModal } = useModal(ModalType.ReplaceSpreadsheetDataMatchup);
  const storageService = useStorageService();

  const googleSheetDataSourceParams = useMemo(() => ({
    spreadsheetId: googleSheetSpreadsheetInfo.googleSpreadsheetId,
    sheetId: googleSheetSpreadsheetInfo.googleSheetId,
  }), [googleSheetSpreadsheetInfo]);

  const initialSyncMethod = getInitialSyncMethod({
    googleSheetMatchesData, googleSheetSpreadsheetInfo, preselectedSyncMethod, realSpreadsheetId,
  });

  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);
  const [lastSelectedSyncMethod, setLastSelectedSyncMethod] = useState<GoogleSheetMapSyncType | null>(initialSyncMethod || null);

  const googleSheetServerDataSource = useGetGoogleSheetDataAsColumnsWithValues(googleSheetDataSourceParams);

  const storeDataAndRedirectToGoogleOauth = useCallback((syncMethod: GoogleSheetMapSyncType | null) => {
    if (!syncMethod) {
      syncMethod = GoogleSheetMapSyncType.GoogleToMaptive;
    }
    storageService.setLocalStorageItem(STORED_GOOGLE_MAPTIVE_SYNC_METHOD, syncMethod);
    invokeGoogleOauth({
      operation: GoogleAPIOperation.SYNC_SPREADSHEETS,
      scopes: determineRequiredMaptiveScopes(syncMethod),
      callbackUrlSuffix: dataRouteWithMapId,
    });
  }, [storageService, dataRouteWithMapId]);

  const onGoogleAuthFailedDataSourceFetchError = useCallback(() => {
    storeDataAndRedirectToGoogleOauth(lastSelectedSyncMethod);
  }, [lastSelectedSyncMethod, storeDataAndRedirectToGoogleOauth]);

  useHandleDataSourceErrors(maptiveDataSource, onGoogleAuthFailedDataSourceFetchError);
  useHandleDataSourceErrors(googleSheetServerDataSource, onGoogleAuthFailedDataSourceFetchError);

  const submitSyncButtonProps = useMemo(() => ({
    prefixIcon: faSync,
    text: t('Sync'),
  }), [t]);

  const showDuplicatesResolutionModal = useCallback((
    duplicates: SyncDuplicateRow[],
    spreadsheet: 'Google' | 'Maptive',
    onProceed: () => void,
    proceedButtonText: string,
    proceedResolutionText: string,
  ) => {
    openConfirmationModal({
      confirmCaption: proceedButtonText,
      onCancel: closeConfirmationModal,
      onConfirm: onProceed,
      text: (
        <div css={duplicatesPromptContentStyles}>
          <span>{t('googleSheets.sync.duplicates.description', { maptiveOrGoogle: spreadsheet })}</span>
          <ul>
            {duplicates.map((item) => (
              <li key={item.id}>{item.id}</li>
            ))}
          </ul>
          {proceedResolutionText && (
            <span>{proceedResolutionText}</span>
          )}
        </div>
      ),
      title: t('googleSheets.sync.duplicates.caption'),
    });
  }, [closeConfirmationModal, openConfirmationModal, t]);

  const handleUnableToFetchMatchingError = useCallback((e: ApiError) => {
    if (e.message === GOOGLE_AUTH_ERROR) {
      storeDataAndRedirectToGoogleOauth(null);
      return;
    }
  }, [storeDataAndRedirectToGoogleOauth]);

  const handleGoogleToMaptiveSyncError = useCallback((e: ApiError) => {
    const handleAuthError = () => {
      storeDataAndRedirectToGoogleOauth(GoogleSheetMapSyncType.GoogleToMaptive);
    };

    const handleGeneralError = () => {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('Unable to Synchronize Google Sheet Data'),
        content: t('googleSheets.sync.unableToSync'),
      }));
    };

    const handleConflictResolved = () => {
      reopenSyncDataModal({
        forceUniqueIndexSelection: true,
        googleSheetSpreadsheetInfo,
        preselectedSyncMethod: GoogleSheetMapSyncType.GoogleToMaptive,
        skipSyncSelectStep: true,
      });
    };

    handleOneWayGoogleSheetError(e, handleConflictResolved, handleAuthError, handleGeneralError);

  }, [dispatch, handleOneWayGoogleSheetError, reopenSyncDataModal, storeDataAndRedirectToGoogleOauth, googleSheetSpreadsheetInfo, t]);

  const handleTwoWaySyncError = useCallback((e: ApiError, onSyncData: (syncMethod: GoogleSheetMapSyncType, identifierIds?: number[]) => void) => {
    if (e.message === GOOGLE_AUTH_ERROR_2) {
      storeDataAndRedirectToGoogleOauth(GoogleSheetMapSyncType.TwoWaySync);
      return;
    }
    else if (e.message === GOOGLE_SPREADSHEET_NOT_FOUND) {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('googleSheets.googleSpreadsheetNotFound.caption'),
        content: t('googleSheets.googleSpreadsheetNotFound.message{{SPREADSHEET_ID}}', { SPREADSHEET_ID: googleSheetSpreadsheetInfo.googleSpreadsheetId }),
      }));
      return;
    }

    if (e.response && realSpreadsheetId) {
      const response = e.response as SyncSpreadsheetResponse;
      if (hasSpreadsheetSyncMatchingErrors(response, realSpreadsheetId)) {
        closeCurrentAndOpenResolveUnableToTwoWaySyncModal({
          onSync: (option) => {
            if (option === ResolveTwoWaySyncFailedOption.TryAgain) {
              onSyncData(GoogleSheetMapSyncType.TwoWaySync);
            }
            if (option === ResolveTwoWaySyncFailedOption.GoogleToMaptiveSync) {
              onSyncData(GoogleSheetMapSyncType.GoogleToMaptive);
            }
          },
        });
        return;
      }

      onClose();
      const duplicates = getSpreadsheetSyncDuplicates(response, realSpreadsheetId);

      if (duplicates.maptive.length) {
        showDuplicatesResolutionModal(
          duplicates.maptive,
          'Maptive',
          () => {
            closeConfirmationModal();
          },
          t('OK'),
          t('googleSheets.sync.duplicates.resolution.dataPage'),
        );
        return;
      }

      if (duplicates.google.length) {
        showDuplicatesResolutionModal(
          duplicates.google,
          'Google',
          () => {
            closeConfirmationModal();
            onSyncData(GoogleSheetMapSyncType.TwoWaySync);
          },
          t('Retry'),
          t('googleSheets.sync.duplicates.resolution.googleSheet'),
        );
        return;
      }
    }

    dispatch(createAppError({
      type: AppErrorType.General,
      title: t('Unable to Synchronize Google Sheet Data'),
      content: t('googleSheets.sync.unableToSync'),
    }));
  }, [closeConfirmationModal, closeCurrentAndOpenResolveUnableToTwoWaySyncModal, dispatch, onClose,
    realSpreadsheetId, showDuplicatesResolutionModal, storeDataAndRedirectToGoogleOauth, t, googleSheetSpreadsheetInfo,
  ]);

  const isForceGoogleToMaptiveSyncMode = useMemo(() => (
    lastSelectedSyncMethod === GoogleSheetMapSyncType.GoogleToMaptive && props.forceUniqueIndexSelection
  ), [props.forceUniqueIndexSelection, lastSelectedSyncMethod]);

  const initialUniqueIdentifierIndexes = useMemo(() => {
    if (!isForceGoogleToMaptiveSyncMode && !isLoadingMatches && googleSheetMatchesData && realSpreadsheetId) {
      const matchup = googleSheetMatchesData[realSpreadsheetId]?.matchup;
      if (matchup) {
        return Object.values(matchup.uniqueKeys.maptive);
      }
    }
    return undefined;
  }, [googleSheetMatchesData, isForceGoogleToMaptiveSyncMode, isLoadingMatches, realSpreadsheetId]);

  const isMaptiveSpreadsheetAndGoogleServerHeaderMatchingActiveMatchup = useMemo(() => {
    if (maptiveDataSource.header && googleSheetServerDataSource.header
      && !isLoadingMatches && realSpreadsheetId && googleSheetMatchesData?.[realSpreadsheetId]
    ) {
      // We don't consider system Added columns like bg_accuracy, as we deal with those separately before sync
      const maptiveDataSourceColumns = filterAddedSpreadsheetColumns(maptiveDataSource.header);
      const googleDataSourceColumns = filterAddedSpreadsheetColumns(googleSheetServerDataSource.header);
      const activeMatchupColumns = googleSheetMatchesData[realSpreadsheetId].matchup.columns;
      const activeMatchupMaptiveColumns = filterAddedSpreadsheetColumns(
        Object.entries(activeMatchupColumns.maptive).map(([id, name]) => ({ id, name }))
      );
      const activeMatchupGoogleColumns = filterAddedSpreadsheetColumns(
        Object.entries(activeMatchupColumns.google).map(([id, name]) => ({ id, name }))
      );

      if (maptiveDataSourceColumns.length === activeMatchupMaptiveColumns.length
        && googleDataSourceColumns.length === activeMatchupGoogleColumns.length
        && maptiveDataSourceColumns.length === googleDataSourceColumns.length
      ) {
        for (let i = 0; i < maptiveDataSourceColumns.length; i++) {
          if (!checkIsColumnNameSame(
            maptiveDataSourceColumns[i].name,
            activeMatchupColumns.maptive[maptiveDataSourceColumns[i].id])
          ) {
            return false;
          }
        }
        for (let i = 0; i < googleDataSourceColumns.length; i++) {
          if (!checkIsColumnNameSame(
            googleDataSourceColumns[i].name,
            activeMatchupColumns.google[googleDataSourceColumns[i].id])
          ) {
            return false;
          }
        }
        return true;
      }
    }

    return false;
  }, [googleSheetMatchesData, googleSheetServerDataSource.header, isLoadingMatches, maptiveDataSource.header, realSpreadsheetId]);

  const syncDataTwoWay = useCallback((
    onSyncData: (syncMethod: GoogleSheetMapSyncType, identifierIds?: number[]) => void,
    maptiveGoogleSpreadsheetSyncInfo: Omit<MaptiveGoogleSpreadsheetSyncInfo, 'columns'>,
    forceMapSettingsReload?: boolean,
  ) => {
    if (clientId && realSpreadsheetId && primarySpreadsheetId) {

      setIsSyncLoading(true);
      syncMaptiveGoogleSheetsTwoWay({
        client_id: clientId,
        spreadsheet_id: primarySpreadsheetId,
        settings: {
          [realSpreadsheetId]: {
            columns_matches: convertMaptiveGoogleSheetsColumnsMatchesClientToServer(maptiveGoogleSpreadsheetSyncInfo.columnsMatches),
            unique_keys: {
              maptive: maptiveGoogleSpreadsheetSyncInfo.uniqueKeys.maptive,
              google: maptiveGoogleSpreadsheetSyncInfo.uniqueKeys.google,
            },
          },
        },
      }).then(() => {
        if (forceMapSettingsReload) {
          location.reload();
        }
        else {
          onClose();
          dispatch(spreadsheetResetStateAndRefetchData());
        }
      }).catch((e: ApiError) => {
        handleTwoWaySyncError(e, onSyncData);
      }).finally(() => {
        setIsSyncLoading(false);
      });
    }
  }, [clientId, dispatch, handleTwoWaySyncError, primarySpreadsheetId, realSpreadsheetId, onClose]);

  const matchHeadersAndSyncDataTwoWay = useCallback((onSyncData: (syncMethod: GoogleSheetMapSyncType, identifierIds?: number[]) => void) => {
    if (clientId && realSpreadsheetId && primarySpreadsheetId && googleSheetMatchesData) {
      if (googleSheetSpreadsheetInfo && !isMaptiveSpreadsheetAndGoogleServerHeaderMatchingActiveMatchup) {

        const onMaptiveAndGoogleSpreadsheetStructureIrreconcilable = () => {
          closeCurrentAndOpenResolveUnableToTwoWaySyncModal({
            onSync: (option) => {
              if (option === ResolveTwoWaySyncFailedOption.TryAgain) {
                onSyncData(GoogleSheetMapSyncType.TwoWaySync);
              }
              if (option === ResolveTwoWaySyncFailedOption.GoogleToMaptiveSync) {
                onSyncData(GoogleSheetMapSyncType.GoogleToMaptive);
              }
            },
          });
        };

        const promptUserToMatchColumnsAndProceedToSync = (columnsInfo: MaptiveToGoogleSheetColumnInfo) => {
          closeCurrentAndOpenMapMaptiveToGoogleColumnsModal({
            initialMatchup: columnsInfo.intersects.reduce((acc, item) => {
              acc[item.maptive] = {
                ignore: false,
                newColumnId: item.google,
              };
              return acc;
            }, {} as ReplaceDataMatchup),
            noIgnoredColumns: true,
            newColumns: columnsInfo.googleColumnsWithoutAdded.map(item => ({ name: item.name, value: item.id })),
            newColumnsCaption: t('Google Sheet Columns'),
            oldColumns: columnsInfo.maptiveColumnsWithoutAdded.map(item => ({ name: item.name, value: item.id })),
            oldColumnsCaption: t('Maptive Columns'),
            onSubmit: (matchup: ReplaceDataMatchup) => {
              syncDataTwoWay(
                onSyncData,
                {
                  columnsMatches: getMaptiveGoogleSheetsColumnsMatchesFromMatchupAndAdded(
                    matchup, columnsInfo,
                  ),
                  uniqueKeys: {
                    maptive: googleSheetMatchesData[realSpreadsheetId].matchup.uniqueKeys.maptive,
                    google: Object.entries(googleSheetMatchesData[realSpreadsheetId].matchup.uniqueKeys.maptive).reduce((acc, [maptiveColumnId, uniqueIndex]) => {
                      acc[matchup[maptiveColumnId].newColumnId as string] = uniqueIndex;
                      return acc;
                    }, {} as { [columnId: string]: number }),
                  },
                },
                true,
              );
            },
          });
        };

        setIsSyncLoading(true);

        guessMaptiveAndGoogleSpreadsheetIntersections(
          googleSheetSpreadsheetInfo.googleAccountId,
          googleSheetSpreadsheetInfo.googleSpreadsheetId,
          googleSheetSpreadsheetInfo.googleSheetId,
          realSpreadsheetId,
        ).then((response) => {
          const processedColumnsAndIntersections = processTwoWaySyncIntersectionsResponse(response.data);
          if (processedColumnsAndIntersections.maptiveColumnsWithoutAdded.length === processedColumnsAndIntersections.googleColumnsWithoutAdded.length) {
            promptUserToMatchColumnsAndProceedToSync(processedColumnsAndIntersections);
          }
          else {
            onMaptiveAndGoogleSpreadsheetStructureIrreconcilable();
          }
        }).catch((e: ApiError) => {
          if (maptiveDataSource.header && googleSheetServerDataSource.header) {
            const processedColumnsAndIntersections = processTwoWaySyncIntersectionsResponse({
              google_columns: googleSheetServerDataSource.header.map((col) => ({ name: col.name, id: col.id })),
              maptive_columns: maptiveDataSource.header.map((col) => ({ name: col.name, id: col.id })),
              intersects: [],
            });
            if (processedColumnsAndIntersections.maptiveColumnsWithoutAdded.length === processedColumnsAndIntersections.googleColumnsWithoutAdded.length) {
              promptUserToMatchColumnsAndProceedToSync(processedColumnsAndIntersections);
            }
            else {
              onMaptiveAndGoogleSpreadsheetStructureIrreconcilable();
            }
          }
          else {
            handleTwoWaySyncError(e, onSyncData);
          }
        }).finally(() => {
          setIsSyncLoading(false);
        });
      }
      else {
        syncDataTwoWay(onSyncData, googleSheetMatchesData[realSpreadsheetId].matchup);
      }
    }
  }, [t, clientId, realSpreadsheetId, primarySpreadsheetId, googleSheetMatchesData, googleSheetSpreadsheetInfo,
    isMaptiveSpreadsheetAndGoogleServerHeaderMatchingActiveMatchup, closeCurrentAndOpenResolveUnableToTwoWaySyncModal,
    closeCurrentAndOpenMapMaptiveToGoogleColumnsModal, syncDataTwoWay, maptiveDataSource, googleSheetServerDataSource,
    handleTwoWaySyncError,
  ]);

  const syncData = useCallback((syncMethod: GoogleSheetMapSyncType, identifierIds?: number[]) => {
    if (clientId && realSpreadsheetId && primarySpreadsheetId) {

      if (syncMethod === GoogleSheetMapSyncType.TwoWaySync) {
        matchHeadersAndSyncDataTwoWay(syncData);
      }
      else if (syncMethod === GoogleSheetMapSyncType.GoogleToMaptive) {
        setIsSyncLoading(true);
        importGoogleSpreadsheetData({
          real_spreadsheet_id: realSpreadsheetId,
          ...(isForceGoogleToMaptiveSyncMode && identifierIds ? {
            force_sync: true,
            unique_keys: identifierIds,
          } : {}),
        }).then(() => {
          location.reload();
        }).catch((e: ApiError) => {
          handleGoogleToMaptiveSyncError(e);
        }).finally(() => {
          setIsSyncLoading(false);
        });
      }
    }
  }, [clientId, realSpreadsheetId, primarySpreadsheetId, matchHeadersAndSyncDataTwoWay,
    isForceGoogleToMaptiveSyncMode, handleGoogleToMaptiveSyncError,
  ]);

  const onSyncMethodDetermined = useCallback((syncMethod: GoogleSheetMapSyncType) => {
    setLastSelectedSyncMethod(syncMethod);
    const maptiveScope = determineRequiredMaptiveScopes(syncMethod);
    if (maptiveScope && (!googleUserScopes || !areGoogleUserScopesSufficient(maptiveScope, googleUserScopes))) {
      storeDataAndRedirectToGoogleOauth(syncMethod);
    }
    else {
      syncData(syncMethod);
    }
    return false;
  }, [googleUserScopes, storeDataAndRedirectToGoogleOauth, syncData]);

  const dataSource = useMemo(() => (
    isForceGoogleToMaptiveSyncMode ? googleSheetServerDataSource : maptiveDataSource
  ), [googleSheetServerDataSource, isForceGoogleToMaptiveSyncMode, maptiveDataSource]);

  const onSyncFormSubmitted = useMemo(() => {
    if (isForceGoogleToMaptiveSyncMode) {
      return syncData;
    }
    return undefined;
  }, [isForceGoogleToMaptiveSyncMode, syncData]);

  useEffect(() => {
    if (googleSheetMatchesError) {
      handleUnableToFetchMatchingError(googleSheetMatchesError);
    }
  }, [handleUnableToFetchMatchingError, googleSheetMatchesError]);

  useEffect(() => {
    const dataSourceFetch = dataSource.fetch;
    if (dataSourceFetch && !maptiveDataSource.isLoading && !maptiveDataSource.header && !maptiveDataSource.error) {
      dataSourceFetch();
    }
  }, [dataSource.fetch, maptiveDataSource.header, maptiveDataSource.error, maptiveDataSource.isLoading]);

  useEffect(() => {
    if (googleSheetServerDataSource.fetch && !googleSheetServerDataSource.isLoading && !googleSheetServerDataSource.header && !googleSheetServerDataSource.error) {
      googleSheetServerDataSource.fetch();
    }
  }, [googleSheetServerDataSource]);

  return (
    <SyncDataContainer
      {...props}
      dataSource={dataSource}
      initialSyncMethod={initialSyncMethod}
      initialUniqueIdentifierIndexes={initialUniqueIdentifierIndexes}
      isLoading={isSyncLoading || isLoadingMatches || maptiveDataSource.isLoading || googleSheetServerDataSource.isLoading}
      modalCaption={t('Sync Data')}
      submitSyncButtonProps={submitSyncButtonProps}
      syncSelectedButtonProps={submitSyncButtonProps}
      skipSyncSelectStep={props.skipSyncSelectStep}
      onSyncMethodSelected={onSyncMethodDetermined}
      onSyncFormSubmitted={onSyncFormSubmitted}
    />
  );
};
