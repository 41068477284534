import {
  type FC, useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type ModalProps } from '~/modal/modalType.enum';
import { type GoogleSheetMapSyncType } from '../../map/map.repository';
import { revokeGoogleAccessToken } from '../../spreadsheet/googleSpreadsheet/googleSheet.repository';
import { userGoogleUnauthorized } from '../../store/userData/userData.actionCreators';
import { useGoogleApiOauthUserDataSelector } from '../../store/userData/userData.selectors';
import { type DataSource } from './dataSource/dataSource';
import {
  type ButtonProps, SyncDataComponent,
} from './syncData.component';

type SyncDataContainerProps = ModalProps<{
  dataSource: DataSource;
  initialSyncMethod: GoogleSheetMapSyncType;
  initialUniqueIdentifierIndexes?: Readonly<number[]>;
  isLoading: boolean;
  modalCaption: string;
  skipSyncSelectStep?: boolean;
  submitSyncButtonProps: ButtonProps;
  syncSelectedButtonProps?: ButtonProps;

  onSyncMethodSelected?: (syncMethod: GoogleSheetMapSyncType) => boolean;
  onSyncFormSubmitted?: (syncMethod: GoogleSheetMapSyncType, identifierIds: number[]) => void;
}>;

export const SyncDataContainer: FC<SyncDataContainerProps> = (props) => {
  const dispatch = useDispatch();
  const googleOauthUserData = useGoogleApiOauthUserDataSelector();

  const [isRevokingGoogleToken, setIsRevokingGoogleToken] = useState<boolean>(false);

  const logoutGoogleUser = useCallback(() => {
    if (googleOauthUserData?.googleAccId) {
      setIsRevokingGoogleToken(true);
      revokeGoogleAccessToken(googleOauthUserData.googleAccId)
        .then(() => {
          dispatch(userGoogleUnauthorized());
        })
        .finally(() => {
          setIsRevokingGoogleToken(false);
        });
    }
  }, [dispatch, googleOauthUserData?.googleAccId]);

  return (
    <SyncDataComponent
      {...props}
      isLoading={props.isLoading || isRevokingGoogleToken}
      googleAccount={googleOauthUserData?.email}
      onGoogleLogout={logoutGoogleUser}
    />
  );
};
