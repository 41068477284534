import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type FocusEvent, type ReactNode, useState,
} from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import { createUuid } from '../../../utils/createUuid';
import {
  InputSize,
  type InputType, TextInputComponent,
} from '../index';
import {
  inputStyle, labelStyles,
} from './textInputAuth.styles';

export type TextInputAuthProps = Readonly<{
  autoComplete?: string;
  hasError?: boolean;
  icon?: IconProp;
  isDisabled?: boolean;
  label?: ReactNode;
  placeholder: string;
  type?: InputType;
  value: string;
  name?: string;

  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
}>;

export const TextInputAuthComponent: FC<TextInputAuthProps> = (props: TextInputAuthProps) => {
  const [id] = useState(createUuid());
  const theme = useTheme();

  return (
    <>
      {props.label && (
        <label
          css={[labelStyles({ theme })]}
          htmlFor={id}
        >
          {props.label}
        </label>
      )}

      <TextInputComponent
        css={inputStyle({ theme, hasError: !!props.hasError })}
        size={InputSize.Large}
        type={props.type}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        id={id}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
        icon={props.icon}
      />
    </>
  );
};
