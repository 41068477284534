import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { type ApiError } from '../../../_shared/utils/api/apiError.helpers';
import {
  mapSnapshotRemoveSuccess, mapSnapshotUpdateSuccessful,
} from '../../../store/mapInfo/mapInfo.actionCreators';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import {
  createMapSnapshot,
  type CreateMapSnapshotRequest,
  deleteMapSnapshot,
  type MapSnapshotUpdateModel,
  updateMapSnapshot,
} from './saveMapView.repository';
import { SaveMapViewFieldName } from './saveMapViewFieldName.enum';

export type FieldErrors = ReadonlyMap<SaveMapViewFieldName, ReadonlyArray<string>>;

const printUnknownFieldErrorsMessageIfAny = (apiError: ApiError) => {
  const unknownErrors = Object.values(SaveMapViewFieldName)
    .reduce((errors, fieldName) => {
      delete errors[fieldName];
      return errors;
    }, { ...(apiError.errors ?? {}) });

  if (Object.values(unknownErrors).length > 0) {
    console.error('saveMapView: got ApiError with errors for unknown fields, unknown field errors: ', unknownErrors);
  }
};

const parseFieldErrors = (apiError: ApiError): ReadonlyMap<SaveMapViewFieldName, ReadonlyArray<string>> => {
  const knownEntries = Object.values(SaveMapViewFieldName)
    .filter(field => !!apiError.errors?.[field])
    .map(field => [field, apiError.errors?.[field] ?? []] as const);

  printUnknownFieldErrorsMessageIfAny(apiError);

  return new Map(knownEntries);
};

export const useCreateMapSnapshot = () => {
  const [isCreating, setIsCreating] = useState(false);
  const clientId = useClientIdSelector();

  const createSnapshot = (mapId: number, data: CreateMapSnapshotRequest) => {
    if (clientId === null) {
      throw new Error(`Cannot create snapshot when clientId (${clientId}) or mapId (${mapId}) is null.`);
    }

    setIsCreating(true);

    return createMapSnapshot(clientId, mapId, data)
      .catch((apiError: ApiError) => Promise.reject(parseFieldErrors(apiError)))
      .finally(() => setIsCreating(false));
  };

  return {
    isCreating,
    createSnapshot,
  };
};

export const useUpdateMapSnapshot = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();

  const updateSnapshot = (parentMapId: number, data: MapSnapshotUpdateModel) => {
    if (clientId === null) {
      throw new Error(`Cannot update snapshot when clientId (${clientId})`);
    }

    setIsUpdating(true);

    return updateMapSnapshot(clientId, parentMapId, data)
      .then(response => {
        dispatch(mapSnapshotUpdateSuccessful(data));
        return response.data;
      })
      .catch((apiError: ApiError) => Promise.reject(parseFieldErrors(apiError)))
      .finally(() => setIsUpdating(false));
  };

  return {
    isUpdating,
    updateSnapshot,
  };
};

export const useDeleteMapSnapshot = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();

  const deleteSnapshot = (snapshotId: number) => {
    if (clientId === null) {
      throw new Error('Cannot delete snapshot when clientId is null');
    }

    setIsDeleting(true);

    return deleteMapSnapshot(clientId, snapshotId)
      .then(response => {
        dispatch(mapSnapshotRemoveSuccess(snapshotId));
        return response;
      })
      .catch((apiError: ApiError) => Promise.reject(parseFieldErrors(apiError)))
      .finally(() => setIsDeleting(false));
  };

  return {
    isDeleting,
    deleteSnapshot,
  };
};
