import type { DrawingItem } from '~/store/mapSettings/drawing/items/drawingItems.state';
import { type DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';

export const updateDrawingItemPlacement = <T extends DrawingItem>(drawingItem: T, newPlacement: DrawingItemPlacement): T => {
  return {
    ...drawingItem,
    value: {
      ...drawingItem.value,
      placement: newPlacement,
    },
  };
};
