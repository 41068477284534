import { css } from '@emotion/react';
import {
  faCog,
  faCopy,
  faEllipsisV,
  faPresentation,
  faShare,
  faUsersMedical,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useState,
} from 'react';
import {
  RoundButtonComponent,
  RoundButtonSize,
} from '~/_shared/baseComponents/buttons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ContextMenuComponent,
  ContextMenuDefaultItem,
  ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTranslation } from '~/_shared/utils/hooks';

const alignLeftStyle = css({
  textAlign: 'left',
});

type MapRowActionsProps = Readonly<{
  isSnapshot: boolean;
  isShareActive: boolean;
  isOwner: boolean;

  onSettingsClick: () => void;
  onMapShareSettingsClick: () => void;
  onTeamShareMapClick: () => void;
  onMapCopyClick: () => void;
  onOpenInShareModeClick: () => void;

  testid?: string;
}>;

export const MapRowActionsComponent: FC<MapRowActionsProps> = (props) => {
  const [t] = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ContextMenuComponent
      isVisible={isMenuOpen}
      onHide={() => setIsMenuOpen(false)}
      renderMenuContent={() => (
        <>
          <ContextMenuItemCoreComponent
            itemStyle={alignLeftStyle}
            data-testid="map-listing-control-menu-settings"
          >
            <ContextMenuDefaultItem
              icon={faCog}
              size="s"
              onClick={props.onSettingsClick}
            >
              {t('Settings')}
            </ContextMenuDefaultItem>
          </ContextMenuItemCoreComponent>

          {props.isShareActive && (
            <ContextMenuItemCoreComponent
              itemStyle={alignLeftStyle}
              data-testid="map-listing-control-menu-share"
            >
              <ContextMenuDefaultItem
                icon={faShare}
                size="s"
                onClick={props.onMapShareSettingsClick}
              >
                {t('Share')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.isOwner && !props.isSnapshot && props.isShareActive && (
            <ContextMenuItemCoreComponent
              itemStyle={alignLeftStyle}
              data-testid="map-listing-control-menu-team-share"
            >
              <ContextMenuDefaultItem
                icon={faUsersMedical}
                size="s"
                onClick={props.onTeamShareMapClick}
              >
                {t('Team Map Share')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          <ContextMenuItemCoreComponent
            itemStyle={alignLeftStyle}
            data-testid="map-listing-control-menu-public"
          >
            <ContextMenuDefaultItem
              icon={faPresentation}
              itemStyle={css({ textAlign: 'left' })}
              size="s"
              onClick={props.onOpenInShareModeClick}
            >
              {t('Open in Presentation/Share Mode')}
            </ContextMenuDefaultItem>
          </ContextMenuItemCoreComponent>

          {!props.isSnapshot && (
            <ContextMenuItemCoreComponent
              itemStyle={alignLeftStyle}
              data-testid="map-listing-control-menu-copy"
            >
              <ContextMenuDefaultItem
                icon={faCopy}
                size="s"
                onClick={props.onMapCopyClick}
              >
                {t('Copy Map')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}
        </>
      )}
    >
      <TooltipComponent
        delayShow={500}
        tooltipContent={t('Map Control')}
      >
        <RoundButtonComponent
          buttonStyle={isMenuOpen ? RoundButtonStyle.Quaternary : RoundButtonStyle.SecondaryInverted}
          icon={faEllipsisV}
          size={RoundButtonSize.Small}
          onClick={() => {
            setIsMenuOpen(true);
          }}
          testid={props.testid}
        />
      </TooltipComponent>
    </ContextMenuComponent>
  );
};

MapRowActionsComponent.displayName = 'MapRowActionsComponent';
