import {
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { UnitSystem } from '../../../../_shared/types/googleMaps/googleMaps.types';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { mapSettingsSettingsUpdateSettings } from '../../../../store/mapSettings/settings/mapSettingsSettings.actionCreators';
import {
  useMapSettingsSettingsIsDisableActiveToolPanelSelector,
  useMapSettingsSettingsIsUserLocationShownSelector,
  useMapSettingsSettingsUnitSystemSelector,
} from '../../../../store/mapSettings/settings/mapSettingsSettings.selectors';
import { type OtherSettingsStructureProps } from './otherSettings.types';

export const useOtherSettingsSettings = (): OtherSettingsStructureProps => {
  const unitSystem = useMapSettingsSettingsUnitSystemSelector();
  const isUserShownOnAdmin = useMapSettingsSettingsIsUserLocationShownSelector();
  const isDisableActiveToolPanel = useMapSettingsSettingsIsDisableActiveToolPanelSelector();
  const { openModal: openCustomizeMapUrlModal } = useModal(ModalType.CustomizeMapUrl);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onMiMkMeasurementToggle = useCallback(() => {
    dispatch(mapSettingsSettingsUpdateSettings({
      unitSystem: unitSystem === UnitSystem.imperial ? UnitSystem.metric : UnitSystem.imperial,
    }));
  }, [dispatch, unitSystem]);

  const onIsUserShownOnAdminToggle = useCallback(() => {
    dispatch(mapSettingsSettingsUpdateSettings({
      isUserLocationShownOnAdmin: !isUserShownOnAdmin,
    }));
  }, [dispatch, isUserShownOnAdmin]);

  const onIsDisableActiveToolPanel = useCallback(() => {
    dispatch(mapSettingsSettingsUpdateSettings({
      isDisableActiveToolPanel: !isDisableActiveToolPanel,
    }));
  }, [dispatch, isDisableActiveToolPanel]);

  return useMemo(() => ({
    buttons: {
      customizeMapUrl: {
        onClick: openCustomizeMapUrlModal,
      },
    },
    toggles: {
      miMkMeasurement: {
        isOn: unitSystem === UnitSystem.metric,
        onToggle: onMiMkMeasurementToggle,
        onLabel: t('km').toUpperCase(),
        offLabel: t('mi').toUpperCase(),
      },
      showLocationOnAdmin: {
        isOn: isUserShownOnAdmin,
        onToggle: onIsUserShownOnAdminToggle,
      },
      disableActiveToolPanel: {
        isOn: isDisableActiveToolPanel,
        onToggle: onIsDisableActiveToolPanel,
      },
    },
  }), [isDisableActiveToolPanel, isUserShownOnAdmin, onIsDisableActiveToolPanel, onIsUserShownOnAdminToggle,
    onMiMkMeasurementToggle, openCustomizeMapUrlModal, t, unitSystem,
  ]);
};
