import { type SerializedStyles } from '@emotion/react';
import { type ColorPickerAlignment } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithColorTileComponent } from '../../../_shared/components/colorPicker/colorPickerWithColorTile/colorPickerWithColorTile.component';

type ColorPickerProps = Readonly<{
  className?: string;
  label?: string;
  selectedColor: ColorResult;
  editable?: boolean;
  onChange: (color: ColorResult) => void;
  displayAlpha: boolean;
  tileStyle?: SerializedStyles;
  size?: number;
  alignment?: ColorPickerAlignment;
  backgroundColor?: ColorResult;
}>;

export const DEFAULT_COLOR_TILE_WIDTH = 22;
export const ColorPickerComponent = ({
  editable = true,
  size = DEFAULT_COLOR_TILE_WIDTH,
  className,
  label,
  onChange,
  selectedColor,
  displayAlpha,
  tileStyle,
  alignment,
  backgroundColor,
  ...restProps
}: ColorPickerProps) => {
  return (
    <div className={className} {...restProps}>
      <ColorPickerWithColorTileComponent
        editable={editable}
        height={size}
        isFixed
        label={label}
        onChange={onChange}
        selectedColor={selectedColor}
        width={size}
        displayAlpha={displayAlpha}
        tileStyle={tileStyle}
        alignment={alignment}
        backgroundColor={backgroundColor}
      />
    </div>
  );
};
