export enum HomepageInfoAccountOption {
  '2FA' = '2FA',
  AccountSettings = 'AccountSettings',
  LaunchPaymentPortal = 'LaunchPaymentPortal',
  ManageTeamUsers = 'ManageTeamUsers',
}

export const homePageZIndexes = {
  topBar: 1000,
  leftSidebar: 20,
  stickyHeader: 10,
};
