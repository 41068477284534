import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { detectGPU } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import {
  resetGraphicSettings,
  setAllowIdleAnimations,
  setForcedClustering,
  setShowMarkerShadows,
  setSlideAnimationDuration,
} from '~/store/frontendState/graphicSettings/graphicSettings.actionCreators';
import {
  useAllowIdleAnimationsSelector,
  useHasForcedClustering,
  useShowMarkerShadows,
  useSlidingAnimationDuration,
} from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { mapSettingsMarkersToggle } from '~/store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import {
  useMapSettingsClusterDenseMarkers, useMapSettingsIsClusteringForced,
} from '~/store/mapSettings/markers/mapSettingsMarkersClustering.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { MapMarkersGraphicsToggleSettingsName } from '../settings/accordion/mapMarkersGraphics/mapMarkersGraphics.enums';
import { useMapContext } from './mapContext';
import {
  useLatLngSpreadsheetData, useSpreadSheetData,
} from './useSpreadsheetData.hook';

type RecordedRenderStats = [number, number][];
type RecordedRenderStatsAverages = { idle: number; nonIdle: number };
type AdjustableGraphicSettings = {
  idleAA?: AAMethod; nonIdleAA?: AAMethod;
  idlePasses?: number; nonIdlePasses?: number;
  passDegrade?: number;
  resolution?: number;
  showMarkerShadows?: boolean;
  forceClustering?: boolean;
  allowIdleAnimations?: boolean;
};
type AdjustableGraphicSettingsPerIdleStatus = { aa: AAMethod; passDegrade: number; passNum: number };

const DEBUG_MODE = testingModeEnabled();

export const SLIDING_ANIMATION_DURATION = 250;
export const MARKER_ALPHA_TEST = 0.3; //every opacity below this value is considered transparent, TODO: need to find a better solution in WebGL
const IDLE_FPS_THRESHOLD_TO_DISABLE_UI_ANIMATIONS = 12;
const NON_IDLE_FPS_THRESHOLD_TO_DISABLE_UI_ANIMATIONS = 16;

const IDLE_FPS_THRESHOLD_TO_DISABLE_IDLE_ANIMATIONS = 20;
const IDLE_FPS_THRESHOLD_TO_ENABLE_IDLE_ANIMATIONS = 30;

const MIN_NUMBER_OF_MARKERS_TO_REMOVE_SHADOWS_WHEN_SLOW = 2500;
const MIN_NUMBER_OF_MARKERS_TO_FORCE_CLUSTERING = 5000;
const NUMBER_OF_MARKERS_ADDED_TO_SWITCH_TO_LOW_SETTINGS = 35000;
const NUMBER_OF_MARKERS_ADDED_INCREMENT_AFTER_LOW_SETTINGS = 10000;

const IDLE_LOW_FPS_THRESHOLD = 5;
const IDLE_HIGH_FPS_THRESHOLD = 25;
const NON_IDLE_LOW_FPS_THRESHOLD = 25;
const NON_IDLE_HIGH_FPS_THRESHOLD = 35;
const MIN_DP_PASSES = 2;
const MAX_DP_PASSES = 7;

// current both min and default are 2, so no other is possible.
// If we wanted to allow for this being changed, we first need to check webgl, as changing resolution currently causes a bug
const MIN_RESOLUTION = 2;
const DEFAULT_RESOLUTION = 2;

const DEFAULT_IDLE_AA = 'FXAA';
const DEFAULT_NON_IDLE_AA = 'FXAA';
const DEFAULT_IDLE_DP_PASSES = 5;
const DEFAULT_NON_IDLE_DP_PASSES = 3;
const DEFAULT_DP_PASS_DEGRADE = 1;
const DEFAULT_MAX_DP_PASS_DEGRADE = 0.7;
const DEFAULT_MARKER_TEXTURE_SIZE = 200;
const DEFAULT_DRAWING_TOOL_TEXTURE_SIZE = 400;
const DEFAULT_MARKER_OPTIMIZATION_LEVEL = 1;

const LOW_PERFORMANCE_MODE_DEFAULT_IDLE_DP_PASSES = 3;
const LOW_PERFORMANCE_MODE_DEFAULT_DP_PASS_DEGRADE = 0.8;

const NO_DEGRADE_DP_PASS_RESOLUTION_GPUS = ['Apple'];

const getAverageFPSSinceLastAnalysis = (fpsCounts: RecordedRenderStats): RecordedRenderStatsAverages => {
  let idle = 0;
  let nonIdle = 0;

  fpsCounts.forEach((item) => {
    idle += item[0];
    nonIdle += item[1];
  });

  return {
    idle: idle / fpsCounts.length,
    nonIdle: nonIdle / fpsCounts.length,
  };
};

const areAdjustableGraphicSettingsPerIdleStatusEqual = (
  settings1: AdjustableGraphicSettingsPerIdleStatus, settings2: AdjustableGraphicSettingsPerIdleStatus
): boolean => (
  settings1.aa === settings2.aa && settings1.passNum === settings2.passNum
    && settings1.passDegrade === settings2.passDegrade
);

const determineNewGraphicSettings = (
  lowFpsThreshold: number, highFpsThreshold: number, averageFps: number,
  aa: AAMethod, passDegrade: number, passNum: number, disallowAAIncrease?: boolean,
): AdjustableGraphicSettingsPerIdleStatus => {
  let newAA = aa;
  let newPassDegrade = passDegrade;
  let newPassNum = passNum;

  if (averageFps < lowFpsThreshold) {
    const averageToLowThresholdFpsRatio = averageFps / lowFpsThreshold;
    if (newPassNum > MIN_DP_PASSES) {
      newPassNum = MIN_DP_PASSES + Math.floor((passNum - MIN_DP_PASSES) * averageToLowThresholdFpsRatio);
    }
    if (passDegrade > maxDpPassDegrade && passNum === newPassNum && !isNoDegradeDpPassResGPU) {
      const ratioToValuePassDegrade =
        Math.floor((passDegrade - maxDpPassDegrade) * averageToLowThresholdFpsRatio * 10) / 10;
      newPassDegrade = Math.round((maxDpPassDegrade + ratioToValuePassDegrade) * 10) / 10;
    }
    if (aa !== 'FXAA' && passNum === newPassNum && passDegrade === newPassDegrade) {
      newAA = 'FXAA';
    }
  }
  else if (averageFps > highFpsThreshold) {
    const averageToHighThresholdFpsRatio = averageFps / highFpsThreshold;
    if (newPassNum < MAX_DP_PASSES) {
      newPassNum = Math.ceil(passNum * averageToHighThresholdFpsRatio);
      if (newPassNum > MAX_DP_PASSES) {
        newPassNum = MAX_DP_PASSES;
      }
    }
    if (passDegrade < 1 && passNum === newPassNum && !isNoDegradeDpPassResGPU) {
      newPassDegrade = Math.ceil(passDegrade * averageToHighThresholdFpsRatio * 10) / 10;
      if (newPassDegrade > 1) {
        newPassDegrade = 1;
      }
    }
    if (!disallowAAIncrease && aa !== 'MSAAx2' && passNum === newPassNum && passDegrade === newPassDegrade) {
      newAA = 'MSAAx2';
    }
  }

  return { aa: newAA, passDegrade: newPassDegrade, passNum: newPassNum };
};

const pixelRatio = window.devicePixelRatio;
const detectedGraphicCard = detectGPU();
const isNoDegradeDpPassResGPU = NO_DEGRADE_DP_PASS_RESOLUTION_GPUS.some((cardName: string) => detectedGraphicCard.includes(cardName));
const maxDpPassDegrade = isNoDegradeDpPassResGPU ? 1 : DEFAULT_MAX_DP_PASS_DEGRADE;

export const useGraphicSettings = (numberOfRenderedMarkers: number) => {
  const { webglLayers: layers, webglOverlay: overlay } = useMapContext();

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const spreadsheetData = useSpreadSheetData();
  const locationData = useLatLngSpreadsheetData();
  const showingMarkerShadows = useShowMarkerShadows();
  const allowIdleAnimations = useAllowIdleAnimationsSelector();
  const slidingAnimationDuration = useSlidingAnimationDuration();
  const isMarkerClusteringForced = useMapSettingsIsClusteringForced();
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();
  const isClusterDenseMarkersEnabled = useMapSettingsClusterDenseMarkers();
  const wasMarkerClusteringForcedByGraphicSettings = useHasForcedClustering();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const fpsCountsSinceLastAnalysis = useRef<RecordedRenderStats>([]);

  const [isIdleAnimationToggleOnCooldown, startIdleAnimationToggleCooldown] = useTimeout(30000);

  const isLowPerformanceMode = !!exportImageSettings.mode;

  const [resolution, setResolution] = useState(DEFAULT_RESOLUTION);
  const [idleAA, setIdleAA] = useState<AAMethod>(DEFAULT_IDLE_AA);
  const [nonIdleAA, setNonIdleAA] = useState<AAMethod>(DEFAULT_NON_IDLE_AA);
  const [idleDPPassNum, setIdleDPPassNum] = useState<number>(isLowPerformanceMode ? LOW_PERFORMANCE_MODE_DEFAULT_IDLE_DP_PASSES : DEFAULT_IDLE_DP_PASSES);
  const [nonIdleDPPassNum, setNonIdleDPPassNum] = useState<number>(DEFAULT_NON_IDLE_DP_PASSES);
  const [dpPassDegrade, setDpPassDegrade] = useState<number>(isNoDegradeDpPassResGPU ? 1 : isLowPerformanceMode ? LOW_PERFORMANCE_MODE_DEFAULT_DP_PASS_DEGRADE : DEFAULT_DP_PASS_DEGRADE);

  const previousNumberOfRenderedMarkers = usePrevious(numberOfRenderedMarkers);

  /*
   * Leaving this here in case we wanted to bring back deciding based on how many markers are on map
   * If unused for a longer time, consider removing
   *
  const approximateBoundsOfAllMarkers = useMemo(() => {
    if (spreadsheetData.areLoaded && isGoogleMapsInitialized) {
      if (locationData.data.length <= NUMBER_OF_SAMPLE_MARKERS_TO_DETERMINE_BOUNDS) {
        return getBoundingBox(locationData.data);
      }
      else {
        const approximateBounds = new BoundingBox();
        for (let i = 0; i < NUMBER_OF_SAMPLE_MARKERS_TO_DETERMINE_BOUNDS; i++) {
          approximateBounds.extend(locationData.data[i]);
        }
        return approximateBounds;
      }
    }
    return undefined;
  }, [locationData.data, spreadsheetData.areLoaded, isGoogleMapsInitialized]);
   */

  const showForcedClusteringDueToPerformanceNoticeModal = useCallback(() => {
    openConfirmationModal({
      confirmCaption: t('OK'),
      title: t('Clustering of Markers was Enabled'),
      text: t('graphicSettings.clusteringForcedNotice'),
      onConfirm: closeConfirmationModal,
      permanentConfirmSettings: {
        id: 'force-clustering-by-graphic-settings',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, openConfirmationModal, t]);

  const setAdjustableGraphicSettings = useCallback((newSettings: AdjustableGraphicSettings) => {
    if (newSettings.resolution) {
      setResolution(newSettings.resolution);
    }
    if (newSettings.idleAA) {
      setIdleAA(newSettings.idleAA);
    }
    if (newSettings.nonIdleAA) {
      setNonIdleAA(newSettings.nonIdleAA);
    }
    if (newSettings.idlePasses) {
      setIdleDPPassNum(newSettings.idlePasses);
    }
    if (newSettings.nonIdlePasses) {
      setNonIdleDPPassNum(newSettings.nonIdlePasses);
    }
    if (newSettings.passDegrade) {
      setDpPassDegrade(newSettings.passDegrade);
    }
    if (newSettings.showMarkerShadows !== undefined) {
      dispatch(setShowMarkerShadows(newSettings.showMarkerShadows));
    }
    if (newSettings.allowIdleAnimations !== undefined) {
      startIdleAnimationToggleCooldown();
      dispatch(setAllowIdleAnimations(newSettings.allowIdleAnimations));
    }
    if (newSettings.forceClustering) {
      dispatch(setForcedClustering(true));
      dispatch(mapSettingsMarkersToggle(MapMarkersGraphicsToggleSettingsName.clusterDenseMarkers));
      showForcedClusteringDueToPerformanceNoticeModal();
    }
  }, [dispatch, showForcedClusteringDueToPerformanceNoticeModal, startIdleAnimationToggleCooldown]);

  const analyzePerformanceAndAdjustSettings = useCallback((aveFps: RecordedRenderStatsAverages) => {
    if (DEBUG_MODE) {
      console.debug('FPS Averages since last analysis', JSON.stringify(aveFps));
    }

    const newSuggestedSettings: AdjustableGraphicSettings = {};

    const newNonIdleSettings = determineNewGraphicSettings(
      NON_IDLE_LOW_FPS_THRESHOLD, NON_IDLE_HIGH_FPS_THRESHOLD, aveFps.nonIdle,
      nonIdleAA, dpPassDegrade, nonIdleDPPassNum, true,
    );
    const newIdleSettings = determineNewGraphicSettings(
      IDLE_LOW_FPS_THRESHOLD, IDLE_HIGH_FPS_THRESHOLD, aveFps.idle,
      idleAA, dpPassDegrade, idleDPPassNum, true,
    );

    if (areAdjustableGraphicSettingsPerIdleStatusEqual(newNonIdleSettings, {
      aa: nonIdleAA, passDegrade: dpPassDegrade, passNum: nonIdleDPPassNum,
    })) {
      // if there was no change in non-idle settings, it means we are either maxed or at the minimum
      if (aveFps.nonIdle < NON_IDLE_LOW_FPS_THRESHOLD) {
        if (showingMarkerShadows && locationData.data.length > MIN_NUMBER_OF_MARKERS_TO_REMOVE_SHADOWS_WHEN_SLOW) {
          newSuggestedSettings.showMarkerShadows = false;
        }
        else if (!wasMarkerClusteringForcedByGraphicSettings &&
          !isClusterDenseMarkersEnabled && !isMarkerClusteringForced
          && locationData.data.length > MIN_NUMBER_OF_MARKERS_TO_FORCE_CLUSTERING
        ) {
          newSuggestedSettings.forceClustering = true;
        }
        else if (pixelRatio < MIN_RESOLUTION + 1) {
          newSuggestedSettings.resolution = MIN_RESOLUTION;
        }
      }
    }
    else {
      newSuggestedSettings.nonIdleAA = newNonIdleSettings.aa;
      newSuggestedSettings.nonIdlePasses = newNonIdleSettings.passNum;
      newSuggestedSettings.passDegrade = newNonIdleSettings.passDegrade;
    }

    if (areAdjustableGraphicSettingsPerIdleStatusEqual(newIdleSettings, {
      aa: idleAA, passDegrade: dpPassDegrade, passNum: idleDPPassNum,
    })) {
      // if there was no change in non-idle settings, it means we are either maxed or at the minimum
      if (!isIdleAnimationToggleOnCooldown) {
        if (allowIdleAnimations && aveFps.idle < IDLE_FPS_THRESHOLD_TO_DISABLE_IDLE_ANIMATIONS) {
          newSuggestedSettings.allowIdleAnimations = false;
        }
        else if (!allowIdleAnimations && aveFps.idle > IDLE_FPS_THRESHOLD_TO_ENABLE_IDLE_ANIMATIONS) {
          newSuggestedSettings.allowIdleAnimations = true;
        }
      }
    }
    else {
      newSuggestedSettings.idleAA = newIdleSettings.aa;
      newSuggestedSettings.idlePasses = newIdleSettings.passNum;
    }

    if (newSuggestedSettings.nonIdlePasses) {
      const idlePasses = newSuggestedSettings.idlePasses || idleDPPassNum;
      if (idlePasses < newSuggestedSettings.nonIdlePasses) {
        newSuggestedSettings.idlePasses = newSuggestedSettings.nonIdlePasses;
      }
    }

    if (DEBUG_MODE && Object.entries(newSuggestedSettings).length) {
      console.debug('New Suggested Graphic Settings', JSON.stringify(newSuggestedSettings));
    }
    setAdjustableGraphicSettings(newSuggestedSettings);

    // this function should not be affected by changes of respective graphic settings
  }, [allowIdleAnimations, dpPassDegrade, idleAA, idleDPPassNum, isClusterDenseMarkersEnabled, isIdleAnimationToggleOnCooldown,
    isMarkerClusteringForced, locationData.data.length, nonIdleAA, nonIdleDPPassNum, setAdjustableGraphicSettings,
    showingMarkerShadows, wasMarkerClusteringForcedByGraphicSettings,
  ]);

  const adjustUIAnimationSettings = useCallback((aveFps: RecordedRenderStatsAverages) => {
    const isLowFpsForUiAnimations = aveFps.nonIdle < NON_IDLE_FPS_THRESHOLD_TO_DISABLE_UI_ANIMATIONS
      || aveFps.idle < IDLE_FPS_THRESHOLD_TO_DISABLE_UI_ANIMATIONS;
    if (isLowFpsForUiAnimations && slidingAnimationDuration) {
      dispatch(setSlideAnimationDuration(0));
    }
    else if (!isLowFpsForUiAnimations && slidingAnimationDuration !== SLIDING_ANIMATION_DURATION) {
      dispatch(setSlideAnimationDuration(SLIDING_ANIMATION_DURATION));
    }
  }, [dispatch, slidingAnimationDuration]);

  const setLowSettings = useCallback(() => {
    setAdjustableGraphicSettings({
      allowIdleAnimations: false,
      idlePasses: idleDPPassNum > DEFAULT_IDLE_DP_PASSES ? DEFAULT_IDLE_DP_PASSES : idleDPPassNum,
      nonIdlePasses: MIN_DP_PASSES,
      passDegrade: maxDpPassDegrade,
      showMarkerShadows: false,
    });
  }, [idleDPPassNum, setAdjustableGraphicSettings]);

  const analyzePerformanceAndAdjustSettingsThrottled = useThrottle(() => {
    if (fpsCountsSinceLastAnalysis.current.length === 0) {
      return;
    }

    const aveFps = getAverageFPSSinceLastAnalysis(fpsCountsSinceLastAnalysis.current);

    analyzePerformanceAndAdjustSettings(aveFps);
    adjustUIAnimationSettings(aveFps);

    fpsCountsSinceLastAnalysis.current = [];
  }, [adjustUIAnimationSettings, analyzePerformanceAndAdjustSettings], 2000);

  const recordStatsAndAnalyzePerformance = useCallback((stats: WebGLRenderingStats) => {
    if (isLowPerformanceMode) {
      return;
    }
    if (spreadsheetData.areLoaded && locationData.data.length) {
      fpsCountsSinceLastAnalysis.current.push([stats.idle.avgFps, stats.nonIdle.avgFps]);
    }
    analyzePerformanceAndAdjustSettingsThrottled();
  }, [analyzePerformanceAndAdjustSettingsThrottled, isLowPerformanceMode, locationData.data.length, spreadsheetData.areLoaded]);

  useEffect(() => {
    if (!overlay) {
      return;
    }
    dispatch(resetGraphicSettings());
  }, [dispatch, mapId, overlay]);

  useEffect(() => {
    if (!overlay) {
      return;
    }
    overlay.view.setStatsCallback(recordStatsAndAnalyzePerformance);
  }, [overlay, recordStatsAndAnalyzePerformance]);

  useEffect(() => {
    if (isLowPerformanceMode) {
      return;
    }

    const previousNumberOfMarkers = previousNumberOfRenderedMarkers || 0;

    const currentMarkersOverTreshold = numberOfRenderedMarkers > NUMBER_OF_MARKERS_ADDED_TO_SWITCH_TO_LOW_SETTINGS;
    const previousMarkersOverTreshold = previousNumberOfMarkers > NUMBER_OF_MARKERS_ADDED_TO_SWITCH_TO_LOW_SETTINGS;

    const markersIncreasedByMoreThanThreshold = currentMarkersOverTreshold && !previousMarkersOverTreshold;
    const markersOverThresholdIncreasedSignificantly = previousMarkersOverTreshold
      && numberOfRenderedMarkers - previousNumberOfMarkers > NUMBER_OF_MARKERS_ADDED_INCREMENT_AFTER_LOW_SETTINGS;

    if (markersIncreasedByMoreThanThreshold || markersOverThresholdIncreasedSignificantly) {
      if (DEBUG_MODE) {
        console.debug('Setting LOW Graphic Settings');
      }
      fpsCountsSinceLastAnalysis.current = [];
      setLowSettings();
      adjustUIAnimationSettings({ idle: 0, nonIdle: 0 });
    }
  }, [overlay, idleAA, nonIdleAA, numberOfRenderedMarkers, previousNumberOfRenderedMarkers, setLowSettings,
    adjustUIAnimationSettings, isLowPerformanceMode]);

  useEffect(() => {
    if (!layers) {
      return;
    }

    layers.Markers.setTextureQuality(DEFAULT_MARKER_TEXTURE_SIZE, DEFAULT_MARKER_OPTIMIZATION_LEVEL);
    layers.Markers.dpIdlePassNum = 3;
    layers.Markers.dpNonIdlePassNum = 3;
    layers.Markers.markerAlphaTest = MARKER_ALPHA_TEST;

    layers.MapObjectMarkers.setTextureQuality(DEFAULT_DRAWING_TOOL_TEXTURE_SIZE, 0);
    layers.MapObjectMarkers.markerAlphaTest = 0.1;

    layers.HighPriorityMarkers.setTextureQuality(DEFAULT_DRAWING_TOOL_TEXTURE_SIZE, 0);
    layers.HighPriorityMarkers.markerAlphaTest = 0.1;

    layers.BoundaryPolygons.optimizeBordersDP = false;
    layers.DriveTimePolygons.optimizeBordersDP = false;

  }, [layers]);

  useEffect(() => {
    if (overlay) {
      overlay.view.setResolution(resolution);
    }
  }, [overlay, resolution]);

  useEffect(() => {
    if (overlay) {
      overlay.view.setAnialiasMode('FXAA', 'FXAA');
    }
  }, [overlay]);

  useEffect(() => {
    if (!overlay) {
      return;
    }

    overlay.layerComposer.passResDegradate = dpPassDegrade;
    overlay.layerComposer.passMaxResDegradate = maxDpPassDegrade;
    overlay.layerComposer.passNumIdle = idleDPPassNum;
    overlay.layerComposer.passNumNonIdle = nonIdleDPPassNum;

  }, [overlay, idleDPPassNum, nonIdleDPPassNum, dpPassDegrade]);

  useEffect(() => {
    if (isLowPerformanceMode) {
      adjustUIAnimationSettings({ idle: 0, nonIdle: 0 });
      setIdleDPPassNum(LOW_PERFORMANCE_MODE_DEFAULT_IDLE_DP_PASSES);
      setDpPassDegrade(LOW_PERFORMANCE_MODE_DEFAULT_DP_PASS_DEGRADE);
    }
  }, [isLowPerformanceMode, adjustUIAnimationSettings]);
};
