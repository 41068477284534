import {
  type FetchableResource, FetchableResourceStatus,
} from './fetchableResource';

export const getLoadingStateFetchableResource = () => ({
  status: FetchableResourceStatus.Loading,
});

// check if succesfully loaded, including containing a value
export const isResourceLoaded = <T>(
  fetchableResource: FetchableResource<T> | undefined | null
): fetchableResource is {
  status: FetchableResourceStatus.Loaded;
  value: T;
} => (
  !!fetchableResource
  && fetchableResource.status === FetchableResourceStatus.Loaded
  && fetchableResource.value !== undefined
);

// check if still loading, usually used when we want to know if we still should wait or loading already finished including failed
export const isResourceLoading = <T>(
  fetchableResource: FetchableResource<T> | undefined | null
): fetchableResource is {
  status: FetchableResourceStatus.Loading;
} => (
  !!fetchableResource
  && fetchableResource.status === FetchableResourceStatus.Loading
);
