import {
  type ReactElement, type RefObject, useRef,
} from 'react';
import {
  useDrag, useDrop, type XYCoord,
} from 'react-dnd';

export type SortableItemProps = {
  id: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  itemType: string;
  render: (props: { isDragging: boolean; ref: RefObject<HTMLDivElement | null> }) => ReactElement;
};

export const SortableItemComponent = ({ id, index, moveItem, render, itemType }: SortableItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: itemType,
    hover(item: { index: number; type: string }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset() as XYCoord;

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return render({ isDragging, ref });
};
