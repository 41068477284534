import { type FC } from 'react';
import {
  type ActiveGroupFilters, type GroupingColumn, type GroupingColumnNumeric,
  type GroupingColumnValues,
} from '../_shared/types/grouping/grouping';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '../_shared/types/spreadsheetData/spreadsheetColumn';
import { MapToolWrapperComponent } from '../sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';
import { GroupingItemsAccordionComponent } from './components/groupingItemsAccordion.component';
import { GroupingSetting } from './components/groupingSetting.component';
import { type GroupChangeRequest } from './useGroupColumnData';

type GroupingPanelProps = Readonly<{
  activeColumns: ReadonlyArray<GroupingColumn>;
  areGroupSettingsVisible: boolean;
  columns: ReadonlyArray<SpreadsheetColumn>;
  filterValues: ActiveGroupFilters;
  isGroupEditingDisabled: boolean;
  isLoading: boolean;

  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, group: GroupingColumnValues<1>) => void;
  onGroupColumnRemove?: (column: GroupingColumn) => void;
  onGroupColumnChange: (newColumn: GroupChangeRequest, columnToReplace: GroupingColumn) => void;
  onGroupColumnAdd: (newColumn: GroupingColumn) => void;
  onGroupColumnNumericSettingsOpen: (column: GroupingColumnNumeric) => void;
}>;

export const GroupingPanelComponent: FC<GroupingPanelProps> = (props) => (
  <MapToolWrapperComponent isLoading={props.isLoading}>
    {props.areGroupSettingsVisible && (
      <GroupingSetting
        activeColumns={props.activeColumns}
        columns={props.columns}
        onGroupColumnAdd={props.onGroupColumnAdd}
        onGroupColumnChange={props.onGroupColumnChange}
        onGroupColumnRemove={props.onGroupColumnRemove}
        onNumericGroupColumnSettingsClick={props.onGroupColumnNumericSettingsOpen}
      />
    )}

    <GroupingItemsAccordionComponent
      activeColumns={props.activeColumns}
      activeFilters={props.filterValues}
      allColumns={props.columns}
      isGroupEditingDisabled={props.isGroupEditingDisabled}
      onFilterChange={props.onFilterChange}
      onGroupColumnRemove={props.onGroupColumnRemove}
      onNumericGroupColumnSettingsClick={props.onGroupColumnNumericSettingsOpen}
    />
  </MapToolWrapperComponent>
);
