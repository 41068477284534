import { ColorPickerAlignment } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';

export const convertColorPickerAlignmentBasedOnAvailableSpace = (alignment: ColorPickerAlignment, tooTall: boolean, tooWide: boolean) => {
  if (tooWide) {
    if (tooTall || alignment === ColorPickerAlignment.TopCenter || alignment === ColorPickerAlignment.TopLeft) {
      return ColorPickerAlignment.TopRight;
    }
    else if (alignment === ColorPickerAlignment.BottomCenter || alignment === ColorPickerAlignment.BottomLeft || alignment === ColorPickerAlignment.Right) {
      return ColorPickerAlignment.BottomRight;
    }
  }
  else if (tooTall) {
    switch (alignment) {
      case ColorPickerAlignment.Right:
      case ColorPickerAlignment.BottomLeft:
        return ColorPickerAlignment.TopLeft;
      case ColorPickerAlignment.BottomCenter:
        return ColorPickerAlignment.TopCenter;
      case ColorPickerAlignment.BottomRight:
        return ColorPickerAlignment.TopRight;
      case ColorPickerAlignment.TopLeft:
        return ColorPickerAlignment.BottomLeft;
      case ColorPickerAlignment.TopCenter:
        return ColorPickerAlignment.BottomCenter;
      case ColorPickerAlignment.TopRight:
        return ColorPickerAlignment.BottomRight;

      default:
        return alignment;
    }
  }

  return alignment;
};
