import { css } from '@emotion/react';
import {
  type FC,
  useCallback,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import {
  s, Trans,
} from '../../../../../translations/Trans';
import { type DeletedBaseMapWithDependenciesOtherUsersMap } from '../baseMapDeleteDependenciesResolve.helper';
import { HandleBaseMapDeletionItemComponent } from './handleBaseMapDeletionItem.component';
import { type HandledLayeredMap } from './handleBaseMapDeletionModal.container';
import {
  allSelected,
  type HandleBaseMapDeletionModalHelper,
} from './handleBaseMapDeletionModal.helper';

const informationStyle = css({
  fontSize: 16,
  padding: 22,
});

const modalStyle = css({
  padding: 0,
});

const accordionPanelStyle = css({
  padding: 0,
});

const otherUsersSectionWrapperStyle = css({
  padding: '4px 32px',
});

const otherUsersInfoStyle = css({
  margin: 0,
});

const otherUsersListStyle = css({
  padding: 0,
  margin: '10px 0 0',
});

const otherUsersListItemStyle = css({
  padding: '0',
  margin: '0 0 0 25px',
});

const mapNameStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.link,
});

const accordionHeadingStyle = css({
  fontSize: 16,
  textTransform: 'uppercase',
});

export type HandleBaseMapDeletionModalComponentProps = {
  mapId: number;
  mapName: string;
  mapNumber: number;
  mapsTotal: number;
  layeredMaps: HandledLayeredMap[];
  otherUsersUsingMap: DeletedBaseMapWithDependenciesOtherUsersMap[];
  selectedResolutions: HandleBaseMapDeletionModalHelper;
  submitting: boolean;

  onSubmit: (selectedResolutions: HandleBaseMapDeletionModalHelper) => void;
};

export const HandleBaseMapDeletionModalComponent: FC<ModalProps<HandleBaseMapDeletionModalComponentProps>> = (props) => {
  const [selectedResolutions, setSelectedResolutions] = useState<HandleBaseMapDeletionModalHelper>(props.selectedResolutions);
  const [areUserMapsExpanded, setAreUserMapsExpanded] = useState(true);
  const [areOtherUsersMapsExpanded, setAreOtherUsersMapOpened] = useState(true);
  const [t] = useTranslation();
  const { onSubmit } = props;
  const theme = useTheme();

  const submitResolutions = useCallback(() => {
    onSubmit(selectedResolutions);
  }, [onSubmit, selectedResolutions]);

  const accordionData: AccordionData[] = [];

  if (props.layeredMaps.length > 0) {
    accordionData.push({
      header: (
        <Trans i18nKey="Maps based on <1>{{mapName}}</1> - you own">
          {s('Maps based on ')}
          <span css={mapNameStyle({ theme })}>{{ mapName: props.mapName }}</span>
          {s(' - you own')}
        </Trans>
      ),
      child: (
        <>
          {props.layeredMaps.map((map) => (
            <HandleBaseMapDeletionItemComponent
              key={map.id}
              map={map}
              selectedResolutions={selectedResolutions}
              onSelectedResolutionsChange={setSelectedResolutions}
            />
          ))}
        </>
      ),
      isExpanded: areUserMapsExpanded,
      onHeadingClick: () => {
        setAreUserMapsExpanded(prevExpanded => !prevExpanded);
      },
    });
  }

  if (props.otherUsersUsingMap.length > 0) {
    accordionData.push({
      header: (
        <Trans i18nKey="Maps based on <1>{{mapName}}</1> - owned by others">
          {s('Maps based on ')}
          <span css={mapNameStyle({ theme })}>{{ mapName: props.mapName }}</span>
          {s(' - owned by others')}
        </Trans>
      ),
      child: (
        <div css={otherUsersSectionWrapperStyle}>
          <p css={otherUsersInfoStyle}>
            {t('This map is used by the following users. A copy of the map will be created for each of them.')}
          </p>
          <ul css={otherUsersListStyle}>
            {props.otherUsersUsingMap.map((user, index) => (
              <li
                key={index}
                css={otherUsersListItemStyle}
              >
                {user.userName}
              </li>
            ))}
          </ul>
        </div>
      ),
      isExpanded: areOtherUsersMapsExpanded,
      onHeadingClick: () => {
        setAreOtherUsersMapOpened(prevExpanded => !prevExpanded);
      },
    });
  }

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      contentStyle={modalStyle}
      caption={t('Layered Map Dependencies {{mapNumber}} of {{mapsTotal}}', { mapNumber: props.mapNumber, mapsTotal: props.mapsTotal })}
      maxWidth={580}
      additionalContent={props.submitting && <OverlayLoaderComponent />}
      confirmButton={(
        <ButtonComponent
          text={t('Done')}
          isDisabled={!allSelected(selectedResolutions)}
          onClick={submitResolutions}
        />
      )}
    >
      <div css={informationStyle}>
        <div>
          <Trans i18nKey="The map <1>{{mapName}}</1> is a part of layered maps.">
            {s('The map ')}
            <span css={mapNameStyle({ theme })}>{{ mapName: props.mapName }}</span>
            {s(' is a part of layered maps.')}
          </Trans>
        </div>

        <div>
          <Trans i18nKey="Please select how you want to remove the <1>{{mapName}}</1> from the following layered maps.">
            {s('Please select how you want to remove the ')}
            <span css={mapNameStyle({ theme })}>{{ mapName: props.mapName }}</span>
            {s(' from the following layered maps.')}
          </Trans>
        </div>
      </div>

      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          itemButtonStyle={accordionHeadingStyle}
          panelStyle={accordionPanelStyle}
          data={accordionData}
        />
      </OutlinePanelComponent>
    </ModalComponent>
  );
};
