import { type BoundaryTerritoryGroupsAction } from './boundaryTerritoryGroups.action';
import {
  BOUNDARY_TERRITORY_CHANGE_NAME,
  BOUNDARY_TERRITORY_DELETE,
  BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL,
  BOUNDARY_TERRITORY_GROUP_CREATE_ERROR,
  BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_FETCH_ERROR,
  BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST,
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR,
  BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST, BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR,
  BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY,
} from './boundaryTerritoryGroups.actionTypes';
import {
  addNewCustomBoundaryTerritory,
  type BoundaryTerritoryGroupsState,
  deleteBoundaryTerritory,
  updateBoundaryTerritoryName,
} from './boundaryTerritoryGroups.state';

const boundaryTerritoryGroupsInitialState: BoundaryTerritoryGroupsState = {
  groups: [],
  isError: false,
  isActionLoading: false,
  isFetchLoading: false,
};

export const boundaryTerritoryGroupsReducer = (
  state: BoundaryTerritoryGroupsState = boundaryTerritoryGroupsInitialState,
  action: BoundaryTerritoryGroupsAction
): BoundaryTerritoryGroupsState => {
  switch (action.type) {
    case BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST: {
      return {
        ...state,
        isError: false,
        isFetchLoading: true,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS: {
      return {
        ...state,
        groups: action.payload.boundaryTerritoryGroups,
        isFetchLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_FETCH_ERROR: {
      return {
        ...state,
        isError: true,
        isFetchLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST:
    case BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST:
    case BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST:
    case BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST: {
      return {
        ...state,
        isError: false,
        isActionLoading: true,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_CREATE_ERROR:
    case BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR:
    case BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR:
    case BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR: {
      return {
        ...state,
        isError: true,
        isActionLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS: {
      return {
        ...state,
        groups: [
          action.payload.boundaryTerritoryGroup,
          ...state.groups,
        ],
        isActionLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY: {
      return addNewCustomBoundaryTerritory(
        state, action.payload.boundaryTerritoryGroupId,
        action.payload.newTerritoryId,
        action.payload.name,
        action.payload.fillColor,
        action.payload.fillOpacity
      );
    }

    case BOUNDARY_TERRITORY_CHANGE_NAME: {
      return updateBoundaryTerritoryName(state, action.payload.newName, action.payload.territoryId, action.payload.boundaryTerritoryGroupId);
    }

    case BOUNDARY_TERRITORY_DELETE: {
      return deleteBoundaryTerritory(state, action.payload.territoryId, action.payload.boundaryTerritoryGroupId);
    }

    case BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS: {
      const newTerritoryGroups = state.groups.map(territoryGroup => {
        if (territoryGroup.boundaryTerritoryGroupId !== action.payload.boundaryTerritoryGroup.boundaryTerritoryGroupId) {
          return territoryGroup;
        }
        else {
          return action.payload.boundaryTerritoryGroup;
        }
      });

      return {
        ...state,
        groups: newTerritoryGroups,
        isActionLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS: {
      return {
        ...state,
        isActionLoading: false,
        groups: state.groups.filter(group => {
          return group.boundaryTerritoryGroupId !== action.payload.boundaryTerritoryGroup.boundaryTerritoryGroupId;
        }),
      };
    }

    case BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL:
    case BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL: {
      return {
        ...state,
        isActionLoading: false,
      };
    }

    default:
      return state;
  }
};
