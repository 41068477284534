import { type DeepReadonly } from 'ts-essentials';

type AnyObject = { readonly [key: string]: any };

const isObject = (item: any): item is Mutable<AnyObject> => {
  return (item && typeof item === 'object' && !Array.isArray(item));
};
const isArray = Array.isArray;

const mergeTwoArguments = (target: any, source: any): any => {
  if (isArray(target) && isArray(source)) {
    target.push(...source);
    return target;
  }
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = mergeTwoArguments(target[key], source[key]);
      }
    }
    return target;
  }
  // In case of a mismatch only return the replacement value.
  if (isArray(source)) {
    // Array needs to be deep-cloned.
    return source.map((item: any) => mergeTwoArguments({}, item));
  }
  if (isObject(source)) {
    // Objects also need to be deep-cloned.
    return Object.fromEntries(Object.entries(source).map(([key, value]) => ([key, mergeTwoArguments({}, value)])));
  }
  // Primitives are just overwritten.
  return source;
};

export const mergeDeep = <T>(target: any, ...sources: any): T => {
  if (!sources.length) {
    return target;
  }

  return sources.reduce((result: any, source: any) => mergeTwoArguments(result, source), target);
};

export const cloneDeep = <T>(source: NonNullable<DeepReadonly<T>>) => mergeDeep<NonNullable<T>>({}, source);
