import {
  apiPatch,
  apiPost,
} from '../../../_shared/utils/api/api.helpers';
import {
  type MapSnapshotResponse, removeMap,
} from '../../../map/map.repository';

export type CreateMapSnapshotRequest = Readonly<{
  name: string;
  description?: string;
  settings_override?: string[];
}>;

export type MapSnapshotUpdateModel = Readonly<{
  id: number;
  name: string;
  description: string;
}>;

export const createMapSnapshot = (clientId: number, mapId: number, payload: CreateMapSnapshotRequest):
Promise<{ readonly data: MapSnapshotResponse }> =>
  apiPost(`/api/clients/${clientId}/maps/${mapId}/save`, payload);

export const updateMapSnapshot = (clientId: number, mapId: number, snapshot: MapSnapshotUpdateModel):
Promise<{ readonly data: MapSnapshotResponse }> =>
  apiPatch(`/api/clients/${clientId}/maps/${mapId}/snapshots/${snapshot.id}`, snapshot);

export const deleteMapSnapshot = (clientId: number, snapshotId: number) => removeMap(clientId, { maps: [{ id: snapshotId }] });
