import { css } from '@emotion/react';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import {
  type ExportImageLegendMode, type ExportImageLocationListMode,
} from '../../types/exportImage/exportImage';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  boxSizing: 'border-box',
  padding: '20px 30px',
  width: '100%',
});

const dropdownStyle = (width?: number) => css({
  width: width || 160,
});

const triggerStyle = css({
  paddingLeft: 15,
  textTransform: 'capitalize',
});

export type OptionsDropdownComponentProps<T> = Readonly<{
  availableOptions: DropdownOption<T>[];
  selectedOption: T;
  width?: number;

  onSelectOption: (option: T) => void;
}>;

export const OptionsDropdownComponent = <T extends ExportImageLegendMode | ExportImageLocationListMode>(props: OptionsDropdownComponentProps<T>) => {
  return (
    <div css={wrapperStyle}>
      <RegularDropdownComponent
        css={dropdownStyle(props.width)}
        itemStyle={triggerStyle}
        onChange={props.onSelectOption}
        options={props.availableOptions}
        triggerStyle={triggerStyle}
        value={props.selectedOption}
        isDisabled={props.availableOptions.length < 2}
      />
    </div>
  );
};
