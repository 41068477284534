import { css } from '@emotion/react';
import {
  useCallback, useEffect, useState,
} from 'react';
import {
  InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';

const numericInput = css({
  width: 120,
  flex: 'initial',
});

type ConstraintSliderInputProps = {
  max?: number;
  min?: number;
  value: number;
  onChange: (value: number) => void;
};

export const ConstraintSliderInput = ({
  value,
  onChange,
  min,
  max,
}: ConstraintSliderInputProps) => {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const onChangeHandler = useCallback(() => {
    onChange(Number(tempValue));
  }, [onChange, tempValue]);

  return (
    <InputWithSpinnersComponent
      hideSpinners
      useLocaleFormat
      onChange={setTempValue}
      onBlur={onChangeHandler}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onChangeHandler();
        }
      }}
      valueMin={min}
      valueMax={max}
      value={tempValue}
      css={numericInput}
      size={InputSize.Small}
    />
  );
};
