import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  boundaryDragEditUpdate,
  boundaryDrawOutlineHover,
  boundaryDrawOutlineMouseOut,
  boundaryDrawRemoveButtonHover,
  boundaryDrawRemoveButtonMouseOut,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import {
  useBoundaryDrawActiveSelector, useBoundaryDrawDragActiveSelector,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { type MapShapeInstance } from '../../mapObjects/mapShape/mapShapeModel';
import { BoundaryDragEditComponent } from './boundaryDragEdit.component';

export const BoundaryDragEditContainer: React.FC = () => {
  const dispatch = useDispatch();

  const onOutlineMouseOver = useCallback(() => {
    dispatch(boundaryDrawOutlineHover());
  }, [dispatch]);

  const onOutlineMouseOut = useCallback(() => {
    dispatch(boundaryDrawOutlineMouseOut());
  }, [dispatch]);

  const updateMapShape = useCallback((mapShape: MapShapeInstance) => {
    dispatch(boundaryDragEditUpdate(mapShape));
  }, [dispatch]);

  const onRemoveMouseOver = useCallback(() => {
    dispatch(boundaryDrawRemoveButtonHover());
  }, [dispatch]);

  const onRemoveMouseOut = useCallback(() => {
    dispatch(boundaryDrawRemoveButtonMouseOut());
  }, [dispatch]);

  const boundaryDraw = useBoundaryDrawActiveSelector();
  const isBoundaryDrawDragActive = useBoundaryDrawDragActiveSelector();

  if (!boundaryDraw || !boundaryDraw.mapShapes.length) {
    return null;
  }

  const shapeComponents = boundaryDraw.mapShapes.map(shape => (
    <BoundaryDragEditComponent
      disabled={!isBoundaryDrawDragActive}
      onOutlineMouseOver={onOutlineMouseOver}
      onOutlineMouseOut={onOutlineMouseOut}
      onRemoveMouseOver={onRemoveMouseOver}
      onRemoveMouseOut={onRemoveMouseOut}
      mapShape={shape}
      onChange={updateMapShape}
      key={shape.id}
    />
  ));

  return (
    <>
      {shapeComponents}
    </>
  );
};
