import { css } from '@emotion/react';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  InputWithSpinnersComponent, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import {
  KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { CheckboxComponent } from '../../baseComponents/checkbox';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';

export const LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS = 15;
const MAX_NUM_OF_LOCATIONS = 150;

const contentStyle = css({
  padding: 0,
});

const sectionMarginStyle = css({
  marginBottom: 20,
});

const limitSetterStyle = css({
  display: 'flex',
});

const checkboxStyle = css({
  marginRight: 8,
});

const rootStyle = css({
  fontSize: '14px',
  padding: 20,
});

const headingStyle = css({
  margin: '0 0 10px',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: 1.4,
  textTransform: 'uppercase',
});

const bottomNoteStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  fontSize: '12px',
  lineHeight: 1.2,
  margin: '16px 0 0',
});

type LocationFinderModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onChange: (name: string) => void;
  value: string | '';
  onSubmit: (locationLimit: number | null) => void;
  isLoading: boolean;
}>;

export const LocationFinderModalComponent: React.FC<LocationFinderModalProps> = props => {
  const [numOfLocations, setNumOfLocations] = useState<number | null>(null);
  const [enableCustomNumberOfLocations, setEnableCustomNumberOfLocations] = useState(false);
  const theme = useTheme();
  const [t] = useTranslation();
  const toggleCustomNumber = useCallback(() =>
    setEnableCustomNumberOfLocations(state => !state), []);

  const handleSubmit = useCallback(() => {
    const submit = props.onSubmit;
    submit(enableCustomNumberOfLocations ? numOfLocations : null);
  }, [props.onSubmit, enableCustomNumberOfLocations, numOfLocations]);

  const getTheNumberOfLocationsLoaded = useMemo(() => {
    const baseNumberOfLocations = numOfLocations ? numOfLocations : LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS;
    return baseNumberOfLocations > MAX_NUM_OF_LOCATIONS ? MAX_NUM_OF_LOCATIONS : baseNumberOfLocations;
  }, [numOfLocations]);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: handleSubmit },
    options: { allowActionWhenModalOpen: true, allowInputs: true },
    targetKeys: KeyboardKeys.Enter,
  }), [handleSubmit]));

  return (
    <ModalComponent
      allowedPages={ALL_MAP_RELATED_PAGES}
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Search For The Nearest Location')}
      contentStyle={contentStyle}
      confirmButton={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Primary}
          onClick={handleSubmit}
          text={t('Find Now')}
        />
      )}
      contentMaxHeight="100%"
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <div css={rootStyle}>

        <div css={sectionMarginStyle}>
          <div css={headingStyle}>{t('Enter Starting point')}</div>
          <TextInputComponent
            value={props.value}
            placeholder={t('My Current Location')}
            onChange={props.onChange}
            icon={faLocationArrow}
          />
        </div>

        <div css={sectionMarginStyle}>
          <div css={headingStyle}>{t('Show This Many Locations')}</div>
          <div css={limitSetterStyle}>
            <CheckboxComponent
              css={checkboxStyle}
              checkedSetter={toggleCustomNumber}
              isChecked={enableCustomNumberOfLocations}
            />
            <InputWithSpinnersComponent
              isDisabled={!enableCustomNumberOfLocations}
              onChange={setNumOfLocations}
              value={getTheNumberOfLocationsLoaded}
              valueMin={1}
              valueMax={MAX_NUM_OF_LOCATIONS}
              width={87}
            />
          </div>
        </div>

        <div css={bottomNoteStyle({ theme })} >
          {t('Just click the "FIND NOW" button to find the location that\'s closest to your current location ... OR ... Enter an address, zip code, city, etc, etc..')}
        </div>

      </div>
    </ModalComponent>
  );
};
