import {
  MAP_SETTINGS_FETCH_DATA_SUCCESS, MAP_SETTINGS_REMOVE_ACTIVE_ITEMS,
} from '../data/mapSettingsData.actionTypes';

export const MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS = 'MAP_SETTINGS_GROUPING/SET_ACTIVE_GROUP_COLUMNS';
export const MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN = 'MAP_SETTINGS_GROUPING/REMOVE_ACTIVE_GROUP_COLUMN';
export const MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS = 'MAP_SETTINGS_GROUPING/ADD_GROUP_COLUMNS';

// Consider adding an action to the list if it can update / remove active group columns.
export const MAP_SETTINGS_GROUPING_CHECK_FOR_ACTIVE_GROUP_CHANGES_ACTIONS = [
  MAP_SETTINGS_FETCH_DATA_SUCCESS,
  MAP_SETTINGS_REMOVE_ACTIVE_ITEMS,

  MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
  MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN,
  MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS,
];
