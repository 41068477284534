import { css } from '@emotion/react';
import {
  faImages, faLayerGroup, faMap,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { TextareaScrollableComponent } from '~/_shared/baseComponents/textareaScrollable/textareaScrollable.component';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const nameStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.secondary,
});

type MapBasicInfoProps = Readonly<{
  customLabel?: string;
  description: string | null;
  descriptionErrorMessage?: string;
  isMapLayered?: boolean;
  isSnapshot?: boolean;
  name: string;
  nameErrorMessage?: ReadonlyArray<string>;
  namePlaceholder: string;

  onDescriptionBlur?: () => void;
  onDescriptionChange: (value: string) => void;
  onNameBlur?: () => void;
  onNameChange: (value: string) => void;
  setMapNameInputRef?: (inputRef: HTMLInputElement) => void;
}>;

export const MapBasicInfoComponent: FC<MapBasicInfoProps> = (props) => {
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionRef, setDescriptionRef] = useHookWithRefCallback<HTMLTextAreaElement>();
  const [focusDescription, setFocusDescription] = useState(false);
  const [t] = useTranslation();

  const onAddDescriptionClick = () => {
    setShowDescription(true);
    setFocusDescription(true);
  };

  useEffect(() => {
    if (props.description) {
      setShowDescription(true);
    }
  }, [props.description]);

  useEffect(() => {
    if (!descriptionRef || !showDescription || !focusDescription) {
      return;
    }

    descriptionRef.focus();
    setFocusDescription(false);
  }, [descriptionRef, showDescription, focusDescription]);

  return (
    <div css={wrapperStyle}>
      <FormTextInputComponent
        inputStyle={nameStyle}
        label={props.customLabel || (props.isSnapshot ? t('Map View Name') : t('Map Name'))}
        setInputRef={props.setMapNameInputRef}
        onChange={props.onNameChange}
        placeholder={props.namePlaceholder}
        value={props.name}
        errorMessages={props.nameErrorMessage}
        onBlur={props.onNameBlur}
        icon={props.isSnapshot ? faImages : props.isMapLayered ? faLayerGroup : faMap}
      />

      {!showDescription && (
        <AddMorePlaceholderComponent
          onClick={onAddDescriptionClick}
          title={t('Add Description')}
        />
      )}

      {showDescription && (
        <>
          <TextareaScrollableComponent
            ref={setDescriptionRef}
            value={props.description ?? ''}
            onChange={props.onDescriptionChange}
            placeholder={t('Enter map description (optional)')}
            onBlur={props.onDescriptionBlur}
            height={75}
          />

          {props.descriptionErrorMessage && (
            <FormErrorMessageComponent
              messages={[props.descriptionErrorMessage]}
              isSingleLine={false}
            />
          )}
        </>
      )}
    </div>
  );
};
