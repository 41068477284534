import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useSendNavigationLink } from '~/_shared/hooks/emails/useSendNavigationLink.hook';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type LatLng } from '~/_shared/types/latLng';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { validateEmail } from '~/_shared/utils/throttle/validation/validation.helpers';
import { AppErrorType } from '~/appError/appErrorType.enum';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useUserDataSelector } from '~/store/userData/userData.selectors';

const containerStyle = css({
  overflow: 'hidden',
});

const formStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const labelStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
});

export type SendNavigationLinksModalProps = ModalProps & {
  destinationLatLng: LatLng;
  locationName: string;
};

export const SendNavigationLinksModal: FC<SendNavigationLinksModalProps> = (props) => {
  const { onClose, locationName, destinationLatLng } = props;

  const userEmail = useUserDataSelector().email;

  const dispatch = useDispatch();
  const theme = useTheme();
  const [t] = useTranslation();
  const { openModal: openEmailNotificationModal } = useModal(ModalType.EmailNotification);
  const { sendNavigationLink, isLoading } = useSendNavigationLink();

  const [email, setEmail] = useState(userEmail);
  const [emailErrors, setEmailErrors] = useState<ReadonlyArray<string>>([]);

  const isFormValid = emailErrors.length === 0;

  const validateUserEmail = useCallback(() => {
    if (isTextEmpty(email) || !validateEmail(email)) {
      setEmailErrors([t('Invalid email!')]);
    }
    else {
      setEmailErrors([]);
    }
  }, [email, t]);

  const onSuccessfullySent = useCallback(() => {
    onClose();
    openEmailNotificationModal({});
  }, [onClose, openEmailNotificationModal]);

  const submitForm = useCallback(() => {
    if (!isFormValid) {
      return;
    }
    sendNavigationLink({
      email,
      locationName,
      latLng: destinationLatLng,
    }).then(() => {
      onSuccessfullySent();
    }).catch(() => {
      onClose();
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('shareNavigationLinks.failed.caption'),
        text: t('Please try again. If this problem persists, please contact the customer support.'),
      }));
    });
  }, [destinationLatLng, dispatch, email, isFormValid, locationName, onClose, onSuccessfullySent, sendNavigationLink, t]);

  const onFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    submitForm();
  }, [submitForm]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Email Navigation Link')}
      confirmButton={(
        <>
          <ButtonComponent
            text={t('Send Email')}
            onClick={() => submitForm()}
            isDisabled={!isFormValid}
          />

          {isLoading &&
            <OverlayLoaderComponent />
          }
        </>
      )}
    >
      <div css={containerStyle}>
        <p>{t('shareNavigationLinks.instructions')}</p>

        <form
          onSubmit={onFormSubmit}
          css={formStyle}
        >
          <FormTextInputComponent
            autoTrim
            errorMessages={emailErrors}
            icon={faEnvelope}
            label={t('Email Address')}
            labelStyle={labelStyle({ theme })}
            marginBottom={10}
            onBlur={validateUserEmail}
            onChange={setEmail}
            placeholder={t('Email Address')}
            value={email}
          />
        </form>
      </div>
    </ModalComponent>
  );
};
