import { type DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import {
  FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL,
  FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED,
  FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED,
  FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED,
  FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED,
  FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED,
  FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED,
  FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL,
  FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY,
  FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY,
} from './drawingTool.actionTypes';

export const drawingToolClearActiveTool = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL,
}) as const;

export const drawingToolSetActiveDrawingTool = (tool: Exclude<DrawingTool, DrawingTool.Management>) => ({
  type: FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL,
  payload: {
    tool,
  },
}) as const;

export const drawingToolEnableDrawingMode = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
}) as const;

export const drawingToolDisableDrawingMode = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE,
}) as const;

export const drawingToolItemHovered = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED,
}) as const;

export const drawingToolItemHoverStopped = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED,
}) as const;

export const drawingToolItemResizeStarted = (draggableCursor: string) => ({
  type: FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED,
  payload: {
    draggableCursor,
  },
}) as const;

export const drawingToolItemResizeStopped = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED,
}) as const;

export const drawingToolItemMoveStarted = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED,
}) as const;

export const drawingToolItemMoveStopped = () => ({
  type: FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED,
}) as const;

export const drawingToolSetToolVisibility = (drawingTool: DrawingTool, isVisible: boolean) => ({
  type: FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY,
  payload: {
    drawingTool,
    isVisible,
  },
}) as const;

export const drawingToolSetAllToolsVisibility = (areToolsVisible: boolean) => ({
  type: FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY,
  payload: {
    areToolsVisible,
  },
}) as const;
