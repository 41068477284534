import { useCallback } from 'react';
import { delay } from '~/_shared/utils/delay';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

// Get a unique id
const attributionElementId = `attributionElement_${Math.random().toString(36).split('.')[1]}`;

export const useAddMapAttributionForPresentationalMap = () => {
  const [t] = useTranslation();
  const isPresentationalMap = useIsMapPresentationalSelector();
  const attributionElementMarkup = `
        <div draggable="false" class="gm-style-cc" style="user-select: none; position: relative; height: 14px; line-height: 14px;">
          <div style="opacity: 0.7; width: 100%; height: 100%; position: absolute;">
            <div style="width: 1px;"></div>
            <div style="background-color: rgb(245, 245, 245); width: auto; height: 100%; margin-left: 1px;"></div>
          </div>
          <div style="position: relative; padding-right: 6px; padding-left: 6px; box-sizing: border-box; font-family: Roboto, Arial, sans-serif; font-size: 10px; color: rgb(0, 0, 0); white-space: nowrap; direction: ltr; text-align: right; vertical-align: middle; display: inline-block;">
            <a href="https://www.maptive.com" draggable="false" aria-label="${t('Created using Maptive')}" title="${t('Created using Maptive')}" type="button" style="background: none; display: inline-block; border: 0px; margin: 0px; padding: 0px; text-transform: none; text-decoration:none; appearance: none; position: relative; cursor: pointer; user-select: none; color: rgb(0, 0, 0); font-family: inherit; line-height: inherit;" target="_blank" rel="nofollow">
              ${t('Maptive')}
            </a>
          </div>
        </div>
      `;

  const addMapAttribution = useCallback(() => {
    if (!isPresentationalMap) {
      return;
    }

    const addMaptiveAttribution = async () => {
      const gmnoScreenContainer = document.getElementsByClassName('gmnoscreen').item(0)?.parentElement;
      const isAttributionAlreadySet = !!document.getElementById(attributionElementId);

      if (isAttributionAlreadySet) {
        return;
      }

      if (gmnoScreenContainer) {
        const attributeElement = document.createElement('div');
        attributeElement.className = 'gmnoprint';
        attributeElement.id = attributionElementId;
        attributeElement.style.zIndex = '1000001';
        attributeElement.innerHTML = attributionElementMarkup;

        gmnoScreenContainer.prepend(attributeElement);
      }
      else {
        await delay(200);
        addMaptiveAttribution();
      }
    };

    addMaptiveAttribution();
  }, [attributionElementMarkup, isPresentationalMap]);

  return {
    addMapAttribution,
  };
};
