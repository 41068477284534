import {
  apiGet,
  apiPost,
} from '../_shared/utils/api/api.helpers';

export type AnnouncementResponse = Readonly<{
  button: {
    icon: string | null;
    link: string;
    style: AnnouncementResponseButtonStyles;
    title: string;
  } | null;
  colors: {
    backgroundColor: string;
    color: string;
    icon_color: string | null;
    overriden_color: string | null;
  };
  display_pages: string;
  display_users: string;
  from: string;
  icon: string | null;
  id: number;
  message: string;
  message_font_size: number;
  subject: string;
  title_font_size: number;
  to: string;
}>;

export type AnnouncementResponseButtonStyles = 'success' | 'primary' | 'danger';

export type MarkAsSeenData = {
  message: string;
};

export const markAsSeen = (clientId: number, userId: number, announcementId: number): Promise<{data: MarkAsSeenData}> => {
  const requestUrl = `/api/clients/${clientId}/user/${userId}/announcements/${announcementId}/mark-as-seen`;

  return apiPost(requestUrl);
};

export const getAnnouncements = (clientId: number, userId: number): Promise<{data: AnnouncementResponse[]}> => {
  const requestUrl = `/api/clients/${clientId}/user/${userId}/announcements`;

  return apiGet(requestUrl);
};
