import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { type LatLng } from '~/_shared/types/latLng';
import { copyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { useTranslation } from '~/_shared/utils/hooks';
import { DirectionSourceType } from '~/store/frontendState/mapTools/directions/directions.state';
import { DistanceCalculatorDrawingModeEnum } from '~/store/frontendState/mapTools/distanceCalculator/distanceCalculator.state';

const menuTriggerStyle = ({ x, y }: {x: number; y: number}) => css({
  position: 'absolute',
  top: y,
  left: x,
});

const menuItemStyle = css({
  fontSize: '12px',
});

const submenuItemStyle = css({
  fontSize: '12px',
});

const textMenuItemStyle = css({
  cursor: 'default',
});

const moreInfoSubMenuStyle = css({
  display: 'inline-block',
  width: '100%',
});

type MapContextMenuProps = Readonly<{
  address: string;
  googleMap: google.maps.Map;
  isAddRadiusAllowed: boolean;
  isDistanceMeasuringAllowed: boolean;
  isAddMarkerAllowed: boolean;
  isFindClosestLocationAllowed: boolean;
  isGetDirectionsAllowed: boolean;
  isLoading: boolean;
  isStreetViewAvailable: boolean;
  latitude: number;
  longitude: number;

  addMarker: () => void;
  addProximityStart: () => void;
  addWaypoint: (directionSourceType: DirectionSourceType) => void;
  findClosestLocation: () => void;
  getAddress: () => void;
  openStreetView: () => void;
  startDistanceCalculator: (mode: DistanceCalculatorDrawingModeEnum) => void;
  setAddress: (address: string) => void;
  setLatLng: (LatLng: LatLng) => void;
}>;

export const MapContextMenuComponent: FC<MapContextMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const { addMarker, addWaypoint, addProximityStart, findClosestLocation,
    openStreetView, startDistanceCalculator, setLatLng } = props;

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleGetDirections = useCallback((directionSourceType: DirectionSourceType) =>
    () => addWaypoint(directionSourceType),
  [addWaypoint]);

  const copyCoordinatesToClipboard = () => {
    copyToClipboard(`${props.latitude}, ${props.longitude}`);
  };

  const copyAddressToClipboard = () => {
    copyToClipboard(props.address);
  };

  const handleDistanceCalculator = useCallback((mode: DistanceCalculatorDrawingModeEnum) =>
    () => startDistanceCalculator(mode),
  [startDistanceCalculator]);

  useEffect(() => {
    const listener = google.maps.event.addListener(props.googleMap, 'contextmenu', function (event: google.maps.MapMouseEvent) {
      setIsOpen(false);

      if (event.latLng) {
        setLatLng(googleLatLngToLocal(event.latLng));

        const mouseEvent = event.domEvent as MouseEvent;
        if (mouseEvent && mouseEvent.target && mouseEvent.clientX && mouseEvent.clientY) {
          const rect = (mouseEvent.target as HTMLElement).getBoundingClientRect();
          setMenuPosition({
            x: Math.floor((mouseEvent.clientX - rect.left) / (rect.right - rect.left) * rect.width),
            y: Math.floor((mouseEvent.clientY - rect.top) / (rect.bottom - rect.top) * rect.height),
          });

          setIsOpen(true);
        }
      }
    });

    return () => {
      setIsOpen(false);
      google.maps.event.removeListener(listener);
    };
  }, [props.googleMap, setLatLng]);

  return (
    <div>
      <ContextMenuComponent
        isVisible={isOpen}
        onHide={closeMenu}
        preferredPlacement="bottom-start"
        maxWidth={300}
        renderMenuContent={() => (
          <>
            {props.isLoading &&
              <OverlayLoaderComponent />
            }
            {props.isAddMarkerAllowed && (
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                  onClick={addMarker}
                >
                  {t('Add Marker')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}

            {props.isAddRadiusAllowed && (
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                  onClick={addProximityStart}
                >
                  {t('Add Radius')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}

            {props.isGetDirectionsAllowed && (
              <ContextMenuItemCoreComponent
                renderSubmenu={() => (
                  <>
                    <ContextMenuItemCoreComponent>
                      <ContextMenuDefaultItem
                        itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                        onClick={handleGetDirections(DirectionSourceType.From)}
                      >
                        {t('From Here')}
                      </ContextMenuDefaultItem>
                    </ContextMenuItemCoreComponent>
                    <ContextMenuItemCoreComponent>
                      <ContextMenuDefaultItem
                        itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                        onClick={handleGetDirections(DirectionSourceType.To)}
                      >
                        {t('To Here')}
                      </ContextMenuDefaultItem>
                    </ContextMenuItemCoreComponent>
                  </>
                )}
              >
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                >
                  {t('Routing / Directions')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}

            {props.isFindClosestLocationAllowed && (
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  itemStyle={menuItemStyle}
                  onClick={findClosestLocation}
                >
                  {t('Find The Closest Locations')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}

            {props.isDistanceMeasuringAllowed && (
              <ContextMenuItemCoreComponent
                renderSubmenu={() => (
                  <>
                    <ContextMenuItemCoreComponent>
                      <ContextMenuDefaultItem
                        itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                        onClick={handleDistanceCalculator(DistanceCalculatorDrawingModeEnum.SinglePoint)}
                      >
                        {t('Single Point (A to B)')}
                      </ContextMenuDefaultItem>
                    </ContextMenuItemCoreComponent>
                    <ContextMenuItemCoreComponent>
                      <ContextMenuDefaultItem
                        itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                        onClick={handleDistanceCalculator(DistanceCalculatorDrawingModeEnum.MultiplePoint)}
                      >
                        {t('Multiple Point')}
                      </ContextMenuDefaultItem>
                    </ContextMenuItemCoreComponent>
                  </>
                )}
              >
                <ContextMenuDefaultItem itemStyle={menuItemStyle}>
                  {t('Measure Distance')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )}

            <ContextMenuItemCoreComponent
              submenuStyle={moreInfoSubMenuStyle}
              subMenuMaxWidth={300}
              renderSubmenu={() => (
                <>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                    >
                      {t('Latitude')}: {props.latitude}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                    >
                      {t('Longitude')}: {props.longitude}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                    >
                      {t('Address')}: {props.address}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={css(textMenuItemStyle, submenuItemStyle)}
                      onClick={openStreetView}
                      isDisabled={!props.isStreetViewAvailable}
                    >
                      {t(props.isStreetViewAvailable ? 'Show Street View' : 'Street View Not Available')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={submenuItemStyle}
                      onClick={copyAddressToClipboard}
                    >
                      {t('Copy Address to Clipboard')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      itemStyle={submenuItemStyle}
                      onClick={copyCoordinatesToClipboard}
                    >
                      {t('Copy Coordinates to Clipboard')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                </>
              )}
            >
              <ContextMenuDefaultItem itemStyle={menuItemStyle} >
                {t('More about location')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          </>
        )}
      >
        {ref => (
          <span
            ref={ref}
            css={menuTriggerStyle(menuPosition)}
          />
        )}
      </ContextMenuComponent>
    </div>
  );
};
