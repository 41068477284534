import { css } from '@emotion/react';
import {
  faCheck, faPlay, faStop, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTranslation } from '../../../_shared/utils/hooks';
import {
  isCustomGroup, useBoundaryGroupsSelector,
} from '../../../store/boundaryGroups/boundaryGroups.selectors';
import {
  boundarySelectEditActivate, boundarySelectEditReset, boundarySelectSetLassoOverlap,
  boundarySelectSetSelectType,
} from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import {
  useBoundarySelectIsLassoLoadingSelector, useBoundarySelectModalOpenSelector,
} from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import {
  BoundarySelectType,
  SelectLassoOverlap,
} from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import { BoundarySelectConfirmComponent } from './boundarySelectConfirm.component';
import { getSelectBoundaryTypeOptions } from './boundarySelectConfirm.helpers';
import { useBoundarySelectSubmitModal } from './useBoundarySelectSubmitModal';

const buttonsWrapperStyle = ({ buttonsCount }: { buttonsCount: number }) => css({
  display: 'flex',
  justifyContent: buttonsCount <= 1 ? 'flex-end' : 'space-between',
});

export const BoundarySelectConfirmContainer: FC = () => {
  const { openBoundarySelectSubmitModal, closeBoundarySelectModal } = useBoundarySelectSubmitModal();
  const boundarySelectState = useBoundarySelectModalOpenSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const isBoundarySelectLassoLoading = useBoundarySelectIsLassoLoadingSelector();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isStartingView = !boundarySelectState?.isActive;

  const onApply = useCallback(() => {
    if (!boundarySelectState?.selectedBoundaryIds.size) {
      return;
    }

    openBoundarySelectSubmitModal();
  }, [boundarySelectState, openBoundarySelectSubmitModal]);

  const onResetClick = useCallback(() => {
    dispatch(boundarySelectEditReset());
  }, [dispatch]);

  const onSelectTypeChange = useCallback((selectType: BoundarySelectType) => {
    dispatch(boundarySelectSetSelectType(selectType));
  }, [dispatch]);

  const onLassoOverlapChange = useCallback((overlap: SelectLassoOverlap) => {
    dispatch(boundarySelectSetLassoOverlap(overlap));
  }, [dispatch]);

  const onStartButtonClick = useCallback(() => {
    dispatch(boundarySelectEditActivate());
  }, [dispatch]);

  const buttons = useMemo(() => {
    if (!boundarySelectState) {
      return [];
    }

    if (isStartingView) {
      return [{
        icon: faPlay,
        buttonStyle: ButtonStyle.Success,
        onClick: onStartButtonClick,
        label: t('Start'),
      }];
    }
    else {
      if (boundarySelectState.selectedBoundaryIds.size === 0) {
        return [{
          icon: faStop,
          buttonStyle: isBoundarySelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Danger,
          onClick: onResetClick,
          label: t('Stop'),
          isDisabled: isBoundarySelectLassoLoading,
        }, {
          icon: faCheck,
          buttonStyle: isBoundarySelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Primary,
          label: t('Save'),
          isDisabled: true,
        }];
      }
      else {
        return [{
          icon: faTrash,
          buttonStyle: isBoundarySelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Danger,
          onClick: onResetClick,
          label: t('Reset'),
          isDisabled: isBoundarySelectLassoLoading,
        }, {
          icon: faCheck,
          buttonStyle: isBoundarySelectLassoLoading ? ButtonStyle.Secondary : ButtonStyle.Primary,
          onClick: onApply,
          label: t('Save'),
          isDisabled: false,
        }];
      }
    }
  }, [boundarySelectState, isBoundarySelectLassoLoading, isStartingView,
    onApply, onResetClick, onStartButtonClick, t]);

  if (!boundarySelectState) {
    return null;
  }

  const options = getSelectBoundaryTypeOptions(t,
    boundarySelectState.allowMultipleSelection
      ? [BoundarySelectType.Click, BoundarySelectType.HoverIdle, BoundarySelectType.Lasso]
      : [BoundarySelectType.Click]
  );

  const boundaryGroup = boundaryGroups.find(group => group.id === boundarySelectState.selectBoundaryGroupId);
  const boundaryIsCustom = boundaryGroup && isCustomGroup(boundaryGroup);

  let heading: string;

  if (isStartingView) {
    if (boundaryIsCustom) {
      heading = t('Territory Selection Tool');
    }
    else {
      heading = t('Boundary Selection Tool');
    }
  }
  else {
    if (boundarySelectState.selectedBoundaryIds.size === 0) {
      heading = t('Start Selecting Now');
    }
    else {
      heading = t('Select & Save');
    }
  }

  let selectType = boundarySelectState.selectType;

  // we don't want double "On Hover" option in the list so we only have HoverIdle on the list
  // that's why we need to map HoverActive to HoverIdle to have proper dropdown option displayed
  if (selectType === BoundarySelectType.HoverActive) {
    selectType = BoundarySelectType.HoverIdle;
  }

  let highlightedStepNumber;

  if (!boundarySelectState.isActive) {
    highlightedStepNumber = 1;
  }
  else {
    if (boundarySelectState.selectType === BoundarySelectType.Click) {
      highlightedStepNumber = 2;
    }

    if (boundarySelectState.selectType === BoundarySelectType.Lasso) {
      highlightedStepNumber = 3;
    }

    if (boundarySelectState.selectType === BoundarySelectType.HoverIdle) {
      highlightedStepNumber = 2;
    }

    if (boundarySelectState.selectType === BoundarySelectType.HoverActive) {
      highlightedStepNumber = 3;
    }
  }

  return (
    <BoundarySelectConfirmComponent
      dropdownHeading={t('Choose Selection Method')}
      isOpen
      highlightedStepNumber={highlightedStepNumber}
      onClose={closeBoundarySelectModal}
      dropdownOptions={options}
      selectedOptionId={selectType}
      onDropdownSelect={onSelectTypeChange}
      radio={selectType !== BoundarySelectType.Lasso ? undefined : {
        heading: t('Include only:'),
        selectedValue: boundarySelectState.selectLassoOverlap,
        onChange: onLassoOverlapChange,
        items: [{
          label: boundaryIsCustom ? t('Full territories only') : t('Full boundaries only'),
          value: SelectLassoOverlap.Contains,
          isDisabled: isBoundarySelectLassoLoading,
        }, {
          label: boundaryIsCustom ? t('Full & partial territories') : t('Full & partial boundaries'),
          value: SelectLassoOverlap.Partial,
          isDisabled: isBoundarySelectLassoLoading,
        }],
      }}
      buttonsWrapperStyle={buttonsWrapperStyle({ buttonsCount: buttons.length })}
      buttons={buttons}
      heading={heading}
    />
  );
};
