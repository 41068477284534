import { css } from '@emotion/react';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { TooltipBehavior } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { copyToClipboard } from '~/_shared/utils/clipboard/clipboard.helpers';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  AccordionComponent,
  type AccordionData,
} from '../../baseComponents/accordion';
import { TextareaComponent } from '../../baseComponents/textarea/textarea.component';
import { useTranslation } from '../../utils/hooks';
import { MapPrivacySelectorComponent } from '../mapPrivacySelector/mapPrivacySelector.component';
import {
  type ManageMapPrivacyCallbacks,
  type ManageMapPrivacyErrors,
  type ManageMapPrivacyState,
} from '../mapPrivacySelector/useManageMapPrivacy.hook';
import { MapPrivacySelectInputField } from '../mapPrivacySelectorModal/mapPrivacySelectorInputField.enum';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';

export enum EmbedMapSection {
  PrivacyLevel = 'PrivacyLevel',
  EmbedCode = 'EmbedCode',
}

const rootStyle = css({
  fontSize: '14px',
  padding: 0,
});

const accordionPanelStyle = css({
  padding: '20px 20px',
});

const embedMapInstructionsStyle = css({
  marginTop: 0,
});

const accordionItemStyle = css({
  textTransform: 'uppercase',
});

const textAreaStyle = css({
  width: '100%',
  height: 102,
});

const copyButtonContainerStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginTop: 20,
});

type EmbedMapModalComponentProps = ModalProps<{
  embedMapLink: string;
  expandedSections: ReadonlySet<EmbedMapSection>;
  isFormValid: boolean;
  isLoading: boolean;
  manageMapPrivacyProps: ManageMapPrivacyState & ManageMapPrivacyErrors & ManageMapPrivacyCallbacks;

  onToggleSection: (section: EmbedMapSection) => void;
}>;

export const EmbedMapModalComponent: FC<EmbedMapModalComponentProps> = props => {
  const { manageMapPrivacyProps, embedMapLink } = props;

  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);

  const [t] = useTranslation();

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(embedMapLink);
    showCopiedToClipboardMessage();
  }, [embedMapLink, showCopiedToClipboardMessage]);

  const privacyLevelSection: AccordionData = {
    isExpanded: props.expandedSections.has(EmbedMapSection.PrivacyLevel),
    onHeadingClick: () => props.onToggleSection(EmbedMapSection.PrivacyLevel),
    header: t('Select Privacy Level'),
    child: (
      <MapPrivacySelectorComponent
        disabledPrivacyLevels={manageMapPrivacyProps.disabledPrivacyLevels}
        onChange={manageMapPrivacyProps.onPrivacyLevelChange}
        onClearPasswordError={() => manageMapPrivacyProps.clearFieldError(MapPrivacySelectInputField.Password)}
        onIsFormValidChanged={manageMapPrivacyProps.setIsFormValid}
        passwordError={manageMapPrivacyProps.fieldErrors.get(MapPrivacySelectInputField.Password)?.[0] ?? undefined}
        privacyLevel={manageMapPrivacyProps.privacyLevel}
        globalError={manageMapPrivacyProps.globalError}
        onClearGlobalError={manageMapPrivacyProps.clearGlobalError}
      />
    ),
  };

  const embedCodeSection: AccordionData = {
    isExpanded: props.expandedSections.has(EmbedMapSection.EmbedCode),
    onHeadingClick: () => props.onToggleSection(EmbedMapSection.EmbedCode),
    header: t('Embed Map Code'),
    child: (
      <div>
        <p css={embedMapInstructionsStyle}>{t('EmbedMap.EmbedCodeInstructions')}</p>
        <TextareaComponent
          css={textAreaStyle}
          value={props.embedMapLink}
          isReadOnly
        />
        <div css={copyButtonContainerStyle}>
          <TooltipDeprComponent
            tooltipContent={isCopiedToClipboard ? t('Copied to Clipboard!') : null}
            behavior={TooltipBehavior.ShowAlways}
          >
            <ButtonComponent
              prefixIcon={faCopy}
              text={t('Copy')}
              onClick={handleCopyToClipboard}
            />
          </TooltipDeprComponent>
        </div>
      </div>
    ),
  };

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Embed Map on Website, Blog, etc.')}
      contentStyle={rootStyle}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={[privacyLevelSection, embedCodeSection]}
          itemButtonStyle={accordionItemStyle}
          panelStyle={accordionPanelStyle}
          showCount
        />
      </OutlinePanelComponent>
    </ModalComponent>
  );
};
