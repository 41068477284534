import {
  type RefObject,
  useEffect, useRef, useState,
} from 'react';
import { isDomNode } from '../dom/dom.helpers';

export const useClickOutsideListenerRef = (onClose: () => void, isActive: boolean) => {
  const ref = useRef(null);
  useClickOutsideListenerRefs(onClose, isActive, [ref]);
  return ref;
};

export const useClickOutsideListenerRefs = (onClickOutside: () => void, isActive: boolean, excludedElements: readonly RefObject<(HTMLElement | null | undefined)>[]) => {
  const onCloseRef = useRef(onClickOutside);
  const excludedRefs = useRef(excludedElements);
  const [isActiveState, setActiveState] = useState(false);

  excludedRefs.current = excludedElements;
  onCloseRef.current = onClickOutside;

  useEffect(() => {
    setTimeout(() => {
      setActiveState(isActive);
    }, 0); // Wait for the stuff to render first, then activate the click outside observer.
    // This wouldn't be needed if the trigger was always properly excluded. Alas, that is not the case.
  }, [isActive]);

  useEffect(() => {
    if (!document) {
      return;
    }

    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseRef.current();
      }
    };

    const clickListener = (e: MouseEvent) => {
      if (isDomNode(e.target) && !e.target?.isConnected) {
        return;
      }
      const isClickExcluded = excludedRefs.current.some(ref => e.target && ref.current?.contains(e.target as Node));

      if (!isClickExcluded) {
        onCloseRef.current();
      }
    };

    if (isActiveState) {
      document.addEventListener('click', clickListener);
      document.addEventListener('contextmenu', clickListener); // right click
      document.addEventListener('keyup', escapeListener);

      return () => {
        document.removeEventListener('click', clickListener);
        document.removeEventListener('contextmenu', clickListener);
        document.removeEventListener('keyup', escapeListener);

      };
    }

    return;
  }, [isActiveState]);
};
