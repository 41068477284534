import { type FC } from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

type CircleArrowFatProps = {
  circleColor: string;
  arrowColor: string;
  dimensions: QuadrilateralDimensions;
};

export const CircleArrowFatComponent: FC<CircleArrowFatProps> = (props) => (
  <svg
    width={props.dimensions.width}
    height={props.dimensions.height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7288 61.2881C47.4549 61.2881 60.2034 47.9973 60.2034 32.2712C60.2034 16.5451 47.4549 3.25424 31.7288 3.25424C16.0027 3.25424 3.25424 16.5451 3.25424 32.2712C3.25424 47.9973 16.0027 61.2881 31.7288 61.2881ZM16.6701 26.5763C14.7724 26.5763 15.0436 28.7458 15.5861 29.2881L30.7777 49.8983C31.5913 50.7119 32.4048 50.7119 33.2184 49.8983L48.1337 29.2881C48.6759 28.7458 48.9419 26.5763 47.3144 26.5763H39.7211V15.1864C39.7211 14.1017 38.6364 13.0169 37.5517 13.0169H26.1618C25.0771 13.0169 23.9923 14.1017 23.9923 15.1864V26.5763H16.6701Z"
      fill={props.circleColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM15.5861 29.2881C15.0436 28.7458 14.7724 26.5763 16.6701 26.5763H23.9923V15.1864C23.9923 14.1017 25.0771 13.0169 26.1618 13.0169H37.5517C38.6364 13.0169 39.7211 14.1017 39.7211 15.1864V26.5763H47.3144C48.9419 26.5763 48.6759 28.7458 48.1337 29.2881L33.2184 49.8983C32.4048 50.7119 31.5913 50.7119 30.7777 49.8983L15.5861 29.2881ZM31.7288 61.8305C47.7544 61.8305 60.7458 48.5963 60.7458 32.2712C60.7458 15.946 47.7544 2.71186 31.7288 2.71186C15.7032 2.71186 2.71186 15.946 2.71186 32.2712C2.71186 48.5963 15.7032 61.8305 31.7288 61.8305ZM60.2034 32.2712C60.2034 47.9973 47.4549 61.2881 31.7288 61.2881C16.0027 61.2881 3.25424 47.9973 3.25424 32.2712C3.25424 16.5451 16.0027 3.25424 31.7288 3.25424C47.4549 3.25424 60.2034 16.5451 60.2034 32.2712Z"
      fill={props.circleColor}
    />
    <path
      d="M16.6702 26.5763C14.7725 26.5763 15.0437 28.7458 15.5862 29.2882L30.7778 49.8983C31.5914 50.7119 32.4049 50.7119 33.2185 49.8983L48.1337 29.2882C48.676 28.7458 48.942 26.5763 47.3144 26.5763H39.7212V15.1865C39.7212 14.1017 38.6365 13.017 37.5517 13.017H26.1619C25.0772 13.017 23.9924 14.1017 23.9924 15.1865V26.5763H16.6702Z"
      fill={props.arrowColor}
    />
  </svg>
);
