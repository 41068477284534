import type { UserDataAction } from '~/store/userData/userData.action';
import { USER_SIGN_OUT_SUCCESS } from '~/store/userData/userData.actionTypes';
import type { UserEventAction } from '~/store/userEvents/userEvents.action';
import { trackUserEvent } from '~/store/userEvents/userEvents.actionCreator';
import { MAX_USER_EVENTS_RETAINED_IN_STORE } from '~/store/userEvents/userEvents.constants';
import type {
  AnyUserEvent, UserEventMapByType,
} from '~/store/userEvents/userEvents.type';

type UserEventsState = {
  readonly events: readonly AnyUserEvent[];
  readonly lastEventsOfType: UserEventMapByType;
};

const initialState: UserEventsState = {
  events: [],
  lastEventsOfType: {},
};

export const userEventsReducer = (state: UserEventsState = initialState, action: UserEventAction|UserDataAction): UserEventsState => {
  if (trackUserEvent.match(action)) {
    return {
      ...state,
      events: [...state.events, action.payload].slice(0, MAX_USER_EVENTS_RETAINED_IN_STORE - 1),
      lastEventsOfType: {
        ...state.lastEventsOfType,
        [action.payload.eventType]: action.payload,
      },
    };
  }

  switch (action.type) {
    case USER_SIGN_OUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
