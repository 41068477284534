import { css } from '@emotion/react';
import {
  type FC, type ReactNode, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { type UploadStatus } from '../../../_shared/components/uploadStatus/uploadStatus.component';
import { type UploadItem } from '../../createNew/createNewMap.component';
import { createUploadItemFromFile } from '../../createNew/createNewMap.helpers';
import { SelectSpreadsheetComponent } from '../selectSpreadsheet.component';

export const MAXIMUM_SPREADSHEET_FILE_SIZE_MB = 20;

type SelectSpreadsheetWithErrorProps = Readonly<{
  error?: ReactNode;
  onErrorMessageClose?: () => void;
  isLoading: boolean;
  onSelect: (file: File, onProgressChange: (progress: number) => void, onStatusChange: (status: UploadStatus) => void) => void;
}>;

const alertStyle = css({
  marginTop: 20,
});

export const SelectSpreadsheetWithErrorComponent: FC<SelectSpreadsheetWithErrorProps> = (props) => {
  const [uploadItem, setUploadItem] = useState<UploadItem | null>(null);

  const onUploadItemChange = (id: string, partial: Partial<UploadItem>) => {
    setUploadItem(item => {
      if (!item || item.id !== id) {
        return item;
      }

      return {
        ...item,
        ...partial,
      };
    });
  };

  const onUploadItemRemove = (itemId: string) => {
    props.onErrorMessageClose?.();

    if (!uploadItem) {
      return;
    }

    if (uploadItem.id === itemId) {
      setUploadItem(null);
    }
  };

  const onUploadItemRetry = (itemId: string) => {
    if (uploadItem?.id === itemId) {
      const newUploadItem = createUploadItemFromFile(uploadItem.file);
      onUpdateItemsSelect([newUploadItem]);
    }
  };

  const onUpdateItemsSelect = (items: UploadItem[]) => {
    const newUploadItem = items.length === 0 ? null : items[items.length - 1];

    setUploadItem(newUploadItem);

    if (newUploadItem === null) {
      return;
    }

    const onProgressChange = (progress: number) => {
      onUploadItemChange(newUploadItem.id, { progress });
    };

    const onStatusChange = (status: UploadStatus) => {
      onUploadItemChange(newUploadItem.id, { status });
    };

    props.onSelect(newUploadItem.file, onProgressChange, onStatusChange);
  };

  return (
    <div>
      <SelectSpreadsheetComponent
        uploadItems={uploadItem ? [uploadItem] : []}
        isDisabled={props.isLoading}
        isMulti={false}
        isRemoveDisabled={props.isLoading}
        onUploadItemRemove={onUploadItemRemove}
        onUploadItemRetry={onUploadItemRetry}
        onUploadItemsSelect={onUpdateItemsSelect}
      />
      {props.error && (
        <AlertComponent
          css={alertStyle}
          type="danger"
          onClose={props.onErrorMessageClose}
        >
          {props.error}
        </AlertComponent>
      )}
    </div>
  );
};
