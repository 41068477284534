import {
  useCallback, useMemo,
} from 'react';
import { type BoundingBox } from '~/map/map/boundingBox';
import { useMapSettingsDataSelector } from '~/map/map/useMapSettings.hook';
import {
  useMapComponentCenterSelector, useMapComponentZoomSelector,
} from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { type MapSettingsToolsState } from '~/store/mapSettings/toolsState/mapSettingsToolsState';
import { useMapSettingsToolsStateSelector } from '~/store/mapSettings/toolsState/mapSettingsToolsState.selectors';
import { useIsCurrentMapSnapshotSelector } from '~/store/selectors/isCurrentMapSnapshot.selector';
import { getCreateMapSnapshotSettingsOverrides } from './saveMapView.helpers';

export const useSnapshotSettingsOverrides = () => {
  const isSnapshot = useIsCurrentMapSnapshotSelector();
  const lastZoom = useMapComponentZoomSelector();
  const lastCenter = useMapComponentCenterSelector();
  const currentToolsState = useMapSettingsToolsStateSelector();
  const currentSettings = useMapSettingsDataSelector();

  const getSnapshotSettingsOverrides = useCallback((
    newState: MapSettingsToolsState,
    lastBounds: BoundingBox,
    newPublicMapSettings?: MapSettingsPublicMapSettingsState,
  ) => (
    getCreateMapSnapshotSettingsOverrides(
      isSnapshot ? currentToolsState : {},
      newState,
      currentSettings,
      { lastBounds, lastCenter, lastZoom: lastZoom || 0 },
      newPublicMapSettings,
    )
  ), [currentSettings, currentToolsState, isSnapshot, lastCenter, lastZoom]);

  return useMemo(() => ({
    getSnapshotSettingsOverrides,
  }), [getSnapshotSettingsOverrides]);
};
