import { css } from '@emotion/react';
import { type FC } from 'react';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { MembersTableComponent } from './membersTable/membersTable.component';
import { NewMemberFormContainer } from './newMemberForm/newMemberForm.container';
import { type TeamMemberLimitsData } from './teamManagement.repository';
import { type InvitationAndMemberData } from './teamManagementModal.container';

const modalContentStyle = css({
  padding: '0',
  width: '100%',
});

type TeamManagementModalComponentProps = ModalProps<{
  isLoading: boolean;
  data: InvitationAndMemberData[];
  limits?: TeamMemberLimitsData;
}>;

export const TeamManagementModalComponent: FC<TeamManagementModalComponentProps> = ({
  isLoading,
  data,
  limits,
  ...modalProps
}) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      {...modalProps}
      additionalContent={isLoading && <OverlayLoaderComponent />}
      caption={t('Manage Users')}
      contentStyle={modalContentStyle}
      maxWidth="980px"
      skipCloseOnMapIdChange
    >
      <NewMemberFormContainer
        teamMemberLimits={limits}
      />
      {!!limits && (
        <MembersTableComponent
          data={data}
          limits={limits}
        />
      )}
    </ModalComponent>
  );
};
