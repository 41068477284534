import { css } from '@emotion/react';
import { type ThemeProps } from '../../../types/themeProps';

export const inputStyle = ({ theme, hasError }: ThemeProps<{ hasError: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  boxShadow: hasError ? `0 0 6px 2px ${theme.borderColors.error}` : undefined,
  borderColor: hasError ? theme.borderColors.error : undefined,
  fontSize: 16,

  '&:focus-within': {
    background: theme.backgroundColors.secondary,
    boxShadow: `0 0 6px 2px ${theme.borderColors.primaryFocused}`,
  },
});

export const labelStyles = ({ theme }: ThemeProps) => css({
  display: 'flex',
  color: theme.textColors.primary,
  fontSize: 16,
  lineHeight: 1.15,
  alignItems: 'center',
  marginBottom: 2,
});
