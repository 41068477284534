import { css } from '@emotion/react';
import { type FC } from 'react';
import { type Writable } from 'ts-essentials';
import { type DemographicsRegion } from '../../../../store/demographics/demographics.repository';
import { type SpreadsheetColumn } from '../../../types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../utils/hooks';
import { TabsComponent } from '../../tabs/tabs.component';
import {
  type TabData, type TabsData,
} from '../../tabs/tabs.types';
import { type SelectedDataMetric } from '../selectedDataMetric.type';
import { CustomizeMetricsSelectDataDemographicsComponent } from './customizeMetricsSelectDataDemographics.component';
import { CustomizeMetricsSelectDataMetricsComponent } from './customizeMetricsSelectDataMetrics.component';

type CustomizeMetricsSelectDataProps = Readonly<{
  addSelectionButtonText: string;
  columnDataSources: ReadonlyArray<SpreadsheetColumn>;
  demographicsRegions: DemographicsRegion[];
  isPopupOpen: boolean;
  selectedData: SelectedDataMetric[];

  onSubmit: (newData: SelectedDataMetric[]) => void;
}>;

const tabContentStyle = css({
  padding: '0 16px',
});

export const CustomizeMetricsSelectDataComponent: FC<CustomizeMetricsSelectDataProps> = (props) => {
  const [t] = useTranslation();

  const dataMetricsTab: TabData = {
    header: t('Data Metrics').toUpperCase(),
    index: 0,
    child: (
      <CustomizeMetricsSelectDataMetricsComponent
        addSelectionButtonText={props.addSelectionButtonText}
        columnDataSources={props.columnDataSources}
        css={tabContentStyle}
        onSubmit={props.onSubmit}
        selectedData={props.selectedData}
      />
    ),
  };

  const demographicTab: TabData = {
    header: t('DemographicCensusData').toUpperCase(),
    index: 1,
    child: (
      <CustomizeMetricsSelectDataDemographicsComponent
        addSelectionButtonText={props.addSelectionButtonText}
        css={tabContentStyle}
        demographicsRegions={props.demographicsRegions}
        onSubmit={props.onSubmit}
        selectedData={props.selectedData}
      />
    ),
  };

  const tabsData: Writable<TabsData> = [dataMetricsTab];
  if (props.demographicsRegions.length > 0) {
    tabsData.push(demographicTab);
  }

  if (!props.isPopupOpen) {
    return null;
  }

  return (
    <TabsComponent
      data={tabsData}
    />
  );
};
