// https://developers.google.com/maps/documentation/javascript/examples/maptype-base
export class CoordinateMapType {
  tileSize: google.maps.Size;
  maxZoom = 19;
  name = 'Tile #s';
  alt = 'Tile Coordinate Map Type';

  constructor(tileSize: google.maps.Size) {
    this.tileSize = tileSize;
  }

  getTile(
    coord: google.maps.Point,
    zoom: number,
    ownerDocument: Document
  ): HTMLElement {
    const div = ownerDocument.createElement('div');

    div.innerHTML = `Tile: ${coord}, Zoom: ${zoom}`;
    div.style.width = this.tileSize.width + 'px';
    div.style.height = this.tileSize.height + 'px';
    div.style.fontSize = '10';
    div.style.borderStyle = 'solid';
    div.style.borderWidth = '1px';
    div.style.borderColor = '#AAAAAA';
    div.style.boxSizing = 'border-box';
    div.style.backgroundColor = '#E5E3DF';
    return div;
  }

  releaseTile(_tile: HTMLElement): void {/*noop*/}
}
