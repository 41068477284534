import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  useClientMembers, useUserIdSelector,
} from '~/store/userData/userData.selectors';
import { useBoundaryGroupListingData } from '../hooks/useBoundaryGroupListingData';
import { useBoundaryGroupRemove } from '../hooks/useBoundaryGroupRemove';
import { useBoundaryGroupRename } from '../hooks/useBoundaryGroupRename';
import { BoundaryGroupsListingComponent } from './boundaryGroupsListing.component';

const SHOW_DELETE_UNUSED = false; // internal dev feature for cleaning testing accounts

type BoundaryGroupsListingProps = ModalProps;

export const BoundaryGroupsListingContainer: FC<BoundaryGroupsListingProps> = (props) => {
  const { customBoundaryGroups, isLoading: isBoundaryGroupListingLoading, getBoundaryGroups } = useBoundaryGroupListingData();
  const { removeBoundaryGroup, isLoading: isBoundaryGroupRemoveLoading } = useBoundaryGroupRemove();
  const { renameBoundaryGroup, isLoading: isBoundaryGroupRenameLoading } = useBoundaryGroupRename();
  const userId = useUserIdSelector();
  const clientId = useClientIdSelector();
  const [t] = useTranslation();
  const clientMembers = useClientMembers();

  const updateName = useCallback((itemId: number, newName: string) => {
    if (clientId === null) {
      return;
    }

    renameBoundaryGroup(itemId, newName, () => {
      getBoundaryGroups(clientId);
    });
  }, [clientId, renameBoundaryGroup, getBoundaryGroups]);

  const onRemoveBoundaryGroup = useCallback((boundaryGroupId: number) => {
    const boundaryGroup = customBoundaryGroups.find(group => group.id === boundaryGroupId);

    if (clientId === null || !boundaryGroup) {
      return;
    }

    removeBoundaryGroup(boundaryGroup.id, boundaryGroup.usedOnMaps.length, () => {
      getBoundaryGroups(clientId);
    });
  }, [removeBoundaryGroup, getBoundaryGroups, clientId, customBoundaryGroups]);

  const items = useMemo(() => {
    return customBoundaryGroups.map(group => {
      return {
        name: group.name,
        id: group.id,
        mapsCount: group.usedOnMaps.length,
        canRemoveBoundaryGroups: group.userId === userId,
        ownerName: clientMembers.find((member) => member.id === group.userId)?.name ?? t('Unknown'),
      };
    });
  }, [customBoundaryGroups, userId, clientMembers, t]);

  useEffect(() => {
    if (clientId === null) {
      return;
    }

    getBoundaryGroups(clientId);
  }, [clientId, getBoundaryGroups]);

  const onRemoveUnusedGroups = useMemo(() =>
    SHOW_DELETE_UNUSED ? () => {
      for (const group of customBoundaryGroups) {
        if (group.usedOnMaps.length === 0) {
          removeBoundaryGroup(group.id, 0);
        }
      }
    } : undefined
  , [customBoundaryGroups, removeBoundaryGroup]);

  return (
    <BoundaryGroupsListingComponent
      items={items}
      onClose={props.onClose}
      isOpen={props.isOpen}
      onEditName={updateName}
      isLoading={isBoundaryGroupListingLoading || isBoundaryGroupRemoveLoading || isBoundaryGroupRenameLoading}
      caption={t('Territory Groups')}
      onRemove={onRemoveBoundaryGroup}
      onRemoveUnused={onRemoveUnusedGroups}
    />
  );
};
