import { type BoundaryGroup } from '~/boundary/boundary.types';
import {
  type BoundaryTerritoryGroupId, type ExtendedBoundaryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import { type BoundaryTerritoryAssignment } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const getBoundaryTerritoryFromAssignments = (
  boundaryIdentifier: ExtendedBoundaryIdentifier,
  boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>,
  assignments: ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment>,
): BoundaryTerritory | null => {
  const boundaryTerritoryId = assignments.get(boundaryIdentifier.boundaryTerritoryGroupId)?.boundaryAssignments.get(boundaryIdentifier.boundaryId) ?? null;

  return boundaryTerritoryGroups
    .find(group => group.boundaryTerritoryGroupId === boundaryIdentifier.boundaryTerritoryGroupId)?.settings
    .boundaryTerritories.find(territory => territory.boundaryTerritoryId === boundaryTerritoryId) ?? null;
};

const hideBoundaryOnZoomLevel = (mapZoomLevel: number, boundaryGroup: BoundaryGroup) => mapZoomLevel < boundaryGroup.loadZoomLevel;
const hideBoundaryBordersBelowLoadZoomLevel = (boundaryTerritoryType: BoundaryTerritoryType) => boundaryTerritoryType !== BoundaryTerritoryType.Manual;

export const shouldOnlyShowBoundariesWithFill = (mapZoomLevel: number, boundaryGroup: BoundaryGroup, boundaryTerritoryType: BoundaryTerritoryType) =>
  hideBoundaryOnZoomLevel(mapZoomLevel, boundaryGroup) && hideBoundaryBordersBelowLoadZoomLevel(boundaryTerritoryType);

const hideBordersWhenBoundariesAsTerritories = (mapZoomLevel: number, boundaryGroup: BoundaryGroup, boundaryTerritoryGroup: BoundaryTerritoryGroup) =>
  boundaryTerritoryGroup.settings.boundariesAsTerritories
  && boundaryTerritoryGroup.settings.boundaryTerritoryType !== BoundaryTerritoryType.Manual
  && mapZoomLevel < (boundaryGroup.zoomLevels[1] ?? 0);

export const hideBoundaryBordersOnZoomLevel = (mapZoomLevel: number, boundaryGroup: BoundaryGroup, boundaryTerritoryGroup: BoundaryTerritoryGroup) =>
  shouldOnlyShowBoundariesWithFill(mapZoomLevel, boundaryGroup, boundaryTerritoryGroup.settings.boundaryTerritoryType)
  || hideBordersWhenBoundariesAsTerritories(mapZoomLevel, boundaryGroup, boundaryTerritoryGroup);
