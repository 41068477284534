import { css } from '@emotion/react';
import { type FC } from 'react';

type OverlayWrapperProps = Readonly<{
  minHeight?: string | number;
  className?: string;
  children?: React.ReactNode;
}>;

const wrapperStyle = ({ minHeight }: { minHeight?: string | number }) => css({
  position: 'relative',
  minHeight: minHeight ?? 150,
  '&:after': {
    content: '\'\'',
    clear: 'both',
    display: 'table',
  },
});

export const OverlayWrapperComponent: FC<OverlayWrapperProps> = (props) => {
  return (
    <div
      className={props.className}
      css={wrapperStyle({ minHeight: props.minHeight })}
    >
      {props.children}
    </div>
  );
};
