import {
  call,
  takeLatest,
} from 'redux-saga/effects';
import { UndoItemType } from '../../../_shared/types/undo/undoItem';
import { type StorageService } from '../../../_shared/utils/storageService';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import { createHistoryItem } from '../../undo/undo.helpers';
import { getUserStorageService } from '../../userData/userData.sagas';
import { type MapSettingsHeatmapsAction } from './mapSettingsHeatmaps.action';
import { MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP } from './mapSettingsHeatmaps.actionTypes';

export function* heatMapsSagas() {
  yield takeLatest(MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP, removeHeatMapSaga);
}

function* removeHeatMapSaga(action: PickAction<MapSettingsHeatmapsAction, typeof MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP>) {
  const storageService: StorageService = yield call(getUserStorageService);

  yield call(createHistoryItem, storageService, { type: UndoItemType.REMOVE_HEATMAP, data: action.payload.heatmap, mapId: action.payload.mapId });
}
