export enum SettingsSelectedOption {
  DontAskAgain = 'DontAskAgain',
  SkipUpdate = 'SkipUpdate',
  AskMeLater = 'AskMeLater',
}

export const createDontAskAgainSetting = (): Settings => ({
  selectedOption: SettingsSelectedOption.DontAskAgain,
});

interface SkipUpdateSettingParams {
  skippedVersion: number;
  skippedSnapshot: number;
}

export const createSkipUpdateSetting = ({ skippedVersion, skippedSnapshot }: SkipUpdateSettingParams): Settings => ({
  selectedOption: SettingsSelectedOption.SkipUpdate,
  skippedVersion,
  skippedSnapshot,
});

interface AskMeLaterSettingParams {
  postponedVersion: number;
  postponedSnapshot: number;
  postponedAt: string;
}

export const createAskMeLaterSetting = (
  { postponedVersion, postponedSnapshot, postponedAt }: AskMeLaterSettingParams
): Settings => ({
  selectedOption: SettingsSelectedOption.AskMeLater,
  postponedVersion,
  postponedSnapshot,
  postponedAt,
});

export type Settings = {
  selectedOption: SettingsSelectedOption.DontAskAgain;
} | {
  selectedOption: SettingsSelectedOption.SkipUpdate;
  skippedVersion: number;
  skippedSnapshot: number;
} | {
  selectedOption: SettingsSelectedOption.AskMeLater;
  postponedVersion: number;
  postponedSnapshot: number;
  postponedAt: string;
};
