import { takeLatest } from 'redux-saga/effects';
import { registerMemberDisabledBroadcastListener } from '~/_shared/utils/api/broadcast.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { frontendLogoutActions } from '~/authorization/authContext/auth.utils';
import { setUserWasDisabled } from '~/clientTeamManagement/clientTeamManagement.helpers';
import { USER_WEB_SOCKET_BROADCAST_INITIALIZED } from '~/store/userData/userData.actionTypes';

export function* clientTeamManagementSagas() {
  yield takeLatest(USER_WEB_SOCKET_BROADCAST_INITIALIZED, registerMemberSocketEventsProcessor);
}

function* registerMemberSocketEventsProcessor() {
  const currentUserId: number | null = yield select(state => state.userData.id);
  if (!currentUserId) {
    return;
  }

  registerMemberDisabledBroadcastListener(user => {
    if (currentUserId === user.disabled.id) {
      frontendLogoutActions();
      setUserWasDisabled();
    }
  });
}
