import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useOnMount } from '~/_shared/hooks/useOnMount';
import {
  ClientLicenseState, isPaidLicense,
} from '~/_shared/types/client/license';
import { PageWithLoaderComponent } from '~/app/pages/pageWithLoader.component';
import { AuthBroadcastChannel } from '~/authorization/authContext/auth.utils';
import { errorPageSetError } from '~/store/errorPage/errorPage.actionCreators';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import { useReloadUserData } from '~/store/userData/useReloadUserData.hook';
import { usePaymentPortalRefresh } from '../usePaymentPortalRefresh.hook';
import { SubscriptionCompletedComponent } from './subscriptionCompleted.component';

export const SubscribedPageContainer = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { paymentPortalRefresh } = usePaymentPortalRefresh();
  const { reloadUserData } = useReloadUserData();

  const onLoad = useCallback(async () => {
    await paymentPortalRefresh();
    const { data: { current_client: { license } } } = await reloadUserData();
    const licensePurchased = isPaidLicense(license.type) && license.state === ClientLicenseState.ACTIVE;

    if (!licensePurchased) {
      dispatch(errorPageSetError(ErrorPageType.ContactSupport));
    }

    AuthBroadcastChannel.postMessage({ type: 'licenseUpdated' });

    setIsLoading(false);
  }, [dispatch, reloadUserData, paymentPortalRefresh]);

  useOnMount(() => {
    onLoad();
  });

  return (
    <PageWithLoaderComponent isLoading={isLoading}>
      <SubscriptionCompletedComponent />
    </PageWithLoaderComponent>
  );
};
