import { css } from '@emotion/react';
import { type FC } from 'react';
import { SidebarTitleComponent } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../_shared/utils/hooks';
import {
  type DrawingTool, drawingToolIcons,
} from '../../../../drawingTool/drawingTool.enums';
import { getDrawingElementLabel } from '../../../../drawingTool/drawingTool.helpers';
import { SidebarContentContainer } from '../sidebarContent/sidebarContent.container';
import { DrawingToolDetailsContentContainer } from './drawingToolDetailsContent.container';

type DrawingToolDetailsProps = {
  isLoading: boolean;
  selectedDrawingTool: DrawingTool;

  onClose: () => void;
};

const descriptionWrapperStyle = css({
  padding: '22px 0',
});

const descriptionIconWrapperStyle = ({ theme }: ThemeProps) => css({
  width: 40,
  height: 40,
  borderRadius: 4,
  background: theme.backgroundColors.secondary,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 16px',
});

const descriptionIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: '24px',
});

export const DrawingToolDetailsComponent: FC<DrawingToolDetailsProps> = (props) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <SidebarContentContainer
      description={(
        <SidebarTitleComponent
          onXClick={props.onClose}
          sidebarTitle={{
            header: getDrawingElementLabel(props.selectedDrawingTool, t),
          }}
          containerStyle={descriptionWrapperStyle}
          icon={drawingToolIcons[props.selectedDrawingTool]}
          iconWrapperStyle={descriptionIconWrapperStyle({ theme })}
          iconStyle={descriptionIconStyle({ theme })}
        />
      )}
      body={(
        <DrawingToolDetailsContentContainer />
      )}
      isLoading={props.isLoading}
    />
  );
};
