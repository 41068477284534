import {
  type FC, useCallback,
} from 'react';
import { AuthorizationPageWrapperComponent } from '~/authorization/pageWrapper/authorizationPageWrapper.component';
import { useQuery } from '../../_shared/utils/hooks/useQuery';
import { PasswordResetPageComponent } from './passwordResetPage.component';
import { usePasswordReset } from './usePasswordReset';

export const PasswordResetPageContainer: FC = () => {
  const { isPasswordResetLoading, passwordResetError, resetPassword } = usePasswordReset();
  const query = useQuery();

  const onSubmitCallback = useCallback(
    (password: string, confirmPassword: string, callback: VoidFunction) => {
      const email = query.get('email');
      const expires = query.get('expires');
      const hash = query.get('hash');
      const signature = query.get('signature');
      if (email && expires && hash && signature) {
        resetPassword(email, Number(expires), hash, signature, password, confirmPassword, callback);
      }
    },
    [resetPassword, query],
  );

  return (
    <AuthorizationPageWrapperComponent>
      <PasswordResetPageComponent
        isLoading={isPasswordResetLoading}
        onSubmit={onSubmitCallback}
        error={passwordResetError}
      />
    </AuthorizationPageWrapperComponent>
  );
};
