import { css } from '@emotion/react';
import {
  type FC, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorPickerHolderMethods } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';
import { ColorPickerWithWheelComponent } from '~/_shared/components/colorPicker/colorPickerWithWheel/colorPickerWithWheel.component';
import { ColorWheelComponent } from '~/_shared/components/colorPicker/colorPickerWithWheel/colorWheel.component';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import { SquareComponent } from '~/_shared/components/square/square.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { getSuitableBorderColorString } from '~/_shared/utils/colors/colors.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  getIsolateBoundaryColorHex, ISOLATE_BOUNDARY_THEME_COLOR,
} from '~/map/map/boundary/boundaryView/mapIsolateBoundaries.helper';
import { mapSettingsBoundariesSetIsolateColor } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.actionCreators';
import { useMapSettingsBoundariesIsolateColor } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.selectors';

const CUSTOM_COLOR_DD_OPTION = 'CUSTOM';

const addDropdownPrefix = (theme: Theme, item: DropdownOption<string>) => {
  const isolateBoundaryColorHex = getIsolateBoundaryColorHex(theme, item.value);
  return ({
    ...item,
    prefix:
  <SquareComponent
    color={isolateBoundaryColorHex}
    style={css({ marginRight: '8px', border: `${getSuitableBorderColorString(isolateBoundaryColorHex, theme.dropdownColors.optionBackground)} 1px solid` })}
  />,
  });
};

export const IsolateBoundaryColorPickerComponent: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const theme = useTheme();
  const isolateBoundaryColor = useMapSettingsBoundariesIsolateColor();
  const isolateBoundaryColorHex = getIsolateBoundaryColorHex(theme, isolateBoundaryColor);
  const colorPickerRef = useRef<ColorPickerHolderMethods>(null);

  const predefinedColorOptions = useMemo(() => {
    return [{
      name: t('White'),
      value: '#ffffff',
    },
    {
      name: t('Black'),
      value: '#000000',
    },
    {
      name: t('Light Gray'),
      value: '#ACBAC3',
    },
    {
      name: t('Light Blue'),
      value: '#B3D7F1',
    },
    {
      name: t('Theme Color'),
      value: ISOLATE_BOUNDARY_THEME_COLOR,
    }];
  }, [t]);

  const dropdownOptions: ReadonlyArray<DropdownOption<string>> = useMemo(() => {
    const customColor = {
      name: t('Custom Color'),
      value: CUSTOM_COLOR_DD_OPTION,
      prefix: <ColorWheelComponent css={css({ height: 16, width: 16, paddingRight: 8 })} />
      ,
    };
    return [...predefinedColorOptions.map((opt) => addDropdownPrefix(theme, opt)), customColor];
  }, [predefinedColorOptions, t, theme]);

  const selectedDropdownColor = predefinedColorOptions.some(option => option.value === isolateBoundaryColor) ? isolateBoundaryColor : CUSTOM_COLOR_DD_OPTION;

  return (
    <div css={{ backgroundColor: theme.backgroundColors.primary }}>
      <div css={{ fontSize: '12px', marginBottom: '4px' }}>{t('Select Isolate Color')}</div>
      <div css={{ display: 'flex', alignItems: 'center' }}>

        <SquareComponent
          color={isolateBoundaryColorHex}
          size={30}
          style={css({ marginRight: '8px', border: `${getSuitableBorderColorString(isolateBoundaryColorHex, theme.backgroundColors.primary)} 1px solid` })}
        />
        <DebouncedUpdateComponent
          value={createColor(isolateBoundaryColorHex)}
          onChangeDebounced={(color) => dispatch(mapSettingsBoundariesSetIsolateColor(color.hex))}
          wait={500}
          render={(value, onChange) => (
            <ColorPickerWithWheelComponent
              ref={colorPickerRef}
              css={{ marginLeft: '6px', marginRight: '20px' }}
              selectedColor={value}
              onChange={onChange}
            />
          )}
        />
        <RegularDropdownComponent
          css={{ flexGrow: 1 }}
          triggerStyle={css({ height: '32px' })}
          onChange={(newColor) => {
            if (newColor === CUSTOM_COLOR_DD_OPTION) {
              colorPickerRef.current?.openColorPicker();
            }
            else {
              dispatch(mapSettingsBoundariesSetIsolateColor(newColor));
            }
          }}
          options={dropdownOptions}
          value={selectedDropdownColor}
          inPortal
        />
      </div>
    </div>
  );
};
