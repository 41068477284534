import { css } from '@emotion/react';
import {
  useEffect, useState,
} from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { useDebounce } from '~/_shared/utils/hooks/useDebounce';
import { SliderWithInputValuesComponent } from '../../../../../_shared/components/slider/sliderWithInputValues/sliderWithInputValues.component';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { type NumericalRange } from '../../../../../_shared/utils/range/range.helpers';

export type NumberFilterSettings = Readonly<{
  maxValue: number;
  minValue: number;
  sliderStep?: number;
}>;

export type NumberFilterValues = Readonly<{
  currentMinValue: number;
  currentMaxValue: number;
  onChange: (args: Readonly<NumericalRange>) => void;
  reset?: boolean;
}>;

type NumberFilterProps = NumberFilterSettings & NumberFilterValues;

const rootStyle = css({
  height: 100,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0px',
  boxSizing: 'border-box',
});

const bottomPartStyle = css({
  margin: '5px 0 0 18px',
  display: 'flex',
  justifyContent: 'space-between',
});

const linkStyle = css({
  cursor: 'pointer',
});

const sliderStyle = css({
  width: 'calc(100% - 35px)', // so the left and right labels fit into the parent
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const NumberFilterComponent = ({
  currentMaxValue,
  currentMinValue,
  maxValue,
  minValue,
  onChange,
  reset,
  sliderStep = 1,
  ...restProps
}: NumberFilterProps) => {
  const [range, setRange] = useState<[number, number]>([currentMinValue, currentMaxValue]);
  const [t] = useTranslation();

  useEffect(() => {
    if (reset) {
      setRange([currentMinValue, currentMaxValue]);
    }
  }, [currentMaxValue, currentMinValue, reset]);

  const onChangeDebounced = useDebounce(onChange, 1000);

  return (
    <div {...restProps} css={rootStyle}>
      <SliderWithInputValuesComponent
        css={sliderStyle}
        value={range}
        onChange={([min, max]) => {
          setRange([min, max]);
          onChangeDebounced({ from: min, to: max });
        }}
        min={minValue}
        max={maxValue}
        step={sliderStep}
        icon={null}
      />

      <div css={bottomPartStyle}>
        <LinkComponent
          css={linkStyle}
          path={null}
          onClick={() => onChange({ from: minValue, to: maxValue })}
        >
          {t('Clear')}
        </LinkComponent>
      </div>
    </div>
  );
};
