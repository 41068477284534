import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { InvitationPageCtaComponent } from '~/clientTeamManagement/invitationPage/cta/invitationPageCta.component';

const containerStyle = css({
  width: '100%',
  position: 'relative',
});

const textContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const messageStyle = ({ color }: { color?: string }) => css({
  marginTop: 50,
  color: color || 'inherit',
  textAlign: 'left',
});

type TrialUserInvitationProps = {
  acceptInvitation: (password?: string, passwordString?: string) => Promise<void>;
  client: string;
  rejectInvitation: () => void;
};

export const TrialUserInvitation: FC<TrialUserInvitationProps> = ({
  client,
  acceptInvitation,
  rejectInvitation,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={containerStyle}>
      <div css={textContainerStyle}>
        <p css={messageStyle({ color: theme.textColors.primary })}>
          {isTextEmpty(client) ?
            t('By accepting the invitation, all your existing maps will be transferred under this company. Do you wish to accept the invitation and continue?') :
            t('By accepting the invitation from the company {{company}}, all your existing maps will be transferred under this company. Do you wish to accept the invitation and continue?', { company: client })}
        </p>
      </div>

      <InvitationPageCtaComponent
        onDeclineClick={rejectInvitation}
        onAcceptClick={acceptInvitation}
      />
    </div>
  );
};
