import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../_shared/utils/hooks';
import { drawingItemsRemoveAllItemsOfType } from '../../store/mapSettings/drawing/items/drawingItems.actionCreators';
import { type DrawingTool } from '../drawingTool.enums';
import {
  getDrawingDeleteAllItemsLabelForDrawingType,
  getDrawingsTypeLabel,
} from '../drawingTool.helpers';

export const useDrawingItemsRemove = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const removeDrawingItemsRequest = useCallback((drawingType: DrawingTool) => {
    const removeDrawingItems = (drawingType: DrawingTool) => {
      dispatch(drawingItemsRemoveAllItemsOfType(drawingType));
    };

    const onConfirm = () => {
      removeDrawingItems(drawingType);
      closeConfirmationModal();
    };

    openConfirmationModal({
      cancelCaption: t('Cancel'),
      isConfirmButtonDestructive: true,
      confirmCaption: t('Proceed'),
      onCancel: closeConfirmationModal,
      title: getDrawingDeleteAllItemsLabelForDrawingType(drawingType, t),
      text: t('You are about to delete all {{drawingsType}} from the map. This action is irreversible. Do you wish to continue?', {
        drawingsType: getDrawingsTypeLabel(drawingType, t).toLowerCase(),
      }),
      onConfirm,
      permanentConfirmSettings: {
        id: 'remove-drawing-items-of-type',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, dispatch, openConfirmationModal, t]);

  return {
    removeDrawingItems: removeDrawingItemsRequest,
  };
};
