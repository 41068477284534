import { type BoundaryEditAction } from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import { type MapShapeInstance } from '~/map/map/mapObjects/mapShape/mapShapeModel';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';

export enum DrawType {
  Draw = 'Draw',
  Drag = 'Drag',
}

export enum BoundaryDrawMode {
  BoundaryPolygonEdit = 'BoundaryPolygonEdit',
  BoundaryDrawEdit = 'BoundaryDrawEdit',
}

type BoundaryPolygonDragEditModeData = Readonly<{
  type: BoundaryDrawMode.BoundaryPolygonEdit;
  boundaryId: BoundaryId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
}>;

type BoundaryDrawEditModeData = Readonly<{
  type: BoundaryDrawMode.BoundaryDrawEdit;
  action: BoundaryEditAction;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  boundaryId?: BoundaryId;
}>;

export type BoundaryDrawInactiveState = Readonly<{
  isModalOpen: false;
  isActive: false;
  boundaryGroupId: null;
  drawType: null;
  mapShapes: null;
  initialShapes: null;
}>;

export type BoundaryPolygonDragEditActiveState = Readonly<{
  isModalOpen: true;
  isActive: boolean;
  boundaryGroupId: BoundaryGroupId;
  drawType: DrawType;
  drawModeData: BoundaryPolygonDragEditModeData;
  mapShapes: readonly MapShapeInstance[];
  initialShapes: readonly MapShapeInstance[];
  disabledReason?: string;
}>;

export type BoundaryDrawEditActiveState = Readonly<{
  isModalOpen: true;
  isActive: boolean;
  boundaryGroupId: BoundaryGroupId;
  drawType: DrawType;
  drawModeData: BoundaryDrawEditModeData;
  mapShapes: readonly MapShapeInstance[];
  initialShapes: readonly MapShapeInstance[];
}>;

export type BoundaryDrawState = BoundaryDrawInactiveState | BoundaryPolygonDragEditActiveState | BoundaryDrawEditActiveState;

export const isBoundaryDrawActive = (state: BoundaryDrawState): state is (BoundaryPolygonDragEditActiveState | BoundaryDrawEditActiveState) => state.isActive;

export const isBoundaryPolygonDragEditActive = (state: BoundaryDrawState): state is BoundaryPolygonDragEditActiveState =>
  state.isActive && state.drawModeData.type === BoundaryDrawMode.BoundaryPolygonEdit;

export const isBoundaryPolygonDragModalOpen = (state: BoundaryDrawState): state is BoundaryPolygonDragEditActiveState =>
  state.isModalOpen && state.drawModeData.type === BoundaryDrawMode.BoundaryPolygonEdit;

export const isBoundaryDrawEditActive = (state: BoundaryDrawState): state is BoundaryDrawEditActiveState =>
  state.isActive && state.drawModeData.type === BoundaryDrawMode.BoundaryDrawEdit;

export const isBoundaryDrawEditModalOpen = (state: BoundaryDrawState): state is BoundaryDrawEditActiveState =>
  state.isModalOpen && state.drawModeData.type === BoundaryDrawMode.BoundaryDrawEdit;
