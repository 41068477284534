import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import type { BoundaryTerritoryId } from '~/store/boundaries/boundaryIdentifier.type';
import { boundaryTerritoryGroupUpdateAssignmentsRequest } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';

type CommonBoundaryAssignmentParams = Readonly<{
  boundaryTerritoryGroupId: number;
  onSuccess?: () => void;
}>;

type AddBoundaryAssignmentParams = CommonBoundaryAssignmentParams & Readonly<{
  columnId?: ColumnId;
  setBoundaryIds: number[];
  boundaryTerritoryId: BoundaryTerritoryId;
}>;

type RemoveBoundaryAssignmentParams = CommonBoundaryAssignmentParams & Readonly<{
  boundaryTerritoryAndColumnIds: ReadonlyArray<Readonly<{
    boundaryTerritoryId: BoundaryTerritoryId;
    columnId?: ColumnId;
  }>>;
  removeBoundaryIds: number[];
}>;

export const useBoundaryAssignmentsUpdate = () => {
  const dispatch = useDispatch();

  const addBoundaryTerritoryAssignments = useCallback((variables: AddBoundaryAssignmentParams) => {
    const { setBoundaryIds, boundaryTerritoryId, boundaryTerritoryGroupId, columnId, onSuccess } = variables;

    if (setBoundaryIds.length === 0) {
      return;
    }

    const updateRequest = {
      boundary_territory_id: boundaryTerritoryId,
      column_id: columnId,
      set: setBoundaryIds.map(boundaryId => ({
        boundary_id: boundaryId,
      })),
    };

    dispatch(boundaryTerritoryGroupUpdateAssignmentsRequest(boundaryTerritoryGroupId, [updateRequest], onSuccess));
  }, [dispatch]);

  const removeBoundaryTerritoryAssignments = useCallback((variables: RemoveBoundaryAssignmentParams) => {
    const { removeBoundaryIds, boundaryTerritoryGroupId, boundaryTerritoryAndColumnIds, onSuccess } = variables;

    if (removeBoundaryIds.length === 0) {
      return;
    }

    const updateRequests = boundaryTerritoryAndColumnIds.map(({ boundaryTerritoryId, columnId }) => ({
      boundary_territory_id: boundaryTerritoryId,
      column_id: columnId,
      remove: removeBoundaryIds,
    }));

    dispatch(boundaryTerritoryGroupUpdateAssignmentsRequest(boundaryTerritoryGroupId, updateRequests, onSuccess));
  }, [dispatch]);

  return {
    addBoundaryTerritoryAssignments,
    removeBoundaryTerritoryAssignments,
  };
};
