import { FilterAction } from '../../../_shared/types/filter/filter';
import { type GroupingColumnValues } from '../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { FilterConjunction } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/filterConjunction.enum';
import { DataType } from '../../spreadsheetData/spreadsheetData.state';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../data/mapSettingsData.actionTypes';
import { type MapSettingsColumnsFilterAction } from './mapSettingsColumnsFilter.action';
import {
  MAP_SETTINGS_COLUMNS_FILTER_CHANGE_ATTRIBUTE_CONJUNCTION,
  MAP_SETTINGS_COLUMNS_FILTER_CHANGE_TEXT_CONJUNCTION,
  MAP_SETTINGS_COLUMNS_FILTER_CLEAR_FILTERS,
  MAP_SETTINGS_COLUMNS_FILTER_REMOVE_FILTER_VALUES,
  MAP_SETTINGS_COLUMNS_FILTER_SET_ATTRIBUTE,
  MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_FROM,
  MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_TO, MAP_SETTINGS_COLUMNS_FILTER_SET_GROUP,
  MAP_SETTINGS_COLUMNS_FILTER_SET_NUMBER,
  MAP_SETTINGS_COLUMNS_FILTER_SET_TEXT,
} from './mapSettingsColumnsFilter.actionTypes';
import {
  type MapSettingsColumnsFilterState,
  type MapSettingsFilterAttributeState,
  type MapSettingsFilterDateState,
  type MapSettingsFilterNumberState,
  type MapSettingsFilterTextState,
} from './mapSettingsColumnsFilter.state';

export const mapSettingsColumnsFilterInitialState: MapSettingsColumnsFilterState = {
};

type DataTypeToFilterStateGeneric<T, S> = {
  type: T;
  value: S;
};

type DataTypeToFilterState =
  DataTypeToFilterStateGeneric<DataType.TEXT, MapSettingsFilterTextState> |
  DataTypeToFilterStateGeneric<DataType.ATTRIBUTE, MapSettingsFilterAttributeState> |
  DataTypeToFilterStateGeneric<DataType.NUMBER, MapSettingsFilterNumberState> |
  DataTypeToFilterStateGeneric<DataType.GROUP, GroupingColumnValues<1>> |
  DataTypeToFilterStateGeneric<DataType.DATE, MapSettingsFilterDateState>;

const addFilterToState = (currentState: MapSettingsColumnsFilterState,
  spreadsheetColumnId: SpreadsheetColumnId, value: DataTypeToFilterState
): MapSettingsColumnsFilterState => {
  return {
    ...currentState,
    [spreadsheetColumnId.spreadsheetId]: {
      ...currentState?.[spreadsheetColumnId.spreadsheetId],
      [spreadsheetColumnId.columnId]: {
        ...currentState?.[spreadsheetColumnId.spreadsheetId]?.[spreadsheetColumnId.columnId],
        [value.type]: value.value,
      },
    },
  };
};

export const getFilterConjunction = (
  filterState: MapSettingsColumnsFilterState,
  spreadsheetColumnId: SpreadsheetColumnId,
  dataType: DataType.ATTRIBUTE | DataType.TEXT
): FilterConjunction => {
  const data = filterState[spreadsheetColumnId.spreadsheetId]?.[spreadsheetColumnId.columnId]?.[dataType];

  return data?.conjunction ?? FilterConjunction.And;
};

export const mapSettingsColumnsFilterReducer = (state = mapSettingsColumnsFilterInitialState,
  action: MapSettingsColumnsFilterAction | MapSettingsDataAction): MapSettingsColumnsFilterState => {
  switch (action.type) {
    case MAP_SETTINGS_COLUMNS_FILTER_CLEAR_FILTERS: {
      return mapSettingsColumnsFilterInitialState;
    }

    case MAP_SETTINGS_COLUMNS_FILTER_REMOVE_FILTER_VALUES: {
      const newState = {
        ...state,
        [action.payload.spreadsheetColumnId.spreadsheetId]: {
          ...state[action.payload.spreadsheetColumnId.spreadsheetId],
          [action.payload.spreadsheetColumnId.columnId]: {
            ...state[action.payload.spreadsheetColumnId.spreadsheetId]?.[action.payload.spreadsheetColumnId.columnId],
          },
        },
      };

      delete newState[action.payload.spreadsheetColumnId.spreadsheetId]?.
        [action.payload.spreadsheetColumnId.columnId];

      return newState;
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_TEXT: {
      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.TEXT,
        value: {
          conjunction: getFilterConjunction(state, action.payload.spreadsheetColumnId, DataType.TEXT),
          values: action.payload.values,
        },
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_CHANGE_TEXT_CONJUNCTION: {
      const value: MapSettingsFilterTextState = {
        conjunction: action.payload.conjunction,
        values: state[action.payload.spreadsheetColumnId.spreadsheetId]
          ?.[action.payload.spreadsheetColumnId.columnId]?.[DataType.TEXT]?.values ?? [],
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.TEXT,
        value,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_GROUP: {
      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.GROUP,
        value: action.payload.group,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_NUMBER: {
      const value: MapSettingsFilterNumberState = {
        values: {
          action: FilterAction.Range,
          value: [action.payload.range.from, action.payload.range.to],
        },
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.NUMBER,
        value,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_FROM: {
      const dateTo = state[action.payload.spreadsheetColumnId.spreadsheetId]
        ?.[action.payload.spreadsheetColumnId.columnId]?.[DataType.DATE]?.values[1] ?? null;

      const value: MapSettingsFilterDateState = {
        values: [action.payload.dateFrom, dateTo],
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.DATE,
        value,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_TO: {
      const dateFrom = state[action.payload.spreadsheetColumnId.spreadsheetId]
        ?.[action.payload.spreadsheetColumnId.columnId]?.[DataType.DATE]?.values[0] ?? null;

      const value: MapSettingsFilterDateState = {
        values: [dateFrom, action.payload.dateTo],
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.DATE,
        value,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_SET_ATTRIBUTE: {
      const value: MapSettingsFilterAttributeState = {
        values: action.payload.selectedAttributeIds,
        conjunction: getFilterConjunction(state, action.payload.spreadsheetColumnId, DataType.ATTRIBUTE),
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.ATTRIBUTE,
        value,
      });
    }

    case MAP_SETTINGS_COLUMNS_FILTER_CHANGE_ATTRIBUTE_CONJUNCTION: {
      const value: MapSettingsFilterAttributeState = {
        conjunction: action.payload.conjunction,
        values: state[action.payload.spreadsheetColumnId.spreadsheetId]?.
          [action.payload.spreadsheetColumnId.columnId]?.[DataType.ATTRIBUTE]?.values ?? {},
      };

      return addFilterToState(state, action.payload.spreadsheetColumnId, {
        type: DataType.ATTRIBUTE,
        value,
      });
    }

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS: {
      return mapSettingsColumnsFilterInitialState;
    }

    default:
      return state;
  }
};
