import { type TranslationFnc } from '../utils/hooks';

export enum RouteCollapsingLevel {
  Routes = 'Routes',
  Stops = 'Stops',
  TurnByTurn = 'TurnByTurn',
}

export const createRouteCollapsingLevelLabel = (t: TranslationFnc, level: RouteCollapsingLevel) => {
  switch (level) {
    case RouteCollapsingLevel.Routes:
      return t('RouteCollapsingLevel.Routes__label');
    case RouteCollapsingLevel.Stops:
      return t('RouteCollapsingLevel.Stops__label');
    case RouteCollapsingLevel.TurnByTurn:
      return t('RouteCollapsingLevel.TurnByTurn__label');
    default:
      throw new Error(`routeCollapsingLevel.enum.ts: Unknown collapsing level '${level}'.`);
  }
};

export const createRouteCollapsingLevelDescription = (t: TranslationFnc, level: RouteCollapsingLevel) => {
  switch (level) {
    case RouteCollapsingLevel.Routes:
      return t('RouteCollapsingLevel.Routes__description');
    case RouteCollapsingLevel.Stops:
      return t('RouteCollapsingLevel.Stops__description');
    case RouteCollapsingLevel.TurnByTurn:
      return t('RouteCollapsingLevel.TurnByTurn__description');
    default:
      throw new Error(`routeCollapsingLevel.enum.ts: Unknown collapsing level '${level}'.`);
  }
};

export const shouldRoutesBeExpanded = (collapsingLevel: RouteCollapsingLevel) =>
  collapsingLevel === RouteCollapsingLevel.Stops || collapsingLevel === RouteCollapsingLevel.TurnByTurn;

export const shouldLegsBeExpanded = (collapsingLevel: RouteCollapsingLevel) =>
  collapsingLevel === RouteCollapsingLevel.TurnByTurn;
