import { css } from '@emotion/react';
import { rgbColorToString } from '../../components/colorPicker/colorPicker.helpers';
import { type TinyMapColorTileComponentProps } from './tinyMapColorTile.component';

const computeDimensions = (fill?: boolean) => fill ? '100%' : '65%';
const computeRadius = (circle?: boolean) => circle ? 500 : 4;

export const colorTileStyle = ({ color, lineColor, lineWidth, fill, circle }: TinyMapColorTileComponentProps) => css({
  width: computeDimensions(fill),
  height: computeDimensions(fill),
  backgroundColor: rgbColorToString(color),
  borderRadius: computeRadius(circle),
  border: lineColor ? `${lineWidth}px solid ${rgbColorToString(lineColor)}` : 'none',
  boxSizing: 'border-box',
  zIndex: 1,
});

export const placeholderStyle = ({ circle }: TinyMapColorTileComponentProps) => css({
  borderRadius: computeRadius(circle),
});

const computeOutlineDimensions = (lineWidth: number, fill?: boolean) => `calc(${computeDimensions(fill)} - ${lineWidth}px)`;
export const outlineStyles = ({ fill, circle, lineWidth }: { fill?: boolean; circle?: boolean; lineWidth: number }) => css({
  position: 'absolute',
  border: '1px solid black',
  zIndex: 2,
  boxSizing: 'border-box',
  borderRadius: computeRadius(circle),
  width: computeOutlineDimensions(lineWidth, fill),
  height: computeOutlineDimensions(lineWidth, fill),
});
