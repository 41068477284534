import { type FC } from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryTerritoryKeySettings } from '../activeBoundary.component';
import { BoundaryRowComponent } from './boundaryRow.component';
import { BoundaryTerritoryContextMenuComponent } from './boundaryTerritoryContextMenu.component';

type BoundaryTerritoryProps = {
  setting: BoundaryTerritoryKeySettings;
};

export const BoundaryTerritoryComponent: FC<BoundaryTerritoryProps> = ({ setting }) => {
  const showContextMenu = !!setting.onExportData || !!setting.onDelete || !!setting.onExportContainedBoundaries;
  const [t] = useTranslation();

  const subLabel = setting.isCustom
    ? setting.isEmpty
      ? t('manual group; no data')
      : t('manual group')
    : setting.isEmpty
      ? t('no data')
      : null;

  return (
    <BoundaryRowComponent
      isChecked={setting.isVisible}
      isDisabled={setting.isEmpty}
      label={setting.label}
      onToggle={setting.onToggle}
      selectedColor={setting.selectedColor}
      lineColor={setting.lineColor}
      lineWidth={setting.lineWidth}
      labelTooltip={setting.isCustom ? t('This group was created and customized manually.') : null}
      subLabel={subLabel}
      actions={showContextMenu && (
        <BoundaryTerritoryContextMenuComponent
          onExportDataClick={setting.onExportData}
          onDeleteClick={setting.onDelete}
          onBatchEditLocationsClick={setting.onBatchEditLocationsClick}
          onExportContainedBoundariesClick={setting.onExportContainedBoundaries}
        />
      )}
    />
  );
};
