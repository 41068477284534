import { BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import {
  deactivateActiveBoundary, deactivateActiveProximity,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { type DistanceCalculatorAction } from '~/store/frontendState/mapTools/distanceCalculator/distanceCalculator.action';
import type { MapAction } from '~/store/map/map.action';
import { MAP_PAGE_INIT } from '~/store/map/map.actionTypes';
import type { LocationFinderToolsStateAction } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.action';
import { setLocationFinderToolsState } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.actionCreators';
import type { SpreadsheetDataAction } from '~/store/spreadsheetData/spreadsheetData.action';
import { type BoundaryItemsAction } from '../../boundaryItems/boundaryItems.action';
import { type BoundaryTerritoryGroupsAction } from '../../boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { type MapSettingsProximityAction } from '../../mapSettings/proximity/mapSettingsProximity.action';
import { type DrawingEditAction } from '../mapTools/drawing/drawingEdit/drawingEdit.action';
import { FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING } from '../mapTools/drawing/drawingEdit/drawingEdit.actionTypes';
import { type ActiveMapElementsAction } from './activeMapElements.action';
import {
  ACTIVE_MAP_ELEMENTS_CLEAR_STATE, ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY, ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_MARKER_NAVIGATION_INDEX, ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY,
  ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST, SPREADSHEET_MARKER_CLICKED, SPREADSHEET_MARKER_HOVER_STOPPED,
  SPREADSHEET_MARKER_HOVERED,
} from './activeMapElements.actionTypes';
import { type ActiveMapElementsState } from './activeMapElements.state';

const initialState: ActiveMapElementsState = {
  activeMarker: null,
  activeMarkerNavigationIndex: 0,
  activeBoundary: null,
  activeBoundaryTerritory: null,
  activeProximity: null,
  hoveredMarker: null,
  isLocationListActive: false,
};

export const activeMapElementsReducer = (
  state = initialState, action: ActiveMapElementsAction | MapSettingsProximityAction
  | BoundaryTerritoryGroupsAction| BoundaryItemsAction | DrawingEditAction
    | LocationFinderToolsStateAction | DistanceCalculatorAction | MapAction | SpreadsheetDataAction
): ActiveMapElementsState => {
  if (setLocationFinderToolsState.match(action)) {
    return initialState;
  }
  if (deactivateActiveProximity.match(action)) {
    return {
      ...state,
      activeProximity: null,
    };
  }
  if (deactivateActiveBoundary.match(action)) {
    return {
      ...state,
      activeBoundary: null,
      activeBoundaryTerritory: null,
    };
  }

  switch (action.type) {
    case SPREADSHEET_MARKER_CLICKED:
      return {
        ...initialState,
        isLocationListActive: state.isLocationListActive,
        activeMarker: action.payload,
        activeMarkerNavigationIndex: initialState.activeMarkerNavigationIndex,
      };

    case SPREADSHEET_MARKER_HOVERED:
      return {
        ...state,
        hoveredMarker: action.payload,
      };

    case MAP_PAGE_INIT:
    case SPREADSHEET_MARKER_HOVER_STOPPED:
      return {
        ...state,
        hoveredMarker: null,
      };

    case ACTIVE_MAP_ELEMENTS_SET_ACTIVE_MARKER_NAVIGATION_INDEX:
      return {
        ...state,
        activeMarkerNavigationIndex: action.payload.index,
      };

    case ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY: {
      return {
        ...initialState,
        isLocationListActive: state.isLocationListActive,
        activeBoundary: action.payload,
      };
    }

    case ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY: {
      return {
        ...initialState,
        isLocationListActive: state.isLocationListActive,
        activeBoundaryTerritory: action.payload,
      };
    }

    case BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS: {
      const removedBTG = action.payload.boundaryTerritoryGroup;

      return {
        ...state,
        ...(removedBTG.boundaryGroupId && state.activeBoundary?.boundaryGroupId === removedBTG.boundaryGroupId
          ? { activeBoundary: initialState.activeBoundary } : {}),
        ...(removedBTG.boundaryTerritoryGroupId && state.activeBoundary?.boundaryTerritoryGroupId === removedBTG.boundaryTerritoryGroupId
          ? { activeBoundaryTerritory: initialState.activeBoundaryTerritory } : {}),
      };
    }

    case FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING:
    case ACTIVE_MAP_ELEMENTS_CLEAR_STATE: {
      return initialState;
    }

    case ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER: {
      return {
        ...state,
        activeMarker: initialState.activeMarker,
        activeMarkerNavigationIndex: initialState.activeMarkerNavigationIndex,
      };
    }

    case ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY:
      return {
        ...initialState,
        isLocationListActive: state.isLocationListActive,
        activeProximity: action.payload,
      };

    case ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST:
      return {
        ...initialState,
        isLocationListActive: true,
      };

    default:
      return state;
  }
};
