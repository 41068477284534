import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { mapSettingsConcurrencySetAlertVisibility } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.actionCreators';
import { useMapSettingsConcurrencySelector } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.selectors';
import {
  s, Trans,
} from '~/translations/Trans';
import { type TopUnderbarItem } from '../item/topUnderbarItem.types';

type UseMapSettingsStatusItemsProps = {
  generateSamples?: boolean;
};

export const useMapSettingsStatusItems = (props?: UseMapSettingsStatusItemsProps): ReadonlyArray<TopUnderbarItem> => {
  const mapSettingsConcurrency = useMapSettingsConcurrencySelector();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  return useMemo(() => {
    const items: TopUnderbarItem[] = [];

    if (mapSettingsConcurrency.showOfflineAlert || props?.generateSamples) {
      items.push({
        key: 'map-settings-save-status-offline',
        sticky: true,
        icon: faExclamationCircle,
        colors: {
          color: theme.textColors.warning,
        },
        onClose: () => dispatch(mapSettingsConcurrencySetAlertVisibility(false)),
        children: (
          <div
            css={{
              fontSize: 16,
            }}
          >
            {t('You are in offline mode, your progress can\'t be saved.')}{' '}
            <Trans i18nKey="<0>Refresh the page</0> if you want to return to online mode.">
              <LinkComponent
                css={{
                  color: theme.textColors.warning,
                  textDecoration: 'underline',
                  fontSize: 16,
                  fontWeight: 700,
                }}
                path="#"
                onClick={refreshPage}
              >
                Refresh the page
              </LinkComponent>
              {s(' if you want to return to online mode.')}
            </Trans>
          </div>
        ),
      });
    }

    return items;
  },
  [dispatch, mapSettingsConcurrency.showOfflineAlert, props?.generateSamples, t, theme.textColors.warning]);
};
