import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import { type MatchupDataState } from '../../matchupData/matchupData.state';
import {
  getSpreadsheetDataForDataType, type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '../spreadsheetData.state';

export const getMissingMarkerInfoSpreadsheetData = (
  mapSettings: MapSettingsState,
  spreadsheetData: SpreadsheetDataData,
  matchupDataState: MatchupDataState
): MissingSpreadsheetData => {
  const dataGroupSpreadsheetColumnsToFetch: SpreadsheetColumnId[] = [];

  // marker label
  if (mapSettings.data.markersGeneral.useTextLabel && mapSettings.data.markersGeneral.labelMarkersNameColumn) {
    dataGroupSpreadsheetColumnsToFetch.push(mapSettings.data.markersGeneral.labelMarkersNameColumn);
  }

  // above marker label
  if (
    mapSettings.data.markersGeneral.useLabelsAboveMarkers &&
    mapSettings.data.markersGeneral.labelsAboveMarkersNameColumn &&
    // TODO: test if below conditions are still required after spreadsheet replace issue is resolved
    mapSettings.data.markersGeneral.labelsAboveMarkersNameColumn.spreadsheetId !== undefined &&
    mapSettings.data.markersGeneral.labelsAboveMarkersNameColumn.columnId !== undefined
  ) {
    dataGroupSpreadsheetColumnsToFetch.push(mapSettings.data.markersGeneral.labelsAboveMarkersNameColumn);
  }

  // matchup data -> 'name' category - get all missing
  Object.keys(matchupDataState).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const nameCategoryMatchedColumnId = matchupDataState[spreadsheetId].data?.categories.name.match;

    if (!nameCategoryMatchedColumnId) {
      return;
    }

    dataGroupSpreadsheetColumnsToFetch.push({
      spreadsheetId,
      columnId: nameCategoryMatchedColumnId,
    });
  });

  const spreadsheetColumnsWithoutFetchedData = dataGroupSpreadsheetColumnsToFetch.filter((item => {
    return !getSpreadsheetDataForDataType(DataType.GROUP, spreadsheetData, item);
  }));

  return spreadsheetColumnsWithoutFetchedData.reduce<MissingSpreadsheetData>(
    (acc, column) => {
      acc.data[column.spreadsheetId] = {
        [Unfiltered]: {
          [DataType.GROUP]: {
            ...acc.data[column.spreadsheetId]?.[Unfiltered]?.[DataType.GROUP] ?? {},
            [column.columnId]: {},
          },
        },
      };

      return acc;
    }, {
      data: {},
    });
};
