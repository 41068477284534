export enum MetricsDataAction {
  SUM = 'sum',
  AVERAGE = 'average',
  HIGH_VALUE = 'high-value',
  LOW_VALUE = 'low-value',
  GROUP_COUNT = 'group-count',
}

export type DemographicMetric = {
  demographicId: number;
};

export type SpreadsheetColumnMetric = {
  columnId: string;
  spreadsheetId: number;
  dataAction: MetricsDataAction;
};

export type MetricProps = Readonly<{
  demographicId?: string;
  additionalData: ReadonlyArray<{
    name: string;
    value: number;
  }>;
  dataAction?: MetricsDataAction;
  name: string;
  prefix?: string;
  suffix?: string;
  value: number;
}>;
