import {
  type FC, type ReactNode, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  type ActiveGroupFilters, GroupingType,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { getActiveGroupFiltersFromFiltersState } from '~/_shared/utils/grouping/grouping.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { spreadsheetColumnIdToString } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { usePresentationalColumnsRestrictionsMatchup } from '~/presentationalColumnsRestrictions/usePresentationalColumnsRestrictionsMatchup';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  mapSettingsColumnsFilterChangeAttributeConjunction,
  mapSettingsColumnsFilterChangeTextConjunction,
  mapSettingsColumnsFilterClearFilters,
  mapSettingsColumnsFilterRemoveFilterValues,
  mapSettingsColumnsFilterSetAttribute,
  mapSettingsColumnsFilterSetDateFrom,
  mapSettingsColumnsFilterSetDateTo,
  mapSettingsColumnsFilterSetGroup,
  mapSettingsColumnsFilterSetNumber,
  mapSettingsColumnsFilterSetText,
} from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilter.actionCreators';
import { usePublicMapSettingsAreFilterMenusOpenSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { getMatchupDataColumnName } from '~/store/matchupData/matchupData.helpers';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  createFilterTextValuesFromTextFilter, isTextFilterOnList,
} from './filterTool/filters/filters.helpers';
import {
  ActiveGroupFilterPanelBody,
  getAttributeFilterPanel,
  getDateFilterPanel,
  getNumberFilterPanelBody,
  getRegularGroupFilterPanelBody,
  getTextFilterPanelBody,
} from './filterTool/filters/renderToolFilter.helper';
import { type TextFilter } from './filterTool/filters/textFilter/textFilter.component';
import {
  type FilterItem, FilterToolComponent,
} from './filterTool/filterTool.component';

export const FilterToolContainer: FC = () => {
  const { openModal } = useModal(ModalType.FilterSettings);
  const filtersState = useSelector(state => state.map.mapSettings.data.filtering);
  const activeGroupingColumns = useSelector(state => state.map.mapSettings.data.grouping.activeGroupColumns);
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useSelector(state => state.spreadsheet.matchupData);
  const filtersDataState = useSelector(state => state.map.mapSettings.data.toolsState.columnsFilter);
  const isSpreadsheetDataLoading = useSelector(state => state.frontendState.processing.spreadsheetDataFetchingInProgress);
  const isMapSettingsSyncInProgress = useSelector(state => state.frontendState.processing.mapSettingsSyncInProgress);
  const isScreenSizeMobile = useIsMobileScreenSelector();
  const areFiltersOpen = usePublicMapSettingsAreFilterMenusOpenSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const restrictionsMatchup = usePresentationalColumnsRestrictionsMatchup();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleIsColumnRestricted = useCallback((spreadsheetColumnId: SpreadsheetColumnId) => {
    const restrictions = restrictionsMatchup.get(spreadsheetColumnIdToString(spreadsheetColumnId));

    return isMapPresentational && restrictions && (restrictions.master || restrictions.filter);
  }, [isMapPresentational, restrictionsMatchup]);

  const activeGroupingColumnsFilterItems: FilterItem[] = useMemo(() => {
    const results: FilterItem[] = [];
    const activeGroupFilters: ActiveGroupFilters = getActiveGroupFiltersFromFiltersState(filtersDataState);

    if (activeGroupingColumns.length === 0) {
      return [];
    }

    for (const activeGroupingColumn of activeGroupingColumns) {
      const spreadsheetColumnId: SpreadsheetColumnId = {
        columnId: activeGroupingColumn.columnId,
        spreadsheetId: activeGroupingColumn.spreadsheetId,
      };

      const columnName = matchupData[activeGroupingColumn.spreadsheetId]?.data?.columns?.[activeGroupingColumn.columnId];
      if ((columnName === undefined) || handleIsColumnRestricted(spreadsheetColumnId)) {
        continue;
      }

      const panelBody = (
        <ActiveGroupFilterPanelBody
          groupingColumn={activeGroupingColumn}
          activeGroups={activeGroupFilters}
          onFilterChange={(spreadsheetColumnId, groups) => {
            dispatch(mapSettingsColumnsFilterSetGroup(spreadsheetColumnId, groups));
          }}
          isEditingDisabled={isScreenSizeMobile || isMapPresentational}
        />
      );

      results.push({
        title: columnName,
        body: panelBody,
      });
    }

    return results;
  }, [activeGroupingColumns, dispatch, filtersDataState, handleIsColumnRestricted, isMapPresentational, isScreenSizeMobile, matchupData]);

  const filters = useMemo(() => {
    const results: FilterItem[] = [];
    const activeGroupFilters: ActiveGroupFilters = getActiveGroupFiltersFromFiltersState(filtersDataState);

    if (!spreadsheetData) {
      return [];
    }

    Object.keys(filtersState.settings).forEach(spreadsheetIdAttr => {
      const spreadsheetId: number = parseInt(spreadsheetIdAttr, 10);
      const spreadsheetItem = filtersState.settings[spreadsheetId];

      if (!spreadsheetItem) {
        return;
      }

      Object.keys(spreadsheetItem).forEach(columnId => {
        const spreadsheetColumnId = { spreadsheetId, columnId };
        const columnName = getMatchupDataColumnName(matchupData, spreadsheetColumnId);
        if (columnName === null || handleIsColumnRestricted(spreadsheetColumnId)) {
          return;
        }

        const item = spreadsheetItem[columnId];

        if (!item) {
          return;
        }

        let panelBody: ReactNode = null;
        switch (item.type) {
          default:
          case DataType.TEXT: {
            panelBody = getTextFilterPanelBody(columnName,
              filtersDataState,
              spreadsheetColumnId,
              (newConjunction) => {
                dispatch(
                  mapSettingsColumnsFilterChangeTextConjunction(spreadsheetColumnId, newConjunction)
                );
              },
              (filters) => {
                const addedFilter: TextFilter | undefined = filters[filters.length - 1];
                if (addedFilter && isTextFilterOnList(addedFilter, filters.slice(0, filters.length - 1))) {
                  dispatch(
                    createAppError({
                      type: AppErrorType.General,
                      title: t('Duplicate Filter'),
                      errorTitle: t('Filter clause with the same value already exists'),
                    })
                  );

                  return;
                }

                const newTextFilterValues = filters.map(item => createFilterTextValuesFromTextFilter(item));

                dispatch(
                  mapSettingsColumnsFilterSetText(spreadsheetColumnId, newTextFilterValues)
                );
              });
          }
            break;

          case DataType.GROUP: {
            panelBody = getRegularGroupFilterPanelBody(
              spreadsheetData,
              matchupData,
              {
                spreadsheetId,
                columnId,
                type: GroupingType.Text,
              },
              activeGroupFilters,
              (spreadsheetColumnId, groups) => {
                dispatch(mapSettingsColumnsFilterSetGroup(spreadsheetColumnId, groups));
              }
            );
            break;
          }

          case DataType.NUMBER: {
            panelBody = getNumberFilterPanelBody(
              spreadsheetData,
              spreadsheetColumnId,
              filtersDataState,
              (range) => {
                if (range === null) {
                  dispatch(mapSettingsColumnsFilterRemoveFilterValues(spreadsheetColumnId));
                }
                else {
                  dispatch(mapSettingsColumnsFilterSetNumber(spreadsheetColumnId, range));
                }
              },
            );
            break;
          }

          case DataType.ATTRIBUTE: {
            panelBody = getAttributeFilterPanel(
              spreadsheetData,
              spreadsheetColumnId,
              filtersDataState,
              columnName,
              newConjunction => {
                dispatch(
                  mapSettingsColumnsFilterChangeAttributeConjunction(spreadsheetColumnId, newConjunction)
                );
              },
              selectedAttributeIds => {
                dispatch(
                  mapSettingsColumnsFilterSetAttribute(spreadsheetColumnId, selectedAttributeIds)
                );
              }
            );
            break;
          }

          case DataType.DATE: {
            panelBody = getDateFilterPanel(
              spreadsheetData,
              spreadsheetColumnId,
              filtersDataState,
              (fromDate) => {
                dispatch(mapSettingsColumnsFilterSetDateFrom(
                  spreadsheetColumnId,
                  fromDate)
                );
              },
              (toDate) => {
                dispatch(mapSettingsColumnsFilterSetDateTo(
                  spreadsheetColumnId,
                  toDate)
                );
              },
            );
            break;
          }
        }

        if (panelBody) {
          results.push({
            title: columnName,
            body: panelBody,
          });
        }
      });
    });

    return results;
  }, [filtersDataState, spreadsheetData, filtersState.settings, matchupData, handleIsColumnRestricted, dispatch, t]);

  const clearAll = () => {
    dispatch(mapSettingsColumnsFilterClearFilters());
  };

  const filtersToShow = useMemo(() =>
    activeGroupingColumnsFilterItems.concat(filters),
  [activeGroupingColumnsFilterItems, filters]);

  return (
    <FilterToolComponent
      areFiltersOpen={isMapPresentational && areFiltersOpen}
      isLoading={isSpreadsheetDataLoading || isMapSettingsSyncInProgress}
      onClear={clearAll}
      onFilterSetup={() => openModal()}
      showAdminControls={!isMapPresentational}
    >
      {filtersToShow}
    </FilterToolComponent>
  );
};
