import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';

export type ImpersonateResponse = {
  message: string;
  access_token_expiration: string;
};

export const impersonate = (expires: string, hash: string, signature: string): Promise<ImpersonateResponse> => {
  const requestUrl = `/api/impersonate?expires=${expires}&hash=${hash}&signature=${signature}`;

  return apiGet(requestUrl);
};

export const loginAsMemberRequest = (
  clientId: number,
  memberId: number,
): Promise<ImpersonateResponse> => {
  const requestUrl = `/api/clients/${clientId}/impersonate/${memberId}`;
  return apiPost(requestUrl);
};

export const endImpersonation = (): Promise<void> => {
  const requestUrl = '/api/impersonate/exit';
  return apiPost(requestUrl);
};
