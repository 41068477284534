import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SelectGroupProps } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import {
  type MarkerSettings,
  type MarkersVisualSettings,
  type TemporaryMarkerSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { filterNonNumericBuckets } from '~/_shared/utils/grouping/grouping.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  getInitialMarker,
  getMarkerVisuals,
  type MarkersVisualsFunctionProps,
  markerVisualSettingsToTemporaryMarkerVisualSettings,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { omit } from '~/_shared/utils/object/omit';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useMapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import {
  useMapSettingsMarkersGeneralSelector, useMapSettingsMarkersTextureAnchors,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { type NumericBucketWithRange } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE,
  DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED,
} from '../map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.constants';
import { useModal } from '../modal/useModal.hook';
import { mapSettingsMarkersUpdateActiveMarkerIndicator } from '../store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import { useMapSettingsActiveMarkerIndicatorSettingsSelector } from '../store/mapSettings/markers/mapSettingsMarkers.selectors';
import { type ActiveMarkerIndicatorSettings } from '../store/mapSettings/markers/mapSettingsMarkers.state';
import { ActiveMarkerIndicatorContextProvider } from './activeMarkerIndicatorContext';
import { CustomizationLevel } from './customizeLabelAndMarker.types';
import { type CustomizationLevelProps } from './customizeLabelAndMarkerModal.container';
import {
  CustomizeLabelAndMarkerSettingsModalComponent,
  MarkerModalTabs,
} from './customizeLabelAndMarkerSettingsModal.component';
import { type CustomizeMarkerTab } from './customizeMarker/customizeMarker.component';
import { useActiveCustomMarkerTextures } from './customizeMarker/useActiveCustomMarkerTextures';

const useCreateTemporaryMarkersVisuals = () => {
  const { getMarkerTextureAnchor } = useMapSettingsMarkersTextureAnchors();
  const { defaultMarkerSetId } = useMapSettingsMarkersGeneralSelector();

  const createTemporaryMarkesVisuals = useCallback(
    (markersVisualsProps: MarkersVisualsFunctionProps): TemporaryMarkerSettings => {
      const markerSettings = getMarkerVisuals({
        ...markersVisualsProps,
        visualSettingsType: MarkerSettingType.Marker,
      });

      const initialisedMarkerVisuals = markerSettings.marker ?? getInitialMarker(defaultMarkerSetId);
      const temporaryMarkerSettings = markerVisualSettingsToTemporaryMarkerVisualSettings(
        initialisedMarkerVisuals, markersVisualsProps.fileAttachments, getMarkerTextureAnchor);

      const label = getMarkerVisuals({
        ...markersVisualsProps,
        visualSettingsType: MarkerSettingType.Label,
      }).label;

      const aboveLabel = getMarkerVisuals({
        ...markersVisualsProps,
        visualSettingsType: MarkerSettingType.LabelAbove,
      }).aboveLabel;

      return {
        aboveLabel,
        label,
        marker: temporaryMarkerSettings,
        useMarker: markerSettings.useMarker,
      };
    }, [defaultMarkerSetId, getMarkerTextureAnchor]);

  return { createTemporaryMarkesVisuals };
};

export type CustomizeLabelAndMarkerSettingsModalContainerProps = ModalProps<{
  caption: string;
  customizationLevelProps: CustomizationLevelProps;
  defaultTab?: CustomizeMarkerTab;
  visualMarkersSettings: MarkersVisualSettings;
  groupInfo?: Readonly<{ // to figure out the initial visual settings
    hierarchyIndicator?: number;
    groupBuckets?: NumericBucketWithRange[];
    groupId?: string;
    bucketId?: number;
  }>;
  markerSettingsTypes: NonEmptyArray<MarkerSettingType>;
  spreadsheetRowId?: SpreadsheetRowId; // to figure out the initial visual settings
  optionalLabelDisabled?: boolean;
  colorCustomizationDisabled?: boolean;
  selectGroupProps?: SelectGroupProps;

  onSave?: (settings: MarkerSettings | null) => void;
  onReset: (visuals: MarkersVisualSettings) => MarkersVisualSettings;
}>;

export const CustomizeLabelAndMarkerSettingsModalContainer: FC<CustomizeLabelAndMarkerSettingsModalContainerProps> = props => {
  const { groupInfo, markerSettingsTypes, spreadsheetRowId, visualMarkersSettings, optionalLabelDisabled } = props;

  const mapSettingsGroupingState = useSelector(state => state.map.mapSettings.data.grouping);
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const { activateTexture } = useActiveCustomMarkerTextures();
  const files = useFileUrls();
  const [resetActive, setResetActive] = useState(false);
  const fileAttachments = useMapSettingsFileAttachmentsMapSelector();
  const defaultIndicatorSettings = useMapSettingsActiveMarkerIndicatorSettingsSelector();
  const dispatch = useDispatch();

  const [activeIndicatorSettings, setActiveIndicatorSettings] = useState<ActiveMarkerIndicatorSettings>({
    ...defaultIndicatorSettings,
    ...(defaultIndicatorSettings.size ? {} : { size: DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE }),
    ...(defaultIndicatorSettings.speed !== undefined ? {} : { speed: DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED }),
  });
  const { replaceCurrentModal: replaceCurrentModalWithRestoreDefaultModal } = useModal(ModalType.RestoreDefaultMarkerCustomizations);
  const { createTemporaryMarkesVisuals } = useCreateTemporaryMarkersVisuals();

  const getVisualProps = useCallback((visuals: MarkersVisualSettings): MarkersVisualsFunctionProps => ({
    groupId: groupInfo?.groupId,
    bucketId: groupInfo?.bucketId,
    hierarchyIndicator: groupInfo?.hierarchyIndicator,
    isLegend: false,
    mapSettingsGroupingState,
    numericBucketsWithRange: filterNonNumericBuckets(groupInfo?.groupBuckets),
    spreadsheetData: spreadsheetData || undefined,
    spreadsheetRowId,
    visualMarkersSettings: visuals,
    fileAttachments,
    files,
  }), [groupInfo, fileAttachments, files, mapSettingsGroupingState, spreadsheetData, spreadsheetRowId]);

  const initialMarkersVisuals = useMemo(() => (
    createTemporaryMarkesVisuals(getVisualProps(visualMarkersSettings))
  ), [createTemporaryMarkesVisuals, getVisualProps, visualMarkersSettings]);

  const [temporaryMarkersVisuals, setTemporaryMarkersVisuals] = useState(() => initialMarkersVisuals);

  const onChange = useCallback((updatedSettings: TemporaryMarkerSettings) => {
    setTemporaryMarkersVisuals(updatedSettings);
    setResetActive(false);
  }, []);

  const labelAndMarkerSettingsEnabledAtTheSameTime = useMemo(() => (
    [MarkerSettingType.Marker, MarkerSettingType.Label].every(t => markerSettingsTypes.includes(t))
  ), [markerSettingsTypes]);

  const handleSave = useCallback(({ activeTab }: { activeTab: MarkerModalTabs }) => {
    const onSave = props.onSave;

    if (!onSave) {
      return;
    }

    setResetActive(false);

    dispatch(mapSettingsMarkersUpdateActiveMarkerIndicator(activeIndicatorSettings));

    if (resetActive) {
      onSave(null);
      return;
    }

    const newSettings = {
      ...temporaryMarkersVisuals,
      useMarker: activeTab === MarkerModalTabs.Marker && labelAndMarkerSettingsEnabledAtTheSameTime,
    };

    if (newSettings && !newSettings.marker) {
      onSave(omit(newSettings, ['marker']));
      return;
    }

    if (newSettings && newSettings.marker) {
      if (newSettings.marker.styleType === MarkerStyleType.STANDARD) {
        const standardMarkerVisualSettings = {
          ...omit(newSettings.marker, ['fileAttachmentId', 'fileId', 'anchor']),
        };

        const finalSettings = {
          ...newSettings,
          marker: standardMarkerVisualSettings,
        };

        onSave(finalSettings);
      }
      else if (newSettings.marker.styleType === MarkerStyleType.CUSTOM) {
        const fileAttachmentId = newSettings.marker.fileId ? activateTexture(newSettings.marker.fileId, newSettings.marker.anchor) : null;

        if (fileAttachmentId !== null) {
          const customMarkerVisualSettings = {
            ...omit(newSettings.marker, ['styleId', 'fileId', 'anchor']),
            fileAttachmentId,
          };

          const finalSettings = {
            ...newSettings,
            marker: customMarkerVisualSettings,
          };

          onSave(finalSettings);
        }
      }
    }

  }, [dispatch, activeIndicatorSettings, props.onSave, resetActive, temporaryMarkersVisuals,
    labelAndMarkerSettingsEnabledAtTheSameTime, activateTexture,
  ]);

  const onReset = useCallback(() => {
    setResetActive(true);

    const onReset = props.onReset;
    const updatedSettings = onReset(visualMarkersSettings);

    const visualProps = getVisualProps(updatedSettings);
    setTemporaryMarkersVisuals(createTemporaryMarkesVisuals(visualProps));

    const activeGroupColumn = mapSettingsGroupingState.activeGroupColumns[props.groupInfo?.hierarchyIndicator || 0];
    const restoreMode = props.customizationLevelProps.customizationLevel === CustomizationLevel.All ?
      markerSettingsTypes : undefined;
    replaceCurrentModalWithRestoreDefaultModal({
      groupInfo: props.groupInfo && activeGroupColumn ? {
        bucketId: props.groupInfo.bucketId,
        groupId: props.groupInfo.groupId,
        groupingColumn: activeGroupColumn,
        hierarchyIndicator: props.groupInfo.hierarchyIndicator,
      } : undefined,
      spreadsheetRowId: props.spreadsheetRowId,
      restoreMode,
    });

  }, [createTemporaryMarkesVisuals, getVisualProps, mapSettingsGroupingState.activeGroupColumns, markerSettingsTypes, props.customizationLevelProps.customizationLevel, props.groupInfo, props.onReset, props.spreadsheetRowId, replaceCurrentModalWithRestoreDefaultModal, visualMarkersSettings]);

  const handleChangeActiveMarkerIndicatorSettings = useCallback((settings: Partial<ActiveMarkerIndicatorSettings>) => {
    setActiveIndicatorSettings({
      ...activeIndicatorSettings,
      ...settings,
    });
  }, [activeIndicatorSettings]);

  // When color customization is disabled, reset the marker color to the initial value
  useEffect(() => {
    if (props.colorCustomizationDisabled) {
      setTemporaryMarkersVisuals(state => ({
        ...state,
        ...(state.marker && initialMarkersVisuals.marker ? {
          marker: {
            ...state.marker,
            selectedColor: initialMarkersVisuals.marker?.selectedColor,
            opacity: initialMarkersVisuals.marker?.opacity,
          },
        } : {}),
      }));
    }
  }, [props.colorCustomizationDisabled, initialMarkersVisuals.marker]);

  return (
    <ActiveMarkerIndicatorContextProvider
      activeMarkerIndicatorSettings={activeIndicatorSettings}
      changeActiveMarkerIndicatorSettings={handleChangeActiveMarkerIndicatorSettings}
    >
      <CustomizeLabelAndMarkerSettingsModalComponent
        caption={props.caption}
        colorCustomizationDisabled={props.colorCustomizationDisabled}
        customizationLevelProps={props.customizationLevelProps}
        defaultTab={props.defaultTab}
        isOpen={props.isOpen}
        markerSettings={temporaryMarkersVisuals}
        markerSettingsTypes={markerSettingsTypes}
        optionalLabelDisabled={optionalLabelDisabled}
        onClose={props.onClose}
        onReset={onReset}
        onSave={props.onSave ? handleSave : undefined}
        onChange={onChange}
        selectGroupProps={props.selectGroupProps}
      />
    </ActiveMarkerIndicatorContextProvider>
  );
};
