export const MAP_SETTINGS_FETCH_DATA_REQUEST = 'MAP_SETTINGS_FETCH_DATA_REQUEST';
export const MAP_SETTINGS_FETCH_DATA_SUCCESS = 'MAP_SETTINGS_FETCH_DATA_SUCCESS';
export const MAP_SETTINGS_FETCH_DATA_ERROR = 'MAP_SETTINGS_FETCH_DATA_ERROR';

export const MAP_SETTINGS_SYNC_REQUEST = 'MAP_SETTING/SYNC_REQUEST';
export const MAP_SETTINGS_SYNC_SUCCESS = 'MAP_SETTING/SYNC_SUCCESS';
export const MAP_SETTINGS_SYNC_ERROR = 'MAP_SETTING/SYNC_ERROR';
export const MAP_SETTINGS_SYNC_CANCEL = 'MAP_SETTING/SYNC_CANCEL';

export const MAP_SETTINGS_REMOVE_ACTIVE_ITEMS = 'MAP_SETTINGS_REMOVE_ACTIVE_ITEMS'; // removes all the items that are shown in action bar
