import { css } from '@emotion/react';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { canMemberManageSubscription } from '~/clientTeamManagement/memberRoles.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import {
  useClientOwner, useUserCurrentClientSelector, useUserDataSelector, useUserIdSelector,
} from '~/store/userData/userData.selectors';
import { useNotifications } from '../../components/topUnderbar/notifications/Notifications';
import {
  excludedRoutesFromLicenseCheck, SUBSCRIPTION_ROUTE,
} from '../../constants/routes';
import { useTheme } from '../../themes/theme.hooks';
import { MILISECONDS_PER_DAY } from '../../utils/date/date.helpers';
import { openEmailDraft } from '../../utils/email/email.helpers';
import { useTranslation } from '../../utils/hooks';
import { useStorageService } from '../../utils/storageService';
import { ClientLicenseState } from './license';

const GRACE_PERIOD_NOTIFICATION_SHOWN_TIME_KEY = 'GRACE_PERIOD_NOTIFICATION_SHOWN_TIME';

const notificationContentStyle = css({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: 10,
  justifyContent: 'space-between',
});
const notificationTextStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const usePushPaymentIssueNotification = () => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { pushNotification, closeNotification } = useNotifications();
  const userData = useUserDataSelector();
  const canManageSubscription = canMemberManageSubscription(userData.clientRole);
  const clientOwner = useClientOwner();
  const { openModal: openManagementUnavailableModal } = useModal(ModalType.SubscriptionManagementUnavailable);
  const migrationFinished = useUserCurrentClientSelector()?.migrationDone;

  const notificationKey = 'gracePeriodNotification';

  const notificationAction = useMemo(() => ({
    text: canManageSubscription ? t('Resolve Now') : t('Contact Owner'),
    onClick: () => {
      if (canManageSubscription) {
        if (migrationFinished) {
          openUrlInNewTab(SUBSCRIPTION_ROUTE);
        }
        else {
          openManagementUnavailableModal();
        }
      }
      else {
        openEmailDraft(clientOwner?.email ?? '', 'Maptive Licence Expired');
      }
    },
  }), [canManageSubscription, clientOwner?.email, migrationFinished, openManagementUnavailableModal, t]);

  return useCallback(({ isClosable = true }: { isClosable?: boolean } = {}) => {
    return pushNotification({
      key: notificationKey,
      icon: faWarning,
      colors: {
        backgroundColor: theme.alertColors.dangerSecondary,
        borderColor: theme.borderColors.error,
        iconColor: theme.textColors.danger,
        color: theme.textColors.danger,
      },
      padding: '10px 16px 10px 6px',
      children:
  <div css={notificationContentStyle}>
    <div css={notificationTextStyle}>
      <div><strong>{t('Payment issue')}</strong></div>
      <div css={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
        {canManageSubscription ? t('SubscriptionManagement.LicenseIssue') : t('SubscriptionManagement.LicenseIssueMember')}
      </div>
    </div>
    <ButtonComponent
      buttonStyle={ButtonStyle.Success}
      text={notificationAction.text}
      onClick={notificationAction.onClick}
    />
  </div>,
      onClose: isClosable ? () => closeNotification(notificationKey) : undefined,
    });
  }, [canManageSubscription, closeNotification, notificationAction.onClick, notificationAction.text, pushNotification, t,
    theme.alertColors.dangerSecondary, theme.borderColors.error, theme.textColors.danger]);
};

export const useSubscriptionChecker = () => {
  const location = useLocation();
  const pushPaymentIssueNotification = usePushPaymentIssueNotification();
  const isPresentationalMap = useIsMapPresentationalSelector();
  const storageService = useStorageService();
  const currentClient = useUserCurrentClientSelector();
  const userId = useUserIdSelector();
  const { openModal, closeModal } = useModal(ModalType.SubscriptionExpired);

  const licenseState = currentClient?.license.state;
  const gracePeriodLastShownNotificationKey = `${GRACE_PERIOD_NOTIFICATION_SHOWN_TIME_KEY}_${userId}`;

  const gracePeriodCheck = useCallback(() => {
    if (licenseState === ClientLicenseState.GRACE_PERIOD) {
      const isPaidAutomatically = currentClient?.license.paymentType === 'card';

      if (isPaidAutomatically) {
        pushPaymentIssueNotification({ isClosable: false });
      }
      else {
        const lastShownNotificationDate = storageService.getLocalStorageItem(gracePeriodLastShownNotificationKey);
        const gracePeriodNotificationLastShownAgo = Date.now() - Number(lastShownNotificationDate);

        const lastChangeAgo = Date.now() - (currentClient?.license.lastStateChange?.getTime() ?? 0);
        const changeInLast30days = lastChangeAgo < 30 * MILISECONDS_PER_DAY;
        const changeInLast45days = lastChangeAgo < 45 * MILISECONDS_PER_DAY;

        if (changeInLast30days) {
          if (gracePeriodNotificationLastShownAgo > 30 * MILISECONDS_PER_DAY) {
            pushPaymentIssueNotification();
            storageService.setLocalStorageItem(gracePeriodLastShownNotificationKey, Date.now().toString());
          }
        }
        else if (changeInLast45days) {
          if (gracePeriodNotificationLastShownAgo >= MILISECONDS_PER_DAY) {
            pushPaymentIssueNotification();
            storageService.setLocalStorageItem(gracePeriodLastShownNotificationKey, Date.now().toString());
          }
        }
        else {
          pushPaymentIssueNotification({ isClosable: false });
        }
      }
    }
  }, [storageService, currentClient?.license.lastStateChange, currentClient?.license.paymentType, gracePeriodLastShownNotificationKey, licenseState, pushPaymentIssueNotification]);

  const checkExpiredLicenses = useCallback(() => {
    if (!licenseState) {
      return;
    }

    if ([ClientLicenseState.EXPIRED, ClientLicenseState.FAILED_PAY_PERIOD].includes(licenseState)) {
      openModal();
    }
  }, [licenseState, openModal]);

  const isOnRouteWithoutExpiredModal = excludedRoutesFromLicenseCheck.includes(location.pathname);

  useEffect(() => {
    if (!isOnRouteWithoutExpiredModal) {
      checkExpiredLicenses();
      if (!isPresentationalMap) {
        gracePeriodCheck();
      }
    }
    else {
      closeModal();
    }
  }, [checkExpiredLicenses, closeModal, gracePeriodCheck, isOnRouteWithoutExpiredModal, isPresentationalMap]);

  const gracePeriodCheckRef = useRef(gracePeriodCheck);
  gracePeriodCheckRef.current = gracePeriodCheck;

  useEffect(() => {
    if (!isPresentationalMap) {
      const onTabVisibilitychange = () => {
        if (document.visibilityState === 'visible') {
          gracePeriodCheckRef.current();
        }
      };

      document.addEventListener('visibilitychange', onTabVisibilitychange);

      return () => document.removeEventListener('visibilitychange', onTabVisibilitychange);
    }

    return undefined;
  }, [isPresentationalMap]);

};
