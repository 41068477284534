import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  boundaryTerritoryGroupCreateSettingsStyleDefaults, defaultBoundaryTerritoryGroupResponseSettings,
} from '~/boundary/settings/defaultBoundarySettings';
import { useGlobalBoundaryGroups } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { boundaryTerritoryGroupsCreateRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { BoundaryMatchingType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import {
  getBoundaryBucketTypeForBoundaryTerritoryType,
  getCalculateBucketFunction,
} from '../../boundaryTerritoryGroup.helpers';
import { useVerifyNumericFillData } from '../../fill/useVerifyNumericFillData';
import { useBoundaryGroupUsage } from '../../hooks/useBoundaryGroupUsage';
import { useSpreadsheetColumnsOptions } from '../../hooks/useSpreadsheetColumnsOptions';
import {
  type LoadGlobalBoundaryGroupData,
  LoadGlobalBoundaryGroupPaneComponent,
} from './loadGlobalBoundaryGroupPane.component';

type LoadGlobalBoundaryGroupPaneContainerProps = Readonly<{
  checkIsNumeric: (columnId: string | null, spreadsheetId: number | null) => Promise<boolean>;
  resetForm: () => void;
}>;

export const LoadGlobalBoundaryGroupPaneContainer: FC<LoadGlobalBoundaryGroupPaneContainerProps> = props => {
  const globalBoundaryGroups = useGlobalBoundaryGroups();
  const { isBoundaryGroupUsedOnMap } = useBoundaryGroupUsage();
  const { verifyNumericFillData } = useVerifyNumericFillData({ checkIsNumeric: props.checkIsNumeric });
  const spreadsheetColumnOptions = useSpreadsheetColumnsOptions();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();

  const handleAddBoundary = (data: LoadGlobalBoundaryGroupData) => {
    if (mapId === null) {
      return;
    }

    if (data.boundaryTerritoryType === BoundaryTerritoryType.Manual) {
      dispatch(boundaryTerritoryGroupsCreateRequest({
        map_id: mapId,
        boundary_group_id: data.boundaryGroupId,
        matchings: {
          matching_type: BoundaryMatchingType.Geometry,
        },
        settings: {
          ...defaultBoundaryTerritoryGroupResponseSettings,
          boundary_territories: [],
          boundary_territory_type: BoundaryTerritoryType.Manual,
          style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
          calculate_bucket_function: getCalculateBucketFunction(BoundaryTerritoryType.Manual),
        },
      }));

      props.resetForm();
    }

    if (data.boundaryTerritoryType === BoundaryTerritoryType.Numeric) {
      verifyNumericFillData(data.boundaryFillData)
        .then(isNumeric => {
          if (!isNumeric || !data.boundaryFillData || data.boundaryGroupId === null) {
            return;
          }

          dispatch(boundaryTerritoryGroupsCreateRequest({
            map_id: mapId,
            boundary_group_id: data.boundaryGroupId,
            matchings: {
              matching_type: BoundaryMatchingType.Geometry,
            },
            settings: {
              ...defaultBoundaryTerritoryGroupResponseSettings,
              boundary_territory_type: BoundaryTerritoryType.Numeric,
              bucket_type: getBoundaryBucketTypeForBoundaryTerritoryType(BoundaryTerritoryType.Numeric) ?? undefined,
              style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
              bucket_column_id: data.boundaryFillData.columnId,
              boundary_territories: [],
              calculate_bucket_function: getCalculateBucketFunction(
                BoundaryTerritoryType.Numeric,
                data.numericCalculateBucketFunction,
              ),
            },
          }));

          props.resetForm();
        });
    }

    if (data.boundaryTerritoryType === BoundaryTerritoryType.Groups) {
      if (!data.boundaryFillData) {
        return;
      }

      dispatch(boundaryTerritoryGroupsCreateRequest({
        map_id: mapId,
        boundary_group_id: data.boundaryGroupId,
        matchings: {
          matching_type: BoundaryMatchingType.Geometry,
        },
        settings: {
          ...defaultBoundaryTerritoryGroupResponseSettings,
          boundary_territory_type: BoundaryTerritoryType.Groups,
          bucket_type: getBoundaryBucketTypeForBoundaryTerritoryType(BoundaryTerritoryType.Groups) ?? undefined,
          style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
          bucket_column_id: data.boundaryFillData.columnId,
          boundary_territories: [],
          calculate_bucket_function: getCalculateBucketFunction(BoundaryTerritoryType.Groups),
        },
      }));

      props.resetForm();
    }

    if (data.boundaryTerritoryType === BoundaryTerritoryType.Density) {
      dispatch(boundaryTerritoryGroupsCreateRequest({
        map_id: mapId,
        boundary_group_id: data.boundaryGroupId,
        matchings: {
          matching_type: BoundaryMatchingType.Geometry,
        },
        settings: {
          ...defaultBoundaryTerritoryGroupResponseSettings,
          boundary_territory_type: BoundaryTerritoryType.Density,
          bucket_type: getBoundaryBucketTypeForBoundaryTerritoryType(BoundaryTerritoryType.Density) ?? undefined,
          style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
          boundary_territories: [],
          calculate_bucket_function: getCalculateBucketFunction(BoundaryTerritoryType.Density),
        },
      }));

      props.resetForm();
    }

    if (data.boundaryTerritoryType === BoundaryTerritoryType.Demographic) {
      if (data.demographicId === null) {
        return;
      }

      dispatch(boundaryTerritoryGroupsCreateRequest({
        map_id: mapId,
        boundary_group_id: data.boundaryGroupId,
        matchings: {
          matching_type: BoundaryMatchingType.Geometry,
        },
        settings: {
          ...defaultBoundaryTerritoryGroupResponseSettings,
          demographic_id: data.demographicId,
          boundary_territory_type: BoundaryTerritoryType.Demographic,
          bucket_type: getBoundaryBucketTypeForBoundaryTerritoryType(BoundaryTerritoryType.Demographic) ?? undefined,
          style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
          boundary_territories: [],
          calculate_bucket_function: getCalculateBucketFunction(BoundaryTerritoryType.Demographic),
        },
      }));

      props.resetForm();
    }
  };

  return (
    <LoadGlobalBoundaryGroupPaneComponent
      globalBoundaryGroups={globalBoundaryGroups}
      isBoundaryGroupUsedOnMap={isBoundaryGroupUsedOnMap}
      spreadsheetColumnOptions={spreadsheetColumnOptions}
      onAddBoundary={handleAddBoundary}
    />
  );
};
