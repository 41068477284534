import { type FC } from 'react';
import { useMapSettingsOpenLinksInSameWindow } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import {
  BubbleItemWithIconComponent, type BubbleItemWithIconProps,
} from './bubbleItemWithIcon.component';

type BubbleItemWithIconContainerProps = BubbleItemWithIconProps;

export const BubbleItemWithIconContainer: FC<BubbleItemWithIconContainerProps> = (props) => {
  const openLinksInSameWindow: boolean = useMapSettingsOpenLinksInSameWindow();

  return (
    <BubbleItemWithIconComponent
      {...props}
      openLinksInNewTab={!openLinksInSameWindow}
    />
  );
};
