import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faLocation, faMapPin,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import {
  DropDownItemSize, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  InputSize, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithInputBarComponent } from '~/_shared/components/colorPicker/colorPickerWithInputBar/colorPickerWithInputBar.component';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import type { SliderValue } from '~/_shared/components/slider/slider.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { type SelectGroupProps } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import {
  type GroupingColumn, type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import {
  type CustomMarkerSettings,
  MarkerAnchorPosition,
  type MarkersMap,
  MarkerStyleType,
  type MarkerVisualSettingsColor,
  type StandardMarkerSettings,
  type TemporaryMarkerVisualSettings,
} from '~/_shared/types/marker.types';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import {
  MARKER_SIZE_MAX, MARKER_SIZE_MIN,
} from '~/_shared/utils/markers/markers.constants';
import { MARKER_DEFAULT_COLOR } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { FPS30 } from '~/_shared/utils/throttle/throttle';
import {
  DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED,
  MAX_ACTIVE_MARKER_INDICATOR_SIZE,
  MAX_ACTIVE_MARKER_INDICATOR_SPEED,
  MIN_ACTIVE_MARKER_INDICATOR_SIZE,
  MIN_ACTIVE_MARKER_INDICATOR_SPEED,
} from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.constants';
import { useActiveMarkerIndicatorContext } from '../../activeMarkerIndicatorContext';
import { CustomizationLevel } from '../../customizeLabelAndMarker.types';
import { type CustomizationLevelProps } from '../../customizeLabelAndMarkerModal.container';
import { CustomizeMarkerTab } from '../customizeMarker.component';
import { PreviewTileComponent } from '../previewTiles/previewTile.component';
import { useHandleSliderOption } from './useHandleSliderOption.hook';

const VIEW_BOX_DIMENSION = 100;
const ICON_DIMENSION = 48;
const DISABLED_TOOLTIP_DELAY_MILLISECONDS = 250;

const labelAndColorStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
});

const labelPaneStyle = ({ optionalLabelDisabled, theme }: ThemeProps<{
  optionalLabelDisabled: boolean | undefined;
}>) => css({
  width: '100%',
  opacity: optionalLabelDisabled ? theme.opacity.disabled : undefined,
  pointerEvents: optionalLabelDisabled ? 'none' : 'all',
});

const colorPaneStyle = ({ isCustom, theme }: ThemeProps<{ isCustom: boolean }>) => css({
  opacity: isCustom ? theme.opacity.disabled : undefined,
  pointerEvents: isCustom ? 'none' : 'all',
  width: '100%',
});

const customizationContainerStyle = css({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
    'preview options'
    'size label'
  `,
  columnGap: 20,
  rowGap: 15,
  position: 'relative',
  width: '100%',
  padding: 20,
});

const previewStyle = css({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const optionsAreaStyle = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: 8,
  maxWidth: 280,
});

const topPartRow = css({
  width: '100%',
});

const checkboxRowStyle = css(topPartRow, {
  marginTop: 20,
  display: 'flex',
  gap: 8,
});

const mainLabelStyle = css({
  cursor: 'default',
  fontSize: 16,
  textTransform: 'uppercase',
  marginBottom: 4,
});

const anchorSelectorStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const anchorOptionsStyle = css({
  gap: 16,
  marginTop: 4,
});

const iconWithIndicatorStyle = ({ dimension }: { readonly dimension: number }) => css({
  height: dimension,
  width: dimension,
});

const iconStyle = (theme: Theme) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: 4,
  color: theme.textColors.secondary,
  fontSize: 32,
  height: '100%',
  width: '100%',
});

const svgStyle = {
  overflow: 'visible',
};

const circleStyle = (theme: Theme) => ({
  stroke: theme.lineColors.contrastGreen,
});

const fullWidthStyle = css({
  width: '100%',
  maxWidth: '100%',
});

const smallLabelStyle = css({
  cursor: 'default',
  fontSize: 10,
  textTransform: 'none',
});

const sliderOpacityStyle = css(fullWidthStyle, {
  paddingTop: 11,
  flex: 1,
});

const sliderStyle = css({
  paddingBottom: 0,
  width: '100%',
});

const sliderInputStyle = css({
  marginLeft: 18,
  width: 90,
});

const sliderLabelsStyle = css({ visibility: 'hidden' });

const unitStyles = css({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

const pxUnit = (
  <div css={unitStyles}>
    px
  </div>
);

const percentUnit = (
  <div css={unitStyles}>
    %
  </div>
);

export type MarkerOptionsChange = Readonly<{
  opacity?: number;
  selectedColor?: string;
  labelText?: string;
  size?: number;
  anchor?: MarkerAnchorPosition;
}>;

type CustomizationOptionsProps = Partial<MarkerVisualSettingsColor> & Readonly<{
  activeTab: CustomizeMarkerTab;
  colorCustomizationDisabled?: boolean;
  customMarkers: ReadonlyArray<CustomMarkerSettings>;
  customizationLevelProps: CustomizationLevelProps;
  labelAbove: LabelVisualSetting;
  visualSettings: TemporaryMarkerVisualSettings;
  optionalLabelDisabled?: boolean;
  standardMarkers: ReadonlyArray<StandardMarkerSettings>;
  selectGroupProps?: SelectGroupProps;

  onModifyMarker: (settings: MarkerOptionsChange) => void;
}>;

export const CustomizationOptionsComponent: React.FC<CustomizationOptionsProps> = props => {
  const {
    activeTab,
    visualSettings,
    onModifyMarker,
    opacity,
    selectedColor,
    optionalLabelDisabled,
    customizationLevelProps,
    selectGroupProps,
  } = props;
  const { size, labelText, styleType } = visualSettings;

  const activeMarkerIndicatorData = useActiveMarkerIndicatorContext();

  const isCustom = styleType === MarkerStyleType.CUSTOM;

  const theme = useTheme();
  const [t] = useTranslation();

  const onMarkerSizeChange = useCallback((newValue: number) => {
    onModifyMarker({ size: newValue });
  }, [onModifyMarker]);

  const markerSizeSliderHandlers = useHandleSliderOption(size, onMarkerSizeChange, MARKER_SIZE_MIN, MARKER_SIZE_MAX);

  const onIndicatorSizeChange = useCallback((newValue: number) => {
    activeMarkerIndicatorData.changeActiveMarkerIndicatorSettings({ size: newValue });
  }, [activeMarkerIndicatorData]);

  const indicatorSizeSliderHandlers = useHandleSliderOption(
    activeMarkerIndicatorData.activeMarkerIndicatorSettings.size,
    onIndicatorSizeChange,
    MIN_ACTIVE_MARKER_INDICATOR_SIZE,
    MAX_ACTIVE_MARKER_INDICATOR_SIZE,
  );

  const onIndicatorSpeedChange = useCallback((newValue: number) => {
    activeMarkerIndicatorData.changeActiveMarkerIndicatorSettings({ speed: newValue });
  }, [activeMarkerIndicatorData]);

  const indicatorSpeedSliderHandlers = useHandleSliderOption(
    activeMarkerIndicatorData.activeMarkerIndicatorSettings.speed,
    onIndicatorSpeedChange,
    MIN_ACTIVE_MARKER_INDICATOR_SPEED,
    MAX_ACTIVE_MARKER_INDICATOR_SPEED,
  );

  const previewStandardMarkers = useMemo(() => {
    const markers: MarkersMap<StandardMarkerSettings> = {};
    props.standardMarkers.forEach(marker => markers[marker.id] = marker);

    return markers;
  }, [props.standardMarkers]);

  const previewCustomMarkers = useMemo(() => {
    const markers: MarkersMap<CustomMarkerSettings> = {};
    props.customMarkers.forEach(marker => markers[marker.fileId] = marker);

    return markers;
  }, [props.customMarkers]);

  const previewMarker = useMemo(() => ({
    labelText: visualSettings.labelText,
    size: visualSettings.size,
    id: visualSettings.styleType === MarkerStyleType.CUSTOM ? visualSettings.fileId : visualSettings.styleId,
    styleType: visualSettings.styleType,
    anchor: visualSettings.styleType === MarkerStyleType.CUSTOM ? visualSettings.anchor : null,
  }), [visualSettings]);

  const changeMarkerAnchor = useCallback((newAnchor: MarkerAnchorPosition) =>
    visualSettings.styleType === MarkerStyleType.CUSTOM && onModifyMarker({ ...visualSettings, anchor: newAnchor }),
  [onModifyMarker, visualSettings]);

  const getAnchorIconTile = useCallback((icon: IconProp, circleProps: React.SVGProps<SVGCircleElement>) => (
    <div css={iconWithIndicatorStyle({ dimension: ICON_DIMENSION })}>
      <div css={iconStyle}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <svg
        viewBox={`-50, 50, ${VIEW_BOX_DIMENSION}, ${VIEW_BOX_DIMENSION}`}
        style={svgStyle}
      >
        <circle
          {...circleProps}
          cx="0"
          fillOpacity={0}
          style={circleStyle(theme)}
        />
      </svg>
    </div>
  ), [theme]);

  const svgCircleCenterProps: React.SVGProps<SVGCircleElement> = useMemo(() => ({
    cy: 0,
    r: 13,
    strokeWidth: '5',
  }), []);

  const svgCircleBottomProps: React.SVGProps<SVGCircleElement> = useMemo(() => ({
    cy: 30,
    r: 9,
    strokeWidth: '3',
  }), []);

  const radioItems: ReadonlyArray<RadioGroupItem<MarkerAnchorPosition>> = useMemo(() => [{
    value: MarkerAnchorPosition.CENTER,
    label: t('Align Center'),
    description: '',
    icon: getAnchorIconTile(faLocation, svgCircleCenterProps),
  }, {
    value: MarkerAnchorPosition.BOTTOM_CENTER,
    label: t('Align Bottom'),
    description: '',
    icon: getAnchorIconTile(faMapPin, svgCircleBottomProps),
  }], [getAnchorIconTile, svgCircleCenterProps, svgCircleBottomProps, t]);

  const selectedColorWithOpacity: ColorResult = useMemo(() => {
    const colorResult = createColor(selectedColor || MARKER_DEFAULT_COLOR);
    const colorWithOpacity = { ...colorResult.rgb, a: opacity };

    return createColor(colorWithOpacity);
  }, [opacity, selectedColor]);

  const onColorChange = useCallback((color: ColorResult) => {
    onModifyMarker({
      selectedColor: color.hex,
      opacity: color.rgb.a,
    });
  }, [onModifyMarker]);

  const onOpacitySliderChange = useThrottle(([value]: [number]) => {
    onModifyMarker({
      opacity: value / 100,
    });
  }, [onModifyMarker], FPS30);

  return (
    <div css={customizationContainerStyle}>
      <div css={[{ gridArea: 'preview' }, previewStyle]}>
        <PreviewTileComponent
          customMarkers={previewCustomMarkers}
          marker={previewMarker}
          labelAbove={props.labelAbove}
          opacity={visualSettings.opacity}
          selectedColor={visualSettings.selectedColor}
          standardMarkers={previewStandardMarkers}
        />
      </div>
      <div css={{ gridArea: 'size' }}>
        {activeTab === CustomizeMarkerTab.Markers && (
          <>
            <div css={mainLabelStyle}>{t('Select Marker Size')}</div>
            <div css={labelAndColorStyle}>
              <DebouncedUpdateComponent<SliderValue>
                value={[size || MARKER_SIZE_MIN]}
                wait={50}
                onChangeDebounced={markerSizeSliderHandlers.onSliderChange}
                render={(value, onChange) => (
                  <SliderWithValueLabelsComponent
                    css={sliderStyle}
                    labelStyleCommon={sliderLabelsStyle}
                    min={MARKER_SIZE_MIN}
                    max={MARKER_SIZE_MAX}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <TextInputComponent
                css={sliderInputStyle}
                onChange={markerSizeSliderHandlers.onTextInputChange}
                value={markerSizeSliderHandlers.inputValue}
                onBlur={() => markerSizeSliderHandlers.onInputBlur(markerSizeSliderHandlers.inputValue)}
                icon={null}
                rightContent={pxUnit}
              />
            </div>
          </>
        )}
      </div>
      <div css={[{ gridArea: 'options' }, optionsAreaStyle]}>
        {activeTab === CustomizeMarkerTab.Markers && customizationLevelProps.availableCustomizationLevels.length > 1 && (
          <div css={topPartRow}>
            <div css={mainLabelStyle}>
              {t('Customize What?')}
            </div>
            <RegularDropdownComponent
              css={fullWidthStyle}
              inputSize={InputSize.Medium}
              itemSize={DropDownItemSize.Large}
              onChange={customizationLevelProps.setCustomizationLevel}
              value={customizationLevelProps.customizationLevel}
              options={customizationLevelProps.availableCustomizationLevels}
            />
          </div>
        )}
        {activeTab === CustomizeMarkerTab.Markers && selectGroupProps?.setSelectedGroupingColumn && (
          <div css={topPartRow}>
            <div css={mainLabelStyle}>
              {t('Select Group Column')}
            </div>
            <RegularDropdownComponent<GroupingColumn>
              css={fullWidthStyle}
              inputSize={InputSize.Medium}
              itemSize={DropDownItemSize.Large}
              onChange={selectGroupProps.setSelectedGroupingColumn}
              value={selectGroupProps.selectedGroupingColumn}
              options={selectGroupProps.availableGroupingColumns}
              placeholder={t('No Group Selected')}
            />
          </div>
        )}
        {activeTab === CustomizeMarkerTab.Markers && selectGroupProps?.setSelectedUniqueGroup && (
          <div css={topPartRow}>
            <div css={mainLabelStyle}>
              {t('Select Group')}
            </div>
            <RegularDropdownComponent<UniqueGroup>
              css={fullWidthStyle}
              inputSize={InputSize.Medium}
              itemSize={DropDownItemSize.Large}
              onChange={selectGroupProps.setSelectedUniqueGroup}
              value={selectGroupProps.selectedUniqueGroup}
              options={selectGroupProps.availableUniqueGroups}
              placeholder={t('No Group Selected')}
            />
          </div>
        )}
        {activeTab === CustomizeMarkerTab.Markers && previewMarker.styleType === MarkerStyleType.CUSTOM && (
          <div css={topPartRow}>
            <div css={anchorSelectorStyle}>
              <div css={[mainLabelStyle, css({ marginTop: 14 })]}>
                {t('Select Marker Position')}
              </div>
              <RadioGroupComponent
                css={anchorOptionsStyle}
                onValueChange={changeMarkerAnchor}
                selectedValue={previewMarker.anchor || MarkerAnchorPosition.BOTTOM_CENTER}
                items={radioItems}
              />
            </div>
          </div>
        )}
        {activeTab === CustomizeMarkerTab.Markers && previewMarker.styleType === MarkerStyleType.STANDARD && (
          <>
            <div css={topPartRow}>
              <div
                css={colorPaneStyle({ isCustom, theme })}
                aria-disabled={isCustom}
              >
                <div
                  css={[
                    mainLabelStyle,
                    css({ marginTop: 14, disabled: isCustom || props.colorCustomizationDisabled }),
                  ]}
                >
                  {t('Select Marker Color')}
                </div>
                <TooltipDeprComponent
                  css={fullWidthStyle}
                  delayShow={DISABLED_TOOLTIP_DELAY_MILLISECONDS}
                  tooltipContent={props.colorCustomizationDisabled && t('The color cannot be changed for All Markers while grouping is active.')}
                >
                  <DebouncedUpdateComponent
                    value={selectedColorWithOpacity}
                    onChangeDebounced={onColorChange}
                    wait={500}
                    render={(value, onChange) => (
                      <ColorPickerWithInputBarComponent
                        isDisabled={isCustom || props.colorCustomizationDisabled}
                        isFixed
                        onChange={onChange}
                        selectedColor={value}
                        displayAlpha={false}
                      />
                    )}
                  />
                </TooltipDeprComponent>
              </div>
            </div>
            <div css={topPartRow}>
              <div css={[mainLabelStyle, css({ marginTop: 14, disabled: props.colorCustomizationDisabled })]}>
                {t('Select Marker Opacity')}
              </div>
              <div css={labelAndColorStyle}>
                <div css={sliderOpacityStyle}>
                  <TooltipDeprComponent
                    css={fullWidthStyle}
                    delayShow={DISABLED_TOOLTIP_DELAY_MILLISECONDS}
                    tooltipContent={props.colorCustomizationDisabled && t('The opacity cannot be changed for All Markers while grouping is active.')}
                  >
                    <DebouncedUpdateComponent<SliderValue>
                      value={[(selectedColorWithOpacity?.rgb?.a ?? 1) * 100]}
                      onChangeDebounced={onOpacitySliderChange}
                      wait={50}
                      render={(value, onChange) => (
                        <SliderWithValueLabelsComponent
                          disabled={props.colorCustomizationDisabled}
                          value={value}
                          onChange={onChange}
                          currentValueOnTheRight
                          valueSuffix="%"
                        />
                      )}
                    />
                  </TooltipDeprComponent>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === CustomizeMarkerTab.Indicator && (
          <>
            <div css={topPartRow}>
              <div css={[mainLabelStyle, css({ fontSize: 14 })]}>
                {t('Select Indicator Color')}
              </div>
              <div>
                <ColorPickerWithInputBarComponent
                  onChange={(newColor) => {
                    activeMarkerIndicatorData.changeActiveMarkerIndicatorSettings({
                      color: newColor.hex,
                    });
                  }}
                  selectedColor={createColor(activeMarkerIndicatorData.activeMarkerIndicatorSettings.color)}
                  isFixed
                />
              </div>
            </div>
            <div css={topPartRow}>
              <div css={[mainLabelStyle, css({ fontSize: 14, marginTop: 14 })]}>
                {t('Select Indicator Size')}
              </div>
              <div css={labelAndColorStyle}>
                <DebouncedUpdateComponent<SliderValue>
                  value={[activeMarkerIndicatorData.activeMarkerIndicatorSettings.size ?? MIN_ACTIVE_MARKER_INDICATOR_SIZE]}
                  wait={50}
                  onChangeDebounced={indicatorSizeSliderHandlers.onSliderChange}
                  render={(value, onChange) => (
                    <SliderWithValueLabelsComponent
                      css={sliderStyle}
                      labelStyleCommon={sliderLabelsStyle}
                      min={MIN_ACTIVE_MARKER_INDICATOR_SIZE}
                      max={MAX_ACTIVE_MARKER_INDICATOR_SIZE}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <TextInputComponent
                  css={sliderInputStyle}
                  onChange={indicatorSizeSliderHandlers.onTextInputChange}
                  value={indicatorSizeSliderHandlers.inputValue}
                  onBlur={() => indicatorSizeSliderHandlers.onInputBlur(indicatorSizeSliderHandlers.inputValue)}
                  icon={null}
                  rightContent={pxUnit}
                />
              </div>
            </div>
            <div css={topPartRow}>
              <div css={[mainLabelStyle, css({ fontSize: 14, marginTop: 14 })]}>
                {t('Select Indicator Speed')}
              </div>
              <div css={labelAndColorStyle}>
                <DebouncedUpdateComponent<SliderValue>
                  value={[activeMarkerIndicatorData.activeMarkerIndicatorSettings.speed ?? DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED]}
                  wait={50}
                  onChangeDebounced={indicatorSpeedSliderHandlers.onSliderChange}
                  render={(value, onChange) => (
                    <SliderWithValueLabelsComponent
                      css={sliderStyle}
                      labelStyleCommon={sliderLabelsStyle}
                      min={MIN_ACTIVE_MARKER_INDICATOR_SPEED}
                      max={MAX_ACTIVE_MARKER_INDICATOR_SPEED}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <TextInputComponent
                  icon={null}
                  css={sliderInputStyle}
                  onChange={indicatorSpeedSliderHandlers.onTextInputChange}
                  value={indicatorSpeedSliderHandlers.inputValue}
                  onBlur={() => indicatorSpeedSliderHandlers.onInputBlur(indicatorSpeedSliderHandlers.inputValue)}
                  rightContent={percentUnit}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div css={{ gridArea: 'label' }}>
        {activeTab === CustomizeMarkerTab.Markers
          && customizationLevelProps.customizationLevel === CustomizationLevel.All
          && props.customizationLevelProps.setOverrideGroupMarkers
          && (
            <div css={checkboxRowStyle}>
              <CheckboxComponent
                isChecked={props.customizationLevelProps.overrideGroupMarkers}
                checkedSetter={() => props.customizationLevelProps.setOverrideGroupMarkers?.(!props.customizationLevelProps.overrideGroupMarkers)}
              />
              {t('Overwrite settings of marker groups')}
            </div>
          )}
        {activeTab === CustomizeMarkerTab.Markers && !optionalLabelDisabled && (
          <div css={labelAndColorStyle}>
            <div
              css={labelPaneStyle({ optionalLabelDisabled, theme })}
              aria-disabled={optionalLabelDisabled}
            >
              <div css={mainLabelStyle}>
                {t('Add Marker Label')} <span css={smallLabelStyle}>*{t('optional')}</span>
              </div>
              <TextInputComponent
                onChange={(value: string) => onModifyMarker({ labelText: value })}
                isDisabled={optionalLabelDisabled}
                value={optionalLabelDisabled ? undefined : (labelText || '')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
