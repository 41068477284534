import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS } from '~/_shared/components/locationFinderModal/locationFinderModal.component';
import { getBoundingBoxOfLatLngRows } from '~/_shared/utils/gis/boundingBox.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { mapComponentSetZoomToBounds } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { myLocationSelector } from '~/store/frontendState/mapTools/myLocation/myLocation.selectors';
import { setLocationFinderToolsState } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.actionCreators';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { getDistancesFromPointForSpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { type LatLng } from '../../types/latLng';
import {
  getLatLngFromAddress3rdPartyApi, getUserPosition,
} from '../../utils/geolocation/geolocation.helpers';
import { useTranslation } from '../../utils/hooks';

const MAX_LOCATIONS = LOCATION_FINDER_DEFAULT_NUMBER_OF_LOCATIONS;

export const useActivateLocationFinder = (props: {
  onSetLocationCallback?: () => void;
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const myLocation = useSelector(myLocationSelector);
  const rowsData = useLatLngSpreadsheetData();

  const [isLoading, setIsLoading] = useState(false);

  const setLocation = useCallback((latLng: LatLng, locationLimit: number | null) => {
    dispatch(setLocationFinderToolsState(latLng, locationLimit));

    const dataWithDistance = getDistancesFromPointForSpreadsheetData(rowsData.data, latLng);
    dispatch(mapComponentSetZoomToBounds(getBoundingBoxOfLatLngRows(
      latLng, dataWithDistance.slice(0, Math.min(locationLimit || MAX_LOCATIONS, MAX_LOCATIONS))
    )));

    const onSetLocationCallback = props.onSetLocationCallback;
    onSetLocationCallback?.();
  }, [dispatch, props.onSetLocationCallback, rowsData.data]);

  const activateLocationFinderForLocation = useCallback((address: string, locationLimit: number | null) => {
    if (address && address.length !== 0) {
      setIsLoading(true);
      getLatLngFromAddress3rdPartyApi(address)
        .then((results) => {
          setIsLoading(false);
          if (results[0]) {
            setLocation(results[0].latLng, locationLimit);
          }
        })
        .catch(() => {
          setIsLoading(false);
          dispatch(createAppError({
            type: AppErrorType.General,
            title: t('Unable to geocode address'),
            errorTitle: t('Check if your address is complete and correct, or please try with a different address.'),
          }));
        });
    }
    else {
      if (myLocation) {
        setLocation(myLocation, locationLimit);
      }
      else {
        setIsLoading(true);
        getUserPosition()
          .then(({ coords }) => {
            setIsLoading(false);
            setLocation({ lat: coords.latitude, lng: coords.longitude }, locationLimit);
          })
          .catch(() => {
            setIsLoading(false);
            dispatch(createAppError({
              type: AppErrorType.General,
              title: t('Unable To Find Your Location'),
              content: t('We were not able to find your location. Please check your browser settings and try again.'),
            }));
          });
      }
    }
  }, [dispatch, myLocation, setLocation, t]);

  return {
    findLocation: activateLocationFinderForLocation,
    isLoading,
  };
};
