import aimMarker from '../../../../../../assets/images/cursors/distance-calculator.png';
import iconHolderDark from '../../../../../../assets/images/iconMarkers/icon-holder-dark.png';
import iconHolderLight from '../../../../../../assets/images/iconMarkers/icon-holder-light.png';
import clusterMarker from '../../../../../../assets/images/markers/cluster.png';
import circle from '../../../../../../assets/images/markers/primary/circle/circle-250.png';
import circleThick from '../../../../../../assets/images/markers/primary/circle/circle-300-double-thickness.png';
import dot from '../../../../../../assets/images/markers/primary/dot/dot.png';
import transparentDot from '../../../../../../assets/images/markers/primary/dot/dot-opacity-50.png';
import locationMarker from '../../../../../../assets/images/markers/primary/location-marker/location-marker.png';
import xMark from '../../../../../../assets/images/markers/primary/x-mark/x-mark.png';
import proximityCenterBackground from '../../../../../../assets/images/markers/proximity-center/l2.png';
import proximityCenterTop from '../../../../../../assets/images/markers/proximity-center/l3.png';
import radarMarker from '../../../../../../assets/images/markers/radar.png';
import radarMarkerGlow from '../../../../../../assets/images/markers/radar-bright.png';

export enum PredefinedTemplate {
  Dot = 'default-dot',
  TransparentDot = 'transparent-dot',
  Circle = 'default-circle',
  CircleThick = 'default-circle-thick',
  XMark = 'default-x-mark',
  LocationMarker = 'location-marker',
  RadarMarker = 'radar-marker',
  RadarMarkerGlow = 'radar-marker-glow',
  Cluster = 'cluster-marker',
  ResizeController = 'resize-controller',
  ProximityCenter = 'proximity-center',
  AimMarker = 'aim-marker',
  IconMarkerHolderDark = 'icon-marker-holder-dark',
  IconMarkerHolderLight = 'icon-marker-holder-light'
}

export enum TemplateLayer {
  MAIN = 1,
  BACKGROUND = 2,
  SHADOW = 3,
}

export type TemplateLayers = {
  main: TemplateLayer;
  background: TemplateLayer | null;
  shadow: TemplateLayer | null;
};

export const createTemplateLayers = (main: TemplateLayer, background?: TemplateLayer, shadow?: TemplateLayer): TemplateLayers => ({
  main, background: background || null, shadow: shadow || null,
});

export const compareTemplateLayers = (tl1: TemplateLayers, tl2: TemplateLayers) => (
  tl1.main === tl2.main && tl1.background === tl2.background && tl1.shadow === tl2.shadow
);

export const getTemplateLayersCode = (templateLayers: TemplateLayers) => (
  '' + templateLayers.main +
  (templateLayers.background || 'X') +
  (templateLayers.shadow || 'X')
);

export type MarkerTemplate = {
  name: string;
  main: string;
  background?: string;
  offsets?: { x: number; y: number };
  shadow?: string;
  size: { w: number; h: number };
};

export const predefinedTemplates: MarkerTemplate[] = [
  {
    name: PredefinedTemplate.Dot,
    main: dot,
    size: { w: 100, h: 100 },
  },
  {
    name: PredefinedTemplate.TransparentDot,
    main: transparentDot,
    size: { w: 100, h: 100 },
  },
  {
    name: PredefinedTemplate.Circle,
    main: circle,
    size: { w: 250, h: 250 },
  },
  {
    name: PredefinedTemplate.CircleThick,
    main: circleThick,
    size: { w: 300, h: 300 },
  },
  {
    name: PredefinedTemplate.XMark,
    main: xMark,
    background: dot,
    size: { w: 100, h: 100 },
  },
  {
    name: PredefinedTemplate.LocationMarker,
    main: locationMarker,
    offsets: { x: 1, y: 23 },
    size: { w: 45, h: 75 },
  },
  {
    name: PredefinedTemplate.RadarMarker,
    main: radarMarker,
    size: { w: 130, h: 130 },
  },
  {
    name: PredefinedTemplate.RadarMarkerGlow,
    main: radarMarkerGlow,
    size: { w: 130, h: 130 },
  },
  {
    name: PredefinedTemplate.Cluster,
    main: clusterMarker,
    size: { w: 130, h: 130 },
  },
  {
    background: proximityCenterBackground,
    name: PredefinedTemplate.ProximityCenter,
    main: proximityCenterTop,
    size: { w: 40, h: 40 },
  },
  {
    name: PredefinedTemplate.AimMarker,
    main: aimMarker,
    size: { w: 30, h: 30 },
  },
  {
    name: PredefinedTemplate.IconMarkerHolderLight,
    main: iconHolderLight,
    size: { h: 48, w: 48 },
  },
  {
    name: PredefinedTemplate.IconMarkerHolderDark,
    main: iconHolderDark,
    size: { h: 48, w: 48 },
  },
];
