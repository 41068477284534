import { Page } from '~/_shared/constants/routes';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import {
  clearLastOpenedMapPageTool, closeLeftSidebar, closeMapTool, openLeftSidebarApp, openMapTool, openMapToolExclusively,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { replaceLastOpenedToolForPage } from '~/store/frontendState/leftSidebar/leftSidebar.helpers';
import { type ActiveMapElementsAction } from '../activeMapElements/activeMapElements.action';
import { type DirectionsAction } from '../mapTools/directions/directions.action';
import { FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT } from '../mapTools/directions/directions.actionTypes';
import { type ProximityToolAction } from '../proximityTool/proximityTool.action';
import { PROXIMITY_TOOL_SET_STARTING_LOCATION } from '../proximityTool/proximityTool.actionTypes';
import { type LeftSidebarAction } from './leftSidebar.action';
import {
  isPageWithMapTools, type LeftSidebarState, type MapToolsPerPage,
} from './leftSidebar.state';

const emptyLastOpenedMapPageTool: MapToolsPerPage = {
  [Page.ADMIN_MAP]: null,
  [Page.ADMIN_DATA]: null,
};

export const leftSidebarEmptyState: LeftSidebarState = {
  lastOpenedMapPageTool: emptyLastOpenedMapPageTool,
  openedApp: null,
  openedMapTool: null,
};

export const leftSidebarReducer = (
  state = leftSidebarEmptyState,
  action: LeftSidebarAction | DirectionsAction | ProximityToolAction | ActiveMapElementsAction
): LeftSidebarState => {
  if (openLeftSidebarApp.match(action)) {
    const { sourcePage, app } = action.payload;
    const lastOpenedMapPageTool = isPageWithMapTools(sourcePage)
      ? { ...state.lastOpenedMapPageTool, [sourcePage]: null }
      : state.lastOpenedMapPageTool;

    return {
      ...state,
      lastOpenedMapPageTool,
      openedApp: app,
      openedMapTool: null,
    };
  }

  if (closeLeftSidebar.match(action)) {
    return {
      ...state,
      openedApp: null,
      openedMapTool: null,
    };
  }

  if (closeMapTool.match(action)) {
    return {
      ...state,
      openedMapTool: null,
    };
  }

  if (openMapTool.match(action)) {
    return {
      ...state,
      lastOpenedMapPageTool: replaceLastOpenedToolForPage(state.lastOpenedMapPageTool, action.payload.tool),
      openedMapTool: action.payload.tool,
      openedApp: SidebarApp.MapTools,
    };
  }

  if (openMapToolExclusively.match(action)) {
    return {
      ...state,
      openedMapTool: action.payload.tool,
      lastOpenedMapPageTool: replaceLastOpenedToolForPage(state.lastOpenedMapPageTool, action.payload.tool),
    };
  }

  if (clearLastOpenedMapPageTool.match(action)) {
    return {
      ...state,
      lastOpenedMapPageTool: { ...state.lastOpenedMapPageTool, [action.payload.page]: null },
    };
  }

  switch (action.type) {
    case FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT:
      return {
        ...state,
        openedMapTool: MapTool.Routing,
        openedApp: SidebarApp.MapTools,
      };

    case PROXIMITY_TOOL_SET_STARTING_LOCATION:
      if (action.payload.openProximityToolBar) {
        return {
          ...state,
          openedMapTool: MapTool.Proximity,
          openedApp: SidebarApp.MapTools,
        };
      }
      else {
        return state;
      }

    default:
      return state;
  }
};
