import type { MapAction } from '~/store/map/map.action';
import { MAP_PAGE_INIT } from '~/store/map/map.actionTypes';
import distanceCalculatorCursor from '../../../../../../../assets/images/cursors/distance-calculator.png';
import { type ActiveMapElementsAction } from '../../../activeMapElements/activeMapElements.action';
import {
  ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED,
  ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED,
  SPREADSHEET_MARKER_HOVER_STOPPED,
  SPREADSHEET_MARKER_HOVERED,
} from '../../../activeMapElements/activeMapElements.actionTypes';
import { type AddLocationAction } from '../../../mapTools/addLocation/addLocation.action';
import {
  FRONTEND_STATE_ADD_LOCATION_START, FRONTEND_STATE_ADD_LOCATION_STOP,
} from '../../../mapTools/addLocation/addLocation.actionTypes';
import { type BoundaryHighlightAction } from '../../../mapTools/boundary/boundaryHighlight/boundaryHighlight.action';
import {
  BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER, BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER, BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER,
} from '../../../mapTools/boundary/boundaryHighlight/boundaryHighlight.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyActiveMapElementsMapOptions = (
  state: MapComponentOptionsState,
  action: ActiveMapElementsAction | AddLocationAction | BoundaryHighlightAction | MapAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case SPREADSHEET_MARKER_HOVERED:
      return push(state, {
        changeId: 'SPREADSHEET_MARKER_HOVER',
        priority,
        change: {
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_ADD_LOCATION_START:
      return push(state, {
        changeId: 'ADD_MARKER_ON_CLICK',
        priority,
        change: {
          draggableCursor: `url("${distanceCalculatorCursor}") 15 15, default`,
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER:
    case BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER: {
      return push(state, {
        changeId: 'BOUNDARY_HOVER',
        priority,
        change: {
          draggableCursor: 'pointer',
        },
      });
    }

    case BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER:
    case BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER: {
      return pop(state, 'BOUNDARY_HOVER');
    }

    case FRONTEND_STATE_ADD_LOCATION_STOP:
      return pop(state, 'ADD_MARKER_ON_CLICK');

    case MAP_PAGE_INIT:
    case SPREADSHEET_MARKER_HOVER_STOPPED:
      return pop(state, 'SPREADSHEET_MARKER_HOVER');

    case ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED: {
      return push(state, {
        changeId: 'ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED',
        priority,
        change: {
          draggableCursor: 'pointer',
        },
      });
    }

    case ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED: {
      return pop(state, 'ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED');
    }

    default:
      return undefined;
  }
};
