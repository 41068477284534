import styled from '@emotion/styled';
import {
  forwardRef, type PropsWithChildren,
} from 'react';

type LimitNumberOfLinesProps = Readonly<{
  maxNumberOfLines: number;
  className?: string;
}>;

// non standard feature - works with Webkit, Blink and FF, https://caniuse.com/css-line-clamp
const LimitNumberOfLinesWrapper = styled.div<{ readonly maxNumberOfLines: number }>((props) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: props.maxNumberOfLines,
}));

export const LimitNumberOfLinesComponent = forwardRef<HTMLDivElement, PropsWithChildren<LimitNumberOfLinesProps>>((props, ref) => {
  return (
    <LimitNumberOfLinesWrapper
      className={props.className}
      maxNumberOfLines={props.maxNumberOfLines}
      ref={ref}
    >
      {props.children}
    </LimitNumberOfLinesWrapper>
  );
});
