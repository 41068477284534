import { useMemo } from 'react';
import { useSpreadSheetData } from '~/map/map/useSpreadsheetData.hook';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useMapSettingsFileAttachmentsMapSelector } from '../../../store/mapSettings/fileAttachments/fileAttachments.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../../../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMapSettingsMarkersGeneralSelector } from '../../../store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  type IndividualMarkerVisualSettings,
  type MarkerSettings,
} from '../../types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '../../types/spreadsheetData/spreadsheetRow';
import { filterNonNumericBuckets } from '../grouping/grouping.helpers';
import { getMarkerVisuals } from './markersVisualSettings.helpers';
import { useBucketsWithRange } from './useBucketsWithRange.helpers';

type GetMarkerVisualsForSpreadsheetRowIdsProps = Readonly<{
  spreadsheetRowIds: ReadonlyArray<SpreadsheetRowId>;
  groupHierarchyIndicator?: number;
  isLegend: boolean;
}>;

export const useGetMarkerVisualsForSpreadsheetRowIds = (
  props: GetMarkerVisualsForSpreadsheetRowIdsProps
): IndividualMarkerVisualSettings<MarkerSettings> => {
  const markersGeneralSettings = useMapSettingsMarkersGeneralSelector();
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const spreadsheetData = useSpreadSheetData().spreadsheetData;
  const groupBuckets = useBucketsWithRange(activeGroupColumns[props.groupHierarchyIndicator || 0]);
  const files = useFileUrls();
  const fileAttachments = useMapSettingsFileAttachmentsMapSelector();

  const visualSettingsLookup = useMemo(() => {
    if (props.groupHierarchyIndicator && props.groupHierarchyIndicator >= 1
      && activeGroupColumns && activeGroupColumns.length < 2) {
      // there is only primary or no group in the grouping tool, so hierarchy indicator >= 1 has no styles
      return {};
    }

    const partialVisualSettingsLookup: { [spreadsheetId: number]: { [rowId: string]: MarkerSettings } } = {};

    props.spreadsheetRowIds
      .forEach((spreadsheetRowId) => {
        const markerVisuals = getMarkerVisuals({
          isLegend: props.isLegend,
          hierarchyIndicator: props.groupHierarchyIndicator,
          mapSettingsGroupingState: { activeGroupColumns },
          spreadsheetData,
          spreadsheetRowId,
          visualMarkersSettings: markersGeneralSettings,
          ...(groupBuckets && { numericBucketsWithRange: filterNonNumericBuckets(groupBuckets) }),
          fileAttachments, files,
        });

        const partialVisualSettings = partialVisualSettingsLookup[spreadsheetRowId.spreadsheetId] ?? {};
        partialVisualSettings[spreadsheetRowId.rowId] = markerVisuals;
        partialVisualSettingsLookup[spreadsheetRowId.spreadsheetId] = partialVisualSettings;
      });

    return partialVisualSettingsLookup;
  }, [activeGroupColumns, fileAttachments, files, groupBuckets, markersGeneralSettings,
    props.groupHierarchyIndicator, props.isLegend, props.spreadsheetRowIds, spreadsheetData,
  ]);

  return visualSettingsLookup;
};
