import {
  useEffect, useState,
} from 'react';
import { copy } from '~/_shared/utils/collections/collections';
import { useBoundaryZoomDisclaimerModal } from '~/map/map/boundary/boundaryView/mapBoundaryPolygons/useBoundaryZoomDisclaimerModal';
import { useMapBoundaryPolygonFilter } from '~/map/map/boundary/boundaryView/mapBoundaryPolygons/useMapBoundaryPolygonFilter';
import { useBoundaryTerritoryGroupDisplayName } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryTerritoryGroupDisplayName';
import { useGetBoundaryGroup } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useBoundarySelectSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { useFirstZoomLevelSelector } from '~/store/frontendState/processing/processing.selectors';
import { useBoundaryFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { isBoundaryGroupHidden } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { useMapSettingsExportImageSettingsModeSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';

export const HIDE_BOUNDARY_ZOOM_DISCLAIMER_SESSION_KEY = 'HIDE_BOUNDARY_ZOOM_DISCLAIMER';

export const useBoundaryZoomDisclaimer = () => {
  const [ignoredBoundaryIds, setIgnoredBoundaryIds] = useState<ReadonlySet<number>>(new Set());
  const boundaryPolygonFilters = useMapBoundaryPolygonFilter();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryFilers = useBoundaryFilters();
  const mapZoom = useMapComponentZoomSelector();
  const firstZoomLevel = useFirstZoomLevelSelector();
  const { getBoundaryTerritoryGroupName } = useBoundaryTerritoryGroupDisplayName();
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  const { openModal } = useBoundaryZoomDisclaimerModal();
  const boundarySelect = useBoundarySelectSelector();
  const isExportImage = !!useMapSettingsExportImageSettingsModeSelector();

  useEffect(() => {
    if (mapZoom === null || firstZoomLevel === null || isExportImage) {
      return;
    }

    const disclaimerItems = [];
    const newIgnoredBoundaryIds: Array<number> = [];
    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const boundaryGroup = getBoundaryGroupById(boundaryTerritoryGroup.boundaryGroupId);
      const boundaryLowestZoomLevel = Math.min(...boundaryGroup?.zoomLevels ?? []);

      if (!boundaryGroup || boundaryLowestZoomLevel === 0 || ignoredBoundaryIds.has(boundaryGroup.id)) {
        continue;
      }
      const filter = boundaryFilers.get(boundaryGroup.id);

      if (isBoundaryGroupHidden(filter)) {
        continue;
      }

      if (boundaryLowestZoomLevel > mapZoom) {
        newIgnoredBoundaryIds.push(boundaryGroup.id);
        disclaimerItems.push({
          groupName: getBoundaryTerritoryGroupName(boundaryTerritoryGroup),
          zoomInCount: boundaryLowestZoomLevel - mapZoom,
        });
      }
    }

    if (disclaimerItems.length > 0) {
      setIgnoredBoundaryIds((boundaryIds) => copy.andAdd(boundaryIds, newIgnoredBoundaryIds));
      openModal({
        items: disclaimerItems,
      });
    }
  }, [boundaryTerritoryGroups, mapZoom, boundaryPolygonFilters, firstZoomLevel, boundaryFilers, openModal,
    getBoundaryTerritoryGroupName, getBoundaryGroupById, ignoredBoundaryIds, isExportImage]);

  useEffect(() => {
    if (!boundarySelect.selectBoundaryGroupId || mapZoom === null || isExportImage) {
      return;
    }

    const boundaryGroup = getBoundaryGroupById(boundarySelect.selectBoundaryGroupId);
    const boundaryLowestZoomLevel = Math.min(...boundaryGroup?.zoomLevels ?? []) ?? null;

    if (!boundaryGroup || !boundaryLowestZoomLevel || ignoredBoundaryIds.has(boundaryGroup.id)) {
      return;
    }

    if (boundaryLowestZoomLevel > mapZoom) {
      setIgnoredBoundaryIds((boundaryIds) => copy.andAdd(boundaryIds, [boundaryGroup.id]));
      openModal({
        items: [{
          groupName: boundaryGroup.name,
          zoomInCount: boundaryLowestZoomLevel - mapZoom,
        }],
      });
    }
  }, [boundarySelect.selectBoundaryGroupId, isExportImage, getBoundaryGroupById, ignoredBoundaryIds, mapZoom, openModal]);
};
