import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

type AlertProps = Readonly<{
  containerStyle?: string;
  option1: string;
  option2: string;
  option1Tooltip?: string;
  option2Tooltip?: string;
  selected: number;
  onChange?: (selected: number) => void;
  disabled?: boolean;
  className?: string;
}>;

export const SwitchOptionsComponent: FC<AlertProps> = ({
  containerStyle,
  option1,
  option2,
  option1Tooltip,
  option2Tooltip,
  selected,
  className,
  disabled = false,
  onChange = () => null,
}) => {
  const theme = useTheme();
  const onChangeSelection = useMemo(() => [0, 1].map(value => () => disabled ? null : onChange(value)), [disabled, onChange]);

  return (
    <div
      className={className}
      css={[containerStyle, disabled ? containerDisabled : null]}
    >
      <TooltipDeprComponent
        placement={TooltipPlacement.Top}
        tooltipContent={option1Tooltip}
      >
        <span
          onClick={onChangeSelection[0]}
          css={[selected === 0 ? unselectableLabel({ theme }) : selectableLabel, disabled ? disabledLabel : null]}
        >{option1}
        </span>
      </TooltipDeprComponent>
      <span css={divider}>/</span>
      <TooltipDeprComponent
        placement={TooltipPlacement.Top}
        tooltipContent={option2Tooltip}
      >
        <span
          onClick={onChangeSelection[1]}
          css={[selected === 1 ? unselectableLabel({ theme }) : selectableLabel, disabled ? disabledLabel : null]}
        >{option2}
        </span>
      </TooltipDeprComponent>
    </div>
  );
};

const containerDisabled = css({
  opacity: .5,
});

const selectableLabel = css({
  display: 'inline-block',
  padding: 4,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const disabledLabel = css({
  cursor: 'not-allowed',
  '&:hover': {
    textDecoration: 'none',
  },
});

const unselectableLabel = ({ theme }: ThemeProps) => css({
  display: 'inline-block',
  padding: 4,
  background: theme.tagColors.secondaryBackgroundColor,
  borderRadius: 4,
  color: theme.tagColors.secondaryTextColor,
});

const divider = css({
  margin: '0 10px',
});
