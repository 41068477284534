import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import {
  selectLatLngSpreadsheetData, type SpreadsheetLatLngRowData,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { spreadsheetDataCheckForDeletedRows } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { mapSettingsMarkersRemoveLabelMarkerOffsets } from '../mapSettingsMarkers.actionCreators';
import { flattenLabelMarkerOffsets } from '../mapSettingsMarkers.helpers';
import { mapSettingsLabelMarkerOffsetsSelector } from '../mapSettingsMarkers.selectors';
import { type MarkerLabelCustomOffsets } from '../mapSettingsMarkers.state';

export function* removeDeletedRowsLabelOffsetsSaga() {
  yield takeLatest(spreadsheetDataCheckForDeletedRows.triggerActions, removeDeletedRowsLabelOffsets);
}

function* removeDeletedRowsLabelOffsets() {
  const proceed: boolean = yield call(spreadsheetDataCheckForDeletedRows.conditionSaga);
  if (!proceed) {
    return;
  }

  const labelMarkerOffsets: MarkerLabelCustomOffsets = yield select<MarkerLabelCustomOffsets>(mapSettingsLabelMarkerOffsetsSelector);
  const spreadsheetData: SpreadsheetLatLngRowData = yield select<SpreadsheetLatLngRowData>(selectLatLngSpreadsheetData);

  const offsets = flattenLabelMarkerOffsets(labelMarkerOffsets);

  const offsetsWithoutRows = offsets
    .map(item => ({ spreadsheetId: item.spreadsheetId, rowId: item.rowId }))
    .filter(item => !spreadsheetData.getRow(item));

  if (offsetsWithoutRows.length) {
    yield put(mapSettingsMarkersRemoveLabelMarkerOffsets(offsetsWithoutRows));
  }
}
