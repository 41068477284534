import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type ConfirmationModalProps } from '~/_shared/components/modal/confirmation/confirmationModal.component';
import { type WithoutModalProps } from '~/modal/renderModal';
import { enableLocationTracking } from '~/store/frontendState/mapTools/myLocation/myLocation.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import {
  type TranslationFnc, useTranslation,
} from '../../_shared/utils/hooks';
import { useStorageService } from '../../_shared/utils/storageService';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { usePublicMapSettingsSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapSettingsSettingsIsUserLocationShownSelector } from '../../store/mapSettings/settings/mapSettingsSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';

const TRACK_MY_LOCATION_ON_MAP_LOAD = 'track-location-on-map-load';

const trackMyLocationOnMapKey = (mapId: number) => `${TRACK_MY_LOCATION_ON_MAP_LOAD}|${mapId}`;

export const useShowUserLocationDefault = () => {
  const { mapId } = useParams<{ mapId: string }>();
  const reduxMapId = useMapIdSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const isUserLocationShownOnAdmin = useMapSettingsSettingsIsUserLocationShownSelector();
  const isPresentationalMap = useIsMapPresentationalSelector();
  const storageService = useStorageService();

  const { openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal(ModalType.Confirmation);
  const [wasInstructionsModalShown, setWasInstructionsModalShown] = useState(false);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const trackOnPublicMap = isPresentationalMap && publicMapSettings && publicMapSettings.showMyLocationDefault;
  const trackOnAdminMap = !isPresentationalMap && isUserLocationShownOnAdmin;
  const defaultUserLocationTrackingEnabled = trackOnPublicMap || trackOnAdminMap;

  const onInstructionModalClose = useCallback(() => {
    closeConfirmationModal();
    setWasInstructionsModalShown(true);
  }, [closeConfirmationModal]);

  const onInstructionModalSubmit = useCallback((checkboxValue: boolean) => {
    if (reduxMapId && checkboxValue) {
      storageService.setLocalStorageItem(trackMyLocationOnMapKey(reduxMapId), 'true');
    }

    dispatch(enableLocationTracking());
    onInstructionModalClose();
  }, [dispatch, reduxMapId, onInstructionModalClose, storageService]);

  useEffect(() => {
    if (!defaultUserLocationTrackingEnabled) {
      return;
    }

    if (reduxMapId === null) {
      return;
    }

    if (!isPresentationalMap && (Number(mapId) !== reduxMapId)) {
      return;
    }

    const trackLocation = storageService.getLocalStorageItem(trackMyLocationOnMapKey(reduxMapId)) === 'true';

    if (trackLocation) {
      dispatch(enableLocationTracking());
    }
    else if (!wasInstructionsModalShown) {
      openConfirmationModal(getConfirmationModalProps({
        onModalClose: onInstructionModalClose,
        onSubmit: onInstructionModalSubmit, t,
      }));
    }
  }, [defaultUserLocationTrackingEnabled, dispatch, mapId, onInstructionModalClose, onInstructionModalSubmit,
    openConfirmationModal, t, wasInstructionsModalShown, storageService, reduxMapId, isPresentationalMap]);
};

const getConfirmationModalProps = (props: {
  onModalClose: () => void;
  onSubmit: (checkboxValue: boolean) => void;
  t: TranslationFnc;
}): WithoutModalProps<ConfirmationModalProps> => ({
  title: props.t('Show My Location'),
  confirmCaption: props.t('OK'),
  cancelCaption: props.t('Cancel'),
  onClose: props.onModalClose,
  onCancel: props.onModalClose,
  onConfirm: props.onSubmit,
  checkboxLabel: props.t('Remember For This Map'),
  children: [props.t('Would you like Maptive to show your location on the map?')],
  isConfirmButtonDestructive: false,
});
