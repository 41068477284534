import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName,
  BaseMapFeatureTypeName,
  BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesIce: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName.all,
    stylers: { visibility: BaseMapStylerVisibility.shown },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName.labels,
    stylers: {
      visibility: BaseMapStylerVisibility.shown,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#444444',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.landscape,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#f2f2f2',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#b4ddf6',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#075EA1',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.landcover'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#b0eae7',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.landcover'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#235451',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.terrain'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#647d8f',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.terrain'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#21303a',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#9fdab9',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#00ffae',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#32493c',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#48bfe7',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#ffffff',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#ffffff',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#55dae5',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      saturation: {
        isActive: true,
        value: 100,
      },
      color: {
        isActive: true,
        value: '#102379',
      },
      lightness: {
        isActive: true,
        value: 15,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#46bcec',
      },
      visibility: BaseMapStylerVisibility.shown,
    },
  },
];
