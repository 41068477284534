import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { useLogOutUser } from '~/authorization/logOutUser.hook';
import { canMemberManageSubscription } from '~/clientTeamManagement/memberRoles.helpers';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  useClientOwner,
  useUserCurrentClientSelector, useUserDataSelector,
} from '~/store/userData/userData.selectors';
import { usePaymentPortalRefresh } from '~/subscription/usePaymentPortalRefresh.hook';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '../../baseComponents/lottieAnimation';
import { ModalComponent } from '../../components/modal/modal.component';
import {
  SUBSCRIBE_ROUTE, SUBSCRIPTION_ROUTE,
} from '../../constants/routes';
import { openEmailDraft } from '../../utils/email/email.helpers';
import { noop } from '../../utils/function.helpers';
import { useTranslation } from '../../utils/hooks';
import {
  ClientLicenseCode, ClientLicenseState,
} from './license';

const modalContentStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8',
  padding: '24px 33px 10px 33px',
  textAlign: 'center',
});

export const SubscriptionExpiredModal: FC<ModalProps> = ({ isOpen }) => {
  const [t] = useTranslation();
  const currentClient = useUserCurrentClientSelector();
  const migrationFinished = useUserCurrentClientSelector()?.migrationDone;
  const { openModal: openSubscriptionUnavailableModal } = useModal(ModalType.SubscriptionManagementUnavailable);
  const userData = useUserDataSelector();
  const canManageSubscription = canMemberManageSubscription(userData.clientRole);
  const clientOwner = useClientOwner();
  const logOutUser = useLogOutUser();
  const { isLoading: isLoadingPortalRefresh, paymentPortalRefresh } = usePaymentPortalRefresh();

  const licenseCode = currentClient?.license.code;
  const licenseState = currentClient?.license.state;

  let modalProperties: {
    caption: string;
    text: string;
    buttonText: string;
    onSubmit: () => void;
  } | null = null;

  if (licenseState === ClientLicenseState.FAILED_PAY_PERIOD) {
    modalProperties = {
      caption: t('License Expired'),
      text: t('SubscriptionManagement.LicenseIssueFriendlyMessage'),
      buttonText: t('Pay Now'),
      onSubmit: () => openUrlInNewTab(SUBSCRIPTION_ROUTE),
    };
  }

  if (licenseState === ClientLicenseState.EXPIRED) {
    if (licenseCode === ClientLicenseCode.TRIAL) {
      modalProperties = {
        caption: t('Trial Expired'),
        text: t('SubscriptionManagement.LicenseIssueTrialPleasePurchase'),
        buttonText: t('Purchase Maptive'),
        onSubmit: () => openUrlInNewTab(SUBSCRIBE_ROUTE),
      };
    }
    else {
      modalProperties = {
        caption: t('License Expired'),
        text: t('SubscriptionManagement.LicenseIssuePleasePurchase'),
        buttonText: t('Purchase Now'),
        onSubmit: () => openUrlInNewTab(SUBSCRIBE_ROUTE),
      };
    }
  }

  if (!canManageSubscription && modalProperties) {
    modalProperties = {
      ...modalProperties,
      text: t('SubscriptionManagement.LicenseIssueFriendlyMessageMember'),
      buttonText: t('Contact Owner'),
      onSubmit: () => openEmailDraft(clientOwner?.email ?? ''),
    };
  }

  const handleSubscriptionUnavailable = useCallback(() =>
    openSubscriptionUnavailableModal(),
  [openSubscriptionUnavailableModal]);

  useEffect(() => {
    const onTabVisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        paymentPortalRefresh();
      }
    };

    document.addEventListener('visibilitychange', onTabVisibilitychange);

    return () => document.removeEventListener('visibilitychange', onTabVisibilitychange);
  }, [paymentPortalRefresh]);

  if (!modalProperties) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={noop}
      caption={modalProperties.caption}
      isCloseDisabled
      skipCloseOnMapIdChange
      confirmButton={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Success}
          text={modalProperties.buttonText}
          onClick={migrationFinished ? modalProperties.onSubmit : handleSubscriptionUnavailable}
        />
      )}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          onClick={logOutUser}
          text={t('Log Out')}
        />
      )}
      additionalContent={isLoadingPortalRefresh ? <OverlayLoaderComponent /> : null}
    >
      <div css={modalContentStyle}>
        {t('Woof! Im Smudge, Maptives friendly guard dog.')}
        <LottieAnimationComponent
          type={LottieAnimationTypes.SmudgeDog}
          size={100}
          autoplay
          loop
        />
        <div css={css`white-space: pre-line`}>{modalProperties.text}</div>
      </div>
    </ModalComponent>
  );
};
