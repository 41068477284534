import { useMemo } from 'react';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import {
  dummyMapObjectZindex, mapObjectEntities, type MapObjectZIndex,
} from '~/map/map/mapObjects/mapObject.types';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useZIndexSeries } from './useZIndexSeries.hook';
import { ZIndexedEntity } from './zIndexRanges';

const idMapper = (entity: string) => entity;

export const useDrawingInstanceZIndex = (instanceId?: Uuid, placement?: DrawingItemPlacement): MapObjectZIndex => {
  const defaultZIndex = useDrawingInstanceDefaultZIndex(instanceId);
  const bottomZIndex = useDrawingInstanceBottomZIndex(instanceId);
  const topZIndex = useDrawingInstanceTopZIndex(instanceId);

  switch (placement) {
    case DrawingItemPlacement.Bottom:
      return bottomZIndex;
    case DrawingItemPlacement.Top:
      return topZIndex;
    default:
      return defaultZIndex;
  }
};

const useDrawingInstanceDefaultZIndex = (instanceId?: Uuid): MapObjectZIndex => {
  const orderedEntities = useMemo(() => instanceId ? [instanceId] : [], [instanceId]);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.DrawingInstance,
  }), [orderedEntities]);

  return (instanceId && notEmpty(entities.subEntityZIndexes)) ? entities.subEntityZIndexes[0] : dummyMapObjectZindex;
};

const useDrawingInstanceBottomZIndex = (instanceId?: Uuid): MapObjectZIndex => {
  const orderedEntities = useMemo(() => instanceId ? [instanceId] : [], [instanceId]);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.DrawingInstanceBottom,
  }), [orderedEntities]);

  return (instanceId && notEmpty(entities.subEntityZIndexes)) ? entities.subEntityZIndexes[0] : dummyMapObjectZindex;
};

const useDrawingInstanceTopZIndex = (instanceId?: Uuid): MapObjectZIndex => {
  const orderedEntities = useMemo(() => instanceId ? [instanceId] : [], [instanceId]);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.DrawingInstanceTop,
  }), [orderedEntities]);

  return (instanceId && notEmpty(entities.subEntityZIndexes)) ? entities.subEntityZIndexes[0] : dummyMapObjectZindex;
};

export const useActiveDrawingInstanceZIndex = (): MapObjectZIndex => {
  const orderedEntities = useMemo(() => ['activeDrawing'], []);
  const entities = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: mapObjectEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.ActiveDrawingInstance,
  }), [orderedEntities]);

  return notEmpty(entities.subEntityZIndexes) ? entities.subEntityZIndexes[0] : dummyMapObjectZindex;
};
