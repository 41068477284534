import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useDirectionsRouteZIndexes } from '~/map/zIndexes/useDirectionsRouteZIndexes.hook';
import { frontendStateDirectionsUpdateRouteData } from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import { useDirectionsCanDragRoutesSelector } from '~/store/frontendState/mapTools/directions/directions.selectors';
import { type RouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { type MapMarkerEventCallback } from '../markers/useMarkerEvents/markerEventCallbacks.type';
import { useMarkerEventCallbacks } from '../markers/useMarkerEvents/useMarkerEventCallbacks';
import { type MapDirectionsManager } from './mapDirectionsManager';

const getMouseEventCallback = (callback: MapMarkerEventCallback) => (markerId: SpreadsheetRowId) =>
  callback([markerId], false, () => null);

type DirectionsRouteInstanceProps = Readonly<{
  manager: MapDirectionsManager;
  route: MapSettingsRoute;
  routeUiData: RouteUiData | undefined;
}>;

export const DirectionsRouteInstanceContainer: FC<DirectionsRouteInstanceProps> = props => {
  const canDragRoutes = useDirectionsCanDragRoutesSelector();
  const isMapInteractionsActive = useIsMapInteractionActive();
  const zIndexes = useDirectionsRouteZIndexes(props.route.waypoints);
  const dispatch = useDispatch();
  const theme = useTheme();
  const markerMouseEventCallbacks = useMarkerEventCallbacks();

  const onRouteChange = useCallback((routeId: string, routes: Array<google.maps.DirectionsRoute>) => {
    dispatch(frontendStateDirectionsUpdateRouteData(routeId, routes));
  }, [dispatch]);
  const onRouteChangeRef = useRef(onRouteChange);

  const idDraggable = canDragRoutes && !isMapInteractionsActive;

  const mouseEventCallbacks = useMemo(() => ({
    onClick: getMouseEventCallback(markerMouseEventCallbacks.onClick),
    onMouseOut: getMouseEventCallback(markerMouseEventCallbacks.onMouseOut),
    onMouseOver: getMouseEventCallback(markerMouseEventCallbacks.onMouseOver),
  }), [markerMouseEventCallbacks.onClick, markerMouseEventCallbacks.onMouseOut, markerMouseEventCallbacks.onMouseOver]);

  useEffect(() => {
    if (props.routeUiData) {
      props.manager.renderRoute(props.route, props.routeUiData, mouseEventCallbacks, zIndexes, idDraggable, onRouteChangeRef.current, theme);
    }
  }, [props.route, props.routeUiData, props.manager, zIndexes, idDraggable, theme, mouseEventCallbacks]);

  useEffect(() => () => props.manager.removeRoute(props.route.id), [props.route, props.manager]);

  return null;
};
