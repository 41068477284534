import {
  type FC, useMemo,
} from 'react';
import { useMapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import {
  useAreMarkerLabelsDisabled, useMapSettingsMarkersGeneralSelector, useMapSettingsMarkersTextureAnchors,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  DEFAULT_MARKER_STYLE, useMarkerSets,
} from '../../store/frontendState/markerSets/markerSets.selectors';
import { type QuadrilateralDimensions } from '../types/coordinateSystem/coordinateSystem';
import {
  type MarkerEntityStyle, type MarkerLabelStyles, MarkerStyleType,
} from '../types/marker.types';
import { MarkerSettingType } from '../types/markers/visualSettings.enums';
import { type MarkerSettings } from '../types/markers/visualSettings.types';
import { useFileAttachmentUrls } from '../utils/files/useFileUrls';
import {
  getFallbackStandardMarkerSettings, getInitialMarker, transformMarkerVisualSettingsForHTMLVisualiser,
} from '../utils/markers/markersVisualSettings.helpers';
import {
  DEFAULT_MARKER_STYLE_ID, INITIAL_LABEL,
} from '../utils/markers/markerVisualSettings.constants';
import { LabelOrMarkerVisualizerComponent } from './labelOrMarkerVisualizer.component';

type LabelOrMarkerVisualizerContainerProps = Readonly<{
  className?: string;
  markerSettings: MarkerSettings;
  maxDimensions: QuadrilateralDimensions;
  maxSize?: number; //everything above this size will be considered 100%
  maxSizeEasing?: boolean;
  visualSettingsType?: MarkerSettingType;
  labelText?: string;
}>;

export const LabelOrMarkerVisualizerContainer: FC<LabelOrMarkerVisualizerContainerProps> = props => {
  const markerSets = useMarkerSets();
  const markersGeneralSettings = useMapSettingsMarkersGeneralSelector();
  const fileAttachmentMap = useMapSettingsFileAttachmentsMapSelector();
  const { fileAttachmentIdsToUrls } = useFileAttachmentUrls();
  const { getMarkerTextureAnchor } = useMapSettingsMarkersTextureAnchors();
  const markerLabelsDisabled = useAreMarkerLabelsDisabled();

  const { markerSettings, visualSettingsType } = props;

  const isLabelConditionMet = !markerSettings.useMarker
    && (
      (visualSettingsType === MarkerSettingType.Label) ||
      ((visualSettingsType !== MarkerSettingType.Marker) && !markerLabelsDisabled)
    );

  const alteredSettings = transformMarkerVisualSettingsForHTMLVisualiser(markerSettings);

  const visualSettings: MarkerEntityStyle = useMemo(() => {
    if (isLabelConditionMet) {
      const label = alteredSettings.label ?? INITIAL_LABEL;
      const labelStyle: MarkerLabelStyles = {
        ...label,
        type: 'label',
        offsetProps: { type: 'default' },
      };
      return labelStyle;
    }

    const marker = alteredSettings.marker ??
      getInitialMarker(markersGeneralSettings.defaultMarkerSetId || DEFAULT_MARKER_STYLE_ID);

    if (marker.styleType === MarkerStyleType.STANDARD) {
      return {
        ...(markerSets[marker.styleId] ?? DEFAULT_MARKER_STYLE),
        opacity: marker.opacity,
        selectedColor: marker.selectedColor,
        size: marker.size,
      };
    }
    else {
      const attachmentId = marker.fileAttachmentId;
      const attachment = fileAttachmentMap.get(attachmentId);
      const fileUrl = fileAttachmentIdsToUrls.get(attachmentId);
      if (!attachment || !fileUrl) {
        const customToStandardFallbackSettings = getFallbackStandardMarkerSettings(markersGeneralSettings);
        return {
          ...(markerSets[customToStandardFallbackSettings.styleId] ?? DEFAULT_MARKER_STYLE),
          opacity: customToStandardFallbackSettings.opacity,
          selectedColor: customToStandardFallbackSettings.selectedColor,
          size: customToStandardFallbackSettings.size,
        };
      }

      return {
        marker: {
          fileId: attachment.fileId,
          anchor: getMarkerTextureAnchor(attachment.id),
          dimensions: fileUrl.original.resolution,
          path: fileUrl.original.url,
        },
        type: 'marker',
        markerType: MarkerStyleType.CUSTOM,
        size: marker.size,
      };
    }
  }, [isLabelConditionMet, alteredSettings.marker, alteredSettings.label, markersGeneralSettings, markerSets,
    fileAttachmentMap, fileAttachmentIdsToUrls, getMarkerTextureAnchor]);

  return (visualSettings ? (
    <LabelOrMarkerVisualizerComponent
      className={props.className}
      dimensions={props.maxDimensions}
      maxSize={props.maxSize}
      maxSizeEasing={props.maxSizeEasing}
      selectedMarkerStyle={visualSettings}
      labelText={props.labelText}
    />
  ) : null);
};
