import {
  faCreditCard, faKey, faUserCog, faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { SUBSCRIPTION_ROUTE } from '~/_shared/constants/routes';
import { isPaidLicense } from '~/_shared/types/client/license';
import { useIntercom } from '~/_shared/utils/3rdPartyIntegrations/intercom/intercom.context';
import { useTranslation } from '~/_shared/utils/hooks';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { isTruthy } from '~/_shared/utils/typeGuards';
import {
  canMemberManageClient, canMemberManageSubscription,
} from '~/clientTeamManagement/memberRoles.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import { useClientSubscriptionLoadingSelector } from '~/store/frontendState/client/client.selectors';
import { openLeftSidebarApp } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import {
  useUserCurrentClientSelector, useUserDataSelector, useUserDataUsagesSelector, useUserLimitsSelector,
} from '~/store/userData/userData.selectors';
import { usePaymentPortal } from '~/subscription/usePaymentPortal.hook';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { HomepageInfoComponent } from './homepageInfo.component';

export const HomepageInfoContainer: FC = _ => {
  const [t] = useTranslation();
  const limits = useUserLimitsSelector();
  const usages = useUserDataUsagesSelector();
  const currentClient = useUserCurrentClientSelector();
  const isSubscriptionLoading = useClientSubscriptionLoadingSelector();
  const dispatch = useDispatch();
  const paymentPortalData = usePaymentPortal();
  const migrationFinished = currentClient?.migrationDone;
  const currentPage = useGetCurrentPageInfo().page;
  const { show: showIntercom } = useIntercom();

  const { openModal: openSubscriptionManagementUnavailable } = useModal(ModalType.SubscriptionManagementUnavailable);
  const { openModal: openTeamManagementModal } = useModal(ModalType.TeamManagement);
  const { openModal: openTwoFaModal } = useModal(ModalType.TwoFa);

  const userData = useUserDataSelector();
  const currentUserClientRole = userData.clientRole;
  const allowManageSubscription = migrationFinished && canMemberManageSubscription(currentUserClientRole);
  const allowManageClient = migrationFinished && canMemberManageClient(currentUserClientRole);
  const isPaidLicence = !!currentClient && isPaidLicense(currentClient.license.type);
  const showPaymentPortal = allowManageSubscription && isPaidLicence;

  const license = useMemo(() => ({
    isLoading: userData.isLoading || isSubscriptionLoading,
    licenseData: currentClient?.license,
  }), [currentClient?.license, isSubscriptionLoading, userData.isLoading]);

  const accountSettingsOptions = useMemo(() => [{
    name: t('Account Settings'),
    onClick: () => dispatch(openLeftSidebarApp(SidebarApp.AccountSettings, currentPage)),
    icon: faUserCog,
  }, showPaymentPortal && {
    name: t('Launch Payment Portal'),
    onClick: () => {
      if (migrationFinished) {
        openUrlInNewTab(SUBSCRIPTION_ROUTE);
      }
      else {
        openSubscriptionManagementUnavailable();
      }
    },
    icon: faCreditCard,
  }, {
    name: t('Two Factor Authentication'),
    onClick: openTwoFaModal,
    icon: faKey,
  }, allowManageClient && {
    name: t('Manage Team Users'),
    onClick: openTeamManagementModal,
    icon: faUsers,
  }].filter(isTruthy), [allowManageClient, currentPage, dispatch, migrationFinished, openSubscriptionManagementUnavailable,
    openTeamManagementModal, openTwoFaModal, showPaymentPortal, t]);

  const usagesCounters = useMemo(() => ({
    locationsMapped: {
      limit: limits.realGeocodes,
      total: usages.realGeocodes,
    },
    publicMapViews: {
      limit: limits.mapView,
      total: usages.publicMapViews,
    },
  }), [limits.mapView, limits.realGeocodes, usages.publicMapViews, usages.realGeocodes]);

  return (
    <HomepageInfoComponent
      accountSettingsOptions={accountSettingsOptions}
      currentLicence={license}
      isChatLoading={false}
      onChatClick={showIntercom}
      paymentPortalData={paymentPortalData}
      statistics={usagesCounters}
    />
  );
};
