//source: https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
export const copyToClipboard = (textSource: string | Promise<string>) => {
  if (typeof textSource === 'string') {
    navigator.clipboard.writeText(textSource);
    return;
  }

  // eslint-disable-next-line no-constant-binary-expression
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873
    const text = new ClipboardItem({
      'text/plain': textSource
        .then(text => new Blob([text], { type: 'text/plain' })),
    });
    return navigator.clipboard.write([text]);
  }
  else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    return textSource
      .then(text => navigator.clipboard.writeText(text));
  }
};

// call this in synchronous context of click handler. This is necessary due to Safari clipboard security.
// returned function can be called in asynchronous context
export const prepareAsyncCopyToClipboard = () => {
  let promiseResolve: (text: string) => void;

  const textCopyingPromise = new Promise<string>(function (resolve, _reject) {
    promiseResolve = resolve;
  });
  copyToClipboard(textCopyingPromise);

  return (textToBeCopied: string) => {
    promiseResolve(textToBeCopied);
  };
};
