export type ButtonStyleProps = {
  backgroundColor: string;
  backgroundHoverColor: string;
  textColor: string;
  textHoverColor?: string;
  borderColor?: string;
  borderHoverColor?: string;
  border?: string;
};

export const ButtonSize = {
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
} as const;

export type ButtonSize = typeof ButtonSize[keyof typeof ButtonSize];

export enum ButtonStyle {
  Custom = 'Custom',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Quaternary = 'Quaternary',
  Success = 'Success',
  Danger = 'Danger',
  DangerInverted = 'DangerInverted',
}
