import {
  type FC, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type QuadrilateralDimensions } from '../../../../_shared/types/coordinateSystem/coordinateSystem';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useGMapDragAndDrop } from '../../../../_shared/utils/hooks/useGMapDragAndDrop';
import { type DrawingItemCallout } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { useMap } from '../../mapContext';
import { MapTextAreaCalloutComponent } from '../../mapObjects/mapTextAreaCallout/mapTextAreaCallout.component';
import { MapTextAreaCalloutContainer } from '../../mapObjects/mapTextAreaCallout/mapTextAreaCallout.container';
import { type MapTextAreaCalloutInstance } from '../../mapObjects/mapTextAreaCallout/mapTextAreaCalloutModel';
import { DRAWING_CALLOUT_ARROW_PERCENTAGE } from '../textAreaCommon/drawingToolTextArea.constants';
import { type DrawingTextAreaSize } from '../textAreaCommon/useDrawingToolTextAreaScalingToggle';
import { getDrawingToolTextAreaPadding } from '../textAreaCommon/useDrawingToolTextAreaSize';

type DrawingToolCalloutInstanceCalloutProps = Readonly<{
  instance: DrawingItemCallout;
  calloutSize: DrawingTextAreaSize;
  zIndex: MapObjectZIndex;
  autoFocusOnInit?: boolean;
  disabled?: boolean;
  selectionDisabled?: boolean;
  etag?: string;

  onClick?: (event: MapObjectClickEventArgs) => void;
  onRightClick?: (event: MapObjectClickEventArgs) => void;
  onCalloutMouseOut?: () => void;
  onCalloutMouseOver?: () => void;
  onCalloutDragStart?: (latLng: LatLng) => void;
  onCalloutDragMove?: (latLng: LatLng) => void;
  onCalloutDragEnd?: () => void;
  renderOutline?: (outline: MapOutlinePositionInstance) => ReactNode;
  onTextChange?: (newText: string, textBoxSize: QuadrilateralDimensions) => void;
}>;

export const DrawingToolCalloutInstanceCalloutContainer: FC<DrawingToolCalloutInstanceCalloutProps> = ({
  instance, zIndex, onCalloutDragEnd, onCalloutDragMove, onCalloutDragStart,
  onCalloutMouseOut, onCalloutMouseOver, onClick, onRightClick, onTextChange,
  renderOutline, autoFocusOnInit, calloutSize, disabled, selectionDisabled, etag,
}) => {
  const map = useMap();

  const { onMouseDown } = useGMapDragAndDrop({
    startPoint: instance.startPoint,
    map,
    onDragMove: onCalloutDragMove,
    onDragEnd: onCalloutDragEnd,
    onDragStart: onCalloutDragStart,
  });

  const renderCallout = useCallback((callout: MapTextAreaCalloutInstance) => {
    const padding = getDrawingToolTextAreaPadding({
      width: callout.textBoxWidth,
      height: callout.textBoxHeight,
      strokeWidth: callout.strokeWidth,
      borderRadius: instance.settings.borderRadius,
    });

    return (
      <MapTextAreaCalloutComponent
        callout={callout}
        visuals={{
          strokeColor: instance.settings.strokeColor,
          strokeOpacity: instance.settings.strokeOpacity / 100,
          fillColor: instance.settings.fillColor,
          fillOpacity: instance.settings.fillOpacity / 100,
          fontColor: instance.settings.fontColor,
          borderRadius: instance.settings.borderRadius,
          textAreaPadding: padding,
        }}
        disabled={disabled}
        selectionDisabled={selectionDisabled}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseDown={onMouseDown}
        onMouseOut={onCalloutMouseOut}
        onMouseOver={onCalloutMouseOver}
        onTextChange={onTextChange}
        autoFocusOnInit={autoFocusOnInit}
        etag={etag}
      />
    );
  }, [instance.settings.borderRadius, instance.settings.strokeColor, instance.settings.strokeOpacity,
    instance.settings.fillColor, instance.settings.fillOpacity, instance.settings.fontColor, disabled,
    selectionDisabled, onClick, onRightClick, onMouseDown, onCalloutMouseOut, onCalloutMouseOver, onTextChange,
    autoFocusOnInit, etag]);

  const callout = useMemo<MapTextAreaCalloutInstance | null>(() => {
    return calloutSize ? {
      id: instance.id,
      startPoint: instance.startPoint,
      text: instance.text,
      strokeWidth: instance.settings.strokeWeight,
      arrowToTexboxPercentage: DRAWING_CALLOUT_ARROW_PERCENTAGE,
      textBoxWidth: calloutSize.width,
      textBoxHeight: calloutSize.height,
      fontSize: calloutSize.fontSize,
      borderRadius: instance.settings.borderRadius,
    } : null;
  }, [calloutSize, instance.id, instance.startPoint, instance.text, instance.settings.strokeWeight, instance.settings.borderRadius]);

  if (!callout) {
    return null;
  }

  return (
    <MapTextAreaCalloutContainer
      callout={callout}
      zIndex={zIndex}
      renderCallout={renderCallout}
      renderOutline={renderOutline}
    />
  );
};
