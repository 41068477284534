import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingCircleSettingsState } from './drawingCircleSettings.state';

export const drawingCircleSettingsInitialState: DrawingCircleSettingsState = {
  scalesWithMapZoom: false,
  strokeOpacity: 100,
  fillOpacity: 50,
  radius: 50,
  strokeWeight: 2,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
};

export const drawingCircleSettingsReducer = (
  state = drawingCircleSettingsInitialState, action: DrawingSettingsAction
): DrawingCircleSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Circle) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.circle,
      };
    }

    default:
      return state;
  }
};
