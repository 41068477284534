import {
  type i18n,
  type TOptions,
} from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { useTranslation as _useTranslation } from 'react-i18next';
import { type Translations } from '../../translations/translations.type';

export type TranslationFnc = (key: keyof Translations, options?: TOptions) => string;

type CustomUseTranslation = () => [ TranslationFnc, i18n, boolean ];

export const useTranslation: CustomUseTranslation = _useTranslation;
