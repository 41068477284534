import { type LatLngRowData } from '../../../../store/spreadsheetData/spreadsheetData.helpers';

export const STACKED_MARKER_DECIMALS = 4;
export type StackedMarkerId = string;

export const createStackedMarkerId = (marker: LatLngRowData): StackedMarkerId =>
  `lat:${roundCoordinate(marker.lat)};lng:${roundCoordinate(marker.lng)}`;

export const createUnstackedMarkerId = (marker: LatLngRowData): StackedMarkerId =>
  `lat:${marker.lat};lng:${marker.lng}`;

const roundCoordinate = (coordinate: number) =>
  coordinate.toFixed(STACKED_MARKER_DECIMALS);
