import {
  type FC, memo, type ReactNode, useMemo,
} from 'react';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type MapObjectZIndex } from '../mapObject.types';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import {
  type MapMarkerInstance, MarkerOutlineMode,
} from './mapMarkerModel';
import { useMapMarkerOutlines } from './useMapMarkerOutlines';

const useBackgroundZIndex = (backgroundZIndex: number) => useMemo((): MapObjectZIndex => ({
  arrow: backgroundZIndex,
  transparentOutlineDot: backgroundZIndex,
  outlineDot: backgroundZIndex,
  outlineCircle: backgroundZIndex,
  line: backgroundZIndex,
  polygon: backgroundZIndex,
  polygonBorder: backgroundZIndex,
  circleArea: backgroundZIndex,
  outlineLabel: backgroundZIndex,
  labelCallout: backgroundZIndex,
  labelText: backgroundZIndex,
  removeButton: backgroundZIndex,
  customMarkerBackground: backgroundZIndex,
  customMarker: backgroundZIndex,
}), [backgroundZIndex]);

type MapMarkerProps = {
  marker: MapMarkerInstance;
  zIndex: MapObjectZIndex;
  outlineMode?: MarkerOutlineMode;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  renderMarker?: (key: string, marker: MapMarkerInstance) => ReactNode;
  renderBackground?: (key: string, marker: MapMarkerInstance) => ReactNode;
};

const MapMarkerContainer: FC<MapMarkerProps> = (props) => {
  const { marker, zIndex, outlineMode } = props;
  const { renderBackground, renderMarker, renderOutline } = props;

  const manager = useMapObjectManager();

  const outlines = useMapMarkerOutlines(marker, {
    predicate: !!renderOutline,
    mode: outlineMode ?? MarkerOutlineMode.Offset,
  });

  const background = useMemo(() => ({ ...marker, id: `${marker.id}-background` }), [marker]);
  const backgroundZIndex = useBackgroundZIndex(zIndex.customMarkerBackground);

  return (
    <>
      <MapObjectContextProvider value={{ manager, zIndex: backgroundZIndex }}>
        {renderBackground?.(background.id, background)}
      </MapObjectContextProvider>
      <MapObjectContextProvider value={{ manager, zIndex }}>
        {renderMarker?.(marker.id, marker)}
        {renderOutline && outlines.map(
          outline => renderOutline(outline.id, outline))
        }
      </MapObjectContextProvider>
    </>
  );
};

const pureComponent = memo(MapMarkerContainer);
export { pureComponent as MapMarkerContainer };
