import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { SettingsTableComponent } from '../settingsTable/settingsTable.component';
import {
  type ChangeableSettingRow,
  type SettingColumn, type SettingRow,
} from '../settingsTable/settingsTable.types';

type SettingsModalProps = Readonly<{
  caption: string;
  isOpen: boolean;
  onClose: () => void;
  leftFooterContent?: ReactNode;
  settingColumns: SettingColumn[];
  settingRows: SettingRow[];
  onChange?: (index: number, newSettingRow: ChangeableSettingRow) => void;
  onSubmit: () => void;
  isLoading: boolean;
  customSaveButton?: ReactNode;
  aboveTableContent?: ReactNode;
  belowTableContent?: ReactNode;
  customLoader?: ReactNode;
}>;

const submitButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
});

const submitLabelStyles = css({
  flexGrow: 1,
  padding: '0 35px 0 20px',
});

const submitIconStyles = css({
  fontSize: '16px',
  lineHeight: 1.5,
  margin: 0,
  width: 40,
});

const modalContentStyle = css({
  padding: 0,
});

const settingsTableStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const contentWrapperStyle = css({
  position: 'relative',
});

export const SettingsModalComponent: FC<SettingsModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={props.caption}
      leftFooterContent={props.leftFooterContent}
      confirmButton={(props.customSaveButton === undefined) ? (
        <ButtonComponent
          css={submitButtonStyles}
          text={<span css={submitLabelStyles}>{t('Save')}</span>}
          prefixIcon={faCheck}
          prefixIconStyle={submitIconStyles}
          onClick={props.onSubmit}
        />
      ) :
        props.customSaveButton
      }
      contentStyle={modalContentStyle}
    >
      <div css={contentWrapperStyle}>
        {props.aboveTableContent}

        <SettingsTableComponent
          css={settingsTableStyle({ theme })}
          settingColumns={props.settingColumns}
          settingRows={props.settingRows}
          onChange={props.onChange}
        />

        {props.belowTableContent}

        {props.isLoading &&
          (props.customLoader || <OverlayLoaderComponent />)
        }
      </div>
    </ModalComponent>
  );
};
