import React, {
  type FC, type ReactNode,
} from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Provider } from 'react-redux';
import { TooltipContextProvider } from '~/_shared/baseComponents/tooltip/tooltipContext';
import { IntercomProvider } from '~/_shared/utils/3rdPartyIntegrations/intercom/intercom.context';
import { MapKeyPressContextProvider } from '~/_shared/utils/hooks/useMapKeyPressContext';
import { appStore } from '~/store/app.store';
import { NotificationsProvider } from '../../_shared/components/topUnderbar/notifications/Notifications';
import { CursorOverlayContextProvider } from '../maptiveApp/cursorOverlayContext';
import {
  getDropTargetElementsAtPoint,
  hasNativeElementsFromPoint,
} from './getDropTargetElementsAtPoint';
import { ThemeWrapperComponent } from './themeWrapper/themeWrapper.component';

export const AppContextAndStoreWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Provider store={appStore}>
    <IntercomProvider>
      <TooltipContextProvider>
        <NotificationsProvider>
          <MapKeyPressContextProvider>
            <React.Suspense fallback={null}>
              <DndProvider
                backend={TouchBackend} //HTML5Backend seems to have a bug related positioning https://github.com/react-dnd/react-dnd/issues/2638
                options={{
                  enableMouseEvents: true,
                  enableHoverOutsideTarget: true,
                  getDropTargetElementsAtPoint: !hasNativeElementsFromPoint && getDropTargetElementsAtPoint, // polyfill for ie11
                }}
              >
                <CursorOverlayContextProvider>
                  <ThemeWrapperComponent>
                    {children}
                  </ThemeWrapperComponent>
                </CursorOverlayContextProvider>
              </DndProvider>
            </React.Suspense>
          </MapKeyPressContextProvider>
        </NotificationsProvider>
      </TooltipContextProvider>
    </IntercomProvider>
  </Provider>
);
