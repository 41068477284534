import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  MARKERS_SELECT_ACTIVATE, MARKERS_SELECT_ADD_MARKERS, MARKERS_SELECT_DEACTIVATE,
  MARKERS_SELECT_REMOVE_MARKERS, MARKERS_SELECT_RESET,
  MARKERS_SELECT_SAVE,
  MARKERS_SELECT_SET_LASSO_IS_LOADING,
  MARKERS_SELECT_SET_TYPE,
} from './markersSelect.actionTypes';
import type { MarkersSelectType } from './markersSelect.state';

export const markersSelectActivate = () => ({
  type: MARKERS_SELECT_ACTIVATE,
}) as const;

export const markersSelectDeactivate = () => ({
  type: MARKERS_SELECT_DEACTIVATE,
}) as const;

export const markersSelectReset = () => ({
  type: MARKERS_SELECT_RESET,
}) as const;

export const markersSelectSave = () => ({
  type: MARKERS_SELECT_SAVE,
}) as const;

export const markersSelectAdd = (markersIds: SpreadsheetRowId[]) => ({
  type: MARKERS_SELECT_ADD_MARKERS,
  payload: {
    markersIds,
  },
}) as const;

export const markersSelectRemove = (markersIds: SpreadsheetRowId[]) => ({
  type: MARKERS_SELECT_REMOVE_MARKERS,
  payload: {
    markersIds,
  },
}) as const;

export const markersSelectSetSelectType = (selectType: MarkersSelectType) => ({
  type: MARKERS_SELECT_SET_TYPE,
  payload: {
    selectType,
  },
}) as const;

export const markersSelectSetLassoIsLoading = (isLoading: boolean) => ({
  type: MARKERS_SELECT_SET_LASSO_IS_LOADING,
  payload: {
    isLoading,
  },
}) as const;
