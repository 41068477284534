import {
  call,
  takeEvery,
} from 'redux-saga/effects';
import { UndoItemType } from '~/_shared/types/undo/undoItem';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { type StorageService } from '../../../_shared/utils/storageService';
import { createHistoryItem } from '../../undo/undo.helpers';
import { getUserStorageService } from '../../userData/userData.sagas';
import { type MapSettingsDirectionsAction } from './mapSettingsDirections.action';
import { MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE } from './mapSettingsDirections.actionTypes';

export function* mapSettingsDirectionsSaga() {
  yield takeEvery(MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE, removeRouteSaga);
}

function* removeRouteSaga(action: PickAction<MapSettingsDirectionsAction, typeof MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE>) {
  const storageService: StorageService = yield call(getUserStorageService);

  yield call(createHistoryItem, storageService, { type: UndoItemType.REMOVE_ROUTE, data: action.payload.route, mapId: action.payload.mapId });
}
