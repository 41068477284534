import {
  useCallback, useState,
} from 'react';
import {
  outlineHoverVisuals,
  outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { createUuid } from '~/_shared/utils/createUuid';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import type { MapObjectZIndex } from '../map/mapObjects/mapObject.types';
import { MapOutline } from '../map/mapObjects/mapOutline/mapOutline.component';
import { DrawMapShape } from '../map/mapObjects/mapShape/drawMapShape.container';
import {
  MapShapeLine, type MapShapePolylineVisualsConfig,
} from '../map/mapObjects/mapShape/mapShapeLine.component';
import type { MapShapeInstance } from '../map/mapObjects/mapShape/mapShapeModel';

export const boundaryDrawLineVisuals: MapShapePolylineVisualsConfig = {
  color: [88, 125, 215, 1],
  style: 'solid',
  width: 3,
};

export type LassoDrawProps = Readonly<{
  onDrawingFinished: (path: PolygonPath) => void;
  onDrawingStop: () => void;
  onFirstOutlineHover?: () => void;
  onFirstOutlineMouseOut?: () => void;
  zIndex: MapObjectZIndex;
}>;

export const LassoDrawComponent: React.FC<LassoDrawProps> = (props) => {
  const { onDrawingFinished, onDrawingStop, onFirstOutlineHover, onFirstOutlineMouseOut } = props;

  const [shape, setShape] = useState<MapShapeInstance | undefined>();
  const [firstOutlineHover, setFirstOutlineHover] = useState<boolean>(false);

  const onShapeChange = useCallback((shape: MapShapeInstance) => setShape(shape), []);
  const [shapeKey, setShapeKey] = useState<Uuid>(createUuid());

  const resetShape = useCallback(() => {
    setShape(undefined);
    setShapeKey(createUuid());
  }, []);

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (isFirstOutlineHoverEnabled(shape, outlineId)) {
      setFirstOutlineHover(true);
      onFirstOutlineHover?.();
    }
  }, [onFirstOutlineHover, shape]);

  const onOutlineMouseOut = useCallback(() => {
    setFirstOutlineHover(false);
    onFirstOutlineMouseOut?.();
  }, [onFirstOutlineMouseOut]);

  const renderOutline = useCallback((id: string, outline: MapObjectOutlineInstance) => (
    <MapOutline
      key={id}
      outline={outline}
      visuals={isFirstOutline(shape, outline.id) && firstOutlineHover ? outlineHoverVisuals : outlineVisuals}
      onMouseOver={onOutlineMouseOver}
      onMouseOut={onOutlineMouseOut}
    />
  ), [onOutlineMouseOut, onOutlineMouseOver, shape, firstOutlineHover]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => (
    <MapShapeLine
      key={id}
      id={id}
      start={start}
      end={end}
      visuals={boundaryDrawLineVisuals}
    />
  ), []);

  const onDrawingDone = useCallback(() => {
    if (!shape || !shape.outlines.length) {
      onDrawingStop();
      return;
    }

    if (shape.outlines.length < 3) {
      resetShape();
      return;
    }

    onDrawingFinished(shape.outlines);
  }, [onDrawingFinished, onDrawingStop, resetShape, shape]);

  return (
    <DrawMapShape
      key={shapeKey}
      zIndex={props.zIndex}
      onDone={onDrawingDone}
      onChange={onShapeChange}
      renderOutline={renderOutline}
      renderLine={renderLine}
    />
  );
};

const isFirstOutline = (shape: MapShapeInstance | undefined, outlineId: string) =>
  shape?.outlines[0]?.id === outlineId;

const isFirstOutlineHoverEnabled = (shape: MapShapeInstance | undefined, outlineId: string) => {
  const isFirst = isFirstOutline(shape, outlineId);
  return isFirst && !!(shape && shape.outlines.length > 2);
};
