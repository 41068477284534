import {
  type FC, useEffect, useMemo,
} from 'react';
import { type MapInfo } from '~/_shared/types/map';
import { type IntersectsMapItem } from '~/map/layered/createLayeredMapModal/MatchupColumnsSection/matchupColumnsSection.helpers';
import { type BaseMapInfos } from '~/map/layered/layering.helpers';
import { type MapIntersect } from '~/map/map.repository';
import { type MapListingItem } from '../../../listing/item/mapListingItem';
import { MatchupColumnsSectionComponent } from './matchupColumnsSection.component';
import { useMatchupColumns } from './useMatchupColumns';

type MatchupColumnsSectionContainerProps = Readonly<{
  currentBaseMaps?: Readonly<BaseMapInfos>;
  disabledMapsIds?: ReadonlySet<number>;
  disableColumnNamesEditing?: boolean;
  isSubmitting: boolean;
  layeringMapInfo?: MapInfo | null;
  mapName: string;
  maps: MapListingItem[];

  onSubmit: (data: MapIntersect[]) => void;
  setIsLoading: (isLoading: boolean) => void;
}>;

export const MatchupColumnsSectionContainer: FC<MatchupColumnsSectionContainerProps> =
  ({
    currentBaseMaps,
    disabledMapsIds,
    disableColumnNamesEditing,
    isSubmitting,
    layeringMapInfo,
    mapName,
    maps,
    onSubmit,
    setIsLoading,
  }) => {
    const { isLoading, intersects, columnData, getMapIdFromPrimarySpreadsheetId, getMapFromRealSpreadsheetId,
      getPreselectionColumns, getSpreadsheetIdFromMapId, getRealSpreadsheetIdFromMapId,
      getPrimarySpreadsheetIdFromMapId } = useMatchupColumns();

    const isEditing = Boolean(layeringMapInfo?.layering);

    // This function adds to the newly added maps also the current ones already linked
    // but only if we are in editing mode, otherwise it corresponds to the added maps
    const mapsWithCurrentBaseMaps = useMemo<IntersectsMapItem[]>(() => {
      if (isEditing) {
        const _currentBaseMaps = currentBaseMaps ?? {};
        const currentBaseMapsCount = Object.keys(_currentBaseMaps).length;
        // inEditMode means that we are not deleting any layers, neither adding any layers
        // in this case we need to allow editing of current maps, otherwise not
        const inEditMode = layeringMapInfo?.layering?.baseMaps.length === currentBaseMapsCount && maps.length === 0;
        const isDisconnected = layeringMapInfo?.layering?.connected === false;
        return [
          ...maps.map(m => ({
            id: m.id,
            name: m.name,
            isCurrentMap: false,
            isDisabled: isDisconnected,
            spreadSheets: m.spreadSheets.map(spreadSheet => ({
              isPrimary: spreadSheet.isPrimary,
              spreadsheetId: spreadSheet.spreadsheetId,
              realSpreadsheets: spreadSheet.realSpreadsheets,
            })),
          })),
          ...Object.entries(_currentBaseMaps).map(([sId, map]) => ({
            id: map.id,
            name: map.name ?? '',
            isCurrentMap: true,
            isDisabled: disabledMapsIds?.has(map.id) || isDisconnected || !inEditMode,
            realSpreadsheetId: +sId,
          })),
        ];
      }
      return maps.map(m => ({
        id: m.id,
        name: m.name,
        isCurrentMap: false,
        isDisabled: false,
        spreadSheets: m.spreadSheets.map(spreadSheet => ({
          isPrimary: spreadSheet.isPrimary,
          spreadsheetId: spreadSheet.spreadsheetId,
          realSpreadsheets: spreadSheet.realSpreadsheets,
        })),
      }));
    }, [isEditing, maps, currentBaseMaps, layeringMapInfo?.layering?.baseMaps.length, layeringMapInfo?.layering?.connected, disabledMapsIds]);

    useEffect(() => {
      if (maps.length) {
        getPreselectionColumns(maps, layeringMapInfo);
      }
    }, [maps, layeringMapInfo, getPreselectionColumns]);

    useEffect(() => {
      setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    return (
      <MatchupColumnsSectionComponent
        maps={mapsWithCurrentBaseMaps}
        mapName={mapName}
        onSubmit={onSubmit}
        isLoading={isLoading || isSubmitting}
        intersects={intersects}
        columnData={columnData}
        disableColumnNamesEditing={disableColumnNamesEditing}
        getMapIdFromPrimarySpreadsheetId={getMapIdFromPrimarySpreadsheetId}
        getMapFromRealSpreadsheetId={getMapFromRealSpreadsheetId}
        getSpreadsheetIdFromMapId={getSpreadsheetIdFromMapId}
        getRealSpreadsheetIdFromMapId={getRealSpreadsheetIdFromMapId}
        getPrimarySpreadsheetIdFromMapId={getPrimarySpreadsheetIdFromMapId}
        layeringMapInfo={layeringMapInfo}
      />
    );
  };
