import { type Theme } from '~/_shared/themes/theme.model';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import type { SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { compareSpreadsheetColumnIds } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { getPerThemeColor } from '~/_shared/utils/colors/colors.helpers';
import {
  numberObjectEntries, stringObjectEntries,
} from '~/_shared/utils/object/objectEnumerators';
import { defaultLocationDataStyle } from '~/customizeLocationPanel/customizeLocationPanel.constants';
import {
  SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.constants';
import { SidebarTitleType } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.enums';
import {
  type SidebarTitleStyle, type SidebarTitleStyleSettingsValueData,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import {
  type ColumnStyleSettingsDataState,
  type ColumnStyleSettingsValueDataState, type PanelSettingsState, type PanelSettingsTitleState,
  type PanelSettingsTitleValueDataState,
} from '~/store/mapSettings/markers/mapSettingsMarkers.state';
import {
  type ColumnStyleSettingsData, type ColumnStyleSettingsValueData, type PanelSettings,
} from './panelSettings.types';

/*
 * The following functions convert PanelSettingsState and its subtypes to PanelSettings
 * For multi-themed properties from PanelSettingsState, only the @theme value will be selected
 */
export const convertPanelSettingsStateToPanelSettings = (panelSettingsState: PanelSettingsState, theme: Theme): PanelSettings => {
  const defaultColors = {
    defaultLabelFontColor: defaultLocationDataStyle(theme, null).labelFontColor,
    defaultValueFontColor: defaultLocationDataStyle(theme, null).valueFontColor,
  };

  const bulkStyle = panelSettingsState.bulkStyle
    ? convertColumnStyleSettingsDataStateToColumnStyleSettingsData(panelSettingsState.bulkStyle, theme, defaultColors)
    : undefined;

  return ({
    generalSettings: panelSettingsState.generalSettings,
    orderedContactItems: panelSettingsState.orderedContactItems,
    orderedBasicItems: panelSettingsState.orderedBasicItems,
    bulkStyle,
    ...(panelSettingsState.locationDescriptionStyle ? {
      locationDescriptionStyle: convertPanelSettingsTitleToSidebarTitleStyle(
        panelSettingsState.locationDescriptionStyle,
        theme,
      ),
    } : {}),

    itemsData: numberObjectEntries(panelSettingsState.itemsData).reduce((perSpreadsheetAccum, [spreadsheetId, perColumnSettings]) => ({
      ...perSpreadsheetAccum,
      ...(perColumnSettings ? {
        [spreadsheetId]: stringObjectEntries(perColumnSettings).reduce((perColumnAccum, [columnId, settings]) => ({
          ...perColumnAccum,
          ...(settings ? {
            [columnId]: {
              id: settings.id,
              isActive: settings.isActive,
              editable: true,
              ...(settings.style ? {
                style: convertColumnStyleSettingsDataStateToColumnStyleSettingsData(settings.style, theme, {
                  defaultValueFontColor: bulkStyle?.valueFontColor ?? defaultColors.defaultValueFontColor,
                  defaultLabelFontColor: bulkStyle?.labelFontColor ?? defaultColors.defaultLabelFontColor,
                }),
              } : {}),
            },
          } : {}),
        }), {}),
      } : {}),
    }), newPerSpreadsheetMap()),
  });
};

const convertColumnStyleSettingsDataStateToColumnStyleSettingsData = (
  columnStyleSettingsDataState: ColumnStyleSettingsDataState,
  theme: Theme,
  defaults: {
    defaultValueFontColor: string;
    defaultLabelFontColor: string;
  },
): ColumnStyleSettingsData => ({
  ...columnStyleSettingsDataState,
  ...convertStyleSettingsValueDataStateToStyleSettingsValueData(columnStyleSettingsDataState, theme, defaults.defaultValueFontColor),
  labelFontColor: getPerThemeColor(columnStyleSettingsDataState.labelFontColor, theme.name) ?? defaults.defaultLabelFontColor,
});

export const convertStyleSettingsValueDataStateToStyleSettingsValueData = (
  columnStyleSettingsValueDataState: ColumnStyleSettingsValueDataState | PanelSettingsTitleValueDataState,
  theme: Theme,
  defaultValueFontColor: string,
): ColumnStyleSettingsValueData => ({
  ...columnStyleSettingsValueDataState,
  valueFontColor: getPerThemeColor(columnStyleSettingsValueDataState.valueFontColor, theme.name) ?? defaultValueFontColor,
});

export const convertPanelSettingsTitleToSidebarTitleStyle = (
  panelSettingsTitleSettings: PanelSettingsTitleState,
  theme: Theme,
): SidebarTitleStyle => {
  const typeAndDefaults = [
    [SidebarTitleType.description, SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE(theme)],
    [SidebarTitleType.header, SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE(theme)],
    [SidebarTitleType.semiHeader, SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE(theme)],
  ] as const;
  return typeAndDefaults.reduce((accum, [type, defaults]) => {
    const panelTitle = panelSettingsTitleSettings[type];

    return ({
      ...accum,
      ...(panelTitle ? {
        [type]: {
          showValue: panelTitle.showValue,
          ...convertStyleSettingsValueDataStateToStyleSettingsValueData(
            panelTitle,
            theme,
            defaults.valueFontColor,
          ),
        },
      } : {}),
    });
  }, {} as SidebarTitleStyle);
};

/*
 * The following functions help convert PanelSettings and its subtypes into PanelSettingsState
 * However, if there are multi-themed properties, it would merge them with those from existing PanelSettingsState
 */
export const mergePanelSettingsToState = (
  panelSettings: PanelSettings,
  panelSettingsState: PanelSettingsState,
  theme: Theme,
): PanelSettingsState => ({
  generalSettings: panelSettings.generalSettings,
  orderedContactItems: panelSettings.orderedContactItems,
  orderedBasicItems: panelSettings.orderedBasicItems,
  ...(panelSettings.locationDescriptionStyle ? {
    locationDescriptionStyle: mergeSidebarTitleStyleToPanelSettings(
      panelSettings.locationDescriptionStyle,
      panelSettingsState.locationDescriptionStyle,
      theme,
    ),
  } : {}),
  ...(panelSettings.bulkStyle ? {
    bulkStyle: mergeColumnStyleSettingsDataToState(
      panelSettings.bulkStyle,
      panelSettingsState.bulkStyle,
      theme,
    ),
  } : {}),
  itemsData: numberObjectEntries(panelSettings.itemsData).reduce((perSpreadsheetAccum, [spreadsheetId, perColumnSettings]) => ({
    ...perSpreadsheetAccum,
    ...(perColumnSettings ? {
      [spreadsheetId]: stringObjectEntries(perColumnSettings).reduce((perColumnAccum, [columnId, settings]) => ({
        ...perColumnAccum,
        ...(settings ? {
          [columnId]: {
            id: settings.id,
            isActive: settings.isActive,
            ...(settings.style ? {
              style: mergeColumnStyleSettingsDataToState(
                settings.style,
                panelSettingsState.itemsData[spreadsheetId]?.[columnId]?.style,
                theme,
              ),
            } : {}),
          },
        } : {}),
      }), {}),
    } : {}),
  }), newPerSpreadsheetMap()),
});

const mergeColumnStyleSettingsDataToState = (
  columnStyleSettingsData: ColumnStyleSettingsData,
  columnStyleSettingsDataState: ColumnStyleSettingsDataState | undefined,
  theme: Theme,
): ColumnStyleSettingsDataState => ({
  ...columnStyleSettingsData,
  ...mergeStyleSettingsValueDataState(
    columnStyleSettingsData, columnStyleSettingsDataState, theme,
  ),
  labelFontColor: {
    ...columnStyleSettingsDataState?.labelFontColor,
    [theme.name]: columnStyleSettingsData.labelFontColor,
  },
});

const mergeStyleSettingsValueDataState = (
  columnStyleSettingsValueData: ColumnStyleSettingsValueData | SidebarTitleStyleSettingsValueData,
  columnStyleSettingsValueDataState: ColumnStyleSettingsValueDataState | undefined,
  theme: Theme,
): ColumnStyleSettingsValueDataState => ({
  ...columnStyleSettingsValueData,
  valueFontColor: {
    ...columnStyleSettingsValueDataState?.valueFontColor,
    [theme.name]: columnStyleSettingsValueData.valueFontColor,
  },
});

export const mergeSidebarTitleStyleToPanelSettings = (
  sidebarTitleStyle: SidebarTitleStyle,
  panelSettingsTitleSettings: PanelSettingsTitleState | undefined,
  theme: Theme,
): PanelSettingsTitleState => (
  stringObjectEntries(sidebarTitleStyle).reduce((accum, [locationType, settings]) => ({
    ...accum,
    ...(settings ? {
      [locationType]: {
        ...settings,
        valueFontColor: {
          ...panelSettingsTitleSettings?.[locationType]?.valueFontColor,
          [theme.name]: settings.valueFontColor,
        },
      },
    } : {}),
  }), {} as PanelSettingsTitleState)
);

export const reorderColumnsWithPriority = (
  columnIds: ReadonlyArray<SpreadsheetColumnId>,
  priorityColumnIds: ReadonlyArray<SpreadsheetColumnId>,
): SpreadsheetColumnId[] => {
  const isValidColumnId = (validatedColumnId: SpreadsheetColumnId): boolean =>
    columnIds.some(columnId => compareSpreadsheetColumnIds(columnId, validatedColumnId));
  const validPriorityColumns = priorityColumnIds.filter(priorityColumn => isValidColumnId(priorityColumn));

  const isPriorityColumn = (columnId: SpreadsheetColumnId): boolean =>
    priorityColumnIds.some(priorityColumn => compareSpreadsheetColumnIds(columnId, priorityColumn));
  const remainingColumns = columnIds.filter(columnId =>
    !isPriorityColumn(columnId));

  return [...validPriorityColumns, ...remainingColumns];
};
