import { css } from '@emotion/react';
import {
  type DropDownItemSize, getRegularDropdownItemSize,
} from '~/_shared/baseComponents/dropdown';
import { type InputSize } from '~/_shared/baseComponents/inputs';

export const dropdownContainerStyle = css({
  display: 'flex',
});

export const selectedItemStyle = css({
  margin: 2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const autocompleteNoResultsStyle = ({ inputSize, itemSize }: {inputSize: InputSize; itemSize: DropDownItemSize}) => css({
  height: getRegularDropdownItemSize(inputSize, itemSize),
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
});

export const selectedItemTextStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
