import { css } from '@emotion/react';
import {
  type FC, useCallback, useState,
} from 'react';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import {
  InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { getTranslatedCalendarMonthsAsNameValue } from '~/_shared/utils/date/date.helpers';
import { useTranslation } from '~/_shared/utils/hooks';

const titleElementsStyle = css({
  display: 'inline-block',
});

const yearStyle = ({ isEditable }: { isEditable: boolean }) => css({
  paddingLeft: 5,
  width: isEditable ? 70 : undefined,
  fontWeight: isEditable ? undefined : 700,
});

const titleStyle = css({
  fontSize: 16,
  userSelect: 'none',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const monthsStyle = ({ isYearEditable }: {isYearEditable: boolean}) => css({
  width: isYearEditable ? 94 : 128,
  position: 'relative',
});

type DatePickerHeaderComponentProps = {
  date: Date;

  onMonthChange: (newMonth: number) => void;
  onYearChange: (newYear: number) => void;
};

export const DatePickerHeaderComponent: FC<DatePickerHeaderComponentProps> = (props) => {
  const [t] = useTranslation();

  const [isYearEditable, setIsYearEditable] = useState(false);

  const onMonthSelected = useCallback((newMonth: number) => {
    const onMonthChange = props.onMonthChange;
    onMonthChange(newMonth);
  }, [props.onMonthChange]);

  const onYearTextClick = useCallback(() => {
    setIsYearEditable(true);
  }, []);

  return (
    <div
      css={titleStyle}
    >
      <div css={[titleElementsStyle, monthsStyle({ isYearEditable })]}>
        <RegularDropdownComponent<number>
          options={getTranslatedCalendarMonthsAsNameValue(t)}
          onChange={onMonthSelected}
          inputSize={InputSize.Small}
          value={props.date.getMonth()}
        />
      </div>
      {!isYearEditable && (
        <div
          css={[titleElementsStyle, yearStyle({ isEditable: false })]}
          onClick={onYearTextClick}
        >
          {props.date.getFullYear()}
        </div>
      )}
      {isYearEditable && (
        <div css={[titleElementsStyle, yearStyle({ isEditable: true })]}>
          <InputWithSpinnersComponent
            size={InputSize.Small}
            value={props.date.getFullYear()}
            onChange={props.onYearChange}
          />
        </div>
      )}
    </div>
  );
};
