import { buffers } from 'redux-saga';
import {
  actionChannel,
  call, put, take, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { type ActionChannel } from '~/_shared/utils/types/saga.type';
import {
  boundaryFillDefaultOpacity,
  boundaryTerritoryGroupCreateSettingsStyleDefaults,
  defaultBoundaryTerritoryGroupResponseSettings,
} from '~/boundary/settings/defaultBoundarySettings';
import { appStore } from '~/store/app.store';
import { type ExtendedBoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import { boundaryGroupsRequest } from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import { cloneBoundaryGroup } from '~/store/boundaryGroups/boundaryGroups.repository';
import { activeMapElementsClearState } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { activeBoundarySelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { frontedStateProcessingBoundariesInitialized } from '~/store/frontendState/processing/processing.actionCreators';
import { mapSettingsBoundariesSetPrimaryBoundaryGroupId } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.actionCreators';
import { clearBoundaryLocationsFilter } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import { boundaryLocationsFiltersStateSelector } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { type BoundaryLocationsFiltersState } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { isMapInOfflineModeSelector } from '~/store/selectors/useMapInfoSelectors';
import { SPREADSHEET_RESET_STATE_AND_REFETCH_DATA } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { guaranteeHash } from '../../_shared/components/colorPicker/colorPicker.helpers';
import { PRESET_COLORS_PRIMARY } from '../../_shared/constants/colors.constants';
import {
  isApiConcurrencyError, isApiError, isNotFoundApiError,
} from '../../_shared/utils/api/apiError.helpers';
import {
  copy, subtract,
} from '../../_shared/utils/collections/collections';
import { createUuid } from '../../_shared/utils/createUuid';
import { createDefaultDemographicsMetrics } from '../../_shared/utils/metric/defaultMetric.factory';
import { getDefaultSpreadsheetColumnMetrics } from '../../_shared/utils/metric/defaultMetric.sagas';
import {
  mapMetricsToServerModel, type MetricModel,
} from '../../_shared/utils/metric/metrics.factory';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { AppErrorType } from '../../appError/appErrorType.enum';
import { type BoundaryGroup } from '../../boundary/boundary.types';
import { BoundaryFill } from '../../boundary/settings/boundaryFill.type';
import { ModalType } from '../../modal/modalType.enum';
import {
  getCalculateBucketFunction,
  isBoundaryTerritoryGroupCustom,
} from '../../sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import { translate } from '../../translations/Trans';
import { type BoundaryGroupsActions } from '../boundaryGroups/boundaryGroups.actions';
import {
  BOUNDARY_GROUP_CREATE_SUCCESS,
  BOUNDARY_GROUP_FETCH_ALL_ERROR,
  BOUNDARY_GROUP_FETCH_ALL_SUCCESS,
  BOUNDARY_GROUPS_DELETE_SUCCESS,
} from '../boundaryGroups/boundaryGroups.actionTypes';
import {
  boundaryGroupsSelector, isCustomGroup,
} from '../boundaryGroups/boundaryGroups.selectors';
import { type BoundaryItemsAction } from '../boundaryItems/boundaryItems.action';
import {
  BOUNDARY_ITEMS_FETCHED_DATA,
  BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS,
} from '../boundaryItems/boundaryItems.actionTypes';
import { mapBoundariesSelector } from '../boundaryItems/boundaryItems.selectors';
import { type BoundaryStateItems } from '../boundaryItems/boundaryItems.state';
import { getBoundaryTerritoryGroupDetails } from '../boundaryTerritoryDetails/boundaryTerritoryDetails.repository';
import { type BoundaryTerritoryGroupDetails } from '../boundaryTerritoryDetails/boundaryTerritoryGroups.state';
import { type MapIdAction } from '../mapId/mapId.action';
import { MAP_ID_SET } from '../mapId/mapId.actionTypes';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../mapSettings/data/mapSettingsData.actionTypes';
import {
  closeModal, createAppError, openModalWithId,
} from '../modal/modal.actionCreators';
import { clientIdSelector } from '../selectors/useClientIdSelector';
import { mapIdSelector } from '../selectors/useMapIdSelector';
import { type FilterTreeItemRequest } from '../spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import { getStoreFilterTree } from '../spreadsheetData/filterTree/spreadsheetDataFilterTree.helpers';
import { BoundaryTerritoryType } from './boundaryTerritoryGroup.type';
import { type BoundaryTerritoryGroupsAction } from './boundaryTerritoryGroups.action';
import {
  boundaryTerritoryGroupsCreateCancel,
  boundaryTerritoryGroupsCreateError,
  boundaryTerritoryGroupsCreateRequest,
  boundaryTerritoryGroupsCreateSuccess,
  boundaryTerritoryGroupsFetchError,
  boundaryTerritoryGroupsFetchRequest,
  boundaryTerritoryGroupsFetchSuccess,
  boundaryTerritoryGroupsRemoveError,
  boundaryTerritoryGroupsRemoveRequest,
  boundaryTerritoryGroupsRemoveSuccess,
  boundaryTerritoryGroupsUpdateCancel,
  boundaryTerritoryGroupsUpdateError,
  boundaryTerritoryGroupsUpdateRequest,
  boundaryTerritoryGroupUpdateAssignmentsError,
  boundaryTerritoryGroupUpdateAssignmentsSuccess,
  boundaryTerritoryGroupUpdateSuccess,
} from './boundaryTerritoryGroups.actionCreators';
import {
  BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST,
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS,
  BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST,
  BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST,
} from './boundaryTerritoryGroups.actionTypes';
import {
  createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup,
  createTerritoryBoundaryGroupFromResponse,
  createTerritoryBoundaryGroupModelFromResponse,
  getBoundaryTerritoryGroupMatchupFromRequest,
  getBoundaryTerritoryGroupSettingsFromRequest,
} from './boundaryTerritoryGroups.factory';
import {
  BoundaryMatchingType,
  type BoundaryTerritoryGroupCreateRequest,
  type BoundaryTerritoryGroupModelResponse,
  type BoundaryTerritoryGroupResponse,
  type BoundaryTerritoryGroupsResponse,
  createBoundaryTerritoryGroup,
  getBoundaryTerritoryGroups,
  removeBoundaryTerritoryGroup,
  updateBoundaryTerritoryAssignments,
  updateBoundaryTerritoryGroup,
} from './boundaryTerritoryGroups.repository';
import { boundaryTerritoryGroupsSelector } from './boundaryTerritoryGroups.selectors';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupModel,
} from './boundaryTerritoryGroups.state';

export function* boundaryTerritoryGroupsSagas() {
  yield takeLatest(MAP_ID_SET, onMapIdChange);
  yield takeLatest(SPREADSHEET_RESET_STATE_AND_REFETCH_DATA, onSpreadsheetDataChange);
  yield takeLatest(BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST, onFetchBoundaryTerritoryGroupsRequest);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST, boundaryTerritoryGroupCreateRequest);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST, cloneBoundaryTerritoryGroup);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST, removeTerritoryGroup);
  yield takeLatest(MAP_SETTINGS_REMOVE_ACTIVE_ITEMS, removeAllTerritoryGroups);
  yield takeEvery(BOUNDARY_GROUP_CREATE_SUCCESS, onBoundaryGroupCreateSuccess);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST, onUpdateBoundaryTerritoryGroup);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST, updateTerritoryGroupAssignments);
  yield takeLatest(BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS, onBoundaryTerritoryGroupCreateSuccess);
  yield takeEvery(BOUNDARY_ITEMS_FETCHED_DATA, onBoundaryItemsFetchedData);
  yield takeEvery(BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS, removeOutdatedBoundaryStyleAssignments);
  yield takeEvery(BOUNDARY_GROUPS_DELETE_SUCCESS, removedBoundaryTerritoryGroupsForRemovedBoundaryGroups);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS, updateBoundaryTerritoryGroupSettingsField);
  yield takeEvery([BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS, BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS], checkForRemovedBtg);
}

function* onBoundaryGroupCreateSuccess(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUP_CREATE_SUCCESS>) {
  const mapId: number | null = yield select(state => state.map.mapId);

  if (mapId === null) {
    return;
  }

  yield put(boundaryTerritoryGroupsCreateRequest({
    map_id: mapId,
    boundary_group_id: action.payload.boundaryGroup.id,
    matchings: {
      matching_type: BoundaryMatchingType.Geometry,
    },
    settings: {
      ...defaultBoundaryTerritoryGroupResponseSettings,
      boundary_territories: [],
      boundary_territory_type: BoundaryTerritoryType.Manual,
      style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
      calculate_bucket_function: getCalculateBucketFunction(BoundaryTerritoryType.Manual),
    },
  }, action.payload.onBoundaryTerritoryGroupCreateSuccess));
}

function* onMapIdChange(action: PickAction<MapIdAction, typeof MAP_ID_SET>) {
  if (action.payload.mapId === null) {
    return;
  }

  yield put(boundaryTerritoryGroupsFetchRequest(action.payload.mapId));
}

function* onSpreadsheetDataChange() {
  const mapId: number | null = yield select<number | null>(state => state.map.mapId);
  if (mapId === null) {
    return;
  }

  yield put(boundaryTerritoryGroupsFetchRequest(mapId));
}

function* fetchBoundaryTerritoryGroups(clientId: number, mapId: number) {
  try {
    const response: BoundaryTerritoryGroupsResponse = yield call(getBoundaryTerritoryGroups, clientId, {
      map_id: mapId,
    });

    yield put(boundaryTerritoryGroupsFetchSuccess(response.boundary_territory_groups.map(item =>
      createTerritoryBoundaryGroupFromResponse(item)
    )));

    if (!response.boundary_territory_groups.length) {
      yield put(frontedStateProcessingBoundariesInitialized());
    }
  }
  catch (e) {
    yield put(boundaryTerritoryGroupsFetchError(e));
  }
}

function* onFetchBoundaryTerritoryGroupsRequest(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(clientIdSelector);

  if (clientId === null) {
    return;
  }

  yield fetchBoundaryTerritoryGroups(clientId, action.payload.mapId);
}

function* boundaryTerritoryGroupCreateRequest(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const isMapOffline: boolean = yield select<boolean>(isMapInOfflineModeSelector);

  if (!clientId) {
    return;
  }

  if (isMapOffline) {
    yield call(createOfflineBoundaryTerritoryGroup, action, clientId, action.payload.onSuccess);
    return;
  }

  try {
    const requestData = action.payload.request;
    const allBoundaryGroups: BoundaryGroup[] = yield select(boundaryGroupsSelector);
    const boundaryGroupForCurrent = allBoundaryGroups.find(bg => bg.id === requestData.boundary_group_id);
    const defaultDemographicsMetrics = boundaryGroupForCurrent ? createDefaultDemographicsMetrics(boundaryGroupForCurrent.demographics) : [];
    const defaultSpreadsheetColumnMetrics: MetricModel[] = yield call(getDefaultSpreadsheetColumnMetrics);
    const serverModelMetrics = mapMetricsToServerModel([...defaultDemographicsMetrics, ...defaultSpreadsheetColumnMetrics]);

    const requestDataWithDefaultMetric: BoundaryTerritoryGroupCreateRequest = {
      ...requestData,
      settings: {
        ...requestData.settings,
        metrics: serverModelMetrics,
      },
    };

    const response: BoundaryTerritoryGroupResponse = yield call(
      createBoundaryTerritoryGroup,
      clientId,
      requestDataWithDefaultMetric
    );

    const newBoundaryTerritoryGroup = createTerritoryBoundaryGroupFromResponse(response);

    yield put(boundaryTerritoryGroupsCreateSuccess(newBoundaryTerritoryGroup));
    action.payload.onSuccess?.(newBoundaryTerritoryGroup);
  }
  catch (e) {
    yield call(onBoundaryTerritoryGroupCreateError, e, action.payload.request);
  }
}

function* cloneBoundaryTerritoryGroup(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

  if (!clientId) {
    action.payload.onError?.(new Error('clientId is null'));
    return;
  }

  try {
    yield call(
      cloneBoundaryGroup,
      clientId,
      action.payload.request.boundary_territory_group_id,
      action.payload.request.new_boundary_territory_group_name
    );

    const refreshChannel: ActionChannel<Action> = yield actionChannel([BOUNDARY_GROUP_FETCH_ALL_SUCCESS, BOUNDARY_GROUP_FETCH_ALL_ERROR], buffers.sliding(1));
    yield put(boundaryGroupsRequest());
    yield take(refreshChannel);

    action.payload.onSuccess?.();
  }
  catch (e) {
    action.payload.onError?.(e);
  }
}

function* onBoundaryTerritoryGroupCreateError(e: any, request: BoundaryTerritoryGroupCreateRequest) {
  const clientId: number | null = yield select(clientIdSelector);

  // BTG was potentially already added to the map
  if (isApiError(e) && e.responseStatus >= 400 && e.responseStatus < 500 && clientId !== null) {
    yield fetchBoundaryTerritoryGroups(clientId, request.map_id);
    const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>
      = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);

    const lookedUpGroup = boundaryTerritoryGroups.find(group => group.boundaryGroupId === request.boundary_group_id);

    // check if it was already added
    if (lookedUpGroup) {
      const boundaryGroups: ReadonlyArray<BoundaryGroup> = yield select<ReadonlyArray<BoundaryGroup>>(
        state => state.boundaries.groups.groups
      );
      const isGroupCustom = isBoundaryTerritoryGroupCustom(lookedUpGroup, boundaryGroups);
      yield put(boundaryTerritoryGroupsCreateError());

      yield put(createAppError({
        type: AppErrorType.General,
        title: translate(isGroupCustom ? 'Territory group was already added' : 'Boundary was already added'),
        errorTitle: translate(isGroupCustom ? 'Territory group was already added to the map' : 'Boundary was already added to the map'),
      }));

      return;
    }
  }

  yield put(boundaryTerritoryGroupsCreateError());
  yield put(createAppError({
    type: AppErrorType.General,
    errorTitle: translate('An error occurred while creating the boundary'),
    title: translate('Boundary Create Error'),
  }));

  console.error(e);
}

function* onBoundaryTerritoryGroupCreateSuccess(action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS>) {
  yield put(mapSettingsBoundariesSetPrimaryBoundaryGroupId(action.payload.boundaryTerritoryGroup.boundaryGroupId));
  yield saveBoundaryItemsStyleIntoBoundaryTerritoryGroupSettings(action.payload.boundaryTerritoryGroup.boundaryGroupId);
}

function* onBoundaryItemsFetchedData(action: PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_FETCHED_DATA>) {
  yield saveBoundaryItemsStyleIntoBoundaryTerritoryGroupSettings(action.payload.boundaryGroupId);
}

let temporaryBoundaryTerritoryGroupIdCounter: number = -1;

function* createOfflineBoundaryTerritoryGroup(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST>,
  clientId: number,
  onSuccess?: (boundaryTerritoryGroup: BoundaryTerritoryGroup) => void,
) {
  try {
    const requestId = createUuid();
    const combinedFilterTrees: FilterTreeItemRequest | null = yield getStoreFilterTree();

    const itemResponse: BoundaryTerritoryGroupModelResponse = {
      map_id: action.payload.request.map_id,
      boundary_group_id: action.payload.request.boundary_group_id,
      archived_at: null,
      matchings: getBoundaryTerritoryGroupMatchupFromRequest(action.payload.request.matchings),
      settings: getBoundaryTerritoryGroupSettingsFromRequest(action.payload.request.settings, []),
    };

    const newTerritoryGroup: BoundaryTerritoryGroupModel = createTerritoryBoundaryGroupModelFromResponse(itemResponse);

    const details: Map<string, BoundaryTerritoryGroupDetails> = yield call(getBoundaryTerritoryGroupDetails, clientId, [{
      boundaryTerritoryGroup: newTerritoryGroup,
      boundaryTerritoryGroupQueryId: requestId,
      filter: combinedFilterTrees?.filterTree ? {
        filter_tree: combinedFilterTrees.filterTree,
      } : null,
    }]);

    const response = details.get(requestId);

    if (!response) {
      yield put(boundaryTerritoryGroupsCreateCancel());
      return;
    }

    const newBoundaryTerritoryGroupId = response.boundaryTerritoryGroupId ?? temporaryBoundaryTerritoryGroupIdCounter--;
    const newBoundaryTerritoryGroup = {
      ...newTerritoryGroup,
      boundaryTerritoryGroupId: newBoundaryTerritoryGroupId,
      settings: {
        ...newTerritoryGroup.settings,
        boundaryTerritories: response.boundaryTerritories,
      },
    };

    yield put(boundaryTerritoryGroupsCreateSuccess(newBoundaryTerritoryGroup));
    onSuccess?.(newBoundaryTerritoryGroup);
  }
  catch (e) {
    yield onBoundaryTerritoryGroupCreateError(e, action.payload.request);
  }
}

function* onUpdateBoundaryTerritoryGroup(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const isMapOffline: boolean = yield select<boolean>(isMapInOfflineModeSelector);
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> =
    yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);
  const previousBoundaryTerritoryGroup = boundaryTerritoryGroups.find(
    btg => btg.boundaryTerritoryGroupId === action.payload.updatedBoundaryTerritoryGroup.boundaryTerritoryGroupId
  );

  if (!clientId || !previousBoundaryTerritoryGroup) {
    return;
  }

  if (isMapOffline) {
    yield updateOfflineBoundaryTerritoryGroup(action, clientId);
  }
  else {
    const updateRequest = createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup(
      action.payload.updatedBoundaryTerritoryGroup,
    );

    try {
      const newTerritoryGroup: BoundaryTerritoryGroup = yield call(
        updateBoundaryTerritoryGroup,
        clientId,
        action.payload.updatedBoundaryTerritoryGroup.boundaryTerritoryGroupId,
        updateRequest
      );

      action.payload.onDone?.();

      yield put(boundaryTerritoryGroupUpdateSuccess(newTerritoryGroup, { fetchBoundaryDetails: action.payload.fetchBoundaryDetails }));
    }
    catch (e) {
      yield onBoundaryTerritoryGroupUpdateError({
        error: e,
        boundaryTerritoryGroup: action.payload.updatedBoundaryTerritoryGroup,
        onSuccess: action.payload.onDone,
        failSilently: action.payload.failSilently,
        refetchOnConcurrencyError: action.payload.refetchOnConcurrencyError,
      });
    }
  }
}

function* updateOfflineBoundaryTerritoryGroup(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST>,
  clientId: number
) {
  try {
    if (!action.payload.fetchBoundaryDetails) {
      yield put(boundaryTerritoryGroupUpdateSuccess(action.payload.updatedBoundaryTerritoryGroup, { fetchBoundaryDetails: false }));
      return;
    }

    const requestId = createUuid();
    const combinedFilterTrees: FilterTreeItemRequest | null = yield getStoreFilterTree();

    const details: Map<string, BoundaryTerritoryGroupDetails> = yield call(getBoundaryTerritoryGroupDetails, clientId, [{
      boundaryTerritoryGroup: action.payload.updatedBoundaryTerritoryGroup,
      boundaryTerritoryGroupQueryId: requestId,
      filter: combinedFilterTrees?.filterTree ? {
        filter_tree: combinedFilterTrees.filterTree,
      } : null,
    }]);

    const response = details.get(requestId);

    if (!response) {
      yield put(boundaryTerritoryGroupsUpdateCancel());
      return;
    }

    action.payload.onDone?.();

    yield put(boundaryTerritoryGroupUpdateSuccess({
      ...action.payload.updatedBoundaryTerritoryGroup,
      settings: {
        ...action.payload.updatedBoundaryTerritoryGroup.settings,
        boundaryTerritories: response.boundaryTerritories,
      },
    },
    { fetchBoundaryDetails: action.payload.fetchBoundaryDetails }
    ));
  }
  catch (e) {
    yield onBoundaryTerritoryGroupUpdateError({
      error: e,
      boundaryTerritoryGroup: action.payload.updatedBoundaryTerritoryGroup,
      onSuccess: action.payload.onDone,
      failSilently: action.payload.failSilently,
      refetchOnConcurrencyError: action.payload.refetchOnConcurrencyError,
    });
  }
}

function* updateTerritoryGroupAssignments(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const isMapOffline: boolean = yield select<boolean>(isMapInOfflineModeSelector);

  if (!clientId) {
    return;
  }

  try {
    if (!isMapOffline) {
      yield call(updateBoundaryTerritoryAssignments, clientId, action.payload.boundaryTerritoryGroupId, action.payload.requests);
    }

    yield put(boundaryTerritoryGroupUpdateAssignmentsSuccess(action.payload.boundaryTerritoryGroupId, action.payload.requests));
    action.payload.onSuccess?.();
  }
  catch (e) {
    yield put(boundaryTerritoryGroupUpdateAssignmentsError());
  }
}

function* onBoundaryTerritoryGroupUpdateError({ error, boundaryTerritoryGroup, onSuccess, failSilently, refetchOnConcurrencyError }: {
  error: any;
  boundaryTerritoryGroup: BoundaryTerritoryGroup;
  onSuccess?: () => void;
  failSilently: boolean;
  refetchOnConcurrencyError: boolean;
}) {
  yield put(boundaryTerritoryGroupsUpdateError());

  if (failSilently) {
    return;
  }

  const boundaryGroups: ReadonlyArray<BoundaryGroup> = yield select<ReadonlyArray<BoundaryGroup>>(boundaryGroupsSelector);

  const isBoundaryTerritoryGroupRemoved: boolean = yield handleTerritoryGroupAlreadyRemoved(error, boundaryTerritoryGroup);
  if (isBoundaryTerritoryGroupRemoved) {
    const isGroupCustom = isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups);
    onSuccess?.();

    yield put(createAppError({
      type: AppErrorType.General,
      title: translate(isGroupCustom ? 'Territory group was already removed' : 'Boundary was already removed'),
      errorTitle: translate(isGroupCustom ? 'Territory group was already removed from the map' : 'Boundary was already removed from the map'),
    }));
  }
  else if (isApiConcurrencyError(error)) {
    const modalId = createUuid();
    const mapId: number | null = yield select<number | null>(mapIdSelector);

    if (mapId === null) {
      return;
    }

    if (refetchOnConcurrencyError) {
      yield put(boundaryTerritoryGroupsFetchRequest(mapId));
      return;
    }

    const onClose = () => {
      appStore.dispatch(closeModal(modalId));
    };

    const isGroupCustom = isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups);

    yield put(openModalWithId(modalId, ModalType.Confirmation, {
      isConfirmButtonDestructive: true,
      title: translate(isGroupCustom ? 'Territories Out Of Sync' : 'Boundaries Out Of Sync'),
      children: translate(isGroupCustom ?
        'Territories have been recently changed by {{userName}}. Please refresh your data in order to be able to make changes.' :
        'Boundaries have been recently changed by {{userName}}. Please refresh your data in order to be able to make changes.',
      undefined, {
        userName: error.response?.reason?.user_name ?? translate('another user'),
      }),
      confirmCaption: translate(isGroupCustom ? 'Refresh Territories' : 'Refresh Boundaries'),
      onCancel: () => {
        onClose();
      },
      onConfirm: () => {
        onSuccess?.();
        appStore.dispatch(boundaryTerritoryGroupsFetchRequest(mapId));
        onClose();
      },
    }));
  }
  else {
    yield put(createAppError({
      type: AppErrorType.General,
      errorTitle: translate('An error occurred while updating the data'),
      title: translate('Boundary Update Error'),
    }));
  }
}

function* removeTerritoryGroup(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const isMapOffline: boolean = yield select<boolean>(isMapInOfflineModeSelector);
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);
  const boundaryTerritoryGroup = boundaryTerritoryGroups.find(g => g.boundaryTerritoryGroupId === action.payload.boundaryTerritoryGroupId);

  if (!boundaryTerritoryGroup) {
    return;
  }

  if (isMapOffline) {
    yield put(boundaryTerritoryGroupsRemoveSuccess(boundaryTerritoryGroup));
    return;
  }

  if (!clientId) {
    return;
  }

  try {
    yield call(removeBoundaryTerritoryGroup, clientId, action.payload.boundaryTerritoryGroupId);

    yield put(boundaryTerritoryGroupsRemoveSuccess(boundaryTerritoryGroup));
  }
  catch (e) {
    yield onRemoveTerritoryGroupError(e, boundaryTerritoryGroup);
  }
}

function* handleTerritoryGroupAlreadyRemoved(e: any, removedBoundaryTerritoryGroup: BoundaryTerritoryGroup) {
  // potentially the boundary territory group is already removed - refetch BTGs and compare with removed BTG
  if (isNotFoundApiError(e)) {
    const clientId: number | null = yield select<number | null>(clientIdSelector);
    const mapId: number | null = yield select<number | null>(mapIdSelector);

    if (clientId === null || mapId === null) {
      return;
    }

    yield fetchBoundaryTerritoryGroups(clientId, mapId);
    const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);

    const isGroupStillExisting = !!boundaryTerritoryGroups.find(
      group => group.boundaryTerritoryGroupId === removedBoundaryTerritoryGroup.boundaryTerritoryGroupId
    );
    if (!isGroupStillExisting) {
      return true;
    }
  }

  return false;
}

function* onRemoveTerritoryGroupError(e: any, removedBoundaryTerritoryGroup: BoundaryTerritoryGroup) {
  const boundaryGroups: ReadonlyArray<BoundaryGroup> = yield select<ReadonlyArray<BoundaryGroup>>(
    state => state.boundaries.groups.groups
  );
  const isGroupCustom = isBoundaryTerritoryGroupCustom(removedBoundaryTerritoryGroup, boundaryGroups);

  const isBoundaryTerritoryGroupRemoved: boolean = yield handleTerritoryGroupAlreadyRemoved(e, removedBoundaryTerritoryGroup);
  if (isBoundaryTerritoryGroupRemoved) {
    yield put(boundaryTerritoryGroupsRemoveSuccess(removedBoundaryTerritoryGroup));
    return;
  }

  yield put(boundaryTerritoryGroupsRemoveError());
  yield put(createAppError({
    type: AppErrorType.General,
    title: translate(isGroupCustom ? 'Territory Group Remove Error' : 'Boundary Remove Error'),
    errorTitle: translate('An error occurred while removing the data'),
  }));
}

function* removeAllTerritoryGroups() {
  const territoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    state => state.map.boundaryTerritoryGroups.groups
  );

  for (const territoryGroup of territoryGroups) {
    yield put(boundaryTerritoryGroupsRemoveRequest(territoryGroup.boundaryTerritoryGroupId));
  }
}

// Items of custom boundary groups are loaded all at once so the actual update of B-T-G can only occur only once per boundary group
function* saveBoundaryItemsStyleIntoBoundaryTerritoryGroupSettings(boundaryGroupId: number) {
  const boundaryStateItems: BoundaryStateItems = yield select<BoundaryStateItems>(mapBoundariesSelector);
  const boundaryItems = Array.from(boundaryStateItems.get(boundaryGroupId)?.values() ?? []);

  const boundaryGroups: ReadonlyArray<BoundaryGroup> = yield select<ReadonlyArray<BoundaryGroup>>(
    state => state.boundaries.groups.groups
  );
  const boundaryGroup = boundaryGroups.find(group => group.id === boundaryGroupId);

  if (!boundaryGroup || !boundaryItems || !isCustomGroup(boundaryGroup)) {
    return;
  }

  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    boundaryTerritoryGroupsSelector
  );

  for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
    if (boundaryTerritoryGroup.boundaryGroupId !== boundaryGroupId) {
      continue;
    }

    let isUpdateRequired = false;

    const boundaryFillType = boundaryTerritoryGroup.settings.boundaryFillType ?? BoundaryFill.DefaultFill;
    if (boundaryFillType === BoundaryFill.NoTerritoryFill) {
      continue;
    }

    const newBoundaryStyle = new Map(boundaryTerritoryGroup.settings.boundaryStyle);
    for (const [index, item] of boundaryItems.entries()) {
      if (boundaryTerritoryGroup.settings.boundaryStyle.has(item.id)) {
        continue;
      }

      if (boundaryTerritoryGroup.settings.boundaryFillType === BoundaryFill.DefaultFill && item.settings.style) {
        newBoundaryStyle.set(item.id, item.settings.style);
        isUpdateRequired = true;
      }

      if (boundaryTerritoryGroup.settings.boundaryFillType === BoundaryFill.RandomizedFill) {
        const groupColor = PRESET_COLORS_PRIMARY[index % PRESET_COLORS_PRIMARY.length] ?? PRESET_COLORS_PRIMARY[0];
        newBoundaryStyle.set(item.id, {
          color: guaranteeHash(groupColor),
          opacity: boundaryFillDefaultOpacity * 100,
        });
        isUpdateRequired = true;
      }
    }

    if (!isUpdateRequired) {
      continue;
    }

    yield put(boundaryTerritoryGroupsUpdateRequest({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryStyle: newBoundaryStyle,
      },
    }, {
      fetchBoundaryDetails: false,

      // Reload BTG if it was updated in the meantime.
      // Probably another browser tab updated the initial color first.
      refetchOnConcurrencyError: true,
    }));
  }
}

// It's nice to have, but not necessary. This will remove styles from B-T-Gs on current map, other maps are unnafected.
// If the function causes problems, it can be deleted. Outdated styles do not cause any problems.
function* removeOutdatedBoundaryStyleAssignments(action: PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS>) {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    boundaryTerritoryGroupsSelector
  );

  for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
    if (!boundaryTerritoryGroup.settings.boundaryStyle.has(action.payload.boundaryId)) {
      continue;
    }

    // This consistency update and the whole saga is optional.
    // We don't want to strees user when update fails due to concurency (etag) or other reasons.
    const failSilently = true;

    yield put(boundaryTerritoryGroupsUpdateRequest({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryStyle: copy.andRemove(boundaryTerritoryGroup.settings.boundaryStyle, [action.payload.boundaryId]),
      },
    }, { failSilently }));
  }
}

function* removedBoundaryTerritoryGroupsForRemovedBoundaryGroups(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUPS_DELETE_SUCCESS>) {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    boundaryTerritoryGroupsSelector
  );

  for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
    if (!action.payload.groupIds.has(boundaryTerritoryGroup.boundaryGroupId)) {
      continue;
    }

    yield put(boundaryTerritoryGroupsRemoveSuccess(boundaryTerritoryGroup));
  }
}

function* updateBoundaryTerritoryGroupSettingsField(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS>
) {
  const { boundaryTerritoryGroupId, patcher } = action.payload;
  const boundaryTerritoryGroups: BoundaryTerritoryGroup[] = yield select(boundaryTerritoryGroupsSelector);
  const btgToUpdate = boundaryTerritoryGroups.find(btg => btg.boundaryTerritoryGroupId === boundaryTerritoryGroupId);
  if (!btgToUpdate) {
    return;
  }

  const newSettings = patcher({ ...btgToUpdate.settings });

  yield put(boundaryTerritoryGroupsUpdateRequest({
    ...btgToUpdate,
    settings: newSettings,
  }));
}

function* checkForRemovedBtg() {
  const activeBoundary: ExtendedBoundaryIdentifier | null = yield select(activeBoundarySelector);
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select(boundaryTerritoryGroupsSelector);

  if (activeBoundary?.boundaryTerritoryGroupId && boundaryTerritoryGroups.every(btg => btg.boundaryTerritoryGroupId !== activeBoundary?.boundaryTerritoryGroupId)) {
    yield put(activeMapElementsClearState());
  }

  const locationFilters: BoundaryLocationsFiltersState = yield select(boundaryLocationsFiltersStateSelector);
  if (subtract(Object.keys(locationFilters), boundaryTerritoryGroups.map(btg => btg.boundaryGroupId.toString())).length !== 0) {
    yield put(clearBoundaryLocationsFilter());
  }
}
