import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type Theme } from '../_shared/themes/theme.model';
import { type ThemeProps } from '../_shared/types/themeProps';

const itemWidth = 61;

export type SingleItemProps = Readonly<{
  icon: IconProp;
  title: string;
  onClick: () => void;
  isDisabled: boolean;
  fontSize?: string;
  testId?: string;
}>;

export type MobileTopUnderbarButtonProps = Readonly<SingleItemProps & {
  isHighlighted?: boolean;
  className?: string;
}>;

const itemStyles = ({ theme, isDisabled, fontSize }: ThemeProps<{ isDisabled: boolean; fontSize?: string }>) => css({
  display: 'flex',
  backgroundColor: theme.backgroundColors.secondary,
  cursor: isDisabled ? 'default' : 'pointer',
  fontSize,
  height: '100%',

  ':hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const menuTileStyle = (props: ThemeProps<{isHighlighted: boolean}>) => {
  let textColor = props.theme.textColors.primary;
  if (props.isHighlighted) {
    textColor = props.theme.iconColors.accented;
  }

  return css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: props.isHighlighted ? props.theme.iconColors.accentedBackground : 'inherit',
    boxSizing: 'border-box',
    color: textColor,
    padding: '0 4px',
    width: itemWidth,
  });
};

const iconStyles = (theme: Theme, isFocused: boolean) => css({
  fontSize: 20,
  fontWeight: 900,
  minWidth: 18,
  color: isFocused ? theme.iconColors.accented : 'inherit',
});

const titleStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: 10,
  marginTop: 2,
  textAlign: 'center',
});

export const MobileTopUnderbarButton: FC<MobileTopUnderbarButtonProps> = props => {
  const theme = useTheme();

  const { onClick } = props;

  return (
    <div
      className={props.className}
      css={itemStyles({ theme, isDisabled: props.isDisabled, fontSize: props.fontSize })}
    >

      <div
        css={menuTileStyle({ theme, isHighlighted: !!props.isHighlighted })}
        onClick={onClick}
        data-testid={props.testId}
      >
        <FontAwesomeIcon
          css={iconStyles(theme, !!props.isHighlighted)}
          icon={props.icon}
        />
        <div css={titleStyle}> {props.title} </div>
      </div>
    </div>
  );
};
