import React, {
  type ReactElement, type ReactNode,
} from 'react';
import ReactSplit from 'react-split';
import { useHookWithRefCallback } from '../../utils/hooks/useHookWithRefCallback';

type SplitDirection = 'vertical' | 'horizontal';

type SplitProps = Readonly<{
  gutter?: ReactElement<any>;
  className?: string;
  sizes: number[];
  minSize?: number[];
  direction?: SplitDirection;
  snapOffset?: number;
  gutterSize?: number;
  children: ReactNode;
  onDragEnd?: (sizes: number[]) => void;
  gutterStyle?: Partial<CSSStyleDeclaration>;
  expandToMin?: boolean;
}>;

export const SplitComponent = ({
  children,
  className,
  direction = 'horizontal',
  expandToMin,
  gutter,
  gutterSize = 20,
  gutterStyle,
  minSize,
  onDragEnd,
  sizes,
  snapOffset = 0,
}: SplitProps) => {
  const [gutterRef, setGutterRef] = useHookWithRefCallback<HTMLElement>();

  const getGutterStyles = (dimension: 'width' | 'height', size: number): Partial<CSSStyleDeclaration> => ({
    [dimension]: `${size}px`,
    ...gutterStyle,
  });

  return (
    <>
      <ReactSplit
        key={gutterRef ? 'custom-gutter' : 'default-gutter'}
        className={className}
        expandToMin={expandToMin}
        gutterAlign="end"
        gutterStyle={gutterStyle ? getGutterStyles : undefined}
        gutter={gutterRef ? () => gutterRef : undefined}
        sizes={sizes}
        minSize={minSize}
        direction={direction}
        snapOffset={snapOffset}
        gutterSize={gutterSize}
        onDragEnd={onDragEnd}
      >
        {children}
      </ReactSplit>

      {gutter &&
        React.cloneElement(gutter, { ref: setGutterRef })
      }
    </>
  );
};
