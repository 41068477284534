import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkRequest,
} from './spreadsheet.repository';

type UseSpreadsheetRowCountParams = {
  mapId: number;
  spreadsheetId: number;
};

export const useSpreadsheetRowCount = () => {
  const clientId = useClientIdSelector();

  const getSpreadsheetRowCount = useCallback(({ mapId, spreadsheetId }: UseSpreadsheetRowCountParams) => {
    if (!clientId) {
      return Promise.resolve(0);
    }

    const request: SpreadsheetDataBulkRequest = {
      params: [{
        exclude_row_data: true,
        exclude_basic_data: true,
        map_id: mapId,
        spreadsheet_id: spreadsheetId,
      }],
    };

    return getSpreadsheetBulkData(clientId, request)
      .then(response => response.data.reduce((agg, data) => agg + (data.result.filtered_rows?.length ?? 0), 0));
  }, [clientId]);

  const { data: rowCount, isLoading, error, invokeAjax } = useAjaxCall(getSpreadsheetRowCount);

  return {
    rowCount,
    isLoading,
    error,
    getSpreadsheetRowCount: invokeAjax,
  };
};
