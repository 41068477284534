import { type Theme } from '~/_shared/themes/theme.model';
import {
  RoundButtonStyle,
  RoundButtonStyles,
} from './roundButton.styles';

export const getTextColorForButtonStyle = (btnStyle: RoundButtonStyle, theme: Theme) => {
  const btnStyles = RoundButtonStyles(theme)[btnStyle];

  switch (btnStyle) {
    case RoundButtonStyle.DangerInverted:
    case RoundButtonStyle.Danger:
      return { color: theme.iconColors.backgroundDangerInverted, colorHover: theme.iconColors.borderDangerInvertedHover };
    case RoundButtonStyle.Confirm:
    case RoundButtonStyle.ConfirmInverted:
      return { color: theme.iconColors.backgroundSuccessInverted, colorHover: theme.iconColors.borderSuccessInvertedHover };
    case RoundButtonStyle.Primary:
    case RoundButtonStyle.PrimaryInverted:
      return { color: theme.textColors.tertiary, colorHover: theme.textColors.primary };
    case RoundButtonStyle.Secondary:
      return { color: btnStyles.textColor, colorHover: btnStyles.borderHoverColor };
    case RoundButtonStyle.SecondaryInverted:
      return { color: theme.textColors.secondary, colorHover: theme.textColors.primary };
    case RoundButtonStyle.Tertiary:
      return { color: btnStyles.textColor, colorHover: theme.textColors.tertiary };
    case RoundButtonStyle.Quaternary:
      return { color: btnStyles.backgroundColor, colorHover: btnStyles.borderHoverColor };
    case RoundButtonStyle.Restricted:
      return { color: btnStyles.backgroundColor, colorHover: btnStyles.borderHoverColor };
    case RoundButtonStyle.RestrictedInverted:
      return { color: btnStyles.backgroundColor, colorHover: btnStyles.borderHoverColor };
    case RoundButtonStyle.Disabled:
    default:
      return { color: btnStyles.textColor, colorHover: btnStyles.textColor };
  }
};
