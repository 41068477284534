import { useMemo } from 'react';
import { convertPolygonToGeometry } from '~/_shared/types/polygon/polygon.utils';
import { useLatLngSpreadsheetData } from '../../../map/map/useSpreadsheetData.hook';
import { createProximityFilterArgumentsFromProximity } from '../../../proximity/proximity.helpers';
import { useProximities } from '../../../proximity/useProximities';
import { type ActiveProximity } from '../../../sidebar/sidebarApps/rightSidebar/rightSidebarConfiguration';
import { type PolygonFilterRequest } from '../../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { createRadiusFilterItemRequest } from '../../../spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import { type RadiusFilterRequest } from '../../../spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import { useProximityMetricsSelector } from '../../../store/mapSettings/proximity/mapSettingsProximity.selectors';
import { isDriveTimePolygon } from '../../types/proximity/proximity.types';
import { type MetricModel } from './metrics.factory';
import { useMetricsData } from './useMetricsData';

export const useProximityMetricsData = (activeMetric: ActiveProximity) => {
  const selectedMetrics: MetricModel[] = useProximityMetricsSelector();
  const { proximities: proximitiesData } = useProximities();
  const latLngLookup = useLatLngSpreadsheetData();

  const activeProximity = proximitiesData.find(proximity => proximity.id === activeMetric.proximityId);

  const radiusFilterRequest: RadiusFilterRequest | null = useMemo(() => {
    if (!activeProximity) {
      return null;
    }

    const { circles } = createProximityFilterArgumentsFromProximity(
      activeProximity,
      latLngLookup,
      activeMetric.spreadsheetRowId ?? null,
    );

    if (circles.length === 0) {
      return null;
    }

    return {
      type: 'or',
      radiuses: circles.map(request => createRadiusFilterItemRequest(request)),
    };
  }, [activeProximity, activeMetric.spreadsheetRowId, latLngLookup]);

  const polygonFilterRequest: PolygonFilterRequest | null = useMemo(() => {
    if (!activeProximity) {
      return null;
    }

    if (isDriveTimePolygon(activeProximity) && activeProximity.data.paths) {
      return {
        type: 'or',
        polygons: activeProximity.data.paths.map(polygon => ({ path: convertPolygonToGeometry(polygon) })),
      };
    }

    return null;
  }, [activeProximity]);

  return useMetricsData({
    selectedMetrics,
    radiusFilterRequest: radiusFilterRequest ?? undefined,
    polygonFilterRequest: polygonFilterRequest ?? undefined,
  });
};
