import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { type TopUnderbarItem } from './topUnderbarItem.types';

const closeButtonStyle = ({ theme, color, itemPadding }: ThemeProps<{
  color?: string;
  itemPadding?: number | string;
}>) => css({
  color: color ?? theme.alertColors.warningPrimary,
  fontSize: 16,
  paddingRight: !itemPadding ? 5 : 0,
  paddingTop: !itemPadding ? 5 : 0,

  '&:hover': {
    color: theme.textColors.dangerOnHover,
  },
});

const itemStyle = ({ theme, padding }: ThemeProps<{ padding?: number}>) => css({
  position: 'relative',
  background: theme.backgroundColors.primary,
  padding,
});

const paneStyle = ({ color, backgroundColor, borderColor, padding, theme }: {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  padding?: number | string;
  theme: Theme;
}) => css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  backgroundColor,
  borderBottom: `1px solid ${borderColor ?? color ?? theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color,
  padding: padding ?? 6,
  width: '100%',
});

const contentStyle = ({ theme, color }: ThemeProps<{color?: string}>) => css({
  display: 'flex',
  alignItems: 'center',
  color: color || theme.textColors.primary,
  width: '100%',
});

const iconStyle = ({ color, iconColor }: {color?: string; iconColor?: string}) => css({
  color: iconColor ?? color,
  paddingLeft: 10,
  paddingRight: 20,
  fontSize: 26,
});

type TopUnderbarItemComponentProps = {
  item: TopUnderbarItem;
  isMobileVersion?: boolean;
};

export const TopUnderbarItemComponent: FC<TopUnderbarItemComponentProps> = ({ item, isMobileVersion }) => {
  const theme = useTheme();

  return (
    <div
      key={item.key}
      css={itemStyle({ theme, padding: item.marging })}
    >
      <div
        css={paneStyle({
          ...item.colors,
          padding: item.padding,
          theme,
        })}
      >
        <div
          css={contentStyle({
            theme,
            ...item.colors,
          })}
        >
          {!isMobileVersion && item.icon && (
            <FontAwesomeIcon
              css={iconStyle({ ...item.colors })}
              icon={item.icon}
            />
          )}

          {item.children}
        </div>
        {item.onClose && (
          <FontAwesomeIcon
            css={closeButtonStyle({ theme, ...item.colors, itemPadding: item.padding })}
            icon={faXmark}
            onClick={item.onClose}
          />
        )}
      </div>
    </div>
  );
};
