import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_PRIMARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingArrowSettingsState } from './drawingArrowSettings.state';

export const drawingArrowSettingsInitialState: DrawingArrowSettingsState = {
  scalesWithMapZoom: true,
  triangleSize: 10,
  fillOpacity: 100,
  strokeOpacity: 100,
  strokeWeight: 2,
  lineWeight: 7,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_PRIMARY,
};

export const drawingArrowSettingsReducer = (
  state = drawingArrowSettingsInitialState, action: DrawingSettingsAction
): DrawingArrowSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Arrow) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.arrow,
      };
    }

    default:
      return state;
  }
};
