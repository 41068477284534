import { css } from '@emotion/react';
import { type FC } from 'react';
import { blinkLoaderStyle } from '~/_shared/components/loader/blinkLoader.styles';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

type BoundaryListingItemSkeletonProps = Readonly<{
  animationDurationMs: number;
  animationDelayMs: number;
}>;

const wrapperStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  background: theme.backgroundColors.secondary,
  borderTop: `1px solid ${theme.borderColors.primary}`,
  justifyContent: 'space-between',
  padding: '5px 14px 3px',
  '&:first-of-type': {
    border: 'none',
  },
});

const headingPrimaryBarStyle = ({ theme }: ThemeProps) => css({
  width: 280,
  height: 20,
  background: theme.backgroundColors.primary,
  borderRadius: 4,
});

const ctaBarStyle = ({ theme }: ThemeProps) => css({
  width: 80,
  height: 20,
  background: theme.backgroundColors.primary,
  borderRadius: 4,
});

export const BoundaryListingItemSkeletonComponent: FC<BoundaryListingItemSkeletonProps> = (props) => {
  const theme = useTheme();

  return (
    <div css={wrapperStyle({ theme })}>
      <div>
        <div css={[headingPrimaryBarStyle({ theme }), blinkLoaderStyle(props.animationDurationMs, props.animationDelayMs)]} />
      </div>

      <div css={[ctaBarStyle({ theme }), blinkLoaderStyle(props.animationDurationMs, props.animationDelayMs)]} />
    </div>
  );
};
