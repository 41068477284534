import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Layer]: {
  views: {
    default: {
      canvasHeight: 392,
      canvasWidth: 392,
      iconWidth: 197,
      iconHeight: 345,
      offsetTop: 20,
    },
  },
  colors: {
    layer1: '#FFCB2B',
    layer2: '#F5820C',
    layer3: '#FFA713',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 60] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/41610-layer" */
      './41610-layer.json'
    ),
} };

export const { Layer } = config;
