import { DndType } from '~/_shared/constants/dndType.enum';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ColumnRole } from '~/_shared/types/columnRole.enum';
import { type BubbleEditorItem } from '~/customizeLocationPanel/locationData/locationData.types';
import { getItemDnDType } from './locationData/locationDataItem/draggableItem.helpers';
import { type ColumnStyleSettingsData } from './locationData/panelSettings.types';

export const isItemContactItem = (itemType?: ColumnRole | null) =>
  itemType && getItemDnDType(itemType) === DndType.LocalizationContactItem;

export const defaultLocationDataStyle = (theme: Theme, itemType?: ColumnRole | null): ColumnStyleSettingsData => {
  const isContactItem = isItemContactItem(itemType);
  return {
    labelFontSize: '15',
    labelFontColor: theme.textColors.info,
    valueFontColor: isContactItem ? theme.textColors.link : theme.textColors.primary,
    valueFontSize: '13',
    showLabel: true,
    verticalGap: '0',
  };
};

export const getItemStyles = (theme: Theme, item: BubbleEditorItem, bulkStyles: ColumnStyleSettingsData): ColumnStyleSettingsData => {
  if (item.itemData.style) {
    return item.itemData.style;
  }

  const isContactItem = isItemContactItem(item.itemType);

  if (isContactItem) {
    return defaultLocationDataStyle(theme, item.itemType);
  }

  return bulkStyles;
};

export const LOCATION_PANEL_DATA_CLASSNAME = 'LOCATION_PANEL_DATA';

export const EMPTY_LOCATION_DESCRIPTION = {
  header: '',
  description: [''],
  semiHeader: '',
};
