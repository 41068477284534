import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  MAP_SETTINGS_FETCH_DATA_ERROR,
  MAP_SETTINGS_FETCH_DATA_REQUEST,
  MAP_SETTINGS_FETCH_DATA_SUCCESS,
} from '../data/mapSettingsData.actionTypes';
import { mapSettingLoadingSet } from './mapSettingsLoading.actionCreators';

export function* mapSettingsLoadingSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_REQUEST, setIsLoadingTrue);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_ERROR, setIsLoadingFalse);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, setIsLoadingFalse);
}

function* setIsLoadingTrue() {
  yield put(mapSettingLoadingSet(true));
}

function* setIsLoadingFalse() {
  yield put(mapSettingLoadingSet(false));
}
