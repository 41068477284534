import { useMemo } from 'react';
import { useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector } from '../mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { useBoundaryTerritoryGroupsSelector } from './boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from './boundaryTerritoryGroups.state';

export const usePrimaryBoundaryTerritoryGroup = (): BoundaryTerritoryGroup | null => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const primaryBoundaryGroupId = useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector();

  return useMemo(() => {
    return boundaryTerritoryGroups.find(territory => territory.boundaryGroupId === primaryBoundaryGroupId) ?? null;
  }, [boundaryTerritoryGroups, primaryBoundaryGroupId]);
};
