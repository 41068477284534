import { type SpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type AppState } from '../app.store';

const matchupDataSelector = (state: AppState) => state.spreadsheet.matchupData;
export const useMatchupDataSelector = () => useSelector(matchupDataSelector);

export const useSpreadsheetMathupDataSelector = (spreadsheetId: number | null): SpreadsheetMatchupData | null => {
  const matchupData = useMatchupDataSelector();
  return spreadsheetId !== null ? matchupData[spreadsheetId]?.data ?? null : null;
};
