import { css } from '@emotion/react';
import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { usePointerClickEvent } from '~/_shared/baseComponents/utils/usePointerClickEvent';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { convertNumberToLetters } from '~/_shared/utils/text/text.helpers';
import { checkMapCanBeShared } from '~/map/listing/mapListing.helpers';
import { type ShareMapProps } from '~/map/share/shareMap.container';
import { getMapUrl } from '../../../map.helpers';
import { type MapListingItem } from '../../item/mapListingItem';
import { getMapRowIcon } from '../../tableData/mapRow/mapRow.helpers';
import { MobileMapRowToggleComponent } from './mobileMapRowToggle.component';

const tableRowStyle = ({ theme }: ThemeProps) => css({
  height: 64,
  background: theme.backgroundColors.quinary,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const tableDataStyle = css({
  padding: '0 10px',
});

const snapshotTableDataStyle = css({
  fontSize: 16,
  fontWeight: 700,
  paddingLeft: 40,
});

const linkStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: 16,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
});

const linkIconStyle = css({
  marginRight: 15,
});

const snapshotsCountStyle = ({ theme }: ThemeProps) => css({
  background: theme.buttonColors.primaryBackground,
  borderRadius: 12,
  color: theme.buttonColors.primaryText,
  display: 'block',
  fontSize: 14,
  height: 24,
  lineHeight: '24px',
  marginRight: 40,
  textAlign: 'center',
  width: 40,
});

const tooltipStyle = css({
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const shareButtonStyle = ({ theme }: ThemeProps) => css({
  border: 'none',
  background: 'none',
  fontSize: 30,
  padding: 5,
  color: theme.buttonColors.quaternaryText,
  display: 'inline-block',
});

const toggleColumnStyle = css({
  width: 40,
  padding: '0 10px',
});

const shareColumnStyle = css({
  width: 45,
  textAlign: 'right',
  paddingRight: 10,
});

const counterStyle = css({
  display: 'inline-block',
  marginRight: 8,
  minWidth: 22,
});

type MobileMapRowProps = Readonly<{
  areSnapshotsExpanded: boolean;
  index: number;
  item: MapListingItem;
  parentMapItem?: MapListingItem;

  onMapShareSettingsClick: (props: ShareMapProps) => void;
  onShowSnapshotsToggle: (mapId: number) => void;
  setRef?: (ref: HTMLTableRowElement) => void;
}>;

export const MobileMapRowComponent: FC<MobileMapRowProps> = (props) => {
  const theme = useTheme();
  const isSnapshot = props.parentMapItem !== undefined;

  const { onClick, onPointerDown, onPointerUp } = usePointerClickEvent(() => {
    props.onMapShareSettingsClick({
      mapId: props.item.id,
      mapName: props.item.name,
      isSnapshot,
      parentMapPrivacy: props.parentMapItem?.privacy || null,
      mapSource: props.item.source,
    });
  });

  return (
    <tr
      css={tableRowStyle({ theme })}
      ref={props?.setRef ? props.setRef : null}
    >
      <td css={tableDataStyle}>
        <div css={isSnapshot ? snapshotTableDataStyle : undefined}>
          <LinkComponent
            path={getMapUrl(props.item.id)}
            css={linkStyle({ theme })}
            tooltip={props.item.name}
            tooltipStyle={tooltipStyle}
            data-testid={`navigate-map-${props.item.name}`}
          >
            <span css={counterStyle}>{isSnapshot ? convertNumberToLetters(props.index + 1) : props.index + 1}.</span>
            <FontAwesomeIcon
              css={linkIconStyle}
              icon={getMapRowIcon({ isSnapshot, item: props.item })}
            />
            {props.item.name}
          </LinkComponent>
        </div>
      </td>

      <td css={toggleColumnStyle}>
        {!isSnapshot && (
          <MobileMapRowToggleComponent
            areSnapshotsExpanded={props.areSnapshotsExpanded}
            item={props.item}
            onClick={props.onShowSnapshotsToggle}
          >
            <span css={snapshotsCountStyle({ theme })}>
              {props.item.snapshots.length}
            </span>
          </MobileMapRowToggleComponent>
        )}
      </td>

      <td css={shareColumnStyle}>
        {checkMapCanBeShared(props.item) && (
          <button
            css={shareButtonStyle({ theme })}
            onClick={onClick}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
          >
            <FontAwesomeIcon icon={faShare} />
          </button>
        )}
      </td>
    </tr>
  );
};
