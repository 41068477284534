import { useState } from 'react';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import {
  getAllFilterTreeRequestsFromMapSettings,
  mergeFilterTrees,
} from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import { type PolygonFilterRequest } from '../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBulkFetchExtra,
  type SpreadsheetDataBulkRequest,
} from '../../spreadsheet/spreadsheet.repository';
import { useSpreadsheetTableSearchFilterTree } from '../table/useSpreadsheetTableSearchFilterTree';
import { type ExportDataFileType } from './exportDataFileType.enum';

export enum FilterTreeType {
  None,
  MapSettings,
  TableSearch,
}

export const useExportSpreadsheetData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const mapSettingsFilterTree = useFilterTreeMapSettingsParams();
  const tableSearchFilterTree = useSpreadsheetTableSearchFilterTree();
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const columns = useSpreadsheetColumns();
  const isMountedRef = useIsComponentMountedRef();

  const getExportedSpreadsheetData = (params: {
    virtualSpreadsheetId: number;
    extension: ExportDataFileType;
    polygonFilter: PolygonFilterRequest | undefined;
    exportAsText: boolean;
    filterTreeType?: FilterTreeType;
  }) => {
    const { virtualSpreadsheetId, extension, polygonFilter, exportAsText, filterTreeType = FilterTreeType.MapSettings } = params;

    if (!mapId || !clientId || columns.length === 0) {
      return Promise.reject();
    }

    setIsLoading(true);
    setIsError(false);

    const { filterTree, searchFilterTree } = getAllFilterTreeRequestsFromMapSettings(mapSettingsFilterTree, [virtualSpreadsheetId]);

    const filterTreeRequest = filterTree[virtualSpreadsheetId]?.filterTree;
    const searchFilterTreeRequest = searchFilterTree[virtualSpreadsheetId]?.filterTree;

    const mergedFilterTrees = mergeFilterTrees([filterTreeRequest, searchFilterTreeRequest], 'and');

    const columnsToFetch = columns.reduce<Record<string, SpreadsheetDataBulkFetchExtra>>(
      (acc, column) => {
        acc[column.id] = {};

        return acc;
      }, {}
    );

    const request: SpreadsheetDataBulkRequest = {
      export: true,
      as_text: exportAsText ? true : undefined,
      extension,
      params: [{
        spreadsheet_id: virtualSpreadsheetId,
        map_id: mapId,
        columns_to_fetch: {
          [DataType.TEXT]: columnsToFetch,
        },
        filter: {
          only_get_filtered: true,
          filter_tree: filterTreeType === FilterTreeType.MapSettings
            ? mergedFilterTrees ?? undefined
            : filterTreeType === FilterTreeType.TableSearch
              ? tableSearchFilterTree
              : undefined,
          polygon_filter: polygonFilter,
        },
      }],
    };

    return getSpreadsheetBulkData(clientId, request)
      .catch(error => {
        if (isMountedRef.current) {
          setIsError(true);
        }

        throw error;
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    isError,
    getExportedSpreadsheetData,
  };
};
