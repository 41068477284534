import { useCallback } from 'react';
import { useAjaxCall } from '../../../_shared/utils/hooks/useAjaxCall';
import { registerUser } from '../../../store/userData/repository/userData.repository';

export type UserRegisterData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  companyName?: string;
};

export const useUserRegister = () => {
  const userRegister = useCallback((data: UserRegisterData) => {
    return registerUser(data);
  }, []);

  const { isLoading, invokeAjax } = useAjaxCall(userRegister);

  return {
    isLoading,
    registerUser: invokeAjax,
  };
};
