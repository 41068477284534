import { css } from '@emotion/react';
import {
  type FC, useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import { useTranslation } from '~/_shared/utils/hooks';
import { authorizationPagesLinkStyle } from '../authorizationPages.styles';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import { AuthorizationSubmitButtonComponent } from '../submitButton/authorizationSubmitButton.component';

type VerifyEmailPageComponentProps = Readonly<{
  isLoading: boolean;
  isEmailSending: boolean;
  errorMessage: string;
  startVerification: (successCallback: () => void, failureCallback: () => void) => void;
  onResend: (successCallback: () => void, failureCallback: () => void) => void;
}>;

const loaderStyle = css({
  margin: '0 auto',
});

const verifyTextStyle = css({
  textAlign: 'center',
});

const linksWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const alertStyle = css({
  marginTop: 10,
});

const submitButtonStyle = css({
  marginTop: 15,
});

export const VerifyEmailPageComponent: FC<VerifyEmailPageComponentProps> = (props) => {
  const { isLoading, isEmailSending, errorMessage, startVerification, onResend } = props;
  const [isVerificationSuccessful, setIsVerificationSuccessful] = useState(false);
  const [isSendEmailSuccess, setIsSendEmailSuccess] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const goToLoginPage = useCallback(
    () => navigate(LOGIN_ROUTE),
    [navigate],
  );

  const handleResend = useCallback(() => {
    onResend(
      () => setIsSendEmailSuccess(true),
      () => setIsSendEmailSuccess(false)
    );
  }, [onResend]);

  useEffect(() => {
    startVerification(
      () => setIsVerificationSuccessful(true),
      () => {
        setIsVerificationSuccessful(false);
        setIsSendEmailSuccess(false);
      });
  }, [startVerification]);

  return (
    <AuthorizationPageWrapperComponent>
      {isVerificationSuccessful ? (
        <div>
          <AlertComponent
            css={alertStyle}
            type="success"
          >
            {t('Your email address was verified successfully')}
          </AlertComponent>

          <AuthorizationSubmitButtonComponent
            css={submitButtonStyle}
            isLoading={false}
            isDisabled={false}
            onClick={goToLoginPage}
          >
            {t('Continue to Login')}
          </AuthorizationSubmitButtonComponent>
        </div>
      ) : (
        <div>
          {isSendEmailSuccess && (
            <AlertComponent
              css={alertStyle}
              type="success"
            >
              {t('The verification link was successfully sent to your email address. Please click on the link in the email to continue.')}
            </AlertComponent>
          )}

          {!isSendEmailSuccess && errorMessage && (
            <AlertComponent
              css={alertStyle}
              type="danger"
            >
              {errorMessage}
            </AlertComponent>
          )}

          {isLoading && (
            <div>
              <LoaderComponent
                css={loaderStyle}
                size={40}
              />
              <p css={verifyTextStyle}>{t('Verifying the provided email address is in progress')}</p>
            </div>
          )}

          {!isLoading && (
            <AuthorizationSubmitButtonComponent
              css={submitButtonStyle}
              isLoading={isEmailSending}
              isDisabled={isEmailSending}
              onClick={handleResend}
            >
              {t('Resend Email')}
            </AuthorizationSubmitButtonComponent>
          )}

          {!isLoading && (
            <div css={linksWrapperStyle}>
              <LinkComponent
                css={authorizationPagesLinkStyle}
                path={LOGIN_ROUTE}
              >
                {t('Back to Login')}
              </LinkComponent>
            </div>
          )}
        </div>
      )}
    </AuthorizationPageWrapperComponent>
  );
};
