import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  forwardRef, useCallback,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

const iconStyle = (props: ThemeProps<{
  baseColor?: string;
  hoverColor?: string;
  size?: number;
}>) => css({
  color: props.baseColor,
  fontSize: props.size ?? 16,

  '&:hover': {
    color: props.hoverColor,
  },
});

type HoverableIconProps = Readonly<{
  baseColor?: string;
  className?: string;
  disabledColor?: string;
  hoverColor: string;
  icon: IconProp;
  isDisabled?: boolean;
  onClick?: () => void;
  size?: number;
  testid?: string;
}>;

export const HoverableIconComponent: React.FC<HoverableIconProps> = forwardRef<SVGSVGElement, HoverableIconProps>((props, ref) => {
  const theme = useTheme();

  const getActiveOrDisabledColor = useCallback((color?: string) => {
    if (props.isDisabled) {
      return props.disabledColor || theme.textColors.secondary;
    }

    return color;
  }, [props.disabledColor, props.isDisabled, theme.textColors.secondary]);

  return (
    <FontAwesomeIcon
      ref={ref}
      className={props.className}
      css={iconStyle({
        baseColor: getActiveOrDisabledColor(props.baseColor),
        size: props.size,
        hoverColor: getActiveOrDisabledColor(props.hoverColor),
        theme,
      })}
      icon={props.icon}
      onClick={props.onClick}
      data-testid={props.testid}
    />
  );
});

HoverableIconComponent.displayName = 'HoverableIcon';
