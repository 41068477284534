import {
  css, type Interpolation,
} from '@emotion/react';
import {
  forwardRef, type ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { convertColorPickerAlignmentBasedOnAvailableSpace } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.helpers';
import {
  type AxisOffsets, type Coordinates,
} from '~/_shared/types/coordinateSystem/coordinateSystem';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { useWindowHeight } from '~/_shared/utils/hooks/useWindowHeight';
import { useWindowWidth } from '~/_shared/utils/hooks/useWindowWidth';
import { FixedStickyContainerComponent } from '../../fixedStickyContainer/fixedStickyContainer.component';
import {
  MAPTIVE_COLOR_PICKER_HEIGHT,
  MAPTIVE_COLOR_PICKER_WIDTH,
  MaptiveColorPickerComponent, type MaptiveColorPickerProps,
} from '../maptiveColorPicker/maptiveColorPicker.component';

const ANCHOR_PICKER_MARGIN = 8;

export enum ColorPickerAlignment {
  BottomCenter = 'BottomCenter',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  Right = 'Right',
  TopCenter = 'TopCenter',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
}
const wrapperStyle = css({
  position: 'relative',
  display: 'inline-flex',
});

const pickerStyle = ({ coordinates }: { coordinates: Coordinates}) => css({
  position: 'absolute',
  left: coordinates.left,
  top: coordinates.top,
  zIndex: 10,
});

const getContentStyles = () => css({
  width: '100%',
});

export type ColorPickerHolderMethods = {
  openColorPicker: () => void;
};

export type ColorPickerHolderProps = Omit<MaptiveColorPickerProps, 'setRef'> & Readonly<{
  alignment: ColorPickerAlignment;
  children: ReactElement;
  className?: string;
  contentStyle?: Interpolation;
  editable?: boolean;
  presetColors?: string[];

  onOpen?: () => void;
}>;

export const ColorPickerHolderComponent = forwardRef<ColorPickerHolderMethods, ColorPickerHolderProps>(({
  editable = true,
  onClose,
  onOpen,
  alignment,
  className,
  contentStyle,
  children,
  isFixed,
  displayNumericalColorsRepresentation,
  selectedColor,
  presetColors,
  displayAlpha,
  onChange,
  ...restProps
}, ref) => {
  const [isShown, setIsShown] = useState(false);
  const [anchorCoord, setAnchorCoord] = useState<DOMRect>({} as DOMRect);
  const [triggerRef, setTriggerRef] = useHookWithRefCallback<HTMLDivElement>();
  const [pickerRef, setPickerRef] = useHookWithRefCallback<HTMLDivElement | undefined>();
  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();

  const handleClose = useCallback(() => {
    setIsShown(false);
    onClose?.();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    if (editable) {
      setIsShown(true);
      onOpen?.();
    }
  }, [editable, onOpen]);

  const handleDOMRef = useCallback((el: HTMLDivElement | null): void => {
    if (el) {
      setTriggerRef(el);
      const coord = el.getBoundingClientRect();
      if (coord.left === anchorCoord.left && coord.top === anchorCoord.top) {
        return;
      }
      else {
        setAnchorCoord(coord);
      }
    }

    // the isShown dependency is manually added here because we need to recalculate the offsets
    // based on the current position at the time of opening
    // (the previous value might be incorrect, e.g. if the picker was hidden in an accordion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorCoord.left, anchorCoord.top, setTriggerRef, isShown]);

  const { pickerWidth, pickerHeight } = useMemo(() => ({
    pickerWidth: pickerRef?.getBoundingClientRect().width || MAPTIVE_COLOR_PICKER_WIDTH,
    pickerHeight: pickerRef?.getBoundingClientRect().height || MAPTIVE_COLOR_PICKER_HEIGHT,
  }), [pickerRef]);

  const getBottomBaseCoordinates = useCallback((): { top: number; yOffset: number } => {
    const top = anchorCoord.height + ANCHOR_PICKER_MARGIN;
    const yOffset = ANCHOR_PICKER_MARGIN;

    return { top, yOffset };
  }, [anchorCoord.height]);

  const getTopBaseCoordinates = useCallback((): { top: number; yOffset: number } => {
    const top = -pickerHeight - ANCHOR_PICKER_MARGIN;
    const yOffset = -pickerHeight - ANCHOR_PICKER_MARGIN - anchorCoord.height;
    return { top, yOffset };
  }, [anchorCoord.height, pickerHeight]);

  const getLeftBaseCoordinates = useCallback((): { left: number; xOffset: number } => ({
    left: 0, xOffset: 0,
  }), []);

  const getRightBaseCoordinates = useCallback((): { left: number; xOffset: number } => {
    const left = -pickerWidth + anchorCoord.width;
    const xOffset = anchorCoord.width - pickerWidth;

    return { left, xOffset };
  }, [anchorCoord.width, pickerWidth]);

  const getRightTopDownwardsBaseCoordinates = useCallback((): { coords: Coordinates; offsets: AxisOffsets } => {
    const offsets: AxisOffsets = {
      xOffset: anchorCoord.width + ANCHOR_PICKER_MARGIN,
      yOffset: -anchorCoord.height,
    };

    const coords = {
      left: offsets.xOffset,
      top: 0,
    };

    return { coords, offsets };
  }, [anchorCoord.height, anchorCoord.width]);

  const getCenterBaseCoordinates = useCallback((): { left: number; xOffset: number } => {
    const xOffset = anchorCoord.width / 2 - pickerWidth / 2;

    return { left: xOffset, xOffset };
  }, [anchorCoord.width, pickerWidth]);

  const getBaseCoordinates = useCallback((alignment: ColorPickerAlignment): { coords: Coordinates; offsets: AxisOffsets } => {
    let horizontalCoords: {left: number; xOffset: number};
    let verticalCoords: {top: number; yOffset: number};

    switch (alignment) {
      case ColorPickerAlignment.BottomCenter: {
        horizontalCoords = getCenterBaseCoordinates();
        verticalCoords = getBottomBaseCoordinates();
        break;
      }
      case ColorPickerAlignment.BottomLeft: {
        horizontalCoords = getLeftBaseCoordinates();
        verticalCoords = getBottomBaseCoordinates();
        break;
      }
      case (ColorPickerAlignment.BottomRight):{
        horizontalCoords = getRightBaseCoordinates();
        verticalCoords = getBottomBaseCoordinates();
        break;
      }
      case ColorPickerAlignment.TopCenter: {
        horizontalCoords = getCenterBaseCoordinates();
        verticalCoords = getTopBaseCoordinates();
        break;
      }
      case ColorPickerAlignment.TopLeft: {
        horizontalCoords = getLeftBaseCoordinates();
        verticalCoords = getTopBaseCoordinates();
        break;
      }
      case (ColorPickerAlignment.TopRight):{
        horizontalCoords = getRightBaseCoordinates();
        verticalCoords = getTopBaseCoordinates();
        break;
      }

      case (ColorPickerAlignment.Right):
      default:
        return getRightTopDownwardsBaseCoordinates();
    }

    return {
      coords: { left: horizontalCoords.left, top: verticalCoords.top },
      offsets: { xOffset: horizontalCoords.xOffset, yOffset: verticalCoords.yOffset },
    };
  }, [getBottomBaseCoordinates, getCenterBaseCoordinates, getLeftBaseCoordinates, getRightBaseCoordinates,
    getRightTopDownwardsBaseCoordinates, getTopBaseCoordinates,
  ]);

  const baseCoords = useMemo(() => {
    const attemptedBaseCoords = getBaseCoordinates(alignment);
    let tooWide = false;
    let tooTall = false;

    if (anchorCoord) {
      const requiredWidth = anchorCoord.left + attemptedBaseCoords.offsets.xOffset + pickerWidth;
      const requiredHeight = anchorCoord.bottom + attemptedBaseCoords.offsets.yOffset + pickerHeight;
      tooWide = windowWidth < requiredWidth;
      tooTall = windowHeight < requiredHeight;
    }

    const pickerAlignment = convertColorPickerAlignmentBasedOnAvailableSpace(alignment, tooTall, tooWide);

    return getBaseCoordinates(pickerAlignment);
    // the isShown dependency is manually added here because we need to recalculate the offsets
    // based on the current position at the time of opening
    // (the previous value might be incorrect, e.g. if the picker was hidden in an accordion)

  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [alignment, getBaseCoordinates, anchorCoord, triggerRef, pickerHeight, pickerWidth, windowWidth, windowHeight,
    isShown]);

  useImperativeHandle(ref, () => ({
    openColorPicker: () => setIsShown(true),
  }));

  return (
    <div
      className={className}
      css={wrapperStyle}
    >
      <div
        css={[getContentStyles, contentStyle]}
        onClick={handleOpen}
        ref={handleDOMRef}
      >
        {children}
      </div>
      {isShown && !isFixed && (
        <div css={pickerStyle({ coordinates: baseCoords.coords })}>
          <MaptiveColorPickerComponent
            {...restProps}
            displayNumericalColorsRepresentation={displayNumericalColorsRepresentation}
            selectedColor={selectedColor}
            presetColors={presetColors}
            displayAlpha={displayAlpha}
            onChange={onChange}
            onClose={handleClose}
            setRef={setPickerRef}
          />
        </div>
      )}
      {isShown && isFixed && (
        <FixedStickyContainerComponent
          isOpen={isShown}
          triggerRef={triggerRef}
          offsets={baseCoords.offsets}
          onClose={handleClose}
        >
          {() => (
            <MaptiveColorPickerComponent
              {...restProps}
              onClose={handleClose}
              setRef={setPickerRef}
              displayNumericalColorsRepresentation={displayNumericalColorsRepresentation}
              selectedColor={selectedColor}
              presetColors={presetColors}
              displayAlpha={displayAlpha}
              onChange={onChange}
            />
          )}
        </FixedStickyContainerComponent>
      )}
    </div>
  );
});
