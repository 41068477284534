import {
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingPolylineSettingsState } from './drawingPolylineSettings.state';

export const drawingPolylineSettingsInitialState: DrawingPolylineSettingsState = {
  strokeOpacity: 100,
  strokeWeight: 2,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY,
};

export const drawingPolylineSettingsReducer = (
  state = drawingPolylineSettingsInitialState, action: DrawingSettingsAction
): DrawingPolylineSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Polyline) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.polyline,
      };
    }

    default:
      return state;
  }
};
