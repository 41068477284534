import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from '~/_shared/utils/debounce';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { type BoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryTerritoryAssignments } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  activeMapElementsSetActiveBoundary,
  activeMapElementsSetActiveBoundaryTerritory,
} from '../../../../store/frontendState/activeMapElements/activeMapElements.actionCreators';
import {
  boundaryHighlightHoverBoundary,
  boundaryHighlightHoverBoundaryTerritory,
  boundaryHighlightStopBoundaryHover,
  boundaryHighlightStopHoverBoundaryTerritory,
} from '../../../../store/frontendState/mapTools/boundary/boundaryHighlight/boundaryHighlight.actionCreators';
import { type MapBoundaryManager } from '../mapBoundary.manager';
import { getBoundaryTerritoryFromAssignments } from './mapBoundary.helpers';

export const useMapBoundaryMouseEvents = (manager: MapBoundaryManager | undefined) => {
  const dispatch = useDispatch();

  const isMapInteractionActive = useIsMapInteractionActive();
  const isMapInteractionActiveRef = useRef(isMapInteractionActive);
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const boundaryTerritoryAssignmentsRef = useRef(boundaryTerritoryAssignments);
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryTerritoryGroupsRef = useRef(boundaryTerritoryGroups);

  useEffect(() => {
    boundaryTerritoryAssignmentsRef.current = boundaryTerritoryAssignments;
    boundaryTerritoryGroupsRef.current = boundaryTerritoryGroups;
    isMapInteractionActiveRef.current = isMapInteractionActive;
  }, [
    boundaryTerritoryAssignments,
    boundaryTerritoryGroups,
    isMapInteractionActive,
  ]);

  const onClick = useCallback((boundaryIdentifier: BoundaryIdentifier) => {
    const boundaryTerritoryGroup = boundaryTerritoryGroupsRef.current
      .find(g => g.boundaryGroupId === boundaryIdentifier.boundaryGroupId);

    if (!boundaryTerritoryGroup) {
      return;
    }

    const extendedIdentifier = {
      ...boundaryIdentifier,
      boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
    };

    if (isMapInteractionActiveRef.current) {
      return;
    }

    const boundaryTerritory = getBoundaryTerritoryFromAssignments(
      extendedIdentifier, boundaryTerritoryGroupsRef.current, boundaryTerritoryAssignmentsRef.current
    );

    if (!boundaryTerritoryGroup?.settings.boundariesAsTerritories || !boundaryTerritory) {
      dispatch(activeMapElementsSetActiveBoundary({
        boundaryGroupId: boundaryIdentifier.boundaryGroupId,
        boundaryId: boundaryIdentifier.boundaryId,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
      }));
    }
    else {
      dispatch(activeMapElementsSetActiveBoundaryTerritory({
        boundaryGroupId: boundaryIdentifier.boundaryGroupId,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
        boundaryTerritoryId: boundaryTerritory.boundaryTerritoryId,
      }));
    }
  }, [dispatch]);

  const onMouseOver = useCallback((boundaryIdentifier: BoundaryIdentifier) => {
    if (isMapInteractionActiveRef.current) {
      return;
    }

    const boundaryTerritoryGroup = boundaryTerritoryGroupsRef.current
      .find(g => g.boundaryGroupId === boundaryIdentifier.boundaryGroupId);

    if (!boundaryTerritoryGroup) {
      return;
    }

    const extendedIdentifier = {
      ...boundaryIdentifier,
      boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
    };

    const boundaryTerritory = getBoundaryTerritoryFromAssignments(
      extendedIdentifier, boundaryTerritoryGroupsRef.current, boundaryTerritoryAssignmentsRef.current
    );

    if (!boundaryTerritoryGroup?.settings.boundariesAsTerritories || !boundaryTerritory) {
      stopHoverDebounced.cancel();
      dispatch(boundaryHighlightHoverBoundary(extendedIdentifier));
    }
    else {
      stopHoverDebounced.cancel();
      dispatch(boundaryHighlightHoverBoundaryTerritory({
        boundaryGroupId: boundaryIdentifier.boundaryGroupId,
        boundaryTerritoryId: boundaryTerritory.boundaryTerritoryId,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
      }));
    }
  }, [dispatch]);

  const onMouseOut = useCallback((boundaryIdentifier: BoundaryIdentifier) => {
    if (isMapInteractionActiveRef.current) {
      return;
    }

    const boundaryTerritoryGroup = boundaryTerritoryGroupsRef.current
      .find(g => g.boundaryGroupId === boundaryIdentifier.boundaryGroupId);

    if (!boundaryTerritoryGroup) {
      return;
    }

    const extendedIdentifier = {
      ...boundaryIdentifier,
      boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
    };

    const boundaryTerritory = getBoundaryTerritoryFromAssignments(
      extendedIdentifier, boundaryTerritoryGroupsRef.current, boundaryTerritoryAssignmentsRef.current
    );

    if (!boundaryTerritoryGroup?.settings.boundariesAsTerritories || !boundaryTerritory) {
      stopHoverDebounced(() => dispatch(boundaryHighlightStopBoundaryHover(extendedIdentifier)));
    }
    else {
      stopHoverDebounced(() => dispatch(boundaryHighlightStopHoverBoundaryTerritory({
        boundaryGroupId: boundaryIdentifier.boundaryGroupId,
        boundaryTerritoryId: boundaryTerritory.boundaryTerritoryId,
        boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
      })));
    }
  }, [dispatch]);

  const eventCallbacks = useMemo(() => ({
    onClick,
    onMouseOver,
    onMouseOut,
  }), [onClick, onMouseOver, onMouseOut]);

  useEffect(() => {
    if (!manager) {
      return;
    }

    manager.addBoundaryEventListener('click', eventCallbacks.onClick);
    manager.addBoundaryEventListener('mouseover', eventCallbacks.onMouseOver);
    manager.addBoundaryEventListener('mouseout', eventCallbacks.onMouseOut);

    return () => {
      manager.removeBoundaryEventListener('click', eventCallbacks.onClick);
      manager.removeBoundaryEventListener('mouseover', eventCallbacks.onMouseOver);
      manager.removeBoundaryEventListener('mouseout', eventCallbacks.onMouseOut);
    };
  }, [manager, eventCallbacks]);
};

const stopHoverDebounced = debounce((action: () => void) => action(), 100);
