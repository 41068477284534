import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  disclaimerStepSecondaryStyle, headingSecondaryStyle, useDisclaimerSteps,
} from '~/_shared/components/disclaimer/disclaimerSteps';
import { DisclaimerContainer } from '../../../_shared/components/disclaimer/disclaimer.container';
import { DisclaimerStepComponent } from '../../../_shared/components/disclaimer/disclaimerStep.component';
import {
  MapConfirmComponent, type MapConfirmProps,
} from '../../../_shared/mapConfirm/mapConfirm.component';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { useTranslation } from '../../../_shared/utils/hooks';
import { BoundarySelectType } from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import {
  s, Trans,
} from '../../../translations/Trans';

type BoundarySelectConfirmProps = MapConfirmProps & Readonly<{
  highlightedStepNumber?: number;
}>;

const disclaimerStyle = css({
  marginLeft: 20,
  padding: '23px 18px 13px',
  fontSize: '14px',
});

const mapConfirmStyle = css({
  minWidth: 270,
});

const triggerPlaceholderStyle = ({ theme }: ThemeProps) => css({
  minWidth: 112,
  color: theme.textColors.primary,
});

const dropdownListStyle = css({
  minWidth: 180,
});

export const BoundarySelectConfirmComponent: FC<BoundarySelectConfirmProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const disclaimerSteps = useDisclaimerSteps();

  return (
    <MapConfirmComponent
      {...props}
      triggerPlaceholderStyle={triggerPlaceholderStyle({ theme })}
      css={mapConfirmStyle}
      dropdownListStyle={dropdownListStyle}
    >
      {props.selectedOptionId === BoundarySelectType.Click && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'select-boundary-click',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            step={1}
            isHighlighted={props.highlightedStepNumber === 1}
          >
            {disclaimerSteps.Start}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={2}
            isHighlighted={props.highlightedStepNumber === 2}
          >
            {t('Click the areas you wish to add to your selection.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={3}
            isHighlighted={props.highlightedStepNumber === 3}
          >
            {disclaimerSteps.FinishSelection}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}

      {(props.selectedOptionId === BoundarySelectType.HoverIdle || props.selectedOptionId === BoundarySelectType.HoverActive) && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'select-boundary-hover',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            step={1}
            isHighlighted={props.highlightedStepNumber === 1}
          >
            {disclaimerSteps.Start}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={2}
            isHighlighted={props.highlightedStepNumber === 2}
          >
            {t('Click once to start adding areas to your selection.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={3}
            isHighlighted={props.highlightedStepNumber === 3}
          >
            {t('Hover your mouse over areas which you want to add to your selection.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={4}
            isHighlighted={props.highlightedStepNumber === 4}
          >
            {t('Click again to stop adding areas to your selection.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={5}
            isHighlighted={props.highlightedStepNumber === 5}
          >
            {disclaimerSteps.FinishSelection}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}

      {(props.selectedOptionId === BoundarySelectType.Lasso) && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'select-boundary-lasso',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            step={1}
            isHighlighted={props.highlightedStepNumber === 1}
          >
            {disclaimerSteps.Start}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={2}
            isHighlighted={props.highlightedStepNumber === 2}
          >
            {t('Select the coverage type.')}

            <span css={disclaimerStepSecondaryStyle}>
              {'- '}
              <Trans i18nKey="<0>Partial</0> requires only part of the area to be placed inside of the lasso to be selected.">
                <strong css={headingSecondaryStyle}>Partial</strong>
                {s(' requires only part of the area to be placed inside of the lasso to be selected.')}
              </Trans>
            </span>
            <span css={disclaimerStepSecondaryStyle}>
              {'- '}
              <Trans i18nKey="<0>Full</0> requires entire area to be placed inside of the lasso to be selected.">
                <strong css={headingSecondaryStyle}>Full</strong>
                {s(' requires entire area to be placed inside of the lasso to be selected.')}
              </Trans>
            </span>
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={3}
            isHighlighted={props.highlightedStepNumber === 3}
          >
            {t('Click the map to start drawing your polygon.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={4}
            isHighlighted={props.highlightedStepNumber === 4}
          >
            {t('Click the map again to add additional vertices to the polygon outline.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={5}
            isHighlighted={props.highlightedStepNumber === 5}
          >
            {t('Click the first vertex or double click the map to complete the loop.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={6}
            isHighlighted={props.highlightedStepNumber === 6}
          >
            {disclaimerSteps.FinishSelection}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}
    </MapConfirmComponent>
  );
};
