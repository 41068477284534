import { type CancelToken } from 'axios';
import type { DeepWritable } from 'ts-essentials';
import { SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type FilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { type BoundaryFilterRequest } from '../../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '../../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type PolygonFilterRequest } from '../../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { type RadiusFilterRequest } from '../../../spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkRequestGetter,
  type SpreadsheetDataColumnsToFetch,
} from '../../../spreadsheet/spreadsheet.repository';
import { getDemographicsQuery } from '../../../store/demographics/demographics.repository';
import {
  createFilterTreeRequestFromMapSettings,
  type FilterTreeRequest,
  mergeFilterTrees,
} from '../../../store/spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import { checkIfIsNumericalColumn } from '../../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { createSearchFilterTreeRequestFromMapSettings } from '../../../store/spreadsheetData/search/spreadsheetData.search.helpers';
import { DataType } from '../../../store/spreadsheetData/spreadsheetData.state';
import { SearchMatchingBehaviour } from '../../constants/searchMatchingBehaviour.enum';
import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '../../types/spreadsheet/spreadsheet.types';
import { isNullOrUndefined } from '../typeGuards';
import { type DemographicMetricModel } from './demographicsMetric.factory';
import { type MetricModel } from './metrics.factory';
import {
  type MetricProps, MetricsDataAction,
} from './metrics.types';
import { type SpreadsheetColumnMetricModel } from './spreadsheetMetric.factory';

export const METRICS_EXCLUDED_ROWS_COUNT = 'METRICS_EXCLUDED_ROWS_COUNT';
export const METRICS_ROWS_COUNT = 'METRICS_ROWS_COUNT';

const numericMetricsTypes = [MetricsDataAction.AVERAGE, MetricsDataAction.HIGH_VALUE, MetricsDataAction.LOW_VALUE, MetricsDataAction.SUM];
const groupMetricsTypes = [MetricsDataAction.GROUP_COUNT];

type GetMetricsDataArguments = {
  clientId: number;
  mapId: number;
  cancelToken: CancelToken;
  selectedMetrics: ReadonlyArray<MetricModel>;
  filterTreeParams?: FilterTreeMapSettingsParams;
  radiusFilterRequest?: RadiusFilterRequest;
  polygonFilterRequest?: PolygonFilterRequest;
  boundaryFilterRequest?: BoundaryFilterRequest;
  boundaryTerritoryFilterRequest?: BoundaryTerritoryFilterRequest;
};

export type MetricsResults = {
  demographicMetrics: MetricProps[];
  spreadsheetColumnMetrics: MetricProps[];
};

export const getAndProcessMetricsData = async (metricsArguments: GetMetricsDataArguments): Promise<MetricsResults> => {
  const demographicMetrics = await getDemographicsMetrics(metricsArguments);
  const spreadsheetColumnMetrics = await getSpreadsheetColumnMetricsData(metricsArguments);

  return {
    demographicMetrics,
    spreadsheetColumnMetrics,
  };
};

const getDemographicsMetrics = async ({ clientId, mapId, selectedMetrics, radiusFilterRequest,
  boundaryFilterRequest, boundaryTerritoryFilterRequest, polygonFilterRequest }: GetMetricsDataArguments): Promise<MetricProps[]> => {
  const demographicMetrics = selectedMetrics
    .filter((metric): metric is DemographicMetricModel => metric.type === SelectedDataType.Demographic);

  if (demographicMetrics.length === 0) {
    return [];
  }

  const demographicIds = demographicMetrics.map(demographic => demographic.demographic.id);
  const response = await getDemographicsQuery(clientId, {
    map_id: mapId,
    demographics_ids: demographicIds,
    radius_filter: radiusFilterRequest,
    polygon_filter: polygonFilterRequest,
    boundary_filter: boundaryFilterRequest,
    boundary_territory_filter: boundaryTerritoryFilterRequest,
  });

  return response.map(result => ({
    demographicId: result.demographicId,
    name: result.name,
    value: result.value,
    prefix: result.preData,
    suffix: result.postData,
    additionalData: [],
  }));
};

const getSpreadsheetColumnMetricsData = async ({ clientId, mapId, cancelToken, selectedMetrics, filterTreeParams,
  radiusFilterRequest, polygonFilterRequest, boundaryFilterRequest, boundaryTerritoryFilterRequest }: GetMetricsDataArguments) => {
  const spreadsheetColumnMetrics = selectedMetrics
    .filter((metric): metric is SpreadsheetColumnMetricModel => metric.type === SelectedDataType.SpreadsheetColumn);

  const metricsSpreadsheetIds = selectedMetrics.reduce<Set<number>>((acc, metric) => {
    if (metric.type === SelectedDataType.SpreadsheetColumn) {
      acc.add(metric.spreadsheetColumn.spreadsheetId);
    }

    return acc;
  }, new Set());
  const filterTree: FilterTreeRequest = filterTreeParams ? createFilterTreeRequestFromMapSettings(filterTreeParams, []) : newPerSpreadsheetMap();

  const searchFilterTree: FilterTreeRequest = filterTreeParams?.search.selectedMatchingBehaviour === SearchMatchingBehaviour.ShowOnlyMatches
    ? createSearchFilterTreeRequestFromMapSettings(filterTreeParams, Array.from(metricsSpreadsheetIds))
    : newPerSpreadsheetMap();

  const perSpreadsheetColumns: PerSpreadsheet<{ [columnId: ColumnId]: Set<DataType> }> = newPerSpreadsheetMap();

  // process spreadsheet metrics
  for (const metric of spreadsheetColumnMetrics) {
    if (!perSpreadsheetColumns[metric.spreadsheetColumn.spreadsheetId]) {
      perSpreadsheetColumns[metric.spreadsheetColumn.spreadsheetId] = {};
    }

    const spreadsheetId = metric.spreadsheetColumn.spreadsheetId;
    const spreadSheetColumns = perSpreadsheetColumns[spreadsheetId] = (perSpreadsheetColumns[spreadsheetId] ?? {});

    const columnId = metric.spreadsheetColumn.id;
    const columnsTypesToFetch = spreadSheetColumns[columnId] = (spreadSheetColumns[columnId] ?? new Set());

    if (numericMetricsTypes.includes(metric.dataAction)) {
      columnsTypesToFetch.add(DataType.NUMBER);
    }

    if (groupMetricsTypes.includes(metric.dataAction)) {
      columnsTypesToFetch.add(DataType.GROUP);
    }
  }

  const perSpreadsheetBulkDataGetters: PerSpreadsheet<SpreadsheetDataBulkRequestGetter> = newPerSpreadsheetMap();

  const perSpreadsheetColumnsEntries = Object.entries(perSpreadsheetColumns)
    .map(([spreadsheetId, columns]) => [Number(spreadsheetId), columns] as const);

  for (const [spreadsheetId, columns] of perSpreadsheetColumnsEntries) {

    const columnsToFetch = Object.entries(columns)
      .reduce<DeepWritable<SpreadsheetDataColumnsToFetch>>((acc, [columnId, dataTypes]) => {
        dataTypes.forEach(type => {
          acc[type] = acc[type] ?? {};
          acc[type][columnId] = {
            empty_is_null: true,
          };
        });

        return acc;
      }, {});

    const filterTreeRequest = filterTree[spreadsheetId]?.filterTree;
    const searchFilterTreeRequest = searchFilterTree[spreadsheetId]?.filterTree;

    const mergedFilterTrees = mergeFilterTrees([filterTreeRequest, searchFilterTreeRequest], 'and');

    if (!perSpreadsheetBulkDataGetters[spreadsheetId]) {
      perSpreadsheetBulkDataGetters[spreadsheetId] = {
        map_id: mapId,
        spreadsheet_id: spreadsheetId,
        exclude_basic_data: true,
        exclude_row_data: false,
        columns_to_fetch: columnsToFetch,
        filter: {
          only_get_filtered: true,
          filter_tree: mergedFilterTrees ?? undefined,
          radius_filter: radiusFilterRequest,
          polygon_filter: polygonFilterRequest,
          boundary_filter: boundaryFilterRequest,
          boundary_territory_filter: boundaryTerritoryFilterRequest,
        },
      };
    }
  }

  const bulkDataGetters: SpreadsheetDataBulkRequestGetter[] = [];

  Object.keys(perSpreadsheetBulkDataGetters).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const spreadsheetGetter = perSpreadsheetBulkDataGetters[spreadsheetId];

    if (spreadsheetGetter) {
      bulkDataGetters.push(spreadsheetGetter);
    }
  });

  if (bulkDataGetters.length === 0) {
    return [];
  }

  const spreadsheetColumnMetricsResults = await getSpreadsheetBulkData(clientId, { params: bulkDataGetters }, cancelToken);

  return spreadsheetColumnMetrics.reduce<MetricProps[]>((acc, metric) => {
    let value: number;
    const additionalData: Array<{ name: string; value: number }> = [];

    // group count
    if (groupMetricsTypes.includes(metric.dataAction)) {
      value = 0;
      spreadsheetColumnMetricsResults.data.forEach(response => {
        if (response.spreadsheet_id !== metric.spreadsheetColumn.spreadsheetId) {
          return;
        }

        const columnValues = response.result.columns_to_fetch?.[DataType.GROUP]?.[metric.spreadsheetColumn.id];
        if (!columnValues) {
          return;
        }

        const additionalColumnCounters: Array<{name: string; count: number}> =
          columnValues.unique_values.map((name) => ({
            name,
            count: 0,
          }));

        Object.keys(columnValues.row_values).forEach(rowId => {
          const itemUniqueGroupIndex = columnValues.row_values[rowId];

          if (itemUniqueGroupIndex !== null && itemUniqueGroupIndex !== undefined) {
            const item = additionalColumnCounters[itemUniqueGroupIndex];
            if (item) {
              item.count++;
            }
          }
        });

        const additionalColumnCountersWithValues = additionalColumnCounters.filter(item => item.count > 0);
        value = additionalColumnCountersWithValues.length;

        additionalData.push(...additionalColumnCountersWithValues.map(item => ({
          name: item.name,
          value: item.count,
        })));
      });
    }
    // numeric results
    else {
      const fallBackValue = 0;
      value = fallBackValue;

      spreadsheetColumnMetricsResults.data.forEach(response => {
        if (response.spreadsheet_id !== metric.spreadsheetColumn.spreadsheetId) {
          return;
        }

        const columnValues = response.result.columns_to_fetch?.[DataType.NUMBER]?.[metric.spreadsheetColumn.id];
        if (!columnValues || !checkIfIsNumericalColumn(columnValues.min, columnValues.max)) {
          return;
        }

        const columnValuesKeys = Object.keys(columnValues.row_values);
        let valuesCount = 0;
        let sum = 0;
        let lowValue: number | null = null;
        let highValue: number | null = null;

        columnValuesKeys.forEach(rowId => {
          const rowValue = columnValues.row_values[rowId];

          if (isNullOrUndefined(rowValue)) {
            return;
          }

          valuesCount++;
          sum += rowValue;
          if (lowValue === null || rowValue < lowValue) {
            lowValue = rowValue;
          }

          if (highValue === null || rowValue > highValue) {
            highValue = rowValue;
          }
        });

        switch (metric.dataAction) {
          case MetricsDataAction.AVERAGE:
            value = valuesCount === 0 ? fallBackValue : (sum / valuesCount);
            additionalData.push({
              name: METRICS_EXCLUDED_ROWS_COUNT,
              value: columnValuesKeys.length - valuesCount,
            });
            additionalData.push({
              name: METRICS_ROWS_COUNT,
              value: columnValuesKeys.length,
            });
            break;
          case MetricsDataAction.LOW_VALUE:
            value = lowValue ?? fallBackValue;
            break;
          case MetricsDataAction.HIGH_VALUE:
            value = highValue ?? fallBackValue;
            break;
          case MetricsDataAction.SUM: {
            value = sum;
            break;
          }
          default:
            break;
        }
      });
    }

    acc.push({
      additionalData,
      dataAction: metric.dataAction,
      name: metric.spreadsheetColumn.name,
      value,
    });

    return acc;
  }, []);
};
