import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  forwardRef, type MouseEventHandler, type ReactNode, useCallback, useMemo, useState,
} from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import {
  type FaIcon, FontAwesomeIcon,
} from '../../icon/fontAwesomeIcon.component';
import { usePointerClickEvent } from '../../utils/usePointerClickEvent';
import {
  buttonStyle, ButtonStyles,
} from './button.styles';
import {
  ButtonSize, ButtonStyle, type ButtonStyleProps,
} from './button.types';

const prefixIconStyle = css({
  display: 'inline-block',
  marginRight: 10,
});

const suffixIconStyle = css({
  display: 'inline-block',
  marginLeft: 10,
});

export type ButtonProps = Readonly<{
  buttonStyle?: ButtonStyle;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isSubmit?: boolean;
  prefixIcon?: FaIcon;
  prefixIconHovered?: FaIcon;
  prefixIconStyle?: SerializedStyles;
  size?: ButtonSize;
  suffixIcon?: FaIcon;

  text: ReactNode | ((style: ButtonStyleProps) => ReactNode);
  onClick?: MouseEventHandler;
}>;

export const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const theme = useTheme();

  const btnStyle = ButtonStyles(theme)[props.buttonStyle || ButtonStyle.Primary];

  const { onClick, onPointerDown, onPointerUp } = usePointerClickEvent(props.onClick);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const prefixIcon = useMemo(() => {
    if (isHovered && props.prefixIconHovered) {
      return props.prefixIconHovered;
    }
    return props.prefixIcon;
  }, [isHovered, props.prefixIcon, props.prefixIconHovered]);

  return (
    <button
      ref={ref}
      css={buttonStyle({
        btnStyle,
        isDisabled: props.isDisabled,
        isActive: props.isActive,
        theme,
        size: props.size || ButtonSize.Medium,
      })}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      className={props.className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={props.isDisabled}
      type={props.isSubmit ? 'submit' : 'button'}
    >
      {prefixIcon && (
        <span css={css(prefixIconStyle, props.prefixIconStyle)}>
          <FontAwesomeIcon icon={prefixIcon} />
        </span>
      )}

      {(typeof props.text === 'function')
        ? props.text(btnStyle)
        : props.text}

      {props.suffixIcon && (
        <span css={suffixIconStyle}>
          <FontAwesomeIcon icon={props.suffixIcon} />
        </span>
      )}
    </button>
  );
});

ButtonComponent.displayName = 'ButtonComponent';
