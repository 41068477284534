import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GoogleSheetMapSyncType } from '~/map/map.repository';
import { type ModalProps } from '~/modal/modalType.enum';
import { type DataSource } from './dataSource/dataSource';
import { DataSourceSectionComponent } from './dataSource/dataSourceSection.component';
import { SyncMethodSectionComponent } from './syncMethodSection.component';
import { useManageUniqueIdentifierSelect } from './useManageUniqueIdentifierSelect.hook';

const noPaddingStyle = css({ padding: 0 });

const contentStyle = css({
  padding: '2px 10px',
});

const buttonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'end',
  marginTop: 25,
  width: '100%',
});

const buttonStyle = css({
  height: 40,
  minWidth: 90,
  padding: '0 12px',
});

const radioLabelStyle = css({
  '&:first-of-type': {
    display: 'flex',
    alignItems: 'start',
  },
});

export type ButtonProps = {
  prefixIcon?: IconProp;
  text: string;
};

type SyncDataProps = ModalProps<{
  dataSource: DataSource;
  googleAccount?: string;
  initialSyncMethod: GoogleSheetMapSyncType;
  initialUniqueIdentifierIndexes?: Readonly<number[]>;
  isLoading: boolean;
  modalCaption: string;
  skipSyncSelectStep?: boolean;
  submitSyncButtonProps: ButtonProps;
  syncSelectedButtonProps?: ButtonProps;

  onGoogleLogout?: () => void;
  onSyncMethodSelected?: (syncMethod: GoogleSheetMapSyncType) => boolean;
  onSyncFormSubmitted?: (syncMethod: GoogleSheetMapSyncType, identifierIds: number[]) => void;
}>;

export const SyncDataComponent: FC<SyncDataProps> = (props) => {
  const { onSyncMethodSelected, onSyncFormSubmitted, dataSource } = props;

  const [syncMethod, setSyncMethod] = useState<GoogleSheetMapSyncType>(props.initialSyncMethod);
  const [isIdSelectionOpen, setIsIdSelectionOpen] = useState(props.skipSyncSelectStep);
  const [isSyncMethodSectionOpen, setIsSyncMethodSectionOpen] = useState(!props.skipSyncSelectStep);

  const [t] = useTranslation();
  const uniqueIdentifierData = useManageUniqueIdentifierSelect(dataSource, props.initialUniqueIdentifierIndexes);

  const toggleSyncMethodOpen = useCallback(() => (
    setIsSyncMethodSectionOpen(isOpen => !isOpen)
  ), []);

  const isReadyForSync = useMemo(() => (
    !uniqueIdentifierData.isCheckingIdentifier
    && uniqueIdentifierData.isIdentifierValid
    && uniqueIdentifierData.uniqueIdentifierIndexes
  ), [uniqueIdentifierData.isCheckingIdentifier, uniqueIdentifierData.isIdentifierValid, uniqueIdentifierData.uniqueIdentifierIndexes]);

  const proceedToPickUIDStep = useCallback(() => {
    setIsIdSelectionOpen(true);
    setIsSyncMethodSectionOpen(false);
  }, []);

  const handleSync = useCallback(() => {
    if (isReadyForSync && uniqueIdentifierData.uniqueIdentifierIndexes && onSyncFormSubmitted) {
      onSyncFormSubmitted(syncMethod, uniqueIdentifierData.uniqueIdentifierIndexes);
    }
  }, [isReadyForSync, onSyncFormSubmitted, syncMethod, uniqueIdentifierData.uniqueIdentifierIndexes]);

  const handleSyncMethodSelected = useCallback(() => {
    if (onSyncMethodSelected) {
      if (onSyncMethodSelected(syncMethod)) {
        proceedToPickUIDStep();
      }
    }
    else {
      proceedToPickUIDStep();
    }
  }, [onSyncMethodSelected, proceedToPickUIDStep, syncMethod]);

  useEffect(() => {
    setSyncMethod(props.initialSyncMethod);
  }, [props.initialSyncMethod]);

  useEffect(() => () => {
    setIsSyncMethodSectionOpen(true);
    setIsIdSelectionOpen(false);
  }, []);

  useEffect(() => {
    if (isSyncMethodSectionOpen) {
      setIsIdSelectionOpen(false);
    }
  }, [isSyncMethodSectionOpen]);

  const dataSourceSection = useMemo(() => (
    <div css={contentStyle}>
      <DataSourceSectionComponent
        dataSource={props.dataSource}
        uniqueIdentifierData={uniqueIdentifierData}
        radioLabelStyle={radioLabelStyle}
      />
      <div css={buttonWrapperStyle}>
        <TooltipDeprComponent
          tooltipContent={!isReadyForSync && t('Check whether the selected column(s) form a unique identifier first.')}
        >
          <ButtonComponent
            css={buttonStyle}
            prefixIcon={props.submitSyncButtonProps.prefixIcon}
            text={props.submitSyncButtonProps.text}
            onClick={handleSync}
            isDisabled={!isReadyForSync}
          />
        </TooltipDeprComponent>
      </div>
    </div>
  ), [props.dataSource, props.submitSyncButtonProps.prefixIcon, props.submitSyncButtonProps.text, uniqueIdentifierData, isReadyForSync, t, handleSync]);

  const syncMethodSection = useMemo(() => (
    <div css={contentStyle}>
      <SyncMethodSectionComponent
        googleAccount={props.googleAccount}
        onGoogleLogout={props.onGoogleLogout}
        onMethodChange={setSyncMethod}
        radioLabelStyle={radioLabelStyle}
        syncMethod={syncMethod}
      />
      <div css={buttonWrapperStyle}>
        <ButtonComponent
          css={buttonStyle}
          onClick={handleSyncMethodSelected}
          text={props.syncSelectedButtonProps?.text || t('Continue')}
          prefixIcon={props.syncSelectedButtonProps?.prefixIcon}
        />
      </div>
    </div>
  ), [handleSyncMethodSelected, props.googleAccount, props.onGoogleLogout, syncMethod, t, props.syncSelectedButtonProps]);

  const syncMethodAccordionSection: AccordionData = useMemo(() => ({
    header: t('Sync Google Sheets Method'),
    isExpanded: isSyncMethodSectionOpen,
    onHeadingClick: toggleSyncMethodOpen,
    child: syncMethodSection,
  }), [isSyncMethodSectionOpen, syncMethodSection, t, toggleSyncMethodOpen]);

  const dataSourceAccordionSection: AccordionData = useMemo(() => ({
    header: t('Select a Unique Identifier'),
    isExpanded: isIdSelectionOpen,
    child: dataSourceSection,
  }), [dataSourceSection, isIdSelectionOpen, t]);

  return (
    <ModalComponent
      caption={props.modalCaption}
      contentStyle={noPaddingStyle}
      isOpen={props.isOpen}
      maxWidth={800}
      onClose={props.onClose}
      isCloseDisabled={props.isLoading}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={[
            syncMethodAccordionSection,
            ...(props.onSyncFormSubmitted ? [dataSourceAccordionSection] : []),
          ]}
          showCount
        />
      </OutlinePanelComponent>
      {props.isLoading && <OverlayLoaderComponent />}
    </ModalComponent>
  );
};
