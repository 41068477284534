import {
  faDraftingCompass, faExpandArrows,
} from '@fortawesome/pro-solid-svg-icons';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';

export const getBoundaryDrawTypeOptions = (t: TranslationFnc, isEmpty = false): ReadonlyArray<DropdownOption<DrawType>> => {
  return [{
    name: t('Draw'),
    value: DrawType.Draw,
    icon: faDraftingCompass,
  }, {
    name: t('Edit'),
    value: DrawType.Drag,
    icon: faExpandArrows,
  }].filter(option => option.value !== DrawType.Drag || !isEmpty);
};
