import { css } from '@emotion/react';
import {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  createColorWithOpacity, guaranteeHash, isValidHex,
} from '../colorPicker.helpers';
import { type ColorResult } from '../colorPicker.types';

const DEFAULT_DIMENSION = 20;

const inputStyle = ({ theme, disabled }: ThemeProps<{disabled?: boolean}>) => css({
  height: DEFAULT_DIMENSION,
  opacity: disabled ? theme.opacity.disabled : 1,
  textTransform: 'uppercase',
  width: '80%',
});

type ColorCodeHexFieldProps = Readonly<{
  className?: string;
  disabled?: boolean;
  onChange?: (value: ColorResult) => void;
  value: ColorResult;
}>;

export const ColorCodeHexFieldComponent = forwardRef<HTMLInputElement, ColorCodeHexFieldProps>((props, inputRef) => {
  const [hexInput, setHexInput] = useState(props.value.hex);
  const theme = useTheme();

  useEffect(() => {
    setHexInput(props.value.hex);
  }, [props.value.hex]);

  const onHexInputChange = useCallback((value: string) => {
    const hex = guaranteeHash(value);

    // Only full length hex is valid to keep input consistent with ColorResult format
    if (hex.length === 7 && isValidHex(hex) && props.value.hex.toUpperCase() !== hex.toUpperCase()) {
      const onChange = props.onChange;
      onChange?.(createColorWithOpacity(hex, props.value.hsl.a));
    }
    else {
      setHexInput(value);
    }
  }, [props.onChange, props.value.hex, props.value.hsl.a]);

  return (
    <input
      className={props.className}
      css={inputStyle({ theme, disabled: props.disabled })}
      disabled={props.disabled}
      maxLength={7}
      onChange={e => onHexInputChange(e.target.value)}
      ref={inputRef}
      value={guaranteeHash(hexInput)}
    />
  );
});
