import {
  useEffect, useMemo, useState,
} from 'react';
import { useFileUrls } from '../../../store/frontendState/fileUrls/fileUrls.selector';
import { type FileUrl } from '../../../store/frontendState/fileUrls/fileUrls.state';
import { useMapSettingsFileAttachmentsListSelector } from '../../../store/mapSettings/fileAttachments/fileAttachments.selectors';

export type FileUrlsLookup = Readonly<{
  fileIdsToUrls: ReadonlyMap<number, FileUrl>;
  fileAttachmentIdsToUrls: ReadonlyMap<string, FileUrl>;
}>;

export const useFileAttachmentUrls = () => {
  const fileUrlsMap = useFileUrls();
  const fileAttachmentList = useMapSettingsFileAttachmentsListSelector();
  const [fileAttachmentIdsToUrls, setFileAttachmentIdsToUrls] = useState(new Map<string, FileUrl>());

  useEffect(() => {
    const newFileAttachmentIdsToUrls = new Map<string, FileUrl>();
    for (const item of fileAttachmentList) {
      const selectedFileUrl = fileUrlsMap.get(item.fileId);
      if (selectedFileUrl) {
        newFileAttachmentIdsToUrls.set(item.id, selectedFileUrl);
      }
    }
    setFileAttachmentIdsToUrls(newFileAttachmentIdsToUrls);
  }, [fileAttachmentList, fileUrlsMap]);

  return useMemo(() => ({
    fileIdsToUrls: fileUrlsMap,
    fileAttachmentIdsToUrls,
  }), [fileUrlsMap, fileAttachmentIdsToUrls]);
};
