import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { boundarySelectSetSelectType } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { useBoundarySelectActiveSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { BoundarySelectType } from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';

export const useSelectionMapBoundaryMapMouseEvents = (map: google.maps.Map | undefined) => {
  const dispatch = useDispatch();

  const selection = useBoundarySelectActiveSelector();
  const selectionRef = useRef(selection);

  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);

  const onClick = useCallback(() => {
    if (!selectionRef.current || !selectionRef.current.isActive) {
      return;
    }

    switch (selectionRef.current.selectType) {
      case BoundarySelectType.HoverIdle: {
        dispatch(boundarySelectSetSelectType(BoundarySelectType.HoverActive));
        break;
      }

      case BoundarySelectType.HoverActive: {
        dispatch(boundarySelectSetSelectType(BoundarySelectType.HoverIdle));
        break;
      }

      default: {
        return;
      }
    }
  }, [dispatch]);

  const eventCallbacks = useMemo(() => ({
    onClick,
  }), [onClick]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const listener = map.addListener('click', eventCallbacks.onClick);

    return () => {
      listener.remove();
    };
  }, [map, eventCallbacks]);
};
