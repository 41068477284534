import { themeTypes } from '~/_shared/themes/themeTypes';
import { type PerThemeColor } from '~/_shared/utils/colors/colors.types';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { type MapSettingsMarkersResponse } from '~/map/map.repository';

type PanelSettingsJson = MapSettingsMarkersResponse['panelSettings'];

export const ensurePanelSettingsCompatibility = (panelSettingsJson: PanelSettingsJson) => {
  const resultJson = panelSettingsJson && cloneDeep(panelSettingsJson);

  ensureTheme(resultJson);

  return resultJson;
};

const ensureTheme = (panelSettingsJson: PanelSettingsJson) => {
  convertColorToThemeColor(panelSettingsJson?.bulkStyle);
  convertColorToThemeColor(panelSettingsJson?.locationDescriptionStyle?.header);
  convertColorToThemeColor(panelSettingsJson?.locationDescriptionStyle?.semiHeader);
  convertColorToThemeColor(panelSettingsJson?.locationDescriptionStyle?.description);

  const itemStyles = Object.values(panelSettingsJson?.itemsData ?? {})
    .map(columns => Object.values(columns))
    .flat();

  itemStyles.forEach(item => {
    delete (item as any).editable;
    return convertColorToThemeColor(item?.style);
  });
};

export const isThemeMissing = (style: string | Readonly<PerThemeColor> | undefined): style is string =>
  !!style && !themeTypes.some(theme => style.hasOwnProperty(theme)) && (typeof style === 'string');

const convertColorToThemeColor = (style: {
  valueFontColor?: string | Readonly<PerThemeColor>;
  labelFontColor?: string | Readonly<PerThemeColor>;
} | undefined) => {
  if (!style) {
    return;
  }

  const addTheme = (color: string) => themeTypes.reduce((accum, themeType) => ({ ...accum, [themeType]: color }), {});

  if (isThemeMissing(style.valueFontColor)) {
    style.valueFontColor = addTheme(style.valueFontColor);
  }

  if (isThemeMissing(style.labelFontColor)) {
    style.labelFontColor = addTheme(style.labelFontColor);
  }
};
