import type { TranslationFnc } from '~/_shared/utils/hooks';
import { PasswordErrorType } from './passwordErrorType';

// TODO: | string should be removed once all error types are implemented
export const parsePasswordErrorType = (
  error: PasswordErrorType | string,
  t: TranslationFnc,
  fallbackErrorMessage?: string,
): string => {
  switch (error) {
    case PasswordErrorType.PasswordUsedRecently:
      return t('password.reset.error.usedRecently');
    default:
      return fallbackErrorMessage || t('Something went wrong, please try again with a different password.');
  }
};
