import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.DrippingSpinner]: {
  views: {
    default: {
      canvasHeight: 600,
      canvasWidth: 600,
      iconWidth: 595,
      iconHeight: 595,
    },
  },
  colors: {
    fill: '#00A3FF',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 120] as const,
    fastStart: [[87, 120], [0, 120]] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/39793-infinite-spinner-falling-drop-of-water-in-aquarium" */
      './39793-infinite-spinner-falling-drop-of-water-in-aquarium.json'
    ),
} };

export const { DrippingSpinner } = config;
