import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { getSpreadsheetMatchup } from '~/spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';

export const useGetMatchupData = () => {
  const clientId = useClientIdSelector();
  const { invokeAjax, isLoading, data, error } = useAjaxCall(getSpreadsheetMatchup);

  const getMatchupData = useCallback((params: ReadonlyArray<{
    mapId: number;
    virtualSpreadsheetId: number;
  }>) => {
    if (!clientId) {
      return;
    }

    invokeAjax(clientId, params.map(p => ({ map_id: p.mapId, spreadsheet_id: p.virtualSpreadsheetId })));
  }, [clientId, invokeAjax]);

  return {
    getMatchupData,
    isLoading,
    data,
    error,
  };
};
