import {
  useCallback,
  useEffect,
} from 'react';

export const useClickOutsideClassNameListener = (onClickOutside: () => void, className: string, shouldBeCalled: boolean) => {
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClickOutside();
    }
  }, [onClickOutside]);
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (shouldBeCalled && e.target && !hasSomeParentTheClass(e.target as HTMLElement, className)) {
        onClickOutside();
      }
    },
    [className, onClickOutside, shouldBeCalled],
  );
  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);
};

const hasSomeParentTheClass = (element: HTMLElement, classname: string): boolean => {
  const classAttribute = element.getAttribute('class');
  if (classAttribute && classAttribute.split(' ').includes(classname)) {
    return true;
  }
  return !!element.parentElement && hasSomeParentTheClass(element.parentElement, classname);
};
