import { useSelector } from '../../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../../app.store';
import {
  type BoundaryDrawEditActiveState,
  type BoundaryPolygonDragEditActiveState,
  isBoundaryDrawActive,
  isBoundaryDrawEditActive,
  isBoundaryDrawEditModalOpen,
  isBoundaryPolygonDragEditActive, isBoundaryPolygonDragModalOpen,
} from './boundaryDraw.state';

export const boundaryDrawSelector = (state: AppState) => state.frontendState.mapTools.boundary.boundaryDraw;

const boundaryDrawPolygonEditActiveSelector = (state: AppState): BoundaryPolygonDragEditActiveState | null => {
  const { boundaryDraw } = state.frontendState.mapTools.boundary;
  return isBoundaryPolygonDragEditActive(boundaryDraw) ? boundaryDraw : null;
};
export const useBoundaryDrawPolygonEditActiveSelector = () => useSelector(boundaryDrawPolygonEditActiveSelector);

const boundaryDrawPolygonEditModalOpenSelector = (state: AppState): BoundaryPolygonDragEditActiveState | null => {
  const { boundaryDraw } = state.frontendState.mapTools.boundary;
  return isBoundaryPolygonDragModalOpen(boundaryDraw) ? boundaryDraw : null;
};
export const useBoundaryDrawPolygonEditModalOpenSelector = () => useSelector(boundaryDrawPolygonEditModalOpenSelector);

const boundaryDrawEditActiveSelector = (state: AppState): BoundaryDrawEditActiveState | null => {
  const { boundaryDraw } = state.frontendState.mapTools.boundary;
  return isBoundaryDrawEditActive(boundaryDraw) ? boundaryDraw : null;
};
export const useBoundaryDrawEditActiveSelector = () => useSelector(boundaryDrawEditActiveSelector);

const boundaryDrawEditModalOpenSelector = (state: AppState): BoundaryDrawEditActiveState | null => {
  const { boundaryDraw } = state.frontendState.mapTools.boundary;
  return isBoundaryDrawEditModalOpen(boundaryDraw) ? boundaryDraw : null;
};
export const useBoundaryDrawEditModalOpenSelector = () => useSelector(boundaryDrawEditModalOpenSelector);

const boundaryDrawActiveSelector = (state: AppState) => {
  const { boundaryDraw } = state.frontendState.mapTools.boundary;
  return isBoundaryDrawActive(boundaryDraw) ? boundaryDraw : null;
};
export const useBoundaryDrawActiveSelector = () => useSelector(boundaryDrawActiveSelector);
export const useBoundaryDrawDragActiveSelector = () => useSelector(boundaryDrawActiveSelector)?.drawType === 'Drag';
