import { css } from '@emotion/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { type UniqueGroup } from '~/_shared/types/grouping/grouping';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { getMapUrl } from '~/map/map.helpers';

const LINE_SIZE = 45;

const columnItemStyle = ({ theme }: ThemeProps) => css({
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  height: LINE_SIZE,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.textColors.primary,
  backgroundColor: theme.backgroundColors.primary,

  '& + &': {
    borderTop: `1px solid ${theme.borderColors.primary}`,
  },
});

const groupDescriptionStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const checkboxStyle = css({
  width: 16,
  height: 16,
  paddingRight: 20,
});

const layerNameStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const iconStyle = (theme: Theme) => css({
  fontSize: 16,
  color: theme.textColors.secondary,
  '&:hover': {
    color: theme.textColors.link,
  },
});

type FilterViewLayerItemProps = Readonly<{
  baseMapsUniqueGroupColumns: UniqueGroup[];
  isLayeredMapConnected: boolean;
  selectedLayersIds: string[];

  onSelectLayer: (layer: UniqueGroup) => () => void;
}>;

export const FilterViewLayersComponent: React.FC<FilterViewLayerItemProps> = ({
  baseMapsUniqueGroupColumns,
  isLayeredMapConnected,
  selectedLayersIds,
  onSelectLayer,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <>
      {baseMapsUniqueGroupColumns.map((layer) => (
        <div
          css={columnItemStyle({ theme })}
          key={layer.id}
        >
          <label
            css={groupDescriptionStyle}
            htmlFor={layer.id + ''}
          >
            <CheckboxComponent
              id={layer.id + ''}
              css={checkboxStyle}
              isChecked={selectedLayersIds.some(id => id === layer.id.toString())}
              checkedSetter={onSelectLayer(layer)}
            />

            <div css={layerNameStyle}>
              {layer.name}
            </div>
          </label>

          {isLayeredMapConnected && (
            <TooltipComponent tooltipContent={t('Go To Map')} delayShow={500}>
              <FontAwesomeIcon
                css={iconStyle}
                icon={faArrowUpRightFromSquare}
                onClick={() => {
                  const mapId = typeof layer.id === 'number' ? layer.id : parseInt(layer.id, 10);
                  window.open(
                    `${location.protocol}//${location.host}${getMapUrl(mapId)}`,
                    '_blank'
                  )?.focus();
                }}
              />
            </TooltipComponent>
          )}
        </div>
      ))}
    </>
  );
};
