import {
  type ColumnInfo, type ColumnsWithValuesDataType,
} from './guessUniqueColumns';

export const isColumnUnique = (column: string[]) => checkArrayVsSetCount([column], new Set(column));

export const getColumnsByKeywords = (keywords: string[], header: string[]): number[] => {
  return keywords.map(keyWord => {
    const exact = header.findIndex(h => h.toString().toLowerCase() === keyWord.toString().toLowerCase());
    if (exact !== -1) {
      return exact;
    }
    else {
      return header.findIndex(h => {
        const containedMatcher = new RegExp('\\b' + keyWord + '\\b', 'i');
        return containedMatcher.test(h);
      });
    }
  }).filter(v => v !== -1);
};

const checkArrayVsSetCount = (base: string[][], compared: Set<string>) =>
  compared.size === base[0].length && compared.size !== 0;

const DELIMITER = '|?%';
export const isColumnCombinationUnique = (indexes: number[], columns: string[][]) => {
  if (!columns[0]) {
    return true;
  }
  const uniqueCombinedValues = new Set<string>();
  columns[0].forEach((_, i) => {
    const combinedValue = indexes.reduce((result, columnIndex) => result + (columns[columnIndex]?.[i]?.trim() ?? '') + DELIMITER, '');
    uniqueCombinedValues.add(combinedValue);
  });

  return checkArrayVsSetCount(columns, uniqueCombinedValues);
};

export const getHeaderAndValuesFromColumnsWithValues = (columnsInfo: ColumnInfo[], dataValues: ColumnsWithValuesDataType) => {
  const header: string[] = [];
  const columns: string[][] = [];

  columnsInfo.forEach(column => {
    header.push(column.name);
    columns.push(dataValues[column.id]);
  });

  return {
    header, data: columns,
  };
};
