import { v4MapInfoComparers } from '~/store/mapMigration/helpers/v4MapInfoComparers';
import { v4MapsPerPage } from '~/store/mapMigration/mapMigration.constants';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import { type V4MapListingOrdering } from '~/store/mapMigration/types/v4MapListingOrdering.enum';

const getListingPage = <TItem, >(list: ReadonlyArray<TItem>, pageNumber: number, itemsPerPage: number): ReadonlyArray<TItem> => {
  return list.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
};

export const getCurrentMapPageInfo = (maps: ReadonlyArray<V4MapInfo>, filterQuery: string, ordering: V4MapListingOrdering, pageNumber: number) => {
  const normalizedQuery = filterQuery.toLowerCase();
  const filteredMaps = maps.filter(mapInfo => mapInfo.title.toLowerCase().includes(normalizedQuery));
  // The In-situ sort is fine here. The `filter` above creates a new array.
  const orderedMaps = filteredMaps.sort(v4MapInfoComparers[ordering]);
  const mapsOnTheCurrentPage = getListingPage(orderedMaps, pageNumber, v4MapsPerPage);

  return {
    mapsOnTheCurrentPage,
    pageCount: Math.max(Math.ceil(orderedMaps.length / v4MapsPerPage), 1),
  };
};
