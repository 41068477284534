import { useState } from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { UploadStatus } from '../../_shared/components/uploadStatus/uploadStatus.component';
import { getUserSpreadsheetImportBroadcast } from '../../_shared/utils/api/broadcast.helpers';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { uploadMapSpreadsheet } from '../map.repository';
import { MAXIMUM_SPREADSHEET_FILE_SIZE_MB } from '../selectSpreadsheet/withError/selectSpreadsheetWithError.component';
import { createFileMap } from './createNewMap.helpers';

export const useCreateMapFromSpreadsheet = (clientId: number | null, userId: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMountedRef = useIsComponentMountedRef();
  const [t] = useTranslation();

  const resetState = () => {
    setIsLoading(false);
    setErrorMessage('');
  };

  const closeErrorMessage = () => {
    if (isMountedRef.current) {
      setErrorMessage('');
    }
  };

  const createMapFromSpreadsheet = async (
    file: File,
    mapName: string, mapDescription: string,
    onProgressChange: (progress: number) => void,
    onStatusChange: (status: UploadStatus) => void,
    onCreateMapSuccess: (mapId: number) => void,
  ) => {
    if (!clientId) {
      return;
    }

    setIsLoading(true);
    onStatusChange(UploadStatus.InProgress);

    try {
      const uploadResponse = await uploadMapSpreadsheet(clientId, file, {
        onProgress: ((progress: number) => {
          onProgressChange(progress / 3);
        }),
      });

      const newMap = await createFileMap(clientId, uploadResponse.data.file_name, mapName, mapDescription);
      onProgressChange(67);

      const onSpreadsheetUploadProcessDone = () => {
        onProgressChange(100);
        onStatusChange(UploadStatus.Success);
        onCreateMapSuccess(newMap.data.id);
      };

      getUserSpreadsheetImportBroadcast(e => {
        if (e.userId === userId) {
          for (const spreadsheet of newMap.data.spreadsheets) {
            const spreadsheetIds = spreadsheet.real_spreadsheets.map(item => item.real_spreadsheet_id);
            if (spreadsheetIds.includes(e.spreadsheetId)) {
              onSpreadsheetUploadProcessDone();
              return;
            }
          }
        }
      }, () => {
        onSpreadsheetUploadProcessDone();
      });
    }
    catch (e) {
      let message = e?.errors?.maps_limit?.[0] ?? e.message;
      if (e.responseStatus === 413) {
        message = t('Provide a supported file (xls, xlsx, csv, tsv) no larger than {{fileSize}}MB', {
          fileSize: MAXIMUM_SPREADSHEET_FILE_SIZE_MB,
        });
      }
      if (isMountedRef.current) {
        setErrorMessage(message);
        onStatusChange(UploadStatus.Error);
        setIsLoading(false);
      }
    }
  };

  return {
    isLoading,
    resetState,
    errorMessage,
    closeErrorMessage,
    createMapFromSpreadsheet,
  };
};
