import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft, faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const headerStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: '1px solid ' + theme.lineColors.basePrimary,
  boxSizing: 'border-box',
  color: theme.textColors.secondary,
  fontSize: '14px',
  fontWeight: 500,
  height: '34px',
  lineHeight: '16px',
  padding: '8px 8px 8px 0',
});

const navigationStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '0 4px',
  '& > *': {
    marginRight: 16,
  },
});

const headerDescriptionStyle = ({ margin }: {margin: number}) => css({
  display: 'flex',
  justifyContent: 'center',
  marginRight: margin,
  width: '100%',
  height: '100%',
  flexShrink: 1,
  overflow: 'hidden',
});

const additionalInfoStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.buttonColors.primaryBackground,
  borderRadius: '4px',
  boxSizing: 'border-box',
  color: theme.buttonColors.primaryText,
  fontSize: '12px',
  fontWeight: 500,
  height: '16px',
  padding: '8px',
});

type ArrowProps = Readonly<{
  isDisabled?: boolean;
  onClick: () => void;
}>;

export type SidebarHeaderArrowsProps = Readonly<{
  left: ArrowProps;
  right: ArrowProps;
}>;

export type SidebarHeaderButtonProps = Readonly<{
  icon: IconProp;
  tooltipLabel?: string;

  onClick: () => void;
}>;

type SidebarHeaderProps = Readonly<{
  additionalInfo?: string;
  additionalInfoStyle?: SerializedStyles;
  arrowProps?: SidebarHeaderArrowsProps;
  buttonProps?: SidebarHeaderButtonProps;
  headerText?: string;
}>;

export const SidebarHeaderComponent: React.FC<SidebarHeaderProps> = props => {
  const theme = useTheme();

  const getMargin = (): number => {
    if (props.additionalInfo && !props.arrowProps) {
      return -45;
    }
    else if (props.arrowProps && !props.additionalInfo) {
      return 48;
    }
    else {
      return 0;
    }
  };

  const handleArrowClick = useCallback((arrowProps: ArrowProps) => () => {
    if (!arrowProps.isDisabled) {
      arrowProps.onClick();
    }
  }, []);

  const isEmpty = !props.arrowProps && !props.headerText && !props.additionalInfo && !props.buttonProps;

  if (isEmpty) {
    return null;
  }

  return (
    <div css={headerStyle({ theme })} >
      {props.arrowProps && (
        <div css={navigationStyle}>
          <RoundButtonComponent
            buttonStyle={props.arrowProps.left.isDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Quaternary}
            icon={faArrowLeft}
            isDisabled={!!props.arrowProps.left.isDisabled}
            onClick={handleArrowClick(props.arrowProps.left)}
            size={RoundButtonSize.Small}
          />
          <RoundButtonComponent
            buttonStyle={props.arrowProps.right.isDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Quaternary}
            icon={faArrowRight}
            isDisabled={!!props.arrowProps.right.isDisabled}
            onClick={handleArrowClick(props.arrowProps.right)}
            size={RoundButtonSize.Small}
          />
        </div>
      )}

      <span css={headerDescriptionStyle({ margin: getMargin() })} >
        {props.headerText}
      </span>
      {props.additionalInfo && (
        <div css={css(additionalInfoStyle({ theme }), props.additionalInfoStyle)} >
          {props.additionalInfo}
        </div>
      )}

      {props.buttonProps && (
        <RoundButtonComponent
          {...props.buttonProps}
          size={RoundButtonSize.Small}
          icon={props.buttonProps.icon}
          tooltipPlacement={TooltipPlacement.Left}
        />
      )}
    </div>
  );
};
