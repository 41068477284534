import { css } from '@emotion/react';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  type FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ApiErrorMessage } from '../../_shared/baseComponents/apiErrorMessage';
import { isMapNowButtonEnabled } from '../../_shared/components/dropzone/dropzone.helpers';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { ModalFooterSampleExcelComponent } from '../../_shared/components/modalFooterSampleExcel/modalFooterSampleExcel.component';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { type UploadStatus } from '../../_shared/components/uploadStatus/uploadStatus.component';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { ImportMethod } from '../../_shared/types/importMethod/importMethod';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { type ApiError } from '../../_shared/utils/api/apiError.helpers';
import { useTranslation } from '../../_shared/utils/hooks';
import { type UploadItem } from '../../map/createNew/createNewMap.component';
import { SelectSpreadsheetWithErrorComponent } from '../../map/selectSpreadsheet/withError/selectSpreadsheetWithError.component';

type ImportDataProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    importMethod: ImportMethod,
    file: File,
    onProgressChange: (progress: number) => void,
    onStatusChange: (status: UploadStatus) => void
  ) => void;
  isLoading: boolean;
  initialImportMethod?: ImportMethod;
  onReplaceImportMethodSelect: () => void;
  error?: ApiError;
  onErrorMessageClose: () => void;
}>;

const accordionContentStyle = css({
  padding: '2px 10px',
});

const noteStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  fontWeight: 500,
  lineHeight: 1.5,
  fontSize: '16px',
  marginBottom: 35,
  display: 'block',
});

const importMethodLabelStyle = css({
  fontSize: '16px',
});

const importMethodDescriptionStyle = css({
  fontSize: '14px',
});

const importMethodRadioGroupStyle = css({
  gap: 25,
  width: '100%',
});

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

export const ImportDataComponent: FC<ImportDataProps> = (props) => {
  const [importMethod, setImportMethod] = useState<ImportMethod | null>(props.initialImportMethod ?? null);
  const [uploadItems, setUploadItems] = useState<UploadItem[]>([]);
  const [isImportMethodSectionOpen, setIsImportMethodSectionOpen] = useState(true);
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();
  const { onReplaceImportMethodSelect } = props;

  const toggleMapOverviewOpen = () => {
    setIsImportMethodSectionOpen(isOpen => !isOpen);
  };

  const onMapOverviewFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsImportMethodSectionOpen(false);
    setIsDataSourceOpen(true);
  };

  const onUploadItemSelect = (file: File, onProgressChange: (progress: number) => void,
    onStatusChange: (status: UploadStatus) => void) => {
    if (importMethod === null) {
      return;
    }

    props.onSubmit(importMethod, file, onProgressChange, onStatusChange);
  };

  const ModalConfirmButton = (
    <ButtonComponent
      css={css({
        height: 40,
        padding: '0 12px',
      })}
      prefixIcon={faMap}
      text={t('Map Now')}
      onClick={() => null}
      isDisabled={!isMapNowButtonEnabled(importMethod, uploadItems)}
    />
  );

  useEffect(() => {
    if (importMethod === null) {
      setIsImportMethodSectionOpen(true);
      setIsDataSourceOpen(false);
    }
    else {
      setIsImportMethodSectionOpen(false);
      setIsDataSourceOpen(true);
    }
  }, [importMethod]);

  useEffect(() => {
    if (!props.isOpen) {
      setImportMethod(null);
      setUploadItems([]);
      setIsImportMethodSectionOpen(true);
      setIsDataSourceOpen(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (importMethod === ImportMethod.Replace && props.initialImportMethod !== ImportMethod.Replace) {
      onReplaceImportMethodSelect();
    }
  }, [importMethod, onReplaceImportMethodSelect, props.initialImportMethod]);

  const importMethodSection: AccordionData = {
    header: t('Select Import Method'),
    isExpanded: isImportMethodSectionOpen,
    isLocked: !importMethod,
    onHeadingClick: toggleMapOverviewOpen,
    child: (
      <form
        css={accordionContentStyle}
        onSubmit={onMapOverviewFormSubmit}
      >
        <RadioGroupComponent<ImportMethod>
          css={importMethodRadioGroupStyle}
          onValueChange={setImportMethod}
          selectedValue={importMethod}
          items={[{
            value: ImportMethod.Replace,
            label: <span css={importMethodLabelStyle}>{t('Import and replace existing data set.')}</span>,
            description: <span css={importMethodDescriptionStyle}>{t('note.existing.data.will.be.replaced')}</span>,
          }, {
            value: ImportMethod.Append,
            label: <span css={importMethodLabelStyle}>{t('Import and add to existing data set.')}</span>,
            description: <span css={importMethodDescriptionStyle}>{t('The data will be appended to the bottom of the existing data set.')}</span>,
          }]}
        />
      </form>
    ),
  };

  const dataSourceSection: AccordionData = {
    header: t('Select Data Source'),
    isExpanded: isDataSourceOpen,
    isLocked: true,
    child: (
      <div css={accordionContentStyle}>
        <strong css={noteStyle({ theme })}>{t('note.include.column.headers')}</strong>

        <SelectSpreadsheetWithErrorComponent
          isLoading={props.isLoading}
          error={props.error && <ApiErrorMessage error={props.error} />}
          onSelect={onUploadItemSelect}
          onErrorMessageClose={props.onErrorMessageClose}
        />
      </div>
    ),
  };

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Import Data')}
      leftFooterContent={<ModalFooterSampleExcelComponent />}
      confirmButton={ModalConfirmButton}
      contentStyle={css({ padding: 0 })}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
      isCloseDisabled={props.isLoading}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={[importMethodSection, dataSourceSection]}
          itemButtonStyle={itemButtonStyle}
          showCount
        />
      </OutlinePanelComponent>
    </ModalComponent>
  );
};
