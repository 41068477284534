import { useMemo } from 'react';
import { type SpreadsheetDescriptor } from '~/_shared/types/map';
import { type AppState } from '~/store/app.store';
import {
  mapInfoSpreadsheetsSelector, useMapInfoSpreadsheetsSelector,
} from '../mapInfo/mapInfo.selectors';

export const usePrimarySpreadsheetRealSpreadsheets = (): ReadonlyArray<{ id: number }> | null => {
  const spreadsheets = useMapInfoSpreadsheetsSelector();

  return useMemo(() => {
    return getRealSpreadsheetsForPrimary(spreadsheets);
  }, [spreadsheets]);
};

export const primarySpreadsheetRealSpreadsheetsSelector = (state: AppState) =>
  getRealSpreadsheetsForPrimary(mapInfoSpreadsheetsSelector(state));

const getRealSpreadsheetsForPrimary = (spreadsheets?: readonly SpreadsheetDescriptor[]) => {
  if (!spreadsheets) {
    return null;
  }

  const primarySpreadsheet = spreadsheets.find(item => item.isPrimary);

  return primarySpreadsheet?.realSpreadsheets?.map(realSpreadsheet => ({ id: realSpreadsheet.realSpreadSheetId }))
    ?? null;
};
