import { css } from '@emotion/react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { type AutoClosableModalProps } from '~/modal/modalType.enum';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';

const modalContentStyle = css({
  padding: '20px 20px',
});

const instructionsStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  fontSize: '16px',
  lineHeight: 1.5,
});

const optionButtonStyle = css({
  marginTop: 16,
  width: '100%',
});

export type OptionsModalContainerOptionsProps = {
  isDestructive?: boolean;
  isDisabled?: boolean;
  name: string;
  onChosen: () => void;
};

export type OptionsModalComponentProps = AutoClosableModalProps<{
  title: string;
  instructions?: string;
  options: OptionsModalContainerOptionsProps[];
  closeModalOnOptionSelect?: boolean;
  isLoading?: boolean;
  width?: number;
}>;

export const OptionsModalComponent = ({
  allowedPages,
  closeModalOnOptionSelect = true,
  instructions,
  isLoading,
  isOpen,
  onClose,
  options,
  skipCloseOnMapIdChange,
  title,
  width,
  ...restProps
}: OptionsModalComponentProps) => {
  const theme = useTheme();

  const chooseOption = (onChosen: () => void) => {
    onChosen();
    if (closeModalOnOptionSelect) {
      onClose();
    }
  };

  const optionElements = options.map((option, index) => (
    <ButtonComponent
      key={index}
      buttonStyle={option.isDestructive ? ButtonStyle.Danger : ButtonStyle.Primary}
      text={option.name}
      css={optionButtonStyle}
      onClick={() => chooseOption(option.onChosen)}
      isDisabled={option.isDisabled}
    />
  ));

  return (
    <ModalComponent
      {...restProps}
      allowedPages={allowedPages}
      caption={title}
      isOpen={isOpen}
      maxWidth={width ?? 500}
      onClose={onClose}
      skipCloseOnMapIdChange={skipCloseOnMapIdChange}
      additionalContent={isLoading ? (
        <OverlayLoaderComponent />
      ) : null}
    >
      <div css={modalContentStyle}>
        {instructions && <p css={instructionsStyle({ theme })}>{instructions}</p>}
        {optionElements}
      </div>
    </ModalComponent>
  );
};
