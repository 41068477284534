import {
  type FC,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { routeUiDataLoaded } from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import { emptyRouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import { selectRoutesWithVisibleMarkers } from '~/store/mapSettings/directions/selectRoutesWithVisibleMarkers';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { useFindDirections } from '../../../directions/useFindDirections.hook';
import { useMapSettingsDirectionsSelectedCollapsingSelector } from '../../../store/mapSettings/directions/mapSettingsDirections.selectors';
import { useMapContext } from '../mapContext';
import { DirectionsRouteInstanceContainer } from './directionsRouteInstance.container';
import { MapDirectionsManager } from './mapDirectionsManager';

export const DirectionsOverlayContainer: FC = () => {
  const dispatch = useDispatch();
  const routes = useSelector(selectRoutesWithVisibleMarkers);
  const routesUiData = useSelector(s => s.frontendState.mapTools.directions.routesUiData);
  const routeCollapsingLevel = useMapSettingsDirectionsSelectedCollapsingSelector();

  const { map, webglLayers } = useMapContext();
  const manager = useMemo(() => new MapDirectionsManager(map, webglLayers), [map, webglLayers]);

  const { findDirections } = useFindDirections();

  useEffect(() => routes
    .filter(r => !routesUiData.has(r.id))
    .forEach(route => {
      findDirections(route.waypoints, false, false)
        .then(({ success }) => {
          if (success) {
            dispatch(routeUiDataLoaded(
              route.id,
              {
                ...emptyRouteUiData,
                legs: success.legs.map(l => l.data),
                copyrights: success.copyrights,
                apiResponses: success.apiResponses,
              },
              routeCollapsingLevel
            ));
          }
        });
    }),
  [routes, routesUiData, dispatch, findDirections, routeCollapsingLevel]);

  return (
    <>
      {routes.map(r => (
        <DirectionsRouteInstanceContainer
          key={r.id}
          manager={manager}
          route={r}
          routeUiData={routesUiData.get(r.id)}
        />
      ))}
    </>
  );
};
