import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  type ActiveGroupFilters, type GroupingColumn, type GroupingColumnNumeric, type GroupingColumnValues, GroupingType,
  type NumericActiveGroupColumnType,
} from '~/_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '~/_shared/types/grouping/numericActiveGroupColumnType.helpers';
import type { MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import type { SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { changeColorAlpha } from '~/_shared/utils/colors/colors.helpers';
import { getActiveGroupFiltersFromFiltersState } from '~/_shared/utils/grouping/grouping.helpers';
import {
  createNumericBucketKey, getHierarchyIndicatorIndex,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import {
  DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB, DEFAULT_NUMERIC_ROUND_MARKER_OPACITY,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import { spreadsheetColumnIdToString } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { GroupingPanelComponent } from '~/grouping/groupingPanel.component';
import type { NumericalGroupSettingsDataRow } from '~/grouping/settings/numeric/numericalGroupSettingsNumeric.component';
import type { NumericalGroupSettingsInitial } from '~/grouping/settings/numericalGroupSettings.component';
import {
  type GroupChangeRequest, useGroupColumnData,
} from '~/grouping/useGroupColumnData';
import { useNumericalGroupSettings } from '~/grouping/useNumericalGroupSettings';
import { usePresentationalColumnsRestrictionsMatchup } from '~/presentationalColumnsRestrictions/usePresentationalColumnsRestrictionsMatchup';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  useIsMapSettingsSyncInProgressSelector, useIsSpreadsheetDataFetchingInProgressSelector,
} from '~/store/frontendState/processing/processing.selectors';
import { mapSettingsColumnsFilterSetGroup } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilter.actionCreators';
import { useMapSettingsColumnsFilterSelector } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';
import {
  mapSettingsGroupingRemoveActiveGroupColumn, mapSettingsGroupingSetActiveGroupColumns,
} from '~/store/mapSettings/grouping/mapSettingsGrouping.actionCreators';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { setNumericGroupMarkerSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { usePerGroupVisualSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import {
  checkIfIsNumericalColumn, getGroupingColumnsUniqueGroups, getNumericalGroupBuckets, getNumericGroupData,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  emptySpreadsheetData, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';

type GroupingToolContainerProps = Readonly<{
  areGroupSettingsVisible: boolean;
}>;

export const GroupingToolContainer: FC<GroupingToolContainerProps> = props => {
  const dispatch = useDispatch();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const isSpreadsheetDataLoading = useIsSpreadsheetDataFetchingInProgressSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isScreenSizeMobile = useIsMobileScreenSelector();
  const activeColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const perGroupVisualSettings = usePerGroupVisualSettings();
  const matchupData = useMatchupDataSelector();
  const mapId = useMapIdSelector();
  const spreadSheetColumns = useSpreadsheetColumns();
  const { isLoading, getGroupingColumnData } = useGroupColumnData();
  const { openNumericalGroupSettingsModal } = useNumericalGroupSettings();
  const restrictionsMatchup = usePresentationalColumnsRestrictionsMatchup();

  const activeFilter = useMemo<ActiveGroupFilters>(() => {
    return getActiveGroupFiltersFromFiltersState(columnsFilter);
  }, [columnsFilter]);

  const unrestrictedColumns = useMemo(() => {
    if (!isMapPresentational) {
      return spreadSheetColumns;
    }

    return spreadSheetColumns.filter(column => {
      const spreadsheetColumnId: SpreadsheetColumnId = {
        columnId: column.id,
        spreadsheetId: column.spreadsheetId,
      };
      const restrictions = restrictionsMatchup.get(spreadsheetColumnIdToString(spreadsheetColumnId));

      return !restrictions?.master && !restrictions?.filter;
    });
  }, [isMapPresentational, restrictionsMatchup, spreadSheetColumns]);

  const determineIsSameColumn = useCallback((column1: GroupingColumn, column2: GroupingColumn) =>
    column1.spreadsheetId === column2.spreadsheetId && column1.columnId === column2.columnId,
  []);

  const replaceOrAddActiveGroupColumn = useCallback((newGroupingColumn: GroupingColumn, columnToReplace: GroupingColumn | null) => {
    const newActiveGroupColumns = columnToReplace && activeColumns.find(c => determineIsSameColumn(c, columnToReplace))
      ? activeColumns.map(c => determineIsSameColumn(c, columnToReplace) ? newGroupingColumn : c)
      : [...activeColumns, newGroupingColumn];

    dispatch(mapSettingsGroupingSetActiveGroupColumns(newActiveGroupColumns, activeColumns));
    return newActiveGroupColumns;
  }, [activeColumns, determineIsSameColumn, dispatch]);

  const getBucketKey = useCallback((data: {
    columnId: SpreadsheetColumnId;
    numberOfBuckets: number;
    numericGroupType: NumericActiveGroupColumnType | null;
  }): [string | null, number] => {
    const hierarchyIndicator = getHierarchyIndicatorIndex(activeColumns, data.columnId);
    const newActiveColumns = activeColumns.map(column => ({
      spreadsheetId: column.spreadsheetId,
      columnId: column.columnId,
    }));
    newActiveColumns[hierarchyIndicator] = data.columnId;

    let bucketLocationString = null;
    if (data.numericGroupType) {
      bucketLocationString = createNumericBucketKey(data.numericGroupType, hierarchyIndicator, data.numberOfBuckets);
    }

    return [bucketLocationString, hierarchyIndicator];
  }, [activeColumns]);

  const removeActiveGroupColumn = useMemo(() =>
    mapId ? (column: GroupingColumn) => dispatch(mapSettingsGroupingRemoveActiveGroupColumn(column, mapId)) : undefined,
  [dispatch, mapId]);

  const saveGroupMarkers = useCallback((
    markers: MarkerSettings[],
    spreadsheetColumn: SpreadsheetColumnId,
    newActiveColumns: GroupingColumn[],
  ) => {
    const numericActiveGroupColumnType = getNumericActiveGroupColumnType(newActiveColumns);

    const [bucketLocationString] = getBucketKey({
      columnId: spreadsheetColumn, numberOfBuckets: markers.length - 1, numericGroupType: numericActiveGroupColumnType,
    });

    if (markers.length > 0 && numericActiveGroupColumnType && bucketLocationString) {
      dispatch(setNumericGroupMarkerSettings({
        spreadsheetId: spreadsheetColumn.spreadsheetId,
        columnId: spreadsheetColumn.columnId,
        bucketKey: bucketLocationString,
        settings: markers,
      }));
    }
  }, [dispatch, getBucketKey]);

  const addGroupingColumn = useCallback(async (newColumn: GroupingColumn) => {
    const promisedResult = await getGroupingColumnData(newColumn, null);

    if (!promisedResult) {
      return;
    }

    const [newGroupingColumn, markerSettings] = promisedResult;

    const newActiveColumns = replaceOrAddActiveGroupColumn(newGroupingColumn, null);
    saveGroupMarkers(markerSettings, newColumn, newActiveColumns);
  }, [getGroupingColumnData, replaceOrAddActiveGroupColumn, saveGroupMarkers]);

  const openNumericGroupColumnSettings = useCallback(async (column: GroupingColumnNumeric) => {
    const numericData = getNumericGroupData(spreadsheetData ?? emptySpreadsheetData, column.spreadsheetId,
      Unfiltered, column.columnId,
    );

    if (!numericData || !checkIfIsNumericalColumn(numericData.extra.min, numericData.extra.max)) {
      return;
    }

    const uniqueGroupColumns = getGroupingColumnsUniqueGroups(
      spreadsheetData ?? emptySpreadsheetData,
      matchupData,
      column,
    );

    const numericActiveGroupColumnType = getNumericActiveGroupColumnType(activeColumns);

    // we don't include NON-NUMERICAL bucket here as it's being added automatically in GroupingToolComponent
    const numericalBuckets = getNumericalGroupBuckets(column, numericData.extra.min, numericData.extra.max, false);

    const [bucketLocationString] = getBucketKey({
      columnId: column, numberOfBuckets: uniqueGroupColumns.length, numericGroupType: numericActiveGroupColumnType,
    });

    if (!bucketLocationString) {
      return;
    }

    const groupMarkers = numericActiveGroupColumnType &&
      perGroupVisualSettings[column.spreadsheetId]?.[column.columnId]?.[GroupingType.Numeric]?.[bucketLocationString];

    const dataRows: NumericalGroupSettingsDataRow[] = numericalBuckets.map((bucket, index) => {
      let color = createColor(DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB);
      const markerSelectedColor = groupMarkers?.[index]?.marker?.selectedColor;
      if (markerSelectedColor) {
        const selectedColor = createColor(markerSelectedColor);
        color = changeColorAlpha(
          selectedColor.rgb,
          groupMarkers?.[index]?.marker?.opacity ?? DEFAULT_NUMERIC_ROUND_MARKER_OPACITY,
        );
      }

      return {
        fromValue: bucket.range?.from?.toString() ?? '0',
        toValue: bucket.range?.to?.toString() ?? '0',
        isValid: true,
        color,
      };
    });

    const initialSettings: NumericalGroupSettingsInitial = {
      valueType: column.valueType,
      numberOfGroups: column.buckets.length + 1,
      dataRows,
    };

    try {
      const { newNumericalColumn, markerSettings } = await openNumericalGroupSettingsModal({
        numericColumn: column,
        numericData,
        groupingColumns: activeColumns,
        initialSettings,
      });

      const newActiveColumns = replaceOrAddActiveGroupColumn(newNumericalColumn, column);

      saveGroupMarkers(markerSettings, column, newActiveColumns);
    }
    catch (e) {
      return;
    }
  }, [activeColumns,
    getBucketKey,
    matchupData,
    openNumericalGroupSettingsModal,
    perGroupVisualSettings,
    replaceOrAddActiveGroupColumn,
    saveGroupMarkers,
    spreadsheetData]);

  const changeGroupingColumn = useCallback(async (newColumn: GroupChangeRequest, columnToReplace: GroupingColumn) => {
    const promisedResult = await getGroupingColumnData(newColumn, columnToReplace);

    if (!promisedResult) {
      return;
    }

    const [newGroupingColumn, markers] = promisedResult;

    const newActiveColumns = replaceOrAddActiveGroupColumn(newGroupingColumn, columnToReplace);
    saveGroupMarkers(markers, newColumn, newActiveColumns);
  }, [getGroupingColumnData, replaceOrAddActiveGroupColumn, saveGroupMarkers]);

  const onFilterChange = useCallback((spreadsheetColumnId: SpreadsheetColumnId, groups: GroupingColumnValues<1>) =>
    dispatch(mapSettingsColumnsFilterSetGroup(spreadsheetColumnId, groups)),
  [dispatch]);

  if (!spreadsheetData) {
    return null;
  }

  return (
    <GroupingPanelComponent
      columns={unrestrictedColumns}
      activeColumns={activeColumns}
      areGroupSettingsVisible={props.areGroupSettingsVisible}
      filterValues={activeFilter}
      onFilterChange={onFilterChange}
      isGroupEditingDisabled={isMapPresentational || isScreenSizeMobile}
      isLoading={isLoading || isSpreadsheetDataLoading || isMapSettingsSyncInProgress}
      onGroupColumnAdd={addGroupingColumn}
      onGroupColumnChange={changeGroupingColumn}
      onGroupColumnRemove={removeActiveGroupColumn}
      onGroupColumnNumericSettingsOpen={openNumericGroupColumnSettings}
    />
  );
};
