import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIsMapSettingsSyncInProgressSelector } from '../../store/frontendState/processing/processing.selectors';
import { mapSettingsRemoveActiveItems } from '../../store/mapSettings/data/mapSettingsData.actionCreators';
import { useMapStatusBarItems } from './hooks/useMapStatusBarItems';
import { MapStatusBarComponent } from './mapStatusBar.component';

export const MapStatusBarContainer: FC = () => {
  const { items, isAnyRemovableItem } = useMapStatusBarItems();
  const isLoading = useIsMapSettingsSyncInProgressSelector();
  const dispatch = useDispatch();

  const onDeleteAllClick = () => {
    dispatch(mapSettingsRemoveActiveItems());
  };

  if (!isAnyRemovableItem) {
    return null;
  }

  return (
    <MapStatusBarComponent
      items={items}
      onDeleteAllClick={onDeleteAllClick}
      isLoading={isLoading}
    />
  );
};
