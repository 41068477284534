import { createAction } from '@reduxjs/toolkit';

export const boundaryTerritoryGroupInStatusBarClicked = createAction('BOUNDARY_TERRITORY_GROUP_IN_STATUSBAR_CLICKED',
  (boundaryGroupId: number) => ({
    payload: {
      boundaryGroupId,
    },
  }),
);
export const boundaryTerritoryGroupInSidebarHighlighted = createAction('BOUNDARY_TERRITORY_GROUP_IN_SIDEBAR_HIGHLIGHTED');
