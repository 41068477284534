import { type HeatMapItem } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { convertColorToWebGLColor } from '../../../_shared/utils/colors/colors.helpers';

type RenderedHeatmap = Readonly<{
  overlayHeatmap: WebglOverlayHeatmap;
}>;
type HeatMapLayer = Map<string, RenderedHeatmap>;

type HeatMapPoints = ReadonlyArray<HeatmapPoint>;

export class HeatMapsManager {
  private readonly heatmapsLayer: HeatmapLayer;
  private heatMapLayers: HeatMapLayer = new Map();

  constructor(heatmapsLayer: HeatmapLayer) {
    this.heatmapsLayer = heatmapsLayer;
  }

  destroy() {
    for (const layer of this.heatMapLayers.values()) {
      this.heatmapsLayer.remove(layer.overlayHeatmap);
    }
    this.heatMapLayers = new Map();
  }

  removeHeatmap(heatmapId: string) {
    const layer = this.heatMapLayers.get(heatmapId);
    if (!layer) {
      return;
    }
    this.heatmapsLayer.remove(layer.overlayHeatmap);
    this.heatMapLayers.delete(heatmapId);
  }

  createOrUpdateHeatmapItem(item: HeatMapItem, points: HeatMapPoints, zIndex: number) {
    let existing = this.heatMapLayers.get(item.heatmap.id);

    // workround for https://app.asana.com/0/1186320334640388/1200141506004053
    if (existing?.overlayHeatmap?.points !== points) {
      this.removeHeatmap(item.heatmap.id);
      existing = undefined;
    }

    if (existing) {
      this.updateHeatmapItem(item, points, zIndex);
    }
    else {
      this.createHeatmapItem(item, points, zIndex);
    }
  }

  private updateHeatmapItem(item: HeatMapItem, points: HeatMapPoints, zIndex: number) {
    const { heatmap } = item;
    const rendered = this.heatMapLayers.get(heatmap.id);
    if (!rendered) {
      return;
    }

    const overlayHeatmap = rendered.overlayHeatmap;

    const color1 = getFirstColorForHeatmap(heatmap.isGradient, heatmap.lightColor, heatmap.singleColor);
    const color2 = convertColorToWebGLColor(heatmap.mediumColor);
    const color3 = convertColorToWebGLColor(heatmap.denseColor);

    overlayHeatmap.color1.set(...color1);
    overlayHeatmap.color2.set(...color2);
    overlayHeatmap.color3.set(...color3);

    overlayHeatmap.singleColor = !heatmap.isGradient;
    overlayHeatmap.opacity = heatmap.opacity / 100;
    overlayHeatmap.radius = getNormalizedRadius(heatmap.radius);
    overlayHeatmap.dissipate = heatmap.dissipate;
    overlayHeatmap.points = points;
    overlayHeatmap.maxIntensity = getMaxIntensityForHeatmapFromThreshold(heatmap.threshold);
    overlayHeatmap.visible = item.otherOptions.visible;
    overlayHeatmap.zIndex = zIndex;
  }

  private createHeatmapItem(item: HeatMapItem, points: HeatMapPoints, zIndex: number) {
    if (this.heatMapLayers.has(item.heatmap.id)) {
      return;
    }

    const overlayHeatmap = createOverlayHeatmap(item, points, zIndex);

    this.heatmapsLayer.add(overlayHeatmap);
    this.heatMapLayers.set(item.heatmap.id, { overlayHeatmap });
  }
}

const createOverlayHeatmap = (item: HeatMapItem, points: HeatMapPoints, zIndex: number) => {
  const { heatmap } = item;

  return new WebGLOverlay.Heatmap({
    points,
    dissipate: heatmap.dissipate,
    radius: getNormalizedRadius(heatmap.radius),
    opacity: heatmap.opacity / 100,
    maxIntensity: getMaxIntensityForHeatmapFromThreshold(heatmap.threshold),
    singleColor: !heatmap.isGradient,
    visible: item.otherOptions.visible,
    color1: getFirstColorForHeatmap(heatmap.isGradient, heatmap.lightColor, heatmap.singleColor),
    color2: convertColorToWebGLColor(heatmap.mediumColor),
    color3: convertColorToWebGLColor(heatmap.denseColor),
    zIndex,
  });
};

const getMaxIntensityForHeatmapFromThreshold = (threshold: number): number => (threshold * 0.004 + 0.025);
const getFirstColorForHeatmap = (isGradient: boolean, lightColor: string, singleColor: string): WebglColor => (
  convertColorToWebGLColor(isGradient ? lightColor : singleColor)
);
const getNormalizedRadius = (radius: number): number => Math.round((radius / 125) * 100);
