import { type Action } from 'redux';
import {
  buffers, type TakeableChannel,
} from 'redux-saga';
import {
  actionChannel, call, delay, put,
  race, take, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { type BoundaryItemsAction } from '~/store/boundaryItems/boundaryItems.action';
import { BOUNDARY_ITEMS_UPDATE_ITEM } from '~/store/boundaryItems/boundaryItems.actionTypes';
import { boundaryTerritoryGroupUpdateSuccess } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { boundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '~/store/mapSettings/data/mapSettingsData.actionTypes';
import { setBoundaryFilter } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import {
  type BoundaryFilters,
  boundaryFiltersSelector,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { isMapInOfflineModeSelector } from '~/store/selectors/useMapInfoSelectors';
import { createUuid } from '../../_shared/utils/createUuid';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { isBoundaryTerritoryEmpty } from '../../sidebar/sidebarApps/mapTools/boundary/fill/boundaryFill.helpers';
import { type BoundaryTerritoryGroupId } from '../boundaries/boundaryIdentifier.type';
import { type BoundaryTerritoryGroupsAction } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import {
  BOUNDARY_TERRITORY_CHANGE_NAME,
  BOUNDARY_TERRITORY_DELETE,
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { updateBoundaryTerritoryGroup } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { type BoundaryTerritoryGroup } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { waitForMapSettingsReady } from '../mapSettings/ready/mapSettingsReady.sagas.helpers';
import { type FilterTreeItemRequest } from '../spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import { getStoreFilterTree } from '../spreadsheetData/filterTree/spreadsheetDataFilterTree.helpers';
import { type BoundaryTerritoryDetailsAction } from './boundaryTerritoryDetails.action';
import {
  boundaryTerritoryDetailsUpdateManualAssignments,
  boundaryTerritoryGroupsFetchDetailsError,
  boundaryTerritoryGroupsFetchDetailsRequest,
  boundaryTerritoryGroupsFetchDetailsSuccess,
} from './boundaryTerritoryDetails.actionCreators';
import {
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST,
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS,
} from './boundaryTerritoryDetails.actionTypes';
import {
  type BoundaryTerritoryGroupDetailsRequestQueryModel,
  getBoundaryTerritoryGroupDetails,
} from './boundaryTerritoryDetails.repository';
import {
  type BoundaryTerritoryAssignment,
  boundaryTerritoryAssignmentsSelector,
  boundaryTerritoryDetailsFilterHashSelector,
} from './boundaryTerritoryDetails.selectors';
import {
  type BoundaryTerritoryGroupDetails, type BoundaryTerritoryGroupDetailsItem,
} from './boundaryTerritoryGroups.state';

export function* boundaryTerritoryDetailsSagas() {
  yield takeLatest([
    BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  ], requestBoundaryTerritoryGroupsDetails);
  yield takeEvery(BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST, getBoundaryTerritoryGroupsDetails);
  yield takeEvery([
    BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS,
    BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  ], onBoundaryTerritoryGroupUpdate);
  yield takeEvery(BOUNDARY_ITEMS_UPDATE_ITEM, onBoundaryItemsUpdate);
  yield takeLatest([
    BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY,
    BOUNDARY_TERRITORY_CHANGE_NAME,
    BOUNDARY_TERRITORY_DELETE,
  ], onAddCustomBoundaryTerritory);
  yield takeEvery(BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS, onBoundaryTerritoryGroupUpdateAssignmentsSuccess);
  yield takeLatest(BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS, verifyFilteredBoundaryTerritories);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, watchMapSettingsFilteringChanges);
  yield takeLatest(BOUNDARY_TERRITORY_DELETE, onBoundaryTerritoryDelete);
}

function* verifyFilteredBoundaryTerritories() {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> =
    yield select<ReadonlyArray<BoundaryTerritoryGroup>>(state => state.map.boundaryTerritoryGroups.groups);

  const boundaryTerritoryAssignments: ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment> =
    yield select<ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment>>(
      state => boundaryTerritoryAssignmentsSelector(state)
    );

  const filters: BoundaryFilters = yield select<BoundaryFilters>(boundaryFiltersSelector);

  for (const group of boundaryTerritoryGroups) {
    const emptyBoundaryTerritories: string[] = [];
    for (const territory of group.settings.boundaryTerritories) {
      if (isBoundaryTerritoryEmpty(boundaryTerritoryAssignments, group.boundaryTerritoryGroupId, territory)) {
        emptyBoundaryTerritories.push(territory.boundaryTerritoryId);
      }
    }

    const filter = filters.get(group.boundaryGroupId);

    // empty boundary territories cannot be filtered
    const newFilteredBoundaryTerritories = filter.filteredBoundaryTerritories
      .filter(id => !emptyBoundaryTerritories.includes(id));

    if (newFilteredBoundaryTerritories.length < filter.filteredBoundaryTerritories.length) {
      yield put(setBoundaryFilter(group.boundaryGroupId, {
        ...filter,
        filteredBoundaryTerritories: newFilteredBoundaryTerritories,
        showAll: newFilteredBoundaryTerritories.length === 0 ? TriStateRange.Full : filter.showAll,
      }));
    }
  }
}

function* watchMapSettingsFilteringChanges() {
  let isMapFirstLoad = true;

  // Buffer latest map settings action when the saga is busy and not taking actions.
  const mapSettingsChannel: TakeableChannel<Action> = yield actionChannel(
    (action: Action) => action.type.startsWith('MAP_SETTINGS'), buffers.sliding(1));

  while (true) {
    yield call(waitForMapSettingsReady);

    if (isMapFirstLoad) {
      isMapFirstLoad = false;
      yield requestBoundaryTerritoryGroupsDetails();
    }

    yield take(mapSettingsChannel);

    while (true) {
      const { timeout } = yield race({
        timeout: delay(1000),
        latestAction: take(mapSettingsChannel),
      });

      if (timeout) {
        break;
      }
    }

    const combinedFilterTrees: FilterTreeItemRequest | null = yield getStoreFilterTree();
    const currentDetailsFilterHash: string = yield select<string>(boundaryTerritoryDetailsFilterHashSelector);

    if (currentDetailsFilterHash === (combinedFilterTrees?.filterHash ?? '')) {
      continue;
    }

    yield requestBoundaryTerritoryGroupsDetails();
  }
}

// update details for boundary territory group if update took place
function* onBoundaryTerritoryGroupUpdate(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS> |
  PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS>
) {
  if (action.type === BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS) {
    if (!action.payload.fetchBoundaryDetails) {
      return;
    }
  }

  yield put(boundaryTerritoryGroupsFetchDetailsRequest([action.payload.boundaryTerritoryGroup]));
}

// update details of all affected boundary territory groups if boundary group item has been updated
function* onBoundaryItemsUpdate(action: PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_UPDATE_ITEM>) {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);
  const boundaryTerritoryGroupsToFetch = boundaryTerritoryGroups.filter(btg => btg.boundaryGroupId === action.payload.boundaryGroupId);

  yield put(boundaryTerritoryGroupsFetchDetailsRequest(boundaryTerritoryGroupsToFetch));
}

function* requestBoundaryTerritoryGroupsDetails() {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    state => state.map.boundaryTerritoryGroups.groups
  );
  const isMapSettingsReady: boolean = yield select<boolean>(state => state.map.mapSettings.isReady);

  if (boundaryTerritoryGroups.length && isMapSettingsReady) {
    yield put(boundaryTerritoryGroupsFetchDetailsRequest(boundaryTerritoryGroups));
  }
}

function* getBoundaryTerritoryGroupsDetails(
  action: PickAction<BoundaryTerritoryDetailsAction, typeof BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

  if (clientId === null || action.payload.boundaryTerritoryGroups.length === 0) {
    return;
  }

  const territoryGroupsWithQueryId: Map<string, BoundaryTerritoryGroup> = new Map();

  action.payload.boundaryTerritoryGroups.forEach(territoryGroup => {
    const requestQueryId = createUuid();
    territoryGroupsWithQueryId.set(requestQueryId, territoryGroup);
  });

  let combinedFilterTrees: FilterTreeItemRequest | null = null;
  try {
    combinedFilterTrees = yield getStoreFilterTree();
  }
  catch (e) {
    return;
  }

  try {
    const requests: BoundaryTerritoryGroupDetailsRequestQueryModel[] = [];
    territoryGroupsWithQueryId.forEach((btg, queryId) => {
      requests.push({
        boundaryTerritoryGroup: btg,
        boundaryTerritoryGroupQueryId: queryId,
        filter: btg.settings.ignoreFilters || !combinedFilterTrees?.filterTree ? null : {
          filter_tree: combinedFilterTrees.filterTree,
        },
      });
    });

    const response: Map<string, BoundaryTerritoryGroupDetails> = yield call(
      getBoundaryTerritoryGroupDetails, clientId, requests
    );

    const actionResults = [];

    for (const [queryId, details] of response.entries()) {
      const territoryGroup = territoryGroupsWithQueryId.get(queryId);

      if (!territoryGroup) {
        return;
      }

      actionResults.push({
        territoryGroupId: territoryGroup.boundaryTerritoryGroupId,
        details: details.boundaryDetails,
      });
    }

    yield put(boundaryTerritoryGroupsFetchDetailsSuccess(actionResults, combinedFilterTrees?.filterHash ?? ''));
  }
  catch (e) {
    yield put(boundaryTerritoryGroupsFetchDetailsError());
  }
}

function* onBoundaryTerritoryDelete(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_DELETE>
) {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> =
    yield select<ReadonlyArray<BoundaryTerritoryGroup>>(state => state.map.boundaryTerritoryGroups.groups);

  const boundaryTerritoryGroup = boundaryTerritoryGroups
    .find(group => group.boundaryTerritoryGroupId === action.payload.boundaryTerritoryGroupId);

  if (!boundaryTerritoryGroup) {
    return;
  }

  yield put(boundaryTerritoryGroupsFetchDetailsRequest([boundaryTerritoryGroup]));
}

function* onBoundaryTerritoryGroupUpdateAssignmentsSuccess(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS>
) {
  const isMapOffline: boolean = yield select<boolean>(isMapInOfflineModeSelector);
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> =
    yield select<ReadonlyArray<BoundaryTerritoryGroup>>(state => state.map.boundaryTerritoryGroups.groups);

  if (!isMapOffline) {
    const boundaryTerritoryGroup = boundaryTerritoryGroups
      .find(group => group.boundaryTerritoryGroupId === action.payload.boundaryTerritoryGroupId);

    if (boundaryTerritoryGroup) {
      yield put(boundaryTerritoryGroupsFetchDetailsRequest([boundaryTerritoryGroup]));
    }
  }
  else {
    const boundaryTerritoriesDetails: ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryGroupDetailsItem> =
      yield select<ReadonlyMap<number, BoundaryTerritoryGroupDetailsItem>>(
        state => state.map.boundaryTerritoryDetails.territoryDetails.data
      );

    const boundaryTerritoryDetails = boundaryTerritoriesDetails.get(action.payload.boundaryTerritoryGroupId);
    if (!boundaryTerritoryDetails) {
      return;
    }

    const newManualAssignments = { ...boundaryTerritoryDetails.manualAssignments };

    for (const request of action.payload.requests) {
      const setBoundaryIds = request.set?.map(item => item.boundary_id) ?? [];
      const removeBoundaryIds = request.remove ?? [];
      let newTerritoryAssignments = newManualAssignments[request.boundary_territory_id] ?? [];
      newTerritoryAssignments = newTerritoryAssignments.filter(boundaryId => !removeBoundaryIds.includes(boundaryId) && !setBoundaryIds.includes(boundaryId));
      newTerritoryAssignments = newTerritoryAssignments.concat(setBoundaryIds);
      newManualAssignments[request.boundary_territory_id] = newTerritoryAssignments;
    }

    yield put(boundaryTerritoryDetailsUpdateManualAssignments(action.payload.boundaryTerritoryGroupId, newManualAssignments));
  }
}

function* onAddCustomBoundaryTerritory(
  action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY>
) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const updatedBoundaryTerritoryGroupId = action.payload.boundaryTerritoryGroupId;
  const updatedState: BoundaryTerritoryGroup | undefined = yield select(
    state => state.map.boundaryTerritoryGroups.groups
      .find(group => group.boundaryTerritoryGroupId === updatedBoundaryTerritoryGroupId)
  );

  if (!updatedState || !clientId) {
    return;
  }
  const payload = createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup(updatedState);

  const newTerritoryGroup: BoundaryTerritoryGroup = yield call(updateBoundaryTerritoryGroup, clientId, updatedBoundaryTerritoryGroupId, payload);
  yield put(boundaryTerritoryGroupUpdateSuccess(newTerritoryGroup, { fetchBoundaryDetails: false }));

  action.payload.onSuccess?.(action.payload.newTerritoryId);
}
