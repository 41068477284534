import type { MapSettingsPlacesAction } from './mapSettingsPlaces.action';
import {
  mapSettingsPlacesAddSearch, mapSettingsPlacesRemoveSearch,
} from './mapSettingsPlaces.actionCreators';
import type { MapSettingsPlacesState } from './mapSettingsPlaces.state';

export const mapSettingsPlacesInitialState: MapSettingsPlacesState = {
  searches: [],
};

export const mapSettingsPlacesReducer = (
  state = mapSettingsPlacesInitialState,
  action: MapSettingsPlacesAction
): MapSettingsPlacesState => {
  if (mapSettingsPlacesAddSearch.match(action)) {
    return {
      ...state,
      searches: [...state.searches, action.payload.newSearch],
    };
  }

  if (mapSettingsPlacesRemoveSearch.match(action)) {
    return {
      ...state,
      searches: state.searches.filter((search) => !action.payload.ids.has(search.id)),
    };
  }

  return state;
};
