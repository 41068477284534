import {
  css, Global,
} from '@emotion/react';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD } from '~/_shared/baseComponents/buttons';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { isSafari } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { frontedStateProcessingGoogleScriptInitialized } from '~/store/frontendState/processing/processing.actionCreators';
import { useTheme } from '../../themes/theme.hooks';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import {
  type GoogleMapInstance, useGetGoogleMapInstance,
} from './useGetGoogleMapInstance.hook';

export const MAIN_GOOGLE_MAP_ID = 'MAIN_GOOGLE_MAP_ID';
export const DEFAULT_GOOGLE_MAPS_SETTINGS = {
  center: { lat: 40, lng: 0 },
  zoom: 3,
};

const wrapperStyle = ({ theme }: ThemeProps) => css({
  height: '100%',
  width: '100%',
  // following styles are for the street view pegman, this is undocumented in google maps api, but the only way
  '& .gm-svpc': {
    backgroundColor: `${theme.backgroundColors.quinary} !important`,
    borderRadius: '8px  !important',
    borderWidth: '0 !important',
    boxShadow: `0 0 0 1px ${theme.borderColors.primary} !important`,
    height: '36px !important',
    width: '36px !important',
  },
  '& .gm-svpc:hover': {
    backgroundColor: `${theme.iconColors.backgroundQuaternaryHover} !important`,
    border: `2px solid ${theme.borderColors.activeItem} !important`,
    boxShadow: `inset ${theme.backgroundColors.secondary} 0px 0px 6px ${SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD}px !important`,
    boxSizing: 'border-box',
  },
});

type GoogleMaComponentProps = Readonly<{
  onMapInitialized: (map: GoogleMapInstance) => void;
}>;

export const GoogleMapComponent: FC<GoogleMaComponentProps> = (props) => {
  const { onMapInitialized } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const [isProjectionInitialized, setIsProjectionInitialized] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const isMountedRef = useIsComponentMountedRef();
  const [mapContainer, setMapContainerRef] = useHookWithRefCallback<HTMLDivElement>();

  const instance = useGetGoogleMapInstance(MAIN_GOOGLE_MAP_ID);

  const onProjectionChange = useCallback(() => {
    if (isMountedRef && instance?.container?.isConnected) {
      setIsProjectionInitialized(true);
    }
  }, [instance?.container?.isConnected, isMountedRef]);

  useEffect(() => {
    if (!isMountedRef || isInitialized || !mapContainer || !instance?.instance) {
      return;
    }

    google.maps.event.addListenerOnce(instance.instance, 'idle', () => {
      if (instance?.instance && !instance.instance.getProjection()) {
        google.maps.event.addListenerOnce(instance.instance, 'projection_changed', () => {
          if (isMountedRef) {
            onProjectionChange();
          }
        });
      }
      else {
        if (isMountedRef) {
          onProjectionChange();
        }
      }
    });
    mapContainer.appendChild(instance.container);
    google.maps.event.trigger(instance.instance, 'idle');

    dispatch(frontedStateProcessingGoogleScriptInitialized());
    setIsInitialized(true);

    return () => {
      setIsInitialized(false);
      setIsProjectionInitialized(false);
      mapContainer.removeChild(instance.container);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainer, onMapInitialized, dispatch, isMountedRef, instance]);

  useEffect(() => {
    if (isMountedRef && isProjectionInitialized && isInitialized && instance?.instance) {
      onMapInitialized(instance);
    }
  }, [onMapInitialized, isInitialized, isProjectionInitialized, instance, isMountedRef]);

  useEffect(() => {
    // https://maptive.atlassian.net/browse/MD-4785
    // workaround for performance problems with safari when map is active element
    if (!isSafari() || !mapContainer) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (mapContainer.isConnected) {
        mapContainer.querySelector<HTMLDivElement>('div[aria-label="Map"]')?.blur();
      }
    });

    observer.observe(mapContainer);
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, [mapContainer]);

  return (
    <div
      ref={setMapContainerRef}
      css={wrapperStyle({ theme })}
    >
      <Global
        styles={{
          '.gm-style iframe + div': {
            border: 'none !important',
          },
        }}
      />
      {!isInitialized &&
        <OverlayLoaderComponent />
      }
    </div>
  );
};
