import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  SettingType, type SpreadsheetColumnIdSettingsRow,
} from '../../_shared/components/settingsTable/settingsTable.types';
import { type TranslationFnc } from '../../_shared/utils/hooks';
import { spreadsheetColumnIdToString } from '../../_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { type MapSettingsDataState } from '../../store/mapSettings/data/mapSettingsData.state';
import { type MapSettingsFilterSetting } from '../../store/mapSettings/filtering/mapSettingsFiltering.state';
import { type PresentationalColumnStateRestriction } from '../../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.state';
import { DataType } from '../../store/spreadsheetData/spreadsheetData.state';

const getFilterVisibilityToggleDisabledInfo = (isCurrentMapSnapshot: boolean, isColumnDisabled: boolean, t: TranslationFnc) => {
  if (isCurrentMapSnapshot) {
    return t('mapSettingsInfo.showHideDataDisabled');
  }
  if (isColumnDisabled) {
    return t('This column was disabled for use on presentational maps by Enable/Disable Columns setting');
  }
  return null;
};

export const getFilterSettingsSettingRows = (
  spreadsheetColumns: SpreadsheetColumn[],
  mapSettings: MapSettingsDataState,
  restrictionsMatchup: Map<string, PresentationalColumnStateRestriction>,
  isCurrentMapSnapshot: boolean,
  t: TranslationFnc,
): SpreadsheetColumnIdSettingsRow[] => {
  const filterTypeOptions = getDataTypeOptions(t);

  const results: SpreadsheetColumnIdSettingsRow[] = spreadsheetColumns.map((col) => {
    const { id: columnId, spreadsheetId, name: columnName } = col;

    const spreadsheetColumnId = {
      spreadsheetId,
      columnId,
    };

    const filterSettings = mapSettings.filtering.settings?.[spreadsheetId]?.[columnId];

    const columnDescriptor: null | MapSettingsFilterSetting = filterSettings ?? null;

    const isActiveGroupColumn: boolean = mapSettings.grouping.activeGroupColumns.filter(
      item => (item.columnId === columnId) && (item.spreadsheetId === spreadsheetId)
    ).length > 0;

    const disabledInfo = isActiveGroupColumn ? t('Functionality under control by Grouping Tool') : null;

    const restrictions = restrictionsMatchup.get(spreadsheetColumnIdToString(spreadsheetColumnId));

    return ({
      columnId: spreadsheetColumnId,
      row: {
        data: [{
          type: SettingType.Text,
          value: columnName,
          disabled: null,
        }, {
          type: SettingType.Dropdown,
          value: isActiveGroupColumn ? DataType.GROUP : columnDescriptor?.type ?? '',
          options: filterTypeOptions,
          disabled: disabledInfo,
        }, {
          type: SettingType.Visibility,
          value: !restrictions || (!restrictions.master && !restrictions.filter),
          isCentered: true,
          disabled: getFilterVisibilityToggleDisabledInfo(isCurrentMapSnapshot, restrictions?.master ?? false, t),
        }, {
          type: SettingType.Checkbox,
          value: isActiveGroupColumn ? true : !!columnDescriptor,
          isCentered: true,
          disabled: disabledInfo,
        }],
      },
    });
  });

  return results;
};

export const getDataTypeOptions = (t: TranslationFnc): DropdownOption<DataType>[] => {
  return Object.values(DataType).map((type: DataType) => ({
    name: t(type),
    value: type,
  }));
};
