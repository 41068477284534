import { css } from '@emotion/react';
import {
  faEllipsisV, faExclamationCircle, faExternalLink, faEye, faEyeSlash, faMapMarkerEdit, faRoute, faSearch, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode, type Ref, useMemo,
} from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import {
  isDriveTimePolygon, isGroupRadius, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { getFontAndBorderColorOnBackground } from '~/_shared/utils/colors/colors.helpers';
import { useTranslation } from '~/_shared/utils/hooks';

const wrapperStyle = (theme: Theme) => css({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: '10px 0',
  width: '100%',
});

const errorStyle = (theme: Theme) => css({
  alignItems: 'center',
  color: theme.textColors.danger,
  display: 'flex',
  fontSize: 12,
  gap: 10,
  padding: '0 12px 0 10px',
});

const headerStyle = ({ theme, disabled }: ThemeProps<{disabled: boolean}>) => css({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: !disabled ? theme.textColors.primary : theme.textColors.disabled,
  display: 'flex',
  padding: '0 12px 0 5px',
});

const toggleRadiusButtonStyle = css({
  flexShrink: 0,
  fontSize: '16px',
  width: 20, // so the alternating icons are not jumpy due to different width
});

const nameWrapperStyle = css({
  overflow: 'hidden',
  display: 'flex',
  padding: '0 8px',
});

const namePrefixStyle = css({
  fontSize: '14px',
  fontWeight: 700,
  marginRight: 4,
  textTransform: 'uppercase',
});

const nameStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'left',
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const infoWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 12,
  marginLeft: 'auto',
});

const estimationStyle = css({
  whiteSpace: 'nowrap',
  fontSize: '14px',
  fontWeight: 600,
});

const settingsButtonStyle = css({
  background: 'none',
  border: 'none',
  fontSize: '16px',
});

export type ProximityListingItemContextCallbacks = Readonly<{
  hideSettingsMenu?: () => void;
  onExportClick?: () => void;
  onExportContainedBoundaries?: () => void;
  onProximityEdit?: () => void;
  onProximityGetRoute?: () => void;
  onProximityZoom?: () => void;
  onRemoveProximity?: () => void;
  openSettingsMenu?: () => void;
}>;

type ProximityListingItemProps = Readonly<{
  disabled: boolean;
  error: ReactNode;
  distance: ReactNode;
  driveTimeLabels: ReactNode;
  isHidden: boolean;
  isSettingsMenuVisible: boolean;
  item: Proximity;
  ordinalPosition: number;

  contextCallbacks: ProximityListingItemContextCallbacks;
  toggleVisibility?: () => void;
}>;

export const ProximityListingItemComponent: FC<ProximityListingItemProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const disabled = !!props.error || props.disabled;

  const { onRemoveProximity, onProximityZoom, onProximityEdit,
    onProximityGetRoute, openSettingsMenu, hideSettingsMenu,
    onExportContainedBoundaries, onExportClick } = props.contextCallbacks;

  return (
    <div css={wrapperStyle(theme)}>
      <div css={headerStyle({ theme, disabled })} >
        <HoverableIconComponent
          css={toggleRadiusButtonStyle}
          onClick={props.toggleVisibility}
          hoverColor={theme.iconColors.contrast}
          icon={props.isHidden || disabled ? faEyeSlash : faEye}
        />

        <div>
          <StepNumber number={props.ordinalPosition} color={props.item.styles.color} />
        </div>

        <div css={nameWrapperStyle}>
          {isGroupRadius(props.item) && (
            <strong css={namePrefixStyle}>
              {t('Group')}:
            </strong>
          )}
          <p css={nameStyle}>{props.item.name}</p>
        </div>

        <div css={infoWrapperStyle} >
          <div css={estimationStyle}>
            {props.distance}
            {props.driveTimeLabels}
          </div>

          <ContextMenuComponent
            isFixed
            maxWidth={280}
            renderMenuContent={() => (
              <>
                {onProximityEdit && (
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      icon={faMapMarkerEdit}
                      onClick={onProximityEdit}
                      isDisabled={disabled}
                    >
                      {t('Edit Proximity')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                )}

                <ContextMenuItemCoreComponent>
                  <ContextMenuDefaultItem
                    onClick={onProximityZoom}
                    icon={faSearch}
                    isDisabled={disabled}
                  >
                    {t('Zoom To Proximity')}
                  </ContextMenuDefaultItem>
                </ContextMenuItemCoreComponent>

                {onExportClick && (
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      onClick={onExportClick}
                      icon={faExternalLink}
                      isDisabled={disabled}
                    >
                      {t('Export Locations')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                )}

                {onExportContainedBoundaries && (
                  <ContextMenuItemCoreComponent>
                    <ContextMenuDefaultItem
                      onClick={onExportContainedBoundaries}
                      icon={faExternalLink}
                      isDisabled={disabled}
                    >
                      {t('Export Contained Boundaries')}
                    </ContextMenuDefaultItem>
                  </ContextMenuItemCoreComponent>
                )}

                <ContextMenuItemCoreComponent>
                  <ContextMenuDefaultItem
                    icon={faRoute}
                    isDisabled={disabled}
                    onClick={onProximityGetRoute}
                  >
                    {t('Routing / Directions')}
                  </ContextMenuDefaultItem>
                </ContextMenuItemCoreComponent>

                <ContextMenuItemCoreComponent>
                  <ContextMenuDefaultItem
                    icon={faTrash}
                    isDestructive
                    onClick={onRemoveProximity}
                  >
                    {isDriveTimePolygon(props.item) ? t('Delete Polygon') : t('Delete Radius')}
                  </ContextMenuDefaultItem>
                </ContextMenuItemCoreComponent>
              </>
            )}
            isVisible={props.isSettingsMenuVisible}
            onHide={hideSettingsMenu}
            preferredPlacement="bottom-end"
          >
            {ref => (
              <button
                ref={ref as Ref<HTMLButtonElement>}
                css={settingsButtonStyle}
                onClick={openSettingsMenu}
              >
                <HoverableIconComponent
                  icon={faEllipsisV}
                  baseColor={theme.textColors.tertiary}
                  hoverColor={theme.textColors.primary}
                />
              </button>
            )}
          </ContextMenuComponent>
        </div>
      </div>

      {props.error && (
        <div css={errorStyle}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          {props.error}
        </div>
      )}
    </div>
  );
};

const colorTileStyle = (borderColor: string) => css({
  border: `1px solid ${borderColor}`,
  fontSize: '14px',
  marginLeft: 12,
});

const StepNumber: FC<{ number: number; color: string }> = ({ number, color }) => {
  const theme = useTheme();

  const [borderColor, fontColor] = getFontAndBorderColorOnBackground(
    createColor(theme.textColors.primary),
    createColor(color),
    createColor(theme.backgroundColors.secondary)
  );

  const circleColors = useMemo(() => ({
    fillColor: color,
    fontColor: guaranteeHash(fontColor.hex),
  }), [color, fontColor]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={colorTileStyle(guaranteeHash(borderColor.hex))}
      size={22}
    >
      {number}
    </NumberInCircleComponent>
  );
};
