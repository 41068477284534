import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import {
  EditDataTableComponent, type EditDataTableRow,
} from '../editDataTable/editDataTable.component';
import { ModalComponent } from '../modal/modal.component';
import {
  type ChangeableSettingRow, type SettingColumn,
} from '../settingsTable/settingsTable.types';

const submitButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
});

const submitLabelStyles = css({
  flexGrow: 1,
  padding: '0 35px 0 20px',
});

const submitIconStyles = css({
  fontSize: '16px',
  lineHeight: 1.5,
  margin: 0,
  width: 40,
});

const modalContentStyle = css({
  padding: 0,
});

const settingsTableDataCellStyle = ({ isCentered, isDisabled }: ThemeProps<{ isCentered?: boolean; isDisabled: boolean }>) => css({
  padding: 0,
  textAlign: isCentered ? 'center' : 'left',
  opacity: isDisabled ? .5 : undefined,
});

const settingsTableDataRowStyle = ({ theme }: ThemeProps) => css({
  height: 48,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:nth-of-type(2n)': {
    background: theme.backgroundColors.tertiary,
  },
  '&:last-of-type': {
    border: 'none',
  },
});

type EditDataModalProps = Readonly<{
  columnNames?: SettingColumn[];
  isOpen: boolean;
  rows: EditDataTableRow[];

  onChange?: (index: number, newSettingRow: ChangeableSettingRow) => void;
  onClose: () => void;
  onSubmit: () => void;
}>;

export const EditDataModalComponent: FC<EditDataModalProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Edit Data')}
      confirmButton={(
        <ButtonComponent
          css={submitButtonStyles}
          text={<span css={submitLabelStyles}>{t('Save')}</span>}
          prefixIcon={faCheck}
          prefixIconStyle={submitIconStyles}
          onClick={props.onSubmit}
        />
      )}
      contentStyle={modalContentStyle}
    >
      <EditDataTableComponent
        columnNames={props.columnNames}
        rows={props.rows}
        onChange={props.onChange}
        customDataCellStyle={settingsTableDataCellStyle}
        customDataRowStyle={settingsTableDataRowStyle}
      />
    </ModalComponent>
  );
};
