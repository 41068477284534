import { type TranslationFnc } from '../utils/hooks';

export enum ImageSize {
  Normal = 'Normal',
  Large = 'Large',
}

export const getImageSizeLabel = (t: TranslationFnc, imageSize: ImageSize) => {
  switch (imageSize) {
    case ImageSize.Large:
      return t('ImageSize.Large__label');
    case ImageSize.Normal:
      return t('ImageSize.Normal__label');
    default:
      throw new Error(`Unknown ImageSize '${imageSize}'`);
  }
};

export const getImageSizeDescription = (t: TranslationFnc, imageSize: ImageSize) => {
  switch (imageSize) {
    case ImageSize.Normal:
      return t('ImageSize.Normal__description');
    case ImageSize.Large:
      return t('ImageSize.Large__description');
    default:
      throw new Error(`Unknown ImageSize '${imageSize}'`);
  }
};
