import {
  type ReactNode,
  useCallback, useEffect, useRef, useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { type MapObjectZIndex } from '../mapObject.types';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';
import { MapShape } from './mapShape.container';
import {
  cloneMapShape, type MapShapeInstance,
} from './mapShapeModel';
import { useEditMapShape } from './private/useEditMapShape';

export type OutlineCallbackProps = {
  readonly onDragStart: () => void;
  readonly onDragMove: (latLng: LatLng) => void;
  readonly onDragEnd: () => void;
  readonly onOutlineMouseOver: () => void;
};

export type EditMapShapeContainerProps = {
  readonly initialMapShape: MapShapeInstance;
  zIndex: MapObjectZIndex;
  readonly isPolygon: boolean;
  readonly renderOutline?: (key: string, outline: MapObjectOutlineInstance, isGhost: boolean, callbackProps: OutlineCallbackProps) => ReactNode;
  readonly renderLine?: (key: string, start: LatLng, end: LatLng) => ReactNode;
  readonly renderRemoveButton?: (onClick: () => void, anchorOutlineId: Uuid) => ReactNode;
  readonly renderPolygon?: () => ReactNode;

  readonly onChange: (mapShape: MapShapeInstance) => void;
};

export const EditMapShapeContainer: React.FC<EditMapShapeContainerProps> = (props) => {
  const { zIndex, isPolygon } = props;
  const { renderLine, renderRemoveButton: renderRemoveButtonCallback,
    renderOutline: renderOutlineCallback, renderPolygon } = props;

  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;

  const [initialMapShape] = useState(cloneMapShape(props.initialMapShape));
  const [isDragging, setIsDragging] = useState(false);

  const [lastHoveredOutlineId, setLastHoveredOutlineId] = useState<string | null>(null);
  const { shapeWithGhosts, shape, copyGhostOutlineToShape, updateOutlinePosition,
    deleteOutline: deleteOutlineInternal } = useEditMapShape({ initialShape: initialMapShape, isPolygon });

  const isGhost = useCallback((outlineId: Uuid) => !!shapeWithGhosts.lookUp.get(outlineId)?.isGhost, [shapeWithGhosts.lookUp]);

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (!isGhost(outlineId)) {
      setLastHoveredOutlineId(outlineId);
    }
  }, [isGhost]);

  const onDragStart = useCallback((outlineId: Uuid) => {
    if (isGhost(outlineId)) {
      copyGhostOutlineToShape(outlineId);
    }
    setIsDragging(true);

    // set hover in case outline was ghost before drag
    setLastHoveredOutlineId(outlineId);
  }, [copyGhostOutlineToShape, isGhost]);

  const stopDragging = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDragMove = useCallback((outlineId: Uuid, latLng: LatLng) => {
    updateOutlinePosition(outlineId, latLng.lat, latLng.lng);
  }, [updateOutlinePosition]);

  const deleteOutline = useCallback((outlineId: Uuid) => {
    setLastHoveredOutlineId(null);
    deleteOutlineInternal(outlineId);
  }, [deleteOutlineInternal]);

  const renderOutline = useCallback((key: string, outline: MapObjectOutlineInstance) =>
    renderOutlineCallback ?
      renderOutlineCallback(key, outline, isGhost(outline.id), {
        onDragMove: (latLng: LatLng) => onDragMove(outline.id, latLng),
        onDragEnd: stopDragging,
        onDragStart: () => onDragStart(outline.id),
        onOutlineMouseOver: () => onOutlineMouseOver(outline.id),
      }) : null, [isGhost, onDragMove, onDragStart, onOutlineMouseOver, renderOutlineCallback, stopDragging]);

  const renderRemoveButton = useCallback<() => ReactNode>(() => {
    if (isDragging) {
      return null;
    }

    if (shape.outlines.length < 3 || (props.isPolygon && shape.outlines.length <= 3)) {
      return null;
    }

    if (!renderRemoveButtonCallback || !lastHoveredOutlineId) {
      return null;
    }

    return renderRemoveButtonCallback(() => deleteOutline(lastHoveredOutlineId), lastHoveredOutlineId);
  }, [deleteOutline, isDragging, lastHoveredOutlineId, props.isPolygon, renderRemoveButtonCallback, shape.outlines.length]);

  useEffect(() => {
    if (!isDragging && shape !== initialMapShape) {
      onChangeRef.current(cloneMapShape(shape));
    }
  }, [isDragging, onChangeRef, initialMapShape, shape]);

  return (
    <MapShape
      renderPolygon={renderPolygon}
      shape={isDragging ? shape : shapeWithGhosts}
      zIndex={zIndex}
      isPolygon={isPolygon}
      renderLine={renderLine}
      renderRemoveButton={renderRemoveButton}
      renderOutline={renderOutline}
    />
  );
};
