import { type ReactNode } from 'react';
import {
  type ActiveGroupFilters,
  type GroupingColumn,
  type GroupingColumnValues,
} from '../../../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type NumericalRange } from '../../../../../_shared/utils/range/range.helpers';
import { GroupingItemBodyContainer } from '../../../../../grouping/components/groupingItemBody.container';
import { getFilterConjunction } from '../../../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilter.reducer';
import {
  type MapSettingsColumnsFilterState,
  type MapSettingsFilterTextState,
} from '../../../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { type MatchupDataState } from '../../../../../store/matchupData/matchupData.state';
import {
  getAttributeColumnUniqueAttributes,
  getPerSpreadsheetUniqueGroups,
} from '../../../../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { getSpreadsheetDataForDataType } from '../../../../../store/spreadsheetData/spreadsheetData.helpers';
import {
  DataType, emptySpreadsheetData, type SpreadsheetDataData,
} from '../../../../../store/spreadsheetData/spreadsheetData.state';
import {
  type Attribute,
  AttributeFilterComponent,
  type AttributeFilterSettings,
  type AttributeFilterValues,
} from './attributeFilter.component';
import {
  DateFilterComponent, type DateFilterSettings, type DateFilterValues,
} from './dateFilter.component';
import { FilterConjunction } from './filterConjunction.enum';
import {
  getColumnsFilterDataForDataType, getTextFilterFromState,
} from './filters.helpers';
import {
  GroupFilterComponent, type GroupFilterSettings, type GroupFilterValues,
} from './groupFilter.component';
import {
  NumberFilterComponent, type NumberFilterSettings, type NumberFilterValues,
} from './numberFilter.component';
import {
  type TextFilter,
  TextFilterComponent,
  type TextFilterSettings,
  type TextFilterValues,
} from './textFilter/textFilter.component';

type PossibleArguments<T = never> =
  [DataType.DATE, DateFilterSettings, DateFilterValues] |
  [DataType.ATTRIBUTE, AttributeFilterSettings<T>, AttributeFilterValues] |
  [DataType.NUMBER, NumberFilterSettings, NumberFilterValues] |
  [DataType.GROUP, GroupFilterSettings, GroupFilterValues] |
  [DataType.TEXT, TextFilterSettings, TextFilterValues];

export function renderToolFilter(type: DataType.NUMBER, settings: NumberFilterSettings, values: NumberFilterValues): ReactNode;
export function renderToolFilter(type: DataType.GROUP, settings: GroupFilterSettings, values: GroupFilterValues): ReactNode;
export function renderToolFilter(type: DataType.TEXT, settings: TextFilterSettings, values: TextFilterValues): ReactNode;
export function renderToolFilter(type: DataType.DATE, settings: DateFilterSettings, values: DateFilterValues): ReactNode;
export function renderToolFilter<T extends unknown = never>(type: DataType.ATTRIBUTE, settings: AttributeFilterSettings<T>, values: AttributeFilterValues): ReactNode;
export function renderToolFilter<T extends unknown = never>(...args: PossibleArguments<T>): ReactNode {
  switch (args[0]) {
    case DataType.ATTRIBUTE:
      return (
        <AttributeFilterComponent
          {...args[1]}
          {...args[2]}
        />
      );
    case DataType.DATE:
      return (
        <DateFilterComponent
          {...args[1]}
          {...args[2]}
        />
      );
    case DataType.NUMBER:
      return (
        <NumberFilterComponent
          {...args[1]}
          {...args[2]}
        />
      );
    case DataType.TEXT:
      return (
        <TextFilterComponent
          {...args[1]}
          {...args[2]}
        />
      );
    case DataType.GROUP:
      return (
        <GroupFilterComponent
          {...args[1]}
          {...args[2]}
        />
      );
    default:
      throw new Error(`Unsupported filter type in filter tool '${args[0]}'`);
  }
}

export const getTextFilterPanelBody = (
  columnName: string,
  columnsFilter: MapSettingsColumnsFilterState,
  spreadsheetColumnId: SpreadsheetColumnId,
  onConjunctionChange: (newConjunction: FilterConjunction) => void,
  onValueChange: (filters: TextFilter[]) => void,
): ReactNode => {
  const filterItemState: MapSettingsFilterTextState | null =
    getColumnsFilterDataForDataType(DataType.TEXT, columnsFilter, spreadsheetColumnId) ?? null;

  const conjunction = getFilterConjunction(columnsFilter, spreadsheetColumnId, DataType.TEXT);
  const filters = filterItemState ? getTextFilterFromState(filterItemState) : [];

  return renderToolFilter(
    DataType.TEXT,
    { filteredEntityName: columnName },
    {
      filters,
      conjunction,
      onConjunctionChange,
      onValueChange,
    }
  );
};

export const ActiveGroupFilterPanelBody = (props: {
  groupingColumn: GroupingColumn;
  activeGroups: ActiveGroupFilters;
  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, groups: GroupingColumnValues<1>) => void;
  isEditingDisabled?: boolean;
}) => (
  <GroupingItemBodyContainer
    currentGroupColumn={props.groupingColumn}
    activeFilters={props.activeGroups}
    onFilterChange={props.onFilterChange}
    isEditingDisabled={!!props.isEditingDisabled}
  />
);

export const getRegularGroupFilterPanelBody = (
  spreadsheetData: SpreadsheetDataData | null,
  matchupData: MatchupDataState,
  groupingColumn: GroupingColumn,
  activeGroups: ActiveGroupFilters,
  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, groups: GroupingColumnValues<1>) => void
): ReactNode => (
  spreadsheetData ? (
    renderToolFilter(
      DataType.GROUP,
      {
        allGroups: getPerSpreadsheetUniqueGroups(spreadsheetData, groupingColumn, matchupData),
      },
      {
        activeGroups,
        onFilterChange,
      }
    )
  ) : null
);

export const getNumberFilterPanelBody = (
  spreadsheetData: SpreadsheetDataData | null,
  spreadsheetColumnId: SpreadsheetColumnId,
  filtersDataState: MapSettingsColumnsFilterState,
  onChange: (newRange: NumericalRange | null) => void,
): ReactNode | null => {
  const data = getSpreadsheetDataForDataType(DataType.NUMBER, spreadsheetData ?? emptySpreadsheetData, spreadsheetColumnId);

  if (!data) {
    return null;
  }

  const min = data.extra.min ?? 0;
  const max = data.extra.max ?? 0;

  const rangeValue: number[] | undefined = filtersDataState[spreadsheetColumnId.spreadsheetId]?.[spreadsheetColumnId.columnId]
    ?.[DataType.NUMBER]?.values.value;

  const currentMin = rangeValue?.[0] ?? min;
  const currentMax = rangeValue?.[1] ?? max;

  const onValueChange = (newRange: NumericalRange) => {
    if ((newRange.from === min) && (newRange.to === max)) {
      onChange(null);
    }
    else {
      onChange(newRange);
    }
  };

  return renderToolFilter(
    DataType.NUMBER,
    {
      minValue: min,
      maxValue: max,
      sliderStep: 1,
    },
    {
      currentMaxValue: currentMax ?? max,
      currentMinValue: currentMin ?? min,
      onChange: onValueChange,
      reset: !rangeValue,
    },
  );
};

export const getAttributeFilterPanel = (
  spreadsheetData: SpreadsheetDataData | null,
  spreadsheetColumnId: SpreadsheetColumnId,
  columnsFilter: MapSettingsColumnsFilterState,
  columnName: string,
  onConjunctionChange: (newConjunction: FilterConjunction) => void,
  onSelectedAttributeIdsChange: (newSelectedAttributeIds: Record<string, 1>) => void,
): ReactNode | null => {
  if (!spreadsheetData) {
    return null;
  }

  const attributeSpreadsheetData = getSpreadsheetDataForDataType(DataType.ATTRIBUTE, spreadsheetData, spreadsheetColumnId);
  const uniqueValues = attributeSpreadsheetData?.extra.uniqueValues;

  let attributes: Attribute<string>[] = [];
  if (uniqueValues) {
    attributes = getAttributeColumnUniqueAttributes(uniqueValues, columnName);
  }

  const attributeValues = getColumnsFilterDataForDataType(DataType.ATTRIBUTE, columnsFilter, spreadsheetColumnId);

  return renderToolFilter<string>(
    DataType.ATTRIBUTE,
    {
      attributes,
      renderAttribute: attr => attr,
    },
    {
      conjunction: attributeValues?.conjunction ?? FilterConjunction.And,
      onConjunctionChange,
      selectedAttributeIds: attributeValues?.values ?? {},
      onChange: onSelectedAttributeIdsChange,
    }
  );
};

export const getDateFilterPanel = (
  spreadsheetData: SpreadsheetDataData | null,
  spreadsheetColumnId: SpreadsheetColumnId,
  columnsFilter: MapSettingsColumnsFilterState,
  onFromDateChange: (fromDate: string) => void,
  onToDateChange: (toDate: string) => void,
): ReactNode | null => {
  if (!spreadsheetData) {
    return null;
  }

  const dateSpreadsheetData = getSpreadsheetDataForDataType(DataType.DATE, spreadsheetData, spreadsheetColumnId);
  const extraValues = dateSpreadsheetData?.extra;

  if (!extraValues) {
    return null;
  }

  const dateValues = getColumnsFilterDataForDataType(DataType.DATE, columnsFilter, spreadsheetColumnId)?.values;

  if (!extraValues.min || !extraValues.max) {
    // eslint-disable-next-line no-console
    console.log('error rendering date filter tool: missing valid min or max date');
    return null;
  }

  const fromDate = dateValues?.[0] ? dateValues[0] : null;
  const toDate = dateValues?.[1] ? dateValues[1] : null;

  return renderToolFilter(
    DataType.DATE,
    {
      minDate: extraValues.min,
      maxDate: extraValues.max,
    },
    {
      currentMinDate: fromDate ?? extraValues.min,
      currentMaxDate: toDate ?? extraValues.max,
      onChangeMinDate: onFromDateChange,
      onChangeMaxDate: onToDateChange,
    }
  );
};
