import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_TERTIARY,
  DRAWING_TOOL_DEFAULT_FONT_COLOR_SECONDARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingNumberSettingsState } from './drawingNumberSettings.state';

export const drawingNumberSettingsInitialState: DrawingNumberSettingsState = {
  scalesWithMapZoom: false,
  counter: 1,
  radius: 50,
  strokeOpacity: 100,
  fillOpacity: 100,
  strokeWeight: 2,
  fontSizePercentage: 50,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_TERTIARY,
  fontColor: DRAWING_TOOL_DEFAULT_FONT_COLOR_SECONDARY,
};

export const drawingNumberSettingsReducer = (
  state = drawingNumberSettingsInitialState,
  action: DrawingSettingsAction
): DrawingNumberSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Number) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.number,
      };
    }

    default:
      return state;
  }
};
