import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { SkeletonWrapperComponent } from '~/_shared/components/skeletonWrapper/skeletonWrapper.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import { BoundaryGroupsListingItemComponent } from './item/boundaryGroupsListingItem.component';
import { BoundaryGroupsListingItemSkeletonComponent } from './item/boundaryGroupsListingItemSkeleton.component';

type BoundaryItem = {
  name: string;
  id: number;
  mapsCount: number;
  canRemoveBoundaryGroups: boolean;
  ownerName: string;
};

const modalContentStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
  padding: 0,
});

const modalStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  // after added to hide the last row's bottom border when the items start touching the bottom of the modal
  '&:after': {
    content: '\'\'',
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: 1,
    width: '100%',
    background: theme.backgroundColors.secondary,
  },
});

const contentStyle = css({
  height: '100%',
});

const noItemsStyle = css({
  margin: 0,
  fontSize: '14px',
  padding: '8px 14px',
});

type BoundaryGroupsListingProps = ModalProps<{
  items: ReadonlyArray<BoundaryItem>;
  caption: string;
  isLoading: boolean;

  onRemove: (groupId: number) => void;
  onEditName: (groupId: number, newName: string) => void;
  onRemoveUnused?: () => void; // internal dev feature for cleaning testing accounts
}>;

export const BoundaryGroupsListingComponent: FC<BoundaryGroupsListingProps> = (props) => {
  const [isScrollbarHiddenWatch, setIsScrollbarHiddenWatch] = useState(false);
  const [isScrollbarHidden, setIsScrollbarHidden] = useState(false);
  const theme = useTheme();
  const [t] = useTranslation();

  useEffect(() => {
    setIsScrollbarHidden(true);

    const timeoutId = setTimeout(() => {
      setIsScrollbarHidden(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isScrollbarHiddenWatch]);

  return (
    <ModalComponent
      caption={props.caption}
      css={modalStyle({ theme })}
      contentStyle={modalContentStyle({ theme })}
      isOpen={props.isOpen}
      maxWidth={560}
      onClose={props.onClose}
      additionalContent={(props.isLoading && props.items.length > 0) ? <OverlayLoaderComponent /> : null}
      isScrollbarHidden={isScrollbarHidden}
      leftFooterContent={props.onRemoveUnused ? (
        <ButtonComponent
          text="Delete Unused" // internal dev feature for cleaning testing accounts
          onClick={props.onRemoveUnused}
          buttonStyle={ButtonStyle.Danger}
        />
      ) : undefined}
    >
      <div css={contentStyle}>
        {props.isLoading && props.items.length === 0 && (
          <SkeletonWrapperComponent
            animationDurationMs={2000}
            itemsCount={5}
            renderItem={({ durationMs, delayMs }) => (
              <BoundaryGroupsListingItemSkeletonComponent
                animationDurationMs={durationMs}
                animationDelayMs={delayMs}
              />
            )}
          />
        )}

        {props.items.map((item, index) => (
          <BoundaryGroupsListingItemComponent
            boundaryGroupId={item.id}
            index={index}
            isLoading={props.isLoading}
            isRemoveDisabled={!item.canRemoveBoundaryGroups}
            key={item.id}
            mapsCount={item.mapsCount}
            name={item.name}
            onEditName={(newName) => props.onEditName(item.id, newName)}
            onExpandClick={() => setIsScrollbarHiddenWatch(flag => !flag)}
            onRemove={() => props.onRemove(item.id)}
            ownerName={item.ownerName}
          />
        ))}

        {(!props.isLoading && props.items.length === 0) &&
          <p css={noItemsStyle}>{t('No territory groups')}</p>
        }
      </div>
    </ModalComponent>
  );
};
