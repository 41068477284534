import { css } from '@emotion/react';
import {
  faMoon, faScrewdriverWrench, faSun,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import {
  getIconForMapToolOrFeature, getTranslationForMapToolOrFeature,
} from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { canMemberManageClient } from '~/clientTeamManagement/memberRoles.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  publicMapSettingsMenuToolsSetDefaultTool, publicMapSettingsSetTheme, publicMapSettingsUpdate,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.actionCreators';
import {
  usePublicMapSettingDefaultPresentationalMapToolSelector, usePublicMapSettingEnabledPresentationalMapToolsSelector, usePublicMapSettingsSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useUserLimitsSelector } from '~/store/userData/userData.selectors';
import { SettingsAccordionDropdownComponent } from '../settingsAccordionDropdown.component';
import { SharedMapToolDropDownOptionComponent } from './sharedMapToolDropDownOption.component';
import {
  SharedPresentationalMapsBooleanToggleSettingsName,
  SharedPresentationalMapsDropdownsSettingsName,
} from './sharedPresentationalMaps.enums';
import { type SharedPresentationalMapsStructureProps } from './sharedPresentationalMaps.types';
import { usePresentationalDataExport } from './usePresentationalDataExport.hook';

const iconStyle = css({
  fontSize: 18,
  marginLeft: 16,
});

export const useSharedPresentationalMapsSettings = (): SharedPresentationalMapsStructureProps => {
  const clientRole = useSelector(state => state.userData?.clientRole);
  const publicMapSettings = usePublicMapSettingsSelector();
  const userLimits = useUserLimitsSelector();
  const enabledPresentationalMapTools = usePublicMapSettingEnabledPresentationalMapToolsSelector();
  const defaultPresentationalMenuTool = usePublicMapSettingDefaultPresentationalMapToolSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { openModal: openRestrictZoomLevelsModal } = useModal(ModalType.RestrictZoomLevels);
  const { openModal: openEnableDisableColumnsOnPublicMapModal } = useModal(ModalType.PublicMapColumnsDisplaySettings);
  const { openModal: openMapMarkerColumnsDisplaySettingsModal } = useModal(ModalType.MapMarkerColumnsDisplaySettings);
  const { openModal: openExportDataFunctionalitiesColumnsDisplaySettingsModal } = useModal(ModalType.ExportDataFunctionalitiesColumnsDisplaySettings);
  const { openModal: openEmbedMapModal } = useModal(ModalType.EmbedMap);
  const { openContactUsForAuthorization, openSelectToolsForAllowExport } = usePresentationalDataExport();

  const [activeDropdown, setActiveDropdown] = useState<SharedPresentationalMapsDropdownsSettingsName | null>(null);

  const onToggleThemeStyle = useCallback(() => {
    const currentTheme = publicMapSettings.theme;
    dispatch(publicMapSettingsSetTheme(currentTheme === 'dark' ? 'light' : 'dark'));
  }, [dispatch, publicMapSettings.theme]);

  const onTogglePublicSettings = useCallback((name: SharedPresentationalMapsBooleanToggleSettingsName) =>
    () => dispatch(publicMapSettingsUpdate(name, !publicMapSettings[name]))
  , [dispatch, publicMapSettings]);

  const onRestrictZoomLevelsClick = useCallback(() => {
    openRestrictZoomLevelsModal();
  }, [openRestrictZoomLevelsModal]);

  const openShowHideDataOnMarker = useCallback(() => {
    openMapMarkerColumnsDisplaySettingsModal();
  }, [openMapMarkerColumnsDisplaySettingsModal]);

  const openShowHideDataFromExport = useCallback(() => {
    openExportDataFunctionalitiesColumnsDisplaySettingsModal();
  }, [openExportDataFunctionalitiesColumnsDisplaySettingsModal]);

  const openShowHideFilterColumns = useCallback(() => {
    openEnableDisableColumnsOnPublicMapModal();
  }, [openEnableDisableColumnsOnPublicMapModal]);

  const onSharedMapToolDropDownChange = useCallback((tool: MapTool) => () => (
    dispatch(publicMapSettingsMenuToolsSetDefaultTool(
      SharedPresentationalMapsDropdownsSettingsName.defaultOpenMapToolPresentationalMap, tool
    ))
  ), [dispatch]);

  const onDefaultToolAccordionHeaderClick = useCallback(() => {
    setActiveDropdown(
      activeDropdown === SharedPresentationalMapsDropdownsSettingsName.defaultOpenMapToolPresentationalMap
        ? null
        : SharedPresentationalMapsDropdownsSettingsName.defaultOpenMapToolPresentationalMap
    );
  }, [activeDropdown]);

  const mapToolsMenuDefaultDropdown = useMemo(() => {
    const isDropdownOpen = activeDropdown === SharedPresentationalMapsDropdownsSettingsName.defaultOpenMapToolPresentationalMap;

    const renderedOptions = enabledPresentationalMapTools
      .map((tool, index) => {

        return (
          <SharedMapToolDropDownOptionComponent
            isLast={enabledPresentationalMapTools.length === index + 1}
            key={tool}
            onChange={onSharedMapToolDropDownChange(tool)}
            mapTool={tool}
          />
        );
      });

    const triggerComponent = (
      <SettingsAccordionDropdownComponent
        isOpen={isDropdownOpen}
        onClick={onDefaultToolAccordionHeaderClick}
        triggerText={defaultPresentationalMenuTool ? getTranslationForMapToolOrFeature(defaultPresentationalMenuTool) : t('No Tools')}
      >
        <FontAwesomeIcon
          css={iconStyle}
          icon={defaultPresentationalMenuTool ? getIconForMapToolOrFeature(defaultPresentationalMenuTool) : faScrewdriverWrench}
        />
      </SettingsAccordionDropdownComponent>
    );

    return {
      isOpen: isDropdownOpen,
      onClose: () => setActiveDropdown(null),
      renderedOptions,
      triggerComponent,
      value: defaultPresentationalMenuTool,
    };
  }, [activeDropdown, enabledPresentationalMapTools, onDefaultToolAccordionHeaderClick,
    defaultPresentationalMenuTool, t, onSharedMapToolDropDownChange,
  ]);

  const buttons = useMemo(() => ({
    restrictZoomLevels: {
      onClick: onRestrictZoomLevelsClick,
    },
    showHideDataOnMarker: {
      onClick: openShowHideDataOnMarker,
    },
    showHideDataFromExport: {
      onClick: openShowHideDataFromExport,
    },
    showHideFilterColumns: {
      onClick: openShowHideFilterColumns,
    },
    embedMap: {
      onClick: openEmbedMapModal,
    },
    presentationalDataExportAuthorize: {
      onClick: canMemberManageClient(clientRole) && !userLimits.canExportSharedMapsData
        ? openContactUsForAuthorization : undefined,
    },
    presentationalDataExportAllowTools: {
      onClick: userLimits.canExportSharedMapsData ? openSelectToolsForAllowExport : undefined,
    },
  }), [clientRole, onRestrictZoomLevelsClick, openContactUsForAuthorization, openEmbedMapModal,
    openSelectToolsForAllowExport, openShowHideDataFromExport, openShowHideDataOnMarker, openShowHideFilterColumns,
    userLimits.canExportSharedMapsData,
  ]);

  const dropdowns = useMemo(() => ({
    defaultOpenMapToolPresentationalMap: mapToolsMenuDefaultDropdown,
  }), [mapToolsMenuDefaultDropdown]);

  const toggles = useMemo(() => ({
    displayTitleAndDescription: {
      isOn: publicMapSettings.displayTitleAndDescription,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.displayTitleAndDescription),
    },
    displaySearchBox: {
      isOn: publicMapSettings.displaySearchBox,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.displaySearchBox),
    },
    distanceCalculator: {
      isOn: publicMapSettings.distanceCalculator,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.distanceCalculator),
    },
    lassoTool: {
      isOn: publicMapSettings.lassoTool,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.lassoTool),
    },
    showMyLocation: {
      isOn: publicMapSettings.showMyLocation,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.showMyLocation),
    },
    showMyLocationDefault: {
      isOn: publicMapSettings.showMyLocationDefault,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.showMyLocationDefault),
    },
    restrictMapPanning: {
      isOn: publicMapSettings.restrictMapPanning,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.restrictMapPanning),
    },
    allowStreetView: {
      isOn: publicMapSettings.allowStreetView,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.allowStreetView),
    },
    allowSatelliteView: {
      isOn: publicMapSettings.allowSatelliteView,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.allowSatelliteView),
    },
    displayLegendOnly: {
      isOn: publicMapSettings.displayLegendOnly,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.displayLegendOnly),
    },
    boundaryTool: {
      isOn: publicMapSettings.boundaryTool,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.boundaryTool),
    },
    filterMenusOpen: {
      isOn: publicMapSettings.filterMenusOpen,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.filterMenusOpen),
    },
    filterTool: {
      isOn: publicMapSettings.filterTool,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.filterTool),
    },
    groupingTool: {
      isOn: publicMapSettings.groupingTool,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.groupingTool),
    },
    heatMappingTool: {
      isOn: publicMapSettings.heatMappingTool,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.heatMappingTool),
    },
    legendReplacesGrouping: {
      isOn: publicMapSettings.legendReplacesGrouping,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.legendReplacesGrouping),
    },
    locationFinder: {
      isOn: publicMapSettings.locationFinder,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.locationFinder),
    },
    locationFinderStartsOpen: {
      isOn: publicMapSettings.locationFinderStartsOpen,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.locationFinderStartsOpen),
    },
    locationList: {
      isOn: publicMapSettings.locationList,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.locationList),
    },
    locationListOpen: {
      isOn: publicMapSettings.locationListOpen,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.locationListOpen),
    },
    /*
      We have decided to disable Map Image Export for presentational maps for beta release
      TODO: remove this some time after release of we decide to never use this
    */
    mapImageExport: {
      isOn: publicMapSettings.mapImageExport,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.mapImageExport),
    },
    mapToolsMenuOpen: {
      isOn: publicMapSettings.mapToolsMenuOpen,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.mapToolsMenuOpen),
    },
    mapZoom: {
      isOn: publicMapSettings.mapZoom,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.mapZoom),
    },
    radiusProximity: {
      isOn: publicMapSettings.radiusProximity,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.radiusProximity),
    },
    routingDirections: {
      isOn: publicMapSettings.routingDirections,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.routingDirections),
    },
    searchBarAlwaysVisible: {
      isOn: publicMapSettings.searchBarAlwaysVisible,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.searchBarAlwaysVisible),
    },
    markersVisibilityButton: {
      isOn: publicMapSettings.markersVisibilityButton,
      onToggle: onTogglePublicSettings(SharedPresentationalMapsBooleanToggleSettingsName.markersVisibilityButton),
    },
    themeStyle: {
      isOn: publicMapSettings.theme === 'dark',
      onToggle: onToggleThemeStyle,
      offIcon: faSun,
      onIcon: faMoon,
    },
  }), [onTogglePublicSettings, publicMapSettings.allowSatelliteView, publicMapSettings.allowStreetView,
    publicMapSettings.boundaryTool, publicMapSettings.displayLegendOnly,
    publicMapSettings.displaySearchBox, publicMapSettings.displayTitleAndDescription, publicMapSettings.distanceCalculator,
    publicMapSettings.filterMenusOpen, publicMapSettings.filterTool, publicMapSettings.groupingTool,
    publicMapSettings.heatMappingTool, publicMapSettings.lassoTool, publicMapSettings.legendReplacesGrouping,
    publicMapSettings.locationFinder, publicMapSettings.locationFinderStartsOpen, publicMapSettings.locationList,
    publicMapSettings.locationListOpen, publicMapSettings.mapImageExport, publicMapSettings.mapToolsMenuOpen,
    publicMapSettings.mapZoom, publicMapSettings.markersVisibilityButton, publicMapSettings.radiusProximity,
    publicMapSettings.restrictMapPanning, publicMapSettings.routingDirections, publicMapSettings.searchBarAlwaysVisible,
    publicMapSettings.showMyLocation, publicMapSettings.showMyLocationDefault, publicMapSettings.theme,
    onToggleThemeStyle,
  ]);

  return useMemo(() => ({
    buttons,
    dropdowns,
    toggles,
  }), [buttons, dropdowns, toggles]);
};
