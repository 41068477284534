import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  EXPORT_IMAGE_ALLOWED_UNCLUSTER_OPTIONS, EXPORT_IMAGE_DEFAULT_UNCLUSTER_OPTION, MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING,
} from '~/_shared/utils/markers/markers.constants';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { selectNumberOfStackedMarkersWithoutForcedUnstacking } from '~/map/map/markers/useStacksAndClusters/selectStackedMarkers';
import { selectAllFilteredSpreadsheetRows } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type AppState } from '../../app.store';
import {
  mapSettingsAreLabelsInsideMarkersActiveSelector, mapSettingsUseLabelsAboveMarkersSelector,
} from '../makersGeneral/mapSettingsMarkersGeneral.selectors';
import { mapSettingsExportImageSettingsSelector } from '../toolsState/exportImageSettings/exportImageSettings.selectors';
import {
  mapSettingsMarkerClusterDenseMarkersStateSelector, mapSettingsMarkerUnclusterBelowNStateSelector,
} from './mapSettingsMarkers.selectors';

export const numberOfLabelsPerMarkerSelector = (state: AppState) => {
  let labelsPerMarker = 0;
  labelsPerMarker += mapSettingsAreLabelsInsideMarkersActiveSelector(state) ? 1 : 0;
  labelsPerMarker += mapSettingsUseLabelsAboveMarkersSelector(state) ? 1 : 0;

  const numberOfMarkers = selectAllFilteredSpreadsheetRows(state).length;

  return numberOfMarkers * labelsPerMarker;
};

export const mapSettingsIsMarkerClusteringForcedUnstackedMarkersSelector = (state: AppState) => {
  const isImageExport = !!mapSettingsExportImageSettingsSelector(state)?.mode;
  if (isImageExport) {
    return true;
  }

  return numberOfLabelsPerMarkerSelector(state) > MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING;
};

export const mapSettingsIsMarkerClusteringForcedSelector = createStateSelector([
  selectNumberOfStackedMarkersWithoutForcedUnstacking,
  numberOfLabelsPerMarkerSelector,
  mapSettingsExportImageSettingsSelector,
  (_state, ignoreImageExport?: boolean) => ignoreImageExport,
], (numberOfStackedMarkersWithoutForcedUnstacking, numberOfLabelsPerMarker, exportImage, ignoreImageExport): boolean => {
  const isImageExport = !!exportImage?.mode;

  if (isImageExport && !ignoreImageExport) {
    return true;
  }

  const numberOfStackedMarkers = numberOfStackedMarkersWithoutForcedUnstacking;
  const numberOfLabels = numberOfLabelsPerMarker + numberOfStackedMarkers;
  return numberOfLabels > MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING;
});
export const useMapSettingsIsClusteringForced = () => useSelector(mapSettingsIsMarkerClusteringForcedSelector);

export const mapSettingsMarkerClusterDenseMarkersSelector = createStateSelector([
  mapSettingsMarkerClusterDenseMarkersStateSelector,
  (state, ignoreImageExport?: boolean) => mapSettingsIsMarkerClusteringForcedSelector(state, ignoreImageExport),
], (clusterDenseMarkersState, isMarkerClusteringForced): boolean => (
  clusterDenseMarkersState || isMarkerClusteringForced
));
export const useMapSettingsClusterDenseMarkers = () => useSelector(mapSettingsMarkerClusterDenseMarkersSelector);

export const mapSettingsMarkerUnclusterBelowNSelector = (state: AppState) => {
  const unclusterBelowN = mapSettingsMarkerUnclusterBelowNStateSelector(state);

  const isImageExport = !!mapSettingsExportImageSettingsSelector(state)?.mode;
  if (isImageExport) {
    const hasOriginalMapClustersOn = mapSettingsMarkerClusterDenseMarkersSelector(state, true);
    if (hasOriginalMapClustersOn) {
      return EXPORT_IMAGE_ALLOWED_UNCLUSTER_OPTIONS.includes(unclusterBelowN) ? unclusterBelowN : EXPORT_IMAGE_DEFAULT_UNCLUSTER_OPTION;
    }
    else {
      return EXPORT_IMAGE_DEFAULT_UNCLUSTER_OPTION;
    }
  }

  return mapSettingsMarkerClusterDenseMarkersSelector(state) ? unclusterBelowN : null;
};
export const useMapSettingsMarkerUnclusterBelowNSelector = () => useSelector(mapSettingsMarkerUnclusterBelowNSelector);
