import { type FC } from 'react';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { MapListSortType } from './mapListing.helpers';
import { MobileMapListingComponent } from './mobileMapListing.component';
import { useMapListingToggleManagement } from './useMapListingToggleManagement.hook';

export const MobileMapListingContainer: FC = () => {
  const {
    onToggleMapSelect, onToggleSnapshotSelect, selectedMapIds, selectedSnapshotIds, onToggleShowMapSnapshots,
    mapListingItems, pagination, onPageChange, isLoading: isMapListingDataLoading, errorMessage, toggledMapIds,
  } = useMapListingToggleManagement(MapListSortType.Updated_DESC);

  const { openModal: openShareSettingsModal } = useModal(ModalType.ShareMap);

  return (
    <MobileMapListingComponent
      errorMessage={errorMessage}
      isMapListingDataLoading={isMapListingDataLoading}
      processedItems={mapListingItems}
      selectedMapIds={selectedMapIds}
      selectedSnapshotIds={selectedSnapshotIds}
      toggledMapIds={toggledMapIds}
      toggleMapSelection={onToggleMapSelect}
      toggleSnapshotSelection={onToggleSnapshotSelect}
      toggleSnapshotsVisibility={onToggleShowMapSnapshots}
      pagination={pagination}
      onPageChange={onPageChange}
      onMapShareSettingsClick={openShareSettingsModal}
    />
  );
};
