import { css } from '@emotion/react';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { isNumber } from '~/_shared/utils/typeGuards';
import {
  InvitationStatus, type Member, MemberStatus,
} from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import { TableAuxiliaryRowComponent } from '~/map/mapMigration/table/tableAuxiliaryRow.component';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import {
  clientMembersSelector, useUserIdSelector,
} from '~/store/userData/userData.selectors';

type MapDependenciesComponentProps = {
  readonly mapInfo: V4MapInfo;
};

type DependencyComponentProps = {
  readonly mapInfo: V4MapInfo;
  readonly currentUserId: number;
  readonly userMap: Record<Member['id'], Member>;
};

type NotOwnedByYouWarningComponentProps = {
  readonly ownerName: string;
};

const dependenciesParagraphStyles = css({ fontSize: 14 });

const NotOwnedByYouWarningComponent = ({ ownerName }: NotOwnedByYouWarningComponentProps) => {
  const [t] = useTranslation();
  return (
    <>
      {' '}
      <TooltipComponent tooltipContent={t('MapMigrationNotOwnedByYouWarning', { mapOwner: ownerName })}>
        <FontAwesomeIcon icon={faWarning} />
      </TooltipComponent>
    </>
  );
};

const unknownMember: Member = {
  id: -1,
  email: 'unknown@member.com',
  name: 'Unknown Member',
  invitation: InvitationStatus.UNKNOWN,
  role: 'Independent Member',
  status: MemberStatus.DISABLED,
};

const DependencyComponent = ({ mapInfo, currentUserId, userMap }: DependencyComponentProps) => {
  const dependencyOwner = isNumber(mapInfo.mapOwnerId) ? (userMap[mapInfo.mapOwnerId] ?? unknownMember) : unknownMember;
  return (
    <span>{mapInfo.title}{(currentUserId !== mapInfo.mapOwnerId) ? (
      <NotOwnedByYouWarningComponent ownerName={dependencyOwner.name} />
    ) : null}
    </span>
  );
};

const clientMemberMapSelector = createStateSelector([clientMembersSelector], (clientMemberArray) => {
  return Object.fromEntries(clientMemberArray.map(client => ([client.id, client])));
});

export const MapDependenciesComponent = ({ mapInfo }: MapDependenciesComponentProps) => {
  const { dependencies } = mapInfo;
  const userId = useUserIdSelector();
  const clientMemberMap = useSelector(clientMemberMapSelector);

  if ((dependencies.length === 0) || !userId) {
    return null;
  }

  return (
    <TableAuxiliaryRowComponent>
      <p css={dependenciesParagraphStyles}>
        Dependencies: {dependencies.map((mapInfo, index) => (
          <React.Fragment
            key={mapInfo.id}
          >
            {index === 0 ? '' : ', '}
            <DependencyComponent
              mapInfo={mapInfo}
              currentUserId={userId}
              userMap={clientMemberMap}
            />
          </React.Fragment>
        ))}
      </p>
    </TableAuxiliaryRowComponent>
  );
};
