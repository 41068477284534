import { css } from '@emotion/react';
import { type FC } from 'react';
import type { Theme } from '~/_shared/themes/theme.model';
import {
  OneDimensionalTableBody, type OneDimensionalTableBodyProps,
} from './oneDimensionalTableBody';
import { OneDimensionalTableHeader } from './oneDimensionalTableHeader';

const groupTableTitleStyle = (theme: Theme) => css({
  alignItems: 'center',
  color: theme.textColors.tertiary,
  display: 'flex',
  fontSize: 14,
  fontWeight: 'lighter',
  justifyContent: 'space-between',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  width: '100%',
});

const tableStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderCollapse: 'separate',
  borderSpacing: 0,
  position: 'relative',
  tableLayout: 'fixed',
  width: '100%',
});

const headingWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 4,
});

export type OneDimensionalTableWithHeaderProps = Readonly<{
  heading: string;
  subheader?: string;
  subheader2?: string;
  body: OneDimensionalTableBodyProps;
}>;

export const OneDimensionalTableWithHeader: FC<OneDimensionalTableWithHeaderProps> = (props) => {
  const { heading, subheader, subheader2, body } = props;

  return (
    <div css={tableStyle}>
      <div css={headingWrapperStyle}>
        <OneDimensionalTableHeader value={heading} />

        {!!subheader && (
          <div css={groupTableTitleStyle}>
            {subheader}
            {!!subheader2 && (
              <div>{subheader2}</div>
            )}
          </div>
        )}
      </div>

      <OneDimensionalTableBody
        {...body}
      />
    </div>
  );
};
