import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { InputWithSpinnersComponent } from '~/_shared/baseComponents/inputs';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerAlignment } from '~/_shared/components/colorPicker/colorPickerHolder/colorPickerHolder.component';
import { ColorPickerWithInputBarComponent } from '~/_shared/components/colorPicker/colorPickerWithInputBar/colorPickerWithInputBar.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  GroupRadiusEditLevel, ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import {
  isDriveTimePolygon, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  validateDecimalInputValue, validateValueHasOnlyNumericCharacters,
} from '~/_shared/utils/form/form.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GroupData } from '~/store/spreadsheetData/spreadsheetData.state';
import { getProximityColor } from '../proximityPanel.helpers';
import { GroupRadiusFormFieldsComponent } from './formFields/groupRadiusFormFields.component';
import { PolygonDriveTimeFormFieldsComponent } from './formFields/polygonDriveTimeFormFields.component';
import { SingleRadiusFormFieldsComponent } from './formFields/singleRadiusFormFields.component';
import { ProximityPanelDropdownComponent } from './inputs/proximityPanelDropdown.component';
import { ProximityPanelErrorComponent } from './inputs/proximityPanelError.component';
import { ProximityPanelInputLabelComponent } from './inputs/proximityPanelInputLabel.component';
import {
  getProximityTypeDropdownOptions, validateProximity,
} from './proximityPanelForm.helpers';

type ProximityPanelFormProps = Readonly<{
  address: string;
  applyTo: GroupRadiusEditLevel;
  borderColor: ColorResult;
  borderLineWidth: number;
  className?: string;
  hideLabels: boolean;
  hideSmallRadii: boolean;
  isFetchingGeoLocation: boolean;
  isGeoError: boolean;
  proximityColor: ColorResult;
  proximityList: ReadonlyArray<Proximity>;
  proximityWithin: number | null;
  selectedGroup: GroupData | null;
  selectedGroupColumnId: SpreadsheetColumnId | null;
  showLabelsHiddenWarningDueToPerformance: boolean;
  showLabelsHiddenWarningDueToTooSmall: boolean;
  spreadsheetColumns: ReadonlyArray<SpreadsheetColumn>;
  travelTimeHours: number | null;
  travelTimeMinutes: number | null;
  type: ProximityType;
  unit: UnitSystem;

  addProximityRadius: () => void;
  onAddressChange: (address: string) => void;
  onGetUserLocalizationClick: () => void;
  onSetApplyTo: (applyTo: GroupRadiusEditLevel) => void;
  onSetHideLabels: (hideLabels: boolean) => void;
  onSetHideSmallRadii: (hideSmallRadii: boolean) => void;
  onSetProximityColor: (proximityColor: ColorResult) => void;
  onSetBorderColor: (borderColor: ColorResult) => void;
  onSetBorderLineWidth: (borderLineWidth: number) => void;
  onSetProximityWithin: (proximityWithin: number | null) => void;
  onSetSelectedGroup: (selectedGroup: GroupData | null) => void;
  onSetSelectedGroupColumn: (selectedGroupColumnId: SpreadsheetColumnId) => void;
  onSetTravelTimeHours: (travelTimeHours: number | null) => void;
  onSetTravelTimeMinutes: (travelTimeMinutes: number | null) => void;
  onSetType?: (type: ProximityType) => void;
  onSetUnit: (unit: UnitSystem) => void;
}>;

const addProximityButtonStyle = css({
  width: '100%',
  height: 48,
  marginTop: 16,
});

const checkboxWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginTop: 34,
});

const checkboxStyle = css({
  fontSize: '14px',
});

const checkboxTextStyle = css({
  marginLeft: 5,
});

const checkboxCheckmarkStyle = ({ theme, isChecked }: ThemeProps<{ isChecked: boolean }>) => css({
  width: 20,
  height: 20,
  border: '1px solid ' + (isChecked
    ? theme.checkboxColors.checkedFill : theme.checkboxColors.uncheckedBorder),
});

const borderColorAndStrokeContainer = css({
  display: 'flex',
  width: '100%',
});

const borderStrokeInput = css({
  borderRadius: 4,
  minWidth: 0,
  flex: 1,
  marginRight: 10,
});

const borderColorPicker = css({
  flex: 1,
  minWidth: 180,
});

export const ProximityPanelFormComponent: FC<ProximityPanelFormProps> = (props) => {
  const [localProximityWithin, setLocalProximityWithin] = useState<string>(props.proximityWithin?.toString() ?? '');
  const { onSetProximityColor, onSetHideLabels, onSetHideSmallRadii } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const typeDropdownOptions = useMemo(() => getProximityTypeDropdownOptions(t), [t]);

  const toggleHideLabels = useCallback(() => onSetHideLabels(!props.hideLabels), [onSetHideLabels, props.hideLabels]);

  const toggleHideSmallRadii = useCallback(() => onSetHideSmallRadii(!props.hideSmallRadii), [onSetHideSmallRadii, props.hideSmallRadii]);

  const onSetProximityWithin = useCallback((newProximityWithin: string) => {
    if (!validateValueHasOnlyNumericCharacters(newProximityWithin, { isDecimal: true })) {
      return;
    }

    setLocalProximityWithin(newProximityWithin);
    if (validateDecimalInputValue(newProximityWithin)) {
      const propagate = props.onSetProximityWithin;
      propagate(parseFloat(newProximityWithin));
    }
  }, [props.onSetProximityWithin]);

  useEffect(() => {
    onSetProximityColor(getProximityColor(props.proximityList.map(item => item.styles.color)));
  }, [onSetProximityColor, props.proximityList]);

  const isDriveTimeDataPending = useMemo(() => props.proximityList.some(proximity => isDriveTimePolygon(proximity) && proximity.data.paths === undefined), [props.proximityList]);

  return (
    <div
      className={props.className}
      css={{ position: 'relative' }}
    >
      {isDriveTimeDataPending &&
        <OverlayLoaderComponent />
      }
      <ProximityPanelInputLabelComponent>{t('Type')}</ProximityPanelInputLabelComponent>
      <ProximityPanelDropdownComponent
        onChange={props.onSetType || noop}
        value={props.type}
        options={typeDropdownOptions}
        isDisabled={!props.onSetType}
      />

      {props.type === ProximityType.DriveTimePolygon
        ? (
          <PolygonDriveTimeFormFieldsComponent
            address={props.address}
            onChangeAddress={props.onAddressChange}
            onGetUserLocation={props.onGetUserLocalizationClick}
            isFetchingGeolocation={props.isFetchingGeoLocation}
            hasGeolocationError={props.isGeoError}
            travelTimeMinutes={props.travelTimeMinutes}
            onChangeTravelTimeMinutes={props.onSetTravelTimeMinutes}
            travelTimeHours={props.travelTimeHours}
            onChangeTravelTimeHours={props.onSetTravelTimeHours}
          />
        )
        : props.type === ProximityType.DistanceRadius && props.applyTo === GroupRadiusEditLevel.IndividualLocation
          ? (
            <SingleRadiusFormFieldsComponent
              applyTo={props.applyTo}
              onChangeApplyTo={props.onSetApplyTo}
              address={props.address}
              onChangeAddress={props.onAddressChange}
              onGetUserLocation={props.onGetUserLocalizationClick}
              isFetchingGeolocation={props.isFetchingGeoLocation}
              hasGeolocationError={props.isGeoError}
              proximityWithin={localProximityWithin}
              onChangeProximityWithin={onSetProximityWithin}
              unit={props.unit}
              onUnitChange={props.onSetUnit}
              canSelectGroupRadius={props.spreadsheetColumns.length > 0}
            />
          )
          : props.type === ProximityType.DistanceRadius && props.applyTo === GroupRadiusEditLevel.Group && props.spreadsheetColumns.length > 0
            ? (
              <GroupRadiusFormFieldsComponent
                applyTo={props.applyTo}
                onChangeApplyTo={props.onSetApplyTo}
                proximityWithin={localProximityWithin}
                onChangeProximityWithin={onSetProximityWithin}
                unit={props.unit}
                onUnitChange={props.onSetUnit}
                columns={props.spreadsheetColumns}
                selectedColumnId={props.selectedGroupColumnId}
                onChangeSelectedColumnId={props.onSetSelectedGroupColumn}
                selectedGroup={props.selectedGroup}
                onChangeSelectedGroup={props.onSetSelectedGroup}
              />
            )
            : null
      }

      <ProximityPanelInputLabelComponent>{t('Proximity Color')}</ProximityPanelInputLabelComponent>
      <ColorPickerWithInputBarComponent
        selectedColor={props.proximityColor}
        onChange={props.onSetProximityColor}
        alignment={ColorPickerAlignment.BottomRight}
        isFixed
      />

      <ProximityPanelInputLabelComponent>{t('Border line Color & Stroke')}</ProximityPanelInputLabelComponent>
      <div css={borderColorAndStrokeContainer}>
        <InputWithSpinnersComponent
          css={borderStrokeInput}
          value={props.borderLineWidth}
          valueMin={1}
          valueMax={20}
          onChange={props.onSetBorderLineWidth}
        />

        <ColorPickerWithInputBarComponent
          css={borderColorPicker}
          selectedColor={props.borderColor}
          onChange={props.onSetBorderColor}
          alignment={ColorPickerAlignment.BottomRight}
          isFixed
        />
      </div>

      <ButtonComponent
        css={addProximityButtonStyle}
        text={props.type === ProximityType.DriveTimePolygon ? t('Add Drive Time Polygon') : t('Add Proximity Radius')}
        isDisabled={!validateProximity({
          type: props.type,
          applyTo: props.applyTo,
          address: props.address,
          proximityWithin: localProximityWithin,
          selectedGroupColumn: props.selectedGroupColumnId,
          selectedGroup: props.selectedGroup,
          travelTimeMinutes: props.travelTimeMinutes,
          travelTimeHours: props.travelTimeHours,
        }) || props.isFetchingGeoLocation}
        onClick={props.addProximityRadius}
      />

      <div css={checkboxWrapperStyle}>
        <CheckboxComponent
          css={checkboxStyle}
          isChecked={props.hideLabels}
          checkedSetter={toggleHideLabels}
          text={t('Hide Labels')}
          checkmarkStyle={checkboxCheckmarkStyle({ theme, isChecked: props.hideLabels })}
          textStyle={checkboxTextStyle}
        />

        <ProximityPanelErrorComponent isDisplayed={props.showLabelsHiddenWarningDueToPerformance}>
          {t('Labels automatically hidden to improve performance. Zoom in to unhide.')}
        </ProximityPanelErrorComponent>

        <ProximityPanelErrorComponent isDisplayed={props.showLabelsHiddenWarningDueToTooSmall}>
          {t('Some labels are automatically hidden at this zoom level because they are too small to be visible.')}
        </ProximityPanelErrorComponent>

        <CheckboxComponent
          css={checkboxStyle}
          isChecked={props.hideSmallRadii}
          checkedSetter={toggleHideSmallRadii}
          text={t('Hide Small Radii')}
          checkmarkStyle={checkboxCheckmarkStyle({ theme, isChecked: props.hideSmallRadii })}
          textStyle={checkboxTextStyle}
        />
      </div>
    </div>
  );
};
