import { type ImageSize } from '../../constants/imageSize.enum';
import { type ImageFileType } from '../../constants/imageType.enum';

export type ExportImageParams = Readonly<{
  imageFileType: ImageFileType;
  imageSize: ImageSize;
  exportMode: ExportImageMode;
  exportLegendMode: ExportImageLegendMode | null;
  exportLocationListMode: ExportImageLocationListMode | null;
}>;

export enum ExportImageMode {
  Screenshot = 'Screenshot',
  Map = 'Map',
  Legend = 'Legend',
  MapAndLegend = 'MapAndLegend',
  LocationList = 'LocationList',
}

export enum ExportImageLegendMode {
  Boundary = 'Boundary',
  Grouping = 'Grouping',
}

export enum ExportImageLocationListMode {
  AllFiltered = 'AllFiltered',
  FilteredInBounds = 'FilteredInBounds',
}
