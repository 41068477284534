import { type DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { DRAWING_SETTINGS_SET_SETTINGS } from './drawingSettings.actionTypes';
import { type DrawingSettingsState } from './drawingSettings.state';

export const drawingSettingsSetSettings = (drawingTool: DrawingTool, settings: DrawingSettingsState) => ({
  type: DRAWING_SETTINGS_SET_SETTINGS,
  payload: {
    drawingTool,
    settings,
  },
}) as const;
