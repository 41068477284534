import type { DrawingItemsContextMenuAction } from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.action';
import {
  hideDrawingItemsContextMenu,
  showDrawingItemsContextMenu,
} from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.actionCreators';
import type { DrawingItemsContextMenuState } from './drawingItemsContextMenu.state';

const initialState: DrawingItemsContextMenuState = {
  isVisible: false,
  drawingItem: null,
  position: null,
};

export const drawingItemsContextMenuReducer = (state: DrawingItemsContextMenuState = initialState, action: DrawingItemsContextMenuAction): DrawingItemsContextMenuState => {
  if (showDrawingItemsContextMenu.match(action)) {
    return {
      ...state,
      isVisible: true,
      position: action.payload.position,
      drawingItem: action.payload.drawingItem,
    };
  }

  if (hideDrawingItemsContextMenu.match(action)) {
    return {
      ...state,
      isVisible: false,
      position: null,
      drawingItem: null,
    };
  }

  return state;
};
