import {
  actionChannel, type ActionPattern, call, debounce, put, take, takeLatest,
} from 'redux-saga/effects';
import { shouldOnlyShowBoundariesWithFill } from '~/map/map/boundary/boundaryView/mapBoundary.helpers';
import { BOUNDARY_GROUP_FETCH_ALL_SUCCESS } from '~/store/boundaryGroups/boundaryGroups.actionTypes';
import { type LastBounds } from '~/store/frontendState/mapComponent/mapComponent.state';
import { type BoundarySelectState } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import { FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM } from '~/store/frontendState/processing/processing.actionTypes';
import { firstZoomDoneSelector } from '~/store/frontendState/processing/processing.selectors';
import {
  type BoundaryFilters,
  boundaryFiltersSelector,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { isBoundaryGroupHidden } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { isMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { type LatLngBounds } from '../../_shared/types/latLng';
import { validateLatLngBounds } from '../../_shared/utils/gis/boundingBox.helpers';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { type BoundaryGroup } from '../../boundary/boundary.types';
import { type BoundingBox } from '../../map/map/boundingBox';
import { isCustomGroup } from '../boundaryGroups/boundaryGroups.selectors';
import {
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { type BoundaryTerritoryGroup } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { MAP_COMPONENT_SET_LAST_BOUNDS } from '../frontendState/mapComponent/mapComponent.actionTypes';
import {
  BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
  BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL,
} from '../frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionTypes';
import { frontedStateProcessingBoundariesInitialized } from '../frontendState/processing/processing.actionCreators';
import { type BoundaryItemsAction } from './boundaryItems.action';
import {
  boundaryItemsFetchCancel,
  boundaryItemsFetchedData,
  boundaryItemsFetchError,
  boundaryItemsFetchRequest,
  boundaryItemsFetchSuccess,
} from './boundaryItems.actionCreators';
import { BOUNDARY_ITEMS_FETCH_REQUEST } from './boundaryItems.actionTypes';
import {
  mapGeometryResponseToStateData, mapQueryResponseToStateData, type ResponseToStateMappingType,
} from './boundaryItems.factory';
import {
  getBoundaryRequestMapZoom, getRequestMapZoomFromZoomLevels,
} from './boundaryItems.helpers';
import { CONTINUATION_TOKEN_ALL_ITEMS_LOADED } from './boundaryItems.reducer';
import {
  getMapBoundaryGeometry,
  getMapBoundaryQuery, type MapBoundaryGeometryResponse,
  type MapBoundaryQueryRequestItem,
  type MapBoundaryQueryResponse,
} from './boundaryItems.repository';

export function* boundaryItemsSagas() {
  yield debounce(
    1000,
    [
      MAP_COMPONENT_SET_LAST_BOUNDS,
      BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
      BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
      BOUNDARY_GROUP_FETCH_ALL_SUCCESS,
      FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM,
    ],
    requestBoundaryItems
  );
  yield takeLatest([
    BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
    BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
    BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL],
  requestBoundaryItems);
  yield watchBoundaryFetchRequests();
}

export function* requestBoundaryItems() {
  const mapId: number | null = yield select<number | null>(state => state.map.mapId);
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const lastBounds: LastBounds | null = yield select<LastBounds | null>(state => state.frontendState.mapComponent.lastBounds);
  const firstZoomDone: boolean = yield select<boolean>(firstZoomDoneSelector);

  if (mapId === null || clientId === null || lastBounds === null || !firstZoomDone) {
    return;
  }

  yield put(boundaryItemsFetchRequest(mapId, clientId, lastBounds.bounds, lastBounds.zoomLevel));
}

function* watchBoundaryFetchRequests() {
  const requestChan: ActionPattern<Action> = yield actionChannel(BOUNDARY_ITEMS_FETCH_REQUEST);

  while (true) {
    const action: PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_FETCH_REQUEST> = yield take(requestChan);
    yield fetchBoundaryGroups(
      action.payload.mapId,
      action.payload.clientId,
      action.payload.lastBoundingBox,
      action.payload.mapZoomLevel
    );
  }
}

const entireMapBoundingBox: LatLngBounds = {
  ne: {
    lat: 90,
    lng: 180,
  },
  sw: {
    lat: -90,
    lng: -180,
  },
};

function* fetchBoundaryGroups(mapId: number, clientId: number, lastBoundingBox: BoundingBox, mapZoomLevel: number) {
  const continuationTokens: Map<number, Map<number, string>> = yield select(
    state => state.boundaries.items.continuationToken
  );
  const boundaryGroups: ReadonlyArray<BoundaryGroup> = yield select<ReadonlyArray<BoundaryGroup>>(
    state => state.boundaries.groups.groups
  );
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(
    state => state.map.boundaryTerritoryGroups.groups
  );
  const boundarySelect: BoundarySelectState = yield select<BoundarySelectState>(
    state => state.frontendState.mapTools.boundary.boundarySelect
  );
  const boundaryFilters: BoundaryFilters = yield select<BoundaryFilters>(boundaryFiltersSelector);

  const boundaryGroupsToFetch = boundaryTerritoryGroups.filter((btg) => {
    const boundaryGroup = boundaryGroups.find(g => g.id === btg.boundaryGroupId);
    if (!boundaryGroup) {
      return false;
    }

    const isCustom = isCustomGroup(boundaryGroup);
    if (isCustom) {
      // Custom boundary groups needs their items fetched under all conditions.
      // They are displayed in the Boundary / Territory Tool.
      return true;
    }

    const isHidden = isBoundaryGroupHidden(boundaryFilters.get(btg.boundaryGroupId));

    const hasBoundaryTerritories = !!btg.settings.boundaryTerritories.length;
    const showOnlyBoundariesWithFill = shouldOnlyShowBoundariesWithFill(mapZoomLevel, boundaryGroup, btg.settings.boundaryTerritoryType);
    const visibleOnZoomLevel = !showOnlyBoundariesWithFill || hasBoundaryTerritories;

    return !isHidden && visibleOnZoomLevel;
  }).map(btg => btg.boundaryGroupId);

  if (boundarySelect.selectBoundaryGroupId && !boundaryGroupsToFetch.includes(boundarySelect.selectBoundaryGroupId)) {
    boundaryGroupsToFetch.push(boundarySelect.selectBoundaryGroupId);
  }

  const boundaryGroupsToFetchViaGeometry: number[] = [];
  const boundaryGroupsToFetchViaQuery: number[] = [];

  const isMapPresentational: boolean = yield select(isMapPresentationalSelector);

  boundaryGroupsToFetch.forEach((boundaryGroupId) => {
    const boundaryGroup = boundaryGroups.find(g => g.id === boundaryGroupId);
    if (!boundaryGroup) {
      return;
    }

    const zoomLevelToFetch = getRequestMapZoomFromZoomLevels(mapZoomLevel, boundaryGroup.zoomLevels);

    const supportsCurrentZoom = boundaryGroup.zoomLevels.some(level => level <= mapZoomLevel);
    if (!supportsCurrentZoom) {
      return;
    }

    const isAlreadyLoaded = continuationTokens.get(boundaryGroupId)?.get(zoomLevelToFetch) === CONTINUATION_TOKEN_ALL_ITEMS_LOADED;
    if (isAlreadyLoaded) {
      return;
    }

    if (isCustomGroup(boundaryGroup)) {
      if (boundaryGroup.wms?.wmsTemporary && boundaryGroup.wms.geometryRemainingCalls === 0) {
        return;
      }

      // Use query for custom boundaries. Geometry endpoint uses client caching and
      // was not yet tested with boundary groups that could be modified (custom).
      boundaryGroupsToFetchViaQuery.push(boundaryGroupId);
      return;
    }

    if (zoomLevelToFetch === 0 && !isMapPresentational) {
      boundaryGroupsToFetchViaGeometry.push(boundaryGroupId);
    }
    else {
      boundaryGroupsToFetchViaQuery.push(boundaryGroupId);
    }
  });

  if (boundaryGroupsToFetchViaGeometry.length === 0 && boundaryGroupsToFetchViaQuery.length === 0) {
    yield put(boundaryItemsFetchCancel());
    yield put(frontedStateProcessingBoundariesInitialized());
    return;
  }

  const boundingBox: LatLngBounds = {
    ne: lastBoundingBox.getNorthEast(),
    sw: lastBoundingBox.getSouthWest(),
  };

  if (!validateLatLngBounds(boundingBox)) {
    yield put(boundaryItemsFetchCancel());
    yield put(frontedStateProcessingBoundariesInitialized());
    return;
  }

  try {
    let stateData: ResponseToStateMappingType[] = [];

    if (boundaryGroupsToFetchViaGeometry.length) {
      const MapBoundaryGeometryResponse: MapBoundaryGeometryResponse[] = yield call(getMapBoundaryGeometry, clientId, boundaryGroupsToFetchViaGeometry);

      const mappedGeometryStateData = mapGeometryResponseToStateData(MapBoundaryGeometryResponse);
      stateData = stateData.concat(mappedGeometryStateData);
    }

    if (boundaryGroupsToFetchViaQuery.length) {
      const queries: MapBoundaryQueryRequestItem[] = boundaryGroupsToFetchViaQuery.map((boundaryGroupId) => {
        const requestMapZoom = getBoundaryRequestMapZoom(mapZoomLevel, boundaryGroupId, boundaryGroups);
        const continuationToken = continuationTokens.get(boundaryGroupId)?.get(requestMapZoom);
        const boundaryGroup = boundaryGroups.find(group => group.id === boundaryGroupId);

        return ({
          zoom_level: mapZoomLevel,
          boundary_group_id: boundaryGroupId,
          bounding_box: boundaryGroup && isCustomGroup(boundaryGroup) ? entireMapBoundingBox : boundingBox,
          continuation_token: continuationToken,
        });
      });

      const mapBoundaryQueryResults: { data: MapBoundaryQueryResponse } = yield call(getMapBoundaryQuery, clientId, {
        map_id: mapId,
        query: queries,
      });

      const mappedQueryStateData = mapQueryResponseToStateData(mapBoundaryQueryResults);
      stateData = stateData.concat(mappedQueryStateData);
    }

    for (const data of stateData) {
      yield put(boundaryItemsFetchedData(
        data.boundaryGroupId,
        data.stateItems,
        data.zoomLevel,
        data.continuationToken,
        data.done,
      ));
    }

    const isRenderingExpected = stateData.some((data) => data.stateItems.length > 0
      && !isBoundaryGroupHidden(boundaryFilters.get(data.boundaryGroupId)));

    yield put(boundaryItemsFetchSuccess(isRenderingExpected));

    const initialBoundariesRendered: boolean = yield select(state => state.frontendState.processing.initialBoundariesRendered);
    if (!initialBoundariesRendered && !isRenderingExpected) {
      yield put(frontedStateProcessingBoundariesInitialized());
    }
  }
  catch (e) {
    yield put(boundaryItemsFetchError(e));
  }
}
