import { copy } from '../../../../_shared/utils/collections/collections';
import {
  DRAWING_TOOLS_LIST, DrawingTool,
} from '../../../../drawingTool/drawingTool.enums';
import { type DrawingItemsAction } from '../../../mapSettings/drawing/items/drawingItems.action';
import { MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM } from '../../../mapSettings/drawing/items/drawingItems.actionTypes';
import { type DrawingToolAction } from './drawingTool.action';
import {
  FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL,
  FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL,
  FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY,
  FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY,
} from './drawingTool.actionTypes';
import { type DrawingToolState } from './drawingTool.state';

const initialState: DrawingToolState = {
  isActive: false, // start / stop
  isDrawingActive: false, // pause / resume
  activeTool: DrawingTool.Management,
  hiddenTools: new Set(),
};

export const drawingToolReducer = (
  state = initialState, action: DrawingToolAction | DrawingItemsAction
): DrawingToolState => {
  switch (action.type) {
    case FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL: {
      return {
        ...state,
        activeTool: DrawingTool.Management,
        isDrawingActive: false,
        isActive: false,
      };
    }

    case FRONTEND_STATE_DRAWING_TOOL_SET_ACTIVE_TOOL: {
      return {
        ...state,
        activeTool: action.payload.tool,
      };
    }

    case FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE: {
      return {
        ...state,
        isDrawingActive: true,
        isActive: true,
      };
    }

    case FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE: {
      return {
        ...state,
        isDrawingActive: false,
      };
    }

    case FRONTEND_STATE_DRAWING_TOOL_SET_TOOL_VISIBILITY: {
      const newHiddenToolsSet = new Set(state.hiddenTools);

      if (action.payload.isVisible) {
        newHiddenToolsSet.delete(action.payload.drawingTool);
      }
      else {
        newHiddenToolsSet.add(action.payload.drawingTool);
      }

      return {
        ...state,
        hiddenTools: newHiddenToolsSet,
      };
    }

    case FRONTEND_STATE_DRAWING_TOOL_SET_ALL_TOOLS_VISIBILITY: {
      if (action.payload.areToolsVisible) {
        return {
          ...state,
          hiddenTools: new Set(),
        };
      }
      else {
        return {
          ...state,
          hiddenTools: new Set(DRAWING_TOOLS_LIST),
        };
      }
    }

    // show drawing items after adding a new instance of specific type
    case MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM: {
      const newHiddenTools = copy.andRemove(state.hiddenTools, [action.payload.drawingItem.type]);

      return {
        ...state,
        hiddenTools: newHiddenTools,
      };
    }

    default: {
      return state;
    }
  }
};
