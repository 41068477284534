import {
  type FC, memo, type ReactNode,
} from 'react';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type MapObjectZIndex } from '../mapObject.types';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { type MapArrowInstance } from './mapArrowModel';
import { useMapArrowOutlines } from './useMapArrowOutlines';

type MapArrowProps = {
  arrow: MapArrowInstance;
  zIndex: MapObjectZIndex;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  renderArrow?: (key: string, arrow: MapArrowInstance) => ReactNode;
};

const MapArrowContainer: FC<MapArrowProps> = (props) => {
  const { arrow, zIndex } = props;
  const { renderArrow, renderOutline } = props;

  const manager = useMapObjectManager();
  const outlines = useMapArrowOutlines(arrow, {
    predicate: !!renderOutline,
  });

  return (
    <MapObjectContextProvider value={{ manager, zIndex }}>
      {renderArrow?.(arrow.id, arrow)}
      {renderOutline && outlines.map(
        outline => renderOutline(outline.id, outline))
      }
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(MapArrowContainer);
export { pureComponent as MapArrowContainer };
