import {
  faHourglassHalf, faHourglassStart,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useMemo,
} from 'react';
import {
  InputWithSpinnersComponent, SuffixText, TextInputComponent,
  TextInputWithSuffixComponent,
} from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithColorTileComponent } from '~/_shared/components/colorPicker/colorPickerWithColorTile/colorPickerWithColorTile.component';
import type { SliderValue } from '~/_shared/components/slider/slider.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import {
  Breakpoint,
  breakpoints,
} from '~/_shared/style.mixins';
import { type UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { GroupRadiusEditLevel } from '~/_shared/types/proximity/proximity.enums';
import { handleNumericInputChange } from '~/_shared/utils/form/form.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useWindowWidth } from '~/_shared/utils/hooks/useWindowWidth';
import {
  borderStrokeInput,
  COLOR_TILE_DIMENSION,
  columnStyle,
  columnsWrapper,
  labelStyle,
  mobileWrapper,
  travelHoursColumnStyle,
  travelMinutesColumnStyle,
  travelTimeInputsWrapperStyle,
} from '~/proximity/edit/proximityEdit.styles';
import { UnitDropdownComponent } from '../panel/form/formFields/unitDropdown.component';
import { validateDriveTimePolygonTime } from '../panel/form/proximityPanelForm.helpers';

export type ProximityEditProperties = {
  borderLineColor: ColorResult;
  borderLineWidth: number;
  borderOpacity: number;
  distance?: string;
  fillColor: ColorResult;
  fillOpacity: number;
  hours?: number;
  minutes?: number;
  name: string;
  radiusApplyTo: GroupRadiusEditLevel;
  unit: UnitSystem;
};

export type ProximityEditBodyProps = ProximityEditProperties & {
  setBorderLineColor: (color: ColorResult) => void;
  setBorderLineOpacity: (value: SliderValue) => void;
  setBorderLineWidth: (value: number) => void;
  setDistance?: (value: string) => void;
  setFillColor: (color: ColorResult) => void;
  setFillOpacity: (value: SliderValue) => void;
  setHours?: (value: number) => void;
  setMinutes?: (value: number) => void;
  setName: (value: string) => void;
  setRadiusApplyTo?: (value: GroupRadiusEditLevel) => void;
  setUnit?: (value: UnitSystem) => void;
};

export const ProximityEditBodyComponent: FC<ProximityEditBodyProps> = (props) => {
  const [t] = useTranslation();
  const { setDistance, setUnit, setHours, setMinutes, setRadiusApplyTo } = props;
  const windowWidth = useWindowWidth();

  const isFullSizeVersion = windowWidth > breakpoints[Breakpoint.small];

  const nameOption = useMemo(() => (
    <div>
      <p css={labelStyle}>{t('Proximity Name')}</p>
      <TextInputComponent
        onChange={props.setName}
        value={props.name}
        placeholder={t('Proximity Name')}
      />
    </div>
  ), [props.name, props.setName, t]);

  const fillOption = useMemo(() => (
    <>
      <ColorPickerWithColorTileComponent
        height={COLOR_TILE_DIMENSION}
        isFixed
        label={t('Proximity Fill Color')}
        onChange={props.setFillColor}
        selectedColor={props.fillColor}
        width={COLOR_TILE_DIMENSION}
        displayAlpha={false}
      />

      <div>
        <p css={labelStyle}>{t('Proximity Fill Opacity')}</p>
        <SliderWithValueLabelsComponent
          value={[props.fillOpacity * 100]}
          onChange={props.setFillOpacity}
          currentValueOnTheRight
          valueSuffix="%"
        />
      </div>
    </>
  ), [props.fillColor, props.fillOpacity, props.setFillColor, props.setFillOpacity, t]);

  const borderOption = useMemo(() => (
    <>
      <ColorPickerWithColorTileComponent
        height={COLOR_TILE_DIMENSION}
        isFixed
        label={t('Border Line Color')}
        onChange={props.setBorderLineColor}
        selectedColor={props.borderLineColor}
        width={COLOR_TILE_DIMENSION}
        displayAlpha={false}
      />

      <div>
        <p css={labelStyle}>{t('Border Line Opacity')}</p>
        <SliderWithValueLabelsComponent
          value={[(props.borderOpacity) * 100]}
          onChange={props.setBorderLineOpacity}
          currentValueOnTheRight
          valueSuffix="%"
        />
      </div>

      <div>
        <p css={labelStyle}>{t('Border Line Stroke')}</p>
        <InputWithSpinnersComponent
          onChange={props.setBorderLineWidth}
          value={props.borderLineWidth}
          css={borderStrokeInput}
          valueMin={1}
          valueMax={20}
        />
      </div>
    </>
  ), [props.borderLineColor, props.borderLineWidth, props.borderOpacity, props.setBorderLineColor,
    props.setBorderLineOpacity, props.setBorderLineWidth, t]);

  const applyToOption = useMemo(() => (
    setRadiusApplyTo && (
      <div>
        <p css={labelStyle}>{t('Apply to')}</p>
        <RadioGroupComponent<GroupRadiusEditLevel>
          onValueChange={value => setRadiusApplyTo(value)}
          selectedValue={props.radiusApplyTo}
          items={[{
            value: GroupRadiusEditLevel.IndividualLocation,
            description: t('Just this Radius'),
          }, {
            value: GroupRadiusEditLevel.Group,
            description: t('All Radii in Group'),
          }]}
        />
      </div>
    )
  ), [props.radiusApplyTo, setRadiusApplyTo, t]);

  const distanceOption = useMemo(() => (
    setDistance && setUnit && (
      <div>
        <p css={labelStyle}>{t('Proximity Within')}</p>
        <UnitDropdownComponent
          onChange={setDistance}
          onUnitChange={setUnit}
          unit={props.unit}
          value={props.distance ?? ''}
        />
      </div>
    )
  ), [props.distance, props.unit, setDistance, setUnit, t]);

  const timeOption = useMemo(() => (
    setHours && setMinutes && (
      <div>
        <p css={labelStyle}>{t('Proximity (Travel Time)')}</p>
        <div css={travelTimeInputsWrapperStyle}>
          <div css={travelHoursColumnStyle}>
            <TextInputWithSuffixComponent
              value={props.hours?.toString() || ''}
              onChange={value => handleNumericInputChange(value, setHours)}
              suffix={<SuffixText>{t('hrs')}</SuffixText>}
              icon={faHourglassStart}
              isInvalid={props.hours !== null && !validateDriveTimePolygonTime(props.hours || null, props.minutes || null)}
            />
          </div>
          <div css={travelMinutesColumnStyle}>
            <TextInputWithSuffixComponent
              value={props.minutes?.toString() || ''}
              onChange={value => handleNumericInputChange(value, setMinutes)}
              suffix={<SuffixText>{t('min')}</SuffixText>}
              icon={faHourglassHalf}
              isInvalid={props.minutes !== null && !validateDriveTimePolygonTime(props.hours || null, props.minutes || null)}
            />
          </div>
        </div>
      </div>
    )
  ), [props.hours, props.minutes, setHours, setMinutes, t]);

  return (
    isFullSizeVersion ? (
      <div css={columnsWrapper}>
        <div css={columnStyle}>
          {nameOption}
          {fillOption}
          {applyToOption}
        </div>

        <div css={columnStyle}>
          {distanceOption}
          {timeOption}
          {borderOption}
        </div>
      </div>
    ) : (
      <div css={mobileWrapper}>
        {nameOption}
        {distanceOption}
        {timeOption}
        {fillOption}
        {borderOption}
        {applyToOption}
      </div>
    )
  );
};
