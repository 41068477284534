import { type BoundaryMultiPolygon } from '~/store/boundaryItems/boundaryItems.state';
import {
  BOUNDARY_POLYGON_UPDATE_ERROR,
  BOUNDARY_POLYGON_UPDATE_FINISHED,
  BOUNDARY_POLYGON_UPDATE_REQUEST,
  BOUNDARY_POLYGON_UPDATE_STARTED,
} from './boundaryPolygonUpdate.actionTypes';

export const updateBoundaryPolygonPaths = (boundaryId: number, boundaryGroupId: number, multiPolygon: BoundaryMultiPolygon) => ({
  type: BOUNDARY_POLYGON_UPDATE_REQUEST,
  payload: {
    boundaryId,
    boundaryGroupId,
    multiPolygon,
  },
}) as const;

export const boundaryPolygonSaveStarted = () => ({
  type: BOUNDARY_POLYGON_UPDATE_STARTED,
}) as const;

export const boundaryPolygonSaveFinished = () => ({
  type: BOUNDARY_POLYGON_UPDATE_FINISHED,
}) as const;

export const boundaryPolygonSaveError = () => ({
  type: BOUNDARY_POLYGON_UPDATE_ERROR,
}) as const;
