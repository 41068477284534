import {
  type MapSettingsDataResponse, type MapSettingsEtag,
} from '../../../map/map.repository';
import {
  MAP_SETTINGS_FETCH_DATA_ERROR,
  MAP_SETTINGS_FETCH_DATA_REQUEST,
  MAP_SETTINGS_FETCH_DATA_SUCCESS, MAP_SETTINGS_REMOVE_ACTIVE_ITEMS, MAP_SETTINGS_SYNC_CANCEL,
  MAP_SETTINGS_SYNC_ERROR,
  MAP_SETTINGS_SYNC_REQUEST,
  MAP_SETTINGS_SYNC_SUCCESS,
} from './mapSettingsData.actionTypes';

export function mapSettingsFetchDataRequest(clientId: number, mapId: number) {
  return {
    type: MAP_SETTINGS_FETCH_DATA_REQUEST,
    payload: {
      clientId,
      mapId,
    },
  } as const;
}

export function mapSettingsFetchDataSuccess(mapSettingsData: MapSettingsDataResponse) {
  return {
    type: MAP_SETTINGS_FETCH_DATA_SUCCESS,
    payload: {
      mapSettingsData,
    },
  } as const;
}

export function mapSettingsFetchDataError(error: Error) {
  return {
    type: MAP_SETTINGS_FETCH_DATA_ERROR,
    payload: {
      error,
    },
  } as const;
}

export function mapSettingsSyncRequest() {
  return {
    type: MAP_SETTINGS_SYNC_REQUEST,
  } as const;
}

export function mapSettingsSyncSuccess(etag?: Partial<MapSettingsEtag>) {
  return {
    type: MAP_SETTINGS_SYNC_SUCCESS,
    payload: {
      etag,
    },
  } as const;
}

export function mapSettingsSyncCancel() {
  return {
    type: MAP_SETTINGS_SYNC_CANCEL,
  } as const;
}

export function mapSettingsSyncError() {
  return {
    type: MAP_SETTINGS_SYNC_ERROR,
  } as const;
}

export function mapSettingsRemoveActiveItems() {
  return {
    type: MAP_SETTINGS_REMOVE_ACTIVE_ITEMS,
  } as const;
}
