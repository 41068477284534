import {
  apiPatch, apiPost,
} from '../../_shared/utils/api/api.helpers';
import { type PasswordErrorType } from './passwordErrorType';

export type PasswordResetResponse = {
  message: PasswordErrorType | string;
};

export const sendPasswordResetEmailRequest = (email: string): Promise<PasswordResetResponse> => {
  const requestUrl = '/api/auth/reset/password';
  return apiPost(requestUrl, {
    email,
  });
};

export const sendPasswordResetRequest = (
  email: string, expires: number, hash: string, signature: string,
  password: string, password_confirmation: string,
): Promise<PasswordResetResponse> => {
  const requestUrl = `/api/auth/reset/password?email=${encodeURIComponent(email)}&expires=${expires}&hash=${hash}&signature=${signature}`;
  return apiPatch(requestUrl, {
    password,
    password_confirmation,
  });
};
