import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  useDeleteEntityAccess,
  useGetEntityAccesses,
  useUpdateEntityAccess,
} from '~/_shared/entityAccess/entityAccess.hooks';
import {
  type EntityAccess,
  EntityAccessLevel,
  EntityType,
  SharedEntityType, TeamShareMapPermission, teamShareMapPermissionToEntityAccessLevel,
} from '~/_shared/entityAccess/entityAccess.repository';
import { useChangeMapOwner } from '~/_shared/entityAccess/useChangeMapOwner';
import { useTranslation } from '~/_shared/utils/hooks';
import { useGetMembers } from '~/clientTeamManagement/teamManagementModal/useTeamManagement';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { useStorageService } from '../../_shared/utils/storageService';
import { AppErrorType } from '../../appError/appErrorType.enum';
import { useModal } from '../../modal/useModal.hook';
import { REMOVE_USER_FROM_MAP_NOT_SHOW_AGAIN_KEY } from './removeMemberFromMapModal/removeMemberFromMapModal.container';
import { TeamShareMapModalComponent } from './teamShareMapModal.component';

export type MapAccessData = EntityAccess & {
  name: string | null;
  email: string | null;
};

export type TeamShareMapModalContainerProps = ModalProps<{
  isSnapshot: boolean;
  mapId: number;
  mapName: string;
}>;

export const TeamShareMapModalContainer: FC<TeamShareMapModalContainerProps> = (props) => {
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const userId = useUserIdSelector();
  const { openModal: openRemoveMemberFromMapModal } = useModal(ModalType.RemoveMemberFromMap);
  const storageService = useStorageService();

  const {
    update: updateEntityAccess,
    isLoading: isUpdatingEntityAccess,
  } = useUpdateEntityAccess();

  const {
    delete: deleteEntityAccess,
    isLoading: isDeletingEntityAccess,
  } = useDeleteEntityAccess();

  const {
    update: changeMapOwner,
    isLoading: isChangingOwner,
  } = useChangeMapOwner();

  const {
    isLoading: isGettingEntityAccesses,
    data: entityAccessesData,
    invokeAjax: getEntityAccesses,
  } = useGetEntityAccesses(
    clientId, EntityType.MAP, SharedEntityType.USER, EntityAccessLevel.WRITE, props.mapId
  );

  const {
    isLoading: isGettingMembers,
    data: membersData,
    invokeAjax: getMembers,
  } = useGetMembers(clientId);

  const isLoading = useMemo(() => (
    isUpdatingEntityAccess || isDeletingEntityAccess || isChangingOwner || isGettingEntityAccesses
    || isGettingMembers
  ), [
    isUpdatingEntityAccess, isDeletingEntityAccess, isChangingOwner, isGettingEntityAccesses, isGettingMembers,
  ]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    getEntityAccesses();
  }, [getEntityAccesses]);

  const mapAccessData = useMemo(() => {
    if (!membersData || !entityAccessesData || !userId) {
      return null;
    }

    const entityAccessDataWithoutOwner = entityAccessesData.list.filter(
      (item) => item.ownerId !== item.sharedEntityId
    );

    const entityAccessDataWithOwner = [
      ...entityAccessDataWithoutOwner,
      {
        id: -1,
        ownerId: userId,
        entityType: EntityType.MAP,
        entityId: props.mapId,
        sharedEntityType: SharedEntityType.USER,
        sharedEntityId: userId,
        accessLevel: EntityAccessLevel.WRITE,
        createdAt: 'created_at',
        updatedAt: 'updated_at',
      },
    ];

    const newData = entityAccessDataWithOwner.map((item) => {
      const member = membersData.members.find(
        (memberItem) => memberItem.id?.toString() === item.sharedEntityId.toString()
      );
      return {
        ...item,
        name: member?.name || t('Unknown'),
        email: member?.email || t('Unknown'),
      };
    });

    return newData;
  }, [t, membersData, entityAccessesData, userId, props.mapId]);

  const dispatchErrorWarning = useCallback(() => {
    dispatch(
      createAppError({
        type: AppErrorType.General,
        title: t('Error'),
        content: t('teamShareMap.editError'),
        errorTitle: t('teamShareMap.editErrorTitle'),
      })
    );
  }, [dispatch, t]);

  const onUpdateAccess = useCallback((item: MapAccessData, newPermission: TeamShareMapPermission) => {
    if (!clientId || !userId) {
      return;
    }

    if (newPermission === TeamShareMapPermission.OWNER) {
      changeMapOwner(
        clientId,
        props.mapId,
        item.sharedEntityId,
      ).then(() => {
        location.reload();
      }).catch(() => {
        dispatchErrorWarning();
      });
    }
    else {
      updateEntityAccess(clientId, item.id, teamShareMapPermissionToEntityAccessLevel(newPermission))
        .then(() => {
          if (item.sharedEntityId === userId) {
            location.reload();
          }
          else {
            getEntityAccesses();
          }
        })
        .catch(() => {
          dispatchErrorWarning();
        });
    }

  }, [
    changeMapOwner, clientId, dispatchErrorWarning, getEntityAccesses, props.mapId,
    updateEntityAccess, userId,
  ]);

  const onDeleteAccess = useCallback((item: MapAccessData) => {
    if (!clientId) {
      return;
    }

    if (storageService.getLocalStorageItem(REMOVE_USER_FROM_MAP_NOT_SHOW_AGAIN_KEY)) {
      deleteEntityAccess(clientId, item.id)
        .then(() => {
          getEntityAccesses();
        })
        .catch(() => {
          dispatchErrorWarning();
        });
    }
    else {
      openRemoveMemberFromMapModal({
        entityAccessId: item.id,
        onClose: () => getEntityAccesses(),
      });
    }
  }, [storageService, clientId, deleteEntityAccess, dispatchErrorWarning, getEntityAccesses, openRemoveMemberFromMapModal]);

  return (
    <TeamShareMapModalComponent
      {...props}
      currentUserId={userId}
      isLoading={isLoading}
      mapAccessData={mapAccessData || []}
      onMapAccessChange={getEntityAccesses}
      onUpdateAccess={onUpdateAccess}
      onDeleteAccess={onDeleteAccess}
    />
  );
};
