import { type GeoUnits } from '~/store/boundaries/boundaries.repository';
import {
  type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';

export enum BoundaryGroupFromAIModalSteps {
  Name = 'Name',
  BuildingBlock = 'BuildingBlock',
  Variables = 'Variables',
  AdditionalOptions = 'AdditionalOptions',
}

export interface MetricValue {
  id: string;
  name: string;
  checked: boolean;
  weight: number;
  isConstraint: boolean;
  minConstraint?: number;
  maxConstraint?: number;
  min: number;
  max: number;
  numericPercent?: number;
}

export enum BuildingBlockSelections {
  ALL = 0,
  SUBSET = 1
}

export enum BuildingBlockSubsetTypes {
  FROM_DATA = 'from-data',
  ON_MAP_BY_LASSO = 'lasso',
  ON_MAP_BY_HOVER = 'hover',
  ON_MAP_BY_CLICK = 'click'
}

export type SalesRepLocationsMap = Readonly<{
  mapId: number;
  spreadsheetId: number;
}>;

export type CreateBoundaryGroupFromAIConfig = Readonly<{
  allStates: boolean;
  applyFilterOnData: boolean;
  buildingBlock: GeoUnits | null;
  buildingBlockSelection: BuildingBlockSelections;
  buildingBlockSubsetType: BuildingBlockSubsetTypes;
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  groupName: string;
  numberOfPreviousTerritories: number | null;
  numberOfSalesReps: number | null;
  numberOfTerritories: number | null;
  optimizationConstraints: boolean;
  overrideTerritory: boolean;
  previouslySavedBoundaryGroupId: BoundaryTerritoryGroupId | null;
  respectPrevTerritories: boolean;
  respectSalesLocations: boolean;
  salesRepLocationsMap: SalesRepLocationsMap | null;
  salesRepNameColumnId: string | null;
  selectedBoundaryIds: ReadonlyArray<BoundaryId>;
  useNumberOfTerritories: boolean;
  statesSubset?: string[];
  zipCodesSubset?: string[];
}>;

export enum NumberOfTerritoriesLastUpdateReason {
  Other = 'Other',
  ManualEdit = 'ManualEdit',
  RespectPreviousTerritoriesChange = 'RespectPreviousTerritoriesChange',
  SalesRepLocationsMapIdChange = 'SalesRepLocationsMapIdChange',
}
