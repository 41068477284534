import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { outlineVisuals } from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { createUuid } from '~/_shared/utils/createUuid';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingToolDisableDrawingMode } from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import {
  DrawingToolMode, useDrawingToolModeSelector, useMapToolsDrawingActiveToolSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingPolylineSettingsState } from '~/store/mapSettings/drawing/settings/polyline/drawingPolylineSettings.state';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { DrawMapShape } from '../../mapObjects/mapShape/drawMapShape.container';
import { MapShapeLine } from '../../mapObjects/mapShape/mapShapeLine.component';
import { type MapShapeInstance } from '../../mapObjects/mapShape/mapShapeModel';

type DrawingToolPolylineCreateNewInstanceProps = Readonly<{
  settings: DrawingPolylineSettingsState;
}>;

export const DrawingToolPolylineCreateNewInstanceContainer: FC<DrawingToolPolylineCreateNewInstanceProps> = ({ settings }) => {
  const [shapeKey, setShapeKey] = useState(createUuid());
  const [shape, setShape] = useState<MapShapeInstance | null>(null);
  const drawingToolMode = useDrawingToolModeSelector();
  const activeDrawingTool = useMapToolsDrawingActiveToolSelector();
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();

  const isActive = drawingToolMode === DrawingToolMode.DrawingItems && activeDrawingTool === DrawingTool.Polyline;

  const resetShape = useCallback(() => {
    setShape(null);
    setShapeKey(createUuid());
  }, []);

  useEffect(() => {
    if (!isActive) {
      resetShape();
    }
  }, [isActive, resetShape]);

  const onShapeChange = useCallback((shape: MapShapeInstance) => {
    setShape(shape);
  }, []);

  const renderOutline = useCallback((id: string, outline: MapObjectOutlineInstance) => (
    <MapOutline
      key={id}
      outline={outline}
      visuals={outlineVisuals}
    />
  ), []);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => {
    const borderColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    return (
      <MapShapeLine
        key={id}
        id={id}
        start={start}
        end={end}
        visuals={{
          width: settings.strokeWeight,
          style: 'solid',
          color: borderColor,
        }}
      />
    );
  }, [settings.strokeOpacity, settings.strokeColor, settings.strokeWeight]);

  const onDrawingDone = useCallback(() => {
    if (!shape || shape?.outlines?.length < 2) {
      dispatch(drawingToolDisableDrawingMode());
      return;
    }

    dispatch(drawingItemsAddItem(shape.id, {
      type: DrawingTool.Polyline,
      value: {
        id: shape.id,
        outlines: shape.outlines,
        placement: DrawingItemPlacement.Default,
        settings,
      },
    }));

    resetShape();
  }, [dispatch, resetShape, settings, shape]);

  if (!isActive) {
    return null;
  }

  return (
    <DrawMapShape
      key={shapeKey}
      zIndex={zIndex}
      onDone={onDrawingDone}
      onChange={onShapeChange}
      renderOutline={renderOutline}
      renderLine={renderLine}
    />
  );
};
