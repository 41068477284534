import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type MapPrivacyLevel } from '~/_shared/types/map';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { type MapListingItem } from '~/map/listing/item/mapListingItem';
import { checkMapCanBeShared } from '~/map/listing/mapListing.helpers';
import { MapRowActionsComponent } from '~/map/listing/tableData/mapRow/mapRowActions.component';
import {
  getMapUrl, getRelativeMapPresentationalUrl,
} from '~/map/map.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import { OPENED_APP_FRAGMENT_IDENTIFIER } from '~/store/frontendState/leftSidebar/leftSidebar.constants';
import { mapListingChanged } from '~/store/frontendState/mapListing/mapListing.actionCreators';
import { type ChangePrivacyLevelProps } from '../../useMapListingItems';

type MapRowActionsProps = Readonly<{
  isOwner: boolean;
  mapListingItem: MapListingItem;
  parentMapItem?: MapListingItem;
  width: number;
  onShareSuccess: (props: ChangePrivacyLevelProps) => void;
}>;

export const MapRowActionsContainer: FC<MapRowActionsProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { openModal: openCopyMapModal } = useModal(ModalType.CopyMap);
  const { openModal: openShareSettingsModal } = useModal(ModalType.ShareMap);
  const { openModal: openTeamShareMapModal } = useModal(ModalType.TeamShareMap);

  const isSnapshot = !!props.parentMapItem;

  const handleShareSuccess = useCallback((newPrivacyLevel: MapPrivacyLevel) => {
    const onShareSuccess = props.onShareSuccess;
    onShareSuccess({
      mapId: props.mapListingItem.id,
      newPrivacyLevel,
      parentMapId: props.parentMapItem?.id,
    });
  }, [props.mapListingItem.id, props.onShareSuccess, props.parentMapItem?.id]);

  const onMapShareSettingsClick = useCallback(() =>
    openShareSettingsModal({
      mapId: props.mapListingItem.id,
      mapName: props.mapListingItem.name,
      isSnapshot,
      parentMapPrivacy: props.parentMapItem?.privacy || null,
      onSuccess: handleShareSuccess,
      mapSource: props.mapListingItem.source,
    }), [openShareSettingsModal, props.mapListingItem.id, props.mapListingItem.name, props.parentMapItem?.privacy, isSnapshot, handleShareSuccess, props.mapListingItem.source]);

  const onTeamShareMapClick = useCallback(() =>
    openTeamShareMapModal({
      mapId: props.mapListingItem.id,
      mapName: props.mapListingItem.name,
      isSnapshot,
    }), [isSnapshot, openTeamShareMapModal, props.mapListingItem.id, props.mapListingItem.name]);

  const onMapCopyClick = useCallback(() =>
    openCopyMapModal({
      mapId: props.mapListingItem.id,
      onMapCopySuccess: () => dispatch(mapListingChanged()),
      originalMapName: props.mapListingItem.name,
    }), [dispatch, openCopyMapModal, props.mapListingItem.id, props.mapListingItem.name]);

  const onSettingsClick = useCallback(() =>
    navigate(getMapUrl(props.mapListingItem.id, undefined, { [OPENED_APP_FRAGMENT_IDENTIFIER]: SidebarApp.MapSettings })),
  [navigate, props.mapListingItem.id]);

  const onOpenInShareModeClick = useCallback(() => {
    openUrlInNewTab(getRelativeMapPresentationalUrl((props.mapListingItem.shareId)));
  }, [props.mapListingItem.shareId]);

  return (
    <MapRowActionsComponent
      isSnapshot={isSnapshot}
      isOwner={props.isOwner}
      onSettingsClick={onSettingsClick}
      onMapCopyClick={onMapCopyClick}
      onTeamShareMapClick={onTeamShareMapClick}
      onMapShareSettingsClick={onMapShareSettingsClick}
      onOpenInShareModeClick={onOpenInShareModeClick}
      isShareActive={checkMapCanBeShared(props.mapListingItem)}
      testid={`map-listing-control-menu-trigger-${props.mapListingItem.name}`}
    />
  );
};

MapRowActionsContainer.displayName = 'MapRowActionsContainer';
