import { type GroupingColumn } from '../../../_shared/types/grouping/grouping';
import {
  MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS,
  MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN,
  MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
} from './mapSettingsGrouping.actionTypes';

export const mapSettingsGroupingSetActiveGroupColumns = (activeGroupColumns: GroupingColumn[], previousActiveGroupColumns: GroupingColumn[]) => ({
  type: MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
  payload: {
    activeGroupColumns,
    previousActiveGroupColumns,
  },
}) as const;

export const mapSettingsGroupingRemoveActiveGroupColumn = (activeGroupColumn: GroupingColumn, mapId: number) => ({
  type: MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN,
  payload: {
    activeGroupColumn,
    mapId,
  },
}) as const;

export const mapSettingsGroupingAddGroupColumn = (newGroupColumn: GroupingColumn) => ({
  type: MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS,
  payload: {
    newGroupColumn,
  },
}) as const;
