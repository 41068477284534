import { useMemo } from 'react';
import { ColumnRole } from '~/_shared/types/columnRole.enum';
import { isResourceLoaded } from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import type {
  CombinedRowId, SpreadsheetId, SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { useSpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import type { SpreadsheetCellData } from '~/store/spreadsheetCellData/spreadsheetCellData.state';
import { notNull } from '../typeGuards';
import { useCategoryToColumnIdsMap } from './useCategoryValueResolver';

export const ADDRESS_ROLES = [ColumnRole.Address1, ColumnRole.Address2, ColumnRole.City,
  ColumnRole.State, ColumnRole.PostCode, ColumnRole.Country];

type SpreadsheetRowAddressInfo = {
  addressAsString: string | null;
  addressFallbackToLatLng: string | null;
  /*
   * @property addressIsLoading
   * one can always fall back to latLng if address is not available, or while it's loading
   * however if the user of the hook is interested in waiting for the address data, this flag can be used
   */
  addressIsLoading: boolean;
  latLngAsString: string | null;
};

type AddressInfoByRowIdsProps = Readonly<{
  spreadsheetRowIds: SpreadsheetRowId[];
  spreadsheetCellData?: SpreadsheetCellData | null; // if provided, will use this instead of fetching
}>;

export const useAddressInfoByRowIds = (props: AddressInfoByRowIdsProps) => {
  const latLngLookup = useLatLngSpreadsheetData();
  const categoryToColumnIdsMap = useCategoryToColumnIdsMap();

  const { spreadsheetCellData } = useSpreadsheetRowData(props.spreadsheetCellData === undefined ? props.spreadsheetRowIds : undefined);

  const finalSpreadsheetCellData = props.spreadsheetCellData || spreadsheetCellData;

  return useMemo(() => {
    const finalLookup = new Map<SpreadsheetId, Map<CombinedRowId, SpreadsheetRowAddressInfo>>();

    for (const rowId of props.spreadsheetRowIds) {
      const rowData = finalSpreadsheetCellData?.[rowId.spreadsheetId]?.get(rowId.rowId);
      const latLng = latLngLookup.getRow(rowId);
      const latLngAsString = latLng ? `${latLng.lat}, ${latLng.lng}` : null;

      let allResourcesLoaded = false;
      let addressAsString = '';
      if (rowData && isResourceLoaded(rowData)) {
        const addressRolesColumnsCellData = ADDRESS_ROLES.map(role => categoryToColumnIdsMap[rowId.spreadsheetId]?.[role])
          .map(columnId => columnId ? rowData.value.columnsData[columnId] || null : null)
          .filter(notNull);
        const allColumnsLoaded = addressRolesColumnsCellData.every(cell => isResourceLoaded(cell));
        if (allColumnsLoaded) {
          allResourcesLoaded = true;
          addressAsString = addressRolesColumnsCellData.map(cell => cell.value).join(', ');
        }
      }

      if (!finalLookup.has(rowId.spreadsheetId)) {
        finalLookup.set(rowId.spreadsheetId, new Map());
      }
      finalLookup.get(rowId.spreadsheetId)?.set(rowId.rowId, {
        addressAsString,
        addressFallbackToLatLng: addressAsString || latLngAsString,
        addressIsLoading: allResourcesLoaded,
        latLngAsString,
      });
    }

    return finalLookup;
  }, [categoryToColumnIdsMap, finalSpreadsheetCellData, latLngLookup, props.spreadsheetRowIds]);
};
