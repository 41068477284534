import { type MobileUIAction } from '~/store/frontendState/mobileUI/mobileUI.action';
import {
  FRONTEND_STATE_MOBILE_UI_TOPBAR_HIDE,
  FRONTEND_STATE_MOBILE_UI_TOPBAR_SHOW,
} from '~/store/frontendState/mobileUI/mobileUI.actionTypes';
import { type MobileUIState } from '~/store/frontendState/mobileUI/mobileUI.state';

const initialState: MobileUIState = {
  topBarOpen: false,
};

export const mobileUIReducer = (state = initialState, action: MobileUIAction): MobileUIState => {
  switch (action.type) {
    case FRONTEND_STATE_MOBILE_UI_TOPBAR_SHOW: {
      return {
        ...state,
        topBarOpen: true,
      };
    }

    case FRONTEND_STATE_MOBILE_UI_TOPBAR_HIDE: {
      return {
        ...state,
        topBarOpen: false,
      };
    }

    default: {
      return state;
    }
  }
};
