import { css } from '@emotion/react';
import { useMemo } from 'react';
import { ButtonStyles } from '~/_shared/baseComponents/buttons/button/button.styles';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  s, Trans,
} from '~/translations/Trans';

export const disclaimerStepSecondaryStyle = css({
  margin: '5px 0 5px 10px',
  display: 'block',
});

export const headingSecondaryStyle = css({
  fontWeight: 700,
});

const startTextStyle = ({ theme }: ThemeProps) => {
  const buttonStyles = ButtonStyles(theme);

  return css({
    color: buttonStyles.Success.textColor,
    backgroundColor: buttonStyles.Success.backgroundColor,
    border: `1px solid ${buttonStyles.Success.borderColor}`,
    borderRadius: 4,
    padding: 2,
  });
};

const saveTextStyle = ({ theme }: ThemeProps) => {
  const buttonStyles = ButtonStyles(theme);

  return css({
    color: buttonStyles.Primary.textColor,
    backgroundColor: buttonStyles.Primary.backgroundColor,
    border: `1px solid ${buttonStyles.Primary.borderColor}`,
    borderRadius: 4,
    padding: 2,
  });
};

export const useDisclaimerSteps = () => {
  const theme = useTheme();

  return useMemo(() => ({
    Start: (
      <Trans i18nKey="Click the <1>Start</1> button to begin.">
        {s('Click the ')}
        <span css={startTextStyle({ theme })}>Start</span>
        {s(' button to begin.')}
      </Trans>),
    FinishSelection: (
      <Trans i18nKey="Click the <1>Save</1> button after your selection is finished.">
        {s('Click the ')}
        <span css={saveTextStyle({ theme })}>Save</span>
        {s(' button after your selection is finished.')}
      </Trans>),
    FinishBoundaryDraw: (
      <Trans i18nKey="Click the <1>Save</1> button after your drawing is finished.">
        {s('Click the ')}
        <span css={saveTextStyle({ theme })}>Save</span>
        {s(' button after your drawing is finished.')}
      </Trans>
    ),
  })
  , [theme]);
};
