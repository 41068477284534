import 'core-js';
import './i18nextSetup';
import 'normalize.css';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter, RouterProvider, useRouteError,
} from 'react-router-dom';
import { AppRootComponent } from './app/appRoot/appRoot.component';

const RouteErrorBoundary = () => {
  const error = useRouteError();
  throw error;
};

export const router = createBrowserRouter([
  { path: '*', element: <AppRootComponent />, errorElement: <RouteErrorBoundary /> },
]);

window.router = router;

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<RouterProvider router={router} />);
