import {
  autoUpdate, flip,
  type Placement, useFloating,
} from '@floating-ui/react';
import { type VirtualElement } from '@popperjs/core';
import {
  type MutableRefObject, type ReactNode, type RefObject,
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ContextMenuClosingContext, type ContextMenuClosingContextType,
} from './contextMenuClosingContext';
import { ContextMenuContentComponent } from './contextMenuContent.component';

const chooseTargetRef = ({ children, userTarget, target, propsTarget }: {
  children: ReactNode | ((ref: MutableRefObject<HTMLElement | null>) => ReactNode);
  userTarget: RefObject<HTMLElement | null>;
  target: RefObject<VirtualElement | null>;
  propsTarget: RefObject<HTMLElement | null> | undefined;
}): RefObject<HTMLElement | null | VirtualElement> => {
  if (typeof children === 'function') {
    return userTarget;
  }
  if (propsTarget) {
    return propsTarget;
  }
  return target;
};

export type ContextMenuProps = Readonly<{
  // with React 19, change the type to RefObject as MutableRefObject becomes obsolete
  children?: ReactNode | ((ref: MutableRefObject<HTMLElement | null>) => ReactNode);
  className?: string;
  isFixed?: boolean;
  isVisible: boolean;
  preferredPlacement?: Placement;
  targetRef?: RefObject<HTMLElement | null>;
  maxWidth?: number;

  onHide?: () => void;
  renderMenuContent: () => ReactNode;
}>;

export const ContextMenuComponent = (props: ContextMenuProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const userTarget = useRef<HTMLElement | null>(null);

  const { onHide, isVisible } = props;

  const targetRef = useMemo(() => ({ current: target }), [target]);

  const chosenTargetRef = chooseTargetRef({
    children: props.children,
    userTarget,
    target: targetRef,
    propsTarget: props.targetRef,
  });

  const { floatingStyles, refs } = useFloating({
    placement: props.preferredPlacement ?? 'bottom-start',
    strategy: props.isFixed ? 'fixed' : 'absolute',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  if (isMounted && isVisible && (chosenTargetRef.current === null)) {
    console.warn('Context menu should be visible, but target ref was neither provided nor found.');
  }

  const closingContext = useMemo((): ContextMenuClosingContextType => ({
    closeContextMenu: () => {
      if (isVisible) {
        onHide?.();
      }
    },
  }), [onHide, isVisible]);

  const renderTrigger = () => {
    if (typeof props.children === 'function') {
      return props.children(userTarget);
    }
    if (props.targetRef) {
      return props.children;
    }
    return (
      <div
        ref={setTarget}
      >
        {props.children}
      </div>
    );

  };

  useEffect(() => {
    setIsMounted(true);
    if (chosenTargetRef.current) {
      refs.setReference(chosenTargetRef.current);
    }
  }, [refs, chosenTargetRef]);

  return (
    <>
      <ContextMenuClosingContext.Provider value={closingContext}>
        <ContextMenuContentComponent
          className={props.className}
          isVisible={isVisible && (chosenTargetRef.current !== null)}
          onHide={closingContext.closeContextMenu}
          ref={refs.setFloating}
          style={floatingStyles}
          maxWidth={props.maxWidth}
        >
          {isVisible && (chosenTargetRef.current !== null) && props.renderMenuContent()}
        </ContextMenuContentComponent>
      </ContextMenuClosingContext.Provider>
      {renderTrigger()}
    </>
  );
};
