import simplepolygon from 'simplepolygon';
import { type DeepWritable } from 'ts-essentials';
import {
  jtsParse, operations,
} from '~/_shared/types/polygon/jsts.utils';
import {
  type GeoJsonFeature, type GeoJsonFeatureCollection, type Geometry, type MultiPolygon, type MultiPolygonCoordinates,
  type Polygon, type PolygonCoordinates, type PolygonGeometry, type PolygonPath,
} from '~/_shared/types/polygon/polygon.types';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { getPolygonPathFromLatLngBounds } from '~/_shared/utils/map/latLngBounds.factory';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { type BoundingBox } from '~/map/map/boundingBox';
import { type LatLng } from '../latLng';

/////////////////////////////
//#region Geometry to Polygon

export const convertGeometryToMultiPolygon = (geometry: Geometry): MultiPolygon => {
  let paths: ReadonlyArray<MultiPolygonCoordinates> = [];

  switch (geometry.type) {
    case 'Polygon': {
      paths = [geometry.coordinates];
      break;
    }

    case 'MultiPolygon': {
      paths = geometry.coordinates;
      break;
    }

    case 'GeometryCollection': {
      return geometry.geometries.flatMap(convertGeometryToMultiPolygon);
    }

    case 'FeatureCollection': {
      return geometry.features.flatMap(feature => convertGeometryToMultiPolygon(feature.geometry));
    }

    default: {
      break;
    }
  }

  return convertCoordinateToMultiPolygon(paths);
};

export const convertCoordinateToMultiPolygon = (multiPolygonCoordinates: ReadonlyArray<MultiPolygonCoordinates>): MultiPolygon =>
  multiPolygonCoordinates.filter(c => c.length).map(convertCoordinateToPolygon);

export const convertCoordinateToPolygon = (polygonCoordinates: NonEmptyArray<PolygonCoordinates>): Polygon => {
  const convert = (coordinates: PolygonCoordinates) => removeLoop(coordinates.map(c => convertCoordinateToLatLng(c)));

  return {
    path: convert(polygonCoordinates[0]),
    holes: polygonCoordinates.slice(1).map(c => convert(c)),
  };
};

export const convertLatLngToCoordinate = (point: LatLng): [number, number] => [point.lng, point.lat];

export const convertCoordinateToLatLng = (coordinates: [number, number]): LatLng => {
  return ({
    lat: coordinates[1],
    lng: coordinates[0],
  });
};

const removeLoop = (path: LatLng[]): LatLng[] => {
  return path.slice(0, -1);
};

//#endregion

/////////////////////////////
//#region Polygon to Geometry

export const convertMultiPolygonToGeometry = (multiPolygon: MultiPolygon): Geometry => {
  if (multiPolygon.length === 1 && notEmpty(multiPolygon)) {
    const polygon = multiPolygon[0];

    return ({
      type: 'Polygon',
      coordinates: convertPolygonToCoordinates(polygon),
    });
  }

  return ({
    type: 'MultiPolygon',
    coordinates: multiPolygon.map(convertPolygonToCoordinates),
  });
};

export const convertPolygonToGeometry = (polygon: Polygon): PolygonGeometry => ({
  type: 'Polygon',
  coordinates: convertPolygonToCoordinates(polygon),
});

export const convertBoundingBoxToGeometry = (bb: BoundingBox): PolygonGeometry[] => {
  const polygonPaths = getPolygonPathFromLatLngBounds(bb);
  return polygonPaths.map(polygonPath => convertLatLngPathToGeometry(polygonPath));
};

export const convertLatLngPathToGeometry = (path: PolygonPath): PolygonGeometry => ({
  type: 'Polygon',
  coordinates: [convertLatLngPathToGeometryCoordinates(path)],
});

export const convertPolygonToCoordinates = (polygon: Polygon): ReadonlyArray<PolygonCoordinates> => ([
  convertLatLngPathToGeometryCoordinates(polygon.path),
  ...polygon.holes.map(convertLatLngPathToGeometryCoordinates),
]);

const convertLatLngPathToGeometryCoordinates = (path: PolygonPath): PolygonCoordinates => {
  const firstPathPoint = path[0];
  const loopedPath = [...path, firstPathPoint];

  const coordinates = loopedPath.map(convertLatLngToCoordinate);
  return coordinates;
};

//#endregion

export const removeGeometryPolygonIntersections = (geometry: PolygonGeometry): GeoJsonFeatureCollection => {
  const feature: GeoJsonFeature = {
    type: 'Feature',
    geometry,
  };

  return simplepolygon(feature);
};

export const flipPolygonsAndHoles = (polygons: readonly Polygon[], globalBounds: PolygonPath): readonly Polygon[] => {
  const polygonHolePaths = polygons
    .flatMap(polygon => polygon.holes);
  const polygonsToBe: readonly Polygon[] = [
    ...polygonHolePaths.map(path => ({ path, holes: [] })),
    { path: globalBounds, holes: [] },
  ];
  const polygonInfos = polygonsToBe.map(polygon => {
    return {
      geometry: jtsParse(convertPolygonToGeometry(polygon)),
      polygon: cloneDeep<DeepWritable<Polygon>>(polygon),
    };
  });
  const holeInfos = polygons.map(holePolygon => {
    return {
      geometry: jtsParse(convertPolygonToGeometry({ path: holePolygon.path, holes: [] })),
      polygon: { path: holePolygon.path, holes: [] },
    };
  });

  // The polygons are sorted so that if A is within B, A comes first in the list so that we can only punch a hole in
  // the first polygon in the list and then break;
  polygonInfos.sort((polygonA, polygonB) => {
    return operations.relate.contains(polygonA.geometry, polygonB.geometry) ? 1 : -1;
  });

  holeInfos.forEach(holeInfo => {
    for (const polygonInfo of polygonInfos) {
      if (operations.relate.contains(polygonInfo.geometry, holeInfo.geometry)) {
        polygonInfo.polygon.holes.push([...holeInfo.polygon.path]);
        break;
      }
    }
  });

  return polygonInfos.map(info => info.polygon);
};
