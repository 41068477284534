import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type Theme } from '../../themes/theme.model';

type ToolboxContainerItemProps = Readonly<{
  icon: IconProp;
  title: string;
  className?: string;
  onClick: () => void;
}>;

const itemStyles = (theme: Theme) => css({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.primary,
  color: theme.textColors.primary,
  cursor: 'pointer',
  padding: '10px 0 10px 10px',
  textTransform: 'uppercase',

  ':hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

const iconContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-around',
  width: 24, // width of the most wide icon
});

const iconStyles = css({
  fontSize: 20,
  fontWeight: 900,
  lineHeight: 24,
  minWidth: 18,
  borderRadius: 8,
});

const labelStyles = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
});

export const ToolboxItemComponent: React.FC<ToolboxContainerItemProps> = props => {
  const theme = useTheme();

  return (
    <div
      css={itemStyles(theme)}
      className={props.className}
      onClick={props.onClick}
    >
      <div css={iconContainerStyles}>
        <FontAwesomeIcon
          css={iconStyles}
          icon={props.icon}
        />
      </div>

      <div css={labelStyles}>
        {props.title}
      </div>
    </div>
  );
};
