import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import {
  type SpreadsheetTableDataUpdateRequest, updateSpreadsheetRows,
} from '../spreadsheet/spreadsheet.repository';
import { useSpreadsheetDataOutOfSync } from './useSpreadsheetDataOutOfSync';

export const useUpdateSpreadsheetData = () => {
  const isMountedRef = useIsComponentMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { onConcurrencyError } = useSpreadsheetDataOutOfSync();

  const updateSpreadsheetData = (
    clientId: number,
    spreadsheetId: number,
    request: SpreadsheetTableDataUpdateRequest,
    onSuccess: () => void
  ) => {
    setIsLoading(true);
    setIsError(false);

    return updateSpreadsheetRows(clientId, spreadsheetId, request)
      .then((response) => {
        if (response.type === 'concurrency-error') {
          onConcurrencyError();
        }
        else {
          onSuccess();
          dispatch(spreadsheetResetStateAndRefetchData());
        }
      })
      .catch((e) => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        throw e;
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  const clearUpdateError = () => {
    if (isMountedRef.current) {
      setIsError(false);
    }
  };

  return {
    isLoading,
    isError,
    clearUpdateError,
    updateSpreadsheetData,
  };
};
