import { type TranslationFnc } from '../hooks';
import { isTextEmpty } from '../text/text.helpers';
import {
  createPasswordErrorMessage, validateEmail,
} from '../throttle/validation/validation.helpers';

export enum FormFieldType {
  Required = 'Required',
  Password = 'Password',
  ConfirmPassword = 'ConfirmPassword',
  ConfirmEmail = 'ConfirmEmail',
  Email = 'Email',
}

export const validateFormFieldOfType = (type: FormFieldType, value: string, confirmValue: string | null, t: TranslationFnc, requiredFieldErrorMessage?: string): string[] => {
  switch (type) {
    case FormFieldType.Email: {
      if (validateEmail(value)) {
        return [];
      }

      if (confirmValue !== null && confirmValue !== value) {
        return [t('Emails do not match')];
      }

      return [t('Invalid email address')];
    }

    case FormFieldType.ConfirmEmail: {
      if (confirmValue === null || confirmValue !== value) {
        return [t('Emails do not match')];
      }
      return [];
    }

    case FormFieldType.Password: {
      return createPasswordErrorMessage(value, t);
    }

    case FormFieldType.ConfirmPassword: {
      if (confirmValue === null || confirmValue !== value) {
        return [t('Passwords do not match')];
      }
      return [];
    }

    case FormFieldType.Required: {
      if (isTextEmpty(value)) {
        return [requiredFieldErrorMessage ?? t('This field is required')];
      }
      return [];
    }

    default:
      return [];
  }
};
