import { MapListSortType } from '~/map/listing/mapListing.helpers';
import type {
  SplitPanelItem, UserSettingsPerClient,
} from '~/store/userData/settings/userDataSettings.state';
import { type UserDataState } from '../userData.state';
import { type UserDataSettingsAction } from './userDataSettings.action';
import {
  USER_SETTINGS_MAP_DROPDOWN_UPDATE, USER_SETTINGS_SPLIT_PANEL_CLOSE, USER_SETTINGS_SPLIT_PANEL_OPEN,
  USER_SETTINGS_THEME_UPDATE,
} from './userDataSettings.actionTypes';

const initialPerClientUserSettings: UserSettingsPerClient = {
  splitPanel: {},
  home: {
    mapListSortType: MapListSortType.Updated_DESC,
  },
};

export const defaultSplitPanelItem: Readonly<SplitPanelItem> = ({
  isActive: false,
});

export const userDataSettingsReducer = (state: UserDataState, action: UserDataSettingsAction): UserDataState => {
  switch (action.type) {
    case USER_SETTINGS_MAP_DROPDOWN_UPDATE: {
      const { clientId } = action.payload;
      const currentClientSettings = {
        ...initialPerClientUserSettings,
        ...state.settings?.[clientId],
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [clientId]: {
            ...currentClientSettings,
            home: {
              ...currentClientSettings.home,
              mapListSortType: action.payload.sortType,
            },
          },
        },
      };
    }

    case USER_SETTINGS_THEME_UPDATE: {
      const { clientId } = action.payload;
      const currentClientSettings = {
        ...initialPerClientUserSettings,
        ...state.settings?.[clientId],
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [clientId]: {
            ...currentClientSettings,
            theme: action.payload.theme,
          },
        },
      };
    }

    case USER_SETTINGS_SPLIT_PANEL_OPEN: {
      const { clientId, mapId } = action.payload;
      const currentClientSettings = {
        ...initialPerClientUserSettings,
        ...state.settings?.[clientId],
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [clientId]: {
            ...currentClientSettings,
            splitPanel: {
              ...currentClientSettings.splitPanel,
              [mapId]: {
                ...(currentClientSettings.splitPanel[mapId] ?? defaultSplitPanelItem),
                isActive: true,
              },
            }
            ,
          },
        },
      };
    }

    case USER_SETTINGS_SPLIT_PANEL_CLOSE: {
      const { clientId, mapId } = action.payload;
      const currentClientSettings = {
        ...initialPerClientUserSettings,
        ...state.settings?.[clientId],
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [clientId]: {
            ...currentClientSettings,
            splitPanel: {
              ...currentClientSettings.splitPanel,
              [mapId]: {
                ...(currentClientSettings.splitPanel[mapId] ?? defaultSplitPanelItem),
                isActive: false,
              },
            },
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
