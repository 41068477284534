import { removeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '~/map/settings/accordion/baseMap/baseMap.enums';
import { baseMapThemes } from '~/map/settings/accordion/baseMap/baseMapThemes/baseMapThemes.constants';
import { type BaseMapThemeName } from '~/map/settings/accordion/baseMap/baseMapThemes/baseMapThemes.enums';
import {
  type MapSettingsMapStylesChangeOptions, type MapSettingsMapStylesElementState, type MapSettingsMapStylesStylers,
  type MapSettingsMapStylesStylingState,
} from './mapSettingsMapStyles.state';

export const initialMapStylesWithAppliedThemeStyles = (themeStyles: readonly MapSettingsMapStylesChangeOptions[]) => {
  let stylingBase: MapSettingsMapStylesStylingState = JSON.parse(JSON.stringify(initialStandardStylingState));
  themeStyles.forEach(themeStyle => stylingBase = changeStyling(stylingBase, themeStyle));
  return stylingBase;
};

export const changeStyling = (oldStyling: MapSettingsMapStylesStylingState, changeData: MapSettingsMapStylesChangeOptions): MapSettingsMapStylesStylingState => {
  const { elementType, featureType, stylers } = changeData;

  return {
    ...oldStyling,
    [featureType]: {
      ...oldStyling[featureType],
      [elementType]: {
        ...oldStyling[featureType][elementType],
        ...stylers,
      },
    },
  };
};

export const getThemeStyling = (themeId: BaseMapThemeName, fallbackState: MapSettingsMapStylesStylingState) => {
  const changeData = baseMapThemes[themeId];
  if (!changeData) {
    return fallbackState;
  }
  return initialMapStylesWithAppliedThemeStyles(changeData);
};

const stylersInitialState: MapSettingsMapStylesStylers = {
  visibility: BaseMapStylerVisibility.inherit,
  color: {
    value: removeHash('#ffff05'),
    isActive: false,
  },
  weight: {
    value: 4,
    isActive: false,
  },
  saturation: {
    value: 100,
    isActive: false,
  },
  lightness: {
    value: 30,
    isActive: false,
  },
};

const elementInitialState: MapSettingsMapStylesElementState = {
  [BaseMapElementTypeName.all]: stylersInitialState,
  [BaseMapElementTypeName.geometry]: stylersInitialState,
  [BaseMapElementTypeName['geometry.fill']]: stylersInitialState,
  [BaseMapElementTypeName['geometry.stroke']]: stylersInitialState,
  [BaseMapElementTypeName.labels]: stylersInitialState,
  [BaseMapElementTypeName['labels.text']]: stylersInitialState,
  [BaseMapElementTypeName['labels.text.fill']]: stylersInitialState,
  [BaseMapElementTypeName['labels.text.stroke']]: stylersInitialState,
  [BaseMapElementTypeName['labels.icon']]: stylersInitialState,
};

const createInitialStandardColors = (newElementStates: Partial<MapSettingsMapStylesElementState>): MapSettingsMapStylesElementState => ({
  ...elementInitialState,
  ...newElementStates,
});

const createInitialStandardElementColors = (elementType: BaseMapElementTypeName, color: string) =>
  createInitialStandardColors({
    [elementType]: {
      ...stylersInitialState,
      color: { value: removeHash(color), isActive: false },
    },
  });

const createInitialStandardGeometryColors = (color: string): MapSettingsMapStylesElementState =>
  createInitialStandardElementColors(BaseMapElementTypeName.geometry, color);
const createInitialStandardLabelColors = (color: string): MapSettingsMapStylesElementState =>
  createInitialStandardElementColors(BaseMapElementTypeName['labels.text.fill'], color);

export const initialStandardStylingState: MapSettingsMapStylesStylingState = {
  [BaseMapFeatureTypeName.administrative]: createInitialStandardLabelColors('#7e8ea5'),
  [BaseMapFeatureTypeName['administrative.country']]: elementInitialState,
  [BaseMapFeatureTypeName['administrative.province']]: elementInitialState,
  [BaseMapFeatureTypeName['administrative.locality']]: elementInitialState,
  [BaseMapFeatureTypeName['administrative.neighborhood']]: elementInitialState,
  [BaseMapFeatureTypeName['administrative.landParcel']]: elementInitialState,
  [BaseMapFeatureTypeName.all]: elementInitialState,
  [BaseMapFeatureTypeName.landscape]: elementInitialState,
  [BaseMapFeatureTypeName['landscape.humanMade']]: createInitialStandardGeometryColors('#e8e8e8'),
  [BaseMapFeatureTypeName['landscape.natural']]: createInitialStandardGeometryColors('#a2d8dd'),
  [BaseMapFeatureTypeName['landscape.natural.landcover']]: elementInitialState,
  [BaseMapFeatureTypeName['landscape.natural.terrain']]: elementInitialState,
  [BaseMapFeatureTypeName.poi]: createInitialStandardGeometryColors('#c7e9c7'),
  [BaseMapFeatureTypeName['poi.attraction']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.business']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.government']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.medical']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.park']]: {
    ...elementInitialState,
    [BaseMapElementTypeName.geometry]: createInitialStandardGeometryColors('#c7e9c7').geometry,
    [BaseMapElementTypeName['labels.text.fill']]: createInitialStandardLabelColors('#188038')['labels.text.fill'],
  },
  [BaseMapFeatureTypeName['poi.placeOfWorship']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.school']]: elementInitialState,
  [BaseMapFeatureTypeName['poi.sportsComplex']]: elementInitialState,
  [BaseMapFeatureTypeName.road]: createInitialStandardGeometryColors('#fff'),
  [BaseMapFeatureTypeName['road.highway']]: createInitialStandardGeometryColors('#fff2af'),
  [BaseMapFeatureTypeName['road.highway.controlledAccess']]: elementInitialState,
  [BaseMapFeatureTypeName['road.arterial']]: createInitialStandardGeometryColors('#ffffff'),
  [BaseMapFeatureTypeName['road.local']]: createInitialStandardGeometryColors('#ffffff'),
  [BaseMapFeatureTypeName.transit]: elementInitialState,
  [BaseMapFeatureTypeName['transit.line']]: elementInitialState,
  [BaseMapFeatureTypeName['transit.station']]: elementInitialState,
  [BaseMapFeatureTypeName['transit.station.airport']]: elementInitialState,
  [BaseMapFeatureTypeName['transit.station.bus']]: elementInitialState,
  [BaseMapFeatureTypeName['transit.station.rail']]: elementInitialState,
  [BaseMapFeatureTypeName.water]: {
    ...elementInitialState,
    [BaseMapElementTypeName.geometry]: createInitialStandardGeometryColors('#aadaff').geometry,
    [BaseMapElementTypeName['labels.text.fill']]: createInitialStandardLabelColors('#4d81c6')['labels.text.fill'],
  },
};
