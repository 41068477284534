import {
  type FC,
  useMemo,
} from 'react';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useGetBoundaryGroup } from '../../../../../store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useSpreadsheetColumns } from '../../../../../store/matchupData/matchupDataSelectors.hook';
import {
  ActiveBoundaryFillInfoComponent,
  type ActiveBoundaryFillInfoEntities,
} from './activeBoundaryFillInfo.component';
import { getCalculateBucketFunctionOptions } from './edit/activeBoundaryFillEditComponent';

type ActiveBoundaryFillInfoProps = Readonly<{
  boundaryTerritoryType: BoundaryTerritoryType;
  boundaryTerritoryGroupId: number;
}>;

export const ActiveBoundaryFillInfoContainer: FC<ActiveBoundaryFillInfoProps> = props => {
  const [t] = useTranslation();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  const spreadSheetColumns = useSpreadsheetColumns();
  const boundaryTerritoryGroup = boundaryTerritoryGroups.find(
    group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId
  );
  const bucketFunctionOptionName = getCalculateBucketFunctionOptions(t)
    .find(o => o.value === boundaryTerritoryGroup?.settings.calculateBucketFunction)?.name;

  const columnName = useMemo(() => {
    const unknownName = t('Unknown column');
    if (boundaryTerritoryGroup) {
      return spreadSheetColumns.find(c => c.id === boundaryTerritoryGroup.settings.bucketColumnId)?.name ?? unknownName;
    }

    return unknownName;
  }, [boundaryTerritoryGroup, spreadSheetColumns, t]);

  const entities: ActiveBoundaryFillInfoEntities | null = useMemo(() => {
    if (!boundaryTerritoryGroup) {
      return null;
    }

    switch (props.boundaryTerritoryType) {
      case BoundaryTerritoryType.Density: {
        return { mainEntity: t('Location Density') };
      }

      case BoundaryTerritoryType.Demographic: {
        if (boundaryTerritoryGroup.settings.demographicId) {
          const demographics = getBoundaryGroupById(boundaryTerritoryGroup.boundaryGroupId)?.demographics;
          if (demographics) {
            const selectedDemographic = demographics.find(d => d.id === boundaryTerritoryGroup.settings.demographicId);
            if (selectedDemographic) {
              return {
                mainEntity: selectedDemographic.category,
                subEntity: selectedDemographic.name,
              };
            }
          }
        }
        return null;
      }

      case BoundaryTerritoryType.Groups: {
        return { mainEntity: t('Group'), subEntity: columnName };
      }

      case BoundaryTerritoryType.Numeric: {
        if (bucketFunctionOptionName) {
          return { mainEntity: bucketFunctionOptionName, subEntity: columnName };
        }
        return null;
      }

      case BoundaryTerritoryType.Manual:
      default: {
        return null;
      }
    }
  }, [boundaryTerritoryGroup, bucketFunctionOptionName, columnName, getBoundaryGroupById, props.boundaryTerritoryType, t]);

  return (entities ? <ActiveBoundaryFillInfoComponent fillEntities={entities} /> : null);
};
