import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { type LatLngBounds } from '../../_shared/types/latLng';
import {
  type BoundaryGroupId, type BoundaryId,
} from '../boundaries/boundaryIdentifier.type';

export type ZoomLevel = number;
export const CUSTOM_BOUNDARY_ZOOM_LEVEL: ZoomLevel = 0;

export type BoundaryPolygon = Polygon & {
  bounds: LatLngBounds | null;
  boundsArea: number | null;
};

export type BoundaryMultiPolygon = ReadonlyArray<BoundaryPolygon>;

export type BoundaryStateItem = {
  displayName: string;
  id: number;
  paths: Map<ZoomLevel, BoundaryMultiPolygon>;
  labelBoundaries: LatLngBounds | null;
  settings: {
    isArtificial: boolean;
    style?: {
      lineWidth: number;
      lineColor: string;
      color: string;
      opacity: number;
    };
    translate?: boolean;
    translateParams?: { from?: number; to?: number; unit?: string; position?: number } | null;
  };
  lastFetchTimestamp: number;
};

export type BoundaryStateItems = ReadonlyMap<BoundaryGroupId, ReadonlyMap<BoundaryId, BoundaryStateItem>>;

export type BoundaryItemsState = Readonly<{
  isLoading: boolean;
  isError: boolean;
  boundaries: BoundaryStateItems;
  continuationToken: ReadonlyMap<BoundaryGroupId, ReadonlyMap<ZoomLevel, string>>;
}>;
