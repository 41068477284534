export const MAP_INFO_FETCH_DATA_REQUEST = 'MAP_INFO/FETCH_DATA_REQUEST';
export const MAP_INFO_FETCH_DATA_ERROR = 'MAP_INFO/FETCH_DATA_ERROR';
export const MAP_INFO_FETCH_DATA_SUCCESS = 'MAP_INFO/FETCH_DATA_SUCCESS';

export const MAP_INFO_UPDATE_REQUEST = 'MAP_INFO/UPDATE_REQUEST';
export const MAP_INFO_UPDATE_SUCCESS = 'MAP_INFO/UPDATE_SUCCESS';
export const MAP_INFO_UPDATE_ERROR = 'MAP_INFO/UPDATE_ERROR';

export const MAP_INFO_UPDATE_BAD_DATA_COUNT = 'MAP_INFO_UPDATE_BAD_DATA_COUNT';

export const MAP_UPDATE_SNAPSHOT_SUCCESS = 'MAP_UPDATE_SNAPSHOT_SUCCESS';

export const MAP_INFO_SNAPSHOT_REMOVE_SUCCESS = 'MAP_INFO_SNAPSHOT_REMOVE_SUCCESS';

export const MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST = 'MAP_INFO_PRESENTATIONAL/FETCH_DATA_REQUEST';
export const MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR = 'MAP_INFO_PRESENTATIONAL/FETCH_DATA_ERROR';
export const MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS = 'MAP_INFO_PRESENTATIONAL/FETCH_DATA_SUCCESS';

export const MAP_INFO_UPDATE_PRIVACY = 'MAP_INFO/UPDATE_PRIVACY';
