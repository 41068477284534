import { type TranslationFnc } from '~/_shared/utils/hooks';
import { getMainMenuItemTitle } from '~/mainMenu/mainMenu.helpers';
import { MainMenuItems } from '~/mainMenu/mainMenuItem.enum';
import { mapToolTitleMap } from '~/sidebar/sidebarApps/mapTools/mapTools.helpers';
import { type MapSettingsKeyOf } from '../../map.repository';

export const getMapSettingsKeyOfTranslation = (property: MapSettingsKeyOf, t: TranslationFnc): string => {
  switch (property) {
    case 'boundaries':
      return getMainMenuItemTitle(MainMenuItems.Boundary, t);

    case 'directions':
      return getMainMenuItemTitle(MainMenuItems.Routing, t);

    case 'drawing':
      return t(mapToolTitleMap.Drawing);

    case 'filtering':
      return getMainMenuItemTitle(MainMenuItems.Filter, t);

    case 'grouping':
      return getMainMenuItemTitle(MainMenuItems.Grouping, t);

    case 'heatmaps':
      return getMainMenuItemTitle(MainMenuItems.HeatMap, t);

    case 'layered':
      return t(mapToolTitleMap.Layering);

    case 'mapStyles':
      return t('Map Styles');

    case 'markers':
      return t('Marker Settings');

    case 'markersGeneral':
      return t('Marker Settings');

    case 'proximity':
      return getMainMenuItemTitle(MainMenuItems.Proximity, t);

    case 'publicMapSettings':
      return t('Public Map Settings');

    case 'search':
      return t('Search Settings');

    case 'settings':
      return t('Other Settings');

    case 'toolsState':
      return t('Snapshot Settings');

    default:
      return t('Other Settings');
  }
};
