import {
  type FC, memo, type ReactNode, useMemo,
} from 'react';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type MapObjectZIndex } from '../mapObject.types';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { getCalloutDimensions } from './mapTextAreaCallout.helpers';
import { type MapTextAreaCalloutInstance } from './mapTextAreaCalloutModel';

type MapTextAreaCalloutContainerProps = Readonly<{
  callout: MapTextAreaCalloutInstance;
  zIndex: MapObjectZIndex;
  renderCallout: (callout: MapTextAreaCalloutInstance) => ReactNode;
  renderOutline?: (outline: MapOutlinePositionInstance) => ReactNode;
}>;

const getTopRightCorner = (callout: MapTextAreaCalloutInstance) => {
  const dimensions = getCalloutDimensions(callout);
  return { x: dimensions.width, y: -dimensions.height };
};

const useCalloutOutlines = (
  callout: MapTextAreaCalloutInstance,
  options: { predicate?: boolean }
): MapOutlinePositionInstance[] => {
  return useMemo(() => {
    if (!options.predicate) {
      return [];
    }

    const topRightCorner = getTopRightCorner(callout);

    return [{
      id: `${callout.id}-bottom-left`,
      ...callout.startPoint,
      offsetX: 0,
      offsetY: 0,
      position: MapOutlinePosition.BottomLeft,
    }, {
      id: `${callout.id}-top-right`,
      ...callout.startPoint,
      offsetX: topRightCorner.x + 5,
      offsetY: topRightCorner.y - 5,
      position: MapOutlinePosition.TopRight,
    }];
  }, [callout, options.predicate]);
};

const MapTextAreaCalloutContainer: FC<MapTextAreaCalloutContainerProps> = (props) => {
  const { callout, zIndex } = props;
  const { renderCallout, renderOutline } = props;

  const mapObjectManager = useMapObjectManager();

  const outlines = useCalloutOutlines(callout, { predicate: !!renderOutline });

  return (
    <MapObjectContextProvider value={{ manager: mapObjectManager, zIndex }}>
      {renderCallout(callout)}
      {renderOutline && outlines.map(
        outline => renderOutline(outline))
      }
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(MapTextAreaCalloutContainer);
export { pureComponent as MapTextAreaCalloutContainer };
