import {
  type AxiosRequestConfig, type CancelToken,
} from 'axios';
import {
  type FileAttachment, type FileAttachmentId,
} from '~/_shared/types/file.types';
import { type GeocodingResponse } from '~/_shared/types/geocoding/geocoding.response';
import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import type { LatLng } from '~/_shared/types/latLng';
import {
  type MapPrivacyLevel, type MapSource, type OwnerMapInfo,
} from '~/_shared/types/map';
import { type PaginationResponse } from '~/_shared/types/pagination/pagination.response';
import { type ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  apiDelete, apiGet, apiPatch, apiPost, uploadFile, type UploadFileParams,
} from '~/_shared/utils/api/api.helpers';
import {
  type ApiError, catchApiError,
} from '~/_shared/utils/api/apiError.helpers';
import type { MemberBasicInfo } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import { type MapSettingsBoundariesState } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.state';
import { type MapSettingsDirectionsState } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { type MapSettingsDrawingState } from '~/store/mapSettings/drawing/mapSettingsDrawing.state';
import { type MapSettingsFilteringState } from '~/store/mapSettings/filtering/mapSettingsFiltering.state';
import { type MapSettingsGroupingState } from '~/store/mapSettings/grouping/mapSettingsGrouping.state';
import { type MapSettingsLayeredState } from '~/store/mapSettings/layeredMap/mapSettingsLayered.state';
import { type MapSettingsMarkersGeneralState } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.state';
import { type MapSettingsMapStylesState } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import { type MapSettingsMarkersState } from '~/store/mapSettings/markers/mapSettingsMarkers.state';
import type { MapSettingsPlacesState } from '~/store/mapSettings/places/mapSettingsPlaces.state';
import { type MapSettingsProximityState } from '~/store/mapSettings/proximity/mapSettingsProximity.state';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { type MapSettingsSearchState } from '~/store/mapSettings/search/mapSettingsSearch.state';
import { type MapSettingsSettingsState } from '~/store/mapSettings/settings/mapSettingsSettings.state';
import { type MapSettingsToolsState } from '~/store/mapSettings/toolsState/mapSettingsToolsState';
import { type MapListSortType } from './listing/mapListing.helpers';
import type { SearchItem } from './search/mapSearch.helpers';

export const MAP_LIMIT_REACHED_MESSAGE = 'The number of created maps has reached the limit.';

export type CreatorResponse = {
  id: number;
  name: string | null;
  date: string;
};

export type SpreadsheetDescriptorResponse = {
  spreadsheet_id: number;
  is_encrypted: boolean;
  is_primary: boolean;
  privileges: 'modify';
  real_spreadsheets: ReadonlyArray<{ real_spreadsheet_id: number }>;
  geocoding?: GeocodingResponse;
};

type UploadClientSpreadsheetResponse = {
  data: {
    columns: { [key: string]: string };
    file_name: string;
  };
};

type ClientDescriptionResponse = Readonly<{
  id: number;
  name: string;
  type: 'individual';
  wms_enabled: boolean;
}>;

type LayeredMapBaseMap = Readonly<{
  id: number;
  name: string;
  spreadsheets: ReadonlyArray<{
    realSpreadsheets: ReadonlyArray<{
      realSpreadsheetId: number;
    }>;
  }>;
  sharedUsers: ReadonlyArray<MemberBasicInfo>;
}>;

export type LayeringType = Readonly<{
  baseMaps: ReadonlyArray<LayeredMapBaseMap>;
  columns: {
    [columnId: ColumnId]: string;
  };
  sourceColumns: {
    [realSpreadsheetId: string]: {
      [columnId: ColumnId]: string;
    };
  };
  connected: boolean;
  matches: MapIntersectWithColumnId[];
  realTime: boolean;
}>;

export type GoogleSheetBasedSpreadsheetInfoResponse = {
  real_spreadsheets: ReadonlyArray<{
    auto_sync_enabled: boolean;
    google_account_id: string | null;
    google_sheet_id: string;
    google_spreadsheet_id: string;
    last_sync_type: GoogleSheetMapSyncType;
    real_spreadsheet_id: number;
    spreadsheet_title: string;
  }>;
  spreadsheet_id: number;
};

export enum BoundaryCreateOptimizedStatusResponse {
  RequestAccepted = '_01_RequestAccepted',
  ValidatingRequest = '_02_ValidatingRequest',
  StartingTerritories = '_03_StartingTerritories',
  BalancingTerritoriesAttempt1 = '_04_BalancingTerritoriesAttempt1',
  BalancingTerritoriesAttempt2 = '_05_BalancingTerritoriesAttempt2',
  BalancingTerritoriesAttempt3 = '_06_BalancingTerritoriesAttempt3',
  ProcessingDone = '_07_ProcessingDone',
  Finished = '_08_Finished',
}

type MapInfoTerritoryGenerationResponse = {
  id: number;
  status: BoundaryCreateOptimizedStatusResponse;
};

export type MapInfoResponse = BaseMapResponse & Readonly<{
  parent_map?: ParentMapResponse;
  snapshots?: ReadonlyArray<MapSnapshotResponse>;
}>;

export type BaseMapResponse = OwnerMapInfo & Readonly<{
  description: string | null;
  encrypted: boolean;
  google_sheets: ReadonlyArray<GoogleSheetBasedSpreadsheetInfoResponse>;
  id: number;
  importing: boolean;
  is_layered: boolean;
  is_matchup_required: boolean;
  layering?: LayeringResponse;
  name: string;
  territories_generation?: ReadonlyArray<MapInfoTerritoryGenerationResponse>;
  privacy: MapPrivacyLevel;
  secure_in_process: boolean;
  settings: Record<string, string>;
  share_id: string;
  spreadsheets: ReadonlyArray<SpreadsheetDescriptorResponse>;
  shared_users: ReadonlyArray<MemberBasicInfo>;
  team: null;
  updated: CreatorResponse;
  versions?: VersionsResponse;
  views: number;
  v4_migration_done?: boolean;
  source?: MapSource;
}>;

export type ParentMapResponse = BaseMapResponse & Readonly<{
  parent_map: undefined;
  snapshots: ReadonlyArray<MapSnapshotResponse>;
}>;

export type MapSnapshotResponse = BaseMapResponse & Readonly<{
  parent_map: number;
  snapshots: undefined;
}>;

interface BaseMapsLayeringResponse {
  id: number;
  name: string;
  spreadsheets: ReadonlyArray<{
    spreadsheet_id: number;
    real_spreadsheets: ReadonlyArray<{
      real_spreadsheet_id: number;
    }>;
  }>;
  shared_users: ReadonlyArray<MemberBasicInfo>;
}

export type LayeringResponse = {
  base_maps: ReadonlyArray<BaseMapsLayeringResponse>;
  columns: {
    [columnId: string]: string;
  };
  source_columns: {
    [realSpreadsheetId: string]: {
      [columnId: string]: string;
    };
  };
  connected: boolean;
  matches: ReadonlyArray<MapIntersectResponse>;
  real_time: boolean;
};

export type VersionsResponseRealSpreadsheet = Readonly<{
  versions: {
    current: {
      snapshot: number;
      version: number;
    };
    latest: {
      snapshot: number;
      version: number;
    };
  };
}>;

export type VersionsResponse = {
  data_changed: boolean;
  real_spreadsheets: Record<number, VersionsResponseRealSpreadsheet>;
};

type CreateMapResponse = {
  data: MapInfoResponse;
};

type CreateMapRequestCommon = {
  name: string;
  description?: string;
  encrypted?: boolean;
};

type CreateMapRequestEmpty = CreateMapRequestCommon & {
  data_type: 'empty';
};

type CreateMapRequestWithData = CreateMapRequestCommon & {
  data_type: 'file' | 'input' | 'spreadsheet';
  data_source: string;
};

export enum GoogleSheetMapSyncType {
  MaptiveToGoogle = 'maptive-to-google',
  GoogleToMaptive = 'google-to-maptive',
  TwoWaySync = 'two-way-sync',
}

export const isGoogleSheetMapSyncType = (value: Maybe<string>): value is GoogleSheetMapSyncType =>
  Object.values(GoogleSheetMapSyncType).includes(value as GoogleSheetMapSyncType);

type CreateMapGoogleSheetData = CreateMapRequestCommon & {
  data_source: string;
  data_type: 'spreadsheet';
  google_account_id: string;
  gs_id: string;
  gs_sheet: string;
  unique_keys: number[];
  sync_type: GoogleSheetMapSyncType;
};

export type MapSettingsFileAttachmentsResponse = Readonly<{
  list?: ReadonlyArray<FileAttachment>;
  map?: Record<FileAttachmentId, FileAttachment>;
}>;

export type HeatMapServerModel = HeatMap & Readonly<{
  selectedGroup?: string; // Backward compatibility with old model
}>;
export type MapSettingsHeatmapsResponse = Readonly<{
  heatmaps?: ReadonlyArray<HeatMapServerModel>;
}>;

export type MapSettingsSearchItemsResponse = {
  items?: ReadonlyArray<Omit<SearchItem, 'latLng | id'> & {
    id?: Uuid;
    latLng?: LatLng | ReadonlyArray<LatLng>;
  }>;
};
export type MapSettingsToolsStateResponse = Partial<Omit<MapSettingsToolsState, 'searchItems'>> & {
  searchItems?: MapSettingsSearchItemsResponse;
};

export type MapSettingsKeyOf = keyof MapSettingsDataServerModel;

export type MapSettingsEtagItem = {
  timestamp: number;
  userName: string | null;
  userId: number | null;
};

export type MapSettingsEtag = { [key in MapSettingsKeyOf]?: MapSettingsEtagItem };

export type MapSettingsMarkersResponse = Partial<MapSettingsMarkersState>;
export type MapSettingsSettingsResponse = Partial<MapSettingsSettingsState>;
export type MapSettingsGroupingResponse = Partial<MapSettingsGroupingState>;
export type MapSettingsFilteringResponse = Partial<MapSettingsFilteringState>;
export type MapSettingsProximityResponse = Partial<MapSettingsProximityState>;
export type MapSettingsDirectionsResponse = Partial<MapSettingsDirectionsState>;
export type LayeredResponse = Partial<MapSettingsLayeredState>;
export type MapSettingsBoundariesResponse = Partial<MapSettingsBoundariesState>;
export type MapSettingsDrawingResponse = Partial<MapSettingsDrawingState>;
export type MapSettingsMarkersGeneralResponse = Partial<MapSettingsMarkersGeneralState>;
export type MapSettingsSearchResponse = Partial<MapSettingsSearchState>;
export type MapSettingsStylesResponse = Partial<MapSettingsMapStylesState>;
export type PublicMapSettingsResponse = Partial<MapSettingsPublicMapSettingsState>;
export type MapSettingsPlacesResponse = Partial<MapSettingsPlacesState>;

export type MapSettingsDataServerModel = {
  settings: MapSettingsSettingsResponse;
  grouping: MapSettingsGroupingResponse;
  fileAttachments: MapSettingsFileAttachmentsResponse;
  filtering: MapSettingsFilteringResponse;
  proximity: MapSettingsProximityResponse;
  directions: MapSettingsDirectionsResponse;
  layered: LayeredResponse;
  boundaries: MapSettingsBoundariesResponse;
  heatmaps: MapSettingsHeatmapsResponse;
  drawing: MapSettingsDrawingResponse;
  mapStyles: MapSettingsStylesResponse;
  markers: MapSettingsMarkersResponse;
  markersGeneral: MapSettingsMarkersGeneralResponse;
  toolsState: MapSettingsToolsStateResponse;
  search: MapSettingsSearchResponse;
  publicMapSettings: PublicMapSettingsResponse;
  places: MapSettingsPlacesResponse;
};

export type MapSettingsDataResponse = MapSettingsDataServerModel & {
  mapId: number;
  etag?: MapSettingsEtag;
};

type MapSettingsUpdateSettingsResponse = {
  data: {
    etag?: Partial<MapSettingsEtag>;
  };
};

export type ConcurrencyCheckStatus = 'passed' | 'failed';

export type MapSettingsUpdateSettingsConcurrencyErrorResponse = {
  reason: {
    [key in MapSettingsKeyOf]: {
      concurency_check: ConcurrencyCheckStatus;
      timestamp: number;
      userName: string;
      userId: number;
    }
  };
};

export type MapSettingsUpdateSettingsCombinedResponse = {
  type: 'success';
  data: MapSettingsUpdateSettingsResponse;
} | {
  type: 'concurrency-error';
  data: MapSettingsUpdateSettingsConcurrencyErrorResponse;
};

export type MapPresentationalResponse = MapInfoResponse & {
  client: ClientDescriptionResponse;
};

type MapListResponse = {
  data: {
    list: MapInfoResponse[];
    meta: PaginationResponse;
  };
};

type RemoveMapMapSuccessResponse = {
  success: true;
};

type ConflictingMapResponse = {
  layered_id: number;
  name: string;
};

type RemoveMapFailUserDataResponse = {
  user_id: number;
  user_name: string;
};

type RemoveMapMapFailResponse = {
  success: false;
  layered: ConflictingMapResponse[];
  used_on_other_users_maps: RemoveMapFailUserDataResponse[];
  map_id: number;
};

export type RemoveMapResponse = {
  data: (RemoveMapMapFailResponse | RemoveMapMapSuccessResponse)[];
};

export enum MapListFilter {
  Layered = 'layered',
  NonLayered = 'non-layered',
}

export type MapListRequest = {
  page?: number;
  per_page?: number;
  search?: string;
  only_layered_maps?: boolean;
  sort?: MapListSortType;
  filter?: MapListFilter;
};

export type RemoveMapResolution = 'delete' | 'disconnect' | 'remove_layer';

export type RemoveMapAction = {
  layered_id: number;
  action: RemoveMapResolution;
};

export type RemoveMapRequest = {
  maps: ReadonlyArray<{
    id: number;
    actions?: RemoveMapAction[];
    allow_copies_for_dependent_users?: boolean;
  }>;
};

export type MapUpdateRequest = {
  name?: string;
  description?: string | null;
  privacy_level?: MapPrivacy;
  custom_map_url?: string;
};

export type CopyMapRequest = {
  name: string;
};

export type MapSnapshotUpdateRequest = {
  name?: string;
  description?: string | null;
  privacy_level?: MapPrivacy;
};

export type MapPrivacy = {
  type: MapPrivacyLevel;
  password?: string;
  password_confirmation?: string;
};

export type CreateMapRequest = CreateMapRequestEmpty | CreateMapRequestWithData | CreateMapGoogleSheetData;

type ShareMapRequestParams = Readonly<{
  privacyLevel: 'public' | 'password_protected';
  password: string | null;
  useCurrentMapView: boolean;
  nameForCurrentMapView: string | null;
  settingsOverride?: string[];
}>;

export type ShareMapResponse = Readonly<{
  data: Readonly<{
    share_id: string;
  }>;
}>;

type ShareMapEmailRequestParams = Readonly<{
  emails: readonly string[];
  message: string;
  token: string;
}>;

export const ShareMapEmailResults = {
  Success: 'Success',
  CaptchaFailed: 'CaptchaFailed',
  InvalidData: 'InvalidData',
} as const;
export type ShareMapEmailResult = typeof ShareMapEmailResults[keyof typeof ShareMapEmailResults];
export type ShareMapEmailResponse = Readonly<{
  result: ShareMapEmailResult;
}>;

export type MapSearchSuggestsRequestItem = {
  search: string;
  spreadsheet_id: number;
  exact: boolean;
  only_address: boolean;
  max_results?: number;
  one_match_per_col: boolean;
};

type MapSearchSuggestsRequest = {
  requests: MapSearchSuggestsRequestItem[];
};

type MapSearchSuggestItemResponse = {
  cell_text: string;
  column_index: string;
  column_name: string;
};

export type MapSearchSuggestsResponse = {
  count_results: number;
  max_results: number;
  spreadsheet_id: number;
  data: Record<string, { row_id: string; data: MapSearchSuggestItemResponse[] }>;
};

type SpreadsheetResponseId = string;
export type ColumnIdPerSpreadsheet = Record<SpreadsheetResponseId, ColumnId | null>;

export type MapIntersectResponse = Readonly<{
  name: string;
  intersects: ColumnIdPerSpreadsheet;
  column_id: ColumnId;
}>;
export type MapIntersectWithColumnIdResponse = MapIntersectResponse & Readonly<{ columnId: ColumnId }>;
export type MapIntersect = Readonly<{
  name: string;
  intersects: ColumnIdPerSpreadsheet;
}>;
export type MapIntersectWithColumnId = MapIntersect & Readonly<{ columnId: ColumnId }>;
export type MapIntersectsResponse = {
  list: MapIntersect[];
};

export const createMapForClient = (clientId: number, request: CreateMapRequest): Promise<CreateMapResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps`;

  return apiPost(requestUrl, request);
};

export const uploadMapSpreadsheet = (clientId: number, spreadsheet: File, uploadParams?: UploadFileParams): Promise<UploadClientSpreadsheetResponse> => {
  const requestUrl = `/api/clients/${clientId}/upload`;

  return uploadFile(requestUrl, spreadsheet, uploadParams);
};

export const getMapData = (clientId: number, mapId: number, layered: boolean = true): Promise<{ data: MapInfoResponse }> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}?with=parent-map${layered ? ',layered' : ''}`;

  return apiGet(requestUrl);
};

export const getMapPresentationalData = (shareMapId: string, password?: string, token?: string): Promise<{ data: MapPresentationalResponse }> => {
  const requestUrl = `/api/presentation/shared-map/${shareMapId}?with=parent-map,parent-map-settings`;
  const requestParams = {
    password,
    ...(token ? { token } : {}),
  };
  return apiPost(requestUrl, requestParams);
};

export const updateMapData = (clientId: number, mapId: number, request: MapUpdateRequest) => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}`;

  return apiPatch(requestUrl, request);
};

export const copyMap = (clientId: number, mapId: number, request: CopyMapRequest) => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/copy`;

  return apiPost(requestUrl, request);
};

export const updateMapSnapshotData = (clientId: number, mapId: number, snapshotId: number, request: MapSnapshotUpdateRequest) => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/snapshots/${snapshotId}`;
  return apiPatch(requestUrl, request);
};

export const incrementMapView = (clientId: number, mapId: number) => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/view`;

  return apiGet(requestUrl);
};

export const getMapList = (clientId: number, request: MapListRequest, cancelToken: CancelToken): Promise<MapListResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps`;
  const escapedRequest = {
    ...request,
    search: encodeURIComponent(request.search ?? ''),
  };

  return apiGet(requestUrl, escapedRequest, { cancelToken });
};

export const removeMap = (clientId: number, request: RemoveMapRequest): Promise<RemoveMapResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps`;

  return apiDelete(requestUrl, request);
};

export const getMapSettings = (clientId: number, mapId: number): Promise<{ data: MapSettingsDataResponse }> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/settings`;

  return apiGet(requestUrl);
};

const isUpdateConcurrencyErrorResponse = (response: any): response is MapSettingsUpdateSettingsConcurrencyErrorResponse => {
  return !!response.reason;
};

export const mapSettingsUpdateSetting = (
  clientId: number, mapId: number, patches: string[], etag: MapSettingsEtag | null
): Promise<MapSettingsUpdateSettingsCombinedResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/settings`;

  const handleConcurrencyError = catchApiError((apiError: ApiError) => {
    const { responseStatus, response } = apiError;

    if ((responseStatus === 412) && isUpdateConcurrencyErrorResponse(response)) {
      return {
        type: 'concurrency-error',
        data: response,
      } as const;
    }

    throw apiError;
  });

  return apiPatch(requestUrl, { data: patches, etag: etag ?? undefined })
    .then((response: MapSettingsUpdateSettingsResponse) => ({ type: 'success', data: response }) as const)
    .catch(handleConcurrencyError);
};

export const shareMap = (clientId: number, mapId: number, params: ShareMapRequestParams): Promise<ShareMapResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/share`;
  const requestParams = {
    privacy_level: params.privacyLevel,
    privacy_password: params.password ?? undefined,
    privacy_password_confirmation: params.password ?? undefined,
    current: params.useCurrentMapView ? 1 : 0,
    name: params.nameForCurrentMapView ?? undefined,
    settings_override: params.useCurrentMapView ? params.settingsOverride ?? undefined : undefined,
  };

  return apiPost(requestUrl, requestParams);
};

export const shareMapEmail = (clientId: number, mapId: number, params: ShareMapEmailRequestParams): Promise<ShareMapEmailResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/share/email`;
  return apiPost(requestUrl, params)
    .then(() => ({ result: ShareMapEmailResults.Success }))
    .catch(catchApiError(e => {
      if (e.message === 'Invalid ReCaptcha token') {
        return { result: ShareMapEmailResults.CaptchaFailed };
      }
      return { result: ShareMapEmailResults.InvalidData };
    }));
};

export const getMapSearchSuggests = (clientId: number, mapId: number, request: MapSearchSuggestsRequest,
  config?: AxiosRequestConfig): Promise<{ data: MapSearchSuggestsResponse[] }> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/search/autocomplete`;

  return apiPost(requestUrl, request, config);
};

export const guessMapIntersects = (clientId: number, realSpreadsheetIds?: number[], virtualSpreadsheetIds?: number[]): Promise<MapIntersectsResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/intersects`;
  const requestParams = {
    spreadsheets: virtualSpreadsheetIds,
    real_spreadsheets: realSpreadsheetIds,
  };
  if (!requestParams.real_spreadsheets) {
    delete requestParams.real_spreadsheets;
  }
  if (!requestParams.spreadsheets) {
    delete requestParams.spreadsheets;
  }
  return apiPost(requestUrl, requestParams);
};

export const setMapV4MigrationDone = (clientId: number, mapId: number) => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/migration/done`;

  return apiPost(requestUrl);
};
