import { css } from '@emotion/react';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons/faPencil';
import { faUserShield } from '@fortawesome/pro-regular-svg-icons/faUserShield';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import {
  DropDownItemSize, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { TeamShareMapPermission } from '~/_shared/entityAccess/entityAccess.repository';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { MemberIndexCircleComponent } from '~/clientTeamManagement/memberIndexCircle.component';
import { type MapAccessData } from '../teamShareMapModal.container';

const itemContainerStyle = ({ theme }: ThemeProps) => css({
  background: theme.modalColors.contentBackground,
  padding: '12px 22px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const leftContentStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

const sortDropdownWrapperStyle = css({
  width: 170,
});

const memberNameStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  width: '270px',
});

const memberEmailStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  width: '270px',
});

const rightContentStyle = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const iconStyle = ({ theme }: ThemeProps) => css({
  fontSize: '20px',
  color: theme.iconColors.danger,
});

type MembersTableItemProps = {
  currentUserId: number;
  index: number;
  item: MapAccessData;
  onUpdateAccess: (item: MapAccessData, newPermission: TeamShareMapPermission) => void;
  onDeleteAccess: (item: MapAccessData) => void;
};

export const MembersTableItem: FC<MembersTableItemProps> = (props) => {
  const { currentUserId, index, item, onUpdateAccess, onDeleteAccess } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const permissionOptions: DropdownOption<TeamShareMapPermission>[] = useMemo(() => {
    return [
      {
        icon: faUserShield,
        name: t('Owner'),
        value: TeamShareMapPermission.OWNER,
      },
      {
        icon: faPencil,
        name: t('Contributor'),
        value: TeamShareMapPermission.CONTRIBUTOR,
      },
    ];
  }, [t]);

  return (
    <div css={itemContainerStyle({ theme })}>
      <div css={leftContentStyle}>
        <MemberIndexCircleComponent number={index} />
        <span css={memberNameStyle({ theme })}>{item.name}</span>
        <span css={memberEmailStyle({ theme })}>{item.email}</span>
        <div css={sortDropdownWrapperStyle}>
          <RegularDropdownComponent
            value={
              item.ownerId === item.sharedEntityId
                ? TeamShareMapPermission.OWNER
                : TeamShareMapPermission.CONTRIBUTOR
            }
            onChange={() => onUpdateAccess(item, TeamShareMapPermission.OWNER)}
            options={permissionOptions}
            inputSize={InputSize.Medium}
            itemSize={DropDownItemSize.Large}
            isDisabled={item.sharedEntityId === currentUserId}
          />
        </div>
      </div>
      {item.sharedEntityId !== currentUserId && (
        <div
          css={rightContentStyle}
          onClick={() => onDeleteAccess(item)}
        >
          <FontAwesomeIcon
            icon={faTrash}
            css={iconStyle({ theme })}
          />
        </div>
      )}
    </div>
  );
};
