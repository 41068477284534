import {
  apiGet, apiPost,
} from '../../_shared/utils/api/api.helpers';
import { type MetricsDataAction } from '../../_shared/utils/metric/metrics.types';
import { type BoundaryFilterRequest } from '../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type PolygonFilterRequest } from '../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { type RadiusFilterRequest } from '../../spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import {
  type BoundaryGroupDemographic, type BoundaryGroupDemographicServerModel,
} from '../boundaryGroups/boundaryGroups.repository';
import { createDemographicsResultFromResponse } from './demographics.factory';

export type DemographicsGlobalListResponse = {
  regions: DemographicsRegionResponse[];
};

export type DemographicsRegionResponse = {
  id: string;
  demographics: BoundaryGroupDemographicServerModel[];
};

export type DemographicsRegion = {
  id: string;
  demographics: BoundaryGroupDemographic[];
};

export type DemographicsQueryRequest = {
  map_id: number;
  demographics_ids: ReadonlyArray<string>;
  boundary_territory_filter?: BoundaryTerritoryFilterRequest;
  boundary_filter?: BoundaryFilterRequest;
  radius_filter?: RadiusFilterRequest;
  polygon_filter?: PolygonFilterRequest;
};

export type DemographicsResultResponse = {
  category: string;
  function_used: MetricsDataAction;
  name: string;
  percentage: boolean;
  value: number;
  pre_data: string;
  post_data: string;
} & ({
  demographics_id: number; // old model
  codename: string;
} | {
  demographics_id: string; // new model
  codename: undefined;
});

export type DemographicsResult = {
  demographicId: string;
  category: string;
  functionUsed: MetricsDataAction;
  name: string;
  percentage: boolean;
  value: number;
  preData: string;
  postData: string;
};

export const getDemographicsListGlobal = (clientId: number): Promise<DemographicsGlobalListResponse> => {
  const requestUrl = `/api/clients/${clientId}/demographics/list-global`;

  return apiGet(requestUrl);
};

export const getDemographicsQuery = (clientId: number, request: DemographicsQueryRequest): Promise<ReadonlyArray<DemographicsResult>> => {
  const requestUrl = `/api/clients/${clientId}/demographics/query`;

  return apiPost<{ data: ReadonlyArray<DemographicsResultResponse> }>(requestUrl, request)
    .then(response => response.data.map(item => createDemographicsResultFromResponse(item)));
};
