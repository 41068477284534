import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useFileUploadError } from '~/_shared/components/dropzone/dropzone.helpers';
import { type SelectGroupProps } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import { UserFileType } from '~/_shared/types/file.types';
import {
  type CustomMarkerSettings,
  isTemporaryCustomMarkerVisualSettings,
  MarkerAnchorPosition,
  MarkerStyleType,
  type StandardMarkerVisualSettings,
  type TemporaryMarkerVisualSettings,
} from '~/_shared/types/marker.types';
import {
  type LabelVisualSetting,
  type TemporaryMarkerSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { useUserFiles } from '~/_shared/utils/files/useUserFiles';
import { useTranslation } from '~/_shared/utils/hooks';
import { INITIAL_LABEL } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useMarkerSets } from '~/store/frontendState/markerSets/markerSets.selectors';
import { uploadFile } from '~/store/mapSettings/fileAttachments/fileApi.helpers';
import { useMapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { type CustomizationLevelProps } from '../customizeLabelAndMarkerModal.container';
import { type MarkerOptionsChange } from './customizationOptions/customizationOptions.component';
import {
  CustomizeMarkerComponent,
  type CustomizeMarkerTab,
} from './customizeMarker.component';
import { type MarkerTextureChange } from './markerStyleList/markerStyleList.component';

const MARKER_TEXTURE_ALLOWED_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];

export type CustomizeMarkerContainerProps = Readonly<{
  colorCustomizationDisabled?: boolean;
  customizationLevelProps: CustomizationLevelProps;
  defaultTab?: CustomizeMarkerTab;
  markerVisualSettings: TemporaryMarkerVisualSettings;
  labelAboveVisualSettings?: LabelVisualSetting;
  optionalLabelDisabled?: boolean;
  selectGroupProps?: SelectGroupProps;

  onChange: (settings: Pick<TemporaryMarkerSettings, 'marker'>) => void;
}>;

export const CustomizeMarkerContainer: FC<CustomizeMarkerContainerProps> = props => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const [t] = useTranslation();
  const { markerVisualSettings, onChange } = props;
  const markerSets = useMarkerSets();
  const [isUploading, setUploading] = useState<boolean>(false);
  const { getUserFiles, userFiles, deleteFile, isLoading, filesLoaded } = useUserFiles(UserFileType.MarkerTexture);
  const { defaultMarkerSetId } = useMapSettingsMarkersGeneralSelector();
  const { showFileUploadError } = useFileUploadError(MARKER_TEXTURE_ALLOWED_FILE_EXTENSIONS);

  const fileUrlsLookup = useFileUrls();

  const standardMarkers = useMemo(() => Object.values(markerSets)
    .filter(style => style.markerType === MarkerStyleType.STANDARD)
    .map(standardStyle => standardStyle.marker), [markerSets]);

  const onTextureChange = useCallback((markerChanges: MarkerTextureChange): void => {
    const markerVisualSettingsWithChanges = markerChanges.styleType === MarkerStyleType.CUSTOM ? {
      ...markerVisualSettings,
      ...markerChanges,
      anchor: markerVisualSettings.anchor ?? MarkerAnchorPosition.BOTTOM_CENTER,
      opacity: 1, // Opacity is not allowed for custom markers
    } : {
      ...markerVisualSettings,
      ...markerChanges,
    } satisfies TemporaryMarkerVisualSettings;

    if (isTemporaryCustomMarkerVisualSettings(markerVisualSettingsWithChanges)) {
      onChange({ marker: markerVisualSettingsWithChanges });
    }
    else {
      onChange({
        marker: {
          ...markerVisualSettingsWithChanges,
          styleType: MarkerStyleType.STANDARD,
          styleId: markerVisualSettingsWithChanges.styleId || defaultMarkerSetId,
        },
      });
    }

  }, [defaultMarkerSetId, markerVisualSettings, onChange]);

  const onMarkerOptionsChange = useCallback((markerChanges: MarkerOptionsChange): void => {
    const markerVisualSettingsWithChanges = {
      ...markerVisualSettings,
      ...markerChanges,
    } satisfies TemporaryMarkerVisualSettings;

    onChange({ marker: markerVisualSettingsWithChanges });
  }, [markerVisualSettings, onChange]);

  const customMarkers = useMemo(() => {
    const newCustomMarkers: CustomMarkerSettings[] = [];
    for (const userFile of userFiles) {
      const fileUrl = fileUrlsLookup.get(userFile.id);
      if (fileUrl) {
        newCustomMarkers.push({
          anchor: MarkerAnchorPosition.BOTTOM_CENTER,
          dimensions: { width: fileUrl['200p'].resolution.width, height: fileUrl['200p'].resolution.height },
          fileId: userFile.id,
          path: fileUrl['200p'].url,
        });
      }
    }
    return newCustomMarkers;
  }, [userFiles, fileUrlsLookup]);

  const onFileAccept = useCallback(async (files: File[]) => {
    setUploading(true);
    for (const file of files) {
      try {
        await uploadFile(file, clientId, UserFileType.MarkerTexture, (percent: number) => {
          // eslint-disable-next-line no-console
          console.log('Uploading Marker Texture Progress:', percent);
        });
      }
      catch (error) {
        dispatch(createAppError({
          type: AppErrorType.General,
          title: t('Uploading File Problem'),
          text: t('There was an error when uploading your file. Please check your connection and try again. If this problem persists please contact our customer support.'),
        }));
      }
    }
    setUploading(false);
    getUserFiles();
  }, [clientId, dispatch, t, getUserFiles]);

  useEffect(() => {
    getUserFiles();
  }, [getUserFiles]);

  useEffect(() => {
    const selectedStyleFileWasDeleted = filesLoaded && markerVisualSettings.styleType === MarkerStyleType.CUSTOM
      && !userFiles.find(file => file.id === markerVisualSettings.fileId);

    if (selectedStyleFileWasDeleted) {
      onChange({
        marker: {
          ...markerVisualSettings,
          styleType: MarkerStyleType.STANDARD,
          styleId: defaultMarkerSetId,
        } as StandardMarkerVisualSettings,
      });
    }
  }, [defaultMarkerSetId, filesLoaded, markerVisualSettings, onChange, userFiles]);

  return (
    <CustomizeMarkerComponent
      colorCustomizationDisabled={props.colorCustomizationDisabled}
      customMarkers={customMarkers}
      customizationLevelProps={props.customizationLevelProps}
      defaultTab={props.defaultTab}
      isUploading={isUploading || isLoading}
      labelAboveVisualSettings={props.labelAboveVisualSettings ?? INITIAL_LABEL}
      markerVisualSettings={markerVisualSettings}
      onTextureChange={onTextureChange}
      onMarkerOptionsChange={onMarkerOptionsChange}
      onFileError={showFileUploadError}
      onFileAccept={onFileAccept}
      onImageDelete={deleteFile}
      optionalLabelDisabled={props.optionalLabelDisabled}
      standardMarkers={standardMarkers}
      acceptFileExtensions={MARKER_TEXTURE_ALLOWED_FILE_EXTENSIONS}
      selectGroupProps={props.selectGroupProps}
    />
  );
};
