import {
  type AxisOffsets, type QuadrilateralDimensions,
} from '../../types/coordinateSystem/coordinateSystem';
import { easeOutFactory } from '../../utils/function.helpers';

export type MarkerTextureOffsets = Readonly<{
  bottom: number;
  left: number;
  right: number;
  top: number;
}>;

export const ensureMaxDimensions = ({ dimensions, maxDimensions, anchorOffsets, markerTextureOffsets, sizeData }: {
  anchorOffsets: AxisOffsets;
  dimensions: QuadrilateralDimensions;
  markerTextureOffsets: MarkerTextureOffsets;
  maxDimensions: QuadrilateralDimensions;
  sizeData?: {
    size: number;
    maxSize: number;
    applyEasing?: boolean;
  };
}): [QuadrilateralDimensions, AxisOffsets, MarkerTextureOffsets] => {
  const { height: textureHeight, width: textureWidth } = dimensions;

  const applyRatioToProperties = <T extends { [property: string]: number }> (newDimensionsRatio: number, oldOffsets: T) => (
    Object.keys(oldOffsets).reduce((result, offsetName) => ({
      ...result,
      [offsetName]: oldOffsets[offsetName] * newDimensionsRatio,
    }), {} as T)
  );

  const getNewAnchorOffsets = (newDimensionsRatio: number) => applyRatioToProperties(newDimensionsRatio, anchorOffsets);

  const getNewTextureOffsets = (newDimensionsRatio: number) => applyRatioToProperties(newDimensionsRatio, markerTextureOffsets);

  const markerWidth = textureWidth - markerTextureOffsets.left - markerTextureOffsets.right;
  const markerHeight = textureHeight - markerTextureOffsets.top - markerTextureOffsets.bottom;

  const textureToDimensionsWidthRatio = markerWidth / maxDimensions.width;
  const textureToDimensionsHeightRatio = markerHeight / maxDimensions.height;
  const textureToDimensionsRatio = textureToDimensionsWidthRatio > textureToDimensionsHeightRatio ? textureToDimensionsWidthRatio : textureToDimensionsHeightRatio;
  const textureResizeRatio = 1 / textureToDimensionsRatio;

  let sizeMultiplier = 1;
  if (sizeData) {
    let size = sizeData.size;
    if (sizeData.maxSize < size) {
      size = sizeData.maxSize;
    }
    else if (sizeData.applyEasing) {
      const easingFunction = easeOutFactory(3);
      size = easingFunction(size / sizeData.maxSize) * sizeData.maxSize;
    }
    sizeMultiplier = size / sizeData.maxSize;
  }

  const finalRatio = textureResizeRatio * sizeMultiplier;

  const newTextureWidth = textureWidth * finalRatio;
  const newTextureHeight = textureHeight * finalRatio;

  return [
    {
      height: newTextureHeight,
      width: newTextureWidth,
    },
    getNewAnchorOffsets(finalRatio),
    getNewTextureOffsets(finalRatio),
  ];
};
