import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from '~/_shared/constants/routes';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { mutuallyExclusiveMapTools } from '~/store/frontendState/mapTools/mutuallyExclusiveMapTools/mutuallyExclusiveMapTools';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';

export const useOnNavigateFromMap = () => {
  const dispatch = useDispatch();
  const currentPage = useGetCurrentPageInfo().page;
  const prevPage = usePrevious(currentPage);

  useEffect(() => {
    if (prevPage === Page.ADMIN_MAP && currentPage !== prevPage) {
      mutuallyExclusiveMapTools.forEach((feature) => {
        dispatch(feature.off());
      });
    }
  }, [currentPage, dispatch, prevPage]);
};
