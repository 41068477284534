import {
  type FC, useMemo,
} from 'react';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  s, Trans,
} from '~/translations/Trans';

type MembersLimitReachedErrorProps = {
  subscriptionIncludesMembers: boolean;
};

export const MembersLimitReachedError: FC<MembersLimitReachedErrorProps> = props => {
  const [t] = useTranslation();

  const supportLink = useMemo(() => (
    <SupportLinkComponent
      type={SupportLinkType.danger}
      subject={t('userManagement.error.membersLimitReached.subject')}
    />
  ), [t]);

  return props.subscriptionIncludesMembers ? (
    <Trans
      i18nKey="userManagement.error.membersLimitReached"
    >
      {s('Oops! You\'ve hit the limit for members on your current subscription. To expand your team and upgrade to a plan with a higher member capacity, please contact ')}
      {supportLink}
      {s(' .')}
    </Trans>
  ) : (
    <Trans
      i18nKey="userManagement.error.membersLimitReached.noMemberAllowance"
    >
      {s('It looks like your current subscription doesn\'t include the ability to add more members. To unlock this feature and upgrade to our Team Plan, please contact ')}
      {supportLink}
      {s(' .')}
    </Trans>
  );
};
