import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  createColor, createColorWithOpacity,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  PermanentConfirmStrategy, useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsBoundaryLocationsExportAllowed } from '~/boundary/useIsBoundaryExportLocationsAllowed';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type ExtendedBoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import {
  isCustomGroup, useBoundaryGroupsSelector,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import {
  useBoundaryTerritoryAssignments,
  useBoundaryTerritoryDetailsSelector,
} from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import {
  boundaryTerritoryGroupsRemoveRequest,
  boundaryTerritoryGroupsUpdateRequest,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { mapSettingsBoundariesSetPrimaryBoundaryGroupId } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.actionCreators';
import { useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { setBoundaryFilter } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import { useBoundaryFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { openModal } from '~/store/modal/modal.actionCreators';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { useTerritoryEdit } from '../../../../../_shared/hooks/useTerritoryEdit';
import {
  BOUNDARY_NO_LOCATIONS_ID, isBoundaryTerritoryHidden,
} from '../../../../../boundary/settings/boundarySettings.helpers';
import { displayNameComparer } from '../../../../../store/boundaryItems/boundaryItems.helpers';
import { boundarySelectEditBoundaryTerritoryOpenModal } from '../../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { convertBoundariesShowNameModal } from '../../../../../store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.actionCreators';
import { useIsConnectedLayeredMapSelector } from '../../../../../store/mapInfo/mapInfo.selectors';
import {
  type ActiveBoundaryProps,
  type BoundaryKeySettings,
  type BoundaryTerritoryKeySettings,
} from '../activeBoundary/activeBoundary.component';
import { getBoundaryItemStyle } from '../boundaryItem.helpers';
import { useBoundaryEdit } from '../edit/useBoundaryEdit';
import { isBoundaryTerritoryEmpty } from '../fill/boundaryFill.helpers';
import { useBoundaryBatchEditLocations } from './useBoundaryBatchEditLocations';
import { useBoundaryExportData } from './useBoundaryExportData';
import { useBoundaryTerritoryGroupDisplayName } from './useBoundaryTerritoryGroupDisplayName';
import { useGetBoundaryTerritoryDisplayNames } from './useGetBoundaryTerritoryDisplayName';
import { useRemoveBoundaryTerritory } from './useRemoveBoundaryTerritory';
import { useRemoveCustomBoundary } from './useRemoveCustomBoundary';
import { useZoomToBoundary } from './useZoomToBoundary';

export const useBoundaryActiveItems = () => {
  const { openModal: openBoundaryItemSettingsModal } = useModal(ModalType.BoundaryItemSettings);
  const { openModal: openBoundaryCustomizeMetricsModal } = useModal(ModalType.BoundaryCustomizeMetrics);
  const { openModal: openBoundaryCustomizeFillModal } = useModal(ModalType.BoundaryCustomizeFill);
  const { openModal: openExportContainedBoundaries } = useModal(ModalType.ExportContainedBoundaryData);
  const { openModal: openCloneBoundaryTerritoryGroupModal } = useModal(ModalType.CloneBoundaryTerritoryGroup);
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryFilters = useBoundaryFilters();
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaries = useMapBoundariesSelector();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const primaryBoundaryGroupId = useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector();
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isBoundaryExportLocationsAllowed = useIsBoundaryLocationsExportAllowed();
  const { getBoundaryTerritoryDisplayName } = useGetBoundaryTerritoryDisplayNames();
  const boundaryTerritoryDetails = useBoundaryTerritoryDetailsSelector();
  const { removeTerritory } = useRemoveBoundaryTerritory();
  const { removeCustomBoundary } = useRemoveCustomBoundary();
  const { startBoundaryEdit } = useBoundaryEdit();
  const { exportBoundaryData, exportBoundaryTerritoryData } = useBoundaryExportData();
  const { getBoundaryTerritoryGroupName } = useBoundaryTerritoryGroupDisplayName();
  const { openModal: openExportBoundaryTerritoryGroupDataModal } = useModal(ModalType.ExportBoundaryTerritoryGroupData);
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { zoomToBoundary } = useZoomToBoundary();
  const { batchEditLocationsInBoundary, batchEditLocationsInBoundaryTerritory } = useBoundaryBatchEditLocations();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const startTerritoryEdit = useTerritoryEdit();

  const removeBoundaryTerritoryGroup = useCallback((boundaryTerritoryGroupId: number, isCustom: boolean) => {
    const entity = isCustom ? t('territory group') : t('boundary');

    openConfirmationModal({
      title: t('Remove {{entity}} from map', { entity }),
      confirmCaption: t('Proceed'),
      text: t('You are about to remove a {{entity}} from the map. This action is irreversible. Do you want to proceed?', { entity }),
      permanentConfirmSettings: {
        id: 'remove-boundary-territory-group-from-map',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        dispatch(boundaryTerritoryGroupsRemoveRequest(boundaryTerritoryGroupId));
        closeConfirmationModal();
      },
    });
  }, [closeConfirmationModal, dispatch, openConfirmationModal, t]);

  const editBoundarySettings = useCallback((identifier: ExtendedBoundaryIdentifier) => {
    openBoundaryItemSettingsModal(identifier);
  }, [openBoundaryItemSettingsModal]);

  const convertTerritoriesToBoundaries = useCallback((boundaryTerritoryGroupId: number) => {
    dispatch(convertBoundariesShowNameModal(boundaryTerritoryGroupId));
  }, [dispatch]);

  return useMemo<ActiveBoundaryProps[]>(() => {
    if (primarySpreadsheetId === null) {
      return [];
    }

    return boundaryTerritoryGroups.map(item => {
      const displayName = getBoundaryTerritoryGroupName(item);
      const matchingBoundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === item.boundaryGroupId);
      const isBoundaryGroupCustom = isCustomGroup(matchingBoundaryGroup);

      const boundaryTerritoryKeySettings: BoundaryTerritoryKeySettings[] = [];
      const boundaryKeySettings: BoundaryKeySettings[] = [];
      const canDrawInGroup = isBoundaryGroupCustom && !isMapPresentational;
      const filter = boundaryFilters.get(item.boundaryGroupId);

      const setBoundaryTerritoriesFilter = (filteredBoundaryTerritories: string[], showAll: TriStateRange) => {
        dispatch(setBoundaryFilter(item.boundaryGroupId, {
          ...filter,
          filteredBoundaryTerritories,
          showAll,
        }));
      };

      const setFilteredBoundaries = (filteredBoundaries: number[], showAll: TriStateRange) => {
        dispatch(setBoundaryFilter(item.boundaryGroupId, {
          ...filter,
          filteredBoundaries,
          showAll,
        }));
      };

      const onToggleShowAll = () => {
        dispatch(setBoundaryFilter(item.boundaryGroupId, {
          ...filter,
          filteredBoundaryTerritories: [],
          filteredBoundaries: [],
          showAll: filter.showAll === TriStateRange.Full
            ? TriStateRange.None
            : TriStateRange.Full,
        }));
      };

      const onToggleHideLabels = () => {
        dispatch(boundaryTerritoryGroupsUpdateRequest({
          ...item,
          settings: {
            ...item.settings,
            hideLabels: !item.settings.hideLabels,
          },
        }, { fetchBoundaryDetails: false }));
      };

      const onToggleHideAreasWithoutData = () => {
        dispatch(boundaryTerritoryGroupsUpdateRequest({
          ...item,
          settings: {
            ...item.settings,
            hideWithoutData: !item.settings.hideWithoutData,
          },
        }, { fetchBoundaryDetails: false }));
      };

      const onToggleIgnoreFilters = () => {
        dispatch(boundaryTerritoryGroupsUpdateRequest({
          ...item,
          settings: {
            ...item.settings,
            ignoreFilters: !item.settings.ignoreFilters,
          },
        }));
      };

      const setCombineBoundariesIntoTerritories = (boundariesAsTerritories: boolean) => {
        dispatch(boundaryTerritoryGroupsUpdateRequest({
          ...item,
          settings: {
            ...item.settings,
            boundariesAsTerritories,
          },
        }));
      };

      const onClone = () => openCloneBoundaryTerritoryGroupModal({
        boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
      });

      const displayBoundaryTerritories = !isBoundaryGroupCustom || item.settings.boundaryTerritories.length > 0;

      if (displayBoundaryTerritories) {
        item.settings.boundaryTerritories.forEach(boundaryTerritory => {
          const territoryIsCustom = boundaryTerritory.custom;
          const canDelete = territoryIsCustom && !isMapPresentational;
          const isEmpty = isBoundaryTerritoryEmpty(boundaryTerritoryAssignments, item.boundaryTerritoryGroupId, boundaryTerritory);
          const isNoLocations = boundaryTerritory.boundaryTerritoryId === BOUNDARY_NO_LOCATIONS_ID;
          const canExportData = !isEmpty && !isNoLocations && isBoundaryExportLocationsAllowed;
          const isHidden = isBoundaryTerritoryHidden(boundaryTerritory, isEmpty, item.settings.boundaryTerritoryType);

          if (isHidden) {
            return;
          }

          boundaryTerritoryKeySettings.push({
            isVisible: filter.filteredBoundaryTerritories.includes(boundaryTerritory.boundaryTerritoryId),
            label: getBoundaryTerritoryDisplayName(boundaryTerritory.boundaryTerritoryId, item.boundaryTerritoryGroupId),
            selectedColor: createColorWithOpacity(boundaryTerritory.style.color, boundaryTerritory.style.opacity / 100),
            lineColor: createColor(item.settings.style.lineColor),
            lineWidth: item.settings.style.lineWidth,

            onToggle: () => {
              const newFilter = filter.filteredBoundaryTerritories
                .filter(territoryId => territoryId !== boundaryTerritory.boundaryTerritoryId);

              if (newFilter.length === filter.filteredBoundaryTerritories.length) {
                newFilter.push(boundaryTerritory.boundaryTerritoryId);
              }

              const showAll = newFilter.length ? TriStateRange.Partial : TriStateRange.None;

              setBoundaryTerritoriesFilter(newFilter, showAll);
            },
            isCustom: boundaryTerritory.custom,
            isEmpty,
            onExportData: canExportData ? () => exportBoundaryTerritoryData(item.boundaryTerritoryGroupId, boundaryTerritory.boundaryTerritoryId) : undefined,
            onDelete: canDelete ? () => {
              removeTerritory(item.boundaryTerritoryGroupId, boundaryTerritory.boundaryTerritoryId);
            } : undefined,
            onBatchEditLocationsClick: !isMapPresentational && !isLayeredMapConnected && !isNoLocations
              ? () => batchEditLocationsInBoundaryTerritory(item.boundaryTerritoryGroupId, boundaryTerritory.boundaryTerritoryId)
              : undefined,
            onExportContainedBoundaries: !isMapPresentational
              ? () => openExportContainedBoundaries({
                filter: {
                  type: 'boundary_territory',
                  boundaryTerritoryId: boundaryTerritory.boundaryTerritoryId,
                  boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
                },
              })
              : undefined,
          });
        });
      }

      const groupBoundaries = boundaries.get(item.boundaryGroupId);
      if (!displayBoundaryTerritories && groupBoundaries) {
        Array.from(groupBoundaries.values())
          .sort(displayNameComparer)
          .forEach(boundary => {
            const canDraw = !isMapPresentational && canDrawInGroup;
            const canRemoveBoundary = !isMapPresentational;

            const boundaryStyle = getBoundaryItemStyle(item, boundary.id);
            const boundaryGroupMarkerCounts = boundaryTerritoryDetails.get(item.boundaryTerritoryGroupId)?.counts;
            const boundaryMarkerCounts = boundaryGroupMarkerCounts?.[boundary.id] ?? 0;
            const isEmpty = boundaryMarkerCounts === 0;

            boundaryKeySettings.push({
              isVisible: filter.filteredBoundaries.includes(boundary.id),
              label: boundary.displayName,
              selectedColor: createColorWithOpacity(boundaryStyle.color, boundaryStyle.opacity / 100),
              lineColor: createColor(item.settings.style.lineColor),
              lineWidth: item.settings.style.lineWidth,
              onToggle: () => {
                const newFilter = filter.filteredBoundaries
                  .filter(boundaryId => boundaryId !== boundary.id);

                if (newFilter.length === filter.filteredBoundaries.length) {
                  newFilter.push(boundary.id);
                }

                const showAll = newFilter.length ? TriStateRange.Partial : TriStateRange.None;

                setFilteredBoundaries(newFilter, showAll);
              },
              isCustom: true,
              onBatchEditLocationsClick: !isMapPresentational && !isLayeredMapConnected && !isEmpty ? () => {
                batchEditLocationsInBoundary(item.boundaryTerritoryGroupId, boundary.id);
              } : undefined,
              onRemoveBoundaryClick: canRemoveBoundary ? () => {
                removeCustomBoundary(boundary.id);
              } : undefined,
              onManuallyEditPolygonClick: canDraw ? () => {
                startTerritoryEdit(boundary.id, item.boundaryGroupId, item.boundaryTerritoryGroupId);
              } : undefined,
              onBoundarySettingsClick: !isMapPresentational ? () => {
                editBoundarySettings({
                  boundaryGroupId: item.boundaryGroupId,
                  boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
                  boundaryId: boundary.id,
                });
              } : undefined,
              onExportDataInBoundaryClick: isBoundaryExportLocationsAllowed && !isEmpty ?
                () => exportBoundaryData(item.boundaryTerritoryGroupId, boundary.id) : undefined,
              onExportContainedBoundaries: !isMapPresentational
                ? () => openExportContainedBoundaries({
                  filter: {
                    type: 'boundary',
                    boundaryId: boundary.id,
                    boundaryGroupId: item.boundaryGroupId,
                    matchings: item.matchings,
                  },
                })
                : undefined,
              onZoomToBoundaryClick: () => zoomToBoundary([{
                boundaryGroupId: item.boundaryGroupId,
                boundaryId: boundary.id,
              }]),
            });
          });
      }

      const results: ActiveBoundaryProps = {
        boundaryTerritoryKeySettings,
        boundaryKeySettings,
        name: displayName,
        onSettingsClick: () => {
          dispatch(openModal(ModalType.BoundarySettings, {
            boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
          }));
        },
        isAiGenerated: !!matchingBoundaryGroup?.wms?.territoriesCount,
        isBoundaryGroupCustom,
        boundaryGroupId: item.boundaryGroupId,
        boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
        boundaryTerritoryType: item.settings.boundaryTerritoryType,
        isPrimary: item.boundaryGroupId === primaryBoundaryGroupId,
        showAll: filter.showAll,
        hideLabels: item.settings.hideLabels,
        hideWithoutData: item.settings.hideWithoutData,
        ignoreFilters: item.settings.ignoreFilters,
        showToolbar: !isMapPresentational,
        boundariesAsTerritories: item.settings.boundariesAsTerritories,
        onToggleBoundariesAsTerritories: !isMapPresentational && !isBoundaryGroupCustom ? () => {
          setCombineBoundariesIntoTerritories(!item.settings.boundariesAsTerritories);
        } : undefined,
        onRemove: () => {
          removeBoundaryTerritoryGroup(item.boundaryTerritoryGroupId, isBoundaryGroupCustom);
        },
        onCustomizeFillClick: () => {
          openBoundaryCustomizeFillModal({
            boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
          });
        },
        onCustomizeMetricsClick: () => {
          openBoundaryCustomizeMetricsModal({
            boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
            isBoundaryGroupCustom,
          });
        },
        onStartEditClick: () => {
          if (isBoundaryGroupCustom) {
            startBoundaryEdit(item.boundaryTerritoryGroupId);
          }
          else {
            dispatch(boundarySelectEditBoundaryTerritoryOpenModal({
              selectBoundaryGroupId: item.boundaryGroupId,
              boundaryTerritoryGroupId: item.boundaryTerritoryGroupId,
            }));
          }
        },
        onToggleIsPrimary: () => dispatch(mapSettingsBoundariesSetPrimaryBoundaryGroupId(item.boundaryGroupId)),
        onConvert: item.settings.boundaryTerritories.length ? () => convertTerritoriesToBoundaries(item.boundaryTerritoryGroupId) : undefined,
        onClone: !isMapPresentational && isBoundaryGroupCustom ? onClone : undefined,
        onToggleShowAll,
        onToggleHideLabels,
        onToggleHideWithoutData: onToggleHideAreasWithoutData,
        onToggleIgnoreFilters: !isMapPresentational ? onToggleIgnoreFilters : undefined,
        onExportClick: isBoundaryExportLocationsAllowed
          ? () => {
            openExportBoundaryTerritoryGroupDataModal({
              selectedBoundaryTerritoryGroupIds: [item.boundaryTerritoryGroupId],
              showSelectBoundaryTerritoryGroupsSection: false,
            });
          }
          : undefined,
      };

      return results;
    });
  }, [
    primarySpreadsheetId,
    boundaryTerritoryGroups,
    getBoundaryTerritoryGroupName,
    boundaryGroups,
    isMapPresentational,
    boundaryFilters,
    boundaries,
    primaryBoundaryGroupId,
    isBoundaryExportLocationsAllowed,
    dispatch,
    boundaryTerritoryAssignments,
    getBoundaryTerritoryDisplayName,
    isLayeredMapConnected,
    exportBoundaryTerritoryData,
    removeTerritory,
    batchEditLocationsInBoundaryTerritory,
    openExportContainedBoundaries,
    boundaryTerritoryDetails,
    batchEditLocationsInBoundary,
    removeCustomBoundary,
    startTerritoryEdit,
    editBoundarySettings,
    exportBoundaryData,
    zoomToBoundary,
    removeBoundaryTerritoryGroup,
    openBoundaryCustomizeFillModal,
    openBoundaryCustomizeMetricsModal,
    startBoundaryEdit,
    convertTerritoriesToBoundaries,
    openExportBoundaryTerritoryGroupDataModal,
    openCloneBoundaryTerritoryGroupModal,
  ]);
};
