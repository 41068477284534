import { type TinyMapPlaceholderSize } from './tinyMapPlaceholder.component';

export const getContainerDimension = (size: TinyMapPlaceholderSize) => {
  switch (size) {
    case 'Small':
      return 24;
    case 'Large':
      return 48;
    case 'Medium':
    default:
      return 32;
  }
};
