import type { ReactNode } from 'react';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import { AboutUsContentComponent } from './aboutUsContent.component';

type AboutUsModalComponentProps = {
  licenses: ReactNode;
  isLoading?: boolean;
} & ModalProps;

export const AboutUsModalComponent = (props: AboutUsModalComponentProps) => {
  const { isLoading, licenses, isOpen, onClose } = props;
  const [t] = useTranslation();

  return (
    <ModalComponent
      caption={t('aboutUs.title')}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={715}
    >
      {isLoading && <OverlayLoaderComponent />}
      <AboutUsContentComponent>
        {licenses}
      </AboutUsContentComponent>
    </ModalComponent>
  );
};
