import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { MarkerComponent } from '~/_shared/components/marker/marker.component';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import {
  type CustomMarkerSettings,
  type MarkerAnchorPosition,
  type MarkersMap,
  MarkerStyleType,
  type MarkerVisualSettingsColor,
  type StandardMarkerSettings,
} from '~/_shared/types/marker.types';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { PreviewTileWrapperComponent } from '~/customizeMarkerPopup/customizeMarker/previewTiles/previewTileWrapper.component';

const markerComponentStyle = (size: number) => css({
  height: `${size}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type PreviewTileProps = Partial<MarkerVisualSettingsColor> & Readonly<{
  customMarkers: MarkersMap<CustomMarkerSettings>;
  labelAbove: LabelVisualSetting;
  marker: {
    labelText?: string;
    size: number;
    id: number;
    styleType: MarkerStyleType;
    anchor: MarkerAnchorPosition | null;
  };
  standardMarkers: MarkersMap<StandardMarkerSettings>;
}>;

export const PreviewTileComponent: FC<PreviewTileProps> = (props) => {
  const { labelAbove, marker } = props;
  const markerDimensions: QuadrilateralDimensions = useMemo(() => ({ height: marker.size, width: marker.size }), [marker]);

  const standardTile = useMemo(() => {
    const standardMarker = props.standardMarkers[marker.id];

    if (!standardMarker) {
      return null;
    }

    return (
      <PreviewTileWrapperComponent
        marker={marker}
        labelAbove={labelAbove}
        imageAlt="Standard marker Background"
      >
        <MarkerComponent
          css={markerComponentStyle(marker.size)}
          marker={standardMarker}
          markerType={MarkerStyleType.STANDARD}
          maxDimensions={markerDimensions}
          opacity={props.opacity}
          selectedColor={props.selectedColor}
        />
      </PreviewTileWrapperComponent>
    );
  }, [props.standardMarkers, props.opacity, props.selectedColor, marker, labelAbove, markerDimensions]);

  const customTile = useMemo(() => {
    const customMarker = props.customMarkers[marker.id];

    if (!customMarker) {
      return null;
    }

    return (
      <PreviewTileWrapperComponent
        marker={marker}
        labelAbove={labelAbove}
        imageAlt="Custom marker Background"
      >
        <MarkerComponent
          css={markerComponentStyle(marker.size)}
          marker={customMarker}
          markerType={MarkerStyleType.CUSTOM}
          maxDimensions={markerDimensions}
        />
      </PreviewTileWrapperComponent>
    );
  }, [props.customMarkers, marker, labelAbove, markerDimensions]);

  return marker.styleType === MarkerStyleType.CUSTOM ? customTile : standardTile;
};
