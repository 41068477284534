import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { NoteModalComponent } from '~/_shared/components/modal/noteModal.component';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { closeAllModals } from '~/store/modal/modal.actionCreators';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';
import { type ModalProps } from '../../../../modal/modalType.enum';
import { type ImpersonationStatusChange } from './types';
import {
  getSubTitle, getText, getTitle,
} from './utils';

export type ImpersonationChangedModalProps = Readonly<ModalProps<{
  reason: ImpersonationStatusChange;
  requireRefresh: boolean;
}>>;

export const ImpersonationChangedModal = ({ reason, requireRefresh, ...props }: ImpersonationChangedModalProps) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(userGetDataRequest({ fetchAnnouncements: true }));
    navigate(HOME_ROUTE);
    dispatch(closeAllModals());
  }, [navigate, dispatch]);

  return (
    <NoteModalComponent
      title={getTitle(reason, t)}
      subTitle={getSubTitle(requireRefresh, t)}
      text={getText(requireRefresh, t)}
      textColor={theme.textColors.secondary}
      isCloseDisabled={requireRefresh}
      confirmButton={requireRefresh ? (
        <ButtonComponent
          text={t('Refresh Page')}
          onClick={onConfirm}
        />
      ) : undefined}
      {...props}
    />
  );
};
