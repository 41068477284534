import { css } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { HOME_ROUTE } from '../../_shared/constants/routes';
import { type ThemeProps } from '../../_shared/types/themeProps';
import {
  HOME_NAVIGATION_TEST_ID, type NavigationItem,
} from '../../topBar/navigation/navigation.helpers';

type NavigationProps = {
  activeNavigationItem: NavigationItem;
  homePageNavigationItem: NavigationItem;
};

const itemStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  display: 'flex',
  alignItems: 'center',
  color: isDisabled ? theme.textColors.disabled : theme.textColors.primary,
  padding: '14px 10px',
  fontSize: '16px',
  lineHeight: '1.25',
  textDecoration: 'none',
  pointerEvents: isDisabled ? 'none' : undefined,
});

const iconStyle = css({
  fontSize: '20px',
});

export const MobileNavigationComponent: FC<NavigationProps> = (props) => {
  const theme = useTheme();

  return (
    <>
      {props.activeNavigationItem.path !== HOME_ROUTE && (
        <Link
          to={props.homePageNavigationItem.isDisabledWithMessage ? '' : props.homePageNavigationItem.path}
          css={itemStyle({ theme, isDisabled: !!props.homePageNavigationItem.isDisabledWithMessage })}
          data-testid={HOME_NAVIGATION_TEST_ID}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            css={iconStyle}
          />
        </Link>
      )}
    </>
  );
};
