import { css } from '@emotion/react';
import {
  animated,
  useSpring,
} from '@react-spring/web';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { type SearchMatchingBehaviour } from '~/_shared/constants/searchMatchingBehaviour.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useSlidingAnimationDuration } from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { MapFilterComponent } from '../filter/mapFilter.component';
import { type MapSearchFilterBy } from '../filter/mapSearchFilterBy.enum';
import { type SearchItem } from './mapSearch.helpers';

const wrapperStyle = css({
  maxWidth: 610,
  width: '80%',
  height: 48,
  lineHeight: '48px',
  borderRadius: 4,
  textAlign: 'center',
});

const mobileWrapperStyle = css({
  width: 'calc(100% - 10px) !important',
  height: 48,
  lineHeight: '48px',
  borderRadius: 4,
  textAlign: 'center',
  left: '5px !important',
  top: '5px !important',
  transform: 'translate(0%) !important',
});

type MapSearchProps = Readonly<{
  areSearchSuggestsLoading: boolean;
  className?: string;
  filterBy: MapSearchFilterBy | null;
  isMobileScreen: boolean;
  searchSuggests: SearchItem[];
  searchText: string;

  onCloseSearchBar?: () => void;
  onSearchSuggestSubmit: (suggest: SearchItem) => void;
  onSetFilterBy: (filterBy: MapSearchFilterBy) => void;
  onSetMapSearchSettings: (selectedMatchingBehaviour: SearchMatchingBehaviour, shouldMatchExactWords: boolean, shouldZoomOnMatches: boolean) => void;
  onSetMapSearchText: (text: string) => void;
}>;

export const MapSearchComponent: FC<MapSearchProps> = (props) => {
  const { onSetMapSearchText, filterBy, onSetFilterBy } = props;

  const slidingAnimationDuration = useSlidingAnimationDuration();
  const { openModal } = useModal(ModalType.SearchSettings);

  const animationConfig = useMemo(() => ({ duration: slidingAnimationDuration, clamp: true }), [slidingAnimationDuration]);

  const createInitialStyles = useCallback(() => (
    { from: { width: '0%' }, width: '100%', config: animationConfig }
  ), [animationConfig]);

  const [transitionProps] = useSpring(() => createInitialStyles());

  const onTextChange = (text: string) => {
    onSetMapSearchText(text);
  };

  return (
    <animated.div
      style={transitionProps}
      css={props.isMobileScreen ? mobileWrapperStyle : wrapperStyle}
      className={props.className}
    >
      <MapFilterComponent
        filterBy={filterBy}
        searchText={props.searchText}
        onFilterByChange={onSetFilterBy}
        onSearchTextChange={onTextChange}
        onShowConfig={() => openModal({ onSave: v => props.onSetMapSearchSettings(v.matchingBehaviour, v.shouldMatchExactWords, v.shouldZoomOnMatches) })}
        searchSuggests={props.searchSuggests}
        onSearchSuggestSubmit={props.onSearchSuggestSubmit}
        areSearchSuggestsLoading={props.areSearchSuggestsLoading}
        isMobileScreen={props.isMobileScreen}
        onCloseSearchBar={props.onCloseSearchBar}
      />
    </animated.div>
  );
};
