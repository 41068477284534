import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  DRAWING_TOOL_DEFAULT_FONT_COLOR_SECONDARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingLabelSettingsState } from './drawingLabelSettings.state';

export const drawingLabelSettingsInitialState: DrawingLabelSettingsState = {
  scalesWithMapZoom: false,
  fontSize: 20,
  dotSize: 50,
  dotColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  backgroundColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY,
  fontColor: DRAWING_TOOL_DEFAULT_FONT_COLOR_SECONDARY,
};

export const drawingLabelSettingsReducer = (
  state = drawingLabelSettingsInitialState, action: DrawingSettingsAction
): DrawingLabelSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Label) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.label,
      };
    }

    default:
      return state;
  }
};
