import {
  type FC, memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { type IndividualRadiusProximity } from '~/_shared/types/proximity/proximity.types';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useMapObjectDragAndDrop } from '~/_shared/utils/hooks/useMapObjectDragAndDrop';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { changeIndividualProximityLatLng } from '~/_shared/utils/proximity';
import { useCenterInteraction } from '~/map/map/proximity/useCenterMarkerInteraction';
import {
  endIndividualRadiusDrag, endIndividualRadiusHoverInDnD, startIndividualRadiusDnDMode, startIndividualRadiusDrag,
  startIndividualRadiusHoverInDnD,
} from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { useProximityToolSelector } from '~/store/frontendState/proximityTool/proximityTool.selectors';
import { moveIndividualProximity } from '~/store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { useIndividualOrDriveTimeProximityZIndex } from '../../zIndexes/useIndividualOrDriveTimeProximityZIndex.hook';
import { isProximityBigEnough } from './isProximityBigEnough.helper';
import { type MapProximityManager } from './mapProximityManager';
import { useProximityExtraStyles } from './useProximityStyles';

type ProximityInstanceCircleIndividualProps = {
  manager: MapProximityManager;
  proximity: IndividualRadiusProximity;
  isVisible: boolean;
  zoom: number;
  map: google.maps.Map | undefined;
};

export const ProximityInstanceCircleIndividual: FC<ProximityInstanceCircleIndividualProps> = memo(props => {
  const hideLabel = useSelector(s => s.map.mapSettings.data.proximity.hideLabels);
  const hideSmallRadii = useSelector(s => s.map.mapSettings.data.proximity.hideSmallRadii);
  const proximityIdInDnD = useProximityToolSelector().proximityIdInDnDMode;
  const zIndex = useIndividualOrDriveTimeProximityZIndex(props.proximity.id, 'circle');
  const isMapInteractionActive = useIsMapInteractionActive();
  const dispatch = useDispatch();
  const proximityWithExtraStyles = useProximityExtraStyles(props.proximity);

  const [isInDnDMode, setIsInDnDMode] = useState(false);
  const [isMouseOverProximity, setIsMouseOverProximity] = useState(false);
  const [latestLatLng, setLatestLatLng] = useState<LatLng>(props.proximity.data.data);
  const [renderedCircle, setRenderedCircle] = useState<WebglOverlayCircleArea | null>(null);
  const [renderedCenter, setRenderedCenter] = useState<WebglOverlayMarker | null>(null);
  const [dragStartToCenterVector, setDragStartToCenterVector] = useState<LatLng>({ lat: 0, lng: 0 });

  const { isMouseOverCenter } = useCenterInteraction(renderedCenter, props.proximity.id);

  // we combine hideSmallRadii and props.zoom to one variable
  // so when hideSmallRadii is not enabled, we don't do unnecessary processing
  const hideSmallRadiiZoom = useMemo(() => (
    hideSmallRadii ? props.zoom : null
  ), [hideSmallRadii, props.zoom]);

  const startDnDMode = useCallback(() => {
    dispatch(startIndividualRadiusDnDMode(props.proximity.id));
    setIsMouseOverProximity(true);
  }, [dispatch, props.proximity.id]);

  const endDnDMode = useCallback(() => {
    dispatch(moveIndividualProximity(props.proximity.id, latestLatLng));
  }, [dispatch, props.proximity.id, latestLatLng]);

  const onMouseEnteredProximity = useCallback(() => {
    if (isInDnDMode) {
      dispatch(startIndividualRadiusHoverInDnD());
    }
    setIsMouseOverProximity(true);
  }, [isInDnDMode, dispatch]);
  const onMouseLeftProximity = useCallback(() => {
    if (isInDnDMode) {
      dispatch(endIndividualRadiusHoverInDnD());
    }
    setIsMouseOverProximity(false);
  }, [isInDnDMode, dispatch]);

  useMapObjectDragAndDrop(() => ({
    dragDisabled: !isInDnDMode,
    map: props.map,
    mapObject: renderedCircle ?? undefined,
    onDragMove: latLng => setLatestLatLng(sumLatLngs(latLng, dragStartToCenterVector)),
    onDragStart: latLng => {
      setDragStartToCenterVector(subtractLatLngs(latestLatLng, latLng));
      dispatch(startIndividualRadiusDrag());
    },
    onDragEnd: () => dispatch(endIndividualRadiusDrag()),
    onMouseOver: onMouseEnteredProximity,
    onMouseOut: onMouseLeftProximity,
  }), [isInDnDMode, props.map, renderedCircle, dispatch, latestLatLng, dragStartToCenterVector, onMouseEnteredProximity, onMouseLeftProximity]);

  // Stop / start dnd once redux property changes
  useEffect(() => {
    if (proximityIdInDnD === props.proximity.id && !isInDnDMode) {
      setIsInDnDMode(true);
    }

    if (proximityIdInDnD !== props.proximity.id && isInDnDMode) {
      endDnDMode();
      setIsInDnDMode(false);
    }
  }, [proximityIdInDnD, props.proximity.id, endDnDMode, isInDnDMode]);

  useEffect(() => {
    if (hideSmallRadiiZoom !== null && !isProximityBigEnough(props.proximity.data, hideSmallRadiiZoom)) {
      props.manager.removeProximityCircle(props.proximity.id);
    }
    else {
      const proximityWithUpdatedLatLng = changeIndividualProximityLatLng(proximityWithExtraStyles, latestLatLng);
      const rendered = props.manager.renderProximitySingleRadius(
        proximityWithUpdatedLatLng,
        props.isVisible,
        zIndex,
        hideLabel,
        isInDnDMode,
        isMouseOverCenter,
      );

      setRenderedCircle(rendered?.circle ?? null);
      setRenderedCenter(rendered?.centerMarker ?? null);
    }
  }, [props.manager, props.isVisible, props.proximity, zIndex, hideLabel, hideSmallRadiiZoom, latestLatLng, isInDnDMode, proximityWithExtraStyles, isMouseOverCenter]);

  useEffect(() => {
    if (isInDnDMode) {
      const clickCallback = () => !isMouseOverProximity && endDnDMode();
      document.addEventListener('click', clickCallback);

      return () => document.removeEventListener('click', clickCallback);
    }
    else {
      const doubleClickCallback = () => isMouseOverCenter && !isMapInteractionActive && startDnDMode();
      document.addEventListener('dblclick', doubleClickCallback);

      return () => document.removeEventListener('dblclick', doubleClickCallback);
    }
  }, [isInDnDMode, isMouseOverProximity, endDnDMode, isMouseOverCenter, startDnDMode, isMapInteractionActive]);

  // Unmount cleanup
  useEffect(() => {
    return () => {
      props.manager.removeProximityCircle(props.proximity.id);
    };
  }, [props.manager, props.proximity.id]);

  return null;
});

const combineLatLngs = (latLng1: LatLng, latLng2: LatLng, combinator: (coordinate1: number, coordinate2: number) => number) => ({
  lat: combinator(latLng1.lat, latLng2.lat),
  lng: combinator(latLng1.lng, latLng2.lng),
});

const sumLatLngs = (latLng1: LatLng, latLng2: LatLng) => combineLatLngs(latLng1, latLng2, (a, b) => a + b);

const subtractLatLngs = (latLng1: LatLng, latLng2: LatLng) => combineLatLngs(latLng1, latLng2, (a, b) => a - b);
