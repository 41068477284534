import {
  type DependencyList,
  useCallback,
  useEffect,
} from 'react';
import { throttle } from '../throttle/throttle';
import { type AnyFunction } from '../types/common.type';

/**
 * Throttles given function so it is called at most once in given number of milliseconds.
 * @param func any function that suffers from too frequent calls
 * @param waitMs a number of milliseconds in which the function will only be invoked once
 */
export const useThrottle = <TFunction extends AnyFunction>(func: TFunction, deps: DependencyList, waitMs: number): TFunction => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFunction = useCallback(throttle(func, waitMs), deps);

  useEffect(() => throttledFunction.cancel, [throttledFunction]);

  return throttledFunction as any as TFunction;
};
