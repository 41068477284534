import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { HeightTransitionComponent } from '../heightTransition/heightTransition.component';

const iconStyles = css({
  marginLeft: 4,
  marginRight: 10,
  fontWeight: 900,
});

const wrapperStyle = css({
  overflow: 'hidden',
  paddingTop: 2,
});

export const FORM_ERROR_MESSAGE_HEIGHT = 20;

const errorStyle = (isSingleLine: boolean) => (theme: Theme) => css({
  alignItems: 'center',
  color: theme.textColors.danger,
  cursor: 'default',
  display: 'flex',
  fontSize: 12,
  lineHeight: 1.2,
  marginTop: '4px',
  maxWidth: '100%',
  minHeight: FORM_ERROR_MESSAGE_HEIGHT,
  overflow: 'hidden',
  textOverflow: isSingleLine ? 'ellipsis' : undefined,
  whiteSpace: isSingleLine ? 'nowrap' : undefined,
  width: '100%',
});

type FormErrorMessageProps = Readonly<{
  messages: ReadonlyArray<string>;
  className?: string;
  hideIcon?: boolean;
  icon?: IconProp;
  isSingleLine?: boolean;
}>;

export const FormErrorMessageComponent: FC<FormErrorMessageProps> = props => {
  if (props.messages.length === 0) {
    return null;
  }

  return (
    <HeightTransitionComponent animateOnMount>
      <div css={wrapperStyle}>
        {props.messages.map((error, index) => (
          <div
            key={index + error}
            css={errorStyle(props.isSingleLine ?? true)}
            className={props.className}
          >
            {(!props.hideIcon && (
              <FontAwesomeIcon
                icon={props.icon ?? faExclamationCircle}
                css={iconStyles}
              />
            ))}
            {error}
          </div>
        ))}
      </div>
    </HeightTransitionComponent>
  );
};
