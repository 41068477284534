import { BoundaryCreateOptimizedStatusResponse } from '../../../../../map/map.repository';
import { BoundaryCreateOptimizedStatus } from './boundaryCreateOptimized.state';

export const createBoundaryCreateOptimizedStatusFromResponse = (
  responseStatus: BoundaryCreateOptimizedStatusResponse
): BoundaryCreateOptimizedStatus => {
  switch (responseStatus) {
    case BoundaryCreateOptimizedStatusResponse.RequestAccepted:
      return BoundaryCreateOptimizedStatus.ProcessingStarted;
    case BoundaryCreateOptimizedStatusResponse.ValidatingRequest:
      return BoundaryCreateOptimizedStatus.ValidatingRequest;
    case BoundaryCreateOptimizedStatusResponse.StartingTerritories:
      return BoundaryCreateOptimizedStatus.InitializingTerritories;
    case BoundaryCreateOptimizedStatusResponse.BalancingTerritoriesAttempt1:
      return BoundaryCreateOptimizedStatus.BalancingTerritories;
    case BoundaryCreateOptimizedStatusResponse.BalancingTerritoriesAttempt2:
      return BoundaryCreateOptimizedStatus.RebalancingTerritories;
    case BoundaryCreateOptimizedStatusResponse.BalancingTerritoriesAttempt3:
      return BoundaryCreateOptimizedStatus.FinalizingTheBalancingOfTerritories;
    case BoundaryCreateOptimizedStatusResponse.ProcessingDone:
      return BoundaryCreateOptimizedStatus.ProcessingFinished;
    case BoundaryCreateOptimizedStatusResponse.Finished:
      return BoundaryCreateOptimizedStatus.Finished;
    default:
      return BoundaryCreateOptimizedStatus.ProcessingStarted;
  }
};
