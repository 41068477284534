import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type ReactNode,
} from 'react';
import {
  SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD,
  SquareIconButtonComponent,
} from '~/_shared/baseComponents/buttons';
import {
  TooltipBehavior, type TooltipOffset, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';

const itemStyle = css({
  breakInside: 'avoid',
  margin: '2px 2px 9px 2px',
  padding: SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD + 1,
  position: 'relative',
  '&:last-of-type': {
    marginBottom: 0,
  },
});

const tooltipOffset: TooltipOffset = {
  right: 20,
};

const tooltipContentStyle = css({
  fontSize: '14px',
});

type RightSideMapToolsMenuItemProps = Readonly<{
  active: boolean;
  className?: string;
  icon: IconProp;
  isLoading?: boolean;
  tooltip: string;
  tooltipAlwaysShow?: boolean;
  UITestId?: string;

  onClick: () => void;
  renderOverlay?: (css: SerializedStyles) => ReactNode;
}>;

export const RightSideMapToolsMenuItem: FC<RightSideMapToolsMenuItemProps> = ({
  UITestId,
  active,
  className,
  icon,
  isLoading,
  onClick,
  renderOverlay,
  tooltip,
  tooltipAlwaysShow,
  ...restProps
}) => (
  <div
    css={itemStyle}
    data-testid={UITestId}
  >
    <SquareIconButtonComponent
      {...restProps}
      className={className}
      icon={icon}
      isActive={active}
      isDisabled={isLoading}
      onClick={onClick}
      renderOverlay={renderOverlay}
      tooltip={tooltip}
      tooltipBehavior={tooltipAlwaysShow ? TooltipBehavior.ShowAlways : undefined}
      tooltipContentStyle={tooltipContentStyle}
      tooltipOffset={tooltipOffset}
      tooltipPlacement={TooltipPlacement.Left}
      useDefaultHoverStyles
    />
  </div>
);
