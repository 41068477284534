import { useState } from 'react';
import { getApiErrorErrors } from '../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import {
  type MapUpdateRequest, updateMapData,
} from './map.repository';

type UpdateMapDataArguments = {
  clientId: number;
  mapId: number;
  request: MapUpdateRequest;
};

export const useMapDataUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMountedRef = useIsComponentMountedRef();

  const clearErrorMessage = () => {
    if (isMountedRef.current) {
      setErrorMessage('');
    }
  };

  const updateMapDataRequest = ({ clientId, mapId, request }: UpdateMapDataArguments) => {
    setIsLoading(true);
    setErrorMessage('');

    return updateMapData(clientId, mapId, request)
      .catch((e) => {

        if (isMountedRef.current) {
          setIsLoading(false);

          const apiErrors = getApiErrorErrors(e);

          setErrorMessage(apiErrors?.[0] ?? e.message);
        }

        throw Error(e);
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    errorMessage,
    updateMapData: updateMapDataRequest,
    clearErrorMessage,
  };
};
