import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useHeatmapItems } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { updateHeatmapToolStateData } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.actionCreators';
import {
  hideHeatMap, showHeatMap,
} from '~/store/mapSettings/toolsState/visibility/visibility.actionCreators';
import { useTranslation } from '../../../_shared/utils/hooks';
import { removeHeatMap } from '../../../store/mapSettings/heatmaps/mapSettingsHeatmaps.actionCreators';
import { useSpreadsheetColumns } from '../../../store/matchupData/matchupDataSelectors.hook';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import {
  createMapStatusBarItemForHeatMapMarkerFilter,
  createMapStatusBarItemFromHeatMap,
} from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useHeatmapStatusBarItems = () => {
  const mapId = useMapIdSelector();
  const columns = useSpreadsheetColumns();
  const heatMapItems = useHeatmapItems();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const heatMapBarItems = useMemo<MapStatusBarItem[]>(() => {
    const items: MapStatusBarItem[] = mapId ? heatMapItems.map((item) => {
      return createMapStatusBarItemFromHeatMap({
        item, columns, t,
        onRemove: () => dispatch(removeHeatMap(item.heatmap, mapId)),
        onVisibilityChange: (visibility: boolean) => dispatch(visibility ? showHeatMap(item.heatmap.id) : hideHeatMap(item.heatmap.id)),
        onClick: () => dispatch(openMapTool(MapTool.HeatMap)),
      });
    }) : [];

    return items;
  }, [mapId, heatMapItems, columns, t, dispatch]);

  const heatmapMarkerFilterItems = useMemo<MapStatusBarItem[]>(() => {
    const items: MapStatusBarItem[] = mapId ? heatMapItems.filter(i => i.otherOptions.showMarkersForGroup).map((item) => {
      return createMapStatusBarItemForHeatMapMarkerFilter({
        item, columns, t,
        onRemove: () => dispatch(updateHeatmapToolStateData(item.heatmap.id, { showMarkersForGroup: false })),
        onClick: () => dispatch(openMapTool(MapTool.HeatMap)),
      });
    }) : [];

    return items;
  }, [mapId, heatMapItems, columns, t, dispatch]);

  return {
    heatmapItems: heatMapBarItems,
    heatmapMarkerFilterItems,
  };
};
