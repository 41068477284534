import {
  useCallback, useState,
} from 'react';
import { Navigate } from 'react-router-dom';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useOnMount } from '~/_shared/hooks/useOnMount';
import { parseUrl } from '~/_shared/utils/url/url.helpers';
import { AppErrorPageWrapperComponent } from '~/app/pages/errorPage/wrapper/appErrorPageWrapper.component';
import { PageWithLoaderComponent } from '~/app/pages/pageWithLoader.component';
import { useAuthContext } from '~/authorization/authContext';
import { initializeUser } from '~/authorization/authContext/auth.utils';
import { getMapUrl } from '~/map/map.helpers';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { authenticateSalesforceUser } from './salesforce.repository';

enum SalesforceLoginErrorTypes {
  TokenMissing = 'TokenMissing',
  InvalidToken = 'InvalidToken',
}

export const SalesforceLoginPageContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setAuthTokens } = useAuthContext();
  const userData = useUserDataSelector();
  const [error, setError] = useState<SalesforceLoginErrorTypes | null>(null);

  const [params] = useState(() => {
    const fragments = parseUrl(window.location.href).fragmentParameters;
    const token = fragments?.token ?? null;
    const mapId = Number(fragments?.mapId);

    return { token, mapId: isNaN(mapId) ? null : mapId };
  });

  const onLoad = useCallback(async () => {
    if (!params.token) {
      setError(SalesforceLoginErrorTypes.TokenMissing);
      return;
    }

    try {
      const response = await authenticateSalesforceUser(params.token);

      if (response?.refresh && response?.access_token_expiration) {
        setAuthTokens(response.refresh, response.access_token_expiration);
        await initializeUser();
      }
      else {
        setError(SalesforceLoginErrorTypes.InvalidToken);
      }
    }
    catch (error) {
      console.error(error);
      setError(SalesforceLoginErrorTypes.InvalidToken);
    }

    setIsLoading(false);
  }, [params.token, setAuthTokens]);

  useOnMount(() => {
    onLoad();
  });

  if (error) {
    return (
      <AppErrorPageWrapperComponent>
        {error}
      </AppErrorPageWrapperComponent>
    );
  }

  return (
    <PageWithLoaderComponent isLoading={!userData.isSignedIn || isLoading}>
      <Navigate to={params.mapId ? getMapUrl(params.mapId) : HOME_ROUTE} />
    </PageWithLoaderComponent>
  );
};
