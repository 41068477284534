import {
  type DependencyList, useMemo,
} from 'react';
import { type CombinedRowId } from '../../_shared/types/spreadsheetData/spreadsheetRow';
import { type ProximityZIndex } from '../map/proximity/mapProximityManager';
import { proximitySubEntities } from './useIndividualOrDriveTimeProximityZIndex.hook';
import { useZIndexSeries } from './useZIndexSeries.hook';
import { ZIndexedEntity } from './zIndexRanges';

export type ProximityZIndexSubEntity = typeof proximitySubEntities[number];

const idMapper = (w: CombinedRowId) => w;

type Params = { rowIds: ReadonlyArray<CombinedRowId>; proximityId: string };
type GroupProximityZIndexes = ReadonlyMap<CombinedRowId, {rowId: CombinedRowId; zIndex: ProximityZIndex}>;

export const useGroupProximityZIndexes = (getParams: () => Params, deps: DependencyList): GroupProximityZIndexes => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { rowIds, proximityId } = useMemo(getParams, deps);

  const zIndexes = useZIndexSeries(() => ({
    orderedEntities: rowIds.map(rowId => `${proximityId}|${rowId}`),
    orderedSubEntities: proximitySubEntities,
    idMapper,
    zIndexedEntity: ZIndexedEntity.ProximityCircle,
  }), [proximityId, rowIds]).subEntityZIndexes;

  return useMemo(() => new Map<CombinedRowId, {rowId: CombinedRowId; zIndex: ProximityZIndex}>(
    rowIds.map((rowId, index) => [rowId, { rowId, zIndex: zIndexes[index] as ProximityZIndex }])
  ), [rowIds, zIndexes]);
};
