import {
  useEffect, useState,
} from 'react';

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState<number>(document.documentElement.clientHeight);

  useEffect(() => {
    let ticking = false;

    const onWindowResize = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setWindowHeight(document.documentElement.clientHeight);
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return windowHeight;
};
