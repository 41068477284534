import { useEffect } from 'react';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE } from '~/drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import {
  DRAWING_TEXT_AREA_MIN_HEIGHT,
  DRAWING_TEXT_AREA_MIN_WIDTH,
} from './drawingToolTextArea.constants';

export type DrawingTextAreaSize = {
  readonly width: number;
  readonly height: number;
  readonly fontSize: number;
};

export type DrawingTextAreaSizeWithText = DrawingTextAreaSize & {
  readonly text?: string;
};

export type DrawingTextAreaSizeProps = DrawingTextAreaSize & {
  readonly scalesWithMapZoom: boolean;
  readonly textAreaToBodyPercentage?: number;
  readonly strokeWidth: number;
  readonly borderRadius: number;
};

export const useDrawingToolTextAreaScalingToggle = (
  props: DrawingTextAreaSizeProps,
  onChange: (props: DrawingTextAreaSizeWithText) => void,
  etag: string | undefined,
) => {
  const prevScalesWithMapZoom = usePrevious(props.scalesWithMapZoom);
  const currentZoom = useMapComponentZoomSelector();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const prevEtag = usePrevious(etag);

  useEffect(() => {
    if (currentZoom === null || prevScalesWithMapZoom === undefined) {
      return;
    }

    if (props.scalesWithMapZoom === prevScalesWithMapZoom) {
      return;
    }

    if (prevEtag !== etag) {
      // item was reset to another version
      // do not recaclulate scaling, it was not toggled
      return;
    }

    const convertedFontSize = props.scalesWithMapZoom
      ? convertPxDistanceToDifferentZoom({
        distance: props.fontSize,
        originalZoom: currentZoom,
        newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS,
      })
      : Math.min(DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
        convertPxDistanceToDifferentZoom({
          distance: props.fontSize,
          originalZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS,
          newZoom: currentZoom,
        })
      );

    const fontSizeRatio = props.fontSize !== 0 ? convertedFontSize / props.fontSize : 0;
    const pixelRatio = props.scalesWithMapZoom ? sizePerPixelRatio : 1;

    const updatedSize = {
      width: Math.max(props.width * fontSizeRatio, DRAWING_TEXT_AREA_MIN_WIDTH * pixelRatio),
      height: Math.max(props.height * fontSizeRatio, DRAWING_TEXT_AREA_MIN_HEIGHT * pixelRatio),
      fontSize: convertedFontSize,
    };

    onChange(updatedSize);
  }, [prevScalesWithMapZoom, currentZoom, onChange, props.scalesWithMapZoom,
    props.fontSize, props.width, props.height, sizePerPixelRatio, etag, prevEtag]);
};
