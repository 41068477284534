import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';
import { useBoundarySelectIsLassoLoadingSelector } from '../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { useBoundaryConvertInProgressSelector } from '../../../store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.selectors';
import { OverMapLoaderComponent } from './overMapLoader.component';
import { useBoundaryLoading } from './useBoundaryLoading';

const isMapTopViewBlocked = (state: AppState): boolean =>
  state.frontendState.moveMarkers.isModalOpen
    || state.frontendState.mapTools.search.searchActive
    || state.frontendState.mapTools.boundary.boundaryDraw.isModalOpen
    || state.frontendState.mapTools.boundary.boundarySelect.isModalOpen
    || state.frontendState.mapTools.drawing.drawingTool.activeTool !== null
    || state.frontendState.mapTools.drawing.drawingEdit.selectedDrawing !== null;

export const useIsMapTopViewBlocked = () => useSelector(isMapTopViewBlocked);

export const OverMapLoaderContainer: React.FC = () => {
  const [t] = useTranslation();
  const position = useIsMapTopViewBlocked() ? 'bottom' : 'top';

  const loadingBoundaries = useBoundaryLoading();
  const convertBoundariesInProgress = useBoundaryConvertInProgressSelector();
  const isBoundarySelectLassoLoading = useBoundarySelectIsLassoLoadingSelector();

  // Register your loaders below. Only one should be displayed at a time.

  if (convertBoundariesInProgress) {
    return (
      <OverMapLoaderComponent
        position={position}
        titleText={t('Generating New Territories')}
        subTitleText={t('This may take a minute...')}
      />
    );
  }

  if (loadingBoundaries) {
    return (
      <OverMapLoaderComponent
        position={position}
        titleText={t('Loading Your Boundaries')}
        subTitleText={t('This may take a minute...')}
      />
    );
  }

  if (isBoundarySelectLassoLoading) {
    return (
      <OverMapLoaderComponent
        position={position}
        titleText={t('Loading Your Selection')}
        subTitleText={t('This may take a minute...')}
      />
    );
  }

  return null;
};
