import { computeOffset } from 'spherical-geometry-js';
import { type LatLng } from '~/_shared/types/latLng';

// If there are multiple locations in the latLngs at the same place
// this function will spread them out in circles around the original location
export const spreadStackedLatLngsIntoRings = (latLngs: ReadonlyArray<LatLng>, fixToDecimals?: number): ReadonlyArray<LatLng> => {
  const latLngsFixed = latLngs.map(({ lat, lng }) => ({ lat: Number(lat.toFixed(fixToDecimals)), lng: Number(lng.toFixed(fixToDecimals)) }));
  const hash = ({ lat, lng }: LatLng) => `${lat},${lng}`;

  const latLngStackedPosition = new Map<number, number>();
  const countMap = new Map<string, number>();
  let maxNumberOfStackedLatLngs = 0;

  latLngsFixed.forEach((latLng, i) => {
    const key = hash(latLng);
    const newValue = (countMap.get(key) || 0) + 1;
    if (newValue > maxNumberOfStackedLatLngs) {
      maxNumberOfStackedLatLngs = newValue;
    }
    countMap.set(key, newValue);
    latLngStackedPosition.set(i, newValue - 1);
  });

  const offsets: [number, number][] = [[0, 0]];
  let ringLevel = 1;
  let perCurrentRingLevel = 4;
  let positionInCurrentRingLevel = 1;
  for (let i = 1; i < maxNumberOfStackedLatLngs; i++) {
    if (positionInCurrentRingLevel > perCurrentRingLevel) {
      ringLevel++;
      perCurrentRingLevel *= 2;
      positionInCurrentRingLevel = 1;
    }
    const angle = positionInCurrentRingLevel * (360 / perCurrentRingLevel);
    offsets.push([ringLevel, angle]);
    positionInCurrentRingLevel++;
  }

  return latLngsFixed.map((latLng, i) => {
    const [ringLevel, angle] = offsets[latLngStackedPosition.get(i) || 0] || [0, 0];

    if (ringLevel === 0 && angle === 0) {
      return latLng;
    }
    else {
      const latLngWithOffset = computeOffset(latLng, ringLevel, angle);
      return {
        lat: latLngWithOffset.lat(),
        lng: latLngWithOffset.lng(),
      };
    }
  });
};
