import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileArrowDown, faLasso, faMagnifyingGlass, faMagnifyingGlassLocation, faQuestionCircle, faRuler,
} from '@fortawesome/pro-solid-svg-icons';
import {
  mapToolIconMap, mapToolTitleMap,
} from '~/sidebar/sidebarApps/mapTools/mapTools.helpers';
import { type Translations } from '~/translations/translations.type';
import { MapFeature } from './mapFeatures.types';
import { MapTool } from './mapTools.types';
import { type ToolOrFeatureWithDataExport } from './toolsAndFeaturesWithDataExport.types';

export const mapFeatureTitleMap: { [key in keyof typeof MapFeature]: keyof Translations} = {
  [MapFeature.DistanceCalculator]: 'Distance Calculator',
  [MapFeature.ExportData]: 'Export Data',
  [MapFeature.Lasso]: 'Lasso Tool',
  [MapFeature.LocationFinder]: 'Location Finder',
  [MapFeature.Search]: 'Search',
};

export const mapFeatureIconMap: { [key in keyof typeof MapFeature]: IconProp} = {
  [MapFeature.DistanceCalculator]: faRuler,
  [MapFeature.ExportData]: faFileArrowDown,
  [MapFeature.Lasso]: faLasso,
  [MapFeature.LocationFinder]: faMagnifyingGlassLocation,
  [MapFeature.Search]: faMagnifyingGlass,
};

export const dataPageMapTools = new Set([MapTool.FilterData]);

export const isMapTool = (key: MapTool | MapFeature): key is MapTool => key in MapTool;
export const isMapFeature = (key: MapTool | MapFeature): key is MapFeature => key in MapFeature;

export const getTranslationForMapToolOrFeature = (key: MapTool | MapFeature): keyof Translations => (
  isMapTool(key) ? mapToolTitleMap[key] : isMapFeature(key) ? mapFeatureTitleMap[key] : 'Unknown'
);

export const getIconForMapToolOrFeature = (key: MapTool | MapFeature): IconProp => (
  isMapTool(key) ? mapToolIconMap[key] : isMapFeature(key) ? mapFeatureIconMap[key] : faQuestionCircle
);

export const hasFeatureOrToolAllowedPresentationalMapExport = (
  key: ToolOrFeatureWithDataExport, allowedExportDataTools: ToolOrFeatureWithDataExport[],
): boolean => (
  allowedExportDataTools.findIndex(item => item === key) !== -1
);
