import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type CSSProperties, type FC,
} from 'react';
import {
  SliderComponent, type SliderMarks,
} from '../slider.component';
import { SliderWithInputValuesItemComponent } from './sliderWithInputValuesItem.component';

type SliderWithInputLabelProps = Readonly<{
  className?: string;
  dotStyle?: CSSProperties;
  included?: boolean;
  isDisabled?: boolean;
  marks?: SliderMarks;
  max: number;
  min: number;
  reverse?: boolean;
  step: number;
  value: [number, number];
  vertical?: boolean;
  withDots?: boolean;
  icon?: IconProp | null;
  onChange: (value: [number, number]) => void;
}>;

const valuesWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 5,
});

export const SliderWithInputValuesComponent: FC<SliderWithInputLabelProps> = (props) => {
  const { min, max } = props;
  const inputSize = Math.max(max.toString().length, min.toString().length);

  return (
    <SliderComponent {...props}>
      <div css={valuesWrapperStyle}>
        {props.value.map((value, index) => (
          <SliderWithInputValuesItemComponent
            inputSize={inputSize}
            min={index === 0 ? props.min : props.value[0]}
            max={index === 1 ? props.max : props.value[1]}
            key={index}
            value={value}
            onChange={(newValue) => {
              const newValues: [number, number] = [...props.value];
              newValues[index] = newValue;
              props.onChange(newValues);
            }}
            icon={props.icon}
          />
        ))}
      </div>
    </SliderComponent>
  );
};
