import { css } from '@emotion/react';
import {
  faCloud, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useEffect,
  useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { LottieAnimationComponent } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { getLottieAnimationDuration } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { createUuid } from '~/_shared/utils/createUuid';
import { delay } from '~/_shared/utils/delay';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';

export enum StatusIndicatorMode {
  None = 'none',
  Saving = 'saving',
  Error = 'error',
  Offline = 'offline',
  TestMode = 'test-mode',
  ExportImageMode = 'export-image-mode',
}

const Statuses = {
  None: 'none',
  Saving: 'saving',
  Error: 'error',
  Saved: 'saved',
  Offline: 'offline',
  TestMode: 'test-mode',
  ExportImageMode: 'export-image-mode',
} as const;
type Status = typeof Statuses[keyof typeof Statuses];

type StatusIndicatorComponentProps = Readonly<{
  mode: StatusIndicatorMode;
}>;

const successAnimationType = LottieAnimationTypes.Success;
const successAnimationProps = {
  segment: LottieAnimations[successAnimationType].segments.default,
  speed: 1,
  size: 30,
  autoplay: true,
} as const;

const successDelay = getLottieAnimationDuration(successAnimationType, successAnimationProps.segment) / successAnimationProps.speed;

const statusContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  textWrap: 'nowrap',
});

const iconContainerStyle = css({
  position: 'relative',
  marginRight: '4px',
});

const firstIconStyle = css({
  fontSize: '16px',
});

const secondIconStyle = css({
  position: 'absolute',
  top: '5px',
  left: '6px',
  fontSize: '8px',
});

const labelSuccessStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.success,
});

const labelDangerStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
});

const refreshPageButtonStyle = css({
  fontSize: '13px',
  marginLeft: '8px',
});

type ErrorStatus = 'error' | 'offline' | 'test-mode';
const ErrorStatus: FC<{ status: ErrorStatus; showRefresh: boolean }> = ({ status, showRefresh }) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const label =
    status === Statuses.TestMode ? 'Test Mode'
      : status === Statuses.Offline ? t('Offline')
        : t('Error');

  return (
    <div css={statusContainerStyle}>
      <div css={iconContainerStyle}>
        <FontAwesomeIcon
          icon={faCloud}
          color={theme.textColors.danger}
          css={firstIconStyle}
        />
        <FontAwesomeIcon
          icon={faXmark}
          color="#fff"
          css={secondIconStyle}
        />
      </div>
      <label css={labelDangerStyle({ theme })}>{label}</label>
      {showRefresh && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Danger}
          text={t('Refresh Page')}
          css={refreshPageButtonStyle}
          onClick={refreshPage}
        />
      )}
    </div>
  );
};

const SavingStatus: FC = () => {
  const [t] = useTranslation();

  return (
    <div css={statusContainerStyle}>
      <label>{t('Saving')}</label>
      <LoaderComponent size={26} />
      {/* <LottieAnimationComponent
        type={savingAnimationType}
        colors={{ fill: theme.contrasts.selectedAccent2 }}
        {...savingAnimationProps}
      /> */}
    </div>
  );
};

const ExortImageStatus: FC = () => {
  const theme = useTheme();

  return (
    <div css={statusContainerStyle}>
      <label>Export Image Mode</label>
      <LottieAnimationComponent
        type={LottieAnimationTypes.UploadingImage}
        segment={LottieAnimations.UploadingImage.segments.visor}
        size={30}
        autoplay
        loop
        bothDirections
        colors={{
          visor: theme.iconColors.contrast,
          watermark: theme.iconColors.contrast,
        }}
      />
    </div>
  );
};

const SavedStatus: FC = () => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={statusContainerStyle}>
      <LottieAnimationComponent
        type={successAnimationType}
        colors={{
          border: theme.contrasts.success,
          fill: theme.contrasts.success,
          tick: theme.contrasts.successBackground,
        }}
        {...successAnimationProps}
      />
      <label css={labelSuccessStyle({ theme })}>{t('Saved')}</label>
    </div>
  );
};

export const StatusIndicatorComponent: FC<StatusIndicatorComponentProps> = (props) => {
  const [status, setStatus] = useState<{value: Status; id: Uuid}>({ value: Statuses.None, id: createUuid() });

  const { mode } = props;
  const previousMode = usePrevious(mode);

  useEffect(() => {
    if (mode === previousMode) {
      return;
    }

    // Saving => Saved => delay => None
    if (mode === Statuses.None && status.value === Statuses.Saving) {
      const statusStateId = createUuid();
      setStatus({ value: Statuses.Saved, id: statusStateId });
      delay(successDelay).then(() =>
        setStatus(previousStatus => previousStatus.id === statusStateId ?
          { value: Statuses.None, id: createUuid() } : previousStatus
        ));
    }
    else {
      setStatus({ value: mode, id: createUuid() });
    }
  }, [mode, previousMode, status.value]);

  if (status.value === Statuses.Saving) {
    return (
      <SavingStatus />
    );
  }

  if (status.value === Statuses.Saved) {
    return (
      <SavedStatus />
    );
  }

  if (status.value === Statuses.ExportImageMode) {
    return (
      <ExortImageStatus />
    );
  }

  if (status.value !== Statuses.None) {
    return (
      <ErrorStatus
        status={status.value}
        showRefresh={status.value !== Statuses.TestMode}
      />
    );
  }

  return null;
};
