import { useMemo } from 'react';
import {
  getNumericalGroupBuckets,
  type NumericBucketWithRange,
} from '../../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '../../../store/spreadsheetData/spreadsheetData.selectors';
import {
  DataType, Unfiltered,
} from '../../../store/spreadsheetData/spreadsheetData.state';
import {
  type GroupingColumn, GroupingType,
} from '../../types/grouping/grouping';

export const useBucketsWithRange = (column?: GroupingColumn) => {
  const spreadsheetData = useSpreadsheetDataDataSelector();

  const groupBuckets: NumericBucketWithRange[] = useMemo(() => {
    if (column && column.type === GroupingType.Numeric && spreadsheetData) {
      const spreadsheetDataGroupValues = spreadsheetData.values[column.spreadsheetId]?.[Unfiltered]?.[column.columnId]?.[DataType.NUMBER];

      if (spreadsheetDataGroupValues) {
        return getNumericalGroupBuckets(
          column,
          spreadsheetDataGroupValues.extra.min,
          spreadsheetDataGroupValues.extra.max,
          true
        );
      }
    }

    return [];
  }, [column, spreadsheetData]);

  return groupBuckets.length > 0 ? groupBuckets : undefined;
};
