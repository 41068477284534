import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { FPS30 } from '~/_shared/utils/throttle/throttle';

export const useHandleSliderOption = (defaultValue: number, onModify: (newValue: number) => void, minValue: number, maxValue: number) => {
  const [inputValue, setInputValue] = useState(defaultValue + '');

  const onInputBlur = useCallback((value: string) => {
    const newNumber = Number(value);

    if (!isNaN(newNumber) && (newNumber >= minValue) && (newNumber <= maxValue)) {
      onModify(newNumber);
    }
    else {
      setInputValue(defaultValue + '');
    }
  }, [minValue, maxValue, onModify, defaultValue]);

  const onModifyRef = useRef(onModify);
  onModifyRef.current = onModify;

  const onSliderChange = useThrottle(([value]: [number]) => {
    onModifyRef.current(value);
    setInputValue(value + '');
  }, [setInputValue], FPS30);

  const onTextInputChange = useCallback((value: string) => {
    const almostNumber = value.replace('px', '');
    if (isNaN(Number(almostNumber))) {
      return;
    }

    setInputValue(almostNumber);
  }, []);

  useEffect(() => {
    setInputValue(defaultValue + '');
  }, [defaultValue]);

  useKeyPress({
    targetKeys: KeyboardKeys.Enter,
    options: { allowInputs: true, allowActionWhenModalOpen: true },
    callbacks: { onKeyPress: useCallback(() => onInputBlur(inputValue), [inputValue, onInputBlur]) },
  });

  return useMemo(() => ({
    inputValue,
    onInputBlur,
    onSliderChange,
    onTextInputChange,
  }), [inputValue, onInputBlur, onSliderChange, onTextInputChange]);
};
