import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';

const clientMembersListEtagSelector = (state: AppState) => state.frontendState.client.membersListEtag;
export const useClientMembersListTagSelector = () => useSelector(clientMembersListEtagSelector);

const clientInviteesListEtagSelector = (state: AppState) => state.frontendState.client.inviteesListEtag;
export const useClientInviteesListTagSelector = () => useSelector(clientInviteesListEtagSelector);

const clientSubscriptionEtagSelector = (state: AppState) => state.frontendState.client.subscriptionEtag;
export const useClientSubscriptionEtagSelector = () => useSelector(clientSubscriptionEtagSelector);

const clientSubscriptionLoadingSelector = (state: AppState) => state.frontendState.client.subscriptionLoading;
export const useClientSubscriptionLoadingSelector = () => useSelector(clientSubscriptionLoadingSelector);
