import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import {
  RANGE_DEFAULT_HIGH_VALUE_COLOR, RANGE_DEFAULT_LOW_VALUE_COLOR, RANGE_DEFAULT_MEDIUM_VALUE_COLOR,
} from '~/_shared/constants/rangeColors';
import { createBoundaryTerritoryStyleFromResponse } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { type BoundaryTerritoryStyleResponse } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupStyle,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { BoundaryFill } from './boundaryFill.type';

export const boundaryFillDefaultOpacity = .5;

export const boundaryTerritoryGroupCreateSettingsStyleDefaults: Readonly<BoundaryTerritoryStyleResponse> = {
  line_color: '#000',
  line_width: 1,
  gradient_colors: {
    low: {
      color: guaranteeHash(RANGE_DEFAULT_LOW_VALUE_COLOR.hex),
    },
    medium: {
      color: guaranteeHash(RANGE_DEFAULT_MEDIUM_VALUE_COLOR.hex),
    },
    high: {
      color: guaranteeHash(RANGE_DEFAULT_HIGH_VALUE_COLOR.hex),
    },
  },
};

export const boundaryTerritoryGroupSettingsStyleDefaults: BoundaryTerritoryGroupStyle
  = createBoundaryTerritoryStyleFromResponse(boundaryTerritoryGroupCreateSettingsStyleDefaults);

export const restoreBTGDefaultColors = (boundaryTerritoryGroup: BoundaryTerritoryGroup) => {
  return {
    ...boundaryTerritoryGroup,
    settings: {
      ...boundaryTerritoryGroup.settings,
      style: {
        ...boundaryTerritoryGroup.settings.style,
        lineColor: boundaryTerritoryGroupSettingsStyleDefaults.lineColor,
        gradientColors: boundaryTerritoryGroupSettingsStyleDefaults.gradientColors,
      },
    },
  };
};

export const blankBoundaryStyle = {
  color: '#FFFFFF',
  opacity: 0,
  lineColor: '#000000',
  lineWidth: 1,
} as const;

export const defaultBoundaryTerritoryGroupResponseSettings = {
  allow_decimal_ranges: true,
  boundary_fill_type: BoundaryFill.DefaultFill,
  boundary_style: [],
} as const;

export const defaultBoundaryTerritoryGroupSettings = {
  allowDecimalRanges: false,
  boundariesAsTerritories: false,
  boundaryFillType: BoundaryFill.DefaultFill,
  boundaryStyle: new Map(),
  boundaryTerritories: [],
  displayName: null,
  emptyIsZero: null,
  excludeFilters: true,
  filteredBoundaries: [],
  filteredBoundaryTerritories: [],
  isHidden: false,
  metrics: [],
  rangesCount: null,
  hideLabels: false,
  hideWithoutData: false,
  ignoreFilters: false,
};
