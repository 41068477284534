import { combineReducers } from 'redux';
import { type PresentationalColumnsRestrictionsState } from '~/store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.state';
import { geocodingReducer } from '../geocoding/geocoding.reducer';
import { type GeocodingState } from '../geocoding/geocoding.state';
import { MAP_RESET } from '../map/map.actionTypes';
import { matchupDataReducer } from '../matchupData/matchupData.reducer';
import { type MatchupDataState } from '../matchupData/matchupData.state';
import { presentationalColumnsRestrictionsReducer } from '../presentationalColumnsRestrictions/presentationalColumnsRestrictions.reducer';
import { spreadsheetDataReducer } from '../spreadsheetData/spreadsheetData.reducer';
import { type SpreadsheetDataState } from '../spreadsheetData/spreadsheetData.state';

export type SpreadsheetState = Readonly<{
  spreadsheetData: SpreadsheetDataState;
  matchupData: MatchupDataState;
  geocoding: GeocodingState;
  presentationalColumnsRestrictions: PresentationalColumnsRestrictionsState;
}>;

const regularReducer = combineReducers({
  spreadsheetData: spreadsheetDataReducer,
  matchupData: matchupDataReducer,
  geocoding: geocodingReducer,
  presentationalColumnsRestrictions: presentationalColumnsRestrictionsReducer,
}) satisfies (...args: unknown[]) => SpreadsheetState;

export const spreadsheetReducer = (state: ReturnType<typeof regularReducer> | undefined, action: Action<typeof regularReducer>) => {
  if (action.type === MAP_RESET) {
    state = undefined;
  }

  return regularReducer(state, action);
};
