import { ClientLicenseCode } from '../../_shared/types/client/license';
import {
  type CurrentClient,
  type CurrentClientResponse,
} from './repository/userData.repository';

export const createCurrentClientFromResponse = (response: CurrentClientResponse): CurrentClient => {
  const startDate = new Date(response.license.start);
  const endDate = new Date(response.license.end);
  const lastStateChange = new Date(response.license.last_state_change);

  return ({
    id: response.id,
    license: {
      autoRenewal: response.license.auto_renewal,
      autopay: response.license.autopay,
      start: !isNaN(startDate.getTime()) ? startDate : null,
      end: !isNaN(endDate.getTime()) ? endDate : null,
      paymentType: response.license.payment_type,
      lastStateChange: !isNaN(lastStateChange.getTime()) ? startDate : null,
      code: response.license.code || ClientLicenseCode.TRIAL,
      state: response.license.state,
      type: response.license.type,
      name: response.license.name || 'Trial',
    },
    migrationDone: !!response.migration_done,
  });
};
