import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type ReactNode } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

type ModalSectionProps = Readonly<{
  title: string;
  titleIcon?: IconProp;
  actions?: ReactNode;
  children: ReactNode;
}>;

const headerHeight = 56;

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

const headerStyle = ({ theme }: ThemeProps & { isFirst?: boolean }) => css({
  display: 'flex',
  height: headerHeight,
  width: '100%',
  lineHeight: `${headerHeight}px`,
  backgroundColor: theme.backgroundColors.secondary,
  color: theme.textColors.primary,
  justifyContent: 'space-between',
  padding: '0 15px',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const contentStyle = ({ theme }: ThemeProps) => css({
  width: '100%',
  backgroundColor: theme.backgroundColors.primary,
  padding: 15,
  paddingRight: 20,
  boxSizing: 'border-box',
});

const leftHeaderStyle = css({
  fontSize: 20,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});
const rightHeaderStyle = css({
  fontSize: 16,
  flexShrink: 0,
});
const iconsStyle = css({
  width: 40,
  height: 40,
  paddingRight: 15,
  verticalAlign: 'middle',
});

export const ModalSectionComponent: React.FC<ModalSectionProps> = props => {
  const theme = useTheme();

  return (
    <div css={containerStyle}>
      <div css={headerStyle({ theme })}>
        <div css={leftHeaderStyle}>
          {props.titleIcon && (
            <FontAwesomeIcon
              css={iconsStyle}
              icon={props.titleIcon}
            />
          )}
          {props.title}
        </div>
        <div css={rightHeaderStyle}>
          {props.actions}
        </div>
      </div>
      <div css={contentStyle({ theme })}>
        {props.children}
      </div>
    </div>
  );
};
