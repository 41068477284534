import {
  type FC, useMemo,
} from 'react';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { useTranslation } from '../../utils/hooks';

export type SpreadsheetsSelectDropdownProps = Readonly<{
  spreadsheetIds: number[];
  currentSelectedSpreadsheetId: number;
  onSelectedSpreadsheetIdChange: (spreadsheetId: number) => void;
}>;

export const SpreadsheetsSelectDropdownComponent: FC<SpreadsheetsSelectDropdownProps> = (props) => {
  const [t] = useTranslation();

  const dropdownOptions: Array<DropdownOption<number>> = useMemo(() => {
    return props.spreadsheetIds.map((spreadsheetId, index) => ({
      value: spreadsheetId,
      name: t('Spreadsheet {{suffix}}', { suffix: index + 1 }),
    }));
  }, [props.spreadsheetIds, t]);

  return (
    <RegularDropdownComponent
      options={dropdownOptions}
      value={props.currentSelectedSpreadsheetId}
      onChange={props.onSelectedSpreadsheetIdChange}
      placeholder={t('Select Spreadsheet')}
    />
  );
};
