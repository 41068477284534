import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import {
  type BoundaryGroupId,
  type BoundaryId,
  type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';

export type FilteredBoundaryItems = {
  filteredBoundaries: ReadonlyArray<BoundaryId>;
  filteredBoundaryTerritories: ReadonlyArray<BoundaryTerritoryId>;
};

export type BoundaryFilter = FilteredBoundaryItems & {
  showAll: TriStateRange;
};

export type BoundaryLocationsFilter = FilteredBoundaryItems & {
  isolate?: boolean;
};

// For the filter in map settings to be working on created snapshot, it cannot be indexed by BTG ids.
// BTGs are cloned when creating snapshots, and they get new ids. Map settings are copied as well.
// Instead it's indexed per Boundary group id, that is referenced from BTG and is not changing between maps.
export type BoundaryFiltersStateGeneric<T extends FilteredBoundaryItems> = Readonly<Record<BoundaryGroupId, T | undefined>>;
export type BoundaryFiltersState = BoundaryFiltersStateGeneric<BoundaryFilter>;
export type BoundaryLocationsFiltersState = BoundaryFiltersStateGeneric<BoundaryLocationsFilter>;

export type MapSettingsToolsStateBoundaryState = {
  filters: BoundaryFiltersState;
  locationsFilter: BoundaryLocationsFiltersState;
};

export const isBoundaryGroupHidden = (filter: BoundaryFilter) =>
  filter.showAll === TriStateRange.None;
