import { css } from '@emotion/react';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { homePageZIndexes } from '~/homepage/homepage.constants';

export const UNDERBAR_HEADER_HEIGHT = 54;
export const TOP_BAR_HEADER_HEIGHT = 48;

export const containerStyle = ({ theme }: ThemeProps) => css({
  zIndex: homePageZIndexes.topBar,
  height: TOP_BAR_HEADER_HEIGHT,
  position: 'relative',
  display: 'flex',
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  maxWidth: '100%',
});

export const primarySectionStyle = css({
  display: 'flex',
  paddingLeft: '2%',
});

export const rightSectionStyle = css({
  justifyContent: 'flex-end',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '2%',
});

export const headingSectionStyle = css({
  flexGrow: 1,
  overflow: 'hidden',
  height: 'inherit',
});

export const rightIconStyle = css({
  fontsize: '20px',
  padding: '0 10px',
});

export const underbarStyle = ({ theme, isVisible }: ThemeProps<{isVisible: boolean}>) => css({
  borderBottom: isVisible ? `1px solid ${theme.borderColors.primary}` : 'none',
  position: 'relative',
  width: '100%',
});
