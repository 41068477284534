import { combineReducers } from 'redux';
import { type TerritoryGroup } from '../../_shared/types/territories';
import { copy } from '../../_shared/utils/collections/collections';
import { type PolygonGroupsActions } from './polygonGroups.actions';
import {
  POLYGON_GROUP_ACTIVATE_REQUEST,
  POLYGON_GROUP_CREATE_SUCCESS,
  POLYGON_GROUP_DEACTIVATE_REQUEST,
  POLYGON_GROUP_DELETE_REQUEST,
  POLYGON_GROUP_DELETE_SUCCESS,
  POLYGON_GROUP_FETCH_ACTIVE_ERROR,
  POLYGON_GROUP_FETCH_ACTIVE_REQUEST,
  POLYGON_GROUP_FETCH_ACTIVE_SUCCESS,
  POLYGON_GROUP_FETCH_ALL_ERROR,
  POLYGON_GROUP_FETCH_ALL_REQUEST,
  POLYGON_GROUP_FETCH_ALL_SUCCESS,
} from './polygonGroups.actionTypes';
import { type PolygonGroupsState } from './polygonGroups.state';

const groups = (state: ReadonlyMap<number, TerritoryGroup> = new Map(), action: PolygonGroupsActions): ReadonlyMap<number, TerritoryGroup> => {
  switch (action.type) {
    case POLYGON_GROUP_FETCH_ALL_SUCCESS: {
      return action.payload.polygonGroups;
    }

    case POLYGON_GROUP_CREATE_SUCCESS: {
      const group = action.payload.polygonGroup;
      return copy.andAdd(state, [[group.id, group]]);
    }

    case POLYGON_GROUP_DELETE_SUCCESS: {
      return copy.andRemove(state, [action.payload.polygonGroupId]);
    }

    default:
      return state;
  }
};

const isLoading = (state: boolean = true, action: PolygonGroupsActions): boolean => {
  switch (action.type) {
    case POLYGON_GROUP_FETCH_ACTIVE_REQUEST:
    case POLYGON_GROUP_FETCH_ALL_REQUEST: {
      return true;
    }

    case POLYGON_GROUP_FETCH_ACTIVE_ERROR:
    case POLYGON_GROUP_FETCH_ACTIVE_SUCCESS:
    case POLYGON_GROUP_FETCH_ALL_ERROR:
    case POLYGON_GROUP_FETCH_ALL_SUCCESS: {
      return false;
    }

    default:
      return state;
  }
};

const activeGroupIds = (state: ReadonlySet<number> = new Set(), action: PolygonGroupsActions): ReadonlySet<number> => {
  switch (action.type) {
    case POLYGON_GROUP_FETCH_ACTIVE_SUCCESS: {
      return action.payload.activePolygonGroupIds;
    }

    case POLYGON_GROUP_CREATE_SUCCESS: {
      return copy.andAdd(state, [action.payload.polygonGroup.id]);
    }

    case POLYGON_GROUP_ACTIVATE_REQUEST: {
      return copy.andAdd(state, [action.payload.polygonGroupId]);

    }

    case POLYGON_GROUP_DELETE_REQUEST:
    case POLYGON_GROUP_DEACTIVATE_REQUEST: {
      return copy.andRemove(state, [action.payload.polygonGroupId]);
    }

    default:
      return state;
  }
};

export const polygonGroupsReducer = combineReducers({
  activeGroupIds,
  groups,
  isLoading,
}) satisfies (...args: unknown[]) => PolygonGroupsState;
