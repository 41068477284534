import { FilterAction } from '../../../../../../_shared/types/filter/filter';
import { TextFilterPredicate } from './textFilterPredicate.enum';

export const createTextPredicateFromFilterAction = (action: FilterAction): TextFilterPredicate => {
  switch (action) {
    case FilterAction.Contains:
      return TextFilterPredicate.Contains;
    case FilterAction.NotContains:
      return TextFilterPredicate.NotContains;
    case FilterAction.Equal:
      return TextFilterPredicate.Equals;
    case FilterAction.NotEqual:
      return TextFilterPredicate.NotEquals;
    case FilterAction.BeginsWith:
      return TextFilterPredicate.StartsWith;
    case FilterAction.NotBeginWith:
      return TextFilterPredicate.NotStartsWith;
    case FilterAction.EndsWith:
      return TextFilterPredicate.EndsWith;
    case FilterAction.NotEndsWith:
      return TextFilterPredicate.NotEndsWith;
    case FilterAction.Null:
      return TextFilterPredicate.IsEmpty;
    case FilterAction.NotNull:
      return TextFilterPredicate.IsNotEmpty;
    case FilterAction.ArrayContains:
      return TextFilterPredicate.IsIn;
    case FilterAction.ArrayNotContains:
      return TextFilterPredicate.IsNotIn;
    default:
      throw Error('Filter action not recognized');
  }
};
