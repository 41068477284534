import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import { type BoundaryTerritoryGroupsAction } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import {
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS, BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { type MapSettingsToolsStateBoundaryStateAction } from './mapSettingsToolsStateBoundary.action';
import {
  MAP_SETTINGS_TOOLS_STATE_BOUNDARY_CLEAR_LOCATIONS_FILTER,
  MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_FILTER, MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER,
} from './mapSettingsToolsStateBoundary.actionTypes';
import { type MapSettingsToolsStateBoundaryState } from './mapSettingsToolsStateBoundary.state';

export const mapSettingsToolsStateBoundaryInitialState: MapSettingsToolsStateBoundaryState = {
  filters: {},
  locationsFilter: {},
};

type Action = MapSettingsToolsStateBoundaryStateAction | BoundaryTerritoryGroupsAction;

export const mapSettingsToolsStateBoundaryReducer = (state = mapSettingsToolsStateBoundaryInitialState, action: Action): MapSettingsToolsStateBoundaryState => {
  switch (action.type) {
    case MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.boundaryGroupId]: action.payload.filter,
        },
      };

    case MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER:
      return {
        ...state,
        locationsFilter: {
          [action.payload.boundaryGroupId]: action.payload.filter,
        },
      };
    case MAP_SETTINGS_TOOLS_STATE_BOUNDARY_CLEAR_LOCATIONS_FILTER:
      return {
        ...state,
        locationsFilter: {},
      };

    case BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS:
      return copyAndDeleteFilter(state, action.payload.boundaryTerritoryGroup.boundaryGroupId);

    case BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS:
      return copyAndDeleteFilter(state, action.payload.boundaryTerritoryGroup.boundaryGroupId);

    default:
      return state;
  }
};

const copyAndDeleteFilter = (state: MapSettingsToolsStateBoundaryState, boundaryGroupId: BoundaryGroupId) => {
  const filters = { ...state.filters };
  delete filters[boundaryGroupId];
  const locationsFilter = { ...state.locationsFilter };
  delete locationsFilter[boundaryGroupId];

  return {
    ...state,
    filters,
    locationsFilter,
  };
};
