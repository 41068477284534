import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  clickSpreadsheetMarker, hoverSpreadsheetMarker, stopSpreadsheetMarkerHover,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { showMarkerContextMenu } from '~/store/frontendState/markersContextMenu/markersContextMenu.actionCreators';
import {
  type MapMarkerEventCallback, type MarkerEventCallbacks,
} from './markerEventCallbacks.type';

export const useMarkerEventCallbacks = (): MarkerEventCallbacks => {
  const dispatch = useDispatch();

  const onClick: MapMarkerEventCallback = useCallback((rowIds: SpreadsheetRowId[]) => {
    dispatch(clickSpreadsheetMarker({ rowIds }));
  }, [dispatch]);

  const onRightClick: MapMarkerEventCallback = useCallback((_rowIds, _isStacked, getMapObjects) => {
    const mapObjects = getMapObjects();

    if (mapObjects?.marker?.entity === 'marker') {
      const marker = mapObjects.marker.object;
      dispatch(showMarkerContextMenu({
        latLng: {
          lat: marker.lat,
          lng: marker.lng,
        },
        offset: {
          x: marker.offset.x,
          y: marker.offset.y,
        },
      }));
    }

    if (mapObjects?.marker?.entity === 'label' && mapObjects.marker.object.label) {
      const label = mapObjects.marker.object.label;
      dispatch(showMarkerContextMenu({
        latLng: {
          lat: label.lat,
          lng: label.lng,
        },
        offset: {
          x: label.offset.x,
          y: label.offset.y,
        },
      }));
    }
  }, [dispatch]);

  //TODO: this 2 below will need to be reworked for stacked markers, so it's fired only once
  const onMouseOver: MapMarkerEventCallback = useCallback((rowIds: SpreadsheetRowId[]) => {
    dispatch(hoverSpreadsheetMarker({ rowIds }));
  }, [dispatch]);

  const onMouseOut: MapMarkerEventCallback = useCallback(() => {
    dispatch(stopSpreadsheetMarkerHover());
  }, [dispatch]);

  return useMemo(() => ({
    onClick,
    onRightClick,
    onMouseOver,
    onMouseOut,
  }), [onClick, onRightClick, onMouseOver, onMouseOut]);
};
