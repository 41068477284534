import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { createPolygonDrawingSettingsFromToolOptions } from '~/drawingTool/factories/drawingToolPolygonSettings.factory';
import { createRectangleDrawingSettingsFromToolOptions } from '~/drawingTool/factories/drawingToolRectangleSettings.factory';
import { drawingEditPushNewSnapshot } from '../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { DrawingTool } from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';
import { createArrowDrawingSettingsFromToolOptions } from '../factories/drawingToolArrowSettings.factory';
import { createCalloutDrawingSettingsFromToolOptions } from '../factories/drawingToolCalloutSettings.factory';
import { createCircleDrawingSettingsFromToolOptions } from '../factories/drawingToolCircleSettings.factory';
import { createIconMarkerDrawingSettingsFromToolOptions } from '../factories/drawingToolIconMarkerSettings.factory';
import { createImageDrawingSettingsFromToolOptions } from '../factories/drawingToolImageSettings.factory';
import { createLabelDrawingSettingsFromToolOptions } from '../factories/drawingToolLabelSettings.factory';
import { createNumberDrawingSettingsFromToolOptions } from '../factories/drawingToolNumberSettings.factory';
import { createPolylineDrawingSettingsFromToolOptions } from '../factories/drawingToolPolylineSettings.factory';
import { createTextDrawingSettingsFromToolOptions } from '../factories/drawingToolTextSettings.factory';
import { useDrawingToolSizePerPixelRatio } from './useDrawingToolSizePerPixelRatio';
import { useSelectedDrawingItemWithOptions } from './useSelectedDrawingItemWithOptions';

export const useSelectedDrawingOptionsChange = () => {
  const selectedDrawingWithOptions = useSelectedDrawingItemWithOptions();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();
  const dispatch = useDispatch();

  const getUpdatedTextBoxDimensions = useCallback((dimensions: QuadrilateralDimensions, currentFontSize: number, newOptions: Partial<DrawingToolOptions>) => {
    const newFontSize = newOptions.scaledSliderOptions?.fontSizeOnCurrentMapZoom?.value
      ?? newOptions.sliderOptions?.fontSize?.value;

    if (newFontSize === undefined) {
      return dimensions;
    }

    const pixelRatio = newOptions.scaledSliderOptions?.fontSizeOnCurrentMapZoom !== undefined ? sizePerPixelRatio : 1;
    const fontSizeChangeRatio = Math.max(newFontSize, pixelRatio) / Math.max(currentFontSize, pixelRatio);

    return {
      width: Math.max(dimensions.width * fontSizeChangeRatio, pixelRatio),
      height: Math.max(dimensions.height * fontSizeChangeRatio, pixelRatio),
    };
  }, [sizePerPixelRatio]);

  const updateSelectedDrawingOptions = useCallback((newOptions: Partial<DrawingToolOptions>) => {
    if (!selectedDrawingWithOptions) {
      return;
    }

    switch (selectedDrawingWithOptions.item.type) {
      case DrawingTool.Arrow: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Arrow,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createArrowDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      } case DrawingTool.Callout: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Callout,
          value: {
            ...selectedDrawingWithOptions.item.value,
            textBoxDimensions: getUpdatedTextBoxDimensions(
              selectedDrawingWithOptions.item.value.textBoxDimensions,
              selectedDrawingWithOptions.item.value.settings.fontSize,
              newOptions
            ),
            settings: createCalloutDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Circle: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Circle,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createCircleDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.IconMarker: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.IconMarker,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createIconMarkerDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Image: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Image,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createImageDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Label: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Label,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createLabelDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Number: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Number,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createNumberDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Polygon: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Polygon,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createPolygonDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Polyline: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Polyline,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createPolylineDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Rectangle: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Rectangle,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createRectangleDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      case DrawingTool.Text: {
        dispatch(drawingEditPushNewSnapshot({
          type: DrawingTool.Text,
          value: {
            ...selectedDrawingWithOptions.item.value,
            settings: createTextDrawingSettingsFromToolOptions(newOptions, selectedDrawingWithOptions.item.value.settings),
          },
        }));
        break;
      }
      default: {
        return;
      }
    }
  }, [dispatch, getUpdatedTextBoxDimensions, selectedDrawingWithOptions]);

  return {
    updateSelectedDrawingOptions,
  };
};
