import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  UniqueIdentifierType, useUniqueIdentifierSelectorManager,
} from '~/_shared/components/uniqueIdentifierSelector/useUniqueIdentifierSelectorManager.hook';
import { guessUniqueAddressColumns } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueAddressColumns';
import { isColumnCombinationUnique } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueColumns.helpers';
import { guessUniqueIdColumn } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueIdColumn';
import {
  notNull, notNullsy,
} from '~/_shared/utils/typeGuards';
import { type DataSource } from './dataSource/dataSource';

export type UniqueIdentifierData = {
  addressColumnIndexes: (number | null)[];
  checkForUniqueDoneForCurrentColumns: boolean;
  idColumnIndexes: (number | null)[];
  isCheckingIdentifier: boolean;
  isIdentifierValid: boolean;
  uIdType: UniqueIdentifierType | null;
  uniqueIdentifierIndexes: (number)[] | null;

  checkIdentifier: () => void;
  removeAddressIdentifierColumnByIndex: (index: number) => void;
  setAddressColumnIndexes: (newValue: (number | null)[]) => void;
  setIdColumnIndexes: (newValue: (number | null)[]) => void;
  setUIdType: (uIdType: UniqueIdentifierType | null) => void;
};

export const useManageUniqueIdentifierSelect = (dataSource: DataSource, initialIndexes?: Readonly<number[]>) => {
  const {
    uIdType,
    setUIdType,
    idColumnIndexes,
    setIdColumnIndexes,
    addressColumnIndexes,
    setAddressColumnIndexes,
    removeAddressIdentifierColumnByIndex,
  } = useUniqueIdentifierSelectorManager(initialIndexes);

  const [checkForUniqueDoneForCurrentColumns, setCheckForUniqueDoneForCurrentColumns] = useState(false);
  const [isCheckingIdentifier, setIsCheckingIdentifier] = useState(false);
  const [isIdentifierValid, setIsIdentifierValid] = useState(false);
  const [missingColumnIndexes, setMissingColumnIndexes] = useState<string[]>([]);

  const relevantColumnIndexes = useMemo(() => {
    let newRelevantColumnIndexes: (number| null)[] = [];

    if (uIdType === UniqueIdentifierType.AddressColumns) {
      newRelevantColumnIndexes = addressColumnIndexes;
    }
    else if (uIdType === UniqueIdentifierType.IdColumn && idColumnIndexes.length) {
      newRelevantColumnIndexes = idColumnIndexes;
    }

    return newRelevantColumnIndexes;
  }, [addressColumnIndexes, idColumnIndexes, uIdType]);

  const validateIdentifierData = useCallback((identifierData: string[][]) => {
    const identifierIndexes = identifierData.map((_, i) => i);
    const isValid = isColumnCombinationUnique(identifierIndexes, identifierData);
    setIsIdentifierValid(isValid);
    setIsCheckingIdentifier(false);
    setCheckForUniqueDoneForCurrentColumns(true);
  }, []);

  const checkIdentifier = useCallback(() => {
    setIsCheckingIdentifier(true);

    const columnIds = relevantColumnIndexes.map(columnIndex => (
      columnIndex !== null && dataSource.header?.[columnIndex] ? dataSource.header?.[columnIndex]?.id : null
    )).filter(notNullsy);

    const newMissingColumnIndexes = columnIds.map(columnId => (
      !dataSource.data?.[columnId] ? columnId : null
    )).filter(notNullsy);

    if (newMissingColumnIndexes.length) {
      setMissingColumnIndexes(newMissingColumnIndexes);
    }
    else {
      const identifierData: string[][] = columnIds.map(
        columnId => columnId ? dataSource.data?.[columnId] : null
      ).filter(notNullsy);
      if (identifierData.length === relevantColumnIndexes.length) {
        validateIdentifierData(identifierData);
      }
    }
  }, [dataSource.data, dataSource.header, relevantColumnIndexes, validateIdentifierData]);

  useEffect(() => {
    setIsIdentifierValid(false);
    setCheckForUniqueDoneForCurrentColumns(false);
  }, [uIdType, addressColumnIndexes, idColumnIndexes]);

  useEffect(() => {
    const dataSourceFetch = dataSource.fetch;
    if (dataSourceFetch && missingColumnIndexes.length) {
      dataSourceFetch(missingColumnIndexes);
    }
  }, [dataSource.fetch, missingColumnIndexes]);

  useEffect(() => {
    if (isCheckingIdentifier) {
      if (dataSource.data && dataSource.header) {
        const columnIds = relevantColumnIndexes.map(columnIndex => (
          columnIndex !== null && dataSource.header?.[columnIndex] ? dataSource.header?.[columnIndex]?.id : null
        )).filter(notNullsy);
        const identifierData: string[][] = columnIds.map(columnId => columnId ? dataSource.data?.[columnId] : null).filter(notNullsy);
        if (identifierData.length === relevantColumnIndexes.length) {
          validateIdentifierData(identifierData);
          return;
        }
      }
      if (dataSource.error) {
        setIsIdentifierValid(false);
        setIsCheckingIdentifier(false);
        setCheckForUniqueDoneForCurrentColumns(true);
      }
    }
  }, [dataSource.data, dataSource.error, dataSource.header, isCheckingIdentifier, relevantColumnIndexes, validateIdentifierData]);

  useEffect(() => {
    // when we select a Unique Key type (Id or address), we will attempt to guess the correct combination
    if (uIdType && dataSource.data && dataSource.header) {
      if (idColumnIndexes.length === 1 && idColumnIndexes[0] === null && uIdType === UniqueIdentifierType.IdColumn) {
        const newUniqueColumnIndex = guessUniqueIdColumn(dataSource.header, dataSource.data);
        if (newUniqueColumnIndex !== null && newUniqueColumnIndex !== undefined) {
          setIdColumnIndexes([newUniqueColumnIndex]);
        }
      }

      if (addressColumnIndexes.length === 1 && addressColumnIndexes[0] === null && uIdType === UniqueIdentifierType.AddressColumns) {
        const newAddressColumnIndexes = guessUniqueAddressColumns(dataSource.header, dataSource.data);
        if (!newAddressColumnIndexes?.length) {
          return;
        }
        setAddressColumnIndexes(newAddressColumnIndexes || [null]);
      }
    }
  }, [addressColumnIndexes, idColumnIndexes, dataSource.data, dataSource.header,
    uIdType, setAddressColumnIndexes, setIdColumnIndexes,
  ]);

  const uniqueIdentifierIndexes = useMemo(() => {
    if (isIdentifierValid) {
      if (uIdType === UniqueIdentifierType.AddressColumns) {
        return addressColumnIndexes.filter(notNull);
      }
      else if (uIdType === UniqueIdentifierType.IdColumn) {
        return idColumnIndexes.filter(notNull);
      }
    }
    return null;
  }, [addressColumnIndexes, idColumnIndexes, isIdentifierValid, uIdType]);

  return useMemo((): UniqueIdentifierData => ({
    uIdType, setUIdType,
    checkForUniqueDoneForCurrentColumns,
    isCheckingIdentifier, checkIdentifier,
    isIdentifierValid,
    idColumnIndexes, setIdColumnIndexes,
    addressColumnIndexes, setAddressColumnIndexes,
    removeAddressIdentifierColumnByIndex,
    uniqueIdentifierIndexes,
  }), [addressColumnIndexes, checkForUniqueDoneForCurrentColumns, checkIdentifier,
    idColumnIndexes, isCheckingIdentifier, isIdentifierValid, removeAddressIdentifierColumnByIndex,
    setAddressColumnIndexes, setIdColumnIndexes, setUIdType, uIdType, uniqueIdentifierIndexes,
  ]);
};
