import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode,
} from 'react';
import {
  type FaIcon, FontAwesomeIcon,
} from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LogoComponent } from '~/_shared/components/logo/logo.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { AppPageStylesWrapper } from '../../appPageStylesWrapper.component';

type AppErrorPageWrapperProps = Readonly<{
  className?: string;
  children?: ReactNode;
  icon?: FaIcon | null;
}>;

const containerStyle = (theme: Theme) => css({
  display: 'flex',
  height: '100%',
  width: '100%',
  minHeight: '100px',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
  color: theme.textColors.danger,
  textAlign: 'center',
  flexWrap: 'wrap',
});

const logoWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const iconWrapperStyle = css({
  margin: '50px 0 10px',
});

const iconStyle = css({
  fontSize: '55px',
});

const contentWrapperStyle = css({
  padding: '20px 0',
});

export const AppErrorPageWrapperComponent: FC<AppErrorPageWrapperProps> = ({
  children,
  icon = faExclamationTriangle,
  className,
}) => {
  return (
    <AppPageStylesWrapper>
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        contentStyle={{
          height: '100%',
          display: 'block',
        }}
      >
        <div
          css={containerStyle}
          className={className}
        >
          <div css={contentWrapperStyle}>
            <div css={logoWrapperStyle}>
              <LogoComponent width="250px" />
            </div>

            {icon && (
              <div css={iconWrapperStyle}>
                <FontAwesomeIcon
                  css={iconStyle}
                  icon={icon}
                />
              </div>
            )}

            {children}
          </div>
        </div>
      </ScrollBarComponent>
    </AppPageStylesWrapper>
  );
};
