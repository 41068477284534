import {
  apiDelete,
  apiGet, apiPost, apiPut,
} from '~/_shared/utils/api/api.helpers';
import { type DemographicCategories } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.helpers';
import {
  type BoundaryGroupId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryMatchingCategory,
  type BoundaryMatchingType,
  type BoundaryTerritoryGroupCreateRequest, type BoundaryTerritoryGroupResponse,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.repository';

export type WmsDemographicCategory = ReturnType<typeof DemographicCategories>[number]['id'];

export type WmsDemographicConstraints = {
  [wmsDemographicCategory in WmsDemographicCategory]: {
    min: number;
    max: number;
  };
};

type CreateBoundaryGroupSettings = {
  display_name: string;
};

export type BoundaryGroupSettingsResponse = {
  display_name: string;
  zoom_levels: number[];
  load_zoom_level?: number;
  created_from?: {
    boundary_territory_group_id?: number;
    boundary_group_ids: number[];
  };
};

export type BoundaryGroupDemographic = Readonly<{
  id: string;
  category: string;
  name: string;
  preData: string;
  postData: string;
  percentage: boolean;
}>;

export type BoundaryGroupDemographicServerModel = {
  category: string;
  name: string;
  pre_data: string;
  post_data: string;
  percentage: boolean;
} & ({
  id: number; // old model
  codename: string;
} | {
  id: string; // new model
  codename?: undefined;
});

export type BoundaryMatchingColumn = {
  id: BoundaryMatchingCategory;
  name: string;
};

export type BoundaryGroupMatchingsResponse = {
  type: BoundaryMatchingType;
  matching_columns: BoundaryMatchingColumn[];
};

export type BoundaryGroupMatchings = {
  type: BoundaryMatchingType;
  matchingColumns: BoundaryMatchingColumn[];
};

export type BoundaryGroupBaseResponse = {
  allow_overlap: boolean;
  boundary_group_id: number;
  client_id: number | null;
  user_id: number | null;
  sort: number | null;
  readonly: boolean;
  settings: BoundaryGroupSettingsResponse;
  matchings: BoundaryGroupMatchingsResponse | Record<string, never>;
  wms?: {
    territories_count?: number;
    geometry_remaining_calls?: number;
    wms_temporary?: boolean;
  };
  wms_constraints?: WmsDemographicConstraints;
  edit_boundary_group_fallback?: BoundaryGroupId;
  visible?: boolean;
};

export type BoundaryGroupResponse = BoundaryGroupBaseResponse & {
  demographics?: BoundaryGroupDemographicServerModel[];
  codename?: string;
};

export type BoundaryGroupDetailsResponse = BoundaryGroupBaseResponse & {
  used_on_maps?: number[];
};

export type BoundaryGroupsResponse = {
  boundary_groups: ReadonlyArray<BoundaryGroupDetailsResponse>;
};

export type BoundaryGroupsQueryResponse = {
  boundary_groups: BoundaryGroupResponse[];
  map_id: number;
};

export type convertTerritoriesToBoundariesRequest = {
  boundary_territory_group_data: BoundaryTerritoryGroupCreateRequest;
  settings: CreateBoundaryGroupSettings;
  archive: boolean;
  zoom?: number;
};

export type ConvertTerritoriesToBoundariesResponse = {
  message: string;
  data: {
    map: number;
    boundary_territory_group_id: BoundaryTerritoryGroupId;
    boundary_group: BoundaryGroupResponse;
  };
};

export type CloneBoundaryGroupResponse = {
  boundary_group: BoundaryGroupBaseResponse;
  boundary_territory_group: BoundaryTerritoryGroupResponse;
};

export const getBoundaryGroupsList = (clientId: number): Promise<BoundaryGroupsResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group`;

  return apiGet(requestUrl);
};

export const getBoundaryGroupsQuery = (clientId: number, mapId: number): Promise<BoundaryGroupsQueryResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/query`;
  const requestParams = {
    query: {
      map_id: mapId,
    },
  };

  return apiPost(requestUrl, requestParams);
};

export const createBoundaryGroup = (clientId: number, displayName: string): Promise<BoundaryGroupResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group`;
  const requestParams: { settings: CreateBoundaryGroupSettings } = {
    settings: {
      display_name: displayName,
    },
  };

  return apiPost(requestUrl, requestParams);
};

export const editBoundaryGroup = (clientId: number, boundaryGroupId: number, displayName: string): Promise<BoundaryGroupResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/${boundaryGroupId}`;
  const requestParams: { settings: CreateBoundaryGroupSettings } = {
    settings: {
      display_name: displayName,
    },
  };

  return apiPut(requestUrl, requestParams);
};

export const deleteBoundaryGroup = (clientId: number, boundaryGroupId: number): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/${boundaryGroupId}`;

  return apiDelete(requestUrl);
};

export const convertTerritoriesToBoundaries = (
  clientId: number,
  params: convertTerritoriesToBoundariesRequest,
): Promise<ConvertTerritoriesToBoundariesResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/convert-from-boundary`;
  return apiPost(requestUrl, params);
};

export const cloneBoundaryGroup = (clientId: number, boundaryTerritoryGroupId: number, displayName: string): Promise<CloneBoundaryGroupResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/clone`;
  const requestParams = {
    boundary_territory_group_id: boundaryTerritoryGroupId,
    settings: {
      display_name: displayName,
    },
  };

  return apiPost(requestUrl, requestParams);
};
