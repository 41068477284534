import { useMemo } from 'react';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { useMapComponentZoomSelector } from '../../store/frontendState/mapComponent/mapComponent.selectors';

export const useDrawingToolSizePerPixelRatio = () => {
  const currentZoom = useMapComponentZoomSelector();

  return useMemo(() => {
    if (currentZoom === null) {
      return 1;
    }
    else {
      return convertPxDistanceToDifferentZoom({
        distance: 1,
        newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS,
        originalZoom: currentZoom,
      });
    }
  }, [currentZoom]);
};
