import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {
  faBuildings, faEdit, faFaceGrinWide, faFaceSunglasses,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  FormTextInputComponent, InputSize,
} from '~/_shared/baseComponents/inputs';
import { showHideIconContainerStyle } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { AccountSettingsUnavailableBodyComponent } from '~/_shared/components/accountSettings/accountSettingsUnavailableBody.component';
import { DEFAULT_EMPTY_ERRORS } from '~/_shared/components/accountSettings/modalDialogs/utils';
import type { Theme } from '~/_shared/themes/theme.model';
import { ValueValidationErrorCode } from '~/_shared/types/responseErrors/_shared/valueValidationErrorCodes.types';
import type { ErrorsTranslated } from '~/_shared/types/responseErrors/responseErrorCodes.helpers';
import { MAX_LENGTH_GENERIC_FIELD } from '~/_shared/types/responseErrors/responseErrorsCodes.constants';
import { autoCompleteDisabled } from '~/_shared/utils/dom/dom.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { UserFieldName } from '~/store/userData/repository/userData.types';
import { type UserTwoFactorMode } from '../../constants/twoFactorMode.enum';
import {
  type ClientLicenseType, isPaidLicense,
} from '../../types/client/license';
import { AccountSettingsActionsComponent } from './accountSettingsActions.component';

const highlightIconOnHoverStyle = (theme: Theme) => css({
  '&:hover': {
    color: theme.textColors.primary,
  },
});

const containerStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  boxSizing: 'border-box',
  padding: 16,
  gap: 24,
});

const submitButtonStyle = css({
  height: 48,
  fontSize: '16px',
  fontWeight: 500,
  width: '100%',
  borderRadius: 4,
});

const alertStyle = css({
  marginTop: 10,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  minHeight: 24,
});

export type AccountSettingsForm = {
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  newPassword: string;
  currentPassword: string;
  confirmPassword: string;
  mfaCode?: string;
};

type AccountSettingsProps = Readonly<{
  allowManageClient: boolean;
  allowManageSubscription: boolean;
  companyName: string;
  email: string;
  error?: string;
  userErrors?: ErrorsTranslated<UserFieldName>;
  clientErrors?: ErrorsTranslated<'company_name'>;
  firstName: string;
  isLoading: boolean;
  isPaidLicence: boolean;
  isUserDataLoading: boolean;
  lastName: string;
  selectedTwoFactorMode: UserTwoFactorMode;
  showSuccessMessage: boolean;
  hideSuccessMessage: () => void;
  licenseType?: ClientLicenseType;
  isClientMigrationInProgress: boolean;

  onSubmit: (form: Partial<AccountSettingsForm>) => void;
}>;

export const AccountSettingsComponent: FC<AccountSettingsProps> = (props) => {
  const [companyName, setCompanyName] = useState(props.companyName);
  const [companyNameErrors, setCompanyNameErrors] = useState(props.clientErrors?.company_name);
  const [firstName, setFirstName] = useState(props.firstName);
  const [firstNameErrors, setFirstNameErrors] = useState(props.userErrors?.[UserFieldName.first_name]);
  const [lastName, setLastName] = useState(props.lastName);
  const [lastNameErrors, setLastNameErrors] = useState(props.userErrors?.[UserFieldName.last_name]);
  const { openModal: openChangeEmailModal } = useModal(ModalType.ChangeEmail);
  const [t] = useTranslation();

  const isFormValid = !isTextEmpty(firstName) && !isTextEmpty(lastName) &&
    !firstNameErrors?.length && !lastNameErrors?.length && !companyNameErrors?.length;

  const onCompanyNameChange = useCallback((value: string) => {
    if (!isTextEmpty(value)) {
      setCompanyNameErrors(DEFAULT_EMPTY_ERRORS);
    }
    setCompanyName(value);
  }, []);

  const textLengthLimitError = useCallback((text: string) => {
    if (text.length > MAX_LENGTH_GENERIC_FIELD) {
      return t(ValueValidationErrorCode.MAX_LENGTH_EXCEEDED, { length: MAX_LENGTH_GENERIC_FIELD });
    }

    return null;
  }, [t]);

  const onFirstNameChange = useCallback((value: string) => {
    if (!isTextEmpty(value)) {
      setFirstNameErrors(DEFAULT_EMPTY_ERRORS);
    }
    setFirstName(value);
  }, []);

  const validateFirstName = useCallback(() => {
    const limitError = textLengthLimitError(firstName);

    if (isTextEmpty(firstName)) {
      setFirstNameErrors([t('First name must not be empty')]);
    }
    else if (limitError) {
      setFirstNameErrors([limitError]);
    }
  }, [firstName, t, textLengthLimitError]);

  const validateCompanyName = useCallback(() => {
    const limitError = textLengthLimitError(companyName);

    if (limitError) {
      setCompanyNameErrors([limitError]);
    }
  }, [companyName, textLengthLimitError]);

  const onLastNameChange = useCallback((value: string) => {
    if (!isTextEmpty(value)) {
      setLastNameErrors(DEFAULT_EMPTY_ERRORS);
    }
    setLastName(value);
  }, []);

  const validateLastName = useCallback(() => {
    const limitError = textLengthLimitError(lastName);

    if (isTextEmpty(lastName)) {
      setLastNameErrors([t('Last name must not be empty')]);
    }
    else if (limitError) {
      setLastNameErrors([limitError]);
    }
  }, [lastName, t, textLengthLimitError]);

  useEffect(() => {
    setFirstNameErrors(props.userErrors?.[UserFieldName.first_name]);
    setLastNameErrors(props.userErrors?.[UserFieldName.last_name]);
  }, [props.userErrors]);

  useEffect(() => {
    setCompanyNameErrors(props.clientErrors?.company_name);
  }, [props.clientErrors?.company_name]);

  useEffect(() => {
    setCompanyName(props.companyName);
  }, [props.companyName]);

  useEffect(() => {
    setFirstName(props.firstName);
  }, [props.firstName]);

  useEffect(() => {
    setLastName(props.lastName);
  }, [props.lastName]);

  const getFormData = useCallback((): Partial<AccountSettingsForm> => ({
    companyName,
    firstName,
    lastName,
  }), [companyName, firstName, lastName]);

  const { onSubmit } = props;
  const handleSaveChanges = useCallback(() => onSubmit(getFormData()), [getFormData, onSubmit]);

  return (
    <div>
      <div
        className="account-settings"
        css={containerStyles}
      >

        {props.isClientMigrationInProgress && (
          <AlertComponent
            type="info"
            size="small"
          >
            <AccountSettingsUnavailableBodyComponent />
          </AlertComponent>
        )}

        <FormTextInputComponent
          errorMessages={companyNameErrors}
          icon={faBuildings}
          isDisabled={!props.allowManageClient || props.isClientMigrationInProgress}
          label={t('Company Name')}
          marginBottom={24}
          onBlur={validateCompanyName}
          onChange={onCompanyNameChange}
          placeholder={t('Company Name')}
          value={companyName}
        />

        <FormTextInputComponent
          errorMessages={firstNameErrors}
          icon={faFaceGrinWide}
          isDisabled={props.isClientMigrationInProgress}
          label={t('First Name')}
          marginBottom={24}
          onBlur={validateFirstName}
          onChange={onFirstNameChange}
          placeholder={t('First Name')}
          value={firstName}
        />

        <FormTextInputComponent
          errorMessages={lastNameErrors}
          icon={faFaceSunglasses}
          isDisabled={props.isClientMigrationInProgress}
          label={t('Last Name')}
          marginBottom={24}
          onBlur={validateLastName}
          onChange={onLastNameChange}
          placeholder={t('Last Name')}
          value={lastName}
        />

        <FormTextInputComponent
          autoTrim
          label={t('Email Address')}
          placeholder={t('Email Address')}
          value={props.email}
          marginBottom={24}
          autoComplete={autoCompleteDisabled}
          icon={faEnvelope}
          onChange={noop}
          isReadOnly
          rightContent={(isPaidLicense(props.licenseType) && !props.isClientMigrationInProgress) && (
            <TooltipComponent tooltipContent={t('Change Email')} >
              <div
                css={[showHideIconContainerStyle({ size: InputSize.Medium }), highlightIconOnHoverStyle]}
                onClick={() => openChangeEmailModal()}
              >
                <FontAwesomeIcon icon={faEdit} />
              </div>
            </TooltipComponent>
          )}
        />
      </div>

      <div css={containerStyles}>
        <ButtonComponent
          css={submitButtonStyle}
          text={t('Save Changes')}
          isDisabled={!isFormValid || props.isLoading || props.isUserDataLoading || props.isClientMigrationInProgress}
          onClick={handleSaveChanges}
        />
        {props.showSuccessMessage && (
          <AlertComponent
            css={alertStyle}
            type="success"
            onClose={props.hideSuccessMessage}
          >
            {t('Account settings updated')}
          </AlertComponent>
        )}
        <AccountSettingsActionsComponent
          isPaidLicence={props.isPaidLicence}
          allowManageClient={props.allowManageClient}
          allowManageSubscription={props.allowManageSubscription}
          isClientMigrationInProgress={props.isClientMigrationInProgress}
        />
      </div>
    </div>
  );
};
