import { css } from '@emotion/react';
import { type IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  type FC, type ReactElement,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';

const textStyle = css({
  marginTop: 70,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const messageStyle = ({ color }: { color?: string }) => css({
  color: color || 'inherit',
});

const iconStyle = ({ color }: { color?: string }) => css({
  color: color || 'inherit',
  marginRight: '10px',
});

type InvitationResultComponentProps = {
  color?: string;
  text: string;
  bottom?: ReactElement;
  icon?: IconDefinition;
};

export const InvitationResultComponent: FC<InvitationResultComponentProps> = ({
  color,
  text,
  bottom,
  icon,
}) => {
  return (
    <>
      <div css={textStyle}>
        {icon && (
          <FontAwesomeIcon
            css={iconStyle({
              color,
            })}
            icon={icon}
          />
        )}
        <p css={messageStyle({ color })}>{text}</p>
      </div>
      {bottom && bottom}
    </>
  );
};
