import memoizee from 'memoizee';
import { newPerSpreadsheetMap } from '../../types/spreadsheet/spreadsheet.types';
import {
  type ColumnPerSpreadsheetSettings, type RowPerSpreadsheetSettings,
  type SpreadsheetColumnId,
} from '../../types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '../../types/spreadsheetData/spreadsheetRow';
import { logError } from '../logError';

export const spreadsheetColumnIdToString = (spreadsheetColumnId: SpreadsheetColumnId): string => (
  spreadsheetColumnId.spreadsheetId + '_' + spreadsheetColumnId.columnId
);

export const parseCombinedRowId = memoizee((combinedRowId: CombinedRowId): Readonly<{ rowId: number; spreadsheetId: number }> | null => {
  const items = combinedRowId.split('|');
  if (items.length !== 2) {
    return null;
  }

  const spreadsheetId = Number(items[0]);
  const rowId = Number(items[1]);

  if (isNaN(spreadsheetId) || isNaN(rowId)) {
    return null;
  }

  return {
    rowId,
    spreadsheetId,
  };
}, {
  // This function can memoize thousands of results. Function argument needs to be used as key to find the cached result.
  // Otherwise, the cost of seeking the cache key based on function arguments would be too high.
  // This is why we use primitive: true
  primitive: true,
});

export const areEqualSpreadsheetRowIds = (idSource: SpreadsheetRowId, idTarget: SpreadsheetRowId) =>
  idSource.spreadsheetId === idTarget.spreadsheetId && idSource.rowId === idTarget.rowId;

export const getDataFromColumnPerSpreadsheetSettings = <T>(
  lookup: ColumnPerSpreadsheetSettings<T>, spreadsheetColumnId: SpreadsheetColumnId
): T | undefined => lookup[spreadsheetColumnId.spreadsheetId]?.[spreadsheetColumnId.columnId];

export const getDataFromRowPerSpreadsheetSettings = <T>(
  lookup: RowPerSpreadsheetSettings<T>, spreadsheetRowId: SpreadsheetRowId
): T | undefined => lookup[spreadsheetRowId.spreadsheetId]?.[spreadsheetRowId.rowId];

export const addOrUpdateColumnPerSpreadsheetSettings = <T>(
  lookup: ColumnPerSpreadsheetSettings<T>, spreadsheetColumnId: SpreadsheetColumnId, item: T
): ColumnPerSpreadsheetSettings<T> => ({
  ...lookup,
  [spreadsheetColumnId.spreadsheetId]: {
    ...lookup[spreadsheetColumnId.spreadsheetId],
    [spreadsheetColumnId.columnId]: item,
  },
});

export const createDataWithColumnPerSpreadsheetSettings = <T>(
  item: T | undefined, spreadsheetColumnId: SpreadsheetColumnId
): ColumnPerSpreadsheetSettings<T | undefined> => {
  const result: ColumnPerSpreadsheetSettings<T | undefined> = newPerSpreadsheetMap();

  result[spreadsheetColumnId.spreadsheetId] = { [spreadsheetColumnId.columnId]: item };

  return result;
};

export const realSpreadsheetRowIdToRealSpreadsheetAndRowId =
  (realSpreadsheetRowId: CombinedRowId): [number, number] => {
    if (!realSpreadsheetRowId) {
      logError(`Could not transform realSpreadsheetRowId ${realSpreadsheetRowId} to RealSpreadsheetId`);
      return [0, 0];
    }
    const parts = realSpreadsheetRowId.toString().split('|');
    if (parts && parts.length === 2) {
      return [Number(parts[0]), Number(parts[1])];
    }
    else {
      logError(`Could not transform realSpreadsheetRowId ${realSpreadsheetRowId} to RealSpreadsheetId`);
      return [0, 0];
    }
  };
