import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import {
  s, Trans,
} from '../../translations/Trans';

const solutionStyle = css({
  marginTop: 10,
});

export const MapLimitReachedContentComponent: FC = () => {
  return (
    <div css={solutionStyle}>
      <Trans
        i18nKey="You can either delete one of your existing maps or contact <1>support</1> to purchase additional maps. Thanks for using Maptive."
      >
        {s('You can either delete one of your existing maps or contact ')}
        <SupportLinkComponent type={SupportLinkType.info} />
        {s(' to purchase additional maps. Thanks for using Maptive.')}
      </Trans>
    </div>
  );
};
