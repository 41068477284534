import { css } from '@emotion/react';
import { useMemo } from 'react';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { LabelVisualizerComponent } from '../components/labelVisualizer/labelVisualizer.component';
import { MarkerComponent } from '../components/marker/marker.component';
import { type QuadrilateralDimensions } from '../types/coordinateSystem/coordinateSystem';
import { type MarkerEntityStyle } from '../types/marker.types';

const markerStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions }) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  height: dimensions.height,
  width: dimensions.width,
});

type LabelOrMarkerVisualizerComponentProps = Readonly<{
  className?: string;
  dimensions: QuadrilateralDimensions;
  maxSize?: number;
  maxSizeEasing?: boolean;
  selectedMarkerStyle: MarkerEntityStyle;
  labelText?: string | undefined;
}>;

export const LabelOrMarkerVisualizerComponent: React.FC<LabelOrMarkerVisualizerComponentProps> = props => {
  const PreviewMarkerComponent = useMemo(() => {
    if (props.selectedMarkerStyle.type === 'marker') {
      const selectedMarkerStyle = props.selectedMarkerStyle;
      return (
        <MarkerComponent
          marker={selectedMarkerStyle.marker}
          markerType={selectedMarkerStyle.markerType}
          maxDimensions={props.dimensions}
          maxSize={props.maxSize}
          maxSizeEasing={props.maxSizeEasing}
          opacity={selectedMarkerStyle.opacity}
          size={selectedMarkerStyle.size}
          selectedColor={selectedMarkerStyle.selectedColor}
        />
      );
    }
    else if (props.selectedMarkerStyle.type === 'label') {
      const selectedLabel = props.selectedMarkerStyle;
      const selectedLabelText = props.labelText ?? '';
      const truncatedLabel = selectedLabelText.length > 4 ? `${selectedLabelText.substring(0, 3)}…` : selectedLabelText;

      return (
        <TooltipComponent
          behavior={TooltipBehavior.ShowOnHover}
          // Don't show tooltip for short labels
          tooltipContent={truncatedLabel !== selectedLabelText ? selectedLabelText : null}
        >
          <LabelVisualizerComponent
            {...selectedLabel}
            label={truncatedLabel}
            maxDimensions={props.dimensions}
          />
        </TooltipComponent>
      );
    }

    return null;
  }, [props.dimensions, props.labelText, props.maxSize, props.maxSizeEasing, props.selectedMarkerStyle]);

  return (
    <div
      css={markerStyle({ dimensions: props.dimensions })}
      className={props.className}
    >
      {PreviewMarkerComponent}
    </div>
  );
};
