import { useMemo } from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useSpreadsheetColumns } from '../../../../../store/matchupData/matchupDataSelectors.hook';

export const useSpreadsheetColumnsOptions = ({ withServerColumns }:
{ withServerColumns?: boolean } = {}
): DropdownOption<SpreadsheetColumnId>[] => {
  const spreadsheetColumns = useSpreadsheetColumns({ withServerColumns });

  return useMemo(
    () => spreadsheetColumns.map(item => ({
      name: item.name,
      value: {
        spreadsheetId: item.spreadsheetId,
        columnId: item.id,
      },
    })),
    [spreadsheetColumns]
  );
};
