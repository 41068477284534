import { css } from '@emotion/react';
import {
  faCheck, faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyles } from '~/_shared/baseComponents/buttons/button/button.styles';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { UniqueIdentifierRadioGroupComponent } from '~/_shared/components/uniqueIdentifierSelector/uniqueIdentifierRadioGroup.component';
import {
  UniqueIdentifierType,
  useUniqueIdentifierSelectorManager,
} from '~/_shared/components/uniqueIdentifierSelector/useUniqueIdentifierSelectorManager.hook';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNull } from '~/_shared/utils/typeGuards';
import { type ModalProps } from '~/modal/modalType.enum';

export type RowSettingsResolution = {
  erase: boolean;
  uniqueKeys?: ReadonlyArray<number>;
};

enum ResolutionStrategy {
  Erase = 'Erase',
  UniqueKey = 'UniqueKey',
}

const contentStyle = css({
  padding: 30,
});

const radioGroupStyle = css({
  gap: 20,
});

const radioOptionDescriptionStyle = css({
  paddingTop: 10,
  fontSize: '14px',
});

const tooltipContainerStyle = css({
  textAlign: 'right',
});

const questionMarkStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.primary,
  height: 24,
  right: 8,
  width: 24,
});

export type RowSettingsResolveModalComponentProps = ModalProps<{
  columnOptions: ReadonlyArray<DropdownOption<number>>;

  onSubmit: (params: RowSettingsResolution) => void;
}>;

export const RowSettingsResolveModalComponent: FC<RowSettingsResolveModalComponentProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [resolutionStrategy, setResolutionStrategy] = useState<ResolutionStrategy | null>(null);

  const {
    uIdType,
    setUIdType,
    idColumnIndexes,
    setIdColumnIndexes,
    addressColumnIndexes,
    setAddressColumnIndexes,
    removeAddressIdentifierColumnByIndex,
  } = useUniqueIdentifierSelectorManager();

  const selectedUidColumns = useMemo(() => (
    uIdType === UniqueIdentifierType.IdColumn
      ? idColumnIndexes.filter(notNull)
      : uIdType === UniqueIdentifierType.AddressColumns
        ? addressColumnIndexes.filter(notNull)
        : undefined
  ), [addressColumnIndexes, idColumnIndexes, uIdType]);

  const isSubmitDisabled = useMemo(() => !(
    resolutionStrategy === ResolutionStrategy.Erase
    || (resolutionStrategy === ResolutionStrategy.UniqueKey && selectedUidColumns && selectedUidColumns.length >= 1)
  ), [resolutionStrategy, selectedUidColumns]);

  const resolutionStrategyItems = useMemo(() => ([
    {
      label: t('mapRowSettingsResolution.option.erase.label'),
      value: ResolutionStrategy.Erase,
      children: (
        <div>
          <div css={radioOptionDescriptionStyle}>{t('mapRowSettingsResolution.option.erase.desc')}</div>
        </div>
      ),
    }, {
      label: t('mapRowSettingsResolution.option.uniqueKey.label'),
      value: ResolutionStrategy.UniqueKey,
      children: (
        <div>
          <div css={radioOptionDescriptionStyle}>{t('mapRowSettingsResolution.option.uniqueKey.desc')}</div>
          {resolutionStrategy === ResolutionStrategy.UniqueKey && (
            <>
              <div css={tooltipContainerStyle}>
                <TooltipDeprComponent
                  delayShow={250}
                  tooltipContent={t('mapRowSettingsResolution.option.uniqueKey.note')}
                >
                  <FontAwesomeIcon
                    css={questionMarkStyle({ theme })}
                    icon={faQuestionCircle}
                  />
                </TooltipDeprComponent>
              </div>
              <UniqueIdentifierRadioGroupComponent
                columnOptions={props.columnOptions}
                uniqueIdentifierData={{
                  addressColumnIndexes,
                  checkForUniqueDoneForCurrentColumns: false,
                  idColumnIndexes,
                  isCheckingIdentifier: false,
                  isIdentifierValid: false,
                  uIdType,
                  removeAddressIdentifierColumnByIndex,
                  setAddressColumnIndexes,
                  setIdColumnIndexes,
                  setUIdType,
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ]), [addressColumnIndexes, idColumnIndexes, props.columnOptions,
    removeAddressIdentifierColumnByIndex, resolutionStrategy, setAddressColumnIndexes,
    setIdColumnIndexes, setUIdType, t, uIdType, theme,
  ]);

  const handleSubmit = useCallback(() => {
    const onSubmit = props.onSubmit;
    onSubmit({
      erase: resolutionStrategy === ResolutionStrategy.Erase,
      uniqueKeys: selectedUidColumns,
    });
  }, [props.onSubmit, resolutionStrategy, selectedUidColumns]);

  const handleChangeStrategy = useCallback((newValue: string) => {
    setResolutionStrategy(newValue as ResolutionStrategy);
  }, []);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      contentStyle={contentStyle}
      maxWidth={547}
      caption={t('mapRowSettingsResolution.caption')}
      leftFooterContent={(
        <ButtonComponent
          text={t('Cancel')}
          onClick={() => props.onClose()}
          css={[ButtonStyles(theme).Secondary]}
        />
      )}
      confirmButton={(
        <ButtonComponent
          text={t('Done')}
          isDisabled={isSubmitDisabled}
          onClick={handleSubmit}
          prefixIcon={faCheck}
        />
      )}
    >
      <RadioGroupComponent<ResolutionStrategy>
        css={radioGroupStyle}
        items={resolutionStrategyItems}
        onValueChange={handleChangeStrategy}
        selectedValue={resolutionStrategy}
      />
    </ModalComponent>
  );
};
