import { faApple } from '@fortawesome/free-brands-svg-icons';
import {
  faGears, faLocationCircle, faStreetView, faTools, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type SidebarRoundMenuButtonProps } from '~/sidebar/sidebarApps/rightSidebar/roundButtonMenu/sidebarRoundButtonMenu.types';
import { MenuButtons } from '../customizeLocationPanel.enums';
import {
  type EditLocationCallbackProps, type RemoveLocationCallbacks, type ToolLocationCallbackProps,
} from './contextMenuStructure/contextMenuStructure.types';
import { deleteMarkerContextMenuStructure } from './contextMenuStructure/structures/deleteMarkerContextMenuStructure';
import { editLocationContextMenuStructure } from './contextMenuStructure/structures/editLocationContextMenuStructure';
import { toolsContextMenuStructure } from './contextMenuStructure/structures/toolsContextMenuStructure';
import { type LocationMenuRefs } from './roundButtonMenu.types';

export const rightLocationMenu = (props: {
  buttonRefs: LocationMenuRefs;
  onMenuSelect: (button: MenuButtons | null) => void; // this determines which round button will be highlighted as selected
  editLocationCallbacksProps: EditLocationCallbackProps;
  isEditingDisabled: boolean;
  removeLocationCallbacks: RemoveLocationCallbacks;
  toolLocationCallbacks: ToolLocationCallbackProps;
  t: TranslationFnc;
  isMapPresentational: boolean;
  isAppleMobileDevice: boolean;
  isMobileDevice: boolean;
  isMobileScreen: boolean;
  isLayeredMapConnected: boolean;
}): ReadonlyArray<SidebarRoundMenuButtonProps<MenuButtons>> => {
  const disableableButtonStyle = props.isEditingDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Primary;
  const isDeleteDisabled = props.isEditingDisabled || props.isLayeredMapConnected;
  const { addToRouteCallback, drawRadiusCallback } = props.toolLocationCallbacks;

  return [
    props.isMobileDevice && props.toolLocationCallbacks.navigate ? {
      buttonName: MenuButtons.NavigateToGoogle,
      buttonStyle: RoundButtonStyle.Primary,
      icon: faLocationCircle,
      tooltipLabel: props.t(MenuButtons.NavigateToGoogle),
      onClick: props.toolLocationCallbacks.navigate.callback,
    } : null,
    props.isAppleMobileDevice && props.toolLocationCallbacks.navigateApple ? {
      buttonName: MenuButtons.NavigateToGoogle,
      buttonStyle: RoundButtonStyle.Primary,
      icon: faApple,
      tooltipLabel: props.t(MenuButtons.NavigateToApple),
      onClick: props.toolLocationCallbacks.navigateApple.callback,
    } : null,
    props.isEditingDisabled ? null : {
      buttonName: MenuButtons.SettingsAndCustomization,
      buttonStyle: disableableButtonStyle,
      contextItemMenuStructure: editLocationContextMenuStructure({
        categorizeData: props.editLocationCallbacksProps.categorizeData,
        editData: props.editLocationCallbacksProps.editData,
        editImages: props.editLocationCallbacksProps.editImages,
        editMarker: props.editLocationCallbacksProps.editMarker,
        editPanel: {
          callback: () => {
            props.onMenuSelect(MenuButtons.SettingsAndCustomization);
            props.editLocationCallbacksProps.editPanel.callback();
          },
          isDisabled: props.editLocationCallbacksProps.editPanel.isDisabled,
          disabledTooltip: props.editLocationCallbacksProps.editPanel.disabledTooltip,
        },
      }, props.t, props.isMobileScreen),
      icon: faGears,
      ref: props.buttonRefs.editLocation,
      tooltipLabel: props.t(MenuButtons.SettingsAndCustomization),
      notSelectable: props.isEditingDisabled,
    },
    (addToRouteCallback || drawRadiusCallback) ? {
      buttonName: MenuButtons.Tools,
      buttonStyle: RoundButtonStyle.Primary,
      contextItemMenuStructure: toolsContextMenuStructure({
        addToRouteCallback: props.toolLocationCallbacks.addToRouteCallback,
        drawRadiusCallback: props.toolLocationCallbacks.drawRadiusCallback,
      }, props.t),
      icon: faTools,
      ref: props.buttonRefs.tools,
      tooltipLabel: props.t(MenuButtons.Tools),
    } : null,
    props.isMapPresentational ? null : {
      buttonName: MenuButtons.DeleteMarker,
      buttonStyle: isDeleteDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Danger,
      contextItemMenuStructure: deleteMarkerContextMenuStructure({
        deleteMarkerCallback: () => props.removeLocationCallbacks.removeMarkerCallback(),
      }, props.t),
      icon: faTrash,
      notSelectable: isDeleteDisabled,
      ref: props.buttonRefs.deleteMarker,
      tooltipLabel: props.t(MenuButtons.DeleteMarker),
    },
  ].filter(notNullsy);
};

export const leftLocationMenu = ({ onMenuSelect, isActive, t }: {
  onMenuSelect: (button?: MenuButtons | null) => void;
  isActive: boolean;
  t: TranslationFnc;
}) => [
  {
    buttonName: MenuButtons.StreetView,
    buttonStyle: isActive ? RoundButtonStyle.Quaternary : RoundButtonStyle.Disabled,
    icon: faStreetView,
    notSelectable: !isActive,
    onClick: () => onMenuSelect(),
    tooltipLabel: isActive ? t(MenuButtons.StreetView) : t('Street View Not Available'),
  },
];
