import { css } from '@emotion/react';
import {
  type FC, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';

const closeTabButtonStyle = css({
  padding: '6px 12px',
});

const stayButtonStyles = ({ theme }: { theme: Theme }) => css({
  background: theme.buttonColors.secondaryBackground,
  color: theme.buttonColors.secondaryText,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  padding: '6px 12px',
  '&:hover': {
    background: theme.buttonColors.secondaryActiveBackground,
  },
});

export type CreateNewSalesRepMapSuccessComponentProps = ModalProps<{
  mapUrl: string;
}>;

export const CreateNewSalesRepMapSuccessComponent: FC<CreateNewSalesRepMapSuccessComponentProps> = (props) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();

  const onCloseTab = useCallback(() => {
    window.close();
  }, []);

  const onStay = useCallback(() => {
    navigate(props.mapUrl);
    props.onClose();
  }, [navigate, props]);

  const ModalConfirmButton = (
    <ButtonComponent
      onClick={onCloseTab}
      css={closeTabButtonStyle}
      text={t('Close Tab')}
    />
  );

  const StayButton = (
    <ButtonComponent
      onClick={onStay}
      css={stayButtonStyles({ theme })}
      text={t('Stay')}
    />
  );

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Map With Sales People Creation Success')}
      leftFooterContent={StayButton}
      confirmButton={ModalConfirmButton}
    >
      <div>{t('sales-map.modal.success.text')}</div>
    </ModalComponent>
  );
};
