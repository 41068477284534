import md5 from 'md5';
import {
  useCallback, useMemo,
} from 'react';
import {
  type ImageGalleryItem, type ImageGalleryItemRenderer,
} from '~/_shared/components/imageGallery/imageGallery.component';
import { ImageGalleryImageComponent } from '~/_shared/components/imageGallery/imageGalleryImage.component';
import { ImageGalleryStreetViewComponent } from '~/_shared/components/imageGallery/imageGalleryStreetView.component';
import { type StreetViewLocation } from '~/store/frontendState/mapTools/streetView/streetView.state';

type UseLocationImageGalleryParams = Readonly<{
  images: readonly {
    original: string;
    thumbnail: string;
  }[];
  streetViewLocation: StreetViewLocation | null;

  onImageClick: (imageIndex: number) => void;
  onStreetViewClick: () => void;
}>;

export type GalleryItem = ImageGalleryItem<{
  type: 'image';
  src: string;
  imageIndex: number;
  onExpandClick: () => void;
} | {
  type: 'streetview';
  streetViewLocation: StreetViewLocation;
  onExpandClick: () => void;
}>;

export const useLocationImageGallery = (params: UseLocationImageGalleryParams) => {
  const { images, onImageClick, streetViewLocation, onStreetViewClick } = params;

  const galleryItems = useMemo<GalleryItem[]>(() => {
    const streetViewItems = streetViewLocation ? [{
      type: 'streetview',
      key: 'location-panel-image-gallery-streetview',
      streetViewLocation,
      onExpandClick: () => onStreetViewClick(),
    } as const] : [];

    const imageItems = images.map((image, index) => ({
      type: 'image',
      key: md5(image.thumbnail),
      src: image.thumbnail,
      imageIndex: index,
      onExpandClick: () => onImageClick(index),
    } as const));

    return [...streetViewItems, ...imageItems];
  }, [images, streetViewLocation, onStreetViewClick, onImageClick]);

  const renderGalleryItem = useCallback<ImageGalleryItemRenderer<GalleryItem>>((item, itemStyles) => {
    if (item.type === 'image') {
      return (
        <ImageGalleryImageComponent
          key={item.key}
          src={item.src}
          css={itemStyles}
          onClick={item.onExpandClick}
        />
      );
    }

    if (item.type === 'streetview') {
      return (
        <ImageGalleryStreetViewComponent
          key={item.key}
          panoramaContainerId={item.key}
          streetViewLocation={item.streetViewLocation}
          css={{
            ...itemStyles,
            width: itemStyles.maxWidth,
          }}
        />
      );
    }

    return null;
  }, []);

  return { galleryItems, renderGalleryItem };
};
