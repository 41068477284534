import {
  css, Global, type Interpolation,
} from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  type CSSProperties, type HTMLProps, type MouseEvent, type ReactNode, useMemo, useRef,
} from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { type AutoClosableModalProps } from '~/modal/modalType.enum';
import { zIndexGeneral } from '../../constants/zIndexGeneral.enum';
import { useTheme } from '../../themes/theme.hooks';
import {
  ScrollBarComponent, ScrollbarType,
} from '../scrollbar/scrollbar.component';
import { useAutoClosableModal } from './hooks/useAutoClosableModal.hook';

export const MODAL_DEFAULT_TITLE_HEIGHT = 56;
export const MODAL_DEFAULT_FOOTER_PADDING = 16;
export const MODAL_DEFAULT_CONFIRM_BUTTON_HEIGHT = 35;
export const MODAL_DEFAULT_VH = 90;
export const MODAL_DEFAULT_PX_OFFSET = 150;
export const MODAL_DEFAULT_MAX_CONTENT_HEIGHT_INNER = `${MODAL_DEFAULT_VH}vh - ${MODAL_DEFAULT_PX_OFFSET}px`;

const getCloseButtonStyle = (theme: Theme) => css({
  color: theme.modalColors.closeButton,
  background: theme.modalColors.closeButtonBackground,
  border: 'none',
  borderRadius: 4,
  position: 'absolute',
  cursor: 'pointer',
  top: 8,
  right: 8,
  fontSize: 20,
  height: 40,
  width: 40,
  lineHeight: '40px',
  textAlign: 'center',
  outline: 'none',
});

const titleStyle = ({ isCloseShown }: Readonly<{ isCloseShown: boolean }>) => (theme: Theme) => css({
  display: 'flex',
  alignItems: 'center',
  background: theme.modalColors.titleBackground,
  color: theme.modalColors.titleText,
  height: MODAL_DEFAULT_TITLE_HEIGHT,
  minHeight: MODAL_DEFAULT_TITLE_HEIGHT,
  lineHeight: '20px',
  margin: 0,
  padding: `0px ${isCloseShown ? '45px' : '15px'} 0px 15px`,
  textTransform: 'uppercase',
});

const modalOverlayStyle = (theme: Theme): CSSProperties => ({
  zIndex: zIndexGeneral.ModalOverlay,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.modalColors.overlayBackground,
});

const getContentStyle = css({
  padding: 10,
});

const modalContainerStyle = ({ maxWidth }: Readonly<{ maxWidth: number | string }>) => (theme: Theme) => css({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `0 0 0 2px ${theme.buttonColors.secondaryBackground}`,
  margin: 20,
  width: '100%',
  maxWidth,
  position: 'relative',
  maxHeight: '95%',
  overflow: 'auto',
  background: theme.modalColors.contentBackground,
  color: theme.modalColors.contentColor,
  opacity: 0,
  transform: 'translateY(-100%)',
  willChange: 'transform, opacity',
  transition: 'all .3s ease-in-out',
  borderRadius: 4,
  outline: 'none',
  boxSizing: 'border-box',
  '&.ReactModal__Content--after-open': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '&.ReactModal__Content--before-close': {
    opacity: 0,
    transform: 'translateY(-100%)',
  },
});

const footerStyle = (theme: Theme) => css({
  background: theme.modalColors.footerBackground,
  color: theme.modalColors.footerText,
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
  padding: `${MODAL_DEFAULT_FOOTER_PADDING}px 10px`,
  display: 'flex',
  alignItems: 'center',
});

const footerLeftContentStyle = css({
  flex: 1,
});

if (document.getElementById('root')) {
  ReactModal.setAppElement('#root');
}

type ModalProps = AutoClosableModalProps<Readonly<{
  additionalContent?: ReactNode;
  caption?: string;
  children: ReactNode;
  className?: string;
  closeButtonStyle?: Interpolation;
  confirmButton?: ReactNode;
  containerStyle?: Interpolation;
  contentMaxHeight?: string;
  contentStyle?: Interpolation;
  footer?: ReactNode;
  isCloseDisabled?: boolean;
  isScrollbarHidden?: boolean;
  leftFooterContent?: ReactNode;
  maxWidth?: number | string;
  preventCloseOnOverlayClick?: boolean;
  scrollbarContentStyle?: CSSProperties;
}>>;

export const ModalComponent = ({
  additionalContent,
  allowedPages,
  caption,
  children,
  className,
  closeButtonStyle,
  confirmButton,
  containerStyle,
  contentMaxHeight = `calc(${MODAL_DEFAULT_MAX_CONTENT_HEIGHT_INNER})`,
  contentStyle,
  footer,
  isCloseDisabled,
  isOpen,
  isScrollbarHidden,
  leftFooterContent,
  maxWidth = 547,
  onClose,
  preventCloseOnOverlayClick,
  scrollbarContentStyle,
  skipCloseOnMapIdChange,
  ...restProps
}: ModalProps) => {
  const theme = useTheme();
  const isMouseDragging = useRef<boolean>(false);

  useAutoClosableModal({ onClose, isOpen, skipCloseOnMapIdChange, allowedPages });
  const allowModalClose = !(isCloseDisabled || preventCloseOnOverlayClick);

  const onOverlayMouseDown = () => {
    isMouseDragging.current = false;
  };

  const onOverlayMouseMove = () => {
    isMouseDragging.current = true;
  };

  const onOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!isMouseDragging.current && event.target === event.currentTarget && allowModalClose) {
      onClose();
    }
    isMouseDragging.current = false;
  };

  const renderOverlay = (divProps: HTMLProps<HTMLDivElement>, contentElement: ReactNode) => {
    return (
      <div
        {...divProps}
        onMouseDown={onOverlayMouseDown}
        onMouseMove={onOverlayMouseMove}
        onClick={onOverlayClick}
      >
        {contentElement}
      </div>
    );
  };

  const footerElement = useMemo(() => {
    if (footer) {
      return (
        <div css={footerStyle}>
          {footer}
        </div>
      );
    }
    else if (leftFooterContent || confirmButton) {
      return (
        <div css={footerStyle}>
          <div css={footerLeftContentStyle}>
            {leftFooterContent}
          </div>
          <div>
            {confirmButton}
          </div>
        </div>
      );
    }
    return null;
  }, [confirmButton, footer, leftFooterContent]);

  return (
    <>
      <Global
        styles={{
          '.ReactModal__Overlay': {
            opacity: 0,
            transition: 'opacity .3s ease-in-out',
            '&.ReactModal__Overlay--after-open': {
              opacity: 1,
            },
            '&.ReactModal__Overlay--before-close': {
              opacity: 0,
            },
          },
        }}
      />
      <ReactModal
        {...restProps}
        htmlOpenClassName="is-modal-open"
        css={[modalContainerStyle({ maxWidth }), containerStyle]}
        className={className}
        isOpen={isOpen}
        onRequestClose={onClose}
        role="dialog"
        shouldCloseOnEsc={allowModalClose}
        shouldCloseOnOverlayClick={allowModalClose}
        shouldFocusAfterRender
        shouldReturnFocusAfterClose
        closeTimeoutMS={300}
        ariaHideApp={false}
        overlayElement={renderOverlay}
        style={{ overlay: modalOverlayStyle(theme) }}
      >
        {caption &&
          <p css={titleStyle(({ isCloseShown: !isCloseDisabled }))}>{caption}</p>
        }

        <ScrollBarComponent
          translateContentHeightToHolder
          type={ScrollbarType.Vertical}
          maxHeight={contentMaxHeight}
          isScrollbarHidden={isScrollbarHidden}
          contentStyle={{
            display: 'block',
            paddingBottom: 1, // fix for rounding error https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
            ...scrollbarContentStyle ?? {},
          }}
        >
          <div css={[getContentStyle, contentStyle]}>
            {children}
          </div>
        </ScrollBarComponent>

        {footerElement}

        {additionalContent}

        {!isCloseDisabled && (
          <button
            css={[getCloseButtonStyle, closeButtonStyle]}
            onClick={onClose}
          >
            <FontAwesomeIcon
              data-testid="close-modal"
              icon={faXmark}
            />
          </button>
        )}
      </ReactModal>
    </>
  );
};
