import {
  useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  closeAllModals, createAppError,
} from '~/store/modal/modal.actionCreators';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import {
  type DataSource, DataSourceError,
} from './dataSource';

export const useHandleDataSourceErrors = (dataSource: DataSource, onGoogleAuthFailed: () => void) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [lastErrorShown, setLastErrorShown] = useState<boolean>(false);

  useEffect(() => {
    setLastErrorShown(false);
  }, [dataSource.error]);

  useEffect(() => {
    if (lastErrorShown || dataSource.isLoading || !dataSource.error) {
      return;
    }

    setLastErrorShown(true);

    let errorMessage = '';

    switch (dataSource.error) {
      case DataSourceError.GoogleUnauthenticated:
        onGoogleAuthFailed();
        return;
      case DataSourceError.Empty:
        errorMessage = t('googleSheets.dataSourceError.empty');
        break;
      case DataSourceError.NotFound:
        errorMessage = t('googleSheets.dataSourceError.notFound');
        break;
      case DataSourceError.NoHeader:
        errorMessage = t('googleSheets.dataSourceError.noHeader');
        break;
      default:
        errorMessage = t('googleSheets.dataSourceError.general');
    }

    if (errorMessage) {
      dispatch(closeAllModals());
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('googleSheets.dataSourceError.title'),
        content: errorMessage,
      }));
    }
  }, [dataSource.error, dataSource.isLoading, dispatch, lastErrorShown, onGoogleAuthFailed, t]);
};
