import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { PRESET_COLORS_SECONDARY } from '~/_shared/constants/colors.constants';
import {
  changeColorAlpha, getNextColorFromPresets,
} from '~/_shared/utils/colors/colors.helpers';

const DEFAULT_PROXIMITY_FILL_COLOR_OPACITY = .35;
const MAX_DRIVE_TIME_POLYGON_HOURS = 8;
export const MAX_DRIVE_TIME_POLYGON_SECONDS = MAX_DRIVE_TIME_POLYGON_HOURS * 60 * 60;

export const getProximityColor = (usedColors: string[]): ColorResult => {
  const newColor = getNextColorFromPresets(usedColors, PRESET_COLORS_SECONDARY);
  let newColorResult = createColor(newColor);
  newColorResult = changeColorAlpha(newColorResult.rgb, DEFAULT_PROXIMITY_FILL_COLOR_OPACITY);

  return newColorResult;
};
