import { useMemo } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  type ToggleableSharedPresentationalMapTool, toggleableSharedPresentationalMapTools,
} from '~/map/settings/accordion/sharedPresentationalMaps/toggleableSharedPresentationalMapTools.types';
import { type AppState } from '~/store/app.store';
import { type MapSettingsPublicMapSettingsState } from './mapSettingsPublicMapSettings.state';

export const publicMapSettingsSelector = (state: AppState) => state.map.mapSettings.data.publicMapSettings;
export const usePublicMapSettingsSelector = () => useSelector(publicMapSettingsSelector);

const publicMapSettingsZoomRestrictLevelsSelector = (state: AppState) => publicMapSettingsSelector(state).zoomRestrictLevels;
export const usePublicMapSettingsZoomRestrictLevelsSelector = () => useSelector(publicMapSettingsZoomRestrictLevelsSelector);

const publicMapSettingsRestrictMapPanningSelector = (state: AppState) => publicMapSettingsSelector(state).restrictMapPanning;
export const usePublicMapSettingsRestrictMapPanningSelector = () => useSelector(publicMapSettingsRestrictMapPanningSelector);

export const publicMapSettingsMapToolsMenuOpenSelector = (state: AppState) => publicMapSettingsSelector(state).mapToolsMenuOpen;
export const usePublicMapSettingsMapToolsMenuOpenSelector = () => useSelector(publicMapSettingsMapToolsMenuOpenSelector);

const publicMapSettingsIsStreetViewEnabledSelector = (state: AppState) => publicMapSettingsSelector(state).allowStreetView;
export const usePublicMapSettingsIsStreetViewEnabledSelector = () => useSelector(publicMapSettingsIsStreetViewEnabledSelector);

const publicMapSettingsAreFilterMenusOpenSelector = (state: AppState) => publicMapSettingsSelector(state).filterMenusOpen;
export const usePublicMapSettingsAreFilterMenusOpenSelector = () => useSelector(publicMapSettingsAreFilterMenusOpenSelector);

const publicMapSettingsIsZoomEnabledSelector = (state: AppState) => publicMapSettingsSelector(state).mapZoom;
export const usePublicMapSettingsIsZoomEnabledSelector = () => useSelector(publicMapSettingsIsZoomEnabledSelector);

const publicMapSettingsMarkersVisibilityButtonSelector = (state: AppState) => publicMapSettingsSelector(state).markersVisibilityButton;
export const usePublicMapSettingsMarkersVisibilityButtonSelector = () => useSelector(publicMapSettingsMarkersVisibilityButtonSelector);

const publicMapSettingsAllowedExportDataToolsSelector = (state: AppState) => state.map.mapSettings.data.publicMapSettings.allowedExportDataTools;
export const usePublicMapSettingsAllowedExportDataToolsSelector = () => useSelector(publicMapSettingsAllowedExportDataToolsSelector);

const publicMapSettingsSearchBarAlwaysVisibleSelector = (state: AppState) => state.map.mapSettings.data.publicMapSettings.searchBarAlwaysVisible;
export const usePublicMapSettingsSearchBarAlwaysVisibleSelector = () => useSelector(publicMapSettingsSearchBarAlwaysVisibleSelector);

export const publicMapSettingEnabledPresentationalMapToolsSelector = (state: AppState) =>
  filterEnabledPresentationalMapTools(publicMapSettingsSelector(state));

export const usePublicMapSettingEnabledPresentationalMapToolsSelector = () => {
  const publicMapSettings = usePublicMapSettingsSelector();

  return useMemo(() => (
    filterEnabledPresentationalMapTools(publicMapSettings)
  ), [publicMapSettings]);
};

export const usePublicMapSettingDefaultPresentationalMapToolSelector = () => {
  const defaultOpenMapToolPresentationalMap = usePublicMapSettingsSelector().defaultOpenMapToolPresentationalMap;
  const enabledPresentationalMapTools = usePublicMapSettingEnabledPresentationalMapToolsSelector();

  if (enabledPresentationalMapTools.length && !enabledPresentationalMapTools.find(tool => tool === defaultOpenMapToolPresentationalMap)) {
    return enabledPresentationalMapTools[0];
  }
  return defaultOpenMapToolPresentationalMap;
};

export const filterEnabledPresentationalMapTools = (publicMapSettings: MapSettingsPublicMapSettingsState) => {
  const allToggleableSharedMapTools = Object.keys(toggleableSharedPresentationalMapTools) as ToggleableSharedPresentationalMapTool[];

  return allToggleableSharedMapTools //loop through all toggleabe presentational map tools
    .filter(tool => publicMapSettings[tool]) // filter only tools which exist and are enabled in public map settings
    .map(spmTool => toggleableSharedPresentationalMapTools[spmTool]); // get them as a list of MapTool type
};
