import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';

const menuAnchorStyle = ({ x, y }: { x: number; y: number }) => css({
  position: 'absolute',
  top: y,
  left: x,
});

const menuItemStyle = css({
  fontSize: '12px',
});

type DrawingItemMenuItem = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};

type DrawingItemsContextMenuProps = Readonly<{
  map: google.maps.Map;
  menuPosition: { x: number; y: number };
  items: ReadonlyArray<DrawingItemMenuItem>;

  onHide: () => void;
}>;

export const DrawingToolContextMenuComponent: FC<DrawingItemsContextMenuProps> = (props) => {
  return (
    <div>
      <ContextMenuComponent
        isVisible
        onHide={props.onHide}
        preferredPlacement="bottom-start"
        renderMenuContent={() => (
          <>
            {props.items.map(((menuItem, index) => (
              <ContextMenuItemCoreComponent key={index}>
                <ContextMenuDefaultItem
                  isDisabled={!menuItem.isActive}
                  itemStyle={menuItemStyle}
                  onClick={menuItem.onClick}
                >
                  {menuItem.label}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            )))}
          </>
        )}
      >
        {ref => (
          <span
            ref={ref}
            css={menuAnchorStyle(props.menuPosition)}
          />
        )}
      </ContextMenuComponent>
    </div>
  );
};
