import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../../../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useAjaxCall } from '../../../../../_shared/utils/hooks/useAjaxCall';
import { deleteBoundaryGroupItem } from '../../../../../store/boundaries/boundaries.repository';
import { boundaryItemRemoveSuccess } from '../../../../../store/boundaryItems/boundaryItems.actionCreators';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';

export const useBoundaryRemove = () => {
  const { closeConfirmationModal, openConfirmationModal } = useConfirmationModal();
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, boundaryGroupId: number) =>
    deleteBoundaryGroupItem(clientId, boundaryGroupId)
  );

  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const removeBoundary = useCallback((clientId: number, boundaryGroupId: number) => {
    return invokeAjax(clientId, boundaryGroupId);
  }, [invokeAjax]);

  const removeBoundaryRequest = useCallback((boundaryId: number, onSuccess?: () => void) => {
    if (clientId === null) {
      return;
    }

    openConfirmationModal({
      title: t('Delete Territory'),
      confirmCaption: t('Proceed'),
      text: t('Are you sure you want to delete this territory?'),
      permanentConfirmSettings: {
        id: 'remove-boundary',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      showLoaderAfterConfirmClick: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        removeBoundary(clientId, boundaryId)
          .then((deleteResult) => {
            dispatch(boundaryItemRemoveSuccess(boundaryId, deleteResult));
            onSuccess?.();
          })
          .finally(() => {
            closeConfirmationModal();
          });
      },
    });
  }, [removeBoundary, dispatch, clientId, openConfirmationModal, closeConfirmationModal, t]);

  return {
    isLoading,
    removeBoundary: removeBoundaryRequest,
  };
};
