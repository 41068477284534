import { useMemo } from 'react';
import { type GroupingColumn } from '~/_shared/types/grouping/grouping';
import { usePerGroupVisualSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import { type SpreadsheetRowId } from '../_shared/types/spreadsheetData/spreadsheetRow';
import { createPieChartItems } from '../_shared/utils/maptive/pieCharts/pieCharts.helpers';
import { type ModalProps } from '../modal/modalType.enum';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMatchupDataSelector } from '../store/matchupData/matchupData.selectors';
import { useSpreadsheetDataDataSelector } from '../store/spreadsheetData/spreadsheetData.selectors';
import { ChartsVisualizerComponent } from './chartsVisualizer.component';

export type ChartsModalProps = ModalProps & Readonly<{
  modalCaption: string;
  spreadsheetRowIds: SpreadsheetRowId[];
}>;

export const ChartsModalContainer: React.FC<ChartsModalProps> = ({ spreadsheetRowIds, modalCaption, isOpen, onClose }) => {
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const primaryGroupColumn: GroupingColumn | undefined = activeGroupColumns[0];
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const perGroupVisualSettings = usePerGroupVisualSettings();

  const data = useMemo(() => {
    if (spreadsheetData) {
      const params = { markerIds: spreadsheetRowIds, activeGroupColumns, spreadsheetData, perGroupVisualSettings, matchupData };
      return {
        chartItems: createPieChartItems({ ...params, hierarchy: 0 }),
        secondaryChartItems: createPieChartItems({ ...params, hierarchy: 1 }),
      };
    }

    return null;
  }, [spreadsheetData, spreadsheetRowIds, activeGroupColumns, perGroupVisualSettings, matchupData]);

  const columnName = useMemo(() => {
    return matchupData[primaryGroupColumn?.spreadsheetId ?? -1]?.data?.columns[primaryGroupColumn?.columnId ?? -1] || '';
  },
  [primaryGroupColumn?.spreadsheetId, primaryGroupColumn?.columnId, matchupData]);

  return (
    <ChartsVisualizerComponent
      chartTitle={columnName}
      data={data}
      isOpen={isOpen}
      modalCaption={modalCaption}
      onClose={onClose}
    />
  );
};
