import { type LatLngBounds } from '~/_shared/types/latLng';
import { type Geometry } from '~/_shared/types/polygon/polygon.types';
import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';
import { type BoundaryItemResponseBase } from '~/store/boundaries/boundaries.repository';
import { type BoundaryFilterRequest } from '../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type PolygonFilterRequest } from '../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { type RadiusFilterRequest } from '../../spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import { type BoundaryId } from '../boundaries/boundaryIdentifier.type';

export type MapBoundaryQueryRequestItem = {
  zoom_level: number;
  boundary_group_id: number;
  bounding_box: LatLngBounds;
  continuation_token?: string;
};

type MapBoundaryQueryRequest = {
  map_id: number;
  query: MapBoundaryQueryRequestItem[];
};

export type MapBoundaryQueryItemResponse = BoundaryItemResponseBase & {
  label_bounding_box: LatLngBounds | null;
  geometry: Geometry;
};

export type BoundaryGroupObject = {
  [boundaryGroupId: number]: BoundaryGroupBoundaryObject;
};
export type BoundaryGroupBoundaryObject = {
  [boundary_id: number]: MapBoundaryQueryItemResponse;
};

export type MapBoundaryGeometryResponse = {
  geometries: {
    zoom_level: number;
  } & BoundaryGroupObject;
};

export type MapBoundaryQueryResponse = {
  list: [{
    continuation_token?: string;
    zoom_level: number;
    done: boolean;
  } & BoundaryGroupObject];
};

export type AreaQueryFilters = {
  boundary_territory_filter?: BoundaryTerritoryFilterRequest;
  boundary_filter?: BoundaryFilterRequest;
  polygon_filter?: PolygonFilterRequest;
  radius_filter?: RadiusFilterRequest;
};

type MapBoundaryAreaQueryRequest = {
  map_id: number;
  boundary_group_id: number;
  format: 'json' | 'xlsx' | 'csv' | 'tsv';
  as_text?: boolean;
  overlap_type: 'partial' | 'contains';
  name_overrides?: { [key: BoundaryId]: string };
  exclude_artificial?: boolean;
} & AreaQueryFilters;

type MapBoundaryAreaQueryBoundaryResponse = {
  boundary_id: number;
  codename: string;
  display_name: string;
  meta_data: {
    settings: Record<string, unknown>;
    state_code: string;
  };
};

type MapBoundaryAreaQueryResponse = {
  boundary_group_id: number;
  boundaries: MapBoundaryAreaQueryBoundaryResponse[];
} | {
  download: string | null;
} | {
  data: string | null;
};

export const getMapBoundaryQuery = (
  clientId: number, request: MapBoundaryQueryRequest
): Promise<{ data: MapBoundaryQueryResponse }> => {
  const requestUrl = `/api/clients/${clientId}/boundary/query`;

  return apiPost(requestUrl, request);
};

export const getMapBoundaryGeometry = (
  clientId: number, boundaryGroupsIds: number[],
): Promise<MapBoundaryGeometryResponse[]> => {
  const getRequestUrl = (boundaryGroupId: number) => `/api/clients/${clientId}/boundary-group/${boundaryGroupId}/geometry`;

  const promiseArray = boundaryGroupsIds.map((boundaryGroupId) => apiGet(getRequestUrl(boundaryGroupId)));
  return Promise.all(promiseArray);
};

export const getMapBoundaryAreaQuery = (
  clientId: number, request: MapBoundaryAreaQueryRequest
): Promise<MapBoundaryAreaQueryResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary/area-query`;

  return apiPost(requestUrl, request);
};
