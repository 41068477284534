import { apiPost } from '~/_shared/utils/api/api.helpers';

export type RemoveLockResponse = {
  message: string;
};

export const removeLock = (expires: string, hash: string, email: string, signature: string): Promise<RemoveLockResponse> => {
  const requestUrl = `/api/locks/remove?email=${encodeURIComponent(email)}&expires=${expires}&hash=${hash}&signature=${signature}`;
  return apiPost(requestUrl);
};
