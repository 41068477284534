import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback,
  useState,
} from 'react';
import {
  ButtonComponent, ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import {
  type ActiveGroupFilters,
  type GroupingColumn,
  type GroupingColumnValues,
  GroupingType,
  type NumericActiveGroupColumnType,
  type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import {
  type ColumnMarkerSettings, type MarkerSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  filterNonNumericBuckets,
  getActiveGroupFiltersValueForGroupIdToggle,
} from '~/_shared/utils/grouping/grouping.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  createNumericBucketKey,
  createTextGroupKey,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { LEGEND_MARKER_SIZE } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { GroupingItemBodyRowComponent } from '~/grouping/components/groupingItemBodyRow.component';
import { GroupingItemBodyRowWrapperComponent } from '~/grouping/components/groupingItemBodyRowWrapper.component';
import { isGroupPrimary } from '../groupingColumns.helpers';

const INITIAL_GROUP_LIMIT = 500;
const GROUP_LIMIT_INCREMENT = 100;

const stretchButtonWidthStyle = {
  flexGrow: 1,
};

type GroupingItemProps = Readonly<{
  activeFilters: ActiveGroupFilters;
  currentGroupColumn: GroupingColumn;
  groupMarkers?: ColumnMarkerSettings;
  hierarchyIndicator?: number;
  areMarkersEditable: boolean;
  isFilteringDisabled: boolean;
  numericActiveGroupColumnType: NumericActiveGroupColumnType | null;
  uniqueGroupColumns: ReadonlyArray<UniqueGroup>;

  onFilterChange: (spreadsheetColumnId: SpreadsheetColumnId, groups: GroupingColumnValues<1>) => void;
  onMarkerEdit: (group: UniqueGroup) => void;
}>;

export const GroupingItemBody: FC<GroupingItemProps> = props => {
  const {
    activeFilters,
    areMarkersEditable,
    currentGroupColumn: { spreadsheetId, columnId },
    groupMarkers,
    hierarchyIndicator,
    isFilteringDisabled,
    numericActiveGroupColumnType,
    onFilterChange,
    onMarkerEdit,
    uniqueGroupColumns,
  } = props;

  const [t] = useTranslation();
  const [groupLimit, setGroupLimit] = useState(INITIAL_GROUP_LIMIT);

  const currentFilter = activeFilters[spreadsheetId]?.[columnId] ?? {};

  const getUpdatedVisualGroupMarkerSettings = (group: UniqueGroup): MarkerSettings | undefined => {
    if (group.type === GroupingType.Numeric) {
      if (!numericActiveGroupColumnType) {
        return undefined;
      }

      const uniqueGroupsWithoutNonNumericLength = filterNonNumericBuckets(uniqueGroupColumns).length;
      const bucketLocationString = createNumericBucketKey(numericActiveGroupColumnType, hierarchyIndicator,
        uniqueGroupsWithoutNonNumericLength);

      const groupSpecificMarkers = groupMarkers?.[GroupingType.Numeric]?.[bucketLocationString];

      const maxGivenMarkerSize = groupSpecificMarkers?.[uniqueGroupsWithoutNonNumericLength - 1]?.marker?.size;
      const sizeFactor = maxGivenMarkerSize ? LEGEND_MARKER_SIZE / maxGivenMarkerSize : 1;

      const originalMarkerProps = groupSpecificMarkers?.[group.id];
      if (!originalMarkerProps) {
        return undefined;
      }

      return {
        ...originalMarkerProps,
        ...(originalMarkerProps.marker && {
          marker: {
            ...originalMarkerProps?.marker,
            size: originalMarkerProps?.marker?.size * sizeFactor,
          },
        }),
      };
    }
    else {
      const isPrimaryGroup = isGroupPrimary(hierarchyIndicator);
      const textGroupKey = createTextGroupKey(group.id, isPrimaryGroup);
      return groupMarkers?.[GroupingType.Text]?.[textGroupKey];
    }
  };

  const onSelectGroup = useCallback((group: UniqueGroup) => {
    const groupingColumn = {
      spreadsheetId,
      columnId,
    };

    const newValues = getActiveGroupFiltersValueForGroupIdToggle(activeFilters, groupingColumn, group);

    onFilterChange(groupingColumn, newValues);
  }, [activeFilters, columnId, onFilterChange, spreadsheetId]);

  const onLoadMore = useCallback(() => {
    setGroupLimit(limit => limit + GROUP_LIMIT_INCREMENT);
  }, []);

  return (
    <>
      {uniqueGroupColumns.slice(0, groupLimit).map((group) => {
        const markerSettings = getUpdatedVisualGroupMarkerSettings(group);
        const isSelected = !!currentFilter[group.type as GroupingType.Text]?.[group.id];

        return (
          // Keep the props stable. This component is memoized and needs to be stable for performance reasons.
          <GroupingItemBodyRowComponent
            key={group.id}
            group={group}
            isFilteringDisabled={isFilteringDisabled}
            isMarkerEditable={areMarkersEditable}
            isSelected={isSelected}
            markerSettings={markerSettings}
            onEditMarker={onMarkerEdit}
            onSelectGroup={onSelectGroup}
          />
        );
      })}
      {(uniqueGroupColumns.length > groupLimit) && (
        <GroupingItemBodyRowWrapperComponent key="loadMore">
          <ButtonComponent
            buttonStyle={ButtonStyle.Quaternary}
            css={stretchButtonWidthStyle}
            onClick={onLoadMore}
            prefixIcon={faArrowDown}
            size={ButtonSize.Small}
            text={t('Load More')}
          />
        </GroupingItemBodyRowWrapperComponent>
      )}
    </>
  );
};
