import { css } from '@emotion/react';
import {
  faLock, faUnlock, faUserShield,
} from '@fortawesome/pro-solid-svg-icons';
import { Breakpoint } from '~/_shared/style.mixins';
import { MapPrivacyLevel } from '~/_shared/types/map';
import { type ThemeProps } from '~/_shared/types/themeProps';

export const mapListingBreakpoints: { [key in Breakpoint]: number } = {
  [Breakpoint.xSmall]: 380,
  [Breakpoint.small]: 546,
  [Breakpoint.mid]: 683,
  [Breakpoint.large]: 794,
  [Breakpoint.xLarge]: 892,
  [Breakpoint.xxLarge]: 1100,
};

export const getMapRowPrivacyIcon = (privacy: string) => {
  switch (privacy) {
    case MapPrivacyLevel.Private:
      return faUserShield;
    case MapPrivacyLevel.PasswordProtected:
      return faLock;
    default:
      return faUnlock;
  }
};

export const containerStyle = css({
  '&:before': {
    content: '""',
    clear: 'both',
    display: 'table',
  },
});

export const titleBlockStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 20,
};

export const titleActionContainer = {
  display: 'flex',
  gap: 8,
};

export const titleStyle = css({
  fontSize: 40,
  fontWeight: 'normal',
  margin: 0,
  textTransform: 'uppercase',
});

export const tableStyle = css({
  cursor: 'default',
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'collapse',
});

export const originalTableHeaderStyle = css({
  visibility: 'hidden',
});

// make the original header invisible
export const originalTableHeaderDataStyle = css({
  height: 0,
  lineHeight: 0,
});

export const originalTableHeaderRowStyle = css({
  height: 0,
  border: 'none',
  overflow: 'hidden',
});

export const originalTableWrapperStyle = ({ theme }: ThemeProps) => css({
  overflow: 'hidden',
  boxShadow: `0 2px 5px ${theme.shadowColors.secondary}`,
});

export const ctaWrapperStyle = css({
  display: 'flex',
  padding: '20px 0',
});

export const stickyComponentStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.primary,
  '&:before': {
    content: '""',
    position: 'absolute',
    background: theme.backgroundColors.primary,
    left: -10,
    right: -10,
    top: 0,
    bottom: 0,
    zIndex: -1,
  },
});

export const noResultsStyle = ({ theme }: ThemeProps) => css({
  margin: 0,
  padding: '10px 0',
  textAlign: 'center',
  background: theme.backgroundColors.primary,
});

export const alertWrapperStyle = css({
  margin: 10,
});

export const mapListingTablePrimaryStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'center',
  ...(tableWidth > mapListingBreakpoints[Breakpoint.xSmall] ? { display: 'table-cell' } : {}),
});

export const mapListingTableSecondaryStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'center',
  ...(tableWidth > mapListingBreakpoints[Breakpoint.large] ? { display: 'table-cell' } : {}),
});

export const mapListingTableLastStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'center',
  ...(tableWidth > mapListingBreakpoints[Breakpoint.xSmall] ? { display: 'table-cell' } : {}),
});

export const mapListingTableHeaderLastStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'right',
  ...(tableWidth > mapListingBreakpoints[Breakpoint.large] ? { display: 'table-cell' } : {}),
});

export const mapListingTableTertiaryStyle = (tableWidth: number) => css({
  display: 'table-cell',
  textAlign: 'center',
  ...(tableWidth < mapListingBreakpoints[Breakpoint.large] ? { display: 'none' } : {}),
});

export const mapListingTableQuaternaryStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'center',
  ...(tableWidth > mapListingBreakpoints[Breakpoint.xLarge] ? { display: 'table-cell' } : {}),
});

export const mapListingTableQuinaryStyle = (tableWidth: number) => css({
  display: 'none',
  textAlign: 'center',
  ...(tableWidth >= mapListingBreakpoints[Breakpoint.xxLarge] ? { display: 'table-cell' } : {}),
});
