import { css } from '@emotion/react';
import type { Placement } from '@floating-ui/react';
import { DropdownPlacement } from '~/_shared/baseComponents/dropdown/dropdown.component';
import { type ThemeProps } from '../../types/themeProps';

export const MENU_BORDER_WIDTH = 1;

const getDropdownMenuBorderRadius = (placement: Placement) => {
  switch (placement) {
    case DropdownPlacement.TopEnd:
    case DropdownPlacement.TopStart:
    case DropdownPlacement.Top: {
      return '4px 4px 0 0';
    }
    case DropdownPlacement.BottomEnd:
    case DropdownPlacement.BottomStart:
    case DropdownPlacement.Bottom: {
      return '0 0 4px 4px';
    }
    case DropdownPlacement.Left:
    case DropdownPlacement.LeftStart:
    case DropdownPlacement.LeftEnd: {
      return '4px 0 0 4px';
    }
    case DropdownPlacement.Right:
    case DropdownPlacement.RightStart:
    case DropdownPlacement.RightEnd: {
      return '0 4px 4px 0';
    }
    default: {
      return 4;
    }
  }
};

export const menuSharedStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.dropdownColors.optionBackground,
  border: `${MENU_BORDER_WIDTH}px solid ${theme.lineColors.basePrimary}`,
  boxShadow: `-5px 5px 8px ${theme.dropdownColors.shadow}, 5px 5px 8px ${theme.dropdownColors.shadow}`,
  borderRadius: 4,
});

export const dropdownMenuStyle = ({ theme, placement }: ThemeProps<{
  placement: Placement;
}>) => css(menuSharedStyle({ theme }), {
  width: 'max-content',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: getDropdownMenuBorderRadius(placement),
  borderTop: 'none',
  marginTop: -1,
});

export const contextMenuStyle = menuSharedStyle;
