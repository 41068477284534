import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import {
  TooltipBehavior,
  TooltipComponent, TooltipPlacement, type TooltipProps,
} from '~/_shared/baseComponents/tooltip/tooltip.component';

type TooltipDeprProps = Omit<TooltipProps, 'children'> & {
  children: ReactNode;
};

const tooltipWrapperStyle = css({
  display: 'inline-block',
});

/**
 * @deprecated Use the {@link TooltipComponent} instead.
 */
export const TooltipDeprComponent = ({
  arrowStyle,
  behavior = TooltipBehavior.ShowOnHover,
  children,
  className,
  contentStyle,
  delayShow,
  offset,
  placement = TooltipPlacement.Top,
  tooltipContent,
  ...restProps
}: TooltipDeprProps) => {
  const isNoContent = !tooltipContent;

  if (isNoContent) {
    return <>{children}</>;
  }

  return (
    <TooltipComponent
      arrowStyle={arrowStyle}
      behavior={behavior}
      className={className}
      contentStyle={contentStyle}
      delayShow={delayShow}
      offset={offset}
      placement={placement}
      tooltipContent={tooltipContent}
      {...restProps}
    >
      <span css={tooltipWrapperStyle}>
        {children}
      </span>
    </TooltipComponent>
  );
};
