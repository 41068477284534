import { copy } from '~/_shared/utils/collections/collections';
import { type MapSettingsFileAttachmentsAction } from './fileAttachments.action';
import {
  MAP_SETTINGS_FILE_ATTACHMENTS_ADD,
  MAP_SETTINGS_FILE_ATTACHMENTS_REMOVE,
} from './fileAttachments.actionTypes';
import { type MapSettingsFileAttachmentsState } from './fileAttachments.state';

export const mapSettingsFileAttachmentsInitialState: MapSettingsFileAttachmentsState = {
  map: new Map(),
};

export const mapSettingsFileAttachmentsReducer = (
  state = mapSettingsFileAttachmentsInitialState,
  action: MapSettingsFileAttachmentsAction
): MapSettingsFileAttachmentsState => {
  switch (action.type) {
    case MAP_SETTINGS_FILE_ATTACHMENTS_REMOVE:
      return {
        ...state,
        map: copy.andRemove(state.map, action.payload.ids),
      };

    case MAP_SETTINGS_FILE_ATTACHMENTS_ADD:
      return {
        ...state,
        map: copy.andAdd(state.map, [[action.payload.attachment.id, action.payload.attachment]]),
      };

    default:
      return state;
  }
};
