import {
  type FC, useMemo,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { getDirectionsLabelMarkerSettings } from '~/map/map/directions/mapDirectionsManager';
import { useMarkerLabelData } from '~/map/map/markers/useMarkers/useMarkerLabelData.hook';
import { useMapMarkerWaypointDataSelector } from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { LabelOrMarkerVisualizerContainer } from '../../../../_shared/labelOrMarkerVisualizer/labelOrMarkerVisualizer.container';
import { useGetMarkerVisualsForSpreadsheetRowIds } from '../../../../_shared/utils/markers/useGetMarkerVisualsForSpreadsheetRowIds.hook';
import { getDataFromRowPerSpreadsheetSettings } from '../../../../_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { useActiveMarkerIdSelector } from '../../../../store/frontendState/activeMapElements/activeMapElements.selectors';
import {
  INDICATOR_WIDTH, type MarkerDescriptionProps, SidebarTitleComponent,
} from './sidebarTitle.component';

const GROUP_HIERARCHY_INDICATOR = 0;
const emptyArray: readonly SpreadsheetRowId[] = [];
const fallbackMarkerId: SpreadsheetRowId = { rowId: '', spreadsheetId: 0 };
const maxVisualizerDimensions = { height: INDICATOR_WIDTH, width: INDICATOR_WIDTH };

export const SidebarTitleContainer: FC<MarkerDescriptionProps> = props => {
  const activeMarkerId = useActiveMarkerIdSelector();
  const theme = useTheme();
  const waypointInfo = useMapMarkerWaypointDataSelector(activeMarkerId);
  const waypointLabelSettings = waypointInfo && getDirectionsLabelMarkerSettings({
    index: waypointInfo.waypointIndex,
    theme,
    totalWaypoints: waypointInfo.route.waypoints.length,
    routeColor: waypointInfo.route.color,
  });
  const waypointLabelText = waypointInfo ? (waypointInfo.waypointIndex + 1).toString(10) : undefined;

  const spreadsheetRowIds = useMemo(() => [activeMarkerId || { spreadsheetId: 0, rowId: '0|0' }] as const, [activeMarkerId]);
  const visualSettings = useGetMarkerVisualsForSpreadsheetRowIds({
    isLegend: false,
    groupHierarchyIndicator: GROUP_HIERARCHY_INDICATOR,
    spreadsheetRowIds,
  });

  const selectedMarkerSettings = useMemo(() =>
    visualSettings ? getDataFromRowPerSpreadsheetSettings(visualSettings, spreadsheetRowIds[0]) : undefined,
  [spreadsheetRowIds, visualSettings]);
  const activeMarkerIdArray = useMemo<readonly SpreadsheetRowId[]>(() => {
    return activeMarkerId ? [activeMarkerId] : emptyArray;
  }, [activeMarkerId]);

  const markerLabelText = useMarkerLabelData(activeMarkerIdArray).get(activeMarkerId ?? fallbackMarkerId);
  const labelTextToUse = waypointLabelText ?? markerLabelText;
  const markerVisualSettingsToUse = waypointLabelSettings ?? selectedMarkerSettings;

  const markerIndicator = (markerVisualSettingsToUse && activeMarkerId) ? (
    <LabelOrMarkerVisualizerContainer
      labelText={labelTextToUse}
      markerSettings={markerVisualSettingsToUse}
      maxDimensions={maxVisualizerDimensions}
      maxSizeEasing
      visualSettingsType={markerVisualSettingsToUse.useMarker ? MarkerSettingType.Marker : MarkerSettingType.Label}
    />
  ) : undefined;

  return (
    <SidebarTitleComponent
      {...props}
      sidebarTitleStyle={props.sidebarTitleStyle}
      indicator={markerIndicator}
    />
  );
};
