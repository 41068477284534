import { useMemo } from 'react';
import { DndType } from '~/_shared/constants/dndType.enum';
import { type ColumnRole } from '~/_shared/types/columnRole.enum';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { reorderColumnsWithPriority } from '~/customizeLocationPanel/locationData/panelSettings.helpers';
import { type SpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { filterOutNotExistingBubbleItemIds } from '../customizeLocationPanel.helpers';
import { convertPanelSettingsToBubbleItemMap } from './locationData.helpers';
import {
  type BubbleItemMap, type BubbleItemsAndOrder,
} from './locationData.types';
import { getItemDnDType } from './locationDataItem/draggableItem.helpers';
import { type PanelItemsSettings } from './panelSettings.types';

type UseBubbleItemsAndOrderHookProps = Readonly<{
  spreadsheetRowData: SpreadsheetRowData | null;
  spreadsheetRowId?: SpreadsheetRowId;
  panelSettings: PanelItemsSettings;
}>;

export const useBubbleItemsAndOrder = ({
  panelSettings,
  ...props
}: UseBubbleItemsAndOrderHookProps): BubbleItemsAndOrder | null => {
  const matchupData = useMatchupDataSelector();

  const bubbleItemsMap = useMemo((): BubbleItemMap | null => {
    if (!props.spreadsheetRowId || !props.spreadsheetRowData) {
      return null;
    }

    const itemMatchupData = matchupData[props.spreadsheetRowId.spreadsheetId]?.data;
    if (!itemMatchupData) {
      return null;
    }
    const categories = itemMatchupData?.categories;
    let categoriesPerColumnLookup: { [columnId: string]: ColumnRole } | null = null;

    if (categories) {
      Object.keys(categories).forEach((cat: ColumnRole) => {
        const categoryColumn = categories[cat].match;

        if (categoryColumn) {
          const categoryForColumn = { [categoryColumn]: cat };
          categoriesPerColumnLookup = { ...categoriesPerColumnLookup, ...categoryForColumn };
        }
      });
    }

    return convertPanelSettingsToBubbleItemMap({
      categoriesPerColumnLookup,
      itemMatchupData,
      columnSettingsMap: panelSettings.itemsData,
      spreadsheetId: props.spreadsheetRowId.spreadsheetId,
      values: props.spreadsheetRowData.columnsData,
    });
  }, [matchupData, panelSettings.itemsData, props.spreadsheetRowData, props.spreadsheetRowId]);

  const { allBasic, allContact, inactiveUnordered } = useMemo(() => {
    const inactiveUnordered: SpreadsheetColumnId[] = [];
    const allContact: SpreadsheetColumnId[] = [];
    const allBasic: SpreadsheetColumnId[] = [];

    if (props.spreadsheetRowId && bubbleItemsMap) {
      const columns = bubbleItemsMap[props.spreadsheetRowId.spreadsheetId];

      if (columns) {
        Object.values(columns).forEach(item => {
          if (item) {
            if (!item.itemData.isActive) {
              inactiveUnordered.push(item.id);
            }

            if (getItemDnDType(item.itemType) === DndType.LocalizationBasicItem) {
              allBasic.push(item.id);
            }
            else {
              allContact.push(item.id);
            }
          }
        });
      }
    }

    return {
      allContact,
      allBasic,
      inactiveUnordered,
    };
  }, [bubbleItemsMap, props.spreadsheetRowId]);

  return useMemo(() => {
    if (!props.spreadsheetRowId || !bubbleItemsMap) {
      return null;
    }

    return {
      items: bubbleItemsMap,
      orderedBasicItems: getOrderedItems(bubbleItemsMap, allBasic, panelSettings.orderedBasicItems),
      orderedContactItems: getOrderedItems(bubbleItemsMap, allContact, panelSettings.orderedContactItems),
      retired: inactiveUnordered,
    };
  }, [allBasic, allContact, bubbleItemsMap, inactiveUnordered, panelSettings.orderedBasicItems, panelSettings.orderedContactItems, props.spreadsheetRowId]);
};

const getOrderedItems = (bubbleItems: BubbleItemMap, columnsWithData: ReadonlyArray<SpreadsheetColumnId>, columnsInOrder: ReadonlyArray<SpreadsheetColumnId>) => {
  const synchronizedColumns = reorderColumnsWithPriority(columnsWithData, columnsInOrder);

  return filterOutNotExistingBubbleItemIds(bubbleItems, synchronizedColumns);
};
