import { css } from '@emotion/react';
import {
  faCheck, faPencil, faTrash,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import {
  RoundButtonComponent,
  RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { TinyMapPlaceholderComponent } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  createColorWithOpacity,
  guaranteeHash,
} from '../../../../../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../../../../../_shared/components/colorPicker/colorPicker.types';
import { type ThemeProps } from '../../../../../../_shared/types/themeProps';
import { isTextEmpty } from '../../../../../../_shared/utils/text/text.helpers';
import { ColorPickerComponent } from '../../../../../../boundary/settings/colorPicker/colorPicker.component';

type BoundaryListingItemProps = Readonly<{
  className?: string;
  isLoading: boolean;
  name: string;
  color: string;
  opacity: number;
  onChange: (name: string, color: string, opacity: number) => void;
  onRemove: () => void;
}>;

const headerStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.primary,
  padding: '4px 38px 4px 14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
});

const headingStyle = css({
  flex: 1,
  marginRight: 8,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});

const headingPrimaryStyle = css({
  fontWeight: 400,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const activeButtonStyle = ({ theme }: ThemeProps) => css({
  background: theme.iconColors.backgroundSecondary,
});

const buttonStyle = css({
  marginLeft: 6,
});

const editNameInputStyle = css({
  fontSize: '14px',
  height: 30,
});

const colorLabelStyle = css({
  marginRight: 8,
});

const headingWrapperStyle = css({
  overflow: 'hidden',
});

export const BoundaryListingItemComponent: FC<BoundaryListingItemProps> = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingName, setEditingName] = useState(props.name);
  const [editingColor, setEditingColor] = useState<ColorResult>(() => createColorWithOpacity(props.color, props.opacity));
  const theme = useTheme();

  const startEditingItem = () => {
    setEditingName(props.name);
    setIsEditing(true);
  };

  const save = () => {
    if (isTextEmpty(editingName)) {
      return;
    }

    props.onChange(editingName, guaranteeHash(editingColor.hex), editingColor.rgb.a ?? 0);
    setIsEditing(false);
  };

  const discard = () => {
    setEditingName(props.name);
    setEditingColor(createColorWithOpacity(props.color, props.opacity));
    setIsEditing(false);
  };

  const isSubmitDisabled = props.isLoading ||
    (isTextEmpty(editingName) || editingName === props.name) &&
    guaranteeHash(editingColor.hex).toUpperCase() === props.color.toUpperCase() &&
    editingColor.rgb.a === props.opacity;

  useEffect(() => {
    setEditingColor(createColorWithOpacity(props.color, props.opacity));
  }, [props.color, props.opacity]);

  return (
    <div>
      <div
        className={props.className}
        css={headerStyle({ theme })}
      >
        <div css={headingStyle}>
          <TinyMapPlaceholderComponent css={colorLabelStyle} >
            <ColorPickerComponent
              selectedColor={editingColor}
              onChange={setEditingColor}
              editable={isEditing}
              tileStyle={css({ border: '1px solid black' })}
              size={18}
              displayAlpha
            />
          </TinyMapPlaceholderComponent>

          {!isEditing ? (
            <div css={headingWrapperStyle}>
              <strong css={headingPrimaryStyle}>
                {props.name}
              </strong>
            </div>
          ) : (
            <TextInputComponent
              css={editNameInputStyle}
              onChange={setEditingName}
              value={editingName}
            />
          )}
        </div>

        <div>
          {isEditing && (
            <>
              <RoundButtonComponent
                css={[buttonStyle, isSubmitDisabled ? undefined : activeButtonStyle({ theme })]}
                buttonStyle={isSubmitDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Confirm}
                size={RoundButtonSize.Small}
                icon={faCheck}
                isDisabled={isSubmitDisabled}
                onClick={save}
              />

              <RoundButtonComponent
                buttonStyle={RoundButtonStyle.Danger}
                css={[buttonStyle, activeButtonStyle({ theme })]}
                size={RoundButtonSize.Small}
                icon={faXmark}
                onClick={discard}
              />
            </>
          )}

          {!isEditing && (
            <RoundButtonComponent
              buttonStyle={RoundButtonStyle.Secondary}
              css={[buttonStyle, activeButtonStyle({ theme })]}
              size={RoundButtonSize.Small}
              icon={faPencil}
              onClick={() => {
                startEditingItem();
              }}
            />
          )}

          <RoundButtonComponent
            buttonStyle={RoundButtonStyle.Danger}
            css={[buttonStyle, activeButtonStyle({ theme })]}
            size={RoundButtonSize.Small}
            icon={faTrash}
            onClick={() => {
              props.onRemove();
            }}
          />
        </div>
      </div>
    </div>
  );
};
