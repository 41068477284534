import { useRef } from 'react';
import { useMapSettingsSettingsUnitSystemSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import { getDirectionsService } from './getDirections.service';

export const useFindDirections = () => {
  const unitSystem = useMapSettingsSettingsUnitSystemSelector();
  const getDirectionsServiceInstance = useRef(getDirectionsService(unitSystem));

  return {
    findDirections: getDirectionsServiceInstance.current.findDirections,
  };
};
