import {
  MAP_SETTINGS_DISABLE_CLICKABLE_ICONS,
  MAP_SETTINGS_ENABLE_CLICKABLE_ICONS, MAP_SETTINGS_SETTINGS_UPDATE_SETTINGS,
} from './mapSettingsSettings.actionTypes';
import { type MapSettingsSettingsState } from './mapSettingsSettings.state';

export const mapSettingsSettingsUpdateSettings = (settings: Partial<MapSettingsSettingsState>) => ({
  type: MAP_SETTINGS_SETTINGS_UPDATE_SETTINGS,
  payload: {
    settings,
  },
}) as const;

export const mapSettingsEnableClickableIcons = () => ({
  type: MAP_SETTINGS_ENABLE_CLICKABLE_ICONS,
}) as const;

export const mapSettingsDisableClickableIcons = () => ({
  type: MAP_SETTINGS_DISABLE_CLICKABLE_ICONS,
}) as const;
