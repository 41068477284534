import {
  MAP_PRESENTATIONAL_SET_SHOW_PRESENTATIONAL_MENU,
  MAP_PRESENTATIONAL_SET_SHOW_WRONG_PASSWORD_ERROR,
  MAP_PRESENTATIONAL_SET_VIEW,
} from './mapPresentational.actionTypes';
import { type MapPresentationalView } from './mapPresentational.state';

export const mapPresentationalSetView = (view: MapPresentationalView) => ({
  type: MAP_PRESENTATIONAL_SET_VIEW,
  payload: {
    view,
  },
}) as const;

export const mapPresentationalSetShowWrongPasswordError = (showPasswordError: boolean) => ({
  type: MAP_PRESENTATIONAL_SET_SHOW_WRONG_PASSWORD_ERROR,
  payload: {
    showPasswordError,
  },
}) as const;

export const mapPresentationalSetShowPresentationalMenu = (isMenuOpen: boolean) => ({
  type: MAP_PRESENTATIONAL_SET_SHOW_PRESENTATIONAL_MENU,
  payload: {
    isMenuOpen,
  },
}) as const;
