import { type Uuid } from '../../_shared/utils/createUuid';
import {
  BOUNDARY_TERRITORY_CHANGE_NAME,
  BOUNDARY_TERRITORY_DELETE,
  BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL,
  BOUNDARY_TERRITORY_GROUP_CREATE_ERROR,
  BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_FETCH_ERROR,
  BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST,
  BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS,
  BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR,
  BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS,
  BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL,
  BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR,
  BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST,
  BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS,
  BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY,
} from './boundaryTerritoryGroups.actionTypes';
import {
  type BoundaryTerritoryGroupCloneRequest,
  type BoundaryTerritoryGroupCreateRequest,
  type UpdateBoundaryTerritoryAssignmentsRequest,
} from './boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupSettings,
} from './boundaryTerritoryGroups.state';

export const boundaryTerritoryGroupsFetchRequest = (mapId: number) => ({
  type: BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST,
  payload: {
    mapId,
  },
}) as const;

export const boundaryTerritoryGroupsFetchSuccess = (boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>) => ({
  type: BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS,
  payload: {
    boundaryTerritoryGroups,
  },
}) as const;

export const boundaryTerritoryGroupsFetchError = (error: Error) => ({
  type: BOUNDARY_TERRITORY_GROUP_FETCH_ERROR,
  payload: {
    error,
  },
}) as const;

export const boundaryTerritoryGroupsCreateRequest = (request: BoundaryTerritoryGroupCreateRequest, onSuccess?: (boundaryTerritoryGroup: BoundaryTerritoryGroup) => void) => ({
  type: BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST,
  payload: {
    request,
    onSuccess,
  },
}) as const;

export const boundaryTerritoryGroupsCloneRequest = (payload: {
  request: BoundaryTerritoryGroupCloneRequest;
  onSuccess?: () => void;
  onError?: (e: Error) => void;
},
) => ({
  type: BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST,
  payload,
}) as const;

export const boundaryTerritoryGroupsCreateSuccess = (boundaryTerritoryGroup: BoundaryTerritoryGroup) => ({
  type: BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS,
  payload: {
    boundaryTerritoryGroup,
  },
}) as const;

export const boundaryTerritoryGroupsUpdateError = () => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR,
}) as const;

export const boundaryTerritoryGroupsUpdateCancel = () => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL,
}) as const;

export const boundaryTerritoryGroupsUpdateRequest = (
  updatedBoundaryTerritoryGroup: BoundaryTerritoryGroup,
  { onDone, fetchBoundaryDetails = true, failSilently = false, refetchOnConcurrencyError = false }:
  {
    onDone?: () => void;
    fetchBoundaryDetails?: boolean;
    failSilently?: boolean;
    refetchOnConcurrencyError?: boolean;
  } = {}) => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST,
  payload: {
    updatedBoundaryTerritoryGroup,
    onDone,
    fetchBoundaryDetails,
    failSilently,
    refetchOnConcurrencyError,
  },
}) as const;

export const boundaryTerritoryGroupUpdateSuccess = (
  boundaryTerritoryGroup: BoundaryTerritoryGroup,
  { fetchBoundaryDetails = true }: { fetchBoundaryDetails?: boolean} = {}
) => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS,
  payload: {
    boundaryTerritoryGroup,
    fetchBoundaryDetails,
  },
}) as const;

export const boundaryTerritoryGroupUpdateAssignmentsError = () => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR,
}) as const;

export const boundaryTerritoryGroupUpdateAssignmentsRequest = (
  boundaryTerritoryGroupId: number,
  requests: UpdateBoundaryTerritoryAssignmentsRequest[],
  onSuccess?: () => void
) => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST,
  payload: {
    boundaryTerritoryGroupId,
    requests,
    onSuccess,
  },
}) as const;

export const boundaryTerritoryGroupUpdateAssignmentsSuccess = (
  boundaryTerritoryGroupId: number,
  requests: UpdateBoundaryTerritoryAssignmentsRequest[]
) => ({
  type: BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS,
  payload: {
    boundaryTerritoryGroupId,
    requests,
  },
}) as const;

export const boundaryTerritoryGroupsCreateError = () => ({
  type: BOUNDARY_TERRITORY_GROUP_CREATE_ERROR,
}) as const;

export const boundaryTerritoryGroupsCreateCancel = () => ({
  type: BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL,
}) as const;

export const boundaryTerritoryGroupsRemoveRequest = (boundaryTerritoryGroupId: number) => ({
  type: BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST,
  payload: {
    boundaryTerritoryGroupId,
  },
}) as const;

export const boundaryTerritoryGroupsRemoveSuccess = (boundaryTerritoryGroup: BoundaryTerritoryGroup) => ({
  type: BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS,
  payload: {
    boundaryTerritoryGroup,
  },
}) as const;

export const boundaryTerritoryGroupsRemoveError = () => ({
  type: BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR,
}) as const;

export const boundaryTerritoryGroupCreateCustomTerritory = (
  boundaryTerritoryGroupId: number, newTerritoryId: Uuid, name: string,
  fillColor: string, fillOpacity: number, onSuccess?: (newBoundaryTerritoryId: string) => void
) => ({
  type: BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY,
  payload: {
    boundaryTerritoryGroupId,
    newTerritoryId,
    name,
    fillColor,
    fillOpacity,
    onSuccess,
  },
}) as const;

export const boundaryTerritoryChangeName = (boundaryTerritoryGroupId: number, territoryId: string, newName: string) => ({
  type: BOUNDARY_TERRITORY_CHANGE_NAME,
  payload: {
    boundaryTerritoryGroupId,
    territoryId,
    newName,
  },
}) as const;

export const boundaryTerritoryDelete = (boundaryTerritoryGroupId: number, territoryId: string) => ({
  type: BOUNDARY_TERRITORY_DELETE,
  payload: {
    boundaryTerritoryGroupId,
    territoryId,
  },
}) as const;

export const boundaryTerritoryGroupPatchSettings = (payload: {
  boundaryTerritoryGroupId: number;
  patcher: (currentSettings: BoundaryTerritoryGroupSettings) => BoundaryTerritoryGroupSettings;
}) => ({
  type: BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS,
  payload,
}) as const;
