import { css } from '@emotion/react';
import {
  type ReactElement,
  useCallback, useMemo,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GoogleSpreadsheetInfo } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';

const SECTION_TITLE_TOP_MARGIN = 10;
const SECTION_TITLE_BOTTOM_MARGIN = 10;
const SECTION_TITLE_HEIGHT = 23;
const MODAL_HEIGHT_CALC_INNER = '90vh - 150px';
const STATIC_SECTION_HEIGHT = SECTION_TITLE_TOP_MARGIN + SECTION_TITLE_HEIGHT + SECTION_TITLE_BOTTOM_MARGIN;

const rootStyle = css({
  fontSize: '14px',
  padding: '0px',
});

const alertStyle = css({
  marginTop: 20,
});

const instructionsStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: 16,
  padding: '10px 10px 0',
});

const scrollBarOverrideStyle = { display: 'block' };

const spreadsheetSectionStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  boxSizing: 'border-box',
  padding: '0 10px 0',
});

const rowStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
});

const spreadsheetRowStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  height: 48,
  padding: '0 25px',
});

const textCellStyle = ({ isDisabled }: {isDisabled: boolean}) => css({
  cursor: isDisabled ? 'not-allowed' : 'default',
  opacity: isDisabled ? .5 : 1,
  padding: 0,
});

const sectionHeaderStyle = css({
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: `${SECTION_TITLE_HEIGHT}px`,
  margin: `${SECTION_TITLE_TOP_MARGIN}px 0 ${SECTION_TITLE_BOTTOM_MARGIN}px`,
});

type GoogleSpreadsheetSelectorComponentProps = Readonly<{
  errorMessage: string;
  isLoading: boolean;
  isOpen: boolean;
  selectedGoogleSpreadsheetInfo: GoogleSpreadsheetInfo | null;

  onClose: () => void;
  onSelect: (spreadSheetId: string, sheetId?: string) => void;
}>;

export const GoogleSpreadsheetSelectorComponent: React.FC<GoogleSpreadsheetSelectorComponentProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();

  const { onSelect, isLoading } = props;

  const selectionButton = useCallback((spreadsheetId: string, sheetId?: string) => (
    <ButtonComponent
      text={t('Select')}
      isDisabled={isLoading}
      onClick={() => onSelect(spreadsheetId, sheetId)}
    />
  ), [isLoading, onSelect, t]);

  const googleSheetRows: ReactElement = useMemo(() => {
    const sheetListing: ReactElement[] = [];
    const selectedSpreadsheet = props.selectedGoogleSpreadsheetInfo;
    if (selectedSpreadsheet) {
      selectedSpreadsheet.sheets.forEach(sheetInfo => (
        sheetListing.push(
          <div
            css={spreadsheetRowStyle({ theme })}
            key={sheetInfo.sheetId}
          >
            <div css={textCellStyle({ isDisabled: isLoading })}>
              {sheetInfo.title}
            </div>
            {selectionButton(selectedSpreadsheet.spreadsheetId, sheetInfo.sheetId)}
          </div>
        )
      ));
    }

    return (
      <div
        css={rowStyle({ theme })}
      >
        {sheetListing}
      </div>
    );

  }, [isLoading, props.selectedGoogleSpreadsheetInfo, selectionButton, theme]);

  return (
    <ModalComponent
      additionalContent={props.isLoading ? (
        <OverlayLoaderComponent />
      ) : null}
      caption={t('Select a Sheet')}
      contentStyle={rootStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      contentMaxHeight={`calc(${MODAL_HEIGHT_CALC_INNER})`}
    >

      <div css={instructionsStyle({ theme })}>
        {t('googleSheets.create.selectOneSheet')}
      </div>

      <div css={spreadsheetSectionStyle({ theme })}>
        <div css={sectionHeaderStyle}>
          {t('Sheet Names')}
        </div>
      </div>

      <ScrollBarComponent
        contentStyle={scrollBarOverrideStyle}
        translateContentHeightToHolder
        type={ScrollbarType.Vertical}
        maxHeight={`calc(${MODAL_HEIGHT_CALC_INNER} - ${STATIC_SECTION_HEIGHT + 1}px)`}
      >
        {googleSheetRows}
      </ScrollBarComponent>

      {props.errorMessage && (
        <AlertComponent
          css={alertStyle}
          type="danger"
        >
          {props.errorMessage}
        </AlertComponent>
      )}

    </ModalComponent>
  );
};
