import {
  MARKERS_CONTEXT_MENU_HIDE, MARKERS_CONTEXT_MENU_SHOW,
} from './markersContextMenu.actionTypes';
import { type MarkersContextMenuPosition } from './markersContextMenu.state';

export const showMarkerContextMenu = (position: MarkersContextMenuPosition) => ({
  type: MARKERS_CONTEXT_MENU_SHOW,
  payload: {
    position,
  },
}) as const;

export const hideMarkerContextMenu = () => ({
  type: MARKERS_CONTEXT_MENU_HIDE,
}) as const;
