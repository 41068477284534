import { css } from '@emotion/react';
import { type FC } from 'react';
import colorWheel from '../../../../../../assets/images/colorwheel.png';

const colorWheelStyle = css({
  display: 'block',
  height: 22,
  width: 22,
});

type ColorWheelProps = Readonly<{
  className?: string;
}>;

export const ColorWheelComponent: FC<ColorWheelProps> = props => (
  <img
    alt="Color wheel"
    className={props.className}
    css={colorWheelStyle}
    src={colorWheel}
  />
);
