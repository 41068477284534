import { type XYCoord } from 'react-dnd';

export const getStylesForDraggedItem = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
  };
};

export const insertBeforeInArray = <T extends any>(data: {
  source: T; target: T; array: ReadonlyArray <T>;
  equalityComparator?: (source: T, target: T) => boolean;
}): ReadonlyArray<T> => {
  const { array, source, target, equalityComparator } = data;

  let handleAreEqual: (source: T, target: T) => boolean;
  if (equalityComparator) {
    handleAreEqual = equalityComparator;
  }
  else {
    handleAreEqual = (source: T, target: T) => target === source;
  }

  const withoutSource = array.filter(i => !handleAreEqual(i, source));
  const indexOfTarget = array.findIndex(el => handleAreEqual(el, target));
  const head = withoutSource.slice(0, indexOfTarget);
  const tail = withoutSource.slice(indexOfTarget, withoutSource.length);

  return [...head, source, ...tail];
};
