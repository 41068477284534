import {
  type FC, memo, type MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import {
  useActiveDrawingInstanceZIndex, useDrawingInstanceZIndex,
} from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  drawingEditPushNewSnapshot, drawingEditSetSelectedDrawing,
} from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered, drawingToolItemHoverStopped, drawingToolItemMoveStarted, drawingToolItemMoveStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemCallout } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  DRAWING_CALLOUT_TEXTBOX_PERCENTAGE, DRAWING_TEXT_AREA_MIN_HEIGHT, DRAWING_TEXT_AREA_MIN_WIDTH,
} from '../textAreaCommon/drawingToolTextArea.constants';
import { useDrawingToolTextAreaResizeEvents } from '../textAreaCommon/useDrawingToolTextAreaResizeEvents';
import {
  type DrawingTextAreaSizeProps, type DrawingTextAreaSizeWithText, useDrawingToolTextAreaScalingToggle,
} from '../textAreaCommon/useDrawingToolTextAreaScalingToggle';
import { useDrawingToolTextAreaSize } from '../textAreaCommon/useDrawingToolTextAreaSize';
import { useDrawingToolTextAreaTextChange } from '../textAreaCommon/useDrawingToolTextAreaTextChange';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolCalloutInstanceCalloutContainer } from './drawingToolCalloutInstanceCallout.container';

const useDrawingToolCalloutProps = (instance: DrawingItemCallout) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dispatch = useDispatch();
  const { onMouseOut, onMouseOver } = useDrawingToolItemMouseEvents();

  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const calloutSize = useDrawingToolTextAreaSize({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    fontSize: instance.settings.fontSize,
    strokeWidth: instance.settings.strokeWeight,
    borderRadius: instance.settings.borderRadius,
    ...instance.textBoxDimensions,
  });

  const onClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Callout,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  // when right click is allowed on callout instance then the map context menu is shown
  // in the top left corner of the map because it's missing e.latLng
  const onCalloutRightClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  if (!calloutSize) {
    return null;
  }

  if (calloutSize.width < DRAWING_TEXT_AREA_MIN_WIDTH || calloutSize.height < DRAWING_TEXT_AREA_MIN_HEIGHT) {
    return null;
  }

  return {
    instance,
    calloutSize,
    disabled: !drawingEventsEnabledRef.current,
    selectionDisabled: true,
    zIndex,
    onClick,
    onRightClick: onCalloutRightClick,
    onCalloutMouseOver: onMouseOver,
    onCalloutMouseOut: onMouseOut,
  };
};

const useDrawingToolCalloutSelectedProps = (instance: DrawingItemCallout, etag?: string) => {
  const [startPoint, setStartPoint] = useState(instance.startPoint);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut: onCalloutMouseOut, onMouseOver: onCalloutMouseOver } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();

  const prevEtag = usePrevious(etag);

  useEffect(() => {
    if (etag !== prevEtag) {
      setStartPoint(instance.startPoint);
    }
  }, [etag, instance, prevEtag]);

  const pushNewSnapshot = useCallback((callout: DrawingItemCallout) => {
    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Callout,
      value: {
        ...callout,
      },
    }));
  }, [dispatch]);

  const textAreaSizeProps: DrawingTextAreaSizeProps = useMemo(() => ({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    fontSize: instance.settings.fontSize,
    textAreaToBodyPercentage: DRAWING_CALLOUT_TEXTBOX_PERCENTAGE,
    strokeWidth: instance.settings.strokeWeight,
    borderRadius: instance.settings.borderRadius,
    ...instance.textBoxDimensions,
  }), [instance.settings.borderRadius, instance.settings.fontSize, instance.settings.scalesWithMapZoom,
    instance.settings.strokeWeight, instance.textBoxDimensions]);

  const onSizeOrTextChange = useCallback((props: DrawingTextAreaSizeWithText) =>
    pushNewSnapshot({
      ...instance,
      text: props.text ?? instance.text,
      textBoxDimensions: {
        width: props.width,
        height: props.height,
      },
      settings: {
        ...instance.settings,
        fontSize: props.fontSize,
      },
    })
  , [instance, pushNewSnapshot]);

  const calloutSize = useDrawingToolTextAreaSize(textAreaSizeProps);
  useDrawingToolTextAreaScalingToggle(textAreaSizeProps, onSizeOrTextChange, etag);
  const { onTextChange } = useDrawingToolTextAreaTextChange(textAreaSizeProps, onSizeOrTextChange);
  const resizeEvents = useDrawingToolTextAreaResizeEvents({ ...textAreaSizeProps, ...calloutSize }, onSizeOrTextChange);

  const onCalloutDragEnd = useCallback(() => {
    dispatch(drawingToolItemMoveStopped());
    pushNewSnapshot({
      ...instance,
      startPoint,
    });
  }, [dispatch, instance, pushNewSnapshot, startPoint]);

  const onCalloutDragStart = useCallback(() => {
    dispatch(drawingToolItemMoveStarted());
  }, [dispatch]);

  const onCalloutDragMove = useCallback((latLng: LatLng) => {
    setStartPoint(latLng);
  }, []);

  const onOutlineMouseOver = useCallback((outlineId: string) => {
    setHoveredOutlineId(outlineId);
    dispatch(drawingToolItemHovered());
  }, [dispatch]);

  const onOutlineMouseOut = useCallback(() => {
    setHoveredOutlineId(null);
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  const renderOutline = useCallback((outline: MapOutlinePositionInstance) => {
    switch (outline.position) {
      case MapOutlinePosition.BottomLeft:
        return (
          <MapOutline
            key={outline.id}
            onMouseOver={onOutlineMouseOver}
            onMouseOut={onOutlineMouseOut}
            onDragStart={onCalloutDragStart}
            onDragMove={(_id, position) => onCalloutDragMove(position)}
            onDragEnd={onCalloutDragEnd}
            outline={outline}
            visuals={outline.id === hoveredOutlineId ? outlineHoverVisuals : outlineVisuals}
          />
        );

      case MapOutlinePosition.TopRight:
        return (
          <MapOutline
            key={outline.id}
            onMouseOver={onOutlineMouseOver}
            onMouseOut={onOutlineMouseOut}
            onDragStart={(_id, position) => resizeEvents.onDragStart(position)}
            onDragMove={(_id, position) => resizeEvents.onDragMove(position)}
            onDragEnd={resizeEvents.onDragEnd}
            outline={outline}
            visuals={outline.id === hoveredOutlineId ? outlineHoverVisuals : outlineVisuals}
          />
        );

      default:
        return null;
    }
  }, [onOutlineMouseOver, onOutlineMouseOut, onCalloutDragStart, onCalloutDragEnd,
    hoveredOutlineId, resizeEvents, onCalloutDragMove]);

  // clicking on map clears the selected drawing, this prevents the click event to be passed to the map
  const onClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const renderInstance = useMemo(() => ({
    ...instance,
    startPoint,
  }), [instance, startPoint]);

  return useMemo(() => {
    if (!calloutSize) {
      return null;
    }

    return {
      autoFocusOnInit: true,
      calloutSize,
      etag,
      instance: renderInstance,
      onCalloutMouseOut,
      onCalloutMouseOver,
      onClick,
      onTextChange,
      renderOutline,
      zIndex,
    };
  }, [calloutSize, etag, onCalloutMouseOut, onCalloutMouseOver, onClick, onTextChange, renderInstance,
    renderOutline, zIndex]);
};

type MapCalloutProps = {
  isSelected?: boolean;
  instance: DrawingItemCallout;
  etag?: string;
};

const DrawingToolCalloutInstance: FC<MapCalloutProps> = ({ instance, isSelected, etag }) => {
  const calloutProps = useDrawingToolCalloutProps(instance);
  const selectedCalloutProps = useDrawingToolCalloutSelectedProps(instance, etag);
  const props = isSelected ? selectedCalloutProps : calloutProps;

  if (!props) {
    return null;
  }

  return (
    <DrawingToolCalloutInstanceCalloutContainer
      {...props}
    />
  );
};

const pureComponent = memo(DrawingToolCalloutInstance);
export { pureComponent as DrawingToolCallout };
