import { css } from '@emotion/react';
import {
  type FC,
  useMemo,
} from 'react';
import { AlternatingStripesComponent } from '~/_shared/baseComponents/alternatingStripes/alternatingStripes';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { type MetricsResults } from '../../utils/metric/useMetricData.helpers';
import { MetricsListItemComponent } from './item/metricsListItem.component';
import { groupMetricsDataByColumn } from './metricsList.helpers';

const rootStyle = ({ theme }: ThemeProps) => css({
  width: '100%',
  color: theme.textColors.primary,
});

const metricsWrapperStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:last-of-type': {
    border: 'none',
  },
});

type MetricsListProps = Readonly<{
  metrics: MetricsResults;
  boundaryIsUnderWmsTrial?: boolean;
}>;

export const MetricsListComponent: FC<MetricsListProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [firstTwoMetricsDataGroupedByColumn, restOfMetricsDataGroupedByColumn] = useMemo(() => {
    const groupedMetrics = groupMetricsDataByColumn(props.metrics.spreadsheetColumnMetrics, t);
    return [groupedMetrics.slice(0, 2), groupedMetrics.slice(2)];
  }, [props.metrics.spreadsheetColumnMetrics, t]);

  return (
    <div
      css={rootStyle({ theme })}
    >
      <AlternatingStripesComponent css={metricsWrapperStyle({ theme })}>
        {props.metrics.demographicMetrics.length > 0 && (
          props.metrics.demographicMetrics.map((metric, index) => (
            <MetricsListItemComponent
              key={index}
              name={metric.name}
              single={{
                ...metric,
              }}
              showApproximateSymbol
              blurMetrics={props.boundaryIsUnderWmsTrial}
            />
          ))
        )}

        {firstTwoMetricsDataGroupedByColumn.map((metric) => (
          <MetricsListItemComponent
            key={metric.name + metric.subheader}
            {...metric}
          />
        ))}
        {restOfMetricsDataGroupedByColumn.map((metric) => (
          <MetricsListItemComponent
            key={metric.name + metric.subheader}
            blurMetrics={props.boundaryIsUnderWmsTrial}
            {...metric}
          />
        ))}
      </AlternatingStripesComponent>
    </div>
  );
};
