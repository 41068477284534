import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type FC, useEffect, useMemo,
} from 'react';
import { UniqueIdentifierRadioGroupComponent } from '~/_shared/components/uniqueIdentifierSelector/uniqueIdentifierRadioGroup.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type UniqueIdentifierData } from '../useManageUniqueIdentifierSelect.hook';
import { type DataSource } from './dataSource';

const noteStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  fontWeight: 500,
  lineHeight: 1.5,
  fontSize: '16px',
  marginBottom: 35,
  display: 'block',
});

type DataSourceSectionProps = Readonly<{
  dataSource: DataSource;
  radioLabelStyle?: SerializedStyles;
  radioTextStyle?: SerializedStyles;
  uniqueIdentifierData: UniqueIdentifierData;
}>;

export const DataSourceSectionComponent: FC<DataSourceSectionProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();

  const columnOptions = useMemo(() => {
    const header = props.dataSource.header;
    if (!header) {
      return [];
    }

    return header.map((h, i) => ({ name: h.name, value: i }));
  }, [props.dataSource.header]);

  useEffect(() => {
    const dataSourceFetch = props.dataSource.fetch;
    if (dataSourceFetch && !props.dataSource.header) {
      dataSourceFetch();
    }
  }, [props.dataSource.fetch, props.dataSource.header]);

  return (
    <>
      <strong css={noteStyle({ theme })}>{t('note.include.column.headers')}</strong>
      <UniqueIdentifierRadioGroupComponent
        columnOptions={columnOptions}
        radioLabelStyle={props.radioLabelStyle}
        radioTextStyle={props.radioTextStyle}
        uniqueIdentifierData={props.uniqueIdentifierData}
      />
    </>
  );
};
