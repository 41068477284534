import { useCallback } from 'react';
import { type StackedMarker } from '../manager/mapMarkerManager';
import { useMoveMarkerLabels } from '../useMoveMarkerLabels/useMoveMarkerLabels';
import { useMoveMarkers } from '../useMoveMarkers/useMoveMarkers';
import { type StackedMarkerId } from '../useStacksAndClusters/StackedMarkerId.type';
import {
  type MapMarkerEventCallback, type MarkerEventCallbacks,
} from './markerEventCallbacks.type';

export const useMarkerDragAndDrop = () => {
  const { moveMarkersActive, moveMarkerCallbacks, moveMarkersReplaceMarkerPositions } = useMoveMarkers();
  const { moveMarkerLabelsActive, moveMarkerLabelCallbacks } = useMoveMarkerLabels();

  const wrapWithDnDEventHandlers = useCallback((markerEventCallbacks: MarkerEventCallbacks) => {
    return {
      onClick: markerEventCallbacks.onClick,
      onRightClick: markerEventCallbacks.onRightClick,
      onMouseOver: (...args: Parameters<MapMarkerEventCallback>) => {
        if (moveMarkersActive) {
          moveMarkerCallbacks.onMouseOver(...args);
        }

        if (moveMarkerLabelsActive) {
          moveMarkerLabelCallbacks.onMouseOver(...args);
        }

        markerEventCallbacks.onMouseOver(...args);
      },
      onMouseOut: (...args: Parameters<MapMarkerEventCallback>) => {
        if (moveMarkersActive) {
          moveMarkerCallbacks.onMouseOut(...args);
        }

        if (moveMarkerLabelsActive) {
          moveMarkerLabelCallbacks.onMouseOut(...args);
        }

        markerEventCallbacks.onMouseOut(...args);
      },
    };
  }, [moveMarkerCallbacks, moveMarkerLabelCallbacks, moveMarkerLabelsActive, moveMarkersActive]);

  const applyTemporaryDnDMarkerChanges = useCallback((originalMarkers: ReadonlyMap<StackedMarkerId, StackedMarker>) => {
    let result = originalMarkers;
    if (moveMarkersActive) {
      result = moveMarkersReplaceMarkerPositions(result);
    }

    return result;
  }, [moveMarkersActive, moveMarkersReplaceMarkerPositions]);

  return {
    markerDnDActive: moveMarkersActive || moveMarkerLabelsActive,
    wrapWithDnDEventHandlers,
    applyTemporaryDnDMarkerChanges,
  };
};
