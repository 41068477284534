import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type BoundaryGroupDemographic } from '../../../store/boundaryGroups/boundaryGroups.repository';
import { type TranslationFnc } from '../../utils/hooks';
import { MetricsDataAction } from '../../utils/metric/metrics.types';

export const getDataActionOptions = (t: TranslationFnc): DropdownOption<MetricsDataAction>[] => ([{
  name: t('Sum (Total)').toUpperCase(),
  value: MetricsDataAction.SUM,
}, {
  name: t('Average').toUpperCase(),
  value: MetricsDataAction.AVERAGE,
}, {
  name: t('Group Count').toUpperCase(),
  value: MetricsDataAction.GROUP_COUNT,
}, {
  name: t('High Value').toUpperCase(),
  value: MetricsDataAction.HIGH_VALUE,
}, {
  name: t('Low Value').toUpperCase(),
  value: MetricsDataAction.LOW_VALUE,
}]);

export const requiresNumericalColumn = (action: MetricsDataAction) => {
  return action !== MetricsDataAction.GROUP_COUNT;
};

export const getDataActionShortLabels = (t: TranslationFnc): {[key in MetricsDataAction]: string} => ({
  [MetricsDataAction.AVERAGE]: t('Average').toUpperCase(),
  [MetricsDataAction.GROUP_COUNT]: t('Group Count').toUpperCase(),
  [MetricsDataAction.HIGH_VALUE]: t('High').toUpperCase(),
  [MetricsDataAction.LOW_VALUE]: t('Low').toUpperCase(),
  [MetricsDataAction.SUM]: t('Total').toUpperCase(),
});

export const getDemographicCategoryDropdownOptions = (demographicData: ReadonlyArray<BoundaryGroupDemographic>): DropdownOption<string>[] => {
  const sortedDemographicData = [...demographicData].sort((a, b) => {
    const aCategoryName = a.category.toLowerCase();
    const bCategoryName = b.category.toLowerCase();

    if (aCategoryName < bCategoryName) {
      return -1;
    }
    if (aCategoryName > bCategoryName) {
      return 1;
    }

    return 0;
  });

  const results: DropdownOption<string>[] = [];

  for (const data of sortedDemographicData) {
    if (results[results.length - 1]?.value.toLowerCase() !== data.category.toLowerCase()) {
      results.push({
        name: data.category,
        value: data.category.toLowerCase(),
      });
    }
  }

  return results;
};

const getDemographicNamesForCategoryName = (
  demographicData: ReadonlyArray<BoundaryGroupDemographic>,
  demographicCategory: string | null
): BoundaryGroupDemographic[] =>
  demographicData.filter(data => data.category?.toLowerCase() === demographicCategory?.toLowerCase());

export const getDemographicDropdownOptions = (
  demographics: ReadonlyArray<BoundaryGroupDemographic>,
  selectedDemographicCategory: string | null
): DropdownOption<string>[] => {
  return getDemographicNamesForCategoryName(demographics, selectedDemographicCategory)
    .map(item => ({ name: item.name, value: item.id }));
};
