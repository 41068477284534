import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { sortRowValuesByColumns } from '~/data/table/sortRowValuesByColumns';
import { type EditDataTableRow } from '../../_shared/components/editDataTable/editDataTable.component';
import { copyToClipboard } from '../../_shared/utils/clipboard/clipboard.helpers';
import { convertLocationsToTsv } from '../../_shared/utils/geolocation/geolocation.helpers';
import { useTimeout } from '../../_shared/utils/hooks/useTimeout';
import { type ModalProps } from '../../modal/modalType.enum';
import {
  type SpreadsheetTableDataUpdateItemServerModel,
  type SpreadsheetTableDataUpdateRequest,
} from '../../spreadsheet/spreadsheet.repository';
import { errorPageSetError } from '../../store/errorPage/errorPage.actionCreators';
import { ErrorPageType } from '../../store/errorPage/errorPage.state';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetId } from '../../store/selectors/usePrimarySpreadsheetId';
import {
  getSpreadsheetDataRowChanges, type SpreadsheetDataRowChange,
} from '../data.helpers';
import { useRemoveSpreadsheetData } from '../useRemoveSpreadheetData';
import { useSpreadsheetDataRawColumns } from '../useSpreadsheetDataRawColumns';
import { useUpdateSpreadsheetData } from '../useUpdateSpreadheetData';
import { BadDataModalComponent } from './badDataModal.component';
import { useSpreadsheetExportedData } from './useSpreadsheetExportedData';

export type DataTableModalProps = ModalProps;

const createUpdateParams = (changes: SpreadsheetDataRowChange[]): SpreadsheetTableDataUpdateItemServerModel[] =>
  changes.map(({ rowId, ...change }) => ({
    ...change,
    row_id: rowId,
  } satisfies SpreadsheetTableDataUpdateItemServerModel));

export const BadDataModalContainer: FC<DataTableModalProps> = (props) => {
  const clientId = useClientIdSelector();
  const spreadsheetId = usePrimarySpreadsheetId();
  const dispatch = useDispatch();
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { isLoading: isSpreadsheetDataRemoveLoading, removeSpreadsheetData } = useRemoveSpreadsheetData();
  const { isLoading: isSpreadsheetUpdating, updateSpreadsheetData, isError } = useUpdateSpreadsheetData();
  const { onClose } = props;

  const { getExportedData, isLoading: isDataLoading, data: spreadsheetDataRows,
    onDataChange, initialData: initialSpreadsheetDataRows } = useSpreadsheetExportedData();

  const { isLoading: isColumnsDataLoading, columns } = useSpreadsheetDataRawColumns({
    spreadsheetId,
  });

  useEffect(() => {
    if (isError) {
      dispatch(errorPageSetError(ErrorPageType.General));
    }
  }, [dispatch, isError]);

  const sortedSpreadSheetData = useMemo(() =>
    spreadsheetDataRows.map((row) => sortRowValuesByColumns(row, columns)),
  [columns, spreadsheetDataRows]
  );

  const onCopyToClipboardClick = useCallback(() => {
    const dataRows = sortedSpreadSheetData.reduce<EditDataTableRow[][]>((acc, item) => {
      const row: EditDataTableRow[] = item.values.map((item) => {
        const label = columns.find((col) => col.id === item.columnId)?.name ?? '';
        return ({
          label,
          value: item.value ? item.value.toString() : '',
        });
      });

      acc.push(row);
      return acc;
    }, []);

    const results = convertLocationsToTsv(dataRows);

    copyToClipboard(results);
    showCopiedToClipboardMessage();
  }, [columns, showCopiedToClipboardMessage, sortedSpreadSheetData]);

  const discardUnmappedRows = useCallback(() => {
    if (!clientId || !spreadsheetId) {
      return;
    }

    removeSpreadsheetData(clientId, spreadsheetId, {
      rows: [],
      remove_bad_data: true,
    }, onClose);
  }, [removeSpreadsheetData, clientId, spreadsheetId, onClose]);

  const remapDataRows = useCallback(() => {
    const changes = getSpreadsheetDataRowChanges(initialSpreadsheetDataRows ?? [], sortedSpreadSheetData);

    if (changes.length === 0 || !clientId || !spreadsheetId) {
      return;
    }

    const params = createUpdateParams(changes);

    if (params.length === 0) {
      return;
    }

    const updateRequest: SpreadsheetTableDataUpdateRequest = {
      params,
    };

    updateSpreadsheetData(clientId, spreadsheetId, updateRequest, () => {
      onClose();
    });
  }, [sortedSpreadSheetData, initialSpreadsheetDataRows, updateSpreadsheetData, onClose, clientId, spreadsheetId]);

  useEffect(() => {
    if (!spreadsheetId) {
      return;
    }

    getExportedData(spreadsheetId, {
      select: 'data',
      extension: 'json',
      failed_geocoding: true,
      autoincrement: true,
    });
  }, [spreadsheetId, getExportedData]);

  return (
    <BadDataModalComponent
      {...props}
      spreadsheetDataRows={sortedSpreadSheetData}
      onCopyToClipboardClick={onCopyToClipboardClick}
      showCopyToClipboardTooltip={isCopiedToClipboard}
      columnWidthsLocalStorageKey={spreadsheetId ? `data-table-raw-custom-columns-width|spreadsheet-${spreadsheetId}` : ''}
      isLoading={isDataLoading || isColumnsDataLoading || isSpreadsheetDataRemoveLoading || isSpreadsheetUpdating}
      columns={columns}
      onDataChange={onDataChange}
      onDiscardUnmappedRowsClick={discardUnmappedRows}
      onRemapDataRowsClick={remapDataRows}
      isRemapButtonDisabled={getSpreadsheetDataRowChanges(initialSpreadsheetDataRows ?? [], sortedSpreadSheetData).length === 0}
    />
  );
};
