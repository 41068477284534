import { faFileDownload } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ExportDataSection } from '../../../../data/exportDataModal/exportDataSection.enum';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideExportDataViewMenuItem: FC = () => {
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.ExportSpreadsheetData);

  return (
    <RightSideMapToolsMenuItem
      icon={faFileDownload}
      active={false}
      onClick={() => openModal({
        availableExportSections: [ExportDataSection.All, ExportDataSection.Filtered, ExportDataSection.OnlyVisible],
      })}
      tooltip={t('Export Data')}
    />
  );
};
