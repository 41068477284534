export const getColumnWidths = (tableRow: HTMLElement): number[] => {
  const widths = [];

  const columns = Array.from(tableRow.querySelectorAll('th'));

  for (const column of columns) {
    widths.push(column.getBoundingClientRect().width);
  }

  return widths;
};
