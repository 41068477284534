import { InputSize } from './textInput.component';

export const getTextInputHeight = (size: InputSize): number => {
  switch (size) {
    case InputSize.Xs:
      return 26;
    case InputSize.Small:
      return 32;
    case InputSize.Medium:
      return 40;
    case InputSize.Large:
      return 50;
    default:
      return 50;
  }
};

export const getTextInputIconFontSize = (size: InputSize): string => {
  switch (size) {
    case InputSize.Xs:
    case InputSize.Small:
      return '14px';
    case InputSize.Medium:
      return '16px';
    case InputSize.Large:
      return '18px';
    default:
      return '18px';
  }
};
