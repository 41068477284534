import { useMemo } from 'react';
import { useActiveBoundariesStatusBarItems } from './useActiveBoundariesStatusBarItems';
import { useActiveFilterStatusBarItems } from './useActiveFilterStatusBarItems';
import { useActiveGroupingColumnsStatusBarItems } from './useActiveGroupingColumnsStatusBarItems';
import { useDirectionsStatusBarItems } from './useDirectionsStatusBarItems';
import { useDrawingStatusBarItems } from './useDrawingStatusBarItems';
import { useHeatmapStatusBarItems } from './useHeatmapStatusBarItems';
import { useMarkersVisibilityStatusBarItem } from './useMarkersVisibilityStatusBarItem';
import { useProximityStatusBarItems } from './useProximityStatusBarItems';

export const useMapStatusBarItems = () => {
  const markersVisibilityItems = useMarkersVisibilityStatusBarItem();
  const activeFiltersItems = useActiveFilterStatusBarItems();
  const activeGroupingColumnsItems = useActiveGroupingColumnsStatusBarItems();
  const proximityItems = useProximityStatusBarItems();
  const directionItems = useDirectionsStatusBarItems();
  const { heatmapItems, heatmapMarkerFilterItems } = useHeatmapStatusBarItems();
  const activeBoundariesItems = useActiveBoundariesStatusBarItems();
  const drawingItems = useDrawingStatusBarItems();

  const items = markersVisibilityItems
    .concat(drawingItems)
    .concat(activeGroupingColumnsItems)
    .concat(activeFiltersItems)
    .concat(heatmapMarkerFilterItems)
    .concat(proximityItems)
    .concat(directionItems)
    .concat(heatmapItems)
    .concat(activeBoundariesItems);

  return useMemo(() => ({
    items,
    // There's always markers visibility item. Don't show status bar if only markers visibility is active
    isAnyRemovableItem: items.length > 1,
  }), [items]);
};
