import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RoundButtonComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  LoaderComponent, ProgressBarComponent,
} from '~/_shared/baseComponents/loaders';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryCreateOptimizedStatus } from '../../../../store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '../../../baseComponents/lottieAnimation';
import { getOptimizedTerritoriesProgressForStatus } from './optimizedTerritoriesProgressBar.helpers';
import { OptimizedTerritoriesTextComponent } from './optimizedTerritoriesText.component';

export type OptimizedTerritoriesProgressBarComponentProps = Readonly<{
  onCancel: () => void;
  onProcessFrozen: () => void;
  status: BoundaryCreateOptimizedStatus;
  className?: string;
}>;

const rootStyle = css({
  display: 'flex',
  width: '100%',
});

const progressContainerStyle = css({
  margin: '0 8px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

const progressStyle = css({
  margin: '2px 0',
});

const progressBarWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const locationPinAnimationStyle = css({
  flexShrink: 0,
  marginLeft: 20,
  marginRight: 44,
  width: 34,
});

const linkButtonStyle = ({ theme }: ThemeProps) => css({
  border: 'none',
  color: theme.textColors.link,
  padding: 0,
  background: 'transparent',
  '&:hover': {
    border: 'none',
    background: 'transparent',
    textDecoration: 'underline',
  },
});

const textStyle = css({
  fontSize: 14,
  textAlign: 'center',
});

export const OptimizedTerritoriesProgressBarComponent = ({ onCancel, ...props }: OptimizedTerritoriesProgressBarComponentProps) => {
  const theme = useTheme();
  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors();

  const [isCancelling, setIsCancelling] = useState(false);
  const onCancelClick = useCallback(() => {
    setIsCancelling(true);
    onCancel();
  }, [onCancel]);
  return (
    <div
      css={rootStyle}
      className={props.className}
    >
      <div css={progressContainerStyle}>
        <div css={progressBarWrapperStyle}>
          <OptimizedTerritoriesTextComponent status={props.status} />
          <ProgressBarComponent
            progress={getOptimizedTerritoriesProgressForStatus(props.status)}
            css={progressStyle}
          />

          <span css={textStyle}>
            <ButtonComponent
              text={t('Click here')}
              css={linkButtonStyle({ theme })}
              onClick={props.onProcessFrozen}
            /> {t('if it looks like the process is frozen')}
          </span>
        </div>

        <LottieAnimationComponent
          css={locationPinAnimationStyle}
          size={46}
          type={LottieAnimationTypes.LocationPin3D}
          colors={animationColors.LocationPin3D}
          autoplay
          loop
          speed={2.5}
        />
        {!isCancelling ? (
          <RoundButtonComponent
            icon={faTrash}
            buttonStyle={RoundButtonStyle.DangerInverted}
            tooltipLabel={t('Cancel')}
            onClick={onCancelClick}
          />
        ) : (
          <div css={{ width: 40 }}>
            <LoaderComponent
              size={40}
              color={theme.iconColors.danger}
            />
          </div>
        )}
      </div>
    </div>
  );
};
