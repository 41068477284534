import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type Theme } from '../../_shared/themes/theme.model';

type HeadingProps = Readonly<{
  accountName?: string;
  mapName?: string;
  mapLink?: string;
  mapViewName?: string;
}>;

const containerStyle = (props: {theme: Theme}) => css({
  color: props.theme.textColors.primary,
  height: '100%',
  fontWeight: 'normal',
  fontSize: '16px',
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap-reverse',
  whiteSpace: 'nowrap',
  margin: '0 10px',
  justifyContent: 'center',
  overflow: 'hidden',
});

const headingStyle = (props: ThemeProps) => css({
  color: props.theme.textColors.secondary,
  fontWeight: 500,
  height: '100%',
  alignItems: 'center',
  display: 'flex',
  '&:after': {
    content: '"\\00a0/\\00a0"',
  },
  '&:first-of-type': {
    color: props.theme.textColors.primary,
    '&:after': {
      display: 'none',
    },
  },
});

const ellipsisStyle = css({
  textOverflow: 'ellipsis',
  flex: '1 0 0',
  overflow: 'hidden',
});

const mapLinkStyle = css({
  fontSize: 'inherit',
  color: 'inherit',
  '&:hover': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export const HeadingComponent: FC<HeadingProps> = (props) => {
  const theme = useTheme();

  const headings = [];

  if (props.accountName) {
    const AccountNameHeading = (
      <span
        key="account-name"
        css={headingStyle({ theme })}
      >
        <span css={ellipsisStyle}>{props.accountName}</span>
      </span>
    );

    headings.push(AccountNameHeading);
  }

  if (props.mapName) {
    const MapNameHeading = (
      <span
        key="map-name"
        css={headingStyle({ theme })}
      >
        {!!props.mapLink && (
          <LinkComponent
            path={props.mapLink}
            css={[ellipsisStyle, mapLinkStyle]}
          >
            {props.mapName}
          </LinkComponent>
        )}

        {!props.mapLink && (
          <span css={ellipsisStyle}>{props.mapName}</span>
        )}
      </span>
    );

    headings.push(MapNameHeading);
  }

  if (props.mapViewName) {
    const MapViewNameHeading = (
      <span
        key="map-view-name"
        css={headingStyle({ theme })}
      >
        <span css={ellipsisStyle}>{props.mapViewName}</span>
      </span>
    );

    headings.push(MapViewNameHeading);
  }

  return (
    <h2 css={containerStyle({ theme })}>
      {/* Headings use wrap when there isn't enough space and only one line is visible. */}
      {/* When wrapping we want to start from the left by hiding the least important heading. */}
      {/* Reversing the rendering order will ensure the first heading is wrapped first. */}
      {(headings.reverse())}
    </h2>
  );
};
