import { type FC } from 'react';
import { RegisterFormComponent } from './registerForm.component';
import { RegisterFormSuccessComponent } from './registerFormSuccess.component';
import { useUserRegister } from './useUserRegister';
import { useUserRegisterFormMessages } from './useUserRegisterMessages';

export const RegisterFormContainer: FC = () => {
  const { registerUser, isLoading } = useUserRegister();
  const { onRegisterSubmit, errors, errorMessage, showSuccessMessage, closeErrorMessage, closeSuccessMessage } = useUserRegisterFormMessages(registerUser);

  if (showSuccessMessage) {
    return (
      <RegisterFormSuccessComponent onClose={closeSuccessMessage} />
    );
  }

  return (
    <RegisterFormComponent
      isLoading={isLoading}
      onSubmit={onRegisterSubmit}
      errors={errors}
      errorMessage={errorMessage}
      closeErrorMessage={closeErrorMessage}
    />
  );

};
