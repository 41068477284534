import {
  createTemplateLayers, TemplateLayer,
} from '~/map/map/markers/manager/mapMarkerTemplates';
import {
  type FileMap, type FileUrl,
} from '~/store/frontendState/fileUrls/fileUrls.state';
import { type MapSettingsFileAttachmentsMap } from '~/store/mapSettings/fileAttachments/fileAttachments.state';
import {
  DEFAULT_MARKER_STYLE, type MarkerSets,
} from '../../../store/frontendState/markerSets/markerSets.selectors';
import {
  type CustomMarkerStyle, type MarkerAnchorPosition, type MarkerEntityStyle, MarkerSegment, type MarkerSegments,
  MarkerStyleType, type StandardMarkerSettings, type StandardMarkerStyle, type StandardMarkerVisualSettings,
} from '../../types/marker.types';
import { type MarkerSettings } from '../../types/markers/visualSettings.types';
import { INITIAL_LABEL } from './markerVisualSettings.constants';

export const getMarkerEntityStyle = (props: {
  fallbackMarkerSettings: StandardMarkerVisualSettings;
  fileAttachments: MapSettingsFileAttachmentsMap;
  fileMap: FileMap;
  getMarkerTextureAnchor: (fileAttachmentId: string) => MarkerAnchorPosition;
  markerSets: MarkerSets;
  markerSettings: MarkerSettings;
  noShadow?: boolean;
  markerLabelsDisabled: boolean;
}): MarkerEntityStyle => {

  const {
    fallbackMarkerSettings, fileAttachments, fileMap, markerSettings, markerSets, noShadow, getMarkerTextureAnchor, markerLabelsDisabled,
  } = props;

  let mainMarkerStyle: StandardMarkerStyle | CustomMarkerStyle | null;

  if (!markerSettings.marker || markerSettings.marker.styleType === MarkerStyleType.STANDARD) {
    const style = markerSettings.marker?.styleId !== undefined ? markerSets[markerSettings.marker?.styleId] : undefined;
    mainMarkerStyle = style ? { ...style } : null;
  }
  else {
    const markerAttachment = markerSettings.marker?.styleType === MarkerStyleType.CUSTOM
      ? fileAttachments.get(markerSettings.marker?.fileAttachmentId) ?? null
      : null;

    const file = markerAttachment && fileMap.get(markerAttachment.fileId);
    mainMarkerStyle = file ? createCustomMarkerStyle(markerAttachment.fileId, file, getMarkerTextureAnchor(markerAttachment.id)) : null;
  }
  if (markerSettings.useMarker || (markerLabelsDisabled && !markerSettings.label?.useLabel)) {
    return {
      ...(mainMarkerStyle ? mainMarkerStyle : markerSets[fallbackMarkerSettings.styleId] ?? DEFAULT_MARKER_STYLE),
      size: mainMarkerStyle && markerSettings.marker?.size ? markerSettings.marker.size : fallbackMarkerSettings.size,
      selectedColor: mainMarkerStyle && markerSettings.marker?.selectedColor ?
        markerSettings.marker?.selectedColor : fallbackMarkerSettings.selectedColor,
      opacity: markerSettings.marker?.opacity ?? fallbackMarkerSettings.opacity,
      segments: mainMarkerStyle?.markerType === MarkerStyleType.STANDARD
        ? createMarkerSegmentsFromStandardMarkerSettings(mainMarkerStyle.marker, noShadow)
        : { [MarkerSegment.MAIN]: createTemplateLayers(TemplateLayer.MAIN) },
    };
  }
  else {
    return {
      ...markerSettings.label ?? INITIAL_LABEL,
      type: 'label',
      offsetProps: {
        type: 'default',
      },
    };
  }
};

export const createCustomMarkerStyle = (fileId: number, attachment: FileUrl, anchor: MarkerAnchorPosition): CustomMarkerStyle => ({
  type: 'marker',
  markerType: MarkerStyleType.CUSTOM,
  marker: {
    fileId,
    anchor,
    dimensions: attachment['200p'].resolution,
    path: attachment['200p'].url,
  },
});

export const createMarkerSegmentsFromStandardMarkerSettings = (
  standardMarkerSettings: StandardMarkerSettings, noShadow?: boolean
): MarkerSegments => ({
  [MarkerSegment.MAIN]: {
    main: TemplateLayer.MAIN,
    background: TemplateLayer.BACKGROUND,
    shadow: null,
  },
  ...(!!standardMarkerSettings.spritesheetSettings.layerOne && !noShadow ? {
    [MarkerSegment.SHADOW]: {
      main: TemplateLayer.SHADOW,
      background: null,
      shadow: null,
    },
  } : {}),
});
