import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { type LatLng } from '../../../../_shared/types/latLng';

const zeroOffest = { offsetX: 0, offsetY: 0 };

export type RectangleStartEndPoints = {
  startPoint: LatLng;
  endPoint: LatLng;
};

const secondaryOutlinePositions: ReadonlyArray<MapOutlinePosition> = [
  MapOutlinePosition.Top, MapOutlinePosition.Right,
  MapOutlinePosition.Bottom, MapOutlinePosition.Left,
];

export const isRectangleOutlinePositionPrimary = (position: MapOutlinePosition): boolean => {
  return !secondaryOutlinePositions.includes(position);
};

export const getOutlinesForRectangleStartEndPoints = (
  startPoint: LatLng, endPoint: LatLng, instanceId: Uuid
): MapObjectOutlineInstance[] => {
  const latMin = Math.min(startPoint.lat, endPoint.lat);
  const latMax = Math.max(startPoint.lat, endPoint.lat);
  const lngMin = Math.min(startPoint.lng, endPoint.lng);
  const lngMax = Math.max(startPoint.lng, endPoint.lng);

  return [{
    id: instanceId + 'topLeft',
    lat: latMax,
    lng: lngMin,
  }, {
    id: instanceId + 'bottomLeft',
    lat: latMin,
    lng: lngMin,
  }, {
    id: instanceId + 'bottomRight',
    lat: latMin,
    lng: lngMax,
  }, {
    id: instanceId + 'topRight',
    lat: latMax,
    lng: lngMax,
  }];
};

// calculate resize controller positions to be in the middle of the outline
export const getResizeControllerOutlinesForRectangleStartEndPoints = (
  startPoint: LatLng, endPoint: LatLng, instanceId: Uuid
): MapOutlinePositionInstance[] => {
  const latMin = Math.min(startPoint.lat, endPoint.lat);
  const latMax = Math.max(startPoint.lat, endPoint.lat);
  const lngMin = Math.min(startPoint.lng, endPoint.lng);
  const lngMax = Math.max(startPoint.lng, endPoint.lng);

  const latMiddle = (latMin + latMax) / 2;
  const lngMiddle = (lngMin + lngMax) / 2;

  return [{
    id: instanceId + 'top',
    position: MapOutlinePosition.Top,
    ...zeroOffest,
    lat: latMax,
    lng: lngMiddle,
  }, {
    id: instanceId + 'left',
    position: MapOutlinePosition.Left,
    ...zeroOffest,
    lat: latMiddle,
    lng: lngMin,
  }, {
    id: instanceId + 'bottom',
    position: MapOutlinePosition.Bottom,
    ...zeroOffest,
    lat: latMin,
    lng: lngMiddle,
  }, {
    id: instanceId + 'right',
    position: MapOutlinePosition.Right,
    ...zeroOffest,
    lat: latMiddle,
    lng: lngMax,
  }, {
    id: instanceId + 'topLeft',
    position: MapOutlinePosition.TopLeft,
    ...zeroOffest,
    lat: latMax,
    lng: lngMin,
  }, {
    id: instanceId + 'topRight',
    position: MapOutlinePosition.TopRight,
    ...zeroOffest,
    lat: latMax,
    lng: lngMax,
  }, {
    id: instanceId + 'bottomLeft',
    position: MapOutlinePosition.BottomLeft,
    ...zeroOffest,
    lat: latMin,
    lng: lngMin,
  }, {
    id: instanceId + 'bottomRight',
    position: MapOutlinePosition.BottomRight,
    ...zeroOffest,
    lat: latMin,
    lng: lngMax,
  }];
};

// reset startPoint to be top-left and endPoint to be bottom-right
export const resetRectangleStartEndPoint = (
  startPoint: LatLng, endPoint: LatLng
): RectangleStartEndPoints => {
  const latMin = Math.min(startPoint.lat, endPoint.lat);
  const latMax = Math.max(startPoint.lat, endPoint.lat);
  const lngMin = Math.min(startPoint.lng, endPoint.lng);
  const lngMax = Math.max(startPoint.lng, endPoint.lng);

  return {
    startPoint: {
      lat: latMax,
      lng: lngMin,
    },
    endPoint: {
      lat: latMin,
      lng: lngMax,
    },
  };
};
