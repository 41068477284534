import { initialLabelVisualizerBodyProps } from '~/_shared/components/labelVisualizer/labelVisualizer.constants';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { type MarkerVisualSettingsColor } from '../../_shared/types/marker.types';
import { type PartialTwoLevels } from '../../_shared/utils/types/deepPartial.type';
import { LabelSettingsOptions } from './labelSettings.enums';
import { type LabelSettingsData } from './labelSettings.types';

const MAX_FONT_SIZE = 72;
const MIN_FONT_SIZE = 5;
const MIN_PADDING = 0;
const MAX_PADDING_SIDES = 100;
const MAX_PADDING_TOP_BOTTOM = 50;

export const convertLabelVisualSettingsToData = (
  visualSettings: LabelVisualSetting,
  applyChange: (current: LabelVisualSetting, update: PartialTwoLevels<LabelVisualSetting>) => void
): LabelSettingsData => ({
  colors: {
    BorderColor: {
      caption: LabelSettingsOptions.BorderColor,
      setter: (color: MarkerVisualSettingsColor) => applyChange(visualSettings, { borderProps: { color } }),
      value: {
        selectedColor: visualSettings.borderProps.color.selectedColor,
        opacity: visualSettings.borderProps.color.opacity,
      },
    },
    BackgroundColor: {
      caption: LabelSettingsOptions.BackgroundColor,
      setter: (color: MarkerVisualSettingsColor) => applyChange(visualSettings, { bodyProps: { backgroundColor: {
        selectedColor: color.selectedColor,
        opacity: visualSettings.bodyProps.backgroundColor.opacity,
      } } }),
      value: {
        selectedColor: visualSettings.bodyProps.backgroundColor.selectedColor,
        opacity: visualSettings.bodyProps.backgroundColor.opacity,
      },
    },
    FontColor: {
      caption: LabelSettingsOptions.FontColor,
      setter: (color: MarkerVisualSettingsColor) => applyChange(visualSettings, { bodyProps: { fontColor: color } }),
      value:
      {
        selectedColor: visualSettings.bodyProps.fontColor.selectedColor,
        opacity: visualSettings.bodyProps.fontColor.opacity,
      },
    },
  },
  sliders: {
    FontSize: {
      caption: LabelSettingsOptions.FontSize,
      setter: (fontSize: number) => applyChange(visualSettings, { bodyProps: { fontSize } }),
      value: visualSettings.bodyProps.fontSize,
      options: {
        min: MIN_FONT_SIZE,
        max: MAX_FONT_SIZE,
      },
    },
    BackgroundOpacity: {
      caption: LabelSettingsOptions.BackgroundOpacity,
      setter: (opacity: number) => applyChange(visualSettings, { bodyProps: { backgroundColor: {
        selectedColor: visualSettings.bodyProps.backgroundColor.selectedColor, opacity: opacity / 100,
      } } }),
      value: (visualSettings.bodyProps.backgroundColor.opacity ?? 1) * 100,
      options: {
        min: 0,
        max: 100,
      },
    },
    PaddingHorizontal: {
      caption: LabelSettingsOptions.PaddingHorizontal,
      setter: (newValue: number) => applyChange(visualSettings, { bodyProps: { padding: {
        ...visualSettings.bodyProps.padding,
        left: newValue / 100,
        right: newValue / 100,
      } } }),
      value: (visualSettings.bodyProps.padding.left ?? initialLabelVisualizerBodyProps.padding.left) * 100,
      options: {
        min: MIN_PADDING,
        max: MAX_PADDING_SIDES,
      },
      suffix: '%',
    },
    PaddingVertical: {
      caption: LabelSettingsOptions.PaddingVertical,
      setter: (newValue: number) => applyChange(visualSettings, { bodyProps: { padding: {
        ...visualSettings.bodyProps.padding,
        top: newValue / 100,
        bottom: newValue / 100,
      } } }),
      value: (visualSettings.bodyProps.padding.top ?? initialLabelVisualizerBodyProps.padding.top) * 100,
      options: {
        min: MIN_PADDING,
        max: MAX_PADDING_TOP_BOTTOM,
      },
      suffix: '%',
    },
  },
});
