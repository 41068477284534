import { faMapPlus } from '@awesome.me/kit-77e1f5874f/icons/kit/custom';
import {
  faImages, faTools, faUndo,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { CONFIG } from '~/_shared/constants/config';
import { ExpiredSubscriptionBlocker } from '~/_shared/types/client/subscriptionBlocker.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { MAX_INT32 } from '~/_shared/utils/number/number.helpers';
import { MapDropdownContainer } from '~/map/dropdown/mapDropdown.container';
import { PresentationalMapDropdownContainer } from '~/map/dropdown/presentationalMapDropdown.container';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import {
  buttonStyle, containerStyle, headingSectionStyle, purchaseButtonStyle, rightSectionStyle, sectionStyle, toolIconStyle,
  toolsIconSpaceStyle, undoButtonWrapperStyle,
} from '~/topBar/desktopTopBar.styles';
import { HeadingComponent } from './heading/heading.component';
import { NavigationContainer } from './navigation/navigation.container';
import {
  StatusIndicatorComponent, StatusIndicatorMode,
} from './statusIndicator.component';

type AppBarProps = Readonly<{
  accountName?: string;
  isPresentationalMenuOpen: boolean;
  allowPurchase: boolean;
  mapLink?: string;
  mapName?: string;
  mapViewName?: string;
  presentationalMode: boolean;
  publicMapSettings?: MapSettingsPublicMapSettingsState;
  showOnlyInformativeUi?: boolean;
  showToolsButton?: boolean;
  showUndoButton: boolean;
  statusIndicator: StatusIndicatorMode;

  onCreateNewMap: () => void;
  onTogglePresentationalMenu: () => void;
  onUndo: () => void;
  onSubscribeClick: () => void;
}>;

export const DesktopTopBarComponent: FC<AppBarProps> = (props) => {
  const [isMyMapDropdownOpen, setIsMyMapDropdownOpen] = useState(false);

  const { ref: rightSectionRef, width: rightSectionWidth } = useElementDimensions();
  const { ref: leftSectionRef, width: leftSectionWidth } = useElementDimensions();

  const [t] = useTranslation();

  const toggleMyMapsDropdown = useCallback(() => setIsMyMapDropdownOpen(prevOpen => !prevOpen), []);
  const closeMyMapsDropdown = useCallback(() => setIsMyMapDropdownOpen(false), []);

  const getMyMapsTriggerComponent = useCallback((tooltipLabel: string) => (
    <RoundButtonComponent
      buttonStyle={RoundButtonStyle.Custom}
      css={buttonStyle(isMyMapDropdownOpen)}
      icon={faImages}
      onClick={toggleMyMapsDropdown}
      size={RoundButtonSize.Large}
      tooltipLabel={tooltipLabel}
    />
  ), [isMyMapDropdownOpen, toggleMyMapsDropdown]);

  const showNavigation = !props.presentationalMode && !props.showOnlyInformativeUi;

  return (
    <div css={containerStyle}>

      <div
        css={{ flexShrink: 0 }}
        ref={leftSectionRef}
      >
        {showNavigation && (
          <div
            css={sectionStyle}
          >
            <NavigationContainer />
            <ExpiredSubscriptionBlocker>
              {props.showUndoButton && (
                <TooltipComponent tooltipContent={t('Undo')}>
                  <div css={undoButtonWrapperStyle}>
                    <RoundButtonComponent
                      css={buttonStyle()}
                      icon={faUndo}
                      onClick={props.onUndo}
                      size={RoundButtonSize.Medium}
                    />
                  </div>
                </TooltipComponent>
              )}
            </ExpiredSubscriptionBlocker>
            <StatusIndicatorComponent mode={props.statusIndicator} />
          </div>
        )}

        {props.showToolsButton && (
          <div css={toolsIconSpaceStyle} >
            <div
              css={toolIconStyle(props.isPresentationalMenuOpen)}
              onClick={props.onTogglePresentationalMenu}
            >
              <FontAwesomeIcon icon={faTools} />
            </div>
          </div>
        )}
      </div>

      <div css={[headingSectionStyle, { flexShrink: 1 }]} >
        {(!props.presentationalMode || props.publicMapSettings?.displayTitleAndDescription) && (
          <HeadingComponent
            accountName={props.accountName}
            mapName={props.mapName}
            mapLink={props.mapLink}
            mapViewName={props.mapViewName}
          />
        )}
      </div>

      {/* Add extra width to the righ section to center the heading relative to window, by balancing the size of left & right sections. */}
      <div css={{ flexBasis: Math.round((leftSectionWidth ?? 0) - (rightSectionWidth ?? 0)), flexShrink: MAX_INT32 }} />

      {!props.showOnlyInformativeUi && (
        <div
          css={[rightSectionStyle, { flexShrink: 0 }]}
          ref={rightSectionRef}
        >
          {props.presentationalMode ? (
            <PresentationalMapDropdownContainer
              isOpen={isMyMapDropdownOpen}
              onClose={closeMyMapsDropdown}
              triggerComponent={getMyMapsTriggerComponent(t('Saved Map Views'))}
            />
          ) : (
            <>
              <ExpiredSubscriptionBlocker>
                <MapDropdownContainer
                  isOpen={isMyMapDropdownOpen}
                  onClose={closeMyMapsDropdown}
                  triggerComponent={getMyMapsTriggerComponent(`${t('My Maps')} / ${t('Saved Map Views')}`)}
                />
                <RoundButtonComponent
                  css={buttonStyle()}
                  icon={faMapPlus}
                  onClick={props.onCreateNewMap}
                  size={RoundButtonSize.Large}
                  tooltipLabel={t('Create New Map')}
                  buttonStyle={RoundButtonStyle.Custom}
                />
              </ExpiredSubscriptionBlocker>
              {props.allowPurchase && (
                <ButtonComponent
                  buttonStyle={ButtonStyle.Success}
                  css={purchaseButtonStyle}
                  text={t('Purchase Maptive')}
                  onClick={props.onSubscribeClick}
                />
              )}
            </>
          )}
        </div>
      )}
      {!!CONFIG.EXPORT_IMAGE_SETTINGS && (
        <div css={{ paddingLeft: 20 }}>
          <StatusIndicatorComponent mode={StatusIndicatorMode.ExportImageMode} />
        </div>
      )}
    </div>
  );
};
