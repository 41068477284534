import {
  css, keyframes,
} from '@emotion/react';
import { type FC } from 'react';
import backImg from '../../../../../../assets/images/markers/radar_middle.png';

type AnimatedRadarMarkerProps = {
  className?: string;
  radius?: number;
};

const pulsate = keyframes({
  from: {
    transform: 'scale(0.25)',
    opacity: '1.0',
  },
  '95%': {
    transform: 'scale(1.3)',
    opacity: '0.0',
  },
  to: {
    transform: 'scale(0.3)',
    opacity: '0',
  },
});

const radarCont = ({ dimension }: { dimension?: number }) => css({
  height: `${dimension}px`,
  width: `${dimension}px`,
  position: 'relative',
});

const ringsCont = css({
  position: 'relative',
});

const radarRings = css({
  position: 'absolute',
  animation: `${pulsate} 1.1s ease-in-out infinite`,
  border: '1pt solid #fff',
  borderRadius: '51px',
  /* multiply the shadows, inside and outside the circle */
  boxShadow: 'inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f',
  /* set the ring's new dimension and re-center it */
  height: '51px!important',
  margin: '-18px 0 0 - 18px',
  width: '51px!important',
});

const radarDot = css({
  height: '20px',
  width: '20px',
  position: 'absolute',
  top: '17px',
  left: '17px',
  backgroundImage: `url(${backImg})`,
  backgroundRepeat: 'no-repeat',
});

export const AnimatedRadarMarker: FC<AnimatedRadarMarkerProps> = (props) => {
  return (
    <div className={props.className}>
      <div css={radarCont({ dimension: (props.radius || 20) * 2 })}>
        <div css={ringsCont}>
          <div css={radarRings} />
        </div>
        <div css={radarDot} />
      </div>
    </div>
  );
};
