import { faUserTag } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { type InputSize } from '~/_shared/baseComponents/inputs';
import { useGetMapColumns } from './useGetMapColumns';

type MapColumnPickerDropdownContainerProps = {
  mapId: number;
  virtualSpreadsheetId: number;
  onChange: (columnId: string) => void;
  triggerHeight?: InputSize;
  placeholder?: string;
};

export const MapColumnPickerDropdownContainer: FC<MapColumnPickerDropdownContainerProps> = (props) => {
  const { mapId, virtualSpreadsheetId } = props;
  const { columns, isLoading, error, getMapColumns } = useGetMapColumns();
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (!mapId || !virtualSpreadsheetId) {
      return;
    }

    getMapColumns(mapId, virtualSpreadsheetId);
  }, [getMapColumns, mapId, virtualSpreadsheetId]);

  const dropdownOptions = useMemo(() => columns.map(({ columnId, name }) => ({ value: columnId, name })), [columns]);

  useEffect(() => {
    if (!dropdownOptions.find(o => o.value === value)) {
      setValue(null);
    }
  }, [dropdownOptions, value]);

  const onChange = useCallback((columnId: string) => {
    setValue(columnId);
    const propagate = props.onChange;
    propagate(columnId);
  }, [props.onChange]);

  return (
    <RegularDropdownComponent
      options={dropdownOptions}
      value={value}
      onChange={onChange}
      isLoading={isLoading || !!error}
      prefixTriggerIcon={faUserTag}
      placeholder={props.placeholder}
      inputSize={props.triggerHeight}
      inPortal
    />
  );
};
