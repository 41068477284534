import { useMemo } from 'react';
import { spreadsheetColumnIdToString } from '../_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { usePresentationalColumnsRestrictionsStateSelector } from '../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.selectors';
import { type PresentationalColumnStateRestriction } from '../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.state';

export const usePresentationalColumnsRestrictionsMatchup = (): Map<string, PresentationalColumnStateRestriction> => {
  const columnsRestrictions = usePresentationalColumnsRestrictionsStateSelector();

  return useMemo<Map<string, PresentationalColumnStateRestriction>>(() => {
    const results: Map<string, PresentationalColumnStateRestriction> = new Map();

    columnsRestrictions.restrictions.forEach((columnsMap, spreadsheetId) => {
      columnsMap.forEach((restriction, columnId) => {
        const spreadsheetColumnIdString = spreadsheetColumnIdToString({
          spreadsheetId,
          columnId,
        });

        results.set(spreadsheetColumnIdString, restriction);
      });
    });

    return results;
  }, [columnsRestrictions]);
};
