import {
  faCommentAltEdit,
  faImages,
  faMapMarkerEdit,
  faPencil,
  faSync,
} from '@fortawesome/pro-solid-svg-icons';
import { type TranslationFnc } from '../../../../_shared/utils/hooks';
import { type ContextMenuItemProps } from '../../../../sidebar/sidebarApps/rightSidebar/sidebarContextMenu/sidebarContextMenu.types';
import { EditLocationContextMenuStructure } from '../contextMenuStructure.enums';
import {
  type EditLocationCallbackProps, type RightSidebarContextMenuCallbackProps,
} from '../contextMenuStructure.types';

const editMarkerStructure = (onClick: () => void, isDisabled: boolean | undefined, disabledTooltip: string | undefined, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(EditLocationContextMenuStructure.CustomizeMarker),
  icon: faMapMarkerEdit,
  onClick,
  isDisabled,
  disabledTooltip: isDisabled ? disabledTooltip : undefined,
});

const editDataStructure = (props: RightSidebarContextMenuCallbackProps, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(EditLocationContextMenuStructure.EditData),
  icon: faPencil,
  isDisabled: props.isDisabled,
  onClick: props.callback,
});

const editPanelStructure = (props: RightSidebarContextMenuCallbackProps, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(EditLocationContextMenuStructure.CustomizeInfoDisplay),
  icon: faCommentAltEdit,
  isDisabled: props.isDisabled,
  onClick: props.callback,
});

const editImagesStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(EditLocationContextMenuStructure.UploadImages),
  icon: faImages,
  onClick,
});

const categorizeDataStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(EditLocationContextMenuStructure.CategorizeData),
  icon: faSync,
  onClick,
});

export const editLocationContextMenuStructure = (
  callbackProps: EditLocationCallbackProps, t: TranslationFnc, isMobileScreen: boolean
): ReadonlyArray<ContextMenuItemProps> => !isMobileScreen ? ([
  editDataStructure(callbackProps.editData, t),
  editImagesStructure(callbackProps.editImages.callback, t),
  editMarkerStructure(callbackProps.editMarker.callback, callbackProps.editMarker.isDisabled, callbackProps.editMarker.disabledTooltip, t),
  editPanelStructure(callbackProps.editPanel, t),
  categorizeDataStructure(callbackProps.categorizeData, t),
]) : ([
  editDataStructure(callbackProps.editData, t),
]);
