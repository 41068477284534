import { css } from '@emotion/react';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { itemWidth } from '~/_shared/constants/mainMenuUI.constants';
import { isPresentationalMapByPathname } from '~/_shared/presentationMode/presentationModeContext';
import { noop } from '~/_shared/utils/function.helpers';
import { MainMenuContainer } from '~/mainMenu/mainMenu.container';
import { decideIsEnoughVerticalSpaceForMenuItems } from '~/mainMenu/mainMenu.helpers';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { MapSettingsContainer } from '~/map/settings/mapSettings.container';
import { useSlidingAnimationDuration } from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { SidebarApp } from './sidebarApp.enum';
import { type SidebarAppProps } from './sidebarAppComponentProps.type';
import { AccountSettingsAppComponent } from './sidebarApps/accountSettingsAppComponent';
import { MapToolsContainer } from './sidebarApps/mapTools.container';
import { SaveMapViewContainer } from './sidebarApps/saveMapView/saveMapView.container';
import { SidebarShareMapContainer } from './sidebarApps/sidebarShareMap.container';

export const SIDEBAR_WIDTH = 290;

const sidebarAppComponentMap: Partial<{ [key in keyof typeof SidebarApp]: React.FC<SidebarAppProps> }> = {
  MapTools: MapToolsContainer,
  MapSettings: MapSettingsContainer,
  SaveMap: SaveMapViewContainer,
  ShareMap: SidebarShareMapContainer,
  AccountSettings: AccountSettingsAppComponent,
  Boundary: MapToolsContainer,
  Proximity: MapToolsContainer,
  Filter: MapToolsContainer,
  Grouping: MapToolsContainer,
  MapLegend: MapToolsContainer,
  HeatMap: MapToolsContainer,
  Routing: MapToolsContainer,
};

const sidebarAppMainMenuItemMap: { [key in keyof typeof SidebarApp]: MainMenuItem } = {
  MapTools: MainMenuItems.MapTools,
  MapLegend: MainMenuItems.MapLegend,
  MapSettings: MainMenuItems.MapSettings,
  SaveMap: MainMenuItems.SaveMapView,
  ShareMap: MainMenuItems.ShareMap,
  AccountSettings: MainMenuItems.AccountSettings,
  Boundary: MainMenuItems.Boundary,
  Proximity: MainMenuItems.Proximity,
  Filter: MainMenuItems.Filter,
  Grouping: MainMenuItems.Grouping,
  HeatMap: MainMenuItems.HeatMap,
  Routing: MainMenuItems.Routing,
};
const getAppFromMainMenuItem = (item: MainMenuItem): SidebarApp | undefined =>
  Object.entries(sidebarAppMainMenuItemMap).find(([, i]) => i === item)?.[0] as SidebarApp;

const sidebarAppsWithToolbox: ReadonlySet<SidebarApp> = new Set([
  SidebarApp.AccountSettings,
  SidebarApp.SaveMap,
  SidebarApp.MapSettings,
  SidebarApp.MapTools,
  SidebarApp.Boundary,
  SidebarApp.Proximity,
  SidebarApp.Filter,
  SidebarApp.Grouping,
  SidebarApp.HeatMap,
  SidebarApp.MapLegend,
  SidebarApp.Routing,
]);

const wrapperStyle = ({ isOnTopOfMap }: {isOnTopOfMap: boolean}) => css({
  position: isOnTopOfMap ? 'absolute' : 'static',
  display: 'flex',
  height: '100%',
});

const menuStyle = css({
  height: '100%',
  position: 'relative',
});

type SidebarProps = Readonly<{
  isMobileScreen: boolean;
  isSidebarVisible: boolean;
  noMainMenu?: boolean;
  openedApp: SidebarApp | null;
  pathname: string;
  sidebarHeight: number;
  visibleMenuItems: ReadonlySet<MainMenuItem>;

  close?: () => void;
  openApp: (app: SidebarApp) => void;
}>;

export const SidebarComponent = (props: SidebarProps) => {
  const slidingAnimationDuration = useSlidingAnimationDuration();
  const animationConfig = useMemo(() => ({ duration: slidingAnimationDuration, clamp: true }), [slidingAnimationDuration]);
  const closedSidebarMenuStyles = useMemo(() => ({ width: 0, config: animationConfig }), [animationConfig]);
  const openedSidebarMenuStyles = useMemo(() => ({ width: itemWidth, config: animationConfig }), [animationConfig]);
  const getInitialSidebarMenuStyles = useCallback((isFirstOpen: boolean) => (
    isFirstOpen ? openedSidebarMenuStyles : closedSidebarMenuStyles
  ), [openedSidebarMenuStyles, closedSidebarMenuStyles]);
  const finalStyles = useMemo(() => (
    { width: SIDEBAR_WIDTH, overflow: 'clip', onRest: noop, config: animationConfig }
  ), [animationConfig]);
  const createInitialStyles = useCallback((onRest?: () => void) => (
    { width: 0, overflow: 'clip', onRest, config: animationConfig }
  ), [animationConfig]);
  const [appAnimationStyles, appAnimationStylesApi] = useSpring(() => createInitialStyles());

  const isPresentationalBeforeStateIsSet = useMemo(() => isPresentationalMapByPathname(props.pathname), [props.pathname]);
  const [sidebarMenuStyles, sidebarMenuStylesApi] = useSpring(() => getInitialSidebarMenuStyles(!isPresentationalBeforeStateIsSet));

  const AppComponent = useMemo(() => props.openedApp && sidebarAppComponentMap[props.openedApp], [props.openedApp]);

  const closeApp = useMemo(() => (
    props.close ? () => appAnimationStylesApi.start(createInitialStyles(props.close)) : undefined
  ), [createInitialStyles, props.close, appAnimationStylesApi]);

  const openApp = useCallback((item: MainMenuItem) => {
    const app = getAppFromMainMenuItem(item);
    return app && props.openApp(app);
  }, [props]);

  const isSidebarOnTopOfMap = useMemo(() => {
    if (!isPresentationalBeforeStateIsSet) {
      return false;
    }
    return !decideIsEnoughVerticalSpaceForMenuItems(props.sidebarHeight, props.visibleMenuItems.size);
  }, [isPresentationalBeforeStateIsSet, props.sidebarHeight, props.visibleMenuItems.size]);

  useEffect(() => {
    if (props.isSidebarVisible) {
      sidebarMenuStylesApi.start(openedSidebarMenuStyles);
    }
    else {
      sidebarMenuStylesApi.start(closedSidebarMenuStyles);
    }
  }, [closedSidebarMenuStyles, openedSidebarMenuStyles, props.isSidebarVisible, sidebarMenuStylesApi]);

  useEffect(() => {
    if (props.openedApp && sidebarAppsWithToolbox.has(props.openedApp)) {
      appAnimationStylesApi.start(finalStyles);
    }
    else {
      appAnimationStylesApi.start(createInitialStyles(props.close));
    }
  }, [props.openedApp, props.close, appAnimationStylesApi, finalStyles, createInitialStyles]);

  return (
    <div css={wrapperStyle({ isOnTopOfMap: isSidebarOnTopOfMap })}>
      {!props.noMainMenu && !props.isMobileScreen && (
        <animated.div
          css={menuStyle}
          style={sidebarMenuStyles}
        >
          <MainMenuContainer
            visibleMenuItems={props.visibleMenuItems}
            menuHeight={props.sidebarHeight}
            onItemClick={openApp}
            selectedItem={props.openedApp && sidebarAppMainMenuItemMap[props.openedApp]}
          />
        </animated.div>
      )}

      <animated.div style={appAnimationStyles}>

        {props.openedApp && AppComponent && (
          <div css={css({ width: SIDEBAR_WIDTH })} >
            <AppComponent onClose={closeApp} />
          </div>
        )}
      </animated.div>
    </div>
  );
};
