import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import {
  type DrawingItemLabel, DrawingItemPlacement,
} from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingLabelSettingsState } from '~/store/mapSettings/drawing/settings/label/drawingLabelSettings.state';
import { type DrawingToolManager } from '../drawingToolManager';

type DrawingToolLabelCreateNewInstanceProps = Readonly<{
  settings: DrawingLabelSettingsState;
  manager: DrawingToolManager;
}>;

export const DrawingToolLabelCreateNewInstanceContainer: FC<DrawingToolLabelCreateNewInstanceProps> = ({
  settings, manager,
}) => {
  const dispatch = useDispatch();
  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    if (!e.latLng) {
      return;
    }
    const id = createUuid();

    const newInstanceSettings = { ...settings };

    const currentZoom = manager.getZoom();
    if (settings.scalesWithMapZoom && currentZoom !== undefined) {
      newInstanceSettings.fontSize = convertPxDistanceToDifferentZoom({ distance: newInstanceSettings.fontSize, originalZoom: currentZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS });
    }

    const newInstance: DrawingItemLabel = {
      id,
      startPoint: googleLatLngToLocal(e.latLng),
      placement: DrawingItemPlacement.Default,
      settings: newInstanceSettings,
      text: '',
    };

    dispatch(drawingItemsAddItem(id, {
      type: DrawingTool.Label,
      value: newInstance,
    }));

    dispatch(drawingEditSetSelectedDrawing(
      newInstance.id,
      {
        type: DrawingTool.Label,
        value: newInstance,
      })
    );
  }, [dispatch, manager, settings]);

  useEffect(() => {
    const listener = manager.addMapClickListener(onMapClick);

    return () => listener.remove();
  }, [manager, onMapClick]);

  return null;
};
