import { css } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const wrapperStyle = ({ theme, isActive }: ThemeProps<{isActive: boolean}>) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  background: isActive ? theme.buttonColors.primaryBackground : 'none',
  border: 'none',
  color: isActive ? theme.textColors.quaternary : theme.textColors.primary,
  fontSize: '12px',
  height: '100%',
  paddingRight: 14,
  transition: 'background .1s',
  '&:hover': {
    background: theme.buttonColors.primaryBackground,
  },
});

const textStyle = css({
  marginLeft: 10,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const caretDownIconStyle = (isDropdownOpen: boolean) => css({
  display: 'flex',
  alignItems: 'center',
  float: 'right',
  fontSize: 16,
  justifyContent: 'center',
  marginLeft: 8,
  transform: isDropdownOpen ? 'rotate(-180deg)' : undefined,
  transition: 'transform .2s',
});

type SettingsAccordionDropdownComponentProps = Readonly<{
  isOpen: boolean;
  triggerText?: string;
  children?: React.ReactNode;
  onClick: () => void;
}>;

export const SettingsAccordionDropdownComponent: React.FC<SettingsAccordionDropdownComponentProps> = props => {
  const theme = useTheme();

  return (
    <div
      css={wrapperStyle({ theme, isActive: props.isOpen })}
      onClick={props.onClick}
    >
      {!!props.children && (
        <div>
          {props.children}
        </div>
      )}
      {props.triggerText && (
        <div css={textStyle}>
          {props.triggerText}
        </div>
      )}
      <div css={caretDownIconStyle(props.isOpen)}>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </div>
  );
};
