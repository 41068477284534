import { css } from '@emotion/react';
import { type FC } from 'react';
import { DisclaimerContainer } from '~/_shared/components/disclaimer/disclaimer.container';
import { DisclaimerStepComponent } from '~/_shared/components/disclaimer/disclaimerStep.component';
import { useDisclaimerSteps } from '~/_shared/components/disclaimer/disclaimerSteps';
import {
  MapConfirmComponent, type MapConfirmProps,
} from '~/_shared/mapConfirm/mapConfirm.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';

const disclaimerStyle = css({
  marginLeft: 20,
  padding: '23px 18px 13px',
  fontSize: '14px',
});

const mapConfirmStyle = css({
  minWidth: 270,
});

const triggerPlaceholderStyle = ({ theme }: ThemeProps) => css({
  minWidth: 112,
  color: theme.textColors.primary,
});

const dropdownListStyle = css({
  minWidth: 180,
});

type MapMarkersSelectOverlayComponentProps = MapConfirmProps & Readonly<{
  highlightedStepNumber?: number;
}>;

export const MapMarkersSelectOverlayComponent: FC<MapMarkersSelectOverlayComponentProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const disclaimerSteps = useDisclaimerSteps();

  return (
    <MapConfirmComponent
      {...props}
      triggerPlaceholderStyle={triggerPlaceholderStyle({ theme })}
      css={mapConfirmStyle}
      dropdownListStyle={dropdownListStyle}
    >
      {props.selectedOptionId === MarkersSelectType.Click && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'select-markers-click',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            step={1}
            isHighlighted={props.highlightedStepNumber === 1}
          >
            {t('Click the markers you wish to add to your selection.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={2}
            isHighlighted={props.highlightedStepNumber === 2}
          >
            {disclaimerSteps.FinishSelection}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}

      {(props.selectedOptionId === MarkersSelectType.Lasso) && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'select-markers-lasso',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            step={1}
            isHighlighted={props.highlightedStepNumber === 1}
          >
            {t('Click the map to start drawing your polygon.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={2}
            isHighlighted={props.highlightedStepNumber === 2}
          >
            {t('Click the map again to add additional vertices to the polygon outline.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={3}
            isHighlighted={props.highlightedStepNumber === 3}
          >
            {t('Click the first vertex or double click the map to complete the loop.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            step={4}
            isHighlighted={props.highlightedStepNumber === 4}
          >
            {disclaimerSteps.FinishSelection}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}
    </MapConfirmComponent>
  );
};
