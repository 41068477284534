import { type PixelCoordinates } from '../../../../_shared/types/coordinateSystem/coordinateSystem';

export const getArrowTailOffset = (
  tipPosition: PixelCoordinates, tailPosition: PixelCoordinates, arrowLength: number
): PixelCoordinates => {
  const angle = Math.atan2(tipPosition.x - tailPosition.x, tipPosition.y - tailPosition.y);

  const offsetX = arrowLength * Math.cos(angle + Math.PI / 2);
  const offsetY = arrowLength * Math.sin(angle + Math.PI / 2);

  return {
    x: offsetX,
    y: -offsetY,
  };
};
