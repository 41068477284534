import { useSelector } from '~/_shared/utils/hooks/useSelector';
import type { AppState } from '~/store/app.store';
import { defaultSplitPanelItem } from '~/store/userData/settings/userDataSettings.reducer';

export const userDataSettingsSelector = (state: AppState) => state.userData.settings;
export const useUserDataSettingsSelector = () => useSelector(userDataSettingsSelector);

export const userDataIsReadySelector = (state: AppState) => state.userData.isReady;

export const useSplitItemSettingsSelector = (clientId: number | null, mapId: number | null) => {
  const userDataSettingsState = useSelector(userDataSettingsSelector);

  if (clientId === null || mapId === null) {
    return defaultSplitPanelItem;
  }

  const splitPanelState = userDataSettingsState?.[clientId]?.splitPanel;

  if (!splitPanelState) {
    return defaultSplitPanelItem;
  }

  return splitPanelState[mapId] ?? defaultSplitPanelItem;
};
