import {
  type FC, memo, type ReactNode, useMemo,
} from 'react';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type MapObjectZIndex } from '../mapObject.types';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { type MapTextInputInstance } from './mapTextInputModel';

type MapInputFieldProps = Readonly<{
  zIndex: MapObjectZIndex;
  instance: MapTextInputInstance;
  renderTextInput: (inputField: MapTextInputInstance) => ReactNode;
  renderOutline?: (outline: MapOutlinePositionInstance) => ReactNode;
}>;

const useTextInputOutlines = (
  instance: MapTextInputInstance,
): MapOutlinePositionInstance[] => {
  return useMemo(() => {
    return [{
      id: `${instance.id}-left`,
      ...instance.startPoint,
      offsetX: 0,
      offsetY: 0,
      position: MapOutlinePosition.Left,
    }];
  }, [instance.id, instance.startPoint]);
};

const MapTextInputContainer: FC<MapInputFieldProps> = (props) => {
  const { instance: inputField, zIndex } = props;
  const { renderTextInput: renderInputField, renderOutline } = props;

  const mapObjectManager = useMapObjectManager();

  const outlines = useTextInputOutlines(inputField);

  return (
    <MapObjectContextProvider value={{ manager: mapObjectManager, zIndex }}>
      {renderInputField(inputField)}
      {renderOutline && outlines.map(
        outline => renderOutline(outline))
      }
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(MapTextInputContainer);
export { pureComponent as MapTextInputContainer };
