import { type BoundaryEditAction } from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import { type CreateBoundaryGroupFromAIConfig } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import {
  type BoundaryGroupId,
  type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';
import {
  BOUNDARY_SELECT_ADD_BOUNDARY,
  BOUNDARY_SELECT_EDIT_ACTIVATE,
  BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CLOSE_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_DEACTIVATE,
  BOUNDARY_SELECT_EDIT_RESET,
  BOUNDARY_SELECT_HOVER_BOUNDARY,
  BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL,
  BOUNDARY_SELECT_REMOVE_BOUNDARY,
  BOUNDARY_SELECT_SET_LASSO_IS_LOADING,
  BOUNDARY_SELECT_SET_LASSO_OVERLAP,
  BOUNDARY_SELECT_SET_SELECT_TYPE,
  BOUNDARY_SELECT_STOP_BOUNDARY_HOVER,
} from './boundarySelect.actionTypes';
import {
  type BoundarySelectType, type EditCustomBoundarySubmitSelectionConfiguration, type SelectLassoOverlap,
} from './boundarySelect.state';

export const boundarySelectEditActivate = () => ({
  type: BOUNDARY_SELECT_EDIT_ACTIVATE,
}) as const;

export const boundarySelectEditCloseModal = () => ({
  type: BOUNDARY_SELECT_EDIT_CLOSE_MODAL,
}) as const;

export const boundarySelectEditBoundaryTerritoryOpenModal = (payload: {
  selectBoundaryGroupId: BoundaryGroupId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
}) => ({
  type: BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
  payload,
}) as const;

export const boundarySelectEditCustomBoundaryOpenModal = (payload: {
  selectBoundaryGroupId: BoundaryGroupId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  actionType: BoundaryEditAction;
  boundaryId?: BoundaryId;
}) => ({
  type: BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
  payload,
}) as const;

export const boundarySelectOptimizedTerritoriesSubsetOpenModal = (payload: {
  boundaryGroupId: BoundaryGroupId;
  selectedBoundaryIds?: ReadonlyArray<BoundaryId>;
  configuration: CreateBoundaryGroupFromAIConfig;
}) => ({
  type: BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL,
  payload,
}) as const;

export const boundarySelectEditCustomBoundarySubmitOpenModal = (payload: {
  selectBoundaryGroupId: BoundaryGroupId;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  configuration: EditCustomBoundarySubmitSelectionConfiguration;
}) => ({
  type: BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL,
  payload,
}) as const;

export const boundarySelectEditDeactivate = () => ({
  type: BOUNDARY_SELECT_EDIT_DEACTIVATE,
}) as const;

export const boundarySelectEditReset = () => ({
  type: BOUNDARY_SELECT_EDIT_RESET,
}) as const;

export const boundarySelectAdd = (boundaryIds: BoundaryId[]) => ({
  type: BOUNDARY_SELECT_ADD_BOUNDARY,
  payload: {
    boundaryIds,
  },
}) as const;

export const boundarySelectRemove = (boundaryId: BoundaryId) => ({
  type: BOUNDARY_SELECT_REMOVE_BOUNDARY,
  payload: {
    boundaryId,
  },
}) as const;

export const boundarySelectHoverBoundary = (boundaryId: BoundaryId) => ({
  type: BOUNDARY_SELECT_HOVER_BOUNDARY,
  payload: {
    boundaryId,
  },
}) as const;

export const boundarySelectStopBoundaryHover = () => ({
  type: BOUNDARY_SELECT_STOP_BOUNDARY_HOVER,
  payload: {
  },
}) as const;

export const boundarySelectSetSelectType = (selectType: BoundarySelectType) => ({
  type: BOUNDARY_SELECT_SET_SELECT_TYPE,
  payload: {
    selectType,
  },
}) as const;

export const boundarySelectSetLassoOverlap = (overlap: SelectLassoOverlap) => ({
  type: BOUNDARY_SELECT_SET_LASSO_OVERLAP,
  payload: {
    overlap,
  },
}) as const;

export const boundarySelectSetLassoIsLoading = (isLoading: boolean) => ({
  type: BOUNDARY_SELECT_SET_LASSO_IS_LOADING,
  payload: {
    isLoading,
  },
}) as const;
