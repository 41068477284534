import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  faCheck, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type ReactNode, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import type { Theme } from '~/_shared/themes/theme.model';
import { type ModalProps } from '~/modal/modalType.enum';
import { CheckboxComponent } from '../../../baseComponents/checkbox';
import { useTranslation } from '../../../utils/hooks';
import { OverlayLoaderComponent } from '../../overlay/overlayLoader.component';
import { ModalComponent } from '../modal.component';

export type ConfirmationModalProps = ModalProps<{
  cancelCaption?: string;
  checkboxLabel?: string;
  children: ReactNode;
  className?: string;
  closeButtonStyle?: SerializedStyles;
  confirmCaption?: string;
  containerStyle?: SerializedStyles;
  contentMaxHeight?: string;
  contentStyle?: SerializedStyles;
  isCloseDisabled?: boolean;
  isConfirmButtonDestructive?: boolean;
  maxWidth?: number | string;
  showLoaderAfterConfirmClick?: boolean;
  title: string;

  onCancel?: () => void;
  onConfirm: (isPermanent: boolean) => void;
}>;

const buttonStyle = css({
  padding: '10px 12px',
});

const checkboxWrapperStyle = (theme: Theme) => css({
  color: theme.textColors.secondary,
  paddingTop: 14,
});

const contentWrapperStyle = css({
  padding: '17px 33px',
});

export const ConfirmationModalComponent = ({
  cancelCaption,
  checkboxLabel,
  children,
  className,
  closeButtonStyle,
  confirmCaption,
  containerStyle,
  contentMaxHeight = 'calc(90vh - 150px)',
  contentStyle,
  isCloseDisabled,
  isConfirmButtonDestructive,
  isOpen,
  maxWidth = 547,
  onCancel,
  onClose,
  onConfirm,
  showLoaderAfterConfirmClick,
  title,
  ...restProps
}: ConfirmationModalProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const [isConfirmPermanent, setIsConfirmPermanent] = useState(false);
  const [t] = useTranslation();

  const onConfirmPermanentCheckboxClick = () => {
    setIsConfirmPermanent(isPermanent => !isPermanent);
  };

  return (
    <ModalComponent
      {...restProps}
      closeButtonStyle={closeButtonStyle}
      onClose={onClose}
      isCloseDisabled={isCloseDisabled}
      isOpen={isOpen}
      className={className}
      containerStyle={containerStyle}
      maxWidth={maxWidth}
      contentMaxHeight={contentMaxHeight}
      caption={title}
      contentStyle={css([contentWrapperStyle, contentStyle])}
      leftFooterContent={onCancel && (
        <ButtonComponent
          css={buttonStyle}
          buttonStyle={ButtonStyle.Secondary}
          prefixIcon={faXmark}
          text={cancelCaption ?? t('Cancel')}
          onClick={onCancel}
        />
      )}
      confirmButton={(
        <ButtonComponent
          buttonStyle={isConfirmButtonDestructive ? ButtonStyle.Danger : ButtonStyle.Primary}
          css={buttonStyle}
          prefixIcon={faCheck}
          text={confirmCaption ?? t('Save')}
          onClick={() => {
            if (showLoaderAfterConfirmClick) {
              setShowLoader(true);
            }

            onConfirm(isConfirmPermanent);
          }}
        />
      )}
      additionalContent={showLoader ? <OverlayLoaderComponent /> : null}
    >
      {children}

      {checkboxLabel && (
        <div css={checkboxWrapperStyle}>
          <CheckboxComponent
            isChecked={isConfirmPermanent}
            checkedSetter={onConfirmPermanentCheckboxClick}
            text={checkboxLabel}
          />
        </div>
      )}
    </ModalComponent>
  );
};
