import {
  useCallback,
  useEffect, useState,
} from 'react';
import {
  type SpreadsheetVersionApiData,
  type SpreadsheetVersionItem,
  type SpreadsheetVersionType,
} from '../_shared/types/undo/versionItem';
import { type ApiError } from '../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import {
  fetchVersions, type RollbackVersionParams, rollbackVersions,
} from './undo.repository';

export const useManageSpreadsheetVersions = (clientId: number | null, virtualSpreadsheetId: number | null) => {
  const isMountedRef = useIsComponentMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [spreadsheetVersions, setVersions] = useState<SpreadsheetVersionItem[] | null>(null);

  const rollbackVersion = useCallback((params: RollbackVersionParams, onSuccess?: () => void) => {
    setIsLoading(true);
    setError(null);

    return rollbackVersions(clientId, virtualSpreadsheetId, params)
      .then(() => {
        onSuccess?.();
      })
      .catch((e: ApiError) => {
        if (isMountedRef.current) {
          setError(e);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [clientId, isMountedRef, virtualSpreadsheetId]);

  useEffect(() => {
    if (virtualSpreadsheetId && clientId) {
      fetchVersions(clientId, virtualSpreadsheetId)
        .then((response: { data: SpreadsheetVersionApiData }) => {
          const spreadsheetVersions: SpreadsheetVersionItem[] = [];
          const realSpreadsheetId = response.data.primary.real_spreadsheet_id;

          response.data.primary.timeline.forEach(row => {
            const snapshotId = row.snapshot_id;

            const snapshotVersions = row.versions.map(version => ({
              createdAt: new Date(version.created_at),
              creator: version.creator,
              id: `${realSpreadsheetId}|${snapshotId}|${version.version_id}`,
              type: version.type as SpreadsheetVersionType,
              updatedAt: new Date(version.updated_at),
            }));

            spreadsheetVersions.push(...snapshotVersions);
          });

          setVersions(spreadsheetVersions);
        })
        .catch((e: ApiError) => {
          setError(e);
        })
        .finally(() => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        });
    }
    else {
      setVersions(null);
    }
  }, [clientId, isMountedRef, virtualSpreadsheetId]);

  return {
    error,
    isLoading,
    rollbackVersion,
    spreadsheetVersions,
  };
};
