import {
  useEffect, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { validateBoundingBox } from '~/_shared/utils/gis/boundingBox.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import {
  mapComponentAllowStreetView,
  mapComponentDisallowStreetView,
  mapComponentSetCenter,
  mapComponentSetLastBounds,
  mapComponentSetZoom,
} from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import {
  useMapComponentCenterSelector,
  useMapComponentZoomSelector,
  useMapComponentZoomToBoundsPreferredZoomSelector,
  useMapComponentZoomToBoundsSelector,
  useResetMapOnChangeSelector,
} from '~/store/frontendState/mapComponent/mapComponent.selectors';
import {
  frontendStateStreetViewOff, frontendStateStreetViewOn,
} from '~/store/frontendState/mapTools/streetView/streetView.actionCreators';
import {
  usePublicMapSettingsIsStreetViewEnabledSelector,
  usePublicMapSettingsZoomRestrictLevelsSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useAreMapSettingsReadySelector } from '~/store/mapSettings/ready/mapSettingsReady.selectors';
import { useMapSettingsExportImageSettingsModeSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { isExportImageMapReady } from '../presentional/useIsMapFullyInitialized';
import { BoundingBox } from './boundingBox';
import { useMapContext } from './mapContext';

export const useMapFeatures = () => {
  const { map } = useMapContext();
  const dispatch = useDispatch();
  const center = useMapComponentCenterSelector();
  const zoom = useMapComponentZoomSelector();
  const zoomToBounds = useMapComponentZoomToBoundsSelector();
  const zoomToBoundsPreferredZoom = useMapComponentZoomToBoundsPreferredZoomSelector();
  const resetMapOnChange = useResetMapOnChangeSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const restrictZoomLevels = usePublicMapSettingsZoomRestrictLevelsSelector();
  const isPublicMaptreetViewEnabled = usePublicMapSettingsIsStreetViewEnabledSelector();
  const mapSettingsReady = useAreMapSettingsReadySelector();
  const isExportImage = !!useMapSettingsExportImageSettingsModeSelector();

  const previousZoom = usePrevious<number | null>(map && resetMapOnChange ? zoom : null);
  const previousCenter = usePrevious<LatLng | null>(map && resetMapOnChange ? center : null);
  const previousZoomToBounds = usePrevious<BoundingBox | null>(zoomToBounds);

  // Save last bounds of map to state
  // Throttle so it won't affect performance
  const updateLastBoundsAndCenter = useThrottle(() => {
    if (!map) {
      return;
    }

    const currentCenter = map.getCenter();
    if (!currentCenter) {
      return;
    }

    if (isExportImage && isExportImageMapReady()) {
      // Prevent all changes to map bounds when export image is taking screenshot
      return;
    }

    dispatch(mapComponentSetCenter({
      lat: Number(currentCenter.lat().toFixed(6)),
      lng: Number(currentCenter.lng().toFixed(6)),
    }, false));

    const zoomLevel = map.getZoom() || 0;

    const mapBounds = map.getBounds();
    if (mapBounds) {
      const newBounds = new BoundingBox(mapBounds);

      if (validateBoundingBox(newBounds)) {
        dispatch(mapComponentSetLastBounds(newBounds, zoomLevel));
      }
    }
  }, [map, isExportImage, dispatch], 1000);

  // Reacting to restrict zoom levels
  useEffect(() => {
    if (!isMapPresentational || !map) {
      return;
    }

    map.setOptions({
      maxZoom: restrictZoomLevels[1], //due to google maps issues, max zoom needs to be before minZoom
      minZoom: restrictZoomLevels[0],
    });
  }, [isMapPresentational, map, restrictZoomLevels]);

  // watch for zoom change (when map is zoomed to match bounding box for example)
  useEffect(() => {
    if (!map) {
      return;
    }

    const onZoomChange = () => {
      const newMapZoom = map.getZoom();

      if (newMapZoom === null || newMapZoom === undefined) {
        return;
      }

      dispatch(mapComponentSetZoom(newMapZoom, false));
    };

    const removeListener = google.maps.event.addListener(map, 'zoom_changed', onZoomChange);

    return () => {
      removeListener.remove();
    };
  }, [map, dispatch]);

  // Reacting to zoom change
  useEffect(() => {
    if (!map || !resetMapOnChange) {
      return;
    }

    if (zoom !== null && previousZoom !== zoom) {
      map.setZoom(zoom);
    }
  }, [previousZoom, zoom, map, resetMapOnChange]);

  // Reacting to center change
  useEffect(() => {
    if (!map || !resetMapOnChange) {
      return;
    }

    if (previousCenter?.lng !== center.lng || previousCenter?.lat !== center.lat) {
      map.setCenter(center);
    }
  }, [previousCenter, center, map, resetMapOnChange]);

  // Reacting to bounds change
  useEffect(() => {
    if (!map || !resetMapOnChange) {
      return;
    }

    if (previousZoomToBounds !== zoomToBounds) {
      if (zoomToBounds) {
        map.fitBounds(zoomToBounds.getGoogleMapsLatLng());

        // use preffered zoom if current zoom is one point smaller
        const currentZoom = map.getZoom() ?? 0;
        if (currentZoom + 1 === zoomToBoundsPreferredZoom) {
          map.setZoom(zoomToBoundsPreferredZoom);
        }
      }
    }
  }, [previousZoomToBounds, zoomToBounds, map, resetMapOnChange, zoomToBoundsPreferredZoom]);

  // Keep the last bounds and center in state
  useEffect(() => {
    if (!map) {
      return;
    }

    const listener = google.maps.event.addListener(map, 'idle', updateLastBoundsAndCenter);

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [map, updateLastBoundsAndCenter]);

  // Disable street view button if it's disabled for a public map
  useEffect(() => {
    if (!map || !mapSettingsReady) {
      return;
    }

    if (isMapPresentational && !isPublicMaptreetViewEnabled) {
      dispatch(mapComponentDisallowStreetView());
    }
    else {
      dispatch(mapComponentAllowStreetView());
    }
  }, [map, dispatch, isMapPresentational, isPublicMaptreetViewEnabled, mapSettingsReady]);

  // Update street view state when street is is enabled via default map control
  const previousStrretViewVisibleRef = useRef(false);
  useEffect(() => {
    if (!map) {
      return;
    }

    const streetView = map.getStreetView();
    google.maps.event.addListener(streetView, 'visible_changed', () => {
      const visible = streetView.getVisible();
      if (visible !== previousStrretViewVisibleRef.current) {
        previousStrretViewVisibleRef.current = visible;

        if (streetView.getVisible()) {
          dispatch(frontendStateStreetViewOn());
        }
        else {
          dispatch(frontendStateStreetViewOff());
        }
      }
    });
  }, [dispatch, map]);

  useEffect(() => {
    // fixes bug where mouse move event is not working after very quick dragstart/end events.
    // https://stackoverflow.com/questions/75409120/google-maps-losses-mousemove-event-listener-after-simultaneous-dragstart-and-dra

    let dragStartCenter: google.maps.LatLng | null = null;
    const onDragStart = google.maps.event.addListener(map, 'dragstart', function () {
      dragStartCenter = map.getCenter() ?? null;
    });

    const onDragEnd = google.maps.event.addListener(map, 'dragend', function () {
      if (dragStartCenter && dragStartCenter.equals(map.getCenter() ?? null)) {
        // execute panBy() if the map has not been moved during dragging.
        map.panBy(0, 0);
        dragStartCenter = null;
      }
    });

    return () => {
      onDragStart.remove();
      onDragEnd.remove();
    };
  }, [map]);
};
