import { type MarkersContextMenuAction } from './markersContextMenu.action';
import {
  MARKERS_CONTEXT_MENU_HIDE, MARKERS_CONTEXT_MENU_SHOW,
} from './markersContextMenu.actionTypes';
import type { MarkersContextMenuState } from './markersContextMenu.state';

const initialState: MarkersContextMenuState = {
  visible: false,
  position: null,
};

export const markersContextMenuReducer = (state: MarkersContextMenuState = initialState, action: MarkersContextMenuAction): MarkersContextMenuState => {
  switch (action.type) {
    case MARKERS_CONTEXT_MENU_SHOW: {
      return {
        ...state,
        visible: true,
        position: action.payload.position,
      };
    }

    case MARKERS_CONTEXT_MENU_HIDE: {
      return {
        ...state,
        visible: false,
        position: null,
      };
    }

    default:
      return state;
  }
};
