import {
  call, put,
  takeEvery, takeLatest,
} from 'redux-saga/effects';
import { UndoItemType } from '../../../_shared/types/undo/undoItem';
import { getDefaultSpreadsheetColumnMetrics } from '../../../_shared/utils/metric/defaultMetric.sagas';
import { type MetricModel } from '../../../_shared/utils/metric/metrics.factory';
import { select } from '../../../_shared/utils/saga/effects';
import { type StorageService } from '../../../_shared/utils/storageService';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import { createHistoryItem } from '../../undo/undo.helpers';
import { getUserStorageService } from '../../userData/userData.sagas';
import { type MapSettingsProximityAction } from './mapSettingsProximity.action';
import { addProximityMetrics } from './mapSettingsProximity.actionCreators';
import {
  MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY,
} from './mapSettingsProximity.actionTypes';

export function* proximitySaga() {
  yield takeEvery(MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY, removeProximitySaga);
  yield takeLatest(MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY, onNewProximityAdd);
}

function* removeProximitySaga(action: PickAction<MapSettingsProximityAction, typeof MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY>) {
  const storageService: StorageService = yield call(getUserStorageService);

  yield call(createHistoryItem, storageService, {
    type: UndoItemType.REMOVE_PROXIMITY,
    data: action.payload.proximity,
    mapId: action.payload.mapId,
  });
}

function* onNewProximityAdd() {
  const existingMetrics: MetricModel[] = yield select<MetricModel[]>(state => state.map.mapSettings.data.proximity.metrics);

  if (existingMetrics.length > 0) {
    return;
  }

  const defaultMetrics: MetricModel[] = yield call(getDefaultSpreadsheetColumnMetrics);

  yield put(addProximityMetrics(defaultMetrics));
}
