import { faMapMarkerPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  startAddLocationTool, stopAddLocationTool,
} from '~/store/frontendState/mapTools/addLocation/addLocation.actionCreators';
import { useAddLocationIsLoadingSelector } from '~/store/frontendState/mapTools/addLocation/addLocation.selectors';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideAddMarkerMenuItem: FC = () => {
  const [t] = useTranslation();
  const { openModal: optionsModal } = useModal(ModalType.OptionsModal);
  const { openModal: addMultipleLocationsModal } = useModal(ModalType.AddMultipleLocations);
  const addLocationActive = useSelector(state => state.frontendState.mapTools.addLocation.active);

  const dispatch = useDispatch();
  const isLoading = useAddLocationIsLoadingSelector();

  const onPlaceMarkerOnTheMap = () => {
    dispatch(startAddLocationTool());
  };

  const toggleAddLocation = () => {
    if (addLocationActive) {
      dispatch(stopAddLocationTool());
    }
    else {
      optionsModal({
        allowedPages: ALL_MAP_RELATED_PAGES,
        title: t('Add a Map Marker to your Map'),
        options: [
          {
            name: t('Input Address Information (Address, City, Latitude, etc)'),
            onChosen: () => addMultipleLocationsModal(),
          },
          {
            name: t('Place a Marker on the Map'),
            onChosen: onPlaceMarkerOnTheMap,
          },
        ],
        width: 600,
      });
    }
  };

  const handleStopAddLocationTool = useCallback(() => dispatch(stopAddLocationTool()), [dispatch]);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: handleStopAddLocationTool },
    options: { allowInputs: true },
    targetKeys: KeyboardKeys.Escape,
  }), [handleStopAddLocationTool]));

  return (
    <RightSideMapToolsMenuItem
      icon={faMapMarkerPlus}
      active={addLocationActive}
      onClick={toggleAddLocation}
      tooltip={t('Add Marker')}
      isLoading={isLoading}
    />
  );
};
