import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  TopUnderbarContainer, TopUnderbarType,
} from '../../_shared/components/topUnderbar/topUnderbar.container';
import { DataTableDataPageContainer } from '../table/dataTableDataPage.container';

const topUnderbarVisibleItems = new Set([
  TopUnderbarType.EXPORT_IMAGE_STATUS,
  TopUnderbarType.GEOCODING_PROGRESS_ALERT,
  TopUnderbarType.ANNOUNCEMENTS,
]);

const containerStyle = css({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flex: '1 1 0',
  flexDirection: 'column',
});

export const DataPageComponent: FC = () => {
  return (
    <div css={containerStyle}>
      <div>
        <TopUnderbarContainer
          visibleItems={topUnderbarVisibleItems}
        />
      </div>

      <DataTableDataPageContainer />
    </div>
  );
};
