import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { KeyboardKeys } from '~/_shared/utils/hooks/useKeyPress';
import {
  type MapKeyPressCallback,
  MapKeyPressPriority, useMapKeyPressContext,
} from '~/_shared/utils/hooks/useMapKeyPressContext';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  DRAWING_TOOL_CIRCLE_MAX_RADIUS, DRAWING_TOOL_CIRCLE_MIN_RADIUS,
} from '~/drawingTool/drawingTool.constants';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { useMap } from '~/map/map/mapContext';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  DrawingToolMode, useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingCircleSettingsState } from '~/store/mapSettings/drawing/settings/circle/drawingCircleSettings.state';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { type DrawingToolManager } from '../drawingToolManager';
import { DrawingToolCircleInstanceCircleContainer } from './drawingToolCircleInstanceCircle.container';

type DrawingToolCircleCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingCircleSettingsState;
}>;

export const DrawingToolCircleCreateNewInstanceContainer: FC<DrawingToolCircleCreateNewInstanceProps> = ({
  settings, manager,
}) => {
  const [circleCenter, setCircleCenter] = useState<LatLng | null>(null);
  const [temporaryCircleRadiusInPx, setTemporaryCircleRadiusInPx] = useState<number | null>(null);
  const drawingToolMode = useDrawingToolModeSelector();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const map = useMap();
  const { getDistanceBetweenPoints } = useProjectionOverlay(map);
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();

  const calculateRadiusInPx = useCallback((center: LatLng, radiusPosition: LatLng): number | null => {
    const radius = getDistanceBetweenPoints(center, radiusPosition);

    if (!radius) {
      return null;
    }

    return clamp(radius, { min: DRAWING_TOOL_CIRCLE_MIN_RADIUS, max: DRAWING_TOOL_CIRCLE_MAX_RADIUS });
  }, [getDistanceBetweenPoints]);

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (!event.latLng) {
      return;
    }

    // start drawing - set center
    if (!circleCenter) {
      setCircleCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });

      return;
    }

    // end drawing - add drawing to the map
    if (circleCenter) {
      const radiusInPx = calculateRadiusInPx(circleCenter, {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });

      if (!radiusInPx) {
        return;
      }

      const ratio = settings.scalesWithMapZoom ? sizePerPixelRatio : 1;

      const radius = clamp(radiusInPx, { min: DRAWING_TOOL_CIRCLE_MIN_RADIUS, max: DRAWING_TOOL_CIRCLE_MAX_RADIUS }) * ratio;

      const id = createUuid();
      dispatch(drawingItemsAddItem(
        id,
        {
          type: DrawingTool.Circle,
          value: {
            id,
            center: circleCenter,
            placement: DrawingItemPlacement.Default,
            settings: {
              ...settings,
              radius,
            },
          },
        }));

      setCircleCenter(null);
      setTemporaryCircleRadiusInPx(null);
    }
  }, [dispatch, circleCenter, calculateRadiusInPx, settings, sizePerPixelRatio]);

  const onMapMouseMove = useCallback((e: google.maps.MapMouseEvent) => {
    if (!circleCenter || !e.latLng) {
      return;
    }

    const radius = calculateRadiusInPx(circleCenter, {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    if (!radius) {
      return;
    }

    setTemporaryCircleRadiusInPx(radius);
  }, [circleCenter, calculateRadiusInPx]);

  useEffect(() => {
    if (drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);
    const mouseMoveListener = manager.addMapMouseMoveListener(onMapMouseMove);

    return () => {
      clickListener.remove();
      mouseMoveListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode, onMapMouseMove]);

  const { addMapKeyHandler } = useMapKeyPressContext();

  useEffect(() => { // Reset drawing on ESC
    if (!circleCenter) {
      return;
    }

    const onEsc: MapKeyPressCallback = (e) => {
      setCircleCenter(null);
      setTemporaryCircleRadiusInPx(null);

      e.stopPropagation();
    };

    const removeKeyHandler = addMapKeyHandler(KeyboardKeys.Escape, MapKeyPressPriority.ResetDrawing, onEsc);
    return removeKeyHandler;
  }, [addMapKeyHandler, circleCenter]);

  if (!circleCenter || !temporaryCircleRadiusInPx) {
    return null;
  }

  return (
    <DrawingToolCircleInstanceCircleContainer
      instance={{
        id: 'drawing-tool-drawing-new-circle',
        center: circleCenter,
        placement: DrawingItemPlacement.Default,
        settings: {
          ...settings,
          scalesWithMapZoom: false,
          radius: temporaryCircleRadiusInPx,
        },
      }}
      zIndex={zIndex}
    />
  );
};
