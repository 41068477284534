import { copy } from '../../_shared/utils/collections/collections';
import { type PresentationalColumnsRestrictionsAction } from './presentationalColumnsRestrictions.action';
import {
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_ERROR,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST, PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_SUCCESS,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_ERROR, PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_SUCCESS,
} from './presentationalColumnsRestrictions.actionTypes';
import {
  type PresentationalColumnsRestrictionsState, type PresentationalColumnsRestrictionsStateRestrictions,
  type PresentationalColumnStateRestriction,
} from './presentationalColumnsRestrictions.state';

const initialState: PresentationalColumnsRestrictionsState = {
  isLoading: false,
  restrictions: new Map(),
};

export const presentationalColumnsRestrictionsReducer = (
  state = initialState,
  action: PresentationalColumnsRestrictionsAction
): PresentationalColumnsRestrictionsState => {
  switch (action.type) {
    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_ERROR:
    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_SUCCESS: {
      return {
        ...state,
        restrictions: action.payload.restrictions,
        isLoading: false,
      };
    }

    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST:
    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_SUCCESS: {
      let newSpreadsheetRestrictions: PresentationalColumnsRestrictionsStateRestrictions = state.restrictions;

      for (const update of action.payload.updates) {
        const newValue: PresentationalColumnStateRestriction = {
          restrictionId: update.restrictionId,
          ...update.restrictionFlags,
        };

        const columnMap = state.restrictions.get(update.spreadsheetColumnId.spreadsheetId) ?? new Map();
        const newColumnRestrictions: ReadonlyMap<string, PresentationalColumnStateRestriction> =
          copy.andReplace(columnMap, update.spreadsheetColumnId.columnId, newValue);

        newSpreadsheetRestrictions =
          copy.andReplace(newSpreadsheetRestrictions, update.spreadsheetColumnId.spreadsheetId, newColumnRestrictions);
      }

      return {
        ...state,
        restrictions: newSpreadsheetRestrictions,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
