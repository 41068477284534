import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';

const frontendStateLeftSidebarSelector = (state: AppState) => state.frontendState.leftSidebar;

const frontendStateLeftSidebarOpenedAppSelector = (state: AppState) => frontendStateLeftSidebarSelector(state).openedApp;
export const useFrontendStateLeftSidebarOpenedAppSelector = () => useSelector(frontendStateLeftSidebarOpenedAppSelector);

const frontendStateLeftSidebarLastOpenedMapPageToolSelector = (state: AppState) => frontendStateLeftSidebarSelector(state).lastOpenedMapPageTool;
export const useFrontendStateLeftSidebarLastOpenedMapPageToolSelector = () => useSelector(frontendStateLeftSidebarLastOpenedMapPageToolSelector);

const frontendStateLeftSidebarOpenedMapToolSelector = (state: AppState) => frontendStateLeftSidebarSelector(state).openedMapTool;
export const useFrontendStateLeftSidebarOpenedMapToolSelector = () => useSelector(frontendStateLeftSidebarOpenedMapToolSelector);
