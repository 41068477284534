export const isTextEmpty = (text: string) => {
  return !/\S+/.test(text);
};

export const removeWhitespaces = (text: string): string => {
  return text.replace(/\s+/g, '');
};

// https://stackoverflow.com/questions/11089399/count-with-a-b-c-d-instead-of-0-1-2-3-with-javascript
export const convertNumberToLetters = (num: number): string => {
  const mod = num % 26;
  // eslint-disable-next-line no-bitwise
  let pow = (num / 26) | 0;
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');

  return pow ? convertNumberToLetters(pow) + out : out;
};
