import { css } from '@emotion/react';
import {
  faSearch, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons';
import {
  isCombinedSearchItem,
  isSearchItemWithColumn, isSingleGeolocatedSearchItem, type SearchItem,
} from '~/map/search/mapSearch.helpers';
import { ALL_COLUMNS_ID } from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.constants';
import { useTranslation } from '../../../utils/hooks';
import { ScrollAndStickyHorizontalSplitComponent } from '../../scrollAndStickyHorizontalSplit/scrollAndStickyHorizontalSplit.component';
import { SelectedItemComponent } from '../../selectedItem/selectedItem.component';

const containerStyle = css({
  width: '100%',
});

const selectedItemStyle = css({
  margin: '5px 10px 5px 0',
  fontSize: '12px',
});

type TopUnderbarSearchEntriesProps = Readonly<{
  items: SearchItem[];
  onItemRemove: (specificItem: SearchItem) => void;
  onRemoveAllSearchItems: () => void;
}>;

export const TopUnderbarSearchEntriesComponent: FC<TopUnderbarSearchEntriesProps> = (props) => {
  const [t] = useTranslation();

  const buttonProps = useMemo(() => [{
    buttonStyle: ButtonStyle.DangerInverted,
    onClick: props.onRemoveAllSearchItems,
    prefixIcon: faTrash,
    label: t('Delete all').toUpperCase(),
  }], [props.onRemoveAllSearchItems, t]);

  return (
    <div css={containerStyle}>
      <ScrollAndStickyHorizontalSplitComponent
        buttons={buttonProps}
        maximumHeight={106}
      >
        {props.items.map((item) => {
          const isGeolocatedText = (isSingleGeolocatedSearchItem(item) || isCombinedSearchItem(item)) ? t('Geographic Location') : null;
          const exactOrPartialWordMatchText = isSearchItemWithColumn(item) && item.columnId === ALL_COLUMNS_ID ? (
            item.exactWords ? t('Exact Word Match') : t('Partial Word Match')
          ) : null;
          const itemColumnText = isSearchItemWithColumn(item) && item.columnName ? item.columnName : null;
          const isBothText = isGeolocatedText && exactOrPartialWordMatchText ? `${exactOrPartialWordMatchText} + ${isGeolocatedText}` : null;
          const subText = isBothText ? isBothText : itemColumnText || isGeolocatedText || exactOrPartialWordMatchText;

          return (
            <SelectedItemComponent
              css={selectedItemStyle}
              key={item.id}
              onRemove={() => props.onItemRemove(item)}
            >
              {item.text}&nbsp;
              {subText && `(${subText})`}
            </SelectedItemComponent>
          );
        })}
      </ScrollAndStickyHorizontalSplitComponent>
    </div>
  );
};

export const renderSearchEntries = ({ items, removeSearchItem, removeAllSearchItems }: {
  items: SearchItem[];
  removeSearchItem: (item: SearchItem) => void;
  removeAllSearchItems: () => void;
}) => ({
  key: 'searchEntries',
  icon: faSearch,
  children: (
    <TopUnderbarSearchEntriesComponent
      items={items}
      onItemRemove={removeSearchItem}
      onRemoveAllSearchItems={removeAllSearchItems}
    />
  ),
});
