import {
  useCallback, useEffect, useRef,
} from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useIsMapSettingsSyncInProgressSelector } from '~/store/frontendState/processing/processing.selectors';

type SyncStatus = 'NOT_STARTED' | 'RUNNING' | 'FINISHED';

const CHECK_TICK = 100;
const CHECK_TIMEOUT = 5000;

export const useAwaitMapSettingsUpdate = () => {
  const syncInProgress = useIsMapSettingsSyncInProgressSelector();
  const previousSyncInProgress = usePrevious(syncInProgress);
  const syncStatus = useRef<SyncStatus>('NOT_STARTED');
  const isAfterTimeout = useRef<boolean>(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    if (syncInProgress && !previousSyncInProgress && syncStatus.current === 'NOT_STARTED') {
      syncStatus.current = 'RUNNING';
    }
    else if (!syncInProgress && previousSyncInProgress && syncStatus.current === 'RUNNING') {
      syncStatus.current = 'FINISHED';
    }
  }, [syncInProgress, previousSyncInProgress]);

  const syncFinishChecker = useCallback(async () => {
    if (syncStatus.current === 'FINISHED') {
      clearTimeout(timeout.current);
      return;
    }

    if (isAfterTimeout.current) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, CHECK_TICK));
    await syncFinishChecker();
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return useCallback((settingsChangeCallback: () => void) => {
    settingsChangeCallback();
    timeout.current = setTimeout(() => isAfterTimeout.current = true, CHECK_TIMEOUT);
    return syncFinishChecker();
  }, [syncFinishChecker]);
};
