import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { TextareaComponent } from '~/_shared/baseComponents/textarea/textarea.component';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import { ReCaptchaBrandingComponent } from '~/_shared/components/reCaptcha/reCaptchaBranding.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { removeWhitespaces } from '~/_shared/utils/text/text.helpers';
import { validateEmail } from '~/_shared/utils/throttle/validation/validation.helpers';
import {
  type ShareMapEmailResult, ShareMapEmailResults,
} from '~/map/map.repository';

type ShareMapCopyEmailProps = Readonly<{
  sendEmailResult: ShareMapEmailResult | null;
  onSubmit: (userEmails: string[], optionalMessage: string) => void;
}>;

const textareaStyle = css({
  marginTop: 10,
});

const buttonStyle = css({
  width: '100%',
  marginTop: 10,
});

const reCaptchaBrandingStyle = css({
  marginTop: 10,
});

export const ShareMapSendEmailComponent: FC<ShareMapCopyEmailProps> = (props) => {
  const [emails, setEmails] = useState('');
  const [optionalMessage, setOptionalMessage] = useState('');
  const [isInputValid, setIsInputValid] = useState(true);
  const [t] = useTranslation();
  const { sendEmailResult } = props;

  const userEmails = useMemo(() => {
    return removeWhitespaces(emails).split(',');
  }, [emails]);

  const validateUserEmail = useCallback(() => {
    for (const email of userEmails) {
      if (!validateEmail(email)) {
        return false;
      }
    }

    return true;
  }, [userEmails]);

  const onFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();

    if (!validateUserEmail()) {
      return;
    }

    const onSubmit = props.onSubmit;
    onSubmit(userEmails, optionalMessage);
  }, [optionalMessage, props, userEmails, validateUserEmail]);

  const onInputBlur = useCallback(() => {
    if (!emails) {
      return;
    }
    setIsInputValid(validateUserEmail());
  }, [emails, validateUserEmail]);

  useEffect(() => {
    if (!emails) {
      setIsInputValid(true);
    }

    if (!isInputValid) {
      setIsInputValid(validateUserEmail());
    }
  }, [validateUserEmail, emails, isInputValid]);

  const errorText = sendEmailResult === ShareMapEmailResults.CaptchaFailed ? t('Google reCaptcha has detected potential suspicious behaviour from your device or network. No email was sent.')
    : sendEmailResult === ShareMapEmailResults.InvalidData ? t('Sorry, we encountered a problem sending email. Please try again later.')
      : undefined;

  return (
    <form onSubmit={onFormSubmit}>
      <FormTextInputComponent
        autoTrim
        errorMessages={isInputValid ? undefined : [t('Provide valid email address')]}
        onChange={setEmails}
        onBlur={onInputBlur}
        value={emails}
        placeholder={t('Invite Users - separate emails with commas')}
        icon={faEnvelope}
      />

      <TextareaComponent
        css={textareaStyle}
        placeholder={t('Additional Message - optional')}
        onChange={setOptionalMessage}
        value={optionalMessage}
      />

      <FormErrorMessageComponent
        messages={errorText ? [errorText] : []}
        hideIcon
      />
      <ButtonComponent
        isSubmit
        css={buttonStyle}
        isDisabled={!validateUserEmail()}
        text={t('Send')}
      />
      <ReCaptchaBrandingComponent css={reCaptchaBrandingStyle} />
    </form>
  );
};
