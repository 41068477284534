import {
  type Maneuver,
  type RouteLeg,
  UnitSystem,
} from '../../_shared/types/googleMaps/googleMaps.types';
import { formatDistance } from '../../_shared/utils/distance/distance.helpers';
import { type TranslationFnc } from '../../_shared/utils/hooks';

export const getDistanceLabel = (distance: number, unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    const miles = distance / 1609.344;
    let remainder = 0;
    if (distance < 1) {
      remainder = distance - (Math.floor(miles) * 1609.344);
      remainder = Math.floor(3.2808399 * remainder);
    }

    return formatDistance(miles, remainder, unitSystem);
  }
  else {
    const km = distance / 1000;
    let remainder = 0;
    if (distance < 1) {
      remainder = distance - (Math.floor(km) * 1000);
      remainder = Math.floor(remainder);
    }

    return formatDistance(km, remainder, unitSystem);
  }
};

export const getDurationLabel = (duration: number, t: TranslationFnc) => {
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = duration - (hours * 3600) - (minutes * 60);
  if (seconds >= 30 && minutes) {
    if (minutes <= 58) {
      minutes++;
    }
    else {
      minutes = 0;
      hours++;
    }
  }

  let result = '';
  if (hours === 1) {
    result += ` ${t('{{hour}} hour', { hour: hours.toString() })} `;
  }
  else if (hours > 1) {
    result += ` ${t('{{hours}} hours', { hours: hours.toString() })} `;
  }
  if (minutes === 1) {
    result += ` ${t('{{minute}} min', { minute: minutes.toString() })} `;
  }
  else if (minutes > 1) {
    result += ` ${t('{{minutes}} mins', { minutes: minutes.toString() })} `;
  }

  return result.trim();
};

export const getRouteEstimation = (legs: ReadonlyArray<RouteLeg>): {
  distance: number;
  duration: number;
} => legs.reduce(({ distance, duration }, leg) => ({
  distance: distance + (leg.distance?.value || 0),
  duration: duration + (leg.duration?.value || 0),
}),
{ distance: 0, duration: 0 });

const maneuverBackgroundPosition: {[key in Maneuver]: string} = {
  ferry: '0 -614px',
  'ferry-train': '0 -566px',
  merge: '0 -143px',
  straight: '0 -534px',
  'fork-left': '0 -550px',
  'ramp-left': '0 -598px',
  'roundabout-left': '0 -197px',
  'turn-left': '0 -413px',
  'turn-sharp-left': '0 0',
  'turn-slight-left': '0 -378px',
  'uturn-left': '0 -305px',
  'fork-right': '0 -499px',
  'ramp-right': '0 -429px',
  'roundabout-right': '0 -232px',
  'turn-right': '0 -483px',
  'turn-sharp-right': '0 -582px',
  'turn-slight-right': '0 -51px',
  'uturn-right': '0 -35px',
};

export const getManeuverBackgroundPosition = (maneuver: Maneuver): string => {
  return maneuverBackgroundPosition[maneuver];
};
