import { type DeepWritable } from 'ts-essentials';
import { MapAreaColors } from '~/_shared/constants/mapObjects/mapAreaColors.constants';
import { mapAreaBorder } from '../../constants/mapObjects/mapAreaBorder.constants';
import {
  type GroupRadiusData,
  type GroupRadiusProximity,
  isGroupRadius,
  type Proximity, type ProximityStyles, type RadiusGroupData,
} from '../../types/proximity/proximity.types';
import { mixColorMemoized } from '../colors/colors.helpers';
import { cloneDeep } from '../object/deepMerge';

const highlightedStyle: Partial<ProximityStyles> = {
  color: MapAreaColors.highlight.fillColor,
  borderColor: MapAreaColors.highlight.borderColor,
  fillOpacity: .4,
  borderOpacity: 1,
  borderWidth: mapAreaBorder.active.minWidth,
};

const activeStyle: Partial<ProximityStyles> = {
  color: MapAreaColors.active.fillColor,
  borderColor: MapAreaColors.active.borderColor,
  fillOpacity: .6,
  borderOpacity: 1,
  borderWidth: mapAreaBorder.active.minWidth,
};

const applyProximityExtraStyles = (baseStyles: ProximityStyles, extraStyles: Partial<ProximityStyles>): ProximityStyles => ({
  ...baseStyles,
  ...extraStyles,
  ...(extraStyles.color ? { color: mixColorMemoized(baseStyles.color, extraStyles.color).toHex() } : {}),
  ...(extraStyles.borderWidth ? { borderWidth: Math.max(baseStyles.borderWidth + 2, extraStyles.borderWidth) } : {}),
});

const computeGroupIndividualStyles = (proximity: GroupRadiusProximity, isHovered: boolean, isActive: boolean, borderColor?: string): GroupRadiusData => {
  const newOverrides = cloneDeep<DeepWritable<RadiusGroupData['individualOverride']>>(proximity.data.data.individualOverride);

  Object.values(newOverrides).forEach(spreadsheetStyles => Object.values(spreadsheetStyles).forEach(rowStyles => {
    if (rowStyles?.styles) {
      rowStyles.styles = {
        ...computeProximityStyles(rowStyles.styles, isHovered, isActive),
        ...(borderColor ? { borderColor } : undefined),
      };
    }
  }));

  return {
    ...proximity.data,
    data: {
      ...proximity.data.data,
      individualOverride: newOverrides,
    },
  };
};

export const computeProximityStyles = (baseStyles: ProximityStyles, isHovered: boolean, isActive: boolean): ProximityStyles => {
  if (isActive) {
    return applyProximityExtraStyles(baseStyles, activeStyle);
  }

  if (isHovered) {
    return applyProximityExtraStyles(baseStyles, highlightedStyle);
  }

  return baseStyles;
};

export const changeProximityStyle = <T extends Proximity>(proximity: T, isHovered: boolean, isActive: boolean, borderColor?: string): T => {

  const computedStyles = computeProximityStyles(proximity.styles, isHovered, isActive);

  return ({
    ...proximity,
    styles: {
      ...computedStyles,
      borderColor: borderColor ?? computedStyles.borderColor,
    },
    data: isGroupRadius(proximity) ? computeGroupIndividualStyles(proximity, isHovered, isActive, borderColor) : proximity.data,
  });
};
