import {
  call,
  delay,
  put,
  takeLatest,
} from '@redux-saga/core/effects';
import { type FileId } from '~/_shared/types/file.types';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { fileUrlsSelector } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { getBulkFiles } from '~/store/mapSettings/fileAttachments/fileApi.helpers';
import {
  isSuccessFile,
  type UserFileResponse, type UserFilesResponse,
} from '~/store/mapSettings/fileAttachments/fileApi.repository';
import { type FileUrlsAction } from './fileUrls.action';
import { fileUrlsUpdate } from './fileUrls.actionCreator';
import { FILE_URLS_UPDATE_REQUEST } from './fileUrls.actionTypes';
import { type FileUrl } from './fileUrls.state';

const MINUTES_30 = 30 * 60 * 1000;
const MINUTES_60 = 60 * 60 * 1000;

export function* fileUrlsSaga() {
  yield takeLatest(FILE_URLS_UPDATE_REQUEST, onFileUrlsUpdateRequest);
  yield scheduleExpiredUrlsUpdate();
}

function* onFileUrlsUpdateRequest(action: PickAction<FileUrlsAction, typeof FILE_URLS_UPDATE_REQUEST>) {
  yield* updateFileUrls(action.payload.files);
}

function* scheduleExpiredUrlsUpdate() {
  while (true) {
    yield delay(MINUTES_30);
    yield* updateExpiredUrls();
  }
}

function* updateExpiredUrls() {
  const clientId: number|null = yield select<number|null>(state => state.userData.clientId);
  const mapId: number|null = yield select<number|null>(state => state.map.mapId);
  const fileUrls: ReadonlyMap<number, FileUrl> = yield select<ReadonlyMap<number, FileUrl>>(fileUrlsSelector);

  const currentDateWithBuffer = new Date(new Date().getTime() + MINUTES_60);

  const filesToFetch: number[] = [];
  for (const [fileId, fileUrl] of fileUrls.entries()) {
    if (fileUrl.expiration < currentDateWithBuffer) {
      filesToFetch.push(fileId);
    }
  }

  if (filesToFetch.length) {
    const refetchedFiles: UserFilesResponse = yield call(getBulkFiles, clientId, filesToFetch, mapId);

    if (refetchedFiles?.list.length) {
      yield updateFileUrls(refetchedFiles.list);
    }
  }
}

export function* updateFileUrls(files: ReadonlyArray<UserFileResponse>) {
  const originalFileUrlsMap: Map<FileId, FileUrl> = yield select(fileUrlsSelector);
  const fileUrlsMap: Map<FileId, FileUrl> = new Map<FileId, FileUrl>(originalFileUrlsMap);

  files.filter(isSuccessFile).forEach(file => {
    fileUrlsMap.set(file.id, {
      fileId: file.id,
      original: file.links.original,
      expiration: new Date(new Date().getTime() + 12 * 60 * 60 * 1000),
      '200p': file.links['200p'] || file.links.original,
      '600p': file.links['600p'] || file.links.original,
    });
  });

  yield put(fileUrlsUpdate(fileUrlsMap));
}
