import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { MapShapePath } from '~/map/map/mapObjects/mapShape/mapShapePath.component';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemPolyline } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';

type MapPolylineInstanceProps = {
  instance: DrawingItemPolyline;
};

const DrawingToolPolylineInstanceContainer: FC<MapPolylineInstanceProps> = ({ instance }) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut: onPolylineMouseOut, onMouseOver: onPolylineMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const onPolylineClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Polyline,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  const onPolylineRightClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Polyline,
      value: instance,
    });
  }, [onRightClick, instance, drawingEventsEnabledRef]);

  const renderPath = useCallback((key: string, points: ReadonlyArray<LatLng>) => {
    const borderColor = convertColorToWebGLColor(instance.settings.strokeColor, instance.settings.strokeOpacity / 100);

    return (
      <MapShapePath
        key={key}
        id={key}
        points={points}
        visuals={{
          width: instance.settings.strokeWeight,
          style: 'solid',
          color: borderColor,
        }}
        onMouseOver={onPolylineMouseOver}
        onMouseOut={onPolylineMouseOut}
        onClick={onPolylineClick}
        onRightClick={onPolylineRightClick}
      />
    );
  }, [instance.settings.strokeWeight, instance.settings.strokeOpacity, instance.settings.strokeColor,
    onPolylineMouseOver, onPolylineMouseOut, onPolylineClick, onPolylineRightClick]);

  return (
    <MapShape
      shape={instance}
      zIndex={zIndex}
      renderPath={renderPath}
      isPolygon={false}
    />
  );
};

const pureComponent = memo(DrawingToolPolylineInstanceContainer);
export { pureComponent as DrawingToolPolylineInstanceContainer };
