import type { CancelToken } from 'axios';
import type {
  CombinedRowId, SpreadsheetId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';

export type ItemizedExportId = string;

export enum ItemizedExportStatus {
  INITIALIZED = 'INITIALIZED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  SUCCESS = 'success',
}

type ItemizedSpreadsheetDataRequest = Readonly<{
  additional_prefix_columns?: { column_name: string }[];
  additional_postfix_columns?: { column_name: string }[];
  as_text?: true;
  extension?: 'csv' | 'tsv' | 'xlsx';
  items: readonly Readonly<{
    rows: readonly Readonly<{
      row_id?: Readonly<{
        row_id: CombinedRowId;
        spreadsheet_id: SpreadsheetId;
      }>;
      prefix_columns_values: readonly string[];
      postfix_columns_values: readonly string[];
    }>[];
  }>[];
}>;

export type ItemizedSpreadsheetDataFileResponse = Readonly<{ //if request.as_text !== true, a file url for download is returned
  message: ItemizedExportStatus;
  data: {
    file?: string;
  };
}>;

export type ItemizedSpreadsheetDataTextResponse = Readonly<{ //if request.as_text === true
  message: ItemizedExportStatus;
  data: {
    data?: string;
  };
}>;

export type ItemizedSpreadsheetDataResponse = ItemizedSpreadsheetDataFileResponse & ItemizedSpreadsheetDataTextResponse;

export type GetItemizedSpreadsheetDataResponse = Readonly<{
  message: ItemizedExportStatus;
  hash: ItemizedExportId;
}>;

export const getItemizedSpreadsheetData = (
  clientId: number, request: ItemizedSpreadsheetDataRequest, mapId?: number, cancelToken?: CancelToken
): Promise<GetItemizedSpreadsheetDataResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/export/itemized-rows` + (mapId ? `?map_id=${mapId}` : '');

  const requestConfig = {
    cancelToken,
  };

  return apiPost(requestUrl, request, requestConfig);
};

export const getItemizedSpreadsheetDataResults = (
  clientId: number, itemizedExportId: ItemizedExportId, mapId?: number
): Promise<ItemizedSpreadsheetDataResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/export/itemized-rows/result/${itemizedExportId}` + (mapId ? `?map_id=${mapId}` : '');

  return apiGet(requestUrl);
};
