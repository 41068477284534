import { useMemo } from 'react';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { type DrawingToolScalingItemProps } from './useDrawingToolItemScalingToggle';

// Returns pixel size for current zoom level
export const useDrawingToolItemSizeForCurrentZoom = (props: DrawingToolScalingItemProps) => {
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  return useMemo<number>(() => {
    const ratio = props.scalesWithMapZoom ? sizePerPixelRatio : 1;

    return clamp(Math.round(props.itemSize / ratio), { min: props.min, max: props.max });
  }, [props.max, props.min, props.scalesWithMapZoom, props.itemSize, sizePerPixelRatio]);
};
