export enum ExportDataFileType {
  Xlsx = 'xlsx',
  Tsv = 'tsv',
  Csv = 'csv',
}

export const renderFileType = (fileType: ExportDataFileType): string => {
  switch (fileType) {
    case ExportDataFileType.Csv:
      return '.csv';
    case ExportDataFileType.Tsv:
      return '.tsv';
    case ExportDataFileType.Xlsx:
      return '.xlsx';
    default:
      throw new Error(`exportDataFileType.enum: unknown file type "${fileType}".`);
  }
};
