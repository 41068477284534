import { useMemo } from 'react';
import {
  MapPrivacyLevel, type MapSource,
} from '~/_shared/types/map';
import { useTranslation } from '~/_shared/utils/hooks';

export type MapPrivacyRestrictions = {
  isValid: boolean;
  warning?: string;
  disabledPrivacyLevels: ReadonlyArray<MapPrivacyLevel>;
};

export const useMapPrivacyRestrictions = (
  currentMapPrivacyLevel?: MapPrivacyLevel,
  parentMapPrivacyLevel?: MapPrivacyLevel,
  mapSource?: MapSource,
): MapPrivacyRestrictions => {

  const [t] = useTranslation();

  return useMemo(() => {
    let warning = '';
    let isValid = true;
    const disabledOptions = [];

    if (mapSource === 'salesforce') {
      return {
        isValid: false,
        warning: t('Salesforce maps cannot have their security status changed.'),
        disabledPrivacyLevels: [MapPrivacyLevel.Private, MapPrivacyLevel.PasswordProtected, MapPrivacyLevel.Public],
      };
    }

    if (parentMapPrivacyLevel) {
      switch (parentMapPrivacyLevel) {
        case MapPrivacyLevel.Private:
          warning = t('Cannot change Security Status for this Map View when it\'s Main Map is Private.');
          disabledOptions.push(MapPrivacyLevel.Public, MapPrivacyLevel.PasswordProtected);
          if (currentMapPrivacyLevel !== MapPrivacyLevel.Private) {
            isValid = false;
          }
          break;
        case MapPrivacyLevel.Public:
          if (currentMapPrivacyLevel === MapPrivacyLevel.PasswordProtected) {
            warning = t('Please add password to the main map to be able to password protect your Map View.');
            disabledOptions.push(MapPrivacyLevel.PasswordProtected);
            isValid = false;
          }
          break;
        case MapPrivacyLevel.PasswordProtected:
          if (currentMapPrivacyLevel === MapPrivacyLevel.Public) {
            warning = t('Please remove password from the main map to be able to make your Map View public.');
            disabledOptions.push(MapPrivacyLevel.Public);
            isValid = false;
          }
          break;
        default:
          break;
      }
    }

    return {
      isValid,
      disabledPrivacyLevels: disabledOptions,
      warning,
    };
  }, [currentMapPrivacyLevel, parentMapPrivacyLevel, t, mapSource]);
};
