import { useCallback } from 'react';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { type BoundaryGroupDemographic } from '~/store/boundaryGroups/boundaryGroups.repository';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';

export const useBoundaryGroupDemographics = () => {
  const boundaryGroups = useBoundaryGroupsSelector();

  const getDemographic = useCallback((boundaryGroupId: number, demographicId: string): BoundaryGroupDemographic | null =>
    getBoundaryGroupDemographic(boundaryGroups, boundaryGroupId, demographicId), [boundaryGroups]);

  return {
    getDemographic,
  };
};

export const getBoundaryGroupDemographic = (
  boundaryGroups: ReadonlyArray<BoundaryGroup>,
  boundaryGroupId: number,
  demographicId: string
): BoundaryGroupDemographic | null => {
  const boundaryGroup = boundaryGroups.find(group => group.id === boundaryGroupId);
  return boundaryGroup?.demographics.find(demographic => demographic.id === demographicId) ?? null;
};
