import { type MapSettingsExportImageSettingsState } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.state';

type ConfigType = Readonly<{
  MODE: 'development' | 'production';
  API_URL?: string;
  ENABLE_MIGRATE_ALL: boolean;
  ENABLE_MIGRATION: boolean;
  ENABLE_PLACES: boolean;
  GOOGLE_APP_ID: string;
  GOOGLE_MAPS_API_KEY: string;
  INTERCOM_APP_ID: string;
  PUBLIC_MAPS_URL?: string;
  RECAPTCHA_KEY: string;
  SHOW_SIGNUP: boolean;
  SOCKET_URL?: string;

  // Simulate the state of the export image settings
  EXPORT_IMAGE_SETTINGS?: Partial<MapSettingsExportImageSettingsState>;
}>;

const defaultConfig: ConfigType = {
  MODE: 'development',
  ENABLE_MIGRATE_ALL: false,
  ENABLE_MIGRATION: true,
  ENABLE_PLACES: true,
  GOOGLE_APP_ID: '143084119377',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAbt4SDtPzrsVwCE5pLvL5iPEHyjKZICWI',
  INTERCOM_APP_ID: 'odvc6gwb',
  RECAPTCHA_KEY: '6Ld8yUojAAAAAJIGu33P9O52_w9yY6l9ewwrNkB8',
  SHOW_SIGNUP: true,
};

const localStorageConfig = localStorage.getItem('ENV_CONFIG') ?? '{}';

const parseLocalStorageConfig = (jsonConfig: string) => {
  try {
    return JSON.parse(jsonConfig);
  }
  catch (e) {
    console.error('Malformed JSON in localStorage.ENV_CONFIG.', e);
    return {};
  }
};

export const CONFIG: ConfigType = {
  ...defaultConfig,
  ...(self as any)?._envConfig,
  ...parseLocalStorageConfig(localStorageConfig),
};
