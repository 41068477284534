import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type ReactElement, type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type ModalProps } from '~/modal/modalType.enum';
import { ModalComponent } from './modal.component';

const modalStyle = css({
  minHeight: 0,
});

const noteContainerStyle = css({
  padding: '14px',
});

const noteSubTitleStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const iconStyle = ({ textColor }: { textColor: string }) =>
  css({
    color: textColor,
    fontSize: '16px',
    marginRight: '8px',
  });

const subTitleStyle = ({ textColor }: { textColor: string }) =>
  css({
    color: textColor,
    fontSize: '20px',
  });

const textStyle = ({ textColor }: { textColor: string }) =>
  css({
    color: textColor,
    fontSize: '16px',
    marginTop: '16px',
    display: 'block',
  });

type NoteModalComponentProps = ModalProps<{
  title: string;
  icon?: IconProp;
  subTitle: string;
  text?: string | ReactElement;
  textColor: string;
  confirmButton?: ReactNode;
  isCloseDisabled?: boolean;
}>;

export const NoteModalComponent = (props: NoteModalComponentProps) => {
  const {
    title,
    icon,
    text,
    subTitle,
    textColor,
    isOpen,
    onClose,
    confirmButton,
    isCloseDisabled,
  } = props;

  return (
    <ModalComponent
      caption={title}
      isOpen={isOpen}
      onClose={onClose}
      css={modalStyle}
      confirmButton={confirmButton}
      isCloseDisabled={isCloseDisabled}
    >
      <div css={noteContainerStyle}>
        <div css={noteSubTitleStyle}>
          {icon && (
            <span css={iconStyle({ textColor })}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
          <span css={subTitleStyle({ textColor })}>{subTitle}</span>
        </div>
        {typeof text === 'string' && <span css={textStyle({ textColor })}>{text}</span>}
        {typeof text !== 'string' && text}
      </div>
    </ModalComponent>
  );
};
