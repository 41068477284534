import { CONFIG } from '~/_shared/constants/config';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../app.store';
import { exportImageSettingsInitialState } from './exportImageSettings.reducer';
import { type MapSettingsExportImageSettingsState } from './exportImageSettings.state';

const DEBUG_EXPORT_IMAGE_SETTINGS = !!CONFIG.EXPORT_IMAGE_SETTINGS;
const DEBUG_EXPORT_IMAGE_STATE: MapSettingsExportImageSettingsState = {
  ...exportImageSettingsInitialState,
  ...CONFIG.EXPORT_IMAGE_SETTINGS,
};

export const mapSettingsExportImageSettingsSelector = (state: AppState) => {
  if (DEBUG_EXPORT_IMAGE_SETTINGS) {
    return DEBUG_EXPORT_IMAGE_STATE;
  }

  return state.map.mapSettings.data.toolsState?.exportImageSettings;
};
export const useMapSettingsExportImageSettingsSelector = () => useSelector(mapSettingsExportImageSettingsSelector);
const mapSettingsExportImageSettingsModeSelector = (state: AppState) => mapSettingsExportImageSettingsSelector(state)?.mode;
export const useMapSettingsExportImageSettingsModeSelector = () => useSelector(mapSettingsExportImageSettingsModeSelector);
