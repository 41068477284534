import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { SwitchOptionsComponent } from '~/_shared/components/switchOptions/switchOptions.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { ContinueButton } from '~/map/layered/createLayeredMapModal/ContinueButton';
import {
  BuildingBlockSelections,
  BuildingBlockSubsetTypes,
} from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import { type GeoUnits } from '~/store/boundaries/boundaries.repository';
import { type BoundaryId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BuildingBlock, buildingBlockFromGeoUnits, buildingBlockToGeoUnit, getAllStatesFlagForBluildingBlock, getBuildingBlockLabel,
  supportedBuildingBlocks,
} from './buildingBlocks.helpers';

// From data option si currently disabled thus the entire selector is not shown
const SUBSET_TYPE_SELECTION_ENABLED = false;

const rowGap = 20;
const gridContainerStyle = ({ showSubset }: {showSubset: boolean}) => css({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateAreas: `
    'blockTypeLabel blockTypeLabel'
    'blockType subset'
    ${showSubset ? `
      'subsetLabel subsetLabel'
      ${SUBSET_TYPE_SELECTION_ENABLED ? '\'subsetSelect goToMap\'' : '\'goToMap goToMap\''}
    ` : ''}
    'selectedBlocks continue'
  `,
  alignItems: 'center',
  justifyItems: 'start',
  gap: `${rowGap}px 15px`,
});

const labelStyle = css({
  marginBottom: 5 - rowGap,
});

export type BuildingBlockStepComponentProps = Readonly<{
  buildingBlock: GeoUnits | null;
  allStates: boolean;
  onChange: (value: GeoUnits, allStates: boolean) => void;
  onContinue: () => void;
  buildingBlockSelection: number;
  buildingBlockSubsetType: string;
  selectedBoundaryIds?: ReadonlyArray<BoundaryId>;
  onSelectSubsetFromMap: () => void;
  onChangeBuildingBlockSelection: (value: number) => void;
  onChangeBuildingBlockSubsetType: (value: string) => void;
}>;

export const BuildingBlockStepComponent: FC<BuildingBlockStepComponentProps> = ({
  allStates,
  buildingBlock,
  onChange,
  onContinue,
  onSelectSubsetFromMap,
  buildingBlockSelection,
  buildingBlockSubsetType,
  selectedBoundaryIds,
  onChangeBuildingBlockSelection,
  onChangeBuildingBlockSubsetType,
}: BuildingBlockStepComponentProps) => {
  const [t] = useTranslation();

  const value = useMemo(() => buildingBlockFromGeoUnits(buildingBlock, allStates), [allStates, buildingBlock]);

  const onChangeBuildingBlocks = useCallback(
    (block: BuildingBlock) => onChange(buildingBlockToGeoUnit(block), getAllStatesFlagForBluildingBlock(block)),
    [onChange]
  );

  const options = useMemo(() =>
    supportedBuildingBlocks.map(block => ({
      name: getBuildingBlockLabel(block, t),
      value: block,
    })), [t]);

  const subsetOptions = useMemo<{
    name: string;
    value: string;
  }[]>(() => ([
    {
      name: t('boundaryAI.On Map'),
      value: BuildingBlockSubsetTypes.ON_MAP_BY_LASSO,
    },
    {
      name: t('boundaryAI.From Data'),
      value: BuildingBlockSubsetTypes.FROM_DATA,
    },
  ]), [t]);

  const isManualSelectionRequired = useMemo(() =>
    !value || (
      buildingBlockSelection === BuildingBlockSelections.SUBSET &&
      buildingBlockSubsetType !== BuildingBlockSubsetTypes.FROM_DATA &&
      !selectedBoundaryIds?.length
    ), [value, buildingBlockSubsetType, buildingBlockSelection, selectedBoundaryIds]
  );

  const isSubmitDisabled = !value || isManualSelectionRequired;
  const showSubset = buildingBlockSelection === 1;
  const blockTypeLabel = options.find(o => o.value === value)?.name;

  return (
    <div css={gridContainerStyle({ showSubset })}>
      <span css={[{ gridArea: 'blockTypeLabel' }, labelStyle]}>{t('boundaryAI.Building Block Type')}</span>
      <RegularDropdownComponent<BuildingBlock>
        css={[{ gridArea: 'blockType', justifySelf: 'stretch' }]}
        onChange={onChangeBuildingBlocks}
        value={value}
        options={options}
        placeholder={t('boundaryAI.Select Building Block Type')}
        inPortal
        triggerStyle={css({ textTransform: 'capitalize' })}
      />
      <SwitchOptionsComponent
        css={{ gridArea: 'subset', justifySelf: 'end' }}
        disabled={!value}
        option1={t('all').toUpperCase()}
        option2={t('boundaryAI.A subset of').toUpperCase()}
        option1Tooltip={blockTypeLabel && t('boundaryAI.Include all {{blocks}} for the territory generation', { blocks: blockTypeLabel })}
        option2Tooltip={blockTypeLabel && t('boundaryAI.Include only a subset of {{blocks}} for the territory generation', { blocks: blockTypeLabel })}
        selected={buildingBlockSelection}
        onChange={onChangeBuildingBlockSelection}
      />
      {
        showSubset && (
          <>
            <span css={[{ gridArea: 'subsetLabel' }, labelStyle]}>
              {SUBSET_TYPE_SELECTION_ENABLED ? t('boundaryAI.Choose Subset Selection Method') : t('boundaryAI.Select the subset area on map')}
            </span>
            {SUBSET_TYPE_SELECTION_ENABLED && (
              <RegularDropdownComponent
                css={{ gridArea: 'subsetSelect', justifySelf: 'stretch' }}
                onChange={onChangeBuildingBlockSubsetType}
                value={buildingBlockSubsetType}
                options={subsetOptions}
                inPortal
              />
            )}
            {
              buildingBlockSubsetType !== BuildingBlockSubsetTypes.FROM_DATA && (
                <ButtonComponent
                  css={{
                    gridArea: 'goToMap',
                    width: !SUBSET_TYPE_SELECTION_ENABLED ? 140 : undefined,
                    justifySelf: SUBSET_TYPE_SELECTION_ENABLED ? 'stretch' : undefined,
                  }}
                  buttonStyle={ButtonStyle.Secondary}
                  text={selectedBoundaryIds?.length ? t('Edit Selection') : t('Go To Map')}
                  onClick={onSelectSubsetFromMap}
                />
              )}
          </>
        )
      }
      {selectedBoundaryIds?.length ? (
        <span css={{ gridArea: 'selectedBlocks' }}>
          {t('boundaryAI.{{count}} blocks selected', { count: selectedBoundaryIds.length })}
        </span>
      ) : null}
      <ContinueButton
        css={{ gridArea: 'continue', justifySelf: 'end' }}
        label={t('Continue')}
        onSubmitClick={onContinue}
        disabled={isSubmitDisabled}
      />
    </div>
  );
};
