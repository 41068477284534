import { call } from 'redux-saga/effects';
import { type SpreadsheetDataBulkResponse } from '../../../spreadsheet/spreadsheet.repository';
import { type MapSettingsFilterSettings } from '../../../store/mapSettings/filtering/mapSettingsFiltering.state';
import { type MatchupDataState } from '../../../store/matchupData/matchupData.state';
import { clientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { mapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { type GroupingColumn } from '../../types/grouping/grouping';
import { select } from '../saga/effects';
import { createDefaultSpreadsheetColumnMetrics } from './defaultMetric.factory';
import { getNumericColumns } from './defaultMetric.helpers';

export function* getDefaultSpreadsheetColumnMetrics() {
  const existingGroups: GroupingColumn[] = yield select<GroupingColumn[]>(state => state.map.mapSettings.data.grouping.activeGroupColumns);
  const existingFilters: MapSettingsFilterSettings = yield select<MapSettingsFilterSettings>(state => state.map.mapSettings.data.filtering.settings);
  const matchupData: MatchupDataState = yield select<MatchupDataState>(state => state.spreadsheet.matchupData);
  const clientId: number | null = yield select<number | null>(clientIdSelector);
  const mapId: number | null = yield select<number | null>(mapIdSelector);
  let numericColumns: SpreadsheetDataBulkResponse[] = [];

  if (clientId && mapId) {
    numericColumns = yield call(getNumericColumns, clientId, mapId, matchupData);
  }

  return createDefaultSpreadsheetColumnMetrics(existingGroups, existingFilters, numericColumns, matchupData);
}
