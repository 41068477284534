import { useMemo } from 'react';
import {
  type GroupingColumnNumeric, type GroupingColumnText, isGroupingColumnNumeric, isGroupingColumnText,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import {
  getItemNumericBucketFromBuckets,
  getNumericalGroupBucketsForSpreadsheetData,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  DataType, type GroupId, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';

export type TextGroupInfo = {
  groupingColumn: GroupingColumnText;
  uniqueGroupIndex?: number; //optional, helps to speed up this hook
  groupId: GroupId;
};

export type NumericGroupInfo = {
  groupingColumn: GroupingColumnNumeric;
  bucketId: number;
};

export type GroupInfo = TextGroupInfo | NumericGroupInfo;

export const useGetSpreadsheetRowIdsByGroupInfo = (groupInfo: GroupInfo | null): SpreadsheetRowId[] => {
  const spreadsheetDataData = useSpreadsheetDataDataSelector();
  const latLngSpreadsheetData = useLatLngSpreadsheetData();

  return useMemo(() => {
    if (groupInfo && spreadsheetDataData) {

      const spreadsheetId = groupInfo.groupingColumn.spreadsheetId;
      const columnId = groupInfo.groupingColumn.columnId;
      const spreadsheetRows = latLngSpreadsheetData.getSpreadsheet(spreadsheetId);

      if (spreadsheetRows) {
        if (isGroupingColumnNumeric(groupInfo.groupingColumn)) {
          const numericGroupInfo = groupInfo as NumericGroupInfo;
          const spreadsheetDataGroupValues = spreadsheetDataData.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.NUMBER];
          if (spreadsheetDataGroupValues) {
            const buckets = getNumericalGroupBucketsForSpreadsheetData(numericGroupInfo.groupingColumn, spreadsheetDataData);
            const results = [];
            for (const row of spreadsheetRows) {
              const value = spreadsheetDataGroupValues.values[row.rowId];
              const itemBucket = getItemNumericBucketFromBuckets(
                value ?? null,
                numericGroupInfo.groupingColumn.valueType,
                spreadsheetDataGroupValues.extra.min ?? 0,
                spreadsheetDataGroupValues.extra.max ?? 0,
                buckets,
              );
              if (itemBucket.id === numericGroupInfo.bucketId) {
                results.push(row);
              }
            }
            return results;
          }
        }
        else if (isGroupingColumnText(groupInfo.groupingColumn)) {
          const spreadsheetDataGroupValues = spreadsheetDataData.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP];
          if (spreadsheetDataGroupValues) {
            const textGroupInfo = groupInfo as TextGroupInfo;
            const uniqueGroupIndex = textGroupInfo.uniqueGroupIndex ?? (
              spreadsheetDataGroupValues.extra.uniqueGroups.findIndex(g => g.id === textGroupInfo.groupId)
            );
            if (uniqueGroupIndex !== null && !isNaN(uniqueGroupIndex) && uniqueGroupIndex !== -1) {
              const results = [];
              for (const row of spreadsheetRows) {
                const value = spreadsheetDataGroupValues.values[row.rowId];
                if (value === uniqueGroupIndex) {
                  results.push(row);
                }
              }
              return results;
            }
          }
        }
      }
    }
    return [];
  }, [groupInfo, latLngSpreadsheetData, spreadsheetDataData]);
};
