import '../../types/emotion-react.types';
import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type ReactNode } from 'react';
import { stringify } from '~/_shared/utils/string/stringify';
import { RadioComponent } from './radio.component';

const itemStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
});

const groupWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const childWrapperStyle = css({
  flexGrow: 1,
  position: 'relative',
  boxSizing: 'border-box',
});

const radioGroupRadioButtonStyle = css({
  flexGrow: 1,
});

export type RadioGroupItem<T> = {
  children?: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
  isDisabled?: boolean;
  label?: ReactNode;
  radioIconStyle?: SerializedStyles;
  style?: SerializedStyles;
  value: T;
  tooltip?: string;
};

export type RadioGroupProps<T> = Readonly<{
  className?: string;
  itemCommonStyle?: SerializedStyles;
  items: ReadonlyArray<RadioGroupItem<T>>;
  labelStyle?: SerializedStyles;
  selectedValue: T | null;

  onValueChange: (value: T) => void;
  radioIconStyle?: (isActive: boolean) => SerializedStyles;
  selectValueId?: (value: T) => string;
}>;

export const RadioGroupComponent = <T extends string>({
  selectValueId = stringify,
  className,
  items,
  itemCommonStyle,
  labelStyle,
  selectedValue,
  onValueChange,
  radioIconStyle,
  ...restProps
}: RadioGroupProps<T>) => {
  return (
    <div
      {...restProps}
      className={className}
      css={groupWrapperStyle}
    >
      {items.map(item => (
        <div
          css={css(itemStyle, itemCommonStyle, item.style)}
          key={selectValueId(item.value)}
        >
          <RadioComponent
            css={[labelStyle, radioGroupRadioButtonStyle]}
            isChecked={item.value === selectedValue}
            onChange={onValueChange}
            label={item.label}
            isDisabled={item.isDisabled}
            description={item.description}
            value={item.value}
            icon={item.icon}
            selectId={selectValueId}
            radioIconStyle={radioIconStyle}
            tooltip={item.tooltip}
          />

          {item.children && (
            <div css={childWrapperStyle}>
              {item.children}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
