import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  SidebarContentComponent, type SidebarContentProps,
} from './sidebarContent.component';

type SidebarContentContainerProps<T> = Omit<SidebarContentProps<T>, 'isMobileScreen'>;

export const SidebarContentContainer = <T extends unknown>(props: SidebarContentContainerProps<T>) => {
  const isMobileScreen = useIsMobileScreenSelector();

  return (
    <SidebarContentComponent
      {...props}
      isMobileScreen={isMobileScreen}
    />
  );
};
