import {
  forwardRef, type PropsWithChildren,
} from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { dataCellStyle } from '~/map/mapMigration/table/table.styles';

type TableDataCell = PropsWithChildren<{
  readonly className?: string;
}>;

export const TableDataCellComponent = forwardRef<HTMLDivElement, TableDataCell>(({ className, children }, ref) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={dataCellStyle({ theme })}
      ref={ref}
    >
      {children}
    </div>
  );
});
