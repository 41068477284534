import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type GroupingColumn } from '~/_shared/types/grouping/grouping';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { mapSettingsGroupingRemoveActiveGroupColumn } from '~/store/mapSettings/grouping/mapSettingsGrouping.actionCreators';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { emptySpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.state';
import { useTranslation } from '../../../_shared/utils/hooks';
import { useMapSettingsColumnsFilterSelector } from '../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../../../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMatchupDataSelector } from '../../../store/matchupData/matchupData.selectors';
import { getGroupingColumnSelectedUniqueGroups } from '../../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '../../../store/spreadsheetData/spreadsheetData.selectors';
import {
  createMapStatusBarItemFromActiveGroupingColumnFilter, createMapStatusBarItemFromGroupingColumn,
} from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';
import { useColumnsFilterGroupUnselect } from './useColumnsFilterGroupUnselect';

export const useActiveGroupingColumnsStatusBarItems = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const { onGroupUnselect } = useColumnsFilterGroupUnselect();
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const activeGroupingColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const matchupData = useMatchupDataSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const spreadSheetColumns = useSpreadsheetColumns();

  const removeActiveGroupColumn = useCallback((column: GroupingColumn) => {
    if (mapId) {
      dispatch(mapSettingsGroupingRemoveActiveGroupColumn(column, mapId));
    }
  }, [dispatch, mapId]);

  const activeGroupingColumnFiltersItems = useMemo<MapStatusBarItem[]>(() => {
    return activeGroupingColumns.reduce<MapStatusBarItem[]>(
      (acc, groupingColumn) => {
        const selectedUniqueGroups = getGroupingColumnSelectedUniqueGroups(
          groupingColumn,
          columnsFilter,
          spreadsheetData ?? emptySpreadsheetData,
          matchupData
        );

        for (const groupUniqueColumn of selectedUniqueGroups) {
          const statusBarItem = createMapStatusBarItemFromActiveGroupingColumnFilter({
            t, groupName: groupUniqueColumn.name,
            onRemove: () => onGroupUnselect(groupingColumn, groupUniqueColumn),
            onClick: () => dispatch(openMapTool(MapTool.Grouping)),
          });
          acc.push(statusBarItem);
        }

        return acc;
      }, []);
  }, [activeGroupingColumns, columnsFilter, spreadsheetData, matchupData, t, onGroupUnselect, dispatch]);

  const activeGroupingColumnsItems = useMemo<MapStatusBarItem[]>(() => (
    activeGroupingColumns.map((groupingColumn, hierarchyIndicator) => {
      const columnName = spreadSheetColumns.find((column) => (
        column.id === groupingColumn.columnId && column.spreadsheetId === groupingColumn.spreadsheetId)
      )?.name ?? '';
      return createMapStatusBarItemFromGroupingColumn({
        t,
        columnName,
        hierarchyIndicator,
        totalGroupingColumns: activeGroupingColumns.length,
        onRemove: () => removeActiveGroupColumn(groupingColumn),
        onClick: () => dispatch(openMapTool(MapTool.Grouping)),
      });
    })
  ), [activeGroupingColumns, spreadSheetColumns, t, removeActiveGroupColumn, dispatch]);

  return useMemo(() => (
    activeGroupingColumnsItems.concat(activeGroupingColumnFiltersItems)
  ), [activeGroupingColumnFiltersItems, activeGroupingColumnsItems]);
};
