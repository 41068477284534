import { type Interpolation } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type ComponentProps,
  type FC, type KeyboardEventHandler, type ReactNode, useCallback, useState,
} from 'react';
import { FormErrorMessageComponent } from '../../../components/formErrorMessage/formErrorMessage.component';
import { createUuid } from '../../../utils/createUuid';
import { type autoCompleteDisabled } from '../../../utils/dom/dom.helpers';
import {
  type InputSize, type InputType, TextInputComponent,
} from '../textInput';
import { FormLabel } from './formLabel.component';
import {
  buttonWrapperStyle, errorStyle, textInputStyles,
} from './formTextInput.styles';

export type TextInputProps = Pick<ComponentProps<typeof TextInputComponent>, 'inputMode'> & Readonly<{
  autoComplete?: string | typeof autoCompleteDisabled;
  autoTrim?: boolean;
  errorMessages?: ReadonlyArray<string>;
  errorStyle?: Interpolation;
  icon?: IconProp | null;
  inputStyle?: Interpolation;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  label?: ReactNode;
  labelStyle?: Interpolation;
  labelTooltip?: ReactNode;
  marginBottom?: number;
  marginTop?: number;
  placeholder?: string;
  size?: InputSize;
  type?: InputType;
  value?: string;
  rightContent?: ReactNode;
  button?: ReactNode;

  onBlur?: () => void;
  onChange: (newValue: string) => void;
  onKeyUp?: KeyboardEventHandler;
  setInputRef?: (inputRef: HTMLInputElement) => void;
}>;

export const FormTextInputComponent: FC<TextInputProps> = (props) => {
  const [id] = useState(createUuid());

  const handleChange = useCallback((newValue: string) => {
    const onChange = props.onChange;
    onChange(props.autoTrim ? newValue.trim() : newValue);
  }, [props.autoTrim, props.onChange]);

  return (
    <div css={{ width: '100%' }}>
      {props.label && (
        <FormLabel
          tooltip={props.labelTooltip}
          css={[props.isDisabled && { opacity: .5 }, props.labelStyle]}
          htmlFor={id}
        >
          {props.label}
        </FormLabel>
      )}

      <div css={{ display: 'flex' }}>
        <div css={{ flex: 1 }} >
          <TextInputComponent
            css={[textInputStyles({ button: !!props.button }), props.inputStyle]}
            size={props.size}
            type={props.type}
            onChange={handleChange}
            value={props.value}
            id={id}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onKeyUp={props.onKeyUp}
            autoComplete={props.autoComplete}
            inputMode={props.inputMode}
            icon={props.icon}
            isDisabled={props.isDisabled}
            isReadOnly={props.isReadOnly}
            ref={props.setInputRef}
            rightContent={props.rightContent}
          />
        </div>
        {props.button && (
          <div css={buttonWrapperStyle}>
            {props.button}
          </div>
        )}
      </div>
      {!!props.errorMessages?.length && !!props.errorMessages[0] && (
        <div css={[errorStyle, props.errorStyle]}>
          <FormErrorMessageComponent
            messages={props.errorMessages}
          />
        </div>
      )}
    </div>
  );
};
