import { css } from '@emotion/react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ModalComponent } from '../_shared/components/modal/modal.component';
import { NoteModalComponent } from '../_shared/components/modal/noteModal.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type ModalProps } from '../modal/modalType.enum';
import { type AppError } from './appError.type';

type AppErrorProps = Readonly<ModalProps<{
  appError: AppError;
}>>;

const contentStyle = css({
  display: 'flex',
});

export const AppErrorComponent: FC<AppErrorProps> = props => {
  const theme = useTheme();

  if (!props.appError.content) {
    return (
      <NoteModalComponent
        onClose={props.onClose}
        isOpen={props.isOpen}
        title={props.appError.title}
        subTitle={props.appError.errorTitle ?? props.appError.title}
        text={props.appError.text}
        textColor={theme.textColors.danger}
        icon={faCircleExclamation}
      />
    );
  }
  else {
    return (
      <ModalComponent
        isOpen={props.isOpen}
        onClose={props.onClose}
        caption={props.appError.title}
      >
        <div css={contentStyle}>
          {props.appError.content}
        </div>
      </ModalComponent>
    );
  }
};
