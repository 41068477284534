import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import { useTheme } from '~/_shared/themes/theme.hooks';

const circleStyle = css({
  marginRight: 15,
});

type MemberIndexCircleProps = Readonly<{
  number: number;
}>;

export const MemberIndexCircleComponent: FC<MemberIndexCircleProps> = ({ number }) => {
  const theme = useTheme();
  const circleColors = useMemo(() => ({
    fillColor: theme.buttonColors.primaryBackground,
    fontColor: theme.buttonColors.primaryText,
  }), [theme.buttonColors.primaryBackground, theme.buttonColors.primaryText]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={circleStyle}
      size={20}
    >
      {number}
    </NumberInCircleComponent>
  );
};
