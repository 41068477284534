import { css } from '@emotion/react';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { RoundButtonStyles } from '../roundButton/roundButton.styles';
import {
  type IconButtonSize,
  IconButtonStyle,
} from './iconButton.types';

const DEFAULT_STYLE = IconButtonStyle.Secondary;

const getIconButtonSize = (size?: IconButtonSize) => {
  switch (size) {
    case 's':
      return '25px';
    case 'm':
      return '32px';
    default:
      return '40px';
  }
};

const getIconButtonFontSize = (size?: IconButtonSize) => {
  switch (size) {
    case 's':
      return '16px';
    case 'm':
      return '20px';
    default:
      return '25px';
  }
};

export const getHoverCss = (props: ThemeProps<{ btnStyle?: IconButtonStyle }>) => {
  const btnStyles = RoundButtonStyles(props.theme)[props.btnStyle || DEFAULT_STYLE];
  return {
    color: btnStyles.textHoverColor,
  };
};

export const buttonStyle = (props: {
  theme: Theme;
  btnStyle?: IconButtonStyle;
  size?: IconButtonSize;
  isDisabled?: boolean;
}) => {
  const btnStyles = RoundButtonStyles(props.theme)[props.btnStyle || DEFAULT_STYLE];
  return css({
    border: 0,
    background: 'transparent',
    color: btnStyles.textColor,
    fontSize: getIconButtonFontSize(props.size),
    lineHeight: 1,
    width: getIconButtonSize(props.size),
    height: getIconButtonSize(props.size),
    minWidth: getIconButtonSize(props.size),
    minHeight: getIconButtonSize(props.size),
    textAlign: 'center',
    padding: 0,
    pointerEvents: props.isDisabled ? 'none' : undefined, // workaround for react-popper behaving wrong on disabled buttons: https://github.com/ariakit/ariakit/issues/471
    '&:hover': getHoverCss(props),
  });
};
