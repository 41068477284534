import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getBoundingBoxOfMultipolygon } from '~/_shared/utils/gis/boundingBox.helpers';
import { type BoundingBox } from '~/map/map/boundingBox';
import { type BoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import { CUSTOM_BOUNDARY_ZOOM_LEVEL } from '~/store/boundaryItems/boundaryItems.state';
import { mapComponentSetZoomToBounds } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { useBoundaryStateItem } from './useBoundaryStateItem';

export const useZoomToBoundary = () => {
  const { getBoundaryStateItem } = useBoundaryStateItem();
  const dispatch = useDispatch();

  const zoomToBoundary = useCallback((boundaryIdentifiers: BoundaryIdentifier[]) => {
    const allPolygonsBounds: BoundingBox[] = [];

    boundaryIdentifiers.forEach(({ boundaryId, boundaryGroupId }) => {
      const boundaryPaths = getBoundaryStateItem(boundaryId, boundaryGroupId)?.paths;
      let boundaryPath = boundaryPaths?.get(CUSTOM_BOUNDARY_ZOOM_LEVEL);
      if (!boundaryPath) {
        const paths = Array.from(boundaryPaths?.values() ?? []);
        if (paths.length === 0) {
          return;
        }
        boundaryPath = paths[0];
      }

      if (boundaryPath) {
        allPolygonsBounds.push(getBoundingBoxOfMultipolygon(boundaryPath));
      }
    });

    const [first] = allPolygonsBounds;
    if (first) {
      const boundariesBounds = allPolygonsBounds.reduce((acc, currentBB) => {
        if (acc !== allPolygonsBounds[0]) {
          acc.extend(currentBB.getNorthEast());
          acc.extend(currentBB.getSouthWest());
        }
        return acc;
      }, first);
      dispatch(mapComponentSetZoomToBounds(boundariesBounds));
    }

  }, [getBoundaryStateItem, dispatch]);

  return {
    zoomToBoundary,
  };
};
