import { ValueValidationErrorCode } from '~/_shared/types/responseErrors/_shared/valueValidationErrorCodes.types';
import type { ResponseCode } from '~/_shared/types/responseErrors/responseErrorCodes.types';
import type { TranslationFnc } from '~/_shared/utils/hooks';
import { i18n } from '~/i18nextSetup';
import { MFAErrorCode } from './_shared/MFAErrorCodes.types';
import { PasswordErrorCode } from './_shared/passwordErrorCodes.types';
import {
  MAX_LENGTH_GENERIC_FIELD, MIN_LENGTH_GENERIC_FIELD,
} from './responseErrorsCodes.constants';

type ErrorsInStructure<TKey extends string> = {
  readonly [key in TKey]?: ReadonlyArray<ResponseCode>;
};

export type ErrorsTranslated<TKey extends string> = {
  readonly [key in TKey]?: ReadonlyArray<string>;
};

export const translateErrorCodesInError = <TKey extends string>(errorsInStructure: ErrorsInStructure<TKey>, t: TranslationFnc): ErrorsTranslated<TKey> => {
  const errorTypesWithMessages = Object
    .keys(errorsInStructure)
    .map((errorType: TKey) => {
      const errors = errorsInStructure[errorType];
      const errorMessages = errors?.map(code => translateErrorCode(code, t));

      return [errorType, errorMessages];
    });
  return Object.fromEntries(errorTypesWithMessages);
};

export const translateErrorCode = (errorCode: ResponseCode, t: TranslationFnc): string => {
  switch (errorCode) {
    case (ValueValidationErrorCode.MAX_LENGTH_EXCEEDED):
      return t(ValueValidationErrorCode.MAX_LENGTH_EXCEEDED, { length: MAX_LENGTH_GENERIC_FIELD });
    case (ValueValidationErrorCode.MIN_LENGTH_REQUIRED):
      return t(ValueValidationErrorCode.MIN_LENGTH_REQUIRED, { length: MIN_LENGTH_GENERIC_FIELD });
    default:
      return i18n.exists(errorCode) ? t(errorCode) : t('An unknown error has occurred.');
  }
};

type ErrorWithPossibleCodes = Readonly<{
  code?: ResponseCode;
  errorCode?: ResponseCode;
}>;

const filterErrorCodesForDisplay = (errorCode?: ResponseCode): ResponseCode | null => {
  switch (errorCode) {
    case undefined:
    case MFAErrorCode.MFA_CODE_IS_REQUIRED:
    case MFAErrorCode.MFA_CODE_SENT_TO_EMAIL:
    case MFAErrorCode.MFA_CODE_SENT_TO_PHONE:
    case MFAErrorCode.MFA_REQUIRED:// not an error - users need to enter 2FA code
    case PasswordErrorCode.OLD_PASSWORD_INVALID: //is handled as a field error
      return null;

    default:
      return errorCode;
  }
};

export const getFilteredResponseErrorCodes = <ErrorType extends ErrorWithPossibleCodes>(error: ErrorType | null): ResponseCode | null => {
  if (error?.code || error?.errorCode) {
    return filterErrorCodesForDisplay(error.code || error.errorCode);
  }

  return null;
};
