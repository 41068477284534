import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type RouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import {
  addRouteToDirections,
  clearDirectionsRoutes,
} from '~/store/mapSettings/directions/mapSettingsDirections.actionCreators';
import {
  useMapSettingsDirectionsRoutesSelector,
  useMapSettingsDirectionsSelectedCollapsingSelector,
} from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { DirectionsPanelComponent } from './directionsPanel.component';

export const DirectionsPanelContainer: FC = () => {
  const allowedExportDataTools = usePublicMapSettingsAllowedExportDataToolsSelector();
  const dispatch = useDispatch();
  const isPresentationalMap = useIsMapPresentationalSelector();
  const routeCollapsingLevel = useMapSettingsDirectionsSelectedCollapsingSelector();
  const routes = useMapSettingsDirectionsRoutesSelector();
  const { openModal } = useModal(ModalType.ExportDirections);

  const allowExportLocations = useMemo(() => (
    (!isPresentationalMap || hasFeatureOrToolAllowedPresentationalMapExport(
      MapTool.Routing, allowedExportDataTools
    ))
  ), [allowedExportDataTools, isPresentationalMap]);

  const onExportFile = useCallback(() => {
    openModal({
      showSelectRoutesSection: true,
      selectedRouteIds: [],
    });
  }, [openModal]);

  const addRoute = (route: MapSettingsRoute, routeUiData: RouteUiData) => {
    dispatch(addRouteToDirections(route, routeUiData, routeCollapsingLevel));
  };

  const clearRoutes = () => {
    dispatch(clearDirectionsRoutes());
  };

  return (
    <DirectionsPanelComponent
      addRoute={addRoute}
      clearRoutes={clearRoutes}
      exportFile={allowExportLocations ? onExportFile : undefined}
      routes={routes}
    />
  );
};
