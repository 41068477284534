import { copy } from '../../_shared/utils/collections/collections';
import { type BoundaryTerritoryGroupsAction } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { BOUNDARY_TERRITORY_DELETE } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { type BoundaryTerritoryDetailsAction } from './boundaryTerritoryDetails.action';
import {
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_ERROR,
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST,
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS,
  BOUNDARY_TERRITORY_DETAILS_UPDATE_MANUAL_ASSIGNMENTS,
} from './boundaryTerritoryDetails.actionTypes';
import { removeTerritoryAssignmentsFromBoundaryTerritoryDetails } from './boundaryTerritoryDetails.helpers';
import { type BoundaryTerritoryDetailsState } from './boundaryTerritoryGroups.state';

const boundaryTerritoryGroupsInitialState: BoundaryTerritoryDetailsState = {
  territoryDetails: {
    data: new Map(),
    filterHash: '',
  },
  isError: false,
  isLoading: false,
};

export const boundaryTerritoryDetailsReducer = (
  state: BoundaryTerritoryDetailsState = boundaryTerritoryGroupsInitialState,
  action: BoundaryTerritoryDetailsAction | BoundaryTerritoryGroupsAction
): BoundaryTerritoryDetailsState => {
  switch (action.type) {
    case BOUNDARY_TERRITORY_DELETE: {
      return removeTerritoryAssignmentsFromBoundaryTerritoryDetails(
        state, action.payload.boundaryTerritoryGroupId, action.payload.territoryId
      );
    }

    case BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST: {
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    }

    case BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS: {
      const newDetails = new Map(state.territoryDetails.data);

      for (const result of action.payload.results) {
        newDetails.set(
          result.territoryGroupId,
          result.details
        );
      }

      return {
        ...state,
        territoryDetails: {
          data: newDetails,
          filterHash: action.payload.filterHash,
        },
        isLoading: false,
      };
    }

    case BOUNDARY_TERRITORY_DETAILS_UPDATE_MANUAL_ASSIGNMENTS: {
      const currentTerritoryDetails = state.territoryDetails.data.get(action.payload.boundaryTerritoryGroupId);
      if (!currentTerritoryDetails) {
        return state;
      }

      const newDetails = {
        ...currentTerritoryDetails,
        manualAssignments: action.payload.manualAssignments,
      };

      return {
        ...state,
        territoryDetails: {
          data: copy.andReplace(state.territoryDetails.data, action.payload.boundaryTerritoryGroupId, newDetails),
          filterHash: state.territoryDetails.filterHash,
        },
      };
    }

    default:
      return state;
  }
};
