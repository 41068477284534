import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { BASIC_PANEL_WIDTH } from '../../../sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS } from './locationListing.container';

const markerIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.warning,
  display: 'flex',
  fontSize: '25px',
  fontWeight: 500,
  lineHeight: '14px',
  margin: '10px 10px',
});

const wrapperStyle = ({ theme, width }: ThemeProps<{ width: number }>) => css({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.textColors.contrast,
  display: 'flex',
  flexDirection: 'column',
  minWidth: `${width}px`,
  padding: 12,
});

const noticeStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexGap: 8,
  fontSize: 20,
});

const warningDescriptionStyle = css({
  display: 'flex',
  fontSize: '16px',
  lineHeight: 1.5,
  paddingLeft: '10px',
  paddingRight: '10px',
  flexDirection: 'column',
});

const warningHintStyle = css({
  paddingTop: 10,
});

export const LocationListingWarningComponent: FC<Readonly<{id?: string}>> = ({ id }) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <div
      css={css(wrapperStyle({ theme, width: BASIC_PANEL_WIDTH }))}
      id={id}
    >
      <div css={noticeStyle}>
        <FontAwesomeIcon
          css={markerIconStyle({ theme })}
          icon={faExclamationTriangle}
        />
        {t('Notice').toUpperCase()}
      </div>
      <div css={warningDescriptionStyle}>
        <div>{t('locationListing.maxnumber.exceed.warning').replace('[NUMBER]', ((MAXIMUM_LOCATIONS_LIST_PANEL_LOCATIONS).toString()))}</div>
        <div css={warningHintStyle}>{t('locationListing.maxnumber.exceed.warning.hint')}</div>
      </div>
    </div>
  );
};
