import {
  DEFAULT_THEME, type ThemeType,
} from '~/_shared/themes/themeType';
import { parseUrl } from '~/_shared/utils/url/url.helpers';

const themeTypeStorageKey = 'THEME_TYPE';
const themeTypes: ThemeType[] = ['dark', 'light'];

const validateThemeType = (type: string | null) => {
  return type && themeTypes.includes(type as ThemeType);
};

export const storeThemeType = (type: ThemeType) => {
  localStorage.setItem(themeTypeStorageKey, type);
};

const loadFromStorage = (): ThemeType | null => {
  const storedType = localStorage.getItem(themeTypeStorageKey);

  if (validateThemeType(storedType)) {
    return storedType as ThemeType;
  }

  return null;
};

const loadFromUri = (): ThemeType | null => {
  const url = window.location.href;
  const themeType = parseUrl(url).fragmentParameters?.theme;

  if (themeType && validateThemeType(themeType)) {
    return themeType as ThemeType;
  }

  return null;
};

export const restoreThemeType = () => {
  const fromUri = loadFromUri();
  if (fromUri) {
    return {
      type: fromUri,
      isForced: true,
    };
  }

  return {
    type: loadFromStorage() ?? DEFAULT_THEME,
    isForced: false,
  };
};
