import {
  type FC,
  useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { BoundarySetNameComponent } from '~/sidebar/sidebarApps/mapTools/boundary/boundarySetName/boundarySetName.component';
import { useSetBoundaryName } from '~/sidebar/sidebarApps/mapTools/boundary/boundarySetName/useSetBoundaryName';
import { convertBoundaryTerritoriesToBoundaries } from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import { convertBoundariesHideNameModal } from '~/store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.actionCreators';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useConvertBoundariesSelector } from '../../../../../store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.selectors';

export const ConvertBoundariesContainer: FC = () => {
  const { name, changeName, isNameFilled, resetName } = useSetBoundaryName();
  const convertState = useConvertBoundariesSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const startConversion = useCallback(() => {
    if (!convertState.showBoundaryConvertModal || !convertState.boundaryTerritoryGroupId) {
      return;
    }

    const boundaryTerritoryGroup = boundaryTerritoryGroups.find(
      group => group.boundaryTerritoryGroupId === convertState.boundaryTerritoryGroupId
    );

    if (!boundaryTerritoryGroup) {
      return;
    }

    dispatch(convertBoundaryTerritoriesToBoundaries(boundaryTerritoryGroup, name, true));
    dispatch(convertBoundariesHideNameModal());
  }, [convertState.showBoundaryConvertModal, convertState.boundaryTerritoryGroupId, boundaryTerritoryGroups, dispatch, name]);

  const cancelConversion = useCallback(() => {
    dispatch(convertBoundariesHideNameModal());
  }, [dispatch]);

  useEffect(() => {
    if (convertState.showBoundaryConvertModal) {
      resetName();
    }
  }, [convertState.showBoundaryConvertModal, resetName]);

  if (!convertState.showBoundaryConvertModal) {
    return null;
  }

  return (
    <BoundarySetNameComponent
      filledName={name}
      submitDisabled={!isNameFilled()}
      onSubmit={startConversion}
      onClose={cancelConversion}
      onUpdateName={changeName}
      title={t('Convert Groups to Territories')}
      buttonLabel={t('Convert')}
    />
  );
};
