import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { ImportMethod } from '~/_shared/types/importMethod/importMethod';
import { getUserSpreadsheetImportBroadcast } from '~/_shared/utils/api/broadcast.helpers';
import { findAllIndexes } from '~/_shared/utils/array/array.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNull } from '~/_shared/utils/typeGuards';
import { createImportMatchupRequest } from '~/data/replaceData/replaceDataMatchupModal/replaceDataMatchup.factory';
import { type RowSettingsResolution } from '~/data/replaceData/replaceDataRowSettingsResolveModal.component';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type ColumnsMapSettingsResponse } from '~/spreadsheet/columnsMapSettings/columnsMapSettings.types';
import {
  importSpreadsheetData,
  type ImportSpreadsheetDataMatchRequest,
  type ImportSpreadsheetResponse,
  type ImportSpreadsheetUniqueKeys,
  ReplaceSpreadsheetErrorMessage,
  type ReplaceSpreadsheetRequiringMatchesResponse,
} from '~/spreadsheet/spreadsheet.repository';
import { mapChange } from '~/store/map/map.actionCreators';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { useConfirmColumnsSettingsErased } from '../columnsSettingsModal/useConfirmColumnsSettingsErased.hook';
import { type ReplaceDataMatchup } from '../replaceData/replaceDataMatchupModal/replaceDataMatchupModal.component';

type ImportDataArguments = Readonly<{
  clientId: number;
  importMethod: ImportMethod;
  mapId: number;
  source: string;
  spreadsheetId: number;
  type: 'input' | 'file';
  userId: number;

  onImportSuccess?: () => void;
}>;

export const useImportData = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const confirmColumnMapSettingsErased = useConfirmColumnsSettingsErased();
  const { closeConfirmationModal, openConfirmationModal } = useConfirmationModal();
  const { replaceCurrentModal: replaceCurrentModalWithReplaceDataModal, closeModal: closeReplaceDataModal } = useModal(ModalType.ReplaceSpreadsheetDataMatchup);
  const { replaceCurrentModal: replaceCurrentModalWithRowSettingsResolveModal, closeModal: closeRowSettingsResolveModal } = useModal(ModalType.RowSettingsResolve);

  const handleResolveRowSettings = useCallback(async (columnOptions: ReadonlyArray<DropdownOption<number>>) => {
    return new Promise<RowSettingsResolution>((resolve, reject) => {
      replaceCurrentModalWithRowSettingsResolveModal({
        columnOptions,
        onClose: () => {
          closeRowSettingsResolveModal();
          reject();
        },
        onSubmit: (params: RowSettingsResolution) => {
          closeRowSettingsResolveModal();
          resolve(params);
        },
      });
    });
  }, [closeRowSettingsResolveModal, replaceCurrentModalWithRowSettingsResolveModal]);

  const handleResolveDuplicates = useCallback(() => {
    return new Promise<boolean>((resolve, reject) => {
      openConfirmationModal({
        title: t('replaceSpreadsheet.rowDuplicates.warning.caption'),
        text: t('replaceSpreadsheet.rowDuplicates.warning.message'),
        confirmCaption: t('Retry'),
        onCancel: () => {
          closeConfirmationModal();
          reject();
        },
        onConfirm: () => {
          closeConfirmationModal();
          resolve(true);
        },
      });
    });
  }, [t, openConfirmationModal, closeConfirmationModal]);

  const handleGetMatchup = useCallback((
    newColumns: ReadonlyArray<DropdownOption<string>>,
    oldColumns: ReadonlyArray<DropdownOption<string>>,
    initialMatchup: ReplaceDataMatchup,
    columnsMapSettings: ColumnsMapSettingsResponse | undefined,
    onConfirm: (matchup: ReplaceDataMatchup) => void,
    onReject: () => void,
  ) => {
    replaceCurrentModalWithReplaceDataModal({
      newColumns,
      oldColumns,
      initialMatchup,
      onSubmit: (matchup) => {
        closeReplaceDataModal();

        const usedColumns = Object.entries(matchup).map(([columnId, value]) => (
          (!value.ignore && value.newColumnId !== null) ? columnId : null
        )).filter(notNull);

        confirmColumnMapSettingsErased({
          columnsMapSettings,
          description: t('columnSettings.header.spreadsheetReplace'),
          description2: t('columnSettings.warnSettingsLost.spreadsheetReplace'),
          usedColumns,
          onConfirm: () => {
            onConfirm(matchup);
          },
          onReject: () => handleGetMatchup(newColumns, oldColumns, matchup, columnsMapSettings, onConfirm, onReject),
        });
      },
      onClose: () => onReject(),
    });
  }, [t, closeReplaceDataModal, confirmColumnMapSettingsErased, replaceCurrentModalWithReplaceDataModal]);

  const handleGetMatchupRequest = useCallback(async (matchupRequest: ReplaceSpreadsheetRequiringMatchesResponse) => {
    const newColumns: ReadonlyArray<DropdownOption<string>> =
      matchupRequest.columns.source
        .map((column, index) => ({
          value: column.id,
          name: column.name || t('Column {{number}}', { number: index + 1 }),
        }));

    const oldColumns: ReadonlyArray<DropdownOption<string>> =
    matchupRequest.columns.table
      .map((column, index) => ({
        value: column.id,
        name: column.name || t('Column {{number}}', { number: index + 1 }),
      }));

    const initialMatchup: ReplaceDataMatchup = {};
    for (const oldColumn of oldColumns) {
      const newColumnId = matchupRequest.params.matches
        .find(match => match.table_column === oldColumn.value)?.source_column ?? null;

      initialMatchup[oldColumn.value] = {
        newColumnId,
        ignore: false,
      };
    }

    return new Promise<ReadonlyArray<ImportSpreadsheetDataMatchRequest> | null>((resolve, reject) => {
      handleGetMatchup(
        newColumns,
        oldColumns,
        initialMatchup,
        matchupRequest.columns_map_settings,
        (matchup: ReplaceDataMatchup) => {
          resolve(createImportMatchupRequest(matchup, newColumns, oldColumns));
        },
        reject,
      );
    });
  }, [handleGetMatchup, t]);

  const importData = useCallback(async (importParams: ImportDataArguments): Promise<void> => {
    const { clientId, spreadsheetId, importMethod, type, source, onImportSuccess, userId, mapId } = importParams;

    let repeatUniqueKeyPicking = false;
    let importResults: ImportSpreadsheetResponse | null = null;
    let matches: ReadonlyArray<ImportSpreadsheetDataMatchRequest> | null = null;
    let matchesResponse: ReplaceSpreadsheetRequiringMatchesResponse | null = null;
    let rowSettingsResolution: { clear_rows_in_settings?: boolean; unique_keys?: ImportSpreadsheetUniqueKeys } | null = null;

    // import might fail because of multiple reasons, where user needs to interfere
    // until we get a passing one, we will keep iterating

    while (true) {
      const combinedResponse = await importSpreadsheetData(clientId, spreadsheetId, {
        action: importMethod,
        type,
        source,
        ...(matches ? { matches } : {}),
        ...(rowSettingsResolution ? rowSettingsResolution : {}),
      });

      if (combinedResponse.type === 'resolution-needed') {
        // if matchup is needed
        if (!matches
          && combinedResponse.response.message === ReplaceSpreadsheetErrorMessage.AdditionalParamsRequired
          && combinedResponse.response.params.matches
        ) {
          try {
            matchesResponse = combinedResponse.response;
            matches = await handleGetMatchupRequest(matchesResponse);
          }
          catch (e) {
            //user closed modal, or another unhandled action
            break;
          }
          continue;
        }
        else if (repeatUniqueKeyPicking || combinedResponse.response.message === ReplaceSpreadsheetErrorMessage.UniqueKeyRequired) {
          // if unique keys are required
          try {
            repeatUniqueKeyPicking = false;
            let columnOptions: ReadonlyArray<DropdownOption<number> & { tableColumnIndex: number }> = [];
            if (matchesResponse && matches) {
              // if structure of new spreadsheet doesn't match the old one, we have received matches in the earlier step
              columnOptions = matches
                .map(item => {
                  if (item.source_column && item.table_column) {
                    const sourceColumnIndex = combinedResponse.response.columns.source.findIndex(c => c.id === item.source_column);
                    const tableColumnIndex = combinedResponse.response.columns.table.findIndex(c => c.id === item.table_column);
                    const sourceColumnName = sourceColumnIndex === -1 ? null : combinedResponse.response.columns.source[sourceColumnIndex]?.name;
                    if (sourceColumnIndex !== -1 && tableColumnIndex !== -1 && sourceColumnName) {
                      return {
                        value: sourceColumnIndex,
                        name: sourceColumnName || t('Column {{number}}', {
                          number: sourceColumnIndex + 1,
                        }),
                        tableColumnIndex,
                      };
                    }
                  }
                  return null;
                })
                .filter(notNull);
            }
            else {
              // the old and new structure was either automatched or didn't change
              columnOptions = combinedResponse.response.columns.source
                .map((item, sourceColumnIndex) => {
                  const matchingTableIndexes = findAllIndexes(
                    combinedResponse.response.columns.table,
                    (col) => (!!item.name && col.name === item.name)
                  );
                  // we only consider those cols for the key, which have the exact (and only) name in the new spreadsheet
                  // using automatches from backend would be more precise (BE doesn't return them atm), but probably not needed as it's a fringe scenario
                  if (matchingTableIndexes.length === 1 && matchingTableIndexes[0]) {
                    return {
                      value: sourceColumnIndex,
                      name: item.name || t('Column {{number}}', {
                        number: sourceColumnIndex + 1,
                      }),
                      tableColumnIndex: matchingTableIndexes[0],
                    };
                  }
                  return null;
                })
                .filter(notNull);
            }
            if (columnOptions.length) {
              // we ask user to choose what to do with row settings
              const rowSettingsResolutionToProcess = await handleResolveRowSettings(columnOptions);
              const uniqueKeys = !rowSettingsResolutionToProcess.erase && rowSettingsResolutionToProcess.uniqueKeys
                ? rowSettingsResolutionToProcess.uniqueKeys.map(key => {
                  const columnInfo = columnOptions.find(col => col.value === key);
                  if (columnInfo) {
                    return {
                      source: columnInfo.value,
                      table: columnInfo.tableColumnIndex,
                    };
                  }
                  return null;
                }).filter(notNull)
                : undefined;
              rowSettingsResolution = {
                clear_rows_in_settings: !!rowSettingsResolutionToProcess.erase,
                ...(!rowSettingsResolutionToProcess.erase && uniqueKeys ? {
                  unique_keys: {
                    source: uniqueKeys.map(uk => uk.source),
                    table: uniqueKeys.map(uk => uk.table),
                  },
                } : {}),
              };
              continue;
            }
          }
          catch (e) {
            //user closed modal, or another unhandled action
          }
          break;
        }
        else if (matches && combinedResponse.response.message === ReplaceSpreadsheetErrorMessage.DuplicatedRowsActionRequired) {
          // if unique key ends up identifying duplicated rows
          try {
            // we ask user how to resolve duplicates
            const resolveDuplicates = await handleResolveDuplicates();
            if (resolveDuplicates) {
              // if users chose to select a different key, let's show the select unique key step again
              repeatUniqueKeyPicking = true;
              continue;
            }
          }
          catch (e) {
            //user closed modal, or another unhandled action
          }
          break;
        }
      }
      else if (combinedResponse.type === 'success') {
        importResults = combinedResponse.response;
        break;
      }

      // something went wrong, but we did not handle the cause of the error
      console.error('Unhandled error during data import or replace.');
      break;
    }

    const cleanup = () => {
      // close replace data modal when the import is completed and modal was open
      closeReplaceDataModal();
    };

    if (importResults === null) {
      cleanup();
      return;
    }

    onImportSuccess?.();

    const onSpreadsheetImportProcessDone = () => {
      cleanup();

      if (importMethod === ImportMethod.Replace) {
        dispatch(mapChange(mapId));
      }
      else {
        dispatch(spreadsheetResetStateAndRefetchData());
      }
    };

    return new Promise((resolve, reject) => {
      getUserSpreadsheetImportBroadcast(e => {
        if (e.userId === userId) {
          if (e.spreadsheetId === importResults?.spreadsheet.id) {
            onSpreadsheetImportProcessDone();
            resolve();
            return;
          }
        }
      }, () => {
        onSpreadsheetImportProcessDone();
        reject();
      });
    });
  }, [closeReplaceDataModal, handleGetMatchupRequest, t, handleResolveRowSettings, handleResolveDuplicates, dispatch]);

  return {
    importData,
  };
};
