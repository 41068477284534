import {
  actionChannel, type ActionPattern, call, put, take, takeLatest,
} from 'redux-saga/effects';
import type { LatLng } from '~/_shared/types/latLng';
import { getLatLngFromAddress } from '~/_shared/utils/geolocation/cachedGeolocation';
import type { GeoLocation } from '~/_shared/utils/geolocation/geolocation';
import { areLatLngEqual } from '~/_shared/utils/latLng/latLng.helpers';
import { select } from '~/_shared/utils/saga/effects';
import type { PickAction } from '~/_shared/utils/types/action.type';
import { BoundingBox } from '~/map/map/boundingBox';
import { isSearchItemWithColumn } from '~/map/search/mapSearch.helpers';
import {
  mapComponentSetCenterAndZoom, mapComponentSetZoomToBounds,
} from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import {
  selectFilteredSpreadsheetRowIds, selectLatLngSpreadsheetData,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { SPREADSHEET_FETCH_DATA_SUCCESS } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import {
  checkIfSpreadsheetRowIdMeetsFilteringCriteria, type getMissingSpreadsheetDataToFetch,
} from '~/store/spreadsheetData/spreadsheetData.helpers';
import { getMissingDataToFetchSaga } from '~/store/spreadsheetData/spreadsheetData.sagas';
import type { MapSettingsSearchItemsAction } from './mapSettingsSearchItems.action';
import { mapSettingsSearchItemsUpdateItems } from './mapSettingsSearchItems.actionCreators';
import { MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM } from './mapSettingsSearchItems.actionTypes';
import { searchItemsSelector } from './mapSettingsSearchItems.selectors';

const SINGLE_SEARCH_RESULT_ZOOM = 17;

export function* mapSettingsSearchItemsSagas() {
  yield takeLatest(MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM, zoomOnSearch);
  yield takeLatest(MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM, saveGeolocationResultsLatLngsIntoSearchItems);
}

function* zoomOnSearch(action: PickAction<MapSettingsSearchItemsAction, typeof MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM>) {
  const requestChannel: ActionPattern<Action> = yield actionChannel(SPREADSHEET_FETCH_DATA_SUCCESS);

  const searchItem = action.payload.item;

  const shouldZoom: boolean = yield select(s => s.map.mapSettings.data.search.shouldZoomOnMatches);

  if (!shouldZoom) {
    return;
  }

  // wait for the new filtered data to be loaded if the search item is interacting with user data
  if (isSearchItemWithColumn(searchItem)) {
    const missingSpreadsheetDataToFetch: ReturnType<typeof getMissingSpreadsheetDataToFetch> = yield call(getMissingDataToFetchSaga);

    if (missingSpreadsheetDataToFetch.search) {
      yield take(requestChannel);
    }
  }

  const bounds = new BoundingBox();

  if (searchItem.latLng !== undefined) {
    searchItem.latLng.forEach(latLngData => bounds.extend({ lat: latLngData.lat, lng: latLngData.lng }));
  }
  else if (searchItem.includeAllGeolocated) {
    // wait for the address to lat/lng lookup to complete if the search works with geolocation and it wasn't completed yet (not concrete latLngs)
    // this can happen when user used enter to start search (might even be before autosuggests have been loaded) which was a functionality requirement
    try {
      const results: ReadonlyArray<GeoLocation> = yield call(getLatLngFromAddress, searchItem.text);
      results.forEach(geolocation => bounds.extend({ lat: geolocation.latLng.lat, lng: geolocation.latLng.lng }));
    }
    catch (e) {
      // do nothing, we just won't zoom
      console.error(e);
    }
  }

  if (isSearchItemWithColumn(searchItem)) {
    const latLngData: ReturnType<typeof selectLatLngSpreadsheetData> = yield select(selectLatLngSpreadsheetData);
    const { searchRowIds }: ReturnType<typeof selectFilteredSpreadsheetRowIds> = yield select(selectFilteredSpreadsheetRowIds);

    latLngData.data.forEach(latLngData => {
      if (!checkIfSpreadsheetRowIdMeetsFilteringCriteria(searchRowIds, latLngData)) {
        return;
      }

      bounds.extend({ lat: latLngData.lat, lng: latLngData.lng });
    });
  }

  if (!bounds.isEmpty()) {
    const southWest = bounds.getSouthWest();
    if (areLatLngEqual(southWest, bounds.getNorthEast())) {
      yield put(mapComponentSetCenterAndZoom(southWest, SINGLE_SEARCH_RESULT_ZOOM));
    }
    else {
      yield put(mapComponentSetZoomToBounds(bounds));
    }
  }
}

// the purpose of this saga is to save any latLngs which are eventually returned by geolocation to the search items, so we don't refetch them in the future
function* saveGeolocationResultsLatLngsIntoSearchItems(action: PickAction<MapSettingsSearchItemsAction, typeof MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM>) {
  const searchItem = action.payload.item;

  if (!searchItem.includeAllGeolocated || searchItem.latLng) {
    return;
  }

  let finalLatLngs: ReadonlyArray<LatLng> = [];
  try {
    const results: ReadonlyArray<GeoLocation> = yield getLatLngFromAddress(searchItem.text);
    finalLatLngs = (results || []).map(geolocation => geolocation.latLng);
  }
  catch (e) {
    // do nothing, we just won't save the latLngs
    console.error(e);
  }

  const allSearchItems: ReturnType<typeof searchItemsSelector> = yield select(searchItemsSelector);
  const searchItemsToUpdate = allSearchItems.filter(item => item.text === searchItem.text && item.includeAllGeolocated && !item.latLng);

  yield put(mapSettingsSearchItemsUpdateItems(
    searchItemsToUpdate.map(item => ({
      ...item,
      latLng: finalLatLngs,
    }))
  ));
}
