import {
  useCallback, useState,
} from 'react';
import { isCancelError } from '../../../_shared/utils/api/apiError.helpers';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type UserRegisterErrors } from '../../../store/userData/repository/userData.repository';
import { type UserRegisterData } from './useUserRegister';

export const useUserRegisterFormMessages = (registerUser: (data: UserRegisterData) => Promise<unknown>) => {
  const [errors, setErrors] = useState<UserRegisterErrors | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [t] = useTranslation();

  const closeErrorMessage = useCallback(() => {
    setErrorMessage('');
  }, []);

  const closeSuccessMessage = useCallback(() => {
    setShowSuccessMessage(false);
  }, []);

  const onRegisterSubmit = useCallback((data: UserRegisterData) => {
    setErrors(null);
    setErrorMessage('');
    setShowSuccessMessage(false);

    registerUser(data)
      .then(response => {
        setShowSuccessMessage(true);
        return response;
      })
      .catch((e) => {
        if (isCancelError(e)) {
          return;
        }

        setErrors(e.errors ?? null);
        setErrorMessage(e.message ?? t('Something went wrong, please try again.'));
      });
  }, [registerUser, t]);

  return {
    errors,
    errorMessage,
    onRegisterSubmit,
    showSuccessMessage,
    closeErrorMessage,
    closeSuccessMessage,
  };
};
