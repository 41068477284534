import {
  type FC, type ReactNode, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  type DataRouteParams, type MapRouteParams,
} from '~/_shared/constants/routes';
import { useCreateSalesRepMapOnAppLoad } from '~/_shared/hooks/appLoad/useCreateSalesRepMapOnAppLoad.hook';
import { useGoogleOauthAppLoadResolver } from '~/_shared/hooks/appLoad/useGoogleOauthAppLoadResolver.hook';
import { parseMapId } from '~/map/page/mapPage.helpers';
import { usePreloadMapAndWebGL } from '~/map/usePreloadMapAndWebGL.hook';
import { errorPageSetError } from '~/store/errorPage/errorPage.actionCreators';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import {
  mapChange, mapReset,
} from '~/store/map/map.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useOnNavigateFromMap } from '~/topBar/navigation/useOnNavigateFromMap.hook';

type MapScopedPageProps = Readonly<{
  children: (mapId: number | null) => ReactNode;
}>;

export const MapContextHoldingPageContainer: FC<MapScopedPageProps> = props => {
  const { mapId: routeMapIdString } = useParams<Partial<MapRouteParams | DataRouteParams>>();
  const dispatch = useDispatch();
  const reduxMapId = useMapIdSelector();
  useGoogleOauthAppLoadResolver();
  useCreateSalesRepMapOnAppLoad();
  usePreloadMapAndWebGL();
  useOnNavigateFromMap();

  const routeMapId = parseMapId(routeMapIdString);
  const isDataOrMapPage = routeMapIdString !== undefined;
  const mapIdIsInvalid = routeMapId === null;

  useEffect(() => {
    return () => {
      dispatch(mapReset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isDataOrMapPage) {
      return;
    }

    if ((reduxMapId !== null && reduxMapId === routeMapId)) {
      return;
    }

    if (mapIdIsInvalid) {
      dispatch(errorPageSetError(ErrorPageType.MapNotFound));
      return;
    }

    dispatch(mapChange(routeMapId));
  }, [dispatch, routeMapId, reduxMapId, routeMapIdString, isDataOrMapPage, mapIdIsInvalid]);

  return <>{props.children(routeMapId)}</>;
};
