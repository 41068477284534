import { css } from '@emotion/react';
import {
  type FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import {
  type GroupingColumn, type GroupingColumnNumeric,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GroupChangeRequest } from '../useGroupColumnData';
import { GroupingSettingColumnContainer } from './groupingSettingColumn.container';

const containerStyle = css({
  padding: '16px',
});

const headingStyle = css({
  cursor: 'default',
  margin: '8px 0',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 1.2,
});

type GroupingSettingProps = Readonly<{
  activeColumns: readonly GroupingColumn[];
  columns: readonly SpreadsheetColumn[];

  onGroupColumnAdd: (column: GroupingColumn) => void;
  onGroupColumnChange: (newColumn: GroupChangeRequest, columnToReplace: GroupingColumn) => void;
  onGroupColumnRemove?: (column: GroupingColumn) => void;
  onNumericGroupColumnSettingsClick: (column: GroupingColumnNumeric) => void;
}>;

export const GroupingSetting: FC<GroupingSettingProps> = props => {
  const [isAddGroupDropdownVisible, setIsAddGroupDropdownVisible] = useState(props.activeColumns.length === 0);
  const [t] = useTranslation();

  const isAddGroupingColumnTriggerVisible = !isAddGroupDropdownVisible && props.activeColumns.length > 0 && props.activeColumns.length + (isAddGroupDropdownVisible ? 1 : 0) < 2;

  const findAvailableColumns = (current?: GroupingColumn) => {
    const activeIds = props.activeColumns
      .map(column => column.columnId)
      .filter(column => column !== current?.columnId);
    return props.columns.filter(option => !activeIds.includes(option.id));
  };

  const onClickAddMore = () => setIsAddGroupDropdownVisible(true);

  useEffect(() => {
    setIsAddGroupDropdownVisible(props.activeColumns.length === 0);
  }, [props.activeColumns]);

  const onChange = useCallback((index: number) => (newGroupColumn: GroupChangeRequest) => {
    const activeColumn = props.activeColumns[index];
    if (activeColumn) {
      const onGroupColumnChange = props.onGroupColumnChange;
      onGroupColumnChange(newGroupColumn, activeColumn);
    }
  }, [props.activeColumns, props.onGroupColumnChange]);

  return (
    <div css={containerStyle}>
      <p css={headingStyle}>{t('Select column source and type')}:</p>
      {props.activeColumns.map((activeColumn, index) => (
        <GroupingSettingColumnContainer
          id={index.toString()}
          key={index}
          selected={activeColumn}
          columns={findAvailableColumns(activeColumn)}
          onChange={onChange(index)}
          onGroupColumnRemove={props.onGroupColumnRemove}
          onNumericGroupColumnSettingsClick={props.onNumericGroupColumnSettingsClick}
        />
      ))}

      {isAddGroupDropdownVisible && (
        <GroupingSettingColumnContainer
          id="add-new-group"
          columns={findAvailableColumns()}
          selected={null}
          onChange={props.onGroupColumnAdd}
        />
      )}

      {isAddGroupingColumnTriggerVisible && (
        <AddMorePlaceholderComponent
          onClick={onClickAddMore}
          title={t('Add Grouping Column')}
        />
      )}
    </div>
  );
};
