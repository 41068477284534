import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { TabsComponent } from '~/_shared/components/tabs/tabs.component';
import {
  type TabData, type TabsData,
} from '~/_shared/components/tabs/tabs.types';
import { type SelectGroupProps } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import {
  isLabelType, MarkerSettingType,
} from '~/_shared/types/markers/visualSettings.enums';
import { type TemporaryMarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { type CustomizationLevelProps } from './customizeLabelAndMarkerModal.container';
import { CustomizeLabelAndMarkerTab } from './customizeLabelAndMarkerModal.enums';
import { type CustomizeMarkerTab } from './customizeMarker/customizeMarker.component';
import { CustomizeMarkerContainer } from './customizeMarker/customizeMarker.container';
import { LabelSettingsContainer } from './labelsSettings/labelSettings.container';

const wrapperStyle = css({ maxWidth: '620px' });

const modalContentStyle = css({
  padding: '0',
  width: '100%',
});

const buttonStyle = css({
  height: '40px',
  margin: '0 6px',
  width: '130px',
});

export enum MarkerModalTabs {
  Marker = 0,
  Label = 1,
}

export type CustomizeLabelAndMarkerSettingsModalProps = ModalProps<{
  caption: string;
  colorCustomizationDisabled?: boolean;
  customizationLevelProps: CustomizationLevelProps;
  defaultTab?: CustomizeMarkerTab;
  markerSettings: TemporaryMarkerSettings;
  markerSettingsTypes: NonEmptyArray<MarkerSettingType>;
  optionalLabelDisabled?: boolean;
  selectGroupProps?: SelectGroupProps;

  onChange: (settings: TemporaryMarkerSettings) => void;
  onReset: () => void;
  onSave?: ({ activeTab }: { activeTab: MarkerModalTabs }) => void;
}>;

export const CustomizeLabelAndMarkerSettingsModalComponent: React.FC<CustomizeLabelAndMarkerSettingsModalProps> = props => {
  const [t] = useTranslation();

  const { onSave, onClose, markerSettingsTypes, markerSettings } = props;

  const onChange = useCallback((partialChange: TemporaryMarkerSettings) => {
    const onChange = props.onChange;

    const combinedSettings = {
      ...markerSettings,
      ...partialChange,
    };

    onChange(combinedSettings);
  }, [markerSettings, props.onChange]);

  const tabsData: TabsData = useMemo(() => {
    const labelType = markerSettingsTypes.find(isLabelType);
    const showBoth = labelType && markerSettingsTypes.includes(MarkerSettingType.Marker);

    const createMarkerTab = (): TabData => ({
      index: MarkerModalTabs.Marker,
      header: t(CustomizeLabelAndMarkerTab.Marker),
      testid: 'customize-marker-tab',
      child: (
        <CustomizeMarkerContainer
          colorCustomizationDisabled={props.colorCustomizationDisabled}
          customizationLevelProps={props.customizationLevelProps}
          defaultTab={props.defaultTab}
          labelAboveVisualSettings={markerSettings.aboveLabel}
          markerVisualSettings={markerSettings.marker}
          onChange={onChange}
          optionalLabelDisabled={props.optionalLabelDisabled}
          selectGroupProps={props.selectGroupProps}
        />
      ),
    });

    const createLabelTypeTab = (type: MarkerSettingType.Label | MarkerSettingType.LabelAbove): TabData => ({
      index: MarkerModalTabs.Label,
      header: t(CustomizeLabelAndMarkerTab.Label),
      testid: 'customize-label-tab',
      child: (
        <LabelSettingsContainer
          labelType={type}
          markerVisualSettings={markerSettings}
          onChange={onChange}
        />
      ),
    });

    if (showBoth) {
      return [createMarkerTab(), createLabelTypeTab(labelType)];
    }

    return labelType ? [createLabelTypeTab(labelType)] : [createMarkerTab()];
  }, [markerSettingsTypes, markerSettings, t, props.colorCustomizationDisabled, props.customizationLevelProps,
    props.defaultTab, props.optionalLabelDisabled, onChange, props.selectGroupProps,
  ]);

  const [activeTab, setActiveTab] = useState<number>(() =>
    tabsData.find(t => t.index === MarkerModalTabs.Label) && !markerSettings.useMarker ? MarkerModalTabs.Label : (tabsData[0]?.index || 0));

  const handleSave = useCallback(() => {
    onSave?.({ activeTab });
    onClose();
  }, [activeTab, onSave, onClose]);

  return (
    <ModalComponent
      {...props}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          onClick={props.onReset}
          text={t('Restore Default')}
        />
      )}
      confirmButton={(
        <ButtonComponent
          css={buttonStyle}
          prefixIcon={faCheck}
          onClick={handleSave}
          text={t('Save')}
          isDisabled={!props.onSave}
        />
      )}
      contentStyle={modalContentStyle}
      maxWidth={620}
      caption={props.caption}
    >
      <div css={wrapperStyle} >
        <TabsComponent
          activeTabIndex={activeTab}
          data={tabsData}
          hideHeader={markerSettingsTypes.length === 1}
          setActiveTabIndex={setActiveTab}
        />
      </div>
    </ModalComponent>
  );
};
