import {
  createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { isPresentationalMapByPathname } from '~/_shared/presentationMode/presentationModeContext';
import { delay } from '~/_shared/utils/delay';
import { type CancellablePromise } from '~/_shared/utils/types/common.type';
import { removeUserWasDisabled } from '~/clientTeamManagement/clientTeamManagement.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';
import { useIsUserSignedInSelector } from '~/store/userData/userData.selectors';
import { ImpersonationService } from '../impersonation';
import {
  ACCESS_TOKEN_EXP_KEY, AuthBroadcastChannel, type AuthMessage, frontendLogoutActions, initializeUser,
  isStoredRefreshTokenValid, REFRESH_TOKEN_KEY, RefreshTokenErrorCodes, refreshTokens, removeAuthTokens,
} from './auth.utils';

// Check for token validity each second
// In case device wakes from sleep and the token is expired
// We need to check validity as soon as possible
const tokenCheckIntervalInMs = 1000;

export type AuthContextType = {
  setAuthTokens: (refreshToken: string, accessExpireIn: string) => void;
  removeAuthTokens: () => void;
  error: string | null;
  clearError: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  setAuthTokens: () => undefined,
  removeAuthTokens: () => undefined,
  error: null,
  clearError: () => undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const [refreshPromise, setRefreshPromise] = useState<CancellablePromise<boolean>>();
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const isViewPresentational = useMemo(() => isPresentationalMapByPathname(location.pathname), [location]);
  const { openModal: openImpersonationModal } = useModal(ModalType.ImpersonationChanged);
  const isUserSignedIn = useIsUserSignedInSelector();

  const refreshCallback = useCallback(async () => {
    ImpersonationService.checkExpiration();
    if (!isStoredRefreshTokenValid()) {
      frontendLogoutActions();
      return false;
    }

    setError(null);

    try {
      await refreshTokens();
    }
    catch (error) {
      if (error?.errorCode === RefreshTokenErrorCodes.INVALID_TOKEN) {
        AuthBroadcastChannel.postMessage({ type: 'logout', errorCode: RefreshTokenErrorCodes.INVALID_TOKEN });
        setError(RefreshTokenErrorCodes.INVALID_TOKEN);
        frontendLogoutActions();
      }
      else {
        console.error(error);
      }

      return false;
    }

    const delayPromise = delay(tokenCheckIntervalInMs).then(refreshCallback);
    setRefreshPromise(delayPromise);

    return true;
  }, []);

  const initialize = useCallback(async () => {
    try {
      if (!isStoredRefreshTokenValid() || isViewPresentational) {
        return;
      }

      const isSuccess = await refreshCallback();
      if (isSuccess) {
        await initializeUser();
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setIsInitializing(false);
    }
  }, [refreshCallback, isViewPresentational]);

  const onMessageBroadcast = useCallback(({ data }: MessageEvent<AuthMessage>) => {
    if (isViewPresentational) {
      return;
    }

    switch (data.type) {
      case 'logout': {
        if (data.errorCode) {
          setError(data.errorCode);
        }

        frontendLogoutActions();
        break;
      }
      case 'impersonationChanged': {
        openImpersonationModal({
          reason: data.reason,
          requireRefresh: true,
        });
        break;
      }
      case 'licenseUpdated': {
        dispatch(userGetDataRequest());
        break;
      }
      default:
        break;
    }
  }, [dispatch, isViewPresentational, openImpersonationModal]);

  useEffect(() => {
    AuthBroadcastChannel.addEventListener('message', onMessageBroadcast);

    return () => AuthBroadcastChannel.removeEventListener('message', onMessageBroadcast);
  }, [onMessageBroadcast]);

  const onTabVisibilitychange = useCallback(() => {
    if (document.visibilityState === 'visible' && !isUserSignedIn) {
      initialize();
    }
  }, [initialize, isUserSignedIn]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onTabVisibilitychange);

    return () => document.removeEventListener('visibilitychange', onTabVisibilitychange);
  }, [onTabVisibilitychange]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    return () => refreshPromise?.cancel();
  }, [refreshPromise]);

  const setAuthTokens: AuthContextType['setAuthTokens'] = useCallback((newRefreshToken, accessExpireIn) => {
    setError(null);
    localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
    localStorage.setItem(ACCESS_TOKEN_EXP_KEY, accessExpireIn);

    const delayPromise = delay(tokenCheckIntervalInMs).then(refreshCallback);

    setRefreshPromise(delayPromise);
  }, [refreshCallback]);

  const clearError = useCallback(() => {
    setError(null);
    removeUserWasDisabled();
  }, []);

  const value = useMemo(() => ({
    setAuthTokens,
    removeAuthTokens,
    error,
    clearError,
  }), [clearError, setAuthTokens, error]);

  return (
    <AuthContext.Provider value={value}>
      {isInitializing ? <OverlayLoaderComponent /> : children}
    </AuthContext.Provider>
  );
};
