import {
  type FC, useMemo,
} from 'react';
import { type MarkerEntityOffsets } from '~/_shared/utils/markers/offsets.helpers';
import { ActiveMarkerCircleIndicatorComponent } from './activeMarkerCircleIndicator.component';
import { centerAroundMarkerIndicators } from './activeMarkerIndicator.constants';
import { ActiveMarkerIndicators } from './activeMarkerIndicator.types';
import { ActiveMarkerPulsatingIndicatorComponent } from './activeMarkerPulsatingIndicator.component';

type ActiveMarkerCircleIndicatorProps = {
  circleIndicatorType: typeof ActiveMarkerIndicators.radarCircle;
  className?: string;
  color: string;
  markerIndicatorOffsets?: MarkerEntityOffsets;
  size: number;
  speed: number;
};

export const ActiveMarkerCircleIndicatorContainer: FC<ActiveMarkerCircleIndicatorProps> = (props) => {

  const isCenteredOnMarkerNotAnchor = useMemo(() => (
    centerAroundMarkerIndicators.includes(props.circleIndicatorType)
  ), [props.circleIndicatorType]);

  const radius = useMemo(() => (
    (props.markerIndicatorOffsets?.radius || 50) * (props.size / 100)
  ), [props.markerIndicatorOffsets?.radius, props.size]);

  const topOffset = useMemo(() => {
    if (!props.markerIndicatorOffsets) {
      return;
    }

    return (
      radius / 2 + (isCenteredOnMarkerNotAnchor ? props.markerIndicatorOffsets.centerToAnchorOffset : 0)
    );
  }, [isCenteredOnMarkerNotAnchor, props.markerIndicatorOffsets, radius]);

  const circleComponent = useMemo(() => {
    switch (props.circleIndicatorType) {
      // case <SPINNING_TYPE>:
      //   return (
      //     <ActiveMarkerSpinningIndicatorComponent
      //       color={props.color}
      //       diameter={props.markerIndicatorOffsets.radius}
      //     />
      //   );

      case ActiveMarkerIndicators.radarCircle:
        return (
          <ActiveMarkerPulsatingIndicatorComponent
            color={props.color}
            speed={props.speed}
          />
        );

      default:
        return false;
    }
  }, [props.circleIndicatorType, props.color, props.speed]);

  return (
    <ActiveMarkerCircleIndicatorComponent
      css={props.className}
      leftOffset={props.markerIndicatorOffsets ? radius / 2 : undefined}
      diameter={radius}
      topOffset={topOffset}
    >
      {circleComponent}
    </ActiveMarkerCircleIndicatorComponent>
  );
};
