import { type TranslationFnc } from '../../../utils/hooks';

export const getDemographicRegionLabel = (regionId: string, t: TranslationFnc): string => {
  switch (regionId) {
    case 'US': {
      return t('United States');
    }
    case 'CA': {
      return t('Canada');
    }
    default: {
      return regionId;
    }
  }
};
