import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Spinner]: {
  views: {
    default: {
      canvasWidth: 800,
      canvasHeight: 600,
      iconWidth: 270,
      iconHeight: 265,
      offsetLeft: 270,
      offsetTop: 175,
    },
  },
  colors: {
    fill: '#000',
  },
  gradients: {},
  frameRate: 70,
  segments: {
    default: [0, 180] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/91617-loader2" */
      './91617-loader2.json'
    ),
} };

export const { Spinner } = config;
