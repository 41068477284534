import { useCallback } from 'react';
import { DATA_ROUTE } from '~/_shared/constants/routes';
import {
  determineRequiredMaptiveScopes, GoogleAPIOperation,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { GoogleSheetMapSyncType } from '~/map/map.repository';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { invokeGoogleOauth } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';

export const useReconnectGoogleSheet = () => {
  const mapId = useMapIdSelector();
  const dataRouteWithMapId = DATA_ROUTE.replace(':mapId', mapId?.toString() || '');
  const { openModal: openGoogleSpreadsheetSelector } = useModal(ModalType.GoogleSpreadsheetSelector);
  const { openModal: openReconnectGoogleSheetSyncModal } = useModal(ModalType.ReconnectGoogleSheet);

  const redirectToGoogleOAuthAndBack = useCallback(() => {
    invokeGoogleOauth({
      operation: GoogleAPIOperation.RECONNECT_GOOGLE_SPREADSHEET,
      scopes: determineRequiredMaptiveScopes(GoogleSheetMapSyncType.GoogleToMaptive),
      callbackUrlSuffix: dataRouteWithMapId,
    });
  }, [dataRouteWithMapId]);

  const handleReselectGoogleSpreadsheetAndResync = useCallback(() => {
    openGoogleSpreadsheetSelector({
      onGoogleSpreadsheetSheetSelected: (googleSpreadsheetInfo, sheetId) => {
        openReconnectGoogleSheetSyncModal({
          googleSheetSpreadsheetInfo: { ...googleSpreadsheetInfo, googleSheetId: sheetId },
        });
      },
      onGoogleAuthError: () => {
        redirectToGoogleOAuthAndBack();
      },
    });
  }, [openGoogleSpreadsheetSelector, openReconnectGoogleSheetSyncModal, redirectToGoogleOAuthAndBack]);

  return useCallback(() => {
    // for now, this component is used only when there is a spreadsheet-based map which was disconnected from a google spreadsheet
    // however, in the future, we will add additional UI which will allow user to reconnect to a different google spreadsheet
    handleReselectGoogleSpreadsheetAndResync();
  }, [handleReselectGoogleSpreadsheetAndResync]);
};
