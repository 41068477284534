import { css } from '@emotion/react';
import { type TinyMapPlaceholderSize } from './tinyMapPlaceholder.component';
import { getContainerDimension } from './tinyMapPlaceholder.helpers';

const getContainerDimensionCss = (size: TinyMapPlaceholderSize) => {
  const dimension = getContainerDimension(size);
  return css({
    height: dimension,
    width: dimension,
    minHeight: dimension,
    minWidth: dimension,
  });
};

export const getContainerCss = (size: TinyMapPlaceholderSize) => css([
  getContainerDimensionCss(size),
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 4,
    flexShrink: 0,
  },
]);

export const markerMapPlaceholderStyle = css({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});
