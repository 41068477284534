import {
  BaseMapElementTypeName, BaseMapFeatureTypeName,
} from '../baseMap.enums';

export const baseMapFeatureTypePrimary = new Set([
  BaseMapFeatureTypeName.all,
  BaseMapFeatureTypeName.administrative,
  BaseMapFeatureTypeName.landscape,
  BaseMapFeatureTypeName.poi,
  BaseMapFeatureTypeName.road,
  BaseMapFeatureTypeName.transit,
  BaseMapFeatureTypeName.water,
]);

export const baseMapFeatureTypeTertiary = new Set([
  BaseMapFeatureTypeName['landscape.natural.landcover'],
  BaseMapFeatureTypeName['landscape.natural.terrain'],
  BaseMapFeatureTypeName['road.highway.controlledAccess'],
  BaseMapFeatureTypeName['transit.station.airport'],
  BaseMapFeatureTypeName['transit.station.bus'],
  BaseMapFeatureTypeName['transit.station.rail'],
]);

export const baseMapElementTypePrimary = new Set([
  BaseMapElementTypeName.all,
  BaseMapElementTypeName.geometry,
  BaseMapElementTypeName.labels,
]);

export const baseMapElementTypeTertiary = new Set([
  BaseMapElementTypeName['labels.text.fill'],
  BaseMapElementTypeName['labels.text.stroke'],
]);
