import {
  useEffect, useMemo,
} from 'react';
import { useHoveredMarkerSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useMapContext } from '../../mapContext';
import { MarkerHoverEffectsManager } from './markerHoverEffectsManager';
import { useMarkerHoverLabelSettings } from './useMarkerHoverLabelSettings';

export const useMarkerHoverEffects = () => {
  const { webglLayers } = useMapContext();
  const hoveredMarker = useHoveredMarkerSelector();
  const hoverLabelSettings = useMarkerHoverLabelSettings(hoveredMarker);

  const manager = useMemo(() => new MarkerHoverEffectsManager(webglLayers), [webglLayers]);

  useEffect(() => {
    manager.updateAboveMarkerLabel(
      hoverLabelSettings.shouldBeDisplayed,
      hoverLabelSettings.hoverMarkerName,
      hoverLabelSettings.aboveLabelSettings,
      hoverLabelSettings.markerEntityOffsets,
    );
  }, [hoverLabelSettings.aboveLabelSettings, hoverLabelSettings.hoverMarkerName,
    hoverLabelSettings.markerEntityOffsets, hoverLabelSettings.shouldBeDisplayed, manager,
  ]);

  useEffect(() => {
    return () => {
      manager?.destroy();
    };
  }, [manager]);
};
