import { type RadiusFilterItemRequest } from '~/spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import { type FilterAction } from './filter/filter';
import { type Geometry } from './polygon/polygon.types';

export const FilterDataTypes = {
  GROUP: 'group',
  TEXT: 'text',
  NUMBER: 'number',
  ATTRIBUTE: 'attribute',
  DATE: 'date',
  POLYGON: 'polygon',
  RADIUS: 'radius',
} as const;

export type FilterDataType = typeof FilterDataTypes[keyof typeof FilterDataTypes];

type FilterItemGroup = {
  col_id: string;
  data_type: typeof FilterDataTypes.GROUP;
  action: FilterAction.Equal;
  value: string;
};

type FilterItemText = {
  col_id: string;
  data_type: typeof FilterDataTypes.TEXT;
  action: FilterAction;
  value: string;
};

type FilterItemAttribute = {
  col_id: string;
  data_type: typeof FilterDataTypes.ATTRIBUTE;
  action: FilterAction.ArrayContains | FilterAction.ArrayNotContains;
  value: string;
};

type FilterItemNumber = {
  col_id: string;
  data_type: typeof FilterDataTypes.NUMBER;
  action: FilterAction.LessThan | FilterAction.LessThanOrEqual | FilterAction.GreaterThan |
  FilterAction.GreaterThanOrEqual | FilterAction.LessThanPercentage | FilterAction.LessThanOrEqualPercentage |
  FilterAction.GreaterThanPercentage | FilterAction.GreaterThanOrEqualPercentage;
  value: number;
};

type FilterItemNotANumber = {
  col_id: string;
  data_type: typeof FilterDataTypes.NUMBER;
  action: FilterAction.NotANumber;
  value: null;
};

type FilterItemNumberRange = {
  col_id: string;
  data_type: typeof FilterDataTypes.NUMBER;
  action: FilterAction.Range | FilterAction.RangeIncludingMax |
  FilterAction.RangePercentage;
  value: [number, number];
};

type FilterItemDate = {
  col_id: string;
  data_type: typeof FilterDataTypes.DATE;
  action: FilterAction.LessThan | FilterAction.LessThanOrEqual | FilterAction.GreaterThan |
  FilterAction.GreaterThanOrEqual;
  value: string;
};

type FilterItemDateRange = {
  col_id: string;
  data_type: typeof FilterDataTypes.DATE;
  action: FilterAction.Range;
  value: [string, string];
};

type FilterItemRadius = {
  data_type: typeof FilterDataTypes.RADIUS;
  value: RadiusFilterItemRequest;
};

type FilterItemPolygon = {
  data_type: typeof FilterDataTypes.POLYGON;
  value: Geometry;
};

export type FilterTreeItemLiteral = {
  type: 'literal';
  ref: FilterItemGroup | FilterItemText | FilterItemNumber | FilterItemNumberRange |
  FilterItemNotANumber | FilterItemAttribute | FilterItemDate |
  FilterItemDateRange | FilterItemRadius | FilterItemPolygon;
};

export type FilterTree = {
  type: 'and' | 'or';
  children: Array<FilterTreeItemLiteral | FilterTree>;
};
