const DRAWING_LABEL_TEXT_INPUT_PADDING = 7;

export const getLabelTextInputPadding = (fontSize: number) =>
  Math.min(DRAWING_LABEL_TEXT_INPUT_PADDING, fontSize / 2);

export const getLabelTextInputHeight = (fontSize: number): number => {
  const height = fontSize + 2 * getLabelTextInputPadding(fontSize);

  return height;
};

export const getLabelOutlineSize = (fontSize: number, dotSizePercentage: number) => {
  const size = Math.round(getLabelTextInputHeight(fontSize) * dotSizePercentage / 100);

  // ensure minmum visible size
  return Math.max(size, 5);
};
