import { createAction } from '@reduxjs/toolkit';
import type { PlaceId } from '~/places/places.types';
import type { PlacesSearch } from './mapSettingsPlaces.state';

export const mapSettingsPlacesAddSearch = createAction(
  'MAP_SETTINGS_PLACES_ADD_SEARCH',
  (newSearch: PlacesSearch) => ({
    payload: { newSearch },
  })
);

export const mapSettingsPlacesRemoveSearch = createAction(
  'MAP_SETTINGS_PLACES_REMOVE_SEARCH',
  (ids: ReadonlySet<PlaceId>) => ({
    payload: { ids },
  })
);
