import { copy } from '../../_shared/utils/collections/collections';
import {
  type BoundaryTerritoryDetailsState, type BoundaryTerritoryGroupDetailsItem,
} from './boundaryTerritoryGroups.state';

export const assignBoundaryToTerritory = (
  state: BoundaryTerritoryDetailsState, boundaryTerritoryGroupId: number, territoryId: string, boundaryId: number
): BoundaryTerritoryDetailsState => {
  const details = state.territoryDetails.data.get(boundaryTerritoryGroupId);
  if (!details) {
    return state;
  }

  const manualAssignmentsClone = { ...details.manualAssignments };
  manualAssignmentsClone[territoryId] = [...(manualAssignmentsClone[territoryId] ?? []), boundaryId];
  const updatedDetails: BoundaryTerritoryGroupDetailsItem = { ...details, manualAssignments: manualAssignmentsClone };

  const updatedTerritoryGroupDetails = copy.andReplace(state.territoryDetails.data, boundaryTerritoryGroupId, updatedDetails);
  const stateClone = { ...state };

  stateClone.territoryDetails.data = updatedTerritoryGroupDetails;
  return stateClone;
};

export const removeTerritoryAssignmentsFromBoundaryTerritoryDetails = (
  state: BoundaryTerritoryDetailsState, boundaryTerritoryGroupId: number, territoryId: string
): BoundaryTerritoryDetailsState => {
  const currentDetails = state.territoryDetails.data.get(boundaryTerritoryGroupId);

  if (!currentDetails) {
    return state;
  }

  const newAssignments = {
    ...currentDetails.assignments,
  };
  delete newAssignments[territoryId];

  const newManualAssignments = {
    ...currentDetails.manualAssignments,
  };
  delete newManualAssignments[territoryId];

  const newDetails: BoundaryTerritoryGroupDetailsItem = {
    ...currentDetails,
    assignments: newAssignments,
    manualAssignments: newManualAssignments,
  };

  return {
    ...state,
    territoryDetails: {
      ...state.territoryDetails,
      data: copy.andReplace(state.territoryDetails.data, boundaryTerritoryGroupId, newDetails),
    },
  };
};
