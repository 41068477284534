import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type ThemeProps } from '../../types/themeProps';

const rootStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.primary,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  cursor: 'pointer',
  display: 'flex',
  height: 56,
  padding: 10,

  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const nameStyle = ({ indent }: {indent?: number}) => css({
  marginLeft: indent ?? 0,
});

const iconStyle = css({
  marginLeft: 'auto',
});

export const defaultMultilevelMenuItemRenderer = (props: ThemeProps<{
  name: string;
  onClick: () => void;
  indent?: number;
  index: number;
  style?: SerializedStyles;
}>) => (
  <div
    css={css(rootStyle({ theme: props.theme }), props.style)}
    onClick={props.onClick}
    key={props.index}
  >
    <div css={nameStyle({ indent: props.indent })}>
      {props.name}
    </div>
    <FontAwesomeIcon
      css={iconStyle}
      icon={faChevronRight}
    />
  </div>
);
