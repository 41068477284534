import { css } from '@emotion/react';
import {
  type EventHandler,
  forwardRef, type ReactNode, type SyntheticEvent, useMemo,
} from 'react';
import {
  type TooltipBehavior, type TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  type FaIcon, FontAwesomeIcon,
} from '../../icon/fontAwesomeIcon.component';
import { usePointerClickEvent } from '../../utils/usePointerClickEvent';
import { CSS_CLASSNAME } from './constants';
import {
  RoundButtonStyle, RoundButtonStyles,
} from './roundButton.styles';

export enum RoundButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export const getRoundButtonSize = (size: RoundButtonSize) => {
  switch (size) {
    case RoundButtonSize.Small:
      return '25px';
    case RoundButtonSize.Medium:
      return '32px';
    case RoundButtonSize.Large:
    default:
      return '40px';
  }
};

export const getRoundButtonFontSize = (size: RoundButtonSize) => {
  switch (size) {
    case RoundButtonSize.Small:
      return '12px';
    case RoundButtonSize.Medium:
      return '15px';
    case RoundButtonSize.Large:
    default:
      return '18px';
  }
};

export const getHoverCss = (props: ThemeProps<{ btnStyle?: RoundButtonStyle}>) => {
  const btnStyles = RoundButtonStyles(props.theme)[props.btnStyle || RoundButtonStyle.Primary];
  return {
    background: btnStyles.backgroundHoverColor,
    border: `2px solid ${btnStyles.borderHoverColor}`,
    color: btnStyles.textHoverColor,
  };
};

const buttonStyle = (props: {
  theme: Theme;
  btnStyle: RoundButtonStyle;
  size: RoundButtonSize;
  isDisabled?: boolean;
}) => {
  const btnStyles = RoundButtonStyles(props.theme)[props.btnStyle];
  return css({
    background: btnStyles.backgroundColor,
    border: btnStyles.border ?? 0,
    borderRadius: '50%',
    boxShadow: props.theme.roundButtonStyle.boxShadow,
    color: btnStyles.textColor,
    fontSize: getRoundButtonFontSize(props.size),
    lineHeight: 1,
    width: getRoundButtonSize(props.size),
    height: getRoundButtonSize(props.size),
    minWidth: getRoundButtonSize(props.size),
    minHeight: getRoundButtonSize(props.size),
    textAlign: 'center',
    padding: 0,
    pointerEvents: props.isDisabled ? 'none' : undefined, // workaround for react-popper behaving wrong on disabled buttons: https://github.com/ariakit/ariakit/issues/471
    '&:hover': getHoverCss(props),
  });
};

type RoundButtonDispatchProps = Readonly<{
  onClick?: EventHandler<SyntheticEvent>;
}>;

export type RoundButtonProps = RoundButtonStateProps & RoundButtonDispatchProps;

type RoundButtonStateProps = Readonly<{
  buttonStyle?: RoundButtonStyle;
  className?: string;
  icon: FaIcon;
  isDisabled?: boolean;
  size?: RoundButtonSize;
  tooltipLabel?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipBehavior?: TooltipBehavior;
  children?: ReactNode;

  testid?: string;
}>;

export const RoundButtonComponent = forwardRef<HTMLButtonElement, RoundButtonProps>((props, ref) => {
  const theme = useTheme();

  const buttonClassname = useMemo(() => `${CSS_CLASSNAME} ${props.className || ''}`, [props.className]);
  const size = props.size ?? RoundButtonSize.Large;

  const style = props.buttonStyle ?? RoundButtonStyle.Primary;

  const { onClick, onPointerDown, onPointerUp } = usePointerClickEvent(props.onClick);

  return (
    <TooltipDeprComponent
      tooltipContent={props.tooltipLabel}
      behavior={props.tooltipBehavior}
      placement={props.tooltipPlacement}
    >
      <button
        ref={ref}
        type="button"
        css={buttonStyle({
          theme,
          btnStyle: style,
          size,
          isDisabled: props.isDisabled,
        })}
        onClick={onClick}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        className={buttonClassname}
        disabled={props.isDisabled}
        data-testid={props.testid}
      >
        <FontAwesomeIcon
          css={css({ margin: 0 })}
          icon={props.icon}
        />
        {props.children}
      </button>
    </TooltipDeprComponent>
  );
});
