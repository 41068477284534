import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { MembersLimitReachedError } from '~/clientTeamManagement/teamManagementModal/newMemberForm/membersLimitReachedError.component';
import {
  clientInviteeListChanged,
  clientMemberListChanged,
} from '~/store/frontendState/client/client.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserCurrentClientSelector } from '~/store/userData/userData.selectors';
import { getTeamMemberLimitProhibitsAdd } from '../teamManagement.helpers';
import { type TeamMemberLimitsData } from '../teamManagement.repository';
import { useSendInvitation } from '../useTeamManagement';
import { NewMemberFormComponent } from './newMemberForm.component';

type NewMemberFormContainerProps = Readonly<{
  teamMemberLimits?: TeamMemberLimitsData;
}>;

export const NewMemberFormContainer: FC<NewMemberFormContainerProps> = ({ teamMemberLimits }) => {
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const currentLicensePlanCode = useUserCurrentClientSelector()?.license?.code;

  const subscriptionIncludesMembers = !!teamMemberLimits && (teamMemberLimits.limit + teamMemberLimits.used) > 1;
  const teamMemberLimitProhibitsAdd = getTeamMemberLimitProhibitsAdd(teamMemberLimits);

  const {
    error: sendInvitationError,
    errorOverMembersLimit,
    isLoading: isSendingInvitation,
    update: sendInvitation,
  } = useSendInvitation();

  const addUser = useCallback((
    newUserEmail: string, newFirstName: string, newLastName: string, onSuccessCallback: () => void,
  ) => {
    if (clientId) {
      sendInvitation(newUserEmail, newFirstName, newLastName, clientId).then(() => {
        onSuccessCallback();
        dispatch(clientMemberListChanged());
        dispatch(clientInviteeListChanged());
      });
    }
  }, [clientId, sendInvitation, dispatch]);

  const error = useMemo(() => {
    if ((errorOverMembersLimit || teamMemberLimitProhibitsAdd) && currentLicensePlanCode) {
      return (
        <MembersLimitReachedError
          subscriptionIncludesMembers={subscriptionIncludesMembers}
        />
      );
    }
    else {
      return sendInvitationError;
    }
  }, [currentLicensePlanCode, errorOverMembersLimit, sendInvitationError, subscriptionIncludesMembers, teamMemberLimitProhibitsAdd]);

  return (
    <NewMemberFormComponent
      addUser={teamMemberLimitProhibitsAdd ? undefined : addUser}
      error={error}
      isLoading={isSendingInvitation}
    />
  );
};
