import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import type { Theme } from '~/_shared/themes/theme.model';

const LABEL_FONT_SIZE = 14;
export const LABEL_HEIGHT = LABEL_FONT_SIZE + 2;
export const LABEL_MARGIN_BOTTOM = 2;

const labelStyles = ({ marginTop }: {readonly marginTop?: number }) => (theme: Theme) => css({
  alignItems: 'center',
  color: theme.textColors.primary,
  display: 'flex',
  fontSize: LABEL_FONT_SIZE,
  height: LABEL_HEIGHT,
  marginBottom: LABEL_MARGIN_BOTTOM,
  marginTop,
  textTransform: 'uppercase',
});

const hintStyles = (theme: Theme) => css({ color: theme.iconColors.primary });

export type FormLabelProps = Readonly<{
  marginTop?: number;
  tooltip?: ReactNode;
  className?: string;
  htmlFor?: string;
  children?: React.ReactNode;
}>;

export const FormLabel: FC<FormLabelProps> = (props) => {
  return (
    <label
      css={labelStyles({ marginTop: props.marginTop })}
      className={props.className}
      htmlFor={props.htmlFor}
    >
      {props.children}
      {props.tooltip && (
        <>
        &nbsp;
          <HintTooltipComponent
            tooltipContent={props.tooltip}
            css={hintStyles}
          />
        </>
      )}

    </label>
  );
};
