import { type BoundaryDragEditAction } from './boundaryPolygonUpdate.action';
import {
  BOUNDARY_POLYGON_UPDATE_ERROR,
  BOUNDARY_POLYGON_UPDATE_FINISHED,
  BOUNDARY_POLYGON_UPDATE_STARTED,
} from './boundaryPolygonUpdate.actionTypes';

type PolygonUpdateState = {
  isWaiting: boolean;
};

const initialState: PolygonUpdateState = {
  isWaiting: false,
};

export const boundaryPolygonUpdateReducer = (state: PolygonUpdateState = initialState, action: BoundaryDragEditAction): PolygonUpdateState => {
  switch (action.type) {
    case BOUNDARY_POLYGON_UPDATE_STARTED: {
      return {
        isWaiting: true,
      };
    }

    case BOUNDARY_POLYGON_UPDATE_FINISHED:
    case BOUNDARY_POLYGON_UPDATE_ERROR: {
      return {
        isWaiting: false,
      };
    }

    default:
      return state;
  }
};
