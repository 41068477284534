import { type BoundaryHighlightAction } from './boundaryHighlight.action';
import {
  BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER,
} from './boundaryHighlight.actionTypes';
import { type BoundaryHighlightState } from './boundaryHighlight.state';

const initialState: BoundaryHighlightState = {
  highlightedBoundary: null,
  highlightedBoundaryTerritory: null,
};

export const boundaryHighlightReducer = (
  state = initialState, action: BoundaryHighlightAction
): BoundaryHighlightState => {
  switch (action.type) {
    case BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER: {
      return {
        ...state,
        highlightedBoundary: { ...action.payload },
        highlightedBoundaryTerritory: null,
      };
    }

    case BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER: {
      if (
        state.highlightedBoundary?.boundaryGroupId === action.payload.boundaryGroupId &&
        state.highlightedBoundary.boundaryId === action.payload.boundaryId
      ) {
        return {
          ...state,
          highlightedBoundary: null,
        };
      }

      return state;
    }

    case BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER: {
      return {
        ...state,
        highlightedBoundary: null,
        highlightedBoundaryTerritory: { ...action.payload },
      };
    }

    case BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER: {
      if (state.highlightedBoundaryTerritory?.boundaryTerritoryGroupId === action.payload.boundaryTerritoryGroupId) {
        return {
          ...state,
          highlightedBoundaryTerritory: null,
        };
      }

      return state;
    }

    default:
      return state;
  }
};
