import {
  createContext, type PropsWithChildren, useContext,
} from 'react';
import { useIntercomInternal } from '~/_shared/utils/3rdPartyIntegrations/intercom/useIntercomInternal';
import { noop } from '~/_shared/utils/function.helpers';

type IntercomContextType = ReturnType<typeof useIntercomInternal>;

const defaultContextValue: IntercomContextType = {
  isBooted: false,
  isInitialized: false,
  isShown: false,
  show: noop,
  unreadCount: 0,
};

const intercomContext = createContext<IntercomContextType>(defaultContextValue);

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  const intercom = useIntercomInternal();

  return (
    <intercomContext.Provider value={intercom}>
      {children}
    </intercomContext.Provider>
  );
};

export const useIntercom = () => {
  return useContext(intercomContext);
};
