import { type FileUrl } from '~/store/frontendState/fileUrls/fileUrls.state';
import { type MarkerTemplate } from '../../markers/manager/mapMarkerTemplates';

export const getTemplateFromFile = (file: FileUrl): MarkerTemplate => ({
  name: `drawing-image-${file.fileId}`,
  main: file['200p'].url,
  size: {
    w: file['200p'].resolution.width,
    h: file['200p'].resolution.height,
  },
});
