import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  MAP_SETTINGS_FETCH_DATA_REQUEST,
  MAP_SETTINGS_FETCH_DATA_SUCCESS,
} from '../data/mapSettingsData.actionTypes';
import { mapSettingsReadySet } from './mapSettingsReady.actionCreators';

export function* mapSettingsReadySagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_REQUEST, setIsReadyFalse);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, setIsReadyTrue);
}

function* setIsReadyFalse() {
  yield put(mapSettingsReadySet(false));
}

function* setIsReadyTrue() {
  yield put(mapSettingsReadySet(true));
}
