import { type FC } from 'react';

type YoutubeEmbedProps = {
  videoId: string;
  className?: string;
};

export const YoutubeEmbed: FC<YoutubeEmbedProps> = ({ videoId, className }) => (
  <iframe
    className={className}
    src={`https://www.youtube.com/embed/${videoId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube video"
    referrerPolicy="strict-origin-when-cross-origin"
  />
);
