export enum SidebarApp {
  AccountSettings = 'AccountSettings',
  Boundary = 'Boundary',
  Proximity = 'Proximity',
  Filter = 'Filter',
  Grouping = 'Grouping',
  HeatMap = 'HeatMap',
  MapLegend = 'MapLegend',
  MapSettings = 'MapSettings',
  MapTools = 'MapTools',
  Routing = 'Routing',
  SaveMap = 'SaveMap',
  ShareMap = 'ShareMap',
}
