import { css } from '@emotion/react';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useCallback, useEffect, useState,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import { CONFIG } from '~/_shared/constants/config';
import {
  FORGOTTEN_PASSWORD_ROUTE, REGISTER_ROUTE,
} from '~/_shared/constants/routes';
import { FormFieldType } from '~/_shared/utils/form/formValidation.helpers';
import { useFormFieldValidation } from '~/_shared/utils/form/useFormFieldValidation';
import { useTranslation } from '~/_shared/utils/hooks';
import { refreshTokenValidityInDays } from '~/authorization/authContext/auth.utils';
import {
  authorizationPagesErrorStyle, authorizationPagesLinkStyle,
} from '../../authorizationPages.styles';
import { AuthorizationSubmitButtonComponent } from '../../submitButton/authorizationSubmitButton.component';
import { validateLoginForm } from './loginForm.helpers';
import { UserLoginErrorComponent } from './userLoginError.component';

const inputWrapperStyle = css({
  marginBottom: '16px',
});

const linksWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const rememberMeCheckboxStyle = css({
  marginBottom: 15,
});

type LoginFormProps = {
  error: string;
  isLoading: boolean;

  onSubmit: (email: string, password: string, rememberMe: boolean) => void;
};

export const LoginFormComponent: FC<LoginFormProps> = (props) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showError, setShowError] = useState(false);
  const [t] = useTranslation();

  const { value: email, onBlur: onEmailBlur, onChange: onEmailChange, errors: emailErrors } = useFormFieldValidation({
    type: FormFieldType.Email,
  });
  const { value: password, onBlur: onPasswordBlur, onChange: onPasswordChange, errors: passwordErrors } = useFormFieldValidation({
    type: FormFieldType.Required,
    requiredFieldErrorMessage: t('Password must not be empty'),
  });

  const isFormValid = validateLoginForm(email, password);

  const toggleRememberMe = useCallback(() => setRememberMe(value => !value), []);

  const onFormSubmit = useCallback((e: FormEvent) => {
    setShowError(false);
    e.preventDefault();

    if (!validateLoginForm(email, password)) {
      return;
    }

    const submit = props.onSubmit;

    submit(email, password, rememberMe);
  }, [email, password, props.onSubmit, rememberMe]);

  useEffect(() => {
    setShowError(!!props.error);
  }, [props.error]);

  return (
    <form
      onSubmit={onFormSubmit}
    >
      <div css={inputWrapperStyle}>
        <TextInputAuthComponent
          icon={faEnvelope}
          placeholder={t('Email')}
          type="email"
          value={email}
          hasError={emailErrors.length > 0}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          isDisabled={props.isLoading}
        />

        {emailErrors.length > 0 && (
          <FormErrorMessageComponent
            css={authorizationPagesErrorStyle}
            messages={emailErrors}
          />
        )}
      </div>

      <div css={inputWrapperStyle}>
        <TextInputAuthComponent
          icon={faLockAlt}
          placeholder={t('Password')}
          type="password"
          value={password}
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
          hasError={passwordErrors.length > 0}
          isDisabled={props.isLoading}
        />

        {passwordErrors.length > 0 && (
          <FormErrorMessageComponent
            css={authorizationPagesErrorStyle}
            messages={passwordErrors}
          />
        )}
      </div>

      <div css={rememberMeCheckboxStyle}>
        <CheckboxComponent
          checkedSetter={toggleRememberMe}
          isChecked={rememberMe}
          isDisabled={props.isLoading}
          text={t('Remember me', { numberOfDays: refreshTokenValidityInDays })}
          textStyle={css({ fontSize: 18 })}
        />
      </div>

      <AuthorizationSubmitButtonComponent
        isLoading={props.isLoading}
        isDisabled={!isFormValid || props.isLoading}
      >
        {t('Sign in')}
      </AuthorizationSubmitButtonComponent>

      {showError && (
        <UserLoginErrorComponent
          code={props.error}
          userEmail={email}
        />
      )}

      <div css={linksWrapperStyle}>
        <LinkComponent path={FORGOTTEN_PASSWORD_ROUTE} >
          <span css={authorizationPagesLinkStyle}>{t('Forgot Password?')}</span>
        </LinkComponent>

        {CONFIG.SHOW_SIGNUP && (
          <LinkComponent path={REGISTER_ROUTE} >
            <span css={authorizationPagesLinkStyle}>{t('Sign Up Here')}</span>
          </LinkComponent>
        )}
      </div>
    </form>
  );
};
