import { css } from '@emotion/react';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useChooseColumnModal } from '~/_shared/components/chooseColumnModal/useChooseColumnModal';
import {
  MarkerComponent, type MarkerProps,
} from '~/_shared/components/marker/marker.component';
import {
  PermanentConfirmStrategy, useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { isStandardMarkerVisualSettings } from '~/_shared/types/marker.types';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD, UNCLUSTER_BELOW_LIMITS, type UnclusterBelowLevel,
} from '~/_shared/utils/markers/markers.constants';
import { CustomizationLevel } from '~/customizeMarkerPopup/customizeLabelAndMarker.types';
import { CustomizeMarkerTab } from '~/customizeMarkerPopup/customizeMarker/customizeMarker.component';
import { ActiveMarkerIndicatorComponent } from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.component';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useMarkerSets } from '~/store/frontendState/markerSets/markerSets.selectors';
import {
  moveMarkersCloseModal, moveMarkersOpenModal,
} from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import { useMoveMarkersIsModalVisible } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { mapSettingsUpdateMarkersGeneral } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import {
  useMapSettingsAreAnyLabelsActiveSelector, useMapSettingsMarkersGeneralSelector,
  useMapSettingsMarkerStyleIdSelector,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  mapSettingsMarkersSetUnstackMarkers,
  mapSettingsMarkersToggle, mapSettingsMarkersUpdateUnclusterBelow,
} from '~/store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import {
  useMapSettingsActiveMarkerIndicatorSettingsSelector, useMapSettingsMarkersSelector,
} from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import { useMapSettingsMarkerUnclusterBelowNSelector } from '~/store/mapSettings/markers/mapSettingsMarkersClustering.selectors';
import {
  type MapButtonsSettings, type MapTogglesSettings,
} from '../settingsAccordion.types';
import { SettingsAccordionDropdownComponent } from '../settingsAccordionDropdown.component';
import { SettingsAccordionDropDownOptionComponent } from '../settingsAccordionDropdownOption.component';
import {
  MapMarkersGraphicsDropdownSettingsName,
  MapMarkersGraphicsToggleSettingsName, MapMarkersGraphicsToggleVisualSettingsName,
} from './mapMarkersGraphics.enums';
import {
  getCustomActiveMarkerProps, getMarkerLabelProperties, getStandardActiveMarkerProps,
} from './mapMarkersGraphics.helpers';
import { type MapMarkersGraphicsSettingsStructureProps } from './mapMarkersGraphics.types';

const MARKER_CONTAINER_DIMENSIONS = { height: 44, width: 44 };
const MARKER_DIMENSIONS = { height: MARKER_CONTAINER_DIMENSIONS.height - 8, width: MARKER_CONTAINER_DIMENSIONS.width - 8 };

const activeIndicatorContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
  width: 50,
});

const markerContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  height: MARKER_CONTAINER_DIMENSIONS.height,
  justifyContent: 'center',
  width: MARKER_CONTAINER_DIMENSIONS.width,
});

const unclusterBelowNWarningContentStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
});

const unclusterBelowNDropdownValueStyle = css({
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  width: '100%',
  paddingRight: 15,
});

const unclusterBelowNOffStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  textTransform: 'uppercase',
});

export const useMapMarkersGraphicsSettings = () => {
  const [t] = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMoveMarkersModalVisible = useMoveMarkersIsModalVisible();
  const defaultMarkerStyleId = useMapSettingsMarkerStyleIdSelector();
  const activeMarkerIndicatorSettings = useMapSettingsActiveMarkerIndicatorSettingsSelector();
  const mapMarkersSettings = useMapSettingsMarkersSelector();
  const markerSets = useMarkerSets();
  const globalMarkerSettings = useMapSettingsMarkersGeneralSelector()?.globalMarkerSettings?.marker;
  const markersVisualSettings = useMapSettingsMarkersGeneralSelector();
  const unclusterBelowN = useMapSettingsMarkerUnclusterBelowNSelector();
  const isLabelsActive = useMapSettingsAreAnyLabelsActiveSelector();
  const fileUrlsLookup = useFileUrls();
  const { openModal: openMarkerSettings } = useModal(ModalType.LabelAndMarkerSettings);
  const { openModal: openLabelSettings } = useModal(ModalType.LabelAndMarkerSettings);
  const { openModal: openChooseSpreadsheetColumnModal } = useChooseColumnModal();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const [activeDropdown, setActiveDropdown] = useState<MapMarkersGraphicsDropdownSettingsName | null>(null);

  const createMarkerGraphicsTogglesProps = useCallback((featureType: MapMarkersGraphicsToggleSettingsName): MapTogglesSettings => ({
    isOn: mapMarkersSettings[featureType],
    onToggle: () => dispatch(mapSettingsMarkersToggle(featureType)),
  }), [mapMarkersSettings, dispatch]);

  const createMarkerGraphicsUnstackMarkerProps = useCallback(() => ({
    isOn: mapMarkersSettings.unstackMarkers,
    onToggle: () => dispatch(mapSettingsMarkersSetUnstackMarkers(!mapMarkersSettings.unstackMarkers)),
  }), [dispatch, mapMarkersSettings.unstackMarkers]);

  const createMarkerGraphicsVisualTogglesProps = useCallback((toggleName: MapMarkersGraphicsToggleVisualSettingsName): MapTogglesSettings => (
    {
      isOn: !!markersVisualSettings[toggleName],
      onToggle: () => {
        const onUpdateSettingsSuccess = () => {
          dispatch(mapSettingsUpdateMarkersGeneral(getMarkerLabelProperties(toggleName, markersVisualSettings)));
        };

        if (!markersVisualSettings[toggleName]) {
          switch (toggleName) {
            case MapMarkersGraphicsToggleVisualSettingsName.useTextLabel: {
              openChooseSpreadsheetColumnModal(
                false,
                markersVisualSettings.labelMarkersNameColumn ?? undefined
              )
                .then(response => {
                  dispatch(mapSettingsUpdateMarkersGeneral({
                    labelMarkersNameColumn: response.selectedSpreadsheetColumnId,
                  }));
                  onUpdateSettingsSuccess();
                })
                .catch(() => {
                  return;
                });

              break;
            }

            case MapMarkersGraphicsToggleVisualSettingsName.useLabelsAboveMarkers: {
              openChooseSpreadsheetColumnModal(
                true,
                markersVisualSettings.labelsAboveMarkersNameColumn ?? undefined,
              )
                .then(response => {
                  dispatch(mapSettingsUpdateMarkersGeneral({
                    labelsAboveMarkersDisplayRowIdIfEmpty: response.displayRowIdIfColumnEmpty,
                    labelsAboveMarkersNameColumn: response.selectedSpreadsheetColumnId,
                  }));
                  onUpdateSettingsSuccess();
                })
                .catch(() => {
                  return;
                });

              break;
            }

            default:
              onUpdateSettingsSuccess();
          }
        }
        else {
          onUpdateSettingsSuccess();
        }
      },
    }
  ), [dispatch, markersVisualSettings, openChooseSpreadsheetColumnModal]);

  const customizeActiveMarkerIndicatorProps = useMemo(() => {
    const activeIndicatorType = activeMarkerIndicatorSettings.type;
    const indicator = (
      <div css={activeIndicatorContainerStyle}>
        <ActiveMarkerIndicatorComponent
          currentActiveIndicator={activeIndicatorType}
          size={50}
          color={activeMarkerIndicatorSettings.color}
          speed={activeMarkerIndicatorSettings.speed}
        />
      </div>
    );
    return ({
      prefix: indicator,
      onClick: () => openMarkerSettings({
        customizationLevel: CustomizationLevel.All,
        overrideGroupMarkers: false,
        markerSettingsTypes: [MarkerSettingType.Marker],
        defaultTab: CustomizeMarkerTab.Indicator,
      }),
    });
  }, [activeMarkerIndicatorSettings, openMarkerSettings]);

  const showUnclusterBelowNWarning = useCallback((onSuccess: () => void) => {
    openConfirmationModal({
      onCancel: closeConfirmationModal,
      onConfirm: () => {
        closeConfirmationModal();
        onSuccess();
      },
      text: (
        <div css={unclusterBelowNWarningContentStyle}>
          <div>
            {t('unclusterBelowN.performanceImpactWarning')}
          </div>
          {!isLabelsActive && (
            <div>
              {t('Note:')}&nbsp;
              {t('unclusterBelowN.noteUnstackForcedOn', {
                threshold: UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD,
                unstackName: t('Unstack Markers'),
              })}
            </div>
          )}
        </div>
      ),
      title: t('Warning: Performance impact'),
      confirmCaption: t('Proceed'),
      permanentConfirmSettings: {
        id: 'unclusterBelowNWarning',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, isLabelsActive, openConfirmationModal, t]);

  const unclusterBelowNDropdown = useMemo(() => {
    const isDropdownOpen = activeDropdown === MapMarkersGraphicsDropdownSettingsName.unclusterBelowN;

    const getLimitNumber = (limit: UnclusterBelowLevel) => (
      isLabelsActive ? UNCLUSTER_BELOW_LIMITS[limit].labels : UNCLUSTER_BELOW_LIMITS[limit].markers
    );

    const getChangeLimit = (limit: UnclusterBelowLevel | null) => () => {
      if (!isLabelsActive
        && limit !== null
        && UNCLUSTER_BELOW_LIMITS[limit].markers >= UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD
      ) {
        showUnclusterBelowNWarning(() => {
          dispatch(mapSettingsMarkersUpdateUnclusterBelow(limit));
        });
        return;
      }
      dispatch(mapSettingsMarkersUpdateUnclusterBelow(limit));
    };

    const renderedOptions = [(
      <SettingsAccordionDropDownOptionComponent
        key={-1}
        isLast={false}
        onChange={getChangeLimit(null)}
      >
        <div css={[unclusterBelowNDropdownValueStyle, unclusterBelowNOffStyle({ theme })]}>
          {t('Off')}
        </div>
      </SettingsAccordionDropDownOptionComponent>
    ),
    ...Object.keys(UNCLUSTER_BELOW_LIMITS).map((unclusterBelowLevel: UnclusterBelowLevel, index) => (
      <SettingsAccordionDropDownOptionComponent
        key={unclusterBelowLevel}
        isLast={index === Object.keys(UNCLUSTER_BELOW_LIMITS).length - 1}
        onChange={getChangeLimit(unclusterBelowLevel)}
      >
        <div css={unclusterBelowNDropdownValueStyle}>
          {t('{{N}} Markers', { N: getLimitNumber(unclusterBelowLevel) })}
        </div>
      </SettingsAccordionDropDownOptionComponent>
    ))];

    const triggerComponent = (
      <SettingsAccordionDropdownComponent
        isOpen={isDropdownOpen}
        onClick={() => setActiveDropdown(
          isDropdownOpen ? null : MapMarkersGraphicsDropdownSettingsName.unclusterBelowN
        )}
      >
        <div css={[unclusterBelowNDropdownValueStyle, ...(!unclusterBelowN ? [unclusterBelowNOffStyle({ theme })] : [])]}>
          {unclusterBelowN
            ? t('{{N}} Markers', { N: getLimitNumber(unclusterBelowN) })
            : t('Off')
          }
        </div>
      </SettingsAccordionDropdownComponent>
    );

    return {
      isOpen: isDropdownOpen,
      onClose: () => setActiveDropdown(null),
      renderedOptions,
      triggerComponent,
      value: unclusterBelowN,
    };
  }, [activeDropdown, dispatch, isLabelsActive, showUnclusterBelowNWarning, t, theme, unclusterBelowN]);

  const customizeLabelsProps = useMemo(() => ({
    onClick: () => openLabelSettings({
      customizationLevel: CustomizationLevel.All,
      markerSettingsTypes: [MarkerSettingType.Label],
    }),
  }), [openLabelSettings]);

  const customizeAboveLabelsProps = useMemo(() => ({
    onClick: () => openLabelSettings({
      customizationLevel: CustomizationLevel.All,
      markerSettingsTypes: [MarkerSettingType.LabelAbove],
    }),
  }), [openLabelSettings]);

  const toggleMoveMarkers = useMemo(() => ({ onClick: () => {
    if (isMoveMarkersModalVisible) {
      dispatch(moveMarkersCloseModal());
    }
    else {
      dispatch(moveMarkersOpenModal());
    }
  } }), [dispatch, isMoveMarkersModalVisible]);

  const customizeMarkersProps: MapButtonsSettings = useMemo(() => {
    let markerProps: MarkerProps;

    //is a standard marker or no global marker settings selected
    if (!globalMarkerSettings || isStandardMarkerVisualSettings(globalMarkerSettings)) {
      markerProps = getStandardActiveMarkerProps({
        markerSets,
        maxDimensions: MARKER_DIMENSIONS,
        styleId: globalMarkerSettings?.styleId || defaultMarkerStyleId,
        selectedColor: globalMarkerSettings?.selectedColor,
      });
    }
    //is a custom marker
    else {
      const customMarkerProps = getCustomActiveMarkerProps({
        customMarkerSettings: globalMarkerSettings,
        fileUrlsLookup,
        maxDimensions: MARKER_DIMENSIONS,
      });
      if (!customMarkerProps) {
        markerProps = getStandardActiveMarkerProps({
          markerSets,
          maxDimensions: MARKER_DIMENSIONS,
        });
      }
      else {
        markerProps = customMarkerProps;
      }
    }

    return {
      prefix: (
        <div css={markerContainerStyle}>
          <MarkerComponent {...markerProps} />
        </div>),
      onClick: () => openMarkerSettings({
        customizationLevel: CustomizationLevel.All,
        defaultTab: CustomizeMarkerTab.Markers,
        markerSettingsTypes: [MarkerSettingType.Marker],
        overrideGroupMarkers: true,
      }),
    };
  }, [defaultMarkerStyleId, fileUrlsLookup, globalMarkerSettings, markerSets, openMarkerSettings]);

  const mapMarkersGraphicsProps: MapMarkersGraphicsSettingsStructureProps = useMemo(() => ({
    buttons: {
      onActivateMoveMarkers: toggleMoveMarkers,
      onCustomizeActiveMarkerIndicator: customizeActiveMarkerIndicatorProps,
      onCustomizeAll: customizeMarkersProps,
      onCustomizeLabels: customizeLabelsProps,
      onCustomizeLabelsAboveMarkers: customizeAboveLabelsProps,
    },
    toggles: {
      clusterDenseMarkers: createMarkerGraphicsTogglesProps(MapMarkersGraphicsToggleSettingsName.clusterDenseMarkers),
      clusterWPieCharts: createMarkerGraphicsTogglesProps(MapMarkersGraphicsToggleSettingsName.clusterWPieCharts),
      highVolumeMarkerDisplay: createMarkerGraphicsTogglesProps(MapMarkersGraphicsToggleSettingsName.highVolumeMarkerDisplay),
      openLinksInSameWindow: createMarkerGraphicsTogglesProps(MapMarkersGraphicsToggleSettingsName.openLinksInSameWindow),
      stackedMarkersWPieCharts: createMarkerGraphicsTogglesProps(MapMarkersGraphicsToggleSettingsName.stackedMarkersWPieCharts),
      unstackMarkers: createMarkerGraphicsUnstackMarkerProps(),
      useLabelsAboveMarkers: createMarkerGraphicsVisualTogglesProps(MapMarkersGraphicsToggleVisualSettingsName.useLabelsAboveMarkers),
      useNumericLabel: createMarkerGraphicsVisualTogglesProps(MapMarkersGraphicsToggleVisualSettingsName.useNumericLabel),
      useTextLabel: createMarkerGraphicsVisualTogglesProps(MapMarkersGraphicsToggleVisualSettingsName.useTextLabel),
    },
    dropdowns: {
      unclusterBelowN: unclusterBelowNDropdown,
    },
  }), [toggleMoveMarkers, customizeActiveMarkerIndicatorProps, customizeLabelsProps, customizeAboveLabelsProps,
    customizeMarkersProps, createMarkerGraphicsTogglesProps, createMarkerGraphicsVisualTogglesProps,
    unclusterBelowNDropdown, createMarkerGraphicsUnstackMarkerProps,
  ]);

  return {
    mapMarkersGraphicsProps,
  };
};
