import {
  useCallback,
  useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  MapPrivacyLevel, type MapSource,
} from '~/_shared/types/map';
import { mapInfoUpdatePrivacy } from '~/store/mapInfo/mapInfo.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { type MapPrivacySelectInputField } from '../mapPrivacySelectorModal/mapPrivacySelectorInputField.enum';
import {
  type MapPrivacyFieldErrors, useUpdateMapPrivacy,
} from '../mapPrivacySelectorModal/useUpdateMapPrivacy.hook';
import { useMapPrivacyRestrictions } from './useMapPrivacyRestrictions.hook';

export type ManageMapPrivacyState = {
  disabledPrivacyLevels: ReadonlyArray<MapPrivacyLevel>;
  isFormValid: boolean;
  isLoading: boolean;
};

export type ManageMapPrivacyErrors = {
  fieldErrors: MapPrivacyFieldErrors;
  globalError?: string;
  privacyLevel: MapPrivacyLevel;
};

export type ManageMapPrivacyCallbacks = {
  clearGlobalError?: () => void;
  clearFieldError: (field: MapPrivacySelectInputField) => void;
  onPrivacyLevelChange: (privacyLevel: MapPrivacyLevel, password?: string) => void;
  onSave: () => void;
  setIsFormValid: (isValid: boolean) => void;
};

export type MapPrivacyInfo = {
  mapPrivacyLevel: MapPrivacyLevel;
  mapId: number;
  parentMapId?: number;
  parentMapPrivacy?: MapPrivacyLevel;
  mapSource: MapSource;
};

type ManageMapPrivacyProps = {
  mapPrivacyInfo?: MapPrivacyInfo;

  onSuccess: (newPrivacyLevel: MapPrivacyLevel) => void;
};

export const useManageMapPrivacy = (props: ManageMapPrivacyProps) => {
  const { mapPrivacyInfo, onSuccess } = props;

  const clientId = useClientIdSelector();

  const [isFormValid, setIsFormValid] = useState(false);
  const [password, setPassword] = useState('');
  const [privacyLevel, setPrivacyLevel] = useState<MapPrivacyLevel>(
    mapPrivacyInfo?.mapPrivacyLevel || MapPrivacyLevel.Private
  );

  const dispatch = useDispatch();

  const {
    isLoading, fieldErrors, globalError,
    clearGlobalError, clearFieldError,
    updateMapPrivacy, updateSnapshotPrivacy,
  } = useUpdateMapPrivacy();

  const snapshotPrivacyRestrictions = useMapPrivacyRestrictions(
    privacyLevel,
    mapPrivacyInfo?.parentMapPrivacy,
    mapPrivacyInfo?.mapSource,
  );

  const onSave = useCallback(() => {
    if (!clientId || !privacyLevel || !mapPrivacyInfo?.mapId || ((privacyLevel === MapPrivacyLevel.PasswordProtected) && !password)) {
      return;
    }

    if (mapPrivacyInfo.parentMapId === undefined) {
      updateMapPrivacy({
        mapId: mapPrivacyInfo.mapId,
        clientId,
        privacyLevel: privacyLevel || MapPrivacyLevel.Public,
        password: password ?? null,
        password_confirmation: password ?? null,
      })
        .then(({ fieldErrors, globalError }) => {
          if (fieldErrors.size === 0 && !globalError) {
            clearGlobalError();
            dispatch(mapInfoUpdatePrivacy(privacyLevel));
            onSuccess(privacyLevel);
          }
        });
    }
    else {
      updateSnapshotPrivacy({
        mapId: mapPrivacyInfo.parentMapId,
        clientId,
        snapshotId: mapPrivacyInfo.mapId,
        privacyLevel: privacyLevel || MapPrivacyLevel.Public,
        password: password ?? null,
        password_confirmation: password ?? null,
      })
        .then(({ fieldErrors, globalError }) => {
          if (fieldErrors.size === 0 && !globalError) {
            clearGlobalError();
            dispatch(mapInfoUpdatePrivacy(privacyLevel));
            onSuccess(privacyLevel);
          }
        });
    }
  }, [clearGlobalError, clientId, dispatch, mapPrivacyInfo?.mapId, mapPrivacyInfo?.parentMapId,
    onSuccess, password, privacyLevel, updateMapPrivacy, updateSnapshotPrivacy,
  ]);

  const onPrivacyLevelChange = useCallback((privacyLevel: MapPrivacyLevel, password?: string) => {
    setPassword(password || '');
    setPrivacyLevel(privacyLevel);
    clearGlobalError();
  }, [clearGlobalError]);

  return useMemo(() => ({
    clearFieldError,
    clearGlobalError: globalError ? clearGlobalError : undefined,
    disabledPrivacyLevels: snapshotPrivacyRestrictions.disabledPrivacyLevels,
    fieldErrors,
    globalError: globalError || snapshotPrivacyRestrictions.warning,
    isFormValid: isFormValid && fieldErrors.size === 0 && !globalError && snapshotPrivacyRestrictions.isValid && !!mapPrivacyInfo,
    isLoading,
    onPrivacyLevelChange,
    onSave,
    privacyLevel,
    setIsFormValid,
  }), [clearFieldError, clearGlobalError, fieldErrors, globalError, isFormValid, isLoading,
    onPrivacyLevelChange, onSave, privacyLevel, snapshotPrivacyRestrictions, mapPrivacyInfo,
  ]);
};
