import {
  GRAPHIC_SETTINGS_RESET,
  GRAPHIC_SETTINGS_SET_ALLOW_IDLE_ANIMATIONS,
  GRAPHIC_SETTINGS_SET_FORCED_CLUSTERING,
  GRAPHIC_SETTINGS_SET_MARKER_SHADOWS,
  GRAPHIC_SETTINGS_SET_SLIDE_ANIMATION_DURATION,
} from './graphicSettings.actionTypes';

export const setShowMarkerShadows = (newValue: boolean) => ({
  type: GRAPHIC_SETTINGS_SET_MARKER_SHADOWS,
  payload: { newValue },
}) as const;

export const setForcedClustering = (newValue: boolean) => ({
  type: GRAPHIC_SETTINGS_SET_FORCED_CLUSTERING,
  payload: { newValue },
}) as const;

export const setAllowIdleAnimations = (newValue: boolean) => ({
  type: GRAPHIC_SETTINGS_SET_ALLOW_IDLE_ANIMATIONS,
  payload: { newValue },
}) as const;

export const setSlideAnimationDuration = (newValue: number) => ({
  type: GRAPHIC_SETTINGS_SET_SLIDE_ANIMATION_DURATION,
  payload: { newValue },
}) as const;

export const resetGraphicSettings = () => ({
  type: GRAPHIC_SETTINGS_RESET,
}) as const;
