import { computeOffset } from 'spherical-geometry-js';
import { convertUnitToMeters } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import type { PlacesSearch } from '~/store/mapSettings/places/mapSettingsPlaces.state';
import type { PlacesSearchRectangle } from './places.repository';

export const placesSearchToPlacesSearchRectangle = (search: PlacesSearch): PlacesSearchRectangle => {
  const { center, distance } = search;
  const { latLng } = center;
  const distanceInMeters = convertUnitToMeters(distance.value, distance.unit);
  return {
    low: {
      latitude: parseFloat(computeOffset(latLng, distanceInMeters, 180).lat().toFixed(6)),
      longitude: parseFloat(computeOffset(latLng, distanceInMeters, 270).lng().toFixed(6)),
    },
    high: {
      latitude: parseFloat(computeOffset(latLng, distanceInMeters, 0).lat().toFixed(6)),
      longitude: parseFloat(computeOffset(latLng, distanceInMeters, 90).lng().toFixed(6)),
    },
  };
};
