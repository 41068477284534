import { useRef } from 'react';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useDrawingEditSelectedDrawingLatestSnapshot } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

export const useAreDrawingEventsEnabledRef = () => {
  const mapInteractionActive = useIsMapInteractionActive();
  const isMapPresentational = useIsMapPresentationalSelector();
  const latestDrawingSnapshot = useDrawingEditSelectedDrawingLatestSnapshot();
  const isMobileScreen = useIsMobileScreenSelector();

  const eventsEnabledRef = useRef<boolean>(false);
  eventsEnabledRef.current = !isMapPresentational && !isMobileScreen && (!mapInteractionActive || !!latestDrawingSnapshot);

  return eventsEnabledRef;
};
