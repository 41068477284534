import {
  useCallback, useState,
} from 'react';
import {
  ghostOutlineHoverVisuals,
  ghostOutlineVisuals,
  outlineDisabledVisuals,
  outlineHoverVisuals,
  outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { type LatLng } from '../../../../_shared/types/latLng';
import { MarkerColor } from '../../../../_shared/types/marker.types';
import { useBoundaryDragEditPolygonZIndex } from '../../../zIndexes/useBoundaryDrawPolygonZIndex.hook';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import {
  EditMapShapeContainer, type OutlineCallbackProps,
} from '../../mapObjects/mapShape/editMapShape.container';
import {
  MapShapeLine, type MapShapePolylineVisualsConfig,
} from '../../mapObjects/mapShape/mapShapeLine.component';
import { type MapShapeInstance } from '../../mapObjects/mapShape/mapShapeModel';
import {
  MapShapeRemoveButton, type MapShapeRemoveButtonVisualsConfig,
} from '../../mapObjects/mapShape/mapShapeRemoveButton.component';

export const dcRemoveButtonHoverVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Red,
};

export const removeButtonVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Black,
};

export const removeButtonHoverVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Red,
};

const lineVisuals: MapShapePolylineVisualsConfig = {
  color: [88, 125, 215, 1],
  style: 'solid',
  width: 3,
};

type BoundaryDragEditComponentProps = {
  readonly onOutlineMouseOver: () => void;
  readonly onOutlineMouseOut: () => void;
  readonly onRemoveMouseOut: () => void;
  readonly onRemoveMouseOver: () => void;
  readonly mapShape: MapShapeInstance;
  readonly onChange: (mapShape: MapShapeInstance) => void;
  readonly disabled?: boolean;
};
export const BoundaryDragEditComponent: React.FC<BoundaryDragEditComponentProps> = (props) => {
  const { mapShape, onOutlineMouseOver, onOutlineMouseOut } = props;

  const [removeHovered, setRemoveHovered] = useState(false);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<Uuid>();
  const zIndex = useBoundaryDragEditPolygonZIndex(mapShape.id);

  const onRemoveMouseOut = useCallback(() => {
    setRemoveHovered(false);
    props.onRemoveMouseOut();
  }, [props]);

  const onRemoveMouseOver = useCallback(() => {
    setRemoveHovered(true);
    props.onRemoveMouseOver();
  }, [props]);

  const renderRemoveButton = useCallback((onClick: () => void, anchorOutlineId: Uuid) => {
    return (
      <MapShapeRemoveButton
        visuals={removeHovered ? removeButtonHoverVisuals : removeButtonVisuals}
        anchorOutlineId={anchorOutlineId}
        onMouseOut={onRemoveMouseOut}
        onMouseOver={onRemoveMouseOver}
        onClick={onClick}
      />
    );
  }, [onRemoveMouseOut, onRemoveMouseOver, removeHovered]);

  const renderOutline = useCallback((id: Uuid, outline: MapObjectOutlineInstance, isGhost: boolean, callbacks: OutlineCallbackProps) => {
    if (props.disabled) {
      return !isGhost ? (
        <MapOutline
          key={id}
          outline={outline}
          visuals={outlineDisabledVisuals}
        />
      ) : null;
    }

    const visuals = isGhost
      ? (hoveredOutlineId === id ? ghostOutlineHoverVisuals : ghostOutlineVisuals)
      : (hoveredOutlineId === id ? outlineHoverVisuals : outlineVisuals);

    return (
      <MapOutline
        key={id}
        outline={outline}
        visuals={visuals}
        onMouseOver={() => {
          callbacks.onOutlineMouseOver();
          onOutlineMouseOver();
          setHoveredOutlineId(id);
        }}
        onMouseOut={() => {
          onOutlineMouseOut();
          setHoveredOutlineId(undefined);
        }}
        onDragMove={(_, latLng) => callbacks.onDragMove(latLng)}
        onDragEnd={callbacks.onDragEnd}
        onDragStart={callbacks.onDragStart}
      />
    );
  }, [hoveredOutlineId, onOutlineMouseOut, onOutlineMouseOver, props.disabled]);

  const renderLine = useCallback((id: Uuid, start: LatLng, end: LatLng) => {
    return (
      <MapShapeLine
        key={id}
        id={id}
        start={start}
        end={end}
        visuals={lineVisuals}
      />
    );
  }, []);

  return (
    <EditMapShapeContainer
      initialMapShape={mapShape}
      zIndex={zIndex}
      isPolygon
      renderLine={renderLine}
      renderRemoveButton={!props.disabled ? renderRemoveButton : undefined}
      renderOutline={renderOutline}
      onChange={props.onChange}
    />
  );
};
