import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { type EditDataTableRow } from '../../components/editDataTable/editDataTable.component';
import { delay } from '../delay';
import { convertSpreadsheetToTsv } from '../spreadsheet/spreadsheetFormatConvertersHelpers';
import {
  type AddressComponents, type AddressPartComponentNames, addressPartToComponentNames, type GeoLocation,
} from './geolocation';

export const NO_GEOLOCATION_RESULTS = 'Response is empty even though the response status is OK.';
const STARTING_RETRY_DELAY = 200;

export const getUserPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

export const getAddressFromLatLng = (lat: number, lng: number, retryDelay = STARTING_RETRY_DELAY): Promise<GeoLocation> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      location: new google.maps.LatLng(lat, lng),
    },
    (response, status) => {
      if (response) {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            if (notEmpty(response)) {
              resolve({
                address: response[0].formatted_address,
                latLng: {
                  lat: response[0].geometry.location.lat(),
                  lng: response[0].geometry.location.lng(),
                },
                addressComponents: getAddressComponentsFromArray(response[0].address_components),
              });
            }
            else {
              reject(NO_GEOLOCATION_RESULTS);
            }
            break;

          case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
            delay(retryDelay)
              .then(() => resolve(getAddressFromLatLng(lat, lng, retryDelay * 2)));
            break;

          default:
            reject(status);
            break;
        }
      }
      else {
        reject(status);
      }
    });
  });
};

const getAddressComponentsFromArray = (addressComponents: google.maps.GeocoderAddressComponent[]): AddressComponents => {
  let result: AddressComponents = {};

  addressComponents.forEach(component => {
    const types = component.types;

    const componentNames = Object.keys(addressPartToComponentNames);

    componentNames.forEach((name: keyof AddressPartComponentNames) => {
      if (types.includes(addressPartToComponentNames[name])) {
        result = {
          ...result,
          [name]: component.long_name,
        };
      }
    });
  });

  return result;
};

export const getLatLngFromAddress3rdPartyApi = (address: string, retryDelay = STARTING_RETRY_DELAY): Promise<GeoLocation[]> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      address,
    },
    (response, status) => {
      if (response) {

        switch (status) {
          case google.maps.GeocoderStatus.OK:
            if (response.length === 0) {
              return reject(NO_GEOLOCATION_RESULTS);
            }
            return resolve(response.map(item => ({
              address: item.formatted_address,
              latLng: {
                lat: item.geometry.location.lat(),
                lng: item.geometry.location.lng(),
              },
            })));

          case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
            delay(retryDelay)
              .then(() => resolve(getLatLngFromAddress3rdPartyApi(address, retryDelay)));
            return;

          default:
            reject(status);
        }
      }
      else {
        reject(status);
      }
    });
  });
};

export const convertLocationsToTsv = (locations: EditDataTableRow[][]): string => {
  const columnNames = (locations[0] ?? []).map(location => location.label);

  const spreadsheet = [columnNames].concat(locations.map(location => location.map(row => row.value)));

  return convertSpreadsheetToTsv(spreadsheet);
};
