import {
  type BoundaryTerritoryIdentifier, type ExtendedBoundaryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import {
  BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER,
  BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER,
} from './boundaryHighlight.actionTypes';

export const boundaryHighlightHoverBoundary = ({ boundaryGroupId, boundaryTerritoryGroupId, boundaryId }: ExtendedBoundaryIdentifier) => ({
  type: BOUNDARY_HIGHLIGHT_BOUNDARY_HOVER,
  payload: {
    boundaryGroupId,
    boundaryTerritoryGroupId,
    boundaryId,
  },
}) as const;

export const boundaryHighlightStopBoundaryHover = ({ boundaryGroupId, boundaryTerritoryGroupId, boundaryId }: ExtendedBoundaryIdentifier) => ({
  type: BOUNDARY_HIGHLIGHT_BOUNDARY_STOP_HOVER,
  payload: {
    boundaryGroupId,
    boundaryTerritoryGroupId,
    boundaryId,
  },
}) as const;

export const boundaryHighlightHoverBoundaryTerritory = ({ boundaryTerritoryId, boundaryGroupId, boundaryTerritoryGroupId }: BoundaryTerritoryIdentifier) => ({
  type: BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_HOVER,
  payload: {
    boundaryTerritoryId,
    boundaryGroupId,
    boundaryTerritoryGroupId,
  },
}) as const;

export const boundaryHighlightStopHoverBoundaryTerritory = ({ boundaryTerritoryId, boundaryGroupId, boundaryTerritoryGroupId }: BoundaryTerritoryIdentifier) => ({
  type: BOUNDARY_HIGHLIGHT_BOUNDARY_TERRITORY_STOP_HOVER,
  payload: {
    boundaryTerritoryId,
    boundaryGroupId,
    boundaryTerritoryGroupId,
  },
}) as const;
