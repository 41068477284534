import {
  type ComponentProps, forwardRef,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { mapSelectCheckboxStyles } from '~/map/listing/tableData/mapRow/mapRow.styles';
import { checkboxCellStyles } from '~/map/mapMigration/table/table.styles';

type TableCheckboxCellProps = Pick<ComponentProps<typeof CheckboxComponent>, 'checkedSetter' | 'isChecked' | 'isDisabled'>;

export const TableCheckboxCellComponent = forwardRef<HTMLDivElement, TableCheckboxCellProps>(({ isChecked, checkedSetter, isDisabled }, ref) => {
  const theme = useTheme();

  return (
    <div
      ref={ref}
      css={checkboxCellStyles({ theme })}
    >
      <CheckboxComponent
        checkedSetter={checkedSetter}
        isChecked={isChecked}
        isDisabled={isDisabled}
        checkmarkStyle={mapSelectCheckboxStyles({ theme, isChecked })}
      />
    </div>
  );
});

TableCheckboxCellComponent.displayName = 'TableCheckboxCellComponent';
