import {
  type FC, type ReactNode, useEffect, useMemo, useState,
} from 'react';
import { MapPrivacyLevel } from '~/_shared/types/map';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePagination } from '~/_shared/utils/hooks/usePagination';
import {
  convertMapSnapshotsToMapListingItems, type MapListingItemWithPath,
} from '~/map/listing/item/mapListingItem';
import { useMapInfoSnapshotsSelector } from '~/store/mapInfo/mapInfo.selectors';
import { MapListSortType } from '../listing/mapListing.helpers';
import { getRelativeMapPresentationalUrl } from '../map.helpers';
import { MapDropdownComponent } from './mapDropdown.component';

type MapDropdownContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  triggerComponent: ReactNode;
}>;

export const PresentationalMapDropdownContainer: FC<MapDropdownContainerProps> = (props) => {
  const snapshots = useMapInfoSnapshotsSelector();
  const [t] = useTranslation();
  const [searchQuery, setQuery] = useState('');
  const { pagination, setPage, setPagination } = usePagination();

  const snapshotsAsMapListingItems: ReadonlyArray<MapListingItemWithPath> | undefined = useMemo(() =>
    snapshots
      ? convertMapSnapshotsToMapListingItems(snapshots)
        .filter(s => s.privacy === MapPrivacyLevel.Public)
        .map(s => ({ ...s, path: getRelativeMapPresentationalUrl(s.shareId) }))
        .sort((s1, s2) => s1.dateCreated > s2.dateCreated ? 1 : -1)
      : undefined,
  [snapshots]);

  const paginatedListingItems = useMemo(() => (
    snapshotsAsMapListingItems?.slice(
      pagination.perPage * (pagination.currentPage - 1), pagination.perPage * pagination.currentPage
    )
  ), [pagination.currentPage, pagination.perPage, snapshotsAsMapListingItems]);

  useEffect(() => {
    if (searchQuery) {
      setPage(1);
    }
  }, [setPage, searchQuery]);

  useEffect(() => {
    if (snapshotsAsMapListingItems) {
      setPagination({
        ...pagination,
        lastPage: Math.ceil((snapshotsAsMapListingItems?.length ?? 0) / pagination.perPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotsAsMapListingItems]);

  return paginatedListingItems?.length ? (
    <MapDropdownComponent
      isLoading={false}
      isOpen={props.isOpen}
      mapListingItems={paginatedListingItems}
      onClose={props.onClose}
      onPageChange={setPage}
      onSearchQueryChange={paginatedListingItems.length !== snapshotsAsMapListingItems?.length ? setQuery : undefined}
      pagination={pagination.lastPage > 1 ? pagination : null}
      searchQuery={searchQuery}
      sortType={MapListSortType.Created_DESC}
      title={t('Saved Map Views')}
      toggledMapIds={[]}
      triggerComponent={props.triggerComponent}
    />
  ) : null;
};
