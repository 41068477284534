import {
  type FC,
  memo,
  useEffect,
  useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { type MapObjectArrowConfig } from '../mapObject.types';
import { useMapObjectContext } from '../private/mapObjectContext';
import { type MapArrowInstance } from './mapArrowModel';

type MapArrowProps = {
  arrow: MapArrowInstance;
  visuals: MapObjectArrowConfig;
  onClick?: (event: MapObjectClickEventArgs) => void;
  onRightClick?: (event: MapObjectClickEventArgs) => void;
  onDragEnd?: () => void;
  onDragMove?: (latLng: LatLng) => void;
  onDragStart?: (latLng: LatLng) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
};

const MapArrowComponent: FC<MapArrowProps> = (props) => {
  const { manager } = useMapObjectContext();
  const { arrow, visuals } = props;
  const { onDragMove, onDragStart, onDragEnd, onMouseOver, onMouseOut, onClick, onRightClick } = props;

  const [mapArrow, setMapArrow] = useState<WebglOverlayArrow | null>(null);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: mapArrow,
    onDragEnd,
    onDragStart,
    onDragMove,
    onMouseOver,
    onMouseOut,
  }), [manager.map, mapArrow, onDragEnd, onDragStart, onDragMove, onMouseOver, onMouseOut]);

  // draw arrow
  useEffect(() => {
    if (arrow.tipLatLng.lat === arrow.tailLatLng.lat && arrow.tipLatLng.lng === arrow.tailLatLng.lng) {
      return;
    }

    const updatedArrow = manager.upsertArrow(arrow, visuals);

    if (mapArrow !== updatedArrow) {
      setMapArrow(updatedArrow);
    }
  }, [manager, arrow, visuals, mapArrow]);

  // web-gl cleanup
  useEffect(() => {
    return () => {
      manager.removeArrow(arrow.id);
    };
  }, [manager, arrow.id]);

  // registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addArrowEventListener(arrow.id, 'click',
      (e) => {
        onClick?.(e);
      });

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, arrow.id]);

  // registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseClickCleanup = manager.addArrowEventListener(arrow.id, 'rightclick',
      (e) => {
        onRightClick?.(e);
      });

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onRightClick, arrow.id]);

  return null;
};

const pureComponent = memo(MapArrowComponent);
export { pureComponent as MapArrowComponent };
