import { BoundaryTerritoryType } from '../../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';

export const getLabelsCountForBoundaryTerritoryType = (boundaryTerritoryType: BoundaryTerritoryType | null): number => {
  switch (boundaryTerritoryType) {
    case BoundaryTerritoryType.Demographic:
    case BoundaryTerritoryType.Numeric:
      return 3;
    case BoundaryTerritoryType.Groups:
      return 2;
    default:
      return 1;
  }
};
