import {
  forwardRef, type ReactNode, useEffect, useRef,
} from 'react';
import { createPortal } from 'react-dom';

const createNewPortalNode = () => {
  return document.createElement('div');
};

type FixedStickyContainerPortalComponentProps = {
  children: ReactNode;
};

export const FixedStickyContainerPortalComponent = forwardRef<HTMLElement, FixedStickyContainerPortalComponentProps>((props, ref) => {
  const portalElementRef = useRef<HTMLElement>(createNewPortalNode());

  useEffect(() => {
    const modalRoot = document.getElementById('fixed-portal');

    if (!modalRoot) {
      return;
    }

    const portalElement = portalElementRef.current;

    modalRoot.appendChild(portalElement);

    return () => {
      modalRoot.removeChild(portalElement);
    };
  }, []);

  useEffect(() => {
    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(portalElementRef.current);
    }
    else {
      ref.current = portalElementRef.current;
    }
  }, [ref]);

  return createPortal(props.children, portalElementRef.current);
});
