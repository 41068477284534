import { combineReducers } from 'redux';
import { mapSettingsConcurrencyReducer } from './concurrency/mapSettingsConcurrency.reducer';
import { mapSettingsDataReducer } from './data/mapSettingsData.reducer';
import { mapSettingsLoadingReducer } from './loading/mapSettingsLoading.reducer';
import { mapSettingsReadyReducer } from './ready/mapSettingsReady.reducer';

export const mapSettingsReducer = combineReducers({
  data: mapSettingsDataReducer,
  concurrency: mapSettingsConcurrencyReducer,
  isLoading: mapSettingsLoadingReducer,
  isReady: mapSettingsReadyReducer,
});
