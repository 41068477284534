import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAjaxCall } from '../../../../../_shared/utils/hooks/useAjaxCall';
import { boundaryGroupsChangeNameSuccess } from '../../../../../store/boundaryGroups/boundaryGroups.actionCreators';
import { editBoundaryGroup } from '../../../../../store/boundaryGroups/boundaryGroups.repository';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';

export const useBoundaryGroupRename = () => {
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();

  const { invokeAjax: renameBoundaryGroup, isLoading } = useAjaxCall((clientId: number, boundaryGroupId: number, newBoundaryGroupName: string) =>
    editBoundaryGroup(clientId, boundaryGroupId, newBoundaryGroupName)
  );

  const renameBoundaryGroupRequest = useCallback((
    boundaryGroupId: number, newBoundaryGroupName: string, onSuccess?: () => void
  ) => {
    if (clientId === null) {
      return;
    }

    renameBoundaryGroup(clientId, boundaryGroupId, newBoundaryGroupName)
      .then(() => {
        dispatch(boundaryGroupsChangeNameSuccess(boundaryGroupId, newBoundaryGroupName));
        onSuccess?.();
      });
  }, [dispatch, clientId, renameBoundaryGroup]);

  return {
    isLoading,
    renameBoundaryGroup: renameBoundaryGroupRequest,
  };
};
