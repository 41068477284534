import { useCallback } from 'react';
import { ModalType } from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';

export const useBoundaryExportData = () => {
  const { openModal: openExportBoundaryDataModal } = useModal(ModalType.ExportBoundaryData);
  const { openModal: openExportTerritoryDataModal } = useModal(ModalType.ExportTerritoryData);

  const exportBoundaryData = useCallback((boundaryTerritoryGroupId: number, boundaryId: number) => {
    openExportBoundaryDataModal({
      boundaryTerritoryGroupId,
      boundaryId,
    });
  }, [openExportBoundaryDataModal]);

  const exportBoundaryTerritoryData = useCallback((boundaryTerritoryGroupId: number, boundaryTerritoryId: string) => {
    openExportTerritoryDataModal({
      boundaryTerritoryGroupId,
      boundaryTerritoryId,
    });
  }, [openExportTerritoryDataModal]);

  return {
    exportBoundaryData,
    exportBoundaryTerritoryData,
  };
};
