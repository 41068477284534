export const BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL = 'BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL';
export const BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL = 'BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL';
export const BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL = 'BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL';
export const BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL = 'BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL';
export const BOUNDARY_SELECT_EDIT_ACTIVATE = 'BOUNDARY_SELECT_EDIT_ACTIVATE';
export const BOUNDARY_SELECT_EDIT_RESET = 'BOUNDARY_SELECT_EDIT_RESET';
export const BOUNDARY_SELECT_EDIT_CLOSE_MODAL = 'BOUNDARY_SELECT_EDIT_CLOSE_MODAL';
export const BOUNDARY_SELECT_EDIT_DEACTIVATE = 'BOUNDARY_SELECT_EDIT_DEACTIVATE';

export const BOUNDARY_SELECT_ADD_BOUNDARY = 'BOUNDARY_SELECT_ADD_BOUNDARY';
export const BOUNDARY_SELECT_REMOVE_BOUNDARY = 'BOUNDARY_SELECT_REMOVE_BOUNDARY';
export const BOUNDARY_SELECT_SET_SELECT_TYPE = 'BOUNDARY_SELECT_SET_SELECT_TYPE';
export const BOUNDARY_SELECT_SET_LASSO_OVERLAP = 'BOUNDARY_SELECT_SET_LASSO_OVERLAP';
export const BOUNDARY_SELECT_SET_LASSO_IS_LOADING = 'BOUNDARY_SELECT_SET_LASSO_IS_LOADING';

export const BOUNDARY_SELECT_HOVER_BOUNDARY = 'BOUNDARY_SELECT_HOVER_BOUNDARY';
export const BOUNDARY_SELECT_STOP_BOUNDARY_HOVER = 'BOUNDARY_SELECT_STOP_BOUNDARY_HOVER';
