import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const dividerStyles = ({ theme, marginLeft = 0, marginRight = 0 }: ThemeProps<{marginRight?: number; marginLeft?: number}>) => css({
  width: `calc(100% - ${marginRight}px - ${marginLeft}px)`,
  border: 'none', height: '1px',
  backgroundColor: theme.borderColors.primary,
  marginTop: 0,
  marginBottom: 0,
  marginLeft,
  marginRight,
});

export type DividerComponentProps = {
  marginRight?: number;
  marginLeft?: number;
  className?: string;
};

// use negative margin if you want to break out from parent container (and it's padding)
export const DividerComponent: FC<DividerComponentProps> = ({ marginRight, marginLeft, className }) => {
  const theme = useTheme();

  const style = dividerStyles({ theme, marginLeft, marginRight });

  return (
    <hr
      className={className}
      css={style}
    />
  );
};
