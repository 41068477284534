import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import type Scrollbar from 'react-scrollbars-custom';
import { type LoaderType } from '~/_shared/baseComponents/loaders';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const wrapperStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const contentWrapperStyle = css({
  position: 'relative',
  flex: 1,
});

const footerStyle = ({ theme }: ThemeProps) => css({
  padding: '10px 10px',
  background: theme.backgroundColors.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const headerStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

type MapToolWrapperProps = Readonly<{
  className?: string;
  footer?: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  loaderType?: LoaderType;
  scrollbarContentStyle?: SerializedStyles;
  contentWrapperStyle?: SerializedStyles;
  children: ReactNode;
  getScrollbarReference?: (ref: Scrollbar) => void;
}>;

export const MapToolWrapperComponent: FC<MapToolWrapperProps> = (props) => {
  const theme = useTheme();

  return (
    <div css={wrapperStyle} className={props.className}>
      {props.header && (
        <div css={headerStyle({ theme })}>
          {props.header}
        </div>
      )}
      <div css={[contentWrapperStyle, props.contentWrapperStyle]}>
        <ScrollBarComponent
          css={props.scrollbarContentStyle}
          type={ScrollbarType.Vertical}
          getScrollbarReference={props.getScrollbarReference}
        >
          {props.children}
        </ScrollBarComponent>
      </div>

      {props.footer && (
        <div css={css(footerStyle({ theme }))}>
          {props.footer}
        </div>
      )}

      {props.isLoading && (
        <OverlayLoaderComponent
          loaderType={props.loaderType}
          loaderSize={60}
        />
      )}
    </div>
  );
};
