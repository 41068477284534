import { css } from '@emotion/react';

export const COLOR_TILE_DIMENSION = 22;
export const MAP_PROXIMITY_RADIUS = 80;

export const modalContentStyle = css({
  padding: 0,
});

export const wrapperStyle = css({
  padding: 20,
});

export const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  margin: '0 0 10px',
  textTransform: 'uppercase',
});

export const columnsWrapper = css({
  display: 'flex',
  gap: 20,
});

export const mobileWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const columnStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '50%',
});

export const travelTimeInputsWrapperStyle = css({
  display: 'flex',
});

export const travelHoursColumnStyle = css({
  marginRight: 5,
  width: '50%',
});

export const travelMinutesColumnStyle = css({
  marginLeft: 5,
  width: '50%',
});

export const mapPlaceholderWrapperStyle = css({
  position: 'relative',
  width: '100%',
});

export const mapPromixityPreviewStyle = ({ bgColor, borderColor, borderWidth }: {
  bgColor: string;
  borderColor: string;
  borderWidth: string;
}) => css({
  position: 'absolute',
  width: `${MAP_PROXIMITY_RADIUS * 2}px`,
  height: `${MAP_PROXIMITY_RADIUS * 2}px`,
  top: `calc(50% - ${MAP_PROXIMITY_RADIUS}px)`,
  left: `calc(50% - ${MAP_PROXIMITY_RADIUS}px)`,
  backgroundColor: bgColor,
  borderRadius: '50%',
  outline: `${borderWidth} solid ${borderColor}`,
  boxSizing: 'border-box',
});

export const borderStrokeInput = css({
  width: 80,
});
