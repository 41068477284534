import { css } from '@emotion/react';
import { faRobot } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ACCORDION_HEADER_SIZE_LARGE } from '~/_shared/baseComponents/accordion';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { UnderlinedLinkComponent } from '~/_shared/baseComponents/links';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsWMSEnabledSelector } from '~/store/userData/userData.selectors';
import {
  s, Trans,
} from '~/translations/Trans';
import { type ActiveBoundaryProps } from './activeBoundary.component';

const primaryHeaderStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.backgroundColors.highlight,
  border: `solid ${theme.borderColors.activeItem}`,
  borderWidth: '1px 0',
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  display: 'flex',
  flexDirection: 'column',
  height: ACCORDION_HEADER_SIZE_LARGE,
  justifyContent: 'center',
  padding: '0px 16px',
  width: '100%',
});

const groupTypeStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  display: 'flex',
  gap: 4,
});

const subTitleStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
});

const trialMessageStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
});

export const ActiveBoundaryHeaderComponent: FC<ActiveBoundaryProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { openModal } = useModal(ModalType.WMSUpgradeSubscription);
  const isWMSEnabled = useIsWMSEnabledSelector();

  return (
    <div css={primaryHeaderStyle({ theme })}>
      {props.name}
      <div css={subTitleStyle}>
        <div css={groupTypeStyle({ theme })}>
          {props.isAiGenerated && (
            <FontAwesomeIcon
              icon={faRobot}
            />
          )} {props.isBoundaryGroupCustom ? t('territory group') : t('boundary')}
        </div>
        {!isWMSEnabled && props.isAiGenerated && (
          <div css={trialMessageStyle({ theme })}>
            <Trans i18nKey="TRIAL (<1>upgrade here</1>)">
              {s('TRIAL (')}
              <UnderlinedLinkComponent
                onClick={() => openModal()}
              >
                upgrade here
              </UnderlinedLinkComponent>
              {s(')')}
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
};
