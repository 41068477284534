import {
  FRONTEND_STATE_ADD_LOCATION_LOADING,
  FRONTEND_STATE_ADD_LOCATION_START,
  FRONTEND_STATE_ADD_LOCATION_STOP,
} from './addLocation.actionTypes';

export const startAddLocationTool = () => ({
  type: FRONTEND_STATE_ADD_LOCATION_START,
}) as const;

export const stopAddLocationTool = () => ({
  type: FRONTEND_STATE_ADD_LOCATION_STOP,
}) as const;

export const rightPanelSetIsAddMarkerLoading = (isLoading: boolean) => ({
  type: FRONTEND_STATE_ADD_LOCATION_LOADING,
  payload: {
    isLoading,
  },
}) as const;
