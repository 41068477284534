import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import type { TranslationFnc } from '~/_shared/utils/hooks';
import type { RouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import { getDurationLabel } from './listing/directionsListing.helpers';

export const getWaypointId = (routeId: Uuid, pointId: Uuid) => {
  return `${routeId}_${pointId}`;
};

export const parseWaypointId = (waypointId: string) => {
  const [firstPart, secondPart] = waypointId.split('_');
  if (secondPart) {
    return {
      routeId: firstPart,
      pointId: secondPart,
    };
  }

  return {
    routeId: null,
    pointId: waypointId,
  };
};

export const getRouteUiDataForWaypoint = (
  waypointIndex: number,
  routeId: string,
  routesUiData: ReadonlyMap<string, RouteUiData>,
  t: TranslationFnc,
) => {
  const routeUiData = routesUiData.get(routeId);
  const isLastRouteLeg = routeUiData ? waypointIndex === routeUiData.legs.length : false;

  const getLatLngFromGoogleLatLngIfDefined = (googleLatLng: google.maps.LatLng | undefined) =>
    googleLatLng ? googleLatLngToLocal(googleLatLng) : undefined;

  return routeUiData ? (
    isLastRouteLeg ? {
      address: routeUiData.legs[routeUiData.legs.length - 1]?.end_address,
      distanceToNext: '-',
      travelTimeToNext: '-',
      latLng: getLatLngFromGoogleLatLngIfDefined(routeUiData.legs[routeUiData.legs.length - 1]?.end_location),
    } : {
      address: routeUiData.legs[waypointIndex]?.start_address,
      distanceToNext: routeUiData.legs[waypointIndex]?.distance?.text ?? '',
      travelTimeToNext: getDurationLabel(routeUiData.legs[waypointIndex]?.duration?.value || 0, t),
      latLng: getLatLngFromGoogleLatLngIfDefined(routeUiData.legs[waypointIndex]?.start_location),
    }
  ) : null;
};
