import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { FormattedValue } from '../../../baseComponents/formattedValue/formattedValue.component';
import { OneDimensionalTableHeader } from '../../../baseComponents/oneDimensionalTable/oneDimensionalTableHeader';
import { OneDimensionalTableWithHeader } from '../../../baseComponents/oneDimensionalTable/oneDimensionalTableWithHeader';

const listItemStyle = css({
  padding: '14px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const emptyRowInfoStyle = (theme: Theme) => ({
  color: theme.textColors.secondary,
  fontSize: 12,
  margin: 0,
});

const valueStyle = (theme: Theme) => css({
  color: theme.textColors.tertiary,
  fontSize: '14px',
  margin: 0,
  paddingLeft: 8,
});

type MetricRow = {
  enforcedNumberOfFractionDigits?: number;
  demographicId?: string;
  name: string;
  prefix?: string;
  suffix?: string;
  value: number;
};

export type GroupedMetric = {
  demographicId?: string;
  name: string;
  subheader?: string;
  subheader2?: string;
  single?: MetricRow;
  groups?: MetricRow[];
};

type MetricsListItemComponentProps = GroupedMetric & {
  showApproximateSymbol?: boolean;
  blurMetrics?: boolean;
  excludedRowCount?: number;
  rowCount?: number;
};

export const MetricsListItemComponent: FC<MetricsListItemComponentProps> = (props) => {
  const [t] = useTranslation();
  const groupsWithOptions = useMemo(() => (
    props.groups?.map(group => ({
      name: group.name,
      value: {
        ...group,
        showApproximateSymbol: props.showApproximateSymbol,
        blur: props.blurMetrics,
      },
    }))
  ), [props.blurMetrics, props.groups, props.showApproximateSymbol]);

  return (
    <div css={listItemStyle}>
      {props.single && (
        <>
          <OneDimensionalTableHeader value={props.name} />
          <p css={valueStyle}>
            <FormattedValue
              {...props.single}
              blur={props.blurMetrics}
              showApproximateSymbol={props.showApproximateSymbol}
            />
          </p>
        </>
      )}

      {props.groups && props.groups.length >= 1 && (
        <OneDimensionalTableWithHeader
          heading={props.name}
          subheader={props.subheader}
          subheader2={props.subheader2}
          body={{
            rows: groupsWithOptions || [],
          }}
        />
      )}
      {((props.excludedRowCount ?? 0) > 0) && ((props.rowCount ?? 0) > 0) && (
        <p css={emptyRowInfoStyle}>
          {t('emptyRowsNotCounted', { emptyRowCount: props.excludedRowCount, rowCount: props.rowCount })}
        </p>
      )}
    </div>
  );
};
