import { useEffect } from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';

export type DrawingToolScalingItem = {
  readonly itemSize: number;
};

export type DrawingToolScalingItemProps = DrawingToolScalingItem & {
  readonly scalesWithMapZoom: boolean;
  readonly max: number;
  readonly min: number;
};

export const useDrawingToolItemScalingToggle = (
  props: DrawingToolScalingItemProps,
  onChange: (props: DrawingToolScalingItem) => void,
  etag?: string,
) => {
  const prevScalesWithMapZoom = usePrevious(props.scalesWithMapZoom);
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const prevEtag = usePrevious(etag);

  useEffect(() => {
    if (prevScalesWithMapZoom === undefined) {
      return;
    }

    if (props.scalesWithMapZoom === prevScalesWithMapZoom) {
      return;
    }

    if (prevEtag !== etag) {
      // item was reset to another version
      // do not recalculate scaling, it was not toggled
      return;
    }

    const convertedItemSize = props.scalesWithMapZoom
      ? props.itemSize * sizePerPixelRatio
      : props.itemSize / sizePerPixelRatio;

    const ratio = props.scalesWithMapZoom ? sizePerPixelRatio : 1;

    const updatedSize = {
      itemSize: clamp(convertedItemSize, { min: props.min * ratio, max: props.max * ratio }),
    };

    onChange(updatedSize);
  }, [prevScalesWithMapZoom, onChange, props.scalesWithMapZoom,
    props.itemSize, sizePerPixelRatio, props.min, props.max, etag, prevEtag]);
};
