import { type MapSettingsHeatmapsResponse } from '~/map/map.repository';
import { mapSettingsHeatmapsInitialState } from './mapSettingsHeatmaps.reducer';
import { type MapSettingsHeatmapsState } from './mapSettingsHeatmaps.state';

export const mapSettingsHeatmapSerializer = {
  jsonToState: (json: MapSettingsHeatmapsResponse): MapSettingsHeatmapsState => {
    const { heatmaps: jsonHeatmaps, ...rest } = json;

    const state: MapSettingsHeatmapsState = {
      ...mapSettingsHeatmapsInitialState,
      ...rest,
    };

    if (jsonHeatmaps) {
      state.heatmaps = jsonHeatmaps.map(heatMap => {
        const { selectedGroup, ...rest } = heatMap;

        return selectedGroup ? {
          ...rest,
          // backward compatibility with old model
          // TODO: remove after beta
          selectedGroupId: selectedGroup,
          selectedGroupName: selectedGroup,
        } : heatMap;
      });
    }

    return state;
  },
  stateToJson: (state: MapSettingsHeatmapsState): MapSettingsHeatmapsResponse => {
    return state;
  },
};
