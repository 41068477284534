import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import {
  DropDownItemSize, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { useWindowWidth } from '~/_shared/utils/hooks/useWindowWidth';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { type MainMenuItemProps } from '~/mainMenu/menuItem/mainMenuItem.component';
import {
  closeIconContainerStyle,
  closeIconStyle,
  containerStyle,
  dropdownContainerStyle,
  HORIZONTAL_PADDING,
  menuItemContainerStyle,
  menuItemFontSize,
  MIN_ICON_DELIMETER_WIDTH,
  MIN_ICON_WIDTH,
  toolContainerStyle,
} from '~/mobileTopBar/mobileTopUnderbar/mobileTopUnderbar.styles';
import {
  getMainMenuItemIcon, getMainMenuItemTitle,
} from '../../mainMenu/mainMenu.helpers';
import {
  mobileMapToolsAsMenuItems, mobileRightSideButtonsMenuItems,
} from '../mobileTopBar.component';
import { MobileTopUnderbarButton } from '../mobileTopUnderbarButton';
import { getMaxNumberOfPossibleIcons } from './mobileTopUnderbar.helpers';

type MobileTopUnderbarComponentProps = Readonly<{
  availableMobileItems: ReadonlySet<MainMenuItem>;
  selectedItems: ReadonlySet<MainMenuItem>;
  isMapToolsSelected: boolean;

  onItemClick: (newApp: MainMenuItem | undefined) => void;
  handleMapToolsClose: () => void;
}>;

export const MobileTopUnderbarComponent: FC<MobileTopUnderbarComponentProps> = (props) => {
  const theme = useTheme();
  const [t] = useTranslation();
  const windowWidth = useWindowWidth();
  const { onItemClick, selectedItems } = props;

  const numberOfPossibleIcons = useMemo(() => {
    const availableRightSideButtonsMenuItems = [...mobileRightSideButtonsMenuItems].filter(item => !props.availableMobileItems.has(item));
    const numberOfPresentRightButtonsAsItems = mobileRightSideButtonsMenuItems.size - availableRightSideButtonsMenuItems.length;

    return getMaxNumberOfPossibleIcons({
      delimiterWidth: MIN_ICON_DELIMETER_WIDTH,
      elementWidth: MIN_ICON_WIDTH,
      horizontalPadding: HORIZONTAL_PADDING * 2,
      minimumNumberOfIcons: numberOfPresentRightButtonsAsItems + 1, // The number of non tools plus one icon for the tools menu
      totalHorizontalSpace: windowWidth,
    });
  }, [props.availableMobileItems, windowWidth]);

  const getHandleItemClick = (clickedItem?: MainMenuItem) =>
    () => onItemClick(clickedItem);

  const visibleItems: ReadonlyArray<MainMenuItem> = useMemo(() => {
    const allItems = [...props.availableMobileItems];

    if (numberOfPossibleIcons < props.availableMobileItems.size) {
      const numberOfInvisibleItems = props.availableMobileItems.size - numberOfPossibleIcons + 1; //The last visible icon gets replaced by the tools menu

      return [...allItems.slice(0, -numberOfInvisibleItems), MainMenuItems.MapTools];
    }

    return allItems;
  }, [props.availableMobileItems, numberOfPossibleIcons]);

  let isANonVisibleItemSelected: boolean = false;

  const visibleItemSet = new Set(visibleItems);

  for (const item of mobileMapToolsAsMenuItems) {
    if (props.availableMobileItems.has(item) && !visibleItemSet.has(item) && selectedItems.has(item)) {
      isANonVisibleItemSelected = true;
      break;
    }
  }

  const mobileMapToolOptions = useMemo(() =>
    [...mobileMapToolsAsMenuItems]
      .map((menuItem: MainMenuItem) => ({
        name: getMainMenuItemTitle(menuItem, t, { useShortVariant: true }),
        value: menuItem,
        icon: getMainMenuItemIcon(menuItem),
      })), [t]);

  const selectedDropdownItem = useMemo(() =>
    [...selectedItems].find(item => mobileMapToolsAsMenuItems.has(item)),
  [selectedItems]);

  const getMenuItemProps = (item: MainMenuItem): MainMenuItemProps => ({
    fontSize: menuItemFontSize[item],
    icon: getMainMenuItemIcon(item),
    onClick: getHandleItemClick(item),
    title: getMainMenuItemTitle(item, t, { useShortVariant: true }),
    isDisabled: false,
    isHighlighted: selectedItems.has(item) || (item === MainMenuItems.MapTools) && isANonVisibleItemSelected,
  });

  return (
    <div css={containerStyle({ theme })}>
      {!props.isMapToolsSelected ? (
        <div css={menuItemContainerStyle({ isMenuSmall: visibleItems.length === 1 })}>
          {visibleItems.map(item => (
            <MobileTopUnderbarButton
              {...getMenuItemProps(item)}
              key={getMainMenuItemTitle(item, t, { useShortVariant: true })}
            />
          ))}
        </div>
      ) : (
        <div css={toolContainerStyle}>
          <RegularDropdownComponent
            css={dropdownContainerStyle}
            inputSize={InputSize.Medium}
            itemSize={DropDownItemSize.Large}
            onChange={onItemClick}
            value={selectedDropdownItem}
            options={mobileMapToolOptions}
            placeholder={t('Select a Tool')}
          />
          <div css={closeIconContainerStyle}>
            <FontAwesomeIcon
              icon={faXmark}
              css={closeIconStyle}
              onClick={props.handleMapToolsClose}
            />
          </div>
        </div>
      )}

    </div>
  );
};
