import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';
import { homePageZIndexes } from '~/homepage/homepage.constants';

type StickyProps = Readonly<{
  className?: string;
}>;

const stickyHeaderStyle = css({
  position: 'sticky',
  top: 0,
  zIndex: homePageZIndexes.stickyHeader,
});

export const StickyComponent = (props: PropsWithChildren<StickyProps>) => {
  return (
    <div
      className={props.className}
      css={stickyHeaderStyle}
    >
      {props.children}
    </div>
  );
};
