import {
  css, type Interpolation,
} from '@emotion/react';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import {
  type ReactNode, useCallback, useState,
} from 'react';
import {
  type FileError, type FileRejection, useDropzone,
} from 'react-dropzone';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  s, Trans,
} from '~/translations/Trans';
import { ErrorTypes } from '../../constants/error.constants';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { isFileAccepted } from './dropzone.helpers';

const containerStyle = ({ theme, isDragOver }: ThemeProps<{ isDragOver: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  border: `1px dashed ${isDragOver ? theme.borderColors.primaryFocused : theme.borderColors.activeItem}`,
  height: 96,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  outline: 'none',
  transition: 'border .2s',
  '&:hover': {
    borderColor: theme.borderColors.primaryFocused,
  },
});

const labelPrimaryText = ({ theme }: ThemeProps) => css({
  color: theme.textColors.link,
});

const labelWrapperStyles = (theme: Theme) => css({
  fontSize: '20px',
  textAlign: 'center',
  width: '100%',
  color: theme.textColors.tertiary,
  padding: '0 10px',
  userSelect: 'none',
});

const labelIconStyle = css({
  marginRight: 10,
  fontSize: '16px',
});

export type DropzoneProps = Readonly<{
  acceptFileExtensions?: ReadonlyArray<string>;
  className?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  label?: ReactNode;
  labelWrapperStyle?: Interpolation;
  maxSize?: number;
  noDefaultContainerStyle?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;

  onFilesReject?: (rejections: FileRejection[]) => void;
  onFilesSelect: (files: File[]) => void;
}>;

export const DropzoneComponent = ({
  acceptFileExtensions,
  className,
  isDisabled = false,
  isMulti = true,
  label,
  labelWrapperStyle,
  maxSize,
  noDefaultContainerStyle,
  onFilesReject,
  onFilesSelect,
  primaryLabel,
  secondaryLabel,
  ...restProps
}: DropzoneProps) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();

  const onDragOver = useCallback(() => {
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const onDrop = useCallback((newFiles: File[]) => {
    const validNewFiles = newFiles.filter(file => isFileAccepted(file.name, acceptFileExtensions));

    onFilesSelect(validNewFiles);
    setIsDragOver(false);

    if (newFiles.length !== validNewFiles.length && onFilesReject) {
      const rejections: FileRejection[] = newFiles
        .filter(file => !isFileAccepted(file.name, acceptFileExtensions))
        .map(file => {
          const error: FileError = { message: ErrorTypes.Extension, code: ErrorTypes.Extension };
          return {
            file: { ...file },
            errors: [error],
          };
        });

      onFilesReject(rejections);
    }
  }, [onFilesSelect, acceptFileExtensions, onFilesReject]);

  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: isDisabled,
    onDrop,
    maxSize,
    multiple: isMulti,
    noClick: true,
    onDragOver,
    onDragLeave,
    onDropRejected: onFilesReject,
    useFsAccessApi: false,
  });

  return (
    <div
      {...restProps}
      {...getRootProps({
        onClick: () => {
          open();
        },
      })}
      css={noDefaultContainerStyle ? undefined : containerStyle({ theme, isDragOver })}
      className={className}
    >
      <input {...getInputProps()} />
      <div css={[labelWrapperStyles, labelWrapperStyle]}>
        {label}
        {!label && (
          <>
            <FontAwesomeIcon
              css={labelIconStyle}
              icon={faPaperclip}
            />
            {primaryLabel || secondaryLabel ? (
              <>
                <span css={labelPrimaryText({ theme })}>{primaryLabel || t('Add File')}</span>
                {secondaryLabel !== undefined ? secondaryLabel : t(' or drop files here.')}
              </>
            ) : (
              <Trans i18nKey="<0>Add File</0> or drop files here.">
                <span css={labelPrimaryText({ theme })}>{t('Add File')}</span>
                {s(' or drop files here.')}
              </Trans>
            )}
          </>
        )}
      </div>
    </div>
  );
};
