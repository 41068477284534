import { type TerritoryGroup } from '../../_shared/types/territories';
import {
  POLYGON_GROUP_ACTIVATE_ERROR,
  POLYGON_GROUP_ACTIVATE_REQUEST,
  POLYGON_GROUP_ACTIVATE_SUCCESS,
  POLYGON_GROUP_CREATE_ERROR,
  POLYGON_GROUP_CREATE_REQUEST,
  POLYGON_GROUP_CREATE_SUCCESS,
  POLYGON_GROUP_DEACTIVATE_ERROR,
  POLYGON_GROUP_DEACTIVATE_REQUEST,
  POLYGON_GROUP_DEACTIVATE_SUCCESS,
  POLYGON_GROUP_DELETE_ERROR,
  POLYGON_GROUP_DELETE_REQUEST,
  POLYGON_GROUP_DELETE_SUCCESS,
  POLYGON_GROUP_FETCH_ACTIVE_ERROR,
  POLYGON_GROUP_FETCH_ACTIVE_REQUEST,
  POLYGON_GROUP_FETCH_ACTIVE_SUCCESS,
  POLYGON_GROUP_FETCH_ALL_ERROR,
  POLYGON_GROUP_FETCH_ALL_REQUEST,
  POLYGON_GROUP_FETCH_ALL_SUCCESS,
} from './polygonGroups.actionTypes';

export const polygonGroupsRequest = (userId?: number) => ({
  type: POLYGON_GROUP_FETCH_ALL_REQUEST,
  payload: {
    userId,
  },
}) as const;

export const polygonGroupsSuccess = (polygonGroups: ReadonlyMap<number, TerritoryGroup>) => ({
  type: POLYGON_GROUP_FETCH_ALL_SUCCESS,
  payload: {
    polygonGroups,
  },
}) as const;

export const polygonGroupsError = (error: Error) => ({
  type: POLYGON_GROUP_FETCH_ALL_ERROR,
  payload: {
    error,
  },
}) as const;

export const polygonGroupCreateRequest = (polygonGroupName: string, userId: number) => ({
  type: POLYGON_GROUP_CREATE_REQUEST,
  payload: {
    polygonGroupName,
    userId,
  },
}) as const;

export const polygonGroupCreateSuccess = (polygonGroup: TerritoryGroup) => ({
  type: POLYGON_GROUP_CREATE_SUCCESS,
  payload: {
    polygonGroup,
  },
}) as const;

export const polygonGroupCreateError = (error: Error) => ({
  type: POLYGON_GROUP_CREATE_ERROR,
  payload: {
    error,
  },
}) as const;

export const polygonGroupDeleteRequest = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_DELETE_REQUEST,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupDeleteSuccess = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_DELETE_SUCCESS,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupDeleteError = (error: Error) => ({
  type: POLYGON_GROUP_DELETE_ERROR,
  payload: {
    error,
  },
}) as const;

export const polygonGroupFetchActiveRequest = () => ({
  type: POLYGON_GROUP_FETCH_ACTIVE_REQUEST,
}) as const;

export const polygonGroupFetchActiveSuccess = (activePolygonGroupIds: ReadonlySet<number>) => ({
  type: POLYGON_GROUP_FETCH_ACTIVE_SUCCESS,
  payload: {
    activePolygonGroupIds,
  },
}) as const;

export const polygonGroupFetchActiveError = (error: Error) => ({
  type: POLYGON_GROUP_FETCH_ACTIVE_ERROR,
  payload: {
    error,
  },
}) as const;

export const polygonGroupActivateRequest = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_ACTIVATE_REQUEST,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupActivateSuccess = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_ACTIVATE_SUCCESS,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupActivateError = (error: Error) => ({
  type: POLYGON_GROUP_ACTIVATE_ERROR,
  payload: {
    error,
  },
}) as const;

export const polygonGroupDeactivateRequest = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_DEACTIVATE_REQUEST,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupDeactivateSuccess = (polygonGroupId: number) => ({
  type: POLYGON_GROUP_DEACTIVATE_SUCCESS,
  payload: {
    polygonGroupId,
  },
}) as const;

export const polygonGroupDeactivateError = (error: Error) => ({
  type: POLYGON_GROUP_DEACTIVATE_ERROR,
  payload: {
    error,
  },
}) as const;
