import {
  put, takeLatest,
} from 'redux-saga/effects';
import { SPREADSHEET_FETCH_DATA_SUCCESS } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { type GroupingColumn } from '../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { select } from '../../../_shared/utils/saga/effects';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { translate } from '../../../translations/Trans';
import { appStore } from '../../app.store';
import { createAppError } from '../../modal/modal.actionCreators';
import {
  checkIfIsNumericalColumn,
  validateSpreadsheetDataItemAttribute,
  validateSpreadsheetDataItemDate,
} from '../../spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '../../spreadsheetData/spreadsheetData.state';
import { MAP_SETTINGS_SYNC_SUCCESS } from '../data/mapSettingsData.actionTypes';
import { MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS } from '../grouping/mapSettingsGrouping.actionTypes';
import {
  mapSettingsFilteringChangeDataType,
  mapSettingsFilteringSetSettings,
} from './mapSettingsFiltering.actionCreators';
import {
  type MapSettingsFilteringState, type MapSettingsFilterSettings,
} from './mapSettingsFiltering.state';

export function* mapSettingsFilteringSagas() {
  yield takeLatest(SPREADSHEET_FETCH_DATA_SUCCESS, validateMapSettingsFilters);
  yield takeLatest(MAP_SETTINGS_SYNC_SUCCESS, validateMapSettingsFilters);
  yield takeLatest(MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS, removeFiltersUsedForGrouping);
}

function* removeFiltersUsedForGrouping() {
  const activeGroupColumns: GroupingColumn[] = yield select<GroupingColumn[]>(
    state => state.map.mapSettings.data.grouping.activeGroupColumns
  );
  const filterSettings: MapSettingsFilterSettings = yield select<MapSettingsFilterSettings>(
    state => state.map.mapSettings.data.filtering.settings
  );
  const newFilterSettings: MapSettingsFilterSettings = {
    ...filterSettings,
  };
  let isChanged = false;

  for (const activeGroup of activeGroupColumns) {
    const filter = newFilterSettings[activeGroup.spreadsheetId]?.[activeGroup.columnId];
    if (filter) {
      newFilterSettings[activeGroup.spreadsheetId] = {
        ...filterSettings[activeGroup.spreadsheetId],
        [activeGroup.columnId]: {
          ...filterSettings[activeGroup.spreadsheetId]?.[activeGroup.columnId],
        },
      };
      delete newFilterSettings[activeGroup.spreadsheetId]?.[activeGroup.columnId];
      isChanged = true;
    }
  }

  if (isChanged) {
    yield put(mapSettingsFilteringSetSettings(newFilterSettings));
  }
}

function* validateMapSettingsFilters() {
  const spreadsheetData: SpreadsheetDataData | null = yield select<SpreadsheetDataData | null>(
    state => state.spreadsheet.spreadsheetData.data
  );

  if (!spreadsheetData) {
    return;
  }

  const mapSettingsFilteringState: MapSettingsFilteringState = yield select<MapSettingsFilteringState>(
    state => state.map.mapSettings.data.filtering
  );

  const onFilterError = (spreadsheetColumnId: SpreadsheetColumnId) => {
    appStore.dispatch(createAppError({
      type: AppErrorType.General,
      title: translate('Invalid Filter Type'),
    }));

    appStore.dispatch(mapSettingsFilteringChangeDataType(spreadsheetColumnId, DataType.TEXT));
  };

  // iterate over every data in filtering
  Object.keys(mapSettingsFilteringState.settings).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    Object.keys(mapSettingsFilteringState.settings[spreadsheetId]).forEach(columnId => {
      const mapFilterSettings = mapSettingsFilteringState.settings[spreadsheetId][columnId];
      const spreadDataForColumn = spreadsheetData?.values[spreadsheetId]?.[Unfiltered]?.[columnId];
      const spreadsheetColumnId = {
        spreadsheetId,
        columnId,
      };

      if (!spreadDataForColumn) {
        return;
      }

      if (mapFilterSettings.type === DataType.TEXT) {
        return;
      }

      // validate number data type
      if (mapFilterSettings.type === DataType.NUMBER) {
        const data = spreadDataForColumn[DataType.NUMBER];

        if (!data) {
          return;
        }

        if (!checkIfIsNumericalColumn(data.extra.min, data.extra.max)) {
          onFilterError(spreadsheetColumnId);
        }
      }

      if (mapFilterSettings.type === DataType.DATE) {
        const data = spreadDataForColumn[DataType.DATE];
        if (!data) {
          return;
        }

        if (!validateSpreadsheetDataItemDate(data)) {
          onFilterError(spreadsheetColumnId);
        }
      }

      if (mapFilterSettings.type === DataType.ATTRIBUTE) {
        const data = spreadDataForColumn[DataType.ATTRIBUTE];
        if (!data) {
          return;
        }

        if (!validateSpreadsheetDataItemAttribute(data)) {
          onFilterError(spreadsheetColumnId);
        }
      }
    });
  });
}
