import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type ModalProps } from '../../../modal/modalType.enum';
import {
  s, Trans,
} from '../../../translations/Trans';
import { type MapSettingsKeyOf } from '../../map.repository';
import { getMapSettingsKeyOfTranslation } from './mapSettingsOutOfSync.helpers';

export type PerUserMapSettingsConflict = {
  userName: string;
  isCurrentUser: boolean;
  reasons: ReadonlyArray<MapSettingsKeyOf>;
};

type MapSettingsOutOfSyncProps = ModalProps<{
  showUnsavedChangesSection: boolean;
  perUserConflicts: ReadonlyArray<PerUserMapSettingsConflict>;
  unsavedChanges: ReadonlyArray<MapSettingsKeyOf>;
}>;

const containerStyle = css({
  overflow: 'hidden',
});

const sectionStyle = css({
  marginTop: 10,
});

const userChangesSectionStyle = css({
  marginTop: 15,
});

const modalContentStyle = css({
  padding: 0,
});

const headingStyle = css({
  margin: 0,
  padding: '8px 10px',
});

const userNameStyle = css({
  fontWeight: 700,
});

const sectionItemStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.primary,
  margin: 0,
  padding: '8px 15px 8px 30px',
  '&:nth-of-type(2n + 1)': {
    background: theme.backgroundColors.secondary,
  },
});

const warningStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
  padding: '0 10px',
});

export const MapSettingsOutOfSyncComponent: FC<MapSettingsOutOfSyncProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={t('Map Settings Out of Sync')}
      contentStyle={modalContentStyle}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => props.onClose()}
          text={t('Continue Offline')}
        />
      )}
      confirmButton={(
        <ButtonComponent
          text={t('Refresh')}
          onClick={() => refreshPage()}
        />
      )}
    >
      <div css={containerStyle}>
        {props.perUserConflicts.map(({ isCurrentUser, userName, reasons: mapSettingsProperties }) => (
          <div
            css={sectionStyle}
            key={userName}
          >
            <p css={headingStyle}>
              {isCurrentUser ? (
                <Trans i18nKey="<0>You</0> have changed the following recently. Consider checking or closing other browser tabs.">
                  <strong css={userNameStyle}>You</strong>
                  {s(' have changed the following recently. Consider checking or closing other browser tabs.')}
                </Trans>
              ) : (
                <Trans
                  i18nKey="User <1>{{userName}}</1> has changed the following recently:"
                  tOptions={{
                    userName,
                  }}
                >
                  {s('User ')}
                  <strong css={userNameStyle}>{'{{userName}}'}</strong>
                  {s(' has changed the following recently:')}
                </Trans>
              )}
            </p>

            <div>
              {mapSettingsProperties.map((property) => (
                <p
                  key={property}
                  css={sectionItemStyle({ theme })}
                >
                  {getMapSettingsKeyOfTranslation(property, t)}
                </p>
              ))}
            </div>
          </div>
        ))}

        <div css={[sectionStyle, userChangesSectionStyle]}>
          <p css={headingStyle}>
            {t('Please refresh your page in order to be able to save your progress. Your changes can not be saved{{endingChar}}', {
              endingChar: props.showUnsavedChangesSection ? ':' : '.',
            })}
          </p>

          {props.showUnsavedChangesSection && (
            <div>
              {props.unsavedChanges.map(property => (
                <p
                  key={property}
                  css={sectionItemStyle({ theme })}
                >
                  {getMapSettingsKeyOfTranslation(property, t)}
                </p>
              ))}
            </div>
          )}
        </div>

        <div css={sectionStyle}>
          <p css={warningStyle({ theme })}>{t('Note: You can continue working on your work offline. You won\'t be able to save you progress in offline mode.')}</p>
        </div>
      </div>
    </ModalComponent>
  );
};
