import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { computeOffset } from 'spherical-geometry-js';
import {
  PermanentConfirmStrategy, useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { type LatLng } from '~/_shared/types/latLng';
import {
  isDriveTimePolygon, isGroupRadius, isIndividualRadius, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { notNull } from '~/_shared/utils/typeGuards';
import { convertUnitToMeters } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { useProximities } from '~/proximity/useProximities';
import { activeMapElementsSetActiveProximity } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import {
  proximityHovered, proximityHoverEnded,
} from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { useMapSettingsExportImageSettingsModeSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useHiddenProximityIdsSelector } from '~/store/mapSettings/toolsState/visibility/visibility.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useMapContext } from '../mapContext';
import {
  useLatLngSpreadsheetData, useSpreadSheetData,
} from '../useSpreadsheetData.hook';
import { MapProximityManager } from './mapProximityManager';
import { ProximityInstanceCircleGroup } from './proximityInstanceCircleGroup.container';
import { ProximityInstanceCircleIndividual } from './proximityInstanceCircleIndividual.container';
import { ProximityInstancePolygon } from './proximityInstancePolygon.container';
import { useProximityLocation } from './useProximityLocation';
import { useVisibleGroupProximityLocations } from './useVisibleGroupProximityLocations';

export const MAX_RENDERED_GROUP_PROXIMITY_CIRCLES = 16000;
export const MAX_RENDERED_GROUP_PROXIMITY_IF_ABOVE_MAXIMUM = 4000;
const MAX_RENDERED_GROUP_PROXIMITY_CIRCLES_WITH_LABELS = 500;

const noCirclesWithLabels = [] as const;

export const ProximityOverlay: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const exportImageMode = useMapSettingsExportImageSettingsModeSelector();
  const spreadsheetData = useSpreadSheetData().spreadsheetData;
  const { proximities } = useProximities();
  const hiddenProximityIds = useHiddenProximityIdsSelector();
  const latLngData = useLatLngSpreadsheetData();
  const lastBounds = useMapComponentLastBoundsSelector();
  const { replaceCurrentModalWithConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { map, webglLayers } = useMapContext();

  const [zoom, setZoom] = useState(0);
  const [shownNoticeForCurrentMap, setShownNoticeForCurrentMap] = useState(false);

  const [proximityClickedAt, setProximityClickedAt] = useState<LatLng | undefined>();
  useTestProximityDetectionInLocation(proximityClickedAt);

  const manager = useMemo(() => new MapProximityManager(webglLayers), [webglLayers]);

  const commonEventsDisabled = useIsMapInteractionActive();

  const groupProximities = useMemo(() => (
    proximities.filter(isGroupRadius)
      .filter(groupProximity => !hiddenProximityIds.includes(groupProximity.id))
  ), [hiddenProximityIds, proximities]);

  const groupProximitiesVisibleLocations = useVisibleGroupProximityLocations({ proximities: groupProximities, zoom });

  const longestGroupProximityRadius = useMemo(() => (
    groupProximities.reduce((acc, proximity) => {
      return Math.max(
        acc,
        convertUnitToMeters(proximity.data.radius, proximity.data.unit),
        Object.values(proximity.data.data.individualOverride).reduce((acc, overrides) => (
          Math.max(
            acc,
            ...Object.values(overrides).map(override => (
              override.radius && override.unit
                ? convertUnitToMeters(override.radius, override.unit)
                : 0
            )
            ),
          )
        ), 0),
      );
    }, 0)
  ), [groupProximities]);

  const boundsExtendedByRadius = useMemo(() => {
    if (!lastBounds) {
      return null;
    }

    const ne = lastBounds.bounds.getNorthEast();
    const sw = lastBounds.bounds.getSouthWest();

    const newBoundingBox = lastBounds.bounds.clone();
    [0, 90, 180, 270].forEach(angle => {
      [ne, sw, { lat: ne.lat, lng: sw.lng }, { lat: sw.lat, lng: ne.lng }].forEach((latLng) => {
        const newLatLng = computeOffset(latLng, longestGroupProximityRadius, angle);
        newBoundingBox.extend({ lat: newLatLng.lat(), lng: newLatLng.lng() });
      });
    });
    return newBoundingBox;
  }, [lastBounds, longestGroupProximityRadius]);

  const totalGroupProximitiesVisibleLocations = useMemo(() => (
    groupProximitiesVisibleLocations.reduce((acc, locations) => acc + locations.length, 0)
  ), [groupProximitiesVisibleLocations]);

  const moreLocationsThatMaximumRendered = totalGroupProximitiesVisibleLocations > MAX_RENDERED_GROUP_PROXIMITY_CIRCLES;

  const groupLocationsInBounds = useMemo(() => {
    // if we have less than the max amount of circles with labels, we can render all circles and skip calculations and re-rendering in ProximityInstanceCircleGroup
    if (totalGroupProximitiesVisibleLocations <= MAX_RENDERED_GROUP_PROXIMITY_CIRCLES_WITH_LABELS) {
      return { total: totalGroupProximitiesVisibleLocations, locations: groupProximitiesVisibleLocations, labelledLocations: groupProximitiesVisibleLocations };
    }

    const targetTotal = moreLocationsThatMaximumRendered
      ? MAX_RENDERED_GROUP_PROXIMITY_IF_ABOVE_MAXIMUM// if we have more locations than the maximum we can handle, then we need to get only those in bounds but up to a smaller limit because of the updating overhead
      : MAX_RENDERED_GROUP_PROXIMITY_CIRCLES_WITH_LABELS; // if we have less locations than the maximum we can handle, then we don't need to get all those in bounds as we will use all anyway. We just need to find those in bounds for labels

    const result = boundsExtendedByRadius
      ? groupProximitiesVisibleLocations.reduce<{ total: number; locations: Array<Array<{ spreadsheetRowId: SpreadsheetRowId; latLng: LatLng }>>; labelledLocations: Array<Array<{ spreadsheetRowId: SpreadsheetRowId }>> }>((acc, locations) => {
        const locationsInBoundsForProximity: Array<{ spreadsheetRowId: SpreadsheetRowId; latLng: LatLng }> = [];
        const locationsInBoundsForProximityWithLabels: Array<{ spreadsheetRowId: SpreadsheetRowId }> = [];

        if (acc.total <= targetTotal) {
          locations.forEach(location => {
            if (acc.total + locationsInBoundsForProximity.length <= targetTotal && boundsExtendedByRadius.contains(location.latLng)) {
              locationsInBoundsForProximity.push(location);
              if (acc.total + locationsInBoundsForProximityWithLabels.length <= MAX_RENDERED_GROUP_PROXIMITY_CIRCLES_WITH_LABELS) {
                locationsInBoundsForProximityWithLabels.push({ spreadsheetRowId: location.spreadsheetRowId });
              }
            }
          });
        }

        return {
          total: moreLocationsThatMaximumRendered ? acc.total + locationsInBoundsForProximity.length : totalGroupProximitiesVisibleLocations,
          locations: moreLocationsThatMaximumRendered ? [...acc.locations, locationsInBoundsForProximity] : groupProximitiesVisibleLocations,
          labelledLocations: [...acc.labelledLocations, locationsInBoundsForProximityWithLabels],
        };
      }, { total: 0, locations: [], labelledLocations: [] })
      : null;

    const totalLabelledLocations = result?.labelledLocations.reduce((acc, locations) => acc + locations.length, 0) || 0;
    const finalLabelledLocations = totalLabelledLocations > MAX_RENDERED_GROUP_PROXIMITY_CIRCLES_WITH_LABELS
      ? noCirclesWithLabels : result?.labelledLocations;

    // if we have less than the max amount of circles, we can render all circles and just manage the labelled locations separately, to avoid circles re-renders
    if (totalGroupProximitiesVisibleLocations <= MAX_RENDERED_GROUP_PROXIMITY_CIRCLES) {
      return { total: totalGroupProximitiesVisibleLocations, locations: groupProximitiesVisibleLocations, labelledLocations: finalLabelledLocations };
    }

    // if we are over both the limits, we will need to re-render everything
    return {
      ...result,
      labelledLocations: finalLabelledLocations,
    };
  }, [totalGroupProximitiesVisibleLocations, boundsExtendedByRadius, groupProximitiesVisibleLocations, moreLocationsThatMaximumRendered]);

  const tooManyGroupProximityCirclesToRender = moreLocationsThatMaximumRendered && (groupLocationsInBounds?.total || 0) > MAX_RENDERED_GROUP_PROXIMITY_IF_ABOVE_MAXIMUM;
  const allowRenderOfGroupProximityCircles = groupLocationsInBounds !== null && !tooManyGroupProximityCirclesToRender;

  const onClick = useCallback((proximityId: string, rowId?: SpreadsheetRowId, latLng?: LatLng) => {
    setProximityClickedAt(latLng);
    dispatch(activeMapElementsSetActiveProximity(proximityId, rowId));
  }, [dispatch]);

  const onMouseEnter = useCallback((proximityId: string) => {
    dispatch(proximityHovered(proximityId));
  }, [dispatch]);

  const onMouseLeave = useCallback((proximityId: string) => {
    dispatch(proximityHoverEnded(proximityId));
  }, [dispatch]);

  const getCommonProps = useCallback((proximity: Proximity) => ({
    manager,
    isVisible: !hiddenProximityIds.includes(proximity.id),
    zoom,
    map,
  } as const), [manager, hiddenProximityIds, zoom, map]);

  const showCirclesHiddenDueToPerformanceNoticeModal = useCallback(() => {
    replaceCurrentModalWithConfirmationModal({
      confirmCaption: t('OK'),
      title: t('Performance Notice'),
      text: t('proximity.hidingGroupProximitiesBecauseTooMany'),
      onConfirm: closeConfirmationModal,
      permanentConfirmSettings: {
        id: `hide-group-proximity-circles-notice-map-${mapId}`,
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, mapId, replaceCurrentModalWithConfirmationModal, t]);

  useEffect(() => {
    if (commonEventsDisabled) {
      return;
    }
    manager.addCommonEventListener('click', onClick);
    manager.addCommonEventListener('mouseover', onMouseEnter);
    manager.addCommonEventListener('mouseout', onMouseLeave);

    return () => {
      manager.removeCommonEventListener(onClick);
      manager.removeCommonEventListener(onMouseEnter);
      manager.removeCommonEventListener(onMouseLeave);
    };
  }, [manager, commonEventsDisabled, onClick, onMouseEnter, onMouseLeave]);

  useEffect(() => {
    const listener = map.addListener('zoom_changed', () => {
      const newZoom = map.getZoom();
      if (newZoom !== undefined) {
        setZoom(newZoom);
      }
    });

    return () => google.maps.event.removeListener(listener);
  }, [map]);

  useEffect(() => {
    setShownNoticeForCurrentMap(false);
  }, [mapId]);

  useEffect(() => {
    if (tooManyGroupProximityCirclesToRender && !shownNoticeForCurrentMap && !exportImageMode) {
      setShownNoticeForCurrentMap(true);
      showCirclesHiddenDueToPerformanceNoticeModal();
    }
  }, [tooManyGroupProximityCirclesToRender, exportImageMode, showCirclesHiddenDueToPerformanceNoticeModal, shownNoticeForCurrentMap]);

  return (
    <>
      {proximities.map(proximity => {
        if (isDriveTimePolygon(proximity)) {
          return (
            <ProximityInstancePolygon
              {...getCommonProps(proximity)}
              key={proximity.id}
              proximity={proximity}

            />
          );
        }
        else if (isIndividualRadius(proximity)) {
          return (
            <ProximityInstanceCircleIndividual
              {...getCommonProps(proximity)}
              key={proximity.id}
              proximity={proximity}
            />
          );
        }
        return null;
      }).filter(notNull)}

      {allowRenderOfGroupProximityCircles && groupProximities.map((proximity, index) => {
        const locations = groupLocationsInBounds?.locations?.[index];
        const circlesWithLabels = groupLocationsInBounds?.labelledLocations?.[index] || noCirclesWithLabels;
        if (!locations) {
          return null;
        }

        return (
          <ProximityInstanceCircleGroup
            {...getCommonProps(proximity)}
            key={proximity.id}
            proximity={proximity}
            locations={locations}
            spreadsheetData={spreadsheetData}
            latLngData={latLngData}
            circlesWithLabels={circlesWithLabels}
          />
        );
      }).filter(notNull)}
    </>
  );
};

export const useTestProximityDetectionInLocation = (loc: LatLng | undefined) => {
  const ENABLED = false;

  const { proximities } = useProximities();
  const hiddenProximityIds = useHiddenProximityIdsSelector();

  const { isProximityAtLocation } = useProximityLocation();

  /* eslint-disable no-console */
  useEffect(() => {
    if (!ENABLED) {
      return;
    }

    if (loc) {
      console.time('proximities');
      const clickedOnProximities = proximities
        .filter(p => !hiddenProximityIds.includes(p.id))
        .filter(p => isProximityAtLocation(p, loc));

      console.timeEnd('proximities');

      console.log(`Clicked on ${clickedOnProximities.length} proximities:`, clickedOnProximities);
    }
  }, [ENABLED, hiddenProximityIds, isProximityAtLocation, loc, proximities]);
  /* eslint-enable no-console */
};
