import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName,
} from '../baseMap.enums';

export const baseMapThemesRetro: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#ebe3cd',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#523735',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#f5f1e6',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#c9b2a6',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#dcd2be',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#ae9e90',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#dfd2ae',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#dfd2ae',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#93817c',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#a5b076',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#447530',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#f5f1e6',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#fdfcf8',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#f8c967',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#e9bc62',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#e98d58',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#db8555',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#806b63',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#dfd2ae',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#8f7d77',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#ebe3cd',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.station'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#dfd2ae',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#b9d3c2',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#92998d',
        isActive: true,
      },
    },
  },
];
