import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  userSettingsSplitPanelClose, userSettingsSplitPanelOpen,
} from '~/store/userData/settings/userDataSettings.actionCreators';
import { useSplitItemSettingsSelector } from '~/store/userData/settings/userDataSettings.selectors';

export const useSplitPanelState = (mapId: number | null) => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();

  const splitItemSettings = useSplitItemSettingsSelector(clientId, mapId);

  const closePanel = useCallback(() => {
    if (mapId === null || clientId === null) {
      return;
    }

    dispatch(userSettingsSplitPanelClose(clientId, mapId));
  }, [dispatch, clientId, mapId]);

  const openPanel = useCallback(() => {
    if (mapId === null || clientId === null) {
      return;
    }

    dispatch(userSettingsSplitPanelOpen(clientId, mapId));
  }, [dispatch, clientId, mapId]);

  return useMemo(() => ({
    isActive: splitItemSettings.isActive,
    openPanel,
    closePanel,
  }), [closePanel, openPanel, splitItemSettings.isActive]);
};
