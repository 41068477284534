import { type LatLng } from '~/_shared/types/latLng';

export type MarkersContextMenuPosition = {
  latLng: LatLng;
  offset: { x: number; y: number };
};

type MarkersContextMenuInactiveState = {
  visible: false;
  position: null;
};

type MarkersContextMenuActiveState = {
  visible: true;
  position: MarkersContextMenuPosition;
};

export type MarkersContextMenuState = MarkersContextMenuActiveState | MarkersContextMenuInactiveState;

export const isMarkersContextMenuActive = (
  state: MarkersContextMenuState
): state is MarkersContextMenuActiveState => state.visible;
