import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/serialize';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft, faArrowRight, faExpand,
} from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type ReactNode, type RefObject, useEffect, useRef, useState,
} from 'react';
import {
  getRoundButtonSize, RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';

const GALLERY_BUTTONS_SIZE = RoundButtonSize.Small;
const GALLERY_BUTTONS_GAP = 8;

const rootStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.secondary,
  height: '100%',
  width: '100%',
  position: 'relative',
});

const headerHeight = 40;
const galleryItemGap = 16;

const headerStyle = css({
  alignItems: 'center',
  display: 'flex',
  height: headerHeight,
  justifyContent: 'space-between',
  paddingRight: 16,
});

const arrowsWrapperStyle = css({
  display: 'flex',
});

const arrowStyle = ({ theme, disabled }: ThemeProps<{ disabled: boolean }>) => css({
  color: disabled ? theme.textColors.disabled : theme.imageGalleryColors.navigationArrowsColor,
  height: headerHeight,
  width: headerHeight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    color: !disabled ? theme.textColors.contrast : undefined,
  },
  userSelect: 'none',
});

const positionIndicatorStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  minWidth: 50, // to prevent move of the trigger (expand) button when this indicator width changes
});

const getImagesFixedFrameStyle = ({ allowOverflow, numberOfItems }: {
  readonly allowOverflow?: boolean;
  readonly numberOfItems: number;
}) => css({
  boxSizing: 'border-box',
  height: `calc(100% - ${headerHeight}px)`,
  paddingBottom: 24,
  paddingLeft: galleryItemGap,
  paddingRight: numberOfItems > 1 ? (galleryItemGap + 32) : galleryItemGap,
  overflow: allowOverflow ? 'unset' : 'clip',
  position: 'relative',
  width: '100%',
});

const imagesMovingPaneStyle = css({
  display: 'flex',
  height: '100%',
  width: '100%',
  gap: galleryItemGap,
});

const galleryButtonsWrapper = ({ isShifted }: { readonly isShifted: boolean }) => css({
  alignItems: 'center',
  display: 'flex',
  gap: GALLERY_BUTTONS_GAP,
  justifyContent: 'center',
  marginLeft: isShifted ? `calc(${GALLERY_BUTTONS_GAP}px + ${getRoundButtonSize(GALLERY_BUTTONS_SIZE)})` : 0, //pretend there is one more item on the left
});

const getItemStyle = ({ fixedFrameWidth }: {
  readonly fixedFrameWidth?: number;
}): ImageGalleryItemComputedStyle => {
  // max width calculated in pixels, because percentages don't work in flex container
  const width = (fixedFrameWidth ?? 0);

  return {
    flexShrink: 0,
    maxWidth: width,
    minWidth: 0,
  };
};

const calculateNegativeMargin = ({ fixedFrameWidth, imagesMovingPaneRef, currentImageIndex }: Readonly<{
  fixedFrameWidth?: number;
  imagesMovingPaneRef: RefObject<HTMLDivElement | null>;
  currentImageIndex: number;
}>): number => {
  const allImagesWidths = Array.from(imagesMovingPaneRef.current?.children ?? [])
    .map(element => (element.getBoundingClientRect()?.width || 0) + galleryItemGap);

  const currentImageMargin = allImagesWidths
    .slice(0, currentImageIndex)
    .reduce((result: number, width: number) => result + width, 0) || 0;

  const allImagesWidth = allImagesWidths
    .reduce((result: number, width: number) => result + width, 0) || 0;

  const isSpaceMissing = (fixedFrameWidth ?? 0) - allImagesWidth < 0;

  return isSpaceMissing ? -currentImageMargin : 0;
};

type ImageGalleryExtraButtonProps = Readonly<{
  icon: IconProp;
  triggerTooltip?: string;

  onClick?: () => void;
}>;

export type ImageGalleryItem<TItem = Record<string, unknown>> = TItem & Readonly<{
  key: string;
}>;

export type ImageGalleryItemComputedStyle = CSSObject;

export type ImageGalleryItemRenderer<TItem = Record<string, unknown>> = (
  item: ImageGalleryItem<TItem>,
  computedStyle: ImageGalleryItemComputedStyle,
) => ReactNode;

export type ImageGalleryProps<TItem = Record<string, unknown>> = Readonly<{
  className?: string;
  extraButton?: ImageGalleryExtraButtonProps;
  items: ReadonlyArray<ImageGalleryItem<TItem>>;
  renderItem: ImageGalleryItemRenderer<TItem>;
  triggerTooltip?: string;
  allowOverflow?: boolean;

  onExpandClick?: () => void;
  onImageSelect: (image: TItem) => void;
}>;

export const ImageGalleryComponent = <T extends unknown>({
  allowOverflow,
  className,
  extraButton,
  items,
  onExpandClick,
  onImageSelect,
  renderItem,
  triggerTooltip,
}: ImageGalleryProps<T>) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesMovingPaneRef = useRef<HTMLDivElement | null>(null);
  const { contentWidth: fixedFrameWidth, ref: setObservedElement } = useElementDimensions();

  const animatedStyles = useSpring({ marginLeft: calculateNegativeMargin({
    currentImageIndex,
    fixedFrameWidth,
    imagesMovingPaneRef,
  }) });

  const incrementPosition = () => setCurrentImageIndex(currentImageIndex + 1 >= items.length ? 0 : currentImageIndex + 1);
  const decrementPosition = () => setCurrentImageIndex(currentImageIndex - 1 < 0 ? items.length - 1 : currentImageIndex - 1);

  const itemStyle = getItemStyle({ fixedFrameWidth });

  useEffect(() => {
    const selectedImage = items[currentImageIndex];

    if (onImageSelect && selectedImage) {
      onImageSelect(selectedImage);
    }
  }, [items, currentImageIndex, onImageSelect]);

  return (
    <div
      css={rootStyle}
      className={className}
    >
      <div css={headerStyle}>
        <span css={arrowsWrapperStyle}>
          <div
            css={arrowStyle({ theme, disabled: items.length < 2 })}
            onClick={decrementPosition}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div
            css={arrowStyle({ theme, disabled: items.length < 2 })}
            onClick={incrementPosition}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </span>

        <div css={galleryButtonsWrapper({ isShifted: !!extraButton })}>
          <RoundButtonComponent
            buttonStyle={RoundButtonStyle.Primary}
            icon={faExpand}
            onClick={onExpandClick}
            size={GALLERY_BUTTONS_SIZE}
            tooltipLabel={triggerTooltip}
          />

          {extraButton && (
            <RoundButtonComponent
              buttonStyle={RoundButtonStyle.Primary}
              icon={extraButton.icon}
              onClick={extraButton.onClick}
              size={GALLERY_BUTTONS_SIZE}
              tooltipLabel={extraButton.triggerTooltip}
            />
          )}
        </div>

        <div css={positionIndicatorStyle({ theme })}>
          {currentImageIndex + 1} {t('ofATotalOf')} {items.length}
        </div>
      </div>

      <div
        css={getImagesFixedFrameStyle({ allowOverflow, numberOfItems: items.length })}
        ref={setObservedElement}
      >
        <animated.div
          css={imagesMovingPaneStyle}
          style={animatedStyles}
          ref={imagesMovingPaneRef}
        >
          {items.map(item => renderItem(item, itemStyle))}
        </animated.div>
      </div>
    </div>
  );
};
