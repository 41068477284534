import { css } from '@emotion/react';
import { type FC } from 'react';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryGroupMatchings } from '~/store/boundaryGroups/boundaryGroups.repository';
import { type BoundaryTerritoryGroupMatchColumn } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export enum BoundarySyncDataType {
  Geometry = 'geometry',
  Data = 'data',
}

type BoundarySettingsSyncDataProps = Readonly<{
  boundaryGroupMatchings: BoundaryGroupMatchings;
  spreadsheetColumns: ReadonlyArray<SpreadsheetColumn>;
  syncDataType: BoundarySyncDataType;
  onSyncDataTypeChange: (type: BoundarySyncDataType) => void;
  matchColumns: ReadonlyArray<BoundaryTerritoryGroupMatchColumn>;
  onMatchingColumnsChange: (columns: ReadonlyArray<BoundaryTerritoryGroupMatchColumn>) => void;
}>;

const wrapperStyle = css({
  padding: '20px 24px',
});

const panelDropdownSectionStyle = css({
  marginBottom: 15,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
});

const dropdownTriggerStyle = css({
  height: 40,
});

const radioGroupStyle = css({
  flexDirection: 'row',
  justifyContent: 'start',
  margin: '10px 0 15px',
  width: '50%',
});

const radioItemStyle = css({
  margin: '0 0 0 55px',
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    marginLeft: 0,
  },
});

export const BoundarySettingsSyncDataComponent: FC<BoundarySettingsSyncDataProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={wrapperStyle}>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>
          {t('Match location with boundaries based on')}
        </div>

        <RadioGroupComponent
          css={radioGroupStyle}
          itemCommonStyle={radioItemStyle}
          onValueChange={(newSyncDataType) => {
            switch (newSyncDataType) {
              case BoundarySyncDataType.Data: {
                props.onSyncDataTypeChange(BoundarySyncDataType.Data);
                return;
              }
              default: {
                props.onSyncDataTypeChange(BoundarySyncDataType.Geometry);
                return;
              }
            }
          }}
          selectedValue={props.syncDataType}
          items={[{
            label: t('Geometry'),
            value: BoundarySyncDataType.Geometry,
          }, {
            label: t('Data'),
            value: BoundarySyncDataType.Data,
          }]}
        />
      </div>

      {props.syncDataType !== BoundarySyncDataType.Geometry && props.boundaryGroupMatchings.matchingColumns.map(boundaryGroupMatchingColumn => {
        const currentValue = props.matchColumns.find(
          matchColumn => matchColumn.category === boundaryGroupMatchingColumn.id
        );

        return (
          <div
            key={boundaryGroupMatchingColumn.id}
            css={panelDropdownSectionStyle}
          >
            <div css={labelStyle}>
              {t('Please verify or select the correct column in your data that matches "{{columnName}}"', {
                columnName: boundaryGroupMatchingColumn.name,
              })}
            </div>

            <RegularDropdownComponent
              onChange={(columnId) => {
                if (typeof columnId === 'undefined') {
                  return;
                }

                const newMatchColumns = props.matchColumns.filter(
                  matchColumns => matchColumns.category !== boundaryGroupMatchingColumn.id
                );
                newMatchColumns.push({
                  category: boundaryGroupMatchingColumn.id,
                  columnId,
                });

                props.onMatchingColumnsChange(newMatchColumns);
              }}
              options={props.spreadsheetColumns.map(column => ({
                name: column.name,
                value: column.id,
              }))}
              triggerStyle={dropdownTriggerStyle}
              value={currentValue?.columnId}
              inPortal
            />
          </div>
        );
      })}
    </div>
  );
};
