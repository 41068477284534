import { combineReducers } from 'redux';
import { DEFAULT_GOOGLE_MAPS_SETTINGS } from '~/_shared/components/google-map/google-map.component';
import { type LatLng } from '../../../_shared/types/latLng';
import { type BoundingBox } from '../../../map/map/boundingBox';
import { MAP_RESET } from '../../map/map.actionTypes';
import { type MapComponentAction } from './mapComponent.action';
import {
  MAP_COMPONENT_SET_CENTER,
  MAP_COMPONENT_SET_CENTER_AND_ZOOM,
  MAP_COMPONENT_SET_LAST_BOUNDS,
  MAP_COMPONENT_SET_ZOOM,
  MAP_COMPONENT_SET_ZOOM_TO_BOUNDS,
} from './mapComponent.actionTypes';
import {
  type LastBounds, type MapComponentState,
} from './mapComponent.state';
import { mapComponentOptionsReducer } from './mapComponentOptions/mapComponentOptions.reducer';

const initialState: Pick<MapComponentState, 'center' | 'zoom' | 'zoomToBounds' | 'zoomToBoundsPreferredZoom' | 'lastBounds' | 'resetMap'> = {
  center: DEFAULT_GOOGLE_MAPS_SETTINGS.center,
  zoom: DEFAULT_GOOGLE_MAPS_SETTINGS.zoom,
  lastBounds: null,
  zoomToBounds: null,
  zoomToBoundsPreferredZoom: null,
  resetMap: true,
};

const zoom = (state = initialState.zoom, action: MapComponentAction): number | null => {
  switch (action.type) {
    case MAP_COMPONENT_SET_ZOOM:
      return action.payload.zoom;

    case MAP_COMPONENT_SET_CENTER_AND_ZOOM:
      return action.payload.zoom;

    default: {
      return state;
    }
  }
};

const center = (state = initialState.center, action: MapComponentAction): LatLng => {
  switch (action.type) {
    case MAP_COMPONENT_SET_CENTER:
      return {
        ...action.payload.center,
      };

    case MAP_COMPONENT_SET_CENTER_AND_ZOOM:
      return {
        ...action.payload.center,
      };

    default: {
      return state;
    }
  }
};

const zoomToBounds = (state = initialState.zoomToBounds, action: MapComponentAction): BoundingBox | null => {
  switch (action.type) {
    case MAP_COMPONENT_SET_ZOOM_TO_BOUNDS:
      return action.payload.boundingBox;

    default: {
      return state;
    }
  }
};

const zoomToBoundsPreferredZoom = (state = initialState.zoomToBoundsPreferredZoom, action: MapComponentAction): number | null => {
  switch (action.type) {
    case MAP_COMPONENT_SET_ZOOM_TO_BOUNDS:
      return action.payload.preferredZoom ?? null;

    default: {
      return state;
    }
  }
};

const lastBounds = (state = initialState.lastBounds, action: MapComponentAction): LastBounds | null => {
  switch (action.type) {
    case MAP_COMPONENT_SET_LAST_BOUNDS:
      return {
        bounds: action.payload.boundingBox,
        zoomLevel: action.payload.zoomLevel,
      };

    default: {
      return state;
    }
  }
};

const resetMap = (state = initialState.resetMap, action: MapComponentAction): boolean => {
  switch (action.type) {
    case MAP_COMPONENT_SET_CENTER:
    case MAP_COMPONENT_SET_CENTER_AND_ZOOM:
    case MAP_COMPONENT_SET_ZOOM:
    case MAP_COMPONENT_SET_ZOOM_TO_BOUNDS:
      return action.payload.resetMap;
    default:
      return state;
  }
};

const regularReducer = combineReducers({
  zoom,
  center,
  zoomToBounds,
  zoomToBoundsPreferredZoom,
  lastBounds,
  resetMap,
  mapOptions: mapComponentOptionsReducer,
});

export const mapComponentReducer = (state: MapComponentState, action: MapComponentAction | Action) => {
  if (action.type === MAP_RESET) {
    return regularReducer(undefined, action);
  }

  return regularReducer(state, action);
};
