import {
  type FC, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { mapSettingsConcurrencySetIsOffline } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.actionCreators';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { type ModalProps } from '../../../modal/modalType.enum';
import { type MapSettingsKeyOf } from '../../map.repository';
import {
  MapSettingsOutOfSyncComponent,
  type PerUserMapSettingsConflict,
} from './mapSettingsOutOfSync.component';
import { type MapSettingsUpdateSettingsConcurrencyErrorReason } from './mapSettingsOutOfSync.types';

export type MapSettingsOutOfSyncContainerProps = ModalProps<{
  syncResults: MapSettingsUpdateSettingsConcurrencyErrorReason;
}>;

export const MapSettingsOutOfSyncContainer: FC<MapSettingsOutOfSyncContainerProps> = (props) => {
  const dispatch = useDispatch();
  const currentUserId = useUserIdSelector();

  const perUserConflicts = useMemo<ReadonlyArray<PerUserMapSettingsConflict>>(() => {
    const reasons = new Map();

    Object.entries(props.syncResults).forEach(([property, value]) => {
      if (value.concurrencyCheck === 'passed') {
        return;
      }

      const userReasons = [...reasons.get(value.userName) ?? [], property];
      reasons.set(value.userId, {
        userName: value.userName,
        userReasons,
      });
    });

    return Array.from(reasons).map(([userId, { userName, userReasons }]) => {
      return {
        userName,
        isCurrentUser: currentUserId === userId,
        reasons: userReasons,
      };
    });
  }, [props.syncResults, currentUserId]);

  const allUnsavedChanges = useMemo(() => {
    const reasons = new Set<MapSettingsKeyOf>();

    Object.keys(props.syncResults).forEach((property: MapSettingsKeyOf) => {
      reasons.add(property);
    });

    return Array.from(reasons);
  }, [props.syncResults]);

  const setMapOffline = () => {
    dispatch(mapSettingsConcurrencySetIsOffline(true));
  };

  const showUnsavedChangesSection = useMemo(() => {
    const passedProperties = new Set<string>(allUnsavedChanges);
    Object.entries(props.syncResults).forEach(([property, result]) => {
      if (result.concurrencyCheck === 'failed') {
        passedProperties.delete(property);
      }
    });

    return passedProperties.size > 0;
  }, [allUnsavedChanges, props.syncResults]);

  return (
    <MapSettingsOutOfSyncComponent
      {...props}
      showUnsavedChangesSection={showUnsavedChangesSection}
      perUserConflicts={perUserConflicts}
      unsavedChanges={allUnsavedChanges}
      onClose={() => {
        setMapOffline();
        props.onClose();
      }}
    />
  );
};
