import { type LatLng } from '~/_shared/types/latLng';
import { type BoundingBox } from '~/map/map/boundingBox';
import {
  ALLOW_STREET_VIEW,
  DISALLOW_STREET_VIEW,
  MAP_COMPONENT_SET_CENTER, MAP_COMPONENT_SET_CENTER_AND_ZOOM, MAP_COMPONENT_SET_LAST_BOUNDS,
  MAP_COMPONENT_SET_ZOOM, MAP_COMPONENT_SET_ZOOM_TO_BOUNDS,
} from './mapComponent.actionTypes';

export const mapComponentSetCenter = (center: LatLng, resetMap = true) => ({
  type: MAP_COMPONENT_SET_CENTER,
  payload: {
    center,
    resetMap,
  },
}) as const;

export const mapComponentSetZoom = (zoom: number | null, resetMap = true) => ({
  type: MAP_COMPONENT_SET_ZOOM,
  payload: {
    zoom,
    resetMap,
  },
}) as const;

export const mapComponentSetCenterAndZoom = (center: LatLng, zoom: number | null, resetMap = true) => ({
  type: MAP_COMPONENT_SET_CENTER_AND_ZOOM,
  payload: {
    center,
    zoom,
    resetMap,
  },
}) as const;

export const mapComponentSetZoomToBounds = (boundingBox: BoundingBox, options?: { resetMap?: boolean; preferredZoom?: number }) => ({
  type: MAP_COMPONENT_SET_ZOOM_TO_BOUNDS,
  payload: {
    boundingBox,
    resetMap: options?.resetMap ?? true,
    preferredZoom: options?.preferredZoom,
  },
}) as const;

export const mapComponentSetLastBounds = (boundingBox: BoundingBox, zoomLevel: number) => ({
  type: MAP_COMPONENT_SET_LAST_BOUNDS,
  payload: {
    boundingBox,
    zoomLevel,
  },
}) as const;

export const mapComponentDisallowStreetView = () => ({
  type: DISALLOW_STREET_VIEW,
}) as const;

export const mapComponentAllowStreetView = () => ({
  type: ALLOW_STREET_VIEW,
}) as const;
