import { type TranslationFnc } from '~/_shared/utils/hooks';
import { ExportImageMode } from './exportImage';

export const EXPORT_LOCATION_LIST_COMPONENT_ID = 'ExportLocationListComponent';
export const EXPORT_LEGEND_COMPONENT_ID = 'ExportLegendComponent';

export const getExportImageModeLabel = (t: TranslationFnc, style: ExportImageMode) => {
  switch (style) {
    case ExportImageMode.Screenshot:
      return t('ExportImageMode.Screenshot__label');
    case ExportImageMode.Legend:
      return t('ExportImageMode.Legend__label');
    case ExportImageMode.LocationList:
      return t('ExportImageMode.LocationList__label');
    case ExportImageMode.Map:
      return t('ExportImageMode.Map__label');
    case ExportImageMode.MapAndLegend:
      return t('ExportImageMode.MapAndLegend__label');
    default:
      throw new Error(`exportImageMode.enum.ts: unknown ExportImageMode '${style}'.`);
  }
};

export const getExportImageModeDescription = (t: TranslationFnc, style: ExportImageMode) => {
  switch (style) {
    case ExportImageMode.Screenshot:
      return t('ExportImageMode.Screenshot__description');
    case ExportImageMode.Legend:
      return t('ExportImageMode.Legend__description');
    case ExportImageMode.LocationList:
      return t('ExportImageMode.LocationList__description');
    case ExportImageMode.Map:
      return t('ExportImageMode.Map__description');
    case ExportImageMode.MapAndLegend:
      return t('ExportImageMode.MapAndLegend__description');
    default:
      throw new Error(`exportImageMode.enum.ts: unknown ExportImageMode '${style}'`);
  }
};
