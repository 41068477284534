import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  DatePickerComponent,
  DatepickerRole,
} from '~/_shared/baseComponents/datepicker/datepicker.component';
import { useTranslation } from '~/_shared/utils/hooks';

export type DateFilterSettings = Readonly<{
  minDate: string;
  maxDate: string;
}>;

export type DateFilterValues = Readonly<{
  currentMinDate: string;
  currentMaxDate: string;
  onChangeMinDate: (newMin: string) => void;
  onChangeMaxDate: (newMax: string) => void;
}>;

type DateFilterProps = DateFilterSettings & DateFilterValues;

const rootStyle = css({
  padding: 10,
  boxSizing: 'border-box',
});

const labelStyle = css({
  display: 'block',
  width: '100%',
  marginTop: 10,
});

export const DateFilterComponent: FC<DateFilterProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={rootStyle}>
      <label css={labelStyle}>
        {t('From')}
      </label>
      <DatePickerComponent
        selectedDate={props.currentMinDate}
        onChange={props.onChangeMinDate}
        minDate={props.minDate}
        maxDate={props.currentMaxDate}
        role={DatepickerRole.RangeStartSelector}
        end={props.currentMaxDate}
      />
      <label css={labelStyle}>
        {t('To')}
      </label>
      <DatePickerComponent
        selectedDate={props.currentMaxDate}
        onChange={props.onChangeMaxDate}
        minDate={props.currentMinDate}
        maxDate={props.maxDate}
        role={DatepickerRole.RangeEndSelector}
        start={props.currentMinDate}
      />
    </div>
  );
};
