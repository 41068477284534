import {
  call, put,
  takeLatest,
} from 'redux-saga/effects';
import WebworkerPromise from 'webworker-promise';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { convertGeometryToMultiPolygon } from '~/_shared/types/polygon/polygon.utils';
import {
  isDriveTimePolygonSettings, type ProximityModel,
} from '~/_shared/types/proximity/proximity.types';
import { flatten } from '~/_shared/utils/collections/collections';
import { getBoundingBoxOfMultipolygon } from '~/_shared/utils/gis/boundingBox.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { notNull } from '~/_shared/utils/typeGuards';
import { type PickAction } from '~/_shared/utils/types/action.type';
import {
  startWorker, Workers,
} from '~/_shared/utils/worker/startWorker';
import {
  type DriveTimeErrorResponseMessage,
  type DriveTimeRouteResponse,
  type DriveTimeTimeMapRequest, getDriveTimeRoute,
} from '../../../proximity/proximity.repository';
import { type MapSettingsDataAction } from '../../mapSettings/data/mapSettingsData.action';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../../mapSettings/data/mapSettingsData.actionTypes';
import { type MapSettingsProximityAction } from '../../mapSettings/proximity/mapSettingsProximity.action';
import {
  MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY,
} from '../../mapSettings/proximity/mapSettingsProximity.actionTypes';
import { mapComponentSetZoomToBounds } from '../mapComponent/mapComponent.actionCreators';
import { proximityDriveTimeSetProximityPaths } from './proximityDriveTimePaths.actionCreators';
import { getDriveTimePathsPathId } from './proximityDriveTimePaths.helpers';
import {
  type ProximityDriveTimePathRequest, type ProximityDriveTimePathsState,
} from './proximityDriveTimePaths.state';

export function* proximityDriveTimePathsSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, onMapSettingsLoaded);
  yield takeLatest(MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY, onNewProximityAdd);
  yield takeLatest(MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY, onMapSettingsProximityListSet);
}

function* onNewProximityAdd(action: PickAction<MapSettingsProximityAction, typeof MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY>) {
  yield processProximityRequests([action.payload.proximity], action.payload.zoomAfter);
}

function* onMapSettingsProximityListSet(action: PickAction<MapSettingsProximityAction, typeof MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY>) {
  yield processProximityRequests([action.payload.proximity]);
}

function* onMapSettingsLoaded(action: PickAction<MapSettingsDataAction, typeof MAP_SETTINGS_FETCH_DATA_SUCCESS>) {
  yield processProximityRequests(action.payload.mapSettingsData.proximity?.proximities ?? []);
}

export type ProcessedProximityResult = {
  proximityPathId: string;
  paths?: MultiPolygon | null;
  message?: DriveTimeErrorResponseMessage;
};

function* processProximityRequests(proximities: ProximityModel[], zoomAfter?: boolean) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const mapId: number | null = yield select(state => state.map.mapInfo.data?.id ?? null);
  const proximityDriveTimePathsState: ProximityDriveTimePathsState = yield select<ProximityDriveTimePathsState>(
    state => state.frontendState.proximityDriveTimePaths
  );
  if (!clientId) {
    return;
  }

  const requests: ProximityDriveTimePathRequest[] = [];

  for (const proximity of proximities) {
    if (!isDriveTimePolygonSettings(proximity)) {
      continue;
    }

    const request = {
      minutes: proximity.data.minutes,
      hours: proximity.data.hours,
      latLng: {
        lat: proximity.data.lat,
        lng: proximity.data.lng,
      },
    };

    const proximityPathId = getDriveTimePathsPathId(request);

    // skip request if data already exists
    if (proximityDriveTimePathsState.paths[proximityPathId]) {
      continue;
    }

    requests.push(request);
  }

  if (!requests.length) {
    return;
  }

  try {
    const proximityOrderedPathIds: string[] = [];
    const driveTimeRoutes: DriveTimeTimeMapRequest = requests.map((r, index) => {
      proximityOrderedPathIds.push(getDriveTimePathsPathId(r));

      return {
        id: index + '',
        longitude: r.latLng.lng,
        latitude: r.latLng.lat,
        transportation: 'driving',
        travel_time: (r.hours * 60 * 60) + (r.minutes * 60),
      };
    });

    const dtRouteResponse: DriveTimeRouteResponse = yield call(getDriveTimeRoute, clientId, mapId, driveTimeRoutes);
    const dtPolygons: Array<ProcessedProximityResult> = driveTimeRoutes.map((dtRoute, index) => {
      const proxItem = dtRouteResponse.get(dtRoute.id);
      const proximityPathId = proximityOrderedPathIds[index];

      if (!proximityPathId || !proxItem) {
        console.error('Travel time: time-map returned invalid results', { item: proxItem, index, result: driveTimeRoutes });
        return null;
      }

      if (!proxItem.success) {
        console.error(proxItem.message, { item: proxItem, index, result: driveTimeRoutes });
        return {
          proximityPathId,
          message: proxItem.message,
          paths: null,
        };
      }

      return {
        proximityPathId,
        paths: flatten(proxItem.data.map(p => convertGeometryToMultiPolygon(p.geometry))),
      };
    }).filter(notNull);

    const worker = new WebworkerPromise(startWorker(Workers.SimplifyDriveTimePaths));
    const simplifiedPolygons: Array<ProcessedProximityResult> = yield call(() => worker.postMessage({ dtData: dtPolygons }));

    const pathsIdArray = simplifiedPolygons.map(item => {
      return {
        proximityPathId: item.proximityPathId,
        paths: item.paths ?? null,
        message: item.message ?? null,
      };
    });

    if (pathsIdArray[0]) {
      yield put(proximityDriveTimeSetProximityPaths(pathsIdArray));

      if (zoomAfter && pathsIdArray[0].paths) {
        yield put(mapComponentSetZoomToBounds(getBoundingBoxOfMultipolygon(pathsIdArray[0].paths)));
      }
    }
  }
  catch (e) {
    console.error(e);
  }
}
