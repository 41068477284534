import { css } from '@emotion/react';
import { type ThemeProps } from '../../../../types/themeProps';

export const spinnersStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  userSelect: 'none',
});

export const arrowStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50%',
  width: '100%',
  border: 'none',
  color: 'inherit',
  '&:hover': {
    backgroundColor: isDisabled ? 'inherit' : theme.backgroundColors.secondaryHover,
  },
});

export const topArrowStyle = css({
  borderRadius: '0 4px 0 0',
});

export const bottomArrowStyle = css({
  borderRadius: '0 0 4px 0',
});

export const arrowIconStyle = css({
  height: '100%',
  maxHeight: '1em',
});
