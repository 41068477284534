import { type DrawingArrowSettingsState } from '../../store/mapSettings/drawing/settings/arrow/drawingArrowSettings.state';
import {
  DRAWING_TOOL_SLIDER_ARROW_TOOL_LINE_WIDTH_RANGE,
  DRAWING_TOOL_SLIDER_ARROW_TOOL_STROKE_WIDTH_RANGE,
  DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
  DRAWING_TOOL_SLIDER_TRIANGLE_SIZE_RANGE,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createGlobalArrowDrawingOptionsFromSettings = (settings: DrawingArrowSettingsState) => createArrowDrawingOptionsFromSettings({ settings });

export const createSelectedArrowDrawingOptionsFromSettings = (settings: DrawingArrowSettingsState, sizePerPixelRatio: number) =>
  createArrowDrawingOptionsFromSettings({ settings, sizePerPixelRatio });

const createArrowDrawingOptionsFromSettings = ({ settings, sizePerPixelRatio }:
{
  settings: DrawingArrowSettingsState;
  sizePerPixelRatio?: number;
}): Partial<DrawingToolOptions> => {
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    // non-scaling feature is disabled
    // booleanOptions: {
    //   scalesWithMapZoom: settings.scalesWithMapZoom,
    // },
    ...(useScaledSlider ? {
      scaledSliderOptions: {
        lineWeightOnCurrentMapZoom: {
          value: settings.lineWeight,
          displayValue: Math.round((settings.lineWeight) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_ARROW_TOOL_LINE_WIDTH_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
          ensureValueInRange: false,
        },
        strokeWeightOnCurrentMapZoom: {
          value: settings.strokeWeight,
          displayValue: Math.round((settings.strokeWeight) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_ARROW_TOOL_STROKE_WIDTH_RANGE,
          suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
          ensureValueInRange: false,
        },
      },
    } : undefined),
    sliderOptions: {
      triangleSize: {
        value: settings.triangleSize,
        range: DRAWING_TOOL_SLIDER_TRIANGLE_SIZE_RANGE,
      },
      strokeOpacity: {
        value: settings.strokeOpacity,
      },
      fillOpacity: {
        value: settings.fillOpacity,
      },
      ...(!useScaledSlider ? {
        lineWeight: {
          value: settings.lineWeight,
          range: DRAWING_TOOL_SLIDER_ARROW_TOOL_LINE_WIDTH_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
        },
        strokeWeight: {
          value: settings.strokeWeight,
          range: DRAWING_TOOL_SLIDER_ARROW_TOOL_STROKE_WIDTH_RANGE,
          suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
        },
      } : undefined),
    },
    colorOptions: {
      strokeColor: settings.strokeColor,
      fillColor: settings.fillColor,
    },
  };
};

export const createArrowDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingArrowSettingsState
): DrawingArrowSettingsState => {
  return {
    // non-scaling feature is disabled
    // scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    scalesWithMapZoom: true,
    lineWeight: toolOptions.sliderOptions?.lineWeight?.value ?? toolOptions.scaledSliderOptions?.lineWeightOnCurrentMapZoom?.value ?? currentSettings.lineWeight,
    triangleSize: toolOptions.sliderOptions?.triangleSize?.value ?? currentSettings.triangleSize,
    fillOpacity: toolOptions.sliderOptions?.fillOpacity?.value ?? currentSettings.fillOpacity,
    strokeOpacity: toolOptions.sliderOptions?.strokeOpacity?.value ?? currentSettings.strokeOpacity,
    strokeWeight: toolOptions.sliderOptions?.strokeWeight?.value ?? toolOptions.scaledSliderOptions?.strokeWeightOnCurrentMapZoom?.value ?? currentSettings.strokeWeight,
    strokeColor: toolOptions.colorOptions?.strokeColor ?? currentSettings.strokeColor,
    fillColor: toolOptions.colorOptions?.fillColor ?? currentSettings.fillColor,
  };
};
