import {
  type MutableRefObject,
  useLayoutEffect, useMemo, useRef, useState,
} from 'react';

// with React 19, change the type to RefObject as MutableRefObject becomes obsolete
export function useHeightTransition(): [MutableRefObject<HTMLElement | null>, number | undefined] {
  const [contentHeight, setContentHeight] = useState<number>();
  const wrapperElementRef = useRef<HTMLElement | null>(null);
  const contentHeightRef = useRef(contentHeight);

  const resizeObserver = useMemo(() =>
    new ResizeObserver(() => {
      if (wrapperElementRef.current && contentHeightRef.current !== wrapperElementRef.current.offsetHeight) {
        contentHeightRef.current = wrapperElementRef.current.offsetHeight;
        setContentHeight(wrapperElementRef.current.offsetHeight);
      }
    }), []);

  useLayoutEffect(() => {
    if (wrapperElementRef.current) {
      setContentHeight(wrapperElementRef.current.offsetHeight);
      resizeObserver.observe(wrapperElementRef.current, {});
    }

    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  return [wrapperElementRef, contentHeight];
}
