import type { LatLng } from '../types/latLng';
import { apiPost } from '../utils/api/api.helpers';

export enum TravelTimeError {
  FAILED = 'FAILED',
  PATH_NOT_FOUND = 'PATH_NOT_FOUND',
  OVER_LIMIT = 'OVER_LIMIT_DISTANCE_MATRIX',
}

export type DistanceMatrixSearchItem = Readonly<{
  id: string;
  from: LatLng;
  to: ReadonlyArray<LatLng>;
}>;

export type DistanceMatrixFastRequest = Readonly<{
  searches: ReadonlyArray<DistanceMatrixSearchItem>;
}>;

export type DistanceMatrixToItemResponse = LatLng & {
  distance: number | null; // in meters
  error?: TravelTimeError | null;
  travel_time: number | null; // in seconds
};

export type DistanceMatrixResultItem = Readonly<{
  id: string;
  from: LatLng;
  to: ReadonlyArray<DistanceMatrixToItemResponse>;
}>;

export type DistanceMatrixFastResponse = Readonly<{
  results: ReadonlyArray<DistanceMatrixResultItem>;
  distance_matrix_requests_usage: number;
}>;

export const getDistanceMatrixFast = (clientId: number, params: DistanceMatrixFastRequest): Promise<DistanceMatrixFastResponse> => {
  const requestUrl = `/api/clients/${clientId}/travel-time/distance-matrix-fast`;
  return apiPost(requestUrl, params);
};
