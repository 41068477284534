import { type MyLocationAction } from './myLocation.action';
import {
  FRONTEND_STATE_DISABLE_LOCATION_TRACKING, FRONTEND_STATE_ENABLE_LOCATION_TRACKING, FRONTEND_STATE_LOCATION_TRACKING_ERROR, FRONTEND_STATE_SET_MY_LOCATION,
} from './myLocation.actionTypes';
import { type MyLocationState } from './myLocation.state';

const initialState: MyLocationState = {
  latLng: null,
  enabled: false,
  error: false,
};

export const myLocationReducer = (state = initialState, action: MyLocationAction): MyLocationState => {
  switch (action.type) {

    case FRONTEND_STATE_SET_MY_LOCATION: {
      return {
        ...state,
        error: false,
        latLng: action.payload.latLng,
      };
    }

    case FRONTEND_STATE_LOCATION_TRACKING_ERROR: {
      return {
        ...state,
        error: true,
        latLng: null,
      };
    }

    case FRONTEND_STATE_ENABLE_LOCATION_TRACKING: {
      return {
        ...state,
        enabled: true,
      };
    }

    case FRONTEND_STATE_DISABLE_LOCATION_TRACKING: {
      return {
        ...state,
        enabled: false,
      };
    }

    default: {
      return state;
    }
  }
};
