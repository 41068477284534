import { type PerColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type MapSettingsDataPath } from '~/store/mapSettings/data/mapSettingsData.helpers';

export const extraColumnSettingsTools = {
  Boundary: 'boundary',
  BoundaryMetrics: 'boundary-metrics',
  ProximityMetrics: 'proximity-metrics',
} as const;

export type ExtraColumnSettingsTool = typeof extraColumnSettingsTools[keyof typeof extraColumnSettingsTools];

export type ColumnSettingsTool = ExtraColumnSettingsTool | MapSettingsDataPath;

export type ColumnMapSettings = Readonly<{
  name: string;
  maps: { readonly [mapId: number]: readonly ColumnSettingsTool[] };
}>;

export type ColumnsMapSettingsResponse = Readonly<{
  columns?: PerColumn<ColumnMapSettings>;
  maps?: {
    readonly [mapId: number]: Readonly<{
      name: string;
      parent_map_id: number;
    }>;
  };
}>;
