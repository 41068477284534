import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';

type SliderWithInputValuesItemProps = Readonly<{
  value: number;
  min: number;
  max: number;
  inputSize: number;
  onChange: (newValue: number) => void;
  icon?: IconProp | null;
}>;

const itemInputStyle = ({ theme, isError }: ThemeProps<{ isError: boolean }>) => css({
  width: 'auto',
  flexGrow: 0,
  borderColor: isError ? theme.borderColors.error : undefined,
});

const SLIDER_INPUT_VALUE_MAX_SIZE = 10;
const SLIDER_INPUT_VALUE_MIN_SIZE = 1;

export const SliderWithInputValuesItemComponent: FC<SliderWithInputValuesItemProps> = (props) => {
  const { min, max, inputSize } = props;
  const [inputValue, setInputValue] = useState(props.value.toString());
  const theme = useTheme();

  useEffect(() => {
    setInputValue(props.value.toString());
  }, [props.value]);

  const onInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const onSave = useCallback(() => {
    const numberValue: number = +inputValue;

    if (isNaN(numberValue)) {
      setInputValue(props.value.toString());
      return;
    }

    const valueInRange = clamp(numberValue, { min, max });

    const onChange = props.onChange;
    if (valueInRange !== props.value) {
      onChange(valueInRange);
    }
    else {
      setInputValue(valueInRange.toString());
    }
  }, [inputValue, max, min, props.onChange, props.value]);

  return (
    <TextInputComponent
      css={itemInputStyle({ theme, isError: isNaN(Number(inputValue)) })}
      inputSize={clamp(Math.round(inputSize), { min: SLIDER_INPUT_VALUE_MIN_SIZE, max: SLIDER_INPUT_VALUE_MAX_SIZE })}
      value={inputValue.toString()}
      onChange={onInputChange}
      onBlur={onSave}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          onSave();
        }
      }}
      icon={props.icon ?? null}
    />
  );
};
