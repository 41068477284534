import {
  put, takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { MapMarkersGraphicsToggleSettingsName } from '~/map/settings/accordion/mapMarkersGraphics/mapMarkersGraphics.enums';
import {
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS, MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
} from '../../makersGeneral/mapSettingsMarkersGeneral.actionTypes';
import { type MapSettingsMarkersAction } from '../mapSettingsMarkers.action';
import {
  mapSettingsMarkersSetUnstackMarkers, mapSettingsMarkersToggle,
} from '../mapSettingsMarkers.actionCreators';
import {
  MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS,
  MAP_SETTINGS_MARKERS_TOGGLE, MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW,
} from '../mapSettingsMarkers.actionTypes';
import { mapSettingsUnstackMarkerStateSelector } from '../mapSettingsMarkers.selectors';
import { mapSettingsIsMarkerClusteringForcedSelector } from '../mapSettingsMarkersClustering.selectors';
import { isMapSettingsUnstackMarkersForcedSelector } from '../mapSettingsMarkersUnstacking.selectors';

export function* preventMarkerSettingsConflictsSaga() {
  yield takeLatest([
    MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS,
    MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS,
    MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
    MAP_SETTINGS_MARKERS_TOGGLE,
    MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW,
  ], unstackMarkersBasedOnBelowNAndClustering);
}

/*
 * If both unstacking and clustering are forced, and unstacking is not enabled in the state, enable it.
 * The reason is that if both of them are forced, it means they are both disabled in the UI
 * Therefore without enabling one of them user cannot alter neither of them in the UI
 */
function* unstackMarkersBasedOnBelowNAndClustering(action: PickAction<MapSettingsMarkersAction, typeof MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS>) {
  const isUnstackingForced: boolean = yield select<boolean>(isMapSettingsUnstackMarkersForcedSelector);
  const isClusteringForced: boolean = yield select<boolean>(mapSettingsIsMarkerClusteringForcedSelector);
  const isUnstackingEnabledInState: boolean = yield select<boolean>(mapSettingsUnstackMarkerStateSelector);

  if (isUnstackingForced && isClusteringForced) {
    if (action.type !== MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS && !isUnstackingEnabledInState) {
      yield put(mapSettingsMarkersSetUnstackMarkers(true));
    }
    else if (action.type === MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS && !action.payload.newValue) {
      yield put(mapSettingsMarkersToggle(MapMarkersGraphicsToggleSettingsName.clusterDenseMarkers));
    }
  }
}
