import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type ModalProps } from '../../../modal/modalType.enum';
import { type BoundaryGroup } from '../../boundary.types';
import {
  BoundaryEditAction,
  BoundaryEditManualMethod,
  BoundaryEditSelectFrom,
  getBoundaryEditActionOptions,
  getBoundaryEditManualMethodOptions,
  getBoundaryEditSelectFromRadioGroupOptions,
} from './boundaryEditModal.helpers';

export type BoundaryEditResults = {
  action: BoundaryEditAction;
  manualMethod: BoundaryEditManualMethod;
  selectedBoundaryGroupId: number;
};

type BoundaryEditModalProps = ModalProps<{
  onSubmit: (results: BoundaryEditResults) => void;
  boundaryGroupIsEmpty: boolean;
  globalBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  customBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  defaultAction?: BoundaryEditAction;
  defaultManualMethod?: BoundaryEditManualMethod;
  defaultSelectFrom?: BoundaryEditSelectFrom;
  defaultBoundaryGroup?: {
    global?: number;
    custom?: number;
  };
}>;

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const radioGroupStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: 55,
  margin: '10px 0 15px',
  paddingLeft: 20,
});

const radioItemStyle = css({
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  width: 'unset',
});

const submitButtonStyles = css({
  height: 40,
});

const contentStyle = css({
  overflow: 'hidden',
});

const getInitialBoundaryGroupId = (defaultBoundaryGroupId: number | undefined, boundaryGroupsArray: ReadonlyArray<BoundaryGroup>) => {
  if (defaultBoundaryGroupId !== undefined) {
    return defaultBoundaryGroupId;
  }
  return boundaryGroupsArray[0]?.id ?? null;
};

export const BoundaryEditModalComponent: FC<BoundaryEditModalProps> = (props) => {
  const [action, setAction] = useState(props.defaultAction || BoundaryEditAction.CreateNewBoundary);
  const [manualMethod, setManualMethod] = useState(props.defaultManualMethod || BoundaryEditManualMethod.Selection);
  const [selectFrom, setSelectFrom] = useState(props.defaultSelectFrom ?? BoundaryEditSelectFrom.Boundary);
  const [selectedGlobalBoundaryGroupId, setSelectedGlobalBoundaryGroupId] = useState<number | null>(getInitialBoundaryGroupId(props?.defaultBoundaryGroup?.global, props.globalBoundaryGroups));
  const [selectedCustomBoundaryGroupId, setSelectedCustomBoundaryGroupId] = useState<number | null>(getInitialBoundaryGroupId(props?.defaultBoundaryGroup?.custom, props.customBoundaryGroups));
  const [t] = useTranslation();

  const isSubmitDisabled =
    (selectFrom === BoundaryEditSelectFrom.Territory && selectedCustomBoundaryGroupId === null) ||
    (selectFrom === BoundaryEditSelectFrom.Boundary && selectedGlobalBoundaryGroupId === null);

  const onSubmit = () => {
    const selectedBoundaryGroupId = selectFrom === BoundaryEditSelectFrom.Boundary ? selectedGlobalBoundaryGroupId : selectedCustomBoundaryGroupId;

    if (selectedBoundaryGroupId === null) {
      return;
    }

    props.onSubmit({
      action,
      manualMethod,
      selectedBoundaryGroupId,
    });
  };

  let labelCount = 1;
  return (
    <ModalComponent
      caption={t('Edit Territory Group')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      confirmButton={(
        <ButtonComponent
          css={submitButtonStyles}
          text={t('Continue')}
          onClick={onSubmit}
          isDisabled={isSubmitDisabled}
        />
      )}
    >
      <div css={contentStyle}>
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t('Select Desired Action')}
          </div>

          <RegularDropdownComponent
            onChange={setAction}
            options={getBoundaryEditActionOptions(t, props.boundaryGroupIsEmpty)}
            value={action}
            inPortal
          />
        </div>

        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t('Manual Method')}
          </div>

          <RegularDropdownComponent
            onChange={setManualMethod}
            options={getBoundaryEditManualMethodOptions(t)}
            value={manualMethod}
            inPortal
          />
        </div>

        {manualMethod === BoundaryEditManualMethod.Selection && (
          <div>
            <div css={panelDropdownSectionStyle}>
              <div css={labelStyle}>
                {labelCount++}. {t('Select From')}
              </div>

              <RadioGroupComponent
                css={radioGroupStyle}
                itemCommonStyle={radioItemStyle}
                onValueChange={(value: BoundaryEditSelectFrom) => setSelectFrom(value)}
                selectedValue={selectFrom}
                items={getBoundaryEditSelectFromRadioGroupOptions(t, false)}
              />
            </div>

            {selectFrom === BoundaryEditSelectFrom.Boundary && (
              <div css={panelDropdownSectionStyle}>
                <div css={labelStyle}>
                  {labelCount++}. {t('Select Boundary Type')}
                </div>

                <AutocompleteComponent
                  onChange={setSelectedGlobalBoundaryGroupId}
                  options={props.globalBoundaryGroups.map(group => ({
                    name: group.name,
                    value: group.id,
                  }))}
                  value={selectedGlobalBoundaryGroupId}
                  inPortal
                  isClearable
                />
              </div>
            )}

            {selectFrom === BoundaryEditSelectFrom.Territory && (
              <div css={panelDropdownSectionStyle}>
                <div css={labelStyle}>
                  {labelCount++}. {t('Select Territory Group')}
                </div>

                <AutocompleteComponent
                  onChange={setSelectedCustomBoundaryGroupId}
                  options={props.customBoundaryGroups.map(group => ({
                    name: group.name,
                    value: group.id,
                  }))}
                  value={selectedCustomBoundaryGroupId}
                  inPortal
                  isClearable
                />
              </div>
            )}
          </div>
        )}
      </div>
    </ModalComponent>
  );
};
