import {
  put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { type BoundaryTerritoryGroupsAction } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.actionTypes';
import { boundarySelectEditDeactivate } from './boundarySelect.actionCreators';
import { boundarySelectSelector } from './boundarySelect.selectors';
import {
  type BoundarySelectState,
  isBoundarySelectEditBoundaryTerritory,
  isBoundarySelectEditCustomBoundary,
} from './boundarySelect.state';

export function* boundarySelectSagas() {
  yield takeEvery(MAP_SETTINGS_BOUNDARIES_SET_PRIMARY_BOUNDING_GROUP_ID, deactivateBoundarySelect);
  yield takeLatest(BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS, onBoundaryTerritoryGroupRemove);
}

function* onBoundaryTerritoryGroupRemove(action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS>) {
  const boundarySelectState: BoundarySelectState = yield select<BoundarySelectState>(boundarySelectSelector);

  if (isBoundarySelectEditBoundaryTerritory(boundarySelectState) || isBoundarySelectEditCustomBoundary(boundarySelectState)) {
    if (boundarySelectState.selectionModeData?.boundaryTerritoryGroupId === action.payload.boundaryTerritoryGroup.boundaryTerritoryGroupId) {
      yield deactivateBoundarySelect();
    }
  }
}

function* deactivateBoundarySelect() {
  yield put(boundarySelectEditDeactivate());
}
