import { UploadStatus } from '../../_shared/components/uploadStatus/uploadStatus.component';
import { createUuid } from '../../_shared/utils/createUuid';
import {
  createMapForClient, type GoogleSheetMapSyncType,
} from '../map.repository';
import { type UploadItem } from './createNewMap.component';

export const createUploadItemFromFile = (file: File): UploadItem => {
  return {
    id: createUuid(),
    file,
    progress: 0,
    status: UploadStatus.InProgress,
  };
};

export const createBlankMap = (clientId: number, mapName: string, mapDescription?: string) => {
  return createMapForClient(clientId, {
    name: mapName,
    description: mapDescription,
    data_type: 'empty',
  });
};

export const createFileMap = (clientId: number, filename: string, mapName: string, mapDescription?: string) => {
  return createMapForClient(clientId, {
    name: mapName,
    description: mapDescription,
    data_type: 'file',
    data_source: filename,
  });
};

type CreateGoogleSheetMapRequestProps = Readonly<{
  clientId: number;
  dataSource: string;
  googleAccountId: string;
  mapDescription?: string;
  mapName: string;
  sheetName: string;
  spreadsheetId: string;
  uniqueKeys: number[];
  syncMethod: GoogleSheetMapSyncType;
}>;

export const createGoogleSheetMap = (requestProps: CreateGoogleSheetMapRequestProps) => {
  return createMapForClient(requestProps.clientId, {
    data_source: requestProps.dataSource,
    data_type: 'spreadsheet',
    description: requestProps.mapDescription,
    google_account_id: requestProps.googleAccountId,
    gs_id: requestProps.spreadsheetId,
    gs_sheet: requestProps.sheetName,
    name: requestProps.mapName,
    sync_type: requestProps.syncMethod,
    unique_keys: requestProps.uniqueKeys,
  });
};

export const convertGoogleSheetRowsToMaptiveRows = (rows: string[][]) =>
  rows.reduce((result: string, dataRow) => {
    const stringValues = dataRow.map(value => {
      if (value) {
        return value.toString().replace(/\r\n|\r|\n/g, ' ');
      }
      return value;
    });

    return result + stringValues.join('\t') + '\n';
  }, '');
