import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import { RangeType } from '~/_shared/utils/range/range.helpers';
import { useBoundaryGroupDemographics } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryGroupDemographics';
import { boundaryGroupsChangeName } from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import {
  isCustomGroup, useBoundaryGroupsSelector,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { getBoundaryTerritoryGroupDetails } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.repository';
import { useBoundaryTerritoryDetailsSelector } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { requestTerritoriesRefresh } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupStyle,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetFilterTreeItem } from '~/store/spreadsheetData/filtering/usePrimarySpreadsheetFilterTreeItemRequest';
import { BOUNDARY_SETTINGS_MIN_RANGES_COUNT } from './boundarySettings.common';
import {
  type BoundarySettingsGroupProps, BoundarySettingsLayout, type BoundarySettingsNumericProps,
} from './boundarySettings.component';
import {
  BOUNDARY_MAX_VALUE_ID, BOUNDARY_MIN_VALUE_ID, getBoundaryTerritoryRegularGroupsCount,
} from './boundarySettings.helpers';
import { restoreBTGDefaultColors } from './defaultBoundarySettings';

export type BoundaryTerritoryGroupPropsVariants = BoundarySettingsNumericProps | BoundarySettingsGroupProps;

export type BoundarySettingsPropsVariantsArguments = {
  boundaryTerritoryGroup?: BoundaryTerritoryGroup;
  onBoundaryTerritoryGroupUpdate: (territoryGroup: BoundaryTerritoryGroup, options?: { updateIsReset?: boolean }) => void;
  changeIsLoading: (isLoading: boolean) => void;
};

export const DEFAULT_RANGES_COUNT = 4;

export const useBoundarySettingsPropsVariants = ({
  boundaryTerritoryGroup,
  onBoundaryTerritoryGroupUpdate,
  changeIsLoading,
}: BoundarySettingsPropsVariantsArguments
) => {
  const clientId = useClientIdSelector();
  const territoryDetails = useBoundaryTerritoryDetailsSelector();
  const { getDemographic } = useBoundaryGroupDemographics();
  const primarySpreadsheetFilterTreeItem = usePrimarySpreadsheetFilterTreeItem();
  const dispatch = useDispatch();
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const originalBoundaryTerritoryGroup = boundaryTerritoryGroups.find(btg => btg.boundaryTerritoryGroupId === boundaryTerritoryGroup?.boundaryTerritoryGroupId);

  return useMemo<BoundaryTerritoryGroupPropsVariants | null>(() => {
    if (!boundaryTerritoryGroup || !originalBoundaryTerritoryGroup) {
      return null;
    }

    const boundaryGroup = boundaryGroups.find(group => group.id === boundaryTerritoryGroup.boundaryGroupId);

    const settings = boundaryTerritoryGroup.settings;

    //#region Range fill
    //////////////////////////////////////
    // BoundaryTerritoryType.Numeric
    // BoundaryTerritoryType.Density
    // BoundaryTerritoryType.Demographic
    //////////////////////////////////////
    if (
      settings.boundaryTerritoryType === BoundaryTerritoryType.Numeric ||
        settings.boundaryTerritoryType === BoundaryTerritoryType.Density ||
        settings.boundaryTerritoryType === BoundaryTerritoryType.Demographic
    ) {
      const territoryNumericalData = territoryDetails.get(boundaryTerritoryGroup.boundaryTerritoryGroupId)?.numericalData;
      const min = territoryNumericalData?.totalLowest ?? BOUNDARY_MIN_VALUE_ID;
      const max = territoryNumericalData?.totalHighest ?? BOUNDARY_MAX_VALUE_ID;

      const demographic = settings.boundaryTerritoryType === BoundaryTerritoryType.Demographic && settings.demographicId
        ? getDemographic(boundaryTerritoryGroup.boundaryGroupId, settings.demographicId)
        : null;

      const disableRangeTypeChange = demographic?.percentage ?? false;

      return {
        min,
        max,
        layout: BoundarySettingsLayout.Numeric,
        disableRangeTypeChange,
        isDecimal: !!boundaryTerritoryGroup.settings.boundaryTerritories.find(bt => !bt.custom),
        onNumberOfRangesChange: (numberOfRanges: number, style: BoundaryTerritoryGroupStyle, rangeType: RangeType) => {
          if (!clientId) {
            return;
          }

          const queryId = createUuid();
          const newBoundaryTerritoryGroup: BoundaryTerritoryGroup = {
            ...boundaryTerritoryGroup,
            settings: {
              ...boundaryTerritoryGroup.settings,
              bucketType: rangeType === RangeType.Value ? 'value-range' : 'percentage-range',
              style,
              rangesCount: numberOfRanges,
              boundaryTerritories: requestTerritoriesRefresh(
                boundaryTerritoryGroup.settings.boundaryTerritories,
                originalBoundaryTerritoryGroup.settings.boundaryTerritories
              ),
            },
          };

          changeIsLoading(true);
          getBoundaryTerritoryGroupDetails(clientId, [{
            boundaryTerritoryGroup: newBoundaryTerritoryGroup,
            boundaryTerritoryGroupQueryId: queryId,
            filter: primarySpreadsheetFilterTreeItem ? {
              filter_tree: primarySpreadsheetFilterTreeItem.filterTree,
            } : null,
          }])
            .then(response => {
              const newTerritoryDetails = response.get(queryId);
              if (!newTerritoryDetails) {
                return;
              }

              onBoundaryTerritoryGroupUpdate({
                ...newBoundaryTerritoryGroup,
                settings: {
                  ...newBoundaryTerritoryGroup.settings,
                  // ensure the ranges count to be at least 2 to have a successful API call
                  rangesCount: Math.max(getBoundaryTerritoryRegularGroupsCount(newTerritoryDetails.boundaryTerritories), BOUNDARY_SETTINGS_MIN_RANGES_COUNT),
                  boundaryTerritories: newTerritoryDetails.boundaryTerritories,
                },
              }, { updateIsReset: true });
            })
            .finally(() => {
              changeIsLoading(false);
            });
        },
        onRecalculateRangesClick: () => {
          if (!clientId) {
            return;
          }

          const queryId = createUuid();
          const newBoundaryTerritoryGroup: BoundaryTerritoryGroup = {
            ...boundaryTerritoryGroup,
            settings: {
              ...boundaryTerritoryGroup.settings,
              bucketType: 'value-range',
              boundaryTerritories: requestTerritoriesRefresh(
                boundaryTerritoryGroup.settings.boundaryTerritories,
                originalBoundaryTerritoryGroup.settings.boundaryTerritories
              ),
            },
          };

          changeIsLoading(true);
          getBoundaryTerritoryGroupDetails(clientId, [{
            boundaryTerritoryGroup: newBoundaryTerritoryGroup,
            boundaryTerritoryGroupQueryId: queryId,
            filter: primarySpreadsheetFilterTreeItem ? {
              filter_tree: primarySpreadsheetFilterTreeItem.filterTree,
            } : null,
          }])
            .then(response => {
              const newTerritoryDetails = response.get(queryId);
              if (!newTerritoryDetails) {
                return;
              }

              onBoundaryTerritoryGroupUpdate({
                ...newBoundaryTerritoryGroup,
                settings: {
                  ...newBoundaryTerritoryGroup.settings,
                  // ensure the ranges count to be at least 2 to have a successful API call
                  rangesCount: Math.max(getBoundaryTerritoryRegularGroupsCount(newTerritoryDetails.boundaryTerritories), BOUNDARY_SETTINGS_MIN_RANGES_COUNT),
                  boundaryTerritories: newTerritoryDetails.boundaryTerritories,
                },
              }, { updateIsReset: true });
            })
            .finally(() => {
              changeIsLoading(false);
            });
        },
      };
    }

    //#endregion

    //#region Group fill
    //////////////////////////////////////
    // BoundaryTerritoryType.Groups
    // BoundaryTerritoryType.Manual
    //////////////////////////////////////

    if (
      settings.boundaryTerritoryType === BoundaryTerritoryType.Groups ||
        settings.boundaryTerritoryType === BoundaryTerritoryType.Manual
    ) {
      return {
        layout: BoundarySettingsLayout.Group,
        boundaryGroupName: boundaryGroup?.name ?? '',
        onBoundaryGroupNameChange: (!boundaryGroup || !isCustomGroup(boundaryGroup)) ? undefined : (newName) => {
          dispatch(boundaryGroupsChangeName(boundaryGroup.id, newName));
        },
        onRestoreDefaultColors: () => {
          if (settings.boundaryTerritoryType === BoundaryTerritoryType.Manual) {
            onBoundaryTerritoryGroupUpdate(restoreBTGDefaultColors(boundaryTerritoryGroup));
            return;
          }

          // If BTG is of type Group, refetch all groups with their default colors from backend.
          if (!clientId) {
            return;
          }

          const queryId = createUuid();
          const newBoundaryTerritoryGroup: BoundaryTerritoryGroup = restoreBTGDefaultColors({
            ...boundaryTerritoryGroup,
            settings: {
              ...boundaryTerritoryGroup.settings,
              bucketType: 'value',
              boundaryTerritories: requestTerritoriesRefresh(
                boundaryTerritoryGroup.settings.boundaryTerritories,
                originalBoundaryTerritoryGroup.settings.boundaryTerritories
              ),
            },
          });

          changeIsLoading(true);
          getBoundaryTerritoryGroupDetails(clientId, [{
            boundaryTerritoryGroup: newBoundaryTerritoryGroup,
            boundaryTerritoryGroupQueryId: queryId,
            filter: primarySpreadsheetFilterTreeItem ? {
              filter_tree: primarySpreadsheetFilterTreeItem.filterTree,
            } : null,
          }])
            .then(response => {
              const newTerritoryDetails = response.get(queryId);
              if (!newTerritoryDetails) {
                return;
              }

              changeIsLoading(false);
              onBoundaryTerritoryGroupUpdate({
                ...newBoundaryTerritoryGroup,
                settings: {
                  ...newBoundaryTerritoryGroup.settings,
                  // ensure the ranges count to be at least 2 to have a successful API call
                  rangesCount: Math.max(getBoundaryTerritoryRegularGroupsCount(newTerritoryDetails.boundaryTerritories), BOUNDARY_SETTINGS_MIN_RANGES_COUNT),
                  boundaryTerritories: newTerritoryDetails.boundaryTerritories,
                },
              });
            })
            .finally(() => {
              changeIsLoading(false);
            });
        },
      };
    }

    //#endregion

    throw new Error(`Invalid boundary territory type: ${settings.boundaryTerritoryType}`);

  }, [boundaryGroups, boundaryTerritoryGroup, changeIsLoading, clientId, dispatch, getDemographic,
    onBoundaryTerritoryGroupUpdate, primarySpreadsheetFilterTreeItem, territoryDetails, originalBoundaryTerritoryGroup]);
};
