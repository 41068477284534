import { faRuler } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import {
  selectDistanceCalculatorDrawingMode,
  startDistanceCalculator, stopDistanceCalculator,
} from '../../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.actionCreators';
import { useDistanceCalculatorIsActiveSelector } from '../../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.selectors';
import { DistanceCalculatorDrawingModeEnum } from '../../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.state';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideMapDistanceCalculatorMenuItem: FC = () => {
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.OptionsModal);

  const distanceCalculatorActive = useDistanceCalculatorIsActiveSelector();

  const dispatch = useDispatch();

  const startDistanceCalculatorDrawingMode = (mode: DistanceCalculatorDrawingModeEnum) => {
    dispatch(startDistanceCalculator());
    dispatch(selectDistanceCalculatorDrawingMode(mode));
  };

  const toggleDistanceCalculator = () => {
    if (distanceCalculatorActive) {
      dispatch(stopDistanceCalculator());
    }
    else {
      openModal({
        allowedPages: ALL_MAP_RELATED_PAGES,
        title: t('Measure Straight-line Distance on Map'),
        instructions: t('Click on the measurement option you want and then click on the map to begin your measurement from the starting location.'),
        options: [
          {
            name: t('Single Point Distance Measurement (A to B)'),
            onChosen: () => startDistanceCalculatorDrawingMode(DistanceCalculatorDrawingModeEnum.SinglePoint),
          },
          {
            name: t('Multiple Point Distance Measurement (A to B to C to D)'),
            onChosen: () => startDistanceCalculatorDrawingMode(DistanceCalculatorDrawingModeEnum.MultiplePoint),
          },
        ],
        width: 600,
      });
    }
  };

  return (
    <RightSideMapToolsMenuItem
      icon={faRuler}
      active={distanceCalculatorActive}
      onClick={toggleDistanceCalculator}
      tooltip={t('Distance Calculator')}
    />
  );
};
