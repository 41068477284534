// Enum is ordered
export enum BoundaryCreateOptimizedStatus {
  ProcessingStarted = 'Processing Started',
  ValidatingRequest = 'Validating Request',
  InitializingTerritories = 'Initializing Territories',
  BalancingTerritories = 'Balancing Territories',
  RebalancingTerritories = 'Rebalancing Territories',
  FinalizingTheBalancingOfTerritories = 'Finalizing the Balancing of Territories',
  ProcessingFinished = 'Processing Finished',
  Finished = 'Finished',
}

export const INITIAL_BOUNDARY_CREATE_OPTIMIZED_STATUS = BoundaryCreateOptimizedStatus.ProcessingStarted;

export type BoundaryCreateOptimizedItem = {
  id: number;
  status: BoundaryCreateOptimizedStatus;
  startedOnCurrentTab: boolean;
};

export type BoundaryCreateOptimizedState = Readonly<{
  pendingItems: ReadonlyArray<BoundaryCreateOptimizedItem>;
}>;
