import { type MatchupDataAction } from '~/store//matchupData/matchupData.action';
import { MATCHUP_UPDATE_SUCCESS } from '~/store//matchupData/matchupData.actionTypes';
import { type SpreadsheetDescriptor } from '../../_shared/types/map';
import { type MapInfoAction } from './mapInfo.action';
import { mapInfoSetV4MigrationDone } from './mapInfo.actionCreators';
import {
  MAP_INFO_FETCH_DATA_ERROR,
  MAP_INFO_FETCH_DATA_REQUEST,
  MAP_INFO_FETCH_DATA_SUCCESS,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST,
  MAP_INFO_SNAPSHOT_REMOVE_SUCCESS,
  MAP_INFO_UPDATE_BAD_DATA_COUNT,
  MAP_INFO_UPDATE_ERROR,
  MAP_INFO_UPDATE_PRIVACY,
  MAP_INFO_UPDATE_REQUEST,
  MAP_INFO_UPDATE_SUCCESS,
  MAP_UPDATE_SNAPSHOT_SUCCESS,
} from './mapInfo.actionTypes';
import { type MapInfoState } from './mapInfo.state';

const initialState: MapInfoState = {
  isLoading: false,
  isPresentational: false,
  data: null,
};

export const mapInfoReducer = (state = initialState, action: MapInfoAction | MatchupDataAction): MapInfoState => {
  if (mapInfoSetV4MigrationDone.match(action)) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        v4MigrationDone: action.payload.isDone,
      },
    };
  }

  switch (action.type) {
    case MAP_INFO_FETCH_DATA_REQUEST: {
      return {
        ...state,
        isPresentational: false,
        isLoading: true,
        data: null,
      };
    }

    case MAP_INFO_FETCH_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case MAP_INFO_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data || null,
      };
    }

    case MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST: {
      return {
        ...state,
        isPresentational: true,
        isLoading: true,
        data: null,
      };
    }

    case MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case MAP_INFO_UPDATE_REQUEST: {
      return {
        ...state,
        updateErrors: undefined,
        isLoading: true,
      };
    }

    case MAP_INFO_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        updateErrors: action.payload.errors,
      };
    }

    case MAP_INFO_UPDATE_SUCCESS: {
      const newState = {
        ...state,
        isLoading: false,
      };

      if (newState.data) {
        newState.data = {
          ...newState.data,
          name: action.payload.request.name ?? newState.data.name,
          description: action.payload.request.description ?? newState.data.description,
        };
      }

      return newState;
    }

    case MATCHUP_UPDATE_SUCCESS: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          isMatchupRequired: false,
        },
      };
    }

    case MAP_UPDATE_SNAPSHOT_SUCCESS: {
      if (!state.data || !state.data.parentMap) {
        return state;
      }

      const updatedParentSnapshots = [...state.data.parentMap.snapshots].map(snapshot => {
        if (snapshot.id !== action.payload.updateModel.id) {
          return snapshot;
        }

        return {
          ...snapshot,
          name: action.payload.updateModel.name,
          description: action.payload.updateModel.description,
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          name: action.payload.updateModel.name,
          description: action.payload.updateModel.description,
          parentMap: {
            ...state.data.parentMap,
            snapshots: updatedParentSnapshots,
          },
        },
      };
    }

    case MAP_INFO_SNAPSHOT_REMOVE_SUCCESS: {
      if (!state.data) {
        return state;
      }

      const newParentMapSnapshots = state.data.parentMap?.snapshots
        .filter(snapshot => snapshot.id !== action.payload.snapshotId);

      const newOwnSnapshots = state.data.snapshots
        ?.filter(snapshot => snapshot.id !== action.payload.snapshotId);

      return {
        ...state,
        data: {
          ...state.data,
          snapshots: newOwnSnapshots,
          parentMap: state.data.parentMap && newParentMapSnapshots
            ? {
              ...state.data.parentMap,
              snapshots: newParentMapSnapshots,
            }
            : undefined,
        },
      };
    }

    case MAP_INFO_UPDATE_BAD_DATA_COUNT: {
      if (!state.data) {
        return state;
      }

      const newSpreadsheets: Array<SpreadsheetDescriptor> = state.data.spreadsheets.map(spreadsheet => {
        if (spreadsheet.spreadSheetId !== action.payload.virtualSpreadsheetId || !spreadsheet.geocoding) {
          return spreadsheet;
        }

        return {
          ...spreadsheet,
          geocoding: {
            ...spreadsheet.geocoding,
            bad_data: action.payload.badDataCount,
          },
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          spreadsheets: newSpreadsheets,
        },
      };
    }

    case MAP_INFO_UPDATE_PRIVACY: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          privacy: action.payload.newPrivacyLevel,
        },
      };
    }

    default:
      return state;
  }
};
