import {
  forwardRef, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { getBoundingBox } from '~/_shared/utils/gis/boundingBox.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNull } from '~/_shared/utils/typeGuards';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { mapComponentSetZoomToBounds } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import {
  frontendStateDirectionsSetWaypoints,
  routeCollapsed,
  routeExpanded,
  routeLegCollapsed,
  routeLegExpanded,
} from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import {
  useDirectionsExpandedRouteIdsSelector, useRoutesUiDataSelector,
} from '~/store/frontendState/mapTools/directions/directions.selectors';
import {
  modifyRouteInDirections,
  removeRouteFromDirections,
} from '~/store/mapSettings/directions/mapSettingsDirections.actionCreators';
import { useMapSettingsDirectionsRoutesSelector } from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { getRouteUiDataForWaypoint } from '../directions.helper';
import { DirectionsListingComponent } from './directionsListing.component';

type DirectionsListingContainer = Readonly<{
  isPrintVersion?: boolean;
}>;

export const DirectionsListingContainer = forwardRef<HTMLDivElement, DirectionsListingContainer>((props, forwardedRef) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const allowedExportDataTools = usePublicMapSettingsAllowedExportDataToolsSelector();
  const expandedRouteIds = useDirectionsExpandedRouteIdsSelector();
  const isPresentationalMap = useIsMapPresentationalSelector();
  const routes = useMapSettingsDirectionsRoutesSelector();
  const routesUiData = useRoutesUiDataSelector();
  const { openModal: openExportModal } = useModal(ModalType.ExportDirections);

  const toggleLeg = (routeId: string, legIndex: number) => {
    const route = routesUiData.get(routeId);
    if (!route) {
      return;
    }
    return route.expandedLegsIndexes.has(legIndex)
      ? dispatch(routeLegCollapsed(routeId, legIndex))
      : dispatch(routeLegExpanded(routeId, legIndex));
  };

  const toggleRoute = (routeId: string) => expandedRouteIds.has(routeId)
    ? dispatch(routeCollapsed(routeId))
    : dispatch(routeExpanded(routeId));

  const loadDirections = (routeId: string) => {
    const selectedRoute = routes.find(r => r.id === routeId);
    const waypoints = selectedRoute?.waypoints;
    if (!waypoints) {
      return;
    }

    dispatch(frontendStateDirectionsSetWaypoints(waypoints, false));
  };

  const updateRouteDetails = (routeId: string, name: string, color: ColorResult) => {
    const route = routes.find(r => r.id === routeId);
    if (route) {
      dispatch(modifyRouteInDirections(routeId, { ...route, name, color: guaranteeHash(color.hex) }));
    }
  };

  const removeRoute = useMemo(() => {
    if (!mapId) {
      return undefined;
    }
    return (route: MapSettingsRoute) => dispatch(removeRouteFromDirections(route, mapId));
  }, [dispatch, mapId]);

  const zoomToRoute = useCallback((routeId: string) => {
    const selectedRoute = routes.find(r => r.id === routeId);
    const waypointLatLngs = selectedRoute?.waypoints.map((waypoint, waypointIndex) => {
      if (waypoint.latLng) {
        return waypoint.latLng;
      }

      const routeUiLatLng = getRouteUiDataForWaypoint(waypointIndex, routeId, routesUiData, t)?.latLng;
      if (routeUiLatLng) {
        return routeUiLatLng;
      }

      return null;
    }).filter(notNull);

    if (!waypointLatLngs) {
      return;
    }
    const boundingBoxAroundWaypoints = getBoundingBox(waypointLatLngs);

    dispatch(mapComponentSetZoomToBounds(boundingBoxAroundWaypoints));
  }, [dispatch, routes, routesUiData, t]);

  const allowExportLocations = useMemo(() => (
    (!isPresentationalMap || hasFeatureOrToolAllowedPresentationalMapExport(
      MapTool.Routing, allowedExportDataTools
    )) && !props.isPrintVersion
  ), [allowedExportDataTools, isPresentationalMap, props.isPrintVersion]);

  const onRouteExport = useCallback((routeId: string) => {
    const route = routes.find(route => route.id === routeId);

    if (!route) {
      return;
    }

    openExportModal({
      showSelectRoutesSection: false,
      selectedRouteIds: [routeId],
    });
  }, [openExportModal, routes]);

  return (
    <DirectionsListingComponent
      ref={forwardedRef}
      isPrintVersion={props.isPrintVersion}
      expandedRouteIds={expandedRouteIds}
      onLegToggle={toggleLeg}
      onLoadDirections={loadDirections}
      onRouteEdit={updateRouteDetails}
      onRouteRemove={removeRoute}
      onRouteToggle={toggleRoute}
      onZoomToRoute={zoomToRoute}
      routes={routes}
      routesUiData={routesUiData}
      unitSystem={UnitSystem.imperial}
      onRouteExport={allowExportLocations ? onRouteExport : undefined}
    />
  );
});
