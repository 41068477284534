import type { MemberBasicInfo } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import {
  type CreatorResponse, type GoogleSheetMapSyncType, type LayeringType,
} from '../../map/map.repository';
import { type BoundaryCreateOptimizedItem } from '../../store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import { type GeocodingResponse } from './geocoding/geocoding.response';

export type MapId = number;

export type Map = Readonly<{
  id: MapId;
  parentId?: number;
  name: string;
  description?: string | null;
}>;

export enum MapPrivacyLevel {
  Private = 'private',
  Public = 'public',
  PasswordProtected = 'password_protected',
}

export type VersionDescriptor = {
  snapshot: number;
  version: number;
};

export type RealSpreadsheetVersions = {
  realSpreadSheetId: number;
  name: string;
  versions: {
    current: VersionDescriptor;
    latest: VersionDescriptor;
  };
};

export type GoogleSheetBasedRealSpreadsheetInfo = {
  autoSyncEnabled: boolean;
  googleAccountId: string | null;
  googleSheetId: string;
  googleSpreadsheetId: string;
  lastSyncType: GoogleSheetMapSyncType;
  realSpreadsheetId: number;
  spreadsheetTitle: string;
};

export type GoogleSheetBasedSpreadsheetInfo = {
  realSpreadsheets: ReadonlyArray<GoogleSheetBasedRealSpreadsheetInfo>;
  spreadsheetId: number;
};

export type OwnerMapInfo = Readonly<{
  created: CreatorResponse;
}>;

export type MapInfo = BaseMap & Readonly<{
  parentMap?: ParentMap;
  snapshots?: ReadonlyArray<MapSnapshot>;
}>;

export type MapSource = 'salesforce' | null;

type BaseMap = OwnerMapInfo & Readonly<{
  description: string | null;
  encrypted: boolean;
  googleSheets: ReadonlyArray<GoogleSheetBasedSpreadsheetInfo> | null;
  id: MapId;
  importing: boolean;
  isLayered: boolean;
  isMatchupRequired: boolean;
  layering?: LayeringType;
  name: string;
  privacy: MapPrivacyLevel;
  secureInProcess: boolean;
  settings: Record<string, string>;
  sharedUsers: ReadonlyArray<MemberBasicInfo>;
  shareId: string;
  spreadsheets: ReadonlyArray<SpreadsheetDescriptor>;
  team: null;
  territoryGeneration: ReadonlyArray<BoundaryCreateOptimizedItem>;
  updated: CreatorResponse;
  v4MigrationDone?: boolean;
  versions?: ReadonlyArray<RealSpreadsheetVersions>;
  views: number;
  source: MapSource;
}>;

export type MapSnapshot = BaseMap & Readonly<{
  parentMap: number;
  snapshots: undefined;
}>;

export type ParentMap = BaseMap & Readonly<{
  parentMap: undefined;
  snapshots: ReadonlyArray<MapSnapshot>;
}>;

export type SpreadsheetDescriptor = {
  spreadSheetId: number;
  isEncrypted: boolean;
  isPrimary: boolean;
  privileges: 'modify';
  realSpreadsheets: ReadonlyArray<{ realSpreadSheetId: number }>;
  geocoding?: GeocodingResponse;
};
