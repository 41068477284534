import { css } from '@emotion/react';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ExportFileButtonContainer } from '~/_shared/components/exportFileButton/exportFileButton.container';
import { type ProximityType } from '~/_shared/types/proximity/proximity.enums';
import { type Proximity } from '~/_shared/types/proximity/proximity.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsOpen } from '~/_shared/utils/hooks/useIsOpen';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { SidebarSearchHeaderComponent } from '~/sidebar/sidebarApps/mapTools/sidebarSearchHeader.component';
import { MapToolHeaderButtonComponent } from '~/sidebar/sidebarApps/mapToolWrapper/mapTooHeaderButton.component';
import { MapToolWrapperComponent } from '~/sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';
import { ProximityEmptyStateComponent } from '../listing/proximityEmptyState.component';
import { ProximityListingContainer } from '../listing/proximityListing.container';
import { ProximityPanelFormContainer } from './form/proximityPanelForm.container';

const formStyle = css({
  padding: '10px 16px',
});

const footerStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  height: '100%',
});

type ProximityPanelProps = Readonly<{
  proximityList: ReadonlyArray<Proximity>;
  proximityType: ProximityType;
  showAdminControls: boolean;

  onExportFile?: () => void;
  onSettingsButtonClick: () => void;
}>;

export const ProximityPanelComponent: FC<ProximityPanelProps> = props => {
  const [query, setQuery] = useState('');
  const queryInputIsOpen = useIsOpen();
  const [t] = useTranslation();

  const { onExportFile } = props;

  const onQueryTriggerClick = useCallback(() => {
    if (queryInputIsOpen.isOpen) {
      setQuery('');
    }
    queryInputIsOpen.toggleOpen();
  }, [queryInputIsOpen]);

  const proximityList = useMemo(() => {
    if (isTextEmpty(query)) {
      return props.proximityList;
    }
    else {
      return props.proximityList.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));
    }
  }, [props.proximityList, query]);

  return (
    <MapToolWrapperComponent
      header={props.showAdminControls && (
        <MapToolHeaderButtonComponent
          prefixIcon={faCog}
          onClick={props.onSettingsButtonClick}
          text={t('Customize Metrics')}
        />
      )}
      footer={proximityList.length > 0 && onExportFile && (
        <div css={footerStyle}>
          <ExportFileButtonContainer
            onExportFileClick={onExportFile}
          />
        </div>
      )}
    >
      <ProximityPanelFormContainer
        proximityType={props.proximityType}
        proximityList={proximityList}
        css={formStyle}
        showLabelsHiddenWarningDueToPerformance={false} //TODO: decide if we want to keep this
        showLabelsHiddenWarningDueToTooSmall={false}
      />

      <SidebarSearchHeaderComponent
        isOpen={queryInputIsOpen.isOpen}
        onChangeSearchPhrase={setQuery}
        onClick={onQueryTriggerClick}
        searchPhrase={query}
        searchPlaceholder={t('Find Proximity')}
        title={t('Selected Proximities')}
      >
        {proximityList.length ? (
          <ProximityListingContainer
            proximityList={proximityList}
          />
        ) : (
          <ProximityEmptyStateComponent
            isSearchActive={!isTextEmpty(query) && !!props.proximityList.length}
            proximityType={props.proximityType}
          />
        )}
      </SidebarSearchHeaderComponent>

    </MapToolWrapperComponent>
  );
};
