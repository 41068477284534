import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useIsProximityExportAllowed } from '~/proximity/useIsProximityExportAllowed';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { type Proximity } from '../../_shared/types/proximity/proximity.types';
import { useProcessMarkersForRouting } from '../../map/map/lassoTool/optimizeRouteBetweenPoints/useProcessMarkersForRouting';
import { useGetMarkersInArea } from '../../map/map/lassoTool/useGetMarkersInArea';
import {
  useLatLngSpreadsheetData, useSpreadSheetData,
} from '../../map/map/useSpreadsheetData.hook';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { modifyProximity } from '../../store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import {
  hideProximity, showProximity,
} from '../../store/mapSettings/toolsState/visibility/visibility.actionCreators';
import { useHiddenProximityIdsSelector } from '../../store/mapSettings/toolsState/visibility/visibility.selectors';
import { type ProximityEditProperties } from '../edit/proximityEdit.component';
import {
  createProximityFilterArgumentsFromProximity, getUpdatedProximity,
} from '../proximity.helpers';
import { useProximityRemove } from '../useProximityRemove';
import { useZoomToProximity } from '../useZoomToProximity';
import { ProximityListingComponent } from './proximityListing.component';

type ProximityListingContainer = Readonly<{
  proximityList: ReadonlyArray<Proximity>;
}>;

export const ProximityListingContainer: FC<ProximityListingContainer> = (props) => {
  const { proximityList } = props;
  const { openModal: openProximityEditModal, closeModal: closeProximityEditModal } = useModal(ModalType.ProximityEdit);
  const { openModal: openExportProximityDataModal } = useModal(ModalType.ExportProximityData);
  const { openModal: openExportContainedBoundariesModal } = useModal(ModalType.ExportContainedBoundaryData);
  const { onProximityRemove } = useProximityRemove();
  const zoomProximity = useZoomToProximity();
  const hiddenProximityIds = useHiddenProximityIdsSelector();
  const { getMarkersInArea } = useGetMarkersInArea();
  const { spreadsheetData } = useSpreadSheetData();
  const latLngLookup = useLatLngSpreadsheetData();
  const { processSelectedData } = useProcessMarkersForRouting();
  const isExportAllowed = useIsProximityExportAllowed();
  const dispatch = useDispatch();
  const isMapPresentational = useIsMapPresentationalSelector();

  const onProximityChange = useCallback(
    (proximity: Proximity) => dispatch(modifyProximity(proximity)), [dispatch]);

  const updateProximity = useCallback((proximity: Proximity, proximityProperties: ProximityEditProperties) => {
    onProximityChange(getUpdatedProximity(proximity, proximityProperties));
  }, [onProximityChange]);

  const startProximityEdit = useCallback((id: string) => {
    const proximity = proximityList.find(p => p.id === id);
    if (!proximity) {
      return;
    }

    const onSubmit = (proximityProperties: ProximityEditProperties) => {
      updateProximity(proximity, proximityProperties);
      closeProximityEditModal();
    };

    openProximityEditModal({ onSubmit, proximity });
  }, [closeProximityEditModal, openProximityEditModal, proximityList, updateProximity]);

  const toggleRadiusVisibility = useCallback((id: string) => {
    if (hiddenProximityIds.includes(id)) {
      dispatch(showProximity(id));
    }
    else {
      dispatch(hideProximity(id));
    }
  }, [dispatch, hiddenProximityIds]);

  const getOnExportLocationsClick = useCallback(() => (
    isExportAllowed
      ? ((proximityId: string) => {
        const proximity = proximityList.find(proximity => proximity.id === proximityId);

        if (!proximity) {
          return;
        }

        openExportProximityDataModal({
          selectedProximityIds: [proximity.id],
          showSelectProximitiesSection: false,
        });
      })
      : undefined
  ), [isExportAllowed, openExportProximityDataModal, proximityList]);

  const onExportContainedBoundaries = useCallback((proximityId: string) => {
    const proximity = proximityList.find(proximity => proximity.id === proximityId);

    if (!proximity) {
      return;
    }

    openExportContainedBoundariesModal({
      filter: {
        type: 'proximity',
        proximity,
      },
    });
  }, [openExportContainedBoundariesModal, proximityList]);

  const handleExportContainedBoundaries = isMapPresentational ? undefined : onExportContainedBoundaries;

  const getRoute = useCallback(async (proximityId: string) => {
    const proximity = proximityList.find(p => p.id === proximityId);
    if (!proximity) {
      return;
    }

    const proximityArguments = createProximityFilterArgumentsFromProximity(
      proximity,
      latLngLookup,
      null,
      spreadsheetData,
    );
    const markersInAreaPerSpreadsheet = await getMarkersInArea({
      useMainFilters: true,
      circles: proximityArguments.circles,
      multiPolygons: proximityArguments.multiPolygons,
    });
    processSelectedData(markersInAreaPerSpreadsheet);
  }, [getMarkersInArea, latLngLookup, processSelectedData, proximityList, spreadsheetData]);

  return (
    <ProximityListingComponent
      hiddenProximityIds={hiddenProximityIds}
      proximityList={proximityList}
      toggleRadiusVisibility={toggleRadiusVisibility}
      getOnExportLocationsClick={getOnExportLocationsClick}
      onProximityEdit={!isMapPresentational ? startProximityEdit : undefined}
      onProximityGetRoute={getRoute}
      onProximityZoom={zoomProximity.zoomById}
      onRemoveProximity={onProximityRemove}
      onExportContainedBoundaries={handleExportContainedBoundaries}
    />
  );
};
