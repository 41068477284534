import {
  type FC, useCallback, useState,
} from 'react';
import {
  type GroupingColumn, type GroupingColumnNumeric,
} from '../../_shared/types/grouping/grouping';
import { type SpreadsheetColumn } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useStorageService } from '../../_shared/utils/storageService';
import { type GroupChangeRequest } from '../useGroupColumnData';
import { GroupingSettingColumn } from './groupingSettingColumn.component';
import {
  checkIfGroupingSettingColumnIsExpanded,
  storeIsGroupingSettingColumnExpanded,
} from './groupingSettingColumn.helpers';

type GroupingSettingColumnContainerProps = Readonly<{
  columns: SpreadsheetColumn[];
  selected: GroupingColumn | null;
  id: string;

  onChange: (columnChange: GroupChangeRequest) => void;
  onGroupColumnRemove?: (column: GroupingColumn) => void;
  onNumericGroupColumnSettingsClick?: (column: GroupingColumnNumeric) => void;
}>;

export const GroupingSettingColumnContainer: FC<GroupingSettingColumnContainerProps> = (props) => {
  const storageService = useStorageService();
  const [isExpanded, setIsExpanded] = useState(() => checkIfGroupingSettingColumnIsExpanded(props.id, storageService));

  const onIsExpandedChange = useCallback((isExpandedArg: boolean) => {
    storeIsGroupingSettingColumnExpanded(props.id, isExpandedArg, storageService);
    setIsExpanded(isExpandedArg);
  }, [props.id, storageService]);

  return (
    <GroupingSettingColumn
      {...props}
      isExpanded={isExpanded}
      onIsExpandedChange={onIsExpandedChange}
    />
  );
};
