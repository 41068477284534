import { notNull } from '~/_shared/utils/typeGuards';
import { UNDO_MODEL_VERSIONS } from '~/undo/undo.constants';
import { anyVersionUndoItemToCurrentVersionUndoItem } from '~/undo/undoItemVersionConverters/undoItemVersionConverter';
import {
  type LocalStoredUndoItem, type LocalStoredUndoItemAnyVersion, type TimestampedLocalStoredUndoItem, type UndoItem,
} from '../../_shared/types/undo/undoItem';
import {
  BYTES_IN_MEGABYTE, getObjectStringMemorySizeInBytes,
} from '../../_shared/utils/memory/memorySize.helpers';
import { type StorageService } from '../../_shared/utils/storageService';

const MAX_UNDO_ITEMS = 30;
const MAX_ITEM_SIZE_IN_BYTES = BYTES_IN_MEGABYTE;
const LOCAL_STORAGE_UNDO_ITEMS_SIZE_LIMIT = 4 * BYTES_IN_MEGABYTE;

export const sortHistoryItems = <T extends { timestamp: string }>(items: T[]): T[] => {
  return items.sort((a, b) => a.timestamp > b.timestamp ? -1 : a.timestamp === b.timestamp ? 0 : 1);
};

export const limitHistoryItems = (items: UndoItem[]): UndoItem[] => {
  return items.slice(0, MAX_UNDO_ITEMS);
};

const addItemToHistory = (storageService: StorageService, item: LocalStoredUndoItemAnyVersion) => {
  if (getObjectStringMemorySizeInBytes(item) > MAX_ITEM_SIZE_IN_BYTES) {
    return;
  }

  const history = getHistoryItemsAnyVersion(storageService);
  history.unshift(item);

  let historySize = getObjectStringMemorySizeInBytes(history);
  while (historySize > LOCAL_STORAGE_UNDO_ITEMS_SIZE_LIMIT) {
    history.splice(-1);
    historySize = getObjectStringMemorySizeInBytes(history);
  }

  setHistoryItems(storageService, sortHistoryItems(history));
};

export const removeUndoItemFromHistory = (storageService: StorageService, item: UndoItem) => {
  const history = getHistoryItemsAnyVersion(storageService);
  setHistoryItems(storageService, sortHistoryItems(history.filter((undoItem) => !(undoItem.type === item.type && undoItem.timestamp === item.timestamp))));
};

const setHistoryItems = (storageService: StorageService, historyItems: LocalStoredUndoItemAnyVersion[]) => {
  const history = JSON.stringify(historyItems);
  storageService.setLocalStorageItem('undo-history', history);
};

const getHistoryItemsAnyVersion = (storageService: StorageService): LocalStoredUndoItemAnyVersion[] => {
  const history = storageService.getLocalStorageItem('undo-history');
  if (!history) {
    return [];
  }
  const historyItems: LocalStoredUndoItemAnyVersion[] = JSON.parse(history);
  if (!historyItems) {
    return [];
  }
  return historyItems;
};

export const getHistoryItems = (storageService: StorageService): TimestampedLocalStoredUndoItem[] => {
  const history = storageService.getLocalStorageItem('undo-history');
  if (!history) {
    return [];
  }
  const itemsFromLocalStorage = getHistoryItemsAnyVersion(storageService);

  const historyItems = itemsFromLocalStorage
    .map(anyVersionItem => anyVersionUndoItemToCurrentVersionUndoItem(anyVersionItem))
    .filter(notNull);

  if (!historyItems) {
    return [];
  }
  return historyItems;
};

export const createHistoryItem = (storageService: StorageService, payload: LocalStoredUndoItem) => {
  const timestamp = new Date().toISOString();
  const version = UNDO_MODEL_VERSIONS[payload.type][0];
  addItemToHistory(storageService, { ...payload, timestamp, version });
};
