import {
  useCallback,
  useRef, useState,
} from 'react';
import { type ApiError } from '../api/apiError.helpers';
import { createUuid } from '../createUuid';

export const useAjaxCall = <TVariables extends any[], TData = unknown>(ajaxCall: (
  ...variables: TVariables
) => Promise<TData>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [data, setData] = useState<TData | null>(null);
  const lastInvokeId = useRef<string | null>(null);

  const invokeAjax = useCallback((...params: TVariables): Promise<TData> => {
    const localInvokeId = createUuid();
    lastInvokeId.current = localInvokeId;
    setIsLoading(true);
    setError(null);

    return ajaxCall(...params)
      .then((res: TData) => {
        if (localInvokeId === lastInvokeId.current) {
          setData(res);
        }
        return res;
      })
      .catch((e: ApiError) => {
        if (localInvokeId === lastInvokeId.current) {
          setError(e);
        }
        throw e;
      })
      .finally(() => {
        if (localInvokeId === lastInvokeId.current) {
          setIsLoading(false);
        }
      });
  }, [ajaxCall]);

  return {
    isLoading,
    error,
    data,
    invokeAjax,
  };
};
