import {
  css, type SerializedStyles,
} from '@emotion/react';
import { forwardRef } from 'react';
import {
  ColorPickerAlignment, type ColorPickerHolderMethods,
} from '../colorPickerHolder/colorPickerHolder.component';
import { ColorPickerHolderWithPresetColorsContainer } from '../colorPickerHolder/colorPickerHolderWithPresetColors.container';
import { type MaptiveColorPickerProps } from '../maptiveColorPicker/maptiveColorPicker.component';
import { ColorWheelComponent } from './colorWheel.component';

const contentStyle = css({ display: 'flex' });

type ColorPickerWithWheelProps = MaptiveColorPickerProps & Readonly<{
  onOpen?: () => void;
  contentStyle?: SerializedStyles;
  alignment?: ColorPickerAlignment;
}>;

export const ColorPickerWithWheelComponent = forwardRef<ColorPickerHolderMethods, ColorPickerWithWheelProps>((props, ref) => (
  <ColorPickerHolderWithPresetColorsContainer
    ref={ref}
    {...props}
    alignment={props.alignment ?? ColorPickerAlignment.BottomCenter}
    contentStyle={css([contentStyle, props.contentStyle])}
    isFixed
    selectedColor={props.selectedColor}
  >
    <ColorWheelComponent />
  </ColorPickerHolderWithPresetColorsContainer>
));
