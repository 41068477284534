import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn';
import {
  faArrowDown,
  faArrowUp,
  faBan,
  faCheck,
  faLockAlt,
  faSignOut,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import {
  ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  canMemberManageClient,
  isClientAdmin,
  isClientOwner,
} from '~/clientTeamManagement/memberRoles.helpers';
import {
  type MemberRole,
  MemberRoles,
} from '../memberRole.type';
import { getTeamMemberLimitProhibitsAdd } from '../teamManagement.helpers';
import {
  MemberStatus, type TeamMemberLimitsData,
} from '../teamManagement.repository';
import { type InvitationAndMemberData } from '../teamManagementModal.container';

type CurrentMemberData = Readonly<{
  id: number | null;
  role: MemberRole;
}>;

type TeamManagementMenuComponentProps = {
  currentMemberData: CurrentMemberData;
  isLoading: boolean;
  member: InvitationAndMemberData;
  passwordResetEmailSent: boolean;
  limits: TeamMemberLimitsData;

  onChangeRole: (role: MemberRole) => void;
  onDeleteUser: () => void;
  onEnableOrDisableUser: () => void;
  onLoginAsUser: () => void;
  onRevokeInvite: () => void;
  onSentPasswordRecoveryEmail: () => void;
};

export const TeamManagementMenuComponent: FC<TeamManagementMenuComponentProps> = (props) => {
  const {
    currentMemberData,
    isLoading,
    member,
    passwordResetEmailSent,
    onLoginAsUser,
    onSentPasswordRecoveryEmail,
    onEnableOrDisableUser,
    onDeleteUser,
    onChangeRole,
    onRevokeInvite,
    limits,
  } = props;
  const [t] = useTranslation();

  const memberCurrentUser = currentMemberData.id === member.id;
  const isMemberEnabled = member.status === MemberStatus.ENABLED;
  const atMembersLimit = getTeamMemberLimitProhibitsAdd(limits);
  const allowLoginAsUser = !memberCurrentUser && !isClientOwner(member) && (canMemberManageClient(currentMemberData.role));
  const allowSendPasswordReset = isMemberEnabled && !passwordResetEmailSent;
  const allowDemote = !memberCurrentUser && !isClientOwner(member) && isClientAdmin(member) && canMemberManageClient(currentMemberData.role);
  const allowPromoteToAdmin = member.role === MemberRoles.INDEPENDENT_MEMBER;
  const isPasswordResetSent = isMemberEnabled && passwordResetEmailSent;
  const allowPromoteToOwner = isMemberEnabled && isClientAdmin(member) && isClientOwner(currentMemberData);
  const deleteAllowed = !memberCurrentUser && canMemberManageClient(currentMemberData.role) && !isClientOwner(member);
  const allowDisableEnable = (isMemberEnabled || (!isMemberEnabled && !atMembersLimit)) && !memberCurrentUser && canMemberManageClient(currentMemberData.role) && !isClientOwner(member);

  const actions: { icon: IconProp; onClick?: () => void; text: string; isDestructive?: boolean; isDisabled?: boolean }[] = [
    ...(allowLoginAsUser ? [{ icon: faSignOut, onClick: onLoginAsUser, text: t('Login as User') }] : []),
    ...(allowSendPasswordReset ? [{ icon: faLockAlt, onClick: onSentPasswordRecoveryEmail, text: t('Send Password Reset') }] : []),
    ...(isPasswordResetSent ? [{ icon: faCheck, text: t('Password Reset Sent!') }] : []),
    ...(allowPromoteToOwner ? [{ icon: faArrowUp, onClick: () => onChangeRole(MemberRoles.COMPANY_OWNER), text: t('Make New Account Owner') }] : []),
    ...(allowPromoteToAdmin ? [{ icon: faArrowUp, onClick: () => onChangeRole(MemberRoles.COMPANY_ADMIN), text: t('Promote to Admin') }] : []),
    ...(allowDemote ? [{ icon: faArrowDown, onClick: () => onChangeRole(MemberRoles.INDEPENDENT_MEMBER), text: t('Demote to Member') }] : []),
    ...(allowDisableEnable ? [{ icon: faToggleOn, onClick: onEnableOrDisableUser, text: isMemberEnabled ? t('Disable User') : t('Enable User') }] : []),
    ...(deleteAllowed ? [{ icon: faTrash, onClick: onDeleteUser, text: t('Delete User'), isDestructive: true }] : []),
  ];

  return (
    <>
      {isLoading && (
        <OverlayLoaderComponent
          loaderSize={45}
        />
      )}
      {member.id ? (
        actions.map((action, index) => (
          <ContextMenuItemCoreComponent key={index}>
            <ContextMenuDefaultItem
              icon={action.icon}
              onClick={action.onClick}
              isDestructive={action.isDestructive}
              isDisabled={action.isDisabled}
            >
              {action.text}
            </ContextMenuDefaultItem>
          </ContextMenuItemCoreComponent>
        ))
      ) : (
        <ContextMenuItemCoreComponent>
          <ContextMenuDefaultItem
            icon={faBan}
            onClick={onRevokeInvite}
          >
            {t('Revoke Invite')}
          </ContextMenuDefaultItem>
        </ContextMenuItemCoreComponent>
      )}
    </>
  );
};
