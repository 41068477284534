import {
  type FC, useEffect, useState,
} from 'react';
import { useAddDataRow } from '~/data/addDataRow/useAddDataRow';
import { type ModalProps } from '~/modal/modalType.enum';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import {
  type EditDataTableRowWithColumnId,
  getEditDataTableRowsWithColumnIdsFromMatchup,
} from '../../../customizeLocationPanel/editLocationModal/editLocation.helpers';
import { useTranslation } from '../../utils/hooks';
import { isTextEmpty } from '../../utils/text/text.helpers';
import { EditLocationModalComponent } from '../editLocationModal/editLocationModal.component';
import {
  type ChangeableSettingRow, type SettingTextData,
} from '../settingsTable/settingsTable.types';

export const AddMultipleLocationsModalContainer: FC<ModalProps> = (props) => {
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const matchupData = useMatchupDataSelector();
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const [rows, setRows] = useState<EditDataTableRowWithColumnId[]>(() => getEditDataTableRowsWithColumnIdsFromMatchup(matchupData, primarySpreadsheetId));
  const { addNewDataRow, isLoading, isError } = useAddDataRow();

  const createNewLocation = async () => {
    const newLocation = rows.reduce((acc: { [columnId: string]: string }, cur) => {
      acc[cur.columnId] = cur.value;
      return acc;
    }, {});

    if (!clientId || !primarySpreadsheetId) {
      return;
    }

    return addNewDataRow({
      clientId,
      virtualSpreadsheetId: primarySpreadsheetId,
      columnValues: newLocation,
    });
  };

  const addLocationAndClose = async () => {
    await createNewLocation();
    props.onClose();
  };

  const isAnyRowFilled = !isTextEmpty(rows.map(row => row.value).join(''));

  const addLocation = async () => {
    await createNewLocation();
    setRows(getEditDataTableRowsWithColumnIdsFromMatchup(matchupData, primarySpreadsheetId));
  };

  const changeRow = (rowIndex: number, newRow: ChangeableSettingRow) => {
    const newRows = [...rows];
    const currentRow = rows[rowIndex];
    if (currentRow) {
      currentRow.value = (newRow.data[1] as SettingTextData).value;
      setRows(newRows);
    }
  };

  useEffect(() => {
    // reset state on matchup data change
    setRows(getEditDataTableRowsWithColumnIdsFromMatchup(matchupData, primarySpreadsheetId));
  }, [matchupData, primarySpreadsheetId]);

  return (
    <EditLocationModalComponent
      {...props}
      caption={t('Type New Marker Information')}
      onChange={changeRow}
      isSubmitDisabled={!isAnyRowFilled || isLoading}
      onSubmit={addLocationAndClose}
      onAddLocation={addLocation}
      rows={rows}
      isLoading={isLoading}
      errorMessage={isError ? t('An error occurred while adding a new row') : undefined}
      showAddAnotherLocation
    />
  );
};
