import { type DemographicsRegion } from '../demographics.repository';
import {
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_ERROR,
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_REQUEST,
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START, DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_SUCCESS,
} from './demographicsListGlobal.actionTypes';

export const demographicsListGlobalFetchDataRequest = () => ({
  type: DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_REQUEST,
}) as const;

export const demographicsListGlobalFetchDataStart = () => ({
  type: DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START,
}) as const;

export const demographicsListGlobalFetchDataError = () => ({
  type: DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_ERROR,
}) as const;

export const demographicsListFetchDataSuccess = (regions: DemographicsRegion[]) => ({
  type: DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_SUCCESS,
  payload: {
    regions,
  },
}) as const;
