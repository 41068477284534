import { css } from '@emotion/react';
import {
  faCheck, faPlay, faStop, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  boundaryDrawCloseModal,
  boundaryDrawDeactivate, boundaryDrawEditActivate,
  boundaryDrawResetShapes,
  boundaryDrawSetDrawType,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { useBoundaryDrawEditModalOpenSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { type DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';
import { noop } from '../../../_shared/utils/function.helpers';
import { BoundaryDrawConfirmComponent } from './boundaryDrawConfirm.component';
import { getBoundaryDrawTypeOptions } from './boundaryDrawConfirm.helpers';
import { useBoundaryDrawSubmitModal } from './useBoundaryDrawSubmitModal';

const buttonsWrapperStyle = ({ buttonsCount }: { buttonsCount: number }) => css({
  display: 'flex',
  justifyContent: buttonsCount <= 1 ? 'flex-end' : 'space-between',
});

export const BoundaryDrawConfirmContainer = () => {
  const boundaryDraw = useBoundaryDrawEditModalOpenSelector();
  const isBoundaryDrawActive = boundaryDraw?.isActive ?? false;
  const { openBoundaryDrawSubmitModal } = useBoundaryDrawSubmitModal();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(boundaryDrawCloseModal());
  };

  const reset = useCallback(() => {
    dispatch(boundaryDrawDeactivate());
    dispatch(boundaryDrawResetShapes());
  }, [dispatch]);

  const onStartButtonClick = useCallback(() => {
    dispatch(boundaryDrawEditActivate());
  }, [dispatch]);

  const submit = useCallback(() => {
    if (!boundaryDraw || boundaryDraw.mapShapes.length === 0) {
      return;
    }

    openBoundaryDrawSubmitModal();
  }, [boundaryDraw, openBoundaryDrawSubmitModal]);

  const onDrawTypeChange = useCallback((drawType: DrawType) => dispatch(boundaryDrawSetDrawType(drawType)), [dispatch]);

  const [t] = useTranslation();
  const shapesChanged = boundaryDraw && boundaryDraw.mapShapes !== boundaryDraw.initialShapes;

  const buttons = useMemo(() => {
    if (!shapesChanged) {
      if (!isBoundaryDrawActive) {
        return [{
          icon: faPlay,
          buttonStyle: ButtonStyle.Success,
          onClick: onStartButtonClick,
          label: t('Start'),
        }];
      }
      else {
        return [{
          icon: faStop,
          buttonStyle: ButtonStyle.Danger,
          onClick: reset,
          label: t('Stop'),
        }, {
          icon: faCheck,
          buttonStyle: ButtonStyle.Primary,
          onClick: noop,
          label: t('Save'),
          isDisabled: true,
        }];
      }
    }
    else {
      return [{
        icon: faTrash,
        buttonStyle: ButtonStyle.Danger,
        onClick: reset,
        label: t('Reset'),
      }, {
        icon: faCheck,
        buttonStyle: ButtonStyle.Primary,
        onClick: submit,
        label: t('Save'),
      }];
    }
  }, [isBoundaryDrawActive, onStartButtonClick, reset, shapesChanged, submit, t]);

  if (!boundaryDraw) {
    return null;
  }

  const options = getBoundaryDrawTypeOptions(t, !boundaryDraw.mapShapes.length);

  let highlightedStepNumber: number;

  if (!boundaryDraw.isActive) {
    highlightedStepNumber = 1;
  }
  else {
    highlightedStepNumber = 2;
  }

  return (
    <BoundaryDrawConfirmComponent
      isOpen
      highlightedStepNumber={highlightedStepNumber}
      heading={t('Edit Territory Group')}
      dropdownOptions={options}
      selectedOptionId={boundaryDraw.drawType}
      buttonsWrapperStyle={buttonsWrapperStyle({ buttonsCount: buttons.length })}
      buttons={buttons}
      onClose={onClose}
      onDropdownSelect={onDrawTypeChange}
    />
  );
};
