import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { HeatMapType } from '../../_shared/types/heatmap/heatMap.enum';
import { type SpreadsheetColumn } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type TranslationFnc } from '../../_shared/utils/hooks';

export const getHeatMapItemName = (heatMap: HeatMap, columns: ReadonlyArray<SpreadsheetColumn>, t: TranslationFnc): string => {
  if (heatMap.type === HeatMapType.AllMarkersDensity) {
    return t('Markers Density');
  }

  if (heatMap.type === HeatMapType.GroupDensity) {
    return t('Marker {{name}}', { name: heatMap.selectedGroupName });
  }

  if (heatMap.type === HeatMapType.AllMarkersNumericalData) {
    return t('Numerical {{name}}', {
      name: columns.find(c => c.id === heatMap.numericalColumnId?.columnId)?.name || '',
    });
  }

  if (heatMap.type === HeatMapType.GroupNumericalData) {
    return t('Numerical {{name}}', { name: heatMap.selectedGroupName });
  }

  throw Error('Heat map item not supported!');
};
