import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { TinyMapColorTileComponent } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import type { Theme } from '~/_shared/themes/theme.model';
import { BoundaryRowContentWrapperComponent } from '~/sidebar/sidebarApps/mapTools/boundary/activeBoundary/boundaryItem/boundaryRowContentWrapper.component';
import { BoundaryRowPaneComponent } from '~/sidebar/sidebarApps/mapTools/boundary/activeBoundary/boundaryItem/boundaryRowPane.component';

type BoundaryRowProps = {
  legendMode?: boolean;
  actions: ReactNode;
  isChecked: boolean;
  label: string;
  selectedColor: ColorResult;
  lineColor: ColorResult;
  lineWidth: number;
  isDisabled?: boolean;
  subLabel?: string | null;
  labelTooltip?: string | null;

  onToggle: () => void;
};

const colorTileWrapperStyle = ({ isDisabled }: { isDisabled: boolean }) => (theme: Theme) => css({
  margin: '0 8px',
  opacity: isDisabled ? theme.opacity.disabled : undefined,
});

const actionWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

const labelStyle = ({ isEmpty }: { isEmpty: boolean }) => (theme: Theme) => css({
  color: theme.textColors.primary,
  fontSize: '14px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  opacity: isEmpty ? theme.opacity.disabled : undefined,
  cursor: 'default',
});

const tooltipStyle = css({
  marginLeft: 10,
});

const subLabelStyle = css({
  fontWeight: 400,
});

export const BoundaryRowComponent = ({
  actions,
  isChecked,
  isDisabled = false,
  label,
  labelTooltip,
  legendMode,
  lineColor,
  lineWidth,
  onToggle,
  selectedColor,
  subLabel,
  ...restProps
}: BoundaryRowProps) => {
  return (
    <BoundaryRowPaneComponent {...restProps}>
      <BoundaryRowContentWrapperComponent>
        <CheckboxComponent
          isDisabled={isDisabled}
          isChecked={isChecked}
          checkedSetter={onToggle}
        />

        <TinyMapColorTileComponent
          color={selectedColor.rgb}
          lineColor={lineColor.rgb}
          lineWidth={Math.ceil(lineWidth / 2)}
          css={colorTileWrapperStyle({ isDisabled })}
        />

        <TooltipComponent
          contentStyle={tooltipStyle}
          delayShow={500}
          tooltipContent={labelTooltip}
        >
          <div css={labelStyle({ isEmpty: isDisabled })}>
            {label}
            {subLabel && (
              <div css={subLabelStyle}>{subLabel}</div>
            )}
          </div>
        </TooltipComponent>
      </BoundaryRowContentWrapperComponent>

      {!legendMode && (
        <div css={actionWrapperStyle}>
          {actions}
        </div>
      )}
    </BoundaryRowPaneComponent>
  );
};
