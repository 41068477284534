import { type FC } from 'react';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../_shared/utils/hooks';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { ModalType } from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';
import { HeatMapDropdownComponent } from './formParts/heatMapDropdown.component';
import { useIsNumericColumnCheck } from './useIsNumericColumnCheck.hook';

type NumericColumnSelectorProps = Readonly<{
  allColumns: ReadonlyArray<SpreadsheetColumn>;
  selectedColumnId: SpreadsheetColumnId | null;
  onColumnSelected: (columnId: string, spreadsheetId: number) => void;
  placeholder?: string;
}>;

export const NumericColumnSelectorComponent: FC<NumericColumnSelectorProps> = props => {
  const { checkIsNumeric, isLoading } = useIsNumericColumnCheck();
  const { openModal } = useModal(ModalType.AppError);
  const [t] = useTranslation();

  const changeValue = (columnId: string | null, spreadsheetId: number | null) =>
    checkIsNumeric(columnId, spreadsheetId)
      .then(result => {
        if (result && columnId) {
          props.onColumnSelected(columnId, spreadsheetId as number);
        }
        else {
          openModal({
            type: AppErrorType.General,
            // title: t('Heat Mapping Tool'),
            title: t('Not a numerical column'),
            content: t('This functionality requires a numerical column. The selected column consists of non-numerical values. To be able to proceed with this column, please make sure it only contains numerical values.'),
          });
        }
      });

  return (
    <HeatMapDropdownComponent
      onChange={(index) => {
        if (index !== null) {
          changeValue(props.allColumns[index].id, props.allColumns[index].spreadsheetId);
        }
      }}
      value={props.selectedColumnId ?
        props.allColumns.findIndex(c =>
          props.selectedColumnId?.columnId === c.id && props.selectedColumnId.spreadsheetId === c.spreadsheetId
        ) : null
      }
      options={props.allColumns.map((c, index) => ({ name: c.name, value: index }))}
      placeholder={props.placeholder}
      isDisabled={isLoading}
    />
  );
};
