import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.CaretDown]: {
  views: {
    default: {
      canvasHeight: 568,
      canvasWidth: 568,
      iconWidth: 260,
      iconHeight: 568,
    },
    short: {
      canvasHeight: 568,
      canvasWidth: 568,
      iconWidth: 260,
      iconHeight: 380,
      offsetTop: 0,
    },
  },
  colors: {
    caretA: '#D8D8D8',
    caretB: '#000000',
    caretC: '#FE6600',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 165] as const,
    short: [0, 135] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/21037-caret-down" */
      './21037-caret-down.json'
    ),
} };

export const { CaretDown } = config;
