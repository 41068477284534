import {
  type FC, useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { createUuid } from '~/_shared/utils/createUuid';
import { LassoSelectContainer } from '~/map/lassoSelect/lassoSelect.container';
import { ModalType } from '~/modal/modalType.enum';
import { convertLatLngPathToGeometry } from '../../../../_shared/types/polygon/polygon.utils';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { AppErrorType } from '../../../../appError/appErrorType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { getMapBoundaryAreaQuery } from '../../../../store/boundaryItems/boundaryItems.repository';
import {
  boundarySelectAdd, boundarySelectEditDeactivate, boundarySelectSetLassoIsLoading,
} from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { useBoundarySelectActiveSelector } from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { BoundarySelectType } from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import { useClientIdSelector } from '../../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../../store/selectors/useMapIdSelector';

export const BoundaryLassoSelectContainer: FC = () => {
  const boundarySelect = useBoundarySelectActiveSelector();
  const { openModal: openErrorModal } = useModal(ModalType.AppError);
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [shapeKey, setShapeKey] = useState<Uuid>(createUuid());

  const resetShape = useCallback(() => {
    setShapeKey(createUuid());
  }, []);

  const handleOnDrawingStop = useCallback(() => {
    dispatch(boundarySelectEditDeactivate());
  }, [dispatch]);

  const handleAddShape = useCallback((path: PolygonPath) => {
    if (!boundarySelect || mapId === null || clientId === null) {
      return;
    }

    dispatch(boundarySelectSetLassoIsLoading(true));

    getMapBoundaryAreaQuery(clientId, {
      map_id: mapId,
      exclude_artificial: false,
      format: 'json',
      overlap_type: boundarySelect.selectLassoOverlap,
      boundary_group_id: boundarySelect.selectBoundaryGroupId,
      polygon_filter: {
        type: 'or',
        polygons: [{
          path: convertLatLngPathToGeometry(path),
        }],
      },
    })
      .then(response => {
        if (!('boundaries' in response)) {
          console.error('Server returned unexpected response');
          return;
        }

        dispatch(boundarySelectAdd(response.boundaries.map(boundary => boundary.boundary_id)));
      })
      .catch(() => {
        openErrorModal({
          type: AppErrorType.General,
          errorTitle: t('Error selecting area using lasso'),
          title: t('Error'),
        });
      })
      .finally(() => {
        resetShape();
        dispatch(boundarySelectSetLassoIsLoading(false));
      });
  }, [boundarySelect, mapId, clientId, dispatch, openErrorModal, t, resetShape]);

  const isLassoActive = boundarySelect?.isActive && boundarySelect.selectType === BoundarySelectType.Lasso;

  if (!isLassoActive) {
    return null;
  }

  return (
    <LassoSelectContainer
      key={shapeKey}
      onAddShape={handleAddShape}
      onDrawingStop={handleOnDrawingStop}
    />
  );
};
