import {
  shouldLegsBeExpanded, shouldRoutesBeExpanded,
} from '~/_shared/constants/routeCollapsingLevel.enum';
import { type RouteLeg } from '~/_shared/types/googleMaps/googleMaps.types';
import { copy } from '~/_shared/utils/collections/collections';
import { createUuid } from '~/_shared/utils/createUuid';
import { range } from '~/_shared/utils/function.helpers';
import { type MapSettingsDirectionsAction } from '~/store/mapSettings/directions/mapSettingsDirections.action';
import {
  MAP_SETTINGS_DIRECTIONS_ADD_ROUTE,
  MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES,
  MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE,
} from '~/store/mapSettings/directions/mapSettingsDirections.actionTypes';
import { type DirectionsAction } from './directions.action';
import {
  FRONTEND_STATE_DIRECTIONS_ADD_EMPTY_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS,
  FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS, FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED,
  FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS,
  FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS,
  FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA,
  FRONTEND_STATE_ROUTE_COLLAPSED,
  FRONTEND_STATE_ROUTE_EXPANDED,
  FRONTEND_STATE_ROUTE_LEG_COLLAPSED,
  FRONTEND_STATE_ROUTE_LEG_EXPANDED,
  FRONTEND_STATE_ROUTE_UI_DATA_LOADED,
} from './directions.actionTypes';
import {
  createEmptyWaypoint,
  generateNewWaypoints,
  getWaypointsAccordingToDirectionType,
} from './directions.helpers';
import {
  type DirectionsState, type RouteUiData,
} from './directions.state';

const initialState: DirectionsState = {
  waypoints: range(2).map(() => createEmptyWaypoint()),
  routesUiData: new Map(),
  expandedRouteIds: new Set(),
};

export const directionsReducer = (state = initialState, action: DirectionsAction | MapSettingsDirectionsAction): DirectionsState => {
  switch (action.type) {
    case FRONTEND_STATE_DIRECTIONS_ADD_EMPTY_WAYPOINT:
      return {
        ...state,
        waypoints: [...state.waypoints, createEmptyWaypoint()],
      };

    case FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT: {
      const { address, latLng, markerId, startsFromUserLocation, directionSourceType } = action.payload;
      const newWaypoint = { address, latLng, id: createUuid(), markerId };

      const waypoints = getWaypointsAccordingToDirectionType({
        directionType: directionSourceType,
        startsFromUserLocation,
        waypoints: state.waypoints,
        newWaypoint,
      });

      return {
        ...state,
        waypoints,
      };
    }

    case FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS:
      return {
        ...state,
        waypoints: initialState.waypoints,
      };

    case FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT:
      if (state.waypoints.length <= 2) {
        return {
          ...state,
          waypoints: state.waypoints.map((w, i) => i === action.payload.waypointIndex ? createEmptyWaypoint() : w),
        };
      }

      return {
        ...state,
        waypoints: [
          ...state.waypoints.slice(0, action.payload.waypointIndex),
          ...state.waypoints.slice(action.payload.waypointIndex + 1),
        ],
      };

    case FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS:
      return {
        ...state,
        waypoints: state.waypoints.map((item, i) =>
          i === action.payload.waypointIndex
            ? { id: item.id, latLng: null, address: action.payload.newAddress }
            : item),
      };

    case FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS: {
      if (action.payload.waypoints.length === 0) {
        return state;
      }

      return {
        ...state,
        waypoints: generateNewWaypoints(state.waypoints, action.payload.waypoints, action.payload.keepFirstWaypointEmpty),
      };
    }

    case FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS: {
      return {
        ...state,
        waypoints: generateNewWaypoints([], action.payload.waypoints, action.payload.keepFirstWaypointEmpty),
      };
    }

    case FRONTEND_STATE_ROUTE_LEG_COLLAPSED:
      return updatePropertyOfRouteIfExists(
        action.payload.routeId,
        'expandedLegsIndexes',
        prev => copy.andRemove(prev, [action.payload.legIndex]),
        state
      );

    case FRONTEND_STATE_ROUTE_LEG_EXPANDED:
      return updatePropertyOfRouteIfExists(
        action.payload.routeId,
        'expandedLegsIndexes',
        prev => copy.andAdd(prev, [action.payload.legIndex]),
        state
      );

    case FRONTEND_STATE_ROUTE_UI_DATA_LOADED: {
      const { routeCollapsingLevel, data, routeId } = action.payload;
      const dataWithExpanded: RouteUiData = {
        ...data,
        expandedLegsIndexes: shouldLegsBeExpanded(routeCollapsingLevel) ? new Set(range(data.legs.length)) : new Set(),
      };

      return {
        ...state,
        routesUiData: copy.andAdd(state.routesUiData, [[routeId, dataWithExpanded]]),
        expandedRouteIds: shouldRoutesBeExpanded(routeCollapsingLevel) ? copy.andAdd(state.expandedRouteIds, [routeId]) : state.expandedRouteIds,
      };
    }

    case FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED: {
      const { newCollapsing } = action.payload;
      const newExpandedRouteIds: Set<string> = new Set();
      const newRoutesUiData: Map<string, RouteUiData> = new Map();

      state.routesUiData.forEach((data, routeId) => {
        const dataWithExpanded: RouteUiData = {
          ...data,
          expandedLegsIndexes: shouldLegsBeExpanded(newCollapsing) ? new Set(range(data.legs.length)) : new Set(),
        };

        newRoutesUiData.set(routeId, dataWithExpanded);
        if (shouldRoutesBeExpanded(newCollapsing)) {
          newExpandedRouteIds.add(routeId);
        }
      });

      return {
        ...state,
        routesUiData: newRoutesUiData,
        expandedRouteIds: newExpandedRouteIds,
      };
    }

    case MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES:
      return {
        ...state,
        routesUiData: new Map(),
        expandedRouteIds: new Set(),
      };

    case MAP_SETTINGS_DIRECTIONS_ADD_ROUTE: {
      const routeId = action.payload.route.id;
      const { routeCollapsingLevel, routeUiData } = action.payload;
      const dataWithExpanded: RouteUiData = {
        ...routeUiData,
        expandedLegsIndexes: shouldLegsBeExpanded(routeCollapsingLevel) ? new Set(range(routeUiData.legs.length)) : new Set(),
      };

      return {
        ...state,
        routesUiData: copy.andAdd(state.routesUiData, [[routeId, dataWithExpanded]]),
        expandedRouteIds: shouldRoutesBeExpanded(routeCollapsingLevel) ? copy.andAdd(state.expandedRouteIds, [routeId]) : state.expandedRouteIds,
      };
    }

    case FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA: {
      const routeData: RouteUiData | undefined = state.routesUiData.get(action.payload.routeId);

      if (!routeData || !routeData.apiResponses[0] || !action.payload.routes[0]) {
        return state;
      }

      const newApiResponses = [...routeData.apiResponses];
      newApiResponses[0] = {
        ...newApiResponses[0],
        routes: action.payload.routes,
      };

      const newRouteData: RouteUiData = {
        ...routeData,
        apiResponses: newApiResponses,
        legs: action.payload.routes[0].legs as unknown as ReadonlyArray<RouteLeg>,
      };

      return {
        ...state,
        routesUiData: copy.andReplace(state.routesUiData, action.payload.routeId, newRouteData),
      };
    }

    case MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE: {
      const routeId = action.payload.route.id;
      return {
        ...state,
        routesUiData: copy.andRemove(state.routesUiData, [routeId]),
        expandedRouteIds: copy.andRemove(state.expandedRouteIds, [routeId]),
      };
    }

    case FRONTEND_STATE_ROUTE_COLLAPSED:
      return {
        ...state,
        expandedRouteIds: copy.andRemove(state.expandedRouteIds, [action.payload.routeId]),
      };

    case FRONTEND_STATE_ROUTE_EXPANDED:
      return {
        ...state,
        expandedRouteIds: copy.andAdd(state.expandedRouteIds, [action.payload.routeId]),
      };

    default:
      return state;
  }
};

const updatePropertyOfRouteIfExists = <TKey extends keyof RouteUiData>(
  routeId: string,
  prop: TKey,
  updater: (prev: RouteUiData[TKey]) => RouteUiData[TKey],
  state: DirectionsState
): DirectionsState => {
  const existing = state.routesUiData.get(routeId);

  return {
    ...state,
    routesUiData: existing
      ? copy.andReplace(state.routesUiData, routeId, { ...existing, [prop]: updater(existing[prop]) })
      : state.routesUiData,
  };
};
