import {
  type FC, useEffect,
} from 'react';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type BoundaryGroupMatchings } from '../../../store/boundaryGroups/boundaryGroups.repository';
import { BoundaryMatchingType } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritoryGroup,
  type BoundaryTerritoryGroupMatchColumn,
  type BoundaryTerritoryGroupMatchings,
} from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useSpreadsheetColumns } from '../../../store/matchupData/matchupDataSelectors.hook';
import {
  BoundarySettingsSyncDataComponent, BoundarySyncDataType,
} from './boundarySettingsSyncData.component';

type BoundarySettingsSyncDataContainer = Readonly<{
  boundaryTerritoryGroup: BoundaryTerritoryGroup;
  boundaryGroupMatchings: BoundaryGroupMatchings;
  onBoundaryTerritoryGroupChange: (territoryGroup: BoundaryTerritoryGroup) => void;
  setIsFormValid: (isValid: boolean) => void;
  setSubmitDisabledReason: (reason: string | null) => void;
}>;

export const BoundarySettingsSyncDataContainer: FC<BoundarySettingsSyncDataContainer> = (props) => {
  const spreadsheetColumns = useSpreadsheetColumns();
  const [t] = useTranslation();

  const { setSubmitDisabledReason, setIsFormValid } = props;

  const changeBoundaryTerritoryGroupMatchings = (newMatchings: BoundaryTerritoryGroupMatchings) => {
    props.onBoundaryTerritoryGroupChange({
      ...props.boundaryTerritoryGroup,
      matchings: newMatchings,
    });
  };

  const changeMatchType = (syncType: BoundarySyncDataType) => {
    if (syncType === BoundarySyncDataType.Data) {
      changeBoundaryTerritoryGroupMatchings({
        matchingType: props.boundaryGroupMatchings.type,
        matchColumns: props.boundaryTerritoryGroup.matchings.matchColumns,
      });
    }
    else {
      changeBoundaryTerritoryGroupMatchings({
        matchingType: BoundaryMatchingType.Geometry,
        matchColumns: [],
      });
    }
  };

  const changeMatchColumns = (newColumns: ReadonlyArray<BoundaryTerritoryGroupMatchColumn>) => {
    if (props.boundaryTerritoryGroup.matchings.matchingType === BoundaryMatchingType.Geometry) {
      return;
    }

    changeBoundaryTerritoryGroupMatchings({
      matchColumns: newColumns,
      matchingType: props.boundaryTerritoryGroup.matchings.matchingType,
    });
  };

  useEffect(() => {
    if (props.boundaryTerritoryGroup.matchings.matchingType === BoundaryMatchingType.Geometry) {
      setIsFormValid(true);
      setSubmitDisabledReason(null);
      return;
    }

    const columnsUsedAcrossBoundaryGroupMatchings: Record<string, true> = {};

    for (const boundaryGroupColumn of props.boundaryGroupMatchings.matchingColumns) {
      const matchedColumn = props.boundaryTerritoryGroup.matchings.matchColumns.find(column => column.category === boundaryGroupColumn.id);

      if (!matchedColumn) {
        // check if all boundary columns are assigned
        setIsFormValid(false);
        setSubmitDisabledReason(t('Select column for each matchup'));
        return;
      }
      else {
        // check if assigned matchings are unique. There can be only one column used for one category
        if (columnsUsedAcrossBoundaryGroupMatchings[matchedColumn.columnId]) {
          setIsFormValid(false);
          setSubmitDisabledReason(t('Use unique columns for each matchup'));
          return;
        }

        columnsUsedAcrossBoundaryGroupMatchings[matchedColumn.columnId] = true;
      }
    }

    setIsFormValid(true);
    setSubmitDisabledReason(null);
  }, [props.boundaryTerritoryGroup.matchings, setIsFormValid, setSubmitDisabledReason, t, props.boundaryGroupMatchings.matchingColumns]);

  return (
    <BoundarySettingsSyncDataComponent
      spreadsheetColumns={spreadsheetColumns}
      matchColumns={props.boundaryTerritoryGroup.matchings.matchColumns}
      syncDataType={props.boundaryTerritoryGroup.matchings.matchingType === BoundaryMatchingType.Geometry ? BoundarySyncDataType.Geometry : BoundarySyncDataType.Data}
      boundaryGroupMatchings={props.boundaryGroupMatchings}
      onSyncDataTypeChange={changeMatchType}
      onMatchingColumnsChange={changeMatchColumns}
    />
  );
};
