import { type DeepWritable } from 'ts-essentials';
import {
  type LabelVisualizerArrowProps, type LabelVisualizerBodyPadding, type LabelVisualizerBodyProps, type LabelVisualizerBorderProps, type LabelVisualizerOffsetProps, type LabelVisualizerPaddingSide,
} from './labelVisualizer.types';

const TOOLTIP_BACKGROUND_COLOR = '111b22';
const TOOLTIP_TEXT_COLOR = 'fff';

export const initialLabelVisualizerArrowProps: LabelVisualizerArrowProps = {
  dimensions: { width: 5, height: 5 },
};

export const initialLabelVisualizerBodyProps = {
  backgroundColor: { selectedColor: TOOLTIP_BACKGROUND_COLOR, opacity: 0.9 },
  dimensions: { height: 20 },
  fontColor: { selectedColor: TOOLTIP_TEXT_COLOR },
  fontSize: 14,
  padding: {
    bottom: 0.18,
    left: 0.21,
    right: 0.21,
    top: 0.18,
  },
} as const satisfies LabelVisualizerBodyProps;

export const initialLabelVisualizerBorderProps: LabelVisualizerBorderProps = {
  color: { selectedColor: TOOLTIP_BACKGROUND_COLOR },
  width: 1,
  radius: 4,
};

export const initialLabelVisualizerOffsetProps: LabelVisualizerOffsetProps = {
  type: 'default',
};

export const getLabelPaddingsValues = (labelBodyProps: LabelVisualizerBodyProps) => {
  const sides = ['bottom', 'left', 'right', 'top'] as const;
  return sides.reduce((acc, side) => {
    acc[side] = getLabelPadding(labelBodyProps.fontSize, side, labelBodyProps.padding[side]);
    return acc;
  }, {} as DeepWritable<LabelVisualizerBodyPadding>);
};

const getLabelPadding = (
  fontSize: number,
  side: LabelVisualizerPaddingSide,
  paddingCoefficient?: number | null | undefined
): number => (
  // paddingCoefficient > 1 is here to ensure backwards compatibility, when paddings were absolute numbers not coefficients
  fontSize * (
    paddingCoefficient === null || paddingCoefficient === undefined || isNaN(paddingCoefficient) || paddingCoefficient > 1
      ? (initialLabelVisualizerBodyProps.padding[side] || 0)
      : paddingCoefficient
  )
);
