import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import {
  useDragLayer, type XYCoord,
} from 'react-dnd';
import type { Theme } from '~/_shared/themes/theme.model';
import { getStylesForDraggedItem } from '~/_shared/utils/dnd/dnd.helpers';

const positionStyle = (props: {
  currentOffset: XYCoord | null;
  initialOffset: XYCoord | null;
}) => css({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
}, getStylesForDraggedItem(props.initialOffset, props.currentOffset));

const draggableStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.primaryDragged,
  boxSizing: 'border-box',
  margin: '8px 0px',
});

type BubbleItemDragLayerProps = {
  acceptedItemType: string;
  // remember to render the item in a way it has a fixed and defined width. If width is not specified, it can have a different size depending on a browser.
  renderItem: (id: string) => ReactNode;
};

export const SortableDragLayerComponent: FC<BubbleItemDragLayerProps> = (props) => {
  const dragProps = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!dragProps.isDragging || !dragProps.item.id || dragProps.itemType !== props.acceptedItemType) {
    return null;
  }

  return (
    <div
      css={positionStyle({
        currentOffset: dragProps.currentOffset,
        initialOffset: dragProps.initialOffset,
      })}
    >
      <div css={draggableStyle} >
        {props.renderItem(dragProps.item.id)}
      </div>
    </div>
  );
};
