import {
  type FC, useCallback, useState,
} from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import { type GeoLocation } from '../../utils/geolocation/geolocation';
import { LocationFinderModalComponent } from './locationFinderModal.component';
import { useActivateLocationFinder } from './useActivateLocationFinder.hook';

export type LocationFinderModalContainerProps = ModalProps<{
  location?: GeoLocation;
}>;

export const LocationFinderModalContainer: FC<LocationFinderModalContainerProps> = (props) => {
  const [address, setAddress] = useState(props.location?.address || '');

  const { onClose } = props;

  const onSetLocationCallback = useCallback(() => onClose(), [onClose]);

  const { findLocation, isLoading } = useActivateLocationFinder({
    onSetLocationCallback,
  });

  const handleFindLocation = useCallback((locationLimit: number | null) =>
    findLocation(address, locationLimit),
  [address, findLocation]);

  return (
    <LocationFinderModalComponent
      {...props}
      onChange={(address: string) => setAddress(address)}
      value={address}
      onSubmit={handleFindLocation}
      isLoading={isLoading}
    />
  );
};
