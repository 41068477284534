import { useCallback } from 'react';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { translateBoundaryTerritoryDisplayName } from '~/boundary/settings/boundarySettings.helpers';
import { i18n } from '~/i18nextSetup';
import {
  type BoundaryTerritoryGroupId, type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { type BoundaryGroupDemographic } from '~/store/boundaryGroups/boundaryGroups.repository';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryDetailsSelector } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { type BoundaryTerritoryGroupDetailsItem } from '~/store/boundaryTerritoryDetails/boundaryTerritoryGroups.state';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryBucketType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { getBoundaryGroupDemographic } from './useBoundaryGroupDemographics';

export const useGetBoundaryTerritoryDisplayNames = (): {
  boundaryTerritoryDisplayNames: ReadonlyMap<BoundaryTerritoryGroupId, ReadonlyMap<BoundaryTerritoryId, string>>;
  getBoundaryTerritoryDisplayName: (boundaryTerritoryId: BoundaryTerritoryId, boundaryTerritoryGroupId: BoundaryTerritoryGroupId) => string;
} => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const territoryDetails = useBoundaryTerritoryDetailsSelector();

  const boundaryTerritoryDisplayNames = getBoundaryTerritoryDisplayNamesMemoized(
    boundaryGroups, boundaryTerritoryGroups, territoryDetails);

  const getDisplayName = useCallback((boundaryTerritoryId: BoundaryTerritoryId, boundaryTerritoryGroupId: BoundaryTerritoryGroupId) =>
    boundaryTerritoryDisplayNames.get(boundaryTerritoryGroupId)?.get(boundaryTerritoryId) ?? '', [boundaryTerritoryDisplayNames]);

  return {
    boundaryTerritoryDisplayNames,
    getBoundaryTerritoryDisplayName: getDisplayName,
  };
};

const getBoundaryTerritoryDisplayNamesMemoized = memoizeOne((
  boundaryGroups: ReadonlyArray<BoundaryGroup>,
  boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>,
  territoryDetails: ReadonlyMap<number, BoundaryTerritoryGroupDetailsItem>,
): ReadonlyMap<BoundaryTerritoryGroupId, ReadonlyMap<BoundaryTerritoryId, string>> => {
  // do not use translate function from useTranslation hook in memoizeOne function
  // it's stored in state, so it has different reference per component
  const translate = i18n.t;

  const allNamesMap = new Map<BoundaryTerritoryGroupId, ReadonlyMap<BoundaryTerritoryId, string>>();

  boundaryTerritoryGroups.forEach(btg => {
    const boundaryTerritoryNamesMap = new Map<BoundaryTerritoryId, string>();
    allNamesMap.set(btg.boundaryTerritoryGroupId, boundaryTerritoryNamesMap);

    const settings = btg.settings;
    const demographic = settings.boundaryTerritoryType === BoundaryTerritoryType.Demographic && settings.demographicId
      ? getBoundaryGroupDemographic(boundaryGroups, btg.boundaryGroupId, settings.demographicId)
      : null;

    btg.settings.boundaryTerritories.forEach(boundaryTerritory => {
      const displayName = getBoundaryTerritoryDisplayName({
        boundaryTerritory,
        boundaryBucketType: settings.bucketType,
        territoryNumericalData: territoryDetails.get(btg.boundaryTerritoryGroupId)?.numericalData,
        demographic,
        translate,
      });

      boundaryTerritoryNamesMap.set(boundaryTerritory.boundaryTerritoryId, displayName);
    });
  });

  return allNamesMap;
});

const getBoundaryTerritoryDisplayName = ({ boundaryTerritory, boundaryBucketType, territoryNumericalData, demographic, translate }:
{
  boundaryTerritory: BoundaryTerritory;
  boundaryBucketType: BoundaryBucketType | null;
  territoryNumericalData: BoundaryTerritoryGroupDetailsItem['numericalData'] | undefined;
  demographic: BoundaryGroupDemographic | null;
  translate: TranslationFnc;
}) => {
  const min = boundaryBucketType === 'percentage-range' ? 0 : territoryNumericalData?.totalLowest ?? null;
  const max = boundaryBucketType === 'percentage-range' ? 100 : territoryNumericalData?.totalHighest ?? null;

  const bucketType = demographic?.percentage ? 'percentage-range' : boundaryBucketType;

  return translateBoundaryTerritoryDisplayName(boundaryTerritory, translate, { bucketType, min, max });
};
