import { css } from '@emotion/react';
import {
  ButtonComponent, type ButtonProps,
} from '~/_shared/baseComponents/buttons/button/button.component';
import {
  ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons/button/button.types';
import { pick } from '~/_shared/utils/object/pick';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';

const headerStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: '5px 16px',
  width: '100%',
});

const buttonStyle = css({
  flex: 1,
});

export type MapToolWrapperHeaderComponentProps = Pick<ButtonProps, 'onClick' | 'prefixIcon' | 'prefixIconStyle' | 'text'> & {
  className?: string;
};

export const MapToolHeaderButtonComponent = (props: MapToolWrapperHeaderComponentProps) => {
  const theme = useTheme();

  return (
    <div
      css={headerStyle({ theme })}
      className={props.className}
    >
      <ButtonComponent
        css={buttonStyle}
        size={ButtonSize.Small}
        buttonStyle={ButtonStyle.Quaternary}
        {...pick(props, ['onClick', 'prefixIcon', 'prefixIconStyle', 'text'])}
      />
    </div>
  );
};
