import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as geometry from 'spherical-geometry-js';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { areLatLngEqual } from '~/_shared/utils/latLng/latLng.helpers';
import { type StreetViewLocation } from '~/store/frontendState/mapTools/streetView/streetView.state';
import { loadGoogleMapsScript } from '../google-map/google-map.loader';

const getStreetViewLocationFromPanorama = (panorama: google.maps.StreetViewPanoramaData, latLng: LatLng): StreetViewLocation | null => {
  const position = panorama.location?.latLng ? googleLatLngToLocal(panorama.location?.latLng) : null;
  if (!position) {
    return null;
  }

  const heading = areLatLngEqual(position, latLng) ? 0 : geometry.computeHeading(position, latLng);

  return {
    position,
    pov: {
      heading,
      pitch: 0,
    },
  };
};

export const useStreetViewPanoramaLocation = (location: LatLng | null) => {
  const [streetViewLocation, setStreetViewLocation] = useState<StreetViewLocation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const callIdRef = useRef<Uuid>(createUuid());

  // memoize LatLng object using lat & lng number parameters, not by LatLng object reference
  const latLngMissing = !location;
  const latLng = useMemo(() => latLngMissing ? null : { lat: location.lat, lng: location.lng },
    [latLngMissing, location?.lat, location?.lng]);

  const streetViewService = useMemo(() => googleMapsLoaded ? new google.maps.StreetViewService() : null, [googleMapsLoaded]);

  useEffect(() => {
    loadGoogleMapsScript().then(() => setGoogleMapsLoaded(true));
  }, []);

  useEffect(() => {
    if (!streetViewService) {
      return;
    }

    setStreetViewLocation(null);
    const callId = createUuid();
    callIdRef.current = callId;

    if (!latLng) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    streetViewService.getPanorama({
      location: latLng,
      preference: google.maps.StreetViewPreference.BEST,
      radius: 100,
      sources: [google.maps.StreetViewSource.OUTDOOR],
    }, (panorama, status) => {
      if (callIdRef.current !== callId) {
        return;
      }

      if (panorama && status === google.maps.StreetViewStatus.OK) {
        setStreetViewLocation(getStreetViewLocationFromPanorama(panorama, latLng));
      }
      else {
        setStreetViewLocation(null);
      }

      setIsLoading(false);
    });
  }, [latLng, streetViewService]);

  return { streetViewLocation, isLoading };
};
