import { useCallback } from 'react';
import { ClientLicenseCode } from '~/_shared/types/client/license';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  getSignUpPages,
  getSignUpPageUrl,
} from '~/subscription/billing.repository';

/*
IAS: Individual Annual Subscription
TAS: Team Annual Subscription
IMS: Individual Monthly Subscription
TMS: Team Monthly Subscription
45: 45 Day Subscription
 */
export enum SignupPageId {
  INVOICED_SIGN_UP_PAGE_NAME = 'Maptive Signup Options',
  IAS_TAS = 'Signup options: IAS,TAS',
  IAS_TAS_45 = 'Signup options: IAS,TAS,45',
  IAS_TAS_IMS_TMS_45 = 'Signup options: IAS,TAS,IMS,TMS,45',
}

export const getSignupPageIdMap = (licenseCode: ClientLicenseCode | undefined): SignupPageId => {
  switch (licenseCode) {
    case ClientLicenseCode.TRIAL:
    case ClientLicenseCode.MAPTIVE_INDIVIDUAL_45_DAYS:
      return SignupPageId.IAS_TAS_45;
    case ClientLicenseCode.MAPTIVE_TEAM_MONTHLY:
    case ClientLicenseCode.MAPTIVE_INDIVIDUAL_MONTHLY:
      return SignupPageId.IAS_TAS_IMS_TMS_45;
    default:
      return SignupPageId.IAS_TAS;
  }
};

export const useGenerateSignUpPageUrl = () => {
  const clientId = useClientIdSelector();

  const generateSignUpUrlCall = useCallback<(signupPageId: string) => ReturnType<typeof getSignUpPageUrl>>((signupPageId) => {
    if (!clientId) {
      return Promise.reject(new Error('Client ID is not available'));
    }

    return getSignUpPages().then((data) => {
      const singUpPage = Object.entries(data.list).findLast(
        ([_, pageName]) => pageName === signupPageId
      );

      if (singUpPage) {
        return getSignUpPageUrl(clientId, singUpPage[0]);
      }
      else {
        return Promise.reject(new Error('Sign up page not found'));
      }
    });
  }, [clientId]);

  const {
    invokeAjax: invokeGenerateSignUpPageUrl, isLoading: isSignUpPageUrlLoading, data: signUpUrlData, error,
  } = useAjaxCall(generateSignUpUrlCall);

  return {
    isLoading: isSignUpPageUrlLoading,
    error,
    subscribeUrl: signUpUrlData?.sign_up_page ?? null,
    generateSignUpPageUrl: invokeGenerateSignUpPageUrl,
  };
};
