import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { lightTheme } from '../../../themes/light.theme';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import {
  changeColorAlpha, getSuitableBorderColor,
} from '../../../utils/colors/colors.helpers';
import {
  createColor, rgbColorToString,
} from '../colorPicker.helpers';
import { type ColorResult } from '../colorPicker.types';
import { ColorPickerAlignment } from '../colorPickerHolder/colorPickerHolder.component';
import { ColorPickerHolderWithPresetColorsContainer } from '../colorPickerHolder/colorPickerHolderWithPresetColors.container';
import { type MaptiveColorPickerProps } from '../maptiveColorPicker/maptiveColorPicker.component';

const wrapperStyle = css({
  display: 'inline-flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const tileWrapperStyle = ({ height, width }: {width?: number; height?: number}) => css({
  position: 'relative',
  height: height && height + 2 || '100%',
  width: width && width + 2 || '100%',
});

const positioningStyle = ({ height, width }: {width?: number; height?: number}) => css({
  position: 'absolute',
  height: height || '100%',
  left: 0,
  top: 0,
  width: width || '100%',
});

const colorTileStyle = (props: ThemeProps<{
  tileColor: ColorResult;
  backgroundColor: ColorResult;
  displayAlpha?: boolean;
}>) => {
  const borderColor = getSuitableBorderColor(props.tileColor, props.backgroundColor);
  const borderColorWithAdjustedOpacity = !props.displayAlpha ? changeColorAlpha(borderColor.rgb, 1) : borderColor;
  const fillColor = !props.displayAlpha ? changeColorAlpha(props.tileColor.rgb, 1) : props.tileColor;

  return css({
    backgroundColor: rgbColorToString(fillColor.rgb),
    border: `1px solid ${rgbColorToString(borderColorWithAdjustedOpacity.rgb)}`,
    borderRadius: 4,
    height: '100%',
    width: '100%',
  });
};

const overridableBaseSquareLayerStyle = css({
  borderRadius: 4,
  height: '100%',
  width: '100%x',
});

const baseSquareLayerStyle = ({ borderColor, displayAlpha }: {borderColor: ColorResult; displayAlpha?: boolean}) => css({
  backgroundColor: lightTheme.backgroundColors.primary,
  border: `1px solid ${rgbColorToString(changeColorAlpha(borderColor.rgb, displayAlpha ? borderColor.rgb.a : 1).rgb)}`,
  height: '100%',
  opacity: 0.5,
  width: '100%',
});

type ColorPickerWithColorTileProps = Omit<MaptiveColorPickerProps, 'presetColors' | 'setRef'> & Readonly<{
  backgroundColor?: ColorResult;
  editable?: boolean;
  height?: number;
  label?: string;
  onOpen?: () => void;
  selectedColor: ColorResult;
  tileStyle?: SerializedStyles;
  width?: number;
  alignment?: ColorPickerAlignment;
}>;

const colorTileLabelStyle = css({
  marginLeft: 10,
});

export const ColorPickerWithColorTileComponent: FC<ColorPickerWithColorTileProps> = ({
  alignment = ColorPickerAlignment.BottomLeft,
  backgroundColor,
  className,
  displayAlpha,
  displayNumericalColorsRepresentation,
  editable = true,
  height,
  isFixed,
  label,
  onChange,
  onClose,
  onOpen,
  selectedColor,
  tileStyle,
  width,
  ...restProps
}) => {
  const theme = useTheme();

  const ensuredBackgroundColor = useMemo(() =>
    backgroundColor || createColor(theme.backgroundColors.primary),
  [backgroundColor, theme.backgroundColors.primary],
  );

  return (
    <ColorPickerHolderWithPresetColorsContainer
      {...restProps}
      alignment={alignment}
      className={className}
      displayAlpha={displayAlpha}
      displayNumericalColorsRepresentation={displayNumericalColorsRepresentation}
      editable={editable}
      isFixed={isFixed}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      selectedColor={selectedColor}
    >
      <div css={wrapperStyle}>
        <div css={tileWrapperStyle({ height, width })}>
          <div css={positioningStyle({ height, width })}>
            <div
              css={[overridableBaseSquareLayerStyle, tileStyle,
                baseSquareLayerStyle({ borderColor: ensuredBackgroundColor, displayAlpha })]}
            />
          </div>
          <div css={positioningStyle({ height, width })}>
            <div
              css={[colorTileStyle({
                backgroundColor: ensuredBackgroundColor,
                theme,
                tileColor: selectedColor,
                displayAlpha,
              }), tileStyle,
              ]}
            />
          </div>
        </div>
        {label && (
          <div css={colorTileLabelStyle}>{label}</div>
        )}
      </div>
    </ColorPickerHolderWithPresetColorsContainer>
  );
};
