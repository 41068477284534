import { HOME_ROUTE } from '~/_shared/constants/routes';
import { endImpersonation } from '~/impersonation/impersonation.repository';
import { ModalType } from '~/modal/modalType.enum';
import { mapReset } from '~/store/map/map.actionCreators';
import { openModal } from '~/store/modal/modal.actionCreators';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';
import { appStore } from '../../store/app.store';
import { AuthBroadcastChannel } from '../authContext/auth.utils';
import { ImpersonationStatusChange } from './components/impersonationChangedModal';

const IMPERSONATION_TOKEN_EXP_KEY = 'IMPERSONATION_TOKEN_EXP';
const IMPERSONATION_LOCK_NAME = 'IMPERSONATION_LOCK';
const minValidityForImpersonationEnd = 60 * 1000;

const shouldEndImpersonation = (): boolean => {
  const expiresAt = localStorage.getItem(IMPERSONATION_TOKEN_EXP_KEY);
  const now = Date.now();

  if (!expiresAt) {
    return false;
  }

  const expiresIn = Number(expiresAt) - now;

  return expiresIn <= minValidityForImpersonationEnd;
};

type EndReason = typeof ImpersonationStatusChange.Expired | typeof ImpersonationStatusChange.Ended;
const end = async ({ reason, requireRefresh }: { reason: EndReason; requireRefresh: boolean }) => {
  try {
    await endImpersonation();
    appStore.dispatch(openModal(ModalType.ImpersonationChanged, { reason, requireRefresh }));
    AuthBroadcastChannel.postMessage({ type: 'impersonationChanged', reason });
    localStorage.removeItem(IMPERSONATION_TOKEN_EXP_KEY);
  }
  catch (e) {
    console.error('Error while ending impersonation', e);
  }
};

const isImpersonated = () => !!localStorage.getItem(IMPERSONATION_TOKEN_EXP_KEY);

export const ImpersonationService = {
  start(cookieExpiration: string) {
    const expireAtTimestamp = Date.parse(cookieExpiration);
    if (isNaN(expireAtTimestamp)) {
      return;
    }
    localStorage.setItem(IMPERSONATION_TOKEN_EXP_KEY, expireAtTimestamp.toString());
    AuthBroadcastChannel.postMessage({ type: 'impersonationChanged', reason: 'started' });
  },
  async checkExpiration() {
    if (!shouldEndImpersonation()) {
      return;
    }

    await navigator.locks.request(IMPERSONATION_LOCK_NAME, async () => {
      if (shouldEndImpersonation()) {
        await end({ reason: ImpersonationStatusChange.Expired, requireRefresh: true });
      }
    });
  },
  async end() {
    await navigator.locks.request(IMPERSONATION_LOCK_NAME, async () => {
      if (isImpersonated()) {
        await end({ reason: ImpersonationStatusChange.Ended, requireRefresh: false });
      }
      appStore.dispatch(userGetDataRequest({ fetchAnnouncements: true }));
      appStore.dispatch(mapReset());
      window.router.navigate(HOME_ROUTE);
    });
  },
  isImpersonated,
};
