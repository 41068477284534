import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createSpreadsheetMetricModelForDataActions } from '~/_shared/utils/metric/defaultMetric.helpers';
import { useDefaultMetrics } from '~/_shared/utils/metric/defaultMetric.hook';
import {
  mapMetricsToServerModel, type MetricModel,
} from '~/_shared/utils/metric/metrics.factory';
import { areMetricsTheSame } from '~/_shared/utils/metric/metrics.helpers';
import { MetricsDataAction } from '~/_shared/utils/metric/metrics.types';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  type DatasetOptimizedTerritoriesResponse,
  GeoUnits,
  getDatasetOptimizedTerritories,
} from '~/store/boundaries/boundaries.repository';
import {
  boundaryGroupsSelector,
  useCustomBoundaryGroupsSelector,
  useGetBoundaryGroup,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { boundaryCreateOptimizedAddItem } from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.actionCreators';
import { INITIAL_BOUNDARY_CREATE_OPTIMIZED_STATUS } from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import {
  boundarySelectOptimizedTerritoriesSubsetOpenModal,
  boundarySelectSetSelectType,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { BoundarySelectType } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import { useSpreadsheetMathupDataSelector as useSpreadsheetMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { usePrimarySpreadsheetFilterTreeItem } from '~/store/spreadsheetData/filtering/usePrimarySpreadsheetFilterTreeItemRequest';
import { CreateBoundaryGroupFromAIModalComponent } from './createBoundaryGroupFromAIModal.component';
import {
  getBoundariesGroupIdByType,
  getTargetNumberOfTerritories,
  populateDemographicValues,
} from './createBoundaryGroupFromAIModal.helpers';
import {
  BoundaryGroupFromAIModalSteps,
  BuildingBlockSelections,
  BuildingBlockSubsetTypes,
  type CreateBoundaryGroupFromAIConfig,
} from './createBoundaryGroupFromAIModal.types';
import { useGetDatasetBoundariesOptimizedTerritories } from './useDatasetBoundariesOptimizedTerritories';
import { useGetDatasetSpreadsheetOptimizedTerritories } from './useDatasetSpreadsheetOptimizedTerritories';
import { useGenerateOptimizedTerritories } from './useGenerateOptimizedTerritories';
import { useGetColumnsConstraints } from './useGetColumnsConstraints';
import { useNumberOfTerritoriesCount } from './useNumberOfTerritoriesCount';

const createEmptyConfiguration: () => CreateBoundaryGroupFromAIConfig = () => ({
  allStates: false,
  applyFilterOnData: false,
  buildingBlock: null,
  buildingBlockSelection: 0,
  buildingBlockSubsetType: BuildingBlockSubsetTypes.ON_MAP_BY_LASSO,
  dataMetricsValues: [],
  demographicsValues: [],
  groupName: '',
  numberOfPreviousTerritories: null,
  numberOfSalesReps: null,
  numberOfTerritories: null,
  optimizationConstraints: false,
  overrideTerritory: false,
  previouslySavedBoundaryGroupId: null,
  respectPrevTerritories: false,
  respectSalesLocations: false,
  salesRepLocationsMap: null,
  salesRepNameColumnId: null,
  selectedBoundaryIds: [],
  useNumberOfTerritories: false,
});

export type CreateBoundaryGroupFromAIModalContainerProps = ModalProps<{
  initialConfig?: CreateBoundaryGroupFromAIConfig;
  initialStep?: BoundaryGroupFromAIModalSteps;
}>;

export const CreateBoundaryGroupFromAIModalContainer: FC<CreateBoundaryGroupFromAIModalContainerProps> = ({
  initialConfig,
  initialStep,
  isOpen,
  onClose,
}: CreateBoundaryGroupFromAIModalContainerProps) => {
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const [currentStep, setCurrentStep] = useState<BoundaryGroupFromAIModalSteps>(initialStep ?? BoundaryGroupFromAIModalSteps.BuildingBlock);
  const [config, setConfig] = useState<CreateBoundaryGroupFromAIConfig>({ ...createEmptyConfiguration(), ...initialConfig });
  const [optimizedTerritoriesRequestError, setOptimizedTerritoriesRequestError] = useState<string | null>(null);
  const { createOptimizedTerritoriesRequest } = useGenerateOptimizedTerritories();
  const { getDatasetBoundariesOptimizedTerritoriesRequest } = useGetDatasetBoundariesOptimizedTerritories();
  const { getDatasetSpreadsheetOptimizedTerritoriesRequest } = useGetDatasetSpreadsheetOptimizedTerritories();
  const spreadsheetId = usePrimarySpreadsheetId();
  const boundariesGroups = useSelector(boundaryGroupsSelector);
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  const { columnsConstraints, isLoading: isLoadingColumnsConstraints } = useGetColumnsConstraints(spreadsheetId);
  const spreadsheetMatchupData = useSpreadsheetMatchupDataSelector(spreadsheetId);
  const filter = usePrimarySpreadsheetFilterTreeItem();
  const boundariesGroupsZipAndStatesIds = useMemo(() => getBoundariesGroupIdByType(boundariesGroups), [boundariesGroups]);
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const { computeDefaultMetrics } = useDefaultMetrics();
  const [isGeneratingOptimizedTerritories, setIsGeneratingOptimizedTerritories] = useState(false);
  const [isLoadingSubsetBlocks, setIsLoadingSubsetBlocks] = useState(false);

  const { numberOfTerritoriesUpdateReason, onRefreshTerritoriesCount, handleNumberOfTerritoriesChange, numberOfTerritoriesLoading } = useNumberOfTerritoriesCount({ config, setConfig });

  const onGoToStep = useCallback((step: BoundaryGroupFromAIModalSteps) => {
    setCurrentStep(step);
  }, []);

  useEffect(() => {
    setConfig(previous => ({
      ...previous,
      dataMetricsValues: Object.entries(columnsConstraints).map(([columnId, { name, min, max, numericPercent }]) => ({
        id: columnId,
        name,
        checked: false,
        weight: 100,
        isConstraint: false,
        min,
        max,
        numericPercent,
      })),
    }));
  }, [columnsConstraints]);

  useEffect(() => {
    const isZip = config.buildingBlock === GeoUnits.US_ZIP_CODES;
    const boundaryGroupId = isZip
      ? boundariesGroupsZipAndStatesIds.zipsGroupId
      : boundariesGroupsZipAndStatesIds.statesGroupId;

    if (boundaryGroupId) {
      const boundaryGroup = getBoundaryGroupById(boundaryGroupId);
      const demographicConstraints = boundaryGroup?.wms.constraints;

      if (demographicConstraints) {
        setConfig(previous => ({
          ...previous,
          demographicsValues: populateDemographicValues(t, demographicConstraints),
        }));
      }
    }
  }, [boundariesGroups, boundariesGroupsZipAndStatesIds.statesGroupId, boundariesGroupsZipAndStatesIds.zipsGroupId, config.buildingBlock, getBoundaryGroupById, t]);

  useEffect(() => {
    const getSubsetsForWMS = async () => {
      try {
        setIsLoadingSubsetBlocks(true);
        if (clientId === null || !config.selectedBoundaryIds.length) {
          return;
        }
        const wmsCodes = await getDatasetOptimizedTerritories(clientId, {
          ...(config.buildingBlock === GeoUnits.US_STATES ? { states: config.selectedBoundaryIds } : {}),
          ...(config.buildingBlock === GeoUnits.US_ZIP_CODES ? { zips: config.selectedBoundaryIds } : {}),
        });

        setConfig((prevState) => ({
          ...prevState,
          statesSubset: [...(wmsCodes.data.dataset_states ? [...wmsCodes.data.dataset_states] : [])],
          zipCodesSubset: [...(wmsCodes.data.dataset_zip_codes ? [...wmsCodes.data.dataset_zip_codes] : [])],
        }));
      }
      catch {
        console.error('Error during fetching codes for wms');
      }
      finally {
        setIsLoadingSubsetBlocks(false);
      }
    };
    getSubsetsForWMS();
  }, [config.selectedBoundaryIds, config.buildingBlock, clientId]);

  const onSelectSubsetFromMap = useCallback(() => {
    onClose();
    dispatch(boundarySelectSetSelectType(
      config.buildingBlockSubsetType === BuildingBlockSubsetTypes.ON_MAP_BY_CLICK
        ? BoundarySelectType.Click
        : config.buildingBlockSubsetType === BuildingBlockSubsetTypes.ON_MAP_BY_HOVER
          ? BoundarySelectType.HoverIdle
          : BoundarySelectType.Lasso
    ));
    dispatch(boundarySelectOptimizedTerritoriesSubsetOpenModal({
      boundaryGroupId: config.buildingBlock === GeoUnits.US_ZIP_CODES
        ? boundariesGroupsZipAndStatesIds.zipsGroupId
        : boundariesGroupsZipAndStatesIds.statesGroupId,
      selectedBoundaryIds: config.selectedBoundaryIds,
      configuration: config,
    }));
  }, [boundariesGroupsZipAndStatesIds.statesGroupId, boundariesGroupsZipAndStatesIds.zipsGroupId,
    config, dispatch, onClose]
  );

  const onChange = useCallback((update: Partial<CreateBoundaryGroupFromAIConfig>) => {
    setOptimizedTerritoriesRequestError(null);
    setConfig(previous => ({
      ...previous,
      ...update,
    }));

    handleNumberOfTerritoriesChange(update);
  }, [handleNumberOfTerritoriesChange]);

  const onCloseOptimizedTerritoriesRequestError = useCallback(() => {
    setOptimizedTerritoriesRequestError(null);
  }, []);

  const zipStatesColumns = useMemo<{
    zipColumn: string | null;
    stateColumn: string | null;
  }>(() => {
    if (spreadsheetMatchupData === null) {
      return {
        zipColumn: null,
        stateColumn: null,
      };
    }
    return {
      zipColumn: spreadsheetMatchupData.categories.zip.match,
      stateColumn: spreadsheetMatchupData.categories.state.match,
    };
  }, [spreadsheetMatchupData]);

  const onSubmit = useCallback(async () => {
    if (clientId && mapId && spreadsheetId && config.buildingBlock) {
      setOptimizedTerritoriesRequestError(null);
      setIsGeneratingOptimizedTerritories(true);
      let datasetResponse: DatasetOptimizedTerritoriesResponse | null = null;
      try {
        if (config.buildingBlockSelection === BuildingBlockSelections.SUBSET) {
          if (config.buildingBlockSubsetType === BuildingBlockSubsetTypes.FROM_DATA) {
            datasetResponse = await getDatasetSpreadsheetOptimizedTerritoriesRequest({
              clientId,
              spreadsheetId,
              zips: config.buildingBlock === GeoUnits.US_ZIP_CODES ? zipStatesColumns.zipColumn ?? undefined : undefined,
              states: config.buildingBlock === GeoUnits.US_STATES ? zipStatesColumns.stateColumn ?? undefined : undefined,
            });
          }
          else {
            datasetResponse = await getDatasetBoundariesOptimizedTerritoriesRequest({
              clientId,
              zips: config.buildingBlock === GeoUnits.US_ZIP_CODES ? config.selectedBoundaryIds : undefined,
              states: config.buildingBlock === GeoUnits.US_STATES ? config.selectedBoundaryIds : undefined,
            });
          }
        }

        const { defaultSpreadsheetColumnMetrics, defaultDemographicsMetrics } = await computeDefaultMetrics();

        const creationParametersMetrics: MetricModel[] = [];

        config.dataMetricsValues.forEach((metric) => {
          if (!metric.checked) {
            return;
          }
          creationParametersMetrics.push(...createSpreadsheetMetricModelForDataActions(
            metric.id,
            spreadsheetId,
            metric.name,
            [MetricsDataAction.HIGH_VALUE, MetricsDataAction.LOW_VALUE, MetricsDataAction.SUM, MetricsDataAction.AVERAGE]
          ));
        });

        const filteredDefaultMetric = [...defaultSpreadsheetColumnMetrics, ...defaultDemographicsMetrics].filter((defaultMetric) => {
          return !creationParametersMetrics.some((creationMetric) => areMetricsTheSame(defaultMetric, creationMetric));
        });

        const combinedMetrics = [...creationParametersMetrics, ...filteredDefaultMetric];

        const serverModelMetrics = mapMetricsToServerModel(combinedMetrics);

        const response = await createOptimizedTerritoriesRequest({
          clientId,
          mapId,
          displayName: config.groupName,
          spreadsheetId,
          targetTerritoryCount: getTargetNumberOfTerritories(config) ?? undefined,
          dataMetricsValues: config.dataMetricsValues,
          demographicsValues: config.demographicsValues,
          geounitId: config.buildingBlock,
          respectTerritoryModel: config.respectPrevTerritories ? config.previouslySavedBoundaryGroupId ?? undefined : undefined,
          datasetZipCodes: config.buildingBlock === GeoUnits.US_ZIP_CODES ? datasetResponse?.data?.dataset_zip_codes : undefined,
          datasetStates: config.buildingBlock === GeoUnits.US_STATES ? datasetResponse?.data?.dataset_states : undefined,
          salesRepLocationsMap: (config.respectSalesLocations && config.salesRepLocationsMap && config.salesRepNameColumnId) ? {
            mapId: config.salesRepLocationsMap.mapId,
            nameColumnId: config.salesRepNameColumnId,
          } : undefined,
          filter,
          allStates: config.allStates,
          settings: {
            metrics: serverModelMetrics,
          },
        });
        dispatch(boundaryCreateOptimizedAddItem({
          id: response.data.id,
          status: INITIAL_BOUNDARY_CREATE_OPTIMIZED_STATUS,
          startedOnCurrentTab: true,
        }));
        onClose();
      }
      catch (e) {
        setOptimizedTerritoriesRequestError(e.message);
      }
      finally {
        setIsGeneratingOptimizedTerritories(false);
      }
    }
  }, [clientId, mapId, spreadsheetId, config, filter, createOptimizedTerritoriesRequest, dispatch, onClose,
    getDatasetSpreadsheetOptimizedTerritoriesRequest, zipStatesColumns.zipColumn, zipStatesColumns.stateColumn,
    getDatasetBoundariesOptimizedTerritoriesRequest, computeDefaultMetrics]);

  return (
    <CreateBoundaryGroupFromAIModalComponent
      config={config}
      currentStep={currentStep}
      customBoundaryGroups={customBoundaryGroups}
      isGenerateOptimizedTerritoriesLoading={isGeneratingOptimizedTerritories}
      isLoadingDataMetrics={isLoadingColumnsConstraints}
      isLoadingSubsetBlocks={isLoadingSubsetBlocks}
      isOpen={isOpen}
      numberOfTerritoriesLoading={numberOfTerritoriesLoading}
      numberOfTerritoriesUpdateReason={numberOfTerritoriesUpdateReason}
      onChange={onChange}
      onClose={onClose}
      onCloseOptimizedTerritoriesRequestError={onCloseOptimizedTerritoriesRequestError}
      onGoToStep={onGoToStep}
      onRefreshTerritoriesCount={onRefreshTerritoriesCount}
      onSelectSubsetFromMap={onSelectSubsetFromMap}
      onSubmit={onSubmit}
      optimizedTerritoriesRequestError={optimizedTerritoriesRequestError}
      markerFilterActive={!!filter}
    />
  );
};
