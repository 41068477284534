import { useCallback } from 'react';
import { type Translations } from '../../../translations/translations.type';
import { type ApiError } from '../../utils/api/apiError.helpers';
import { identity } from '../../utils/function.helpers';
import { useTranslation } from '../../utils/hooks';

export type ApiErrorMessageProps = {
  error?: ApiError | null;
};

export const ApiErrorMessage = ({ error }: ApiErrorMessageProps) => {
  const [t, i18n] = useTranslation();

  const translateExisting = useCallback((e: string) => {
    const apiErrorTranslationKey = `ApiError.${e}`;
    if (i18n.exists(apiErrorTranslationKey)) {
      return t(apiErrorTranslationKey as keyof Translations);
    }
    else {
      console.warn(`Missing translation '${apiErrorTranslationKey}'`);
      return false;
    }
  }, [i18n, t]);

  if (!error) {
    return null;
  }
  const errorsToShow = [error.errorCode, ...(error.errors ? Object.values(error.errors).flatMap(identity) : [])].filter(identity);

  if (errorsToShow.length === 0) {
    console.warn('No error codes to show');
    return null;
  }

  const errorMessages = errorsToShow.map(translateExisting)
    .filter(identity)
    .join(', ');

  return <>{errorMessages}</>;
};
