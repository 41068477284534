import { type UserFileType } from '../../../_shared/types/file.types';
import {
  type FileMapListResponse,
  getAllUserFilesRequest,
  getBulkFilesRequest,
  getFileMapListRequest,
  type RemoveFileResponse,
  removeUserFileRequest, type UploadFile,
  uploadFileRequest,
  type UserFilesResponse,
} from './fileApi.repository';

export const uploadFile = (file: File, clientId: number|null, type: UserFileType, progressCallback: (percent: number) => void): Promise<UploadFile> => {
  return uploadFileRequest(clientId, { file, type, user: false }, progressCallback);
};

export const getAllUserFiles = (clientId: number, type: UserFileType): Promise<UserFilesResponse> => {
  return getAllUserFilesRequest(clientId, { type });
};

export const removeUserFile = (clientId: number, fileId: number): Promise<RemoveFileResponse> => {
  return removeUserFileRequest(clientId, fileId);
};

export const getBulkFiles = (clientId: number|null, ids: number[], mapId: number|null): Promise<UserFilesResponse> => {
  const uniqueIds: number[] = [...new Set(ids)];
  return getBulkFilesRequest(clientId, uniqueIds, mapId);
};

export const getFileMapList = (clientId: number, fileId: number): Promise<FileMapListResponse> => {
  return getFileMapListRequest(clientId, fileId);
};
