import { type FC } from 'react';
import { type SpreadsheetRowId } from '../../../_shared/types/spreadsheetData/spreadsheetRow';
import { CustomizeLocationPanelContainer } from '../../../customizeLocationPanel/customizeLocationPanel.container';
import { type RightSidebarOpeneableApps } from './rightSidebarConfiguration';

export const LocationPanel: FC<Readonly<{
  markerId: SpreadsheetRowId;
  onClose: () => void;
  openOrCloseApp: (app: RightSidebarOpeneableApps, isOpened: boolean) => void;
}>> = props => (
  <CustomizeLocationPanelContainer
    onClose={props.onClose}
    openOrCloseApp={props.openOrCloseApp}
  />
);
