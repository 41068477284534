import {
  useEffect, useMemo,
} from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import {
  hasBoundaryItemChanged, hasZoomLevelDetailChanged,
} from '~/store/boundaryItems/boundaryItems.helpers';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { usePrimaryBoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/usePrimaryBoundaryTerritoryGroup';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useBoundarySelectActiveSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { type MapBoundaryManager } from '../../mapBoundary.manager';
import { useMapBoundaryDistanceToPixels } from './useMapBoundaryDistanceToPixels';
import { useMapBoundaryLabelFilter } from './useMapBoundaryLabelFilter';
import { useMapBoundaryPolygonFilter } from './useMapBoundaryPolygonFilter';

export const useMapBoundaryPolygons = (manager?: MapBoundaryManager, map?: google.maps.Map) => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryItems = useMapBoundariesSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const mapZoom = useMapComponentZoomSelector();
  const boundarySelect = useBoundarySelectActiveSelector();

  const previousManager = usePrevious(manager);
  const forceUpdate = !previousManager && previousManager !== manager;

  const previousMapZoom = usePrevious(mapZoom);
  const previousBoundaryItems = usePrevious(boundaryItems);

  const boundaryPolygonFilters = useMapBoundaryPolygonFilter();
  const previousBoundaryPolygonFilters = usePrevious(boundaryPolygonFilters);

  const boundaryGroupsWithLabels = useMemo(() =>
    boundaryTerritoryGroups
      .filter(btg => !btg.settings.hideLabels)
      .map(btg => btg.boundaryGroupId)
      .filter(id => id !== boundarySelect?.selectBoundaryGroupId)
  , [boundarySelect?.selectBoundaryGroupId, boundaryTerritoryGroups]);

  const distanceToPixelsScale = useMapBoundaryDistanceToPixels(map);
  const boundaryLabelFilters = useMapBoundaryLabelFilter({
    boundaryGroupIds: boundaryGroupsWithLabels,
    distanceToPixelsScale,
    textDimensionsCalculator: manager?.textDimensionsCalculator,
  });
  const previousBoundaryLabelFilters = usePrevious(boundaryLabelFilters);

  const primaryBoundaryGroup = usePrimaryBoundaryTerritoryGroup();

  // Remove polygons when boundaries are removed from map
  useEffect(() => {
    if (!manager) {
      return;
    }

    const usedBoundaries = new Set(boundaryTerritoryGroups.map(g => g.boundaryGroupId));
    manager.removeUnusedBoundaryGroups(usedBoundaries);
  }, [boundaryTerritoryGroups, manager]);

  useEffect(() => {
    if (!manager || !mapZoom || !primaryBoundaryGroup) {
      return;
    }

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {

      const boundaryGroup = boundaryGroups.find(g => g.id === boundaryTerritoryGroup.boundaryGroupId);
      if (!boundaryGroup) {
        continue;
      }

      const shouldUpdate = forceUpdate
        || hasBoundaryItemChanged(boundaryGroup.id, boundaryItems, previousBoundaryItems)
        || hasZoomLevelDetailChanged(boundaryGroup, mapZoom, previousMapZoom)
        || hasBoundaryFilterChanged(boundaryGroup.id, boundaryPolygonFilters, previousBoundaryPolygonFilters)
        || hasBoundaryFilterChanged(boundaryGroup.id, boundaryLabelFilters, previousBoundaryLabelFilters);

      if (!shouldUpdate) {
        continue;
      }

      const boundariesData = boundaryItems.get(boundaryTerritoryGroup.boundaryGroupId);
      if (!boundariesData) {
        continue;
      }

      const filter = boundaryPolygonFilters.get(boundaryGroup.id) ?? (() => false);
      const labelFilter = boundaryLabelFilters.get(boundaryGroup.id) ?? (() => null);

      manager.updateBoundaryGroupPolygons({ boundaryGroup, boundariesData, mapZoomLevel: mapZoom, filter, labelFilter });
    }

  }, [boundaryGroups, boundaryItems, boundaryLabelFilters, boundaryPolygonFilters, boundaryTerritoryGroups, forceUpdate,
    manager, mapZoom, previousBoundaryItems, previousBoundaryLabelFilters, previousBoundaryPolygonFilters, previousMapZoom, primaryBoundaryGroup]);
};

const hasBoundaryFilterChanged = (boundaryGroupId: number, currentFilters: ReadonlyMap<BoundaryGroupId, any>, previousFilters?: ReadonlyMap<BoundaryGroupId, any>) => {
  const current = currentFilters.get(boundaryGroupId);
  const previous = previousFilters?.get(boundaryGroupId);

  return current !== previous;
};
