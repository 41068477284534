import {
  memo, type ReactElement,
  useCallback, useEffect, useState,
} from 'react';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { KeyboardKeys } from '~/_shared/utils/hooks/useKeyPress';
import {
  type MapKeyPressCallback,
  MapKeyPressPriority, useMapKeyPressContext,
} from '~/_shared/utils/hooks/useMapKeyPressContext';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { FPS60 } from '~/_shared/utils/throttle/throttle';
import { ensureVertexNotOverlapping } from '~/_shared/utils/webgl/mapVertices.helpers';
import { type MapObjectZIndex } from '../mapObject.types';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';
import { MapObjectContextProvider } from '../private/mapObjectContext';
import { useMapObjectManager } from '../private/useMapObjectManager';
import { type MapShapeInstance } from './mapShapeModel';
import { MapShapeContextProvider } from './private/mapShapeContext';
import { MapShapeLines } from './private/mapShapeLines.component';

export type ShapeSubmitOptions = {
  FirstOutline: boolean;
  Esc: boolean;
  DoubleClick: boolean;
};

export type ShapeSubmitOption = keyof ShapeSubmitOptions;

const defaultSubmitOptions = { FirstOutline: true, Esc: true, DoubleClick: true };

export type DrawMapShapeProps = {
  zIndex: MapObjectZIndex;
  submitOptions?: ShapeSubmitOptions;
  onDone?: (how: ShapeSubmitOption) => void;
  onChange?: (shape: MapShapeInstance) => void;
  getCursorLabel?: (position: LatLng) => string | null | undefined;
  renderLine?: (id: string, start: LatLng, end: LatLng) => ReactElement | null;
  renderOutline?: (id: string, outline: MapObjectOutlineInstance) => ReactElement | null;
  startingPoint?: LatLng | null;
};

const DrawMapShape: React.FC<DrawMapShapeProps> = (props) => {
  const { zIndex, submitOptions = defaultSubmitOptions } = props;
  const { onDone, onChange, getCursorLabel } = props;
  const { renderOutline, renderLine } = props;

  const [shape, setShape] = useState<MapShapeInstance>();
  const [firstOutlineHover, setFirstOutlineHover] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<LatLng>();

  const manager = useMapObjectManager();

  useEffect(() => { // Start drawing
    const emptyInstance = { id: createUuid(), outlines: [] };

    const startingPoint = props.startingPoint;
    if (startingPoint) {
      setShape(() => {
        const startingOutline = manager.createOutlineFromLatLng(startingPoint);
        return {
          ...emptyInstance,
          outlines: [startingOutline],
        };
      });
    }
    else {
      setShape(emptyInstance);
    }

    return () => { // Cleanup
      setShape(undefined);
      setFirstOutlineHover(false);
    };
  }, [manager, props.startingPoint]);

  // Map click when drawing
  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (!shape || firstOutlineHover || !event.latLng) {
      return;
    }

    const outline = manager.createOutlineFromLatLng({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });

    // prevent adding the same outline at last and one before last position to prevent adding double points or lines
    const outlineExists = !!shape.outlines.slice(-2).find(
      shapeOutline => shapeOutline.lat === outline.lat && shapeOutline.lng === outline.lng
    );

    if (outlineExists) {
      return;
    }

    const outlineLatLngWithoutOverlaps = ensureVertexNotOverlapping(outline, shape.outlines);
    const updatedShape = {
      ...shape,
      outlines: [...shape.outlines, { ...outline, ...outlineLatLngWithoutOverlaps }],
    };

    setShape(updatedShape);
    onChange?.(updatedShape);

  }, [manager, shape, firstOutlineHover, onChange]);

  useEffect(() => { // Registers onMapClick
    if (!onMapClick) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);

    // Cleanup
    return () => {
      clickListener.remove();
    };
  }, [onMapClick, manager]);

  // Mouse move while drawing DC instance
  // Throttle mouse move event when drawing so it won't affect performance
  const onMouseMove = useThrottle((event: google.maps.MapMouseEvent) => {
    if (!shape?.outlines.length || !event.latLng) {
      return;
    }

    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };

    setCursorPosition(position);

    const text = getCursorLabel?.(position);
    if (text) {
      manager.upsertCursorLabel(position, text, zIndex.outlineLabel);
    }
    else {
      manager.removeCursorLabel();
    }
  }, [manager, zIndex.outlineLabel, shape, getCursorLabel], FPS60);

  useEffect(() => { // Registers onMouseMove
    const moveListener = manager.addMapMouseMoveListener(onMouseMove);

    return () => { // Cleanup
      moveListener.remove();
      manager.removeCursorLabel();
      setCursorPosition(undefined);
    };
  }, [onMouseMove, manager]);

  useEffect(() => { // First outline hover, register finish drawing on outline click
    if (!submitOptions.FirstOutline || !shape || shape?.outlines.length < 3) {
      return;
    }

    const firstOutline = shape?.outlines[0];
    if (!firstOutline) {
      return;
    }

    const mouseOverCleanup = manager.addOutlineMarkerEventListener(firstOutline.id, 'mouseover',
      () => {
        setFirstOutlineHover(true);
      });

    const mouseOutCleanup = manager.addOutlineMarkerEventListener(firstOutline.id, 'mouseout',
      () => {
        setFirstOutlineHover(false);
      });

    const mouseClickCleanup = manager.addOutlineMarkerEventListener(firstOutline.id, 'click',
      () => onDone?.('FirstOutline'));

    return () => {
      mouseOverCleanup();
      mouseOutCleanup();
      mouseClickCleanup();
    };
  }, [shape, manager, submitOptions.FirstOutline, onDone]);

  const { addMapKeyHandler } = useMapKeyPressContext();

  useEffect(() => { // Finish drawing on ESC
    if (!shape || !onDone || !submitOptions.Esc) {
      return;
    }

    const onEsc: MapKeyPressCallback = (e) => {
      onDone('Esc');
      e.stopPropagation();
    };

    const removeKeyHandler = addMapKeyHandler(KeyboardKeys.Escape, MapKeyPressPriority.FinishDrawing, onEsc);
    return removeKeyHandler;
  }, [shape, submitOptions.Esc, onDone, addMapKeyHandler]);

  useEffect(() => { // Finish drawing on double click
    if (!shape) {
      return;
    }

    const onDblClick = () => onDone?.('DoubleClick');

    if (submitOptions.DoubleClick && !firstOutlineHover) {
      document.addEventListener('dblclick', onDblClick);
    }

    return () => {
      document.removeEventListener('dblclick', onDblClick);
    };
  }, [manager, shape, firstOutlineHover, submitOptions.DoubleClick, onDone]);

  if (!shape) {
    return null;
  }

  return (
    <MapObjectContextProvider value={{ manager, zIndex }}>
      <MapShapeContextProvider value={{ shape, isPolygon: false }}>
        {renderOutline && shape.outlines.map(outline => renderOutline(outline.id, outline))}
        {renderLine && (
          <>
            <MapShapeLines
              renderLine={renderLine}
            />
            {shape.outlines.length && cursorPosition &&
            renderLine(`cursor-line-${shape.id}`, shape.outlines.lastItem, cursorPosition)
            }
          </>
        )}
      </MapShapeContextProvider>
    </MapObjectContextProvider>
  );
};

const pureComponent = memo(DrawMapShape);
export { pureComponent as DrawMapShape };
