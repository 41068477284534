import { css } from '@emotion/react';
import {
  type FC,
  type ReactElement,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTranslation } from '~/_shared/utils/hooks';

export const LAYERED_MAP_MAP_INFO_CONTAINER_MAX_WIDTH = 500;

const containerStyle = ({ maxWidth }: { maxWidth: number | string }) => css({
  maxWidth,
});

const buttonPositionStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 10,
  justifyContent: 'end',
  marginTop: 8,
});

type ButtonProps = {
  isDisabled: boolean;
  text?: string;

  onClick: () => void;
};

type LayeredMapStepContentHolderComponentProps = Readonly<{
  children: ReactElement;
  buttonProps: ButtonProps;
  maxContentWidth?: number | string;
  secondaryButtonProps?: ButtonProps;
  additionalButton?: ReactElement;
}>;

export const LayeredMapSectionHolderComponent: FC<LayeredMapStepContentHolderComponentProps> = (props) => {
  const [t] = useTranslation();

  const maxContentWidth = props.maxContentWidth ?? LAYERED_MAP_MAP_INFO_CONTAINER_MAX_WIDTH;

  return (
    <div>
      <div css={containerStyle({ maxWidth: maxContentWidth })}>
        {props.children}
      </div>

      <div css={buttonPositionStyle}>
        {props.additionalButton}
        {props.secondaryButtonProps && (
          <ButtonComponent
            buttonStyle={ButtonStyle.Quaternary}
            text={props.secondaryButtonProps.text}
            onClick={props.secondaryButtonProps.onClick}
          />
        )}
        <ButtonComponent
          buttonStyle={ButtonStyle.Primary}
          isDisabled={props.buttonProps.isDisabled}
          onClick={props.buttonProps.onClick}
          text={props.buttonProps.text || t('Continue')}
        />
      </div>
    </div>
  );
};
