import { BaseMapThemeName } from './baseMapThemes.enums';
import { type BaseMapThemes } from './baseMapThemes.types';
import { baseMapThemesAubergine } from './baseMapThemesAubergine.constants';
import { baseMapThemesCourgette } from './baseMapThemesCourgette.constants';
import { baseMapThemesDark } from './baseMapThemesDark.constants';
import { baseMapThemesDeepWater } from './baseMapThemesDeepWater.constants';
import { baseMapThemesIce } from './baseMapThemesIce.constants';
import { baseMapThemesMint } from './baseMapThemesMint.constants';
import { baseMapThemesNight } from './baseMapThemesNight.constants';
import { baseMapThemesRetro } from './baseMapThemesRetro.constants';
import { baseMapThemesSilver } from './baseMapThemesSilver.constants';
import { baseMapThemesStandard } from './baseMapThemesStandard.constants';
import { baseMapThemesStorm } from './baseMapThemesStorm.constants';
import { baseMapThemesVintage } from './baseMapThemesVintage.constants';

export const baseMapThemes: BaseMapThemes = {
  [BaseMapThemeName.Aubergine]: baseMapThemesAubergine,
  [BaseMapThemeName.Courgette]: baseMapThemesCourgette,
  [BaseMapThemeName.Dark]: baseMapThemesDark,
  [BaseMapThemeName.DeepWater]: baseMapThemesDeepWater,
  [BaseMapThemeName.Ice]: baseMapThemesIce,
  [BaseMapThemeName.Mint]: baseMapThemesMint,
  [BaseMapThemeName.Night]: baseMapThemesNight,
  [BaseMapThemeName.Retro]: baseMapThemesRetro,
  [BaseMapThemeName.Silver]: baseMapThemesSilver,
  [BaseMapThemeName.Standard]: baseMapThemesStandard,
  [BaseMapThemeName.Storm]: baseMapThemesStorm,
  [BaseMapThemeName.Vintage]: baseMapThemesVintage,
};
