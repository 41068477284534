import {
  apiGet, apiPatch, apiPost,
} from '../_shared/utils/api/api.helpers';

type PresentationalColumnsRestrictionsListRequest = {
  map_id: number;
};

export type PresentationalColumnsRestrictionFlags = {
  master: boolean;
  marker: boolean;
  filter: boolean;
  export: boolean;
};

type PresentationalColumnsRestrictionsItem = {
  column_id: string;
  map_id: number;
  restriction_id: number;
  restrictions: PresentationalColumnsRestrictionFlags;
  spreadsheet_id: number;
};

export type PresentationalColumnsRestrictionsListResponse = {
  list: PresentationalColumnsRestrictionsItem[];
};

type PresentationalColumnsRestrictionsCreateRequest = {
  map_id: number;
  spreadsheet_id: number;
  column_id: string;
} & Partial<PresentationalColumnsRestrictionFlags>;

type PresentationalColumnsRestrictionsUpdateRequest = {
  restriction_id: number;
} & Partial<PresentationalColumnsRestrictionFlags>;

export type PresentationalColumnsRestrictionsCreateResponse = {
  message: string;
  records: PresentationalColumnsRestrictionsItem[];
};

export const getPresentationalColumnsRestrictionList = (
  clientId: number,
  request: PresentationalColumnsRestrictionsListRequest
): Promise<{data: PresentationalColumnsRestrictionsListResponse}> => {
  const requestUrl = `/api/clients/${clientId}/columns-restriction`;

  return apiGet(requestUrl, request);
};

export const createPresentationalColumnsRestriction = (
  clientId: number,
  requestParams: PresentationalColumnsRestrictionsCreateRequest[]
): Promise<{data: PresentationalColumnsRestrictionsCreateResponse}> => {
  const requestUrl = `/api/clients/${clientId}/columns-restriction`;

  return apiPost(requestUrl, {
    params: requestParams,
  });
};

export const updatePresentationalColumnsRestriction = (
  clientId: number,
  requestParams: PresentationalColumnsRestrictionsUpdateRequest[]
) => {
  const requestUrl = `/api/clients/${clientId}/columns-restriction`;

  return apiPatch(requestUrl, {
    params: requestParams,
  });
};
