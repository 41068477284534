import { useMemo } from 'react';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundarySelectSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import {
  calculateZIndex, ZIndexedEntity,
} from '../../../zIndexes/zIndexRanges';
import { type BoundaryExtraStyles } from '../boundaryView/mapBoundaryStyles/useMapBoundaryExtraStyles';
import { type BoundaryItemStyle } from '../mapBoundary.manager';

const emptyBaseStyles: ReadonlyMap<BoundaryGroupId, BoundaryItemStyle> = new Map();

const selectionBoundaryStyle = {
  fillOpacity: { value: .4 },
  fillColor: '#FF0',
} as const;

export const hoveredBoundaryStyle = {
  fillOpacity: { value: .4 },
  fillColor: '#0F0',
} as const;

export const selectedHoveredBoundaryStyle = {
  fillOpacity: { value: .6 },
  fillColor: '#F00',
} as const;

const getBaseStyle = (): BoundaryItemStyle => ({
  fillColor: '#000',
  fillOpacity: { value: 0 },
  borderColor: { value: '#000' },
  borderOpacity: { value: 1 },
  borderWidth: 1,
  zIndex: {
    polygon: calculateZIndex(0, ZIndexedEntity.BoundarySelectionPolygon),
    border: calculateZIndex(0, ZIndexedEntity.BoundarySelectionPolygonBorder),
    label: calculateZIndex(0, ZIndexedEntity.BoundarySelectionLabelText),
  },
  showPolygonOutline: false,
});

export const useSelectionMapBoundaryStyles = () => {
  const boundarySelection = useBoundarySelectSelector();

  const baseStyle = useMemo(() => getBaseStyle(), []);

  const baseStyles = useMemo<ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>>(() => {
    if (!boundarySelection.isModalOpen) {
      return emptyBaseStyles;
    }

    return new Map([[boundarySelection.selectBoundaryGroupId, baseStyle]]);
  }, [baseStyle, boundarySelection?.isModalOpen, boundarySelection?.selectBoundaryGroupId]);

  const selectedBoundariesStyles = useMemo<BoundaryExtraStyles>(() => {
    const selectedBoundaryStyles = new Map<BoundaryId, {boundary: BoundaryIdentifier; style: BoundaryItemStyle}>();

    if (boundarySelection.isModalOpen) {
      const style = getBoundarySelectionStyles(baseStyle);
      boundarySelection.selectedBoundaryIds.forEach(boundaryId =>
        selectedBoundaryStyles.set(boundaryId, { boundary: { boundaryId, boundaryGroupId: boundarySelection.selectBoundaryGroupId }, style }));

      if (boundarySelection.hoveredBoundaryId) {
        const hoverStyle = boundarySelection.selectedBoundaryIds.has(boundarySelection.hoveredBoundaryId)
          ? getBoundaryHoverStyles(baseStyle, selectedHoveredBoundaryStyle)
          : getBoundaryHoverStyles(baseStyle, hoveredBoundaryStyle);

        selectedBoundaryStyles.set(boundarySelection.hoveredBoundaryId, {
          boundary: { boundaryId: boundarySelection.hoveredBoundaryId, boundaryGroupId: boundarySelection.selectBoundaryGroupId },
          style: hoverStyle,
        });
      }
    }

    return { boundaryExtraStyles: selectedBoundaryStyles, boundariesWithExtraStyles: new Set(selectedBoundaryStyles.keys()) };
  }, [baseStyle, boundarySelection]);

  return {
    baseStyles,
    selectedBoundariesStyles,
  };
};

const getBoundarySelectionStyles = (
  baseStyle: BoundaryItemStyle
): BoundaryItemStyle => ({
  ...baseStyle,
  ...selectionBoundaryStyle,
  borderWidth: baseStyle.borderWidth,
});

const getBoundaryHoverStyles = (
  baseStyle: BoundaryItemStyle,
  hoverStyle: Partial<BoundaryItemStyle>
): BoundaryItemStyle => ({
  ...baseStyle,
  ...hoverStyle,
});
