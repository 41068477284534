import { css } from '@emotion/react';
import { faChartColumn } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo, useState,
} from 'react';
import {
  TinyMapColorTileComponent,
  type TinyMapColorTileComponentProps,
} from '~/_shared/baseComponents/tinyMapPlaceholder';
import { SidebarTitleComponent } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.component';
import { type SidebarTitle } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import {
  createColor, guaranteeHash,
} from '../_shared/components/colorPicker/colorPicker.helpers';
import { MetricsListComponent } from '../_shared/components/metricsList/metricsList.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type ThemeProps } from '../_shared/types/themeProps';
import { getFontAndBorderColorOnBackground } from '../_shared/utils/colors/colors.helpers';
import { useTranslation } from '../_shared/utils/hooks';
import { type MetricsResults } from '../_shared/utils/metric/useMetricData.helpers';
import { MapToolHeaderButtonComponent } from '../sidebar/sidebarApps/mapToolWrapper/mapTooHeaderButton.component';
import { getTotalLocationsStyle } from '../sidebar/sidebarApps/rightSidebar/helpers/getTotalLocationsStyle';
import { SidebarContentContainer } from '../sidebar/sidebarApps/rightSidebar/sidebarContent/sidebarContent.container';
import { type SidebarHeaderArrowsProps } from '../sidebar/sidebarApps/rightSidebar/sidebarHeader/sidebarHeader.component';
import { MetricsPanel } from './metricsPanel/metricsPanel';
import {
  type ProximityMenuButtons, type ProximityMenuCallbacks, useRightProximityDetailsMenu,
} from './roundButtonMenu/proximityRoundButtonMenu';

type ProximitySettingsPanelProps = Readonly<{
  additionalInfo: string;
  additionalInfoBackgroundColor: string;
  arrowProps?: SidebarHeaderArrowsProps;
  isLoading: boolean;
  isProximityVisible: boolean;
  locationDescription: SidebarTitle;
  locationIndex: number;
  menuButtonsCallbacks: ProximityMenuCallbacks;
  metrics: MetricsResults;
  totalLocations: number;
  markersCount: number;
  indicatorStyles?: Pick<TinyMapColorTileComponentProps, 'lineColor' | 'lineWidth' | 'color'>;
  isDriveTimePolygon: boolean;

  onCustomizeMetricsClick?: () => void;
  onClose: () => void;
}>;

const containerStyle = css({
  paddingLeft: '8px',
});

const customizeMetricsWrapperStyle = css({
  background: 'inherit',
  padding: 8,
});

const additionalInfoStyle = ({ backgroundColor, theme }: ThemeProps<{ backgroundColor: string }>) => {
  const [borderColor, fontColor] = getFontAndBorderColorOnBackground(
    createColor(theme.textColors.primary),
    createColor(backgroundColor),
    createColor(theme.backgroundColors.secondary)
  );

  return css({
    backgroundColor,
    border: `1px solid ${guaranteeHash(borderColor.hex)}`,
    whiteSpace: 'nowrap',
    color: guaranteeHash(fontColor.hex),
  });
};

export const ProximityDetailsPanelComponent: FC<ProximitySettingsPanelProps> = props => {
  const [selectedMenuButton, setSelectedMenuButton] = useState<ProximityMenuButtons | null>(null);
  const [t] = useTranslation();
  const theme = useTheme();

  const proximityDetailsMenu = useRightProximityDetailsMenu(props);

  const handleClose = (): void => {
    props.onClose();
    setSelectedMenuButton(null);
  };

  const proximityIndicator = useMemo(() => {
    if (!props.indicatorStyles) {
      return undefined;
    }

    return (
      <TinyMapColorTileComponent
        {...props.indicatorStyles}
        fill
        outline={props.isDriveTimePolygon}
        circle={!props.isDriveTimePolygon}
      />
    );
  }, [props.indicatorStyles, props.isDriveTimePolygon]);

  return (
    <SidebarContentContainer
      isLoading={props.isLoading}
      body={(
        <MetricsPanel>
          <MetricsListComponent
            metrics={props.metrics}
          />
        </MetricsPanel>
      )}
      description={(
        <SidebarTitleComponent
          onXClick={handleClose}
          sidebarTitle={props.locationDescription}
          containerStyle={containerStyle}
          indicator={proximityIndicator}
          sidebarTitleStyle={getTotalLocationsStyle(theme)}
        />
      )}
      headerProps={{
        arrowProps: props.arrowProps,
        currentLocationIndex: props.locationIndex,
        totalLocations: props.totalLocations,
        additionalInfo: props.additionalInfo,
        additionalInfoStyle: additionalInfoStyle({
          backgroundColor: props.additionalInfoBackgroundColor,
          theme,
        }),
      }}
      secondaryHeader={props.onCustomizeMetricsClick ? (
        <MapToolHeaderButtonComponent
          css={customizeMetricsWrapperStyle}
          text={t('Customize Metrics')}
          prefixIcon={faChartColumn}
          onClick={props.onCustomizeMetricsClick}
        />
      ) : null}
      menuProps={{
        roundButtonRightDetails: proximityDetailsMenu,
        selectedButton: selectedMenuButton,
      }}
    />
  );
};
