import {
  type FC, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ADMIN_MAP_PAGES } from '~/_shared/components/modal/modal.constants';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  isSearchItemWithColumn, type SearchItem,
} from '~/map/search/mapSearch.helpers';
import { useCancelOptimizedTerritories } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/useCancelOptimizedTerritory';
import { announcementsMarkAsSeenRequest } from '~/store/announcements/announcements.actionCreators';
import { useAnnouncementsSelector } from '~/store/announcements/announcements.selector';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useBoundaryCreateOptimizedPendingItemsSelector } from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.selectors';
import { BoundaryCreateOptimizedStatus } from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import {
  geocodingPauseRequest, geocodingResumeRequest,
} from '~/store/geocoding/geocoding.actionCreators';
import { useCurrentSpreadsheetGeocodingState } from '~/store/geocoding/geocoding.selectors';
import { mapSettingsConcurrencySetAlertVisibility } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.actionCreators';
import {
  mapSettingsSearchItemsRemoveAllItems, mapSettingsSearchItemsRemoveItem,
} from '~/store/mapSettings/searchItems/mapSettingsSearchItems.actionCreators';
import { useSearchItemsSelector } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetRealSpreadsheets } from '~/store/selectors/usePrimarySpreadsheetRealSpreadsheets';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { renderAnnouncement } from './announcements/topUnderbarAnnouncement.component';
import { renderGeocodingState } from './geocodingProgress/topUnderbarGeocoding.component';
import { type TopUnderbarItem } from './item/topUnderbarItem.types';
import { useTopUnderbarSampleItems } from './item/useTopUnderbarSampleItems.hook';
import { useNotifications } from './notifications/Notifications';
import { renderBoundaryOptimizedTerritoryItem } from './optimizedTerritoriesProgress/topUnderbarOptimizedTerritories.component';
import { renderSearchEntries } from './searchEntries/topUnderbarSearchEntries.component';
import { useExportImageStatusItems } from './statusItem/useExportImageStatusItems.hook';
import { useMapSettingsStatusItems } from './statusItem/useMapSettingsStatusItems.hook';
import { TopUnderbarComponent } from './topUnderbar.component';

const GENERATE_UNDERBAR_ITEM_SAMPLES = false;

type TopUnderbarContainer = {
  maximumHeight?: number;
  visibleItems: Set<TopUnderbarType>;
};

export enum TopUnderbarType {
  GEOCODING_PROGRESS_ALERT = 'GEOCODING_PROGRESS_ALERT',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  SEARCH_ENTRIES = 'SEARCH_ENTRIES',
  MAP_SETTINGS_STATUS = 'MAP_SETTINGS_STATUS',
  EXPORT_IMAGE_STATUS = 'EXPORT_IMAGE_STATUS',
  OPTIMIZE_TERRITORIES_PROGRESS = 'OPTIMIZE_TERRITORIES_PROGRESS',
}

export const TopUnderbarContainer: FC<TopUnderbarContainer> = (props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const userId = useUserIdSelector();
  const announcements = useAnnouncementsSelector();
  const realSpreadsheetIds = usePrimarySpreadsheetRealSpreadsheets();
  const currentSpreadsheetGeocodingState = useCurrentSpreadsheetGeocodingState();
  const currentSpreadsheetId = realSpreadsheetIds?.[0]?.id;
  const stateSearchItems = useSearchItemsSelector();
  const matchupData = useMatchupDataSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const optimizedTerritoriesPendingItems = useBoundaryCreateOptimizedPendingItemsSelector();
  const { cancelOptimizedTerritory } = useCancelOptimizedTerritories();
  const mapSettingsStatusItems = useMapSettingsStatusItems();
  const exportImageStatusItems = useExportImageStatusItems();
  const underbarItemSamples = useTopUnderbarSampleItems({ generateSamples: GENERATE_UNDERBAR_ITEM_SAMPLES, isMobileScreen });
  const currentPage = useGetCurrentPageInfo().page;
  const { notifications } = useNotifications();

  const dispatchProps = useMemo(() => ({
    markAnnouncementAsSeen: (announcementId: number) => {
      if (clientId && userId) {
        dispatch(announcementsMarkAsSeenRequest(clientId, userId, announcementId));
      }
    },
    removeSearchItem: (specificItem: SearchItem) => {
      dispatch(mapSettingsSearchItemsRemoveItem(specificItem.id));
    },
    removeAllSearchItems: () => {
      dispatch(mapSettingsSearchItemsRemoveAllItems());
    },
    toggleGeocodingPause: (flag: boolean) => {
      const realSpreadsheetId = currentSpreadsheetId;

      if (realSpreadsheetId === undefined || clientId === null) {
        return;
      }

      if (!flag) {
        dispatch(geocodingResumeRequest(clientId, realSpreadsheetId));
      }
      else {
        dispatch(geocodingPauseRequest(clientId, realSpreadsheetId));
      }
    },
    hideOfflineAlert: () => {
      dispatch(mapSettingsConcurrencySetAlertVisibility(false));
    },
  }), [clientId, dispatch, userId, currentSpreadsheetId]);

  const announcementItems: TopUnderbarItem[] = useMemo(() => {
    return announcements.map<TopUnderbarItem>(announcement => renderAnnouncement({
      isMobileVersion: isMobileScreen,
      announcement,
      markAnnouncementAsSeen: dispatchProps.markAnnouncementAsSeen,
    }));
  }, [announcements, dispatchProps.markAnnouncementAsSeen, isMobileScreen]);

  const searchEntries: TopUnderbarItem | null = useMemo(() => {
    if (stateSearchItems.length === 0) {
      return null;
    }

    const searchEntries: SearchItem[] = stateSearchItems.map(searchItem => ({
      ...searchItem,
      ...(isSearchItemWithColumn(searchItem)
        ? { columnName: searchItem.spreadsheetId !== undefined ? matchupData[searchItem.spreadsheetId]?.data?.columns[searchItem.columnId] ?? '' : '' }
        : {}
      ),
    }));

    return renderSearchEntries({
      items: searchEntries,
      removeSearchItem: dispatchProps.removeSearchItem,
      removeAllSearchItems: dispatchProps.removeAllSearchItems,
    });
  }, [stateSearchItems, dispatchProps.removeSearchItem, dispatchProps.removeAllSearchItems, matchupData]);

  const geocodingItem: TopUnderbarItem | null = useMemo(() => {
    if (!currentSpreadsheetGeocodingState?.inProgress || !ADMIN_MAP_PAGES.has(currentPage)) {
      return null;
    }

    return renderGeocodingState({
      isMobileVersion: isMobileScreen,
      state: currentSpreadsheetGeocodingState,
      togglePause: dispatchProps.toggleGeocodingPause,
      t,
    });
  }, [currentPage, currentSpreadsheetGeocodingState, dispatchProps.toggleGeocodingPause, isMobileScreen, t]);

  const optimizedTerritoriesItems: TopUnderbarItem[] = useMemo(() => {
    return optimizedTerritoriesPendingItems.length
      ? optimizedTerritoriesPendingItems
        .filter(item => item.status !== BoundaryCreateOptimizedStatus.Finished)
        .map(item => {
          const onCancel = () => clientId && cancelOptimizedTerritory(clientId, item.id);

          return renderBoundaryOptimizedTerritoryItem({
            isMobileVersion: isMobileScreen,
            item,
            onCancel,
            t,
          });
        })
      : [];
  }, [cancelOptimizedTerritory, clientId, isMobileScreen, optimizedTerritoriesPendingItems, t]);

  const topUnderbarItems = useMemo(() => {
    const topUnderbarItems: TopUnderbarItem[] = [];

    if (props.visibleItems.has(TopUnderbarType.MAP_SETTINGS_STATUS)) {
      topUnderbarItems.push(...mapSettingsStatusItems);
    }

    if (props.visibleItems.has(TopUnderbarType.EXPORT_IMAGE_STATUS)) {
      topUnderbarItems.push(...exportImageStatusItems);
    }

    if (geocodingItem !== null && props.visibleItems.has(TopUnderbarType.GEOCODING_PROGRESS_ALERT)) {
      topUnderbarItems.push(geocodingItem);
    }

    if (props.visibleItems.has(TopUnderbarType.OPTIMIZE_TERRITORIES_PROGRESS)) {
      topUnderbarItems.push(...optimizedTerritoriesItems);
    }

    if (searchEntries !== null && props.visibleItems.has(TopUnderbarType.SEARCH_ENTRIES)) {
      topUnderbarItems.push(searchEntries);
    }

    if (props.visibleItems.has(TopUnderbarType.ANNOUNCEMENTS)) {
      topUnderbarItems.push(...announcementItems);
    }

    topUnderbarItems.push(...underbarItemSamples);
    topUnderbarItems.push(...notifications);

    return topUnderbarItems;
  }, [announcementItems, exportImageStatusItems, geocodingItem, mapSettingsStatusItems, notifications, optimizedTerritoriesItems, props.visibleItems, searchEntries, underbarItemSamples]);

  return (
    <TopUnderbarComponent
      maximumHeight={props.maximumHeight}
      isMobileVersion={isMobileScreen}
      items={topUnderbarItems}
    />
  );
};
