import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideMobileTopBar } from '~/store/frontendState/mobileUI/mobileUI.actionCreators';
import { isPresentationalMapByPathname } from '../_shared/presentationMode/presentationModeContext';
import { MobileTopBarContainer } from '../mobileTopBar/mobileTopBar.container';
import { useIsMobileScreenSelector } from '../store/frontendState/deviceInfo/deviceInfo.selector';
import { DesktopTopBarContainer } from './desktopTopBarContainer';

export type TopBarProps = {
  showUndoButton: boolean;
  title?: string;
};

export const TopBar: FC<TopBarProps> = ({ showUndoButton, title }) => {
  const dispatch = useDispatch();
  const isMobileScreen = useIsMobileScreenSelector();
  const location = useLocation();
  const isViewPresentational = useMemo(() => isPresentationalMapByPathname(location.pathname), [location.pathname]);

  useEffect(() => {
    if (!isMobileScreen) {
      dispatch(hideMobileTopBar());
    }
  }, [isMobileScreen, dispatch]);

  if (isMobileScreen) {
    return <MobileTopBarContainer />;
  }
  else {
    const presentationalMode = isViewPresentational;

    return (
      <DesktopTopBarContainer
        showUndoButton={showUndoButton}
        presentationalMode={presentationalMode}
        title={title}
      />
    );
  }
};
