import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { makeColorLighter } from '~/_shared/utils/colors/colors.helpers';
import { type ActiveMarkerIndicatorSettings } from '~/store/mapSettings/markers/mapSettingsMarkers.state';
import chevronArrowIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/chevronArrow.svg';
import circleArrowBasicIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/circleArrowBasic.svg';
import circleArrowFatIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/circleArrowFat.svg';
import circleArrowSlimIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/circleArrowSlim.svg';
import neonArrowIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/neonArrow.svg';
import simpleArrowIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/simpleArrow.svg';
import tripleNeonIndicatorPath from '../../../../../../assets/images/activeMarkerIndicators/tripleNeonArrow.svg';
import {
  type ActiveMarkerIndicator,
  ActiveMarkerIndicators,
  type LottieActiveMarkerIndicator, type LottieActiveMarkerIndicatorProps, LottieActiveMarkerIndicators,
  type NamedActiveMarkerIndicator,
  NamedActiveMarkerIndicators,
  type SvgActiveMarkerIndicator, type SvgActiveMarkerIndicatorsProps,
} from './activeMarkerIndicator.types';

export const DEFAULT_INDICATOR_COLOR = '#0740D8';
export const DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE = 100;
export const MAX_ACTIVE_MARKER_INDICATOR_SIZE = 100;
export const MIN_ACTIVE_MARKER_INDICATOR_SIZE = 30;
export const DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED = 50;
export const MAX_ACTIVE_MARKER_INDICATOR_SPEED = 100;
export const MIN_ACTIVE_MARKER_INDICATOR_SPEED = 0;

export const activeMarkerIndicatorsProperties: {
  [key in SvgActiveMarkerIndicator]: SvgActiveMarkerIndicatorsProps;
} = {
  [ActiveMarkerIndicators.basicCircleArrow]: {
    dimensions: { height: 36, width: 36 },
    path: circleArrowBasicIndicatorPath,
  },
  [ActiveMarkerIndicators.chevronArrow]: {
    dimensions: { height: 22, width: 32 },
    path: chevronArrowIndicatorPath,
  },
  [ActiveMarkerIndicators.fatCircleArrow]: {
    dimensions: { height: 36, width: 36 },
    path: circleArrowFatIndicatorPath,
  },
  [ActiveMarkerIndicators.neonArrow]: {
    dimensions: { height: 40, width: 36 },
    path: neonArrowIndicatorPath,
  },
  [ActiveMarkerIndicators.radarCircle]: {
    dimensions: { height: 50, width: 50 },
    path: '',
  },
  [ActiveMarkerIndicators.simpleArrow]: {
    dimensions: { height: 36, width: 36 },
    path: simpleArrowIndicatorPath,
  },
  [ActiveMarkerIndicators.slimCircleArrow]: {
    dimensions: { height: 36, width: 36 },
    path: circleArrowSlimIndicatorPath,
  },
  [ActiveMarkerIndicators.tripleNeonArrow]: {
    dimensions: { height: 40, width: 36 },
    path: tripleNeonIndicatorPath,
  },
};

export const lottieActiveMarkerIndicatorProps: {
  [key in LottieActiveMarkerIndicator]: LottieActiveMarkerIndicatorProps;
} = {
  [LottieActiveMarkerIndicators.lottieChevron]: {
    size: 64,
    position: 'above',
    offset: { top: -10, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.Chevron,
      colors: { primary: color, secondary: color },
    }),
  },
  [LottieActiveMarkerIndicators.lottieChevronBothDirections]: {
    size: 64,
    position: 'above',
    offset: { top: -10, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.Chevron,
      colors: { primary: color, secondary: color },
    }),
    bothDirections: true,
  },
  [LottieActiveMarkerIndicators.lottieCaretDown]: {
    size: 64,
    position: 'above',
    configFactory: color => ({
      type: LottieAnimationTypes.CaretDown,
      colors: { caretA: color, caretB: color, caretC: color },
    }),
    speed: 2,
  },
  [LottieActiveMarkerIndicators.lottieCaretDownShort]: {
    size: 48,
    position: 'above',
    configFactory: color => ({
      type: LottieAnimationTypes.CaretDown,
      colors: { caretA: color, caretB: color, caretC: color },
    }),
    speed: 2,
    segment: LottieAnimations.CaretDown.segments.short,
    view: LottieAnimations.CaretDown.views.short,
  },
  [LottieActiveMarkerIndicators.lottieDoubleDownArrow]: {
    size: 48,
    position: 'above',
    offset: { top: 2, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.DoubleDownArrow,
      colors: { primary: color, secondary: color },
    }),
    segment: LottieAnimations.DoubleDownArrow.segments.arrows,
  },
  [LottieActiveMarkerIndicators.lottieDoubleDownArrowBothDirections]: {
    size: 48,
    position: 'above',
    offset: { top: 2, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.DoubleDownArrow,
      colors: { primary: color, secondary: color },
    }),
    segment: LottieAnimations.DoubleDownArrow.segments.noFade,
    bothDirections: true,
  },
  [LottieActiveMarkerIndicators.lottieScrollDownArrowChain]: {
    size: 64,
    position: 'above',
    offset: { top: 2, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.ScrollDownArrow,
      colors: { arrow: color, reflection: makeColorLighter(color, 75).hex },
    }),
    segment: LottieAnimations.ScrollDownArrow.segments.chain,
    bothDirections: true,
  },
  [LottieActiveMarkerIndicators.lottieScrollDownArrow]: {
    size: 64,
    position: 'above',
    offset: { top: 2, left: 0 },
    configFactory: color => ({
      type: LottieAnimationTypes.ScrollDownArrow,
      colors: { arrow: color, reflection: makeColorLighter(color, 75).hex },
    }),
  },
};

export const centerAroundMarkerIndicators: ReadonlyArray<ActiveMarkerIndicator> = [];
export const centeredMarkerIndicators: ReadonlyArray<ActiveMarkerIndicator> = [ActiveMarkerIndicators.radarCircle];

export const defaultActiveMarkerIndicatorSettings: ActiveMarkerIndicatorSettings = {
  color: DEFAULT_INDICATOR_COLOR,
  size: DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE,
  speed: DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED,
  type: ActiveMarkerIndicators.lottieChevron,
};

const lottieActiveMarkerIndicatorList: ReadonlyArray<string> = Object.values(LottieActiveMarkerIndicators);
export const isLottieActiveMarkerIndicator = (indicator: ActiveMarkerIndicator): indicator is LottieActiveMarkerIndicator =>
  lottieActiveMarkerIndicatorList.includes(indicator);

export const isNamedActiveMarkerIndicator = (indicator: ActiveMarkerIndicator): indicator is NamedActiveMarkerIndicator =>
  NamedActiveMarkerIndicators.findIndex(i => i === indicator) !== -1;
