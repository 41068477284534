import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { type ModalProps } from '../../../modal/modalType.enum';
import { uploadFile } from '../../../store/mapSettings/fileAttachments/fileApi.helpers';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { UserFileType } from '../../types/file.types';
import { type SpreadsheetRowId } from '../../types/spreadsheetData/spreadsheetRow';
import { useUserFiles } from '../../utils/files/useUserFiles';
import { useFileUploadError } from '../dropzone/dropzone.helpers';
import { UploadStatus } from '../uploadStatus/uploadStatus.component';
import {
  type CustomImage, ImageLibraryComponent, type UploadingFileDetails,
} from './imageLibrary.component';
import { useMarkerLibraryImages } from './useMarkerLibraryImages';

const IMAGE_LIBRARY_FILE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];

export type ImageLibraryContainerProps = ModalProps<{
  markerId: SpreadsheetRowId;
}>;

export const ImageLibraryContainer: FC<ImageLibraryContainerProps> = ({ markerId, ...props }: ImageLibraryContainerProps) => {
  const clientId = useClientIdSelector();
  const [dropzoneFilesDetails, setDropzoneFilesDetails] = useState<UploadingFileDetails[]>([]);
  const { isLoading, getUserFiles, deleteFile, userFiles } = useUserFiles(UserFileType.MarkerAttachment);
  const fileUrlsLookup = useFileUrls();
  const { activateImage, deactivateImage, currentMarkerImages,
    fileIdToFileAttachment, fileAttachmentIdToAttachment } = useMarkerLibraryImages({ markerId });
  const { showFileUploadError } = useFileUploadError(IMAGE_LIBRARY_FILE_EXTENSIONS);

  const libraryImages = useMemo(() => {
    const images: CustomImage[] = [];
    userFiles.forEach((userFile) => {
      if (fileUrlsLookup.get(userFile.id)) {
        images.push({
          id: userFile.id,
          url: fileUrlsLookup.get(userFile.id)?.['200p'].url || '',
        });
      }
    });
    return images;
  }, [userFiles, fileUrlsLookup]);

  const currentMarkerImagesFileIds = useMemo(() =>
    currentMarkerImages.map((fileAttachmentId) => fileAttachmentIdToAttachment(fileAttachmentId)?.fileId)
      .filter(n => !!n) as number[]
  , [currentMarkerImages, fileAttachmentIdToAttachment]);

  const handleRemoveImageFromActive = useCallback((fileId: number) => {
    const fileAttachment = fileIdToFileAttachment(fileId);
    if (fileAttachment) {
      deactivateImage(fileAttachment.id);
    }
  }, [deactivateImage, fileIdToFileAttachment]);

  const handleAddImageToActive = useCallback((fileId: number) => {
    activateImage(fileId);
  }, [activateImage]);

  const handleRotateImage = useCallback((_imageId: number) => {
    return null;
  }, []);

  const handleDeleteImage = useCallback((fileId: number) => {
    deleteFile(fileId);
  }, [deleteFile]);

  const updateFileUploadingState = useCallback((file: File, fileDetails: Partial<UploadingFileDetails>) => {
    setDropzoneFilesDetails(state => {
      const newState = [...state];
      const itemIndex = newState.findIndex((item: UploadingFileDetails) => item.file === file);
      newState[itemIndex] = {
        file: fileDetails?.file ? fileDetails.file : newState[itemIndex].file,
        status: fileDetails?.status ? fileDetails.status : newState[itemIndex].status,
        uploadProgress: fileDetails?.uploadProgress ? fileDetails.uploadProgress : newState[itemIndex].uploadProgress,
      };
      return newState;
    });
  }, [setDropzoneFilesDetails]);

  const handleDropZoneFiles = useCallback(async (files: File[]) => {
    setDropzoneFilesDetails(state => [
      ...state,
      ...files.map(file => ({ file, status: UploadStatus.InProgress, uploadProgress: 0 })),
    ]);
    for (const file of files) {
      try {
        await uploadFile(file, clientId, UserFileType.MarkerAttachment, (percent: number) => {
          updateFileUploadingState(file, { status: UploadStatus.InProgress, uploadProgress: percent });
        });
        updateFileUploadingState(file, { status: UploadStatus.Success, uploadProgress: 0 });
      }
      catch (error) {
        updateFileUploadingState(file, { status: UploadStatus.Error, uploadProgress: 0 });
      }
    }
    getUserFiles();
  }, [clientId, updateFileUploadingState, getUserFiles]);

  const onRemoveFromDropzoneCallback = useCallback((fileDetails: UploadingFileDetails) => {
    setDropzoneFilesDetails(state => [...state.filter((item: UploadingFileDetails) => item !== fileDetails)]);
  }, [setDropzoneFilesDetails]);

  const onRetryInDropzoneCallback = useCallback(async (fileDetails: UploadingFileDetails) => {
    try {
      updateFileUploadingState(fileDetails.file, { status: UploadStatus.InProgress, uploadProgress: 0 });
      await uploadFile(fileDetails.file, clientId, UserFileType.MarkerAttachment, (percent: number) => {
        updateFileUploadingState(fileDetails.file, { status: UploadStatus.InProgress, uploadProgress: percent });
      });
      updateFileUploadingState(fileDetails.file, { status: UploadStatus.Success, uploadProgress: 0 });
    }
    catch (error) {
      updateFileUploadingState(fileDetails.file, { status: UploadStatus.Error, uploadProgress: 0 });
    }
  }, [updateFileUploadingState, clientId]);

  useEffect(() => {
    getUserFiles();
  }, [getUserFiles]);

  return (
    <ImageLibraryComponent
      images={libraryImages}
      currentMarkerImages={currentMarkerImagesFileIds}
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onImageRemoveFromActive={handleRemoveImageFromActive}
      onImageAddToActive={handleAddImageToActive}
      onImageDelete={handleDeleteImage}
      onImageRotate={handleRotateImage}
      onDropZoneFilesSelect={handleDropZoneFiles}
      uploadingFilesDetails={dropzoneFilesDetails}
      onRemoveFromDropzoneClick={onRemoveFromDropzoneCallback}
      onRetryInDropzoneClick={onRetryInDropzoneCallback}
      acceptFileExtensions={IMAGE_LIBRARY_FILE_EXTENSIONS}
      onFileReject={showFileUploadError}
    />
  );
};
