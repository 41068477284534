import {
  type FC, useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TooltipBehavior } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { SUBSCRIBE_ROUTE } from '~/_shared/constants/routes';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { RoleBlocker } from '~/authorization/authContext/roleBlocker.component';
import { MemberRoles } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useUserCurrentClientSelector } from '~/store/userData/userData.selectors';
import { useTranslation } from '../../utils/hooks';
import { useTimeout } from '../../utils/hooks/useTimeout';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';

export type TrialExportNoticeModalProps = Readonly<{
  exportMethod: ExportDataMethod;
  isOpen: boolean;
  onClose: () => void;
  onExport: () => Promise<void> | undefined;
}>;

export const TrialExportNoticeModal: FC<TrialExportNoticeModalProps> = props => {
  const [t] = useTranslation();
  const { openModal: openSubscriptionManagementUnavailableModal } = useModal(ModalType.SubscriptionManagementUnavailable);
  const migrationFinished = useUserCurrentClientSelector()?.migrationDone;
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const [isLoading, setLoading] = useState(false);

  const onPurchaseClick = useCallback(() => {
    if (migrationFinished) {
      const close = props.onClose;
      close();
      openUrlInNewTab(SUBSCRIBE_ROUTE);
    }
    else {
      openSubscriptionManagementUnavailableModal();
    }
  }, [migrationFinished, openSubscriptionManagementUnavailableModal, props.onClose]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('TrialExportNotice.header')}
      maxWidth={600}
      additionalContent={isLoading && <OverlayLoaderComponent />}
      leftFooterContent={(
        <TooltipDeprComponent
          tooltipContent={isCopiedToClipboard ? t('Copied to Clipboard!') : null}
          behavior={TooltipBehavior.ShowAlways}
        >
          <ButtonComponent
            text={t('Export Sample')}
            onClick={async () => {
              setLoading(true);

              try {
                await props.onExport();

                if (props.exportMethod === ExportDataMethod.Clipboard) {
                  showCopiedToClipboardMessage();
                }
                else {
                  props.onClose();
                }
              }
              catch (e) {
                console.error(e);
              }
              finally {
                setLoading(false);
              }
            }}
          />
        </TooltipDeprComponent>

      )}
      confirmButton={(
        <RoleBlocker allowRoles={[MemberRoles.COMPANY_OWNER]}>
          <ButtonComponent
            text={t('Purchase Now')}
            onClick={onPurchaseClick}
          />
        </RoleBlocker>
      )}
    >
      {t('TrialExportNotice.message')}
    </ModalComponent>
  );
};
