import { FilterAction } from '~/_shared/types/filter/filter';
import { type FilterTree } from '~/_shared/types/filterTree.types';
import {
  newPerSpreadsheetMap,
  type PerSpreadsheet,
  type PerSpreadsheetFilteredRowsIdsMap,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import {
  isSearchItemWithColumn, type SearchItemWithColumn,
} from '../../../map/search/mapSearch.helpers';
import { type AppState } from '../../app.store';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import { type MapSettingsSearchItemsState } from '../../mapSettings/searchItems/mapSettingsSearchItems.state';
import { ALL_COLUMNS_ID } from '../filtering/spreadsheetDataFiltering.constants';
import { type FilterTreeRequest } from '../filtering/spreadsheetDataFiltering.helpers';
import {
  type FilterTreeMapSettingsParams,
  getFilterTreeParamsFromMapSettings,
} from '../filtering/useFilterTreeMapSettingsParams';
import {
  generateHash,
  type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType, type SpreadsheetFilters,
} from '../spreadsheetData.state';

const addSearchItemToFilterTree = (perSpreadsheetFilters: PerSpreadsheet<FilterTree>, spreadsheetId: number,
  filterAction: FilterAction, columnId: string, searchQuery: string): PerSpreadsheet<FilterTree> => {
  if (!perSpreadsheetFilters[spreadsheetId]?.children) {
    perSpreadsheetFilters[spreadsheetId] = {
      type: 'or',
      children: [],
    };
  }

  const spreadsheetFilter = perSpreadsheetFilters[spreadsheetId];
  if (spreadsheetFilter) {
    spreadsheetFilter.children.push({
      type: 'literal',
      ref: {
        col_id: columnId,
        data_type: DataType.TEXT,
        value: searchQuery,
        action: filterAction,
      },
    });
  }

  return perSpreadsheetFilters;
};

const addSearchItemToPerSpreadsheetHashList = (perSpreadsheetHashList: PerSpreadsheet<Array<string>>, spreadsheetId: number,
  searchItem: SearchItemWithColumn): PerSpreadsheet<Array<string>> => {
  if (!perSpreadsheetHashList[spreadsheetId]) {
    perSpreadsheetHashList[spreadsheetId] = ['search'];
  }

  const spreadsheetHashList = perSpreadsheetHashList[spreadsheetId];
  if (spreadsheetHashList) {
    spreadsheetHashList.push(
      searchItem.columnId,
      searchItem.text
    );
  }

  return perSpreadsheetHashList;
};

const getHashForSearchItems = (spreadsheetIds: ReadonlyArray<number>, spreadsheetSpecificSearchItems: SearchItemWithColumn[],
  allSpreadsheetsSearchItems: SearchItemWithColumn[]): PerSpreadsheet<string> => {
  const perSpreadsheetHashList: PerSpreadsheet<Array<string>> = newPerSpreadsheetMap();

  if (allSpreadsheetsSearchItems.length > 0) {
    for (const spreadsheetId of spreadsheetIds) {
      for (const searchItem of allSpreadsheetsSearchItems) {
        addSearchItemToPerSpreadsheetHashList(perSpreadsheetHashList, spreadsheetId, searchItem);
      }
    }
  }

  for (const searchItem of spreadsheetSpecificSearchItems) {
    if (!searchItem.spreadsheetId) {
      continue;
    }

    addSearchItemToPerSpreadsheetHashList(perSpreadsheetHashList, searchItem.spreadsheetId, searchItem);

  }

  return Object.keys(perSpreadsheetHashList).reduce<PerSpreadsheet<string>>((acc, spreadsheetIdString) => {
    const spreadsheetId = +spreadsheetIdString;
    const hashListItem = perSpreadsheetHashList[spreadsheetId];

    if (hashListItem) {
      acc[spreadsheetId] = generateHash(hashListItem.join('-'));
    }

    return acc;
  }, newPerSpreadsheetMap());
};

export const getDataRowsForSearch = (params: {
  spreadsheetIds: ReadonlyArray<number>;
  filters: SpreadsheetFilters;
  searchItems: MapSettingsSearchItemsState;
}): PerSpreadsheetFilteredRowsIdsMap => {
  const results: PerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

  const itemSpecificForSpreadsheet = params.searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId !== ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];
  const itemForAllSpreadsheets = params.searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId === ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];

  const perSpreadsheetFilterHash = getHashForSearchItems(
    params.spreadsheetIds,
    itemSpecificForSpreadsheet,
    itemForAllSpreadsheets
  );

  Object.keys(perSpreadsheetFilterHash).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const spreadsheetFilterHash = perSpreadsheetFilterHash[spreadsheetId];

    const data = spreadsheetFilterHash && params.filters[spreadsheetId]?.[spreadsheetFilterHash];

    if (!data) {
      // this way we prevent do display all the available data in case the filtering data isn't fetched yet
      results[spreadsheetId] = {};
      return;
    }

    results[spreadsheetId] = data;
  });

  return results;
};

export const createSearchFilterTreeRequestFromMapSettings = (params: FilterTreeMapSettingsParams, spreadsheetIds: number[]):
FilterTreeRequest => {
  const { searchItems } = params;

  const itemSpecificForSpreadsheet = searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId !== ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];
  const itemForAllSpreadsheets = searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId === ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];

  const perSpreadsheetValues: PerSpreadsheet<FilterTree> = newPerSpreadsheetMap();

  const perSpreadsheetFilterHash = getHashForSearchItems(
    spreadsheetIds,
    itemSpecificForSpreadsheet,
    itemForAllSpreadsheets
  );

  for (const searchItem of itemSpecificForSpreadsheet) {
    if (!searchItem.spreadsheetId) {
      continue;
    }

    addSearchItemToFilterTree(
      perSpreadsheetValues,
      searchItem.spreadsheetId,
      searchItem.exactWords ? FilterAction.Equal : FilterAction.Contains,
      searchItem.columnId,
      searchItem.text,
    );
  }

  // apply items for all spreadsheets
  if (itemForAllSpreadsheets.length > 0) {
    for (const spreadsheetId of spreadsheetIds) {
      for (const everySpreadsheetItem of itemForAllSpreadsheets) {
        addSearchItemToFilterTree(
          perSpreadsheetValues,
          spreadsheetId,
          everySpreadsheetItem.exactWords ? FilterAction.Equal : FilterAction.Contains,
          everySpreadsheetItem.columnId,
          everySpreadsheetItem.text,
        );
      }
    }
  }

  const results: FilterTreeRequest = newPerSpreadsheetMap();

  Object.keys(perSpreadsheetValues).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const filterHash = perSpreadsheetFilterHash[spreadsheetId];
    const filterTree = perSpreadsheetValues[spreadsheetId];

    if (filterHash && filterTree) {
      results[spreadsheetId] = {
        filterHash,
        filterTree,
      };
    }
  });

  return results;
};

export const getMissingSearchSpreadsheetData = (spreadsheetIds: number[], mapSettings: MapSettingsState,
  spreadsheetFilters: SpreadsheetFilters): MissingSpreadsheetData => {
  const missingSearchSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  const filterTreeParams = getFilterTreeParamsFromMapSettings(mapSettings.data);
  const request = createSearchFilterTreeRequestFromMapSettings(filterTreeParams, spreadsheetIds);

  Object.keys(request).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const requestItem = request[spreadsheetId];

    if (requestItem && !spreadsheetFilters[spreadsheetId]?.[requestItem.filterHash]) {
      missingSearchSpreadsheetData.search = {
        ...missingSearchSpreadsheetData.search,
        [spreadsheetId]: {
          ...missingSearchSpreadsheetData.search?.[spreadsheetId],
          [requestItem.filterHash]: requestItem.filterTree,
        },
      };
    }
  });

  return missingSearchSpreadsheetData;
};

export const isLatestSearchLoaded = (state: AppState): boolean => {
  const itemSpecificForSpreadsheet = state.map.mapSettings.data.toolsState.searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId !== ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];
  const itemForAllSpreadsheets = state.map.mapSettings.data.toolsState.searchItems.items.filter(
    item => isSearchItemWithColumn(item) && item.columnId === ALL_COLUMNS_ID
  ) as SearchItemWithColumn[];
  const currentMapSpreadsheetIds = state.map.mapInfo.data?.spreadsheets.map(s => s.spreadSheetId) ?? [];
  const loadedFilters = state.spreadsheet.spreadsheetData.filters;

  const perSpreadsheetFilterHash = getHashForSearchItems(
    currentMapSpreadsheetIds,
    itemSpecificForSpreadsheet,
    itemForAllSpreadsheets
  );

  return Object.entries(perSpreadsheetFilterHash)
    .every(([spreadsheetId, hash]) => loadedFilters?.[+spreadsheetId]?.[hash || '']);
};
