import type { PropsWithChildren } from 'react';
import { rowStyle } from '~/map/mapMigration/table/table.styles';

type TableRowProps = PropsWithChildren<{
  readonly className?: string;
}>;

export const TableRowComponent = ({ children, className }: TableRowProps) => {
  return (
    <div
      css={rowStyle}
      className={`row ${className ?? ''}`}
    >
      {children}
    </div>
  );
};
