import {
  getTextInputHeight, InputSize,
} from '../inputs';
import { type DropdownOption } from './regularDropdown.component';

export const getDropdownNumericOptions = (min: number, max: number, step = 1): DropdownOption<number>[] => {
  const options: DropdownOption<number>[] = [];
  let i = min;

  while (i <= max) {
    options.push({
      value: i,
      name: i.toString(),
    });

    i += step;
  }

  return options;
};

export enum DropDownItemSize {
  Default = 'default',
  Large = 'large'
}

export const getRegularDropdownTriggerSize = getTextInputHeight;

export const getRegularDropdownItemSize = (inputSize: InputSize, itemSize: DropDownItemSize): number => {
  if (itemSize === DropDownItemSize.Large) {
    return getRegularDropdownTriggerSize(inputSize);
  }

  switch (inputSize) {
    case InputSize.Xs:
      return 22;
    case InputSize.Small:
      return 26;
    case InputSize.Medium:
      return 32;
    case InputSize.Large:
      return 40;
    default:
      return 32;
  }
};
