import { type BaseMapsUpdateModalActionForMap } from '~/map/layered/baseMapUpdatedModal/baseMapsUpdateModal.types';
import { MAP_SETTINGS_LAYERED_MAP_SETTINGS_SUBMITTED } from './mapSettingsLayered.actionTypes';

export const submitLayeredMapSettings = (baseMapActions: BaseMapsUpdateModalActionForMap[], currentDate: Date) => ({
  type: MAP_SETTINGS_LAYERED_MAP_SETTINGS_SUBMITTED,
  payload: {
    baseMapActions,
    currentDate,
  },
}) as const;
