import { css } from '@emotion/react';
import {
  faCheck, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { TooltipBehavior } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import SpreadsheetTemplate from '../../../../assets/files/New_York_Apple_Stores.xls';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import {
  type SpreadsheetHeaderColumn, type SpreadsheetRow,
} from '../../_shared/components/spreadsheet/spreadsheet.types';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { downloadFile } from '../../_shared/utils/document/document.helpers';
import { useTranslation } from '../../_shared/utils/hooks';
import { type ModalProps } from '../../modal/modalType.enum';
import {
  s, Trans,
} from '../../translations/Trans';
import { DataTableComponent } from '../table/dataTable.component';

type DataTableModalProps = ModalProps<{
  columnWidthsLocalStorageKey: string;
  columns: SpreadsheetHeaderColumn[];
  isLoading: boolean;
  isRemapButtonDisabled: boolean;
  showCopyToClipboardTooltip: boolean;
  spreadsheetDataRows: SpreadsheetRow[];

  onCopyToClipboardClick: () => void;
  onDataChange: (newData: SpreadsheetRow[]) => void;
  onDiscardUnmappedRowsClick: () => void;
  onRemapDataRowsClick: () => void;
}>;

const dataTableStyle = css({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
});

const headerStyle = css({
  display: 'flex',
  padding: '10px 16px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,
});

const buttonWrapperStyle = css({
  flexShrink: 0,
});

const linkStyle = css({
  fontSize: 'inherit',
  textDecoration: 'underline',
});

const wrapperStyle = css({
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
});

const modalContentStyle = css({
  display: 'none',
});

const footerStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
  padding: '10px 16px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const BadDataModalComponent: FC<DataTableModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const dataTableSpreadsheetProps = {
    data: props.spreadsheetDataRows,
    onDataChange: props.onDataChange,
    columns: props.columns,
    columnWidthsLocalStorageKey: props.columnWidthsLocalStorageKey,
    allowCellSelect: false,
  };

  return (
    <ModalComponent
      {...props}
      preventCloseOnOverlayClick
      caption={t('The unmapped data below was removed from your imported dataset')}
      contentStyle={modalContentStyle}
      maxWidth="90vw"
      additionalContent={(
        <div css={wrapperStyle}>
          <div css={headerStyle}>
            <div>
              <Trans i18nKey="Data should contain location information such as Address, City, State, Zip/Postal Code.<1></1>You may also check that Step 2, Match Up Your Data, was completed, and/or download our <3>spreadsheet template</3> for help with formatting issues.">
                {s('Data should contain location information such as Address, City, State, Zip/Postal Code.')}
                <br />
                {s('You may also check that Step 2, Match Up Your Data, was completed, and/or download our ')}
                <LinkComponent
                  css={linkStyle}
                  path="#"
                  onClick={() => {
                    downloadFile(SpreadsheetTemplate, { fileName: 'New_York_Apple_Stores.xls' });
                  }}
                >
                  spreadsheet template
                </LinkComponent>
                {s(' for help with formatting issues.')}
              </Trans>
            </div>

            <div css={buttonWrapperStyle}>
              <TooltipDeprComponent
                tooltipContent={props.showCopyToClipboardTooltip ? t('Copied to Clipboard!') : null}
                behavior={TooltipBehavior.ShowAlways}
              >
                <ButtonComponent
                  buttonStyle={ButtonStyle.Secondary}
                  text={t('Copy to Clipboard')}
                  onClick={props.onCopyToClipboardClick}
                />
              </TooltipDeprComponent>
            </div>
          </div>

          <DataTableComponent
            css={dataTableStyle}
            spreadsheetProps={dataTableSpreadsheetProps}
            isSearchFilterActive={false}
            isLoading={props.isLoading}
          />

          <div css={footerStyle({ theme })}>
            <ButtonComponent
              text={t('Discard Unmapped Rows')}
              buttonStyle={ButtonStyle.Danger}
              prefixIcon={faTrash}
              onClick={props.onDiscardUnmappedRowsClick}
            />
            <ButtonComponent
              isDisabled={props.isRemapButtonDisabled}
              text={t('Remap Data Rows')}
              prefixIcon={faCheck}
              onClick={props.onRemapDataRowsClick}
            />
          </div>
        </div>
      )}
    >
      {null}
    </ModalComponent>
  );
};
