import {
  useCallback, useMemo,
} from 'react';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { usePublicMapSettingsSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';

export const useItemsVisibleInMobileTopUnderbar = (): ReadonlySet<MainMenuItem> => {
  const isMapPresentational = useIsMapPresentationalSelector();
  const publicMapSettings = usePublicMapSettingsSelector();

  const hasGroupingLegend = publicMapSettings.legendReplacesGrouping || publicMapSettings.displayLegendOnly;
  const hasBoundaryLegend = publicMapSettings.displayLegendOnly || publicMapSettings.boundaryTool;

  const publicMapCondition = useCallback((condition: boolean) =>
    !isMapPresentational || condition,
  [isMapPresentational]);

  return useMemo(() => {
    const resultSet: Set<MainMenuItem> = new Set();

    if (publicMapCondition(!!publicMapSettings?.locationList)) {
      resultSet.add(MainMenuItems.Location);
    }

    if (publicMapCondition(!!publicMapSettings?.displaySearchBox)) {
      resultSet.add(MainMenuItems.Search);
    }

    if (publicMapCondition(!!publicMapSettings?.locationFinder)) {
      resultSet.add(MainMenuItems.NearMe);
    }

    if (publicMapCondition(publicMapSettings.groupingTool && !hasGroupingLegend)) {
      resultSet.add(MainMenuItems.Grouping);
    }

    if (isMapPresentational && hasGroupingLegend) {
      resultSet.add(MainMenuItems.MapLegend);
    }

    if (publicMapCondition(!publicMapSettings.displayLegendOnly && publicMapSettings.routingDirections)) {
      resultSet.add(MainMenuItems.Routing);
    }

    if (publicMapCondition(!publicMapSettings.displayLegendOnly && publicMapSettings.radiusProximity)) {
      resultSet.add(MainMenuItems.Proximity);
    }

    if (publicMapCondition(!publicMapSettings.displayLegendOnly && publicMapSettings.filterTool)) {
      resultSet.add(MainMenuItems.Filter);
    }

    if (publicMapCondition(hasBoundaryLegend)) {
      resultSet.add(MainMenuItems.Boundary);
    }

    return resultSet;
  },
  [publicMapCondition, hasGroupingLegend, publicMapSettings.displayLegendOnly,
    publicMapSettings?.displaySearchBox, publicMapSettings.filterTool, publicMapSettings.groupingTool,
    publicMapSettings?.locationFinder, publicMapSettings?.locationList, publicMapSettings.radiusProximity,
    publicMapSettings.routingDirections, isMapPresentational, hasBoundaryLegend]);
};
