import {
  useEffect, useRef, useState,
} from 'react';
import { useMoveMarkerLabelsIsActiveSelector } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.selectors';
import { useMoveMarkersIsActiveSelector } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { useRenderingMarkersSelector } from '~/store/frontendState/processing/processing.selectors';
import { useSpreadSheetData } from '../useSpreadsheetData.hook';
import { BlockingMapLoaderComponent } from './blockingMapLoader.component';
import { OverMapLoaderContainer } from './overMapLoader.container';

const BLOCKING_LOADER_DELAY = 1500;

export const MapLoaderContainer: React.FC = () => {
  const markersLoading = useMarkersLoading();
  const [showBlockingLoader, setShowBlockingLoader] = useState<boolean>(markersLoading);
  const delayHandler = useRef<number | null>(null);

  useEffect(() => {
    if (markersLoading) {
      if (!delayHandler.current) {
        delayHandler.current = window.setTimeout(function () {
          if (delayHandler.current) {
            clearTimeout(delayHandler.current);
            delayHandler.current = null;
          }
          setShowBlockingLoader(true);
        }, BLOCKING_LOADER_DELAY);
      }
    }
    else {
      if (delayHandler.current) {
        clearTimeout(delayHandler.current);
        delayHandler.current = null;
      }
      setShowBlockingLoader(false);
    }
  }, [markersLoading]);

  if (showBlockingLoader) {
    return (
      <BlockingMapLoaderComponent />
    );
  }

  return <OverMapLoaderContainer />;
};

const useMarkersLoading = () => {
  const areSpreadsheetDataReady = useSpreadSheetData().areLoaded;
  const markersRendering = useRenderingMarkersSelector();
  const moveMarkersActive = useMoveMarkersIsActiveSelector();
  const moveMarkerLabelsActive = useMoveMarkerLabelsIsActiveSelector();

  const markerEditingActive = moveMarkersActive || moveMarkerLabelsActive;
  const showLoader = !areSpreadsheetDataReady
    || (markersRendering && !markerEditingActive);

  return showLoader;
};
