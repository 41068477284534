import { FetchableResourceStatus } from '~/_shared/types/fetchableResource/fetchableResource';
import { getLoadingStateFetchableResource } from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import { copy } from '~/_shared/utils/collections/collections';
import { notNull } from '~/_shared/utils/typeGuards';
import type { PlacesAction } from './places.action';
import {
  frontendStatePlacesPlacesSearchesRequestNextPage,
  frontendStatePlacesPlacesSearchesSetLoading,
  frontendStatePlacesSetActivePlace,
  frontendStatePlacesSetPlacesSearchResults,
} from './places.actionCreators';
import type { PlacesState } from './places.state';

const initialState: PlacesState = {
  activePlaceId: null,
  searchResults: new Map(),
};

export const placesReducer = (state = initialState, action: PlacesAction): PlacesState => {
  if (frontendStatePlacesSetActivePlace.match(action)) {
    return {
      ...state,
      activePlaceId: action.payload.placeId,
    };
  }

  if (frontendStatePlacesPlacesSearchesSetLoading.match(action)) {
    return {
      ...state,
      searchResults: new Map(
        copy.andAdd(state.searchResults, Array.from(action.payload.searchIds).map((searchId) => {
          const stateSearchResultsForSearchId = state.searchResults.get(searchId);
          if (stateSearchResultsForSearchId) {
            return [searchId, { ...stateSearchResultsForSearchId, status: FetchableResourceStatus.Loading }];
          }
          return [searchId, getLoadingStateFetchableResource()];
        }))
      ),
    };
  }

  if (frontendStatePlacesPlacesSearchesRequestNextPage.match(action)) {
    return {
      ...state,
      searchResults: copy.andAdd(state.searchResults, new Map(
        Array.from(action.payload.searchIds).map((searchId) => {
          const stateSearchResultsForSearchId = state.searchResults.get(searchId);
          if (stateSearchResultsForSearchId) {
            return [searchId, { ...stateSearchResultsForSearchId, status: FetchableResourceStatus.NextPageRequested }] as const;
          }
          return null;
        }).filter(notNull))
      ),
    };
  }

  if (frontendStatePlacesSetPlacesSearchResults.match(action)) {
    return {
      ...state,
      searchResults: copy.andAdd(state.searchResults, action.payload.results),
    };
  }

  return state;
};
