import { useCallback } from 'react';
import {
  googleLatLngToLocal, localLatLngToGoogle,
} from '~/_shared/utils/geolocation/geolocation';
import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { type PixelCoordinates } from '../../_shared/types/coordinateSystem/coordinateSystem';
import { type LatLng } from '../../_shared/types/latLng';

export type MoveLatLngByPixelsOnCurrentZoom = (latLng: LatLng, toAdd: google.maps.Point) => LatLng | null;

export const useProjectionOverlay = (map: google.maps.Map | undefined) => {
  const { overlay, initialized } = getProjectionMemoized(map);

  const fromLatLngToDivPixel = useCallback((latLng: LatLng): PixelCoordinates | null => {
    if (!initialized) {
      return null;
    }

    return overlay?.getProjection().fromLatLngToDivPixel(localLatLngToGoogle(latLng)) ?? null;
  }, [initialized, overlay]);

  const fromLatLngToContainerPixel = useCallback((latLng: LatLng): PixelCoordinates | null => {
    if (!initialized) {
      return null;
    }

    return overlay?.getProjection().fromLatLngToContainerPixel(localLatLngToGoogle(latLng)) ?? null;
  }, [initialized, overlay]);

  const fromDivPixelToLatLng = useCallback((pixelCoordinates: PixelCoordinates): LatLng | null => {
    if (!initialized) {
      return null;
    }

    const point = new google.maps.Point(pixelCoordinates.x, pixelCoordinates.y);
    const googleLatLng = overlay?.getProjection()?.fromDivPixelToLatLng(point);

    return googleLatLng ? googleLatLngToLocal(googleLatLng) : null;
  }, [initialized, overlay]);

  const moveLatLngByPixelsOnCurrentZoom = useCallback<MoveLatLngByPixelsOnCurrentZoom>((latLng, toAdd) => {
    if (!initialized) {
      return null;
    }

    const projection = overlay?.getProjection();

    if (!projection) {
      return null;
    }

    const pxPosition = projection.fromLatLngToDivPixel(localLatLngToGoogle(latLng));
    if (!pxPosition) {
      return null;
    }
    pxPosition.x += toAdd.x;
    pxPosition.y += toAdd.y;

    const latLngPosition = projection.fromDivPixelToLatLng(pxPosition);

    return latLngPosition ? googleLatLngToLocal(latLngPosition) : null;
  }, [initialized, overlay]);

  const getDistanceBetweenPoints = useCallback((pos1: LatLng, pos2: LatLng): number | null => {
    const pos1Coordinates = fromLatLngToDivPixel(pos1);
    const pos2Coordinates = fromLatLngToDivPixel(pos2);
    if (!pos1Coordinates || !pos2Coordinates) {
      return null;
    }

    return Math.sqrt(
      (Math.pow(pos2Coordinates.x - pos1Coordinates.x, 2)) +
      (Math.pow(pos2Coordinates.y - pos1Coordinates.y, 2))
    );
  }, [fromLatLngToDivPixel]);

  return {
    fromLatLngToDivPixel,
    fromLatLngToContainerPixel,
    fromDivPixelToLatLng,
    moveLatLngByPixelsOnCurrentZoom,
    getDistanceBetweenPoints,
  };
};

const getProjectionMemoized = memoizeOne((map?: google.maps.Map): {overlay?: google.maps.OverlayView; initialized: boolean } => {
  if (!map) {
    return { initialized: false };
  }

  const overlay = new google.maps.OverlayView();
  overlay.setMap(map);

  const projectionResult = {
    overlay,
    initialized: false,
  };

  overlay.onAdd = () => projectionResult.initialized = true;

  return projectionResult;
});
