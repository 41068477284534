import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import {
  HeatMapSample, HeatMapStyle,
} from '~/_shared/types/heatmap/heatMap.enum';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type TranslationFnc } from '~/_shared/utils/hooks';

export const getHeatMapStyleOptions = (t: TranslationFnc): DropdownOption<HeatMapStyle>[] => {
  return [{
    value: HeatMapStyle.MarkerDensity,
    name: t('Represents Marker Density'),
  }, {
    value: HeatMapStyle.NumericalData,
    name: t('Represents Numerical Data'),
  }];
};

export const getHeatMapSampleOptions = (t: TranslationFnc): DropdownOption<HeatMapSample>[] => {
  return [{
    value: HeatMapSample.AllMarkers,
    name: t('All Markers in Data'),
  }, {
    value: HeatMapSample.SpecificGroup,
    name: t('Specific Group'),
  }];
};

export const validateHeatMapItem = (heatMapStyle: HeatMapStyle, numericColumnId: SpreadsheetColumnId | null, sample: HeatMapSample, selectedGroupId: string | null): boolean => {
  if (heatMapStyle === HeatMapStyle.NumericalData && numericColumnId === null) {
    return false;
  }
  return (sample === HeatMapSample.AllMarkers || sample === HeatMapSample.SpecificGroup && selectedGroupId !== null);
};
