import { useCallback } from 'react';
import { useMapSettingsFilteringSelector } from '../../../store/mapSettings/filtering/mapSettingsFiltering.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../../../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { useSelector } from '../hooks/useSelector';
import {
  createDefaultDemographicsMetrics, createDefaultSpreadsheetColumnMetrics,
} from './defaultMetric.factory';
import { getNumericColumns } from './defaultMetric.helpers';

export const useDefaultMetrics = () => {
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const activeFiltering = useMapSettingsFilteringSelector();
  const matchupData = useSelector(state => state.spreadsheet.matchupData);
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();

  const computeDefaultMetrics = useCallback(async () => {
    if (!clientId || !mapId) {
      return {
        defaultDemographicsMetrics: [],
        defaultSpreadsheetColumnMetrics: [],
      };
    }
    const numericColumns = await getNumericColumns(clientId, mapId, matchupData);
    const defaultSpreadsheetColumnMetrics = createDefaultSpreadsheetColumnMetrics(activeGroupColumns, activeFiltering, numericColumns, matchupData);

    const defaultDemographicsMetrics = createDefaultDemographicsMetrics([]);

    return {
      defaultDemographicsMetrics,
      defaultSpreadsheetColumnMetrics,
    };
  }, [clientId, mapId, matchupData, activeGroupColumns, activeFiltering]);

  return {
    computeDefaultMetrics,
  };
};
