import { filterObjectProperties } from '~/_shared/utils/collections/collections';
import { type MapSettingsDataAction } from '~/store/mapSettings/data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '~/store/mapSettings/data/mapSettingsData.actionTypes';
import { type MapSettingsHeatmapsAction } from '~/store/mapSettings/heatmaps/mapSettingsHeatmaps.action';
import {
  MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP, MAP_SETTINGS_HEATMAP_RESET_HEATMAPS,
} from '~/store/mapSettings/heatmaps/mapSettingsHeatmaps.actionTypes';
import { type HeatmapToolsStateAction } from './heatmapToolState.action';
import { MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA } from './heatmapToolState.actionTypes';
import { type HeatmapToolState } from './heatmapToolState.state';

export const heatmapsToolStateInitialState: HeatmapToolState = {
  heatmapStatePerId: {},
};

export const heatmapToolStateReducer = (
  state: HeatmapToolState = heatmapsToolStateInitialState,
  action: MapSettingsHeatmapsAction | HeatmapToolsStateAction | MapSettingsDataAction
): HeatmapToolState => {
  switch (action.type) {

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS:
    case MAP_SETTINGS_HEATMAP_RESET_HEATMAPS:
      return heatmapsToolStateInitialState;

    case MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP:
      return {
        ...state,
        heatmapStatePerId: filterObjectProperties((_, id) => id !== action.payload.heatmap.id, state.heatmapStatePerId),
      };

    case MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA: {
      const current = state.heatmapStatePerId[action.payload.heatmapId];

      return {
        ...state,
        heatmapStatePerId: {
          ...state.heatmapStatePerId,
          [action.payload.heatmapId]: {
            ...current,
            ...action.payload.data,
          },
        },
      };
    }

    default:
      return state;
  }
};
