import { type Action as ReduxAction } from 'redux';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { type BoundaryItemsAction } from '~/store/boundaryItems/boundaryItems.action';
import { BOUNDARY_ITEMS_FETCHED_DATA } from '~/store/boundaryItems/boundaryItems.actionTypes';
import { type DirectionsAction } from '~/store/frontendState/mapTools/directions/directions.action';
import { FRONTEND_STATE_ROUTE_UI_DATA_LOADED } from '~/store/frontendState/mapTools/directions/directions.actionTypes';

const actionSanitizer = (action: Action): Action => {

  if (action.type === BOUNDARY_ITEMS_FETCHED_DATA) {
    const boundaryItemsfetchSuccessAction = action as PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_FETCHED_DATA>;
    return {
      ...boundaryItemsfetchSuccessAction,
      payload: {
        ...boundaryItemsfetchSuccessAction.payload,
        items: '<<LARGE_DATA>>',
      },
    };
  }

  if (action.type === FRONTEND_STATE_ROUTE_UI_DATA_LOADED) {
    const routeDataAction = action as PickAction<DirectionsAction, typeof FRONTEND_STATE_ROUTE_UI_DATA_LOADED>;
    return {
      ...routeDataAction,
      payload: {
        ...routeDataAction.payload,
        data: '<<LARGE_DATA>>',
      },
    };
  }

  return action;
};

const reduxTypedActionSanitizer = actionSanitizer as <A extends ReduxAction>(action: A) => A;
export { reduxTypedActionSanitizer as actionSanitizer };
