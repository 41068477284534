import { css } from '@emotion/react';
import { type FC } from 'react';

const appContentStyle = css({
  overflowY: 'auto',
  height: '100%',
  flex: '1 1 100%',
});

export const AppContent: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div
      css={appContentStyle}
      id="appContent"
    >
      {children}
    </div>
  );
};
