import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import { LogoComponent } from '~/_shared/components/logo/logo.component';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
});

const logoWrapperStyle = css({
  display: 'flex',
  margin: '64px 0 32px',
  justifyContent: 'center',
});

const loadingTextStyle = ({ theme }: ThemeProps) => css({
  margin: '20px 0 20px 0',
  color: theme.textColors.primary,
  fontSize: 35,
});

const failedTextStyle = ({ theme }: ThemeProps) => css({
  margin: '20px 0 20px 0',
  color: theme.textColors.danger,
  fontSize: 30,
  maxWidth: 600,
  textAlign: 'center',
});

const successTextStyle = ({ theme }: ThemeProps) => css({
  margin: '20px 0 20px 0',
  color: theme.textColors.success,
  fontSize: 20,
  maxWidth: 600,
  textAlign: 'center',
});

const noteTextStyle = ({ theme }: ThemeProps) => css({
  margin: '20px 0 20px 0',
  color: theme.textColors.secondary,
  fontSize: 20,
  maxWidth: 600,
  textAlign: 'center',
});

type ImpersonationStartPageComponentProps = {
  errorMessage?: string;
  isLoading?: boolean;
  showHomePageLink?: boolean;
  showSuccessful?: boolean;
};

export const ImpersonationStartPageComponent: FC<ImpersonationStartPageComponentProps> = (props) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <div css={wrapperStyle}>
      <div css={logoWrapperStyle} >
        <LogoComponent width="210px" />
      </div>
      {props.isLoading && (
        <>
          <div css={loadingTextStyle({ theme })}>{t('UserImpersonation.Start.Loading')}...</div>
          <div>
            <LoaderComponent size={100} />
          </div>
        </>
      )}
      {props.errorMessage && (
        <div css={failedTextStyle({ theme })}>{props.errorMessage}</div>
      )}
      {props.showSuccessful && (
        <div css={successTextStyle({ theme })}>{t('UserImpersonation.Start.Success')}</div>
      )}
      {!props.errorMessage && (
        <div css={noteTextStyle({ theme })}>{t('UserImpersonation.Start.Note')}</div>
      )}
      {props.showHomePageLink && (
        <LinkComponent path={HOME_ROUTE}>
          {t('To Homepage')}
        </LinkComponent>
      )}
    </div>
  );
};
