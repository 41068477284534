import {
  type FC, useMemo,
} from 'react';
import {
  getDropdownNumericOptions, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { useTranslation } from '../../../_shared/utils/hooks';
import { dropdownHeadingStyle } from '../../../boundary/settings/boundarySettings.common';

type RangeSettingsProps = Readonly<{
  className?: string;
  maxNumberOfRanges: number;
  numberOfRanges: number;
  onNumberOfRangesChange: (range: number) => void;
}>;

export const NumericalGroupSettingsRangeSettingsComponent: FC<RangeSettingsProps> = (props) => {
  const [t] = useTranslation();

  const { maxNumberOfRanges, numberOfRanges, onNumberOfRangesChange } = props;

  const numberOfRangeOptions = useMemo(() => getDropdownNumericOptions(2, maxNumberOfRanges), [maxNumberOfRanges]);

  return (
    <div className={props.className}>
      <p css={dropdownHeadingStyle}>{t('Number Of Groups')}</p>
      <RegularDropdownComponent<number>
        onChange={onNumberOfRangesChange}
        value={numberOfRanges}
        options={numberOfRangeOptions}
        inPortal
      />
    </div>
  );
};
