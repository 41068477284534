import { type TranslationFnc } from '../../_shared/utils/hooks';

export enum ExportDataSection {
  All = 'All',
  Filtered = 'Filtered',
  FilteredTableSearch = 'FilteredTableSearch',
  OnlyVisible = 'OnlyVisible',
}

export const createSectionLabel = (section: ExportDataSection, t: TranslationFnc): string => {
  switch (section) {
    case ExportDataSection.All:
      return t('Export Entire Dataset');
    case ExportDataSection.Filtered:
      return t('Export Filtered Data');
    case ExportDataSection.OnlyVisible:
      return t('Export Data Visible on Map');
    default:
      throw new Error(`ExportDataSection.enum: unknown section "${section}".`);
  }
};
