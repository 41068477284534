import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faPaperPlane, faPhone, faWindow,
} from '@fortawesome/pro-solid-svg-icons';
import {
  forwardRef, type MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { getItemStyles } from '~/customizeLocationPanel/customizeLocationPanel.constants';
import { guaranteeHash } from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { ColumnRole } from '../../../_shared/types/columnRole.enum';
import { type BubbleEditorItem } from '../locationData.types';
import { type ColumnStyleSettingsData } from '../panelSettings.types';

const contactDataWrapperStyle = ({ valueFontColor, valueFontSize }: { valueFontColor: string; valueFontSize: string }) => css({
  display: 'flex',
  color: guaranteeHash(valueFontColor),
  fontSize: `${valueFontSize}px`,
  fontWeight: 500,
  lineHeight: 1.2,
});

const linkWrapperStyle = css({ display: 'flex' });

const withEllipsisStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const linkStyle = ({ valueFontColor }: {valueFontColor: string }) => css({
  color: guaranteeHash(valueFontColor),
  textDecoration: 'none',
}, withEllipsisStyle);

const basicDataRecordWrapperStyle = ({ labelFontSize, labelFontColor }: {labelFontSize: string; labelFontColor: string}) => css({
  color: guaranteeHash(labelFontColor),
  fontSize: `${labelFontSize}px`,
  fontWeight: 500,
});

const contactIconsStyle = css({
  fontSize: 'calc(100% + 2px)',
  fontWeight: 900,
  lineHeight: '24px',
  marginRight: '8px',
});

const dataBodyRecordStyle = ({ valueFontSize, valueFontColor, verticalGap }: {
  valueFontSize: string;
  valueFontColor: string;
  verticalGap: string;
}) => css({
  color: guaranteeHash(valueFontColor),
  fontSize: `${valueFontSize}px`,
  fontWeight: 500,
  lineHeight: 1.2,
  marginTop: `${verticalGap}px`,
  paddingLeft: 8,
  textDecoration: 'none',
});

const labelStyle = css({
  textTransform: 'uppercase',
});

type BubbleItemProps = Readonly<{
  item: Required<BubbleEditorItem>;
  bulkStyles: ColumnStyleSettingsData;
  isDraggable: boolean;
  openLinksInNewTab?: boolean;
}>;

export const BubbleItemComponent = forwardRef<HTMLImageElement, BubbleItemProps>(({ item, isDraggable, openLinksInNewTab, bulkStyles }, ref) => {
  const theme = useTheme();
  const [icon] = useState(getRelevantIcon(item.itemType));
  const {
    labelFontColor,
    labelFontSize,
    showLabel,
    valueFontColor,
    valueFontSize,
    verticalGap,
  } = getItemStyles(theme, item, bulkStyles);

  const handleMouseDownCallback = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (isDraggable) {
        e.preventDefault();
      }
    },
    [isDraggable],
  );

  const isLinkElement = useMemo(() => {
    if (item.itemType === ColumnRole.Email || item.itemType === ColumnRole.URL || item.itemType === ColumnRole.Image) {
      return true;
    }
    if (typeof(item.value) === 'string' && (item.value.startsWith('http://') || item.value.startsWith('https://') || item.value.startsWith('www.'))) {
      return true;
    }

    return false;
  }, [item]);

  const hrefLink = useMemo(() => {
    if (item.itemType === ColumnRole.Email) {
      return 'mailto:' + item.value;
    }
    if (typeof(item.value) === 'string' && item.value.startsWith('www.')) {
      return '//' + item.value;
    }
    return item.value;
  }, [item]);

  return (
    <>
      {icon ? (
        <div
          css={contactDataWrapperStyle({ valueFontColor, valueFontSize })}
          ref={ref}
          onMouseDown={e => handleMouseDownCallback(e)}
          draggable="false"
        >
          <FontAwesomeIcon
            icon={icon}
            css={contactIconsStyle}
          />
          {isLinkElement ? (
            <a
              href={hrefLink}
              css={linkStyle({ valueFontColor })}
              target={openLinksInNewTab ? 'blank' : ''}
            >
              {item.value}
            </a>
          ) : (
            <div css={withEllipsisStyle} >
              {item.value}
            </div>
          )}
        </div>
      ) : (
        <div
          css={basicDataRecordWrapperStyle({ labelFontSize, labelFontColor })}
          ref={ref}
          onMouseDown={e => handleMouseDownCallback(e)}
          draggable="false"
        >
          {showLabel && (
            <span css={labelStyle}>
              {item.itemData.name}
            </span>
          )}
          {isLinkElement ? (
            <div css={linkWrapperStyle}>
              <a
                href={hrefLink}
                target={openLinksInNewTab ? 'blank' : ''}
                css={[dataBodyRecordStyle({ valueFontSize, valueFontColor, verticalGap })]}
              >
                {item.value}
              </a>
            </div>
          ) : (
            <div css={dataBodyRecordStyle({ valueFontSize, valueFontColor, verticalGap })} >
              {item.value}
            </div>
          )}
        </div>
      )}
    </>
  );
});

const getRelevantIcon = (type: ColumnRole | null): IconProp | null => {
  switch (type) {
    case ColumnRole.Phone:
      return faPhone;
    case ColumnRole.Email:
      return faPaperPlane;
    case ColumnRole.URL:
      return faWindow;

    default:
      return null;
  }
};
