import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { SettingsTableComponent } from '../settingsTable/settingsTable.component';
import {
  type ChangeableSettingRow, type SettingColumn,
  type SettingData,
  type SettingRow, SettingType,
} from '../settingsTable/settingsTable.types';

const settingsTableStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const settingRows = (rows: EditDataTableRow[]): SettingRow[] => rows.map(row => {
  const baseColumns: SettingData[] = [{
    type: SettingType.Text,
    value: row.label,
    disabled: null,
  }, {
    type: SettingType.Input,
    value: row.value,
    disabled: null,
  }];

  if (row.toggled === undefined) {
    return {
      data: baseColumns,
    };
  }

  const toggleColumn: SettingData = {
    disabled: null,
    isCentered: true,
    type: SettingType.Checkbox,
    value: row.toggled,
  };

  return { data: [...baseColumns, toggleColumn] };
});

export type EditDataTableRow = {
  label: string;
  value: string;
  toggled?: boolean;
};

type EditDataTableProps = Readonly<{
  columnNames?: SettingColumn[];
  rows: EditDataTableRow[];
  customDataCellStyle?: ({ isCentered, isDisabled }: ThemeProps<{ isCentered?: boolean; isDisabled: boolean }>) => SerializedStyles;
  customDataRowStyle?: ({ theme }: ThemeProps) => SerializedStyles;

  onChange?: (index: number, newSettingRow: ChangeableSettingRow) => void;
  onFormSubmit?: () => void;
}>;

export const EditDataTableComponent: FC<EditDataTableProps> = (props) => {
  const theme = useTheme();

  return (
    <SettingsTableComponent
      css={settingsTableStyle({ theme })}
      settingColumns={props.columnNames}
      settingRows={settingRows(props.rows)}
      onChange={props.onChange}
      customDataCellStyle={props.customDataCellStyle}
      customDataRowStyle={props.customDataRowStyle}
      onFormSubmit={props.onFormSubmit}
    />
  );
};
