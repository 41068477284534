import { css } from '@emotion/react';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useMemo,
} from 'react';
import { AccordionComponent } from '~/_shared/baseComponents/accordion';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import {
  EmptyStateComponent, EmptyStateSize,
} from '~/_shared/components/emptyState/emptyState.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { TabsComponent } from '~/_shared/components/tabs/tabs.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { MIN_PERCENTAGE_NUMERIC_EMPTY } from '~/heatMap/panel/form/useIsNumericColumnCheck.helpers';
import { ContinueButton } from '~/map/layered/createLayeredMapModal/ContinueButton';
import { type MetricValue } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import { DataWeights } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/dataWeights.component';
import {
  SELECTABLE_METRIC_LINE_SIZE, SelectableMetricsListComponent,
} from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/selectableMetricsList.component';

const continueRowStyle = ({ showFilter }: {showFilter: boolean}) => css({
  display: 'flex',
  justifyContent: showFilter ? 'space-between' : 'flex-end',
  marginTop: 20,
});

const accordionStyle = css({
  padding: 0,
  '&:first-of-type': {
    borderRadius: '4px 4px 0 0',
  },
});

const accordionItemStyle = css({
  '&:first-of-type': {
    '& > div': {
      borderRadius: '4px 4px 0 0s',
    },
  },
  '&:last-of-type': {
    borderRadius: '0 0 4px 4px',
    '& > div:last-of-type': {
      borderRadius: '0 0 4px 4px',
    },
  },
});

const tabStyle = css({
  '&:first-of-type': {
    borderRadius: '4px 0 0',
  },
  '&:last-of-type': {
    borderRadius: '0 4px 0 0 ',
  },
});

const metricsContainerStyle = css({
  height: SELECTABLE_METRIC_LINE_SIZE * 5,
  width: '100%',
});

const emptyStateStyles = css({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  height: '100%',
  flexWrap: 'wrap',
});

export type VariablesStepComponentProps = Readonly<{
  applyFilterOnData: boolean;
  onContinue: () => void;
  isLoadingDataMetrics: boolean;
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  onChangeDataMetrics: (dataMetricsValues: MetricValue[]) => void;
  onChangeDemographics: (demographicsValues: MetricValue[]) => void;
  onChangeApplyFilterOnData?: (flag: boolean) => void;
}>;

export const VariablesStepComponent: FC<VariablesStepComponentProps> = ({
  applyFilterOnData,
  onContinue,
  dataMetricsValues,
  demographicsValues,
  isLoadingDataMetrics,
  onChangeDataMetrics,
  onChangeDemographics,
  onChangeApplyFilterOnData,
}: VariablesStepComponentProps) => {
  const [t] = useTranslation();

  const selectableDataMetricsValues = useMemo(() => dataMetricsValues.map(dataMetric => ({
    id: dataMetric.id,
    label: dataMetric.name,
    checked: dataMetric.checked,
    disabled: dataMetric.numericPercent === undefined || dataMetric.numericPercent < MIN_PERCENTAGE_NUMERIC_EMPTY,
    numericPercent: dataMetric.numericPercent,
  })), [dataMetricsValues]);

  const selectableDemographicsValues = useMemo(() => demographicsValues.map(demographicsValue => ({
    id: demographicsValue.id,
    label: demographicsValue.name,
    checked: demographicsValue.checked,
  })), [demographicsValues]);

  const onChangeDataMetric = useCallback((id: string, checked: boolean) => {
    const newMetricValues = dataMetricsValues.map(dataMetricsValue => ({
      ...dataMetricsValue,
      checked: dataMetricsValue.id === id ? checked : dataMetricsValue.checked,
    }));
    onChangeDataMetrics(newMetricValues);
  }, [dataMetricsValues, onChangeDataMetrics]);

  const onChangeDemographicsValue = useCallback((id: string, checked: boolean) => {
    const newMetricValues = demographicsValues.map(demographicsValue => ({
      ...demographicsValue,
      checked: demographicsValue.id === id ? checked : demographicsValue.checked,
    }));
    onChangeDemographics(newMetricValues);
  }, [demographicsValues, onChangeDemographics]);

  const canContinue = useMemo(() => {
    return dataMetricsValues.some(dataMetric => dataMetric.checked) ||
      demographicsValues.some(demographic => demographic.checked);
  }, [dataMetricsValues, demographicsValues]);

  const onSubmit = useCallback(() => {
    onContinue();
  }, [onContinue]);

  const showFilter = !!onChangeApplyFilterOnData;

  return (
    <>
      <OutlinePanelComponent
        borderRadius={4}
        outlines={[OutlinePanelOptions.Right, OutlinePanelOptions.Bottom, OutlinePanelOptions.Left]}
      >
        <AccordionComponent
          itemStyle={accordionItemStyle}
          panelStyle={accordionStyle}
          data={[
            {
              header: null,
              isLocked: true,
              isExpanded: true,
              child: (
                <TabsComponent
                  data={[
                    {
                      header: t('Data Metrics').toUpperCase(),
                      index: 0,
                      child: (
                        <div css={metricsContainerStyle}>
                          {!isLoadingDataMetrics && selectableDataMetricsValues.length ? (
                            <SelectableMetricsListComponent
                              metrics={selectableDataMetricsValues}
                              onChange={onChangeDataMetric}
                            />
                          ) : (
                            <div css={emptyStateStyles}>
                              {isLoadingDataMetrics ? (
                                <OverlayLoaderComponent />
                              ) : (
                                <EmptyStateComponent
                                  css={{ padding: 10 }}
                                  icon={faChartSimple}
                                  title={t('No Data Columns Available')}
                                  size={EmptyStateSize.Small}
                                />
                              )}
                            </div>
                          )}
                        </div>),
                    },
                    {
                      header: t('DemographicCensusData').toUpperCase(),
                      index: 1,
                      child: (
                        <div css={metricsContainerStyle}>
                          <SelectableMetricsListComponent
                            metrics={selectableDemographicsValues}
                            onChange={onChangeDemographicsValue}
                          />
                        </div>
                      ),
                    },
                  ]}
                  tabStyle={tabStyle}
                />
              ),
            },
            {
              header: t('boundaryAI.Summary data for territory optimizing with weights'),
              isLocked: true,
              isExpanded: true,
              child: (
                <DataWeights
                  dataMetricsValues={dataMetricsValues}
                  demographicsValues={demographicsValues}
                  onChangeDataMetrics={onChangeDataMetrics}
                  onChangeDemographics={onChangeDemographics}
                />),
            },
          ]}
        />
      </OutlinePanelComponent>
      <div css={continueRowStyle({ showFilter })}>
        {showFilter && (
          <div css={css({ display: 'flex', gap: 10, alignItems: 'center', width: 350 })}>
            <CheckboxComponent
              isChecked={applyFilterOnData}
              checkedSetter={() => onChangeApplyFilterOnData(!applyFilterOnData)}
            />
            <span
              onClick={() => onChangeApplyFilterOnData(!applyFilterOnData)}
              css={css({ textTransform: 'uppercase' })}
            >{t('boundaryAI.Apply Current Filter On Data')}
            </span>
          </div>
        )}
        <ContinueButton
          label={t('Continue')}
          onSubmitClick={onSubmit}
          disabled={!canContinue}
        />
      </div>
    </>
  );
};
