import { css } from '@emotion/react';
import { faSliders } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import {
  EmptyStateComponent, EmptyStateSize,
} from '~/_shared/components/emptyState/emptyState.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type MetricValue } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';

const LINE_SIZE = 65;
const METRICS_PADDING = 16;
const METRICS_PADDING_LARGE = 20;

const container = css({
  minHeight: LINE_SIZE,
  maxHeight: 200,
});

const content = css({
  padding: METRICS_PADDING,
});

const slicerContainer = css({
  ':not(:last-of-type)': {
    marginBottom: METRICS_PADDING_LARGE,
  },
});

const helperText = css({
  fontSize: '.75em',
});

const helperTextSection = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

const slider = css({
  paddingBottom: 0,
});

type DataWeightsProps = {
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  onChangeDataMetrics: (dataMetricsValues: MetricValue[]) => void;
  onChangeDemographics: (demographicsValues: MetricValue[]) => void;
};

export const DataWeights = ({ dataMetricsValues, onChangeDataMetrics, demographicsValues, onChangeDemographics }: DataWeightsProps) => {
  const [t] = useTranslation();

  const onChangeDataMetricsSlider = useCallback((id: string, weight: number) => {
    const newMetricValues = dataMetricsValues.map(dataMetricsValue => ({
      ...dataMetricsValue,
      weight: dataMetricsValue.id === id ? weight : dataMetricsValue.weight,
    }));
    onChangeDataMetrics(newMetricValues);
  }, [onChangeDataMetrics, dataMetricsValues]);

  const onChangeDemographicsSlider = useCallback((id: string, weight: number) => {
    const newMetricValues = demographicsValues.map(demographicsValue => ({
      ...demographicsValue,
      weight: demographicsValue.id === id ? weight : demographicsValue.weight,
    }));
    onChangeDemographics(newMetricValues);
  }, [onChangeDemographics, demographicsValues]);

  const renderSliders = (metricsValues: MetricValue[], onChange: (id: string, weight: number) => void) => {
    return metricsValues
      .filter(metricsValue => metricsValue.checked)
      .map(metricsValue => (
        <div
          key={metricsValue.id}
          css={slicerContainer}
        >
          <span>{metricsValue.name}</span>
          <SliderWithValueLabelsComponent
            css={slider}
            currentValueOnTheRight
            noLabel
            onChange={([value]) => onChange(metricsValue.id, value)}
            value={[metricsValue.weight]}
            valueSuffix="%"
          />
          <span css={helperTextSection}>
            <span css={helperText}>{t('Less important')}</span>
            <span css={helperText}>{t('More important')}</span>
          </span>
        </div>
      ));
  };

  const numberOfMetrics = dataMetricsValues.concat(demographicsValues).filter(v => v.checked).length;

  return (
    <div
      css={container}
      style={{ height: numberOfMetrics ? ((numberOfMetrics * LINE_SIZE) + METRICS_PADDING) : undefined }}
    >
      {!numberOfMetrics && (
        <EmptyStateComponent
          css={{ padding: 10 }}
          icon={faSliders}
          title={t('No Metrics Selected')}
          size={EmptyStateSize.Small}
        />
      )}
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        maxHeight={200}
      >
        <div css={content}>
          {renderSliders(dataMetricsValues, onChangeDataMetricsSlider)}
          {renderSliders(demographicsValues, onChangeDemographicsSlider)}
        </div>
      </ScrollBarComponent>
    </div>
  );
};
