import {
  useCallback, useMemo,
} from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { getCustomerPortalUrl } from './billing.repository';

export const usePaymentPortal = () => {
  const clientId = useClientIdSelector();

  const getPaymentPortalUrl = useCallback(() => {
    if (clientId) {
      return getCustomerPortalUrl(clientId);
    }
    return Promise.reject();
  }, [clientId]);

  const { invokeAjax: invokeGetPaymentPortalUrl, isLoading, data } = useAjaxCall(getPaymentPortalUrl);

  return useMemo(() => ({
    isLoading,
    url: data?.data.link,
    getPaymentPortalUrl: invokeGetPaymentPortalUrl,
  }), [data?.data.link, invokeGetPaymentPortalUrl, isLoading]);
};
