import { type Column } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  apiDelete,
  apiGet, apiPost, getPrefixedApiUrl,
} from '~/_shared/utils/api/api.helpers';
import {
  type GoogleAPIOperation, type MaptiveGoogleApiScope,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { type GoogleSheetMapSyncType } from '~/map/map.repository';
import { type ColumnsMapSettingsResponse } from '../columnsMapSettings/columnsMapSettings.types';

export const EXTERNAL_ACTION_INDICATOR = 'startup_action';

export const GOOGLE_AUTH_ERROR = 'Invalid Credentials!';
export const GOOGLE_AUTH_ERROR_2 = 'Token is invalid or not exists for user!';
export const GOOGLE_TO_MAPTIVE_SYNC_COLUMNS_CHANGED = 'Conflict occured in process of synchronization!';
export const GOOGLE_SPREADSHEET_NOT_FOUND = 'Requested entity was not found.';

const GOOGLE_API_URL_PREFIX = '/api/google';

export type GoogleSpreadsheetId = string;
export type GoogleSpreadsheetSheetId = string;
export type CompositeUniqueIdentifier = string;

export type MaptiveGoogleSheetColumnsMapping<T extends number | string> = {
  maptive: {
    [columnId: string]: T;
  };
  google: {
    [columnId: string]: T;
  };
};

export enum MaptiveGoogleSheetSyncAction {
  Add = 'add',
  Ignore = 'ignore',
  Remove = 'remove',
}

export type MaptiveGoogleSheetsColumnsMatches = {
  maptiveColumn?: string;
  googleColumn?: string;
  action?: MaptiveGoogleSheetSyncAction;
};

type MaptiveGoogleSheetsColumnsMatchesServer = {
  maptive_column?: string;
  google_column?: string;
  action?: MaptiveGoogleSheetSyncAction;
};

export type MaptiveGoogleSpreadsheetSyncInfo = {
  columns: MaptiveGoogleSheetColumnsMapping<string>;
  columnsMatches: MaptiveGoogleSheetsColumnsMatches[];
  duplicates?: Partial<MaptiveGoogleSheetColumnsMapping<number>>;
  uniqueKeys: MaptiveGoogleSheetColumnsMapping<number>;
};

type MaptiveGoogleSpreadsheetSyncInfoResponse = {
  columns: MaptiveGoogleSheetColumnsMapping<string>;
  columns_matches: MaptiveGoogleSheetsColumnsMatchesServer[];
  // This will need to be filled in when duplicates exist (returned by the sync proceed call)
  duplicates?: Partial<MaptiveGoogleSheetColumnsMapping<number>>;
  unique_keys: MaptiveGoogleSheetColumnsMapping<number>;
};

export type MaptiveGoogleSheetColumnsAndMatchupData = {
  [virtualSpreadsheetId: number]: {
    autoSyncEnabled: boolean;
    columns: MaptiveGoogleSheetColumnsMapping<string>;
    lastSyncType: GoogleSheetMapSyncType;
    matchup: MaptiveGoogleSpreadsheetSyncInfo;
  };
};

type MaptiveGoogleSheetColumnsAndMatchupDataResponse = {
  [virtualSpreadsheetId: number]: {
    auto_sync_enabled: boolean;
    columns: MaptiveGoogleSheetColumnsMapping<string>;
    last_sync_type: GoogleSheetMapSyncType;
    matchup: MaptiveGoogleSpreadsheetSyncInfoResponse;
  };
};

type MaptiveGoogleSpreadsheetSyncInfoRequest = {
  columns_matches: MaptiveGoogleSheetsColumnsMatchesServer[];
  duplicates?: Partial<MaptiveGoogleSheetColumnsMapping<number>>;
  unique_keys: MaptiveGoogleSheetColumnsMapping<number>;
};

type SyncSpreadsheetRequest = {
  client_id: number;
  settings: {
    [virtualSpreadsheetId: number]: MaptiveGoogleSpreadsheetSyncInfoRequest;
  };
  spreadsheet_id: number;
};

export enum SyncSpreadsheetErrorMessage {
  DuplicateRowsMaptive = 'Actions for maptive spreadsheet duplicated rows is required',
  DuplicateRowsGoogle = 'Actions for google spreadsheet duplicated rows is required',
}

enum SyncSpreadsheetGoogleErrorMessage {
  ColumnMissingInList = 'At least one google column not specified in list!',
}

enum SyncSpreadsheetMaptiveErrorMessage {
  ColumnMissingInList = 'At least one maptive column not specified in list!',
}

export type SyncSpreadsheetMatchesErrors = {
  google?: {
    message: SyncSpreadsheetGoogleErrorMessage;
    list: { [columnId: string]: string };
  };
  maptive?: {
    message: SyncSpreadsheetMaptiveErrorMessage;
    list: { [columnId: string]: string };
  };
};

export type SyncSpreadsheetDuplicateDetails = {
  data: string[];
  process: boolean;
};

export type SyncSpreadsheetDuplicatesList = {
  [compositeUniqueIdentifier: CompositeUniqueIdentifier]: SyncSpreadsheetDuplicateDetails[];
};

export type SyncSpreadsheetResponse = {
  data: {
    [real_spreadsheet_id: number]: {
      data?: {
        rows: {
          deleted: number;
          updated?: number;
        };
        columns: {
          deleted: number;
          updated?: number;
        };
      };
      list?: SyncSpreadsheetDuplicatesList;
      matches?: SyncSpreadsheetMatchesErrors;
      message?: SyncSpreadsheetErrorMessage;
      success?: boolean;
    };
  };
};

type ImportGoogleSpreadsheetRequestBase = {
  real_spreadsheet_id: number;
  google_account_id?: string;
  gs_id?: string;
  gs_sheet?: string;
  gs_title?: string;
};

type ImportGoogleSpreadsheetRequest =
  ImportGoogleSpreadsheetRequestBase | (ImportGoogleSpreadsheetRequestBase & {
    force_sync: true;
    unique_keys: number[];
  });

type GoogleSpreadsheetSheetInfoResponse = {
  position: number;
  sheet_id: string;
  title: string;
};

export type GoogleSpreadsheetSheetInfo = {
  position: number;
  sheetId: string;
  title: string;
};

type GoogleSpreadsheetInfoResponse = {
  sheets: GoogleSpreadsheetSheetInfoResponse[];
  spreadsheet_id: string;
  title: string;
};

export type GoogleSpreadsheetInfo = {
  sheets: GoogleSpreadsheetSheetInfo[];
  spreadsheetId: string;
  title: string;
};

export type GetGoogleSpreadsheetDataSuccessResponse = {
  data?: {
    rows: string[][];
  };
};

export type GetGoogleSpreadsheetDataFailResponse = {
  message?: string | typeof GOOGLE_AUTH_ERROR;
};

export type MaptiveAndGoogleSpreadsheetIntersectionResponse = {
  google_columns: Column[];
  maptive_columns: Column[];
  intersects: {
    google: string;
    maptive: string;
  }[];
};

type InvokeGoogleAPIOAuthProps = Readonly<{
  scopes: MaptiveGoogleApiScope[];
  operation: GoogleAPIOperation;
  callbackUrlSuffix: string;
}>;

export type RefreshGoogleAccessTokenSuccessResponse = Readonly<{
  data: {
    access_token: string;
  };
}>;

type RefreshGoogleAccessTokenFailResponse = Readonly<{
  message: string;
}>;

export type GoogleSheetManualSyncConflictFailResponse = {
  message: typeof GOOGLE_TO_MAPTIVE_SYNC_COLUMNS_CHANGED;
  columns_map_settings?: ColumnsMapSettingsResponse;
};

export type GoogleSheetManualSyncFailResponse = GoogleSheetManualSyncConflictFailResponse | {
  message: typeof GOOGLE_AUTH_ERROR_2 | string;
};

type GoogleSheetManualSyncResponse = GoogleSheetManualSyncFailResponse & {
  message: string;
};

export const syncMaptiveGoogleSheetsTwoWay =
  (request: SyncSpreadsheetRequest): Promise<{ data: SyncSpreadsheetResponse }> => {
    const requestUrl = GOOGLE_API_URL_PREFIX + '/sheets/synchronize';

    return apiPost(requestUrl, request);
  };

export const importGoogleSpreadsheetData =
  (request: ImportGoogleSpreadsheetRequest): Promise<GoogleSheetManualSyncResponse> => {
    const requestUrl = GOOGLE_API_URL_PREFIX + '/sheets/synchronization/manual/google';

    return apiPost(requestUrl, request);
  };

export const getMaptiveGoogleSpreadsheetMatches =
  (clientId: number, spreadsheetId: number): Promise<{ data: MaptiveGoogleSheetColumnsAndMatchupDataResponse }> => {
    const requestUrl = GOOGLE_API_URL_PREFIX + `/sheets/matches?client_id=${clientId}&spreadsheet_id=${spreadsheetId}`;

    return apiGet(requestUrl);
  };

export const getGoogleSpreadsheetInfo =
  (accId: string, googleSpreadsheetId: string): Promise<{ data: GoogleSpreadsheetInfoResponse }> => {
    const requestUrl = GOOGLE_API_URL_PREFIX + `/${accId}/spreadsheets/${googleSpreadsheetId}`;

    return apiGet(requestUrl);
  };

export const getGoogleSpreadsheetSheetData =
  (accId: string, googleSpreadsheetId: string, googleSheetId: string): Promise<GetGoogleSpreadsheetDataSuccessResponse | GetGoogleSpreadsheetDataFailResponse> => {
    const requestUrl = GOOGLE_API_URL_PREFIX + `/${accId}/spreadsheets/${googleSpreadsheetId}/${googleSheetId}`;

    return apiGet(requestUrl);
  };

export const guessMaptiveAndGoogleSpreadsheetIntersections = (
  accId: string,
  googleSpreadsheetId: string,
  googleSheetId: string,
  realSpreadsheetId: number,
): Promise<Readonly<{ data: MaptiveAndGoogleSpreadsheetIntersectionResponse }>> => {
  const requestUrl = GOOGLE_API_URL_PREFIX + `/${accId}/spreadsheets/${googleSpreadsheetId}/${googleSheetId}/intersects/${realSpreadsheetId}`;

  return apiGet(requestUrl);
};

export const getGoogleAPIOAuthUrl = (props: InvokeGoogleAPIOAuthProps): string => {
  const { scopes, operation } = props;
  const callbackAtt = encodeURIComponent(`${location.protocol}//${location.host}${props.callbackUrlSuffix}?${EXTERNAL_ACTION_INDICATOR}=${operation}`);
  const scopesAtt = `${scopes.reduce((result, s, i) =>
    !i ? s : result + `,${s}`, '')}`;

  return getPrefixedApiUrl(GOOGLE_API_URL_PREFIX + '/oauth/redirect?scopes=' + scopesAtt + '&callback=' + callbackAtt);
};

export const invokeGoogleOauth = (props: InvokeGoogleAPIOAuthProps) => location.replace(getGoogleAPIOAuthUrl(props));

export const revokeGoogleAccessToken = (accId: string): Promise<{ message: string }> => {
  const requestUrl = GOOGLE_API_URL_PREFIX + `/accounts/${accId}/revoke`;
  return apiDelete(requestUrl);
};

export const refreshGoogleAccessToken = (googleAccountId: string): Promise<RefreshGoogleAccessTokenSuccessResponse | RefreshGoogleAccessTokenFailResponse> => {
  const requestUrl = GOOGLE_API_URL_PREFIX + `/accounts/${googleAccountId}/refresh`;
  return apiPost(requestUrl);
};

const convertMaptiveGoogleSheetsColumnsMatchesServerToClient =
  (response: MaptiveGoogleSheetsColumnsMatchesServer[]): MaptiveGoogleSheetsColumnsMatches[] =>
    response.map((item) => ({
      maptiveColumn: item.maptive_column,
      googleColumn: item.google_column,
      action: item.action,
    }));

export const convertMaptiveGoogleSheetsColumnsMatchesClientToServer =
  (response: MaptiveGoogleSheetsColumnsMatches[]): MaptiveGoogleSheetsColumnsMatchesServer[] =>
    response.map((item) => ({
      maptive_column: item.maptiveColumn,
      google_column: item.googleColumn,
      action: item.action,
    }));

const convertMaptiveGoogleSpreadsheetSyncInfoResponse =
  (response: MaptiveGoogleSpreadsheetSyncInfoResponse): MaptiveGoogleSpreadsheetSyncInfo => ({
    columns: response.columns,
    columnsMatches: convertMaptiveGoogleSheetsColumnsMatchesServerToClient(response.columns_matches),
    duplicates: response.duplicates,
    uniqueKeys: response.unique_keys,
  });

export const convertMaptiveGoogleSheetColumnsAndMatchupDataResponse =
  (response: MaptiveGoogleSheetColumnsAndMatchupDataResponse): MaptiveGoogleSheetColumnsAndMatchupData =>
    Object.entries(response).reduce((acc, [virtualSpreadsheetId, data]) => {
      acc[Number(virtualSpreadsheetId)] = {
        autoSyncEnabled: data.auto_sync_enabled,
        columns: data.columns,
        matchup: convertMaptiveGoogleSpreadsheetSyncInfoResponse(data.matchup),
        lastSyncType: data.last_sync_type,
      };
      return acc;
    }, {} as MaptiveGoogleSheetColumnsAndMatchupData);

export const convertGoogleSpreadsheetInfoResponse =
  (response: GoogleSpreadsheetInfoResponse): GoogleSpreadsheetInfo => ({
    sheets: response.sheets.map(item => ({
      position: item.position,
      sheetId: item.sheet_id,
      title: item.title,
    })),
    spreadsheetId: response.spreadsheet_id,
    title: response.title,
  });
