import {
  createColor, guaranteeHash,
} from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { type Theme } from '../../../_shared/themes/theme.model';
import { getDarkOrLightDependingOnBaseColor } from '../../../_shared/utils/colors/colors.helpers';

export enum DirectionsWaypointPosition {
  Start='Start',
  Middle='Middle',
  End='End',
}

export const getDirectionsTilePosition = (index: number, itemsLength: number): DirectionsWaypointPosition => {
  if (index === 0) {
    return DirectionsWaypointPosition.Start;
  }
  if (index === itemsLength - 1) {
    return DirectionsWaypointPosition.End;
  }

  return DirectionsWaypointPosition.Middle;
};

export const getDirectionsTileColors = (position: DirectionsWaypointPosition, theme: Theme, routeColor: string): {
  background: string;
  textColor: string;
} => {
  if (position === DirectionsWaypointPosition.Start) {
    return {
      background: theme.iconColors.success,
      textColor: theme.textColors.quaternary,
    };
  }

  if (position === DirectionsWaypointPosition.End) {
    return {
      background: theme.iconColors.danger,
      textColor: theme.textColors.quaternary,
    };
  }

  const baseColor = createColor(routeColor);
  const lightTextColor = createColor('#fff');
  const darkTextColor = createColor('#000');

  return {
    background: routeColor,
    textColor: guaranteeHash(getDarkOrLightDependingOnBaseColor(baseColor, darkTextColor, lightTextColor).hex),
  };
};
