import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  ButtonComponent, type ButtonProps,
} from '~/_shared/baseComponents/buttons/button/button.component';

export const FormButtonComponent: FC<ButtonProps> = (props) => {
  return (
    <ButtonComponent
      {...props}
      css={css({ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 })}
    />
  );
};
