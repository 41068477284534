import {
  type BoundaryEtag, type BoundaryEtagServerModal,
} from './etag.types';

export const createEtagFromServerModal = (etagModel: BoundaryEtagServerModal): BoundaryEtag => ({
  lastUpdatedUserId: etagModel.last_updated_user_id,
  lastUpdatedUserName: etagModel.last_updated_user_name,
  timestamp: etagModel.timestamp,
});

export const createEtagServerModel = (etag: BoundaryEtag): BoundaryEtagServerModal => ({
  last_updated_user_id: etag.lastUpdatedUserId,
  last_updated_user_name: etag.lastUpdatedUserName,
  timestamp: etag.timestamp,
});
