import { type LatLng } from '~/_shared/types/latLng';
import {
  areLatLngEqual, MAX_GOOGLE_LAT_LONG_DECIMALS,
} from '~/_shared/utils/latLng/latLng.helpers';

const VERY_SMALL_VALUE = 0.0000000001; // 10 decimals
const VERY_BIG_VALUE = 100000000000000; // 15 digits

const incrementValue = (coordinate: number) =>
  Math.round((coordinate + VERY_SMALL_VALUE) * VERY_BIG_VALUE) / VERY_BIG_VALUE;

export const incrementLatLng = (vertex: LatLng): LatLng => ({
  lat: incrementValue(vertex.lat),
  lng: incrementValue(vertex.lng),
});

//TODO This is added to work around WebGLLayer's issue of not allowing 2 of the same points
export const ensureVertexNotOverlapping = (currentVertex: LatLng, drawnVertices: LatLng[]): LatLng => {
  const isNotOverlapping = !drawnVertices.find(vertex => areLatLngEqual(currentVertex, vertex, MAX_GOOGLE_LAT_LONG_DECIMALS));

  if (isNotOverlapping) {
    return currentVertex;
  }

  return ensureVertexNotOverlapping(incrementLatLng(currentVertex), drawnVertices);
};
