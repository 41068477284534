import { type StorageService } from '../../_shared/utils/storageService';

const GROUPING_SETTING_COLUMN_IS_EXPANDED_KEY_PREFIX = 'GROUPING_SETTING_COLUMN_IS_EXPANDED_';

const getSettingColumnKey = (id: string) => GROUPING_SETTING_COLUMN_IS_EXPANDED_KEY_PREFIX + id;

export const checkIfGroupingSettingColumnIsExpanded = (id: string, storageService: StorageService): boolean => {
  const key = getSettingColumnKey(id);
  const value = storageService.getLocalStorageItem(key);

  return value === null || value === 'true';
};

export const storeIsGroupingSettingColumnExpanded = (id: string, isExpanded: boolean, storageService: StorageService) => {
  const key = getSettingColumnKey(id);

  storageService.setLocalStorageItem(key, String(isExpanded));
};
