import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesCourgette: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['geometry.fill'],
    stylers: {
      saturation: {
        isActive: true,
        value: -66,
      },
      lightness: {
        isActive: true,
        value: 1,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.labels,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#C1F6D8',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#000000',
      },
      lightness: {
        isActive: true,
        value: 13,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['geometry.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#000000',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#144b53',
      },
      lightness: {
        isActive: true,
        value: 14,
      },
      weight: {
        isActive: true,
        value: 1.4,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.landscape,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      color: {
        isActive: true,
        value: '#223c35',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#172720',
      },
      lightness: {
        isActive: true,
        value: 5,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#162723',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      saturation: {
        isActive: true,
        value: 14,
      },
      weight: {
        isActive: true,
        value: 0.43,
      },
      color: {
        isActive: true,
        value: '#357464',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.labels,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#1f3222',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      lightness: {
        isActive: true,
        value: 25,
      },
      color: {
        isActive: true,
        value: '#133f19',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName.labels,
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway.controlledAccess'],
    elementType: BaseMapElementTypeName['labels.text'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#000000',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      lightness: {
        isActive: true,
        value: 16,
      },
      color: {
        isActive: true,
        value: '#1ad9ba',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName['labels.text'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName.all,
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#26625a',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#48b697',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      color: {
        isActive: true,
        value: '#233833',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#131d19',
      },
    },
  },
];
