import {
  useEffect,
  useState,
} from 'react';

type ElementDimensions = Readonly<{
  width: number;
  height: number;
  contentWidth: number;
  contentHeight: number;
}>;

export const useElementDimensions = () => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [dimensions, setDimensions] = useState<ElementDimensions>();
  const [observer] = useState(new ResizeObserver(entries => {
    const { contentRect, target } = entries[0] ?? {};
    if (contentRect && target) {
      const elementRect = target.getBoundingClientRect();
      setDimensions({
        width: elementRect.width,
        height: elementRect.height,
        contentWidth: contentRect.width,
        contentHeight: contentRect.height,
      });
    }
  }));

  useEffect(() => {
    if (element === null) {
      return;
    }
    observer.disconnect();
    observer.observe(element);
  }, [element, observer]);

  useEffect(() => () => observer.disconnect(), [observer]);

  return {
    ref: setElement,
    ...dimensions,
  };
};
