import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  autoUpdate,
  type Placement, useFloating,
} from '@floating-ui/react';
import {
  type ReactNode, useCallback, useContext, useState,
} from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { ContextMenuClosingContext } from './contextMenuClosingContext';
import { ContextMenuContentComponent } from './contextMenuContent.component';

type Props = Readonly<{
  children: ReactNode;
  renderSubmenu?: () => ReactNode;
  showSubmenuOnClick?: boolean;
  className?: string;
  onClick?: () => void;
  closeOnClick?: boolean;
  itemStyle?: SerializedStyles;
  submenuStyle?: SerializedStyles;
  subMenuMaxWidth?: number;
  preferredPlacement?: Placement;
}>;

const menuItemStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
  '&:first-of-type': {
    borderTop: 'none',
  },
});

export const ContextMenuItemCoreComponent = ({
  children,
  className,
  closeOnClick = true,
  itemStyle,
  onClick,
  preferredPlacement = 'right-start',
  renderSubmenu,
  showSubmenuOnClick,
  subMenuMaxWidth,
  submenuStyle,
  ...restProps
}: Props) => {
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    placement: preferredPlacement,
    whileElementsMounted: autoUpdate,
  });
  const { closeContextMenu } = useContext(ContextMenuClosingContext);
  const theme = useTheme();

  const handleHide = useCallback(() => setIsSubmenuVisible(false), []);

  return (
    <div
      ref={refs.setReference}
      css={menuItemStyle({ theme })}
      className={className}
      onMouseEnter={() => !showSubmenuOnClick && setIsSubmenuVisible(true)}
      onMouseLeave={() => !showSubmenuOnClick && setIsSubmenuVisible(false)}
      {...restProps}
    >
      <div
        css={itemStyle}
        onClick={event => {
          event.stopPropagation();
          onClick?.();
          if (closeOnClick) {
            closeContextMenu();
          }
          if (showSubmenuOnClick) {
            setIsSubmenuVisible(!isSubmenuVisible);
          }
        }}
      >
        {children}
      </div>
      {renderSubmenu && (
        <ContextMenuContentComponent
          css={submenuStyle}
          ref={refs.setFloating}
          isVisible={isSubmenuVisible}
          onHide={handleHide}
          style={floatingStyles}
          maxWidth={subMenuMaxWidth}
        >
          {renderSubmenu()}
        </ContextMenuContentComponent>
      )}
    </div>
  );
};
