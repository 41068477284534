import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DATA_ROUTE } from '~/_shared/constants/routes';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import {
  determineRequiredMaptiveScopes, GoogleAPIOperation,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { GoogleSheetMapSyncType } from '~/map/map.repository';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  type GoogleSpreadsheetInfo, importGoogleSpreadsheetData, invokeGoogleOauth,
} from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useCurrentGoogleAccountIdSelector } from '~/store/selectors/googleUserSelectors.selector';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { usePrimarySpreadsheetRealSpreadsheets } from '~/store/selectors/usePrimarySpreadsheetRealSpreadsheets';
import { useGetGoogleSheetDataAsColumnsWithValues } from './dataSource/useGetGoogleSheetDataAsColumnsWithValues.hook';
import { useHandleDataSourceErrors } from './dataSource/useHandleDataSourceErrors.hook';
import { SyncDataContainer } from './syncData.container';
import { useResolveGoogleToMaptiveSyncError } from './useResolveGoogleToMaptiveSyncError.hook';

export type SyncDataModalForReconnectingGoogleSheetContainerProps = ModalProps<{
  forceSync?: boolean;
  googleSheetSpreadsheetInfo: GoogleSpreadsheetInfo & { googleSheetId: string };
}>;

export const SyncDataModalForReconnectingGoogleSheetContainer: FC<SyncDataModalForReconnectingGoogleSheetContainerProps> = props => {
  const { googleSheetSpreadsheetInfo, forceSync } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const clientId = useClientIdSelector();
  const currentGoogleAccId = useCurrentGoogleAccountIdSelector();
  const realSpreadsheetId = usePrimarySpreadsheetRealSpreadsheets()?.[0]?.id;
  const dataRouteWithMapId = DATA_ROUTE.replace(':mapId', mapId?.toString() || '');
  const { handleOneWayGoogleSheetError } = useResolveGoogleToMaptiveSyncError();
  const { replaceCurrentModal: reopenReconnectGoogleSheetModal } = useModal(ModalType.ReconnectGoogleSheet);

  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);

  const isReadyForSync = clientId && currentGoogleAccId && realSpreadsheetId;

  const googleSheetDataSourceParams = useMemo(() => ({
    spreadsheetId: googleSheetSpreadsheetInfo.spreadsheetId,
    sheetId: googleSheetSpreadsheetInfo.googleSheetId,
  }), [googleSheetSpreadsheetInfo]);

  const googleSheetServerDataSource = useGetGoogleSheetDataAsColumnsWithValues(googleSheetDataSourceParams);

  const submitSyncButtonProps = useMemo(() => ({
    prefixIcon: faSync,
    text: t('Reconnect'),
  }), [t]);

  const redirectToGoogleOAuthAndBack = useCallback(() => {
    invokeGoogleOauth({
      operation: GoogleAPIOperation.RECONNECT_GOOGLE_SPREADSHEET,
      scopes: determineRequiredMaptiveScopes(GoogleSheetMapSyncType.GoogleToMaptive),
      callbackUrlSuffix: dataRouteWithMapId,
    });
  }, [dataRouteWithMapId]);

  const handleSyncError = useCallback((e: ApiError) => {
    const handleGeneralError = () => {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('Unable to Synchronize Google Sheet Data'),
        content: t('googleSheets.sync.unableToSync'),
      }));
    };

    const handleConflictResolved = () => {
      reopenReconnectGoogleSheetModal({
        forceSync: true,
        googleSheetSpreadsheetInfo,
      });
    };

    handleOneWayGoogleSheetError(e, handleConflictResolved, redirectToGoogleOAuthAndBack, handleGeneralError);
  }, [dispatch, googleSheetSpreadsheetInfo, handleOneWayGoogleSheetError, redirectToGoogleOAuthAndBack, reopenReconnectGoogleSheetModal, t]);

  const syncData = useCallback((_syncMethod: GoogleSheetMapSyncType, identifierIds?: number[]) => {
    if (isReadyForSync) {
      setIsSyncLoading(true);
      const firstOrSelectedSheetId = googleSheetSpreadsheetInfo.googleSheetId || googleSheetSpreadsheetInfo.sheets[0]?.sheetId;
      const selectedSheet = googleSheetSpreadsheetInfo.sheets.find((sheet) => (
        sheet.sheetId === firstOrSelectedSheetId
      )) || googleSheetSpreadsheetInfo.sheets[0];

      importGoogleSpreadsheetData({
        google_account_id: currentGoogleAccId,
        gs_id: googleSheetSpreadsheetInfo.spreadsheetId,
        gs_sheet: selectedSheet?.title || undefined,
        gs_title: googleSheetSpreadsheetInfo.title,
        real_spreadsheet_id: realSpreadsheetId,
        ...(forceSync ? { force_sync: true } : {}),
        ...(identifierIds ? { unique_keys: identifierIds } : {}),
      }).then(() => {
        location.reload();
      }).catch((e: ApiError) => {
        handleSyncError(e);
      }).finally(() => {
        setIsSyncLoading(false);
      });
    }
  }, [currentGoogleAccId, forceSync, googleSheetSpreadsheetInfo, handleSyncError, isReadyForSync, realSpreadsheetId]);

  useHandleDataSourceErrors(googleSheetServerDataSource, redirectToGoogleOAuthAndBack);

  useEffect(() => {
    if (googleSheetServerDataSource.fetch && !googleSheetServerDataSource.isLoading && !googleSheetServerDataSource.header && !googleSheetServerDataSource.error) {
      googleSheetServerDataSource.fetch();
    }
  }, [googleSheetServerDataSource]);

  return (
    <SyncDataContainer
      {...props}
      dataSource={googleSheetServerDataSource}
      initialSyncMethod={GoogleSheetMapSyncType.GoogleToMaptive}
      isLoading={isSyncLoading || googleSheetServerDataSource.isLoading}
      modalCaption={t('Reconnect Google Spreadsheet')}
      submitSyncButtonProps={submitSyncButtonProps}
      syncSelectedButtonProps={submitSyncButtonProps}
      skipSyncSelectStep
      onSyncFormSubmitted={isReadyForSync ? syncData : undefined}
    />
  );
};
