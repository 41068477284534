import { type TranslationFnc } from '~/_shared/utils/hooks';
import { BoundaryGroupCodenames } from '~/boundary/boundary.types';
import { type WmsDemographicConstraints } from '~/store/boundaryGroups/boundaryGroups.repository';
import {
  BoundaryGroupFromAIModalSteps, type CreateBoundaryGroupFromAIConfig, type MetricValue,
} from './createBoundaryGroupFromAIModal.types';

type BoundaryGroupFromAiModalStepFlags = { [key in keyof typeof BoundaryGroupFromAIModalSteps]: boolean };

export const isStepExpanded = (currentStep: BoundaryGroupFromAIModalSteps): BoundaryGroupFromAiModalStepFlags => ({
  [BoundaryGroupFromAIModalSteps.BuildingBlock]: currentStep === BoundaryGroupFromAIModalSteps.BuildingBlock,
  [BoundaryGroupFromAIModalSteps.Variables]: currentStep === BoundaryGroupFromAIModalSteps.Variables,
  [BoundaryGroupFromAIModalSteps.AdditionalOptions]: currentStep === BoundaryGroupFromAIModalSteps.AdditionalOptions,
  [BoundaryGroupFromAIModalSteps.Name]: currentStep === BoundaryGroupFromAIModalSteps.Name,
});

export const isStepLocked = (currentStep: BoundaryGroupFromAIModalSteps): BoundaryGroupFromAiModalStepFlags => ({
  [BoundaryGroupFromAIModalSteps.BuildingBlock]: false,
  [BoundaryGroupFromAIModalSteps.Variables]: ![BoundaryGroupFromAIModalSteps.AdditionalOptions, BoundaryGroupFromAIModalSteps.Name].includes(currentStep),
  [BoundaryGroupFromAIModalSteps.AdditionalOptions]: ![BoundaryGroupFromAIModalSteps.Name].includes(currentStep),
  [BoundaryGroupFromAIModalSteps.Name]: true,
});

export const DemographicCategories = (t: TranslationFnc) => [
  {
    id: 'census.Population',
    name: t('census.Population'),
  },
  {
    id: 'census.MalePopulation',
    name: t('census.MalePopulation'),
  },
  {
    id: 'census.FemalePopulation',
    name: t('census.FemalePopulation'),
  },
  // commenting out for now, because backend doesnt contain min/max data and we are unsure how this constrain works in WMS
  // {
  //   id: 'census.Area',
  //   name: t('census.Area'),
  // },
  {
    id: 'census.Age_Under5',
    name: t('census.Age_Under5'),
  },
  {
    id: 'census.Age_5to9',
    name: t('census.Age_5to9'),
  },
  {
    id: 'census.Age_10to14',
    name: t('census.Age_10to14'),
  },
  {
    id: 'census.Age_15to19',
    name: t('census.Age_15to19'),
  },
  {
    id: 'census.Age_20to24',
    name: t('census.Age_20to24'),
  },
  {
    id: 'census.Age_25to29',
    name: t('census.Age_25to29'),
  },
  {
    id: 'census.Age_30to34',
    name: t('census.Age_30to34'),
  },
  {
    id: 'census.Age_35to39',
    name: t('census.Age_35to39'),
  },
  {
    id: 'census.Age_40to44',
    name: t('census.Age_40to44'),
  },
  {
    id: 'census.Age_45to49',
    name: t('census.Age_45to49'),
  },
  {
    id: 'census.Age_50to54',
    name: t('census.Age_50to54'),
  },
  {
    id: 'census.Age_55to59',
    name: t('census.Age_55to59'),
  },
  {
    id: 'census.Age_60to64',
    name: t('census.Age_60to64'),
  },
  {
    id: 'census.Age_65to69',
    name: t('census.Age_65to69'),
  },
  {
    id: 'census.Age_70to74',
    name: t('census.Age_70to74'),
  },
  {
    id: 'census.Age_75to79',
    name: t('census.Age_75to79'),
  },
  {
    id: 'census.Age_80to84',
    name: t('census.Age_80to84'),
  },
  {
    id: 'census.Age_16andOver',
    name: t('census.Age_16andOver'),
  },
  {
    id: 'census.Age_18andOver',
    name: t('census.Age_18andOver'),
  },
  {
    id: 'census.Age_21andOver',
    name: t('census.Age_21andOver'),
  },
  {
    id: 'census.Age_62andOver',
    name: t('census.Age_62andOver'),
  },
  {
    id: 'census.Age_65andOver',
    name: t('census.Age_65andOver'),
  },
  {
    id: 'census.Age_85andOver',
    name: t('census.Age_85andOver'),
  },
] as const;

export const populateDemographicValues = (
  t: TranslationFnc,
  wdc: WmsDemographicConstraints,
): MetricValue[] => DemographicCategories(t).map(cat => ({
  id: cat.id,
  name: cat.name,
  checked: false,
  weight: 100,
  min: wdc[cat.id]?.min || 0.1,
  max: wdc[cat.id]?.max || 100,
  isConstraint: false,
}));

export const getBoundariesGroupIdByType = (boundariesGroups: ReadonlyArray<{id: number; codename?: string}>) => {
  return {
    zipsGroupId: boundariesGroups.find(bG => bG.codename === BoundaryGroupCodenames.UsZipCodes)?.id ?? 0,
    statesGroupId: boundariesGroups.find(bG => bG.codename === BoundaryGroupCodenames.UsStates)?.id ?? 0,
  };
};

export const getTargetNumberOfTerritories = (config: CreateBoundaryGroupFromAIConfig) =>
  config.useNumberOfTerritories ? config.numberOfTerritories :
    config.respectSalesLocations ? config.numberOfSalesReps :
      config.respectPrevTerritories ? config.numberOfPreviousTerritories : null;
