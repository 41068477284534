import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { SelectGroupComponent } from '../../../_shared/components/selectGroup/selectGroup.component';
import {
  HeatMapSample, HeatMapStyle,
} from '../../../_shared/types/heatmap/heatMap.enum';
import {
  type SpreadsheetColumn, type SpreadsheetColumnId,
} from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type GroupData } from '../../../store/spreadsheetData/spreadsheetData.state';
import { HeatMapDropdownComponent } from './formParts/heatMapDropdown.component';
import { HeatMapDropdownLabelComponent } from './formParts/heatMapDropdownLabel.component';
import {
  getHeatMapSampleOptions, getHeatMapStyleOptions, validateHeatMapItem,
} from './heatMapPanelForm.helpers';
import { NumericColumnSelectorComponent } from './numericColumnSelector.component';

type HeatMapPanelFormProps = Readonly<{
  onSubmit: (item: HeatMapPanelSubmitItem) => void;
  selectableColumns: ReadonlyArray<SpreadsheetColumn>;
}>;

export type HeatMapPanelSubmitItem = {
  style: HeatMapStyle;
  sample: HeatMapSample;
  selectedNumericColumnId: SpreadsheetColumnId | null;
  selectedGroupColumn: SpreadsheetColumnId | null;
  selectedGroupId: string | null;
  selectedGroupName: string | null;
};

const submitButtonStyle = css({
  marginTop: 18,
  width: '100%',
  height: 48,
});

export const HeatMapPanelFormComponent: FC<HeatMapPanelFormProps> = (props) => {
  const [selectedHeatMapStyle, setSelectedHeatMapStyle] = useState<HeatMapStyle>(HeatMapStyle.MarkerDensity);
  const [selectedSample, setSelectedSample] = useState<HeatMapSample>(HeatMapSample.AllMarkers);
  const [selectedNumericColumnId, setSelectedNumericColumnId] = useState<SpreadsheetColumnId | null>(null);
  const [selectedGroupColumn, setSelectedGroupColumn] = useState<SpreadsheetColumnId | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
  const [t] = useTranslation();

  const heatMapStyleOptions = useMemo(() => getHeatMapStyleOptions(t), [t]);
  const sampleOptions = useMemo(() => getHeatMapSampleOptions(t), [t]);

  const groupColumnOptions = useMemo(() =>
    props.selectableColumns.map((c, index) => ({ name: c.name, value: index })),
  [props.selectableColumns]);

  let dropdownCounter = 1;

  const isFormValid = validateHeatMapItem(
    selectedHeatMapStyle,
    selectedNumericColumnId,
    selectedSample,
    selectedGroup?.id ?? null
  );

  const onAddHeatMapClick = useCallback(() => {
    if (!isFormValid) {
      return;
    }

    const onSubmit = props.onSubmit;

    onSubmit({
      style: selectedHeatMapStyle,
      sample: selectedSample,
      selectedGroupId: selectedGroup?.id ?? null,
      selectedGroupName: selectedGroup?.label ?? null,
      selectedGroupColumn,
      selectedNumericColumnId,
    });
  }, [isFormValid, props.onSubmit, selectedGroup?.id, selectedGroup?.label, selectedGroupColumn,
    selectedHeatMapStyle, selectedNumericColumnId, selectedSample]);

  const onSelectedGroupChange = useCallback((group: GroupData | null) => setSelectedGroup(group), []);

  const onNumericalColumnSelected = useCallback((columnId: string, spreadsheetId: number) => {
    setSelectedNumericColumnId({ columnId, spreadsheetId });
  }, []);

  const onGroupColumnSelected = useCallback((newIndex: number | null) => {
    if (newIndex !== null) {
      setSelectedGroupColumn({
        spreadsheetId: props.selectableColumns[newIndex].spreadsheetId,
        columnId: props.selectableColumns[newIndex].id,
      });
    }
  }, [props.selectableColumns]);

  useEffect(() => {
    setSelectedSample(HeatMapSample.AllMarkers);
    setSelectedGroupColumn(null);
  }, [selectedHeatMapStyle, selectedNumericColumnId]);

  return (
    <div>
      <HeatMapDropdownLabelComponent>{dropdownCounter++}. {t('Select Heat Map Style')}</HeatMapDropdownLabelComponent>
      <HeatMapDropdownComponent
        onChange={setSelectedHeatMapStyle}
        value={selectedHeatMapStyle}
        options={heatMapStyleOptions}
      />

      {selectedHeatMapStyle === HeatMapStyle.NumericalData && (
        <>
          <HeatMapDropdownLabelComponent>{dropdownCounter++}. {t('Select Numerical Data')}</HeatMapDropdownLabelComponent>
          <NumericColumnSelectorComponent
            onColumnSelected={onNumericalColumnSelected}
            selectedColumnId={selectedNumericColumnId}
            allColumns={props.selectableColumns}
            placeholder={t('Select Numerical Column...')}
          />
        </>
      )}

      {(selectedHeatMapStyle === HeatMapStyle.MarkerDensity || (selectedHeatMapStyle === HeatMapStyle.NumericalData && selectedNumericColumnId)) && (
        <>
          <HeatMapDropdownLabelComponent>{dropdownCounter++}. {t('Select Sample')}</HeatMapDropdownLabelComponent>
          <HeatMapDropdownComponent
            onChange={setSelectedSample}
            value={selectedSample}
            options={sampleOptions}
          />
        </>
      )}

      {selectedSample === HeatMapSample.SpecificGroup && (
        <>
          <HeatMapDropdownLabelComponent>{dropdownCounter++}. {t('Select a Group Column')}</HeatMapDropdownLabelComponent>
          <HeatMapDropdownComponent
            onChange={onGroupColumnSelected}
            value={selectedGroupColumn ?
              props.selectableColumns.findIndex(c =>
                selectedGroupColumn.columnId === c.id && selectedGroupColumn.spreadsheetId === c.spreadsheetId
              ) : null
            }
            options={groupColumnOptions}
            placeholder={t('Select A Column...')}
          />

          <SelectGroupComponent
            spreadsheetId={selectedGroupColumn?.spreadsheetId ?? null}
            onSelectedGroupChange={onSelectedGroupChange}
            selectedGroup={selectedGroup}
            selectedGroupColumn={selectedGroupColumn?.columnId || null}
            label={`${dropdownCounter}. ${t('Select a Group')}`}
            placeholder={t('Select A Group...')}
          />
        </>
      )}

      <ButtonComponent
        onClick={onAddHeatMapClick}
        css={submitButtonStyle}
        text={t('Add Heat Map')}
        isDisabled={!isFormValid}
      />
    </div>
  );
};
