import {
  useCallback, useState,
} from 'react';
import { useSnapshotSettingsOverrides } from '~/sidebar/sidebarApps/saveMapView/useSnapshotSettingsOverrides.hook';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useMapSettingsToolsStateSelector } from '~/store/mapSettings/toolsState/mapSettingsToolsState.selectors';
import { MapPrivacyLevel } from '../../_shared/types/map';
import { type ApiError } from '../../_shared/utils/api/apiError.helpers';
import { copy } from '../../_shared/utils/collections/collections';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { shareMap } from '../map.repository';
import { ShareInputField } from './shareInputField.enum';

export type FieldErrors = ReadonlyMap<ShareInputField, ReadonlyArray<string>>;

export type ShareMapRequestParams = Readonly<{
  mapId: number | null;
  clientId: number | null;
  privacyLevel: MapPrivacyLevel;
  password: string | null;
  useCurrentMapView: boolean;
  mapName: string | null | undefined;
}>;

const printUnknownFieldErrorsMessage = (apiError: ApiError) => {
  const unknownFieldErrors = Object.values(ShareInputField).reduce((errors, field) => {
    delete errors[field];
    return errors;
  }, { ...apiError.errors });
  if (Object.values(unknownFieldErrors).length > 0) {
    console.error('shareMap: got ApiError with unknown fieldErrors: ', unknownFieldErrors);
  }
};

export const useShareMap = () => {
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(new Map());
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shareId, setShareId] = useState('');
  const isMountedRef = useIsComponentMountedRef();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds;
  const currentToolsState = useMapSettingsToolsStateSelector();
  const { getSnapshotSettingsOverrides } = useSnapshotSettingsOverrides();

  const submitShareRequest = useCallback((params: ShareMapRequestParams): Promise<{ fieldErrors: FieldErrors; globalError: string | null }> => {
    if (params.mapId === null || params.clientId === null) {
      throw new Error('Cannot share map when map with mapId or clientId is not loaded.');
    }
    setIsLoading(true);

    return shareMap(params.clientId, params.mapId, {
      privacyLevel: params.privacyLevel === MapPrivacyLevel.Public ? 'public' : 'password_protected',
      password: params.privacyLevel === MapPrivacyLevel.Public ? null : params.password,
      useCurrentMapView: params.useCurrentMapView,
      nameForCurrentMapView: params.mapName ?? null,
      settingsOverride: params.useCurrentMapView && lastBounds ? getSnapshotSettingsOverrides(
        currentToolsState, lastBounds,
      ) : undefined,
    })
      .then(({ data: { share_id } }) => {
        setShareId(share_id);
        setGlobalError(null);
        setFieldErrors(new Map());

        return { fieldErrors: new Map(), globalError: null };
      })
      .catch(e => {
        const apiError = e as ApiError;
        const knownFieldErrors = new Map(Object.values(ShareInputField)
          .filter(field => apiError.errors?.hasOwnProperty(field))
          .map(field => [field, apiError.errors?.[field] ?? []] as const));

        if (isMountedRef.current) {
          setFieldErrors(knownFieldErrors);
        }
        if (knownFieldErrors.size === 0) {
          console.error('shareMap: got ApiError without any known fieldErrors, showing global error.');
          printUnknownFieldErrorsMessage(apiError);
          if (isMountedRef.current) {
            setGlobalError(apiError.message);
          }
        }

        return { fieldErrors: knownFieldErrors, globalError: apiError.message };
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [currentToolsState, getSnapshotSettingsOverrides, isMountedRef, lastBounds]);

  return {
    fieldErrors,
    globalError,
    isLoading,
    shareId,
    shareMap: submitShareRequest,
    clearGlobalError: () => setGlobalError(null),
    clearFieldError: (field: ShareInputField) => setFieldErrors(prev => copy.andRemove(prev, [field])),
  };
};
