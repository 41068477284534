import { always } from '~/_shared/utils/function.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { EMPTY_MESSAGE } from '~/map/settings/structure.constants';
import {
  type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from '../settingsAccordionData.type';
import { type OtherSettingsStructureProps } from './otherSettings.types';

export const otherSettingsStructure = (t: TranslationFnc, props: OtherSettingsStructureProps):
ReadonlyArray<SettingsAccordionSubItem> => {
  const { toggles, buttons } = props;

  return [{
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    infoTooltip: t('mapSettingsInfo.ShowUserLocationOnAdmin'),
    label: t('Show User Location on Admin'),
    type: SettingsAccordionSubItemType.Toggle,
    ...toggles.showLocationOnAdmin,
  }, {
    label: t('MI/KM Measurement'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.MiMkMeasurement'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...toggles.miMkMeasurement,
  }, {
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    infoTooltip: t('mapSettingsInfo.DisableActiveToolPanel'),
    label: t('Disable Active Tool Panel'),
    type: SettingsAccordionSubItemType.Toggle,
    ...toggles.disableActiveToolPanel,
  }, {
    type: SettingsAccordionSubItemType.Button,
    label: t('Customize Map Url'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    infoTooltip: t('mapSettingsInfo.CustomizeMapUrl'),
    textSelector: () => t('Open'),
    ...buttons.customizeMapUrl,
  }];
};
