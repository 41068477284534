import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesStorm: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#7D7D7D',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#353535',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#464646',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#2B2B2B',
      },
      weight: {
        isActive: true,
        value: 2,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#d1caca',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.landscape,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#c5bbba',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#c5bbba',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.landcover'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#5c5c5c',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#827C7C',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#202121',
      },
      weight: {
        isActive: true,
        value: 1,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#2B2B2B',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#454545',
      },
      weight: {
        isActive: true,
        value: 1,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#202121',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.arterial'],
    elementType: BaseMapElementTypeName.labels,
    stylers: {
      color: {
        isActive: true,
        value: '#ffffff',
      },
      visibility: BaseMapStylerVisibility.simplified,
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#202121',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName.labels,
    stylers: {
      visibility: BaseMapStylerVisibility.simplified,
      color: {
        isActive: true,
        value: '#fffdfd',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#333333',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#4D4D51',
      },
    },
  },
];
