import {
  createContext, type FC, type ReactNode, useCallback, useContext,
  useMemo, useState,
} from 'react';
import { createUuid } from '../createUuid';
import { noop } from '../function.helpers';
import {
  type KeyboardKeys, useKeyPress,
} from './useKeyPress';

export enum MapKeyPressPriority {
  Default = 0,
  CloseOpenElements = 1,
  ResetDrawing = 2,
  FinishDrawing = 3,
}

export type MapKeyPressCallback = (e: { readonly stopPropagation: () => void }) => void;

type RegisteredHandler = Readonly<{
  id: Uuid;
  key: KeyboardKeys;
  priority: MapKeyPressPriority;
  callback: MapKeyPressCallback;
}>;

type HandlerCleanUp = () => void;

type MapKeyPressContext = {
  addMapKeyHandler: (key: KeyboardKeys, priority: MapKeyPressPriority, handler: MapKeyPressCallback) => HandlerCleanUp;
};

const MapKeyPressContext = createContext<MapKeyPressContext>({
  addMapKeyHandler: () => noop,
});

export const MapKeyPressContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [handlers, setHandlers] = useState<ReadonlyArray<RegisteredHandler>>([]);

  const addMapKeyHandler = useCallback((key: KeyboardKeys, priority: MapKeyPressPriority, handler: MapKeyPressCallback): HandlerCleanUp => {
    const id = createUuid();

    setHandlers((prevHandlers) => [...prevHandlers, { id, key, priority, callback: handler }]);

    return () => {
      setHandlers((prevHandlers) => prevHandlers.filter((handler) => handler.id !== id));
    };
  }, [setHandlers]);

  const context = useMemo(() => ({ addMapKeyHandler }), [addMapKeyHandler]);

  // Sort handlers by priority in descending order
  const sortedHandlers = useMemo(() => [...handlers].sort((a, b) => b.priority - a.priority), [handlers]);
  const keys = useMemo(() => Array.from(new Set(sortedHandlers.map((handler) => handler.key))), [sortedHandlers]);

  const onKeyPress = useCallback((key: KeyboardKeys) => {
    let propagate = true;
    const stopPropagation = () => propagate = false;

    sortedHandlers
      .filter((handler) => handler.key === key)
      .forEach((handler) => {
        if (propagate) {
          handler.callback({ stopPropagation });
        }
      });
  }, [sortedHandlers]);

  useKeyPress({
    targetKeys: keys,
    options: { allowInputs: true },
    callbacks: { onKeyPress },
  });

  return (
    <MapKeyPressContext.Provider value={context}>
      {children}
    </MapKeyPressContext.Provider>
  );
};

export const useMapKeyPressContext = (): MapKeyPressContext => {
  const context = useContext(MapKeyPressContext);

  if (!context) {
    throw new Error(`${__filename}: useMapKeyPressContext cannot be used without the context provider.`);
  }

  return context;
};
