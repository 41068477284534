import {
  FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_EDITED_FILE_ID,
  FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_NEW_INSTANCE_FILE_ID,
} from './drawingImageSelector.actionTypes';

export const drawingToolImageSelectorSetEditedFileId = (imageFileId: number | null) => ({
  type: FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_EDITED_FILE_ID,
  payload: {
    imageFileId,
  },
}) as const;

export const drawingToolImageSelectorSetNewInstanceFileId = (imageFileId: number | null) => ({
  type: FRONTEND_STATE_DRAWING_IMAGE_SELECTOR_SET_NEW_INSTANCE_FILE_ID,
  payload: {
    imageFileId,
  },
}) as const;
