import {
  useEffect, useState,
} from 'react';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState<number>(document.documentElement.clientWidth);

  useEffect(() => {
    let ticking = false;

    const onWindowResize = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setWindowWidth(document.documentElement.clientWidth);
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return windowWidth;
};
