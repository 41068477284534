import {
  type MapInfo, type MapSnapshot, type ParentMap, type RealSpreadsheetVersions, type SpreadsheetDescriptor,
} from '~/_shared/types/map';
import {
  type GoogleSheetBasedSpreadsheetInfoResponse, type LayeringResponse, type MapInfoResponse, type MapSnapshotResponse,
  type SpreadsheetDescriptorResponse, type VersionsResponse,
} from '~/map/map.repository';
import { createBoundaryCreateOptimizedStatusFromResponse } from '../frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimizedStatus.factory';

export const createRealSpreadSheetFromMapRealSpreadSheetResponse = (realSpreadSheet: { real_spreadsheet_id: number }) => ({
  realSpreadSheetId: realSpreadSheet.real_spreadsheet_id,
});

export const createRealSpreadsheetVersionsFromMapResponse = (
  realSpreadsheetVersionResponses: VersionsResponse, layeringResponse: LayeringResponse
): ReadonlyArray<RealSpreadsheetVersions> => {
  if (!realSpreadsheetVersionResponses.data_changed) {
    return [];
  }

  return layeringResponse.base_maps.map((baseMap): RealSpreadsheetVersions => {
    const name = baseMap.name;
    if (baseMap.spreadsheets.length > 1 || (baseMap.spreadsheets[0] && baseMap.spreadsheets[0].real_spreadsheets.length > 1)) {
      throw Error('Unexpected to have more than 1 spreadsheet');
    }
    const realSpreadsheetId = baseMap.spreadsheets[0]?.real_spreadsheets[0]?.real_spreadsheet_id;
    const realSpreadsheetVersion = realSpreadsheetId && realSpreadsheetVersionResponses.real_spreadsheets[realSpreadsheetId];
    if (!realSpreadsheetVersion) {
      throw Error(`Cannot find version for real spreadsheet ${realSpreadsheetId}`);
    }
    const { versions: { current, latest } } = realSpreadsheetVersion;

    return {
      realSpreadSheetId: realSpreadsheetId,
      name,
      versions: {
        current: {
          version: current.version,
          snapshot: current.snapshot,
        },
        latest: {
          version: latest.version,
          snapshot: latest.snapshot,
        },
      },
    };
  });
};

export const createMapSpreadSheetFromMapSpreadSheetResponse = (
  spreadsheet: SpreadsheetDescriptorResponse
): SpreadsheetDescriptor => ({
  spreadSheetId: spreadsheet.spreadsheet_id,
  isEncrypted: spreadsheet.is_encrypted,
  isPrimary: spreadsheet.is_primary,
  privileges: spreadsheet.privileges,
  realSpreadsheets: spreadsheet.real_spreadsheets.map(realSpreadsheet => createRealSpreadSheetFromMapRealSpreadSheetResponse(realSpreadsheet)),
  geocoding: spreadsheet.geocoding,
});

export const createParentMapFromParentMapResponse = (data: MapInfoResponse): ParentMap => {
  const baseMap = createMapInfoFromMapInfoResponse(data);

  return {
    ...baseMap,
    parentMap: undefined,
    snapshots: data.snapshots?.map(view => createMapSnapshotFromResponse(view)) || [],
  };
};

export const createMapSnapshotFromResponse = (snapshot: MapSnapshotResponse): MapSnapshot => {
  const baseMap = createMapInfoFromMapInfoResponse({ ...snapshot, parent_map: undefined });

  return {
    ...baseMap,
    parentMap: snapshot.parent_map,
    snapshots: undefined,
  };
};

const getGoogleSheetsInfoFromMapInfoGoogleSheetsResponse = (mapInfoGoogleSheets: ReadonlyArray<GoogleSheetBasedSpreadsheetInfoResponse>) => {
  if (mapInfoGoogleSheets) {
    return mapInfoGoogleSheets.map((ssItem) => ({
      spreadsheetId: ssItem.spreadsheet_id,
      realSpreadsheets: ssItem.real_spreadsheets.map((rsItem) => ({
        autoSyncEnabled: rsItem.auto_sync_enabled,
        googleAccountId: rsItem.google_account_id,
        googleSheetId: rsItem.google_sheet_id,
        googleSpreadsheetId: rsItem.google_spreadsheet_id,
        lastSyncType: rsItem.last_sync_type,
        realSpreadsheetId: rsItem.real_spreadsheet_id,
        spreadsheetTitle: rsItem.spreadsheet_title,
      })),
    }));
  }
  return null;
};

export const createMapInfoFromMapInfoResponse = (
  data: MapInfoResponse
): MapInfo => ({
  created: data.created,
  description: data.description,
  encrypted: data.encrypted,
  googleSheets: getGoogleSheetsInfoFromMapInfoGoogleSheetsResponse(data.google_sheets),
  id: data.id,
  importing: data.importing,
  isLayered: data.is_layered,
  isMatchupRequired: data.is_matchup_required,
  layering: data.layering ? {
    baseMaps: data.layering.base_maps?.map(baseMap => ({
      id: baseMap.id,
      name: baseMap.name,
      spreadsheets: baseMap.spreadsheets?.map(s => ({
        realSpreadsheets: s.real_spreadsheets.map(rS => ({
          realSpreadsheetId: rS.real_spreadsheet_id,
        })),
      })),
      sharedUsers: baseMap.shared_users,
    })),
    columns: data.layering.columns,
    connected: data.layering.connected,
    sourceColumns: data.layering.source_columns,
    matches: data.layering.matches.map(match => ({ ...match, columnId: match.column_id })),
    realTime: data.layering.real_time,
  } : undefined,
  name: data.name,
  parentMap: !data.parent_map ? undefined : createParentMapFromParentMapResponse(data.parent_map),
  privacy: data.privacy,
  secureInProcess: data.secure_in_process,
  settings: data.settings,
  sharedUsers: data.shared_users,
  shareId: data.share_id,
  snapshots: data.snapshots?.map(snapshot => createMapSnapshotFromResponse(snapshot)),
  spreadsheets: data.spreadsheets.map((spreadsheet: SpreadsheetDescriptorResponse) => createMapSpreadSheetFromMapSpreadSheetResponse(spreadsheet)),
  team: data.team,
  territoryGeneration: (data.territories_generation ?? []).map(item => ({
    id: item.id,
    status: createBoundaryCreateOptimizedStatusFromResponse(item.status),
    startedOnCurrentTab: false,
  })),
  updated: data.updated,
  versions: (data.versions && data.layering) ? createRealSpreadsheetVersionsFromMapResponse(data.versions, data.layering) : undefined,
  views: data.views,
  v4MigrationDone: data.v4_migration_done,
  source: data.source ?? null,
});
