import { css } from '@emotion/react';
import {
  type FC, useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import { useTranslation } from '~/_shared/utils/hooks';
import { authorizationPagesLinkStyle } from '../authorizationPages.styles';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import { AuthorizationSubmitButtonComponent } from '../submitButton/authorizationSubmitButton.component';

const loaderStyle = css({
  margin: '0 auto',
});

const verifyTextStyle = css({
  textAlign: 'center',
});

const linksWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const alertStyle = css({
  marginTop: 10,
});

const submitButtonStyle = css({
  marginTop: 15,
});

type UnblockAccountComponentProps = Readonly<{
  isLoading: boolean;
  errorMessage: string;

  unblockAccount: () => Promise<void>;
}>;

export const UnblockAccountComponent: FC<UnblockAccountComponentProps> = (props) => {
  const { isLoading, errorMessage, unblockAccount } = props;
  const [isSuccess, setIsSuccess] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const goToLoginPage = useCallback(() => navigate(LOGIN_ROUTE), [navigate]);

  useEffect(() => {
    unblockAccount()
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      });
  }, [unblockAccount]);

  return (
    <AuthorizationPageWrapperComponent>
      {isSuccess ? (
        <div>
          <AlertComponent
            css={alertStyle}
            type="success"
          >
            {t('UnblockAccount.success')}
          </AlertComponent>

          <AuthorizationSubmitButtonComponent
            css={submitButtonStyle}
            isLoading={false}
            isDisabled={false}
            onClick={goToLoginPage}
          >
            {t('Continue to Login')}
          </AuthorizationSubmitButtonComponent>
        </div>
      ) : (
        <div>

          {!isSuccess && errorMessage && (
            <AlertComponent
              css={alertStyle}
              type="danger"
            >
              {errorMessage}
            </AlertComponent>
          )}

          {isLoading && (
            <div>
              <LoaderComponent
                css={loaderStyle}
                size={40}
              />
              <p css={verifyTextStyle}>{t('UnblockAccount.inProgress')}</p>
            </div>
          )}

          {!isLoading && (
            <div css={linksWrapperStyle}>
              <LinkComponent
                css={authorizationPagesLinkStyle}
                path={LOGIN_ROUTE}
              >
                {t('Back to Login')}
              </LinkComponent>
            </div>
          )}
        </div>
      )}
    </AuthorizationPageWrapperComponent>
  );
};
