import { type BoundaryItemsAction } from '~/store/boundaryItems/boundaryItems.action';
import {
  BOUNDARY_ITEMS_FETCH_REQUEST,
  BOUNDARY_ITEMS_FETCH_SUCCESS,
} from '~/store/boundaryItems/boundaryItems.actionTypes';
import { type MapAction } from '~/store/map/map.action';
import { MAP_RESET } from '~/store/map/map.actionTypes';
import { type BoundaryRenderingAction } from './boundaryRendering.action';
import {
  BOUNDARY_RENDERING_EXPECTED_RESET,
  BOUNDARY_RENDERING_START,
  BOUNDARY_RENDERING_STOP,
  BOUNDARY_SELECT_RENDERING_START,
  BOUNDARY_SELECT_RENDERING_STOP,
} from './boundaryRendering.actionTypes';
import { type BoundaryRenderingState } from './boundaryRendering.state';

const initialState: BoundaryRenderingState = {
  renderingBoundaries: false,
  renderingSelectBoundaries: false,
  isRenderingExpected: false,
};

type Action = BoundaryRenderingAction | BoundaryItemsAction | MapAction;

export const boundaryRenderingReducer = (state: BoundaryRenderingState = initialState, action: Action): BoundaryRenderingState => {
  switch (action.type) {
    case BOUNDARY_RENDERING_START: {
      return {
        ...state,
        renderingBoundaries: true,
      };
    }

    case BOUNDARY_RENDERING_STOP: {
      return {
        ...state,
        renderingBoundaries: false,
      };
    }

    case BOUNDARY_SELECT_RENDERING_START: {
      return {
        ...state,
        renderingSelectBoundaries: true,
      };
    }

    case BOUNDARY_SELECT_RENDERING_STOP: {
      return {
        ...state,
        renderingSelectBoundaries: false,
      };
    }

    case BOUNDARY_ITEMS_FETCH_SUCCESS: {
      return {
        ...state,
        isRenderingExpected: action.payload.isRenderingExpected,
      };
    }

    case BOUNDARY_RENDERING_EXPECTED_RESET:
    case BOUNDARY_ITEMS_FETCH_REQUEST: {
      return {
        ...state,
        isRenderingExpected: false,
      };
    }

    case MAP_RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
