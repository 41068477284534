import { type ReactElement } from 'react';
import { useTranslation } from '../../../_shared/utils/hooks';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { ModalType } from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';

export const useMembersErrorModal = () => {
  const [t] = useTranslation();
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);

  return { openErrorModal: (error: string | ReactElement | ReactElement[]) => openAppErrorModal({
    type: AppErrorType.General,
    title: t('There Was An Error Managing Your Team Member'),
    content: error,
  }) };
};
