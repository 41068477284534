import { type CreateCustomBoundaryMode } from '../../../../../sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createCustomBoundaryGroupPane.component';
import { type BoundaryActionType } from '../../../../../sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/loadBoundaryPane.component';
import {
  BOUNDARY_FORM_RESET, BOUNDARY_FORM_SET_TYPE_AND_MODE,
} from './boundaryForm.actionTypes';

export const boundaryFormReset = () => ({
  type: BOUNDARY_FORM_RESET,
}) as const;

export const boundaryFormSetType = (type: BoundaryActionType | null) => ({
  type: BOUNDARY_FORM_SET_TYPE_AND_MODE,
  payload: {
    type,
    mode: null,
  },
}) as const;

export const boundaryFormSetTypeAndMode = (type: BoundaryActionType | null, mode: CreateCustomBoundaryMode | null) => ({
  type: BOUNDARY_FORM_SET_TYPE_AND_MODE,
  payload: {
    type,
    mode,
  },
}) as const;
