import {
  type FC, useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { AppErrorType } from '~/appError/appErrorType.enum';
import {
  BoundaryEditAction,
  BoundaryEditManualMethod,
} from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import {
  boundaryTerritoryGroupCreateSettingsStyleDefaults, defaultBoundaryTerritoryGroupSettings,
} from '~/boundary/settings/defaultBoundarySettings';
import { useIsNumericColumnCheck } from '~/heatMap/panel/form/useIsNumericColumnCheck.hook';
import {
  boundaryGroupsCreateRequest, convertBoundaryTerritoriesToBoundaries,
} from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import {
  useCustomBoundaryGroupsSelector, useGetBoundaryGroup, useGlobalBoundaryGroups,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import {
  BoundaryTerritoryType, type CalculateBucketFunctionNumeric,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { createBoundaryTerritoryStyleFromResponse } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { BoundaryMatchingType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupModel,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { boundaryDrawEditOpenModal } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { boundaryFormSetTypeAndMode } from '~/store/frontendState/mapTools/boundary/boundaryForm/boundaryForm.actionCreators';
import { useBoundaryFormMode } from '~/store/frontendState/mapTools/boundary/boundaryForm/boundaryForm.selectors';
import { boundarySelectEditCustomBoundaryOpenModal } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { translate } from '~/translations/Trans';
import {
  getBoundaryBucketTypeForBoundaryTerritoryType, getCalculateBucketFunction,
} from '../../boundaryTerritoryGroup.helpers';
import { useVerifyNumericFillData } from '../../fill/useVerifyNumericFillData';
import { BoundaryActionType } from '../loadBoundaryPane.component';
import {
  CreateCustomBoundaryGroupPaneComponent,
  type CreateCustomBoundaryGroupResults,
  CreateCustomBoundaryMode,
} from './createCustomBoundaryGroupPane.component';

type CreateCustomBoundaryGroupPaneContainer = Readonly<{
  resetForm: () => void;
}>;

export const CreateCustomBoundaryGroupPaneContainer: FC<CreateCustomBoundaryGroupPaneContainer> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const globalBoundaryGroups = useGlobalBoundaryGroups();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const { checkIsNumeric, isLoading: isCheckNumericLoading } = useIsNumericColumnCheck();
  const { verifyNumericFillData } = useVerifyNumericFillData({ checkIsNumeric });
  const mode = useBoundaryFormMode();
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  const setMode = useCallback((mode: CreateCustomBoundaryMode) => dispatch(boundaryFormSetTypeAndMode(BoundaryActionType.CreateTerritories, mode)), [dispatch]);

  const createBoundaryTerritoryGroupFromData = async (
    selectedBoundaryGroupId: number,
    groupName: string,
    boundaryTerritoryType: BoundaryTerritoryType,
    boundaryFillData: SpreadsheetColumnId | null,
    demographicId: string | null,
    numericCalculateBucketFunction: CalculateBucketFunctionNumeric,
  ) => {
    if (boundaryTerritoryType === BoundaryTerritoryType.Manual || clientId === null || mapId === null) {
      return;
    }

    if (boundaryTerritoryType === BoundaryTerritoryType.Numeric) {
      if (!await verifyNumericFillData(boundaryFillData)) {
        return;
      }
    }

    try {
      setIsLoading(true);
      const newBoundaryTerritoryGroup: BoundaryTerritoryGroupModel = {
        boundaryGroupId: selectedBoundaryGroupId,
        archivedAt: null,
        mapId,
        matchings: {
          matchingType: BoundaryMatchingType.Geometry,
          matchColumns: [],
        },
        settings: {
          ...defaultBoundaryTerritoryGroupSettings,
          boundaryTerritoryType,
          calculateBucketFunction: getCalculateBucketFunction(
            boundaryTerritoryType,
            numericCalculateBucketFunction,
          ),
          bucketColumnId: boundaryFillData?.columnId ?? null,
          bucketType: getBoundaryBucketTypeForBoundaryTerritoryType(boundaryTerritoryType),
          demographicId,
          style: createBoundaryTerritoryStyleFromResponse(boundaryTerritoryGroupCreateSettingsStyleDefaults),
        },
      };

      dispatch(convertBoundaryTerritoriesToBoundaries(newBoundaryTerritoryGroup, groupName, false));
      props.resetForm();
    }
    catch (e) {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: translate('Error creating new territory'),
      }));
    }
    setIsLoading(false);
  };

  const onSubmit = (results: CreateCustomBoundaryGroupResults) => {
    if (isTextEmpty(results.groupName) || clientId === null || mapId === null) {
      return;
    }

    if (results.type === CreateCustomBoundaryMode.Manual) {
      dispatch(boundaryGroupsCreateRequest(results.groupName, (boundaryTerritoryGroup: BoundaryTerritoryGroup) => {
        if (results.manualMethod === BoundaryEditManualMethod.Selection) {
          const selectedBoundaryGroup = getBoundaryGroupById(results.selectedBoundaryGroupId);
          if (!selectedBoundaryGroup) {
            return;
          }

          dispatch(boundarySelectEditCustomBoundaryOpenModal({
            actionType: BoundaryEditAction.CreateNewBoundary,
            boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
            selectBoundaryGroupId: selectedBoundaryGroup?.editBoundaryGroupFallback ?? selectedBoundaryGroup?.id,
          }));
        }
        else {
          dispatch(boundaryDrawEditOpenModal({
            action: BoundaryEditAction.CreateNewBoundary,
            boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId,
            boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId,
          }));
        }

        props.resetForm();
      }));
    }
    else if (results.type === CreateCustomBoundaryMode.FromData) {
      const selectedBoundaryGroup = getBoundaryGroupById(results.selectedBoundaryGroupId);
      if (!selectedBoundaryGroup) {
        return;
      }

      createBoundaryTerritoryGroupFromData(
        selectedBoundaryGroup.editBoundaryGroupFallback ?? selectedBoundaryGroup.id,
        results.groupName,
        results.boundaryTerritoryType,
        results.boundaryFillData,
        results.demographicId,
        results.numericCalculateBucketFunction
      );
    }
  };

  return (
    <CreateCustomBoundaryGroupPaneComponent
      key={customBoundaryGroups.length}
      isLoading={isLoading || isCheckNumericLoading}
      labelCountStart={2}
      onSubmit={onSubmit}
      customBoundaryGroups={customBoundaryGroups}
      globalBoundaryGroups={globalBoundaryGroups}
      mode={mode}
      setMode={setMode}
    />
  );
};
