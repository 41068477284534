import {
  css, type Interpolation,
} from '@emotion/react';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import {
  type CSSProperties,
  forwardRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type Theme } from '../../themes/theme.model';

export type ImageProps = Readonly<{
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  notFoundImageStyle?: Interpolation<Theme>;
  foundImageStyle?: Interpolation<Theme>;
  commonStyle?: Interpolation<Theme>;
}>;

const imageStyles = (theme: Theme) => css({
  objectFit: 'contain',
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: 4,
});

const fallbackStyles = (theme: Theme) => css(imageStyles(theme), {
  display: 'flex',
  fontSize: '24px',
  lineHeight: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.iconColors.lowContrast,
});

export const ImageComponent = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const [showFallback, setShowFallback] = useState(false);
  const { width, height, style } = props;

  return !showFallback
    ? (
      <img
        css={[imageStyles, props.foundImageStyle, props.commonStyle]}
        style={{ ...style, width, height }}
        ref={ref}
        className={props.className}
        src={props.src}
        alt={props.alt}
        onError={() => setShowFallback(true)}
        onClick={props.onClick}
        onMouseDown={e => e.preventDefault()} // because react-dnd touch backend doesn't use native browser dnd event's, default behaviour would be to select elements while mouse is down and moving
        draggable="false" // because react-dnd touch backend doesn't use native browser event's, draggable elements would have browser-generate preview which we don't want
      />
    )
    : (
      <div
        css={[fallbackStyles, props.notFoundImageStyle, props.commonStyle]}
        style={{ ...style, width, height }}
        ref={ref}
        className={props.className}
        onClick={props.onClick}
        onMouseDown={e => e.preventDefault()}
        draggable="false"
      >
        <FontAwesomeIcon icon={faImage} />
      </div>
    );
});

ImageComponent.displayName = 'ImageComponent';
