import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { mapInfoFetchDataRequest } from '~/store/mapInfo/mapInfo.actionCreators';
import {
  useMapInfoDataSelector, useMapInfoPrivacySelector,
} from '~/store/mapInfo/mapInfo.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useMapDataUpdate } from '../useMapDataUpdate';
import { CustomizeMapUrlComponent } from './customizeMapUrl.component';

type CustomizeMapUrlContainerProps = ModalProps;

export const CustomizeMapUrlContainer: FC<CustomizeMapUrlContainerProps> = (props) => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const mapInfoData = useMapInfoDataSelector();
  const mapPrivacyInfo = useMapInfoPrivacySelector();

  const { replaceCurrentModal } = useModal(ModalType.ShareMap);
  const { isLoading, clearErrorMessage, errorMessage, updateMapData } = useMapDataUpdate();

  const onUpdateMapDataRequest = (newUrl: string, onSuccess?: () => void) => {
    if (!clientId || !mapId) {
      return;
    }

    updateMapData({
      clientId,
      mapId,
      request: {
        custom_map_url: newUrl,
      },
    })
      .then(() => {
        onSuccess?.();
        dispatch(mapInfoFetchDataRequest(clientId, mapId));
        props.onClose();
      });
  };

  const onSaveClick = (newUrl: string) => {
    onUpdateMapDataRequest(newUrl);
  };

  const onSaveAndShareClick = (newUrl: string) => {
    onUpdateMapDataRequest(newUrl, () => {
      if (!clientId || !mapId || !mapInfoData?.name) {
        return;
      }

      replaceCurrentModal({
        isSnapshot: !!mapInfoData.parentMap,
        mapId,
        mapName: mapInfoData?.name,
        parentMapPrivacy: mapPrivacyInfo?.parentMapPrivacy || null,
        mapSource: mapInfoData.source,
      });

      dispatch(mapInfoFetchDataRequest(clientId, mapId));
    });
  };

  return (
    <CustomizeMapUrlComponent
      {...props}
      currentMapUrl={mapInfoData?.shareId ?? ''}
      isLoading={isLoading}
      onErrorMessageClose={clearErrorMessage}
      errorMessage={errorMessage}
      onSaveClick={onSaveClick}
      onSaveAndShareClick={onSaveAndShareClick}
    />
  );
};
