import { css } from '@emotion/react';
import {
  faSearch, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useMemo,
} from 'react';
import { RoundButtonSize } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButtonWithText.component';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import {
  InputSize, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import { delay } from '~/_shared/utils/delay';
import { autoCompleteDisabled } from '~/_shared/utils/dom/dom.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { LottieAnimationComponent } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { getLottieAnimationDuration } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '../../../_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { useElementDimensions } from '../../../_shared/utils/hooks/useElementDimensions';
import {
  getSortOptions, type MapListSortType,
} from '../mapListing.helpers';

const MQ_BREAKPOINT_SM = 600;
const MQ_BREAKPOINT_MD = 800;

const actionHeaderWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const searchWrapperStyle = css({
  position: 'relative',
  flex: 1,
});

const sortDropdownWrapperStyle = ({ width = 0 }: { width?: number }) => css({
  width: 280,
  marginLeft: 16,
  ...(width >= MQ_BREAKPOINT_MD ? {
    width: 315,
  } : {}),
});

const deleteButtonWrapperStyle = ({ width }: { width: number }) => css({
  marginRight: 16,
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  ...(width >= MQ_BREAKPOINT_MD ? {
    minWidth: 175,
  } : {}),
});

const sortDropdownContainerStyle = css({
  '&:before': {
    display: 'none',
  },
});

const deleteAnimationStyle = css({
  marginLeft: 8,
});

export enum MapListingDeleteStatus {
  Disabled = 'Disabled',
  Active = 'Active',
  Loading = 'Loading',
  Success = 'Success',
}

type ActionsHeaderProps = Readonly<{
  deleteStatus: MapListingDeleteStatus;
  searchQuery: string;
  sortType: MapListSortType;

  onDeleteClick: () => void;
  onDeleteSuccessAnimationFinish: () => void;
  onSearchQueryChange: (query: string) => void;
  setSortType: (sortType: MapListSortType) => void;
}>;

export const ActionsHeaderComponent: FC<ActionsHeaderProps> = (props) => {
  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors();
  const { ref, width } = useElementDimensions();

  const sortOptions: DropdownOption<MapListSortType>[] = useMemo(() => getSortOptions(t), [t]);

  useEffect(() => {
    if (props.deleteStatus !== MapListingDeleteStatus.Success) {
      return;
    }

    const later = delay(getLottieAnimationDuration(LottieAnimationTypes.Delete) + 1000)
      .then(props.onDeleteSuccessAnimationFinish);

    return () => {
      later.cancel();
    };
  }, [props.deleteStatus, props.onDeleteSuccessAnimationFinish]);

  let deleteLabel: string;
  switch (props.deleteStatus) {
    case MapListingDeleteStatus.Loading:
      deleteLabel = `${t('Deleting')}...`;
      break;
    case MapListingDeleteStatus.Success:
      deleteLabel = t('Deleted');
      break;
    default: {
      deleteLabel = t('Delete Selected');
    }
  }

  return (
    <div
      css={actionHeaderWrapperStyle}
      ref={ref}
    >
      <div css={deleteButtonWrapperStyle({ width: width ?? 0 })}>
        <RoundButtonWithTextComponent
          buttonStyle={RoundButtonStyle.DangerInverted}
          icon={faTrash}
          isDisabled={props.deleteStatus !== MapListingDeleteStatus.Active}
          isTextHidden={(width ?? 0) < MQ_BREAKPOINT_SM}
          onClick={props.onDeleteClick}
          size={RoundButtonSize.Large}
          text={deleteLabel}
        />

        {props.deleteStatus === MapListingDeleteStatus.Success && (
          <LottieAnimationComponent
            css={deleteAnimationStyle}
            size={40}
            type={LottieAnimationTypes.Delete}
            colors={animationColors.Delete}
            autoplay
          />
        )}
      </div>

      <div css={searchWrapperStyle}>
        <TextInputComponent
          type="text"
          role="searchbox"
          autoComplete={autoCompleteDisabled}
          value={props.searchQuery}
          placeholder={t('Search...')}
          onChange={props.onSearchQueryChange}
          icon={faSearch}
          size={InputSize.Large}
        />
      </div>

      <div css={sortDropdownWrapperStyle({ width })}>
        <RegularDropdownComponent
          value={props.sortType}
          onChange={props.setSortType}
          options={sortOptions}
          inputSize={InputSize.Large}
          css={sortDropdownContainerStyle}
        />
      </div>
    </div>
  );
};
