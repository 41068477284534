import {
  type FC, memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import {
  ghostOutlineHoverVisuals,
  ghostOutlineVisuals,
  outlineHoverVisuals,
  outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { areLatLngPartiallyEqual } from '~/_shared/utils/latLng/latLng.helpers';
import { getDraggableCursorForOutlinePosition } from '~/_shared/utils/mapObjectOutline/outline.helpers';
import { incrementLatLng } from '~/_shared/utils/webgl/mapVertices.helpers';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditPushNewSnapshot } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered,
  drawingToolItemHoverStopped,
  drawingToolItemMoveStarted,
  drawingToolItemMoveStopped, drawingToolItemResizeStarted, drawingToolItemResizeStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemRectangle } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useMap } from '../../mapContext';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  getResizeControllerOutlinesForRectangleStartEndPoints, isRectangleOutlinePositionPrimary,
  type RectangleStartEndPoints, resetRectangleStartEndPoint,
} from './drawingToolRectangle.helpers';
import { DrawingToolRectangleInstanceRectangleContainer } from './drawingToolRectangleInstanceRectangle.container';

type MapRectangleSelectedInstanceProps = {
  instance: DrawingItemRectangle;
};

const DrawingToolRectangleSelectedInstanceContainer: FC<MapRectangleSelectedInstanceProps> = ({
  instance,
}) => {
  const [isResizeDragging, setIsResizeDragging] = useState(false);
  const [isPolygonDragging, setIsPolygonDragging] = useState(false);
  const [startEndPoint, setStartEndPoint] = useState<RectangleStartEndPoints>({
    startPoint: instance.startPoint,
    endPoint: instance.endPoint,
  });
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const map = useMap();
  const { fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom } = useProjectionOverlay(map);
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();

  const dragStartDataRef = useRef<{ startDragPosition: LatLng; initialStartEndPoint: RectangleStartEndPoints } | null>(null);
  const resizeControllerDragStartDataRef = useRef<{ initialStartEndPoint: RectangleStartEndPoints } | null>(null);

  const onMapShapeChange = useCallback((startPoint: LatLng, endPoint: LatLng) => {
    let uniqueEndpoint = endPoint;
    if (areLatLngPartiallyEqual(uniqueEndpoint, startPoint)) {
      uniqueEndpoint = incrementLatLng(uniqueEndpoint);
    }
    setStartEndPoint({
      startPoint,
      endPoint: uniqueEndpoint,
    });

    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Rectangle,
      value: {
        ...instance,
        startPoint,
        endPoint: uniqueEndpoint,
      },
    }));
  }, [dispatch, instance]);

  const resizeControllerOutlines = useMemo<MapOutlinePositionInstance[]>(() => {
    return getResizeControllerOutlinesForRectangleStartEndPoints(startEndPoint.startPoint, startEndPoint.endPoint, instance.id);
  }, [instance.id, startEndPoint]);

  const onResizeControllerDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      onMapShapeChange(startEndPoint.startPoint, startEndPoint.endPoint);
      setIsResizeDragging(false);
      dispatch(drawingToolItemResizeStopped());
    }
  }, [dispatch, startEndPoint, drawingEventsEnabledRef, onMapShapeChange]);

  const onResizeControllerDragStart = useCallback((_outlineId: string, position: MapOutlinePosition) => {
    if (drawingEventsEnabledRef.current) {
      setHoveredOutlineId(null);
      resizeControllerDragStartDataRef.current = { initialStartEndPoint: startEndPoint };
      setIsResizeDragging(true);
      dispatch(drawingToolItemResizeStarted(
        getDraggableCursorForOutlinePosition(position))
      );
    }
  }, [dispatch, startEndPoint, drawingEventsEnabledRef]);

  const onResizeControllerMouseOver = useCallback((outlineId: string) => {
    dispatch(drawingToolItemHovered());
    setHoveredOutlineId(outlineId);
  }, [dispatch]);

  const onResizeControllerMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
    setHoveredOutlineId(null);
  }, [dispatch]);

  const onResizeControllerDragMove = useCallback((
    _outlineId: string, position: MapOutlinePosition, latLng: LatLng
  ) => {
    if (!resizeControllerDragStartDataRef.current || !drawingEventsEnabledRef.current) {
      return;
    }

    const { startPoint: newStartPoint, endPoint: newEndPoint } = resetRectangleStartEndPoint(
      resizeControllerDragStartDataRef.current.initialStartEndPoint.startPoint,
      resizeControllerDragStartDataRef.current.initialStartEndPoint.endPoint
    );

    switch (position) {
      case MapOutlinePosition.Right:
        newEndPoint.lng = latLng.lng;
        break;
      case MapOutlinePosition.Left:
        newStartPoint.lng = latLng.lng;
        break;
      case MapOutlinePosition.Top:
        newStartPoint.lat = latLng.lat;
        break;
      case MapOutlinePosition.Bottom:
        newEndPoint.lat = latLng.lat;
        break;
      case MapOutlinePosition.BottomLeft:
        newStartPoint.lng = latLng.lng;
        newEndPoint.lat = latLng.lat;
        break;
      case MapOutlinePosition.BottomRight:
        newEndPoint.lng = latLng.lng;
        newEndPoint.lat = latLng.lat;
        break;
      case MapOutlinePosition.TopLeft:
        newStartPoint.lat = latLng.lat;
        newStartPoint.lng = latLng.lng;
        break;
      case MapOutlinePosition.TopRight:
        newStartPoint.lat = latLng.lat;
        newEndPoint.lng = latLng.lng;
        break;
      default:
        return;
    }

    setStartEndPoint({
      startPoint: newStartPoint,
      endPoint: newEndPoint,
    });
  }, [drawingEventsEnabledRef]);

  const renderResizeController = useCallback((id: string, resizeController: MapObjectOutlineInstance) => {
    const outline = resizeControllerOutlines.find(item => item.id === id);

    if (!outline) {
      return null;
    }

    const visuals = isRectangleOutlinePositionPrimary(outline.position)
      ? (hoveredOutlineId === id ? outlineHoverVisuals : outlineVisuals)
      : (hoveredOutlineId === id ? ghostOutlineHoverVisuals : ghostOutlineVisuals);

    return (
      <MapOutline
        key={id}
        outline={resizeController}
        visuals={visuals}
        onDragMove={(outlineId, latLng) => onResizeControllerDragMove(outlineId, outline.position, latLng)}
        onDragEnd={onResizeControllerDragEnd}
        onDragStart={(outlineId) => onResizeControllerDragStart(outlineId, outline.position)}
        onMouseOver={onResizeControllerMouseOver}
        onMouseOut={onResizeControllerMouseOut}
      />
    );
  }, [resizeControllerOutlines, hoveredOutlineId, onResizeControllerDragEnd, onResizeControllerMouseOver,
    onResizeControllerMouseOut, onResizeControllerDragMove, onResizeControllerDragStart]);

  const onRectangleDragStart = useCallback((position: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      dragStartDataRef.current = { startDragPosition: position, initialStartEndPoint: startEndPoint };
      setIsPolygonDragging(true);
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef, startEndPoint]);

  const onRectangleDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      onMapShapeChange(startEndPoint.startPoint, startEndPoint.endPoint);

      setIsPolygonDragging(false);
      dispatch(drawingToolItemMoveStopped());
    }
  }, [dispatch, startEndPoint, drawingEventsEnabledRef, onMapShapeChange]);

  const onRectangleDragMove = useCallback((position: LatLng) => {
    if (!dragStartDataRef.current) {
      return;
    }

    const { startDragPosition, initialStartEndPoint } = dragStartDataRef.current;

    const offsetStartPoint = fromLatLngToDivPixel(startDragPosition);
    const offsetEndPoint = fromLatLngToDivPixel(position);

    if (!offsetStartPoint || !offsetEndPoint) {
      return;
    }

    const offsetPoint = new google.maps.Point(
      offsetEndPoint.x - offsetStartPoint.x,
      offsetEndPoint.y - offsetStartPoint.y
    );

    if (!offsetPoint) {
      return;
    }

    const newStartPoint = moveLatLngByPixelsOnCurrentZoom(initialStartEndPoint.startPoint, offsetPoint);
    const newEndPoint = moveLatLngByPixelsOnCurrentZoom(initialStartEndPoint.endPoint, offsetPoint);

    if (!newStartPoint || !newEndPoint) {
      return;
    }

    setStartEndPoint({
      startPoint: newStartPoint,
      endPoint: newEndPoint,
    });
  }, [fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom]);

  const onRectangleMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onRectangleMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  useEffect(() => {
    setStartEndPoint({
      startPoint: instance.startPoint,
      endPoint: instance.endPoint,
    });
  }, [instance.startPoint, instance.endPoint]);

  return (
    <>
      <DrawingToolRectangleInstanceRectangleContainer
        renderOnlyLine={isResizeDragging}
        instance={{
          id: instance.id,
          startPoint: startEndPoint.startPoint,
          endPoint: startEndPoint.endPoint,
          placement: instance.placement,
          settings: instance.settings,
        }}
        zIndex={zIndex}
        onRectangleClick={(e) => {
          if (!drawingEventsEnabledRef.current) {
            return;
          }

          e.stopPropagation();
        }}
        onRectangleMouseOut={onRectangleMouseOut}
        onRectangleMouseOver={onRectangleMouseOver}
        onRectangleDragStart={onRectangleDragStart}
        onRectangleDragEnd={onRectangleDragEnd}
        onRectangleDragMove={onRectangleDragMove}
      />

      {!isPolygonDragging && (
        <MapShape
          shape={{
            id: instance.id,
            outlines: resizeControllerOutlines,
          }}
          zIndex={zIndex}
          renderOutline={renderResizeController}
          isPolygon
        />
      )}
    </>
  );
};

const pureComponent = memo(DrawingToolRectangleSelectedInstanceContainer);
export { pureComponent as DrawingToolRectangleSelectedInstanceContainer };
