import { createNanoEvents } from 'nanoevents';
import { delay } from '~/_shared/utils/delay';
import { type CancellablePromise } from '../types/common.type';

export const eventsEmitter = createNanoEvents();

const DOUBLE_CLICK_GAP_IN_MS = 300;
export const ignoreDoubleClicks = <TEvent>(handler: (event: TEvent & { detail: number }) => void) => {
  let delayedClick: CancellablePromise<void> | null = null;

  return (event: TEvent & { detail: number }) => {
    if (delayedClick) {
      delayedClick.cancel();
      delayedClick = null;
    }

    if (event.detail < 2) {
      delayedClick = delay(DOUBLE_CLICK_GAP_IN_MS).then(() => {
        delayedClick = null;
        handler(event);
      });
    }
  };
};
