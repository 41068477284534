import {
  getLast, notEmpty,
} from '~/_shared/utils/array/array.helpers';
import { createUuid } from '../../../../_shared/utils/createUuid';
import {
  DirectionSourceType, type Waypoint,
} from './directions.state';

export const createEmptyWaypoint = () => ({ address: '', latLng: null, id: createUuid() });

const isEmptyWaypoint = (waypoint: Waypoint) => waypoint.address === '' && waypoint.latLng === null;

export const generateNewWaypoints = (
  initialWaypoints: ReadonlyArray<Waypoint>, addingWaypoints: ReadonlyArray<Waypoint>, keepFirstWaypointEmpty: boolean,
): Waypoint[] => {
  let newWaypoints = initialWaypoints.filter((w, i) => !isEmptyWaypoint(w) || (i === 0 && keepFirstWaypointEmpty));

  newWaypoints = newWaypoints.concat(addingWaypoints);

  if (newWaypoints.length < 2) {
    for (let i = 0; i < 2 - newWaypoints.length; i++) {
      newWaypoints.push(createEmptyWaypoint());
    }
  }

  return newWaypoints;
};

export const getWaypointsAccordingToDirectionType = (data: {
  directionType?: DirectionSourceType;
  newWaypoint: Waypoint;
  startsFromUserLocation: boolean;
  waypoints: ReadonlyArray<Waypoint>;
}) => {
  const { newWaypoint, waypoints, startsFromUserLocation, directionType } = data;

  switch (directionType) {
    case DirectionSourceType.From: {
      if (notEmpty(waypoints) && isEmptyWaypoint(waypoints[0])) {
        return [newWaypoint, ...waypoints.slice(1)];
      }
      else {
        return [newWaypoint, ...waypoints];
      }
    }

    case DirectionSourceType.To: {
      const lastWaypoint = getLast(waypoints);

      if (lastWaypoint && isEmptyWaypoint(lastWaypoint)) {
        return [...waypoints.slice(0, -1), newWaypoint];
      }
      else {
        return [...waypoints, newWaypoint];
      }
    }

    default: {
      const firstEmptyWaypoint: Waypoint | undefined = (startsFromUserLocation ? waypoints.slice(1) : waypoints)
        .filter(isEmptyWaypoint)[0];

      if (firstEmptyWaypoint) {
        return waypoints.map(w => w === firstEmptyWaypoint ? newWaypoint : w);
      }
      else {
        return [...waypoints, newWaypoint];
      }
    }
  }
};
