import { css } from '@emotion/react';
import {
  type FC, useCallback, useState,
} from 'react';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { YOUTUBE_VIDEO_ROUTE } from '~/_shared/constants/routes';

const frameStyle = css({
  border: 0, //to ensure consistent rendering across all browsers
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
});
const wrapperStyle = css({
  borderRadius: 4,
  height: 0,
  maxWidth: '100%',
  overflow: 'hidden',
  paddingBottom: '56.25%', //aspect ratio of 16:9
  position: 'relative',
});

type EmbeddedYouTubeVideoProps = Readonly<{
  title: string;
  videoId: string;
}>;

export const EmbeddedYouTubeVideoComponent: FC<EmbeddedYouTubeVideoProps> = props => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = useCallback(() =>
    setIsLoading(false),
  []);

  return (
    <div css={wrapperStyle}>
      <iframe
        onLoad={handleLoad}
        allow="encrypted-media; picture-in-picture"
        allowFullScreen
        css={frameStyle}
        src={`${YOUTUBE_VIDEO_ROUTE}/${props.videoId}`}
        title={props.title}
      />
      {isLoading && <OverlayLoaderComponent />}
    </div>
  );
};
