import {
  buffers, type TakeableChannel,
} from 'redux-saga';
import {
  actionChannel,
  put, take, takeLatest,
} from 'redux-saga/effects';
import { type RouteCollapsingLevel } from '~/_shared/constants/routeCollapsingLevel.enum';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { MAP_RESET } from '~/store/map/map.actionTypes';
import { MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS } from '../../../mapSettings/directions/mapSettingsDirections.actionTypes';
import { mapSettingsDirectionsSelectedCollapsingSelector } from '../../../mapSettings/directions/mapSettingsDirections.selectors';
import { type MapSettingsReadyAction } from '../../../mapSettings/ready/mapSettingsReady.action';
import { MAP_SETTINGS_READY_SET } from '../../../mapSettings/ready/mapSettingsReady.actionTypes';
import { frontendStateDirectionsCollapsingChanged } from './directions.actionCreators';

let lastCollapsingLevel: RouteCollapsingLevel | null = null;

export function* directionsSaga() {
  yield takeLatest([MAP_RESET], onMapReset);
  yield takeLatest(MAP_SETTINGS_READY_SET, watchDirectionSettingsChange);
}

function onMapReset() {
  lastCollapsingLevel = null;
}

function* watchDirectionSettingsChange(action: PickAction<MapSettingsReadyAction, typeof MAP_SETTINGS_READY_SET>) {
  if (!action.payload.isReady) {
    return;
  }

  lastCollapsingLevel = yield select(mapSettingsDirectionsSelectedCollapsingSelector);

  const updateDirectionSettingsActionBuffer: TakeableChannel<Action> = yield actionChannel(
    [MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS], buffers.sliding(1));

  while (true) {
    yield take(updateDirectionSettingsActionBuffer);
    const newCollapsingLevel: RouteCollapsingLevel = yield select(mapSettingsDirectionsSelectedCollapsingSelector);

    if (lastCollapsingLevel !== newCollapsingLevel) {
      yield put(frontendStateDirectionsCollapsingChanged(newCollapsingLevel));
      lastCollapsingLevel = newCollapsingLevel;
    }
  }
}
