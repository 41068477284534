import { type MapSettingsStylesChangeData } from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName,
} from '../baseMap.enums';

export const baseMapThemesNight: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#242f3e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#746855',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#242f3e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.locality'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#d59563',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#d59563',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#263c3f',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#6b9a76',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#38414e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#212a37',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#9ca5b3',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#746855',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#1f2835',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#f3d19c',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#2f3948',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.station'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#d59563',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#17263c',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#515c6d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#17263c',
        isActive: true,
      },
    },
  },
];
