import { type RangeType } from '../../utils/range/range.helpers';
import { type MarkerStyle } from '../marker.types';
import { type MarkerSettings } from '../markers/visualSettings.types';

export enum GroupingType {
  Text = 'text',
  Numeric = 'numeric'
}

export type GroupingColumn = GroupingColumnText | GroupingColumnNumeric;

export type GroupingColumnText = Readonly<{
  spreadsheetId: number;
  columnId: string;
  type: GroupingType.Text;
}>;

export type GroupingColumnNumeric = Readonly<{
  spreadsheetId: number;
  columnId: string;
  type: GroupingType.Numeric;
  isDecimal: boolean;
  buckets: number[];
  valueType: RangeType;
}>;

export const isGroupingColumnText = (g: GroupingColumn): g is GroupingColumnText => g.type === GroupingType.Text;
export const isGroupingColumnNumeric = (g: GroupingColumn): g is GroupingColumnNumeric => g.type === GroupingType.Numeric;

export type GroupingColumnValues<T> = {
  [GroupingType.Numeric]?: {
    [bucketId: number]: T;
  };
  [GroupingType.Text]?: {
    [groupId: string]: T;
  };
};

export enum NumericActiveGroupColumnType {
  DoubleNumeric = 2,
  SingleNumeric = 1,
}

export type TextGroupKey = string;
export type BucketKey = string;

export type GroupingColumnBucketNumberValues<T> = {
  [GroupingType.Numeric]?: {
    //e.g. 2-0-4 for DoubleNumeric column, the first grouping column, 4 buckets
    [bucketKey: BucketKey]: {
      [bucketId: number]: T | undefined;
    } | undefined;
  };
  [GroupingType.Text]?: {
    [groupId: TextGroupKey]: T | undefined;
  };
};

type PerGroupSpreadSheetType<T> = {
  [spreadsheetId: number]: {
    [columnId: string]: GroupingColumnValues<T>;
  };
};

type PerGroupAndBucketNumberSpreadSheetType<T> = {
  [spreadsheetId: number]: {
    [columnId: string]: GroupingColumnBucketNumberValues<T> | undefined;
  } | undefined;
};

export type ActiveGroupFilters = PerGroupSpreadSheetType<1>;

export type GroupMarkers = PerGroupSpreadSheetType<MarkerStyle>;
export type GroupMarkerSettings = PerGroupAndBucketNumberSpreadSheetType<MarkerSettings>;

type UniqueGroupNumeric = Readonly<{
  id: number;
  name: string;
  type: GroupingType.Numeric;
}>;

type UniqueGroupText = Readonly<{
  id: string;
  name: string;
  type: GroupingType.Text;
}>;

export type UniqueGroup = UniqueGroupNumeric | UniqueGroupText;

export type PerSpreadsheetUniqueGroups = {
  [spreadsheetId: number]: {
    [columnId: string]: UniqueGroup[];
  };
};

export const isTextTypeGroup = (g: UniqueGroup): g is UniqueGroupText => g.type === GroupingType.Text;
export const isNumericTypeGroup = (g: UniqueGroup): g is UniqueGroupNumeric => g.type === GroupingType.Numeric;
