import {
  type FC, memo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { type DrawingItemRectangle } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolRectangleInstanceRectangleContainer } from './drawingToolRectangleInstanceRectangle.container';

type MapRectangleInstanceProps = {
  instance: DrawingItemRectangle;
};

const DrawingToolRectangleInstanceContainer: FC<MapRectangleInstanceProps> = ({
  instance,
}) => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut: onRectangleMouseOut, onMouseOver: onRectangleMouseOver, onRightClick } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const onRectangleClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Rectangle,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  const onRectangleRightClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current || !e.latLng) {
      return;
    }

    e.stopPropagation();
    onRightClick(e.latLng, {
      type: DrawingTool.Rectangle,
      value: instance,
    });
  }, [drawingEventsEnabledRef, onRightClick, instance]);

  return (
    <DrawingToolRectangleInstanceRectangleContainer
      renderOnlyLine={false}
      instance={instance}
      zIndex={zIndex}
      onRectangleClick={onRectangleClick}
      onRectangleMouseOut={onRectangleMouseOut}
      onRectangleMouseOver={onRectangleMouseOver}
      onRectangleRightClick={onRectangleRightClick}
    />
  );
};

const pureComponent = memo(DrawingToolRectangleInstanceContainer);
export { pureComponent as DrawingToolRectangleInstanceContainer };
