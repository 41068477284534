export class Pool<T extends any, U extends any[]> {

  private pool: T[];
  private used: T[];

  private itemFactory: (...params: U) => T;
  private onDestroy: (item: T) => void;
  private onRetire: (item: T) => void;
  private onRevive: (item: T, ...params: U) => void;

  constructor(params: {
    itemFactory: (...params: U) => T;
    onDestroy: (item: T) => void;
    onRetire: (item: T) => void;
    onRevive: (item: T, ...params: U) => void;
  }) {
    this.itemFactory = params.itemFactory;
    this.onDestroy = params.onDestroy;
    this.onRetire = params.onRetire;
    this.onRevive = params.onRevive;
    this.pool = [];
    this.used = [];
  }

  public getNextItem(...params: U): T {
    const nextItem = this.pool.pop();
    if (nextItem) {
      this.onRevive(nextItem, ...params);
      this.used.push(nextItem);
      return nextItem;
    }
    else {
      const newItem = this.itemFactory(...params);
      this.used.push(newItem);
      return newItem;
    }
  }

  public retireItem(item: T): void {
    // first we need to check if the items actually exists among our used items
    const itemIndex = this.used.findIndex(i => i === item);
    if (itemIndex !== -1) {
      this.onRetire(item);
      this.used.splice(itemIndex, 1);
      this.pool.push(item);
    }
    else {
      throw new Error('The passed Item is not a used Pool item!');
    }
  }

  public dispose(): void {
    this.used.forEach(i => this.onDestroy(i));
    this.used = [];
    this.pool = [];
  }
}
