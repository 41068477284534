import { css } from '@emotion/react';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type FC, useEffect,
} from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const fullWidthStyle = css({ width: '100%' });

const resendLinkStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  cursor: isDisabled ? 'not-allowed' : 'default',
  opacity: isDisabled ? theme.opacity.disabled : 1,
  textDecoration: 'underline',
});

const mfaSentStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.success,
  cursor: 'default',
  display: 'flex',
  gap: '8px',
});

type EmailSentAnimatedTextProps = Readonly<{
  playAnimation: boolean;
  text: string;

  onAnimationEnd?: () => void;
  onResend: () => void;
}>;

export const EmailSentAnimatedTextComponent: FC<EmailSentAnimatedTextProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [resendSectionStyle, resendSectionStyleApi] = useSpring(() => ({ opacity: 1 }));

  useEffect(() => {
    if (props.playAnimation) {
      resendSectionStyleApi.start({
        from: {
          opacity: 0.4,
        },
        to: { opacity: 1 },
        config: {
          duration: 300,
        },
      });
    }
  }, [props.playAnimation, resendSectionStyleApi]);

  return (
    <animated.div
      style={resendSectionStyle}
      css={mfaSentStyle({ theme })}
    >
      <LottieAnimationComponent
        autoplay={props.playAnimation}
        onAnimationEnd={props.onAnimationEnd}
        size={30}
        type={LottieAnimationTypes.MailChecked}
      />
      <div css={fullWidthStyle}>
        {props.text}
        <br />
        <LinkComponent
          disabled={props.playAnimation}
          css={resendLinkStyle({ theme, isDisabled: props.playAnimation })}
          onClick={props.onResend}
          path={null}
        >
          {t('Resend Email')}
        </LinkComponent>
      </div>
    </animated.div>
  );
};
