import {
  compare, type Operation,
} from 'fast-json-patch';
import {
  buffers, type TakeableChannel,
} from 'redux-saga';
import {
  actionChannel, call, delay, put, race, take, takeLatest,
} from 'redux-saga/effects';
import { omit } from '~/_shared/utils/object/omit';
import { isMapInOfflineModeSelector } from '~/store/selectors/useMapInfoSelectors';
import { select } from '../../../_shared/utils/saga/effects';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import { createUpdateSettingsConcurrencyError } from '../../../map/map.factory';
import {
  getMapSettings, type MapInfoResponse, type MapSettingsDataResponse, type MapSettingsDataServerModel,
  type MapSettingsEtag, mapSettingsUpdateSetting, type MapSettingsUpdateSettingsCombinedResponse,
} from '../../../map/map.repository';
import { ModalType } from '../../../modal/modalType.enum';
import { MAP_RESET } from '../../map/map.actionTypes';
import { type MapIdAction } from '../../mapId/mapId.action';
import { MAP_ID_SET } from '../../mapId/mapId.actionTypes';
import { MAP_INFO_FETCH_DATA_SUCCESS } from '../../mapInfo/mapInfo.actionTypes';
import { openModal } from '../../modal/modal.actionCreators';
import { isCurrentMapSnapshot } from '../../selectors/isCurrentMapSnapshot.selector';
import { mapSettingsConcurrencyEtagSelector } from '../concurrency/mapSettingsConcurrency.selectors';
import { waitForMapSettingsReady } from '../ready/mapSettingsReady.sagas.helpers';
import { areMapSettingsReadySelector } from '../ready/mapSettingsReady.selectors';
import { type MapSettingsDataAction } from './mapSettingsData.action';
import {
  mapSettingsFetchDataError, mapSettingsFetchDataRequest, mapSettingsFetchDataSuccess, mapSettingsSyncCancel,
  mapSettingsSyncError, mapSettingsSyncRequest, mapSettingsSyncSuccess,
} from './mapSettingsData.actionCreators';
import {
  MAP_SETTINGS_FETCH_DATA_REQUEST, MAP_SETTINGS_FETCH_DATA_SUCCESS,
} from './mapSettingsData.actionTypes';
import { normalizePathForMapSettingsTree } from './mapSettingsData.helpers';
import { mapSettingsDataServerModelSelector } from './mapSettingsData.selectors';
import { type MapSettingsDataState } from './mapSettingsData.state';

const fieldsToExcludeForNonSnapshots: ReadonlyArray<keyof MapSettingsDataState> = [
  'toolsState',
];

let lastMapSettingsSyncData: MapSettingsDataServerModel | null = null;

export function* mapSettingsDataSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_REQUEST, fetchMapSettings);
  // watch for map settings markers changes to save data on the server only after fetching current data
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, watchMapSettingsDiff);
  yield takeLatest(MAP_RESET, onMapReset);
  yield takeLatest(MAP_ID_SET, onMapIdChange);
}

function* onMapIdChange(action: PickAction<MapIdAction, typeof MAP_ID_SET>) {
  const clientId: number | null = yield select(state => state.userData.clientId);

  if (action.payload.mapId === null) {
    return;
  }

  if (clientId === null) {
    yield put(mapSettingsFetchDataError(new Error('Missing clientId')));
    return;
  }

  yield put(mapSettingsFetchDataRequest(clientId, action.payload.mapId));
}

function onMapReset() {
  lastMapSettingsSyncData = null;
}

function* fetchMapSettings(action: PickAction<MapSettingsDataAction, typeof MAP_SETTINGS_FETCH_DATA_REQUEST>) {
  try {
    const response: { data: MapSettingsDataResponse } = yield call(getMapSettings, action.payload.clientId, action.payload.mapId);
    const mapInfo: MapInfoResponse | null = yield select(s => s.map.mapInfo.data);
    // without mapInfo we can't decide if a snapshot is opened or not
    if (!mapInfo) {
      yield take(MAP_INFO_FETCH_DATA_SUCCESS);
    }

    const isSnapshot: boolean = yield select(isCurrentMapSnapshot);
    const dataWithoutActiveElements = Object.keys(response.data)
      .reduce((acc, key: keyof MapSettingsDataResponse) => {
        acc[key] = !isSnapshot && fieldsToExcludeForNonSnapshots.includes(key as keyof MapSettingsDataState)
          ? {}
          : response.data[key] as any;

        return acc;
      }, {} as MapSettingsDataResponse);

    lastMapSettingsSyncData = omit(dataWithoutActiveElements, ['mapId']);

    yield put(mapSettingsFetchDataSuccess(dataWithoutActiveElements));
  }
  catch (e) {
    yield put(mapSettingsFetchDataError(e));
  }
}

function* syncMapSettings(clientId: number, mapId: number, diff: Operation[]) {
  const isSnapshot: boolean = yield select(isCurrentMapSnapshot);

  const mappedDiff = normalizePathForMapSettingsTree(diff)
    .filter(o => isSnapshot || fieldsToExcludeForNonSnapshots.every(f => !o.path.startsWith(f as string)));

  const etag: MapSettingsEtag | null = yield select<MapSettingsEtag | null>(mapSettingsConcurrencyEtagSelector);

  if (mappedDiff.length === 0) {
    // required to notify spreadsheet import that sync is cancelled (spreadsheetImport.sagas.ts)
    yield put(mapSettingsSyncCancel());
    return true;
  }

  try {
    yield put(mapSettingsSyncRequest());

    const updateResults: MapSettingsUpdateSettingsCombinedResponse = yield call(
      mapSettingsUpdateSetting, clientId, mapId, mappedDiff.map(item => JSON.stringify(item)), etag,
    );

    if (updateResults.type === 'concurrency-error') {
      yield put(openModal(ModalType.MapSettingsOutOfSync, {
        syncResults: createUpdateSettingsConcurrencyError(updateResults.data),
      }));

      yield put(mapSettingsSyncError());
      return false;
    }
    else {
      yield put(mapSettingsSyncSuccess(updateResults.data.data.etag));
      return true;
    }
  }
  catch (e) {
    yield put(mapSettingsSyncError());
    yield put(mapSettingsFetchDataRequest(clientId, mapId));
    return false;
  }
}

export function* watchMapSettingsDiff() {

  // Buffer latest map settings action when the saga is busy and not taking actions (blocked by API call).
  const mapSettingsChannel: TakeableChannel<Action> = yield actionChannel(
    (action: Action) => action.type.startsWith('MAP_SETTINGS'), buffers.sliding(1));

  while (true) {
    yield call(waitForMapSettingsReady);

    yield take(mapSettingsChannel);

    while (true) {
      const { timeout } = yield race({
        timeout: delay(1000),
        latestAction: take(mapSettingsChannel),
      });

      if (timeout) {
        break;
      }
    }

    const areMapSettingsReady: boolean = yield select(areMapSettingsReadySelector);
    // stop sync if map is in "offline" mode
    const isOffline: boolean = yield select(isMapInOfflineModeSelector);
    if (!areMapSettingsReady || !lastMapSettingsSyncData) {
      continue;
    }

    const nextData: MapSettingsDataServerModel = yield select<MapSettingsDataServerModel>(mapSettingsDataServerModelSelector);
    const diff = compare(lastMapSettingsSyncData, nextData);

    if (isOffline || !diff.length) {
      yield put(mapSettingsSyncCancel());
      continue;
    }

    const clientId: number | null = yield select(state => state.userData.clientId);
    const mapId: number | null = yield select(state => state.map.mapId);
    if (clientId === null || mapId === null) {
      continue;
    }

    const success: boolean = yield call(syncMapSettings, clientId, mapId, diff);
    if (success) {
      lastMapSettingsSyncData = nextData;
    }
  }
}
