import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useCreateEntityAccess } from '../../../_shared/entityAccess/entityAccess.hooks';
import {
  EntityAccessLevel, EntityType, type NewEntityAccess, SharedEntityType,
} from '../../../_shared/entityAccess/entityAccess.repository';
import { useTranslation } from '../../../_shared/utils/hooks';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { createAppError } from '../../../store/modal/modal.actionCreators';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import {
  type Member, MemberStatus,
} from '../../teamManagementModal/teamManagement.repository';
import { type MapAccessData } from '../teamShareMapModal.container';
import { NewMemberFormComponent } from './newMemberForm.component';

export type NewMemberFormContainerProps = Readonly<{
  mapAccessData: MapAccessData[];
  mapId: number;
  onNewMemberAdded?: () => void;
}>;

export const NewMemberFormContainer: FC<NewMemberFormContainerProps> = (props) => {
  const { mapId, onNewMemberAdded, mapAccessData } = props;
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    create: createEntityAccess,
    isLoading,
  } = useCreateEntityAccess();

  const onAddUsers = useCallback((newMembers: Member[], onSuccess?: () => void) => {
    if (!clientId) {
      return;
    }

    createEntityAccess(
      clientId,
      newMembers.map((item) => {
        if (!item?.id) {
          return null;
        }
        return {
          entityType: EntityType.MAP,
          sharedEntityType: SharedEntityType.USER,
          accessLevel: EntityAccessLevel.WRITE,
          entityId: mapId,
          sharedEntityId: item.id,
        };
      }).filter(i => i) as NewEntityAccess[],
    ).then(() => {
      onNewMemberAdded?.();
      onSuccess?.();
    }).catch(() => {
      dispatch(
        createAppError({
          type: AppErrorType.General,
          title: t('Error'),
          content: t('teamShareMap.addingUserError'),
          errorTitle: t('teamShareMap.addingUserErrorTitle'),
        })
      );
    });
  }, [clientId, createEntityAccess, dispatch, mapId, onNewMemberAdded, t]);

  const excludeExistingMembersFilter = useCallback((item: Member) => (
    !!item.id &&
    item.status === MemberStatus.ENABLED &&
    !mapAccessData
      .map((item) => item.sharedEntityId.toString())
      .includes(item.id.toString())
  ), [mapAccessData]);

  return (
    <NewMemberFormComponent
      onAddUsers={onAddUsers}
      excludeExistingMembersFilter={excludeExistingMembersFilter}
      isLoading={isLoading}
    />
  );
};
