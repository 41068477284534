export enum DeleteMarkerContextMenuStructure {
  DeleteMarker = 'Delete Marker',
}

export enum EditLocationContextMenuStructure {
  CategorizeData = 'Categorize Data',
  UploadImages = 'Upload Images',
  CustomizeInfoDisplay = 'Customize Info Display',
  CustomizeMarker = 'Customize Marker',
  EditData = 'Edit Data',
}

export enum ToolsContextMenuStructure {
  AddToRoute = 'Add to Route',
  DrawRadius = 'Draw Radius',
  Navigate = 'Navigate'
}
