import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';

const showMarkerShadowsSelector = (state: AppState) => state.frontendState.graphicSettings.showMarkerShadows;
export const useShowMarkerShadows = () => useSelector(showMarkerShadowsSelector);

const forcedClusteringSelector = (state: AppState) => state.frontendState.graphicSettings.forcedClustering;
export const useHasForcedClustering = () => useSelector(forcedClusteringSelector);

const slidingAnimationDurationSelector = (state: AppState) => state.frontendState.graphicSettings.slideAnimationDuration;
export const useSlidingAnimationDuration = () => useSelector(slidingAnimationDurationSelector);

const allowIdleAnimationsSelector = (state: AppState) => state.frontendState.graphicSettings.allowIdleAnimations;
export const useAllowIdleAnimationsSelector = () => useSelector(allowIdleAnimationsSelector);
