import { type TranslationFnc } from '../../../../../_shared/utils/hooks';
import { BoundaryTerritoryType } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryTerritoryTypeOptionNames } from '../boundaryTools.types';

export const boundaryTerritoryTypeOptionNames = (t: TranslationFnc): BoundaryTerritoryTypeOptionNames => ({
  [BoundaryTerritoryType.Manual]: t('NoFill'),
  [BoundaryTerritoryType.Numeric]: t('MyNumericalData'),
  [BoundaryTerritoryType.Groups]: t('MyGroupTerritoryData'),
  [BoundaryTerritoryType.Density]: t('MarkerCountLocationDensity'),
  [BoundaryTerritoryType.Demographic]: t('DemographicCensusData'),
});

export enum BoundarySelectedOption {
  BoundaryFillType = 'Select Fill',
  BoundaryFillData = 'Select Fill Data',
  BoundaryTerritoryType = 'Select Fill Option',
  DemographicGroup = 'Demographic Group',
  SelectActionType = 'Select Action',
  SelectBoundaryType = 'Select Boundary',
  SelectCustomBoundaryGroup = 'Select Territory Group',
  SelectCalculateFunction = 'Select Function',
}
