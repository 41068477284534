import { type Theme } from '../../../../_shared/themes/theme.model';
import {
  type SidebarTitleSettings, type SidebarTitleStyle,
} from './sidebarTitle.types';

export const SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE = (theme: Theme): SidebarTitleSettings => ({
  showValue: true,
  valueFontColor: theme.textColors.primary,
  valueFontSize: '20',
});

export const SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE = (theme: Theme): SidebarTitleSettings => ({
  showValue: true,
  valueFontColor: theme.textColors.secondary,
  valueFontSize: '14',
});

export const SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE = (theme: Theme): SidebarTitleSettings => ({
  showValue: true,
  valueFontColor: theme.textColors.primary,
  valueFontSize: '14',
});

export const SIDEBAR_TITLE_DESCRIPTION_STYLE = (theme: Theme): SidebarTitleStyle => ({
  description: SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE(theme),
  header: SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE(theme),
  semiHeader: SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE(theme),
});
