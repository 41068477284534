import {
  type MapSettingsMapStylesChangeOptions,
  type MapSettingsStylesChangeData,
} from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName,
  BaseMapFeatureTypeName,
  BaseMapStylerVisibility,
  MapStylesSliderSettingsName,
} from '../baseMap.enums';
import { makeSliderChangeDataVisible } from './baseMapStandardSliders.helpers';
import { type BaseMapSliderOptions } from './baseMapStandardSliders.types';

const hiddenLandParcelAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['administrative.landParcel'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenLandParcelLabels: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['administrative.landParcel'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenNeighborhoodAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['administrative.neighborhood'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenAllLabels: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.labels,
  featureType: BaseMapFeatureTypeName.all,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenPoiLabels: MapSettingsMapStylesChangeOptions = {
  featureType: BaseMapFeatureTypeName.poi,
  elementType: BaseMapElementTypeName['labels.text'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const firstLabelsSlider: MapSettingsStylesChangeData = [
  hiddenNeighborhoodAll,
  hiddenLandParcelAll,
  hiddenAllLabels,
];

const secondLabelsSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(firstLabelsSlider),
  hiddenNeighborhoodAll,
  hiddenLandParcelAll,
  hiddenPoiLabels,
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.labels,
    stylers: { visibility: BaseMapStylerVisibility.hidden },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text'],
    stylers: { visibility: BaseMapStylerVisibility.hidden },
  },
];

const thirdLabelsSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(secondLabelsSlider),
  hiddenPoiLabels,
  hiddenLandParcelLabels,
  {
    featureType: BaseMapFeatureTypeName['road.local'],
    elementType: BaseMapElementTypeName.labels,
    stylers: { visibility: BaseMapStylerVisibility.hidden },
  },
];

const forthLabelsSlider: MapSettingsStylesChangeData = makeSliderChangeDataVisible(thirdLabelsSlider);

export const labelSliderOptions: BaseMapSliderOptions = [
  firstLabelsSlider,
  secondLabelsSlider,
  thirdLabelsSlider,
  forthLabelsSlider,
];

export const defaultBaseMapStandardSliderValues: {[key in keyof typeof MapStylesSliderSettingsName]: number} = {
  [MapStylesSliderSettingsName.labels]: 3,
  [MapStylesSliderSettingsName.landmarks]: 3,
  [MapStylesSliderSettingsName.roads]: 3,
};
