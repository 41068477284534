import { css } from '@emotion/react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  DropdownLabelComponent, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import {
  SelectedItemComponent, Size,
} from '~/_shared/components/selectedItem/selectedItem.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import {
  isArrayTextPredicate, isNullableTextPredicate,
} from '../filterTool/filters/filters.helpers';
import {
  renderTextFilterPredicate, TextFilterPredicate,
} from '../filterTool/filters/textFilter/textFilterPredicate.enum';

export type FilterDataQueryItem = {
  filterPredicate: TextFilterPredicate;
  textArgument: string;
  columnName: string;
  onRemoveItem: () => void;
};

type FilterDataToolProps = Readonly<{
  queries: ReadonlyArray<FilterDataQueryItem>;
  spreadsheetColumnOptions: ReadonlyArray<DropdownOption<SpreadsheetColumnId>>;

  onAddItem: (spreadsheetColumnId: SpreadsheetColumnId, action: TextFilterPredicate, value: string) => void;
}>;

const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const sectionWrapperStyle = css({
  padding: '16px 16px 0',
});

const submitButtonWrapperStyle = css({
  display: 'flex',
});

const buttonStyle = css({
  padding: '4px 8px',
  marginLeft: 'auto',
});

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const queriesHeadingStyle = ({ theme }: ThemeProps) => css({
  border: `1px solid ${theme.borderColors.primary}`,
  borderWidth: '1px 0',
  padding: 10,
  fontSize: '14px',
});

const queryItemStyle = css({
  marginTop: 8,
  marginLeft: 4,
});

const queriesWrapperStyle = css({
  overflow: 'hidden',
  padding: '0 10px 0 6px',
  display: 'flex',
  flexWrap: 'wrap',
});

const hintStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
  fontSize: 12,
  lineHeight: 1.15,
  margin: '0 0 8px',
});

export const FilterDataToolComponent: FC<FilterDataToolProps> = (props) => {
  const [selectedDataColumn, setSelectedDataColumn] = useState<SpreadsheetColumnId | null>(null);
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState<TextFilterPredicate>(TextFilterPredicate.Contains);
  const [query, setQuery] = useState('');
  const prevQueryLength = usePrevious(props.queries.length);

  const [t] = useTranslation();
  const theme = useTheme();

  const isNullablePredicate = isNullableTextPredicate(selectedSearchCriteria);

  // reset query input on successful query add
  useEffect(() => {
    if (prevQueryLength !== undefined && props.queries.length > prevQueryLength) {
      setQuery('');
    }
  }, [props.queries.length, prevQueryLength]);

  useEffect(() => {
    if (isNullablePredicate) {
      setQuery('');
    }
  }, [isNullablePredicate]);

  const isSubmitButtonDisabled = selectedDataColumn === null || (isTextEmpty(query) && !isNullablePredicate);

  const onSubmit = () => {
    if (isSubmitButtonDisabled) {
      return;
    }

    props.onAddItem(selectedDataColumn, selectedSearchCriteria, query);
  };

  return (
    <div>
      <div css={sectionWrapperStyle}>
        <div css={panelDropdownSectionStyle}>
          <DropdownLabelComponent css={labelStyle}>1. {t('Select Data Column')}</DropdownLabelComponent>
          <RegularDropdownComponent
            inPortal
            options={props.spreadsheetColumnOptions}
            value={selectedDataColumn}
            onChange={setSelectedDataColumn}
            placeholder={t('Data Column')}
          />
        </div>

        <div css={panelDropdownSectionStyle}>
          <DropdownLabelComponent css={labelStyle}>2. {t('Set Search Criteria')}</DropdownLabelComponent>
          <RegularDropdownComponent
            inPortal
            options={Object.values(TextFilterPredicate).map(value => ({ name: renderTextFilterPredicate(value, t), value }))}
            value={selectedSearchCriteria}
            onChange={setSelectedSearchCriteria}
            placeholder={t('Search Criteria')}
          />
        </div>

        <div css={panelDropdownSectionStyle}>
          <DropdownLabelComponent css={labelStyle}>3. {t('Enter Search')}</DropdownLabelComponent>
          <TextInputComponent
            value={query}
            onChange={setQuery}
            type="text"
            isDisabled={isNullablePredicate}
            placeholder={t('Search')}
            icon={faSearch}
          />
        </div>

        {isArrayTextPredicate(selectedSearchCriteria) && (
          <div css={hintStyle({ theme })}>
            {t('Hint: You may enter multiple values separated with commas.')}
          </div>
        )}

        <div css={[panelDropdownSectionStyle, submitButtonWrapperStyle]}>
          <ButtonComponent
            isDisabled={isSubmitButtonDisabled}
            css={buttonStyle}
            onClick={() => onSubmit()}
            text={t('Continue')}
          />
        </div>
      </div>

      <div>
        <div css={queriesHeadingStyle({ theme })}>{t('Current Active Search Queries')}</div>

        <div css={queriesWrapperStyle}>
          {props.queries.map((searchQuery, index) => (
            <SelectedItemComponent
              css={queryItemStyle}
              key={index}
              onRemove={searchQuery.onRemoveItem}
              size={Size.Small}
              multiline
              maxNumberOfLines={2}
            >
              <div>
                {searchQuery.columnName} {renderTextFilterPredicate(searchQuery.filterPredicate, t)} {searchQuery.textArgument}
              </div>
            </SelectedItemComponent>
          ))}
        </div>
      </div>
    </div>
  );
};
