import { css } from '@emotion/react';
import React from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const nameSectionWrapperStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  cursor: 'default',
  display: 'flex',
  fontSize: 14,
  gap: 6,
  padding: '10px 20px',
  width: '100%',
});

const titleStyle = css({
  flexShrink: 0,
});

const mapNameStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.info,
  fontSize: 18,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

type MapNameSectionProps = Readonly<{
  className?: string;
  isSnapshot: boolean;
  name: string;
}>;

export const MapNameSectionComponent: React.FC<MapNameSectionProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div
      className={props.className}
      css={nameSectionWrapperStyle({ theme })}
    >
      <span css={titleStyle}>{t(props.isSnapshot ? 'Map View Name' : 'Map Name: ')}</span>
      <span css={mapNameStyle({ theme })}> {props.name}</span>
    </div>
  );
};
