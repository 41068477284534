import { css } from '@emotion/react';
import {
  type FC, type ReactNode, useMemo,
} from 'react';
import markerBackground from '~/_shared/../../../assets/images/marker-map-background.png';
import { ImageComponent } from '~/_shared/components/image/image.component';
import { LabelVisualizerComponent } from '~/_shared/components/labelVisualizer/labelVisualizer.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type AxisOffsets, type QuadrilateralDimensions,
} from '~/_shared/types/coordinateSystem/coordinateSystem';
import { type MarkerAnchorPosition } from '~/_shared/types/marker.types';
import { type LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { useActiveMarkerIndicatorContext } from '~/customizeMarkerPopup/activeMarkerIndicatorContext';
import { getIndicatorOffsets } from '~/customizeMarkerPopup/customizeMarker/previewTiles/previewTileWrapper.helpers';
import { ActiveMarkerIndicatorComponent } from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.component';
import { useMarkerSets } from '~/store/frontendState/markerSets/markerSets.selectors';

const PREVIEW_BACKGROUND_DIMENSIONS: QuadrilateralDimensions = { height: 195, width: 266 };
const FIXED_MARGIN = 2;

const previewWrapperStyle = (props: ThemeProps) => css({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  color: props.theme.textColors.primary,
  fontFamily: '"Work Sans", sans-serif',
  fontSize: '14px',
  fontWeight: 500,
  paddingBottom: '3px',
  width: '100%',
});

const previewLabelStyle = css({
  alignSelf: 'start',
  cursor: 'default',
  fontSize: '16px',
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const imageStyle = ({ dimensions }: {dimensions: QuadrilateralDimensions}) => css({
  borderRadius: 4,
  height: `${dimensions.height}px`,
  width: `${dimensions.width}px`,
  overflow: 'hidden',
  position: 'relative',
});

const markerPreviewStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  bottom: 0,
  height: '100%',
  left: 0,
  width: PREVIEW_BACKGROUND_DIMENSIONS.width,
  position: 'absolute',
});

const activateIndicatorPreviewWrapperStyle = ({ offsets }: {
  offsets: AxisOffsets | null;
}) => css(offsets ? {
  position: 'absolute',
  top: `calc(50% - ${offsets.yOffset}px)`,
  left: `calc(50% - ${offsets.xOffset}px)`,
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
} : {
  marginBottom: FIXED_MARGIN,
});

type PreviewTileWrapperProps = Readonly<{
  children: ReactNode;
  imageAlt: string;
  labelAbove: LabelVisualSetting;
  marker: {
    anchor: MarkerAnchorPosition | null;
    id: number;
    labelText?: string;
    size: number;
  };
}>;

export const PreviewTileWrapperComponent: FC<PreviewTileWrapperProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { activeMarkerIndicatorSettings } = useActiveMarkerIndicatorContext();
  const markerSets = useMarkerSets();

  const { ref: labelAboveRef, height: labelAboveHeight } = useElementDimensions();

  const offsets = useMemo(() => (
    getIndicatorOffsets({
      indicator: activeMarkerIndicatorSettings,
      marker: props.marker,
      markerSet: markerSets[props.marker.id],
      aboveMarkerOffset: labelAboveHeight,
    })
  ), [activeMarkerIndicatorSettings, markerSets, props.marker, labelAboveHeight]);

  return (
    <div css={previewWrapperStyle({ theme })}>
      <div css={previewLabelStyle}>{t('Marker Preview')}</div>
      <div css={imageStyle({ dimensions: PREVIEW_BACKGROUND_DIMENSIONS })}>
        <ImageComponent
          width={PREVIEW_BACKGROUND_DIMENSIONS.width}
          height={PREVIEW_BACKGROUND_DIMENSIONS.height}
          src={markerBackground}
          alt={props.imageAlt}
        />
        <div css={markerPreviewStyle}>
          <div
            css={activateIndicatorPreviewWrapperStyle({ offsets })}
          >
            <ActiveMarkerIndicatorComponent
              color={activeMarkerIndicatorSettings.color}
              currentActiveIndicator={activeMarkerIndicatorSettings.type}
              markerVisualSettings={undefined}
              size={activeMarkerIndicatorSettings.size}
              speed={activeMarkerIndicatorSettings.speed}
            />
          </div>
          {props.marker.labelText && (
            <div ref={labelAboveRef}>
              <LabelVisualizerComponent
                {...props.labelAbove}
                label={props.marker.labelText}
              />
            </div>
          )}

          {props.children}

        </div>
      </div>
    </div>

  );
};
