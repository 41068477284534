import { type CancelTokenSource } from 'axios';
import {
  useCallback, useEffect, useState,
} from 'react';
import { createCancelToken } from '~/_shared/utils/api/api.helpers';
import { isCancelError } from '~/_shared/utils/api/apiError.helpers';
import { createUuid } from '~/_shared/utils/createUuid';
import { getLatLngFromAddress } from '~/_shared/utils/geolocation/cachedGeolocation';
import { type GeoLocation } from '~/_shared/utils/geolocation/geolocation';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { MapSearchFilterBy } from '../filter/mapSearchFilterBy.enum';
import {
  getMapSearchSuggests, type MapSearchSuggestsRequestItem, type MapSearchSuggestsResponse,
} from '../map.repository';
import {
  type SearchItem, type SearchItemGeolocated, type SearchItemWithColumn,
} from './mapSearch.helpers';

const SEARCH_SUGGESTS_COUNT_LIMIT = 5;
const GEOLOCATED_SUGGEST_COUNT_LIMIT = 3;

const getUniqueItemsFromAutocompleteResponse = (responses: MapSearchSuggestsResponse[], limit: number): SearchItemWithColumn[] => {
  const uniqueCellText: string[] = [];
  const uniqueItems: SearchItemWithColumn[] = [];

  for (const response of responses) {
    for (const key in response.data) {
      if (!response.data[key]) {
        continue;
      }

      for (let i = 0; i < response.data[key].data.length; i++) {
        const item = response.data[key].data[i];

        if (item && !uniqueCellText.includes(item.cell_text)) {
          uniqueCellText.push(item.cell_text);
          uniqueItems.push({
            id: createUuid(),
            text: item.cell_text,
            columnId: item.column_index,
            columnName: item.column_name,
            spreadsheetId: response.spreadsheet_id,
          });
        }

        if (uniqueCellText.length >= limit) {
          return uniqueItems;
        }
      }
    }
  }

  return uniqueItems;
};

const getItemsFromGeolocationResponse = (locations: ReadonlyArray<GeoLocation>, limit: number): SearchItemGeolocated[] =>
  locations.map(location => ({
    id: createUuid(),
    text: location.address,
    latLng: [location.latLng] as const,
  })).slice(0, limit);

type MapSearchSuggestsParams = {
  exact: boolean;
  filterBy: MapSearchFilterBy | null;
  query: string;
  spreadsheetIds: number[];
};

export const useMapSearchSuggests = (clientId: number | null, mapId: number | null, params: MapSearchSuggestsParams) => {
  const { query, spreadsheetIds, exact, filterBy } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [suggests, setSuggests] = useState<SearchItem[]>([]);
  const isMountedRef = useIsComponentMountedRef();

  const fetchSuggestions = useCallback(async (cancelToken: CancelTokenSource) => {
    if (clientId === null || mapId === null || query.length === 0 || spreadsheetIds.length === 0) {
      return;
    }

    setIsLoading(true);
    setIsError(false);

    const requests: MapSearchSuggestsRequestItem[] = spreadsheetIds.map(spreadsheetId => ({
      exact,
      max_results: SEARCH_SUGGESTS_COUNT_LIMIT,
      only_address: false,
      search: query,
      spreadsheet_id: spreadsheetId,
      one_match_per_col: false,
    }));

    try {
      let results: SearchItem[] = [];

      if (filterBy === null || filterBy === MapSearchFilterBy.OnlyUserData || filterBy === MapSearchFilterBy.UserDataAndGeolocated) {
        const userDataSuggests = await getMapSearchSuggests(clientId, mapId, { requests }, {
          cancelToken: cancelToken.token,
        });
        results = [...results, ...getUniqueItemsFromAutocompleteResponse(userDataSuggests.data, SEARCH_SUGGESTS_COUNT_LIMIT)];
      }

      if (filterBy === MapSearchFilterBy.OnlyGeolocated || filterBy === MapSearchFilterBy.UserDataAndGeolocated) {
        try {
          const geographicLocations = await getLatLngFromAddress(query);
          results = [...results, ...getItemsFromGeolocationResponse(geographicLocations, GEOLOCATED_SUGGEST_COUNT_LIMIT)];
        }
        catch {
          //we don't need to error handle currently if no geographic matches are found
        }
      }

      setSuggests(results);
    }
    catch (e) {
      if (isCancelError(e)) {
        return;
      }

      if (isMountedRef.current) {
        setIsError(true);
        console.error(e);
      }
    }
    finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [clientId, exact, filterBy, isMountedRef, mapId, query, spreadsheetIds]);

  useEffect(() => {
    if (clientId === null || mapId === null || query.length === 0) {
      setSuggests([]);
      return;
    }

    const cancelToken = createCancelToken();
    const timeoutId = setTimeout(() => {
      fetchSuggestions(cancelToken);
    }, 1250);

    return () => {
      clearTimeout(timeoutId);
      cancelToken.cancel();
    };
  }, [clientId, fetchSuggestions, mapId, query.length]);

  return {
    suggests,
    isLoading,
    isError,
  };
};
