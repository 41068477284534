import {
  MOVE_MARKER_LABELS_ACTIVATE,
  MOVE_MARKER_LABELS_CLOSE_MODAL,
  MOVE_MARKER_LABELS_DEACTIVATE,
  MOVE_MARKER_LABELS_DISCARD_CHANGES,
  MOVE_MARKER_LABELS_DRAG_START,
  MOVE_MARKER_LABELS_DRAG_STOP,
  MOVE_MARKER_LABELS_OPEN_MODAL,
  MOVE_MARKER_LABELS_UPDATE_POSITION,
  MOVE_MARKER_LABELS_XMARK_HOVER_STOP,
  MOVE_MARKER_LABELS_XMARK_HOVERED,
} from './moveMarkerLabels.actionTypes';
import { type MoveMarkerLabelsOffset } from './moveMarkerLabels.state';

export const activateMoveMarkerLabels = () => ({
  type: MOVE_MARKER_LABELS_ACTIVATE,
}) as const;

export const moveMarkerLabelsOpenModal = () => ({
  type: MOVE_MARKER_LABELS_OPEN_MODAL,
}) as const;

export const moveMarkerLabelsCloseModal = () => ({
  type: MOVE_MARKER_LABELS_CLOSE_MODAL,
}) as const;

export const deactivateMoveMarkerLabels = () => ({
  type: MOVE_MARKER_LABELS_DEACTIVATE,
}) as const;

export const discardMoveMarkerLabels = () => ({
  type: MOVE_MARKER_LABELS_DISCARD_CHANGES,
}) as const;

export const updateMarkerLabelPosition = (offset: MoveMarkerLabelsOffset) => ({
  type: MOVE_MARKER_LABELS_UPDATE_POSITION,
  payload: offset,
}) as const;

export const startMoveMarkerLabelDrag = () => ({
  type: MOVE_MARKER_LABELS_DRAG_START,
}) as const;

export const stopMoveMarkerLabelDrag = () => ({
  type: MOVE_MARKER_LABELS_DRAG_STOP,
}) as const;

export const hoverMoveMarkerLabelsXMarkButton = () => ({
  type: MOVE_MARKER_LABELS_XMARK_HOVERED,
}) as const;

export const stopMoveMarkerLabelsXMarkButtonHover = () => ({
  type: MOVE_MARKER_LABELS_XMARK_HOVER_STOP,
}) as const;
