import {
  useCallback, useState,
} from 'react';

// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export const useHookWithRefCallback = <T>() => {
  const [state, setState] = useState<T | null>(null);
  const setRef = useCallback((node: T) => {
    if (node !== null) {
      setState(node);
    }
  }, []);

  return [state, setRef] as const;
};
