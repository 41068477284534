import {
  DRAWING_TOOL_NUMBER_MAX_VALUE,
  DRAWING_TOOL_NUMBER_MIN_VALUE,
} from '~/drawingTool/drawingTool.constants';
import { type DrawingNumberSettingsState } from '../../store/mapSettings/drawing/settings/number/drawingNumberSettings.state';
import {
  DRAWING_TOOL_SLIDER_FONT_SIZE_PERCENTAGE_RANGE,
  DRAWING_TOOL_SLIDER_PERCENTAGE_SUFFIX, DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
  DRAWING_TOOL_SLIDER_RADIUS_RANGE,
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createNumberDrawingGlobalOptionsFromSettings = (
  settings: DrawingNumberSettingsState
) => createNumberDrawingOptionsFromSettings({ settings });

export const createSelectedNumberDrawingOptionsFromSettings = (settings: DrawingNumberSettingsState, sizePerPixelRatio: number) =>
  createNumberDrawingOptionsFromSettings({ settings, sizePerPixelRatio });

type CreateNumberOptionsArguments = {
  settings: DrawingNumberSettingsState;
  sizePerPixelRatio?: number;
};

const createNumberDrawingOptionsFromSettings = (
  { settings, sizePerPixelRatio }: CreateNumberOptionsArguments
): Partial<DrawingToolOptions> => {
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    booleanOptions: {
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    otherOptions: {
      number: {
        value: settings.counter,
        min: DRAWING_TOOL_NUMBER_MIN_VALUE,
        max: DRAWING_TOOL_NUMBER_MAX_VALUE,
      },
    },
    sliderOptions: {
      strokeOpacity: {
        value: settings.strokeOpacity,
      },
      fillOpacity: {
        value: settings.fillOpacity,
      },
      strokeWeight: {
        value: settings.strokeWeight,
        range: DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
        suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
      },
      fontSizePercentage: {
        value: settings.fontSizePercentage,
        range: DRAWING_TOOL_SLIDER_FONT_SIZE_PERCENTAGE_RANGE,
        suffix: DRAWING_TOOL_SLIDER_PERCENTAGE_SUFFIX,
      },
      ...!useScaledSlider ? {
        radius: {
          // fallback if previous radius was used (radiusPercentage)
          value: settings.radius ?? 20,
          range: DRAWING_TOOL_SLIDER_RADIUS_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
        },
      } : undefined,
    },
    ...useScaledSlider ? {
      scaledSliderOptions: {
        radiusOnCurrentMapZoom: {
          value: settings.radius,
          displayValue: Math.round((settings.radius) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_RADIUS_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
          ensureValueInRange: true,
        },
      },
    } : undefined,
    colorOptions: {
      strokeColor: settings.strokeColor,
      fillColor: settings.fillColor,
      fontColor: settings.fontColor,
    },
  };
};

export const createNumberDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingNumberSettingsState
): DrawingNumberSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    counter: toolOptions.otherOptions?.number?.value ?? currentSettings.counter,
    // fallback if previous radius was used (radiusPercentage)
    radius: toolOptions.sliderOptions?.radius?.value ?? toolOptions.scaledSliderOptions?.radiusOnCurrentMapZoom?.value ?? currentSettings.radius ?? 20,
    strokeOpacity: toolOptions.sliderOptions?.strokeOpacity?.value ?? currentSettings.strokeOpacity,
    fillOpacity: toolOptions.sliderOptions?.fillOpacity?.value ?? currentSettings.fillOpacity,
    strokeWeight: toolOptions.sliderOptions?.strokeWeight?.value ?? currentSettings.strokeWeight,
    fontSizePercentage: toolOptions.sliderOptions?.fontSizePercentage?.value ?? currentSettings.fontSizePercentage,
    strokeColor: toolOptions.colorOptions?.strokeColor ?? currentSettings.strokeColor,
    fillColor: toolOptions.colorOptions?.fillColor ?? currentSettings.fillColor,
    fontColor: toolOptions.colorOptions?.fontColor ?? currentSettings.fontColor,
  };
};
