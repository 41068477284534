import {
  buffers, eventChannel,
} from 'redux-saga';
import {
  actionChannel,
  call, flush, put, race, take,
} from 'redux-saga/effects';
import { type LatLng } from '~/_shared/types/latLng';
import { select } from '~/_shared/utils/saga/effects';
import { type ActionChannel } from '~/_shared/utils/types/saga.type';
import { MAP_RESET } from '~/store/map/map.actionTypes';
import {
  locationTrackingError, setMyLocation,
} from './myLocation.actionCreators';
import {
  FRONTEND_STATE_DISABLE_LOCATION_TRACKING, FRONTEND_STATE_ENABLE_LOCATION_TRACKING,
} from './myLocation.actionTypes';
import { myLocationTrackingEnabledSelector } from './myLocation.selectors';

const ENABLE_TRACKING_ACTIONS = [FRONTEND_STATE_ENABLE_LOCATION_TRACKING];
const DISABLE_TRACKING_ACTIONS = [MAP_RESET, FRONTEND_STATE_DISABLE_LOCATION_TRACKING];

export function* myLocationSagas() {
  yield trackPositionSaga();
}

type WatchPositionRaceResult = {
  position?: GeolocationPosition;
  error?: GeolocationPositionError;
};

const watchPosition = () => {
  return eventChannel<WatchPositionRaceResult>(emitter => {
    const watchId = navigator.geolocation.watchPosition(
      position => emitter({ position }),
      error => emitter({ error })
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, buffers.sliding(1));
};

function* waitForTrackingEnabled() {
  const enableTrackingChannel: ActionChannel<Action> = yield actionChannel(ENABLE_TRACKING_ACTIONS, buffers.sliding(1));
  const cancelTrackingChannel: ActionChannel<Action> = yield actionChannel(DISABLE_TRACKING_ACTIONS, buffers.sliding(1));

  let trackingEnabled: boolean = yield select<boolean>(myLocationTrackingEnabledSelector);

  while (!trackingEnabled) {
    yield take(enableTrackingChannel);
    yield flush(cancelTrackingChannel);
    trackingEnabled = yield select<boolean>(myLocationTrackingEnabledSelector);
  }

  return cancelTrackingChannel;
}

type TrackPositionRaceResult = {
  result?: WatchPositionRaceResult;
  cancel?: Action;
};

function* trackPositionSaga() {
  while (true) {
    const cancelTrackingChannel: ActionChannel<Action> = yield call(waitForTrackingEnabled);
    const getPositionChannel: ReturnType<typeof watchPosition> = yield call(watchPosition);

    try {
      while (true) {
        const { result, cancel }: TrackPositionRaceResult = yield race({
          result: take(getPositionChannel),
          cancel: take(cancelTrackingChannel),
        });

        if (result?.position) {
          const latLng: LatLng = {
            lat: result.position.coords.latitude,
            lng: result.position.coords.longitude,
          };
          yield put(setMyLocation(latLng));
        }
        else if (result?.error) {
          yield put(locationTrackingError());
        }

        if (cancel) {
          break;
        }
      }
    }
    finally {
      getPositionChannel.close();
    }
  }
}
