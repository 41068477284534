import { notNullsy } from '~/_shared/utils/typeGuards';
import type {
  AnyUserEvent, UserEventMapByType,
} from '~/store/userEvents/userEvents.type';

type SequenceEvaluator = (events: readonly AnyUserEvent[], lastEventsOfType: UserEventMapByType) => (() => void) | null;

let sequenceEvaluators: SequenceEvaluator[] = [];

export const evaluateSequence = (events: readonly AnyUserEvent[], lastEventsOfType: UserEventMapByType) => {
  sequenceEvaluators.map(evaluator => {
    return evaluator(events, lastEventsOfType);
  })
    .filter(notNullsy)
    .forEach(f => f());
};

export const unregisterUserEventSequenceEvaluator = (sequenceEvaluator: SequenceEvaluator) => {
  sequenceEvaluators = sequenceEvaluators.filter(evaluator => evaluator !== sequenceEvaluator);
};

export const registerUserEventSequenceEvaluator = (sequenceEvaluator: SequenceEvaluator) => {
  sequenceEvaluators.push(sequenceEvaluator);

  return () => unregisterUserEventSequenceEvaluator(sequenceEvaluator);
};
