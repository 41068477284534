import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';

const IconTile = styled.div<ThemeProps>(({ theme }) => ({
  width: 65,
  height: 65,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.backgroundColors.secondary,
  transition: 'all .1s',
  flexShrink: 0,
  borderRadius: 4,
  boxSizing: 'border-box',
  border: '2px solid transparent',
}));

const contentStyle = (isDisabled: boolean) => (theme: Theme) => css({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  padding: 0,
  margin: 0,
  border: 'none',
  background: 'none',
  gap: 20,
  color: theme.textColors.primary,
  borderRadius: 4,
  transition: 'background .1s',
  '&:hover': isDisabled ? undefined : {
    background: theme.backgroundColors.tertiary,
    [`${IconTile}`]: {
      background: theme.backgroundColors.highlight,
      boxShadow: 'none',
      border: `2px solid ${theme.borderColors.activeItem}`,
      color: theme.textColors.primary,
    },
  },
});

const iconStyle = (theme: Theme) => css({
  color: theme.textColors.primary,
  width: 45,
  height: 45,
});

const headingStyle = (isDisabled: boolean) => (theme: Theme) => css(
  isDisabled ? disabledStyle(theme) : {}, {
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
  });

const textStyle = css({
  fontSize: '14px',
});

const errorTextStyle = (theme: Theme) => css(textStyle, {
  color: theme.textColors.danger,
});

const disabledStyle = (theme: Theme) => css({
  opacity: 0.6,
  color: theme.textColors.disabled,
  cursor: 'not-allowed',
});

type ShareMapSelectItemProps = Readonly<{
  className?: string;
  error?: string;
  icon: IconProp;
  heading: ReactNode;
  description: string;
  onClick?: () => void;
}>;

export const ShareMapSelectItemComponent: FC<ShareMapSelectItemProps> = (props) => {
  const theme = useTheme();

  const isDisabled = !props.onClick || !!props.error;

  return (
    <button
      className={props.className}
      css={contentStyle(isDisabled)}
      onClick={props.onClick}
      disabled={!props.onClick}
    >
      <IconTile theme={theme} css={isDisabled && disabledStyle}>
        <FontAwesomeIcon css={iconStyle} icon={props.icon} />
      </IconTile>

      <div>
        <div css={headingStyle(isDisabled)}>{props.heading}</div>
        {!props.error && <div css={textStyle}>{props.description}</div>}
        {props.error && <div css={errorTextStyle}>{props.error}</div>}
      </div>
    </button>
  );
};
