import { css } from '@emotion/react';
import {
  type FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
  useSections,
} from '~/_shared/baseComponents/accordion';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import { type MapInfo } from '~/_shared/types/map';
import { copy } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { ConnectivitySettingsSectionComponent } from '~/map/layered/createLayeredMapModal/ConnectivitySettingsSection/connectivitySettingsSection.component';
import { MatchupColumnsSectionContainer } from '~/map/layered/createLayeredMapModal/MatchupColumnsSection/matchupColumnsSection.container';
import { SelectMapsSectionContainer } from '~/map/layered/createLayeredMapModal/SelectMapsSection/selectMapsSection.container';
import { type BaseMapInfos } from '~/map/layered/layering.helpers';
import { type MapListingItem } from '~/map/listing/item/mapListingItem';
import { type MapIntersect } from '../../map.repository';
import {
  SelectMapAddOrRemoveOperation,
  type SelectMapsAddBaseMapProps,
  type SelectMapsRemoveBaseMapsProps,
} from '../createLayeredMapModal/SelectMapsSection/selectMapsSection.component';
import { LAYERED_MAP_MODAL_MAX_WIDTH } from '../layeredMap.constants';
import { LayeredMapNameAndDescriptionComponent } from '../layeredMapNameAndDescription.component';
import { MapConnectSettings } from '../mapConnectSettings';

export enum EditLayeredMapModalSection {
  MapsSelection = 0,
  Name = 1,
  ConnectivitySettings = 2,
  MatchUp = 3,
}

export type EditLayeredMapModalSettings = {
  mapName: string;
  selectedMapListItems: MapListingItem[];
  connectionSetting: MapConnectSettings | null;
  matches: MapIntersect[];
};

const getInitialConnectionSetting = (mapInfo: MapInfo | null) => (
  mapInfo?.layering?.connected
    ? mapInfo?.layering?.realTime
      ? MapConnectSettings.ConnectedInRealTime
      : MapConnectSettings.ConnectedWithApproval
    : MapConnectSettings.Disconnected
);

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

const overlayAnimationStyle = css({
  position: 'fixed',
});

type EditLayeredMapModalComponentProps = Readonly<{
  isOpen: boolean;
  isEditingLayeredMap: boolean;
  isMapLayered: boolean;
  mapInfo: MapInfo | null;
  currentBaseMaps: Readonly<BaseMapInfos>;

  onClose: () => void;
  onEditLayeredMap: (settings: EditLayeredMapModalSettings, deletedMaps: ReadonlySet<number>) => void;
}>;

export const EditLayeredMapModalComponent: FC<EditLayeredMapModalComponentProps> = memo((props) => {
  const {
    isOpen,
    mapInfo,
    currentBaseMaps,
    onClose,
    isEditingLayeredMap,
    onEditLayeredMap,
  } = props;

  const [mapName, setMapName] = useState(mapInfo?.name ?? '');
  const [mapDescription, setMapDescription] = useState(mapInfo?.description ?? '');
  const [connectionSetting, setConnectionSetting] = useState<MapConnectSettings>(getInitialConnectionSetting(mapInfo));
  const [isLoading, setIsLoading] = useState(false);
  const [mapNameInputRef, setMapNameInputRef] = useHookWithRefCallback<HTMLInputElement>();
  const [addOrRemoveOperation, setAddOrRemoveOperation] = useState<SelectMapAddOrRemoveOperation | null>(null);
  const [selectedMaps, setSelectedMaps] = useState<Array<MapListingItem | null>>([]);
  const [deletedMaps, setDeletedMaps] = useState<ReadonlySet<number>>(new Set());
  const layerAnimationColors = useLottieAnimationDefaultColors().Layer;
  const [t] = useTranslation();

  const { toggleSection, setExpandedSection, isExpanded } = useSections<EditLayeredMapModalSection>([EditLayeredMapModalSection.MapsSelection]);

  const isMapOverviewContinueEnabled = useMemo(() => !isTextEmpty(mapName), [mapName]);

  const onChangeOperation = useCallback((newValue: SelectMapAddOrRemoveOperation) => {
    setAddOrRemoveOperation(newValue);
    if (newValue === SelectMapAddOrRemoveOperation.ADD) {
      setDeletedMaps(new Set());
    }
    else {
      setSelectedMaps([]);
    }
  }, []);

  const onToggleBaseMap = useCallback((mapId: number) => {
    if (deletedMaps.has(mapId)) {
      setDeletedMaps(prev => copy.andRemove(prev, [mapId]));
    }
    else {
      setDeletedMaps(prev => copy.andAdd(prev, [mapId]));
    }
  }, [deletedMaps]);

  const onMapSelected = useCallback((map: MapListingItem, mapIndex: number) => {
    setSelectedMaps(maps => [...maps.slice(0, mapIndex), map, ...maps.slice(mapIndex + 1)]);
  }, []);

  const selectMapAddOrRemoveProps: SelectMapsRemoveBaseMapsProps | SelectMapsAddBaseMapProps = useMemo(() => {
    if (addOrRemoveOperation === SelectMapAddOrRemoveOperation.REMOVE) {
      return {
        operation: SelectMapAddOrRemoveOperation.REMOVE,
        deletedMaps,
        onChangeOperation,
        onToggleBaseMap,
      };
    }

    else {
      return {
        operation: SelectMapAddOrRemoveOperation.ADD,
        onChangeOperation,
      };
    }
  }, [addOrRemoveOperation, deletedMaps, onChangeOperation, onToggleBaseMap]);

  const matchupColumnsDisabledMapIds = useMemo(() => {
    if (addOrRemoveOperation === SelectMapAddOrRemoveOperation.REMOVE) {
      return new Set<number>(Object.values(currentBaseMaps).map(baseMapInfo => baseMapInfo.id));
    }
    return new Set<number>();
  }, [addOrRemoveOperation, currentBaseMaps]);

  const onMapOverviewContinue = useCallback(() =>
    setExpandedSection(EditLayeredMapModalSection.ConnectivitySettings),
  [setExpandedSection]);

  const selectMapSelectionSection = useCallback(() => {
    if (connectionSetting) {
      setExpandedSection(EditLayeredMapModalSection.MatchUp);
    }
  }, [connectionSetting, setExpandedSection]);

  const onSelectMapSubmit = useCallback(() => {
    setExpandedSection(EditLayeredMapModalSection.Name);
  }, [setExpandedSection]);

  const onSkipToMatchUp = useCallback(() => {
    setExpandedSection(EditLayeredMapModalSection.MatchUp);
  }, [setExpandedSection]);

  const selectedMapListItems = useMemo(() => {
    const newMapListItems: MapListingItem[] = [];
    for (const mapItem of selectedMaps) {
      if (mapItem) {
        newMapListItems.push(mapItem);
      }
    }
    return newMapListItems;
  }, [selectedMaps]);

  const onEditLayeredMapSubmit = useCallback((matches: MapIntersect[]) => {
    onEditLayeredMap({ matches, selectedMapListItems, mapName, connectionSetting }, deletedMaps);
  }, [onEditLayeredMap, selectedMapListItems, mapName, connectionSetting, deletedMaps]);

  useEffect(() => {
    if (!isOpen) {
      setMapName('');
      setMapDescription('');
      setConnectionSetting(getInitialConnectionSetting(mapInfo));
    }
  }, [isOpen, mapInfo]);

  useEffect(() => {
    if (isOpen && mapNameInputRef) {
      mapNameInputRef.focus();
    }
  }, [isOpen, mapNameInputRef]);

  const mapOverview: AccordionData = useMemo(() => ({
    header: t('Name Your Map'),
    isExpanded: isExpanded(EditLayeredMapModalSection.Name),
    isLocked: !isMapOverviewContinueEnabled,
    onHeadingClick: () => toggleSection(EditLayeredMapModalSection.Name),
    child: (
      <LayeredMapNameAndDescriptionComponent
        isContinueEnabled={isMapOverviewContinueEnabled}
        mapDescription={mapDescription}
        mapName={mapName}
        onContinue={onMapOverviewContinue}
        setMapDescription={setMapDescription}
        setMapName={setMapName}
        setMapNameInputRef={setMapNameInputRef}
      />
    ),
  }), [t, isExpanded, isMapOverviewContinueEnabled, mapDescription, mapName, onMapOverviewContinue,
    setMapNameInputRef, toggleSection]);

  const accordionData: AccordionData[] = useMemo(() => [
    {
      header: t('Add/Remove Maps to Your Layered Map'),
      isExpanded: isExpanded(EditLayeredMapModalSection.MapsSelection),
      isLocked: !connectionSetting,
      onHeadingClick: () => toggleSection(EditLayeredMapModalSection.MapsSelection),
      child: (
        <>
          {isExpanded(EditLayeredMapModalSection.MapsSelection) && (
            <SelectMapsSectionContainer
              currentBaseMaps={currentBaseMaps}
              isDisabled={false}
              selectedMaps={selectedMaps}
              selectMapAddOrRemove={selectMapAddOrRemoveProps}
              onChangeAddedMaps={setSelectedMaps}
              onMapSelected={onMapSelected}
              onSkipToMatchUp={onSkipToMatchUp}
              onSubmit={onSelectMapSubmit}
            />
          )}
        </>
      ),
    },
    mapOverview,
    {
      header: t('Connectivity Settings'),
      isExpanded: isExpanded(EditLayeredMapModalSection.ConnectivitySettings),
      isLocked: !isMapOverviewContinueEnabled,
      onHeadingClick: () => toggleSection(EditLayeredMapModalSection.ConnectivitySettings),
      child: (
        <ConnectivitySettingsSectionComponent
          onChange={setConnectionSetting}
          selected={connectionSetting}
          onContinue={selectMapSelectionSection}
          layering={mapInfo?.layering}
        />
      ),
    }, {
      header: t('Match-up Map Columns'),
      isExpanded: isExpanded(EditLayeredMapModalSection.MatchUp),
      isLocked: true,
      onHeadingClick: () => toggleSection(EditLayeredMapModalSection.MatchUp),
      child: (
        <>
          {isExpanded(EditLayeredMapModalSection.MatchUp) && (
            <MatchupColumnsSectionContainer
              currentBaseMaps={currentBaseMaps}
              disabledMapsIds={matchupColumnsDisabledMapIds}
              disableColumnNamesEditing={addOrRemoveOperation === SelectMapAddOrRemoveOperation.REMOVE}
              isSubmitting={isEditingLayeredMap}
              layeringMapInfo={mapInfo}
              mapName={mapName}
              maps={selectedMapListItems}
              onSubmit={onEditLayeredMapSubmit}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      ),
    },
  ], [t, isExpanded, connectionSetting, currentBaseMaps, selectedMaps, selectMapAddOrRemoveProps, onMapSelected,
    onSkipToMatchUp, onSelectMapSubmit, mapOverview, isMapOverviewContinueEnabled, selectMapSelectionSection, mapInfo,
    matchupColumnsDisabledMapIds, addOrRemoveOperation, isEditingLayeredMap, mapName, selectedMapListItems,
    onEditLayeredMapSubmit, toggleSection,
  ]);

  return (
    <ModalComponent
      onClose={onClose}
      isOpen={isOpen}
      caption={t('Edit Layered Map')}
      contentStyle={css({ padding: 0 })}
      maxWidth={LAYERED_MAP_MODAL_MAX_WIDTH}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={accordionData}
          itemButtonStyle={itemButtonStyle}
          showCount
        />
      </OutlinePanelComponent>
      {(isLoading || isEditingLayeredMap) && (
        <OverlayLottieAnimationComponent
          css={overlayAnimationStyle}
          type={LottieAnimationTypes.Layer}
          size={120}
          autoplay
          loop
          colors={layerAnimationColors}
        />
      )}
    </ModalComponent>
  );
});
