import { useMemo } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { initialStylingState } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.reducer';
import {
  type ContinuousOptions, type MapSettingsMapStylesStylers,
} from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  type BaseMapElementTypeName, type BaseMapFeatureTypeName, type BaseMapStylerVisibility,
} from '../baseMap.enums';

export type BaseMapOverriddenStylesList = ReadonlySet<BaseMapFeatureTypeName | BaseMapChangelistCombination>;
type BaseMapChangelistCombination = `${BaseMapFeatureTypeName}_${BaseMapElementTypeName}`;

export const useGetBaseMapOverriddenStyles = (): BaseMapOverriddenStylesList => {
  const mapStyling = useSelector(state => state.map.mapSettings.data.mapStyles);

  const currentThemeAndBaseStyles = useMemo(() => {
    // Here we have the option of either treating 'override' as any style change compared to:
    //  - base "Standard" style (current implementation)
    //  - base "Standard" style with current active theme (mapStyling.theme) styles applied on it
    //     - leaving the commented out code if we wanted to switch to later, or combination of the two
    // This will affect the 'dots' in advanced styles tab in map settings.

    /* const currentThemeStyles = baseMapThemes[mapStyling.theme as BaseMapThemeName];
      if (currentThemeStyles) {
        return initialMapStylesWithAppliedThemeStyles(currentThemeStyles);
      }
    */
    return initialStylingState;
  }, []);

  const currentThemeAndBaseStylesChangesSignatures = useMemo(() => {
    const signatures = new Set();
    Object.keys(currentThemeAndBaseStyles).forEach((featureName: BaseMapFeatureTypeName) => {
      const featureStyles = currentThemeAndBaseStyles[featureName];
      Object.keys(featureStyles).forEach((elementName: BaseMapElementTypeName) => {
        const elementStyles = featureStyles[elementName];
        Object.keys(elementStyles).forEach((elementStyle: keyof MapSettingsMapStylesStylers) => {
          const style = elementStyles[elementStyle];
          signatures.add(getChangedSignature(style, featureName, elementName, elementStyle));
        });
      });
    });
    return signatures;
  }, [currentThemeAndBaseStyles]);

  return useMemo(() => {
    const overridesList = new Set<BaseMapFeatureTypeName | BaseMapChangelistCombination>();

    Object.keys(mapStyling.styling).forEach((featureName: BaseMapFeatureTypeName) => {
      const featureStyles = mapStyling.styling[featureName];
      let featureHasElementOverridden = false;

      Object.keys(featureStyles).forEach((elementName: BaseMapElementTypeName) => {
        const elementStyles = featureStyles[elementName];

        const elementHasOverriddenStyle = Object.keys(elementStyles).some((elementStyle: keyof MapSettingsMapStylesStylers) => {
          const elementStyler = elementStyles[elementStyle];
          if (typeof elementStyler === 'string' || elementStyler.isActive) {
            return !currentThemeAndBaseStylesChangesSignatures.has(
              getChangedSignature(elementStyler, featureName, elementName, elementStyle)
            );
          }
          return false;
        });

        if (elementHasOverriddenStyle) {
          featureHasElementOverridden = true;
          overridesList.add(`${featureName}_${elementName}` as unknown as BaseMapChangelistCombination);
        }
      });

      if (featureHasElementOverridden) {
        overridesList.add(featureName);
      }
    });

    return overridesList;
  }, [currentThemeAndBaseStylesChangesSignatures, mapStyling.styling]);
};

const getChangedSignature = (
  value: BaseMapStylerVisibility | ContinuousOptions<string> | ContinuousOptions<number>,
  featureName: BaseMapFeatureTypeName,
  elementName: BaseMapElementTypeName,
  elementStyle: keyof MapSettingsMapStylesStylers,
): string => (
  featureName + '||' + elementName + '||' + elementStyle + '||'
    + (typeof value === 'string' ? value : value.value)
);
