import {
  put, takeLatest,
} from 'redux-saga/effects';
import { select } from '../../../../../_shared/utils/saga/effects';
import { type PickAction } from '../../../../../_shared/utils/types/action.type';
import { type BoundaryItemsAction } from '../../../../boundaryItems/boundaryItems.action';
import { BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS } from '../../../../boundaryItems/boundaryItems.actionTypes';
import { type BoundaryTerritoryGroupsAction } from '../../../../boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS } from '../../../../boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { boundaryDrawDeactivate } from './boundaryDraw.actionCreators';
import { boundaryDrawSelector } from './boundaryDraw.selectors';
import {
  BoundaryDrawMode, type BoundaryDrawState, DrawType,
} from './boundaryDraw.state';

export function* boundaryDrawSagas() {
  yield takeLatest(BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS, onBoundaryTerritoryGroupRemove);
  yield takeLatest(BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS, onBoundaryItemRemove);
}

function* onBoundaryTerritoryGroupRemove(action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS>) {
  const boundaryDrawState: BoundaryDrawState = yield select<BoundaryDrawState>(boundaryDrawSelector);

  if (boundaryDrawState.boundaryGroupId === action.payload.boundaryTerritoryGroup.boundaryGroupId) {
    yield deactivateBoundaryDraw();
  }
}

function *onBoundaryItemRemove(action: PickAction<BoundaryItemsAction, typeof BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS>) {
  const boundaryDrawState: BoundaryDrawState = yield select<BoundaryDrawState>(boundaryDrawSelector);

  if (
    boundaryDrawState.drawType === DrawType.Drag &&
    boundaryDrawState.drawModeData.type === BoundaryDrawMode.BoundaryPolygonEdit &&
    boundaryDrawState.drawModeData.boundaryId === action.payload.boundaryId
  ) {
    yield deactivateBoundaryDraw();
  }
}

function* deactivateBoundaryDraw() {
  yield put(boundaryDrawDeactivate());
}
