import { type ThemeType } from '~/_shared/themes/themeType';

export const themeTypes: ThemeType[] = [
  'light',
  'dark',
];

export type PerTheme<T> = Partial<{
  [theme in ThemeType]: T;
}>;
