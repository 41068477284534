import { type ActiveMapElementsAction } from '../../activeMapElements/activeMapElements.action';
import {
  ACTIVE_MAP_ELEMENTS_CLEAR_STATE,
  ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST,
} from '../../activeMapElements/activeMapElements.actionTypes';
import { type LocationFinderAction } from './locationFinder.action';
import { FRONTEND_STATE_LOCATION_HOVERED } from './locationFinder.actionTypes';
import { type LocationFinderState } from './locationFinder.state';

const initialState: LocationFinderState = {
  hoveredMarkerId: null,
};

export const locationFinderReducer = (
  state = initialState, action: LocationFinderAction | ActiveMapElementsAction
): LocationFinderState => {
  switch (action.type) {

    case FRONTEND_STATE_LOCATION_HOVERED:
      return {
        ...state,
        hoveredMarkerId: action.payload.rowId,
      };

    case ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST:
    case ACTIVE_MAP_ELEMENTS_CLEAR_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};
