import {
  DropDownItemSize,
  RegularDropdownComponent,
  type RegularDropdownProps,
} from '~/_shared/baseComponents/dropdown';
import { InputSize } from '~/_shared/baseComponents/inputs';

type ProximityPanelDropdownProps<T> = Pick<RegularDropdownProps<T>, 'value' | 'onChange' | 'options' | 'triggerStyle' |
'itemStyle' | 'placeholder' | 'isDisabled'>;

export const ProximityPanelDropdownComponent = <T extends unknown>(props: ProximityPanelDropdownProps<T>) => {

  return (
    <RegularDropdownComponent
      {...props}
      inputSize={InputSize.Medium}
      itemSize={DropDownItemSize.Large}
      inPortal
    />
  );
};
