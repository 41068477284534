import { type MapSettingsDataAction } from '~/store/mapSettings/data/mapSettingsData.action';
import {
  MAP_SETTINGS_SYNC_CANCEL, MAP_SETTINGS_SYNC_ERROR, MAP_SETTINGS_SYNC_REQUEST, MAP_SETTINGS_SYNC_SUCCESS,
} from '~/store/mapSettings/data/mapSettingsData.actionTypes';
import { type SpreadsheetDataAction } from '~/store/spreadsheetData/spreadsheetData.action';
import {
  SPREADSHEET_FETCH_DATA_ERROR, SPREADSHEET_FETCH_DATA_STARTED, SPREADSHEET_FETCH_DATA_SUCCESS,
} from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { type MapAction } from '../../map/map.action';
import {
  MAP_PAGE_INIT, MAP_RESET,
} from '../../map/map.actionTypes';
import { type FileUrlsAction } from '../fileUrls/fileUrls.action';
import { FILE_ATTACHMENTS_URLS_SYNCED } from '../fileUrls/fileUrls.actionTypes';
import { type BoundaryRenderingAction } from '../mapTools/boundary/boundaryRendering/boundaryRendering.action';
import { BOUNDARY_RENDERING_STOP } from '../mapTools/boundary/boundaryRendering/boundaryRendering.actionTypes';
import { type ProcessingAction } from './processing.action';
import {
  FRONTEND_STATE_PROCESSING_BOUNDARIES_INITIALIZED,
  FRONTEND_STATE_PROCESSING_EXPORT_IMAGE_MODE_INITIALIZED,
  FRONTEND_STATE_PROCESSING_GOOGLE_SCRIPT_INITIALIZED,
  FRONTEND_STATE_PROCESSING_IMAGE_EXPORT_IN_PROGRESS,
  FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_START,
  FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_STOP,
  FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM,
} from './processing.actionTypes';
import { type ProcessingState } from './processing.state';

const initialState: ProcessingState = {
  mapSettingsSyncInProgress: false,
  mapSettingsSyncFailed: false,
  spreadsheetDataFetchingInProgress: false,
  isGoogleScriptInitialized: false,
  imageExportInProgress: false,
  initialMarkersRendered: false,
  initialBoundariesRendered: false,
  renderingMarkers: false,
  areFileAttachmentsSynced: false,
  firstZoomLevel: null,
  exportImageModeInitialized: false,
};

type Action = ProcessingAction | FileUrlsAction | BoundaryRenderingAction | MapAction | MapSettingsDataAction | SpreadsheetDataAction;

export const processingReducer = (state = initialState, action: Action): ProcessingState => {
  switch (action.type) {
    case MAP_SETTINGS_SYNC_REQUEST: {
      return {
        ...state,
        mapSettingsSyncInProgress: true,
        mapSettingsSyncFailed: false,
      };
    }

    case MAP_SETTINGS_SYNC_SUCCESS:
    case MAP_SETTINGS_SYNC_CANCEL: {
      return {
        ...state,
        mapSettingsSyncInProgress: false,
      };
    }

    case MAP_SETTINGS_SYNC_ERROR: {
      return {
        ...state,
        mapSettingsSyncInProgress: false,
        mapSettingsSyncFailed: true,
      };
    }

    case FRONTEND_STATE_PROCESSING_GOOGLE_SCRIPT_INITIALIZED:
      return {
        ...state,
        isGoogleScriptInitialized: true,
      };

    case SPREADSHEET_FETCH_DATA_STARTED: {
      return {
        ...state,
        spreadsheetDataFetchingInProgress: true,
      };
    }

    case SPREADSHEET_FETCH_DATA_SUCCESS:
    case SPREADSHEET_FETCH_DATA_ERROR: {
      return {
        ...state,
        spreadsheetDataFetchingInProgress: false,
      };
    }

    case FRONTEND_STATE_PROCESSING_IMAGE_EXPORT_IN_PROGRESS: {
      return {
        ...state,
        imageExportInProgress: action.payload.inProgress,
      };
    }

    case FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_START: {
      return {
        ...state,
        renderingMarkers: true,
      };
    }

    case FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_STOP: {
      return {
        ...state,
        renderingMarkers: false,
        initialMarkersRendered: true,
      };
    }

    case FILE_ATTACHMENTS_URLS_SYNCED: {
      return {
        ...state,
        areFileAttachmentsSynced: true,
      };
    }

    case FRONTEND_STATE_PROCESSING_BOUNDARIES_INITIALIZED:
    case BOUNDARY_RENDERING_STOP: {
      return {
        ...state,
        initialBoundariesRendered: true,
      };
    }

    case FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM: {
      return {
        ...state,
        firstZoomLevel: action.payload.newValue,
      };
    }

    case FRONTEND_STATE_PROCESSING_EXPORT_IMAGE_MODE_INITIALIZED: {
      return {
        ...state,
        exportImageModeInitialized: true,
      };
    }

    case MAP_RESET: {
      return {
        ...state,
        areFileAttachmentsSynced: initialState.areFileAttachmentsSynced,
        mapSettingsSyncInProgress: initialState.mapSettingsSyncInProgress,
        mapSettingsSyncFailed: initialState.mapSettingsSyncFailed,
        initialMarkersRendered: initialState.initialMarkersRendered,
        initialBoundariesRendered: initialState.initialBoundariesRendered,
        renderingMarkers: initialState.renderingMarkers,
        firstZoomLevel: initialState.firstZoomLevel,
      };
    }

    case MAP_PAGE_INIT: {
      return {
        ...state,
        initialMarkersRendered: false,
        initialBoundariesRendered: false,
        renderingMarkers: false,
      };
    }

    default:
      return state;
  }
};
