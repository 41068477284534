import {
  type MainMenuItem, MainMenuItems,
} from './mainMenuItem.enum';

export const topPartItems: ReadonlyArray<MainMenuItem> = [
  MainMenuItems.MapTools,
  MainMenuItems.MapSettings,
  MainMenuItems.SaveMapView,
  MainMenuItems.Present,
  MainMenuItems.ShareMap,
  MainMenuItems.ShareMapAndPresent,
  MainMenuItems.SplitScreen,
  MainMenuItems.FilterData,
  MainMenuItems.SyncData,
  MainMenuItems.ImportNewData,
  MainMenuItems.ExportData,
  MainMenuItems.AddNew,
  MainMenuItems.AddNewDataRow,
  MainMenuItems.AddNewDataColumn,
  MainMenuItems.RemoveDataRow,
  MainMenuItems.MatchMyLocationData,
  MainMenuItems.Grouping,
  MainMenuItems.MapLegend,
  MainMenuItems.Filter,
  MainMenuItems.Proximity,
  MainMenuItems.Routing,
  MainMenuItems.Boundary,
  MainMenuItems.HeatMap,
];

export const bottomPartItems: ReadonlyArray<MainMenuItem> = [
  MainMenuItems.AccountSettings,
  MainMenuItems.Help,
  MainMenuItems.Account,
  MainMenuItems.LogOut,
];

export const menuItemFontSize: { [key in MainMenuItem]?: string } = {
  [MainMenuItems.FilterData]: '14px',
  [MainMenuItems.ImportNewData]: '14px',
  [MainMenuItems.ExportData]: '14px',
  [MainMenuItems.SyncData]: '14px',
  [MainMenuItems.AddNewDataRow]: '14px',
  [MainMenuItems.RemoveDataRow]: '14px',
  [MainMenuItems.AddNewDataColumn]: '14px',
  [MainMenuItems.MatchMyLocationData]: '14px',
};
