import {
  useCallback,
  useRef,
  useState,
} from 'react';
import { delay } from '../delay';
import { type CancellablePromise } from '../types/common.type';

export const useTimeout = (ms: number) => {
  const timeoutPromise = useRef<CancellablePromise | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const invoker = useCallback(() => {
    timeoutPromise.current?.cancel();
    setIsWaiting(true);
    timeoutPromise.current = delay(ms).then(() => setIsWaiting(false));
  }, [ms]);

  return [isWaiting, invoker] as const;
};
