import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';

export enum MarkersSelectType {
  Click = 'Click',
  Lasso = 'Lasso',
}

type MarkersSelectCommonState = Readonly<{
  selectType: MarkersSelectType;
  lastSavedMarkerIds: SpreadsheetRowId[] | null;
}>;

export type MarkersSelectInactiveState = MarkersSelectCommonState & Readonly<{
  isActive: false;
  isLassoSelectLoading: false;
  selectedMarkerIds: null;
}>;

export type MarkersSelectActiveState = MarkersSelectCommonState & Readonly<{
  isActive: true;
  selectedMarkerIds: SpreadsheetRowId[];
  isLassoSelectLoading: boolean;
}>;

export type MarkersSelectState = MarkersSelectInactiveState | MarkersSelectActiveState;

export const isMarkersSelectActive = (
  state: MarkersSelectState
): state is MarkersSelectActiveState => state.isActive;
