import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';

export const MainMenuItems = {
  MapTools: 'MapTools',
  MapSettings: 'MapSettings',
  SaveMapView: 'SaveMapView',
  Present: 'Present',
  ShareMap: 'ShareMap',
  ShareMapAndPresent: 'ShareMapAndPresent',
  SplitScreen: 'SplitScreen',
  Location: 'Location',
  Search: 'Search',
  NearMe: 'NearMe',

  FilterData: 'FilterData',
  ImportNewData: 'ImportNewData',
  ExportData: 'ExportData',
  SyncData: 'SyncData',
  AddNewDataRow: 'AddNewDataRow',
  AddNew: 'AddNew',
  RemoveDataRow: 'RemoveDataRow',
  AddNewDataColumn: 'AddNewDataColumn',
  MatchMyLocationData: 'MatchMyLocationData',

  Account: 'Account',
  AccountSettings: 'AccountSettings',
  Help: 'Help',
  LogOut: 'LogOut',

  Grouping: MapTool.Grouping,
  MapLegend: MapTool.MapLegend,
  Filter: MapTool.Filter,
  Proximity: MapTool.Proximity,
  Routing: MapTool.Routing,
  Boundary: MapTool.Boundary,
  HeatMap: MapTool.HeatMap,
} as const;

export type MainMenuItem = typeof MainMenuItems[keyof typeof MainMenuItems];
