import { createNanoEvents } from 'nanoevents';
import {
  getValueOfAnimatableColorProperty, getValueOfAnimatableNumericalProperty,
} from '~/_shared/types/animation/animatableProperty.helpers';
import {
  type AnimatableColorProperty, type AnimatableNumericalProperty,
} from '~/_shared/types/animation/animatableProperty.types';
import { type LatLngBounds } from '~/_shared/types/latLng';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { flipPolygonsAndHoles } from '~/_shared/types/polygon/polygon.utils';
import {
  registerAnimationCallback, removeAnimationCallback,
} from '~/_shared/utils/colorAnimationTicker/colorAnimationTicker';
import {
  convertColorToWebGLColor, mixColorMemoized,
} from '~/_shared/utils/colors/colors.helpers';
import { debounce } from '~/_shared/utils/debounce';
import { getLabelStroke } from '~/_shared/utils/labels/labels.helpers';
import { Pool } from '~/_shared/utils/pooling/pool';
import { throttle } from '~/_shared/utils/throttle/throttle';
import {
  isNullOrUndefined, notNullsy,
} from '~/_shared/utils/typeGuards';
import {
  createLabelWithBodyVirtualLayer, type LabelWithBodyVirtualLayer, type WebglOverlayLabelWithBody,
} from '~/_shared/utils/webgl/labelWithBody';
import { BOUNDARY_OUTLINE_MAXIMUM_NUMBER_OF_POLYGONS } from '~/boundary/boundary.constants';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { type BoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import { getRequestMapZoomFromZoomLevels } from '~/store/boundaryItems/boundaryItems.helpers';
import {
  type BoundaryMultiPolygon, type BoundaryStateItem,
} from '~/store/boundaryItems/boundaryItems.state';
import {
  spreadBoundaryGroupLabelZIndex, spreadBoundaryGroupZIndex,
} from './boundaryView/mapBoundaryStyles/useMapBoundaryZIndexes';

const DEBUG_LABEL_BOUNDS = false;
const GLOBAL_BOUNDS_POLYGON_PATH: PolygonPath = [
  { lat: 85.08, lng: -2000 },
  { lat: 85.08, lng: 2000 },
  { lat: -85.08, lng: 2000 },
  { lat: -85.08, lng: -2000 },
];

type EventCallback = (boundaryIdentifier: BoundaryIdentifier) => void;

type RegisteredCallbacks = Readonly<{
  eventName: MapElementEventName;
  originalCallback: EventCallback;
  boundCallback: () => void;
}>;

type BoundaryGroupId = number;
type BoundaryId = number;

const AnimatableNumericBoundaryProperties = [
  'fillOpacity',
  'borderOpacity',
] as const;

const AnimatableColorBoundaryProperties = [
  'borderColor',
] as const;

const AnimatableBoundaryProperties = [
  ...AnimatableNumericBoundaryProperties,
  ...AnimatableColorBoundaryProperties,
] as const;

export type AnimatableNumericBoundaryProperty = typeof AnimatableNumericBoundaryProperties[number];
export type AnimatableColorBoundaryProperty = typeof AnimatableColorBoundaryProperties[number];
export type AnimatableBoundaryProperty = typeof AnimatableBoundaryProperties[number];

type BoundaryWebGlObjects = {
  polygons: WebglOverlayPolygon[];
  outlinePolygons: WebglOverlayPolygon[];
  label: WebglOverlayLabelWithBody | null;
  labelBoundsPolygon: WebglOverlayPolygon | null;
};

type BoundaryPath = Readonly<{
  multiPolygon: BoundaryMultiPolygon;
  zoomLevel: number;
}>;

type BoundaryLabel = Readonly<{
  text: string;
  bounds: LatLngBounds | null;
}>;

type BoundaryItem = {
  id: BoundaryId;
  webGlObjects: BoundaryWebGlObjects;
  path?: BoundaryPath;
  registeredCallbacks: ReadonlyArray<RegisteredCallbacks>;
  style: BoundaryItemStyle;
  label?: BoundaryLabel;
};

type EventListeners = ReadonlyArray<Readonly<{eventName: MapElementEventName; callback: EventCallback}>>;

type BoundaryGroupItem = {
  boundaryGroupId: number;
  items: Map<BoundaryId, BoundaryItem>;
  baseStyle: BoundaryItemStyle;
};

type BoundaryGroupMap = Map<BoundaryGroupId, BoundaryGroupItem>;

export type BoundaryItemStyle = Readonly<{
  fillColor: string;
  fillOpacity: AnimatableNumericalProperty;
  borderColor: AnimatableColorProperty;
  borderOpacity: AnimatableNumericalProperty;
  borderWidth: number;
  zIndex: BoundaryZIndexes;
  showPolygonOutline: boolean;
}>;

export type BoundaryZIndexes = {
  polygon: number;
  border: number;
  label: number;
  polygonOutline?: number;
  polygonOutlineBorder?: number;
};

type BoundaryLayers = Readonly<{
  BoundaryPolygons: PolygonLayer;
  BoundaryPolygonOutlines?: PolygonLayer;
  BoundaryPolygonLabelsBackground?: TextBoxCalloutLayer;
  BoundaryPolygonLabelsText?: LabelsLayer;
}>;

type LabelTextConfig = Readonly<{
  fontBase: number;
  lineHeight: number;
  font: string;
  minFontSize: number;
  maxFontSize: number;
  letterSpacing: number;
  paddingVertical: number;
  paddingHorizontal: number;
}>;

export type BoundaryTextDimensionsCalculator = Readonly<{
  calculateMinHeight: (linesCount?: number) => number;
  calculateMinWidth: (text: string) => number;
}>;

const defaultStyles: BoundaryItemStyle = {
  fillColor: '#000',
  fillOpacity: { value: 0 },
  borderColor: { value: '#000' },
  borderOpacity: { value: .5 },
  borderWidth: .5,
  zIndex: {
    polygon: 0,
    border: 1,
    label: 2,
  },
  showPolygonOutline: false,
};

const labelTextDefaults = {
  font: 'arial',
  minFontSize: 6,
  maxFontSize: 14,
  letterSpacing: 0,
  backgroundColor: '#30839f',
  paddingVertical: 4,
  paddingHorizontal: 6,
};

export type BoundaryLayerEvents = {
  onBusy: () => void;
  onIdle: () => void;
  onFirstRender: () => void;
};

export class MapBoundaryManager {
  private firstRenderDone = false;
  private readonly overlayLayers: Readonly<{
    BoundaryPolygons: PolygonLayer;
    BoundaryPolygonOutlines?: PolygonLayer;
    BoundaryLabels?: LabelWithBodyVirtualLayer;
  }>;
  private boundaries: BoundaryGroupMap = new Map();
  private eventListeners: EventListeners = [];

  private emitIdleDebounced = debounce(() => {
    this.eventEmitter.emit('onIdle');
    if (!this.firstRenderDone) {
      this.eventEmitter.emit('onFirstRender');
      this.firstRenderDone = true;
    }
  }, 500);
  private emitBusyThrottled = throttle(() => {
    this.emitIdleDebounced.cancel();
    this.eventEmitter.emit('onBusy');
  }, 500, { leading: true, trailing: false });

  private outlinePolygonPool: Pool<WebglOverlayPolygon, [WebglOverlayPolygonConfig, Points[][]]>;

  private animatedBoundaries: Map<BoundaryId, [BoundaryItem, Set<AnimatableBoundaryProperty>]> = new Map();

  private animationPercent: number = 0;

  private isolateColor: string = '#fff';
  private isolatePolygon: Readonly<{ hash: string; polygon: WebglOverlayPolygon | null}> = { hash: '', polygon: null };
  private isolateBoundaries: ReadonlyMap<BoundaryGroupId, ReadonlySet<BoundaryId>> = new Map();
  private isolatePolygonZIndex: number | null = null;

  public textDimensionsCalculator: BoundaryTextDimensionsCalculator | undefined;
  public eventEmitter = createNanoEvents<BoundaryLayerEvents>();

  constructor(overlayLayers: BoundaryLayers) {
    this.overlayLayers = {
      BoundaryPolygons: overlayLayers.BoundaryPolygons,
      BoundaryPolygonOutlines: overlayLayers.BoundaryPolygonOutlines,
      BoundaryLabels: overlayLayers.BoundaryPolygonLabelsText && overlayLayers.BoundaryPolygonLabelsBackground
        ? createLabelWithBodyVirtualLayer(overlayLayers.BoundaryPolygonLabelsText, overlayLayers.BoundaryPolygonLabelsBackground)
        : undefined,
    };

    this.initializeLabelTextconfig(overlayLayers.BoundaryPolygonLabelsText);

    this.overlayLayers.BoundaryPolygons.addEventListener('BeforeItemAdd', this.emitBusyThrottled);
    this.overlayLayers.BoundaryPolygons.addEventListener('Updated', this.emitIdleDebounced);

    this.initializeStylesAnimationTimeout();
    this.outlinePolygonPool = initializeOutlinePolygonPool(overlayLayers.BoundaryPolygonOutlines);
  }

  public updateBoundaryGroupPolygons({ boundaryGroup, boundariesData, mapZoomLevel, filter, labelFilter }:
  {
    boundaryGroup: BoundaryGroup;
    boundariesData: ReadonlyMap<number, BoundaryStateItem>;
    mapZoomLevel: number;
    filter: (boundary: BoundaryStateItem) => boolean;
    labelFilter: (boundary: BoundaryStateItem) => BoundaryLabel | null;
  }) {
    const requestedZoomLevel = this.getBoundaryZoomLevel(boundaryGroup.zoomLevels, mapZoomLevel);
    if (requestedZoomLevel === null) {
      return;
    }

    let allPolygonsFilteredOut = true;

    for (const boundaryData of boundariesData.values()) {
      const { boundaryItem, boundaryGroupItem } = this.ensureBoundaryItem(boundaryGroup.id, boundaryData.id);
      const boundaryPassedFilter = filter(boundaryData);

      // Only execute label filter when polygon goes through the filter
      const labelData = boundaryPassedFilter ? labelFilter(boundaryData) : null;
      const labelPassedFilter = !!labelData;

      const visible = !!boundaryItem.webGlObjects.polygons.length;
      const labelVisible = !!boundaryItem.webGlObjects.label;

      const shouldRemove = visible && !boundaryPassedFilter;
      const path = this.getBoundaryPathForZoom(requestedZoomLevel, boundaryData);

      if (shouldRemove || !path) {
        this.removePolygons(boundaryItem);
        this.removeOutlinePolygons(boundaryItem);
        this.removeLabel(boundaryItem);
        continue;
      }

      const shouldRemoveLabel = labelVisible && !labelPassedFilter;
      if (shouldRemoveLabel) {
        this.removeLabel(boundaryItem);
      }

      if (!visible && path.zoomLevel < requestedZoomLevel) {
        // requested detail is not available and boundary was not yet rendered with lower detail
        // if it was already rendered with lower detail we would want to keep it updated
        continue;
      }

      if (boundaryPassedFilter) {
        allPolygonsFilteredOut = false;
        this.updatePolygons(boundaryItem, boundaryGroupItem, path);

        if (DEBUG_LABEL_BOUNDS) {
          this.updateLabelBoundsPolygon(boundaryItem, boundaryData);
        }
      }

      if (labelPassedFilter) {
        this.updateLabel(boundaryItem, boundaryGroupItem, labelData);
      }
    }

    this.updateIsolatePolygons();

    // Emit first render event when boundaries data are available but all polygons are filtered out
    if (boundariesData.size && allPolygonsFilteredOut && !this.firstRenderDone) {
      this.eventEmitter.emit('onFirstRender');
      this.firstRenderDone = true;
    }
  }

  public updateBoundaryGroupStyles({ boundaryGroupId, baseStyle, overrides, ignoreBoundaryIds }: {
    boundaryGroupId: number;
    baseStyle: BoundaryItemStyle;
    overrides: ReadonlyMap<number, BoundaryItemStyle> | undefined;
    ignoreBoundaryIds: ReadonlySet<number>;
  }) {
    const boundaryGroupItem = this.ensureBoundaryGroupItem(boundaryGroupId);

    boundaryGroupItem.baseStyle = baseStyle;

    for (const boundary of boundaryGroupItem.items.values()) {
      if (ignoreBoundaryIds.has(boundary.id)) {
        continue;
      }

      boundary.style = baseStyle;
    }

    overrides?.forEach((style, boundaryId) => {
      if (ignoreBoundaryIds.has(boundaryId)) {
        return;
      }

      const { boundaryItem } = this.ensureBoundaryItem(boundaryGroupId, boundaryId);
      boundaryItem.style = style;
    });

    for (const boundary of boundaryGroupItem.items.values()) {
      this.applyBoundaryItemStyles(boundary);
    }
  }

  public updateBoundaryStyles({ boundaryGroupId, styles, ignoreBoundaryIds }: {
    boundaryGroupId: number;
    styles: ReadonlyMap<number, BoundaryItemStyle>;
    ignoreBoundaryIds: ReadonlySet<number>;
  }) {
    styles.forEach((style, boundaryId) => {
      if (ignoreBoundaryIds.has(boundaryId)) {
        return;
      }

      const { boundaryItem } = this.ensureBoundaryItem(boundaryGroupId, boundaryId);
      boundaryItem.style = style;

      this.applyBoundaryItemStyles(boundaryItem);
    });
  }

  public updateBoundaryStyle(boundaryGroupId: number, boundaryId: number, style: BoundaryItemStyle) {
    const { boundaryItem } = this.ensureBoundaryItem(boundaryGroupId, boundaryId);
    boundaryItem.style = style;

    this.applyBoundaryItemStyles(boundaryItem);
  }

  public updateIsolateColor(color: string) {
    if (this.isolateColor !== color) {
      this.isolateColor = color;
      this.isolatePolygon.polygon?.fillColor.set(...convertColorToWebGLColor(this.isolateColor, 1));
    }
  }

  public updateIsolateBoundaries(isolateBoundaries: ReadonlyMap<BoundaryGroupId, ReadonlySet<BoundaryId>>, coverPolygonZIndex?: number) {
    this.isolateBoundaries = isolateBoundaries;
    if (coverPolygonZIndex) {
      this.isolatePolygonZIndex = coverPolygonZIndex;
    }
    this.updateIsolatePolygons();
  }

  public addBoundaryEventListener(eventName: MapElementEventName, callback: EventCallback) {
    this.eventListeners = [...this.eventListeners.filter(e => e.eventName !== eventName), { eventName, callback }];

    this.boundaries.forEach((boundaryGroup, boundaryGroupId) => {
      boundaryGroup.items
        .forEach(boundary => registerBoundaryEventListener(boundaryGroupId, boundary, eventName,
          callback, { polygons: true, label: true }));
    });
  }

  public removeBoundaryEventListener = (eventName: MapElementEventName, callback: EventCallback) => {
    this.eventListeners = this.eventListeners.filter(e => e.eventName !== eventName);

    this.boundaries.forEach((boundaryGroup) => {
      boundaryGroup.items.forEach(boundary => removeBoundaryEventListener(boundary, eventName, callback));
    });
  };

  public removeUnusedBoundaryGroups(boundaryGroupIds: Set<number>) {
    const currentBoundaryGroupIdKeys = this.boundaries.keys();

    for (const boundaryGroupId of currentBoundaryGroupIdKeys) {
      if (!boundaryGroupIds.has(boundaryGroupId)) {
        this.removeBoundaryGroupItems(boundaryGroupId);
      }
    }
  }

  public removeBoundary(boundaryIdentifier: BoundaryIdentifier) {
    const boundaryGroup = this.boundaries.get(boundaryIdentifier.boundaryGroupId);
    if (!boundaryGroup) {
      return;
    }

    const boundary = boundaryGroup.items.get(boundaryIdentifier.boundaryId);

    if (!boundary) {
      return;
    }

    this.removeBoundaryItem(boundaryGroup, boundary);
  }

  public dispose() {
    this.animatedBoundaries = new Map();
    removeAnimationCallback(this.animateBoundaries);

    this.boundaries.forEach((_, boundaryGroupId) => this.removeBoundaryGroupItems(boundaryGroupId));
    this.boundaries = new Map();
    this.eventListeners = [];

    this.overlayLayers.BoundaryPolygons.removeEventListener('BeforeItemAdd', this.emitBusyThrottled);
    this.overlayLayers.BoundaryPolygons.removeEventListener('Updated', this.emitIdleDebounced);

    this.outlinePolygonPool.dispose();
  }

  private getBoundaryPathForZoom(requestedZoomLevel: number, pathData: BoundaryStateItem, fallbackToLowerZoom = true) {
    let zoomLevel = requestedZoomLevel;
    let boundaryPath = pathData.paths.get(requestedZoomLevel);

    if (!boundaryPath && fallbackToLowerZoom) {
      const fallback = Array.from(pathData.paths.entries())
        .filter(([zoom, _]) => zoom < requestedZoomLevel)
        .sort(([zoomA, _], [zoomB, __]) => zoomA - zoomB)
        .lastItem;

      if (!fallback) {
        return;
      }

      const [fallbackZoom, fallbackPath] = fallback;

      boundaryPath = fallbackPath;
      zoomLevel = fallbackZoom;

    }

    if (!boundaryPath) {
      return null;
    }

    return {
      multiPolygon: boundaryPath,
      zoomLevel,
    };
  }

  private ensureBoundaryGroupItem(boundaryGroupId: number): BoundaryGroupItem {
    let groupItem = this.boundaries.get(boundaryGroupId);

    if (!groupItem) {
      groupItem = { boundaryGroupId, items: new Map(), baseStyle: defaultStyles };
      this.boundaries.set(boundaryGroupId, groupItem);
    }

    return groupItem;
  }

  private ensureBoundaryItem(boundaryGroupId: number, boundaryId: number) {
    const boundaryGroupItem = this.ensureBoundaryGroupItem(boundaryGroupId);

    let boundaryItem = boundaryGroupItem.items.get(boundaryId);
    if (!boundaryItem) {
      boundaryItem = {
        id: boundaryId,
        webGlObjects: {
          polygons: [],
          outlinePolygons: [],
          label: null,
          labelBoundsPolygon: null,
        },
        style: boundaryGroupItem.baseStyle,
        registeredCallbacks: [],
      };

      boundaryGroupItem.items.set(boundaryId, boundaryItem);
    }

    return { boundaryItem, boundaryGroupItem };
  }

  private getBoundaryZoomLevel(boundaryMapZoomLevels: number[], currentMapZoomLevel: number): number | null {
    if (boundaryMapZoomLevels.length === 0) {
      return null;
    }

    return getRequestMapZoomFromZoomLevels(currentMapZoomLevel, boundaryMapZoomLevels);
  }

  private removeBoundaryGroupItems(boundaryGroupId: number) {
    const boundaryGroup = this.boundaries.get(boundaryGroupId);

    if (!boundaryGroup) {
      return;
    }

    boundaryGroup.items.forEach((boundary) => {
      this.removeBoundaryItem(boundaryGroup, boundary);
    });

    this.boundaries.delete(boundaryGroupId);
  }

  private removeBoundaryItem(boundaryGroup: BoundaryGroupItem, boundary: BoundaryItem) {
    this.removePolygons(boundary);
    this.removeOutlinePolygons(boundary);
    this.removeLabel(boundary);

    this.animatedBoundaries.delete(boundary.id);
    boundaryGroup.items.delete(boundary.id);
  }

  private updatePolygons(boundaryItem: BoundaryItem, boundaryGroupItem: BoundaryGroupItem, path: BoundaryPath) {
    const { webGlObjects } = boundaryItem;

    const shouldUpdate = boundaryItem.path?.multiPolygon !== path.multiPolygon
      || boundaryItem.path?.zoomLevel !== path.zoomLevel;

    if (!shouldUpdate) {
      return;
    }

    boundaryItem.path = path;

    for (const polygon of webGlObjects.polygons) {
      this.overlayLayers.BoundaryPolygons.remove(polygon);
    }

    webGlObjects.polygons = [];

    for (const polygonPath of boundaryItem.path?.multiPolygon ?? []) {
      const polygon = new WebGLOverlay.Polygon(
        initializePolygonProperties(boundaryItem, this.animationPercent),
        [[polygonPath.path, ...polygonPath.holes]],
      );
      webGlObjects.polygons.push(polygon);
      this.overlayLayers.BoundaryPolygons.add(polygon);
    }

    this.eventListeners.forEach(e => {
      registerBoundaryEventListener(boundaryGroupItem.boundaryGroupId, boundaryItem,
        e.eventName, e.callback, { polygons: true });
    });

    this.updateOutlinePolygons(boundaryItem);
  }

  private updateOutlinePolygons(boundaryItem: BoundaryItem) {
    if (!this.overlayLayers.BoundaryPolygonOutlines) {
      return;
    }

    const { webGlObjects, style } = boundaryItem;
    const numberOfPolygons = boundaryItem.path?.multiPolygon?.length ?? 0;

    for (const polygon of webGlObjects.outlinePolygons) {
      this.outlinePolygonPool.retireItem(polygon);
    }

    webGlObjects.outlinePolygons = [];

    if (!style.showPolygonOutline) {
      return;
    }

    if (isNullOrUndefined(style.zIndex.polygonOutline) || isNullOrUndefined(style.zIndex.polygonOutlineBorder)) {
      return;
    }

    const polygonPaths = numberOfPolygons <= BOUNDARY_OUTLINE_MAXIMUM_NUMBER_OF_POLYGONS
      ? boundaryItem.path?.multiPolygon ?? []
      : Array.from(boundaryItem.path?.multiPolygon ?? [])
        .sort((polygonA, polygonB) => (polygonB.boundsArea ?? 0) - (polygonA.boundsArea ?? 0))
        .slice(0, BOUNDARY_OUTLINE_MAXIMUM_NUMBER_OF_POLYGONS);

    for (const polygonPath of polygonPaths) {
      const polygon = this.outlinePolygonPool.getNextItem(
        {
          visible: true,
          interactive: false,
          border: true,
          borderWidth: 1.5,
          fillColor: convertColorToWebGLColor('#fff', 0),
          borderColor: convertColorToWebGLColor('#000'),
          zIndex: spreadBoundaryGroupZIndex(style.zIndex.polygonOutline, boundaryItem.id),
          borderZIndex: spreadBoundaryGroupZIndex(style.zIndex.polygonOutlineBorder, boundaryItem.id),
        },
        [[polygonPath.path, ...polygonPath.holes]],
      );
      webGlObjects.outlinePolygons.push(polygon);
    }
  }

  private updateIsolatePolygons() {
    if (!this.overlayLayers.BoundaryPolygonOutlines || !this.isolatePolygonZIndex) {
      return;
    }

    const boundaryItemsToIsolate: BoundaryItem[] = [];
    this.isolateBoundaries.forEach((boundaryIds, boundaryGroupId) => {
      boundaryIds.forEach(boundaryId => {
        const boundaryItem = this.boundaries.get(boundaryGroupId)?.items.get(boundaryId);
        if (boundaryItem) {
          boundaryItemsToIsolate.push(boundaryItem);
        }
      });
    });
    const newHash = boundaryItemsToIsolate.map((b) => `${b.id}-${b.path?.zoomLevel}`).join('.');

    if (newHash === this.isolatePolygon.hash) {
      return;
    }
    this.removeIsolatePolygons();

    const multiPolygon = boundaryItemsToIsolate.flatMap(boundaryItem => boundaryItem?.path?.multiPolygon).filter(notNullsy);

    if (boundaryItemsToIsolate.length === 0 || multiPolygon.length === 0) {
      this.isolatePolygon = { hash: '', polygon: null };
      return;
    }

    const flippedPolygons = flipPolygonsAndHoles(multiPolygon, GLOBAL_BOUNDS_POLYGON_PATH);
    const coverPolygonCoordinates = flippedPolygons.map(polygon => [
      polygon.path,
      ...polygon.holes,
    ]);

    const mainPolygon = new WebGLOverlay.Polygon(
      {
        visible: true,
        interactive: true,
        border: false,
        borderWidth: 0,
        fillColor: convertColorToWebGLColor(this.isolateColor, 1),
        borderColor: convertColorToWebGLColor('#000', 0),
        zIndex: this.isolatePolygonZIndex,
        borderZIndex: this.isolatePolygonZIndex,
      },
      coverPolygonCoordinates,
    );

    this.overlayLayers.BoundaryPolygonOutlines.add(mainPolygon);
    this.isolatePolygon = { hash: newHash, polygon: mainPolygon };
  }

  private removePolygons(boundary: BoundaryItem) {
    for (const polygon of boundary.webGlObjects?.polygons ?? []) {
      this.overlayLayers.BoundaryPolygons.remove(polygon);
    }

    boundary.webGlObjects.polygons = [];
    boundary.path = undefined;

    if (boundary.webGlObjects.labelBoundsPolygon) {
      this.overlayLayers.BoundaryPolygons.remove(boundary.webGlObjects.labelBoundsPolygon);
      boundary.webGlObjects.labelBoundsPolygon = null;
    }
  }

  private removeOutlinePolygons(boundary: BoundaryItem) {
    if (!this.overlayLayers.BoundaryPolygonOutlines) {
      return;
    }

    for (const polygon of boundary.webGlObjects?.outlinePolygons ?? []) {
      this.outlinePolygonPool.retireItem(polygon);
    }

    boundary.webGlObjects.outlinePolygons = [];
  }

  private removeIsolatePolygons() {
    if (!this.overlayLayers.BoundaryPolygonOutlines) {
      return;
    }

    if (this.isolatePolygon.polygon) {
      this.overlayLayers.BoundaryPolygonOutlines.remove(this.isolatePolygon.polygon);
    }

    this.isolatePolygon = { hash: '', polygon: null };
  }

  private updateLabel(boundaryItem: BoundaryItem, boundaryGroupItem: BoundaryGroupItem, labelData: BoundaryLabel) {
    if (!areLabelsEnabled(this.overlayLayers)) {
      return;
    }

    const shouldUpdate = boundaryItem.label?.text !== labelData.text
      || boundaryItem.label?.bounds !== labelData.bounds;

    if (!shouldUpdate) {
      return;
    }

    boundaryItem.label = {
      text: labelData.text,
      bounds: labelData.bounds,
    };

    if (boundaryItem.webGlObjects.label) {
      this.overlayLayers.BoundaryLabels.remove(boundaryItem.webGlObjects.label);
    }

    const label = new WebGLOverlay.Label(initializeLabelProperties(boundaryItem));
    const textBoxCallout = new WebGLOverlay.TextBoxCallout(initializeTextCalloutProperties(boundaryItem));
    label.setCallout(textBoxCallout);

    const labelWithBody = { label, textBoxCallout };
    boundaryItem.webGlObjects.label = labelWithBody;
    this.overlayLayers.BoundaryLabels.add(labelWithBody);

    this.eventListeners.forEach(e => {
      registerBoundaryEventListener(boundaryGroupItem.boundaryGroupId, boundaryItem,
        e.eventName, e.callback, { label: true });
    });
  }

  private removeLabel(boundary: BoundaryItem) {
    if (boundary.webGlObjects?.label) {
      this.overlayLayers.BoundaryLabels?.remove(boundary.webGlObjects.label);
    }

    boundary.webGlObjects.label = null;
    boundary.label = undefined;
  }

  private updateLabelBoundsPolygon(boundary: BoundaryItem, labelData: BoundaryStateItem) {
    if (!DEBUG_LABEL_BOUNDS || !areLabelsEnabled(this.overlayLayers)) {
      return;
    }

    const b = labelData.labelBoundaries;

    if (b && !boundary.webGlObjects.labelBoundsPolygon) {
      const config = {
        ...initializePolygonProperties(boundary, this.animationPercent),
        fillColor: [255, 255, 255, 0.7] as WebglColor,
        borderColor: [0, 0, 0, 1] as WebglColor,
        border: true,
        borderWidth: 2,
      };
      const polygon = new WebGLOverlay.Polygon(config,
        [[[b.sw, { lat: b.sw.lat, lng: b.ne.lng }, b.ne, { lat: b.ne.lat, lng: b.sw.lng }]]]);

      boundary.webGlObjects.labelBoundsPolygon = polygon;
      this.overlayLayers.BoundaryPolygons.add(polygon);
    }
  }

  private initializeLabelTextconfig(textLayer?: LabelsLayer) {
    const font = textLayer?.fonts.get(labelTextDefaults.font);

    if (!font) {
      return;
    }

    const setConfig = () => {
      const config = {
        ...labelTextDefaults,
        fontBase: font.base,
        lineHeight: font.lineHeight,
      };

      this.textDimensionsCalculator = createTextDimensionsCalculator(config);
    };

    if (font.state === 'READY') {
      setConfig();
    }
    else {
      font.addEventListener('Loaded', setConfig);
    }
  }

  private applyBoundaryItemStyles(boundary: BoundaryItem) {
    if (!boundary.style) {
      return;
    }

    const polygonConfig = initializePolygonProperties(boundary, this.animationPercent);

    for (const polygon of boundary.webGlObjects.polygons) {
      polygon.fillColor.set(...polygonConfig.fillColor);
      polygon.borderColor.set(...polygonConfig.borderColor);
      polygon.borderWidth = polygonConfig.borderWidth;
      polygon.border = polygonConfig.border;
      polygon.zIndex = polygonConfig.zIndex;
      polygon.borderZIndex = polygonConfig.borderZIndex;
    }

    if (boundary.webGlObjects.label) {
      const { label, textBoxCallout } = boundary.webGlObjects.label;
      const labelCalloutConfig = initializeTextCalloutProperties(boundary);

      textBoxCallout.fillColor.set(...labelCalloutConfig.fillColor);
      textBoxCallout.zIndex = labelCalloutConfig.zIndex;

      const labelTextConfig = initializeLabelProperties(boundary);

      label.zIndex = labelTextConfig.zIndex;
    }

    AnimatableBoundaryProperties.forEach((animatableProperty) => {
      const animatedProperties = this.animatedBoundaries.get(boundary.id)?.[1] || new Set();
      if (boundary.style[animatableProperty].animation) {
        animatedProperties.add(animatableProperty);
      }
      else {
        animatedProperties.delete(animatableProperty);
      }
      this.animatedBoundaries.set(boundary.id, [boundary, animatedProperties]);
    });

    if (boundary.style.showPolygonOutline) {
      if (boundary.webGlObjects.outlinePolygons.length === 0) {
        this.updateOutlinePolygons(boundary);
      }
    }
    else {
      this.removeOutlinePolygons(boundary);
    }
  }

  private animateBoundaries = (animationPercent: number) => {
    this.animationPercent = animationPercent;
    this.animatedBoundaries.forEach(([boundaryItem, animatedProperties]) => {
      boundaryItem.webGlObjects.polygons.forEach(polygon => {
        animatedProperties.forEach(property => {
          const polygonAndBoundaryColorProperty = property === 'fillOpacity'
            ? [polygon.fillColor, boundaryItem.style.fillColor, boundaryItem.style.fillOpacity] as const
            : property === 'borderOpacity' || property === 'borderColor'
              ? [polygon.borderColor, boundaryItem.style.borderColor, boundaryItem.style.borderOpacity] as const
              : undefined;
          polygonAndBoundaryColorProperty?.[0]?.set(...convertColorToWebGLColor(
            typeof polygonAndBoundaryColorProperty?.[1] === 'string'
              ? polygonAndBoundaryColorProperty?.[1]
              : getValueOfAnimatableColorProperty(
                polygonAndBoundaryColorProperty?.[1],
                animationPercent,
              ),
            getValueOfAnimatableNumericalProperty(
              polygonAndBoundaryColorProperty?.[2],
              animationPercent,
            ),
          ));
        });
      });
    });
  };

  private initializeStylesAnimationTimeout() {
    registerAnimationCallback(this.animateBoundaries);
  }
}

const initializePolygonProperties = (data: BoundaryItem, animationPercent: number) => {
  const styles = data.style ?? defaultStyles;

  return {
    visible: true,
    fillColor: convertColorToWebGLColor(
      styles.fillColor,
      getValueOfAnimatableNumericalProperty(styles.fillOpacity, animationPercent),
    ),
    border: !!styles.borderWidth && !!styles.borderOpacity,
    borderWidth: styles.borderWidth,
    borderColor: convertColorToWebGLColor(
      getValueOfAnimatableColorProperty(styles.borderColor, animationPercent),
      getValueOfAnimatableNumericalProperty(styles.borderOpacity, animationPercent),
    ),
    zIndex: spreadBoundaryGroupZIndex(styles.zIndex.polygon, data.id),
    borderZIndex: spreadBoundaryGroupZIndex(styles.zIndex.border, data.id),
  };
};

const getTextCalloutFillColorAndOpacity = (styles: BoundaryItemStyle) => {
  const hex = styles.fillColor && styles.fillOpacity
    ? mixColorMemoized(styles.fillColor, '#000').toHex()
    : labelTextDefaults.backgroundColor;
  return {
    color: hex,
    webGLColor: convertColorToWebGLColor(hex),
    opacity: 1,
  };
};

const initializeLabelProperties = (data: BoundaryItem) => {
  const styles = data.style ?? defaultStyles;
  const { paddingVertical, paddingHorizontal } = labelTextDefaults;
  const fontColor = {
    selectedColor: 'FFF',
    opacity: 1,
  };
  const backgroundColor = getTextCalloutFillColorAndOpacity(styles);
  const textStroke = getLabelStroke({
    backgroundColor: {
      selectedColor: backgroundColor.color,
      opacity: backgroundColor.opacity,
    },
    fontColor,
  });

  return {
    visible: true,
    text: {
      value: data.label?.text,
      fontSize: labelTextDefaults.maxFontSize,
      fillColor: convertColorToWebGLColor(fontColor.selectedColor, fontColor.opacity),
      align: 'center' as const,
      font: labelTextDefaults.font,
      ...textStroke,
    },
    zIndex: spreadBoundaryGroupLabelZIndex(styles.zIndex.label, data.id, 'text'),
    staticSize: true,
    padding: { b: paddingVertical, l: paddingHorizontal, r: paddingHorizontal, t: paddingVertical },
    horizontalAnchor: 'center' as const,
    verticalAnchor: 'center' as const,
    boundaries: {
      enabled: true,
      sw: data.label?.bounds?.sw,
      ne: data.label?.bounds?.ne,
      minFontSize: labelTextDefaults.minFontSize,
      maxFontSize: labelTextDefaults.maxFontSize,
    },
    interactive: false,
  };
};

const initializeTextCalloutProperties = (data: BoundaryItem) => {
  const styles = data.style ?? defaultStyles;

  const backgroundColor = getTextCalloutFillColorAndOpacity(styles);

  return {
    visible: true,
    triangle: false,
    fillColor: backgroundColor.webGLColor,
    fillOpacity: backgroundColor.opacity,
    staticSize: true,
    zIndex: spreadBoundaryGroupLabelZIndex(styles.zIndex.label, data.id, 'callout'),
    borderRadius: 10,
  };
};

const initializeOutlinePolygonPool = (
  polygonLayer: PolygonLayer | undefined,
): Pool<WebglOverlayPolygon, [WebglOverlayPolygonConfig, Points[][]]> => (
  new Pool({
    itemFactory: (polygonConfig, points) => {
      const newPoly = new WebGLOverlay.Polygon({
        ...polygonConfig,
        dynamic: true,
      }, points);
      polygonLayer?.add(newPoly);
      return newPoly;
    },
    onDestroy: (polygon) => {
      polygon.visible = false;
      polygonLayer?.remove(polygon);
    },
    onRetire: (polygon) => {
      polygon.visible = false;
    },
    onRevive: (polygon, _polygonConfig, points) => {
      polygon.points = points;
      polygon.visible = true;
    },
  })
);

const registerBoundaryEventListener = (
  boundaryGroupId: number,
  boundary: BoundaryItem,
  eventName: MapElementEventName,
  callback: EventCallback,
  config: { polygons?: boolean; label?: boolean },
) => {
  let registeredCallback = boundary.registeredCallbacks.find(c => c.originalCallback === callback);

  if (!registeredCallback) {
    registeredCallback = {
      eventName,
      originalCallback: callback,
      boundCallback: () => callback({
        boundaryId: boundary.id,
        boundaryGroupId,
      }),
    };

    boundary.registeredCallbacks = [...boundary.registeredCallbacks, registeredCallback];
  }

  if (config.polygons) {
    for (const polygon of boundary.webGlObjects.polygons) {
      polygon.addEventListener(eventName, registeredCallback.boundCallback);
    }
  }

  if (config.label) {
    boundary.webGlObjects.label?.textBoxCallout.addEventListener(eventName, registeredCallback.boundCallback);
  }
};

const removeBoundaryEventListener = (
  boundary: BoundaryItem,
  eventName: MapElementEventName,
  callback: EventCallback
) => {
  const registeredCallback = boundary.registeredCallbacks.find(c => c.originalCallback === callback);

  if (!registeredCallback) {
    return;
  }

  boundary.registeredCallbacks = boundary.registeredCallbacks.filter(c => c !== registeredCallback);

  for (const polygon of boundary.webGlObjects.polygons) {
    polygon.removeEventListener(eventName, registeredCallback.boundCallback);
  }
};

const areLabelsEnabled = (layers: Readonly<{ BoundaryLabels?: LabelWithBodyVirtualLayer }>): layers is Readonly<{ BoundaryLabels: LabelWithBodyVirtualLayer }> =>
  !!layers.BoundaryLabels;

const createTextDimensionsCalculator = (config: LabelTextConfig): BoundaryTextDimensionsCalculator => {
  const minfontCoef = config.minFontSize / config.fontBase;
  const minLineHeight = minfontCoef * config.lineHeight;
  const approxCharWidth = 20;

  return {
    calculateMinHeight: (linesCount: number = 1) => minLineHeight * linesCount + (2 * config.paddingVertical),
    calculateMinWidth: (text: string) => text.length * approxCharWidth * minfontCoef + (2 * config.paddingHorizontal),
  };
};
