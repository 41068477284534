import { type FC } from 'react';
import type { TeamMemberLimitsData } from '../teamManagement.repository';
import { type InvitationAndMemberData } from '../teamManagementModal.container';
import { MembersTableItemComponent } from './membersTableItem.component';
import { useMembersTableItemContextMenu } from './useMembersTableItemContextMenu';

type MembersTableItemContainerProps = {
  index: number;
  member: InvitationAndMemberData;
  limits: TeamMemberLimitsData;
};

export const MembersTableItemContainer: FC<MembersTableItemContainerProps> = ({
  index,
  member,
  limits,
}) => {
  const { contextMenu, isLoading } = useMembersTableItemContextMenu({ member, limits });
  return (
    <MembersTableItemComponent
      member={member}
      index={index}
      contextMenu={contextMenu}
      isLoading={isLoading}
    />
  );
};
