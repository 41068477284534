import { useMemo } from 'react';
import type { ActiveMarker } from '~/_shared/types/marker.types';
import { useGetFinalMarkerSettingsForSpreadsheetRow } from '~/_shared/utils/markers/useGetFinalMarkerSettingsForSpreadsheetRow.hook';
import { useGetStackedInfoForSpreadsheetRow } from '~/_shared/utils/markers/useGetStackedInfoForSpreadsheetRow.hook';
import { useMarkerEntityOffsets } from '~/_shared/utils/markers/useMarkerEntityOffsets.hook';
import { useHoverMarkerName } from '~/_shared/utils/markers/useMarkerName.hook';
import { useMoveMarkersIsActiveSelector } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { useLatLngSpreadsheetData } from '../../useSpreadsheetData.hook';

export const useMarkerHoverLabelSettings = (hoveredMarker: ActiveMarker | null) => {
  const rowsData = useLatLngSpreadsheetData();
  const isMoveMarkersActive = useMoveMarkersIsActiveSelector();

  const hoveredMarkerId = hoveredMarker?.rowIds[0];
  const hoveredMarkerRow = useMemo(() => hoveredMarkerId && rowsData.getRow(hoveredMarkerId), [hoveredMarkerId, rowsData]);

  const stackedMarkerInfo = useGetStackedInfoForSpreadsheetRow(hoveredMarkerRow);
  const hoverMarkerName = useHoverMarkerName(hoveredMarkerId, stackedMarkerInfo.data);
  const activeMarkerVisualSettings = useGetFinalMarkerSettingsForSpreadsheetRow(hoveredMarkerRow, stackedMarkerInfo.data);

  const markerEntityOffsetsProps = useMemo(() => ({
    markerVisualSettings: activeMarkerVisualSettings,
    applyLabelAboveOffset: false,
    customizedLabelOffsetRowId: hoveredMarkerId,
  }), [activeMarkerVisualSettings, hoveredMarkerId]);

  const markerEntityOffsets = useMarkerEntityOffsets(markerEntityOffsetsProps);
  const shouldBeDisplayed = hoverMarkerName !== null && !isMoveMarkersActive && activeMarkerVisualSettings?.aboveLabel;

  return useMemo(() => ({
    shouldBeDisplayed: shouldBeDisplayed ? hoveredMarkerRow || null : null,
    hoverMarkerName,
    aboveLabelSettings: activeMarkerVisualSettings?.aboveLabel,
    markerEntityOffsets,
  }), [activeMarkerVisualSettings, hoverMarkerName, hoveredMarkerRow, markerEntityOffsets, shouldBeDisplayed]);
};
