import { useCallback } from 'react';
import { type RowsPerSpreadsheetWithBasicData } from '../../../../../map/map/lassoTool/rowsPerSpreadsheet';
import { useGetMarkersInArea } from '../../../../../map/map/lassoTool/useGetMarkersInArea';
import {
  type BoundaryId,
  type BoundaryTerritoryGroupId,
  type BoundaryTerritoryId,
} from '../../../../../store/boundaries/boundaryIdentifier.type';
import {
  createRequestFromBoundaryTerritoryGroupSettings,
  getBoundaryTerritoryGroupMatchupRequest,
} from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';

export type MarkersFetchDataResults = {
  rowsData: ReadonlyArray<RowsPerSpreadsheetWithBasicData>;
  useMainFilters: boolean;
};

export const useMarkersInBoundary = () => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const { getMarkersInArea } = useGetMarkersInArea();

  const getMarkersInBoundary = useCallback((
    boundaryTerritoryGroupId: BoundaryTerritoryGroupId, boundaryId: BoundaryId
  ): Promise<MarkersFetchDataResults> => {
    const boundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === boundaryTerritoryGroupId);

    if (!boundaryTerritoryGroup) {
      return Promise.reject();
    }

    const useMainFilters = !boundaryTerritoryGroup.settings.ignoreFilters;

    return getMarkersInArea({
      useMainFilters,
      boundaries: {
        [boundaryTerritoryGroup.boundaryGroupId]: {
          boundary_ids: [boundaryId],
          matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
        },
      },
    })
      .then(rowsData => ({
        useMainFilters,
        rowsData,
      }));
  }, [boundaryTerritoryGroups, getMarkersInArea]);

  const getMarkersInBoundaryTerritory = useCallback((
    boundaryTerritoryGroupId: BoundaryTerritoryGroupId, boundaryTerritoryId: BoundaryTerritoryId
  ): Promise<MarkersFetchDataResults> => {
    const boundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === boundaryTerritoryGroupId);

    if (!boundaryTerritoryGroup) {
      return Promise.reject();
    }

    const useMainFilters = !boundaryTerritoryGroup.settings.ignoreFilters;

    return getMarkersInArea({
      useMainFilters,
      boundaryTerritories: [{
        type: 'or',
        boundary_territories: [boundaryTerritoryId],
        definition: {
          boundary_territory_group_id: boundaryTerritoryGroup.boundaryTerritoryGroupId,
          boundary_group_id: boundaryTerritoryGroup.boundaryGroupId,
          matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
          settings: createRequestFromBoundaryTerritoryGroupSettings(boundaryTerritoryGroup.settings),
        },
      }],
    })
      .then(rowsData => ({
        useMainFilters,
        rowsData,
      }));
  }, [boundaryTerritoryGroups, getMarkersInArea]);

  return {
    getMarkersInBoundary,
    getMarkersInBoundaryTerritory,
  };
};
