import {
  type AxiosRequestConfig, type CancelToken,
} from 'axios';
import { type ColumnRole } from '~/_shared/types/columnRole.enum';
import { type FilterTree } from '~/_shared/types/filterTree.types';
import { type Geocoding } from '~/_shared/types/geocoding/geocoding';
import { createGeocodingFromResponse } from '~/_shared/types/geocoding/geocoding.factory';
import { type GeocodingResponse } from '~/_shared/types/geocoding/geocoding.response';
import type { Pagination } from '~/_shared/types/pagination/pagination';
import { createPaginationFromResponse } from '~/_shared/types/pagination/pagination.factory';
import { type PaginationResponse } from '~/_shared/types/pagination/pagination.response';
import { type PerSpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { type PerColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  apiDelete, apiGet, apiPatch, apiPost,
} from '~/_shared/utils/api/api.helpers';
import {
  type ApiError, ApiErrorCode, catchApiError,
} from '~/_shared/utils/api/apiError.helpers';
import { type ConcurrencyCheckStatus } from '~/map/map.repository';
import {
  type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { type DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import { type ColumnsMapSettingsResponse } from './columnsMapSettings/columnsMapSettings.types';
import { type BoundaryFilterRequest } from './filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from './filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type PolygonFilterRequest } from './filter/polygon/spreadsheetPolygonFilter.types';
import { type RadiusFilterRequest } from './filter/radius/spreadsheetFilterRadius.types';

export const MAXIMUM_RAW_DATA_MARKERS = 300;

type ColumnId = string;
type ColumnName = string;

export type SpreadsheetBasicDataItemResponse = Readonly<{
  row_id: CombinedRowId;
  latitude: string;
  longitude: string;
  name: string | null;
}>;

export type SpreadsheetColumnsToFetchGroupResponse = Readonly<{
  row_values: {
    readonly [rowId: string]: number; // value number is an index in unique_values
  };
  unique_values: readonly string[];
  unique_indexes?: readonly number[];
}>;

export type SpreadsheetColumnsToFetchTextResponse = {
  readonly row_values: {
    readonly [rowId: string]: string;
  };
};

export type SpreadsheetColumnsToFetchAttributeResponse = Readonly<{
  row_values: {
    readonly [rowId: string]: readonly number[]; // value number is an index in unique_values
  };
  unique_values: readonly string[];
}>;

export type SpreadsheetColumnsToFetchDateResponse = Readonly<{
  min: string | null;
  max: string | null;
  min_filtered: string;
  max_filtered: string;
  row_values: {
    readonly [rowId: string]: string;
  };
}>;

export type SpreadsheetColumnsToFetchNumberResponse = Readonly<{
  max: number;
  max_filtered: number;
  min: number;
  min_filtered: number;
  row_values: {
    readonly [rowId: string]: number | null | undefined;
  };
  constraints?: Readonly<{
    min: number;
    max: number;
  }>;
  numeric_percent: number;
  numeric_and_empty_percent: number;
}>;

export type SpreadsheetColumnsToFetchResponse = Readonly<{
  [DataType.GROUP]?: {
    readonly [columnId: string]: SpreadsheetColumnsToFetchGroupResponse;
  };
  [DataType.TEXT]?: {
    readonly [columnId: string]: SpreadsheetColumnsToFetchTextResponse;
  };
  [DataType.ATTRIBUTE]?: {
    readonly [columnId: string]: SpreadsheetColumnsToFetchAttributeResponse;
  };
  [DataType.DATE]?: {
    readonly [columnId: string]: SpreadsheetColumnsToFetchDateResponse;
  };
  [DataType.NUMBER]?: {
    readonly [columnId: string]: SpreadsheetColumnsToFetchNumberResponse;
  };
}>;

type SpreadsheetFilterBulkRequestDescriptor = Readonly<{
  requestType: 'filter';
  filterHash: string;
  requestGetter: SpreadsheetDataBulkRequestGetter;
}>;

type SpreadsheetSearchBulkRequestDescriptor = Readonly<{
  requestType: 'search';
  filterHash: string;
  requestGetter: Omit<SpreadsheetDataBulkRequestGetter, 'map_id'>;
}>;

type SpreadsheetBoundaryLocationsFilterBulkRequestDescriptor = Readonly<{
  requestType: 'boundary-locations-filter';
  filterHash: string;
  requestGetter: Omit<SpreadsheetDataBulkRequestGetter, 'map_id'>;
}>;

type SpreadsheetBasicDataBulkRequestDescriptor = Readonly<{
  requestType: 'basicData';
  requestGetter: SpreadsheetDataBulkRequestGetter;
}>;

export type SpreadsheetDataBulkRequestDescriptor = SpreadsheetFilterBulkRequestDescriptor |
SpreadsheetSearchBulkRequestDescriptor | SpreadsheetBasicDataBulkRequestDescriptor |
SpreadsheetBoundaryLocationsFilterBulkRequestDescriptor;

export type SpreadsheetDataBulkResponse = Readonly<{
  spreadsheet_id: number;
  result: Readonly<{
    file_name?: string;
    content?: string;
    basic_data?: readonly SpreadsheetBasicDataItemResponse[];
    columns_to_fetch?: SpreadsheetColumnsToFetchResponse;
    filtered_rows?: readonly CombinedRowId[];
    order_list?: readonly number[];
    row_to_boundary_id?: {
      readonly [key: string]: {
        readonly string: number;
      };
    };
  }>;
}>;

export type SpreadsheetDataBulkRequest = Readonly<{
  export?: true;
  as_text?: true;
  extension?: 'csv' | 'tsv' | 'xlsx';
  params: readonly SpreadsheetDataBulkRequestGetter[];
}>;

export type SpreadsheetDataBulkRequestGetterFilter = Readonly<{
  only_get_filtered: boolean;
  filter_combine?: 'and' | 'or';
  filter_tree?: FilterTree;
  radius_filter?: RadiusFilterRequest;
  polygon_filter?: PolygonFilterRequest;
  boundary_filter?: BoundaryFilterRequest;
  boundary_territory_filter?: BoundaryTerritoryFilterRequest;
}>;

export type SpreadsheetDataBoundariesRequest = Readonly<{
  [groupId: BoundaryTerritoryGroupId]: {
    readonly name_overrides?: { readonly [id: BoundaryId]: string };
  };
}>;

export type SpreadsheetDataColumnsToFetch = {
  readonly [dataType in DataType]?: {
    readonly [columnId: string]: SpreadsheetDataBulkFetchExtra;
  }
};

export type SpreadsheetDataBulkRequestGetter = Readonly<{
  map_id: number;
  spreadsheet_id: number;
  exclude_basic_data?: boolean;
  exclude_row_data?: boolean;
  boundaries?: SpreadsheetDataBoundariesRequest;
  include_ungeocoded?: boolean;
  columns_to_fetch?: SpreadsheetDataColumnsToFetch;
  filter?: SpreadsheetDataBulkRequestGetterFilter;
}>;

type AddSpreadsheetColumnRequest = Readonly<{
  column: string;
  type: 'text';
  default?: string;
}>;

type AddSpreadsheetRowRequest = Readonly<{
  real_spreadsheet_id?: string;
  lat?: number;
  lng?: number;
  data: {
    readonly [columnId: string]: string;
  };
}>;

export type ImportSpreadsheetDataMatchRequest = Readonly<{
  source_column?: ColumnId;
  table_column?: ColumnId;
  delete?: true;
  add?: true;
}>;

export type ImportSpreadsheetUniqueKeys = Readonly<{
  source: ReadonlyArray<number>;
  table: ReadonlyArray<number>;
}>;

type ImportSpreadsheetDataRequest = Readonly<{
  type: 'file' | 'input';
  source: string;
  action: 'replace' | 'append';
  clear_rows_in_settings?: boolean;
  matches?: ReadonlyArray<ImportSpreadsheetDataMatchRequest>;
  unique_keys?: ImportSpreadsheetUniqueKeys;
}>;

export type ImportSpreadsheetResponse = Readonly<{
  message: string;
  spreadsheet: {
    readonly type: 'real';
    readonly id: number;
  };
}>;

export type ImportSpreadsheetMatchupItemResponse = Readonly<{
  source_column: string;
  table_column: string;
  add?: true;
  delete?: true;
}>;

export enum ReplaceSpreadsheetErrorMessage {
  DuplicatedRowsActionRequired = 'Actions for duplicated rows are required',
  AdditionalParamsRequired = 'Request require additional params.',
  UniqueKeyRequired = 'Unique key is required for replace import',
  InvalidUniqueKey = 'Provided unique keys are invalid',
}

type ReplaceSpreadsheetErrorResponse = {
  readonly columns: {
    readonly source: ReadonlyArray<Readonly<{ id: ColumnId; name: ColumnName }>>;
    readonly table: ReadonlyArray<Readonly<{ id: ColumnId; name: ColumnName }>>;
  };
};

export type ReplaceSpreadsheetRequiringMatchesResponse = ReplaceSpreadsheetErrorResponse & Readonly<{
  message: ReplaceSpreadsheetErrorMessage.AdditionalParamsRequired;
  params: {
    readonly matches: ReadonlyArray<ImportSpreadsheetMatchupItemResponse>;
  };
  columns_map_settings?: ColumnsMapSettingsResponse;
}>;

export type ReplaceSpreadsheetRequiringUniqueKeyResponse = ReplaceSpreadsheetErrorResponse & {
  readonly message: ReplaceSpreadsheetErrorMessage.UniqueKeyRequired;
};

export type ReplaceSpreadsheetRequiringDuplicatesResolutionResponse = ReplaceSpreadsheetErrorResponse & {
  readonly message: ReplaceSpreadsheetErrorMessage.DuplicatedRowsActionRequired;
};

export type ReplaceSpreadsheetRequiringResolutionResponse =
  ReplaceSpreadsheetRequiringMatchesResponse
  | ReplaceSpreadsheetRequiringDuplicatesResolutionResponse
  | ReplaceSpreadsheetRequiringUniqueKeyResponse;

export type SpreadsheetDataRequestSortObject = Readonly<{
  id: ColumnId;
  type: 'asc' | 'desc';
}>;

type SpreadsheetTableDataRequest = Readonly<{
  page?: number;
  per_page?: number;
  filter?: Readonly<{
    only_get_filtered?: boolean;
    filter_tree?: FilterTree;
    rows?: readonly string[];
  }>;
  map_id?: number;
  only_columns?: readonly ColumnId[];
  sort_object?: readonly SpreadsheetDataRequestSortObject[];
}>;

export type SpreadsheetTableDataUpdateItemServerModel = Readonly<{
  row_id: CombinedRowId;
  version?: number;
  lat?: number;
  lng?: number;
  data: {
    readonly [columnId: string]: string;
  };
}>;

export type SpreadsheetTableDataUpdateRequest = {
  params: SpreadsheetTableDataUpdateItemServerModel[];
};

type SpreadsheetRowItemServerModel = {
  row_id: CombinedRowId;
  version?: number;
};

export type SpreadsheetTableRemoveRowsRequest = Readonly<{
  rows: ReadonlyArray<SpreadsheetRowItemServerModel>;
  remove_bad_data?: boolean;
}>;

export type SpreadsheetTableData = Readonly<{
  geocoding: Geocoding;
  list: readonly Readonly<{
    columnData: PerColumn<string | null>;
    rowId: CombinedRowId;
    version: number;
    isBad?: boolean;
  }>[];
  pagination: Pagination;
}>;

type SpreadsheetTableDataResponse = Readonly<{
  geocoding: GeocodingResponse;
  list: readonly (PerColumn<string | null> & Readonly<{
    row_id: CombinedRowId;
    version: number;
    is_bad?: boolean;
  }>)[];
  meta: PaginationResponse;
}>;

const convertTableDataServerToClient = (serverResponse: SpreadsheetTableDataResponse): SpreadsheetTableData => ({
  geocoding: createGeocodingFromResponse(serverResponse.geocoding),
  list: serverResponse.list.map(listItem => {
    const rowId = listItem.row_id;
    const version = listItem.version;
    const isBad = listItem.is_bad;
    const columnData = Object.entries(listItem).reduce((acc, [possibleColumnId, value]) => {
      if (!['row_id', 'version', 'is_bad'].includes(possibleColumnId)) {
        acc[possibleColumnId] = value as string | null;
      }
      return acc;
    }, {} as { [columnId: string]: string | null });
    return {
      columnData,
      rowId,
      version,
      isBad,
    };
  }),
  pagination: createPaginationFromResponse(serverResponse.meta),
});

export enum SpreadsheetTableDataError {
  General = 'The given data was invalid.',
  SortDescriptor = 'Provided sort object is invalid.',
}

export type SpreadsheetTableDataErrorResponse = Readonly<{
  message: string;
  errors?: {
    readonly [errorIndex: string]: ReadonlyArray<SpreadsheetTableDataError.SortDescriptor | string>;
  };
}>;

export const convertTableDataErrorServerToClient = (serverError: SpreadsheetTableDataErrorResponse): SpreadsheetTableDataError => {
  const isSortError = Object.values(serverError.errors || {})
    .some(errorMessages => errorMessages.some(errorMessage => errorMessage === SpreadsheetTableDataError.SortDescriptor));
  return isSortError ? SpreadsheetTableDataError.SortDescriptor : SpreadsheetTableDataError.General;
};

export type UpdatedCoordinate = Readonly<{
  row_id: string;
  lat: number;
  lng: number;
}>;

export type SpreadsheetTableDataUpdateCoordinatesRequest = {
  readonly params: readonly UpdatedCoordinate[];
};

export type SpreadsheetDataExportRequest = Readonly<{
  select: 'data' | 'map';
  extension: 'csv' | 'tsv' | 'xlsx' | 'json';
  autoincrement?: boolean;
  failed_geocoding?: boolean;
}>;

type ExportedSpreadsheetDataResponse = {
  readonly data: {
    readonly list: readonly Readonly<Record<string, string | null>>[];
  };
};

export type SpreadsheetDataBulkFetchExtra = Record<string, unknown> | SpreadsheetDataBulkFetchNumbersExtra;
export type SpreadsheetDataBulkFetchNumbersExtra = Readonly<{
  empty_is_null: boolean;
  //for now this would be an empty object, but in the future we could add additional params in it
  include_constraints?: Record<string, never>;
}>;

export type UpdateSpreadsheetMatchupRequest = {
  readonly [key in ColumnRole]?: string
};

export type GetSpreadsheetMatchupDataRequestItem = Readonly<{
  spreadsheet_id: number; // virtual spreadsheet id
  map_id: number;
  guess?: boolean;
}>;

export const getSpreadsheetMatchup = (
  clientId: number, requestData: GetSpreadsheetMatchupDataRequestItem[]
): Promise<{ data: PerSpreadsheetMatchupData }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/get/matches`;
  const requestParams = {
    virtual_spreadsheets: requestData,
  };

  return apiPost(requestUrl, requestParams);
};

export const updateSpreadsheetMatchup = (
  clientId: number,
  spreadsheetId: number,
  mapId: number,
  request: UpdateSpreadsheetMatchupRequest
): Promise<{ message: string }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/matches/map/${mapId}`;

  return apiPost(requestUrl, { matches: request });
};

export const getSpreadsheetBulkData = (
  clientId: number, request: SpreadsheetDataBulkRequest, cancelToken?: CancelToken
): Promise<{ data: SpreadsheetDataBulkResponse[] }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/data`;

  const requestConfig = {
    cancelToken,
  };

  return apiPost(requestUrl, request, requestConfig)
    // BE sends [] as result if emtpy data, so let's adjust before moving on
    .then(response => ({
      ...response,
      data: response.data.map((spreadsheetBulkResponse: any) => ({
        ...spreadsheetBulkResponse,
        result: Array.isArray(spreadsheetBulkResponse.result)
          ? {}
          : spreadsheetBulkResponse.result,
      })),
    }));
};

export const addSpreadsheetColumn = (
  clientId: number, spreadsheetId: number, request: AddSpreadsheetColumnRequest
): Promise<{ message: string }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/columns`;

  return apiPost(requestUrl, request);
};

export const addSpreadsheetRow = (
  clientId: number, spreadsheetId: number, request: AddSpreadsheetRowRequest
): Promise<{ message: string }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/rows`;

  return apiPost(requestUrl, request);
};

type SpreadsheetDataUpdateResponse = Readonly<{
  message: string;
  updated: readonly SpreadsheetTableDataUpdateItemServerModel[];
}>;

type SpreadsheetDataUpdateConcurrencyErrorResponse = Readonly<{
  reason: ReadonlyArray<Readonly<{
    concurency_check: ConcurrencyCheckStatus;
    row_id: CombinedRowId;
    versions: {
      readonly latest: number;
      readonly provided: number;
    };
  }>>;
}>;

type SpreadsheetDataUpdateCombinedResponse = Readonly<{
  type: 'success';
  data: SpreadsheetDataUpdateResponse;
}> | Readonly<{
  type: 'concurrency-error';
  data: SpreadsheetDataUpdateConcurrencyErrorResponse;
}>;

type ImportOrReplaceSpreadsheetCombinedResponse = Readonly<{
  type: 'success';
  response: ImportSpreadsheetResponse;
}> | Readonly<{
  type: 'resolution-needed';
  response: ReplaceSpreadsheetRequiringResolutionResponse;
}>;

const isSpreadsheetDataConcurrencyErrorResponse = (
  response: any
): response is SpreadsheetDataUpdateConcurrencyErrorResponse => {
  return !!response?.reason;
};

export const importSpreadsheetData = (
  clientId: number, spreadsheetId: number, request: ImportSpreadsheetDataRequest
): Promise<ImportOrReplaceSpreadsheetCombinedResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/import`;

  const handleRequireResolutionResponse = catchApiError((apiError: ApiError) => {
    const { responseStatus, response, errorCode } = apiError;
    if ((responseStatus === 400 || responseStatus === 422) && errorCode !== ApiErrorCode.DIFFERENT_STRUCTURE) {
      return {
        type: 'resolution-needed',
        response: response as ReplaceSpreadsheetRequiringResolutionResponse,
      } as const;
    }

    throw apiError;
  });

  return apiPost(requestUrl, request)
    .then((response: ImportSpreadsheetResponse) => ({ type: 'success', response } as const))
    .catch(handleRequireResolutionResponse);
};

export const getSpreadsheetTableData = (
  clientId: number, spreadsheetId: number, request: SpreadsheetTableDataRequest,
  config?: AxiosRequestConfig
): Promise<{ data: SpreadsheetTableData }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/raw`;

  return apiPost(requestUrl, request, config)
    .then(response => ({ ...response, data: convertTableDataServerToClient(response.data) }));
};

export const updateSpreadsheetRows = (
  clientId: number, spreadsheetId: number, request: SpreadsheetTableDataUpdateRequest
): Promise<SpreadsheetDataUpdateCombinedResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/rows`;

  const handleConcurrencyError = catchApiError((apiError: ApiError) => {
    const { responseStatus, response } = apiError;

    if (responseStatus === 412 && isSpreadsheetDataConcurrencyErrorResponse(response)) {
      return {
        type: 'concurrency-error',
        data: response,
      } as const;
    }

    throw apiError;
  });

  return apiPatch(requestUrl, request)
    .then((response: SpreadsheetDataUpdateResponse) => ({ type: 'success', data: response }) as const)
    .catch(handleConcurrencyError);
};

export const removeSpreadsheetRows = (
  clientId: number, spreadsheetId: number, request: SpreadsheetTableRemoveRowsRequest
): Promise<{ message: string }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/rows`;

  return apiDelete(requestUrl, request);
};

export const updateSpreadsheetCoordinates = (
  clientId: number, spreadsheetId: number, request: SpreadsheetTableDataUpdateCoordinatesRequest
): Promise<{ message: string }> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/coordinates`;

  return apiPost(requestUrl, request);
};

export const getExportedSpreadsheetData = (
  clientId: number, spreadsheetId: number, request: SpreadsheetDataExportRequest
): Promise<ExportedSpreadsheetDataResponse> => {
  const requestUrl = `/api/clients/${clientId}/spreadsheets/${spreadsheetId}/export`;

  return apiGet(requestUrl, request);
};
