import { type RemoveMapResponse } from '~/map/map.repository';

export enum Resolution {
  None = 'None',
  Disconnect = 'Disconnect',
  Delete = 'Delete',
  RemoveLayer = 'RemoveLayer',
}

export type LayeredMap = {
  id: number;
  name: string;
};

export type BaseMapDeleteDependenciesResolveHelper = { deletedMapIds: number[]; disconnectedMapIds: number[] };

const wasDisconnected = (alreadyResolvedMaps: BaseMapDeleteDependenciesResolveHelper, mapId: number) =>
  alreadyResolvedMaps.disconnectedMapIds.find(id => id === mapId);

const wasDeleted = (alreadyResolvedMaps: BaseMapDeleteDependenciesResolveHelper, mapId: number) =>
  alreadyResolvedMaps.deletedMapIds.find(id => id === mapId);

export const createEmptyAlreadyResolvedMaps = (): BaseMapDeleteDependenciesResolveHelper => ({
  deletedMapIds: [],
  disconnectedMapIds: [],
});

export const mergeAlreadyResolvedMaps = (
  first: BaseMapDeleteDependenciesResolveHelper, second: BaseMapDeleteDependenciesResolveHelper,
): BaseMapDeleteDependenciesResolveHelper => ({
  deletedMapIds: [...first.deletedMapIds, ...second.deletedMapIds],
  disconnectedMapIds: [...first.disconnectedMapIds, ...second.disconnectedMapIds],
});

export type DeletedBaseMapWithDependenciesOtherUsersMap = {
  userId: number;
  userName: string;
};

export type DeletedBaseMapWithDependencies = {
  mapId: number;
  name: string;
  dependentLayeredMaps: LayeredMap[];
  otherUsersMaps: DeletedBaseMapWithDependenciesOtherUsersMap[];
};

export const calculatePreviousResolutionForMap = (
  mapId: number, alreadyResolvedMaps: BaseMapDeleteDependenciesResolveHelper,
): Resolution.Delete | Resolution.Disconnect | undefined => {
  if (wasDisconnected(alreadyResolvedMaps, mapId)) {
    return Resolution.Disconnect;
  }

  if (wasDeleted(alreadyResolvedMaps, mapId)) {
    return Resolution.Delete;
  }

  return undefined;
};

export type DeletedBaseMapsWithDependencies = DeletedBaseMapWithDependencies[];

export const getBaseMapsWithDependenciesFromResponse = (
  maps: readonly {id: number; name: string}[], response: RemoveMapResponse
): DeletedBaseMapsWithDependencies => (
  response.data.reduce((acc: DeletedBaseMapsWithDependencies, mapData) => {
    if (mapData.success) {
      return acc;
    }

    if (!mapData.layered) {
      return acc;
    }

    const mapName = maps.find(map => map.id === mapData.map_id)?.name ?? '';

    return [
      ...acc,
      {
        mapId: mapData.map_id,
        name: mapName,
        dependentLayeredMaps: mapData.layered?.map(layer => ({ id: layer.layered_id, name: layer.name })) ?? [],
        otherUsersMaps: mapData.used_on_other_users_maps?.map(user => ({ userId: user.user_id, userName: user.user_name })) ?? [],
      },
    ];
  }, [])
);
