import { useMemo } from 'react';
import { mapAreaBorder } from '~/_shared/constants/mapObjects/mapAreaBorder.constants';
import { MapAreaColors } from '~/_shared/constants/mapObjects/mapAreaColors.constants';
import { type AnimatableNumericalProperty } from '~/_shared/types/animation/animatableProperty.types';
import { mixColorMemoized } from '~/_shared/utils/colors/colors.helpers';
import { notNull } from '~/_shared/utils/typeGuards';
import { getBoundaryItemStyle } from '~/sidebar/sidebarApps/mapTools/boundary/boundaryItem.helpers';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useCustomBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useActiveBoundarySelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useHighlightedBoundarySelector } from '~/store/frontendState/mapTools/boundary/boundaryHighlight/boundaryHighlight.selectors';
import { useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { useBoundaryLocationsFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import {
  type AnimatableNumericBoundaryProperty, type BoundaryItemStyle,
} from '../../mapBoundary.manager';
import { useMapBoundaryZIndexes } from './useMapBoundaryZIndexes';

export const highlightedBoundaryStyle = {
  fillColor: MapAreaColors.highlight.fillColor,
  borderColor: { value: MapAreaColors.highlight.borderColor },
  fillOpacity: { value: .4 },
  borderOpacity: { value: 1 },
  showPolygonOutline: true,
} as const;

export const activeBoundaryStyle = {
  fillColor: MapAreaColors.active.fillColor,
  borderColor: {
    value: MapAreaColors.active.borderColor,
    animation: {
      from: MapAreaColors.active.borderColor,
      to: MapAreaColors.active.borderColorAlternative,
    },
  },
  fillOpacity: { value: .3 },
  borderOpacity: { value: 1 },
  showPolygonOutline: true,
} as const;

export const isolatedBoundaryStyle = {
  borderColor: { value: MapAreaColors.isolated.borderColor },
  borderOpacity: { value: 1 },
  borderWidth: 2,
} as const;

export type BoundaryExtraStyles = Readonly<{
  boundaryExtraStyles: ReadonlyMap<BoundaryId, {boundary: BoundaryIdentifier; style: BoundaryItemStyle}>;
  boundariesWithExtraStyles: ReadonlySet<BoundaryId>;
}>;

export const useMapBoundaryExtraStyles = (baseStyles: ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>) => {
  const highlightedBoundary = useHighlightedBoundarySelector();
  const activeBoundary = useActiveBoundarySelector();
  const primaryBoundaryGroupId = useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const boundaryItems = useMapBoundariesSelector();
  const zIndexes = useMapBoundaryZIndexes();
  const boundaryLocationsFilters = useBoundaryLocationsFilters();

  const isolatedBoundaryToBoundaryGroupIdMap = useMemo(() => (
    new Map<BoundaryGroupId, Set<BoundaryId>>(
      boundaryLocationsFilters.entries.map(item => item.filter.isolate ?
        [item.boundaryGroupId, new Set(item.filter.filteredBoundaries)] as const : null
      ).filter(notNull)
    )
  ), [boundaryLocationsFilters.entries]);

  return useMemo<BoundaryExtraStyles>(() => {
    const boundaryExtraStyles = new Map<BoundaryId, {boundary: BoundaryIdentifier; style: BoundaryItemStyle}>();

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const customBoundaryGroup = customBoundaryGroups.find(group => group.id === boundaryTerritoryGroup.boundaryGroupId);

      if (!customBoundaryGroup || boundaryTerritoryGroup.settings.boundaryTerritories.length > 0) {
        continue;
      }

      const boundaryBaseStyle = baseStyles.get(boundaryTerritoryGroup.boundaryGroupId);

      if (!boundaryBaseStyle) {
        continue;
      }

      const isPrimary = primaryBoundaryGroupId === boundaryTerritoryGroup.boundaryGroupId;

      boundaryItems.get(boundaryTerritoryGroup.boundaryGroupId)?.forEach(boundary => {
        const boundaryStyle = getBoundaryItemStyle(boundaryTerritoryGroup, boundary.id);
        const fillOpacity = isPrimary ? boundaryStyle.opacity / 100 : boundaryStyle.opacity / 200;

        boundaryExtraStyles.set(boundary.id, { boundary: {
          boundaryId: boundary.id,
          boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId,
        },
        style: {
          ...boundaryBaseStyle,
          fillColor: boundaryStyle.color,
          fillOpacity: { value: fillOpacity },
        } });
      });
    }

    // Active/isolated styles take precedence, don't apply highlighted when already active/isolated
    if (highlightedBoundary?.boundaryId !== activeBoundary?.boundaryId
      && !isolatedBoundaryToBoundaryGroupIdMap.get(highlightedBoundary?.boundaryGroupId || -1)?.has(highlightedBoundary?.boundaryId || -1)
    ) {
      applyActiveStyles({
        boundary: highlightedBoundary, baseStyles, boundaryExtraStyles,
        extraStyle: { ...highlightedBoundaryStyle, zIndex: zIndexes.highlightedBoundary },
      });
    }

    // Isolated styles take precedence, don't apply active when already isolated
    if (!isolatedBoundaryToBoundaryGroupIdMap.get(activeBoundary?.boundaryGroupId || -1)?.has(activeBoundary?.boundaryId || -1)) {
      applyActiveStyles({
        boundary: activeBoundary, baseStyles, boundaryExtraStyles,
        extraStyle: { ...activeBoundaryStyle, zIndex: zIndexes.activeBoundary },
      });
    }

    isolatedBoundaryToBoundaryGroupIdMap.forEach((boundaryIds, boundaryGroupId) => {
      boundaryIds.forEach(boundaryId => {
        applyActiveStyles({
          boundary: { boundaryId, boundaryGroupId }, baseStyles, boundaryExtraStyles,
          extraStyle: { ...isolatedBoundaryStyle, zIndex: zIndexes.activeBoundary },
        });
      });
    });

    return { boundaryExtraStyles, boundariesWithExtraStyles: new Set(boundaryExtraStyles.keys()) };
  }, [highlightedBoundary, activeBoundary, baseStyles, zIndexes.activeBoundary, zIndexes.highlightedBoundary,
    boundaryTerritoryGroups, customBoundaryGroups, primaryBoundaryGroupId, boundaryItems, isolatedBoundaryToBoundaryGroupIdMap,
  ]);
};

type PartialBoundaryItemStyles = Omit<Partial<BoundaryItemStyle>, 'zIndex'> & { zIndex: Partial<BoundaryItemStyle['zIndex']> };

const getActiveStyles = (
  baseStyle: BoundaryItemStyle,
  activeStyle: PartialBoundaryItemStyles,
): BoundaryItemStyle => {
  const fillColor = activeStyle.fillColor
    ? mixColorMemoized(baseStyle.fillColor, activeStyle.fillColor).toHex()
    : baseStyle.fillColor;

  const borderColor = activeStyle.borderColor ?? baseStyle.borderColor;
  const fillOpacity = getActiveStyleAnimatableNumericalPropertyValue('fillOpacity', baseStyle, activeStyle);
  const borderOpacity = getActiveStyleAnimatableNumericalPropertyValue('borderOpacity', baseStyle, activeStyle);

  return ({
    ...baseStyle,
    ...activeStyle,
    fillColor,
    fillOpacity,
    zIndex: { ...baseStyle.zIndex, ...activeStyle.zIndex },
    borderWidth: activeStyle.borderWidth ?? Math.max(baseStyle.borderWidth + 2, mapAreaBorder.active.minWidth),
    borderColor,
    borderOpacity,
  });
};

const applyActiveStyles = ({ boundary, extraStyle, baseStyles, boundaryExtraStyles }: {
  boundary: { boundaryId: BoundaryId; boundaryGroupId: BoundaryGroupId} | null;
  extraStyle: PartialBoundaryItemStyles;
  baseStyles: ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>;
  boundaryExtraStyles: Map<BoundaryId, { boundary: BoundaryIdentifier; style: BoundaryItemStyle }>;
}) => {
  const activeBoundaryBaseStyle = boundary
    ? boundaryExtraStyles.get(boundary.boundaryId)?.style ?? baseStyles.get(boundary.boundaryGroupId)
    : undefined;

  if (boundary && activeBoundaryBaseStyle) {
    const style = getActiveStyles(activeBoundaryBaseStyle, extraStyle);
    boundaryExtraStyles.set(boundary.boundaryId, { boundary, style });
  }
};

const getActiveStyleAnimatableNumericalPropertyValue = (
  propertyName: AnimatableNumericBoundaryProperty,
  baseStyle: BoundaryItemStyle,
  activeStyle: PartialBoundaryItemStyles,
): AnimatableNumericalProperty => {
  if (activeStyle[propertyName]?.animation) {
    return activeStyle[propertyName];
  }
  else if (baseStyle[propertyName].animation) {
    return baseStyle[propertyName];
  }
  else {
    return {
      value: Math.max(baseStyle[propertyName].value, activeStyle[propertyName]?.value ?? 0),
    };
  }
};
