import {
  useEffect, useState,
} from 'react';

const loadedScripts = new Map<string, Promise<Event>>();

export const useScript = (src: string, condition: boolean = true) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    if (isMounted && condition) {
      let scriptPromise = loadedScripts.get(src);
      if (!scriptPromise) {
        scriptPromise = new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.onload = resolve;
          script.onerror = reject;
          script.src = src;
          document.head.append(script);
        });
        loadedScripts.set(src, scriptPromise);
      }
      scriptPromise
        .then(() => setIsLoaded(true))
        .catch((e) => console.error(`Error loading ${src}`, e));
    }
  }, [src, condition, isMounted]);

  return isLoaded;
};
