import { css } from '@emotion/react';
import {
  useCallback, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { useTranslation } from '../../../utils/hooks';

type TwoFactorGoogleComponentProps = Readonly<{
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  isAlertOpen: boolean;
  qrCode: string | null;
  closeAlert: () => void;
  onTwoFactorGoogleSubmit: (code: string) => void;
}>;

const alertStyle = css({
  marginTop: 20,
});

const qrCodeStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const qrCodeImageStyle = css({
  width: 219,
  height: 219,
});

const bottomStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
  textAlign: 'center',
});

const bottomLabelStyle = css({
  padding: '0 80px',
});

const codeContainerStyle = css({
  display: 'flex',
  margin: '10px 0px 0px 0px',
});

const codeInputStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.disabled,
  flex: 1,
  height: 40,
  borderRadius: '4px',
});

const submitButtonStyle = css({
  marginLeft: 16,
  height: 40,
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
});

export const TwoFactorGoogleComponent: React.FC<TwoFactorGoogleComponentProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const [code, setCode] = useState('');
  const { onTwoFactorGoogleSubmit } = props;

  const onCodeCheckClick = useCallback(() => {
    onTwoFactorGoogleSubmit(code);
  }, [code, onTwoFactorGoogleSubmit]);

  return (
    <>
      {props.qrCode && (
        <>
          <div css={qrCodeStyle}>
            <img
              alt="qrcode"
              src={`data:image/svg+xml;base64,${props.qrCode}`}
              css={qrCodeImageStyle}
            />
          </div>
          <div css={bottomStyle}>
            <span css={bottomLabelStyle}>{t('Enter the one-time code found in your Google Authenticator after scanning the QR code')}</span>
            <div css={codeContainerStyle}>
              <TextInputComponent
                onChange={setCode}
                value={code}
                css={codeInputStyle({ theme })}
                placeholder={t('Enter code here')}
              />

              <ButtonComponent
                css={submitButtonStyle}
                text={t('Submit')}
                isDisabled={!code || code.length !== 6}
                onClick={onCodeCheckClick}
              />
            </div>
          </div>
        </>
      )}
      {props.successMessage && props.isAlertOpen && (
        <AlertComponent
          type="success"
          css={alertStyle}
          autoCloseTimeout={5000}
          onClose={props.closeAlert}
        >
          {props.successMessage}
        </AlertComponent>
      )}
      {!props.successMessage && props.errorMessage && props.isAlertOpen && (
        <AlertComponent
          type="danger"
          css={alertStyle}
          autoCloseTimeout={5000}
          onClose={props.closeAlert}
        >
          {props.errorMessage}
        </AlertComponent>
      )}
    </>
  );
};
