import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { getUserData } from './repository/userData.repository';
import { userGetDataSuccess } from './userData.actionCreators';
import { USER_GET_DATA_REQUEST } from './userData.actionTypes';
import { userDataServerToClientModel } from './userData.dataMapping';

// Use this hook when you need to await the reload of user data.
// For async reload of user data use redux action;
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
USER_GET_DATA_REQUEST;

export const useReloadUserData = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    error,
    invokeAjax,
  } = useAjaxCall(getUserData);

  const reloadUserData = useCallback(async () => {
    const serverData = await invokeAjax();
    const userData = userDataServerToClientModel(serverData);

    dispatch(userGetDataSuccess(userData));

    return serverData;
  }, [dispatch, invokeAjax]);

  return {
    isLoading,
    error,
    reloadUserData,
  };
};
