import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { UniqueIdentifierSelectorComponent } from '~/_shared/components/uniqueIdentifierSelector/uniqueIdentifierSelector.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { UniqueIdentifierType } from './useUniqueIdentifierSelectorManager.hook';

const radioTextStyle = css({
  fontSize: '16px',
  marginTop: 2,
});

const radioLabelStyle = css({
  alignItems: 'center',
  display: 'flex',
  lineHeight: 1,
});

const radioItemStyle = css({
  flexDirection: 'column',
});

type UniqueIdentifierSelectorCombinedProps = {
  addressColumnIndexes: (number | null)[];
  checkForUniqueDoneForCurrentColumns: boolean;
  idColumnIndexes: (number | null)[];
  isCheckingIdentifier: boolean;
  isIdentifierValid: boolean;
  uIdType: UniqueIdentifierType | null;

  checkIdentifier?: () => void;
  removeAddressIdentifierColumnByIndex: (index: number) => void;
  setAddressColumnIndexes: (newValue: (number | null)[]) => void;
  setIdColumnIndexes: (newValue: (number | null)[]) => void;
  setUIdType: (uIdType: UniqueIdentifierType | null) => void;
};

type UniqueIdentifierRadioGroupComponentProps = Readonly<{
  columnOptions: ReadonlyArray<DropdownOption<number | null>>;
  radioLabelStyle?: SerializedStyles;
  radioTextStyle?: SerializedStyles;
  uniqueIdentifierData: UniqueIdentifierSelectorCombinedProps;
}>;

export const UniqueIdentifierRadioGroupComponent: FC<UniqueIdentifierRadioGroupComponentProps> = props => {
  const [t] = useTranslation();

  return (
    <RadioGroupComponent<UniqueIdentifierType>
      itemCommonStyle={radioItemStyle}
      items={[{
        children: props.uniqueIdentifierData.uIdType === UniqueIdentifierType.IdColumn && (
          <UniqueIdentifierSelectorComponent
            checkForUniqueDoneForCurrentColumns={props.uniqueIdentifierData.checkForUniqueDoneForCurrentColumns}
            columnOptions={props.columnOptions}
            identifierColumnIds={props.uniqueIdentifierData.idColumnIndexes}
            isChecking={props.uniqueIdentifierData.isCheckingIdentifier}
            isIdentifierUnique={props.uniqueIdentifierData.isIdentifierValid}
            onCheckIdentifier={props.uniqueIdentifierData.checkIdentifier}
            setIdentifierColumnIds={props.uniqueIdentifierData.setIdColumnIndexes}
          />
        ),
        label: <div css={props.radioTextStyle || radioTextStyle}>{t('Id Column')}</div>,
        value: UniqueIdentifierType.IdColumn,
      }, {
        children: props.uniqueIdentifierData.uIdType === UniqueIdentifierType.AddressColumns && (
          <UniqueIdentifierSelectorComponent
            allowAddColumns
            checkForUniqueDoneForCurrentColumns={props.uniqueIdentifierData.checkForUniqueDoneForCurrentColumns}
            columnOptions={props.columnOptions}
            identifierColumnIds={props.uniqueIdentifierData.addressColumnIndexes}
            isChecking={props.uniqueIdentifierData.isCheckingIdentifier}
            isIdentifierUnique={props.uniqueIdentifierData.isIdentifierValid}
            onCheckIdentifier={props.uniqueIdentifierData.checkIdentifier}
            onRemoveDropdown={props.uniqueIdentifierData.removeAddressIdentifierColumnByIndex}
            setIdentifierColumnIds={props.uniqueIdentifierData.setAddressColumnIndexes}
            title={t('Address Column')}
          />
        ),
        label: <div css={props.radioTextStyle || radioTextStyle}>{t('Address Column as Unique Identifier')}</div>,
        value: UniqueIdentifierType.AddressColumns,
      }]}
      labelStyle={props.radioLabelStyle || radioLabelStyle}
      onValueChange={props.uniqueIdentifierData.setUIdType as (uIdType: UniqueIdentifierType) => void}
      selectedValue={props.uniqueIdentifierData.uIdType}
    />
  );
};
