import memoizee from 'memoizee';
import tinycolor from 'tinycolor2';
import { type ThemeType } from '~/_shared/themes/themeType';
import {
  createColor, guaranteeHash, rgbColorToString,
} from '../../components/colorPicker/colorPicker.helpers';
import {
  type ColorResult, type RGBColor,
} from '../../components/colorPicker/colorPicker.types';
import { memoizeWeak } from '../memoize/memoize';
import { type PerThemeColor } from './colors.types';

export const getNextColorFromPresets = (usedColors: ReadonlyArray<string>, presetColors: readonly [string, ...string[]]): string => {
  const normalizedPresetColors = presetColors.map(color => guaranteeHash(color).toUpperCase());
  const normalizedUsedColors = usedColors.map(color => guaranteeHash(color).toUpperCase());

  let lastUsedPresetColor = normalizedPresetColors[normalizedPresetColors.length - 1];

  for (const usedRouteColor of normalizedUsedColors) {
    if (normalizedPresetColors.includes(usedRouteColor)) {
      lastUsedPresetColor = usedRouteColor;
    }
  }

  let indexOfNewColor = normalizedPresetColors.indexOf(lastUsedPresetColor ?? '') + 1;
  if (indexOfNewColor > normalizedPresetColors.length - 1) {
    indexOfNewColor = 0;
  }

  // TODO: Change to @ts-expect-error when we transition to the stricter TS config.
  // eslint-disable-next-line
  // @ts-ignore: The indexing is made safe by the condition above.
  return normalizedPresetColors[indexOfNewColor];
};

export const changeColorAlpha = memoizeWeak((color: RGBColor, newAlpha: number | undefined): ColorResult => {
  const newColor = { ...color };
  newColor.a = newAlpha;

  return createColor(newColor);
});

export const convertColorToWebGLColor = memoizee((color: string, opacity?: number): WebglColor => {
  const parsedColor = createColor(color).rgb;
  const base = [parsedColor.r, parsedColor.g, parsedColor.b] as const;

  return [...base, opacity ?? 1];
});

export const webGLColorToString = (color: WebglColor): string =>
  `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;

export const convertRGBColorToWebGLColor = memoizeWeak((color: RGBColor): WebglColor =>
  Object.values({ ...color, a: color.a || 1 }) as unknown as WebglColor);

const getColorBrightness = (color: RGBColor) =>
  Math.sqrt(
    color.r * color.r * .241 +
    color.g * color.g * .691 +
    color.b * color.b * .068,
  );

export const getSuitableBorderColor = (baseColor: ColorResult, backgroundColor: ColorResult): ColorResult => {
  const backgroundBrightness = getColorBrightness(backgroundColor.rgb);
  const baseBrightness = getColorBrightness(baseColor.rgb);

  let baseLightness = baseColor.hsl.l;
  let baseSaturation = baseColor.hsl.s;

  if (Math.abs(backgroundBrightness - baseBrightness) < 35) {
    if (backgroundBrightness > 130) {
      baseLightness = baseLightness - 0.15;
    }
    else {
      if (baseLightness < 0.05) {
        baseSaturation = 0.02;
        baseLightness = 0.6;
      }
      else {
        baseLightness = baseLightness + 0.15;
      }
    }
  }

  return createColor({
    ...baseColor.hsl,
    l: baseLightness,
    s: baseSaturation,
  });
};

export const getSuitableBorderColorString = (baseColor: string, backgroundColor: string): string => {
  return rgbColorToString(getSuitableBorderColor(createColor(baseColor), createColor(backgroundColor)).rgb);
};

export const makeColorLighter = (baseColor: string, lightnessDifference = 30): ColorResult => {
  const colorResultColor = createColor(baseColor);
  const newHslColor = {
    ...colorResultColor.hsl,
    l: Math.min(colorResultColor.hsl.l + lightnessDifference / 100, 1),
  };

  return createColor(newHslColor);
};

export const makeColorDarker = (baseColor: string, lightnessDifference = 20): ColorResult => {
  const colorResultColor = createColor(baseColor);

  const newHslColor = {
    ...colorResultColor.hsl,
    l: Math.max(colorResultColor.hsl.l - lightnessDifference / 100, 0),
  };

  return createColor(newHslColor);
};

/**
 * Gets a contrast color based on the base color and optional background color.
 *
 * @param baseColor - The base color to get the contrast for.
 * @param backgroundColor - The background color to compare against.
 * @param options - Optional settings for the contrast calculation.
 * @param options.tolerance - The tolerance level for brightness difference.
 * Brightness difference below this level will trigger a different contrast color.
 * Default is 85. Max value is 255 and min value is 0.
 * @returns The contrast color.
 */
export const getContrastColor = (
  baseColor: ColorResult,
  backgroundColor?: ColorResult,
  options?: { tolerance?: number }
) => getContrastColorMemoized(baseColor, backgroundColor, options?.tolerance ?? 85);

const getContrastColorMemoized = memoizeWeak((
  baseColor: ColorResult,
  backgroundColor: ColorResult | undefined,
  tolerance: number,
): ColorResult => {
  const backgroundBrightness = backgroundColor && getColorBrightness(backgroundColor.rgb);
  const baseBrightness = getColorBrightness(baseColor.rgb);

  let baseLightness = baseColor.hsl.l;

  if (!backgroundBrightness || Math.abs(backgroundBrightness - baseBrightness) < tolerance) {
    if ((backgroundBrightness ?? baseBrightness) > 130) {
      baseLightness = 0.15;
    }
    else {
      baseLightness = 0.85;
    }
  }

  return createColor({
    ...baseColor.hsl,
    l: baseLightness,
  });
});

export const getDarkOrLightDependingOnBaseColor = (baseColor: ColorResult, darkColor: ColorResult, lightColor: ColorResult): ColorResult => {
  const baseBrightness = getColorBrightness(baseColor.rgb);

  return baseBrightness > 130 ? darkColor : lightColor;
};

export const getFontAndBorderColorOnBackground = (
  fontColor: ColorResult, baseColor: ColorResult, backgroundColor: ColorResult,
): [borderColor: ColorResult, fontColor: ColorResult] => {
  const contrastTextColor = getContrastColor(fontColor, baseColor);
  const borderColor = getSuitableBorderColor(baseColor, backgroundColor);

  return [borderColor, contrastTextColor];
};

export const mixColorMemoized = memoizee((
  c1: string, c2: string, amount?: number,
) => tinycolor.mix(c1, c2, amount !== undefined ? amount * 100 : undefined));

export const getPerThemeColor = (perThemeColor: PerThemeColor, currentTheme: ThemeType): string | null => (
  perThemeColor[currentTheme] ?? null
);
