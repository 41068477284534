export const InvitationAcceptErrorCodes = {
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  USER_MISMATCH: 'USER_MISMATCH',
  ACCEPTED: 'ACCEPTED',
  SERVER_ERROR: 'SERVER_ERROR',
} as const;

export enum InviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}
