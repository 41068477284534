import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { DCInstance } from './dcInstance.container';
import { UnsavedDCInstance } from './unsavedDCInstance.container';

export const DistanceCalculatorOverlay: React.FC = () => {
  const instances = useSelector(state => state.map.mapSettings.data.toolsState.distanceCalculator.instances);

  return (
    <>
      {instances.map(instance => (
        <DCInstance
          key={instance.id}
          instance={instance}
        />
      ))}
      <UnsavedDCInstance />
    </>
  );
};
