import { css } from '@emotion/react';

export const radioGroupStyle = css({
  margin: '10px 0 15px',
  display: 'flex',
});

export const radioItemStyle = css({
  marginRight: '20px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
  textTransform: 'uppercase',
});
