import { Loader } from '@googlemaps/js-api-loader';
import { CONFIG } from '~/_shared/constants/config';
import { i18n } from '../../../i18nextSetup';

let loadPromise: Promise<typeof google.maps> | undefined;

export const loadGoogleMapsScript = () => {
  if (loadPromise) {
    return loadPromise;
  }

  const loader = new Loader({
    apiKey: CONFIG.GOOGLE_MAPS_API_KEY,
    libraries: ['geometry', 'drawing', 'visualization'],
    version: '3',
    channel: 'maptive',
    language: i18n.language,
  });

  loadPromise = loader.load().then(() => {
    return window.google.maps;
  });

  return loadPromise;
};
