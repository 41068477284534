import { type FC } from 'react';
import { DrawingTool } from '../../../drawingTool/drawingTool.enums';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
  useMapToolsDrawingActiveToolSelector,
} from '../../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { useDrawingSettingsSelector } from '../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { DrawingToolArrowCreateNewInstanceContainer } from './arrow/drawingToolArrowCreateNewInstance.container';
import { DrawingToolCalloutCreateNewInstanceContainer } from './callout/drawingToolCalloutCreateNewInstance.container';
import { DrawingToolCircleCreateNewInstanceContainer } from './circle/drawingToolCircleCreateNewInstance.container';
import { type DrawingToolManager } from './drawingToolManager';
import { DrawingToolIconMarkerCreateNewInstanceContainer } from './iconMarker/drawingToolIconMarkerCreateNewInstance.container';
import { DrawingToolImageCreateNewInstanceContainer } from './image/drawingToolImageCreateNewInstance.container';
import { DrawingToolLabelCreateNewInstanceContainer } from './label/drawingToolLabelCreateNewInstance.container';
import { DrawingToolNumberCreateNewInstanceContainer } from './number/drawingToolNumberCreateNewInstance.container';
import { DrawingToolPolygonCreateNewInstanceContainer } from './polygon/drawingToolPolygonCreateNewInstance.container';
import { DrawingToolPolylineCreateNewInstanceContainer } from './polyline/drawingToolPolylineCreateNewInstance.container';
import { DrawingToolRectangleCreateNewInstanceContainer } from './rectangle/drawingToolRectangleCreateNewInstance.container';
import { DrawingToolTextCreateNewInstanceContainer } from './text/drawingToolTextCreateNewInstance.container';

type DrawingToolCreateNewInstanceProps = Readonly<{
  drawingToolManager: DrawingToolManager;
}>;

export const DrawingToolCreateNewInstanceContainer: FC<DrawingToolCreateNewInstanceProps> = ({ drawingToolManager }) => {
  const activeDrawingTool = useMapToolsDrawingActiveToolSelector();
  const drawingSettings = useDrawingSettingsSelector();
  const drawingToolMode = useDrawingToolModeSelector();

  if (!activeDrawingTool || drawingToolMode !== DrawingToolMode.DrawingItems) {
    return null;
  }

  return (
    <div>
      {activeDrawingTool === DrawingTool.Arrow && (
        <DrawingToolArrowCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.arrow}
        />
      )}

      {activeDrawingTool === DrawingTool.Callout && (
        <DrawingToolCalloutCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.callout}
        />
      )}

      {activeDrawingTool === DrawingTool.Circle && (
        <DrawingToolCircleCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.circle}
        />
      )}

      {activeDrawingTool === DrawingTool.IconMarker && (
        <DrawingToolIconMarkerCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.iconMarker}
        />
      )}

      {activeDrawingTool === DrawingTool.Image && (
        <DrawingToolImageCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.image}
        />
      )}

      {activeDrawingTool === DrawingTool.Label && (
        <DrawingToolLabelCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.label}
        />
      )}

      {activeDrawingTool === DrawingTool.Number && (
        <DrawingToolNumberCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.number}
        />
      )}

      {activeDrawingTool === DrawingTool.Polygon && (
        <DrawingToolPolygonCreateNewInstanceContainer
          settings={drawingSettings.polygon}
        />
      )}

      {activeDrawingTool === DrawingTool.Polyline && (
        <DrawingToolPolylineCreateNewInstanceContainer
          settings={drawingSettings.polyline}
        />
      )}

      {activeDrawingTool === DrawingTool.Rectangle && (
        <DrawingToolRectangleCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.rectangle}
        />
      )}

      {activeDrawingTool === DrawingTool.Text && (
        <DrawingToolTextCreateNewInstanceContainer
          manager={drawingToolManager}
          settings={drawingSettings.text}
        />
      )}
    </div>
  );
};
