import { type MapSettingsToolsStateResponse } from '~/map/map.repository';
import { mapSettingsSearchItemsSerializer } from '../searchItems/mapSettingsSearchItems.serializer';
import { mapSettingsToolsStateBoundaryInitialState } from './boundary/mapSettingsToolsStateBoundary.reducer';
import { type MapSettingsToolsState } from './mapSettingsToolsState';
import { mapSettingsToolsStateInitialState } from './mapSettingsToolsState.reducer';

export const mapSettingsToolsStateSerializer = {
  jsonToState: (json: MapSettingsToolsStateResponse): MapSettingsToolsState => {
    const { searchItems, ...jsonRest } = json;

    return ({
      ...mapSettingsToolsStateInitialState,
      ...ensureAllBoundaryFilters(jsonRest),
      searchItems: mapSettingsSearchItemsSerializer.jsonToState(searchItems),
    });
  },
  stateToJson: (state: MapSettingsToolsState): MapSettingsToolsStateResponse => {
    const { searchItems, ...stateRest } = state;

    return ({
      ...stateRest,
      searchItems: mapSettingsSearchItemsSerializer.stateToJson(searchItems),
    });
  },
};

const ensureAllBoundaryFilters = (json: MapSettingsToolsStateResponse): MapSettingsToolsStateResponse => ({
  ...json,
  boundary: {
    filters: json.boundary?.filters || mapSettingsToolsStateBoundaryInitialState.filters,
    locationsFilter: json.boundary?.locationsFilter || mapSettingsToolsStateBoundaryInitialState.locationsFilter,
  },
});
