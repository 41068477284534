import { combineReducers } from 'redux';
import { drawingToolReducer } from '../drawingTool/drawingTool.reducer';
import { drawingEditReducer } from './drawingEdit/drawingEdit.reducer';
import { drawingImageSelectorReducer } from './drawingImageSelector/drawingImageSelector.reducer';

export const mapToolsDrawingReducer = combineReducers({
  drawingTool: drawingToolReducer,
  drawingEdit: drawingEditReducer,
  drawingImageSelector: drawingImageSelectorReducer,
});
