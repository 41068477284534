import {
  type FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mapReset } from '~/store/map/map.actionCreators';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { getMapUrl } from '../../../map/map.helpers';
import {
  useMapComponentCenterSelector,
  useMapComponentLastBoundsSelector,
  useMapComponentZoomSelector,
} from '../../../store/frontendState/mapComponent/mapComponent.selectors';
import { useMapInfoDataSelector } from '../../../store/mapInfo/mapInfo.selectors';
import { mapSettingsSettingsUpdateSettings } from '../../../store/mapSettings/settings/mapSettingsSettings.actionCreators';
import { useMapSettingsToolsStateSelector } from '../../../store/mapSettings/toolsState/mapSettingsToolsState.selectors';
import { type SidebarAppProps } from '../../sidebarAppComponentProps.type';
import { EditorState } from './editorState.enum';
import { SaveMapViewComponent } from './saveMapView.component';
import {
  type FieldErrors,
  useCreateMapSnapshot,
  useDeleteMapSnapshot,
  useUpdateMapSnapshot,
} from './saveMapView.hooks';
import { useSnapshotSettingsOverrides } from './useSnapshotSettingsOverrides.hook';

export const SaveMapViewContainer: FC<SidebarAppProps> = props => {
  const dispatch = useDispatch();
  const mapInfo = useMapInfoDataSelector();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(new Map());
  const [editorState, setEditorState] = useState<EditorState | undefined>(EditorState.Creating);
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds;
  const lastZoom = useMapComponentZoomSelector();
  const lastCenter = useMapComponentCenterSelector();
  const currentUserId = useUserIdSelector();
  const navigate = useNavigate();
  const { createSnapshot, isCreating } = useCreateMapSnapshot();
  const { updateSnapshot, isUpdating } = useUpdateMapSnapshot();
  const { deleteSnapshot, isDeleting } = useDeleteMapSnapshot();
  const snapshots = mapInfo?.snapshots ?? mapInfo?.parentMap?.snapshots ?? [];
  const currentToolsState = useMapSettingsToolsStateSelector();
  const isCurrentUserMapOwner = mapInfo?.created.id === currentUserId;
  const { getSnapshotSettingsOverrides } = useSnapshotSettingsOverrides();

  const submitSnapshot = () => {
    if (!mapInfo) {
      return;
    }

    if (editorState === EditorState.Editing) {
      if (!mapInfo.parentMap?.id) {
        return;
      }

      updateSnapshot(mapInfo.parentMap.id, { id: mapInfo.id, description, name })
        .then(() => {
          // we also want to update the initial bounds so when the snapshot is loaded it sets the map bounds the same place
          if (lastBounds && lastZoom && lastCenter) {
            dispatch(mapSettingsSettingsUpdateSettings({ initialMapCenterAndZoom: { center: lastCenter, zoom: lastZoom }, initialBounds: lastBounds.getBounds() }));
          }
        })
        .catch(setFieldErrors);
    }
    else {
      if (!lastBounds) {
        return;
      }

      createSnapshot(mapInfo.id, {
        name,
        description,
        settings_override: getSnapshotSettingsOverrides(
          currentToolsState, lastBounds,
        ),
      })
        .then(response => {
          setEditorState(EditorState.Editing);
          navigate(getMapUrl(response.data.id));
        })
        .catch((e) => {
          setFieldErrors(e);
        });
    }
  };

  const onDeleteSnapshot = async (snapshotId: number) => {
    try {
      await deleteSnapshot(snapshotId);
      // navigate to parent map on snapshot delete
      if (mapInfo?.id === snapshotId) {
        if (mapInfo.parentMap) {
          setEditorState(EditorState.Creating);
          navigate(getMapUrl(mapInfo.parentMap.id));
        }
        else {
          dispatch(mapReset());
          navigate('/');
        }
      }
    }
    catch (e) {
      setFieldErrors(e);
    }
  };

  useEffect(() => {
    setFieldErrors(new Map());

    if (editorState === EditorState.Editing) {
      setName(mapInfo?.name ?? '');
      setDescription(mapInfo?.description ?? '');
    }
    else {
      setName('');
      setDescription('');
    }
  }, [editorState, mapInfo]);

  useEffect(() => {
    const parentMap = mapInfo?.parentMap;
    if (parentMap) {
      if (isCurrentUserMapOwner) {
        setEditorState(EditorState.Editing);
      }
      else {
        setEditorState(EditorState.Creating);
      }
    }
    else {
      setEditorState(undefined);
    }
  }, [currentUserId, isCurrentUserMapOwner, mapInfo?.parentMap]);

  return (
    <SaveMapViewComponent
      snapshotModificationDisabled={!isCurrentUserMapOwner}
      currentUserId={currentUserId}
      onClose={props.onClose}
      onSave={submitSnapshot}
      snapshots={snapshots}
      onSnapshotDelete={onDeleteSnapshot}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      name={name}
      onNameChange={setName}
      description={description}
      onDescriptionChange={setDescription}
      isSaveDisabled={isCreating || isUpdating || isDeleting}
      isLoading={isCreating || isUpdating || isDeleting}
      fieldErrors={fieldErrors}
    />
  );
};
