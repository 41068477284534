export enum MapMarkersGraphicsToggleSettingsName {
  clusterDenseMarkers = 'clusterDenseMarkers',
  clusterWPieCharts = 'clusterWPieCharts',
  highVolumeMarkerDisplay = 'highVolumeMarkerDisplay',
  openLinksInSameWindow = 'openLinksInSameWindow',
  stackedMarkersWPieCharts = 'stackedMarkersWPieCharts',
  unstackMarkers = 'unstackMarkers',
}

export enum MapMarkersGraphicsToggleVisualSettingsName {
  useLabelsAboveMarkers = 'useLabelsAboveMarkers',
  useNumericLabel = 'useNumericLabel',
  useTextLabel = 'useTextLabel',
}

export enum MapMarkersGraphicsButtonSettingsName {
  onActivateMoveMarkers = 'onActivateMoveMarkers',
  onCustomizeActiveMarkerIndicator = 'onCustomizeActiveMarkerIndicator',
  onCustomizeAll = 'onCustomizeAll',
  onCustomizeLabels = 'onCustomizeLabels',
  onCustomizeLabelsAboveMarkers = 'onCustomizeLabelsAboveMarkers',
}

export enum MapMarkersGraphicsDropdownSettingsName {
  unclusterBelowN = 'unclusterBelowN',
}
