import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '../app.store';

const userDataSelector = (state: AppState) => state.userData;
export const useUserDataSelector = () => useSelector(userDataSelector);

const TwoFASelector = (state: AppState) => state.userData['2fa'];
export const useTwoFASelector = () => useSelector(TwoFASelector);

const userDataUsagesSelector = (state: AppState) => state.userData.usage;
export const useUserDataUsagesSelector = () => useSelector(userDataUsagesSelector);

export const userCurrentClientSelector = (state: AppState) => state.userData.currentClient;
export const useUserCurrentClientSelector = () => useSelector(userCurrentClientSelector);

const googleApiOauthUserDataSelector = (state: AppState) => state.userData.googleApiOauth;
export const useGoogleApiOauthUserDataSelector = () => useSelector(googleApiOauthUserDataSelector);

export const userIdSelector = (state: AppState) => state.userData.id;
export const useUserIdSelector = () => useSelector<number | null>(userIdSelector);

const userLimitsSelector = (state: AppState) => state.userData.limits;
export const useUserLimitsSelector = () => useSelector(userLimitsSelector);

const isWMSEnabledSelector = (state: AppState) => state.userData.limits.wmsEnabled;
export const useIsWMSEnabledSelector = () => useSelector(isWMSEnabledSelector);

const userRoleSelector = (state: AppState) => state.userData.role;
export const useUserRoleSelector = () => useSelector(userRoleSelector);

const isUserSignedInSelector = (state: AppState) => state.userData.isSignedIn;
export const useIsUserSignedInSelector = () => useSelector(isUserSignedInSelector);

const userSettingsSelector = (state: AppState) => state.userData.settings;

const currentClientUserSettingsSelector = (state: AppState) => {
  const settings = userSettingsSelector(state);
  const currentClientId = userCurrentClientSelector(state);
  if (settings && currentClientId) {
    return settings[currentClientId.id];
  }
  return null;
};
export const useCurrentClientUserSettingsSelector = () => useSelector(currentClientUserSettingsSelector);

export const clientMembersSelector = (state: AppState) => state.userData.clientMembers;
export const useClientMembers = () => useSelector(clientMembersSelector);
export const useClientOwner = () => useSelector(clientMembersSelector).find(m => m.role === 'Company Owner');
