import { type AutocompleteComponentProps } from './autocomplete.component';
import {
  type AutocompleteCustomOption, type AutocompleteOption,
} from './autocomplete.types';

export const isCustomOption = <T, >(option: AutocompleteOption<T>): option is AutocompleteCustomOption => 'render' in option;

export const compareOptionsValues = <T, >(
  valueA: T,
  valueB: T,
  comparer?: AutocompleteComponentProps<T>['customValueComparer']
): boolean => comparer ? comparer(valueA, valueB) : valueA === valueB;
