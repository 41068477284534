import Echo from 'laravel-echo';
import { type Channel } from 'laravel-echo/dist/channel';
import socketIdClient from 'socket.io-client';
import { CONFIG } from '~/_shared/constants/config';
import type {
  ItemizedExportId, ItemizedExportStatus,
} from '~/spreadsheet/itemizeExport.repository';
import { type BoundaryTerritoryGroupResponse } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import type {
  V4MapMigrationErrorMessage, V4MapMigrationProgressMessage,
} from '~/store/mapMigration/types/v4MapMigrationMessage.type';
import { type BoundaryCreateOptimizedStatusResponse } from '../../../map/map.repository';
import { getPrefixedApiUrl } from './api.helpers';

type SpreadsheetImportBroadcastData = {
  message: string;
  socket: null;
  spreadsheetId: number;
  userId: number;
};

export type GeocodingStartedEventBroadcastData = {
  clientId: number;
  message: string;
  percentage_progress: number;
  realSpreadsheetId: number;
  userId: number;
  virtualSpreadsheets: number[];
};

export type GeocodingEventBroadcastData = {
  client_id: number;
  virtual_id: number;
  real_spreadsheet_id: number;
  snapshot: number;
  version: number;
  user_id: number;
  percentage: number;
  counters: {
    completed: string; // percentage with % symbol
    total: number; // total count of records
    processed: {
      matches: number; // count of geopoints received from matched lat and lng
      cache: number; // count of geopoints received from cache
      api: number; // count of geopoints received from api
      failed: number; // count of failed geocoding requests
    };
  };
};

export type ConvertStartedEventBroadcastData = {
  approximate_time: string;
  boundary_group_id: number;
  boundary_territory_type: string;
  client_id: number;
  map_id: number;
};

export type ConvertEventBroadcastData = {
  btg_id: number | null;
  map_id: number;
  success: boolean;
};

let broadcast: Echo | undefined | null;
let userBroadcast: Channel | null = null;

type BroadcastInitParams = {
  userId: number;
};

export const initializeBroadcast = (params: BroadcastInitParams) => {
  const url = CONFIG.SOCKET_URL || getPrefixedApiUrl('');

  broadcast = new Echo({
    client: socketIdClient,
    broadcaster: 'socket.io',
    host: url,
  });

  userBroadcast = broadcast.private(`user.${params.userId}`);
};

export const destroyBroadcast = () => {
  if (!broadcast) {
    return;
  }

  broadcast.disconnect();
  broadcast = null;
};

export const getUserSpreadsheetImportBroadcast = (listener: (e: SpreadsheetImportBroadcastData) => void, onError: () => void) => {
  if (!userBroadcast) {
    onError();
    return;
  }

  return userBroadcast
    .stopListening('SpreadsheetImport')
    .listen('SpreadsheetImport', listener)
    .error(onError);
};

interface GeocodingEventBroadcastListenerParams {
  onStarted: (e: GeocodingStartedEventBroadcastData) => void;
  onEvent: (e: GeocodingEventBroadcastData) => void;
  onError: () => void;
}

export const registerGeocodingEventBroadcastListener = ({ onStarted, onEvent, onError }: GeocodingEventBroadcastListenerParams) => {
  if (!userBroadcast) {
    onError();
    return;
  }

  return userBroadcast
    .stopListening('GeocodingEvent')
    .listen('GeocodingEvent', onEvent)
    .stopListening('GeocodingStarted')
    .listen('GeocodingStarted', onStarted)
    .error(onError);
};

type MigrationListeners = {
  readonly onEvent: (message: V4MapMigrationProgressMessage) => void;
  readonly onError: (message?: V4MapMigrationErrorMessage) => void;
};

export const registerMigrationEventBroadcastListener = ({ onEvent, onError }: MigrationListeners) => {
  if (!userBroadcast) {
    onError();
    return;
  }

  return userBroadcast
    .stopListening('DataMigrated')
    .listen('DataMigrated', onEvent)
    .stopListening('DataMigrationFailed')
    .listen('DataMigrationFailed', onError)
    .error(onError);
};

export type OptimizedTerritoriesStatusEventData = {
  success: boolean;
  status: BoundaryCreateOptimizedStatusResponse;
  id: number;
};

export type OptimizedTerritoriesEventData = {
  message: string;
  id: number;
  btg: BoundaryTerritoryGroupResponse;
};

export type FailedOptimizedTerritoriesEventData = {
  message: string;
  id: number;
};

export type CanceledOptimizedTerritoriesEventData = {
  message: string;
  id: number;
};

type OptimizedTerritoriesEventBroadcastListenerCallbacks = {
  onSuccess: (e: OptimizedTerritoriesEventData) => void;
  onError: (e: FailedOptimizedTerritoriesEventData) => void;
  onStatusChange: (e: OptimizedTerritoriesStatusEventData) => void;
  onCancel: (e: CanceledOptimizedTerritoriesEventData) => void;
};

export const registerOptimizedTerritoriesBroadcastListener = ({ onSuccess, onStatusChange, onError, onCancel }: OptimizedTerritoriesEventBroadcastListenerCallbacks) => {
  if (!userBroadcast) {
    return;
  }

  return userBroadcast
    .stopListening('OptimizedTerritories\\ProcessingEvent')
    .listen('OptimizedTerritories\\ProcessingEvent', onStatusChange)
    .stopListening('OptimizedTerritories\\FailedEvent')
    .listen('OptimizedTerritories\\FailedEvent', onError)
    .stopListening('OptimizedTerritories\\FinishedEvent')
    .listen('OptimizedTerritories\\FinishedEvent', onSuccess)
    .stopListening('OptimizedTerritories\\CanceledEvent')
    .listen('OptimizedTerritories\\CanceledEvent', onCancel);
};

type TeamMemberStatusEventData = Readonly<{
  client_id: number;
  disabled: Readonly<{
    id: number;
    name: string;
  }>;
}>;

export const registerMemberDisabledBroadcastListener = (onEvent: (e: TeamMemberStatusEventData) => void) => {
  if (!userBroadcast) {
    return;
  }

  userBroadcast
    .stopListening('DeactivatedUserEvent')
    .listen('DeactivatedUserEvent', onEvent);
};

type ItemizedExportBroadcastData = Readonly<{
  status: ItemizedExportStatus;
  hash: ItemizedExportId;
}>;

export const getItemizedExportBroadcast = (listener: (e: ItemizedExportBroadcastData) => void, onError: () => void) => {
  if (!userBroadcast) {
    onError();
    return;
  }

  return userBroadcast
    .stopListening('SpreadsheetItemizedRowsExportEvent')
    .listen('SpreadsheetItemizedRowsExportEvent', listener)
    .error(onError);
};
