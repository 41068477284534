import type {
  MutableRefObject, Ref,
} from 'react';

export const isForwardRefComponent = (element: any) => {
  return element?.type?.$$typeof === Symbol.for('react.forward_ref');
};

export const preserveRef = <T extends HTMLElement>(ref: Ref<T>, node: T) => {
  if (ref) {
    if (typeof ref === 'function') {
      ref(node);
    }
    if ({}.hasOwnProperty.call(ref, 'current')) {
      (ref as MutableRefObject<T>).current = node;
    }
  }
};
