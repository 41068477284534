import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type FC, useMemo, useState,
} from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '../scrollbar/scrollbar.component';
import { TabComponent } from './tab.component';
import { type TabsData } from './tabs.types';

type TabsProps = Readonly<{
  activeTabIndex?: number;
  className?: string;
  data: TabsData;
  hideHeader?: boolean;
  tabStyle?: SerializedStyles;

  setActiveTabIndex?: (index: number) => void;
  setTabsHeaderRef?: (tabHeaderRef: HTMLLIElement) => void;
}>;

const tabsWrapperStyle = css({
  position: 'relative',
  height: '100%',
});

const tabListStyle = css({
  display: 'flex',
  justifyItems: 'baseline',
  width: '100%',
  padding: 0,
  margin: 0,
});

const scrollbarWrapperStyle = css({
  position: 'relative',
  width: '100%',
  // By default scrollbar calculates holder height and then uses relative and absolute position for the content.
  // Height calculation however doesn't work with tabs component at the moment and is disabled (translateContentHeightToHolder).
  // Since content is usining position absolute internally the holder needs to have fixed height otherwise it would collapse to 0px.
  // We have two options here:
  // 1) provide fixed height to holder (tabs component). This is hard if tabs are used in environment without fixed height and should stretch based on content.
  // 2) disable the absolute positioning and let the content set its own heigh according to the normal flow of the page. [USED BELOW]
  '& > .ScrollbarsCustom > .ScrollbarsCustom-Wrapper': {
    position: 'static !important' as any,
  },
  '& > .ScrollbarsCustom > .ScrollbarsCustom-Wrapper > .ScrollbarsCustom-Scroller': {
    position: 'static !important' as any,
  },
});

export const TabsComponent: FC<TabsProps> = (props) => {
  const [activeTab, setActiveTab] = useState<number>(props.data[0].index);

  const tabChildren = useMemo(() =>
    props.data.map(child => (
      <TabComponent
        css={props.tabStyle}
        activeTab={props.activeTabIndex ?? activeTab}
        index={child.index}
        key={child.index}
        header={child.header}
        onClick={(tab) => (props.setActiveTabIndex || setActiveTab)(tab)}
        setTabsHeaderRef={props.setTabsHeaderRef}
        testid={child.testid}
      />
    )), [
    activeTab, props.activeTabIndex, props.data, props.setActiveTabIndex, props.setTabsHeaderRef, props.tabStyle,
  ]);

  const data = useMemo(() =>
    props.data.find((tabChild) => tabChild.index === (props.activeTabIndex ?? activeTab))?.child,
  [activeTab, props.activeTabIndex, props.data]);

  return (
    <div
      className={props.className}
      css={tabsWrapperStyle}
    >
      {!props.hideHeader && (
        <ul css={tabListStyle}>
          {tabChildren}
        </ul>
      )}
      <div css={scrollbarWrapperStyle} >
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
          translateContentHeightToHolder={false}
        >
          {data}
        </ScrollBarComponent>
      </div>
    </div>
  );
};
