import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { createMapLimitReachedAppError } from '~/appError/specificErrors/mapLimitReached/mapLimitReached.helpers';
import {
  type GetGoogleSpreadsheetDataSuccessResponse,
  getGoogleSpreadsheetSheetData, type GoogleSpreadsheetInfo,
} from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import {
  closeAllModals, createAppError,
} from '~/store/modal/modal.actionCreators';
import { useCurrentGoogleAccountIdSelector } from '~/store/selectors/googleUserSelectors.selector';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  type GoogleSheetMapSyncType, MAP_LIMIT_REACHED_MESSAGE,
} from '../../map.repository';
import {
  convertGoogleSheetRowsToMaptiveRows, createGoogleSheetMap,
} from '../createNewMap.helpers';

const MARKER_LIMIT_GOOGLE_SHEET = 100000;

export type CreateMapFromGoogleSheetProps = Readonly<{
  mapDescription?: string;
  mapName: string;
  selectedGoogleSpreadsheetInfo: GoogleSpreadsheetInfo;
  sheetId: string;
  uniqueIdentifierColumnIds: number[];
  syncMethod: GoogleSheetMapSyncType;
  successCallback?: (mapId: number) => void;
}>;

export const useCreateMapFromGoogleSheet = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const currentGoogleAccId = useCurrentGoogleAccountIdSelector();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const createMapFromGoogleSheet = useCallback((props: CreateMapFromGoogleSheetProps) => {
    const { mapName, mapDescription, sheetId, successCallback, selectedGoogleSpreadsheetInfo, syncMethod } = props;

    const firstOrSelectedSheetId = sheetId || selectedGoogleSpreadsheetInfo.sheets[0]?.sheetId;
    const selectedSheet = selectedGoogleSpreadsheetInfo.sheets.find((sheet) => (
      sheet.sheetId === firstOrSelectedSheetId
    )) || selectedGoogleSpreadsheetInfo.sheets[0];

    if (!clientId || !selectedSheet || !currentGoogleAccId) {
      return;
    }

    setIsLoading(true);
    getGoogleSpreadsheetSheetData(
      currentGoogleAccId,
      selectedGoogleSpreadsheetInfo.spreadsheetId,
      selectedSheet.sheetId,
    ).then((response: GetGoogleSpreadsheetDataSuccessResponse) => {

      const rows = response.data?.rows || [];

      if (!rows.length) {
        setErrorMessage(t('Unable to get data from Google Sheets, please try again.'));
        setIsLoading(false);
        return;
      }

      if (currentGoogleAccId && selectedSheet.title) {
        if (rows.length < MARKER_LIMIT_GOOGLE_SHEET) {

          const replacedNewLines: string = convertGoogleSheetRowsToMaptiveRows(rows);

          createGoogleSheetMap({
            clientId,
            dataSource: replacedNewLines,
            googleAccountId: currentGoogleAccId,
            mapDescription,
            mapName,
            sheetName: selectedSheet.title,
            spreadsheetId: selectedGoogleSpreadsheetInfo.spreadsheetId,
            syncMethod,
            uniqueKeys: props.uniqueIdentifierColumnIds,
          }).then(newMap => {
            successCallback?.(newMap.data.id);
          }).catch((e: ApiError) => {
            const message = e?.errors?.maps_limit?.[0] ?? e.message;
            if (message === MAP_LIMIT_REACHED_MESSAGE) {
              dispatch(closeAllModals());
              dispatch(createMapLimitReachedAppError(t));
            }
            else {
              dispatch(createAppError({
                type: AppErrorType.General,
                title: t('Map creation failed'),
                errorTitle: message,
              }));
            }
          });
        }

        else {
          setErrorMessage(t('You Have Too Many Rows In Your Google Sheets File'));
          setIsLoading(false);
        }
      }
      else {
        setErrorMessage(t('Not logged into Google account'));
        setIsLoading(false);
      }
    }).catch(e => {
      setErrorMessage(t('googleSheets.create.unable'));
      console.error('Error creating Google Sheet: ', e.message);
      setIsLoading(false);
    });
  }, [clientId, currentGoogleAccId, dispatch, t]);

  return {
    createMapFromGoogleSheet,
    errorMessage,
    isLoading,
  };
};
