import { css } from '@emotion/react';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { type ColorResult } from 'react-color';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import {
  createColor, createColorWithOpacity, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { BoundaryTerritoryListingLimit } from '~/boundary/constants';
import { useLoadMoreListing } from '~/boundary/hooks/useLoadMoreListing';
import { BoundarySettingsLoadMoreRowComponent } from '~/boundary/settings/table/row/BoundarySettingsLoadMoreRow.component';
import { isBoundaryTerritoryEmpty } from '~/sidebar/sidebarApps/mapTools/boundary/fill/boundaryFill.helpers';
import type { BoundaryTerritoryId } from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryTerritoryAssignments } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { translateBoundaryTerritoryDisplayName } from '../boundarySettings.helpers';
import { BoundarySettingsCustomTerritoryDataComponent } from '../custom/boundarySettingsCustomTerritoryDataComponent';
import { BoundarySettingsGroupNameComponent } from '../groupName/boundarySettingsGroupName.component';
import { LineColorComponent } from '../lineColor/lineColor.component';
import { LineExampleComponent } from '../lineExample/lineExample.component';
import { LineWidthComponent } from '../lineWidth/lineWidth.component';
import { DEFAULT_TERRITORY_OPACITY } from '../numeric/boundarySettingsNumeric.component';
import { BoundarySettingsRestoreButtonComponent } from '../restoreButton/boundarySettingsRestoreButton.component';
import { BoundarySettingsTableComponent } from '../table/boundarySettingsTable.component';
import { BoundarySettingsTableRowComponent } from '../table/row/boundarySettingsTableRow.component';

const toggleLabelStyle = (theme: Theme) => css({
  fontSize: 12,
  textTransform: 'uppercase',
  color: theme.textColors.secondary,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: 4,
});

const boundarySettingsWrapperStyle = css({
  display: 'flex',
  padding: '20px 24px',
});

const boundaryColumnStyle = css({
  width: '50%',
  paddingLeft: 10,
  '&:first-of-type': {
    padding: 0,
  },
});

const lineColorWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

const lineColorStyle = css({
  minWidth: 150,
});

const boundarySettingsActionsWrapperStyle = (showDelimiter: boolean) => (theme: Theme) => css({
  padding: '5px 24px 20px',
  borderBottom: showDelimiter ? `1px solid ${theme.borderColors.primary}` : undefined,
  display: 'flex',
  justifyContent: 'space-between',
});

const restoreColorsButtonStyle = css({
  padding: 0,
});

type BoundarySettingsGroupProps = Readonly<{
  boundaryGroupName: string;
  boundaryTerritoryGroup: BoundaryTerritoryGroup;
  onBoundaryGroupNameChange?: (newName: string) => void;
  onBoundaryTerritoryGroupChange: (territoryGroup: BoundaryTerritoryGroup) => void;
  setIsFormValid: (isFormValid: boolean) => void;
  restoreDefaultColors: () => void;
}>;

export const BoundarySettingsGroupComponent: FC<BoundarySettingsGroupProps> = (props) => {
  const [t] = useTranslation();
  const [showEmptyBoundaries, setShowEmptyBoundaries] = useState(false);
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();

  const isBoundaryTerritoryNonEmpty = useCallback((territory: BoundaryTerritory) =>
    !isBoundaryTerritoryEmpty(boundaryTerritoryAssignments, props.boundaryTerritoryGroup.boundaryTerritoryGroupId, territory),
  [boundaryTerritoryAssignments, props.boundaryTerritoryGroup.boundaryTerritoryGroupId]);

  const setIsFormValidRef = useRef(props.setIsFormValid);

  const {
    limit: boundarySettingListCountLimit,
    increaseLimit: increaseBoundarySettingListCountLimit,
  } = useLoadMoreListing(BoundaryTerritoryListingLimit);

  const boundaryTerritories = props.boundaryTerritoryGroup.settings.boundaryTerritories;
  const lineWidth = props.boundaryTerritoryGroup.settings.style.lineWidth;
  const lineColor = createColor(props.boundaryTerritoryGroup.settings.style.lineColor);
  const nonEmptyBoundaryTerritories = useMemo(() => boundaryTerritories.filter(isBoundaryTerritoryNonEmpty),
    [boundaryTerritories, isBoundaryTerritoryNonEmpty]);
  const boundaryTerritoriesToShow = showEmptyBoundaries ? boundaryTerritories : nonEmptyBoundaryTerritories;
  const hasEmptyBoundaryTerritories = boundaryTerritories.length > nonEmptyBoundaryTerritories.length;

  const handleChangeLineWidth = (width: number) => {
    props.onBoundaryTerritoryGroupChange({
      ...props.boundaryTerritoryGroup,
      settings: {
        ...props.boundaryTerritoryGroup.settings,
        style: {
          ...props.boundaryTerritoryGroup.settings.style,
          lineWidth: width,
        },
      },
    });
  };

  const handleChangeLineColor = (color: ColorResult) => {
    props.onBoundaryTerritoryGroupChange({
      ...props.boundaryTerritoryGroup,
      settings: {
        ...props.boundaryTerritoryGroup.settings,
        style: {
          ...props.boundaryTerritoryGroup.settings.style,
          lineColor: guaranteeHash(color.hex),
        },
      },
    });
  };

  const handleChangeTerritoryColor = useCallback((id: BoundaryTerritoryId) => (color: ColorResult) => {
    const onChange = props.onBoundaryTerritoryGroupChange;
    onChange({
      ...props.boundaryTerritoryGroup,
      settings: {
        ...props.boundaryTerritoryGroup.settings,
        boundaryTerritories: boundaryTerritories.map((bt) => {
          if (id !== bt.boundaryTerritoryId) {
            return bt;
          }
          else {
            return {
              ...bt,
              style: {
                ...bt.style,
                color: guaranteeHash(color.hex),
                opacity: (color.rgb?.a ?? DEFAULT_TERRITORY_OPACITY) * 100,
              },
            };
          }
        }),
      },
    });
  }, [boundaryTerritories, props.boundaryTerritoryGroup, props.onBoundaryTerritoryGroupChange]);

  const handleChangeTerritoryName = useCallback((id: string) => (name: string) => {
    const onChange = props.onBoundaryTerritoryGroupChange;
    onChange({
      ...props.boundaryTerritoryGroup,
      settings: {
        ...props.boundaryTerritoryGroup.settings,
        boundaryTerritories: props.boundaryTerritoryGroup.settings.boundaryTerritories.map(
          bt => bt.boundaryTerritoryId === id ?
            {
              ...bt,
              displayName: name,
              translate: false,
            } : bt),
      },
    });
  }, [props.boundaryTerritoryGroup, props.onBoundaryTerritoryGroupChange]);

  useEffect(() => {
    for (const boundaryTerritory of boundaryTerritories) {
      if (isTextEmpty(boundaryTerritory.displayName)) {
        setIsFormValidRef.current(false);
        return;
      }
    }

    setIsFormValidRef.current(true);
  }, [boundaryTerritories]);

  return (
    <div>
      {props.onBoundaryGroupNameChange && (
        <BoundarySettingsGroupNameComponent
          groupName={props.boundaryGroupName}
          onGroupNameChange={props.onBoundaryGroupNameChange}
        />
      )}

      <LineExampleComponent
        lineWidth={lineWidth}
        lineColor={lineColor}
      />

      <div css={boundarySettingsWrapperStyle}>
        <div css={boundaryColumnStyle}>
          <LineWidthComponent
            lineWidth={lineWidth}
            onLineWidthChange={handleChangeLineWidth}
          />
        </div>

        <div css={[boundaryColumnStyle, lineColorWrapperStyle]}>
          <LineColorComponent
            css={lineColorStyle}
            lineColor={lineColor}
            onLineColorChange={handleChangeLineColor}
          />
        </div>
      </div>

      <div css={boundarySettingsActionsWrapperStyle(!!boundaryTerritoriesToShow.length)}>
        <BoundarySettingsRestoreButtonComponent
          css={restoreColorsButtonStyle}
          prefixIcon={faSync}
          text={t('Restore Default Colors')}
          onClick={props.restoreDefaultColors}
        />
        {hasEmptyBoundaryTerritories && (
          <label css={toggleLabelStyle}>
            {t('Show empty groups')}
            <ToggleComponent
              isOn={showEmptyBoundaries}
              onChange={setShowEmptyBoundaries}
            />
          </label>
        )}
      </div>

      {boundaryTerritoriesToShow.length > 0 && (
        <BoundarySettingsTableComponent
          dataHeadingLabel={t('Group Names')}
          dataRows={(
            <>
              {boundaryTerritoriesToShow.map((item, index) => {
                if (index >= boundarySettingListCountLimit) {
                  return null;
                }

                const color = createColorWithOpacity(
                  item.style.color,
                  item.style.opacity / 100,
                );

                return (
                  <BoundarySettingsTableRowComponent
                    key={item.boundaryTerritoryId}
                    index={index + 1}
                    data={item.custom ? (
                      <BoundarySettingsCustomTerritoryDataComponent
                        territory={item}
                        onTerritoryNameChange={handleChangeTerritoryName}
                      />
                    ) : translateBoundaryTerritoryDisplayName(item, t)}
                    lineColor={lineColor}
                    lineWidth={lineWidth}
                    color={color}
                    onColorChange={handleChangeTerritoryColor(item.boundaryTerritoryId)}
                  />
                );
              })}
              {(boundaryTerritoriesToShow.length > boundarySettingListCountLimit) && (
                <BoundarySettingsLoadMoreRowComponent onClick={increaseBoundarySettingListCountLimit} />
              )}
            </>
          )}
        />
      )}
    </div>
  );
};
