import {
  type FC, useMemo,
} from 'react';
import { type MarkerEntityOffsets } from '~/_shared/utils/markers/offsets.helpers';
import { ActiveMarkerBouncingIndicatorComponent } from './activeMarkerBouncingIndicator.component';
import { ActiveMarkerCircleArrowIndicatorContainer } from './activeMarkerCircleArrowIndicator.container';
import { indicatorStyles } from './activeMarkerIndicator.component';
import {
  activeMarkerIndicatorsProperties, DEFAULT_INDICATOR_COLOR,
} from './activeMarkerIndicator.constants';
import {
  ActiveMarkerIndicators, type SvgActiveMarkerIndicator,
} from './activeMarkerIndicator.types';
import { NeonArrowComponent } from './multiColorIndicators/neonArrow.component';
import { TripleNeonArrowComponent } from './multiColorIndicators/tripleNeonArrow.component';

type ActiveMarkerIndicatorProps = {
  bouncingIndicatorType: SvgActiveMarkerIndicator;
  className?: string;
  color: string;
  markerIndicatorOffsets?: MarkerEntityOffsets;
  size: number;
  speed: number;
};

export const ActiveMarkerBouncingIndicatorContainer: FC<ActiveMarkerIndicatorProps> = (props) => {

  const dimensions = useMemo(() => ({
    width: activeMarkerIndicatorsProperties[props.bouncingIndicatorType].dimensions.width * (props.size / 100),
    height: activeMarkerIndicatorsProperties[props.bouncingIndicatorType].dimensions.height * (props.size / 100),
  }), [props.bouncingIndicatorType, props.size]);

  const simpleArrowStyle = useMemo(() => indicatorStyles({
    color: props.color || DEFAULT_INDICATOR_COLOR,
    dimensions,
    svg: activeMarkerIndicatorsProperties[props.bouncingIndicatorType].path,
  }), [dimensions, props.bouncingIndicatorType, props.color]);

  const offset = useMemo(() => {
    if (!props.markerIndicatorOffsets) {
      return;
    }

    return {
      left: props.markerIndicatorOffsets.leftOffset,
      top: props.markerIndicatorOffsets.topOffset,
    };
  }, [props.markerIndicatorOffsets]);

  const bouncingIndicator = useMemo(() => {
    switch (props.bouncingIndicatorType) {
      case ActiveMarkerIndicators.basicCircleArrow:
      case ActiveMarkerIndicators.fatCircleArrow:
      case ActiveMarkerIndicators.slimCircleArrow:
        return (
          <ActiveMarkerCircleArrowIndicatorContainer
            color={props.color}
            dimensions={dimensions}
            circleArrowType={props.bouncingIndicatorType}
          />
        );

      case ActiveMarkerIndicators.tripleNeonArrow:
        return (
          <TripleNeonArrowComponent
            color={props.color}
            dimensions={dimensions}
          />
        );

      case ActiveMarkerIndicators.neonArrow:
        return (
          <NeonArrowComponent
            color={props.color}
            dimensions={dimensions}
          />
        );

      case ActiveMarkerIndicators.chevronArrow:
      case ActiveMarkerIndicators.simpleArrow:
        return <div css={simpleArrowStyle} />;

      default:
        return null;
    }
  }, [dimensions, props.bouncingIndicatorType, props.color, simpleArrowStyle]);

  return (
    <ActiveMarkerBouncingIndicatorComponent
      className={props.className}
      dimensions={dimensions}
      offset={offset}
      speed={props.speed}
    >
      {bouncingIndicator}
    </ActiveMarkerBouncingIndicatorComponent>
  );
};
