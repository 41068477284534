import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { ImageSelectorComponent } from '~/_shared/components/imageSelector/imageSelector.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { isNullOrUndefined } from '~/_shared/utils/typeGuards';
import { ActiveMarkerIndicatorComponent } from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.component';
import { ActiveMarkerIndicators } from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.types';
import { useActiveMarkerIndicatorContext } from '../activeMarkerIndicatorContext';

const MAX_ELEMENTS_IN_LINE = 9;
const TILE_DIMENSIONS = { height: 100, width: 64 };
const LIBRARY_PADDING = 8;

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const labelStyle = css({
  cursor: 'default',
  fontSize: 16,
  marginBottom: 4,
  textTransform: 'uppercase',
});

const selectorStyle = (props: {
  numberOfElements: number;
  tileHeightAndMargin: number;
}) => css({
  height: `${Math.ceil(props.numberOfElements / MAX_ELEMENTS_IN_LINE) * props.tileHeightAndMargin}px`,
  width: `calc(100% + ${LIBRARY_PADDING}px)`,
  margin: '4px 0 4px',
});

export const ActiveIndicatorListComponent: FC = () => {
  const [t] = useTranslation();

  const activeMarkerIndicatorData = useActiveMarkerIndicatorContext();

  const indicatorsOrdered = useMemo(() => Object.values(ActiveMarkerIndicators), []);

  const images = useMemo(() => indicatorsOrdered.map(indicator => ({
    id: indicatorsOrdered.indexOf(indicator),
    render: () => (
      <ActiveMarkerIndicatorComponent
        currentActiveIndicator={indicator}
        color={activeMarkerIndicatorData.activeMarkerIndicatorSettings.color}
        speed={activeMarkerIndicatorData.activeMarkerIndicatorSettings.speed}
      />
    ),
  })), [activeMarkerIndicatorData.activeMarkerIndicatorSettings.color, activeMarkerIndicatorData.activeMarkerIndicatorSettings.speed, indicatorsOrdered]);

  const handleSelectionChange = useCallback(([firstElementId]: number[]) => {
    if (!isNullOrUndefined(firstElementId)) {
      activeMarkerIndicatorData.changeActiveMarkerIndicatorSettings({
        type: indicatorsOrdered[firstElementId],
      });
    }
  }, [activeMarkerIndicatorData, indicatorsOrdered]);

  return (
    <div css={wrapperStyle}>
      <div css={labelStyle}>
        {t('Active Marker Indicators')}
      </div>
      <div
        css={selectorStyle({
          numberOfElements: indicatorsOrdered.length,
          tileHeightAndMargin: TILE_DIMENSIONS.height + LIBRARY_PADDING,
        })}
      >
        <ImageSelectorComponent
          imageHeight={TILE_DIMENSIONS.height}
          imageWidth={TILE_DIMENSIONS.width}
          dontUseScrollbar
          paddingVertical={LIBRARY_PADDING}
          paddingHorizontal={LIBRARY_PADDING}
          selectedImagesIds={[indicatorsOrdered.indexOf(activeMarkerIndicatorData.activeMarkerIndicatorSettings.type)]}
          images={images}
          onSelectionChanged={handleSelectionChange}
        />
      </div>
    </div>
  );
};
