import { useCallback } from 'react';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';

export const useBoundaryStateItem = () => {
  const boundaryItems = useMapBoundariesSelector();

  const getBoundaryStateItem = useCallback((boundaryId: number, boundaryGroupId: number) => {
    return boundaryItems.get(boundaryGroupId)?.get(boundaryId) ?? null;
  }, [boundaryItems]);

  return {
    getBoundaryStateItem,
  };
};
