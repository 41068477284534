import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  RoundButtonComponent,
  RoundButtonSize,
} from '~/_shared/baseComponents/buttons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ColumnRole } from '~/_shared/types/columnRole.enum';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { getItemStyles } from '~/customizeLocationPanel/customizeLocationPanel.constants';
import { EXTENDED_PANEL_WIDTH } from '~/sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import {
  SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.constants';
import { SidebarTitleType } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.enums';
import {
  type SidebarTitleSettings,
  type SidebarTitleStyle,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { getColumnItemById } from '../locationData/locationData.helpers';
import { type BubbleItemMap } from '../locationData/locationData.types';
import {
  type ColumnStyleSettingsData, type GeneralPanelSettings,
} from '../locationData/panelSettings.types';
import { ColorsAndFontsEditableComponent } from './colorsAndFontsEditable/colorsAndFontsEditable.component';

const TAB_HEADER_HEIGHT = 42;

const panelStyle = ({ width }: {width: number}) => css({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  height: '100%',
  left: width,
  overflow: 'visible',
  position: 'absolute',
  top: 0,
  width: 0,
});

const tabsPanesStyle = ({ width }: { width: number }) => css({
  boxSizing: 'border-box',
  height: '100%',
  padding: '0 10px',
  width: `${width}px`,
});

const exitIconWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  height: TAB_HEADER_HEIGHT,
  paddingTop: 10,
  width: '100%',
});

const editableSectionStyle = css({
  height: `calc(100% - ${TAB_HEADER_HEIGHT}px)`,
});

type EditableOptionsProps = Readonly<{
  bubbleItems: BubbleItemMap;
  bulkStyle: ColumnStyleSettingsData;
  generalSettings: GeneralPanelSettings;
  locationDescriptionStyle?: SidebarTitleStyle | null;
  selectedItemId: SpreadsheetColumnId | null;
  selectedLocationDescriptionType: SidebarTitleType | null;

  onChangeGeneral?: (settings: Partial<GeneralPanelSettings>) => void;
  onChangeItemStyle: (style: Partial<ColumnStyleSettingsData>) => void;
  onChangeLocationDescriptionStyle: (settings: Partial<SidebarTitleSettings>) => void;
  onStopEditing: () => void;
}>;

export const EditableOptionsComponent: FC<EditableOptionsProps> = props => {
  const [selectedBubbleType, setSelectedBubbleType] = useState<ColumnRole | null>(null);
  const theme = useTheme();

  const { onChangeItemStyle, onChangeLocationDescriptionStyle } = props;

  useEffect(() => {
    if (props.selectedItemId !== null) {
      const itemType = getColumnItemById(props.bubbleItems, props.selectedItemId)?.itemType || null;
      setSelectedBubbleType(itemType);
    }
    else {
      setSelectedBubbleType(null);
    }
  }, [props.bubbleItems, props.selectedItemId]);

  const locationItemStyleSettings = useMemo((): SidebarTitleSettings | null => {
    switch (props.selectedLocationDescriptionType) {
      case SidebarTitleType.header:
        return props.locationDescriptionStyle?.header || SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE(theme);

      case SidebarTitleType.semiHeader:
        return props.locationDescriptionStyle?.semiHeader || SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE(theme);

      case SidebarTitleType.description:
        return props.locationDescriptionStyle?.description || SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE(theme);

      default:
        return null;
    }
  }, [props.locationDescriptionStyle?.description, props.locationDescriptionStyle?.header,
    props.locationDescriptionStyle?.semiHeader, props.selectedLocationDescriptionType, theme]);

  const itemStyleSettings: ColumnStyleSettingsData | null = useMemo(() => {
    if (props.selectedItemId !== null) {
      const item = getColumnItemById(props.bubbleItems, props.selectedItemId);
      if (!item) {
        return props.bulkStyle;
      }
      return getItemStyles(theme, item, props.bulkStyle);
    }
    else if (props.selectedLocationDescriptionType === null) {
      return props.bulkStyle;
    }
    else {
      return null;
    }
  }, [props.bubbleItems, props.bulkStyle, props.selectedItemId, props.selectedLocationDescriptionType, theme]);

  const handleStyleChange = useCallback((style: Partial<ColumnStyleSettingsData | SidebarTitleSettings>) => {
    if (props.selectedItemId !== null || !props.selectedLocationDescriptionType) {
      onChangeItemStyle(style);
    }
    else {
      onChangeLocationDescriptionStyle(style);
    }
  }, [onChangeItemStyle, onChangeLocationDescriptionStyle, props.selectedItemId, props.selectedLocationDescriptionType]);

  return (
    <div css={panelStyle({ width: EXTENDED_PANEL_WIDTH })}>

      <div css={tabsPanesStyle({ width: EXTENDED_PANEL_WIDTH })} >
        <div css={exitIconWrapperStyle}>
          <RoundButtonComponent
            buttonStyle={RoundButtonStyle.Quaternary}
            icon={faXmark}
            onClick={props.onStopEditing}
            size={RoundButtonSize.Small}
          />
        </div>

        <div css={editableSectionStyle}>
          <ColorsAndFontsEditableComponent
            bubbleItemType={selectedBubbleType}
            generalSettings={props.generalSettings}
            locationDescriptionStyle={locationItemStyleSettings}
            onChangeStyle={handleStyleChange}
            onChangeGeneral={props.onChangeGeneral}
            selectedItemStyle={itemStyleSettings}
          />
        </div>
      </div>
    </div>
  );
};
