import { css } from '@emotion/react';
import { type FC } from 'react';
import { zIndexGeneral } from '../../constants/zIndexGeneral.enum';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

type OverlayProps = Readonly<{
  className?: string;
  children?: React.ReactNode;
}>;

const overlayStyle = ({ theme }: ThemeProps) => css({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: theme.backgroundColors.overlay,
  zIndex: zIndexGeneral.OverlayLayer,
});

export const OverlayComponent: FC<OverlayProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      className={props.className}
      css={overlayStyle({ theme })}
    >
      {props.children}
    </div>
  );
};
