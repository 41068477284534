import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { changeMapOwnerRequest } from './changeMapOwner.repository';

export const useChangeMapOwner = () => {
  const update = useCallback((clientId: number, mapId: number, newOwnerId: number) => (
    changeMapOwnerRequest(clientId, mapId, newOwnerId)
  ), []);
  const { invokeAjax, isLoading } = useAjaxCall(update);

  return {
    update: invokeAjax,
    isLoading,
  };
};
