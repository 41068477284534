import { type BoundaryCreateOptimizedAction } from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.action';
import {
  BOUNDARY_CREATE_OPTIMIZED_ADD_ITEM,
  BOUNDARY_CREATE_OPTIMIZED_REMOVE_ITEM,
  BOUNDARY_CREATE_OPTIMIZED_UPDATE_ITEM,
} from './boundaryCreateOptimized.actionTypes';
import { type BoundaryCreateOptimizedState } from './boundaryCreateOptimized.state';

const initialState: BoundaryCreateOptimizedState = {
  pendingItems: [],
};

export const boundaryCreateOptimizedReducer = (state: BoundaryCreateOptimizedState = initialState, action: BoundaryCreateOptimizedAction): BoundaryCreateOptimizedState => {
  switch (action.type) {
    case BOUNDARY_CREATE_OPTIMIZED_ADD_ITEM: {
      return {
        pendingItems: [
          action.payload.item,
          ...state.pendingItems,
        ],
      };
    }
    case BOUNDARY_CREATE_OPTIMIZED_UPDATE_ITEM: {
      return {
        pendingItems: state.pendingItems.map(item => {
          if (item.id === action.payload.updatedItem.id) {
            return {
              ...item,
              ...action.payload.updatedItem,
            };
          }

          return item;
        }),
      };
    }

    case BOUNDARY_CREATE_OPTIMIZED_REMOVE_ITEM: {
      return {
        pendingItems: state.pendingItems.filter(item => item.id !== action.payload.id),
      };
    }

    default:
      return state;
  }
};
