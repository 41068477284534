import { css } from '@emotion/react';
import {
  faCog,
  faEye, faEyeSlash, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import {
  PermanentConfirmStrategy, useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import {
  SelectedItemComponent, Size,
} from '~/_shared/components/selectedItem/selectedItem.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type MapStatusBarLabelItem } from './mapStatusBarItem.type';

const statusBarItemClassName = 'MapStatusBarItem';

const selectedItemStyle = ({ theme }: ThemeProps) => css({
  background: theme.tagColors.backgroundColor,
  color: theme.tagColors.textColor,
  margin: 2,
  padding: '0 6px',
  maxWidth: '100%',
  width: undefined,

  '&:hover': {
    background: theme.tagColors.primaryBackgroundColor,
    color: theme.tagColors.primaryTextColor,
  },
});

const selectedItemLabelStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

const leftActionStyle = ({ theme, disabled }: ThemeProps<{ disabled?: boolean }>) => css({
  background: 'none',
  border: 'none',
  boxSizing: 'content-box',
  color: theme.tagColors.textColor,
  margin: '0 0 0 -6px',
  opacity: disabled ? .5 : 1,
  padding: '0 6px',
  width: 12,

  [`.${statusBarItemClassName}:hover &`]: {
    color: theme.tagColors.primaryTextColor,

    '&:hover': {
      color: disabled ? undefined : theme.tagColors.primaryTextColorOnHover,
    },
  },
});

const removeButtonStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  '&:hover': {
    color: theme.textColors.dangerOnHover,
  },
});

type MapStatusBarItemComponentProps = {
  item: MapStatusBarLabelItem;
  disabled?: boolean;
};

export const MapStatusBarItemComponent = (props: MapStatusBarItemComponentProps) => {
  const { item, disabled = false } = props;

  const theme = useTheme();
  const [t] = useTranslation();

  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const onRemove = useMemo(() => {
    const itemOnRemove = item.onRemove;

    return itemOnRemove && (() => {
      if (item.removeStrategy === null) {
        itemOnRemove();
        return;
      }

      let confirmText;
      if (item.removeStrategy === PermanentConfirmStrategy.Session) {
        confirmText = t('You are about to delete this element. This action is irreversible. Do you wish to proceed?');
      }
      else {
        confirmText = t('You are about to delete this element. You can undo this action any time with the Undo modal.');
      }

      openConfirmationModal({
        title: t('Delete element'),
        text: confirmText,
        onConfirm: () => {
          itemOnRemove();
          closeConfirmationModal();
        },
        isConfirmButtonDestructive: true,
        onCancel: closeConfirmationModal,
        confirmCaption: t('Proceed'),
        permanentConfirmSettings: {
          id: 'map-status-bar-remove-item',
          strategy: item.removeStrategy,
        },
      });
    });
  }, [closeConfirmationModal, item.onRemove, item.removeStrategy, openConfirmationModal, t]);

  return (
    <SelectedItemComponent
      className={statusBarItemClassName}
      css={selectedItemStyle({ theme })}
      size={Size.Small}
      onRemove={onRemove}
      isDisabled={disabled}
      removeIcon={faTrash}
      removeButtonStyle={removeButtonStyle({ theme })}
    >
      {item.isVisible !== undefined && (
        <button
          css={leftActionStyle({ theme })}
          onClick={() => item.onVisibilityChange?.(!item.isVisible)}
        >
          <FontAwesomeIcon
            icon={item.isVisible ? faEye : faEyeSlash}
          />
        </button>
      )}

      {item.settingsIcon !== undefined && (
        <TooltipDeprComponent tooltipContent={item.settingsIcon.tooltip}>
          <button
            css={leftActionStyle({ theme, disabled: !!item.settingsIcon.isDisabled })}
            disabled={item.settingsIcon.isDisabled}
            onClick={() => item.settingsIcon?.onClick()}
          >
            <FontAwesomeIcon
              icon={faCog}
            />
          </button>
        </TooltipDeprComponent>
      )}

      <div
        css={selectedItemLabelStyle}
        onClick={props.item.onClick}
      >
        <span>{item.label}</span>
      </div>

    </SelectedItemComponent>
  );
};
