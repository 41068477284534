import { type Property } from 'csstype';
import { type PropsWithChildren } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { gridStyle } from '~/map/mapMigration/table/table.styles';
import { TableContext } from './table.context';

type TableProps = PropsWithChildren<{
  readonly columnWidths: ReadonlyArray<Property.ColumnWidth>;
  readonly className?: string;
}>;

export const TableComponent = ({
  children,
  columnWidths,
  className,
}: TableProps) => {
  const theme = useTheme();

  return (
    <div css={gridStyle({ columnWidths, theme })} className={className}>
      <TableContext.Provider value={{ columnCount: columnWidths.length }}>
        {children}
      </TableContext.Provider>
    </div>
  );
};
