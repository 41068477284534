import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  activateMoveMarkers, moveMarkersCloseModal,
} from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import {
  useMoveMarkersIsActiveSelector,
  useMoveMarkersIsModalVisible,
} from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { MoveMarkersSaveComponent } from './moveMarkersSave.component';
import { useMoveMarkersSave } from './useMoveMarkersSave';

export const MoveMarkersSaveContainer = () => {
  const { submit, markersMoved, discard } = useMoveMarkersSave();
  const isMoveMarkersModalVisible = useMoveMarkersIsModalVisible();
  const isMoveMarkersActive = useMoveMarkersIsActiveSelector();

  const dispatch = useDispatch();

  const onStartClick = useCallback(() => {
    dispatch(activateMoveMarkers());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(moveMarkersCloseModal());
  }, [dispatch]);

  return (
    <MoveMarkersSaveComponent
      isMoveMarkersModalVisible={isMoveMarkersModalVisible}
      isMoveMarkersActive={isMoveMarkersActive}
      onClose={onClose}
      onResetClick={discard}
      onConfirmClick={submit}
      markersMoved={markersMoved}
      onStartClick={onStartClick}
    />
  );
};
