import { type DeepWritable } from 'ts-essentials';
import { GroupingType } from '~/_shared/types/grouping/grouping';
import {
  type MarkerSettings, type MarkersVisualSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { NON_NUMERICAL_VALUE } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../data/mapSettingsData.actionTypes';
import { updateMarkerSettingsOnAttachmentDelete } from './mapSettingsMarkersDeleteAttachment.helpers';
import { type MapSettingsMarkersGeneralAction } from './mapSettingsMarkersGeneral.action';
import {
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR,
  MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS,
  MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS,
} from './mapSettingsMarkersGeneral.actionTypes';
import { type MapSettingsMarkersGeneralState } from './mapSettingsMarkersGeneral.state';

type MutableGroupSettings = DeepWritable<MapSettingsMarkersGeneralState['groupMarkerSettings']>;
type MutableIndividualSettings = DeepWritable<MapSettingsMarkersGeneralState['individualMarkerSettings']>;

export const mapSettingsMarkersGeneralInitialState: MapSettingsMarkersGeneralState = {
  defaultMarkerSetId: 5,
  useNumericLabel: false,
  useTextLabel: false,
  useLabelsAboveMarkers: false,

  labelMarkersNameColumn: null,
  labelsAboveMarkersNameColumn: null,
  labelsAboveMarkersDisplayRowIdIfEmpty: true,

  hideAllMarkers: false,
};

const removeIndividualSettingsProperty = ({ individualMarkerSettings }: MarkersVisualSettings, prop: 'label' | 'aboveLabel') => {
  (Object.keys(individualMarkerSettings ?? {})).map(Number).forEach((spreadsheetId) => {
    Object.keys(individualMarkerSettings?.[spreadsheetId] ?? {}).forEach((rowId) => {
      delete individualMarkerSettings?.[spreadsheetId]?.[rowId]?.[prop];
    });
  });
};

export const mapSettingsMarkersGeneralReducer = (
  state = mapSettingsMarkersGeneralInitialState, action: MapSettingsMarkersGeneralAction | MapSettingsDataAction
): MapSettingsMarkersGeneralState => {
  switch (action.type) {
    case MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload.settings,
      };

    case MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS: {
      const { spreadsheetId, columnId, groupKey, settings } = action.payload;

      if (settings) {
        return {
          ...state,
          groupMarkerSettings: {
            ...state.groupMarkerSettings,
            [spreadsheetId]: {
              ...state.groupMarkerSettings?.[spreadsheetId],
              [columnId]: {
                ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId],
                [GroupingType.Text]: {
                  ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Text],
                  [groupKey]: settings,
                },
              },
            },
          },
        };
      }
      else {
        if (!state.groupMarkerSettings) {
          return state;
        }

        const clonedMutableGroupSettings = cloneDeep<MutableGroupSettings>(state.groupMarkerSettings);
        delete clonedMutableGroupSettings[spreadsheetId]?.[columnId]?.[GroupingType.Text]?.[groupKey];

        return {
          ...state,
          groupMarkerSettings: clonedMutableGroupSettings,
        };
      }
    }

    case MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS: {
      const { spreadsheetId, columnId, bucketKey, bucketId, settings } = action.payload;

      if (settings) {
        return {
          ...state,
          groupMarkerSettings: {
            ...state.groupMarkerSettings,
            [spreadsheetId]: {
              ...state.groupMarkerSettings?.[spreadsheetId],
              [columnId]: {
                ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId],
                [GroupingType.Numeric]: {
                  ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Numeric],
                  [bucketKey]: {
                    ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Numeric]?.[bucketKey],
                    [bucketId]: settings,
                  },
                },
              },
            },
          },
        };
      }
      else {
        if (!state.groupMarkerSettings) {
          return state;
        }

        const clonedMutableGroupSettings = cloneDeep<MutableGroupSettings>(state.groupMarkerSettings);
        delete clonedMutableGroupSettings[spreadsheetId]?.[columnId]?.[GroupingType.Numeric]?.[bucketKey]?.[bucketId];

        return {
          ...state,
          groupMarkerSettings: clonedMutableGroupSettings,
        };
      }
    }

    case MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS: {
      const { spreadsheetId, columnId, bucketKey, settings } = action.payload;

      const bucketSettings: { [buckedId: number]: MarkerSettings } = {};
      settings.forEach((marker, index) => {
        const bucket = index === settings.length - 1 ? NON_NUMERICAL_VALUE : index;

        bucketSettings[bucket] = {
          marker: marker.marker,
          useMarker: marker.useMarker,
          label: marker.label,
        };
      });

      return {
        ...state,
        groupMarkerSettings: {
          ...state.groupMarkerSettings,
          [spreadsheetId]: {
            ...state.groupMarkerSettings?.[spreadsheetId],
            [columnId]: {
              ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId],
              [GroupingType.Numeric]: {
                ...state.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Numeric],
                [bucketKey]: bucketSettings,
              },
            },
          },
        },
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS: {
      const { spreadsheetId, rowId } = action.payload.id;

      return {
        ...state,
        individualMarkerSettings: {
          ...state.individualMarkerSettings,
          [spreadsheetId]: {
            ...state.individualMarkerSettings?.[spreadsheetId],
            [rowId]: action.payload.settings,
          },
        },
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS: {
      if (!state.groupMarkerSettings) {
        return state;
      }

      const clonedMutableState = cloneDeep<MutableGroupSettings>(state.groupMarkerSettings);

      action.payload.groups.forEach(group => {
        delete clonedMutableState[group.spreadsheetId]?.[group.columnId];
      });

      return {
        ...state,
        groupMarkerSettings: clonedMutableState,
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS: {
      if (!state.individualMarkerSettings) {
        return state;
      }

      const clonedMutableState = cloneDeep<MutableIndividualSettings>(state.individualMarkerSettings);

      action.payload.items.forEach(item => {
        delete clonedMutableState[item.spreadsheetId]?.[item.rowId];
      });

      return {
        ...state,
        individualMarkerSettings: clonedMutableState,
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS: {
      const newState = cloneDeep(state);
      removeIndividualSettingsProperty(newState, 'label');
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS: {
      const newState = cloneDeep(state);
      removeIndividualSettingsProperty(newState, 'aboveLabel');
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS: {
      const newState: DeepWritable<MapSettingsMarkersGeneralState> = cloneDeep(state);
      removeIndividualSettingsProperty(newState, 'label');
      delete newState?.globalMarkerSettings?.label;
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS: {
      const newState: DeepWritable<MapSettingsMarkersGeneralState> = cloneDeep(state);
      removeIndividualSettingsProperty(newState, 'aboveLabel');
      delete newState?.globalMarkerSettings?.aboveLabel;
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS: {
      const newState = { ...state };
      delete newState['individualMarkerSettings'];
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS: {
      const newState = {
        ...state,
        defaultMarkerSetId: mapSettingsMarkersGeneralInitialState.defaultMarkerSetId,
      };
      delete newState['individualMarkerSettings'];
      delete newState['groupMarkerSettings'];
      delete newState['globalMarkerSettings'];
      return newState;
    }

    case MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS:
      return {
        ...state,
        hideAllMarkers: true,
      };

    case MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS:
      return {
        ...state,
        hideAllMarkers: false,
      };

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS:
      return {
        ...state,
        hideAllMarkers: false,
      };

    case MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR: {
      return {
        ...state,
        markerTextureSettings: {
          ...state.markerTextureSettings,
          anchors: {
            ...state.markerTextureSettings?.anchors,
            [action.payload.attachmentId]: action.payload.anchor,
          },
        },
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR: {
      if (!state.markerTextureSettings) {
        return state;
      }

      const anchorsSpreadCopy = {
        ...state.markerTextureSettings.anchors,
      };

      delete anchorsSpreadCopy[action.payload.attachmentId];

      return {
        ...state,
        markerTextureSettings: {
          ...state.markerTextureSettings,
          anchors: anchorsSpreadCopy,
        },
      };
    }

    case MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS:
      return updateMarkerSettingsOnAttachmentDelete(state, action.payload.attachmentIds);

    default:
      return state;
  }
};
