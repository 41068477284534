import { Outlet } from 'react-router-dom';
import { useUserDataSelector } from '../../store/userData/userData.selectors';
import { AppErrorPageLogOutRequiredComponent } from '../pages/errorPage/logoutRequired/appErrorPageLogOutRequired.component';

export const LogOutRequiredRoute = () => {
  const userData = useUserDataSelector();

  if (userData.isSignedIn) {
    return <AppErrorPageLogOutRequiredComponent />;
  }

  return (
    <Outlet />
  );
};
