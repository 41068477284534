import { css } from '@emotion/react';
import { useDragLayer } from 'react-dnd';
import { DndType } from '../../constants/dndType.enum';
import { getStylesForDraggedItem } from '../../utils/dnd/dnd.helpers';
import { ImageComponent } from '../image/image.component';
import { isImageItem } from './imageSelectorItem.component';

type ImageSelectorDragLayerProps = Readonly<{
  previewWidth: number;
  previewHeight: number;
  className?: string;
}>;

const layerStyles = css({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  backgroundColor: 'rgba(0,0,0,0)',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
});

const renderableItemStyle = (props: ImageSelectorDragLayerProps) => css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: props.previewWidth,
  height: props.previewHeight,
});

export const ImageSelectorDragLayerComponent: React.FC<ImageSelectorDragLayerProps> = props => {
  const dragProps = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!dragProps.isDragging || dragProps.itemType !== DndType.ImageInSelector) {
    return null;
  }

  const item = dragProps.item.item;
  return (
    <div css={layerStyles}>
      <div
        css={getStylesForDraggedItem(dragProps.initialOffset, dragProps.currentOffset)}
      >
        {isImageItem(item) ? (
          <ImageComponent
            src={item.url}
            width={props.previewWidth}
            height={props.previewHeight}
            className={props.className}
          />
        )
          : (
            <div
              css={renderableItemStyle(props)}
              className={props.className}
            >
              {item.render()}
            </div>
          )
        }
      </div>
    </div>
  );
};
