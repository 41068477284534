import { useMemo } from 'react';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { DrawingTool } from '../../../drawingTool/drawingTool.enums';
import { type AppState } from '../../app.store';

const drawingSettingsSelector = (state: AppState) => state.map.mapSettings.data.drawing.settings;
export const useDrawingSettingsSelector = () => useSelector(drawingSettingsSelector);

export const drawingItemsSelector = (state: AppState) => state.map.mapSettings.data.drawing.items;
export const useDrawingItemsSelector = () => useSelector(drawingItemsSelector);

export const useDrawingItemsCountSelector = (drawingType: DrawingTool | null) => {
  const drawingItems = useDrawingItemsSelector();

  return useMemo(() => {
    if (!drawingType || drawingType === DrawingTool.Management) {
      return drawingItems.length;
    }
    else {
      return drawingItems.filter(item => item.type === drawingType).length;
    }
  }, [drawingItems, drawingType]);
};
