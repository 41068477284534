import {
  put, takeEvery,
} from 'redux-saga/effects';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { boundaryTerritoryGroupInStatusBarClicked } from './boundarySidebarState.actionCreators';

export function* boundarySidebarStateSagas() {
  yield takeEvery(boundaryTerritoryGroupInStatusBarClicked, onBoundaryInStatusBarClicked);
}

function* onBoundaryInStatusBarClicked() {
  yield put(openMapTool(MapTool.Boundary));
}
