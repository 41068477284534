import { css } from '@emotion/react';
import {
  type FC, useCallback,
  useState,
} from 'react';
import { type GoogleMapInstance } from '~/_shared/components/google-map/useGetGoogleMapInstance.hook';
import { MapMarkersSelectOverlayContainer } from '~/_shared/components/mapMarkersSelect/mapMarkersSelectOverlay.container';
import { BoundaryDrawConfirmContainer } from '~/boundary/editMode/drawConfirm/boundaryDrawConfirm.container';
import { DrawingToolContextMenuContainer } from '~/drawingTool/contextMenu/drawingToolContextMenu.container';
import { MapMarkerContextMenuContainer } from '~/map/contextMenu/mapMarkerContextMenu.container';
import { useAddMapAttributionForPresentationalMap } from '~/map/map/useAddMapAttributionForPresentationalMap';
import { useExposeMapTestingFunctions } from '~/testingMode/testingModeFunctions';
import { GoogleMapComponent } from '../../_shared/components/google-map/google-map.component';
import { BoundaryPolygonDragEditConfirmContainer } from '../../boundary/editMode/drawConfirm/boundaryPolygonEditConfirm.container';
import { BoundarySelectConfirmContainer } from '../../boundary/editMode/selectConfirm/boundarySelectConfirm.container';
import { DrawingToolConfirmContainer } from '../../drawingTool/confirm/drawingToolConfirm.container';
import { testingModeEnabled } from '../../testingMode/testingMode';
import { MapContextMenuContainer } from '../contextMenu/mapContextMenu.container';
import { BoundaryDragEditContainer } from './boundary/boundaryDragEdit/boundaryDragEdit.container';
import { BoundaryDrawContainer } from './boundary/boundaryDraw/boundaryDraw.container';
import { BoundaryLassoSelectContainer } from './boundary/boundaryLassoSelect/boundaryLassoSelect.container';
import { useMapBoundary } from './boundary/useMapBoundary';
import { DirectionsOverlayContainer } from './directions/directionsOverlay.container';
import { DistanceCalculatorOverlay } from './distanceCalculator/distanceCalculatorOverlay.container';
import { DrawingToolOverlay } from './drawingTool/drawingToolOverlay.container';
import { HeatmapsOverlay } from './heatmaps/heatmapsOverlay.container';
import { LassoToolOverlay } from './lassoTool/overlay/lassoToolOverlay.container';
import { MapLoaderContainer } from './loader/mapLoader.container';
import { MapContextProvider } from './mapContext';
import { useMarkerHoverEffects } from './mapOverlays/markerHoverEffects/useMarkerHoverEffects.hook';
import { MapToolsOverlay } from './mapTools/mapToolsOverlay.container';
import { MarkersLassoSelectContainer } from './markers/markersSelect/markersLassoSelect.container';
import { useMarkers } from './markers/useMarkers.hook';
import { MoveMarkerLabelsSaveContainer } from './markers/useMoveMarkerLabels/moveMarkerLabelsSave.container';
import { MoveMarkersSaveContainer } from './markers/useMoveMarkers/moveMarkersSave.container';
import { ProximityOverlay } from './proximity/proximityOverlay.container';
import { SearchOverlayContainer } from './search/searchOverlay.container';
import { useDebugBounds } from './useDebugBounds';
import { useGraphicSettings } from './useGraphicSettings.hook';
import { useMapFeatures } from './useMapFeatures.hook';
import { useMapOptions } from './useMapOptions.hook';
import { useMapStyles } from './useStyleManager.hook';
import { useWebGL } from './webgl/useWebGL';

const wrapperStyle = css({
  height: '100%',
  width: '100%',
  position: 'relative',
});

const MapFeaturesWithContext: FC = () => {
  useMapFeatures();
  useMapOptions();
  const numberOfRenderedMarkers = useMarkers();
  useMapBoundary();
  useMapStyles();
  useMarkerHoverEffects();
  useDebugBounds({ enabled: false });
  useGraphicSettings(numberOfRenderedMarkers);

  const exposeMapTestingFunctions = useExposeMapTestingFunctions();

  if (testingModeEnabled()) {
    exposeMapTestingFunctions();
  }

  return null;
};

export const MapComponent: FC = () => {
  const [map, setMap] = useState<GoogleMapInstance>();
  const [webglOverlay, webglLayers, markerTemplateManager] = useWebGL(map);
  const { addMapAttribution } = useAddMapAttributionForPresentationalMap();

  const handleMapInit = useCallback((map: GoogleMapInstance) => {
    setMap(map);
    addMapAttribution();
  }, [addMapAttribution]);

  return (
    <div
      data-testid="mapView"
      css={wrapperStyle}
    >
      <GoogleMapComponent onMapInitialized={handleMapInit} />

      <MoveMarkersSaveContainer />
      <MoveMarkerLabelsSaveContainer />
      <BoundarySelectConfirmContainer />
      <MapMarkersSelectOverlayContainer />
      <DrawingToolConfirmContainer />
      <BoundaryPolygonDragEditConfirmContainer />
      <BoundaryDrawConfirmContainer />

      <MapContextProvider
        map={map?.instance}
        webglLayers={webglLayers}
        webglOverlay={webglOverlay}
        markerTemplateManager={markerTemplateManager}
      >
        <MapFeaturesWithContext />
        <DistanceCalculatorOverlay />
        <HeatmapsOverlay />
        <DrawingToolOverlay />
        <DrawingToolContextMenuContainer />
        <MapToolsOverlay />
        <ProximityOverlay />
        <SearchOverlayContainer />
        <LassoToolOverlay />
        <BoundaryDrawContainer />
        <BoundaryLassoSelectContainer />
        <MarkersLassoSelectContainer />
        <BoundaryDragEditContainer />
        <MapContextMenuContainer />
        <MapMarkerContextMenuContainer />
        <DirectionsOverlayContainer />
        <MapLoaderContainer />
      </MapContextProvider>
    </div>
  );
};
