import { css } from '@emotion/react';
import { type FC } from 'react';
import { ShowWithDelayComponent } from '~/_shared/components/delay/showWithDelay.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';

type MapLoaderProps = Readonly<{
  showDelay?: number;
}>;

const loaderContainerStyle = css({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
});

export const BlockingMapLoaderComponent: FC<MapLoaderProps> = props => {
  return (
    <ShowWithDelayComponent
      showDelay={props.showDelay ?? 0}
      isActive
    >
      <div css={loaderContainerStyle}>
        <OverlayLoaderComponent />
      </div>
    </ShowWithDelayComponent>
  );
};
