import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { type MapSettingsSettingsAction } from './mapSettingsSettings.action';
import {
  MAP_SETTINGS_DISABLE_CLICKABLE_ICONS,
  MAP_SETTINGS_ENABLE_CLICKABLE_ICONS, MAP_SETTINGS_SETTINGS_UPDATE_SETTINGS,
} from './mapSettingsSettings.actionTypes';
import { type MapSettingsSettingsState } from './mapSettingsSettings.state';

export const mapSettingsSettingsInitialState: MapSettingsSettingsState = {
  unitSystem: UnitSystem.imperial,
  isUserLocationShownOnAdmin: false,
  isDisableActiveToolPanel: false,
};

export const mapSettingsSettingsReducer = (
  state = mapSettingsSettingsInitialState,
  action: MapSettingsSettingsAction
): MapSettingsSettingsState => {
  switch (action.type) {
    case MAP_SETTINGS_SETTINGS_UPDATE_SETTINGS:
      return { ...state, ...action.payload.settings };

    case MAP_SETTINGS_ENABLE_CLICKABLE_ICONS: {
      return {
        ...state,
        clickableIcons: true,
      };
    }

    case MAP_SETTINGS_DISABLE_CLICKABLE_ICONS: {
      return {
        ...state,
        clickableIcons: false,
      };
    }

    default:
      return state;
  }
};
