import { type MarkerProps } from '~/_shared/components/marker/marker.component';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import {
  type CustomMarkerVisualSettings, MarkerAnchorPosition, MarkerStyleType,
} from '~/_shared/types/marker.types';
import { type MarkersVisualSettings } from '~/_shared/types/markers/visualSettings.types';
import { recreateFileAttachment } from '~/_shared/utils/files/fileAttachments.helpers';
import {
  DEFAULT_MARKER_STYLE_ID, MARKER_DEFAULT_COLOR,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import { getOpacityForDropdownMarkers } from '~/map/settings/accordion/mapMarkersGraphics/markerDropdown/markerDropDown.helpers';
import { type FileMap } from '~/store/frontendState/fileUrls/fileUrls.state';
import {
  DEFAULT_MARKER_STYLE, type MarkerSets,
} from '~/store/frontendState/markerSets/markerSets.selectors';
import { MapMarkersGraphicsToggleVisualSettingsName } from './mapMarkersGraphics.enums';

export const getMarkerLabelProperties = (
  propertyName: MapMarkersGraphicsToggleVisualSettingsName, markerVisualSettings: MarkersVisualSettings
): Partial<MarkersVisualSettings> => {
  const noLabelsAreUsed = !markerVisualSettings.useNumericLabel && !markerVisualSettings.useTextLabel;

  if (
    propertyName === MapMarkersGraphicsToggleVisualSettingsName.useLabelsAboveMarkers ||
    markerVisualSettings[propertyName] || noLabelsAreUsed
  ) {
    return {
      [propertyName]: !markerVisualSettings[propertyName],
    };
  }

  else {
    return {
      useTextLabel: !markerVisualSettings.useTextLabel,
      useNumericLabel: !markerVisualSettings.useNumericLabel,
    };
  }
};

export const getCustomActiveMarkerProps = ({ customMarkerSettings, fileUrlsLookup, maxDimensions }: {
  customMarkerSettings: CustomMarkerVisualSettings;
  fileUrlsLookup: FileMap;
  maxDimensions: QuadrilateralDimensions;
}): MarkerProps | null => {
  const fileAttachmentId = customMarkerSettings.fileAttachmentId;
  const fileId = recreateFileAttachment(fileAttachmentId)?.fileId ?? null;
  const fileUrl = fileId && fileUrlsLookup.get(fileId);

  if (!fileUrl) {
    return null;
  }

  const customMarker = {
    fileId,
    anchor: MarkerAnchorPosition.BOTTOM_CENTER,
    dimensions: { width: fileUrl['200p'].resolution.width, height: fileUrl['200p'].resolution.height },
    path: fileUrl['200p'].url,
  };

  return ({
    opacity: 1,
    maxDimensions,
    marker: customMarker,
    markerType: MarkerStyleType.CUSTOM,
  });
};

export const getStandardActiveMarkerProps = ({ styleId, selectedColor, markerSets, maxDimensions }: {
  styleId?: number;
  selectedColor?: string;
  markerSets: MarkerSets;
  maxDimensions: QuadrilateralDimensions;
}): MarkerProps => {
  const selectedMarkerSet = markerSets[styleId || DEFAULT_MARKER_STYLE_ID];
  const guaranteedMarkerSet = selectedMarkerSet || DEFAULT_MARKER_STYLE;

  return ({
    selectedColor: selectedColor || MARKER_DEFAULT_COLOR,
    opacity: getOpacityForDropdownMarkers(guaranteedMarkerSet.marker.id),
    maxDimensions,
    marker: guaranteedMarkerSet.marker,
    markerType: MarkerStyleType.STANDARD,
  });
};
