import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  type PermanentConfirmSettings, useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { removeSpreadsheetRows } from '~/spreadsheet/spreadsheet.repository';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { type RowsPerSpreadsheet } from '../rowsPerSpreadsheet';

type BulkDeleteOptions = {
  permanentConfirmSettings?: PermanentConfirmSettings;
};

const bulkRemove = async (
  clientId: number,
  rowsPerSpreadsheetsToRemove: ReadonlyArray<RowsPerSpreadsheet>,
) => {
  const removePromises = rowsPerSpreadsheetsToRemove.map(async (spreadsheet) => {
    const spreadsheetId = spreadsheet.spreadsheetId;

    const request = {
      rows: spreadsheet.rows.map(row => ({
        row_id: row.id,
      })),
    };
    return await removeSpreadsheetRows(clientId, spreadsheetId, request);
  });

  return await Promise.all(removePromises);
};

export const useBulkDelete = (options?: BulkDeleteOptions) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const { openConfirmationModal: openDeleteConfirmationModal, closeConfirmationModal: closeDeleteConfirmationModal } = useConfirmationModal();
  const openConfirmationModal = (clientId: number, affectedSpreadsheets: ReadonlyArray<RowsPerSpreadsheet>) => {
    const markersTotal = affectedSpreadsheets.reduce((acc, affectedSpreadsheet) => acc + affectedSpreadsheet.rows.length, 0);

    openDeleteConfirmationModal({
      onCancel: closeDeleteConfirmationModal,
      onConfirm: () => {
        setLoading(true);
        closeDeleteConfirmationModal();
        bulkRemove(clientId, affectedSpreadsheets).then(() => {
          dispatch(spreadsheetResetStateAndRefetchData());
        }).finally(() => {
          setLoading(false);
        });
      },
      text: t('deleteConfirmation.lassoMarkers', {
        count: markersTotal,
        modalName: t('Undo'),
        tabName: t('resetToVersion'),
      }),
      title: t('Delete Markers'),
      confirmCaption: t('Proceed'),
      isConfirmButtonDestructive: true,
      permanentConfirmSettings: options?.permanentConfirmSettings,
    });
  };

  return {
    openConfirmationModal,
    loading,
  };
};
