import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import {
  dataTableWrapperStyle,
  overridePaddingStyle, tabDataTableWrapperStyle,
} from '~/data/table/dataTableWrapper.styles';
import { errorPageSetError } from '~/store/errorPage/errorPage.actionCreators';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import { useHasSpreadsheetTableSearchAnyQueries } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { DataTableComponent } from './dataTable.component';
import { useManageDataTableSpreadsheet } from './useManageDataTableSpreadsheet.hook';
import { useSelectDataTableRows } from './useSelectDataTableRows.hook';
import { useSpreadsheetTableSearchFilterTree } from './useSpreadsheetTableSearchFilterTree';

export const DataTableDataPageContainer: FC = () => {
  const dispatch = useDispatch();
  const spreadsheetId = usePrimarySpreadsheetId();
  const filterTree = useSpreadsheetTableSearchFilterTree();
  const hasSearchAnyQueries = useHasSpreadsheetTableSearchAnyQueries();

  const onDataError = useCallback(() => {
    dispatch(errorPageSetError(ErrorPageType.General));
  }, [dispatch]);

  const manageDataTableSpreadsheet = useManageDataTableSpreadsheet({
    activeMarkerId: null,
    debounceGetSpreadsheetData: false,
    filterTree,
    onDataError,
    spreadsheetId,
  });

  const selectDataTableRows = useSelectDataTableRows({
    data: manageDataTableSpreadsheet.data,
    isDataLoading: manageDataTableSpreadsheet.isLoading,
  });

  const { ref: metaRef, height: metaHeight } = useElementDimensions();

  return (
    <div css={[dataTableWrapperStyle, tabDataTableWrapperStyle, metaHeight ? overridePaddingStyle : undefined]}>
      <DataTableComponent
        allowRowsSelect
        isLoading={manageDataTableSpreadsheet.isLoading || selectDataTableRows.isLoading}
        isSearchFilterActive={hasSearchAnyQueries}
        metaRef={metaRef}
        onSelectedRowsChange={selectDataTableRows.onSelectedRowsChange}
        selectedRows={selectDataTableRows.selectedRows}
        showBadDataSection
        spreadsheetProps={manageDataTableSpreadsheet}
      />
    </div>
  );
};
