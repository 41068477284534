import { useEffect } from 'react';
import { checkIfModalIsAlreadyConfirmed } from '~/_shared/components/modal/confirmation/confirmationModal.helpers';
import { useStorageService } from '~/_shared/utils/storageService';
import { groupingToolPermanentConfirmSettings } from '~/grouping/components/GroupingToolHint.component';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { registerUserEventSequenceEvaluator } from '~/store/userEvents/userEventSequenceEvaluation';

export const useHandleUserEventSequences = () => {
  const storageService = useStorageService();
  const hasMarkerGroupingToolBeenDismissedPermanently = checkIfModalIsAlreadyConfirmed(groupingToolPermanentConfirmSettings, storageService);
  const { openModal } = useModal(ModalType.GroupingToolHint);

  useEffect(() => {
    if (!hasMarkerGroupingToolBeenDismissedPermanently) {
      const manyIndividualMarkerCustomisationsSequenceEvaluator: Parameters<typeof registerUserEventSequenceEvaluator>[0] =
        (events, lastEventsOfType) => {
          if (lastEventsOfType['GroupMarkerCustomizationSaved']) {
            return null;
          }
          const fiveMinutesAgo = Date.now() - (5 * 60 * 1000);
          const individualMarkerCustomizationsInLast5Minutes = events.filter(event =>
            (event.eventType === 'IndividualMarkerCustomizationSaved') && (event.timeStamp > fiveMinutesAgo),
          );

          if (individualMarkerCustomizationsInLast5Minutes.length >= 3) {
            return () => {
              openModal({});
            };
          }

          return null;
        };

      const unregister = registerUserEventSequenceEvaluator(manyIndividualMarkerCustomisationsSequenceEvaluator);

      return () => unregister();
    }

    return undefined;
  }, [hasMarkerGroupingToolBeenDismissedPermanently, openModal]);
};
