import {
  cancalledPromiseError, delay,
} from '../delay';
import {
  type AnyFunction, type CancellablePromise,
} from '../types/common.type';

// 60 fps ~ 16ms
export const FPS60 = 16;
export const FPS30 = 32;
export const FPS20 = 50;
export const FPS0_66 = 1500;

/*
 Returns a function, that, when invoked, will only be triggered at most once during a given window of time.
 Normally, the throttled function will run as much as it can, without ever going more than once per wait duration;
 but if you’d like to disable the execution on the leading edge, pass {leading: false}.
 To disable execution on the trailing edge, ditto.
 */
type ThrottleOptions = {
  trailing?: boolean;
  leading?: boolean;
};

const _now = Date.now || function () {
  return new Date().getTime();
};

export type ThrottledFunction = {
  (..._args: unknown[]): void;
  readonly cancel: () => void;
};

export function throttle<T extends AnyFunction>(func: T, wait: number, options: ThrottleOptions = {}): ThrottledFunction {
  let context: any;
  let args: any;
  let timeout: CancellablePromise | null = null;
  let previous = 0;
  const later = function () {
    previous = options.leading === false ? 0 : _now();
    timeout = null;
    func.apply(context, args);
    if (!timeout) {
      context = args = null;
    }
  };
  const res = function (this: any) {
    const now = _now();
    if (!previous && options.leading === false) {
      previous = now;
    }
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if ((remaining <= 0) || (remaining > wait)) {
      if (timeout) {
        timeout.cancel();
        timeout = null;
      }
      previous = now;
      func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    }
    else if (!timeout && options.trailing !== false) {
      timeout = delay(remaining).then(later, error => {
        if (error !== cancalledPromiseError) {
          throw error;
        }
      });
    }
  };

  return Object.assign(res, {
    cancel: (): void => {
      if (timeout) {
        timeout.cancel();
        timeout = null;
      }
    },
  });
}
