import { isMobileScreen } from '../../../_shared/utils/deviceDetect/deviceDetect.helpers';
import { type DeviceInfoAction } from './deviceInfo.action';
import { DEVICE_INFO_UPDATE } from './deviceInfo.actionTypes';
import { type DeviceInfoState } from './deviceInfo.state';

const deviceInfoInitialState: DeviceInfoState = {
  isMobileScreen: isMobileScreen(),
};

export const deviceInfoReducer = (state = deviceInfoInitialState, action: DeviceInfoAction): DeviceInfoState => {
  switch (action.type) {

    case DEVICE_INFO_UPDATE:
      return {
        ...state,
        isMobileScreen: action.payload.isMobileScreen,
      };

    default:
      return state;
  }
};
