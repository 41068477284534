import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createUuid } from '~/_shared/utils/createUuid';
import { getNodeFirstScrollableParent } from '~/_shared/utils/dom/dom.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useStorageService } from '~/_shared/utils/storageService';
import {
  FPS30, throttle,
} from '~/_shared/utils/throttle/throttle';
import { useCursorOverlayContext } from '~/app/maptiveApp/cursorOverlayContext';
import { SPREADSHEET_CELL_MIN_WIDTH } from './cell/spreadsheetCell.component';

type LocalStorageColumnWidths = {
  map: Record<string, number>;
};

export const useSpreadsheetColumnWidth = ({ columnsCount, localStorageKey }: {
  columnsCount: number;
  localStorageKey?: string;
}) => {
  const [columnWidthOverrides, setColumnWidthOverrides] = useState(new Map<string, number>());
  const [isColumnResizing, setIsColumnResizing] = useState(false);
  const previousLocalStorageKey = usePrevious(localStorageKey);
  const storageService = useStorageService();
  const cursorOverlayContext = useCursorOverlayContext();
  // use ref to prevent removal of event handlers when context value changes
  const cursorOverlayContextRef = useRef(cursorOverlayContext);
  cursorOverlayContextRef.current = cursorOverlayContext;
  const [cursorTransactionId] = useState(createUuid());

  useEffect(() => {
    if (localStorageKey) {
      const localStorageRecord = storageService.getLocalStorageItem(localStorageKey);
      const jsonMap = localStorageRecord ? (JSON.parse(localStorageRecord) as LocalStorageColumnWidths).map : {};
      const map = new Map(Object.entries(jsonMap));
      setColumnWidthOverrides(map);
    }
  }, [storageService, localStorageKey]);

  useEffect(() => {
    if (localStorageKey && previousLocalStorageKey === localStorageKey) {
      if (!columnWidthOverrides.size) {
        return;
      }

      const localStorageRecord: LocalStorageColumnWidths = { map: Object.fromEntries(columnWidthOverrides) };
      storageService.setLocalStorageItem(localStorageKey, JSON.stringify(localStorageRecord));
    }
  }, [storageService, columnWidthOverrides, localStorageKey, previousLocalStorageKey]);

  const getColumnWidth = useCallback((columnId: string) =>
    columnWidthOverrides.get(columnId) ?? `${100 / columnsCount}%`, [columnWidthOverrides, columnsCount]);

  const onPointerUpRef = useRef<() => void>(undefined);
  const onPointerMoveRef = useRef<(e: PointerEvent) => void>(undefined);

  const resizeCleanup = useCallback(() => {
    setIsColumnResizing(false);
    cursorOverlayContextRef.current.pop(cursorTransactionId);

    if (onPointerMoveRef.current) {
      document.removeEventListener('pointermove', onPointerMoveRef.current);
      onPointerMoveRef.current = undefined;
    }

    if (onPointerUpRef.current) {
      document.removeEventListener('pointerup', onPointerUpRef.current);
      onPointerUpRef.current = undefined;
    }
  }, [cursorTransactionId]);

  const onResizerPointerDown = useCallback((columnId: string, initialWidth: number, evDown: React.PointerEvent) => {
    resizeCleanup();
    const scrollableParent = getNodeFirstScrollableParent(evDown.target as HTMLElement);
    const startScrollLeft = scrollableParent?.scrollLeft ?? 0;
    const startX = evDown.clientX + startScrollLeft;

    setIsColumnResizing(true);
    cursorOverlayContextRef.current.push(cursorTransactionId, 'col-resize');

    onPointerMoveRef.current = throttle((evMove: PointerEvent) => {
      const moveX = evMove.clientX + (scrollableParent?.scrollLeft ?? 0);
      const newWidth = Math.max(initialWidth + moveX - startX, SPREADSHEET_CELL_MIN_WIDTH);

      setColumnWidthOverrides(previous => new Map([...previous.entries(), [columnId, newWidth]]));

      if (Math.round(evMove.clientX) >= document.documentElement.clientWidth && scrollableParent !== undefined) {
        scrollableParent.scrollLeft += 10;
      }
    }, FPS30, { trailing: true, leading: false });

    document.addEventListener('pointermove', onPointerMoveRef.current);

    onPointerUpRef.current = () => resizeCleanup();
    document.addEventListener('pointerup', onPointerUpRef.current);
  }, [cursorTransactionId, resizeCleanup]);

  // unmount
  useEffect(() => {
    return () => resizeCleanup();
  }, [resizeCleanup]);

  return {
    isColumnResizing,
    getColumnWidth,
    onResizerPointerDown,
  };
};
