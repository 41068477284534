export enum MapStylesColorSettingsName {
  poi = 'poi',
  water = 'water',
}

export enum MapStylesColorListLabelSettingsName {
  'labels.administrative' = 'labels.administrative',
  'labels.poi.recreation' = 'labels.poi.recreation',
  'labels.water' = 'labels.water',
}

export enum MapStylesColorListLandscapeSettingsName {
  'landscape.natural' = 'landscape.natural',
  'landscape.humanMade' = 'landscape.humanMade',
}

export enum MapStylesColorListRoadSettingsName {
  'roads.highway' = 'roads.highway',
  'roads.others' = 'roads.others',
}

export enum MapStylesColorListSettingsName {
  landscape = 'landscape',
  roads = 'roads',
  labels = 'labels',
}

export enum MapStylesSliderSettingsName {
  roads = 'roads',
  landmarks = 'landmarks',
  labels = 'labels',
}

export enum MapStylesToggleSettingsName {
  enablePOIDescriptions='enablePOIDescriptions',
}

export enum BaseMapFeatureTypeName {
  administrative= 'administrative',
  'administrative.country'= 'administrative.country',
  'administrative.province'= 'administrative.province',
  'administrative.locality'= 'administrative.locality',
  'administrative.neighborhood'= 'administrative.neighborhood',
  'administrative.landParcel'= 'administrative.landParcel',
  all= 'all',
  landscape= 'landscape',
  'landscape.humanMade'= 'landscape.humanMade',
  'landscape.natural'= 'landscape.natural',
  'landscape.natural.landcover'= 'landscape.natural.landcover',
  'landscape.natural.terrain'= 'landscape.natural.terrain',
  poi= 'poi',
  'poi.attraction'= 'poi.attraction',
  'poi.business'= 'poi.business',
  'poi.government'= 'poi.government',
  'poi.medical'= 'poi.medical',
  'poi.park'= 'poi.park',
  'poi.placeOfWorship'= 'poi.placeOfWorship',
  'poi.school'= 'poi.school',
  'poi.sportsComplex'= 'poi.sportsComplex',
  road= 'road',
  'road.highway'= 'road.highway',
  'road.highway.controlledAccess'= 'road.highway.controlledAccess',
  'road.arterial'= 'road.arterial',
  'road.local'= 'road.local',
  transit= 'transit',
  'transit.line'= 'transit.line',
  'transit.station'= 'transit.station',
  'transit.station.airport'= 'transit.station.airport',
  'transit.station.bus'= 'transit.station.bus',
  'transit.station.rail'= 'transit.station.rail',
  water= 'water',
}

export enum BaseMapElementTypeName {
  all= 'all',
  geometry= 'geometry',
  'geometry.fill'= 'geometry.fill',
  'geometry.stroke'= 'geometry.stroke',
  labels= 'labels',
  'labels.text'= 'labels.text',
  'labels.text.fill'= 'labels.text.fill',
  'labels.text.stroke'= 'labels.text.stroke',
  'labels.icon'= 'labels.icon',
}

export enum BaseMapStylerName {
  visibility= 'visibility',
  color= 'color',
  weight= 'weight',
  saturation= 'saturation',
  lightness= 'lightness',
}

export enum BaseMapStylerVisibility {
  inherit = 'inherit',
  hidden = 'hidden',
  simplified = 'simplified',
  shown = 'shown',
}
