import { useCallback } from 'react';
import { type LatLng } from '~/_shared/types/latLng';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';

export const useCalculateMidpoint = (map: google.maps.Map) => {
  const { fromLatLngToDivPixel, fromDivPixelToLatLng } = useProjectionOverlay(map);
  const calculateMidpoint = useCallback((point1: LatLng, point2: LatLng) => {
    const point1Pixel = fromLatLngToDivPixel(point1);

    const point2Pixel = fromLatLngToDivPixel(point2);
    if (!point1Pixel || !point2Pixel) {
      return null;
    }

    const midpointPixel = {
      x: (point1Pixel.x + point2Pixel.x) / 2,
      y: (point1Pixel.y + point2Pixel.y) / 2,
    };
    const midpoint = fromDivPixelToLatLng(midpointPixel);

    return midpoint;
  }, [fromDivPixelToLatLng, fromLatLngToDivPixel]);

  return {
    calculateMidpoint,
  };
};
