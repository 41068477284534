import {
  useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { SearchMatchingBehaviour } from '~/_shared/constants/searchMatchingBehaviour.enum';
import { delay } from '~/_shared/utils/delay';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { useSelectMarkersSelectActiveRows } from '~/store/frontendState/mapTools/markersSelect/markersSelect.selectors';
import {
  frontedStateProcessingRenderingMarkersStart,
  frontedStateProcessingRenderingMarkersStop,
} from '~/store/frontendState/processing/processing.actionCreators';
import { useColumnSearchItemsSelector } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.selectors';
import { emptySpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { isLatestSearchLoaded } from '~/store/spreadsheetData/search/spreadsheetData.search.helpers';
import { useSpreadsheetMarkerZIndexes } from '../../zIndexes/useSpreadsheetMarkersZIndexes.hook';
import { useMapContext } from '../mapContext';
import {
  useFilteredLatLngSpreadsheetData,
  useVisibleRowsWithoutWaypoints,
} from '../useSpreadsheetData.hook';
import { useZoomOnFirstLoad } from '../useZoomOnFirstLoad';
import { type MapMarkerManager } from './manager/mapMarkerManager';
import { useMapMarkerManager } from './manager/useMapMarkerManager';
import { useMarkerEvents } from './useMarkerEvents/useMarkerEvents';
import {
  useMarkerLabelAboveData, useMarkerLabelData,
} from './useMarkers/useMarkerLabelData.hook';
import {
  useMarkerStyles,
  useStackedMarkerStyles,
} from './useMarkers/useMarkerStyles.hook';
import { useMarkerClusterer } from './useStacksAndClusters/useMarkerClusterer';
import { useStackedMarkers } from './useStacksAndClusters/useStackedMarkers';

export const useMarkers = () => {
  const { webglLayers, markerTemplateManager } = useMapContext();
  const manager = useMapMarkerManager(webglLayers);
  const showSearchHighlight = useSelector(s => s.map.mapSettings.data.search.selectedMatchingBehaviour === SearchMatchingBehaviour.ShowAllMarkers);
  const isSearchActive = useColumnSearchItemsSelector().length > 0;
  const isSearchFilterLoaded = useSelector(isLatestSearchLoaded);
  const renderingCallbacks = useRenderingCallbacks();

  const selectedRows = useSelectMarkersSelectActiveRows();
  const searchedRows = useFilteredLatLngSpreadsheetData().search;
  const visibleRowsData = useVisibleRowsWithoutWaypoints();

  const stackedMarkerStyles = useStackedMarkerStyles({ registerTemplates: true, markerTemplateManager });
  const markerStyles = useMarkerStyles(visibleRowsData, markerTemplateManager);

  const zIndexes = useSpreadsheetMarkerZIndexes(visibleRowsData, markerStyles);
  const labelMarkerTexts = useMarkerLabelData(visibleRowsData);
  const labelAboveTexts = useMarkerLabelAboveData(visibleRowsData);

  const { applyTemporaryDnDMarkerChanges } = useMarkerEvents(manager);

  const stackedMarkersWithoutDnD = useStackedMarkers();
  const stackedMarkers = applyTemporaryDnDMarkerChanges(stackedMarkersWithoutDnD);

  const initialZoomDone = useZoomOnFirstLoad();

  const { markerIdsToRender, areClustersCreatedAfterFirstZoom, hideMarkersOnRemove } = useMarkerClusterer({
    markers: visibleRowsData,
    isFirstZoomed: initialZoomDone,
  });

  const throttledMarkersUpdate = useThrottle((...args: Parameters<MapMarkerManager['updateMarkers']>) =>
    delay(0)
      .then(() => manager?.updateMarkers(...args))
      .then(() => {
        renderingCallbacks.onStop();
      }), [renderingCallbacks, manager], 500);

  useEffect(() => { // Update markers
    if (!manager || !stackedMarkerStyles || !zIndexes || !areClustersCreatedAfterFirstZoom) {
      return;
    }

    renderingCallbacks.onStart();
    throttledMarkersUpdate(
      stackedMarkers,
      zIndexes,
      markerStyles,
      stackedMarkerStyles,
      { labelAboveTexts, labelMarkerTexts },
      (showSearchHighlight && isSearchActive && isSearchFilterLoaded) ? searchedRows : emptySpreadsheetLatLngRowData,
      selectedRows,
      markerIdsToRender,
      hideMarkersOnRemove
    );

  }, [areClustersCreatedAfterFirstZoom, manager, markerIdsToRender, stackedMarkersWithoutDnD,
    zIndexes, markerStyles, stackedMarkerStyles, labelAboveTexts,
    labelMarkerTexts, showSearchHighlight, searchedRows, isSearchActive, isSearchFilterLoaded,
    stackedMarkers, renderingCallbacks, throttledMarkersUpdate, selectedRows, hideMarkersOnRemove]);

  useEffect(() => { // Cleanup
    if (!manager) {
      return;
    }

    return () => {
      manager.removeAllMarkers();
    };
  }, [manager]); // Only run on unmount or manager change

  return markerIdsToRender.size;
};

const useRenderingCallbacks = () => {
  const dispatch = useDispatch();

  return useMemo(() => ({
    onStart: () => dispatch(frontedStateProcessingRenderingMarkersStart()),
    onStop: () => dispatch(frontedStateProcessingRenderingMarkersStop()),
  }), [dispatch]);
};
