import { omit } from '~/_shared/utils/object/omit';
import { type GroupId } from '~/store/spreadsheetData/spreadsheetData.state';
import { type DriveTimeErrorResponseMessage } from '../../../proximity/proximity.repository';
import { nameOf } from '../../utils/nameOf';
import { type UnitSystem } from '../googleMaps/googleMaps.types';
import { type MultiPolygon } from '../polygon/polygon.types';
import { type SpreadsheetColumnId } from '../spreadsheetData/spreadsheetColumn';
import { type SpreadsheetRowId } from '../spreadsheetData/spreadsheetRow';
import {
  GroupRadiusEditLevel,
  ProximityType,
} from './proximity.enums';

export type ProximityStyles = Readonly<{
  color: string;
  borderColor: string;
  borderWidth: number;
  fillOpacity: number;
  borderOpacity: number;
}>;

export type RadiusProximityBasicData = Readonly<{
  radius: number;
  unit: UnitSystem;
  name: string;
  styles: ProximityStyles;
}>;

export type RadiusGroupData = Readonly<{
  columnId: SpreadsheetColumnId;
  group: GroupId;
  individualOverride: {
    readonly [spreadsheetId: number]: {
      readonly [rowId: string]: Partial<RadiusProximityBasicData>;
    };
  };
}>;

export type RadiusIndividualData = Readonly<{
  address: string;
  lat: number;
  lng: number;
  rowId?: SpreadsheetRowId;
}>;

export type RadiusData = Readonly<{
  applyTo: GroupRadiusEditLevel;
  data: RadiusGroupData | RadiusIndividualData;
  radius: number;
  unit: UnitSystem;
}>;

export interface GroupRadiusData extends RadiusData {
  applyTo: GroupRadiusEditLevel.Group;
  data: RadiusGroupData;
}

export interface IndividualRadiusData extends RadiusData {
  applyTo: GroupRadiusEditLevel.IndividualLocation;
  data: RadiusIndividualData;
}

type DriveTimePolygonSettings = Readonly<{
  address: string;
  lat: number;
  lng: number;
  hours: number;
  minutes: number;
}>;

export type DriveTimePolygonData = DriveTimePolygonSettings & {
  // can be null in some scenarios (starting point is lake, hill)
  paths?: MultiPolygon | null;
  message?: DriveTimeErrorResponseMessage | null;
};

export interface DriveTimePolygonProximitySettings extends ProximityModel {
  type: ProximityType.DriveTimePolygon;
  data: DriveTimePolygonSettings;
}

export interface DriveTimePolygonProximity extends Proximity {
  type: ProximityType.DriveTimePolygon;
  data: DriveTimePolygonData;
}

export interface RadiusProximity extends Proximity {
  type: ProximityType.DistanceRadius;
  data: RadiusData;
}
export interface GroupRadiusProximity extends RadiusProximity {
  data: GroupRadiusData;
}

export interface IndividualRadiusProximity extends RadiusProximity {
  data: IndividualRadiusData;
}

export type Proximity = Readonly<{
  id: string;
  name: string;
  data: RadiusData | DriveTimePolygonData;
  type: ProximityType;
  styles: ProximityStyles;
}>;

export type ProximityModel = Readonly<{
  id: string;
  name: string | GroupId;
  data: (RadiusData | DriveTimePolygonSettings) & {paths?: never};
  type: ProximityType;
  styles: ProximityStyles;
}>;

export const areGroupRadiusData = (data: RadiusIndividualData | RadiusGroupData): data is RadiusGroupData =>
  data.hasOwnProperty(nameOf<RadiusGroupData>('group')) && data.hasOwnProperty(nameOf<RadiusGroupData>('columnId'));

export const areRadiusData = (data: RadiusData | DriveTimePolygonData | DriveTimePolygonSettings): data is RadiusData =>
  data.hasOwnProperty(nameOf<RadiusData>('applyTo')) && data.hasOwnProperty(nameOf<RadiusData>('radius'));

export const isDriveTimePolygon = (proximity: Proximity): proximity is DriveTimePolygonProximity =>
  proximity.type === ProximityType.DriveTimePolygon && !areRadiusData(proximity.data);

export const isDriveTimePolygonSettings = (proximity: Proximity): proximity is DriveTimePolygonProximitySettings =>
  proximity.type === ProximityType.DriveTimePolygon && !areRadiusData(proximity.data);

export const isRadiusProximity = (proximity: Proximity): proximity is RadiusProximity =>
  proximity.type === ProximityType.DistanceRadius;

export const isGroupRadius = (proximity: Proximity): proximity is GroupRadiusProximity =>
  proximity.type === ProximityType.DistanceRadius &&
  areRadiusData(proximity.data) &&
  proximity.data.applyTo === GroupRadiusEditLevel.Group &&
  areGroupRadiusData(proximity.data.data);

export const isIndividualRadius = (proximity: Proximity): proximity is IndividualRadiusProximity =>
  proximity.type === ProximityType.DistanceRadius &&
  areRadiusData(proximity.data) &&
  proximity.data.applyTo === GroupRadiusEditLevel.IndividualLocation &&
  !areGroupRadiusData(proximity.data.data);

export const toProximityModel = (proximity: Proximity): ProximityModel => {
  const data = isDriveTimePolygon(proximity) ? omit(proximity.data, ['paths']) : proximity.data;
  return { ...proximity, data };
};
