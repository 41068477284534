import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { mq } from '~/_shared/style.mixins';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { MOBILE_VERSION_WIDTH_THRESHOLD } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { useTranslation } from '~/_shared/utils/hooks';

type NumericalGroupSettingsTableProps = Readonly<{
  dataHeadingLabel: string;
  dataRows: ReactNode;
}>;

const tableStyle = css({
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'collapse',
});

const tableHeadingStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  textTransform: 'uppercase',
  fontSize: '14px',
  padding: '16px 8px 16px 0',
  textAlign: 'left',
  '&:first-of-type': {
    paddingLeft: 16,
    paddingRight: 8,
  },
});

const tableHeadingLowPriorityColumnStyle = ({ theme }: ThemeProps) => css(tableHeadingStyle({ theme }), {
  display: 'none',

  [mq(MOBILE_VERSION_WIDTH_THRESHOLD)]: {
    display: 'table-cell',
  },
});

const tablePrimaryColumnStyle = ({ theme }: ThemeProps) => css(tableHeadingStyle({ theme }), {
  width: '50%',
});

export const NumericalGroupSettingsTableComponent: FC<NumericalGroupSettingsTableProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <table css={tableStyle}>
      <thead>
        <tr>
          <th css={tableHeadingStyle({ theme })}>{t('Fill Color')}</th>
          <th css={tableHeadingLowPriorityColumnStyle({ theme })}>{t('Fill Opacity')}</th>
          <th css={tablePrimaryColumnStyle({ theme })}>{props.dataHeadingLabel}</th>
        </tr>
      </thead>
      <tbody>
        {props.dataRows}
      </tbody>
    </table>
  );
};
