import { css } from '@emotion/react';

export const contentStyle = css`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`;

export const textStyle = css`
  text-align: center;
`;
