import { ImageSize } from '~/_shared/constants/imageSize.enum';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  MAX_RESOLUTION_HEIGHT, MAX_RESOLUTION_WIDTH, MIN_RESOLUTION_HEIGHT, MIN_RESOLUTION_WIDTH,
} from '~/store/frontendState/imageExport/imageExport.repository';
import { ImageFileType } from '../../constants/imageType.enum';

const LARGE_RESOLUTION_HEIGHT = MAX_RESOLUTION_HEIGHT;
const LARGE_RESOLUTION_WIDTH = MAX_RESOLUTION_WIDTH;

export const imageTypeToExportImageExtension = (imageType: ImageFileType) => {
  switch (imageType) {
    case ImageFileType.JPEG:
      return 'jpeg';
    case ImageFileType.PDF:
      return 'pdf';
    case ImageFileType.PNG:
      return 'png';
    default:
      throw new Error(`imageType.enum.ts: unknown imageType '${imageType}'.`);
  }
};

export const getImageResolution = (imageSize: ImageSize) => {
  if (imageSize === ImageSize.Normal) {
    return {
      height: clamp(window.innerHeight, { min: MIN_RESOLUTION_HEIGHT, max: MAX_RESOLUTION_HEIGHT }),
      width: clamp(window.innerWidth, { min: MIN_RESOLUTION_WIDTH, max: MAX_RESOLUTION_WIDTH }),
    };
  }

  return {
    height: LARGE_RESOLUTION_HEIGHT,
    width: LARGE_RESOLUTION_WIDTH,
  };
};
