import memoizee from 'memoizee';
import { logError } from '../logError';

const maxZoom = 40;

export const calculateMapScale = (zoom: number) => {
  if (!Number.isInteger(zoom)) {
    logError(`calculateMapScale: zoom has to be an integer, passed zoom: ${zoom}`);
    zoom = Math.floor(zoom);
  }
  if (zoom > maxZoom) {
    logError(`calculateMapScale: zoom is bigger then maximum of ${maxZoom}, passed zoom: ${zoom}`);
    zoom = maxZoom;
  }
  if (zoom < 0) {
    logError(`calculateMapScale: zoom is less then 0, passed zoom: ${zoom}`);
    zoom = 0;
  }

  return calculateMapScaleMemoized(zoom);
};

const calculateMapScaleMemoized = memoizee(
  (zoom: number) => 591657550.500000 / Math.pow(2, zoom - 1),
  { max: maxZoom + 1 }
);
