import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useStreetViewPanoramaLocation } from '~/_shared/components/streetViewPanorama/useStreetViewPanoramaLocation';
import { type LatLng } from '~/_shared/types/latLng';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useMap } from '~/map/map/mapContext';
import { frontendStateStreetViewSet } from '~/store/frontendState/mapTools/streetView/streetView.actionCreators';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { ProximityType } from '../../_shared/types/proximity/proximity.enums';
import { type GeoLocation } from '../../_shared/utils/geolocation/geolocation';
import { useLocationFromGeolocation } from '../../_shared/utils/hooks/useLocationFromGeolocation';
import { usePrevious } from '../../_shared/utils/hooks/usePrevious';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { frontendStateDirectionsAddMarkerWaypoint } from '../../store/frontendState/mapTools/directions/directions.actionCreators';
import { type DirectionSourceType } from '../../store/frontendState/mapTools/directions/directions.state';
import {
  selectDistanceCalculatorDrawingMode,
  startDistanceCalculator,
} from '../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.actionCreators';
import { type DistanceCalculatorDrawingModeEnum } from '../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.state';
import {
  setStartingLocation, setType,
} from '../../store/frontendState/proximityTool/proximityTool.actionCreators';
import { useMapSettingsDirectionsShouldBeginAtUserLocationSelector } from '../../store/mapSettings/directions/mapSettingsDirections.selectors';
import { usePublicMapSettingsSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { MapContextMenuComponent } from './mapContextMenu.component';

export const MapContextMenuContainer: FC = () => {
  const dispatch = useDispatch();
  const directionsUseUserLocation = useMapSettingsDirectionsShouldBeginAtUserLocationSelector();
  const isPresentationalView = useIsMapPresentationalSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const { openModal: openFindLocation } = useModal(ModalType.LocationFinder);
  const [isLoading, setIsLoading] = useState(false);
  const [latLng, setLatLng] = useState<LatLng | null>(null);
  const previousLatLng = usePrevious(latLng);
  const [address, setAddress] = useState('');
  const { addLocationFromLatLng, getGeolocationFromLatLng } = useLocationFromGeolocation();
  const isMapInteractionActive = useIsMapInteractionActive();
  const map = useMap();

  const { streetViewLocation } = useStreetViewPanoramaLocation(latLng);

  const isStreetViewAvailable = !!streetViewLocation
    && (!isPresentationalView || publicMapSettings.allowStreetView);

  const handleAddressRequest = useCallback((
    params: { callback: (response: GeoLocation) => void}
  ) => {
    if (!latLng) {
      return;
    }

    getGeolocationFromLatLng(
      { callback: params.callback, ...latLng },
      setIsLoading,
    );
  }, [getGeolocationFromLatLng, latLng]);

  const handleGetAddress = useCallback(() =>
    handleAddressRequest({
      callback: response => {
        setAddress(response.address);
      },
    }), [handleAddressRequest]);

  const handleAddWaypoint = useCallback((directionSourceType: DirectionSourceType) =>
    handleAddressRequest({
      callback: (response) => {
        dispatch(frontendStateDirectionsAddMarkerWaypoint({
          address: response.address,
          latLng: response.latLng,
          startsFromUserLocation: directionsUseUserLocation,
          directionSourceType,
        }));
      },
    }), [directionsUseUserLocation, dispatch, handleAddressRequest]);

  const handleAddProximityStart = useCallback(() =>
    handleAddressRequest({
      callback: (response) => {
        dispatch(setType(ProximityType.DistanceRadius));
        dispatch(
          setStartingLocation(response, true)
        );
      },
    }), [dispatch, handleAddressRequest]);

  const handleAddMarkerCallback = useCallback(() => {
    if (!latLng) {
      return;
    }

    addLocationFromLatLng(
      latLng,
      setIsLoading,
    );
  }, [addLocationFromLatLng, latLng]);

  const handleFindClosesLocation = useCallback(() =>
    handleAddressRequest({
      callback: (response => {
        openFindLocation({ location: response });
      }),
    }), [handleAddressRequest, openFindLocation]);

  const handleDistanceCalculatorDrawingMode = useCallback((mode: DistanceCalculatorDrawingModeEnum) => {
    if (!latLng) {
      return;
    }

    dispatch(startDistanceCalculator());
    dispatch(selectDistanceCalculatorDrawingMode(mode, latLng));
  }, [dispatch, latLng]);

  const handleOpenStreetView = useCallback(() => {
    if (isStreetViewAvailable) {
      dispatch(frontendStateStreetViewSet(streetViewLocation));
    }
  }, [dispatch, isStreetViewAvailable, streetViewLocation]);

  const getPublicMapViewCondition = useCallback((condition: boolean) =>
    !isPresentationalView || condition,
  [isPresentationalView]);

  useEffect(() => {
    if (latLng && previousLatLng !== latLng) {
      handleGetAddress();
    }
  }, [handleGetAddress, latLng, previousLatLng]);

  if (isMapInteractionActive) {
    return null;
  }

  return (
    <MapContextMenuComponent
      addMarker={handleAddMarkerCallback}
      addProximityStart={handleAddProximityStart}
      addWaypoint={handleAddWaypoint}
      address={address}
      findClosestLocation={handleFindClosesLocation}
      getAddress={handleGetAddress}
      googleMap={map}
      isAddRadiusAllowed={getPublicMapViewCondition(publicMapSettings.radiusProximity)}
      isDistanceMeasuringAllowed={getPublicMapViewCondition(publicMapSettings.distanceCalculator)}
      isAddMarkerAllowed={!isPresentationalView && !isLayeredMapConnected}
      isFindClosestLocationAllowed={getPublicMapViewCondition(publicMapSettings.locationFinder)}
      isGetDirectionsAllowed={getPublicMapViewCondition(publicMapSettings.routingDirections)}
      isLoading={isLoading}
      isStreetViewAvailable={isStreetViewAvailable}
      latitude={latLng?.lat ?? 0}
      longitude={latLng?.lng ?? 0}
      openStreetView={handleOpenStreetView}
      setAddress={setAddress}
      setLatLng={setLatLng}
      startDistanceCalculator={handleDistanceCalculatorDrawingMode}
    />
  );
};
