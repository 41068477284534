export const MOVE_MARKER_LABELS_OPEN_MODAL = 'MOVE_MARKER_LABELS_OPEN_MODAL';
export const MOVE_MARKER_LABELS_CLOSE_MODAL = 'MOVE_MARKER_LABELS_CLOSE_MODAL';
export const MOVE_MARKER_LABELS_ACTIVATE = 'MOVE_MARKER_LABELS_ACTIVATE';
export const MOVE_MARKER_LABELS_DEACTIVATE = 'MOVE_MARKER_LABELS_DEACTIVATE';
export const MOVE_MARKER_LABELS_DISCARD_CHANGES = 'MOVE_MARKER_LABELS_DISCARD_CHANGES';
export const MOVE_MARKER_LABELS_UPDATE_POSITION = 'MOVE_MARKER_LABELS_UPDATE_POSITION';
export const MOVE_MARKER_LABELS_DRAG_START = 'MOVE_MARKER_LABELS_DRAG_START';
export const MOVE_MARKER_LABELS_DRAG_STOP = 'MOVE_MARKER_LABELS_DRAG_STOP';
export const MOVE_MARKER_LABELS_XMARK_HOVERED = 'MOVE_MARKER_LABELS_XMARK_HOVERED';
export const MOVE_MARKER_LABELS_XMARK_HOVER_STOP = 'MOVE_MARKER_LABELS_XMARK_HOVER_STOP';
