import {
  put, takeLatest,
} from 'redux-saga/effects';
import { type ApiError } from '~/_shared/utils/api/apiError.helpers';
import { isPublicDomain } from '~/_shared/utils/url/url.helpers';
import { errorPageSetError } from '~/store/errorPage/errorPage.actionCreators';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import { type PickAction } from '../../../_shared/utils/types/action.type';
import { type MapInfoAction } from '../../mapInfo/mapInfo.action';
import {
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS,
} from '../../mapInfo/mapInfo.actionTypes';
import {
  mapPresentationalSetShowWrongPasswordError,
  mapPresentationalSetView,
} from './mapPresentational.actionCreators';
import { MapPresentationalView } from './mapPresentational.state';

let fetchDataPasswordErrorCounter = 0;

const isOwnerDisabledError = (error: ApiError) => {
  return error.errorCode === 'OWNER_IS_DISABLED';
};

export function* mapPresentationalSagas() {
  yield takeLatest(MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR, onMapInfoPresentationalFetchError);
  yield takeLatest(MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST, hideWrongPasswordError);
  yield takeLatest(MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS, setIsPasswordRequiredFalse);
}

function* onMapInfoPresentationalFetchError(action: PickAction<MapInfoAction, typeof MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR>) {
  if (action.payload.error.errors?.password?.length ?? 0 > 0) {
    if (fetchDataPasswordErrorCounter > 0) {
      yield put(mapPresentationalSetShowWrongPasswordError(true));
      return;
    }

    yield put(mapPresentationalSetView(MapPresentationalView.Password));
    fetchDataPasswordErrorCounter++;
    return;
  }

  if (action.payload.error.errors?.token?.length ?? 0 > 0) {
    if (isPublicDomain()) {
      yield put(errorPageSetError(ErrorPageType.PublicMapUnavailable));
    }
    else {
      yield put(mapPresentationalSetView(MapPresentationalView.PrivateTokenMissing));
    }

    return;
  }

  if (isOwnerDisabledError(action.payload.error)) {
    yield put(errorPageSetError(ErrorPageType.PublicMapUnavailable));
    return;
  }

  if (action.payload.error.responseStatus === 403) {
    yield put(errorPageSetError(ErrorPageType.PublicMapUnavailable));
    return;
  }

  if (action.payload.error.responseStatus === 404) {
    yield put(errorPageSetError(ErrorPageType.PageNotFound));
    return;
  }

  if (action.payload.error.errors?.map_views?.length) {
    yield put(errorPageSetError(ErrorPageType.PublicMapUnavailable));
    return;
  }

  yield put(errorPageSetError(ErrorPageType.General));
}

function* hideWrongPasswordError() {
  yield put(mapPresentationalSetShowWrongPasswordError(false));
}

function* setIsPasswordRequiredFalse() {
  yield put(mapPresentationalSetView(MapPresentationalView.Regular));
}
