import { MatchUpDataSection } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import {
  ColumnRole, isOptionalColumnRole, isPublicApiRelatedRole,
} from '~/_shared/types/columnRole.enum';
import { type TranslationFnc } from '~/_shared/utils/hooks';

export const getMatchUpColumnRolesForSection = (section: MatchUpDataSection, hideLatLng: boolean = false, hidePublicApiRelatedRoles: boolean = true): ReadonlyArray<ColumnRole> => {
  switch (section) {
    case MatchUpDataSection.LocationData:
      return getRequiredColumnRoles(hideLatLng);
    case MatchUpDataSection.InfoPopup:
      return getOptionalColumnRoles(hidePublicApiRelatedRoles);
    default:
      return [];
  }
};

export const getMatchUpDataHeaderForSection = (section: MatchUpDataSection, t: TranslationFnc): string => {
  switch (section) {
    case MatchUpDataSection.LocationData:
      return t('Match up Location Related Data');
    case MatchUpDataSection.InfoPopup:
      return t('Set the info Popup on your Map (optional)');
    default:
      return '';
  }
};

const getRequiredColumnRoles = (hideLatLng: boolean) =>
  Object.values(ColumnRole)
    .filter(columnRole => !isOptionalColumnRole(columnRole) && (!hideLatLng || !isLatLng(columnRole)));

const getOptionalColumnRoles = (filterOutPublicRelatedRoles: boolean) => (
  Object.values(ColumnRole)
    .filter(isOptionalColumnRole)
    .filter(columnRole => !filterOutPublicRelatedRoles || !isPublicApiRelatedRole(columnRole))
);

const isLatLng = (columnRole: ColumnRole) => columnRole === ColumnRole.Latitude || columnRole === ColumnRole.Longitude;
