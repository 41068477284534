import { useMemo } from 'react';
import { convertPolygonToGeometry } from '~/_shared/types/polygon/polygon.utils';
import { type PolygonFilterRequest } from '~/spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { useIsBoundaryGroupCustom } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { CUSTOM_BOUNDARY_ZOOM_LEVEL } from '~/store/boundaryItems/boundaryItems.state';
import { getBoundaryTerritoryGroupMatchupRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { type BoundaryFilterRequest } from '../../../spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type ExtendedBoundaryIdentifier } from '../../../store/boundaries/boundaryIdentifier.type';
import { useBoundaryTerritoryGroupsSelector } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useBoundaryMetrics } from './useBoundaryMetrics';
import { useMetricsData } from './useMetricsData';

const DEBUG_SEND_GEOMETRY_INSTEAD_OF_BOUNDARY_ID = false;

export const useBoundaryMetricsData = (activeMetric: ExtendedBoundaryIdentifier, ignoreFilters?: boolean) => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const { isBoundaryGroupCustom } = useIsBoundaryGroupCustom();
  const boundaryPolygons = useMapBoundariesSelector();

  const boundaryLastUpdateTimestamp = boundaryPolygons.get(activeMetric.boundaryGroupId)?.get(activeMetric.boundaryId)?.lastFetchTimestamp ?? 0;

  const boundaryTerritoryGroup = useMemo(() => {
    return boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === activeMetric.boundaryTerritoryGroupId);
  }, [boundaryTerritoryGroups, activeMetric.boundaryTerritoryGroupId]);

  const selectedMetrics = useBoundaryMetrics(boundaryTerritoryGroup);

  const polygonFilterRequest: PolygonFilterRequest | undefined = useMemo(() => {
    if (!DEBUG_SEND_GEOMETRY_INSTEAD_OF_BOUNDARY_ID) {
      return undefined;
    }

    if (!isBoundaryGroupCustom(activeMetric.boundaryGroupId)) {
      return undefined;
    }

    const boundary = boundaryPolygons.get(activeMetric.boundaryGroupId)?.get(activeMetric.boundaryId);
    const multiPolygon = boundary?.paths.get(CUSTOM_BOUNDARY_ZOOM_LEVEL);

    if (multiPolygon) {
      return {
        type: 'or',
        polygons: multiPolygon.map(polygon => ({ path: convertPolygonToGeometry(polygon) })),
      };
    }

    return undefined;
  }, [activeMetric.boundaryGroupId, activeMetric.boundaryId, boundaryPolygons, isBoundaryGroupCustom]);

  const boundaryFilterRequest: BoundaryFilterRequest | undefined = useMemo(() => {
    // we want to include boundaryLastUpdateTimestamp in the memo so that we refetch new metrics data if the boundary polygon is edited/updated
    if (!boundaryTerritoryGroup || !boundaryLastUpdateTimestamp) {
      return undefined;
    }

    if (DEBUG_SEND_GEOMETRY_INSTEAD_OF_BOUNDARY_ID) {
      if (isBoundaryGroupCustom(activeMetric.boundaryGroupId)) {
        return undefined;
      }
    }

    return {
      type: 'or',
      boundaries: {
        [activeMetric.boundaryGroupId]: {
          boundary_ids: [activeMetric.boundaryId],
          matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
        },
      },
    } satisfies BoundaryFilterRequest;
  }, [boundaryTerritoryGroup, activeMetric.boundaryGroupId, activeMetric.boundaryId, isBoundaryGroupCustom, boundaryLastUpdateTimestamp]);

  return useMetricsData({
    selectedMetrics,
    boundaryFilterRequest,
    polygonFilterRequest,
    ignoreFilterTree: ignoreFilters,
  });
};
