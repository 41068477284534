import { type DrawingPolylineSettingsState } from '../../store/mapSettings/drawing/settings/polyline/drawingPolylineSettings.state';
import {
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE, DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createPolylineDrawingOptionsFromSettings = (settings: DrawingPolylineSettingsState): Partial<DrawingToolOptions> => {
  return {
    sliderOptions: {
      strokeOpacity: {
        value: settings.strokeOpacity,
      },
      strokeWeight: {
        value: settings.strokeWeight,
        range: DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
        suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
      },
    },
    colorOptions: {
      strokeColor: settings.strokeColor,
    },
  };
};

export const createPolylineDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingPolylineSettingsState
): DrawingPolylineSettingsState => {
  return {
    strokeOpacity: toolOptions.sliderOptions?.strokeOpacity?.value ?? currentSettings.strokeOpacity,
    strokeWeight: toolOptions.sliderOptions?.strokeWeight?.value ?? currentSettings.strokeWeight,
    strokeColor: toolOptions.colorOptions?.strokeColor ?? currentSettings.strokeColor,
  };
};
