import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { MAPTIVE_SUPPORT_EMAIL } from '~/_shared/constants/maptive';
import { type ToolOrFeatureWithDataExport } from '~/_shared/types/toolsAndFeatures/toolsAndFeaturesWithDataExport.types';
import { openEmailDraft } from '~/_shared/utils/email/email.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { publicMapSettingsSetAllowedExportDataTools } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.actionCreators';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';

export const usePresentationalDataExport = () => {
  const clientEmail = useSelector<string>(state => state.userData.email);
  const allowedExportDataTools = usePublicMapSettingsAllowedExportDataToolsSelector();

  const dispatch = useDispatch();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const { openModal: openSelectToolsModal, closeModal: closeSelectToolsModal } = useModal(ModalType.PresentationalDataExportAllowTool);
  const [t] = useTranslation();

  const openContactUsForAuthorization = useCallback(() => {
    openConfirmationModal({
      confirmCaption: t('Contact Maptive Support'),
      maxWidth: 604,
      text: t('exportPresentationalMapData.contactUs.text'),
      title: t('exportPresentationalMapData.contactUs.caption'),
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        openEmailDraft(
          MAPTIVE_SUPPORT_EMAIL,
          t('exportPresentationalMapData.contactUs.email.subject{{CLIENT_EMAIL}}', { CLIENT_EMAIL: clientEmail }),
        );
      },
    });
  }, [clientEmail, closeConfirmationModal, openConfirmationModal, t]);

  const openSelectToolsForAllowExport = useCallback(() => {
    openSelectToolsModal({
      initialSelectedMapTools: new Set(allowedExportDataTools),
      onProceed: (newSelections: ReadonlySet<ToolOrFeatureWithDataExport>) => {
        dispatch(publicMapSettingsSetAllowedExportDataTools(newSelections));
        closeSelectToolsModal();
      },
    });
  }, [openSelectToolsModal, allowedExportDataTools, dispatch, closeSelectToolsModal]);

  return useMemo(() => ({
    openContactUsForAuthorization,
    openSelectToolsForAllowExport,
  }), [openContactUsForAuthorization, openSelectToolsForAllowExport]);
};
