import {
  useEffect, useState,
} from 'react';
import { getOrSet } from '~/_shared/utils/collections/collections';
import { setCoordinateMapType } from '~/testingMode/testingModeFunctions';
import {
  TESTING_MAP_TYPE, testingModeEnabled,
} from '../../../testingMode/testingMode';
import { DEFAULT_GOOGLE_MAPS_SETTINGS } from './google-map.component';
import { loadGoogleMapsScript } from './google-map.loader';

export type GoogleMapInstance = {
  container: HTMLDivElement;
  instance: google.maps.Map;
};

const googleMapsInstances = new Map<string, GoogleMapInstance>();

const createGoogleMap = () => {
  const container = document.createElement('div');
  container.style.width = '100%';
  container.style.height = '100%';

  const instance = new google.maps.Map(container, {
    ...DEFAULT_GOOGLE_MAPS_SETTINGS,
    ...(testingModeEnabled() ? { mapTypeId: TESTING_MAP_TYPE } : undefined),
  });

  setCoordinateMapType(instance);

  return { container, instance };
};

export const useGetGoogleMapInstance = (id: string) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    loadGoogleMapsScript().then(() => setGoogleMapsLoaded(true));
  }, []);

  if (!googleMapsLoaded) {
    return undefined;
  }

  return getOrSet(googleMapsInstances, id, createGoogleMap);
};
