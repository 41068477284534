import { type LatLng } from '../../../../_shared/types/latLng';
import {
  FRONTEND_STATE_DISABLE_LOCATION_TRACKING,
  FRONTEND_STATE_ENABLE_LOCATION_TRACKING,
  FRONTEND_STATE_LOCATION_TRACKING_ERROR,
  FRONTEND_STATE_SET_MY_LOCATION,
} from './myLocation.actionTypes';

export const setMyLocation = (latLng: LatLng | null) => ({
  type: FRONTEND_STATE_SET_MY_LOCATION,
  payload: {
    latLng,
  },
}) as const;

export const disableLocationTracking = () => ({
  type: FRONTEND_STATE_DISABLE_LOCATION_TRACKING,
}) as const;

export const enableLocationTracking = () => ({
  type: FRONTEND_STATE_ENABLE_LOCATION_TRACKING,
}) as const;

export const locationTrackingError = () => ({
  type: FRONTEND_STATE_LOCATION_TRACKING_ERROR,
}) as const;
