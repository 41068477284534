import {
  type ColumnInfo, type ColumnsWithValuesDataType,
} from './guessUniqueColumns';
import {
  getColumnsByKeywords, getHeaderAndValuesFromColumnsWithValues, isColumnCombinationUnique,
} from './guessUniqueColumns.helpers';

export const ADDITIONAL_COLUMN_NAMES_KEYWORDS = ['apartment', 'apt.', 'name', 'last name', 'surname'];
export const ADDRESS_2_COLUMN_NAMES_KEYWORDS = ['number', 'nr.', 'city', 'street'];
export const ADDRESS_COLUMN_NAMES_KEYWORDS = ['address'];
export const LATITUDE_COLUMN_NAMES_KEYWORDS = ['latitude', 'lat'];
export const LONGITUDE_COLUMN_NAMES_KEYWORDS = ['longitude', 'lng', 'lon'];

export const guessUniqueAddressColumns = (header: ColumnInfo[], data: ColumnsWithValuesDataType): number[] | null => {
  if (!data) {
    return null;
  }

  const { header: columnNames, data: dataArray } = getHeaderAndValuesFromColumnsWithValues(header, data);

  const getHeaderIndexesByKeywords = (names: string[]) => getColumnsByKeywords(names, columnNames);

  const addressIndex = getHeaderIndexesByKeywords(ADDRESS_COLUMN_NAMES_KEYWORDS)[0];
  const additionalColumnIndexes = getHeaderIndexesByKeywords(ADDITIONAL_COLUMN_NAMES_KEYWORDS);
  const address2Indexes = getHeaderIndexesByKeywords(ADDRESS_2_COLUMN_NAMES_KEYWORDS);

  if (addressIndex !== undefined && additionalColumnIndexes && additionalColumnIndexes.length) {
    const relevantColumnIndexes = [addressIndex, ...additionalColumnIndexes];
    if (isColumnCombinationUnique(relevantColumnIndexes, dataArray)) {
      return relevantColumnIndexes;
    }
  }

  if (address2Indexes.length && additionalColumnIndexes && additionalColumnIndexes.length) {
    const relevantColumns = [...address2Indexes, ...additionalColumnIndexes];
    if (isColumnCombinationUnique(relevantColumns, dataArray)) {
      return relevantColumns;
    }
  }

  // 'latitude'/'lat', 'longitude'/'lng'/'lon'
  const latitudeIndex = getHeaderIndexesByKeywords(LATITUDE_COLUMN_NAMES_KEYWORDS);
  const longitudeIndex = getHeaderIndexesByKeywords(LONGITUDE_COLUMN_NAMES_KEYWORDS);

  if (latitudeIndex.length && longitudeIndex.length) {
    const relevantColumns = [...latitudeIndex, ...longitudeIndex, ...additionalColumnIndexes];
    if (isColumnCombinationUnique(relevantColumns, dataArray)) {
      return relevantColumns;
    }
  }

  return null;
};
