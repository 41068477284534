import {
  GEOCODING_PAUSE_ERROR,
  GEOCODING_PAUSE_REQUEST,
  GEOCODING_PAUSE_SUCCESS,
  GEOCODING_PROCESSING_SUCCESS,
  GEOCODING_RESUME_ERROR,
  GEOCODING_RESUME_REQUEST,
  GEOCODING_RESUME_SUCCESS,
  GEOCODING_SET_IS_PAUSE_RESUME_LOADING,
  GEOCODING_SET_PROGRESS,
  GEOCODING_START_TRACKING,
} from './geocoding.actionTypes';
import { type PerSpreadsheetGeocodingUpdates } from './geocoding.state';

export const geocodingProcessingSuccess = (realSpreadsheetIds: Set<number>) => ({
  type: GEOCODING_PROCESSING_SUCCESS,
  payload: {
    realSpreadsheetIds,
  },
}) as const;

export const geocodingStartTracking = (realSpreadsheetId: number, triggeredByUserId: number, startingPercent: number) => ({
  type: GEOCODING_START_TRACKING,
  payload: {
    realSpreadsheetId,
    triggeredByUserId,
    startingPercent,
  },
}) as const;

export const geocodingSetProgress = (updates: PerSpreadsheetGeocodingUpdates) => ({
  type: GEOCODING_SET_PROGRESS,
  payload: {
    updates,
  },
}) as const;

export const geocodingPauseRequest = (clientId: number, realSpreadsheetId: number) => ({
  type: GEOCODING_PAUSE_REQUEST,
  payload: {
    clientId,
    realSpreadsheetId,
  },
}) as const;

export const geocodingPauseError = (realSpreadsheetId: number) => ({
  type: GEOCODING_PAUSE_ERROR,
  payload: {
    realSpreadsheetId,
  },
}) as const;

export const geocodingPauseSuccess = (realSpreadsheetId: number) => ({
  type: GEOCODING_PAUSE_SUCCESS,
  payload: {
    realSpreadsheetId,
  },
}) as const;

export const geocodingResumeRequest = (clientId: number, realSpreadsheetId: number) => ({
  type: GEOCODING_RESUME_REQUEST,
  payload: {
    clientId,
    realSpreadsheetId,
  },
}) as const;

export const geocodingResumeError = (realSpreadsheetId: number) => ({
  type: GEOCODING_RESUME_ERROR,
  payload: {
    realSpreadsheetId,
  },
}) as const;

export const geocodingResumeSuccess = (realSpreadsheetId: number) => ({
  type: GEOCODING_RESUME_SUCCESS,
  payload: {
    realSpreadsheetId,
  },
}) as const;

export const geocodingSetIsPauseResumeLoading = (realSpreadsheetId: number) => ({
  type: GEOCODING_SET_IS_PAUSE_RESUME_LOADING,
  payload: {
    realSpreadsheetId,
  },
}) as const;
