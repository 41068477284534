import { SpreadsheetRowIdMap } from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { type MoveMarkerLabelsAction } from './moveMarkerLabels.action';
import {
  MOVE_MARKER_LABELS_ACTIVATE,
  MOVE_MARKER_LABELS_CLOSE_MODAL,
  MOVE_MARKER_LABELS_DEACTIVATE,
  MOVE_MARKER_LABELS_DISCARD_CHANGES,
  MOVE_MARKER_LABELS_OPEN_MODAL,
  MOVE_MARKER_LABELS_UPDATE_POSITION,
} from './moveMarkerLabels.actionTypes';
import { type MoveMarkerLabelsState } from './moveMarkerLabels.state';

const initialState: MoveMarkerLabelsState = {
  isActive: false,
  isModalOpen: false,
  offsets: new SpreadsheetRowIdMap(),
};

export const moveMarkerLabelsReducer = (state = initialState, action: MoveMarkerLabelsAction): MoveMarkerLabelsState => {
  switch (action.type) {
    case MOVE_MARKER_LABELS_OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      };
    }

    case MOVE_MARKER_LABELS_CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
        isActive: false,
        offsets: initialState.offsets,
      };
    }

    case MOVE_MARKER_LABELS_ACTIVATE: {
      return {
        ...state,
        isActive: true,
        offsets: initialState.offsets,
      };
    }

    case MOVE_MARKER_LABELS_DEACTIVATE: {
      return {
        ...state,
        isActive: false,
        offsets: initialState.offsets,
      };
    }

    case MOVE_MARKER_LABELS_DISCARD_CHANGES: {
      return {
        ...state,
        offsets: initialState.offsets,
      };
    }

    case MOVE_MARKER_LABELS_UPDATE_POSITION: {
      const offsets = new SpreadsheetRowIdMap(state.offsets);
      offsets.set(action.payload.id, action.payload);

      return {
        ...state,
        offsets,
      };
    }

    default: {
      return state;
    }
  }
};
