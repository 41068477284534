import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  s, Trans,
} from '~/translations/Trans';

const upgradeModalWrapperStyle = css({
  cursor: 'default',
  padding: '16px 32px',
});

export const UpgradeWMSSubscriptionModalComponent: FC <ModalProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      caption={t('Upgrade Subscription')}
      contentStyle={upgradeModalWrapperStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      skipCloseOnMapIdChange
    >
      <Trans
        i18nKey="For subscription to Auto Territory Builder please contact <1>support</1>"
      >
        {s('For subscription to Auto Territory Builder please contact ')}
        <SupportLinkComponent type={SupportLinkType.info} />
      </Trans>
    </ModalComponent>
  );
};
