import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { AppErrorType } from '~/appError//appErrorType.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useClientIdSelector } from '~/store//selectors/useClientIdSelector';
import { userGetDataRequest } from '~/store//userData/userData.actionCreators';
import {
  cancelClientSubscriptionRenewal, restoreClientSubscriptionRenewal,
} from '~/subscription/billing.repository';
import { CancelSubscriptionConfirmationBodyComponent } from '~/subscription/cancelSubscriptionConfirmationBody.component';

export const useManageClientSubscriptionRenewal = () => {
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { openModal: openErrorModal } = useModal(ModalType.AppError);
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const cancelRenewalCall = useCallback(() => {
    if (clientId) {
      return cancelClientSubscriptionRenewal(clientId);
    }
    return Promise.reject();
  }, [clientId]);

  const restoreRenewalCall = useCallback(() => {
    if (clientId) {
      return restoreClientSubscriptionRenewal(clientId);
    }
    return Promise.reject();
  }, [clientId]);

  const {
    invokeAjax: invokeCancel, isLoading: isCancelLoading, error: cancelError,
  } = useAjaxCall(cancelRenewalCall);
  const {
    invokeAjax: invokeRestore, isLoading: isRestoreLoading, error: restoreError,
  } = useAjaxCall(restoreRenewalCall);

  const onCancelSubscription = useCallback(() => {
    invokeCancel().then(() => {
      dispatch(userGetDataRequest());
      closeConfirmationModal();
    });
  }, [closeConfirmationModal, dispatch, invokeCancel]);

  const isError = !!cancelError || !!restoreError;

  const openCancelRenewalConfirmation = useCallback(() => {
    openConfirmationModal({
      confirmCaption: t('Cancel Subscription'),
      isConfirmButtonDestructive: true,
      onCancel: closeConfirmationModal,
      onConfirm: onCancelSubscription,
      text: (<CancelSubscriptionConfirmationBodyComponent />),
      title: t('Are you sure you want to cancel your license?'),
    });
  }, [closeConfirmationModal, onCancelSubscription, openConfirmationModal, t]);

  const restoreRenewal = useCallback(() => {
    invokeRestore().then(() => {
      dispatch(userGetDataRequest());
    });
  }, [dispatch, invokeRestore]);

  useEffect(() => {
    if (isError) {
      openErrorModal({
        errorTitle: t('Failed to Update Subscription.'),
        text: t('SubscriptionManagement.ErrorMessage.CancellationFailed'),
        title: t('Error'),
        type: AppErrorType.General,
      });
    }
  }, [isError, openErrorModal, t]);

  return useMemo(() => ({
    isLoading: isRestoreLoading || isCancelLoading,
    isError: !!cancelError || !!restoreError,
    openCancelRenewalConfirmation,
    restoreRenewal,
  }), [cancelError, openCancelRenewalConfirmation, isCancelLoading, isRestoreLoading, restoreError, restoreRenewal]);
};
