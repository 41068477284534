import {
  type FC, useEffect, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  DropdownLabelComponent, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { StackedWarningsComponent } from '~/_shared/baseComponents/warning/stackedWarnings.component';
import {
  headingStyle, optionsWrapperStyle, submitButtonStyle,
} from '~/_shared/components/customizeMetrics/selectData/customizeMetricsSelectData.styles';
import { useDemographicDropdownOptions } from '~/_shared/components/customizeMetrics/useDemographicDropdownOptions';
import { matchBoundaryGroupDemographic } from '~/_shared/utils/metric/demographicsMetric.factory';
import { type DemographicsRegion } from '~/store/demographics/demographics.repository';
import { useTranslation } from '../../../utils/hooks';
import { customizeMetricsDropdownTriggerStyle } from '../customizeMetrics.component';
import {
  type SelectedDataMetric, SelectedDataType,
} from '../selectedDataMetric.type';
import { getDemographicRegionLabel } from './customizeMetricsSelectDataDemographics.helpers';

type CustomizeMetricsSelectDataDemographicsProps = Readonly<{
  addSelectionButtonText: string;
  className?: string;
  demographicsRegions: DemographicsRegion[];
  onSubmit: (newData: SelectedDataMetric[]) => void;
  selectedData: SelectedDataMetric[];
}>;

export const CustomizeMetricsSelectDataDemographicsComponent: FC<CustomizeMetricsSelectDataDemographicsProps> = (props) => {
  const [selectedDemographicCategory, setSelectedDemographicCategory] = useState<string | null>(null);
  const [selectedDemographicId, setSelectedDemographicId] = useState<string | null>(null);
  const [selectedDemographicRegionId, setSelectedDemographicRegionId] = useState<string | null>(null);
  const [t] = useTranslation();

  const { addSelectionButtonText, selectedData, onSubmit } = props;

  const demographics = useMemo(() => {
    if (!selectedDemographicRegionId) {
      return [];
    }
    const regionData = props.demographicsRegions.find(region => region.id === selectedDemographicRegionId);

    return regionData ? regionData.demographics : [];
  }, [props.demographicsRegions, selectedDemographicRegionId]);

  const { demographicOptions, demographicCategoryOptions } = useDemographicDropdownOptions(demographics, selectedDemographicCategory);

  const demographicRangeOptions: DropdownOption<string>[] = useMemo(() => props.demographicsRegions.map(region => ({
    name: getDemographicRegionLabel(region.id, t),
    value: region.id,
  })), [props.demographicsRegions, t]);

  const isItemAlreadySelected = useMemo(() => (
    selectedData.some(item => {
      const isDemographic = item.type === SelectedDataType.Demographic;
      return (isDemographic && selectedDemographicId) && matchBoundaryGroupDemographic(item.selectedDemographic.id, selectedDemographicId);
    })
  ), [selectedData, selectedDemographicId]);

  const isSubmitDisabled: boolean =
    selectedDemographicRegionId === null ||
    selectedDemographicId === null ||
    isItemAlreadySelected;

  const addSelectedItem = () => {
    if (isSubmitDisabled) {
      return;
    }

    const selectedDemographic = props.demographicsRegions
      .find(region => region.id === selectedDemographicRegionId)?.demographics
      .find(demographic => demographic.id === selectedDemographicId);

    if (!selectedDemographic) {
      return;
    }

    const dataItem: SelectedDataMetric = {
      type: SelectedDataType.Demographic,
      selectedDemographic,
    };

    const newData = [...props.selectedData];
    newData.push(dataItem);

    onSubmit(newData);

    setSelectedDemographicId(null);
  };

  const metricsWarnings = useMemo(() => {
    const warnings = [];
    if (isItemAlreadySelected) {
      warnings.push(t('This combination is already on the list'));
    }

    return warnings;
  }, [isItemAlreadySelected, t]);

  useEffect(() => {
    setSelectedDemographicId(null);
  }, [selectedDemographicCategory]);

  useEffect(() => {
    setSelectedDemographicCategory(null);
    setSelectedDemographicId(null);
  }, [selectedDemographicRegionId]);

  return (
    <div className={props.className}>
      <strong css={headingStyle}>{t('DemographicCensusData')}</strong>

      <div css={optionsWrapperStyle}>
        <div>
          <DropdownLabelComponent>{t('Select Region')}</DropdownLabelComponent>
          <RegularDropdownComponent
            onChange={setSelectedDemographicRegionId}
            value={selectedDemographicRegionId}
            options={demographicRangeOptions}
            triggerStyle={customizeMetricsDropdownTriggerStyle({ isPlaceholder: selectedDemographicRegionId === null })}
            placeholder={t('Select Region')}
            inPortal
          />
        </div>

        {selectedDemographicRegionId !== null && (
          <>
            <div>
              <DropdownLabelComponent>{t('Select Demographic Group')}</DropdownLabelComponent>
              <RegularDropdownComponent
                onChange={setSelectedDemographicCategory}
                value={selectedDemographicCategory}
                options={demographicCategoryOptions}
                triggerStyle={customizeMetricsDropdownTriggerStyle({ isPlaceholder: selectedDemographicCategory === null })}
                placeholder={t('Select Demographic Group')}
                inPortal
              />
            </div>

            <div>
              <DropdownLabelComponent>{t('Select Demographic')}</DropdownLabelComponent>
              <RegularDropdownComponent
                onChange={setSelectedDemographicId}
                value={selectedDemographicId}
                options={demographicOptions}
                triggerStyle={customizeMetricsDropdownTriggerStyle({ isPlaceholder: selectedDemographicId === null })}
                placeholder={t('Select Demographic')}
                inPortal
              />
            </div>
          </>
        )}

        <StackedWarningsComponent
          warnings={metricsWarnings}
        />
      </div>

      <ButtonComponent
        text={addSelectionButtonText}
        onClick={addSelectedItem}
        css={submitButtonStyle}
        isDisabled={isSubmitDisabled}
      />
    </div>
  );
};
