import { css } from '@emotion/react';
import {
  forwardRef, useCallback, useEffect,
} from 'react';
import { inputCommonStyle } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import { TextareaComponent } from '~/_shared/baseComponents/textarea/textarea.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useCombinedRefs } from '~/_shared/utils/hooks/useCombinedRefs';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';

const textareaStyle = css({
  overflow: 'hidden',
  resize: 'none',
  background: 'none',
  border: 'none',
  boxSizing: 'border-box',
  padding: '10px 8px',
});

const scrollbarContentStyle = {
  display: 'flex',
  padding: 0,
  margin: 0,
};

const scrollBarStyle = (isReadonly: boolean) => (theme: Theme) => css(
  inputCommonStyle(theme, !isReadonly),
  {
    margin: '4px 0 0',
  }
);

export type TextareaScrollableProps = Readonly<{
  placeholder?: string;
  value: string;
  height: number;
  testid?: string;
  isReadOnly?: boolean;

  onBlur?: () => void;
  onChange: (value: string) => void;
}>;

export const TextareaScrollableComponent = forwardRef<HTMLTextAreaElement, TextareaScrollableProps>((props, ref) => {
  const [textareaRef, setTextareaRef] = useHookWithRefCallback<HTMLTextAreaElement>();
  const setRefs = useCombinedRefs(ref, setTextareaRef);

  const adjustHeight = useCallback(() => {
    if (textareaRef) {
      textareaRef.style.height = 'auto';
      textareaRef.style.height = `${textareaRef.scrollHeight}px`;
    }
  }, [textareaRef]);

  useEffect(() => {
    adjustHeight();
  }, [props.value, adjustHeight]);

  return (
    <ScrollBarComponent
      type={ScrollbarType.Vertical}
      contentStyle={scrollbarContentStyle}
      scrollbarStyle={scrollBarStyle(!!props.isReadOnly)}
      style={{
        height: props.height,
      }}
    >
      <TextareaComponent
        isReadOnly={props.isReadOnly}
        ref={setRefs}
        css={textareaStyle}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        testid={props.testid}
      />
    </ScrollBarComponent>
  );
});
