import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import { CheckboxComponent } from '../../baseComponents/checkbox';
import {
  createSearchMatchingBehaviourDescription,
  SearchMatchingBehaviour,
} from '../../constants/searchMatchingBehaviour.enum';
import {
  type TranslationFnc,
  useTranslation,
} from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

const createMatchingBehaviourItems = (t: TranslationFnc) => Object.values(SearchMatchingBehaviour)
  .map(b => ({ value: b, description: createSearchMatchingBehaviourDescription(t, b) }));

const rootStyle = css({
  fontSize: 14,
  padding: 21,
});

const matchingBehaviourHeaderStyle = css({
  fontSize: 18,
  marginBottom: 16,
  textTransform: 'uppercase',
});

const rowWithCheckboxStyle = css({
  display: 'flex',
});

const checkboxSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginTop: 32,
});

const radiosStyle = css({
  gap: 12,
});

const checkboxStyle = css({
  paddingRight: 8,
});

export type SavePayload = Readonly<{
  matchingBehaviour: SearchMatchingBehaviour;
  shouldMatchExactWords: boolean;
  shouldZoomOnMatches: boolean;
}>;

type SearchSettingsModalProps = Readonly<{
  isOpen: boolean;
  selectedMatchingBehaviourState: SearchMatchingBehaviour;
  shouldMatchExactWordsState: boolean;
  shouldZoomOnMatchesState: boolean;

  onClose: () => void;
  onSave: (params: SavePayload) => void;
}>;

export const SearchSettingsModalComponent: React.FC<SearchSettingsModalProps> = props => {
  const [selectedMatchingBehaviour, setSelectedMatchingBehaviour] = useState<SearchMatchingBehaviour>(props.selectedMatchingBehaviourState);
  const [shouldZoomOnMatches, setShouldZoomOnMatches] = useState<boolean>(props.shouldZoomOnMatchesState);
  const [shouldMatchExactWords, setShouldMatchExactWords] = useState<boolean>(props.shouldMatchExactWordsState);
  const [t] = useTranslation();

  const onDone = () => {
    props.onSave({
      matchingBehaviour: selectedMatchingBehaviour,
      shouldMatchExactWords,
      shouldZoomOnMatches,
    });
    props.onClose();
  };

  return (
    <ModalComponent
      allowedPages={ALL_MAP_RELATED_PAGES}
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Search Settings')}
      contentStyle={rootStyle}
      confirmButton={(
        <ButtonComponent
          text={t('Done')}
          onClick={onDone}
          prefixIcon={faCheck}
        />
      )}
    >
      <div css={matchingBehaviourHeaderStyle}>{t('SELECT SEARCH MATCHING BEHAVIOR')}</div>
      <RadioGroupComponent
        css={radiosStyle}
        items={createMatchingBehaviourItems(t)}
        onValueChange={newValue => setSelectedMatchingBehaviour(Object.values(SearchMatchingBehaviour).find(b => b === newValue) ?? selectedMatchingBehaviour)}
        selectedValue={selectedMatchingBehaviour}
      />

      <div> {/*because of visual issues with scaling (125%) on Windows*/}
        <div css={checkboxSectionStyle}>
          <label css={rowWithCheckboxStyle}>
            <CheckboxComponent
              css={checkboxStyle}
              checkedSetter={() => setShouldZoomOnMatches(!shouldZoomOnMatches)}
              isChecked={shouldZoomOnMatches}
            />
            {t('shouldZoomOnSearchMatches')}
          </label>
          <label css={rowWithCheckboxStyle}>
            <CheckboxComponent
              css={checkboxStyle}
              checkedSetter={() => setShouldMatchExactWords(!shouldMatchExactWords)}
              isChecked={shouldMatchExactWords}
            />
            {t('shouldMatchExactWords')}
          </label>
        </div>
      </div>
    </ModalComponent>
  );
};
