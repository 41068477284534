import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { fromUTCDate12PMToCurrentTimezone12PM } from '~/_shared/utils/date/date.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { RangeType } from '~/_shared/utils/range/range.helpers';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import {
  mapSettingsColumnsFilterRemoveFilterValues,
  mapSettingsColumnsFilterSetAttribute,
  mapSettingsColumnsFilterSetText,
} from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilter.actionCreators';
import { useMapSettingsColumnsFilterSelector } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';
import { getMatchupDataColumnName } from '~/store/matchupData/matchupData.helpers';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { getActiveFilters } from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import {
  getAttributeColumnUniqueAttributes,
  getGroupingColumnSelectedUniqueGroups,
  getNumericalBucketRangeName,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { getSpreadsheetDataForDataType } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { DataType } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  getColumnsFilterDataForDataType,
  getTextFilterLabel,
} from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/filters.helpers';
import { useMapSettingsDataSelector } from '../../map/useMapSettings.hook';
import {
  createMapStatusBarItemFromGroupFilter,
  createMapStatusBarItemFromNumberFilter, createMapStatusBarItemFromTextFilter,
} from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';
import { useColumnsFilterGroupUnselect } from './useColumnsFilterGroupUnselect';

export const useActiveFilterStatusBarItems = () => {
  const mapSettings = useMapSettingsDataSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const matchupData = useMatchupDataSelector();
  const dispatch = useDispatch();
  const { onGroupUnselect } = useColumnsFilterGroupUnselect();
  const [t] = useTranslation();

  const removeFilterValues = useCallback((spreadsheetColumnId: SpreadsheetColumnId) => {
    dispatch(mapSettingsColumnsFilterRemoveFilterValues(spreadsheetColumnId));
  }, [dispatch]);

  const activeFilters = useMemo(() => {
    return getActiveFilters({
      columnsFilter: mapSettings.toolsState.columnsFilter,
      filtering: mapSettings.filtering,
      grouping: mapSettings.grouping,
      excludedDataTypes: [],
      includeActiveGroupingColumns: false,
    });
  }, [mapSettings.filtering, mapSettings.grouping, mapSettings.toolsState.columnsFilter]);

  return useMemo<MapStatusBarItem[]>(() => {
    return activeFilters.reduce<MapStatusBarItem[]>(
      (acc, filter) => {
        const columnName = getMatchupDataColumnName(matchupData, filter);

        if (!spreadsheetData || columnName === null) {
          return acc;
        }

        // GROUP
        if (filter.dataType === DataType.GROUP) {
          const selectedUniqueGroups = getGroupingColumnSelectedUniqueGroups(
            filter.extra,
            columnsFilter,
            spreadsheetData,
            matchupData
          );

          for (const columnUniqueGroup of selectedUniqueGroups) {
            const statusBarItem = createMapStatusBarItemFromGroupFilter({
              columnName, t,
              selectedGroupName: columnUniqueGroup.name,
              onRemove: () => onGroupUnselect(filter.extra, columnUniqueGroup),
              onClick: () => dispatch(openMapTool(MapTool.Filter)),
            });

            acc.push(statusBarItem);
          }
        }

        // NUMBER
        if (filter.dataType === DataType.NUMBER) {
          const values = getColumnsFilterDataForDataType(DataType.NUMBER, columnsFilter, filter);
          const rangeValue: number[] | undefined = values?.values.value;

          const data = getSpreadsheetDataForDataType(DataType.NUMBER, spreadsheetData, filter);
          const min = data?.extra.min ?? 0;
          const max = (data?.extra.max ?? 0);

          const statusBarItem = createMapStatusBarItemFromNumberFilter(
            {
              columnName, t,
              selectedGroupName: getNumericalBucketRangeName(
                { from: rangeValue?.[0] ?? min, to: rangeValue?.[1] ?? max },
                RangeType.Value, min, max),
              onRemove: () => removeFilterValues(filter),
              onClick: () => dispatch(openMapTool(MapTool.Filter)),
            });

          acc.push(statusBarItem);
        }

        // TEXT
        if (filter.dataType === DataType.TEXT) {
          const values = getColumnsFilterDataForDataType(DataType.TEXT, columnsFilter, filter);

          if (!values) {
            return acc;
          }

          for (const value of values.values) {
            const statusBarItem = createMapStatusBarItemFromTextFilter(
              {
                columnName, t,
                filterAction: value.action,
                text: getTextFilterLabel(value),
                onRemove: () => {
                  const newTextValues = values.values.filter(item => item !== value);
                  dispatch(mapSettingsColumnsFilterSetText(filter, newTextValues));
                },
                onClick: () => dispatch(openMapTool(MapTool.Filter)),
              });

            acc.push(statusBarItem);
          }
        }

        // ATTRIBUTE
        if (filter.dataType === DataType.ATTRIBUTE) {
          const values = getColumnsFilterDataForDataType(DataType.ATTRIBUTE, columnsFilter, filter)?.values;
          const data = getSpreadsheetDataForDataType(DataType.ATTRIBUTE, spreadsheetData, filter);

          if (!data || !values) {
            return acc;
          }

          const attributeUniqueGroups = getAttributeColumnUniqueAttributes(
            data.extra.uniqueValues,
            columnName
          );

          for (const uniqueColumn of attributeUniqueGroups) {
            if (values[uniqueColumn.id] === 1) {

              const statusBarItem = createMapStatusBarItemFromGroupFilter(
                {
                  columnName, t,
                  selectedGroupName: uniqueColumn.value,
                  onRemove: () => {
                    const newValues = { ...values };
                    delete newValues[uniqueColumn.id];
                    dispatch(mapSettingsColumnsFilterSetAttribute(filter, newValues));
                  },
                  onClick: () => dispatch(openMapTool(MapTool.Filter)),
                });

              acc.push(statusBarItem);
            }
          }
        }

        // DATE
        if (filter.dataType === DataType.DATE) {
          const data = getSpreadsheetDataForDataType(DataType.DATE, spreadsheetData, filter);
          const values = getColumnsFilterDataForDataType(DataType.DATE, columnsFilter, filter);

          if (!values || !data) {
            return acc;
          }

          const minDate = fromUTCDate12PMToCurrentTimezone12PM(data.extra.min);
          const maxDate = fromUTCDate12PMToCurrentTimezone12PM(data.extra.max);

          if (!minDate || !maxDate) {
            return acc;
          }

          const fromDate = values.values?.[0] ? fromUTCDate12PMToCurrentTimezone12PM(values.values[0]) : minDate;
          const toDate = values.values?.[1] ? fromUTCDate12PMToCurrentTimezone12PM(values.values[1]) : maxDate;
          const fromDateString = fromDate?.toLocaleDateString() ?? '';
          const toDateString = toDate?.toLocaleDateString() ?? '';

          const statusBarItem = createMapStatusBarItemFromNumberFilter({
            columnName, t,
            selectedGroupName: `${fromDateString}-${toDateString}`,
            onRemove: () => removeFilterValues(filter),
            onClick: () => dispatch(openMapTool(MapTool.Filter)),
          });

          acc.push(statusBarItem);
        }

        return acc;
      }, []
    );
  }, [dispatch, columnsFilter, matchupData, spreadsheetData, t, activeFilters, onGroupUnselect, removeFilterValues]);
};
