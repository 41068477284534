import { computeDistanceBetween } from 'spherical-geometry-js';
import type {
  DistanceMatrixResultItem, DistanceMatrixSearchItem,
} from '~/_shared/repositories/travelTime.repository';
import type { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import {
  isDriveTimePolygon, isRadiusProximity, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  createUuid, type Uuid,
} from '~/_shared/utils/createUuid';
import type { TranslationFnc } from '~/_shared/utils/hooks';
import { secondsToHms } from '~/_shared/utils/time/time.helpers';
import {
  notNull, notNullOrUndefined,
} from '~/_shared/utils/typeGuards';
import { convertMetersToUnit } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { createProximityFilterArgumentsFromProximity } from '~/proximity/proximity.helpers';
import type { ExportProximityLimits } from '~/proximityDetails/export/exportProximityLimitResultsSection.component';
import type {
  PerProximitySpreadsheetRowsWithTravelData, ProximityExportFilter,
  SpreadsheetRowWithTravelData,
} from '~/proximityDetails/export/useExportProximityData';
import {
  ProximityLimitInclusionType, ProximityLimitMarkersType,
} from '~/proximityDetails/export/useExportProximityLimitResults';
import { type RadiusFilterRequestArguments } from '~/spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import type { SpreadsheetDataBulkResponse } from '~/spreadsheet/spreadsheet.repository';
import { type SpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import type { LatLngRowData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { type SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';

type ProximityRequest = {
  circles: ReadonlyArray<RadiusFilterRequestArguments>;
  polygons: MultiPolygon[];
};

export const getRequestsFromProximity = (
  proximity: Proximity, latLngLookup: SpreadsheetLatLngRowData, spreadsheetData: SpreadsheetDataData, selectedSpreadsheetRowId: SpreadsheetRowId | null,
): ProximityRequest => {

  const { circles, multiPolygons } = createProximityFilterArgumentsFromProximity(
    proximity,
    latLngLookup,
    selectedSpreadsheetRowId,
    spreadsheetData
  );

  return {
    circles,
    polygons: multiPolygons,
  };
};

export const filterDuplicatedRowsExceptClosestToCenter = (
  perProximityRowsWithTravelData: ReadonlyArray<ReadonlyArray<SpreadsheetRowWithTravelData>>,
) => {
  const closestRows = new Map<CombinedRowId, {
    distance: number | null;
    proximityIndex: number;
  }>();

  perProximityRowsWithTravelData.forEach((rowsOfProximity, index) => {
    rowsOfProximity.forEach((row) => {
      const closestRowDistance = closestRows.get(row.rowId.rowId);

      if (!closestRowDistance) {
        closestRows.set(row.rowId.rowId, {
          distance: row.distanceFromCenter === undefined ? null : row.distanceFromCenter,
          proximityIndex: index,
        });
      }
      else {
        if (row.distanceFromCenter !== undefined && (closestRowDistance.distance === null || row.distanceFromCenter < closestRowDistance.distance)) {
          closestRows.set(row.rowId.rowId, {
            distance: row.distanceFromCenter,
            proximityIndex: index,
          });
        }
      }
    });
  });

  return perProximityRowsWithTravelData
    .map((rowsOfProximity, index) => {
      return rowsOfProximity.filter(row => {
        const closestRow = closestRows.get(row.rowId.rowId);
        if (!closestRow) {
          return false;
        }
        return closestRow.proximityIndex === index;
      });
    });
};

export const applyProximityResultsLimitsToRowsWithTravelData = (
  perProximityRowsWithTravelData: PerProximitySpreadsheetRowsWithTravelData,
  proximitiesFilters: ReadonlyArray<ProximityExportFilter>,
  exportLimits: ExportProximityLimits,
): PerProximitySpreadsheetRowsWithTravelData => {
  perProximityRowsWithTravelData = perProximityRowsWithTravelData.map((rowsForOneProximity, index) => {
    const proximity = proximitiesFilters[index];

    if (proximity) {
      return getRadiusProximityRowsWithinLimit({
        rows: rowsForOneProximity,
        exportLimits,
      });
    }

    return rowsForOneProximity;
  });

  if (exportLimits.isReturnMarkersOnlyOnce) {
    perProximityRowsWithTravelData = filterDuplicatedRowsExceptClosestToCenter(perProximityRowsWithTravelData);
  }

  return perProximityRowsWithTravelData;
};

export const getRadiusProximityRowsWithinLimit = ({ rows, exportLimits }: {
  rows: ReadonlyArray<SpreadsheetRowWithTravelData>;
  exportLimits: ExportProximityLimits;
}): ReadonlyArray<SpreadsheetRowWithTravelData> => {
  if (exportLimits.markersLimitType === ProximityLimitMarkersType.ClosestToCenter) {
    return rows
      .filter(notNull)
      .sort((a, b) => {
        if (a.distanceFromCenter === undefined) {
          return 1;
        }
        if (b.distanceFromCenter === undefined) {
          return -1;
        }
        return a.distanceFromCenter - b.distanceFromCenter;
      })
      .slice(0, exportLimits.markersCount);
  }

  return rows;
};

export const getRowsPerSpreadsheetResponseForInclusionType = (
  allSpreadsheetRows: SpreadsheetLatLngRowData,
  spreadsheetId: number,
  spreadsheetData: ReadonlyArray<SpreadsheetDataBulkResponse>,
  markersInclusionType: ProximityLimitInclusionType,
) => {
  const allSpreadsheetRowsMap = new Map<CombinedRowId, LatLngRowData>();
  const allSpreadsheetRowIds = new Set<CombinedRowId>();

  for (const spreadsheetRow of allSpreadsheetRows.getSpreadsheet(spreadsheetId) ?? []) {
    allSpreadsheetRowsMap.set(spreadsheetRow.rowId, spreadsheetRow);
    allSpreadsheetRowIds.add(spreadsheetRow.rowId);
  }

  return spreadsheetData
    .map((resultsForOneProximity) => {
      // create a map of allSpreadsheetData but only with spreadsheetId that matches the argument
      const rowsInsideProximity = resultsForOneProximity.result.filtered_rows ?? [];

      let resultRowIds: Set<CombinedRowId>;

      // INSIDE
      if (markersInclusionType === ProximityLimitInclusionType.Inside) {
        resultRowIds = new Set(rowsInsideProximity);
      }
      // OUTSIDE
      else {
        resultRowIds = new Set(allSpreadsheetRowIds);
        for (const rowInsideId of rowsInsideProximity) {
          resultRowIds.delete(rowInsideId);
        }
      }

      const resultRows: ReadonlyArray<LatLngRowData> = Array.from<CombinedRowId>(resultRowIds)
        .map((rowId) => allSpreadsheetRowsMap.get(rowId) ?? null)
        .filter(notNull);

      return resultRows;
    });
};

export const proximityExportCalculateDistanceFromCenterForRows = (
  resultRows: ReadonlyArray<ReadonlyArray<LatLngRowData>>,
  proximitiesFilters: ReadonlyArray<ProximityExportFilter>,
  spreadsheetId: number,
  unitSystem: UnitSystem,
): PerProximitySpreadsheetRowsWithTravelData => {
  return resultRows.map((resultsForOneProximity, index) => {
    const proximity = proximitiesFilters[index];

    return resultsForOneProximity.map(rowLatLng => {
      const centerIfCircle = proximity?.circle && isRadiusProximity(proximity.proximity) ? proximity.circle.center : null;
      const centerIfPolygon = proximity && isDriveTimePolygon(proximity.proximity)
        ? { lat: proximity.proximity.data.lat, lng: proximity.proximity.data.lng }
        : null;
      const center = proximity && isRadiusProximity(proximity.proximity) ? centerIfCircle : centerIfPolygon;

      const distanceFromCenter = center && rowLatLng
        ? convertMetersToUnit(computeDistanceBetween(center, rowLatLng), unitSystem)
        : undefined;
      const distanceColumnValue = distanceFromCenter === undefined ? undefined : distanceFromCenter.toString();

      return {
        rowId: { spreadsheetId, rowId: rowLatLng.rowId },
        distanceFromCenter,
        distanceColumnValue,
      };
    });
  });
};

export const createFrom1ToNSearchItemsFromProximitySpreadsheetData = (
  proximityExportFilter: ReadonlyArray<ProximityExportFilter>,
  proximitySpreadsheetData: ReadonlyArray<ReadonlyArray<LatLngRowData>>,
) => proximitySpreadsheetData.reduce((accum, rows, index) => {
  const proximity = proximityExportFilter[index];
  if (proximity && rows.length) {
    const id = createUuid();
    const fromRadius = isRadiusProximity(proximity.proximity) ? proximity.circle?.center : null;
    const fromPolygon = isDriveTimePolygon(proximity.proximity)
      ? { lat: proximity.proximity.data.lat, lng: proximity.proximity.data.lng }
      : null;
    const from = isRadiusProximity(proximity.proximity) ? fromRadius : fromPolygon;
    if (from) {
      accum.searchIdsPerProximityFilter.push(id);
      accum.searchItems.push({
        id,
        from,
        to: rows,
      });
    }
  }
  else {
    accum.searchIdsPerProximityFilter.push(null);
  }
  return accum;
}, {
  searchItems: [] as Array<DistanceMatrixSearchItem>,
  searchIdsPerProximityFilter: [] as Array<Uuid | null>,
});

export const proximityExportAddDistanceMatrixTravelDataToRows = ({
  proximityExportFilter, searchIdsPerProximityFilter, proximitySpreadsheetData, distanceMatrixResults, unitSystem, t,
}: {
  proximityExportFilter: ReadonlyArray<ProximityExportFilter>;
  proximitySpreadsheetData: ReadonlyArray<ReadonlyArray<LatLngRowData>>;
  searchIdsPerProximityFilter: ReadonlyArray<Uuid | null>;
  distanceMatrixResults: ReadonlyArray<DistanceMatrixResultItem>;
  unitSystem: UnitSystem;
  t: TranslationFnc;
}): PerProximitySpreadsheetRowsWithTravelData => (
  proximitySpreadsheetData.map((resultsForOneProximity, proximityIndex) => {
    const proximity = proximityExportFilter[proximityIndex];

    if (!proximity) {
      return resultsForOneProximity.map(rowLatLng => ({
        rowId: { spreadsheetId: rowLatLng.spreadsheetId, rowId: rowLatLng.rowId },
      }));
    }

    const distanceMatrixToResultsForProximity = distanceMatrixResults.find(
      item => item.id === searchIdsPerProximityFilter[proximityIndex]
    )?.to ?? [];

    return resultsForOneProximity.map((rowLatLng, index) => {
      let distanceFromCenter: number | undefined = undefined;
      let distanceColumnValue: string | undefined = undefined;
      let travelTimeColumnValue: string | undefined = undefined;

      const result = distanceMatrixToResultsForProximity[index];

      if (result) {
        if (notNullOrUndefined(result.distance)) {
          const distance = convertMetersToUnit(result.distance, unitSystem);
          distanceFromCenter = distance;
          distanceColumnValue = (isNaN(distance) || distance === 0) ? '-' : distance.toFixed(2);
        }
        else if (result.distance === null) {
          distanceColumnValue = t('Directions Not Found or Over 3 hours');
        }
        if (result.travel_time !== null && result.travel_time !== 0) {
          travelTimeColumnValue = secondsToHms(result.travel_time, t);
        }
      }
      else {
        distanceColumnValue = '-';
      }

      return {
        rowId: { spreadsheetId: rowLatLng.spreadsheetId, rowId: rowLatLng.rowId },
        distanceFromCenter,
        distanceColumnValue,
        travelTimeColumnValue,
      };
    });
  })
);
