import { css } from '@emotion/react';
import { itemWidth } from '~/_shared/constants/mainMenuUI.constants';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';

const BORDER_WIDTH = 1;

const commonMainStyles = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.secondary,
  borderRight: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  height: '100%',
  position: 'absolute',
  zIndex: 500,
});

export const movableMainStyles = (props: ThemeProps<{
  availableHeight: number;
  isDisplayedAsColumns: boolean;
  isBackground: boolean;
  itemsCount: number;
}>) => css(
  commonMainStyles(props.theme), {
    backgroundColor: props.isBackground ? props.theme.backgroundColors.secondary : 'transparent',
    borderRight: props.isBackground ? `${BORDER_WIDTH}px solid ${props.theme.borderColors.primary}` : 'none',
    columnGap: BORDER_WIDTH,
    columns: props.isDisplayedAsColumns ? `${itemWidth}px` : 'none',
    direction: 'ltr',
    right: 0,
  });

export const scrollableMainStyles = ({ theme }: ThemeProps) => css(commonMainStyles(theme), {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

export const upperAndLowerPartStyles = (props: {flexShrink: number}) => css({
  flexDirection: 'column',
  flexShrink: props.flexShrink,
  width: itemWidth,
});

export const movablePartStyles = ({ theme, isFolded }: ThemeProps<{isFolded: boolean}>) => css({
  border: `solid ${theme.borderColors.primary}`,
  borderWidth: isFolded ? '0 1px 1px 0' : 0,
  borderRadius: 0,
  breakInside: 'avoid',
  height: itemWidth,
  width: '100%',
});

export const placeholderStyles = css({
  height: '100%',
  width: '100%',
});
