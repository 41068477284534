import { css } from '@emotion/react';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButtonWithText.component';
import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { togglePresence } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { type HeatMapItem } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { type HeatmapToolStateData } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.state';
import { MapToolWrapperComponent } from '../../sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';
import { createHeatMapFromForm } from '../heatMap.factory';
import { HeatMapListingComponent } from '../listing/heatMapListing.component';
import { type HeatMapPanelSubmitItem } from './form/heatMapPanelForm.component';
import { HeatMapPanelFormContainer } from './form/heatMapPanelForm.container';
import { getHeatMapPresetColor } from './heatMapPanel.helpers';

type HeatmapPanelProps = Readonly<{
  items: ReadonlyArray<HeatMapItem>;
  columns: ReadonlyArray<SpreadsheetColumn>;
  onHeatMapItemAdd: (heatmap: HeatMap) => void;
  onHeatMapItemRemove: (heatmap: HeatMap) => void;
  updateHeatMapItem: (item: HeatMap) => void;
  resetHeatMapItems: () => void;
  updateHeatMapItemData: (heatmapId: string, data: Partial<HeatmapToolStateData>) => void;
  updateHeatMapVisibility: (heatmapId: string, visibility: boolean) => void;
}>;

const contentStyle = css({
  padding: '0 16px 25px',
});

export const HeatMapPanelComponent: FC<HeatmapPanelProps> = (props) => {
  const [selectedItemsIds, setSelectedItemsIds] = useState<ReadonlyArray<string>>([]);
  const [t] = useTranslation();

  const onAddHeatMapItem = (item: HeatMapPanelSubmitItem) => {
    const itemColors = getHeatMapPresetColor(props.items.map(item => item.heatmap.singleColor));
    const request = createHeatMapFromForm(item, itemColors.dense, itemColors.medium, itemColors.light, itemColors.single);
    props.onHeatMapItemAdd(request);
  };

  const toggleItemSelection = (itemId: string) =>
    setSelectedItemsIds(togglePresence(selectedItemsIds, itemId));

  return (
    <MapToolWrapperComponent
      footer={(
        <RoundButtonWithTextComponent
          buttonStyle={RoundButtonStyle.Primary}
          icon={faUndo}
          onClick={props.resetHeatMapItems}
          text={t('Reset')}
        />
      )}
    >
      <div css={contentStyle}>
        <HeatMapPanelFormContainer onSubmit={onAddHeatMapItem} />
      </div>

      <HeatMapListingComponent
        items={props.items}
        onItemToggle={toggleItemSelection}
        selectedItemsIds={selectedItemsIds}
        onHeatMapItemRemove={props.onHeatMapItemRemove}
        updateHeatMapItem={props.updateHeatMapItem}
        updateHeatMapItemData={props.updateHeatMapItemData}
        updateHeatMapVisibility={props.updateHeatMapVisibility}
        columns={props.columns}
      />
    </MapToolWrapperComponent>
  );
};
