import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { frontendStateStreetViewOff } from '../../../../store/frontendState/mapTools/streetView/streetView.actionCreators';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideCloseStreetViewMenuItemContainer: FC = () => {
  const [t] = useTranslation();

  const streetView = useSelector(state => state.frontendState.mapTools.streetView);

  const dispatch = useDispatch();
  const closeStreetView = () => dispatch(frontendStateStreetViewOff());

  return (
    <RightSideMapToolsMenuItem
      icon={faXmark}
      active={streetView.active}
      onClick={closeStreetView}
      tooltip={t('Close Street View')}
    />
  );
};
