import { css } from '@emotion/react';
import {
  Breakpoint, mq,
} from '~/_shared/style.mixins';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { mapListingBreakpoints } from '~/map/listing/mapListing.styles';

export const tableRowStyle = ({ theme, isSnapshot }: ThemeProps<{ isSnapshot?: boolean }>) => css({
  height: isSnapshot ? 30 : 36,
  background: isSnapshot ? theme.backgroundColors.primary : theme.backgroundColors.secondary,
  borderBottom: `1px solid ${theme.borderColors.primary}`,

  '&:hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

export const tableDataStyle = css({
  padding: '0 10px',
});

export const snapshotTableDataStyle = css({
  fontSize: 16,
  fontWeight: 600,
  paddingLeft: 50,
  maxWidth: 200,
});

export const mapRowLinkStyle = ({ theme, isActive }: ThemeProps<{ isActive: boolean}>) => css({
  color: isActive ? theme.textColors.success : theme.textColors.primary,
  fontSize: 16,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',

  '&:hover': {
    color: isActive ? theme.textColors.successOnHover : undefined,
  },
});

export const linkIconStyle = css({
  marginRight: 15,
});

export const mapRowNameStyle = ({ isSnapshot, rowWidth }: { isSnapshot: boolean; rowWidth: number }) => {
  let width = isSnapshot ? 130 : 160;
  if (rowWidth > mapListingBreakpoints[Breakpoint.xLarge]) {
    width = isSnapshot ? 250 : 280;
  }
  else if (rowWidth > mapListingBreakpoints[Breakpoint.mid]) {
    width = isSnapshot ? 200 : 250;
  }
  else if (rowWidth > mapListingBreakpoints[Breakpoint.small]) {
    width = isSnapshot ? 150 : 200;
  }
  return css({
    display: 'flex',
    alignItems: 'center',
    width,
  });
};

export const snapshotsCountContainerStyle = css({
  textAlign: 'center',
});

export const snapshotsCountStyle = ({ theme }: ThemeProps) => css({
  color: theme.buttonColors.primaryText,
  background: theme.buttonColors.primaryBackground,
  height: 24,
  lineHeight: '24px',
  width: 40,
  borderRadius: 12,
  margin: '0 auto',
  textAlign: 'center',
  fontSize: 14,
  display: 'block',
});

export const snapshotsUncollapseArrowStyle = css({
  width: 30,
  paddingRight: 15,
});

export const mapRowValueStyle = css({
  fontSize: 14,
});

export const toggleStyle = ({ theme }: ThemeProps) => css({
  border: 'none',
  background: 'none',
  fontSize: 16,
  marginLeft: 20,
  color: theme.iconColors.contrast,

  '&:hover': {
    color: theme.iconColors.focused,
  },
  [mq(Breakpoint.large)]: {
    marginLeft: 30,
  },
});

export const toggleIconStyle = ({ isToggled }: { isToggled: boolean }) => css({
  transition: 'transform .2s',
  transform: isToggled ? undefined : 'rotate(180deg)',
});

export const checkboxWrapperStyles = css({
  margin: '0 15px 0 5px',
});

export const mapSelectCheckboxStyles = ({ theme, isChecked }: ThemeProps<{ isChecked: boolean }>) => css({
  width: 20,
  height: 20,
  border: '1px solid ' + (isChecked
    ? theme.checkboxColors.checkedFill : theme.checkboxColors.uncheckedBorder),
});
