import { useCallback } from 'react';
import { ModalType } from '../../../../../../modal/modalType.enum';
import { useModal } from '../../../../../../modal/useModal.hook';
import { useIsBoundaryAiGenerated } from '../../../../../../store/boundaryGroups/boundaryGroups.selectors';
import { useIsWMSEnabledSelector } from '../../../../../../store/userData/userData.selectors';

export type WmsBlockerFn = (functionToBlock: (() => void)) => () => void;

export const useWmsFeatureBlocker = (boundaryGroupId: number) => {
  const isBoundaryAiGenerated = useIsBoundaryAiGenerated(boundaryGroupId);
  const isWMSEnabled = useIsWMSEnabledSelector();
  const { openModal } = useModal(ModalType.WMSUpgradeSubscription);

  const boundaryIsUnderWmsTrial = isBoundaryAiGenerated && !isWMSEnabled;

  const blockWhenWmsTrial: WmsBlockerFn = useCallback((functionToBlock: () => void) => {
    if (boundaryIsUnderWmsTrial) {
      return () => openModal();
    }
    else {
      return () => functionToBlock();
    }
  }, [boundaryIsUnderWmsTrial, openModal]);

  return {
    boundaryIsUnderWmsTrial,
    blockWhenWmsTrial,
  };
};
