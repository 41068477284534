import { SuffixWrapper } from './suffixText.styles';

export type SuffixTextProps = {
  children: string;
};

export const SuffixText = (props: SuffixTextProps) => {
  return (
    <SuffixWrapper>
      {props.children}
    </SuffixWrapper>
  );
};
