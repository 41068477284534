import { css } from '@emotion/react';
import { ExportFileButtonContainer } from '~/_shared/components/exportFileButton/exportFileButton.container';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTheme } from '../../themes/theme.hooks';

export const LOCATION_DESCRIPTION_FOOTER_HEIGHT = 60;

const footerStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.quinary,
  borderTop: '1px solid ' + theme.lineColors.basePrimary,
  bottom: 0,
  boxSizing: 'border-box',
  display: 'flex',
  height: LOCATION_DESCRIPTION_FOOTER_HEIGHT,
  justifyContent: 'flex-end',
  padding: '0 10px 0 10px',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
});

type LocationListingHeaderProps = Readonly<{
  onPrintClick?: () => void;
  onExportFileClick: () => void;
}>;

export const LocationListingFooterComponent: React.FC<LocationListingHeaderProps> = props => {
  const theme = useTheme();

  return (
    <div css={footerStyle({ theme })} >
      <ExportFileButtonContainer
        onExportFileClick={props.onExportFileClick}
      />
    </div>
  );
};
