import { type FileUrlsAction } from './fileUrls.action';
import {
  FILE_URLS_UPDATE, USER_FILES_DELETED,
} from './fileUrls.actionTypes';
import {
  type FileUrl, type FileUrlsState,
} from './fileUrls.state';

const fileUrlsInitialState: FileUrlsState = {
  files: new Map<number, FileUrl>(),
};

export const fileUrlsReducer = (
  state = fileUrlsInitialState,
  action: FileUrlsAction
): FileUrlsState => {
  switch (action.type) {
    case FILE_URLS_UPDATE: {
      return {
        ...state,
        files: action.payload.fileUrlsMap,
      };
    }

    case USER_FILES_DELETED: {
      const clonedFiles = new Map(state.files);

      action.payload.fileIds.forEach(fileId => clonedFiles.delete(fileId));

      return {
        ...state,
        files: clonedFiles,
      };
    }

    default:
      return state;
  }
};
