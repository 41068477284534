import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';

const groupButtonWrapperStyle = css({
  display: 'flex',
});

const IconWrapper = styled.div({
  fontSize: 18,
  left: 0,
  position: 'absolute',
  top: 8,
  transition: 'top .2s',
  width: '100%',
});

const TextWrapper = styled.div({
  bottom: 0,
  boxSizing: 'border-box',
  fontSize: 10,
  left: 0,
  opacity: 0,
  position: 'absolute',
  textAlign: 'center',
  transition: 'bottom .2s, opacity .2s',
  width: '100%',
});

const FIRST_ITEM_CSS_CLASS = 'group-button--first-item';
const LAST_ITEM_CSS_CLASS = 'group-button--last-item';
const BASE_Z_INDEX = 0;
const OUTER_RADIUS = 16;

export const colorStyle = (props?: { isSelected?: boolean; isDisabled?: boolean}) =>
  (theme: Theme) => css({
    background: props?.isSelected ? theme.backgroundColors.quaternary : theme.backgroundColors.primary,
    boxShadow: 'none',
    border: `2px solid ${props?.isSelected ? theme.borderColors.primaryFocused : theme.borderColors.primary}`,
    color: props?.isSelected ? theme.textColors.quaternary : theme.textColors.primary,
    opacity: props?.isDisabled ? .5 : 1,
    '&:hover': props?.isDisabled ? {} : {
      color: theme.textColors.primary,
      background: theme.backgroundColors.highlight,
      borderColor: theme.borderColors.activeItem,
    },
    '&:active': {
      boxShadow: `inset 0 0 6px 2px ${theme.borderColors.primaryFocused}`,
    },
  });

const buttonStyle = (
  { isDisabled, theme, isSelected }: ThemeProps<{ isDisabled?: boolean; isSelected?: boolean }>
) => {
  const showTextAndIconStyle = {
    [`${IconWrapper}`]: {
      fontSize: 14,
      top: 4,
    },
    [`${TextWrapper}`]: {
      bottom: 2,
      opacity: 1,
    },
  };

  return css(colorStyle({ isSelected, isDisabled })(theme), {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: 18,
    height: 38,
    marginRight: -2,
    padding: 2,
    position: 'relative',
    transition: 'color .2s, background .2s, boxShadow .2s',
    width: 44,
    zIndex: isSelected ? BASE_Z_INDEX + 2 : BASE_Z_INDEX,
    [`&.${FIRST_ITEM_CSS_CLASS}`]: {
      borderRadius: `${OUTER_RADIUS}px 0 0 ${OUTER_RADIUS}px`,
    },
    [`&.${LAST_ITEM_CSS_CLASS}`]: {
      borderRadius: `0 ${OUTER_RADIUS}px ${OUTER_RADIUS}px 0`,
    },
    '&:hover': {
      zIndex: BASE_Z_INDEX + (isDisabled ? 0 : 1),
      ...(isDisabled
        ? {}
        : { ...showTextAndIconStyle }),
    },
  });
};

export type GroupButtonItem<T> = Readonly<{
  icon: IconProp;
  id: T;
  disabledMessage: string | null;
  text: string;
  onClick: () => void;
  testid?: string;
}>;

 type GroupButtonProps<T> = {
   buttonItems: ReadonlyArray<GroupButtonItem<T>>;
   className?: string;
   selectedButtonItemId: T;
 };

export const GroupButtonComponent = <T extends string | number>(props: GroupButtonProps<T>) => {
  const theme = useTheme();

  return (
    <div css={groupButtonWrapperStyle}>
      {props.buttonItems.map((item, i) => {

        const buttonClassname = i === 0
          ? FIRST_ITEM_CSS_CLASS
          : i === props.buttonItems.length - 1 ? LAST_ITEM_CSS_CLASS : '';

        return (
          <TooltipDeprComponent
            key={item.id}
            tooltipContent={item.disabledMessage}
          >
            <button
              css={buttonStyle({
                isDisabled: !!item.disabledMessage,
                isSelected: props.selectedButtonItemId === item.id,
                theme,
              })}
              onClick={item.disabledMessage ? undefined : item.onClick} // we cannot use disabled attribute as it prevents tooltips to work properly on such a button
              className={`${props.className || ''} ${buttonClassname}`}
              data-testid={item.testid}
            >
              <IconWrapper>
                <FontAwesomeIcon icon={item.icon} />
              </IconWrapper>
              <TextWrapper>
                {item.text}
              </TextWrapper>
            </button>
          </TooltipDeprComponent>
        );
      })}
    </div>
  );
};
