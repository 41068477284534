import { type SerializedStyles } from '@emotion/react';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback,
} from 'react';
import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useIsImageExportInProgressSelector } from '~/store/frontendState/processing/processing.selectors';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideExportImageMenuItem: FC = () => {
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.ExportImage);
  const isImageExportLoading = useIsImageExportInProgressSelector();
  const theme = useTheme();

  const renderOverlay = useCallback((style: SerializedStyles) => (
    <OverlayLottieAnimationComponent
      css={style}
      type={LottieAnimationTypes.UploadingImage}
      segment={LottieAnimations.UploadingImage.segments.visor}
      size={26}
      autoplay
      loop
      bothDirections
      colors={{
        visor: theme.iconColors.contrast,
        watermark: theme.iconColors.contrast,
      }}
    />
  ), [theme.iconColors.contrast]);

  return (
    <RightSideMapToolsMenuItem
      icon={faCamera}
      active={isImageExportLoading}
      onClick={() => openModal()}
      tooltip={t('Export Map Image')}
      isLoading={isImageExportLoading}
      renderOverlay={renderOverlay}
    />
  );
};
