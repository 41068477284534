import { css } from '@emotion/react';
import {
  faCheck, faUndo,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTranslation } from '../../../_shared/utils/hooks';

type NumericalGroupSettingsModalProps = Readonly<{
  isOpen: boolean;
  isSubmitDisabled: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  onRestoreClick: () => void;
  onSubmitClick: () => void;
}>;

const modalStyle = css({
  maxWidth: 611,
});

const modalContentStyle = css({
  padding: 0,
});

export const NumericalGroupSettingsModalComponent: FC<NumericalGroupSettingsModalProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      css={modalStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={t('Group By Numerical or % Value Ranges')}
      contentStyle={modalContentStyle}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          text={t('Group Now')}
          onClick={props.onSubmitClick}
          isDisabled={props.isSubmitDisabled}
        />
      )}
      leftFooterContent={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          prefixIcon={faUndo}
          text={t('Restore Defaults')}
          onClick={props.onRestoreClick}
        />
      )}
    >
      {props.children}
    </ModalComponent>
  );
};
