import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const wrapperStyle = ({ theme, isLast }: ThemeProps<{isLast: boolean}>) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: `0 0 ${isLast ? '4px 4px' : '0 0'}`,
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
  boxSizing: 'border-box',
  fontSize: 10,
  height: 44,
  paddingRight: 10,
  width: '100%',

  '&:hover': {
    backgroundColor: theme.buttonColors.tertiaryBorder,
  },
});

const titleStyle = css({
  marginLeft: 10,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

type SettingsAccordionDropDownOptionComponentProps = Readonly<{
  isLast: boolean;
  title?: string;
  children?: React.ReactNode;
  onChange: () => void;
}>;

export const SettingsAccordionDropDownOptionComponent: FC<SettingsAccordionDropDownOptionComponentProps> = props => {
  const theme = useTheme();

  const { isLast, onChange } = props;

  return (
    <div
      css={wrapperStyle({ theme, isLast })}
      onClick={onChange}
    >
      {props.children}
      {props.title && (
        <div css={titleStyle}>{props.title}</div>
      )}
    </div>
  );
};
