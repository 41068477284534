import { type ColumnsMatchUp } from '~/_shared/types/columnRole.enum';
import { isString } from '~/_shared/utils/typeGuards';

const isColumnSelected = (data: [string | null, boolean]): boolean => {
  return isString(data[0]);
};

export const isMatchUpLocationSelected = (matchup: ColumnsMatchUp): boolean => {
  if (
    isColumnSelected(matchup.address) ||
    isColumnSelected(matchup.city) ||
    isColumnSelected(matchup.state) ||
    isColumnSelected(matchup.zip) ||
    isColumnSelected(matchup.country) ||
    (isColumnSelected(matchup.latitude) && isColumnSelected(matchup.longitude))
  ) {
    return true;
  }

  return false;
};
