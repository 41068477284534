import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useGooglePickerApi } from '~/_shared/components/google-api/useGooglePickerApi.hook';
import { useRefreshGoogleOAuthAccessToken } from '~/_shared/hooks/google/useRefreshGoogleOAuthAccessToken.hook';
import { useGoogleApiOauthUserDataSelector } from '~/store/userData/userData.selectors';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  convertGoogleSpreadsheetInfoResponse,
  getGoogleSpreadsheetInfo, type GoogleSpreadsheetInfo,
} from './googleSheet.repository';

type SelectGoogleSpreadsheetProps = Readonly<{
  googleAccountId?: string;
  onGoogleAuthError: () => void;
  onClose?: () => void;
}>;

export const useSelectGoogleSpreadsheet = (props: SelectGoogleSpreadsheetProps) => {
  const { onGoogleAuthError, googleAccountId: selectedGoogleAccountId, onClose } = props;

  const [t] = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState<string>('');
  const [googleSpreadsheetInfo, setGoogleSpreadsheetInfo] = useState<GoogleSpreadsheetInfo | null>(null);

  const {
    refreshAccessToken, token: googleOAuthToken, isLoading: isLoadingGoogleAuthToken, error: googleOAuthTokenError,
  } = useRefreshGoogleOAuthAccessToken();

  const googleUserInfo = useGoogleApiOauthUserDataSelector();

  const googlePickerApiProps = useMemo(() => ({
    onGeneralError: onGoogleAuthError,
    onGoogleUserPermissionsScopeError: onGoogleAuthError,
    onCanceledByUser: onClose,
  }), [onClose, onGoogleAuthError]);

  const { requestShowPickerAndGetSelectedFile } = useGooglePickerApi(googlePickerApiProps);

  const onSelectSpreadsheet = useCallback((spreadsheetId: string) => {
    setSelectedSpreadsheetId(spreadsheetId);
    setGoogleSpreadsheetInfo(null);
    setIsLoading(true);

    if (selectedGoogleAccountId) {
      getGoogleSpreadsheetInfo(selectedGoogleAccountId, spreadsheetId).then(responseInfo => {
        setGoogleSpreadsheetInfo(convertGoogleSpreadsheetInfoResponse(responseInfo.data));
        setIsLoading(false);
      }, (error: any) => {
        setErrorMessage(error.error);
        setSelectedSpreadsheetId('');
        setIsLoading(false);
      });
    }
    else {
      setErrorMessage(t('Not logged into Google account'));
      setIsLoading(false);
    }
  }, [selectedGoogleAccountId, t]);

  useEffect(() => {
    if (googleOAuthTokenError) {
      onGoogleAuthError();
    }
  }, [googleOAuthTokenError, onGoogleAuthError]);

  useEffect(() => {
    if (selectedGoogleAccountId && !googleOAuthTokenError && !googleOAuthToken && !isLoadingGoogleAuthToken) {
      refreshAccessToken(selectedGoogleAccountId);
    }
  }, [googleOAuthTokenError, googleOAuthToken, refreshAccessToken, selectedGoogleAccountId, isLoadingGoogleAuthToken]);

  useEffect(() => {
    if (!googleSpreadsheetInfo && selectedGoogleAccountId && !googleOAuthTokenError && googleOAuthToken) {
      requestShowPickerAndGetSelectedFile(googleOAuthToken, googleUserInfo, onSelectSpreadsheet);
    }
  }, [googleOAuthTokenError, googleOAuthToken, googleUserInfo, requestShowPickerAndGetSelectedFile,
    onSelectSpreadsheet, selectedGoogleAccountId, googleSpreadsheetInfo]);

  return useMemo(() => ({
    errorMessage,
    isLoading,
    onSelectSpreadsheet,
    selectedGoogleSpreadsheetInfo: googleSpreadsheetInfo,
    selectedSpreadsheetId,
  }), [errorMessage, googleSpreadsheetInfo, isLoading, onSelectSpreadsheet, selectedSpreadsheetId]);
};
