import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ACCORDION_HEADER_SIZE_LARGE } from '~/_shared/baseComponents/accordion';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { BASIC_PANEL_WIDTH } from '~/sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import { useTheme } from '../../themes/theme.hooks';

export const LOCATION_DESCRIPTION_HEADER_HEIGHT = ACCORDION_HEADER_SIZE_LARGE;

const headerStyle = ({ theme, width }: ThemeProps<{ width: number }>) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondaryAlternative,
  borderBottom: '1px solid ' + theme.lineColors.basePrimary,
  boxSizing: 'border-box',
  fontSize: '20px',
  fontWeight: 500,
  height: LOCATION_DESCRIPTION_HEADER_HEIGHT,
  minWidth: `${width}px`,
  padding: '8px 8px 8px 0',
});

const headerIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  margin: '0 8px',
  '&:hover': {
    color: theme.iconColors.contrast,
  },
});

const headerDescriptionStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  marginLeft: '15px',
  textTransform: 'uppercase',
});

type LocationListingHeaderProps = Readonly<{
  onCloseClick: () => void;
  title: string;
}>;

export const LocationListingHeaderComponent: React.FC<LocationListingHeaderProps> = props => {
  const theme = useTheme();
  return (
    <div css={headerStyle({ theme, width: BASIC_PANEL_WIDTH })} >
      <span css={headerDescriptionStyle} >
        {props.title}
      </span>
      <FontAwesomeIcon
        css={headerIconStyle({ theme })}
        icon={faXmark}
        onClick={props.onCloseClick}
      />
    </div>
  );
};
