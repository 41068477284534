import { useState } from 'react';
import { type ApiError } from '../../_shared/utils/api/apiError.helpers';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { createBlankMap } from './createNewMap.helpers';

export const useCreateBlankMap = (clientId: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMountedRef = useIsComponentMountedRef();

  const resetState = () => {
    setIsLoading(false);
    setErrorMessage('');
  };

  const onCreateBlankMap = (mapName: string, mapDescription: string, onSuccess: (mapId: number) => void) => {
    if (clientId === null) {
      return;
    }

    setIsLoading(true);
    createBlankMap(clientId, mapName, mapDescription)
      .then((response) => {
        onSuccess(response.data.id);
      })
      .catch((e: ApiError) => {
        const message = e?.errors?.maps_limit?.[0] ?? e.message;
        if (isMountedRef.current) {
          setErrorMessage(message);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    errorMessage,
    resetState,
    isLoading,
    createBlankMap: onCreateBlankMap,
  };
};
