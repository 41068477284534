import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { copy } from '~/_shared/utils/collections/collections';
import { type BoundarySelectAction } from './boundarySelect.action';
import {
  BOUNDARY_SELECT_ADD_BOUNDARY, BOUNDARY_SELECT_EDIT_ACTIVATE,
  BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CLOSE_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL,
  BOUNDARY_SELECT_EDIT_DEACTIVATE,
  BOUNDARY_SELECT_EDIT_RESET, BOUNDARY_SELECT_HOVER_BOUNDARY,
  BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL,
  BOUNDARY_SELECT_REMOVE_BOUNDARY, BOUNDARY_SELECT_SET_LASSO_IS_LOADING,
  BOUNDARY_SELECT_SET_LASSO_OVERLAP,
  BOUNDARY_SELECT_SET_SELECT_TYPE,
  BOUNDARY_SELECT_STOP_BOUNDARY_HOVER,
} from './boundarySelect.actionTypes';
import {
  type BoundarySelectInactiveState, BoundarySelectMode, type BoundarySelectState, BoundarySelectType,
  isBoundarySelectActive, SelectLassoOverlap,
} from './boundarySelect.state';

export const defaultBoundarySelectOptions = {
  selectType: BoundarySelectType.Click,
  selectLassoOverlap: SelectLassoOverlap.Partial,
  isLassoSelectLoading: false,
  hoveredBoundaryId: null,
  allowMultipleSelection: true,
} as const;

const initialState: BoundarySelectInactiveState = {
  isModalOpen: false,
  isActive: false,
  selectBoundaryGroupId: null,
  selectionModeData: null,
  selectedBoundaryIds: null,
  ...defaultBoundarySelectOptions,
};

export const boundarySelectReducer = (state: BoundarySelectState = initialState, action: BoundarySelectAction): BoundarySelectState => {
  switch (action.type) {
    case BOUNDARY_SELECT_EDIT_CLOSE_MODAL: {
      return initialState;
    }

    case BOUNDARY_SELECT_EDIT_DEACTIVATE: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        isActive: false,
        selectedBoundaryIds: new Set(),
      };
    }

    case BOUNDARY_SELECT_EDIT_ACTIVATE: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        isActive: true,
      };
    }

    case BOUNDARY_SELECT_EDIT_RESET: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        isActive: false,
        selectType: state.selectType === BoundarySelectType.HoverActive ? BoundarySelectType.HoverIdle : state.selectType,
        selectedBoundaryIds: new Set(),
      };
    }

    case BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_SUBMIT_OPEN_MODAL: {
      return {
        ...state,
        ...defaultBoundarySelectOptions,
        isModalOpen: true,
        isActive: false,
        allowMultipleSelection: false,
        selectBoundaryGroupId: action.payload.selectBoundaryGroupId,
        selectionModeData: {
          type: BoundarySelectMode.EditCustomBoundarySubmit,
          boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
          configuration: {
            ...action.payload.configuration,
          },
        },
        selectedBoundaryIds: new Set(),
      };
    }

    case BOUNDARY_SELECT_EDIT_BOUNDARY_TERRITORY_OPEN_MODAL: {
      return {
        ...state,
        ...defaultBoundarySelectOptions,
        isModalOpen: true,
        isActive: false,
        selectBoundaryGroupId: action.payload.selectBoundaryGroupId,
        selectionModeData: {
          type: BoundarySelectMode.EditBoundaryTerritory,
          boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
        },
        selectedBoundaryIds: new Set(),
      };
    }

    case BOUNDARY_SELECT_EDIT_CUSTOM_BOUNDARY_OPEN_MODAL: {
      return {
        ...state,
        ...defaultBoundarySelectOptions,
        isModalOpen: true,
        isActive: false,
        selectBoundaryGroupId: action.payload.selectBoundaryGroupId,
        selectionModeData: {
          type: BoundarySelectMode.EditCustomBoundary,
          action: action.payload.actionType,
          boundaryTerritoryGroupId: action.payload.boundaryTerritoryGroupId,
          boundaryId: action.payload.boundaryId,
        },
        selectedBoundaryIds: new Set(),
      };
    }

    case BOUNDARY_SELECT_SET_LASSO_IS_LOADING: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        isLassoSelectLoading: action.payload.isLoading,
      };
    }

    case BOUNDARY_SELECT_SET_LASSO_OVERLAP: {
      if (!state.isModalOpen) {
        return state;
      }

      return {
        ...state,
        selectLassoOverlap: action.payload.overlap,
      };
    }

    case BOUNDARY_SELECT_OPTIMIZED_TERRITORIES_SUBSET_OPEN_MODAL: {
      const {
        boundaryGroupId,
        configuration,
      } = action.payload;
      return {
        ...state,
        isModalOpen: true,
        isActive: false,
        selectBoundaryGroupId: boundaryGroupId,
        selectionModeData: {
          type: BoundarySelectMode.CreateOptimizedTerritoriesSubset,
          configuration,
        },
        selectedBoundaryIds: new Set(action.payload.selectedBoundaryIds),
        hoveredBoundaryId: null,
      };
    }

    case BOUNDARY_SELECT_ADD_BOUNDARY: {
      if (!isBoundarySelectActive(state) || !notEmpty(action.payload.boundaryIds)) {
        return state;
      }

      const firstBoundaryId: number = action.payload.boundaryIds[0];
      const newSelectBoundaryIds = state.allowMultipleSelection ? copy.andAdd(state.selectedBoundaryIds, action.payload.boundaryIds) : new Set([firstBoundaryId]);

      return {
        ...state,
        selectedBoundaryIds: newSelectBoundaryIds,
      };
    }

    case BOUNDARY_SELECT_REMOVE_BOUNDARY: {
      if (!isBoundarySelectActive(state)) {
        return state;
      }

      const newSelectBoundaryIds = copy.andRemove(state.selectedBoundaryIds, [action.payload.boundaryId]);

      return {
        ...state,
        selectedBoundaryIds: newSelectBoundaryIds,
      };
    }

    case BOUNDARY_SELECT_HOVER_BOUNDARY: {
      if (!isBoundarySelectActive(state)) {
        return state;
      }

      return {
        ...state,
        hoveredBoundaryId: action.payload.boundaryId,
      };
    }

    case BOUNDARY_SELECT_STOP_BOUNDARY_HOVER: {
      return {
        ...state,
        hoveredBoundaryId: null,
      };
    }

    case BOUNDARY_SELECT_SET_SELECT_TYPE: {
      return {
        ...state,
        selectType: action.payload.selectType,
      };
    }

    default:
      return state;
  }
};
