import { useMemo } from 'react';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { createStackedMarkerId } from '~/map/map/markers/useStacksAndClusters/StackedMarkerId.type';
import { useStackedMarkers } from '~/map/map/markers/useStacksAndClusters/useStackedMarkers';

export const useGetStackedInfoForSpreadsheetRow = (spreadsheetRow: Readonly<LatLng & SpreadsheetRowId> | undefined) => {
  const markersStacks = useStackedMarkers();

  const stackForSpreadsheetRow = useMemo(() => {
    return spreadsheetRow ? markersStacks.get(createStackedMarkerId(spreadsheetRow)) : undefined;
  }, [spreadsheetRow, markersStacks]);

  const isStacked = (stackForSpreadsheetRow?.stackedMarkers?.length ?? -1) > 1;

  const latLng: LatLng | undefined = useMemo(() => {
    return stackForSpreadsheetRow && isStacked ? ({
      lat: stackForSpreadsheetRow.lat,
      lng: stackForSpreadsheetRow.lng,
    }) : spreadsheetRow;
  }, [spreadsheetRow, stackForSpreadsheetRow, isStacked]);

  return useMemo(() => ({
    latLng,
    isStacked,
    data: isStacked ? stackForSpreadsheetRow : undefined,
  }), [isStacked, latLng, stackForSpreadsheetRow]);
};
