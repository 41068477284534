import { useMemo } from 'react';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { createSelectedCalloutDrawingOptionsFromSettings } from '~/drawingTool/factories/drawingToolCalloutSettings.factory';
import { useDrawingEditSelectedDrawingLatestSnapshot } from '../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { type DrawingItem } from '../../store/mapSettings/drawing/items/drawingItems.state';
import { type DrawingToolOptions } from '../drawingTool.types';
import { createSelectedArrowDrawingOptionsFromSettings } from '../factories/drawingToolArrowSettings.factory';
import { createCircleDrawingOptionsFromSettings } from '../factories/drawingToolCircleSettings.factory';
import { createIconMarkerDrawingOptionsFromSettings } from '../factories/drawingToolIconMarkerSettings.factory';
import { createSelectedImageDrawingOptionsFromSettings } from '../factories/drawingToolImageSettings.factory';
import { createSelectedLabelDrawingOptionsFromSettings } from '../factories/drawingToolLabelSettings.factory';
import { createSelectedNumberDrawingOptionsFromSettings } from '../factories/drawingToolNumberSettings.factory';
import { createPolygonDrawingOptionsFromSettings } from '../factories/drawingToolPolygonSettings.factory';
import { createPolylineDrawingOptionsFromSettings } from '../factories/drawingToolPolylineSettings.factory';
import { createRectangleDrawingOptionsFromSettings } from '../factories/drawingToolRectangleSettings.factory';
import { createSelectedTextDrawingOptionsFromSettings } from '../factories/drawingToolTextSettings.factory';
import { useDrawingToolSizePerPixelRatio } from './useDrawingToolSizePerPixelRatio';

type SelectedDrawingWithOptions = {
  item: DrawingItem;
  options: Partial<DrawingToolOptions>;
};

export const useSelectedDrawingItemWithOptions = (): SelectedDrawingWithOptions | null => {
  const selectedDrawingSnapshot = useDrawingEditSelectedDrawingLatestSnapshot();
  const scaledSizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  return useMemo(() => {
    if (!selectedDrawingSnapshot) {
      return null;
    }

    let options: Partial<DrawingToolOptions> | null = null;

    switch (selectedDrawingSnapshot.type) {
      case DrawingTool.Arrow: {
        options = createSelectedArrowDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio);
        break;
      }
      case DrawingTool.Callout: {
        options = createSelectedCalloutDrawingOptionsFromSettings(
          selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio
        );
        break;
      }
      case DrawingTool.Circle: {
        options = createCircleDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings);
        break;
      }
      case DrawingTool.IconMarker: {
        options = createIconMarkerDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings.icon);
        break;
      }
      case DrawingTool.Image: {
        options = createSelectedImageDrawingOptionsFromSettings(
          selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio, selectedDrawingSnapshot.value.attachmentId);
        break;
      }
      case DrawingTool.Label: {
        options = createSelectedLabelDrawingOptionsFromSettings(
          selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio
        );
        break;
      }
      case DrawingTool.Number: {
        options = createSelectedNumberDrawingOptionsFromSettings(
          selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio
        );
        break;
      }
      case DrawingTool.Polygon: {
        options = createPolygonDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings);
        break;
      }
      case DrawingTool.Polyline: {
        options = createPolylineDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings);
        break;
      }
      case DrawingTool.Rectangle: {
        options = createRectangleDrawingOptionsFromSettings(selectedDrawingSnapshot.value.settings);
        break;
      }
      case DrawingTool.Text: {
        options = createSelectedTextDrawingOptionsFromSettings(
          selectedDrawingSnapshot.value.settings, scaledSizePerPixelRatio
        );
        break;
      }

      default:
    }

    if (!options) {
      return null;
    }

    return {
      item: selectedDrawingSnapshot,
      options,
    };
  }, [selectedDrawingSnapshot, scaledSizePerPixelRatio]);
};
