import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { CustomPicker } from 'react-color';
import {
  Alpha, Hue, Saturation,
} from 'react-color/lib/components/common';
import { type ExportedColorProps } from 'react-color/lib/components/common/ColorWrap';
import { inputCommonStyle } from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { FPS30 } from '~/_shared/utils/throttle/throttle';
import { useTheme } from '../../../themes/theme.hooks';
import { type Theme } from '../../../themes/theme.model';
import { type ThemeProps } from '../../../types/themeProps';
import { useClickOutsideListenerRef } from '../../../utils/hooks/useClickOutsideListenerRef';
import { ColorCodeHexFieldComponent } from '../colorCodeFields/colorCodeHexField.component';
import {
  createColor, getMaxAmountOfColors, rgbColorToString,
} from '../colorPicker.helpers';
import {
  type ColorResult, type RGBColor,
} from '../colorPicker.types';

const COLOR_PICKER_WIDTH = 200;
const COLOR_PICKER_PADDING = 10;
const COLOR_TILES_WRAPPER_PADDING = 10;
const SATURATION_PANEL_HEIGHT = 150;
const PRESET_COLOR_TILE_DIMENSIONS = 16;
const SELECTED_COLOR_TILE_DIMENSION = 24;
const SELECTED_COLOR_TILE_MARGIN = 4;
const COLOR_TILES_AND_INPUT_HEIGHT = 2 * PRESET_COLOR_TILE_DIMENSIONS + COLOR_TILES_WRAPPER_PADDING;
const PRESET_COLORS_CONTAINER_HEIGHT = COLOR_TILES_AND_INPUT_HEIGHT + 2 * COLOR_TILES_WRAPPER_PADDING;

export const MAPTIVE_COLOR_PICKER_WIDTH = COLOR_PICKER_WIDTH + COLOR_PICKER_PADDING;
export const MAPTIVE_COLOR_PICKER_HEIGHT = SELECTED_COLOR_TILE_DIMENSION + SELECTED_COLOR_TILE_MARGIN
  + SATURATION_PANEL_HEIGHT + COLOR_TILES_WRAPPER_PADDING + PRESET_COLORS_CONTAINER_HEIGHT;

const coloPickerStyle = (props: {theme: Theme}) => css({
  position: 'relative',
  backgroundColor: props.theme.colorPickerColors.backgroundColor,
  borderRadius: '4px',
  width: `${COLOR_PICKER_WIDTH}px`,
  padding: `${COLOR_PICKER_PADDING}px ${COLOR_PICKER_PADDING}px 0px`,
  boxShadow: `${props.theme.colorPickerColors.shadowColorPrimary} 0px 0px 0px 1px,
              ${props.theme.colorPickerColors.shadowColorPrimary} 0px 8px 16px`,
  zIndex: 1,
});
const saturationStyle = css({
  position: 'relative',
  width: '200px',
  height: `${SATURATION_PANEL_HEIGHT}px`,
});
const hueStyle = css({
  position: 'relative',
  width: '170px',
  height: '10px',
});
const alphaStyle = css({
  position: 'relative',
  width: '170px',
  height: '10px',
  marginTop: '4px',
});
const hueAlphaTileWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});
const slidersWrapperStyle = css({ padding: '4px 0px' });

const selectedColorTileStyle = (props: ThemeProps<{color: RGBColor}>) => css({
  backgroundColor: rgbColorToString(props.color),
  borderRadius: '3px',
  boxShadow: `${props.theme.colorPickerColors.shadowColorPrimary} 0px 0px 0px 1px inset,
              ${props.theme.colorPickerColors.shadowColorSecondary} 0px 0px 4px inset`,
  width: `${SELECTED_COLOR_TILE_DIMENSION}px`,
  height: `${SELECTED_COLOR_TILE_DIMENSION}px`,
  margin: `${SELECTED_COLOR_TILE_MARGIN}px 0 0 ${SELECTED_COLOR_TILE_MARGIN}px`,
});

const colorsAndInputStyle = css({
  display: 'flex',
  position: 'relative',
  boxSizing: 'border-box',
  height: PRESET_COLORS_CONTAINER_HEIGHT,
});

const colorTilesWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  margin: `0px -${COLOR_TILES_WRAPPER_PADDING}px`,
  padding: `${COLOR_TILES_WRAPPER_PADDING}px 0px 0px ${COLOR_TILES_WRAPPER_PADDING}px`,
  width: '60%',
});

const hexInputWrapperStyle = css({
  display: 'flex',
  position: 'absolute',
  bottom: COLOR_TILES_WRAPPER_PADDING,
  marginLeft: -COLOR_TILES_WRAPPER_PADDING,
  right: 0,

  '&:focus-visible': {
    outline: 'none',
  },
});

const hexInputStyle = (props: ThemeProps) => css(inputCommonStyle(props.theme, true), {
  backgroundColor: props.theme.colorPickerColors.backgroundColor,
  border: `1px solid ${props.theme.colorPickerColors.shadowColorInput}`,
  boxSizing: 'border-box',
  color: props.theme.colorPickerColors.textColor,
  fontSize: 16,
  fontWeight: 500,
  height: COLOR_TILES_AND_INPUT_HEIGHT,
  outline: 'none',
  padding: '4px 10% 3px',
  width: PRESET_COLOR_TILE_DIMENSIONS * 4 + COLOR_TILES_WRAPPER_PADDING * 3,
});

const presetColorTileStyle = (props: ThemeProps<{color: RGBColor}>) => css({
  backgroundColor: rgbColorToString(props.color),
  borderRadius: '3px',
  height: PRESET_COLOR_TILE_DIMENSIONS,
  margin: '0px 10px 10px 0px',
  width: PRESET_COLOR_TILE_DIMENSIONS,
  boxShadow: `${props.theme.colorPickerColors.shadowColorPrimary} 0px 0px 0px 1px inset`,
  '&:focus': {
    boxShadow: `${props.theme.colorPickerColors.shadowColorPrimary} 0px 0px 0px 1px inset,
                ${props.color} 0px 0px 4px`,
  },
});

type CustomColorPickerProps = ExportedColorProps & Readonly<{
  className?: string;
  displayAlpha?: boolean;
  displayNumericalColorsRepresentation: boolean;
  presetColors: readonly string[];
  selectedColor: ColorResult;

  onChange: (color: ColorResult) => void;
  onClose: () => void;
  setRef?: (ref?: HTMLDivElement | null) => void;
}>;

const CustomColorPickerComponent: FC<CustomColorPickerProps> = ({
  selectedColor,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const previewColor = props.displayAlpha ? selectedColor : createColor({ ...selectedColor.rgb, a: 1 });

  const colorPickerRef = useClickOutsideListenerRef(props.onClose, true);

  const presetColors = useMemo(() =>
    getMaxAmountOfColors(props.presetColors),
  [props.presetColors]);

  useEffect(() => {
    const setRef = props.setRef;
    setRef?.(colorPickerRef?.current);
  }, [colorPickerRef, props.setRef]);

  return (
    <div
      className={props.className}
      css={coloPickerStyle({ theme })}
      ref={colorPickerRef}
    >
      <div css={saturationStyle} >
        <Saturation
          {...props}
          onChange={onChange}
        />
      </div>
      <span css={hueAlphaTileWrapperStyle}>
        <span css={slidersWrapperStyle}>
          <div css={hueStyle} >
            <Hue
              {...props}
              onChange={onChange}
            />
          </div>
          {props.displayAlpha && (
            <div css={alphaStyle}>
              <Alpha
                {...props}
                onChange={onChange}
              />
            </div>
          )}
        </span>
        <div css={selectedColorTileStyle({ theme, color: previewColor.rgb })} />
      </span>
      <div css={colorsAndInputStyle}>
        <div css={colorTilesWrapperStyle} >
          {presetColors.map((hex: string, index: number) => {
            const color = createColor(hex);
            return (
              <div
                key={hex}
                tabIndex={index}
                css={presetColorTileStyle({ theme, color: color.rgb })}
                onClick={() => onChange(color)}
              />
            );
          })}
        </div>
        <div css={hexInputWrapperStyle} >
          <ColorCodeHexFieldComponent
            css={hexInputStyle({ theme })}
            value={selectedColor}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

const ColorPicker = CustomPicker(CustomColorPickerComponent);

export type MaptiveColorPickerProps = Readonly<{
  selectedColor: ColorResult;
  isFixed?: boolean;
  className?: string;
  displayAlpha?: boolean;
  presetColors?: readonly string[];
  displayNumericalColorsRepresentation?: boolean;

  onChange?: (color: ColorResult) => void;
  onClose?: () => void;
  setRef?: (ref?: HTMLDivElement) => void;
}>;

export const MaptiveColorPickerComponent: FC<MaptiveColorPickerProps> = props => {
  const presetColors = useMemo(() => props.presetColors ?? [], [props.presetColors]);

  const onClose = useCallback((): void => {
    const onClose = props.onClose;
    onClose?.();
  }, [props.onClose]);

  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;

  const onColorChange = useThrottle((color: ColorResult): void => {
    onChangeRef.current?.(createColor(color.hsl));
  }, [], FPS30);

  return (
    <ColorPicker
      {...props}
      color={props.selectedColor.hsl}
      displayNumericalColorsRepresentation={!!props.displayNumericalColorsRepresentation}
      onChange={onColorChange}
      onClose={onClose}
      presetColors={presetColors}
      selectedColor={props.selectedColor}
    />
  );
};
