import { createAction } from '@reduxjs/toolkit';
import type { LatLng } from '~/_shared/types/latLng';

export const setLocationFinderToolsState = createAction(
  'MAP_SETTINGS_TOOLS_STATE_SET_LOCATION_FINDER',
  (latLng: LatLng | null, locationLimit: number | null) => ({
    payload: {
      latLng,
      locationLimit,
    },
  })
);
