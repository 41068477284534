import { type FC } from 'react';
import { type BoundaryKeySettings } from '../activeBoundary.component';
import { BoundaryItemContextMenuComponent } from './boundaryItemContextMenu.component';
import { BoundaryRowComponent } from './boundaryRow.component';

type BoundaryItemProps = {
  boundaryGroupId: number;
  setting: BoundaryKeySettings;
  legendMode?: boolean;
};

export const BoundaryItemComponent: FC<BoundaryItemProps> = ({ boundaryGroupId, setting, legendMode }) => {
  return (
    <BoundaryRowComponent
      legendMode={legendMode}
      isChecked={setting.isVisible}
      label={setting.label}
      onToggle={setting.onToggle}
      selectedColor={setting.selectedColor}
      lineColor={setting.lineColor}
      lineWidth={setting.lineWidth}
      actions={(
        <BoundaryItemContextMenuComponent
          boundaryGroupId={boundaryGroupId}
          onDeleteTerritoryClick={setting.onRemoveBoundaryClick}
          onTerritorySettingsClick={setting.onBoundarySettingsClick}
          onExportDataInTerritoryClick={setting.onExportDataInBoundaryClick}
          onManuallyEditPolygonClick={setting.onManuallyEditPolygonClick}
          onZoomToBoundaryClick={setting.onZoomToBoundaryClick}
          onBatchEditLocationsClick={setting.onBatchEditLocationsClick}
          onExportContainedBoundaries={setting.onExportContainedBoundaries}
        />
      )}
    />
  );
};
