import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type Theme } from '../../themes/theme.model';
import { useTranslation } from '../../utils/hooks';
import {
  getSliderSize, getToggleFontSize, getToggleHeight, truncate,
} from './toggle.helpers';
import {
  checkBoxStyle, iconOrLabelStyle, iconOrLabelWrapperStyle, labelStyle, spanStyle,
} from './toggle.styles';

export enum ToggleSize {
  M = 'M',
  L = 'L',
}

export type ToggleProps = Readonly<{
  className?: string;
  isDisabled?: boolean;
  isOn: boolean;
  offIcon?: IconProp;
  offLabel?: string;
  onIcon?: IconProp;
  onLabel?: string;
  size?: ToggleSize;
  maxLabelChars?: number;

  onChange?: (isSelected: boolean) => void;
}>;

export const ToggleComponent: FC<ToggleProps> = (props) => {
  const theme: Theme = useTheme();
  const [t] = useTranslation();
  const fontSize: number = getToggleFontSize(props.size);
  const height: number = getToggleHeight(props.size);
  const sliderRadius: number = getSliderSize(props.size);

  const { size = ToggleSize.M, maxLabelChars = 3, isOn, isDisabled, className, onIcon, offIcon } = props;
  const onLabel = props.onLabel ? truncate(props.onLabel, maxLabelChars) : t('Short3Char.On');
  const offLabel = props.offLabel ? truncate(props.offLabel, maxLabelChars) : t('Short3Char.Off');

  return (
    <label
      css={labelStyle({
        theme, height,
        isDisabled: !!isDisabled,
        isOn,
      })}
      className={className}
    >
      <input
        type="checkbox"
        css={checkBoxStyle}
        onChange={(e) => {
          if (isDisabled) {
            return;
          }
          props.onChange?.(e.target.checked);
        }}
        checked={props.isOn}
        value={isOn ? onLabel : offLabel}
      />
      <div
        css={iconOrLabelWrapperStyle({
          fontSize,
          isOn,
          theme,
        })}
      >

        <span css={iconOrLabelStyle({ size, isOn, visible: !isOn })}>
          {offIcon ? (
            <FontAwesomeIcon
              icon={offIcon}
              css={{ paddingLeft: 4 }}
            />
          ) : (
            offLabel
          )}
        </span>

        <span css={iconOrLabelStyle({ size, isOn, visible: isOn })}>
          {onIcon ? (
            <FontAwesomeIcon
              icon={onIcon}
              css={{ paddingRight: 4 }}
            />
          ) : (
            onLabel
          )}
        </span>

      </div>
      <span
        css={spanStyle({
          theme,
          isOn: props.isOn,
          radius: sliderRadius,
        })}
      />
    </label>
  );
};
