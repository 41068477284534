import { useMemo } from 'react';
import { calculateMapScale } from '~/_shared/utils/map/calculateMapScale';
import { useProjectionOverlay } from '../../../useProjectionOverlay';

export type MapBoundaryDistanceToPixelsMap = {[zoom: number]: {latSize: number; lngSize: number} | undefined};

export const useMapBoundaryDistanceToPixels = (map: google.maps.Map | undefined): MapBoundaryDistanceToPixelsMap | undefined => {
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);

  return useMemo(() => {
    if (!map) {
      return;
    }

    const currentZoom = map.getZoom() || 0;
    const currentZoomScale = calculateMapScale(currentZoom);

    const zeroPoint = { lat: 0, lng: 0 };
    const latPoint = { lat: 1, lng: 0 };
    const lngPoint = { lat: 0, lng: 1 };

    const zeroPointPixel = fromLatLngToDivPixel(zeroPoint);
    const latPointPixel = fromLatLngToDivPixel(latPoint);
    const lngPointPixel = fromLatLngToDivPixel(lngPoint);

    if (!zeroPointPixel || !latPointPixel || !lngPointPixel) {
      return;
    }

    const latSize = Math.abs(zeroPointPixel.y - latPointPixel.y);
    const lngSize = Math.abs(zeroPointPixel.x - lngPointPixel.x);

    const result: MapBoundaryDistanceToPixelsMap = {};

    for (let zoomLevel = 0; zoomLevel <= 30; zoomLevel++) {
      const scale = calculateMapScale(zoomLevel);

      const latSizeForLevel = latSize / (scale / currentZoomScale);
      const lngSizeForLevel = lngSize / (scale / currentZoomScale);

      result[zoomLevel] = { latSize: latSizeForLevel, lngSize: lngSizeForLevel };
    }

    return result;
  }, [fromLatLngToDivPixel, map]);
};
