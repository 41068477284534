import {
  type FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { type MapObjectCustomMarkerConfig } from '../mapObject.types';
import { useMapObjectContext } from '../private/mapObjectContext';
import { type MapMarkerInstance } from './mapMarkerModel';

type MapMarkerProps = {
  marker: MapMarkerInstance;
  visuals: MapObjectCustomMarkerConfig;
  onClick?: (markerId: Uuid, event: MapObjectClickEventArgs) => void;
  onRightClick?: (markerId: Uuid, event: MapObjectClickEventArgs) => void;
  onDragEnd?: (markerId: Uuid) => void;
  onDragMove?: (markerId: Uuid, latLng: LatLng) => void;
  onDragStart?: (markerId: Uuid, latLng: LatLng) => void;
  onMouseOut?: (markerId: Uuid) => void;
  onMouseOver?: (markerId: Uuid) => void;
};

const MapMarkerComponent: FC<MapMarkerProps> = (props) => {
  const { manager, zIndex } = useMapObjectContext();
  const { marker, visuals } = props;
  const { onDragMove, onDragStart, onDragEnd, onMouseOver, onMouseOut, onClick, onRightClick } = props;

  const [mapMarker, setMapMarker] = useState<WebglOverlayMarker | null>(null);

  const markerOnDragMove = useMemo(() => onDragMove ? (latLng: LatLng) => onDragMove(marker.id, latLng) : undefined, [marker.id, onDragMove]);
  const markerOnDragStart = useMemo(() => onDragStart ? (latLng: LatLng) => onDragStart(marker.id, latLng) : undefined, [marker.id, onDragStart]);
  const markerOnDragEnd = useMemo(() => onDragEnd ? () => onDragEnd(marker.id) : undefined, [marker.id, onDragEnd]);
  const markerOnMouseOver = useMemo(() => onMouseOver ? () => onMouseOver(marker.id) : undefined, [onMouseOver, marker.id]);
  const markerOnMouseOut = useMemo(() => onMouseOut ? () => onMouseOut(marker.id) : undefined, [onMouseOut, marker.id]);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: mapMarker,
    onDragEnd: markerOnDragEnd,
    onDragStart: markerOnDragStart,
    onDragMove: markerOnDragMove,
    onMouseOver: markerOnMouseOver,
    onMouseOut: markerOnMouseOut,
  }), [manager.map, mapMarker, markerOnDragEnd, markerOnDragStart, markerOnDragMove, markerOnMouseOver, markerOnMouseOut]);

  // draw marker
  useEffect(() => {
    const updatedMarker = manager.upsertCustomMarker(marker, visuals, zIndex.customMarker);

    if (mapMarker !== updatedMarker) {
      setMapMarker(updatedMarker);
    }
  }, [manager, marker, visuals, mapMarker, zIndex.customMarker]);

  // web-gl cleanup
  useEffect(() => {
    return () => {
      manager.removeCustomMarker(marker.id);
    };
  }, [manager, marker.id]);

  // registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addCustomMarkerEventListener(marker.id, 'click',
      (e) => {
        onClick?.(marker.id, e);
      });

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, marker.id]);

  // registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseRightClickCleanup = manager.addCustomMarkerEventListener(marker.id, 'rightclick',
      (e) => {
        onRightClick?.(marker.id, e);
      });

    return () => {
      mouseRightClickCleanup();
    };
  }, [manager, onRightClick, marker.id]);

  return null;
};

const pureComponent = memo(MapMarkerComponent);
export { pureComponent as MapMarkerComponent };
