import 'rc-slider/assets/index.css';
import {
  faBracketRoundedSquareLeft, faBracketRoundedSquareRight,
} from '@awesome.me/kit-77e1f5874f/icons/kit/custom';
import { css } from '@emotion/react';
import Slider from 'rc-slider';
import { type MarkObj } from 'rc-slider/lib/Marks';
import {
  type CSSProperties, type FC, type ReactNode, useMemo,
} from 'react';
import type { Writable } from 'ts-essentials';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { isNumber } from '~/_shared/utils/typeGuards';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { isArray } from '../../utils/collections/collections';

export type SliderMarks = Record<string | number, ReactNode | MarkObj>;

export enum SliderConstraintType {
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
}

export type SliderConstraint = {
  type: SliderConstraintType;
  value: number;
};

const DEFAULT_HANDLE_COLOR = '#fff';

const outOfConstraintHandleStyle = ({ theme }: ThemeProps): CSSProperties => ({
  backgroundColor: theme.sliderColors.danger,
  transition: 'background .2s',
});

const handleStyle = ({ theme, index }: ThemeProps<{ index: number }>): CSSProperties => ({
  backgroundColor: index % 2 === 0 ? theme.sliderColors.contrastColor : DEFAULT_HANDLE_COLOR,
});

const constraintMarkIconContainerStyle = ({ isGreaterThan }: { isGreaterThan: boolean }): CSSProperties => ({
  marginLeft: isGreaterThan ? 2 : -2,
});

const constraintMarkIconStyle = ({ theme, index }: ThemeProps<{ index: number }>) => css({
  color: index % 2 === 0 ? theme.sliderColors.contrastColor : DEFAULT_HANDLE_COLOR,
  fontSize: 14,
});

const constraintDotStyle: CSSProperties = {
  display: 'none',
  pointerEvents: 'none',
};

export type SliderValue = readonly [number, ...number[]];

type SliderProps = Readonly<{
  className?: string;
  constraints?: SliderConstraint | SliderConstraint[]; //constraints need to be in the same order as value, take priority over marks and dots
  dotStyle?: CSSProperties | ((value: number) => CSSProperties);
  included?: boolean;
  disabled?: boolean;
  marks?: SliderMarks;
  max: number;
  min: number;
  reverse?: boolean;
  step: number;
  value: SliderValue;
  vertical?: boolean;
  withDots?: boolean;
  children?: React.ReactNode;
  onChange: (value: SliderValue) => void;
}>;

const sliderStyle = ({ theme, isVertical, disabled, marksOnLine }: ThemeProps<{ isVertical?: boolean; disabled?: boolean; marksOnLine?: boolean }>) => {
  const widthOrHeightProp = isVertical ? { width: 4 } : { height: 4 };

  return css({
    '.rc-slider-track': {
      background: theme.sliderColors.trackBackground,
      ...widthOrHeightProp,
    },
    '.rc-slider-rail': {
      background: theme.sliderColors.railBackground,
      ...widthOrHeightProp,
    },
    '.rc-slider-dot': {
      backgroundColor: theme.sliderColors.dotColor,
      borderColor: theme.sliderColors.dotBorder,
      cursor: 'default',
    },
    '.rc-slider-dot-active': {
      backgroundColor: theme.sliderColors.activeDotColor,
      borderColor: theme.sliderColors.activeDotBorder,
    },
    '.rc-slider-handle': {
      '&, &.rc-slider-handle-dragging': {
        boxShadow: `0px 3px 7px ${theme.sliderColors.handleShadow}`,
        border: 'none',
        cursor: 'default',
        opacity: 1,
        backgroundColor: DEFAULT_HANDLE_COLOR,
        zIndex: 1,
      },
    },
    '&.rc-slider-disabled': {
      backgroundColor: 'inherit',
    },
    ...(marksOnLine ? {
      '.rc-slider-mark': {
        top: 0,
        pointerEvents: 'none',
      },
    } : {}),

    opacity: disabled ? theme.opacity.disabled : undefined,
  });
};

export const SliderComponent: FC<SliderProps> = (props) => {
  const theme = useTheme();

  const onChange = (values: number | [number, ...number[]]) => {
    if (isArray(values)) {
      props.onChange(values);
    }
    else {
      props.onChange([values]);
    }
  };

  const constraintsMarksAndStyles = useMemo(() => {
    if (!props.constraints) {
      return null;
    }

    const values = props.value;

    return (isArray(props.constraints) ? props.constraints : [props.constraints]).reduce((acc, constraint, index) => {
      acc.marks[acc.marks[constraint.value] ? constraint.value + 0.0001 : constraint.value] = {
        label: (
          <FontAwesomeIcon
            key={constraint.type === SliderConstraintType.GreaterThan
              ? 'dash-left' : 'dash-right'}
            icon={constraint.type === SliderConstraintType.GreaterThan
              ? faBracketRoundedSquareLeft : faBracketRoundedSquareRight}
            css={constraintMarkIconStyle({ theme, index })}
          />
        ),
        style: constraintMarkIconContainerStyle({ isGreaterThan: constraint.type === SliderConstraintType.GreaterThan }),
      };
      acc.handleStyle.push(
        (
          (values[index] && constraint.type === SliderConstraintType.GreaterThan && (values[index] ?? 0) < constraint.value)
          || (values[index] && constraint.type === SliderConstraintType.LessThan && (values[index] ?? 0) > constraint.value)
        ) ? outOfConstraintHandleStyle({ theme }) : handleStyle({ theme, index })
      );
      return acc;
    }, { handleStyle: [] as React.CSSProperties[], marks: {} as SliderMarks });
  }, [props.constraints, props.value, theme]);

  const [singleValue] = props.value;

  return (
    <div
      className={props.className}
    >
      {props.value.length === 1 && isNumber(singleValue) && (
        <Slider
          range={false}
          css={sliderStyle({ theme, isVertical: props.vertical, disabled: props.disabled, marksOnLine: !!constraintsMarksAndStyles })}
          disabled={props.disabled}
          dotStyle={constraintsMarksAndStyles?.marks ? constraintDotStyle : props.dotStyle}
          dots={props.withDots}
          included={props.included}
          marks={constraintsMarksAndStyles?.marks || props.marks}
          max={props.max}
          min={props.min}
          onChange={onChange}
          reverse={props.reverse}
          step={props.step}
          value={singleValue}
          vertical={props.vertical}
          handleStyle={constraintsMarksAndStyles?.handleStyle[0] || undefined}
        />
      )}

      {props.value.length > 1 && (
        <Slider
          range
          allowCross={false}
          css={sliderStyle({ theme, disabled: props.disabled, marksOnLine: !!constraintsMarksAndStyles })}
          disabled={props.disabled}
          dotStyle={constraintsMarksAndStyles?.marks ? constraintDotStyle : props.dotStyle}
          marks={constraintsMarksAndStyles?.marks || props.marks}
          max={props.max}
          min={props.min}
          onChange={onChange}
          step={props.step}
          value={props.value as Writable<SliderValue>}
          handleStyle={constraintsMarksAndStyles?.handleStyle.length
            ? constraintsMarksAndStyles?.handleStyle : undefined
          }
        />
      )}

      {props.children}
    </div>
  );
};
