import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../store/app.store';

const buttonActionStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  height: 24,
  padding: '0 12px',
  textTransform: 'uppercase',
});

type SettingsAccordionButtonProps = Readonly<{
  buttonStyle?: ButtonStyle;
  isDisabled: boolean;
  textSelector: (state: AppState) => ReactNode;
  onSubmitClick?: () => void;
}>;

export const SettingsAccordionButtonComponent: FC<SettingsAccordionButtonProps> = (props) => {
  const buttonText = useSelector(props.textSelector);

  return (
    <ButtonComponent
      buttonStyle={props.buttonStyle}
      css={buttonActionStyle}
      isDisabled={props.isDisabled}
      onClick={props.onSubmitClick}
      text={buttonText}
    />
  );
};
