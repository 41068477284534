import {
  type FC, useEffect, useMemo, useState,
} from 'react';
import { useStorageService } from '../../utils/storageService';
import { DisclaimerComponent } from './disclaimer.component';

type DisclaimerContainer = Readonly<{
  className?: string;
  permanentConfirmSettings?: {
    id: string;
    text: string;
  };
  children?: React.ReactNode;
}>;

const getPermanentConfigKey = (id: string) => (`permanent-disclaimer-${id}`);

export const DisclaimerContainer: FC<DisclaimerContainer> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const storageService = useStorageService();

  const storageKey = props.permanentConfirmSettings ? getPermanentConfigKey(props.permanentConfirmSettings.id) : null;
  const isPermanentlyClosed = useMemo(() => {
    return storageKey ? storageService.getLocalStorageItem(storageKey) === 'true' : false;
  }, [storageService, storageKey]);

  const onClose = (isPermanent: boolean) => {
    if (isPermanent && storageKey) {
      storageService.setLocalStorageItem(storageKey, 'true');
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (!isPermanentlyClosed) {
      setIsOpen(true);
      return;
    }

    setIsOpen(false);
  }, [isPermanentlyClosed]);

  if (!isOpen) {
    return null;
  }

  return (
    <DisclaimerComponent
      onClose={onClose}
      checkboxLabel={props.permanentConfirmSettings?.text}
      className={props.className}
    >
      {props.children}
    </DisclaimerComponent>
  );
};
