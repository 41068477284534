import { type ReadonlySpreadsheetRowIdMap } from '../spreadsheet/spreadsheetRowIdMap';

const isMap = (entry: ReadonlyMap<unknown, unknown> | ReadonlySet<unknown> | ReadonlyArray<unknown>): entry is ReadonlyMap<unknown, unknown> => {
  if ((entry as ReadonlySpreadsheetRowIdMap<unknown>).isSpreadsheetRowIdMap) {
    throw new Error('This helper function can only work with native map object. Not with objects that implement map interface.');
  }

  return Map.prototype.isPrototypeOf(entry);
};

const isSet = (collection: ReadonlyArray<unknown> | ReadonlySet<unknown>): collection is ReadonlySet<unknown> =>
  Set.prototype.isPrototypeOf(collection);

export const isArray = <T>(collection: any): collection is ReadonlyArray<T> => {
  return Array.isArray(collection);
};

function remove<TKey, TValue>(map: ReadonlyMap<TKey, TValue>, toRemove: Iterable<TKey>): ReadonlyMap<TKey, TValue>;
function remove<TValue>(set: ReadonlySet<TValue>, toRemove: Iterable<TValue>): ReadonlySet<TValue>;
function remove<TValue>(array: ReadonlyArray<TValue>, toRemove: Iterable<TValue>): ReadonlyArray<TValue>;
function remove<TKey, TValue>(input: ReadonlySet<TValue> | ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue>, toRemove: Iterable<TValue> | Iterable<TKey>): ReadonlySet<TValue> | ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(input)) {
    const result = new Map(input);
    for (const key of (toRemove as Iterable<TKey>)) {
      result.delete(key);
    }
    return result;
  }

  if (isArray(input)) {
    const toRemoveSet = new Set(toRemove as Iterable<TValue>);
    return input.filter(value => !toRemoveSet.has(value));
  }

  const result = new Set(input);
  for (const value of (toRemove as Iterable<TValue>)) {
    result.delete(value);
  }
  return result;
}

function add<TKey, TValue>(map: ReadonlyMap<TKey, TValue>, toAdd: Iterable<readonly [TKey, TValue]>, existingKeyResolver?: (newValue: TValue, existingValue: TValue, key: TKey) => TValue): ReadonlyMap<TKey, TValue>;
function add<TValue>(set: ReadonlySet<TValue>, toAdd: Iterable<TValue>): ReadonlySet<TValue>;
function add<TKey, TValue>(
  set: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  toAdd: Iterable<TValue> | Iterable<readonly [TKey, TValue]>,
  existingKeyResolver: ((newValue: TValue, existingValue: TValue, key: TKey) => TValue) =
  v => v, // replaces existing with new value by default
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(set)) {
    const result = new Map(set);
    for (const [key, value] of (toAdd as Iterable<readonly [TKey, TValue]>)) {
      result.set(
        key,
        result.has(key)
          ? existingKeyResolver(value, result.get(key) ?? value, key)
          : value,
      );
    }
    return result;
  }
  const result = new Set(set);
  for (const value of (toAdd as Iterable<TValue>)) {
    result.add(value);
  }
  return result;
}

function replace<TValue>(array: Array<TValue>, index: number, newValue: TValue): Array<TValue>;
function replace<TValue>(array: ReadonlyArray<TValue>, index: number, newValue: TValue): ReadonlyArray<TValue>;
function replace<TValue, TKey>(map: ReadonlyMap<TKey, TValue>, key: TKey, newValue: TValue): ReadonlyMap<TKey, TValue>;
function replace<TValue, TKey>(collection: ReadonlyArray<TValue> | Array<TValue> | ReadonlyMap<TKey, TValue>, key: number | TKey, newValue: TValue): ReadonlyArray<TValue> | Array<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    const result = new Map(collection);
    result.set(key as TKey, newValue);
    return result;
  }
  const index = key as number;
  return collection.length <= index ?
    collection :
    [...collection.slice(0, index), newValue, ...collection.slice(index + 1)];
}

export function togglePresence<TValue>(set: ReadonlySet<TValue>, item: TValue): ReadonlySet<TValue>;
export function togglePresence<TValue>(array: ReadonlyArray<TValue>, item: TValue): ReadonlyArray<TValue>;
export function togglePresence<TValue>(collection: ReadonlyArray<TValue> | ReadonlySet<TValue>, item: TValue): ReadonlyArray<TValue> | ReadonlySet<TValue> {
  if (isSet(collection)) {
    return collection.has(item)
      ? remove(collection, [item])
      : add(collection, [item]);
  }

  return collection.includes(item)
    ? collection.filter(i => i !== item)
    : [...collection, item];
}

export const copy = {
  andRemove: remove,
  andAdd: add,
  andReplace: replace,
  andTogglePresence: togglePresence,
};

export const flatten = <T>(array: ReadonlyArray<ReadonlyArray<T>>): T[] => ([] as ReadonlyArray<T>).concat(...array);

export const mapObjectProperties = <TOldValue, TNewValue>(mapper: (oldValue: TOldValue, key: string) => TNewValue, obj: {
  readonly [key: string]: TOldValue;
}): { [key: string]: TNewValue } =>
  Object.keys(obj)
    .reduce((acc, key) => {
      const oldValue = obj[key];
      if (!oldValue) {
        return acc;
      }

      acc[key] = mapper(oldValue, key);
      return acc;
    }, {} as { [key: string]: TNewValue });

export const filterObjectProperties = <TValue>(shouldRemain: (value: TValue, key: string) => boolean, obj: {
  readonly [key: string]: TValue;
}): { [key: string]: TValue } =>
  Object.keys(obj)
    .reduce((acc, key) => {
      const value = obj[key];
      if (!value || !shouldRemain(value, key)) {
        return acc;
      }

      acc[key] = value;
      return acc;
    }, {} as { [key: string]: TValue });

export const zipWith = <TFirst, TSecond, TResult>(array1: ReadonlyArray<TFirst>, array2: ReadonlyArray<TSecond>, zipper: (a: TFirst, b: TSecond) => TResult): TResult[] =>
  array1
    .slice(0, array2.length)
    .map((a, i) => zipper(a, array2[i] as TSecond));

export const pushAndReturn = <T>(array: T[], itemToAdd: T) => {
  array.push(itemToAdd);
  return itemToAdd;
};

export const getOrSet = <TKey, TValue>(map: Map<TKey, TValue>, key: TKey, valueFactory: () => Exclude<TValue, undefined>) => {
  const existing = map.get(key);
  if (existing !== undefined) {
    return existing;
  }

  const value = valueFactory();
  map.set(key, value);
  return value;
};

export type KeyOfMap<M extends Map<unknown, unknown>> = M extends Map<infer K, unknown> ? K : never;

export type FixedLengthArray<L extends number, T> = ArrayLike<T> & { length: L };

export const subtract = <T>(A: T[], B: T[]) => {
  return A.filter(n => !B.includes(n));
};

export const getOneMapEntry = <K, V>(map: Map<K, V>): [K, V] | null => {
  const result = map.entries().next();
  if (!result.done) {
    return result.value;
  }
  return null;
};

export const areSetsEqual = <TValue>(set1: ReadonlySet<TValue>, set2: ReadonlySet<TValue>): boolean => {
  if (set1 === set2) {
    return true;
  }

  if (set1.size !== set2.size) {
    return false;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }

  return true;
};
