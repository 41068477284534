import { useState } from 'react';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { convertPolygonToGeometry } from '~/_shared/types/polygon/polygon.utils';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '../../../../_shared/components/exportDataModal/exportDataModal.component';
import { prepareAsyncCopyToClipboard } from '../../../../_shared/utils/clipboard/clipboard.helpers';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { useTimeout } from '../../../../_shared/utils/hooks/useTimeout';
import { ExportDataFileType } from '../../../../data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '../../../../data/exportDataModal/exportDataMethod.enum';
import { useExportSpreadsheetData } from '../../../../data/exportDataModal/useExportSpreadsheetData';
import {
  type ModalProps, ModalType,
} from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { type PolygonFilterRequest } from '../../../../spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { usePrimarySpreadsheetId } from '../../../../store/selectors/usePrimarySpreadsheetId';

export type ExportLassoDataModalContainerProps = Readonly<{
  polygon: Polygon;
} & ModalProps>;

export const ExportLassoDataModalContainer: React.FC<ExportLassoDataModalContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { getExportedSpreadsheetData, isLoading } = useExportSpreadsheetData();
  const [t] = useTranslation();

  const exportData = async (results: ExportDataModalResults) => {
    if (!primarySpreadsheetId) {
      return;
    }
    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    const filterMarkersInLasso: PolygonFilterRequest = {
      type: 'or',
      polygons: [{ path: convertPolygonToGeometry(props.polygon) }],
    };

    try {
      const response = await getExportedSpreadsheetData({
        virtualSpreadsheetId: primarySpreadsheetId,
        extension: results.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : results.fileType,
        polygonFilter: filterMarkersInLasso,
        exportAsText: results.method === ExportDataMethod.Clipboard,
      });

      if (results.method === ExportDataMethod.Clipboard) {
        const fileContent = response.data[0]?.result?.content;

        if (!fileContent) {
          setErrorMessage(t('No locations to export'));
          return;
        }
        asyncCopyToClipboard(fileContent);
        showCopiedToClipboardMessage();
      }
      else {
        const fileName = response.data[0]?.result?.file_name;
        if (!fileName) {
          setErrorMessage(t('No locations to export'));
          return;
        }

        const exportUrl = getExportUrl(fileName);
        downloadFile(exportUrl);

        props.onClose();
        openDownloadResultsModal({
          downloadUrl: exportUrl,
        });
      }
    }
    catch {
      setErrorMessage('Error exporting data');
    }
  };

  return (
    <ExportDataModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={exportData}
      isLoading={isLoading}
      error={errorMessage}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
    />
  );
};
