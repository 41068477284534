import { useMemo } from 'react';
import {
  SettingType,
  type SpreadsheetColumnIdSettingsRow,
} from '../_shared/components/settingsTable/settingsTable.types';
import { type TranslationFnc } from '../_shared/utils/hooks';
import { useSpreadsheetColumns } from '../store/matchupData/matchupDataSelectors.hook';
import { usePresentationalColumnsRestrictionsStateSelector } from '../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.selectors';
import { type PresentationalColumnsRestrictionFlags } from './presentationalColumnsRestrictions.repository';

type Results = {
  settingsRows: SpreadsheetColumnIdSettingsRow[];
};

export const usePresentationalColumnsRestrictionsSettingsRows = (
  selectedSpreadsheetId: number | null,
  restrictionFlag: keyof PresentationalColumnsRestrictionFlags,
  t: TranslationFnc
): Results => {
  const spreadsheetColumns = useSpreadsheetColumns();
  const columnsRestrictions = usePresentationalColumnsRestrictionsStateSelector();

  const settingsRows = useMemo<SpreadsheetColumnIdSettingsRow[]>(() => {
    const results: SpreadsheetColumnIdSettingsRow[] = [];

    for (const column of spreadsheetColumns) {
      if (column.spreadsheetId !== selectedSpreadsheetId) {
        continue;
      }

      const restriction = columnsRestrictions.restrictions.get(column.spreadsheetId)?.get(column.id);

      // we don't disable column if we are working on master column settings
      const isItemDisabled: boolean = restrictionFlag !== 'master' && restriction?.master === true;
      const isItemOn: boolean = !restriction || !restriction[restrictionFlag];

      const disabledReason = isItemDisabled ?
        t('This column was disabled for use on presentational maps by Enable/Disable Columns setting')
        :
        null;

      results.push({
        columnId: {
          columnId: column.id,
          spreadsheetId: column.spreadsheetId,
        },
        row: {
          data: [{
            type: SettingType.Text,
            value: column.name,
            disabled: disabledReason,
          }, {
            type: SettingType.Toggle,
            value: isItemOn,
            onLabel: t('Short3Char.On'),
            offLabel: t('Short3Char.Off'),
            isCentered: true,
            disabled: disabledReason,
          }],
        },
      });
    }

    return results;
  }, [selectedSpreadsheetId, spreadsheetColumns, columnsRestrictions, restrictionFlag, t]);

  return {
    settingsRows,
  };
};
