import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  EditDataTableComponent, type EditDataTableRow,
} from '../../_shared/components/editDataTable/editDataTable.component';
import { ModalComponent } from '../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { type ChangeableSettingRow } from '../../_shared/components/settingsTable/settingsTable.types';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { useTranslation } from '../../_shared/utils/hooks';
import { isTextEmpty } from '../../_shared/utils/text/text.helpers';

type AddDataColumnProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isError: boolean;
  onSubmit: (columnName: string, defaultValue: string) => void;
}>;

const modalContentStyle = css({
  padding: 0,
});

const defaultValueNoteStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  padding: '0 12px 10px',
  margin: '0',
  fontSize: 12,
  textAlign: 'right',
});

const errorAlertStyle = css({
  marginTop: 15,
});

const settingsTableDataCellStyle = ({ isCentered, isDisabled }: ThemeProps<{ isCentered?: boolean; isDisabled: boolean }>) => css({
  padding: 0,
  textAlign: isCentered ? 'center' : 'left',
  opacity: isDisabled ? .5 : undefined,
});

const settingsTableDataRowStyle = ({ theme }: ThemeProps) => css({
  height: 48,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:last-of-type': {
    border: 'none',
  },
});

const alertWrapperStyle = css({
  padding: 10,
});

export const AddDataColumnComponent: FC<AddDataColumnProps> = (props) => {
  const [columnName, setColumnName] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [t] = useTranslation();
  const theme = useTheme();

  const isSubmitDisabled = isTextEmpty(columnName) || props.isLoading;

  const onRowsChange = (index: number, newSettingRow: ChangeableSettingRow) => {
    const newValue = newSettingRow.data[1]?.value as string;

    switch (index) {
      case 0:
        setColumnName(newValue);
        break;
      case 1:
        setDefaultValue(newValue);
        break;
      default:
        return;
    }
  };

  const submitForm = () => {
    if (isSubmitDisabled) {
      return;
    }

    props.onSubmit(columnName, defaultValue);
  };

  const rows: EditDataTableRow[] = [{
    value: columnName,
    label: t('Column Name'),
  }, {
    value: defaultValue,
    label: t('Default Value (Optional)'),
  }];

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Add a New Column to Data Sheet')}
      contentStyle={modalContentStyle}
      confirmButton={(
        <ButtonComponent
          text={t('Save')}
          prefixIcon={faCheck}
          isDisabled={isSubmitDisabled}
          onClick={submitForm}
        />
      )}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
    >
      <EditDataTableComponent
        rows={rows}
        onChange={onRowsChange}
        customDataCellStyle={settingsTableDataCellStyle}
        customDataRowStyle={settingsTableDataRowStyle}
        onFormSubmit={submitForm}
      />

      <p css={defaultValueNoteStyle({ theme })}>
        {t('If left blank, all the new column cells will be empty by default.')}
      </p>

      {props.isError && (
        <div css={alertWrapperStyle}>
          <AlertComponent
            css={errorAlertStyle}
            type="danger"
          >
            {t('An error occurred while adding a new column')}
          </AlertComponent>
        </div>
      )}
    </ModalComponent>
  );
};
