import {
  type FC, useCallback,
  useEffect, useMemo,
} from 'react';
import { useCurrentGoogleAccountIdSelector } from '~/store/selectors/googleUserSelectors.selector';
import { type ModalProps } from '../../../modal/modalType.enum';
import { type GoogleSpreadsheetInfo } from '../../../spreadsheet/googleSpreadsheet/googleSheet.repository';
import { useSelectGoogleSpreadsheet } from '../../../spreadsheet/googleSpreadsheet/useSelectGoogleSpreadsheet.hook';
import { GoogleSpreadsheetSelectorComponent } from './googleSpreadsheetSelector.component';

export type GoogleSpreadsheetSelectorContainerProps = ModalProps<{
  onGoogleAuthError: () => void;
  onGoogleSpreadsheetSheetSelected: (googleSpreadsheetInfo: GoogleSpreadsheetInfo, sheetId: string) => void;
}>;

export const GoogleSpreadsheetSelectorContainer: FC<GoogleSpreadsheetSelectorContainerProps> = (props) => {
  const { onGoogleAuthError, onGoogleSpreadsheetSheetSelected, onClose } = props;

  const currentGoogleAccId = useCurrentGoogleAccountIdSelector();

  const getGoogleSpreadsheetsDataProps = useMemo(() => ({
    googleAccountId: currentGoogleAccId,
    onGoogleAuthError: props.onGoogleAuthError,
    onClose,
  }), [currentGoogleAccId, onClose, props.onGoogleAuthError]);

  const {
    errorMessage,
    isLoading,
    onSelectSpreadsheet,
    selectedGoogleSpreadsheetInfo,
  } = useSelectGoogleSpreadsheet(getGoogleSpreadsheetsDataProps);

  const handleSheetSelected = useCallback((spreadsheetId: string, sheetId?: string) => {
    if (selectedGoogleSpreadsheetInfo && sheetId !== undefined && sheetId !== null) {
      onClose();
      onGoogleSpreadsheetSheetSelected(selectedGoogleSpreadsheetInfo, sheetId);
    }
    else {
      onSelectSpreadsheet(spreadsheetId);
    }
  },
  [onClose, onSelectSpreadsheet, onGoogleSpreadsheetSheetSelected, selectedGoogleSpreadsheetInfo]);

  useEffect(() => {
    if (!currentGoogleAccId) {
      onGoogleAuthError();
    }
  }, [currentGoogleAccId, onGoogleAuthError]);

  useEffect(() => {
    if (currentGoogleAccId && selectedGoogleSpreadsheetInfo && selectedGoogleSpreadsheetInfo.sheets.length === 1 && selectedGoogleSpreadsheetInfo.sheets[0]) {
      // if there is only 1 sheet in a selected google spreadsheet, just continue with selecting that one
      onGoogleSpreadsheetSheetSelected(selectedGoogleSpreadsheetInfo, selectedGoogleSpreadsheetInfo.sheets[0].sheetId);
    }
  }, [currentGoogleAccId, onGoogleSpreadsheetSheetSelected, selectedGoogleSpreadsheetInfo]);

  if (!selectedGoogleSpreadsheetInfo) {
    return null;
  }

  return (
    <GoogleSpreadsheetSelectorComponent
      errorMessage={errorMessage}
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSelect={handleSheetSelected}
      selectedGoogleSpreadsheetInfo={selectedGoogleSpreadsheetInfo}
    />
  );
};
