import type { MapId } from '~/_shared/types/map';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { flatten } from '~/_shared/utils/collections/collections';
import { type LayeringType } from '~/map/map.repository';

export type BaseMapInfos = {
  [realSpreadsheetId: string]: {
    id: number;
    name: string;
  };
};

export const getLayeredMapBaseMaps = (
  layering?: LayeringType
): BaseMapInfos => {
  if (layering) {
    const baseMapsNames: BaseMapInfos = {};
    const baseMaps = layering?.baseMaps ?? [];
    for (const baseMap of baseMaps) {
      baseMapsNames[baseMap.spreadsheets[0].realSpreadsheets[0].realSpreadsheetId.toString()] = {
        id: baseMap.id,
        name: baseMap.name,
      };
    }
    return baseMapsNames;
  }
  return {};
};

export const getBaseMapNameFromId = (mapId?: string | null, maps?: BaseMapInfos) => {
  if (!maps) {
    return null;
  }

  return Object.values(maps).find(map => `${map.id}` === `${mapId}`)?.name || null;
};

export const getBaseMapColumnIdFromLayeredSpreadsheetColumnId = (
  baseMapId: MapId,
  layeredColumnId: ColumnId,
  layering: LayeringType,
): ColumnId | null => {
  const layeredColumnMatch = layering.matches.find(m => m.columnId === layeredColumnId);
  const baseMap = layering.baseMaps.find(b => b.id === baseMapId);

  if (baseMap && layeredColumnMatch) {
    const baseMapRealSpreadsheets = flatten(baseMap.spreadsheets.map(
      s => s.realSpreadsheets.map(rs => rs.realSpreadsheetId)
    ) || []);
    const matchedRealSpreadsheetId = baseMapRealSpreadsheets.find(s => !!layeredColumnMatch.intersects[`spreadsheet_${s}`]);
    if (matchedRealSpreadsheetId) {
      return layeredColumnMatch.intersects[`spreadsheet_${matchedRealSpreadsheetId}`] || null;
    }
  }

  return null;
};
