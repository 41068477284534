import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import {
  createDrawingToolOptionsFromDrawingToolSettings,
  createDrawingToolSettingsFromToolOptions,
} from '../../../../drawingTool/drawingTool.helpers';
import { type DrawingToolOptions } from '../../../../drawingTool/drawingTool.types';
import { useDrawingSettingsUpdate } from '../../../../drawingTool/hooks/useDrawingSettingsUpdate';
import { useDrawingEditSelectedDrawingLatestSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import {
  drawingToolClearActiveTool,
  drawingToolSetActiveDrawingTool,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
  useMapToolsDrawingActiveToolSelector,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { useDrawingSettingsSelector } from '../../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { DrawingToolComponent } from './drawingTool.component';

export const DrawingToolContainer: FC = () => {
  const activeDrawingTool = useMapToolsDrawingActiveToolSelector();
  const latestDrawingSnapshot = useDrawingEditSelectedDrawingLatestSnapshot();
  const drawingSettings = useDrawingSettingsSelector();
  const drawingToolMode = useDrawingToolModeSelector();

  const { updateDrawingToolGlobalSettings } = useDrawingSettingsUpdate();
  const dispatch = useDispatch();

  const managementDisabled = [DrawingToolMode.DrawingItems, DrawingToolMode.Paused].includes(drawingToolMode);
  const showEditLockOverlay = !!latestDrawingSnapshot && activeDrawingTool !== DrawingTool.Management;

  const onDrawingToolChange = (newTool: DrawingTool) => {
    if (newTool === DrawingTool.Management) {
      dispatch(drawingToolClearActiveTool());
    }
    else {
      dispatch(drawingToolSetActiveDrawingTool(newTool));
    }
  };

  const toolOptions = useMemo(() => {
    return createDrawingToolOptionsFromDrawingToolSettings(activeDrawingTool, drawingSettings);
  }, [activeDrawingTool, drawingSettings]);

  const onToolOptionsChange = (newOptions: Partial<DrawingToolOptions>) => {
    const newSettings = createDrawingToolSettingsFromToolOptions(
      activeDrawingTool,
      drawingSettings,
      newOptions
    );

    updateDrawingToolGlobalSettings(activeDrawingTool, newSettings);
  };

  // clear "add elements" mode on container unload
  useEffect(() => {
    dispatch(drawingToolClearActiveTool());

    return () => {
      dispatch(drawingToolClearActiveTool());
    };
  }, [dispatch]);

  return (
    <DrawingToolComponent
      isManagementDisabled={managementDisabled}
      selectedDrawingTool={activeDrawingTool}
      onDrawingToolChange={onDrawingToolChange}
      toolOptions={toolOptions}
      onToolOptionsChange={onToolOptionsChange}
      drawingToolMode={drawingToolMode}
      showEditLockOverlay={showEditLockOverlay}
    />
  );
};
