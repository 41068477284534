import { type UnitSystem } from '../_shared/types/googleMaps/googleMaps.types';
import {
  GroupRadiusEditLevel,
  ProximityType,
} from '../_shared/types/proximity/proximity.enums';
import { type ProximityModel } from '../_shared/types/proximity/proximity.types';
import { type SpreadsheetColumn } from '../_shared/types/spreadsheetData/spreadsheetColumn';
import { createUuid } from '../_shared/utils/createUuid';
import { type GeoLocation } from '../_shared/utils/geolocation/geolocation';
import { type GroupData } from '../store/spreadsheetData/spreadsheetData.state';
import { parseDriveTime } from './proximity.helpers';

export type ProximityRequestCreateObject = {
  type: ProximityType;
  applyTo?: GroupRadiusEditLevel;
  unit: UnitSystem;
  startingLocation: GeoLocation;
  distance: number | null;
  selectedGroupColumn?: SpreadsheetColumn;
  selectedGroup?: GroupData | null;
  travelTimeMinutes: number | null;
  travelTimeHours: number | null;
  fillColor: string;
  fillOpacity: number;
  borderOpacity: number;
  borderColor: string;
  borderWidth: number;
  markerId?: number;
};

export const createProximityRequest = ({ type, applyTo, fillColor, fillOpacity, borderColor, borderWidth,
  startingLocation, unit, distance, selectedGroup, selectedGroupColumn, borderOpacity,
  travelTimeMinutes, travelTimeHours }: ProximityRequestCreateObject): ProximityModel => {
  const id = createUuid();
  if (type === ProximityType.DriveTimePolygon) {

    const { hours, minutes } = parseDriveTime(travelTimeHours || 0, travelTimeMinutes || 0);

    return {
      id,
      name: startingLocation.address || `${startingLocation.latLng.lat}, ${startingLocation.latLng.lng}`,
      type: ProximityType.DriveTimePolygon,
      styles: {
        color: fillColor,
        borderColor,
        borderWidth,
        fillOpacity,
        borderOpacity,
      },
      data: {
        address: startingLocation.address,
        hours,
        minutes,
        lat: startingLocation.latLng.lat,
        lng: startingLocation.latLng.lng,
      },
    };
  }

  if (type === ProximityType.DistanceRadius && applyTo === GroupRadiusEditLevel.IndividualLocation) {
    return {
      id,
      name: startingLocation.address || `${startingLocation.latLng.lat}, ${startingLocation.latLng.lng}`,
      type: ProximityType.DistanceRadius,
      styles: {
        color: fillColor,
        borderColor,
        borderWidth,
        fillOpacity,
        borderOpacity,
      },
      data: {
        applyTo: GroupRadiusEditLevel.IndividualLocation,
        data: {
          address: startingLocation.address,
          lat: startingLocation.latLng.lat,
          lng: startingLocation.latLng.lng,
        },
        radius: distance || 0,
        unit,
      },
    };
  }

  if (type === ProximityType.DistanceRadius && applyTo === GroupRadiusEditLevel.Group && selectedGroupColumn && selectedGroup) {
    return {
      id,
      name: selectedGroup.label,
      type: ProximityType.DistanceRadius,
      styles: {
        color: fillColor,
        borderColor,
        borderWidth,
        fillOpacity,
        borderOpacity,
      },
      data: {
        applyTo: GroupRadiusEditLevel.Group,
        data: {
          columnId: {
            columnId: selectedGroupColumn.id,
            spreadsheetId: selectedGroupColumn.spreadsheetId,
          },
          group: selectedGroup.id,
          individualOverride: {},
        },
        radius: distance || 0,
        unit,
      },
    };
  }

  throw Error('Unexpected Proximity Request');
};
