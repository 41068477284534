import { type GeocodingResponse } from '~/_shared/types/geocoding/geocoding.response';
import { type SpreadsheetDescriptor } from '../../_shared/types/map';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { type MapInfoAction } from '../mapInfo/mapInfo.action';
import { MAP_INFO_FETCH_DATA_SUCCESS } from '../mapInfo/mapInfo.actionTypes';
import { type GeocodingAction } from './geocoding.action';
import {
  GEOCODING_PAUSE_ERROR,
  GEOCODING_PAUSE_SUCCESS,
  GEOCODING_RESUME_ERROR,
  GEOCODING_RESUME_SUCCESS,
  GEOCODING_SET_IS_PAUSE_RESUME_LOADING,
  GEOCODING_SET_PROGRESS,
  GEOCODING_START_TRACKING,
} from './geocoding.actionTypes';
import {
  type GeocodingState, initializeSpreadsheetWithPresetValues,
  pauseRequest,
  resumeRequest,
  setRequestLoading,
  unsetRequestLoading,
  updateSpreadsheetProgress,
} from './geocoding.state';

const initialState: GeocodingState = {
  perSpreadsheetGeocodingRequest: {},
};

export const geocodingReducer = (state = initialState, action: GeocodingAction | MapInfoAction): GeocodingState => {
  switch (action.type) {
    case MAP_INFO_FETCH_DATA_SUCCESS: {
      return loadOngoingProgress(state, action);
    }

    case GEOCODING_START_TRACKING: {
      const existingProgress = state.perSpreadsheetGeocodingRequest[action.payload.realSpreadsheetId];
      const progressDoesntExistOrIsComplete = !existingProgress || !existingProgress.inProgress;

      if (progressDoesntExistOrIsComplete) {
        return updateSpreadsheetProgress(
          state, {
            [action.payload.realSpreadsheetId]: {
              realSpreadsheetId: action.payload.realSpreadsheetId,
              progress: action.payload.startingPercent,
              triggeredByUserId: action.payload.triggeredByUserId,
            },
          },
        );
      }

      return state;
    }

    case GEOCODING_SET_PROGRESS: {
      return updateSpreadsheetProgress(state, action.payload.updates);
    }

    case GEOCODING_PAUSE_SUCCESS: {
      return pauseRequest(unsetRequestLoading(state, action.payload.realSpreadsheetId), action.payload.realSpreadsheetId);
    }

    case GEOCODING_RESUME_SUCCESS: {
      return resumeRequest(unsetRequestLoading(state, action.payload.realSpreadsheetId), action.payload.realSpreadsheetId);
    }

    case GEOCODING_SET_IS_PAUSE_RESUME_LOADING: {
      return setRequestLoading(state, action.payload.realSpreadsheetId);
    }

    case GEOCODING_PAUSE_ERROR:
    case GEOCODING_RESUME_ERROR: {
      return unsetRequestLoading(state, action.payload.realSpreadsheetId);
    }

    default: {
      return state;
    }
  }
};

function getProgress(realSpreadsheetId: number, geocoding: GeocodingResponse) {
  const spreadGeocodingKey = `real-spreadsheet-${realSpreadsheetId}`;
  const geocodingDataForSpreadsheet = geocoding.geocodings[spreadGeocodingKey];

  return { percentage: geocodingDataForSpreadsheet?.percentage, triggeredByUserId: geocodingDataForSpreadsheet?.caller?.id };
}

function loadOngoingProgress(state: GeocodingState, action: PickAction<MapInfoAction, typeof MAP_INFO_FETCH_DATA_SUCCESS>): GeocodingState {
  const spreadsheets: ReadonlyArray<SpreadsheetDescriptor> | undefined = action.payload.data.spreadsheets;
  const spreadsheetData: Maybe<SpreadsheetDescriptor> = spreadsheets?.find(spreadsheet => spreadsheet.isPrimary);

  const geocoding = spreadsheetData?.geocoding;

  if (!spreadsheetData || !geocoding || !(geocoding.in_progress || geocoding.is_required)) {
    return state;
  }

  const updatedState = spreadsheetData.realSpreadsheets.reduce((acc, spreadsheet) => {
    const realSpreadsheetId = spreadsheet.realSpreadSheetId;
    const maybeProgress = getProgress(realSpreadsheetId, geocoding);

    return initializeSpreadsheetWithPresetValues(
      acc,
      realSpreadsheetId,
      {
        paused: { isPaused: geocoding.is_paused, changeRequestSent: false },
        progress: maybeProgress.percentage ?? 0,
        inProgress: geocoding.in_progress,
        isRequired: geocoding.is_required,
        triggeredByUserId: maybeProgress.triggeredByUserId ?? null,
      },
    );
  }, state);

  return updatedState;
}
