import { css } from '@emotion/react';
import {
  forwardRef, type PropsWithChildren,
} from 'react';
import type { Theme } from '~/_shared/themes/theme.model';

export const GROUPING_ITEM_ROW_HEIGHT = 45;

const columnItemStyle = (theme: Theme) => css({
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  height: GROUPING_ITEM_ROW_HEIGHT,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.textColors.primary,
  backgroundColor: theme.backgroundColors.primary,

  '& + &': {
    borderTop: `1px solid ${theme.borderColors.primary}`,
  },
});

type GroupingItemBodyRowWrapperComponent = PropsWithChildren<Readonly<{
  className?: string;
}>>;

export const GroupingItemBodyRowWrapperComponent = forwardRef<HTMLDivElement, GroupingItemBodyRowWrapperComponent>(({
  className,
  children,
}, ref) => {
  return (
    <div
      css={columnItemStyle}
      className={className}
      ref={ref}
    >
      {children}
    </div>
  );
});
