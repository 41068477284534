import { useCallback } from 'react';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GoogleSheetMapSyncType } from '~/map/map.repository';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { usePrimaryGoogleSpreadsheetInfo } from '~/store/selectors/useMapInfoSelectors';
import { useReconnectGoogleSheet } from './useReconnectGoogleSheet.hook';

export type GoogleSyncProps = {
  preselectedSyncMethod?: GoogleSheetMapSyncType;
};

export const useSyncGoogleSheet = () => {
  const [t] = useTranslation();
  const googleSheetSpreadsheetInfo = usePrimaryGoogleSpreadsheetInfo();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const reconnectGoogleSheet = useReconnectGoogleSheet();
  const { openModal: openSyncDataModal } = useModal(ModalType.SyncGoogleSheet);

  const openUserMustReselectGoogleSpreadsheetWarning = useCallback((onConfirm: () => void) => {
    const onCloseOrCancel = () => {
      closeConfirmationModal();
    };

    openConfirmationModal({
      confirmCaption: t('Proceed'),
      onCancel: onCloseOrCancel,
      onClose: onCloseOrCancel,
      onConfirm,
      text: (
        <div>
          <div>{t('googleSheets.sync.lostConnectionToGoogleSheet.warning')}</div>
          <div>&nbsp;</div>
          <div>{t('googleSheets.sync.lostConnectionToGoogleSheet.instructions{{spreadsheetName}}', {
            spreadsheetName: googleSheetSpreadsheetInfo?.spreadsheetTitle,
          })}
          </div>
        </div>
      ),
      title: t('Reconnect Google Spreadsheet'),
    });
  }, [closeConfirmationModal, googleSheetSpreadsheetInfo?.spreadsheetTitle, openConfirmationModal, t]);

  return useCallback((props: GoogleSyncProps) => {
    if (googleSheetSpreadsheetInfo) {
      const googleAccountId = googleSheetSpreadsheetInfo.googleAccountId;
      if (googleAccountId) {
        openSyncDataModal({
          ...props,
          googleSheetSpreadsheetInfo: {
            ...googleSheetSpreadsheetInfo,
            googleAccountId,
          },
        });
        return;
      }
      else {
        openUserMustReselectGoogleSpreadsheetWarning(() => {
          reconnectGoogleSheet();
        });
        return;
      }
    }
  }, [googleSheetSpreadsheetInfo, reconnectGoogleSheet, openSyncDataModal, openUserMustReselectGoogleSpreadsheetWarning]);
};
