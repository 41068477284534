import { type SpreadsheetColumnId } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type PresentationalColumnsRestrictionFlags } from '../../presentationalColumnsRestrictions/presentationalColumnsRestrictions.repository';
import {
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_ERROR,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_SUCCESS,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_ERROR, PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_SUCCESS,
} from './presentationalColumnsRestrictions.actionTypes';
import { type PresentationalColumnsRestrictionsStateRestrictions } from './presentationalColumnsRestrictions.state';

type RestrictionUpdateItem = {
  restrictionId: number;
  spreadsheetColumnId: SpreadsheetColumnId;
  restrictionFlags: PresentationalColumnsRestrictionFlags;
};

export const presentationalColumnsRestrictionsGetDataRequest = (
  clientId: number,
  mapId: number
) => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST,
  payload: {
    clientId,
    mapId,
  },
}) as const;

export const presentationalColumnsRestrictionsGetDataError = () => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_ERROR,
}) as const;

export const presentationalColumnsRestrictionsGetDataSuccess = (
  restrictions: PresentationalColumnsRestrictionsStateRestrictions
) => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_SUCCESS,
  payload: {
    restrictions,
  },
}) as const;

export const presentationalColumnsRestrictionsUpdateError = () => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_ERROR,
}) as const;

export const presentationalColumnsRestrictionsUpdateSuccess = (updates: RestrictionUpdateItem[]) => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_UPDATE_SUCCESS,
  payload: {
    updates,
  },
}) as const;

export const presentationalColumnsRestrictionsSetRestriction = (
  spreadsheetColumnId: SpreadsheetColumnId,
  restrictionFlag: keyof PresentationalColumnsRestrictionFlags,
  value: boolean
) => ({
  type: PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST,
  payload: {
    spreadsheetColumnId,
    restrictionFlag,
    value,
  },
}) as const;
