import { ShareMapSection } from './shareMap.component';

export enum ShareInputField {
  Password = 'privacy_password',
  MapName = 'name',
}

export const sectionToFieldMapping: ReadonlyMap<ShareMapSection, ShareInputField> = new Map([
  [ShareMapSection.PrivacyLevel, ShareInputField.Password],
  [ShareMapSection.StartingMap, ShareInputField.MapName],
]);
