import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  s, Trans,
} from '~/translations/Trans';

// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
export const hideReCaptchaBadgeStyles = css({ '.grecaptcha-badge': { visibility: 'hidden' } });

const brandingStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  fontSize: 14,
  lineHeight: '114%',
});

export const ReCaptchaBrandingComponent: FC<{className?: string}> = ({ className }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={brandingStyle({ theme })}
    >
      <Trans i18nKey="This site is protected by reCAPTCHA and the Google <1>Privacy Policy</1> and <3>Terms of Service</3> apply.">
        {s('This site is protected by reCAPTCHA and the Google ')}
        <LinkComponent path="https://policies.google.com/privacy">{s('Privacy Policy')}</LinkComponent>{s(' and ')}
        <LinkComponent path="https://policies.google.com/terms">{s('Terms of Service')}</LinkComponent>{s(' apply.')}
      </Trans>
    </div>
  );
};
