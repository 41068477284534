import {
  useCallback, useMemo,
} from 'react';
import { useMarkerLibraryImages } from '~/_shared/components/imageLibrary/useMarkerLibraryImages';
import { parseImageUrlsFromValue } from '~/_shared/types/spreadsheetData/spreadsheetData.parsers';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useFileAttachmentUrls } from '~/_shared/utils/files/useFileUrls';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type SpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';

export type LocationImage = Readonly<{
  original: string;
  thumbnail: string;
}>;

export const useLocationImages = (
  activeMarkerId: SpreadsheetRowId | undefined,
  spreadsheetRowData: SpreadsheetRowData | null,
) => {
  const matchupData = useMatchupDataSelector();
  const fileUrlsLookup = useFileAttachmentUrls();
  const { currentMarkerImages } = useMarkerLibraryImages({ markerId: activeMarkerId });
  const { openModal: openImageViewModal } = useModal(ModalType.ImagesView);

  const matchupDataForActiveMarkerSpreadsheet = useMemo(() => {
    if (
      activeMarkerId
      && !matchupData[activeMarkerId?.spreadsheetId]?.isLoading
      && matchupData[activeMarkerId?.spreadsheetId]?.data
    ) {
      return matchupData[activeMarkerId?.spreadsheetId]?.data;
    }
    return null;
  }, [activeMarkerId, matchupData]);

  const spreadsheetImagesUrls = useMemo(() => {
    if (
      matchupDataForActiveMarkerSpreadsheet && matchupDataForActiveMarkerSpreadsheet.categories.image_url.match
      && spreadsheetRowData && spreadsheetRowData.columnsData[matchupDataForActiveMarkerSpreadsheet.categories.image_url.match]
    ) {
      return parseImageUrlsFromValue(
        spreadsheetRowData.columnsData[matchupDataForActiveMarkerSpreadsheet.categories.image_url.match]
      );
    }
    return [];
  }, [matchupDataForActiveMarkerSpreadsheet, spreadsheetRowData]);

  const images = useMemo(() => {
    const galleryImages: LocationImage[] = [];

    spreadsheetImagesUrls.forEach(url => galleryImages.push({
      original: url,
      thumbnail: url,
    }));

    for (const fileAttachmentId of currentMarkerImages) {
      if (fileAttachmentId && fileUrlsLookup.fileAttachmentIdsToUrls.has(fileAttachmentId)) {
        const fileUrl = fileUrlsLookup.fileAttachmentIdsToUrls.get(fileAttachmentId);
        if (fileUrl) {
          galleryImages.push({
            original: fileUrl.original.url,
            thumbnail: fileUrl['200p'].url,
          });
        }
      }
    }

    return galleryImages;
  }, [currentMarkerImages, fileUrlsLookup.fileAttachmentIdsToUrls, spreadsheetImagesUrls]);

  const openImagesView = useCallback((imageIndex: number) => {
    openImageViewModal({
      startingImageIndex: imageIndex,
      images,
    });
  }, [openImageViewModal, images]);

  return useMemo(() => ({
    images,
    openImagesView,
  }), [images, openImagesView]);
};
