import {
  type FC, useEffect,
} from 'react';
import {
  type RouteProps, useLocation, useNavigate,
} from 'react-router-dom';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import { LoginFormContainer } from './loginForm/loginForm.container';

type LoginPageProps = RouteProps & {
  isLoading?: boolean;
  isUserSignedIn: boolean;

  onLoginFormSubmit: () => void;
};

export const LoginPageComponent: FC<LoginPageProps> = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isUserSignedIn) {
      navigate(state?.from ?? '/');
    }
  }, [navigate, props.isUserSignedIn, state?.from]);

  return (
    <AuthorizationPageWrapperComponent>
      {props.isLoading && <OverlayLoaderComponent />}
      {!props.isLoading && <LoginFormContainer onLoginFormSubmit={props.onLoginFormSubmit} />}
    </AuthorizationPageWrapperComponent>
  );
};
