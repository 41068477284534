import {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  Page, PageTitle,
} from '~/_shared/constants/routes';
import { useTranslation } from '~/_shared/utils/hooks';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';

export const useMapTitleResolver = () => {
  const currentPage = useGetCurrentPageInfo();
  const currentMapName = useMapInfoDataSelector()?.name;
  const [t] = useTranslation();

  const headerPlaceholder = t('Maptive');

  const wrapWithMaptiveTitle = useCallback((pageTitle: string) => (
    `${headerPlaceholder} | ` + pageTitle
  ), [headerPlaceholder]);

  const title = useMemo(() => {
    if (currentPage.page === Page.PRESENTATIONAL) {
      if (currentMapName === undefined || currentMapName === null) { // can happen when the map is locked with password
        return headerPlaceholder;
      }
      return wrapWithMaptiveTitle(t(PageTitle.PRESENTATIONAL, { mapName: currentMapName }));
    }
    else if (currentPage.page === Page.ADMIN_DATA) {
      return wrapWithMaptiveTitle(t(PageTitle.DATA));
    }
    else if (currentPage.page === Page.ADMIN_MAP) {
      return wrapWithMaptiveTitle(t(PageTitle.MAP));
    }
    else if (currentPage.page === Page.ADMIN_HOME) {
      return wrapWithMaptiveTitle(t(PageTitle.HOME));
    }
    else if (currentPage.page === Page.SUBSCRIBE) {
      return wrapWithMaptiveTitle(t(PageTitle.SUBSCRIBE));
    }
    else if (currentPage.page === Page.SUBSCRIBED) {
      return wrapWithMaptiveTitle(t(PageTitle.CONGRATULATIONS));
    }
    else if (currentPage.page === Page.LOGIN) {
      return wrapWithMaptiveTitle(t(PageTitle.LOGIN));
    }
    else {
      return headerPlaceholder;
    }
  }, [currentMapName, currentPage.page, headerPlaceholder, t, wrapWithMaptiveTitle]);

  useEffect(() => {
    document.title = title;
  }, [title]);
};
