import { resetHeatMapItems } from '../../../heatMap/panel/heatMapPanel.helpers';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../data/mapSettingsData.actionTypes';
import { type MapSettingsHeatmapsAction } from './mapSettingsHeatmaps.action';
import {
  MAP_SETTINGS_HEATMAP_ADD_HEATMAP,
  MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP,
  MAP_SETTINGS_HEATMAP_RESET_HEATMAPS,
  MAP_SETTINGS_HEATMAP_UPDATE_HEATMAP,
} from './mapSettingsHeatmaps.actionTypes';
import { type MapSettingsHeatmapsState } from './mapSettingsHeatmaps.state';

export const mapSettingsHeatmapsInitialState: MapSettingsHeatmapsState = {
  heatmaps: [],
};

export const mapSettingsHeatmapsReducer = (
  state = mapSettingsHeatmapsInitialState,
  action: MapSettingsHeatmapsAction | MapSettingsDataAction,
): MapSettingsHeatmapsState => {
  switch (action.type) {
    case MAP_SETTINGS_HEATMAP_ADD_HEATMAP: {
      return {
        ...state,
        heatmaps: [...state.heatmaps, action.payload.heatmap],
      };
    }

    case MAP_SETTINGS_HEATMAP_UPDATE_HEATMAP: {
      const updated = action.payload.heatmap;
      return {
        ...state,
        heatmaps: state.heatmaps.map(h => h.id === updated.id ? updated : h),
      };
    }

    case MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP: {
      return {
        ...state,
        heatmaps: state.heatmaps.filter(h => h.id !== action.payload.heatmap.id),
      };
    }

    case MAP_SETTINGS_HEATMAP_RESET_HEATMAPS: {
      return {
        ...state,
        heatmaps: resetHeatMapItems(state.heatmaps),
      };
    }

    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS: {
      return {
        ...state,
        heatmaps: [],
      };
    }

    default:
      return state;
  }
};
