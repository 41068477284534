import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Filter]: {
  views: {
    default: {
      canvasHeight: 515,
      canvasWidth: 515,
      iconWidth: 515,
      iconHeight: 515,
    },
  },
  colors: {
    filterBorder: '#FF5C00',
    filterFill: '#FFFFFF',
    filterShadow: '#D4E1F4',
    bubble: '#000000',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 133] as const,
    filtering: [0, 75] as const,
    short: [0, 100] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/59895-filter" */
      './59895-filter.json'
    ),
} };

export const { Filter } = config;
