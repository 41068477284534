import { useMemo } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type LottieAnimations } from './animations/lottieAnimations';
import {
  type LottieAnimationType, LottieAnimationTypes,
} from './lottieAnimation.types';

type LottieColors = {
  [AnimationType in LottieAnimationType]?: Partial<typeof LottieAnimations[AnimationType]['colors']>;
};

export const useLottieAnimationDefaultColors = () => {
  const theme = useTheme();

  return useMemo<LottieColors>(() => ({
    [LottieAnimationTypes.CircleDownload]: {
      fillFrom: theme.backgroundColors.primary,
      borderFrom: theme.iconColors.borderPrimaryHover,
      fillTo: theme.iconColors.backgroundSuccessInverted,
      borderTo: theme.iconColors.backgroundSuccessInvertedHover,
      tick: theme.iconColors.tertiary,
      downloadIcon: theme.iconColors.secondary,
    },
    [LottieAnimationTypes.Copy]: {
      frontFill: theme.backgroundColors.secondary,
      frontBorder: theme.iconColors.contrast,
      backBorder: theme.iconColors.active,
    },
    [LottieAnimationTypes.Delete]: {
      borderFrom: theme.iconColors.danger,
      trash: theme.iconColors.danger,
      tick: theme.iconColors.success,
      borderTo: theme.iconColors.success,
    },
    [LottieAnimationTypes.DrippingSpinner]: {
      fill: theme.iconColors.contrast,
    },
    [LottieAnimationTypes.Filter]: {
      filterBorder: theme.iconColors.contrast,
      filterShadow: theme.iconColors.highlight,
      filterFill: theme.iconColors.backgroundSecondary,
      bubble: theme.iconColors.primary,
    },
    [LottieAnimationTypes.Layer]: {
      layer1: theme.iconColors.contrast,
      layer2: theme.iconColors.highlight,
      layer3: theme.iconColors.contrast,
    },
    [LottieAnimationTypes.LocationPin3D]: {
      pin: theme.iconColors.contrast,
    },
    [LottieAnimationTypes.People]: {
      frontPerson: theme.iconColors.active,
      backgroundPeople: theme.borderColors.activeItem,
    },
    [LottieAnimationTypes.Saving]: {
      arrow: theme.iconColors.contrast,
      cloud: theme.iconColors.contrast,
    },
    [LottieAnimationTypes.Success]: {
      border: theme.iconColors.success,
      fill: theme.iconColors.success,
      tick: theme.backgroundColors.primary,
    },
    [LottieAnimationTypes.UploadingImage]: {
      watermark: theme.iconColors.active,
      visor: theme.contrasts.selectedAccent2,
      tick: theme.contrasts.selectedAccent2,
    },
    [LottieAnimationTypes.Failed]: {
      fill: theme.iconColors.danger,
      border: theme.buttonColors.dangerBackgroundHover,
    },
  }), [theme]);
};
