import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { removeHash } from '../../_shared/components/colorPicker/colorPicker.helpers';

type HeatMapItemPresetColor = {
  dense: string;
  medium: string;
  light: string;
  single: string;
};

const presetColors: HeatMapItemPresetColor[] = [{
  dense: 'FF0000',
  medium: 'FFFF00',
  light: '00FF00',
  single: 'FF0000',
}, {
  dense: '00FF00',
  medium: '0002FF',
  light: '00C8FF',
  single: '0000FF',
}, {
  dense: '000080',
  medium: '0000FF',
  light: '00BFFF',
  single: '000080',
}, {
  dense: 'FF00FF',
  medium: 'EE82EE',
  light: 'FFC0CB',
  single: 'FF00FF',
}, {
  dense: 'A52A2A',
  medium: '8B4513',
  light: 'F4A460',
  single: 'A52A2A',
}, {
  dense: '2F4F4F',
  medium: '696969',
  light: 'A9A9A9',
  single: '2F4F4F',
}];

export const resetHeatMapItems = (items: ReadonlyArray<HeatMap>): ReadonlyArray<HeatMap> =>
  items.map((item, index) => {
    const presetColor = presetColors[index % presetColors.length];

    return {
      ...item,
      radius: 50,
      opacity: 50,
      threshold: 50,
      isGradient: true,
      denseColor: presetColor.dense,
      mediumColor: presetColor.medium,
      lightColor: presetColor.light,
      singleColor: presetColor.single,
    };
  });

export const getHeatMapPresetColor = (usedColors: string[]): HeatMapItemPresetColor =>
  presetColors
    .find(c => usedColors.every(usedC => removeHash(usedC.toLowerCase()) !== removeHash(c.single.toLowerCase())))
  ?? presetColors[getRandomIndex(presetColors.length - 1)];

const getRandomIndex = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));
