type RegisteredWorker = Readonly<{
  url: () => Worker;
}>;

export const Workers = {
  SimplifyDriveTimePaths: {
    url: () => new Worker(new URL('~/store/frontendState/proximityDriveTimePaths/simplifyDriveTimePaths.worker.ts', import.meta.url)),
  },
};

export const startWorker = (worker: RegisteredWorker) => worker.url();
