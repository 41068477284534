import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  getTextInputHeight, InputSize,
} from '~/_shared/baseComponents/inputs';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';

type AuthorizationSubmitButtonProps = Readonly<{
  className?: string;
  isLoading: boolean;
  isDisabled: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}>;

const buttonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 20,
  height: getTextInputHeight(InputSize.Large),
  width: '100%',
});

const loaderStyle = css({
  marginRight: 20,
});

export const AuthorizationSubmitButtonComponent: FC<AuthorizationSubmitButtonProps> = (props) => {
  return (
    <ButtonComponent
      isSubmit
      className={props.className}
      css={buttonStyle}
      onClick={props.onClick}
      text={(style) => (
        <>
          {props.isLoading && (
            <LoaderComponent
              color={style.textColor}
              css={loaderStyle}
              size={22}
            />
          )}
          {props.children}
        </>
      )}
      isDisabled={props.isDisabled || props.isLoading}
    />
  );
};
