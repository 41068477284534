import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LOGIN_ROUTE } from '../../_shared/constants/routes';
import { useTranslation } from '../../_shared/utils/hooks';
import { authorizationPagesLinkStyle } from '../authorizationPages.styles';
import { PasswordResetFormComponent } from './passwordResetForm.component';

const linksWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

type PasswordResetPageComponentProps = Readonly<{
  error: string;
  isLoading: boolean;
  onSubmit: (password: string, confirmPassword: string, callback: () => void) => void;
}>;

export const PasswordResetPageComponent: FC<PasswordResetPageComponentProps> = (props) => {
  const { onSubmit, isLoading, error } = props;
  const [t] = useTranslation();

  return (
    <div>
      <PasswordResetFormComponent
        isLoading={isLoading}
        onSubmit={onSubmit}
        error={error}
      />

      <div css={linksWrapperStyle}>
        <LinkComponent
          css={authorizationPagesLinkStyle}
          path={LOGIN_ROUTE}
        >
          {t('Back to Login')}
        </LinkComponent>
      </div>
    </div>
  );
};
