import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import {
  acceptInvitationRequest,
  getInvitationDataRequest,
  inviteeInvitationResponseToInviteeInvitation, rejectInvitationRequest,
} from './inviteeInvitation.repository';

export const useGetInvitationData = (
  email: string,
  hash: string,
  signature: string,
  expires: string
) => {
  const getInvitation = useCallback(
    () => getInvitationDataRequest(email, hash, signature, expires),
    [expires, hash, email, signature]
  );
  const { isLoading, error, data, invokeAjax } = useAjaxCall(getInvitation);

  const convertedData = data ? inviteeInvitationResponseToInviteeInvitation(data) : null;

  return {
    isLoading,
    error,
    data: convertedData,
    invokeAjax,
  };
};

export const useAcceptInvitation = () => {
  const update = useCallback(async (
    email: string, hash: string, signature: string, expires: string, password?: string, passwordConfirmation?: string | undefined
  ) => {
    return await acceptInvitationRequest(email, hash, signature, expires, password, passwordConfirmation);
  }, []);
  const { isLoading, error, invokeAjax } = useAjaxCall(update);

  return {
    invoke: invokeAjax,
    error,
    isLoading,
  };
};

export const useRejectInvitation = () => {
  const update = useCallback(
    async (email: string, hash: string, signature: string, expires: string) => {
      await rejectInvitationRequest(email, hash, signature, expires);
    },
    []
  );
  const { isLoading, error, data, invokeAjax } = useAjaxCall(update);

  return {
    invoke: invokeAjax,
    error,
    data,
    isLoading,
  };
};
