import {
  useCallback, useEffect, useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  mapComponentSetCenter,
  mapComponentSetCenterAndZoom,
  mapComponentSetZoom,
  mapComponentSetZoomToBounds,
} from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { frontendStateProcessingSetFirstZoom } from '~/store/frontendState/processing/processing.actionCreators';
import { useIsGeocodingRunningOrRequired } from '~/store/geocoding/geocoding.selectors';
import { useMapSettingsExportImageSettingsModeSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { selectAllFilteredSpreadsheetRows } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import {
  useExportImageModeInitializedSelector, useFirstZoomDoneSelector,
} from '../../store/frontendState/processing/processing.selectors';
import { usePublicMapSettingsRestrictMapPanningSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useAreMapSettingsReadySelector } from '../../store/mapSettings/ready/mapSettingsReady.selectors';
import {
  useMapSettingsSettingsInitialBoundsSelector,
  useMapSettingsSettingsInitialMapCenterAndZoomSelector,
} from '../../store/mapSettings/settings/mapSettingsSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { getBoundsAtLatLngWithZoom } from '../map.helpers';
import { BoundingBox } from './boundingBox';
import {
  useCallOnceTheMapIsLoaded,
  useMapContext,
} from './mapContext';
import {
  useLatLngSpreadsheetData, useSpreadSheetData,
} from './useSpreadsheetData.hook';

const useSetBoundsCallbacks = () => {
  const dispatch = useDispatch();

  const setBounds = useCallback((boundingBox: BoundingBox, preferredZoom?: number) => {
    dispatch(mapComponentSetZoomToBounds(boundingBox, { preferredZoom }));
  }, [dispatch]);

  const setZoom = useCallback((zoom: number) => {
    dispatch(mapComponentSetZoom(zoom));
  }, [dispatch]);

  const setCenter = useCallback((center: LatLng) => {
    dispatch(mapComponentSetCenter(center));
  }, [dispatch]);

  const setCenterAndZoom = useCallback((center: LatLng, zoom: number) => {
    dispatch(mapComponentSetCenterAndZoom(center, zoom));
  }, [dispatch]);

  return useMemo(() => ({
    setBounds, setZoom, setCenter, setCenterAndZoom,
  }), [setCenter, setBounds, setZoom, setCenterAndZoom]);
};

const useAreMarkersReady = (): boolean => {
  const areMapSettingsReady = useSelector(s => s.map.mapSettings.isReady);
  const areSpreadsheetDataReady = useSpreadSheetData().areLoaded;
  const areCustomMarkerAttachmentsLoaded = useSelector(s => s.frontendState.processing.areFileAttachmentsSynced);
  const isGeocodingInProgress = useIsGeocodingRunningOrRequired();

  return areMapSettingsReady && areSpreadsheetDataReady && areCustomMarkerAttachmentsLoaded && !isGeocodingInProgress;
};

const getInitialZoomMapBounds = (map: google.maps.Map, markers: readonly Readonly<LatLng & SpreadsheetRowId>[]) => {
  if (markers?.length) {
    const newBounds = new BoundingBox();

    markers
      .forEach(marker => newBounds.extend({
        lat: marker.lat,
        lng: marker.lng,
      }));

    return newBounds;
  }
  else {
    return new BoundingBox(map.getBounds());
  }
};

export const useZoomOnFirstLoad = () => {
  const dispatch = useDispatch();
  const { isMapLoaded, map } = useMapContext();
  const {
    registerCallback: callOnceTheMapIsLoaded,
    resetCalledFlag,
  } = useCallOnceTheMapIsLoaded();
  const areMapSettingsReady = useAreMapSettingsReadySelector();
  const firstZoomDone = useFirstZoomDoneSelector();
  const initialBounds = useMapSettingsSettingsInitialBoundsSelector();
  const initialMapCenterAndZoom = useMapSettingsSettingsInitialMapCenterAndZoomSelector();
  const setBoundsCallbacks = useSetBoundsCallbacks();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isPanningRestricted = usePublicMapSettingsRestrictMapPanningSelector();
  const hasIdleBeenCalled = useRef<boolean>(false);

  const filteredMarkers = useSelector(selectAllFilteredSpreadsheetRows);
  const allMarkers = useLatLngSpreadsheetData();
  const markersToZoomTo = filteredMarkers.length > 0 ? filteredMarkers : allMarkers.data;
  const areMarkersReady = useAreMarkersReady();

  const exportImageMode = useMapSettingsExportImageSettingsModeSelector();
  const exportImageModeInitialized = useExportImageModeInitializedSelector();

  const stateIsReadyForFirstZoom = areMapSettingsReady
    && (!isMapPresentational || !exportImageMode || exportImageModeInitialized);

  const setRestriction = useCallback((bounds: BoundingBox) => {
    if (isMapPresentational && isPanningRestricted) {
      map.setOptions({
        restriction: {
          latLngBounds: bounds.getGoogleMapsLatLng(),
        },
      });
    }
  }, [isMapPresentational, isPanningRestricted, map]);

  const dispatchFirstZoomWhenMapIsIdle = useCallback(() => {
    const callback = () => {
      const initialZoomLevel = map.getZoom() || 0;
      dispatch(frontendStateProcessingSetFirstZoom(initialZoomLevel));
    };

    if (!isMapLoaded) {
      // if map is not loaded yet, we want to fire the callback once the map is loaded
      callOnceTheMapIsLoaded(callback);
    }
    else {
      // if it is already loaded, then changing its bounds or zoom will trigger idle event
      hasIdleBeenCalled.current = false;
      const listener = google.maps.event.addListenerOnce(map, 'idle', () => {
        if (!hasIdleBeenCalled.current) {
          hasIdleBeenCalled.current = true;
          callback();
        }
      });
      // if within a second idle was not called, we will call the callback anyway
      // this might happen if the map was already in the desired spot
      setTimeout(() => {
        if (!hasIdleBeenCalled.current) {
          hasIdleBeenCalled.current = true;
          callback();
        }
        if (listener) {
          listener.remove();
        }
      }, 500);
    }

  }, [isMapLoaded, map, dispatch, callOnceTheMapIsLoaded]);

  useEffect(() => {
    if (!firstZoomDone) {
      resetCalledFlag();
    }
  }, [resetCalledFlag, firstZoomDone]);

  useEffect(() => {
    if (!map || firstZoomDone || !stateIsReadyForFirstZoom) {
      return;
    }

    if (initialBounds) {
      const bounds = new BoundingBox(new google.maps.LatLngBounds(initialBounds.sw, initialBounds.ne));
      setBoundsCallbacks.setBounds(bounds, initialMapCenterAndZoom?.zoom);
      setRestriction(bounds);
      dispatchFirstZoomWhenMapIsIdle();
      return;
    }

    if (initialMapCenterAndZoom) {
      setBoundsCallbacks.setCenterAndZoom(initialMapCenterAndZoom.center, initialMapCenterAndZoom.zoom);
      dispatchFirstZoomWhenMapIsIdle();

      if (isPanningRestricted) {
        const computedBounds = getBoundsAtLatLngWithZoom(map, initialMapCenterAndZoom.center, initialMapCenterAndZoom.zoom);
        if (computedBounds) {
          setRestriction(new BoundingBox(computedBounds));
        }
      }
      return;
    }

    // Set zoom bounds on markers load
    if (areMarkersReady) {
      const initialZoomBounds = getInitialZoomMapBounds(map, markersToZoomTo);
      setRestriction(initialZoomBounds);
      setBoundsCallbacks.setBounds(initialZoomBounds);
      dispatchFirstZoomWhenMapIsIdle();
    }
  }, [areMarkersReady, dispatchFirstZoomWhenMapIsIdle, firstZoomDone, initialBounds, initialMapCenterAndZoom,
    isPanningRestricted, map, markersToZoomTo, setBoundsCallbacks, setRestriction, stateIsReadyForFirstZoom]);

  return firstZoomDone;
};
