import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.CheckingPlatform]: {
  views: {
    default: {
      canvasHeight: 512,
      canvasWidth: 512,
      iconWidth: 512,
      iconHeight: 512,
    },
  },
  colors: {
    base: '#4F81A6',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [54, 202] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/16679-checking-platform.json" */
      './16679-checking-platform.json'
    ),
} };

export const { CheckingPlatform } = config;
