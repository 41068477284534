import { type FC } from 'react';
import { type MarkerSettings } from '../../_shared/types/markers/visualSettings.types';
import { type GetMarkerVisualWithBakedDataProps } from '../useGroupColumnData';
import { type BucketMarkerVisualType } from './numeric/numericalGroupSettingsNumeric.helpers';
import {
  NumericalGroupSettingsComponent, type NumericalGroupSettingsInitial, type NumericalGroupSettingsResults,
} from './numericalGroupSettings.component';

export type NumericalGroupSettingsContainerProps = Readonly<{
  isDecimal: boolean;
  isOpen: boolean;
  markerVisualRadioOptions: ReadonlyArray<BucketMarkerVisualType>;
  max: number;
  min: number;

  getExistingGroupVisualsIfAny: (bucketId: number, numberOfBuckets: number) => MarkerSettings | null;
  getMarkerVisuals: (props: GetMarkerVisualWithBakedDataProps) => MarkerSettings;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (results: NumericalGroupSettingsResults) => void;
  initialSettings?: NumericalGroupSettingsInitial;
}>;

export const NumericalGroupSettingsContainer: FC<NumericalGroupSettingsContainerProps> = (props) => {

  return (
    <NumericalGroupSettingsComponent
      getExistingGroupVisualsIfAny={props.getExistingGroupVisualsIfAny}
      getMarkerVisuals={props.getMarkerVisuals}
      isDecimal={props.isDecimal}
      isOpen={props.isOpen}
      markerVisualRadioOptions={props.markerVisualRadioOptions}
      max={props.max}
      min={props.min}
      onClose={() => {
        props.onClose();
        props.onCancel();
      }}
      onSubmit={props.onSubmit}
      initialSettings={props.initialSettings}
    />
  );
};
