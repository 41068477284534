import {
  all,
  call, put, takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { createPolygonGroupFromPolygonGroupsResponse } from '../../polygonGroup/polygonGroup.factory';
import {
  activatePolygonGroup, deactivatePolygonGroup,
  deletePolygonGroup as removePolygonGroup, getActivePolygonGroups,
  getPolygonGroups, type PolygonGroupCreateRequestData,
  type PolygonGroupCreateResponse,
  type PolygonGroupsResponse, postPolygonGroup,
} from '../../polygonGroup/polygonGroup.repository';
import {
  polygonGroupActivateError,
  polygonGroupActivateSuccess,
  polygonGroupCreateError,
  polygonGroupCreateSuccess,
  polygonGroupDeactivateError,
  polygonGroupDeactivateSuccess,
  polygonGroupDeleteError,
  polygonGroupDeleteSuccess,
  polygonGroupFetchActiveError,
  polygonGroupFetchActiveSuccess,
  polygonGroupsError,
  polygonGroupsSuccess,
} from './polygonGroups.actionCreators';
import { type PolygonGroupsActions } from './polygonGroups.actions';
import {
  POLYGON_GROUP_ACTIVATE_REQUEST,
  POLYGON_GROUP_CREATE_REQUEST,
  POLYGON_GROUP_DEACTIVATE_REQUEST,
  POLYGON_GROUP_DELETE_REQUEST, POLYGON_GROUP_FETCH_ACTIVE_REQUEST,
  POLYGON_GROUP_FETCH_ALL_REQUEST,
} from './polygonGroups.actionTypes';

function* fetchPolygonGroups(action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_FETCH_ALL_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot fetch polygon groups when no client is logged in.');
    }
    const resData: PolygonGroupsResponse = yield call(getPolygonGroups, clientId, action.payload.userId, true);
    yield put(polygonGroupsSuccess(createPolygonGroupFromPolygonGroupsResponse(resData.data.list)));
  }
  catch (e) {
    yield put(polygonGroupsError(e));
  }
}

export function* polygonGroupsFetchSagas() {
  yield takeLatest(POLYGON_GROUP_FETCH_ALL_REQUEST, fetchPolygonGroups);
}

function* createPolygonGroup(action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_CREATE_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot create polygon group when no client is logged in.');
    }
    const mapId: number | null = yield select(state => state.map.mapInfo.data?.id ?? null);
    if (mapId === null) {
      throw new Error('Cannot create polygon group when no map is loaded.');
    }

    const requestData: PolygonGroupCreateRequestData = {
      name: action.payload.polygonGroupName,
      userId: action.payload.userId,
    };
    const resData: PolygonGroupCreateResponse = yield call(postPolygonGroup, clientId, mapId, requestData);
    yield put(polygonGroupCreateSuccess({
      name: action.payload.polygonGroupName,
      territories: [],
      id: resData.group_id,
    }));
  }
  catch (e) {
    yield put(polygonGroupCreateError(e));
  }
}

export function* polygonGroupCreateSagas() {
  yield takeEvery(POLYGON_GROUP_CREATE_REQUEST, createPolygonGroup);
}

function* deletePolygonGroup(action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_DELETE_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot delete polygon group when no client is logged in.');
    }

    yield call(removePolygonGroup, clientId, action.payload.polygonGroupId);
    yield put(polygonGroupDeleteSuccess(action.payload.polygonGroupId));
  }
  catch (e) {
    yield put(polygonGroupDeleteError(e));
  }
}

export function* polygonGroupDeleteSagas() {
  yield takeEvery(POLYGON_GROUP_DELETE_REQUEST, deletePolygonGroup);
}

function* fetchActivePolygonGroupIds(_action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_FETCH_ACTIVE_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot get active polygon group ids when no client is logged in.');
    }
    const mapId: number | null = yield select(state => state.map.mapInfo.data?.id);
    if (mapId === null) {
      throw new Error('Cannot get active polygon group ids when no map is loaded.');
    }

    const resData: PolygonGroupsResponse = yield call(getActivePolygonGroups, clientId, mapId);
    yield put(polygonGroupFetchActiveSuccess(new Set(resData.data.list.map(g => g.id))));
  }
  catch (e) {
    yield put(polygonGroupFetchActiveError(e));
  }
}

export function* polygonGroupFetchActiveIdsSagas() {
  yield takeEvery(POLYGON_GROUP_FETCH_ACTIVE_REQUEST, fetchActivePolygonGroupIds);
}

function* attachPolygonGroupToMap(action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_ACTIVATE_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot attach polygon group when no client is logged in.');
    }
    const mapId: number | null = yield select(state => state.map.mapInfo.data?.id);
    if (mapId === null) {
      throw new Error('Cannot attach polygon group to a map when no map is loaded.');
    }

    yield call(activatePolygonGroup, clientId, mapId, action.payload.polygonGroupId);
    yield put(polygonGroupActivateSuccess(action.payload.polygonGroupId));
  }
  catch (e) {
    yield put(polygonGroupActivateError(e));
  }
}

export function* polygonGroupAttachSagas() {
  yield takeEvery(POLYGON_GROUP_ACTIVATE_REQUEST, attachPolygonGroupToMap);
}

function* detachPolygonGroupToMap(action: PickAction<PolygonGroupsActions, typeof POLYGON_GROUP_DEACTIVATE_REQUEST>) {
  try {
    const clientId: number | null = yield select(state => state.userData.clientId);
    if (clientId === null) {
      throw new Error('Cannot detach polygon group when no client is logged in.');
    }
    const mapId: number | null = yield select(state => state.map.mapInfo.data?.id);
    if (mapId === null) {
      throw new Error('Cannot detach polygon group from a map when no map is loaded.');
    }

    yield call(deactivatePolygonGroup, clientId, mapId, action.payload.polygonGroupId);
    yield put(polygonGroupDeactivateSuccess(action.payload.polygonGroupId));
  }
  catch (e) {
    yield put(polygonGroupDeactivateError(e));
  }
}

export function* polygonGroupDetachSagas() {
  yield takeEvery(POLYGON_GROUP_DEACTIVATE_REQUEST, detachPolygonGroupToMap);
}

export function* polygonGroupsSagas() {
  yield all([
    polygonGroupsFetchSagas(),
    polygonGroupCreateSagas(),
    polygonGroupDeleteSagas(),
    polygonGroupFetchActiveIdsSagas(),
    polygonGroupAttachSagas(),
    polygonGroupDetachSagas(),
  ]);
}
