import { css } from '@emotion/react';
import {
  type FC, useEffect, useRef,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { SPREADSHEET_ERROR_TIMEOUT } from '~/_shared/components/spreadsheet/constants';
import { useHookWithRefCallback } from '../../../utils/hooks/useHookWithRefCallback';
import { FixedStickyContainerComponent } from '../../fixedStickyContainer/fixedStickyContainer.component';

type SpreadsheetDataEditorDisabledProps = {
  disabledNotice: string;
  onClose: () => void;
};

const spreadsheetDisabledDataEditorStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});

const alertStyle = css({
  width: 300,
});

export const SpreadsheetDataEditorDisabledComponent: FC<SpreadsheetDataEditorDisabledProps> = (props) => {
  const [triggerRef, setTriggerRef] = useHookWithRefCallback<HTMLDivElement>();
  const { disabledNotice, onClose } = props;
  const handleCloseRef = useRef(() => {
    onClose();
  });

  useEffect(() => {
    const handleClose = handleCloseRef.current;
    window.addEventListener('mousedown', handleClose);

    return () => {
      window.removeEventListener('mousedown', handleClose);
    };
  }, []);

  return (
    <div
      css={spreadsheetDisabledDataEditorStyle}
      ref={setTriggerRef}
    >
      <FixedStickyContainerComponent
        isOpen
        onClose={onClose}
        triggerRef={triggerRef}
      >
        {() => (
          <AlertComponent
            type="danger"
            autoCloseTimeout={SPREADSHEET_ERROR_TIMEOUT}
            css={alertStyle}
            onClose={handleCloseRef.current}
          >
            {disabledNotice}
          </AlertComponent>
        )}
      </FixedStickyContainerComponent>
    </div>
  );
};
