import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import React, { useMemo } from 'react';
import { EmptyStateComponent } from '~/_shared/components/emptyState/emptyState.component';
import { useTranslation } from '~/_shared/utils/hooks';

type SaveMapViewEmptyStateProps = Readonly<{
  isSearchActive: boolean;
}>;

export const SaveMapViewEmptyStateComponent: React.FC<SaveMapViewEmptyStateProps> = props => {
  const [t] = useTranslation();

  const { title, description } = useMemo(() => {
    if (props.isSearchActive) {
      return {
        title: t('No Saved Map Views Match Your Search'),
        description: t('Refine your search and try again'),
      };
    }

    return {
      title: t('No Saved Map Views'),
      description: t('Your saved map views will be listed here'),
    };
  }, [props.isSearchActive, t]);

  return (
    <EmptyStateComponent
      css={{ padding: '50px 30px 16px' }}
      icon={faImages}
      title={title}
      description={description}
    />
  );
};
