import { apiPost } from '~/_shared/utils/api/api.helpers';

// this also creates a 'write' access for the existing user, so they don't lose the access to the map entirely
export const changeMapOwnerRequest = (
  clientId: number,
  mapId: number,
  newOwnerId: number
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/maps/ownership`;
  return apiPost(requestUrl, {
    id: mapId,
    new_owner_id: newOwnerId,
  });
};
