import { css } from '@emotion/react';
import { type FC } from 'react';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type TeamShareMapPermission } from '../../../_shared/entityAccess/entityAccess.repository';
import { type MapAccessData } from '../teamShareMapModal.container';
import { MembersTableItem } from './membersTableItem.component';

const tableContainerStyle = css({
  paddingBottom: '100px',
});

const sectionHeaderStyle = ({ theme }: ThemeProps) => css({
  background: theme.modalColors.contentBackground,
  padding: '12px 22px',
  display: 'flex',
  alignItems: 'center',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const sectionTitleStyle = ({ theme }: ThemeProps) => css({
  color: theme.modalColors.contentColor,
  fontSize: '18px',
  fontWeight: 'bold',
});

const teamMembersSectionContentStyle = ({ theme }: ThemeProps) => css({
  background: theme.modalColors.contentBackground,
  position: 'relative',
});

const loaderStyle = ({ theme }: ThemeProps) => css({
  background: theme.loadingOverlay.backgroundColor,
});

type MembersTableComponentProps = {
  currentUserId: number | null;
  isLoading: boolean;
  mapAccessData: MapAccessData[];

  onUpdateAccess: (item: MapAccessData, newPermission: TeamShareMapPermission) => void;
  onDeleteAccess: (item: MapAccessData) => void;
};

export const MembersTableComponent: FC<MembersTableComponentProps> = (props) => {
  const { currentUserId, isLoading, mapAccessData, ...handlers } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={tableContainerStyle}>
      {isLoading && (
        <OverlayLoaderComponent
          css={loaderStyle({ theme })}
          loaderSize={45}
        />
      )}
      <div css={sectionHeaderStyle({ theme })}>
        <span css={sectionTitleStyle({ theme })}>{t('Team Members On This Map')}</span>
      </div>
      <div css={teamMembersSectionContentStyle({ theme })}>
        {currentUserId && mapAccessData.map((item, idx) => (
          <MembersTableItem
            key={idx}
            index={idx + 1}
            item={item}
            currentUserId={currentUserId}
            {...handlers}
          />
        ))}
      </div>
    </div>
  );
};
