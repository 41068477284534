import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/serialize';
import type { Theme } from '~/_shared/themes/theme.model';

export const dataTableWrapperStyle = (theme: Theme) => css({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  height: '100%',
});

/**
 * Use this when pagination or other meta element is present. The meta element already provides enough padding.
 */
export const overridePaddingStyle = {
  paddingBottom: 3,
} as const satisfies CSSObject;

export const tabDataTableWrapperStyle = {
  padding: 16,
} as const satisfies CSSObject;

export const splitScreenDataTableWrapperStyle = {
  padding: 8,
} as const satisfies CSSObject;
