import {
  CONVERT_BOUNDARIES_HIDE_NAME_MODAL, CONVERT_BOUNDARIES_SHOW_NAME_MODAL, CONVERT_BOUNDARIES_START,
  CONVERT_BOUNDARIES_STOP,
} from './convertBoundaries.actionTypes';

export const convertBoundariesShowNameModal = (boundaryTerritoryGroupId: number) => ({
  type: CONVERT_BOUNDARIES_SHOW_NAME_MODAL,
  payload: {
    boundaryTerritoryGroupId,
  },
}) as const;

export const convertBoundariesHideNameModal = () => ({
  type: CONVERT_BOUNDARIES_HIDE_NAME_MODAL,
}) as const;

export const convertBoundariesStart = () => ({
  type: CONVERT_BOUNDARIES_START,
}) as const;

export const convertBoundariesStop = () => ({
  type: CONVERT_BOUNDARIES_STOP,
}) as const;
