import {
  css,
  keyframes,
} from '@emotion/react';

const blinkAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '20%': {
    opacity: 0.15,
  },
  '40%': {
    opacity: 0,
  },
});

export const blinkLoaderStyle = (animationDuration: number, animationDelay: number, animationColor?: string) => css({
  position: 'relative',
  overflow: 'hidden',

  '&:after': {
    content: '\'\'',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    height: '100%',
    background: animationColor || '#000',
    opacity: 0,
    animationName: blinkAnimation,
    animationDuration: `${animationDuration}ms`,
    animationDelay: `${animationDelay}ms`,
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
});
