import {
  apiGet, apiPost,
} from '../../../../_shared/utils/api/api.helpers';

export type TwoFactorViaEmailResponse = {
  message: string;
};

export type TwoFactorViaEmailData = {
  status: number;
};

export type TwoFactorQRCodeResponse = {
  data: {
    qrcode: string;
  };
};

export type TwoFactorViaGoogleData = {
  status: number;
  code?: string;
};

export type TwoFactorViaGoogleResponse = {
  message: string;
  data?: {
    qrcode: string;
  };
};

export const twoFactorViaEmailRequest = (request: TwoFactorViaEmailData): Promise<TwoFactorViaEmailResponse> => {
  const requestUrl = '/api/user/mfa/email';

  return apiPost(requestUrl, request);
};

export const twoFactorQRCodeRequest = (): Promise<TwoFactorQRCodeResponse> => {
  const requestUrl = '/api/user/mfa/qrcode';

  return apiGet(requestUrl);
};

export const twoFactorViaGoogleRequest = (request: TwoFactorViaGoogleData): Promise<TwoFactorViaGoogleResponse> => {
  const requestUrl = '/api/user/mfa/google';

  return apiPost(requestUrl, request);
};
