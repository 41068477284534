import { DataType } from '../../spreadsheetData/spreadsheetData.state';
import { type MapSettingsFilteringAction } from './mapSettingsFiltering.action';
import {
  MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE,
  MAP_SETTINGS_FILTERING_SET_SETTINGS,
  MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF,
  MAP_SETTINGS_FILTERING_TURN_FILTER_ON,
} from './mapSettingsFiltering.actionTypes';
import {
  type MapSettingsFilteringState, type MapSettingsFilterSetting,
} from './mapSettingsFiltering.state';

export const mapSettingsFilteringInitialState: MapSettingsFilteringState = {
  settings: {},
};

export const mapSettingsFilteringReducer = (
  state = mapSettingsFilteringInitialState,
  action: MapSettingsFilteringAction
): MapSettingsFilteringState => {
  switch (action.type) {
    case MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE: {
      const newColumnItem: MapSettingsFilterSetting = {
        type: action.payload.dataType,
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.spreadsheetColumnId.spreadsheetId]: {
            ...state.settings[action.payload.spreadsheetColumnId.spreadsheetId],
            [action.payload.spreadsheetColumnId.columnId]: newColumnItem,
          },
        },
      };
    }

    case MAP_SETTINGS_FILTERING_TURN_FILTER_ON: {
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.payload.spreadsheetColumnId.spreadsheetId]: {
            ...state.settings[action.payload.spreadsheetColumnId.spreadsheetId],
            [action.payload.spreadsheetColumnId.columnId]: {
              type: action.payload.dataType,
              isPublic: true,
            },
          },
        },
      };
    }

    case MAP_SETTINGS_FILTERING_SET_SETTINGS: {
      return {
        ...state,
        settings: action.payload.settings,
      };
    }

    case MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF: {
      const currentItem = state.settings[action.payload.spreadsheetColumnId.spreadsheetId]?.[action.payload.spreadsheetColumnId.columnId];

      if (!currentItem) {
        return {
          ...state,
          settings: {
            ...state.settings,
            [action.payload.spreadsheetColumnId.spreadsheetId]: {
              ...(state.settings[action.payload.spreadsheetColumnId.spreadsheetId]
                && state.settings[action.payload.spreadsheetColumnId.spreadsheetId]),
              [action.payload.spreadsheetColumnId.columnId]: {
                type: DataType.TEXT,
                isPublic: true,
              },
            },
          },
        };
      }
      else {
        const newSpreadsheetItem = {
          ...state.settings[action.payload.spreadsheetColumnId.spreadsheetId],
        };

        delete newSpreadsheetItem[action.payload.spreadsheetColumnId.columnId];

        return {
          ...state,
          settings: {
            ...state.settings,
            [action.payload.spreadsheetColumnId.spreadsheetId]: newSpreadsheetItem,
          },
        };
      }
    }

    default:
      return state;
  }
};
