import { type Operation } from 'fast-json-patch';
import { type ThemeType } from '~/_shared/themes/themeType';
import { type MapListSortType } from '../../../map/listing/mapListing.helpers';
import {
  USER_SETTINGS_MAP_DROPDOWN_UPDATE, USER_SETTINGS_SPLIT_PANEL_CLOSE,
  USER_SETTINGS_SPLIT_PANEL_OPEN,
  USER_SETTINGS_SYNC_FAIL, USER_SETTINGS_SYNC_REQUEST, USER_SETTINGS_SYNC_SUCCESS,
  USER_SETTINGS_THEME_UPDATE,
} from './userDataSettings.actionTypes';

export const mapDropdownUpdate = (clientId: number, sortType: MapListSortType) => ({
  type: USER_SETTINGS_MAP_DROPDOWN_UPDATE,
  payload: {
    clientId,
    sortType,
  },
}) as const;

export const userSettingsThemeUpdate = (clientId: number, theme: ThemeType) => ({
  type: USER_SETTINGS_THEME_UPDATE,
  payload: {
    clientId,
    theme,
  },
}) as const;

export function userSettingsSyncRequest(diff: Operation[]) {
  return {
    type: USER_SETTINGS_SYNC_REQUEST,
    payload: {
      diff,
    },
  } as const;
}

export function userSettingsSyncRequestSuccess() {
  return {
    type: USER_SETTINGS_SYNC_SUCCESS,
  } as const;
}

export function userSettingsSyncRequestError() {
  return {
    type: USER_SETTINGS_SYNC_FAIL,
  } as const;
}

export const userSettingsSplitPanelOpen = (clientId: number, mapId: number) => ({
  type: USER_SETTINGS_SPLIT_PANEL_OPEN,
  payload: {
    clientId,
    mapId,
  },
}) as const;

export const userSettingsSplitPanelClose = (clientId: number, mapId: number) => ({
  type: USER_SETTINGS_SPLIT_PANEL_CLOSE,
  payload: {
    clientId,
    mapId,
  },
}) as const;
