import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateAccountInformation } from '~/_shared/components/accountSettings/modalDialogs/hooks/useUpdateAccountInformation';
import { isPaidLicense } from '~/_shared/types/client/license';
import { ValueValidationErrorCode } from '~/_shared/types/responseErrors/_shared/valueValidationErrorCodes.types';
import { MAX_LENGTH_GENERIC_FIELD } from '~/_shared/types/responseErrors/responseErrorsCodes.constants';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNullsy } from '~/_shared/utils/typeGuards';
import {
  canMemberManageClient, canMemberManageSubscription,
} from '~/clientTeamManagement/memberRoles.helpers';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  ClientDataErrorCode, type UpdateUserRequest,
} from '~/store/userData/repository/userData.repository';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';
import {
  useUserCurrentClientSelector, useUserDataSelector,
} from '~/store/userData/userData.selectors';
import { twoFactorToServerModel } from '../../constants/twoFactorMode.enum';
import {
  AccountSettingsComponent, type AccountSettingsForm,
} from './accountSettings.component';
import { useTwoFactorMethod } from './twoFactorModal/useTwoFactorSettingData';
import { useUpdateClient } from './useUpdateClient';

export const AccountSettingsContainer: FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const userData = useUserDataSelector();
  const currentClient = useUserCurrentClientSelector();
  const { fieldErrors: userFieldErrors, generalError, isLoading, changeUserData } = useUpdateAccountInformation();
  const { selectedTwoFactorMode } = useTwoFactorMethod();
  const currentUserClientRole = userData.clientRole;
  const {
    isLoading: updateClientNameIsLoading,
    error: updateClientNameError,
    updateClientName,
  } = useUpdateClient(clientId);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { allowManageClient, allowManageSubscription } = useMemo(() => ({
    allowManageClient: canMemberManageClient(currentUserClientRole),
    allowManageSubscription: canMemberManageSubscription(currentUserClientRole),
  }), [currentUserClientRole]);

  const isPaidLicence = !!currentClient && isPaidLicense(currentClient.license.type);

  const onUserDataChange = useCallback(async (userDataRequest: UpdateUserRequest, newClientName: string): Promise<boolean> => {

    if (newClientName !== userData.clientName) {
      const updateClientNameResult = await updateClientName(newClientName);
      if (updateClientNameResult?.errors) {
        return false;
      }
    }

    const changeUserDataResult = await changeUserData(userDataRequest);

    if (changeUserDataResult.error) {
      return false;
    }

    dispatch(userGetDataRequest());

    return true;
  }, [changeUserData, dispatch, updateClientName, userData.clientName]);

  const onSubmit = useCallback((form: AccountSettingsForm) => {
    setShowSuccessMessage(false);

    if (form.currentPassword) {
      onUserDataChange({
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        mfa: twoFactorToServerModel(selectedTwoFactorMode),
        code: form.mfaCode,
        password: form.newPassword ? form.newPassword : undefined,
        password_confirmation: form.confirmPassword ? form.confirmPassword : undefined,
        old_password: form.currentPassword,
      }, form.companyName).then((success) => {
        if (success) {
          setShowSuccessMessage(true);
        }
      });
    }
    else {
      onUserDataChange({
        first_name: form.firstName,
        last_name: form.lastName,
      }, form.companyName).then((success) => {
        if (success) {
          setShowSuccessMessage(true);
        }
      });
    }
  }, [onUserDataChange, selectedTwoFactorMode]);

  const hideSuccessMessage = useCallback(() => setShowSuccessMessage(false), []);

  const clientErrors = useMemo(() => {
    let includesUnprocessed = false;

    const companyErrors = updateClientNameError?.name?.map(message => {
      if (message === ClientDataErrorCode.lengthExceeded) {
        return t(ValueValidationErrorCode.MAX_LENGTH_EXCEEDED, { length: MAX_LENGTH_GENERIC_FIELD });
      }
      else if (!includesUnprocessed) {
        includesUnprocessed = true;
        return t('An unknown error has occurred.');
      }
      else {
        return null;
      }
    }).filter(notNullsy);

    return companyErrors ? { company_name: companyErrors } : undefined;
  }, [t, updateClientNameError?.name]);

  return (
    <AccountSettingsComponent
      allowManageClient={allowManageClient}
      allowManageSubscription={allowManageSubscription}
      clientErrors={clientErrors}
      companyName={userData.clientName ?? ''}
      email={userData.email}
      error={generalError || undefined}
      firstName={userData.firstName}
      hideSuccessMessage={hideSuccessMessage}
      isClientMigrationInProgress={!currentClient?.migrationDone}
      isLoading={isLoading || updateClientNameIsLoading}
      isPaidLicence={isPaidLicence}
      isUserDataLoading={userData.isLoading}
      lastName={userData.lastName}
      licenseType={currentClient?.license.type}
      onSubmit={onSubmit}
      selectedTwoFactorMode={selectedTwoFactorMode}
      showSuccessMessage={showSuccessMessage}
      userErrors={userFieldErrors || undefined}
    />
  );
};
