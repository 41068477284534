import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { defaultActiveMarkerIndicatorSettings } from '~/map/map/mapOverlays/activeMarkerIndicator/activeMarkerIndicator.constants';
import { MapMarkersGraphicsDropdownSettingsName } from '~/map/settings/accordion/mapMarkersGraphics/mapMarkersGraphics.enums';
import { type MapSettingsMarkersAction } from './mapSettingsMarkers.action';
import {
  MAP_SETTINGS_ACTIVE_MARKER_INDICATOR_UPDATE,
  MAP_SETTINGS_MARKERS_PANEL_SETTINGS_SAVE,
  MAP_SETTINGS_MARKERS_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_MARKERS_REMOVE_LABEL_MARKER_OFFSETS,
  MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES,
  MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS,
  MAP_SETTINGS_MARKERS_TOGGLE,
  MAP_SETTINGS_MARKERS_UPDATE_LABEL_MARKER_OFFSETS,
  MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES,
  MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW,
} from './mapSettingsMarkers.actionTypes';
import { flattenMarkerImages } from './mapSettingsMarkers.helpers';
import {
  type GeneralPanelSettingsState,
  type MapSettingsMarkersState,
  type MarkerImages,
  type MarkerLabelCustomOffsets,
} from './mapSettingsMarkers.state';

export const generalPanelSettingsInitialState: GeneralPanelSettingsState = {
  showStreetViewImage: true,
};

export const mapSettingsMarkersInitialState: MapSettingsMarkersState = {
  activeMarkerIndicatorSettings: defaultActiveMarkerIndicatorSettings,
  clusterDenseMarkers: true,
  clusterWPieCharts: true,
  highVolumeMarkerDisplay: false,
  labelMarkerOffsets: {},
  markerImages: {},
  openLinksInSameWindow: false,
  stackedMarkersWPieCharts: false,
  [MapMarkersGraphicsDropdownSettingsName.unclusterBelowN]: 'medium',
  unstackMarkers: false,
};

export const mapSettingsMarkersReducer = (state = mapSettingsMarkersInitialState, action: MapSettingsMarkersAction): MapSettingsMarkersState => {
  switch (action.type) {
    case MAP_SETTINGS_MARKERS_TOGGLE: {
      return {
        ...state,
        [action.payload.property]: !state[action.payload.property],
      };
    }

    case MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS: {
      return {
        ...state,
        unstackMarkers: action.payload.newValue,
      };
    }

    case MAP_SETTINGS_MARKERS_PANEL_SETTINGS_SAVE: {
      return {
        ...state,
        panelSettings: action.payload.panelSettings,
      };
    }

    case MAP_SETTINGS_MARKERS_UPDATE_LABEL_MARKER_OFFSETS: {
      if (!action.payload.labelOffsets.length) {
        return state;
      }

      const updatedOffsets = cloneDeep<MarkerLabelCustomOffsets>(state.labelMarkerOffsets);
      action.payload.labelOffsets.forEach(({ marker: { spreadsheetId, rowId }, offset }) => {
        if (offset.type === 'default') {
          delete updatedOffsets?.[spreadsheetId]?.[rowId];
        }
        else {
          const rowOffsets = updatedOffsets[spreadsheetId] ?? {};
          rowOffsets[rowId] = { x: offset.x, y: offset.y };
          updatedOffsets[spreadsheetId] = rowOffsets;
        }
      });

      return {
        ...state,
        labelMarkerOffsets: updatedOffsets,
      };
    }

    case MAP_SETTINGS_MARKERS_REMOVE_LABEL_MARKER_OFFSETS: {
      if (!action.payload.markerIds.length) {
        return state;
      }

      const updatedOffsets = cloneDeep<MarkerLabelCustomOffsets>(state.labelMarkerOffsets);

      action.payload.markerIds.forEach(({ spreadsheetId, rowId }) => {
        delete updatedOffsets?.[spreadsheetId]?.[rowId];
      });

      return {
        ...state,
        labelMarkerOffsets: updatedOffsets,
      };
    }

    case MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES: {
      if (!action.payload.activeMaker.rowId || !action.payload.activeMaker.spreadsheetId) {
        return state;
      }

      return {
        ...state,
        markerImages: {
          ...state.markerImages,
          [action.payload.activeMaker.spreadsheetId]: {
            ...(state.markerImages[action.payload.activeMaker.spreadsheetId] || {}),
            [action.payload.activeMaker.rowId]: [...new Set(action.payload.fileAttachmentIds)],
          },
        },
      };
    }

    case MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES: {
      if (!action.payload.markerIds.length) {
        return state;
      }

      const updatedImages = cloneDeep<MarkerImages>(state.markerImages);

      action.payload.markerIds.forEach(({ spreadsheetId, rowId }) => {
        delete updatedImages?.[spreadsheetId]?.[rowId];
      });

      return {
        ...state,
        markerImages: updatedImages,
      };
    }

    case MAP_SETTINGS_MARKERS_REMOVE_FILE_ATTACHMENTS: {
      if (!action.payload.attachmentsIds.length) {
        return state;
      }

      const updatedImages = cloneDeep<MarkerImages>(state.markerImages);

      flattenMarkerImages(updatedImages).forEach(item => {
        item.rowImages[item.rowId] = item.images.filter(image => !action.payload.attachmentsIds.includes(image));
      });

      return {
        ...state,
        markerImages: updatedImages,
      };
    }

    case MAP_SETTINGS_ACTIVE_MARKER_INDICATOR_UPDATE: {
      return {
        ...state,
        activeMarkerIndicatorSettings: {
          ...state.activeMarkerIndicatorSettings,
          ...action.payload.settings,
        },
      };
    }

    case MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW: {
      return {
        ...state,
        unclusterBelowN: action.payload.unclusterBelowLevel,
      };
    }

    default:
      return state;
  }
};
