export const getAvailableItemSpace = (availableSpace: number, itemsWidth: number[], itemIndex: number): number | null => {
  if (itemIndex > itemsWidth.length - 1) {
    return null;
  }

  const nextItemsWidths = itemsWidth.slice(itemIndex + 1).reduce((sum, width) => sum + width, 0);
  const width = availableSpace - nextItemsWidths;

  if (width < 0) {
    return 0;
  }

  return width;
};
