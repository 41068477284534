import { css } from '@emotion/react';
import {
  faCheck, faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { OverlayLoaderComponent } from '../../../_shared/components/overlay/overlayLoader.component';

const modalContentStyle = css({
  padding: 0,
  width: '100%',
});

const modalInnerContainerStyle = css({
  padding: '23px',
});

const submitButtonStyle = css({
  height: '40px',
  fontSize: '16px',
  fontWeight: 500,
  width: '120px',
  borderRadius: 4,
  cursor: 'pointer',
});

const cancelButtonStyle = css({
  height: '40px',
  fontSize: '16px',
  fontWeight: 500,
  width: '120px',
  borderRadius: 4,
  cursor: 'pointer',
});

const checkboxStyle = css({
  marginTop: 10,
});

const buttonBarStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  paddingTop: '18px',
  borderTop: '1px solid #424A51',
});

type RemoveMemberFromMapModalComponentProps = ModalProps<{
  isLoading: boolean;
  deleteEntityAccess: (doNotShowAgain: boolean) => void;
}>;

export const RemoveMemberFromMapModalComponent: FC<RemoveMemberFromMapModalComponentProps> = (props) => {
  const { deleteEntityAccess, isLoading, ...modalProps } = props;
  const [t] = useTranslation();

  const [isCheckedNotShowAgain, setNotShowAgain] = useState(false);

  const handleDeleteEntityAccess = useCallback(() => {
    deleteEntityAccess(isCheckedNotShowAgain);
  }, [deleteEntityAccess, isCheckedNotShowAgain]);

  return (
    <ModalComponent
      {...modalProps}
      caption={t('Remove Member From Map')}
      contentStyle={modalContentStyle}
      maxWidth="600px"
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <div css={modalInnerContainerStyle}>
        <div>
          {t('You are about to remove a member from your map. You can re-add the member any time. Do you wish to proceed?')}
        </div>
        <div>
          <CheckboxComponent
            css={checkboxStyle}
            isChecked={isCheckedNotShowAgain}
            checkedSetter={() => {
              setNotShowAgain(!isCheckedNotShowAgain);
            }}
            text={t('Do not show again')}
          />
        </div>
        <div css={buttonBarStyle}>
          <ButtonComponent
            css={cancelButtonStyle}
            text={t('Cancel')}
            onClick={props.onClose}
            prefixIcon={faTimes}
            isDisabled={isLoading}
          />
          <ButtonComponent
            buttonStyle={ButtonStyle.Danger}
            css={submitButtonStyle}
            isDisabled={isLoading}
            onClick={handleDeleteEntityAccess}
            prefixIcon={faCheck}
            text={t('Confirm')}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
