import { combineReducers } from 'redux';
import { boundaryTerritoryDetailsReducer } from '../boundaryTerritoryDetails/boundaryTerritoryDetails.reducer';
import { boundaryTerritoryGroupsReducer } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.reducer';
import { mapIdReducer } from '../mapId/mapIdReducer';
import { mapInfoReducer } from '../mapInfo/mapInfo.reducer';
import { mapSettingsReducer } from '../mapSettings/mapSettings.reducer';
import { undoReducer } from '../undo/undo.reducer';
import { MAP_RESET } from './map.actionTypes';

export const regularReducer = combineReducers({
  mapId: mapIdReducer,
  mapInfo: mapInfoReducer,
  mapSettings: mapSettingsReducer,
  undo: undoReducer,
  boundaryTerritoryGroups: boundaryTerritoryGroupsReducer,
  boundaryTerritoryDetails: boundaryTerritoryDetailsReducer,
});

export const mapReducer = (state: ReturnType<typeof regularReducer> | undefined, action: Action<typeof regularReducer>) => {
  if (action.type === MAP_RESET) {
    state = undefined;
  }

  return regularReducer(state, action);
};
