import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';
import type {
  Place, PlaceId,
} from './places.types';

export enum PlacesSearchErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  FAILED = 'FAILED',
  LIMIT_REACHED = 'LIMIT_REACHED',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum PlacesSearchResultsRankingPreference {
  Distance = 'distance',
  Relevance = 'relevance',
}

export type PlacesSearchRectangle = Readonly<{
  low: Readonly<{
    latitude: number;
    longitude: number;
  }>;
  high: Readonly<{
    latitude: number;
    longitude: number;
  }>;
}>;

export type GetPlacesByTextSearchRequestItem = Readonly<{
  text: string;
  location: Readonly<{
    rectangle: PlacesSearchRectangle;
  }>;
  preference: PlacesSearchResultsRankingPreference;
  more?: boolean;
}>;

export type GetPlacesByTextSearchRequest = Readonly<{
  params: ReadonlyArray<GetPlacesByTextSearchRequestItem>;
}>;

export type GetPlacesByTextSearchResponse = Readonly<{
  data: ReadonlyArray<Readonly<{
    items?: ReadonlyArray<Place>;
    hasMore?: boolean;
    code?: PlacesSearchErrorCode;
  }>>;
}>;

export const getPlacesByTextSearch = (clientId: number, params: GetPlacesByTextSearchRequest): Promise<GetPlacesByTextSearchResponse> => {
  const requestUrl = `/api/clients/${clientId}/places/google/text-search-new`;
  return apiPost(requestUrl, params);
};

export const getPlaceDetails = (clientId: number, placeId: PlaceId): Promise<GetPlacesByTextSearchResponse> => {
  const requestUrl = `/api/clients/${clientId}/places/google/place/${placeId}`;
  return apiGet(requestUrl);
};
