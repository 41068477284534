import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { LOGIN_ROUTE } from '~/_shared/constants/routes';
import { useTranslation } from '../../../_shared/utils/hooks';

const linkStyle = css({
  fontSize: 20,
  textAlign: 'center',
  marginTop: 10,
});

export const PrivateMapWarningComponent: FC = () => {
  const [t] = useTranslation();

  return (
    <div>
      <div css={linkStyle}>
        <p>{t('This map is set to private and can only be accessed when logged in.')}</p>

        <LinkComponent
          css={linkStyle}
          path={LOGIN_ROUTE}
        >
          {t('Sign in here')}
        </LinkComponent>
      </div>
    </div>
  );
};
