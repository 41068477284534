import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { errorPageClearError } from '~/store/errorPage/errorPage.actionCreators';
import {
  AppErrorPageComponent, type AppErrorPageProps,
} from './appErrorPage.component';

type AppErrorPageContainerProps = Omit<AppErrorPageProps, 'clearErrorPage'>;

export const AppErrorPageContainer: FC<AppErrorPageContainerProps> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const previousPathname = usePrevious(location.pathname);

  const clearErrorPage = useCallback(() => {
    dispatch(errorPageClearError());
  }, [dispatch]);

  useEffect(() => {
    if (!!previousPathname && (previousPathname !== location.pathname)) {
      clearErrorPage();
    }
  }, [clearErrorPage, location.pathname, previousPathname]);

  return (
    <AppErrorPageComponent
      {...props}
      clearErrorPage={clearErrorPage}
    />
  );
};
