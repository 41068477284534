import { css } from '@emotion/react';
import { type FC } from 'react';
import { AnchorLink } from '~/_shared/baseComponents/links';
import { LinkTypes } from '~/_shared/baseComponents/links/anchorLink/link.types';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { supportLinkStyle } from '~/_shared/baseComponents/links/supportLink/supportLink.styles';

const valueStyle = css({
  fontSize: 18,
});

type ContactUsValueProps = Readonly<{
  value: string;
  type: LinkTypes;
}>;

export const ContactUsValueComponent: FC<ContactUsValueProps> = props => {
  if (props.type === LinkTypes.href) {
    return (
      <SupportLinkComponent
        alternativeEmail={props.value}
        type={SupportLinkType.info}
      >
        <span css={valueStyle}>
          {props.value}
        </span>
      </SupportLinkComponent>
    );
  }
  else {
    return (
      <AnchorLink
        css={supportLinkStyle(SupportLinkType.info)}
        href={`tel:${props.value}`}
      >
        {props.value}
      </AnchorLink>
    );
  }
};
