import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';

export enum EmptyStateSize {
  Default = 'Default',
  Small = 'Small',
}

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: 1.15,
  textAlign: 'center',
});

const iconWrapperStyle = ({ size }: {size: EmptyStateSize}) => css({
  fontSize: size === EmptyStateSize.Default ? 40 : 30,
});

const headingStyle = ({ size }: {size: EmptyStateSize}) => css({
  fontSize: size === EmptyStateSize.Default ? 18 : 14,
  fontWeight: 700,
  margin: '8px 0 0',
  textTransform: 'capitalize',
});

const textStyle = css({
  fontSize: 12,
  margin: '8px 0 0',
});

type EmptyStateProps = Readonly<{
  className?: string;
  description?: string;
  icon: IconProp;
  title: string;
  size?: EmptyStateSize;
}>;

export const EmptyStateComponent: FC<EmptyStateProps> = props => {
  const size = props.size ?? EmptyStateSize.Default;

  return (
    <div
      className={props.className}
      css={wrapperStyle}
    >
      <div css={iconWrapperStyle({ size })}>
        <FontAwesomeIcon icon={props.icon} />
      </div>

      <p css={headingStyle({ size })}>{props.title}</p>

      <p css={textStyle}>{props.description}</p>
    </div>
  );
};
