import {
  faCheck, faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useEffect, useMemo, useState,
} from 'react';
import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { getLottieAnimationDuration } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { createUuid } from '~/_shared/utils/createUuid';
import { delay } from '~/_shared/utils/delay';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useIsImageExportInProgressSelector } from '~/store/frontendState/processing/processing.selectors';
import { exportImageTimeRange } from '../../exportImageModal/exportImageModal.component';
import { type TopUnderbarItem } from '../item/topUnderbarItem.types';
import { TopUnderbarStatusItemComponent } from './topUnderbarStatus.component';

const enum ExportStatus {
  None = 'none',
  Exporting = 'exporting',
  Done = 'done',
}

const doneAnimationType = LottieAnimationTypes.
  CircleDownload;
const doneAnimationProps = {
  segment: LottieAnimations[doneAnimationType].segments.default,
  speed: 1,
  size: 30,
  autoplay: true,
} as const;

const doneDelay = getLottieAnimationDuration(doneAnimationType, doneAnimationProps.segment) / doneAnimationProps.speed;

const exportingAnimationType = LottieAnimationTypes.UploadingImage;
const exportingAnimationProps = {
  segment: LottieAnimations[exportingAnimationType].segments.visor,
  speed: 1,
  size: 36,
  autoplay: true,
  loop: true,
  bothDirections: true,
} as const;

type UseMapSettingsSavingItemsProps = {
  generateSamples?: boolean;
};

export const useExportImageStatusItems = (props?: UseMapSettingsSavingItemsProps): ReadonlyArray<TopUnderbarItem> => {
  const isImageExportLoading = useIsImageExportInProgressSelector();
  const [status, setStatus] = useState({ value: ExportStatus.None, id: createUuid() });
  const theme = useTheme();
  const [t] = useTranslation();

  const statusUpdate = isImageExportLoading ? ExportStatus.Exporting : ExportStatus.None;

  const previousStatusUpdate = usePrevious(statusUpdate);

  useEffect(() => {
    if (statusUpdate === previousStatusUpdate) {
      return;
    }

    // Saving => Saved => delay => None
    if (statusUpdate === ExportStatus.None && status.value === ExportStatus.Exporting) {
      const savedStateId = createUuid();
      setStatus({ value: ExportStatus.Done, id: savedStateId });
      delay(doneDelay).then(() =>
        setStatus(previousStatus => previousStatus.id === savedStateId ?
          { value: ExportStatus.None, id: createUuid() } : previousStatus
        ));
    }
    else {
      setStatus({ value: statusUpdate, id: createUuid() });
    }
  }, [previousStatusUpdate, status, statusUpdate]);

  return useMemo(() => {
    const items: TopUnderbarItem[] = [];

    if (status.value === ExportStatus.Exporting || props?.generateSamples) {
      items.push({
        key: `image-export-save-status-${status.value}`,
        sticky: true,
        icon: faCircleInfo,
        colors: {
          color: theme.contrasts.selectedAccent2,
          backgroundColor: theme.contrasts.selectedAccent2Background,
        },
        children: (
          <TopUnderbarStatusItemComponent
            animation={{
              type: exportingAnimationType,
              colors: {
                watermark: theme.iconColors.active,
                visor: theme.contrasts.selectedAccent2,
                tick: theme.contrasts.selectedAccent2,
              },
              ...exportingAnimationProps,
            }}
            text={{
              value: `${t('Exporting')}...`,
            }}
            suffixText={{
              value: t('This process can take up to {{fromMinutes}}-{{toMinutes}} minutes.', exportImageTimeRange),
            }}
          />
        ),
      });
    }

    if (status.value === ExportStatus.Done || props?.generateSamples) {
      items.push({
        key: `image-export-save-announcement-${status.value}`,
        sticky: true,
        icon: faCheck,
        colors: {
          color: theme.contrasts.success,
          backgroundColor: theme.contrasts.successBackground,
        },
        children: (
          <TopUnderbarStatusItemComponent
            animation={{
              type: doneAnimationType,
              colors: {
                fillFrom: theme.contrasts.successBackground,
                borderFrom: theme.iconColors.borderPrimaryHover,
                downloadIcon: theme.iconColors.borderPrimaryHover,
                fillTo: theme.iconColors.backgroundSuccessInverted,
                borderTo: theme.iconColors.backgroundSuccessInvertedHover,
                tick: theme.contrasts.successBackground,
              },
              ...doneAnimationProps,
            }}
            text={{
              value: t('Download Started'),
            }}
          />
        ),
      });
    }

    return items;
  },
  [props?.generateSamples, status.value, t, theme]);
};
