import { css } from '@emotion/react';
import {
  faCheck, faPlay, faStop, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { computeLatLngBoundsArea } from '~/_shared/utils/gis/boundingBox.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { createLatLngBoundsFromPolygonPaths } from '~/_shared/utils/map/latLngBounds.factory';
import {
  boundaryDrawCloseModal, boundaryDrawEditActivate, boundaryDrawEditOpenModal, boundaryDrawResetShapes,
  boundaryDrawSetDrawType,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { useBoundaryDrawPolygonEditModalOpenSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';
import { noop } from '../../../_shared/utils/function.helpers';
import { updateBoundaryPolygonPaths } from '../../../store/frontendState/mapTools/boundary/boundaryPolygonUpdate/boundaryPolygonUpdate.actionCreators';
import { BoundaryEditAction } from '../boundaryEditModal/boundaryEditModal.helpers';
import { BoundaryDrawConfirmComponent } from './boundaryDrawConfirm.component';
import { getBoundaryDrawTypeOptions } from './boundaryDrawConfirm.helpers';

const buttonsWrapperStyle = ({ buttonsCount }: { buttonsCount: number }) => css({
  display: 'flex',
  justifyContent: buttonsCount <= 1 ? 'flex-end' : 'space-between',
});

export const BoundaryPolygonDragEditConfirmContainer = () => {
  const boundaryDragEdit = useBoundaryDrawPolygonEditModalOpenSelector();
  const isDragEditActive = boundaryDragEdit?.isActive ?? false;
  const dragDisabled = !!boundaryDragEdit?.disabledReason;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onClose = useCallback(() => {
    dispatch(boundaryDrawCloseModal());
  }, [dispatch]);

  const reset = useCallback(() => {
    dispatch(boundaryDrawResetShapes());
  }, [dispatch]);

  const onStartButtonClick = useCallback(() => {
    if (!dragDisabled) {
      dispatch(boundaryDrawEditActivate());
    }
    else if (boundaryDragEdit) {
      dispatch(boundaryDrawEditOpenModal({
        boundaryGroupId: boundaryDragEdit.boundaryGroupId,
        boundaryTerritoryGroupId: boundaryDragEdit.drawModeData.boundaryTerritoryGroupId,
        action: BoundaryEditAction.EditBoundaries,
        boundaryId: boundaryDragEdit.drawModeData.boundaryId,
        startAsActive: true,
      }));
    }
  }, [boundaryDragEdit, dispatch, dragDisabled]);

  const submit = useCallback(() => {
    if (!boundaryDragEdit) {
      return;
    }

    dispatch(boundaryDrawCloseModal());

    const multiPolygon = boundaryDragEdit.mapShapes.map(shape => {
      const bounds = createLatLngBoundsFromPolygonPaths(shape.outlines);

      return ({
        path: shape.outlines.map(o => ({ lat: o.lat, lng: o.lng })),
        holes: [],
        bounds,
        boundsArea: bounds && computeLatLngBoundsArea(bounds),
      });
    });

    dispatch(updateBoundaryPolygonPaths(
      boundaryDragEdit.drawModeData.boundaryId,
      boundaryDragEdit.boundaryGroupId,
      multiPolygon));
  }, [boundaryDragEdit, dispatch]);

  const onDrawTypeChange = useCallback((drawType: DrawType) => dispatch(boundaryDrawSetDrawType(drawType)), [dispatch]);

  const shapesChanged = boundaryDragEdit && boundaryDragEdit.mapShapes !== boundaryDragEdit.initialShapes;

  const buttons = useMemo(() => {
    if (!shapesChanged) {
      if (!isDragEditActive) {
        return [{
          icon: faPlay,
          buttonStyle: ButtonStyle.Success,
          onClick: onStartButtonClick,
          label: t('Start'),
          isDisabled: boundaryDragEdit?.drawType === DrawType.Drag && dragDisabled,
          hint: boundaryDragEdit?.disabledReason,
        }];
      }
      else {
        return [{
          icon: faStop,
          buttonStyle: ButtonStyle.Danger,
          onClick: reset,
          label: t('Stop'),
        }, {
          icon: faCheck,
          buttonStyle: ButtonStyle.Primary,
          onClick: noop,
          label: t('Save'),
          isDisabled: true,
        }];
      }
    }
    else {
      return [{
        icon: faTrash,
        buttonStyle: ButtonStyle.Danger,
        onClick: reset,
        label: t('Reset'),
      }, {
        icon: faCheck,
        buttonStyle: ButtonStyle.Primary,
        onClick: submit,
        label: t('Save'),
      }];
    }
  }, [boundaryDragEdit?.disabledReason, boundaryDragEdit?.drawType, dragDisabled, isDragEditActive,
    onStartButtonClick, reset, shapesChanged, submit, t]);

  if (!boundaryDragEdit) {
    return null;
  }

  const options = getBoundaryDrawTypeOptions(t);

  let highlightedStepNumber: number;

  if (!boundaryDragEdit.isActive) {
    highlightedStepNumber = 1;
  }
  else {
    highlightedStepNumber = 2;
  }

  return (
    <BoundaryDrawConfirmComponent
      isOpen
      highlightedStepNumber={highlightedStepNumber}
      heading={t('Edit Territory Group')}
      dropdownOptions={options}
      selectedOptionId={boundaryDragEdit.drawType}
      buttonsWrapperStyle={buttonsWrapperStyle({ buttonsCount: buttons.length })}
      buttons={buttons}
      onClose={onClose}
      onDropdownSelect={onDrawTypeChange}
    />
  );
};
