import { css } from '@emotion/react';
import {
  type FC, useCallback,
} from 'react';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import type { SliderValue } from '~/_shared/components/slider/slider.component';
import type { LabelVisualSetting } from '~/_shared/types/markers/visualSettings.types';
import standardMarkerBackground from '../../../../assets/images/previewStandardMarkerBackground.png';
import { createColor } from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithWheelAndTileComponent } from '../../_shared/components/colorPicker/colorPickerWithTileAndWheel/colorPickerWithTileAndWheel.component';
import { ImageComponent } from '../../_shared/components/image/image.component';
import { LabelVisualizerComponent } from '../../_shared/components/labelVisualizer/labelVisualizer.component';
import { SliderWithValueLabelsComponent } from '../../_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { type QuadrilateralDimensions } from '../../_shared/types/coordinateSystem/coordinateSystem';
import { type MarkerVisualSettingsColor } from '../../_shared/types/marker.types';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  type LabelSettingData,
  type LabelSettingsData,
} from './labelSettings.types';

const MIN_PREVIEW_HEIGHT = 160;
const BACKGROUND_DIMENSIONS: QuadrilateralDimensions = { height: 210, width: 572 };

const contentStyle = css({
  padding: '36px 24px 22px',
});

const settingStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'default',
  fontSize: 14,
  marginTop: 12,
});

const previewStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  cursor: 'default',
});

const titleStyle = css({
  fontSize: 16,
  marginBottom: 12,
  textTransform: 'uppercase',
});

const labelStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: 0,
  height: '85%',
  left: 0,
  position: 'absolute',
  width: '100%',
});

const labelCenterStyle = css({
  display: 'inline-block',
});

const imageWrapperStyle = css({
  position: 'relative',
  ...BACKGROUND_DIMENSIONS,
});

const imageStyle = css({
  borderRadius: 10,
});

const labelWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  marginBottom: 24,
  minHeight: MIN_PREVIEW_HEIGHT,
  width: '100%',
});

const sliderStyle = css({
  width: '50%',
});

export type LabelSettingsProps = Readonly<{
  label: string;
  labelSettingsData: LabelSettingsData;
  labelVisualSetting: LabelVisualSetting;
}>;

export const LabelSettingsComponent: FC<LabelSettingsProps> = (props) => {
  const { labelSettingsData: { colors, sliders }, labelVisualSetting } = props;
  const [t] = useTranslation();

  const renderColorSetting = useCallback((c: LabelSettingData<MarkerVisualSettingsColor>) => (
    <div
      css={settingStyle}
      key={c.caption}
    >
      <div>{t(c.caption)}</div>
      <DebouncedUpdateComponent
        onChangeDebounced={(color: ColorResult) => c.setter({ selectedColor: color.hex, opacity: color.rgb.a })}
        value={createColor({
          ...createColor(c.value.selectedColor).rgb,
          a: c.value.opacity,
        })}
        wait={500}
        render={(value, onChange) => (
          <ColorPickerWithWheelAndTileComponent
            selectedColor={value}
            onChange={onChange}
            displayAlpha={false}
          />
        )}
      />
    </div>
  ), [t]);

  const renderSliderSetting = useCallback((s: LabelSettingData<number>) => (
    <div
      css={settingStyle}
      key={s.caption}
    >
      <div>
        {t(s.caption)}
      </div>
      <DebouncedUpdateComponent<SliderValue>
        onChangeDebounced={([value]) => s.setter(value)}
        value={[s.value]}
        wait={50}
        render={(value, onChange) => (
          <SliderWithValueLabelsComponent
            css={sliderStyle}
            value={value}
            onChange={onChange}
            min={s?.options?.min ?? 0}
            max={s?.options?.max ?? 100}
            currentValueOnTheRight
            valueSuffix={s.suffix ?? ''}
          />
        )}
      />
    </div>
  ), [t]);

  return (
    <div css={contentStyle}>
      <div css={previewStyle}>
        <div css={titleStyle}>{t('Marker Preview')}</div>
        <div css={labelWrapperStyle} >
          <div css={imageWrapperStyle}>
            <ImageComponent
              width={BACKGROUND_DIMENSIONS.width}
              height={BACKGROUND_DIMENSIONS.height}
              src={standardMarkerBackground}
              alt="Label Background"
              commonStyle={imageStyle}
            />
            <div css={labelStyle}>
              <div css={labelCenterStyle}>
                <LabelVisualizerComponent
                  label={props.label}
                  bodyProps={labelVisualSetting.bodyProps}
                  borderProps={labelVisualSetting.borderProps}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {renderColorSetting(colors.BorderColor)}
        {renderColorSetting(colors.BackgroundColor)}
        {renderSliderSetting(sliders.BackgroundOpacity)}
        {renderColorSetting(colors.FontColor)}
        {renderSliderSetting(sliders.FontSize)}
        {renderSliderSetting(sliders.PaddingHorizontal)}
        {renderSliderSetting(sliders.PaddingVertical)}
      </div>
    </div>
  );
};
