import {
  type FC, memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  MapOutlinePosition,
  type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '../../../../_shared/types/latLng';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { drawingEditPushNewSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered,
  drawingToolItemHoverStopped, drawingToolItemMoveStarted, drawingToolItemMoveStopped,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemIconMarker } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import { DrawingToolMarkerInstanceMarkerContainer } from './drawingToolIconMarkerInstanceMarker.container';

type DrawingToolIconMarkerSelectedInstanceProps = {
  instance: DrawingItemIconMarker;
};

const DrawingToolIconMarkerSelectedInstanceContainer: FC<DrawingToolIconMarkerSelectedInstanceProps> = ({ instance }) => {
  const [center, setCenter] = useState(instance.center);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dragStartDataRef = useRef<{ position: LatLng; originalCenter: LatLng } | null>(null);
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();

  const updatedCenterInstance = useMemo(() => {
    return {
      ...instance,
      center,
    };
  }, [instance, center]);

  useEffect(() => {
    setCenter(instance.center);
  }, [instance.center]);

  const onDragStart = useCallback((_id: Uuid, position: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      setHoveredOutlineId(null);
      dragStartDataRef.current = {
        position,
        originalCenter: center,
      };
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef, center]);

  const onDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingEditPushNewSnapshot({
        type: DrawingTool.IconMarker,
        value: {
          ...instance,
          center,
        },
      }));

      dispatch(drawingToolItemMoveStopped());
    }
  }, [drawingEventsEnabledRef, dispatch, instance, center]);

  const onDragMove = useCallback((_id: Uuid, position: LatLng) => {
    if (!dragStartDataRef.current) {
      return;
    }

    const { position: startPosition, originalCenter } = dragStartDataRef.current;

    const latOffset = position.lat - startPosition.lat;
    const lngOffset = position.lng - startPosition.lng;

    setCenter({
      lat: originalCenter.lat + latOffset,
      lng: originalCenter.lng + lngOffset,
    });
  }, []);

  const onMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  const onClick = useCallback((_id: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const onOutlineMouseOver = useCallback((outlineId: string) => {
    dispatch(drawingToolItemHovered());
    setHoveredOutlineId(outlineId);
  }, [dispatch]);

  const onOutlineMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
    setHoveredOutlineId(null);
  }, [dispatch]);

  const renderOutline = useCallback((id: string, outline: MapOutlinePositionInstance) => {
    if (outline.position !== MapOutlinePosition.Bottom) {
      return null;
    }

    return (
      <MapOutline
        key={id}
        outline={outline}
        visuals={id === hoveredOutlineId ? outlineHoverVisuals : outlineVisuals}
        onMouseOver={onOutlineMouseOver}
        onMouseOut={onOutlineMouseOut}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
      />
    );
  }, [hoveredOutlineId, onOutlineMouseOver, onOutlineMouseOut, onDragMove, onDragStart, onDragEnd]);

  return (
    <DrawingToolMarkerInstanceMarkerContainer
      instance={updatedCenterInstance}
      zIndex={zIndex}
      renderOutline={renderOutline}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
      onDragStart={onDragStart}
    />
  );
};

const pureComponent = memo(DrawingToolIconMarkerSelectedInstanceContainer);
export { pureComponent as DrawingToolIconMarkerSelectedInstanceContainer };
