import {
  type ActiveGroupFilters, type GroupingColumnNumeric,
  type GroupingColumnValues,
  GroupingType,
  type PerSpreadsheetUniqueGroups,
  type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { type MapSettingsColumnsFilterState } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import {
  getItemNumericBucketFromBuckets,
  getNumericalGroupBucketsForSpreadsheetData,
  NON_NUMERICAL_VALUE,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';

export const getUniqueGroupId = (uniqueGroupIndex: number, groupingColumn: SpreadsheetColumnId, spreadsheetData: SpreadsheetDataData): string | undefined => {
  const groupData = spreadsheetData.values[groupingColumn.spreadsheetId]?.[Unfiltered]?.[groupingColumn.columnId]?.[DataType.GROUP];
  return groupData?.extra.uniqueGroups[uniqueGroupIndex]?.id;
};

export const getTextColumnGroupIndex = (data: SpreadsheetDataData, spreadsheetId: number, columnId: string, rowId: CombinedRowId) => {
  const dataItem = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP];
  return dataItem?.values[rowId] || 0;
};

export const getNumberColumnBucketId = (
  data: SpreadsheetDataData, spreadsheetId: number, columnId: string,
  rowId: CombinedRowId, groupingColumn: GroupingColumnNumeric
) => {
  const dataItem = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.NUMBER];
  const value = dataItem?.values[rowId];
  const buckets = getNumericalGroupBucketsForSpreadsheetData(groupingColumn, data);
  const dataItemExtra = dataItem?.extra;

  return getItemNumericBucketFromBuckets(
    value ?? null,
    groupingColumn.valueType,
    dataItemExtra?.min ?? 0,
    dataItemExtra?.max ?? 0,
    buckets,
  );
};

export const mapSpreadsheetDataGroups = <T>(data: PerSpreadsheetUniqueGroups,
  mapper: (group: UniqueGroup, columnId: string, spreadsheetId: number) => T) =>
  Object.keys(data).map(sheetIdString => {
    const sheetId = Number(sheetIdString);
    return Object.keys(data[sheetId]).map(columnId => {
      return data[sheetId][columnId].map(group => mapper(group, columnId, sheetId));
    });
  });

export const getActiveGroupFiltersFromFiltersState = (filterState: MapSettingsColumnsFilterState):
ActiveGroupFilters => {
  const results: ActiveGroupFilters = {};

  Object.keys(filterState).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    results[spreadsheetId] = {};

    Object.keys(filterState[spreadsheetId]).forEach(columnId => {
      const value = filterState[spreadsheetId][columnId][DataType.GROUP];
      if (value) {
        results[spreadsheetId][columnId] = value;
      }
    });
  });

  return results;
};

export const getActiveGroupFiltersValueForGroupIdToggle = (activeGroupFilters: ActiveGroupFilters,
  groupingColumn: SpreadsheetColumnId, toggledGroup: UniqueGroup): GroupingColumnValues<1> => {
  const newTextValues: { [groupId: string]: 1} = {
    ...activeGroupFilters[groupingColumn.spreadsheetId]?.[groupingColumn.columnId]?.[GroupingType.Text],
  };

  const newNumericValues = {
    ...activeGroupFilters[groupingColumn.spreadsheetId]?.[groupingColumn.columnId]?.[GroupingType.Numeric],
  };

  if (toggledGroup.type === GroupingType.Text) {
    if (newTextValues[toggledGroup.id]) {
      delete newTextValues[toggledGroup.id];
    }
    else {
      newTextValues[toggledGroup.id] = 1;
    }
  }
  else {
    if (newNumericValues[toggledGroup.id]) {
      delete newNumericValues[toggledGroup.id];
    }
    else {
      newNumericValues[toggledGroup.id] = 1;
    }
  }

  return {
    [GroupingType.Text]: newTextValues,
    [GroupingType.Numeric]: newNumericValues,
  };
};

export const filterNonNumericBuckets = <T extends { id: number | string }>(groupBuckets?: T[] | ReadonlyArray<T>): T[] => {
  if (!groupBuckets) {
    return [];
  }

  return groupBuckets.filter(bucket => bucket.id !== NON_NUMERICAL_VALUE);
};

export const getMarkerGroup = (data: SpreadsheetDataData, spreadsheetId: number, columnId: string, rowId: CombinedRowId): string | null => {
  const dataItem = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP];
  const groupIndex = dataItem?.values[rowId];
  return groupIndex !== undefined && groupIndex < (dataItem?.extra.uniqueGroups?.length ?? -1)
    ? dataItem?.extra.uniqueGroups[groupIndex]?.label ?? null
    : null;
};
