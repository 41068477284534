import { type RouteCollapsingLevel } from '~/_shared/constants/routeCollapsingLevel.enum';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  FRONTEND_STATE_DIRECTIONS_ADD_EMPTY_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS,
  FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS, FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED,
  FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT,
  FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS,
  FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS,
  FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA,
  FRONTEND_STATE_ROUTE_COLLAPSED,
  FRONTEND_STATE_ROUTE_EXPANDED,
  FRONTEND_STATE_ROUTE_LEG_COLLAPSED,
  FRONTEND_STATE_ROUTE_LEG_EXPANDED,
  FRONTEND_STATE_ROUTE_UI_DATA_LOADED,
} from './directions.actionTypes';
import {
  type DirectionSourceType,
  type RouteUiData,
  type Waypoint,
} from './directions.state';

export const frontendStateDirectionsAddEmptyWaypoint = () => ({
  type: FRONTEND_STATE_DIRECTIONS_ADD_EMPTY_WAYPOINT,
}) as const;

export const frontendStateDirectionsAddMarkerWaypoint = (payload: {
  address: string;
  latLng: LatLng;
  markerId?: SpreadsheetRowId;
  startsFromUserLocation: boolean;
  directionSourceType?: DirectionSourceType;
}) => ({
  type: FRONTEND_STATE_DIRECTIONS_ADD_MARKER_WAYPOINT,
  payload,
}) as const;

export const frontendStateDirectionsRemoveWaypoint = (waypointIndex: number) => ({
  type: FRONTEND_STATE_DIRECTIONS_REMOVE_WAYPOINT,
  payload: {
    waypointIndex,
  },
}) as const;

export const frontendStateDirectionsClearWaypoints = () => ({
  type: FRONTEND_STATE_DIRECTIONS_CLEAR_WAYPOINTS,
}) as const;

export const frontendStateDirectionsSetWaypointAddress = (waypointIndex: number, newAddress: string) => ({
  type: FRONTEND_STATE_DIRECTIONS_SET_WAYPOINT_ADDRESS,
  payload: {
    waypointIndex,
    newAddress,
  },
}) as const;

export const frontendStateDirectionsSetWaypoints = (waypoints: ReadonlyArray<Waypoint>, keepFirstWaypointEmpty: boolean) => ({
  type: FRONTEND_STATE_DIRECTIONS_SET_WAYPOINTS,
  payload: {
    waypoints,
    keepFirstWaypointEmpty,
  },
}) as const;

export const frontendStateDirectionsAddWaypoints = (waypoints: ReadonlyArray<Waypoint>, keepFirstWaypointEmpty: boolean) => ({
  type: FRONTEND_STATE_DIRECTIONS_ADD_WAYPOINTS,
  payload: {
    waypoints,
    keepFirstWaypointEmpty,
  },
}) as const;

export const frontendStateDirectionsCollapsingChanged = (newCollapsing: RouteCollapsingLevel) => ({
  type: FRONTEND_STATE_DIRECTIONS_COLLAPSING_CHANGED,
  payload: {
    newCollapsing,
  },
}) as const;

export const routeUiDataLoaded = (routeId: string, data: RouteUiData, routeCollapsingLevel: RouteCollapsingLevel) => ({
  type: FRONTEND_STATE_ROUTE_UI_DATA_LOADED,
  payload: {
    routeId,
    data,
    routeCollapsingLevel,
  },
}) as const;

export const routeLegExpanded = (routeId: string, legIndex: number) => ({
  type: FRONTEND_STATE_ROUTE_LEG_EXPANDED,
  payload: {
    routeId,
    legIndex,
  },
}) as const;

export const routeLegCollapsed = (routeId: string, legIndex: number) => ({
  type: FRONTEND_STATE_ROUTE_LEG_COLLAPSED,
  payload: {
    routeId,
    legIndex,
  },
}) as const;

export const routeExpanded = (routeId: string) => ({
  type: FRONTEND_STATE_ROUTE_EXPANDED,
  payload: {
    routeId,
  },
}) as const;

export const routeCollapsed = (routeId: string) => ({
  type: FRONTEND_STATE_ROUTE_COLLAPSED,
  payload: {
    routeId,
  },
}) as const;

export const frontendStateDirectionsUpdateRouteData = (routeId: string, routes: Array<google.maps.DirectionsRoute>) => ({
  type: FRONTEND_STATE_DIRECTIONS_UPDATE_ROUTE_DATA,
  payload: {
    routeId,
    routes,
  },
}) as const;
