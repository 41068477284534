import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Page } from '~/_shared/constants/routes';
import { ExportImageMode } from '~/_shared/types/exportImage/exportImage';
import { dataPageMapTools } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useWindowHeight } from '~/_shared/utils/hooks/useWindowHeight';
import {
  buildUrl, parseUrl,
} from '~/_shared/utils/url/url.helpers';
import { decideIsEnoughVerticalSpaceForMenuItems } from '~/mainMenu/mainMenu.helpers';
import { type MainMenuItem } from '~/mainMenu/mainMenuItem.enum';
import { isSidebarApp } from '~/sidebar/sidebarApp.helpers';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  clearLastOpenedMapPageTool, closeLeftSidebar, openLeftSidebarApp, openMapTool, openMapToolExclusively,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { OPENED_APP_FRAGMENT_IDENTIFIER } from '~/store/frontendState/leftSidebar/leftSidebar.constants';
import { useFrontendStateLeftSidebarLastOpenedMapPageToolSelector } from '~/store/frontendState/leftSidebar/leftSidebar.selectors';
import { isPageWithMapTools } from '~/store/frontendState/leftSidebar/leftSidebar.state';
import { mapPresentationalSetShowPresentationalMenu } from '~/store/frontendState/mapPresentational/mapPresentational.actionCreators';
import { useIsPresentationalMenuShownSelector } from '~/store/frontendState/mapPresentational/mapPresentational.selectors';
import {
  usePublicMapSettingDefaultPresentationalMapToolSelector, usePublicMapSettingsMapToolsMenuOpenSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { topBarHeaderHeight } from '~/topBar/desktopTopBar.styles';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { SidebarComponent } from './sidebar.component';
import { type SidebarApp } from './sidebarApp.enum';

type SidebarContainerProps = Readonly<{
  visibleMenuItems: ReadonlySet<MainMenuItem>;
}>;

export const SidebarContainer: FC<SidebarContainerProps> = props => {
  const dispatch = useDispatch();
  const currentPage = useGetCurrentPageInfo().page;
  const openedApp = useSelector(s => s.frontendState.leftSidebar.openedApp);
  const lastOpenedMapTool = useFrontendStateLeftSidebarLastOpenedMapPageToolSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isPresentationalMenuOpen = useIsPresentationalMenuShownSelector();
  const defaultPresentationalMenuTool = usePublicMapSettingDefaultPresentationalMapToolSelector();
  const isMenuDefaultOpen = usePublicMapSettingsMapToolsMenuOpenSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();
  const location = useLocation();
  const windowHeight = useWindowHeight();

  const closeApp = useCallback(() => dispatch(closeLeftSidebar()), [dispatch]);
  const clearLastOpenedToolOfCurrentPage = useCallback(() => {
    if (isPageWithMapTools(currentPage)) {
      dispatch(clearLastOpenedMapPageTool(currentPage));
    }
  }, [dispatch, currentPage]);

  const closeSidebar = useCallback(() => {
    clearLastOpenedToolOfCurrentPage();
    closeApp();
  }, [clearLastOpenedToolOfCurrentPage, closeApp]);

  const openApp = useCallback((app: SidebarApp) => {
    dispatch(openLeftSidebarApp(app, currentPage));
    dispatch(mapPresentationalSetShowPresentationalMenu(false));
  }, [currentPage, dispatch]);

  const openDefaultTool = useCallback(() => {
    if (defaultPresentationalMenuTool && props.visibleMenuItems.has(defaultPresentationalMenuTool as MainMenuItem)) {
      openApp(defaultPresentationalMenuTool as unknown as SidebarApp);
      dispatch(openMapToolExclusively(defaultPresentationalMenuTool));
    }
  }, [defaultPresentationalMenuTool, dispatch, openApp, props.visibleMenuItems]);

  const isLegendOrExportMode = useMemo(() => {
    const { mode, legendMode } = exportImageSettings || { mode: null, legendMode: null };
    return legendMode || mode === ExportImageMode.Map || mode === ExportImageMode.MapAndLegend;
  }, [exportImageSettings]);

  const noMainMenu = !!isLegendOrExportMode || props.visibleMenuItems.size === 0;

  useEffect(() => {
    const url = window.location.href;
    const { fragmentParameters, queryParameters, urlPart } = parseUrl(url);

    if (fragmentParameters && isSidebarApp(fragmentParameters[OPENED_APP_FRAGMENT_IDENTIFIER])) {
      const urlWithoutFragment = buildUrl(urlPart, queryParameters, { ...fragmentParameters, [OPENED_APP_FRAGMENT_IDENTIFIER]: undefined });
      history.pushState(undefined, '', urlWithoutFragment);
      dispatch(openLeftSidebarApp(fragmentParameters[OPENED_APP_FRAGMENT_IDENTIFIER], currentPage));
    }
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (isMapPresentational && isPresentationalMenuOpen && props.visibleMenuItems.size === 1) {
      openDefaultTool();
    }
  }, [isPresentationalMenuOpen, isMapPresentational, props.visibleMenuItems, dispatch,
    openDefaultTool]);

  useEffect(() => {
    if (isMapPresentational && isMenuDefaultOpen) {
      openDefaultTool();

      const isEnoughVerticalSpaceForMenuItems = decideIsEnoughVerticalSpaceForMenuItems(
        windowHeight - topBarHeaderHeight, props.visibleMenuItems.size
      );
      dispatch(mapPresentationalSetShowPresentationalMenu(isEnoughVerticalSpaceForMenuItems));
    }
  }, [dispatch, openDefaultTool, isMapPresentational, isMenuDefaultOpen, props.visibleMenuItems, windowHeight]);

  // Remember the last opened tool
  useEffect(() => {
    if (!openedApp && isPageWithMapTools(currentPage)) {
      const lastTool = lastOpenedMapTool[currentPage];
      if (lastTool) {
        const isAdminMapWithValidTool = currentPage === Page.ADMIN_MAP && !dataPageMapTools.has(lastTool);
        const isAdminDataWithValidTool = currentPage === Page.ADMIN_DATA && dataPageMapTools.has(lastTool);

        if (isAdminMapWithValidTool || isAdminDataWithValidTool) {
          clearLastOpenedToolOfCurrentPage();
          dispatch(openMapTool(lastTool));
        }
      }
    }
  }, [dispatch, lastOpenedMapTool, openedApp, currentPage, clearLastOpenedToolOfCurrentPage]);

  // close opened app when unmounted
  useEffect(() => () => {
    closeApp();
  }, [closeApp]);

  return (
    <SidebarComponent
      close={isLegendOrExportMode ? undefined : closeSidebar}
      isMobileScreen={isMobileScreen}
      isSidebarVisible={!isMapPresentational || isPresentationalMenuOpen}
      noMainMenu={isMapPresentational ? noMainMenu : undefined}
      openApp={openApp}
      openedApp={openedApp}
      pathname={location.pathname}
      sidebarHeight={windowHeight - topBarHeaderHeight}
      visibleMenuItems={props.visibleMenuItems}
    />
  );
};
