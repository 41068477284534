import {
  type MapSettingsEtag, type MapSettingsEtagItem, type MapSettingsKeyOf,
} from '../../../map/map.repository';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import {
  MAP_SETTINGS_FETCH_DATA_SUCCESS, MAP_SETTINGS_SYNC_SUCCESS,
} from '../data/mapSettingsData.actionTypes';
import { type MapSettingsConcurrencyAction } from './mapSettingsConcurrency.action';
import {
  MAP_SETTINGS_CONCURRENCY_SET_ALERT_VISIBILITY,
  MAP_SETTINGS_CONCURRENCY_SET_IS_OFFLINE,
} from './mapSettingsConcurrency.actionTypes';
import { type MapSettingsConcurrencyState } from './mapSettingsConcurrency.state';

const mapSettingsConcurrencyInitialState: MapSettingsConcurrencyState = {
  isOffline: false,
  showOfflineAlert: false,
  etag: null,
};

export const mapSettingsConcurrencyReducer = (
  state = mapSettingsConcurrencyInitialState,
  action: MapSettingsConcurrencyAction | MapSettingsDataAction
): MapSettingsConcurrencyState => {
  switch (action.type) {
    case MAP_SETTINGS_CONCURRENCY_SET_IS_OFFLINE: {
      return {
        ...state,
        isOffline: action.payload.isOffline,
        showOfflineAlert: action.payload.isOffline,
      };
    }

    case MAP_SETTINGS_CONCURRENCY_SET_ALERT_VISIBILITY: {
      return {
        ...state,
        showOfflineAlert: action.payload.showAlert,
      };
    }

    case MAP_SETTINGS_SYNC_SUCCESS: {
      if (!action.payload.etag) {
        return state;
      }

      const newEtag: MapSettingsEtag = { ...state.etag ?? {} };

      Object.entries(action.payload.etag).forEach(([property, value]: [MapSettingsKeyOf, MapSettingsEtagItem]) => {
        if (!value) {
          return;
        }

        newEtag[property] = value;
      });

      return {
        ...state,
        etag: newEtag,
      };
    }

    case MAP_SETTINGS_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        etag: action.payload.mapSettingsData.etag ?? null,
      };
    }

    default:
      return state;
  }
};
