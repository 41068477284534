import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { createUuid } from '../../../../_shared/utils/createUuid';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';

export type MapShapeInstance = {
  readonly id: Uuid;
  readonly outlines: MapObjectOutlineInstance[];
};

export const shapeOutlinesToPath = (outlines: MapObjectOutlineInstance[]): PolygonPath =>
  outlines.map(o => ({ lat: o.lat, lng: o.lng }));

export const pathToMapShapeOutlines = (path: PolygonPath): MapObjectOutlineInstance[] =>
  path.map(coordinate => ({ lat: coordinate.lat, lng: coordinate.lng, id: createUuid() }));

export const cloneMapShape = (instance: MapShapeInstance): MapShapeInstance => ({
  // only clone known fields, we don't want to propagate internal data
  id: instance.id,
  outlines: instance.outlines.map(o => ({
    id: o.id,
    lat: o.lat,
    lng: o.lng,
  })),
});
