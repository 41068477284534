import { type DeepWritable } from 'ts-essentials';
import {
  createUuid, type Uuid,
} from '~/_shared/utils/createUuid';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { type MapSettingsDrawingResponse } from '~/map/map.repository';
import {
  type DrawingItem, type DrawingItemsState,
} from './items/drawingItems.state';
import { mapSettingsDrawingInitialState } from './mapSettingsDrawing.reducer';
import { type MapSettingsDrawingState } from './mapSettingsDrawing.state';

export const mapSettingsDrawingSerializer = {
  jsonToState: (json: MapSettingsDrawingResponse): MapSettingsDrawingState => {
    const { items: jsonItems, settings: jsonSettings, ...jsonRest } = json;
    const items = ensureItemsUniqueIds(
      ensureMapSettingsDrawingItemsBackwardCompatibility(jsonItems));

    return {
      ...mapSettingsDrawingInitialState,
      ...jsonRest,
      settings: {
        ...mapSettingsDrawingInitialState.settings,
        ...jsonSettings,
      },
      items,
    };
  },
  stateToJson: (state: MapSettingsDrawingState): MapSettingsDrawingResponse => {
    return state;
  },
};

// Remove after release
// Previously drawing items were an object but were changed to an array
const ensureMapSettingsDrawingItemsBackwardCompatibility = (jsonItems: DrawingItemsState | undefined): DrawingItemsState =>
  jsonItems && Array.isArray(jsonItems) ? jsonItems : [];

// When layered map is created from two or more maps where one map was a clone of another,
// some drawing items can have the same ids.
const ensureItemsUniqueIds = (items: DrawingItemsState) => {
  const usedIds = new Set<Uuid>();

  return items.map(item => {
    if (!usedIds.has(item.value.id)) {
      usedIds.add(item.value.id);
      return item;
    }
    else {
      const clone = cloneDeep<DeepWritable<DrawingItem>>(item);
      clone.value.id = createUuid();
      return clone;
    }
  });
};
