import { useMemo } from 'react';
import {
  MapOutlinePosition,
  type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { convertPxDistanceToDifferentZoom } from '../../../../_shared/utils/distance/distance.helpers';
import { useMapZoomChangeFinished } from '../../../../_shared/utils/hooks/useMapZoomChangeFinished';
import { type MapCircleInstance } from './mapCircleModel';

export const useMapCircleOutlines = (
  circle: MapCircleInstance,
  options: {
    predicate: boolean;
    hideDuringZoomChangeAnimation?: boolean;
  },
): MapOutlinePositionInstance[] => {
  const mapZoom = useMapComponentZoomSelector();

  const { zoomChangeEtag, lastZoom } = useMapZoomChangeFinished(options.predicate);

  return useMemo((): MapOutlinePositionInstance[] => {
    if (!zoomChangeEtag || mapZoom === null) {
      return [];
    }

    // Hide outlines when map is performing zoom animation.
    if (options.hideDuringZoomChangeAnimation && mapZoom !== lastZoom) {
      return [];
    }

    const relativeOffset = circle.radius + (circle.strokeWeight / 2);
    const offset = circle.sizeOnLevel
      ? convertPxDistanceToDifferentZoom({ distance: relativeOffset, originalZoom: circle.sizeOnLevel, newZoom: mapZoom })
      : relativeOffset;

    return [{
      id: getMapCircleOutlineId(circle.id, MapOutlinePosition.Top),
      position: MapOutlinePosition.Top,
      lat: circle.lat,
      lng: circle.lng,
      offsetY: -offset,
      offsetX: 0,
    }, {
      id: getMapCircleOutlineId(circle.id, MapOutlinePosition.Bottom),
      position: MapOutlinePosition.Bottom,
      lat: circle.lat,
      lng: circle.lng,
      offsetY: offset,
      offsetX: 0,
    }, {
      id: getMapCircleOutlineId(circle.id, MapOutlinePosition.Right),
      position: MapOutlinePosition.Right,
      lat: circle.lat,
      lng: circle.lng,
      offsetY: 0,
      offsetX: offset,
    }, {
      id: getMapCircleOutlineId(circle.id, MapOutlinePosition.Left),
      position: MapOutlinePosition.Left,
      lat: circle.lat,
      lng: circle.lng,
      offsetY: 0,
      offsetX: -offset,
    }];
  }, [circle.id, circle.lat, circle.lng, circle.radius, circle.sizeOnLevel, circle.strokeWeight,
    options.hideDuringZoomChangeAnimation, mapZoom, zoomChangeEtag, lastZoom]);
};

export const getMapCircleOutlineId = (circleAreaId: string, position: MapOutlinePosition): string =>
  `${circleAreaId}-${position}`;
