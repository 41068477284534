import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { type MarkersVisualSettings } from '~/_shared/types/markers/visualSettings.types';
import { select } from '~/_shared/utils/saga/effects';
import {
  selectLatLngSpreadsheetData, type SpreadsheetLatLngRowData,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { spreadsheetDataCheckForDeletedRows } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { removeIndividualMarkerSettings } from '../mapSettingsMarkersGeneral.actionCreators';
import { mapSettingsMarkersGeneralSelector } from '../mapSettingsMarkersGeneral.selectors';
import { getIndividualMarkerSettings } from '../mapSettingsMarkersGeneralSettings.helpers';

export function* removeDeletedRowsIndividualMarkerSettingsSaga() {
  yield takeLatest(spreadsheetDataCheckForDeletedRows.triggerActions, removeDeletedRowsIndividualMarkerSettings);
}

function* removeDeletedRowsIndividualMarkerSettings() {
  const proceed: boolean = yield call(spreadsheetDataCheckForDeletedRows.conditionSaga);
  if (!proceed) {
    return;
  }

  const markerVisualSettings: MarkersVisualSettings = yield select<MarkersVisualSettings>(mapSettingsMarkersGeneralSelector);
  const spreadsheetData: SpreadsheetLatLngRowData = yield select<SpreadsheetLatLngRowData>(selectLatLngSpreadsheetData);

  const individualMarkerSettings = getIndividualMarkerSettings(markerVisualSettings);

  const settingsWithoutRows = individualMarkerSettings
    .map(item => ({ spreadsheetId: item.spreadsheetId, rowId: item.rowId }))
    .filter(item => !spreadsheetData.getRow(item));

  if (settingsWithoutRows.length) {
    yield put(removeIndividualMarkerSettings(settingsWithoutRows));
  }
}
