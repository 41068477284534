import {
  type FC, type ReactElement, type ReactNode,
} from 'react';
import { ActiveLicenseStates } from '~/_shared/types/client/license.helpers';
import { useUserCurrentClientSelector } from '../../../store/userData/userData.selectors';
import { type ClientLicenseState } from './license';

export type SubscriptionBlockerProps = {
  allowedState: ClientLicenseState[];
  children: ReactNode;
  renderWhenBlocked?: () => ReactElement;
};

export const ExpiredSubscriptionBlocker: FC<Pick<SubscriptionBlockerProps, 'renderWhenBlocked' | 'children'>> = ({ children, renderWhenBlocked }) => (
  <SubscriptionBlocker allowedState={ActiveLicenseStates} renderWhenBlocked={renderWhenBlocked}>
    {children}
  </SubscriptionBlocker>
);

export const SubscriptionBlocker: FC<SubscriptionBlockerProps> = ({ allowedState, children, renderWhenBlocked }) => {
  const currentClient = useUserCurrentClientSelector();
  const licenseState = currentClient?.license.state;

  const isAllowed = licenseState && allowedState.includes(licenseState);

  if (isAllowed) {
    return <>{children}</>;
  }
  else {
    return (renderWhenBlocked?.() ?? null);
  }
};
