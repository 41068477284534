import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { type BoundaryAction } from '../boundaryTools.types';
import { BoundarySelectedOption } from '../fill/boundaryFill.enums';
import { CreateCustomBoundaryGroupPaneContainer } from './createCustomBoundaryPane/createCustomBoundaryGroupPane.container';
import { LoadCustomBoundaryGroupPaneContainer } from './loadCustomBoundaryPane/loadCustomBoundaryGroupPane.container';
import { LoadGlobalBoundaryGroupPaneContainer } from './loadGlobalBoundaryPane/loadGlobalBoundaryGroupPane.container';

const panelDropdownContentStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  padding: '16px',
});

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const dropdownTriggerStyle = ({ theme, isPlaceholder }: ThemeProps<{ isPlaceholder: boolean }>) => css({
  color: isPlaceholder ? theme.textColors.disabled : undefined,
  height: 40,
});

export enum BoundaryActionType {
  LoadGeographicBoundary,
  LoadSavedTerritoryGroup,
  CreateTerritories
}

type LoadBoundaryPaneComponentProps = Readonly<{
  actions: ReadonlyArray<BoundaryAction>;
  action: BoundaryActionType | null;
  setAction: (action: BoundaryActionType | null) => void;
  checkIsNumeric: (columnId: string | null, spreadsheetId: number | null) => Promise<boolean>;
  reset: () => void;
}>;

export const LoadBoundaryPaneComponent: FC<LoadBoundaryPaneComponentProps> = ({ reset, ...props }) => {
  const [formKey, setFormKey] = useState<number>(0);
  const [t] = useTranslation();
  const theme = useTheme();

  const boundaryActionListOptions: DropdownOption<number>[] = useMemo(() =>
    props.actions.map(item => ({
      name: item.name,
      value: item.value,
    })), [props.actions]);

  const resetForm = useCallback(() => {
    reset();
    setFormKey(formKey + 1);
  }, [formKey, reset]);

  return (
    <div css={panelDropdownContentStyle({ theme })}>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>1. {t('Select Action')}</div>
        <RegularDropdownComponent<BoundaryActionType>
          inPortal
          onChange={props.setAction}
          options={boundaryActionListOptions}
          triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: props.action === null })}
          value={props.action}
          placeholder={t(BoundarySelectedOption.SelectActionType)}
        />
      </div>

      {props.action === BoundaryActionType.LoadGeographicBoundary && (
        <LoadGlobalBoundaryGroupPaneContainer
          key={`load-global-${formKey}`}
          checkIsNumeric={props.checkIsNumeric}
          resetForm={resetForm}
        />
      )}

      {props.action === BoundaryActionType.LoadSavedTerritoryGroup && (
        <LoadCustomBoundaryGroupPaneContainer
          key={`load-custom-${formKey}`}
          resetForm={resetForm}
        />
      )}

      {props.action === BoundaryActionType.CreateTerritories && (
        <CreateCustomBoundaryGroupPaneContainer
          key={`create-custom-${formKey}`}
          resetForm={resetForm}
        />
      )}
    </div>
  );
};
