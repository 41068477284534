import { css } from '@emotion/react';
import {
  type CSSProperties,
  forwardRef, type HTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

type StackComponentProps = PropsWithChildren<{
  readonly children: ReactNode;
  readonly gap: CSSProperties['gap'];
  readonly className?: string;
}> & HTMLAttributes<HTMLDivElement>;

const stackWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
});

export const Stack = forwardRef<HTMLDivElement, StackComponentProps>(({
  children,
  gap,
  className,
  ...restProps
}, ref) => {
  return (
    <div
      className={className}
      css={[stackWrapperStyles, { gap }]}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  );
});

Stack.displayName = 'Stack';
