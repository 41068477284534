import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  warningColorStyle, warningIconStyle, warningWrapperStyle,
} from '~/_shared/baseComponents/warning/warning.styles';

export type StackedWarningsProps = Readonly<{
  className?: string;
  warnings?: ReadonlyArray<string>;
}>;

export const StackedWarningsComponent: FC<StackedWarningsProps> = props => {
  if (!props.warnings?.length) {
    return null;
  }

  return (
    <div
      css={warningWrapperStyle}
      className={props.className}
    >
      {props.warnings.map((warningMessage, i) => (
        <div
          key={i}
          css={warningColorStyle}
        >
          <FontAwesomeIcon
            css={warningIconStyle}
            icon={faExclamationTriangle}
          />
          {warningMessage}
        </div>
      ))}
    </div>
  );
};
