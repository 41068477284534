import { noop } from '~/_shared/utils/function.helpers';
import { logError } from '~/_shared/utils/logError';

type ExternallyResolvedPromiseExtension<TResult> = {
  readonly resolve: (result: TResult) => void;
  readonly isResolved: () => boolean;
};

export const getExternallyResolvedPromise = <TResult>(): Promise<TResult> & ExternallyResolvedPromiseExtension<TResult> => {
  let promiseResolve: (result: TResult) => void = noop;
  let isResolved: boolean = false;
  const promise = new Promise<TResult>((resolve) => {
    promiseResolve = (result: TResult) => {
      if (!isResolved) {
        isResolved = true;
        resolve(result);
      }
      else {
        logError('Resolving for a second time!');
      }
    };
  });

  return Object.assign(promise, { resolve: promiseResolve, isResolved: () => isResolved });
};

export const isExternallyResolvedPromise = <TValue>(arg: Promise<TValue>): arg is ReturnType<typeof getExternallyResolvedPromise<TValue>> => {
  return typeof((arg as any).isResolved) === 'function';
};
