import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import { LottieAnimationComponent } from '../../baseComponents/lottieAnimation/lottieAnimation.component';
import { getLottieAnimationDuration } from '../../baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '../../baseComponents/lottieAnimation/lottieAnimation.types';
import {
  Breakpoint, mq,
} from '../../style.mixins';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

export type EmailSentNotificationModalProps = ModalProps;

const modalContentStyle = css({
  padding: 0,
});

const textStyle = ({ theme }: ThemeProps) => css({
  padding: '35px 10px',
  margin: 0,
  color: theme.textColors.success,
  textAlign: 'center',
  [mq(Breakpoint.small)]: {
    padding: '45px 10px',
  },
});

const contentWrapperStyle = css({
  position: 'relative',
});

const animationStyle = css({
  position: 'absolute',
  top: '50%',
  right: 24,
  transform: 'translateY(-50%)',
});

export const EmailSentNotificationModalContainer: FC<EmailSentNotificationModalProps> = (props) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();
  const { onClose } = props;

  useEffect(() => {
    setTimeout(() => {
      setStartAnimation(true);
    }, 500);
  }, []);

  useEffect(() => {
    const duration = getLottieAnimationDuration(LottieAnimationTypes.MailChecked) + 1000;

    const timeoutId = setTimeout(() => {
      onClose();
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onClose]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Email Notification')}
      contentStyle={modalContentStyle}
    >
      <div css={contentWrapperStyle}>
        <p css={textStyle({ theme })}>{t('Email was sent!')}</p>

        <div css={animationStyle}>
          <LottieAnimationComponent
            progress={startAnimation ? 100 : 0}
            size={48}
            type={LottieAnimationTypes.MailChecked}
            autoplay={startAnimation}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
