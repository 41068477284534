import { css } from '@emotion/react';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import { useTranslation } from '~/_shared/utils/hooks';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  width: 190,
});

type IdentifierAdditionalElementProps = Readonly<{
  buttonTitle?: string;
  elementTitle?: string;

  onClick: () => void;
}>;

export const IdentifierAdditionalElementComponent: React.FC<IdentifierAdditionalElementProps> = props => {
  const [t] = useTranslation();

  return (
    <div css={wrapperStyle} >
      {props.elementTitle}
      <AddMorePlaceholderComponent
        onClick={props.onClick}
        title={props.buttonTitle || t('Add Another Identifier')}
        width={190}
      />
    </div>
  );
};
