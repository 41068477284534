import { SearchMatchingBehaviour } from '../../../_shared/constants/searchMatchingBehaviour.enum';
import { type MapSettingSearchAction } from './mapSettingSearch.action';
import {
  MAP_SETTINGS_SEARCH_SET_FILTER_BY,
  MAP_SETTINGS_SEARCH_SET_SEARCH_SETTINGS,
} from './mapSettingsSearch.actionTypes';
import { type MapSettingsSearchState } from './mapSettingsSearch.state';

export const mapSettingsSearchInitialState: MapSettingsSearchState = {
  selectedMatchingBehaviour: SearchMatchingBehaviour.ShowOnlyMatches,
  shouldZoomOnMatches: false,
  shouldMatchExactWords: false,
  filterBy: null,
};

export const mapSettingsSearchReducer = (state = mapSettingsSearchInitialState, action: MapSettingSearchAction): MapSettingsSearchState => {
  switch (action.type) {
    case MAP_SETTINGS_SEARCH_SET_SEARCH_SETTINGS: {
      return {
        ...state,
        selectedMatchingBehaviour: action.payload.selectedMatchingBehaviour,
        shouldMatchExactWords: action.payload.shouldMatchExactWords,
        shouldZoomOnMatches: action.payload.shouldZoomOnMatches,
      };
    }

    case MAP_SETTINGS_SEARCH_SET_FILTER_BY: {
      return {
        ...state,
        filterBy: action.payload.filterBy,
      };
    }

    default: {
      return state;
    }
  }
};
