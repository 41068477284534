import {
  type FC, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMapUrl } from '~/map/map.helpers';
import { type AppState } from '~/store/app.store';
import { MapConnectSettings } from '../mapConnectSettings';
import { useCreateLayeredMap } from '../useCreateLayeredMap';
import {
  CreateLayeredMapModalComponent, type CreateLayeredMapModalSettings,
} from './createLayeredMapModal.component';

export type CreateLayeredMapModalContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const CreateLayeredMapModalContainer: FC<CreateLayeredMapModalContainerProps> = (props) => {
  const clientId = useSelector((state: AppState) => state.userData.clientId);
  const navigate = useNavigate();
  const { createLayeredMap, isLoading: isCreatingLayeredMap } = useCreateLayeredMap();

  const onCreateLayeredMapSuccess = useCallback((mapId: number) => {
    navigate(getMapUrl(mapId));
    props.onClose();
  }, [navigate, props]);

  const onCreateLayeredMap = useCallback((settings: CreateLayeredMapModalSettings) => {
    const selectedMapListItem = settings.selectedMapListItems[0]?.id;
    if (clientId !== null && selectedMapListItem) {
      createLayeredMap(
        clientId,
        {
          name: settings.mapName,
          description: settings.mapDescription,
          integrated: true,
          connected: settings.connectionSetting === MapConnectSettings.ConnectedInRealTime
            || settings.connectionSetting === MapConnectSettings.ConnectedWithApproval,
          real_time: settings.connectionSetting === MapConnectSettings.ConnectedInRealTime,
          maps: settings.selectedMapListItems.map(sM => sM.id),
          primary_map: selectedMapListItem,
          matches: settings.matches,
        },
        onCreateLayeredMapSuccess
      );
    }
  }, [createLayeredMap, clientId, onCreateLayeredMapSuccess]);

  return (
    <CreateLayeredMapModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCreatingLayeredMap={isCreatingLayeredMap}
      onCreateLayeredMap={onCreateLayeredMap}
    />
  );
};
