import { css } from '@emotion/react';
import {
  faCog,
  faExchange, faExternalLink,
  faPencil,
  faSearchLocation,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  type Ref,
} from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  createColor,
  rgbColorToString,
} from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type UnitSystem } from '../../_shared/types/googleMaps/googleMaps.types';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { stopPropagationAnd } from '../../_shared/utils/dom/dom.helpers';
import { useTranslation } from '../../_shared/utils/hooks';
import { type MapSettingsRoute } from '../../store/mapSettings/directions/mapSettingsDirections.state';
import { getDefaultOrCustomRouteName } from '../panel/directionsPanel.helpers';
import {
  getDistanceLabel,
  getDurationLabel,
} from './directionsListing.helpers';

const headingWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const colorTileStyle = (color: ColorResult) => css({
  display: 'block',
  marginRight: 10,
  width: 22,
  height: 22,
  borderRadius: 4,
  background: rgbColorToString(color.rgb),
  flexShrink: 0,
});

const settingsButtonStyle = ({ theme }: ThemeProps) => css({
  background: 'none',
  border: 'none',
  fontSize: '16px',
  color: theme.textColors.primary,
});

const settingsButtonWrapperStyle = css({
  marginLeft: 'auto',
});

const routeInfoStyle = css({
  fontSize: '12px',
  fontWeight: 400,
  margin: 0,
  flex: 1,
  overflow: 'hidden',
  lineHeight: 1.15,
});

const routeEstimationStyle = css({
  margin: 0,
});

const routeNameStyle = css({
  textTransform: 'uppercase',
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const stepsCountStyle = css({
  textTransform: 'uppercase',
  margin: 0,
});

type RouteHeaderProps = Readonly<{
  isLoading: boolean;
  isMenuVisible: boolean;
  routeItem: MapSettingsRoute;
  totalDistance: number;
  totalDuration: number;
  unitSystem: UnitSystem;

  onEditRoute?: () => void;
  onHideMenu?: () => void;
  onLoadDirections?: () => void;
  onRemoveRoute?: () => void;
  onRouteExport?: () => void;
  onShowMenu?: () => void;
  onZoomToRoute?: () => void;
}>;

export const RouteHeaderComponent: FC<RouteHeaderProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={headingWrapperStyle}>
      <span css={colorTileStyle(createColor(props.routeItem.color))} />

      <div css={routeInfoStyle}>
        <p css={routeNameStyle}>{getDefaultOrCustomRouteName(props.routeItem.name, t)}</p>
        <p css={stepsCountStyle}>
          ({t('{{count}} Stops', { count: props.routeItem.waypoints.length - 1 })})
        </p>
        <p css={routeEstimationStyle}>
          {getDistanceLabel(props.totalDistance, props.unitSystem)} — {getDurationLabel(props.totalDuration, t)}
        </p>
      </div>

      <div
        css={settingsButtonWrapperStyle}
        onClick={e => e.stopPropagation()}
      >
        <ContextMenuComponent
          isFixed
          renderMenuContent={() => (
            <>
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  icon={faPencil}
                  onClick={props.onEditRoute}
                >
                  {t('Edit Route')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  icon={faExchange}
                  onClick={props.onLoadDirections}
                >
                  {t('Load Directions')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
              {props.onRouteExport && (
                <ContextMenuItemCoreComponent>
                  <ContextMenuDefaultItem
                    icon={faExternalLink}
                    onClick={props.onRouteExport}
                  >
                    {t('Export Data')}
                  </ContextMenuDefaultItem>
                </ContextMenuItemCoreComponent>
              )}
              <ContextMenuItemCoreComponent>
                <ContextMenuDefaultItem
                  icon={faSearchLocation}
                  onClick={props.onZoomToRoute}
                >
                  {t('Zoom to Route')}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
              <ContextMenuItemCoreComponent>
                {props.onRemoveRoute && (
                  <ContextMenuDefaultItem
                    icon={faTrash}
                    isDestructive
                    onClick={props.onRemoveRoute}
                  >
                    {t('Delete Route')}
                  </ContextMenuDefaultItem>
                )}
              </ContextMenuItemCoreComponent>
            </>
          )}
          isVisible={props.isMenuVisible}
          onHide={props.onHideMenu}
          preferredPlacement="bottom-end"
        >
          {ref => (
            <button
              ref={ref as Ref<HTMLButtonElement>}
              css={settingsButtonStyle({ theme })}
              onClick={stopPropagationAnd(props.onShowMenu)}
            >
              <FontAwesomeIcon icon={faCog} />
            </button>
          )}
        </ContextMenuComponent>
      </div>

      {props.isLoading && (
        <OverlayLoaderComponent loaderSize={22} />
      )}
    </div>
  );
};
