import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../../../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useAjaxCall } from '../../../../../_shared/utils/hooks/useAjaxCall';
import { boundaryGroupsDeleteSuccess } from '../../../../../store/boundaryGroups/boundaryGroups.actionCreators';
import { deleteBoundaryGroup } from '../../../../../store/boundaryGroups/boundaryGroups.repository';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';

export const useBoundaryGroupRemove = () => {
  const { closeConfirmationModal, openConfirmationModal } = useConfirmationModal();
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, boundaryGroupId: number) =>
    deleteBoundaryGroup(clientId, boundaryGroupId)
  );

  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const removeBoundaryGroup = useCallback((clientId: number, boundaryGroupId: number) => {
    return invokeAjax(clientId, boundaryGroupId);
  }, [invokeAjax]);

  const removeBoundaryGroupRequest = useCallback((boundaryGroupId: number, usedOnMapsCount: number, onSuccess?: () => void) => {
    if (clientId === null) {
      return;
    }

    const text = t('Are you sure you want to delete territory group? It is currently used on {{count}} maps.', {
      count: usedOnMapsCount,
    });

    openConfirmationModal({
      title: t('Delete Territory Group'),
      confirmCaption: t('Proceed'),
      text,
      permanentConfirmSettings: {
        id: 'remove-boundary-group',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      showLoaderAfterConfirmClick: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        removeBoundaryGroup(clientId, boundaryGroupId)
          .then(() => {
            dispatch(boundaryGroupsDeleteSuccess(new Set([boundaryGroupId])));
            onSuccess?.();
          })
          .finally(() => {
            closeConfirmationModal();
          });
      },
    });
  }, [removeBoundaryGroup, dispatch, clientId, openConfirmationModal, closeConfirmationModal, t]);

  return {
    isLoading,
    removeBoundaryGroup: removeBoundaryGroupRequest,
  };
};
