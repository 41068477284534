import { useMemo } from 'react';
import {
  type MainMenuItem, MainMenuItems,
} from '~/mainMenu/mainMenuItem.enum';
import { usePublicMapSettingsSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';

export const useItemsVisibleOnPresentationalMap = (): ReadonlySet<MainMenuItem> => {
  const isMapPresentational = useIsMapPresentationalSelector();
  const publicMapSettings = usePublicMapSettingsSelector();

  const hasGroupingLegend = publicMapSettings.legendReplacesGrouping || publicMapSettings.displayLegendOnly;
  const hasBoundaryLegend = publicMapSettings.displayLegendOnly || publicMapSettings.boundaryTool;

  return useMemo(() => {
    const resultSet: Set<MainMenuItem> = new Set();

    if (!isMapPresentational) {
      return resultSet;
    }

    if (publicMapSettings.groupingTool && !hasGroupingLegend) {
      resultSet.add(MainMenuItems.Grouping);
    }

    if (hasGroupingLegend) {
      resultSet.add(MainMenuItems.MapLegend);
    }

    if (!publicMapSettings.displayLegendOnly && publicMapSettings.filterTool) {
      resultSet.add(MainMenuItems.Filter);
    }

    if (!publicMapSettings.displayLegendOnly && publicMapSettings.radiusProximity) {
      resultSet.add(MainMenuItems.Proximity);
    }

    if (!publicMapSettings.displayLegendOnly && publicMapSettings.routingDirections) {
      resultSet.add(MainMenuItems.Routing);
    }

    if (hasBoundaryLegend) {
      resultSet.add(MainMenuItems.Boundary);
    }

    if (!publicMapSettings.displayLegendOnly && publicMapSettings.heatMappingTool) {
      resultSet.add(MainMenuItems.HeatMap);
    }

    return resultSet;
  },
  [hasBoundaryLegend, hasGroupingLegend, isMapPresentational, publicMapSettings.displayLegendOnly,
    publicMapSettings.filterTool, publicMapSettings.groupingTool, publicMapSettings.heatMappingTool,
    publicMapSettings.radiusProximity, publicMapSettings.routingDirections]);
};
