export const BOUNDARY_GROUP_FETCH_ALL_REQUEST = 'BOUNDARY_GROUP_FETCH_ALL_REQUEST';
export const BOUNDARY_GROUP_FETCH_ALL_SUCCESS = 'BOUNDARY_GROUP_FETCH_ALL_SUCCESS';
export const BOUNDARY_GROUP_FETCH_ALL_ERROR = 'BOUNDARY_GROUP_FETCH_ALL_ERROR';

export const BOUNDARY_GROUP_CREATE_REQUEST = 'BOUNDARY_GROUP_CREATE_REQUEST';
export const BOUNDARY_GROUP_CREATE_ERROR = 'BOUNDARY_GROUP_CREATE_ERROR';
export const BOUNDARY_GROUP_CREATE_SUCCESS = 'BOUNDARY_GROUP_CREATE_SUCCESS';
export const BOUNDARY_GROUP_CHANGE_NAME = 'BOUNDARY_GROUP_CHANGE_NAME';
export const BOUNDARY_GROUP_CHANGE_NAME_SUCCESS = 'BOUNDARY_GROUP_CHANGE_NAME_SUCCESS';
export const BOUNDARY_GROUP_CHANGE_NAME_ERROR = 'BOUNDARY_GROUP_CHANGE_NAME_ERROR';
export const BOUNDARY_GROUPS_DELETE_REQUEST = 'BOUNDARY_GROUPS_DELETE_REQUEST';
export const BOUNDARY_GROUPS_DELETE_SUCCESS = 'BOUNDARY_GROUPS_DELETE_SUCCESS';
export const BOUNDARY_GROUPS_DELETE_ERROR = 'BOUNDARY_GROUPS_DELETE_ERROR';
export const BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST = 'BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST';
export const BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR = 'BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR';
export const BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED = 'BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED';
export const BOUNDARY_GROUPS_FILTER_CHANGED = 'BOUNDARY_GROUPS_FILTER_CHANGED';
