import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import { TermsAndPrivacyNoticeComponent } from '~/_shared/components/termsAndPrivacyNotice/termsAndPrivacyNotice.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { PasswordResetFormComponent } from '~/authorization/passwordResetPage/passwordResetForm.component';
import { InvitationPageCtaComponent } from '~/clientTeamManagement/invitationPage/cta/invitationPageCta.component';

const containerStyle = css({
  marginTop: -10,
  padding: 10,
  position: 'relative',
});

const textContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const messageStyle = ({ color }: { color?: string }) => css({
  marginTop: 50,
  color: color || 'inherit',
  textAlign: 'left',
});

const passwordFormContainerStyle = css({
  width: '100%',
});

const linksWrapperStyle = css({
  margin: '0 0 20px',
});

type NewUserInvitationProps = {
  acceptInvitation: (password: string, passwordConfirmation: string) => void;
  acceptInvitationError: string | null;
  client: string;
  invitee: string;
  isLoading: boolean;
  rejectInvitation: () => void;
};

export const NewUserInvitation: FC<NewUserInvitationProps> = ({
  acceptInvitation,
  acceptInvitationError,
  client,
  invitee,
  isLoading,
  rejectInvitation,
}) => {
  const [isProceeding, setIsProceeding] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();

  const handleProceedToAccountCreate = () => {
    setIsProceeding(true);
  };

  return (
    <div css={containerStyle}>
      {isProceeding ? (
        <>
          <div css={textContainerStyle}>
            <p css={messageStyle({ color: theme.textColors.primary })}>
              {isTextEmpty(client) ?
                t('Welcome {{invitee}}! You have been invited to join Maptive. Please create a password to continue.', { invitee }) :
                t('Welcome {{invitee}}! You have been invited to join Maptive by {{client}}. Please create a password to continue.', { invitee, client })
              }
            </p>
          </div>

          <div css={passwordFormContainerStyle}>
            <PasswordResetFormComponent
              isLoading={isLoading}
              onSubmit={acceptInvitation}
              error={acceptInvitationError || ''}
              submitSuccessText={t('Loading...')}
              renderButtons={(
                <InvitationPageCtaComponent
                  onDeclineClick={rejectInvitation}
                  acceptButtonCaption={t('Create Account')}
                  isCreateButtonSubmit
                />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <div css={textContainerStyle}>
            <p css={messageStyle({ color: theme.textColors.primary })}>
              {isTextEmpty(client) ?
                t('Welcome {{invitee}}! You have been invited to join Maptive. Do you wish to accept the invitation and sign up?', { invitee }) :
                t('Welcome {{invitee}}! You have been invited to join Maptive by {{client}}. Do you wish to accept the invitation and sign up?', {
                  invitee,
                  client,
                })}
            </p>
          </div>

          <TermsAndPrivacyNoticeComponent css={linksWrapperStyle} />

          <InvitationPageCtaComponent
            onDeclineClick={rejectInvitation}
            onAcceptClick={handleProceedToAccountCreate}
            acceptButtonCaption={t('Create Account')}
          />
        </>
      )}
    </div>
  );
};
