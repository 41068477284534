import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { ACTIVE_MAP_ELEMENTS_SET_ACTIONS } from '~/store/frontendState/activeMapElements/activeMapElements.actionTypes';
import { cloneDeep } from '../../../../../_shared/utils/object/deepMerge';
import { select } from '../../../../../_shared/utils/saga/effects';
import { type PickAction } from '../../../../../_shared/utils/types/action.type';
import { DrawingTool } from '../../../../../drawingTool/drawingTool.enums';
import { updateDrawingToolImageInterceptor } from '../../../../../map/map/drawingTool/image/updateDrawingToolImageInterceptor';
import { type DrawingItemsAction } from '../../../../mapSettings/drawing/items/drawingItems.action';
import { drawingItemsUpdateItem } from '../../../../mapSettings/drawing/items/drawingItems.actionCreators';
import {
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE, MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM,
} from '../../../../mapSettings/drawing/items/drawingItems.actionTypes';
import { type DrawingItem } from '../../../../mapSettings/drawing/items/drawingItems.state';
import { drawingEditClearSelectedDrawing } from './drawingEdit.actionCreators';
import {
  FRONTEND_STATE_DRAWING_EDIT_CONFIRM_CHANGES, FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES,
  FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT,
} from './drawingEdit.actionTypes';
import {
  drawingEditSelectedDrawingLatestSnapshotSelector,
  drawingEditSelectedDrawingSelector,
} from './drawingEdit.selectors';
import { type SelectedDrawing } from './drawingEdit.state';

export function* drawingEditSagas() {
  yield takeLatest(MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS, closeDrawingEdit);
  yield takeLatest(ACTIVE_MAP_ELEMENTS_SET_ACTIONS, closeDrawingEdit);
  yield takeLatest(MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE, onDrawingItemsOfTypeRemove);
  yield takeLatest(MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM, onDrawingItemRemove);
  yield takeLatest(FRONTEND_STATE_DRAWING_EDIT_CONFIRM_CHANGES, confirmDrawingEditChanges);
  yield takeLatest(FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT, confirmDrawingEditChanges);
  yield takeLatest(FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES, confirmDrawingEditChanges);
  yield takeLatest(MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS, onUserFilesDeleted);
}

function* closeDrawingEdit() {
  yield put(drawingEditClearSelectedDrawing());
}

function* onDrawingItemsOfTypeRemove(action: PickAction<DrawingItemsAction, typeof MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE>) {
  const latestDrawingSnapshot: DrawingItem | null = yield select<DrawingItem | null>(
    drawingEditSelectedDrawingLatestSnapshotSelector
  );

  if (!latestDrawingSnapshot || latestDrawingSnapshot.type !== action.payload.type) {
    return;
  }

  yield closeDrawingEdit();
}

function* onDrawingItemRemove(action: PickAction<DrawingItemsAction, typeof MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM>) {
  const latestDrawingSnapshot: DrawingItem | null = yield select<DrawingItem | null>(
    drawingEditSelectedDrawingLatestSnapshotSelector
  );

  if (latestDrawingSnapshot && latestDrawingSnapshot.value.id === action.payload.id) {
    yield closeDrawingEdit();
  }
}

function* confirmDrawingEditChanges() {
  let latestDrawingSnapshot: DrawingItem | null = yield select<DrawingItem | null>(
    drawingEditSelectedDrawingLatestSnapshotSelector
  );

  latestDrawingSnapshot = yield call(updateDrawingToolImageInterceptor, latestDrawingSnapshot);

  if (!latestDrawingSnapshot) {
    return;
  }

  yield put(drawingItemsUpdateItem(
    latestDrawingSnapshot.value.id, cloneDeep(latestDrawingSnapshot))
  );
}

function* onUserFilesDeleted(action: PickAction<DrawingItemsAction, typeof MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS>) {
  const selectedDrawing: SelectedDrawing | null = yield select<SelectedDrawing | null>(drawingEditSelectedDrawingSelector);

  const originalSnapshot = selectedDrawing?.snapshots[0];

  if (!originalSnapshot || originalSnapshot.type !== DrawingTool.Image) {
    return;
  }

  if (action.payload.attachmentIds.includes(originalSnapshot.value.attachmentId)) {
    yield put(drawingEditClearSelectedDrawing());
  }
}
