import { css } from '@emotion/react';
import {
  faLock, faMoon, faSun, faUnlock,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { AccordionComponent } from '~/_shared/baseComponents/accordion';
import { AccordionStepNumber } from '~/_shared/baseComponents/accordion/stepNumber.component';
import { EmbeddedYouTubeVideoComponent } from '~/_shared/baseComponents/embededYoutubeVideo/embeddedYouTubeVideo';
import { MAPTIVE_TUTORIAL_YOUTUBE_ID } from '~/_shared/baseComponents/embededYoutubeVideo/youtubeVideoIds.constants';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { darkTheme } from '~/_shared/themes/dark.theme';
import { lightTheme } from '~/_shared/themes/light.theme';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useCurrentThemeSelector } from '~/store/theme/theme.selectors';
import { useUpdateUserTheme } from '~/store/theme/useUpdateUserTheme.hook';
import { userDataTwoFactorSuccess } from '~/store/userData/userData.actionCreators';
import { useTwoFASelector } from '~/store/userData/userData.selectors';

const modalStyle = css({
  padding: 0,
});

const accordionStyle = css({
  padding: '16px 18px 18px',
});

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 16,
  gap: 18,
});

const toggleStyle = css({
  width: 55,
});

const rowStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});

const videoSectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

type WelcomeModalProps = ModalProps;

export const WelcomeModalComponent: FC<WelcomeModalProps> = props => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { updateUserTheme, themeChangeAllowed } = useUpdateUserTheme();
  const { openModal: openTwoFaModal } = useModal(ModalType.TwoFa);

  const currentTheme = useCurrentThemeSelector().type;
  const twoFaStats = useTwoFASelector();

  const handleThemeChange = useCallback((isOn: boolean) => {
    if (themeChangeAllowed) {
      updateUserTheme(isOn ? darkTheme.name : lightTheme.name);
    }
  }, [themeChangeAllowed, updateUserTheme]);

  const isTwoFaOn = useMemo(() =>
    Object.values(twoFaStats).some(stat => stat),
  [twoFaStats]);

  const toggleTwoFa = useCallback(() => {
    if (isTwoFaOn) {
      dispatch(userDataTwoFactorSuccess({
        email: false,
        google: false,
      }));
    }
    else {
      openTwoFaModal();
    }
  }, [dispatch, isTwoFaOn, openTwoFaModal]);

  return (
    <ModalComponent
      caption={t('welcomeModal.caption')}
      contentStyle={modalStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <AccordionComponent
        panelStyle={accordionStyle}
        data={[{
          isExpanded: true,
          isLocked: true,
          child: (
            <div css={wrapperStyle}>
              <div css={rowStyle}>
                <AccordionStepNumber number={1} />
                <span>{t('welcomeModal.2FA')}</span>
                <ToggleComponent
                  css={toggleStyle}
                  isOn={isTwoFaOn}
                  offIcon={faUnlock}
                  onChange={toggleTwoFa}
                  onIcon={faLock}
                />
              </div>
              <div css={rowStyle}>
                <AccordionStepNumber number={2} />
                <span>{t('welcomeModal.theme')}</span>
                <ToggleComponent
                  css={toggleStyle}
                  isOn={currentTheme === darkTheme.name}
                  offIcon={faSun}
                  onChange={handleThemeChange}
                  onIcon={faMoon}
                />
              </div>
              <div css={videoSectionStyle}>
                <div css={rowStyle}>
                  <AccordionStepNumber number={3} />
                  <span>{t('welcomeModal.tutorialVideo')}</span>
                </div>
                <EmbeddedYouTubeVideoComponent
                  videoId={MAPTIVE_TUTORIAL_YOUTUBE_ID}
                  title={t('welcomeModal.tutorialVideo.title')}
                />
              </div>
            </div>
          ),
          header: t('welcomeModal.title'),
        }]}
      />

    </ModalComponent>
  );
};
