import { css } from '@emotion/react';
import { AlertComponent } from '~/_shared/baseComponents/alert';

type TwoFactorCommonComponentProps = Readonly<{
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  isAlertOpen: boolean;
  closeAlert: () => void;
}>;

const alertStyle = css({
  marginTop: 20,
});

export const TwoFactorCommonComponent: React.FC<TwoFactorCommonComponentProps> = props => {
  return (
    <>
      {!!props.successMessage && props.isAlertOpen && (
        <AlertComponent
          type="success"
          css={alertStyle}
          autoCloseTimeout={5000}
          onClose={props.closeAlert}
        >
          {props.successMessage}
        </AlertComponent>
      )}
      {!props.successMessage && !!props.errorMessage && props.isAlertOpen && (
        <AlertComponent
          type="danger"
          css={alertStyle}
          autoCloseTimeout={5000}
          onClose={props.closeAlert}
        >
          {props.errorMessage}
        </AlertComponent>
      )}
    </>
  );
};
