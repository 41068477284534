import {
  type FC,
  useCallback, useEffect,
} from 'react';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';
import { useBoundaryData } from '../hooks/useBoundaryData';
import { useBoundaryRemove } from '../hooks/useBoundaryRemove';
import { useBoundaryUpdate } from '../hooks/useBoundaryUpdate';
import { BoundaryListingComponent } from './boundaryListing.component';

export type BoundaryListItemStyle = {
  color: string;
  opacity: number;
  lineColor: string;
  lineWidth: number;
};

export type BoundaryListItem = {
  name: string;
  id: number;
  settings: {
    style: BoundaryListItemStyle | null;
    translate?: boolean;
  };
};

export type BoundaryListingProps = {
  boundaryGroupId: number;
};

export const BoundaryListingContainer: FC<BoundaryListingProps> = props => {
  const { getBoundaryItems, boundaryItems, isLoading: isBoundaryItemsLoading } = useBoundaryData();
  const { updateBoundary, isLoading: isBoundaryNameSaveLoading } = useBoundaryUpdate();
  const { removeBoundary, isLoading: isBoundaryRemoveLoading } = useBoundaryRemove();
  const clientId = useClientIdSelector();

  const onRemoveBoundary = useCallback((boundaryId: number) => {
    if (clientId === null) {
      return;
    }

    removeBoundary(boundaryId, () => {
      getBoundaryItems(clientId, props.boundaryGroupId);
    });
  }, [clientId, removeBoundary, getBoundaryItems, props.boundaryGroupId]);

  const onUpdateBoundary = useCallback((boundaryId: number, newName: string, newColor: string, newOpacity: number) => {
    if (clientId === null) {
      return;
    }

    updateBoundary({
      boundaryGroupId: props.boundaryGroupId,
      boundaryId,
      opacity: newOpacity,
      color: newColor,
      name: newName,
    }, () => {
      getBoundaryItems(clientId, props.boundaryGroupId);
    });
  }, [clientId, props.boundaryGroupId, updateBoundary, getBoundaryItems]);

  useEffect(() => {
    if (clientId === null) {
      return;
    }

    getBoundaryItems(clientId, props.boundaryGroupId);
  }, [clientId, getBoundaryItems, props.boundaryGroupId]);

  return (
    <BoundaryListingComponent
      items={boundaryItems}
      onBoundaryUpdate={onUpdateBoundary}
      isLoading={isBoundaryItemsLoading || isBoundaryRemoveLoading || isBoundaryNameSaveLoading}
      onRemove={onRemoveBoundary}
    />
  );
};
