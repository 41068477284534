import type { ComponentProps } from 'react';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import {
  type MetricProps, MetricsDataAction,
} from '~/_shared/utils/metric/metrics.types';
import {
  METRICS_EXCLUDED_ROWS_COUNT, METRICS_ROWS_COUNT,
} from '~/_shared/utils/metric/useMetricData.helpers';
import { getDataActionShortLabels } from '../customizeMetrics/customizeMetrics.helpers';
import {
  type GroupedMetric, type MetricsListItemComponent,
} from './item/metricsListItem.component';

const getAdditionalDataProps = (additionalData: MetricProps['additionalData']): Readonly<Pick<ComponentProps<typeof MetricsListItemComponent>, 'excludedRowCount'|'rowCount'>> => {
  const excludedRowCount = additionalData.find(data => data.name === METRICS_EXCLUDED_ROWS_COUNT);
  const rowCount = additionalData.find(data => data.name === METRICS_ROWS_COUNT);

  return {
    ...(excludedRowCount?.value ? { excludedRowCount: excludedRowCount?.value } : {}),
    ...(rowCount?.value ? { rowCount: rowCount?.value } : {}),
  };
};

export type GroupedMetricWithGroupActionFlag = GroupedMetric & { isGroupCountAction: boolean } & ReturnType<typeof getAdditionalDataProps>;

export const groupMetricsDataByColumn = (metrics: MetricProps[], t: TranslationFnc): readonly GroupedMetricWithGroupActionFlag[] => {
  const actionLabels = getDataActionShortLabels(t);

  return metrics.reduce((groupedMetrics: GroupedMetricWithGroupActionFlag[], metric) => {
    const additionalDataProps = getAdditionalDataProps(metric.additionalData);

    if (metric.dataAction === MetricsDataAction.GROUP_COUNT && metric.additionalData.length > 0) {
      return [...groupedMetrics, {
        demographicId: metric.demographicId,
        name: metric.name,
        subheader: metric.dataAction ? actionLabels[metric.dataAction] : undefined,
        subheader2: t('{{count}} Groups', { count: metric.additionalData.length }),
        groups: metric.additionalData.map(item => ({
          name: item.name,
          value: item.value,
        })),
        isGroupCountAction: true,
        ...additionalDataProps,
      }];
    }

    const groupedMetric = groupedMetrics.findLast(item => item.name === metric.name && !item.isGroupCountAction);
    if (groupedMetric) {
      Object.assign(groupedMetric, { groups: [
        ...groupedMetric.groups || [],
        {
          name: metric.dataAction ? actionLabels[metric.dataAction] : metric.name,
          value: metric.value,
        },
      ],
      ...additionalDataProps });
      return groupedMetrics;
    }
    else {
      return [
        ...groupedMetrics,
        {
          demographicId: metric.demographicId,
          name: metric.name,
          groups: [
            { name: metric.dataAction ? actionLabels[metric.dataAction] : metric.name, value: metric.value },
          ],
          isGroupCountAction: false,
          ...additionalDataProps,
        },
      ];
    }
  }, []);
};
