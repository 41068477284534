import { useEffect } from 'react';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { EXTERNAL_ACTION_INDICATOR } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { parseUrl } from '../../utils/url/url.helpers';

export const CREATE_NEW_SALES_REP_MAP_ACTION = 'create-new-sales-rep-map';

export const useCreateSalesRepMapOnAppLoad = () => {
  const { openModal: openCreateNewMap } = useModal(ModalType.CreateNewMap);

  useEffect(() => {
    const queryParameters = parseUrl(window.location.href).queryParameters;
    const actionParameter = queryParameters?.[EXTERNAL_ACTION_INDICATOR];

    if (actionParameter === CREATE_NEW_SALES_REP_MAP_ACTION) {
      openCreateNewMap({ isNewSalesRepMap: true });
    }
  }, [openCreateNewMap]);
};
