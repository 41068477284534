import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import {
  MatchUpDataModalComponent, type MatchUpDataSection,
} from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import { type ColumnsMatchUp } from '~/_shared/types/columnRole.enum';
import { isMatchUpLocationSelected } from '~/map/page/matchup/matchupData.helpers';
import { type ModalProps } from '~/modal/modalType.enum';

type MapPageMatchupProps = ModalProps<{
  columns: ReadonlyArray<[string, string]>;
  ensureLocationDataIsSelected: boolean;
  initialColumnsMatchup: ColumnsMatchUp;
  hideHeading?: boolean;
  isLoading: boolean;
  isPublicApiActive: boolean;
  sections: ReadonlyArray<MatchUpDataSection>;

  onSubmit: (matchup: ColumnsMatchUp) => void;
}>;

export const MatchupDataComponent: FC<MapPageMatchupProps> = (props) => {
  const [matchUp, setMatchUp] = useState<ColumnsMatchUp>(props.initialColumnsMatchup);

  const handleSubmit = useCallback(() => {
    const onSubmit = props.onSubmit;
    onSubmit(matchUp);
  },
  [matchUp, props.onSubmit]);

  useEffect(() => {
    setMatchUp(props.initialColumnsMatchup);
  }, [props.initialColumnsMatchup]);

  const isSubmitDisabled = (props.ensureLocationDataIsSelected && !isMatchUpLocationSelected(matchUp)) || props.isLoading;

  return (
    <MatchUpDataModalComponent
      hideHeading={props.hideHeading}
      sections={props.sections}
      columns={props.columns}
      matchUp={matchUp}
      onSave={setMatchUp}
      isCloseDisabled={props.ensureLocationDataIsSelected}
      isOpen={props.isOpen}
      isPublicApiActive={props.isPublicApiActive}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
