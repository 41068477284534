import {
  css, keyframes,
} from '@emotion/react';
import {
  faMagnifyingGlassArrowRight,
  faMagnifyingGlassMinus, faMagnifyingGlassPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback,
} from 'react';
import {
  SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD,
  SquareIconButtonComponent,
} from '~/_shared/baseComponents/buttons';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const BUTTON_SIZE = 36;

const getButtonColors = ({ theme }: ThemeProps) => ({
  backgroundColor: {
    base: theme.backgroundColors.quinary,
    active: theme.iconColors.backgroundQuaternaryHover,
  },
  borderColor: {
    base: theme.borderColors.primary,
    active: theme.borderColors.activeItem,
  },
} as const);

const popInAnimation = ({ theme }: ThemeProps) => {
  const colors = getButtonColors({ theme });
  return keyframes({
    '0%': {
      opacity: 0,
      borderColor: `${colors.borderColor.active}`,
      backgroundColor: `${colors.backgroundColor.active}`,
    },
    '50%': {
      borderColor: `${colors.borderColor.active}`,
      backgroundColor: `${colors.backgroundColor.active}`,
    },
    '100%': {
      opacity: 1,
      borderColor: `${colors.borderColor.base}`,
      backgroundColor: `${colors.backgroundColor.base}`,
    },
  });
};

const popInAnimationStyle = ({ theme }: ThemeProps) => css({
  animationName: popInAnimation({ theme }),
  animationDuration: '1.5s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 1,
});

const buttonContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const activeButtonStyle = ({ theme }: ThemeProps) => ({
  backgroundColor: `${getButtonColors({ theme }).backgroundColor.active}`,
  border: `2px solid ${getButtonColors({ theme }).borderColor.active}`,
  boxShadow: `inset ${theme.backgroundColors.secondary} 0px 0px 6px ${SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD}px`,
});

const buttonStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: getButtonColors({ theme }).backgroundColor.base,
  border: `2px solid ${getButtonColors({ theme }).borderColor.base}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  fontSize: '24px',
  // add active styling for 'pressing button' (mainly for mobile devices)
  '&:active': {
    ...activeButtonStyle({ theme }),
  },
  // don't use hover effect on mobile devices
  '@media (hover: hover)': {
    '&:hover': {
      ...activeButtonStyle({ theme }),
    },
  },
});

type ZoomControlsComponentProps = {
  onZoomClick: (difference: number) => void;
  onZoomToInitial?: () => void;
};

export const ZoomControlsComponent: FC<ZoomControlsComponentProps> = (props) => {
  const { onZoomClick, onZoomToInitial } = props;

  const theme = useTheme();

  const onZoomIn = useCallback(() => {
    onZoomClick(1);
  }, [onZoomClick]);

  const onZoomOut = useCallback(() => {
    onZoomClick(-1);
  }, [onZoomClick]);

  return (
    <div css={buttonContainerStyle}>
      {onZoomToInitial && (
        <SquareIconButtonComponent
          buttonSize={BUTTON_SIZE}
          css={[buttonStyle({ theme }), popInAnimationStyle({ theme })]}
          icon={faMagnifyingGlassArrowRight}
          onClick={onZoomToInitial}
        />
      )}
      <SquareIconButtonComponent
        buttonSize={BUTTON_SIZE}
        css={buttonStyle({ theme })}
        icon={faMagnifyingGlassPlus}
        onClick={onZoomIn}
      />
      <SquareIconButtonComponent
        buttonSize={BUTTON_SIZE}
        css={buttonStyle({ theme })}
        icon={faMagnifyingGlassMinus}
        onClick={onZoomOut}
      />
    </div>
  );
};
