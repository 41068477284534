import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { activeMapElementsSetActiveProximity } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import {
  individualRadiusCenterHovered, individualRadiusCenterHoverEnded,
} from '~/store/frontendState/proximityTool/proximityTool.actionCreators';

export const useCenterInteraction = (
  renderedCenter: WebglOverlayMarker | null,
  proximityId: string,
  rowId?: SpreadsheetRowId,
) => {
  const dispatch = useDispatch();
  const [isMouseOverCenter, setIsMouseOverCenter] = useState(false);

  const isMapInteractionActive = useIsMapInteractionActive();

  const handleHoverStart = useCallback(() => {
    if (!isMapInteractionActive) {
      dispatch(individualRadiusCenterHovered());
      setIsMouseOverCenter(true);
    }
  }, [dispatch, isMapInteractionActive]);

  const handleHoverEnd = useCallback(() => {
    dispatch(individualRadiusCenterHoverEnded());
    setIsMouseOverCenter(false);
  }, [dispatch]);

  const handleClick = useCallback(() => {
    dispatch(activeMapElementsSetActiveProximity(proximityId, rowId));
  }, [dispatch, proximityId, rowId]);

  // Attach center hover event listeners
  useEffect(() => {
    if (renderedCenter) {
      renderedCenter.addEventListener('click', handleClick);
      renderedCenter.addEventListener('mouseout', handleHoverEnd);
      renderedCenter.addEventListener('mouseover', handleHoverStart);

      return () => {
        renderedCenter.removeEventListener('click', handleClick);
        renderedCenter.removeEventListener('mouseout', handleHoverEnd);
        renderedCenter.removeEventListener('mouseover', handleHoverStart);
      };
    }

    return undefined;
  }, [renderedCenter, handleHoverStart, handleHoverEnd, handleClick]);

  return { isMouseOverCenter };
};
