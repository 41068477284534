import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type ImportSpreadsheetDataMatchRequest } from '~/spreadsheet/spreadsheet.repository';
import { type ReplaceDataMatchup } from './replaceDataMatchupModal.component';

export const createImportMatchupRequest = (
  matchup: ReplaceDataMatchup,
  newColumns: ReadonlyArray<DropdownOption<string>>,
  oldColumns: ReadonlyArray<DropdownOption<string>>,
): ReadonlyArray<ImportSpreadsheetDataMatchRequest> => {
  const request: Array<ImportSpreadsheetDataMatchRequest> = [];

  Object.entries(matchup).forEach(([oldColumnId, value]) => {
    if (value.newColumnId === null || value.ignore) {
      request.push({
        table_column: oldColumnId,
        delete: true,
      });
    }
    else {
      request.push({
        table_column: oldColumnId,
        source_column: value.newColumnId,
      });
    }
  });

  // delete all old column that are missing in the matchup
  for (const oldColumn of oldColumns) {
    const isColumnInRequest = !!request.find(item => item.table_column === oldColumn.value);
    if (!isColumnInRequest) {
      request.push({
        table_column: oldColumn.value,
        delete: true,
      });
    }
  }

  // add all new columns that are missing in the matchup
  for (const newColumn of newColumns) {
    const isColumnInRequest = !!request.find(item => item.source_column === newColumn.value);
    if (!isColumnInRequest) {
      request.push({
        source_column: newColumn.value,
        add: true,
      });
    }
  }

  return request;
};
