import { RouteCollapsingLevel } from '../../../_shared/constants/routeCollapsingLevel.enum';
import { type MapSettingsDataAction } from '../data/mapSettingsData.action';
import { MAP_SETTINGS_REMOVE_ACTIVE_ITEMS } from '../data/mapSettingsData.actionTypes';
import { type MapSettingsDirectionsAction } from './mapSettingsDirections.action';
import {
  MAP_SETTINGS_DIRECTIONS_ADD_ROUTE, MAP_SETTINGS_DIRECTIONS_ADD_ROUTE_SETTING,
  MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES,
  MAP_SETTINGS_DIRECTIONS_MODIFY_ROUTE,
  MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE,
  MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS,
} from './mapSettingsDirections.actionTypes';
import { type MapSettingsDirectionsState } from './mapSettingsDirections.state';

export const mapSettingsDirectionsInitialState: MapSettingsDirectionsState = {
  selectedCollapsing: RouteCollapsingLevel.Routes,
  canDragRoutes: false,
  shouldBeginAtUserLocation: false,
  routes: [],
};

export const mapSettingsDirectionsReducer = (
  state = mapSettingsDirectionsInitialState,
  action: MapSettingsDirectionsAction | MapSettingsDataAction
): MapSettingsDirectionsState => {
  switch (action.type) {
    case MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS: {
      return {
        ...state,
        selectedCollapsing: action.payload.selectedCollapsing,
        canDragRoutes: action.payload.canDragRoutes,
        shouldBeginAtUserLocation: action.payload.shouldBeginAtUserLocation,
      };
    }

    case MAP_SETTINGS_DIRECTIONS_ADD_ROUTE:
      return {
        ...state,
        routes: [...state.routes, action.payload.route],
      };

    case MAP_SETTINGS_DIRECTIONS_ADD_ROUTE_SETTING:
      return {
        ...state,
        routes: [...state.routes, action.payload.route],
      };

    case MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE:
      return {
        ...state,
        routes: state.routes.filter(({ id }) => id !== action.payload.route.id),
      };

    case MAP_SETTINGS_DIRECTIONS_MODIFY_ROUTE:
      return {
        ...state,
        routes: state.routes.map(r => r.id === action.payload.routeId ? action.payload.route : r),
      };

    case MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES:
    case MAP_SETTINGS_REMOVE_ACTIVE_ITEMS:
      return {
        ...state,
        routes: [],
      };

    default:
      return state;
  }
};
