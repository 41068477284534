import { type Pagination } from './pagination';
import { type PaginationResponse } from './pagination.response';

export const createPaginationFromResponse = (response: PaginationResponse): Pagination => {
  return {
    currentPage: response.current_page,
    lastPage: response.last_page,
    perPage: response.per_page,
    total: response.total ?? null,
  };
};
