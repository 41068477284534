import {
  type FC, useEffect, useState,
} from 'react';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import type { BoundaryFilterBoundariesRequest } from '~/spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { getBoundaryTerritoryGroupMatchupRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '../../../../../_shared/components/exportDataModal/exportDataModal.component';
import { prepareAsyncCopyToClipboard } from '../../../../../_shared/utils/clipboard/clipboard.helpers';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useTimeout } from '../../../../../_shared/utils/hooks/useTimeout';
import { ExportDataFileType } from '../../../../../data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '../../../../../data/exportDataModal/exportDataMethod.enum';
import {
  type ModalProps, ModalType,
} from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { usePrimarySpreadsheetId } from '../../../../../store/selectors/usePrimarySpreadsheetId';
import { useExportBoundaryTerritoryGroupData } from '../hooks/useExportBoundaryTerritoryGroupData';

export type ExportBoundaryContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  boundaryId: number;
}>;

export const ExportBoundaryContainer: FC<ExportBoundaryContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { isLoading, isError, getExportedBoundaryTerritoryGroupData } = useExportBoundaryTerritoryGroupData();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const [t] = useTranslation();

  const onSave = (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId) {
      return;
    }
    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    const selectedBoundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);

    if (!selectedBoundaryTerritoryGroup) {
      return;
    }

    const boundariesFilter: BoundaryFilterBoundariesRequest = {
      [selectedBoundaryTerritoryGroup.boundaryGroupId]: {
        boundary_ids: [props.boundaryId],
        matchings: getBoundaryTerritoryGroupMatchupRequest(selectedBoundaryTerritoryGroup.matchings),
      },
    };

    return getExportedBoundaryTerritoryGroupData({
      spreadsheetId: virtualSpreadsheetId,
      boundariesFilter,
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      exportAsText: arg.method === ExportDataMethod.Clipboard,
      useMainFilters: !selectedBoundaryTerritoryGroup.settings.ignoreFilters,
    })
      .then(response => {
        if (arg.method === ExportDataMethod.Clipboard) {

          const fileContent = response.data[0]?.result?.content;

          if (!fileContent) {
            setErrorMessage(t('No locations to export'));
            return;
          }
          asyncCopyToClipboard(fileContent);
          showCopiedToClipboardMessage();
        }
        else {
          const fileName = response.data[0]?.result?.file_name;

          if (!fileName) {
            setErrorMessage(t('No locations to export'));
            return;
          }

          const exportUrl = getExportUrl(fileName);
          downloadFile(exportUrl);

          props.onClose();
          openDownloadResultsModal({
            downloadUrl: exportUrl,
          });
        }
      });
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  return (
    <ExportDataModalComponent
      isLoading={isLoading}
      onSubmit={onSave}
      onClose={props.onClose}
      isOpen={props.isOpen}
      error={errorMessage}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
    />
  );
};
