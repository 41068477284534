import { type DeepWritable } from 'ts-essentials';
import {
  type SpreadsheetColumnsToFetchAttributeResponse, type SpreadsheetColumnsToFetchDateResponse,
  type SpreadsheetColumnsToFetchGroupResponse, type SpreadsheetColumnsToFetchNumberResponse,
  type SpreadsheetColumnsToFetchTextResponse,
} from '~/spreadsheet/spreadsheet.repository';
import { generateGroupIdByName } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  type GroupDataTypeData, type SpreadsheetDataItemAttribute, type SpreadsheetDataItemDate, type SpreadsheetDataItemGroup, type SpreadsheetDataItemNumber, type SpreadsheetDataItemText,
} from './spreadsheetData.state';

type SpreadsheetColumnsToFetchGroupResponseOrEmpty = SpreadsheetColumnsToFetchGroupResponse | Record<string, never>;

export const createSpreadsheetDataItemGroupFromResponse
  = (response: SpreadsheetColumnsToFetchGroupResponseOrEmpty): SpreadsheetDataItemGroup | null => {
    if (Object.keys(response).length === 0) {
      return null;
    }

    const extra: DeepWritable<GroupDataTypeData> = {
      uniqueGroups: [],
    };
    if (response.unique_indexes) {
      extra.uniqueGroups = response.unique_values.map((groupName, index) => ({
        id: response.unique_indexes?.[index]?.toString() ?? '',
        label: groupName,
      }));
    }
    else {
      extra.uniqueGroups = response.unique_values.map((groupName) => ({
        id: generateGroupIdByName(groupName),
        label: groupName,
      }));
    }
    return {
      values: response.row_values,
      extra,
    };
  };

export const createSpreadsheetDataItemTextFromResponse
  = (response: SpreadsheetColumnsToFetchTextResponse): SpreadsheetDataItemText => {
    return {
      values: response.row_values,
    };
  };

export const createSpreadsheetDataItemAttributeFromResponse
  = (response: SpreadsheetColumnsToFetchAttributeResponse): SpreadsheetDataItemAttribute => {
    return {
      values: response.row_values,
      extra: {
        uniqueValues: response.unique_values,
      },
    };
  };

export const createSpreadsheetDataItemNumberFromResponse
  = (response: SpreadsheetColumnsToFetchNumberResponse): SpreadsheetDataItemNumber => {
    return {
      values: response.row_values,
      extra: {
        min: response.min,
        max: response.max,
        maxFiltered: response.max_filtered,
        minFiltered: response.min_filtered,
        numericPercent: response.numeric_percent,
        numericAndEmptyPercent: response.numeric_and_empty_percent,
      },
    };
  };

export const createSpreadsheetDataItemDateFromResponse
  = (response: SpreadsheetColumnsToFetchDateResponse): SpreadsheetDataItemDate => {
    return {
      values: response.row_values,
      extra: {
        min: response.min ?? '',
        max: response.max ?? '',
        maxFiltered: response.max_filtered,
        minFiltered: response.min_filtered,
      },
    };
  };
