import { useMemo } from 'react';
import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import type { SpreadsheetMatchupMatch } from '~/_shared/types/spreadsheetData/matchupData';
import { isNumber } from '~/_shared/utils/typeGuards';
import { useSpreadSheetData } from '~/map/map/useSpreadsheetData.hook';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';
import { type MatchupDataState } from '../../../store/matchupData/matchupData.state';
import {
  type ColumnRole, type PerColumnRole,
} from '../../types/columnRole.enum';
import { type CombinedRowId } from '../../types/spreadsheetData/spreadsheetRow';
import { stringObjectEntries } from '../object/objectEnumerators';

export const useCategoryValueResolver = () => {
  const { spreadsheetData } = useSpreadSheetData();
  const matchup = useMatchupDataSelector();
  const categoryValueResolver = useMemo(() => getCategoryResolver(spreadsheetData, matchup), [matchup, spreadsheetData]);

  return {
    categoryValueResolver,
  };
};

export const useCategoryToColumnIdsMap = () => {
  const matchup = useMatchupDataSelector();

  return useMemo(() => (
    Object.entries(matchup).reduce<PerSpreadsheet<PerColumnRole<SpreadsheetMatchupMatch>>>((acc, [spreadsheetId, spreadsheetMatchupData]) => {
      const categories = spreadsheetMatchupData?.data?.categories;
      if (categories) {
        acc[+spreadsheetId] =
          stringObjectEntries(categories).reduce<PerColumnRole<SpreadsheetMatchupMatch>>((categoryAcc, [columnRole, category]) => {
            categoryAcc[columnRole] = category.match;
            return categoryAcc;
          }, {} as PerColumnRole<SpreadsheetMatchupMatch>);
      }
      return acc;
    }, newPerSpreadsheetMap())
  ), [matchup]);
};

export const getCategoryResolver = (spreadsheetData: SpreadsheetDataData, matchup: MatchupDataState) =>
  (category: ColumnRole, spreadsheetId: number, rowId: CombinedRowId) => {
    const columnId = matchup[spreadsheetId]?.data?.categories[category]?.match;
    if (!columnId) {
      return null;
    }

    const columnGroupsPerRow = spreadsheetData.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP];
    if (!columnGroupsPerRow) {
      return null;
    }

    const emptyGroup = columnGroupsPerRow.extra.uniqueGroups.find(group => group.label === '');
    const columnGroupRowIndex = columnGroupsPerRow.values[rowId];
    const group = isNumber(columnGroupRowIndex) ? columnGroupsPerRow.extra.uniqueGroups[columnGroupRowIndex] : undefined;
    const value = (group ?? emptyGroup)?.label;

    return value ? value : null;
  };
