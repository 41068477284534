import { compare } from 'fast-json-patch';
import { type DeepPartial } from 'ts-essentials';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { type LatLng } from '../../../_shared/types/latLng';
import { type BoundingBox } from '../../../map/map/boundingBox';
import { normalizePathForMapSettingsTree } from '../../../store/mapSettings/data/mapSettingsData.helpers';
import { type MapSettingsDataState } from '../../../store/mapSettings/data/mapSettingsData.state';
import { type MapSettingsToolsState } from '../../../store/mapSettings/toolsState/mapSettingsToolsState';

export const getCreateMapSnapshotSettingsOverrides = (
  serverToolsState: Partial<MapSettingsToolsState>,
  newToolsState: MapSettingsToolsState,
  serverMapSettings: MapSettingsDataState,
  boundsAndCenterOverrides: { lastBounds: BoundingBox; lastCenter: LatLng; lastZoom: number },
  newPublicMapSettingsState?: MapSettingsPublicMapSettingsState,
): string[] => {
  const initialMapSettings: DeepPartial<MapSettingsDataState> & Record<string, unknown> = {
    settings: {
      initialMapCenterAndZoom: serverMapSettings.settings.initialMapCenterAndZoom,
      initialBounds: serverMapSettings.settings.initialBounds,
    },
    toolsState: {
      ...serverToolsState,
    },
    ...(newPublicMapSettingsState ? {
      publicMapSettings: serverMapSettings.publicMapSettings,
    } : {}),
  };
  const mapSettingsWithLastBounds: DeepPartial<MapSettingsDataState> & Record<string, unknown> = {
    settings: { initialMapCenterAndZoom: { center: boundsAndCenterOverrides.lastCenter, zoom: boundsAndCenterOverrides.lastZoom }, initialBounds: boundsAndCenterOverrides.lastBounds.getBounds() },
    toolsState: {
      ...newToolsState,
    },
    ...(newPublicMapSettingsState ? {
      publicMapSettings: newPublicMapSettingsState,
    } : {}),
  };

  const diff = compare(initialMapSettings, mapSettingsWithLastBounds);
  const mappedDiff = normalizePathForMapSettingsTree(diff);
  return mappedDiff.map(item => JSON.stringify(item));
};
