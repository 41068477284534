import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Chevron]: {
  views: {
    default: {
      canvasHeight: 471,
      canvasWidth: 471,
      iconWidth: 250,
      iconHeight: 471,
    },
    short: {
      canvasHeight: 471,
      canvasWidth: 471,
      iconWidth: 250,
      iconHeight: 380,
      offsetTop: 0,
    },
  },
  colors: {
    primary: '#FE6600',
    secondary: '#D8D8D8',
  },
  gradients: {},
  frameRate: 24,
  segments: {
    default: [0, 24] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/101315-orange-down-arrow" */
      './101315-orange-down-arrow.json'
    ),
} };

export const { Chevron } = config;
