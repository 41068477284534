import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ExportImageMode } from '~/_shared/types/exportImage/exportImage';
import { useKeyCloseMapElement } from '~/_shared/utils/hooks/useKeyCloseMapElements';
import { useQuery } from '~/_shared/utils/hooks/useQuery';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { SidebarApp } from '~/sidebar/sidebarApp.enum';
import { activeMapElementsSetActiveLocationList } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { openLeftSidebarApp } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useMapInitializationFailedSelector } from '~/store/frontendState/mapInitializationFailed/mapInitializationFailed.selectors';
import { frontendStateSearchShow } from '~/store/frontendState/mapTools/search/search.actionCreators';
import { useSearchActiveSelector } from '~/store/frontendState/mapTools/search/search.selectors';
import { mapPageInit } from '~/store/map/map.actionCreators';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMapSettingsSettingsIsDisableActiveToolPanelSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useSplitPanel } from '~/store/userData/settings/splitPanel/useSplitPanel';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { MAP_SETTINGS_SIDEBAR_OPEN_URL_PARAM } from '../map.helpers';
import { MapPageComponent } from './mapPage.component';

type MapPageContainerProps = Readonly<{
  mapId: number | null;
}>;

export const MapPageContainer: FC<MapPageContainerProps> = (props) => {
  const dispatch = useDispatch();
  const searchActive = useSearchActiveSelector();
  const query = useQuery();
  const openMapSettingsUrlQuery = query.get(MAP_SETTINGS_SIDEBAR_OPEN_URL_PARAM);
  const { openModal: openLocationFinderModal } = useModal(ModalType.LocationFinder);
  const { openModal: openAfterMigrationMapPrepareModal } = useModal(ModalType.AfterMigrationMapPrepare);
  const { mapId } = props;

  const { isActive: isSplitPanelActive, height: splitPanelHeight, setHeight: setSplitPanelHeight, closePanel: closeSplitPanel } = useSplitPanel(mapId);
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();
  const isMapInitializationFailed = useMapInitializationFailedSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const isDisableActiveToolPanel = useMapSettingsSettingsIsDisableActiveToolPanelSelector();
  const mapInfoData = useMapInfoDataSelector();
  const currentPage = useGetCurrentPageInfo().page;

  useKeyCloseMapElement();

  useEffect(() => {
    if (mapInfoData && mapInfoData.v4MigrationDone === false && !isMapPresentational) {
      openAfterMigrationMapPrepareModal();
    }
  }, [openAfterMigrationMapPrepareModal, mapInfoData, isMapPresentational]);

  const isMapOverlayUiHidden = useMemo(() => {
    const exportMode = exportImageSettings?.mode;
    return isMapPresentational && (exportMode === ExportImageMode.Map || exportMode === ExportImageMode.MapAndLegend);
  }, [exportImageSettings?.mode, isMapPresentational]);

  useEffect(() => {
    if (openMapSettingsUrlQuery === 'true') {
      dispatch(openLeftSidebarApp(SidebarApp.MapSettings, currentPage));
    }
  }, [openMapSettingsUrlQuery, dispatch, currentPage]);

  useEffect(() => {
    if (isMapPresentational) {
      if (publicMapSettings.searchBarAlwaysVisible) {
        dispatch(frontendStateSearchShow());
      }
      if (publicMapSettings.locationFinder && publicMapSettings.locationFinderStartsOpen) {
        openLocationFinderModal({});
      }
      if (publicMapSettings.locationList && publicMapSettings.locationListOpen) {
        dispatch(activeMapElementsSetActiveLocationList());
      }
    }
  }, [dispatch, isMapPresentational, openLocationFinderModal, publicMapSettings.locationFinder,
    publicMapSettings.locationFinderStartsOpen, publicMapSettings.locationList, publicMapSettings.locationListOpen,
    publicMapSettings.searchBarAlwaysVisible,
  ]);

  useEffect(() => {
    dispatch(mapPageInit());
  }, [dispatch]);

  if (mapId === null || isMapInitializationFailed) {
    return null;
  }

  return (
    <MapPageComponent
      key={mapId}
      onSplitPanelClose={closeSplitPanel}
      onSplitPanelHeightChange={setSplitPanelHeight}
      isSplitPanelActive={isSplitPanelActive && !isMapPresentational}
      splitPanelHeight={splitPanelHeight}
      isMapOverlayUiHidden={isMapOverlayUiHidden}
      isMobileScreen={isMobileScreen}
      searchActive={searchActive}
      showStatusBar={!isMapPresentational && !isDisableActiveToolPanel}
    />
  );
};
