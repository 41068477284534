import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { Trans } from '~/translations/Trans';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import {
  EditDataTableComponent, type EditDataTableRow,
} from '../editDataTable/editDataTable.component';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { baseTableCellStyle } from '../settingsTable/settingsTable.component';
import {
  type ChangeableSettingRow, type SettingColumn,
} from '../settingsTable/settingsTable.types';

const submitButtonStyles = css({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0',
});

const submitLabelStyles = css({
  flexGrow: 1,
  padding: '0 35px 0 20px',
});

const modalContentStyle = css({
  padding: 0,
});

const explanationStyle = css({
  fontSize: 14,
  fontWeight: 400,
  padding: '12px 24px 0',
});

const settingsTableDataRowStyle = ({ theme }: ThemeProps) => css({
  height: 48,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  '&:last-of-type': {
    border: 'none',
  },
});

const alertWrapperStyle = css({
  padding: 10,
});

type EditLocationModalCommonProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  caption: string;
  columnNames?: SettingColumn[];
  rows: EditDataTableRow[];
  isSubmitDisabled: boolean;
  isLoading: boolean;
  submitButtonLabel?: string;
  errorMessage: string | undefined;

  onChange?: (index: number, newSettingRow: ChangeableSettingRow) => void;
  onErrorMessageClose?: () => void;
  onSubmit: () => void;
}>;

type EditLocationModalWithAddLocationProps = EditLocationModalCommonProps & Readonly<{
  showAddAnotherLocation: true;
  onAddLocation: () => void;
}>;

type EditLocationModalWithoutAddLocationProps = EditLocationModalCommonProps & Readonly<{
  showAddAnotherLocation: false;
}>;

type EditLocationModalProps = EditLocationModalWithAddLocationProps | EditLocationModalWithoutAddLocationProps;

export const EditLocationModalComponent: FC<EditLocationModalProps> = (props) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={props.caption}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          isDisabled={props.isSubmitDisabled}
          text={props.submitButtonLabel ?? t('Map Now')}
          onClick={props.onSubmit}
        />
      )}
      leftFooterContent={props.showAddAnotherLocation ? (
        <ButtonComponent
          isDisabled={props.isSubmitDisabled}
          css={submitButtonStyles}
          text={<span css={submitLabelStyles}>{t('Add Another Location')}</span>}
          onClick={props.onAddLocation}
        />
      ) : null}
      contentStyle={modalContentStyle}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
    >

      <div css={explanationStyle}>
        <Trans
          i18nKey="bulkEditExplanation"
          values={{ batchEdit: t('Batch Edit') }}
          components={{
            strong: <strong />,
            Unchanging: <p />,
          }}
        />
      </div>

      <EditDataTableComponent
        columnNames={props.columnNames}
        rows={props.rows}
        onChange={props.onChange}
        customDataCellStyle={baseTableCellStyle}
        customDataRowStyle={settingsTableDataRowStyle}
        onFormSubmit={props.onSubmit}
      />

      {!!props.errorMessage && (
        <div css={alertWrapperStyle}>
          <AlertComponent
            type="danger"
            onClose={props.onErrorMessageClose}
          >
            {props.errorMessage}
          </AlertComponent>
        </div>
      )}
    </ModalComponent>
  );
};
