import { type CancelToken } from 'axios';
import { apiPost } from '~/_shared/utils/api/api.helpers';

//this can be either an entry from DeWmsDemographicCategory or a column id in the format column_X
export type ConstraintsMetric = string;

export type ValidateTerritoryConstraintsItem = {
  metric: string;
  min: number;
  max: number;
};

export type ValidateTerritoryConstraintsRequest = {
  map_id: number;
  spreadsheet_id: number;
  constraints: ValidateTerritoryConstraintsItem[];
  dataset_states?: ReadonlyArray<string>;
  dataset_zip_codes?: ReadonlyArray<string>;
};

export type ValidateTerritoryConstraintResultItem = {
  errors: string[];
  isValid: boolean;
  limits: {
    lowestMax: number | null;
    highestMin: number | null;
    min: number;
    max: number;
  };
  value: {
    min: number;
    max: number;
  };
  metric: ConstraintsMetric;
};

export type ValidateTerritoryConstraintsResponse = {
  results: ValidateTerritoryConstraintResultItem[];
};

export const validateTerritoryConstraints = (
  clientId: number, params: ValidateTerritoryConstraintsRequest, cancelToken?: CancelToken
): Promise<ValidateTerritoryConstraintsResponse> => {
  const requestUrl = `/api/clients/${clientId}/optimized-territories/constraints`;

  return apiPost(requestUrl, params, {
    cancelToken,
  });
};
