import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type Ref, useCallback, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { LocationListingItemAddressSkeletonComponent } from '~/_shared/components/locationListingPanel/item/locationListingItemAddressSkeleton.component';
import { LabelOrMarkerVisualizerContainer } from '~/_shared/labelOrMarkerVisualizer/labelOrMarkerVisualizer.container';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import {
  type FetchableResource, FetchableResourceStatus,
} from '~/_shared/types/fetchableResource/fetchableResource';
import { isResourceLoaded } from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { INDICATOR_WIDTH } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.component';

const ITEM_HEIGHT = 88;

const markerStyle = css({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  marginRight: 12,
  width: INDICATOR_WIDTH,
});

const rootStyle = (index: number) => (theme: Theme) => css({
  position: 'relative',
  backgroundColor: index % 2 === 0 ? theme.backgroundColors.primary : theme.backgroundColors.secondary,
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  maxHeight: ITEM_HEIGHT,

  width: '100%',
  '&:hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

const infoPortionStyle = ({ isCentered }: { isCentered: boolean }) => css({
  display: 'flex',
  padding: '12px 56px 12px 12px',
  userSelect: 'none',
  ...(isCentered && { alignItems: 'center' }),
});

const linesStyle = css({
  boxSizing: 'border-box',
  maxWidth: `calc(100% - ${INDICATOR_WIDTH}px)`,
});

const lineTextStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});

const headerStyle = css(lineTextStyle, {
  fontWeight: 500,
  fontSize: '20px',
});

const lineStyle = css(lineTextStyle, {
  fontWeight: 400,
  fontSize: '14px',
});

const settingsButtonWrapperStyle = css({
  position: 'absolute',
  right: 12,
  top: 12,
});

const linesLimit = 4;

export type LocationListingActions = Readonly<{
  icon?: IconProp;
  text: string;
  isDisabled: boolean;
  isDestructive: boolean;
  preventCloseOnClick?: boolean;
  onClick: () => void;
}>;

export type LocationListingItemProps = Readonly<{
  actions?: LocationListingActions[];
  lines?: FetchableResource<ReadonlyArray<string>>;
  header?: string;
  index: number;
  markerVisualSettings: MarkerSettings;
  readOnlyMode?: boolean;
  labelText?: string;

  onClick?: () => void;
  onHover?: () => void;
  onMouseLeave?: () => void;
}>;

export const LocationListingItemComponent: FC<LocationListingItemProps> = props => {
  const theme = useTheme();
  const hasMoreLinesThanCanFit = (props.lines?.value?.length ?? 0) > linesLimit;
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);

  const onMenuClick = useCallback(() => {
    setIsSettingsVisible(!isSettingsVisible);
  }, [isSettingsVisible]);

  return (
    <div
      css={rootStyle(props.index)}
      onMouseEnter={props.onHover}
      onMouseLeave={props.onMouseLeave}
    >
      <div
        css={infoPortionStyle({
          isCentered: props.lines?.status !== FetchableResourceStatus.Loading
            && !props.lines?.value?.length,
        })}
        onClick={props.onClick}
      >
        <div css={markerStyle}>
          <LabelOrMarkerVisualizerContainer
            labelText={props.labelText}
            maxDimensions={{ width: INDICATOR_WIDTH, height: INDICATOR_WIDTH }}
            maxSizeEasing
            markerSettings={props.markerVisualSettings}
            visualSettingsType={props.markerVisualSettings.useMarker ? MarkerSettingType.Marker : MarkerSettingType.Label}
          />
        </div>
        <div css={linesStyle}>
          {props.header && <div css={headerStyle}>{props.header}</div>}
          {isResourceLoaded(props.lines) &&
            props.lines.value
              .slice(0, hasMoreLinesThanCanFit ? linesLimit - 1 : undefined)
              .map((line, index) => (
                <div
                  key={index}
                  css={lineStyle}
                >
                  {line}
                </div>
              ))
          }
          {props.lines?.status === FetchableResourceStatus.Loading && (
            <LocationListingItemAddressSkeletonComponent
              animationColor={props.index % 2 === 1 ? undefined : theme.backgroundColors.secondaryHover}
              animationDelayMs={props.index * 200}
              animationDurationMs={2000}
              backgroundColor={props.index % 2 === 1 ? theme.backgroundColors.primary : theme.backgroundColors.secondary}
            />
          )}
          {hasMoreLinesThanCanFit && (
            <div
              css={lineStyle}
              title={props.lines?.value?.join('\n')}
            >
              ...
            </div>
          )}
        </div>
      </div>

      <ContextMenuComponent
        isFixed
        maxWidth={280}
        renderMenuContent={() => (
          <>
            {props.actions && props.actions.map((action, actionIndex) => (
              <ContextMenuItemCoreComponent
                key={actionIndex}
                closeOnClick={!action.preventCloseOnClick}
              >
                <ContextMenuDefaultItem
                  icon={action.icon}
                  onClick={action.onClick}
                  isDisabled={action.isDisabled}
                  isDestructive={action.isDestructive}
                >
                  {action.text}
                </ContextMenuDefaultItem>
              </ContextMenuItemCoreComponent>
            ))}
          </>
        )}
        isVisible={isSettingsVisible}
        onHide={() => setIsSettingsVisible(false)}
        preferredPlacement="bottom-end"
      >
        {ref => (props.readOnlyMode ? false : (
          <div css={settingsButtonWrapperStyle}>
            <RoundButtonComponent
              ref={ref as Ref<HTMLButtonElement>}
              buttonStyle={props.index % 2 === 0 ? RoundButtonStyle.Secondary : RoundButtonStyle.Primary}
              onClick={onMenuClick}
              icon={faEllipsisV}
              size={RoundButtonSize.Small}
              testid={`location-listing-item-menu-button-${props.header}`}
            />
          </div>
        )
        )}
      </ContextMenuComponent>
    </div>
  );
};
