import { type ThemeType } from '~/_shared/themes/themeType';
import { type MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { type ToolOrFeatureWithDataExport } from '~/_shared/types/toolsAndFeatures/toolsAndFeaturesWithDataExport.types';
import {
  type SharedPresentationalMapsBooleanToggleSettingsName, type SharedPresentationalMapsDropdownsSettingsName,
} from '~/map/settings/accordion/sharedPresentationalMaps/sharedPresentationalMaps.enums';
import {
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ALLOWED_EXPORT_DATA_TOOLS,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_THEME,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ZOOM_RESTRICT_LEVELS,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE, MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE_DROPDOWNS,
} from './mapSettingsPublicMapSettings.actionTypes';

export const publicMapSettingsUpdate = (name: SharedPresentationalMapsBooleanToggleSettingsName, value: boolean) => ({
  type: MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE,
  payload: {
    name,
    value,
  },
}) as const;

export const publicMapSettingsMenuToolsSetDefaultTool = (name: SharedPresentationalMapsDropdownsSettingsName, value: MapTool) => ({
  type: MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE_DROPDOWNS,
  payload: {
    name,
    value,
  },
}) as const;

export const publicMapSettingsSetZoomRestrictLevels = (zoomLevels: [number, number]) => ({
  type: MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ZOOM_RESTRICT_LEVELS,
  payload: {
    zoomLevels,
  },
}) as const;

export const publicMapSettingsSetAllowedExportDataTools = (allowedTools: ReadonlySet<ToolOrFeatureWithDataExport>) => ({
  type: MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ALLOWED_EXPORT_DATA_TOOLS,
  payload: {
    allowedTools,
  },
}) as const;

export const publicMapSettingsSetTheme = (value: ThemeType) => ({
  type: MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_THEME,
  payload: {
    value,
  },
}) as const;
