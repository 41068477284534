import { faSatellite } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { mapSettingsMapStylesSetSatellite } from '../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.actionCreators';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideSatelliteViewMenuItem: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isSatelliteEnabled = useSelector(state => state.map.mapSettings.data.mapStyles.isSatelliteEnabled);

  const setSatellite = (isSatelliteEnabled: boolean) => dispatch(mapSettingsMapStylesSetSatellite(isSatelliteEnabled));

  return (
    <RightSideMapToolsMenuItem
      icon={faSatellite}
      active={isSatelliteEnabled}
      onClick={() => setSatellite(!isSatelliteEnabled)}
      tooltip={t('Satellite View')}
    />
  );
};
