import {
  useCallback, useState,
} from 'react';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  getEmailVerificationRequest, resendEmailVerificationRequest,
} from './verifyEmail.repository';

export const useVerifyEmail = () => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getEmailVerification = useCallback((props: {
    expires: string;
    failureCallback: () => void;
    hash: string;
    id: string;
    userEmail: string;
    signature: string;
    successCallback: () => void;
  }) => {
    setIsLoading(true);
    getEmailVerificationRequest(props.expires, props.hash, props.id, props.userEmail, props.signature)
      .then(() => {
        setIsLoading(false);
        props.successCallback();
      }).catch((error) => {
        setIsLoading(false);
        if (error.responseStatus === 403) {
          setErrorMessage(t('This functionality is not available for current user.'));
        }
        else {
          setErrorMessage(t('The provided email address could not be verified'));
        }
        props.failureCallback();
      });
  }, [t]);

  const resendEmailVerification = useCallback((userEmail: string, successCallback: () => void, failureCallback: () => void) => {
    setIsEmailSending(true);
    resendEmailVerificationRequest(userEmail)
      .then(() => {
        setIsEmailSending(false);
        successCallback();
      })
      .catch(() => {
        setIsEmailSending(false);
        setErrorMessage(t('We were unable to re-send the verification email, please try again. If this problem persists please contact our customer support.'));
        failureCallback();
      });

  }, [t]);

  return {
    isLoading,
    isEmailSending,
    errorMessage,
    getEmailVerification,
    resendEmailVerification,
  };
};
