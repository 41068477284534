import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { getExternallyResolvedPromise } from '~/_shared/utils/promise/externallyResolvedPromise';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { getGroupProximityLocations } from '~/proximityDetails/proximityDetails.helpers';
import { activeMapElementsSetActiveProximity } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import type { SpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import type { SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';
import { ProximityType } from '../_shared/types/proximity/proximity.enums';
import {
  isDriveTimePolygon, isGroupRadius, isIndividualRadius, type Proximity,
} from '../_shared/types/proximity/proximity.types';
import {
  getBoundingBoxOfCircle, getBoundingBoxOfMultipolygon,
} from '../_shared/utils/gis/boundingBox.helpers';
import { calculateRadiusInMeters } from '../spreadsheet/filter/radius/spreadsheetFilterRadius.helpers';
import { mapComponentSetZoomToBounds } from '../store/frontendState/mapComponent/mapComponent.actionCreators';
import { useProximities } from './useProximities';

type AwaitedSpreadsheetData = {
  readonly spreadsheetData: SpreadsheetDataData;
  readonly latLngLookup: SpreadsheetLatLngRowData;
};

export const useZoomToProximity = () => {
  const dispatch = useDispatch();
  const { proximities: proximityList } = useProximities();
  const spreadsheetDataOrNot = useSpreadsheetDataDataSelector();
  const latLngLookup = useLatLngSpreadsheetData();
  const spreadsheetDataPromiseRef = useRef(getExternallyResolvedPromise<AwaitedSpreadsheetData>());

  useEffect(() => {
    if (spreadsheetDataPromiseRef.current.isResolved()) {
      spreadsheetDataPromiseRef.current = getExternallyResolvedPromise<AwaitedSpreadsheetData>();
    }
    if (spreadsheetDataOrNot) {
      spreadsheetDataPromiseRef.current.resolve({ spreadsheetData: spreadsheetDataOrNot, latLngLookup });
    }
  }, [latLngLookup, spreadsheetDataOrNot]);

  const zoomToRadius = useCallback(async (proximity: Proximity) => {
    if (isGroupRadius(proximity)) {
      const radiusData = proximity.data;
      const { spreadsheetData, latLngLookup } = await spreadsheetDataPromiseRef.current;
      const firstGroupProximityLocation = getGroupProximityLocations(spreadsheetData, proximity)[0];
      if (firstGroupProximityLocation) {
        const proximityLatLng = latLngLookup.getRow(firstGroupProximityLocation);
        const radiusInMeters = calculateRadiusInMeters(radiusData.radius, radiusData.unit);

        if (proximityLatLng) {
          const boundingBox = getBoundingBoxOfCircle({ lat: proximityLatLng.lat, lng: proximityLatLng.lng }, radiusInMeters);
          dispatch(activeMapElementsSetActiveProximity(proximity.id, firstGroupProximityLocation));
          dispatch(mapComponentSetZoomToBounds(boundingBox));
        }
      }
    }
    else if (isIndividualRadius(proximity)) {
      const radiusData = proximity.data;
      const proximityLatLng = { lat: radiusData.data.lat, lng: radiusData.data.lng };
      const radiusInMeters = calculateRadiusInMeters(radiusData.radius, radiusData.unit);

      const boundingBox = getBoundingBoxOfCircle(proximityLatLng, radiusInMeters);
      dispatch(mapComponentSetZoomToBounds(boundingBox));
    }
  }, [dispatch]);

  const zoomToProximity = useCallback((proximity: Proximity) => {
    if (proximity.type === ProximityType.DistanceRadius) {
      zoomToRadius(proximity);
    }
    else if (isDriveTimePolygon(proximity) && proximity.data.paths) {
      dispatch(mapComponentSetZoomToBounds(getBoundingBoxOfMultipolygon(proximity.data.paths)));
    }
  }, [dispatch, zoomToRadius]);

  const zoomById = useCallback((proximityId: string) => {
    const proximity = proximityList.find(p => p.id === proximityId);
    if (proximity) {
      zoomToProximity(proximity);
    }
  }, [proximityList, zoomToProximity]);

  return useMemo(() => ({
    zoomToRadius,
    zoomById,
  }), [zoomById, zoomToRadius]);
};
