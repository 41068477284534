import { type MarkersVisualSettings } from '~/_shared/types/markers/visualSettings.types';
import {
  memoizeOne, memoizeWeak,
} from '~/_shared/utils/memoize/memoize';
import { type AppState } from '~/store/app.store';
import { fileUrlsSelector } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { type FileMap } from '~/store/frontendState/fileUrls/fileUrls.state';
import { type MapSettingsFileAttachmentsMap } from '~/store/mapSettings/fileAttachments/fileAttachments.state';
import { mapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { type MatchupDataState } from '~/store/matchupData/matchupData.state';
import { type SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  type GroupingColumn, type GroupMarkerSettings, isNumericTypeGroup, isTextTypeGroup,
} from '../../../_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '../../../_shared/types/grouping/numericActiveGroupColumnType.helpers';
import { filterNonNumericBuckets } from '../../../_shared/utils/grouping/grouping.helpers';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import {
  createNumericBucketKey, createTextGroupKey,
  getMarkerVisuals,
} from '../../../_shared/utils/markers/markersVisualSettings.helpers';
import { mergeDeep } from '../../../_shared/utils/object/deepMerge';
import { isGroupPrimary } from '../../../grouping/groupingColumns.helpers';
import {
  getGroupingColumnsUniqueGroups,
  getNumericalGroupBucketsForSpreadsheetData,
  type NumericBucketWithRange,
} from '../../spreadsheetData/grouping/spreadsheetData.grouping.helpers';

type PerGroupVisualSettingsParams = {
  matchupData: MatchupDataState;
  spreadsheetData: SpreadsheetDataData | null;
  activeGroupColumns: GroupingColumn[];
  markersGeneral: MarkersVisualSettings;
  files: FileMap;
  fileAttachments: MapSettingsFileAttachmentsMap;
};

const selectPerGroupVisualSettingsParamsMemoized = memoizeOne((
  matchupData: MatchupDataState,
  spreadsheetData: SpreadsheetDataData | null,
  activeGroupColumns: GroupingColumn[],
  markersGeneral: MarkersVisualSettings,
  files: FileMap,
  fileAttachments: MapSettingsFileAttachmentsMap,
): PerGroupVisualSettingsParams => ({
  matchupData,
  spreadsheetData,
  activeGroupColumns,
  markersGeneral,
  files,
  fileAttachments,
}));

const selectPerGroupVisualSettingsMemoized = memoizeWeak((params: PerGroupVisualSettingsParams): GroupMarkerSettings => {
  const {
    matchupData,
    spreadsheetData,
    activeGroupColumns,
    markersGeneral,
    files,
    fileAttachments,
  } = params;

  if (!spreadsheetData) {
    return {};
  }

  const perGroupMarkers: GroupMarkerSettings = {};
  activeGroupColumns.forEach((column, index) => {
    const { type, spreadsheetId, columnId } = column;

    const uniqueGroups = getGroupingColumnsUniqueGroups(
      spreadsheetData,
      matchupData,
      column,
    );

    if (uniqueGroups.length === 0) {
      return;
    }

    const groupBuckets: NumericBucketWithRange[] = getNumericalGroupBucketsForSpreadsheetData(column, spreadsheetData);
    const numericActiveGroupColumnType = getNumericActiveGroupColumnType(activeGroupColumns);
    const groupBucketsWithoutNonNumerical = filterNonNumericBuckets(groupBuckets);

    const isPrimaryGroup = isGroupPrimary(index);

    let bucketLocationString: string | null = null;
    if (numericActiveGroupColumnType) {
      bucketLocationString = createNumericBucketKey(numericActiveGroupColumnType, index, groupBucketsWithoutNonNumerical.length);
    }

    uniqueGroups.forEach(group => {
      const visualSettings = getMarkerVisuals({
          hierarchyIndicator: index,
          isLegend: true,
          mapSettingsGroupingState: { activeGroupColumns },
          spreadsheetData,
          visualMarkersSettings: markersGeneral,
          ...(isTextTypeGroup(group) && { groupId: group.id }),
          ...(isNumericTypeGroup(group) && groupBuckets && {
            bucketId: group.id,
            numericBucketsWithRange: groupBucketsWithoutNonNumerical,
          }),
          fileAttachments, files,
        }),

        perGroupMarker: GroupMarkerSettings = {
          [spreadsheetId]: {
            [columnId]: {
              [type]: {
                ...(isNumericTypeGroup(group) && bucketLocationString && {
                  [bucketLocationString]: { [group.id]: visualSettings },
                }),
                ...(isTextTypeGroup(group) && {
                  [createTextGroupKey(group.id, isPrimaryGroup)]: visualSettings,
                }),
              },
            },
          },
        };

      mergeDeep(perGroupMarkers, perGroupMarker);
    });
  });

  return perGroupMarkers;
});

export const selectPerGroupVisualSettings = (state: AppState): GroupMarkerSettings => {
  const params = selectPerGroupVisualSettingsParamsMemoized(
    state.spreadsheet.matchupData,
    state.spreadsheet.spreadsheetData.data,
    state.map.mapSettings.data.grouping.activeGroupColumns,
    mapSettingsMarkersGeneralSelector(state),
    fileUrlsSelector(state),
    state.map.mapSettings.data.fileAttachments.map,
  );

  return selectPerGroupVisualSettingsMemoized(params);
};

export const usePerGroupVisualSettings = () => useSelector(selectPerGroupVisualSettings);
