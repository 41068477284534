import { useMemo } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useMarkerNameResolver } from '~/_shared/utils/spreadsheet/useMarkerNameResolver';
import { type SidebarTitle } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { type SpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { EMPTY_LOCATION_DESCRIPTION } from '../customizeLocationPanel.constants';
import {
  getLocationDescription, getLocationDescriptionProperties,
} from '../customizeLocationPanel.helpers';
import { convertPanelSettingsStateToPanelSettings } from './panelSettings.helpers';
import { useBubbleItemsAndOrder } from './useBubbleItemsAndOrder.hook';
import { usePanelSettingsStateSelector } from './usePanelSettingsState.selector';

type UseLocationDescriptionStyleProps = Readonly<{
  spreadsheetRowId?: SpreadsheetRowId;
  spreadsheetRowData: SpreadsheetRowData | null;
}>;

export const useLocationDescriptionData = ({
  spreadsheetRowId,
  ...props
}: UseLocationDescriptionStyleProps): SidebarTitle => {
  const theme = useTheme();
  const matchupData = useMatchupDataSelector();
  const { markerNameResolver } = useMarkerNameResolver();
  const panelSettingsState = usePanelSettingsStateSelector();

  const panelSettings = useMemo(() => (
    convertPanelSettingsStateToPanelSettings(panelSettingsState, theme)
  ), [panelSettingsState, theme]);

  const bubbleRecords = useBubbleItemsAndOrder({ spreadsheetRowId, spreadsheetRowData: props.spreadsheetRowData, panelSettings });

  return useMemo((): SidebarTitle => {
    if (spreadsheetRowId && spreadsheetRowId.spreadsheetId && bubbleRecords) {
      const locationDescriptionProperties = getLocationDescriptionProperties({
        spreadsheetRowId,
        bubbleItems: bubbleRecords.items,
        matchupData,
      });

      return getLocationDescription({
        ...locationDescriptionProperties,
        markerName: markerNameResolver(spreadsheetRowId.spreadsheetId, spreadsheetRowId.rowId, false),
      });
    }

    return EMPTY_LOCATION_DESCRIPTION;
  }, [bubbleRecords, markerNameResolver, matchupData, spreadsheetRowId]);
};
