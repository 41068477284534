import { createAction } from '@reduxjs/toolkit';
import type { MemberRole } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import type { Member } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import type {
  CurrentClient, GoogleApiOauthStatus, TwoFactorStats,
} from './repository/userData.repository';
import { type UserSettingsState } from './settings/userDataSettings.state';
import {
  USER_AUTH_EXPIRE, USER_DATA_SETTING_READY_SET, USER_GET_DATA_ERROR, USER_GET_DATA_REQUEST, USER_GET_DATA_SUCCESS, USER_GOOGLE_UNAUTHORIZED, USER_RESTORE_DATA, USER_SET_CLIENT_ID, USER_SET_CLIENT_LIMIT_WMS_ENABLED, USER_SET_CURRENT_CLIENT_LICENSE_AUTO_RENEWAL, USER_SET_USAGE_DISTANCE_MATRIX, USER_SIGN_IN_ERROR, USER_SIGN_IN_REQUEST, USER_SIGN_OUT_REQUEST, USER_SIGN_OUT_SUCCESS, USER_UPDATE_TWO_FACTOR_SUCCESS,
} from './userData.actionTypes';
import type {
  Role, UserDataLimits, UserDataStateUsage,
} from './userData.state';

export type UserGetDataSuccessPayload = Readonly<{
  '2fa': TwoFactorStats;
  clientId: number | null;
  clientMembers: Member[];
  clientName: string | null;
  clientRole: MemberRole;
  createdAt: string;
  currentClient: CurrentClient | null;
  email: string;
  firstName: string;
  googleApiOauth: GoogleApiOauthStatus | null;
  id: number;
  intercomHash: string;
  isPublicApiActive: boolean;
  lastName: string;
  limits: UserDataLimits;
  previousLogin: string | null;
  role: Role;
  settings: UserSettingsState;
  teamId: number | null;
  usage: UserDataStateUsage;
  intercom_hash: string;
}>;

export const userRestoreData = () => ({
  type: USER_RESTORE_DATA,
}) as const;

export const userSignInRequest = (email: string, password: string, rememberMe: boolean) => ({
  type: USER_SIGN_IN_REQUEST,
  payload: {
    email,
    password,
    rememberMe,
  },
}) as const;

export const userSignInError = (message: string) => ({
  type: USER_SIGN_IN_ERROR,
  payload: {
    message,
  },
}) as const;

export const userGetDataRequest = (options: { fetchAnnouncements?: boolean } = {}) => ({
  type: USER_GET_DATA_REQUEST,
  payload: {
    options,
  },
}) as const;

export const userGetDataSuccess = (data: UserGetDataSuccessPayload, options: { fetchAnnouncements?: boolean } = {}) => ({
  type: USER_GET_DATA_SUCCESS,
  payload: {
    data,
    options,
  },
}) as const;

export const userSetClientId = (clientId: number) => ({
  type: USER_SET_CLIENT_ID,
  payload: {
    clientId,
  },
}) as const;

export const userGetDataError = (message: string) => ({
  type: USER_GET_DATA_ERROR,
  payload: {
    message,
  },
}) as const;

export const userAuthorizationExpire = () => ({
  type: USER_AUTH_EXPIRE,
}) as const;

export const userSignOutRequest = (onSuccess?: () => void) => ({
  type: USER_SIGN_OUT_REQUEST,
  payload: {
    onSuccess,
  },
}) as const;

export const userSignOutSuccess = () => ({
  type: USER_SIGN_OUT_SUCCESS,
}) as const;

export const dataSettingsReadySet = (isReady: boolean) => ({
  type: USER_DATA_SETTING_READY_SET,
  payload: {
    isReady,
  },
}) as const;

export const userDataTwoFactorSuccess = (data: TwoFactorStats) => ({
  type: USER_UPDATE_TWO_FACTOR_SUCCESS,
  payload: {
    data,
  },
}) as const;

export const userGoogleUnauthorized = () => ({
  type: USER_GOOGLE_UNAUTHORIZED,
}) as const;

export const userCurrentClientLicenseSetAutoRenewal = (isAutoRenewalEnabled: boolean) => ({
  type: USER_SET_CURRENT_CLIENT_LICENSE_AUTO_RENEWAL,
  payload: {
    isAutoRenewalEnabled,
  },
}) as const;

export const userSetClientLimitWmsEnabled = (wmsEnabled: boolean) => ({
  type: USER_SET_CLIENT_LIMIT_WMS_ENABLED,
  payload: {
    wmsEnabled,
  },
}) as const;

export const userSetDistanceMatrixUsage = createAction(
  USER_SET_USAGE_DISTANCE_MATRIX,
  (count: number) => ({
    payload: {
      count,
    },
  })
);
