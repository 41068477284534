import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { updateFileUrls } from '~/store/frontendState/fileUrls/fileUrls.saga';
import { fileUrlsSelector } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { type FileAttachment } from '../../../_shared/types/file.types';
import { select } from '../../../_shared/utils/saga/effects';
import {
  fileAttachmentsUrlsSynced, userFilesDeleted,
} from '../../frontendState/fileUrls/fileUrls.actionCreator';
import { type FileUrl } from '../../frontendState/fileUrls/fileUrls.state';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../data/mapSettingsData.actionTypes';
import { removeDeletedFilesAttachmentsSaga } from './consistency/removeDeletedFilesAttachments.saga';
import { removeUnusedAttachmentsSaga } from './consistency/removeUnusedAttachments.saga';
import { syncActiveGroupMarkerAttachmentsSaga } from './consistency/syncActiveGroupMarkerAttachments.saga';
import { getBulkFiles } from './fileApi.helpers';
import {
  isErrorFile, UserFileErrorCode, type UserFilesResponse,
} from './fileApi.repository';
import { MAP_SETTINGS_FILE_ATTACHMENTS_ADD } from './fileAttachments.actionTypes';
import { mapSettingsFileAttachmentsListSelector } from './fileAttachments.selectors';

export function* fileAttachmentsSaga() {
  yield* removeUnusedAttachmentsSaga();
  yield* removeDeletedFilesAttachmentsSaga();
  yield* syncActiveGroupMarkerAttachmentsSaga();
  yield takeLatest(MAP_SETTINGS_FILE_ATTACHMENTS_ADD, fileAttachmentsSyncSaga);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, fileAttachmentsSyncSaga);
}

function* fileAttachmentsSyncSaga() {
  const clientId: number|null = yield select<number|null>(state => state.userData.clientId);
  const mapId: number|null = yield select<number|null>(state => state.map.mapId);
  const attachments: ReadonlyArray<FileAttachment> = yield select<ReadonlyArray<FileAttachment>>(mapSettingsFileAttachmentsListSelector);
  const fileUrls: ReadonlyMap<number, FileUrl> = yield select<ReadonlyMap<number, FileUrl>>(fileUrlsSelector);

  const filesToFetch = attachments
    .filter(a => !fileUrls.has(a.fileId))
    .map(a => a.fileId);

  if (filesToFetch.length) {
    const fetchedFiles: UserFilesResponse = yield call(getBulkFiles, clientId, filesToFetch, mapId);

    if (fetchedFiles?.list.length) {
      yield* updateFileUrls(fetchedFiles.list);

      const deletedFiles = fetchedFiles.list
        .filter(isErrorFile)
        .filter(file => file.error_code === UserFileErrorCode.NotFound);

      if (deletedFiles.length) {
        yield put(userFilesDeleted(deletedFiles.map(file => file.id)));
      }
    }
  }

  yield put(fileAttachmentsUrlsSynced());
}
