import { type SidebarTitleStyle } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { type Theme } from '../../../../_shared/themes/theme.model';

export const getTotalLocationsStyle = (theme: Theme): SidebarTitleStyle => ({
  description: {
    valueFontColor: theme.textColors.info,
    valueFontSize: '14',
    showValue: true,
    capitalize: true,
  },
});
