import { SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { createGetSpreadsheetBulkDataNumericParams } from '../../../heatMap/panel/form/useIsNumericColumnCheck.helpers';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBulkRequestGetter,
  type SpreadsheetDataBulkResponse,
} from '../../../spreadsheet/spreadsheet.repository';
import { type MatchupDataState } from '../../../store/matchupData/matchupData.state';
import { type SpreadsheetMatchupData } from '../../types/spreadsheetData/matchupData';
import { type MetricsDataAction } from './metrics.types';
import { type SpreadsheetColumnMetricModel } from './spreadsheetMetric.factory';

const numericColKeys = ['sale', 'sales', 'revenue', 'price', 'cost', 'expense', 'fee', 'payment', 'charge', 'figure', 'toll', 'fare',
  'amount', 'sum', 'total', 'result', 'penalty', 'appraisal', 'value', 'estimate', 'mortgage', 'debt', 'payroll', 'debit', 'surcharge'];

const isNumericCol = (colName: string) => {
  return numericColKeys.some((key) => colName.toLowerCase().includes(key));
};

export const getNumericColsIdsFromSpreadsheetMatchupData = (spreadsheetMatchupData: SpreadsheetMatchupData) => {
  return Object.entries(spreadsheetMatchupData.columns).reduce((result: string[], [colId, colName]) => {
    if (colName && isNumericCol(colName)) {
      return [...result, colId];
    }
    return result;
  }, []);
};

export const getNumericColumns = async (clientId: number, mapId: number, matchupData: MatchupDataState): Promise<SpreadsheetDataBulkResponse[]> => {
  const spreadsheetsAsBulkDataParams: SpreadsheetDataBulkRequestGetter[] = [];

  for (const [spreadsheetId, { data }] of Object.entries(matchupData)) {

    if (!data) {
      continue;
    }

    const spreadsheetIdParsed = parseInt(spreadsheetId, 10);
    const columnIds = getNumericColsIdsFromSpreadsheetMatchupData(data);

    if (!columnIds.length) {
      continue;
    }
    spreadsheetsAsBulkDataParams.push(createGetSpreadsheetBulkDataNumericParams(mapId, spreadsheetIdParsed, columnIds));
  }

  if (spreadsheetsAsBulkDataParams.length) {
    const response: { data: SpreadsheetDataBulkResponse[] } = await getSpreadsheetBulkData(clientId, { params: spreadsheetsAsBulkDataParams });
    return response.data;
  }

  return [];
};

export const createSpreadsheetMetricModelForDataActions = (id: string, spreadsheetId: number, name: string, dataActions: MetricsDataAction[]) => {
  const result: SpreadsheetColumnMetricModel[] = [];
  for (const dataAction of dataActions) {
    result.push(({
      type: SelectedDataType.SpreadsheetColumn,
      spreadsheetColumn: {
        id,
        spreadsheetId,
        name,
      },
      dataAction,
    }));
  }

  return result;
};

export const getColumnName = (matchupData: MatchupDataState, spreadsheetId: number, columnId: string) => {
  return matchupData[spreadsheetId]?.data?.columns[columnId] || columnId;
};

export const distinctSpreadsheetColumnMetrics = (metrics: SpreadsheetColumnMetricModel[]): SpreadsheetColumnMetricModel[] => {
  const uniqueMetrics = new Map<string, SpreadsheetColumnMetricModel>();

  for (const metric of metrics) {
    const metricKey = `${metric.spreadsheetColumn.id}_${metric.dataAction}`;
    const presentMetric = uniqueMetrics.get(metricKey);
    if (!presentMetric) {
      uniqueMetrics.set(metricKey, metric);
    }
  }

  return Array.from(uniqueMetrics.values());
};
