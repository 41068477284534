import {
  type DependencyList,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { type FixedLengthArray } from '../../_shared/utils/collections/collections';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import {
  ensureZIndexesWithOrder,
  releaseZIndexes,
} from '../../store/frontendState/mapZIndexes/mapZIndexes.actionCreators';
import {
  calculateZIndex,
  getZIndexesPerEntity,
  type SubEntitySize,
  type ZIndexedEntity,
} from './zIndexRanges';

export type ZIndexSeriesParams<
  TEntity,
  TSubEntity extends string,
  TZIndexEntityType extends ZIndexedEntity
> = {
  orderedEntities: ReadonlyArray<TEntity>;
  orderedSubEntities?: FixedLengthArray<SubEntitySize<TZIndexEntityType>, TSubEntity>;
  idMapper: (entity: TEntity) => string;
  zIndexedEntity: TZIndexEntityType;
};

export type ZIndexSeries<TSubEntity extends string> = Readonly<{
  entityZIndexes: ReadonlyArray<number>;
  subEntityZIndexes: ReadonlyArray<Record<TSubEntity, number>>;
  combined: ReadonlyArray<Readonly<{
    entityId: string;
    entityZIndex: number;
    subEntityZIndexes: Record<TSubEntity, number>;
  }>>;
}>;

export const useZIndexSeries = <TEntity, TSubEntity extends string, TZIndexEntityType extends ZIndexedEntity>(
  getParams: () => ZIndexSeriesParams<TEntity, TSubEntity, TZIndexEntityType>,
  deps: DependencyList
): ZIndexSeries<TSubEntity> => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => getParams(), deps);
  const { orderedEntities } = params;
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const reservedIdsRef = useRef<string[]>([]);

  const usedZIndexes = useSelector(s => s.frontendState.mapZIndexes[paramsRef.current.zIndexedEntity].usedZIndexes);

  // Update zIndexes when orderedEntities change
  useEffect(() => {
    const { idMapper, zIndexedEntity } = paramsRef.current;
    const idsToReserve = orderedEntities.map(idMapper);

    const idsToReserveSet = new Set(idsToReserve);
    const idsToRelease = reservedIdsRef.current.filter(id => !idsToReserveSet.has(id));

    if (idsToRelease.length > 0) {
      dispatch(releaseZIndexes(idsToRelease, zIndexedEntity));
    }

    reservedIdsRef.current = idsToReserve;
    dispatch(ensureZIndexesWithOrder(idsToReserve, zIndexedEntity));

  }, [orderedEntities, dispatch]);

  // Unmount
  useEffect(() => {
    return () => {
      const { zIndexedEntity } = paramsRef.current;

      if (reservedIdsRef.current.length > 0) {
        dispatch(releaseZIndexes(reservedIdsRef.current, zIndexedEntity));
      }
    };
  }, [dispatch]);

  return useMemo(() => {
    const { idMapper, zIndexedEntity } = paramsRef.current;
    const orderedSubEntities = Array.from(paramsRef.current.orderedSubEntities ?? []);

    const combined = orderedEntities
      .map(idMapper)
      .map((entityId, entityIndex) => {
        const entityZIndex = usedZIndexes.get(entityId)
          ?? calculateZIndex(entityIndex * getZIndexesPerEntity(zIndexedEntity), zIndexedEntity);

        const subEntityZIndexes = orderedSubEntities.reduce((result, subEntity, subEntityIndex) => {
          result[subEntity] = entityZIndex + subEntityIndex;
          return result;
        }, {} as Record<TSubEntity, number>);

        return {
          entityId,
          entityZIndex,
          subEntityZIndexes,
        };
      });

    return {
      combined,
      entityZIndexes: combined.map(i => i.entityZIndex),
      subEntityZIndexes: combined.map(i => i.subEntityZIndexes),
    };
  },
  [usedZIndexes, orderedEntities]);
};
