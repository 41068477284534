const BASE_PATH = '';

const MAP_ID_SEGMENT = ':mapId';
const MAP_PATH = `map/${MAP_ID_SEGMENT}` as const;
const DATA_PATH = `data/${MAP_ID_SEGMENT}` as const;

export const AppNavigationPaths = {
  Base: `${BASE_PATH}/*`,
  Map: MAP_PATH,
  Data: DATA_PATH,
} as const;

const _HOME_ABSOLUTE_ROUTE = `${BASE_PATH}/` as const;
const _MAP_ABSOLUTE_ROUTE = `${BASE_PATH}/${MAP_PATH}` as const;
const _DATA_ABSOLUTE_ROUTE = `${BASE_PATH}/${DATA_PATH}` as const;

export type HomeRoute = typeof _HOME_ABSOLUTE_ROUTE;
export type MapRoute = typeof _MAP_ABSOLUTE_ROUTE;
export type DataRoute = typeof _DATA_ABSOLUTE_ROUTE;
