import {
  type MutableRefObject, type ReactElement,
} from 'react';
import { SortableItemComponent } from './item/sortableItem.component';

export type SortableItemRenderProps<T> = {
  data: T;
  index: number;
  isDragging: boolean;
  moveTriggerRef: MutableRefObject<null | HTMLElement>;
};

type SortableProps<T> = {
  itemType: string;
  className?: string;
  items: readonly SortableItem<T>[];
  onItemsChange: (items: readonly SortableItem<T>[]) => void;
  renderItem: (props: SortableItemRenderProps<T>) => ReactElement;
};

export interface SortableItem<T> {
  id: string;
  data: T;
}

export const SortableComponent = <T extends (object | string)>(props: SortableProps<T>) => {
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = props.items[dragIndex];
    const newItems = [...props.items];
    newItems.splice(dragIndex, 1);
    if (dragItem) {
      newItems.splice(hoverIndex, 0, dragItem);
    }

    props.onItemsChange(newItems);
  };

  return (
    <div className={props.className}>
      {props.items.map((item, i) => (
        <SortableItemComponent
          key={item.id}
          index={i}
          id={item.id}
          moveItem={moveItem}
          render={renderProps => props.renderItem({
            isDragging: renderProps.isDragging,
            moveTriggerRef: renderProps.ref,
            index: i,
            data: item.data,
          })}
          itemType={props.itemType}
        />
      ))}
    </div>
  );
};
