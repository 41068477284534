import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ERASURE_KEYS, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useDrawingItemRemove } from '~/drawingTool/hooks/useDrawingItemRemove';
import { useSelectedDrawingItemWithOptions } from '~/drawingTool/hooks/useSelectedDrawingItemWithOptions';
import { useSelectedDrawingOptionsChange } from '~/drawingTool/hooks/useSelectedDrawingOptionsChange';
import { drawingEditDiscardChanges } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { useDrawingEditIsSelectedDrawingEdited } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { useDrawingSettingsSelector } from '~/store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { DrawingToolDetailsContentComponent } from './drawingToolDetailsContent.component';

export const DrawingToolDetailsContentContainer: FC = () => {
  const isSelectedDrawingChanged = useDrawingEditIsSelectedDrawingEdited();
  const selectedDrawingWithOptions = useSelectedDrawingItemWithOptions();
  const drawingSettings = useDrawingSettingsSelector();
  const { removeDrawingItem } = useDrawingItemRemove();
  const { updateSelectedDrawingOptions } = useSelectedDrawingOptionsChange();
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    if (!selectedDrawingWithOptions) {
      return;
    }

    removeDrawingItem(selectedDrawingWithOptions.item.type, selectedDrawingWithOptions.item.value.id);
  }, [selectedDrawingWithOptions, removeDrawingItem]);

  const onResetClick = () => {
    dispatch(drawingEditDiscardChanges());
  };

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: onDelete },
    targetKeys: ERASURE_KEYS,
  }), [onDelete]));

  if (!selectedDrawingWithOptions) {
    return null;
  }

  return (
    <DrawingToolDetailsContentComponent
      isResetButtonDisabled={!isSelectedDrawingChanged}
      drawingSettings={drawingSettings}
      onResetClick={onResetClick}
      onDeleteClick={onDelete}
      onToolOptionsChange={updateSelectedDrawingOptions}
      selectedDrawingItem={selectedDrawingWithOptions.item}
      selectedItemOptions={selectedDrawingWithOptions.options}
    />
  );
};
