import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { removeMap } from '~/map/map.repository';
import {
  baseMapDeletionResolutionsToArray,
  convertResolutionToServerModel, createRemoveMapRequestForConflictingMap,
  createResolutionAction,
  type HandleBaseMapDeletionModalHelper,
} from './handleBaseMapDeletionModal.helper';

export const useConflictingMapDelete = (clientId: number | null) => {
  const deleteWithConflictResolution = useCallback((params: { mapId: number; selectedResolutions: HandleBaseMapDeletionModalHelper; allowCopiesForDependantUsers: boolean }) => {
    const exec = async () => {
      if (!clientId) {
        return Promise.resolve();
      }

      const actions = baseMapDeletionResolutionsToArray(params.selectedResolutions).map(([mapId, resolution]) => {
        return createResolutionAction(mapId, convertResolutionToServerModel(resolution));
      });
      const request = createRemoveMapRequestForConflictingMap(
        params.mapId, actions, params.allowCopiesForDependantUsers
      );

      return removeMap(clientId, request);
    };
    return exec();
  }, [clientId]);
  const { invokeAjax, isLoading } = useAjaxCall(deleteWithConflictResolution);

  return {
    isLoading,
    deleteMap: invokeAjax,
  };
};
