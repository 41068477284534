import { type SpreadsheetRowId } from '../../../_shared/types/spreadsheetData/spreadsheetRow';

export enum RightSidebarView {
  LocationListing = 'LocationListing',
  CustomizeLocation = 'CustomizeLocation',
  ProximityDetails = 'ProximityDetails',
  BoundaryDetails = 'BoundaryDetails',
  BoundaryTerritoryDetails = 'BoundaryTerritoryDetails',
  DrawingToolDetails = 'DrawingToolDetails',
}

export type ActiveProximity = Readonly<{
  proximityId: string;
  spreadsheetRowId?: SpreadsheetRowId;
}>;

export type RightSidebarConfiguration = ({
  view: RightSidebarView.CustomizeLocation;
  markerId: SpreadsheetRowId;
} | {
  view: RightSidebarView.ProximityDetails;
  activeMetrics: ActiveProximity;
} | {
  view: RightSidebarView.BoundaryDetails;
  boundaryGroupId: number;
  boundaryId: number;
  boundaryTerritoryGroupId: number;
} | {
  boundaryTerritoryGroupId: number;
  boundaryTerritoryId: string;
  view: RightSidebarView.BoundaryTerritoryDetails;
} | {
  view: RightSidebarView.LocationListing;
} | {
  view: RightSidebarView.DrawingToolDetails;
}) & Readonly<{
  isLocationKeyOrFinderActive?: boolean;
}>;

export enum RightSidebarOpeneableApps {
  EditPanel = 'EditPanel',
}
