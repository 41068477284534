import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Success]: {
  views: {
    default: {
      canvasHeight: 200,
      canvasWidth: 200,
      iconWidth: 190,
      iconHeight: 190,
    },
  },
  colors: {
    tick: '#FFFFFF',
    border: '#76C37C',
    fill: '#76C37C',
  },
  gradients: {},
  frameRate: 50,
  segments: {
    default: [0, 120] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/95833-success" */
      './95833-success.json'
    ),
} };

export const { Success } = config;
