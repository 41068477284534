import {
  DropDownItemSize,
  RegularDropdownComponent,
  type RegularDropdownProps,
} from '../../../../../dropdown';
import { suffixDropdownStyle } from './suffixDropdown.styles';

type SuffixDropdownProps<T> = Pick<RegularDropdownProps<T>, 'value' | 'onChange' | 'options' | 'triggerStyle' |
'triggerIconStyle' | 'itemStyle' | 'placeholder' | 'inputSize'>;

export const SuffixDropdown = <T extends unknown>(props: SuffixDropdownProps<T>) => {
  return (
    <RegularDropdownComponent
      {...props}
      triggerStyle={[props.triggerStyle, suffixDropdownStyle]}
      inputSize={props.inputSize}
      itemSize={DropDownItemSize.Large}
      inPortal
    />
  );
};
