import {
  MAP_LISTING_CHANGED, MAP_LISTING_SET_SEARCH_QUERY,
} from './mapListing.actionTypes';

export const mapListingSearchQueryChanged = (searchQuery: string) => ({
  type: MAP_LISTING_SET_SEARCH_QUERY,
  payload: {
    searchQuery,
  },
}) as const;

export const mapListingChanged = () => ({
  type: MAP_LISTING_CHANGED,
}) as const;
