import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type MarkerAnchorPosition } from '~/_shared/types/marker.types';
import { setMapSettingsMarkerTextureAnchor } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { FileAttachmentTool } from '../../_shared/types/file.types';
import {
  createNewFileAttachment, getFileAttachmentId,
} from '../../_shared/utils/files/fileAttachments.helpers';
import { mapSettingsFileAttachmentsAdd } from '../../store/mapSettings/fileAttachments/fileAttachments.actionCreators';
import { useMapSettingsFileAttachmentsMapSelector } from '../../store/mapSettings/fileAttachments/fileAttachments.selectors';

export const useActiveCustomMarkerTextures = () => {
  const dispatch = useDispatch();
  const fileAttachmentMap = useMapSettingsFileAttachmentsMapSelector();

  const getFileAttachmentIfUsedAsTextureByFileId = useCallback((fileId: number) => {
    const fileAttachment = fileAttachmentMap.get(getFileAttachmentId(fileId, FileAttachmentTool.CustomMarker));
    if (fileAttachment) {
      return fileAttachment;
    }
    return undefined;
  }, [fileAttachmentMap]);

  const getFileAttachmentIdIfUsedAsTextureByFileId = useCallback((fileId: number) =>
    getFileAttachmentIfUsedAsTextureByFileId(fileId)?.id, [getFileAttachmentIfUsedAsTextureByFileId]);

  const activateTexture = useCallback(
    (fileId: number, anchor: MarkerAnchorPosition) => {
      let fileAttachment = getFileAttachmentIfUsedAsTextureByFileId(fileId);

      if (!fileAttachment) {
        fileAttachment = createNewFileAttachment(fileId, FileAttachmentTool.CustomMarker);
        dispatch(mapSettingsFileAttachmentsAdd(fileAttachment));
      }

      dispatch(setMapSettingsMarkerTextureAnchor(fileAttachment.id, anchor));
      return fileAttachment.id;
    },
    [dispatch, getFileAttachmentIfUsedAsTextureByFileId],
  );

  return {
    activateTexture,
    getFileAttachmentIdIfUsedAsTextureByFileId,
  };
};
