import {
  type FC, type MouseEvent, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type DrawingItemText } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { MapTextInputComponent } from '../../mapObjects/mapTextInput/mapTextInput.component';
import { MapTextInputContainer } from '../../mapObjects/mapTextInput/mapTextInput.container';
import { type MapTextInputInstance } from '../../mapObjects/mapTextInput/mapTextInputModel';

export type DrawingToolTextInstanceTextProps = Readonly<{
  instance: DrawingItemText;
  zIndex: MapObjectZIndex;
  fontSize: number;
  autoFocusOnInit?: boolean;
  disabled?: boolean;
  selectionDisabled?: boolean;
  etag?: string;

  onClick?: (event: MouseEvent) => void;
  onRightClick?: (event: MouseEvent) => void;
  onTextMouseOut?: () => void;
  onTextMouseOver?: () => void;
  renderOutline?: (outline: MapOutlinePositionInstance, size: number) => ReactNode;
  onTextChange?: (newText: string) => void;
}>;

export const DrawingToolTextInstanceTextContainer: FC<DrawingToolTextInstanceTextProps> = (props) => {
  const { onTextMouseOut, onTextMouseOver, onClick, onRightClick, onTextChange } = props;
  const { instance, autoFocusOnInit, fontSize, disabled, selectionDisabled, zIndex, etag } = props;

  const renderInputField = useCallback((textItem: MapTextInputInstance) => {
    return (
      <MapTextInputComponent
        instance={textItem}
        visuals={{
          background: 'none',
          fontColor: instance.settings.fontColor,
          offsetX: Math.round(fontSize / 2 + 5),
          padding: 0,
        }}
        disabled={disabled}
        selectionDisabled={selectionDisabled}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseOut={onTextMouseOut}
        onMouseOver={onTextMouseOver}
        onTextChange={onTextChange}
        autoFocusOnInit={autoFocusOnInit}
        etag={etag}
      />
    );
  }, [instance.settings.fontColor, fontSize, disabled, selectionDisabled, onClick,
    onRightClick, onTextMouseOut, onTextMouseOver, onTextChange, autoFocusOnInit, etag]);

  const renderOutline = useMemo(() => {
    if (!props.renderOutline) {
      return undefined;
    }

    const renderOutlineCallback = props.renderOutline;
    const outlineSize = Math.max(Math.round(fontSize / 4), 5);

    return (outline: MapOutlinePositionInstance) => renderOutlineCallback(outline, outlineSize);
  }, [props.renderOutline, fontSize]);

  const textInstance = useMemo<MapTextInputInstance | null>(() => {
    return {
      id: instance.id,
      startPoint: instance.startPoint,
      text: instance.text,
      fontSize,
    };
  }, [instance.id, instance.startPoint, instance.text, fontSize]);

  if (!textInstance) {
    return null;
  }

  return (
    <MapTextInputContainer
      zIndex={zIndex}
      instance={textInstance}
      renderTextInput={renderInputField}
      renderOutline={renderOutline}
    />
  );
};
