import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { CreateCustomBoundaryMode } from './createCustomBoundaryGroupPane.component';

export const getCreateCustomBoundaryModeOptions = (t: TranslationFnc): ReadonlyArray<DropdownOption<CreateCustomBoundaryMode>> => ([{
  name: t('Create Territories Manually'),
  value: CreateCustomBoundaryMode.Manual,
},
/* hide reason: https://github.com/BradCrisp/MaptiveV5-FE/pull/789#discussion_r1185864247
{
  name: t('Group Areas Using Data'),
  value: CreateCustomBoundaryMode.FromData,
},*/
{
  name: t('Auto Territory Builder'),
  value: CreateCustomBoundaryMode.FromAI,
}]);
