import { css } from '@emotion/react';
import {
  type FC, type ReactNode, useMemo,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import { TinyMapColorTileComponent } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import type { SliderValue } from '~/_shared/components/slider/slider.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { changeColorAlpha } from '~/_shared/utils/colors/colors.helpers';
import {
  MARKER_SIZE_MAX, MARKER_SIZE_MIN,
} from '~/_shared/utils/markers/markers.constants';
import { roundNumberDecimalPlaces } from '~/_shared/utils/number/number.helpers';
import { BoundarySettingsTableCellWrapperComponent } from '~/boundary/settings/table/row/BoundarySettingsTableCellWrapper.component';
import { BoundarySettingsTableRowWrapperComponent } from '~/boundary/settings/table/row/BoundarySettingsTableRowWrapper.component';
import { useHandleSliderOption } from '~/customizeMarkerPopup/customizeMarker/customizationOptions/useHandleSliderOption.hook';
import { ColorPickerComponent } from '../../colorPicker/colorPicker.component';

const SLIDER_WIDTH = 60; // note that if you want to set this to a lower value, double check if it's still well usable accross devices
const SLIDER_INPUT_WIDTH = 60;

const tableFillColorWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const opacityWrapperStyle = css({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  gap: 12,
  justifyContent: 'space-between',
});

const sliderInputStyle = css({
  width: SLIDER_INPUT_WIDTH,
});

const sliderStyle = css({
  paddingBottom: 0,
  width: SLIDER_WIDTH,
});

const groupNameStyle = css({
  overflow: 'visible',
  textOverflow: 'clip',
  whiteSpace: 'normal',
});

const mapTileStyle = css({
  marginLeft: 8,
});

const unitStyles = css({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

const OpacityUnit = (
  <div css={unitStyles}>
    %
  </div>
);

type BoundarySettingsTableRowProps = Readonly<{
  index: number;
  data: ReactNode;
  color: ColorResult;
  lineColor: ColorResult;
  lineWidth: number;
  onColorChange: (color: ColorResult) => void;
}>;

export const BoundarySettingsTableRowComponent: FC<BoundarySettingsTableRowProps> = (props) => {
  const opacity = (props.color.rgb?.a ?? 1) * 100;

  const changeColorOpacity = (alpha: number) => {
    const newColor = changeColorAlpha(props.color.rgb, alpha / 100);
    props.onColorChange(newColor);
  };

  const opacitySliderHandlers = useHandleSliderOption(opacity, changeColorOpacity, MARKER_SIZE_MIN, MARKER_SIZE_MAX);

  const roundedValue = useMemo(() => {
    const value = opacitySliderHandlers.inputValue;
    const numValue = Number(value);

    if (isNaN(numValue)) {
      return value;
    }

    return roundNumberDecimalPlaces(numValue, 0).toString();
  }, [opacitySliderHandlers.inputValue]);

  return (
    <BoundarySettingsTableRowWrapperComponent>
      <BoundarySettingsTableCellWrapperComponent>
        <div css={tableFillColorWrapperStyle}>
          <RowNumber number={props.index} />
          <ColorPickerComponent
            onChange={props.onColorChange}
            selectedColor={props.color}
            displayAlpha={false}
            size={22}
          />
          <TinyMapColorTileComponent
            color={props.color.rgb}
            lineColor={props.lineColor.rgb}
            lineWidth={Math.ceil(props.lineWidth / 2)}
            css={mapTileStyle}
            size="Large"
          />
        </div>
      </BoundarySettingsTableCellWrapperComponent>

      <BoundarySettingsTableCellWrapperComponent>
        <div css={opacityWrapperStyle}>
          <DebouncedUpdateComponent<SliderValue>
            onChangeDebounced={opacitySliderHandlers.onSliderChange}
            render={(value, onChange) => (
              <SliderWithValueLabelsComponent
                css={sliderStyle}
                currentValueOnTheRight
                noLabel
                onChange={onChange}
                step={1}
                value={value}
              />
            )}
            value={[opacity]}
            wait={50}
          />
          <TextInputComponent
            css={sliderInputStyle}
            icon={null}
            onBlur={() => opacitySliderHandlers.onInputBlur(roundedValue)}
            onChange={opacitySliderHandlers.onTextInputChange}
            rightContent={OpacityUnit}
            value={roundedValue}
          />
        </div>
      </BoundarySettingsTableCellWrapperComponent>

      <BoundarySettingsTableCellWrapperComponent css={groupNameStyle}>
        <LimitNumberOfLinesComponent maxNumberOfLines={2} >
          {props.data}
        </LimitNumberOfLinesComponent>
      </BoundarySettingsTableCellWrapperComponent>
    </BoundarySettingsTableRowWrapperComponent>
  );
};

const tableCounterStyle = css({
  marginRight: 12,
});

const RowNumber: FC<{ number: number }> = ({ number }) => {
  const theme = useTheme();
  const circleColors = useMemo(() => ({
    fillColor: theme.backgroundColors.secondaryHover,
    fontColor: theme.textColors.primary,
  }), [theme.backgroundColors.secondaryHover, theme.textColors.primary]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={tableCounterStyle}
      size={24}
    >
      {number}
    </NumberInCircleComponent>
  );
};
