import { PRESET_COLORS_SECONDARY } from '../../_shared/constants/colors.constants';
import { getNextColorFromPresets } from '../../_shared/utils/colors/colors.helpers';
import { type TranslationFnc } from '../../_shared/utils/hooks';

export const getRouteColor = (usedColors: string[]) => {
  return getNextColorFromPresets(usedColors, PRESET_COLORS_SECONDARY);
};

export const getRouteName = (routeNumber: number, t: TranslationFnc) => {
  return t('Route #{{number}}', { number: routeNumber });
};

export const getDefaultOrCustomRouteName = (name: string, t: TranslationFnc) => {
  const regex = /^(Route) #\d+$/;
  if (regex.test(name)) {
    const number = name.split('#')[1] as unknown as number;
    return getRouteName(number, t);
  }
  return name;
};
