import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo, useState,
} from 'react';
import { AccordionComponent } from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { StackedWarningsComponent } from '~/_shared/baseComponents/warning/stackedWarnings.component';
import {
  getMatchUpColumnRolesForSection, getMatchUpDataHeaderForSection,
} from '~/_shared/components/matchUpDataModal/matchUpDataModal.helpers';
import { ADMIN_MAP_PAGES } from '~/_shared/components/modal/modal.constants';
import { filterAddedSpreadsheetColumns } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import {
  KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  type ColumnRole, type ColumnsMatchUp, createColumnRoleName,
} from '../../types/columnRole.enum';
import {
  type TranslationFnc, useTranslation,
} from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';
import {
  MatchUpDataTableComponent, type RowProps,
} from './matchUpDataTable.component';

export const MATCHUP_MODAL_WIDTH = 547;

const contentPadding = 15;

export enum MatchUpDataSection {
  LocationData = 'LocationData',
  InfoPopup = 'InfoPopup'
}

export const matchUpDataAllDataSections = [MatchUpDataSection.LocationData, MatchUpDataSection.InfoPopup];

const infoTextStyle = css({
  padding: contentPadding,
});

const contentStyle = css({
  padding: 0,
});

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

const footerStyle = css({
  width: '100%',
});

const submitButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

const submitWarningStyle = css({
  marginBottom: 20,
});

const findUnusedColumns = (columns: ReadonlyArray<[string, string]>, matchUp: ColumnsMatchUp): ReadonlyArray<[string, string]> => {
  const usedColumnIds: ReadonlySet<string | null> = new Set<string | null>(Object.values(matchUp).map(v => v[0]));

  return columns.filter(c => !usedColumnIds.has(c[1]));
};

const convertColumnsToDropdownOptions = (columns: ReadonlyArray<[string, string]>): ReadonlyArray<DropdownOption<string>> =>
  columns.map(c => ({ name: c[0], value: c[1] }));

const createOnChange = (matchUp: ColumnsMatchUp, onChange: (newMatchUp: ColumnsMatchUp) => void, role: ColumnRole) =>
  (newColumnId: string) =>
    onChange({ ...matchUp, [role]: [newColumnId, false] });

const addSelectedColumn = (props: MatchUpDataProps, columns: ReadonlyArray<[string, string]>, selectedColumnId: null | string) => {
  const selectedColumn = selectedColumnId !== null && props.columns.find(c => c[1] === selectedColumnId);

  return selectedColumn
    ? [...columns, selectedColumn]
    : columns;
};

const createRowProps = (props: MatchUpDataProps, roles: ReadonlyArray<ColumnRole>, t: TranslationFnc): ReadonlyArray<RowProps> =>
  roles.map(role => {
    const dropdownOptions = [
      {
        value: undefined,
        name: t('No Match'),
      },
      ...filterAddedSpreadsheetColumns(
        convertColumnsToDropdownOptions(
          addSelectedColumn(
            props, findUnusedColumns(props.columns, props.matchUp),
            props.matchUp[role][0]
          )
        )
      ),
    ];

    return {
      onChange: createOnChange(props.matchUp, props.onSave, role),
      dropdownOptions,
      selectedOptionId: props.matchUp[role][0],
      rowName: createColumnRoleName(role, t),
      isAutoMatched: props.matchUp[role][1],
    };
  });

export type MatchUpDataProps = Readonly<{
  columns: ReadonlyArray<[string, string]>;
  matchUp: ColumnsMatchUp;
  hideLatLng?: boolean;
  hideHeading?: boolean;
  isPublicApiActive: boolean;
  sections: ReadonlyArray<MatchUpDataSection>;

  onSave: (newMatchUp: ColumnsMatchUp) => void;
}>;

export const MatchUpDataComponent: FC<MatchUpDataProps> = (props) => {
  const [t] = useTranslation();
  const [displayedSections, setDisplayedSections] = useState<ReadonlyArray<MatchUpDataSection>>([...props.sections]);

  const toggleDisplaySection = (part: MatchUpDataSection) => displayedSections.includes(part)
    ? setDisplayedSections(displayedSections.filter(p => p !== part))
    : setDisplayedSections([...displayedSections, part]);

  return (
    <>
      {!props.hideHeading && (
        <div css={infoTextStyle}>
          {t('matchUpModalInfoMessage')}
        </div>
      )}

      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={props.sections.map((section) => ({
            header: getMatchUpDataHeaderForSection(section, t),
            isExpanded: displayedSections.includes(section),
            onHeadingClick: () => toggleDisplaySection(section),
            child: (
              <MatchUpDataTableComponent
                primaryColumnHeading={t('Categories').toUpperCase()}
                secondaryColumnHeading={t('Your Data').toUpperCase()}
                rows={createRowProps(props, getMatchUpColumnRolesForSection(
                  section, props.hideLatLng, !props.isPublicApiActive,
                ), t)}
              />
            ),
          }))}
          itemButtonStyle={itemButtonStyle}
          panelStyle={contentStyle}
          showCount
        />
      </OutlinePanelComponent>
    </>
  );
};

type MatchUpDataModalProps = ModalProps<MatchUpDataProps> & Readonly<{
  isCloseDisabled?: boolean;
  isSubmitDisabled: boolean;
  onSubmit: () => void;
}>;

export const MatchUpDataModalComponent: FC<MatchUpDataModalProps> = ({
  onClose,
  isOpen,
  isCloseDisabled,
  isSubmitDisabled,
  onSubmit,
  ...restProps
}) => {
  const [t] = useTranslation();

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: onSubmit },
    options: { allowActionWhenModalOpen: true, allowInputs: true },
    targetKeys: KeyboardKeys.Enter,
  }), [onSubmit]));

  return (
    <ModalComponent
      allowedPages={ADMIN_MAP_PAGES}
      onClose={onClose}
      isOpen={isOpen}
      isCloseDisabled={isCloseDisabled}
      contentStyle={contentStyle}
      maxWidth={MATCHUP_MODAL_WIDTH}
      caption={t('Categorize Data')}
      footer={(
        <div css={footerStyle}>
          {isSubmitDisabled && (
            <StackedWarningsComponent
              css={submitWarningStyle}
              warnings={[t('At least one of the columns: Address, City, State, Zip, Country, or both Latitude and Longitude columns must be selected to categorize the data.')]}
            />
          )}

          <div css={submitButtonWrapperStyle}>
            <ButtonComponent
              text={t('Done')}
              isDisabled={isSubmitDisabled}
              onClick={onSubmit}
              prefixIcon={faCheck}
            />
          </div>
        </div>
      )}
    >
      <MatchUpDataComponent {...restProps} />
    </ModalComponent>
  );
};
