import {
  type FC, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useIsCurrentMapSnapshotSelector } from '~/store/selectors/isCurrentMapSnapshot.selector';
import { useTranslation } from '../../_shared/utils/hooks';
import { useMapSettingsDataSelector } from '../../map/map/useMapSettings.hook';
import { usePresentationalColumnsRestrictionsMatchup } from '../../presentationalColumnsRestrictions/usePresentationalColumnsRestrictionsMatchup';
import {
  useIsMapSettingsSyncInProgressSelector,
  useIsSpreadsheetDataFetchingInProgressSelector,
} from '../../store/frontendState/processing/processing.selectors';
import {
  mapSettingsFilteringChangeDataType, mapSettingsFilteringToggleOnOff,
} from '../../store/mapSettings/filtering/mapSettingsFiltering.actionCreators';
import { presentationalColumnsRestrictionsSetRestriction } from '../../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.actionCreators';
import { usePresentationalColumnsRestrictionsIsLoadingSelector } from '../../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.selectors';
import { type DataType } from '../../store/spreadsheetData/spreadsheetData.state';
import { FilterSettingsComponent } from './filterSettings.component';
import { getFilterSettingsSettingRows } from './filterSettings.helpers';

type FilterSettingsContainer = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const FilterSettingsContainer: FC<FilterSettingsContainer> = (props) => {
  const spreadsheetColumns = useSpreadsheetColumns();
  const mapSettings = useMapSettingsDataSelector();
  const isMapSnapshot = useIsCurrentMapSnapshotSelector();
  const isSpreadsheetDataLoading = useIsSpreadsheetDataFetchingInProgressSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const isColumnsRestrictionsLoading = usePresentationalColumnsRestrictionsIsLoadingSelector();
  const restrictionsMatchup = usePresentationalColumnsRestrictionsMatchup();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const filterSettingDataRows = useMemo(() => {
    return getFilterSettingsSettingRows(spreadsheetColumns, mapSettings, restrictionsMatchup, isMapSnapshot, t);
  }, [spreadsheetColumns, mapSettings, restrictionsMatchup, isMapSnapshot, t]);

  const dispatchProps = useMemo(() => ({
    onDataTypeChange: (rowIndex: number, newDataType: DataType) => {
      const columnId = filterSettingDataRows[rowIndex]?.columnId;
      if (columnId) {
        dispatch(mapSettingsFilteringChangeDataType(columnId, newDataType));
      }
    },
    onPublicSet: (rowIndex: number, newValue: boolean) => {
      const spreadsheetColumnId = filterSettingDataRows[rowIndex]?.columnId;

      if (spreadsheetColumnId) {
        dispatch(presentationalColumnsRestrictionsSetRestriction(spreadsheetColumnId, 'filter', !newValue));
      }
    },
    onOnToggle: (rowIndex: number) => {
      const columnId = filterSettingDataRows[rowIndex]?.columnId;

      if (columnId) {
        dispatch(mapSettingsFilteringToggleOnOff(columnId));
      }
    },
  }), [dispatch, filterSettingDataRows]);

  return (
    <FilterSettingsComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      settingRows={filterSettingDataRows.map(item => item.row)}
      onDataTypeChange={dispatchProps.onDataTypeChange}
      onPublicSet={dispatchProps.onPublicSet}
      onOnToggle={dispatchProps.onOnToggle}
      isLoading={isSpreadsheetDataLoading || isMapSettingsSyncInProgress || isColumnsRestrictionsLoading}
    />
  );
};
