import {
  type FC, useEffect, useState,
} from 'react';
import { canMemberManageClient } from '~/clientTeamManagement/memberRoles.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  useClientInviteesListTagSelector,
  useClientMembersListTagSelector,
} from '~/store/frontendState/client/client.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import {
  type Invitee,
  type Member,
} from './teamManagement.repository';
import { TeamManagementModalComponent } from './teamManagementModal.component';
import {
  useGetInvitations, useGetMembers,
} from './useTeamManagement';

export type InvitationAndMemberData = Member & {
  invitationId: string | null;
  invitee: null | Invitee;
};

export type TeamManagementModalContainerProps = ModalProps;

export const TeamManagementModalContainer: FC<TeamManagementModalContainerProps> = (props) => {
  const clientId = useClientIdSelector();
  const clientMembersListEtag = useClientMembersListTagSelector();
  const clientInviteesListEtag = useClientInviteesListTagSelector();
  const clientRole = useUserDataSelector().clientRole;

  const {
    isLoading: isGettingMembers,
    data: membersData,
    invokeAjax: getMembers,
  } = useGetMembers(clientId);

  const {
    isLoading: isGettingInvitations,
    data: invitationsData,
    invokeAjax: getInvitations,
  } = useGetInvitations(clientId);

  const [data, setData] = useState<InvitationAndMemberData[] | undefined>(undefined);

  useEffect(() => {
    getMembers();
  }, [clientMembersListEtag, getMembers]);

  useEffect(() => {
    getInvitations();
  }, [clientInviteesListEtag, getInvitations]);

  useEffect(() => {
    if (!membersData || !invitationsData) {
      return;
    }

    const newData = membersData.members.map((item) => {
      const invitation = invitationsData.data.find(
        (invitationItem) => invitationItem.email === item.email
      );
      return {
        ...item,
        invitationId: invitation ? invitation.id : null,
        invitee: invitation ? invitation.invitee : null,
      };
    });

    setData(newData);
  }, [membersData, invitationsData]);

  useEffect(() => {
    const close = props.onClose;
    if (!canMemberManageClient(clientRole)) {
      close();
    }
  }, [clientRole, props.onClose]);

  return (
    <TeamManagementModalComponent
      {...props}
      isLoading={isGettingMembers || isGettingInvitations}
      data={data || []}
      limits={membersData?.limits}
    />
  );
};
