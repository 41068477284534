import { css } from '@emotion/react';
import lottie, { type AnimationItem } from 'lottie-web/build/player/lottie_light';
import {
  type FC, forwardRef, useCallback, useEffect, useMemo,
  useRef, useState,
} from 'react';
import { createUuid } from '~/_shared/utils/createUuid';
import { areShallowEqual } from '~/_shared/utils/equality/shallowEqual.helper';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { removeUndefined } from '~/_shared/utils/object/removeUndefined';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { noop } from '../../utils/function.helpers';
import { LottieAnimations } from './animations/lottieAnimations';
import {
  loadAnimationJson, normalizeSegmentModel,
} from './lottieAnimation.helpers';
import {
  type LottieAnimationConfig, type LottieAnimationSegment,
  LottieAnimationTypes, type LottieAnimationViewDetails, type LottieJsonConfig,
} from './lottieAnimation.types';

const testingModeReplacement = (config: LottieAnimationProps): LottieAnimationProps => {
  switch (config.type) {
    case LottieAnimationTypes.LocationPin3D: // slow performance for UI tests
      return {
        ...config,
        type: LottieAnimationTypes.DrippingSpinner,
        colors: {
          fill: config?.colors?.pin,
        },
        segment: undefined,
      };
    default:
      return config;
  }
};

const rootStyle = (size: number) => css({
  boxSizing: 'border-box',
  width: size,
  height: size,
  svg: {
    display: 'block',
  },
});

const iconWrapperStyle = ({ canvasWidth, canvasHeight, iconWidth, iconHeight, offsetTop, offsetLeft }: LottieAnimationViewDetails) => {
  const offsetTopIndicator = offsetTop !== undefined ? (offsetTop / (canvasHeight - iconHeight)) : 0.5;
  const offsetTopValue = (canvasHeight / iconHeight - 1) * offsetTopIndicator;
  const offsetLeftIndicator = offsetLeft !== undefined ? offsetLeft / (canvasWidth - iconWidth) : 0.5;
  const offsetLeftValue = (canvasWidth / iconWidth - 1) * offsetLeftIndicator;

  const offsetTopPercentage = offsetTopValue * 100;
  const offsetLeftPercentage = offsetLeftValue * 100;

  return css({
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    svg: {
      position: 'absolute',
      height: `${canvasHeight / iconHeight * 100}% !important`,
      top: `${-offsetTopPercentage}%`,
      width: `${canvasWidth / iconWidth * 100}% !important`,
      left: `${-offsetLeftPercentage}%`,
    },
  });
};

type LottieAnimationCommonProps = Readonly<{
  size: number;
  className?: string;
  autoplay?: boolean;
  loop?: boolean;
  bothDirections?: boolean;
  progress?: number;
  speed?: number;
  segment?: LottieAnimationSegment | ReadonlyArray<LottieAnimationSegment>;
  view?: LottieAnimationViewDetails;
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
}>;

export type LottieAnimationProps = LottieAnimationCommonProps & LottieAnimationConfig;

const LottieDomContainerComponent = forwardRef<HTMLDivElement, { className?: string }>(({ className }, ref) => {
  return (
    <div
      className={className}
      ref={ref}
    />
  );
});

export const LottieAnimationComponent: FC<LottieAnimationProps> = (props) => {
  const disableAnimations = testingModeEnabled();
  const disabledProps: LottieAnimationProps = {
    ...testingModeReplacement(props),
    autoplay: false,
    loop: false,
    progress: 100,
  };

  return <LottieAnimation {...(disableAnimations ? disabledProps : props)} />;
};

const LottieAnimation: FC<LottieAnimationProps> = (props) => {
  const { type, progress, loop, bothDirections, autoplay, speed = 1, onAnimationStart } = props;

  const lottieDomContainer = useRef(null);
  const animationRef = useRef<AnimationItem | null>(null);
  const prevFrameRef = useRef<number>(0);
  const initialProgressRef = useRef(true);
  const segments = useMemo(() => normalizeSegmentModel(props.segment ?? LottieAnimations[type].segments.default), [props.segment, type]);
  const activeSegmentsRef = useRef(segments);
  const [colors, setColors] = useState({ ...LottieAnimations[type].colors, ...removeUndefined(props.colors ?? {}) });
  const previousColors = usePrevious(props.colors);
  const [gradients, setGradients] = useState({ ...LottieAnimations[type].gradients, ...removeUndefined(props.gradients ?? {}) });
  const previousGradients = usePrevious(props.gradients);
  const previousType = usePrevious(type);
  const typeChanged = type !== previousType;

  const { views: { default: defaultView } } = LottieAnimations[type];
  const view = props.view ?? defaultView;

  // Use ref to prevent loop, autoplay and progress triggering full animation reload
  const loadAnimationParamsRef = useRef({ type, loop, bothDirections, autoplay, progress, speed, segments });
  loadAnimationParamsRef.current = { type, loop, bothDirections, autoplay, progress, speed, segments };

  const setProgress = useCallback(({ autoplay, progress, initial }:
  {autoplay?: boolean; progress?: number; initial: boolean}) => {
    if (progress === undefined || animationRef.current === null) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastSegment = activeSegmentsRef.current[activeSegmentsRef.current.length - 1]!;
    const segmentFrames = Math.abs(lastSegment[0] - lastSegment[1]);
    const endFrame = lastSegment[0] + Math.round(segmentFrames / 100 * (progress ?? 100));

    if (!initial && (prevFrameRef.current !== endFrame)) {
      // discard previous segment on first progress animation
      // otherwise it would finish / complete previous segment first
      // after animation load, previous segment is the entire animation
      const discardPreviousSegment = initialProgressRef.current;

      animationRef.current.playSegments([prevFrameRef.current, endFrame], discardPreviousSegment);

      prevFrameRef.current = endFrame;
      initialProgressRef.current = false;
    }
    else if (initial) {
      animationRef.current.resetSegments(false);

      if (autoplay && progress !== 0) {
        const segmentsBeforeLast = activeSegmentsRef.current.slice(0, -1);
        animationRef.current.playSegments([...segmentsBeforeLast, [0, endFrame]], true);
      }
      else {
        animationRef.current.goToAndStop(endFrame, true);
      }

      prevFrameRef.current = endFrame;
      initialProgressRef.current = true;
    }
  }, []);

  const onAnimationEnd = useRef(noop);
  onAnimationEnd.current = props.onAnimationEnd ?? noop;

  const reverse = useCallback(() => {
    if (!animationRef.current) {
      return;
    }

    if (!loadAnimationParamsRef?.current.autoplay || !loadAnimationParamsRef?.current.bothDirections) {
      onAnimationEnd.current(); // unidirection animation ended
      return;
    }

    const isOnStart = animationRef.current.playDirection === -1;

    if (!loadAnimationParamsRef?.current.loop && isOnStart) {
      onAnimationEnd.current();// bidirection animation ended
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastSegment = activeSegmentsRef.current[activeSegmentsRef.current.length - 1]!;

    if (isOnStart) {
      animationRef.current.playSegments([lastSegment[0] + 1, lastSegment[1]]);
    }
    else {
      animationRef.current.playSegments([lastSegment[1] - 1, lastSegment[0]]);
    }
  }, []);

  // Colors are not memoized, only reload animation when color values differ
  useEffect(() => {
    if (typeChanged || (previousColors && !areShallowEqual(previousColors, props.colors))) {
      setColors({ ...LottieAnimations[type].colors, ...removeUndefined(props.colors ?? {}) });
    }
  }, [previousColors, props.colors, type, typeChanged]);

  // Gradients are not memoized, only reload animation when color values differ
  useEffect(() => {
    if (typeChanged || (previousGradients && !areShallowEqual(previousGradients, props.gradients, { levels: 3 }))) {
      setGradients({ ...LottieAnimations[type].gradients, ...removeUndefined(props.gradients ?? {}) });
    }
  }, [previousGradients, props.gradients, type, typeChanged]);

  // Reload entire animation on colors / type change.
  const animationLoadParams = useMemo(() => ({
    loadKey: createUuid(),
    loadType: type,
    onAnimationStart,
    colors, gradients, view, reverse, setProgress,
  }), [colors, gradients, onAnimationStart, reverse, setProgress, type, view]);

  useEffect(() => {
    const { colors, view, reverse, setProgress, loadType, gradients } = animationLoadParams;
    const container = lottieDomContainer.current;
    const { getJson } = LottieAnimations[loadType];

    if (container) {
      const config: LottieJsonConfig = { type: loadType, getJson, view, colors, gradients };
      const autoplay = loadAnimationParamsRef.current.autoplay ?? false;
      const loop = autoplay && (loadAnimationParamsRef.current.loop ?? false);
      const bothDirections = loadAnimationParamsRef.current.bothDirections ?? false;
      const segments = loadAnimationParamsRef.current.segments ?? LottieAnimations[loadType].segments.default;

      loadAnimationJson(config)
        .then(animationData => {
          if (container !== lottieDomContainer.current) {
            return;
          }

          animationRef.current = lottie.loadAnimation({
            container,
            animationData,
            loop: loop && !bothDirections,
          });

          activeSegmentsRef.current = segments;

          if (autoplay && loadAnimationParamsRef.current.progress === undefined) {
            animationRef.current.playSegments(segments, true);
          }

          setProgress({ ...loadAnimationParamsRef.current, initial: true });
          animationRef.current?.setSpeed(loadAnimationParamsRef.current.speed);

          animationRef.current.addEventListener('complete', reverse);
          if (animationLoadParams.onAnimationStart) {
            animationRef.current.addEventListener('enterFrame', animationLoadParams.onAnimationStart);
          }
        });
    }

    return () => {
      // https://maptive.atlassian.net/browse/MD-4729
      // removed lottie elements without destroy function to prevent an error
      const elements: any[] | undefined = animationRef.current?.renderer.elements;
      elements?.forEach((value, index) => {
        if (value && !value.destroy) {
          elements[index] = undefined;
        }
      });

      animationRef.current?.destroy();
      animationRef.current = null;
    };
  }, [animationLoadParams]); // make sure animationLoadParams are single and only dependency

  useEffect(() => {
    if (!animationRef.current) {
      return;
    }

    activeSegmentsRef.current = segments;

    if (autoplay && loadAnimationParamsRef.current.progress === undefined) {
      animationRef.current.playSegments(segments, true);
    }
    else {
      setProgress({
        autoplay,
        progress: loadAnimationParamsRef.current.progress,
        initial: true,
      });
    }
  }, [autoplay, segments, setProgress]);

  useEffect(() => {
    if (!animationRef.current) {
      return;
    }

    animationRef.current.loop = !!loop && !bothDirections;

    const autoplay = loadAnimationParamsRef.current.autoplay;
    if (loop && autoplay) {
      animationRef.current.play();
    }
  }, [bothDirections, loop]);

  useEffect(() => {
    setProgress({
      autoplay, progress, initial: false,
    });
  }, [autoplay, progress, setProgress]);

  useEffect(() => {
    if (!animationRef.current) {
      return;
    }

    animationRef.current.setSpeed(speed);
  }, [speed]);

  return (
    <div
      className={props.className}
      css={rootStyle(props.size)}
    >
      <LottieDomContainerComponent
        // do not reuse container when animation is reloaded
        // lottie destroy crashes when container is re-used
        key={animationLoadParams.loadKey}
        css={iconWrapperStyle(view)}
        ref={lottieDomContainer}
      />
    </div>
  );
};
