import { type TranslationFnc } from '../../../../../../_shared/utils/hooks';

export enum TextFilterPredicate {
  Contains = 'Contains',
  NotContains = 'NotContains',
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  StartsWith = 'StartsWith',
  NotStartsWith = 'NotStartsWith',
  EndsWith = 'EndsWith',
  NotEndsWith = 'NotEndsWith',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  IsIn = 'IsIn',
  IsNotIn = 'IsNotIn',
}

export const renderTextFilterPredicate = (predicate: TextFilterPredicate, t: TranslationFnc): string => {
  switch (predicate) {
    case TextFilterPredicate.Contains:
      return t('Contains');
    case TextFilterPredicate.EndsWith:
      return t('Ends With');
    case TextFilterPredicate.Equals:
      return t('Equals');
    case TextFilterPredicate.IsEmpty:
      return t('Is Empty');
    case TextFilterPredicate.IsNotEmpty:
      return t('Is Not Empty');
    case TextFilterPredicate.NotContains:
      return t('Does Not Contain');
    case TextFilterPredicate.NotEndsWith:
      return t('Does Not End With');
    case TextFilterPredicate.NotEquals:
      return t('Does Not Equal');
    case TextFilterPredicate.NotStartsWith:
      return t('Does Not Start With');
    case TextFilterPredicate.StartsWith:
      return t('Starts With');
    case TextFilterPredicate.IsIn:
      return t('Is In');
    case TextFilterPredicate.IsNotIn:
      return t('Is Not In');
    default:
      throw new Error(`Unknown text filter predicate '${predicate}'`);
  }
};
