import {
  type Key, type ReactNode,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox/checkbox.component';
import { checkboxRowStyle } from '~/_shared/baseComponents/checkbox/checkboxGroup.styles';

type SupportedValueTypes = Key;

export type CheckboxGroupItem<TValue extends SupportedValueTypes> = Readonly<{
  id?: string;
  text?: ReactNode;
  isDisabled?: boolean;
  value: TValue;
  name?: string;
  extraContent?: ReactNode;
}>;

export type CheckboxGroupComponentProps<TValue extends SupportedValueTypes> = Readonly<{
  onSelectionChanged: (newSelection: TValue[]) => void;
  selectedValues: readonly TValue[];
  items: CheckboxGroupItem<TValue>[];
}>;

export const CheckboxGroupComponent = <TValue extends SupportedValueTypes>(props: CheckboxGroupComponentProps<TValue>) => {
  const { onSelectionChanged, selectedValues, items } = props;

  const onCheckboxClick = (value: TValue) => {
    if (selectedValues.includes(value)) {
      onSelectionChanged(selectedValues.filter(v => v !== value));
    }
    else {
      onSelectionChanged([...selectedValues, value]);
    }
  };

  return (
    <>
      {items.map(item => (
        <div
          key={item.value}
          css={checkboxRowStyle}
        >
          <CheckboxComponent
            isChecked={selectedValues.includes(item.value)}
            checkedSetter={() => onCheckboxClick(item.value)}
            isDisabled={item.isDisabled}
            name={item.name}
            text={item.text}
            id={item.id}
          />

          {item.extraContent}
        </div>
      ))}
    </>
  );
};
