export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  originalObject: T,
  keysToOmit: ReadonlyArray<K>,
): Omit<T, K> => {
  const clonedObject = { ...originalObject };

  for (const path of keysToOmit) {
    delete clonedObject[path];
  }

  return clonedObject;
};
