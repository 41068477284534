import {
  type FC, useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserTwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { useTranslation } from '~/_shared/utils/hooks';
import type { ModalProps } from '~/modal/modalType.enum';
import { TwoFactorModalComponent } from './twoFactorModal.component';
import {
  useNoTwoFactorData,
  useTwoFactorEmailData, useTwoFactorMethod,
  useTwoFactorViaGoogleData,
} from './useTwoFactorSettingData';

export type TwoFactorModalContainerProps = ModalProps<void>;

export const TwoFactorModalContainer: FC<TwoFactorModalContainerProps> = (props) => {
  const [t] = useTranslation();
  const { selectedTwoFactorMode, setSelectedTwoFactorMode, dispatchTwoFactorType, isTwoFactorOn, setIsTwoFactorOn } = useTwoFactorMethod();
  const { isLoading: twoFactorEmailLoading, error: twoFactorEmailError, updateTwoFactorEmailData } = useTwoFactorEmailData();
  const { isLoading: twoFactorGoogleLoading, error: twoFactorGoogleError, qrCode, getTwoFactorQRCodeRequest, updateTwoFactorGoogleData } = useTwoFactorViaGoogleData();
  const { isLoading: noTwoFactorLoading, error: noTwoFactorError, updateNoTwoFactorData } = useNoTwoFactorData();
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState(false);

  const isLoading = useMemo(() => twoFactorEmailLoading || twoFactorGoogleLoading || noTwoFactorLoading, [twoFactorEmailLoading, twoFactorGoogleLoading, noTwoFactorLoading]);

  const errorMessage = useMemo(() => {
    switch (selectedTwoFactorMode) {
      case UserTwoFactorMode.TwoFactorViaEmail:
        return twoFactorEmailError ? t('Activating 2 Factor Authentication via Email has failed') : '';
      case UserTwoFactorMode.TwoFactorViaGoogle:
        return twoFactorGoogleError ? t('Activating 2 Factor Authentication via Google Authenticator has failed') : '';
      case UserTwoFactorMode.NoTwoFactor:
        return noTwoFactorError ? t('Deactivating 2 Factor Authentication has failed') : '';
      default:
        return '';
    }
  }, [twoFactorEmailError, twoFactorGoogleError, noTwoFactorError, selectedTwoFactorMode, t]);

  const handleSelectedTwoFactorMode = useCallback(
    (newMode: UserTwoFactorMode) => {
      setSelectedTwoFactorMode(newMode);
      setSuccessMessage('');
      setAlertOpen(false);
      if (newMode === UserTwoFactorMode.TwoFactorViaEmail) {
        updateTwoFactorEmailData({ status: 1 }, () => {
          setSuccessMessage(t('2 Factor Authorization via Email was successfully activated'));
          setAlertOpen(true);
          dispatchTwoFactorType(UserTwoFactorMode.TwoFactorViaEmail);
        });
      }
      else if (newMode === UserTwoFactorMode.TwoFactorViaGoogle) {
        getTwoFactorQRCodeRequest();
      }
      else {
        updateNoTwoFactorData(() => {
          setSuccessMessage(t('2 Factor Authentication was successfully disabled'));
          setAlertOpen(true);
          dispatchTwoFactorType(UserTwoFactorMode.NoTwoFactor);
        });
      }
    },
    [setSelectedTwoFactorMode, updateTwoFactorEmailData, getTwoFactorQRCodeRequest, updateNoTwoFactorData, dispatchTwoFactorType, t],
  );

  const handleUpdateGoogleCallBack = useCallback(
    (code: string) => {
      setSuccessMessage('');
      setAlertOpen(false);
      updateTwoFactorGoogleData({ status: 1, code }, () => {
        setSuccessMessage(t('2 Factor Authorization via Google Authenticator was successfully activated'));
        setAlertOpen(true);
        dispatchTwoFactorType(UserTwoFactorMode.TwoFactorViaGoogle);
      });
    },
    [updateTwoFactorGoogleData, setSuccessMessage, setAlertOpen, dispatchTwoFactorType, t],
  );

  const toggleTwoFactorCallBack = useCallback((toggle: boolean) => {
    setIsTwoFactorOn(toggle);
    if (!toggle) {
      handleSelectedTwoFactorMode(UserTwoFactorMode.NoTwoFactor);
    }
  }, [setIsTwoFactorOn, handleSelectedTwoFactorMode]);

  useEffect(() => {
    if (isLoading || (!successMessage && !errorMessage)) {
      setAlertOpen(false);
    }
    else {
      setAlertOpen(true);
    }
  }, [errorMessage, successMessage, isLoading]);

  return (
    <TwoFactorModalComponent
      {...props}
      selectedTwoFactorMode={selectedTwoFactorMode}
      setSelectedTwoFactorMode={handleSelectedTwoFactorMode}
      isLoading={isLoading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      isAlertOpen={alertOpen}
      closeAlert={() => setAlertOpen(false)}
      qrCode={qrCode}
      onTwoFactorGoogleSubmit={handleUpdateGoogleCallBack}
      toggleTwoFactorCallBack={toggleTwoFactorCallBack}
      isTwoFactorOn={isTwoFactorOn}
    />
  );
};
