import type { TranslationFnc } from '../hooks';

export const secondsToHms = (seconds: number | string, t: TranslationFnc, noSecondsIfMoreThanAMinute?: boolean) => {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);

  const hDisplay = h > 0 ? [t('{{hours}} hrs', { hours: h })] : [];
  const mDisplay = m > 0 ? [t('{{minutes}} mins', { minutes: m })] : [];
  const sDisplay = s > 0 && ((h === 0 && m === 0) || !noSecondsIfMoreThanAMinute)
    ? [t('{{count}} sec', { count: s })] : [];

  return [...hDisplay, ...mDisplay, ...sDisplay].join(' ');
};
