import { type PerSpreadsheet } from '~/_shared/types/spreadsheet/spreadsheet.types';

export type GeocodingState = {
  perSpreadsheetGeocodingRequest: PerSpreadsheetRequest;
};

type GeocodingPaused = {
  isPaused: boolean;
  changeRequestSent: boolean;
};

export type GeocodingRequest = {
  progress: number;
  inProgress: boolean;
  isRequired: boolean;
  paused: GeocodingPaused;
  triggeredByUserId: number | null;
};

type PerSpreadsheetRequest = {[realSpreadsheetId: number]: GeocodingRequest | undefined};

export type PerSpreadsheetGeocodingUpdates = PerSpreadsheet<{
  realSpreadsheetId: number;
  progress: number;
  triggeredByUserId: number;
  isPaused?: boolean;
}>;

const defaultPaused = {
  isPaused: false,
  changeRequestSent: false,
};

const emptyRequest = {
  progress: 0,
  inProgress: false,
  isRequired: false,
  paused: defaultPaused,
  triggeredByUserId: null,
};

function calculateUpdatedRequest(oldValue: GeocodingRequest, update: Partial<GeocodingRequest>): GeocodingRequest {
  return { ...oldValue, ...update };
}

function updateRequest(state: GeocodingState, realSpreadsheetId: number, update: (request: GeocodingRequest) => GeocodingRequest): GeocodingState {
  const prevState = state.perSpreadsheetGeocodingRequest[realSpreadsheetId] ?? emptyRequest;

  const updatedProgresses = { ...state.perSpreadsheetGeocodingRequest, [realSpreadsheetId]: update(prevState) };

  return { perSpreadsheetGeocodingRequest: updatedProgresses };
}

export function initializeSpreadsheetWithPresetValues(state: GeocodingState, realSpreadsheetId: number, progress: GeocodingRequest): GeocodingState {
  const updatedProgresses = { ...state.perSpreadsheetGeocodingRequest, [realSpreadsheetId]: progress };

  return { perSpreadsheetGeocodingRequest: updatedProgresses };
}

export function updateSpreadsheetProgress(state: GeocodingState, updates: PerSpreadsheetGeocodingUpdates): GeocodingState {
  return Object.values(updates).reduce((accum, update) => {
    if (!update) {
      return accum;
    }
    else if (update.progress >= 100) {
      return completeRequest(accum, update.realSpreadsheetId);
    }
    else {
      return updateRequest(accum, update.realSpreadsheetId, (request) => calculateUpdatedRequest(request, {
        progress: update.progress,
        inProgress: true,
        triggeredByUserId: update.triggeredByUserId,
        paused: {
          isPaused: update.isPaused || false,
          changeRequestSent: accum.perSpreadsheetGeocodingRequest[update.realSpreadsheetId]?.paused.changeRequestSent || false,
        },
      }));
    }
  }, state);
}

export function pauseRequest(state: GeocodingState, realSpreadsheetId: number) {
  const updatedProgress = updateRequest(state, realSpreadsheetId, (request) => calculateUpdatedRequest(request, { paused: { isPaused: true, changeRequestSent: false } }));

  return updatedProgress;
}

export function completeRequest(state: GeocodingState, realSpreadsheetId: number) {
  const updatedProgresses = updateRequest(state, realSpreadsheetId, (request) =>
    ({ ...request, inProgress: false, progress: 100, paused: defaultPaused, isRequired: false }));

  return updatedProgresses;
}

export function resumeRequest(state: GeocodingState, realSpreadsheetId: number) {
  const updatedProgress = updateRequest(state, realSpreadsheetId, (request) => calculateUpdatedRequest(request, { paused: { isPaused: false, changeRequestSent: false } }));

  return updatedProgress;
}

export function setRequestLoading(state: GeocodingState, realSpreadsheetId: number) {
  const updatedProgress = updateRequest(state, realSpreadsheetId, (request) => calculateUpdatedRequest(request, { paused: { isPaused: request.paused.isPaused, changeRequestSent: true } }));

  return updatedProgress;
}

export function unsetRequestLoading(state: GeocodingState, realSpreadsheetId: number) {
  const updatedProgress = updateRequest(state, realSpreadsheetId, (request) => calculateUpdatedRequest(request, { paused: { isPaused: request.paused.isPaused, changeRequestSent: false } }));

  return updatedProgress;
}
