import type {
  Interpolation, SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type Ref,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { OverlayLottieAnimationComponent } from '../../components/overlay/overlayLottieAnimation.component';
import { OverlayWrapperComponent } from '../../components/overlay/overlayWrapper.component';
import {
  SelectedItemComponent, Size,
} from '../../components/selectedItem/selectedItem.component';
import { usePrevious } from '../../utils/hooks/usePrevious';
import {
  DropdownComponent, dropdownItemsContainerStyle, DropDownItemSize, DropdownPlacement, type DropdownProps,
} from '../dropdown';
import { InputSize } from '../inputs';
import { standardLoaderAnimationSettings } from '../loaders';
import {
  type LottieAnimationProps, useLottieAnimationDefaultColors,
} from '../lottieAnimation';
import {
  autocompleteNoResultsStyle, dropdownContainerStyle, selectedItemStyle, selectedItemTextStyle,
} from './autocomplete.styles';
import {
  type AutocompleteOption, type AutocompleteRegularOption,
} from './autocomplete.types';
import { AutocompleteItem } from './components';
import {
  AutocompleteTrigger, type AutocompleteTriggerProps,
} from './private/components/autocompleteTrigger';
import {
  compareOptionsValues, isCustomOption,
} from './utils';

type BaseAutocompleteComponentProps<T> = Readonly<{
  checkSelected?: boolean;
  className?: string;
  hideChevron?: boolean;
  icon?: IconProp | null;
  iconStyle?: SerializedStyles;
  inputStyle?: Interpolation;
  inputSize?: InputSize;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  customValueComparer?: (option: T, value: T) => boolean;
  itemSize?: DropDownItemSize;
  maxSelectedVisible?: number;
  onInputChange?: (value: string) => void;
  onOpenChange?: (isVisible: boolean) => void;
  options: ReadonlyArray<AutocompleteOption<T>>;
  placeholder?: string;
  loaderConfig?: LottieAnimationProps;
  hideNoResultsMessage?: boolean;
  triggerInputRef?: Ref<HTMLInputElement>;
}> & Pick<DropdownProps,
'inPortal' |
'menuStyle'
> & Pick<AutocompleteTriggerProps, 'onBlur' | 'onKeyUp'>;

export type MultipleAutocompleteProps<T> = {
  value: T[];
  onChange: (value: T[]) => void;
  multiple: true;
} & BaseAutocompleteComponentProps<T>;

export type SingleAutocompleteProps<T> = {
  value: T | null;
  onChange: (value: T | null) => void;
  multiple?: false;
} & BaseAutocompleteComponentProps<T>;

export type AutocompleteComponentProps<T> = MultipleAutocompleteProps<T> | SingleAutocompleteProps<T>;

export const AutocompleteComponent = <T, >(props: AutocompleteComponentProps<T>) => {
  const {
    isLoading,
    placeholder,
    value,
    hideNoResultsMessage,
    inputSize = InputSize.Medium,
    itemSize = DropDownItemSize.Default,
    inputStyle,
    icon = null,
    iconStyle,
    options,
    onInputChange,
    isOpen,
    onOpenChange,
    isDisabled,
    isClearable,
    checkSelected,
    hideChevron,
    multiple = false,
    customValueComparer,
    maxSelectedVisible,
    onBlur,
    onKeyUp,
    loaderConfig,
    triggerInputRef,
    ...restDropdownProps
  } = props;
  const [t] = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [localInputValue, setLocalInputValue] = useState('');
  const triggerRef = useRef<HTMLDivElement>(null);
  const previousValue = usePrevious(value);
  const callbacksRef = useRef({ onInputChange, onOpenChange });

  const regularOptions = useMemo(() => options.filter((option): option is AutocompleteRegularOption<T> => !isCustomOption(option)), [options]);
  const areValuesEqual = useCallback((valueA: T, valueB: T) => compareOptionsValues(valueA, valueB, customValueComparer), [customValueComparer]);
  const optionForValue = useMemo(() => {
    if (value === null || Array.isArray(value)) {
      return null;
    }
    return regularOptions.find((option) => areValuesEqual(value, option.value)) ?? null;
  }, [value, regularOptions, areValuesEqual]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsDropdownOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    callbacksRef.current.onOpenChange = onOpenChange;
  }, [onOpenChange]);
  useEffect(() => {
    callbacksRef.current.onInputChange = onInputChange;
  }, [onInputChange]);

  useEffect(() => {
    callbacksRef.current?.onOpenChange?.(isDropdownOpen);
  }, [isDropdownOpen]);

  useEffect(() => {
    callbacksRef.current?.onInputChange?.(localInputValue);
  }, [localInputValue]);

  useEffect(() => {
    if (isDisabled && multiple) {
      setLocalInputValue('');
    }
  }, [isDisabled, multiple]);

  useEffect(() => {
    if (value === previousValue || multiple || Array.isArray(value)) {
      return;
    }

    if (value === null) {
      setLocalInputValue('');
      return;
    }

    if (optionForValue) {
      setLocalInputValue(optionForValue.name);
    }
  }, [value, multiple, previousValue, optionForValue]);

  const isOptionSelected = (option: AutocompleteRegularOption<T>): boolean => {
    if (value === null) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.some(selectedValue => areValuesEqual(option.value, selectedValue));
    }

    return areValuesEqual(option.value, value);
  };

  const toggleDropdown = () => setIsDropdownOpen(isOpen => {
    if (isDisabled) {
      return isOpen;
    }
    onOpenChange?.(!isOpen);
    return !isOpen;
  });

  const getOptionMatchesValue = useCallback((option: AutocompleteRegularOption<T>, includePartial?: boolean) => {
    const normalizedInputValue = localInputValue.toLowerCase();
    if (includePartial) {
      return option.name.toLowerCase().includes(normalizedInputValue) ||
        option.aliases?.some(value => value.toLowerCase().includes(normalizedInputValue));
    }
    return option.name.toLowerCase() === normalizedInputValue
      || option.aliases?.some(alias => alias.toLowerCase() === normalizedInputValue);
  }, [localInputValue]);

  const filteredOptions = useMemo(() => {
    const isSelectedValueSameAsInput = !multiple && optionForValue !== null && getOptionMatchesValue(optionForValue);
    if (isSelectedValueSameAsInput) {
      return options;
    }

    return options.filter((option) => {
      if (isCustomOption(option)) {
        return true;
      }

      return getOptionMatchesValue(option, true);
    });
  }, [multiple, optionForValue, getOptionMatchesValue, options]);

  const handleMenuClose = () => {
    setIsDropdownOpen(false);

    if (localInputValue && filteredOptions.length === 1) {
      const remnantOption = filteredOptions[0];
      const isInputMatch = remnantOption && !isCustomOption(remnantOption) && getOptionMatchesValue(remnantOption);
      if (isInputMatch) {
        if (!props.multiple) {
          props.onChange(remnantOption.value);
          setLocalInputValue(remnantOption.name);
        }
        else {
          props.onChange([...props.value, remnantOption.value]);
          setLocalInputValue('');
        }
      }

      return;
    }

    if (!props.multiple && optionForValue) {
      setLocalInputValue(optionForValue.name);
    }
  };

  const handleItemClick = (option: AutocompleteRegularOption<T>) => {
    if (!props.multiple) {
      props.onChange(option.value);
      setLocalInputValue(option.name);
      setIsDropdownOpen(false);
      return;
    }

    let newValue: T[] = [];

    if (isOptionSelected(option)) {
      newValue = props.value.filter(selectedValue => !areValuesEqual(option.value, selectedValue));
    }
    else {
      newValue = [...props.value, option.value];
    }

    props.onChange(newValue);
    setLocalInputValue('');
  };

  const handleOnClear = () => {
    setLocalInputValue('');

    if (!props.multiple) {
      props.onChange(null);
    }
    else {
      props.onChange([]);
    }
  };

  const onValueRemove = (valueToRemove: T) => {
    if (!props.multiple) {
      return;
    }
    const filtered = props.value.filter(selectedValue => !areValuesEqual(valueToRemove, selectedValue));
    props.onChange(filtered);
    return;
  };

  const renderTags = () => {
    if (props.multiple) {
      const optionsForValue = regularOptions.filter(option => props.value.some((selectedValue) => areValuesEqual(option.value, selectedValue)));
      const valueVisiblePortion = optionsForValue.slice(0, maxSelectedVisible ?? optionsForValue.length);
      return (
        <>
          {valueVisiblePortion.map((option, index) => (
            <SelectedItemComponent
              css={selectedItemStyle}
              key={index}
              size={Size.Small}
              onRemove={isDisabled ? undefined : (e) => {
                e.stopPropagation();
                onValueRemove(option.value);
              }}
              isDisabled={isLoading}
            >
              <span css={selectedItemTextStyle}>{option.name}</span>
            </SelectedItemComponent>
          ))}
          {props.value.length > valueVisiblePortion.length && (
            <SelectedItemComponent
              css={selectedItemStyle}
              size={Size.Small}
            >
              +{props.value.length - valueVisiblePortion.length}
            </SelectedItemComponent>
          )}
        </>
      );
    }
    return undefined;
  };

  const defaultLottieColors = useLottieAnimationDefaultColors();

  const defaultLoaderConfig: LottieAnimationProps = useMemo(() => loaderConfig ?? ({
    ...standardLoaderAnimationSettings,
    size: 45,
    colors: {
      fill: defaultLottieColors.DrippingSpinner?.fill,
    },
  }), [loaderConfig, defaultLottieColors]);

  const hasValue = !props.multiple ? !!localInputValue : props.value.length > 0;

  return (
    <DropdownComponent
      {...restDropdownProps}
      isOpen={isDropdownOpen && !isDisabled}
      onClose={handleMenuClose}
      css={dropdownContainerStyle}
      closeOnInsideClick={false}
      dropdownPlacement={DropdownPlacement.BottomStart}
      triggerComponent={(
        <AutocompleteTrigger
          ref={triggerRef}
          inputSize={inputSize}
          inputStyle={inputStyle}
          inputRef={triggerInputRef}
          isOpen={isDropdownOpen}
          value={localInputValue}
          placeholder={placeholder}
          onChange={isDisabled ? undefined : setLocalInputValue}
          onClick={() => {
            setIsDropdownOpen(true);
          }}
          onChevronClick={hideChevron ? undefined : toggleDropdown}
          isDisabled={isDisabled}
          hasValue={hasValue}
          onClear={isClearable && !isDisabled ? handleOnClear : undefined}
          icon={icon}
          iconStyle={iconStyle}
          renderTags={renderTags}
          onBlur={isDisabled ? undefined : onBlur}
          onKeyUp={isDisabled ? undefined : onKeyUp}
        />
      )}
    >
      {isLoading && (
        <OverlayWrapperComponent minHeight={100}>
          <OverlayLottieAnimationComponent
            {...defaultLoaderConfig}
            autoplay
            loop
          />
        </OverlayWrapperComponent>
      )}
      {!isLoading && filteredOptions.map((option, index) => {
        return (
          <div
            key={index}
            css={dropdownItemsContainerStyle}
          >
            {isCustomOption(option) ? (
              <>{option.render(inputSize, itemSize)} </>
            ) : (
              <AutocompleteItem
                name={option.name}
                onClick={isDisabled ? undefined : () => handleItemClick(option)}
                isDisabled={isDisabled}
                isSelected={checkSelected && isOptionSelected(option)}
                size={inputSize}
                subSize={itemSize}
                prefix={option.prefix}
              />
            )}
          </div>
        );
      })}
      {!hideNoResultsMessage && !isLoading && filteredOptions.length === 0 && (
        <div css={dropdownItemsContainerStyle}>
          <div css={autocompleteNoResultsStyle({ inputSize, itemSize })}>
            {t('No results')}
          </div>
        </div>
      )}
    </DropdownComponent>
  );
};
