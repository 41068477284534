import { css } from '@emotion/react';
import { type IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  type FC, useMemo,
} from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { authorizationPagesLinkStyle } from '../../authorization/authorizationPages.styles';
import { AuthorizationPageWrapperComponent } from '../../authorization/pageWrapper/authorizationPageWrapper.component';
import { InvitationResultComponent } from './invitationResult.component';
import { type InviteeInvitation } from './inviteeInvitation.repository';
import { NewUserInvitation } from './newUserInvitation.component';
import { TrialUserInvitation } from './trialUserInvitation.component';
import { UnderPaidClientInvitation } from './underPaidClientInvitation.component';

const linkStyles = css(authorizationPagesLinkStyle, {
  width: '100%',
});

export type LinkInfo = {
  onClick: () => void;
  text: string;
};

export enum UIType {
  Loading = 'Loading',
  InvitationResult = 'InvitationResult',
  NewUserInvitation = 'NewUserInvitation',
  TrialUserInvitation = 'TrialUserInvitation',
  UnderPaidClientInvitation = 'UnderPaidClientInvitation',
}

export enum InvitationResultStatus {
  Danger,
  Normal,
  Success,
}

export type InvitationResult = {
  icon: IconDefinition;
  linkInfo: LinkInfo | null;
  status: InvitationResultStatus;
  text: string;
};

export type AcceptInvitationPageComponentProps = Readonly<{
  acceptInvitationError: string;
  invitation: InviteeInvitation | null;
  invitationResult?: InvitationResult;
  isAcceptingInvitation: boolean;
  isRejectingInvitation: boolean;
  uiType: UIType;

  acceptInvitation: (password?: string, passwordString?: string) => Promise<void>;
  rejectInvitation: () => void;
}>;

export const InvitationPageComponent: FC<AcceptInvitationPageComponentProps> = (props) => {
  const {
    uiType, invitationResult, invitation,
    acceptInvitationError, acceptInvitation, rejectInvitation,
    isAcceptingInvitation, isRejectingInvitation,
  } = props;

  const theme = useTheme();

  const invitationResultLink = useMemo(() => {
    if (!invitationResult || !invitationResult.linkInfo) {
      return false;
    }
    return (
      <LinkComponent
        css={linkStyles}
        path={null}
        onClick={invitationResult.linkInfo.onClick}
      >
        {invitationResult.linkInfo.text}
      </LinkComponent>
    );
  }, [invitationResult]);

  const isPageLoading = isRejectingInvitation || isAcceptingInvitation;

  return (
    <AuthorizationPageWrapperComponent>
      {uiType === UIType.InvitationResult && invitationResult && (
        <InvitationResultComponent
          color={
            invitationResult.status === InvitationResultStatus.Danger ? theme.textColors.danger
              : invitationResult.status === InvitationResultStatus.Success ? theme.textColors.success
                : theme.textColors.contrast
          }
          icon={invitationResult.icon}
          text={invitationResult.text}
          bottom={invitationResultLink || undefined}
        />
      )}

      {uiType === UIType.UnderPaidClientInvitation && invitation && (
        <UnderPaidClientInvitation
          client={invitation.client.name}
          rejectInvitation={rejectInvitation}
        />
      )}

      {uiType === UIType.TrialUserInvitation && invitation && (
        <TrialUserInvitation
          client={invitation.client.name}
          acceptInvitation={acceptInvitation}
          rejectInvitation={rejectInvitation}
        />
      )}

      {uiType === UIType.NewUserInvitation && invitation && (
        <NewUserInvitation
          client={invitation.client.name}
          invitee={`${invitation.invitee.firstName} ${invitation.invitee.lastName}`}
          acceptInvitation={acceptInvitation}
          isLoading={isAcceptingInvitation || isRejectingInvitation}
          acceptInvitationError={acceptInvitationError}
          rejectInvitation={rejectInvitation}
        />
      )}

      {(uiType === UIType.Loading || isPageLoading) && (
        <OverlayLoaderComponent />
      )}
    </AuthorizationPageWrapperComponent>
  );
};
