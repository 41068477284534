import { css } from '@emotion/react';
import {
  faHourglassHalf,
  faHourglassStart,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import {
  SuffixText, TextInputWithSuffixComponent,
} from '~/_shared/baseComponents/inputs';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import { handleNumericInputChange } from '~/_shared/utils/form/form.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { MAX_DRIVE_TIME_POLYGON_SECONDS } from '../../proximityPanel.helpers';
import { ProximityPanelGeolocationInputComponent } from '../inputs/proximityPanelGeolocationInput.component';
import { ProximityPanelInputLabelComponent } from '../inputs/proximityPanelInputLabel.component';
import { validateDriveTimePolygonTime } from '../proximityPanelForm.helpers';

type PolygonDriveTimeFormFieldsProps = Readonly<{
  address: string;
  onChangeAddress: (address: string) => void;
  onGetUserLocation: () => void;
  isFetchingGeolocation: boolean;
  hasGeolocationError: boolean;
  travelTimeMinutes: number | null;
  onChangeTravelTimeMinutes: (minutes: number) => void;
  travelTimeHours: number | null;
  onChangeTravelTimeHours: (hours: number) => void;
}>;

const TRAVEL_BOX_MARGIN = 5;
const inlineStyle = css({
  alignItems: 'center',
  display: 'flex',
});

const travelTimeStyle = css(inlineStyle, {
  gap: TRAVEL_BOX_MARGIN * 2,
});

const travelHoursColumnStyle = css({
  width: `calc(50% - ${TRAVEL_BOX_MARGIN}px)`,
});

const travelMinutesColumnStyle = css({
  width: `calc(50% - ${TRAVEL_BOX_MARGIN}px)`,
});

const proximityInputLabelStyles = css({
  alignItems: 'center',
});

export const PolygonDriveTimeFormFieldsComponent: FC<PolygonDriveTimeFormFieldsProps> = props => {
  const [t] = useTranslation();

  return (
    <>
      <ProximityPanelGeolocationInputComponent
        value={props.address}
        onChange={props.onChangeAddress}
        onGetUserLocation={props.onGetUserLocation}
        hasError={props.hasGeolocationError}
        isLoading={props.isFetchingGeolocation}
      />

      <ProximityPanelInputLabelComponent>
        <Inline gap={8} css={proximityInputLabelStyles}>
          <span>
            {t('Proximity (Travel Time)')}
          </span>
          <HintTooltipComponent
            delayShow={500}
            tooltipContent={t('Currently this tool supports a maximum of {{limit}} hours.', { limit: MAX_DRIVE_TIME_POLYGON_SECONDS / (60 * 60) })}
            size={24}
          />
        </Inline>
      </ProximityPanelInputLabelComponent>

      <div css={travelTimeStyle}>
        <div css={travelHoursColumnStyle}>
          <TextInputWithSuffixComponent
            icon={faHourglassStart}
            isInvalid={props.travelTimeHours !== null && !validateDriveTimePolygonTime(props.travelTimeHours, props.travelTimeMinutes)}
            onChange={value => handleNumericInputChange(value, props.onChangeTravelTimeHours)}
            suffix={<SuffixText>{t('hrs')}</SuffixText>}
            value={props.travelTimeHours?.toString() || ''}
          />
        </div>

        <div css={travelMinutesColumnStyle}>
          <TextInputWithSuffixComponent
            icon={faHourglassHalf}
            isInvalid={props.travelTimeMinutes !== null && !validateDriveTimePolygonTime(props.travelTimeHours, props.travelTimeMinutes)}
            onChange={value => handleNumericInputChange(value, props.onChangeTravelTimeMinutes)}
            suffix={<SuffixText>{t('min')}</SuffixText>}
            value={props.travelTimeMinutes?.toString() || ''}
          />
        </div>
      </div>
    </>
  );
};
