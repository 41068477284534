import { css } from '@emotion/react';
import {
  type ComponentProps, type FC, useCallback, useState,
} from 'react';
import { CounterComponent } from '~/_shared/baseComponents/counter';
import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import { type TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { commafy } from '~/_shared/utils/number/number.helpers';
import { LottieAnimationComponent } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { getLottieAnimationDuration } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '../../../_shared/baseComponents/lottieAnimation/lottieAnimation.types';

type HomepageInfoStatisticsCardProps = Readonly<{
  amountLimit: number | null;
  amountUsed: number;
  title: string;
  titleTooltip?: ComponentProps<typeof TooltipDeprComponent>['tooltipContent'];
  text: string;
}>;

const HOME_PAGE_INFO_CARD_WIDTH_MAX = 368;
const MQ_BREAKPOINT_SM = 300;

const cardWrapperStyle = (theme: Theme) => css({
  containerType: 'inline-size',
  containerName: 'card',
  backgroundColor: theme.backgroundColors.secondary,
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  maxWidth: HOME_PAGE_INFO_CARD_WIDTH_MAX,
  marginLeft: 'auto',
  borderRadius: 8,
  position: 'relative',
  width: '100%',
  height: '100%',
  boxShadow: `0 0 8px ${theme.shadowColors.secondary}`,
  border: `1px solid ${theme.borderColors.primary}`,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const cardContentStyle = css({
  display: 'grid',
  gridTemplate: `"statValue statGraph" 1fr
  "statText statText" auto`,
  minWidth: 0,
  boxSizing: 'border-box',
  padding: '14px 8px',
  position: 'relative',
  width: '100%',

  [`@container card (min-width: ${MQ_BREAKPOINT_SM}px)`]: {
    gridTemplate: `"statValue statGraph" 1fr
  "statText statGraph" auto`,
  },
});

const progressCircleStyle = css({
  [`@container card (min-width: ${MQ_BREAKPOINT_SM}px)`]: {
    height: 120,
    width: 120,
  },
});

const amountValueStyle = (theme: Theme) => css({
  gridArea: 'statValue',
  alignSelf: 'end',
  fontSize: 40,
  color: theme.textColors.link,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
});

const textStyle = css({
  gridArea: 'statText',
  display: 'block',
  fontSize: 14,
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
});

const titleStyle = (theme: Theme) => css({
  padding: '6px 8px',
  textTransform: 'uppercase',
  fontSize: 14,
  color: theme.textColors.quaternary,
  background: theme.backgroundColors.quaternary,
  maxWidth: '100%',
});

const indicatorWrapperStyle = css({
  gridArea: 'statGraph',
  justifySelf: 'end',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const progressStatisticsStyle = css({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const progressTextStyle = (theme: Theme) => css({
  fontSize: 18,
  maxWidth: 40,
  textAlign: 'center',
  color: theme.percentageIndicatorColors.donutEntry,
});

const reserveSpaceForTheTooltipIconToBreakWithTheLastWordStyles = css({
  paddingRight: 20,
});

const shiftIconLeftIntoTheReservedSpaceStyles = css({
  marginLeft: -16,
});

export const HomepageInfoStatisticsCardComponent: FC<HomepageInfoStatisticsCardProps> = (props) => {
  const [animationStarted, setAnimationStarted] = useState(false);
  const [t] = useTranslation();

  const progress = props.amountLimit === null ? 0 : Math.round(props.amountUsed / props.amountLimit * 100);
  const animationDuration = Math.round(progress / 100 * getLottieAnimationDuration(LottieAnimationTypes.GradientLimitIndicator, LottieAnimations[LottieAnimationTypes.GradientLimitIndicator].segments.progress));

  const onAnimationStart = useCallback(() => {
    setAnimationStarted(true);
  }, []);

  return (
    <div
      css={cardWrapperStyle}
    >
      <div
        css={titleStyle}
      >
        <span css={props.titleTooltip ? reserveSpaceForTheTooltipIconToBreakWithTheLastWordStyles : undefined}>
          {props.title}
        </span>
        {props.titleTooltip && (
          <HintTooltipComponent
            tooltipContent={props.titleTooltip}
            css={shiftIconLeftIntoTheReservedSpaceStyles}
          />
        )}
      </div>
      <div css={cardContentStyle}>
        <div css={amountValueStyle}>
          {commafy(props.amountUsed)}
        </div>
        <div css={textStyle}>
          {props.text}
        </div>

        <div css={indicatorWrapperStyle}>
          <LottieAnimationComponent
            css={progressCircleStyle}
            type={LottieAnimationTypes.GradientLimitIndicator}
            progress={progress}
            size={100}
            segment={LottieAnimations[LottieAnimationTypes.GradientLimitIndicator].segments.progress}
            autoplay
            onAnimationStart={onAnimationStart}
          />

          <div css={progressStatisticsStyle}>
            <div css={progressTextStyle}>
              {props.amountLimit !== null ? (
                <>
                  {animationStarted ? (
                    <CounterComponent
                      startValue={0}
                      endValue={progress}
                      renderText={progress => t('{{percentageValue}}% Used', { percentageValue: progress })}
                      duration={animationDuration}
                    />
                  ) : t('{{percentageValue}}% Used', { percentageValue: 0 })}
                </>
              ) : t('No Limit')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
