import { type FC } from 'react';
import { SuccessAnimationComponent } from '../animation/success/sucessAnimation.component';
import { OverlayComponent } from './overlay.component';
import { lottieAnimationOverlayStyle } from './overlayLottieAnimation.component';

type OverlayLoaderProps = Readonly<{
  iconSize?: number;
  className?: string;
}>;

export const OverlaySuccessComponent: FC<OverlayLoaderProps> = (props) => {
  const size = props.iconSize ?? 80;

  return (
    <OverlayComponent className={props.className}>
      <SuccessAnimationComponent
        css={lottieAnimationOverlayStyle({ size })}
        size={size}
      />
    </OverlayComponent>
  );
};
