import { useCallback } from 'react';
import {
  type BoundaryId, type BoundaryTerritoryGroupId, type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { AppErrorType } from '../../../../../appError/appErrorType.enum';
import { ModalType } from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import {
  type MarkersFetchDataResults, useMarkersInBoundary,
} from './useMarkersInBoundary';

export const useBoundaryBatchEditLocations = () => {
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);
  const { openModal: openBulkEditLocationsModal } = useModal(ModalType.BulkEditLocationsData);
  const { getMarkersInBoundary, getMarkersInBoundaryTerritory } = useMarkersInBoundary();
  const [t] = useTranslation();

  const batchEditLocations = useCallback((getMarkersPromise: Promise<MarkersFetchDataResults>) => {
    getMarkersPromise
      .then(results => {
        if (results.rowsData.length === 0) {
          openAppErrorModal({
            type: AppErrorType.General,
            title: t('No Markers Selected'),
            content: t('There are no markers in the selected area.'),
          });
          return;
        }

        openBulkEditLocationsModal({
          affectedItems: results.rowsData,
          useMainFilters: results.useMainFilters,
        });
      })
      .catch(() => {
        openAppErrorModal({
          type: AppErrorType.General,
          title: t('Failed to fetch data'),
          content: t('Something went wrong, please try again.'),
        });
      });
  }, [openBulkEditLocationsModal, openAppErrorModal, t]);

  const batchEditLocationsInBoundary = useCallback(
    (boundaryTerritoryGroupId: BoundaryTerritoryGroupId, boundaryId: BoundaryId) =>
      batchEditLocations(getMarkersInBoundary(boundaryTerritoryGroupId, boundaryId)),
    [batchEditLocations, getMarkersInBoundary]);

  const batchEditLocationsInBoundaryTerritory = useCallback(
    (boundaryTerritoryGroupId: BoundaryTerritoryGroupId, boundaryTerritoryId: BoundaryTerritoryId) =>
      batchEditLocations(getMarkersInBoundaryTerritory(boundaryTerritoryGroupId, boundaryTerritoryId)),
    [batchEditLocations, getMarkersInBoundaryTerritory]);

  return {
    batchEditLocationsInBoundary,
    batchEditLocationsInBoundaryTerritory,
  };
};
