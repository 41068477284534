import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { objectsAlphabetically } from '~/_shared/utils/sort';
import {
  getMembersRequest, type Member,
} from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';

export const useGetMemberListings = (clientId: number | null) => {
  const [resultData, setResultData] = useState<Member[]>([]);

  const getClientMembers = useCallback(() => {
    if (clientId) {
      return getMembersRequest(clientId);
    }
    else {
      return Promise.resolve({
        members: [] as Member[],
        limits: {
          limit: 0,
          used: 0,
          remaining: 0,
        },
      });
    }
  }, [clientId]);

  const { isLoading, error, data, invokeAjax: getMembers } = useAjaxCall(getClientMembers);

  const processedData = useMemo(() => (data ? {
    ...data,
    members: data.members.filter(i => i && i.id),
  } : null), [data]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    if (!processedData) {
      return;
    }

    const newResultData = [...processedData.members].sort(objectsAlphabetically(member => member.name));

    setResultData(newResultData);
  }, [processedData]);

  return {
    isLoading,
    error,
    data: processedData,
    members: resultData,
  };
};
