import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { type FileAttachmentId } from '~/_shared/types/file.types';
import { type LatLng } from '~/_shared/types/latLng';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { type DrawingArrowSettingsState } from '../settings/arrow/drawingArrowSettings.state';
import { type DrawingCalloutSettingsState } from '../settings/callout/drawingCalloutSettings.state';
import { type DrawingCircleSettingsState } from '../settings/circle/drawingCircleSettings.state';
import { type DrawingIconMarkerSettingsState } from '../settings/iconMarker/drawingIconMarkerSettings.state';
import { type DrawingImageSettingsState } from '../settings/image/drawingImageSettings.state';
import { type DrawingLabelSettingsState } from '../settings/label/drawingLabelSettings.state';
import { type DrawingNumberSettingsState } from '../settings/number/drawingNumberSettings.state';
import { type DrawingPolygonSettingsState } from '../settings/polygon/drawingPolygonSettings.state';
import { type DrawingPolylineSettingsState } from '../settings/polyline/drawingPolylineSettings.state';
import { type DrawingRectangleSettingsState } from '../settings/rectangle/drawingRectangleSettings.state';
import { type DrawingTextSettingsState } from '../settings/text/drawingTextSettings.state';

export enum DrawingItemPlacement {
  Default = 'Default',
  Bottom = 'Bottom',
  Top = 'Top',
}

export type DrawingItemArrow = Readonly<{
  id: Uuid;
  staticLength: number;
  tipPoint: LatLng;
  tailPoint: LatLng;
  placement?: DrawingItemPlacement;
  settings: DrawingArrowSettingsState;
}>;

export type DrawingItemCallout = Readonly<{
  id: Uuid;
  startPoint: LatLng;
  textBoxDimensions: QuadrilateralDimensions;
  text: string;
  placement?: DrawingItemPlacement;
  settings: DrawingCalloutSettingsState;
}>;

export type DrawingItemCircle = Readonly<{
  id: Uuid;
  center: LatLng;
  placement?: DrawingItemPlacement;
  settings: DrawingCircleSettingsState;
}>;

export type DrawingItemIconMarker = Readonly<{
  id: Uuid;
  center: LatLng;
  placement?: DrawingItemPlacement;
  settings: DrawingIconMarkerSettingsState;
}>;

export type DrawingItemImage = Readonly<{
  id: Uuid;
  center: LatLng;
  attachmentId: FileAttachmentId;
  placement?: DrawingItemPlacement;
  settings: DrawingImageSettingsState;
}>;

export type DrawingItemLabel = Readonly<{
  id: Uuid;
  startPoint: LatLng;
  text: string;
  placement?: DrawingItemPlacement;
  settings: DrawingLabelSettingsState;
}>;

export type DrawingItemNumber = Readonly<{
  id: Uuid;
  center: LatLng;
  placement?: DrawingItemPlacement;
  settings: DrawingNumberSettingsState;
}>;

export type DrawingItemPolygon = Readonly<{
  id: Uuid;
  outlines: MapObjectOutlineInstance[];
  placement?: DrawingItemPlacement;
  settings: DrawingPolygonSettingsState;
}>;

export type DrawingItemPolyline = Readonly<{
  id: Uuid;
  outlines: MapObjectOutlineInstance[];
  placement?: DrawingItemPlacement;
  settings: DrawingPolylineSettingsState;
}>;

export type DrawingItemRectangle = Readonly<{
  id: Uuid;
  startPoint: LatLng;
  endPoint: LatLng;
  placement?: DrawingItemPlacement;
  settings: DrawingRectangleSettingsState;
}>;

export type DrawingItemText = Readonly<{
  id: Uuid;
  startPoint: LatLng;
  text: string;
  placement?: DrawingItemPlacement;
  settings: DrawingTextSettingsState;
}>;
