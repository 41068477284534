import { css } from '@emotion/react';
import { type FC } from 'react';

type DropdownLabelProps = Readonly<{
  children: (string | number)[];
}>;

const dropdownHeadingStyle = css({
  margin: '10px 0 5px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
});

export const HeatMapDropdownLabelComponent: FC<DropdownLabelProps> = props => (
  <p css={dropdownHeadingStyle} >{props.children}</p>
);
