import { useMemo } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  isSearchItemWithColumn, isSearchItemWithGeolocatedData, type SearchItemWithColumn,
  type SearchItemWithGeolocatedData,
} from '~/map/search/mapSearch.helpers';
import { type AppState } from '~/store/app.store';

export const searchItemsSelector = (state: AppState) => state.map.mapSettings.data.toolsState.searchItems.items;
export const useSearchItemsSelector = () => useSelector(searchItemsSelector);

export const useGeolocatedSearchItemsSelector = (): SearchItemWithGeolocatedData[] => {
  const searchItems = useSearchItemsSelector();
  return useMemo(() => (
    searchItems.filter(item => isSearchItemWithGeolocatedData(item))
  ), [searchItems]);
};

export const useColumnSearchItemsSelector = (): SearchItemWithColumn[] => {
  const searchItems = useSearchItemsSelector();
  return useMemo(() => (
    searchItems.filter(item => isSearchItemWithColumn(item))
  ), [searchItems]);
};
