import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { type StreetViewLocation } from '~/store/frontendState/mapTools/streetView/streetView.state';
import { StreetViewPanoramaComponent } from '../streetViewPanorama/streetViewPanorama.component';

const galleryItemWrapperStyle = css({
  position: 'relative',
});

const labelStyles = css({
  position: 'absolute',
  left: 0,
  top: 0,
  padding: '5px 10px',
  borderRadius: '0 0 4px 0',
  fontSize: 12,
  textTransform: 'uppercase',
  zIndex: 10,

  // Given this label component is rendered over a 3rd party image,
  // I don't see value adding them into theme as they must always look the same
  backgroundColor: 'rgba(17, 27, 34, 0.8)',
  color: '#FFFFFF',
});

const panoramaStyles = css({
  width: '100%',
  height: '100%',
});

type ImageGalleryStreetViewComponentProps = {
  // Image gallery items are rendered as list/array thus key property is always required
  key: string;
  panoramaContainerId: string;
  streetViewLocation: StreetViewLocation;
  className?: string;
  onClick?: () => void;
};

export const ImageGalleryStreetViewComponent: FC<ImageGalleryStreetViewComponentProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div
      css={galleryItemWrapperStyle}
      className={props.className}
      onClick={props.onClick}
    >
      <div css={labelStyles} >
        {t('Street View')}
      </div>
      <StreetViewPanoramaComponent
        panoramaContainerId={props.panoramaContainerId}
        streetViewLocation={props.streetViewLocation}
        css={panoramaStyles}
      />
    </div>
  );
};
