import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type SpreadsheetColumnId } from '../../types/spreadsheetData/spreadsheetColumn';

export enum SettingType {
  Text,
  Visibility,
  Dropdown,
  Checkbox,
  Input,
  Toggle,
  Button
}

export type SettingTypeResponse = 'Text' | 'Visibility' | 'Dropdown' | 'Checkbox' | 'Input' | 'Toggle' | 'Button';

export type SettingColumn = {
  caption: string;
  align?: 'left' | 'center' | 'right';
  width: string;
};

type SettingDataCommon = {
  isCentered?: boolean;
  disabled: string | null;
};

export type SettingTextData = {
  value: string;
  type: SettingType.Text;
} & SettingDataCommon;

export type SettingInputData = {
  value: string;
  type: SettingType.Input;
} & SettingDataCommon;

export type SettingVisibilityData = {
  value: boolean;
  type: SettingType.Visibility;
} & SettingDataCommon;

export type SettingCheckboxData = {
  value: boolean;
  type: SettingType.Checkbox;
} & SettingDataCommon;

export type SettingToggleData = {
  value: boolean;
  type: SettingType.Toggle;
  onLabel: string;
  offLabel: string;
} & SettingDataCommon;

export type SettingDropdownData = {
  value: string;
  type: SettingType.Dropdown;
  placeholder?: string;
  options: DropdownOption<string>[];
} & SettingDataCommon;

export type SettingButtonData = {
  buttonText: string;
  type: SettingType.Button;
  callback: (index: number) => void;
} & SettingDataCommon;

export type SettingData =
  SettingTextData | SettingInputData | SettingVisibilityData |
  SettingCheckboxData | SettingDropdownData | SettingToggleData | SettingButtonData;

export type ChangeableSettingsData = Exclude<SettingData, SettingButtonData>;

export type SettingRow = {
  data: SettingData[];
};

export type ChangeableSettingRow = {
  data: ChangeableSettingsData[];
};

export type SpreadsheetColumnIdSettingsRow = {
  columnId: SpreadsheetColumnId;
  row: SettingRow;
};
