import { css } from '@emotion/react';
import { type FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { s } from '~/translations/Trans';
import { AppErrorPageWrapperComponent } from '../wrapper/appErrorPageWrapper.component';

const textWrapperStyle = css({
  maxWidth: 680,
});

const textStyle = css({
  margin: '0 0 20px',
});

const linkStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  textDecoration: 'underline',
  fontWeight: 700,
  '&:hover': {
    color: theme.textColors.dangerOnHover,
  },
});

export const AppErrorPageMapNotFoundComponent: FC = () => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <AppErrorPageWrapperComponent>
      <div css={textWrapperStyle}>
        <p css={textStyle}>
          {t('The map you are looking for doesn\'t exist.')}
          <br />
          <Trans i18nKey="Try a different map or see all available maps by clicking <1>here</1>.">
            {s('Try a different map or see all available maps by clicking ')}
            <Link
              to={HOME_ROUTE}
              css={linkStyle({ theme })}
            >
              here
            </Link>
            {s('.')}
          </Trans>
        </p>

        <p css={textStyle}>{t('It\'s possible the map creator deleted the map or the data no longer exists.')}</p>
      </div>
    </AppErrorPageWrapperComponent>
  );
};
