import { createUuid } from '~/_shared/utils/createUuid';
import { type SpreadsheetDataAction } from '~/store/spreadsheetData/spreadsheetData.action';
import { SPREADSHEET_RESET_STATE_AND_REFETCH_DATA } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { DataType } from '../../spreadsheetData/spreadsheetData.state';
import { type SpreadsheetTableAction } from './spreadsheetTable.action';
import {
  SPREADSHEET_TABLE_SEARCH_SET_QUERY,
  SPREADSHEET_TABLE_SET_IS_ROWS_REMOVE_IN_PROGRESS,
  SPREADSHEET_TABLE_SET_SELECTED_ROWS,
} from './spreadsheetTable.actionTypes';
import { type SpreadsheetTableState } from './spreadsheetTable.state';

const initialState: SpreadsheetTableState = {
  spreadsheetTableDataETag: createUuid(),
  selectedRows: {},
  isRemoveInProgress: false,
  search: {},
};

type ReducerAction = SpreadsheetTableAction | SpreadsheetDataAction;

export const spreadsheetTableReducer = (state = initialState, action: ReducerAction): SpreadsheetTableState => {
  switch (action.type) {
    case SPREADSHEET_RESET_STATE_AND_REFETCH_DATA: {
      return {
        ...state,
        // This triggers refetch as the value is used as effect dependency
        spreadsheetTableDataETag: createUuid(),
      };
    }

    case SPREADSHEET_TABLE_SET_SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
      };
    }

    case SPREADSHEET_TABLE_SET_IS_ROWS_REMOVE_IN_PROGRESS: {
      return {
        ...state,
        isRemoveInProgress: action.payload.inProgress,
      };
    }

    case SPREADSHEET_TABLE_SEARCH_SET_QUERY: {
      const spreadsheetColumnId = action.payload.spreadsheetColumnId;

      return {
        ...state,
        search: {
          [spreadsheetColumnId.spreadsheetId]: {
            ...state.search[spreadsheetColumnId.spreadsheetId],
            [spreadsheetColumnId.columnId]: {
              [DataType.TEXT]: action.payload.values,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
