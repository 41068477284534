import { css } from '@emotion/react';
import { type FC } from 'react';
import { AnimatedRadarMarker } from '../../../../_shared/components/markers/animatedRadarMarker/animatedRadarMarker.component';
import { type LatLng } from '../../../../_shared/types/latLng';
import { GoogleMapsOverlayViewContainer } from '../_shared/googleMapsOverlayViewContainer';

const RADIUS: number = 20;

type RadarMarkerOverlayProps = {
  position: LatLng;
  map: google.maps.Map;
  onClick?: () => void;
};

export const RadarMarkerOverlay: FC<RadarMarkerOverlayProps> = (props) => {
  return (
    <GoogleMapsOverlayViewContainer
      position={props.position}
      map={props.map}
    >
      <div
        onClick={props.onClick}
        css={css({
          width: RADIUS + 'px',
          height: RADIUS + 'px',
          position: 'relative',
          top: '-26px',
          left: '-25px',
        })}
      >
        <AnimatedRadarMarker radius={RADIUS} />
      </div>
    </GoogleMapsOverlayViewContainer>
  );
};
