import { logWarning } from '../../../_shared/utils/logError';
import { notNull } from '../../../_shared/utils/typeGuards';
import {
  calculateEntityZIndexOffset,
  calculateEntityZIndexRangeSize,
  getZIndexesPerEntity,
  type ZIndexedEntity,
  zIndexEntitiesOrdered,
} from '../../../map/zIndexes/zIndexRanges';
import { type MapZIndexesAction } from './mapZIndexes.action';
import {
  FRONTEND_STATE_ZINDEX_ENTITIES_RELEASED,
  FRONTEND_STATE_ZINDEX_ENTITIES_WITH_ORDER_ENSURED,
} from './mapZIndexes.actionTypes';
import {
  type SlotsPerEntity, type ZIndexedEntityState,
} from './mapZIndexes.state';

const initialEntityState: () => ZIndexedEntityState = () => ({
  usedZIndexes: new Map(),
  freedZIndexes: new Set(),
});

const newInitialValue = Object.fromEntries(zIndexEntitiesOrdered.map(item => {
  const entity = item[0];
  return [entity, initialEntityState()] as const;
})) as SlotsPerEntity;

export const mapZIndexesReducer = (state = newInitialValue, action: MapZIndexesAction): SlotsPerEntity => {
  switch (action.type) {
    case FRONTEND_STATE_ZINDEX_ENTITIES_WITH_ORDER_ENSURED: {
      const { entity, ids } = action.payload;
      const currentEntityState = state[entity];

      const alreadyRegisteredAndOrdered = ids.reduce(({ previousZIndex, result }, id: string) => {
        const existingZIndex = currentEntityState.usedZIndexes.get(id);
        return {
          previousZIndex: existingZIndex ?? -1,
          result: result && existingZIndex !== undefined && previousZIndex < existingZIndex,
        };
      }, { previousZIndex: -1, result: true }).result;

      if (alreadyRegisteredAndOrdered) {
        return state;
      }

      const newEntityStateMutable = {
        usedZIndexes: new Map(currentEntityState.usedZIndexes),
        freedZIndexes: new Set(currentEntityState.freedZIndexes),
      };

      ids.forEach(id => releaseZIndex(id, newEntityStateMutable));

      const orderedZIndexes = ids.map(id => assignFreeZIndex(entity, id, newEntityStateMutable))
        .filter(notNull)
        .sort((a, b) => a - b)
        .map((zIndex, i) => [ids[i], zIndex] as const);

      if (orderedZIndexes.length !== ids.length) {
        return state; // failed to reserve all indexes
      }

      // apply ordering
      orderedZIndexes.forEach(([id, zIndex]) => newEntityStateMutable.usedZIndexes.set(id, zIndex));

      return {
        ...state,
        [entity]: newEntityStateMutable,
      };
    }

    case FRONTEND_STATE_ZINDEX_ENTITIES_RELEASED: {
      const { entity, ids } = action.payload;
      const currentEntityState = state[entity];

      const newEntityStateMutable = {
        usedZIndexes: new Map(currentEntityState.usedZIndexes),
        freedZIndexes: new Set(currentEntityState.freedZIndexes),
      };

      ids.forEach(id => releaseZIndex(id, newEntityStateMutable));

      return {
        ...state,
        [entity]: newEntityStateMutable,
      };
    }

    default:
      return state;
  }
};

const assignFreeZIndex = (entity: ZIndexedEntity, id: string, { usedZIndexes, freedZIndexes }: {
  usedZIndexes: Map<string, number>; // mutable
  freedZIndexes: Set<number>; // mutable
}) => {
  const zIndexesPerEntity = getZIndexesPerEntity(entity);
  const entityZIndexOffset = calculateEntityZIndexOffset(entity);
  const unusedZIndexOffset = zIndexesPerEntity * (usedZIndexes.size + freedZIndexes.size) + entityZIndexOffset;

  const nextFreedZIndex = freedZIndexes.values().next();
  const assignedIndex = !nextFreedZIndex.done ? nextFreedZIndex.value : unusedZIndexOffset;

  if (assignedIndex + (zIndexesPerEntity - 1) >= calculateEntityZIndexRangeSize(entity) + entityZIndexOffset) {
    logWarning(`Entity ${entity} has overflowed its z-index entity range.`);
    return null;
  }

  freedZIndexes.delete(assignedIndex);
  usedZIndexes.set(id, assignedIndex);
  return assignedIndex;
};

const releaseZIndex = (id: string, { usedZIndexes, freedZIndexes }: {
  usedZIndexes: Map<string, number>; // mutable
  freedZIndexes: Set<number>; // mutable
}) => {
  const zIndex = usedZIndexes.get(id);
  if (zIndex !== undefined) {
    usedZIndexes.delete(id);
    freedZIndexes.add(zIndex);
  }
};
