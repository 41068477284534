import { css } from '@emotion/react';
import { faCommentExclamation } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

export enum AdditionalWarningMessageType {
  Error = 'Error',
  Warning = 'Warning',
}

type AdditionalWarningMessageProps = Readonly<{
  className?: string;
  type: AdditionalWarningMessageType;
  children: React.ReactNode;
}>;

const wrapperStyle = ({ theme, type }: ThemeProps<{ type: AdditionalWarningMessageType }>) => css({
  color: type === AdditionalWarningMessageType.Error ? theme.alertColors.dangerPrimary : theme.alertColors.warningPrimary,
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  fontSize: 12,
  marginTop: 8,
});

export const AdditionalOptionsWarningMessageComponent: FC<AdditionalWarningMessageProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={wrapperStyle({ theme, type: props.type })}
      className={props.className}
    >
      <FontAwesomeIcon
        icon={faCommentExclamation}
        css={{ fontSize: 18 }}
      />
      {props.children}
    </div>
  );
};
