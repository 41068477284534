import { type ColumnsMatchUp } from '../../_shared/types/columnRole.enum';
import { type SpreadsheetMatchupData } from '../../_shared/types/spreadsheetData/matchupData';
import {
  MATCHUP_FETCH_DATA_ERROR,
  MATCHUP_FETCH_DATA_REQUEST,
  MATCHUP_FETCH_DATA_SUCCESS, MATCHUP_REFETCH_DATA,
  MATCHUP_UPDATE_ERROR,
  MATCHUP_UPDATE_REQUEST,
  MATCHUP_UPDATE_SUCCESS,
} from './matchupData.actionTypes';

export function matchupFetchDataRequest(clientId: number, spreadsheetVirtualId: number, mapId: number, isMatchupRequired: boolean) {
  return {
    type: MATCHUP_FETCH_DATA_REQUEST,
    payload: {
      clientId,
      spreadsheetVirtualId,
      mapId,
      isMatchupRequired,
    },
  } as const;
}

export function matchupRefetchData(virtualSpreadsheetId: number) {
  return {
    type: MATCHUP_REFETCH_DATA,
    payload: {
      virtualSpreadsheetId,
    },
  } as const;
}

export function matchupFetchDataError(spreadsheetVirtualId: number, error: Error) {
  return {
    type: MATCHUP_FETCH_DATA_ERROR,
    payload: {
      error,
      spreadsheetVirtualId,
    },
  } as const;
}

export function matchupFetchDataSuccess(spreadsheetVirtualId: number, data: SpreadsheetMatchupData, isMatchupRequired: boolean) {
  return {
    type: MATCHUP_FETCH_DATA_SUCCESS,
    payload: {
      data,
      spreadsheetVirtualId,
      isMatchupRequired,
    },
  } as const;
}

export function matchupUpdateRequest(spreadsheetVirtualId: number, matchup: ColumnsMatchUp) {
  return {
    type: MATCHUP_UPDATE_REQUEST,
    payload: {
      matchup,
      spreadsheetVirtualId,
    },
  } as const;
}

export function matchupUpdateError(spreadsheetVirtualId: number, error: Error) {
  return {
    type: MATCHUP_UPDATE_ERROR,
    payload: {
      error,
      spreadsheetVirtualId,
    },
  } as const;
}

export function matchupUpdateSuccess(spreadsheetVirtualId: number, data: SpreadsheetMatchupData) {
  return {
    type: MATCHUP_UPDATE_SUCCESS,
    payload: {
      spreadsheetVirtualId,
      data,
    },
  } as const;
}
