import { arrayEquals } from '../array/array.helpers';

export const opaqueBlack: WebglColor = [0, 0, 0, 1];
export const opaqueGray: WebglColor = [211, 211, 211, 1];

export const webglColorEquals = (colorObject: WebglColorObject, colorArray?: WebglColor | null): colorArray is WebglColor =>
  !!colorArray && arrayEquals(colorObject.toArray(), colorArray);

export const webglColorChanged = (colorObject: WebglColorObject, colorArray?: WebglColor | null): colorArray is WebglColor =>
  !!colorArray && !arrayEquals(colorObject.toArray(), colorArray);
