import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../app.store';

const routesUiDataSelector = (state: AppState) => state.frontendState.mapTools.directions.routesUiData;
export const useRoutesUiDataSelector = () => useSelector(routesUiDataSelector);

const directionsExpandedRouteIdsSelector = (state: AppState) => state.frontendState.mapTools.directions.expandedRouteIds;
export const useDirectionsExpandedRouteIdsSelector = () => useSelector(directionsExpandedRouteIdsSelector);

const directionsShouldBeginAtUserLocationSelector = (state: AppState) => state.map.mapSettings.data.directions.shouldBeginAtUserLocation;
export const useDirectionsShouldBeginAtUserLocationSelector = () => useSelector(directionsShouldBeginAtUserLocationSelector);

const directionsCanDragRoutesSelector = (state: AppState) => state.map.mapSettings.data.directions.canDragRoutes;
export const useDirectionsCanDragRoutesSelector = () => useSelector(directionsCanDragRoutesSelector);

const directionsSelectedCollapsingSelector = (state: AppState) => state.map.mapSettings.data.directions.selectedCollapsing;
export const useDirectionsSelectedCollapsingSelector = () => useSelector(directionsSelectedCollapsingSelector);
