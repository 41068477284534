import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.ArrowSpinner]: {
  views: {
    default: {
      canvasHeight: 532,
      canvasWidth: 532,
      iconWidth: 510,
      iconHeight: 510,
    },
  },
  colors: {
    arrow: '#B3D7F1',
    circle: '#B3D7F1',
    spinner: '#0078CE',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 100] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/40609-arrow-loader" */
      './40609-arrow-loader.json'
    ),
} };

export const { ArrowSpinner } = config;
