import {
  useCallback, useMemo,
} from 'react';
import { GroupingType } from '~/_shared/types/grouping/grouping';
import {
  isCustomMarkerStyle, isLabelStyle,
  isMarkerStyle,
  isStandardMarkerStyle, type MarkerEntityStyle,
  type MarkerLabelStyles,
  MarkerSegment,
  type MarkerSpritesheetSettings,
  type MarkerStyle,
  MarkerStyleType,
  type StackedMarkerStyle,
  type StandardMarkerStyle,
} from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { logError } from '~/_shared/utils/logError';
import {
  createMarkerSegmentsFromStandardMarkerSettings,
  getMarkerEntityStyle,
} from '~/_shared/utils/markers/markerEntityStyle.helpers';
import { STACKED_MARKER_SIZE_MULTIPLIER } from '~/_shared/utils/markers/markers.constants';
import {
  getFallbackStandardMarkerSettings, getNumericBucketKeyForGroupingColumn,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { DEFAULT_MARKER_SIZE } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { useGetMarkerVisualsForSpreadsheetRowIds } from '~/_shared/utils/markers/useGetMarkerVisualsForSpreadsheetRowIds.hook';
import {
  type ReadonlySpreadsheetRowIdMap,
  SpreadsheetRowIdMap,
} from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { useShowMarkerShadows } from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { useMapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import { mapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import {
  mapSettingsMarkerStyleIdSelector,
  useAreMarkerLabelsDisabled,
  useMapSettingsMarkersGeneralSelector,
  useMapSettingsMarkersTextureAnchors,
  useMapSettingsUseLabelsAboveMarkersSelector,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { selectPerGroupVisualSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import blankTexture from '../../../../../../assets/images/blank_200x200.png';
import { useMarkerSets } from '../../../../store/frontendState/markerSets/markerSets.selectors';
import {
  createTemplateLayers, TemplateLayer,
} from '../manager/mapMarkerTemplates';
import {
  createDynamicTemplateName, type MarkerTemplateManager,
} from '../manager/markerTemplateManager';
import { useMoveMarkerLabelOffsets } from '../useMoveMarkerLabels/useMoveMarkerLabelOffsets';

export type StandardMarkerStyleWithSize = StandardMarkerStyle & { readonly size: number };
export type MarkerEntitiesStyle = Readonly<{
  mainMarker: MarkerEntityStyle;
  subMarker?: MarkerEntityStyle;
  labelAbove?: MarkerLabelStyles;
  aboveLabelText?: string;
}>;
export type MarkerEntitiesStyles = ReadonlySpreadsheetRowIdMap<MarkerEntitiesStyle>;

export type StackedMarkerStyles = {
  pieChartHolderStyle: StackedMarkerStyle;
  stackedMarkerStyle: StackedMarkerStyle;
  perGroup: { [groupId: string]: StackedMarkerStyle };
};

const MIN_SPECIAL_MARKER_SIZE = 35;
export const PIECHART_HOLDER_STYLE_ID = 18;
export const PIECHART_HOLDER_SIZE = 55;

export const useMarkerStyles = (markers: ReadonlyArray<SpreadsheetRowId>, markerTemplateManager: MarkerTemplateManager | undefined): ReadonlySpreadsheetRowIdMap<MarkerEntitiesStyle> => {
  const groupingColumnsCount = useSelector(s => s.map.mapSettings.data.grouping.activeGroupColumns.length);
  const markerSets = useMarkerSets();
  const areLabelsAboveEnabled = useMapSettingsUseLabelsAboveMarkersSelector();
  const files = useFileUrls();
  const fileAttachments = useMapSettingsFileAttachmentsMapSelector();
  const generalMarkerSettings = useMapSettingsMarkersGeneralSelector();
  const showMarkerShadows = useShowMarkerShadows();
  const { getMarkerTextureAnchor } = useMapSettingsMarkersTextureAnchors();
  const { applyCustomLabelOffsets } = useMoveMarkerLabelOffsets();
  const markerLabelsDisabled = useAreMarkerLabelsDisabled();

  const fallbackMarkerSettings = useMemo(() => getFallbackStandardMarkerSettings(generalMarkerSettings), [generalMarkerSettings]);

  const mainMarkersStyles = useGetMarkerVisualsForSpreadsheetRowIds({
    spreadsheetRowIds: markers,
    groupHierarchyIndicator: groupingColumnsCount > 0 ? 0 : undefined,
    isLegend: false,
  });
  const subMarkerStyles = useGetMarkerVisualsForSpreadsheetRowIds({
    spreadsheetRowIds: markers,
    groupHierarchyIndicator: 1,
    isLegend: false,
  });

  const getMarkerEntityStyleWithLabelOffset = useCallback((
    id: SpreadsheetRowId,
    markerSettings: MarkerSettings,
    noShadow?: boolean,
  ): MarkerEntityStyle => {

    const markerEntityStyle = getMarkerEntityStyle({
      fallbackMarkerSettings,
      fileAttachments,
      fileMap: files,
      getMarkerTextureAnchor,
      markerSets,
      markerSettings,
      noShadow,
      markerLabelsDisabled,
    });

    if (isLabelStyle(markerEntityStyle)) {
      return applyCustomLabelOffsets(id, markerEntityStyle);
    }

    return markerEntityStyle;
  }, [applyCustomLabelOffsets, fallbackMarkerSettings, fileAttachments, files, getMarkerTextureAnchor, markerLabelsDisabled, markerSets]);

  return useMemo(() => {
    const result = new SpreadsheetRowIdMap<MarkerEntitiesStyle>();
    for (const id of markers) {
      const mainMarkerSettings = mainMarkersStyles[id.spreadsheetId]?.[id.rowId];
      if (!mainMarkerSettings) {
        continue;
      }

      const mainMarker = getMarkerEntityStyleWithLabelOffset(
        id, mainMarkerSettings, !showMarkerShadows
      );

      if (mainMarkerSettings.useMarker && markerTemplateManager && isMarkerStyle(mainMarker)) {
        registerMarkerTemplate(mainMarker, markerTemplateManager);
      }

      const subMarkerSettings = groupingColumnsCount > 1 ? subMarkerStyles[id.spreadsheetId]?.[id.rowId] : null;

      const subMarker = subMarkerSettings ? getMarkerEntityStyleWithLabelOffset(
        id, subMarkerSettings, true
      ) : undefined;

      if (subMarkerSettings?.useMarker && markerTemplateManager && isMarkerStyle(subMarker)) {
        registerMarkerTemplate(subMarker, markerTemplateManager);
      }

      const labelAbove: MarkerLabelStyles | undefined = mainMarkerSettings.aboveLabel &&
        (areLabelsAboveEnabled || mainMarkerSettings.marker?.labelText) ? {
          type: 'label',
          ...mainMarkerSettings.aboveLabel,
          offsetProps: { type: 'default' },
        } : undefined;

      result.set(id, {
        mainMarker,
        subMarker,
        labelAbove,
        aboveLabelText: mainMarkerSettings.marker?.labelText,
      });
    }

    return result.asReadonly();
  }, [markers, mainMarkersStyles, getMarkerEntityStyleWithLabelOffset, showMarkerShadows,
    markerTemplateManager, groupingColumnsCount, subMarkerStyles, areLabelsAboveEnabled,
  ]);
};

export const useStackedMarkerStyles = (options: {
  registerTemplates: boolean;
  markerTemplateManager?: MarkerTemplateManager;
} = { registerTemplates: false }): StackedMarkerStyles | null => {
  const { registerTemplates, markerTemplateManager } = options;
  const showMarkerShadows = useShowMarkerShadows();
  const { globalMarkerSettings } = useMapSettingsMarkersGeneralSelector();
  const markerSets = useMarkerSets();

  const registerStackedMarkerStyle = useCallback((styleId: number, markerSettings?: MarkerSettings, sizeOverride?: number) => {
    const style = markerSets[styleId] ?? null;
    if (!style) {
      return null;
    }

    const stackedMarkerStyle = {
      ...createStackMarkerStyles({ style, markerSettings, showMarkerShadows }),
      ...sizeOverride ? { size: sizeOverride } : {},
    };

    if (registerTemplates && markerTemplateManager) {
      registerMarkerTemplate(stackedMarkerStyle, markerTemplateManager);
    }

    return stackedMarkerStyle;
  }, [markerSets, markerTemplateManager, registerTemplates, showMarkerShadows]);

  const globalMarkerStyleId = useSelector(mapSettingsMarkerStyleIdSelector);

  const globalStackedMarkerStyle = useMemo(() => registerStackedMarkerStyle(globalMarkerStyleId, globalMarkerSettings),
    [globalMarkerSettings, globalMarkerStyleId, registerStackedMarkerStyle]);
  const pieChartHolderStyle = useMemo(() => registerStackedMarkerStyle(PIECHART_HOLDER_STYLE_ID, globalMarkerSettings, PIECHART_HOLDER_SIZE),
    [globalMarkerSettings, registerStackedMarkerStyle]);

  const activeGroupColumns = useSelector(mapSettingsGroupingActiveGroupColumnsSelector);
  const perGroupVisualSettings = useSelector(selectPerGroupVisualSettings);
  const perGroup = useMemo(() => {
    const result: { [groupId: string | number]: StackedMarkerStyle } = {};

    if (!notEmpty(activeGroupColumns)) {
      return result;
    }

    const hierarchy = 0; // use only primary columns
    const { type, columnId, spreadsheetId } = activeGroupColumns[hierarchy];
    const columnVisuals = perGroupVisualSettings[spreadsheetId]?.[columnId];

    const registerGroupStackedMarkerStyle = ([groupId, visualSettings]: [groupId: string, visualSettings: MarkerSettings]) => {
      if (visualSettings?.marker?.styleType === MarkerStyleType.STANDARD) {
        const stackedStyles = registerStackedMarkerStyle(visualSettings.marker.styleId, visualSettings);
        if (stackedStyles) {
          result[groupId] = stackedStyles;
        }
      }
    };

    if (type === GroupingType.Text) {
      Object.entries(columnVisuals?.[GroupingType.Text] ?? {}).forEach(registerGroupStackedMarkerStyle);
    }

    if (type === GroupingType.Numeric) {
      const bucketKey = getNumericBucketKeyForGroupingColumn(activeGroupColumns, hierarchy);
      Object.entries(columnVisuals?.[GroupingType.Numeric]?.[bucketKey] ?? {}).forEach(registerGroupStackedMarkerStyle);
    }

    return result;
  }, [activeGroupColumns, perGroupVisualSettings, registerStackedMarkerStyle]);

  return useMemo(() => {
    if (registerTemplates && !markerTemplateManager) {
      return null;
    }

    if (!globalStackedMarkerStyle || !pieChartHolderStyle) {
      return null;
    }

    return ({
      pieChartHolderStyle,
      stackedMarkerStyle: globalStackedMarkerStyle,
      perGroup,
    });
  }, [globalStackedMarkerStyle, markerTemplateManager, perGroup, pieChartHolderStyle, registerTemplates]);
};

const registerMarkerTemplate = (markerStyle: MarkerStyle, templatesManager: MarkerTemplateManager) => {
  if (isCustomMarkerStyle(markerStyle)) {
    const { height, width } = markerStyle.marker.dimensions;
    templatesManager.registerTemplate({
      name: createDynamicTemplateName(markerStyle),
      main: markerStyle.marker.path,
      size: { w: width, h: height },
    });
  }
  else if (isStandardMarkerStyle(markerStyle)) {
    const marker = markerStyle.marker;
    const { height, width } = marker.spritesheetSettings.dimensions;
    const getTextureForLayer = (spritesheetSettings: MarkerSpritesheetSettings, templateLayer: TemplateLayer) => (
      templateLayer === TemplateLayer.MAIN ? spritesheetSettings.layerThree
        : templateLayer === TemplateLayer.BACKGROUND ? spritesheetSettings.layerTwo : spritesheetSettings.layerOne
    );

    let markerSegments = markerStyle.segments;
    if (!markerSegments) {
      markerSegments = {
        [MarkerSegment.MAIN]: createTemplateLayers(
          TemplateLayer.MAIN, TemplateLayer.BACKGROUND, TemplateLayer.SHADOW,
        ),
      };
    }

    Object.values(markerSegments).forEach((markerLayer) => {
      templatesManager.registerTemplate({
        name: createDynamicTemplateName(markerStyle, markerLayer),
        // TODO: using blank texture in some cases because 3 layered marker texture does not work
        // without main set in the current webgl layer implementation. Remove this once fixed on webgl layer.
        main: marker.spritesheetSettings.layerThree
          ? (`${getTextureForLayer(marker.spritesheetSettings, markerLayer.main)}`)
          : blankTexture,
        background: marker.spritesheetSettings.layerTwo && markerLayer.background
          ? (`${getTextureForLayer(marker.spritesheetSettings, markerLayer.background)}`) : undefined,
        shadow: marker.spritesheetSettings.layerOne && markerLayer.shadow
          ? (`${getTextureForLayer(marker.spritesheetSettings, markerLayer.shadow)}`) : undefined,
        size: { w: width, h: height },
      });
    });
  }
  else {
    logError('useMarkers: marker style is neither standard nor custom, there must be a mistake');
  }
};

const getStackedMarkerSize = (globalMarkerSettings: MarkerSettings | undefined) => {
  const isStandardMarkerStyle = globalMarkerSettings?.marker?.styleType === MarkerStyleType.STANDARD;
  const sizeFromGlobalMarkerSettings = isStandardMarkerStyle ? globalMarkerSettings?.marker?.size : null;
  const generalOrDefaultSize = sizeFromGlobalMarkerSettings ?? DEFAULT_MARKER_SIZE;
  const stackedMarkerSize = generalOrDefaultSize * STACKED_MARKER_SIZE_MULTIPLIER;
  return MIN_SPECIAL_MARKER_SIZE > stackedMarkerSize ? MIN_SPECIAL_MARKER_SIZE : stackedMarkerSize;
};

const createStackMarkerStyles = ({ style, markerSettings, showMarkerShadows }: {
  style: StandardMarkerStyle;
  markerSettings?: MarkerSettings;
  showMarkerShadows: boolean;
}) => {
  const size = getStackedMarkerSize(markerSettings);
  return {
    ...style,
    size,
    segments: createMarkerSegmentsFromStandardMarkerSettings(style.marker, !showMarkerShadows),
  };
};
