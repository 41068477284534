import {
  type FC, memo, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { clamp } from '~/_shared/utils/number/number.helpers';
import {
  DRAWING_TOOL_CIRCLE_MAX_RADIUS, DRAWING_TOOL_CIRCLE_MIN_RADIUS,
} from '~/drawingTool/drawingTool.constants';
import { useMap } from '~/map/map/mapContext';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '../../../../_shared/types/latLng';
import { getDraggableCursorForOutlinePosition } from '../../../../_shared/utils/mapObjectOutline/outline.helpers';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '../../../../drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { drawingEditPushNewSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered, drawingToolItemHoverStopped, drawingToolItemMoveStarted, drawingToolItemMoveStopped,
  drawingToolItemResizeStarted, drawingToolItemResizeStopped,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemCircle } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  type DrawingToolScalingItem, type DrawingToolScalingItemProps, useDrawingToolItemScalingToggle,
} from '../hooks/useDrawingToolItemScalingToggle';
import { DrawingToolCircleInstanceCircleContainer } from './drawingToolCircleInstanceCircle.container';

type MapCircleSelectedInstanceProps = {
  instance: DrawingItemCircle;
};

const DrawingToolCircleSelectedInstanceContainer: FC<MapCircleSelectedInstanceProps> = ({ instance }) => {
  const [drawingCenter, setDrawingCenter] = useState<LatLng>(instance.center);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();

  const map = useMap();
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const isDraggingRef = useRef<boolean>(false);

  const scaledRadiusSizeProps: DrawingToolScalingItemProps = {
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.radius,
    min: DRAWING_TOOL_CIRCLE_MIN_RADIUS,
    max: DRAWING_TOOL_CIRCLE_MAX_RADIUS,
  };

  const onRadiusChange = useCallback((props: DrawingToolScalingItem) => {
    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Circle,
      value: {
        ...instance,
        settings: {
          ...instance.settings,
          radius: props.itemSize,
        },
      },
    }));
  }, [instance, dispatch]);
  useDrawingToolItemScalingToggle(scaledRadiusSizeProps, onRadiusChange);

  const onResizeControllerMouseOver = useCallback((outlineId: string) => {
    dispatch(drawingToolItemHovered());
    setHoveredOutlineId(outlineId);
  }, [dispatch]);

  const onResizeControllerMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
    setHoveredOutlineId(null);
  }, [dispatch]);

  const onOutlineDragMove = useCallback((
    _outlineId: string, position: MapOutlinePosition, latLng: LatLng
  ) => {
    const itemCenterPosition = fromLatLngToDivPixel(instance.center);
    const resizeControllerPosition = fromLatLngToDivPixel(latLng);

    if (!itemCenterPosition || !resizeControllerPosition) {
      return;
    }

    let newRadiusInPx: number;
    switch (position) {
      case MapOutlinePosition.Right:
        newRadiusInPx = resizeControllerPosition.x - itemCenterPosition.x - instance.settings.strokeWeight / 2;
        break;
      case MapOutlinePosition.Left:
        newRadiusInPx = itemCenterPosition.x - resizeControllerPosition.x - instance.settings.strokeWeight / 2;
        break;
      case MapOutlinePosition.Bottom:
        newRadiusInPx = resizeControllerPosition.y - itemCenterPosition.y - instance.settings.strokeWeight / 2;
        break;
      case MapOutlinePosition.Top:
        newRadiusInPx = itemCenterPosition.y - resizeControllerPosition.y - instance.settings.strokeWeight / 2;
        break;
      default:
        return;
    }

    const radiusSizeRatio = instance.settings.scalesWithMapZoom ? sizePerPixelRatio : 1;

    const radius = clamp(newRadiusInPx, { min: DRAWING_TOOL_CIRCLE_MIN_RADIUS, max: DRAWING_TOOL_CIRCLE_MAX_RADIUS }) * radiusSizeRatio;

    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Circle,
      value: {
        ...instance,
        settings: {
          ...instance.settings,
          radius,
        },
      },
    }));
  }, [fromLatLngToDivPixel, instance, sizePerPixelRatio, dispatch]);

  const onOutlineDragEnd = useCallback(() => {
    dispatch(drawingToolItemResizeStopped());
  }, [dispatch]);

  const onResizeControllerDragStart = useCallback((_outlineId: string, position: MapOutlinePosition) => {
    dispatch(drawingToolItemResizeStarted(
      getDraggableCursorForOutlinePosition(position))
    );
  }, [dispatch]);

  const onCircleDragStart = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      isDraggingRef.current = true;
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onCircleDragEnd = useCallback(() => {
    isDraggingRef.current = false;

    if (drawingCenter) {
      dispatch(drawingEditPushNewSnapshot({
        type: DrawingTool.Circle,
        value: {
          ...instance,
          center: drawingCenter,
        },
      }));
    }
    dispatch(drawingToolItemMoveStopped());
  }, [dispatch, drawingCenter, instance]);

  const onCircleDragMove = useCallback((_circleAreaId: Uuid, latLng: LatLng) => {
    if (isDraggingRef.current) {
      setDrawingCenter(latLng);
    }
  }, []);

  const onCircleMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onCircleMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  // clicking on map clears the selected drawing, this prevents the click event to be passed to the map
  const onCircleClick = useCallback((_: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  useEffect(() => {
    setDrawingCenter(instance.center);
  }, [instance]);

  const renderResizeController = useCallback((id: string, resizeController: MapOutlinePositionInstance) => {
    return (
      <MapOutline
        key={id}
        outline={resizeController}
        visuals={id === hoveredOutlineId ? outlineHoverVisuals : outlineVisuals}
        onDragMove={(outlineId, latLng) => onOutlineDragMove(outlineId, resizeController.position, latLng)}
        onDragEnd={onOutlineDragEnd}
        onDragStart={(outlineId) => onResizeControllerDragStart(outlineId, resizeController.position)}
        onMouseOver={onResizeControllerMouseOver}
        onMouseOut={onResizeControllerMouseOut}
      />
    );
  }, [hoveredOutlineId, onOutlineDragEnd, onResizeControllerMouseOver,
    onResizeControllerMouseOut, onOutlineDragMove, onResizeControllerDragStart]);

  return (
    <DrawingToolCircleInstanceCircleContainer
      instance={{
        id: instance.id,
        center: drawingCenter,
        placement: instance.placement,
        settings: instance.settings,
      }}
      zIndex={zIndex}
      onCircleClick={onCircleClick}
      onCircleMouseOut={onCircleMouseOut}
      onCircleMouseOver={onCircleMouseOver}
      renderOutline={renderResizeController}
      onCircleDragStart={onCircleDragStart}
      onCircleDragEnd={onCircleDragEnd}
      onCircleDragMove={onCircleDragMove}
    />
  );
};

const pureComponent = memo(DrawingToolCircleSelectedInstanceContainer);
export { pureComponent as DrawingToolCircleSelectedInstanceContainer };
