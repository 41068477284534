import { css } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import type { ReactNode } from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { type RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

const FRAME_HEIGHT_MARGIN = 26;
const FRAME_WIDTH_MARGIN = 28;

const sideOptionButtonStyle = (top: boolean, right: boolean) => css({
  position: 'absolute',
  right: right ? '0px' : 'auto',
  left: right ? 'auto' : '0px',
  top: top ? '0px' : 'auto',
  bottom: top ? 'auto' : '0px',
  boxShadow: '0px 2px 4px rgba(105, 112, 117, 50%)',
});

const sideOptionsFrameStyle = (props: ThemeProps<{imageHeight: number; imageWidth: number}>) =>
  css({
    position: 'relative',
    width: `${props.imageWidth + FRAME_WIDTH_MARGIN}px`,
    height: `${props.imageHeight + FRAME_HEIGHT_MARGIN}px`,
    background: props.theme.modalColors.contentBackground,
    color: props.theme.modalColors.contentColor,
  });

export type SideOptionsFrameIcon = Readonly<{
  icon: IconProp;
  style: RoundButtonStyle;
  callback: () => void;
}>;

export type SideOptionsFrameProps = Readonly<{
  leftTopButton?: SideOptionsFrameIcon;
  rightTopButton?: SideOptionsFrameIcon;
  leftBottomButton?: SideOptionsFrameIcon;
  rightBottomButton?: SideOptionsFrameIcon;
  width?: number;
  height?: number;
  children: ReactNode;
}>;

export const SideOptionsFrame = ({
  children,
  height = 104,
  leftBottomButton,
  leftTopButton,
  rightBottomButton,
  rightTopButton,
  width = 104,
  ...restProps
}: SideOptionsFrameProps) => {
  const theme = useTheme();

  return (
    <div
      {...restProps}
      css={sideOptionsFrameStyle({
        theme,
        imageHeight: height,
        imageWidth: width,
      })}
    >
      {children}
      {rightTopButton && (
        <RoundButtonComponent
          css={sideOptionButtonStyle(true, true)}
          icon={rightTopButton.icon}
          buttonStyle={rightTopButton.style}
          onClick={rightTopButton.callback}
          size={RoundButtonSize.Medium}
        />
      )}
      {rightBottomButton && (
        <RoundButtonComponent
          css={sideOptionButtonStyle(false, true)}
          icon={rightBottomButton.icon}
          buttonStyle={rightBottomButton.style}
          onClick={rightBottomButton.callback}
          size={RoundButtonSize.Medium}
        />
      )}
      {leftTopButton && (
        <RoundButtonComponent
          css={sideOptionButtonStyle(true, false)}
          icon={leftTopButton.icon}
          buttonStyle={leftTopButton.style}
          onClick={leftTopButton.callback}
          size={RoundButtonSize.Medium}
        />
      )}
      {leftBottomButton && (
        <RoundButtonComponent
          css={sideOptionButtonStyle(false, false)}
          icon={leftBottomButton.icon}
          buttonStyle={leftBottomButton.style}
          onClick={leftBottomButton.callback}
          size={RoundButtonSize.Medium}
        />
      )}
    </div>
  );
};
