import { css } from '@emotion/react';
import {
  faEnvelope, faLockAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useCallback, useEffect, useState,
} from 'react';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { EmailSentAlertComponent } from '~/_shared/components/emailSent/emailSentAlert.component';
import { TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { useTranslation } from '~/_shared/utils/hooks';
import authenticatorLogo from '../../../../../assets/images/2fa/2falogo.svg';
import {
  authorizationPagesAlertStyle, authorizationPagesLinkStyle,
} from '../../authorizationPages.styles';
import { AuthorizationSubmitButtonComponent } from '../../submitButton/authorizationSubmitButton.component';
import { validate2FaLoginForm } from './loginForm.helpers';
import { UserLoginErrorComponent } from './userLoginError.component';

const authenticatorLogoWrapperStyle = css({
  display: 'flex',
  margin: '64px 0 32px',
  justifyContent: 'center',
});

const labelStyle = css({
  marginBottom: 4,
});

const codeContainerStyle = css({
  margin: '10px 0 15px',
});

const alertStyle = css(authorizationPagesAlertStyle, {
  marginTop: 10,
});

const linksWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const authenicatorLogoStyle = css({
  display: 'block',
  maxWidth: 300,
  width: '120%',
  height: 'auto',
});

const resendLinkStyle = ({ isDisabled }: { isDisabled: boolean }) => css(authorizationPagesLinkStyle, {
  cursor: isDisabled ? 'not-allowed' : 'default',
});

type Login2FaFormProps = {
  error: string;
  isLoading: boolean;
  mfaMode: TwoFactorMode;

  onSubmit: (code: string) => void;
  repeatMFA?: (successCallback?: () => void) => void;
  reset2FaMode: () => void;
};

export const Login2FaFormComponent: FC<Login2FaFormProps> = (props) => {
  const [code, setCode] = useState('');
  const [t] = useTranslation();
  const [showError, setShowError] = useState(true);
  const [isRepeatSuccess, setIsRepeatSuccess] = useState(false);

  const onFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (!validate2FaLoginForm(code)) {
      return;
    }
    const onSubmit = props.onSubmit;
    onSubmit(code);
  }, [code, props.onSubmit]);

  const onReset2FaMode = useCallback(() => {
    const reset2FaMode = props.reset2FaMode;
    reset2FaMode();
  }, [props.reset2FaMode]);

  const handleRepeatMFA = useCallback(() => {
    const repeat = props.repeatMFA;

    if (props.mfaMode === TwoFactorMode.email && repeat) {
      repeat();
      setIsRepeatSuccess(true);
    }
  }, [props.mfaMode, props.repeatMFA]);

  const handleEmailSendingOver = useCallback(() => setIsRepeatSuccess(false), []);

  useEffect(() => {
    setShowError(!!props.error);
  }, [props.error]);

  useEffect(() => {
    setShowError(false);
  }, [code]);

  const isFormValid = validate2FaLoginForm(code) && !props.isLoading;

  return (
    <form onSubmit={onFormSubmit} >
      {props.mfaMode === TwoFactorMode.google && (
        <div css={authenticatorLogoWrapperStyle} >
          <img
            css={authenicatorLogoStyle}
            alt="Google Authenticator"
            src={authenticatorLogo}
          />
        </div>
      )}

      <div>
        {props.mfaMode === TwoFactorMode.email ?
          <span css={labelStyle}>{t('Please enter the code we have just sent to your E-mail address')}</span> :
          <span css={labelStyle}>{t('Please enter the one-time code found in your Google Authenticator')}</span>}

        <div css={codeContainerStyle}>
          <TextInputAuthComponent
            onChange={setCode}
            value={code}
            placeholder={t('Enter code here')}
            icon={props.mfaMode === 'email' ? faEnvelope : faLockAlt}
            isDisabled={props.isLoading}
          />
        </div>

        <AuthorizationSubmitButtonComponent
          isLoading={props.isLoading}
          isDisabled={!isFormValid}
        >
          {t('Submit')}
        </AuthorizationSubmitButtonComponent>

        {showError && (
          <UserLoginErrorComponent code={props.error} />
        )}

        <div css={linksWrapperStyle}>
          <LinkComponent
            css={authorizationPagesLinkStyle}
            onClick={onReset2FaMode}
            path=""
          >
            {t('Go to Login Page')}
          </LinkComponent>
          {props.repeatMFA && (
            <LinkComponent
              css={resendLinkStyle({ isDisabled: isRepeatSuccess })}
              disabled={isRepeatSuccess}
              onClick={handleRepeatMFA}
              path={null}
            >
              {props.mfaMode === TwoFactorMode.email ? t('Resend Email') : undefined}
            </LinkComponent>
          )}
        </div>
        {isRepeatSuccess && (
          <div css={alertStyle}>
            <EmailSentAlertComponent
              resetFlag={isRepeatSuccess}
              onAnimationEnd={handleEmailSendingOver}
            />
          </div>
        )}
      </div>
    </form>
  );
};
