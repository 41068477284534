import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { EditLocationModalComponent } from '~/_shared/components/editLocationModal/editLocationModal.component';
import {
  type ChangeableSettingRow, type SettingCheckboxData, type SettingColumn, type SettingTextData,
} from '~/_shared/components/settingsTable/settingsTable.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { getEditDataTableRowsWithColumnIdsFromMatchup } from '~/customizeLocationPanel/editLocationModal/editLocation.helpers';
import { useUpdateSpreadsheetData } from '~/data/useUpdateSpreadheetData';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  type SpreadsheetTableDataUpdateItemServerModel, type SpreadsheetTableDataUpdateRequest,
} from '~/spreadsheet/spreadsheet.repository';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetRealSpreadsheets } from '~/store/selectors/usePrimarySpreadsheetRealSpreadsheets';
import { type RowsPerSpreadsheetWithBasicData } from '../rowsPerSpreadsheet';
import { useAffectedMarkersInvisibleWarning } from '../useAffectedMarkersInvisibleWarning';

export type BulkEditLocationsDataModalContainerProps = ModalProps<{
  affectedItems: ReadonlyArray<RowsPerSpreadsheetWithBasicData>;
  useMainFilters: boolean;
}>;

export type DataTableColumn = {
  label: string;
  value: string;
  columnId: string;
  toggled: boolean;
};

const isIncluded = (row: DataTableColumn) => row.toggled;

export const BulkEditLocationsModalContainer: FC<BulkEditLocationsDataModalContainerProps> = (props) => {
  const [t] = useTranslation();
  const [columnsData, setColumnsData] = useState<DataTableColumn[]>([]);
  const clientId = useClientIdSelector();
  const matchupData = useMatchupDataSelector();
  const { updateSpreadsheetData, isLoading, isError, clearUpdateError } = useUpdateSpreadsheetData();
  const realSpreadsheetIds = usePrimarySpreadsheetRealSpreadsheets();
  const firstSpreadsheet = props.affectedItems[0];
  const { confirmAllMarkersVisible } = useAffectedMarkersInvisibleWarning();

  const columnNames: SettingColumn[] = useMemo(() => {
    return [
      {
        caption: t('Categories'),
        width: '33%',
      },
      {
        caption: t('New Value / Text'),
        width: '53%',
      },
      {
        align: 'center',
        caption: t('Batch Edit'),
        width: '14%',
      }];
  }, [t]);

  useEffect(() => {
    if (firstSpreadsheet) {
      setColumnsData(getEditDataTableRowsWithColumnIdsFromMatchup(
        matchupData, firstSpreadsheet.spreadsheetId
      ).map(data => ({ ...data, toggled: false })));
    }
  }, [matchupData, firstSpreadsheet]);

  const submitUpdate = async () => {
    if (!clientId || columnsData.length === 0 || !firstSpreadsheet?.spreadsheetId || !realSpreadsheetIds?.[0]) {
      return;
    }

    const canProceed = await confirmAllMarkersVisible(props.affectedItems, props.useMainFilters);

    if (!canProceed) {
      return;
    }

    const params: SpreadsheetTableDataUpdateItemServerModel[] = firstSpreadsheet.rows.map(row => {
      const rowId = row.id;

      return ({
        row_id: rowId,
        data: columnsData
          .filter(row => isIncluded(row))
          .reduce<{[columnId: string]: string }>((acc, row) => {
            acc[row.columnId] = row.value;
            return acc;
          }, {}),
      });
    });

    const request: SpreadsheetTableDataUpdateRequest = {
      params,
    };

    const onSuccess = () => {
      props.onClose();
    };

    updateSpreadsheetData(clientId, firstSpreadsheet.spreadsheetId, request, onSuccess);
  };

  const anyUserChanges = columnsData.some(row => isIncluded(row));

  const changeColumnData = useCallback((columnIndex: number, newRow: ChangeableSettingRow) => {
    const newRows = [...columnsData];
    const currentColumn: DataTableColumn | undefined = columnsData[columnIndex];

    if (!currentColumn) {
      return;
    }

    const previousHadValue = !!currentColumn.value;

    const enteredValue = (newRow.data[1] as SettingTextData).value;
    currentColumn.value = enteredValue;

    const isToggled = (newRow.data[2] as SettingCheckboxData).value;
    const valueErased = previousHadValue && !enteredValue;

    currentColumn.toggled = !!enteredValue || (isToggled && !valueErased);
    setColumnsData(newRows);
  }, [columnsData]);

  return (
    <EditLocationModalComponent
      {...props}
      caption={t('Edit Data')}
      columnNames={columnNames}
      errorMessage={isError ? t('An error occurred while updating the data') : undefined}
      isLoading={isLoading}
      isSubmitDisabled={!anyUserChanges || isLoading}
      onChange={changeColumnData}
      onErrorMessageClose={clearUpdateError}
      onSubmit={submitUpdate}
      rows={columnsData}
      showAddAnotherLocation={false}
      submitButtonLabel={t('Save')}
    />
  );
};
