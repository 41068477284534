import { type FC } from 'react';
import {
  Navigate,
  Route, Routes, useLocation,
} from 'react-router-dom';
import { LottieAnimationSandboxComponent } from '~/_shared/baseComponents/lottieAnimation/lottieAnimationSandbox.component';
import { CONFIG } from '~/_shared/constants/config';
import {
  ACCEPT_INVITATION_ROUTE,
  CONFIRM_EMAIL_CHANGE_ROUTE,
  DATA_ROUTE,
  FORGOTTEN_PASSWORD_ROUTE,
  HOME_ROUTE,
  IMPERSONATE_ROUTE,
  LOGIN_ROUTE,
  LOTTIE_SANDBOX_ROUTE,
  MAP_ROUTE,
  PASSWORD_RESET_ROUTE,
  REGISTER_ROUTE,
  SALESFORCE_LOGIN_ROUTE,
  SALESFORCE_REGISTER_FINISH_ROUTE,
  sandboxRoutes,
  SUBSCRIBE_ROUTE,
  SUBSCRIBED_ROUTE,
  SUBSCRIPTION_ROUTE,
  UNBLOCK_ACCOUNT_ROUTE,
  VERIFY_EMAIL_ROUTE,
} from '~/_shared/constants/routes';
import { ConfirmEmailChangePage } from '~/authorization/confirmEmailChangePage/confirmEmailChangePage';
import { ForgottenPasswordPageContainer } from '~/authorization/forgottenPassowordPage/forgottenPasswordPage.container';
import { LoginPageContainer } from '~/authorization/loginPage/loginPage.container';
import { PasswordResetPageContainer } from '~/authorization/passwordResetPage/passwordResetPage.container';
import { RegisterPageComponent } from '~/authorization/registerPage/registerPage.component';
import { UnblockAccountContainer } from '~/authorization/unblockAccount/unblockAccount.container';
import { VerifyEmailPageContainer } from '~/authorization/verifyEmailPage/verifyEmailPage.container';
import { InvitationPageContainer } from '~/clientTeamManagement/invitationPage/invitationPage.container';
import { MemberRoles } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { DataPageContainer } from '~/data/page/dataPage.container';
import { HomepageContainer } from '~/homepage/homepage.container';
import { ImpersonationStartPageContainer } from '~/impersonation/ImpersonationStartPageContainer';
import { SalesforceLoginPageContainer } from '~/salesforce/salesforceLoginPage.container';
import { SalesforceRegistrationFinishPageContainer } from '~/salesforce/salesforceRegistrationFinish/salesforceRegistrationFinishPage.container';
import { useErrorPageErrorSelector } from '~/store/errorPage/errorPage.selectors';
import { type ErrorPageType } from '~/store/errorPage/errorPage.state';
import { useIsUserSignedInSelector } from '~/store/userData/userData.selectors';
import { SubscribedPageContainer } from '~/subscription/subscribe/subscribedPage.container';
import { SubscribePageContainer } from '~/subscription/subscribe/subscribePage.container';
import { SubscriptionPageContainer } from '~/subscription/subscriptionPage.container';
import { TopBar } from '~/topBar/topBar';
import { AppErrorPageContainer } from '../pages/errorPage/appErrorPage.container';
import {
  AppNavigation, MapPageContent,
} from './appNavigation/appNavigation';
import { AppNavigationPaths } from './appNavigation/appNavigation.constants';
import { AuthorizedRoute } from './authorizedRoute';
import { LogOutRequiredRoute } from './logOutRequiredRoute';
import { PublicMapPaths } from './publicMaps/publicMaps.constants';
import { PresentationalMapRoutes } from './publicMaps/publicMapsRoutes';

export const AppDomainRoutes: FC = () => {
  const location = useLocation();
  const globalError = useErrorPageErrorSelector();

  if (globalError) {
    return <GlobalErrorRoutes error={globalError} />;
  }

  return (
    <Routes>
      {/* DEVELOPMENT ROUTES */}
      {CONFIG.MODE !== 'production' && sandboxRoutes.includes(location.pathname) && (
        <Route path={LOTTIE_SANDBOX_ROUTE} element={<LottieAnimationSandboxComponent />} />
      )}

      {/* UNAUTHORISED ROUTES */}
      <Route path={LOGIN_ROUTE} element={<LoginPageContainer />} />
      <Route path={REGISTER_ROUTE} element={<RegisterPageComponent />} />
      <Route path={FORGOTTEN_PASSWORD_ROUTE} element={<ForgottenPasswordPageContainer />} />
      <Route path={ACCEPT_INVITATION_ROUTE} element={<InvitationPageContainer />} />
      <Route path={PublicMapPaths.Base} element={<PresentationalMapRoutes />} />
      <Route path={SALESFORCE_LOGIN_ROUTE} element={<SalesforceLoginPageContainer />} />
      <Route path={SALESFORCE_REGISTER_FINISH_ROUTE} element={<SalesforceRegistrationFinishPageContainer />} />

      {/* LOGGED OUT ROUTES */}
      <Route element={<LogOutRequiredRoute />} >
        <Route path={PASSWORD_RESET_ROUTE} element={<PasswordResetPageContainer />} />
        <Route path={UNBLOCK_ACCOUNT_ROUTE} element={<UnblockAccountContainer />} />
        <Route path={VERIFY_EMAIL_ROUTE} element={<VerifyEmailPageContainer />} />
      </Route>

      {/* AUTHORISED ROUTES */}
      <Route element={<AuthorizedRoute />}>
        <Route path={CONFIRM_EMAIL_CHANGE_ROUTE} element={<ConfirmEmailChangePage />} />
        <Route path={SUBSCRIPTION_ROUTE} element={<AuthorizedRoute allowedRoles={[MemberRoles.COMPANY_OWNER]} />} >
          <Route index element={<SubscriptionPageContainer />} />
        </Route>
        <Route path={SUBSCRIBE_ROUTE} element={<AuthorizedRoute allowedRoles={[MemberRoles.COMPANY_OWNER]} />} >
          <Route index element={<SubscribePageContainer />} />
        </Route>
        <Route path={SUBSCRIBED_ROUTE} element={<SubscribedPageContainer />} />
        <Route path={IMPERSONATE_ROUTE} element={<ImpersonationStartPageContainer />} />

        {/* ROUTES WITH APP NAVIGATION */}
        <Route path={AppNavigationPaths.Base} element={<AppNavigation />} >
          <Route index element={<HomepageContainer />} />
          <Route path={AppNavigationPaths.Map} element={<MapPageContent />} />
          <Route path={AppNavigationPaths.Data} element={<DataPageContainer />} />
          <Route path="*" element={(<Navigate to={HOME_ROUTE} />)} />
        </Route>
      </Route>
    </Routes>
  );
};

const GlobalErrorRoutes = ({ error }: { error: ErrorPageType }) => {
  const isUserSignedIn = useIsUserSignedInSelector();

  const TopBarErrorRoutes = () => (
    <Routes>
      <Route path={MAP_ROUTE} element={<TopBar showUndoButton={false} />} />
      <Route path={DATA_ROUTE} element={<TopBar showUndoButton={false} />} />
      <Route path={HOME_ROUTE} element={<TopBar showUndoButton={false} />} />
    </Routes>
  );

  return (
    <>
      {isUserSignedIn && <TopBarErrorRoutes />}
      <AppErrorPageContainer error={error} isUserSignedIn={isUserSignedIn} />
    </>
  );
};
