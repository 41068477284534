import { css } from '@emotion/react';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type FormEvent, useEffect, useState,
} from 'react';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { useTranslation } from '~/_shared/utils/hooks';
import { FormErrorMessageComponent } from '../../../_shared/components/formErrorMessage/formErrorMessage.component';
import { autoCompleteDisabled } from '../../../_shared/utils/dom/dom.helpers';
import { AuthorizationSubmitButtonComponent } from '../../../authorization/submitButton/authorizationSubmitButton.component';
import { validateMapAccessPassword } from './mapPresentationalForm.helpers';

type MapPresentationalPasswordFormProps = Readonly<{
  isLoading: boolean;
  onSubmit: (password: string) => void;
  showWrongPasswordError: boolean;
}>;

const inputWrapperStyle = css({
  marginBottom: '15px',
});

export const MapPresentationalFormComponent: FC<MapPresentationalPasswordFormProps> = (props) => {
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(true);
  const [t] = useTranslation();

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!validateMapAccessPassword(password)) {
      return;
    }

    props.onSubmit(password);
  };

  const hidePasswordError = () => {
    setShowError(false);
  };

  const isFormValid = validateMapAccessPassword(password) && !props.isLoading;

  useEffect(() => {
    if (props.showWrongPasswordError) {
      setShowError(true);
    }
  }, [props.showWrongPasswordError]);

  useEffect(() => {
    setShowError(false);
  }, [password]);

  return (
    <form
      onSubmit={onFormSubmit}
    >
      <div css={inputWrapperStyle}>
        <TextInputAuthComponent
          icon={faLockAlt}
          placeholder={t('Enter Map Password')}
          type="password"
          value={password}
          onChange={setPassword}
          onFocus={hidePasswordError}
          isDisabled={props.isLoading}
          autoComplete={autoCompleteDisabled}
          hasError={showError}
        />

        {showError && (
          <FormErrorMessageComponent
            messages={[t('Wrong password')]}
          />
        )}
      </div>

      <AuthorizationSubmitButtonComponent
        isLoading={props.isLoading}
        isDisabled={!isFormValid}
      >
        {t('Sign in')}
      </AuthorizationSubmitButtonComponent>
    </form>
  );
};
