import {
  useCallback, useEffect, useState,
} from 'react';
import { type LatLng } from '../../types/latLng';
import { noop } from '../function.helpers';
import { googleLatLngToLocal } from '../geolocation/geolocation';

export const useGMapDragAndDrop = (params: Readonly<{
  map: google.maps.Map;
  startPoint: LatLng;
  onDragStart?: (latLng: LatLng) => void;
  onDragMove?: (latLng: LatLng) => void;
  onDragEnd?: () => void;
}>) => {
  const { onDragStart, onDragMove, onDragEnd, startPoint } = params;

  const [mouseDownLatLng, setMouseDownLatLng] = useState<LatLng | null>(null);
  const [startingLatLng, setStartingLatLng] = useState(startPoint);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const mouseDownListener = google.maps.event.addListener(
      params.map,
      'mousedown',
      (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          setMouseDownLatLng(googleLatLngToLocal(e.latLng));
        }
      },
    );

    return () => mouseDownListener.remove();
  }, [params.map]);

  const onMouseDown = useCallback(() => {
    onDragStart?.(startPoint);
    setIsDragging(true);
    setStartingLatLng(startPoint);
  }, [onDragStart, startPoint]);

  const onMouseUp = useCallback(() => {
    onDragEnd?.();
    setIsDragging(false);
  }, [onDragEnd]);

  useEffect(() => {
    if (isDragging) {
      const moveListener = google.maps.event.addListener(params.map, 'mousemove', (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          const targetLatLng = googleLatLngToLocal(e.latLng);
          const latLngDiff = {
            lat: targetLatLng.lat - (mouseDownLatLng?.lat || 0),
            lng: targetLatLng.lng - (mouseDownLatLng?.lng || 0),
          };
          const newLatLng = {
            lat: startingLatLng.lat + latLngDiff.lat,
            lng: startingLatLng.lng + latLngDiff.lng,
          };

          onDragMove?.(newLatLng);
        }
      });

      document.addEventListener('mouseup', onMouseUp);

      return () => {
        moveListener.remove();
        document.removeEventListener('mouseup', onMouseUp);
      };
    }

    return noop;
  }, [params.map, onDragMove, isDragging, startingLatLng, mouseDownLatLng, onMouseUp]);

  return { onMouseDown };
};
