import { useCallback } from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type MapListingItem } from '../../item/mapListingItem';
import {
  type BaseMapDeleteDependenciesResolveHelper, calculatePreviousResolutionForMap, createEmptyAlreadyResolvedMaps,
  type DeletedBaseMapsWithDependencies, type DeletedBaseMapWithDependencies, type LayeredMap, mergeAlreadyResolvedMaps, Resolution,
} from './baseMapDeleteDependenciesResolve.helper';

export const useBaseMapDeleteDependenciesResolve = (allMapListingItems: ReadonlyArray<MapListingItem>) => {
  const { openModal, closeModal } = useModal(ModalType.BaseMapDeletionConflictResolution);
  const [t] = useTranslation();

  const getLayeredMapResolutionOptions = useCallback((layeredMap: LayeredMap): DropdownOption<Resolution>[] => {
    const mapObjectForLayeredMap = allMapListingItems.find((map) => map.id === layeredMap.id);

    const isBaseMapLastOfLayered = mapObjectForLayeredMap && mapObjectForLayeredMap.layering?.baseMaps.length === 1;

    return [
      {
        value: Resolution.Delete,
        name: t('Delete Layered Map'),
      },
      {
        value: Resolution.Disconnect,
        name: t('Make Map Disconnected'),
      },
      {
        value: Resolution.RemoveLayer,
        name: t('Delete Layer'),
        isDisabled: isBaseMapLastOfLayered,
      },
    ];
  }, [allMapListingItems, t]);

  const openResolutionModalByIndex = useCallback((
    deletedBaseMaps: DeletedBaseMapsWithDependencies,
    resolvedMaps: BaseMapDeleteDependenciesResolveHelper,
    mapIndex: number,
    onSuccess: () => void
  ) => {
    const mapsTotal = deletedBaseMaps.length;
    const baseMap = deletedBaseMaps[mapIndex];
    if (!baseMap) { //should not happen, but in case it does let's silently fail
      console.error('Trying to access to-delete base map data which does not exist');
      return;
    }

    const handleSubmit = (newResolvedMaps: BaseMapDeleteDependenciesResolveHelper) => {
      closeModal();

      const noMoreMaps = mapIndex >= mapsTotal - 1;
      if (noMoreMaps) {
        onSuccess();
        return;
      }

      const allResolvedMaps = mergeAlreadyResolvedMaps(resolvedMaps, newResolvedMaps);
      const nextMapIndex = mapIndex + 1;
      openResolutionModalByIndex(deletedBaseMaps, allResolvedMaps, nextMapIndex, onSuccess);
    };

    const layeredMapsWithPreviousResolutions = baseMap.dependentLayeredMaps.map(layeredMap => ({
      id: layeredMap.id,
      name: layeredMap.name,
      previousResolution: calculatePreviousResolutionForMap(layeredMap.id, resolvedMaps),
      resolutionOptions: getLayeredMapResolutionOptions(layeredMap),
    }));

    openModal({
      mapId: baseMap.mapId,
      mapName: baseMap.name,
      mapIndex,
      mapsTotal,
      layeredMaps: layeredMapsWithPreviousResolutions,
      otherUsersUsingMap: baseMap.otherUsersMaps,
      onSubmit: handleSubmit,
    });
  }, [openModal, closeModal, getLayeredMapResolutionOptions]);

  const resolveDependencies = useCallback((deletedBaseMaps: DeletedBaseMapWithDependencies[], onSuccess: () => void) => {
    const emptyAlreadyResolvedMaps = createEmptyAlreadyResolvedMaps();
    if (deletedBaseMaps.length) {
      openResolutionModalByIndex(deletedBaseMaps, emptyAlreadyResolvedMaps, 0, onSuccess);
    }
    else {
      onSuccess();
    }
  }, [openResolutionModalByIndex]);

  return {
    resolveDependencies,
  };
};
