// The number of times a stacked marker is bigger compared to a regular marker
export const STACKED_MARKER_SIZE_MULTIPLIER = 1.3;

// Total number of labels created is 2x the limit. Marker & Above Marker labels.
// Max Labels in webgl is 5000, we leave 2000 buffer for boundaries, proximities, DC, etc...
export const MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING = 3000;

export const MARKER_SIZE_MIN = 10;
export const MARKER_SIZE_MAX = 100;

export const NUMERICAL_MARKER_SIZE_DEFAULT_MIN = 18;
export const NUMERICAL_MARKER_SIZE_DEFAULT_MAX = 68;

// These define minimum and maximum values of zoom level applied when "zoom to location" is performed.
export const ZOOM_TO_MARKER_MIN_ZOOM_LEVEL = 16;
export const ZOOM_TO_MARKER_MAX_ZOOM_LEVEL = 20;

// When clustering is enabled labels are not visible when zoomed out.
// Also their webgl entities are not cached and need to be created every time,
// unlike with marker entities that are cached and re-used.
// Creating hundreds of labels every time you zoom in generates lag.
// Only allow 1000 labels before we start filtering labels that are out of bounds.
export const CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING = 1000;

// This is used as a setting in map tools -> marker settings, in conjunction with clustering
export const UNCLUSTER_BELOW_LIMITS = {
  low: {
    markers: 2000, labels: Math.round(CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING * 0.4),
  },
  medium: {
    markers: 5000, labels: Math.round(CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING * 0.7),
  },
  high: {
    markers: 7500, labels: Math.round(CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING * 0.85),
  },
  highest: {
    markers: 10000, labels: CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING,
  },
} as const;

export type UnclusterBelowLevel = keyof typeof UNCLUSTER_BELOW_LIMITS;

export const UNCLUSTER_BELOW_LIMIT_FORCE_UNSTACK_MARKERS_THRESHOLD
  = UNCLUSTER_BELOW_LIMITS['high'].markers;

export const EXPORT_IMAGE_ALLOWED_UNCLUSTER_OPTIONS: ReadonlyArray<UnclusterBelowLevel | null> = [null, 'low', 'medium', 'high'];
export const EXPORT_IMAGE_DEFAULT_UNCLUSTER_OPTION: UnclusterBelowLevel = 'high';
