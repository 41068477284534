import { useCallback } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useMapSettingsUnstackMarkerSelector } from '~/store/mapSettings/markers/mapSettingsMarkersUnstacking.selectors';
import { type LatLngRowData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { type StackedMarker } from '../manager/mapMarkerManager';
import { selectStackedMarkers } from './selectStackedMarkers';
import {
  createStackedMarkerId, createUnstackedMarkerId, type StackedMarkerId,
} from './StackedMarkerId.type';

export const useStackedMarkers = (): ReadonlyMap<StackedMarkerId, StackedMarker> => useSelector(selectStackedMarkers);

export const useGetMarkerStackId = () => {
  const shouldStack = !useMapSettingsUnstackMarkerSelector();

  const getMarkerStackId = useCallback(
    (marker: LatLngRowData) =>
      shouldStack ? createStackedMarkerId(marker) : createUnstackedMarkerId(marker),
    [shouldStack]
  );

  return {
    getMarkerStackId,
  };
};
