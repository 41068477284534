import {
  type FC, useCallback, useMemo,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { notNull } from '~/_shared/utils/typeGuards';
import { ExportImageLegendMode } from '../../types/exportImage/exportImage';
import { useTranslation } from '../../utils/hooks';
import { OptionsDropdownComponent } from './optionsDropdown.component';

export type LegendModeSelectionProps = Readonly<{
  availableOptions: ExportImageLegendMode[];
  selectedOption: ExportImageLegendMode;

  onSelectOption: (option: ExportImageLegendMode) => void;
}>;

export const LegendModeSelectionComponent: FC<LegendModeSelectionProps> = props => {
  const [t] = useTranslation();

  const getModeName = useCallback((mode: ExportImageLegendMode) => {
    switch (mode) {
      case ExportImageLegendMode.Boundary:
        return t('boundary');
      case ExportImageLegendMode.Grouping:
        return t('Grouping');
      default:
        return '';
    }
  }, [t]);

  const legendModeOptions: DropdownOption<ExportImageLegendMode>[] = useMemo(() => (
    Object.keys(ExportImageLegendMode).map((mode: ExportImageLegendMode) =>
      props.availableOptions.includes(mode)
        ? ({
          value: ExportImageLegendMode[mode],
          name: getModeName(mode),
        })
        : null
    ).filter(notNull)
  ), [getModeName, props.availableOptions]);

  return (
    <OptionsDropdownComponent
      onSelectOption={props.onSelectOption}
      availableOptions={legendModeOptions}
      selectedOption={props.selectedOption}
      width={160}
    />
  );
};
