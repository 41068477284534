import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { type TranslationFnc } from '../../../../_shared/utils/hooks';
import { type ContextMenuItemProps } from '../../../../sidebar/sidebarApps/rightSidebar/sidebarContextMenu/sidebarContextMenu.types';
import { DeleteMarkerContextMenuStructure } from '../contextMenuStructure.enums';
import { type DeleteMarkerCallbacks } from '../contextMenuStructure.types';

const deleteMarkerStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(DeleteMarkerContextMenuStructure.DeleteMarker),
  icon: faTrash,
  onClick,
});

export const deleteMarkerContextMenuStructure = (
  callbacks: DeleteMarkerCallbacks, t: TranslationFnc
): ReadonlyArray<ContextMenuItemProps> => ([
  deleteMarkerStructure(callbacks.deleteMarkerCallback, t),
]);
