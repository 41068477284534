import {
  useCallback, useMemo,
} from 'react';
import { INITIAL_LABEL } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { MarkerSettingType } from '../../_shared/types/markers/visualSettings.enums';
import {
  type LabelVisualSetting, type TemporaryMarkerSettings,
} from '../../_shared/types/markers/visualSettings.types';
import { mergeDeep } from '../../_shared/utils/object/deepMerge';
import { type PartialTwoLevels } from '../../_shared/utils/types/deepPartial.type';
import { LabelSettingsComponent } from './labelSettings.component';
import { convertLabelVisualSettingsToData } from './labelSettings.helpers';

const DEFAULT_LABEL = 'Example';

export type LabelSettingsContainerProps = Readonly<{
  label?: string;
  labelType: MarkerSettingType.Label | MarkerSettingType.LabelAbove;
  markerVisualSettings: TemporaryMarkerSettings;

  onChange: (markerSettings: Pick<TemporaryMarkerSettings, 'aboveLabel' | 'label'>) => void;
}>;

export const LabelSettingsContainer: React.FC<LabelSettingsContainerProps> = props => {
  const { labelType, onChange } = props;
  const labelSettings = props.markerVisualSettings.label ?? INITIAL_LABEL;
  const aboveLabelSettings = props.markerVisualSettings.aboveLabel ?? INITIAL_LABEL;

  const applyChange = useCallback((current: LabelVisualSetting, update: PartialTwoLevels<LabelVisualSetting>) => {
    const newSettings: LabelVisualSetting = mergeDeep({}, current, update);

    if (labelType === MarkerSettingType.LabelAbove) {
      onChange({ aboveLabel: newSettings });
    }
    else {
      onChange({ label: newSettings });
    }
  }, [labelType, onChange]);

  const relevantSettings = labelType === MarkerSettingType.LabelAbove ? aboveLabelSettings : labelSettings;

  const convertedLabelSettingsData = useMemo(() => (
    convertLabelVisualSettingsToData(relevantSettings, applyChange)
  ), [relevantSettings, applyChange]);

  return (
    <LabelSettingsComponent
      {...props}
      label={props.label || DEFAULT_LABEL}
      labelSettingsData={convertedLabelSettingsData}
      labelVisualSetting={relevantSettings}
    />
  );
};
