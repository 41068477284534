import { css } from '@emotion/react';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const textStyle = css`
  width: 400px;
  text-align: center
`;
