import { css } from '@emotion/react';
import {
  faEnvelope, faFaceGrinWide, faFaceSunglasses, faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactElement,
  useCallback, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  FormTextInputComponent, LABEL_HEIGHT, LABEL_MARGIN_BOTTOM,
} from '~/_shared/baseComponents/inputs';
import { LottieAnimations } from '~/_shared/baseComponents/lottieAnimation';
import { getLottieAnimationDuration } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { validateEmail } from '~/_shared/utils/throttle/validation/validation.helpers';

const ERROR_MARGIN_BOTTOM = 12;
const GAP = 20;
const MAX_INPUT_WIDTH = 350;

const sectionHeaderStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  background: theme.modalColors.contentBackground,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  borderTop: `1px solid ${theme.borderColors.primary}`,
  color: theme.modalColors.contentColor,
  cursor: 'default',
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '12px 22px',
});

const newMemberSectionContentStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  background: theme.modalColors.contentBackground,
  padding: '12px 22px',
  maxWidth: MAX_INPUT_WIDTH * 2 + GAP,
});

const newMemberFormRowStyle = css({
  display: 'flex',
  gap: GAP,
  position: 'relative',
});

const labelStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
});

const buttonStyle = css({
  height: 40,
  marginTop: LABEL_HEIGHT + LABEL_MARGIN_BOTTOM,
});

const inputStyle = css({
  maxWidth: MAX_INPUT_WIDTH,
  width: '100%',
});

const buttonWrapperStyle = css(inputStyle, {
  display: 'flex',
  justifyContent: 'end',
});

const generalErrorStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  cursor: 'default',
});

type NewMemberFormComponentProps = {
  addUser?: (newUserEmail: string, newFirstName: string, newLastName: string, onSuccessCallback: () => void) => void;
  error?: string | ReactElement;
  isLoading: boolean;
};

export const NewMemberFormComponent: FC<NewMemberFormComponentProps> = (props) => {
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [firstNameErrors, setFirstNameErrors] = useState<ReadonlyArray<string>>([]);
  const [lastNameErrors, setLastNameErrors] = useState<ReadonlyArray<string>>([]);
  const [emailErrors, setEmailErrors] = useState<ReadonlyArray<string>>([]);
  const [playSuccessAnimation, setPlaySuccessAnimation] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();

  const animationColors = useLottieAnimationDefaultColors();

  const validateFirstName = useCallback(() => {
    if (isTextEmpty(newFirstName)) {
      setFirstNameErrors([t('First name cannot be empty!')]);
    }
    else {
      setFirstNameErrors([]);
    }
  }, [newFirstName, t]);

  const validateLastName = useCallback(() => {
    if (isTextEmpty(newLastName)) {
      setLastNameErrors([t('Last name cannot be empty!')]);
    }
    else {
      setLastNameErrors([]);
    }
  }, [newLastName, t]);

  const validateUserEmail = useCallback(() => {
    if (isTextEmpty(newUserEmail) || !validateEmail(newUserEmail)) {
      setEmailErrors([t('Invalid email!')]);
    }
    else {
      setEmailErrors([]);
    }
  }, [newUserEmail, t]);

  const handleAddUser = useCallback(() => {
    validateFirstName();
    validateLastName();
    validateUserEmail();
    if (
      newFirstName && !firstNameErrors.length &&
      newLastName && !lastNameErrors.length &&
      newUserEmail && !emailErrors.length
    ) {
      const addUser = props.addUser;
      addUser?.(newUserEmail, newFirstName, newLastName, () => {
        setNewUserEmail('');
        setNewLastName('');
        setNewFirstName('');
        setPlaySuccessAnimation(true);
      });
    }
  }, [
    props.addUser, emailErrors, firstNameErrors, lastNameErrors, newFirstName, newLastName, newUserEmail,
    validateFirstName, validateLastName, validateUserEmail,
  ]);

  useEffect(() => {
    if (!playSuccessAnimation) {
      return;
    }

    const animationDuration = getLottieAnimationDuration(LottieAnimationTypes.Success);
    const timeoutId = setTimeout(() => {
      setPlaySuccessAnimation(false);
    }, animationDuration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [playSuccessAnimation]);

  return (
    <OverlayWrapperComponent>
      <div css={sectionHeaderStyle({ theme })}>
        {t('Add a New Team Member')}
      </div>
      <div css={newMemberSectionContentStyle({ theme })}>
        {props.isLoading && (
          <OverlayLottieAnimationComponent
            colors={animationColors.People}
            type={LottieAnimationTypes.People}
            size={80}
            autoplay
            bothDirections
            loop
            segment={LottieAnimations[LottieAnimationTypes.People].segments.visible}
          />
        )}
        {!props.isLoading && playSuccessAnimation && (
          <OverlayLottieAnimationComponent
            colors={animationColors.Success}
            type={LottieAnimationTypes.Success}
            size={80}
            autoplay
          />
        )}

        <div css={newMemberFormRowStyle}>
          <div css={inputStyle}>
            <FormTextInputComponent
              errorMessages={firstNameErrors}
              icon={faFaceGrinWide}
              isDisabled={!props.addUser}
              label={t('First Name')}
              labelStyle={labelStyle({ theme })}
              marginBottom={ERROR_MARGIN_BOTTOM}
              onBlur={validateFirstName}
              onChange={setNewFirstName}
              placeholder={t('First Name')}
              value={newFirstName}
            />
          </div>
          <div css={inputStyle}>
            <FormTextInputComponent
              errorMessages={lastNameErrors}
              icon={faFaceSunglasses}
              isDisabled={!props.addUser}
              label={t('Last Name')}
              labelStyle={labelStyle({ theme })}
              marginBottom={ERROR_MARGIN_BOTTOM}
              onBlur={validateLastName}
              onChange={setNewLastName}
              placeholder={t('Last Name')}
              value={newLastName}
            />
          </div>
        </div>

        <div css={newMemberFormRowStyle}>
          <div css={inputStyle}>
            <FormTextInputComponent
              autoTrim
              errorMessages={emailErrors}
              icon={faEnvelope}
              isDisabled={!props.addUser}
              label={t('Email Address')}
              labelStyle={labelStyle({ theme })}
              marginBottom={ERROR_MARGIN_BOTTOM}
              onBlur={validateUserEmail}
              onChange={setNewUserEmail}
              placeholder={t('Email Address')}
              value={newUserEmail}
            />
          </div>
          <div css={buttonWrapperStyle}>
            <ButtonComponent
              css={buttonStyle}
              isDisabled={!props.addUser}
              onClick={handleAddUser}
              prefixIcon={faPlus}
              text={t('Add User')}
            />
          </div>
        </div>

        {props.error && (
          <div css={generalErrorStyle({ theme })}>
            {props.error}
          </div>
        )}
      </div>
    </OverlayWrapperComponent>
  );
};
