import { css } from '@emotion/react';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import {
  BoundaryEditManualMethod,
  BoundaryEditSelectFrom,
  getBoundaryEditManualMethodOptions,
  getBoundaryEditSelectFromRadioGroupOptions,
} from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import {
  BoundaryTerritoryType,
  type CalculateBucketFunctionNumeric,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { getLabelsCountForBoundaryTerritoryType } from '../../activeBoundary/edit/activeBoundaryFill.helpers';
import { ActiveBoundaryFillEditComponent } from '../../activeBoundary/edit/activeBoundaryFillEditComponent';
import { useActiveBoundaryFillEdit } from '../../activeBoundary/edit/useActiveBoundaryFillEdit';
import { LoadBoundaryPaneSubmitButtonComponent } from '../loadBoundaryPaneSubmitButton/boundaryCreateSubmitButton.component';
import { getCreateCustomBoundaryModeOptions } from './createCustomBoundaryGroupPane.helpers';
import { useCreateBoundaryGroupFromAIWithSubscriptionCheck } from './useCreateBoundaryGroupFromAIWithSubscriptionCheck';

type CreateCustomBoundaryGroupPaneProps = Readonly<{
  labelCountStart: number;
  onSubmit: (results: CreateCustomBoundaryGroupResults) => void;
  globalBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  customBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  isLoading: boolean;
  mode: CreateCustomBoundaryMode | null;
  setMode: (mode: CreateCustomBoundaryMode | null) => void;
}>;

export type CreateCustomBoundaryGroupResults = CreateCustomBoundaryGroupResultsManual | CreateCustomBoundaryGroupResultsFromData | CreateCustomBoundaryGroupResultsFromAI;

type CreateCustomBoundaryGroupResultsManual = CreateCustomBoundaryGroupResultsManualSelection | CreateCustomBoundaryGroupResultsManualDraw;

type CreateCustomBoundaryGroupResultsManualSelection = {
  type: CreateCustomBoundaryMode.Manual;
  manualMethod: BoundaryEditManualMethod.Selection;
  selectedBoundaryGroupId: number;
  groupName: string;
};

type CreateCustomBoundaryGroupResultsManualDraw = {
  type: CreateCustomBoundaryMode.Manual;
  manualMethod: BoundaryEditManualMethod.Draw;
  groupName: string;
};

type CreateCustomBoundaryGroupResultsFromData = {
  type: CreateCustomBoundaryMode.FromData;
  groupName: string;
  selectedBoundaryGroupId: number;
  boundaryTerritoryType: BoundaryTerritoryType;
  boundaryFillData: SpreadsheetColumnId | null;
  demographicId: string | null;
  numericCalculateBucketFunction: CalculateBucketFunctionNumeric;
};

type CreateCustomBoundaryGroupResultsFromAI = {
  type: CreateCustomBoundaryMode.FromAI;
  groupName: string;
};

export enum CreateCustomBoundaryMode {
  Manual,
  FromData,
  FromAI,
}

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const territoryGroupNameStyle = ({ theme, isPlaceholder }: ThemeProps<{ isPlaceholder: boolean }>) => css({
  height: 40,
  backgroundColor: theme.backgroundColors.secondary,
  color: isPlaceholder ? theme.textColors.disabled : undefined,
});

const radioGroupStyle = css({
  display: 'flex',
  flexDirection: 'row',
  margin: '10px 0 15px',
  padding: '0 15px',
});

const radioItemStyle = css({
  fontSize: '12px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

const radioIconStyle = (isActive: boolean) => css({
  width: 15,
  height: 15,
  borderWidth: isActive ? 3 : 2,
});

const addBoundaryTooltipStyle = css({
  display: 'block',
});

const excludedBoundaryTerritoryTypes = [BoundaryTerritoryType.Manual];

export const CreateCustomBoundaryGroupPaneComponent: FC<CreateCustomBoundaryGroupPaneProps> = ({ mode, setMode, ...props }) => {
  const [groupName, setGroupName] = useState('');
  const [manualMethod, setManualMethod] = useState<BoundaryEditManualMethod | null>(null);
  const [selectFrom, setSelectFrom] = useState(BoundaryEditSelectFrom.Boundary);
  const [selectedGlobalBoundaryGroupId, setSelectedGlobalBoundaryGroupId] = useState<number | null>(null);
  const [selectedCustomBoundaryGroupId, setSelectedCustomBoundaryGroupId] = useState<number | null>(null);
  const selectedBoundaryGroupId: number | null = selectFrom === BoundaryEditSelectFrom.Boundary ? selectedGlobalBoundaryGroupId : selectedCustomBoundaryGroupId;
  const { createBoundaryGroupFromAI } = useCreateBoundaryGroupFromAIWithSubscriptionCheck();

  const {
    isUpdateDisabled: fillModelInvalid, boundaryFillModel, ...boundaryFillProps
  } = useActiveBoundaryFillEdit({
    boundaryGroupId: selectedBoundaryGroupId,
    hiddenBoundaryTerritoryTypeOptions: excludedBoundaryTerritoryTypes,
  });
  const [t] = useTranslation();
  const theme = useTheme();

  const isSubmitDisabled = props.isLoading ||
    (mode === CreateCustomBoundaryMode.Manual && isTextEmpty(groupName)) ||
    (mode === CreateCustomBoundaryMode.FromData && isTextEmpty(groupName)) ||
    (mode === CreateCustomBoundaryMode.FromData && fillModelInvalid);

  const showNameTerritoryGroupStep =
    (mode === CreateCustomBoundaryMode.Manual && manualMethod === BoundaryEditManualMethod.Draw) ||
    (mode === CreateCustomBoundaryMode.Manual && manualMethod === BoundaryEditManualMethod.Selection && selectedBoundaryGroupId) ||
    (mode === CreateCustomBoundaryMode.FromData && !fillModelInvalid);

  const submitDisabledReason = mode === CreateCustomBoundaryMode.FromData ? boundaryFillProps.updateDisabledReason : null;

  const onSubmit = () => {
    if (isTextEmpty(groupName) || mode === null || manualMethod === null) {
      return;
    }
    if (mode === CreateCustomBoundaryMode.Manual) {
      switch (manualMethod) {
        case BoundaryEditManualMethod.Selection:
          if (selectedBoundaryGroupId === null) {
            break;
          }
          props.onSubmit({
            type: mode,
            groupName,
            manualMethod,
            selectedBoundaryGroupId,
          });
          break;
        case BoundaryEditManualMethod.Draw:
          props.onSubmit({
            type: mode,
            groupName,
            manualMethod,
          });
          break;
        default:
      }
    }
    else if (mode === CreateCustomBoundaryMode.FromData) {
      if (boundaryFillModel.boundaryTerritoryType === null || boundaryFillModel.numericCalculateBucketFunction === null || selectedBoundaryGroupId === null) {
        return;
      }
      props.onSubmit({
        type: mode,
        groupName,
        selectedBoundaryGroupId,
        numericCalculateBucketFunction: boundaryFillModel.numericCalculateBucketFunction,
        demographicId: boundaryFillModel.selectedDemographicId,
        boundaryFillData: boundaryFillModel.boundaryFillData,
        boundaryTerritoryType: boundaryFillModel.boundaryTerritoryType,
      });
    }
  };

  let labelCount = props.labelCountStart;

  return (
    <div>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>
          {labelCount++}. {t('Create Territories')}
        </div>

        <RegularDropdownComponent
          onChange={setMode}
          options={getCreateCustomBoundaryModeOptions(t)}
          value={mode}
          inPortal
          placeholder={t('Select Method')}
        />
      </div>

      {mode === CreateCustomBoundaryMode.Manual && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t('Manual Method')}
          </div>

          <RegularDropdownComponent
            onChange={setManualMethod}
            options={getBoundaryEditManualMethodOptions(t)}
            value={manualMethod}
            inPortal
            placeholder={t('Select Method')}
          />
        </div>
      )}

      {(mode === CreateCustomBoundaryMode.FromData || (mode === CreateCustomBoundaryMode.Manual && manualMethod === BoundaryEditManualMethod.Selection)) && (
        <>
          <div css={panelDropdownSectionStyle}>
            <div css={labelStyle}>
              {labelCount++}. {t('Select From')}
            </div>

            <RadioGroupComponent
              css={radioGroupStyle}
              itemCommonStyle={radioItemStyle}
              radioIconStyle={radioIconStyle}
              onValueChange={(value: BoundaryEditSelectFrom) => setSelectFrom(value)}
              selectedValue={selectFrom}
              items={getBoundaryEditSelectFromRadioGroupOptions(t, true)}
            />
          </div>

          {manualMethod && selectFrom === BoundaryEditSelectFrom.Boundary && (
            <div css={panelDropdownSectionStyle}>
              <div css={labelStyle}>
                {labelCount++}. {t('Select Boundary Type')}
              </div>

              <RegularDropdownComponent
                onChange={setSelectedGlobalBoundaryGroupId}
                options={props.globalBoundaryGroups.map(group => ({
                  name: group.name,
                  value: group.id,
                }))}
                value={selectedGlobalBoundaryGroupId}
                inPortal
                placeholder={t('Select Boundary Type')}
              />
            </div>
          )}

          {selectFrom === BoundaryEditSelectFrom.Territory && (
            <div css={panelDropdownSectionStyle}>
              <div css={labelStyle}>
                {labelCount++}. {t('Select Territory Group')}
              </div>

              <AutocompleteComponent
                onChange={setSelectedCustomBoundaryGroupId}
                options={props.customBoundaryGroups.map(group => ({
                  name: group.name,
                  value: group.id,
                }))}
                value={selectedCustomBoundaryGroupId}
                inPortal
                placeholder={t('Select Territory Group')}
                isClearable
              />
            </div>
          )}

          {mode === CreateCustomBoundaryMode.FromData && (
            <ActiveBoundaryFillEditComponent
              {...boundaryFillModel}
              {...boundaryFillProps}
              labelCountStart={labelCount}
            />
          )}
        </>
      )}

      {showNameTerritoryGroupStep && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {mode === CreateCustomBoundaryMode.Manual ? labelCount : labelCount + getLabelsCountForBoundaryTerritoryType(boundaryFillModel.boundaryTerritoryType)}
            . {t('Name Territory Group')}
          </div>

          <TextInputComponent
            css={territoryGroupNameStyle({ theme, isPlaceholder: groupName.length === 0 })}
            value={groupName}
            onChange={setGroupName}
            type="text"
            placeholder={t('Territory Group Name')}
            icon={faGlobe}
          />
        </div>
      )}

      <TooltipDeprComponent
        css={addBoundaryTooltipStyle}
        tooltipContent={submitDisabledReason}
        placement="bottom"
      >
        {
          mode === CreateCustomBoundaryMode.FromAI ? (
            <LoadBoundaryPaneSubmitButtonComponent
              text={t('Continue')}
              isDisabled={isSubmitDisabled}
              onClick={createBoundaryGroupFromAI}
            />
          ) : (
            <LoadBoundaryPaneSubmitButtonComponent
              text={t('Create Territory Group')}
              isDisabled={isSubmitDisabled}
              onClick={onSubmit}
            />
          )}
      </TooltipDeprComponent>
    </div>
  );
};
