import { createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import {
  type MetricServerModel,
  updateBoundaryTerritoryGroup,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { type BoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const updateBTGMetric = (
  clientId: number,
  btg: BoundaryTerritoryGroup,
  metrics: MetricServerModel[],
) => {
  try {
    const serverBTG = createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup(btg);

    const serverBTGWithMetrics = {
      ...serverBTG,
      settings: {
        ...serverBTG.settings,
        metrics,
      },
    };

    return updateBoundaryTerritoryGroup(
      clientId,
      btg.boundaryTerritoryGroupId,
      serverBTGWithMetrics,
    );
  }
  catch (e) {
    console.error(e);
  }

  return Promise.resolve();
};
