import { css } from '@emotion/react';
import { type FC } from 'react';

const circleStyle = ({ colors, size }: { size: number; colors: ColorProps }) => css({
  alignItems: 'center',
  background: colors.fillColor,
  borderRadius: '50%',
  color: colors.fontColor,
  cursor: 'default',
  display: 'inline-flex',
  flexShrink: 0,
  height: size,
  justifyContent: 'center',
  lineHeight: `${size}px`,
  width: size,
});

type ColorProps = Readonly<{
  fontColor: string;
  fillColor: string;
}>;

export type NumberInCircleProps = Readonly<{
  children: number;
  className?: string;
  colors: ColorProps;
  size: number;
}>;

export const NumberInCircleComponent: FC<NumberInCircleProps> = props => {
  return (
    <div
      className={props.className}
      css={circleStyle({ colors: props.colors, size: props.size })}
    >
      {props.children}
    </div>
  );
};
