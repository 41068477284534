import type { Uuid } from '~/_shared/utils/createUuid';
import { type SearchItem } from '../../../map/search/mapSearch.helpers';
import {
  MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM,
  MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ITEM,
  MAP_SETTINGS_SEARCH_ITEMS_UPDATE_ITEMS,
} from './mapSettingsSearchItems.actionTypes';

export const mapSettingsSearchItemsAddItem = (item: SearchItem) => ({
  type: MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM,
  payload: {
    item,
  },
}) as const;

export const mapSettingsSearchItemsUpdateItems = (updatedItems: ReadonlyArray<SearchItem>) => ({
  type: MAP_SETTINGS_SEARCH_ITEMS_UPDATE_ITEMS,
  payload: {
    updatedItems,
  },
}) as const;

export const mapSettingsSearchItemsRemoveItem = (id: Uuid) => ({
  type: MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ITEM,
  payload: {
    id,
  },
}) as const;

export const mapSettingsSearchItemsRemoveAllItems = () => ({
  type: MAP_SETTINGS_SEARCH_ITEMS_REMOVE_ALL_ITEMS,
}) as const;
