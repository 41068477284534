import { noop } from './function.helpers';
import { type CancellablePromise } from './types/common.type';

type PromiseCallback<T = unknown> = ((value: T) => T) | null | undefined;

export const cancalledPromiseError = 'Cancallable promise was manually cancelled. No need to worry.';

function makeCancelable<T>(thePromise: Promise<T>, onCancel: () => void): CancellablePromise<T> {
  const originalThen = thePromise.then;

  return Object.assign(thePromise, {
    cancel() {
      onCancel();
    },
    then(success: PromiseCallback<T>, fail: PromiseCallback) {
      return makeCancelable(originalThen.call(thePromise, success, fail), onCancel);
    },
  });
}

export function delay(duration: number): CancellablePromise<void> {
  let timeout: any = -1;
  let performCancellation: () => void = noop;
  const thePromise = new Promise<void>((resolve, reject) => {
    // We can't use window.setTimeout here, because that doesn't work in tests
    timeout = setTimeout(resolve, duration);

    performCancellation = () => {
      clearTimeout(timeout);
      reject(cancalledPromiseError);
    };
  });

  return makeCancelable(thePromise, performCancellation);
}
