import { css } from '@emotion/react';
import {
  animated, useSpring,
} from '@react-spring/web';
import React, {
  type FC, useCallback, useEffect, useImperativeHandle, useMemo,
} from 'react';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';

const wrapperStyle = (props: ThemeProps<{ isOpen: boolean }>) => css({
  display: 'flex',
  borderLeft: props.isOpen ? `1px solid ${props.theme.lineColors.basePrimary}` : '',
  position: 'relative',
});

const sidebarContentStyle = (width: number, isMobileScreen: boolean) => css({
  zIndex: 0,
  height: '100%',
  width: isMobileScreen ? '100%' : width,
});

const slidingOverlayStyle = (props: ThemeProps) => css({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  backgroundColor: props.theme.backgroundColors.primary,
});

export type RightSidebarAnimationControllerMethods = Readonly<{
  playSlideOutAnimation: () => void;
}>;

type RightSidebarProps = Readonly<{
  animationControllerRef?: React.Ref<RightSidebarAnimationControllerMethods>;
  children?: React.ReactElement;
  isMobileScreen: boolean;
  slidingAnimationDuration: number;
  width: number;
}>;

export const RightSidebarComponent: FC<RightSidebarProps> = (props) => {
  const theme = useTheme();
  const animationConfig = useMemo(() => ({ duration: props.slidingAnimationDuration, clamp: true }), [props.slidingAnimationDuration]);

  const getPaneAnimationConfig = useCallback((width: number, isMobileScreen: boolean) => ({
    width: isMobileScreen ? '100%' : width,
    overflow: isMobileScreen ? 'unset' : 'clip',
    config: animationConfig,
  }), [animationConfig]);

  const getOverlaySlideOutAnimationConfig = useCallback((width: number) => ({
    width,
    config: animationConfig,
  }), [animationConfig]);

  const [paneSlideOutAnimationStyle, paneSlideOutAnimationStyleApi] = useSpring(() => getPaneAnimationConfig(0, props.isMobileScreen));
  const [overlaySlideOutAnimationStyle, overlaySlideOutAnimationStyleApi] = useSpring(() => getOverlaySlideOutAnimationConfig(0));

  useImperativeHandle(props.animationControllerRef, () => ({
    playSlideOutAnimation: () => {
      overlaySlideOutAnimationStyleApi.set({ width: props.width });
      overlaySlideOutAnimationStyleApi.start(getOverlaySlideOutAnimationConfig(0));
    },
  }), [getOverlaySlideOutAnimationConfig, props.width, overlaySlideOutAnimationStyleApi]);

  useEffect(() => {
    paneSlideOutAnimationStyleApi.start(getPaneAnimationConfig(props.width, props.isMobileScreen));
  }, [paneSlideOutAnimationStyleApi, props.width, props.isMobileScreen, getPaneAnimationConfig]);

  return (
    <div css={wrapperStyle({ theme, isOpen: props.width > 0 })}>
      <animated.div style={paneSlideOutAnimationStyle}>
        <div css={sidebarContentStyle(props.width, props.isMobileScreen)} >
          {props.children}
        </div>
        <animated.div
          css={slidingOverlayStyle({ theme })}
          style={overlaySlideOutAnimationStyle}
        />
      </animated.div>
    </div>
  );
};
