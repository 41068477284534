import { StreetViewStatus } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  type StreetViewLocation, type StreetViewState,
} from '../../store/frontendState/mapTools/streetView/streetView.state';

export enum StreetViewUpdateStatus {
  NOOP = 'NOOP',
  OK = 'OK',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

const streetViewUIOptions = {
  fullscreenControl: false,
  addressControl: false,
  enableCloseButton: false,
};

const streetViesStatusToUpdateStatus = (status: StreetViewStatus | undefined) => {
  switch (status) {
    case undefined:
      return StreetViewUpdateStatus.NOOP;
    case StreetViewStatus.OK:
      return StreetViewUpdateStatus.OK;
    case StreetViewStatus.ZERO_RESULTS:
      return StreetViewUpdateStatus.ZERO_RESULTS;
    default:
      return StreetViewUpdateStatus.UNKNOWN_ERROR;
  }
};

export class StreetViewManager {
  private readonly map: google.maps.Map;
  private streetView: StreetViewState;

  constructor(map: google.maps.Map) {
    this.map = map;
    this.streetView = { streetViewLocation: null, active: false };
  }

  updateStreetView(streetView: StreetViewState): StreetViewUpdateStatus {
    if (this.streetView === streetView) {
      return StreetViewUpdateStatus.NOOP;
    }

    let status = StreetViewUpdateStatus.OK;

    if (!streetView.active) {
      const panorama = this.map.getStreetView();
      status = streetViesStatusToUpdateStatus(panorama.getStatus());
      panorama.setVisible(false);
    }
    else if (streetView.streetViewLocation && this.streetView.streetViewLocation !== streetView.streetViewLocation) {
      this.openStreetView(streetView.streetViewLocation);
    }
    else {
      // street view without location
      // was opened directly via google map ui elements
      this.updateStreetViewUI();
    }

    this.streetView = streetView;
    return status;
  }

  openStreetView(streetViewLocation: StreetViewLocation) {
    const panorama = this.map.getStreetView();
    const panoOptions = {
      position: streetViewLocation.position,
      pov: streetViewLocation.pov ?? { heading: 34, pitch: 10 },
      ...streetViewUIOptions,
    };

    panorama.setOptions(panoOptions);
    panorama.setVisible(true);
  }

  updateStreetViewUI() {
    const panorama = this.map.getStreetView();
    const panoOptions = {
      ...streetViewUIOptions,
    };

    panorama.setOptions(panoOptions);
  }
}
