import { type LatLng } from '../../types/latLng';

export const MAX_GOOGLE_LAT_LONG_DECIMALS = 10; //The max number of decimals returned by Google for lat long is 14 but 10 is mostly used

export const getCenterBetweenLatLngPositions = (position1: LatLng, position2: LatLng): LatLng => ({
  lat: (position1.lat + position2.lat) / 2,
  lng: (position1.lng + position2.lng) / 2,
});

export const areLatLngEqual = (latLng1: LatLng, latLng2: LatLng, precisionDecimals: number = 6) => (
  latLng1.lat.toFixed(precisionDecimals) === latLng2.lat.toFixed(precisionDecimals)
    && latLng1.lng.toFixed(precisionDecimals) === latLng2.lng.toFixed(precisionDecimals)
);

export const areLatLngPartiallyEqual = (latLng1: LatLng, latLng2: LatLng, precisionDecimals: number = MAX_GOOGLE_LAT_LONG_DECIMALS) => (
  latLng1.lat.toFixed(precisionDecimals) === latLng2.lat.toFixed(precisionDecimals)
    || latLng1.lng.toFixed(precisionDecimals) === latLng2.lng.toFixed(precisionDecimals)
);

/**
 * Extracts the latitude and longitude from a given record.
 *
 * @param record - An object that contains latitude and longitude properties, along with any other properties.
 * @returns An object containing only the latitude (`lat`) and longitude (`lng`) properties.
 */
export const extractLatLng = (record: LatLng & Record<string, any>): LatLng => ({ lat: record.lat, lng: record.lng });
