import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type MapId } from '~/_shared/types/map';
import {
  type ColumnName, type PerColumn,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type SpreadsheetMapInfo } from '~/map/listing/item/mapListingItem';

export type ColumnNamePerColumnId = PerColumn<ColumnName | null>;
export type ColumnNamePerMapId = Record<MapId, ColumnName | null>;

export type Row = Readonly<{
  selectedSourceColumnPerMap: ColumnNamePerMapId;
  targetColumnName: ColumnName | null;
  isRemovable: boolean;
}>;

export type RowWithExtraData = Readonly<Row & {
  availableTargetColumnNames: DropdownOption<string | null>[];
  onlyOneSourceMap: boolean;
}>;

export type IntersectsMapItem = {
  id: number;
  name: string;
  isCurrentMap: boolean;
  isDisabled: boolean;
  realSpreadsheetId?: number;
  spreadSheets?: ReadonlyArray<{
    spreadsheetId: number;
    isPrimary: boolean;
    realSpreadsheets: ReadonlyArray<{ realSpreadsheetId: number }>;
  }>;
};

export function findAnotherIndex<T>(input: T[], findFnc: (value: T) => boolean, notThisIndex: number): number {
  return input.findIndex((value, index) => findFnc(value) && index !== notThisIndex);
}

export const getAnotherRowWithSameColumnIndex = (rows: Row[], value: string, updatedRowIndex: number, mapId: number) => {
  return findAnotherIndex(rows, (row) => row.selectedSourceColumnPerMap?.[mapId] === value, updatedRowIndex);
};

export const getRowUsedOtherData = (rows: Row[], value: string, updatedRowIndex: number) => {
  return findAnotherIndex(rows, (row) => row.targetColumnName === value, updatedRowIndex);
};

const replaceRow = (rows: Row[], index: number, newValueFunction: (prev: Row) => Row): Row[] => {
  const row = rows[index];

  if (!row) {
    return rows;
  }

  return [
    ...rows.slice(0, index),
    newValueFunction(row),
    ...rows.slice(index + 1),
  ];
};

const createChangeSelectedColumnFunction = (mapId: number, selectedColumn: string | null) => (row: Row): Row => ({
  ...row,
  selectedSourceColumnPerMap: {
    ...row.selectedSourceColumnPerMap,
    [mapId]: selectedColumn,
  },
  targetColumnName: null,
});

const createClearSelectedColumnFunction = (mapId: number) => (row: Row): Row => ({
  ...row,
  selectedSourceColumnPerMap: {
    ...row.selectedSourceColumnPerMap,
    [mapId]: null,
  },
}
);

const clearTargetColumn = (row: Row) => ({
  ...row,
  targetColumnName: null,
});

const createFixTargetColumnFunction = (removedSourceColumn: string | null) => (row: Row): Row => {
  const wasUsedSourceColumnRemoved = row.targetColumnName === removedSourceColumn;
  if (!wasUsedSourceColumnRemoved) {
    return row;
  }

  return clearTargetColumn(row);
};

const resolveConflictBetweenRows = (rows: Row[], conflictingColumn: string, index: number,
  mapId: number, selectedColumn: string | null) => {
  const conflictingRowIndex = getAnotherRowWithSameColumnIndex(rows, conflictingColumn, index, mapId);
  const isConflict = conflictingRowIndex !== -1;
  if (!isConflict) {
    return rows;
  }

  const rowsWithoutDuplicateUsage = replaceRow(rows, conflictingRowIndex, createClearSelectedColumnFunction(mapId));
  return replaceRow(rowsWithoutDuplicateUsage, conflictingRowIndex, createFixTargetColumnFunction(selectedColumn));
};

const removeRowsWithAllNoneOptions = (rows: Row[]): Row[] => {
  return rows.filter(row => !Object.values(row.selectedSourceColumnPerMap).every(c => !c));
};

const addMissingSourceColumnsToRows = (
  rows: Row[],
  sourceColumnsPerMap: Record<number, DropdownOption<string | null>[]>,
  maps: ReadonlyArray<{
    id: number;
    name: string;
  }>
): Row[] => {
  const missingSourceColumnsRows: Row[] = maps
    .map(map => {
      // For each map's columns
      return sourceColumnsPerMap[map.id]
        // remove the None options
        ?.filter(column => column.value !== null)
        // Check for those columns that are not listed in any rows
        .filter(column =>
          !rows.some(row =>
            row.selectedSourceColumnPerMap[map.id] === column.name
          )
        )
        // Convert them in new rows
        .map(column => {
          const newSelectedOptionPerMap: ColumnNamePerMapId = createRecordWithDefaultForMaps(maps, null);
          newSelectedOptionPerMap[map.id] = column.name;

          return {
            targetColumnName: null,
            selectedSourceColumnPerMap: newSelectedOptionPerMap,
            isRemovable: true,
          };
        });
    })
    .filter(notNullsy)
    .reduce((newRows, rows) => [...newRows, ...rows], []);

  return [
    ...rows,
    ...missingSourceColumnsRows,
  ];
};

const isMaybeDuplicateColumnUsage = (selectedColumn: string | null): selectedColumn is string => {
  return !!selectedColumn;
};

export const selectColumn = (
  rows: Row[], selectedColumn: string | null, mapId: number, index: number,
  sourceColumnsPerMap: Record<number, DropdownOption<string | null>[]>,
  maps: ReadonlyArray<{
    id: number;
    name: string;
  }>
): Row[] => {
  const changeSelectedColumnFunction = createChangeSelectedColumnFunction(mapId, selectedColumn);
  const rowsWithUpdatedColumn = replaceRow(rows, index, changeSelectedColumnFunction);

  if (!isMaybeDuplicateColumnUsage(selectedColumn)) {
    return addMissingSourceColumnsToRows(removeRowsWithAllNoneOptions(rowsWithUpdatedColumn), sourceColumnsPerMap, maps);
  }

  const rowsWithUniqueColumnsUsage = resolveConflictBetweenRows(rowsWithUpdatedColumn, selectedColumn, index, mapId, selectedColumn);

  return addMissingSourceColumnsToRows(removeRowsWithAllNoneOptions(rowsWithUniqueColumnsUsage), sourceColumnsPerMap, maps);
};

export const addNewRow = (rows: Row[], maps: ReadonlyArray<{
  id: number;
  name: string;
}>): Row[] => {
  const emptySelectedOptionPerMap: ColumnNamePerMapId = {};
  for (const map of maps) {
    emptySelectedOptionPerMap[map.id] = null;
  }
  return [...rows, { targetColumnName: null, selectedSourceColumnPerMap: emptySelectedOptionPerMap, isRemovable: true }];
};

export const removeRow = (
  rows: Row[], index: number,
  sourceColumnsPerMap: Record<number, DropdownOption<string | null>[]>,
  maps: IntersectsMapItem[]
) => {
  return addMissingSourceColumnsToRows(
    [...rows.slice(0, index), ...rows.slice(index + 1)],
    sourceColumnsPerMap,
    maps
  );
};

export const getMapIdFromPrimarySpreadsheetId = (mapList: IntersectsMapItem[], spreadsheetId: number) => {
  for (const map of mapList) {
    const spreadsheets = map.spreadSheets || [];
    const spreadsheetIds = spreadsheets.map(spreadsheet => spreadsheet.spreadsheetId);
    if (spreadsheetIds.includes(spreadsheetId)) {
      return map.id;
    }
  }
  return null;
};

export const getMapFromRealSpreadsheetId = (mapList: IntersectsMapItem[], spreadsheetId: number): IntersectsMapItem | null => {
  for (const map of mapList) {
    if (map.isCurrentMap) {
      if (map.realSpreadsheetId === spreadsheetId) {
        return map;
      }
    }
    else {
      const spreadsheets = map.spreadSheets || [];
      let allSpreadsheetIds: number[] = [];
      spreadsheets.forEach(spreadsheet => {
        const realSpreadsheets = spreadsheet.realSpreadsheets;
        realSpreadsheets.forEach((item) => {
          allSpreadsheetIds = [...allSpreadsheetIds, item.realSpreadsheetId];
        });
      });
      if (allSpreadsheetIds.includes(spreadsheetId)) {
        return map;
      }
    }
  }
  return null;
};

export const getSpreadsheetIdFromMapId = (mapList: IntersectsMapItem[], mapId: number) => {
  for (const map of mapList) {
    if (map.id === mapId) {
      const spreadsheets = map.spreadSheets || [];
      const primarySpreadsheet = spreadsheets.filter((spreadsheet: SpreadsheetMapInfo) => spreadsheet.isPrimary);
      return primarySpreadsheet?.[0]?.spreadsheetId || null;
    }
  }
  return null;
};

export const getRealSpreadsheetIdFromMapId = (mapList: IntersectsMapItem[], mapId: number) => {
  for (const map of mapList) {
    if (map.id === mapId) {
      const spreadsheets = map.spreadSheets || [];
      const primarySpreadsheet = spreadsheets.filter((spreadsheet: SpreadsheetMapInfo) => spreadsheet.isPrimary);
      if (primarySpreadsheet.length && primarySpreadsheet[0]?.realSpreadsheets.length) {
        return primarySpreadsheet[0]?.realSpreadsheets[0]?.realSpreadsheetId;
      }
      return null;
    }
  }
  return null;
};

export const getPrimarySpreadsheetIdFromMapId = (mapList: IntersectsMapItem[], mapId: number) => {
  for (const map of mapList) {
    if (map.id === mapId) {
      const spreadsheets = map.spreadSheets || [];
      const primarySpreadsheet = spreadsheets.filter((spreadsheet: SpreadsheetMapInfo) => spreadsheet.isPrimary);
      if (primarySpreadsheet.length) {
        return primarySpreadsheet[0]?.spreadsheetId;
      }
      return null;
    }
  }
  return null;
};

export const hasRowOnlyOneSourceMap = (row: Row, maps: IntersectsMapItem[]) => {
  const definedColumns = maps.reduce((count, m) => {
    const sourceColumn = row.selectedSourceColumnPerMap[m.id];
    return count + (sourceColumn ? 1 : 0);
  }, 0);

  return definedColumns === 1;
};

export const createRecordWithDefaultForMaps = <UValue extends {id: number}, TValue>(items: ReadonlyArray<UValue>, defaultValue: TValue) =>
  items.reduce((lookup, item) => {
    lookup[item.id] = defaultValue;

    return lookup;
  }, {} as Record<number, TValue>);

export const createUsedColumnKeyForMap = (mapId: number, columnKey: string) => {
  return `${mapId}_${columnKey}`;
};

export const getTargetColumnForColumn = ({ column, columns, mapId, usedKeys }: Readonly<{
  column: string | null;
  columns: Readonly<Record<string, string>>;
  mapId: number;
  usedKeys: ReadonlySet<string>;
}>) =>
  Object.keys(columns).find(key => {
    const shouldBeUsed = !usedKeys.has(createUsedColumnKeyForMap(mapId, key));
    return shouldBeUsed && columns[key] === column;
  }) || null;

export const extractRealSpreadsheetIdFromKey = (spreadsheetKey: string) =>
  Number(spreadsheetKey.slice(spreadsheetKey.indexOf('_') + 1));

export const createKeyFromRealSpreadsheetId = (realSpreadsheetId: number) =>
  `spreadsheet_${realSpreadsheetId}`;

export const createColumnIdFromNumber = (id: number) =>
  `column_${id}`;
