import { css } from '@emotion/react';

export const authorizationPagesErrorStyle = css({
  fontSize: 16,
  fontWeight: 'bold',
  marginTop: 8,
});

export const authorizationPagesAlertStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
});

export const authorizationPagesLinkStyle = css({
  fontSize: 20,
});
