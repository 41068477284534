import { createUuid } from '~/_shared/utils/createUuid';
import { type ClientAction } from './client.action';
import {
  CLIENT_INVITEE_LIST_CHANGED, CLIENT_MEMBER_LIST_CHANGED, CLIENT_SET_SUBSCRIPTION_LOADING, CLIENT_SUBSCRIPTION_CHANGED,
} from './client.actionTypes';
import { type ClientState } from './client.state';

const initialState: ClientState = {
  inviteesListEtag: createUuid(),
  membersListEtag: createUuid(),
  subscriptionEtag: createUuid(),
  subscriptionLoading: false,
};

export const clientReducer = (state = initialState, action: ClientAction): ClientState => {
  switch (action.type) {
    case CLIENT_MEMBER_LIST_CHANGED:
      return {
        ...state,
        membersListEtag: createUuid(),
      };

    case CLIENT_INVITEE_LIST_CHANGED:
      return {
        ...state,
        inviteesListEtag: createUuid(),
      };

    case CLIENT_SUBSCRIPTION_CHANGED:
      return {
        ...state,
        subscriptionEtag: createUuid(),
      };

    case CLIENT_SET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        subscriptionLoading: action.payload.newValue,
      };

    default:
      return state;
  }
};
