import {
  type EventHandler, type MouseEvent,
  type PointerEvent, type SyntheticEvent, useMemo, useRef,
} from 'react';
import { delay } from '~/_shared/utils/delay';

const SINGLE_TAP_DELAY = 300;

type Handler = EventHandler<SyntheticEvent>;

/**
 * This hook is a workaround that prevents default behavior on mobile safari:
 * - On single tap event, mouseover is fired, which could activate hover effects.
 * - If a mouseover event changes the page content, the following events are never fired:
 *   mousemove, mousedown, mouseup, click.
 *
 * For touch and pen pointer types, this hook uses pointer events to detect a single tap,
 * and executes the onClick action on it's own, while also disabling native onClick action.
 */
export const usePointerClickEvent = <TClick extends Handler, TTap extends Handler>(onClick?: TClick, onTap?: TTap) => {
  const pointerTypeRef = useRef<string | null>(null);
  const pointerDownTimestampRef = useRef<number>(0);

  const doNothing = !onClick && !onTap;

  const onPointerDown = useMemo(() => {
    if (doNothing) {
      return undefined;
    }

    return (e: PointerEvent) => {
      pointerTypeRef.current = e.pointerType;
      pointerDownTimestampRef.current = e.timeStamp;
    };
  }, [doNothing]);

  const onPointerUp = useMemo(() => {
    if (doNothing) {
      return undefined;
    }

    return (e: PointerEvent) => {
      if (e.pointerType === 'mouse') {
        return;
      }

      if (e.timeStamp - pointerDownTimestampRef.current > SINGLE_TAP_DELAY) {
        return;
      }

      const action = () => onTap ? onTap(e) : onClick?.(e);

      /**
       * Use delay to execute click/tap event, after native click event.
       * Executing before native event might trigger react to change the DOM
       * and cause the click to land on another DOM element.
       */
      delay(100).then(action);
    };
  }, [doNothing, onClick, onTap]);

  const onMouseClick = useMemo(() => {
    if (doNothing) {
      return undefined;
    }

    return (e: MouseEvent) => {
      if (pointerTypeRef.current === 'mouse') {
        onClick?.(e);
      }
    };
  }, [doNothing, onClick]);

  return {
    onClick: onMouseClick,
    onPointerDown,
    onPointerUp,
  };
};
