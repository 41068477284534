import {
  type FC, useMemo,
} from 'react';
import { itemCountStyle } from '~/_shared/baseComponents/accordion/accordion.styles';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import { useTheme } from '~/_shared/themes/theme.hooks';

export const AccordionStepNumber: FC<{ number: number }> = ({ number }) => {
  const theme = useTheme();
  const circleColors = useMemo(() => ({
    fillColor: theme.accordionColors.countBackground,
    fontColor: theme.accordionColors.countColor,
  }), [theme.accordionColors.countBackground, theme.accordionColors.countColor]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={itemCountStyle}
      size={20}
    >
      {number}
    </NumberInCircleComponent>
  );
};
