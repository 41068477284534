import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import {
  clientSetSubscriptionLoading, clientSubscriptionChanged,
} from '~/store/frontendState/client/client.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { refresh } from './billing.repository';

export const usePaymentPortalRefresh = () => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();

  const postRefresh = useCallback(() => {
    if (clientId) {
      dispatch(clientSetSubscriptionLoading(true));
      return refresh(clientId).finally(() => {
        dispatch(clientSetSubscriptionLoading(false));
        dispatch(clientSubscriptionChanged());
      });
    }
    return Promise.reject();
  }, [clientId, dispatch]);

  const { invokeAjax: invokeRefresh, isLoading } = useAjaxCall(postRefresh);

  return useMemo(() => ({
    isLoading,
    paymentPortalRefresh: invokeRefresh,
  }), [invokeRefresh, isLoading]);
};
