import { css } from '@emotion/react';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode, useEffect, useMemo,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { delay } from '~/_shared/utils/delay';
import { autoCompleteDisabled } from '~/_shared/utils/dom/dom.helpers';
import { MapNameSectionComponent } from '~/map/basicInfo/mapNameSection';
import { getLottieAnimationDuration } from '../../baseComponents/lottieAnimation/lottieAnimation.helpers';
import { LottieAnimationTypes } from '../../baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '../../baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLottieAnimationComponent } from '../overlay/overlayLottieAnimation.component';
import { OverlaySuccessComponent } from '../overlay/overlaySuccess.component';
import { CopyMapInputField } from './copyMapInputField.enum';

const wrapperStyle = css({
  padding: 0,
});

const rootStyle = css({
  fontSize: '14px',
  padding: 20,
});

const buttonStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.buttonColors.successBackground,
  color: theme.buttonColors.successText,

  '&:hover': {
    backgroundColor: theme.buttonColors.successBackground,
    color: theme.buttonColors.successText,
  },
});

type CopyMapModalProps = Readonly<{
  fieldErrors: ReadonlyMap<CopyMapInputField, ReadonlyArray<string>>;
  isLoading: boolean;
  isOpen: boolean;
  isSnapshot?: boolean;
  isSuccess: boolean;
  originalMapName: string;
  value: string;

  onChange: (name: string) => void;
  onClose: () => void;
  onSave: () => void;
  onSuccessAnimationFinish: () => void;
}>;

export const CopyMapModalComponent: FC<CopyMapModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const animationColors = useLottieAnimationDefaultColors();
  const { onSuccessAnimationFinish } = props;

  const fieldErrors = useMemo(() => {
    const fieldError = props.fieldErrors.get(CopyMapInputField.Name)?.[0];
    return fieldError ? [fieldError] : undefined;
  }, [props.fieldErrors]);

  useEffect(() => {
    if (!props.isSuccess) {
      return;
    }

    const later = delay(getLottieAnimationDuration(LottieAnimationTypes.Success))
      .then(() => onSuccessAnimationFinish());

    return () => {
      later.cancel();
    };
  }, [props.isSuccess, onSuccessAnimationFinish]);

  let additionalContent: ReactNode = null;

  if (props.isLoading) {
    additionalContent = (
      <OverlayLottieAnimationComponent
        size={60}
        type={LottieAnimationTypes.Copy}
        colors={animationColors.Copy}
        autoplay
        loop
      />
    );
  }

  if (props.isSuccess) {
    additionalContent = (
      <OverlaySuccessComponent />
    );
  }

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Copy Map')}
      contentStyle={wrapperStyle}
      confirmButton={(
        <ButtonComponent
          css={buttonStyle({ theme })}
          text={t('Copy')}
          onClick={props.onSave}
          isDisabled={props.value.length === 0}
        />
      )}
      additionalContent={additionalContent}
    >
      <MapNameSectionComponent
        isSnapshot={!!props.isSnapshot}
        name={props.originalMapName}
      />
      <div css={rootStyle}>
        <FormTextInputComponent
          autoComplete={autoCompleteDisabled}
          label={t('New Map Name') + ':'}
          value={props.value}
          onChange={props.onChange}
          errorMessages={fieldErrors}
          icon={faMap}
        />
      </div>
    </ModalComponent>
  );
};
