import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { GeneralErrorCode } from '~/_shared/types/responseErrors/_shared/generalErrorCodes.types';
import { catchApiError } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  authenticateTwoFactorUser, authenticateUser, isMFARequiredResponse, sendTwoFactorEmailRequest,
} from '~/store/userData/repository/userData.repository';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';

type userSignData = {
  email: string; password: string; rememberMe: boolean;
};

export const useUserSign = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mfaMode, setMfaMode] = useState<TwoFactorMode | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const authenticateUserRequest = (request: userSignData) => {
    setIsLoading(true);
    setMfaMode(null);
    setError(null);
    setRememberMe(request.rememberMe);
    setEmail(request.email);
    setPassword(request.password);

    return authenticateUser(request.email, request.password, request.rememberMe)
      .then((res) => {
        setMfaMode(null);
        dispatch(userGetDataRequest({ fetchAnnouncements: true }));
        return res;
      })
      .catch(catchApiError(apiError => {
        if (isMFARequiredResponse(apiError.response)) {
          const mfaModes = apiError.response.data.list;

          if (mfaModes[0]) {
            setMfaMode(mfaModes[0]);
            if (mfaModes[0] === TwoFactorMode.email) {
              sendTwoFactorEmailRequest(request.email, request.password);
            }
          }
        }
        else {
          setError(apiError.errorCode ?? null);
        }
      }))
      .catch(_ => setError(t('Unable to log in. Please check your internet connection and try again.')))
      .finally(() => setIsLoading(false));
  };

  const authenticate2FaUserRequest = (code: string) => {
    setError(null);
    setIsLoading(true);

    return authenticateTwoFactorUser(email, password, rememberMe, mfaMode || '', code)
      .then((res) => {
        dispatch(userGetDataRequest({ fetchAnnouncements: true }));
        return res;
      })
      .catch(catchApiError(apiError => {
        setError(apiError.errorCode ?? GeneralErrorCode.UNKNOWN_ERROR);
      }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetTwoFactorData = () => {
    setError(null);
    setIsLoading(false);
    setMfaMode(null);
    setRememberMe(false);
    setEmail('');
    setPassword('');
  };

  return {
    isLoading,
    error,
    mfaMode,
    authenticateUserRequest,
    authenticate2FaUserRequest, resetTwoFactorData,
  };
};
