import { MapOutlinePosition } from '../../constants/mapObjects/mapObjectOutline/outlinePositions';

export const getDraggableCursorForOutlinePosition = (position: MapOutlinePosition) => {
  switch (position) {
    case MapOutlinePosition.BottomLeft:
      return 'sw-resize';
    case MapOutlinePosition.BottomRight:
      return 'se-resize';
    case MapOutlinePosition.TopLeft:
      return 'nw-resize';
    case MapOutlinePosition.TopRight:
      return 'ne-resize';
    case MapOutlinePosition.Bottom:
    case MapOutlinePosition.Top:
      return 'ns-resize';
    case MapOutlinePosition.Left:
    case MapOutlinePosition.Right:
      return 'ew-resize';
    default:
      return '';
  }
};
