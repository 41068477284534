import { css } from '@emotion/react';
import {
  type ChangeEventHandler,
  type FC, type MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { YoutubeEmbed } from '~/_shared/baseComponents/video/YoutubeEmbed.component';
import { buildConfirmationModalPropsFromParams } from '~/_shared/components/modal/confirmation/confirmationModal.helpers';
import { PermanentConfirmStrategy } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { MAPTIVE_SUPPORT_EMAIL } from '~/_shared/constants/maptive';
import type { Theme } from '~/_shared/themes/theme.model';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { useStorageService } from '~/_shared/utils/storageService';
import type { ModalProps } from '~/modal/modalType.enum';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { Trans } from '~/translations/Trans';

export const groupingToolPermanentConfirmSettings = { id: 'GroupingToolHint', strategy: PermanentConfirmStrategy.Local };

const groupingHintContentStyle = css({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  fontSize: 16,
});

const textPartStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const videoPartStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const videoEmbedStyles = css({
  width: 507,
  height: 241,
});

const textBlockStyles = css({ margin: 0, fontWeight: 400 });

const listIndentStyle = css({
  paddingLeft: 24,
});

const checkboxTextStyle = css({
  fontSize: 14,
});

const greenEmphasisStyles = (theme: Theme) => css({
  color: theme.textColors.success,
});

const redEmphasisStyles = (theme: Theme) => css({
  color: theme.textColors.danger,
});

const linkStyles = css({
  fontSize: 16,
});

const getTranslationComponents = (openGroupingTool: MouseEventHandler) => ({
  b: <b />,
  GreenEmphasis: <span css={greenEmphasisStyles} />,
  RedEmphasis: <span css={redEmphasisStyles} />,
  GroupingToolLink: (
    <LinkComponent
      css={linkStyles}
      path="#"
      onClick={openGroupingTool}
    >
      {null}
    </LinkComponent>
  ),
  ContactUsLink: <LinkComponent css={linkStyles} path={`mailto:${MAPTIVE_SUPPORT_EMAIL}?subject=Exploring%20Maptive%3A%20Demo%20Inquiry`} >{null}</LinkComponent>,
});

export const GroupingToolHintComponent: FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const storageService = useStorageService();
  const [isPermanentCheckboxChecked, setIsPermanentCheckboxChecked] = useState(false);
  const { onConfirm, checkboxLabel } = useMemo(() => buildConfirmationModalPropsFromParams({
    permanentConfirmSettings: groupingToolPermanentConfirmSettings,
    onClose,
    onConfirm: onClose,
    text: undefined,
    title: '',
  }, t, storageService),
  [onClose, storageService, t]);

  const closeHandler = useCallback(() => {
    onConfirm(isPermanentCheckboxChecked);
  }, [isPermanentCheckboxChecked, onConfirm]);

  const openGroupingTool: MouseEventHandler = useCallback((event) => {
    event.preventDefault();
    onConfirm(isPermanentCheckboxChecked);
    dispatch(openMapTool(MapTool.Grouping));
  }, [dispatch, isPermanentCheckboxChecked, onConfirm]);

  const checkboxChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setIsPermanentCheckboxChecked(event.target.checked);
  }, []);

  const translationComponents = getTranslationComponents(openGroupingTool);

  return (
    <ModalComponent
      onClose={closeHandler}
      isOpen={isOpen}
      caption={t('GroupingToolHint.Caption')}
      contentStyle={groupingHintContentStyle}
    >
      <div css={textPartStyles}>
        <p css={textBlockStyles}>
          <Trans
            i18nKey="GroupingToolHint.Intro"
            components={translationComponents}
          />
        </p>
        <ul css={[textBlockStyles, listIndentStyle]}>
          <li css={textBlockStyles}>
            <Trans
              i18nKey="GroupingToolHint.BulletPoint1"
              components={translationComponents}
            />
          </li>
          <li css={textBlockStyles}>
            <Trans
              i18nKey="GroupingToolHint.BulletPoint2"
              components={translationComponents}
            />
          </li>
        </ul>
        <p css={textBlockStyles}>
          <Trans
            i18nKey="GroupingToolHint.DemoParagraph"
            components={translationComponents}
          />
        </p>
      </div>
      <div css={videoPartStyles}>
        <p css={textBlockStyles}>
          <Trans
            i18nKey="GroupingToolHint.Outro"
            components={translationComponents}
          />
        </p>
        <YoutubeEmbed
          css={videoEmbedStyles}
          videoId="cozCl9FbNl4"
        />
      </div>
      <div>
        <CheckboxComponent
          text={checkboxLabel}
          textStyle={checkboxTextStyle}
          isChecked={isPermanentCheckboxChecked}
          checkedSetter={checkboxChangeHandler}
        />
      </div>
    </ModalComponent>
  );
};
