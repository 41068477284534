import { useState } from 'react';
import { useIsComponentMountedRef } from '../../../_shared/utils/hooks/useIsComponentMountedRef';
import { copyMap } from '../../../map/map.repository';
import { type ApiError } from '../../utils/api/apiError.helpers';
import { copy } from '../../utils/collections/collections';
import { CopyMapInputField } from './copyMapInputField.enum';

export type FieldErrors = ReadonlyMap<CopyMapInputField, ReadonlyArray<string>>;

export type CopyMapRequestParams = Readonly<{
  mapId: number | null;
  clientId: number | null;
  name: string;
}>;

const printUnknownFieldErrorsMessage = (apiError: ApiError) => {
  const unknownFieldErrors = Object.values(CopyMapInputField).reduce((errors, field) => {
    delete errors[field];
    return errors;
  }, { ...apiError.errors });
  if (Object.values(unknownFieldErrors).length > 0) {
    console.error('copyMap: got ApiError with unknown fieldErrors: ', unknownFieldErrors);
  }
};

export const useCopyMap = () => {
  const isMountedRef = useIsComponentMountedRef();
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(new Map());
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitCopyMapRequest = (params: CopyMapRequestParams): Promise<{ fieldErrors: FieldErrors; globalError: string | null }> => {
    if (params.mapId === null || params.clientId === null) {
      throw new Error('Cannot copy map without mapId or clientId');
    }
    setIsLoading(true);

    return copyMap(params.clientId, params.mapId, {
      name: params.name,
    })
      .then(() => {
        setGlobalError(null);
        setFieldErrors(new Map());
        return { fieldErrors: new Map(), globalError: null };
      })
      .catch(e => {

        const apiError = e as ApiError;
        const knownFieldErrors = new Map(Object.values(CopyMapInputField)
          .filter(field => {
            return apiError.errors?.hasOwnProperty(field);
          })
          .map(field => [field, apiError.errors?.[field] ?? []] as const));

        if (isMountedRef.current) {
          setFieldErrors(knownFieldErrors);
        }

        if (knownFieldErrors.size === 0) {
          printUnknownFieldErrorsMessage(apiError);
          setGlobalError(apiError.message);
        }
        return { fieldErrors: knownFieldErrors, globalError: apiError.message };
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    fieldErrors,
    globalError,
    isLoading,
    copyMap: submitCopyMapRequest,
    clearGlobalError: () => setGlobalError(null),
    clearFieldError: (field: CopyMapInputField) => setFieldErrors(prev => copy.andRemove(prev, [field])),
  };
};
