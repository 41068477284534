import { css } from '@emotion/react';
import { type FC } from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import {
  s, Trans,
} from '../../../translations/Trans';
import { LottieAnimationComponent } from '../../baseComponents/lottieAnimation/lottieAnimation.component';
import { LottieAnimationTypes } from '../../baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '../../baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

export type DownloadResultsModalProps = ModalProps<{
  downloadUrl: string;
}>;

const headingStyle = css({
  margin: 0,
  fontSize: '18px',
});

const restartDownloadStyle = ({ theme }: ThemeProps) => css({
  marginTop: 24,
  color: theme.textColors.secondary,
});

const linkStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.link,
  textDecoration: 'none',
  '&:hover': {
    color: theme.textColors.linkOnHover,
  },
});

const contentStyle = css({
  overflow: 'hidden',
});

const modalContentStyle = css({
  padding: 24,
});

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DownloadResultsModalComponent: FC<DownloadResultsModalProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const downloadColors = useLottieAnimationDefaultColors().CircleDownload;

  return (
    <ModalComponent
      {...props}
      caption={t('Download Started')}
      contentStyle={modalContentStyle}
    >
      <div css={wrapperStyle}>
        <div css={contentStyle}>
          <p css={headingStyle}>{t('The download should start automatically.')}</p>

          <div css={restartDownloadStyle({ theme })}>
            <Trans i18nKey={'Download didn\'t work? Click here to <1>download again</1>'} >
              {s('Download didn\'t work? Click here to ')}
              <a
                href={props.downloadUrl}
                download
                css={linkStyle({ theme })}
                rel="noreferrer"
              >
                download again
              </a>
            </Trans>
          </div>
        </div>

        <div>
          <LottieAnimationComponent
            size={48}
            type={LottieAnimationTypes.CircleDownload}
            autoplay
            colors={downloadColors}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
