import {
  put, takeLatest,
} from 'redux-saga/effects';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionTypes';
import { type BoundaryTerritoryGroupsAction } from '../../../boundaryTerritoryGroups/boundaryTerritoryGroups.action';
import { boundaryTerritoryGroupsSelector } from '../../../boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from '../../../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { setBoundaryFilter } from './mapSettingsToolsStateBoundary.actionCreators';
import { boundaryFiltersStateSelector } from './mapSettingsToolsStateBoundary.selectors';
import { type BoundaryFiltersState } from './mapSettingsToolsStateBoundary.state';

export function* mapSettingsToolsStateBoundarySagas() {
  yield takeLatest(BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST, resetBoundaryFiltersOnRangesCountChange);
}

function* resetBoundaryFiltersOnRangesCountChange(action: PickAction<BoundaryTerritoryGroupsAction, typeof BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST>) {
  const boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup> = yield select<ReadonlyArray<BoundaryTerritoryGroup>>(boundaryTerritoryGroupsSelector);
  const boundaryFiltersState: BoundaryFiltersState = yield select<BoundaryFiltersState>(boundaryFiltersStateSelector);

  const currentBoundaryTerritoryGroup = boundaryTerritoryGroups.find(
    group => group.boundaryTerritoryGroupId === action.payload.updatedBoundaryTerritoryGroup.boundaryTerritoryGroupId
  );

  const boundaryFilters = boundaryFiltersState[action.payload.updatedBoundaryTerritoryGroup.boundaryGroupId];

  if (!currentBoundaryTerritoryGroup ||
    currentBoundaryTerritoryGroup.settings.rangesCount === action.payload.updatedBoundaryTerritoryGroup.settings.rangesCount ||
    !boundaryFilters
  ) {
    return;
  }

  yield put(setBoundaryFilter(action.payload.updatedBoundaryTerritoryGroup.boundaryGroupId, {
    filteredBoundaries: [],
    filteredBoundaryTerritories: [],
    showAll: boundaryFilters.showAll === TriStateRange.Partial
      ? TriStateRange.Full
      : boundaryFilters.showAll,
  }));
}
