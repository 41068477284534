import { css } from '@emotion/react';
import {
  type FC, Fragment, useCallback, useEffect,
  useRef, useState,
} from 'react';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import { type ShareMapProps } from '~/map/share/shareMap.container';
import { type MapListingItem } from '../item/mapListingItem';
import { MobileMapRowComponent } from './mapRow/mobileMapRow.component';

const tableStyle = css({
  width: '100%',
  borderSpacing: 0,
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
});

type MobileTableDataProps = Readonly<{
  data: ReadonlyArray<MapListingItem>;
  isMapListingDataLoading: boolean;
  pagination: Pagination | null;
  selectedMapsIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  toggledMapIds: ReadonlyArray<number>;

  onMapShareSettingsClick: (props: ShareMapProps) => void;
  onPageChange: (page: number) => void;
  onShowSnapshotsToggle: (mapId: number) => void;
  onSnapshotSelectToggle: (params: Readonly<{ mapId: number; snapshotId: number }>) => void;
}>;

export const MobileTableDataComponent: FC<MobileTableDataProps> = (props) => {
  const { isMapListingDataLoading, pagination, onPageChange, onSnapshotSelectToggle } = props;
  const observer = useRef<IntersectionObserver | null>(null);
  const [isLock, setIsLock] = useState<boolean>(false);
  const lastRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (isMapListingDataLoading || isLock) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting && pagination && onPageChange && pagination.lastPage > pagination.currentPage) {
          setIsLock(true);
          onPageChange(pagination.currentPage + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isMapListingDataLoading, pagination, onPageChange, isLock],
  );

  useEffect(() => {
    if (!isMapListingDataLoading) {
      setIsLock(false);
    }
  }, [isMapListingDataLoading]);

  const handleSnapshotToggle = useCallback((itemId: number, snapshotId: number) =>
    () => onSnapshotSelectToggle({ mapId: itemId, snapshotId }),
  [onSnapshotSelectToggle]);

  return (
    <table
      css={tableStyle}
      data-testid="mapList"
    >
      <tbody>
        {props.data.map((item, index) => {
          const areSnapshotsExpanded = props.toggledMapIds.includes(item.id);
          const isLastElement = index + 1 === props.data.length;

          return (
            <Fragment key={item.id} >
              <MobileMapRowComponent
                setRef={isLastElement ? lastRef : undefined}
                areSnapshotsExpanded={areSnapshotsExpanded}
                index={index}
                item={item}
                onShowSnapshotsToggle={props.onShowSnapshotsToggle}
                onMapShareSettingsClick={props.onMapShareSettingsClick}
              />

              {areSnapshotsExpanded && item.snapshots.map((snapshot, subIndex) => (
                <MobileMapRowComponent
                  areSnapshotsExpanded={false}
                  index={subIndex}
                  item={snapshot}
                  key={item.id + 'snapshot' + subIndex}
                  onShowSnapshotsToggle={handleSnapshotToggle(item.id, snapshot.id)}
                  parentMapItem={item}
                  onMapShareSettingsClick={props.onMapShareSettingsClick}
                />
              ))}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
