import { useEffect } from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  type BoundaryGroupId, type BoundaryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryItemStyle, type MapBoundaryManager,
} from '../../mapBoundary.manager';
import { type BoundaryExtraStyles } from './useMapBoundaryExtraStyles';

const emptyStylesMap: ReadonlyMap<number, BoundaryItemStyle> = new Map();
const emptyMap = new Map();

export const useMapBoundaryStyles = ({ manager, baseStyles = emptyMap, boundaryTerritoryStyles = emptyMap, extra }: {
  manager: MapBoundaryManager | undefined;
  baseStyles?: ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>;
  boundaryTerritoryStyles?: ReadonlyMap<BoundaryGroupId, ReadonlyMap<BoundaryId, BoundaryItemStyle>>;
  extra: BoundaryExtraStyles;
}) => {
  const previousManager = usePrevious(manager);
  const forceUpdate = !previousManager && previousManager !== manager;

  const previousBaseStyles = usePrevious(baseStyles, { reset: forceUpdate });
  const previousBoundaryTerritoryStyles = usePrevious(boundaryTerritoryStyles, { reset: forceUpdate });

  const { boundaryExtraStyles, boundariesWithExtraStyles } = extra;
  const previousBoundaryExtraStyles = usePrevious(boundaryExtraStyles, { reset: forceUpdate });

  useEffect(() => {
    if (!manager) {
      return;
    }

    // Apply base and boundary territory styles
    baseStyles.forEach((baseStyle, boundaryGroupId) => {
      if (baseStyle === previousBaseStyles?.get(boundaryGroupId)) {
        // base styles haven't changed => only apply boundary territory styles

        const styles = boundaryTerritoryStyles.get(boundaryGroupId) ?? emptyStylesMap;
        const previousStyles = previousBoundaryTerritoryStyles?.get(boundaryGroupId) ?? emptyStylesMap;

        if (styles !== previousStyles) {
          const ignoreBoundaryIds = boundariesWithExtraStyles;

          // reset boundaries that are not part of boundary territory
          previousStyles?.forEach((_, boundaryId) => {
            if (!styles.has(boundaryId) && !ignoreBoundaryIds.has(boundaryId)) {
              manager.updateBoundaryStyle(boundaryGroupId, boundaryId, baseStyle);
            }
          });

          // apply current boundary territory styles
          manager.updateBoundaryStyles({ boundaryGroupId, styles, ignoreBoundaryIds });
        }
      }
      else {
        // base styles have changed => reset all
        const boundaryTerritoryStyle = boundaryTerritoryStyles.get(boundaryGroupId);

        manager.updateBoundaryGroupStyles({
          boundaryGroupId,
          baseStyle,
          overrides: boundaryTerritoryStyle,
          ignoreBoundaryIds: boundariesWithExtraStyles,
        });
      }

      // Apply extra styles
      if (boundaryExtraStyles !== previousBoundaryExtraStyles) {

        // reset boundaries that lost extra styling
        for (const previousExtra of previousBoundaryExtraStyles?.values() ?? []) {
          const { boundaryGroupId, boundaryId } = previousExtra.boundary;

          if (!boundaryExtraStyles.has(boundaryId)) {
            const baseStyle = baseStyles.get(boundaryGroupId);
            const boundaryTerritoryStyle = boundaryTerritoryStyles.get(boundaryGroupId)?.get(boundaryId);

            const style = boundaryTerritoryStyle ?? baseStyle;

            if (style) {
              manager.updateBoundaryStyle(boundaryGroupId, boundaryId, style);
            }
          }
        }

        // apply current extra styles
        for (const extra of boundaryExtraStyles?.values() ?? []) {
          const { boundaryGroupId, boundaryId } = extra.boundary;
          manager.updateBoundaryStyle(boundaryGroupId, boundaryId, extra.style);
        }
      }
    });
  }, [baseStyles, boundariesWithExtraStyles, boundaryExtraStyles, boundaryTerritoryStyles, manager,
    previousBaseStyles, previousBoundaryExtraStyles, previousBoundaryTerritoryStyles]);
};
