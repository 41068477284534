import jwtDecode from 'jwt-decode';
import { StrictBroadcastChannel } from '~/_shared/types/strictBroadcastChannel';
import {
  destroyBroadcast, initializeBroadcast,
} from '../../_shared/utils/api/broadcast.helpers';
import { appStore } from '../../store/app.store';
import {
  getUserData, refreshAccessToken, type UserDataResponse,
} from '../../store/userData/repository/userData.repository';
import {
  userAuthorizationExpire, userGetDataSuccess, userSignOutSuccess,
} from '../../store/userData/userData.actionCreators';
import { userDataServerToClientModel } from '../../store/userData/userData.dataMapping';
import { type ImpersonationStatusChange } from '../impersonation';
import { RefreshTokenLockService } from './refreshTokenLockService';

export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_EXP_KEY = 'ACCESS_TOKEN_EXP';
export const refreshTokenValidityInDays = 14;
export const minAccessTokenValidityInMs = 2 * 60 * 1000;
export const accessTokenHalfValidityInMs = 15 * 60 * 1000;
export const refreshTokenValidityBufferInSec = 2 * 60;

export type RefreshTokenErrorCode = typeof RefreshTokenErrorCodes[keyof typeof RefreshTokenErrorCodes];
export const RefreshTokenErrorCodes = {
  INVALID_TOKEN: 'INVALID_TOKEN',
} as const;

export const removeAuthTokens = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_EXP_KEY);
};

export const getAccessTokenExpireIn = (getNowMs = Date.now) => {
  const storedAccessTokenExp = localStorage.getItem(ACCESS_TOKEN_EXP_KEY);
  if (!storedAccessTokenExp) {
    return 0;
  }

  const expireAtMs = new Date(storedAccessTokenExp).getTime();
  const nowMs = getNowMs();
  const expireInMs = expireAtMs - nowMs;

  return expireInMs;
};

export const isAuthTokenExpired = () => {
  const expiresIn = getAccessTokenExpireIn();
  return expiresIn < minAccessTokenValidityInMs;
};

export const authTokenShouldRefresh = () => {
  const expiresIn = getAccessTokenExpireIn();
  return expiresIn < accessTokenHalfValidityInMs;
};

export const frontendLogoutActions = () => {
  destroyBroadcast();
  removeAuthTokens();
  appStore.dispatch(userAuthorizationExpire());
  appStore.dispatch(userSignOutSuccess());
};

export const isJWTValid = (jwt: string | null): jwt is string => {
  if (!jwt) {
    return false;
  }

  const { exp } = jwtDecode<{ exp: number }>(jwt); // exp is in seconds
  const nowTimestampInSeconds = Date.now() / 1000;
  return nowTimestampInSeconds < (exp - refreshTokenValidityBufferInSec);
};

export const isStoredRefreshTokenValid = () => {
  const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  return isJWTValid(storedRefreshToken);
};

export const refreshTokens = async () => {
  if (!authTokenShouldRefresh()) {
    return;
  }

  const inProgress = await RefreshTokenLockService.inProgress();

  if (!inProgress) {
    await RefreshTokenLockService.call(async () => {
      const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      if (!isJWTValid(storedRefreshToken) || !authTokenShouldRefresh()) {
        return;
      }

      const { refresh: newRefreshToken, access_token_expiration } = await refreshAccessToken(storedRefreshToken);

      if (newRefreshToken && access_token_expiration) {
        localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
        localStorage.setItem(ACCESS_TOKEN_EXP_KEY, access_token_expiration);
      }
    });
  }
  else if (inProgress) {
    await RefreshTokenLockService.waitForFinish();
  }
};

export const initializeUser = async () => {
  const userData: UserDataResponse = await getUserData();
  initializeBroadcast({
    userId: userData.data.id,
  });

  const clientModel = userDataServerToClientModel(userData);
  appStore.dispatch(userGetDataSuccess(clientModel, { fetchAnnouncements: true }));
};

type LogoutMessage = Readonly<{
  type: 'logout';
  errorCode?: RefreshTokenErrorCode;
}>;
type ImpersonationChangedMessage = Readonly<{
  type: 'impersonationChanged';
  reason: ImpersonationStatusChange;
}>;
type LicenseUpdatedMessage = Readonly<{
  type: 'licenseUpdated';
}>;
export type AuthMessage = LogoutMessage | ImpersonationChangedMessage | LicenseUpdatedMessage;
export const AuthBroadcastChannel = new StrictBroadcastChannel<AuthMessage>('AUTH_BROADCAST_CHANNEL');
