import {
  css, keyframes,
} from '@emotion/react';
import { type FC } from 'react';
import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';

const MAX_SPEED = 4;

const pulsate = keyframes({
  from: {
    transform: 'scale(0)',
    opacity: 0,
  },
  '20%': {
    transform: 'scale(0)',
    opacity: 0,
  },
  '21%': {
    transform: 'scale(0.1)',
    opacity: 1,
  },
  '95%': {
    transform: 'scale(1)',
    opacity: 0,
  },
  to: {
    transform: 'scale(0.3)',
    opacity: 0,
  },
});

const indicatorStyle = ({ color, speed }: { color: string; speed: number }) => css({
  position: 'absolute',
  border: '1pt solid #fff',
  borderRadius: '50%',
  /* multiply the shadows, inside and outside the circle */
  boxShadow: `inset 0 0 5px ${color}, inset 0 0 5px ${color}, inset 0 0 5px ${color}, 0 0 5px ${color}, 0 0 5px ${color}, 0 0 5px ${color}`,
  /* set the ring's new dimension and re-center it */
  height: '100%',
  width: '100%',
  ...(speed === 0 ? {} : { animation: `${pulsate} ${MAX_SPEED * ((100 - (speed === 100 ? 99 : speed)) / 100)}s ease-in-out infinite` }),
});

type ActiveMarkerPulsatingIndicatorProps = {
  color: string;
  speed: number;
};

export const ActiveMarkerPulsatingIndicatorComponent: FC<ActiveMarkerPulsatingIndicatorProps> = props => {
  return (
    <div
      css={indicatorStyle({
        color: guaranteeHash(props.color),
        speed: props.speed,
      })}
    />
  );
};
