import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type MatchupDataState } from './matchupData.state';

export const getMatchupDataColumnName = (matchupData: MatchupDataState, { columnId, spreadsheetId }: SpreadsheetColumnId):
string | null => {
  return matchupData[spreadsheetId]?.data?.columns?.[columnId] ?? null;
};

export const checkAreAllMatchupDataLoaded = (matchupData: MatchupDataState): boolean => {
  for (const spreadsheetId in matchupData) {
    if (matchupData[spreadsheetId]?.isLoading) {
      return false;
    }
  }
  return true;
};
