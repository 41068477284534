import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { memoizeOne } from '../../../_shared/utils/memoize/memoize';
import { type AppState } from '../../app.store';
import { type HeatmapToolStateData } from '../toolsState/heatmaps/heatmapToolState.state';

export type HeatMapItem = {
  readonly heatmap: HeatMap;
  readonly otherOptions: {
    readonly visible: boolean;
    readonly showMarkersForGroup?: boolean;
  };
};

export const selectHeatMapItems = (state: AppState): ReadonlyArray<HeatMapItem> => selectHeatMapItemsMemoized(
  state.map.mapSettings.data.heatmaps.heatmaps,
  state.map.mapSettings.data.toolsState.visibility.hiddenHeatmapIds,
  state.map.mapSettings.data.toolsState.heatmaps.heatmapStatePerId
);

export const useHeatmapItems: () => ReadonlyArray<HeatMapItem> = () => useSelector(selectHeatMapItems);

const selectHeatMapItemsMemoized = memoizeOne((
  heatmaps: ReadonlyArray<HeatMap>,
  hiddenHeatmapIds: ReadonlyArray<string>,
  heatmapToolStateData: Readonly<{ [id: string]: HeatmapToolStateData }>
) => {
  return heatmaps
    .map(heatmap => ({
      heatmap,
      otherOptions: {
        visible: !hiddenHeatmapIds.includes(heatmap.id),
        ...heatmapToolStateData[heatmap.id],
      },
    }));
});

export type HeatmapGroupsFilter = {
  column: SpreadsheetColumnId | null | undefined;
  group: string | undefined;
};

export const selectHeatmapGroupsFilter = createStateSelector(
  [selectHeatMapItems],
  (heatmapItems): ReadonlyArray<HeatmapGroupsFilter> =>
    heatmapItems.filter(i => !!i.otherOptions.showMarkersForGroup && !!i.heatmap.selectedGroupId && !!i.heatmap.selectedGroupColumn)
      .map(i => ({ column: i.heatmap.selectedGroupColumn, group: i.heatmap.selectedGroupId }))
);
