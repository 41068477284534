import { type Operation } from 'fast-json-patch';
import { useDispatch } from 'react-redux';
import { type MapInfo } from '~/_shared/types/map';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { type MapIntersect } from '~/map/map.repository';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { editLayeredMapRequest } from '../layering.repository';

export type EditLayeredMapParams = {
  layeringMapInfo: MapInfo;
  name?: string;
  integrated?: boolean;
  connected?: boolean | null;
  realTime?: boolean | null;
  newMaps?: number[];
  matches?: MapIntersect[];
  deletedMaps?: ReadonlyArray<number>;
};

export const useEditLayeredMap = () => {
  const { isLoading, error, invokeAjax } = useAjaxCall(
    ({
      clientId,
      layeredMapId,
      data,
    }: {
      clientId: number;
      layeredMapId: number;
      data: string[];
    }) => editLayeredMapRequest(clientId, layeredMapId, data)
  );
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const editLayeredMap = async (
    clientId: number,
    layeredMapId: number,
    {
      layeringMapInfo,
      matches,
      connected = null,
      realTime = null,
      newMaps,
      deletedMaps,
    }: EditLayeredMapParams, onSuccess?: () => void) => {
    try {
      const ops: Array<Operation> = [];

      if (newMaps && newMaps.length) {
        for (const map of newMaps) {
          ops.push({
            op: 'add',
            path: 'maps',
            value: map,
          });
        }
      }

      if (deletedMaps && deletedMaps.length) {
        for (const map of deletedMaps) {
          ops.push({
            op: 'remove',
            path: `maps/${map}`,
          });
        }
      }

      // We don't pass matches if the LM is disconnected and we removed a layer
      // It is not allowed from the BE to modify the matchup when removing layers from a disconnected LM
      if (matches && !(deletedMaps && deletedMaps.length && !layeringMapInfo?.layering?.connected)) {
        ops.push({
          op: 'replace',
          path: 'matches',
          value: matches,
        });
      }

      // the connect - disconnect operation needs to be added as last here
      // because if backend did disconnect first and then changed matches this would error out, other way around it works
      // (because we cannot change matches of disconnected map currently)
      if (layeringMapInfo?.layering?.connected && realTime !== null && layeringMapInfo?.layering?.realTime !== realTime) {
        ops.push({
          op: 'replace',
          path: 'real_time',
          value: realTime,
        });
      }
      else if (layeringMapInfo?.layering?.connected && connected !== null && !connected) {
        ops.push({
          op: 'replace',
          path: 'connected',
          value: connected,
        });
      }

      await invokeAjax({
        clientId,
        layeredMapId,
        data: ops.map(op => JSON.stringify(op)),
      });
      onSuccess?.();
    }
    catch (e) {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('Failed to Edit a Layered Map'),
        text: t('We were unable to edit a layered map for you. Please try again. If this problem persists, please contact the customer support.'),
      }));
    }
  };

  return {
    isLoading,
    error,
    editLayeredMap,
  };
};
