import { type FC } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useLassoToolModal } from '~/map/map/lassoTool/useLassoToolModal';
import { type AppState } from '~/store/app.store';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { RightSideMapToolsComponent } from './rightSideMapTools.component';

const streetViewSelector = (state: AppState) => state.frontendState.mapTools.streetView;

export const RightSideMapToolsContainer: FC = () => {
  const publicMapSettings = usePublicMapSettingsSelector();
  const streetView = useSelector(streetViewSelector);
  const isMapPresentational = useIsMapPresentationalSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const hideLassoDueNoActions = !useLassoToolModal().allowedLassoActions.length;

  return (
    <RightSideMapToolsComponent
      forceHideLasso={hideLassoDueNoActions}
      isMapPresentational={isMapPresentational}
      isLayeredMapConnected={isLayeredMapConnected}
      isMobileScreen={isMobileScreen}
      publicMapSettings={publicMapSettings}
      streetView={streetView}
    />
  );
};
