import {
  type FC, useMemo,
} from 'react';
import { useHeatmapItems } from '../../../store/mapSettings/heatmaps/useHeatmapItems.selector';
import { useWebglLayer } from '../mapContext';
import { HeatmapInstance } from './heatmapInstance.container';
import { HeatMapsManager } from './heatMapsManager';

export const HeatmapsOverlay: FC = () => {
  const heatmapItems = useHeatmapItems();
  const heatmapsLayer = useWebglLayer('Heatmaps');

  const manager = useMemo(() => new HeatMapsManager(heatmapsLayer), [heatmapsLayer]);

  return (
    <>
      {
        heatmapItems.map(item => (
          <HeatmapInstance
            key={item.heatmap.id}
            item={item}
            manager={manager}
          />
        ))
      }
    </>
  );
};
