import { TextFilterPredicate } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/textFilter/textFilterPredicate.enum';
import { FilterAction } from './filter';

export const createFilterActionFromTextPredicate = (textPredicate: TextFilterPredicate): FilterAction => {
  switch (textPredicate) {
    case TextFilterPredicate.Contains:
      return FilterAction.Contains;
    case TextFilterPredicate.NotContains:
      return FilterAction.NotContains;
    case TextFilterPredicate.Equals:
      return FilterAction.Equal;
    case TextFilterPredicate.NotEquals:
      return FilterAction.NotEqual;
    case TextFilterPredicate.StartsWith:
      return FilterAction.BeginsWith;
    case TextFilterPredicate.NotStartsWith:
      return FilterAction.NotBeginWith;
    case TextFilterPredicate.EndsWith:
      return FilterAction.EndsWith;
    case TextFilterPredicate.NotEndsWith:
      return FilterAction.NotEndsWith;
    case TextFilterPredicate.IsEmpty:
      return FilterAction.Null;
    case TextFilterPredicate.IsNotEmpty:
      return FilterAction.NotNull;
    case TextFilterPredicate.IsIn:
      return FilterAction.ArrayContains;
    case TextFilterPredicate.IsNotIn:
      return FilterAction.ArrayNotContains;
    default:
      throw Error('Text predicate not recognized');
  }
};
