import { css } from '@emotion/react';
import { type FC } from 'react';
import { LinkComponent } from '~/_shared/baseComponents/links';
import {
  MAPTIVE_PRIVACY_POLICY, MAPTIVE_TERMS_OF_SERVICE,
} from '~/_shared/constants/maptive';
import {
  s, Trans,
} from '~/translations/Trans';

const linkStyle = css({
  fontSize: 'inherit',
});

type TermsAndPrivacyNoticeProps = {
  className?: string;
};

export const TermsAndPrivacyNoticeComponent: FC<TermsAndPrivacyNoticeProps> = (props) => {
  return (
    <div className={props.className}>
      <Trans
        i18nKey='By clicking the "Create Account" button I agree to the <1>Terms of Service</1> & <3>Privacy Policy</3>'
      >
        {s('By clicking the "Create Account" button I agree to the ')}
        <LinkComponent
          css={linkStyle}
          path={MAPTIVE_TERMS_OF_SERVICE}
          target="_blank"
        >
          {s('Terms of Service')}
        </LinkComponent>
        {s(' & ')}
        <LinkComponent
          css={linkStyle}
          path={MAPTIVE_PRIVACY_POLICY}
          target="_blank"
        >
          {s('Privacy Policy')}
        </LinkComponent>
      </Trans>
    </div>
  );
};
