import {
  MAP_ROUTE, type MapRouteParams, PUBLIC_MAP_ROUTE, type PublicMapRouteParams,
} from '~/_shared/constants/routes';
import { type LatLng } from '~/_shared/types/latLng';
import type { OwnerMapInfo } from '~/_shared/types/map';
import {
  buildUrl, buildUrlWithPath, getPublicBaseUrl, type UrlParameters,
} from '~/_shared/utils/url/url.helpers';

export const MAP_SETTINGS_SIDEBAR_OPEN_URL_PARAM = 'mapSettings';

export const getMapUrl = (mapId: number, queryParams?: UrlParameters, fragmentParams?: UrlParameters) => {
  const url = buildUrlWithPath<MapRouteParams>(MAP_ROUTE, { mapId: mapId.toString() });
  return buildUrl(url, queryParams, fragmentParams);
};

export const getAbsolutePresentationalMapUrl = (shareId = '') => {
  return `${getPublicBaseUrl()}${getRelativeMapPresentationalUrl(shareId)}`;
};

export const getRelativeMapPresentationalUrl = (shareId: string) => {
  return buildUrlWithPath<PublicMapRouteParams>(PUBLIC_MAP_ROUTE, { mapShareId: shareId });
};

// https://gist.github.com/dbrockman/7901077
export const getBoundsAtLatLngWithZoom = (map: google.maps.Map, center: LatLng, zoom: number) => {
  const p = map.getProjection();
  if (!p) {
    return null;
  }
  const el = map.getDiv().getBoundingClientRect();
  const zf = Math.pow(2, zoom) * 2;
  // eslint-disable-next-line no-bitwise
  const dw = (el.width | 0) / zf;
  // eslint-disable-next-line no-bitwise
  const dh = (el.height | 0) / zf;
  const cpx = p.fromLatLngToPoint(center);
  if (cpx === null) {
    return null;
  }
  return new google.maps.LatLngBounds(
    p.fromPointToLatLng(new google.maps.Point(cpx.x - dw, cpx.y + dh)),
    p.fromPointToLatLng(new google.maps.Point(cpx.x + dw, cpx.y - dh)));
};

export const waitForMapIdle = (map: google.maps.Map): Promise<void> => {
  return new Promise(function (resolve) {
    google.maps.event.addListenerOnce(map, 'idle', () => {
      resolve();
    });
  });
};

type RelevantMapInfoData = {
  id: number;
  parentMap?: {
    id: number;
  };
};
export const compareIdToParentMapOrMapId = (id: number, mapInfo: RelevantMapInfoData | null) =>
  id === (mapInfo?.parentMap?.id ?? mapInfo?.id);

export const getMapOwnerUserId = (mapInfo: OwnerMapInfo) => mapInfo.created.id;
