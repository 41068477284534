import { css } from '@emotion/react';
import {
  faCheck, faCloudArrowUp, faPieChart, faUndo,
} from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type FC, useEffect, useMemo,
  useRef, useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import type { Theme } from '~/_shared/themes/theme.model';
import {
  getIsAppleMobileDevice,
  isMobileDevice as getIsMobileDevice, isSafari,
} from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import {
  type SidebarHeaderArrowsProps,
  type SidebarHeaderButtonProps,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarHeader/sidebarHeader.component';
import { SidebarTitleContainer } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.container';
import { type SidebarTitle } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { type StreetViewLocation } from '~/store/frontendState/mapTools/streetView/streetView.state';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { ImageGalleryComponent } from '../_shared/components/imageGallery/imageGallery.component';
import { useTranslation } from '../_shared/utils/hooks';
import { useClickOutsideClassNameListener } from '../_shared/utils/hooks/useClickOutsideClassNameListener';
import { SidebarContentContainer } from '../sidebar/sidebarApps/rightSidebar/sidebarContent/sidebarContent.container';
import { LOCATION_PANEL_DATA_CLASSNAME } from './customizeLocationPanel.constants';
import { MenuButtons } from './customizeLocationPanel.enums';
import { EditableOptionsComponent } from './editableOptionsPanel/editableOptions.component';
import { LocationDataComponent } from './locationData/locationData.component';
import { type PanelSettingsManagementProps } from './locationData/usePanelSettingsManagement.hook';
import {
  type EditLocationCallbackProps,
  type RemoveLocationCallbacks, type ToolLocationCallbackProps,
} from './roundButtonMenu/contextMenuStructure/contextMenuStructure.types';
import {
  leftLocationMenu, rightLocationMenu,
} from './roundButtonMenu/roundButtonMenu.constants';
import {
  type GalleryItem, useLocationImageGallery,
} from './useLocationImageGallery';
import { type LocationImage } from './useLocationImages.hook';

const fullHeightStyle = css({
  height: '100%',
  position: 'relative',
});

const locationDataWrapperStyle = (isFullWidth: boolean) => css(fullHeightStyle, {
  width: isFullWidth ? '100%' : 'unset',
});

const dataManagementWrapperStyle = ({ galleryHeight }: { readonly galleryHeight: number }) => css({
  height: `calc(100% - ${galleryHeight}px)`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
});

const editablePanelStyle = css({
  display: 'flex',
  flexGrow: 1,
  position: 'relative',
});

const bottomButtonWrapperStyle = (theme: Theme) => css({
  borderTop: '1px solid ' + theme.borderColors.primary,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px',
});

const bottomButtonStyle = css({
  height: '40px',
  padding: '11px',
  width: '130px',
});

const bottomRestoreButtonStyle = css({
  height: '40px',
  marginLeft: 16,
  padding: '11px',
  width: '180px',
});

const buttonIconStyle = css({
  float: 'left',
});

const imageGalleryStyle = ({ height }: { readonly height: number }) => css({
  height,
});

const animationConfig = { tension: 280, friction: 25, clamp: true };
const buttonsMaxHeight = 75;
const imageGalleryHeight = 225;

type CustomizeLocationPanelHeaderProps = Readonly<{
  openLocationDetails?: () => void;
  selectLocation: (index: number) => void;
  selectedLocationIndex: number;
  totalLocations: number;
}>;

type CustomizeLocationPanelProps = Readonly<{
  editLocationCallbacksProps: EditLocationCallbackProps;
  headerProps: CustomizeLocationPanelHeaderProps;
  isEditingDisabled: boolean;
  isStreetViewActive: boolean;
  itemManagementProps: PanelSettingsManagementProps;
  locationDescription: SidebarTitle;
  removeMarkerCallbacks: RemoveLocationCallbacks;
  toolLocationCallbacks: ToolLocationCallbackProps;
  selectedMenuButton: MenuButtons | null;
  images: LocationImage[];
  isMobileVersion: boolean;
  streetViewLocation: StreetViewLocation | null;
  streetViewLocationLoading: boolean;
  isLayeredMapConnected: boolean;
  isLoading: boolean;

  onClose: () => void;
  onEditClick?: () => void;
  onExitEditMode: () => void;
  onImageGalleryItemClicked: (imageIndex: number) => void;
  onSaveSettings: () => void;
  onSelectMenuButton: (button: (MenuButtons | null)) => void;
  openStreetView: () => void;
}>;

export const CustomizeLocationPanelComponent: FC<CustomizeLocationPanelProps> = props => {
  const { selectedMenuButton, onSelectMenuButton } = props;
  const isMapPresentational = useIsMapPresentationalSelector();
  const isMobileDevice = getIsMobileDevice();
  const isAppleMobileDevice = getIsAppleMobileDevice();
  const deleteMarkerMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const editLocationMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const syncDataMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const toolsMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const initialSaveRestoreStyle = useMemo(() => ({ height: 0, overflow: 'hidden', config: animationConfig }), []);
  const finalSaveRestoreStyle = useMemo(() => ({ height: buttonsMaxHeight, overflow: 'hidden', config: animationConfig }), []);
  const [saveRestoreStyle, saveRestoreStyleApi] = useSpring(() => initialSaveRestoreStyle);
  const [currentlySelectedImage, setCurrentlySelectedImage] = useState<GalleryItem | null>(null);
  const [t] = useTranslation();

  const showStreetViewImage = useMemo(() => (
    props.itemManagementProps.generalSettings.showStreetViewImage && !props.streetViewLocationLoading
  ), [props.itemManagementProps.generalSettings.showStreetViewImage, props.streetViewLocationLoading]);

  const gallery = useLocationImageGallery({
    images: props.images,
    onImageClick: props.onImageGalleryItemClicked,
    onStreetViewClick: props.openStreetView,
    streetViewLocation: showStreetViewImage ? props.streetViewLocation : null,
  });

  const isInEditing = selectedMenuButton === MenuButtons.SettingsAndCustomization;

  const showImageGallery = useMemo(() => (
    !isInEditing && !!gallery.galleryItems.length
  ), [gallery.galleryItems.length, isInEditing]);

  useEffect(() => {
    const isLocationEdited = selectedMenuButton === MenuButtons.SettingsAndCustomization;
    saveRestoreStyleApi.start(isLocationEdited ? finalSaveRestoreStyle : initialSaveRestoreStyle);
  }, [finalSaveRestoreStyle, initialSaveRestoreStyle, selectedMenuButton, saveRestoreStyleApi]);

  useClickOutsideClassNameListener(
    props.itemManagementProps.clickOutsideCallback,
    LOCATION_PANEL_DATA_CLASSNAME,
    props.itemManagementProps.selectedItemId !== null || !!props.itemManagementProps.selectedLocationType
  );

  const sidebarMenuProps = useMemo(() => ({
    roundButtonLeftDetails: leftLocationMenu({
      onMenuSelect: props.openStreetView,
      isActive: props.isStreetViewActive && !!props.streetViewLocation,
      t,
    }),
    roundButtonRightDetails: rightLocationMenu({
      onMenuSelect: onSelectMenuButton,
      buttonRefs: {
        editLocation: editLocationMenuButtonRef,
        deleteMarker: deleteMarkerMenuButtonRef,
        tools: toolsMenuButtonRef,
        syncData: syncDataMenuButtonRef,
      },
      editLocationCallbacksProps: props.editLocationCallbacksProps,
      removeLocationCallbacks: props.removeMarkerCallbacks,
      isEditingDisabled: props.isEditingDisabled,
      toolLocationCallbacks: props.toolLocationCallbacks,
      t,
      isMapPresentational,
      isAppleMobileDevice,
      isMobileDevice,
      isMobileScreen: props.isMobileVersion,
      isLayeredMapConnected: props.isLayeredMapConnected,
    }),
    selectedButton: selectedMenuButton,
  }), [props.openStreetView, props.isStreetViewActive, props.streetViewLocation, props.editLocationCallbacksProps,
    props.removeMarkerCallbacks, props.isEditingDisabled, props.toolLocationCallbacks, props.isMobileVersion,
    props.isLayeredMapConnected, t, onSelectMenuButton, isMapPresentational, isMobileDevice, selectedMenuButton,
    isAppleMobileDevice,
  ]);

  const arrowProps: SidebarHeaderArrowsProps | undefined = useMemo(() => {
    if (props.headerProps.totalLocations <= 1) {
      return undefined;
    }

    const isLeftDisabled = props.headerProps.selectedLocationIndex < 1;
    const isRightDisabled = props.headerProps.selectedLocationIndex >= props.headerProps.totalLocations - 1;
    return {
      right: {
        isDisabled: isRightDisabled,
        onClick: () => props.headerProps.selectLocation(props.headerProps.selectedLocationIndex + 1),
      },
      left: {
        isDisabled: isLeftDisabled,
        onClick: () => props.headerProps.selectLocation(props.headerProps.selectedLocationIndex - 1),
      },
    };
  }, [props.headerProps]);

  const buttonProps: SidebarHeaderButtonProps | undefined = useMemo(() => {
    if (props.headerProps.openLocationDetails) {
      return {
        icon: faPieChart,
        tooltipLabel: t('Stacked marker metrics'),
        onClick: props.headerProps.openLocationDetails,
      };
    }

    return undefined;
  }, [props.headerProps.openLocationDetails, t]);

  const galleryUploadButtonProps = useMemo(() => props.isEditingDisabled ? undefined : {
    icon: faCloudArrowUp,
    triggerTooltip: t('Upload Images'),
    onClick: props.editLocationCallbacksProps.editImages.callback,
  }, [props.editLocationCallbacksProps.editImages.callback, props.isEditingDisabled, t]);

  return (
    <>
      <SidebarContentContainer
        isLoading={props.isLoading}
        body={(!props.itemManagementProps.bubbleRecords?.items ? undefined : (
          <div css={fullHeightStyle}>
            <div css={dataManagementWrapperStyle({ galleryHeight: showImageGallery ? imageGalleryHeight : 0 })}>
              <DndProvider
                backend={TouchBackend}
                options={{ enableMouseEvents: true }}
              >
                <div css={editablePanelStyle}>
                  <div css={locationDataWrapperStyle(!isInEditing)}>
                    <LocationDataComponent
                      allBasicItemsOrdered={props.itemManagementProps.bubbleRecords?.orderedBasicItems}
                      allContactItemsOrdered={props.itemManagementProps.bubbleRecords?.orderedContactItems}
                      bubbleItems={props.itemManagementProps.bubbleRecords?.items}
                      isBeingEdited={isInEditing}
                      onDrop={props.itemManagementProps.dropItems}
                      onSelectItem={props.itemManagementProps.selectItem}
                      retiredOrdered={props.itemManagementProps.bubbleRecords?.retired}
                      selectedItemId={props.itemManagementProps.selectedItemId}
                      isMobileScreen={props.isMobileVersion}
                      onHideItem={props.itemManagementProps.deactivateItem}
                      onShowItem={props.itemManagementProps.activateItem}
                      bulkStyles={props.itemManagementProps.bulkStyle}
                    />
                  </div>
                  {!props.isMobileVersion && (
                    <EditableOptionsComponent
                      bubbleItems={props.itemManagementProps.bubbleRecords?.items}
                      bulkStyle={props.itemManagementProps.bulkStyle}
                      generalSettings={props.itemManagementProps.generalSettings}
                      locationDescriptionStyle={props.itemManagementProps.locationDescriptionStyle}
                      onChangeGeneral={props.itemManagementProps.modifyGeneral}
                      onChangeItemStyle={props.itemManagementProps.modifyItemVisuals}
                      onChangeLocationDescriptionStyle={props.itemManagementProps.modifyLocationVisuals}
                      selectedItemId={props.itemManagementProps.selectedItemId}
                      selectedLocationDescriptionType={props.itemManagementProps.selectedLocationType}
                      onStopEditing={props.onExitEditMode}
                    />
                  )}
                </div>
              </DndProvider>
              <animated.div style={saveRestoreStyle} >
                <div css={bottomButtonWrapperStyle} >
                  <div>
                    <ButtonComponent
                      className={LOCATION_PANEL_DATA_CLASSNAME}
                      buttonStyle={ButtonStyle.Secondary}
                      css={bottomButtonStyle}
                      prefixIcon={faUndo}
                      prefixIconStyle={buttonIconStyle}
                      onClick={props.itemManagementProps.resetToLastSaved}
                      text={t('Reset')}
                    />
                    <ButtonComponent
                      className={LOCATION_PANEL_DATA_CLASSNAME}
                      buttonStyle={ButtonStyle.Secondary}
                      css={bottomRestoreButtonStyle}
                      prefixIcon={faUndo}
                      prefixIconStyle={buttonIconStyle}
                      onClick={props.itemManagementProps.resetToDefault}
                      text={t('Restore Default')}
                    />
                  </div>
                  <ButtonComponent
                    className={LOCATION_PANEL_DATA_CLASSNAME}
                    css={bottomButtonStyle}
                    isDisabled={!props.itemManagementProps.areChangesPending}
                    prefixIcon={faCheck}
                    prefixIconStyle={buttonIconStyle}
                    onClick={props.onSaveSettings}
                    text={t('Save')}
                  />
                </div>
              </animated.div>
            </div>
            {showImageGallery && (
              <ImageGalleryComponent
                items={gallery.galleryItems}
                renderItem={gallery.renderGalleryItem}
                css={imageGalleryStyle({ height: imageGalleryHeight })}
                onImageSelect={setCurrentlySelectedImage}
                onExpandClick={currentlySelectedImage?.onExpandClick}
                extraButton={galleryUploadButtonProps}
                triggerTooltip={currentlySelectedImage?.type && currentlySelectedImage.type === 'streetview' ? t('Launch Street View') : t('Expand Image')}
                allowOverflow={isSafari()} // https://maptive.atlassian.net/browse/MD-4746
              />
            )}
          </div>
        ))}
        description={(
          <SidebarTitleContainer
            onEditClick={props.isMobileVersion ? undefined : props.onEditClick}
            isEditable={selectedMenuButton === MenuButtons.SettingsAndCustomization}
            sidebarTitle={props.locationDescription}
            sidebarTitleStyle={props.itemManagementProps.locationDescriptionStyle}
            onSelectItem={props.itemManagementProps.selectLocationDescription}
            onXClick={props.onClose}
            selectedTitleItem={props.itemManagementProps.selectedLocationType}
          />
        )}
        headerProps={{
          arrowProps,
          buttonProps,
          totalLocations: props.headerProps.totalLocations,
          currentLocationIndex: props.headerProps.selectedLocationIndex,
        }}
        menuProps={sidebarMenuProps}
      />
    </>
  );
};
