import differenceInHours from 'date-fns/differenceInHours/index.js';
import {
  type Settings, SettingsSelectedOption,
} from './settings.types';

const ASK_ME_LAYER_COOLDOWN_PERIOD = 24;

type UpdatedBaseMap = {
  id: number;
  versionDifference: number;
};

export type Resolution = {
  allHandled: boolean;
  updatedBaseMaps: UpdatedBaseMap[];
};

export type LinkedBaseMap = {
  id: number;
  name: string;
  latestVersion: number;
  currentVersion: number;
  latestSnapshot: number;
  wasUpdated: boolean;
};

const AllHandledResolution = {
  allHandled: true,
  updatedBaseMaps: [],
};

const calculateIgnoreMapUpdate = (mapSettings: Settings | null, linkedBaseMap: LinkedBaseMap, currentTime: Date) => {
  if (mapSettings === null) {
    return false;
  }

  switch (mapSettings.selectedOption) {
    case SettingsSelectedOption.DontAskAgain:
      return true;
    case SettingsSelectedOption.SkipUpdate: {
      const currentUpdateIsSameAsIgnored = mapSettings.skippedVersion === linkedBaseMap.latestVersion &&
        mapSettings.skippedSnapshot === linkedBaseMap.latestSnapshot;

      return currentUpdateIsSameAsIgnored;

    }
    case SettingsSelectedOption.AskMeLater: {
      const currentUpdateIsSameAsPostponed = mapSettings.postponedVersion === linkedBaseMap.latestVersion &&
        mapSettings.postponedSnapshot === linkedBaseMap.latestSnapshot;
      const wasPostponedLessThanCooldownPeriod = differenceInHours(
        currentTime, new Date(mapSettings.postponedAt), { roundingMethod: 'ceil' }
      ) <= ASK_ME_LAYER_COOLDOWN_PERIOD;
      if (currentUpdateIsSameAsPostponed) {
        return wasPostponedLessThanCooldownPeriod;
      }
      return false;
    }

    default:
      throw Error('Unexpected option');
  }
};

export const getBaseMapsRequiringUpdate = (
  linkedBaseMaps: LinkedBaseMap[], getMapSettings: (baseMapId: number) => Settings | null, currentTime: Date
): Resolution => {
  const allCurrent = linkedBaseMaps.every(map => !map.wasUpdated);
  if (allCurrent) {
    return AllHandledResolution;
  }
  const updatedBaseMaps = linkedBaseMaps.filter(map => map.wasUpdated);
  const mapsToHandle = updatedBaseMaps.filter(baseMap => {
    const mapSettings = getMapSettings(baseMap.id);

    const ignoreUpdate = calculateIgnoreMapUpdate(mapSettings, baseMap, currentTime);
    return !ignoreUpdate;
  });

  if (mapsToHandle.length === 0) {
    return AllHandledResolution;
  }

  return {
    allHandled: false,
    updatedBaseMaps: mapsToHandle.map(map => ({
      id: map.id,
      versionDifference: map.latestVersion - map.currentVersion,
    })),
  };
};
