import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { flatten } from '~/_shared/utils/collections/collections';
import { type BaseMapInfos } from '~/map/layered/layering.helpers';
import { geocodingResumeRequest } from '~/store/geocoding/geocoding.actionCreators';
import { useSpreadsheetGeocodingState } from '~/store/geocoding/useSpreadsheetGeocodingState.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { type MapListingItem } from '../../../listing/item/mapListingItem';
import {
  type SelectMapsAddBaseMapProps,
  type SelectMapsRemoveBaseMapsProps,
  SelectMapsSectionComponent,
} from './selectMapsSection.component';

type SelectMapsSectionContainerProps = Readonly<{
  currentBaseMaps?: Readonly<BaseMapInfos>;
  isDisabled: boolean;
  selectMapAddOrRemove?: SelectMapsRemoveBaseMapsProps | SelectMapsAddBaseMapProps;
  selectedMaps: Array<MapListingItem | null>;

  onChangeAddedMaps: (maps: Array<MapListingItem | null>) => void;
  onMapSelected: (map: MapListingItem, mapIndex: number) => void;
  onSkipToMatchUp?: () => void;
  onSubmit: () => void;
}>;

export const SelectMapsSectionContainer: FC<SelectMapsSectionContainerProps> = (props) => {
  const { selectedMaps, onChangeAddedMaps, currentBaseMaps } = props;

  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const spreadsheetGeocodingState = useSpreadsheetGeocodingState();

  const onAddNewMap = useCallback(() => {
    onChangeAddedMaps([...selectedMaps, null]);
  }, [selectedMaps, onChangeAddedMaps]);

  const onRemoveNewMap = useCallback((index: number) => {
    const newSelectedMaps = selectedMaps.filter((_map, mapIndex) => mapIndex !== index);
    onChangeAddedMaps(newSelectedMaps);
  }, [selectedMaps, onChangeAddedMaps]);

  // Ignore maps that are already selected and not matched up, as well as layered map
  const eligibleMapsFilter = useCallback((currentMapIndex: number, mapItem: MapListingItem) => {
    const currentMap = selectedMaps[currentMapIndex];
    const mapsSelectedInAnotherFieldIds = selectedMaps
      .filter(map => map !== currentMap && map !== null)
      .map((map: MapListingItem) => map.id);

    const isMatchedUp = !mapItem.isMatchupRequired;
    const isSelectedInAnotherField = mapsSelectedInAnotherFieldIds.includes(mapItem.id);
    const isExistingBaseMap = currentBaseMaps ? Object.values(currentBaseMaps).some(item => item.id === mapItem.id) : false;
    const isLayered = mapItem.isLayered;

    return isMatchedUp && !isSelectedInAnotherField && !isLayered && !isExistingBaseMap;
  }, [selectedMaps, currentBaseMaps]);

  const getGeocodingStateForMapListingItem = useCallback((mapItem: MapListingItem) => {
    const geocodingState = spreadsheetGeocodingState.getCombinedProgressForMultipleRealSpreadsheets(
      flatten(mapItem.spreadSheets.map(spreadsheet => spreadsheet.realSpreadsheets.map(realSpreadsheet => realSpreadsheet.realSpreadsheetId)))
    );
    return geocodingState || null;
  }, [spreadsheetGeocodingState]);

  const isAnySelectedMapNotGeocoded = useMemo(() => (
    selectedMaps.some(map => {
      const geocodingState = map ? getGeocodingStateForMapListingItem(map) : null;
      if (geocodingState) {
        return geocodingState.inProgress || !!geocodingState.pausedRealSpreadsheetIds.length;
      }
      return false;
    })
  ), [getGeocodingStateForMapListingItem, selectedMaps]);

  const onStartGeocoding = useCallback((realSpreadsheetId: number) => {
    if (!clientId) {
      return;
    }
    dispatch(geocodingResumeRequest(clientId, realSpreadsheetId));
  }, [clientId, dispatch]);

  return (
    <SelectMapsSectionComponent
      currentBaseMaps={props.currentBaseMaps}
      eligibleMapsFilter={eligibleMapsFilter}
      getGeocodingStateForMapListingItem={getGeocodingStateForMapListingItem}
      isDisabled={props.isDisabled || isAnySelectedMapNotGeocoded}
      selectMapsAddOrRemoveProps={props.selectMapAddOrRemove}
      selectedMaps={props.selectedMaps}
      onAddNewMap={onAddNewMap}
      onMapSelected={props.onMapSelected}
      onRemoveNewMap={onRemoveNewMap}
      onSkipToMatchUp={props.onSkipToMatchUp}
      onStartGeocoding={onStartGeocoding}
      onSubmit={props.onSubmit}
    />
  );
};
