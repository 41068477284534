import { type GeoJsonFeature } from '~/_shared/types/polygon/polygon.types';
import { apiPost } from '../_shared/utils/api/api.helpers';

export type Transportation = 'cycling' | 'driving' | 'driving+train' | 'public_transport' | 'walking' | 'coach' | 'bus' |
'train' | 'ferry' | 'driving+ferry' | 'cycling+ferry' | 'cycling+public_transport';

export type DriveTimeErrorResponseMessage =
  'TRAVEL_TIME_EMPTY_TERRITORIES' |
  'TRAVEL_TIME_INVALID_AREA' |
  'TRAVEL_TIME_INVALID_START' |
  'TRAVEL_TIME_API_FAILED' |
  'TRAVEL_TIME_OTHER' |
  'TRAVEL_TIME_LIMIT_REACHED';

type DriveTimeTimeMapServerModel = {
  list: ReadonlyArray<{
    id: string;
    longitude: number;
    latitude: number;
    travel_time: number;
    transportation: Transportation;
    no_holes?: boolean;
  }>;
};

export type DriveTimeTimeMapRequest = ReadonlyArray<{
  id: string;
  longitude: number;
  latitude: number;
  travel_time: number;
  transportation: Transportation;
  no_holes?: boolean;
}>;

export type DriveTimeRouteResponseSuccess = {
  success: true;
  data: ReadonlyArray<GeoJsonFeature>;
};

export type DriveTimeRouteResponseError = {
  success: false;
  message: DriveTimeErrorResponseMessage;
};

export type DriveTimeRouteResponseType = DriveTimeRouteResponseSuccess | DriveTimeRouteResponseError;

type DriveTimeRouteServerModel = {
  data: ReadonlyArray<DriveTimeRouteResponseType>;
};

export type DriveTimeRouteResponse = Map<string, { id: string } & DriveTimeRouteResponseType>;

export const getDriveTimeRoute = (clientId: number, mapId: number | null, list: DriveTimeTimeMapRequest): Promise<DriveTimeRouteResponse> => {
  const requestUrl = `/api/clients/${clientId}/travel-time/time-map` + (mapId ? `?map_id=${mapId}` : '');
  const request: DriveTimeTimeMapServerModel = { list };

  const promise = apiPost<DriveTimeRouteServerModel>(requestUrl, request);

  return promise.then(res => {
    return new Map(request.list.map(({ id }, index) => {
      const responseItem = res.data[index];
      return [id, {
        id,
        ...responseItem ?? {
          success: false,
          message: 'TRAVEL_TIME_OTHER',
        },
      }];
    }));
  });
};
