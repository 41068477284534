export const MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS = 'MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS';
export const MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE = 'MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE';
export const MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM = 'MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM';
export const MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM = 'MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM';
export const MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM = 'MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM';

export const MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS = 'MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS';

export const MAP_SETTINGS_DRAWING_ITEMS_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS = [
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE,
  MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM,
];
