import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ALL_MAP_RELATED_PAGES } from '~/_shared/components/modal/modal.constants';
import { SUBSCRIBE_ROUTE } from '~/_shared/constants/routes';
import { isPresentationalMapByPathname } from '~/_shared/presentationMode/presentationModeContext';
import { isPaidLicense } from '~/_shared/types/client/license';
import { ExportImageMode } from '~/_shared/types/exportImage/exportImage';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useWindowHeight } from '~/_shared/utils/hooks/useWindowHeight';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import { isMapRoute } from '~/_shared/utils/tests/router.helpers';
import { useItemsVisibleOnPresentationalMap } from '~/app/maptiveApp/useItemsVisibleOnPresentationalMap.hook';
import { canMemberManageClient } from '~/clientTeamManagement/memberRoles.helpers';
import { decideIsEnoughVerticalSpaceForMenuItems } from '~/mainMenu/mainMenu.helpers';
import { getMapUrl } from '~/map/map.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { closeLeftSidebar } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { mapPresentationalSetShowPresentationalMenu } from '~/store/frontendState/mapPresentational/mapPresentational.actionCreators';
import {
  useIsPresentationalMenuShownSelector, usePresentationalMapViewSelector,
} from '~/store/frontendState/mapPresentational/mapPresentational.selectors';
import { MapPresentationalView } from '~/store/frontendState/mapPresentational/mapPresentational.state';
import {
  useIsMapSettingsSyncInProgressSelector, useMapSettingsSyncFailedSelector,
} from '~/store/frontendState/processing/processing.selectors';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { useMapSettingsConcurrencySelector } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.selectors';
import {
  usePublicMapSettingEnabledPresentationalMapToolsSelector, usePublicMapSettingsSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useAreMapSettingsReadySelector } from '~/store/mapSettings/ready/mapSettingsReady.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { openModal } from '~/store/modal/modal.actionCreators';
import {
  useUserCurrentClientSelector, useUserDataSelector,
} from '~/store/userData/userData.selectors';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { DesktopTopBarComponent } from './desktopTopBarComponent';
import { StatusIndicatorMode } from './statusIndicator.component';

export type DesktopTopBarProps = {
  showUndoButton: boolean;
  presentationalMode: boolean;
  title?: string;
};

export const DesktopTopBarContainer: FC<DesktopTopBarProps> = (props) => {
  const dispatch = useDispatch();
  const mapInfo = useMapInfoDataSelector();
  const mapViewName = useSelector(state => state.map.mapInfo.data?.name ?? undefined);
  const parentMapName = useSelector(state => state.map.mapInfo.data?.parentMap?.name ?? undefined);
  const parentMapId = mapInfo?.parentMap?.id;
  const mapLink = parentMapId ? getMapUrl(parentMapId) : undefined;
  const location = useLocation();
  const windowHeight = useWindowHeight();

  const isPresentationalMenuOpen = useIsPresentationalMenuShownSelector();
  const itemsVisibleOnPresentationalMap = useItemsVisibleOnPresentationalMap();
  const mapSettingsReady = useAreMapSettingsReadySelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const exportImageSettings = useMapSettingsExportImageSettingsSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const mapSettingsSyncFailedSelector = useMapSettingsSyncFailedSelector();
  const availablePublicMapTools = usePublicMapSettingEnabledPresentationalMapToolsSelector();
  const mapSettingsConcurrency = useMapSettingsConcurrencySelector();
  const presentationalMapView = usePresentationalMapViewSelector();
  const userData = useUserDataSelector();
  const isViewPresentational = useMemo(() => isPresentationalMapByPathname(location.pathname), [location.pathname]);
  const isMapPage = useMemo(() => isMapRoute(location.pathname), [location.pathname]);
  const currentPage = useGetCurrentPageInfo().page;

  const additionalToolMenuButtonCallback = useCallback(() => {
    if (isViewPresentational
      && !decideIsEnoughVerticalSpaceForMenuItems(windowHeight, itemsVisibleOnPresentationalMap.size)) {
      dispatch(closeLeftSidebar());
    }
  }, [dispatch, isViewPresentational, itemsVisibleOnPresentationalMap.size, windowHeight]);

  const onTogglePresentationalMenu = useCallback(() => {
    if (additionalToolMenuButtonCallback && !isPresentationalMenuOpen) {
      additionalToolMenuButtonCallback();
    }
    dispatch(mapPresentationalSetShowPresentationalMenu(!isPresentationalMenuOpen));
  },
  [dispatch, additionalToolMenuButtonCallback, isPresentationalMenuOpen]);

  const dispatchProps = useMemo(() => ({
    onTogglePresentationalMenu,
    onUndo: () => dispatch(openModal(ModalType.Undo)),
    onCreateNewMap: () => dispatch(openModal(ModalType.CreateNewMap, {})),
  }), [dispatch, onTogglePresentationalMenu]);

  const showOnlyInformativeUi = useMemo(() => {
    const mode = exportImageSettings?.mode;

    return props.presentationalMode &&
      (
        (mode === ExportImageMode.MapAndLegend || mode === ExportImageMode.Map) ||
        presentationalMapView !== MapPresentationalView.Regular // don't show top left menu if not in "regular" view to prevent app crash
      );
  }, [exportImageSettings?.mode, props.presentationalMode, presentationalMapView]);

  const mapSettingsStatus =
    isMapSettingsSyncInProgress ? StatusIndicatorMode.Saving
      : mapSettingsConcurrency.isOffline ? StatusIndicatorMode.Offline
        : mapSettingsSyncFailedSelector ? StatusIndicatorMode.Error
          : StatusIndicatorMode.None;

  const statusIndicator =
    testingModeEnabled() ? StatusIndicatorMode.TestMode
      : isMapPage ? mapSettingsStatus
        : StatusIndicatorMode.None;

  const showMapName = ALL_MAP_RELATED_PAGES.has(currentPage);
  const mapDisplayName = showMapName ? parentMapName ?? mapViewName : undefined;
  const mapDisplayViewName = showMapName && parentMapName && !exportImageSettings?.mode ? mapViewName : undefined;

  const isPurchaseVisible = canMemberManageClient(userData.clientRole) &&
    !isPaidLicense(userData.currentClient?.license.type || null);

  const showLegend = publicMapSettings.displayLegendOnly || publicMapSettings.legendReplacesGrouping;
  const showToolsButton = props.presentationalMode && !showOnlyInformativeUi && (showLegend || !!availablePublicMapTools.length);

  const { onSubscribeClick } = useOnSubscribe();

  return (
    <DesktopTopBarComponent
      accountName={props.title ?? userData.clientName ?? undefined}
      isPresentationalMenuOpen={isPresentationalMenuOpen}
      allowPurchase={isPurchaseVisible}
      mapLink={isViewPresentational ? undefined : mapLink}
      mapName={mapDisplayName}
      mapViewName={mapDisplayViewName}
      presentationalMode={props.presentationalMode}
      publicMapSettings={props.presentationalMode && mapSettingsReady ? publicMapSettings : undefined}
      showOnlyInformativeUi={showOnlyInformativeUi}
      showToolsButton={showToolsButton}
      showUndoButton={props.showUndoButton}
      statusIndicator={statusIndicator}
      onSubscribeClick={onSubscribeClick}
      {...dispatchProps}
    />
  );
};

const useOnSubscribe = () => {
  const isSubscriptionUnavailable = !useUserCurrentClientSelector()?.migrationDone;

  const { openModal: openSubscriptionManagementUnavailableModal } = useModal(ModalType.SubscriptionManagementUnavailable);

  const onSubscribeClick = useCallback(() => {
    if (!isSubscriptionUnavailable) {
      openUrlInNewTab(SUBSCRIBE_ROUTE);
    }
    else {
      openSubscriptionManagementUnavailableModal();
    }
  }, [isSubscriptionUnavailable, openSubscriptionManagementUnavailableModal]);

  return {
    onSubscribeClick,
  };
};
