import { css } from '@emotion/react';
import { useCallback } from 'react';
import { CheckboxComponent } from '../_shared/baseComponents/checkbox';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type ThemeProps } from '../_shared/types/themeProps';
import { type UndoSelectorItem } from '../_shared/types/undo/undoSelectorItem';
import { togglePresence } from '../_shared/utils/collections/collections';

const itemStyle = css({
  cursor: 'default',
  display: 'flex',
  gap: '4px',
});

const checkboxStyle = css({
  alignItems: 'start',
});

const listingStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const itemTextStyle = css({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '4px',
});

const itemTitleStyle = css({ fontWeight: 600 });

const titleStyle = css({
  marginBottom: '12px',
  textTransform: 'uppercase',
});

const dateStyle = (props: ThemeProps) => css({ color: props.theme.textColors.tertiary });

type UndoTitle = Readonly<{
  title: string;
  emptyTitle: string;
}>;

type UndoSelectorProps = Readonly<{
  items: ReadonlyArray<UndoSelectorItem>;
  selectedItemIds: ReadonlySet<string | number>;
  title: UndoTitle;

  onSelectItemIds: (itemIds: ReadonlySet<string | number>) => void;
}>;

export const UndoSpecificActionSelectorComponent: React.FC<UndoSelectorProps> = props => {
  const theme = useTheme();

  const { onSelectItemIds } = props;

  const handleToggle = useCallback((id: string | number) =>
    onSelectItemIds(togglePresence(props.selectedItemIds, id)),
  [onSelectItemIds, props.selectedItemIds]);

  return (
    <>
      <div css={titleStyle}>
        {props.items.length ? props.title.title + ':' : props.title.emptyTitle}
      </div>
      <div css={listingStyle}>
        {props.items.map((item) => (
          <div
            css={itemStyle}
            key={item.id}
          >
            <CheckboxComponent
              checkedSetter={() => handleToggle(item.id)}
              css={checkboxStyle}
              isChecked={props.selectedItemIds.has(item.id)}
            />
            <div
              css={itemTextStyle}
              onClick={() => handleToggle(item.id)}
            >
              <div css={itemTitleStyle}>
                {`${item.caption}: ${item.description}`}
              </div>
              <div css={dateStyle({ theme })}>
                {item.date.toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
