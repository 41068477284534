export const SPREADSHEET_MARKER_CLICKED = 'SPREADSHEET_MARKER_CLICKED';
export const SPREADSHEET_MARKER_HOVERED = 'SPREADSHEET_MARKER_HOVERED';
export const ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED = 'ACTIVE_MAP_ELEMENTS_CLUSTER_HOVERED';
export const ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED = 'ACTIVE_MAP_ELEMENTS_CLUSTER_HOVER_STOPPED';
export const SPREADSHEET_MARKER_HOVER_STOPPED = 'SPREADSHEET_MARKER_HOVER_STOPPED';
export const ACTIVE_MAP_ELEMENTS_CLEAR_STATE = 'ACTIVE_MAP_ELEMENTS/CLEAR_STATE';
export const ACTIVE_MAP_ELEMENTS_SET_ACTIVE_MARKER_NAVIGATION_INDEX = 'ACTIVE_MAP_ELEMENTS/SET_ACTIVE_MARKER_NAVIGATION_INDEX';
export const ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER = 'ACTIVE_MAP_ELEMENTS/DEACTIVATE_ACTIVE_MARKER';

export const ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY = 'ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY';
export const ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY = 'ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY';

export const ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY = 'ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY';
export const ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST = 'ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST';

export const ACTIVE_MAP_ELEMENTS_SET_ACTIONS = [
  SPREADSHEET_MARKER_CLICKED,
  ACTIVE_MAP_ELEMENTS_DEACTIVATE_ACTIVE_MARKER,
  ACTIVE_MAP_ELEMENTS_CLEAR_STATE,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_BOUNDARY_TERRITORY,
  ACTIVE_MAP_ELEMENTS_SET_ACTIVE_PROXIMITY,
  ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST,
];
