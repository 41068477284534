import { useMemo } from 'react';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';
import { DataType } from '../../spreadsheetData/spreadsheetData.state';

const spreadsheetTableSelector = (state: AppState) => state.frontendState.spreadsheetTable;
export const useSpreadsheetTableSelector = () => useSelector(spreadsheetTableSelector);

const spreadsheetTableSelectedRowsSelector = (state: AppState) => state.frontendState.spreadsheetTable.selectedRows;
export const useSpreadsheetTableSelectedRowsSelector = () => useSelector(spreadsheetTableSelectedRowsSelector);

const isRemoveInProgressSelector = (state: AppState) => state.frontendState.spreadsheetTable.isRemoveInProgress;
export const useIsRowsRemoveInProgressSelector = () => useSelector(isRemoveInProgressSelector);

const spreadsheetTableDataETagSelector = (state: AppState) => state.frontendState.spreadsheetTable.spreadsheetTableDataETag;
export const useSpreadsheetTableDataETagSelector = () => useSelector(spreadsheetTableDataETagSelector);

const spreadsheetTableSearchSelector = (state: AppState) => state.frontendState.spreadsheetTable.search;
export const useSpreadsheetTableSearchSelector = () => useSelector(spreadsheetTableSearchSelector);

export const useHasSpreadsheetTableSearchAnyQueries = () => {
  const tableSearch = useSpreadsheetTableSearchSelector();

  return useMemo(() => {
    let hasSearchAnyQueries = false;

    Object.keys(tableSearch).forEach(spreadsheetIdAttr => {
      const spreadsheetId: number = parseInt(spreadsheetIdAttr, 10);
      const spreadsheetItem = tableSearch[spreadsheetId];

      Object.keys(spreadsheetItem).forEach(columnId => {
        const columnValues = spreadsheetItem[columnId][DataType.TEXT];

        if (columnValues.length > 0) {
          hasSearchAnyQueries = true;
          return;
        }
      });
    });

    return hasSearchAnyQueries;
  }, [tableSearch]);
};
