import { css } from '@emotion/react';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  innerItemHeight, innerItemWidth, itemHeight, itemWidth,
} from '../../_shared/constants/mainMenuUI.constants';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type Theme } from '../../_shared/themes/theme.model';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { type SingleItemProps } from './mainMenuItem.component';

type MainMenuItemInnerMenuProps = Readonly<{
  innerMenu: ReadonlyArray<SingleItemProps>;
  onItemClick: (callback: () => void, isShown: boolean, isDisabled: boolean) => () => void;
}>;

const wrapperStyle = ({ theme, height }: ThemeProps<{ height: number }>) => css({
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  bottom: height - itemHeight,
  color: theme.textColors.primary,
  height,
  left: 0,
});

const iconStyle = (theme: Theme, isFocused: boolean) => css({
  fontSize: 18,
  fontWeight: 900,
  color: isFocused ? theme.iconColors.accented : 'inherit',
});

const itemStyle = ({ theme, isDisabled, fontSize }: ThemeProps<{ isDisabled: boolean; fontSize?: string }>) => css({
  backgroundColor: theme.backgroundColors.secondary,
  cursor: isDisabled ? 'default' : 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  borderRight: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  fontSize,
  height: innerItemHeight,
  padding: '0 12px',
  width: innerItemWidth,
  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const headingStyle = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: innerItemWidth - itemWidth,
});

export const MainMenuItemInnerMenuComponent: FC<MainMenuItemInnerMenuProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={wrapperStyle({ theme, height: props.innerMenu.length * innerItemHeight })}
    >
      {props.innerMenu.map((item, index) => (
        <div
          css={itemStyle({ theme, isDisabled: item.isDisabled, fontSize: item.fontSize })}
          onClick={props.onItemClick(item.onClick, false, item.isDisabled)}
          key={index}
          data-testid={item.testId}
        >
          <FontAwesomeIcon
            css={iconStyle(theme, false)}
            icon={item.icon}
          />

          <div css={headingStyle}>{item.title}</div>
        </div>
      ))}
    </div>
  );
};
