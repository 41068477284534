import { useTranslation } from '~/_shared/utils/hooks';
import { ErrorPageContentGuardianDogComponent } from '../../errorPageContent/guardianDog';

export const AppErrorPageInvalidQueryString = () => {
  const [t] = useTranslation();

  return (
    <ErrorPageContentGuardianDogComponent>
      {t('PageError.QueryStringParamMissing')}
    </ErrorPageContentGuardianDogComponent>
  );
};
