import { SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type MetricModel } from './metrics.factory';

export const areMetricsTheSame = (metricA: MetricModel, metricsB: MetricModel) => {
  if (metricA.type === SelectedDataType.Demographic && metricA.type === metricsB.type) {
    return metricA.demographic.id === metricsB.demographic.id;
  }
  else if (metricA.type === SelectedDataType.SpreadsheetColumn && metricA.type === metricsB.type) {
    return metricA.spreadsheetColumn.id === metricsB.spreadsheetColumn.id
      && metricA.spreadsheetColumn.spreadsheetId === metricsB.spreadsheetColumn.spreadsheetId
      && metricA.dataAction === metricsB.dataAction;
  }

  return false;
};
