import {
  FRONTEND_STATE_MOBILE_UI_TOPBAR_HIDE,
  FRONTEND_STATE_MOBILE_UI_TOPBAR_SHOW,
} from '~/store/frontendState/mobileUI/mobileUI.actionTypes';

export const showMobileTopBar = () => ({
  type: FRONTEND_STATE_MOBILE_UI_TOPBAR_SHOW,
}) as const;

export const hideMobileTopBar = () => ({
  type: FRONTEND_STATE_MOBILE_UI_TOPBAR_HIDE,
}) as const;
