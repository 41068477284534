import { type FC } from 'react';
import { AuthorizationPageWrapperComponent } from '../pageWrapper/authorizationPageWrapper.component';
import { RegisterFormContainer } from './form/registerForm.container';

export const RegisterPageComponent: FC = () => {
  return (
    <AuthorizationPageWrapperComponent>
      <RegisterFormContainer />
    </AuthorizationPageWrapperComponent>
  );
};
