import { type ReactNode } from 'react';
import {
  Outlet, Route, Routes, useOutletContext,
} from 'react-router-dom';
import { MapPageContainer } from '~/map/page/mapPage.container';
import { TopBar } from '~/topBar/topBar';
import { AppContent } from '../../maptiveApp/appContent';
import { MapContextHoldingPageContainer } from '../../maptiveApp/mapContextHoldingPage.container';
import { AppPageStylesWrapper } from '../../pages/appPageStylesWrapper.component';
import { AppNavigationPaths } from './appNavigation.constants';
import { LeftSidebarRoutes } from './leftSidebarRoutes';

export const AppNavigation = () => (
  <>
    <TopBarRoutes />
    <AppPageStylesWrapper>
      <LeftSidebarRoutes />
      <AppContent>
        <MapContextHoldingPageContainer>
          {mapId => <Outlet context={{ mapId } satisfies RouteMapIdContextType} />}
        </MapContextHoldingPageContainer>
      </AppContent>
    </AppPageStylesWrapper>
  </>
);

const TopBarRoutes = () => {
  return (
    <Routes>
      <Route index element={<TopBar showUndoButton />} />
      <Route path={AppNavigationPaths.Map} element={<TopBar showUndoButton />} />
      <Route path={AppNavigationPaths.Data} element={<TopBar showUndoButton />} />
      <Route path="*" element={<TopBar showUndoButton={false} />} />
    </Routes>
  );
};

type RouteMapIdContextType = { mapId: number | null };
const RouteMapIdContextConsumer = ({ children }: { children: (mapId: number | null) => ReactNode }) => {
  const { mapId } = useOutletContext<RouteMapIdContextType>();
  return <>{children(mapId)}</>;
};

export const MapPageContent = () => (
  <RouteMapIdContextConsumer>
    {mapId => <MapPageContainer mapId={mapId} />}
  </RouteMapIdContextConsumer>
);
