import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { LatLng } from '~/_shared/types/latLng';
import { showDrawingItemsContextMenu } from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.actionCreators';
import {
  drawingToolItemHovered,
  drawingToolItemHoverStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import type { DrawingItem } from '~/store/mapSettings/drawing/items/drawingItems.state';
import { useAreDrawingEventsEnabledRef } from './hooks/useAreDrawingEventsEnabledRef';

export const useDrawingToolItemMouseEvents = () => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dispatch = useDispatch();

  const onMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  const onRightClick = useCallback((position: LatLng, drawingItem: DrawingItem) => {
    dispatch(showDrawingItemsContextMenu(position, drawingItem));
  }, [dispatch]);

  return {
    onMouseOver,
    onMouseOut,
    onRightClick,
  };
};
