import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';

const moveMarkerLabelsIsActiveSelector = (state: AppState) => state.frontendState.moveMarkerLabels.isActive;
export const useMoveMarkerLabelsIsActiveSelector = () => useSelector(moveMarkerLabelsIsActiveSelector);

const moveMarkerLabelsIsModalVisible = (state: AppState) => state.frontendState.moveMarkerLabels.isModalOpen;
export const useMoveMarkerLabelsIsModalVisible = () => useSelector(moveMarkerLabelsIsModalVisible);

const moveMarkerLabelOffsetsSelector = (state: AppState) => state.frontendState.moveMarkerLabels.offsets;
export const useMoveMarkerLabelOffsetsSelector = () => useSelector(moveMarkerLabelOffsetsSelector);
