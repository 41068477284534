import { css } from '@emotion/react';
import {
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { LinkComponent } from '~/_shared/baseComponents/links';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { getMapUrl } from '~/map/map.helpers';
import {
  fileUrlsUpdateRequest,
  userFilesDeleted,
} from '../../../store/frontendState/fileUrls/fileUrls.actionCreator';
import {
  getAllUserFiles,
  getFileMapList,
  removeUserFile,
} from '../../../store/mapSettings/fileAttachments/fileApi.helpers';
import {
  type FileMapListResponse,
  isSuccessFile,
  type UserFilesResponse,
} from '../../../store/mapSettings/fileAttachments/fileApi.repository';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import {
  type UserFile,
  type UserFileType,
} from '../../types/file.types';
import { useTranslation } from '../hooks';

const deletePromptContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
});

export const useUserFiles = (userFileType: UserFileType) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const [isLoading, setIsLoading] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [userFiles, setUserFiles] = useState<UserFile[]>([]);
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const getUserFiles = useCallback(
    () => {
      if (!clientId) {
        return;
      }
      setIsLoading(true);
      getAllUserFiles(clientId, userFileType)
        .then((data: UserFilesResponse) => {
          if (data?.list?.length) {
            const newUserFiles = data.list.filter(isSuccessFile).map((file) => ({
              id: file.id,
              type: file.type,
              clientId: file.client_id,
            }));

            setUserFiles(newUserFiles);
            dispatch(fileUrlsUpdateRequest(data.list));
          }

          // has to be toggled after user files are set to state
          setIsLoading(false);
          setFilesLoaded(true);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [clientId, dispatch, userFileType],
  );

  const deleteFile = useCallback((fileId: number) => {
    if (!clientId || !fileId || !mapId) {
      return;
    }

    const executeDelete = () => {
      return removeUserFile(clientId, fileId)
        .then(() => {
          dispatch(userFilesDeleted([fileId]));
          getUserFiles();
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    setIsLoading(true);
    getFileMapList(clientId, fileId)
      .then((maps: FileMapListResponse) => {
        if (maps.list.length === 0) {
          executeDelete();
        }
        else {
          const mapList = maps.list.map((map, index) => (
            <li key={index}>
              <LinkComponent
                path={getMapUrl(map.map_id)}
                target="_blank"
              >
                {map.map_name}
              </LinkComponent>
            </li>
          ));

          openConfirmationModal({
            title: t('Delete File'),
            confirmCaption: t('Proceed'),
            text: (
              <div css={deletePromptContentStyles}>
                <span>{t('You are about to delete a file that is currently used on map:', { count: maps.list.length })}</span>
                <ul>{mapList}</ul>
              </div>
            ),
            permanentConfirmSettings: {
              id: 'remove-user-file',
              strategy: PermanentConfirmStrategy.Session,
            },
            isConfirmButtonDestructive: true,
            onCancel: () => {
              setIsLoading(false);
              closeConfirmationModal();
            },
            onConfirm: () => {
              executeDelete().then(closeConfirmationModal);
            },
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [clientId, mapId, dispatch, getUserFiles, openConfirmationModal, t, closeConfirmationModal]);

  return {
    isLoading,
    filesLoaded,
    setIsLoading,
    getUserFiles,
    deleteFile,
    userFiles,
  };
};
