import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { useSelectedDrawingItemWithOptions } from '../../../../drawingTool/hooks/useSelectedDrawingItemWithOptions';
import { type AppState } from '../../../app.store';

export enum DrawingToolMode {
  Inactive = 'Inactive',
  DrawingItems = 'Adding',
  Paused = 'Paused',
  Stopped = 'Stopped',
}

const PAUSED_OR_STOPPED_DRAWING_TOOL_MODES = [DrawingToolMode.Paused, DrawingToolMode.Stopped];

const mapToolsDrawingActiveToolSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingTool.activeTool;
export const useMapToolsDrawingActiveToolSelector = () => useSelector(mapToolsDrawingActiveToolSelector);

const drawingToolStateSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingTool;

export const useDrawingToolModeSelector = (): DrawingToolMode => {
  const drawingToolState = useSelector(drawingToolStateSelector);
  const selectedDrawing = useSelectedDrawingItemWithOptions();

  if (!drawingToolState.isActive) {
    return drawingToolState.activeTool === DrawingTool.Management
      ? DrawingToolMode.Inactive
      : DrawingToolMode.Stopped;
  }

  if (!drawingToolState.isDrawingActive || !!selectedDrawing) {
    return DrawingToolMode.Paused;
  }
  else {
    return DrawingToolMode.DrawingItems;
  }
};

const hiddenDrawingToolsSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingTool.hiddenTools;
export const useHiddenDrawingToolsSelector = () => useSelector(hiddenDrawingToolsSelector);

const isDrawingToolModeStoppedOrPaused = (mode: DrawingToolMode) => PAUSED_OR_STOPPED_DRAWING_TOOL_MODES.includes(mode);
export const useIsDrawingToolModeStoppedOrPausedSelector = () => isDrawingToolModeStoppedOrPaused(useDrawingToolModeSelector());
