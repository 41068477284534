import type { NonEmptyArray } from 'ts-essentials';
import { areShallowEqual } from '../equality/shallowEqual.helper';

export const findIndexOfObject = <TObject>(array: ReadonlyArray<TObject>, object: TObject): number =>
  array.findIndex(id => areShallowEqual(id, object));

export const findAllIndexes = <T>(array: ReadonlyArray<T>, predicate: (el: T) => boolean): number[] =>
  array.reduce((a, e, i) => predicate(e) ? a.concat(i) : a, [] as number[]);

export const arrayEquals = (a: ReadonlyArray<any>, b: ReadonlyArray<any>) => {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
};

export const arrayOfSize = <T>(size: number, defaultValue: T) => new Array<T>(size).fill(defaultValue);
export const emptyArrayOfSize = (size: number) => arrayOfSize(size, undefined);

// The type of elements that make up the given array T.
export type ArrayElement<TArray> = TArray extends ReadonlyArray<infer TItem> ? TItem : never;

interface IGetLast {
  <TItem>(array: readonly [TItem, ...TItem[]]): TItem;
  <TItem>(array: readonly TItem[]): TItem | undefined;
}

export const getLast: IGetLast = (array) => array[array.length - 1];

export const notEmpty = <TItem>(array: readonly TItem[] = []): array is NonEmptyArray<TItem> => array.length > 0;

export const uniqueValues = <TItem>(array: TItem[]) => Array.from(new Set(array));

export const splitArrayIntoChunks = <T>(array: readonly T[], chunkSize: number): readonly (readonly T[])[] => {
  if (chunkSize < 1 || Number.isNaN(chunkSize)) {
    throw new RangeError(`Chunk size '${chunkSize}' must be greater (or equal) than 1`);
  }
  return array.reduce((acc: T[][], item: T, index: number) => {
    // Determine which chunk the current item belongs to
    const chunkIndex = Math.floor(index / chunkSize);

    // If the chunk doesn't exist, create it
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    // Add the current item to the appropriate chunk
    acc[chunkIndex].push(item);

    return acc;
  }, []);
};
