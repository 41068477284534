import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const iconStyle = css({
  marginRight: 4,
});

const messageStyle = ({ theme, destructive }: ThemeProps<{ destructive: boolean }>) => css({
  color: destructive ? theme.textColors.danger : theme.textColors.warning,
  marginTop: 8,
});

type CancelSubscriptionConfirmationBodyComponentProps = Readonly<{
  irreversible?: boolean;
}>;

export const CancelSubscriptionConfirmationBodyComponent: React.FC<CancelSubscriptionConfirmationBodyComponentProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <>
      {t('Are you sure you want to cancel your license?')}
      <div css={messageStyle({ theme, destructive: !!props.irreversible })}>
        {props.irreversible && (
          <FontAwesomeIcon
            css={iconStyle}
            icon={faExclamationTriangle}
          />
        )}
        {props.irreversible ? t('SubscriptionManagement.ReactivateOptions.Impossible') : t('SubscriptionManagement.ReactivateOptions.Possible')}
      </div>
    </>
  );
};
