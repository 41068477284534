import {
  FRONTEND_STATE_PROCESSING_BOUNDARIES_INITIALIZED,
  FRONTEND_STATE_PROCESSING_EXPORT_IMAGE_MODE_INITIALIZED,
  FRONTEND_STATE_PROCESSING_GOOGLE_SCRIPT_INITIALIZED,
  FRONTEND_STATE_PROCESSING_IMAGE_EXPORT_IN_PROGRESS,
  FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_START,
  FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_STOP, FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM,
  FRONTEND_STATE_PROCESSING_SET_MAP_SETTINGS_SYNC_IN_PROGRESS,
  FRONTEND_STATE_PROCESSING_SET_SPREADSHEET_DATA_FETCHING_IN_PROGRESS,
} from './processing.actionTypes';

export const frontendStateProcessingSetMapSettingsSyncInProgress = (inProgress: boolean) => ({
  type: FRONTEND_STATE_PROCESSING_SET_MAP_SETTINGS_SYNC_IN_PROGRESS,
  payload: {
    inProgress,
  },
}) as const;

export const frontendStateProcessingSetSpreadsheetDataFetchingInProgress = (inProgress: boolean) => ({
  type: FRONTEND_STATE_PROCESSING_SET_SPREADSHEET_DATA_FETCHING_IN_PROGRESS,
  payload: {
    inProgress,
  },
}) as const;

export const frontedStateProcessingGoogleScriptInitialized = () => ({
  type: FRONTEND_STATE_PROCESSING_GOOGLE_SCRIPT_INITIALIZED,
}) as const;

export const frontendStateProcessingExportImageExportInProgress = (inProgress: boolean) => ({
  type: FRONTEND_STATE_PROCESSING_IMAGE_EXPORT_IN_PROGRESS,
  payload: {
    inProgress,
  },
}) as const;

export const frontedStateProcessingRenderingMarkersStart = () => ({
  type: FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_START,
}) as const;

export const frontedStateProcessingRenderingMarkersStop = () => ({
  type: FRONTEND_STATE_PROCESSING_MARKERS_RENDERING_STOP,
}) as const;

export const frontedStateProcessingBoundariesInitialized = () => ({
  type: FRONTEND_STATE_PROCESSING_BOUNDARIES_INITIALIZED,
}) as const;

export const frontendStateProcessingSetFirstZoom = (newValue: number) => ({
  type: FRONTEND_STATE_PROCESSING_SET_FIRST_ZOOM,
  payload: {
    newValue,
  },
}) as const;

export const frontendStateProcessingExportImageModeInitialized = () => ({
  type: FRONTEND_STATE_PROCESSING_EXPORT_IMAGE_MODE_INITIALIZED,
}) as const;
