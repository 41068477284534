import {
  css, type Interpolation,
} from '@emotion/react';
import type { Placement } from '@popperjs/core';
import {
  cloneElement, isValidElement, type ReactElement, type ReactNode, useCallback, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { CONFIG } from '~/_shared/constants/config';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { mergeProps } from '~/_shared/utils/props/mergeProps';
import {
  isForwardRefComponent, preserveRef,
} from '~/_shared/utils/ref/ref.helpers';
import { useTheme } from '../../themes/theme.hooks';
import {
  tooltipArrowStyle, tooltipContentStyle,
} from './tooltip.styles';
import { useTooltipContext } from './tooltipContext';

export enum TooltipPlacement {
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
}

export enum TooltipBehavior {
  ShowOnHover = 'ShowOnHover',
  ShowAlways = 'ShowAlways',
  ShowOnOverflow = 'ShowOnOverflow',
}

const arrowSize = 6;

const tooltipWrapperStyle = css({
  display: 'inline-block',
});

export type TooltipOffset = {
  top?: number;
  right?: number;
};

export type TooltipProps = Readonly<{
  tooltipContent?: ReactNode;
  placement?: Placement;
  // ReactElement ensures we can only have one child compared to ReactNode that was used previously
  children: ReactElement;
  behavior?: TooltipBehavior;
  className?: string;
  contentStyle?: Interpolation;
  arrowStyle?: Interpolation;
  offset?: TooltipOffset;
  delayShow?: number;
}>;

type TooltipInnerProps = TooltipProps & Required<Pick<TooltipProps, 'placement' | 'behavior'>>;

const TriggerWithoutTooltip = (props: TooltipInnerProps) => {
  return (
    <>
      {props.children}
    </>
  );
};

const DesktopTooltipComponent = (props: TooltipInnerProps) => {
  const theme = useTheme();
  const [alwaysHidden, setAlwaysHidden] = useState(false);
  const [visible, setVisible] = useState(props.behavior === TooltipBehavior.ShowAlways);
  const [observer] = useState(new ResizeObserver(() => checkVisibility));

  const prevBehavior = usePrevious(props.behavior);

  const { children } = props;

  const popperTooltip = usePopperTooltip({
    delayShow: props.delayShow,
    visible: props.behavior === TooltipBehavior.ShowAlways ? true : (visible && !alwaysHidden),
    placement: props.placement,
    onVisibleChange: (newVisibility) => {
      setVisible(newVisibility);
    },
  }, {
    strategy: 'fixed',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [props.offset?.top, props.offset?.right ?? arrowSize],
      },
    }],
    placement: props.placement,
  });

  const checkVisibility = useCallback(() => {
    if (popperTooltip.triggerRef && (
      (popperTooltip.triggerRef.offsetWidth >= popperTooltip.triggerRef.scrollWidth) &&
        (popperTooltip.triggerRef.offsetHeight >= popperTooltip.triggerRef.scrollHeight)
    )
    ) {
      setAlwaysHidden(true);
    }
  }, [popperTooltip.triggerRef]);

  const setTriggerObserver = useCallback((node: HTMLElement) => {
    if (props.behavior !== TooltipBehavior.ShowOnOverflow || !node) {
      return;
    }

    observer.disconnect();
    observer.observe(node);
    checkVisibility();
  }, [checkVisibility, observer, props.behavior]);

  useEffect(() => () => observer.disconnect(), [observer]);

  useEffect(() => {
    if (prevBehavior === TooltipBehavior.ShowAlways
      && props.behavior !== TooltipBehavior.ShowAlways
    ) {
      // this is due to a bug inside the react-popper-tooltip lib
      // need to trigger mouse out when moving away from controlled state,
      // otherwise we would be left with hovered-over element
      popperTooltip.triggerRef?.dispatchEvent(new MouseEvent('mouseout', { bubbles: true }));
    }
  }, [popperTooltip.triggerRef, prevBehavior, props.behavior]);

  const triggerProps = {
    ref: (node: HTMLElement) => {
      setTriggerObserver(node);
      popperTooltip.setTriggerRef(node);
      const ref = (children as any).ref;

      if (ref) {
        preserveRef(ref, node);
      }
    },
    className: props.className,
    onClick: () => {
      if (props.behavior !== TooltipBehavior.ShowAlways) {
        setVisible(false);
      }
    },
  };

  const renderTrigger = () => {
    if (isValidElement(children)) {
      return cloneElement(children, mergeProps(triggerProps, children.props as any));
    }
    else {
      return (
        <span
          css={tooltipWrapperStyle}
          {...triggerProps}
        >
          {children}
        </span>
      );
    }
  };

  useEffect(() => {
    if (!isValidElement(children) || CONFIG.MODE !== 'development' || !props.tooltipContent) {
      return;
    }

    const isDOMElement = typeof children.type === 'string';
    const isForwardRef = isForwardRefComponent(children);

    if (!isDOMElement && !isForwardRef) {
      console.error(`${__filename}: Tooltip needs to work with a ref. The child you supplied does not appear to be ref-able. Did you forget to forwardRef?`);
    }
  }, [children, props.tooltipContent]);

  if (!props.tooltipContent) {
    return (
      <TriggerWithoutTooltip {...props} />
    );
  }

  const tooltipProps = popperTooltip.getTooltipProps({
    css: [tooltipContentStyle({ theme, isVisible: !!props.tooltipContent }), props.contentStyle],
    ref: popperTooltip.tooltipRef,
  });

  const placement = popperTooltip.state?.placement ?? props.placement;

  const arrowProps = popperTooltip.getArrowProps({
    css: [tooltipArrowStyle({ theme, placement }), props.arrowStyle],
    'data-placement': placement,
  });

  return (
    <>
      {renderTrigger()}
      {popperTooltip.visible && (
        ReactDOM.createPortal(
          <div
            {...tooltipProps}
            ref={popperTooltip.setTooltipRef}
          >
            <div {...arrowProps} />
            {props.tooltipContent}
          </div>,
          document.getElementById('tooltip-portal') as HTMLElement
        )
      )}
    </>
  );
};

export const TooltipComponent = ({
  placement = TooltipPlacement.Top,
  behavior = TooltipBehavior.ShowOnHover,
  ...restProps
}: TooltipProps) => {
  const { isMobileScreen } = useTooltipContext();
  return isMobileScreen ? (
    <TriggerWithoutTooltip
      behavior={behavior}
      placement={placement}
      {...restProps}
    />
  ) : (
    <DesktopTooltipComponent
      behavior={behavior}
      placement={placement}
      {...restProps}
    />
  );
};
