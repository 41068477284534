import { css } from '@emotion/react';
import { type Theme } from '~/_shared/themes/theme.model';
import { type DemographicsRegion } from '~/store/demographics/demographics.repository';
import {
  AccordionComponent, type AccordionData,
} from '../../baseComponents/accordion';
import { type SpreadsheetColumn } from '../../types/spreadsheetData/spreadsheetColumn';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { CustomizeMetricsResultsComponent } from './results/customizeMetricsResults.component';
import { CustomizeMetricsSelectDataComponent } from './selectData/customizeMetricsSelectData.component';
import { type SelectedDataMetric } from './selectedDataMetric.type';

type CustomizeMetricsProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  selectedData: SelectedDataMetric[];
  onSelectedDataChange: (newData: SelectedDataMetric[]) => void;
  addSelectionButtonText: string;
  selectedDataCaption: string;
  columnDataSources: ReadonlyArray<SpreadsheetColumn>;
  demographicsRegions: DemographicsRegion[];
  title: string;
}>;

export const customizeMetricsDropdownTriggerStyle = ({ isPlaceholder }: { isPlaceholder: boolean }) => (theme: Theme) => css({
  color: isPlaceholder ? theme.textColors.disabled : undefined,
  height: 40,
});

const accordionItemStyle = css({
  textTransform: 'uppercase',
});

const accordionPanelStyle = css({
  padding: '0 0 24px',
});

const modalContentStyle = css({
  padding: 0,
});

export const CustomizeMetricsComponent = ({
  addSelectionButtonText,
  columnDataSources,
  demographicsRegions = [],
  isLoading,
  isOpen,
  onClose,
  onSelectedDataChange,
  selectedData,
  selectedDataCaption,
  title,
  ...restProps
}: CustomizeMetricsProps) => {

  const removeSelectedData = (index: number) => {
    const newData = [...selectedData];
    newData.splice(index, 1);

    onSelectedDataChange(newData);
  };

  const selectDataSection: AccordionData = {
    header: null,
    child: (
      <CustomizeMetricsSelectDataComponent
        addSelectionButtonText={addSelectionButtonText}
        columnDataSources={columnDataSources}
        demographicsRegions={demographicsRegions}
        isPopupOpen={isOpen}
        selectedData={selectedData}
        onSubmit={onSelectedDataChange}
      />
    ),
    isLocked: true,
    isExpanded: true,
  };

  const selectedDataSection: AccordionData = {
    header: selectedDataCaption,
    child: (
      <CustomizeMetricsResultsComponent
        selectedData={selectedData}
        onRemoveItem={index => removeSelectedData(index)}
      />
    ),
    isExpanded: true,
    isLocked: true,
  };

  const accordionData = [selectDataSection, selectedDataSection];

  return (
    <ModalComponent
      {...restProps}
      onClose={onClose}
      isOpen={isOpen}
      caption={title}
      contentStyle={modalContentStyle}
      additionalContent={isLoading ? (
        <OverlayLoaderComponent />
      ) : null}
    >
      <AccordionComponent
        data={accordionData}
        itemButtonStyle={accordionItemStyle}
        panelStyle={accordionPanelStyle}
        showCount={false}
      />
    </ModalComponent>
  );
};
