import {
  faAnchor,
  faAppleAlt,
  faArchway,
  faBaby,
  faBabyCarriage,
  faBalloons,
  faBanSmoking,
  faBaseballBatBall,
  faBasketballBall,
  faBat,
  faBedAlt,
  faBee,
  faBeer,
  faBicycle,
  faBinoculars,
  faBlockBrick,
  faBookFont,
  faBooks,
  faBootHeeled,
  faBowArrow,
  faBowlChopsticks,
  faBowlHot,
  faBowlingPins,
  faBox,
  faBoxingGlove,
  faBreadLoaf,
  faBroadcastTower,
  faBuilding,
  faBullhorn,
  faBurgerSoda,
  faBus,
  faCableCar,
  faCakeSlice,
  faCampground,
  faCandy,
  faCar,
  faCards,
  faCarGarage,
  faCarrot,
  faCartShopping,
  faCarWash,
  faCarWrench,
  faCastle,
  faCat,
  faCctv,
  faChargingStation,
  faCheck,
  faCheeseSwiss,
  faChess,
  faChild,
  faChurch,
  faCitrusSlice,
  faClouds,
  faCocktail,
  faCoffeePot,
  faCoffeeTogo,
  faConciergeBell,
  faCow,
  faCroissant,
  faCross,
  faCrosshairs,
  faCrow,
  faDeer,
  faDharmachakra,
  faDigging,
  faDog,
  faDollarSign,
  faDolphin,
  faDonut,
  faDrum,
  faDuck,
  faDumbbell,
  faEnvelope,
  faExclamation,
  faFaucetDrip,
  faFerrisWheel,
  faFilm,
  faFireHydrant,
  faFish,
  faFishCooked,
  faFishingRod,
  faFlagCheckered,
  faFlask,
  faFlowerTulip,
  faFootballBall,
  faFrenchFries,
  faFutbol,
  faGamepadAlt,
  faGasPump,
  faGift,
  faGolfBallTee,
  faGopuram,
  faGraduationCap,
  faGuitarElectric,
  faHamburger,
  faHatChef,
  faHeart,
  faHeartbeat,
  faHelicopter,
  faHillRockslide,
  faHockeyPuck,
  faHorseSaddle,
  faHospitalSymbol,
  faHotdog,
  faHouse,
  faHSquare,
  faIceCream,
  faIceSkate,
  faImage,
  faInfo,
  faLacrosseStickBall,
  faLandmark,
  faLandmarkDome,
  faLaptopMobile,
  faLeaf,
  faLightbulbOn,
  faLips,
  faLobster,
  faMailbox,
  faMap,
  faMaskSnorkel,
  faMeat,
  faMicrophoneStand,
  faMoneyBill1,
  faMonkey,
  faMosque,
  faMotorcycle,
  faMountain,
  faMugHot,
  faMushroom,
  faMusic,
  faNewspaper,
  faPalette,
  faParking,
  faPaw,
  faPeace,
  faPepperHot,
  faPerson,
  faPersonDress,
  faPersonHiking,
  faPersonRunning,
  faPhone,
  faPiano,
  faPiggyBank,
  faPills,
  faPizza,
  faPizzaSlice,
  faPlaceOfWorship,
  faPlane,
  faPlus,
  faPopcorn,
  faPray,
  faQuestion,
  faRacquet,
  faRaindrops,
  faRecycle,
  faRestroomSimple,
  faRibbon,
  faRingsWedding,
  faRoad,
  faRouteHighway,
  faRouteInterstate,
  faSackDollar,
  faSailboat,
  faSalad,
  faScissors,
  faShip,
  faShirtLongSleeve,
  faShishKebab,
  faShoePrints,
  faShoppingBasket,
  faShovel,
  faShower,
  faShrimp,
  faShuttlecock,
  faShuttleVan,
  faSignsPost,
  faSkiing,
  faSmoking,
  faSnake,
  faSnowboarding,
  faSnowflakes,
  faSnowplow,
  faSolarPanel,
  faSpa,
  faSquareParkingSlash,
  faSteak,
  faStore,
  faStrawberry,
  faSubway,
  faSuitcase,
  faSun,
  faSunCloud,
  faSushiRoll,
  faSwimmer,
  faSword,
  faSynagogue,
  faTaco,
  faTaxi,
  faTeddyBear,
  faTelescope,
  faTheaterMasks,
  faThunderstorm,
  faTire,
  faToilet,
  faTombstone,
  faTools,
  faTooth,
  faTornado,
  faTrafficCone,
  faTrafficLight,
  faTrain,
  faTrainSubwayTunnel,
  faTrainTunnel,
  faTram,
  faTrashCan,
  faTreeAlt,
  faTreePalm,
  faTrophy,
  faTruckPickup,
  faTv,
  faUmbrella,
  faUmbrellaBeach,
  faUniformMartialArts,
  faUtensils,
  faVihara,
  faVolleyballBall,
  faWandSparkles,
  faWarehouseFull,
  faWaterLadder,
  faWheat,
  faWheelchair,
  faWifi,
  faWind,
  faWindTurbine,
  faWineBottle,
  faWineGlass,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import anchorIcon from '../../../../assets/images/iconMarkers/icons/anchor.png';
import appleIcon from '../../../../assets/images/iconMarkers/icons/apple.png';
import archwayIcon from '../../../../assets/images/iconMarkers/icons/archway.png';
import babyIcon from '../../../../assets/images/iconMarkers/icons/baby.png';
import babyCarriageIcon from '../../../../assets/images/iconMarkers/icons/baby-carriage.png';
import balloonsIcon from '../../../../assets/images/iconMarkers/icons/balloons.png';
import banSmokingIcon from '../../../../assets/images/iconMarkers/icons/ban-smoking.png';
import baseballIcon from '../../../../assets/images/iconMarkers/icons/baseball.png';
import basketballIcon from '../../../../assets/images/iconMarkers/icons/basketball.png';
import batIcon from '../../../../assets/images/iconMarkers/icons/bat.png';
import bedIcon from '../../../../assets/images/iconMarkers/icons/bed.png';
import beeIcon from '../../../../assets/images/iconMarkers/icons/bee.png';
import beerIcon from '../../../../assets/images/iconMarkers/icons/beer.png';
import bicycleIcon from '../../../../assets/images/iconMarkers/icons/bicycle.png';
import binocularsIcon from '../../../../assets/images/iconMarkers/icons/binoculars.png';
import bookFontIcon from '../../../../assets/images/iconMarkers/icons/book-font.png';
import booksIcon from '../../../../assets/images/iconMarkers/icons/books.png';
import bootHeeledIcon from '../../../../assets/images/iconMarkers/icons/boot-heeled.png';
import bottleIcon from '../../../../assets/images/iconMarkers/icons/bottle.png';
import bowArrowIcon from '../../../../assets/images/iconMarkers/icons/bow-arrow.png';
import bowlChopsticksIcon from '../../../../assets/images/iconMarkers/icons/bowl-chopsticks.png';
import bowlHotIcon from '../../../../assets/images/iconMarkers/icons/bowl-hot.png';
import bowlingPinsIcon from '../../../../assets/images/iconMarkers/icons/bowling-pins.png';
import boxIcon from '../../../../assets/images/iconMarkers/icons/box.png';
import boxingGloveIcon from '../../../../assets/images/iconMarkers/icons/boxing-glove.png';
import breadIcon from '../../../../assets/images/iconMarkers/icons/bread.png';
import bricksIcon from '../../../../assets/images/iconMarkers/icons/bricks.png';
import broadcastTowerIcon from '../../../../assets/images/iconMarkers/icons/broadcast-tower.png';
import buildingIcon from '../../../../assets/images/iconMarkers/icons/building.png';
import bullhornIcon from '../../../../assets/images/iconMarkers/icons/bullhorn.png';
import burgerSodaIcon from '../../../../assets/images/iconMarkers/icons/burger-soda.png';
import busIcon from '../../../../assets/images/iconMarkers/icons/bus.png';
import cableCarIcon from '../../../../assets/images/iconMarkers/icons/cable-car.png';
import cakeSliceIcon from '../../../../assets/images/iconMarkers/icons/cake-slice.png';
import campgroundIcon from '../../../../assets/images/iconMarkers/icons/campground.png';
import candyIcon from '../../../../assets/images/iconMarkers/icons/candy.png';
import carIcon from '../../../../assets/images/iconMarkers/icons/car.png';
import carGarageIcon from '../../../../assets/images/iconMarkers/icons/car-garage.png';
import carWashIcon from '../../../../assets/images/iconMarkers/icons/car-wash.png';
import carWrenchIcon from '../../../../assets/images/iconMarkers/icons/car-wrench.png';
import cardsIcon from '../../../../assets/images/iconMarkers/icons/cards.png';
import carrotIcon from '../../../../assets/images/iconMarkers/icons/carrot.png';
import cartShoppingIcon from '../../../../assets/images/iconMarkers/icons/cart-shopping.png';
import castleIcon from '../../../../assets/images/iconMarkers/icons/castle.png';
import catIcon from '../../../../assets/images/iconMarkers/icons/cat.png';
import cctvIcon from '../../../../assets/images/iconMarkers/icons/cctv.png';
import chargingStationIcon from '../../../../assets/images/iconMarkers/icons/charging-station.png';
import checkIcon from '../../../../assets/images/iconMarkers/icons/check.png';
import cheeseIcon from '../../../../assets/images/iconMarkers/icons/cheese.png';
import chessIcon from '../../../../assets/images/iconMarkers/icons/chess.png';
import childIcon from '../../../../assets/images/iconMarkers/icons/child.png';
import churchIcon from '../../../../assets/images/iconMarkers/icons/church.png';
import citrusSliceIcon from '../../../../assets/images/iconMarkers/icons/citrus-slice.png';
import cloudsIcon from '../../../../assets/images/iconMarkers/icons/clouds.png';
import cocktailIcon from '../../../../assets/images/iconMarkers/icons/cocktail.png';
import coffeePotIcon from '../../../../assets/images/iconMarkers/icons/coffee-pot.png';
import coffeeTogoIcon from '../../../../assets/images/iconMarkers/icons/coffee-togo.png';
import conciergeBellIcon from '../../../../assets/images/iconMarkers/icons/concierge-bell.png';
import cowIcon from '../../../../assets/images/iconMarkers/icons/cow.png';
import croissantIcon from '../../../../assets/images/iconMarkers/icons/croissant.png';
import crossIcon from '../../../../assets/images/iconMarkers/icons/cross.png';
import crosshairsIcon from '../../../../assets/images/iconMarkers/icons/crosshairs.png';
import crowIcon from '../../../../assets/images/iconMarkers/icons/crow.png';
import deerIcon from '../../../../assets/images/iconMarkers/icons/deer.png';
import dharmachakraIcon from '../../../../assets/images/iconMarkers/icons/dharmachakra.png';
import diggingIcon from '../../../../assets/images/iconMarkers/icons/digging.png';
import dogIcon from '../../../../assets/images/iconMarkers/icons/dog.png';
import dollarSignIcon from '../../../../assets/images/iconMarkers/icons/dollar-sign.png';
import dolphinIcon from '../../../../assets/images/iconMarkers/icons/dolphin.png';
import donutIcon from '../../../../assets/images/iconMarkers/icons/donut.png';
import drumIcon from '../../../../assets/images/iconMarkers/icons/drum.png';
import duckIcon from '../../../../assets/images/iconMarkers/icons/duck.png';
import dumbbellIcon from '../../../../assets/images/iconMarkers/icons/dumbbell.png';
import envelopeIcon from '../../../../assets/images/iconMarkers/icons/envelope.png';
import exclamationIcon from '../../../../assets/images/iconMarkers/icons/exclamation.png';
import faucetDripIcon from '../../../../assets/images/iconMarkers/icons/faucet-drip.png';
import ferrisWheelIcon from '../../../../assets/images/iconMarkers/icons/ferris-wheel.png';
import filmIcon from '../../../../assets/images/iconMarkers/icons/film.png';
import fireHydrantIcon from '../../../../assets/images/iconMarkers/icons/fire-hydrant.png';
import fishIcon from '../../../../assets/images/iconMarkers/icons/fish.png';
import fishCookedIcon from '../../../../assets/images/iconMarkers/icons/fish-cooked.png';
import fishingRodIcon from '../../../../assets/images/iconMarkers/icons/fishing-rod.png';
import flagCheckeredIcon from '../../../../assets/images/iconMarkers/icons/flag-checkered.png';
import flaskIcon from '../../../../assets/images/iconMarkers/icons/flask.png';
import flowerTulipIcon from '../../../../assets/images/iconMarkers/icons/flower-tulip.png';
import footballIcon from '../../../../assets/images/iconMarkers/icons/football.png';
import friesIcon from '../../../../assets/images/iconMarkers/icons/fries.png';
import futbolIcon from '../../../../assets/images/iconMarkers/icons/futbol.png';
import gamepadIcon from '../../../../assets/images/iconMarkers/icons/gamepad.png';
import gasPumpIcon from '../../../../assets/images/iconMarkers/icons/gas-pump.png';
import giftIcon from '../../../../assets/images/iconMarkers/icons/gift.png';
import golfBallTeeIcon from '../../../../assets/images/iconMarkers/icons/golf-ball-tee.png';
import gopuramIcon from '../../../../assets/images/iconMarkers/icons/gopuram.png';
import graduationCapIcon from '../../../../assets/images/iconMarkers/icons/graduation-cap.png';
import guitarElectricIcon from '../../../../assets/images/iconMarkers/icons/guitar-electric.png';
import hSquareIcon from '../../../../assets/images/iconMarkers/icons/h-square.png';
import hamburgerIcon from '../../../../assets/images/iconMarkers/icons/hamburger.png';
import hatChefIcon from '../../../../assets/images/iconMarkers/icons/hat-chef.png';
import heartIcon from '../../../../assets/images/iconMarkers/icons/heart.png';
import heartbeatIcon from '../../../../assets/images/iconMarkers/icons/heartbeat.png';
import helicopterIcon from '../../../../assets/images/iconMarkers/icons/helicopter.png';
import hillRockslideIcon from '../../../../assets/images/iconMarkers/icons/hill-rockslide.png';
import hockeyPuckIcon from '../../../../assets/images/iconMarkers/icons/hockey-puck.png';
import horseSaddleIcon from '../../../../assets/images/iconMarkers/icons/horse-saddle.png';
import hospitalSymbolIcon from '../../../../assets/images/iconMarkers/icons/hospital-symbol.png';
import hotdogIcon from '../../../../assets/images/iconMarkers/icons/hotdog.png';
import houseIcon from '../../../../assets/images/iconMarkers/icons/house.png';
import iceCreamIcon from '../../../../assets/images/iconMarkers/icons/ice-cream.png';
import iceSkateIcon from '../../../../assets/images/iconMarkers/icons/ice-skate.png';
import imageIcon from '../../../../assets/images/iconMarkers/icons/image.png';
import infoIcon from '../../../../assets/images/iconMarkers/icons/info.png';
import kebabIcon from '../../../../assets/images/iconMarkers/icons/kebab.png';
import lacrosseIcon from '../../../../assets/images/iconMarkers/icons/lacrosse.png';
import landmarkIcon from '../../../../assets/images/iconMarkers/icons/landmark.png';
import landmarkDomeIcon from '../../../../assets/images/iconMarkers/icons/landmark-dome.png';
import laptopMobileIcon from '../../../../assets/images/iconMarkers/icons/laptop-mobile.png';
import leafIcon from '../../../../assets/images/iconMarkers/icons/leaf.png';
import lightbulbOnIcon from '../../../../assets/images/iconMarkers/icons/lightbulb-on.png';
import lipsIcon from '../../../../assets/images/iconMarkers/icons/lips.png';
import lobsterIcon from '../../../../assets/images/iconMarkers/icons/lobster.png';
import mailboxIcon from '../../../../assets/images/iconMarkers/icons/mailbox.png';
import mapIcon from '../../../../assets/images/iconMarkers/icons/map.png';
import maskSnorkelIcon from '../../../../assets/images/iconMarkers/icons/mask-snorkel.png';
import meatIcon from '../../../../assets/images/iconMarkers/icons/meat.png';
import microphoneStandIcon from '../../../../assets/images/iconMarkers/icons/microphone-stand.png';
import moneyBill1Icon from '../../../../assets/images/iconMarkers/icons/money-bill-1.png';
import monkeyIcon from '../../../../assets/images/iconMarkers/icons/monkey.png';
import mosqueIcon from '../../../../assets/images/iconMarkers/icons/mosque.png';
import motorcycleIcon from '../../../../assets/images/iconMarkers/icons/motorcycle.png';
import mountainIcon from '../../../../assets/images/iconMarkers/icons/mountain.png';
import mugIcon from '../../../../assets/images/iconMarkers/icons/mug.png';
import mushroomIcon from '../../../../assets/images/iconMarkers/icons/mushroom.png';
import musicIcon from '../../../../assets/images/iconMarkers/icons/music.png';
import newspaperIcon from '../../../../assets/images/iconMarkers/icons/newspaper.png';
import paletteIcon from '../../../../assets/images/iconMarkers/icons/palette.png';
import parkingIcon from '../../../../assets/images/iconMarkers/icons/parking.png';
import pawIcon from '../../../../assets/images/iconMarkers/icons/paw.png';
import peaceIcon from '../../../../assets/images/iconMarkers/icons/peace.png';
import pepperHotIcon from '../../../../assets/images/iconMarkers/icons/pepper-hot.png';
import personIcon from '../../../../assets/images/iconMarkers/icons/person.png';
import personDressIcon from '../../../../assets/images/iconMarkers/icons/person-dress.png';
import personHikingIcon from '../../../../assets/images/iconMarkers/icons/person-hiking.png';
import personRunningIcon from '../../../../assets/images/iconMarkers/icons/person-running.png';
import phoneIcon from '../../../../assets/images/iconMarkers/icons/phone.png';
import pianoIcon from '../../../../assets/images/iconMarkers/icons/piano.png';
import piggyBankIcon from '../../../../assets/images/iconMarkers/icons/piggy-bank.png';
import pillsIcon from '../../../../assets/images/iconMarkers/icons/pills.png';
import pizzaIcon from '../../../../assets/images/iconMarkers/icons/pizza.png';
import pizzaSliceIcon from '../../../../assets/images/iconMarkers/icons/pizza-slice.png';
import placeOfWorshipIcon from '../../../../assets/images/iconMarkers/icons/place-of-worship.png';
import planeIcon from '../../../../assets/images/iconMarkers/icons/plane.png';
import plusIcon from '../../../../assets/images/iconMarkers/icons/plus.png';
import popcornIcon from '../../../../assets/images/iconMarkers/icons/popcorn.png';
import prayIcon from '../../../../assets/images/iconMarkers/icons/pray.png';
import questionIcon from '../../../../assets/images/iconMarkers/icons/question.png';
import racquetIcon from '../../../../assets/images/iconMarkers/icons/racquet.png';
import raindropsIcon from '../../../../assets/images/iconMarkers/icons/raindrops.png';
import recycleIcon from '../../../../assets/images/iconMarkers/icons/recycle.png';
import restroomSimpleIcon from '../../../../assets/images/iconMarkers/icons/restroom-simple.png';
import ribbonIcon from '../../../../assets/images/iconMarkers/icons/ribbon.png';
import ringsWeddingIcon from '../../../../assets/images/iconMarkers/icons/rings-wedding.png';
import roadIcon from '../../../../assets/images/iconMarkers/icons/road.png';
import routeHighwayIcon from '../../../../assets/images/iconMarkers/icons/route-highway.png';
import routeInterstateIcon from '../../../../assets/images/iconMarkers/icons/route-interstate.png';
import sackDollarIcon from '../../../../assets/images/iconMarkers/icons/sack-dollar.png';
import sailboatIcon from '../../../../assets/images/iconMarkers/icons/sailboat.png';
import saladIcon from '../../../../assets/images/iconMarkers/icons/salad.png';
import scissorsIcon from '../../../../assets/images/iconMarkers/icons/scissors.png';
import shipIcon from '../../../../assets/images/iconMarkers/icons/ship.png';
import shirtLongSleeveIcon from '../../../../assets/images/iconMarkers/icons/shirt-long-sleeve.png';
import shoeprintsIcon from '../../../../assets/images/iconMarkers/icons/shoeprints.png';
import shoppingBasketIcon from '../../../../assets/images/iconMarkers/icons/shopping-basket.png';
import shovelIcon from '../../../../assets/images/iconMarkers/icons/shovel.png';
import showerIcon from '../../../../assets/images/iconMarkers/icons/shower.png';
import shrimpIcon from '../../../../assets/images/iconMarkers/icons/shrimp.png';
import shuttlecockIcon from '../../../../assets/images/iconMarkers/icons/shuttlecock.png';
import signsPostIcon from '../../../../assets/images/iconMarkers/icons/signs-post.png';
import skiingIcon from '../../../../assets/images/iconMarkers/icons/skiing.png';
import smokingIcon from '../../../../assets/images/iconMarkers/icons/smoking.png';
import snakeIcon from '../../../../assets/images/iconMarkers/icons/snake.png';
import snowboardingIcon from '../../../../assets/images/iconMarkers/icons/snowboarding.png';
import snowflakesIcon from '../../../../assets/images/iconMarkers/icons/snowflakes.png';
import snowplowIcon from '../../../../assets/images/iconMarkers/icons/snowplow.png';
import solarPanelIcon from '../../../../assets/images/iconMarkers/icons/solar-panel.png';
import spaIcon from '../../../../assets/images/iconMarkers/icons/spa.png';
import squareParkingSlashIcon from '../../../../assets/images/iconMarkers/icons/square-parking-slash.png';
import steakIcon from '../../../../assets/images/iconMarkers/icons/steak.png';
import storeIcon from '../../../../assets/images/iconMarkers/icons/store.png';
import strawberryIcon from '../../../../assets/images/iconMarkers/icons/strawberry.png';
import subwayIcon from '../../../../assets/images/iconMarkers/icons/subway.png';
import suitcaseIcon from '../../../../assets/images/iconMarkers/icons/suitcase.png';
import sunIcon from '../../../../assets/images/iconMarkers/icons/sun.png';
import sunCloudIcon from '../../../../assets/images/iconMarkers/icons/sun-cloud.png';
import sushiIcon from '../../../../assets/images/iconMarkers/icons/sushi-roll.png';
import swimmerIcon from '../../../../assets/images/iconMarkers/icons/swimmer.png';
import swordIcon from '../../../../assets/images/iconMarkers/icons/sword.png';
import synagogueIcon from '../../../../assets/images/iconMarkers/icons/synagogue.png';
import tacoIcon from '../../../../assets/images/iconMarkers/icons/taco.png';
import taxiIcon from '../../../../assets/images/iconMarkers/icons/taxi.png';
import teddyBearIcon from '../../../../assets/images/iconMarkers/icons/teddy-bear.png';
import telescopeIcon from '../../../../assets/images/iconMarkers/icons/telescope.png';
import theaterIcon from '../../../../assets/images/iconMarkers/icons/theater.png';
import thunderstormIcon from '../../../../assets/images/iconMarkers/icons/thunderstorm.png';
import tireIcon from '../../../../assets/images/iconMarkers/icons/tire.png';
import toiletIcon from '../../../../assets/images/iconMarkers/icons/toilet.png';
import tombstoneIcon from '../../../../assets/images/iconMarkers/icons/tombstone.png';
import toolsIcon from '../../../../assets/images/iconMarkers/icons/tools.png';
import toothIcon from '../../../../assets/images/iconMarkers/icons/tooth.png';
import tornadoIcon from '../../../../assets/images/iconMarkers/icons/tornado.png';
import trafficConeIcon from '../../../../assets/images/iconMarkers/icons/traffic-cone.png';
import trafficLightIcon from '../../../../assets/images/iconMarkers/icons/traffic-light.png';
import trainIcon from '../../../../assets/images/iconMarkers/icons/train.png';
import trainTunnelIcon from '../../../../assets/images/iconMarkers/icons/train-tunnel.png';
import tramIcon from '../../../../assets/images/iconMarkers/icons/tram.png';
import trashCanIcon from '../../../../assets/images/iconMarkers/icons/trash-can.png';
import treeAltIcon from '../../../../assets/images/iconMarkers/icons/tree-alt.png';
import treePalmIcon from '../../../../assets/images/iconMarkers/icons/tree-palm.png';
import trophyIcon from '../../../../assets/images/iconMarkers/icons/trophy.png';
import truckPickupIcon from '../../../../assets/images/iconMarkers/icons/truck-pickup.png';
import tunnelIcon from '../../../../assets/images/iconMarkers/icons/tunnel.png';
import tvIcon from '../../../../assets/images/iconMarkers/icons/tv.png';
import umbrellaIcon from '../../../../assets/images/iconMarkers/icons/umbrella.png';
import umbrellaBeachIcon from '../../../../assets/images/iconMarkers/icons/umbrella-beach.png';
import uniformMartialArtsIcon from '../../../../assets/images/iconMarkers/icons/uniform-martial-arts.png';
import utensilsIcon from '../../../../assets/images/iconMarkers/icons/utensils.png';
import vanIcon from '../../../../assets/images/iconMarkers/icons/van.png';
import viharaIcon from '../../../../assets/images/iconMarkers/icons/vihara.png';
import volleyballIcon from '../../../../assets/images/iconMarkers/icons/volleyball.png';
import wandIcon from '../../../../assets/images/iconMarkers/icons/wand.png';
import warehouseFullIcon from '../../../../assets/images/iconMarkers/icons/warehouse-full.png';
import waterLadderIcon from '../../../../assets/images/iconMarkers/icons/water-ladder.png';
import wheatIcon from '../../../../assets/images/iconMarkers/icons/wheat.png';
import wheelchairIcon from '../../../../assets/images/iconMarkers/icons/wheelchair.png';
import wifiIcon from '../../../../assets/images/iconMarkers/icons/wifi.png';
import windIcon from '../../../../assets/images/iconMarkers/icons/wind.png';
import windTurbineIcon from '../../../../assets/images/iconMarkers/icons/wind-turbine.png';
import wineGlassIcon from '../../../../assets/images/iconMarkers/icons/wine-glass.png';
import xmarkIcon from '../../../../assets/images/iconMarkers/icons/xmark.png';

export const drawingToolIconMarkers = [
  {
    id: 'icon-cross',
    icon: faCross,
    path: crossIcon,
    v4: '0_0',
  }, {
    id: 'icon-spa',
    icon: faSpa,
    path: spaIcon,
    v4: '0_1',
  }, {
    id: 'icon-place-of-worship',
    icon: faPlaceOfWorship,
    path: placeOfWorshipIcon,
    v4: '0_2',
  }, {
    id: 'icon-gopuram',
    icon: faGopuram,
    path: gopuramIcon,
  }, {
    id: 'icon-mosque',
    icon: faMosque,
    path: mosqueIcon,
  }, {
    id: 'icon-synagogue',
    icon: faSynagogue,
    path: synagogueIcon,
  }, {
    id: 'icon-vihara',
    icon: faVihara,
    path: viharaIcon,
  }, {
    id: 'icon-church',
    icon: faChurch,
    path: churchIcon,
    v4: '0_16',
  }, {
    id: 'icon-pray',
    icon: faPray,
    path: prayIcon,
    v4: '0_4',
  }, {
    id: 'icon-map',
    icon: faMap,
    path: mapIcon,
    v4: '0_5',
  }, {
    id: 'icon-tombstone',
    icon: faTombstone,
    path: tombstoneIcon,
    v4: '0_6',
  }, {
    id: 'icon-castle',
    icon: faCastle,
    path: castleIcon,
    v4: '0_7',
  }, {
    id: 'icon-landmark-dome',
    icon: faLandmarkDome,
    path: landmarkDomeIcon,
    v4: '0_8',
  }, {
    id: 'icon-bricks',
    icon: faBlockBrick,
    path: bricksIcon,
    v4: '0_15',
  }, {
    id: 'icon-landmark',
    icon: faLandmark,
    path: landmarkIcon,
    v4: '0_17',
  }, {
    id: 'icon-archway',
    icon: faArchway,
    path: archwayIcon,
    v4: '0_19',
  }, {
    id: 'icon-house',
    icon: faHouse,
    path: houseIcon,
  }, {
    id: 'icon-building',
    icon: faBuilding,
    path: buildingIcon,
  }, {
    id: 'icon-store',
    icon: faStore,
    path: storeIcon,
  }, {
    id: 'icon-warehouse-full',
    icon: faWarehouseFull,
    path: warehouseFullIcon,
  }, {
    id: 'icon-info',
    icon: faInfo,
    path: infoIcon,
    v4: '0_20',
  }, {
    id: 'icon-gas-pump',
    icon: faGasPump,
    path: gasPumpIcon,
    v4: '0_21',
  }, {
    id: 'icon-charging-station',
    icon: faChargingStation,
    path: chargingStationIcon,
  }, {
    id: 'icon-hospital-symbol',
    icon: faHospitalSymbol,
    path: hospitalSymbolIcon,
    v4: '0_22',
  }, {
    id: 'icon-signs-post',
    icon: faSignsPost,
    path: signsPostIcon,
    v4: '0_23',
  }, {
    id: 'icon-shoeprints',
    icon: faShoePrints,
    path: shoeprintsIcon,
    v4: '0_24',
  }, {
    id: 'icon-car',
    icon: faCar,
    path: carIcon,
    v4: '0_26',
  }, {
    id: 'icon-sailboat',
    icon: faSailboat,
    path: sailboatIcon,
    v4: '0_27',
  }, {
    id: 'icon-ship',
    icon: faShip,
    path: shipIcon,
    v4: '0_28',
  }, {
    id: 'icon-parking',
    icon: faParking,
    path: parkingIcon,
    v4: '0_29',
  }, {
    id: 'icon-square-parking-slash',
    icon: faSquareParkingSlash,
    path: squareParkingSlashIcon,
  }, {
    id: 'icon-car-garage',
    icon: faCarGarage,
    path: carGarageIcon,
  }, {
    id: 'icon-anchor',
    icon: faAnchor,
    path: anchorIcon,
    v4: '1_0',
  }, {
    id: 'icon-train',
    icon: faTrain,
    path: trainIcon,
    v4: '1_2',
  }, {
    id: 'icon-subway',
    icon: faSubway,
    path: subwayIcon,
  }, {
    id: 'icon-bus',
    icon: faBus,
    path: busIcon,
    v4: '1_3',
  }, {
    id: 'icon-van',
    icon: faShuttleVan,
    path: vanIcon,
    v4: '1_5',
  }, {
    id: 'icon-tunnel',
    icon: faTrainSubwayTunnel,
    path: tunnelIcon,
    v4: '1_6',
  }, {
    id: 'icon-train-tunnel',
    icon: faTrainTunnel,
    path: trainTunnelIcon,
    v4: '1_7',
  }, {
    id: 'icon-truck-pickup',
    icon: faTruckPickup,
    path: truckPickupIcon,
    v4: '1_8',
  }, {
    id: 'icon-tram',
    icon: faTram,
    path: tramIcon,
    v4: '1_9',
  }, {
    id: 'icon-taxi',
    icon: faTaxi,
    path: taxiIcon,
  }, {
    id: 'icon-traffic-light',
    icon: faTrafficLight,
    path: trafficLightIcon,
    v4: '1_10',
  }, {
    id: 'icon-tire',
    icon: faTire,
    path: tireIcon,
    v4: '1_11',
  }, {
    id: 'icon-tools',
    icon: faTools,
    path: toolsIcon,
    v4: '1_13',
  }, {
    id: 'icon-motorcycle',
    icon: faMotorcycle,
    path: motorcycleIcon,
    v4: '1_14',
  }, {
    id: 'icon-road',
    icon: faRoad,
    path: roadIcon,
    v4: '1_16',
  }, {
    id: 'icon-route-highway',
    icon: faRouteHighway,
    path: routeHighwayIcon,
  }, {
    id: 'icon-route-interstate',
    icon: faRouteInterstate,
    path: routeInterstateIcon,
  }, {
    id: 'icon-helicopter',
    icon: faHelicopter,
    path: helicopterIcon,
    v4: '1_17',
  }, {
    id: 'icon-hill-rockslide',
    icon: faHillRockslide,
    path: hillRockslideIcon,
    v4: '1_18',
  }, {
    id: 'icon-car-wash',
    icon: faCarWash,
    path: carWashIcon,
    v4: '1_19',
  }, {
    id: 'icon-car-wrench',
    icon: faCarWrench,
    path: carWrenchIcon,
  }, {
    id: 'icon-cable-car',
    icon: faCableCar,
    path: cableCarIcon,
    v4: '1_20',
  }, {
    id: 'icon-snowplow',
    icon: faSnowplow,
    path: snowplowIcon,
    v4: '1_21',
  }, {
    id: 'icon-plane',
    icon: faPlane,
    path: planeIcon,
    v4: '1_22',
  }, {
    id: 'icon-cctv',
    icon: faCctv,
    path: cctvIcon,
    v4: '1_24',
  }, {
    id: 'icon-bicycle',
    icon: faBicycle,
    path: bicycleIcon,
    v4: '1_25',
  }, {
    id: 'icon-wheelchair',
    icon: faWheelchair,
    path: wheelchairIcon,
    v4: '1_26',
  }, {
    id: 'icon-digging',
    icon: faDigging,
    path: diggingIcon,
    v4: '1_27',
  }, {
    id: 'icon-traffic-cone',
    icon: faTrafficCone,
    path: trafficConeIcon,
  }, {
    id: 'icon-tv',
    icon: faTv,
    path: tvIcon,
    v4: '1_28',
  }, {
    id: 'icon-bed',
    icon: faBedAlt,
    path: bedIcon,
    v4: '1_29',
  }, {
    id: 'icon-restroom-simple',
    icon: faRestroomSimple,
    path: restroomSimpleIcon,
  }, {
    id: 'icon-smoking',
    icon: faSmoking,
    path: smokingIcon,
  }, {
    id: 'icon-ban-smoking',
    icon: faBanSmoking,
    path: banSmokingIcon,
  }, {
    id: 'icon-beer',
    icon: faBeer,
    path: beerIcon,
    v4: '2_0',
  }, {
    id: 'icon-bread',
    icon: faBreadLoaf,
    path: breadIcon,
    v4: '0_12',
  }, {
    id: 'icon-hamburger',
    icon: faHamburger,
    path: hamburgerIcon,
    v4: '2_1',
  }, {
    id: 'icon-sushi',
    icon: faSushiRoll,
    path: sushiIcon,
    v4: '2_2',
  }, {
    id: 'icon-concierge-bell',
    icon: faConciergeBell,
    path: conciergeBellIcon,
    v4: '2_3',
  }, {
    id: 'icon-wine-glass',
    icon: faWineGlass,
    path: wineGlassIcon,
    v4: '2_4',
  }, {
    id: 'icon-bottle',
    icon: faWineBottle,
    path: bottleIcon,
    v4: '2_5',
  }, {
    id: 'icon-coffee-pot',
    icon: faCoffeePot,
    path: coffeePotIcon,
    v4: '2_6',
  }, {
    id: 'icon-coffee-togo',
    icon: faCoffeeTogo,
    path: coffeeTogoIcon,
    v4: '2_7',
  }, {
    id: 'icon-carrot',
    icon: faCarrot,
    path: carrotIcon,
    v4: '2_8',
  }, {
    id: 'icon-shrimp',
    icon: faShrimp,
    path: shrimpIcon,
    v4: '2_10',
  }, {
    id: 'meat-pot',
    icon: faMeat,
    path: meatIcon,
    v4: '2_11',
  }, {
    id: 'icon-steak',
    icon: faSteak,
    path: steakIcon,
    v4: '2_12',
  }, {
    id: 'icon-taco',
    icon: faTaco,
    path: tacoIcon,
    v4: '2_13',
  }, {
    id: 'icon-bowl-chopsticks',
    icon: faBowlChopsticks,
    path: bowlChopsticksIcon,
    v4: '2_14',
  }, {
    id: 'icon-pizza-slice',
    icon: faPizzaSlice,
    path: pizzaSliceIcon,
    v4: '2_15',
  }, {
    id: 'icon-pepper-hot',
    icon: faPepperHot,
    path: pepperHotIcon,
    v4: '2_16',
  }, {
    id: 'icon-fish-cooked',
    icon: faFishCooked,
    path: fishCookedIcon,
    v4: '2_17',
  }, {
    id: 'icon-popcorn',
    icon: faPopcorn,
    path: popcornIcon,
    v4: '2_18',
  }, {
    id: 'icon-hat-chef',
    icon: faHatChef,
    path: hatChefIcon,
    v4: '2_19',
  }, {
    id: 'icon-pizza',
    icon: faPizza,
    path: pizzaIcon,
    v4: '2_21',
  }, {
    id: 'icon-kebab',
    icon: faShishKebab,
    path: kebabIcon,
    v4: '2_22',
  }, {
    id: 'icon-ice-cream',
    icon: faIceCream,
    path: iceCreamIcon,
    v4: '2_23',
  }, {
    id: 'icon-utensils',
    icon: faUtensils,
    path: utensilsIcon,
    v4: '2_25',
  }, {
    id: 'icon-fries',
    icon: faFrenchFries,
    path: friesIcon,
    v4: '2_26',
  }, {
    id: 'icon-burger-soda',
    icon: faBurgerSoda,
    path: burgerSodaIcon,
    v4: '2_27',
  }, {
    id: 'icon-mug',
    icon: faMugHot,
    path: mugIcon,
    v4: '2_28',
  }, {
    id: 'icon-salad',
    icon: faSalad,
    path: saladIcon,
  }, {
    id: 'icon-hotdog',
    icon: faHotdog,
    path: hotdogIcon,
  }, {
    id: 'icon-bowl-hot',
    icon: faBowlHot,
    path: bowlHotIcon,
  }, {
    id: 'icon-croissant',
    icon: faCroissant,
    path: croissantIcon,
  }, {
    id: 'icon-citrus-slice',
    icon: faCitrusSlice,
    path: citrusSliceIcon,
    v4: '3_0',
  }, {
    id: 'icon-cocktail',
    icon: faCocktail,
    path: cocktailIcon,
    v4: '3_1',
  }, {
    id: 'icon-donut',
    icon: faDonut,
    path: donutIcon,
    v4: '6_11',
  }, {
    id: 'icon-cake',
    icon: faCakeSlice,
    path: cakeSliceIcon,
    v4: '5_27',
  }, {
    id: 'icon-strawberry',
    icon: faStrawberry,
    path: strawberryIcon,
    v4: '6_12',
  }, {
    id: 'icon-apple',
    icon: faAppleAlt,
    path: appleIcon,
    v4: '5_23',
  }, {
    id: 'icon-candy',
    icon: faCandy,
    path: candyIcon,
    v4: '6_13',
  }, {
    id: 'icon-cheese',
    icon: faCheeseSwiss,
    path: cheeseIcon,
    v4: '5_25',
  }, {
    id: 'icon-ferris-wheel',
    icon: faFerrisWheel,
    path: ferrisWheelIcon,
    v4: '3_3',
  }, {
    id: 'icon-image',
    icon: faImage,
    path: imageIcon,
    v4: '3_4',
  }, {
    id: 'icon-horse-saddle',
    icon: faHorseSaddle,
    path: horseSaddleIcon,
    v4: '3_6',
  }, {
    id: 'icon-microphone-stand',
    icon: faMicrophoneStand,
    path: microphoneStandIcon,
    v4: '3_8',
  }, {
    id: 'icon-gamepad',
    icon: faGamepadAlt,
    path: gamepadIcon,
    v4: '3_9',
  }, {
    id: 'icon-fishing-rod',
    icon: faFishingRod,
    path: fishingRodIcon,
    v4: '3_10',
  }, {
    id: 'icon-books',
    icon: faBooks,
    path: booksIcon,
    v4: '3_11',
  }, {
    id: 'icon-music',
    icon: faMusic,
    path: musicIcon,
    v4: '3_12',
  }, {
    id: 'icon-guitar-electric',
    icon: faGuitarElectric,
    path: guitarElectricIcon,
  }, {
    id: 'icon-piano',
    icon: faPiano,
    path: pianoIcon,
  }, {
    id: 'icon-drum',
    icon: faDrum,
    path: drumIcon,
  }, {
    id: 'icon-flask',
    icon: faFlask,
    path: flaskIcon,
    v4: '3_14',
  }, {
    id: 'icon-peace',
    icon: faPeace,
    path: peaceIcon,
    v4: '3_15',
  }, {
    id: 'icon-umbrella-beach',
    icon: faUmbrellaBeach,
    path: umbrellaBeachIcon,
    v4: '3_18',
  }, {
    id: 'icon-theater',
    icon: faTheaterMasks,
    path: theaterIcon,
    v4: '3_19',
  }, {
    id: 'icon-wand',
    icon: faWandSparkles,
    path: wandIcon,
    v4: '3_21',
  }, {
    id: 'icon-cards',
    icon: faCards,
    path: cardsIcon,
    v4: '3_20',
  }, {
    id: 'icon-film',
    icon: faFilm,
    path: filmIcon,
    v4: '3_23',
  }, {
    id: 'icon-bowling-pins',
    icon: faBowlingPins,
    path: bowlingPinsIcon,
    v4: '3_24',
  }, {
    id: 'icon-binoculars',
    icon: faBinoculars,
    path: binocularsIcon,
  }, {
    id: 'icon-telescope',
    icon: faTelescope,
    path: telescopeIcon,
  }, {
    id: 'icon-chess',
    icon: faChess,
    path: chessIcon,
  }, {
    id: 'icon-palette',
    icon: faPalette,
    path: paletteIcon,
  }, {
    id: 'icon-shuttlecock',
    icon: faShuttlecock,
    path: shuttlecockIcon,
    v4: '3_29',
  }, {
    id: 'icon-dumbbell',
    icon: faDumbbell,
    path: dumbbellIcon,
    v4: '4_3',
  }, {
    id: 'icon-lacrosse',
    icon: faLacrosseStickBall,
    path: lacrosseIcon,
    v4: '4_0',
  }, {
    id: 'icon-volleyball',
    icon: faVolleyballBall,
    path: volleyballIcon,
    v4: '4_5',
  }, {
    id: 'icon-football',
    icon: faFootballBall,
    path: footballIcon,
    v4: '4_6',
  }, {
    id: 'icon-racquet',
    icon: faRacquet,
    path: racquetIcon,
    v4: '4_7',
  }, {
    id: 'icon-swimmer',
    icon: faSwimmer,
    path: swimmerIcon,
    v4: '4_8',
  }, {
    id: 'icon-futbol',
    icon: faFutbol,
    path: futbolIcon,
    v4: '4_10',
  }, {
    id: 'icon-golf-ball-tee',
    icon: faGolfBallTee,
    path: golfBallTeeIcon,
  }, {
    id: 'icon-snowboarding',
    icon: faSnowboarding,
    path: snowboardingIcon,
    v4: '4_11',
  }, {
    id: 'icon-skiing',
    icon: faSkiing,
    path: skiingIcon,
    v4: '4_12',
  }, {
    id: 'icon-person-hiking',
    icon: faPersonHiking,
    path: personHikingIcon,
  }, {
    id: 'icon-person-running',
    icon: faPersonRunning,
    path: personRunningIcon,
  }, {
    id: 'icon-dharmachakra',
    icon: faDharmachakra,
    path: dharmachakraIcon,
    v4: '4_13',
  }, {
    id: 'icon-flag-checkered',
    icon: faFlagCheckered,
    path: flagCheckeredIcon,
    v4: '4_15',
  }, {
    id: 'icon-hockey-puck',
    icon: faHockeyPuck,
    path: hockeyPuckIcon,
    v4: '4_16',
  }, {
    id: 'icon-sword',
    icon: faSword,
    path: swordIcon,
    v4: '4_17',
  }, {
    id: 'icon-ice-skate',
    icon: faIceSkate,
    path: iceSkateIcon,
    v4: '4_19',
  }, {
    id: 'icon-mask-snorkel',
    icon: faMaskSnorkel,
    path: maskSnorkelIcon,
    v4: '4_20',
  }, {
    id: 'icon-boxing-glove',
    icon: faBoxingGlove,
    path: boxingGloveIcon,
    v4: '4_21',
  }, {
    id: 'icon-shovel',
    icon: faShovel,
    path: shovelIcon,
    v4: '4_22',
  }, {
    id: 'icon-basketball',
    icon: faBasketballBall,
    path: basketballIcon,
    v4: '4_23',
  }, {
    id: 'icon-baseball',
    icon: faBaseballBatBall,
    path: baseballIcon,
    v4: '4_24',
  }, {
    id: 'icon-bow-arrow',
    icon: faBowArrow,
    path: bowArrowIcon,
    v4: '4_25',
  }, {
    id: 'icon-crosshairs',
    icon: faCrosshairs,
    path: crosshairsIcon,
    v4: '4_26',
  }, {
    id: 'icon-book-font',
    icon: faBookFont,
    path: bookFontIcon,
    v4: '4_27',
  }, {
    id: 'icon-uniform-martial-arts',
    icon: faUniformMartialArts,
    path: uniformMartialArtsIcon,
    v4: '4_29',
  }, {
    id: 'icon-campground',
    icon: faCampground,
    path: campgroundIcon,
  }, {
    id: 'icon-trophy',
    icon: faTrophy,
    path: trophyIcon,
  }, {
    id: 'icon-h-square',
    icon: faHSquare,
    path: hSquareIcon,
    v4: '2_24',
  }, {
    id: 'icon-plus',
    icon: faPlus,
    path: plusIcon,
    v4: '5_0',
  }, {
    id: 'icon-heartbeat',
    icon: faHeartbeat,
    path: heartbeatIcon,
    v4: '5_1',
  }, {
    id: 'icon-pills',
    icon: faPills,
    path: pillsIcon,
    v4: '5_2',
  }, {
    id: 'icon-tooth',
    icon: faTooth,
    path: toothIcon,
    v4: '5_3',
  }, {
    id: 'icon-lips',
    icon: faLips,
    path: lipsIcon,
    v4: '5_4',
  }, {
    id: 'icon-scissors',
    icon: faScissors,
    path: scissorsIcon,
  }, {
    id: 'icon-toilet',
    icon: faToilet,
    path: toiletIcon,
    v4: '5_6',
  }, {
    id: 'icon-person',
    icon: faPerson,
    path: personIcon,
  }, {
    id: 'icon-person-dress',
    icon: faPersonDress,
    path: personDressIcon,
  }, {
    id: 'icon-child',
    icon: faChild,
    path: childIcon,
  }, {
    id: 'icon-rings-wedding',
    icon: faRingsWedding,
    path: ringsWeddingIcon,
  }, {
    id: 'icon-baby-carriage',
    icon: faBabyCarriage,
    path: babyCarriageIcon,
  }, {
    id: 'icon-baby',
    icon: faBaby,
    path: babyIcon,
    v4: '5_7',
  }, {
    id: 'icon-graduation-cap',
    icon: faGraduationCap,
    path: graduationCapIcon,
    v4: '5_8',
  }, {
    id: 'icon-teddy-bear',
    icon: faTeddyBear,
    path: teddyBearIcon,
    v4: '5_11',
  }, {
    id: 'icon-paw',
    icon: faPaw,
    path: pawIcon,
  }, {
    id: 'icon-cat',
    icon: faCat,
    path: catIcon,
    v4: '5_9',
  }, {
    id: 'icon-dog',
    icon: faDog,
    path: dogIcon,
    v4: '5_12',
  }, {
    id: 'icon-fish',
    icon: faFish,
    path: fishIcon,
    v4: '5_14',
  }, {
    id: 'icon-dolphin',
    icon: faDolphin,
    path: dolphinIcon,
    v4: '5_16',
  }, {
    id: 'icon-cow',
    icon: faCow,
    path: cowIcon,
    v4: '5_17',
  }, {
    id: 'icon-lobster',
    icon: faLobster,
    path: lobsterIcon,
    v4: '5_18',
  }, {
    id: 'icon-crow',
    icon: faCrow,
    path: crowIcon,
  }, {
    id: 'icon-duck',
    icon: faDuck,
    path: duckIcon,
    v4: '5_20',
  }, {
    id: 'icon-monkey',
    icon: faMonkey,
    path: monkeyIcon,
    v4: '5_21',
  }, {
    id: 'icon-bee',
    icon: faBee,
    path: beeIcon,
    v4: '5_22',
  }, {
    id: 'icon-snake',
    icon: faSnake,
    path: snakeIcon,
    v4: '5_24',
  }, {
    id: 'icon-deer',
    icon: faDeer,
    path: deerIcon,
    v4: '5_28',
  }, {
    id: 'icon-bat',
    icon: faBat,
    path: batIcon,
    v4: '5_29',
  }, {
    id: 'icon-tree-alt',
    icon: faTreeAlt,
    path: treeAltIcon,
    v4: '0_14',
  }, {
    id: 'icon-tree-palm',
    icon: faTreePalm,
    path: treePalmIcon,
    v4: '2_20',
  }, {
    id: 'icon-leaf',
    icon: faLeaf,
    path: leafIcon,
    v4: '5_10',
  }, {
    id: 'icon-mushroom',
    icon: faMushroom,
    path: mushroomIcon,
    v4: '5_26',
  }, {
    id: 'icon-flower-tulip',
    icon: faFlowerTulip,
    path: flowerTulipIcon,
    v4: '6_17',
  }, {
    id: 'icon-wheat',
    icon: faWheat,
    path: wheatIcon,
  }, {
    id: 'icon-recycle',
    icon: faRecycle,
    path: recycleIcon,
    v4: '6_0',
  }, {
    id: 'icon-wind-turbine',
    icon: faWindTurbine,
    path: windTurbineIcon,
  }, {
    id: 'icon-solar-panel',
    icon: faSolarPanel,
    path: solarPanelIcon,
  }, {
    id: 'icon-mountain',
    icon: faMountain,
    path: mountainIcon,
    v4: '6_1',
  }, {
    id: 'icon-tornado',
    icon: faTornado,
    path: tornadoIcon,
    v4: '6_2',
  }, {
    id: 'icon-snowflakes',
    icon: faSnowflakes,
    path: snowflakesIcon,
    v4: '6_3',
  }, {
    id: 'icon-thunderstorm',
    icon: faThunderstorm,
    path: thunderstormIcon,
    v4: '6_4',
  }, {
    id: 'icon-sun',
    icon: faSun,
    path: sunIcon,
    v4: '6_5',
  }, {
    id: 'icon-sun-cloud',
    icon: faSunCloud,
    path: sunCloudIcon,
  }, {
    id: 'icon-clouds',
    icon: faClouds,
    path: cloudsIcon,
    v4: '6_6',
  }, {
    id: 'icon-raindrops',
    icon: faRaindrops,
    path: raindropsIcon,
    v4: '6_7',
  }, {
    id: 'icon-wind',
    icon: faWind,
    path: windIcon,
    v4: '6_8',
  }, {
    id: 'icon-umbrella',
    icon: faUmbrella,
    path: umbrellaIcon,
    v4: '5_15',
  }, {
    id: 'icon-suitcase',
    icon: faSuitcase,
    path: suitcaseIcon,
    v4: '6_9',
  }, {
    id: 'icon-boot-heeled',
    icon: faBootHeeled,
    path: bootHeeledIcon,
    v4: '6_10',
  }, {
    id: 'icon-shirt-long-sleeve',
    icon: faShirtLongSleeve,
    path: shirtLongSleeveIcon,
  }, {
    id: 'icon-shopping-basket',
    icon: faShoppingBasket,
    path: shoppingBasketIcon,
    v4: '6_14',
  }, {
    id: 'icon-ribbon',
    icon: faRibbon,
    path: ribbonIcon,
    v4: '6_16',
  }, {
    id: 'icon-gift',
    icon: faGift,
    path: giftIcon,
  }, {
    id: 'icon-balloons',
    icon: faBalloons,
    path: balloonsIcon,
  }, {
    id: 'icon-mailbox',
    icon: faMailbox,
    path: mailboxIcon,
  }, {
    id: 'icon-envelope',
    icon: faEnvelope,
    path: envelopeIcon,
  }, {
    id: 'icon-box',
    icon: faBox,
    path: boxIcon,
  }, {
    id: 'icon-money-bill-1',
    icon: faMoneyBill1,
    path: moneyBill1Icon,
  }, {
    id: 'icon-sack-dollar',
    icon: faSackDollar,
    path: sackDollarIcon,
  }, {
    id: 'icon-dollar-sign',
    icon: faDollarSign,
    path: dollarSignIcon,
  }, {
    id: 'icon-piggy-bank',
    icon: faPiggyBank,
    path: piggyBankIcon,
  }, {
    id: 'icon-cart-shopping',
    icon: faCartShopping,
    path: cartShoppingIcon,
  }, {
    id: 'icon-trash-can',
    icon: faTrashCan,
    path: trashCanIcon,
  }, {
    id: 'icon-fire-hydrant',
    icon: faFireHydrant,
    path: fireHydrantIcon,
  }, {
    id: 'icon-faucet-drip',
    icon: faFaucetDrip,
    path: faucetDripIcon,
  }, {
    id: 'icon-shower',
    icon: faShower,
    path: showerIcon,
  }, {
    id: 'icon-water-ladder',
    icon: faWaterLadder,
    path: waterLadderIcon,
  }, {
    id: 'icon-broadcast-tower',
    icon: faBroadcastTower,
    path: broadcastTowerIcon,
    v4: '3_2',
  }, {
    id: 'icon-laptop-mobile',
    icon: faLaptopMobile,
    path: laptopMobileIcon,
  }, {
    id: 'icon-wifi',
    icon: faWifi,
    path: wifiIcon,
  }, {
    id: 'icon-newspaper',
    icon: faNewspaper,
    path: newspaperIcon,
  }, {
    id: 'icon-bullhorn',
    icon: faBullhorn,
    path: bullhornIcon,
  }, {
    id: 'icon-phone',
    icon: faPhone,
    path: phoneIcon,
  }, {
    id: 'icon-heart',
    icon: faHeart,
    path: heartIcon,
  }, {
    id: 'icon-lightbulb-on',
    icon: faLightbulbOn,
    path: lightbulbOnIcon,
  }, {
    id: 'icon-question',
    icon: faQuestion,
    path: questionIcon,
  }, {
    id: 'icon-exclamation',
    icon: faExclamation,
    path: exclamationIcon,
  }, {
    id: 'icon-check',
    icon: faCheck,
    path: checkIcon,
  }, {
    id: 'icon-xmark',
    icon: faXmark,
    path: xmarkIcon,
  },
] as const;
