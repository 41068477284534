import { type MapSettingsFileAttachmentsResponse } from '~/map/map.repository';
import { mapSettingsFileAttachmentsInitialState } from './fileAttachments.reducer';
import { type MapSettingsFileAttachmentsState } from './fileAttachments.state';

export const fileAttachmentSerializer = {
  jsonToState: (json: MapSettingsFileAttachmentsResponse): MapSettingsFileAttachmentsState => {
    const state = {
      ...mapSettingsFileAttachmentsInitialState,
    };

    if (json.map) {
      state.map = new Map(Object.entries(json.map));
    }

    return state;
  },
  stateToJson: (state: MapSettingsFileAttachmentsState): MapSettingsFileAttachmentsResponse => {
    const { map, ...rest } = state;

    const json: MapSettingsFileAttachmentsResponse = {
      ...rest,
      map: Object.fromEntries(map),
    };

    return json;
  },
};
