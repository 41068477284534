import { type SearchMatchingBehaviour } from '~/_shared/constants/searchMatchingBehaviour.enum';
import {
  newPerSpreadsheetMap, type PerSpreadsheetFilteredRowsIdsMap, type ReadonlyPerSpreadsheetFilteredRowsIdsMap,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import {
  type CombinedRowId, type SpreadsheetId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { spreadStackedLatLngsIntoRings } from '~/_shared/utils/gis/spreadLatLngs.helpers';
import { getMarkerGroup } from '~/_shared/utils/grouping/grouping.helpers';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import {
  memoizeOne,
  memoizeWeak,
} from '~/_shared/utils/memoize/memoize';
import { STACKED_MARKER_DECIMALS } from '~/map/map/markers/useStacksAndClusters/StackedMarkerId.type';
import {
  type BoundaryLocationsFilters, getBoundaryTerritoryGroupLocationsFiltersMemoized,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { type AppState } from '../app.store';
import { type MapSettingsColumnsFilterState } from '../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { selectMapSettingsDirectionsRoutesSpreadsheetRowsSelector } from '../mapSettings/directions/mapSettingsDirections.selectors';
import { type MapSettingsFilteringState } from '../mapSettings/filtering/mapSettingsFiltering.state';
import { type MapSettingsGroupingState } from '../mapSettings/grouping/mapSettingsGrouping.state';
import { selectHeatmapGroupsFilter } from '../mapSettings/heatmaps/useHeatmapItems.selector';
import { hideAllMarkersSelector } from '../mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { mapSettingsUnstackMarkerSelector } from '../mapSettings/markers/mapSettingsMarkersUnstacking.selectors';
import { type MapSettingsSearchItemsState } from '../mapSettings/searchItems/mapSettingsSearchItems.state';
import { type MatchupDataState } from '../matchupData/matchupData.state';
import {
  checkIfSpreadsheetRowIdMeetsFilteringCriteria,
  type FilteredRowIdsResults,
  getLatLngFromSpreadsheetData,
  getPerSpreadsheetFilteredRowIds,
  getPerSpreadsheetPerRowLookupFromLatLngRowData,
  type GetSpreadSheetFilteredRowIdsParams,
  intersectionOfPerSpreadsheetDataRows,
  type LatLngRowData,
} from '../spreadsheetData/spreadsheetData.helpers';
import {
  emptySpreadsheetData,
  type SpreadsheetDataData,
  type SpreadsheetFilters,
} from '../spreadsheetData/spreadsheetData.state';

export type SpreadSheetData = Readonly<{
  spreadsheetData: SpreadsheetDataData;
  matchupData: MatchupDataState;
  spreadsheetIds: ReadonlyArray<number>;
  areLoaded: boolean;
}>;

export type SpreadsheetLatLngRowData = Readonly<{
  data: ReadonlyArray<LatLngRowData>;
  getRow: (spreadsheetRowId: SpreadsheetRowId) => LatLngRowData | undefined;
  getSpreadsheet: (spreadsheetId: number) => IterableIterator<LatLngRowData> | undefined;
  // in some cases, e.g. when unstacking markers, we artificially adjust the locations in the data
  // the following function allow us to retrieve the original data, if needed
  // for example, if we wanted to draw a line between the artificially adjusted and the original location
  originalData?: ReadonlyArray<LatLngRowData>;
  getRowOriginal: (spreadsheetRowId: SpreadsheetRowId) => LatLngRowData | undefined;
}>;

export const emptySpreadsheetLatLngRowData: SpreadsheetLatLngRowData = {
  data: [],
  getRow: () => undefined,
  getSpreadsheet: () => undefined,
  getRowOriginal: () => undefined,
};

/**
 * @param dataLookup is optional, but can be provided for performance reasons to avoid generating the lookup map
 * @param getRowOriginal is optional, but should be provided in case the data was artificially adjusted, so it's possible to look up the original
 */
export const getSpreadsheetLatLngRowData = (
  data: ReadonlyArray<LatLngRowData>,
  dataLookup?: Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>,
  originalData?: ReadonlyArray<LatLngRowData>,
  getRowOriginal?: (spreadsheetRowId: SpreadsheetRowId) => LatLngRowData | undefined,
): SpreadsheetLatLngRowData => {
  dataLookup = dataLookup || getPerSpreadsheetPerRowLookupFromLatLngRowData(data);
  const getRow = (spreadsheetRowId: SpreadsheetRowId) => dataLookup?.get(spreadsheetRowId.spreadsheetId)?.get(spreadsheetRowId.rowId);
  const getSpreadsheet = (spreadsheetId: SpreadsheetId) => dataLookup?.get(spreadsheetId)?.values();

  return {
    data,
    getRow,
    getSpreadsheet,
    originalData: originalData ? originalData : data,
    getRowOriginal: getRowOriginal || getRow,
  };
};

export const emptyFilteredRowsIdMap: ReadonlyPerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

export type FilteredRowIds = FilteredRowIdsResults & {
  readonly visibleRowIds: ReadonlyPerSpreadsheetFilteredRowsIdsMap;
};

export type FilteredLatLngRowData = Readonly<{
  filter: SpreadsheetLatLngRowData;
  search: SpreadsheetLatLngRowData;
  boundaryLocationsFilter: SpreadsheetLatLngRowData;
  visible: SpreadsheetLatLngRowData;
}>;

const emptySpreadsheets: ReadonlyArray<number> = [];

type SelectSpreadsheetDataType = (state: AppState) => SpreadSheetData;
export const selectSpreadsheetData: SelectSpreadsheetDataType = createStateSelector(
  [
    state => state.spreadsheet.spreadsheetData.data,
    state => state.spreadsheet.matchupData,
    state => !state.map.mapInfo.isLoading
      && !state.spreadsheet.spreadsheetData.isLoading
      && state.spreadsheet.spreadsheetData.isReady,
    state => state.map.mapInfo.data?.spreadsheets,
  ],
  (spreadsheetData, matchupData, areLoaded, spreadsheets): SpreadSheetData => ({
    spreadsheetData: spreadsheetData ?? emptySpreadsheetData,
    matchupData,
    spreadsheetIds: spreadsheets?.map(spreadsheet => spreadsheet.spreadSheetId) ?? emptySpreadsheets,
    areLoaded,
  })
);

export const selectLatLngSpreadsheetData = createStateSelector(
  [
    selectSpreadsheetData,
    (state, spreadStackedLatLng?: boolean) => spreadStackedLatLng ?? mapSettingsUnstackMarkerSelector(state),
  ],
  (spreadSheetData, spreadStackedLatLng): SpreadsheetLatLngRowData => {
    const latLngs = getLatLngSpreadsheetDataMemoized(spreadSheetData);
    return spreadStackedLatLng ? latLngs.spread : latLngs.original;
  });

export const selectFilteredSpreadsheetRowIds = (state: AppState): FilteredRowIdsResults => {
  const data = selectSpreadsheetData(state);
  const mapSettings = state.map.mapSettings;

  const filteredRowIdsParams = selectFilteredSpreadsheetRowIdsParamsMemoized(
    data.spreadsheetIds,
    data.spreadsheetData,
    state.spreadsheet.spreadsheetData.filters,
    mapSettings.data.toolsState.columnsFilter,
    mapSettings.data.filtering,
    mapSettings.data.grouping,
    mapSettings.data.toolsState.searchItems,
    mapSettings.data.search.selectedMatchingBehaviour,
    getBoundaryTerritoryGroupLocationsFiltersMemoized(mapSettings.data.toolsState.boundary.locationsFilter),
  );

  return selectFilteredSpreadsheetRowIdsMemoized(filteredRowIdsParams);
};

type SelectVisibleFilteredSpreadsheetRowIdsType = (state: AppState, spreadsheetLatLngRowData?: SpreadsheetLatLngRowData) => FilteredRowIds;
export const selectVisibleFilteredSpreadsheetRowIds: SelectVisibleFilteredSpreadsheetRowIdsType = createStateSelector(
  [
    hideAllMarkersSelector,
    selectSpreadsheetData,
    selectFilteredSpreadsheetRowIds,
    (state, spreadsheetLatLngRowData?: SpreadsheetLatLngRowData) =>
      selectFilteredHeatmapGroupRowIds(state, spreadsheetLatLngRowData ?? selectLatLngSpreadsheetData(state)),
  ],
  (hideAllMarkers, spreadsheetData, filteredRowIds, filteredHeatmapGroupRowIds): FilteredRowIds => {
    if (hideAllMarkers) {
      return selectEmptyVisibleSpreadsheetRowIdsMemoized(filteredRowIds, spreadsheetData.spreadsheetIds);
    }
    const visibleRowIds = intersectionOfPerSpreadsheetDataRows([
      filteredRowIds.filteredRowIds,
      filteredRowIds.boundaryLocationsFilterRowIds,
      filteredHeatmapGroupRowIds,
    ]);

    return {
      ...filteredRowIds,
      visibleRowIds,
    };
  });

export const selectFilteredLatLngSpreadsheetData = createStateSelector(
  [
    state => selectSpreadsheetData(state),
    (state, latLngData?: SpreadsheetLatLngRowData) => latLngData ?? selectLatLngSpreadsheetData(state),
    (state, _?: SpreadsheetLatLngRowData, filteredData?: FilteredRowIds) => filteredData ?? selectVisibleFilteredSpreadsheetRowIds(state),
  ],
  (spreadsheetData, latLngData, filteredData): FilteredLatLngRowData => {

      type Cache = { data: LatLngRowData[]; lookup: Map<number, Map<CombinedRowId, LatLngRowData>> };

      const cache: { filter: Cache; search: Cache; boundaryLocationsFilter: Cache; visible: Cache } = {
        filter: { data: [], lookup: new Map() },
        search: { data: [], lookup: new Map() },
        boundaryLocationsFilter: { data: [], lookup: new Map() },
        visible: { data: [], lookup: new Map() },
      };

      const addRow = (row: LatLngRowData, bucket: Cache) => {
        bucket.data.push(row);
        bucket.lookup.get(row.spreadsheetId)?.set(row.rowId, row);
      };

      spreadsheetData.spreadsheetIds.forEach(spreadsheetId => {
        cache.filter.lookup.set(spreadsheetId, new Map<CombinedRowId, LatLngRowData>());
        cache.search.lookup.set(spreadsheetId, new Map<CombinedRowId, LatLngRowData>());
        cache.boundaryLocationsFilter.lookup.set(spreadsheetId, new Map<CombinedRowId, LatLngRowData>());
        cache.visible.lookup.set(spreadsheetId, new Map<CombinedRowId, LatLngRowData>());

        const spreadsheetRows = latLngData.getSpreadsheet(spreadsheetId);
        if (spreadsheetRows) {
          for (const row of spreadsheetRows) {
            if (checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredData.filteredRowIds, row)) {
              addRow(row, cache.filter);
            }
            if (checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredData.searchRowIds, row)) {
              addRow(row, cache.search);
            }
            if (checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredData.boundaryLocationsFilterRowIds, row)) {
              addRow(row, cache.boundaryLocationsFilter);
            }
            if (checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredData.visibleRowIds, row)) {
              addRow(row, cache.visible);
            }
          }
        }
      });

      return {
        filter: getSpreadsheetLatLngRowData(cache.filter.data, cache.filter.lookup, cache.filter.data, latLngData.getRowOriginal),
        search: getSpreadsheetLatLngRowData(cache.search.data, cache.search.lookup, cache.search.data, latLngData.getRowOriginal),
        boundaryLocationsFilter: getSpreadsheetLatLngRowData(cache.boundaryLocationsFilter.data, cache.boundaryLocationsFilter.lookup, cache.boundaryLocationsFilter.data, latLngData.getRowOriginal),
        visible: getSpreadsheetLatLngRowData(cache.visible.data, cache.visible.lookup, cache.visible.data, latLngData.getRowOriginal),
      };
  });

const selectAllFilteredSpreadsheetRowsMemoized = memoizeOne((
  latLngData: ReadonlyArray<LatLngRowData>,
  filteredRowIdsResults: FilteredRowIdsResults,
) => (
  latLngData.filter((row) => checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredRowIdsResults.filteredRowIds, row))
));
export const selectAllFilteredSpreadsheetRows = (state: AppState): ReadonlyArray<LatLngRowData> => {
  const spreadSheetData = selectSpreadsheetData(state);
  const latLngData = getLatLngSpreadsheetDataMemoized(spreadSheetData).original;
  return selectAllFilteredSpreadsheetRowsMemoized(
    latLngData.data,
    selectFilteredSpreadsheetRowIds(state),
  );
};

const getLatLngSpreadsheetDataMemoized = memoizeWeak((params: SpreadSheetData): { original: SpreadsheetLatLngRowData; spread: SpreadsheetLatLngRowData } => {
  const data: LatLngRowData[] = [];
  const lookup = new Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>();

  params.spreadsheetIds.forEach(id => {
    const rowLookup = new Map<CombinedRowId, LatLngRowData>();
    lookup.set(id, rowLookup);

    if (params.spreadsheetData) {
      getLatLngFromSpreadsheetData(id, params.spreadsheetData, params.matchupData).forEach(rowData => {
        data.push(rowData);
        rowLookup.set(rowData.rowId, rowData);
      });
    }
  });

  const getSpreadDataAndLookup = (
    originalData: LatLngRowData[],
    originalDataLookup: Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>
  ): [
      LatLngRowData[],
      Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>,
      (spreadsheetRowId: SpreadsheetRowId) => LatLngRowData | undefined,
    ] => {
    const spreadLatLngs = spreadStackedLatLngsIntoRings(originalData, STACKED_MARKER_DECIMALS);
    const spreadData: LatLngRowData[] = [];
    const spreadLookup = new Map<SpreadsheetId, Map<CombinedRowId, LatLngRowData>>();
    originalData.forEach((latLng, i) => {
      let spreadLookupSpreadsheet = spreadLookup.get(latLng.spreadsheetId);
      if (!spreadLookupSpreadsheet) {
        spreadLookupSpreadsheet = new Map<CombinedRowId, LatLngRowData>();
        spreadLookup.set(latLng.spreadsheetId, spreadLookupSpreadsheet);
      }

      const spreadLatLng = spreadLatLngs[i];

      if (spreadLatLng) {
        const spreadLatLngRow = {
          ...spreadLatLng,
          rowId: latLng.rowId,
          spreadsheetId: latLng.spreadsheetId,
        };
        spreadData.push(spreadLatLngRow);
        spreadLookupSpreadsheet.set(spreadLatLngRow.rowId, spreadLatLngRow);
      }
      else {
        spreadLookupSpreadsheet.set(latLng.rowId, latLng);
        spreadData.push(latLng);
      }
    });

    const getRowOriginal = (spreadsheetRowId: SpreadsheetRowId) =>
      originalDataLookup?.get(spreadsheetRowId.spreadsheetId)?.get(spreadsheetRowId.rowId);

    return [spreadData, spreadLookup, getRowOriginal];
  };

  const [spreadLatLngs, spreadLookup, getRowOriginal] = getSpreadDataAndLookup(data, lookup);

  return {
    original: getSpreadsheetLatLngRowData(data, lookup),
    spread: getSpreadsheetLatLngRowData(spreadLatLngs, spreadLookup, data, getRowOriginal),
  };
});

const selectFilteredSpreadsheetRowIdsParamsMemoized = memoizeOne((
  spreadsheetIds: ReadonlyArray<number>,
  spreadsheetData: SpreadsheetDataData | null,
  filters: SpreadsheetFilters | null,
  columnsFilter: MapSettingsColumnsFilterState,
  filtering: MapSettingsFilteringState,
  grouping: MapSettingsGroupingState,
  searchItems: MapSettingsSearchItemsState,
  selectedMatchingBehaviour: SearchMatchingBehaviour,
  boundaryLocationsFilters: BoundaryLocationsFilters,
): GetSpreadSheetFilteredRowIdsParams => ({
  spreadsheetIds,
  spreadsheetData,
  filters,
  columnsFilter,
  filtering,
  grouping,
  searchItems,
  selectedMatchingBehaviour,
  boundaryLocationsFilters,
}));

const selectFilteredSpreadsheetRowIdsMemoized = memoizeWeak((params: GetSpreadSheetFilteredRowIdsParams) =>
  getPerSpreadsheetFilteredRowIds(params));

const selectFilteredHeatmapGroupRowIds = createStateSelector(
  [
    state => selectSpreadsheetData(state).spreadsheetData,
    selectHeatmapGroupsFilter,
    (_state, latLngData: SpreadsheetLatLngRowData) => latLngData.data,
  ],
  (spreadsheetData, heatmapGroupsFilter, latLngData): ReadonlyPerSpreadsheetFilteredRowsIdsMap => {
    const visibleRowIds: PerSpreadsheetFilteredRowsIdsMap = {};

    if (!heatmapGroupsFilter.length) {
      return visibleRowIds;
    }

    latLngData
      .forEach(({ spreadsheetId, rowId }) => {
        const filter = visibleRowIds[Number(spreadsheetId)] ?? (visibleRowIds[Number(spreadsheetId)] = {});

        const isRowFiltered = heatmapGroupsFilter
          .some(({ column, group }) => column && group && column.spreadsheetId === spreadsheetId &&
          getMarkerGroup(spreadsheetData, spreadsheetId, column.columnId, rowId)?.toLowerCase() === group.toLowerCase());

        if (isRowFiltered) {
          filter[rowId] = 1;
        }
      });

    return visibleRowIds;
  });

const selectEmptyVisibleSpreadsheetRowIdsMemoized = memoizeOne((filteredRowIds: FilteredRowIdsResults, spreadsheetIds: ReadonlyArray<number>): FilteredRowIds => {
  return {
    ...filteredRowIds,
    visibleRowIds: spreadsheetIds.reduce((acc: ReadonlyPerSpreadsheetFilteredRowsIdsMap, current) => {
      acc[current] = {};
      return acc;
    }, {}),
  };
});

export const selectVisibleRowsWithoutWaypoints = createStateSelector(
  [
    selectMapSettingsDirectionsRoutesSpreadsheetRowsSelector,
    (state, filteredLatLngRowData?: FilteredLatLngRowData) => filteredLatLngRowData ?? selectFilteredLatLngSpreadsheetData(state),
  ],
  (directionsSpreadsheetRows, filteredRows): ReadonlyArray<LatLngRowData> => filteredRows.visible.data.filter(markerRow =>
    !directionsSpreadsheetRows.get(markerRow.spreadsheetId)?.has(markerRow.rowId)
  )
);
