import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { KeyboardKeys } from '~/_shared/utils/hooks/useKeyPress';
import {
  type MapKeyPressCallback, MapKeyPressPriority, useMapKeyPressContext,
} from '~/_shared/utils/hooks/useMapKeyPressContext';
import { areLatLngPartiallyEqual } from '~/_shared/utils/latLng/latLng.helpers';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  DrawingToolMode, useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingRectangleSettingsState } from '~/store/mapSettings/drawing/settings/rectangle/drawingRectangleSettings.state';
import { type DrawingToolManager } from '../drawingToolManager';
import { DrawingToolRectangleInstanceRectangleContainer } from './drawingToolRectangleInstanceRectangle.container';

type DrawingToolRectangleCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingRectangleSettingsState;
}>;

export const DrawingToolRectangleCreateNewInstanceContainer: FC<DrawingToolRectangleCreateNewInstanceProps> = ({
  settings, manager,
}) => {
  const [rectangleStartPoint, setRectangleStartPoint] = useState<LatLng | null>(null);
  const [temporaryRectangleEndPoint, setTemporaryRectangleEndPoint] = useState<LatLng | null>(null);
  const drawingToolMode = useDrawingToolModeSelector();
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    const eventPoint = event.latLng && { lat: event.latLng.lat(), lng: event.latLng.lng() };

    // start drawing - set starting point
    if (!rectangleStartPoint) {
      if (eventPoint) {
        setRectangleStartPoint(eventPoint);
      }

      return;
    }

    if (rectangleStartPoint && eventPoint) {
      if (areLatLngPartiallyEqual(rectangleStartPoint, eventPoint)) {
        return;
      }

      // end drawing - add drawing to the map
      const id = createUuid();
      dispatch(drawingItemsAddItem(
        id,
        {
          type: DrawingTool.Rectangle,
          value: {
            id,
            startPoint: rectangleStartPoint,
            endPoint: eventPoint,
            placement: DrawingItemPlacement.Default,
            settings,
          },
        }));

      setRectangleStartPoint(null);
      setTemporaryRectangleEndPoint(null);
    }
  }, [dispatch, rectangleStartPoint, settings]);

  const onMapMouseMove = useCallback((e: google.maps.MapMouseEvent) => {
    if (!rectangleStartPoint || !e.latLng) {
      return;
    }

    setTemporaryRectangleEndPoint({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, [rectangleStartPoint]);

  useEffect(() => {
    if (drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);
    const mouseMoveListener = manager.addMapMouseMoveListener(onMapMouseMove);

    return () => {
      clickListener.remove();
      mouseMoveListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode, onMapMouseMove]);

  const { addMapKeyHandler } = useMapKeyPressContext();

  useEffect(() => { // Reset drawing on ESC
    if (!rectangleStartPoint) {
      return;
    }

    const onEsc: MapKeyPressCallback = (e) => {
      setRectangleStartPoint(null);
      setTemporaryRectangleEndPoint(null);

      e.stopPropagation();
    };

    const removeKeyHandler = addMapKeyHandler(KeyboardKeys.Escape, MapKeyPressPriority.ResetDrawing, onEsc);
    return removeKeyHandler;
  }, [addMapKeyHandler, rectangleStartPoint]);

  if (!rectangleStartPoint || !temporaryRectangleEndPoint) {
    return null;
  }

  return (
    <DrawingToolRectangleInstanceRectangleContainer
      renderOnlyLine
      instance={{
        id: 'drawing-tool-drawing-new-rectangle',
        startPoint: rectangleStartPoint,
        endPoint: temporaryRectangleEndPoint,
        placement: DrawingItemPlacement.Default,
        settings,
      }}
      zIndex={zIndex}
    />
  );
};
