import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
} from '~/_shared/utils/api/api.helpers';

export enum TeamShareMapPermission {
  OWNER = 'Owner',
  CONTRIBUTOR = 'Contributor',
}

export enum EntityAccessLevel {
  WRITE = 'write',
  READ = 'read',
}

export enum EntityType {
  MAP = 'map',
}

export enum SharedEntityType {
  USER = 'user',
}

export type EntityAccess = {
  id: number;
  ownerId: number;
  entityType: EntityType;
  entityId: number;
  sharedEntityType: SharedEntityType;
  sharedEntityId: number;
  accessLevel: EntityAccessLevel;
  createdAt: string;
  updatedAt: string;
};

export type EntityAccessResponse = {
  id: number;
  owner_id: number;
  entity_type: EntityType;
  entity_id: number;
  shared_entity_type: SharedEntityType;
  shared_entity_id: number;
  access_level: EntityAccessLevel;
  created_at: string;
  updated_at: string;
};

export type GetEntityAccessesResponse = {
  list: EntityAccessResponse[];
};

export const entityAccessResponseToEntityAccess = (entityAccessResponse: EntityAccessResponse): EntityAccess => ({
  id: entityAccessResponse.id,
  ownerId: entityAccessResponse.owner_id,
  entityType: entityAccessResponse.entity_type,
  entityId: entityAccessResponse.entity_id,
  sharedEntityType: entityAccessResponse.shared_entity_type,
  sharedEntityId: entityAccessResponse.shared_entity_id,
  accessLevel: entityAccessResponse.access_level,
  createdAt: entityAccessResponse.created_at,
  updatedAt: entityAccessResponse.updated_at,
});

export const teamShareMapPermissionToEntityAccessLevel = (tsmp: TeamShareMapPermission) => {
  if (tsmp === TeamShareMapPermission.OWNER || tsmp === TeamShareMapPermission.CONTRIBUTOR) {
    return EntityAccessLevel.WRITE;
  }
  return EntityAccessLevel.READ;
};

export const getEntityAccessesRequest = (
  clientId: number,
  entityType?: EntityType,
  sharedEntityType?: SharedEntityType,
  accessLevel?: EntityAccessLevel,
  entityId?: number,
  sharedEntityId?: number,
): Promise<GetEntityAccessesResponse> => {
  const requestUrl = `/api/clients/${clientId}/accesses`;
  return apiGet(requestUrl, {
    entity_type: entityType,
    shared_entity_type: sharedEntityType,
    access_level: accessLevel,
    entity_id: entityId,
    shared_entity_id: sharedEntityId,
  });
};

export type NewEntityAccess = {
  accessLevel: EntityAccessLevel;
  entityId: number;
  entityType: EntityType;
  sharedEntityId: number;
  sharedEntityType: SharedEntityType;
};

export const createEntityAccessRequest = (clientId: number, newEntityAccesses: NewEntityAccess[]): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/accesses`;
  return apiPost(requestUrl, {
    params: newEntityAccesses.map(i => ({
      entity_type: i.entityType,
      shared_entity_type: i.sharedEntityType,
      access_level: i.accessLevel,
      entity_id: i.entityId,
      shared_entity_id: i.sharedEntityId,
    })),
  });
};

export const updateEntityAccessRequest = (
  clientId: number,
  entityAccessId: number,
  accessLevel: EntityAccessLevel
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/accesses/${entityAccessId}`;
  return apiPatch(requestUrl, {
    access_level: accessLevel,
  });
};

export const deleteEntityAccessRequest = (
  clientId: number,
  entityAccessId: number,
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/accesses/${entityAccessId}`;
  return apiDelete(requestUrl);
};
