import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  type SelectedDataDemographic,
  type SelectedDataMetric,
  SelectedDataType,
} from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { CustomizeMetricsComponent } from '../../_shared/components/customizeMetrics/customizeMetrics.component';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  createDemographicMetricFromSelectedData, type DemographicMetricModel, matchBoundaryGroupDemographic,
} from '../../_shared/utils/metric/demographicsMetric.factory';
import { createSelectedDataFromMetric } from '../../_shared/utils/metric/metrics.factory';
import {
  createSpreadsheetMetricFromSelectedData, type SpreadsheetColumnMetricModel,
} from '../../_shared/utils/metric/spreadsheetMetric.factory';
import { type ModalProps } from '../../modal/modalType.enum';
import { boundaryTerritoryGroupsUpdateRequest } from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { demographicsListGlobalFetchDataRequest } from '../../store/demographics/demographicsListGlobal/demographicsListGlobal.actionCreators';
import {
  useDemographicsListGlobalIsLoadingSelector,
  useDemographicsListGlobalRegionsSelector,
} from '../../store/demographics/demographicsListGlobal/demographicsListGlobal.selectors';
import { useSpreadsheetColumns } from '../../store/matchupData/matchupDataSelectors.hook';

export type BoundaryCustomizeMetricsContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  isBoundaryGroupCustom: boolean;
}>;

export const BoundaryCustomizeMetricsContainer: FC<BoundaryCustomizeMetricsContainerProps> = (props) => {
  const matchupColumns = useSpreadsheetColumns();
  const boundaryGroups = useBoundaryGroupsSelector();
  const isBoundaryTerritoryGroupsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const isDemographicsLoading = useDemographicsListGlobalIsLoadingSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const boundaryTerritoryGroup = useMemo(() =>
    boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId),
  [boundaryTerritoryGroups, props.boundaryTerritoryGroupId]);

  const boundaryGroup = useMemo(() => boundaryGroups.find(bg => bg.id === boundaryTerritoryGroup?.boundaryGroupId),
    [boundaryGroups, boundaryTerritoryGroup?.boundaryGroupId]);

  const boundaryTerritoryGroupMetrics = useMemo(() =>
    boundaryTerritoryGroup?.settings.metrics ?? [],
  [boundaryTerritoryGroup]);

  const demographicRegions = useDemographicsListGlobalRegionsSelector();

  const selectedData: SelectedDataMetric[] = useMemo(() => {
    return boundaryTerritoryGroupMetrics
      .map(metric => createSelectedDataFromMetric(metric))
      .filter((selectedData): selectedData is SelectedDataMetric => !!selectedData);
  }, [boundaryTerritoryGroupMetrics]);

  const dispatchProps = useMemo(() => ({
    setBoundaryTerritoryGroupMetrics: (selectedData: SelectedDataMetric[]) => {
      if (!boundaryTerritoryGroup || !boundaryGroup) {
        return;
      }

      const newSpreadsheetMetrics: SpreadsheetColumnMetricModel[] = selectedData
        .map(data => createSpreadsheetMetricFromSelectedData(data))
        .filter((item): item is SpreadsheetColumnMetricModel => !!item);

      const newDemographicMetrics: DemographicMetricModel[] = selectedData
        .filter((item): item is SelectedDataDemographic => item.type === SelectedDataType.Demographic)
        .map(data => {
          const matchingNativeDemographic = boundaryGroup.demographics.find(demographic => matchBoundaryGroupDemographic(demographic.id, data.selectedDemographic.id));
          if (matchingNativeDemographic) {
            return {
              ...data,
              selectedDemographic: matchingNativeDemographic,
            };
          }

          return data;
        })
        .map(data => createDemographicMetricFromSelectedData(data))
        .filter((item): item is DemographicMetricModel => !!item);

      const updatedBoundaryTerritoryGroup: BoundaryTerritoryGroup = {
        ...boundaryTerritoryGroup,
        settings: {
          ...boundaryTerritoryGroup.settings,
          metrics: [...newSpreadsheetMetrics, ...newDemographicMetrics],
        },
      };

      dispatch(boundaryTerritoryGroupsUpdateRequest(updatedBoundaryTerritoryGroup));
    },
  }), [boundaryTerritoryGroup, boundaryGroup, dispatch]);

  useEffect(() => {
    dispatch(demographicsListGlobalFetchDataRequest());
  }, [dispatch]);

  if (!boundaryTerritoryGroup) {
    return null;
  }

  return (
    <CustomizeMetricsComponent
      isLoading={isBoundaryTerritoryGroupsLoading || isDemographicsLoading}
      selectedData={selectedData}
      onSelectedDataChange={dispatchProps.setBoundaryTerritoryGroupMetrics}
      title={props.isBoundaryGroupCustom ? t('Customize Territory Metrics') : t('Customize Boundary Metrics')}
      addSelectionButtonText={props.isBoundaryGroupCustom ? t('Add to Territory Details') : t('Add to Boundary Details')}
      selectedDataCaption={props.isBoundaryGroupCustom ? t('Data added to territory details') : t('Data added to boundary details')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      columnDataSources={matchupColumns}
      demographicsRegions={demographicRegions}
    />
  );
};
