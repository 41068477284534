import { css } from '@emotion/react';
import {
  type FC, useCallback, useContext,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import { cellContainerStyle } from '~/_shared/components/spreadsheet/cell/spreadsheetCell.component';
import type { GridElement } from '~/_shared/components/spreadsheet/spreadsheet.types';
import { SpreadsheetContext } from '~/_shared/components/spreadsheet/spreadsheetContext';
import type { Theme } from '~/_shared/themes/theme.model';
import { CheckboxComponent } from '../../../baseComponents/checkbox';
import { spreadsheetCellsWrapperStyle } from '../header/spreadsheetHeader.component';
import RowRendererProps = ReactDataSheet.RowRendererProps;

const rowContainerStyle = css({
  display: 'flex',
  width: '100%',
});

const checkboxWrapperStyle = css({
  width: 30,
  display: 'flex',
  justifyContent: 'center',
});

const cellStyle = (theme: Theme) => css(
  checkboxWrapperStyle,
  cellContainerStyle({})(theme),
);

const highlightStyle = (theme: Theme) => spreadsheetCellsWrapperStyle({ theme, isHoverable: true });

type SpreadsheetRowProps = RowRendererProps<GridElement>;

export const SpreadsheetRowComponent: FC<SpreadsheetRowProps> = ({ row, children }) => {
  const {
    selectableRows,
    data,
    selectedRows,
    onHoveredRowChange,
    onRowClick,
    onSelectedRowsChange,
  } = useContext(SpreadsheetContext);
  const rowData = data[row];

  const handleRowClick = useCallback(() => {
    if (rowData) {
      onRowClick?.(rowData);
    }
  }, [onRowClick, rowData]);

  const handleMouseEnter = useCallback(() => {
    onHoveredRowChange?.(rowData ?? null);
  }, [onHoveredRowChange, rowData]);

  const handleMouseLeave = useCallback(() => {
    onHoveredRowChange?.(null);
  }, [onHoveredRowChange]);
  const handleSelectChanged = useCallback(() => {

    if (rowData) {
      const { rowId } = rowData;
      const newSelectedRows = {
        ...selectedRows,
      };

      if (newSelectedRows[rowId]) {
        delete newSelectedRows[rowId];
      }
      else {
        newSelectedRows[rowId] = true;
      }

      onSelectedRowsChange(newSelectedRows);
    }
  }, [onSelectedRowsChange, rowData, selectedRows]);

  if (rowData === undefined) {
    return null;
  }

  const isSelected = selectedRows[rowData.rowId] ?? false;

  return (
    <div
      css={rowContainerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleRowClick}
    >
      {selectableRows && (
        <div css={cellStyle}>
          <CheckboxComponent
            isChecked={isSelected}
            checkedSetter={handleSelectChanged}
          />
        </div>
      )}

      <div css={highlightStyle}>
        {children}
      </div>
    </div>
  );
};
