import { css } from '@emotion/react';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

export const SELECTABLE_METRIC_LINE_SIZE = 36;

const columnItemStyle = ({ theme }: ThemeProps) => css({
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  height: SELECTABLE_METRIC_LINE_SIZE,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.textColors.primary,
  backgroundColor: theme.backgroundColors.primary,

  '& + &': {
    borderTop: `1px solid ${theme.borderColors.primary}`,
  },
});

const labelStyle = ({ theme, disabled }: ThemeProps<{ disabled: boolean }>) => css({
  opacity: disabled ? theme.opacity.disabled : undefined,
});

export type SelectableMetricValue = {
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  numericPercent?: number;
};

type SelectableMetricsListComponentProps = {
  metrics: SelectableMetricValue[];
  onChange: (id: string, checked: boolean) => void;
};

export const SelectableMetricsListComponent = ({ metrics, onChange }: SelectableMetricsListComponentProps) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <>
      {metrics.map(metric => (
        <div
          css={columnItemStyle({ theme })}
          key={metric.id}
        >
          <span css={labelStyle({ theme, disabled: !!metric.disabled })}>{metric.label}</span>
          {!metric.disabled && (
            <CheckboxComponent
              isDisabled={metric.disabled}
              isChecked={metric.checked}
              checkedSetter={() => onChange(metric.id, !metric.checked)}
            />
          )}
          {metric.disabled && (
            <div css={[labelStyle({ theme, disabled: !!metric.disabled }), { display: 'flex', gap: 10 }]}>
              <span css={{ textTransform: 'uppercase' }} >{t('Text Column')}</span>
              <TooltipDeprComponent
                placement={TooltipPlacement.TopStart}
                tooltipContent={t(
                  'More than {{percentage}}% of values in this column are considered non numerical.',
                  { percentage: Math.min(99, Math.floor(100 - (metric.numericPercent ?? 0))) })
                }
              >
                <FontAwesomeIcon icon={faCircleInfo} />
              </TooltipDeprComponent>
            </div>
          )}
        </div>
      ))
      }
    </>
  );
};
