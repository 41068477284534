import { css } from '@emotion/react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import {
  getIconForMapToolOrFeature, getTranslationForMapToolOrFeature,
} from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { SettingsAccordionDropDownOptionComponent } from '../settingsAccordionDropdownOption.component';

const iconStyle = css({
  fontSize: 18,
  marginLeft: 16,
});

type SharedMapToolDropDownOptionProps = Readonly<{
  isLast: boolean;
  mapTool: MapTool;

  onChange: () => void;
}>;

export const SharedMapToolDropDownOptionComponent: React.FC<SharedMapToolDropDownOptionProps> = props => {

  return (
    <SettingsAccordionDropDownOptionComponent
      isLast={props.isLast}
      title={getTranslationForMapToolOrFeature(props.mapTool)}
      onChange={props.onChange}
    >
      <FontAwesomeIcon
        css={iconStyle}
        icon={getIconForMapToolOrFeature(props.mapTool)}
      />
    </SettingsAccordionDropDownOptionComponent>
  );
};
