import { Page } from '~/_shared/constants/routes';
import { type MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { dataPageMapTools } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { type MapToolsPerPage } from '~/store/frontendState/leftSidebar/leftSidebar.state';

export const replaceLastOpenedToolForPage = (
  lastOpenedMapTool: MapToolsPerPage,
  mapTool: MapTool
) => {
  const page = dataPageMapTools.has(mapTool) ? Page.ADMIN_DATA : Page.ADMIN_MAP;

  return { ...lastOpenedMapTool, [page]: mapTool };
};
