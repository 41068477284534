import {
  useCallback,
  useState,
} from 'react';
import { isCustomGroup } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { sortAlphabetically } from '../../../../../_shared/utils/sort/sort.helpers';
import { type BoundaryGroupDetails } from '../../../../../boundary/boundary.types';
import { createBoundaryGroupDetailsFromResponse } from '../../../../../store/boundaryGroups/boundaryGroups.factory';
import { getBoundaryGroupsList } from '../../../../../store/boundaryGroups/boundaryGroups.repository';

export const useBoundaryGroupListingData = () => {
  const [customBoundaryGroups, setCustomBoundaryGroups] = useState<BoundaryGroupDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBoundaryGroups = useCallback((clientId: number) => {
    setIsLoading(true);
    getBoundaryGroupsList(clientId)
      .then(response => {
        setCustomBoundaryGroups(
          response.boundary_groups
            .map(group => createBoundaryGroupDetailsFromResponse(group))
            .filter(group => isCustomGroup(group) && !group.wms.wmsTemporary && group.visible)
            .sort((a, b) => sortAlphabetically(a.name, b.name))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    customBoundaryGroups,
    getBoundaryGroups,
    isLoading,
  };
};
