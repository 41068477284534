import { FetchableResourceStatus } from '~/_shared/types/fetchableResource/fetchableResource';
import { isResourceLoaded } from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import { type MapAction } from '~/store/map/map.action';
import { MAP_RESET } from '~/store/map/map.actionTypes';
import { type SpreadsheetDataAction } from '~/store/spreadsheetData/spreadsheetData.action';
import { SPREADSHEET_RESET_STATE_AND_REFETCH_DATA } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { type SpreadsheetCellDataAction } from './spreadsheetCellData.action';
import { SPREADSHEET_CELL_DATA_UPDATE } from './spreadsheetCellData.actionTypes';
import {
  type PerRowFetchableSpreadsheetRows,
  type SpreadsheetCellData, type SpreadsheetCellDataState,
} from './spreadsheetCellData.state';

const initialState: SpreadsheetCellDataState = newPerSpreadsheetMap();

export const spreadsheetCellDataReducer = (
  state = initialState, action: SpreadsheetCellDataAction | SpreadsheetDataAction | MapAction
): SpreadsheetCellDataState => {
  switch (action.type) {
    case SPREADSHEET_CELL_DATA_UPDATE: {
      const newSpreadsheetCellData = action.payload.newSpreadsheetCellData;

      // update the existing state by looping through the new data
      return Object.entries(newSpreadsheetCellData).reduce<SpreadsheetCellData>((perSpreadsheetAcc, [spreadsheetId, spreadsheetDataPerRowMap]) => {
        if (spreadsheetDataPerRowMap) {

          // update the existing rows for given spreadsheet by comparing them to the new rows
          const existingPerRowStateForSpreadsheet: PerRowFetchableSpreadsheetRows = state[+spreadsheetId] || new Map();
          const perRowData = Array.from(spreadsheetDataPerRowMap.entries()).reduce<PerRowFetchableSpreadsheetRows>((perRowAcc, [rowId, fetchData]) => {
            const existing = existingPerRowStateForSpreadsheet.get(rowId);

            if (existing) {
              // if current row is still loading, only update status on existing state
              if (!isResourceLoaded(fetchData)) {
                perRowAcc.set(rowId, { ...existing, status: fetchData.status });
                return perRowAcc;
              }

              // if new row is loaded, only update fetched cells
              const existingColumnsDataStateForRow = existingPerRowStateForSpreadsheet.get(rowId)?.value?.columnsData;
              if (existingColumnsDataStateForRow) {
                const perColumnDataMergedNewWithState = { ...existingColumnsDataStateForRow };
                Object.entries(fetchData.value.columnsData).forEach(([columnId, cellData]) => {
                  perColumnDataMergedNewWithState[columnId] = cellData;
                });

                perRowAcc.set(rowId, {
                  status: FetchableResourceStatus.Loaded,
                  value: {
                    ...fetchData.value,
                    columnsData: perColumnDataMergedNewWithState,
                  },
                });

                return perRowAcc;
              }
            }

            // default: add new row value
            perRowAcc.set(rowId, fetchData);
            return perRowAcc;

            // as initial value we use the copy of the current per-row value the current state
          }, new Map(existingPerRowStateForSpreadsheet));

          perSpreadsheetAcc[+spreadsheetId] = perRowData;
        }

        return perSpreadsheetAcc;
        // as initial value we use the copy of the current per-spreadsheet state
      }, { ...state });
    }
    case MAP_RESET:
    case SPREADSHEET_RESET_STATE_AND_REFETCH_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};
