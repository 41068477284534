import { useMemo } from 'react';
import { MapAreaColors } from '~/_shared/constants/mapObjects/mapAreaColors.constants';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryTerritoryId, type BoundaryTerritoryIdentifier,
} from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryTerritoryAssignment, useBoundaryTerritoryAssignments,
} from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useActiveBoundaryTerritorySelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useHighlightedBoundaryTerritorySelector } from '~/store/frontendState/mapTools/boundary/boundaryHighlight/boundaryHighlight.selectors';
import { useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { type BoundaryItemStyle } from '../../mapBoundary.manager';

export const highlightedBoundaryTerritoryStyle = {
  fillColor: MapAreaColors.highlightCombinedAreas.fillColor,
  borderColor: { value: MapAreaColors.highlight.borderColor },
  fillOpacity: { value: 0.4 },
  borderOpacity: { value: 0 },
  showPolygonOutline: false,
} as const;

export const activeBoundaryTerritoryStyle = {
  fillColor: MapAreaColors.active.fillColor,
  borderColor: { value: MapAreaColors.active.borderColor },
  fillOpacity: { value: 1 },
  borderOpacity: { value: 0 },
  showPolygonOutline: false,
} as const;

export const useMapBoundaryTerritoryStyles = (baseStyles: ReadonlyMap<BoundaryGroupId, BoundaryItemStyle>) => {
  const primaryBoundaryGroupId = useMapSettingsBoundariesPrimaryBoundaryGroupIdSelector();
  const highlightedBoundaryTerritory = useHighlightedBoundaryTerritorySelector();
  const activeBoundaryTerritory = useActiveBoundaryTerritorySelector();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();

  return useMemo<ReadonlyMap<BoundaryGroupId, ReadonlyMap<BoundaryId, BoundaryItemStyle>>>(() => {
    const results = new Map<BoundaryGroupId, Map<BoundaryId, BoundaryItemStyle>>();

    for (const boundaryTerritoryGroup of boundaryTerritoryGroups) {
      const isPrimary = boundaryTerritoryGroup.boundaryGroupId === primaryBoundaryGroupId;
      const assignments = boundaryTerritoryAssignments.get(boundaryTerritoryGroup.boundaryTerritoryGroupId);
      const baseStyle = baseStyles.get(boundaryTerritoryGroup.boundaryGroupId);

      if (!baseStyle || !assignments) {
        continue;
      }

      const activeBoundaryTerritoryId = getBoundaryTerritoryId(activeBoundaryTerritory, boundaryTerritoryGroup);
      const highlightedBoundaryTerritoryId = getBoundaryTerritoryId(highlightedBoundaryTerritory, boundaryTerritoryGroup);

      // Memoization ensures styles are the same object unless inputs have changed
      const boundaryStyleMap = getBoundaryTerritoryStylesMemoized(
        assignments, boundaryTerritoryGroup.settings.boundaryTerritories, baseStyle, isPrimary, activeBoundaryTerritoryId, highlightedBoundaryTerritoryId);

      if (boundaryStyleMap.size) {
        results.set(boundaryTerritoryGroup.boundaryGroupId, boundaryStyleMap);
      }
    }

    return results;
  }, [boundaryTerritoryGroups, primaryBoundaryGroupId, boundaryTerritoryAssignments, baseStyles, highlightedBoundaryTerritory, activeBoundaryTerritory]);
};

const getBoundaryTerritoryStyles = (
  assignments: BoundaryTerritoryAssignment,
  boundaryTerritories: readonly BoundaryTerritory[],
  baseStyle: BoundaryItemStyle,
  isPrimary: boolean,
  activeBoundaryTerritoryId?: BoundaryTerritoryId,
  highlightedBoundaryTerritoryId?: BoundaryTerritoryId,
) => {
  const boundaryStyleMap: Map<BoundaryId, BoundaryItemStyle> = new Map();

  for (const boundaryTerritory of boundaryTerritories) {
    const activeStyle = getActiveStyle(boundaryTerritory.boundaryTerritoryId, activeBoundaryTerritoryId, highlightedBoundaryTerritoryId);
    const boundaryIds = assignments?.territoryAssignments.get(boundaryTerritory.boundaryTerritoryId);

    const fillOpacity = isPrimary ? boundaryTerritory.style.opacity / 100 : boundaryTerritory.style.opacity / 200;

    for (const boundaryId of boundaryIds ?? []) {
      const style = {
        ...baseStyle,
        fillColor: activeStyle?.fillColor ?? boundaryTerritory.style.color,
        fillOpacity: activeStyle?.fillOpacity ?? { value: fillOpacity },
        borderColor: activeStyle?.borderColor ?? baseStyle.borderColor,
        borderWidth: activeStyle ? 0 : baseStyle.borderWidth,
        borderOpacity: activeStyle?.borderOpacity ?? baseStyle.borderOpacity,
      };
      boundaryStyleMap.set(boundaryId, style);
    }
  }

  return boundaryStyleMap;
};
const getBoundaryTerritoryStylesMemoized = memoizeWeak(getBoundaryTerritoryStyles);

const getBoundaryTerritoryId = (boundaryTerritory: BoundaryTerritoryIdentifier | null, boundaryTerritoryGroup: BoundaryTerritoryGroup) =>
  boundaryTerritory?.boundaryTerritoryGroupId === boundaryTerritoryGroup.boundaryTerritoryGroupId
    ? boundaryTerritory.boundaryTerritoryId
    : undefined;

const getActiveStyle = (
  boundaryTerritoryId: string,
  activeBoundaryTerritoryId?: string,
  highlightedBoundaryTerritoryId?: string
): Partial<BoundaryItemStyle> | undefined => {
  // active takes precedence over highlighted
  if (boundaryTerritoryId === activeBoundaryTerritoryId) {
    return activeBoundaryTerritoryStyle;
  }

  if (boundaryTerritoryId === highlightedBoundaryTerritoryId) {
    return highlightedBoundaryTerritoryStyle;
  }

  return undefined;
};
