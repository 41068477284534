import { css } from '@emotion/react';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButtonWithText.component';
import { ExportImageLegendMode } from '~/_shared/types/exportImage/exportImage';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsBoundaryLocationsExportAllowed } from '~/boundary/useIsBoundaryExportLocationsAllowed';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  useBoundaryGroupsIsLoadingSelector,
  useBoundaryGroupsSelector,
  useCustomBoundaryGroupsSelector,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryDetailsIsLoadingSelector } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useBoundaryConvertInProgressSelector } from '~/store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.selectors';
import { useMapSettingsExportImageSettingsSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useIsWMSEnabledSelector } from '~/store/userData/userData.selectors';
import { useIsNumericColumnCheck } from '../../../../heatMap/panel/form/useIsNumericColumnCheck.hook';
import { BoundaryToolComponent } from './boundaryTool.component';
import { type BoundaryAction } from './boundaryTools.types';
import { BoundaryActionType } from './loadBoundaryPane/loadBoundaryPane.component';

const exportBtnStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  height: '100%',
});

export const BoundaryToolContainer: FC = () => {
  const isBoundaryConvertInProgress = useBoundaryConvertInProgressSelector();
  const isBoundaryExportLocationsAllowed = useIsBoundaryLocationsExportAllowed();
  const isBoundaryGroupsLoading = useBoundaryGroupsIsLoadingSelector();
  const isBoundaryTerritoryDetailsLoading = useBoundaryTerritoryDetailsIsLoadingSelector();
  const isBoundaryTerritoryGroupsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const { openModal: openBoundaryGroupsListingModal } = useModal(ModalType.BoundaryGroupsListing);
  const { openModal: openBoundaryTerritoryGroupExportModal } = useModal(ModalType.ExportBoundaryTerritoryGroupData);
  const { openModal: openUpgradeWMSSubscriptionModal } = useModal(ModalType.WMSUpgradeSubscription);
  const { checkIsNumeric, isLoading: isCheckNumericLoading } = useIsNumericColumnCheck();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const [t] = useTranslation();
  const isWMSEnabled = useIsWMSEnabledSelector();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const legendMode = useMapSettingsExportImageSettingsSelector().legendMode === ExportImageLegendMode.Boundary;

  const boundaryActions: BoundaryAction[] = useMemo(() => {
    let actions = [{
      name: t('Load Geographic Boundary'),
      value: BoundaryActionType.LoadGeographicBoundary,
    }];

    if (!isMapPresentational) {
      actions = actions.concat([{
        name: t('Load Saved Territory Group'),
        value: BoundaryActionType.LoadSavedTerritoryGroup,
      }, {
        name: t('Create Territories'),
        value: BoundaryActionType.CreateTerritories,
      }]);
    }

    return actions;
  }, [isMapPresentational, t]);

  const handleManageBoundaryGroupsClick = !isMapPresentational ? () => {
    openBoundaryGroupsListingModal();
  } : undefined;

  const mapHasOnlyAIGeneratedBTG = boundaryTerritoryGroups.length > 0 && boundaryTerritoryGroups.every((btg) => {
    const matchingBoundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === btg.boundaryGroupId);
    return !!matchingBoundaryGroup?.wms.territoriesCount;
  });

  const handleExportFile = useCallback(() => {
    if (!isWMSEnabled && mapHasOnlyAIGeneratedBTG) {
      openUpgradeWMSSubscriptionModal();
    }
    else {
      openBoundaryTerritoryGroupExportModal({
        selectedBoundaryTerritoryGroupIds: [],
        showSelectBoundaryTerritoryGroupsSection: true,
      });
    }
  }, [openBoundaryTerritoryGroupExportModal, isWMSEnabled, mapHasOnlyAIGeneratedBTG, openUpgradeWMSSubscriptionModal]);

  const isLoading = isBoundaryGroupsLoading
  || isBoundaryTerritoryGroupsLoading
  || isBoundaryTerritoryDetailsLoading
  || isCheckNumericLoading
  || isBoundaryConvertInProgress;

  const exportBtn = isBoundaryExportLocationsAllowed ? (
    <div css={exportBtnStyle}>
      <RoundButtonWithTextComponent
        buttonStyle={!isWMSEnabled && mapHasOnlyAIGeneratedBTG ? RoundButtonStyle.RestrictedInverted : RoundButtonStyle.Quaternary}
        icon={faFileExport}
        onClick={handleExportFile}
        text={t('Export File')}
      />
    </div>
  ) : undefined;

  return (
    <BoundaryToolComponent
      legendMode={legendMode}
      actions={boundaryActions}
      checkIsNumeric={checkIsNumeric}
      isLoading={isLoading}
      exportBtn={exportBtn}
      onManageBoundaryGroupsClick={handleManageBoundaryGroupsClick}
      showAdminControls={!isMapPresentational && customBoundaryGroups.length > 0}
      showBoundaryLoadOptions={!isMapPresentational}
    />
  );
};
