import {
  useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useGetRightSidebarContent } from '~/sidebar/sidebarApps/rightSidebar/helpers/useGetRightSidebarContent';
import { activeMapElementsClearState } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import {
  clearLastOpenedMapPageTool, closeLeftSidebar as closeLeftSidebarActionCreator,
  closeMapTool as closeMapToolActionCreator,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import {
  useFrontendStateLeftSidebarOpenedAppSelector, useFrontendStateLeftSidebarOpenedMapToolSelector,
} from '~/store/frontendState/leftSidebar/leftSidebar.selectors';
import { isPageWithMapTools } from '~/store/frontendState/leftSidebar/leftSidebar.state';
import {
  boundaryDrawCloseModal, boundaryDrawDeactivate,
  boundaryDrawResetShapes,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import {
  useBoundaryDrawDragActiveSelector,
  useBoundaryDrawEditActiveSelector,
  useBoundaryDrawEditModalOpenSelector, useBoundaryDrawPolygonEditActiveSelector,
  useBoundaryDrawPolygonEditModalOpenSelector,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import {
  boundarySelectEditCloseModal, boundarySelectEditDeactivate,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import {
  useBoundarySelectActiveSelector, useBoundarySelectModalOpenSelector,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { drawingToolClearActiveTool } from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { useIsDrawingToolModeStoppedOrPausedSelector } from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { mutuallyExclusiveMapTools } from '~/store/frontendState/mapTools/mutuallyExclusiveMapTools/mutuallyExclusiveMapTools';
import { frontendStateSearchHide } from '~/store/frontendState/mapTools/search/search.actionCreators';
import { useSearchActiveSelector } from '~/store/frontendState/mapTools/search/search.selectors';
import { hideMarkerContextMenu } from '~/store/frontendState/markersContextMenu/markersContextMenu.actionCreators';
import { useMarkersContextMenuActiveSelector } from '~/store/frontendState/markersContextMenu/markersContextMenu.selectors';
import { moveMarkerLabelsCloseModal } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionCreators';
import { useMoveMarkerLabelsIsModalVisible } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.selectors';
import {
  deactivateMoveMarkers as reduxDeactivateMoveMarkers, moveMarkersCloseModal,
} from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import {
  useMoveMarkersIsActiveSelector, useMoveMarkersIsModalVisible,
} from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useSplitPanelState } from '~/store/userData/settings/splitPanel/useSplitPanelState';
import { useGetCurrentPageInfo } from '~/topBar/navigation/navigation.helpers';
import { useIsMapInteractionActive } from './useIsMapInteractionActive';
import { KeyboardKeys } from './useKeyPress';
import {
  type MapKeyPressCallback, MapKeyPressPriority,
  useMapKeyPressContext,
} from './useMapKeyPressContext';

export const useKeyCloseMapElement = () => {
  const dispatch = useDispatch();
  const mapId = useMapIdSelector();
  const searchActive = useSearchActiveSelector();
  const rightSidebarContentOpen = !!useGetRightSidebarContent();
  const leftSidebarOpen = !!useFrontendStateLeftSidebarOpenedAppSelector();
  const isMapInteractionActive = useIsMapInteractionActive();
  const currentPage = useGetCurrentPageInfo().page;
  const mapTool = useFrontendStateLeftSidebarOpenedMapToolSelector();
  const isMoveMarkersActive = useMoveMarkersIsActiveSelector();
  const isMoveMarkersVisible = useMoveMarkersIsModalVisible();
  const isMoveMarkerLabelsVisible = useMoveMarkerLabelsIsModalVisible();
  const isBoundaryDrawActive = !!useBoundaryDrawEditActiveSelector();
  const isBoundaryDrawPolygonActive = !!useBoundaryDrawPolygonEditActiveSelector();
  const isBoundaryDrawDragActive = useBoundaryDrawDragActiveSelector();
  const isBoundaryDrawVisible = !!useBoundaryDrawEditModalOpenSelector();
  const isBoundaryDrawPolygonVisible = !!useBoundaryDrawPolygonEditModalOpenSelector();
  const isBoundarySelectActive = !!useBoundarySelectActiveSelector();
  const isBoundarySelectVisible = !!useBoundarySelectModalOpenSelector();
  const isMarkerContextMenuActive = !!useMarkersContextMenuActiveSelector();
  const isDrawingToolStoppedOrPaused = useIsDrawingToolModeStoppedOrPausedSelector();
  const { isActive: isSplitPanelActive, closePanel: closeSplitPanel } = useSplitPanelState(mapId);

  const isBoundaryDrawModeActive = isBoundaryDrawActive || isBoundaryDrawPolygonActive;

  const closeMarkerContextMenu = useCallback(() => dispatch(hideMarkerContextMenu()), [dispatch]);
  const hideSearch = useCallback(() => dispatch(frontendStateSearchHide()), [dispatch]);
  const closeRightSidebar = useCallback(() => dispatch(activeMapElementsClearState()), [dispatch]);
  const deactivateMoveMarkers = useCallback(() => dispatch(reduxDeactivateMoveMarkers()), [dispatch]);
  const closeMoveMarkers = useCallback(() => dispatch(moveMarkersCloseModal()), [dispatch]);
  const closeMoveMarkerLabels = useCallback(() => dispatch(moveMarkerLabelsCloseModal()), [dispatch]);
  const resetBoundaryDraw = useCallback(() => dispatch(boundaryDrawResetShapes()), [dispatch]);
  const deactivateBoundaryDraw = useCallback(() => dispatch(boundaryDrawDeactivate()), [dispatch]);
  const closeBoundaryDraw = useCallback(() => dispatch(boundaryDrawCloseModal()), [dispatch]);
  const deactivateBoundarySelect = useCallback(() => dispatch(boundarySelectEditDeactivate()), [dispatch]);
  const closeBoundarySelect = useCallback(() => dispatch(boundarySelectEditCloseModal()), [dispatch]);
  const clearActiveDrawingTool = useCallback(() => dispatch(drawingToolClearActiveTool()), [dispatch]);

  const closeMapInteractionTools = useCallback(() => {
    mutuallyExclusiveMapTools.forEach((feature) => {
      dispatch(feature.off());
    });
  }, [dispatch]);

  const closeLeftSidebar = useCallback(() => {
    if (mapTool && isPageWithMapTools(currentPage)) {
      dispatch(closeMapToolActionCreator());
      dispatch(clearLastOpenedMapPageTool(currentPage));
    }
    dispatch(closeLeftSidebarActionCreator());
  }, [currentPage, dispatch, mapTool]);

  const onEscPress = useCallback<MapKeyPressCallback>(e => {
    let keyHandled = true;

    switch (true) {
      case isMarkerContextMenuActive:
        closeMarkerContextMenu();
        break;
      case searchActive:
        hideSearch();
        break;
      case isMoveMarkersActive:
        deactivateMoveMarkers();
        break;
      case isBoundaryDrawDragActive:
        resetBoundaryDraw();
        break;
      case isBoundaryDrawModeActive:
        deactivateBoundaryDraw();
        break;
      case isBoundarySelectActive:
        deactivateBoundarySelect();
        break;
      case isDrawingToolStoppedOrPaused:
        clearActiveDrawingTool();
        break;
      case isMapInteractionActive:
        closeMapInteractionTools();
        break;
      case isSplitPanelActive:
        closeSplitPanel();
        break;
      case isMoveMarkersVisible:
        closeMoveMarkers();
        break;
      case isMoveMarkerLabelsVisible:
        closeMoveMarkerLabels();
        break;
      case isBoundaryDrawVisible:
        closeBoundaryDraw();
        break;
      case isBoundaryDrawPolygonVisible:
        closeBoundaryDraw();
        break;
      case isBoundarySelectVisible:
        closeBoundarySelect();
        break;
      case rightSidebarContentOpen:
        closeRightSidebar();
        break;
      case leftSidebarOpen:
        closeLeftSidebar();
        break;
      default:
        keyHandled = false;
        break;
    }

    if (keyHandled) {
      e.stopPropagation();
    }
  }, [isMarkerContextMenuActive, closeMarkerContextMenu, searchActive, hideSearch, isMoveMarkersActive, deactivateMoveMarkers,
    isBoundaryDrawDragActive, resetBoundaryDraw, isBoundaryDrawModeActive, deactivateBoundaryDraw, isBoundarySelectActive,
    deactivateBoundarySelect, isDrawingToolStoppedOrPaused, clearActiveDrawingTool, isMapInteractionActive, closeMapInteractionTools,
    isSplitPanelActive, closeSplitPanel, isMoveMarkersVisible, closeMoveMarkers, isMoveMarkerLabelsVisible, closeMoveMarkerLabels,
    isBoundaryDrawVisible, closeBoundaryDraw, isBoundaryDrawPolygonVisible, isBoundarySelectVisible, closeBoundarySelect,
    rightSidebarContentOpen, closeRightSidebar, leftSidebarOpen, closeLeftSidebar,
  ]);

  const { addMapKeyHandler } = useMapKeyPressContext();

  useEffect(() => {
    const removeKeyHandler = addMapKeyHandler(KeyboardKeys.Escape, MapKeyPressPriority.CloseOpenElements, onEscPress);
    return removeKeyHandler;
  }, [addMapKeyHandler, onEscPress]);
};
