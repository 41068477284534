import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  boundaryFormReset, boundaryFormSetType,
} from '~/store/frontendState/mapTools/boundary/boundaryForm/boundaryForm.actionCreators';
import { useBoundaryFormType } from '~/store/frontendState/mapTools/boundary/boundaryForm/boundaryForm.selectors';
import { type BoundaryAction } from '../boundaryTools.types';
import { LoadBoundaryPaneComponent } from './loadBoundaryPane.component';

type LoadBoundaryPaneContainerProps = Readonly<{
  actions: ReadonlyArray<BoundaryAction>;
  checkIsNumeric: (columnId: string | null, spreadsheetId: number | null) => Promise<boolean>;
}>;

export const LoadBoundaryPaneContainer: FC<LoadBoundaryPaneContainerProps> = props => {
  const dispatch = useDispatch();

  const action = useBoundaryFormType();
  const reset = useCallback(() => dispatch(boundaryFormReset()), [dispatch]);

  return (
    <LoadBoundaryPaneComponent
      action={action}
      setAction={(action) => dispatch(boundaryFormSetType(action))}
      reset={reset}
      {...props}
    />
  );
};
