import { useMemo } from 'react';
import {
  useActiveBoundarySelector,
  useActiveBoundaryTerritorySelector, useActiveMarkerIdSelector,
  useActiveProximitySelector,
  useIsLocationListOpen,
} from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useDrawingEditSelectedDrawingLatestSnapshot } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { useIsLocationFinderSelectorActive } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.selectors';
import {
  type RightSidebarConfiguration, RightSidebarView,
} from '../rightSidebarConfiguration';

export const useGetRightSidebarContent = (): RightSidebarConfiguration | null => {
  const isLocationKeyActive = useIsLocationListOpen();
  const isLocationFinderActive = useIsLocationFinderSelectorActive();
  const activeMarkerId = useActiveMarkerIdSelector();
  const activeBoundary = useActiveBoundarySelector();
  const activeBoundaryTerritory = useActiveBoundaryTerritorySelector();
  const activeProximity = useActiveProximitySelector();
  const latestDrawingSnapshot = useDrawingEditSelectedDrawingLatestSnapshot();

  const isLocationKeyOrFinderActive = isLocationKeyActive || isLocationFinderActive;

  return useMemo<RightSidebarConfiguration | null>(() => {
    if (latestDrawingSnapshot !== null) {
      return { view: RightSidebarView.DrawingToolDetails };
    }
    if (activeMarkerId) {
      return {
        view: RightSidebarView.CustomizeLocation,
        markerId: activeMarkerId,
        isLocationKeyOrFinderActive,
      };
    }
    if (activeProximity) {
      return {
        view: RightSidebarView.ProximityDetails,
        activeMetrics: activeProximity,
      };
    }
    if (activeBoundary) {
      return {
        view: RightSidebarView.BoundaryDetails,
        boundaryId: activeBoundary.boundaryId,
        boundaryGroupId: activeBoundary.boundaryGroupId,
        boundaryTerritoryGroupId: activeBoundary.boundaryTerritoryGroupId,
      };
    }
    if (activeBoundaryTerritory) {
      return {
        view: RightSidebarView.BoundaryTerritoryDetails,
        boundaryTerritoryId: activeBoundaryTerritory.boundaryTerritoryId,
        boundaryTerritoryGroupId: activeBoundaryTerritory.boundaryTerritoryGroupId,
      };
    }
    if (isLocationKeyOrFinderActive) {
      return { view: RightSidebarView.LocationListing };
    }

    return null;
  }, [activeMarkerId, isLocationKeyOrFinderActive, latestDrawingSnapshot, activeProximity, activeBoundary, activeBoundaryTerritory]);
};
