import {
  type FC, memo, useCallback, useMemo,
} from 'react';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { type LatLng } from '../../../../_shared/types/latLng';
import { type DrawingItemRectangle } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import { MapShapeLine } from '../../mapObjects/mapShape/mapShapeLine.component';
import { MapShapePolygon } from '../../mapObjects/mapShape/mapShapePolygon.component';
import { getOutlinesForRectangleStartEndPoints } from './drawingToolRectangle.helpers';

type MapRectangleInstanceProps = {
  instance: DrawingItemRectangle;
  zIndex: MapObjectZIndex;
  renderOnlyLine: boolean;
  onRectangleClick?: (event: MapObjectClickEventArgs) => void;
  onRectangleRightClick?: (event: MapObjectClickEventArgs) => void;
  onRectangleMouseOut?: () => void;
  onRectangleMouseOver?: () => void;
  onRectangleDragStart?: (latLng: LatLng) => void;
  onRectangleDragEnd?: () => void;
  onRectangleDragMove?: (latLng: LatLng) => void;
};

const DrawingToolRectangleInstanceRectangleContainer: FC<MapRectangleInstanceProps> = ({
  instance, zIndex, onRectangleClick, onRectangleMouseOut,
  onRectangleMouseOver, onRectangleDragStart, onRectangleDragEnd,
  onRectangleDragMove, renderOnlyLine, onRectangleRightClick,
}) => {
  const outlines = useMemo<MapObjectOutlineInstance[]>(() => {
    return getOutlinesForRectangleStartEndPoints(instance.startPoint, instance.endPoint, instance.id);
  }, [instance.id, instance.startPoint, instance.endPoint]);

  const { settings } = instance;

  const renderPolygon = useCallback(() => {
    const fillColor = convertColorToWebGLColor(settings.fillColor, settings.fillOpacity / 100);
    const borderColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    return (
      <MapShapePolygon
        visuals={{
          fillColor,
          borderColor,
          borderWidth: settings.strokeWeight,
        }}
        onClick={onRectangleClick}
        onMouseOver={onRectangleMouseOver}
        onMouseOut={onRectangleMouseOut}
        onDragStart={onRectangleDragStart}
        onDragEnd={onRectangleDragEnd}
        onDragMove={onRectangleDragMove}
        onRightClick={onRectangleRightClick}
      />
    );
  }, [settings.fillColor, settings.fillOpacity, onRectangleClick, onRectangleMouseOver,
    onRectangleMouseOut, onRectangleDragStart, onRectangleDragEnd, onRectangleDragMove,
    settings.strokeWeight, settings.strokeOpacity, settings.strokeColor, onRectangleRightClick]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => {
    const borderColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    return (
      <MapShapeLine
        key={id}
        id={id}
        start={start}
        end={end}
        visuals={{
          width: settings.strokeWeight,
          style: 'solid',
          color: borderColor,
        }}
      />
    );
  }, [settings.strokeWeight, settings.strokeOpacity, settings.strokeColor]);

  const mapShape = useMemo(() => ({
    id: instance.id,
    outlines,
  }), [instance.id, outlines]);

  return (
    <MapShape
      shape={mapShape}
      zIndex={zIndex}
      isPolygon
      renderPolygon={renderOnlyLine ? undefined : renderPolygon}
      renderLine={renderOnlyLine ? renderLine : undefined}
    />
  );
};

const pureComponent = memo(DrawingToolRectangleInstanceRectangleContainer);
export { pureComponent as DrawingToolRectangleInstanceRectangleContainer };
