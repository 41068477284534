export const BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL = 'BOUNDARY_DRAW_POLYGON_DRAG_EDIT_OPEN_MODAL';
export const BOUNDARY_DRAW_EDIT_OPEN_MODAL = 'BOUNDARY_DRAW_EDIT_OPEN_MODAL';
export const BOUNDARY_DRAW_CLOSE_MODAL = 'BOUNDARY_DRAW_CLOSE_MODAL';
export const BOUNDARY_DRAW_EDIT_ACTIVATE = 'BOUNDARY_DRAW_EDIT_ACTIVATE';

export const BOUNDARY_DRAW_DEACTIVATE = 'BOUNDARY_DRAW_DEACTIVATE';

export const BOUNDARY_DRAW_ADD_SHAPE = 'BOUNDARY_DRAW_ADD_SHAPE';
export const BOUNDARY_DRAW_REMOVE_SHAPE = 'BOUNDARY_DRAW_REMOVE_SHAPE';
export const BOUNDARY_DRAW_UPDATE_SHAPE = 'BOUNDARY_DRAW_UPDATE_SHAPE';

export const BOUNDARY_DRAW_RESET_SHAPES = 'BOUNDARY_DRAW_RESET_SHAPES';
export const BOUNDARY_DRAW_SET_DRAW_TYPE = 'BOUNDARY_DRAW_SET_DRAW_TYPE';

export const BOUNDARY_DRAW_OUTLINE_HOVER = 'BOUNDARY_DRAW_OUTLINE_HOVER';
export const BOUNDARY_DRAW_OUTLINE_MOUSEOUT = 'BOUNDARY_DRAW_OUTLINE_MOUSEOUT';
export const BOUNDARY_DRAW_REMOVE_BUTTON_HOVER = 'BOUNDARY_DRAW_REMOVE_BUTTON_HOVER';
export const BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT = 'BOUNDARY_DRAW_REMOVE_BUTTON_MOUSEOUT';
export const BOUNDARY_DRAW_DRAWING_START = 'BOUNDARY_DRAW_DRAWING_START';
export const BOUNDARY_DRAW_DRAWING_STOP = 'BOUNDARY_DRAW_DRAWING_STOP';
