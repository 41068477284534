import {
  useCallback,
  useState,
} from 'react';
import { sortAlphabetically } from '../../../../../_shared/utils/sort/sort.helpers';
import { getBoundaryGroupItemsList } from '../../../../../store/boundaries/boundaries.repository';
import { type BoundaryListItem } from '../boundaryListing/boundaryListing.container';

export const useBoundaryData = () => {
  const [boundaryItems, setBoundaryItems] = useState<ReadonlyArray<BoundaryListItem>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBoundaryItems = useCallback((clientId: number, boundaryGroupId: number) => {
    setIsLoading(true);
    getBoundaryGroupItemsList(clientId, boundaryGroupId)
      .then(response => {
        setBoundaryItems([...response].sort((a, b) => sortAlphabetically(a.name, b.name)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    getBoundaryItems,
    isLoading,
    boundaryItems,
  };
};
