import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMapPrivacyRestrictions } from '~/_shared/components/mapPrivacySelector/useMapPrivacyRestrictions.hook';
import {
  type ShareMapEmailResult,
  ShareMapEmailResults,
} from '~/map/map.repository';
import { validatePrivacyLevel } from '../../_shared/components/mapPrivacySelector/mapPrivacySelector.helpers';
import { Page } from '../../_shared/constants/routes';
import {
  MapPrivacyLevel, type MapSource,
} from '../../_shared/types/map';
import { copy } from '../../_shared/utils/collections/collections';
import {
  type ModalProps,
  ModalType,
} from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';
import { useGetCurrentPageInfo } from '../../topBar/navigation/navigation.helpers';
import { type ShareMethod } from './sections/shareSection.component';
import { StartingView } from './sections/startingMapViewSection.component';
import { useShareMapSendEmail } from './sendEmail/useShareMapSendEmail';
import {
  sectionToFieldMapping,
  type ShareInputField,
} from './shareInputField.enum';
import {
  ShareMapComponent,
  ShareMapSection,
} from './shareMap.component';
import {
  type FieldErrors,
  useShareMap,
} from './useShareMap';

const sectionsOrder: ReadonlyArray<ShareMapSection> = [
  ShareMapSection.PrivacyLevel,
  ShareMapSection.StartingMap,
  ShareMapSection.Share,
];

const moveToSection = (expandedSections: ReadonlySet<ShareMapSection>, targetSection: ShareMapSection): ReadonlySet<ShareMapSection> => {
  const index = sectionsOrder.indexOf(targetSection);
  if (index < 1) {
    return expandedSections;
  }

  const target = sectionsOrder[index];
  const beforeTarget = sectionsOrder[index - 1];
  if (target && beforeTarget) {
    return copy.andAdd(copy.andRemove(expandedSections, [beforeTarget]), [target]);
  }

  return expandedSections;
};

const hasErrorOnPath = (targetSection: ShareMapSection, fieldErrors: FieldErrors): boolean => {
  const index = sectionsOrder.indexOf(targetSection);
  if (index < 0) {
    return false;
  }

  return sectionsOrder.slice(0, index).some(section => getSectionErrors(section, fieldErrors).length > 0);
};
const getSectionErrors = (targetSection: ShareMapSection, fieldErrors: FieldErrors) => {
  const field = sectionToFieldMapping.get(targetSection);
  if (!field) {
    return [];
  }

  return fieldErrors.get(field) ?? [];
};

const getIsStartingViewSectionValid = (selected: StartingView | null, mapName: string) =>
  selected !== null && (selected !== StartingView.CurrentMapView || mapName.length > 0);

type SubmitShareMapRequestData = Readonly<{
  clientId: number | null;
  mapId: number | null;
  privacyLevel: MapPrivacyLevel | null;
  startingMap: StartingView | null;
  mapName: string;
  password: string | null | undefined;
}>;

export type ShareMapProps = {
  isSnapshot: boolean;
  mapId: number;
  mapName: string;
  mapSource: MapSource;
  parentMapPrivacy: MapPrivacyLevel | null; // parameter is required, so any dev is aware that they NEED to provide it
  onSuccess?: (newPrivacyLevel: MapPrivacyLevel) => void;
};

export type ShareMapContainerProps = ModalProps & ShareMapProps;

export const ShareMapContainer: FC<ShareMapContainerProps> = (props) => {
  const { onSuccess } = props;

  const clientId = useClientIdSelector();
  const isCalledFromMap = useGetCurrentPageInfo().page === Page.ADMIN_MAP;

  const [privacyLevel, setPrivacyLevel] = useState<MapPrivacyLevel | null>(null);
  const [password, setPassword] = useState('');
  const [startingMap, setStartingMap] = useState<StartingView | null>(null);
  const [shareMethod, setSharingMethod] = useState<ShareMethod | null>(null);
  const [expandedSections, setExpandedSections] = useState<ReadonlySet<ShareMapSection>>(new Set([ShareMapSection.PrivacyLevel]));
  const [mapName, setMapName] = useState('');
  const { shareId, isLoading, globalError, fieldErrors, shareMap, clearFieldError, clearGlobalError } = useShareMap();
  const [sendEmailResult, setSendEmailResult] = useState<ShareMapEmailResult | null>(null);

  const { isSendingEmail, sendShareMapEmail } = useShareMapSendEmail(props.mapId);
  const { openModal: openEmailNotificationModal } = useModal(ModalType.EmailNotification);
  const mapPrivacyRestrictions = useMapPrivacyRestrictions(
    privacyLevel || undefined,
    props.parentMapPrivacy || undefined,
    props.mapSource
  );

  const submitShareMap = useCallback((data: SubmitShareMapRequestData) => {
    if (!data.privacyLevel || !getIsStartingViewSectionValid(data.startingMap, data.mapName)
      || !validatePrivacyLevel(data.privacyLevel, data.password || '') || fieldErrors.size > 0
      || !mapPrivacyRestrictions.isValid
    ) {
      return;
    }

    const newPrivacyLevel = data.privacyLevel || MapPrivacyLevel.Public;

    shareMap({
      ...data,
      privacyLevel: newPrivacyLevel,
      password: data.password ?? null,
      useCurrentMapView: data.startingMap === StartingView.CurrentMapView,
    })
      .then(({ fieldErrors, globalError }) => {
        const sectionsToExpand = Array.from(sectionToFieldMapping.keys())
          .filter(section => fieldErrors.has(sectionToFieldMapping.get(section) as ShareInputField));
        setExpandedSections(prev => copy.andAdd(prev, sectionsToExpand));
        if (fieldErrors.size === 0 && globalError) {
          setExpandedSections(prev => copy.andAdd(prev, [ShareMapSection.PrivacyLevel]));
        }

        if (fieldErrors.size === 0 && !globalError) {
          if (onSuccess) {
            onSuccess(newPrivacyLevel);
          }
        }
      });
  }, [fieldErrors.size, mapPrivacyRestrictions.isValid, shareMap, onSuccess]);

  const onPrivacyLevelChange = useCallback((privacyLevel: MapPrivacyLevel, password?: string) => {
    setPrivacyLevel(privacyLevel);
    setPassword(password || '');

    if (validatePrivacyLevel(privacyLevel, password)) {
      setExpandedSections(moveToSection(expandedSections, ShareMapSection.StartingMap));
      submitShareMap({ clientId, mapId: props.mapId, privacyLevel, password, startingMap, mapName });
    }
  }, [clientId, expandedSections, mapName, props.mapId, startingMap, submitShareMap]);

  const onStartMapChange = useCallback((value: StartingView) => {
    setStartingMap(value);
    if (value === StartingView.CurrentMapView) {
      return;
    }

    setExpandedSections(moveToSection(expandedSections, ShareMapSection.Share));
    submitShareMap({ clientId, mapId: props.mapId, privacyLevel, password, startingMap: value, mapName });
  }, [clientId, expandedSections, mapName, password, privacyLevel, props.mapId, submitShareMap]);

  const onMapNameFormSubmit = useCallback((mapName: string) => {
    setMapName(mapName);
    setExpandedSections(moveToSection(expandedSections, ShareMapSection.Share));
    submitShareMap({ clientId, mapId: props.mapId, privacyLevel, password, startingMap, mapName });
  }, [clientId, expandedSections, password, privacyLevel, props.mapId, startingMap, submitShareMap]);

  const onSubmitSendEmail = useCallback(async (emails: readonly string[], message: string) => {
    const result = await sendShareMapEmail(emails, message);

    if (result === ShareMapEmailResults.Success) {
      const onClose = props.onClose;
      onClose();
      openEmailNotificationModal({});
    }
    else {
      setSendEmailResult(result);
    }
  }, [props.onClose, sendShareMapEmail, openEmailNotificationModal]);

  const isPrivacyLevelValid = !!privacyLevel && validatePrivacyLevel(privacyLevel, password);

  const lockedSections = useMemo(() =>
    new Set<ShareMapSection>([
      !isPrivacyLevelValid || hasErrorOnPath(ShareMapSection.StartingMap, fieldErrors) ? ShareMapSection.StartingMap : undefined,
      !isPrivacyLevelValid || !startingMap || hasErrorOnPath(ShareMapSection.Share, fieldErrors) ? ShareMapSection.Share : undefined,
    ].filter(s => !!s)),
  [fieldErrors, isPrivacyLevelValid, startingMap]);

  useEffect(() => {
    if (!props.isOpen) {
      setPrivacyLevel(null);
      setExpandedSections(new Set([ShareMapSection.PrivacyLevel]));
      setPassword('');
      setStartingMap(null);
      setMapName('');
    }
  }, [props.isOpen]);

  return (
    <ShareMapComponent
      isOpen={props.isOpen}
      isCalledFromMap={isCalledFromMap}
      isSnapshot={props.isSnapshot}
      onClose={props.onClose}
      onChangeShareMethod={setSharingMethod}
      onChangeStartingMap={onStartMapChange}
      onMapFormSubmit={onMapNameFormSubmit}
      onPrivacyLevelChange={onPrivacyLevelChange}
      privacyLevel={privacyLevel}
      mapName={props.mapName}
      mapPrivacyRestrictions={mapPrivacyRestrictions}
      shareMethod={shareMethod}
      startingMap={startingMap}
      expandedSections={expandedSections}
      onToggleSection={section => setExpandedSections(copy.andTogglePresence(expandedSections, section))}
      lockedSections={lockedSections}
      isLoading={isLoading || isSendingEmail}
      globalError={globalError}
      onClearGlobalError={clearGlobalError}
      fieldErrors={fieldErrors}
      onClearFieldError={clearFieldError}
      shareId={shareId}
      onSubmitSendEmail={onSubmitSendEmail}
      sendEmailResult={sendEmailResult}
    />
  );
};
