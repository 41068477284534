import {
  type BoundaryEditAction, type BoundaryEditManualMethod,
} from '~/boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import { type BoundaryEditSubmitModalAction } from '~/boundary/editMode/boundaryEditSubmitModal/boundaryEditSubmitModal.helpers';
import { type CreateBoundaryGroupFromAIConfig } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import type { BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import {
  type BoundaryGroupId, type BoundaryId, type BoundaryTerritoryGroupId,
} from '~/store/boundaries/boundaryIdentifier.type';

export enum BoundarySelectType {
  Click = 'Click',
  HoverIdle = 'HoverIdle',
  HoverActive = 'HoverActive',
  Lasso = 'Lasso',
}

export enum SelectLassoOverlap {
  Partial = 'partial',
  Contains = 'contains',
}

export enum BoundarySelectMode {
  EditBoundaryTerritory = 'EditBoundaryTerritory',
  EditCustomBoundary = 'EditCustomBoundary',
  EditCustomBoundarySubmit = 'EditCustomBoundarySubmit',
  CreateOptimizedTerritoriesSubset = 'CreateOptimizedTerritoriesSubset',
}

type EditBoundaryTerritorySelectionModeData = Readonly<{
  type: BoundarySelectMode.EditBoundaryTerritory;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
}>;

type EditCustomBoundarySelectionModeData = Readonly<{
  type: BoundarySelectMode.EditCustomBoundary;
  action: BoundaryEditAction;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  boundaryId?: BoundaryId;
}>;

type CreateOptimizedTerritoriesSubsetSelectionModeData = Readonly<{
  type: BoundarySelectMode.CreateOptimizedTerritoriesSubset;
  configuration: CreateBoundaryGroupFromAIConfig;
}>;

export type EditCustomBoundarySubmitSelectionConfiguration = {
  geometry: BoundaryCombineGeometryRequestItem[];
  submitAction: BoundaryEditSubmitModalAction;
  previousBoundaryGroupId?: BoundaryGroupId;
  method: BoundaryEditManualMethod;
};

type EditCustomBoundarySubmitSelectionModeData = Readonly<{
  type: BoundarySelectMode.EditCustomBoundarySubmit;
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  configuration: EditCustomBoundarySubmitSelectionConfiguration;
}>;

type BoundarySelectCommon = Readonly<{
  selectLassoOverlap: SelectLassoOverlap;
  selectType: BoundarySelectType;
  allowMultipleSelection: boolean;
}>;

export type BoundarySelectInactiveState = BoundarySelectCommon & Readonly<{
  isModalOpen: false;
  isActive: false;
  hoveredBoundaryId: null;
  selectBoundaryGroupId: null;
  selectedBoundaryIds: null;
  selectionModeData: null;
  isLassoSelectLoading: false;
}>;

type BoundarySelectActiveCommon = BoundarySelectCommon & Readonly<{
  isModalOpen: true;
  isActive: boolean;
  hoveredBoundaryId: BoundaryId | null;
  selectBoundaryGroupId: BoundaryGroupId;
  selectedBoundaryIds: ReadonlySet<BoundaryId>;
  isLassoSelectLoading: boolean;
}>;

export type BoundaryTerritorySelectActiveState = BoundarySelectActiveCommon & Readonly<{
  selectionModeData: EditBoundaryTerritorySelectionModeData;
}>;

export type CustomBoundarySelectActiveState = BoundarySelectActiveCommon & Readonly<{
  selectionModeData: EditCustomBoundarySelectionModeData;
}>;

export type CreateOptimizedTerritoriesSubsetSelectActiveState = BoundarySelectActiveCommon & Readonly<{
  selectionModeData: CreateOptimizedTerritoriesSubsetSelectionModeData;
}>;

export type EditCustomBoundarySubmitSelectActiveState = BoundarySelectActiveCommon & Readonly<{
  selectionModeData: EditCustomBoundarySubmitSelectionModeData;
}>;

export type BoundarySelectState = BoundarySelectInactiveState | BoundaryTerritorySelectActiveState | CustomBoundarySelectActiveState | CreateOptimizedTerritoriesSubsetSelectActiveState | EditCustomBoundarySubmitSelectActiveState;

export const isBoundarySelectActive = (
  state: BoundarySelectState
): state is (BoundaryTerritorySelectActiveState | CustomBoundarySelectActiveState | CreateOptimizedTerritoriesSubsetSelectActiveState) => state.isActive;

export const isBoundarySelectModalOpen = (
  state: BoundarySelectState
): state is (BoundaryTerritorySelectActiveState | CustomBoundarySelectActiveState | CreateOptimizedTerritoriesSubsetSelectActiveState) => state.isModalOpen;

export const isBoundarySelectEditBoundaryTerritory = (state: BoundarySelectState): state is BoundaryTerritorySelectActiveState =>
  state.selectionModeData?.type === BoundarySelectMode.EditBoundaryTerritory;

export const isBoundarySelectEditCustomBoundary = (state: BoundarySelectState): state is CustomBoundarySelectActiveState =>
  state.selectionModeData?.type === BoundarySelectMode.EditCustomBoundary;

export const isBoundarySelectEditCustomBoundarySubmit = (state: BoundarySelectState): state is EditCustomBoundarySubmitSelectActiveState =>
  state.selectionModeData?.type === BoundarySelectMode.EditCustomBoundarySubmit;

export const isBoundarySelectCreateOptimizedTerritoriesSubset = (state: BoundarySelectState): state is CreateOptimizedTerritoriesSubsetSelectActiveState =>
  state.selectionModeData?.type === BoundarySelectMode.CreateOptimizedTerritoriesSubset;
