import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faImages } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useEffect,
  useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { FormErrorMessageComponent } from '~/_shared/components/formErrorMessage/formErrorMessage.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  autoCompleteDisabled, preventDefaultAnd,
} from '~/_shared/utils/dom/dom.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';

export enum StartingView {
  DefaultMap = 'default-map',
  CurrentMapView = 'current-map-view',
}

const mapNameContainerStyle = css({
  display: 'flex',
  marginTop: 24,
});

const mapNameInputStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.secondary,
  flex: 1,
});

const mapNameButtonStyle = css({
  marginLeft: 15,
});

type StartingMapViewSectionProps = Readonly<{
  accordionLabelStyle: SerializedStyles;
  isCalledFromMap: boolean;
  mapNameErrors: ReadonlyArray<string> | null;
  radioGroupStyle: SerializedStyles;
  value: StartingView | null;

  onChange: (view: StartingView) => void;
  onClearMapNameErrors: () => void;
  onMapNameFormSubmitted: (mapName: string) => void;
}>;

export const StartingMapViewSectionComponent: FC<StartingMapViewSectionProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [mapNameRef, setMapNameRef] = useHookWithRefCallback<HTMLInputElement>();
  const [mapName, setMapName] = useState('');

  useEffect(() => {
    if (props.value === StartingView.CurrentMapView) {
      mapNameRef?.focus();
    }
  }, [props.value, mapNameRef]);

  useEffect(() => {
    if (mapName) {
      props.onChange(StartingView.CurrentMapView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapName]);

  useEffect(() => {
    if (props.value !== StartingView.CurrentMapView) {
      setMapName('');
    }
  }, [props.value]);

  return (
    <>
      <RadioGroupComponent
        css={props.radioGroupStyle}
        onValueChange={props.onChange}
        selectedValue={props.value}
        items={[{
          label: <span css={props.accordionLabelStyle}>{t('Default Map')}</span>,
          description: t('Share the primary default map.'),
          value: StartingView.DefaultMap,
        }, ...(props.isCalledFromMap ? [{
          label: <span css={props.accordionLabelStyle}>{t('Your Current View Map')}</span>,
          description: t('Share your current view including saved map position, zoom level, filters, grouping, radius circles, routes, etc.'),
          value: StartingView.CurrentMapView,
        }] : [])]}
      />

      {props.isCalledFromMap && (
        <form
          onSubmit={preventDefaultAnd(() => props.onMapNameFormSubmitted(mapName))}
          css={mapNameContainerStyle}
        >
          <TextInputComponent
            autoComplete={autoCompleteDisabled}
            ref={setMapNameRef}
            css={mapNameInputStyle({ theme })}
            onChange={name => {
              setMapName(name);
              return props.mapNameErrors && props.onClearMapNameErrors();
            }}
            value={mapName}
            placeholder={t('Name Your Map')}
            icon={faImages}
          />

          <ButtonComponent
            isSubmit
            css={mapNameButtonStyle}
            text={t('Create Map View')}
            isDisabled={!mapName}
          />
        </form>
      )}
      {props.mapNameErrors && props.mapNameErrors.map((error, index) => (
        <FormErrorMessageComponent
          key={index}
          messages={[error]}
        />
      ))}
    </>
  );
};
