import { type Operation } from 'fast-json-patch';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { mapToolTitleMap } from '~/sidebar/sidebarApps/mapTools/mapTools.helpers';
import { type MapSettingsDataState } from './mapSettingsData.state';

export type MapSettingsDiffRequest = {
  path: string;
  operation: Operation;
};

export const mapSettingsDataPathMap = {
  markers: 'markers',
  search: 'search',
  proximity: 'proximity',
  directions: 'directions',
  boundaries: 'boundaries',
  drawing: 'drawing',
  filtering: 'filtering',
  fileAttachments: 'fileAttachments',
  grouping: 'grouping',
  heatmaps: 'heatmaps',
  layered: 'layered',
  markersGeneral: 'markersGeneral',
  settings: 'settings',
  mapStyles: 'mapStyles',
  toolsState: 'toolsState',
  publicMapSettings: 'publicMapSettings',
  places: 'places',
} as const satisfies {[key in keyof MapSettingsDataState]: string };

export type MapSettingsDataPath = typeof mapSettingsDataPathMap[keyof typeof mapSettingsDataPathMap];

export const normalizePathForMapSettingsTree = (diff: Operation[]): Operation[] =>
  diff
    .filter(i => {
      const items = i.path.split('/').filter(item => item);
      return items.length > 1 && mapSettingsDataPathMap[items[0] as MapSettingsDataPath];
    })
    .map(i => ({
      ...i,
      path: i.path.split('/').filter(item => item).join('/'),
    }));

export const getMapSettingsDataPathItemName = (item: MapSettingsDataPath, t: TranslationFnc): string => {
  switch (item) {
    case mapSettingsDataPathMap.grouping:
      return t(mapToolTitleMap.Grouping);
    case mapSettingsDataPathMap.filtering:
      return t(mapToolTitleMap.Filter);
    case mapSettingsDataPathMap.heatmaps:
      return t(mapToolTitleMap.HeatMap);
    case mapSettingsDataPathMap.proximity:
      return t(mapToolTitleMap.Proximity);
    case mapSettingsDataPathMap.markersGeneral:
      return t('Map Markers / Graphics');
    default:
      return t('Map Tools');
  }
};
