import { type BoundaryGroupDemographic } from '~/store/boundaryGroups/boundaryGroups.repository';
import { type SpreadsheetColumn } from '../../types/spreadsheetData/spreadsheetColumn';
import { type MetricsDataAction } from '../../utils/metric/metrics.types';

export enum SelectedDataType {
  SpreadsheetColumn = 'SpreadsheetColumn',
  Demographic = 'Demographic'
}

export type SelectedDataSpreadsheetColumn = {
  type: SelectedDataType.SpreadsheetColumn;
  selectedSpreadsheetColumn: SpreadsheetColumn;
  dataAction: MetricsDataAction;
};

export type SelectedDataDemographic = {
  type: SelectedDataType.Demographic;
  selectedDemographic: BoundaryGroupDemographic;
};

export type SelectedDataMetric = SelectedDataSpreadsheetColumn | SelectedDataDemographic;
