import { useEffect } from 'react';
import {
  areGoogleUserScopesSufficient, determineRequiredMaptiveScopes, GoogleAPIOperation,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { parseUrl } from '~/_shared/utils/url/url.helpers';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { useReconnectGoogleSheet } from '~/data/syncData/useReconnectGoogleSheet.hook';
import { useSyncGoogleSheet } from '~/data/syncData/useSyncGoogleSheet.hook';
import {
  GoogleSheetMapSyncType, isGoogleSheetMapSyncType,
} from '~/map/map.repository';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { EXTERNAL_ACTION_INDICATOR } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { useGoogleApiOauthUserDataSelector } from '~/store/userData/userData.selectors';
import {
  type StorageService, useStorageService,
} from '../../utils/storageService';

export const STORED_GOOGLE_MAPTIVE_SYNC_METHOD = 'google_maptive_sync_method';
const GOOGLE_AUTH_STATUS_GET_PARAM = 'google_auth_status';

const getStoredSyncMethod = (storage: StorageService) => {
  const storedValue = storage.getLocalStorageItem(STORED_GOOGLE_MAPTIVE_SYNC_METHOD);
  return isGoogleSheetMapSyncType(storedValue) ? storedValue : GoogleSheetMapSyncType.GoogleToMaptive;
};

export const useGoogleOauthAppLoadResolver = () => {
  const currentUserScope = useGoogleApiOauthUserDataSelector()?.scopes;
  const [t] = useTranslation();
  const syncGoogleSheet = useSyncGoogleSheet();
  const reconnectGoogleSheet = useReconnectGoogleSheet();
  const { openModal: openCreateGoogleSheetMapModal } = useModal(ModalType.CreateGooogleSheetMap);
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);
  const storageService = useStorageService();

  useEffect(() => {
    const { queryParameters, urlPart } = parseUrl(window.location.href);
    const statusParameter = queryParameters?.[GOOGLE_AUTH_STATUS_GET_PARAM];

    if (currentUserScope && statusParameter === '1') {
      const actionParameter = queryParameters?.[EXTERNAL_ACTION_INDICATOR];
      const isReconnectGoogleSheetAction = actionParameter === GoogleAPIOperation.RECONNECT_GOOGLE_SPREADSHEET;
      const syncMethod = isReconnectGoogleSheetAction ? GoogleSheetMapSyncType.GoogleToMaptive : getStoredSyncMethod(storageService);
      const requiredPermissions = syncMethod ? determineRequiredMaptiveScopes(syncMethod) : null;

      if (!areGoogleUserScopesSufficient(requiredPermissions, currentUserScope)) {
        openAppErrorModal({
          type: AppErrorType.General,
          title: t('googleSheets.permissionsNotGiven.title'),
          errorTitle: t('googleSheets.permissionsNotGiven.errorTitle'),
          text: t('googleSheets.permissionsNotGiven.errorText'),
        });
      }
      else if (actionParameter === GoogleAPIOperation.CREATE_MAP_FROM_SHEET) {
        openCreateGoogleSheetMapModal({
          preselectedSyncMethod: syncMethod || undefined,
        });
      }
      else if (actionParameter === GoogleAPIOperation.SYNC_SPREADSHEETS) {
        syncGoogleSheet({
          preselectedSyncMethod: syncMethod || undefined,
        });
      }
      else if (isReconnectGoogleSheetAction) {
        reconnectGoogleSheet();
      }

      storageService.removeLocalStorageItem(STORED_GOOGLE_MAPTIVE_SYNC_METHOD);
    }
    else if (statusParameter === '2') {
      openAppErrorModal({
        type: AppErrorType.General,
        title: t('googleSheets.googleAccUsedByAnotherClient.title'),
        errorTitle: t('googleSheets.googleAccUsedByAnotherClient.errorTitle'),
        text: t('googleSheets.googleAccUsedByAnotherClient.errorText'),
      });
    }

    // cleanup the url from the address bar to prevent reload issues
    const isGoogleOauth = !!statusParameter;
    if (isGoogleOauth && urlPart) {
      history.pushState(undefined, '', urlPart);
    }
  }, [storageService, currentUserScope, openAppErrorModal, openCreateGoogleSheetMapModal, syncGoogleSheet, t, reconnectGoogleSheet]);
};
