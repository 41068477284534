import {
  SPREADSHEET_ADD_FILTER_ITEM, SPREADSHEET_FETCH_DATA_ERROR, SPREADSHEET_FETCH_DATA_STARTED,
  SPREADSHEET_FETCH_DATA_SUCCESS, SPREADSHEET_RESET_STATE_AND_REFETCH_DATA,
} from './spreadsheetData.actionTypes';
import { type SpreadsheetDataData } from './spreadsheetData.state';

export function spreadsheetFetchSpreadsheetDataStarted() {
  return {
    type: SPREADSHEET_FETCH_DATA_STARTED,
  } as const;
}

export function spreadsheetFetchSpreadsheetDataError(error: Error) {
  return {
    type: SPREADSHEET_FETCH_DATA_ERROR,
    payload: {
      error,
    },
  } as const;
}

export function spreadsheetAddFilterItem(spreadsheetId: number, filterHash: string, rowsData: {[key: string]: 1}) {
  return {
    type: SPREADSHEET_ADD_FILTER_ITEM,
    payload: {
      spreadsheetId,
      filterHash,
      rowsData,
    },
  } as const;
}

export function spreadsheetFetchSpreadsheetDataSuccess(data: SpreadsheetDataData, etag: Uuid) {
  return {
    type: SPREADSHEET_FETCH_DATA_SUCCESS,
    payload: {
      data,
      etag,
    },
  } as const;
}

export function spreadsheetResetStateAndRefetchData() {
  return {
    type: SPREADSHEET_RESET_STATE_AND_REFETCH_DATA,
  } as const;
}
