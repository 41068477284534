import { type TranslationFnc } from '../utils/hooks';

export enum SearchMatchingBehaviour {
  ShowOnlyMatches = 'ShowOnlyMatches',
  ShowAllMarkers = 'ShowAllMarkers',
}

export const createSearchMatchingBehaviourDescription = (t: TranslationFnc, behaviour: SearchMatchingBehaviour) => {
  switch (behaviour) {
    case SearchMatchingBehaviour.ShowAllMarkers:
      return t('SearchMatchingBehaviour.ShowAllMarkers__description');
    case SearchMatchingBehaviour.ShowOnlyMatches:
      return t('SearchMatchingBehaviour.ShowOnlyMatches__description');
    default:
      throw new Error(`searchMatchingBehaviour.enum.ts: Unknown SearchMatchingBehaviour '${behaviour}'.`);
  }
};
