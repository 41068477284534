import { truncateMarkerLabelText } from '../labels/labels.helpers';
import { isNullOrUndefined } from '../typeGuards';
import { webglColorChanged } from './webglColor.helpers';

export type WebglOverlayLabelWithBody = {
  label: WebglOverlayLabel;
  textBoxCallout: WebglOverlayTextBoxCallout;
};

// zIndex is another parameter for the two separately; width and height are modified when aligning so cannot be set from outside
type Config = Omit<WebglOverlayTextBoxCalloutConfig & WebglOverlayLabelConfig, 'zIndex' | 'width' | 'height'>;
type ZIndexes = { text: number; background: number };
export type LabelWithBodyVirtualLayer = { remove: (label: WebglOverlayLabelWithBody) => void; add: (label: WebglOverlayLabelWithBody) => void };
type Layers = readonly [LabelsLayer, TextBoxCalloutLayer];

const createAddLabelWithBody = (...[texts, backgrounds]: Layers) => (label: WebglOverlayLabelWithBody) => {
  texts.add(label.label);
  backgrounds.add(label.textBoxCallout);
};

const createRemoveLabelWithBody = (...[texts, backgrounds]: Layers) => (label: WebglOverlayLabelWithBody) => {
  texts.remove(label.label);
  backgrounds.remove(label.textBoxCallout);
};

export const createLabelWithBodyVirtualLayer = (textLabelLayer: LabelsLayer, backgroundLabelLayer: TextBoxCalloutLayer) => ({
  add: createAddLabelWithBody(textLabelLayer, backgroundLabelLayer),
  remove: createRemoveLabelWithBody(textLabelLayer, backgroundLabelLayer),
});

export const createWebglOverlayLabelWithBody = (config: Config, zIndexes: ZIndexes): WebglOverlayLabelWithBody => {
  const alteredConfig = {
    ...config,
    text: {
      ...config.text,
      value: config.text?.value ? truncateMarkerLabelText(config.text.value) : '',
    },
  };
  const result = ({
    label: new WebGLOverlay.Label({ ...alteredConfig, interactive: false, zIndex: zIndexes.text }),
    textBoxCallout: new WebGLOverlay.TextBoxCallout({ ...alteredConfig, zIndex: zIndexes.background }),
  });
  result.label.setCallout(result.textBoxCallout);

  return result;
};

export const updateWebglOverlayLabelWithBody = (existing: WebglOverlayLabelWithBody, config: Config, zIndexes: ZIndexes | null): void => {
  updateLabelText(existing.label, { ...config, interactive: false, zIndex: zIndexes?.text });
  updateLabelBackground(existing.textBoxCallout, { ...config, zIndex: zIndexes?.background });
};

const mapObjectKeys = ['visible', 'interactive', 'opacity', 'zIndex'] as const;
const latLngKeys = ['lat', 'lng'] as const;
const offsetKeys = ['x', 'y'] as const;

const updateLabelText = (existing: WebglOverlayLabel, config: WebglOverlayLabelConfig) => {
  const directKeys = [...mapObjectKeys, ...latLngKeys, 'lat', 'lng', 'horizontalAnchor', 'verticalAnchor', 'autoHideWhenCollide', 'sizeOnLevel'] as const;

  tryUpdateKeys(existing, directKeys, config);
  tryUpdateKeys(existing.offset, offsetKeys, config.offset);
  tryUpdateKeys(existing.position, offsetKeys, config.position);

  existing.padding.y = config.padding?.t ?? existing.padding.y;
  existing.padding.x = config.padding?.r ?? existing.padding.x;
  existing.padding.z = config.padding?.l ?? existing.padding.z;
  existing.padding.w = config.padding?.b ?? existing.padding.w;

  if (config.text && webglColorChanged(existing.text.fillColor, config.text.fillColor)) {
    existing.text.fillColor.set(...config.text.fillColor);
  }
  if (config.text && webglColorChanged(existing.text.strokeColor, config.text?.strokeColor)) {
    existing.text.strokeColor.set(...config.text.strokeColor);
  }
  if (!isNullOrUndefined(config.text)) {
    existing.text.value = config.text?.value ? truncateMarkerLabelText(config.text.value) : '';
  }

  const textKeys = [
    'font',
    'fontSize',
    'fillWidth',
    'fillEdge',
    'strokeWidth',
    'letterSpacing',
    'linePadding',
    'align',
  ] as const;
  tryUpdateKeys(existing.text, textKeys, config.text);

  const boundariesBaseKeys = ['enabled', 'minFontSize', 'maxFontSize'] as const;
  tryUpdateKeys(existing.boundaries, boundariesBaseKeys, config.boundaries);

  tryUpdateKeys(existing.boundaries.ne, latLngKeys, config.boundaries?.ne);
  tryUpdateKeys(existing.boundaries.sw, latLngKeys, config.boundaries?.sw);
};

const updateLabelBackground = (existing: WebglOverlayTextBoxCallout, config: WebglOverlayTextBoxCalloutConfig) => {
  const backgroundKeysHandledByLabelIntegration = new Set([
    'width',
    'height',
    'visible',
    ...latLngKeys,
  ]);
  const directKeys = [
    ...mapObjectKeys,
    ...latLngKeys,
    'borderWidth',
    'borderRadius',
    'triangle',
    'triangleHeight',
    'triangleSide',
    'triangleWidth',
    'zIndex',
    'staticSize',
  ] as const;
  const filteredKeys = directKeys.filter(k => !backgroundKeysHandledByLabelIntegration.has(k));

  tryUpdateKeys(existing, filteredKeys, config);

  if (webglColorChanged(existing.fillColor, config.fillColor)) {
    existing.fillColor.set(...config.fillColor);
  }
  if (webglColorChanged(existing.borderColor, config.borderColor)) {
    existing.borderColor.set(...config.borderColor);
  }
};

const tryUpdate = <TObject, TKey extends keyof TObject, TConfig extends { [key in TKey]?: TObject[TKey] }>(obj: TObject, key: TKey, config: TConfig) => {
  obj[key] = config[key] ?? obj[key];
};

const tryUpdateKeys = <TKeys extends readonly string[], TObject extends { [key in TKeys[number]]: unknown }, TConfig extends { [TKey in TKeys[number]]?: TObject[TKey] }>(obj: TObject, keys: TKeys, config: TConfig | undefined) => {
  if (!config) {
    return;
  }
  Object.keys(config)
    .filter(key => keys.includes(key))
    .forEach((key: TKeys[number]) => tryUpdate(obj, key, config));
};
