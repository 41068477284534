import { clamp } from './number/number.helpers';

export const noop = () => {/* do nothing */};

export const range = (count: number, start: number = 0, step: number = 1) =>
  Array.from(Array(Math.ceil(count)).keys())
    .map(i => i * step + start);

export const always = <T>(res: T) => () => res;

/* easeOutFactory
 *
 * Generate an ease-out function with desired steepness.
 * Article: https://medium.com/analytic-animations/ease-out-the-half-sigmoid-7240df433d98#.yupto8l43
 *
 * Note: Values below 6 may not come to a smooth stop.
 *
 * Required:
 *   k: (float), sharpness of ease
 *
 * Return: f(t), t in 0..1
 */
export const easeOutFactory = (k: number) => {
  // There's a discontinuity at k = 0 that
  // doesn't make sense from a usability perspective
  // so patch it over.
  k = (k === 0) ? 1e-7 : k;

  const sigmoid = (t: number) => (1 / (1 + Math.exp(-k * t))) - 0.5;

  return (t: number) => {
    t = clamp(t, { min: 0, max: 1 });
    return sigmoid(t) / sigmoid(1);
  };
};

export const identity = <T>(t: T) => t;
