import {
  type ComponentProps, type FC,
} from 'react';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { TriStateCheckboxComponent } from './triStateCheckbox.component';

export type CheckboxComponentProps = Omit<ComponentProps<typeof TriStateCheckboxComponent>, 'checkmark'> & Readonly<{
  isChecked: boolean;
}>;

export const CheckboxComponent: FC<CheckboxComponentProps> = (props) => (
  <TriStateCheckboxComponent
    checkmark={props.isChecked ? TriStateRange.Full : TriStateRange.None}
    {...props}
  />
);
