import {
  useMemo, useRef,
} from 'react';
import { debounce } from '../debounce';

/**
 * Debounces given function so it is called after the given number of milliseconds.
 * @param func any function that suffers from too frequent calls
 * @param waitMs a number of milliseconds in which the function will only be invoked once
 */

export const useDebounce = <A extends any[], T>(func: (...args: A) => T, waitMs: number) => {
  const functionRef = useRef<(...args: A) => T>(func);
  functionRef.current = func;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounce<A, T>((...args) => functionRef.current(...args), waitMs), []);
};
