import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  useDirectionsCanDragRoutesSelector,
  useDirectionsSelectedCollapsingSelector,
  useDirectionsShouldBeginAtUserLocationSelector,
} from '../../store/frontendState/mapTools/directions/directions.selectors';
import { setMapDirectionSettings } from '../../store/mapSettings/directions/mapSettingsDirections.actionCreators';
import {
  DirectionsSettingsComponent, type onChangeParams,
} from './directionsSettings.component';

export type DirectionSettingsModalContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const DirectionSettingsModalContainer: FC<DirectionSettingsModalContainerProps> = (props) => {
  const dispatch = useDispatch();
  const selectedCollapsing = useDirectionsSelectedCollapsingSelector();
  const canDragRoutes = useDirectionsCanDragRoutesSelector();
  const shouldBeginAtUserLocation = useDirectionsShouldBeginAtUserLocationSelector();

  const onChange = useCallback((settings: onChangeParams) => {
    dispatch(setMapDirectionSettings(settings.collapsingLevel, settings.canDragRoutes, settings.shouldBeginAtUserLocation));
  }, [dispatch]);

  return (
    <DirectionsSettingsComponent
      {...props}
      onChange={onChange}
      selectedCollapsing={selectedCollapsing}
      canDragRoutes={canDragRoutes}
      shouldBeginAtUserLocation={shouldBeginAtUserLocation}
    />
  );
};
