import {
  type FC, useEffect, useRef, useState,
} from 'react';
import { getOrSet } from '~/_shared/utils/collections/collections';
import { type StreetViewLocation } from '~/store/frontendState/mapTools/streetView/streetView.state';
import { loadGoogleMapsScript } from '../google-map/google-map.loader';

type PanoramaInstance = {
  container: HTMLDivElement;
  instance: google.maps.StreetViewPanorama;
};

const panoramaInstances = new Map<string, PanoramaInstance>();

const defaultPanoramaOptions = {
  zoom: 0,
  pov: { heading: 0, pitch: 0 },
};

const createPanorama = (id: string) => {
  const container = document.createElement('div');
  container.style.width = '100%';
  container.style.height = '100%';
  container.id = id;

  const instance = new google.maps.StreetViewPanorama(container, {
    disableDefaultUI: true,
  });

  return { container, instance };
};

const getPanorama = (id: string) => {
  const panorama = getOrSet(panoramaInstances, id, () => createPanorama(id));

  if (panorama.container.isConnected) {
    console.error(`Panorama container ${id} is already mounted (is used by another component).`);
    return null;
  }

  return panorama;
};

export type StreetViewPanoramaComponentProps = {
  panoramaContainerId: string;
  streetViewLocation: StreetViewLocation;
  className?: string;
};

export const StreetViewPanoramaComponent: FC<StreetViewPanoramaComponentProps> = (props) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const panoramaParentNodeRef = useRef<HTMLDivElement>(null);
  const [panoramaState, setPanoramaState] = useState<PanoramaInstance | null>(null);

  useEffect(() => {
    loadGoogleMapsScript().then(() => setGoogleMapsLoaded(true));
  }, []);

  useEffect(() => {
    if (!googleMapsLoaded) {
      return;
    }

    const parentNode = panoramaParentNodeRef.current;

    if (parentNode) {
      const panorama = getPanorama(props.panoramaContainerId);

      if (panorama) {
        setPanoramaState(panorama);
        panorama.instance.setOptions(defaultPanoramaOptions);
        parentNode.appendChild(panorama.container);
        return () => {
          parentNode.removeChild(panorama.container);
        };
      }
    }

    return;
  }, [googleMapsLoaded, props.panoramaContainerId]);

  useEffect(() => {
    if (panoramaState) {
      panoramaState.instance.setOptions({
        ...defaultPanoramaOptions,
        ...props.streetViewLocation,
      });
    }
  }, [panoramaState, props.streetViewLocation]);

  return (
    <div
      ref={panoramaParentNodeRef}
      className={props.className}
    />
  );
};
