import {
  type FC, useCallback,
} from 'react';
import { useQuery } from '~/_shared/utils/hooks/useQuery';
import { UnblockAccountComponent } from './unblockAccount.component';
import { useUnblockAccount } from './useUnblockAccount';

export const UnblockAccountContainer: FC = () => {
  const { isLoading, errorMessage, unblockAccount } = useUnblockAccount();
  const query = useQuery();

  const expires = query.get('expires');
  const hash = query.get('hash');
  const userEmail = query.get('email');
  const signature = query.get('signature');

  const handleUnblockAccount = useCallback(() => {
    if (expires && hash && userEmail && signature) {
      return unblockAccount({ expires, hash, userEmail, signature })
        .then(() => Promise.resolve());
    }
    return Promise.reject();
  }, [expires, hash, signature, unblockAccount, userEmail]);

  return (
    <UnblockAccountComponent
      unblockAccount={handleUnblockAccount}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
};
