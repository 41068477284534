import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type RadioGroupItem } from '~/_shared/baseComponents/radio/radioGroup.component';
import { type TranslationFnc } from '~/_shared/utils/hooks';

export enum BoundaryEditAction {
  EditBoundaries = 'EditBoundaries',
  CreateNewBoundary = 'CreateNewBoundary'
}

export const getBoundaryEditActionOptions = (t: TranslationFnc, boundaryGroupIsEmpty: boolean): ReadonlyArray<DropdownOption<BoundaryEditAction>> => ([{
  name: t('Create New Territory'),
  value: BoundaryEditAction.CreateNewBoundary,
}, {
  name: t('Edit Existing Territories'),
  value: BoundaryEditAction.EditBoundaries,
}].filter(option => option.value !== BoundaryEditAction.EditBoundaries || !boundaryGroupIsEmpty));

export enum BoundaryEditManualMethod {
  Selection = 'Selection',
  Draw = 'Draw'
}

export const getBoundaryEditManualMethodOptions = (t: TranslationFnc): ReadonlyArray<DropdownOption<BoundaryEditManualMethod>> => ([{
  name: t('Selection'),
  value: BoundaryEditManualMethod.Selection,
}, {
  name: t('Drawing'),
  value: BoundaryEditManualMethod.Draw,
}]);

export enum BoundaryEditSelectFrom {
  Boundary = 'Boundary',
  Territory = 'Territory'
}

export const getBoundaryEditSelectFromRadioGroupOptions = (t: TranslationFnc, short: boolean): ReadonlyArray<RadioGroupItem<BoundaryEditSelectFrom>> => ([{
  label: short ? t('Boundaries') : t('From Boundaries'),
  value: BoundaryEditSelectFrom.Boundary,
}, {
  label: short ? t('Territories') : t('From Territories'),
  value: BoundaryEditSelectFrom.Territory,
}]);
