import { type ProximityDriveTimePathsAction } from './proximityDriveTimePaths.action';
import { PROXIMITY_DRIVE_TIME_SET_PROXIMITY_PATHS } from './proximityDriveTimePaths.actionTypes';
import { type ProximityDriveTimePathsState } from './proximityDriveTimePaths.state';

const initialProximityToolState: ProximityDriveTimePathsState = {
  paths: {},
  messages: {},
};

export const proximityDriveTimePathsReducer = (state = initialProximityToolState, action: ProximityDriveTimePathsAction): ProximityDriveTimePathsState => {
  switch (action.type) {
    case PROXIMITY_DRIVE_TIME_SET_PROXIMITY_PATHS: {
      const newPaths = { ...state.paths };
      const newMessages = { ...state.messages };
      action.payload.paths.forEach((item) => {
        newPaths[item.proximityPathId] = item.paths;
        newMessages[item.proximityPathId] = item.message;
      });
      return {
        ...state,
        paths: newPaths,
        messages: newMessages,
      };
    }

    default:
      return state;
  }
};
