import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryGroupDemographic, type BoundaryGroupMatchings, type WmsDemographicConstraints,
} from '../store/boundaryGroups/boundaryGroups.repository';

export enum BoundaryGroupCodenames {
  UsStates = 'us-states',
  UsCounties = 'us-counties',
  UsZipCodes = 'us-zip-codes',
  UsZipCodesLowDetail = 'us-zip-codes-lower',
}

export type BoundaryGroupBase = Readonly<{
  clientId: number | null;
  id: number;
  loadZoomLevel: number;
  name: string;
  sort: number | null; //this indicates the order in which the boundary group is displayed in the selected boundary type dropdown
  userId: number | null;
  zoomLevels: number[];
  matchings: BoundaryGroupMatchings | null;
  readonly: boolean;
  allowOverlap: boolean;
  wms: {
    territoriesCount: number;
    constraints?: WmsDemographicConstraints;
    geometryRemainingCalls: number | null;
    wmsTemporary: boolean;
  };
  createdFrom?: {
    boundaryTerritoryGroupId?: number;
    boundaryGroupIds: ReadonlyArray<number>;
  };
  editBoundaryGroupFallback?: BoundaryGroupId;
  visible: boolean;
}>;

export type BoundaryGroup = BoundaryGroupBase & Readonly<{
  demographics: readonly BoundaryGroupDemographic[];
  codename?: string;
}>;

export type BoundaryGroupDetails = BoundaryGroupBase & Readonly<{
  usedOnMaps: number[];
}>;
