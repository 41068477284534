export enum SharedPresentationalMapsBooleanToggleSettingsName {
  allowSatelliteView = 'allowSatelliteView',
  allowStreetView = 'allowStreetView',
  boundaryTool = 'boundaryTool',
  displayLegendOnly = 'displayLegendOnly',
  displaySearchBox = 'displaySearchBox',
  displayTitleAndDescription = 'displayTitleAndDescription',
  distanceCalculator = 'distanceCalculator',
  filterMenusOpen = 'filterMenusOpen',
  filterTool = 'filterTool',
  groupingTool = 'groupingTool',
  heatMappingTool = 'heatMappingTool',
  lassoTool = 'lassoTool',
  legendReplacesGrouping = 'legendReplacesGrouping',
  locationFinder = 'locationFinder',
  locationFinderStartsOpen = 'locationFinderStartsOpen',
  locationList = 'locationList',
  locationListOpen = 'locationListOpen',
  mapImageExport = 'mapImageExport',
  mapToolsMenuOpen = 'mapToolsMenuOpen',
  mapZoom = 'mapZoom',
  markersVisibilityButton = 'markersVisibilityButton',
  radiusProximity = 'radiusProximity',
  restrictMapPanning = 'restrictMapPanning',
  routingDirections = 'routingDirections',
  searchBarAlwaysVisible = 'searchBarAlwaysVisible',
  showMyLocation = 'showMyLocation',
  showMyLocationDefault = 'showMyLocationDefault',
}

export enum SharedPresentationalMapsNonBooleanToggleSettingsName {
  themeStyle = 'themeStyle',
}

export enum SharedPresentationalMapsDropdownsSettingsName {
  defaultOpenMapToolPresentationalMap = 'defaultOpenMapToolPresentationalMap',
}

export enum SharePresentationalMapsButtonsName {
  embedMap = 'embedMap',
  restrictZoomLevels = 'restrictZoomLevels',
  showHideDataOnMarker = 'showHideDataOnMarker',
  showHideDataFromExport = 'showHideDataFromExport',
  showHideFilterColumns = 'showHideFilterColumns',
  presentationalDataExportAuthorize = 'presentationalDataExportAuthorize',
  presentationalDataExportAllowTools = 'presentationalDataExportAllowTools',
}
