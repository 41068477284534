import { useCallback } from 'react';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';

export const useBoundaryGroupUsage = () => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();

  const isBoundaryGroupUsedOnMap = useCallback((boundaryGroupId: number) => {
    for (const boundaryGroup of boundaryTerritoryGroups) {
      if (boundaryGroup.boundaryGroupId === boundaryGroupId) {
        return true;
      }
    }

    return false;
  }, [boundaryTerritoryGroups]);

  return {
    isBoundaryGroupUsedOnMap,
  };
};
