import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { LottieAnimationComponent } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { type MarkerEntityOffsets } from '~/_shared/utils/markers/offsets.helpers';
import { lottieActiveMarkerIndicatorProps } from './activeMarkerIndicator.constants';
import { type LottieActiveMarkerIndicator } from './activeMarkerIndicator.types';

const positionContainerStyle = ({ size, offset }: {
  size: number;
  offset?: {
    left: number;
    top: number;
  };
}) => css({
  height: size,
  left: offset ? offset.left - size / 2 : 0,
  position: 'relative',
  top: offset ? -offset.top - size : 0,
  width: size,
});

type ActiveMarkerLottieIndicatorComponentProps = {
  color: string;
  indicator: LottieActiveMarkerIndicator;
  offsets?: MarkerEntityOffsets;
  size: number;
  speed: number;
};

export const ActiveMarkerLottieIndicatorComponent: FC<ActiveMarkerLottieIndicatorComponentProps> = props => {
  const { color, indicator, size } = props;

  const animationProps = useMemo(() => {
    const maxSpeed = (lottieActiveMarkerIndicatorProps[indicator].speed ?? 1) * 2;
    return {
      ...lottieActiveMarkerIndicatorProps[indicator],
      ...{ size: lottieActiveMarkerIndicatorProps[indicator].size * (size / 100) },
      ...{ speed: maxSpeed * ((props.speed ?? 50) / 100) },
    };
  }, [indicator, props.speed, size]);

  const animationConfig = useMemo(() => animationProps.configFactory(color), [animationProps, color]);

  const offset = useMemo(() => {
    if (!props.offsets) {
      return;
    }

    let offset = { ...(animationProps.offset ?? { top: 0, left: 0 }) };

    if (animationProps.position === 'above') {
      offset = {
        top: offset.top + props.offsets.topOffset,
        left: offset.left + props.offsets.leftOffset,
      };
    }

    return offset;
  }, [animationProps.offset, animationProps.position, props.offsets]);

  return (
    <div css={positionContainerStyle({ size: animationProps.size, offset })} >
      <LottieAnimationComponent
        autoplay
        loop
        {...animationConfig}
        size={animationProps.size}
        segment={animationProps.segment}
        view={animationProps.view}
        speed={animationProps.speed}
        bothDirections={animationProps.bothDirections}
      />
    </div>
  );
};
