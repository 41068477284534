import { type DeepPartial } from 'ts-essentials';
import {
  RANGE_DEFAULT_HIGH_VALUE_COLOR, RANGE_DEFAULT_LOW_VALUE_COLOR, RANGE_DEFAULT_MEDIUM_VALUE_COLOR,
} from '~/_shared/constants/rangeColors';
import { calculateGroupColor } from '~/boundary/settings/boundarySettings.helpers';
import {
  isGroupLabelCustomizationDisabled, isGroupPrimary,
} from '~/grouping/groupingColumns.helpers';
import { type GetMarkerVisualWithBakedDataProps } from '~/grouping/useGroupColumnData';
import { type FileUrl } from '~/store/frontendState/fileUrls/fileUrls.state';
import { type MapSettingsFileAttachmentsMap } from '~/store/mapSettings/fileAttachments/fileAttachments.state';
import { type MapSettingsGroupingState } from '~/store/mapSettings/grouping/mapSettingsGrouping.state';
import { type MapSettingsMarkersGeneralState } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.state';
import {
  getItemNumericBucketFromBuckets, NON_NUMERICAL_COLOR, NON_NUMERICAL_VALUE, type NumericBucketWithRange,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '~/store/spreadsheetData/spreadsheetData.state';
import { createColor } from '../../components/colorPicker/colorPicker.helpers';
import { PRESET_COLORS_PRIMARY } from '../../constants/colors.constants';
import { type FileAttachment } from '../../types/file.types';
import {
  type BucketKey, type GroupingColumn, type GroupingColumnNumeric, GroupingType, type GroupMarkerSettings,
  isGroupingColumnNumeric, NumericActiveGroupColumnType, type TextGroupKey,
} from '../../types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '../../types/grouping/numericActiveGroupColumnType.helpers';
import {
  isStandardMarkerVisualSettings, type MarkerAnchorPosition, MarkerStyleType, type MarkerVisualSettings, type StandardMarkerVisualSettings, type TemporaryMarkerVisualSettings,
} from '../../types/marker.types';
import { MarkerSettingType } from '../../types/markers/visualSettings.enums';
import {
  type ColumnMarkerSettings, type LabelVisualSetting, type MarkerSettings, type MarkersVisualSettings,
  type MarkersVisualSettingsLabelFlags,
} from '../../types/markers/visualSettings.types';
import { type SpreadsheetColumnId } from '../../types/spreadsheetData/spreadsheetColumn';
import { type SpreadsheetRowId } from '../../types/spreadsheetData/spreadsheetRow';
import {
  filterNonNumericBuckets, getUniqueGroupId,
} from '../grouping/grouping.helpers';
import { mergeDeep } from '../object/deepMerge';
import {
  NUMERICAL_MARKER_SIZE_DEFAULT_MAX, NUMERICAL_MARKER_SIZE_DEFAULT_MIN,
} from './markers.constants';
import {
  DEFAULT_MARKER_SIZE, DEFAULT_MARKER_STYLE_ID, DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB,
  DEFAULT_NUMERIC_GREEN_MARKER_COLOR, DEFAULT_NUMERIC_ROUND_MARKER_OPACITY, DEFAULT_STANDARD_MARKER_OPACITY,
  DEFAULT_TEXT_MARKER_OPACITY, GROUPING_SECONDARY_MARKER_SUFFIX, ID_OF_GROWING_NUMERIC_MARKER, ID_OF_NUMERICAL_MARKER,
  ID_OF_NUMERICAL_MARKER_CENTRE, ID_OF_SECONDARY_GROUP_TEXT_MARKER, INITIAL_LABEL, LEGEND_MARKER_SIZE,
  MARKER_DEFAULT_COLOR,
} from './markerVisualSettings.constants';

export const getInitialMarker = (markerSetId: number): StandardMarkerVisualSettings => ({
  labelText: '',
  selectedColor: MARKER_DEFAULT_COLOR,
  size: DEFAULT_MARKER_SIZE,
  styleId: markerSetId,
  styleType: MarkerStyleType.STANDARD,
});

export type MarkersVisualsFunctionProps = Readonly<{
  bucketId?: number;
  groupId?: string;
  hierarchyIndicator?: number;
  isLegend: boolean;
  mapSettingsGroupingState: MapSettingsGroupingState;
  numericBucketsWithRange?: NumericBucketWithRange[];
  spreadsheetData?: SpreadsheetDataData;
  spreadsheetRowId?: SpreadsheetRowId;
  visualMarkersSettings: MarkersVisualSettings;
  visualSettingsType?: MarkerSettingType;
  fileAttachments: ReadonlyMap<string, FileAttachment>;
  files: ReadonlyMap<number, FileUrl>;
}>;

export const getHierarchyIndicatorIndex = <T extends SpreadsheetColumnId>(activeGroupColumns: T[], requestedColumn: T): number => {
  let hierarchyIndicator = activeGroupColumns
    .findIndex((column: T) =>
      column.columnId === requestedColumn.columnId && column.spreadsheetId === requestedColumn.spreadsheetId,
    );

  if (hierarchyIndicator === -1) {
    hierarchyIndicator = activeGroupColumns.length > 1 ? 1 : activeGroupColumns.length;
  }

  return hierarchyIndicator;
};

export const generateMarkersVisualsWithBakedDataGetter = (data: {
  groupingColumns: GroupingColumn[];
  requestedNumericColumn: GroupingColumnNumeric;
  visualMarkersSettings: MapSettingsMarkersGeneralState;
  fileAttachments: ReadonlyMap<string, FileAttachment>;
  files: ReadonlyMap<number, FileUrl>;
}) => {
  const { groupingColumns, requestedNumericColumn, visualMarkersSettings, fileAttachments, files } = data;

  const hierarchyIndicator = getHierarchyIndicatorIndex(groupingColumns, requestedNumericColumn);

  const newActiveColumns = [...groupingColumns];
  newActiveColumns[hierarchyIndicator] = requestedNumericColumn;

  const getMarkersVisualsWithBakedData = (additionalData: GetMarkerVisualWithBakedDataProps): MarkerSettings => {
    const visualSettingsBadColor = getMarkerVisuals({
      bucketId: additionalData.bucketId,
      hierarchyIndicator,
      isLegend: false,
      mapSettingsGroupingState: { activeGroupColumns: newActiveColumns },
      numericBucketsWithRange: additionalData.buckets,
      visualMarkersSettings,
      fileAttachments, files,
    });

    const partialSettingsWithColor: DeepPartial<MarkerSettings> = {
      marker: {
        ...(additionalData.opacity !== null ? { opacity: additionalData.opacity } : {}),
        ...(additionalData.selectedColor !== null ? { selectedColor: additionalData.selectedColor } : {}),
        size: determineBucketSize({
          index: additionalData.bucketId,
          numberOfRanges: additionalData.buckets?.length ?? 1,
        }),
      },
      label: {
        bodyProps: {
          backgroundColor: {
            ...(additionalData.opacity !== null ? { opacity: additionalData.opacity } : {}),
            ...(additionalData.selectedColor !== null ? { selectedColor: additionalData.selectedColor } : {}),
          },
        },
      },
      useMarker: hierarchyIndicator !== 0,
    };

    return mergeDeep({}, visualSettingsBadColor, partialSettingsWithColor);
  };

  return getMarkersVisualsWithBakedData;
};

export const getMarkerVisuals = ({
  groupId,
  bucketId,
  mapSettingsGroupingState,
  numericBucketsWithRange,
  visualSettingsType,
  spreadsheetData,
  visualMarkersSettings,
  hierarchyIndicator,
  spreadsheetRowId,
  isLegend,
  fileAttachments,
  files,
}: MarkersVisualsFunctionProps): MarkerSettings => {
  const markerLabelsDisabled = areMarkerLabelsDisabled(visualMarkersSettings);

  const finalizeVisualSettings = (preFinalVisualMarkerSettings: MarkerSettings, forceMarker = false) => {
    const settingsWithLabel: MarkerSettings = {
      ...preFinalVisualMarkerSettings,
      aboveLabel: visualMarkersSettings.globalMarkerSettings?.aboveLabel || INITIAL_LABEL,
    };

    if (markerLabelsDisabled || forceMarker) {
      return {
        ...settingsWithLabel,
        useMarker: true,
      };
    }

    return settingsWithLabel;
  };

  const checkForSeekedSettings = (settings: MarkerSettings) =>
    handleReturnRelevantSettings(settings, markerLabelsDisabled, visualSettingsType);
  const isMarkerTextureAvailable = (markerSettings: MarkerSettings) => (
    markerLabelsDisabled && markerSettings.marker ?
      isTextureAvailable(markerSettings.marker, fileAttachments, files) :
      true
  );

  hierarchyIndicator = hierarchyIndicator ?? 0;
  if (spreadsheetRowId && hierarchyIndicator === 0) {
    const { rowId, spreadsheetId } = spreadsheetRowId;
    const individualMarkerSettings = visualMarkersSettings.individualMarkerSettings?.[spreadsheetId]?.[rowId];

    if (individualMarkerSettings && checkForSeekedSettings(individualMarkerSettings)
      && isMarkerTextureAvailable(individualMarkerSettings)) {
      return finalizeVisualSettings(individualMarkerSettings);
    }
  }

  const activeGroupColumns = mapSettingsGroupingState.activeGroupColumns;
  const activeGroupColumn = activeGroupColumns[hierarchyIndicator];
  if (activeGroupColumns.length >= hierarchyIndicator + 1 && activeGroupColumn) {
    const columnMarkerSettings = getColumnGroupMarkerVisualSettings(visualMarkersSettings, activeGroupColumn);

    //This section handles the looking up of groupId and groupIndex, or bucketId
    let groupIndex: number | undefined;
    if (spreadsheetRowId && activeGroupColumn.columnId) {
      const { rowId, spreadsheetId } = spreadsheetRowId;
      const spreadSheetDataItem = spreadsheetData?.values[spreadsheetId]?.[Unfiltered]?.[activeGroupColumn.columnId];

      if (activeGroupColumn.type === GroupingType.Text) {
        const dataItem = spreadSheetDataItem?.[DataType.GROUP];
        groupIndex = dataItem?.values[rowId];

        groupId = dataItem?.extra?.uniqueGroups?.[groupIndex ?? 0]?.id;
      }

      if (activeGroupColumn.type === GroupingType.Numeric && numericBucketsWithRange) {
        const dataItem = spreadSheetDataItem?.[DataType.NUMBER];
        const value = dataItem?.values[rowId];

        if (dataItem && numericBucketsWithRange) {
          bucketId = getItemNumericBucketFromBuckets(
            value ?? null,
            activeGroupColumn.valueType,
            dataItem.extra.min,
            dataItem.extra.max,
            numericBucketsWithRange,
          ).id;
        }
      }
    }
    else if (activeGroupColumn.type === GroupingType.Text && groupId !== undefined) {
      const spreadSheetDataItem = spreadsheetData?.values[activeGroupColumn.spreadsheetId]?.[Unfiltered]?.[activeGroupColumn.columnId];
      const dataItem = spreadSheetDataItem?.[DataType.GROUP];
      //TODO: Might need to optimize if the number of groups exceeds 500. --use a hook and cache unique groups
      groupIndex = dataItem?.extra?.uniqueGroups?.findIndex(g => g.id === groupId);
    }

    const numericActiveGroupColumnType = getNumericActiveGroupColumnType(activeGroupColumns);
    const specificColumnMarkerSettings = getGroupedMarkerSettings({
      columnMarkerSettings, type: activeGroupColumn.type, groupId, bucketId, hierarchyIndicator,
      numberOfBuckets: numericBucketsWithRange?.length, numericActiveGroupColumnType,
    });

    const defaultGroupsSettings = getDefaultMarkerSettingsForGroup({
      activeGroupColumns, divisionIndex: groupIndex ?? bucketId, isLegend,
      hierarchyIndicator, type: activeGroupColumn.type, visualMarkersSettings, buckets: numericBucketsWithRange,
    });

    if (specificColumnMarkerSettings && checkForSeekedSettings(specificColumnMarkerSettings)
      && isMarkerTextureAvailable(specificColumnMarkerSettings)) {
      const forceMarker = isGroupLabelCustomizationDisabled(activeGroupColumn, hierarchyIndicator);
      return finalizeVisualSettings({
        ...specificColumnMarkerSettings,
        label: !visualSettingsType && !specificColumnMarkerSettings.label ? defaultGroupsSettings?.label : specificColumnMarkerSettings.label,
        marker: !visualSettingsType && !specificColumnMarkerSettings.marker ? defaultGroupsSettings?.marker : specificColumnMarkerSettings.marker,
      }, forceMarker);
    }

    // No custom style was set for the specific marker nor its group => return the default set style for all markers in groups
    if (defaultGroupsSettings && checkForSeekedSettings(defaultGroupsSettings)
      && isMarkerTextureAvailable(defaultGroupsSettings)) {
      const forceMarker = isGroupLabelCustomizationDisabled(activeGroupColumn, hierarchyIndicator);
      return finalizeVisualSettings(defaultGroupsSettings, forceMarker);
    }
  }

  if (visualMarkersSettings.globalMarkerSettings && checkForSeekedSettings(visualMarkersSettings.globalMarkerSettings)
    && isMarkerTextureAvailable(visualMarkersSettings.globalMarkerSettings)) {
    return finalizeVisualSettings(visualMarkersSettings.globalMarkerSettings);
  }
  return {
    marker: getInitialMarker(visualMarkersSettings.defaultMarkerSetId || DEFAULT_MARKER_STYLE_ID),
    label: INITIAL_LABEL,
    aboveLabel: INITIAL_LABEL,
    useMarker: markerLabelsDisabled,
  };
};

type DefaultMarkerData = Readonly<{
  activeGroupColumns: GroupingColumn[];
  index?: number;
  numberOfRanges?: number;
  type?: GroupingType;
  visualMarkersSettings: MarkersVisualSettings;
}>;

const DEFAULT_ROUND_NUMERIC_COLOR_WITH_OPACITY = {
  selectedColor: createColor(DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB).hex,
  opacity: DEFAULT_NUMERIC_ROUND_MARKER_OPACITY,
};

type MarkerIdAndColor = Readonly<{
  id: number;
  selectedColor?: string;
  opacity?: number;
}>;
const getDefaultMarkerIdAndColorOfFirstGroup = (
  { activeGroupColumns, index, numberOfRanges, type, visualMarkersSettings }: DefaultMarkerData,
): MarkerIdAndColor | null => {
  if (type === GroupingType.Text) {
    return { id: visualMarkersSettings.defaultMarkerSetId };
  }

  if (type === GroupingType.Numeric) {
    const isLastOfNumeric = index === NON_NUMERICAL_VALUE;
    const selectedNumericalMarkerColor = calculateGroupColor(
      index || 0,
      numberOfRanges || 2,
      RANGE_DEFAULT_LOW_VALUE_COLOR,
      RANGE_DEFAULT_MEDIUM_VALUE_COLOR,
      RANGE_DEFAULT_HIGH_VALUE_COLOR,
      DEFAULT_ROUND_NUMERIC_COLOR_WITH_OPACITY.opacity,
    );

    if (isLastOfNumeric) {
      return { id: ID_OF_NUMERICAL_MARKER_CENTRE, selectedColor: NON_NUMERICAL_COLOR };
    }

    if (activeGroupColumns.length === 1) {
      return {
        id: ID_OF_NUMERICAL_MARKER,
        selectedColor: selectedNumericalMarkerColor.hex,
        opacity: selectedNumericalMarkerColor.rgb.a,
      };
    }

    if (activeGroupColumns.length === 2) {
      if (activeGroupColumns[1]?.type === GroupingType.Text) {
        return {
          id: ID_OF_NUMERICAL_MARKER,
          selectedColor: selectedNumericalMarkerColor.hex,
          opacity: selectedNumericalMarkerColor.rgb.a,
        };
      }
      if (activeGroupColumns[1]?.type === GroupingType.Numeric) {
        return { id: ID_OF_GROWING_NUMERIC_MARKER, selectedColor: DEFAULT_NUMERIC_GREEN_MARKER_COLOR };
      }
    }
  }

  return null;
};

const getDefaultMarkerIdAndColorOfSecondGroup = (
  { activeGroupColumns, index, numberOfRanges, type, visualMarkersSettings }: DefaultMarkerData,
): MarkerIdAndColor | null => {
  if (type === GroupingType.Numeric) {
    const isLastOfNumeric = index === NON_NUMERICAL_VALUE;
    const selectedNumericalMarkerColor = calculateGroupColor(
      index || 0,
      numberOfRanges || 2,
      RANGE_DEFAULT_LOW_VALUE_COLOR,
      RANGE_DEFAULT_MEDIUM_VALUE_COLOR,
      RANGE_DEFAULT_HIGH_VALUE_COLOR,
      DEFAULT_ROUND_NUMERIC_COLOR_WITH_OPACITY.opacity,
    );

    if (isLastOfNumeric) {
      return { id: ID_OF_NUMERICAL_MARKER_CENTRE, selectedColor: NON_NUMERICAL_COLOR };
    }
    return {
      id: ID_OF_NUMERICAL_MARKER,
      selectedColor: selectedNumericalMarkerColor.hex,
      opacity: selectedNumericalMarkerColor.rgb.a,
    };
  }

  if (type === GroupingType.Text) {
    if (activeGroupColumns[0]?.type === GroupingType.Text) {
      return { id: getDefaultStyleIdForSecondaryTextGroup(visualMarkersSettings), opacity: DEFAULT_TEXT_MARKER_OPACITY };
    }
    if (activeGroupColumns[0]?.type === GroupingType.Numeric) {
      return { id: visualMarkersSettings.defaultMarkerSetId };
    }
  }

  return null;
};

export const getDefaultStyleIdForSecondaryTextGroup = (visualMarkersSettings: MarkersVisualSettings): number => {
  const defaultSecondaryTextMarker = visualMarkersSettings.groupMarkerDefaultMarkerSetIds?.[1]?.[GroupingType.Text]?.styleId;
  return defaultSecondaryTextMarker || ID_OF_SECONDARY_GROUP_TEXT_MARKER;
};

const getDefaultMarkerSettingsForGroup = (data: {
  activeGroupColumns: GroupingColumn[];
  buckets?: NumericBucketWithRange[];
  divisionIndex?: number;
  isLegend: boolean;
  hierarchyIndicator?: number;
  type?: GroupingType;
  visualMarkersSettings: MarkersVisualSettings;
}): MarkerSettings | undefined => {
  const { activeGroupColumns, buckets, divisionIndex, hierarchyIndicator, type, visualMarkersSettings, isLegend } = data;

  if (divisionIndex === undefined) {
    return;
  }

  let relevantMarkerProps: MarkerIdAndColor | null = null;
  let markerSize = DEFAULT_MARKER_SIZE;
  const numberOfRanges = filterNonNumericBuckets(buckets).length;

  const markerData: DefaultMarkerData = {
    activeGroupColumns,
    index: divisionIndex,
    numberOfRanges,
    visualMarkersSettings,
    type,
  };

  if (!hierarchyIndicator) {
    relevantMarkerProps = getDefaultMarkerIdAndColorOfFirstGroup(markerData);
  }

  if (hierarchyIndicator === 1) {
    relevantMarkerProps = getDefaultMarkerIdAndColorOfSecondGroup(markerData);
  }

  if (relevantMarkerProps) {
    const selectedColor = relevantMarkerProps.selectedColor || PRESET_COLORS_PRIMARY[divisionIndex % PRESET_COLORS_PRIMARY.length] || '#000'; //for TS; the black color would never happen

    if (type === GroupingType.Numeric && buckets) {
      markerSize = determineBucketSize({
        index: divisionIndex, maxSize: isLegend ? LEGEND_MARKER_SIZE : undefined, numberOfRanges,
      });
    }
    else {
      markerSize = hierarchyIndicator === 0 ? (visualMarkersSettings.globalMarkerSettings?.marker?.size || DEFAULT_MARKER_SIZE) : DEFAULT_MARKER_SIZE;
    }

    const defaultMarkerLabelSettings = visualMarkersSettings.globalMarkerSettings?.label;

    return {
      ...visualMarkersSettings.globalMarkerSettings,
      useMarker: hierarchyIndicator !== 0,
      marker: {
        styleType: MarkerStyleType.STANDARD,
        size: markerSize,
        styleId: relevantMarkerProps.id,
        selectedColor,
        opacity: relevantMarkerProps.opacity ?? DEFAULT_STANDARD_MARKER_OPACITY,
      },
      label: {
        ...INITIAL_LABEL,
        ...(defaultMarkerLabelSettings ? { ...defaultMarkerLabelSettings } : {}),
        bodyProps: {
          ...INITIAL_LABEL.bodyProps,
          ...(defaultMarkerLabelSettings ? { ...defaultMarkerLabelSettings.bodyProps } : {}),
          backgroundColor: {
            ...INITIAL_LABEL.bodyProps.backgroundColor,
            selectedColor,
            opacity: relevantMarkerProps.opacity ?? defaultMarkerLabelSettings?.bodyProps.backgroundColor.opacity ?? INITIAL_LABEL.bodyProps.backgroundColor.opacity,
          },
        },
      },
    };
  }

  return undefined;
};

const getGroupedMarkerSettings = (data: {
  bucketId?: number;
  columnMarkerSettings?: ColumnMarkerSettings;
  groupId?: string;
  hierarchyIndicator?: number;
  numberOfBuckets?: number;
  numericActiveGroupColumnType: NumericActiveGroupColumnType | null;
  type?: GroupingType;
}): MarkerSettings | undefined => {
  const { columnMarkerSettings, type, groupId, bucketId, numberOfBuckets, numericActiveGroupColumnType } = data;

  if (!type || !columnMarkerSettings || (groupId === undefined && bucketId === undefined)) {
    return undefined;
  }
  if (type === GroupingType.Numeric && bucketId !== undefined && numberOfBuckets && numericActiveGroupColumnType) {
    const locatingString = createNumericBucketKey(numericActiveGroupColumnType, data.hierarchyIndicator, numberOfBuckets);

    return columnMarkerSettings.numeric?.[locatingString]?.[bucketId];
  }

  if (type === GroupingType.Text && groupId !== undefined) {
    const isPrimaryGroup = isGroupPrimary(data.hierarchyIndicator);
    const textGroupKey = createTextGroupKey(groupId, isPrimaryGroup);

    return columnMarkerSettings.text?.[textGroupKey];
  }

  return undefined;
};

const handleReturnRelevantSettings = (
  settings: MarkerSettings, markerLabelsDisabled: boolean, neededType?: MarkerSettingType,
): MarkerSettings | null => {
  if (neededType === MarkerSettingType.LabelAbove) {
    return settings.aboveLabel ? settings : null;
  }

  if (neededType === MarkerSettingType.Label && !markerLabelsDisabled) {
    return settings.label ? settings : null;
  }

  return settings.marker || (settings.label && !markerLabelsDisabled) ? settings : null;
};

export const areMarkerLabelsDisabled = (flags: MarkersVisualSettingsLabelFlags): boolean =>
  !flags.useNumericLabel && !flags.useTextLabel;

export const getExistingNumericGroupVisualSettingsIfAny = (data: {
  groupingColumns: GroupingColumn[];
  bucketId: number;
  hierarchyIndicator?: number;
  numberOfBuckets?: number;
  visualMarkersSettings: MarkersVisualSettings;
}): MarkerSettings | null => {
  const column = data.groupingColumns[data.hierarchyIndicator || 0];

  if (!column) {
    return null;
  }

  const { type } = column;
  const columnVisuals = getColumnGroupMarkerVisualSettings(data.visualMarkersSettings, column);
  const numericGroupingType = getNumericActiveGroupColumnType(data.groupingColumns);

  let groupVisuals: MarkerSettings | undefined;

  const commonProps = {
    columnMarkerSettings: columnVisuals,
    hierarchyIndicator: data.hierarchyIndicator,
    numericActiveGroupColumnType: numericGroupingType,
    type,
  };

  if (type === GroupingType.Numeric) {
    const { buckets } = column;

    groupVisuals = getGroupedMarkerSettings({
      ...commonProps,
      bucketId: data.bucketId,
      numberOfBuckets: data.numberOfBuckets ?? buckets.length + 1,
    });
  }

  return groupVisuals || null;
};

const getColumnGroupMarkerVisualSettings = (
  visualMarkersSettings: MarkersVisualSettings, activeGroupColumn: GroupingColumn,
): ColumnMarkerSettings | undefined => {
  const { spreadsheetId, columnId } = activeGroupColumn;

  return visualMarkersSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId];
};

const determineBucketSize = (data: {
  index: number; maxSize?: number; minSize?: number; numberOfRanges: number;
}): number => {
  const minSize = data.minSize ?? NUMERICAL_MARKER_SIZE_DEFAULT_MIN;
  const maxSize = data.maxSize ?? NUMERICAL_MARKER_SIZE_DEFAULT_MAX;
  const { index, numberOfRanges } = data;
  const sizeStep = (maxSize - minSize) / (numberOfRanges - 1);

  if (index === NON_NUMERICAL_VALUE) {
    return maxSize / 2;
  }
  return Math.floor(index * sizeStep + minSize);
};

export const createNumericBucketKey = (
  columnType: NumericActiveGroupColumnType,
  hierarchyIndicator: number | undefined,
  numberOfBuckets: number,
): BucketKey => {
  return `${columnType}-${hierarchyIndicator || 0}-${numberOfBuckets}`;
};

export const createTextGroupKey = (
  groupId: string,
  isPrimaryGroup: boolean,
): TextGroupKey => `${groupId}${!isPrimaryGroup ? `-${GROUPING_SECONDARY_MARKER_SUFFIX}` : ''}`;

export const getTextGroupKeyHierarchyIndicator = (textGroupKey: TextGroupKey): number => {
  const parts = textGroupKey.split('-');
  if (parts[1] === GROUPING_SECONDARY_MARKER_SUFFIX) {
    return 1;
  }
  return 0;
};

export const getNumericBucketKeyForGroupingColumn = (groupingColumns: readonly GroupingColumn[], hierarchyIndicator: number): string => {
  const groupingColumn = groupingColumns[hierarchyIndicator];
  const numericColumnType = getNumericActiveGroupColumnType(groupingColumns) ?? NumericActiveGroupColumnType.SingleNumeric;
  const noOfBuckets = groupingColumn && isGroupingColumnNumeric(groupingColumn) ? groupingColumn.buckets.length + 1 : 1;
  return createNumericBucketKey(numericColumnType, hierarchyIndicator, noOfBuckets);
};

export const getTextGroupingColumnGroupVisuals = (
  uniqueGroupIndex: number,
  groupingColumns: readonly GroupingColumn[],
  hierarchyIndicator: number,
  groupMarkerVisualSettings: GroupMarkerSettings,
  spreadsheetData: SpreadsheetDataData,
): MarkerSettings | undefined => {
  const groupingColumn = groupingColumns[hierarchyIndicator];
  if (groupingColumn) {
    const columnVisuals = groupMarkerVisualSettings[groupingColumn.spreadsheetId]?.[groupingColumn.columnId];
    const groupId = getUniqueGroupId(uniqueGroupIndex, groupingColumn, spreadsheetData);
    return groupId ? columnVisuals?.[GroupingType.Text]?.[createTextGroupKey(groupId, !hierarchyIndicator)] : undefined;
  }
  return undefined;
};

export const getNumberGroupingColumnAllBucketsVisuals = (
  groupingColumns: readonly GroupingColumn[],
  hierarchyIndicator: number,
  groupMarkerVisualSettings: GroupMarkerSettings,
): { [bucketId: number]: MarkerSettings | undefined } | undefined => {
  const groupingColumn = groupingColumns[hierarchyIndicator];
  if (groupingColumn) {
    const columnVisuals = groupMarkerVisualSettings[groupingColumn.spreadsheetId]?.[groupingColumn.columnId];
    const bucketKey = getNumericBucketKeyForGroupingColumn(groupingColumns, hierarchyIndicator);
    return columnVisuals?.[GroupingType.Numeric]?.[bucketKey];
  }
  return undefined;
};

export const syncMarkerColorAndLabelBackgroundVisualSettings = (markerSettings: MarkerSettings) => {
  const markerColor = markerSettings.marker?.selectedColor;
  const labelColorSettings: DeepPartial<LabelVisualSetting> = {
    bodyProps: {
      backgroundColor: {
        selectedColor: markerColor,
      },
    },
  };
  const labelSettings = mergeDeep<LabelVisualSetting>({}, markerSettings.label, labelColorSettings);
  const synchronizedLabelSettings: LabelVisualSetting | undefined = markerColor ? labelSettings : undefined;
  return {
    ...markerSettings,
    ...(synchronizedLabelSettings ? { label: synchronizedLabelSettings } : {}),
  };
};

export const removeAboveLabelVisualSettings = (markerSettings: MarkerSettings): MarkerSettings => ({
  ...markerSettings,
  aboveLabel: undefined,
});

export const markerVisualSettingsToTemporaryMarkerVisualSettings = (
  markerVisualSettings: MarkerVisualSettings,
  fileAttachments: MapSettingsFileAttachmentsMap,
  getMarkerTextureAnchor: (fileAttachmentId: string) => MarkerAnchorPosition
): TemporaryMarkerVisualSettings => {
  if (isStandardMarkerVisualSettings(markerVisualSettings)) {
    return markerVisualSettings;
  }
  else {
    const fileAttachment = fileAttachments.get(markerVisualSettings.fileAttachmentId);
    if (fileAttachment) {
      return {
        ...markerVisualSettings,
        fileId: fileAttachment.fileId,
        anchor: getMarkerTextureAnchor(fileAttachment.id),
      };
    }
    else {
      return {
        ...markerVisualSettings,
        styleType: MarkerStyleType.STANDARD,
        styleId: DEFAULT_MARKER_STYLE_ID,
      };
    }
  }
};

const isTextureAvailable = (markerVisualSettings: MarkerVisualSettings,
  fileAttachments: ReadonlyMap<string, FileAttachment>, files: ReadonlyMap<number, FileUrl>): boolean => {
  if (markerVisualSettings.styleType !== MarkerStyleType.CUSTOM) {
    return true;
  }
  const attachmentId = markerVisualSettings.fileAttachmentId;
  const attachment = fileAttachments.get(attachmentId);
  if (attachment && files.has(attachment.fileId)) {
    return true;
  }
  return false;
};

export const getDefaultStandardMarkerColor = () => (MARKER_DEFAULT_COLOR);

/*
 * This should be only called if the mechanism responsible for drawing custom marker textures doesn't
 * find the custom marker's file url.
 *
 * As this is taken care of by getMarkerVisuals function before, this is very unlikely to happen and if this is for some reason
 * called, we need to troubleshoot and understand why. Nevertheless, since it in theory can happen, we need this fallback.
 */
export const getFallbackStandardMarkerSettings =
  (generalMarkerSettings: MapSettingsMarkersGeneralState): StandardMarkerVisualSettings => ({
    styleId: generalMarkerSettings.defaultMarkerSetId ?? DEFAULT_MARKER_STYLE_ID,
    styleType: MarkerStyleType.STANDARD,
    size: generalMarkerSettings.globalMarkerSettings?.marker?.size ?? DEFAULT_MARKER_SIZE,
    selectedColor: generalMarkerSettings.globalMarkerSettings?.marker?.selectedColor ?? MARKER_DEFAULT_COLOR,
    opacity: generalMarkerSettings.globalMarkerSettings?.marker?.opacity ?? DEFAULT_STANDARD_MARKER_OPACITY,
  });

export const transformMarkerVisualSettingsForHTMLVisualiser = (visualSettings: MarkerSettings): MarkerSettings => {
  const { label } = visualSettings;

  const labelDimensions = label?.bodyProps.dimensions;
  const newLabelWidth = labelDimensions?.width ? labelDimensions?.width / 2 : labelDimensions?.height;

  if (!newLabelWidth) {
    return visualSettings;
  }

  const complementWithNewLabelWidth: DeepPartial<LabelVisualSetting> = {
    bodyProps: {
      dimensions: { width: newLabelWidth },
      fontSize: 12,
    },
  };

  const newLabel: LabelVisualSetting | undefined = mergeDeep({}, label, complementWithNewLabelWidth);

  return {
    ...visualSettings,
    label: newLabel,
  };
};
