import { type FC } from 'react';
import { AnchorLink } from '~/_shared/baseComponents/links';
import { LINK_TO_V4_ACCOUNT_SETTINGS } from '~/_shared/constants/maptive';
import {
  s, Trans,
} from '~/translations/Trans';

export const AccountSettingsUnavailableBodyComponent: FC = () => (
  <Trans i18nKey="AccountSettingsLockInfo">
    {s('Please use the ')}
    <AnchorLink
      href={LINK_TO_V4_ACCOUNT_SETTINGS}
      target="_blank"
    >
      {s('previous')}
    </AnchorLink>
    {s(' version of Maptive to make any account settings changes, as this functionality is temporarily unavailable in the current version you are using.')}
  </Trans>
);
