import {
  type FC, memo, useCallback, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ghostOutlineHoverVisuals,
  ghostOutlineVisuals,
  outlineHoverVisuals,
  outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { MapShapePolygon } from '~/map/map/mapObjects/mapShape/mapShapePolygon.component';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { type LatLng } from '../../../../_shared/types/latLng';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { drawingEditPushNewSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered, drawingToolItemHoverStopped,
  drawingToolItemMoveStarted, drawingToolItemMoveStopped,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemPolygon } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import {
  removeButtonHoverVisuals, removeButtonVisuals,
} from '../../boundary/boundaryDragEdit/boundaryDragEdit.component';
import { useMap } from '../../mapContext';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import {
  EditMapShapeContainer, type OutlineCallbackProps,
} from '../../mapObjects/mapShape/editMapShape.container';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import { MapShapeLine } from '../../mapObjects/mapShape/mapShapeLine.component';
import {
  cloneMapShape, type MapShapeInstance,
} from '../../mapObjects/mapShape/mapShapeModel';
import { MapShapeRemoveButton } from '../../mapObjects/mapShape/mapShapeRemoveButton.component';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';

type MapCircleInstanceProps = {
  instance: DrawingItemPolygon;
};

const DrawingToolPolygonSelectedInstanceContainer: FC<MapCircleInstanceProps> = (props) => {
  const { instance: { id, settings, outlines: initialOutlines } } = props;
  const [isOutlineDragging, setIsOutlineDragging] = useState(false);
  const [isPolygonDragging, setIsPolygonDragging] = useState(false);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<null | Uuid>(null);
  const [removeHovered, setRemoveHovered] = useState(false);
  const [outlines, setOutlines] = useState(initialOutlines);
  const dragStartDataRef = useRef<{ position: LatLng; outlines: MapObjectOutlineInstance[] } | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const map = useMap();
  const { fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom } = useProjectionOverlay(map);
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (drawingEventsEnabledRef.current) {
      setHoveredOutlineId(outlineId);
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onOutlineMouseOut = useCallback(() => {
    setHoveredOutlineId(null);
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  const onOutlineDragStart = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      setIsOutlineDragging(true);
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onOutlineDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      setIsOutlineDragging(false);
      dispatch(drawingToolItemMoveStopped());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onRemoveMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      setRemoveHovered(true);
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onRemoveMouseOut = useCallback(() => {
    setRemoveHovered(false);
    dispatch(drawingToolItemHoverStopped());
  }, [dispatch]);

  const onPolygonDragStart = useCallback((position: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      dragStartDataRef.current = { position, outlines };
      setIsPolygonDragging(true);
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef, outlines]);

  const onPolygonDragMove = useCallback((position: LatLng) => {
    if (!dragStartDataRef.current) {
      return;
    }

    const { position: startPosition, outlines } = dragStartDataRef.current;

    const offsetStartPoint = fromLatLngToDivPixel(startPosition);
    const offsetEndPoint = fromLatLngToDivPixel(position);

    if (!offsetStartPoint || !offsetEndPoint) {
      return;
    }

    const offsetPoint = new google.maps.Point(
      offsetEndPoint.x - offsetStartPoint.x,
      offsetEndPoint.y - offsetStartPoint.y
    );

    if (!offsetPoint) {
      return;
    }

    setOutlines(outlines.map(outline => {
      const newOutlinePosition = moveLatLngByPixelsOnCurrentZoom(outline, offsetPoint);

      if (!newOutlinePosition) {
        return outline;
      }

      return {
        id: outline.id,
        lat: newOutlinePosition.lat,
        lng: newOutlinePosition.lng,
      };
    }));
  }, [fromLatLngToDivPixel, moveLatLngByPixelsOnCurrentZoom]);

  const onPolygonMouseOver = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHovered());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onPolygonMouseOut = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingToolItemHoverStopped());
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onMapShapeChange = useCallback((shape: MapShapeInstance) => {
    setOutlines(shape.outlines);

    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Polygon,
      value: {
        ...props.instance,
        outlines: cloneMapShape(shape).outlines,
      },
    }));
  }, [dispatch, props.instance]);

  const onPolygonDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      onMapShapeChange({
        id,
        outlines,
      });

      setIsPolygonDragging(false);
      dispatch(drawingToolItemMoveStopped());
    }
  }, [drawingEventsEnabledRef, onMapShapeChange, id, outlines, dispatch]);

  const renderOutline = useCallback((
    id: string, outline: MapObjectOutlineInstance, isGhost: boolean, callbacks: OutlineCallbackProps
  ) => {
    const visuals = isGhost
      ? (hoveredOutlineId === id ? ghostOutlineHoverVisuals : ghostOutlineVisuals)
      : (hoveredOutlineId === id ? outlineHoverVisuals : outlineVisuals);

    return (
      <MapOutline
        key={id}
        outline={outline}
        visuals={visuals}
        onDragStart={() => {
          callbacks.onDragStart();
          onOutlineDragStart();
        }}
        onDragMove={(_, latLng) => callbacks.onDragMove(latLng)}
        onDragEnd={() => {
          callbacks.onDragEnd();
          onOutlineDragEnd();
        }}
        onMouseOver={(outlineId) => {
          callbacks.onOutlineMouseOver();
          onOutlineMouseOver(outlineId);
        }}
        onMouseOut={onOutlineMouseOut}
      />
    );
  }, [hoveredOutlineId, onOutlineMouseOver, onOutlineMouseOut, onOutlineDragStart, onOutlineDragEnd]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => {
    const borderColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    return (
      <MapShapeLine
        key={id}
        id={id}
        start={start}
        end={end}
        visuals={{
          width: settings.strokeWeight,
          style: 'solid',
          color: borderColor,
        }}
      />
    );
  }, [settings.strokeWeight, settings.strokeOpacity, settings.strokeColor]);

  const renderRemoveButton = useCallback((onClick: () => void, anchorOutlineId: Uuid) => {
    return (
      <MapShapeRemoveButton
        visuals={removeHovered ? removeButtonHoverVisuals : removeButtonVisuals}
        anchorOutlineId={anchorOutlineId}
        onMouseOut={onRemoveMouseOut}
        onMouseOver={onRemoveMouseOver}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
    );
  }, [onRemoveMouseOut, onRemoveMouseOver, removeHovered]);

  const renderPolygon = useCallback(() => {
    const fillColor = convertColorToWebGLColor(settings.fillColor, settings.fillOpacity / 100);
    const borderColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    return (
      <MapShapePolygon
        visuals={{
          borderWidth: settings.strokeWeight,
          borderColor,
          fillColor,
        }}
        onClick={(e) => {
          if (!drawingEventsEnabledRef.current) {
            return;
          }

          e.stopPropagation();
        }}
        onDragStart={onPolygonDragStart}
        onDragMove={onPolygonDragMove}
        onDragEnd={onPolygonDragEnd}
        onMouseOver={onPolygonMouseOver}
        onMouseOut={onPolygonMouseOut}
      />
    );
  }, [drawingEventsEnabledRef, settings.fillOpacity, settings.fillColor, onPolygonDragStart,
    onPolygonDragMove, onPolygonDragEnd, settings.strokeWeight, settings.strokeColor,
    settings.strokeOpacity, onPolygonMouseOver, onPolygonMouseOut]);

  return (
    <>
      {!isOutlineDragging && (
        <MapShape
          shape={{ id, outlines }}
          zIndex={zIndex}
          isPolygon
          renderPolygon={renderPolygon}
        />
      )}

      {!isPolygonDragging && (
        <EditMapShapeContainer
          key={id}
          isPolygon
          initialMapShape={{ id, outlines }}
          zIndex={zIndex}
          onChange={onMapShapeChange}
          renderOutline={renderOutline}
          renderLine={isOutlineDragging ? renderLine : undefined}
          renderRemoveButton={renderRemoveButton}
        />
      )}
    </>
  );
};

const pureComponent = memo(DrawingToolPolygonSelectedInstanceContainer);
export { pureComponent as DrawingToolPolygonSelectedInstanceContainer };
