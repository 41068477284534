import { type BoundaryListItem } from '../../sidebar/sidebarApps/mapTools/boundary/boundaryListing/boundaryListing.container';
import { type BoundaryItemResponseBase } from './boundaries.repository';

export const createBoundariesListItemFromResponse = (response: BoundaryItemResponseBase): BoundaryListItem => ({
  id: response.boundary_id,
  name: response.display_name,
  settings: {
    style: response.settings.style ? {
      color: response.settings.style.color,
      opacity: response.settings.style.opacity,
      lineColor: response.settings.style.line_color,
      lineWidth: response.settings.style.line_width,
    } : null,
    translate: response.settings.translate,
  },
});
