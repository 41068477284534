import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.GradientLimitIndicator]: {
  views: {
    default: {
      canvasHeight: 900,
      canvasWidth: 900,
      iconWidth: 825,
      iconHeight: 795,
    },
  },
  colors: {},
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 111] as const,
    progress: [0, 97] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/129801-gradient-limit-indicator" */
      './129801-gradient-limit-indicator.json'
    ),
} };

export const { GradientLimitIndicator } = config;
