import { type CSSInterpolation } from '@emotion/serialize';
import { type PropsWithChildren } from 'react';

const loadMoreButtonWrapperStyle = {
  minWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
} satisfies CSSInterpolation;

export const BoundaryRowLoadMoreWrapperComponent = ({ children }: PropsWithChildren) => (
  <div
    css={loadMoreButtonWrapperStyle}
  >
    {children}
  </div>
);
