import {
  isGroupRadius, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { generateGroupIdByName } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { getSpreadsheetDataForDataType } from '~/store/spreadsheetData/spreadsheetData.helpers';
import {
  DataType, type SpreadsheetDataData,
} from '~/store/spreadsheetData/spreadsheetData.state';

export const getGroupProximityLocations = (spreadsheetData: SpreadsheetDataData, proximity: Proximity) => {
  if (!isGroupRadius(proximity)) {
    return [];
  }

  const spreadsheetValues = getSpreadsheetDataForDataType(DataType.GROUP, spreadsheetData, proximity.data.data.columnId);
  const proximityGroupId = generateGroupIdByName(proximity.data.data.group);

  if (!spreadsheetValues) {
    return [];
  }

  const indexOfGroupId = spreadsheetValues?.extra?.uniqueGroups?.findIndex(g => g.id === proximityGroupId) ?? -1;

  if (indexOfGroupId === -1) {
    return [];
  }

  return Object.keys(spreadsheetValues.values).reduce<SpreadsheetRowId[]>((acc, rowId) => {
    if (spreadsheetValues.values[rowId] === indexOfGroupId) {
      acc.push({
        rowId,
        spreadsheetId: proximity.data.data.columnId.spreadsheetId,
      });
    }

    return acc;
  }, []);
};
