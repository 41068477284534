import { css } from '@emotion/react';
import {
  faShare, faUsersMedical,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  AccordionComponent, type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useOnUnmount } from '~/_shared/hooks/useOnUnmount';
import { useTranslation } from '~/_shared/utils/hooks';
import { MapNameSectionComponent } from '~/map/basicInfo/mapNameSection';
import { ShareMapSelectItemComponent } from '~/map/share/select/shareMapSelectItem.component';
import { type ModalProps } from '~/modal/modalType.enum';
import { closeLeftSidebar } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import {
  s, Trans,
} from '~/translations/Trans';

const modalContentStyle = css({
  padding: 0,
});

const accordionPanelStyle = css({
  padding: 20,
});

const accordionItemStyle = css({
  textTransform: 'uppercase',
});

const headingSubStyle = css({
  fontSize: '12px',
});

const shareWrapperStyle = css({
  display: 'flex',
  gap: 10,
  flexDirection: 'column',
});

export type ShareMapSelectProps = ModalProps<{
  mapName: string;
  isSnapshot: boolean;
  teamShareError?: string;
  onTeamShareClick?: () => void;
  onPresentationalShareClick: () => void;
}>;

export const ShareMapSelectComponent: FC<ShareMapSelectProps> = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useOnUnmount(() => dispatch(closeLeftSidebar()));

  const accordionData: AccordionData = {
    isExpanded: true,
    header: t('Choose audience'),
    isLocked: true,
    child: (
      <div css={shareWrapperStyle}>
        <ShareMapSelectItemComponent
          icon={faUsersMedical}
          heading={t('Share with your team')}
          description={t('Assign specific map roles to your team members.')}
          onClick={props.onTeamShareClick}
          error={props.teamShareError}
        />

        <ShareMapSelectItemComponent
          icon={faShare}
          heading={(
            <Trans i18nKey="Share externally <1>(Public map link, password protected, etc)</1>">
              {s('Share externally ')}
              <span css={headingSubStyle}>{s('(Public map link, password protected, etc)')}</span>
            </Trans>
          )}
          description={t('Make your map publicly available for view. You will be able to add password protection.')}
          onClick={props.onPresentationalShareClick}
        />
      </div>
    ),
  };

  return (
    <ModalComponent
      caption={t('Share Your Map')}
      contentStyle={modalContentStyle}
      isOpen={props.isOpen}
      onClose={props.onClose}
      maxWidth={590}
    >
      <MapNameSectionComponent
        isSnapshot={props.isSnapshot}
        name={props.mapName}
      />
      <AccordionComponent
        data={[accordionData]}
        itemButtonStyle={accordionItemStyle}
        panelStyle={accordionPanelStyle}
      />
    </ModalComponent>
  );
};
