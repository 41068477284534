import { css } from '@emotion/react';
import { type FC } from 'react';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { useTranslation } from '../../../_shared/utils/hooks';
import { ColorPickerComponent } from '../colorPicker/colorPicker.component';

type RangeColorsProps = Readonly<{
  className?: string;
  lowValueColor: ColorResult;
  onLowValueColorChange: (color: ColorResult) => void;
  highValueColor: ColorResult;
  onHighValueColorChange: (color: ColorResult) => void;
  mediumValueColor: ColorResult;
  onMediumValueColorChange: (color: ColorResult) => void;
}>;

const colorTileItemStyle = css({
  marginTop: 20,
  '&:first-of-type': {
    marginTop: 15,
  },
});

const dropdownHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '16px',
  lineHeight: 1.2,
});

export const RangeColorsComponent: FC<RangeColorsProps> = (props) => {
  const [t] = useTranslation();

  const { lowValueColor, onLowValueColorChange, highValueColor, onHighValueColorChange,
    mediumValueColor, onMediumValueColorChange } = props;

  return (
    <div className={props.className}>
      <p css={dropdownHeadingStyle}>{t('Range Colors')}</p>
      <ColorPickerComponent
        css={colorTileItemStyle}
        selectedColor={lowValueColor}
        onChange={onLowValueColorChange}
        label={t('Low Values')}
        displayAlpha={false}
      />

      <ColorPickerComponent
        css={colorTileItemStyle}
        selectedColor={mediumValueColor}
        onChange={onMediumValueColorChange}
        label={t('Medium Values')}
        displayAlpha={false}
      />

      <ColorPickerComponent
        css={colorTileItemStyle}
        selectedColor={highValueColor}
        onChange={onHighValueColorChange}
        label={t('High Values')}
        displayAlpha={false}
      />
    </div>
  );
};
