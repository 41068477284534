export enum BoundaryTerritoryType {
  Groups = 'groups',
  Numeric = 'numeric',
  Density = 'density',
  Demographic = 'demographic',
  Manual = 'manual'
}

export type CalculateBucketFunctionNumeric = 'min' | 'avg' | 'max' | 'sum';
export type CalculateBucketFunction = CalculateBucketFunctionNumeric | 'equal' | 'sum';

export const isBoundaryTerritoryTypeAllowedWithoutGeocoding = (type: BoundaryTerritoryType) => {
  switch (type) {
    case BoundaryTerritoryType.Manual:
    case BoundaryTerritoryType.Demographic:
      return true;

    default:
      return false;
  }
};
