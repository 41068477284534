import { useCallback } from 'react';
import { useGeocodingState } from './geocoding.selectors';
import {
  type GeocodingRequest, type GeocodingState,
} from './geocoding.state';

export type CombinedGeocodingProgressInfo = {
  progress: number;
  inProgress: boolean;
  pausedRealSpreadsheetIds: number[];
  changePauseRequestSent: boolean;
};

export const useSpreadsheetGeocodingState = () => {
  const geocodingState = useGeocodingState();

  const getCombinedProgressForMultipleRealSpreadsheets = useCallback((realSpreadsheetIds: number[]) => {
    const geocodingRequests = realSpreadsheetIds.map(
      realSpreadsheetId => ([
        realSpreadsheetId,
        getGeocodingRequestForRealSpreadsheet(realSpreadsheetId, geocodingState),
      ] as const)
    );
    return getCombinedGeocodingProgressForMultipleRealSpreadsheets(geocodingRequests);
  }, [geocodingState]);

  return {
    getCombinedProgressForMultipleRealSpreadsheets,
  };
};

const getGeocodingRequestForRealSpreadsheet = (realSpreadsheetId: number, geocodingState: GeocodingState): GeocodingRequest | null => (
  geocodingState.perSpreadsheetGeocodingRequest[realSpreadsheetId] || null
);

export const getCombinedGeocodingProgressForMultipleRealSpreadsheets = (allRealSpreadsheetsGeocodingStates: (readonly [number, GeocodingRequest | null])[]): CombinedGeocodingProgressInfo | null => {
  if (allRealSpreadsheetsGeocodingStates.some(realSpreadsheetGeocodingState => !realSpreadsheetGeocodingState)) {
    // if there is at least one spreadsheet we don't have geocoding info on, we cannot return a reliable result
    return null;
  }

  const accumulatedGeocodingInfo = allRealSpreadsheetsGeocodingStates.reduce<CombinedGeocodingProgressInfo>((accum, [realSpreadsheetId, realSpreadsheetStateGeocoding]) => {
    if (realSpreadsheetStateGeocoding !== null) {
      if (realSpreadsheetStateGeocoding.inProgress) {
        accum.progress += realSpreadsheetStateGeocoding.progress;
        accum.inProgress = true;
      }
      else {
        accum.progress += 100;
      }
      if (realSpreadsheetStateGeocoding.paused.isPaused) {
        accum.pausedRealSpreadsheetIds.push(realSpreadsheetId);
      }
      if (realSpreadsheetStateGeocoding.paused.changeRequestSent) {
        accum.changePauseRequestSent = true;
      }
    }
    return accum;
  }, {
    progress: 0,
    inProgress: false,
    pausedRealSpreadsheetIds: [],
    changePauseRequestSent: false,
  });

  return {
    progress: accumulatedGeocodingInfo.progress / allRealSpreadsheetsGeocodingStates.length,
    inProgress: accumulatedGeocodingInfo.inProgress,
    pausedRealSpreadsheetIds: accumulatedGeocodingInfo.pausedRealSpreadsheetIds,
    changePauseRequestSent: accumulatedGeocodingInfo.changePauseRequestSent,
  };
};
