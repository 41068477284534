import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { MapSource } from '~/_shared/types/map';
import { formatDate } from '~/_shared/utils/date/date.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { ignoreInScreenshotsDataAttribute } from '~/_shared/utils/testing/utils';
import {
  getMapRowPrivacyIcon, mapListingTableLastStyle, mapListingTablePrimaryStyle, mapListingTableQuaternaryStyle,
  mapListingTableQuinaryStyle,
} from '~/map/listing/mapListing.styles';
import { getMapRowIcon } from '~/map/listing/tableData/mapRow/mapRow.helpers';
import {
  checkboxWrapperStyles, linkIconStyle, mapRowLinkStyle, mapRowNameStyle, mapRowValueStyle, mapSelectCheckboxStyles,
  snapshotsCountContainerStyle, snapshotsCountStyle, snapshotsUncollapseArrowStyle, snapshotTableDataStyle,
  tableDataStyle, tableRowStyle, toggleIconStyle, toggleStyle,
} from '~/map/listing/tableData/mapRow/mapRow.styles';
import { MapRowActionsContainer } from '~/map/listing/tableData/mapRow/mapRowActions.container';
import {
  compareIdToParentMapOrMapId, getMapUrl,
} from '~/map/map.helpers';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { type MapListingItem } from '../../item/mapListingItem';
import { type ChangePrivacyLevelProps } from '../../useMapListingItems';
import { MapRowToggleComponent } from './mapRowToggle.component';

export type MapPrivacyClickParams = {
  mapId: number;
  mapName: string;
  privacyLevel: string;
  parentMapId?: number;
  parentMapPrivacy?: string;
  mapSource: MapSource;
};

type MapRowProps = Readonly<{
  areSnapshotsExpanded: boolean;
  index: number;
  isMapSelected: boolean;
  isOwner: boolean;
  item: MapListingItem;
  parentMapItem?: MapListingItem;

  onMapPrivacyClick: (data: MapPrivacyClickParams) => void;
  onMapSelectToggle: (mapId: number) => void;
  onShareSuccess: (props: ChangePrivacyLevelProps) => void;
  onShowSnapshotsToggle: (mapId: number) => void;
  width: number;
}>;

export const MapRowComponent: FC<MapRowProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const mapInfo = useMapInfoDataSelector();

  const isSnapshot = props.parentMapItem !== undefined;

  const checkboxTooltip = useMemo(() => {
    if (!props.isOwner) {
      return isSnapshot
        ? props.isMapSelected
          ? t('Deleting a map also deletes all its map views, including map views of other owners')
          : t('Only the owner is allowed to delete their map views.')
        : t('Only the owner is allowed to delete their maps.');
    }
    return '';
  }, [isSnapshot, props.isMapSelected, props.isOwner, t]);

  const onMapPrivacyClick = useCallback(() => {
    const onClick = props.onMapPrivacyClick;
    onClick({
      mapId: props.item.id,
      mapName: props.item.name,
      privacyLevel: props.item.privacy,
      parentMapId: props.parentMapItem?.id,
      parentMapPrivacy: props.parentMapItem?.privacy,
      mapSource: props.item.source,
    });
  }, [props.item.id, props.item.name, props.item.privacy, props.onMapPrivacyClick, props.parentMapItem?.id,
    props.parentMapItem?.privacy, props.item.source]);

  return (
    <tr css={tableRowStyle({ theme, isSnapshot })} data-testid={props.isMapSelected ? 'selected-map-row' : undefined}>
      <td css={isSnapshot ? snapshotTableDataStyle : tableDataStyle}>
        <div css={mapRowNameStyle({ isSnapshot, rowWidth: props.width })}>
          <span css={checkboxWrapperStyles}>
            <TooltipComponent
              tooltipContent={checkboxTooltip}
            >
              <CheckboxComponent
                checkedSetter={() => props.onMapSelectToggle(props.item.id)}
                isChecked={props.isMapSelected}
                isDisabled={!props.isOwner}
                checkmarkStyle={mapSelectCheckboxStyles({ theme, isChecked: props.isMapSelected })}
              />
            </TooltipComponent>
          </span>
          <LinkComponent
            path={getMapUrl(props.item.id)}
            css={mapRowLinkStyle({ theme, isActive: compareIdToParentMapOrMapId(props.item.id, mapInfo) })}
            title={props.item.name}
            data-testid={`navigate-map-${props.item.name}`}
          >
            <FontAwesomeIcon
              css={linkIconStyle}
              icon={getMapRowIcon({
                isSnapshot,
                item: props.item,
              })}
            />
            {props.item.name}
          </LinkComponent>
        </div>
      </td>

      <td css={[tableDataStyle, snapshotsCountContainerStyle, mapListingTablePrimaryStyle(props.width)]}>
        {!isSnapshot && (
          <MapRowToggleComponent
            areSnapshotsExpanded={props.areSnapshotsExpanded}
            item={props.item}
            onClick={props.onShowSnapshotsToggle}
            css={snapshotsCountContainerStyle}
          >
            <span css={snapshotsCountStyle({ theme })}>
              {props.item.snapshots.length}
            </span>
          </MapRowToggleComponent>
        )}
      </td>

      <td css={[tableDataStyle, mapListingTableQuinaryStyle(props.width)]}>
        <MapRowToggleComponent
          areSnapshotsExpanded={props.areSnapshotsExpanded}
          item={props.item}
          onClick={props.onShowSnapshotsToggle}
        >
          <span css={mapRowValueStyle}>
            {formatDate(props.item.dateCreated).toUpperCase()}
          </span>
        </MapRowToggleComponent>
      </td>

      <td css={[tableDataStyle, mapListingTableQuinaryStyle(props.width)]} {...ignoreInScreenshotsDataAttribute}>
        <MapRowToggleComponent
          areSnapshotsExpanded={props.areSnapshotsExpanded}
          item={props.item}
          onClick={props.onShowSnapshotsToggle}
        >
          <span css={mapRowValueStyle}>
            {formatDate(props.item.dateUpdated).toUpperCase()}
          </span>
        </MapRowToggleComponent>
      </td>

      <td css={[tableDataStyle, mapListingTableQuaternaryStyle(props.width)]}>
        <span css={mapRowValueStyle}>
          {props.item.mapViews}
        </span>
      </td>

      <td css={[tableDataStyle, mapListingTablePrimaryStyle(props.width)]}>
        <TooltipDeprComponent
          delayShow={500}
          tooltipContent={t('Map Security')}
        >
          <RoundButtonComponent
            icon={getMapRowPrivacyIcon(props.item.privacy)}
            onClick={onMapPrivacyClick}
            size={RoundButtonSize.Small}
          />
        </TooltipDeprComponent>
      </td>

      <td css={[tableDataStyle, mapListingTableLastStyle(props.width)]}>
        <MapRowActionsContainer
          width={props.width}
          isOwner={props.isOwner}
          mapListingItem={props.item}
          parentMapItem={props.parentMapItem}
          onShareSuccess={props.onShareSuccess}
        />
      </td>

      <td css={snapshotsUncollapseArrowStyle}>
        {props.item.snapshots.length > 0 && (
          <MapRowToggleComponent
            areSnapshotsExpanded={props.areSnapshotsExpanded}
            item={props.item}
            onClick={props.onShowSnapshotsToggle}
          >
            <span css={toggleStyle({ theme })}>
              <FontAwesomeIcon
                css={toggleIconStyle({ isToggled: props.areSnapshotsExpanded })}
                icon={faCaretUp}
              />
            </span>
          </MapRowToggleComponent>
        )}
      </td>
    </tr>
  );
};
