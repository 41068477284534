import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  boundaryTerritoryGroupCreateSettingsStyleDefaults,
  defaultBoundaryTerritoryGroupResponseSettings,
} from '~/boundary/settings/defaultBoundarySettings';
import { useCustomBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { boundaryTerritoryGroupsCreateRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { BoundaryMatchingType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { type BoundaryFillType } from '../../../../../../boundary/settings/boundaryFill.type';
import { getCalculateBucketFunction } from '../../boundaryTerritoryGroup.helpers';
import { useBoundaryGroupUsage } from '../../hooks/useBoundaryGroupUsage';
import {
  type LoadCustomBoundaryGroupData,
  LoadCustomBoundaryGroupPaneComponent,
} from './loadCustomBoundaryGroupPane.component';
import { LoadCustomBoundaryFillType } from './loadCustomBoundaryGroupPane.helpers';

export type LoadCustomBoundaryGroupPaneContainerProps = Readonly<{
  resetForm: () => void;
}>;

export const LoadCustomBoundaryGroupPaneContainer: FC<LoadCustomBoundaryGroupPaneContainerProps> = props => {
  const { isBoundaryGroupUsedOnMap } = useBoundaryGroupUsage();
  const customBoundaryGroups = useCustomBoundaryGroupsSelector();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();

  const handleAddBoundary = (data: LoadCustomBoundaryGroupData) => {
    const territoryGroup = customBoundaryGroups.find(item => item.id === data.boundaryGroupId);

    if (mapId === null || !territoryGroup) {
      return;
    }

    const boundaryFillType: BoundaryFillType =
      data.boundaryFillType === LoadCustomBoundaryFillType.FillBasedOnData
        ? LoadCustomBoundaryFillType.DefaultFill :
        data.boundaryFillType;

    let boundaryTerritoryType = BoundaryTerritoryType.Manual;
    let calculateBucketFunction = getCalculateBucketFunction(BoundaryTerritoryType.Manual);
    let bucketColumnId = null;

    if (data.boundaryFillType === LoadCustomBoundaryFillType.FillBasedOnData) {
      boundaryTerritoryType = data.boundaryTerritoryType;
      calculateBucketFunction = getCalculateBucketFunction(data.boundaryTerritoryType, data.numericCalculateBucketFunction);
      bucketColumnId = data.boundaryFillData?.columnId ?? null;
    }

    dispatch(boundaryTerritoryGroupsCreateRequest({
      boundary_group_id: territoryGroup.id,
      map_id: mapId,
      matchings: {
        matching_type: BoundaryMatchingType.Geometry,
      },
      settings: {
        ...defaultBoundaryTerritoryGroupResponseSettings,
        bucket_column_id: bucketColumnId,
        boundary_fill_type: boundaryFillType,
        boundary_territories: [],
        boundary_territory_type: boundaryTerritoryType,
        style: boundaryTerritoryGroupCreateSettingsStyleDefaults,
        calculate_bucket_function: calculateBucketFunction,
      },
    }));

    props.resetForm();
  };

  return (
    <LoadCustomBoundaryGroupPaneComponent
      customBoundaryGroups={customBoundaryGroups}
      isBoundaryGroupUsedOnMap={isBoundaryGroupUsedOnMap}
      onAddBoundary={handleAddBoundary}
    />
  );
};
