import { useMemo } from 'react';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import {
  convertLatLngPathToGeometry, convertPolygonToGeometry,
} from '~/_shared/types/polygon/polygon.utils';
import { type Proximity } from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  useLatLngSpreadsheetData, useSpreadSheetData,
} from '~/map/map/useSpreadsheetData.hook';
import { createProximityFilterArgumentsFromProximity } from '~/proximity/proximity.helpers';
import { createRadiusFilterItemRequest } from '~/spreadsheet/filter/radius/spreadsheetFilterRadius.factory';
import { type AreaQueryFilters } from '~/store/boundaryItems/boundaryItems.repository';
import {
  createRequestFromBoundaryTerritoryGroupSettings,
  getBoundaryTerritoryGroupMatchupRequest,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { type SpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';

type PolygonInputs = {
  type: 'polygon';
  polygon: Polygon;
};

type BoundaryInputs = {
  type: 'boundary';
  boundaryId: number;
  boundaryGroupId: number;
  matchings: BoundaryTerritoryGroup['matchings'];
};

type BoundaryTerritoryInputs = {
  type: 'boundary_territory';
  boundaryTerritoryId: string;
  boundaryTerritoryGroupId: number;
};

type ProximityInputs = {
  type: 'proximity';
  proximity: Proximity;
  spreadsheetRowId?: SpreadsheetRowId;
};

export type FilterInputs = PolygonInputs | BoundaryInputs | BoundaryTerritoryInputs | ProximityInputs;

export function useAreaQueryFilters(inputs: FilterInputs) {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const latLngLookup = useLatLngSpreadsheetData();
  const { spreadsheetData } = useSpreadSheetData();

  const filters = useMemo<AreaQueryFilters>(() => {
    switch (inputs.type) {
      case 'polygon':
        return getPolygonFilter(inputs);
      case 'boundary':
        return getBoundaryFilter(inputs);
      case 'boundary_territory':
        return getBoundaryTerritoryFilter(inputs, boundaryTerritoryGroups);
      case 'proximity':
        return getProximityFilter(inputs, latLngLookup, spreadsheetData);
      default:
        inputs satisfies never;
        return {};
    }
  }, [boundaryTerritoryGroups, inputs, latLngLookup, spreadsheetData]);

  return filters;
}

function getPolygonFilter(inputs: PolygonInputs): AreaQueryFilters {
  return {
    polygon_filter: {
      type: 'or',
      polygons: [{
        path: convertLatLngPathToGeometry(inputs.polygon.path),
      }],
    },
  };
}

function getBoundaryFilter(inputs: BoundaryInputs): AreaQueryFilters {
  return {
    boundary_filter: {
      type: 'or' as const,
      boundaries: {
        [inputs.boundaryGroupId]: {
          boundary_ids: [inputs.boundaryId],
          matchings: getBoundaryTerritoryGroupMatchupRequest(inputs.matchings),
        },
      },
    },
  };
}

function getBoundaryTerritoryFilter(inputs: BoundaryTerritoryInputs, boundaryTerritoryGroups: readonly BoundaryTerritoryGroup[]): AreaQueryFilters {
  const selectedBoundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === inputs.boundaryTerritoryGroupId);
  if (!selectedBoundaryTerritoryGroup) {
    throw new Error('Selected boundary territory group not found');
  }

  return {
    boundary_territory_filter: {
      type: 'or' as const,
      boundary_territory_groups: [{
        type: 'or' as const,
        boundary_territories: [inputs.boundaryTerritoryId],
        definition: {
          boundary_territory_group_id: inputs.boundaryTerritoryGroupId,
          boundary_group_id: selectedBoundaryTerritoryGroup.boundaryGroupId,
          matchings: getBoundaryTerritoryGroupMatchupRequest(selectedBoundaryTerritoryGroup.matchings),
          settings: createRequestFromBoundaryTerritoryGroupSettings(selectedBoundaryTerritoryGroup.settings),
        },
      }],
    },
  };
}

function getProximityFilter(inputs: ProximityInputs, latLngLookup: SpreadsheetLatLngRowData, spreadsheetData: SpreadsheetDataData): AreaQueryFilters {
  const { circles, multiPolygons } = createProximityFilterArgumentsFromProximity(inputs.proximity, latLngLookup, inputs.spreadsheetRowId ?? null, spreadsheetData);

  const radiusFilter = {
    radius_filter: {
      type: 'or' as const,
      radiuses: circles.map(createRadiusFilterItemRequest),
    },
  };

  const polygonFilter = {
    polygon_filter: {
      type: 'or' as const,
      polygons: multiPolygons.flatMap(
        multipolygon => multipolygon.map(
          polygon => ({ path: convertPolygonToGeometry(polygon) })
        )
      ),
    },
  };

  return {
    ...(circles.length !== 0 ? radiusFilter : {}),
    ...(multiPolygons.length !== 0 ? polygonFilter : {}),
  };
}
