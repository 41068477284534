import {
  type ColumnInfo, type ColumnsWithValuesDataType,
} from './guessUniqueColumns';
import {
  getHeaderAndValuesFromColumnsWithValues, isColumnUnique,
} from './guessUniqueColumns.helpers';

export const guessUniqueIdColumn = (header: ColumnInfo[], data: ColumnsWithValuesDataType): number | null => {
  if (!data) {
    return null;
  }

  const { header: columnNames, data: dataArray } = getHeaderAndValuesFromColumnsWithValues(header, data);

  // try with 'id'
  const idIndex = columnNames.findIndex(s => s.toLowerCase() === 'id');
  if (idIndex !== -1) {
    if (isColumnUnique(dataArray[idIndex])) {
      return idIndex;
    }
  }

  // try with column name including 'id'
  const indexOfColumnWithIdInIt = columnNames.findIndex((value, index) => {
    if (/\bid\b/i.test(value)) {
      return isColumnUnique(dataArray[index]);
    }
    return false;
  });

  return indexOfColumnWithIdInIt === -1 ? null : indexOfColumnWithIdInIt;
};
