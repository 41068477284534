import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { addSpreadsheetRow } from '../../spreadsheet/spreadsheet.repository';
import { spreadsheetResetStateAndRefetchData } from '../../store/spreadsheetData/spreadsheetData.actionCreators';

export type ParamsForAddingNewDataRow = Readonly<{
  clientId: number;
  columnValues: { [columnId: string]: string };
  realSpreadsheetId?: string;
  virtualSpreadsheetId: number;
  lat?: number;
  lng?: number;
}>;

export const useAddDataRow = () => {
  const dispatch = useDispatch();
  const isMountedRef = useIsComponentMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const addNewDataRow = (params: ParamsForAddingNewDataRow): Promise<{message: string}> => {
    setIsLoading(true);
    setIsError(false);

    return addSpreadsheetRow(params.clientId, params.virtualSpreadsheetId, {
      real_spreadsheet_id: params.realSpreadsheetId,
      data: params.columnValues,
      lat: params.lat, lng: params.lng,
    })
      .then((result) => {
        dispatch(spreadsheetResetStateAndRefetchData());
        return result;
      })
      .catch((e) => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        throw Error(e);
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  /*
   * This can be used on map page, when one also needs to consider the usual drill like re-fetching the data
   * and error handling.
   */
  const addNewDataRowMapPage = (params: ParamsForAddingNewDataRow, onIsLoadingChanged?: (newValue: boolean) => void) => {
    onIsLoadingChanged?.(true);
    addNewDataRow(params)
      .finally(() => {
        onIsLoadingChanged?.(false);
      });
  };

  return {
    isLoading,
    isError,
    addNewDataRow,
    addNewDataRowMapPage,
  };
};
