import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';

const primaryContentStyle = css({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  height: '100%',
});

export const BoundaryRowContentWrapperComponent = ({ children }: PropsWithChildren) => {
  return (
    <div css={primaryContentStyle}>
      {children}
    </div>
  );
};
