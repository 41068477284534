import {
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useMapBoundariesIsLoadingSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { resetExpectBoundaryRendering } from '~/store/frontendState/mapTools/boundary/boundaryRendering/boundaryRendering.actionCreators';
import { useBoundaryRenderingSelector } from '~/store/frontendState/mapTools/boundary/boundaryRendering/boundaryRendering.selectors';

export const useBoundaryLoading = () => {
  const dispatch = useDispatch();

  const isLoading = useMapBoundariesIsLoadingSelector();
  const editIsWaiting = useSelector(state => state.frontendState.mapTools.boundary.boundaryPolygonUpdate.isWaiting);

  const { renderingBoundaries, renderingSelectBoundaries, isRenderingExpected } = useBoundaryRenderingSelector();
  const previousIsRenderingExpectedRef = useRef(false);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (isRenderingExpected && !previousIsRenderingExpectedRef.current) {
      timeoutId = window.setTimeout(() => {
        dispatch(resetExpectBoundaryRendering());
      }, 500);
    }

    previousIsRenderingExpectedRef.current = isRenderingExpected;

    return () => {
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [dispatch, isRenderingExpected]);

  return isLoading || editIsWaiting || renderingBoundaries || renderingSelectBoundaries || isRenderingExpected;
};
