import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  s, Trans,
} from '~/translations/Trans';
import { AppErrorPageWrapperComponent } from '../wrapper/appErrorPageWrapper.component';

const textWrapperStyle = css({
  maxWidth: 680,
});

export const AppErrorPageGeneralComponent: FC = () => {
  const [t] = useTranslation();

  return (
    <AppErrorPageWrapperComponent>
      <div css={textWrapperStyle}>
        <div>
          <p>{t('Something didn\'t go as planned.')}</p>
          <p>
            <Trans i18nKey={'We encountered an error loading this page. Try reloading it - if it doesn\'t work,<1 />try again later or contact customer support.'} >
              {s('We encountered an error loading this page. Try reloading it - if it doesn\'t work,')}
              <br />
              {s('try again later or contact customer support.')}
            </Trans>
          </p>
        </div>

        <ButtonComponent
          text={t('Refresh Page')}
          onClick={() => refreshPage()}
        />
      </div>
    </AppErrorPageWrapperComponent>
  );
};
