import { css } from '@emotion/react';
import {
  type FC,
  type ReactNode,
} from 'react';

type ProximityPanelInputLabelProps = Readonly<{
  children: ReactNode;
}>;

const labelStyle = css({
  cursor: 'default',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
  margin: '10px 0 5px',
  textTransform: 'uppercase',
});

export const ProximityPanelInputLabelComponent: FC<ProximityPanelInputLabelProps> = props => (
  <div css={labelStyle}>
    {props.children}
  </div>
);
