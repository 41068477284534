import {
  type FC,
  useMemo,
} from 'react';
import { type UnitSystem } from '../../../../_shared/types/googleMaps/googleMaps.types';
import { GroupRadiusEditLevel } from '../../../../_shared/types/proximity/proximity.enums';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { ProximityPanelDropdownComponent } from '../inputs/proximityPanelDropdown.component';
import { ProximityPanelGeolocationInputComponent } from '../inputs/proximityPanelGeolocationInput.component';
import { ProximityPanelInputLabelComponent } from '../inputs/proximityPanelInputLabel.component';
import { createProximityApplyToDropdownOption } from '../proximityPanelForm.helpers';
import { UnitDropdownComponent } from './unitDropdown.component';

type SingleRadiusFormFieldsProps = Readonly<{
  address: string;
  applyTo: GroupRadiusEditLevel;
  canSelectGroupRadius: boolean;
  hasGeolocationError: boolean;
  isFetchingGeolocation: boolean;
  proximityWithin: string;
  unit: UnitSystem;

  onChangeAddress: (address: string) => void;
  onChangeApplyTo: (applyTo: GroupRadiusEditLevel) => void;
  onChangeProximityWithin: (proximityWithin: string) => void;
  onGetUserLocation: () => void;
  onUnitChange: (unit: UnitSystem) => void;
}>;

export const SingleRadiusFormFieldsComponent: FC<SingleRadiusFormFieldsProps> = props => {
  const [t] = useTranslation();
  const applyToDropdownOptions = useMemo(() => Object.values(GroupRadiusEditLevel)
    .filter(v => v === GroupRadiusEditLevel.IndividualLocation || props.canSelectGroupRadius)
    .map(v => createProximityApplyToDropdownOption(v, t))
  , [t, props.canSelectGroupRadius]);

  return (
    <>
      <ProximityPanelInputLabelComponent>{t('Apply to')}</ProximityPanelInputLabelComponent>
      <ProximityPanelDropdownComponent
        onChange={props.onChangeApplyTo}
        value={props.applyTo}
        options={applyToDropdownOptions}
      />

      <ProximityPanelGeolocationInputComponent
        value={props.address}
        onChange={props.onChangeAddress}
        onGetUserLocation={props.onGetUserLocation}
        hasError={props.hasGeolocationError}
        isLoading={props.isFetchingGeolocation}
      />

      <ProximityPanelInputLabelComponent>{t('Proximity Within')}</ProximityPanelInputLabelComponent>
      <UnitDropdownComponent
        onChange={props.onChangeProximityWithin}
        onUnitChange={props.onUnitChange}
        unit={props.unit}
        value={props.proximityWithin?.toString() || ''}
      />
    </>
  );
};
