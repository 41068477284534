import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  forwardRef, useCallback,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ImageComponent, type ImageProps,
} from './image.component';

type ImageWithDeleteProps = ImageProps & Readonly<{
  onDelete?: () => void;
}>;

const deleteButtonWrapperStyle = css({
  display: 'none',
});

const wrapperStyle = (props: ImageProps) => css({
  width: props.width,
  height: props.height,
  position: 'relative',
  overflow: 'hidden',
  '&:hover .button-wrapper': {
    display: 'block',
  },
});

const sideOptionButtonStyle = css({
  position: 'absolute',
  right: 0,
  bottom: 0,
});

export const ImageWithDeleteComponent = forwardRef<HTMLImageElement, ImageWithDeleteProps>((props, ref) => {
  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const onDelete = props.onDelete;
    onDelete?.();
  }, [props.onDelete]);

  return (
    <div
      ref={ref}
      css={wrapperStyle(props)}
    >
      <ImageComponent
        {...props}
        className={props.className}
      />
      {props.onDelete && (
        <div
          css={deleteButtonWrapperStyle}
          className="button-wrapper"
        >
          <RoundButtonComponent
            css={sideOptionButtonStyle}
            icon={faTrash}
            buttonStyle={RoundButtonStyle.DangerInverted}
            onClick={onDelete}
            size={RoundButtonSize.Small}
          />
        </div>
      )}
    </div>
  );

});
