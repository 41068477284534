import { css } from '@emotion/react';
import { type FC } from 'react';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { useTranslation } from '../../../_shared/utils/hooks';
import { ColorPickerComponent } from '../colorPicker/colorPicker.component';

type LineColorProps = Readonly<{
  className?: string;
  lineColor: ColorResult;
  onLineColorChange: (color: ColorResult) => void;
}>;

const dropdownHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '16px',
  lineHeight: 1.2,
});

const colorPickerStyle = css({
  marginTop: 4,
});

export const LineColorComponent: FC<LineColorProps> = (props) => {
  const [t] = useTranslation();

  const { lineColor, onLineColorChange } = props;

  return (
    <div css={props.className}>
      <p css={dropdownHeadingStyle}>{t('Line Color')}</p>
      <ColorPickerComponent
        css={colorPickerStyle}
        label={t('Line Color')}
        selectedColor={lineColor}
        onChange={onLineColorChange}
        displayAlpha={false}
      />
    </div>
  );
};
