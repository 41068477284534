import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import {
  getDropdownNumericOptions, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { useTranslation } from '../../../_shared/utils/hooks';
import { BOUNDARY_SETTINGS_MIN_RANGES_COUNT } from '../boundarySettings.common';

type NumberOfRangesProps = Readonly<{
  className?: string;
  maxNumberOfRanges: number;
  numberOfRanges: number;
  onNumberOfRangesChange: (range: number) => void;
}>;

const dropdownHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '16px',
  lineHeight: 1.2,
});

export const NumberOfRangesComponent: FC<NumberOfRangesProps> = (props) => {
  const [t] = useTranslation();

  const { maxNumberOfRanges, numberOfRanges, onNumberOfRangesChange } = props;

  const numberOfRangeOptions = useMemo(() => getDropdownNumericOptions(BOUNDARY_SETTINGS_MIN_RANGES_COUNT, maxNumberOfRanges), [maxNumberOfRanges]);

  return (
    <div className={props.className}>
      <p css={dropdownHeadingStyle}>{t('Number Of Ranges')}</p>
      <RegularDropdownComponent<number>
        onChange={onNumberOfRangesChange}
        value={numberOfRanges}
        options={numberOfRangeOptions}
        inPortal
      />
    </div>
  );
};
