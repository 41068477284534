import { css } from '@emotion/react';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const territoryGroupNameStyle = ({ theme, isPlaceholder }: ThemeProps<{ isPlaceholder: boolean }>) => css({
  height: 40,
  backgroundColor: theme.backgroundColors.secondary,
  color: isPlaceholder ? theme.textColors.disabled : undefined,
});

const generateButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
});

const generateButtonStyle = css({
  width: '100%',
  paddingTop: 13,
  paddingBottom: 13,
});

const alertStyles = css({
  marginTop: 16,
});

export type NameStepComponentProps = Readonly<{
  groupName: string;
  onChange: (groupName: string) => void;
  onContinue: () => void;
  isGenerateOptimizedTerritoriesLoading: boolean;
  optimizedTerritoriesRequestError: string | null;
  onCloseOptimizedTerritoriesRequestError: () => void;
}>;

export const NameStepComponent: FC<NameStepComponentProps> = ({
  groupName,
  onChange,
  onContinue,
  isGenerateOptimizedTerritoriesLoading,
  optimizedTerritoriesRequestError,
  onCloseOptimizedTerritoriesRequestError,
}: NameStepComponentProps) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const disabled = useMemo(() => !groupName.trim(), [groupName]);

  const onSubmit = useCallback(() => {
    onChange(groupName.trim());
    onContinue();
  }, [groupName, onContinue, onChange]);

  return (
    <>
      <TextInputComponent
        css={territoryGroupNameStyle({ theme, isPlaceholder: groupName.length === 0 })}
        value={groupName}
        onChange={onChange}
        type="text"
        placeholder={t('boundaryAI.Territory Group Name')}
        icon={faGlobe}
      />
      {!!optimizedTerritoriesRequestError && (
        <AlertComponent
          css={alertStyles}
          type="danger"
          onClose={onCloseOptimizedTerritoriesRequestError}
        >
          {optimizedTerritoriesRequestError}
        </AlertComponent>
      )}
      <div css={generateButtonContainerStyle}>
        <ButtonComponent
          css={generateButtonStyle}
          text={t('boundaryAI.Generate Territory')}
          onClick={onSubmit}
          isDisabled={isGenerateOptimizedTerritoriesLoading || disabled}
        />
      </div>
    </>
  );
};
