import { type CSSInterpolation } from '@emotion/serialize';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { type MouseEventHandler } from 'react';
import {
  ButtonComponent, ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { useTranslation } from '~/_shared/utils/hooks';
import { BoundaryRowLoadMoreWrapperComponent } from '~/sidebar/sidebarApps/mapTools/boundary/activeBoundary/boundaryItem/boundaryRowLoadMoreWrapperComponent';
import { BoundaryRowPaneComponent } from '~/sidebar/sidebarApps/mapTools/boundary/activeBoundary/boundaryItem/boundaryRowPane.component';

const stretchButtonWidthStyle = {
  flexGrow: 1,
} satisfies CSSInterpolation;

const boundaryRowPanPaddingOverride = {
  paddingInline: 16,
} satisfies CSSInterpolation;

export const BoundaryRowLoadMoreComponent = ({ onButtonClick }: { readonly onButtonClick: MouseEventHandler }) => {
  const [t] = useTranslation();
  return (
    <BoundaryRowPaneComponent css={boundaryRowPanPaddingOverride}>
      <BoundaryRowLoadMoreWrapperComponent>
        <ButtonComponent
          buttonStyle={ButtonStyle.Quaternary}
          css={stretchButtonWidthStyle}
          onClick={onButtonClick}
          prefixIcon={faArrowDown}
          size={ButtonSize.Small}
          text={t('Load More')}
        />
      </BoundaryRowLoadMoreWrapperComponent>
    </BoundaryRowPaneComponent>
  );
};
