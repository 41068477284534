import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

const activeMarkerSelector = (state: AppState) => state.frontendState.activeMapElements.activeMarker;
const activeMarkerNavigationIndexSelector = (state: AppState) => state.frontendState.activeMapElements.activeMarkerNavigationIndex;
export const activeMarkerIdsSelector = (state: AppState) => state.frontendState.activeMapElements.activeMarker?.rowIds;
const activeMarkerIdSelector = (state: AppState) => activeMarkerIdsSelector(state)?.[
  state.frontendState.activeMapElements.activeMarkerNavigationIndex
];
export const useActiveMarkerSelector = () => useSelector(activeMarkerSelector);
export const useActiveMarkerNavigationIndexSelector = () => useSelector(activeMarkerNavigationIndexSelector);
export const useActiveMarkerIdSelector = () => useSelector(activeMarkerIdSelector);
export const useActiveMarkerIdsSelector = () => useSelector(activeMarkerIdsSelector);

const hoveredMarkerSelector = (state: AppState) => state.frontendState.activeMapElements.hoveredMarker;
export const useHoveredMarkerSelector = () => useSelector(hoveredMarkerSelector);

export const activeBoundarySelector = (state: AppState) => state.frontendState.activeMapElements.activeBoundary;
export const useActiveBoundarySelector = () => useSelector(activeBoundarySelector);

const activeBoundaryTerritorySelector = (state: AppState) => state.frontendState.activeMapElements.activeBoundaryTerritory;
export const useActiveBoundaryTerritorySelector = () => useSelector(activeBoundaryTerritorySelector);

const activeProximitySelector = (state: AppState) => state.frontendState.activeMapElements.activeProximity;
export const useActiveProximitySelector = () => useSelector(activeProximitySelector);

const isLocationListOpen = (state: AppState) => state.frontendState.activeMapElements.isLocationListActive;
export const useIsLocationListOpen = () => useSelector(isLocationListOpen);
