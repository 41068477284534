import { css } from '@emotion/react';
import {
  faBroom, faCog, faLasso,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type MutableRefObject, useEffect,
} from 'react';
import {
  RoundButtonSize, RoundButtonWithTextComponent,
} from '~/_shared/baseComponents/buttons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  TooltipBehavior,
  TooltipComponent, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithInputBarComponent } from '~/_shared/components/colorPicker/colorPickerWithInputBar/colorPickerWithInputBar.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { SortableDragLayerComponent } from '~/_shared/components/sortable/dragLayer/sortableDragLayer.component';
import { SortableComponent } from '~/_shared/components/sortable/sortable.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type Waypoint } from '~/store/frontendState/mapTools/directions/directions.state';
import { DirectionsPanelInputComponent } from '../input/directionsPanelInput.component';

const wrapperStyle = css({
  position: 'relative',
});

const primaryContentStyle = css({
  padding: '10px 16px 40px',
});

const ctaWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const ctaButtonStyle = css({
  fontSize: '14px',
  fontWeight: 500,
});

const primaryHeadingStyle = css({
  margin: '10px 0 0',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'uppercase',
});

const secondaryHeadingStyle = css({
  margin: '3px 0 0',
  fontSize: '14px',
});

const originInputStyle = ({ opacity, width }: { opacity: number; width?: string }) => css({
  width,
  opacity,
  marginTop: 15,
});

const waypointsWrapperStyle = css({
  marginTop: 8,
});

const addLocationWrapperStyle = css({
  marginTop: 14,
});

const buttonWrapperStyle = css({
  marginTop: 10,
});

const directionsColorLabelStyle = css({
  margin: '20px 0 4px',
  fontSize: '14px',
  textTransform: 'uppercase',
});

const getDirectionsButtonStyle = css({
  height: 48,
  width: '100%',
  marginTop: 12,
  borderRadius: 4,
  fontSize: '16px',
  fontWeight: 500,
});

const optimizeRouteButtonStyle = css({
  height: 48,
  width: '100%',
  marginTop: 8,
  borderRadius: 4,
  fontSize: '16px',
  fontWeight: 500,
});

type DirectionsPanelFormProps = Readonly<{
  getDirectionsDisabledMessage: string | null;
  isClearFormEnabled: boolean;
  showLassoToolButton: boolean;
  isLassoActive: boolean;
  isLoading: boolean;
  routeDirectionsSelectedColor: ColorResult;
  showAdminControls: boolean;
  startWithUserLocation?: boolean;
  waypoints: ReadonlyArray<Waypoint>;

  onAddLocationClick: () => void;
  onClearForm: () => void;
  onGetDirectionsClick: () => void;
  onLassoAndFillCancel: () => void;
  onLassoAndFillStart: () => void;
  onOpenSettingsClick: () => void;
  onOptimizeRouteClick: () => void;
  onRouteColorChange: (color: ColorResult) => void;
  onWaypointCleanClick: (waypointIndex: number) => void;
  setWaypointAddress: (index: number, value: string) => void;
  setWaypoints: (waypoints: ReadonlyArray<Waypoint>) => void;
}>;

const DIRECTIONS_DND_ITEM_TYPE = 'DIRECTIONS_DND_ITEM';

export const DirectionsPanelFormComponent: FC<DirectionsPanelFormProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [isLassoInstructionsShown, showLassoInstructions] = useTimeout(3000);

  useEffect(() => {
    const isLassoActive = props.isLassoActive;
    if (isLassoActive) {
      showLassoInstructions();
    }
  }, [props.isLassoActive, showLassoInstructions]);

  const renderWaypointItem = (
    data: Waypoint,
    index: number,
    isDragging = false,
    moveTriggerRef?: MutableRefObject<HTMLButtonElement>
  ) => {
    const opacity = isDragging ? 0.5 : 1;
    const inputWidth = isDragging ? '257px' : '100%';

    return (
      <DirectionsPanelInputComponent
        itemsLength={props.waypoints.length}
        index={index}
        css={originInputStyle({ opacity, width: inputWidth })}
        value={data.address || ''}
        onChange={(value) => props.setWaypointAddress(index, value)}
        placeholder={
          index === 0 && props.startWithUserLocation
            ? t('Your Current Location')
            : t('directionsTool.inputPlaceholder')
        }
        onCleanClick={() => props.onWaypointCleanClick(index)}
        dragRef={moveTriggerRef}
        showCleanButton={
          props.waypoints.length > 2 || !isTextEmpty(data.address || '') || data.latLng !== null
        }
        routeColor={props.routeDirectionsSelectedColor.hex}
      />
    );
  };

  return (
    <div css={wrapperStyle}>
      <div css={primaryContentStyle}>
        <div css={ctaWrapperStyle}>
          {props.showAdminControls && (
            <ButtonComponent
              buttonStyle={ButtonStyle.Tertiary}
              css={ctaButtonStyle}
              prefixIcon={faCog}
              text={t('Settings')}
              onClick={props.onOpenSettingsClick}
            />
          )}

          <ButtonComponent
            buttonStyle={ButtonStyle.Secondary}
            css={ctaButtonStyle}
            prefixIcon={faBroom}
            text={t('Clear Form')}
            onClick={props.onClearForm}
            isDisabled={!props.isClearFormEnabled}
          />
        </div>

        <h3 css={primaryHeadingStyle}>{t('Enter Locations')}</h3>
        <p css={secondaryHeadingStyle}>{t('Drag and drop to customize ordering')}</p>

        <SortableDragLayerComponent
          acceptedItemType={DIRECTIONS_DND_ITEM_TYPE}
          renderItem={(id) => {
            const index = props.waypoints.findIndex(item => item.id === id);
            const data = props.waypoints[index];

            if (!data) {
              return null;
            }
            return renderWaypointItem(data, index, true);
          }}
        />

        <SortableComponent<Waypoint>
          itemType={DIRECTIONS_DND_ITEM_TYPE}
          css={waypointsWrapperStyle}
          items={props.waypoints.map(data => ({ id: data.id, data }))}
          onItemsChange={newOrder => props.setWaypoints(newOrder.map(({ data }) => data))}
          renderItem={({ isDragging, data, index, moveTriggerRef }) =>
            renderWaypointItem(data, index, isDragging, moveTriggerRef as MutableRefObject<HTMLButtonElement>)
          }
        />

        <AddMorePlaceholderComponent
          css={addLocationWrapperStyle}
          onClick={props.onAddLocationClick}
          title={t('Add Additional Location')}
        />

        {props.showLassoToolButton && (
          <RoundButtonWithTextComponent
            tooltipPlacement={TooltipPlacement.Top}
            tooltipLabel={isLassoInstructionsShown ? t('lassoActiveClickOnMapInstructions') : undefined}
            tooltipBehavior={TooltipBehavior.ShowAlways}
            buttonStyle={!props.isLassoActive
              ? RoundButtonStyle.ConfirmInverted
              : isLassoInstructionsShown ? RoundButtonStyle.Disabled : RoundButtonStyle.Danger
            }
            icon={faLasso}
            onClick={!props.isLassoActive
              ? props.onLassoAndFillStart
              : isLassoInstructionsShown ? undefined : props.onLassoAndFillCancel}
            size={RoundButtonSize.Large}
            text={!props.isLassoActive || isLassoInstructionsShown ? t('Lasso & Fill Locations') : t('Cancel Lasso')}
            textColor={theme.textColors.secondary}
            textHoverColor={isLassoInstructionsShown ? theme.textColors.secondary : theme.textColors.primary}
            wrapperStyle={buttonWrapperStyle}
          />
        )}

        <div>
          <p css={directionsColorLabelStyle}>{t('Route Directions Color')}</p>
          <ColorPickerWithInputBarComponent
            isFixed
            selectedColor={props.routeDirectionsSelectedColor}
            displayAlpha={false}
            onChange={props.onRouteColorChange}
          />
        </div>

        <TooltipComponent
          tooltipContent={props.getDirectionsDisabledMessage}
          placement="right"
        >
          <ButtonComponent
            isDisabled={!!props.getDirectionsDisabledMessage}
            css={getDirectionsButtonStyle}
            text={t('Get Directions')}
            onClick={props.onGetDirectionsClick}
          />
        </TooltipComponent>

        <TooltipComponent
          tooltipContent={props.getDirectionsDisabledMessage}
          placement="right"
        >
          <ButtonComponent
            isDisabled={!!props.getDirectionsDisabledMessage}
            buttonStyle={ButtonStyle.Quaternary}
            css={optimizeRouteButtonStyle}
            text={t('Optimize Route')}
            onClick={props.onOptimizeRouteClick}
          />
        </TooltipComponent>
      </div>

      {props.isLoading && (
        <OverlayLoaderComponent />
      )}
    </div>
  );
};
