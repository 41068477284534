import { css } from '@emotion/react';
import {
  type FC, useContext,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import type { GridElement } from '~/_shared/components/spreadsheet/spreadsheet.types';
import { SpreadsheetContext } from '~/_shared/components/spreadsheet/spreadsheetContext';
import type { Theme } from '~/_shared/themes/theme.model';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import CellRendererProps = ReactDataSheet.CellRendererProps;

export const SPREADSHEET_CELL_HEIGHT = 24;
export const SPREADSHEET_CELL_MIN_WIDTH = 80;
export const SELECTED_OR_HIGHLIGHTED_CELL = 'SELECTED_OR_HIGHLIGHTED_CELL';

export const cellContainerStyle = ({ width, minWidth, state }: {
  minWidth?: string | number;
  state?: ReadonlySet<SpreadsheetCellState>;
  width?: string | number;
}) => (theme: Theme) => {
  return css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    border: `1px solid ${theme.borderColors.primary}`,
    width,
    minWidth,
    height: SPREADSHEET_CELL_HEIGHT,
    boxSizing: 'border-box',
    padding: '0 2px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    background: !state?.has(SpreadsheetCellState.disabled) ? 'inherit' : theme.backgroundColors.secondary,
    flexShrink: 0,
  }, highlightStyle(state)(theme));
};

const highlightStyle = (state?: ReadonlySet<SpreadsheetCellState>) => (theme: Theme) => {
  const isSelected = state?.has(SpreadsheetCellState.selected);
  const isHighlighted = state?.has(SpreadsheetCellState.highlighted);

  if (!isSelected && !isHighlighted) {
    return undefined;
  }

  const backgroundColor =
    (isSelected && isHighlighted && theme.backgroundColors.highlightHover)
    || (isSelected && theme.backgroundColors.secondaryHover)
    || (isHighlighted && theme.backgroundColors.highlight)
    || theme.backgroundColors.primary;

  const getBoxShadowProps = (yOffset: number) => `0 ${yOffset}px 0 1px inset ${theme.borderColors.activeItem}`;

  return css({
    backgroundColor,
    borderColor: theme.borderColors.activeItem,
    '&:after': {
      boxShadow: isHighlighted && !isSelected ? `${getBoxShadowProps(1)}, ${getBoxShadowProps(-1)}` : 'none',
      content: '" "',
      inset: 0,
      pointerEvents: 'none',
      position: 'absolute',
    },
  });
};

export enum SpreadsheetCellState {
  disabled = 'disabled',
  highlighted = 'highlight',
  selected = 'selected',
}

type SpreadsheetCellProps = CellRendererProps<GridElement, string>;

export const SpreadsheetCellComponent: FC<SpreadsheetCellProps> = (props) => {
  const spreadsheetContext = useContext(SpreadsheetContext);
  if (!spreadsheetContext) {
    return null;
  }
  const { columns, data, highlightedRows, getColumnWidth } = spreadsheetContext;
  const columnId = columns[props.col]?.id;
  const rowId = data[props.row]?.rowId;

  const cellState = new Set([
    columns[props.col]?.disabled ? SpreadsheetCellState.disabled : undefined,
    notNullOrUndefined(rowId) && highlightedRows?.has(rowId) ? SpreadsheetCellState.highlighted : undefined,
    props.selected ? SpreadsheetCellState.selected : undefined,
  ].filter(notNullOrUndefined));

  const width = columnId !== undefined ? getColumnWidth(columnId) : undefined;

  const isDisabled = cellState.has(SpreadsheetCellState.disabled);
  const isSelectedOrHighlighted = cellState?.has(SpreadsheetCellState.selected) || cellState?.has(SpreadsheetCellState.highlighted);

  return (
    <div
      className={`${props.className} ${isSelectedOrHighlighted && SELECTED_OR_HIGHLIGHTED_CELL}`}
      css={cellContainerStyle({
        state: cellState,
        minWidth: SPREADSHEET_CELL_MIN_WIDTH,
        width,
      })}
      data-disabled={isDisabled}
      onMouseOver={!isDisabled ? props.onMouseOver : undefined}
      onMouseDown={!isDisabled ? props.onMouseDown : undefined}
      onDoubleClick={!isDisabled ? props.onDoubleClick : undefined}
    >
      {props.children}
    </div>
  );
};
