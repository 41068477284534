import { css } from '@emotion/react';
import { type FC } from 'react';
import { DisclaimerContainer } from '~/_shared/components/disclaimer/disclaimer.container';
import {
  disclaimerStepSecondaryStyle, headingSecondaryStyle, useDisclaimerSteps,
} from '~/_shared/components/disclaimer/disclaimerSteps';
import { useTranslation } from '~/_shared/utils/hooks';
import { DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';
import { DisclaimerStepComponent } from '../../../_shared/components/disclaimer/disclaimerStep.component';
import {
  MapConfirmComponent, type MapConfirmProps,
} from '../../../_shared/mapConfirm/mapConfirm.component';

export type BoundaryDrawConfirmProps = MapConfirmProps & Readonly<{
  highlightedStepNumber?: number;
}>;

const disclaimerStyle = css({
  marginLeft: 20,
  fontSize: '14px',
});

const disclaimerStepStyle = css({
  margin: '10px 5px',
});

const disclaimerTextStepStyle = css({
  display: 'inline-block',
  marginRight: 8,
});

const mapConfirmStyle = css({
  minWidth: 267,
});

export const BoundaryDrawConfirmComponent: FC<BoundaryDrawConfirmProps> = (props) => {
  const [t] = useTranslation();
  const disclaimerSteps = useDisclaimerSteps();

  return (
    <MapConfirmComponent
      css={mapConfirmStyle}
      {...props}
    >
      {props.selectedOptionId === DrawType.Draw && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'draw-boundary-draw',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 1}
            step={1}
          >
            {disclaimerSteps.Start}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 2}
            step={2}
          >
            {t('Click the map to start drawing your polygon.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 3}
            step={3}
          >
            {t('Click the map again to add additional vertices to the polygon outline.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 4}
            step={4}
          >
            {t('Click the first vertex or double click the map to complete the loop.')}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 5}
            step={5}
          >
            {disclaimerSteps.FinishBoundaryDraw}
          </DisclaimerStepComponent>
        </DisclaimerContainer>
      )}

      {props.selectedOptionId === DrawType.Drag && (
        <DisclaimerContainer
          css={disclaimerStyle}
          permanentConfirmSettings={{
            id: 'draw-boundary-drag',
            text: t('Do not show again'),
          }}
        >
          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 1}
            step={1}
          >
            {disclaimerSteps.Start}
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 2}
            step={2}
          >
            {t('Edit your polygon.')}

            <span css={disclaimerStepSecondaryStyle}>
              {'- '}
              <strong css={headingSecondaryStyle}>{t('Drag')}</strong>
              {t(' the outline vertex to reshape your polygon.')}
            </span>
            <span css={disclaimerStepSecondaryStyle}>
              {'- '}
              <strong css={headingSecondaryStyle}>{t('Hover')}</strong>
              {t(' the outline and click the remove button to delete the vertex.')}
            </span>
          </DisclaimerStepComponent>

          <DisclaimerStepComponent
            isHighlighted={props.highlightedStepNumber === 3}
            step={3}
          >
            {disclaimerSteps.FinishBoundaryDraw}
          </DisclaimerStepComponent>

          <p css={disclaimerStepStyle}>
            <span css={disclaimerTextStepStyle}>
              {t('Draw another')}:
            </span>
            {t('Select Draw action in the menu on the left.')}
          </p>
        </DisclaimerContainer>
      )}
    </MapConfirmComponent>
  );
};
