import { faMapSalesforce } from '@awesome.me/kit-77e1f5874f/icons/kit/custom';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import type { MapListingItem } from '~/map/listing/item/mapListingItem';

export const getMapRowIcon = ({ isSnapshot, item }: { isSnapshot: boolean; item: MapListingItem }) => {
  const isConnected = !!item.layering?.connected;
  const isSalesforce = item.source === 'salesforce';

  if (isSnapshot) {
    return faImages;
  }

  return isSalesforce ? faMapSalesforce : isConnected ? faLayerGroup : faMap;
};
