import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '../../../../../_shared/utils/hooks';
import { type BoundaryTerritoryGroupId } from '../../../../../store/boundaries/boundaryIdentifier.type';
import { type BoundaryTerritoryAssignment } from '../../../../../store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { BoundaryTerritoryType } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryTerritory } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { boundaryTerritoryTypeOptionNames } from './boundaryFill.enums';

export const getBoundaryTerritoryTypeDropdownOptions = (t: TranslationFnc): ReadonlyArray<DropdownOption<BoundaryTerritoryType>> => {
  return [
    getBoundaryTerritoryTypeDropdownOption(BoundaryTerritoryType.Manual, t),
    getBoundaryTerritoryTypeDropdownOption(BoundaryTerritoryType.Numeric, t),
    getBoundaryTerritoryTypeDropdownOption(BoundaryTerritoryType.Groups, t),
    getBoundaryTerritoryTypeDropdownOption(BoundaryTerritoryType.Density, t),
    getBoundaryTerritoryTypeDropdownOption(BoundaryTerritoryType.Demographic, t),
  ];
};

const getBoundaryTerritoryTypeDropdownOption = (boundaryTerritoryType: BoundaryTerritoryType, t: TranslationFnc): DropdownOption<BoundaryTerritoryType> => ({
  name: boundaryTerritoryTypeOptionNames(t)[boundaryTerritoryType], value: boundaryTerritoryType,
});

export const isBoundaryTerritoryEmpty = (
  boundaryTerritoryAssignments: ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment>,
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId,
  boundaryTerritory: BoundaryTerritory,
): boolean => {
  const assignments = boundaryTerritoryAssignments.get(boundaryTerritoryGroupId);
  if (!assignments) {
    return false;
  }

  const territoryAssignments = assignments.territoryAssignments.get(boundaryTerritory.boundaryTerritoryId);
  if (!territoryAssignments || territoryAssignments.size === 0) {
    return true;
  }

  return false;
};
