import {
  css, type Interpolation, type SerializedStyles,
} from '@emotion/react';
import styled from '@emotion/styled';
import { type Placement } from '@floating-ui/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown, faCaretUp,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useMemo, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { OverlayLoaderComponent } from '../../components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '../../components/overlay/overlayWrapper.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import {
  getTextInputIconFontSize, InputSize,
} from '../inputs';
import { usePointerClickEvent } from '../utils/usePointerClickEvent';
import {
  DEFAULT_DROPDOWN_PLACEMENT, DropdownComponent, DropdownPlacement,
} from './dropdown.component';
import {
  DropdownItemComponent, type DropdownItemComponentProps,
} from './dropdownItem.component';
import {
  DropDownItemSize, getRegularDropdownTriggerSize,
} from './regularDropdown.helpers';

const getDropdownTriggerBorderRadius = (placement: Placement) => {
  switch (placement) {
    case DropdownPlacement.TopEnd:
    case DropdownPlacement.TopStart:
    case DropdownPlacement.Top: {
      return '0 0 4px 4px';
    }
    case DropdownPlacement.BottomEnd:
    case DropdownPlacement.BottomStart:
    case DropdownPlacement.Bottom: {
      return '4px 4px 0 0';
    }
    case DropdownPlacement.Left:
    case DropdownPlacement.LeftStart:
    case DropdownPlacement.LeftEnd: {
      return '0 4px 4px 0';
    }
    case DropdownPlacement.Right:
    case DropdownPlacement.RightStart:
    case DropdownPlacement.RightEnd: {
      return '4px 0 0 4px';
    }
    default: {
      return 4;
    }
  }
};

const DropdownTriggerButton = styled.button<Readonly<{
  disabled?: boolean;
  isOpen: boolean;
  placement: Placement;
  triggerHeight: number;
}>>((
  { theme, triggerHeight, isOpen, disabled, placement }
) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.textColors.primary,
  backgroundColor: isOpen ? theme.backgroundColors.highlight : theme.dropdownColors.triggerBackground,
  height: triggerHeight,
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 500,
  textAlign: 'left',
  padding: '6px 8px',
  transition: 'background .1s',
  outline: 'none',
  width: '100%',
  borderRadius: isOpen ? getDropdownTriggerBorderRadius(placement) : 4,
  border: `1px solid ${isOpen ? theme.borderColors.activeItem : theme.borderColors.primary}`,
  opacity: disabled ? 0.7 : 1,

  '&:hover': {
    ...(disabled ? {} : { backgroundColor: theme.backgroundColors.highlight }),
  },
}));

const triggerIconStyle = css({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: '100%',
  marginLeft: 'auto',
});

const prefixTriggerIconStyle = ({ theme, triggerSize }: ThemeProps<{ triggerSize: InputSize }>) => css({
  color: theme.iconColors.secondary,
  display: 'inline-block',
  fontSize: getTextInputIconFontSize(triggerSize),
  padding: '0 6px 0 4px',
});

const caretDownIconStyle = (isDropdownOpen: boolean) => css(triggerIconStyle, {
  transform: isDropdownOpen ? 'rotate(-180deg)' : undefined,
  transition: 'transform .2s',
});

const dropdownContainerStyle = css({
  display: 'flex',
});

const triggerPlaceholderStyle = (
  { theme, allowWordWrapping }: ThemeProps<{ allowWordWrapping?: boolean }>
) => css(ellipsisStyle({ allowWordWrapping }), {
  color: theme.textColors.primary,
  display: 'flex',
  opacity: .4,
});

const ellipsisStyle = ({ allowWordWrapping }: { readonly allowWordWrapping?: boolean }) => css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: allowWordWrapping ? 'initial' : 'nowrap',
});

const iconStyle = css({
  fontSize: 16,
  marginRight: 3,
});

const getDropdownOptionBorderStyle = (placement: Placement) => {
  switch (placement) {
    case DropdownPlacement.TopEnd:
    case DropdownPlacement.TopStart:
    case DropdownPlacement.Top:
    case DropdownPlacement.Left:
    case DropdownPlacement.LeftStart:
    case DropdownPlacement.LeftEnd:
    case DropdownPlacement.Right:
    case DropdownPlacement.RightStart:
    case DropdownPlacement.RightEnd: {
      return css({
        '&:first-of-type': {
          borderTop: 'none',
        },
      });
    }
    default: {
      return {};
    }
  }
};

export type DropdownOption<T> = {
  style?: SerializedStyles;
  value: T;
} & Pick<DropdownItemComponentProps, 'icon' | 'prefix' | 'name' | 'isDisabled'>;

export type RegularDropdownProps<T> = Readonly<{
  className?: string;
  excludeSelectedOption?: boolean;
  value: T | null;
  fallbackName?: string;
  isDisabled?: boolean;
  onChange: (selectedValue: T) => void;
  options: ReadonlyArray<DropdownOption<T>>;
  triggerStyle?: Interpolation;
  triggerPlaceholderStyle?: Interpolation;
  triggerIconStyle?: Interpolation;
  prefixTriggerIconStyle?: Interpolation;
  triggerIcon?: IconProp;
  prefixTriggerIcon?: IconProp;
  dropdownContainerStyle?: Interpolation;
  listStyle?: Interpolation;
  itemStyle?: Interpolation;
  itemCustomStyle?: (item: T) => Interpolation;
  itemIconStyle?: Interpolation;
  placeholder?: string;
  placeholderIcon?: IconProp;
  placeholderIconStyle?: Interpolation;
  inPortal?: boolean;
  allowWordWrappingInSelectedOption?: boolean;
  compareOptions?: (a: T | null, b: T | null) => boolean;
  isLoading?: boolean;
  inputSize?: InputSize;
  itemSize?: DropDownItemSize; // is relative to input size
  dropdownPlacement?: Placement;
  disableAutoHeight?: boolean;
  testid?: string;
}>;

export const dropdownItemsContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

export const RegularDropdownComponent = <T extends unknown>(props: RegularDropdownProps<T>) => {
  const theme = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const triggerSize = props.inputSize ?? InputSize.Medium;
  const triggerHeight = getRegularDropdownTriggerSize(triggerSize);
  const itemSize = props.itemSize ?? DropDownItemSize.Default;
  const dropdownPlacement = props.dropdownPlacement ?? DEFAULT_DROPDOWN_PLACEMENT;

  const compareOptions = useMemo(() => (
    props.compareOptions ? props.compareOptions : ((a: T | null, b: T | null) => a === b)
  ), [props.compareOptions]);

  const activeOptionName: string = useMemo(
    () => {
      return props.options.find(item => compareOptions(item.value, props.value))?.name ?? props.fallbackName ?? '';
    },
    [compareOptions, props.value, props.options, props.fallbackName]
  );

  const activeOptionIcon: IconProp | null = useMemo(
    () => {
      return props.options.find(item => compareOptions(item.value, props.value))?.icon ?? null;
    },
    [compareOptions, props.value, props.options]
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(isOpen => !isOpen);
  };

  const { onClick, onPointerDown, onPointerUp } = usePointerClickEvent(toggleDropdown);

  const closeDropdown = () => setIsDropdownOpen(false);

  const dropdownOptions = useMemo(() => {
    if (props.excludeSelectedOption) {
      return props.options.filter(item => item.value !== props.value);
    }

    return props.options;
  }, [props.excludeSelectedOption, props.options, props.value]);

  const DropdownTrigger = (
    <DropdownTriggerButton
      css={props.triggerStyle}
      data-testid={props.testid}
      disabled={props.isDisabled}
      isOpen={isDropdownOpen}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      placement={dropdownPlacement}
      triggerHeight={triggerHeight}
    >
      {props.prefixTriggerIcon && (
        <div css={[prefixTriggerIconStyle({ theme, triggerSize }), props.prefixTriggerIconStyle]}>
          <FontAwesomeIcon icon={props.prefixTriggerIcon} />
        </div>
      )}
      {activeOptionName !== '' ? (
        <span css={ellipsisStyle({ allowWordWrapping: props.allowWordWrappingInSelectedOption })}>
          {!!activeOptionIcon && (
            <>
              <FontAwesomeIcon
                icon={activeOptionIcon}
                css={[iconStyle, props.itemIconStyle]}
              />
            </>
          )}
          {activeOptionName}
        </span>
      ) : (
        <span
          css={[
            triggerPlaceholderStyle({ theme, allowWordWrapping: props.allowWordWrappingInSelectedOption }),
            props.triggerPlaceholderStyle,
          ]}
        >
          {props.placeholderIcon && (
            <div css={[iconStyle, props.placeholderIconStyle]}>
              <FontAwesomeIcon icon={props.placeholderIcon} />
            </div>
          )}
          {props.placeholder}
        </span>
      )}
      {props.triggerIcon ? (
        <div css={[triggerIconStyle, props.triggerIconStyle]}>
          <FontAwesomeIcon icon={isDropdownOpen ? faCaretUp : props.triggerIcon} />
        </div>
      ) : (
        <div css={[caretDownIconStyle(isDropdownOpen), props.triggerIconStyle]}>
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      )}
    </DropdownTriggerButton>
  );

  return (
    <DropdownComponent
      isOpen={isDropdownOpen}
      onClose={closeDropdown}
      css={[dropdownContainerStyle, props.dropdownContainerStyle]}
      className={props.className}
      triggerComponent={DropdownTrigger}
      menuStyle={props.listStyle}
      inPortal={props.inPortal}
      dropdownPlacement={props.dropdownPlacement}
      disableAutoHeight={props.disableAutoHeight}
    >
      <div css={dropdownItemsContainerStyle}>
        {props.isLoading && (
          <OverlayWrapperComponent minHeight={100} >
            <OverlayLoaderComponent
              loaderSize={45}
            />
          </OverlayWrapperComponent>
        )}
        {!props.isLoading && dropdownOptions.map((item, index) => (
          <DropdownItemComponent
            css={[props.itemStyle, props.itemCustomStyle?.(item.value), item.style, getDropdownOptionBorderStyle(dropdownPlacement)]}
            key={JSON.stringify(item.value) + index}
            onClick={item.isDisabled ? undefined : () => {
              props.onChange(item.value);
            }}
            icon={item.icon}
            itemIconStyle={props.itemIconStyle}
            name={item.name}
            size={triggerSize}
            subSize={itemSize}
            prefix={item.prefix}
            isDisabled={item.isDisabled}
          />
        ))}
      </div>
    </DropdownComponent>
  );
};
