import {
  type SelectedDataMetric, SelectedDataType,
} from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type BoundaryGroupDemographic } from '../../../store/boundaryGroups/boundaryGroups.repository';

export type DemographicMetricModel = {
  type: SelectedDataType.Demographic;
  demographic: BoundaryGroupDemographic;
};

export const createDemographicMetricFromSelectedData = (selectedData: SelectedDataMetric): DemographicMetricModel | null => {
  if (selectedData.type !== SelectedDataType.Demographic) {
    return null;
  }

  return ({
    type: SelectedDataType.Demographic,
    demographic: selectedData.selectedDemographic,
  });
};

export const createSelectedDataFromDemographicMetric = (metric: DemographicMetricModel): SelectedDataMetric => {
  return ({
    type: SelectedDataType.Demographic,
    selectedDemographic: metric.demographic,
  });
};

const removeBoundaryGroupPrefix = (demographicsId: string) => {
  const delimiterIndex = demographicsId.indexOf('|');

  if (delimiterIndex === -1) {
    return null;
  }

  return demographicsId.substring(delimiterIndex + 1);
};

export const matchBoundaryGroupDemographic = (demographicId: string, otherDemographicId: string) => {
  const withoutPrefix = removeBoundaryGroupPrefix(demographicId);
  const otherWithoutPrefix = removeBoundaryGroupPrefix(otherDemographicId);

  return !!withoutPrefix && !!otherWithoutPrefix && withoutPrefix === otherWithoutPrefix;
};
