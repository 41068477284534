import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faSort as duoToneSort } from '@fortawesome/pro-duotone-svg-icons/faSort';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, memo, useCallback, useRef,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { CheckboxComponent } from '../../../baseComponents/checkbox';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import {
  SELECTED_OR_HIGHLIGHTED_CELL, SPREADSHEET_CELL_MIN_WIDTH,
} from '../cell/spreadsheetCell.component';
import { getNextSpreadsheetSortType } from '../spreadsheet.helpers';
import {
  type SpreadsheetHeaderColumn, type SpreadsheetSortType,
} from '../spreadsheet.types';

const HEADER_CHECKBOX_WIDTH = 30;
const HEADER_HEIGHT = 24;
const RESIZER_WIDTH = 4;
const RESIZER_RIGHT = -2;
const HEADER_BORDER_WIDTH = 1;
const UNIFIED_PADDING = 2;

const headerRowStyle = css({
  display: 'flex',
  width: '100%',
  userSelect: 'none',
});

export const spreadsheetCellsWrapperStyle = ({ theme, isHoverable }: ThemeProps<{ isHoverable?: boolean }>) => css({
  background: theme.backgroundColors.primary,
  display: 'flex',
  flex: 1,
  width: `calc(100% - ${HEADER_CHECKBOX_WIDTH}px)`,

  ...(isHoverable ? {
    '&:hover': {
      background: theme.backgroundColors.secondaryHover,
      [`> .${SELECTED_OR_HIGHLIGHTED_CELL}`]: {
        backgroundColor: theme.backgroundColors.highlightHover,
      },
    },
  } : {}),
});

const headerCheckboxWrapperStyle = css({
  width: HEADER_CHECKBOX_WIDTH,
  display: 'flex',
  justifyContent: 'center',
});

const SORT_INDICATOR_ICON_WIDTH = '20px';

const headerColumnStyle = ({ theme, width, minWidth }: ThemeProps<{ width?: string | number; minWidth?: string | number }>) => css({
  alignItems: 'center',
  background: theme.backgroundColors.secondary,
  border: `${HEADER_BORDER_WIDTH}px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  display: 'flex',
  flexShrink: 0,
  height: HEADER_HEIGHT,
  minWidth,
  position: 'relative',
  whiteSpace: 'nowrap',
  width,
});

const HeaderColumn = styled.div(({ theme, width, minWidth }: ThemeProps<{
  width?: string | number; minWidth?: string | number;
}>) => headerColumnStyle({ theme, width, minWidth }));

const sortIndicatorIconStyle = css({
  visibility: 'hidden',
  width: 0,

  [`${HeaderColumn}:hover &`]: {
    visibility: 'visible',
    padding: '0 4px',
    width: SORT_INDICATOR_ICON_WIDTH,
  },
});

const headerResizerStyle = css({
  width: RESIZER_WIDTH,
  minWidth: RESIZER_WIDTH,
  height: HEADER_HEIGHT,
  boxSizing: 'border-box',
  background: 'transparent',
  cursor: 'col-resize',
  position: 'absolute',
  right: RESIZER_RIGHT - HEADER_BORDER_WIDTH,
  zIndex: 1,
});

const resizePlaceholderStyle = css({
  width: 25,
  minWidth: 25,
});

const headerButtonStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.backgroundColors.secondary,
  border: 'none',
  boxSizing: 'border-box',
  color: theme.textColors.primary,
  height: '100%',
  paddingLeft: UNIFIED_PADDING,
  width: '100%',
});

const headerNameStyle = css({
  overflow: 'hidden',
  paddingRight: UNIFIED_PADDING,
  width: `calc(100% - ${UNIFIED_PADDING}px)`,
  [`${HeaderColumn}:hover &`]: {
    width: `calc(100% - ${SORT_INDICATOR_ICON_WIDTH}px)`,
  },
});

const iconStyle = ({ isAsc }: {isAsc: boolean}) => css({
  transform: isAsc ? 'rotate(-180deg)' : undefined,
  width: SORT_INDICATOR_ICON_WIDTH,
});

const sortIndexStyle = css({
  marginLeft: UNIFIED_PADDING,
  display: 'block',
  padding: `0 ${UNIFIED_PADDING}px`,
});

const sortWrapperStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.highlight,
  boxShadow: `0 0 0 2px ${theme.borderColors.primary}`,
  height: '100%',
  justifyContent: 'center',
  marginLeft: UNIFIED_PADDING,

  '&:hover': {
    backgroundColor: theme.backgroundColors.highlightHover,
  },
});

type SpreadsheetHeaderProps = Readonly<{
  columns: SpreadsheetHeaderColumn[];
  areAllRowsSelected: boolean;
  useResizePlaceholder?: boolean;
  onSortChange?: (sortChange: { columnId: string; newSort: SpreadsheetSortType | null }) => void;
  onSelectAll: () => void;
  getColumnWidth: (columnId: string) => string | number;
  onResizerPointerDown: (columnId: string, initialWidth: number, evDown: React.PointerEvent) => void;
  selectableRows: boolean;
}>;

type SpreadsheetResizableHeaderProps = Readonly<{
  index: number;
  column: SpreadsheetHeaderColumn;
  width: string | number;

  onSortChange?: (index: number) => void;
  onResizePointerDown: (columnId: string, initialWidth: number, evDown: React.PointerEvent) => void;
}>;

const SpreadsheetResizableHeaderComponent: FC<SpreadsheetResizableHeaderProps> = props => {
  const theme = useTheme();
  const { index, column, onSortChange } = props;

  const headerElementRef = useRef<HTMLDivElement>(null);

  const onResizePointerDownRef = useRef(props.onResizePointerDown);
  onResizePointerDownRef.current = props.onResizePointerDown;

  const onResizePointerDown = useCallback((e: React.PointerEvent) => {
    if (headerElementRef.current) {
      const width = headerElementRef.current.getBoundingClientRect().width;
      onResizePointerDownRef.current(column.id, width, e);
    }
  }, [column.id]);

  return (
    <HeaderColumn
      key={column.id}
      minWidth={SPREADSHEET_CELL_MIN_WIDTH}
      ref={headerElementRef}
      theme={theme}
      width={props.width}
    >
      <div
        css={headerButtonStyle({ theme })}
        onClick={() => onSortChange?.(index)}
      >

        <div css={headerNameStyle}> {column.name} </div>

        {column.sort ? (
          <span css={sortWrapperStyle({ theme })}>
            <span css={sortIndexStyle}>
              {column.sort.index}
            </span>
            <FontAwesomeIcon
              css={iconStyle({ isAsc: column.sort.type === 'asc' })}
              icon={duoToneSort}
            />
          </span>
        ) : (props.onSortChange && (
          <FontAwesomeIcon
            css={sortIndicatorIconStyle}
            icon={faSort}
          />
        ))}
      </div>

      <span
        css={headerResizerStyle}
        onPointerDown={onResizePointerDown}
      />
    </HeaderColumn>
  );
};

const SpreadsheetHeaderComponent: FC<SpreadsheetHeaderProps> = (props) => {
  const theme = useTheme();

  const onSortChange = useCallback((columnIndex: number) => {
    const column = props.columns[columnIndex];
    const sort = props.onSortChange;

    if (!column || !sort) {
      return;
    }

    sort({
      columnId: column.id,
      newSort: getNextSpreadsheetSortType(column.sort?.type ?? null),
    });
  }, [props.columns, props.onSortChange]);

  return (
    <div css={headerRowStyle}>
      {
        props.selectableRows && (
          <div css={[headerCheckboxWrapperStyle, headerColumnStyle({ theme })]}>
            <CheckboxComponent
              isChecked={props.areAllRowsSelected}
              checkedSetter={props.onSelectAll}
            />
          </div>
        )
      }

      <div css={spreadsheetCellsWrapperStyle({ theme })}>
        {props.columns.map((column, index) => (
          <SpreadsheetResizableHeaderComponent
            key={column.id}
            index={index}
            column={column}
            width={props.getColumnWidth(column.id)}
            onSortChange={(!column.disabled && props.onSortChange) ? onSortChange : undefined}
            onResizePointerDown={props.onResizerPointerDown}
          />
        ))}
        {props.useResizePlaceholder && <div css={resizePlaceholderStyle} />}
      </div>
    </div>
  );
};

const pureComponent = memo(SpreadsheetHeaderComponent);
export { pureComponent as SpreadsheetHeaderComponent };
