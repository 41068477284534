import { type ReactNode } from 'react';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { LogoComponent } from '~/_shared/components/logo/logo.component';
import {
  containerStyle, textStyle,
} from './errorPageContentGuardianDog.styles';

export type AccessForbiddenProps = {
  children: ReactNode;
};

export const ErrorPageContentGuardianDogComponent = (props: AccessForbiddenProps) => {
  return (
    <div css={containerStyle}>
      <LogoComponent width="250px" />
      <LottieAnimationComponent
        type={LottieAnimationTypes.SmudgeDog}
        size={200}
        autoplay
        loop
      />
      <div css={textStyle}>
        {props.children}
      </div>
    </div>
  );
};
