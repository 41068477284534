import {
  faPause, faPlay, faStop,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  type MapConfirmButtonProps, MapConfirmComponent,
} from '../../_shared/mapConfirm/mapConfirm.component';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  drawingToolClearActiveTool,
  drawingToolDisableDrawingMode,
  drawingToolEnableDrawingMode,
} from '../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
} from '../../store/frontendState/mapTools/drawingTool/drawingTool.selectors';

export const DrawingToolAddConfirmContainer: FC = (_props) => {
  const drawingToolMode = useDrawingToolModeSelector();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const showManagement = useCallback(() => {
    dispatch(drawingToolClearActiveTool());
  }, [dispatch]);

  const activateDrawingMode = useCallback(() => {
    dispatch(drawingToolEnableDrawingMode());
  }, [dispatch]);

  const pauseDrawingMode = useCallback(() => {
    dispatch(drawingToolDisableDrawingMode());
  }, [dispatch]);

  const buttons = useMemo(() => {
    const buttons: Array<MapConfirmButtonProps> = [];

    if (drawingToolMode === DrawingToolMode.Stopped) {
      buttons.push({
        icon: faPlay,
        onClick: activateDrawingMode,
        buttonStyle: ButtonStyle.Success,
        label: t('Start Drawing'),
      });
    }

    if ([DrawingToolMode.Paused, DrawingToolMode.DrawingItems].includes(drawingToolMode)) {
      buttons.push({
        icon: faStop,
        onClick: showManagement,
        buttonStyle: ButtonStyle.Danger,
        label: t('Stop Drawing'),
      });
    }

    if (drawingToolMode === DrawingToolMode.Paused) {
      buttons.push({
        icon: faPlay,
        onClick: activateDrawingMode,
        buttonStyle: ButtonStyle.Success,
        label: t('Resume Drawing'),
      });
    }

    if (drawingToolMode === DrawingToolMode.DrawingItems) {
      buttons.push({
        icon: faPause,
        onClick: pauseDrawingMode,
        buttonStyle: ButtonStyle.Primary,
        label: t('Pause Drawing'),
      });
    }

    return buttons;
  }, [activateDrawingMode, drawingToolMode, pauseDrawingMode, showManagement, t]);

  if (drawingToolMode === DrawingToolMode.Inactive) {
    return null;
  }

  let heading = t('Draw Element');

  if (drawingToolMode === DrawingToolMode.Paused) {
    heading = t('Continue Drawing');
  }

  return (
    <MapConfirmComponent
      heading={heading}
      isOpen
      buttons={buttons}
    />
  );
};
