import { useCallback } from 'react';
import { computeDistanceBetween } from 'spherical-geometry-js';
import { type LatLng } from '~/_shared/types/latLng';
import {
  jtsParse, operations,
} from '~/_shared/types/polygon/jsts.utils';
import {
  convertLatLngToCoordinate, convertMultiPolygonToGeometry,
} from '~/_shared/types/polygon/polygon.utils';
import {
  type GroupRadiusProximity, isDriveTimePolygon, isGroupRadius, isIndividualRadius, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { notNull } from '~/_shared/utils/typeGuards';
import { convertUnitToMeters } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { findRowIdsToRenderForColumnIdInGroup } from '~/proximity/proximity.helpers';
import { checkIfSpreadsheetRowIdMeetsFilteringCriteria } from '~/store/spreadsheetData/spreadsheetData.helpers';
import {
  useFilteredSpreadsheetRowIds, useLatLngSpreadsheetData, useSpreadSheetData,
} from '../useSpreadsheetData.hook';

type GroupRadiusProximityLocation = Readonly<{ spreadsheetRowId: SpreadsheetRowId; latLng: LatLng | null }>;
export type GroupRadiusProximityLocations = ReadonlyArray<GroupRadiusProximityLocation>;

export const useProximityLocation = () => {
  const spreadsheetData = useSpreadSheetData().spreadsheetData;
  const latLngData = useLatLngSpreadsheetData();
  const { filteredRowIds } = useFilteredSpreadsheetRowIds();

  const getGroupRadiusProximityLocations = useCallback((proximity: GroupRadiusProximity, ignoreFilters: boolean = false): GroupRadiusProximityLocations => {
    const { columnId, group: groupId } = proximity.data.data;
    const rowIds = findRowIdsToRenderForColumnIdInGroup(spreadsheetData, columnId, groupId);

    return rowIds.map((rowId) => {
      const spreadsheetRowId = {
        spreadsheetId: columnId.spreadsheetId,
        rowId,
      };

      if (!ignoreFilters && !checkIfSpreadsheetRowIdMeetsFilteringCriteria(filteredRowIds, spreadsheetRowId)) {
        return { spreadsheetRowId, latLng: null };
      }

      const latLng = latLngData.getRow(spreadsheetRowId);

      return {
        spreadsheetRowId,
        latLng: latLng ?? null,
      };
    }).filter(notNull);

  }, [filteredRowIds, latLngData, spreadsheetData]);

  const isProximityAtLocation = useCallback((proximity: Proximity, point: LatLng) => {
    if (isIndividualRadius(proximity)) {
      return computeDistanceBetween(point, proximity.data.data) < convertUnitToMeters(proximity.data.radius, proximity.data.unit);
    }

    if (isGroupRadius(proximity)) {
      return getGroupRadiusProximityLocations(proximity)
        .some(loc => !!loc.latLng && computeDistanceBetween(point, loc.latLng) < convertUnitToMeters(proximity.data.radius, proximity.data.unit));
    }

    if (isDriveTimePolygon(proximity)) {
      const clickPoint = jtsParse({
        type: 'Point',
        coordinates: convertLatLngToCoordinate(point),
      });
      return !!proximity.data.paths && operations.relate.intersects(clickPoint, jtsParse(convertMultiPolygonToGeometry(proximity.data.paths)));
    }

    return false;
  }, [getGroupRadiusProximityLocations]);

  return {
    getGroupRadiusProximityLocations,
    isProximityAtLocation,
  };
};
