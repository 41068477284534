import { useSelector } from 'react-redux';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { type AppState } from '~/store/app.store';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryFilter,
  type BoundaryFiltersState,
  type BoundaryLocationsFilter,
  type BoundaryLocationsFiltersState,
  type FilteredBoundaryItems,
} from './mapSettingsToolsStateBoundary.state';

export const emptyBoundaryTerritoryGroupFilter: BoundaryFilter = {
  filteredBoundaries: [],
  filteredBoundaryTerritories: [],
  showAll: TriStateRange.Full,
};

export const emptyBoundaryLocationsFilter: BoundaryLocationsFilter = {
  filteredBoundaries: [],
  filteredBoundaryTerritories: [],
};

type BoundaryFiltersGeneric<T extends FilteredBoundaryItems> = {
  get: (boundaryGroupId: BoundaryGroupId) => T;
  entries: ReadonlyArray<{boundaryGroupId: BoundaryGroupId; filter: T}>;
};

export type BoundaryFilters = BoundaryFiltersGeneric<BoundaryFilter>;
export type BoundaryLocationsFilters = BoundaryFiltersGeneric<BoundaryLocationsFilter>;

// Boundary Filters Selectors
export const boundaryFiltersStateSelector = (state: AppState): BoundaryFiltersState =>
  state.map.mapSettings.data.toolsState.boundary.filters;

export const boundaryFiltersSelector = (state: AppState): BoundaryFilters =>
  getBoundaryTerritoryGroupFiltersMemoized(boundaryFiltersStateSelector(state));

export const useBoundaryFilters = () =>
  useSelector(boundaryFiltersSelector);

// Boundary Locations Filters Selectors
export const boundaryLocationsFiltersStateSelector = (state: AppState): BoundaryLocationsFiltersState =>
  state.map.mapSettings.data.toolsState.boundary.locationsFilter;

export const boundaryLocationsFiltersSelector = (state: AppState): BoundaryLocationsFilters =>
  getBoundaryTerritoryGroupLocationsFiltersMemoized(boundaryLocationsFiltersStateSelector(state));

export const useBoundaryLocationsFilters = () =>
  useSelector(boundaryLocationsFiltersSelector);

const getBoundaryTerritoryGroupFiltersMemoized = memoizeWeak((filters: BoundaryFiltersState): BoundaryFilters => ({
  get: (boundaryGroupId: BoundaryGroupId) => filters[boundaryGroupId] ?? emptyBoundaryTerritoryGroupFilter,
  entries: Object.entries(filters).filter(([_, filter]) => !!filter).map(([key, filter]) => ({
    boundaryGroupId: parseInt(key, 10),
    filter: filter as BoundaryFilter,
  })),
}));

export const getBoundaryTerritoryGroupLocationsFiltersMemoized = memoizeWeak((filters: BoundaryLocationsFiltersState): BoundaryLocationsFilters => ({
  get: (boundaryGroupId: BoundaryGroupId) => filters[boundaryGroupId] ?? emptyBoundaryLocationsFilter,
  entries: Object.entries(filters)
    .filter((pair): pair is [string, BoundaryLocationsFilter] => {
      const [_, filter] = pair;
      return !!filter && (!!filter.filteredBoundaries.length || !!filter.filteredBoundaryTerritories.length);
    })
    .map(([key, filter]) => ({
      boundaryGroupId: parseInt(key, 10),
      filter: filter satisfies BoundaryLocationsFilter,
    })),
}));
