export enum FetchableResourceStatus {
  NotFound = 'NotFound',
  Loading = 'Loading',
  Loaded = 'Loaded',
  Forbidden = 'Forbidden',
  NextPageRequested = 'NextPageRequested',
}

export type FetchableResource<T> = {
  status: FetchableResourceStatus;
  value?: T;
};
