import { useMemo } from 'react';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import { cloneDeep } from '~/_shared/utils/object/deepMerge';
import { useSpreadSheetData } from '~/map/map/useSpreadsheetData.hook';
import { generalPanelSettingsInitialState } from '~/store/mapSettings/markers/mapSettingsMarkers.reducer';
import { useMapSettingsMarkersPanelSettingsSelector } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import {
  type PanelSettingsColumnItemMapState, type PanelSettingsState,
} from '~/store/mapSettings/markers/mapSettingsMarkers.state';
import { type SpreadSheetData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';

const ensureAllColumns = (
  settings: PanelSettingsColumnItemMapState,
  spreadsheetData: SpreadSheetData
): PanelSettingsColumnItemMapState => {
  let result = settings;

  spreadsheetData.spreadsheetIds.forEach(spreadsheetId => {
    const columns = spreadsheetData.matchupData[spreadsheetId]?.data?.columns;
    if (!columns) {
      return;
    }

    Object.keys(columns).forEach(columnId => {
      if (!result[spreadsheetId]?.[columnId]) {

        // clone current settings before first update
        if (result === settings) {
          result = cloneDeep(settings);
        }

        const perSpreadsheet = result[spreadsheetId] = result[spreadsheetId] ?? {};

        perSpreadsheet[columnId] = {
          id: { columnId, spreadsheetId },
          isActive: true,
        };
      }
    });
  });

  return result;
};

const initialPanelSettings = {
  generalSettings: generalPanelSettingsInitialState,
  bulkStyle: undefined, // undefined uses default styles for current theme
  locationDescriptionStyle: undefined, // undefined uses default styles for current theme
  orderedBasicItems: [], // unordered, default order generated in component
  orderedContactItems: [], // unordered, default order generated in component
};

const useInitializedPanelSettings = (panelSettings?: PanelSettingsState): PanelSettingsState => {
  const spreadsheetData = useSpreadSheetData();
  const columnItems = useMemo(() => ensureAllColumns(panelSettings?.itemsData ?? newPerSpreadsheetMap(), spreadsheetData),
    [panelSettings?.itemsData, spreadsheetData]);

  return useMemo(() => ({
    ...initialPanelSettings,
    ...panelSettings,
    itemsData: columnItems,
  }), [columnItems, panelSettings]);
};

export const useDefaultPanelSettingsStateSelector = (): PanelSettingsState => {
  return useInitializedPanelSettings();
};

export const usePanelSettingsStateSelector = (): PanelSettingsState => {
  const panelSettings = useMapSettingsMarkersPanelSettingsSelector();
  return useInitializedPanelSettings(panelSettings);
};
