import {
  type FC, useMemo,
} from 'react';
import {
  type ChangeableSettingRow,
  type SettingColumn,
  type SettingRow,
  type SpreadsheetColumnIdSettingsRow,
} from '../_shared/components/settingsTable/settingsTable.types';
import { SettingsModalWithSpreadsheetSelectComponent } from '../_shared/components/spreadsheetSettingsWithSelector/settingsModalWithSpreadsheetSelectComponent';
import { type SpreadsheetColumnId } from '../_shared/types/spreadsheetData/spreadsheetColumn';
import { type ModalProps } from '../modal/modalType.enum';
import {
  useIsMapSettingsSyncInProgressSelector,
  useIsSpreadsheetDataFetchingInProgressSelector,
} from '../store/frontendState/processing/processing.selectors';
import { usePresentationalColumnsRestrictionsIsLoadingSelector } from '../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.selectors';
import { useSpreadsheetIdsSelector } from '../store/selectors/useSpreadsheetIdsSelector';

type PresentationalColumnsRestrictionsProps = ModalProps<{
  modalTitle: string;
  columnNameLabel: string;
  toggleColumnLabel: string;
  onToggleClick: (spreadsheetColumnId: SpreadsheetColumnId, newValue: boolean) => void;
  settingsDataRows: SpreadsheetColumnIdSettingsRow[];
  selectedSpreadsheetId: number | null;
  onSelectedSpreadsheetIdChange: (spreadsheetId: number) => void;
}>;

export const PresentationalColumnsRestrictionsContainer: FC<PresentationalColumnsRestrictionsProps> = (props) => {
  const isSpreadsheetDataLoading = useIsSpreadsheetDataFetchingInProgressSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const isColumnsRestrictionsLoading = usePresentationalColumnsRestrictionsIsLoadingSelector();
  const spreadsheetIds = useSpreadsheetIdsSelector();

  const onOnToggle = (rowIndex: number, newValue: boolean) => {
    const spreadsheetColumnId = props.settingsDataRows[rowIndex].columnId;

    props.onToggleClick(spreadsheetColumnId, !newValue);
  };

  const onSettingRowChange = (rowIndex: number, newRow: ChangeableSettingRow) => {
    const currentRow: SettingRow = props.settingsDataRows[rowIndex].row;

    // on/off
    if (typeof newRow.data[1].value === 'boolean' && currentRow.data[1] !== newRow.data[1]) {
      onOnToggle(rowIndex, newRow.data[1].value);
    }
  };

  const settingColumns: SettingColumn[] = useMemo(() => [{
    caption: props.columnNameLabel,
    width: '50%',
  }, {
    caption: props.toggleColumnLabel,
    align: 'center',
    width: '50%',
  }], [props.columnNameLabel, props.toggleColumnLabel]);

  return (
    <SettingsModalWithSpreadsheetSelectComponent
      {...props}
      title={props.modalTitle}
      currentSelectedSpreadsheetId={props.selectedSpreadsheetId}
      onSelectedSpreadsheetIdChange={props.onSelectedSpreadsheetIdChange}
      spreadsheetIds={spreadsheetIds}
      settingRows={props.settingsDataRows.map(item => item.row)}
      onSettingRowChange={onSettingRowChange}
      settingColumns={settingColumns}
      isLoading={isSpreadsheetDataLoading || isMapSettingsSyncInProgress || isColumnsRestrictionsLoading}
    />
  );
};
