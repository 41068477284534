import { type LatLng } from '../../../../_shared/types/latLng';
import {
  FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT,
  FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER,
  FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT,
  FRONTEND_STATE_DISTANCE_CALCULATOR_START,
  FRONTEND_STATE_DISTANCE_CALCULATOR_STOP,
} from './distanceCalculator.actionTypes';
import { type DistanceCalculatorDrawingMode } from './distanceCalculator.state';

export const startDistanceCalculator = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_START,
}) as const;

export const stopDistanceCalculator = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_STOP,
}) as const;

export const hoverDistanceCalculatorRemoveButton = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER,
}) as const;

export const stopDistanceCalculatorRemoveButtonHover = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT,
}) as const;

export const hoverDistanceCalculatorOutline = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER,
}) as const;

export const stopDistanceCalculatorOutlineHover = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT,
}) as const;

export const startDistanceCalculatorOutlineDrag = (draggedInstanceId: Uuid) => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START,
  payload: {
    draggedInstanceId,
  },
}) as const;

export const stopDistanceCalculatorOutlineDrag = () => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP,
}) as const;

export const selectDistanceCalculatorDrawingMode = (mode: DistanceCalculatorDrawingMode, startingPoint?: LatLng) => ({
  type: FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED,
  payload: {
    mode,
    startingPoint,
  },
}) as const;
