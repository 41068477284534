import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DndType } from '~/_shared/constants/dndType.enum';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type ColumnRole, CONTACT_COLUMNS_ROLES,
} from '~/_shared/types/columnRole.enum';
import {
  type ColumnId, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { areDeepEqual } from '~/_shared/utils/equality/deepEqual.helper';
import { mergeDeep } from '~/_shared/utils/object/deepMerge';
import {
  createDataWithColumnPerSpreadsheetSettings, getDataFromColumnPerSpreadsheetSettings,
} from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import {
  defaultLocationDataStyle,
  isItemContactItem,
} from '~/customizeLocationPanel/customizeLocationPanel.constants';
import { SIDEBAR_TITLE_DESCRIPTION_STYLE } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.constants';
import { type SidebarTitleType } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.enums';
import {
  type SidebarTitleSettings,
  type SidebarTitleStyle,
} from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { type SpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { mapSettingsMarkersSavePanelSettings } from '~/store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import {
  changeDescriptionSettings,
  computeItemsOrder,
  toggleActiveStatus,
  updateStyle,
} from '../customizeLocationPanel.helpers';
import {
  getColumnItemById,
  modifyPanelSettingsColumnItemMap,
} from './locationData.helpers';
import { type BubbleItemsAndOrder } from './locationData.types';
import { getItemDnDType } from './locationDataItem/draggableItem.helpers';
import {
  convertPanelSettingsStateToPanelSettings, mergePanelSettingsToState, reorderColumnsWithPriority,
} from './panelSettings.helpers';
import {
  type ColumnStyleSettingsData, type GeneralPanelSettings, type PanelSettings, type PanelSettingsColumnItemMap,
} from './panelSettings.types';
import { useBubbleItemsAndOrder } from './useBubbleItemsAndOrder.hook';
import {
  useDefaultPanelSettingsStateSelector, usePanelSettingsStateSelector,
} from './usePanelSettingsState.selector';

export type PanelSettingsManagementProps = Readonly<{
  bubbleRecords: BubbleItemsAndOrder | null;
  areChangesPending: boolean;
  bulkStyle: ColumnStyleSettingsData;
  locationDescriptionStyle: SidebarTitleStyle | null;
  generalSettings: GeneralPanelSettings;
  selectedItemId: SpreadsheetColumnId | null;
  selectedLocationType: SidebarTitleType | null;

  activateItem: (itemId: SpreadsheetColumnId) => void;
  clickOutsideCallback: () => void;
  deactivateItem: (itemId: SpreadsheetColumnId) => void;
  dropItems: (sourceId: SpreadsheetColumnId, targetId?: SpreadsheetColumnId | undefined) => void;
  modifyGeneral?: (settings: Partial<GeneralPanelSettings>) => void;
  modifyItemVisuals: (style: Partial<ColumnStyleSettingsData>) => void;
  modifyLocationVisuals: (settings: Partial<SidebarTitleSettings>) => void;
  resetToDefault: () => void;
  resetToLastSaved: () => void;
  saveSettings: () => void;
  selectItem: (itemId: SpreadsheetColumnId | null) => void;
  selectLocationDescription: (itemName: SidebarTitleType | null) => void;
}>;

type UsePanelSettingsManagementProps = Readonly<{
  spreadsheetRowData: SpreadsheetRowData | null;
  spreadsheetRowId?: SpreadsheetRowId;
}>;

export const usePanelSettingsManagement = ({
  spreadsheetRowId,
  spreadsheetRowData,
}: UsePanelSettingsManagementProps): PanelSettingsManagementProps => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const defaultPanelSettingsState = useDefaultPanelSettingsStateSelector();
  const storedPanelSettingsState = usePanelSettingsStateSelector();
  const matchupData = useMatchupDataSelector();

  const defaultPanelSettings = useMemo(() => (
    convertPanelSettingsStateToPanelSettings(defaultPanelSettingsState, theme)
  ), [defaultPanelSettingsState, theme]);

  const storedPanelSettings = useMemo(() => (
    convertPanelSettingsStateToPanelSettings(storedPanelSettingsState, theme)
  ), [storedPanelSettingsState, theme]);

  const defaultBulkStyle = useMemo(() => defaultLocationDataStyle(theme, null), [theme]);

  const [customizedPanelSettings, setCustomizedPanelSettings] = useState<PanelSettings>(storedPanelSettings);

  const bubbleRecords = useBubbleItemsAndOrder({
    spreadsheetRowId,
    spreadsheetRowData,
    panelSettings: customizedPanelSettings,
  });

  const [selectedItemId, setSelectedItemId] = useState<SpreadsheetColumnId | null>(null);
  const [selectedLocationDescriptionType, setSelectedLocationDescriptionType] = useState<SidebarTitleType | null>(null);
  const [areChangesProbable, setAreChangesProbable] = useState(false);

  useEffect(() => {
    setSelectedItemId(null);
  }, [spreadsheetRowId]);

  useEffect(() => {
    // update panel settings when changed in store and no local changes are pending
    if (!areChangesProbable) {
      setCustomizedPanelSettings(storedPanelSettings);
    }
  }, [areChangesProbable, storedPanelSettings]);

  const handleUpdateGeneral = useMemo(() => {
    if (selectedItemId === null) {
      return (newSettings: Partial<GeneralPanelSettings>) => {
        setAreChangesProbable(true);
        setCustomizedPanelSettings((prevState) => ({
          ...prevState,
          generalSettings: {
            ...prevState.generalSettings,
            ...newSettings,
          },
        }));
      };
    }
    return undefined;
  }, [selectedItemId]);

  const handleSave = useCallback(() => {
    if (!spreadsheetRowId) {
      return;
    }

    setAreChangesProbable(false);

    dispatch(mapSettingsMarkersSavePanelSettings(
      mergePanelSettingsToState(customizedPanelSettings, storedPanelSettingsState, theme)
    ));
  }, [customizedPanelSettings, dispatch, spreadsheetRowId, storedPanelSettingsState, theme]);

  const deactivateItem = useCallback((itemId: SpreadsheetColumnId) => {
    setAreChangesProbable(true);
    setCustomizedPanelSettings(prevState => ({
      ...prevState,
      itemsData: toggleActiveStatus({
        itemId,
        status: false,
        columnSettings: prevState.itemsData,
      }),
    }));
  }, []);

  const activateItem = useCallback((itemId: SpreadsheetColumnId): void => {
    setAreChangesProbable(true);
    setCustomizedPanelSettings(prevState => ({
      ...prevState,
      itemsData: toggleActiveStatus({
        itemId,
        status: true,
        columnSettings: prevState.itemsData,
      }),
    }));
  }, []);

  const { allBasicColumnsWithOrder, allContactColumnsWithOrder } = useMemo(() => {
    const allBasicColumns: SpreadsheetColumnId[] = [];
    const allContactColumns: SpreadsheetColumnId[] = [];

    const itemMatchupData = spreadsheetRowId ? matchupData?.[spreadsheetRowId.spreadsheetId]?.data : null;

    if (!itemMatchupData || !spreadsheetRowId) {
      return {
        allBasicColumnsWithOrder: [],
        allContactColumnsWithOrder: [],
      };
    }
    const allColumnsIds: ReadonlyArray<ColumnId> = Array.from(Object.keys(itemMatchupData.columns));
    const contactColumnIds: ColumnId[] = [];

    if (itemMatchupData.categories) {
      Object.keys(itemMatchupData.categories).forEach((category: ColumnRole) => {
        const categoryColumn = itemMatchupData.categories[category].match;

        if (categoryColumn && CONTACT_COLUMNS_ROLES.includes(category)) {
          contactColumnIds.push(categoryColumn);
        }
      });
    }

    for (const columnId of allColumnsIds) {
      if (contactColumnIds.includes(columnId)) {
        allContactColumns.push({
          spreadsheetId: spreadsheetRowId.spreadsheetId,
          columnId,
        });
      }
      else {
        allBasicColumns.push({
          spreadsheetId: spreadsheetRowId.spreadsheetId,
          columnId,
        });
      }
    }

    // apply current map settings order to columns
    const allBasicColumnsWithOrder = reorderColumnsWithPriority(allBasicColumns, customizedPanelSettings.orderedBasicItems);
    const allContactColumnsWithOrder = reorderColumnsWithPriority(allContactColumns, customizedPanelSettings.orderedContactItems);

    return {
      allBasicColumnsWithOrder,
      allContactColumnsWithOrder,
    };
  }, [matchupData, spreadsheetRowId, customizedPanelSettings.orderedBasicItems, customizedPanelSettings.orderedContactItems]);

  const handleDropItems = useCallback((sourceId: SpreadsheetColumnId, targetId: SpreadsheetColumnId): void => {
    if (!bubbleRecords?.items) {
      return;
    }
    setAreChangesProbable(true);

    const sourceItem = getColumnItemById(bubbleRecords.items, sourceId);
    setCustomizedPanelSettings((prevState) => {

      if (getItemDnDType(sourceItem?.itemType) === DndType.LocalizationContactItem) {
        return ({
          ...prevState,
          orderedContactItems: computeItemsOrder(allContactColumnsWithOrder, sourceId, targetId),
        });
      }
      else {
        return ({
          ...prevState,
          orderedBasicItems: computeItemsOrder(allBasicColumnsWithOrder, sourceId, targetId),
        });
      }
    });
  }, [allBasicColumnsWithOrder, allContactColumnsWithOrder, bubbleRecords?.items]);

  const handleItemSelect = useCallback((itemId: SpreadsheetColumnId | null) => {
    setSelectedItemId(itemId);
    setSelectedLocationDescriptionType(null);
  }, []);

  const handleLocationDescriptionTypeSelect = useCallback((itemName: SidebarTitleType | null) => {
    setSelectedItemId(null);
    setSelectedLocationDescriptionType(itemName);
  }, []);

  const handleChangeLocationSettings = useCallback((settings: Partial<SidebarTitleSettings>): void => {
    if (selectedLocationDescriptionType !== null) {
      setAreChangesProbable(true);

      setCustomizedPanelSettings((prevState) => {
        const updatedDescriptionStyle = changeDescriptionSettings({
          currentLocationDescriptionStyle: prevState.locationDescriptionStyle,
          itemName: selectedLocationDescriptionType,
          settings,
          theme,
        });

        return ({
          ...prevState,
          locationDescriptionStyle: areDeepEqual(SIDEBAR_TITLE_DESCRIPTION_STYLE(theme), updatedDescriptionStyle)
            ? undefined
            : updatedDescriptionStyle,
        });
      });
    }
  }, [selectedLocationDescriptionType, theme]);

  const handleChangeColorsAndFonts = useCallback((style: Partial<ColumnStyleSettingsData>): void => {

    setAreChangesProbable(true);

    if (selectedItemId !== null && bubbleRecords) {
      const selectedItem = getColumnItemById(bubbleRecords.items, selectedItemId);

      setCustomizedPanelSettings((prevState) => {
        const currentItemData = getDataFromColumnPerSpreadsheetSettings(prevState.itemsData, selectedItemId);
        if (currentItemData && selectedItem) {
          const itemDefaultBulkStyle = defaultLocationDataStyle(theme, selectedItem.itemType);
          const itemCurrentBulkStyle = isItemContactItem(selectedItem.itemType) ? itemDefaultBulkStyle : (prevState.bulkStyle ?? itemDefaultBulkStyle);
          const updatedItemStyle = mergeDeep<ColumnStyleSettingsData>({}, itemCurrentBulkStyle, currentItemData.style, style);

          const updatedItemData = {
            ...currentItemData,
            style: areDeepEqual(defaultLocationDataStyle(theme, selectedItem.itemType), updatedItemStyle)
              ? undefined
              : updatedItemStyle,
          };

          return ({
            ...prevState,
            itemsData: mergeDeep<PanelSettingsColumnItemMap>({}, prevState.itemsData, createDataWithColumnPerSpreadsheetSettings(updatedItemData, selectedItemId)),
          });
        }
        return prevState;
      });

    }
    else {
      setCustomizedPanelSettings(prevState => {
        const updatedBulkStyle = updateStyle(prevState.bulkStyle ?? defaultBulkStyle, style);

        return ({
          ...prevState,
          itemsData: modifyPanelSettingsColumnItemMap({
            columnSettingItems: prevState.itemsData,
            itemDataModifier: (item) => ({ ...item, style: undefined }),
          }),
          bulkStyle: areDeepEqual(defaultBulkStyle, updatedBulkStyle) ? undefined : updatedBulkStyle,
        });
      });
    }
  }, [selectedItemId, bubbleRecords, defaultBulkStyle, theme]);

  const handleReset = useCallback(() => {
    setAreChangesProbable(false);
    setCustomizedPanelSettings(storedPanelSettings);
  }, [storedPanelSettings]);

  const handleResetDefaults = useCallback(() => {
    setAreChangesProbable(true);
    setCustomizedPanelSettings(defaultPanelSettings);
  }, [defaultPanelSettings]);

  const clickOutsideCallback = useCallback(() => {
    setSelectedItemId(null);
    setSelectedLocationDescriptionType(null);
  }, []);

  return {
    bubbleRecords,

    bulkStyle: customizedPanelSettings.bulkStyle ?? defaultBulkStyle,
    generalSettings: customizedPanelSettings.generalSettings,
    locationDescriptionStyle: customizedPanelSettings.locationDescriptionStyle ?? null,

    areChangesPending: areChangesProbable,
    selectedItemId,
    selectedLocationType: selectedLocationDescriptionType,

    clickOutsideCallback,

    activateItem,
    deactivateItem,
    dropItems: handleDropItems,
    modifyGeneral: handleUpdateGeneral,
    modifyItemVisuals: handleChangeColorsAndFonts,
    modifyLocationVisuals: handleChangeLocationSettings,
    resetToDefault: handleResetDefaults,
    resetToLastSaved: handleReset,
    saveSettings: handleSave,
    selectItem: handleItemSelect,
    selectLocationDescription: handleLocationDescriptionTypeSelect,
  };
};
