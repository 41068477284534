import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { OverlayLoaderComponent } from '../../../../_shared/components/overlay/overlayLoader.component';
import { noop } from '../../../../_shared/utils/function.helpers';
import { usePrevious } from '../../../../_shared/utils/hooks/usePrevious';
import {
  lassoToolDrawingFinished,
  stopLassoTool,
} from '../../../../store/frontendState/mapTools/lasso/lasso.actionCreators';
import { useLassoStateSelector } from '../../../../store/frontendState/mapTools/lasso/lasso.selectors';
import { LassoConfiguration } from '../../../../store/frontendState/mapTools/lasso/lasso.state';
import { useLassoToolModal } from '../useLassoToolModal';
import { LassoToolDrawComponent } from './lassoToolDraw';
import { useLassoAndFill } from './useLassoAndFill';

enum LassoToolState {
  None,
  Drawing,
  Displaying,
}

export const LassoToolOverlay: React.FC = () => {
  const { openLassoToolModal, loading: lassoToolLoading } = useLassoToolModal();
  const lassoTool = useLassoStateSelector();
  const { processToRouting, loading: processToRoutingLoading } = useLassoAndFill();
  const dispatch = useDispatch();
  const previousLassoTool = usePrevious(lassoTool);
  const [state, setState] = useState(LassoToolState.None);

  const disableLassoTool = useCallback(() => dispatch(stopLassoTool()), [dispatch]);

  const openToolModal = useCallback((polygon?: Polygon) => {
    setState(LassoToolState.Displaying);
    dispatch(lassoToolDrawingFinished());
    if (polygon) {
      openLassoToolModal(polygon);
    }
  }, [dispatch, openLassoToolModal]);

  const sendToRouting = useCallback((polygon?: Polygon) => {
    setState(LassoToolState.Displaying);
    if (polygon) {
      processToRouting(polygon).then(disableLassoTool);
    }
    else {
      disableLassoTool();
    }
  }, [disableLassoTool, processToRouting]);

  useEffect(() => {
    if (!lassoTool.active) {
      return;
    }

    if (!previousLassoTool?.active) {
      setState(LassoToolState.Drawing);
      return;
    }

    if (previousLassoTool.active && previousLassoTool.configuration !== lassoTool.configuration) {
      setState(LassoToolState.Drawing);
    }
  }, [lassoTool, previousLassoTool]);

  const { onDrawingFinished, onPolygonClick } = useMemo(() => {
    if (!lassoTool.active) {
      return { onDrawingFinished: noop, onPolygonClick: noop };
    }
    switch (lassoTool.configuration) {
      case LassoConfiguration.LassoTool: {
        return { onDrawingFinished: openToolModal, onPolygonClick: openToolModal };
      }
      case LassoConfiguration.RoutingSelection: {
        return { onDrawingFinished: sendToRouting, onPolygonClick: noop };
      }
      default:
        return {
          onDrawingFinished: noop,
          onPolygonClick: noop,
        };
    }
  }, [lassoTool, openToolModal, sendToRouting]);

  const key = !lassoTool.active ? 'disabled' : lassoTool.configuration;
  const loading = processToRoutingLoading || lassoToolLoading;
  return (
    <>
      {loading && <OverlayLoaderComponent />}
      {lassoTool.active && (
        <LassoToolDrawComponent
          key={key}
          onDrawingFinished={onDrawingFinished}
          onPolygonClick={onPolygonClick}
          onRemove={disableLassoTool}
          isDrawing={state === LassoToolState.Drawing}
        />
      )}
    </>
  );
};
