import {
  boundaryTerritoryGroupInSidebarHighlighted,
  boundaryTerritoryGroupInStatusBarClicked,
} from './boundarySidebarState.actionCreators';

type BoundarySidebarHighlightState = {
  highlightedBoundaryTerritoryGroupId: number|null;
};

const initialState: BoundarySidebarHighlightState = {
  highlightedBoundaryTerritoryGroupId: null,
};

export const boundarySidebarStateReducer = (state: BoundarySidebarHighlightState = initialState, action: Action): BoundarySidebarHighlightState => {
  if (boundaryTerritoryGroupInStatusBarClicked.match(action)) {
    return {
      ...state,
      highlightedBoundaryTerritoryGroupId: action.payload.boundaryGroupId,
    };
  }

  if (boundaryTerritoryGroupInSidebarHighlighted.match(action)) {
    return {
      ...state,
      highlightedBoundaryTerritoryGroupId: initialState.highlightedBoundaryTerritoryGroupId,
    };
  }

  return state;
};
