import { ignoreDoubleClicks } from '~/_shared/utils/events/events.helpers';
import {
  createPieChartItems, type CreatePieChartItemsParams,
} from '~/_shared/utils/maptive/pieCharts/pieCharts.helpers';
import type { PieChartItem } from '~/_shared/utils/maptive/pieCharts/pieCharts.types';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type LatLng } from '../../../../_shared/types/latLng';
import { type SpreadsheetRowId } from '../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { noop } from '../../../../_shared/utils/function.helpers';
import { nameOf } from '../../../../_shared/utils/nameOf';
import { type ReadonlySpreadsheetRowIdMap } from '../../../../_shared/utils/spreadsheet/spreadsheetRowIdMap';
import {
  calculateZIndex,
  ZIndexedEntity,
} from '../../../zIndexes/zIndexRanges';
import { mapFont } from '../../mapLabelFonts';
import { type ClusterLabelInfo } from '../../mapOverlays/markerClusterer/ClusterableMarker.type';
import { type ExtendsWebglLayers } from '../../webgl/useWebGL';
import { PredefinedTemplate } from '../manager/mapMarkerTemplates';

type PieChartInput = Readonly<{
  pieChartItems?: ReadonlyArray<PieChartItem>;
  secondaryPieChartItems?: ReadonlyArray<PieChartItem>;
}>;

type ClusterInput = ClusterLabelInfo & PieChartInput;
type ClusterWithPieChart = ClusterLabelInfo & Required<PieChartInput>;

const NUMBER_OF_LAYERS_MARKER_CLUSTER = 3;
const MARKER_CLUSTER_LAYER = {
  MARKER: 1,
  PIE_CHART: 2,
  LABEL: 3,
};

type RenderedClusterDataCallbacks = Readonly<{
  onClick: (e: MapObjectClickEventArgs) => void;
  onDoubleClick: (e: MapObjectClickEventArgs) => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
}>;
type RenderedBaseClusterData = Readonly<{
  clusterInfo: ClusterWithPieChart | ClusterLabelInfo;
  label: WebglOverlayLabel;
}>;
type RenderedStandardClusterData = RenderedBaseClusterData & Readonly<{
  clusterInfo: ClusterLabelInfo;
  marker: WebglOverlayMarker;
}>;
type RenderedPieChartClusterData = RenderedBaseClusterData & Readonly<{
  clusterInfo: ClusterWithPieChart;
  pieChart: WebglPieChart;
}>;
type RenderedClusterData = RenderedStandardClusterData | RenderedPieChartClusterData;
type RenderedClusterDataWithCallbacks = RenderedClusterDataCallbacks & RenderedClusterData;

const maxFontSize = 18;
const clusterMarkerSize = 135;

type RequiredLayers = ExtendsWebglLayers<Readonly<{
  Markers: MarkersLayer;
  MarkerLabelsText: LabelsLayer;
  PieChartClusters: PieChartsLayer;
}>>;

export type OnClusterClick = (markers: ReadonlySpreadsheetRowIdMap<Readonly<LatLng & SpreadsheetRowId>>, bounds: google.maps.LatLngBounds) => void;

export class MapClustersManager {
  private readonly overlayLayers: RequiredLayers;
  private renderedClusterData: ReadonlyArray<RenderedClusterDataWithCallbacks> = [];
  private onClusterClick: OnClusterClick = noop;
  private onClusterMouseOver: () => void = noop;
  private onClusterMouseOut: () => void = noop;
  private onClusterDoubleClick: (bounds: google.maps.LatLngBounds) => void = noop;

  constructor(overlayLayers: RequiredLayers) {
    this.overlayLayers = overlayLayers;
  }

  public updateClusters = (clusterInfos: ReadonlyArray<ClusterInput>) => {
    this.clearClusters();
    clusterInfos.forEach(info => {
      if (isInputWithPieChart(info)) {
        this.renderPieChartCluster(info);
      }
      else {
        this.renderStandardCluster(info);
      }
    });
  };

  private renderStandardCluster = (clusterInfo: ClusterLabelInfo) => {
    const label = createWebglLabel({ clusterInfo, fillColor: [255, 255, 255, 1] });
    const marker = createWebglStandardCluster(clusterInfo);

    const onClick = addClickHandler({ marker, clusterInfo, label }, () => this.onClusterClick(clusterInfo.markers, clusterInfo.boundsOfAllMarkers));
    const onDoubleClick = addDoubleClickHandler({ marker, clusterInfo, label }, () => this.onClusterDoubleClick(clusterInfo.boundsOfAllMarkers));
    const onMouseOver = addMouseOverHandler({ marker, clusterInfo, label }, () => this.onClusterMouseOver());
    const onMouseOut = addMouseOutHandler({ marker, clusterInfo, label }, () => this.onClusterMouseOut());
    this.overlayLayers.MarkerLabelsText.add(label);
    this.overlayLayers.Markers.add(marker);

    this.renderedClusterData = [...this.renderedClusterData, { label, marker, onClick, onDoubleClick, onMouseOver, onMouseOut, clusterInfo }];
  };

  private renderPieChartCluster = (clusterInfo: ClusterWithPieChart) => {
    const label = createWebglLabel({ clusterInfo, fillColor: [0, 0, 0, 1], strokeColor: [255, 255, 255, 1] });
    const pieChart = createWebglPieChart(clusterInfo);

    const onClick = addClickHandler({ pieChart, clusterInfo, label }, () => this.onClusterClick(clusterInfo.markers, clusterInfo.boundsOfAllMarkers));
    const onDoubleClick = addDoubleClickHandler({ pieChart, clusterInfo, label }, () => this.onClusterDoubleClick(clusterInfo.boundsOfAllMarkers));
    const onMouseOver = addMouseOverHandler({ pieChart, clusterInfo, label }, () => this.onClusterMouseOver());
    const onMouseOut = addMouseOutHandler({ pieChart, clusterInfo, label }, () => this.onClusterMouseOut());
    this.overlayLayers.PieChartClusters.add(pieChart);
    this.overlayLayers.MarkerLabelsText.add(label);

    this.renderedClusterData = [...this.renderedClusterData, { onClick, onDoubleClick, onMouseOver, onMouseOut, pieChart, clusterInfo, label }];
  };

  public updateOnClickHandler = (callbacks: {
    newOnDoubleClick: (bounds: google.maps.LatLngBounds) => void;
    newOnClick: OnClusterClick;
    newOnMouseOver: () => void;
    newOnMouseOut: () => void;
  }) => {
    this.onClusterClick = callbacks.newOnClick;
    this.onClusterDoubleClick = callbacks.newOnDoubleClick;
    this.onClusterMouseOver = callbacks.newOnMouseOver;
    this.onClusterMouseOut = callbacks.newOnMouseOut;

    this.renderedClusterData = this.renderedClusterData.map(data => {
      removeClickHandler(data);
      removeDoubleClickHandler(data);
      removeMouseOverHandler(data);
      removeMouseOutHandler(data);

      const onClick = addClickHandler(data, () => this.onClusterClick(data.clusterInfo.markers, data.clusterInfo.boundsOfAllMarkers));
      const onDoubleClick = addDoubleClickHandler(data, () => this.onClusterDoubleClick(data.clusterInfo.boundsOfAllMarkers));
      const onMouseOver = addMouseOverHandler(data, () => this.onClusterMouseOver());
      const onMouseOut = addMouseOutHandler(data, () => this.onClusterMouseOut());

      return {
        ...data,
        onClick,
        onDoubleClick,
        onMouseOver,
        onMouseOut,
      };
    });
  };

  private clearClusters = () => {
    this.renderedClusterData.forEach(data => {
      this.overlayLayers.MarkerLabelsText.remove(data.label);

      if (isPieChartCluster(data)) {
        this.overlayLayers.PieChartClusters.remove(data.pieChart);
      }
      else {
        this.overlayLayers.Markers.remove(data.marker);
      }
    });

    this.renderedClusterData = [];
  };
}

const createWebglPieChart = (clusterInfo: ClusterWithPieChart) =>
  new WebGLOverlay.PieChart({
    lat: clusterInfo.position.lat,
    lng: clusterInfo.position.lng,
    zIndex: calculateZIndex(
      clusterInfo.index * NUMBER_OF_LAYERS_MARKER_CLUSTER + MARKER_CLUSTER_LAYER.PIE_CHART,
      ZIndexedEntity.MarkerClusterMarker,
    ),
    radius: 24,
    borderWidth: 1,
    border: true,
    staticSize: true,
  }, clusterInfo.pieChartItems);

const createWebglStandardCluster = (clusterInfo: ClusterLabelInfo) =>
  new WebGLOverlay.Marker({
    zIndex: calculateZIndex(
      clusterInfo.index * NUMBER_OF_LAYERS_MARKER_CLUSTER + MARKER_CLUSTER_LAYER.MARKER,
      ZIndexedEntity.MarkerClusterMarker,
    ),
    template: PredefinedTemplate.Cluster,
    lat: clusterInfo.position.lat,
    lng: clusterInfo.position.lng,
    size: clusterMarkerSize,
    anchor: 'center',
  });

const createWebglLabel = ({ clusterInfo, fillColor, strokeColor }: {
  clusterInfo: ClusterLabelInfo; fillColor: WebglColor; strokeColor?: WebglColor; }
) => {
  const value = clusterInfo.markers.size.toString();
  const scaledFontSize = clusterMarkerSize / (value.length * 2);

  return new WebGLOverlay.Label({
    lat: clusterInfo.position.lat,
    lng: clusterInfo.position.lng,
    text: {
      value,
      strokeColor,
      strokeWidth: strokeColor ? 1 : undefined,
      fillColor,
      fillWidth: 0.52,
      fontSize: Math.min(scaledFontSize, maxFontSize),
      align: 'center',
      font: mapFont.arialBold.name,
    },
    opacity: 1,
    zIndex: calculateZIndex(
      clusterInfo.index * NUMBER_OF_LAYERS_MARKER_CLUSTER + MARKER_CLUSTER_LAYER.LABEL,
      ZIndexedEntity.MarkerClusterMarker,
    ),
    horizontalAnchor: 'center',
    verticalAnchor: 'center',
    interactive: false,
  });
};

const addClickHandler = (data: RenderedClusterData, handler: () => void) => {
  const singleClickHandler = ignoreDoubleClicks(handler);

  if (isPieChartCluster(data)) {
    data.pieChart.addEventListener('click', singleClickHandler);
  }
  else {
    data.marker.addEventListener('click', singleClickHandler);
  }

  return singleClickHandler;
};

const removeClickHandler = (data: RenderedClusterDataWithCallbacks) => {
  if (isPieChartCluster(data)) {
    data.pieChart.removeEventListener('click', data.onClick);
  }
};

const addDoubleClickHandler = (data: RenderedClusterData, handler: () => void): ((e: MapObjectClickEventArgs) => void) => {
  const handlerWithoutPropagation = (e: MapObjectClickEventArgs) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    handler();
  };

  if (isPieChartCluster(data)) {
    data.pieChart.addEventListener('dblclick', handlerWithoutPropagation);
  }
  else {
    data.marker.addEventListener('dblclick', handlerWithoutPropagation);
  }

  return handlerWithoutPropagation;
};

const removeDoubleClickHandler = (data: RenderedClusterDataWithCallbacks) => {
  if (isPieChartCluster(data)) {
    data.pieChart.removeEventListener('dblclick', data.onDoubleClick);
  }
  else {
    data.marker.removeEventListener('dblclick', data.onDoubleClick);
  }
};

const addMouseOverHandler = (data: RenderedClusterData, handler: () => void): (() => void) => {
  if (isPieChartCluster(data)) {
    data.pieChart.addEventListener('mouseover', handler);
  }

  return handler;
};

const removeMouseOverHandler = (data: RenderedClusterDataWithCallbacks) => {
  if (isPieChartCluster(data)) {
    data.pieChart.removeEventListener('mouseover', data.onMouseOver);
  }
};

const addMouseOutHandler = (data: RenderedClusterData, handler: () => void): (() => void) => {
  if (isPieChartCluster(data)) {
    data.pieChart.addEventListener('mouseout', handler);
  }

  return handler;
};

const removeMouseOutHandler = (data: RenderedClusterDataWithCallbacks) => {
  if (isPieChartCluster(data)) {
    data.pieChart.removeEventListener('mouseout', data.onMouseOut);
  }
};

const isPieChartCluster = (cluster: RenderedClusterData): cluster is RenderedPieChartClusterData =>
  cluster.hasOwnProperty(nameOf<RenderedPieChartClusterData>('pieChart'));

const isInputWithPieChart = (input: ClusterInput): input is ClusterWithPieChart =>
  notNullsy(input.pieChartItems) && input.pieChartItems.length > 0;

export const createWebGlPieChartItems = (params: Omit<CreatePieChartItemsParams, 'hierarchy'>): PieChartInput => ({
  pieChartItems: createPieChartItems({ ...params, hierarchy: 0 }),
  secondaryPieChartItems: createPieChartItems({ ...params, hierarchy: 1 }),
});
