import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  LottieAnimationComponent, type LottieAnimationProps,
} from '../../baseComponents/lottieAnimation/lottieAnimation.component';
import { OverlayComponent } from './overlay.component';

type OverlayLottieAnimationProps = LottieAnimationProps;

export const lottieAnimationOverlayStyle = ({ size }: { size: number }) => css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginLeft: -size / 2,
  marginTop: -size / 2,
});

/*
 * If you are using default animations colors and settings consider using OverlayLoaderComponent
 * with loaderType prop instead (and feel free to add a new entry to LoaderComponent's switch to support more types)
*/

export const OverlayLottieAnimationComponent: FC<OverlayLottieAnimationProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <OverlayComponent
      className={className}
    >
      <LottieAnimationComponent
        css={lottieAnimationOverlayStyle({ size: props.size })}
        {...restProps}
      />
    </OverlayComponent>
  );
};
