import { type Placement } from '@floating-ui/react';
import {
  type FC, type RefObject, useCallback,
} from 'react';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { type ContextMenuItemProps } from './sidebarContextMenu.types';

const CONTEXT_MENU_WIDTH = 280;

type ContextMenuProps = Readonly<{
  children: React.ReactElement;
  isOpen: boolean;
  menuItems: ReadonlyArray<ContextMenuItemProps>;
  preferredPlacement?: Placement;
  targetRef?: RefObject<HTMLElement | null>;

  onHide: () => void;
}>;

export const SidebarContextMenuComponent: FC<ContextMenuProps> = ({
  children,
  isOpen,
  menuItems,
  onHide,
  preferredPlacement,
  targetRef,
  ...restProps
}) => {
  const close = useCallback(() => onHide(), [onHide]);

  return (
    <ContextMenuComponent
      {...restProps}
      isFixed
      isVisible={isOpen}
      onHide={close}
      preferredPlacement={preferredPlacement}
      renderMenuContent={() => (
        <>
          {menuItems.map((s) => (
            <ContextMenuItemCoreComponent
              key={s.text}
              onClick={() => !s.isDisabled && s.onClick()}
            >
              <ContextMenuDefaultItem
                icon={s.icon}
                isDisabled={s.isDisabled}
                iconStyle={s.iconStyle}
                tooltipLabel={s.disabledTooltip}
                tooltipPlacement={TooltipPlacement.Left}
              >
                {s.text}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          ))}
        </>
      )}
      targetRef={targetRef}
      maxWidth={CONTEXT_MENU_WIDTH}
    >
      {children}
    </ContextMenuComponent>
  );
};
