import { type FC } from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

type CircleArrowBasicProps = {
  circleColor: string;
  arrowColor: string;
  dimensions: QuadrilateralDimensions;
};

export const CircleArrowBasicComponent: FC<CircleArrowBasicProps> = (props) => (
  <svg
    width={props.dimensions.width}
    height={props.dimensions.height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM16.3751 35C15.875 35.5 15.875 36 16.3751 36.5L30.8751 51C31.6251 51.75 32.1251 51.75 32.8751 51L47.3751 36.5C47.875 36 47.875 35.5 47.3751 35L44.8751 32.5C44.375 32 43.875 32 43.3751 32.5L34.8751 41V14C34.8751 13 34.3751 12.5 33.3751 12.5H30.3751C29.3751 12.5 28.8751 13 28.8751 14V41L20.3751 32.5C19.875 32 19.375 32 18.8751 32.5L16.3751 35Z"
      fill={props.circleColor}
    />
    <path
      d="M16.3751 35C15.875 35.5 15.875 36 16.3751 36.5L30.8751 51C31.6251 51.75 32.1251 51.75 32.8751 51L47.3751 36.5C47.875 36 47.875 35.5 47.3751 35L44.8751 32.5C44.375 32 43.875 32 43.3751 32.5L34.8751 41V14C34.8751 13 34.3751 12.5 33.3751 12.5H30.3751C29.3751 12.5 28.8751 13 28.8751 14V41L20.3751 32.5C19.875 32 19.375 32 18.8751 32.5L16.3751 35Z"
      fill={props.arrowColor}
    />
  </svg>

);
