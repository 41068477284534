import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  useDrag, useDrop,
} from 'react-dnd';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import {
  type BubbleDndItem, type BubbleEditorItem,
} from '../locationData.types';
import { type ColumnStyleSettingsData } from '../panelSettings.types';
import { BubbleItemWithIconContainer } from './bubbleItemWithIcon.container';
import { getItemDnDType } from './draggableItem.helpers';

const wrapperStyle = ({ theme, isSelected }: ThemeProps<{ isSelected?: boolean }>) => css({
  backgroundColor: isSelected ? theme.backgroundColors.secondaryHover : '',
});

type DraggableItemProps = Readonly<{
  className?: string;
  item: BubbleEditorItem;
  isDraggable: boolean;
  isVisible: boolean;
  onVisibilityIconClick: () => void;
  showVisibilityIcon: boolean;
  isSelected?: boolean;
  bulkStyles: ColumnStyleSettingsData;

  onDrop: (sourceId: SpreadsheetColumnId, targetId: SpreadsheetColumnId) => void;
}>;

export const DraggableItemComponent: FC<DraggableItemProps> = ({
  bulkStyles,
  className,
  isDraggable,
  isSelected,
  isVisible,
  item,
  onDrop,
  onVisibilityIconClick,
  showVisibilityIcon,
  ...restProps
}) => {
  const [, drag] = useDrag({
    type: getItemDnDType(item.itemType),
    // id: item.id,
    item,
    canDrag: isDraggable,
  });
  const [, drop] = useDrop<{ type: string; id: SpreadsheetColumnId }, void, unknown>({
    accept: [getItemDnDType(item.itemType)],
    hover: (bubbleItem: BubbleDndItem) =>
      (bubbleItem.id.spreadsheetId !== item.id.spreadsheetId || bubbleItem.id.columnId !== item.id.columnId)
      && onDrop(bubbleItem.id, item.id),
  });
  const theme = useTheme();

  return (
    <div
      {...restProps}
      className={className}
      css={wrapperStyle({ theme, isSelected })}
      ref={el => {
        drag(el);
        drop(el);
      }}
    >
      <BubbleItemWithIconContainer
        showDragIcon={isDraggable}
        item={item}
        isDraggable={isDraggable}
        isItemVisible={isVisible}
        onVisibilityIconClick={onVisibilityIconClick}
        showVisibilityIcon={showVisibilityIcon}
        bulkStyles={bulkStyles}
      />
    </div>
  );
};
