import { css } from '@emotion/react';
import {
  faLockAlt, faX,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FormEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { MFAField } from '~/_shared/components/accountSettings/modalDialogs/components/MFAField.component';
import { useUpdateAccountInformation } from '~/_shared/components/accountSettings/modalDialogs/hooks/useUpdateAccountInformation';
import {
  accountSettingsModalContentStyle, footerButtonStyle, formStyle, hiddenStyle, scrollContentStyle,
} from '~/_shared/components/accountSettings/modalDialogs/shared.styles';
import {
  createErrorMessagesFromMessage, DEFAULT_EMPTY_ERRORS,
} from '~/_shared/components/accountSettings/modalDialogs/utils';
import { useTwoFactorMethod } from '~/_shared/components/accountSettings/twoFactorModal/useTwoFactorSettingData';
import { SuccessAnimationComponent } from '~/_shared/components/animation/success/sucessAnimation.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { twoFactorToServerModel } from '~/_shared/constants/twoFactorMode.enum';
import { autoFocus } from '~/_shared/utils/form/form.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  createPasswordErrorMessage, validatePassword,
} from '~/_shared/utils/throttle/validation/validation.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import type { ModalProps } from '~/modal/modalType.enum';
import { UserFieldName } from '~/store/userData/repository/userData.types';

const passwordChangeSuccessWrapperStyle = css({
  display: 'flex',
  gap: 56,
  alignItems: 'center',
});

export const ChangePasswordModalComponent = (props: ModalProps) => {
  const [t] = useTranslation();
  const { selectedTwoFactorMode } = useTwoFactorMethod();
  const {
    setPhase,
    phase,
    generalError,
    fieldErrors: errorsPerFieldName,
    changeUserData,
    isLoading,
  } = useUpdateAccountInformation();
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [currentPasswordBlurred, setCurrentPasswordBlurred] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>(createErrorMessagesFromMessage(''));
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);

  const confirmPasswordError = (confirmPassword || newPassword) && (confirmPassword !== newPassword) ?
    t('Passwords do not match') :
    '';

  const isSubmitFormDisabled = isLoading
    || (newPasswordErrors.length > 0)
    || (currentPassword === '')
    || (newPassword === '')
    || (confirmPassword === '');

  const errorMessages = useMemo(() =>
    createPasswordErrorMessage(newPassword, t),
  [newPassword, t]);

  const handleCurrentPasswordBlur = useCallback(() => {
    setCurrentPasswordBlurred(true);
  }, []);

  useEffect(() => {
    if (currentPasswordBlurred) {
      if (currentPassword === '') {
        setCurrentPasswordError(t('This field is required'));
      }
      else {
        setCurrentPasswordError('');
      }
    }
  }, [currentPassword, currentPasswordBlurred, t]);

  useEffect(() => {
    if (!newPassword.length || validatePassword(newPassword)) {
      setNewPasswordErrors(createErrorMessagesFromMessage(''));
      return;
    }
    else {
      setNewPasswordErrors(errorMessages);
    }
  }, [errorMessages, newPassword]);

  const changePassword = useCallback((newMfaCode?: string) => {
    if (notNullsy(newMfaCode)) {
      setMfaCode(newMfaCode);
    }
    changeUserData({
      mfa: twoFactorToServerModel(selectedTwoFactorMode),
      code: (phase !== 'Form') ? (newMfaCode || mfaCode) : undefined,
      old_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
  }, [changeUserData, confirmPassword, currentPassword, mfaCode, newPassword, phase, selectedTwoFactorMode]);

  const resaveChanges = useCallback(() => {
    setResendEnabled(false);
    changePassword(''); // set MFA code to empty string both for UI and for the next request not to use the old one
  }, [changePassword]);

  const onFormSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resaveChanges();
  }, [resaveChanges]);

  const goBackToForm = useCallback(() => {
    setPhase('Form');
  }, [setPhase]);

  const alertMessage = useMemo(() =>
    generalError ? generalError : errorsPerFieldName?.code ? errorsPerFieldName.code : null,
  [errorsPerFieldName?.code, generalError]);

  if (phase === 'Success') {
    return (
      <ModalComponent
        key={phase}
        caption={t('Change Password')}
        contentStyle={accountSettingsModalContentStyle}
        isOpen={props.isOpen}
        maxWidth={547}
        onClose={props.onClose}
        preventCloseOnOverlayClick
      >
        <div css={passwordChangeSuccessWrapperStyle}>
          {t('Your account information has been updated successfully.')}
          <SuccessAnimationComponent size={56} onAnimationEnd={props.onClose} />
        </div>
      </ModalComponent>
    );
  }

  if (phase === 'MFA') {
    return (
      <ModalComponent
        caption={t('Change Password')}
        contentStyle={accountSettingsModalContentStyle}
        isOpen={props.isOpen}
        key={phase}
        maxWidth={547}
        onClose={goBackToForm}
        leftFooterContent={(
          <ButtonComponent
            buttonStyle={ButtonStyle.Secondary}
            onClick={goBackToForm}
            prefixIcon={faX}
            text={t('Back')}
          />
        )}
        additionalContent={isLoading ? (
          <OverlayLoaderComponent />
        ) : null}
      >
        <MFAField
          isResendEnabled={resendEnabled}
          onChange={setMfaCode}
          value={mfaCode}
          onResendEmail={resaveChanges}
          onSubmit={resaveChanges}
          setInputRef={autoFocus}
        />
        {alertMessage && (
          <AlertComponent
            type="danger"
          >
            {alertMessage}
          </AlertComponent>
        )}
      </ModalComponent>
    );
  }

  return (
    <ModalComponent
      caption={t('Change Password')}
      contentStyle={accountSettingsModalContentStyle}
      isOpen={props.isOpen}
      key={phase}
      maxWidth={547}
      onClose={props.onClose}
      footer={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Primary}
          css={footerButtonStyle}
          isDisabled={isSubmitFormDisabled}
          onClick={isSubmitFormDisabled ? undefined : resaveChanges}
          text={t('Confirm')}
        />
      )}
      additionalContent={isLoading ? (
        <OverlayLoaderComponent />
      ) : null}
    >
      <ScrollBarComponent
        contentStyle={scrollContentStyle}
        maxHeight={1000}
        translateContentHeightToHolder
        type={ScrollbarType.Vertical}
      >
        <form
          css={formStyle}
          onSubmit={isSubmitFormDisabled ? undefined : onFormSubmit}
        >
          <FormTextInputComponent
            autoComplete="current-password"
            errorMessages={createErrorMessagesFromMessage(currentPasswordError).concat(errorsPerFieldName?.[UserFieldName.old_password] || DEFAULT_EMPTY_ERRORS)}
            icon={faLockAlt}
            label={t('Current Password')}
            onBlur={handleCurrentPasswordBlur}
            onChange={setCurrentPassword}
            setInputRef={autoFocus}
            type="password"
            value={currentPassword}
          />
          <FormTextInputComponent
            autoComplete="new-password"
            errorMessages={newPasswordErrors.concat(errorsPerFieldName?.[UserFieldName.password] || DEFAULT_EMPTY_ERRORS)}
            icon={faLockAlt}
            label={t('Enter New Password')}
            onChange={setNewPassword}
            type="password"
            value={newPassword}
          />
          <FormTextInputComponent
            autoComplete="new-password"
            errorMessages={createErrorMessagesFromMessage(confirmPasswordError)}
            icon={faLockAlt}
            label={t('Confirm New Password')}
            onChange={setConfirmPassword}
            type="password"
            value={confirmPassword}
          />

          {generalError && (
            <AlertComponent
              type="danger"
            >
              {generalError}
            </AlertComponent>
          )}
          {!isSubmitFormDisabled && (
            // This makes the form submit on Enter
            <input type="submit" css={hiddenStyle} />
          )}
        </form>
      </ScrollBarComponent>
    </ModalComponent>
  );
};
