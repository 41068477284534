import { useCallback } from 'react';
import { ColumnRole } from '../../types/columnRole.enum';
import { type CombinedRowId } from '../../types/spreadsheetData/spreadsheetRow';
import {
  type TranslationFnc, useTranslation,
} from '../hooks';
import { parseCombinedRowId } from './generalSpreadsheet.helpers';
import { useMarkerPropertyResolver } from './useMarkerPropertyResolver';

export const getMarkerFallbackName = (id: CombinedRowId, t: TranslationFnc) => {
  const rowIdNumber = parseCombinedRowId(id)?.rowId;
  return rowIdNumber ? `${t('Marker')} ${rowIdNumber}` : '';
};

export type MarkerNameResolver = (spreadsheetId: number, rowId: CombinedRowId, withFallbackName: boolean) => string;

export const useMarkerNameResolver = () => {
  const [t] = useTranslation();
  const markerNameResolverWithoutFallback = useMarkerPropertyResolver(ColumnRole.Name);
  const markerNameResolver: MarkerNameResolver = useCallback((spreadsheetId, rowId, withFallbackName) => {
    const maybeName = markerNameResolverWithoutFallback(spreadsheetId, rowId);

    if (!maybeName && withFallbackName) {
      return getMarkerFallbackName(rowId, t);
    }

    return maybeName || '';
  }, [t, markerNameResolverWithoutFallback]);

  return {
    markerNameResolver,
  };
};
