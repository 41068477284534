import {
  createContext, type FC, type PropsWithChildren, useCallback, useContext, useMemo, useState,
} from 'react';
import { noop } from '../../../utils/function.helpers';
import { type TopUnderbarItem } from '../item/topUnderbarItem.types';

type NotificationsContext = {
  notifications: ReadonlyArray<TopUnderbarItem>;
  pushNotification: (notification: TopUnderbarItem, options?: NotificationOptions) => () => void;
  closeNotification: (key: string) => void;
};

const Context = createContext<NotificationsContext>({
  notifications: [],
  pushNotification: () => noop,
  closeNotification: noop,
});
Context.displayName = 'NotificationContext';

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<TopUnderbarItem[]>([]);

  const closeNotification = useCallback((key: string) => {
    setNotifications((prevNotifications) => prevNotifications.filter(notification => notification.key !== key));
  }, []);

  const pushNotification = useCallback((newNotification: TopUnderbarItem) => {
    setNotifications((prevNotifications) => [...prevNotifications.filter(notification => notification.key !== newNotification.key), newNotification]);
    return () => closeNotification(newNotification.key);
  }, [closeNotification]);

  const context: NotificationsContext = useMemo(() => {
    return {
      notifications,
      pushNotification,
      closeNotification,
    };
  }, [closeNotification, notifications, pushNotification]);

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
};

export const useNotifications = () => {
  return useContext(Context);
};
