import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';
import { isMarkersContextMenuActive } from './markersContextMenu.state';

const markersContextMenuSelector = (state: AppState) => state.frontendState.markersContextMenu;

const markersContextMenuActiveSelector = (state: AppState) => {
  const markersContextMenuState = markersContextMenuSelector(state);
  return isMarkersContextMenuActive(markersContextMenuState) ? markersContextMenuState : null;
};
export const useMarkersContextMenuActiveSelector = () => useSelector(markersContextMenuActiveSelector);
