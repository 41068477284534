import { notNullsy } from '~/_shared/utils/typeGuards';
import { FilterAction } from '../../../_shared/types/filter/filter';
import { type FilterTree } from '../../../_shared/types/filterTree.types';
import {
  type GroupingColumn, type GroupingColumnValues, GroupingType,
} from '../../../_shared/types/grouping/grouping';
import {
  getLowestResolution, RangeType,
} from '../../../_shared/utils/range/range.helpers';
import { FilterConjunction } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/filterConjunction.enum';
import {
  type MapSettingsColumnsFilterState,
  type MapSettingsFilterAttributeState,
  type MapSettingsFilterDateState,
  type MapSettingsFilterNumberState,
  type MapSettingsFilterTextState,
} from '../../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { NO_ATTRIBUTES } from '../grouping/spreadsheetData.grouping.helpers';
import { DataType } from '../spreadsheetData.state';
import { createFilterItemFromTextValue } from './text/spreadsheetDataTextFilter.helpers';

type MapSettingsRegularFilter = {
  spreadsheetId: number;
  columnId: string;
  dataType: DataType.NUMBER | DataType.TEXT | DataType.ATTRIBUTE | DataType.DATE;
};

type MapSettingsGroupFilter = {
  spreadsheetId: number;
  columnId: string;
  dataType: DataType.GROUP;
  extra: GroupingColumn;
};

export type MapSettingsFilter = MapSettingsRegularFilter | MapSettingsGroupFilter;

export const createFilterTreeForFilter = (
  filter: MapSettingsFilter,
  columnsFilterState: MapSettingsColumnsFilterState,
  activeGroupingColumns: GroupingColumn[]
): FilterTree | null => {
  const columnFilters = columnsFilterState[filter.spreadsheetId]?.[filter.columnId];

  if (!columnFilters) {
    return null;
  }

  // TEXT
  if (filter.dataType === DataType.TEXT) {
    const textValues = columnFilters[DataType.TEXT];

    return createFilterTreeForTextFilter(filter, textValues);
  }

  // GROUP
  if (filter.dataType === DataType.GROUP) {
    const groupValues = columnFilters[DataType.GROUP];

    return createFilterTreeForGroupFilter(filter, groupValues, activeGroupingColumns);
  }

  // ATTRIBUTE
  if (filter.dataType === DataType.ATTRIBUTE) {
    const attributeValues = columnFilters[DataType.ATTRIBUTE];

    return createFilterTreeForAttributeFilter(filter, attributeValues);
  }

  // DATE
  if (filter.dataType === DataType.DATE) {
    const dateValues = columnFilters[DataType.DATE];

    return createFilterTreeForDateFilter(filter, dateValues);
  }

  // NUMBER
  if (filter.dataType === DataType.NUMBER) {
    const numberValues = columnFilters[DataType.NUMBER];

    return createFilterTreeForNumberFilter(filter, numberValues);
  }

  return null;
};

const createFilterTreeForTextFilter = (
  filter: MapSettingsFilter, textValues?: MapSettingsFilterTextState
): FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };

  if (!textValues) {
    return null;
  }

  filterItem.type = textValues.conjunction;

  for (const textValue of textValues.values) {
    const textFilter = createFilterItemFromTextValue(textValue, filter.columnId);
    filterItem.children.push(textFilter);
  }

  return filterItem.children.length > 0 ? filterItem : null;
};

const createFilterTreeForGroupFilter = (
  filter: MapSettingsGroupFilter,
  groupValues: GroupingColumnValues<1> | undefined,
  activeGroupingColumns: GroupingColumn[]
):
FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };
  const textGroupValues = groupValues?.text;
  const textGroupValuesCount = Object.keys(textGroupValues ?? {}).length;
  const numericGroupValues = groupValues?.numeric;
  const numericGroupValuesCount = Object.keys(numericGroupValues ?? {}).length;

  if (filter.extra.type === GroupingType.Text && textGroupValuesCount === 0) {
    return null;
  }

  if (filter.extra.type === GroupingType.Numeric && numericGroupValuesCount === 0) {
    return null;
  }

  filterItem.type = 'or';

  if (filter.extra.type === GroupingType.Numeric && numericGroupValues && numericGroupValuesCount > 0) {
    Object.keys(numericGroupValues).forEach(bucketIdString => {
      const bucketId = +bucketIdString;
      const numericalGroupingColumn = activeGroupingColumns.find((item) =>
        item.columnId === filter.columnId &&
        item.spreadsheetId === filter.spreadsheetId &&
        item.type === GroupingType.Numeric
      );

      if (!numericalGroupingColumn || numericalGroupingColumn.type === GroupingType.Text) {
        return;
      }

      if (bucketId === 0) {
        filterItem.children.push({
          type: 'literal',
          ref: {
            col_id: filter.columnId,
            action: numericalGroupingColumn.valueType === RangeType.Value ?
              FilterAction.LessThan :
              FilterAction.LessThanPercentage,
            data_type: DataType.NUMBER,
            value: numericalGroupingColumn.buckets[0] ?? 0,
          },
        });

        return;
      }

      if (bucketId === numericalGroupingColumn.buckets.length) {
        filterItem.children.push({
          type: 'literal',
          ref: {
            col_id: filter.columnId,
            action: numericalGroupingColumn.valueType === RangeType.Value ?
              FilterAction.GreaterThan :
              FilterAction.GreaterThanPercentage,
            data_type: DataType.NUMBER,
            value: numericalGroupingColumn.buckets[numericalGroupingColumn.buckets.length - 1],
          },
        });

        return;
      }

      if (bucketId > 0 && bucketId < numericalGroupingColumn.buckets.length) {

        const range = {
          low: numericalGroupingColumn.buckets[bucketId - 1],
          high: numericalGroupingColumn.buckets[bucketId],
        };

        if (notNullsy(range.low) && notNullsy(range.high)) {
          const bucketsResolution = numericalGroupingColumn.isDecimal ? getLowestResolution(numericalGroupingColumn.buckets) : 1;

          filterItem.children.push({
            type: 'literal',
            ref: {
              col_id: filter.columnId,
              action: numericalGroupingColumn.valueType === RangeType.Value ?
                FilterAction.Range :
                FilterAction.RangePercentage,
              data_type: DataType.NUMBER,
              value: [
                range.low,
                range.high - bucketsResolution,
              ],
            },
          });

          return;
        }
      }

      if (bucketId === numericalGroupingColumn.buckets.length + 1) {
        filterItem.children.push({
          type: 'literal',
          ref: {
            col_id: filter.columnId,
            action: FilterAction.NotANumber,
            data_type: DataType.NUMBER,
            value: null,
          },
        });
        return;
      }
    });
  }

  if (textGroupValues && textGroupValuesCount > 0) {
    Object.keys(textGroupValues).forEach(groupId => {
      filterItem.children.push({
        type: 'literal',
        ref: {
          col_id: filter.columnId,
          action: FilterAction.Equal,
          value: groupId,
          data_type: DataType.GROUP,
        },
      });
    });
  }

  return filterItem.children.length > 0 ? filterItem : null;
};

const createFilterTreeForAttributeFilter = (filter: MapSettingsFilter, attributeValues?: MapSettingsFilterAttributeState):
FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };

  if (!attributeValues) {
    return null;
  }

  filterItem.type = attributeValues.conjunction;

  Object.keys(attributeValues.values).forEach(attributeId => {
    filterItem.children.push({
      type: 'literal',
      ref: attributeId === NO_ATTRIBUTES ? {
        col_id: filter.columnId,
        data_type: DataType.TEXT,
        action: FilterAction.Null,
        value: '',
      } : {
        col_id: filter.columnId,
        data_type: DataType.ATTRIBUTE,
        action: FilterAction.ArrayContains,
        value: attributeId,
      },
    });
  });

  return filterItem.children.length > 0 ? filterItem : null;
};

const createFilterTreeForDateFilter = (filter: MapSettingsFilter, dateValues?: MapSettingsFilterDateState):
FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };

  if (!dateValues) {
    return null;
  }

  filterItem.type = FilterConjunction.And;
  if (dateValues.values[0] && dateValues.values[1]) {

    filterItem.children.push({
      type: 'literal',
      ref: {
        col_id: filter.columnId,
        data_type: DataType.DATE,
        action: FilterAction.Range,
        value: [dateValues.values[0], dateValues.values[1]],
      },
    });
  }
  else {
    if (dateValues.values[0]) {
      filterItem.children.push({
        type: 'literal',
        ref: {
          col_id: filter.columnId,
          data_type: DataType.DATE,
          action: FilterAction.GreaterThanOrEqual,
          value: dateValues.values[0],
        },
      });
    }

    if (dateValues.values[1]) {
      filterItem.children.push({
        type: 'literal',
        ref: {
          col_id: filter.columnId,
          data_type: DataType.DATE,
          action: FilterAction.LessThanOrEqual,
          value: dateValues.values[1],
        },
      });
    }
  }

  return filterItem.children.length > 0 ? filterItem : null;
};

const createFilterTreeForNumberFilter = (filter: MapSettingsFilter, numberValues?: MapSettingsFilterNumberState):
FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };

  if (!numberValues) {
    return null;
  }

  filterItem.type = FilterConjunction.And;

  filterItem.children.push({
    type: 'literal',
    ref: {
      col_id: filter.columnId,
      data_type: DataType.NUMBER,
      action: FilterAction.RangeIncludingMax,
      value: [numberValues.values.value[0], numberValues.values.value[1]],
    },
  });

  return filterItem.children.length > 0 ? filterItem : null;
};
