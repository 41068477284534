import { css } from '@emotion/react';
import {
  faArrowRightFromBracket,
  faMoon,
  faSun,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { darkTheme } from '~/_shared/themes/dark.theme';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeType } from '~/_shared/themes/themeType';
import { useTranslation } from '~/_shared/utils/hooks';

const containerStyle = (theme: Theme) => css({
  alignItems: 'center',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
  width: '100%',
});

const buttonStyle = css({
  width: '100%',
});

const iconStyle = css({
  marginLeft: 8,
});

type MobileFooterProps = Readonly<{
  currentTheme: ThemeType;

  onLogOut: () => void;
  onThemeChange?: (isDarkTheme: boolean) => void;
}>;

export const MobileFooterComponent: FC<MobileFooterProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div>
      <div css={containerStyle(theme)}>
        {t('Theme Style')}
        <ToggleComponent
          css={iconStyle}
          isOn={props.currentTheme === darkTheme.name}
          offIcon={faSun}
          onChange={props.onThemeChange}
          onIcon={faMoon}
        />
      </div>
      <div css={containerStyle(theme)}>
        <ButtonComponent
          css={buttonStyle}
          onClick={props.onLogOut}
          prefixIcon={faArrowRightFromBracket}
          text={t('Log Out')}
        />
      </div>
    </div>
  );
};
