import { type AppState } from '~/store/app.store';
import { isDrawingEditActiveSelector } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { mutuallyExclusiveMapToolsSagas } from '~/store/frontendState/mapTools/mutuallyExclusiveMapTools/mutuallyExclusiveMapToolsSagas';
import { proximityToolDnDIsActiveSelector } from '~/store/frontendState/proximityTool/proximityTool.selectors';
import { useSelector } from './useSelector';

// When map interaction is active other tools should disable their interactions
// so that the two tools won't override each other in unexpected ways

// For instance when drawing with distance calculator tool,
// click events on spreadsheet markers will be disabled

// Also see
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
mutuallyExclusiveMapToolsSagas;

// Extend the condition in here when you have made a new tool
const isMutuallyExclusiveMapToolActive = (state: AppState): boolean => {
  return state.frontendState.mapTools.distanceCalculator.active
    || state.frontendState.mapTools.distanceCalculator.dragging
    || state.frontendState.mapTools.lasso.active
    || state.frontendState.mapTools.addLocation.active
    || state.frontendState.mapTools.boundary.boundaryDraw.isActive
    || state.frontendState.mapTools.boundary.boundarySelect.isActive
    || state.frontendState.mapTools.drawing.drawingTool.isDrawingActive
    || state.frontendState.mapTools.markersSelect.isActive
    || isDrawingEditActiveSelector(state)
    || proximityToolDnDIsActiveSelector(state)
    || state.frontendState.moveMarkers.isActive
    || state.frontendState.moveMarkerLabels.isActive;
};

export const useIsMapInteractionActive = () => {
  return useSelector(isMutuallyExclusiveMapToolActive);
};
