import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { type AnnouncementResponseButtonStyles } from '../../announcements/announcements.repository';

export const announcementsButtonStyleResponseToViewModel = (response: AnnouncementResponseButtonStyles): ButtonStyle => {
  switch (response) {
    case 'danger':
      return ButtonStyle.Danger;
    case 'success':
      return ButtonStyle.Success;
    case 'primary':
    default:
      return ButtonStyle.Primary;
  }
};
