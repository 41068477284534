export const getGeoLink = (lat: number, lng: number) => (
  `geo:${lat},${lng}?q=${lat},${lng}`
);

export const getGoogleMapsNavigateToAddressLink = (address: string) => (
  `https://maps.google.com/?saddr=Current+Location&daddr=${address}`
);

export const getAppleMapsNavigateToAddressLink = (address: string) => (
  `http://maps.apple.com/?daddr=${address}`
);
