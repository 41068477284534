import {
  type FC, memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { useActiveDrawingInstanceZIndex } from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import { useDrawingImageSelectorEditedDrawingFileId } from '~/store/frontendState/mapTools/drawing/drawingImageSelector/drawingImageSelector.selectors';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '../../../../_shared/types/latLng';
import { getDraggableCursorForOutlinePosition } from '../../../../_shared/utils/mapObjectOutline/outline.helpers';
import {
  DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE, DrawingTool,
} from '../../../../drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '../../../../drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { useFileUrls } from '../../../../store/frontendState/fileUrls/fileUrls.selector';
import { drawingEditPushNewSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemHovered, drawingToolItemHoverStopped, drawingToolItemMoveStarted, drawingToolItemMoveStopped,
  drawingToolItemResizeStarted, drawingToolItemResizeStopped,
} from '../../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemImage } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { useMap } from '../../mapContext';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { type MarkerTemplate } from '../../markers/manager/mapMarkerTemplates';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  type DrawingToolScalingItem, useDrawingToolItemScalingToggle,
} from '../hooks/useDrawingToolItemScalingToggle';
import { useDrawingToolItemSizeForCurrentZoom } from '../hooks/useDrawingToolItemSizeForCurrentZoom';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import { DrawingToolImageInstanceMarkerContainer } from './drawingToolImageInstanceMarker.container';
import { getTemplateFromFile } from './drawingToolImages.helpers';

type DrawingToolImageSelectedInstanceProps = {
  instance: DrawingItemImage;
};

const imageOutlinePositions = [
  MapOutlinePosition.TopLeft,
  MapOutlinePosition.TopRight,
  MapOutlinePosition.BottomRight,
  MapOutlinePosition.BottomLeft,
];

const DrawingToolImageSelectedInstanceContainer: FC<DrawingToolImageSelectedInstanceProps> = ({ instance }) => {
  const [center, setCenter] = useState(instance.center);
  const [imageSize, setImageSize] = useState(instance.settings.imageSize);
  const [hoveredOutlineId, setHoveredOutlineId] = useState<string | null>(null);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dragStartDataRef = useRef<{ position: LatLng; originalCenter: LatLng } | null>(null);
  const { onMouseOut, onMouseOver } = useDrawingToolItemMouseEvents();
  const map = useMap();
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);
  const resizeControllerDragStartDataRef = useRef<{ startPoint: LatLng } | null>(null);
  const scaledSizePerPixelRatio = useDrawingToolSizePerPixelRatio();
  const editedImageFileId = useDrawingImageSelectorEditedDrawingFileId();
  const fileUrls = useFileUrls();
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();

  const markerTemplate: MarkerTemplate | null = useMemo(() => {
    if (editedImageFileId === null) {
      return null;
    }

    const templateFile = fileUrls.get(editedImageFileId);

    if (!templateFile) {
      return null;
    }

    return getTemplateFromFile(templateFile);
  }, [editedImageFileId, fileUrls]);

  const sizePerPixelRatio = useMemo(() =>
    instance.settings.scalesWithMapZoom ? scaledSizePerPixelRatio : 1
  , [instance.settings.scalesWithMapZoom, scaledSizePerPixelRatio]);

  const temporaryInstance = useMemo(() => {
    return {
      ...instance,
      center,
      settings: {
        ...instance.settings,
        imageSize,
      },
    };
  }, [instance, center, imageSize]);

  const onSizeChange = useCallback((props: DrawingToolScalingItem) => {
    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Image,
      value: {
        ...temporaryInstance,
        settings: {
          ...temporaryInstance.settings,
          imageSize: props.itemSize,
        },
      },
    }));
  }, [temporaryInstance, dispatch]);

  const sizeProps = {
    itemSize: temporaryInstance.settings.imageSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.to,
    scalesWithMapZoom: temporaryInstance.settings.scalesWithMapZoom,
  };

  const size = useDrawingToolItemSizeForCurrentZoom(sizeProps);
  useDrawingToolItemScalingToggle(sizeProps, onSizeChange);

  useEffect(() => {
    setCenter(instance.center);
  }, [instance.center]);

  useEffect(() => {
    setImageSize(instance.settings.imageSize);
  }, [instance.settings.imageSize]);

  const onResizeControllerDragStart = useCallback((_outlineId: string, position: MapOutlinePosition, latLng: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      setHoveredOutlineId(null);
      resizeControllerDragStartDataRef.current = { startPoint: latLng };
      dispatch(drawingToolItemResizeStarted(
        getDraggableCursorForOutlinePosition(position))
      );
    }
  }, [dispatch, drawingEventsEnabledRef]);

  const onResizeControllerDragMove = useCallback((
    _outlineId: string, position: MapOutlinePosition, latLng: LatLng
  ) => {
    if (!resizeControllerDragStartDataRef.current || !drawingEventsEnabledRef.current) {
      return;
    }

    const rangeMin = DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.from * sizePerPixelRatio;
    const rangeMax = DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.to * sizePerPixelRatio;

    const startPointPosition = fromLatLngToDivPixel(resizeControllerDragStartDataRef.current.startPoint);
    const currentPosition = fromLatLngToDivPixel(latLng);

    if (!startPointPosition || !currentPosition) {
      return;
    }

    let distance: number | null = null;

    switch (position) {
      case MapOutlinePosition.TopRight:
      case MapOutlinePosition.BottomRight:
        distance = currentPosition.x - startPointPosition.x;
        break;
      case MapOutlinePosition.TopLeft:
      case MapOutlinePosition.BottomLeft:
        distance = startPointPosition.x - currentPosition.x;
        break;
      default:
        return;
    }

    if (distance === null) {
      return;
    }

    // twice the distance because we point toward image center
    let newSize = instance.settings.imageSize + (distance * 2 * sizePerPixelRatio);

    newSize = clamp(newSize, { min: rangeMin, max: rangeMax });

    setImageSize(newSize);
  }, [drawingEventsEnabledRef, fromLatLngToDivPixel, instance.settings.imageSize, sizePerPixelRatio]);

  const onResizeControllerDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingEditPushNewSnapshot({
        type: DrawingTool.Image,
        value: {
          ...instance,
          settings: {
            ...instance.settings,
            imageSize,
          },
        },
      }));

      resizeControllerDragStartDataRef.current = null;
      dispatch(drawingToolItemResizeStopped());
    }
  }, [dispatch, drawingEventsEnabledRef, imageSize, instance]);

  const onDragStart = useCallback((_id: Uuid, position: LatLng) => {
    if (drawingEventsEnabledRef.current) {
      dragStartDataRef.current = {
        position,
        originalCenter: center,
      };
      dispatch(drawingToolItemMoveStarted());
    }
  }, [dispatch, drawingEventsEnabledRef, center]);

  const onDragEnd = useCallback(() => {
    if (drawingEventsEnabledRef.current) {
      dispatch(drawingEditPushNewSnapshot({
        type: DrawingTool.Image,
        value: {
          ...instance,
          center,
        },
      }));

      dispatch(drawingToolItemMoveStopped());
    }
  }, [drawingEventsEnabledRef, dispatch, instance, center]);

  const onDragMove = useCallback((_id: Uuid, position: LatLng) => {
    if (!dragStartDataRef.current) {
      return;
    }

    const { position: startPosition, originalCenter } = dragStartDataRef.current;

    const latOffset = position.lat - startPosition.lat;
    const lngOffset = position.lng - startPosition.lng;

    setCenter({
      lat: originalCenter.lat + latOffset,
      lng: originalCenter.lng + lngOffset,
    });
  }, []);

  const onClick = useCallback((_id: string, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const onOutlineMouseOver = useCallback((outlineId: string) => {
    dispatch(drawingToolItemHovered());
    setHoveredOutlineId(outlineId);
  }, [dispatch]);

  const onOutlineMouseOut = useCallback(() => {
    dispatch(drawingToolItemHoverStopped());
    setHoveredOutlineId(null);
  }, [dispatch]);

  const renderOutline = useCallback((id: string, outline: MapOutlinePositionInstance) => {
    if (!imageOutlinePositions.includes(outline.position)) {
      return null;
    }

    return (
      <MapOutline
        key={id}
        outline={outline}
        visuals={id === hoveredOutlineId ? outlineHoverVisuals : outlineVisuals}
        onMouseOver={onOutlineMouseOver}
        onMouseOut={onOutlineMouseOut}
        onDragStart={(outlineId, latLng) => onResizeControllerDragStart(outlineId, outline.position, latLng)}
        onDragMove={(outlineId, latLng) => onResizeControllerDragMove(outlineId, outline.position, latLng)}
        onDragEnd={onResizeControllerDragEnd}
      />
    );
  }, [hoveredOutlineId, onOutlineMouseOver, onOutlineMouseOut, onResizeControllerDragMove,
    onResizeControllerDragEnd, onResizeControllerDragStart]);

  if (markerTemplate === null) {
    return null;
  }

  return (
    <DrawingToolImageInstanceMarkerContainer
      markerTemplate={markerTemplate}
      instance={temporaryInstance}
      size={size}
      zIndex={zIndex}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      onClick={onClick}
      renderOutline={renderOutline}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    />
  );
};

const pureComponent = memo(DrawingToolImageSelectedInstanceContainer);
export { pureComponent as DrawingToolImageSelectedInstanceContainer };
