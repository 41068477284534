import { Globals } from '@react-spring/web';
import { useDispatch } from 'react-redux';
import { rightPanelHoverMarker } from '~/store/frontendState/mapTools/locationFinder/locationFinder.actionCreators';
import { MapTypeId } from '../_shared/types/googleMaps/googleMaps.types';
import { waitForMapIdle } from '../map/map.helpers';
import { useMap } from '../map/map/mapContext';
import {
  type AppState, appStore,
} from '../store/app.store';
import { activeMapElementsClearState } from '../store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { mapReset } from '../store/map/map.actionCreators';
import { closeAllModals } from '../store/modal/modal.actionCreators';
import { CoordinateMapType } from './CoordinateMapType';

export const setCoordinateMapType = (map: google.maps.Map) => {
  map.mapTypes.set(
    MapTypeId.Coordinate,
    new CoordinateMapType(new google.maps.Size(256, 256))
  );
};

export const useExposeTestingFunctions = () => {
  const dispatch = useDispatch();

  return () => {
    (window as any).testing = {
      ...(window as any).testing,
      resetMap: () => {
        dispatch(mapReset());
      },
      closeAllModals: () => {
        dispatch(closeAllModals());
      },
      hideHoveredMarkerIndicator: () => {
        dispatch(rightPanelHoverMarker(null));
      },
      reduxSelect: (selectFn: (state: AppState) => any) => {
        return selectFn(appStore.getState());
      },
      reduxDispatch: (action: any) => {
        dispatch(action);
      },
      clearActiveMapElements: () => {
        dispatch(activeMapElementsClearState());
      },
    };
  };
};
// Used to expose functions which require map context

export const useExposeMapTestingFunctions = () => {
  const map = useMap();

  return () => {
    (window as any).testing = {
      ...(window as any).testing,
      waitForMapIdle: () => waitForMapIdle(map),
    };
  };
};

export const disableReactSpringAnimations = () => {
  Globals.assign({
    skipAnimation: true,
  });
};
