import { type BoundaryFormActions } from './boundaryForm.actions';
import {
  BOUNDARY_FORM_RESET, BOUNDARY_FORM_SET_TYPE_AND_MODE,
} from './boundaryForm.actionTypes';
import { type BoundaryFormState } from './boundaryForm.state';

const boundaryFormInitialState: BoundaryFormState = {
  type: null,
  mode: null,
};

export const boundaryFormReducer = (
  state: BoundaryFormState = boundaryFormInitialState,
  action: BoundaryFormActions
): BoundaryFormState => {
  switch (action.type) {
    case BOUNDARY_FORM_RESET: {
      return boundaryFormInitialState;
    }
    case BOUNDARY_FORM_SET_TYPE_AND_MODE: {
      return {
        type: action.payload.type,
        mode: action.payload.mode,
      };
    }

    default:
      return state;
  }
};
