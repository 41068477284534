import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback,
  useMemo,
} from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { LottieAnimationComponent } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import {
  type SliderConstraint, SliderConstraintType,
} from '~/_shared/components/slider/slider.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { ConstraintSliderInput } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/constraintSliderInput.component';

const constraintsSliderContainer = css({
  flex: 1,
  margin: '0 20px',
});

const constraintsSliderInputsContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
});

const constraintsName = css({
  marginLeft: 10,
  fontSize: 14,
});

const constraintsNameOpen = css({
  width: 140,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const removeIcon = css({
  cursor: 'pointer',
  marginRight: 5,
  marginLeft: 'auto',
});

const animationWrapperStyle = css({
  alignSelf: 'flex-start',
  marginTop: -5,
});

const contentStyle = css({
  marginTop: 15,
  display: 'flex',
  alignItems: 'center',
});

const headingStyle = css({
  display: 'flex',
  alignItems: 'center',
});

type ConstraintSliderProps = {
  animationType?: 'success' | 'error';
  checkable: boolean;
  checked?: boolean;
  highestMin?: number | null;
  id: string;
  label: string;
  lowestMax?: number | null;
  max: number;
  maxValue?: number;
  min: number;
  minValue?: number;
  removable: boolean;

  onChange: (id: string, change: {
    isConstraint?: boolean;
    minConstraint?: number;
    maxConstraint?: number;
  }) => void;
  onRemove?: (id: string) => void;
};

export const ConstraintSlider = (props: ConstraintSliderProps) => {
  const {
    animationType,
    checkable,
    checked = false,
    highestMin,
    id,
    label,
    lowestMax,
    max,
    min,
    removable,
    onRemove,
  } = props;

  const minValue = props.minValue === undefined ? min : (props.minValue < min ? min : props.minValue);
  const maxValue = props.maxValue === undefined ? max : (props.maxValue > max ? max : props.maxValue);

  const onChange = useCallback((change: {
    isConstraint?: boolean;
    minConstraint?: number;
    maxConstraint?: number;
  }) => {
    // only propagate when something changes
    if (change.isConstraint === checked && change.minConstraint === minValue && change.maxConstraint === maxValue) {
      return;
    }

    const propagate = props.onChange;
    propagate(id, change);
  }, [checked, id, maxValue, minValue, props.onChange]);

  const constraints = useMemo(() => {
    const result = [] as SliderConstraint[];

    if (highestMin) {
      result.push(({
        type: SliderConstraintType.LessThan,
        value: highestMin,
      }));
    }

    if (lowestMax) {
      result.push(({
        type: SliderConstraintType.GreaterThan,
        value: lowestMax,
      }));
    }

    if (result.length) {
      return result;
    }
    return undefined;
  }, [highestMin, lowestMax]);

  return (
    <div key={id} >
      <div css={headingStyle}>
        {checkable && (
          <CheckboxComponent
            isChecked={checked ?? false}
            checkedSetter={() => onChange({ isConstraint: !checked })}
          />
        )}
        <span css={[constraintsName, (!checkable || checked) ? constraintsNameOpen : null]}>{label}</span>

        {removable && (
          <FontAwesomeIcon
            css={removeIcon}
            onClick={() => onRemove?.(id)}
            icon={faTimes}
          />
        )}
      </div>

      {(!checkable || checked) && (
        <div css={contentStyle}>
          <div css={constraintsSliderContainer}>
            <SliderWithValueLabelsComponent
              noLabel
              min={min}
              max={max}
              value={[minValue, maxValue]}
              onChange={([_minValue, _maxValue]) => onChange({ minConstraint: _minValue, maxConstraint: _maxValue })}
              constraints={constraints}
            />

            <div css={constraintsSliderInputsContainer}>
              <ConstraintSliderInput
                max={highestMin ?? undefined}
                min={min}
                value={minValue}
                onChange={newValue => onChange({
                  minConstraint: newValue > maxValue ? maxValue : newValue,
                  maxConstraint: newValue > maxValue ? newValue : maxValue,
                })}
              />
              <ConstraintSliderInput
                value={maxValue}
                min={lowestMax ?? undefined}
                max={max}
                onChange={newValue => onChange({
                  minConstraint: newValue < minValue ? newValue : minValue,
                  maxConstraint: newValue < minValue ? minValue : newValue,
                })}
              />
            </div>
          </div>

          {animationType !== undefined && (
            <div css={animationWrapperStyle}>
              {animationType === 'success' && (
                <LottieAnimationComponent
                  size={24}
                  type={LottieAnimationTypes.Success}
                />
              )}

              {animationType === 'error' && (
                <LottieAnimationComponent
                  size={24}
                  type={LottieAnimationTypes.Failed}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
