import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BoundaryEditAction } from '../../boundary/editMode/boundaryEditModal/boundaryEditModal.helpers';
import { useBoundaryEditPolygon } from '../../sidebar/sidebarApps/mapTools/boundary/edit/useBoundaryEditPolygon';
import { useGetBoundaryGroup } from '../../store/boundaryGroups/boundaryGroups.selectors';
import { boundarySelectEditCustomBoundaryOpenModal } from '../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { clearBoundaryLocationsFilter } from '../../store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import { sortNumbersAsc } from '../utils/sort';

export const useTerritoryEdit = () => {
  const dispatch = useDispatch();
  const { toggleBoundaryPolygonEdit } = useBoundaryEditPolygon();
  const { getBoundaryGroupById } = useGetBoundaryGroup();

  return useCallback((boundaryId: number, boundaryGroupId: number, boundaryTerritoryGroupId: number) => {
    const boundaryGroup = getBoundaryGroupById(boundaryGroupId);
    const sourceBoundaryGroupId = [...(boundaryGroup?.createdFrom?.boundaryGroupIds ?? [])].sort(sortNumbersAsc)[0];

    const sourceBoundaryGroup = getBoundaryGroupById(sourceBoundaryGroupId);

    dispatch(clearBoundaryLocationsFilter());

    if (sourceBoundaryGroup) {
      dispatch(boundarySelectEditCustomBoundaryOpenModal({
        actionType: BoundaryEditAction.EditBoundaries,
        boundaryTerritoryGroupId,
        selectBoundaryGroupId: sourceBoundaryGroup.editBoundaryGroupFallback ?? sourceBoundaryGroup.id,
        boundaryId,
      }));
      return;
    }

    toggleBoundaryPolygonEdit(boundaryId, boundaryGroupId, boundaryTerritoryGroupId);
  }, [dispatch, toggleBoundaryPolygonEdit, getBoundaryGroupById]);
};
