import { type DemographicsListGlobalAction } from './demographicsListGlobal.action';
import {
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_ERROR,
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START,
  DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_SUCCESS,
} from './demographicsListGlobal.actionTypes';
import { type DemographicsListGlobalState } from './demographicsListGlobal.state';

const initialState: DemographicsListGlobalState = {
  isLoading: false,
  isDataFetched: false,
  regions: [],
};

export function demographicsListGlobalReducer(state = initialState, action: DemographicsListGlobalAction): DemographicsListGlobalState {
  switch (action.type) {
    case DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DEMOGRAPHICS_LIST_GLOBAL_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isDataFetched: true,
        regions: action.payload.regions,
      };
    }

    default:
      return state;
  }
}
