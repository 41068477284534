import { css } from '@emotion/react';
import {
  type FC, useState,
} from 'react';
import {
  AutocompleteComponent, type AutocompleteOption,
} from '~/_shared/baseComponents/autocomplete';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import type { BoundaryId } from '~/store/boundaries/boundaryIdentifier.type';

const radioGroupStyle = css({
  display: 'flex',
  flexDirection: 'row',
  margin: '10px 0',
  gap: 35,
});

const radioItemStyle = css({
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  width: 'auto',
});

const radioIconStyle = (isActive: boolean) => css({
  width: 16,
  height: 16,
  borderWidth: isActive ? 4 : 2,
});

const selectedBoundaryLabelStyle = (theme: Theme) => css({
  color: theme.textColors.link,
});

const selectedBoundaryNameStyle = css({
  marginTop: 10,
});

enum BoundaryEditSelectMethod {
  FromList = 'FromList',
  FromMap = 'FromMap'
}

type BoundaryEditSubmitModalSelectBoundaryProps = Readonly<{
  isDisabled: boolean;
  placeholder: string;
  selectedBoundaryId: BoundaryId | null;
  selectedBoundaryIdOptions: ReadonlyArray<AutocompleteOption<BoundaryId>>;
  selectedBoundaryName: string | null;

  onGoToMapClick: () => void;
  onSelectedBoundaryIdChange: (boundaryId: BoundaryId) => void;
}>;

export const BoundaryEditSubmitModalSelectBoundaryComponent: FC<BoundaryEditSubmitModalSelectBoundaryProps> = (props) => {
  const [selectMethod, setSelectMethod] = useState(BoundaryEditSelectMethod.FromList);
  const [t] = useTranslation();

  const selectMethodOptions: RadioGroupItem<BoundaryEditSelectMethod>[] = [{
    value: BoundaryEditSelectMethod.FromList,
    isDisabled: props.isDisabled,
    label: t('From list'),
  }, {
    value: BoundaryEditSelectMethod.FromMap,
    isDisabled: props.isDisabled,
    label: t('From map'),
  }];

  return (
    <div>
      <RadioGroupComponent<BoundaryEditSelectMethod>
        css={radioGroupStyle}
        itemCommonStyle={radioItemStyle}
        radioIconStyle={radioIconStyle}
        selectedValue={selectMethod}
        onValueChange={setSelectMethod}
        items={selectMethodOptions}
      />

      {selectMethod === BoundaryEditSelectMethod.FromList && (
        <AutocompleteComponent
          onChange={props.onSelectedBoundaryIdChange}
          options={props.selectedBoundaryIdOptions}
          value={props.selectedBoundaryId}
          inPortal
          placeholder={props.placeholder}
          isDisabled={props.isDisabled}
          hideChevron={props.isDisabled}
        />
      )}

      {selectMethod === BoundaryEditSelectMethod.FromMap && (
        <div>
          {!props.isDisabled && (
            <ButtonComponent
              text={t('Go To Map')}
              css={{
                minWidth: 140,
              }}
              buttonStyle={ButtonStyle.Secondary}
              onClick={props.onGoToMapClick}
            />
          )}

          {props.selectedBoundaryName !== null && (
            <div css={selectedBoundaryNameStyle}>
              <span css={selectedBoundaryLabelStyle}>
                {`${t('Selected:')} `}
              </span>
              <span>
                {props.selectedBoundaryName}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
