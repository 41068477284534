import { css } from '@emotion/react';
import { type Theme } from '../../themes/theme.model';
import { type ThemeProps } from '../../types/themeProps';
import { type ToggleSize } from './toggle.component';
import {
  animationLengthInMS, BORDER_WIDTH, getToggleHeight, PADDING_CIRCLE, PADDING_TEXT,
} from './toggle.helpers';

export const checkBoxStyle = css({
  display: 'none',
});

export const labelStyle = (props: ThemeProps<{
  height: number;
  isDisabled: boolean;
  isOn: boolean;
}>) => css({
  alignItems: 'center',
  backgroundColor: props.theme.switchColors.primaryBackground,
  border: `${BORDER_WIDTH}px solid ${props.theme.switchColors.primaryBorder}`,
  borderRadius: props.height,
  boxSizing: 'border-box',
  display: 'flex',
  flexShrink: 0,
  height: props.height,
  justifyContent: 'space-between',
  opacity: props.isDisabled ? '60%' : '100%',
  ...(props.isOn ? { paddingLeft: PADDING_TEXT } : { paddingRight: PADDING_TEXT }),
  position: 'relative',

  '&:hover': {
    backgroundColor: props.isDisabled ? props.theme.switchColors.primaryBackground : props.theme.backgroundColors.highlight,
  },
});

export const iconOrLabelWrapperStyle = (props: ThemeProps<{
  fontSize: number;
  isOn: boolean;
}>) => css({
  alignItems: 'center',
  color: props.isOn ? props.theme.switchColors.primaryOn : props.theme.switchColors.primaryOff,
  display: 'grid',
  gridTemplateColumns: '1fr',
  fontSize: props.fontSize,
  height: '100%',
  justifyItems: props.isOn ? 'start' : 'end',
  textTransform: 'uppercase',
});

export const iconOrLabelStyle = ({ isOn, size, visible }: { isOn: boolean; size: ToggleSize; visible: boolean }) => css({
  gridRowStart: 1,
  gridColumnStart: 1,
  opacity: !visible ? 0 : undefined,
  paddingRight: isOn ? getToggleHeight(size) : undefined,
  paddingLeft: !isOn ? getToggleHeight(size) : undefined,
});

export const spanStyle = (props: {
  theme: Theme;
  isOn: boolean;
  radius: number;
}) => css({
  background: props.isOn ? props.theme.switchColors.secondaryOn : props.theme.switchColors.primaryOff,
  borderRadius: '50%',
  height: `${props.radius}px`,
  position: 'absolute',
  left: !props.isOn ? PADDING_CIRCLE : `calc(100% - ${props.radius + PADDING_CIRCLE}px)`,
  transition: `left ${animationLengthInMS}ms`,
  width: `${props.radius}px`,
});
