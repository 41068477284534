import {
  useCallback, useMemo,
} from 'react';
import { matchUpDataAllDataSections } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { CustomizationLevel } from '~/customizeMarkerPopup/customizeLabelAndMarker.types';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { RightSidebarOpeneableApps } from '~/sidebar/sidebarApps/rightSidebar/rightSidebarConfiguration';
import { useActiveMarkerIdSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { type EditLocationCallbackProps } from './contextMenuStructure.types';
import { createRightSidebarContextMenuCallbackProps } from './editLocationCallbacks.helpers';

type EditLocationCallbacksProps = Readonly<{
  areBubbleItemsIncluded: boolean;
  disableEditMarker: boolean;
  disabledEditMarkerTooltip: string;

  selectOpenableApp: (app: RightSidebarOpeneableApps, isOpened: boolean) => void;
}>;

export const useEditLocationCallbacks = (props: EditLocationCallbacksProps): EditLocationCallbackProps => {
  const activeMarkerId = useActiveMarkerIdSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const { openModal: openCustomizeLabelAndMarker } = useModal(ModalType.LabelAndMarkerSettings);
  const { openModal: openEditMarker } = useModal(ModalType.EditLocationData);
  const { openModal: openEditImage } = useModal(ModalType.ImageLibrary);
  const { openModal: openMatchLocationModal } = useModal(ModalType.MatchupData);

  const { selectOpenableApp } = props;

  const editDataCallback = useCallback(() => {
    if (!activeMarkerId) {
      return;
    }

    openEditMarker({
      spreadsheetRowId: activeMarkerId,
    });
  }, [activeMarkerId, openEditMarker]);

  const editMarkerCallback = useCallback(() => {
    if (!activeMarkerId) {
      throw new Error('useEditLocationCallbacks: Missing marker Id');
    }
    openCustomizeLabelAndMarker({
      customizationLevel: CustomizationLevel.Individual,
      overrideGroupMarkers: true,
      spreadsheetRowId: activeMarkerId,
      markerSettingsTypes: [MarkerSettingType.Marker],
    });
  }, [activeMarkerId, openCustomizeLabelAndMarker]);

  const editPanelCallback = useCallback(() => {
    selectOpenableApp(RightSidebarOpeneableApps.EditPanel, true);
  }, [selectOpenableApp]);

  const editImageCallback = useCallback(() => {
    if (activeMarkerId) {
      openEditImage({ markerId: activeMarkerId });
    }
  }, [openEditImage, activeMarkerId]);

  const categorizeDataCallback = useCallback(() => {
    openMatchLocationModal({
      ensureLocationDataIsSelected: false,
      sections: matchUpDataAllDataSections,
    });
  }, [openMatchLocationModal]);

  return useMemo(() => ({
    categorizeData: categorizeDataCallback,
    editData: createRightSidebarContextMenuCallbackProps({
      callback: editDataCallback,
      isDisabled: isLayeredMapConnected,
    }),
    editImages: createRightSidebarContextMenuCallbackProps({ callback: editImageCallback }),
    editMarker: createRightSidebarContextMenuCallbackProps({
      callback: editMarkerCallback,
      isDisabled: props.disableEditMarker,
      disabledTooltip: props.disabledEditMarkerTooltip,
    }),
    editPanel: createRightSidebarContextMenuCallbackProps({
      isDisabled: !props.areBubbleItemsIncluded,
      callback: editPanelCallback,
    }),
  }), [categorizeDataCallback, editDataCallback, isLayeredMapConnected, editImageCallback, editMarkerCallback,
    props.disableEditMarker, props.disabledEditMarkerTooltip, props.areBubbleItemsIncluded, editPanelCallback]);
};
