import { type UserFileResponse } from '../../mapSettings/fileAttachments/fileApi.repository';
import {
  FILE_ATTACHMENTS_URLS_SYNCED,
  FILE_URLS_UPDATE,
  FILE_URLS_UPDATE_REQUEST,
  USER_FILES_DELETED,
} from './fileUrls.actionTypes';
import { type FileUrl } from './fileUrls.state';

export const fileUrlsUpdate = (fileUrlsMap: ReadonlyMap<number, FileUrl>) => ({
  type: FILE_URLS_UPDATE,
  payload: {
    fileUrlsMap,
  },
}) as const;

export const fileUrlsUpdateRequest = (files: ReadonlyArray<UserFileResponse>) => ({
  type: FILE_URLS_UPDATE_REQUEST,
  payload: {
    files,
  },
}) as const;

export const fileAttachmentsUrlsSynced = () => ({
  type: FILE_ATTACHMENTS_URLS_SYNCED,
}) as const;

export const userFilesDeleted = (fileIds: number[]) => ({
  type: USER_FILES_DELETED,
  payload: {
    fileIds,
  },
}) as const;
