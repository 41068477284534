import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { type UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  type GroupRadiusEditLevel,
  type ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type GeoLocation } from '~/_shared/utils/geolocation/geolocation';
import { type GroupData } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED,
  PROXIMITY_TOOL_SET_APPLY_TO,
  PROXIMITY_TOOL_SET_BORDER_COLOR,
  PROXIMITY_TOOL_SET_BORDER_LINE_WIDTH,
  PROXIMITY_TOOL_SET_PROXIMITY_COLOR,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED,
  PROXIMITY_TOOL_SET_PROXIMITY_WITHIN,
  PROXIMITY_TOOL_SET_SELECTED_GROUP,
  PROXIMITY_TOOL_SET_SELECTED_GROUP_COLUMN,
  PROXIMITY_TOOL_SET_STARTING_LOCATION,
  PROXIMITY_TOOL_SET_TRAVEL_TIME_HOURS,
  PROXIMITY_TOOL_SET_TRAVEL_TIME_MINUTES,
  PROXIMITY_TOOL_SET_TYPE,
  PROXIMITY_TOOL_SET_UNIT,
} from './proximityTool.actionTypes';

export const setApplyTo = (applyTo: GroupRadiusEditLevel) => ({
  type: PROXIMITY_TOOL_SET_APPLY_TO,
  payload: {
    applyTo,
  },
}) as const;

export const setType = (type: ProximityType) => ({
  type: PROXIMITY_TOOL_SET_TYPE,
  payload: {
    type,
  },
}) as const;

export const setUnit = (unit: UnitSystem) => ({
  type: PROXIMITY_TOOL_SET_UNIT,
  payload: {
    unit,
  },
}) as const;

export const setTravelTimeHours = (travelTimeHours: number | null) => ({
  type: PROXIMITY_TOOL_SET_TRAVEL_TIME_HOURS,
  payload: {
    travelTimeHours,
  },
}) as const;

export const setTravelTimeMinutes = (travelTimeMinutes: number | null) => ({
  type: PROXIMITY_TOOL_SET_TRAVEL_TIME_MINUTES,
  payload: {
    travelTimeMinutes,
  },
}) as const;

export const setProximityWithin = (proximityWithin: number | null) => ({
  type: PROXIMITY_TOOL_SET_PROXIMITY_WITHIN,
  payload: {
    proximityWithin,
  },
}) as const;

export const setSelectedGroup = (selectedGroup: GroupData | null) => ({
  type: PROXIMITY_TOOL_SET_SELECTED_GROUP,
  payload: {
    selectedGroup,
  },
}) as const;

export const setSelectedGroupColumn = (selectedGroupColumn: SpreadsheetColumnId) => ({
  type: PROXIMITY_TOOL_SET_SELECTED_GROUP_COLUMN,
  payload: {
    selectedGroupColumn,
  },
}) as const;

export const setProximityColor = (proximityColor: ColorResult) => ({
  type: PROXIMITY_TOOL_SET_PROXIMITY_COLOR,
  payload: {
    proximityColor,
  },
}) as const;

export const setBorderColor = (borderColor: ColorResult) => ({
  type: PROXIMITY_TOOL_SET_BORDER_COLOR,
  payload: {
    borderColor,
  },
}) as const;

export const setBorderLineWidth = (borderLineWidth: number) => ({
  type: PROXIMITY_TOOL_SET_BORDER_LINE_WIDTH,
  payload: {
    borderLineWidth,
  },
}) as const;

export const setStartingLocation = (startingLocation: GeoLocation, openProximityToolBar?: boolean) => ({
  type: PROXIMITY_TOOL_SET_STARTING_LOCATION,
  payload: {
    startingLocation,
    openProximityToolBar,
  },
}) as const;

export const startIndividualRadiusDrag = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED,
}) as const;

export const endIndividualRadiusDrag = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED,
}) as const;

export const individualRadiusCenterHovered = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED,
}) as const;

export const individualRadiusCenterHoverEnded = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED,
}) as const;

export const startIndividualRadiusDnDMode = (proximityId: string) => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED,
  payload: {
    proximityId,
  },
}) as const;

export const endIndividualRadiusDnDMode = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED,
}) as const;

export const startIndividualRadiusHoverInDnD = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED,
}) as const;

export const endIndividualRadiusHoverInDnD = () => ({
  type: PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED,
}) as const;

export const proximityHovered = (proximityId: string) => ({
  type: PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED,
  payload: {
    proximityId,
  },
}) as const;

export const proximityHoverEnded = (proximityId: string) => ({
  type: PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED,
  payload: {
    proximityId,
  },
}) as const;
