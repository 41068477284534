import { type LatLng } from '~/_shared/types/latLng';
import { MarkerAnchorPosition } from '~/_shared/types/marker.types';
import {
  getSingleZIndex, ZIndexedEntity,
} from '../zIndexes/zIndexRanges';
import {
  PredefinedTemplate, predefinedTemplates,
} from './markers/manager/mapMarkerTemplates';

export class MapToolsManager {
  private readonly highPriorityMarkerLayer: MarkersLayer;
  private locationLatLng: LatLng | null;
  private locationMarker: WebglOverlayMarker | null;
  private hoveredMarker: WebglOverlayMarker | null;

  constructor(highPriorityMarkerLayer: MarkersLayer) {
    this.highPriorityMarkerLayer = highPriorityMarkerLayer;
  }

  public updateLocation = (latLng: LatLng | null) => {
    if (this.locationLatLng === latLng) {
      return;
    }
    if (this.locationMarker) {
      this.highPriorityMarkerLayer.remove(this.locationMarker);
      this.locationMarker = null;
    }
    if (latLng) {
      this.addLocationMarker(latLng);
    }
    this.locationLatLng = latLng;
  };

  private addLocationMarker = (latLng: LatLng) => {
    const zIndex = getSingleZIndex(ZIndexedEntity.LocationFinderMarker);
    this.locationMarker = this.createMarker(latLng, PredefinedTemplate.LocationMarker, zIndex);
    this.highPriorityMarkerLayer.add(this.locationMarker);
  };

  private createMarker = (data: LatLng, template: PredefinedTemplate, zIndex: number) => new WebGLOverlay.Marker({
    lat: data.lat,
    lng: data.lng,
    size: 60,
    zIndex,
    template,
    anchor: template === PredefinedTemplate.RadarMarker
      ? MarkerAnchorPosition.CENTER : MarkerAnchorPosition.BOTTOM_CENTER,
    offset: template === PredefinedTemplate.LocationMarker
      ? predefinedTemplates.find(t => t.name === PredefinedTemplate.LocationMarker)?.offsets : undefined,
  });

  public destroy = () => {
    if (this.locationMarker) {
      this.highPriorityMarkerLayer.remove(this.locationMarker);
      this.locationMarker = null;
    }
    if (this.hoveredMarker) {
      this.highPriorityMarkerLayer.remove(this.hoveredMarker);
      this.hoveredMarker = null;
    }
  };
}
