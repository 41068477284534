import { type ClientLicenseType } from '~/_shared/types/client/license';
import {
  apiDelete, apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';
import { type MemberRole } from './memberRole.type';

export enum SendInvitationErrorCode {
  USER_LIMIT_REACHED = 'USER_LIMIT_REACHED',
  ALREADY_ADDED_TO_CLIENT = 'ALREADY_ADDED_TO_CLIENT',
  USER_CANNOT_BE_INVITED = 'USER_CANNOT_BE_INVITED',
}

export enum InvitationStatus {
  ACCEPTED = 'Accepted',
  PENDING = 'Pending',
  EXPIRED = 'Expired',
  REJECTED = 'Rejected',
  UNKNOWN = 'N/A',
}

export enum MemberStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  ACTIVE = 'Active',
}

export type GetInvitationsResponse = {
  data: Invitation[];
};

export type TeamMemberLimitsData = {
  limit: number;
  used: number;
  remaining: number;
};

export type GetMembersResponse = {
  members: Member[];
  limits: TeamMemberLimitsData;
};

export type RoleItem = {
  id: number;
  name: string;
};

export type GetRolesResponse = {
  data: RoleItem[];
};

export type MemberBasicInfo = Readonly<{
  id: number;
  name: string;
}>;

export type Member = MemberBasicInfo & Readonly<{
  email: string;
  role: MemberRole | null;
  status: MemberStatus | null;
  invitation: InvitationStatus;
}>;

export type Invitee = {
  id: string;
  first_name: string;
  last_name: string;
  license: ClientLicenseType;
  status: MemberStatus;
};

export type Invitation = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  invitee: null | Invitee;
  status: InvitationStatus;
  issued_at: string;
  expire_at: string;
};

type SendInvitationRequestSuccessResponse = {
  data: Invitation[];
};

type SendInvitationRequestFailResponse = {
  errorCode: SendInvitationErrorCode | string;
};

export const getInvitationsRequest = (
  clientId: number
): Promise<GetInvitationsResponse> => {
  const requestUrl = `/api/clients/${clientId}/client-admin`;
  return apiGet(requestUrl, {
    clientId,
  });
};

export const getMembersRequest = (
  clientId: number
): Promise<GetMembersResponse> => {
  const requestUrl = `/api/clients/${clientId}/members`;
  return apiGet(requestUrl, {
    clientId,
  });
};

export const getRolesRequest = (): Promise<GetRolesResponse> => {
  const requestUrl = '/api/roles';
  return apiGet(requestUrl);
};

export const sendInvitationRequest = (
  email: string,
  firstName: string,
  lastName: string,
  clientId: number,
): Promise<SendInvitationRequestSuccessResponse | SendInvitationRequestFailResponse> => {
  const requestUrl = `/api/clients/${clientId}/client-admin/send-invite`;
  return apiPost(requestUrl, {
    email,
    first_name: firstName,
    last_name: lastName,
  });
};

export const revokeInvitationRequest = (
  clientId: number,
  invitationId: string
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/client-admin/revoke-invite?id=${invitationId}`;
  return apiDelete(requestUrl, {
    invitationId,
  });
};

export const requestDeleteUserRequest = (
  clientId: number,
  memberId: number
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/members/${memberId}/delete`;
  return apiDelete(requestUrl);
};

export const enableMemberRequest = (
  clientId: number,
  memberId: number,
  enable: boolean
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/members/${memberId}/enable`;
  return apiPost(requestUrl, {
    is_enabling: enable,
  });
};

export const changeRoleRequest = (
  clientId: number,
  memberId: number,
  roleId: number
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/members/${memberId}/change-role`;
  return apiPost(requestUrl, {
    role_id: roleId,
  });
};
