import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import numeral from 'numeral';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { ProgressBarComponent } from '~/_shared/baseComponents/loaders';
import {
  LottieAnimationComponent, LottieAnimationTypes, useLottieAnimationDefaultColors,
} from '../../baseComponents/lottieAnimation';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { useTranslation } from '../../utils/hooks';

export enum UploadStatus {
  InProgress,
  Error,
  Success
}

type UploadStatusProps = Readonly<{
  className?: string;
  file: File;
  iconSize: number;
  iconMargin: number;
  isRemoveDisabled?: boolean;
  onRemoveClick: () => void;
  onRetryClick: () => void;
  orderIndex: number;
  progress: number;
  status: UploadStatus;
}>;

const containerStyles = css({
  display: 'flex',
  maxWidth: '100%',
});

const progressBarStyle = css({
  width: 176,
});

const summaryStyles = (props: ThemeProps<{ isOrderIndexOdd: boolean }>) => css({
  background: props.isOrderIndexOdd ? 'rgba(0, 120, 206, 0.1)' : 'none',
  color: props.isOrderIndexOdd ? props.theme.textColors.link : props.theme.textColors.tertiary,
  cursor: 'default',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: '2px 8px',
  alignItems: 'center',
});

const ctaWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
});

const filenameStyles = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 300,
});

const errorLabelStyles = css({
  whiteSpace: 'nowrap',
});

const progressBarWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const progressBarIconStyle = ({ marginLeft }: { marginLeft: number }) => css({
  flexShrink: 0,
  marginLeft,
});

const accessoryButtonStyle = (props: ThemeProps<{
  isVisible: boolean;
  hoverFontColor?: string;
}>) => css({
  background: 'none',
  border: 'none',
  fontSize: '16px',
  fontWeight: 500,
  padding: 0,
  marginRight: 8,
  transition: 'color .2s',
  visibility: props.isVisible ? 'visible' : 'hidden',
  '&:hover': {
    color: props.theme.buttonColors.primaryBackgroundOnHover,
  },
});

const retryButtonStyles = (props: ThemeProps<{ isVisible: boolean }>) => css({
  color: props.theme.buttonColors.primaryBackground,
  marginRight: 8,
}, accessoryButtonStyle({ ...props, hoverFontColor: props.theme.buttonColors.primaryBackgroundOnHover }));

const removeButtonStyles = (props: ThemeProps<{ isVisible: boolean }>) => css({
  color: props.theme.textColors.tertiary,
  margin: '0 10px',
}, accessoryButtonStyle({ ...props, hoverFontColor: props.theme.textColors.secondary }));

export const UploadStatusComponent: FC<UploadStatusProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const animationColors = useLottieAnimationDefaultColors();

  return (
    <div
      className={props.className}
      css={containerStyles}
    >
      <div css={summaryStyles({ theme, isOrderIndexOdd: !!(props.orderIndex % 2) })}>
        <span
          title={props.file.name}
          css={filenameStyles}
        >
          {props.file.name}
        </span>
        {props.status === UploadStatus.Success &&
          <span>{numeral(props.file.size).format('0a')}B</span>
        }
        {props.status === UploadStatus.Error &&
          <span css={errorLabelStyles}>{t('Failed')}</span>
        }
        {props.status === UploadStatus.InProgress && (
          <div css={progressBarWrapperStyle}>
            <ProgressBarComponent
              css={progressBarStyle}
              height={8}
              progress={props.progress}
              trackOffset={1}
              borderWidth={1}
            />

            <LottieAnimationComponent
              css={progressBarIconStyle({ marginLeft: props.iconMargin })}
              size={props.iconSize}
              type={LottieAnimationTypes.Saving}
              colors={animationColors.Saving}
              autoplay
              loop
            />
          </div>
        )}
      </div>

      <div css={ctaWrapperStyles}>
        <button
          css={removeButtonStyles({ theme, isVisible: !props.isRemoveDisabled })}
          onClick={props.onRemoveClick}
          disabled={props.isRemoveDisabled}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <button
          css={retryButtonStyles({ theme, isVisible: props.status === UploadStatus.Error })}
          onClick={props.onRetryClick}
        >
          {t('Retry')}
        </button>
      </div>
    </div>
  );
};
