import { type GroupingColumnValues } from '../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type NumericalRange } from '../../../_shared/utils/range/range.helpers';
import { type FilterConjunction } from '../../../sidebar/sidebarApps/mapTools/filterTool/filters/filterConjunction.enum';
import {
  MAP_SETTINGS_COLUMNS_FILTER_CHANGE_ATTRIBUTE_CONJUNCTION,
  MAP_SETTINGS_COLUMNS_FILTER_CHANGE_TEXT_CONJUNCTION,
  MAP_SETTINGS_COLUMNS_FILTER_CLEAR_FILTERS,
  MAP_SETTINGS_COLUMNS_FILTER_REMOVE_FILTER_VALUES,
  MAP_SETTINGS_COLUMNS_FILTER_SET_ATTRIBUTE,
  MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_FROM,
  MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_TO, MAP_SETTINGS_COLUMNS_FILTER_SET_GROUP,
  MAP_SETTINGS_COLUMNS_FILTER_SET_NUMBER,
  MAP_SETTINGS_COLUMNS_FILTER_SET_TEXT,
} from './mapSettingsColumnsFilter.actionTypes';
import { type MapSettingsFilterTextValue } from './mapSettingsColumnsFilter.state';

export const mapSettingsColumnsFilterClearFilters = () => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_CLEAR_FILTERS,
}) as const;

export const mapSettingsColumnsFilterRemoveFilterValues = (spreadsheetColumnId: SpreadsheetColumnId) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_REMOVE_FILTER_VALUES,
  payload: {
    spreadsheetColumnId,
  },
}) as const;

export const mapSettingsColumnsFilterSetText = (spreadsheetColumnId: SpreadsheetColumnId, values: MapSettingsFilterTextValue[]) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_TEXT,
  payload: {
    spreadsheetColumnId,
    values,
  },
}) as const;

export const mapSettingsColumnsFilterChangeTextConjunction = (spreadsheetColumnId: SpreadsheetColumnId,
  conjunction: FilterConjunction) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_CHANGE_TEXT_CONJUNCTION,
  payload: {
    spreadsheetColumnId,
    conjunction,
  },
}) as const;

export const mapSettingsColumnsFilterSetGroup = (spreadsheetColumnId: SpreadsheetColumnId, group: GroupingColumnValues<1>) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_GROUP,
  payload: {
    spreadsheetColumnId,
    group,
  },
}) as const;

export const mapSettingsColumnsFilterSetNumber = (spreadsheetColumnId: SpreadsheetColumnId,
  range: NumericalRange) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_NUMBER,
  payload: {
    spreadsheetColumnId,
    range,
  },
}) as const;

export const mapSettingsColumnsFilterSetDateFrom = (spreadsheetColumnId: SpreadsheetColumnId,
  dateFrom: string | null) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_FROM,
  payload: {
    spreadsheetColumnId,
    dateFrom,
  },
}) as const;

export const mapSettingsColumnsFilterSetDateTo = (spreadsheetColumnId: SpreadsheetColumnId,
  dateTo: string | null) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_TO,
  payload: {
    spreadsheetColumnId,
    dateTo,
  },
}) as const;

export const mapSettingsColumnsFilterSetAttribute = (spreadsheetColumnId: SpreadsheetColumnId,
  selectedAttributeIds: Record<string, 1>) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_SET_ATTRIBUTE,
  payload: {
    spreadsheetColumnId,
    selectedAttributeIds,
  },
}) as const;

export const mapSettingsColumnsFilterChangeAttributeConjunction = (spreadsheetColumnId: SpreadsheetColumnId,
  conjunction: FilterConjunction) => ({
  type: MAP_SETTINGS_COLUMNS_FILTER_CHANGE_ATTRIBUTE_CONJUNCTION,
  payload: {
    spreadsheetColumnId,
    conjunction,
  },
}) as const;
