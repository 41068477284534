import {
  type FC, Fragment, useCallback,
} from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useIsWMSEnabledSelector } from '~/store/userData/userData.selectors';
import { useBoundaryActiveItems } from '../hooks/useBoundaryActiveItems';
import {
  ActiveBoundaryComponent, type ActiveBoundaryProps,
} from './activeBoundary.component';

type ActiveBoundariesComponentProps = {
  legendMode?: boolean;
};

export const ActiveBoundariesComponent: FC<ActiveBoundariesComponentProps> = ({ legendMode }) => {
  const activeBoundaries = useBoundaryActiveItems();
  const isWMSEnabled = useIsWMSEnabledSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const sidebarBTGHighlightedId = useSelector(state => state.frontendState.mapTools.boundary.boundarySidebarHighlight.highlightedBoundaryTerritoryGroupId);

  const isHidden = useCallback((boundary: ActiveBoundaryProps) => {
    return isMapPresentational && boundary.isAiGenerated && !isWMSEnabled;
  }, [isMapPresentational, isWMSEnabled]);

  return (
    <>
      {activeBoundaries.filter(item => !isHidden(item)).map((item) => (
        <Fragment key={item.boundaryTerritoryGroupId}>
          <ActiveBoundaryComponent
            isHighlighted={item.boundaryGroupId === sidebarBTGHighlightedId}
            legendMode={legendMode}
            {...item}
          />
        </Fragment>
      ))}

    </>
  );
};
