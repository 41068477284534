import { css } from '@emotion/react';
import { useSpring } from '@react-spring/web';
import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import type { Theme } from '~/_shared/themes/theme.model';
import { delay } from '~/_shared/utils/delay';

const px = (px: number): string => `${px}px`;

const staticHighlightStyles = (theme: Theme) => css({
  position: 'relative',
  ':after': {
    position: 'absolute',
    content: '" "',
    inset: 0,
    boxShadow: `inset 0 0 var(--box-shadow-blur) var(--box-shadow-size) ${theme.borderColors.activeItem}`,
    pointerEvents: 'none',
  },
});

const inititalDynamicStyles = {
  '--box-shadow-size': '0px',
  '--box-shadow-blur': '0px',
} as const;

type HighlightAnimationOptions = {
  duration: number;
  pulseDuration: number;
  size: number;
  blur: number;
};

const defaultOptions: HighlightAnimationOptions = {
  duration: 900,
  pulseDuration: 500,
  size: 0,
  blur: 24,
};

export const useHighlightAnimation = ({
  duration = defaultOptions.duration,
  pulseDuration = defaultOptions.pulseDuration,
  size = defaultOptions.size,
  blur = defaultOptions.blur,
}: Partial<HighlightAnimationOptions> = {}) => {
  const [animateHighlight, setAnimateHighlight] = useState(false);
  const durationRef = useRef(duration);

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    if (animateHighlight) {
      const delayPromise = delay(durationRef.current).then(() => setAnimateHighlight(false));
      return () => {
        setAnimateHighlight(false);
        delayPromise.cancel();
      };
    }

    return undefined;
  }, [animateHighlight]);

  const startHighlightAnimation = useCallback(() => setAnimateHighlight(true), []);

  const dynamicStyles = useSpring({
    from: {
      ...inititalDynamicStyles,
      color: 'inherit',
    }, // The color here prevents type issues
    to: async (next) => {
      while (animateHighlight) {
        await next({
          '--box-shadow-size': px(size),
          '--box-shadow-blur': px(blur),
        });
        await next(inititalDynamicStyles);
      }
      await next(inititalDynamicStyles);
    },
    config: { duration: pulseDuration / 2 }, // Adjust the duration for speed control
  });

  return {
    staticHighlightStyles,
    startHighlightAnimation,
    dynamicStyles,
    isAnimationActive: animateHighlight,
  };
};
