import { css } from '@emotion/react';
import { type CSSInterpolation } from '@emotion/serialize';
import { AccordionHeaderSize } from '~/_shared/baseComponents/accordion/accordion.types';
import { type ThemeProps } from '../../types/themeProps';

export const ACCORDION_HEADER_SIZE_LARGE = 70;
export const ACCORDION_HEADER_SIZE_MEDIUM = 45;

export const accordionStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontFamily: '"Work Sans", sans-serif',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  width: '100%',
  '& .accordion__heading > *': {
    outline: 'none',
  },
});

const headerItemButtonSizeStyleMap = {
  [AccordionHeaderSize.Large]: {
    height: ACCORDION_HEADER_SIZE_LARGE,
    padding: '0 16px',
  },
  [AccordionHeaderSize.Medium]: {
    height: ACCORDION_HEADER_SIZE_MEDIUM,
    padding: '0 16px',
  },
  [AccordionHeaderSize.Flexible]: {
    minHeight: ACCORDION_HEADER_SIZE_MEDIUM,
    paddingBlock: 4,
    paddingInline: 16,
  },
} as const satisfies Readonly<Record<AccordionHeaderSize, CSSInterpolation>>;

export const getHeaderItemButtonStyle = (props: ThemeProps<{
  isLocked?: boolean;
  hiddenBorderBottom?: boolean;
  hiddenBorderTop?: boolean;
  isExpanded?: boolean;
  size: AccordionHeaderSize;
}>) => {
  const getBorder = (isHidden?: boolean) => {
    const color = props.isExpanded ? props.theme.accordionColors.headingBorderActive : props.theme.accordionColors.borderColor;
    return isHidden ? 'none' : `1px solid ${color}`;
  };

  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: props.isExpanded ? props.theme.accordionColors.headingBackgroundActive : props.theme.accordionColors.headingBackground,
    boxShadow: props.hiddenBorderTop && props.isExpanded ? `0px 1px 0px 0px inset ${props.theme.accordionColors.headingBorderActive}` : 'unset',
    borderTop: getBorder(props.hiddenBorderTop),
    borderBottom: getBorder(props.hiddenBorderBottom),
    cursor: 'default',
    ...headerItemButtonSizeStyleMap[props.size],
    '&:hover': {
      backgroundColor: props.isLocked ? undefined
        : props.isExpanded ? props.theme.accordionColors.headingBackgroundActiveHover
          : props.theme.accordionColors.headingBackgroundHover,
    },
  });
};

export const itemCountStyle = css({
  marginRight: 8,
});

export const caretStyle = ({ isToggled, isLocked }: { isToggled?: boolean; isLocked?: boolean }) => css({
  display: isLocked ? 'none' : undefined,
  fontWeight: 900,
  fontSize: '16px',
  lineHeight: '24px',
  transition: 'transform .2s',
  transform: isToggled ? undefined : 'rotate(180deg)',
});

export const panelStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.accordionColors.itemBackground,
  padding: '14px',
});

export const headerWrapperStyle = css({
  flex: 1,
  display: 'block',
  marginRight: 10,
});
