import {
  type FC, type MouseEvent, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type MapOutlinePositionInstance } from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type DrawingItemLabel } from '../../../../store/mapSettings/drawing/items/drawingItems.types';
import { MapTextInputComponent } from '../../mapObjects/mapTextInput/mapTextInput.component';
import { MapTextInputContainer } from '../../mapObjects/mapTextInput/mapTextInput.container';
import { type MapTextInputInstance } from '../../mapObjects/mapTextInput/mapTextInputModel';
import {
  getLabelOutlineSize,
  getLabelTextInputPadding,
} from './drawingToolLabel.helpers';

export type DrawingToolLabelInstanceLabelProps = Readonly<{
  instance: DrawingItemLabel;
  zIndex: MapObjectZIndex;
  fontSize: number;
  disabled?: boolean;
  selectionDisabled?: boolean;
  autoFocusOnInit?: boolean;
  etag?: string;

  onClick?: (event: MouseEvent) => void;
  onRightClick?: (event: MouseEvent) => void;
  onLabelMouseOut?: () => void;
  onLabelMouseOver?: () => void;
  renderOutline?: (outline: MapOutlinePositionInstance, outlineHeight: number) => ReactNode;
  onTextChange?: (newText: string) => void;
}>;

export const DrawingToolLabelInstanceLabelContainer: FC<DrawingToolLabelInstanceLabelProps> = props => {
  const { onLabelMouseOut, onLabelMouseOver, onClick, onRightClick, onTextChange } = props;
  const { instance, autoFocusOnInit, fontSize, disabled, selectionDisabled, zIndex, etag } = props;

  const renderLabel = useCallback((label: MapTextInputInstance) => {
    const outlineSize = getLabelOutlineSize(fontSize, instance.settings.dotSize);
    const padding = getLabelTextInputPadding(fontSize);

    return (
      <MapTextInputComponent
        instance={label}
        visuals={{
          background: instance.settings.backgroundColor,
          fontColor: instance.settings.fontColor,
          offsetX: outlineSize / 2 + padding,
          padding,
          borderRadius: 7,
        }}
        disabled={disabled}
        selectionDisabled={selectionDisabled}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseOut={onLabelMouseOut}
        onMouseOver={onLabelMouseOver}
        onTextChange={onTextChange}
        autoFocusOnInit={autoFocusOnInit}
        etag={etag}
      />
    );
  }, [fontSize, instance.settings.dotSize, instance.settings.backgroundColor, instance.settings.fontColor, disabled,
    selectionDisabled, onClick, onRightClick, onLabelMouseOut, onLabelMouseOver, onTextChange, autoFocusOnInit, etag]);

  const renderOutline = useMemo(() => {
    if (!props.renderOutline) {
      return undefined;
    }

    const renderOutlineCallback = props.renderOutline;

    // If dotSize is 0, outline is invisible. Otherwise compute render size.
    const outlineSize = instance.settings.dotSize && getLabelOutlineSize(fontSize, instance.settings.dotSize);

    return (outline: MapOutlinePositionInstance) => renderOutlineCallback(outline, outlineSize);
  }, [instance.settings.dotSize, fontSize, props.renderOutline]);

  const label = useMemo<MapTextInputInstance | null>(() => {
    return {
      id: instance.id,
      dotSize: instance.settings.dotSize,
      startPoint: instance.startPoint,
      text: instance.text,
      fontSize,
    };
  }, [instance.id, instance.startPoint, instance.text, fontSize, instance.settings.dotSize]);

  if (!label) {
    return null;
  }

  return (
    <MapTextInputContainer
      zIndex={zIndex}
      instance={label}
      renderTextInput={renderLabel}
      renderOutline={renderOutline}
    />
  );
};
