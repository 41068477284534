import { css } from '@emotion/react';
import {
  type FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
  useSections,
} from '~/_shared/baseComponents/accordion';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type MapListingItem } from '~/map/listing/item/mapListingItem';
import { type MapIntersect } from '../../map.repository';
import { LAYERED_MAP_MODAL_MAX_WIDTH } from '../layeredMap.constants';
import { LayeredMapNameAndDescriptionComponent } from '../layeredMapNameAndDescription.component';
import { MapConnectSettings } from '../mapConnectSettings';
import { ConnectivitySettingsSectionComponent } from './ConnectivitySettingsSection/connectivitySettingsSection.component';
import { MatchupColumnsSectionContainer } from './MatchupColumnsSection/matchupColumnsSection.container';
import {
  SelectMapAddOrRemoveOperation,
  type SelectMapsAddBaseMapProps,
} from './SelectMapsSection/selectMapsSection.component';
import { SelectMapsSectionContainer } from './SelectMapsSection/selectMapsSection.container';

const INITIAL_CONNECTIVITY_SETTINGS = MapConnectSettings.ConnectedInRealTime;

export enum LayeredMapModalSection {
  Name = 0,
  ConnectivitySettings = 1,
  MapsSelection = 2,
  MatchUp = 3,
}

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

const overlayAnimationStyle = css({
  position: 'fixed',
});

const columnsSelectHeadingStyle = css({
  margin: '0 0 8px',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.2,
});

export type CreateLayeredMapModalSettings = {
  connectionSetting: MapConnectSettings | null;
  mapDescription?: string;
  mapName: string;
  matches: MapIntersect[];
  selectedMapListItems: MapListingItem[];
};

type CreateLayeredMapModalComponentProps = Readonly<{
  isCreatingLayeredMap: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCreateLayeredMap: (settings: CreateLayeredMapModalSettings) => void;
}>;

export const CreateLayeredMapModalComponent: FC<CreateLayeredMapModalComponentProps> = memo((props) => {
  const { isOpen, onClose, isCreatingLayeredMap, onCreateLayeredMap } = props;
  const [mapName, setMapName] = useState('');
  const [mapDescription, setMapDescription] = useState('');

  const { toggleSection, setExpandedSection, isExpanded } = useSections<LayeredMapModalSection>([LayeredMapModalSection.Name]);

  const [mapNameInputRef, setMapNameInputRef] = useHookWithRefCallback<HTMLInputElement>();
  const [connectionSetting, setConnectionSetting] = useState<MapConnectSettings>(INITIAL_CONNECTIVITY_SETTINGS);
  const isMapOverviewContinueEnabled = !isTextEmpty(mapName);
  const [selectedMaps, setSelectedMaps] = useState<Array<MapListingItem | null>>([null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const layerAnimationColors = useLottieAnimationDefaultColors().Layer;

  const isSelectMapContinueDisabled = useMemo(() => {
    return selectedMaps.some((map) => map === null);
  }, [selectedMaps]);

  const onMapOverviewContinue = useCallback(() =>
    setExpandedSection(LayeredMapModalSection.ConnectivitySettings),
  [setExpandedSection]);

  const selectMapSelectionSection = useCallback(() => {
    if (connectionSetting) {
      setExpandedSection(LayeredMapModalSection.MapsSelection);
    }
  }, [connectionSetting, setExpandedSection]);

  const onMapSelected = useCallback((map: MapListingItem, mapIndex: number) => {
    setSelectedMaps(maps => [...maps.slice(0, mapIndex), map, ...maps.slice(mapIndex + 1)]);
  }, []);

  const onSelectMapSubmit = useCallback(() => {
    setExpandedSection(LayeredMapModalSection.MatchUp);
  }, [setExpandedSection]);

  useEffect(() => {
    if (isTextEmpty(mapName)) {
      setExpandedSection(LayeredMapModalSection.Name);
    }
  }, [mapName, setExpandedSection]);

  useEffect(() => {
    if (!isOpen) {
      setMapName('');
      setMapDescription('');
      setConnectionSetting(INITIAL_CONNECTIVITY_SETTINGS);
      setSelectedMaps([null, null]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && mapNameInputRef) {
      mapNameInputRef.focus();
    }
  }, [isOpen, mapNameInputRef]);

  const mapOverview: AccordionData = useMemo(() => ({
    header: t('Name Your Map'),
    isExpanded: isExpanded(LayeredMapModalSection.Name),
    isLocked: !isMapOverviewContinueEnabled,
    onHeadingClick: () => toggleSection(LayeredMapModalSection.Name),
    child: (
      <LayeredMapNameAndDescriptionComponent
        isContinueEnabled={isMapOverviewContinueEnabled}
        mapDescription={mapDescription}
        mapName={mapName}
        onContinue={onMapOverviewContinue}
        setMapDescription={setMapDescription}
        setMapName={setMapName}
        setMapNameInputRef={setMapNameInputRef}
      />
    ),
  }), [t, isExpanded, isMapOverviewContinueEnabled, mapName, mapDescription, setMapNameInputRef, onMapOverviewContinue, toggleSection]);

  const selectedMapListItems = useMemo(() => {
    const newMapListItems: MapListingItem[] = [];
    for (const mapItem of selectedMaps) {
      if (mapItem) {
        newMapListItems.push(mapItem);
      }
    }
    return newMapListItems;
  }, [selectedMaps]);

  const onCreateLayeredMapSubmit = useCallback((matches: MapIntersect[]) => {
    onCreateLayeredMap({ mapName, selectedMapListItems, connectionSetting, matches, mapDescription });
  }, [onCreateLayeredMap, selectedMapListItems, mapName, mapDescription, connectionSetting]);

  const accordionData: AccordionData[] = useMemo(() => [
    mapOverview,
    {
      header: t('Connectivity Settings'),
      isExpanded: isExpanded(LayeredMapModalSection.ConnectivitySettings),
      isLocked: !isMapOverviewContinueEnabled,
      onHeadingClick: () => toggleSection(LayeredMapModalSection.ConnectivitySettings),
      child: (
        <ConnectivitySettingsSectionComponent
          onChange={setConnectionSetting}
          selected={connectionSetting}
          onContinue={selectMapSelectionSection}
        />
      ),
    },
    {
      header: t('Select Maps to Layer'),
      isExpanded: isExpanded(LayeredMapModalSection.MapsSelection),
      isLocked: !connectionSetting,
      onHeadingClick: () => toggleSection(LayeredMapModalSection.MapsSelection),
      child: (
        <>
          {isExpanded(LayeredMapModalSection.MapsSelection) && (
            <SelectMapsSectionContainer
              onMapSelected={onMapSelected}
              selectedMaps={selectedMaps}
              selectMapAddOrRemove={ADD_BASE_MAP_PROPS}
              isDisabled={isSelectMapContinueDisabled}
              onSubmit={onSelectMapSubmit}
              onChangeAddedMaps={setSelectedMaps}
            />
          )}
        </>
      ),
    }, {
      header: t('Match-up Map Columns'),
      isExpanded: isExpanded(LayeredMapModalSection.MatchUp),
      onHeadingClick: () => toggleSection(LayeredMapModalSection.MatchUp),
      isLocked: isSelectMapContinueDisabled,
      child: (
        <>
          <p css={columnsSelectHeadingStyle}>
            {t('You\'re almost there! Simply match the corresponding columns and then decide on a name for your layered column. You can either use one of the original column names or come up with a brand-new one.')}
          </p>
          {isExpanded(LayeredMapModalSection.MatchUp) && (
            <MatchupColumnsSectionContainer
              maps={selectedMapListItems}
              mapName={mapName}
              onSubmit={onCreateLayeredMapSubmit}
              setIsLoading={setIsLoading}
              isSubmitting={isCreatingLayeredMap}
            />
          )}
        </>
      ),
    },
  ], [mapOverview, t, isExpanded, isMapOverviewContinueEnabled, connectionSetting, selectMapSelectionSection,
    onMapSelected, selectedMaps, isSelectMapContinueDisabled, onSelectMapSubmit, selectedMapListItems, mapName,
    onCreateLayeredMapSubmit, isCreatingLayeredMap, toggleSection]);

  return (
    <ModalComponent
      onClose={onClose}
      isOpen={isOpen}
      caption={t('Layer Multiple Maps')}
      contentStyle={css({ padding: 0 })}
      maxWidth={LAYERED_MAP_MODAL_MAX_WIDTH}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={accordionData}
          itemButtonStyle={itemButtonStyle}
          showCount
        />
      </OutlinePanelComponent>
      {(isLoading || isCreatingLayeredMap) && (
        <OverlayLottieAnimationComponent
          css={overlayAnimationStyle}
          type={LottieAnimationTypes.Layer}
          size={120}
          autoplay
          loop
          colors={layerAnimationColors}
        />
      )}
    </ModalComponent>
  );
});

const ADD_BASE_MAP_PROPS: SelectMapsAddBaseMapProps = {
  operation: SelectMapAddOrRemoveOperation.ADD,
};
