import {
  type FC, useCallback,
} from 'react';
import type { LatLng } from '~/_shared/types/latLng';
import type { MapObjectZIndex } from '../map/mapObjects/mapObject.types';
import { MapShape } from '../map/mapObjects/mapShape/mapShape.container';
import { MapShapeLine } from '../map/mapObjects/mapShape/mapShapeLine.component';
import type { MapShapeInstance } from '../map/mapObjects/mapShape/mapShapeModel';
import { boundaryDrawLineVisuals } from './lassoDraw.component';

type LassoPolygonProps = Readonly<{
  shape: MapShapeInstance;
  zIndex: MapObjectZIndex;
}>;

export const LassoPolygonComponent: FC<LassoPolygonProps> = (props) => {
  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => (
    <MapShapeLine
      key={id}
      id={id}
      start={start}
      end={end}
      visuals={boundaryDrawLineVisuals}
    />
  ), []);

  return (
    <MapShape
      renderLine={renderLine}
      shape={props.shape}
      zIndex={props.zIndex}
      isPolygon
    />
  );
};
