import { type TranslationFnc } from '~/_shared/utils/hooks';
import { standardBaseMapStructure } from './baseMap/standardBaseMap/standardBaseMap.structure';
import { type BaseMapStructureProps } from './baseMap/standardBaseMap/standardBaseMap.types';
import { mapMarkersGraphicsStructure } from './mapMarkersGraphics/mapMarkersGraphics.structure';
import { type MapMarkersGraphicsSettingsStructureProps } from './mapMarkersGraphics/mapMarkersGraphics.types';
import { otherSettingsStructure } from './otherSettings/otherSettings.structure';
import { type OtherSettingsStructureProps } from './otherSettings/otherSettings.types';
import { type SettingsAccordionSubItem } from './settingsAccordionData.type';
import { sharedPresentationalMapsStructure } from './sharedPresentationalMaps/sharedPresentationalMaps.structure';
import { type SharedPresentationalMapsStructureProps } from './sharedPresentationalMaps/sharedPresentationalMaps.types';

export const mapSettingsAccordionStructure = ({
  t,
  baseMapProps,
  mapMarkersGraphicsProps,
  sharedPresentationMapProps,
  otherSettingsProps,
  isLayeredMapConnected,
}: {
  t: TranslationFnc;
  baseMapProps: BaseMapStructureProps;
  mapMarkersGraphicsProps: MapMarkersGraphicsSettingsStructureProps;
  sharedPresentationMapProps: SharedPresentationalMapsStructureProps;
  otherSettingsProps: OtherSettingsStructureProps;
  isLayeredMapConnected: boolean;
}): ReadonlyArray<{
  title: string;
  subItems: ReadonlyArray<SettingsAccordionSubItem>;
}> => [
  {
    title: t('Map Markers / Graphics').toUpperCase(),
    subItems: mapMarkersGraphicsStructure(t, mapMarkersGraphicsProps, isLayeredMapConnected),
  },
  {
    title: t('Base Map').toUpperCase(),
    subItems: standardBaseMapStructure(t, baseMapProps),
  },
  {
    title: t('Shared/Display Maps').toUpperCase(),
    subItems: sharedPresentationalMapsStructure(t, sharedPresentationMapProps),
  },
  {
    title: t('Other Settings').toUpperCase(),
    subItems: otherSettingsStructure(t, otherSettingsProps),
  },
];
