import { useMemo } from 'react';
import { type MarkerEntitiesStyle } from '~/map/map/markers/useMarkers/useMarkerStyles.hook';
import {
  markerSubEntityCount, markerSubEntityZIndexOffsets, type MarkerZIndex,
} from '~/map/zIndexes/markerZIndex';
import { useActiveMarkerIdsSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { type SpreadsheetRowId } from '../../_shared/types/spreadsheetData/spreadsheetRow';
import { logError } from '../../_shared/utils/logError';
import {
  type ReadonlySpreadsheetRowIdMap,
  SpreadsheetRowIdMap,
} from '../../_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { type LatLngRowData } from '../../store/spreadsheetData/spreadsheetData.helpers';
import {
  calculateZIndex,
  ZIndexedEntity,
} from './zIndexRanges';

const comparer = (marker1: LatLngRowData, marker2: LatLngRowData): number =>
  marker2.lat - marker1.lat;

const calculateEntityOffset = (markerIndex: number, markerEntityIndex: number) => {
  if (markerEntityIndex >= markerSubEntityCount) {
    logError(`calculateEntityOffset: received markerEntityIndex ${markerEntityIndex} is higher than the count of marker entities of ${markerSubEntityCount}. Check what numbers are passed in.`);
  }

  return (markerIndex * markerSubEntityCount) + markerEntityIndex;
};

const createMarkerSubEntityZIndexCalculator = (markerIndex: number, isActive: boolean) =>
  (subEntityOffset: number) => {
    if (isActive) {
      return calculateZIndex(subEntityOffset, ZIndexedEntity.ActiveMarker);
    }
    return calculateZIndex(calculateEntityOffset(markerIndex, subEntityOffset), ZIndexedEntity.Marker);
  };

export const useSpreadsheetMarkerZIndexes = (markers: ReadonlyArray<LatLngRowData>, _markerStyles: ReadonlySpreadsheetRowIdMap<MarkerEntitiesStyle>) => {
  const activeMarkerIds = useActiveMarkerIdsSelector();
  return useMemo(() => {
    const zIndexEntries = Array.from(markers)
      .sort(comparer)
      .map((marker, markerIndex): readonly [SpreadsheetRowId, MarkerZIndex] => {
        const isActive = activeMarkerIds?.some(activeMarkerId =>
          (marker.spreadsheetId === activeMarkerId?.spreadsheetId) && (marker.rowId === activeMarkerId?.rowId),
        ) ?? false;
        const calculateSubEntityZIndex = createMarkerSubEntityZIndexCalculator(markerIndex, isActive);
        return [marker, {
          shadow: calculateZIndex(markerIndex, ZIndexedEntity.MarkerShadow),
          highlight: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.highlight),
          subMarker: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.subMarker),
          marker: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.marker),
          markerLabelText: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.markerLabelText),
          labelAbove: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.labelAbove),
          labelAboveText: calculateSubEntityZIndex(markerSubEntityZIndexOffsets.labelAboveText),
        }] as const;
      });

    return new SpreadsheetRowIdMap(zIndexEntries).asReadonly();
  }, [activeMarkerIds, markers]);
};
