import { css } from '@emotion/react';
import { type PropsWithChildren } from 'react';

const headerWrapperStyles = css({ display: 'contents' });

type TableHeaderContentProps = PropsWithChildren;

export const TableHeaderComponent = ({ children }: TableHeaderContentProps) => {
  return (
    <div
      css={headerWrapperStyles}
    >
      {children}
    </div>
  );
};
