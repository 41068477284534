import { type FC } from 'react';
import { MARKER_DEFAULT_COLOR } from '~/_shared/utils/markers/markerVisualSettings.constants';
import { type QuadrilateralDimensions } from '../../types/coordinateSystem/coordinateSystem';
import {
  type CustomMarkerSettings, MarkerStyleType, type MarkerVisualSettingsColor, type StandardMarkerSettings,
} from '../../types/marker.types';
import { CustomMarkerComponent } from './customMarker/customMarker.component';
import { StandardMarkerComponent } from './standardMarker/standardMarker.component';

export type MarkerProps = Partial<MarkerVisualSettingsColor> & Readonly<{
  className?: string;
  marker: CustomMarkerSettings | StandardMarkerSettings;
  markerType: MarkerStyleType;
  maxDimensions: QuadrilateralDimensions;
  maxSize?: number;
  maxSizeEasing?: boolean;
  size?: number;

  onDelete?: () => void;
}>;

export const MarkerComponent: FC<MarkerProps> = props => (
  props.markerType === MarkerStyleType.CUSTOM
    ? (
      <CustomMarkerComponent
        className={props.className}
        marker={props.marker as CustomMarkerSettings}
        maxDimensions={props.maxDimensions}
        maxSize={props.maxSize}
        maxSizeEasing={props.maxSizeEasing}
        size={props.size}
        onDelete={props.onDelete}
      />
    )
    : (
      <StandardMarkerComponent
        className={props.className}
        marker={props.marker as StandardMarkerSettings}
        maxDimensions={props.maxDimensions}
        maxSize={props.maxSize}
        maxSizeEasing={props.maxSizeEasing}
        opacity={props.opacity}
        selectedColor={props.selectedColor || MARKER_DEFAULT_COLOR}
        size={props.size}
      />
    )
);
