import { type TranslationFnc } from '~/_shared/utils/hooks';
import { isNullOrUndefined } from '~/_shared/utils/typeGuards';
import { GeoUnits } from '~/store/boundaries/boundaries.repository';

export enum BuildingBlock {
  CONTINENTAL_US_STATES = 'CONTINENTAL_US_STATES',
  CONTINENTAL_US_ZIP_CODES = 'CONTINENTAL_US_ZIP_CODES',
  US_STATES = 'US_STATES',
  US_ZIP_CODES = 'US_ZIP_CODES',
}

export const supportedBuildingBlocks = [
  BuildingBlock.CONTINENTAL_US_STATES,
  BuildingBlock.CONTINENTAL_US_ZIP_CODES,
  BuildingBlock.US_ZIP_CODES,
] as const;

export const getBuildingBlockLabel = (block: BuildingBlock, t: TranslationFnc) => {
  switch (block) {
    case BuildingBlock.CONTINENTAL_US_STATES:
      return t('US States');
    case BuildingBlock.CONTINENTAL_US_ZIP_CODES:
      return t('US Zip Codes');
    case BuildingBlock.US_STATES:
      return t('US States with Alaska and Hawaii');
    case BuildingBlock.US_ZIP_CODES:
      return t('US Zip Codes with Alaska and Hawaii');
    default:
      throw new Error('Invalid Building Block');
  }
};

const BuildingBlockToGeoUnitMap = {
  [BuildingBlock.CONTINENTAL_US_STATES]: GeoUnits.US_STATES,
  [BuildingBlock.CONTINENTAL_US_ZIP_CODES]: GeoUnits.US_ZIP_CODES,
  [BuildingBlock.US_STATES]: GeoUnits.US_STATES,
  [BuildingBlock.US_ZIP_CODES]: GeoUnits.US_ZIP_CODES,
} as const;

export const buildingBlockToGeoUnit = (block: BuildingBlock) => BuildingBlockToGeoUnitMap[block];

export const getAllStatesFlagForBluildingBlock = (block: BuildingBlock) => {
  switch (block) {
    case BuildingBlock.US_STATES:
    case BuildingBlock.US_ZIP_CODES:
      return true;
    default:
      return false;
  }
};

export const buildingBlockFromGeoUnits = (geoUnit: GeoUnits | null, allStates: boolean) => {
  if (isNullOrUndefined(geoUnit)) {
    return null;
  }

  if (allStates) {
    switch (geoUnit) {
      case GeoUnits.US_ZIP_CODES:
        return BuildingBlock.US_ZIP_CODES;
      default:
        return BuildingBlock.US_STATES;
    }
  }
  else {
    switch (geoUnit) {
      case GeoUnits.US_ZIP_CODES:
        return BuildingBlock.CONTINENTAL_US_ZIP_CODES;
      default:
        return BuildingBlock.CONTINENTAL_US_STATES;
    }
  }
};
