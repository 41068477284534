import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { removeHash } from '../_shared/components/colorPicker/colorPicker.helpers';
import {
  HeatMapSample, HeatMapStyle, HeatMapType,
} from '../_shared/types/heatmap/heatMap.enum';
import { createUuid } from '../_shared/utils/createUuid';
import { type HeatMapPanelSubmitItem } from './panel/form/heatMapPanelForm.component';

export const createHeatMapFromForm = (item: HeatMapPanelSubmitItem, denseColor: string, mediumColor: string, lightColor: string, singleColor: string): HeatMap => {
  const id = createUuid();
  const commonProperties = {
    id,
    dissipate: true,
    unlinkFromOtherTools: false,
    isGradient: true,
    radius: 50,
    opacity: 50,
    threshold: 50,
    denseColor: removeHash(denseColor),
    mediumColor: removeHash(mediumColor),
    lightColor: removeHash(lightColor),
    singleColor: removeHash(singleColor),
  } as const;

  if (item.sample === HeatMapSample.SpecificGroup) {
    const groupCommon = {
      selectedGroupId: item.selectedGroupId || '',
      selectedGroupName: item.selectedGroupName || '',
      selectedGroupColumn: item.selectedGroupColumn,
      showMarkersForGroup: false,
    } as const;

    if (item.style === HeatMapStyle.MarkerDensity) {
      return {
        ...commonProperties,
        ...groupCommon,
        type: HeatMapType.GroupDensity,
        sample: HeatMapSample.SpecificGroup,
        style: HeatMapStyle.MarkerDensity,
      };
    }
    else {
      return {
        ...commonProperties,
        ...groupCommon,
        type: HeatMapType.GroupNumericalData,
        sample: HeatMapSample.SpecificGroup,
        style: HeatMapStyle.NumericalData,
        numericalColumnId: item.selectedNumericColumnId,
      };
    }
  }
  else {
    if (item.style === HeatMapStyle.MarkerDensity) {
      return {
        ...commonProperties,
        type: HeatMapType.AllMarkersDensity,
        sample: HeatMapSample.AllMarkers,
        style: HeatMapStyle.MarkerDensity,
      };
    }
    else {
      return {
        ...commonProperties,
        type: HeatMapType.AllMarkersNumericalData,
        sample: HeatMapSample.AllMarkers,
        style: HeatMapStyle.NumericalData,
        numericalColumnId: item.selectedNumericColumnId,
      };
    }
  }
};
