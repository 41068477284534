import { copy } from '~/_shared/utils/collections/collections';
import { type Uuid } from '~/_shared/utils/createUuid';
import { type MetricModel } from '~/_shared/utils/metric/metrics.factory';
import { type BoundaryEtag } from '~/boundary/etag/etag.types';
import { type BoundaryFillType } from '~/boundary/settings/boundaryFill.type';
import { type BoundaryId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  type BoundaryTerritoryType, type CalculateBucketFunction,
} from './boundaryTerritoryGroup.type';
import {
  type BoundaryBucketType, type BoundaryMatchingCategory, type BoundaryMatchingType,
} from './boundaryTerritoryGroups.repository';

export type BoundaryTerritoryStyle = Readonly<{
  color: string;
  opacity: number;
}>;

type BoundaryTerritoryCommon = Readonly<{
  boundaryTerritoryId: string;
  displayName: string;
  translate: boolean;
  style: BoundaryTerritoryStyle;
}>;

export type GeneratedBoundaryTerritory = BoundaryTerritoryCommon & Readonly<{
  custom: false;
  bucket: string;
}>;

export type CustomBoundaryTerritory = BoundaryTerritoryCommon & Readonly<{
  custom: true;
}>;

export type BoundaryTerritory = GeneratedBoundaryTerritory | CustomBoundaryTerritory;

export type BoundaryTerritoryGroupStyle = Readonly<{
  lineWidth: number;
  lineColor: string;
  gradientColors: {
    low: {
      color: string;
    };
    medium: {
      color: string;
    };
    high: {
      color: string;
    };
  };
}>;

export type BoundaryTerritoryGroupSettings = Readonly<{
  allowDecimalRanges: boolean;
  boundariesAsTerritories: boolean;
  boundaryFillType: BoundaryFillType;
  boundaryTerritories: ReadonlyArray<BoundaryTerritory>;
  boundaryTerritoryType: BoundaryTerritoryType;
  bucketColumnId: string | null;
  bucketType: BoundaryBucketType | null;
  calculateBucketFunction: CalculateBucketFunction | null;
  demographicId: string | null;
  displayName: null | string;
  emptyIsZero: null;
  metrics: ReadonlyArray<MetricModel>;
  rangesCount: number | null;
  style: BoundaryTerritoryGroupStyle;
  boundaryStyle: ReadonlyMap<BoundaryId, BoundaryTerritoryStyle>;
  hideLabels: boolean;
  hideWithoutData: boolean;
  ignoreFilters: boolean;
  selectionEditPreferredBoundaryGroup?: { id: number };
}>;

export type BoundaryTerritoryGroupMatchColumn = Readonly<{
  columnId: string;
  category: BoundaryMatchingCategory;
}>;

export type BoundaryTerritoryGroupMatchings = Readonly<{
  matchingType: BoundaryMatchingType;
  matchColumns: ReadonlyArray<BoundaryTerritoryGroupMatchColumn>;
}>;

export type BoundaryTerritoryGroupModel = Readonly<{
  boundaryTerritoryGroupId?: number;
  boundaryGroupId: number;
  mapId: number;
  matchings: BoundaryTerritoryGroupMatchings;
  settings: BoundaryTerritoryGroupSettings;
  archivedAt: string | null;
  etag?: BoundaryEtag;
}>;

export type BoundaryTerritoryGroup = BoundaryTerritoryGroupModel & Readonly<{
  boundaryTerritoryGroupId: number;
}>;

export type BoundaryTerritoryGroupsState = Readonly<{
  groups: ReadonlyArray<BoundaryTerritoryGroup>;
  isFetchLoading: boolean;
  isActionLoading: boolean;
  isError: boolean;
}>;

const updateBoundaryTerritories = (
  boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>,
  boundaryTerritoryGroupId: number,
  update: (territories: ReadonlyArray<BoundaryTerritory>
  ) => ReadonlyArray<BoundaryTerritory>) => {
  return boundaryTerritoryGroups.map(group => {
    if (group.boundaryTerritoryGroupId !== boundaryTerritoryGroupId) {
      return group;
    }

    const settings = group.settings;
    const updatedBoundaryTerritories = update(settings.boundaryTerritories);
    const updatedSettings = { ...settings, boundaryTerritories: updatedBoundaryTerritories };
    return { ...group, settings: updatedSettings };
  });
};

const createCustomBoundaryTerritory = (
  id: Uuid, name: string, fillColor: string, fillOpacity: number
): CustomBoundaryTerritory => ({
  boundaryTerritoryId: id,
  displayName: name,
  translate: false,
  custom: true,
  style: {
    color: fillColor,
    opacity: fillOpacity,
  },
});

export function addNewCustomBoundaryTerritory(
  state: BoundaryTerritoryGroupsState, boundaryTerritoryGroupId: number, newTerritoryId: Uuid,
  name: string, fillColor: string, fillOpacity: number,
): BoundaryTerritoryGroupsState {
  const boundaryTerritory = createCustomBoundaryTerritory(newTerritoryId, name, fillColor, fillOpacity);

  const addNewCustomBoundaryTerritory = (territories: ReadonlyArray<BoundaryTerritory>) => {
    return [...territories, boundaryTerritory];
  };

  return {
    ...state,
    groups: updateBoundaryTerritories(state.groups, boundaryTerritoryGroupId, addNewCustomBoundaryTerritory),
  };
}

export function updateBoundaryTerritoryName(
  state: BoundaryTerritoryGroupsState, newName: string, territoryId: string, boundaryTerritoryGroupId: number
): BoundaryTerritoryGroupsState {
  const updateBoundaryTerritoryName = (boundaryTerritories: ReadonlyArray<BoundaryTerritory>): ReadonlyArray<BoundaryTerritory> => {
    const index = boundaryTerritories.findIndex(territory => territory.boundaryTerritoryId === territoryId);
    const boundaryTerritory = boundaryTerritories[index];
    if (!boundaryTerritory) {
      return boundaryTerritories;
    }
    const updatedBoundaryTerritory: BoundaryTerritory = { ...boundaryTerritory, displayName: newName, translate: false };

    const updatedBoundaryTerritories = copy.andReplace(boundaryTerritories, index, updatedBoundaryTerritory);

    return updatedBoundaryTerritories;
  };

  return {
    ...state, groups: updateBoundaryTerritories(state.groups, boundaryTerritoryGroupId, updateBoundaryTerritoryName),
  };
}

export function deleteBoundaryTerritory(
  state: BoundaryTerritoryGroupsState, boundaryTerritoryId: string, boundaryTerritoryGroupId: number
): BoundaryTerritoryGroupsState {
  const updatedBoundaryTerritories = updateBoundaryTerritories(state.groups, boundaryTerritoryGroupId, (boundaryTerritories) => {
    const deletedBoundaryTerritory = boundaryTerritories.find(territory => territory.boundaryTerritoryId === boundaryTerritoryId);
    if (!deletedBoundaryTerritory) {
      return boundaryTerritories;
    }

    return copy.andRemove(boundaryTerritories, [deletedBoundaryTerritory]);
  });

  return {
    ...state, groups: updatedBoundaryTerritories,
  };
}
