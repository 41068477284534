import { css } from '@emotion/react';
import {
  type FC,
  useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ShareMapProps } from '~/map/share/shareMap.container';
import { type MapListingItem } from './item/mapListingItem';
import { MobileTableDataComponent } from './mobileTableData/mobileTableData.component';

const containerStyle = css({
  '&:before': {
    content: '""',
    clear: 'both',
    display: 'table',
  },
});

const originalTableWrapperStyle = ({ theme, marginTop }: ThemeProps<{ marginTop: number }>) => css({
  marginTop,
  overflow: 'hidden',
  boxShadow: `0 2px 5px ${theme.shadowColors.secondary}`,
});

const noResultsStyle = ({ theme }: ThemeProps) => css({
  margin: 0,
  padding: '10px 0',
  textAlign: 'center',
  background: theme.backgroundColors.primary,
});

const alertWrapperStyle = css({
  margin: 10,
});

const spinnerStyle = css({
  position: 'fixed',
});

type MobileMapListingProps = Readonly<{
  errorMessage: string | null;
  isMapListingDataLoading: boolean;
  pagination: Pagination | null;
  processedItems: ReadonlyArray<MapListingItem>;
  selectedMapIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  toggledMapIds: ReadonlyArray<number>;

  onMapShareSettingsClick: (props: ShareMapProps) => void;
  onPageChange: (page: number) => void;
  toggleMapSelection: (mapId: number) => void;
  toggleSnapshotSelection: (params: Readonly<{ mapId: number; snapshotId: number }>) => void;
  toggleSnapshotsVisibility: (mapId: number) => void;
}>;

export const MobileMapListingComponent: FC<MobileMapListingProps> = (props) => {
  const [sortAndFiltersHeight] = useState(0);
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <ScrollBarComponent type={ScrollbarType.Vertical} >
      <div css={containerStyle} >
        <div css={originalTableWrapperStyle({ marginTop: sortAndFiltersHeight, theme })}>
          <OverlayWrapperComponent>
            <MobileTableDataComponent
              data={props.processedItems}
              onShowSnapshotsToggle={props.toggleSnapshotsVisibility}
              onSnapshotSelectToggle={props.toggleSnapshotSelection}
              selectedMapsIds={props.selectedMapIds}
              toggledMapIds={props.toggledMapIds}
              selectedSnapshotIds={props.selectedSnapshotIds}
              pagination={props.pagination}
              onPageChange={props.onPageChange}
              isMapListingDataLoading={props.isMapListingDataLoading}
              onMapShareSettingsClick={props.onMapShareSettingsClick}
            />

            {!props.isMapListingDataLoading && props.processedItems.length === 0 &&
              <p css={noResultsStyle({ theme })}>{t('No results')}</p>
            }

            {props.errorMessage && (
              <div css={alertWrapperStyle}>
                <AlertComponent type="danger">{props.errorMessage}</AlertComponent>
              </div>
            )}

            {props.isMapListingDataLoading &&
              <OverlayLoaderComponent spinnerStyle={props.processedItems.length >= 10 ? spinnerStyle : undefined} />
            }
          </OverlayWrapperComponent>
        </div>
      </div>
    </ScrollBarComponent>
  );
};
