import { type PerColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { SERVER_ADDED_COLUMN_NAMES } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { type MatchupDataState } from '~/store/matchupData/matchupData.state';

export type EditDataTableRowWithColumnId = {
  label: string;
  value: string;
  columnId: string;
};

export const getEditDataTableRowsWithColumnIdsFromMatchup = (
  matchupData: MatchupDataState,
  spreadsheetId: number | null,
  spreadsheetRowData?: PerColumn<string | null>,
): EditDataTableRowWithColumnId[] => {
  if (!matchupData || Object.keys(matchupData).length === 0 || !spreadsheetId) {
    return [];
  }

  const data = matchupData[spreadsheetId]?.data;
  if (!data) {
    return [];
  }

  return Object.entries(data.columns)
    .filter((column): column is [string, string] => !!column[0] && !!column[1])
    .filter(([columnId, label]) => columnId !== BASE_MAPS_COLUMN_ID && !SERVER_ADDED_COLUMN_NAMES.has(label))
    .map(([columnId, label]) => ({
      label,
      value: spreadsheetRowData?.[columnId] ?? '',
      columnId,
    }));
};
