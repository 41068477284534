import {
  type FC, type ReactNode,
} from 'react';
import type { MemberRole } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { useUserDataSelector } from '../../store/userData/userData.selectors';

export type RoleBlockerProps = {
  allowRoles: MemberRole[];
  children: ReactNode;
};

export const RoleBlocker: FC<RoleBlockerProps> = ({ allowRoles, children }) => {
  const userData = useUserDataSelector();
  const memberRole = userData.clientRole;

  const isAllowed = allowRoles.includes(memberRole);

  if (isAllowed) {
    return <>{children}</>;
  }
  else {
    return (<></>);
  }
};
