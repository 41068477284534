import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import type { SpreadsheetRow } from '~/_shared/components/spreadsheet/spreadsheet.types';
import type { CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { spreadsheetTableSetSelectedRows } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.actionCreators';
import {
  useIsRowsRemoveInProgressSelector, useSpreadsheetTableSelectedRowsSelector,
} from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import type { SpreadsheetSelectedRow } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.state';

type RowIdToSpreadsheetSelectedRows = { readonly [rowId: CombinedRowId]: SpreadsheetSelectedRow };

type SelectDataTableRowsProps = Readonly<{
  data: SpreadsheetRow[];
  isDataLoading: boolean;
}>;

export const useSelectDataTableRows = ({ data, isDataLoading }: SelectDataTableRowsProps) => {
  const dispatch = useDispatch();
  const selectedRows = useSpreadsheetTableSelectedRowsSelector();
  const isRowsRemoveInProgress = useIsRowsRemoveInProgressSelector();

  const setSelectedRows = useCallback((newSelectedRows: RowIdToSpreadsheetSelectedRows) => {
    dispatch(spreadsheetTableSetSelectedRows(newSelectedRows));
  }, [dispatch]);

  const onSelectedRowsChange = useCallback(((newSelections: Record<string, boolean>) => {
    const newSelectedRows: Record<CombinedRowId, SpreadsheetSelectedRow> = {};

    for (const row of data) {
      if (newSelections[row.rowId]) {
        newSelectedRows[row.rowId] = {
          virtualSpreadsheetId: row.virtualSpreadsheetId,
          rowId: row.rowId,
          value: true,
        };
      }
    }

    setSelectedRows?.(newSelectedRows);
  }), [data, setSelectedRows]);

  const mappedSelectedRows = useMemo<Record<CombinedRowId, true>>(() => (
    Object.fromEntries(Object.keys(selectedRows || {}).map(rowId => ([rowId, true])))
  ), [selectedRows]);

  useEffect(() => {
    setSelectedRows?.({});
  }, [isDataLoading, setSelectedRows]);

  return useMemo(() => ({
    setSelectedRows,
    isLoading: isRowsRemoveInProgress,
    selectedRows: mappedSelectedRows,
    onSelectedRowsChange,
  }), [isRowsRemoveInProgress, mappedSelectedRows, onSelectedRowsChange, setSelectedRows]);
};
