import { css } from '@emotion/react';
import {
  faEdit,
  faEllipsisV, faExternalLink, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { useTranslation } from '../../../../../../_shared/utils/hooks';

type BoundaryTerritoryContextMenuProps = Readonly<{
  onBatchEditLocationsClick?: () => void;
  onDeleteClick?: () => void;
  onExportDataClick?: () => void;
  onExportContainedBoundariesClick?: () => void;
}>;

const toolbarButtonStyle = css({
  marginLeft: 6,
});

const triggerButtonStyle = ({ isMenuOpen }: { isMenuOpen: boolean }) =>
  isMenuOpen ? RoundButtonStyle.Quaternary : RoundButtonStyle.Secondary;

export const BoundaryTerritoryContextMenuComponent: FC<BoundaryTerritoryContextMenuProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [t] = useTranslation();

  return (
    <ContextMenuComponent
      isVisible={isMenuOpen}
      onHide={() => setIsMenuOpen(false)}
      maxWidth={280}
      renderMenuContent={() => (
        <>
          {props.onExportDataClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faExternalLink}
                onClick={props.onExportDataClick}
                size="s"
              >
                {t('Export Data in Group')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onExportContainedBoundariesClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faExternalLink}
                onClick={props.onExportContainedBoundariesClick}
                size="s"
              >
                {t('Export Contained Boundaries')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onBatchEditLocationsClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faEdit}
                onClick={props.onBatchEditLocationsClick}
                size="s"
              >
                {t('Batch Edit Locations')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onDeleteClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faTrash}
                onClick={props.onDeleteClick}
                size="s"
                isDestructive
              >
                {t('Delete Fill Group')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}
        </>
      )}
    >

      <div css={toolbarButtonStyle}>
        <RoundButtonComponent
          icon={faEllipsisV}
          onClick={() => setIsMenuOpen(true)}
          size={RoundButtonSize.Small}
          buttonStyle={triggerButtonStyle({ isMenuOpen })}
        />
      </div>
    </ContextMenuComponent>
  );
};
