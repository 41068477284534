import { css } from '@emotion/react';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { UnderlinedLinkComponent } from '~/_shared/baseComponents/links';
import { EmptyStateComponent } from '~/_shared/components/emptyState/emptyState.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import type { MapListingItem } from '~/map/listing/item/mapListingItem';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useMapListSearchQuerySelector } from '~/store/frontendState/mapListing/mapListing.selectors';
import { openModal } from '~/store/modal/modal.actionCreators';

const centerStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const wrapperStyle = (isCompact: boolean) => css(centerStyle, {
  gap: 18,
  padding: `${isCompact ? 18 : 38}px 0`,
});

const linkStyle = (theme: Theme) => css(centerStyle, {
  color: theme.textColors.success,
  cursor: 'default',

  '&:hover': {
    color: theme.textColors.successOnHover,
  },
});

type MapListingEmptyStateProps = Readonly<{
  maps: ReadonlyArray< MapListingItem>;
}>;

export const MapListingEmptyStateComponent: FC<MapListingEmptyStateProps> = ({ maps }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { openModal: openWelcomeModal } = useModal(ModalType.Welcome);
  const isSearchActive = !!useMapListSearchQuerySelector();

  const onlySampleMaps = useMemo((): boolean => {
    return !maps.some(map => !map.isSample);
  }, [maps]);

  const handleCreateNewMap = useCallback(() =>
    dispatch(openModal(ModalType.CreateNewMap, {})),
  [dispatch]);

  const showQuickStartLink = onlySampleMaps && !isSearchActive;
  const showEmptyState = !maps.length;

  if (!showEmptyState && !showQuickStartLink) {
    return null;
  }

  return (
    <div css={wrapperStyle(showQuickStartLink && !showEmptyState)}>
      {showEmptyState && (
        <EmptyStateComponent
          icon={faMap}
          title={isSearchActive ? t('mapListing.emptyStateTitle.withFilter') : t('mapListing.emptyStateTitle')}
          description={isSearchActive ? t('Refine your search and try again') : t('mapListing.emptyStateDescription')}
        />
      )}
      {showEmptyState && !isSearchActive && (
        <ButtonComponent
          buttonStyle={ButtonStyle.Success}
          onClick={handleCreateNewMap}
          text={t('Create My First Map')}
        />
      )}
      {showQuickStartLink && (
        <UnderlinedLinkComponent
          css={linkStyle}
          onClick={openWelcomeModal}
        >
          {t('quickStartLink')}
        </UnderlinedLinkComponent>
      )}
    </div>
  );
};
