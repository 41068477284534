import { useMemo } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { mapSettingsConcurrencyIsOfflineSelector } from '~/store/mapSettings/concurrency/mapSettingsConcurrency.selectors';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { type AppState } from '../app.store';
import { usePrimarySpreadsheetId } from './usePrimarySpreadsheetId';

export const isMapPresentationalSelector = (state: AppState) => state.map.mapInfo.isPresentational;
export const useIsMapPresentationalSelector = () => useSelector(isMapPresentationalSelector);

const googleSheetsSpreadsheetsInfo = (state: AppState) => state.map.mapInfo.data?.googleSheets;
export const useGoogleSheetsSpreadsheetsInfo = () => useSelector(googleSheetsSpreadsheetsInfo);

export const isMapInOfflineModeSelector = (state: AppState) =>
  isMapPresentationalSelector(state) || mapSettingsConcurrencyIsOfflineSelector(state) || testingModeEnabled();

export const usePrimaryGoogleSpreadsheetInfo = () => {
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const googleSheetsSpreadsheetsInfo = useGoogleSheetsSpreadsheetsInfo();

  return useMemo(() => {
    if (googleSheetsSpreadsheetsInfo && primarySpreadsheetId) {
      const primaryGoogleSheetSpreadsheet = googleSheetsSpreadsheetsInfo.find(
        i => i.spreadsheetId === primarySpreadsheetId
      );
      if (primaryGoogleSheetSpreadsheet && primaryGoogleSheetSpreadsheet.realSpreadsheets.length) {
        return primaryGoogleSheetSpreadsheet.realSpreadsheets[0];
      }
    }
    return null;
  }, [googleSheetsSpreadsheetsInfo, primarySpreadsheetId]);
};
