import { css } from '@emotion/react';
import { type FC } from 'react';

type DropdownLabelProps = Readonly<{
  className?: string;
  children?: React.ReactNode;
}>;

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
  textTransform: 'uppercase',
});

export const DropdownLabelComponent: FC<DropdownLabelProps> = (props) => {
  return (
    <div
      css={labelStyle}
      className={props.className}
    >
      {props.children}
    </div>
  );
};
