import { css } from '@emotion/react';
import {
  type ChangeEventHandler,
  type FC, type KeyboardEvent, type MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import { SpreadsheetDataEditorDisabledComponent } from '~/_shared/components/spreadsheet/dataEditor/spreadsheetDataEditorDisabled.component';
import type { GridElement } from '~/_shared/components/spreadsheet/spreadsheet.types';
import { SpreadsheetContext } from '~/_shared/components/spreadsheet/spreadsheetContext';
import { type Theme } from '~/_shared/themes/theme.model';
import { useHookWithRefCallback } from '../../../utils/hooks/useHookWithRefCallback';
import { FixedStickyContainerComponent } from '../../fixedStickyContainer/fixedStickyContainer.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../scrollbar/scrollbar.component';
import DataEditorProps = ReactDataSheet.DataEditorProps;

const stopPropagation: MouseEventHandler = (e) => {
  e.stopPropagation();
};

const preventClosingTextarea: MouseEventHandler = (e) => {
  // required to stop immediate close of the textarea on user click in the textarea
  e.nativeEvent.stopImmediatePropagation();
  e.stopPropagation();
  return false;
};

const textareaStyle = (theme: Theme) => css({
  resize: 'none',
  width: '100%',
  border: `1px solid ${theme.textColors.link}`,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  height: 'auto',
});

const spreadsheetDataEditorStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});

export const SpreadsheetDataEditorComponent: FC<DataEditorProps<GridElement>> = ({
  cell,
  col,
  onChange,
  onCommit,
  onKeyDown,
  onRevert,
  row,
  value,
}) => {
  const {
    data,
    getSpreadsheetCellDisabledMessage,
    isColumnResizing,
    onCellsChanged,
  } = useContext(SpreadsheetContext);
  const ensuredStringValue = value?.toString() ?? '';
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const renderCounterRef = useRef(0);
  const initialValueRef = useRef(ensuredStringValue);
  const [localValue, setLocalValue] = useState(ensuredStringValue);
  const [hasPastFirstRender, setHasPastFirstRender] = useState(false);
  const [triggerRef, setTriggerRef] = useHookWithRefCallback<HTMLDivElement>();
  const rowData = data[row];

  if (renderCounterRef.current === 1) {
    // This is to bypass a react-datasheet bug. The first render is always with the wrong value.
    initialValueRef.current = ensuredStringValue;
    setLocalValue(ensuredStringValue);
  }

  renderCounterRef.current++;

  const errorMessage = useMemo(() => (
    rowData ? getSpreadsheetCellDisabledMessage?.(
      { rowId: rowData.rowId, spreadsheetId: rowData.virtualSpreadsheetId },
      rowData.values?.[col]?.columnId,
    ) : null
  ), [col, getSpreadsheetCellDisabledMessage, rowData]);

  const revertChangeRef = useRef(onRevert);
  revertChangeRef.current = onRevert;

  const closeEditorRef = useRef(() => {
    if (hasPastFirstRender) {
      onCommit(localValue);
    }
    else {
      revertChangeRef.current();
    }
  });
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    setHasPastFirstRender(true);
  }, []);

  useEffect(() => {
    if (hasPastFirstRender) {
      const textAreaElement = textAreaRef.current;
      if (textAreaElement) {
        textAreaElement.style.height = `${textAreaElement.scrollHeight}px`;
      }
    }
  }, [hasPastFirstRender, localValue]);

  useEffect(() => {
    if (isColumnResizing) {
      revertChangeRef.current();
    }
  }, [isColumnResizing]);

  useEffect(() => {
    const textAreaElement = textAreaRef.current;
    if (hasPastFirstRender && textAreaElement) {
      textAreaElement.focus();
      textAreaElement.setSelectionRange(textAreaElement.value.length, textAreaElement.value.length);
    }
  }, [hasPastFirstRender]);

  const valueChangeHandler = useCallback<ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    setLocalValue(event.target.value);
    onChangeRef.current(event.target.value);
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyDown(e);

    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      // When the value does not change cell commit does a "revert".
      if (localValue === initialValueRef.current) {
        // For cell commit when no change was made we might want to unify the value in other selected cells.
        onCellsChanged([{
          cell,
          col,
          row,
          value: localValue,
        }]);
      }
    }

    if (e.key === 'Escape') {
      // Prevent closing split screen
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const clickHandler = closeEditorRef.current;
    window.addEventListener('click', clickHandler);

    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  if (isColumnResizing) {
    return null;
  }

  if (errorMessage) {
    return (
      <SpreadsheetDataEditorDisabledComponent
        disabledNotice={errorMessage}
        onClose={onRevert}
      />
    );
  }

  return (
    <div
      css={spreadsheetDataEditorStyle}
      ref={setTriggerRef}
    >
      <FixedStickyContainerComponent
        isOpen
        onClose={closeEditorRef.current}
        triggerRef={triggerRef}
      >
        {({ maxHeight }) => (
          <div>
            <ScrollBarComponent
              type={ScrollbarType.Vertical}
              contentStyle={{
                display: 'block',
                minWidth: 'none',
              }}
              translateContentHeightToHolder
              maxHeight={maxHeight}
            >
              <div>
                <textarea
                  ref={textAreaRef}
                  css={textareaStyle}
                  value={localValue ?? ''}
                  onClick={stopPropagation}
                  onKeyDown={handleKeyDown}
                  onMouseDown={preventClosingTextarea}
                  onMouseUp={preventClosingTextarea}
                  onChange={valueChangeHandler}
                />
              </div>
            </ScrollBarComponent>
          </div>
        )}
      </FixedStickyContainerComponent>
    </div>
  );
};
