import { css } from '@emotion/react';
import { type Theme } from '~/_shared/themes/theme.model';

export const warningWrapperStyle = (theme: Theme) => css({
  color: theme.textColors.warning,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

export const warningColorStyle = (theme: Theme) => css({
  color: theme.textColors.warning,
});

export const inlineWarningStyle = (theme: Theme) => css(warningColorStyle(theme), {
  textTransform: 'uppercase',
});

export const warningIconStyle = css({
  marginRight: 4,
});
