import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { type LatLng } from '~/_shared/types/latLng';
import { GoogleMapsOverlayViewContainer } from '../_shared/googleMapsOverlayViewContainer';

const INDICATOR_SIZE = 200;
const INDICATOR_COLOR = { base: '#000' };

const positionContainerStyle = css({
  left: -(INDICATOR_SIZE / 2),
  position: 'relative',
  top: -(INDICATOR_SIZE / 2),
});

export type HoveredMarkerIndicatorProps = {
  map: google.maps.Map;
  position: LatLng;
};

export const HoveredMarkerIndicator: FC<HoveredMarkerIndicatorProps> = (props) => {
  return (
    <GoogleMapsOverlayViewContainer
      position={props.position}
      map={props.map}
      noMouseEvents
    >
      <div
        css={positionContainerStyle}
        data-testid="hovered-marker-indicator"
      >
        <LottieAnimationComponent
          autoplay
          colors={INDICATOR_COLOR}
          loop
          size={INDICATOR_SIZE}
          type={LottieAnimationTypes.CheckingPlatform}
        />
      </div>
    </GoogleMapsOverlayViewContainer>
  );
};
