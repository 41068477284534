import {
  type FC, type MutableRefObject, type ReactNode, useEffect, useMemo, useState,
} from 'react';
import { type InputSize } from '~/_shared/baseComponents/inputs';
import { type MapListingItem } from '../../../map/listing/item/mapListingItem';
import { MapListSortType } from '../../../map/listing/mapListing.helpers';
import { useMapListingItems } from '../../../map/listing/useMapListingItems';
import { type MapListFilter } from '../../../map/map.repository';
import { useTranslation } from '../../utils/hooks';
import { MapPickerDropdownComponent } from './mapPickerDropdown.component';

type MapPickerDropdownContainerProps = Readonly<{
  additionalAction?: ReactNode;
  forceRefreshCallbackRef?: MutableRefObject<() => void>;
  inputSize?: InputSize;
  placeholder?: string;
  preFetchFilter?: MapListFilter;
  selectedMap: MapListingItem | null;

  onMapSelected: (map: MapListingItem) => void;
  highlightedMapsFilter?: (map: MapListingItem) => boolean;
  postFetchFilter: (map: MapListingItem) => boolean;
}>;

export const MapPickerDropdownContainer: FC<MapPickerDropdownContainerProps> = props => {
  const [t] = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const { allMapListingItems: mapListingItems, pagination, onPageChange, isLoading, forceRefresh }
    = useMapListingItems({
      isActive: true,
      sortType: MapListSortType.Updated_DESC,
      filterType: props.preFetchFilter,
      searchQuery,
    });

  const filteredMapListingItems = useMemo(() => {
    const postFetchFilter = props.postFetchFilter;
    return mapListingItems.filter((map) => postFetchFilter(map));
  }, [mapListingItems, props.postFetchFilter]);

  const highlightedMapListingItemIds = useMemo(() => {
    const highlightedMapsFilter = props.highlightedMapsFilter;

    if (highlightedMapsFilter) {
      return new Set(mapListingItems.filter((map) => highlightedMapsFilter(map)).map(map => map.id));
    }

    return new Set<number>();
  }, [mapListingItems, props.highlightedMapsFilter]);

  useEffect(() => {
    if (props.forceRefreshCallbackRef?.current) {
      props.forceRefreshCallbackRef.current = forceRefresh;
    }
  }, [props.forceRefreshCallbackRef, forceRefresh]);

  return (
    <MapPickerDropdownComponent
      higlightedMapIds={highlightedMapListingItemIds}
      placeholder={props.placeholder ?? t('Select Map')}
      onMapSelected={props.onMapSelected}
      options={filteredMapListingItems}
      pagination={pagination}
      onSearchQueryChange={setSearchQuery}
      onPageChange={onPageChange}
      isLoading={isLoading}
      selectedMap={props.selectedMap}
      additionalAction={props.additionalAction}
      inputSize={props.inputSize}
    />
  );
};
