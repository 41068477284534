import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Copy]: {
  views: {
    default: {
      canvasWidth: 480,
      canvasHeight: 480,
      iconWidth: 220,
      iconHeight: 220,
      offsetTop: 124,
      offsetLeft: 124,
    },
  },
  colors: {
    frontFill: '#FFFFFF',
    frontBorder: '#2B3151',
    backBorder: '#9BA0C0',
  },
  gradients: {},
  frameRate: 60,
  segments: {
    default: [0, 121] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/110165-copy" */
      './110165-copy.json'
    ),
} };

export const { Copy } = config;
