import type { MarkersSelectAction } from '~/store/frontendState/mapTools/markersSelect/markersSelect.action';
import {
  MARKERS_SELECT_ACTIVATE,
  MARKERS_SELECT_DEACTIVATE, MARKERS_SELECT_SAVE,
  MARKERS_SELECT_SET_TYPE,
} from '~/store/frontendState/mapTools/markersSelect/markersSelect.actionTypes';
import { defaultMarkersSelectOptions } from '~/store/frontendState/mapTools/markersSelect/markersSelect.reducer';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

const CHANGE_ID = 'MARKERS_SELECT_SELECT_TYPE';

const getCursorForSelectType = (type: MarkersSelectType) => {
  switch (type) {
    case MarkersSelectType.Lasso: {
      return 'crosshair';
    }
    case MarkersSelectType.Click:
    default: {
      return 'pointer';
    }
  }
};

const getSelectTypeSettings = (type: MarkersSelectType, priority: number) => ({
  changeId: CHANGE_ID,
  priority,
  change: {
    draggableCursor: getCursorForSelectType(type),
    ...(type === MarkersSelectType.Lasso ? { disableDoubleClickZoom: true } : undefined),
  },
});

let isActive = false;
let lastSelectType: MarkersSelectType = defaultMarkersSelectOptions.selectType;

export const applyMarkersSelectMapOptions = (
  state: MapComponentOptionsState,
  action: MarkersSelectAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case MARKERS_SELECT_ACTIVATE: {
      isActive = true;

      const activated = push(state, {
        changeId: CHANGE_ID,
        priority,
        change: {
          clickableIcons: false,
        },
      });

      return push(activated, getSelectTypeSettings(lastSelectType, priority));
    }

    case MARKERS_SELECT_SET_TYPE: {
      lastSelectType = action.payload.selectType;

      return isActive ? push(
        pop(state, CHANGE_ID),
        getSelectTypeSettings(action.payload.selectType, priority)
      ) : undefined;
    }

    case MARKERS_SELECT_SAVE:
    case MARKERS_SELECT_DEACTIVATE: {
      const newState = pop(state, CHANGE_ID);

      isActive = false;
      return pop(newState, CHANGE_ID);
    }

    default:
      return undefined;
  }
};
