import {
  type FC, memo, type ReactNode, useCallback,
} from 'react';
import { type MapOutlinePositionInstance } from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '~/_shared/types/latLng';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { ZOOM_LEVEL_FOR_SCALED_ITEMS } from '~/_shared/utils/distance/distance.helpers';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import { type DrawingItemArrow } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapArrowComponent } from '../../mapObjects/mapArrow/mapArrow.component';
import { MapArrowContainer } from '../../mapObjects/mapArrow/mapArrow.container';
import { type MapArrowInstance } from '../../mapObjects/mapArrow/mapArrowModel';
import { type MapObjectZIndex } from '../../mapObjects/mapObject.types';

type MapArrowInstanceProps = {
  instance: DrawingItemArrow;
  onClick?: (event: MapObjectClickEventArgs) => void;
  onRightClick?: (event: MapObjectClickEventArgs) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  onDragStart?: (latLng: LatLng) => void;
  onDragEnd?: () => void;
  onDragMove?: (latLng: LatLng) => void;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  zIndex: MapObjectZIndex;
};

const DrawingToolArrowInstanceArrowContainer: FC<MapArrowInstanceProps> = ({
  instance, onClick, onMouseOut,
  onMouseOver, onDragStart, onDragEnd,
  onDragMove, renderOutline, zIndex, onRightClick,
}) => {
  const { settings } = instance;
  const scaledSizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const renderArrow = useCallback((_: string, arrow: MapArrowInstance) => {
    const fillColor = convertColorToWebGLColor(settings.fillColor, settings.fillOpacity / 100);
    const strokeColor = convertColorToWebGLColor(settings.strokeColor, settings.strokeOpacity / 100);

    const sizeRatio = settings.scalesWithMapZoom ? scaledSizePerPixelRatio : 1;
    const useStrokeAsFill = settings.strokeWeight && settings.lineWeight && settings.lineWeight / sizeRatio < 1;

    return (
      <MapArrowComponent
        visuals={{
          color: useStrokeAsFill ? strokeColor : fillColor,
          strokeColor,
          tipLength: `${settings.triangleSize}%`,
          tipWidth: `${settings.triangleSize}%`,
          width: useStrokeAsFill ? settings.lineWeight + settings.strokeWeight : settings.lineWeight,
          strokeWidth: useStrokeAsFill ? 0 : settings.strokeWeight,
          zIndex: zIndex.arrow,
          ...(settings.scalesWithMapZoom ? { sizeOnLevel: ZOOM_LEVEL_FOR_SCALED_ITEMS } : undefined),
        }}
        arrow={arrow}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
      />
    );
  }, [settings.fillColor, settings.fillOpacity, settings.strokeColor, settings.strokeOpacity, settings.scalesWithMapZoom,
    settings.strokeWeight, settings.lineWeight, settings.triangleSize, scaledSizePerPixelRatio, zIndex.arrow, onClick,
    onMouseOver, onMouseOut, onDragStart, onDragEnd, onDragMove, onRightClick]);

  return (
    <MapArrowContainer
      zIndex={zIndex}
      arrow={{
        id: instance.id,
        tipLatLng: instance.tipPoint,
        tailLatLng: instance.tailPoint,
        staticLength: settings.scalesWithMapZoom ? 0 : instance.staticLength,
      }}
      renderArrow={renderArrow}
      renderOutline={renderOutline}
    />
  );
};

const pureComponent = memo(DrawingToolArrowInstanceArrowContainer);
export { pureComponent as DrawingToolArrowInstanceArrowContainer };
