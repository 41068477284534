import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '../../../_shared/types/toolsAndFeatures/mapTools.types';
import { useTranslation } from '../../../_shared/utils/hooks';
import { useDrawingItemsRemoveAll } from '../../../drawingTool/hooks/useDrawingItemsRemoveAll';
import { useDrawingToolVisibility } from '../../../drawingTool/hooks/useDrawingToolVisibility';
import { openMapTool } from '../../../store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { drawingToolClearActiveTool } from '../../../store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { useDrawingItemsSelector } from '../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useDrawingStatusBarItems = () => {
  const drawingItems = useDrawingItemsSelector();
  const { removeAllDrawings } = useDrawingItemsRemoveAll();
  const dispatch = useDispatch();
  const { setAllToolsVisibility, areAllDrawingToolsHidden } = useDrawingToolVisibility();
  const [t] = useTranslation();

  return useMemo<MapStatusBarItem[]>(() => {
    if (drawingItems.length === 0) {
      return [];
    }

    return [{
      isPrimary: true,
      isVisible: !areAllDrawingToolsHidden,
      label: t('Drawings'),
      onClick: () => {
        dispatch(drawingToolClearActiveTool());
        dispatch(openMapTool(MapTool.Drawing));
      },
      onRemove: () => {
        removeAllDrawings();
      },
      onVisibilityChange: (showAll) => {
        setAllToolsVisibility(showAll);
      },
      removeStrategy: null,
    }];
  }, [removeAllDrawings, t, drawingItems, dispatch, areAllDrawingToolsHidden, setAllToolsVisibility]);
};
