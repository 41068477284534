import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { createUuid } from '~/_shared/utils/createUuid';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { frontendStateDirectionsAddWaypoints } from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import { type Waypoint } from '~/store/frontendState/mapTools/directions/directions.state';
import { useMapSettingsDirectionsShouldBeginAtUserLocationSelector } from '../../../../store/mapSettings/directions/mapSettingsDirections.selectors';
import { useLatLngSpreadsheetData } from '../../useSpreadsheetData.hook';
import { type RowWithName } from './useProcessMarkersForRouting';

export const useSendToOptimizeRoute = () => {
  const [loading, setLoading] = useState(false);
  const directionsUseUserLocation = useMapSettingsDirectionsShouldBeginAtUserLocationSelector();
  const latLngLookup = useLatLngSpreadsheetData();
  const dispatch = useDispatch();

  const sendToOptimizeRoute = (rowsWithNames: RowWithName[]) => {
    try {
      setLoading(true);
      const maybeWaypoints = rowsWithNames.map(row => {
        const spreadsheetRowId = { rowId: row.rowId, spreadsheetId: row.spreadsheetId };
        const rowLatLng = latLngLookup.getRow(spreadsheetRowId);
        if (!rowLatLng) {
          return undefined;
        }

        return {
          latLng: { lng: rowLatLng.lng, lat: rowLatLng.lat },
          id: createUuid(),
          address: row.name,
          markerId: spreadsheetRowId,
        };
      });

      const waypoints = maybeWaypoints.filter(waypoint => !!waypoint) as Waypoint[];
      dispatch(openMapTool(MapTool.Routing));
      dispatch(frontendStateDirectionsAddWaypoints(waypoints, directionsUseUserLocation));
    }
    finally {
      setLoading(false);
    }
  };

  return {
    sendToOptimizeRoute,
    loading,
  };
};
