import { css } from '@emotion/react';
import {
  type CSSProperties, useCallback, useMemo, useState,
} from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '../_shared/components/scrollbar/scrollbar.component';
import {
  type SliderMarks, type SliderValue,
} from '../_shared/components/slider/slider.component';
import { SliderWithValueLabelsComponent } from '../_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTheme } from '../_shared/themes/theme.hooks';
import { type ThemeProps } from '../_shared/types/themeProps';
import { type SpreadsheetVersionItem } from '../_shared/types/undo/versionItem';
import {
  createFormatDateAccordingToBaseDate, formatDate,
} from '../_shared/utils/date/date.helpers';
import { useTranslation } from '../_shared/utils/hooks';

const TITLE_MARGIN_VERTICAL = 12;
const TITLE_LINE_HEIGHT = 19;
const titleStyle = css({
  lineHeight: `${TITLE_LINE_HEIGHT}px`,
  marginBottom: TITLE_MARGIN_VERTICAL,
  textTransform: 'uppercase',
});

const versionsPaneStyle = ({ theme, height }: ThemeProps<{ height: number }>) => css({
  height,
  margin: '16px 0',
  paddingLeft: 6,

  '& span': {
    cursor: 'default',
    '&.rc-slider-mark-text-active': {
      fontWeight: 'bold',
      color: theme.textColors.primary,
    },
    '&:not(.rc-slider-mark-text-active):hover': {
      color: theme.textColors.secondary,
    },
  },
});

const bodyWrapperStyle = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const timelineWrapperStyle = ({ height }: { height: number | string }) => css({
  height,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '70%',
});

const dateLabelStyles: CSSProperties = {
  marginLeft: 8,
  textAlign: 'start',
};

const detailsBodyStyle = css({
  position: 'relative',
  boxSizing: 'border-box',
  paddingLeft: 40,
  marginTop: 12,
  overflow: 'hidden',
  width: '50%',
});

const detailsTitleStyle = css({
  fontSize: 14,
  textTransform: 'uppercase',
});

const detailsNameStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  marginRight: 6,
});

const detailsDataStyle = css({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const detailsInfoStyle = css({
  fontSize: 12,

  '& > div': {
    display: 'flex',
    marginTop: 16,
  },
});

type RestoreVersionProps = Readonly<{
  height: number;
  items: ReadonlyArray<SpreadsheetVersionItem>;

  onSelectVersion: (itemId: string) => void;
}>;

const PER_UNDO_ITEM_SLIDER_LENGTH = 40;

export const RestoreVersionSelectorComponent: React.FC<RestoreVersionProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [sliderValue, setSliderValue] = useState<number>(0);

  const { onSelectVersion } = props;

  const handleChangeSlider = useCallback((values: SliderValue) => {
    const id = values[0];

    setSliderValue(id);
    onSelectVersion(props.items[id].id);
  }, [onSelectVersion, props.items]);

  const dates = useMemo(() => {
    const dateMarks: SliderMarks = {};
    const currentDate = new Date();

    props.items
      .map(item => item.createdAt)
      .forEach((date, i) => {
        const dateShape = createFormatDateAccordingToBaseDate(currentDate, date);

        dateMarks[i] = {
          label: formatDate(date, dateShape) + (i === 0 ? ' (current)' : ''),
          style: dateLabelStyles,
        };
      });

    return dateMarks;
  },
  [props.items]);

  const itemDetails = useMemo(() => {
    const item = props.items[sliderValue];
    if (!item) {
      return null;
    }

    const date = formatDate(item.updatedAt, 'YYYY-MMM-DD HH:MM');
    const type = item.type;
    const userName = item.creator.name;

    return { date, type, userName };
  }, [props.items, sliderValue]);

  return (
    <>
      <div css={titleStyle}>
        {props.items.length ? t('Select a version you wish to fall back to') + ':' : t('There are no older versions')}
      </div>

      <div css={bodyWrapperStyle} >
        <div css={timelineWrapperStyle({ height: props.height - TITLE_LINE_HEIGHT - TITLE_MARGIN_VERTICAL })}>
          <ScrollBarComponent type={ScrollbarType.Vertical}>
            <div css={versionsPaneStyle({ theme, height: props.items.length * PER_UNDO_ITEM_SLIDER_LENGTH })}>
              <SliderWithValueLabelsComponent
                included={false}
                marks={dates}
                max={props.items.length - 1}
                noLabel
                onChange={handleChangeSlider}
                reverse
                value={[sliderValue]}
                vertical
              />
            </div>
          </ScrollBarComponent>
        </div>

        <div css={detailsBodyStyle}>
          <div css={detailsTitleStyle}>
            Details:
          </div>
          <div css={detailsInfoStyle}>
            <div>
              <span css={detailsNameStyle({ theme })}>Date:</span>
              <span css={detailsDataStyle}>{itemDetails?.date}</span>
            </div>
            <div>
              <span css={detailsNameStyle({ theme })}>Name:</span>
              <span css={detailsDataStyle}>{itemDetails?.userName}</span>
            </div>
            <div>
              <span css={detailsNameStyle({ theme })}>Type:</span>
              <span css={detailsDataStyle}>{itemDetails?.type}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
