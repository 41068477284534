import {
  MOVE_MARKER_LABELS_DRAG_START,
  MOVE_MARKER_LABELS_DRAG_STOP,
  MOVE_MARKER_LABELS_XMARK_HOVER_STOP,
  MOVE_MARKER_LABELS_XMARK_HOVERED,
} from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionTypes';
import {
  MOVE_MARKERS_DRAG_START, MOVE_MARKERS_DRAG_STOP,
} from '~/store/frontendState/moveMarkers/moveMarkers.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyMoveMarkersMapOptions = (
  state: MapComponentOptionsState,
  action: Action,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {

    case MOVE_MARKERS_DRAG_START:
    case MOVE_MARKER_LABELS_DRAG_START:
      return push(state, {
        changeId: 'MOVE_MARKER_DRAG_ACTIVE',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case MOVE_MARKERS_DRAG_STOP:
    case MOVE_MARKER_LABELS_DRAG_STOP:
      return pop(state, 'MOVE_MARKER_DRAG_ACTIVE');

    case MOVE_MARKER_LABELS_XMARK_HOVERED:
      return push(state, {
        changeId: 'MOVE_MARKER_XMARK_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case MOVE_MARKER_LABELS_XMARK_HOVER_STOP:
      return pop(state, 'MOVE_MARKER_XMARK_HOVER');

    default:
      return undefined; // return undefined if not matched
  }
};
