import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import {
  RANGE_DEFAULT_HIGH_VALUE_COLOR, RANGE_DEFAULT_LOW_VALUE_COLOR, RANGE_DEFAULT_MEDIUM_VALUE_COLOR,
} from '~/_shared/constants/rangeColors';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB, DEFAULT_NUMERIC_GREEN_MARKER_COLOR, DEFAULT_NUMERIC_ROUND_MARKER_OPACITY,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import {
  formatValue, RangeType,
} from '~/_shared/utils/range/range.helpers';
import { type NumericalGroupSettingsInitial } from '../numericalGroupSettings.component';
import { NumericalGroupSettingsRangeSettingsComponent } from '../rangeSettings/numericalGroupSettingsRangeSettingsComponent';
import { NumericalGroupSettingsTableComponent } from '../table/numericalGroupSettingsTable.component';
import { NumericalGroupSettingsTableRowComponent } from '../table/row/numericalGroupSettingsTableRowComponent';
import {
  getDefaultBucketSettingsDataRows, getGroupingMaxRange, handleNumericGroupValueToChange, recalculateDataRowsValues,
} from './groupSettingsNumeric.helpers';
import {
  BucketMarkerVisualType, DEFAULT_BUCKET_MARKER_VISUAL_TYPE, getNumericDataRowSettingsWithFinalColors,
  markerVisualRadioDataLookup,
} from './numericalGroupSettingsNumeric.helpers';

export type NumericalGroupSettingsDataRow = {
  fromValue: string;
  toValue: string;
  isValid: boolean;
  color: ColorResult | null;
};

const DEFAULT_NUMBER_OF_RANGES = 4;
const DEFAULT_NO_LOCATIONS_COLOR = createColor({ r: 255, g: 255, b: 255, a: 0 });

const wrapperStyle = css({
  padding: '0 16px',
});

const bucketSettingsWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
  justifyContent: 'space-between',
  padding: '20px 4px',
});

const optionsColumnStyle = css({
  boxSizing: 'border-box',
  minWidth: 180,
  flexGrow: 1,
});

const rangeTypeRadioGroupStyle = css({
  marginTop: 25,
  gap: 25,
});

const rageTypeRadioItemStyle = css({
  width: 'unset',
});

const bubbleRadioGroupStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: 4,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  fontSize: 14,
  height: 80,
  justifyContent: 'space-between',
  padding: '24px 22px',
});

const dataLabelStyle = css({
  textTransform: 'uppercase',
});

const tablePrimaryDataStyle = css({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const tableToLabelStyle = css({
  padding: '0 5px',
});

const tableInputStyle = ({ theme, isValid }: ThemeProps<{ isValid: boolean }>) => css({
  background: theme.backgroundColors.secondary,
  minWidth: 50,
  borderColor: isValid ? undefined : theme.borderColors.error,
});

const toValueStyle = css({
  maxWidth: 120,
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
});

const fromValueStyle = css({
  maxWidth: 120,
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
});

const valueToWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

const headingStyle = css({
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
  margin: 0,
});

const getDefaultNumberOfBuckets = (min: number, max: number): number => {
  const minMaxDifference = max - min;
  const isMinMaxNonDecimal = min % 1 === 0 && max % 1 === 0;
  return (isMinMaxNonDecimal && minMaxDifference === 1
    ? 2
    : isMinMaxNonDecimal && minMaxDifference <= 10
      ? minMaxDifference
      : DEFAULT_NUMBER_OF_RANGES
  );
};

type NumericalGroupSettingsProps = Readonly<{
  initialSettings?: NumericalGroupSettingsInitial;
  isInitiallyDecimal: boolean;
  markerVisualRadioOptions: ReadonlyArray<BucketMarkerVisualType>;
  markerVisualSettings: MarkerSettings[];
  max: number;
  min: number;
  restoreButtonClicked: boolean;
  selectedMarkerVisualRadioOption: BucketMarkerVisualType | null;

  getExistingGroupVisualsIfAny: (bucketId: number, numberOfBuckets: number) => MarkerSettings | null;
  onDataRowsChange: (valueRanges: NumericalGroupSettingsDataRow[]) => void;
  onRangeTypeChange: (rangeType: RangeType) => void;
  setIsFormValid: (isFormValid: boolean) => void;
  setRestoreButtonClicked: (restoreButtonClicked: boolean) => void;
  setSelectedMarkerVisualRadioOption: (option: BucketMarkerVisualType | null) => void;
}>;

export const NumericalGroupSettingsNumericComponent: FC<NumericalGroupSettingsProps> = (props) => {
  const [rangeType, setRangeType] = useState<RangeType>(props.initialSettings?.valueType ?? RangeType.Value);
  const { setSelectedMarkerVisualRadioOption } = props;

  const [t] = useTranslation();
  const theme = useTheme();

  const bucketMarkerType = props.selectedMarkerVisualRadioOption;
  const defaultNumberOfRanges = props.initialSettings?.numberOfGroups ?? getDefaultNumberOfBuckets(props.min, props.max);
  const isDecimalMode = (rangeType !== RangeType.Percentage) && props.isInitiallyDecimal;

  const rangeMin = rangeType === RangeType.Value ? props.min : 0;
  const rangeMax = rangeType === RangeType.Value ? props.max : 100;
  const maxNumberOfRanges = useMemo(() => props.isInitiallyDecimal ? 10 : getGroupingMaxRange(rangeMin, rangeMax), [props.isInitiallyDecimal, rangeMin, rangeMax]);
  const initialNumberOfRanges = defaultNumberOfRanges > maxNumberOfRanges ? maxNumberOfRanges : defaultNumberOfRanges;

  const [numberOfRanges, setNumberOfRanges] = useState(initialNumberOfRanges);

  const getDataRowsWithColor = useCallback((
    rangeMin: number, rangeMax: number, numberOfRanges: number, isDecimalMode: boolean,
  ) => {
    let lowColor: ColorResult | undefined;
    let mediumColor: ColorResult | undefined;
    let highColor: ColorResult | undefined;
    let opacity: number | undefined;

    if (bucketMarkerType === BucketMarkerVisualType.BlueBubble) {
      lowColor = mediumColor = highColor = createColor(DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB);
      opacity = DEFAULT_NUMERIC_ROUND_MARKER_OPACITY;
    }

    if (bucketMarkerType === BucketMarkerVisualType.GrowingPin) {
      lowColor = mediumColor = highColor = createColor(DEFAULT_NUMERIC_GREEN_MARKER_COLOR);
      opacity = 1;
    }

    if (bucketMarkerType === BucketMarkerVisualType.MultiColorBubble) {
      lowColor = RANGE_DEFAULT_LOW_VALUE_COLOR;
      mediumColor = RANGE_DEFAULT_MEDIUM_VALUE_COLOR;
      highColor = RANGE_DEFAULT_HIGH_VALUE_COLOR;
      opacity = DEFAULT_NUMERIC_ROUND_MARKER_OPACITY;
    }

    return getDefaultBucketSettingsDataRows(
      rangeMin,
      rangeMax,
      numberOfRanges,
      isDecimalMode,
      lowColor,
      mediumColor,
      highColor,
      opacity,
    );
  }, [bucketMarkerType]);

  const getDataRowSettingsWithFinalColors = useCallback((initialDataRowsSettings?: NumericalGroupSettingsDataRow[]) =>
    getNumericDataRowSettingsWithFinalColors({
      defaultDataRows: getDataRowsWithColor(rangeMin, rangeMax, numberOfRanges, isDecimalMode),
      getExistingGroupVisualsIfAny: props.getExistingGroupVisualsIfAny,
      initialDataRowsSettings,
    }), [getDataRowsWithColor, isDecimalMode, numberOfRanges, props.getExistingGroupVisualsIfAny, rangeMax, rangeMin]);

  const [dataRows, setDataRows] = useState<NumericalGroupSettingsDataRow[]>(
    getDataRowSettingsWithFinalColors(props.initialSettings?.dataRows)
  );

  const prevNumberOfRanges = usePrevious(numberOfRanges);
  const prevBubbleMarkerType = usePrevious(props.selectedMarkerVisualRadioOption);
  const prevRangeMin = usePrevious(rangeMin);
  const prevRangeMax = usePrevious(rangeMax);
  const numberOfDataRows = usePrevious(dataRows.length);
  const { onDataRowsChange, onRangeTypeChange } = props;

  const dataTableValueSuffix = rangeType === RangeType.Percentage ? '%' : '';

  const changeDataRowValue = (index: number, value: string) => {
    setDataRows(handleNumericGroupValueToChange(index, value, dataRows, rangeMin, rangeMax, rangeType !== RangeType.Percentage));
  };

  const changeDataRowColor = (index: number, color: ColorResult) => {
    setDataRows(rows => {
      const newRows = [...rows];

      const newRowOnIndex = newRows[index];
      if (!newRowOnIndex) {
        return rows;
      }

      const newRow: NumericalGroupSettingsDataRow = {
        ...newRowOnIndex,
        color,
      };
      newRows.splice(index, 1, newRow);

      return newRows;
    });
  };

  const restoreDefaults = useCallback(() => {
    setNumberOfRanges(initialNumberOfRanges);
    setRangeType(RangeType.Value);
    setDataRows(getDataRowSettingsWithFinalColors());
    setSelectedMarkerVisualRadioOption(DEFAULT_BUCKET_MARKER_VISUAL_TYPE);
  }, [initialNumberOfRanges, getDataRowSettingsWithFinalColors, setSelectedMarkerVisualRadioOption]);

  useEffect(() => {
    props.setIsFormValid(dataRows.filter(item => !item.isValid).length === 0);
  }, [dataRows, props]);

  useEffect(() => {
    if (props.restoreButtonClicked) {
      restoreDefaults();
      props.setRestoreButtonClicked(false);
    }
  }, [props, props.restoreButtonClicked, restoreDefaults]);

  useEffect(() => {
    if (prevNumberOfRanges !== undefined && numberOfRanges !== prevNumberOfRanges) {
      setDataRows(getDataRowSettingsWithFinalColors());
      return;
    }

    if (
      (prevRangeMin !== undefined && rangeMin !== prevRangeMin) ||
      (prevRangeMax !== undefined && rangeMax !== prevRangeMax)
    ) {
      setDataRows(recalculateDataRowsValues(dataRows, rangeMin, rangeMax, isDecimalMode));
    }

    if (prevBubbleMarkerType !== undefined && prevBubbleMarkerType !== props.selectedMarkerVisualRadioOption) {
      setDataRows(getDataRowsWithColor(rangeMin, rangeMax, numberOfRanges, isDecimalMode));
    }
  }, [isDecimalMode, dataRows, prevRangeMax, rangeMax, prevRangeMin, rangeMin, numberOfRanges, prevNumberOfRanges,
    getDataRowsWithColor, props.selectedMarkerVisualRadioOption, prevBubbleMarkerType,
    getDataRowSettingsWithFinalColors]);

  useEffect(() => {
    onDataRowsChange(dataRows);
  }, [dataRows, numberOfDataRows, onDataRowsChange]);

  useEffect(() => {
    onRangeTypeChange(rangeType);
  }, [onRangeTypeChange, rangeType]);

  return (
    <div css={wrapperStyle}>
      <div css={bucketSettingsWrapperStyle}>
        <NumericalGroupSettingsRangeSettingsComponent
          css={optionsColumnStyle}
          maxNumberOfRanges={maxNumberOfRanges}
          numberOfRanges={numberOfRanges}
          onNumberOfRangesChange={setNumberOfRanges}
        />

        <div css={optionsColumnStyle}>
          <p css={headingStyle}>{t('Grouping Method')}</p>

          <RadioGroupComponent
            css={rangeTypeRadioGroupStyle}
            selectedValue={rangeType}
            onValueChange={value => setRangeType(value as RangeType)}
            items={[{
              value: RangeType.Percentage,
              label: t('Percentage Ranges'),
            }, {
              value: RangeType.Value,
              label: t('Value Ranges'),
            }]}
          />
        </div>
      </div>

      <div>
        <RadioGroupComponent
          css={bubbleRadioGroupStyle({ theme })}
          itemCommonStyle={rageTypeRadioItemStyle}
          items={props.markerVisualRadioOptions.map(radio => markerVisualRadioDataLookup[radio])}
          onValueChange={value => setSelectedMarkerVisualRadioOption(value as BucketMarkerVisualType)}
          selectedValue={bucketMarkerType}
        />
      </div>

      {dataRows.length > 0 && (
        <NumericalGroupSettingsTableComponent
          dataHeadingLabel={t('Number Ranges')}
          dataRows={(
            <>
              {dataRows.map((item, index) => {
                const fromValue = formatValue(item.fromValue) + dataTableValueSuffix;
                const toValue = formatValue(item.toValue) + dataTableValueSuffix;
                const markerVisualSettings = props.markerVisualSettings[index];
                const color = item.color ||
                  (markerVisualSettings?.marker?.selectedColor
                    ? createColor(markerVisualSettings.marker.selectedColor)
                    : createColor(DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB)
                  );

                return markerVisualSettings ? (
                  <NumericalGroupSettingsTableRowComponent
                    key={index}
                    index={index + 1}
                    data={(
                      <div css={tablePrimaryDataStyle}>
                        <span
                          title={fromValue}
                          css={fromValueStyle}
                        >
                          {t('{{value}}+', { value: fromValue })}
                        </span>
                        <div css={valueToWrapperStyle}>
                          <span css={tableToLabelStyle}>{t('toNumber')}</span>
                          {index < dataRows.length - 1 ? (
                            <TextInputComponent
                              css={tableInputStyle({ theme, isValid: item.isValid })}
                              onChange={value => changeDataRowValue(index, value)}
                              value={item.toValue}
                              title={item.toValue}
                              icon={null}
                            />
                          ) : (
                            <span
                              title={toValue}
                              css={toValueStyle}
                            >
                              {toValue}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    color={color}
                    markerSettings={markerVisualSettings}
                    onColorChange={color => changeDataRowColor(index, color)}
                  />
                ) : null;
              })}
              <NumericalGroupSettingsTableRowComponent
                color={DEFAULT_NO_LOCATIONS_COLOR}
                data={(
                  <span css={dataLabelStyle}>{t('Non-numerical Values')}</span>
                )}
                index={dataRows.length + 1}
                isLast
                markerSettings={props.markerVisualSettings.lastItem}
                onColorChange={noop}
              />
            </>
          )}
        />
      )}
    </div>
  );
};
