import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';

export const baseMapThemesVintage: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#455A66',
      },
      weight: {
        isActive: true,
        value: 2,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#466F75',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#E5D060',
      },
      weight: {
        isActive: true,
        value: 6,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.administrative,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      color: {
        isActive: true,
        value: '#e6007e',
      },
      weight: {
        isActive: true,
        value: 1,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.landscape,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      color: {
        isActive: true,
        value: '#E5D060',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      color: {
        isActive: true,
        value: '#466F75',
      },
      visibility: BaseMapStylerVisibility.simplified,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#E5D060',
      },
      visibility: BaseMapStylerVisibility.shown,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#466F75',
      },
      weight: {
        isActive: true,
        value: 8,
      },
      visibility: BaseMapStylerVisibility.shown,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName.all,
    stylers: {
      visibility: BaseMapStylerVisibility.simplified,
      color:
    {
      isActive: true,
      value: '#466F75',
    },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#466F75',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      visibility: BaseMapStylerVisibility.simplified,
      color:
    {
      isActive: true,
      value: '#E5D060',
    },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.icon'],
    stylers: {
      visibility: BaseMapStylerVisibility.hidden,
    },
  },
];
