import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  activateMoveMarkerLabels, deactivateMoveMarkerLabels, moveMarkerLabelsCloseModal,
} from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionCreators';
import {
  useMoveMarkerLabelOffsetsSelector,
  useMoveMarkerLabelsIsActiveSelector,
  useMoveMarkerLabelsIsModalVisible,
} from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.selectors';
import { mapSettingsMarkersUpdateLabelMarkerOffsets } from '~/store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import { MoveMarkerLabelsSaveComponent } from './moveMarkerLabelsSave.component';

export const MoveMarkerLabelsSaveContainer = () => {
  const isModalVisible = useMoveMarkerLabelsIsModalVisible();
  const isMoveActive = useMoveMarkerLabelsIsActiveSelector();
  const temporaryOffsets = useMoveMarkerLabelOffsetsSelector();
  const labelsMoved = !!temporaryOffsets.size;

  const dispatch = useDispatch();

  const deactivate = useCallback(() => {
    dispatch(deactivateMoveMarkerLabels());
  }, [dispatch]);

  const onStartClick = useCallback(() => {
    dispatch(activateMoveMarkerLabels());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(moveMarkerLabelsCloseModal());
  }, [dispatch]);

  const onConfirmClick = useCallback(() => {
    dispatch(moveMarkerLabelsCloseModal());

    dispatch(mapSettingsMarkersUpdateLabelMarkerOffsets(
      Array.from(temporaryOffsets).map(([marker, offset]) => ({ marker, offset })))
    );
  }, [dispatch, temporaryOffsets]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <MoveMarkerLabelsSaveComponent
      isMoveActive={isMoveActive}
      onClose={onClose}
      onResetClick={deactivate}
      onConfirmClick={onConfirmClick}
      labelsMoved={labelsMoved}
      onStartClick={onStartClick}
    />
  );
};
