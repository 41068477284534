import { faPencilPlus } from '@awesome.me/kit-77e1f5874f/icons/kit/custom';
import { css } from '@emotion/react';
import {
  faChartColumn,
  faCog,
  faDiamondTurnRight,
  faDrawPolygon,
  faEdit,
  faEllipsisVertical,
  faExternalLink,
  faScissors,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type MutableRefObject, useMemo, useRef,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import {
  TinyMapColorTileComponent,
  type TinyMapColorTileComponentProps,
} from '~/_shared/baseComponents/tinyMapPlaceholder';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { type MetricsResults } from '~/_shared/utils/metric/useMetricData.helpers';
import { ISOLATE_BOUNDARY_MAXIMUM_NUMBER_OF_POLYGONS } from '~/boundary/boundary.constants';
import { MapToolHeaderButtonComponent } from '~/sidebar/sidebarApps/mapToolWrapper/mapTooHeaderButton.component';
import { SidebarTitleComponent } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.component';
import { MetricsListComponent } from '../../../../_shared/components/metricsList/metricsList.component';
import { MetricsPanel } from '../../../../proximityDetails/metricsPanel/metricsPanel';
import { type WmsBlockerFn } from '../../mapTools/boundary/activeBoundary/boundaryItem/useWmsFeatureBlocker';
import { getTotalLocationsStyle } from '../helpers/getTotalLocationsStyle';
import { type SidebarRoundMenuButtonProps } from '../roundButtonMenu/sidebarRoundButtonMenu.types';
import { SidebarContentContainer } from '../sidebarContent/sidebarContent.container';
import { type ContextMenuItemProps } from '../sidebarContextMenu/sidebarContextMenu.types';
import { IsolateBoundaryColorPickerComponent } from './isolateBoundaryColorPicker.component';

const locationDescriptionStyle = css({
  paddingLeft: '8px',
});

const alertWrapperStyle = css({
  fontSize: 14,
});

const alertHeadingStyle = css({
  fontWeight: 700,
});

const customizeMetricsWrapperStyle = css({
  background: 'inherit',
});

//TODO: Replace affected div with <Stack> once it makes its way to master.
const stackStyles = css({
  padding: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const syntheticBoundaryNoteStyle = (theme: Theme) => css({
  color: theme.textColors.secondary,
  textTransform: 'initial',
  fontSize: 14,
  lineHeight: '16px',
  alignItems: 'center',
});

type BoundaryDetailsComponentProps = {
  boundaryName?: string;
  isLoading: boolean;
  metrics: MetricsResults;
  ignoreFilters: boolean;
  indicatorStyles?: Pick<TinyMapColorTileComponentProps, 'lineColor' | 'lineWidth' | 'color'>;
  isCustom: boolean;
  isArtificial: boolean;
  blockWhenWmsTrial?: WmsBlockerFn;

  onClose: () => void;
  onEditClick?: () => void;
  onCustomizeMetricsClick?: () => void;
} & Omit<CreateButtonsParams, 'buttonRefs' | 'blockWhenWmsTrial'>;

type CreateButtonsParams = {
  boundaryPolygonsTotal: number;
  buttonRefs: {
    additionalActionsRef: MutableRefObject<HTMLButtonElement | null>;
  };
  isBoundaryIsolated?: boolean;
  isCustom: boolean;
  isMobileVersion: boolean;
  boundaryIsUnderWmsTrial?: boolean;
  blockWhenWmsTrial: WmsBlockerFn;
  isLayeredMapConnected: boolean;
  isEditingBoundary?: boolean;
  markersCount: number;
  onAdvanceEditClick?: () => void;
  onBatchEditLocationsClick?: () => void;
  onBoundaryItemRemoveClick?: () => void;
  onBoundaryItemSettingsClick?: () => void;
  onDirectionsClick?: () => void;
  onEditBoundaryPolygonClick?: () => void;
  onExportLocationsClick?: () => void;
  onIsolateBoundaryClick?: () => void;
  onExportContainedBoundariesClick?: () => void;
};

const useCreateButtons = (params: CreateButtonsParams) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { buttonRefs } = params;

  const buttonsConfiguration: SidebarRoundMenuButtonProps<string>[] = [];

  if (params.onBoundaryItemSettingsClick) {
    const text = params.isCustom ? t('Territory Settings') : t('Boundary Settings');
    const editNameButtonConfiguration = {
      buttonName: text,
      buttonStyle: RoundButtonStyle.Primary,
      icon: faCog,
      onClick: params.onBoundaryItemSettingsClick,
      tooltipLabel: text,
    };
    buttonsConfiguration.push(editNameButtonConfiguration);
  }

  const boundaryType = params.isCustom ? t('Territory') : t('Boundary');

  const tooManyPolygonsForIsolateBoundary = params.boundaryPolygonsTotal > ISOLATE_BOUNDARY_MAXIMUM_NUMBER_OF_POLYGONS;
  if (params.onIsolateBoundaryClick) {
    const text = t('Isolate {{boundaryType}}', { boundaryType });
    const editNameButtonConfiguration = {
      buttonName: text,
      buttonStyle: params.isBoundaryIsolated ? RoundButtonStyle.Quaternary : RoundButtonStyle.Primary,
      icon: faScissors,
      onClick: params.onIsolateBoundaryClick,
      tooltipLabel: tooManyPolygonsForIsolateBoundary
        ? t('Isolate {{boundaryType}} tooManyPolygons', { boundaryType }) : text,
      notSelectable: tooManyPolygonsForIsolateBoundary,
    };
    buttonsConfiguration.push(editNameButtonConfiguration);
  }

  if (params.onEditBoundaryPolygonClick) {
    const text = t('Edit {{boundaryType}}', { boundaryType });
    const editBoundaryButtonStyle = !params.isEditingBoundary ? params.boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : RoundButtonStyle.Primary : RoundButtonStyle.ConfirmInverted;

    const editPolygonButtonConfiguration = {
      buttonName: text,
      buttonStyle: editBoundaryButtonStyle,
      icon: faDrawPolygon,
      onClick: params.blockWhenWmsTrial(params.onEditBoundaryPolygonClick),
      tooltipLabel: text,
    };

    buttonsConfiguration.push(editPolygonButtonConfiguration);
  }

  if (params.onAdvanceEditClick) {
    const text = t('Advanced Add / Edit {{boundaryType}}', { boundaryType });
    const advanceEditButtonConfiguration = {
      buttonName: text,
      buttonStyle: params.boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : RoundButtonStyle.Primary,
      icon: faPencilPlus,
      onClick: params.blockWhenWmsTrial(params.onAdvanceEditClick),
      tooltipLabel: text,
    };

    buttonsConfiguration.push(advanceEditButtonConfiguration);
  }

  const iconStyle = params.boundaryIsUnderWmsTrial ? css({ color: theme.textColors.warning }) : undefined;

  const hasMarkers = params.markersCount > 0;

  const additionalButtons: ContextMenuItemProps[] = [
    ...(params.onBatchEditLocationsClick && hasMarkers && !params.isLayeredMapConnected ? [{ text: t('Batch Edit Locations'), icon: faEdit, iconStyle, onClick: params.blockWhenWmsTrial(params.onBatchEditLocationsClick) }] : []),
    ...(params.onExportLocationsClick && hasMarkers ? [{ text: t('Export Locations'), icon: faExternalLink, iconStyle, onClick: params.blockWhenWmsTrial(params.onExportLocationsClick) }] : []),
    ...(params.onExportContainedBoundariesClick ? [{ text: t('Export Contained Boundaries'), icon: faExternalLink, iconStyle, onClick: params.blockWhenWmsTrial(params.onExportContainedBoundariesClick) }] : []),
    ...(params.onDirectionsClick && hasMarkers ? [{ text: t('Optimize Driving Route'), icon: faDiamondTurnRight, iconStyle, onClick: params.blockWhenWmsTrial(params.onDirectionsClick) }] : []),
  ];

  if (additionalButtons.length > 0) {
    const additionalActionsButton: SidebarRoundMenuButtonProps<string> = {
      buttonName: t('Additional Actions'),
      buttonStyle: params.boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : RoundButtonStyle.Primary,
      contextItemMenuStructure: additionalButtons,
      icon: faEllipsisVertical,
      tooltipLabel: t('Additional Actions'),
      ref: buttonRefs.additionalActionsRef,
    };

    buttonsConfiguration.push(additionalActionsButton);
  }

  if (params.onBoundaryItemRemoveClick) {
    const deleteButtonConfiguration = {
      buttonName: t('Delete'),
      buttonStyle: RoundButtonStyle.Danger,
      icon: faTrash,
      onClick: params.onBoundaryItemRemoveClick,
      tooltipLabel: t('Delete'),
    };

    buttonsConfiguration.push(deleteButtonConfiguration);
  }

  return buttonsConfiguration;
};

export const BoundaryDetailsComponent: FC<BoundaryDetailsComponentProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const additionalActionsRef = useRef<HTMLButtonElement | null>(null);
  const blockWhenWmsTrial = props.blockWhenWmsTrial ?? (fn => () => fn());
  const buttons = useCreateButtons({ buttonRefs: { additionalActionsRef }, ...props, blockWhenWmsTrial });
  const ignoreFiltersAlert = (
    <div css={alertWrapperStyle}>
      <AlertComponent
        size="small"
        type="warning"
        transparentBackground
        collapsedContent={(<span css={alertHeadingStyle}>{t('Ignore Filters is activated.')}</span>)}
      >
        <span css={alertHeadingStyle}>{t('Ignore Filters is activated.')}</span>
        <p>
          {t('The counts and metrics calculated in this panel might include markers which are not visible on the map.')}
        </p>
      </AlertComponent>
    </div>
  );

  const boundaryIndicator = useMemo(() => {
    if (!props.indicatorStyles) {
      return undefined;
    }

    return (
      <TinyMapColorTileComponent
        color={props.indicatorStyles.color}
        lineColor={props.indicatorStyles.lineColor}
        lineWidth={props.indicatorStyles.lineWidth}
        fill
        outline
      />
    );
  }, [props.indicatorStyles]);

  return (
    <SidebarContentContainer
      body={(
        <MetricsPanel>

          <MetricsListComponent
            metrics={props.metrics}
            boundaryIsUnderWmsTrial={props.boundaryIsUnderWmsTrial}
          />
        </MetricsPanel>
      )}
      secondaryHeader={(
        <div css={stackStyles}>
          {props.isBoundaryIsolated && <IsolateBoundaryColorPickerComponent />}
          {props.onCustomizeMetricsClick && (
            <MapToolHeaderButtonComponent
              css={customizeMetricsWrapperStyle}
              text={t('Customize Metrics')}
              prefixIcon={faChartColumn}
              prefixIconStyle={props.boundaryIsUnderWmsTrial ? css({ color: theme.textColors.warning }) : undefined}
              onClick={blockWhenWmsTrial(props.onCustomizeMetricsClick)}
            />
          )}
          {!props.isLoading && props.ignoreFilters ? ignoreFiltersAlert : undefined}
        </div>
      )}
      isLoading={props.isLoading}
      description={(
        <SidebarTitleComponent
          containerStyle={locationDescriptionStyle}
          onEditClick={props.isMobileVersion ? undefined : props.onEditClick}
          onXClick={props.onClose}
          sidebarTitle={{
            semiHeader: props.isArtificial ? (
              <Inline
                gap={8}
                css={syntheticBoundaryNoteStyle}
              >
                <LimitNumberOfLinesComponent
                  maxNumberOfLines={1}
                >
                  <div>{t('SyntheticBoundary.Note')}</div>
                </LimitNumberOfLinesComponent>
                <HintTooltipComponent
                  tooltipContent={t('SyntheticBoundary.Tooltip')}
                  placement={TooltipPlacement.Left}
                />
              </Inline>
            ) : undefined,
            header: props.boundaryName,
            description: [t('Total Locations: {{locationsCount}}', {
              locationsCount: props.markersCount,
            })],
          }}
          indicator={boundaryIndicator}
          sidebarTitleStyle={getTotalLocationsStyle(theme)}
        />
      )}
      headerProps={{
        currentLocationIndex: 0,
        totalLocations: 1,
      }}
      menuProps={{
        roundButtonRightDetails: buttons,
        roundButtonLeftDetails: undefined,
        selectedButton: null,
      }}
    />
  );
};
