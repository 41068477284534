import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { createUuid } from '../../../../../_shared/utils/createUuid';
import { type ActiveMapElementsAction } from '../../../activeMapElements/activeMapElements.action';
import { type DrawingEditAction } from './drawingEdit.action';
import {
  FRONTEND_STATE_DRAWING_EDIT_CLEAR_SELECTED_DRAWING,
  FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES,
  FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT,
  FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
  FRONTEND_STATE_DRAWING_EDIT_UNDO_LAST_CHANGE,
} from './drawingEdit.actionTypes';
import { type DrawingEditState } from './drawingEdit.state';

const initialState: DrawingEditState = {
  selectedDrawing: null,
};

export const drawingEditReducer = (
  state = initialState, action: DrawingEditAction | ActiveMapElementsAction,
): DrawingEditState => {
  switch (action.type) {
    case FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING: {
      if (state.selectedDrawing?.id === action.payload.id) {
        return state;
      }

      return {
        ...state,
        selectedDrawing: {
          id: action.payload.id,
          Etag: createUuid(),
          snapshots: [action.payload.drawingItem],
        },
      };
    }

    case FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT: {
      if (state.selectedDrawing?.id !== action.payload.snapshot.value.id) {
        return state;
      }

      const newSnapshots = [...state.selectedDrawing.snapshots, action.payload.snapshot];

      return {
        ...state,
        selectedDrawing: {
          ...state.selectedDrawing,
          snapshots: newSnapshots,
        },
      };
    }

    case FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES: {
      if (state.selectedDrawing && notEmpty(state.selectedDrawing.snapshots)) {
        const newSnapshots = [state.selectedDrawing.snapshots[0]];

        return {
          selectedDrawing: {
            ...state.selectedDrawing,
            Etag: createUuid(),
            snapshots: newSnapshots,
          },
        };
      }
      return state;
    }

    case FRONTEND_STATE_DRAWING_EDIT_UNDO_LAST_CHANGE: {
      if (!state.selectedDrawing || state.selectedDrawing.snapshots.length < 2) {
        return state;
      }

      const newSnapshots = state.selectedDrawing.snapshots.slice(0, -1);

      return {
        selectedDrawing: {
          ...state.selectedDrawing,
          Etag: createUuid(),
          snapshots: newSnapshots,
        },
      };
    }

    case FRONTEND_STATE_DRAWING_EDIT_CLEAR_SELECTED_DRAWING: {
      return {
        ...state,
        selectedDrawing: null,
      };
    }

    default: {
      return state;
    }
  }
};
