import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback,
  useMemo,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { type QuadrilateralDimensions } from '../../../types/coordinateSystem/coordinateSystem';
import { type CustomMarkerSettings } from '../../../types/marker.types';
import { MARKER_SIZE_MAX } from '../../../utils/markers/markers.constants';
import { ensureMaxDimensions } from '../marker.helpers';

const deleteButtonWrapperStyle = css({
  display: 'none',
});

const wrapperStyle = css({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover .button-wrapper': {
    display: 'block',
  },
});

const positionerStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions }) => css({
  position: 'relative',
  width: dimensions.width,
  height: dimensions.height,
});

const imageStyle = ({ dimensions }: { dimensions: QuadrilateralDimensions}) => css({
  position: 'absolute',
  height: `${dimensions.height}px`,
  width: `${dimensions.width}px`,
});

const sideOptionButtonStyle = ({ top, right }: { top: boolean; right: boolean }) => css({
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '24px',
  height: '24px',
  right: right ? '0px' : 'auto',
  left: right ? 'auto' : '0px',
  top: top ? '0px' : 'auto',
  bottom: top ? 'auto' : '0px',
  boxShadow: '0px 2px 4px rgba(105, 112, 117, 50%)',
  fontSize: '12px',
});

type CustomMarkerComponentProps = {
  className?: string;
  marker: CustomMarkerSettings;
  maxDimensions: QuadrilateralDimensions;
  maxSize?: number;
  maxSizeEasing?: boolean;
  size?: number;

  onDelete?: () => void;
};

export const CustomMarkerComponent: React.FC<CustomMarkerComponentProps> = props => {
  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const onDelete = props.onDelete;
    onDelete?.();
  }, [props.onDelete]);

  const [dimensions] = useMemo(() => ensureMaxDimensions({
    anchorOffsets: { xOffset: 0, yOffset: 0 },
    dimensions: props.marker?.dimensions || 0,
    markerTextureOffsets: { bottom: 0, left: 0, right: 0, top: 0 },
    maxDimensions: props?.maxDimensions,
    ...(props.size ? {
      sizeData: {
        size: props.size,
        maxSize: props.maxSize ?? MARKER_SIZE_MAX,
        applyEasing: props.maxSizeEasing,
      },
    } : {}),
  }), [props.marker?.dimensions, props?.maxDimensions, props.maxSize, props.maxSizeEasing, props.size]);

  return (
    <div
      css={wrapperStyle}
      className={props.className}
    >
      <div css={positionerStyle({ dimensions })}>
        <img
          css={imageStyle({ dimensions })}
          alt={`custom marker ${props.marker.fileId}`}
          src={props.marker?.path}
        />
      </div>
      {props.onDelete && (
        <div
          css={deleteButtonWrapperStyle}
          className="button-wrapper"
        >
          <RoundButtonComponent
            css={sideOptionButtonStyle({ top: false, right: true })}
            icon={faTrash}
            buttonStyle={RoundButtonStyle.DangerInverted}
            onClick={onDelete}
            size={RoundButtonSize.Small}
          />
        </div>
      )}
    </div>
  );
};
