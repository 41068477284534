import { convertGeometryToMultiPolygon } from '~/_shared/types/polygon/polygon.utils';
import { computeLatLngBoundsArea } from '~/_shared/utils/gis/boundingBox.helpers';
import { createLatLngBoundsFromPolygonPaths } from '~/_shared/utils/map/latLngBounds.factory';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { BOUNDARY_OUTLINE_MAXIMUM_NUMBER_OF_POLYGONS } from '~/boundary/boundary.constants';
import { i18n } from '~/i18nextSetup';
import {
  type BoundaryItemResponseBase, type BoundaryResponse,
} from '~/store/boundaries/boundaries.repository';
import {
  type BoundaryGroupBoundaryObject, type MapBoundaryGeometryResponse, type MapBoundaryQueryItemResponse,
  type MapBoundaryQueryResponse,
} from './boundaryItems.repository';
import { type BoundaryStateItem } from './boundaryItems.state';

export const createBoundaryStateItemFromBoundaryResponse = (response: BoundaryResponse) =>
  createBoundaryStateItemFromMapQueryResponseItem(response, response.boundary_id, response.zoom_level);

export const createBoundaryStateItemFromMapQueryResponseItem = (
  response: MapBoundaryQueryItemResponse,
  boundaryId: number,
  responseZoomLevel: number,
): BoundaryStateItem => {
  const multiPolygon = convertGeometryToMultiPolygon(response.geometry);
  const boundaryPaths = multiPolygon.map(polygon => {
    // perf. optimization only compute bounds on large multipolygons
    // bounds and bounds area are only used when polygon count is above max threshold
    const bounds = multiPolygon.length > BOUNDARY_OUTLINE_MAXIMUM_NUMBER_OF_POLYGONS
      ? createLatLngBoundsFromPolygonPaths(polygon.path)
      : null;

    return ({
      ...polygon,
      bounds,
      boundsArea: bounds && computeLatLngBoundsArea(bounds),
    });
  });

  const labelBoundaries = response.label_bounding_box ??
    (multiPolygon.length > 0 ? boundaryPaths[0]?.bounds : null);

  const polygonPathsForZoomLevels = new Map([[responseZoomLevel, boundaryPaths]]);

  return {
    displayName: resolveBoundaryDisplayName(response),
    id: boundaryId,
    paths: polygonPathsForZoomLevels,
    labelBoundaries: labelBoundaries ?? null,
    settings: {
      isArtificial: !!response.settings.is_artificial,
      style: response.settings.style ? {
        lineWidth: response.settings.style.line_width,
        lineColor: response.settings.style.line_color,
        color: response.settings.style.color,
        opacity: response.settings.style.opacity,
      } : undefined,
      translate: response.settings.translate,
      translateParams: response.settings.translate_params,
    },
    lastFetchTimestamp: Date.now(),
  };
};

export const resolveBoundaryDisplayName = (boundary: BoundaryItemResponseBase): string => {
  const { translate, translate_params, is_artificial } = boundary.settings;

  if (!translate) {
    if (is_artificial) {
      return i18n.t('artificialAreaCode', { areaCode: boundary.display_name.substring(0, 3) });
    }
    return boundary.display_name;
  }

  if (notNullOrUndefined(translate_params)) {
    if (boundary.display_name === 'custom-boundary-name-numbered' && translate_params?.position) {
      return i18n.t('custom-boundary-name-numbered', { position: translate_params?.position });
    }

    if (notNullOrUndefined(translate_params?.from) && notNullOrUndefined(translate_params?.to)) {
      return i18n.t(boundary.display_name, {
        valueFrom: `${translate_params.from}${translate_params.unit ?? ''}`,
        valueTo: `${translate_params.to}${translate_params.unit ?? ''}`,
      });
    }
  }

  return i18n.t(boundary.display_name);
};

export const mapBoundaryItemsResponseToBoundaryStateItems = (
  responseObject: BoundaryGroupBoundaryObject,
  zoomLevel: number,
): BoundaryStateItem[] => {
  const items: BoundaryStateItem[] = [];

  for (const [boundaryIdString, queryItemResponse] of Object.entries(responseObject)) {
    const boundaryId = +boundaryIdString;

    const item = createBoundaryStateItemFromMapQueryResponseItem(
      queryItemResponse,
      boundaryId,
      zoomLevel,
    );

    if (item) {
      items.push(item);
    }
  }

  return items;
};

export type ResponseToStateMappingType = { boundaryGroupId: number; stateItems: BoundaryStateItem[]; zoomLevel: number; continuationToken: string | undefined; done: boolean };

export const mapGeometryResponseToStateData = (responseData: MapBoundaryGeometryResponse[]): ResponseToStateMappingType[] => {
  const result: ResponseToStateMappingType[] = [];

  for (const { geometries } of responseData) {
    const geometryKeys = Object.keys(geometries);

    for (const key of geometryKeys) {
      const boundaryGroupId = +key;
      if (isNaN(boundaryGroupId)) {
        continue;
      }

      const responseObject = geometries[boundaryGroupId];
      if (!responseObject) {
        continue;
      }

      const stateItems = mapBoundaryItemsResponseToBoundaryStateItems(responseObject, geometries.zoom_level);
      result.push({
        boundaryGroupId,
        stateItems,
        zoomLevel: 0,
        continuationToken: undefined,
        done: true,
      });
    }
  }
  return result;
};

export const mapQueryResponseToStateData = (responseData: { data: MapBoundaryQueryResponse }): ResponseToStateMappingType[] => {
  const result: ResponseToStateMappingType[] = [];

  for (const response of responseData.data.list) {
    const geometryKeys = Object.keys(response);

    for (const key of geometryKeys) {
      const boundaryGroupId = +key;
      if (isNaN(boundaryGroupId)) {
        continue;
      }

      const responseObject = response[boundaryGroupId];
      if (!responseObject) {
        continue;
      }

      const stateItems = mapBoundaryItemsResponseToBoundaryStateItems(responseObject, response.zoom_level);
      result.push({
        boundaryGroupId,
        stateItems,
        zoomLevel: response.zoom_level,
        continuationToken: response.continuation_token,
        done: response.done,
      });
    }
  }

  return result;
};
