import { css } from '@emotion/react';
import {
  type FC, type ReactNode, useMemo,
} from 'react';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import {
  getContainerDimension, TinyMapPlaceholderComponent, TinyMapPlaceholderSize,
} from '~/_shared/baseComponents/tinyMapPlaceholder';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { LabelOrMarkerVisualizerContainer } from '~/_shared/labelOrMarkerVisualizer/labelOrMarkerVisualizer.container';
import { mq } from '~/_shared/style.mixins';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { changeColorAlpha } from '~/_shared/utils/colors/colors.helpers';
import { MOBILE_VERSION_WIDTH_THRESHOLD } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { DEFAULT_MARKER_SIZE } from '~/_shared/utils/markers/markerVisualSettings.constants';
import {
  ColorPickerComponent, DEFAULT_COLOR_TILE_WIDTH,
} from '~/boundary/settings/colorPicker/colorPicker.component';

type NumericalGroupSettingsTableRowProps = Readonly<{
  color: ColorResult;
  data: ReactNode;
  index: number;
  isLast?: boolean;
  markerSettings: MarkerSettings;

  onColorChange: (color: ColorResult) => void;
}>;

const LINE_HEIGHT = 56;
const tableDataRowStyle = ({ theme }: ThemeProps) => css({
  borderTop: `1px solid ${theme.borderColors.primary}`,
  height: LINE_HEIGHT,
});

const tableFillColorWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const TABLE_DATA_PADDING = 8;
const markerStyle = ({ isLast }: {isLast: boolean}) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: LINE_HEIGHT - 2 * TABLE_DATA_PADDING + 1,
  marginLeft: isLast ? DEFAULT_COLOR_TILE_WIDTH : 0,
  width: DEFAULT_MARKER_SIZE,
  position: 'relative',
});

const tableDataStyle = css({
  padding: TABLE_DATA_PADDING,
  '&:first-of-type': {
    paddingLeft: 16,
  },
});

const tableDataLowPriorityColumnStyle = css(tableDataStyle, {
  display: 'none',

  [mq(MOBILE_VERSION_WIDTH_THRESHOLD)]: {
    display: 'table-cell',
  },
});

export const NumericalGroupSettingsTableRowComponent: FC<NumericalGroupSettingsTableRowProps> = (props) => {
  const theme = useTheme();

  const changeColorOpacity = (alpha: readonly number[]) => {
    const newColor = changeColorAlpha(props.color.rgb, (alpha[0] ?? 100) / 100);
    props.onColorChange(newColor);
  };

  const maxMarkerDimensions = useMemo(() => ({
    height: getContainerDimension(TinyMapPlaceholderSize.Large),
    width: getContainerDimension(TinyMapPlaceholderSize.Large),
  }), []);

  return (
    <tr css={tableDataRowStyle({ theme })}>
      <td css={tableDataStyle}>
        <div css={tableFillColorWrapperStyle}>
          <RowNumber number={props.index} />

          {!props.isLast && (
            <ColorPickerComponent
              displayAlpha={false}
              onChange={props.onColorChange}
              selectedColor={props.color}
            />
          )}

          {props.markerSettings && (
            <div css={markerStyle({ isLast: !!props.isLast })}>
              <TinyMapPlaceholderComponent size={TinyMapPlaceholderSize.Large}>
                <LabelOrMarkerVisualizerContainer
                  maxDimensions={maxMarkerDimensions}
                  markerSettings={{
                    ...props.markerSettings,
                    useMarker: true,
                  }}
                  maxSize={props.isLast ? 140 : undefined}
                  visualSettingsType={MarkerSettingType.Marker}
                />
              </TinyMapPlaceholderComponent>
            </div>
          )}
        </div>
      </td>

      <td css={tableDataLowPriorityColumnStyle}>
        {!props.isLast && (
          <SliderWithValueLabelsComponent
            value={[(props.color.rgb?.a ?? 1) * 100]}
            onChange={changeColorOpacity}
            currentValueOnTheRight
            valueSuffix="%"
          />
        )}
      </td>

      <td css={tableDataStyle}>
        {props.data}
      </td>
    </tr>
  );
};

const tableCounterStyle = css({
  marginRight: 12,
});

const RowNumber: FC<{ number: number }> = ({ number }) => {
  const theme = useTheme();
  const circleColors = useMemo(() => ({
    fillColor: theme.backgroundColors.secondaryHover,
    fontColor: theme.textColors.primary,
  }), [theme.backgroundColors.secondaryHover, theme.textColors.primary]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={tableCounterStyle}
      size={24}
    >
      {number}
    </NumberInCircleComponent>
  );
};
