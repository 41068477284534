import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useTranslation } from '~/_shared/utils/hooks';

export type PrintExportFileProps = Readonly<{
  onExportFileClick: () => void;
}>;

export const ExportFileButtonComponent: FC<PrintExportFileProps> = (props) => {
  const [t] = useTranslation();

  return (
    <RoundButtonWithTextComponent
      buttonStyle={RoundButtonStyle.Quaternary}
      icon={faFileExport}
      onClick={props.onExportFileClick}
      text={t('Export File')}
    />
  );
};

ExportFileButtonComponent.displayName = 'ExportFileButtonComponent';
