import { css } from '@emotion/react';
import type { ReactNode } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { AuthorizationPageWrapperComponent } from '~/authorization/pageWrapper/authorizationPageWrapper.component';
import {
  PasswordResetFormComponent, type PasswordResetFormProps,
} from '~/authorization/passwordResetPage/passwordResetForm.component';

const passwordFormContainerStyle = css({
  width: '100%',
});

const textContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const messageStyle = ({ color }: { color?: string }) => css({
  marginTop: 50,
  color: color || 'inherit',
  textAlign: 'left',
});

type SalesforceRegistrationFinishPageComponentProps = Readonly<{
  isLoading: boolean;
  passwordError: ReactNode;

  onSubmit: PasswordResetFormProps['onSubmit'];
}>;

export const SalesforceRegistrationFinishPageComponent = (props: SalesforceRegistrationFinishPageComponentProps) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <AuthorizationPageWrapperComponent>

      <div css={textContainerStyle}>
        <p css={messageStyle({ color: theme.textColors.primary })}>
          {t('salesforce.registerFinish.instructions')}
        </p>
      </div>

      <div css={passwordFormContainerStyle}>
        <PasswordResetFormComponent
          isLoading={props.isLoading}
          onSubmit={props.onSubmit}
          error={props.passwordError}
          submitSuccessText={t('Loading...')}
          submitButtonText={t('Proceed')}
        />
      </div>

    </AuthorizationPageWrapperComponent>
  );
};
