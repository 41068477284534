import { type BoundaryFilterRequest } from '~/spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '~/spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { SearchMatchingBehaviour } from '../../../_shared/constants/searchMatchingBehaviour.enum';
import { type FilterTree } from '../../../_shared/types/filterTree.types';
import { GroupingType } from '../../../_shared/types/grouping/grouping';
import {
  newPerSpreadsheetMap,
  type PerSpreadsheet, type PerSpreadsheetFilteredRowsIdsMap,
} from '../../../_shared/types/spreadsheet/spreadsheet.types';
import { type MapSettingsColumnsFilterState } from '../../mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { type MapSettingsFilteringState } from '../../mapSettings/filtering/mapSettingsFiltering.state';
import { type MapSettingsGroupingState } from '../../mapSettings/grouping/mapSettingsGrouping.state';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import {
  createFilterTreeForFilter, type MapSettingsFilter,
} from '../filterTree/spreadsheetDataFilterTree.factory';
import { createSearchFilterTreeRequestFromMapSettings } from '../search/spreadsheetData.search.helpers';
import {
  addMissingSpreadsheetData, generateHash, type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType, type SpreadsheetDataData, type SpreadsheetFilters, Unfiltered,
} from '../spreadsheetData.state';
import {
  type FilterTreeMapSettingsParams,
  getFilterTreeParamsFromMapSettings,
} from './useFilterTreeMapSettingsParams';

export type FilterTreeItemRequest = {
  filterHash: string;
  filterTree: FilterTree;
};

export type FilterTreeRequest = PerSpreadsheet<FilterTreeItemRequest>;

export type BoundaryBoundaryTerritoryFilterRequestItem = {
  filterHash: string;
  boundaryFilter?: BoundaryFilterRequest;
  boundaryTerritoryFilter?: BoundaryTerritoryFilterRequest;
};

export type BoundaryBoundaryTerritoryFilterRequest = PerSpreadsheet<BoundaryBoundaryTerritoryFilterRequestItem>;

type FilterTreeResults = {
  filterTree: FilterTreeRequest;
  searchFilterTree: FilterTreeRequest;
};

export const mergeFilterTrees = (filterTrees: Array<FilterTree | undefined>, type: 'or' | 'and'): FilterTree | null => {
  const mergedFilterTrees = filterTrees.reduce<FilterTree>((acc, item) => {
    if (item) {
      acc.children.push(...item.children);
    }
    return acc;
  }, {
    type,
    children: [],
  });

  if (mergedFilterTrees.children.length === 0) {
    return null;
  }
  else {
    return mergedFilterTrees;
  }
};

export const getMissingFilteringSpreadsheetData = (mapSettings: MapSettingsState, spreadsheetState: SpreadsheetDataData): MissingSpreadsheetData => {
  const missingFilteringSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  Object.keys(mapSettings.data.filtering.settings).forEach(spreadsheetIdAttr => {
    const spreadsheetId: number = parseInt(spreadsheetIdAttr, 10);
    const spreadsheetItem = mapSettings.data.filtering.settings[spreadsheetId];
    Object.keys(spreadsheetItem).forEach(columnId => {
      const item = spreadsheetItem[columnId];

      if (item.type === DataType.TEXT || spreadsheetState.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[item.type]) {
        return;
      }

      addMissingSpreadsheetData(missingFilteringSpreadsheetData, {
        spreadsheetId,
        columnId,
        filterHashOrUnfiltered: Unfiltered,
        dataType: item.type,
        spreadsheetDataToFetchExtra: {},
      });
    });
  });

  return missingFilteringSpreadsheetData;
};

export const getAllFilterTreeRequestsFromMapSettings = (
  filterTreeParams: FilterTreeMapSettingsParams, spreadsheetIds: number[]
): FilterTreeResults => {
  const filterTree = createFilterTreeRequestFromMapSettings(filterTreeParams, []);
  const searchFilterTree: FilterTreeRequest = filterTreeParams.search.selectedMatchingBehaviour === SearchMatchingBehaviour.ShowOnlyMatches
    ? createSearchFilterTreeRequestFromMapSettings(filterTreeParams, spreadsheetIds)
    : newPerSpreadsheetMap();

  return {
    filterTree,
    searchFilterTree,
  };
};

export const getCombinedFilterTreeForSpreadsheetId = (
  combinedFilterTree: FilterTreeResults, spreadsheetId: number
): FilterTreeItemRequest | null => {
  const filtersCombinedFilterHash = (combinedFilterTree.filterTree[spreadsheetId]?.filterHash ?? '') +
    (combinedFilterTree.searchFilterTree[spreadsheetId]?.filterHash ?? '');

  const mergedFilterTreeRequest = mergeFilterTrees(
    [combinedFilterTree.filterTree[spreadsheetId]?.filterTree, combinedFilterTree.searchFilterTree[spreadsheetId]?.filterTree],
    'and'
  );

  if (!mergedFilterTreeRequest) {
    return null;
  }

  return {
    filterHash: filtersCombinedFilterHash,
    filterTree: mergedFilterTreeRequest,
  };
};

export const createFilterTreeRequestFromMapSettings = (params: FilterTreeMapSettingsParams,
  excludedDataTypes: DataType[]): FilterTreeRequest => {
  const { columnsFilter, filtering, grouping } = params;

  // get filter settings to see what kind of filters are available
  const perSpreadsheetValues: { [spreadsheetId: number]: FilterTree } = {};

  const turnedOnFiltersWithValues: MapSettingsFilter[] = getActiveFilters({
    columnsFilter,
    filtering,
    grouping,
    excludedDataTypes,
    includeActiveGroupingColumns: true,
  });

  const perSpreadsheetFilterHash = getHashForTurnedOnFilters(
    turnedOnFiltersWithValues,
    columnsFilter,
    excludedDataTypes,
  );

  for (const filter of turnedOnFiltersWithValues) {
    const filterItem = createFilterTreeForFilter(
      filter,
      columnsFilter,
      grouping.activeGroupColumns
    );

    if (excludedDataTypes.includes(filter.dataType) || !filterItem) {
      continue;
    }

    perSpreadsheetValues[filter.spreadsheetId] = {
      type: 'and',
      children: [
        ...(perSpreadsheetValues[filter.spreadsheetId])?.children ?? [],
        filterItem,
      ],
    };
  }

  const results: FilterTreeRequest = newPerSpreadsheetMap();

  Object.keys(perSpreadsheetValues).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const filterHash = perSpreadsheetFilterHash[spreadsheetId];

    if (filterHash) {
      results[spreadsheetId] = {
        filterHash,
        filterTree: perSpreadsheetValues[spreadsheetId],
      };
    }
  });

  return results;
};
export const getMissingFilteredSpreadsheetData = (mapSettings: MapSettingsState, spreadsheetFilters: SpreadsheetFilters):
MissingSpreadsheetData => {
  const missingFilteredSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  // get filter hash for current map settings
  const filterTreeParams = getFilterTreeParamsFromMapSettings(mapSettings.data);
  const request = createFilterTreeRequestFromMapSettings(filterTreeParams, [DataType.GROUP]);

  Object.keys(request).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;
    const requestItem = request[spreadsheetId];

    if (requestItem && !spreadsheetFilters[spreadsheetId]?.[requestItem.filterHash]) {
      missingFilteredSpreadsheetData.filterDetails = {
        ...missingFilteredSpreadsheetData.filterDetails,
        [spreadsheetId]: {
          ...missingFilteredSpreadsheetData.filterDetails?.[spreadsheetId],
          [requestItem.filterHash]: requestItem.filterTree,
        },
      };
    }
  });

  return missingFilteredSpreadsheetData;
};

// get ONLY filters/active groups that actually have selected values
export const getActiveFilters = (params: {
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
  excludedDataTypes: DataType[];
  includeActiveGroupingColumns: boolean;
}): MapSettingsFilter[] => {
  const filters: MapSettingsFilter[] = [];

  Object.keys(params.filtering.settings).forEach((spreadsheetIdString) => {
    const spreadsheetId = +spreadsheetIdString;

    Object.keys(params.filtering.settings[spreadsheetId]).forEach(columnId => {
      const dataTypeObj = params.filtering.settings[spreadsheetId][columnId];
      if (params.excludedDataTypes.includes(dataTypeObj.type) || !dataTypeObj) {
        return;
      }

      if (dataTypeObj.type === DataType.GROUP) {
        filters.push({
          spreadsheetId,
          columnId,
          dataType: DataType.GROUP,
          extra: {
            type: GroupingType.Text,
            spreadsheetId,
            columnId,
          },
        });
      }
      else {
        filters.push({
          spreadsheetId,
          columnId,
          dataType: dataTypeObj.type,
        });
      }
    });
  });

  if (!params.excludedDataTypes.includes(DataType.GROUP) && params.includeActiveGroupingColumns) {
    for (const activeGroupingColumn of params.grouping.activeGroupColumns) {
      filters.push({
        spreadsheetId: activeGroupingColumn.spreadsheetId,
        columnId: activeGroupingColumn.columnId,
        dataType: DataType.GROUP,
        extra: activeGroupingColumn,
      });
    }
  }

  return filters.filter(filter => {
    const filterValues = params.columnsFilter[filter.spreadsheetId]
      ?.[filter.columnId];

    if (!filterValues) {
      return false;
    }

    let isValidFilter = false;

    Object.keys(filterValues).forEach(dataTypeString => {
      const dataType: DataType = dataTypeString as DataType;

      if (params.excludedDataTypes.includes(dataType)) {
        return;
      }

      if (dataType === DataType.GROUP) {
        const numericValues = filterValues?.[DataType.GROUP]?.numeric ?? {};
        const textValues = filterValues?.[DataType.GROUP]?.text ?? {};

        if (Object.keys(numericValues).length > 0 || Object.keys(textValues).length > 0) {
          isValidFilter = true;
        }

        return;
      }

      const filterDataTypeValues = filterValues?.[dataType]?.values;

      if (Object.keys(filterDataTypeValues ?? {}).length > 0) {
        isValidFilter = true;
      }
    });

    return isValidFilter;
  });
};

export const getDataRowsForActiveFilters = (params: {
  filters: SpreadsheetFilters;
  columnsFilter: MapSettingsColumnsFilterState;
  filtering: MapSettingsFilteringState;
  grouping: MapSettingsGroupingState;
  excludedDataTypes: DataType[];
}): PerSpreadsheetFilteredRowsIdsMap => {
  const results: PerSpreadsheetFilteredRowsIdsMap = newPerSpreadsheetMap();

  const turnedOnFilters = getActiveFilters({ ...params, includeActiveGroupingColumns: true });
  const filterHashes = getHashForTurnedOnFilters(
    turnedOnFilters,
    params.columnsFilter,
    params.excludedDataTypes
  );

  Object.keys(filterHashes).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    const spreadsheetFilterHash = filterHashes[spreadsheetId];

    const data = spreadsheetFilterHash && params.filters[spreadsheetId]?.[spreadsheetFilterHash];

    if (!data) {
      // this way we prevent do display all the available data in case the filtering data isn't fetched yet
      results[spreadsheetId] = {};
      return;
    }

    results[spreadsheetId] = data;
  });

  return results;
};

const getHashForTurnedOnFilters = (turnedOnFilters: MapSettingsFilter[],
  filtersState: MapSettingsColumnsFilterState, excludedDataTypes: DataType[]): PerSpreadsheet<string> => {
  const perSpreadsheetHashList: PerSpreadsheet<Array<string | number>> = newPerSpreadsheetMap();

  for (const filter of turnedOnFilters) {
    const filterValues = filtersState[filter.spreadsheetId]?.[filter.columnId];
    const filterHashList: Array<string | number> = [];

    if (!filterValues) {
      continue;
    }

    Object.keys(filterValues).forEach((dataTypeString) => {
      const dataType: DataType = dataTypeString as DataType;

      if (excludedDataTypes.includes(dataType)) {
        return;
      }

      filterHashList.push(filter.columnId, filter.dataType);
      filterHashList.push(JSON.stringify(filterValues));
    });

    if (filterHashList.length > 0) {
      if (!perSpreadsheetHashList[filter.spreadsheetId]) {
        perSpreadsheetHashList[filter.spreadsheetId] = ['filtering'];
      }

      perSpreadsheetHashList[filter.spreadsheetId] =
        perSpreadsheetHashList[filter.spreadsheetId]?.concat(filterHashList);
    }
  }

  return Object.keys(perSpreadsheetHashList).reduce<PerSpreadsheet<string>>((acc, spreadsheetIdString) => {
    const spreadsheetId = +spreadsheetIdString;
    const hashListItem = perSpreadsheetHashList[spreadsheetId];

    if (hashListItem) {
      acc[spreadsheetId] = generateHash(hashListItem.join('-'));
    }

    return acc;
  }, newPerSpreadsheetMap());
};
