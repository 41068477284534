export type MarkerZIndex = Readonly<{
  shadow: number;
  highlight: number;
  subMarker: number;
  marker: number;
  markerLabelText: number;
  labelAbove: number;
  labelAboveText: number;
}>;

export const markerSubEntityZIndexOffsets: Omit<MarkerZIndex, 'shadow'> = { // Shadows are in a lower layer
  highlight: 0,
  subMarker: 1,
  marker: 2,
  markerLabelText: 3,
  labelAbove: 4,
  labelAboveText: 5,
};

export const markerSubEntityCount = Object.keys(markerSubEntityZIndexOffsets).length;
