import { css } from '@emotion/react';
import {
  type FC, useEffect, useMemo, useState,
} from 'react';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { boundariesToDropDownOptions } from '~/boundary/boundary.helpers';
import { translateBoundaryTerritoryDisplayName } from '~/boundary/settings/boundarySettings.helpers';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '../../../_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type ModalProps } from '../../../modal/modalType.enum';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroupSettings,
} from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  BoundaryTerritoryEditSubmitAction,
  getBoundaryTerritoryEditSubmitActionOptions,
} from './boundaryTerritoryEditSubmitModal.helpers';

type BoundaryTerritoryEditSubmitModalProps = ModalProps<{
  onSubmit: (results: BoundaryTerritoryEditSubmitResults) => void;
  isLoading: boolean;
  boundaryTerritories: ReadonlyArray<BoundaryTerritory>;
  boundaryTerritoryGroupSettings: BoundaryTerritoryGroupSettings;
}>;

type BoundaryTerritoryEditResultsCreateNew = {
  action: BoundaryTerritoryEditSubmitAction.CreateNew;
};

type BoundaryTerritoryEditResultsRemoveFromExisting = {
  action: BoundaryTerritoryEditSubmitAction.RemoveFromExisting;
};
type BoundaryTerritoryEditResultsAddToExisting = {
  action: BoundaryTerritoryEditSubmitAction.AddToExisting;
  selectedBoundaryTerritoryId: string;
};

export type BoundaryTerritoryEditSubmitResults =
  BoundaryTerritoryEditResultsCreateNew |
  BoundaryTerritoryEditResultsRemoveFromExisting |
  BoundaryTerritoryEditResultsAddToExisting;

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
});

const dropdownTriggerStyle = css({
  height: 40,
});

const submitButtonStyles = css({
  height: 40,
});

const contentStyle = css({
  overflow: 'hidden',
});

const modalContentStyle = css({
  padding: '15px 20px 10px',
});

export const BoundaryTerritoryEditSubmitModalComponent: FC<BoundaryTerritoryEditSubmitModalProps> = (props) => {
  const [action, setAction] = useState<BoundaryTerritoryEditSubmitAction | null>(null);
  const [selectedBoundaryTerritoryId, setSelectedBoundaryTerritoryId] = useState<string | null>(null);
  const [t] = useTranslation();

  const boundaryTerritoriesDropdownOptions = useMemo(() => {
    const boundaryTerritories = props.boundaryTerritories.map(boundaryTerritory => {
      return {
        ...boundaryTerritory,
        displayName: translateBoundaryTerritoryDisplayName(boundaryTerritory, t),
        id: boundaryTerritory.boundaryTerritoryId,
      };
    });

    const boundaryTerritoryColorMap = new Map(boundaryTerritories.map(boundaryTerritory => {
      return [boundaryTerritory.id, boundaryTerritory.style];
    }));

    return boundariesToDropDownOptions(boundaryTerritories, {
      color: props.boundaryTerritoryGroupSettings.style.lineColor,
      width: props.boundaryTerritoryGroupSettings.style.lineWidth,
    }, boundaryTerritoryColorMap);
  }, [props.boundaryTerritories, props.boundaryTerritoryGroupSettings, t]);

  const actionOptions = useMemo(() =>
    getBoundaryTerritoryEditSubmitActionOptions(
      t,
      boundaryTerritoriesDropdownOptions.length > 0,
      boundaryTerritoriesDropdownOptions.length > 0
    ), [t, boundaryTerritoriesDropdownOptions.length]);

  const isSubmitDisabled = props.isLoading || (action === null) ||
    (action === BoundaryTerritoryEditSubmitAction.AddToExisting && selectedBoundaryTerritoryId === null);

  const onSubmit = () => {
    switch (action) {
      case BoundaryTerritoryEditSubmitAction.CreateNew: {
        props.onSubmit({
          action: BoundaryTerritoryEditSubmitAction.CreateNew,
        });
        break;
      }

      case BoundaryTerritoryEditSubmitAction.RemoveFromExisting: {
        props.onSubmit({
          action: BoundaryTerritoryEditSubmitAction.RemoveFromExisting,
        });
        break;
      }

      case BoundaryTerritoryEditSubmitAction.AddToExisting: {
        if (selectedBoundaryTerritoryId === null) {
          break;
        }

        props.onSubmit({
          action: BoundaryTerritoryEditSubmitAction.AddToExisting,
          selectedBoundaryTerritoryId,
        });
        break;
      }

      default: {
        return;
      }
    }
  };

  useEffect(() => {
    const [firstActionOption] = actionOptions;
    if (firstActionOption) {
      setAction(firstActionOption.value);
    }
  }, [actionOptions]);

  let labelCount = 1;
  return (
    <ModalComponent
      caption={t('Boundary Selection Tool')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      contentStyle={modalContentStyle}
      confirmButton={(
        <ButtonComponent
          css={submitButtonStyles}
          text={t('Done')}
          onClick={onSubmit}
          isDisabled={isSubmitDisabled}
        />
      )}
      additionalContent={props.isLoading ? <OverlayLoaderComponent /> : null}
    >
      <div css={contentStyle}>
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t('What would you like to do with the selected boundaries?')}
          </div>

          <RegularDropdownComponent
            onChange={setAction}
            options={actionOptions}
            value={action}
            triggerStyle={dropdownTriggerStyle}
            inPortal
            placeholder={t('Select Action')}
          />
        </div>

        {boundaryTerritoriesDropdownOptions.length > 0 && action === BoundaryTerritoryEditSubmitAction.AddToExisting && (
          <div css={panelDropdownSectionStyle}>
            <div css={labelStyle}>
              {labelCount++}. {t('Select Fill Group')}
            </div>

            <AutocompleteComponent
              onChange={setSelectedBoundaryTerritoryId}
              options={boundaryTerritoriesDropdownOptions}
              value={selectedBoundaryTerritoryId}
              inPortal
              placeholder={t('Select Fill Group')}
            />
          </div>
        )}
      </div>
    </ModalComponent>
  );
};
