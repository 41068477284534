import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  convertColorToWebGLColor, webGLColorToString,
} from '~/_shared/utils/colors/colors.helpers';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import type { PieChartItem } from '~/_shared/utils/maptive/pieCharts/pieCharts.types';
import {
  getDefaultStandardMarkerColor, getMarkerVisuals,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { opaqueGray } from '~/_shared/utils/webgl/webglColor.helpers';
import { type AppState } from '~/store/app.store';
import { fileUrlsSelector } from '~/store/frontendState/fileUrls/fileUrls.selector';
import { mapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import { mapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { mapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { selectPerGroupVisualSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import {
  mapSettingsStackMarkersWithPieChartsSelector, mapSettingsUnstackMarkerStateSelector,
} from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import { mapSettingsUnstackMarkerSelector } from '~/store/mapSettings/markers/mapSettingsMarkersUnstacking.selectors';
import {
  selectFilteredLatLngSpreadsheetData,
  selectLatLngSpreadsheetData,
  selectSpreadsheetData,
  selectVisibleFilteredSpreadsheetRowIds,
  selectVisibleRowsWithoutWaypoints,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type LatLngRowData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { type StackedMarker } from '../manager/mapMarkerManager';
import { createWebGlPieChartItems } from './mapClustersManager';
import {
  createStackedMarkerId, createUnstackedMarkerId, type StackedMarkerId,
} from './StackedMarkerId.type';

type StackedMarkersSelectorType = (state: AppState, unstack?: boolean, markers?: ReadonlyArray<LatLngRowData>) => ReadonlyMap<StackedMarkerId, StackedMarker>;
export const selectStackedMarkers: StackedMarkersSelectorType = createStateSelector(
  [
    state => selectSpreadsheetData(state).spreadsheetData,
    mapSettingsStackMarkersWithPieChartsSelector,
    mapSettingsGroupingActiveGroupColumnsSelector,
    mapSettingsMarkersGeneralSelector,
    fileUrlsSelector,
    mapSettingsFileAttachmentsMapSelector,
    selectPerGroupVisualSettings,
    state => state.spreadsheet.matchupData,
    (state, unstack?: boolean) => unstack ?? mapSettingsUnstackMarkerSelector(state),
    (state, _?: boolean, markers?: ReadonlyArray<LatLngRowData>) => markers ?? selectVisibleRowsWithoutWaypoints(state),
  ],
  (
    spreadsheetData,
    showPieCharts,
    activeGroupColumns,
    markerVisualSettings,
    files,
    fileAttachments,
    perGroupVisualSettings,
    matchupData,
    unstack,
    markers,
  ): ReadonlyMap<StackedMarkerId, StackedMarker> => {
    const defaultGeneralMarkerSettings = getMarkerVisuals({
      isLegend: false,
      mapSettingsGroupingState: { activeGroupColumns: [] },
      visualMarkersSettings: markerVisualSettings,
      files, fileAttachments,
    });

    const pieChartCreator = (markers: ReadonlyArray<SpreadsheetRowId>) =>
      markers.length > 1 && activeGroupColumns.length
        ? createWebGlPieChartItems({ markerIds: markers, activeGroupColumns, spreadsheetData, perGroupVisualSettings, matchupData })
        : undefined;

    const tieChartItem: Omit<PieChartItem, 'id'> = ({
      title: 'Tie',
      value: 1,
      rgbColor: createColor(webGLColorToString(opaqueGray)).rgb,
      color: opaqueGray,
      originalColor: opaqueGray,
      count: 1,
      size: 35,
      opacity: 1,
    });

    const pickMostPrevalent = (pieChartItems: ReadonlyArray<PieChartItem> | undefined) => {
      if (!pieChartItems?.length) {
        return;
      }

      return pieChartItems.reduce((prev, current) => {
        if (notNullsy(current.rank)) {
          return current.rank > (prev.rank ?? 0) ? current : prev;
        }

        if (current.count === prev.count) {
          return { ...tieChartItem, id: prev.id };
        }

        return (current.count > prev.count ? current : prev);
      });
    };

    const pickTheMostPrevalentColorOrDefault = (pieChartItems: ReadonlyArray<PieChartItem> | undefined): WebglColor => {
      return pickMostPrevalent(pieChartItems)?.originalColor || convertColorToWebGLColor(
        defaultGeneralMarkerSettings.marker?.selectedColor || getDefaultStandardMarkerColor()
      );
    };

    if (unstack) {
      return new Map(markers.map(m => [createUnstackedMarkerId(m), m] as const));
    }

    return new Map(Array.from(stackMarkers(markers))
      .map(([id, marker]) => {
        const pieCharts = pieChartCreator(marker.stackedMarkers);
        const newItem: StackedMarker = {
          ...marker,
          pieChartItems: showPieCharts ? pieCharts?.pieChartItems : undefined,
          secondaryPieChartItems: showPieCharts ? pieCharts?.secondaryPieChartItems : undefined,
          mainColor: pickTheMostPrevalentColorOrDefault(pieCharts?.pieChartItems),
          opacity: pickMostPrevalent(pieCharts?.pieChartItems)?.opacity,
          subMarkerColor: pickTheMostPrevalentColorOrDefault(pieCharts?.secondaryPieChartItems),
          subMarkerOpacity: pickMostPrevalent(pieCharts?.secondaryPieChartItems)?.opacity,
          subMarkerSize: pickMostPrevalent(pieCharts?.secondaryPieChartItems)?.size,
          groupId: pickMostPrevalent(pieCharts?.pieChartItems)?.id.toString(),
        };
        return [id, newItem];
      }));
  });

const stackMarkers = (markers: ReadonlyArray<LatLngRowData>) =>
  markers
    .reduce((result, marker) => {
      const key = createStackedMarkerId(marker);
      const existing = result.get(key);
      if (existing) {
        existing.stackedMarkers?.push(marker);
      }
      else {
        result.set(key, { ...marker, stackedMarkers: [marker] });
      }

      return result;
    }, new Map<string, LatLngRowData & { stackedMarkers: LatLngRowData[] }>());

const getMarkerStackCount = memoizeWeak((stackedMarkers: ReadonlyMap<StackedMarkerId, StackedMarker>) => {
  let count = 0;
  for (const markerStack of stackedMarkers.values()) {
    count += (markerStack.stackedMarkers?.length ?? 0) >= 2 ? 1 : 0;
  }
  return count;
});

const getNumberOfStackedMarkers = (unstackMarkers: boolean, stackedMarkers: ReturnType<typeof selectStackedMarkers>) => (
  unstackMarkers ? 0 : getMarkerStackCount(stackedMarkers)
);

const selectNumberOfStackedMarkers = (state: AppState): number =>
  getNumberOfStackedMarkers(mapSettingsUnstackMarkerSelector(state), selectStackedMarkers(state));

export const selectNumberOfStackedMarkersWithoutForcedUnstacking = (state: AppState): number => {
  const unstackMarkers = mapSettingsUnstackMarkerStateSelector(state);
  const latLngSpreadsheetData = selectLatLngSpreadsheetData(state, unstackMarkers);
  const visibleFilteredRowIds = selectVisibleFilteredSpreadsheetRowIds(state, latLngSpreadsheetData);
  const filteredLatLngRowData = selectFilteredLatLngSpreadsheetData(state, latLngSpreadsheetData, visibleFilteredRowIds);
  const latLngRowDataWithoutWaypoints = selectVisibleRowsWithoutWaypoints(state, filteredLatLngRowData);
  const stackedMarkers = selectStackedMarkers(state, unstackMarkers, latLngRowDataWithoutWaypoints);
  return getNumberOfStackedMarkers(unstackMarkers, stackedMarkers);
};

export const useSelectNumberOfStackedMarkers = () => useSelector(selectNumberOfStackedMarkers);
