import { type ThemeProps } from '../_shared/types/themeProps';

// The library we are using utilises the chart.js library.
// The returned object is typed directly according to the chart.js docu https://www.chartjs.org/docs/latest/general/options.htmlconst chartOptionsStyle = ({ theme }: ThemeProps) => {
export const chartOptionsStyle = ({ theme }: ThemeProps) => {
  return {
    plugins: {
      legend: {
        labels: { // the legend label
          color: theme.textColors.primary,
        },
      },
    },
  };
};

export const spiderWebChartOptionsStyle = ({ theme }: ThemeProps) => {
  const basicOptions = chartOptionsStyle({ theme });
  const spiderWebScaleOptions = {
    r: {
      angleLines: {
        color: theme.lineColors.basePrimary, // the main lines of the "web"
      },
      grid: {
        color: theme.lineColors.basePrimary, // the spiral lines of the "web"
      },
      pointLabels: {
        color: theme.textColors.primary, // the labels at the "web"
      },
      ticks: { // the numbers marking each spiral line of the "web"
        color: theme.textColors.primary,
        backdropColor: theme.backgroundColors.primary,
      },
    },
  };

  return {
    ...basicOptions,
    scales: {
      ...spiderWebScaleOptions,
    },
  };
};

export const barChartOptionsStyle = ({ theme }: ThemeProps) => {
  const gridLineOptions = {
    grid: {
      color: theme.lineColors.basePrimary, // the color of the line
    },
    ticks: {
      color: theme.textColors.primary, // the numbers marking each line
    },
  };

  return ({
    ...chartOptionsStyle({ theme }),
    scales: {
      x: gridLineOptions,
      y: gridLineOptions,
    },
  });
};

export const horizontalBarChartOptionsStyle = ({ theme }: ThemeProps) => ({
  ...barChartOptionsStyle({ theme }),
  indexAxis: 'y' as const,
});
