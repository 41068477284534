import { always } from '~/_shared/utils/function.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { getMainMenuItemTitle } from '~/mainMenu/mainMenu.helpers';
import { MainMenuItems } from '~/mainMenu/mainMenuItem.enum';
import { EMPTY_MESSAGE } from '~/map/settings/structure.constants';
import {
  filterEnabledPresentationalMapTools,
  publicMapSettingsMapToolsMenuOpenSelector,
  publicMapSettingsSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { isCurrentMapSnapshot } from '~/store/selectors/isCurrentMapSnapshot.selector';
import {
  type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from '../settingsAccordionData.type';
import { type SharedPresentationalMapsStructureProps } from './sharedPresentationalMaps.types';

const DISABLED_TOOLTIP_LEGEND_ONLY = 'This option is disabled while "Display Legend Only" is active';

const isMapToolsMenuOpenDisabled = createStateSelector([publicMapSettingsSelector], settings => {
  return settings.displayLegendOnly || !filterEnabledPresentationalMapTools(settings).length;
});

const disabledForMapSnapshotsSelector = createStateSelector([
  isCurrentMapSnapshot,
  (_state, message: string) => message,
], (isSnapshot, message) => isSnapshot ? {
  message,
  isActiveWhenDisabled: false,
} : EMPTY_MESSAGE);

const isLocationListDisabled = createStateSelector([publicMapSettingsSelector], settings => {
  return !settings.locationList;
});

const isLocationFinderDisabled = createStateSelector([publicMapSettingsSelector], settings => {
  return !settings.locationFinder;
});

const legendOnlyDisabledTooltipSelector = createStateSelector([
  publicMapSettingsSelector,
  (_state, t: TranslationFnc) => t,
], (settings, t) => ({
  message:
    settings.displayLegendOnly ? t(DISABLED_TOOLTIP_LEGEND_ONLY) : null,
}));

const grupingToolDisabledTooltipSelector = createStateSelector([
  publicMapSettingsSelector,
  (_state, t: TranslationFnc) => t,
], (settings, t) => {
  if (settings.displayLegendOnly) {
    return { message: t(DISABLED_TOOLTIP_LEGEND_ONLY) };
  }
  if (settings.legendReplacesGrouping) {
    return { message: t('This option is turned off when "Legend Replaces Grouping" is enabled') };
  }

  return EMPTY_MESSAGE;
});

const boundaryToolDisabledTooltipSelector = createStateSelector([
  publicMapSettingsSelector,
  (_state, t: TranslationFnc) => t,
], (settings, t) => {
  if (settings.displayLegendOnly) {
    return { message: t(DISABLED_TOOLTIP_LEGEND_ONLY) };
  }
  return EMPTY_MESSAGE;
});

const mapToolsMenuOpenDisabledTooltipSelector = createStateSelector([
  isMapToolsMenuOpenDisabled,
  (_state, t: TranslationFnc) => t,
], (menuDisabled, t) =>
  menuDisabled ? ({ message: t('This option is disabled while there are no tools set to be visible on a public map') }) : EMPTY_MESSAGE);

const defaultMapToolDisabledTooltipSelector = createStateSelector([
  isMapToolsMenuOpenDisabled,
  publicMapSettingsMapToolsMenuOpenSelector,
  (_state, t: TranslationFnc) => t,
], (menuDisabled, menuOpen, t) => {
  if (menuDisabled || !menuOpen) {
    return ({ message: t('This option is disabled while "Map  Tools Menu Open" is inactive.') });
  }
  return EMPTY_MESSAGE;
});

const locationListOpenDisabledTooltipSelector = createStateSelector([
  isLocationListDisabled,
  (_state, t: TranslationFnc) => t,
], (menuDisabled, t) => menuDisabled ? ({ message: t('Turn on location list to enable this option') }) : EMPTY_MESSAGE);

const locationFinderStartsOpenDisabledTooltipSelector = createStateSelector([
  isLocationFinderDisabled,
  (_state, t: TranslationFnc) => t,
], (menuDisabled, t) => menuDisabled ? ({ message: t('Turn on location finder to enable this option') }) : EMPTY_MESSAGE);

export const sharedPresentationalMapsStructure = (t: TranslationFnc, props: SharedPresentationalMapsStructureProps):
ReadonlyArray<SettingsAccordionSubItem> => {
  const { toggles, buttons, dropdowns } = props;

  const allItems: ReadonlyArray<SettingsAccordionSubItem | null> = [
    {
      label: t('Display Legend Only'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.DisplayLegendOnly'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.displayLegendOnly,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.Grouping, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.GroupingTool'),
      disabledTooltipSelector: state => grupingToolDisabledTooltipSelector(state, t),
      ...toggles.groupingTool,
    },
    {
      label: t('Legend Replaces Grouping'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LegendReplacesGrouping'),
      disabledTooltipSelector: state => legendOnlyDisabledTooltipSelector(state, t),
      ...toggles.legendReplacesGrouping,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.Filter, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.FilterTool'),
      disabledTooltipSelector: state => legendOnlyDisabledTooltipSelector(state, t),
      ...toggles.filterTool,
    },
    {
      label: t('Filter Menus Open'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.FilterMenusOpen'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.filterMenusOpen,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.Routing, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.RoutingDirections'),
      disabledTooltipSelector: state => legendOnlyDisabledTooltipSelector(state, t),
      ...toggles.routingDirections,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.Boundary, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.BoundaryTool'),
      disabledTooltipSelector: state => boundaryToolDisabledTooltipSelector(state, t),
      ...toggles.boundaryTool,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.Proximity, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.RadiusProximity'),
      disabledTooltipSelector: state => legendOnlyDisabledTooltipSelector(state, t),
      ...toggles.radiusProximity,
    },
    {
      label: getMainMenuItemTitle(MainMenuItems.HeatMap, t),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.HeatMappingTool'),
      disabledTooltipSelector: state => legendOnlyDisabledTooltipSelector(state, t),
      ...toggles.heatMappingTool,
    },
    {
      label: t('Map Tools Menu Open'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.MapToolsMenuOpen'),
      disabledTooltipSelector: state => mapToolsMenuOpenDisabledTooltipSelector(state, t),
      ...toggles.mapToolsMenuOpen,
    },
    {
      label: t('Default Tool'),
      type: SettingsAccordionSubItemType.DropDown,
      infoTooltip: t('mapSettingsInfo.DefaultOpenMapToolPresentationalMap'),
      disabledTooltipSelector: (state) => defaultMapToolDisabledTooltipSelector(state, t),
      ...dropdowns.defaultOpenMapToolPresentationalMap,
    },
    {
      label: t('Location List'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LocationList'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.locationList,
    },
    {
      label: t('Location List Open'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LocationListOpen'),
      disabledTooltipSelector: state => locationListOpenDisabledTooltipSelector(state, t),
      ...toggles.locationListOpen,
    },
    {
      label: t('Location Finder'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LocationFinder'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.locationFinder,
    },
    {
      label: t('Location Finder Starts Open'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LocationFinderStartsOpen'),
      disabledTooltipSelector: state => locationFinderStartsOpenDisabledTooltipSelector(state, t),
      ...toggles.locationFinderStartsOpen,
    },
    {
      label: t('Display Search Button'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.DisplaySearchBox'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.displaySearchBox,
    },
    {
      label: t('Search Bar Always Visible'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.SearchBarAlwaysVisible'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.searchBarAlwaysVisible,
    },
    {
      label: t('Allow Satellite View'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.AllowSatelliteView'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.allowSatelliteView,
    },
    {
      label: t('Show My Location'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.ShowMyLocation'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.showMyLocation,
    },
    {
      label: t('Show My Location Default'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.ShowMyLocationDefault'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.showMyLocationDefault,
    },
    /*
      We have decided to disable Map Image Export for presentational maps for beta release
      TODO: remove this some time after release of we decide to never use this
      {
        label: t('Map Image Export'),
        type: SettingsAccordionSubItemType.Toggle,
        infoTooltip: t('mapSettingsInfo.MapImageExport'),
        disabledTooltipSelector: always(EMPTY_MESSAGE),
        ...toggles.mapImageExport,
      }
    */
    {
      label: t('Distance Calculator'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.DistanceCalculator'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.distanceCalculator,
    },
    {
      label: t('Lasso Tool'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.LassoTool'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.lassoTool,
    },
    {
      label: t('Display Title & Description'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.DisplayTitleAndDescription'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.displayTitleAndDescription,
    },
    {
      label: t('Theme Style'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.themeStyle,
    },
    {
      label: t('Allow Street View'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.AllowStreetView'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.allowStreetView,
    },
    {
      label: t('Markers Visibility Button'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.MarkersVisibilityButton'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.markersVisibilityButton,
    },
    {
      label: t('Map Zoom'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.MapZoom'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.mapZoom,
    },
    {
      label: t('Restrict Zoom Levels'),
      textSelector: () => t('Open'),
      type: SettingsAccordionSubItemType.Button,
      infoTooltip: t('mapSettingsInfo.RestrictZoomLevels'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...buttons.restrictZoomLevels,
    },
    {
      label: t('Restrict Map Panning'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('mapSettingsInfo.RestrictMapPanning'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      ...toggles.restrictMapPanning,
    },
    {
      type: SettingsAccordionSubItemType.Button,
      label: t('Enable/Disable Columns'),
      disabledTooltipSelector: state => disabledForMapSnapshotsSelector(state, t('mapSettingsInfo.showHideDataDisabled')),
      infoTooltip: t('mapSettingsInfo.enableDisableColumns'),
      textSelector: () => t('Open'),
      ...buttons.showHideFilterColumns,
    },
    {
      type: SettingsAccordionSubItemType.Button,
      label: t('Show/Hide Data on Marker'),
      disabledTooltipSelector: state => disabledForMapSnapshotsSelector(state, t('mapSettingsInfo.showHideDataDisabled')),
      infoTooltip: t('mapSettingsInfo.showHideDataOnMarker'),
      textSelector: () => t('Open'),
      ...buttons.showHideDataOnMarker,
    },
    {
      type: SettingsAccordionSubItemType.Button,
      label: t('Show/Hide Data from Export'),
      disabledTooltipSelector: state => disabledForMapSnapshotsSelector(state, t('mapSettingsInfo.showHideDataDisabled')),
      infoTooltip: t('mapSettingsInfo.showHideDataFromExport'),
      textSelector: () => t('Open'),
      ...buttons.showHideDataFromExport,
    },
    buttons.presentationalDataExportAuthorize?.onClick ? {
      type: SettingsAccordionSubItemType.Button,
      label: t('mapSettingsInfo.ExportingDataFeatures'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      infoTooltip: t('mapSettingsInfo.ExportingDataFeatures'),
      textSelector: () => t('Authorize'),
      ...buttons.presentationalDataExportAuthorize,
    } : null,
    buttons.presentationalDataExportAllowTools?.onClick ? {
      type: SettingsAccordionSubItemType.Button,
      label: t('mapSettingsInfo.ExportingDataFeatures'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      infoTooltip: t('mapSettingsInfo.ExportingDataFeatures'),
      textSelector: () => t('Activate'),
      ...buttons.presentationalDataExportAllowTools,
    } : null,
    {
      type: SettingsAccordionSubItemType.Button,
      label: t('Embed Map'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
      infoTooltip: t('Embed Map on Website, Blog, etc.'),
      textSelector: () => t('Open'),
      ...buttons.embedMap,
    },
  ];

  return allItems.filter(notNullsy);
};
