import {
  type FC,
  Fragment,
  useCallback,
} from 'react';
import { noop } from '~/_shared/utils/function.helpers';
import { type MapListingItem } from '../item/mapListingItem';
import { type ChangePrivacyLevelProps } from '../useMapListingItems';
import {
  type MapPrivacyClickParams, MapRowComponent,
} from './mapRow/mapRow.component';

type TableDataProps = Readonly<{
  data: ReadonlyArray<MapListingItem>;
  currentUserId: number | null;
  onMapPrivacyClick: (data: MapPrivacyClickParams) => void;
  onMapSelectToggle: (mapId: number) => void;
  toggledMapIds: ReadonlyArray<number>;
  onShowSnapshotsToggle: (mapId: number) => void;
  onSnapshotSelectToggle: (params: Readonly<{ mapId: number; snapshotId: number }>) => void;
  selectedMapsIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  width: number;
  onShareSuccess: (props: ChangePrivacyLevelProps) => void;
}>;

export const TableDataComponent: FC<TableDataProps> = (props) => {

  const getOnSnapshotSelectToggle = useCallback((mapId: number, snapshotId: number) => () => {
    const onSnapshotSelectToggle = props.onSnapshotSelectToggle;
    return onSnapshotSelectToggle({ mapId, snapshotId });
  }, [props.onSnapshotSelectToggle]);

  return (
    <tbody>
      {props.currentUserId && props.data.map((item, index) => {
        const isMapSelected = props.selectedMapsIds.includes(item.id);
        const areSnapshotsExpanded = props.toggledMapIds.includes(item.id);

        return (
          <Fragment key={item.id}>
            <MapRowComponent
              areSnapshotsExpanded={areSnapshotsExpanded}
              index={index}
              isMapSelected={isMapSelected}
              isOwner={item?.ownerId === props.currentUserId}
              item={item}
              onMapPrivacyClick={props.onMapPrivacyClick}
              onMapSelectToggle={props.onMapSelectToggle}
              onShowSnapshotsToggle={props.onShowSnapshotsToggle}
              width={props.width}
              onShareSuccess={props.onShareSuccess}
            />

            {areSnapshotsExpanded && item.snapshots.map((snapshot, subIndex) => (
              <MapRowComponent
                areSnapshotsExpanded={false}
                index={subIndex}
                isMapSelected={props.selectedSnapshotIds.has(snapshot.id)}
                isOwner={snapshot.ownerId === props.currentUserId}
                item={snapshot}
                key={item.id + 'snapshot' + subIndex}
                onMapPrivacyClick={props.onMapPrivacyClick}
                onMapSelectToggle={getOnSnapshotSelectToggle(item.id, snapshot.id)}
                onShowSnapshotsToggle={noop}
                parentMapItem={item}
                width={props.width}
                onShareSuccess={props.onShareSuccess}
              />
            ))}
          </Fragment>
        );
      })}
    </tbody>
  );
};
