import { type ProximityStyles } from '../../types/proximity/proximity.types';

export const mapAreaBorder = {
  active: {
    minWidth: 6,
  },
} as const;

export const getOutlineProximityStyles = (isVisible: boolean): ProximityStyles => ({
  color: '#fff',
  borderColor: '#000',
  borderWidth: isVisible ? 1.5 : 0,
  fillOpacity: 0,
  borderOpacity: 1,
});
