import { css } from '@emotion/react';
import {
  faCog, faFillDrip, faPen, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { animated } from '@react-spring/web';
import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  RoundButtonComponent,
  RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  CheckboxComponent, TriStateCheckboxComponent,
} from '~/_shared/baseComponents/checkbox';
import { RadioComponent } from '~/_shared/baseComponents/radio/radio.component';
import { type TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { useHighlightAnimation } from '~/_shared/hooks/useHighlightAnimation';
import {
  BoundaryTerritoryListingExportLimit, BoundaryTerritoryListingLimit,
} from '~/boundary/constants';
import { useLoadMoreListing } from '~/boundary/hooks/useLoadMoreListing';
import { BoundaryRowLoadMoreComponent } from '~/sidebar/sidebarApps/mapTools/boundary/activeBoundary/boundaryItem/boundaryRowLoadMore.component';
import {
  BoundaryTerritoryType,
  type CalculateBucketFunction,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { boundaryTerritoryGroupInSidebarHighlighted } from '~/store/frontendState/mapTools/boundary/boundarySidebarState/boundarySidebarState.actionCreators';
import { type ColorResult } from '../../../../../_shared/components/colorPicker/colorPicker.types';
import { type Theme } from '../../../../../_shared/themes/theme.model';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { ActiveBoundaryFillInfoContainer } from './activeBoundaryFillInfo.container';
import { ActiveBoundaryHeaderComponent } from './activeBoundaryHeader.component';
import { BoundaryItemComponent } from './boundaryItem/boundaryItem.component';
import { BoundaryTerritoryComponent } from './boundaryItem/boundaryTerritory.component';
import { useWmsFeatureBlocker } from './boundaryItem/useWmsFeatureBlocker';
import { ActiveBoundaryContextMenuComponent } from './contextMenu/activeBoundaryContextMenu.component';

const actionsWrapperStyle = (theme: Theme) => css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.backgroundColors.secondary,
  boxSizing: 'border-box',
  padding: '5px 10px 5px 16px',
});

const toolbarButtonWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minHeight: 34,
});

const boundaryOptionsHeaderStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.secondary,
  borderTop: `1px solid ${theme.lineColors.basePrimary}`,
  boxSizing: 'border-box',
  padding: '8px 16px',
  width: '100%',
});

const boundaryOptionStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 0',
  width: '100%',
});

const primaryBoundaryLabelStyle = css({
  textTransform: 'uppercase',
  fontSize: '14px',
});

const checkboxStyle = (theme: Theme) => css({
  textTransform: 'uppercase',
  color: theme.textColors.secondary,
  fontSize: '12px',
});

const toolbarButtonStyle = css({
  marginLeft: 6,
});

export type BoundaryTerritoryKeySettings = Readonly<{
  isCustom: boolean;
  isEmpty: boolean;
  isVisible: boolean;
  label: string;
  selectedColor: ColorResult;
  lineColor: ColorResult;
  lineWidth: number;

  onBatchEditLocationsClick?: () => void;
  onDelete?: () => void;
  onExportData?: () => void;
  onExportContainedBoundaries?: () => void;
  onToggle: () => void;
}>;

export type BoundaryKeySettings = Readonly<{
  isCustom: boolean;
  isVisible: boolean;
  label: string;
  selectedColor: ColorResult;
  lineColor: ColorResult;
  lineWidth: number;

  onBatchEditLocationsClick?: () => void;
  onBoundarySettingsClick?: () => void;
  onExportDataInBoundaryClick?: () => void;
  onExportContainedBoundaries?: () => void;
  onManuallyEditPolygonClick?: () => void;
  onRemoveBoundaryClick?: () => void;
  onToggle: () => void;
  onZoomToBoundaryClick: () => void;
}>;

export type ActiveBoundaryProps = Readonly<{
  boundariesAsTerritories: boolean;
  boundaryKeySettings: ReadonlyArray<BoundaryKeySettings>;
  boundaryGroupId: number;
  boundaryTerritoryGroupId: number;
  boundaryTerritoryKeySettings: ReadonlyArray<BoundaryTerritoryKeySettings>;
  boundaryTerritoryType: BoundaryTerritoryType;
  bucketColumnId?: string;
  calculateBucketFunction?: CalculateBucketFunction;
  className?: string;
  demographicId?: string;
  name: string;
  hideLabels: boolean;
  hideWithoutData: boolean;
  ignoreFilters: boolean;
  isBoundaryGroupCustom: boolean;
  isAiGenerated: boolean;
  isPrimary: boolean;
  showAll: TriStateRange;
  legendMode?: boolean;
  showToolbar: boolean;
  isHighlighted?: boolean;

  onConvert?: () => void;
  onClone?: () => void;
  onExportClick?: () => void;
  onCustomizeFillClick: () => void;
  onCustomizeMetricsClick: () => void;
  onRemove: () => void;
  onSettingsClick: () => void;
  onStartEditClick: () => void;
  onToggleBoundariesAsTerritories?: () => void;
  onToggleHideLabels?: () => void;
  onToggleHideWithoutData?: () => void;
  onToggleIgnoreFilters?: () => void;
  onToggleIsPrimary: () => void;
  onToggleShowAll: () => void;
}>;

const wmsTrialStyle = (theme: Theme) => css({
  border: `2px solid ${theme.textColors.warning}`,
  borderRightWidth: '3px', // scrollbar eats 1px
});

export const ActiveBoundaryComponent: FC<ActiveBoundaryProps> = props => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { boundaryIsUnderWmsTrial, blockWhenWmsTrial } = useWmsFeatureBlocker(props.boundaryGroupId);

  const initialRowLimit = props.legendMode ? BoundaryTerritoryListingExportLimit : BoundaryTerritoryListingLimit;
  const {
    limit: shownBoundaryKeySettingCountLimit,
    increaseLimit: increaseShownBoundaryKeySettingCountLimit,
  } = useLoadMoreListing(initialRowLimit);
  const {
    limit: shownBoundaryTerritoryKeySettingCountLimit,
    increaseLimit: increaseShownBoundaryTerritoryKeySettingCountLimit,
  } = useLoadMoreListing(initialRowLimit);

  const { startHighlightAnimation, dynamicStyles, staticHighlightStyles, isAnimationActive } = useHighlightAnimation();

  const highlightCallback = useCallback((node: HTMLDivElement) => {
    if (props.isHighlighted && node) {
      if (!isAnimationActive) {
        node.scrollIntoView();
        startHighlightAnimation();
      }
      dispatch(boundaryTerritoryGroupInSidebarHighlighted());
    }
  }, [props.isHighlighted, isAnimationActive, dispatch, startHighlightAnimation]);

  const customizeFillButtonStyle = boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : props.boundaryTerritoryType === BoundaryTerritoryType.Manual
    ? RoundButtonStyle.Primary : RoundButtonStyle.Quaternary;

  const containsLegend = props.boundaryKeySettings.length || props.boundaryTerritoryKeySettings.length;

  if (props.legendMode && !containsLegend) {
    return null;
  }

  return (
    <animated.div
      style={dynamicStyles}
      ref={highlightCallback}
      css={[staticHighlightStyles, boundaryIsUnderWmsTrial && wmsTrialStyle]}
      className={props.className}
    >
      <ActiveBoundaryHeaderComponent {...props} />

      {!props.legendMode && (
        <div css={actionsWrapperStyle}>
          <div>
            <RadioComponent
              isChecked={props.isPrimary}
              onChange={props.onToggleIsPrimary}
              label={t('Active')}
              css={primaryBoundaryLabelStyle}
              value="active"
            />
          </div>

          <div>
            <div css={toolbarButtonWrapperStyle}>
              {props.showToolbar && (
                <>
                  <div css={toolbarButtonStyle}>
                    <RoundButtonComponent
                      buttonStyle={boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : RoundButtonStyle.Primary}
                      icon={faPen}
                      onClick={blockWhenWmsTrial(props.onStartEditClick)}
                      size={RoundButtonSize.Small}
                      tooltipLabel={t(props.isBoundaryGroupCustom ? 'Start Edit Mode' : 'Start Selection Mode')}
                    />
                  </div>

                  <div css={toolbarButtonStyle}>
                    <RoundButtonComponent
                      icon={faFillDrip}
                      onClick={blockWhenWmsTrial(props.onCustomizeFillClick)}
                      size={RoundButtonSize.Small}
                      tooltipLabel={t('Customize Fill by data')}
                      buttonStyle={customizeFillButtonStyle}
                    />
                  </div>

                  <div css={toolbarButtonStyle}>
                    <RoundButtonComponent
                      icon={faCog}
                      onClick={props.onSettingsClick}
                      size={RoundButtonSize.Small}
                      tooltipLabel={t(props.isBoundaryGroupCustom ? 'Territory Group Settings' : 'Boundary Settings')}
                    />
                  </div>

                  <div css={toolbarButtonStyle}>
                    <RoundButtonComponent
                      icon={faXmark}
                      onClick={props.onRemove}
                      buttonStyle={RoundButtonStyle.Danger}
                      size={RoundButtonSize.Small}
                      tooltipLabel={t(props.isBoundaryGroupCustom ? 'Remove Territory Group from Map' : 'Remove Boundary from Map')}
                    />
                  </div>

                  <ActiveBoundaryContextMenuComponent
                    boundaryGroupId={props.boundaryGroupId}
                    isBoundaryGroupCustom={props.isBoundaryGroupCustom}
                    onConvert={props.onConvert}
                    onClone={props.onClone}
                    onCustomizeMetricsClick={props.onCustomizeMetricsClick}
                    onExportClick={props.onExportClick}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {!props.legendMode && (
        <div css={boundaryOptionsHeaderStyle}>
          {props.onToggleHideLabels && (
            <div css={boundaryOptionStyle}>
              <CheckboxComponent
                isChecked={props.hideLabels}
                checkedSetter={props.onToggleHideLabels}
                text={t('Hide Labels')}
                textStyle={checkboxStyle}
              />
            </div>
          )}

          {props.onToggleIgnoreFilters && (
            <div css={boundaryOptionStyle}>
              <CheckboxComponent
                isChecked={props.ignoreFilters}
                checkedSetter={props.onToggleIgnoreFilters}
                text={t('Ignore Filters')}
                textStyle={checkboxStyle}
              />
            </div>
          )}

          {props.onToggleBoundariesAsTerritories && (
            <div css={boundaryOptionStyle}>
              <CheckboxComponent
                isChecked={props.boundariesAsTerritories}
                checkedSetter={props.onToggleBoundariesAsTerritories}
                text={t('Combine Groups')}
                textStyle={checkboxStyle}
              />
            </div>
          )}

          {props.onToggleHideWithoutData && (
            <div css={boundaryOptionStyle}>
              <CheckboxComponent
                isChecked={props.hideWithoutData}
                checkedSetter={props.onToggleHideWithoutData}
                text={t('Hide Areas w/o Data')}
                textStyle={checkboxStyle}
              />
            </div>
          )}
        </div>
      )}

      <ActiveBoundaryFillInfoContainer
        boundaryTerritoryType={props.boundaryTerritoryType}
        boundaryTerritoryGroupId={props.boundaryTerritoryGroupId}
      />

      {!props.legendMode && (
        <div css={boundaryOptionsHeaderStyle}>
          <div css={boundaryOptionStyle}>
            <TriStateCheckboxComponent
              checkmark={props.showAll}
              checkedSetter={props.onToggleShowAll}
              text={props.isBoundaryGroupCustom ? t('Show All Territories') : t('Show All Boundaries')}
              textStyle={checkboxStyle}
            />
          </div>
        </div>
      )}

      {props.boundaryTerritoryKeySettings.map((setting, index) => (index < shownBoundaryTerritoryKeySettingCountLimit) && (
        <BoundaryTerritoryComponent
          key={index}
          setting={setting}
        />
      ))}

      {(props.boundaryTerritoryKeySettings.length > shownBoundaryTerritoryKeySettingCountLimit) && (
        <BoundaryRowLoadMoreComponent
          onButtonClick={increaseShownBoundaryTerritoryKeySettingCountLimit}
        />
      )}

      {props.boundaryKeySettings.map((setting, index) => (index < shownBoundaryKeySettingCountLimit) && (
        <BoundaryItemComponent
          legendMode={props.legendMode}
          key={index}
          boundaryGroupId={props.boundaryGroupId}
          setting={setting}
        />
      ))}

      {(props.boundaryKeySettings.length > shownBoundaryKeySettingCountLimit) && (
        <BoundaryRowLoadMoreComponent
          onButtonClick={increaseShownBoundaryKeySettingCountLimit}
        />
      )}
    </animated.div>
  );
};
