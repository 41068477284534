import {
  type FC,
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { createMapLimitReachedAppError } from '~/appError/specificErrors/mapLimitReached/mapLimitReached.helpers';
import { MAP_LIMIT_REACHED_MESSAGE } from '~/map/map.repository';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  closeAllModals, createAppError,
} from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { CopyMapModalComponent } from './copyMapModal.component';
import { useCopyMap } from './useCopyMap';

export type CopyMapModalContainerProps = ModalProps<{
  mapId: number;
  originalMapName: string;
  isSnapshot?: boolean;

  onMapCopySuccess: () => void;
}>;

export const CopyMapModalContainer: FC<CopyMapModalContainerProps> = (props) => {
  const [name, setName] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { isLoading, copyMap, fieldErrors } = useCopyMap();

  const { onMapCopySuccess, onClose } = props;

  const onSave = useCallback(() => {
    if (!clientId || !name || !props.mapId) {
      return;
    }
    copyMap({
      mapId: props.mapId,
      clientId,
      name,
    })
      .then(({ fieldErrors, globalError }) => {
        if (fieldErrors.size === 0) {
          if (globalError) {
            dispatch(closeAllModals());
            if (globalError === MAP_LIMIT_REACHED_MESSAGE) {
              dispatch(createMapLimitReachedAppError(t));
            }
            else {
              dispatch(createAppError({
                type: AppErrorType.General,
                title: t('Map creation failed'),
                errorTitle: globalError,
              }));
            }
          }
          else {
            setIsSuccess(true);
          }
        }
      });
  }, [clientId, copyMap, dispatch, name, props.mapId, t]);

  const onChange = useCallback((name: string) => {
    setName(name);
  }, []);

  const onSuccessAnimationFinish = useCallback(() => {
    onMapCopySuccess();
    onClose();
  }, [onClose, onMapCopySuccess]);

  return (
    <CopyMapModalComponent
      {...props}
      onChange={onChange}
      value={name}
      onSave={onSave}
      isLoading={isLoading}
      isSnapshot={props.isSnapshot}
      isSuccess={isSuccess}
      onSuccessAnimationFinish={onSuccessAnimationFinish}
      fieldErrors={fieldErrors}
    />
  );
};
