import {
  BOUNDARY_SELECT_EDIT_ACTIVATE,
  BOUNDARY_SELECT_EDIT_CLOSE_MODAL,
  BOUNDARY_SELECT_EDIT_DEACTIVATE,
  BOUNDARY_SELECT_SET_SELECT_TYPE,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionTypes';
import { defaultBoundarySelectOptions } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.reducer';
import { type BoundarySelectAction } from '../../../mapTools/boundary/boundarySelect/boundarySelect.action';
import { BoundarySelectType } from '../../../mapTools/boundary/boundarySelect/boundarySelect.state';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

const getCursorForSelectType = (type: BoundarySelectType) => {
  switch (type) {
    case BoundarySelectType.Click:
    case BoundarySelectType.HoverIdle: {
      return 'pointer';
    }
    case BoundarySelectType.HoverActive:
    case BoundarySelectType.Lasso: {
      return 'crosshair';
    }
    default: {
      return 'pointer';
    }
  }
};

const getSelectTypeSettings = (type: BoundarySelectType, priority: number) => ({
  changeId: 'BOUNDARY_SELECT_SELECT_TYPE',
  priority,
  change: {
    draggableCursor: getCursorForSelectType(type),
    ...(type === BoundarySelectType.Lasso ? { disableDoubleClickZoom: true } : undefined),
  },
});

let isActive = false;
let lastSelectType: BoundarySelectType = defaultBoundarySelectOptions.selectType;

export const applyBoundarySelectMapOptions = (
  state: MapComponentOptionsState,
  action: BoundarySelectAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case BOUNDARY_SELECT_EDIT_ACTIVATE: {
      isActive = true;

      const activated = push(state, {
        changeId: 'BOUNDARY_SELECT_SELECT_TYPE',
        priority,
        change: {
          clickableIcons: false,
        },
      });

      return push(activated, getSelectTypeSettings(lastSelectType, priority));
    }

    case BOUNDARY_SELECT_SET_SELECT_TYPE: {
      lastSelectType = action.payload.selectType;

      return isActive ? push(
        pop(state, 'BOUNDARY_SELECT_SELECT_TYPE'),
        getSelectTypeSettings(action.payload.selectType, priority)
      ) : undefined;
    }

    case BOUNDARY_SELECT_EDIT_CLOSE_MODAL:
    case BOUNDARY_SELECT_EDIT_DEACTIVATE: {
      const newState = pop(state, 'BOUNDARY_SELECT_ACTIVATE');

      isActive = false;
      return pop(newState, 'BOUNDARY_SELECT_SELECT_TYPE');
    }

    default:
      return undefined;
  }
};
