import { faLocation } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { useFindMyLocationWithErrorCooldown } from '~/_shared/utils/hooks/useFindMyLocationWithErrorCooldown';
import { RightSideMapToolsMenuItem } from '../rightSideMapToolsMenuItem.component';

export const RightSideMyLocationMenuItem: FC = _ => {
  const [t] = useTranslation();

  const {
    isLoading, locationTrackingEnabled, handleFindLocationClick, isErrorTooltipShown,
  } = useFindMyLocationWithErrorCooldown();

  return (
    <RightSideMapToolsMenuItem
      active={locationTrackingEnabled}
      icon={faLocation}
      isLoading={isLoading || isErrorTooltipShown}
      onClick={handleFindLocationClick}
      tooltip={!isErrorTooltipShown ? t('My Location') : t('We were not able to find your location. Please check your browser settings.')}
      tooltipAlwaysShow={isErrorTooltipShown}
    />
  );
};
