import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown, faCaretUp,
} from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type CSSProperties, type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { ToolboxContainerComponent } from '~/_shared/components/toolboxContainer/toolboxContainer.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { type SidebarAppProps } from '../sidebarAppComponentProps.type';
import {
  mapToolComponentMap, mapToolIconMap, mapToolTitleMap,
} from './mapTools/mapTools.helpers';
import { ToolSelectorComponent } from './mapTools/toolSelector.component';

type MapToolsComponentProps = SidebarAppProps & Readonly<{
  selectedTool: MapTool | null;
  isToolSelectEnabled: boolean;

  closeTool: () => void;
  openTool: (tool: MapTool) => void;
}>;

const animationConfig = { mass: 1, tension: 300, friction: 30, clamp: true };
const createNonAnimatedStyles = ({ theme }: ThemeProps): CSSProperties => ({
  overflow: 'hidden',
  position: 'absolute',
  zIndex: 50,
  top: '0%',
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.backgroundColors.primary,
});

const createCollapsedStyles = (onRest?: () => void) => ({ marginTop: '-100%', config: animationConfig, onRest });
const createExpandedStyles = (onRest?: () => void) => ({ marginTop: '0%', config: animationConfig, onRest });

export const MapToolsComponent: FC<MapToolsComponentProps> = props => {
  const [isSelectorRendered, setIsSelectorRendered] = useState<boolean>(!props.selectedTool);
  const [selectorStyles, selectorStylesApi] = useSpring(
    () => props.selectedTool ? createCollapsedStyles() : createExpandedStyles());
  const [lastSelectedTool, setLastSelectedTool] = useState(props.selectedTool);
  const [displayedTool, setDisplayedTool] = useState(props.selectedTool);

  const previousTool = usePrevious(props.selectedTool);
  const showSelector = !props.selectedTool;
  const previousShowSelector = usePrevious(showSelector);

  const { openTool, closeTool } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  const SelectedComponent = useMemo(() =>
    displayedTool && mapToolComponentMap[displayedTool],
  [displayedTool]);

  const titleIcon = useMemo((): IconProp | undefined => {
    if (displayedTool && !showSelector) {
      return mapToolIconMap[displayedTool];
    }
    return undefined;
  }, [showSelector, displayedTool]);

  const toggleSelector = useCallback(() => {
    if (!props.isToolSelectEnabled) {
      return;
    }
    else if (!showSelector) {
      closeTool();
    }
    else {
      openTool(lastSelectedTool ?? MapTool.Grouping);
    }
  },
  [showSelector, lastSelectedTool, props.isToolSelectEnabled, closeTool, openTool]);

  const nonAnimatedStyles = useMemo(() => ({
    ...createNonAnimatedStyles({ theme }),
    ...selectorStyles,
  }), [selectorStyles, theme]);

  const additionalToolboxAction = useMemo(() => {
    if (!props.isToolSelectEnabled) {
      return undefined;
    }
    else {
      return {
        icon: !showSelector ? faCaretDown : faCaretUp,
        onClick: toggleSelector,
      };
    }
  }, [showSelector, props.isToolSelectEnabled, toggleSelector]);

  useEffect(() => {
    if (previousShowSelector === showSelector) {
      return;
    }

    if (showSelector) {
      setIsSelectorRendered(true);
      selectorStylesApi.start(createExpandedStyles(() => setDisplayedTool(null)));
    }
    else {
      setDisplayedTool(props.selectedTool);
      selectorStylesApi.start(createCollapsedStyles(() => setIsSelectorRendered(false)));
    }
  }, [showSelector, previousShowSelector, props.isToolSelectEnabled, props.selectedTool, selectorStylesApi]);

  useEffect(() => {
    if (props.selectedTool) {
      setLastSelectedTool(props.selectedTool);
    }

    if (previousTool && props.selectedTool) {
      if (previousTool !== props.selectedTool) {
        setDisplayedTool(props.selectedTool);
      }
    }
  }, [previousTool, props.selectedTool]);

  return (
    <ToolboxContainerComponent
      title={displayedTool && !showSelector ? t(mapToolTitleMap[displayedTool]) : t('Select tool')}
      titleIcon={titleIcon}
      additionalAction={additionalToolboxAction}
      onClose={props.onClose}
      onTitleClick={toggleSelector}
    >
      {isSelectorRendered && (
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
        >
          <animated.div style={nonAnimatedStyles}>
            <ScrollBarComponent type={ScrollbarType.Vertical}>
              <ToolSelectorComponent selectTool={props.openTool} />
            </ScrollBarComponent>
          </animated.div>
        </ScrollBarComponent>
      )}

      {displayedTool && SelectedComponent && <SelectedComponent />}
    </ToolboxContainerComponent>
  );
};
