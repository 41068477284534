import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import {
  TopUnderbarContainer, TopUnderbarType,
} from '~/_shared/components/topUnderbar/topUnderbar.container';
import { MobileHomePageComponent } from '~/homepage/mobile/mobileHomePageComponent';
import { MapListingContainer } from '~/map/listing/mapListing.container';
import { mq } from '../_shared/style.mixins';
import { ExpiredSubscriptionBlocker } from '../_shared/types/client/subscriptionBlocker.component';
import { MOBILE_VERSION_WIDTH_THRESHOLD } from '../_shared/utils/deviceDetect/deviceDetect.helpers';
import { HomepageInfoContainer } from './homepageInfo.container';

const homepageStyle = css({
  height: '100%',
  maxWidth: '1100px',
  margin: '0 auto',
  [mq(MOBILE_VERSION_WIDTH_THRESHOLD)]: {
    padding: '0 10px 20px',
  },
});

const mobileScrollbarStyle = css({
  height: '100%',
});

const topUnderbarVisibleItems = new Set([
  TopUnderbarType.EXPORT_IMAGE_STATUS,
  TopUnderbarType.GEOCODING_PROGRESS_ALERT,
  TopUnderbarType.ANNOUNCEMENTS,
]);

type HomepageProps = Readonly<{
  isMobileScreen: boolean;
}>;

export const HomepageComponent: FC<HomepageProps> = props => {
  return (
    <ScrollBarComponent
      css={props.isMobileScreen ? mobileScrollbarStyle : undefined}
      type={ScrollbarType.Vertical}
    >
      <TopUnderbarContainer visibleItems={topUnderbarVisibleItems} />
      <div css={homepageStyle}>
        {props.isMobileScreen ? (
          <MobileHomePageComponent />
        ) : (
          <div>
            <HomepageInfoContainer />
            <ExpiredSubscriptionBlocker>
              <MapListingContainer />
            </ExpiredSubscriptionBlocker>
          </div>
        )}
      </div>
    </ScrollBarComponent>
  );
};
