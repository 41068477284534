import { type SerializedStyles } from '@emotion/react';
import { type FC } from 'react';
import {
  LoaderComponent, type LoaderType,
} from '~/_shared/baseComponents/loaders';
import { OverlayComponent } from './overlay.component';
import { lottieAnimationOverlayStyle } from './overlayLottieAnimation.component';

type OverlayLoaderProps = Readonly<{
  className?: string;
  loaderSize?: number;
  spinnerStyle?: SerializedStyles;
  loaderType?: LoaderType;
}>;

export const OverlayLoaderComponent: FC<OverlayLoaderProps> = (props) => {
  const size = props.loaderSize ?? 60;

  return (
    <OverlayComponent className={props.className}>
      <LoaderComponent
        css={[lottieAnimationOverlayStyle({ size }), props.spinnerStyle]}
        size={size}
        type={props.loaderType}
      />
    </OverlayComponent>
  );
};
