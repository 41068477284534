import type { PropsWithChildren } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { tableHeaderCellStyle } from '~/map/mapMigration/table/table.styles';

type TableHeaderCellProps = PropsWithChildren<{
  readonly className?: string;
  readonly colSpan?: number;
}>;

export const TableHeaderCellComponent = ({ className, children, colSpan = 1 }: TableHeaderCellProps) => {
  const theme = useTheme();
  return (
    <div
      className={className}
      css={tableHeaderCellStyle({ theme, colSpan })}
    >
      {children}
    </div>
  );
};
