import { css } from '@emotion/react';
import {
  forwardRef, type Ref, useMemo,
} from 'react';
import {
  LottieAnimationTypes, useLottieAnimationDefaultColors,
} from '~/_shared/baseComponents/lottieAnimation';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayLottieAnimationComponent } from '~/_shared/components/overlay/overlayLottieAnimation.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import {
  type SpreadsheetHeaderColumn, type SpreadsheetRow, type SpreadsheetSortType,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import type { Theme } from '~/_shared/themes/theme.model';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import type {
  CombinedRowId, SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  DataTableBottomSectionComponent, type DataTablePaginationProps,
} from '~/data/table/dataTableBottomSection.component';
import { SpreadsheetComponent } from '../../_shared/components/spreadsheet/spreadsheet.component';

export const META_CONTAINER_HEIGHT = 52;

const centerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const bottomSectionWrapper = css(centerStyle, {
  position: 'relative',
  width: '100%',
});

const metaContainerStyle = css({
  minHeight: META_CONTAINER_HEIGHT,
  position: 'relative',
  width: '100%',
});

const scrollBarContentStyle = {
  display: 'block',
  minWidth: 'none',
  paddingBottom: 5,
};

const scrollBarStyle = css({
  transform: 'translateX(0)',
});

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 100%',
  height: '100%',
});

const spreadsheetWrapperStyle = (theme: Theme) => css({
  border: `2px solid ${theme.borderColors.primary}`,
  height: '100%',
});

const noResultsStyle = css({
  margin: 0,
  padding: '10px 8px',
  textAlign: 'center',
});

type DataTableSpreadsheetProps = Readonly<{
  data: SpreadsheetRow[];
  columns: SpreadsheetHeaderColumn[];
  paginationProps?: DataTablePaginationProps;
  columnWidthsLocalStorageKey?: string;
  allowCellSelect: boolean;
  badLocationsCount?: number;
  getSpreadsheetCellDisabledMessage?: (spreadsheetRowId: SpreadsheetRowId, columnId?: ColumnId) => string | null;
  onDataChange: (newData: SpreadsheetRow[]) => void;
  onSortChange?: (sortDescriptor: { columnId: string; newSort: SpreadsheetSortType | null }) => void;
  onBadLocationsClick?: () => void;
}>;

type DataTableProps = Readonly<{
  allowRowsSelect?: boolean;
  className?: string;
  isLoading: boolean;
  isSearchFilterActive: boolean;
  highlightedRows?: ReadonlySet<CombinedRowId>;
  selectedRows?: { [rowId: number]: true };
  showBadDataSection?: boolean;
  spreadsheetProps: DataTableSpreadsheetProps;
  sheetWrapperRef?: Ref<HTMLDivElement>;
  metaRef?: Ref<HTMLDivElement>;

  onHoveredRowChange?: (row: SpreadsheetRow) => void;
  onRowClick?: (row: SpreadsheetRow) => void;
  onSelectedRowsChange?: (newSelectedRows: { [rowId: number]: true}) => void;
}>;

export const DataTableComponent = forwardRef<HTMLDivElement, DataTableProps>(({
  allowRowsSelect,
  className,
  highlightedRows,
  isLoading,
  isSearchFilterActive,
  metaRef,
  onHoveredRowChange,
  onRowClick,
  onSelectedRowsChange,
  selectedRows,
  sheetWrapperRef,
  showBadDataSection,
  spreadsheetProps,
}, ref) => {
  const {
    allowCellSelect,
    badLocationsCount,
    columnWidthsLocalStorageKey,
    columns,
    data,
    getSpreadsheetCellDisabledMessage,
    onBadLocationsClick,
    onDataChange,
    onSortChange,
    paginationProps: { pagination } = {},
  } = spreadsheetProps;

  const [t] = useTranslation();
  const animationColors = useLottieAnimationDefaultColors();

  const hasPagination = !!pagination && pagination.lastPage > 1;
  const hasBadData = (badLocationsCount ?? 0) > 0;
  const showBadData = showBadDataSection && hasBadData && onBadLocationsClick;
  const hasBottomSection = !!showBadData || hasPagination;

  const badDataProps = useMemo(() =>
    showBadData ? { onBadLocationsClick, badLocationsCount } : undefined,
  [badLocationsCount, onBadLocationsClick, showBadData]);

  return (
    <div
      className={className}
      css={containerStyle}
      ref={ref}
    >
      <div
        ref={sheetWrapperRef}
        css={spreadsheetWrapperStyle}
      >
        <ScrollBarComponent
          type={[ScrollbarType.Horizontal, ScrollbarType.Vertical]}
          contentStyle={scrollBarContentStyle}
          css={scrollBarStyle}
        >
          <SpreadsheetComponent
            columnWidthsLocalStorageKey={columnWidthsLocalStorageKey}
            columns={columns}
            data={data}
            getSpreadsheetCellDisabledMessage={getSpreadsheetCellDisabledMessage}
            highlightedRows={highlightedRows}
            onDataChange={onDataChange}
            onHoveredRowChange={onHoveredRowChange}
            onRowClick={onRowClick}
            onSelectedRowsChange={onSelectedRowsChange ?? noop}
            onSortChange={onSortChange}
            canSelectCells={allowCellSelect}
            canSelectRows={!!allowRowsSelect}
            selectedRows={selectedRows ?? noSelectedRows}
            useResizePlaceholder
          />

          {!isLoading && data.length === 0 && (
            <div css={noResultsStyle}>
              {t(isSearchFilterActive ? 'No results are matching your query. Update your filters to see the data.' : 'No results')}
            </div>
          )}
        </ScrollBarComponent>
      </div>

      {hasBottomSection && (
        <div css={bottomSectionWrapper}>
          <div ref={metaRef} css={metaContainerStyle}>
            <DataTableBottomSectionComponent
              paginationProps={spreadsheetProps.paginationProps}
              badDataProps={badDataProps}
            />
          </div>
        </div>
      )}

      {isLoading && !isSearchFilterActive && (
        <OverlayLoaderComponent />
      )}

      {isLoading && isSearchFilterActive && (
        <OverlayLottieAnimationComponent
          autoplay
          colors={animationColors[LottieAnimationTypes.Filter]}
          loop
          size={90}
          type={LottieAnimationTypes.Filter}
        />
      )}
    </div>
  );
});

const noSelectedRows = {};
