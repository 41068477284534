import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithInputBarComponent } from '../../../_shared/components/colorPicker/colorPickerWithInputBar/colorPickerWithInputBar.component';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { isTextEmpty } from '../../../_shared/utils/text/text.helpers';
import { type ModalProps } from '../../../modal/modalType.enum';

const modalStyle = css({
  width: 400,
});

const colorPickerWrapperStyle = css({
  marginTop: 15,
});

const labelStyle = css({
  textTransform: 'uppercase',
  margin: '0 0 5px',
  fontSize: '14px',
  fontWeight: 500,
});

export type DirectionsPanelEditProps = ModalProps<{
  routeName: string;
  routeColor: ColorResult;
  onSave: (routeName: string, routeColor: ColorResult) => void;
}>;

export const DirectionsEditRouteComponent: FC<DirectionsPanelEditProps> = (props) => {
  const [routeName, setRouteName] = useState(props.routeName);
  const [routeColor, setRouteColor] = useState(props.routeColor);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.routeName) {
      setRouteName(props.routeName);
    }
  }, [props.routeName]);

  useEffect(() => {
    if (props.routeColor) {
      setRouteColor(props.routeColor);
    }
  }, [props.routeColor]);

  return (
    <ModalComponent
      css={modalStyle}
      caption={t('Edit Route')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      confirmButton={(
        <ButtonComponent
          prefixIcon={faCheck}
          text={t('Save')}
          isDisabled={isTextEmpty(routeName)}
          onClick={() => props.onSave(routeName, routeColor)}
        />
      )}
    >
      <div>
        <p css={labelStyle}>{t('Route Name')}</p>
        <TextInputComponent
          value={routeName}
          onChange={setRouteName}
        />
      </div>

      <div css={colorPickerWrapperStyle}>
        <p css={labelStyle}>{t('Route Color')}</p>
        <ColorPickerWithInputBarComponent
          onChange={setRouteColor}
          selectedColor={routeColor}
          isFixed
          displayAlpha={false}
        />
      </div>
    </ModalComponent>
  );
};
