import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButtonWithText.component';

type DrawingToolFooterProps = Readonly<{
  removeDrawingsLabel: string;
  isRemoveDisabled: boolean;
  onRemove: () => void;
}>;

export const DrawingToolFooterComponent: FC<DrawingToolFooterProps> = props => {

  return (
    <RoundButtonWithTextComponent
      buttonStyle={RoundButtonStyle.Danger}
      icon={faTrash}
      isDisabled={props.isRemoveDisabled}
      onClick={props.onRemove}
      text={props.removeDrawingsLabel}
    />
  );
};
