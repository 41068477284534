import { type FC } from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import { ErrorPageType } from '~/store/errorPage/errorPage.state';
import { useErrorPageErrorSelector } from '../../store/errorPage/errorPage.selectors';
import { AppErrorPageNotFoundComponent } from '../pages/errorPage/notFound/appErrorPageNotFound.component';
import { AppErrorPagePresentationalMapUnavailable } from '../pages/errorPage/publicMapUnavailable/appErrorPagePresentationalMapUnavailable';
import { PublicMapPaths } from './publicMaps/publicMaps.constants';
import { PresentationalMapRoutes } from './publicMaps/publicMapsRoutes';

export const PublicDomainRoutes: FC = () => {
  const globalError = useErrorPageErrorSelector();

  if (globalError === ErrorPageType.PageNotFound) {
    return (
      <AppErrorPageNotFoundComponent />
    );
  }

  if (globalError !== null) {
    return (
      <AppErrorPagePresentationalMapUnavailable />
    );
  }

  return (
    <Routes>
      <Route
        path={PublicMapPaths.Base}
        element={<PresentationalMapRoutes />}
      />
      <Route
        path="*"
        element={<AppErrorPageNotFoundComponent />}
      />
    </Routes>
  );
};
