import {
  useCallback, useRef,
} from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { useMap } from '~/map/map/mapContext';
import {
  type DrawingTextAreaSizeProps, type DrawingTextAreaSizeWithText,
} from './useDrawingToolTextAreaScalingToggle';
import {
  getDrawingToolTexAreaFullSize,
  getDrawingToolTextAreaPadding,
} from './useDrawingToolTextAreaSize';

export const useDrawingToolTextAreaTextChange = (
  props: DrawingTextAreaSizeProps,
  onChangeCallback: (props: DrawingTextAreaSizeWithText) => void
) => {
  // we use map.getZoom() to get current zoom because the callbacks are debounced/throttled
  const map = useMap();

  const onChangeRef = useRef(onChangeCallback);
  onChangeRef.current = onChangeCallback;
  const onChange = useCallback((props: DrawingTextAreaSizeWithText) => onChangeRef.current(props), []);

  const onTextChange = useCallback((newText: string, newDimensions: QuadrilateralDimensions) => {
    if (!map) {
      return;
    }

    if (props.fontSize === 0) {
      // When font size is 0 we can't use computed text area dimensions
      onChange({
        text: newText,
        height: props.height,
        width: props.width,
        fontSize: props.fontSize,
      });
    }

    let scaledDimensions = newDimensions;
    if (props.scalesWithMapZoom) {
      const padding = getDrawingToolTextAreaPadding({
        ...newDimensions,
        borderRadius: props.borderRadius,
        strokeWidth: props.strokeWidth,
      });

      const heightWithoutPadding = newDimensions.height - 2 * padding;
      const widthWithoutPadding = newDimensions.width - 2 * padding;

      const mapZoom = map.getZoom();
      if (mapZoom) {
        const scaledHeightWithoutPadding = convertPxDistanceToDifferentZoom({ distance: heightWithoutPadding, originalZoom: mapZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS });
        const scaledWidthWithoutPadding = convertPxDistanceToDifferentZoom({ distance: widthWithoutPadding, originalZoom: mapZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS });

        scaledDimensions = getDrawingToolTexAreaFullSize({
          heightWithoutPadding: scaledHeightWithoutPadding,
          widthWithoutPadding: scaledWidthWithoutPadding,
          borderRadius: props.borderRadius,
          strokeWidth: props.strokeWidth,
        });
      }
    }

    onChange({
      text: newText,
      height: scaledDimensions.height,
      width: Math.max(scaledDimensions.width, props.width),
      fontSize: props.fontSize,
    });
  }, [map, onChange, props.borderRadius, props.fontSize, props.height, props.scalesWithMapZoom,
    props.strokeWidth, props.width]);

  return {
    onTextChange,
  };
};
