import { FilterAction } from '../../../../../_shared/types/filter/filter';
import { createFilterActionFromTextPredicate } from '../../../../../_shared/types/filter/filterAction.factory';
import { type GroupingColumnValues } from '../../../../../_shared/types/grouping/grouping';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type FilterActionStringDescriptor,
  type FilterActionValueLessDescriptor,
  type MapSettingsColumnsFilterState,
  type MapSettingsFilterAttributeState,
  type MapSettingsFilterDateState,
  type MapSettingsFilterNumberState,
  type MapSettingsFilterTextState,
  type MapSettingsFilterTextValue,
} from '../../../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { DataType } from '../../../../../store/spreadsheetData/spreadsheetData.state';
import { type TextFilter } from './textFilter/textFilter.component';
import { createTextPredicateFromFilterAction } from './textFilter/textFilter.factory';
import { TextFilterPredicate } from './textFilter/textFilterPredicate.enum';

const nullablePredicates = [TextFilterPredicate.IsNotEmpty, TextFilterPredicate.IsEmpty];
const nullableFilterActions = [FilterAction.NotNull, FilterAction.Null];
const arrayPredicates = [TextFilterPredicate.IsIn, TextFilterPredicate.IsNotIn];
const arrayFilterActions = [FilterAction.ArrayContains, FilterAction.ArrayNotContains];

export const getTextFilterFromState = (state: MapSettingsFilterTextState): TextFilter[] => {
  return state.values.map(item => ({
    filterPredicate: createTextPredicateFromFilterAction(item.action),
    textArgument: item.value ?? '',
  }));
};

export const createFilterTextValuesFromTextFilter = (textFilter: TextFilter): MapSettingsFilterTextValue => {
  if (isNullableTextPredicate(textFilter.filterPredicate)) {
    return {
      action: createFilterActionFromTextPredicate(textFilter.filterPredicate),
    } as FilterActionValueLessDescriptor;
  }

  return {
    action: createFilterActionFromTextPredicate(textFilter.filterPredicate),
    value: textFilter.textArgument,
  } as FilterActionStringDescriptor;
};

export const filterTextValueEquals = (filter1: MapSettingsFilterTextValue, filter2: MapSettingsFilterTextValue): boolean => {
  if (filter1.action !== filter2.action) {
    return false;
  }

  if (isNullableFilterAction(filter1.action)) {
    return true;
  }

  return filter1.value === filter2.value;
};

export const isNullableTextPredicate = (predicate: TextFilterPredicate): boolean => {
  return nullablePredicates.includes(predicate);
};

export const isArrayTextPredicate = (predicate: TextFilterPredicate): boolean => {
  return arrayPredicates.includes(predicate);
};

const isNullableFilterAction = (action: FilterAction): boolean => {
  return nullableFilterActions.includes(action);
};

export const isArrayFilterAction = (action: FilterAction): action is FilterAction.ArrayContains | FilterAction.ArrayNotContains => {
  return arrayFilterActions.includes(action);
};

export const isTextFilterOnList = (textFilter: TextFilter, filters: TextFilter[]): boolean => {
  for (const filter of filters) {
    if (compareTextFilters(filter, textFilter)) {
      return true;
    }
  }

  return false;
};

export const compareTextFilters = (filter1: TextFilter, filter2: TextFilter): boolean => {
  return filter1.filterPredicate === filter2.filterPredicate && filter1.textArgument === filter2.textArgument;
};

type ColumnsFilterDataObjectType<T> =
  T extends DataType.GROUP ? GroupingColumnValues<1> :
    T extends DataType.NUMBER ? MapSettingsFilterNumberState :
      T extends DataType.ATTRIBUTE ? MapSettingsFilterAttributeState :
        T extends DataType.DATE ? MapSettingsFilterDateState :
          T extends DataType.TEXT ? MapSettingsFilterTextState:
            never;

export const getColumnsFilterDataForDataType = <T extends DataType>(
  dataType: T,
  columnsFilter: MapSettingsColumnsFilterState,
  { spreadsheetId, columnId }: SpreadsheetColumnId
): ColumnsFilterDataObjectType<T> | null => {
  const data = columnsFilter[spreadsheetId]?.[columnId];

  if (!data) {
    return null;
  }

  switch (dataType) {
    case DataType.NUMBER:
      return (data[DataType.NUMBER] ?? null) as ColumnsFilterDataObjectType<T> | null;
    case DataType.GROUP:
      return (data[DataType.GROUP] ?? null) as ColumnsFilterDataObjectType<T> | null;
    case DataType.TEXT:
      return (data[DataType.TEXT] ?? null) as ColumnsFilterDataObjectType<T> | null;
    case DataType.ATTRIBUTE:
      return (data[DataType.ATTRIBUTE] ?? null) as ColumnsFilterDataObjectType<T> | null;
    case DataType.DATE:
      return (data[DataType.DATE] ?? null) as ColumnsFilterDataObjectType<T> | null;
    default:
      return null;
  }
};

export const getTextFilterLabel = (
  filter: MapSettingsFilterTextValue
): string | null => {
  const textPredicate = createTextPredicateFromFilterAction(filter.action);

  if (isNullableTextPredicate(textPredicate)) {
    return null;
  }
  else {
    return filter.value ?? '';
  }
};
