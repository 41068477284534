import {
  MAP_SETTINGS_CONCURRENCY_SET_ALERT_VISIBILITY,
  MAP_SETTINGS_CONCURRENCY_SET_IS_OFFLINE,
} from './mapSettingsConcurrency.actionTypes';

export const mapSettingsConcurrencySetIsOffline = (isOffline: boolean) => ({
  type: MAP_SETTINGS_CONCURRENCY_SET_IS_OFFLINE,
  payload: {
    isOffline,
  },
}) as const;

export const mapSettingsConcurrencySetAlertVisibility = (showAlert: boolean) => ({
  type: MAP_SETTINGS_CONCURRENCY_SET_ALERT_VISIBILITY,
  payload: {
    showAlert,
  },
}) as const;
