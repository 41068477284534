import {
  useCallback, useMemo,
} from 'react';
import {
  createEntityAccessRequest,
  deleteEntityAccessRequest,
  type EntityAccessLevel, entityAccessResponseToEntityAccess,
  type EntityType,
  getEntityAccessesRequest,
  type SharedEntityType,
  updateEntityAccessRequest,
} from '~/_shared/entityAccess/entityAccess.repository';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';

export const useGetEntityAccesses = (
  clientId: number | null,
  entityType?: EntityType,
  sharedEntityType?: SharedEntityType,
  accessLevel?: EntityAccessLevel,
  entityId?: number,
  sharedEntityId?: number,
) => {
  const getEntityAccesses = useCallback(() => {
    if (clientId) {
      return getEntityAccessesRequest(
        clientId,
        entityType,
        sharedEntityType,
        accessLevel,
        entityId,
        sharedEntityId
      );
    }
    else {
      return Promise.resolve({ list: [] });
    }
  }, [accessLevel, clientId, entityId, entityType, sharedEntityId, sharedEntityType]);
  const { isLoading, error, data, invokeAjax } = useAjaxCall(getEntityAccesses);

  const processedData = useMemo(() => ({
    list: data?.list.map((item) => entityAccessResponseToEntityAccess(item)) || [],
  }), [data]);

  return {
    isLoading,
    error,
    data: processedData,
    invokeAjax,
  };
};

export const useCreateEntityAccess = () => {
  const { invokeAjax, isLoading } = useAjaxCall(createEntityAccessRequest);

  return {
    create: invokeAjax,
    isLoading,
  };
};

export const useUpdateEntityAccess = () => {
  const { invokeAjax, isLoading } = useAjaxCall(updateEntityAccessRequest);

  return {
    update: invokeAjax,
    isLoading,
  };
};

export const useDeleteEntityAccess = () => {
  const { invokeAjax, isLoading } = useAjaxCall(deleteEntityAccessRequest);

  return {
    delete: invokeAjax,
    isLoading,
  };
};
