import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { DndType } from '~/_shared/constants/dndType.enum';
import { type ColumnRole } from '~/_shared/types/columnRole.enum';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  type ColumnStyleSettingsData, type GeneralPanelSettings,
} from '~/customizeLocationPanel/locationData/panelSettings.types';
import { type SidebarTitleSettings } from '~/sidebar/sidebarApps/rightSidebar/sidebarTitle/sidebarTitle.types';
import { LOCATION_PANEL_DATA_CLASSNAME } from '../../customizeLocationPanel.constants';
import { getItemDnDType } from '../../locationData/locationDataItem/draggableItem.helpers';
import { ColorFontComponent } from './colorFont.component';
import { guaranteeSize } from './colorsAndFontsEditable.helpers';

const wrapperStyle = css({
  boxSizing: 'border-box',
  fontSize: 14,
  fontWeight: 500,
  height: '100%',
  lineHeight: '16px',
  padding: '0 10px 10px',
  width: '100%',
});

const LABEL_MARGIN_TOP = 24;

const labelStyle = css({
  marginTop: LABEL_MARGIN_TOP,
  textTransform: 'uppercase',
});

const longLabelStyle = css({
  margin: `${LABEL_MARGIN_TOP}px 0 0`,
}, labelStyle);

const recordStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 15,
  paddingLeft: 14,
});

const inputWithLabelStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const inputStyle = css({
  marginRight: 4,
  width: 55,
});

type ColorsAndFontsEditableProps = Readonly<{
  bubbleItemType?: ColumnRole | null;
  generalSettings: GeneralPanelSettings;
  locationDescriptionStyle: SidebarTitleSettings | null;
  selectedItemStyle: ColumnStyleSettingsData | null;

  onChangeGeneral?: (settings: Partial<GeneralPanelSettings>) => void;
  onChangeStyle: (style: Partial<ColumnStyleSettingsData | SidebarTitleSettings>) => void;
}>;

export const ColorsAndFontsEditableComponent: FC<ColorsAndFontsEditableProps> = props => {
  const [t] = useTranslation();
  const {
    locationDescriptionStyle: locationStyle,
    selectedItemStyle: bubbleStyle,
    generalSettings, onChangeGeneral,
    onChangeStyle,
  } = props;

  const bubbleItemType = useMemo(() =>
    props.bubbleItemType && getItemDnDType(props.bubbleItemType),
  [props.bubbleItemType]);

  const changeCallback: {[key in keyof(ColumnStyleSettingsData & SidebarTitleSettings)]: (value: string | boolean) => void} = useMemo(() => {
    return {
      verticalGap: (value: string) => {
        onChangeStyle({ verticalGap: guaranteeSize(value) });
      },
      showLabel: (value: boolean) => {
        onChangeStyle({ showLabel: value });
      },
      showValue: (value: boolean) => {
        onChangeStyle({ showValue: value });
      },
      labelFontColor: (value: string) => {
        onChangeStyle({ labelFontColor: value });
      },
      labelFontSize: (value: string) => {
        onChangeStyle({ labelFontSize: value });
      },
      valueFontColor: (value: string) => {
        onChangeStyle({ valueFontColor: value });
      },
      valueFontSize: (value: string) => {
        onChangeStyle({ valueFontSize: value });
      },
    };
  }, [onChangeStyle]);

  const handleChangeShowStreetView = useCallback((newValue: boolean) => {
    onChangeGeneral?.({
      showStreetViewImage: newValue,
    });
  }, [onChangeGeneral]);

  const valueFontStyle: ColumnStyleSettingsData | SidebarTitleSettings | undefined = useMemo(() => {
    if (bubbleStyle) {
      return bubbleStyle;
    }
    else if (locationStyle && locationStyle.showValue) {
      return locationStyle;
    }

    return undefined;
  }, [bubbleStyle, locationStyle]);

  return (
    <ScrollBarComponent
      className={LOCATION_PANEL_DATA_CLASSNAME}
      type={ScrollbarType.Vertical}
    >
      <div css={wrapperStyle} >
        {onChangeGeneral && (
          <>
            <div css={longLabelStyle}>{t('Hide street view image')}</div>
            <div css={recordStyle}>
              <ToggleComponent
                isOn={!generalSettings.showStreetViewImage}
                onChange={(hideStreetView: boolean) => handleChangeShowStreetView(!hideStreetView)}
              />
            </div>
          </>
        )}
        {bubbleStyle && bubbleItemType !== DndType.LocalizationContactItem && (
          <>
            <div css={longLabelStyle}>{t('Column labels / content spacing')}</div>
            <div css={recordStyle}>
              <ToggleComponent
                isOn={bubbleStyle.showLabel}
                onChange={changeCallback.showLabel}
              />
              <span css={inputWithLabelStyle}>
                <InputWithSpinnersComponent
                  size={InputSize.Small}
                  css={inputStyle}
                  value={Number(bubbleStyle.verticalGap)}
                  onChange={value => changeCallback.verticalGap(value.toString())}
                  valueMin={0}
                />
                {t('Pixels')}
              </span>
            </div>
          </>
        )}
        {locationStyle && (
          <>
            <div css={labelStyle}>{t('Show value')}</div>
            <div css={recordStyle}>
              <ToggleComponent
                isOn={locationStyle.showValue}
                onChange={changeCallback.showValue}
              />
            </div>
          </>
        )}
        {
          bubbleStyle && bubbleStyle.showLabel && bubbleItemType !== DndType.LocalizationContactItem && (
            <>
              <div css={labelStyle}>{t('Data font color / size', { what: t('label') })}</div>
              <div css={recordStyle}>
                <ColorFontComponent
                  color={bubbleStyle.labelFontColor}
                  onColorChange={changeCallback.labelFontColor}
                  onSizeChange={changeCallback.labelFontSize}
                  size={bubbleStyle.labelFontSize}
                />
              </div>
            </>
          )}
        {(locationStyle && locationStyle.showValue || bubbleStyle) && (
          <>
            <div css={labelStyle}>{t('Data font color / size', { what: t('Value') })}</div>
            <div css={recordStyle} >
              {valueFontStyle && (
                <ColorFontComponent
                  onColorChange={changeCallback.valueFontColor}
                  onSizeChange={changeCallback.valueFontSize}
                  color={valueFontStyle.valueFontColor}
                  size={valueFontStyle.valueFontSize}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ScrollBarComponent>
  );
};
