import {
  DRAWING_TOOL_DEFAULT_FILL_COLOR_PRIMARY, DRAWING_TOOL_DEFAULT_FONT_COLOR_PRIMARY,
  DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingCalloutSettingsState } from './drawingCalloutSettings.state';

export const drawingCalloutSettingsInitialState: DrawingCalloutSettingsState = {
  scalesWithMapZoom: false,
  borderRadius: 10,
  fillOpacity: 100,
  strokeOpacity: 100,
  strokeWeight: 2,
  fontSize: 20,
  strokeColor: DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY,
  fillColor: DRAWING_TOOL_DEFAULT_FILL_COLOR_PRIMARY,
  fontColor: DRAWING_TOOL_DEFAULT_FONT_COLOR_PRIMARY,
};

export const drawingCalloutSettingsReducer = (
  state = drawingCalloutSettingsInitialState, action: DrawingSettingsAction
): DrawingCalloutSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Callout) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.callout,
      };
    }

    default:
      return state;
  }
};
