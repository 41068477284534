import {
  faLocationDot,
  faLocationDotSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import {
  animationLengthInMS, ToggleSize, ToggleWithDelayComponent,
} from '~/_shared/baseComponents/toggle';
import { type MapStatusBarToggleSwitchItem } from '../mapStatusBarItem.type';

type MapStatusBarToggleItemComponentProps = Readonly<{
  className?: string;
  size?: ToggleSize;
  item: MapStatusBarToggleSwitchItem;
}>;

export const MapStatusBarToggleItemComponent: FC<MapStatusBarToggleItemComponentProps> = (props) => {
  return (
    <div>
      <ToggleWithDelayComponent
        className={props.className}
        isOn={!!props.item.isVisible}
        offIcon={faLocationDotSlash}
        onChange={props.item.onVisibilityChange}
        onIcon={faLocationDot}
        size={props.size ?? ToggleSize.M}
        delayInMS={animationLengthInMS}
      />
    </div>
  );
};
