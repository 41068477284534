import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  faXmark, type IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type MouseEvent,
  type PropsWithChildren,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { LimitNumberOfLinesComponent } from '../limitNumberOfLines/limitNumberOfLines.component';

export enum Size {
  Standard = 'Standard',
  Small = 'Small',
}

type SizeRelatedProps = Readonly<{ height: number; fontSize: number; multilinePadding: number }>;
const createSizeRelatedProps = (size?: Size): SizeRelatedProps => {
  switch (size ?? Size.Standard) {
    case Size.Standard:
      return {
        height: 32,
        fontSize: 16,
        multilinePadding: 8,
      };
    case Size.Small:
      return {
        height: 24,
        fontSize: 12,
        multilinePadding: 6,
      };
    default:
      throw new Error(`Unknown Size prop in SelectedItemComponent: '${size}'.`);
  }
};

type SelectedItemProps = Readonly<{
  className?: string;
  size?: Size;
  isDisabled?: boolean;
  removeIcon?: IconDefinition;
  removeButtonStyle?: SerializedStyles;
  multiline?: boolean;
  maxNumberOfLines?: number;

  onRemove?: (e: MouseEvent<HTMLButtonElement>) => void;
  onHoverChange?: (hovered: boolean) => void;
}>;

const containerStyle = ({ theme, sizeProps, multiline }: ThemeProps<{ sizeProps: SizeRelatedProps; multiline: boolean }>) => css({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.tagColors.backgroundColor,
  color: theme.tagColors.textColor,
  fontSize: sizeProps.fontSize,
  padding: '0 10px',
  borderRadius: 24,
  paddingTop: multiline ? sizeProps.multilinePadding : undefined,
  paddingBottom: multiline ? sizeProps.multilinePadding : undefined,
  height: multiline ? undefined : sizeProps.height,
  wordBreak: multiline ? 'break-word' : undefined,

  '&:not(:has(~ &))': { // This selects the last &. ':last-of-type' only works with tags.
    marginRight: 8,
  },
});

const removeButtonStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  color: theme.iconColors.contrast,
  background: 'none',
  border: 'none',
  padding: '0 0 0 6px',
});

export const SelectedItemComponent = (props: PropsWithChildren<SelectedItemProps>) => {
  const { onHoverChange } = props;
  const theme = useTheme();

  const onMouseOver = useMemo(() => onHoverChange && (() => onHoverChange(true)), [onHoverChange]);
  const onMouseLeave = useMemo(() => onHoverChange && (() => onHoverChange(false)), [onHoverChange]);

  return (
    <div
      css={containerStyle({ theme,
        sizeProps: createSizeRelatedProps(props.size),
        multiline: !!props.multiline }
      )}
      className={props.className}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {props.multiline && props.maxNumberOfLines ? (
        <LimitNumberOfLinesComponent maxNumberOfLines={props.maxNumberOfLines}>
          {props.children}
        </LimitNumberOfLinesComponent>
      ) : props.children}

      {props.onRemove && (
        <button
          css={[removeButtonStyle({ theme }), props.removeButtonStyle]}
          onClick={props.onRemove}
          disabled={props.isDisabled}
        >
          <FontAwesomeIcon icon={props.removeIcon ?? faXmark} />
        </button>
      )}
    </div>
  );
};
