import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import {
  createPresentationalColumnsRestriction,
  getPresentationalColumnsRestrictionList,
  type PresentationalColumnsRestrictionFlags,
  type PresentationalColumnsRestrictionsCreateResponse,
  type PresentationalColumnsRestrictionsListResponse,
  updatePresentationalColumnsRestriction,
} from '../../presentationalColumnsRestrictions/presentationalColumnsRestrictions.repository';
import { type MapIdAction } from '../mapId/mapId.action';
import { MAP_ID_SET } from '../mapId/mapId.actionTypes';
import { type PresentationalColumnsRestrictionsAction } from './presentationalColumnsRestrictions.action';
import {
  presentationalColumnsRestrictionsGetDataError,
  presentationalColumnsRestrictionsGetDataRequest,
  presentationalColumnsRestrictionsGetDataSuccess,
  presentationalColumnsRestrictionsUpdateError, presentationalColumnsRestrictionsUpdateSuccess,
} from './presentationalColumnsRestrictions.actionCreators';
import {
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST,
  PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST,
} from './presentationalColumnsRestrictions.actionTypes';
import { type PresentationalColumnsRestrictionsStateRestrictions } from './presentationalColumnsRestrictions.state';

export function* presentationalColumnsRestrictionsSagas() {
  yield takeLatest(MAP_ID_SET, onMapIdSet);
  yield takeLatest(PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST, onColumnsRestrictionsSetRestrictionRequest);
  yield takeLatest(PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST, getColumnsRestrictions);
}

function* onMapIdSet(action: PickAction<MapIdAction, typeof MAP_ID_SET>) {
  const clientId: number | null = yield select(state => state.userData.clientId);

  if (!action.payload.mapId || !clientId) {
    return;
  }

  yield put(presentationalColumnsRestrictionsGetDataRequest(clientId, action.payload.mapId));
}

function* getColumnsRestrictions(action: PickAction<PresentationalColumnsRestrictionsAction, typeof PRESENTATIONAL_COLUMNS_RESTRICTIONS_GET_DATA_REQUEST>) {
  try {
    const results: {data: PresentationalColumnsRestrictionsListResponse} = yield call(getPresentationalColumnsRestrictionList, action.payload.clientId, {
      map_id: action.payload.mapId,
    });

    const restrictions = new Map();

    for (const item of results.data.list) {
      const columnMap = restrictions.get(item.spreadsheet_id) ?? new Map();

      columnMap.set(item.column_id, {
        restrictionId: item.restriction_id,
        master: item.restrictions.master,
        marker: item.restrictions.marker,
        export: item.restrictions.export,
        filter: item.restrictions.filter,
      });

      restrictions.set(item.spreadsheet_id, columnMap);
    }

    yield put(presentationalColumnsRestrictionsGetDataSuccess(restrictions));
  }
  catch (e) {
    yield put(presentationalColumnsRestrictionsGetDataError());
  }
}

function* onColumnsRestrictionsSetRestrictionRequest(action: PickAction<PresentationalColumnsRestrictionsAction, typeof PRESENTATIONAL_COLUMNS_RESTRICTIONS_SET_RESTRICTION_REQUEST>) {
  const mapId: number | null = yield select(state => state.map.mapId);
  const clientId: number | null = yield select(state => state.userData.clientId);
  const columnsRestrictions: PresentationalColumnsRestrictionsStateRestrictions = yield select(state => state.spreadsheet.presentationalColumnsRestrictions.restrictions);

  if (!mapId || !clientId) {
    yield put(presentationalColumnsRestrictionsUpdateError());
    return;
  }

  try {
    const currentRestriction = columnsRestrictions.get(action.payload.spreadsheetColumnId.spreadsheetId)
      ?.get(action.payload.spreadsheetColumnId.columnId);

    const newRestrictionFlags: PresentationalColumnsRestrictionFlags = {
      master: currentRestriction?.master ?? false,
      export: currentRestriction?.export ?? false,
      marker: currentRestriction?.marker ?? false,
      filter: currentRestriction?.filter ?? false,
    };

    newRestrictionFlags[action.payload.restrictionFlag] = action.payload.value;

    if (currentRestriction) {
      yield call(updatePresentationalColumnsRestriction, clientId, [{
        restriction_id: currentRestriction.restrictionId,
        ...newRestrictionFlags,
      }]);

      yield put(presentationalColumnsRestrictionsUpdateSuccess([{
        restrictionId: currentRestriction.restrictionId,
        spreadsheetColumnId: action.payload.spreadsheetColumnId,
        restrictionFlags: newRestrictionFlags,
      }]));
    }
    else {
      const newRestrictions: {data: PresentationalColumnsRestrictionsCreateResponse} =
        yield call(createPresentationalColumnsRestriction, clientId, [{
          map_id: mapId,
          spreadsheet_id: action.payload.spreadsheetColumnId.spreadsheetId,
          column_id: action.payload.spreadsheetColumnId.columnId,
          ...newRestrictionFlags,
        }]);

      const updates = newRestrictions.data.records.map(record => ({
        restrictionId: record.restriction_id,
        spreadsheetColumnId: {
          columnId: record.column_id,
          spreadsheetId: record.spreadsheet_id,
        },
        restrictionFlags: record.restrictions,
      }));

      yield put(presentationalColumnsRestrictionsUpdateSuccess(updates));
    }
  }
  catch (e) {
    yield put(presentationalColumnsRestrictionsUpdateError());
  }
}
