import type { MapInfo } from '~/_shared/types/map';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import type { MapListingItem } from '~/map/listing/item/mapListingItem';

export enum MapListSortType {
  Name_ASC = 'name_asc',
  Name_DESC = 'name_desc',
  Updated_DESC = 'updated_at_desc',
  Layered = 'layered',
  Created_DESC = 'created_at_desc',
  Created_ASC = 'created_at_asc',
}

export const getSortOptions = (t: TranslationFnc) => ([{
  name: t('All Maps A to Z'),
  value: MapListSortType.Name_ASC,
}, {
  name: t('All Maps Z to A'),
  value: MapListSortType.Name_DESC,
}, {
  name: t('Most Recently Updated Maps'),
  value: MapListSortType.Updated_DESC,
}, {
  name: t('All Layered Maps'),
  value: MapListSortType.Layered,
}, {
  name: t('Newest to Oldest Maps'),
  value: MapListSortType.Created_DESC,
}, {
  name: t('Oldest to Newest Maps'),
  value: MapListSortType.Created_ASC,
},
]);

export const checkMapCanBeShared = (mapListingItem: MapListingItem | MapInfo) => {
  return mapListingItem.source !== 'salesforce';
};
