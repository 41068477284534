import { combineReducers } from 'redux';
import { mapMigrationReducer } from '~/store/mapMigration/mapMigration.reducer';
import { userEventsReducer } from '~/store/userEvents/userEvents.reducer';
import { announcementsReducer } from './announcements/announcements.reducer';
import { boundariesReducer } from './boundaries/boundaries.reducer';
import { demographicsReducer } from './demographics/demographics.reducer';
import { errorPageReducer } from './errorPage/errorPage.reducer';
import { frontendStateReducer } from './frontendState/frontendState.reducer';
import { mapReducer } from './map/map.reducer';
import { modalReducer } from './modal/modal.reducer';
import { polygonGroupsReducer } from './polygonGroups/polygonGroups.reducer';
import { spreadsheetReducer } from './spreadsheet/spreadsheet.reducer';
import { spreadsheetCellDataReducer } from './spreadsheetCellData/spreadsheetCellData.reducer';
import { themeReducer } from './theme/theme.reducer';
import { userDataReducer } from './userData/userData.reducer';

export const appReducer = combineReducers({
  errorPage: errorPageReducer,
  announcements: announcementsReducer,
  boundaries: boundariesReducer,
  demographics: demographicsReducer,
  frontendState: frontendStateReducer,
  map: mapReducer,
  mapMigration: mapMigrationReducer,
  modal: modalReducer,
  polygonGroups: polygonGroupsReducer,
  spreadsheet: spreadsheetReducer,
  spreadsheetCellData: spreadsheetCellDataReducer,
  theme: themeReducer,
  userData: userDataReducer,
  userEvents: userEventsReducer,
});
