export type MapMarkerInstance = {
  readonly id: Uuid;
  readonly lat: number;
  readonly lng: number;
  readonly anchor: MarkerAnchor;
  readonly size: number;
  readonly aspectRatio?: number;
};

export enum MarkerOutlineMode {
  Offset = 'Offset',
  LatLng = 'LatLng',
}
