import {
  useCallback, useMemo,
} from 'react';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';

type exportLocationFinderLocationsProps = {
  rows: {
    rowId: SpreadsheetRowId;
    distance: number;
  }[];
};

export const useExportLocationFinderLocations = () => {
  const [t] = useTranslation();
  const { replaceCurrentModal } = useModal(ModalType.ExportItemizedSpreadsheetData);

  const exportLocationFinderLocations = useCallback((props: exportLocationFinderLocationsProps) => {
    replaceCurrentModal({
      postfixColumns: [t('Distance')],
      items: [{
        rows: props.rows.map(row => ({
          rowId: row.rowId,
          postfixColumnsValues: [row.distance ? row.distance.toString() : '0'],
        })),
      }],
    });
  }, [replaceCurrentModal, t]);

  return useMemo(() => ({
    exportLocationFinderLocations,
  }), [exportLocationFinderLocations]);
};
