export const BYTES_IN_MEGABYTE = 1000000;

export const getStringMemorySizeInBytes = (s: string) =>
  s.length * 2;

export const getObjectStringMemorySizeInBytes = (o: any) => {
  const stringRepresentation = JSON.stringify(o);

  return getStringMemorySizeInBytes(stringRepresentation);
};
