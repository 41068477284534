import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { parsePasswordErrorType } from '~/authorization/passwordResetPage/passwordErrorType.helpers';
import { completeSalesforceRegistration } from '../salesforce.repository';

export const useSalesforceRegistrationFinish = () => {
  const [t] = useTranslation();

  const { isLoading, error: ajaxError, invokeAjax } = useAjaxCall(completeSalesforceRegistration);

  const generalError = useMemo(() => (
    <Trans
      i18nKey="salesforce.registerFinish.generalError"
      components={{
        email: <SupportLinkComponent type={SupportLinkType.info} />,
      }}
    />
  ), []);

  const error = ajaxError
    ? ajaxError.errors?.password?.[0] ? parsePasswordErrorType(ajaxError.errors?.password?.[0], t) : generalError
    : null;

  return {
    invoke: invokeAjax,
    error,
    isLoading,
  };
};
