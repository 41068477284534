import { isMobileScreen } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { type LeftSidebarAction } from '~/store/frontendState/leftSidebar/leftSidebar.action';
import {
  closeLeftSidebar, openLeftSidebarApp, openMapTool,
} from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

const REMOVE_STREET_VIEW_ICON_MOBILE = 'REMOVE_STREET_VIEW_ICON_MOBILE';

export const applySidebarMapOptions = (
  state: MapComponentOptionsState,
  action: LeftSidebarAction,
  { priority }: MapOptionsConfig
) => {
  if ((openLeftSidebarApp.match(action) || openMapTool.match(action)) && isMobileScreen()) {
    return push(state, {
      changeId: REMOVE_STREET_VIEW_ICON_MOBILE,
      priority,
      change: {
        streetViewControl: false,
      },
    });
  }

  if (closeLeftSidebar.match(action)) {
    return pop(state, REMOVE_STREET_VIEW_ICON_MOBILE);
  }
  return undefined;
};
