import { css } from '@emotion/react';
import {
  type FC,
  useCallback, useMemo,
} from 'react';
import {
  InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import { createColor } from '../../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithColorTileComponent } from '../../../_shared/components/colorPicker/colorPickerWithColorTile/colorPickerWithColorTile.component';
import { ColorPickerWithWheelComponent } from '../../../_shared/components/colorPicker/colorPickerWithWheel/colorPickerWithWheel.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { LOCATION_PANEL_DATA_CLASSNAME } from '../../customizeLocationPanel.constants';

type ColorFontProps = Readonly<{
  color: string;
  size: string;

  onColorChange: (value: string) => void;
  onSizeChange: (value: string) => void;
}>;

const TILE_DIMENSION = 24;

const inputWithLabelStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const inputStyle = css({
  marginRight: 4,
  width: 55,
});

const tileWheelWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const tileWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 24,
});

const commonPickerProps = {
  className: LOCATION_PANEL_DATA_CLASSNAME,
  displayAlpha: false,
};

export const ColorFontComponent: FC<ColorFontProps> = (props) => {
  const [t] = useTranslation();

  const onColorChange = useCallback((color: ColorResult) => {
    const propagateChange = props.onColorChange;
    propagateChange(color.hex);
  }, [props.onColorChange]);

  const selectedColor = useMemo(() => createColor(props.color), [props.color]);

  return (
    <>
      <div css={tileWheelWrapperStyle} >
        <div css={tileWrapperStyle}>
          <DebouncedUpdateComponent
            value={selectedColor}
            onChangeDebounced={onColorChange}
            wait={500}
            render={(value, onChange) => (
              <ColorPickerWithColorTileComponent
                selectedColor={value}
                onChange={onChange}
                isFixed
                height={TILE_DIMENSION}
                width={TILE_DIMENSION}
                {...commonPickerProps}
              />
            )}
          />
        </div>
        <DebouncedUpdateComponent
          value={selectedColor}
          onChangeDebounced={onColorChange}
          wait={500}
          render={(value, onChange) => (
            <ColorPickerWithWheelComponent
              {...commonPickerProps}
              selectedColor={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <span css={inputWithLabelStyle}>
        <InputWithSpinnersComponent
          size={InputSize.Small}
          css={inputStyle}
          value={Number(props.size)}
          onChange={value => props.onSizeChange(value.toString())}
          valueMin={0}
        />
        {t('Pixels')}
      </span>
    </>
  );
};
