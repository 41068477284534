import { useMemo } from 'react';
import { type LatLngBounds } from '~/_shared/types/latLng';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { isBoundaryTerritorySpecial } from '~/boundary/settings/boundarySettings.helpers';
import { type BoundingBox } from '~/map/map/boundingBox';
import { useGetBoundaryTerritoryDisplayNames } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useGetBoundaryTerritoryDisplayName';
import {
  type BoundaryGroupId, type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { type BoundaryStateItem } from '~/store/boundaryItems/boundaryItems.state';
import {
  type BoundaryTerritoryAssignment, useBoundaryTerritoryAssignments,
} from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroupSettings } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { type LastBounds } from '~/store/frontendState/mapComponent/mapComponent.state';
import { type BoundaryTextDimensionsCalculator } from '../../mapBoundary.manager';
import { type MapBoundaryDistanceToPixelsMap } from './useMapBoundaryDistanceToPixels';

type BoundaryLabel = Readonly<{
  text: string;
  bounds: LatLngBounds | null;
}>;

export type BoundaryLabelFilterMap = ReadonlyMap<BoundaryGroupId, (boundary: BoundaryStateItem) => BoundaryLabel | null>;

type UseMapBoundaryLabelFilterParams = {
  boundaryGroupIds: ReadonlyArray<number>;
  distanceToPixelsScale?: MapBoundaryDistanceToPixelsMap;
  textDimensionsCalculator?: BoundaryTextDimensionsCalculator;
};

const emptyFilter = () => null;

export const useMapBoundaryLabelFilter = ({ boundaryGroupIds, distanceToPixelsScale, textDimensionsCalculator }: UseMapBoundaryLabelFilterParams) => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const lastBounds = useMapComponentLastBoundsSelector();

  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const { boundaryTerritoryDisplayNames } = useGetBoundaryTerritoryDisplayNames();

  return useMemo<BoundaryLabelFilterMap>(() => {
    const result = new Map<BoundaryGroupId, (boundary: BoundaryStateItem) => BoundaryLabel | null>();

    if (!lastBounds || !distanceToPixelsScale || !textDimensionsCalculator) {
      return result;
    }

    for (const boundaryGroupId of boundaryGroupIds) {
      const boundaryGroup = boundaryGroups.find(g => g.id === boundaryGroupId);
      const boundaryTerritoryGroup = boundaryTerritoryGroups.find(btg => btg.boundaryGroupId === boundaryGroupId);

      if (!boundaryGroup || !boundaryTerritoryGroup) {
        result.set(boundaryGroupId, emptyFilter);
        continue;
      }

      const settings = boundaryTerritoryGroup.settings;

      const filter = getMapBoundaryLabelFilterMemoized(
        lastBounds,
        boundaryGroup.loadZoomLevel,
        distanceToPixelsScale,
        textDimensionsCalculator,
        settings,
        boundaryTerritoryAssignments.get(boundaryTerritoryGroup.boundaryTerritoryGroupId),
        boundaryTerritoryDisplayNames.get(boundaryTerritoryGroup.boundaryTerritoryGroupId)
      );

      result.set(boundaryGroupId, filter);
    }

    return result;
  }, [boundaryGroupIds, boundaryGroups, boundaryTerritoryAssignments, boundaryTerritoryDisplayNames,
    boundaryTerritoryGroups, distanceToPixelsScale, lastBounds, textDimensionsCalculator]);
};

const getMapBoundaryLabelFilter = (
  lastBounds: LastBounds,
  loadZoomLevel: number,
  distanceToPixelsScale: MapBoundaryDistanceToPixelsMap,
  textDimensionsCalculator: BoundaryTextDimensionsCalculator,
  settings: BoundaryTerritoryGroupSettings,
  boundaryTerritoryAssignment: BoundaryTerritoryAssignment | undefined,
  boundaryTerritoryDisplayNames: ReadonlyMap<BoundaryTerritoryId, string> | undefined,
): (boundary: BoundaryStateItem) => BoundaryLabel | null => {
  return (boundary: BoundaryStateItem) => {
    if (boundary.settings.isArtificial) {
      return null;
    }
    if (!boundary.labelBoundaries || !boundary.displayName) {
      return null;
    }

    if (loadZoomLevel > lastBounds.zoomLevel) {
      return null;
    }

    if (isLabelOutOfBounds(lastBounds.bounds, boundary.labelBoundaries)) {
      return null;
    }

    const pixelScale = distanceToPixelsScale[lastBounds.zoomLevel];
    if (!pixelScale) {
      return null;
    }

    const getTextFromBoundaryTerritory = () => {
      const boundaryTerritoryId = boundaryTerritoryAssignment?.boundaryAssignments.get(boundary.id);
      const boundaryTerritory = settings.boundaryTerritories.find(bt => bt.boundaryTerritoryId === boundaryTerritoryId);

      if (!boundaryTerritory || isBoundaryTerritorySpecial(boundaryTerritory)) {
        return null;
      }

      return boundaryTerritoryDisplayNames?.get(boundaryTerritory.boundaryTerritoryId) ?? null;
    };

    const text = settings.boundariesAsTerritories ? getTextFromBoundaryTerritory() ?? boundary.displayName : boundary.displayName;

    const boundsHeight = (boundary.labelBoundaries.ne.lat - boundary.labelBoundaries.sw.lat) * pixelScale.latSize;
    const boundsWidth = (boundary.labelBoundaries.ne.lng - boundary.labelBoundaries.sw.lng) * pixelScale.lngSize;

    const textHeight = textDimensionsCalculator.calculateMinHeight();
    const textWidth = textDimensionsCalculator.calculateMinWidth(boundary.displayName);

    if (boundsHeight < textHeight || boundsWidth < textWidth) {
      return null;
    }

    return {
      text,
      bounds: boundary.labelBoundaries,
    };
  };
};
const getMapBoundaryLabelFilterMemoized = memoizeWeak(getMapBoundaryLabelFilter);

const isLabelOutOfBounds = (bounds: BoundingBox, labelBoundaries: LatLngBounds) => !bounds.intersects(labelBoundaries);
