import { css } from '@emotion/react';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';

export enum ResolveTwoWaySyncFailedOption {
  GoogleToMaptiveSync = 'googleToMaptiveSync',
  TryAgain = 'tryAgain',
}

const contentStyle = css({
  padding: '16px 20px',
});

const footerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const negativeButtonStyle = ({ theme }: ThemeProps) => css({
  background: theme.buttonColors.secondaryBackground,
  height: 40,
  padding: '0 12px',
  '&:hover': {
    background: theme.buttonColors.secondaryBackgroundHover,
  },
});

const positiveButtonStyle = css({
  height: 40,
  padding: '0 12px',
});

const noteStyle = ({ theme, isDanger }: ThemeProps<{ isDanger?: boolean }>) => css({
  color: isDanger ? theme.textColors.danger : theme.textColors.contrast,
  fontSize: '14px',
});

const notePaddingTop = css({
  paddingTop: '5px',
});

const noteSectionPaddingBottom = css({
  paddingBottom: '20px',
});

const radioLabelStyle = css({
  display: 'flex',
  alignItems: 'start',
});

const radioTextStyle = css({
  fontSize: '16px',
});

const radioGroupDescriptionContainer = css({
  marginTop: 8,
});

export type ResolveUnableToTwoWaySyncModalProps = ModalProps<{
  onSync: (option: ResolveTwoWaySyncFailedOption) => void;
}>;

export const ResolveUnableToTwoWaySyncModal: FC<ResolveUnableToTwoWaySyncModalProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const [resolveTwoWaySyncFailedOption, setResolveTwoWaySyncFailedOption]
    = useState<ResolveTwoWaySyncFailedOption | null>(null);

  const handleSyncClick = useCallback(() => {
    props.onClose();
    if (resolveTwoWaySyncFailedOption) {
      props.onSync(resolveTwoWaySyncFailedOption);
    }
  }, [props, resolveTwoWaySyncFailedOption]);

  const footer = useMemo(() => (
    <div css={footerStyle}>
      <ButtonComponent
        css={negativeButtonStyle({ theme })}
        text={t('Cancel')}
        onClick={() => props.onClose()}
      />
      <ButtonComponent
        css={positiveButtonStyle}
        prefixIcon={faSync}
        text={t('Sync')}
        onClick={handleSyncClick}
        isDisabled={resolveTwoWaySyncFailedOption === null}
      />
    </div>
  ), [handleSyncClick, props, resolveTwoWaySyncFailedOption, t, theme]);

  return (
    <ModalComponent
      caption={t('Sync 2 Directions: Google Sheets >> Maptive and Maptive >> Google Sheets')}
      isOpen={props.isOpen}
      maxWidth={800}
      onClose={props.onClose}
      footer={footer}
      contentStyle={contentStyle}
    >
      <span css={noteStyle({ theme })}>{t('Unable to match Google and Maptive spreadsheet columns.')}</span>
      <br />
      <div css={[noteStyle({ theme }), noteSectionPaddingBottom]}>
        {t('Please select how you wish this irregularity to be resolved.')}
      </div>

      <RadioGroupComponent<ResolveTwoWaySyncFailedOption>
        labelStyle={radioLabelStyle}
        onValueChange={setResolveTwoWaySyncFailedOption as (uIdType: ResolveTwoWaySyncFailedOption) => void}
        selectedValue={resolveTwoWaySyncFailedOption}
        items={[{
          label: <span css={radioTextStyle}>{t('Override your Spreadsheet Data')}</span>,
          value: ResolveTwoWaySyncFailedOption.GoogleToMaptiveSync,
          children: (
            <div css={radioGroupDescriptionContainer}>
              <span css={[noteStyle({ theme }), notePaddingTop]}>
                {t('googleSheets.sync.overrideFromGoogle.desc')}
              </span>
              &nbsp;<br />
              <span css={[noteStyle({ theme, isDanger: true })]}>
                {t('googleSheets.sync.overrideFromGoogle.note')}
              </span>
            </div>),
        }, {
          label: <span css={radioTextStyle}>{t('googleSheets.sync.tryAgain.caption')}</span>,
          value: ResolveTwoWaySyncFailedOption.TryAgain,
          children: (
            <div css={radioGroupDescriptionContainer}>
              <span css={[noteStyle({ theme })]}>
                {t('googleSheets.sync.tryAgain.desc')}
              </span>
              &nbsp;<br />
              <span css={[noteStyle({ theme })]}>
                {t('googleSheets.sync.tryAgain.warning')}
              </span>
            </div>),
        }]}
      />
    </ModalComponent>
  );
};
