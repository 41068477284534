import { css } from '@emotion/react';
import { faPalette } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { EmptyStateComponent } from '~/_shared/components/emptyState/emptyState.component';
import { useTranslation } from '~/_shared/utils/hooks';

const wrapperStyle = css({
  padding: '50px 30px 16px',
});

export const DrawingToolNoItemsComponent: FC = () => {
  const [t] = useTranslation();

  return (
    <EmptyStateComponent
      css={wrapperStyle}
      icon={faPalette}
      title={t('No Drawn Elements')}
      description={t('Elements you draw on the map can be viewed and managed here')}
    />
  );
};
