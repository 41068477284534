import { css } from '@emotion/react';

export const optionsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

export const submitButtonStyle = css({
  height: 48,
  fontSize: '18px',
  width: '100%',
  marginTop: 32,
});

export const headingStyle = css({
  display: 'block',
  fontWeight: 700,
  fontSize: '18px',
  margin: '15px 0 22px',
});
