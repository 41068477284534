import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import { HintTooltipComponent } from '~/_shared/baseComponents/tooltip/hintTooltip.component';
import { useIsMapTopViewBlocked } from '~/map/map/loader/overMapLoader.container';
import { TooltipComponent } from '../baseComponents/tooltip/tooltip.component';
import { FloatingBoxComponent } from '../components/floatingBox/floatingBox.component';
import { FloatingBoxContainer } from '../components/floatingBox/floatingBox.container';
import { useTheme } from '../themes/theme.hooks';
import { type ThemeProps } from '../types/themeProps';
import { useTranslation } from '../utils/hooks';

export type MapConfirmButtonProps = {
  icon: IconProp;
  onClick?: () => void;
  buttonStyle?: ButtonStyle;
  label: string;
  className?: string;
  isDisabled?: boolean;
  tooltip?: string;
  hint?: string;
};

export type MapConfirmProps = Readonly<{
  isOpen: boolean;
  onClose?: () => void;
  dropdownOptions?: ReadonlyArray<DropdownOption<string>>;
  dropdownListStyle?: SerializedStyles;
  dropdownHeading?: string;
  selectedOptionId?: string | null;
  onDropdownSelect?: (optionId: string) => void;
  heading: string;
  dropdownTriggerStyle?: SerializedStyles;
  triggerPlaceholderStyle?: SerializedStyles;
  className?: string;
  buttons: MapConfirmButtonProps[];
  buttonsWrapperStyle?: SerializedStyles;
  radioHeading?: string;
  radio?: {
    heading: string;
    items: ReadonlyArray<RadioGroupItem<string>>;
    onChange: (value: string) => void;
    selectedValue: string;
  };
  children?: React.ReactNode;
}>;

const dropdownTriggerStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  height: 40,
  marginRight: 10,
  width: 'auto',
});

const floatingBoxContainerStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  top: 20,
  left: 20,
});

const buttonsWrapperStyle = css({
  display: 'flex',
});

const sectionWrapperStyle = css({
  marginTop: 20,
  '&:first-of-type': {
    marginTop: 0,
  },
});

const triggerHiddenIconStyle = css({
  display: 'none',
});

const buttonStyle = css({
  padding: '8px 16px',
});

const buttonItemStyle = css({
  display: 'flex',
  gap: 4,
  marginRight: 20,
  '&:last-of-type': {
    marginRight: 0,
  },
});

const dropdownItemStyle = ({ theme, roundTopRightCorner }: ThemeProps<{ roundTopRightCorner: boolean }>) => css({
  color: theme.textColors.primary,
  fontSize: '18px',
  padding: 20,
  height: 'auto',
  '&:first-of-type': {
    borderRadius: roundTopRightCorner ? '0 4px 0 0' : undefined,
  },
});

const dropdownListStyle = ({ roundTopRightCorner }: { roundTopRightCorner: boolean }) => css({
  minWidth: 150,
  borderRadius: roundTopRightCorner ? '0 4px 4px 4px' : '0 0 4px 4px',
});

const dropdownItemIconStyle = css({
  marginRight: 8,
});

const sectionHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
});

const radioGroupStyle = css({
  marginTop: 10,
});

const radioItemStyle = css({
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  '&:first-of-type': {
    marginLeft: 0,
  },
});

////////////////////////////////////////////////////////////////////////////////////////////////////
// When this component is used on the map, don't forget to register action in useIsMapTopViewBlocked
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
useIsMapTopViewBlocked;
////////////////////////////////////////////////////////////////////////////////////////////////////
export const MapConfirmComponent: FC<MapConfirmProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  if (!props.isOpen) {
    return null;
  }

  const roundTopRightCorner = props.selectedOptionId !== null;
  const showDropdown = props.dropdownOptions && props.dropdownOptions.length > 0;

  return (
    <FloatingBoxContainer
      css={floatingBoxContainerStyle}
    >
      <FloatingBoxComponent
        isOpen={props.isOpen}
        onClose={props.onClose}
        heading={props.heading}
        className={props.className}
      >
        {showDropdown && (
          <div css={sectionWrapperStyle}>
            {props.dropdownHeading !== undefined &&
              <p css={sectionHeadingStyle}>{props.dropdownHeading}</p>
            }
            <RegularDropdownComponent
              inPortal
              itemStyle={dropdownItemStyle({ theme, roundTopRightCorner })}
              itemIconStyle={dropdownItemIconStyle}
              options={props.dropdownOptions}
              triggerStyle={css(dropdownTriggerStyle({ theme }), props.dropdownTriggerStyle)}
              triggerPlaceholderStyle={props.triggerPlaceholderStyle}
              value={props.selectedOptionId}
              listStyle={css([dropdownListStyle({ roundTopRightCorner }), props.dropdownListStyle])}
              onChange={(id) => {
                if (id !== undefined && props.onDropdownSelect) {
                  props.onDropdownSelect(id);
                }
              }}
              placeholder={t('Select Desired Option')}
              isDisabled={props.dropdownOptions.length === 1}
              triggerIconStyle={props.dropdownOptions.length === 1 ? triggerHiddenIconStyle : undefined}
            />
          </div>
        )}

        {props.radio && (
          <div css={sectionWrapperStyle}>
            <p css={sectionHeadingStyle}>{props.radio.heading}</p>

            <RadioGroupComponent
              css={radioGroupStyle}
              itemCommonStyle={radioItemStyle}
              items={props.radio.items}
              onValueChange={props.radio.onChange}
              selectedValue={props.radio.selectedValue}
            />
          </div>
        )}

        {props.buttons.length > 0 && (
          <div css={[sectionWrapperStyle, buttonsWrapperStyle, props.buttonsWrapperStyle]}>
            {props.buttons.map((button, index) => (
              <div
                css={buttonItemStyle}
                key={index}
              >
                {button.hint && <HintTooltipComponent tooltipContent={button.hint} size={16} />}
                <TooltipComponent tooltipContent={button.tooltip}>
                  <ButtonComponent
                    prefixIcon={button.icon}
                    text={button.label}
                    css={buttonStyle}
                    buttonStyle={button.buttonStyle}
                    onClick={button.onClick}
                    isDisabled={button.isDisabled}
                  />
                </TooltipComponent>
              </div>
            ))}
          </div>
        )}
      </FloatingBoxComponent>

      {props.children}
    </FloatingBoxContainer>
  );
};
