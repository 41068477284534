import { css } from '@emotion/react';
import { useState } from 'react';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type UniqueGroup } from '~/_shared/types/grouping/grouping';
import { type MapInfo } from '~/_shared/types/map';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { togglePresence } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BaseMapInfos } from '~/map/layered/layering.helpers';
import {
  type LayeringType, type MapIntersect,
} from '~/map/map.repository';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { ConnectivitySettingsComponent } from '~/sidebar/sidebarApps/mapTools/layeringTool/connectivitySettings.component';
import { FilterViewLayersComponent } from '~/sidebar/sidebarApps/mapTools/layeringTool/filterViewLayers.component';
import { IntegratedColumnComponent } from '~/sidebar/sidebarApps/mapTools/layeringTool/integratedColumn.component';
import { MapToolWrapperComponent } from '~/sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';

const expandedSubItemStyle = ({ theme, hideBorder }: ThemeProps<{hideBorder: boolean}>) => css({
  borderBottom: `1px solid${hideBorder ? theme.accordionColors.borderColor : theme.accordionColors.headingBorderActive}`, //To prevent a double border (header bottom and first child top when active)
});

const createNewButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'calc(100% - 32px)',
  height: 48,
  margin: '24px 16px',
});

const bodyRootStyle = css({
  padding: 0,
});

const headingStyle = css({
  textTransform: 'uppercase',
});

type LayeringToolProps = Readonly<{
  layering?: LayeringType;
  matches: Readonly<MapIntersect[]>;
  baseMapsNames: Readonly<BaseMapInfos>;
  sourceColumns: Readonly<{
    [realSpreadsheetId: string]: {
      [column: string]: string;
    };
  }>;
  baseMapsUniqueGroupColumns: UniqueGroup[];
  onSelectLayer: (layer: UniqueGroup) => () => void;
  mapInfo: MapInfo | null;
  onDisconnectMap: () => void;
  selectedLayersIds: string[];
}>;

export const LayeringToolComponent: React.FC<LayeringToolProps> = ({
  layering,
  matches,
  baseMapsNames,
  sourceColumns,
  baseMapsUniqueGroupColumns,
  onSelectLayer,
  mapInfo,
  onDisconnectMap,
  selectedLayersIds,
}) => {
  const { openModal: openCreateModal } = useModal(ModalType.CreateLayeredMap);
  const { openModal: openEditModal } = useModal(ModalType.EditLayeredMap);
  const [expandedColumns, setExpandedColumns] = useState<ReadonlySet<number>>(new Set([]));
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <MapToolWrapperComponent>
      <ButtonComponent
        css={createNewButtonStyle}
        onClick={() => openCreateModal({})}
        text={t('Create New Layered Map')}
      />

      {layering && (
        <>
          {layering.connected && (
            <ButtonComponent
              css={createNewButtonStyle}
              onClick={() => openEditModal()}
              text={t('Edit Layered Map')}
            />
          )}

          <OutlinePanelComponent outlines={[OutlinePanelOptions.Bottom]}>
            <AccordionComponent
              data={[{
                header: <span css={headingStyle}>{t('Filter Maps')}</span>,
                child: (
                  <FilterViewLayersComponent
                    baseMapsUniqueGroupColumns={baseMapsUniqueGroupColumns}
                    isLayeredMapConnected={layering.connected}
                    onSelectLayer={onSelectLayer}
                    selectedLayersIds={selectedLayersIds}
                  />
                ),
                isExpanded: true,
                isLocked: true,
              }]}
              headerSize={AccordionHeaderSize.Large}
              panelStyle={bodyRootStyle}
            />
            <AccordionComponent
              data={[{
                header: <span css={headingStyle}>{t('Connectivity Settings')}</span>,
                child: (
                  <ConnectivitySettingsComponent
                    mapInfo={mapInfo}
                    onDisconnectMap={onDisconnectMap}
                  />
                ),
                isExpanded: true,
                isLocked: true,
              }]}
              headerSize={AccordionHeaderSize.Large}
              panelStyle={bodyRootStyle}
            />
            <AccordionComponent
              data={[{
                header: <span css={headingStyle}>{t('Integrated Columns')}</span>,
                child: (
                  <AccordionComponent
                    panelStyle={bodyRootStyle}
                    data={(matches.map((match, index) => ({
                      header: match.name,
                      child: Object.keys(match?.intersects).map(spreadsheetId => (
                        <IntegratedColumnComponent
                          key={spreadsheetId}
                          mapName={baseMapsNames[spreadsheetId.replace('spreadsheet_', '')]?.name ?? '-'}
                          columnName={sourceColumns?.[spreadsheetId]?.[match.intersects[spreadsheetId] ?? ''] ?? '-'}
                        />
                      )),
                      isExpanded: expandedColumns.has(index),
                      onHeadingClick: () => setExpandedColumns(togglePresence(expandedColumns, index)),
                    })))}
                  />
                ),
                isExpanded: true,
                isLocked: true,
              }]}
              expandedItemStyle={expandedSubItemStyle({ theme, hideBorder: expandedColumns.has(0) })}
              headerSize={AccordionHeaderSize.Large}
              panelStyle={bodyRootStyle}
            />
          </OutlinePanelComponent>

        </>
      )}
    </MapToolWrapperComponent>
  );
};
