import { clamp } from '~/_shared/utils/number/number.helpers';
import { SPLIT_SCREEN_GUTTER_SIZE } from './mapPage.component';

const SPLIT_SCREEN_MIN_INITIAL_HEIGHT_RATIO = 0.36;
const SPLIT_SCREEN_MIN_MAP_HEIGHT_RATIO = 0.25;

export const parseMapId = (mapId?: string): number | null => {
  if (mapId === undefined || mapId === null) {
    return null;
  }

  const parsedMapId = parseInt(mapId, 10);

  if (isNaN(parsedMapId)) {
    return null;
  }

  return parsedMapId;
};

export const getDefaultSplitPanelContentHeight = (splitPanelEl: HTMLElement, wrapperEl: HTMLElement) => {
  const wrapperHeight = wrapperEl.getBoundingClientRect().height;
  const splitPanelContentHeight = splitPanelEl.getBoundingClientRect().height;
  const splitPanelRatio = Math.ceil(100 * (splitPanelContentHeight + SPLIT_SCREEN_GUTTER_SIZE) / wrapperHeight);
  const clampedSplitPanelRatio = clamp(
    splitPanelRatio,
    {
      min: 100 * SPLIT_SCREEN_MIN_INITIAL_HEIGHT_RATIO,
      max: (1 - SPLIT_SCREEN_MIN_MAP_HEIGHT_RATIO) * 100,
    });
  return clampedSplitPanelRatio;
};

export const getMapMinHeight = (splitPanelEl: HTMLElement, wrapperEl: HTMLElement, isMobileScreen: boolean) => {
  const wrapperHeight = wrapperEl.getBoundingClientRect().height;
  let newMapMinHeight = wrapperHeight * SPLIT_SCREEN_MIN_MAP_HEIGHT_RATIO;

  if (splitPanelEl && isMobileScreen) {
    const splitPanelContentHeight = splitPanelEl.getBoundingClientRect().height;

    if (splitPanelContentHeight < newMapMinHeight) {
      newMapMinHeight = wrapperHeight - splitPanelContentHeight - SPLIT_SCREEN_GUTTER_SIZE;
    }
  }
  return newMapMinHeight;
};
