import { useMemo } from 'react';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { type LatLng } from '~/_shared/types/latLng';
import { useMap } from '~/map/map/mapContext';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useMapZoomChangeFinished } from '../../../../_shared/utils/hooks/useMapZoomChangeFinished';
import {
  type MapMarkerInstance, MarkerOutlineMode,
} from './mapMarkerModel';

const zeroOffset = { offsetX: 0, offsetY: 0 };

export const useMapMarkerOutlines = (
  instance: MapMarkerInstance,
  options: {
    predicate: boolean;
    mode: MarkerOutlineMode;
    hideDuringZoomChangeAnimation?: boolean;
  },
): MapOutlinePositionInstance[] => {
  const map = useMap();
  const overlay = useProjectionOverlay(map);
  const mapZoom = useMapComponentZoomSelector();

  const { zoomChangeEtag, lastZoom } = useMapZoomChangeFinished(options.predicate);

  return useMemo((): MapOutlinePositionInstance[] => {
    if (!zoomChangeEtag || mapZoom === null) {
      return [];
    }

    // Hide outlines when map is performing zoom animation in LatLng mode.
    if (options.hideDuringZoomChangeAnimation && mapZoom !== lastZoom) {
      return [];
    }

    const aspectRatio = instance.aspectRatio ?? 1;
    const realWidth = instance.size * aspectRatio;
    const realHeight = instance.size;

    const relativeOffsets: [MapOutlinePosition, {x: number; y: number}][] = [
      [MapOutlinePosition.BottomLeft, { x: -realWidth / 2, y: 0 }],
      [MapOutlinePosition.Bottom, { x: 0, y: 0 }],
      [MapOutlinePosition.BottomRight, { x: realWidth / 2, y: 0 }],
      [MapOutlinePosition.TopLeft, { x: -realWidth / 2, y: -realHeight }],
      [MapOutlinePosition.Top, { x: 0, y: -realHeight }],
      [MapOutlinePosition.TopRight, { x: realWidth / 2, y: -realHeight }],
      [MapOutlinePosition.Left, { x: -realWidth / 2, y: -realHeight / 2 }],
      [MapOutlinePosition.Right, { x: realWidth / 2, y: -realHeight / 2 }],
    ];

    const outlineOffsets: [MapOutlinePosition, {x: number; y: number}][] = relativeOffsets.map(([position, offset]) => [position, {
      x: offset.x,
      y: offset.y + (instance.anchor === 'center' ? realHeight / 2 : 0),
    }]);

    if (options.mode === MarkerOutlineMode.LatLng) {
      return outlineOffsets
        .map(([position, offset]) => [
          position,
          overlay.moveLatLngByPixelsOnCurrentZoom({ lat: instance.lat, lng: instance.lng }, new google.maps.Point(offset.x, offset.y)),
        ])
        .filter((tuple): tuple is [MapOutlinePosition, LatLng] => !!tuple[1])
        .map(([position, latLng]) => ({
          id: getMapMarkerOutlineId(instance.id, position),
          position,
          ...zeroOffset,
          ...latLng,
        }));
    }
    else {
      return outlineOffsets
        .map(([position, offset]) => ({
          id: getMapMarkerOutlineId(instance.id, position),
          position,
          lat: instance.lat,
          lng: instance.lng,
          offsetX: offset.x,
          offsetY: offset.y,
        }));
    }

  }, [instance.anchor, instance.aspectRatio, instance.id, instance.lat, instance.lng, instance.size, mapZoom,
    options.hideDuringZoomChangeAnimation, options.mode, overlay, zoomChangeEtag, lastZoom]);
};

const getMapMarkerOutlineId = (markerId: string, position: MapOutlinePosition): string =>
  `${markerId}-${position}`;
