import {
  type FC, useCallback, useEffect, useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { LocationListingContainer } from '~/_shared/components/locationListingPanel/locationListing.container';
import { activeMapElementsClearState } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useSlidingAnimationDuration } from '~/store/frontendState/graphicSettings/graphicSettings.selectors';
import { ProximityDetailsPanelContainer } from '../../../proximityDetails/proximityDetailsPanel.container';
import { BoundaryDetailsContainer } from './boundaryDetails/boundaryDetails.container';
import { BoundaryTerritoryDetailsContainer } from './boundaryTerritoryDetails/boundaryTerritoryDetails.container';
import { DrawingToolDetailsContainer } from './drawingToolDetails/drawingToolDetails.container';
import { useGetRightSidebarContent } from './helpers/useGetRightSidebarContent';
import { LocationPanel } from './locationPanel.component';
import {
  type RightSidebarAnimationControllerMethods,
  RightSidebarComponent,
} from './rightSidebar.component';
import {
  type RightSidebarConfiguration, type RightSidebarOpeneableApps, RightSidebarView,
} from './rightSidebarConfiguration';
import { decideSidebarWidth } from './rightSidebarContent.helpers';

export const RightSidebarContainer: FC = () => {
  const dispatch = useDispatch();
  const isMobileScreen = useIsMobileScreenSelector();
  const contents = useGetRightSidebarContent();
  const slidingAnimationDuration = useSlidingAnimationDuration();

  const [openedApps, setOpenedApps] = useState<Set<RightSidebarOpeneableApps>>(new Set());
  const [sidebarWidth, setSidebarWidth] = useState(0);

  const animationControllerRef = useRef<RightSidebarAnimationControllerMethods>(null);

  const close = useCallback(() => {
    dispatch(activeMapElementsClearState());

    setOpenedApps(new Set());
  }, [dispatch]);

  const handleOpenOrCloseApp = useCallback((app: RightSidebarOpeneableApps, isOpened: boolean) => {
    if (isOpened) {
      setOpenedApps(apps => new Set(apps.add(app)));
    }
    else {
      setOpenedApps(apps => {
        apps.delete(app);

        return new Set(apps);
      });
    }
  }, []);

  useEffect(() => {
    setSidebarWidth(decideSidebarWidth(!!contents, openedApps));
  }, [contents, openedApps]);

  useEffect(() => {
    setOpenedApps(new Set());
  }, [contents?.view]);

  return (
    <RightSidebarComponent
      animationControllerRef={animationControllerRef}
      width={sidebarWidth}
      isMobileScreen={isMobileScreen}
      slidingAnimationDuration={slidingAnimationDuration}
    >
      {renderContent(contents, close, handleOpenOrCloseApp)}
    </RightSidebarComponent>
  );
};

const renderContent = (
  configuration: RightSidebarConfiguration | null,
  onClose: () => void,
  handleOpenOrCloseApp: (app: RightSidebarOpeneableApps, isOpened: boolean) => void,
) => {
  if (configuration === null) {
    return undefined;
  }

  switch (configuration.view) {
    case RightSidebarView.LocationListing:
      return (
        <LocationListingContainer
          onClose={onClose}
        />
      );

    case RightSidebarView.ProximityDetails: {
      return (
        <ProximityDetailsPanelContainer
          activeMetrics={configuration.activeMetrics}
          onClose={onClose}
        />
      );
    }

    case RightSidebarView.CustomizeLocation: {
      return (
        <LocationPanel
          markerId={configuration.markerId}
          onClose={onClose}
          openOrCloseApp={handleOpenOrCloseApp}
        />
      );
    }

    case RightSidebarView.BoundaryDetails: {
      return (
        <BoundaryDetailsContainer
          boundaryId={configuration.boundaryId}
          boundaryGroupId={configuration.boundaryGroupId}
          boundaryTerritoryGroupId={configuration.boundaryTerritoryGroupId}
          onClose={onClose}
        />
      );
    }

    case RightSidebarView.BoundaryTerritoryDetails: {
      return (
        <BoundaryTerritoryDetailsContainer
          boundaryTerritoryGroupId={configuration.boundaryTerritoryGroupId}
          boundaryTerritoryId={configuration.boundaryTerritoryId}
          onClose={onClose}
        />
      );
    }

    case RightSidebarView.DrawingToolDetails: {
      return (
        <DrawingToolDetailsContainer
          onClose={onClose}
        />
      );
    }

    default:
      throw new Error(`rightSidebar: unknown contentType: ${configuration}`);
  }
};
