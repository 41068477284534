import { css } from '@emotion/react';
import { type FC } from 'react';
import { blinkLoaderStyle } from '~/_shared/components/loader/blinkLoader.styles';
import { SIDEBAR_WIDTH } from '~/sidebar/sidebar.component';

const itemStyle = (backgroundColor: string) => css({
  borderRadius: 4,
  backgroundColor,
  height: 32,
  width: `calc(100% + ${SIDEBAR_WIDTH / 4}px)`,
  maxWidth: SIDEBAR_WIDTH / 3 * 2,
});

type LocationListingItemSkeletonProps = Readonly<{
  animationColor?: string;
  animationDurationMs: number;
  animationDelayMs: number;
  backgroundColor: string;
}>;

export const LocationListingItemAddressSkeletonComponent: FC<LocationListingItemSkeletonProps> = (props) => (
  <div
    data-testid="listingItemSkeleton"
    css={[
      itemStyle(props.backgroundColor),
      blinkLoaderStyle(props.animationDurationMs, props.animationDelayMs, props.animationColor),
    ]}
  />
);
