import { css } from '@emotion/react';
import {
  faCopy,
  faEllipsisV, faExternalLink,
  faSortNumericDown, faUpload,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { useTranslation } from '~/_shared/utils/hooks';
import { useWmsFeatureBlocker } from '../boundaryItem/useWmsFeatureBlocker';

type ActiveBoundaryContextMenuProps = Readonly<{
  boundaryGroupId: number;
  isBoundaryGroupCustom: boolean;
  onExportClick?: () => void;
  onConvert?: () => void;
  onCustomizeMetricsClick: () => void;
  onClone?: () => void;
}>;

const toolbarButtonStyle = css({
  marginLeft: 6,
});

const triggerButtonStyle = ({ isMenuOpen, boundaryIsUnderWmsTrial }: { isMenuOpen: boolean; boundaryIsUnderWmsTrial: boolean }) =>
  boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : isMenuOpen ? RoundButtonStyle.Quaternary : RoundButtonStyle.Primary;

export const ActiveBoundaryContextMenuComponent: FC<ActiveBoundaryContextMenuProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [t] = useTranslation();
  const { boundaryIsUnderWmsTrial, blockWhenWmsTrial } = useWmsFeatureBlocker(props.boundaryGroupId);

  return (
    <ContextMenuComponent
      isVisible={isMenuOpen}
      onHide={() => setIsMenuOpen(false)}
      maxWidth={280}
      renderMenuContent={() => (
        <>
          <ContextMenuItemCoreComponent>
            <ContextMenuDefaultItem
              icon={faSortNumericDown}
              onClick={props.onCustomizeMetricsClick}
              size="s"
            >
              {t(props.isBoundaryGroupCustom ? 'Customize Territory Metrics' : 'Customize Boundary Metrics')}
            </ContextMenuDefaultItem>
          </ContextMenuItemCoreComponent>

          {props.onClone && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faCopy}
                onClick={props.onClone}
                size="s"
              >
                {t('Copy Territory Group')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onConvert && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faUpload}
                onClick={props.onConvert}
                size="s"
              >
                {t('Convert Groups to Territories')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onExportClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faExternalLink}
                onClick={props.onExportClick}
                size="s"
              >
                {t(props.isBoundaryGroupCustom ? 'Export Data with Territories' : 'Export Data with Boundaries')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}
        </>
      )}
    >

      <div css={toolbarButtonStyle}>
        <RoundButtonComponent
          icon={faEllipsisV}
          onClick={blockWhenWmsTrial(() => setIsMenuOpen(true))}
          size={RoundButtonSize.Small}
          buttonStyle={triggerButtonStyle({ isMenuOpen, boundaryIsUnderWmsTrial })}
        />
      </div>
    </ContextMenuComponent>
  );
};
