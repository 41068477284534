import {
  memo, type ReactNode,
} from 'react';
import { type LatLng } from '../../../../../_shared/types/latLng';
import { useMapShapeContext } from './mapShapeContext';

type MapShapeLinesProps = {
  renderLine: (key: string, start: LatLng, end: LatLng) => ReactNode;
};

const MapShapeLines: React.FC<MapShapeLinesProps> = ({ renderLine }) => {
  const { shape, isPolygon } = useMapShapeContext();

  return (
    <>
      {shape.outlines.map((outline, index) => {
        if (index > 0) {
          const previousOutline = shape.outlines[index - 1];
          if (previousOutline) {
            return renderLine(`${previousOutline.id}:${outline.id}`, previousOutline, outline);
          }
        }

        return;
      })}
      {isPolygon
        ? renderLine(`${shape.outlines.lastItem.id}:${shape.outlines[0].id}`, shape.outlines.lastItem, shape.outlines[0])
        : null}
    </>
  );
};

const pureComponent = memo(MapShapeLines);
export { pureComponent as MapShapeLines };
