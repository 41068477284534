import { useMemo } from 'react';
import { DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE } from '~/drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import {
  type DrawingTextAreaSize, type DrawingTextAreaSizeProps,
} from './useDrawingToolTextAreaScalingToggle';

const radiusPaddingCoef = 250;

// Contains inverted equasion to getDrawingToolTexAreaFullSize. Don't forget to update the other one when changing anything.
export const getDrawingToolTextAreaPadding = (props: {
  readonly width: number;
  readonly height: number;
  readonly strokeWidth: number;
  readonly borderRadius: number;
}) => props.strokeWidth + Math.min(props.height, props.width) * props.borderRadius / radiusPaddingCoef;

// Contains inverted equasion to getDrawingToolTextAreaPadding. Don't forget to update the other one when changing anything.
export const getDrawingToolTexAreaFullSize = (props: {
  readonly widthWithoutPadding: number;
  readonly heightWithoutPadding: number;
  readonly strokeWidth: number;
  readonly borderRadius: number;
}) => {
  const smallerDimension = Math.min(props.heightWithoutPadding, props.widthWithoutPadding);

  const dimensionWithPaddings = (smallerDimension + 2 * props.strokeWidth) / (1 - (2 * props.borderRadius / radiusPaddingCoef));
  const padding = (dimensionWithPaddings - smallerDimension) / 2;

  return {
    width: props.widthWithoutPadding + padding * 2,
    height: props.heightWithoutPadding + padding * 2,
  };
};

export const useDrawingToolTextAreaSize = (props: DrawingTextAreaSizeProps): DrawingTextAreaSize | null => {
  const sizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  return useMemo(() => {
    if (!props.scalesWithMapZoom) {
      return {
        width: props.width,
        height: props.height,
        fontSize: props.fontSize,
      };
    }
    else {
      const scaledFontSize = Math.min(
        Math.round(props.fontSize / sizePerPixelRatio),
        DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to);

      const fontSizeRatio = props.fontSize / scaledFontSize;
      const ratio = fontSizeRatio ? fontSizeRatio : sizePerPixelRatio;

      const scaledPadding = getDrawingToolTextAreaPadding({
        width: props.width,
        height: props.height,
        strokeWidth: props.strokeWidth,
        borderRadius: props.borderRadius,
      });

      const newDimensions = {
        width: (props.width - scaledPadding * 2) / ratio,
        height: (props.height - scaledPadding * 2) / ratio,
      };

      return {
        ...getDrawingToolTexAreaFullSize({
          strokeWidth: props.strokeWidth,
          borderRadius: props.borderRadius,
          widthWithoutPadding: newDimensions.width,
          heightWithoutPadding: newDimensions.height,
        }),
        fontSize: scaledFontSize,
      };
    }
  }, [props.scalesWithMapZoom, props.width, props.height, props.fontSize, props.strokeWidth,
    props.borderRadius, sizePerPixelRatio]);
};
