import { css } from '@emotion/react';
import {
  faExclamationTriangle, faMinus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { type Scrollbar } from 'react-scrollbars-custom';
import {
  getRegularDropdownTriggerSize, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { AddMorePlaceholderComponent } from '~/_shared/components/addMorePlaceholder/addMorePlaceholder.component';
import {
  createColor, hexColorWithOpacityToString,
  rgbColorToString,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { type MapInfo } from '~/_shared/types/map';
import { type PerSpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { delay } from '~/_shared/utils/delay';
import { useTranslation } from '~/_shared/utils/hooks';
import { type IntersectsMapItem } from '~/map/layered/createLayeredMapModal/MatchupColumnsSection/matchupColumnsSection.helpers';
import { type MapIntersect } from '~/map/map.repository';
import { LayeredMapSectionHolderComponent } from '../../layeredMapSectionHolderComponent';
import { SectionAutocompleteComponent } from './SectionAutocomplete/sectionAutocomplete.component';
import { useMatchupColumnsSection } from './useMatchupColumnsSection';

const DROPDOWN_TRIGGER_SIZE = InputSize.Medium;
const MAIN_COLUMN_WIDTH = 223;
const SECONDARY_COLUMN_WIDTH = 183;
const MAP_NAME_BOTTOM_MARGIN = 8;
const SCROLLBAR_MARGIN = 2;
const UNIFIED_MARGIN = 8;
const MAX_VERTICAL_HEIGHT = 250;
const MAP_NAME_HEIGHT = 32;
const COLUMN_HEIGHT = getRegularDropdownTriggerSize(DROPDOWN_TRIGGER_SIZE);
const MAX_ITEMS_IN_COLUMN = Math.floor(MAX_VERTICAL_HEIGHT / ((COLUMN_HEIGHT + UNIFIED_MARGIN) - UNIFIED_MARGIN));
const SCROLL_DELAY = 50;
const REMOVE_ICON_FONT_SIZE = 18;

const containerStyle = css({
  display: 'flex',
});

const baseMapWrapperStyle = (theme: Theme) => css({
  background: hexColorWithOpacityToString(theme.borderColors.primary, .5),
  border: `1px solid ${theme.borderColors.primary}`,
  padding: '0 8px',
  marginTop: 8,
  borderRadius: 4,
});

const baseMapsScrollbarStyle = css({
  display: 'inline-flex',
  flex: 1,
  flexDirection: 'column',
  minWidth: SECONDARY_COLUMN_WIDTH,
  paddingBottom: UNIFIED_MARGIN,
});

const horizontalScrollbarProperties = {
  display: 'inline-table',
  paddingBottom: UNIFIED_MARGIN,
};

const baseMapsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: UNIFIED_MARGIN,
});

const baseMapColumnStyle = css({
  width: SECONDARY_COLUMN_WIDTH,
});

const baseMapsNamesStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  gap: 8,
  height: MAP_NAME_HEIGHT,
});

const ellipsisStyle = css(baseMapColumnStyle, {
  boxSizing: 'border-box',
  overflow: 'hidden',
  padding: `0 ${UNIFIED_MARGIN}px`,
  textAlign: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const rowStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});

const combinedColumnNameStyle = css({
  alignItems: 'end',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginBottom: MAP_NAME_BOTTOM_MARGIN,
  minHeight: `${MAP_NAME_HEIGHT}px`,
  padding: `0 ${UNIFIED_MARGIN}px 0 35px`,
  textAlign: 'center',
});

const divisionStyle = css({
  paddingLeft: UNIFIED_MARGIN,
  marginLeft: SCROLLBAR_MARGIN,
});

const combinedSectionStyle = css({
  height: MAX_VERTICAL_HEIGHT + COLUMN_HEIGHT + 2 * UNIFIED_MARGIN + MAP_NAME_HEIGHT,
  minWidth: MAIN_COLUMN_WIDTH,
  position: 'relative',
  width: MAIN_COLUMN_WIDTH,
});

const combinedColumnsStyle = ({ isPadded }: { isPadded: boolean }) => css(
  divisionStyle, {
    display: 'flex',
    flexDirection: 'column',
    gap: UNIFIED_MARGIN,
    paddingRight: isPadded ? UNIFIED_MARGIN : 0,
  });

const removeStyle = ({ isDisabled }: { isDisabled: boolean }) => (theme: Theme) => css({
  color: rgbColorToString({ ...createColor(isDisabled ? theme.iconColors.primary : theme.iconColors.backgroundDangerInverted).rgb, a: isDisabled ? 0.3 : 1 }),
  fontSize: REMOVE_ICON_FONT_SIZE,
  '&:hover': {
    ...(!isDisabled ? { color: theme.iconColors.backgroundDangerInvertedHover } : {}),
  },
});

const combinedDropdownStyle = css({
  boxSizing: 'border-box',
  flex: 1,
  width: `calc(100% - ${REMOVE_ICON_FONT_SIZE}px - ${UNIFIED_MARGIN}px)`,
  position: 'relative',
});

const addMoreWrapperStyle = css({
  marginLeft: SCROLLBAR_MARGIN,
  padding: `${UNIFIED_MARGIN}px 0 0 ${UNIFIED_MARGIN}px`,
});

const unnamedColumnsButtonStyle = (theme: Theme) => css({
  color: theme.textColors.danger,
  fontSize: 16,
  fontWeight: 400,
  border: 'none',
  background: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  '&:hover': {
    color: theme.textColors.dangerOnHover,
  },
});

const unnamedColumnsButtonTextStyle = css({
  textDecoration: 'underline',
});

const unnamedColumnsButtonIconStyle = css({
  fontSize: 18,
  textDecoration: 'underline',
});

type MatchupColumnsSectionComponentProps = Readonly<{
  columnData?: PerSpreadsheetMatchupData;
  disableColumnNamesEditing?: boolean;
  intersects: MapIntersect[];
  isLoading: boolean;
  layeringMapInfo?: MapInfo | null;
  mapName: string;
  maps: IntersectsMapItem[];

  getMapFromRealSpreadsheetId: (mapList: IntersectsMapItem[], spreadsheetId: number) => IntersectsMapItem | null;
  getMapIdFromPrimarySpreadsheetId: (mapList: IntersectsMapItem[], spreadsheetId: number) => number | null;
  getPrimarySpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;
  getRealSpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;
  getSpreadsheetIdFromMapId: (mapList: IntersectsMapItem[], mapId: number) => number | null;

  onSubmit: (data: MapIntersect[]) => void;
}>;

export const MatchupColumnsSectionComponent: FC<MatchupColumnsSectionComponentProps> = (props) => {
  const { disableColumnNamesEditing, maps, isLoading } = props;
  const [columnNamesScrollbarRef, setCombinedScrollbarRef] = useState<Scrollbar>();
  const [baseScrollbarRef, setBaseScrollbarRef] = useState<Scrollbar>();
  const [t] = useTranslation();

  const {
    onAddRow,
    onRemoveRow,
    onTargetColumnNameChange,
    rows,
    onSourceColumnChange,
    sourceColumnsPerMap,
    handleGenerateIntersectsAndSubmit,
    areAllRowsSelected,
  } = useMatchupColumnsSection({
    maps,
    columnData: props.columnData,
    getMapIdFromPrimarySpreadsheetId: props.getMapIdFromPrimarySpreadsheetId,
    getMapFromRealSpreadsheetId: props.getMapFromRealSpreadsheetId,
    getSpreadsheetIdFromMapId: props.getSpreadsheetIdFromMapId,
    getRealSpreadsheetIdFromMapId: props.getRealSpreadsheetIdFromMapId,
    getPrimarySpreadsheetIdFromMapId: props.getPrimarySpreadsheetIdFromMapId,
    intersects: props.intersects,
    isLoading,
    onSubmit: props.onSubmit,
    layeringMapInfo: props.layeringMapInfo,
  });

  const buttonProps = useMemo(() => ({
    onClick: handleGenerateIntersectsAndSubmit,
    text: t('Layer Map Now'),
    isDisabled: isLoading || !areAllRowsSelected,
  }), [areAllRowsSelected, handleGenerateIntersectsAndSubmit, isLoading, t]);

  const onUpdateBaseScrollState = useCallback(() => {
    const scrollState = baseScrollbarRef?.getScrollState().scrollTop;
    columnNamesScrollbarRef?.scrollTo(undefined, scrollState);
  }, [baseScrollbarRef, columnNamesScrollbarRef]);

  const onUpdateColumnNamesScrollState = useCallback(() => {
    const scrollState = columnNamesScrollbarRef?.getScrollState().scrollTop;
    baseScrollbarRef?.scrollTo(undefined, scrollState);
  }, [baseScrollbarRef, columnNamesScrollbarRef]);

  const getInvokeCallbackAndScroll = useCallback((callback: () => void) => () => {
    callback();
    delay(SCROLL_DELAY).then(() => {
      columnNamesScrollbarRef?.scrollToBottom();
    });
  }, [columnNamesScrollbarRef]);

  const scrollToFirstEmptyRow = useCallback(() => {
    const emptyRowIndex = rows.findIndex(row => !row.targetColumnName);
    if (!baseScrollbarRef?.contentElement || emptyRowIndex === -1) {
      return;
    }

    const rowNode = document.querySelector(`[data-layered-map-row-index="${emptyRowIndex}"]`);

    if (!rowNode) {
      return;
    }

    const scrollBarTop = baseScrollbarRef.contentElement?.getBoundingClientRect().top;
    const rowTop = rowNode.getBoundingClientRect().top;

    const offset = rowTop - scrollBarTop;

    baseScrollbarRef?.scrollTo(undefined, offset);
  }, [baseScrollbarRef, rows]);

  const hasEmptyRow = rows.some((row) => !row.targetColumnName);

  return (
    <LayeredMapSectionHolderComponent
      buttonProps={buttonProps}
      maxContentWidth="100%"
      additionalButton={(!isLoading && hasEmptyRow) ? (
        <button css={unnamedColumnsButtonStyle} onClick={scrollToFirstEmptyRow}>
          <FontAwesomeIcon css={unnamedColumnsButtonIconStyle} icon={faExclamationTriangle} />
          <span css={unnamedColumnsButtonTextStyle}>
            {t('Unnamed columns detected')}
          </span>
        </button>
      ) : undefined}
    >
      <div css={containerStyle}>
        <div css={baseMapsScrollbarStyle}>
          <div css={baseMapWrapperStyle}>
            <ScrollBarComponent
              contentStyle={horizontalScrollbarProperties}
              translateContentHeightToHolder
              type={ScrollbarType.Horizontal}
            >
              <div css={baseMapsNamesStyle}>
                {props.maps.map(map => (
                  <div
                    css={ellipsisStyle}
                    key={map.id}
                  >
                    {map.name}
                  </div>
                ))}
              </div>
              <ScrollBarComponent
                getScrollbarReference={setBaseScrollbarRef}
                isScrollbarHidden
                maxHeight={MAX_VERTICAL_HEIGHT}
                onUpdate={onUpdateBaseScrollState}
                translateContentHeightToHolder
                type={ScrollbarType.Vertical}
              >
                <div css={baseMapsWrapperStyle}>
                  {rows.map((row, index) => (
                    <div
                      css={rowStyle}
                      key={index}
                    >
                      {props.maps.map(map => (
                        <RegularDropdownComponent
                          css={baseMapColumnStyle}
                          inputSize={DROPDOWN_TRIGGER_SIZE}
                          isDisabled={isLoading || map.isDisabled}
                          inPortal
                          key={map.id}
                          onChange={(value) => onSourceColumnChange(value ?? null, map.id, index)}
                          options={sourceColumnsPerMap[map.id] || []}
                          placeholder={t('None')}
                          value={row?.selectedSourceColumnPerMap?.[map.id]}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </ScrollBarComponent>
            </ScrollBarComponent>
          </div>
        </div>

        <div css={combinedSectionStyle}>
          <div css={combinedColumnNameStyle}>
            <LimitNumberOfLinesComponent maxNumberOfLines={2} >
              {props.mapName}
            </LimitNumberOfLinesComponent>
          </div>
          <ScrollBarComponent
            getScrollbarReference={setCombinedScrollbarRef}
            maxHeight={MAX_VERTICAL_HEIGHT}
            onUpdate={onUpdateColumnNamesScrollState}
            type={ScrollbarType.Vertical}
          >
            <div css={combinedColumnsStyle({ isPadded: rows.length > MAX_ITEMS_IN_COLUMN })}>
              {rows.map((row, index) => (
                <div
                  key={index}
                  css={rowStyle}
                  data-layered-map-row-index={index}
                >
                  {row.isRemovable && !disableColumnNamesEditing && (
                    <TooltipComponent
                      tooltipContent={row.onlyOneSourceMap ? t('Each base map column must be present in the layered map') : null}
                      delayShow={500}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        css={removeStyle({ isDisabled: row.onlyOneSourceMap })}
                        onClick={row.onlyOneSourceMap ? undefined : getInvokeCallbackAndScroll(() => onRemoveRow(index))}
                      />
                    </TooltipComponent>
                  )}
                  <div css={combinedDropdownStyle} >
                    <SectionAutocompleteComponent
                      isLoading={isLoading}
                      onChange={(value) => onTargetColumnNameChange(value, index)}
                      value={row.targetColumnName}
                      options={row.availableTargetColumnNames.map(columnName => ({
                        name: columnName.name,
                        value: columnName.value || columnName.name,
                      }))}
                      isDisabled={disableColumnNamesEditing}
                    />
                  </div>
                </div>
              ))}
            </div>
          </ScrollBarComponent>
          <div css={addMoreWrapperStyle}>
            <AddMorePlaceholderComponent
              onClick={getInvokeCallbackAndScroll(onAddRow)}
              title={t('Select More Columns')}
            />
          </div>
        </div>
      </div>
    </LayeredMapSectionHolderComponent>
  );
};
