import { darkTheme } from '../../../_shared/themes/dark.theme';
import { lightTheme } from '../../../_shared/themes/light.theme';
import { type Theme } from '../../../_shared/themes/theme.model';
import { type ThemeType } from '../../../_shared/themes/themeType';

export const getThemeForType = (type: ThemeType): Theme => {
  switch (type) {
    case 'dark':
      return darkTheme;
    default:
      return lightTheme;
  }
};
