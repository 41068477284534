import {
  type FC, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AuthorizationPageWrapperComponent } from '~/authorization/pageWrapper/authorizationPageWrapper.component';
import { OverlayLoaderComponent } from '../../_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '../../_shared/components/overlay/overlayWrapper.component';
import { type PublicMapRouteParams } from '../../_shared/constants/routes';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../store/app.store';
import { MapPresentationalView } from '../../store/frontendState/mapPresentational/mapPresentational.state';
import { mapReset } from '../../store/map/map.actionCreators';
import { mapInfoPresentationalFetchDataRequest } from '../../store/mapInfo/mapInfo.actionCreators';
import { useMapIdSelector } from '../../store/selectors/useMapIdSelector';
import { MapPageContainer } from '../page/mapPage.container';
import { MapPresentationalFormComponent } from './passwordForm/mapPresentationalFormComponent';
import { PrivateMapWarningComponent } from './privateMapWarning/privateMapWarning.component';
import { useIsMapFullyInitialized } from './useIsMapFullyInitialized';

const mapViewSelector = (state: AppState) => state.frontendState.mapPresentational.view;
const isMapInfoLoadingSelector = (state: AppState) => state.map.mapInfo.isLoading;
const showWrongPasswordErrorSelector = (state: AppState) => state.frontendState.mapPresentational.showWrongPasswordError;

export const MapPresentationalContainer: FC = () => {
  useIsMapFullyInitialized();
  const dispatch = useDispatch();
  const { mapShareId = '' } = useParams<PublicMapRouteParams>();
  const isLoading = useSelector(isMapInfoLoadingSelector);
  const mapView = useSelector(mapViewSelector);
  const showWrongPasswordError = useSelector(showWrongPasswordErrorSelector);
  const mapId = useMapIdSelector();

  const onPasswordFormSubmit = (password: string) => {
    dispatch(mapInfoPresentationalFetchDataRequest(mapShareId, password));
  };

  useEffect(() => {
    dispatch(mapReset());
    dispatch(mapInfoPresentationalFetchDataRequest(mapShareId));
  }, [dispatch, mapShareId]);

  if (mapView === MapPresentationalView.Password) {
    return (
      <AuthorizationPageWrapperComponent>
        <MapPresentationalFormComponent
          isLoading={isLoading}
          onSubmit={onPasswordFormSubmit}
          showWrongPasswordError={showWrongPasswordError}
        />
      </AuthorizationPageWrapperComponent>
    );
  }

  if (mapView === MapPresentationalView.PrivateTokenMissing) {
    return (
      <AuthorizationPageWrapperComponent>
        <PrivateMapWarningComponent />
      </AuthorizationPageWrapperComponent>
    );
  }

  if (mapView === MapPresentationalView.Loading || !mapId) {
    return (
      <OverlayWrapperComponent minHeight="100%">
        <OverlayLoaderComponent />
      </OverlayWrapperComponent>
    );
  }

  return (<MapPageContainer mapId={mapId} />);
};
