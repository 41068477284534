import {
  put, takeEvery,
} from '@redux-saga/core/effects';
import {
  type FileAttachment, FileAttachmentTool,
} from '~/_shared/types/file.types';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { type FileUrlsAction } from '~/store/frontendState/fileUrls/fileUrls.action';
import { USER_FILES_DELETED } from '~/store/frontendState/fileUrls/fileUrls.actionTypes';
import { drawingItemsRemoveFileAttachments } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { removeMapSettingsMarkersFileAttachments } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { mapSettingsMarkersImagesRemoveFileAttachments } from '~/store/mapSettings/markers/mapSettingsMarkers.actionCreators';
import { mapSettingsFileAttachmentsRemove } from '../fileAttachments.actionCreators';
import { mapSettingsFileAttachmentsListSelector } from '../fileAttachments.selectors';

export function* removeDeletedFilesAttachmentsSaga() {
  yield takeEvery(USER_FILES_DELETED, onFilesDeleted);
}

function* onFilesDeleted(action: PickAction<FileUrlsAction, typeof USER_FILES_DELETED>) {
  yield* removeAttachmentsOfFiles(action.payload.fileIds);
}

function* removeAttachmentsOfFiles(fileIds: ReadonlyArray<number>) {
  const attachments: ReadonlyArray<FileAttachment> = yield select<ReadonlyArray<FileAttachment>>(mapSettingsFileAttachmentsListSelector);

  const deletedAttachments = attachments.filter(a => fileIds.includes(a.fileId));

  if (!deletedAttachments.length) {
    return;
  }

  yield* updateCustomMarkers(deletedAttachments);
  yield* updateMarkerImages(deletedAttachments);
  yield* updateDrawingImages(deletedAttachments);

  yield put(mapSettingsFileAttachmentsRemove(new Set(deletedAttachments.map(a => a.id))));
}

function *updateCustomMarkers(deletedAttachments: ReadonlyArray<FileAttachment>) {
  const customMarkersFileAttachments = deletedAttachments
    .filter(a => a.tool === FileAttachmentTool.CustomMarker)
    .map(a => a.id);

  if (customMarkersFileAttachments.length) {
    yield put(removeMapSettingsMarkersFileAttachments(customMarkersFileAttachments));
  }
}

function *updateMarkerImages(deletedAttachments: ReadonlyArray<FileAttachment>) {
  const markerImagesFileAttachments = deletedAttachments
    .filter(a => a.tool === FileAttachmentTool.MarkerGallery)
    .map(a => a.id);

  if (markerImagesFileAttachments.length) {
    yield put(mapSettingsMarkersImagesRemoveFileAttachments(markerImagesFileAttachments));
  }
}

function *updateDrawingImages(deletedAttachments: ReadonlyArray<FileAttachment>) {
  const drawingImagesFileAttachments = deletedAttachments
    .filter(a => a.tool === FileAttachmentTool.Drawing)
    .map(a => a.id);

  if (drawingImagesFileAttachments.length) {
    yield put(drawingItemsRemoveFileAttachments(drawingImagesFileAttachments));
  }
}
