import { type StorageService } from '~/_shared/utils/storageService';

const SPLIT_PANEL_STORAGE_KEY = 'split-panel-settings';

type SplitPanelLocalState = {
  mapId: number;
  height: number;
};

export const restoreSplitPanelHeight = (storageService: StorageService, mapId: number | null): number | null => {
  if (mapId === null) {
    return null;
  }

  const storedState = storageService.getLocalStorageItem(SPLIT_PANEL_STORAGE_KEY);
  if (storedState) {
    try {
      const storedStateJson = JSON.parse(storedState);

      if (typeof storedStateJson.mapId === 'number' && typeof storedStateJson.height === 'number') {
        if (storedStateJson.mapId === mapId) {
          return storedStateJson.height;
        }
        else {
          // map id is different from the stored one, meaning it changed so removing the stored split panel state
          removeSplitPanelState(storageService);
        }
      }
    }
    // eslint-disable-next-line no-empty
    catch (e) {}
  }

  return null;
};

const removeSplitPanelState = (storageService: StorageService) => {
  storageService.removeLocalStorageItem(SPLIT_PANEL_STORAGE_KEY);
};

export const storeSplitPanelState = (state: SplitPanelLocalState, storageService: StorageService) => {
  storageService.setLocalStorageItem(SPLIT_PANEL_STORAGE_KEY, JSON.stringify(state));
};
