import { css } from '@emotion/react';
import { type FC } from 'react';
import { type ModalProps } from '~/modal/modalType.enum';
import { AccordionComponent } from '../../baseComponents/accordion';
import { useTranslation } from '../../utils/hooks';
import { SettingsModalComponent } from '../settingsModal/settingsModal.component';
import {
  type ChangeableSettingRow,
  type SettingColumn, type SettingRow,
} from '../settingsTable/settingsTable.types';
import { SpreadsheetsSelectDropdownComponent } from '../spreadsheetsSelectDropdown/spreadsheetsSelectDropdown.component';

type SettingsModalWithSpreadsheetSelectProps = ModalProps<{
  title: string;
  settingRows: SettingRow[];
  settingColumns: SettingColumn[];
  onSettingRowChange: (rowIndex: number, newRow: ChangeableSettingRow) => void;
  isLoading: boolean;
  spreadsheetIds: number[];
  currentSelectedSpreadsheetId: number | null;
  onSelectedSpreadsheetIdChange: (spreadsheetId: number) => void;
}>;

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

export const SettingsModalWithSpreadsheetSelectComponent: FC<SettingsModalWithSpreadsheetSelectProps> = (props) => {
  const [t] = useTranslation();

  return (
    <SettingsModalComponent
      aboveTableContent={props.currentSelectedSpreadsheetId !== null && props.spreadsheetIds.length > 1 ? (
        <AccordionComponent
          data={[{
            header: t('Select Spreadsheet'),
            isLocked: true,
            isExpanded: true,
            child: (
              <SpreadsheetsSelectDropdownComponent
                spreadsheetIds={props.spreadsheetIds}
                currentSelectedSpreadsheetId={props.currentSelectedSpreadsheetId}
                onSelectedSpreadsheetIdChange={props.onSelectedSpreadsheetIdChange}
              />
            ),
          }]}
          itemButtonStyle={itemButtonStyle}
        />
      ) : null}
      caption={props.title}
      isOpen={props.isOpen}
      onClose={props.onClose}
      settingColumns={props.settingColumns}
      settingRows={props.settingRows}
      onChange={props.onSettingRowChange}
      onSubmit={props.onClose}
      isLoading={props.isLoading}
      customSaveButton={null}
    />
  );
};
