import { type MapSettingsReadyAction } from './mapSettingsReady.action';
import { MAP_SETTINGS_READY_SET } from './mapSettingsReady.actionTypes';
import { type MapSettingsReadyState } from './mapSettingsReady.state';

const initialState: MapSettingsReadyState = false;

export const mapSettingsReadyReducer = (state = initialState, action: MapSettingsReadyAction): MapSettingsReadyState => {
  switch (action.type) {
    case MAP_SETTINGS_READY_SET: {
      return action.payload.isReady;
    }

    default: {
      return state;
    }
  }
};
