import {
  type SpreadsheetColumnsToFetchNumberResponse,
  type SpreadsheetDataBulkRequestGetter,
  type SpreadsheetDataBulkResponse,
} from '../../../spreadsheet/spreadsheet.repository';
import { DataType } from '../../../store/spreadsheetData/spreadsheetData.state';

export const MIN_PERCENTAGE_NUMERIC_EMPTY = 95;
export const MIN_PERCENTAGE_NUMERIC = 50;

export const isColumnNumeric = (numericAndEmptyPercent: number, numericPercent: number) => (
  numericAndEmptyPercent >= MIN_PERCENTAGE_NUMERIC_EMPTY && numericPercent >= MIN_PERCENTAGE_NUMERIC
);

const isColumnNumericFromNumberResponse = (column: SpreadsheetColumnsToFetchNumberResponse) => (
  isColumnNumeric(column.numeric_and_empty_percent, column.numeric_percent)
);

export const createGetSpreadsheetBulkDataNumericParams = (mapId: number, spreadsheetId: number, columnIds: string[]): SpreadsheetDataBulkRequestGetter => {
  const columnsToFetch = columnIds.reduce((result, curValue) => {
    return { ...result, [curValue]: {} };
  }, {});

  return {
    map_id: mapId,
    spreadsheet_id: spreadsheetId,
    exclude_basic_data: true,
    exclude_row_data: true,
    columns_to_fetch: {
      [DataType.NUMBER]: columnsToFetch,
    },
  };
};

export const getValidNumericColumnIds = (data: SpreadsheetDataBulkResponse): string[] => {
  const numberColumnsData = data.result.columns_to_fetch?.number;
  if (!numberColumnsData) {
    return [];
  }
  return Object.entries(numberColumnsData)
    .filter(([_, columnData]) => isColumnNumericFromNumberResponse(columnData))
    .map(([columnId]) => columnId);
};
