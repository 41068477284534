import { takeLatest } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';
import { type GroupingColumn } from '~/_shared/types/grouping/grouping';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import {
  type MarkerSettings, type MarkersVisualSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { recreateFileAttachment } from '~/_shared/utils/files/fileAttachments.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { mapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { mapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  getNumericGroupMarkerSettings, getTextGroupMarkerSettings,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSettings.helpers';
import { MAP_SETTINGS_GROUPING_CHECK_FOR_ACTIVE_GROUP_CHANGES_ACTIONS } from '../../grouping/mapSettingsGrouping.actionTypes';
import { mapSettingsFileAttachmentsAdd } from '../fileAttachments.actionCreators';
import { mapSettingsFileAttachmentsMapSelector } from '../fileAttachments.selectors';
import { type MapSettingsFileAttachmentsMap } from '../fileAttachments.state';

export function* syncActiveGroupMarkerAttachmentsSaga() {
  yield takeLatest(MAP_SETTINGS_GROUPING_CHECK_FOR_ACTIVE_GROUP_CHANGES_ACTIONS, addActiveGroupMarkerAttachments);
}

function* addActiveGroupMarkerAttachments() {
  const activeGroupColumns: ReadonlyArray<GroupingColumn> = yield select<ReadonlyArray<GroupingColumn>>(mapSettingsGroupingActiveGroupColumnsSelector);
  const markerVisualSettings: MarkersVisualSettings = yield select<MarkersVisualSettings>(mapSettingsMarkersGeneralSelector);
  const attachments: MapSettingsFileAttachmentsMap = yield select<MapSettingsFileAttachmentsMap>(mapSettingsFileAttachmentsMapSelector);

  const activeGroupsMarkerAttachmentIds: Set<string> = new Set();

  const logAttachment = (settings: MarkerSettings | undefined) => {
    const marker = settings?.marker;
    if (marker?.styleType === MarkerStyleType.CUSTOM) {
      activeGroupsMarkerAttachmentIds.add(marker.fileAttachmentId);
    }
  };

  const isGroupingColumnActive = (({ spreadsheetId, columnId }: { spreadsheetId: number; columnId: string }) => activeGroupColumns.find(
    activeGroup => activeGroup.spreadsheetId === spreadsheetId && activeGroup.columnId === columnId));

  getTextGroupMarkerSettings(markerVisualSettings).forEach(item => isGroupingColumnActive(item) && logAttachment(item.markerSettings));
  getNumericGroupMarkerSettings(markerVisualSettings).forEach(item => isGroupingColumnActive(item) && logAttachment(item.markerSettings));

  for (const attachmentId of activeGroupsMarkerAttachmentIds) {
    const existing = attachments.get(attachmentId);
    if (!existing) {
      const recreatedAttachment = recreateFileAttachment(attachmentId);

      if (recreatedAttachment) {
        yield put(mapSettingsFileAttachmentsAdd(recreatedAttachment));
      }
    }
  }
}
