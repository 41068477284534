import './react-datasheet.css';
import {
  type FC, memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import { SPREADSHEET_ERROR_TIMEOUT } from '~/_shared/components/spreadsheet/constants';
import { SpreadsheetContextProvider } from '~/_shared/components/spreadsheet/spreadsheetContext';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '../../types/spreadsheetData/spreadsheetRow';
import { range } from '../../utils/function.helpers';
import { SpreadsheetCellComponent } from './cell/spreadsheetCell.component';
import { SpreadsheetDataEditorComponent } from './dataEditor/spreadsheetDataEditor.component';
import { SpreadsheetRowComponent } from './row/spreadsheetRow.component';
import { SpreadsheetSheetComponent } from './sheet/spreadsheetSheet.component';
import {
  type GridElement, type SpreadsheetHeaderColumn, type SpreadsheetRow, type SpreadsheetSortType,
} from './spreadsheet.types';
import { SpreadsheetValueViewerComponent } from './valueViewer/spreadsheetValueViewer.component';
import CellsChangedHandler = ReactDataSheet.CellsChangedHandler;
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { useTimeout } from '~/_shared/utils/hooks/useTimeout';

type SelectionDescriptorItem = {
  i: number; // rowIndex
  j: number; // columnIndex
};

type SelectionDescriptor = {
  start: SelectionDescriptorItem;
  end: SelectionDescriptorItem;
};

type SpreadsheetProps = Readonly<{
  columns: SpreadsheetHeaderColumn[];
  data: SpreadsheetRow[];
  highlightedRows?: ReadonlySet<CombinedRowId>;
  selectedRows: Record<CombinedRowId, true>;
  columnWidthsLocalStorageKey?: string;
  useResizePlaceholder?: boolean;
  canSelectCells: boolean;
  canSelectRows: boolean;

  getSpreadsheetCellDisabledMessage?: (spreadsheetRowId: SpreadsheetRowId, columnId?: ColumnId) => string | null;
  onDataChange: (newData: SpreadsheetRow[]) => void;
  onHoveredRowChange?: (row: SpreadsheetRow | null) => void;
  onRowClick?: (row: SpreadsheetRow) => void;
  onSelectedRowsChange: (newSelections: Record<CombinedRowId, true>) => void;
  onSortChange?: (sortChange: { columnId: string; newSort: SpreadsheetSortType | null }) => void;
}>;

const isSelectionMultiCell = (selection: SelectionDescriptor): boolean => {
  return selection.start.i !== selection.end.i || selection.start.j !== selection.end.j;
};

const isChangeWithinSelection = (
  selection: SelectionDescriptor,
  change: { row: number; col: number },
): boolean => {
  const forwardsSelection = unifySelectionDirection(selection);

  return (
    change.row >= forwardsSelection.start.i && change.row <= forwardsSelection.end.i
    && change.col >= forwardsSelection.start.j && change.col <= forwardsSelection.end.j
  );
};

const unifySelectionDirection = (selection: SelectionDescriptor): SelectionDescriptor => {
  const lowestSelectedRowIndex = Math.min(selection.start.i, selection.end.i);
  const highestSelectedRowIndex = Math.max(selection.start.i, selection.end.i);
  const lowestSelectedColumnIndex = Math.min(selection.start.j, selection.end.j);
  const highestSelectedColumnIndex = Math.max(selection.start.j, selection.end.j);

  return {
    start: { i: lowestSelectedRowIndex, j: lowestSelectedColumnIndex },
    end: { i: highestSelectedRowIndex, j: highestSelectedColumnIndex },
  };
};

const SpreadsheetComponent: FC<SpreadsheetProps> = ({
  canSelectCells,
  canSelectRows,
  columnWidthsLocalStorageKey,
  columns,
  data,
  getSpreadsheetCellDisabledMessage,
  highlightedRows,
  onDataChange,
  onHoveredRowChange,
  onRowClick,
  onSelectedRowsChange,
  onSortChange: handleSortChange,
  selectedRows,
  useResizePlaceholder,
}) => {

  const [t] = useTranslation();

  const [selection, setSelection] = useState<SelectionDescriptor | null>(null);
  const [selectionForbiddenCoords, setSelectionForbiddenCoords] = useState<{ row: number; col: number } | null>(null);
  const [isShowForbiddenCoordsMessage, showForbiddenCoordsMessage] = useTimeout(SPREADSHEET_ERROR_TIMEOUT);

  const parsePaste = useCallback((str: string): string[][] => {
    if (!selection) {
      return [];
    }

    const cells = str.split(/\r\n|\n|\r/).map((row) => row.split('\t'));
    const selectedNumberOfRows = Math.abs(selection.end.i - selection.start.i) + 1;
    const selectedNumberOfCols = Math.abs(selection.end.j - selection.start.j) + 1;

    if (cells.length === 0 || !cells[0]) {
      return [];
    }

    const rows = Math.max(selectedNumberOfRows, cells.length);
    const cols = Math.max(selectedNumberOfCols, cells[0].length);

    return range(rows).map(row => {
      return range(cols).map(col => {
        const cell = cells[row % cells.length];
        if (!cell || !cells[0]) {
          return '';
        }

        const value = cell[col % cells[0].length];

        return value ?? '';
      });
    });
  }, [selection]);

  const valueRenderer = useCallback((cell: GridElement) => {
    return cell.value;
  }, []);

  const onSortChange = useCallback((sortChange: { columnId: string; newSort: SpreadsheetSortType | null }) => {
    setSelection(null);
    handleSortChange?.(sortChange);
  }, [handleSortChange]);

  const onSelectionChange = useCallback((newSelection: SelectionDescriptor | null) => {
    let finalSelection = newSelection;

    // if cells selection is disabled, we just use the 'end' from the SelectionDescriptor, which is the currently hovered cell
    if (!canSelectCells && newSelection) {
      finalSelection = {
        start: { ...newSelection.end },
        end: { ...newSelection.end },
      };
    }

    if (!canSelectCells && newSelection && !selectionForbiddenCoords
      && (newSelection.start.i !== newSelection.end.i || newSelection.start.j !== newSelection.end.j)
    ) {
      setSelectionForbiddenCoords({ row: newSelection.start.i, col: newSelection.start.j });
      showForbiddenCoordsMessage();
    }

    setSelection(finalSelection);
  }, [canSelectCells, selectionForbiddenCoords, showForbiddenCoordsMessage]);

  const getSpreadsheetCellForbiddenMessage = useCallback((row: number, col: number) => {
    if (selectionForbiddenCoords?.row === row && selectionForbiddenCoords?.col === col) {
      return t('layeredMap.connected.batchEditNotAllowed');
    }

    return null;
  }, [t, selectionForbiddenCoords?.col, selectionForbiddenCoords?.row]);

  const onCloseSpreadsheetCellForbiddenMessage = useCallback((row: number, col: number) => {
    if (selectionForbiddenCoords?.row === row && selectionForbiddenCoords?.col === col) {
      setSelectionForbiddenCoords(null);
    }
  }, [selectionForbiddenCoords?.col, selectionForbiddenCoords?.row]);

  const onCellsChanged: CellsChangedHandler<GridElement, string> = (changes) => {
    const changesByRow = new Map(changes.map(change => [change.row, change]));

    const newGrid = data.map((row, rowIndex) => {
      const change = changesByRow.get(rowIndex);
      if (!change) {
        return row;
      }
      const cellDisabledMessage = getSpreadsheetCellDisabledMessage?.(
        { rowId: row.rowId, spreadsheetId: row.virtualSpreadsheetId },
        row.values?.[change.col]?.columnId,
      );
      if (cellDisabledMessage) {
        return row;
      }

      const affectedColumnId = row.values[change.col]?.columnId;
      const newValues = row.values.map((value, index) => {
        if ((index === change.col) && affectedColumnId) {
          return {
            value: change.value,
            columnId: affectedColumnId,
          };
        }
        return value;
      });
      return {
        ...row,
        values: newValues,
      };
    });

    // this is when user selects multiple cells and wants to write down the same value
    if ((changes.length === 1) && changes[0] && !!selection && isSelectionMultiCell(selection)) {
      // if the selection does not include the cell they are changing, do nothing as it's probably just quick click
      // elsewhere
      if (isChangeWithinSelection(selection, changes[0])) {
        const forwardsSelection = unifySelectionDirection(selection);
        for (let rowIndex = forwardsSelection.start.i; rowIndex <= forwardsSelection.end.i; rowIndex++) {
          for (let columnIndex = forwardsSelection.start.j; columnIndex <= forwardsSelection.end.j; columnIndex++) {
            const newGridRow = newGrid[rowIndex];
            if (!newGridRow) {
              continue;
            }

            if (getSpreadsheetCellDisabledMessage?.(
              { rowId: newGridRow.rowId, spreadsheetId: newGridRow.virtualSpreadsheetId },
              newGridRow.values?.[columnIndex]?.columnId,
            )) {
              continue;
            }

            const newRow: SpreadsheetRow = {
              ...newGridRow,
              values: [...newGridRow.values],
            };

            const newRowsValueColumnId = newRow.values[columnIndex]?.columnId;
            if (newRowsValueColumnId !== undefined) {
              newRow.values[columnIndex] = {
                columnId: newRowsValueColumnId,
                value: changes[0]?.value ?? null,
              };
            }

            newGrid[rowIndex] = newRow;
          }
        }
      }
    }

    onDataChange(newGrid);
  };

  useEffect(() => {
    if (!isShowForbiddenCoordsMessage && selectionForbiddenCoords) {
      setSelectionForbiddenCoords(null);
    }
  }, [data, isShowForbiddenCoordsMessage, selectionForbiddenCoords]);

  const dataMatrix = useMemo(() => {
    return data.map(row => [
      ...row.values.map(cell => ({
        ...cell,
        key: `${row.rowId}-${cell.columnId}`,
      })),
    ]);
  }, [data]);

  const getRowId = useCallback((rowIndex: number) => {
    const row = data[rowIndex];

    return row?.rowId ?? rowIndex;
  }, [data]);

  return (
    <SpreadsheetContextProvider
      columns={columns}
      data={data}
      highlightedRows={highlightedRows}
      getSpreadsheetCellDisabledMessage={getSpreadsheetCellDisabledMessage}
      getSpreadsheetCellForbiddenMessage={getSpreadsheetCellForbiddenMessage}
      onCloseSpreadsheetCellForbiddenMessage={onCloseSpreadsheetCellForbiddenMessage}
      columnWidthsLocalStorageKey={columnWidthsLocalStorageKey}
      selectableRows={canSelectRows}
      onSortChange={onSortChange}
      onSelectedRowsChange={onSelectedRowsChange}
      selectedRows={selectedRows}
      useResizePlaceholder={useResizePlaceholder}
      onHoveredRowChange={onHoveredRowChange}
      onRowClick={onRowClick}
      onCellsChanged={onCellsChanged}
    >
      <ReactDataSheet<GridElement>
        cellRenderer={SpreadsheetCellComponent}
        data={dataMatrix}
        dataEditor={SpreadsheetDataEditorComponent}
        keyFn={getRowId}
        onCellsChanged={onCellsChanged}
        onSelect={onSelectionChange}
        parsePaste={parsePaste}
        rowRenderer={SpreadsheetRowComponent}
        selected={selection}
        sheetRenderer={SpreadsheetSheetComponent}
        valueRenderer={valueRenderer}
        valueViewer={SpreadsheetValueViewerComponent}
      />
    </SpreadsheetContextProvider>
  );
};
const pureComponent = memo(SpreadsheetComponent);
export { pureComponent as SpreadsheetComponent };
