import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { HOME_ROUTE } from '~/_shared/constants/routes';
import {
  type ApiError, getApiErrorErrors,
} from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { ImpersonationService } from '~/authorization/impersonation';
import { type ImpersonateResponse } from '~/impersonation/impersonation.repository';
import {
  clientInviteeListChanged,
  clientMemberListChanged,
} from '~/store/frontendState/client/client.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { userGetDataRequest } from '~/store/userData/userData.actionCreators';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { type MemberRole } from '../memberRole.type';
import {
  MemberStatus, type TeamMemberLimitsData,
} from '../teamManagement.repository';
import { type InvitationAndMemberData } from '../teamManagementModal.container';
import { useGetRoles } from '../useTeamManagement';
import { TeamManagementMenuComponent } from './teamManagementMenu.component';
import { useMembersErrorModal } from './useMembersErrorModal';

type TeamManagementMenuContainerProps = {
  member: InvitationAndMemberData;
  limits: TeamMemberLimitsData;

  close: () => void;
  requestDeleteUser: (clientId: number, memberId: number) => Promise<void>;
  enableMember: (clientId: number, memberId: number, enable: boolean) => Promise<void>;
  changeRole: (clientId: number, memberId: number, roleId: number) => Promise<void>;
  revokeInvite: (clientId: number, invitationId: string) => Promise<void>;
  loginAsMember: (clientId: number, memberId: number) => Promise<ImpersonateResponse>;
  sendPasswordResetEmail: (email: string, callback: () => void) => void;
};

export const TeamManagementMenuContainer: FC<TeamManagementMenuContainerProps> = ({
  member,
  close,
  requestDeleteUser,
  enableMember,
  changeRole,
  revokeInvite,
  loginAsMember,
  sendPasswordResetEmail,
  limits,
}) => {
  const clientId = useClientIdSelector();
  const { clientRole: currentMemberRole, id: currentMemberId } = useUserDataSelector();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { openErrorModal } = useMembersErrorModal();
  const { openConfirmationModal: openUserDeleteConfirmation, closeConfirmationModal: closeUserDeleteConfirmation } = useConfirmationModal();
  const { data: roles, invokeAjax: getRoles } = useGetRoles();

  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState<boolean>(false);
  const [currentError, setCurrentError] = useState<string>('');

  const currentMemberData = useMemo(() => ({
    id: currentMemberId,
    role: currentMemberRole,
  }), [currentMemberId, currentMemberRole]);

  const onLoginAsUser = useCallback(() => {
    if (!clientId || !member.id) {
      return;
    }

    loginAsMember(clientId, member.id)
      .then((response) => {
        ImpersonationService.start(response.access_token_expiration);
        close();
        navigate(HOME_ROUTE);
        location.reload();
      }).catch((e: ApiError) => {
        setCurrentError(getApiErrorErrors(e)?.join(', ') || e.message);
      });
  }, [clientId, close, navigate, loginAsMember, member.id]);

  const onSentPasswordRecoveryEmail = useCallback(() => {
    if (!member.email) {
      return;
    }
    sendPasswordResetEmail(member.email, () => {
      setPasswordResetEmailSent(true);
    });
  }, [member.email, sendPasswordResetEmail]);

  const onEnableOrDisableUser = useCallback(() => {
    if (!clientId || !member.id) {
      return;
    }

    enableMember(clientId, member.id, member.status !== MemberStatus.ENABLED)
      .then(() => {
        dispatch(clientMemberListChanged());
        close();
      }).catch((e: ApiError) => {
        setCurrentError(getApiErrorErrors(e)?.join(', ') || e.message);
      });
  }, [clientId, close, dispatch, enableMember, member.id, member.status]);

  const deleteUser = useCallback(() => {
    if (!clientId || !member.id) {
      return;
    }

    requestDeleteUser(clientId, member.id)
      .then(() => {
        dispatch(clientMemberListChanged());
        close();
      }).catch((e: ApiError) => {
        setCurrentError(getApiErrorErrors(e)?.join(', ') || e.message);
      });
  }, [clientId, close, dispatch, member.id, requestDeleteUser]);

  const confirmDeleteUser = useCallback(() => {
    openUserDeleteConfirmation({
      confirmCaption: t('Proceed'),
      isConfirmButtonDestructive: true,
      onCancel: closeUserDeleteConfirmation,
      onConfirm: () => {
        deleteUser();
        closeUserDeleteConfirmation();
      },
      text: t('You are about to delete this member. Once deleted, a member cannot be re-added. Do you wish to proceed?'),
      title: t('Delete User'),
    });
  }, [closeUserDeleteConfirmation, deleteUser, openUserDeleteConfirmation, t]);

  const onChangeRole = useCallback((role: MemberRole) => {
    if (!clientId || !member.id || !roles?.data) {
      return;
    }

    const roleId = roles.data.find((item) => item.name === role)?.id || null;

    if (roleId === null) {
      return;
    }

    changeRole(clientId, member.id, roleId)
      .then(() => {
        dispatch(clientMemberListChanged());
        dispatch(dispatch(userGetDataRequest()));
        close();
      }).catch((e: ApiError) => {
        setCurrentError(getApiErrorErrors(e)?.join(', ') || e.message);
      });
  }, [changeRole, clientId, close, dispatch, member.id, roles]);

  const onRevokeInvite = useCallback(() => {
    if (!clientId || !member.invitationId) {
      return;
    }

    revokeInvite(clientId, member.invitationId)
      .then(() => {
        dispatch(clientInviteeListChanged());
        dispatch(clientMemberListChanged());
        close();
      }).catch((e: ApiError) => {
        setCurrentError(getApiErrorErrors(e)?.join(', ') || e.message);
      });
  }, [clientId, close, dispatch, member.invitationId, revokeInvite]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    if (currentError) {
      close();
      openErrorModal(currentError);
    }
  }, [close, currentError, openErrorModal, t]);

  return (
    <TeamManagementMenuComponent
      currentMemberData={currentMemberData}
      isLoading={!roles}
      limits={limits}
      member={member}
      passwordResetEmailSent={passwordResetEmailSent}
      onChangeRole={onChangeRole}
      onDeleteUser={confirmDeleteUser}
      onEnableOrDisableUser={onEnableOrDisableUser}
      onLoginAsUser={onLoginAsUser}
      onRevokeInvite={onRevokeInvite}
      onSentPasswordRecoveryEmail={onSentPasswordRecoveryEmail}
    />
  );
};
