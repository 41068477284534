import { isEmpty } from '~/_shared/utils/object/isEmpty';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type SpreadsheetRow } from '../_shared/components/spreadsheet/spreadsheet.types';
import {
  DATA_ROUTE, type DataRouteParams,
} from '../_shared/constants/routes';
import { type CombinedRowId } from '../_shared/types/spreadsheetData/spreadsheetRow';
import { buildUrlWithPath } from '../_shared/utils/url/url.helpers';

export const getDataUrl = (mapId: number) => {
  return buildUrlWithPath<DataRouteParams>(DATA_ROUTE, { mapId: mapId.toString() });
};

export type SpreadsheetDataRowChange = {
  rowId: CombinedRowId;
  rowVersion?: number;
  data: {
    [columnId: string]: string;
  };
};

export const getSpreadsheetDataRowChanges = (
  oldData: SpreadsheetRow[],
  newData: SpreadsheetRow[],
): SpreadsheetDataRowChange[] => {
  const newDataMap = new Map(newData.map(newDataItem => [newDataItem.rowId, {
    ...newDataItem,
    valueMap: new Map(newDataItem.values.map(value => [value.columnId, value])),
  }]));

  const changes: SpreadsheetDataRowChange[] = oldData.map(oldDataRow => {
    const newDataRow = newDataMap.get(oldDataRow.rowId);
    if (!newDataRow) {
      return;
    }

    const valueChanges = oldDataRow.values.reduce((changes, oldValue) => {
      const columnId = oldValue.columnId;
      const newValueObj = newDataRow.valueMap.get(columnId);
      if (oldValue !== newValueObj) {
        const newValue = newValueObj?.value?.toString() ?? '';

        if (columnId) {
          changes[columnId] = newValue;
        }
      }
      return changes;
    }, {} as Record<string, string>);

    if (isEmpty(valueChanges)) {
      return null;
    }
    return {
      rowId: newDataRow.rowId,
      rowVersion: newDataRow.version,
      data: valueChanges,
    };
  }).filter(notNullsy);

  return changes;
};
