import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { drawingEditSetSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import {
  DrawingItemPlacement,
  type DrawingItemText,
} from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingTextSettingsState } from '~/store/mapSettings/drawing/settings/text/drawingTextSettings.state';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { type DrawingToolManager } from '../drawingToolManager';

type DrawingToolTextCreateNewInstanceProps = Readonly<{
  settings: DrawingTextSettingsState;
  manager: DrawingToolManager;
}>;

export const DrawingToolTextCreateNewInstanceContainer: FC<DrawingToolTextCreateNewInstanceProps> = ({
  settings, manager,
}) => {
  const dispatch = useDispatch();
  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    const id = createUuid();
    const currentZoom = manager.getZoom();
    const latLng = e.latLng;
    const newInstanceSettings = { ...settings };

    if (!latLng) {
      return;
    }

    if (settings.scalesWithMapZoom && currentZoom) {
      newInstanceSettings.fontSize = convertPxDistanceToDifferentZoom({
        distance: newInstanceSettings.fontSize,
        originalZoom: currentZoom,
        newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS,
      });
    }

    const newInstance: DrawingItemText = {
      id,
      placement: DrawingItemPlacement.Default,
      startPoint: googleLatLngToLocal(latLng),
      settings: newInstanceSettings,
      text: '',
    };

    dispatch(drawingItemsAddItem(id, {
      type: DrawingTool.Text,
      value: newInstance,
    }));

    dispatch(drawingEditSetSelectedDrawing(
      newInstance.id,
      {
        type: DrawingTool.Text,
        value: newInstance,
      })
    );
  }, [dispatch, manager, settings]);

  useEffect(() => {
    const listener = manager.addMapClickListener(onMapClick);

    return () => listener.remove();
  }, [manager, onMapClick]);

  return null;
};
