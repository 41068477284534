import { useCallback } from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import {
  type DatasetOptimizedTerritoriesRequestParams, getDatasetOptimizedTerritories,
} from '~/store/boundaries/boundaries.repository';
import { type BoundaryId } from '~/store/boundaries/boundaryIdentifier.type';

export const useGetDatasetBoundariesOptimizedTerritories = () => {
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, params: DatasetOptimizedTerritoriesRequestParams) =>
    getDatasetOptimizedTerritories(clientId, params)
  );

  const getDatasetBoundariesOptimizedTerritoriesRequest = useCallback(({
    clientId,
    zips,
    states,
  }: {
    clientId: number;
    zips?: ReadonlyArray<BoundaryId>;
    states?: ReadonlyArray<BoundaryId>;
  }
  ) => {
    return invokeAjax(clientId, {
      zips,
      states,
    });
  }, [invokeAjax]);

  return {
    isLoading,
    getDatasetBoundariesOptimizedTerritoriesRequest,
  };
};
