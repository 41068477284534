import {
  useCallback, useEffect, useState,
} from 'react';
import { getApiErrorErrors } from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { loginAsMemberRequest } from '~/impersonation/impersonation.repository';
import {
  changeRoleRequest,
  enableMemberRequest,
  getInvitationsRequest,
  getMembersRequest,
  getRolesRequest,
  requestDeleteUserRequest,
  revokeInvitationRequest,
  SendInvitationErrorCode,
  sendInvitationRequest,
} from './teamManagement.repository';

export const useGetInvitations = (clientId: number | null) => {
  const getInvitations = useCallback(() => {
    if (clientId) {
      return getInvitationsRequest(clientId);
    }
    else {
      return Promise.resolve({ data: [] });
    }
  }, [clientId]);
  const { isLoading, error, data, invokeAjax } = useAjaxCall(getInvitations);

  return {
    isLoading,
    error,
    data,
    invokeAjax,
  };
};

export const useGetMembers = (clientId: number | null) => {
  const getClientMembers = useCallback(() => {
    if (clientId) {
      return getMembersRequest(clientId);
    }
    else {
      return Promise.resolve({
        members: [],
        limits: {
          limit: 0,
          used: 0,
          remaining: 0,
        },
      });
    }
  }, [clientId]);
  const { isLoading, error, data, invokeAjax } = useAjaxCall(getClientMembers);

  return {
    isLoading,
    error,
    data,
    invokeAjax,
  };
};

export const useGetRoles = () => {
  const { isLoading, error, data, invokeAjax } = useAjaxCall(getRolesRequest);

  return {
    isLoading,
    error,
    data,
    invokeAjax,
  };
};

export const useSendInvitation = () => {
  const { invokeAjax, isLoading, error: ajaxError } = useAjaxCall(sendInvitationRequest);
  const [error, setError] = useState('');
  const [userLimitExceeded, setUserLimitExceeded] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (ajaxError) {
      const errorErrors = getApiErrorErrors(ajaxError);
      if (ajaxError.errorCode === SendInvitationErrorCode.USER_LIMIT_REACHED) {
        setError(SendInvitationErrorCode.USER_LIMIT_REACHED);
        setUserLimitExceeded(true);
      }
      else if (ajaxError.errorCode === SendInvitationErrorCode.USER_CANNOT_BE_INVITED) {
        setError(t('User with provided email cannot be invited'));
      }
      else if (errorErrors) {
        const errorLookup = new Set(errorErrors);
        if (errorLookup.has(SendInvitationErrorCode.ALREADY_ADDED_TO_CLIENT)) {
          setError(t('userManagement.error.emailUsedAlready'));
        }
        else {
          setError(errorErrors.join(' '));
        }
      }
      else {
        setError(t('Something went wrong, please try again.'));
      }
    }
    else {
      setError('');
      setUserLimitExceeded(false);
    }
  }, [ajaxError, error, setError, t]);

  return {
    error,
    errorOverMembersLimit: userLimitExceeded,
    isLoading,
    update: invokeAjax,
  };
};

export const useRevokeInvitation = () => {
  const { invokeAjax, isLoading, error } = useAjaxCall(revokeInvitationRequest);

  return {
    error,
    isLoading,
    update: invokeAjax,
  };
};

export const useRequestDeleteUser = () => {
  const { invokeAjax, isLoading } = useAjaxCall(requestDeleteUserRequest);

  return {
    update: invokeAjax,
    isLoading,
  };
};

export const useEnableMember = () => {
  const { invokeAjax, isLoading } = useAjaxCall(enableMemberRequest);

  return {
    update: invokeAjax,
    isLoading,
  };
};

export const useChangeRole = () => {
  const { invokeAjax, isLoading } = useAjaxCall(changeRoleRequest);

  return {
    update: invokeAjax,
    isLoading,
  };
};

export const useLoginAsMember = () => {
  const { invokeAjax, isLoading } = useAjaxCall(loginAsMemberRequest);

  return {
    update: invokeAjax,
    isLoading,
  };
};
