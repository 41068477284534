import { css } from '@emotion/react';
import {
  faEye, faEyeSlash, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, Fragment,
} from 'react';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import {
  createColor, removeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { HeatMapSample } from '~/_shared/types/heatmap/heatMap.enum';
import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { type HeatMapItem } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { type HeatmapToolStateData } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.state';
import { HeatMapColorRowComponent } from './colorRow/heatMapColorRow.component';
import { getHeatMapItemName } from './heatMapListing.helpers';

const itemHeaderStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 4,
  justifyContent: 'space-between',
});

const ellipsisStyle = css({
  maxWidth: 210,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const itemHeaderLeftStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 6,
});

const visibilityWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 30, // prevent size change on icon change
});

const visibilityIconStyle = css({
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  margin: 0,
});

const accordionHeaderWrapperStyle = css({
  marginRight: 0,
  width: '100%',
});

const accordionButtonStyle = css({
  boxSizing: 'border-box',
  padding: '0 12px',
});

const itemContentStyle = css({
  padding: '8px 16px 10px',
});

const labelColumnStyle = css({
  paddingRight: 10,
});

const sliderColumnStyle = css({
  padding: '8px 0',
  width: '80%',
});

const accordionStyle = css({
  fontSize: 16,
});

const gradientWrapperStyle = css({
  margin: '18px 0 8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const gradientLabelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  margin: '0',
});

const gradientToggleStyle = css({
  textTransform: 'uppercase',
});

const colorSelectRowStyle = css({
  marginTop: 10,
});

const checkboxStyle = css({
  marginTop: 15,
  '&:first-of-type': {
    marginTop: 8,
  },
});

const tableStyle = css({
  width: '100%',
});

type HeatMapListingProps = Readonly<{
  items: ReadonlyArray<HeatMapItem>;
  columns: ReadonlyArray<SpreadsheetColumn>;
  selectedItemsIds: ReadonlyArray<string>;
  onItemToggle: (id: string) => void;
  onHeatMapItemRemove: (heatmap: HeatMap) => void;
  updateHeatMapItem: (item: HeatMap) => void;
  updateHeatMapItemData: (heatmapId: string, data: Partial<HeatmapToolStateData>) => void;
  updateHeatMapVisibility: (heatmapId: string, visibility: boolean) => void;
}>;

export const HeatMapListingComponent: FC<HeatMapListingProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {props.items.map(({ heatmap, otherOptions }) => (
        <Fragment key={heatmap.id}>
          <AccordionComponent
            accordionStyle={accordionStyle}
            data={[{
              header: (
                <div css={itemHeaderStyle}>
                  <div css={itemHeaderLeftStyle}>
                    <div css={visibilityWrapperStyle}>
                      <HoverableIconComponent
                        css={visibilityIconStyle}
                        icon={otherOptions.visible ? faEye : faEyeSlash}
                        hoverColor={theme.iconColors.contrast}
                        onClick={() => props.updateHeatMapVisibility(heatmap.id, !otherOptions.visible)}
                      />
                    </div>
                    <span css={ellipsisStyle}>{getHeatMapItemName(heatmap, props.columns, t)}</span>
                  </div>

                  <HoverableIconComponent
                    hoverColor={theme.iconColors.danger}
                    icon={faTrash}
                    onClick={() => props.onHeatMapItemRemove(heatmap)}
                  />
                </div>
              ),
              child: (
                <>
                  <table css={tableStyle}>
                    <tbody>
                      <tr>
                        <td css={labelColumnStyle}>
                          {t('Radius')}
                        </td>
                        <td css={sliderColumnStyle}>
                          <SliderWithValueLabelsComponent
                            value={[heatmap.radius]}
                            onChange={(value) => props.updateHeatMapItem({ ...heatmap, radius: value[0] })}
                            currentValueOnTheRight
                            valueSuffix="%"
                          />
                        </td>
                      </tr>
                      <tr css={css({ paddingTop: 17 })}>
                        <td css={labelColumnStyle}>
                          {t('Opacity')}
                        </td>
                        <td css={sliderColumnStyle}>
                          <SliderWithValueLabelsComponent
                            value={[heatmap.opacity]}
                            onChange={(value) => props.updateHeatMapItem({ ...heatmap, opacity: value[0] })}
                            currentValueOnTheRight
                            valueSuffix="%"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td css={labelColumnStyle}>
                          {t('Threshold')}
                        </td>
                        <td css={sliderColumnStyle}>
                          <SliderWithValueLabelsComponent
                            value={[heatmap.threshold]}
                            onChange={(value) => props.updateHeatMapItem({ ...heatmap, threshold: value[0] })}
                            currentValueOnTheRight
                            valueSuffix="%"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <CheckboxComponent
                    css={checkboxStyle}
                    isChecked={heatmap.dissipate}
                    checkedSetter={() => props.updateHeatMapItem({ ...heatmap, dissipate: !heatmap.dissipate })}
                    text={t('Dissipate')}
                  />

                  <CheckboxComponent
                    css={checkboxStyle}
                    isChecked={heatmap.unlinkFromOtherTools}
                    checkedSetter={() => props.updateHeatMapItem({ ...heatmap, unlinkFromOtherTools: !heatmap.unlinkFromOtherTools })}
                    text={t('Unlink From Other Tools')}
                  />

                  {heatmap.sample === HeatMapSample.SpecificGroup && (
                    <CheckboxComponent
                      css={checkboxStyle}
                      isChecked={!!otherOptions.showMarkersForGroup}
                      checkedSetter={() => props.updateHeatMapItemData(heatmap.id, { showMarkersForGroup: !otherOptions.showMarkersForGroup })}
                      text={t('Show Markers For This Group')}
                    />
                  )}

                  <div css={gradientWrapperStyle}>
                    <p css={gradientLabelStyle}>{t('Gradient')}</p>

                    <ToggleComponent
                      css={gradientToggleStyle}
                      onChange={() => props.updateHeatMapItem({ ...heatmap, isGradient: !heatmap.isGradient })}
                      isOn={heatmap.isGradient}
                    />
                  </div>

                  {heatmap.isGradient && (
                    <>
                      <HeatMapColorRowComponent
                        css={colorSelectRowStyle}
                        label={t('Dense')}
                        selectedColor={createColor(heatmap.denseColor)}
                        onColorChange={color => props.updateHeatMapItem({ ...heatmap, denseColor: removeHash(color.hex) })}
                      />

                      <HeatMapColorRowComponent
                        css={colorSelectRowStyle}
                        label={t('Medium')}
                        selectedColor={createColor(heatmap.mediumColor)}
                        onColorChange={color => props.updateHeatMapItem({ ...heatmap, mediumColor: removeHash(color.hex) })}
                      />

                      <HeatMapColorRowComponent
                        css={colorSelectRowStyle}
                        label={t('Light')}
                        selectedColor={createColor(heatmap.lightColor)}
                        onColorChange={color => props.updateHeatMapItem({ ...heatmap, lightColor: removeHash(color.hex) })}
                      />
                    </>
                  )}

                  {!heatmap.isGradient && (
                    <HeatMapColorRowComponent
                      css={colorSelectRowStyle}
                      label={t('Color')}
                      selectedColor={createColor(heatmap.singleColor)}
                      onColorChange={color => props.updateHeatMapItem({ ...heatmap, singleColor: removeHash(color.hex) })}
                    />
                  )}
                </>
              ),
              isExpanded: true,
              isLocked: true,
            }]}
            headerSize={AccordionHeaderSize.Large}
            headerWrapperStyle={accordionHeaderWrapperStyle}
            itemButtonStyle={accordionButtonStyle}
            panelStyle={itemContentStyle}
          />

        </Fragment>
      ))}
    </div>
  );
};
