import { css } from '@emotion/react';
import {
  faCog, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { TriStateCheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';

const BUTTON_SECTION_WIDTH_WITH_COG = 147;
const BUTTON_SECTION_WIDTH_WITHOUT_COG = 121;

const itemHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const itemNameStyle = ({ expandedWidth }: { expandedWidth: boolean }) => css({
  cursor: 'default',
  fontWeight: 500,
  maxWidth: `calc(100% - ${expandedWidth ? BUTTON_SECTION_WIDTH_WITHOUT_COG : BUTTON_SECTION_WIDTH_WITH_COG}px)`,
});

const ellipsisStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const itemNameSubStyle = css(ellipsisStyle, {
  fontSize: '14px',
  lineHeight: '16px',
});

const columnSubStyle = css(ellipsisStyle, {
  fontSize: '12px',
  lineHeight: '21px',
});

const buttonSectionStyle = ({ expandedWidth }: { expandedWidth: boolean }) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: expandedWidth ? BUTTON_SECTION_WIDTH_WITH_COG : BUTTON_SECTION_WIDTH_WITHOUT_COG,
});

const rowWithCheckboxStyle = css({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
});

const checkboxStyle = css({
  width: 20,
  height: 20,
  paddingRight: 4,
});

const trashIconStyle = css({
  fontSize: '16px',
});

const numericGroupSettingsButtonStyle = css({
  fontSize: '16px',
  background: 'none',
  border: 'none',
  color: 'inherit',
});

type GroupingItemHeaderProps = Readonly<{
  groupName: string;
  groupStatusTitle: string;
  isEditingDisabled?: boolean;
  isFilteringDisabled?: boolean;
  showAll: TriStateRange;
  showCogButton: boolean;

  onCogClick: () => void;
  onRemove?: () => void;
  onToggleShowAll: (newValue: boolean) => void;
}>;

export const GroupingItemHeaderComponent: FC<GroupingItemHeaderProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  const isEditable = props.showCogButton && !props.isEditingDisabled;

  return (
    <div css={itemHeaderStyle}>
      <div css={itemNameStyle({ expandedWidth: !isEditable })}>
        <div css={itemNameSubStyle}>{props.groupName}</div>
        <div css={columnSubStyle}>{props.groupStatusTitle}</div>
      </div>

      <div css={buttonSectionStyle({ expandedWidth: isEditable })}>
        {!props.isFilteringDisabled && (
          <label css={rowWithCheckboxStyle}>
            <TriStateCheckboxComponent
              css={checkboxStyle}
              checkedSetter={() => props.onToggleShowAll(props.showAll !== TriStateRange.Full)}
              checkmark={props.showAll}
            />
            {t('Show All')}
          </label>
        )}

        {isEditable && (
          <HoverableIconComponent
            css={numericGroupSettingsButtonStyle}
            hoverColor={theme.iconColors.borderPrimaryHover}
            icon={faCog}
            onClick={props.onCogClick}
          />
        )}

        {!props.isEditingDisabled && props.onRemove && (
          <HoverableIconComponent
            css={trashIconStyle}
            onClick={props.onRemove}
            hoverColor={theme.iconColors.danger}
            icon={faTrash}
          />
        )}
      </div>
    </div>
  );
};
