import {
  createContext,
  type FC, type ReactNode, useContext, useMemo,
} from 'react';
import { type Nullable } from '~/_shared/utils/types/common.type';
import { type MapObjectZIndex } from '../mapObject.types';
import { type MapObjectManager } from './mapObjectManager';

export type MapObjectContextModel = {
  readonly manager: MapObjectManager;
  readonly zIndex: MapObjectZIndex;
};

const MapObjectContext = createContext<MapObjectContextModel | null>(null);

type MapObjectContextProviderProps = {
  value: Partial<Nullable<MapObjectContextModel>>;
  children: ReactNode;
};

export const MapObjectContextProvider: FC<MapObjectContextProviderProps> = ({ value: { manager, zIndex }, children }) => {
  const mapObjectContext = useMemo(() => manager && zIndex ? { manager, zIndex } : null, [manager, zIndex]);

  if (!mapObjectContext) {
    return null;
  }

  return (
    <MapObjectContext.Provider value={mapObjectContext} >
      {children}
    </MapObjectContext.Provider>
  );
};

/*
When the nearest <MyContext.Provider> above the component updates, this Hook will trigger a rerender
with the latest context value passed to that MyContext provider. Even if an ancestor uses React.memo
or shouldComponentUpdate, a rerender will still happen starting at the component itself using useContext.
*/
export const useMapObjectContext = (): MapObjectContextModel => {
  const context = useContext(MapObjectContext);

  if (!context) {
    throw new Error('MapObject components cannot be used without the context provider.');
  }

  return context;
};
