import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  type ActiveGroupFilters, type GroupingColumn, type UniqueGroup,
} from '../../../_shared/types/grouping/grouping';
import {
  getActiveGroupFiltersFromFiltersState,
  getActiveGroupFiltersValueForGroupIdToggle,
} from '../../../_shared/utils/grouping/grouping.helpers';
import { mapSettingsColumnsFilterSetGroup } from '../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilter.actionCreators';
import { useMapSettingsColumnsFilterSelector } from '../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';

export const useColumnsFilterGroupUnselect = () => {
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const dispatch = useDispatch();

  const onGroupUnselect = useCallback((groupingColumn: GroupingColumn, uniqueGroup: UniqueGroup) => {
    const activeFilter: ActiveGroupFilters = getActiveGroupFiltersFromFiltersState(columnsFilter);

    const newValues = getActiveGroupFiltersValueForGroupIdToggle(activeFilter, groupingColumn, uniqueGroup);
    dispatch(mapSettingsColumnsFilterSetGroup(groupingColumn, newValues));
  }, [columnsFilter, dispatch]);

  return {
    onGroupUnselect,
  };
};
