import { MarkerColor } from '../../../_shared/types/marker.types';
import {
  MapObjectOutlineSize,
  MapObjectOutlineThinCircleSize,
} from '../mapObjects/mapObject.types';
import { type MapOutlineVisualsConfig } from '../mapObjects/mapOutline/mapOutline.component';
import { type MapShapePolylineVisualsConfig } from '../mapObjects/mapShape/mapShapeLine.component';
import { type MapShapePolygonVisualConfig } from '../mapObjects/mapShape/mapShapePolygon.component';
import { type MapShapeRemoveButtonVisualsConfig } from '../mapObjects/mapShape/mapShapeRemoveButton.component';

export const dcOutlineVisuals: MapOutlineVisualsConfig = {
  dot: {
    color: MarkerColor.Black,
    size: MapObjectOutlineSize.Small,
  },
};

export const dcOutlineRemoveVisuals: MapOutlineVisualsConfig = {
  dot: {
    color: MarkerColor.Red,
    size: MapObjectOutlineSize.Small,
  },
};

export const dcOutlineHoverVisuals: MapOutlineVisualsConfig = {
  dot: {
    color: MarkerColor.Black,
    size: MapObjectOutlineSize.Default,
  },
  circle: {
    color: MarkerColor.Blue,
    size: MapObjectOutlineThinCircleSize.Enlarged,
    style: 'thin',
  },
};

export const dcLineVisuals: MapShapePolylineVisualsConfig = {
  color: [88, 125, 215, 1],
  style: 'dash',
  width: 3,
};

export const dcLineRemoveVisuals: MapShapePolylineVisualsConfig = {
  color: [255, 0, 0, 1],
  style: 'dash',
  width: 3,
};

export const dcRemoveButtonVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Black,
};

export const dcRemoveButtonHoverVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Red,
};

export const dcPolygonVisuals: MapShapePolygonVisualConfig = {
  fillColor: [0, 0, 255, 0.2],
};
