import {
  buffers, eventChannel,
} from 'redux-saga';
import {
  call, put, take, takeLatest,
} from 'redux-saga/effects';
import {
  type CanceledOptimizedTerritoriesEventData,
  type FailedOptimizedTerritoriesEventData,
  type OptimizedTerritoriesEventData,
  type OptimizedTerritoriesStatusEventData,
  registerOptimizedTerritoriesBroadcastListener,
} from '~/_shared/utils/api/broadcast.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { boundaryGroupsRequest } from '~/store/boundaryGroups/boundaryGroups.actionCreators';
import { boundaryTerritoryGroupsFetchRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { mapSettingsBoundariesSetPrimaryBoundaryGroupId } from '~/store/mapSettings/boundaries/mapSettingsBoundaries.actionCreators';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { mapIdSelector } from '~/store/selectors/useMapIdSelector';
import { USER_WEB_SOCKET_BROADCAST_INITIALIZED } from '~/store/userData/userData.actionTypes';
import { translate } from '~/translations/Trans';
import { type PickAction } from '../../../../../_shared/utils/types/action.type';
import { type MapInfoAction } from '../../../../mapInfo/mapInfo.action';
import { MAP_INFO_FETCH_DATA_SUCCESS } from '../../../../mapInfo/mapInfo.actionTypes';
import {
  boundaryCreateOptimizedAddItem, boundaryCreateOptimizedRemoveItem, boundaryCreateOptimizedUpdateItem,
} from './boundaryCreateOptimized.actionCreators';
import { boundaryCreateOptimizedPendingItemsSelector } from './boundaryCreateOptimized.selectors';
import {
  type BoundaryCreateOptimizedItem, BoundaryCreateOptimizedStatus,
} from './boundaryCreateOptimized.state';
import { createBoundaryCreateOptimizedStatusFromResponse } from './boundaryCreateOptimizedStatus.factory';

export function* boundaryCreateOptimizedSagas() {
  yield takeLatest(MAP_INFO_FETCH_DATA_SUCCESS, onMapInfoFetchSuccess);
  yield takeLatest(USER_WEB_SOCKET_BROADCAST_INITIALIZED, registerOptimizedTerritoriesSocketEventsProcessor);
}

function* onMapInfoFetchSuccess(action: PickAction<MapInfoAction, typeof MAP_INFO_FETCH_DATA_SUCCESS>) {
  for (const territoryGeneration of action.payload.data.territoryGeneration) {
    if (territoryGeneration.status !== BoundaryCreateOptimizedStatus.Finished) {
      yield put(boundaryCreateOptimizedAddItem(territoryGeneration));
    }
  }
}

type OptimizedTerritoriesListenerMessage = {
  type: 'change';
  data: OptimizedTerritoriesStatusEventData;
} | {
  type: 'success';
  data: OptimizedTerritoriesEventData;
} | {
  type: 'error';
  data: FailedOptimizedTerritoriesEventData;
} | {
  type: 'cancel';
  data: CanceledOptimizedTerritoriesEventData;
};

const registerOptimizedTerritoriesListener = () => {
  return eventChannel<OptimizedTerritoriesListenerMessage>(emitter => {
    registerOptimizedTerritoriesBroadcastListener({
      onStatusChange: (data) => {
        emitter({
          type: 'change',
          data,
        });
      },
      onSuccess: (data) => {
        emitter({
          type: 'success',
          data,
        });
      },
      onError: (data) => {
        emitter({
          type: 'error',
          data,
        });
      },
      onCancel: (data) => {
        emitter({
          type: 'cancel',
          data,
        });
      },
    });

    return noop;
  }, buffers.expanding());
};

function* registerOptimizedTerritoriesSocketEventsProcessor() {
  const optimizedTerritoriesChannel: ReturnType<typeof registerOptimizedTerritoriesListener> = yield call(registerOptimizedTerritoriesListener);

  while (true) {
    const message: OptimizedTerritoriesListenerMessage = yield take(optimizedTerritoriesChannel);
    const boundaryCreateOptimizedPendingItems: ReadonlyArray<BoundaryCreateOptimizedItem>
      = yield select<ReadonlyArray<BoundaryCreateOptimizedItem>>(boundaryCreateOptimizedPendingItemsSelector);

    const pendingItem = boundaryCreateOptimizedPendingItems.find(item => item.id === message.data.id);
    if (!pendingItem) {
      break;
    }

    switch (message.type) {
      case 'change': {
        yield put(boundaryCreateOptimizedUpdateItem({
          id: message.data.id,
          status: createBoundaryCreateOptimizedStatusFromResponse(message.data.status),
        }));
        break;
      }
      case 'success': {
        yield put(boundaryCreateOptimizedRemoveItem(message.data.id));
        const mapId: number | null = yield select<number | null>(mapIdSelector);

        if (mapId !== null) {
          yield put(boundaryTerritoryGroupsFetchRequest(mapId));
          yield put(boundaryGroupsRequest());

          if (pendingItem.startedOnCurrentTab) { // prevent map settings update conflicts when map is opened multiple times
            yield put(mapSettingsBoundariesSetPrimaryBoundaryGroupId(message.data.btg.boundary_group_id));
          }
        }
        break;
      }
      case 'cancel':{
        yield put(boundaryCreateOptimizedRemoveItem(message.data.id));
        break;
      }
      case 'error': {
        yield put(boundaryCreateOptimizedRemoveItem(message.data.id));
        yield put(createAppError({
          type: AppErrorType.General,
          title: translate('Failed to create optimised territories'),
        }));
        break;
      }
      default: {
        break;
      }
    }
  }
}
