import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetRowId } from '../../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { AppErrorType } from '../../../../../appError/appErrorType.enum';
import { useLatLngSpreadsheetData } from '../../../../../map/map/useSpreadsheetData.hook';
import { ModalType } from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import { frontendStateDirectionsAddMarkerWaypoint } from '../../../../../store/frontendState/mapTools/directions/directions.actionCreators';
import {
  type MarkersFetchDataResults, useMarkersInBoundary,
} from './useMarkersInBoundary';

export const useBoundaryDirections = () => {
  const { getMarkersInBoundary, getMarkersInBoundaryTerritory } = useMarkersInBoundary();
  const { openModal: openAppErrorModal } = useModal(ModalType.AppError);
  const latLngLookup = useLatLngSpreadsheetData();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onDataFetchSuccess = useCallback((response: MarkersFetchDataResults) => {
    const spreadsheetRowIds = response.rowsData.reduce<SpreadsheetRowId[]>((acc, item) => {
      for (const row of item.rows) {
        acc.push({
          spreadsheetId: item.spreadsheetId,
          rowId: row.id,
        });
      }

      return acc;
    }, []);

    spreadsheetRowIds.forEach(spreadsheetRowId => {
      const center = latLngLookup.getRow(spreadsheetRowId);

      if (!center) {
        return;
      }

      dispatch(frontendStateDirectionsAddMarkerWaypoint({
        address: t('Marker {{rowId}}', { rowId: spreadsheetRowId.rowId }),
        latLng: center,
        markerId: spreadsheetRowId,
        startsFromUserLocation: false,
      }));
    });
  }, [latLngLookup, dispatch, t]);

  const onDataFetchError = useCallback(() => {
    openAppErrorModal({
      type: AppErrorType.General,
      title: t('Failed to fetch data'),
      content: t('Something went wrong, please try again.'),
    });
  }, [openAppErrorModal, t]);

  const addBoundaryDirections = useCallback((boundaryTerritoryGroupId: number, boundaryId: number) => {
    getMarkersInBoundary(boundaryTerritoryGroupId, boundaryId)
      .then(onDataFetchSuccess)
      .catch(onDataFetchError);
  }, [getMarkersInBoundary, onDataFetchSuccess, onDataFetchError]);

  const addBoundaryTerritoryDirections = useCallback((boundaryTerritoryGroupId: number, boundaryTerritoryId: string) => {
    getMarkersInBoundaryTerritory(boundaryTerritoryGroupId, boundaryTerritoryId)
      .then(onDataFetchSuccess)
      .catch(onDataFetchError);
  }, [getMarkersInBoundaryTerritory, onDataFetchSuccess, onDataFetchError]);

  return {
    addBoundaryDirections,
    addBoundaryTerritoryDirections,
  };
};
