import {
  buffers, type TakeableChannel,
} from 'redux-saga';
import {
  actionChannel, all, call, put, take, takeLatest,
} from 'redux-saga/effects';
import { type SpreadsheetDescriptor } from '~/_shared/types/map';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import { type SpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import {
  getSpreadsheetBulkData, type SpreadsheetDataBulkRequestDescriptor, type SpreadsheetDataBulkRequestGetter,
  type SpreadsheetDataBulkResponse, type SpreadsheetDataColumnsToFetch,
} from '~/spreadsheet/spreadsheet.repository';
import { MAP_PAGE_INIT } from '~/store/map/map.actionTypes';
import { type MapInfoState } from '~/store/mapInfo/mapInfo.state';
import { MAP_SETTINGS_READY_SET } from '~/store/mapSettings/ready/mapSettingsReady.actionTypes';
import { areMapSettingsReadySelector } from '~/store/mapSettings/ready/mapSettingsReady.selectors';
import { type GeocodingAction } from '../geocoding/geocoding.action';
import { GEOCODING_PROCESSING_SUCCESS } from '../geocoding/geocoding.actionTypes';
import {
  MAP_INFO_FETCH_DATA_SUCCESS, MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS,
} from '../mapInfo/mapInfo.actionTypes';
import {
  MAP_SETTINGS_SYNC_CANCEL, MAP_SETTINGS_SYNC_SUCCESS,
} from '../mapSettings/data/mapSettingsData.actionTypes';
import { type MapSettingsState } from '../mapSettings/mapSettings.state';
import {
  MATCHUP_FETCH_DATA_SUCCESS, MATCHUP_UPDATE_SUCCESS,
} from '../matchupData/matchupData.actionTypes';
import { checkAreAllMatchupDataLoaded } from '../matchupData/matchupData.helpers';
import { type MatchupDataState } from '../matchupData/matchupData.state';
import { primarySpreadsheetRealSpreadsheetsSelector } from '../selectors/usePrimarySpreadsheetRealSpreadsheets';
import {
  spreadsheetAddFilterItem, spreadsheetFetchSpreadsheetDataError, spreadsheetFetchSpreadsheetDataStarted,
  spreadsheetFetchSpreadsheetDataSuccess, spreadsheetResetStateAndRefetchData,
} from './spreadsheetData.actionCreators';
import { SPREADSHEET_RESET_STATE_AND_REFETCH_DATA } from './spreadsheetData.actionTypes';
import {
  applyColumnToFetchToState,
  cloneSpreadsheetData,
  createUnfilteredBasicDataFromSpreadsheetResponse, getEmptyColumnsForRequestDescriptorColumns,
  getMissingSpreadsheetDataToFetch, missingBoundaryLocationsFilterDataBulkRequestGetters,
  missingColumnsToSpreadsheetBulkGetters, missingFilteredDataBulkGetters, missingSearchDataBulkRequestGetters,
  type WritableSpreadsheetData,
} from './spreadsheetData.helpers';
import {
  emptySpreadsheetData,
  type SpreadsheetDataState, type SpreadsheetFilters, Unfiltered,
} from './spreadsheetData.state';

const REFETCH_SPREADSHEET_DATA_TRIGGERS = [
  MAP_INFO_FETCH_DATA_SUCCESS,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS,
  MAP_PAGE_INIT,
  MAP_SETTINGS_READY_SET,
  MAP_SETTINGS_SYNC_SUCCESS,
  MAP_SETTINGS_SYNC_CANCEL,
  MATCHUP_FETCH_DATA_SUCCESS,
  MATCHUP_UPDATE_SUCCESS,
  SPREADSHEET_RESET_STATE_AND_REFETCH_DATA,
];

export function* spreadsheetDataSagas() {
  // used all to make the saga concurrent
  yield all([
    spreadsheetDataRegularSagas(),
    watchForSpreadsheetDataRefetch(),
  ]);
}

export function* spreadsheetDataRegularSagas() {
  yield takeLatest(GEOCODING_PROCESSING_SUCCESS, resetSpreadsheetStateAndRefetchData);
}

function* resetSpreadsheetStateAndRefetchData(action: PickAction<GeocodingAction, typeof GEOCODING_PROCESSING_SUCCESS>) {
  const primarySpreadsheetRealSpreadsheetIds: {id: number}[] | null = yield select<{id: number}[] | null>(primarySpreadsheetRealSpreadsheetsSelector);
  if (primarySpreadsheetRealSpreadsheetIds?.length) {
    const finishedGeocodingIds = action.payload.realSpreadsheetIds;
    if (finishedGeocodingIds?.size) {
      for (const primaryRealSpreadsheetId of primarySpreadsheetRealSpreadsheetIds) {
        if (finishedGeocodingIds.has(primaryRealSpreadsheetId.id)) {
          yield put(spreadsheetResetStateAndRefetchData());
          return;
        }
      }
    }
  }
}

function* fetchSpreadsheetData(
  clientId: number,
  mapId: number,
  getterDescriptors: SpreadsheetDataBulkRequestDescriptor[],
  currentSpreadsheetState: SpreadsheetDataState,
) {
  yield put(spreadsheetFetchSpreadsheetDataStarted());

  try {
    const bulkGetters: SpreadsheetDataBulkRequestGetter[] = getterDescriptors.map(item => ({
      ...item.requestGetter,
      map_id: mapId,
    }));

    const spreadsheetData: { data: SpreadsheetDataBulkResponse[] } = yield call(
      getSpreadsheetBulkData,
      clientId,
      { params: bulkGetters }
    );

    const newSpreadsheetData = currentSpreadsheetState.data
      ? { ...currentSpreadsheetState.data } as WritableSpreadsheetData // for performance reasons
      : cloneSpreadsheetData(emptySpreadsheetData);

    let requestedDescriptorColumnsPerSpreadsheet: {[spreadsheetId: number]: SpreadsheetDataColumnsToFetch} = {};
    // create unfiltered items if there are missing
    for (let i = 0; i < spreadsheetData.data.length; i++) {
      const spreadsheet = spreadsheetData.data[i];
      const requestDescriptor = getterDescriptors[i];

      if (spreadsheet) {
        const spreadsheetId = spreadsheet.spreadsheet_id;
        requestedDescriptorColumnsPerSpreadsheet = {
          ...requestedDescriptorColumnsPerSpreadsheet,
          [spreadsheetId]: { ...requestDescriptor?.requestGetter.columns_to_fetch },
        };

        if (requestDescriptor?.requestType === 'basicData') {
          const matchupData: SpreadsheetMatchupData | undefined | null = yield select<SpreadsheetMatchupData | undefined | null>(
            state => state.spreadsheet.matchupData[spreadsheetId]?.data,
          );

          if (!matchupData || (currentSpreadsheetState.data && currentSpreadsheetState.data.values[spreadsheetId])) {
            continue;
          }

          const item = createUnfilteredBasicDataFromSpreadsheetResponse(spreadsheet, matchupData);
          newSpreadsheetData.values[spreadsheetId] = {
            [Unfiltered]: {
              ...newSpreadsheetData.values[spreadsheetId]?.[Unfiltered],
              ...item,
            },
          };
        }

        if (requestDescriptor?.requestType === 'filter' || requestDescriptor?.requestType === 'search' || requestDescriptor?.requestType === 'boundary-locations-filter') {
          yield put(spreadsheetAddFilterItem(
            spreadsheetId,
            requestDescriptor.filterHash,
            spreadsheet.result?.filtered_rows?.reduce<{ [key: string]: 1 }>((acc, item) => {
              acc[item] = 1;
              return acc;
            }, {}) ?? {},
          ));
        }
      }
    }

    // merge columns_to_fetch into the state
    for (const spreadsheet of spreadsheetData.data) {
      const spreadsheetId = spreadsheet.spreadsheet_id;
      const requestedDescriptorColumns = requestedDescriptorColumnsPerSpreadsheet[spreadsheetId];
      const columnsToFetch = spreadsheet.result.columns_to_fetch ??
        getEmptyColumnsForRequestDescriptorColumns(requestedDescriptorColumns ?? {});

      if (!newSpreadsheetData.values[spreadsheetId]) {
        newSpreadsheetData.values[spreadsheetId] = {};
      }

      const dataValuesUnfiltered = newSpreadsheetData.values[spreadsheetId]?.unfiltered;
      newSpreadsheetData.values[spreadsheetId] = {
        [Unfiltered]: applyColumnToFetchToState(columnsToFetch, dataValuesUnfiltered ?? {}),
      };
    }

    yield put(spreadsheetFetchSpreadsheetDataSuccess(newSpreadsheetData, currentSpreadsheetState.etag));
  }
  catch (e) {
    console.error(e);
    yield put(spreadsheetFetchSpreadsheetDataError(e));
  }
}

function* watchForSpreadsheetDataRefetch() {
  // use buffer instead of take latest, so that we won't abandon already running fetch call
  // subsequent fetch might be cancelled if all data was already loaded by previous call
  const refetchTriggersChannel: TakeableChannel<Action> = yield actionChannel(REFETCH_SPREADSHEET_DATA_TRIGGERS, buffers.sliding(1));

  while (true) {
    yield take(refetchTriggersChannel);

    const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

    if (clientId === null) {
      continue;
    }

    const mapId: number | null = yield select<number | null>(state => state.map.mapId);

    if (mapId === null) {
      continue;
    }

    const mapSettingsReady: boolean = yield select<boolean>(areMapSettingsReadySelector);
    if (!mapSettingsReady) {
      continue;
    }

    const isMatchupRequired: boolean = yield select<boolean>(
      state => state.map.mapInfo.data?.isMatchupRequired ?? false
    );

    if (isMatchupRequired) {
      continue;
    }

    const matchupData: MatchupDataState = yield select(state => state.spreadsheet.matchupData);

    if (Object.keys(matchupData).length === 0 || !checkAreAllMatchupDataLoaded(matchupData)) {
      continue;
    }

    try {
      const currentSpreadsheetState: SpreadsheetDataState = yield select<SpreadsheetDataState>(
        state => state.spreadsheet.spreadsheetData
      );

      const missingSpreadsheetDataToFetch: ReturnType<typeof getMissingSpreadsheetDataToFetch> = yield call(getMissingDataToFetchSaga);

      if (Object.entries(missingSpreadsheetDataToFetch.data).length === 0 &&
        !missingSpreadsheetDataToFetch.filterDetails &&
        !missingSpreadsheetDataToFetch.search &&
        !missingSpreadsheetDataToFetch.boundaryFilter
      ) {
        continue;
      }

      const spreadsheetDataBulkRequestGetters = missingColumnsToSpreadsheetBulkGetters(mapId, missingSpreadsheetDataToFetch);
      const filterDataBulkRequestGetters = missingFilteredDataBulkGetters(mapId, missingSpreadsheetDataToFetch);
      const searchDataBulkRequestGetters = missingSearchDataBulkRequestGetters(missingSpreadsheetDataToFetch);
      const boundaryLocationsFilterDataBulkRequestGetters = missingBoundaryLocationsFilterDataBulkRequestGetters(missingSpreadsheetDataToFetch);

      const getterDescriptors = spreadsheetDataBulkRequestGetters
        .concat(filterDataBulkRequestGetters)
        .concat(searchDataBulkRequestGetters)
        .concat(boundaryLocationsFilterDataBulkRequestGetters);

      yield call(fetchSpreadsheetData, clientId, mapId, getterDescriptors, currentSpreadsheetState);
    }
    catch (e) {
      console.error(e);
      yield put(spreadsheetFetchSpreadsheetDataError(e));
    }
  }
}

export function* getMissingDataToFetchSaga() {
  const mapSettings: MapSettingsState = yield select<MapSettingsState>(state => state.map.mapSettings);
  const currentSpreadsheetState: SpreadsheetDataState = yield select<SpreadsheetDataState>(
    state => state.spreadsheet.spreadsheetData
  );
  const currentSpreadsheetFiltersData: SpreadsheetFilters = yield select<SpreadsheetFilters>(
    state => state.spreadsheet.spreadsheetData.filters ?? newPerSpreadsheetMap()
  );
  const mainMapSpreadsheets: SpreadsheetDescriptor[] = yield select<readonly SpreadsheetDescriptor[]>(
    state => state.map.mapInfo.data?.spreadsheets || []
  );
  const matchupDataState: MatchupDataState = yield select<MatchupDataState>(
    state => state.spreadsheet.matchupData
  );
  const mapInfo: MapInfoState = yield select<MapInfoState>(
    state => state.map.mapInfo
  );

  return getMissingSpreadsheetDataToFetch(
    mainMapSpreadsheets.map(item => item.spreadSheetId),
    mapSettings,
    currentSpreadsheetState.data ?? emptySpreadsheetData,
    currentSpreadsheetFiltersData,
    matchupDataState,
    Boolean(mapInfo?.data?.isLayered),
  );
}
