import {
  useCallback,
  useEffect, useState,
} from 'react';

export enum ProximityLimitMarkersType {
  All = 'All',
  ClosestToCenter = 'ClosestToCenter',
}

export enum ProximityLimitInclusionType {
  Inside = 'Inside',
  Outside = 'Outside',
}

export const useExportProximityLimitResults = () => {
  const [markersCount, setMarkersCount] = useState(100);
  const [markersLimitType, setMarkersLimitType] = useState(ProximityLimitMarkersType.All);
  const [markersInclusionType, setMarkersInclusionType] = useState(ProximityLimitInclusionType.Inside);
  const [isReturnMarkersOnlyOnce, setIsReturnMarkersOnlyOnce] = useState(false);

  useEffect(() => {
    if (markersLimitType === ProximityLimitMarkersType.ClosestToCenter
      && markersInclusionType === ProximityLimitInclusionType.Outside
    ) {
      setMarkersInclusionType(ProximityLimitInclusionType.Inside);
    }
  }, [markersInclusionType, markersLimitType]);

  // change markers limit type to "Closest to center" if count is changed
  const onMarkersCountChange = useCallback((newCount: number) => {
    setMarkersCount(newCount);
    setMarkersLimitType(ProximityLimitMarkersType.ClosestToCenter);
  }, []);

  return {
    markersCount,
    onMarkersCountChange,
    markersLimitType,
    markersInclusionType,
    onMarkersLimitTypeChange: setMarkersLimitType,
    onMarkersInclusionTypeChange: setMarkersInclusionType,
    isReturnMarkersOnlyOnce,
    onIsReturnMarkersOnlyOnceChange: setIsReturnMarkersOnlyOnce,
  };
};
