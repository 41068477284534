import {
  createBoundaryTerritoryFromResponse,
  createBoundaryTerritorySettingsRequestFromBoundaryTerritorySettings,
  createRequestFromBoundaryTerritoryGroupSettings,
  getBoundaryTerritoryGroupMatchupRequest,
  sortBoundaryTerritoriesFromResponse,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import {
  type BoundaryTerritoryGroup,
  type BoundaryTerritoryGroupModel,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  type BoundaryTerritoryGroupDetailsFilterRequest,
  type BoundaryTerritoryGroupDetailsItemResponse,
  type BoundaryTerritoryGroupDetailsRequestQuery,
  type BoundaryTerritoryGroupDetailsResponse,
} from './boundaryTerritoryDetails.repository';
import {
  type BoundaryTerritoryGroupDetails, type BoundaryTerritoryGroupDetailsItem,
} from './boundaryTerritoryGroups.state';

export const createBoundaryTerritoryGroupDetailsFromResponse = (
  response: BoundaryTerritoryGroupDetailsResponse
): BoundaryTerritoryGroupDetails => {
  return {
    boundaryDetails: createBoundaryTerritoryGroupDetailsItemFromResponse(response.boundary_details),
    boundaryGroupId: response.boundary_group_id,
    boundaryTerritoryGroupId: response.boundary_territory_group_id,
    boundaryTerritories: response.boundary_territories
      .map(territory => createBoundaryTerritoryFromResponse(territory))
      .sort(sortBoundaryTerritoriesFromResponse),
    boundaryTerritoryGroupQueryId: response.boundary_territory_group_query_id,
    mapId: response.map_id,
  };
};

export const createBoundaryTerritoryGroupDetailsRequestQuery = (
  territoryGroup: BoundaryTerritoryGroupModel,
  boundaryTerritoryGroupQueryId: string,
  filter?: BoundaryTerritoryGroupDetailsFilterRequest,
): BoundaryTerritoryGroupDetailsRequestQuery => {

  return {
    boundary_group_id: territoryGroup.boundaryGroupId,
    boundary_territory_group_id: territoryGroup.boundaryTerritoryGroupId,
    boundary_territory_group_query_id: boundaryTerritoryGroupQueryId,
    map_id: territoryGroup.mapId,
    matchings: getBoundaryTerritoryGroupMatchupRequest(territoryGroup.matchings),
    settings: createBoundaryTerritorySettingsRequestFromBoundaryTerritorySettings(territoryGroup.settings),
    filter,
  };
};

export const createBoundaryTerritoryRequestFromBoundaryTerritoryGroup = (
  boundaryTerritoryGroup: BoundaryTerritoryGroup,
  queryId: string,
  request?: Partial<BoundaryTerritoryGroupDetailsRequestQuery>,
): BoundaryTerritoryGroupDetailsRequestQuery => {
  return {
    boundary_territory_group_query_id: queryId,
    map_id: boundaryTerritoryGroup.mapId,
    boundary_group_id: boundaryTerritoryGroup.boundaryGroupId,
    boundary_territory_group_id: boundaryTerritoryGroup.boundaryTerritoryGroupId,
    matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
    settings: createRequestFromBoundaryTerritoryGroupSettings(boundaryTerritoryGroup.settings),
    ...request,
  };
};

const createBoundaryTerritoryGroupDetailsItemFromResponse = (
  response: BoundaryTerritoryGroupDetailsItemResponse,
): BoundaryTerritoryGroupDetailsItem => {
  return {
    assignments: response.assignments,
    manualAssignments: response.manual_assignments,
    counts: response.counts,
    numericalData: {
      totals: response.numerical_data.totals,
      totalHighest: response.numerical_data.total_highest,
      totalLowest: response.numerical_data.total_lowest,
    },
    hasEmpty: response.has_empty,
  };
};
