import {
  type FileAttachment, type FileAttachmentId,
} from '~/_shared/types/file.types';
import { type AppState } from '~/store/app.store';
import { type boundariesReducer } from '~/store/boundaries/boundaries.reducer';
import { type BoundaryGroupsState } from '~/store/boundaryGroups/boundaryGroups.state';
import { type BoundaryItemsState } from '~/store/boundaryItems/boundaryItems.state';
import { type frontendStateReducer } from '~/store/frontendState/frontendState.reducer';
import { type directionsReducer } from '~/store/frontendState/mapTools/directions/directions.reducer';
import { type mapToolsReducer } from '~/store/frontendState/mapTools/mapTools.reducer';
import { type mapZIndexesReducer } from '~/store/frontendState/mapZIndexes/mapZIndexes.reducer';
import { type proximityDriveTimePathsReducer } from '~/store/frontendState/proximityDriveTimePaths/proximityDriveTimePaths.reducer';
import { type mapReducer } from '~/store/map/map.reducer';
import type { mapMigrationReducer } from '~/store/mapMigration/mapMigration.reducer';
import { type mapSettingsDataReducer } from '~/store/mapSettings/data/mapSettingsData.reducer';
import { type mapSettingsFileAttachmentsReducer } from '~/store/mapSettings/fileAttachments/fileAttachments.reducer';
import { type mapSettingsMapStylesReducer } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.reducer';

const stateSanitizer = (state: AppState): any => ({
  ...state,
  boundaries: boundariesSanitizer(state.boundaries),
  map: mapSanitizer(state.map),
  frontendState: frontendStateSanitizer(state.frontendState),
  mapMigration: mapMigrationSanitizer(state.mapMigration),
});

const boundariesSanitizer = (state: ReturnType<typeof boundariesReducer>) => ({
  ...state,
  items: boundaryItemsSanitizer(state.items),
  groups: boundaryGroupsSanitizer(state.groups),
});

const boundaryItemsSanitizer = (state: BoundaryItemsState) => {
  return {
    ...state,
    boundaries: '<<LARGE_DATA>>',
  };
};

const boundaryGroupsSanitizer = (state: BoundaryGroupsState) => {
  const sanitizedGroups = state.groups.map(
    group => ({ id: group.id, rest: '<<LARGE_DATA>>' }));

  return {
    ...state,
    groups: sanitizedGroups,
  };
};

const mapSanitizer = (state: ReturnType<typeof mapReducer>) => ({
  ...state,
  mapSettings: {
    ...state.mapSettings,
    data: mapSettingsSanitizer(state.mapSettings.data),
  },
});

const mapSettingsSanitizer = (state: ReturnType<typeof mapSettingsDataReducer>) => ({
  ...state,
  fileAttachments: mapSettingsFileAttachmentSanitizer(state.fileAttachments),
  mapStyles: mapSettingsMapStylesSanitizer(state.mapStyles),
});

const mapSettingsFileAttachmentSanitizer = (state: ReturnType<typeof mapSettingsFileAttachmentsReducer>) => ({
  ...state,
  map: Array.from(state.map.entries()).reduce((result, [k, v]) => {
    result[k] = v;
    return result;
  }, {} as Record<FileAttachmentId, FileAttachment>),
});

const mapSettingsMapStylesSanitizer = (state: ReturnType<typeof mapSettingsMapStylesReducer>) => ({
  ...state,
  styling: '<<LARGE_DATA>>',
});

const frontendStateSanitizer = (state: ReturnType<typeof frontendStateReducer>) => ({
  ...state,
  mapZIndexes: frontendStateMapZIndexesSanitizer(state.mapZIndexes),
  proximityDriveTimePaths: frontendStateProximityDriveTimePathsSanitizier(state.proximityDriveTimePaths),
  mapTools: frontendStateMapToolsSanitizer(state.mapTools),
});

const mapMigrationSanitizer = (state: ReturnType<typeof mapMigrationReducer>) => ({
  ...state,
  v4MapInfos: state.v4MapInfos.map(topLevelMapInfo => ({
    ...topLevelMapInfo,
    dependencies: topLevelMapInfo.dependencies.map(dependency => dependency.id),
  })),
});

const frontendStateMapZIndexesSanitizer = (state: ReturnType<typeof mapZIndexesReducer>) => (
  Object.fromEntries(Object.entries(state).map(([entity, entityState]) => [entity, {
    ...entityState,
    usedZIndexes: Object.fromEntries(entityState.usedZIndexes.entries()),
    freedZIndexes: Array.from(entityState.freedZIndexes),
  }]))
);

const frontendStateProximityDriveTimePathsSanitizier = (state: ReturnType<typeof proximityDriveTimePathsReducer>) => ({
  ...state,
  paths: '<<LARGE_DATA>>',
});

const frontendStateMapToolsSanitizer = (state: ReturnType<typeof mapToolsReducer>) => ({
  ...state,
  directions: frontedStateMapToolsDirectionsSanitizer(state.directions),
});

const frontedStateMapToolsDirectionsSanitizer = (state: ReturnType<typeof directionsReducer>) => ({
  ...state,
  routesUiData: '<<LARGE_DATA>>',
});

const reduxTypedStateSanitizer = stateSanitizer as <S>(state: S) => S;
export { reduxTypedStateSanitizer as stateSanitizer };
