import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { boundaryTerritoryGroupsUpdateRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { type ModalProps } from '../../modal/modalType.enum';
import { isBoundaryTerritoryGroupCustom } from '../../sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import {
  useBoundaryGroupsIsListingLoadingSelector,
  useBoundaryGroupsSelector,
} from '../../store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryDetailsIsLoadingSelector } from '../../store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { type BoundaryTerritoryGroup } from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  BoundarySettingsComponent, BoundarySettingsLayout,
} from './boundarySettings.component';
import {
  type BoundarySettingsPropsVariantsArguments,
  useBoundarySettingsPropsVariants,
} from './useBoundarySettingsPropsVariants';

export type BoundarySettingsContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
}>;

export const BoundarySettingsContainer: FC<BoundarySettingsContainerProps> = (props) => {
  const dispatch = useDispatch();
  const { onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const storeBoundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const isBoundaryTerritoryGroupLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const isBoundaryGroupsListingLoading = useBoundaryGroupsIsListingLoadingSelector();
  const isBoundaryTerritoryDetailsLoading = useBoundaryTerritoryDetailsIsLoadingSelector();
  const [boundaryTerritoryGroup, setBoundaryTerritoryGroup] = useState(() =>
    storeBoundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId)
  );
  const [valueIsInitial, setValueIsInitial] = useState(true);

  const boundaryGroup = boundaryGroups.find(group => group.id === boundaryTerritoryGroup?.boundaryGroupId);

  const updateBoundaryTerritoryGroup = useCallback<BoundarySettingsPropsVariantsArguments['onBoundaryTerritoryGroupUpdate']>(
    (updated: BoundaryTerritoryGroup, options?: { updateIsReset?: boolean }) => {
      setValueIsInitial(!!options?.updateIsReset);
      setBoundaryTerritoryGroup(updated);
    }, []
  );

  const boundaryTerritoryGroupProps = useBoundarySettingsPropsVariants({
    boundaryTerritoryGroup,
    onBoundaryTerritoryGroupUpdate: updateBoundaryTerritoryGroup,
    changeIsLoading: setIsLoading,
  });
  const boundaryTerritoryGroupPropsLayout = boundaryTerritoryGroupProps?.layout;
  const onBoundaryGroupNameChange = boundaryTerritoryGroupPropsLayout === BoundarySettingsLayout.Group ? boundaryTerritoryGroupProps?.onBoundaryGroupNameChange : null;

  const initialBoundaryGroupName = boundaryTerritoryGroupProps?.layout === BoundarySettingsLayout.Group ? boundaryTerritoryGroupProps?.boundaryGroupName : '';
  const [boundaryGroupName, setBoundaryGroupName] = useState<string>(initialBoundaryGroupName);

  const submit = useCallback(() => {
    if (!boundaryTerritoryGroup) {
      return;
    }

    if (boundaryTerritoryGroupPropsLayout === BoundarySettingsLayout.Group && initialBoundaryGroupName !== boundaryGroupName) {
      onBoundaryGroupNameChange?.(boundaryGroupName);
    }

    dispatch(boundaryTerritoryGroupsUpdateRequest(boundaryTerritoryGroup, { onDone: onClose }));
  }, [boundaryTerritoryGroup, boundaryTerritoryGroupPropsLayout, initialBoundaryGroupName, boundaryGroupName, dispatch, onClose, onBoundaryGroupNameChange]);

  // update the local boundary territory group instance if was changed in the store (might happen after concurrency error)
  useEffect(() => {
    const newBoundaryTerritoryGroup = storeBoundaryTerritoryGroups.find(
      group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId
    );
    if (boundaryTerritoryGroup && newBoundaryTerritoryGroup &&
      boundaryTerritoryGroup.etag?.timestamp !== newBoundaryTerritoryGroup.etag?.timestamp) {
      setBoundaryTerritoryGroup(newBoundaryTerritoryGroup);
    }
  }, [storeBoundaryTerritoryGroups, boundaryTerritoryGroup, props.boundaryTerritoryGroupId]);

  if (!boundaryTerritoryGroup || !boundaryTerritoryGroupProps || !boundaryGroup) {
    return null;
  }
  const restOfBoundarySettingsComponentProps = { ...boundaryTerritoryGroupProps, onBoundaryGroupNameChange: setBoundaryGroupName };

  return (
    <BoundarySettingsComponent
      boundaryGroup={boundaryGroup}
      boundaryTerritoryGroup={boundaryTerritoryGroup}
      valueIsInitial={valueIsInitial}
      isBoundaryTerritoryGroupCustom={isBoundaryTerritoryGroupCustom(boundaryTerritoryGroup, boundaryGroups)}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={submit}
      isLoading={isLoading || isBoundaryTerritoryGroupLoading || isBoundaryTerritoryDetailsLoading || isBoundaryGroupsListingLoading}
      onBoundaryTerritoryGroupChange={updateBoundaryTerritoryGroup}
      {...restOfBoundarySettingsComponentProps}
    />
  );
};
