import { type FC } from 'react';
import { useShowWelcomeModalOnFirstLogin } from '~/_shared/components/welcomeModal/useShowWelcomeModalOnFirstLogin.hook';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { HomepageComponent } from './homepage.component';

export const HomepageContainer: FC = () => {
  const isMobileScreen = useIsMobileScreenSelector();

  useShowWelcomeModalOnFirstLogin();

  return (
    <HomepageComponent
      isMobileScreen={isMobileScreen}
    />
  );
};
