import { type BoundingBox } from '../../map/map/boundingBox';
import { type BoundaryDeleteResult } from '../boundaries/boundaries.repository';
import {
  BOUNDARY_ITEMS_FETCH_CANCEL,
  BOUNDARY_ITEMS_FETCH_ERROR,
  BOUNDARY_ITEMS_FETCH_REQUEST,
  BOUNDARY_ITEMS_FETCH_SUCCESS,
  BOUNDARY_ITEMS_FETCHED_DATA,
  BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS,
  BOUNDARY_ITEMS_RESET,
  BOUNDARY_ITEMS_UPDATE_ITEM,
  BOUNDARY_ITEMS_UPDATE_ITEM_NAME_SUCCESS,
} from './boundaryItems.actionTypes';
import { type BoundaryStateItem } from './boundaryItems.state';

export const boundaryItemsFetchRequest = (
  mapId: number,
  clientId: number,
  lastBoundingBox: BoundingBox,
  mapZoomLevel: number,
) => ({
  type: BOUNDARY_ITEMS_FETCH_REQUEST,
  payload: {
    mapId,
    clientId,
    lastBoundingBox,
    mapZoomLevel,
  },
}) as const;

export const boundaryItemsFetchedData = (
  boundaryGroupId: number,
  items: BoundaryStateItem[],
  responseZoomLevel: number,
  continuationToken: string | undefined,
  done: boolean,
) => ({
  type: BOUNDARY_ITEMS_FETCHED_DATA,
  payload: {
    boundaryGroupId,
    items,
    responseZoomLevel,
    continuationToken,
    done,
  },
}) as const;

export const boundaryItemsFetchError = (error: Error) => ({
  type: BOUNDARY_ITEMS_FETCH_ERROR,
  payload: {
    error,
  },
}) as const;

export const boundaryItemsFetchCancel = () => ({
  type: BOUNDARY_ITEMS_FETCH_CANCEL,
}) as const;

export const boundaryItemsUpdateItemNameSuccess = (boundaryGroupId: number, itemId: number, newName: string) => ({
  type: BOUNDARY_ITEMS_UPDATE_ITEM_NAME_SUCCESS,
  payload: {
    newName,
    itemId,
    boundaryGroupId,
  },
}) as const;

export const boundaryItemRemoveSuccess = (boundaryId: number, deleteResult: BoundaryDeleteResult) => ({
  type: BOUNDARY_ITEMS_REMOVE_ITEM_SUCCESS,
  payload: {
    boundaryId,
    deleteResult,
  },
}) as const;

export const boundaryItemsFetchSuccess = (isRenderingExpected: boolean) => ({
  type: BOUNDARY_ITEMS_FETCH_SUCCESS,
  payload: {
    isRenderingExpected,
  },
}) as const;

export const boundaryItemsUpdateItem = (boundaryGroupId: number, boundaryStateItem: BoundaryStateItem) => ({
  type: BOUNDARY_ITEMS_UPDATE_ITEM,
  payload: {
    boundaryStateItems: [boundaryStateItem],
    boundaryGroupId,
  },
}) as const;

export const boundaryItemsUpdateItems = (boundaryGroupId: number, boundaryStateItems: BoundaryStateItem[]) => ({
  type: BOUNDARY_ITEMS_UPDATE_ITEM,
  payload: {
    boundaryStateItems,
    boundaryGroupId,
  },
}) as const;

export const boundaryItemsReset = () => ({
  type: BOUNDARY_ITEMS_RESET,
}) as const;
