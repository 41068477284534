import {
  css, type SerializedStyles,
} from '@emotion/react';
import { ColorPickerComponent } from '../../../../boundary/settings/colorPicker/colorPicker.component';
import { type ColorResult } from '../colorPicker.types';
import { ColorPickerWithWheelComponent } from '../colorPickerWithWheel/colorPickerWithWheel.component';

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const colorPickerStyle = ({ spaceBetween }: {spaceBetween?: number}) => css({
  marginRight: `${spaceBetween ?? 15}px`,
});

const colorWheelStyle = css({
  width: 22,
  height: 22,
});

type ColorPickerWithWheelAndTileProps = Readonly<{
  containerStyle?: SerializedStyles;
  isDisabled?: boolean;
  selectedColor: ColorResult;
  spaceBetween?: number;
  displayAlpha: boolean;

  onChange: (color: ColorResult) => void;
}>;

export const ColorPickerWithWheelAndTileComponent: React.FC<ColorPickerWithWheelAndTileProps> = props => (
  <div css={css(wrapperStyle, props.containerStyle)}>
    <ColorPickerComponent
      css={colorPickerStyle({ spaceBetween: props.spaceBetween })}
      onChange={props.onChange}
      selectedColor={props.selectedColor}
      editable={!props.isDisabled}
      displayAlpha={props.displayAlpha}
    />
    {!props.isDisabled && (
      <div css={colorWheelStyle}>
        <ColorPickerWithWheelComponent
          onChange={props.onChange}
          selectedColor={props.selectedColor}
          displayAlpha={props.displayAlpha}
        />
      </div>
    )}
  </div>
);
