import type { ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { validateValueHasOnlyNumericCharacters } from '~/_shared/utils/form/form.helpers';
import { limitValueToMaxPossibleLength } from '~/_shared/utils/number/number.helpers';
import {
  getDecimalPlaceFromValue, getDefaultResolution, getLowestRequiredResolution, getResolutionFromDecimalPlace,
  type NumericalRange, recalculateRanges,
  roundValueToResolution,
} from '~/_shared/utils/range/range.helpers';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { calculateGroupColor } from '~/boundary/settings/boundarySettings.helpers';
import { type NumericalGroupSettingsDataRow } from './numericalGroupSettingsNumeric.component';

export const getGroupingMaxRange = (min: number, max: number): number => {
  const diff = max - min;

  if (diff < 2) {
    return 2;
  }
  else if (diff < 10) {
    return diff;
  }
  else {
    return 10;
  }
};

export const getDefaultBucketSettingsDataRows = (
  min: number,
  max: number,
  numberOfRanges: number,
  isDecimalMode: boolean,
  lowValueColor?: ColorResult,
  mediumValueColor?: ColorResult,
  highValueColor?: ColorResult,
  opacity?: number,
): NumericalGroupSettingsDataRow[] => {
  const lowColor = lowValueColor;
  const mediumColor = mediumValueColor;
  const highColor = highValueColor;
  const results: NumericalGroupSettingsDataRow[] = [];
  const resolution = isDecimalMode ? getDefaultResolution(min, max) : 1;

  const distance = roundValueToResolution(
    (max - min - (numberOfRanges) * resolution) / (numberOfRanges),
    resolution
  );

  for (let i = 0; i < numberOfRanges; i++) {
    const prevResult = results[i - 1];

    const fromValue: number = !prevResult ? min : roundValueToResolution(+prevResult.toValue, resolution);
    const toValue: number = i === numberOfRanges - 1 ? max : roundValueToResolution(fromValue + distance + resolution, resolution);

    const color = lowColor && mediumColor && highColor
      ? calculateGroupColor(i, numberOfRanges, lowColor, mediumColor, highColor, opacity)
      : null;

    results.push({
      color,
      fromValue: fromValue.toString(),
      toValue: toValue.toString(),
      isValid: true,
    });
  }

  return results;
};

export const handleNumericGroupValueToChange = (
  index: number,
  valueTo: string,
  dataRows: NumericalGroupSettingsDataRow[],
  min: number,
  max: number,
  isDecimal: boolean
): NumericalGroupSettingsDataRow[] => {
  if (!validateValueHasOnlyNumericCharacters(valueTo, { isDecimal })) {
    return dataRows;
  }

  const parsedValueTo: '' | number = isTextEmpty(valueTo) ? '' : +valueTo;

  if (parsedValueTo === '' || isNaN(parsedValueTo) || valueTo.endsWith('.')) {
    return dataRows.map((dataRow, i) => {
      if (i !== index) {
        return dataRow;
      }

      return {
        ...dataRow,
        toValue: valueTo,
        isValid: false,
      };
    });
  }

  valueTo = limitValueToMaxPossibleLength(valueTo);

  let resolution = isDecimal ? getDefaultResolution(min, max) : 1;

  if (isDecimal) {
    const currentResolutionDecimalPlace = getDecimalPlaceFromValue(resolution);
    const valueToDecimalPlace = getDecimalPlaceFromValue(parsedValueTo);
    const lowestRequiredResolution = getLowestRequiredResolution(parsedValueTo, max, index, dataRows.length);

    if (valueToDecimalPlace > currentResolutionDecimalPlace) {
      resolution = getResolutionFromDecimalPlace(valueToDecimalPlace);
    }
    else {
      // check for all the previous values if the resolution shouldn't be smaller
      // (checking only previous values as the next ones will be recalculated)
      let biggestDecimalPlaceOfPreviousValues = valueToDecimalPlace;
      for (const item of dataRows.slice(0, index)) {
        const valueToDecimalPlace = getDecimalPlaceFromValue(+item.toValue);
        if (valueToDecimalPlace > biggestDecimalPlaceOfPreviousValues) {
          biggestDecimalPlaceOfPreviousValues = valueToDecimalPlace;
        }
      }

      resolution = getResolutionFromDecimalPlace(biggestDecimalPlaceOfPreviousValues);
    }

    if (lowestRequiredResolution !== null && lowestRequiredResolution < resolution) {
      resolution = lowestRequiredResolution;
    }
  }

  return recalculateDataRowsForNewValueTo(dataRows, index, valueTo, min, max, resolution);
};

export const recalculateDataRowsValues = (
  dataRows: NumericalGroupSettingsDataRow[],
  min: number,
  max: number,
  isDecimalMode: boolean
): NumericalGroupSettingsDataRow[] => {
  const newDataRows = [...dataRows];

  const ranges: NumericalRange[] = newDataRows.map(row => ({
    from: +row.fromValue,
    to: +row.toValue,
  }));

  const newRanges = recalculateRanges(ranges, min, max, isDecimalMode);

  // apply new ranges to dataRows
  return newDataRows.map((dataRow, index) => {
    const range = newRanges[index];
    if (!range) {
      return dataRow;
    }

    return {
      ...dataRow,
      fromValue: range.from.toString(),
      toValue: range.to.toString(),
      isValid: true,
    };
  });
};

export const recalculateDataRowsForNewValueTo = (
  dataRows: NumericalGroupSettingsDataRow[],
  index: number,
  newToValue: string,
  rangeMin: number,
  rangeMax: number,
  resolution: number
): NumericalGroupSettingsDataRow[] => {
  const dataRow = dataRows[index];

  if (!dataRow) {
    return dataRows;
  }

  const isValueValid = validateNumericGroupValue(rangeMin, rangeMax, index, dataRows.length, +dataRow.fromValue, +newToValue, resolution);

  const newDataRow: NumericalGroupSettingsDataRow = {
    ...dataRow,
    toValue: newToValue,
    isValid: isValueValid,
  };

  const newDataRows = [...dataRows];
  newDataRows[index] = newDataRow;

  const distance = roundValueToResolution(
    (rangeMax - +newToValue - (dataRows.length - 1 - index) * resolution) / (dataRows.length - 1 - index),
    resolution
  );

  if (isValueValid) {
    for (let i = 1; i <= index; i++) {
      const prevDataRow = newDataRows[i - 1];
      const currentDataRow = newDataRows[i];

      if (!prevDataRow || !currentDataRow) {
        continue;
      }
      const fromValue = roundValueToResolution(+prevDataRow.toValue, resolution);

      newDataRows[i] = {
        ...currentDataRow,
        fromValue: fromValue.toString(),
      };
    }

    for (let i = index + 1; i < dataRows.length; i++) {
      const prevDataRow = newDataRows[i - 1];
      const currentDataRow = newDataRows[i];

      if (!prevDataRow || !currentDataRow) {
        continue;
      }
      const fromValue = roundValueToResolution(+prevDataRow.toValue, resolution);

      newDataRows[i] = {
        ...currentDataRow,
        isValid: true,
        fromValue: fromValue.toString(),
        toValue: i === dataRows.length - 1 ?
          rangeMax.toString() : roundValueToResolution(fromValue + distance + resolution, resolution).toString(),
      };
    }
  }

  return newDataRows;
};

const validateNumericGroupValue = (
  minValue: number,
  max: number,
  itemIndex: number,
  itemsCount: number,
  valueFrom: number,
  valueTo: number,
  resolution: number
): boolean => {
  const maxValue = max - (itemsCount - itemIndex - 1) * resolution;

  return valueTo >= minValue && valueTo <= maxValue && valueTo > valueFrom;
};
