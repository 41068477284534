import {
  type BaseMapElementTypeName,
  type BaseMapFeatureTypeName,
  type MapStylesSliderSettingsName,
} from '../../../map/settings/accordion/baseMap/baseMap.enums';
import { type BaseMapThemeName } from '../../../map/settings/accordion/baseMap/baseMapThemes/baseMapThemes.enums';
import {
  MAP_SETTINGS_MAP_STYLES_CHANGE, MAP_SETTINGS_MAP_STYLES_SET_SATELLITE,
  MAP_SETTINGS_MAP_STYLES_STANDARD_COLORS_CHANGE,
  MAP_SETTINGS_MAP_STYLES_STANDARD_SLIDERS_CHANGE,
  MAP_SETTINGS_MAP_STYLES_THEME_CHANGE,
} from './mapSettingsMapStyles.actionTypes';
import { type MapSettingsStylesChangeData } from './mapSettingsMapStyles.state';

export const mapSettingsMapStylesChangeStylers = (stylesChangeData: MapSettingsStylesChangeData) => {
  return {
    type: MAP_SETTINGS_MAP_STYLES_CHANGE,
    payload: {
      stylesChangeData,
    },
  } as const;
};

export const mapSettingsMapStylesChangeStandardColors = (
  featureNames: ReadonlyArray<BaseMapFeatureTypeName>,
  elementName: BaseMapElementTypeName,
  color: string
) => {
  return {
    type: MAP_SETTINGS_MAP_STYLES_STANDARD_COLORS_CHANGE,
    payload: {
      featureNames,
      elementName,
      color,
    },
  } as const;
};

export const mapSettingsMapStylesChangeStandardSliders = (sliderName: MapStylesSliderSettingsName, value: number) => {
  return {
    type: MAP_SETTINGS_MAP_STYLES_STANDARD_SLIDERS_CHANGE,
    payload: {
      sliderName,
      value,
    },
  } as const;
};

export const mapSettingsMapStylesChangeTheme = (themeId: BaseMapThemeName) => {
  return {
    type: MAP_SETTINGS_MAP_STYLES_THEME_CHANGE,
    payload: {
      themeId,
    },
  } as const;
};

export const mapSettingsMapStylesSetSatellite = (isSatelliteEnabled: boolean) => ({
  type: MAP_SETTINGS_MAP_STYLES_SET_SATELLITE,
  payload: {
    isSatelliteEnabled,
  },
}) as const;
