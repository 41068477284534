import { HOUR_IN_MS } from '~/_shared/constants/time.constants';
import { UserLoginErrorSpecialCode } from '~/store/userData/repository/userLogin.types';

const USER_WAS_DISABLED = 'USER_WAS_DISABLED';

export const setUserWasDisabled = (): void => {
  const currentTime = Date.now().toString();
  localStorage.setItem(USER_WAS_DISABLED, currentTime);
};

export const getUserWasDisabled = (): string | null => {
  return localStorage.getItem(USER_WAS_DISABLED);
};

export const removeUserWasDisabled = (): void => {
  localStorage.removeItem(USER_WAS_DISABLED);
};

export const getTeamMemberError = () => {
  const timeToken = getUserWasDisabled();

  const timeStored = Number(timeToken);
  // Check if an hour has passed
  if (Date.now() - timeStored > HOUR_IN_MS) {
    removeUserWasDisabled();
    return undefined;
  }
  else {
    return UserLoginErrorSpecialCode.USER_WAS_DISABLED;
  }
};
