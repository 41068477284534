import {
  type MutableRefObject,
  type Ref,
  type RefCallback,
  useCallback,
} from 'react';

/**
 * Combines many refs into one. Useful for combining many ref hooks
 */
export const useCombinedRefs = <T extends HTMLElement>(...refs: Array<Ref<T | null> | undefined>): RefCallback<T> =>
  useCallback(
    (element: T) =>
      refs.forEach(ref => {
        if (!ref) {
          return;
        }

        // Ref can have two types - a function or an object. We treat each case.
        if (typeof ref === 'function') {
          return ref(element);
        }

        // As per https://github.com/facebook/react/issues/13029
        // it should be fine to set current this way.
        (ref as MutableRefObject<T>).current = element;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
