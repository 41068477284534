import { css } from '@emotion/react';
import {
  faArrowLeft, faArrowRight, faEllipsis,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

type PaginationProps = {
  className?: string;
  currentPage: number;
  pageCount: number;
  onPageSelect: (pageIndex: number) => void;
};

const wrapperStyle = css({
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'center',
  paddingInlineStart: 0,
});

const buttonStyle = ({ theme, isActive }: ThemeProps<{ isActive: boolean }>) => css({
  borderRadius: 0,
  padding: '0 8px',
  height: 32,
  color: isActive ? theme.textColors.quaternary : theme.textColors.link,
  background: isActive ? theme.backgroundColors.quaternary : 'none',
  border: 'none',
  '&:hover': {
    background: isActive ? undefined : theme.backgroundColors.highlight,
  },
  '&:disabled': {
    color: isActive ? undefined : theme.textColors.secondary,
    background: isActive ? undefined : 'none',
  },
});

const listItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 4px',
});

const arrowStyle = css({
  background: 'none',
});

export const PaginationComponent: FC<PaginationProps> = (props) => {
  const theme = useTheme();

  if (props.pageCount === 1) {
    return null;
  }

  const paginationElements = [];
  const innerSegmentCount = 3;
  const min = 1;
  const max = props.pageCount;
  const innerSegmentStart = Math.max(min + 1, Math.min(props.pageCount - (innerSegmentCount), props.currentPage - 1));
  const innerSegmentEnd = Math.min(max - 1, innerSegmentStart + 2);

  const displayPages = [1];

  for (let i = innerSegmentStart; i <= innerSegmentEnd; i++) {
    displayPages.push(i);
  }

  displayPages.push(props.pageCount);

  for (let i = 0; i < displayPages.length; i++) {
    const page = displayPages[i];
    if (!page) {
      return;
    }

    const prevPage = i > 0 ? displayPages[i - 1] : null;
    const nextPage = displayPages[i + 1] ?? null;
    const isActive = page === props.currentPage;

    if (i === displayPages.length - 1 && prevPage !== null && prevPage !== page - 1) {
      paginationElements.push(
        <li
          key="right-ellipsis"
          css={listItemStyle}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </li>
      );
    }

    paginationElements.push(
      <li
        key={page}
        css={listItemStyle}
      >
        <button
          css={buttonStyle({ theme, isActive })}
          disabled={isActive}
          onClick={() => {
            props.onPageSelect(page);
          }}
        >
          {(page < 10 ? '0' : '') + page}
        </button>
      </li>
    );

    if (i === 0 && displayPages && nextPage !== null && nextPage !== page + 1) {
      paginationElements.push(
        <li
          key="left-ellipsis"
          css={listItemStyle}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </li>
      );
    }
  }

  const isNextButtonDisabled = props.currentPage >= props.pageCount;
  const isPrevButtonDisabled = props.currentPage <= 1;

  return (
    <ul
      className={props.className}
      css={wrapperStyle}
    >
      <li css={listItemStyle}>
        <button
          css={[buttonStyle({ theme, isActive: false }), arrowStyle]}
          disabled={isPrevButtonDisabled}
          onClick={() => {
            props.onPageSelect(props.currentPage - 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </li>

      {paginationElements}

      <li css={listItemStyle}>
        <button
          css={[buttonStyle({ theme, isActive: false }), arrowStyle]}
          disabled={isNextButtonDisabled}
          onClick={() => {
            props.onPageSelect(props.currentPage + 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </li>
    </ul>
  );
};
