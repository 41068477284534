import {
  type FC, useCallback, useState,
} from 'react';
import { TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import { getTeamMemberError } from '~/clientTeamManagement/clientTeamManagement.helpers';
import { sendTwoFactorEmailRequest } from '~/store/userData/repository/userData.repository';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { useAuthContext } from '../../authContext';
import { Login2FaFormComponent } from './login2FaForm.component';
import { LoginFormComponent } from './loginForm.component';
import { useUserSign } from './useUserSign';

type LoginFormContainerProps = {
  onLoginFormSubmit?: () => void;
};

export const LoginFormContainer: FC<LoginFormContainerProps> = (props) => {
  const { isLoading, error, mfaMode, authenticateUserRequest, authenticate2FaUserRequest,
    resetTwoFactorData } = useUserSign();
  const { setAuthTokens, error: refreshTokenError, clearError } = useAuthContext();
  const teamMemberError = getTeamMemberError();
  const [usedEmail, setUsedEmail] = useState('');
  const [usedPassword, setUsedPassword] = useState('');
  const isLoadingFromState = useUserDataSelector().isLoading;
  const isAnythingLoading = isLoading || isLoadingFromState;

  const dispatchProps = {
    onFormSubmit: async (email: string, password: string, rememberMe: boolean) => {
      clearError();
      setUsedEmail(email);
      setUsedPassword(password);
      const response = await authenticateUserRequest({ email, password, rememberMe });
      if (response?.refresh && response?.access_token_expiration) {
        setAuthTokens(response.refresh, response.access_token_expiration);
      }
      props.onLoginFormSubmit?.();
    },
    on2FASubmit: async (code: string) => {
      clearError();
      const response = await authenticate2FaUserRequest(code);
      if (response?.refresh && response?.access_token_expiration) {
        setAuthTokens(response.refresh, response.access_token_expiration);
      }
    },
    reset2FaMode: () => {
      resetTwoFactorData();
    },
  };

  const resendMFAEmail = useCallback(() =>
    sendTwoFactorEmailRequest(usedEmail, usedPassword),
  [usedEmail, usedPassword]);

  return (
    <div>
      {!mfaMode ? (
        <LoginFormComponent
          error={error || refreshTokenError || teamMemberError || ''}
          isLoading={isAnythingLoading}
          onSubmit={dispatchProps.onFormSubmit}
        />
      ) : (
        <Login2FaFormComponent
          error={error || refreshTokenError || ''}
          isLoading={isAnythingLoading}
          mfaMode={mfaMode}
          onSubmit={dispatchProps.on2FASubmit}
          repeatMFA={mfaMode === TwoFactorMode.google ? undefined : resendMFAEmail}
          reset2FaMode={dispatchProps.reset2FaMode}
        />
      )}
    </div>
  );
};
