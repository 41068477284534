import {
  useCallback, useState,
} from 'react';

export const useLoadMoreListing = (initialLimit: number, loadMoreStep?: number) => {
  const step = loadMoreStep ?? initialLimit;
  const [limit, setLimit] = useState(initialLimit);

  const increaseLimit = useCallback(() => setLimit(oldLimit => oldLimit + step), [step]);

  return { limit, increaseLimit };
};
