import {
  call, put, takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { requestBoundaryItems } from '~/store/boundaryItems/boundaryItems.sagas';
import {
  convertBoundariesStart, convertBoundariesStop,
} from '~/store/frontendState/mapTools/boundary/convertBoundaries/convertBoundaries.actionCreators';
import { type MapIdState } from '~/store/mapId/mapId.state';
import { isBoundaryGroupHidden } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { boundaryTerritoryGroupsFetchRequest } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { createBoundaryTerritoryConvertRequestFromBoundaryTerritoryGroupModel } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { type MapIdAction } from '../mapId/mapId.action';
import { MAP_ID_SET } from '../mapId/mapId.actionTypes';
import {
  boundaryGroupsChangeNameError,
  boundaryGroupsChangeNameSuccess,
  boundaryGroupsCreateError, boundaryGroupsCreateSuccess, boundaryGroupsDeleteError, boundaryGroupsDeleteSuccess,
  boundaryGroupsError, boundaryGroupsRequest,
  boundaryGroupsSuccess,
  convertBoundaryTerritoriesToBoundariesError,
} from './boundaryGroups.actionCreators';
import { type BoundaryGroupsActions } from './boundaryGroups.actions';
import {
  BOUNDARY_GROUP_CHANGE_NAME,
  BOUNDARY_GROUP_CREATE_REQUEST,
  BOUNDARY_GROUP_FETCH_ALL_REQUEST,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST,
  BOUNDARY_GROUPS_DELETE_REQUEST,
  BOUNDARY_GROUPS_FILTER_CHANGED,
} from './boundaryGroups.actionTypes';
import { createBoundaryGroupFromResponse } from './boundaryGroups.factory';
import {
  type BoundaryGroupResponse, type BoundaryGroupsQueryResponse,
  convertTerritoriesToBoundaries,
  createBoundaryGroup,
  deleteBoundaryGroup,
  editBoundaryGroup,
  getBoundaryGroupsQuery,
} from './boundaryGroups.repository';
import {
  boundaryGroupsSelector, findEditBoundaryGroup,
} from './boundaryGroups.selectors';

export function* boundaryGroupsSagas() {
  yield takeLatest(MAP_ID_SET, onMapIdChange);
  yield takeLatest(BOUNDARY_GROUP_FETCH_ALL_REQUEST, fetchBoundaryGroups);
  yield takeEvery(BOUNDARY_GROUP_CREATE_REQUEST, createNewBoundaryGroup);
  yield takeEvery(BOUNDARY_GROUP_CHANGE_NAME, updateBoundaryGroupName);
  yield takeEvery(BOUNDARY_GROUPS_DELETE_REQUEST, deleteBoundaryGroups);
  yield takeEvery(BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST, convertBoundaryTerritoriesToBoundaries);
  yield takeEvery(BOUNDARY_GROUPS_FILTER_CHANGED, onBoundaryFilterChanged);
}

function* onMapIdChange(action: PickAction<MapIdAction, typeof MAP_ID_SET>) {
  if (action.payload.mapId === null) {
    return;
  }

  yield put(boundaryGroupsRequest());
}

function* fetchBoundaryGroups(_action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUP_FETCH_ALL_REQUEST>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const mapId: number | null = yield select<number | null>(state => state.map.mapId);

  if (!clientId || !mapId) {
    return;
  }

  try {
    const response: BoundaryGroupsQueryResponse = yield call(getBoundaryGroupsQuery, clientId, mapId);

    const boundaryGroups = response.boundary_groups
      .map(item => createBoundaryGroupFromResponse(item));

    yield put(boundaryGroupsSuccess(boundaryGroups));
  }
  catch (e) {
    console.error(e);
    yield put(boundaryGroupsError(e));
  }
}

function* createNewBoundaryGroup(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUP_CREATE_REQUEST>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

  if (!clientId) {
    return;
  }

  try {
    const response: BoundaryGroupResponse = yield call(createBoundaryGroup, clientId, action.payload.groupName);

    const newBoundaryGroup = createBoundaryGroupFromResponse(response);
    yield put(boundaryGroupsCreateSuccess(newBoundaryGroup, action.payload.onBoundaryTerritoryGroupCreateSuccess));
  }
  catch (e) {
    console.error(e);
    yield put(boundaryGroupsCreateError(e));
  }
}

function* updateBoundaryGroupName(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUP_CHANGE_NAME>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

  if (!clientId) {
    return;
  }

  try {
    const { groupId, newName } = action.payload;
    yield call(editBoundaryGroup, clientId, groupId, newName);
    yield put(boundaryGroupsChangeNameSuccess(groupId, newName));
  }
  catch (e) {
    console.error(e);
    yield put(boundaryGroupsChangeNameError());
  }
}

function* deleteBoundaryGroups(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUPS_DELETE_REQUEST>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);

  if (!clientId) {
    return;
  }

  try {
    const { groupIds } = action.payload;
    for (const groupId of groupIds) {
      yield call(deleteBoundaryGroup, clientId, groupId);
    }
    yield put(boundaryGroupsDeleteSuccess(groupIds));
  }
  catch (e) {
    console.error(e);
    yield put(boundaryGroupsDeleteError());
  }
}

function* convertBoundaryTerritoriesToBoundaries(action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST>) {
  const clientId: number | null = yield select<number | null>(state => state.userData.clientId);
  const mapId: MapIdState = yield select<MapIdState>(state => state.map.mapId);
  const boundaryGroup: BoundaryGroup | undefined = yield select<BoundaryGroup | undefined>(state =>
    findEditBoundaryGroup(boundaryGroupsSelector(state), action.payload.boundaryTerritoryGroup.boundaryGroupId));

  if (!clientId || !mapId || !boundaryGroup) {
    return;
  }

  try {
    yield put(convertBoundariesStart());

    const boundaryTerritoryGroupData = createBoundaryTerritoryConvertRequestFromBoundaryTerritoryGroupModel(
      action.payload.boundaryTerritoryGroup
    );

    yield call(
      convertTerritoriesToBoundaries,
      clientId,
      {
        boundary_territory_group_data: boundaryTerritoryGroupData,
        settings: { display_name: action.payload.name },
        archive: action.payload.archiveBoundaryTerritoryGroup,
        zoom: Math.max(...boundaryGroup.zoomLevels),
      },
    );

    yield put(boundaryGroupsRequest());
    yield put(boundaryTerritoryGroupsFetchRequest(mapId));
    yield put(convertBoundariesStop());
  }
  catch (e) {
    console.error(e);
    yield put(convertBoundaryTerritoriesToBoundariesError());
  }
}

function* onBoundaryFilterChanged(
  action: PickAction<BoundaryGroupsActions, typeof BOUNDARY_GROUPS_FILTER_CHANGED>
) {
  if (isBoundaryGroupHidden(action.payload.previousFilter) && !isBoundaryGroupHidden(action.payload.currentFilter)) {
    yield requestBoundaryItems();
  }
}
