import { css } from '@emotion/react';
import {
  faCog,
  faDrawPolygon, faEdit, faEllipsisV, faExternalLink, faSearchLocation, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  ContextMenuComponent, ContextMenuDefaultItem, ContextMenuItemCoreComponent,
} from '~/_shared/baseComponents/contextMenu';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '../../../../../../_shared/themes/theme.hooks';
import { useTranslation } from '../../../../../../_shared/utils/hooks';
import { useWmsFeatureBlocker } from './useWmsFeatureBlocker';

type BoundaryItemContextMenuProps = Readonly<{
  boundaryGroupId: number;
  onBatchEditLocationsClick?: () => void;
  onDeleteTerritoryClick?: () => void;
  onExportDataInTerritoryClick?: () => void;
  onExportContainedBoundaries?: () => void;
  onManuallyEditPolygonClick?: () => void;
  onTerritorySettingsClick?: () => void;
  onZoomToBoundaryClick: () => void;
}>;

const toolbarButtonStyle = css({
  marginLeft: 6,
});

const triggerButtonStyle = ({ isMenuOpen, boundaryIsUnderWmsTrial }: { isMenuOpen: boolean; boundaryIsUnderWmsTrial: boolean }) =>
  boundaryIsUnderWmsTrial ? RoundButtonStyle.Restricted : isMenuOpen ? RoundButtonStyle.Quaternary : RoundButtonStyle.Primary;

export const BoundaryItemContextMenuComponent: FC<BoundaryItemContextMenuProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [t] = useTranslation();
  const theme = useTheme();
  const { boundaryIsUnderWmsTrial, blockWhenWmsTrial } = useWmsFeatureBlocker(props.boundaryGroupId);

  const iconStyle = boundaryIsUnderWmsTrial ? css({ color: theme.textColors.warning }) : undefined;
  return (
    <ContextMenuComponent
      isVisible={isMenuOpen}
      onHide={() => setIsMenuOpen(false)}
      maxWidth={280}
      renderMenuContent={() => (
        <>
          {props.onTerritorySettingsClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faCog}
                onClick={props.onTerritorySettingsClick}
                size="s"
              >
                {t('Territory Settings')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onManuallyEditPolygonClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                tooltipPlacement={TooltipPlacement.Right}
                icon={faDrawPolygon}
                onClick={blockWhenWmsTrial(props.onManuallyEditPolygonClick)}
                size="s"
                iconStyle={iconStyle}
              >
                {t('Edit Territory')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          <ContextMenuItemCoreComponent>
            <ContextMenuDefaultItem
              icon={faSearchLocation}
              onClick={props.onZoomToBoundaryClick}
              size="s"
            >
              {t('Zoom to Territory')}
            </ContextMenuDefaultItem>
          </ContextMenuItemCoreComponent>

          {props.onExportDataInTerritoryClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faExternalLink}
                onClick={blockWhenWmsTrial(props.onExportDataInTerritoryClick)}
                size="s"
                iconStyle={iconStyle}
              >
                {t('Export Data in Territory')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onExportContainedBoundaries && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faExternalLink}
                onClick={blockWhenWmsTrial(props.onExportContainedBoundaries)}
                size="s"
                iconStyle={iconStyle}
              >
                {t('Export Contained Boundaries')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onBatchEditLocationsClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faEdit}
                onClick={blockWhenWmsTrial(props.onBatchEditLocationsClick)}
                size="s"
                iconStyle={iconStyle}
              >
                {t('Batch Edit Locations')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}

          {props.onDeleteTerritoryClick && (
            <ContextMenuItemCoreComponent>
              <ContextMenuDefaultItem
                icon={faTrash}
                onClick={props.onDeleteTerritoryClick}
                size="s"
                isDestructive
              >
                {t('Delete Territory')}
              </ContextMenuDefaultItem>
            </ContextMenuItemCoreComponent>
          )}
        </>
      )}
    >

      <div css={toolbarButtonStyle}>
        <RoundButtonComponent
          icon={faEllipsisV}
          onClick={() => setIsMenuOpen(true)}
          size={RoundButtonSize.Small}
          buttonStyle={triggerButtonStyle({ isMenuOpen, boundaryIsUnderWmsTrial })}
        />
      </div>
    </ContextMenuComponent>
  );
};
