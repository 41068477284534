import {
  useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '../_shared/types/toolsAndFeatures/mapTools.types';
import { usePrevious } from '../_shared/utils/hooks/usePrevious';
import { useSelector } from '../_shared/utils/hooks/useSelector';
import { type SidebarApp } from '../sidebar/sidebarApp.enum';
import { useGetRightSidebarContent } from '../sidebar/sidebarApps/rightSidebar/helpers/useGetRightSidebarContent';
import { activeMapElementsClearState } from '../store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useIsMobileScreenSelector } from '../store/frontendState/deviceInfo/deviceInfo.selector';
import { closeLeftSidebar } from '../store/frontendState/leftSidebar/leftSidebar.actionCreators';

const isLeftMenuOpen = (selectedApp: SidebarApp | null, selectedTool: MapTool | null) => !!(
  selectedApp && (selectedTool === MapTool.Grouping || selectedTool === MapTool.Filter
  || selectedTool === MapTool.Proximity || selectedTool === MapTool.Routing)
);

export const useMobileMenuManager = () => {
  const dispatch = useDispatch();
  const isMobileScreen = useIsMobileScreenSelector();
  const selectedTool = useSelector(state => state.frontendState.leftSidebar.openedMapTool);
  const selectedApp = useSelector(state => state.frontendState.leftSidebar.openedApp);
  const rightSidebarContents = useGetRightSidebarContent();
  const prevRightSidebarContents = usePrevious(rightSidebarContents);
  const isLeftOpen = useMemo(() => isLeftMenuOpen(selectedApp, selectedTool), [selectedApp, selectedTool]);
  const prevIsLeftOpen = usePrevious(isLeftMenuOpen(selectedApp, selectedTool));

  useEffect(() => {
    if (!isMobileScreen) {
      return;
    }

    if (prevRightSidebarContents !== rightSidebarContents && rightSidebarContents) {
      if (isLeftOpen) {
        // close left sidebar if something in right sidebar was activated
        // so the right sidebar (on the bottom in mobile version) and left tools menu are not active at the same time
        dispatch(closeLeftSidebar());
      }
    }
    else {
      if (prevIsLeftOpen !== isLeftOpen && isLeftOpen) {
        // close right sidebar if something in left sidebar was activated
        if (rightSidebarContents) {
          dispatch(activeMapElementsClearState());
        }
      }
    }

  }, [dispatch, isMobileScreen, rightSidebarContents, isLeftOpen, prevRightSidebarContents, prevIsLeftOpen]);

};
