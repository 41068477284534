import { css } from '@emotion/react';
import {
  faCaretUp, faCheck, faPencil, faTrash, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useState,
} from 'react';
import { ACCORDION_HEADER_SIZE_MEDIUM } from '~/_shared/baseComponents/accordion';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  RoundButtonComponent,
  RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { TextInputComponent } from '~/_shared/baseComponents/inputs';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { HeightTransitionComponent } from '~/_shared/components/heightTransition/heightTransition.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { BoundaryListingContainer } from '../../boundaryListing/boundaryListing.container';

const itemStyle = ({ theme }: ThemeProps) => css({
  borderBottom: `1px solid ${theme.lineColors.basePrimary}`,
  marginBottom: -1,
});

const headerStyle = ({ theme, isActive, borderTopHidden }: ThemeProps<{isActive: boolean; borderTopHidden: boolean}>) => css({
  alignItems: 'center',
  background: isActive ? theme.accordionColors.headingBackgroundActive : theme.accordionColors.headingBackground,
  border: borderTopHidden && !isActive ? 'none' : `solid ${isActive ? theme.accordionColors.headingBorderActive : theme.accordionColors.borderColor}`,
  boxSizing: 'border-box',
  borderWidth: isActive ? '1px 0' : '1px 0 0',
  display: 'flex',
  height: ACCORDION_HEADER_SIZE_MEDIUM,
  justifyContent: 'space-between',
  marginTop: isActive && !borderTopHidden ? 1 : 0, //to negate the -1px of itemStyle so borderTop is shown
  padding: '4px 8px 4px 14px',

  '&:hover': {
    background: isActive ? theme.accordionColors.headingBackgroundActiveHover : theme.accordionColors.headingBackgroundHover,
  },
});

const headingStyle = css({
  flex: 1,
  marginRight: 8,
  overflow: 'hidden',
});

const headingPrimaryStyle = css({
  fontWeight: 400,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const headingSecondaryStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  fontSize: '12px',
  margin: 0,
});

const buttonStyle = css({
  border: 'none',
  '&:hover': {
    border: 'none',
  },
  marginLeft: 6,
});

const expandButtonStyle = ({ theme, isExpanded }: ThemeProps<{ isExpanded: boolean }>) => css({
  color: theme.textColors.primary,
  transform: `rotate(${isExpanded ? 0 : '180deg'})`,
  transition: 'transform .25s',
  fontSize: '14px',
  boxShadow: 'none',
  '&, &:hover': {
    background: 'none',
  },
});

const editNameInputStyle = css({
  fontSize: '14px',
  height: 30,
});

const mapsCountButtonStyle = css({
  border: 'none',
  fontSize: '12px',
  opacity: 1,
  padding: '6px 4px',
});

type BoundariesListingItemProps = Readonly<{
  boundaryGroupId: number;
  className?: string;
  index: number;
  isLoading: boolean;
  isRemoveDisabled: boolean;
  mapsCount: number;
  name: string;
  ownerName: string;

  onEditName: (newName: string) => void;
  onExpandClick: () => void;
  onRemove: () => void;
}>;

export const BoundaryGroupsListingItemComponent: FC<BoundariesListingItemProps> = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [editingName, setEditingName] = useState(props.name);
  const [t] = useTranslation();
  const theme = useTheme();

  const startEditingItem = () => {
    setEditingName(props.name);
    setIsEditing(true);
  };

  const save = () => {
    if (isTextEmpty(editingName)) {
      return;
    }

    props.onEditName(editingName);
    setIsEditing(false);
  };

  const discard = () => {
    setEditingName(props.name);
    setIsEditing(false);
  };

  const toggleShowItems = () => {
    setShowItems(showItems => !showItems);
  };

  const isSubmitDisabled = props.isLoading || isTextEmpty(editingName) || editingName === props.name;

  return (
    <div css={itemStyle({ theme })}>
      <div
        className={props.className}
        css={headerStyle({ theme, isActive: showItems, borderTopHidden: props.index === 0 })}
      >
        <div css={headingStyle}>
          {!isEditing ? (
            <div>
              <strong css={headingPrimaryStyle}>
                {props.name}
              </strong>

              <p css={headingSecondaryStyle({ theme })}>{t('territory group')}</p>
            </div>
          ) : (
            <TextInputComponent
              css={editNameInputStyle}
              onChange={setEditingName}
              value={editingName}
            />
          )}
        </div>

        <div>
          <TooltipDeprComponent
            tooltipContent={t('Territory group used on {{count}} map', { count: props.mapsCount })}
          >
            <ButtonComponent
              buttonStyle={ButtonStyle.Custom} // change to Primary style once the onClick is implemented
              css={mapsCountButtonStyle}
              text={t('{{count}} Map', { count: props.mapsCount })}
              isDisabled
            />
          </TooltipDeprComponent>

          {isEditing && (
            <>
              <RoundButtonComponent
                css={[buttonStyle]}
                buttonStyle={isSubmitDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Confirm}
                size={RoundButtonSize.Small}
                icon={faCheck}
                isDisabled={isSubmitDisabled}
                onClick={save}
              />

              <RoundButtonComponent
                css={[buttonStyle]}
                buttonStyle={RoundButtonStyle.Danger}
                size={RoundButtonSize.Small}
                icon={faXmark}
                onClick={discard}
              />
            </>
          )}

          {!isEditing && (
            <RoundButtonComponent
              css={buttonStyle}
              size={RoundButtonSize.Small}
              icon={faPencil}
              onClick={() => {
                startEditingItem();
              }}
            />
          )}

          <TooltipDeprComponent
            tooltipContent={
              props.isRemoveDisabled ?
                t('Only the owner ({{ownerName}}) is allowed to delete this territory group', { ownerName: props.ownerName })
                : null
            }
          >
            <RoundButtonComponent
              css={buttonStyle}
              size={RoundButtonSize.Small}
              icon={faTrash}
              isDisabled={props.isRemoveDisabled}
              buttonStyle={props.isRemoveDisabled ? RoundButtonStyle.Disabled : RoundButtonStyle.Danger}
              onClick={() => {
                props.onRemove();
              }}
            />
          </TooltipDeprComponent>

          <RoundButtonComponent
            css={[buttonStyle, expandButtonStyle({ theme, isExpanded: showItems })]}
            size={RoundButtonSize.Small}
            icon={faCaretUp}
            onClick={() => {
              toggleShowItems();
              props.onExpandClick();
            }}
          />
        </div>
      </div>

      <HeightTransitionComponent animateOnMount>
        {showItems && (
          <BoundaryListingContainer boundaryGroupId={props.boundaryGroupId} />
        )}
      </HeightTransitionComponent>
    </div>
  );
};
