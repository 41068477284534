import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useTranslation } from '../../../_shared/utils/hooks';
import { removeRouteFromDirections } from '../../../store/mapSettings/directions/mapSettingsDirections.actionCreators';
import { useMapSettingsDirectionsRoutesSelector } from '../../../store/mapSettings/directions/mapSettingsDirections.selectors';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { createMapStatusBarItemFromRoute } from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useDirectionsStatusBarItems = () => {
  const routes = useMapSettingsDirectionsRoutesSelector();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  return useMemo<MapStatusBarItem[]>(() => {
    if (mapId) {
      return routes.map(route => {
        return createMapStatusBarItemFromRoute({
          routeName: route.name, t,
          onRemove: () => dispatch(removeRouteFromDirections(route, mapId)),
          onClick: () => dispatch(openMapTool(MapTool.Routing)),
        });
      });
    }

    return [];
  }, [mapId, routes, t, dispatch]);
};
