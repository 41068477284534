import { createUuid } from '~/_shared/utils/createUuid';
import { type MapAction } from '~/store/map/map.action';
import { MAP_RESET } from '~/store/map/map.actionTypes';
import { type SpreadsheetDataAction } from './spreadsheetData.action';
import {
  SPREADSHEET_ADD_FILTER_ITEM,
  SPREADSHEET_FETCH_DATA_ERROR,
  SPREADSHEET_FETCH_DATA_STARTED,
  SPREADSHEET_FETCH_DATA_SUCCESS,
  SPREADSHEET_RESET_STATE_AND_REFETCH_DATA,
} from './spreadsheetData.actionTypes';
import { type SpreadsheetDataState } from './spreadsheetData.state';

const initialState: SpreadsheetDataState = {
  isLoading: true,
  data: null,
  filters: null,
  isReady: false,
  etag: 'initial-state',
};

export const spreadsheetDataReducer = (state = initialState, action: SpreadsheetDataAction | MapAction): SpreadsheetDataState => {
  switch (action.type) {
    case SPREADSHEET_FETCH_DATA_STARTED: {
      return {
        ...state,
        isReady: false,
        isLoading: true,
      };
    }

    case SPREADSHEET_FETCH_DATA_ERROR: {
      return {
        ...state,
        isReady: false,
        isLoading: false,
      };
    }

    case SPREADSHEET_FETCH_DATA_SUCCESS: {
      // Do not write delayed/obsolete fetches after spreadsheet data reset
      if (state.etag !== action.payload.etag) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        isReady: true,
        data: action.payload.data,
        etag: createUuid(),
      };
    }

    case MAP_RESET:
    case SPREADSHEET_RESET_STATE_AND_REFETCH_DATA: {
      return {
        ...initialState,
        etag: createUuid(),
      };
    }

    case SPREADSHEET_ADD_FILTER_ITEM: {
      return {
        ...state,
        filters: {
          // perSpreadsheetMap: true,
          ...state.filters,
          [action.payload.spreadsheetId]: {
            ...state.filters?.[action.payload.spreadsheetId],
            [action.payload.filterHash]: action.payload.rowsData,
          },
        },
      };
    }

    default:
      return state;
  }
};
