import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName,
} from '../baseMap.enums';

export const baseMapThemesAubergine: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#1d2c4d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#8ec3b9',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.all,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#1a3646',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.country'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#4b6878',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.landParcel'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#64779e',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['administrative.province'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#4b6878',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#334e87',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#023e58',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#283d6a',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#6f9ba5',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#1d2c4d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['geometry.fill'],
    stylers: {
      color: {
        value: '#023e58',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['poi.park'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#3C7680',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#304a7d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#98a5be',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#1d2c4d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#2c6675',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        value: '#255763',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#b0d5ce',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#023e58',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#98a5be',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.transit,
    elementType: BaseMapElementTypeName['labels.text.stroke'],
    stylers: {
      color: {
        value: '#1d2c4d',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.line'],
    elementType: BaseMapElementTypeName['geometry.fill'],
    stylers: {
      color: {
        value: '#283d6a',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['transit.station'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#3a4762',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        value: '#0e1626',
        isActive: true,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        value: '#4e6d70',
        isActive: true,
      },
    },
  },
];
