import MapTypeStyle = google.maps.MapTypeStyle;
import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { MapTypeId } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  type MapSettingsMapStylesElementState,
  type MapSettingsMapStylesState,
  type MapSettingsMapStylesStylers,
  type MapSettingsMapStylesStylingState,
} from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  TESTING_MAP_TYPE, testingModeEnabled,
} from '../../testingMode/testingMode';
import {
  type BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../settings/accordion/baseMap/baseMap.enums';

type MapTypeStyleFeatureType = string;
type MapTypeStyler = any; //Google maps JS API has currently this defined as <Object> in the documentation

const DEFAULT_MAP_TYPE = testingModeEnabled() ? TESTING_MAP_TYPE : MapTypeId.Roadmap;

export class MapStyleManager {
  private map: google.maps.Map;
  private styles: MapTypeStyle[] = [];
  private localStyles = {} as MapSettingsMapStylesStylingState;
  private localIsSatelliteEnabled = false;

  constructor(map: google.maps.Map) {
    this.map = map;
    this.setIsSatelliteMode(false);
  }

  getStyles() {
    return this.styles;
  }

  updateStyles(newStyles: MapSettingsMapStylesState) {
    if (this.hasStylesDataChanged(newStyles.styling)) {
      this.localStyles = newStyles.styling;

      this.styles = this.createWizardStylesFromLocalStyles(newStyles.styling);

      this.map.setOptions({ styles: this.styles });
    }

    if (this.localIsSatelliteEnabled !== newStyles.isSatelliteEnabled) {
      this.setIsSatelliteMode(newStyles.isSatelliteEnabled);
    }
  }

  destroy() {
    this.removeAllStyles();
  }

  private setIsSatelliteMode(newValue: boolean) {
    this.localIsSatelliteEnabled = newValue;
    const mapType = newValue ? MapTypeId.Hybrid : DEFAULT_MAP_TYPE;
    this.map.setMapTypeId(mapType);
  }

  private createWizardStylesFromLocalStyles(localStyles: MapSettingsMapStylesStylingState): MapTypeStyle[] {
    const results: MapTypeStyle[] = [];

    Object.keys(localStyles).forEach((feature: BaseMapFeatureTypeName) => {
      const elementState: MapSettingsMapStylesElementState = localStyles[feature];
      Object.keys(elementState).forEach(((element: BaseMapElementTypeName) => {
        const stylersState = elementState[element];
        const stylers: MapTypeStyler[] | null = this.localToWizardStylers(stylersState);

        if (stylers) {
          results.push({
            featureType: this.localToWizardFeatureType(feature),
            elementType: element,
            stylers,
          });
        }
      }));
    });

    return results;
  }

  private localToWizardFeatureType(featureType: BaseMapFeatureTypeName): MapTypeStyleFeatureType {
    switch (featureType) {
      case BaseMapFeatureTypeName['administrative.landParcel']:
        return 'administrative.land_parcel';

      case BaseMapFeatureTypeName['landscape.humanMade']:
        return 'landscape.man_made';

      case BaseMapFeatureTypeName['poi.placeOfWorship']:
        return 'poi.place_of_worship';

      case BaseMapFeatureTypeName['poi.sportsComplex']:
        return 'poi.sports_complex';

      case BaseMapFeatureTypeName['road.highway.controlledAccess']:
        return 'road.highway.controlled_access';

      default:
        return featureType;
    }
  }

  private localToWizardStylers(stylers: MapSettingsMapStylesStylers): MapTypeStyler[] | null {
    const typeStylers: MapTypeStyler[] = [];

    Object.keys(stylers).forEach((styler: keyof MapSettingsMapStylesStylers) => {
      let value: string | number | undefined = undefined;
      if (styler === 'visibility') {
        value = this.localToWizardVisibility(stylers[styler]);
      }
      else if (stylers[styler].isActive) {
        value = stylers[styler].value;
      }

      if (styler === 'color' && value) {
        value = guaranteeHash(value as string);
      }

      if (value !== undefined) {
        typeStylers.push({ [styler]: value });
      }
    });

    return typeStylers.length > 0 ? typeStylers : null;
  }

  private localToWizardVisibility(visibility: BaseMapStylerVisibility): string | undefined {
    switch (visibility) {
      case BaseMapStylerVisibility.hidden:
        return 'off';

      case BaseMapStylerVisibility.inherit:
        return undefined;

      case BaseMapStylerVisibility.shown:
        return 'on';

      case BaseMapStylerVisibility.simplified:
        return visibility;

      default:
        return 'inherit';
    }
  }

  private hasStylesDataChanged(newStyles: MapSettingsMapStylesStylingState): boolean {
    return newStyles !== this.localStyles;
  }

  private removeAllStyles() {
    this.styles = [];
  }
}
