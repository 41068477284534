import axios, {
  type AxiosRequestConfig, type CancelToken,
} from 'axios';
import { CONFIG } from '~/_shared/constants/config';
import {
  isAuthTokenExpired, isStoredRefreshTokenValid, refreshTokens,
} from '~/authorization/authContext/auth.utils';
import { invitationEndpointUrl } from '~/clientTeamManagement/invitationPage/inviteeInvitation.repository';
import { GOOGLE_AUTH_ERROR } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { type SpreadsheetDataBulkRequestGetter } from '~/spreadsheet/spreadsheet.repository';
import { appStore } from '~/store/app.store';
import {
  loginEndpointUrl, refreshTokensEndpointUrl, userDataEndpointUrl,
} from '~/store/userData/repository/userData.repository';
import { userGoogleUnauthorized } from '~/store/userData/userData.actionCreators';
import { HOME_ROUTE } from '../../constants/routes';
import {
  EXPIRED_SUBPATH, isPresentationalMapByPathname,
} from '../../presentationMode/presentationModeContext';
import { refreshPage } from '../document/document.helpers';
import { isPresentationalMapRoute } from '../tests/router.helpers';
import { buildUrl } from '../url/url.helpers';
import {
  createCancelError, createConnectionApiError, createHttpError, createUnknownError,
} from './apiError.helpers';

type ApiGetParams = {
  readonly [key: string]: number | string | boolean | undefined | null;
};

type SingleApiParam = number | string | boolean | undefined | null | SpreadsheetDataBulkRequestGetter
| ReadonlyArray<SingleApiParam>;

type ApiParams = {
  readonly [key: string]: SingleApiParam | ApiParams | ReadonlyArray<ApiParams>;
};

export type UploadFileParams = {
  onProgress?: (progress: number) => void;
  cancelToken?: CancelToken;
};

export type OperationResultResponse = {
  code?: string;
  message?: string;
  errors?: { readonly [key: string]: ReadonlyArray<string> };
  data?: any;
};

export const getPrefixedApiUrl = (url: string): string => {
  return (CONFIG.API_URL ?? '') + url;
};

const urlContainsSegment = (url: string | undefined, urlSegments: readonly string[]) =>
  urlSegments.some(segment => url?.includes(segment));

const urlsThatIgnoreAccessToken: readonly string[] = [loginEndpointUrl, refreshTokensEndpointUrl];
const isUrlWithIgnoredAccessToken = (url: string | undefined) => urlContainsSegment(url, urlsThatIgnoreAccessToken);

axios.interceptors.request.use(async config => {
  const newConfig = { ...config };
  newConfig.withCredentials = true;
  newConfig.url = getPrefixedApiUrl(config.url || '');

  if (isPresentationalMapByPathname(window.location.pathname)) {
    newConfig.url = buildUrl(newConfig.url, { presentation_mode: true });
  }
  else if (!isUrlWithIgnoredAccessToken(config.url)) {
    if (isAuthTokenExpired() && isStoredRefreshTokenValid()) {
      try {
        await refreshTokens();
      }
      catch {
        // do full reload
        // logout in reducer is not enough to recover from failed response
        // the promise will finish and sometimes it redirects to error page and not to login
        // or it keeps invalid data / error states in redux store
        // all this depends on the implementation of the feature that created the call
        refreshPage();
      }
    }
  }

  return newConfig;
});

const urlsWithoutRefreshOn401: readonly string[] = [userDataEndpointUrl, invitationEndpointUrl];
const isUrlWithoutRefreshOn401 = (url: string | undefined) => urlContainsSegment(url, urlsWithoutRefreshOn401);

const handleUnauthorized = (response: OperationResultResponse, config: AxiosRequestConfig) => {
  if (response.message === GOOGLE_AUTH_ERROR) {
    appStore.dispatch(userGoogleUnauthorized());
    return false;
  }
  else if (isUrlWithoutRefreshOn401(config.url) && config.method?.toLowerCase() === 'get') {
    // prevent refresh loop
    // do not refresh when app fails to fetch the user on initial load
    return false;
  }
  else {
    // do full reload
    // logout in reducer is not enough to recover from failed response
    // the promise will finish and sometimes it redirects to error page and not to login
    // or it keeps invalid data / error states in redux store
    // all this depends on the implementation of the feature that created the call
    refreshPage();
    return true;
  }
};

const handle423 = () => {
  // 423 = licence expired
  if (isPresentationalMapRoute(window.location.pathname)) {
    window.location.href += EXPIRED_SUBPATH;
  }
  else {
    window.location.href = HOME_ROUTE;
  }
  return true;
};

axios.interceptors.response.use(response => response.data, error => {
  if (axios.isCancel(error)) {
    throw createCancelError();
  }

  if (error.response) {
    let handled = false;

    if (error.response.status === 401) {
      handled = handleUnauthorized(error.response.data, error.response.config);
    }

    if (error.response.status === 423) {
      handled = handle423();
    }

    if (error.response.status === 413) {
      throw createHttpError(error.response.status, { message: 'Payload Too Large' });
    }

    if (!handled) {
      throw createHttpError(error.response.status, error.response.data);
    }
  }
  else if (error.request) {
    throw createConnectionApiError();
  }
  else {
    throw createUnknownError(error.message);
  }
});

export const createCancelToken = () => {
  return axios.CancelToken.source();
};

export const apiPost = <T = any>(url: string, params: ApiParams = {}, config?: AxiosRequestConfig): Promise<T> => {
  return axios.post(url, params, config);
};

export const apiGet = <T = any>(url: string, params: ApiGetParams = {}, config?: AxiosRequestConfig): Promise<T> => {
  const requestUrl = buildUrl(url, params);

  return axios.get(requestUrl, config);
};

export const apiDelete = <T = any>(url: string, params: ApiParams = {}, config?: AxiosRequestConfig): Promise<T> => {
  return axios.delete(url, {
    data: params,
    ...config,
  });
};

export const apiPatch = <T = any>(url: string, params: ApiParams = {}, config?: AxiosRequestConfig): Promise<T> => {
  return axios.patch(url, params, config);
};

export const apiPut = <T = any>(url: string, params: ApiParams = {}, config?: AxiosRequestConfig): Promise<T> => {
  return axios.put(url, params, config);
};

export const uploadFile = <T = any>(url: string, file: File, uploadFileParams: UploadFileParams = {}): Promise<T> => {
  const data = new FormData();
  data.append('file', file);

  return axios.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: uploadFileParams.cancelToken,
    onUploadProgress: progressEvent => {
      if (uploadFileParams.onProgress) {
        uploadFileParams?.onProgress(
          progressEvent.event.lengthComputable ? progressEvent.loaded * 100 / progressEvent.event.total : 0
        );
      }
    },
  });
};
