import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useUserDataSettingsSelector } from '~/store/userData/settings/userDataSettings.selectors';
import { mapDropdownUpdate } from '../../store/userData/settings/userDataSettings.actionCreators';
import { MapListSortType } from './mapListing.helpers';

export const useMapDropDown = (clientId: number | null) => {
  const dispatch = useDispatch();
  const settings = useUserDataSettingsSelector();
  const sortType = useMemo(() => {
    if (!clientId) {
      return MapListSortType.Updated_DESC;
    }
    return settings?.[clientId]?.home?.mapListSortType || MapListSortType.Updated_DESC;
  }, [settings, clientId]);

  const setSortType = useCallback(
    (sort: MapListSortType) => {
      if (clientId) {
        dispatch(mapDropdownUpdate(clientId, sort));
      }
    },
    [clientId, dispatch],
  );

  return {
    sortType,
    setSortType,
  };
};
