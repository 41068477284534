import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type FC, type ReactNode,
} from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useHeightTransition } from './useHeightTransition';

type HeightTransitionProps = Readonly<{
  children: ReactNode;
  animateOnMount: boolean;
}>;

export const HeightTransitionComponent: FC<HeightTransitionProps> = (props) => {
  const [wrapperElementRef, contentHeight] = useHeightTransition();
  const previousContentHeight = usePrevious(contentHeight);

  const immediate = previousContentHeight === undefined && !props.animateOnMount
    || previousContentHeight === contentHeight;

  const heightStyle = useSpring({
    from: {
      height: previousContentHeight ?? 0,
    },
    to: {
      height: contentHeight ?? 0,
    },
    immediate,
  });

  return (
    <animated.div style={{ ...heightStyle, overflow: 'hidden' }}>
      <div
        ref={el => {
          wrapperElementRef.current = el;
        }}
      >
        {props.children}
      </div>
    </animated.div>
  );
};
