import { type ReactNode } from 'react';

export type AccordionData = Readonly<{
  header: ReactNode;
  child: ReactNode;
  onHeadingClick?: () => void;
  isLocked?: boolean;
  isExpanded?: boolean; //do not switch between boolean (controlled) and undefined (uncontrolled)!
  showCount?: boolean;
  testId?: string;
}>;

export const AccordionHeaderSize = {
  Flexible: 'Flexible',
  Medium: 'Medium',
  Large: 'Large',
} as const;

export type AccordionHeaderSize = typeof AccordionHeaderSize[keyof typeof AccordionHeaderSize];
