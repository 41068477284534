import { css } from '@emotion/react';
import {
  faCog, faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { SquareIconButtonComponent } from '~/_shared/baseComponents/buttons';
import { RoundButtonComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { TextSearchBoxComponent } from '~/_shared/components/textSearchBox/textSearchBox.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type SearchItem } from '../search/mapSearch.helpers';
import {
  createDisplayName,
  MapSearchFilterBy,
} from './mapSearchFilterBy.enum';

const filterHeight = 48;

const filterShadowStyle = ({ theme }: ThemeProps) => css({
  boxShadow: `0px 2px 4px ${theme.shadowColors.secondary}`,
});

const wrapperStyle = ({ isMobile }: { isMobile: boolean}) => css({
  display: 'flex',
  backgroundColor: 'transparent',
  width: isMobile ? '100%' : undefined,

  '& > * + *': {
    marginLeft: 5,
  },
});

const searchBoxStyle = css({
  height: filterHeight,
});

const buttonStyle = (props: ThemeProps) => css(filterShadowStyle(props), {
  flexShrink: 0,
});

const closeButtonStyle = (props: ThemeProps) => css(filterShadowStyle(props), {
  minWidth: 25,
  width: 25,
  minHeight: 25,
  height: 25,
});

const dropdownListStyle = ({ theme, isSelected }: ThemeProps<{ isSelected: boolean }>) => css(filterShadowStyle({ theme }), {
  color: isSelected ? theme.textColors.primary : theme.textColors.secondary,
  width: '359px',
});

const dropdownRootStyle = (props: ThemeProps) => css(filterShadowStyle(props), {
  width: '100%',
  minWidth: 110,
  maxWidth: 153,
});

const dropdownTriggerStyle = (props: ThemeProps<{ isSelected: boolean }>) => css(dropdownListStyle(props), {
  height: '100%',
  width: '100%',
});

type MapFilterProps = Readonly<{
  areSearchSuggestsLoading: boolean;
  className?: string;
  filterBy: MapSearchFilterBy | null;
  isMobileScreen: boolean;
  searchSuggests: SearchItem[];
  searchText: string;

  onCloseSearchBar?: () => void;
  onFilterByChange: (newFilterBy: MapSearchFilterBy) => void;
  onSearchSuggestSubmit: (suggest: SearchItem) => void;
  onSearchTextChange: (newText: string) => void;
  onShowConfig: () => void;
}>;

export const MapFilterComponent: FC<MapFilterProps> = props => {
  const theme = useTheme();
  const isFilterBySelected = props.filterBy !== null;
  const [t] = useTranslation();

  return (
    <div
      css={wrapperStyle({ isMobile: props.isMobileScreen })}
      className={props.className}
    >
      {!props.isMobileScreen && (
        <RegularDropdownComponent
          css={dropdownRootStyle({ theme })}
          onChange={props.onFilterByChange}
          value={props.filterBy}
          options={Object.values(MapSearchFilterBy).map(value => ({ value, name: createDisplayName(value, t) }))}
          fallbackName={t('Filter By')}
          triggerStyle={dropdownTriggerStyle({ theme, isSelected: isFilterBySelected })}
          listStyle={dropdownListStyle({ theme, isSelected: isFilterBySelected })}
        />
      )}

      <TextSearchBoxComponent
        css={filterShadowStyle({ theme })}
        inputStyle={searchBoxStyle}
        value={props.searchText}
        onClear={() => props.onSearchTextChange('')}
        onChange={props.onSearchTextChange}
        searchSuggests={props.searchSuggests}
        onSearchSuggestSubmit={props.onSearchSuggestSubmit}
        areSearchSuggestsLoading={props.areSearchSuggestsLoading}
      />

      {!props.isMobileScreen && (
        <SquareIconButtonComponent
          css={buttonStyle({ theme })}
          icon={faCog}
          onClick={props.onShowConfig}
          buttonSize={filterHeight}
        />
      )}

      {props.onCloseSearchBar && (
        <RoundButtonComponent
          css={closeButtonStyle({ theme })}
          icon={faTimes}
          buttonStyle={RoundButtonStyle.Secondary}
          onClick={props.onCloseSearchBar}
        />
      )}
    </div>
  );
};
