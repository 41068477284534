import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  MAP_SETTINGS_TOOLS_STATE_BOUNDARY_CLEAR_LOCATIONS_FILTER,
  MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_FILTER, MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER,
} from './mapSettingsToolsStateBoundary.actionTypes';
import {
  type BoundaryFilter, type BoundaryLocationsFilter,
} from './mapSettingsToolsStateBoundary.state';

export const setBoundaryFilter = (boundaryGroupId: BoundaryGroupId, filter: BoundaryFilter) => ({
  type: MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_FILTER,
  payload: {
    boundaryGroupId,
    filter,
  },
}) as const;

export const setBoundaryLocationsFilter = (boundaryGroupId: BoundaryGroupId, filter: BoundaryLocationsFilter) => ({
  type: MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER,
  payload: {
    boundaryGroupId,
    filter,
  },
}) as const;

export const clearBoundaryLocationsFilter = () => ({
  type: MAP_SETTINGS_TOOLS_STATE_BOUNDARY_CLEAR_LOCATIONS_FILTER,
}) as const;
