import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  type GroupingColumn, type GroupingColumnValues, GroupingType,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetDescriptor } from '~/_shared/types/map';
import { RangeType } from '~/_shared/utils/range/range.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { MAP_INFO_FETCH_DATA_SUCCESS } from '~/store/mapInfo/mapInfo.actionTypes';
import {
  calculateDataRowsForActiveGroupingFilters, getGroupingColumnRangesFromBuckets,
} from '../../spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { type SpreadsheetDataAction } from '../../spreadsheetData/spreadsheetData.action';
import { spreadsheetAddFilterItem } from '../../spreadsheetData/spreadsheetData.actionCreators';
import { SPREADSHEET_FETCH_DATA_SUCCESS } from '../../spreadsheetData/spreadsheetData.actionTypes';
import {
  DataType, type SpreadsheetDataData, Unfiltered,
} from '../../spreadsheetData/spreadsheetData.state';
import { type MapSettingsDataState } from '../data/mapSettingsData.state';
import { type MapSettingsFilteringAction } from '../filtering/mapSettingsFiltering.action';
import {
  MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE, MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF,
} from '../filtering/mapSettingsFiltering.actionTypes';
import { type MapSettingsGroupingAction } from '../grouping/mapSettingsGrouping.action';
import {
  MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN, MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
} from '../grouping/mapSettingsGrouping.actionTypes';
import { mapSettingsGroupingActiveGroupColumnsSelector } from '../grouping/mapSettingsGrouping.selectors';
import {
  mapSettingsColumnsFilterRemoveFilterValues, mapSettingsColumnsFilterSetGroup,
} from './mapSettingsColumnsFilter.actionCreators';
import { type MapSettingsColumnsFilterState } from './mapSettingsColumnsFilter.state';
import { mapSettingsColumnsFilterSelector } from './mapSettingsColumnsFilters.selectors';

export function* mapSettingsColumnsFiltersSagas() {
  yield takeLatest(MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE, removeFilterValues);
  yield takeLatest(MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF, removeFilterValues);
  yield takeLatest(MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN, onActiveGroupingColumnRemove);
  yield takeLatest(MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS, onActiveGroupingColumnsChange);
  yield takeLatest(SPREADSHEET_FETCH_DATA_SUCCESS, validateGroupingFilterGroupValues);

  yield takeLatest(
    [
      MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN,
      MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
      SPREADSHEET_FETCH_DATA_SUCCESS,
      MAP_INFO_FETCH_DATA_SUCCESS,
    ],
    recalculateSpreadsheetDataForGroupingFilters
  );

  yield takeLatest(
    (action: {type?: string} | undefined) => {
      return action?.type?.startsWith('MAP_SETTINGS_COLUMNS_FILTER') ?? false;
    },
    recalculateSpreadsheetDataForGroupingFilters
  );
}

type RemoveFilterValuesActions =
  PickAction<MapSettingsFilteringAction, typeof MAP_SETTINGS_FILTERING_CHANGE_DATA_TYPE> |
  PickAction<MapSettingsFilteringAction, typeof MAP_SETTINGS_FILTERING_TOGGLE_ON_OFF>;

function* removeFilterValues(action: RemoveFilterValuesActions) {
  yield put(mapSettingsColumnsFilterRemoveFilterValues(action.payload.spreadsheetColumnId));
}

function* onActiveGroupingColumnsChange(action: PickAction<MapSettingsGroupingAction, typeof MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS>) {
  for (const activeGroupColumn of action.payload.activeGroupColumns) {
    const isColumnTypeChanged = !action.payload.previousActiveGroupColumns.find(item =>
      item.spreadsheetId === activeGroupColumn.spreadsheetId
      && item.columnId === activeGroupColumn.columnId
      && item.type === activeGroupColumn.type
      && (item.type !== GroupingType.Numeric || activeGroupColumn.type !== GroupingType.Numeric || item.buckets.length === activeGroupColumn.buckets.length)
    );

    if (isColumnTypeChanged) {
      yield put(mapSettingsColumnsFilterRemoveFilterValues({
        spreadsheetId: activeGroupColumn.spreadsheetId,
        columnId: activeGroupColumn.columnId,
      }));
    }
  }
}

function* recalculateSpreadsheetDataForGroupingFilters() {
  const currentSpreadsheetData: SpreadsheetDataData | null = yield select<SpreadsheetDataData | null>(state => state.spreadsheet.spreadsheetData.data);
  const mapSettings: MapSettingsDataState = yield select<MapSettingsDataState>(state => state.map.mapSettings.data);
  const spreadsheets: ReadonlyArray<SpreadsheetDescriptor> = yield select<ReadonlyArray<SpreadsheetDescriptor>>(state => state.map.mapInfo.data?.spreadsheets ?? []);
  const primarySpreadsheet = spreadsheets.find(item => item.isPrimary);
  const spreadsheetId = primarySpreadsheet?.spreadSheetId ?? null;

  if (currentSpreadsheetData === null || !spreadsheetId) {
    return;
  }

  const groupingData = calculateDataRowsForActiveGroupingFilters(
    currentSpreadsheetData,
    {
      spreadsheetId,
      columnsFilter: mapSettings.toolsState.columnsFilter,
      filtering: mapSettings.filtering,
      grouping: mapSettings.grouping,
    });

  for (const spreadsheetIdString in groupingData) {
    if (!groupingData.hasOwnProperty(spreadsheetIdString)) {
      continue;
    }

    const spreadsheetId = +spreadsheetIdString;
    const spreadsheetGroupingData = groupingData[spreadsheetId];

    if (spreadsheetGroupingData) {
      yield put(spreadsheetAddFilterItem(
        spreadsheetId,
        spreadsheetGroupingData.filterHash,
        spreadsheetGroupingData.values
      ));
    }
  }
}

function* onActiveGroupingColumnRemove(action: PickAction<MapSettingsGroupingAction,
  typeof MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN>) {
  yield put(mapSettingsColumnsFilterRemoveFilterValues(action.payload.activeGroupColumn));
}

function *validateGroupingFilterGroupValues(action: PickAction<SpreadsheetDataAction, typeof SPREADSHEET_FETCH_DATA_SUCCESS>) {
  const columnFilter: MapSettingsColumnsFilterState = yield select<MapSettingsColumnsFilterState>(mapSettingsColumnsFilterSelector);
  const activeGroupColumns: GroupingColumn[] = yield select<GroupingColumn[]>(mapSettingsGroupingActiveGroupColumnsSelector);
  const itemsToUpdate: Array<{
    spreadsheetId: number;
    columnId: string;
    groupIds: GroupingColumnValues<1>;
  }> = [];

  Object.keys(action.payload.data.values).forEach(spreadsheetId => {
    const unfilteredValues = action.payload.data.values[+spreadsheetId]?.[Unfiltered];
    if (!unfilteredValues) {
      return;
    }

    Object.keys(unfilteredValues).forEach(columnId => {
      const groupSpreadsheetData = unfilteredValues?.[columnId]?.[DataType.GROUP];
      const columnFilterData = columnFilter[+spreadsheetId]?.[columnId]?.[DataType.GROUP];

      if (!columnFilterData) {
        return;
      }

      // TEXT data type
      if (groupSpreadsheetData) {
        const uniqueGroupIds = new Set(groupSpreadsheetData.extra.uniqueGroups.map(group => group.id));

        const filterGroupTextIds = new Set(Object.keys(columnFilterData?.text ?? {}));
        let textGroupRequiresUpdate = false;
        Array.from(filterGroupTextIds).forEach(groupId => {
          if (!uniqueGroupIds.has(groupId)) {
            filterGroupTextIds.delete(groupId);
            textGroupRequiresUpdate = true;
          }
        });

        const groupIds: Record<string, 1> = {};

        for (const groupId of filterGroupTextIds) {
          groupIds[groupId] = 1;
        }

        if (textGroupRequiresUpdate) {
          itemsToUpdate.push({
            spreadsheetId: +spreadsheetId,
            columnId,
            groupIds: {
              [GroupingType.Text]: groupIds,
            },
          });
        }
      }

      // NUMBER data type
      const spreadsheetDataDetails = unfilteredValues?.[columnId]?.[DataType.NUMBER];
      const filterGroupNumberIds = new Set<number>(Object.keys(columnFilterData?.numeric ?? {}).map(key => +key));
      let numericGroupRequiresUpdate = false;
      const activeColumn = activeGroupColumns.find(group => group.spreadsheetId === +spreadsheetId && group.columnId === columnId);
      if (!activeColumn || activeColumn.type !== GroupingType.Numeric || !spreadsheetDataDetails) {
        return;
      }

      const minMax = activeColumn.valueType === RangeType.Value ? {
        min: spreadsheetDataDetails.extra.min,
        max: spreadsheetDataDetails.extra.max,
      } : {
        min: 0,
        max: 100,
      };

      const numericalRanges = getGroupingColumnRangesFromBuckets(activeColumn, minMax.min, minMax.max);
      for (let i = 0; i < numericalRanges.length; i++) {
        const range = numericalRanges[i];

        if (!range) {
          return;
        }

        const { from: valueFrom, to: valueTo } = range;

        const isValueValid = valueTo > minMax.min && valueFrom < minMax.max && valueTo > valueFrom;

        if (!isValueValid) {
          filterGroupNumberIds.delete(i);
          numericGroupRequiresUpdate = true;
        }
      }

      const numericGroupIds: Record<string, 1> = {};

      for (const groupId of filterGroupNumberIds) {
        numericGroupIds[groupId] = 1;
      }

      if (numericGroupRequiresUpdate) {
        itemsToUpdate.push({
          spreadsheetId: +spreadsheetId,
          columnId,
          groupIds: {
            [GroupingType.Numeric]: numericGroupIds,
          },
        });
      }
    });
  });

  for (const item of itemsToUpdate) {
    yield put(mapSettingsColumnsFilterSetGroup({
      spreadsheetId: item.spreadsheetId,
      columnId: item.columnId,
    }, item.groupIds));
  }
}
