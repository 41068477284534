import { css } from '@emotion/react';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { NumberInCircleComponent } from '~/_shared/baseComponents/numberInCircle/numberInCircle.component';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

type LegHeaderProps = Readonly<{
  address: string;
  colorTileBackground: string;
  colorTileColor: string;
  estimation?: string;
  isExpandable: boolean;
  isExpanded?: boolean;
  number: number;

  onClick?: () => void;
}>;

const headerStyle = ({ theme, displayBorder }: ThemeProps<{ displayBorder: boolean }>) => css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '10px 0 10px 30px',
  background: 'none',
  border: 'none',
  borderBottom: displayBorder ? `1px solid ${theme.borderColors.primary}` : 'none',
  color: theme.textColors.primary,
  textAlign: 'left',
});

const addressStyle = css({
  lineHeight: 1.15,
  fontSize: '14px',
});

const estimationStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  margin: '4px 0 0',
  lineHeight: 1.15,
});

const iconWrapperStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.primary,
  marginRight: 16,
});

const caretStyle = ({ isExpanded }: { isExpanded?: boolean }) => css({
  fontSize: '16px',
  transition: 'transform .2s',
  transform: isExpanded ? undefined : 'rotate(180deg)',
});

const contentStyle = css({
  overflow: 'hidden',
  paddingRight: 10,
});

export const LegHeaderComponent: FC<LegHeaderProps> = (props) => {
  const theme = useTheme();

  return (
    <button
      css={headerStyle({ theme, displayBorder: props.isExpandable })}
      onClick={props.onClick}
    >
      <div>
        <RowNumber
          fillColor={props.colorTileBackground}
          fontColor={props.colorTileColor}
          number={props.number}
        />
      </div>

      <div css={contentStyle}>
        <TooltipComponent
          tooltipContent={props.address}
          delayShow={500}
        >
          <LimitNumberOfLinesComponent
            css={addressStyle}
            maxNumberOfLines={2}
          >
            {props.address}
          </LimitNumberOfLinesComponent>
        </TooltipComponent>

        {props.estimation !== undefined && (
          <p css={estimationStyle}>{props.estimation}</p>
        )}
      </div>

      {props.isExpandable && (
        <div css={iconWrapperStyle({ theme })}>
          <FontAwesomeIcon
            css={caretStyle({ isExpanded: props.isExpanded })}
            icon={faCaretUp}
          />
        </div>
      )}
    </button>
  );
};

const colorTileStyle = css({
  marginRight: 8,
  fontSize: '14px',
});

const RowNumber: FC<{ number: number; fillColor: string; fontColor: string }> = (props) => {
  const circleColors = useMemo(() => ({
    fillColor: props.fillColor,
    fontColor: props.fontColor,
  }), [props.fillColor, props.fontColor]);

  return (
    <NumberInCircleComponent
      colors={circleColors}
      css={colorTileStyle}
      size={22}
    >
      {props.number}
    </NumberInCircleComponent>
  );
};
