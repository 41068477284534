import {
  useEffect, useMemo,
} from 'react';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useMarkersSelectActiveSelector } from '~/store/frontendState/mapTools/markersSelect/markersSelect.selectors';
import { MarkersSelectType } from '~/store/frontendState/mapTools/markersSelect/markersSelect.state';
import { type MapMarkerManager } from '../manager/mapMarkerManager';
import { useMarkerDragAndDrop } from './useMarkerDragAndDrop';
import { useMarkerEventCallbacks } from './useMarkerEventCallbacks';
import { useMarkersSelectEventCallbacks } from './useMarkersSelectEventCallbacks';

export const useMarkerEvents = (manager?: MapMarkerManager) => {
  const markersSelection = useMarkersSelectActiveSelector();
  const callbacksWithoutDnD = useMarkerEventCallbacks();
  const markerSelectCallbacks = useMarkersSelectEventCallbacks();
  const { markerDnDActive, wrapWithDnDEventHandlers, applyTemporaryDnDMarkerChanges } = useMarkerDragAndDrop();

  const markersSelectionByClickActive = markersSelection?.isActive && markersSelection.selectType === MarkersSelectType.Click;
  const markerEventsDisabled = useIsMapInteractionActive() && !markerDnDActive && !markersSelectionByClickActive;

  const callbacks = useMemo(() => (
    markersSelectionByClickActive
      ? markerSelectCallbacks
      : wrapWithDnDEventHandlers(callbacksWithoutDnD)
  ), [callbacksWithoutDnD, markerSelectCallbacks, markersSelectionByClickActive, wrapWithDnDEventHandlers]);

  useEffect(() => { // Register marker events
    if (!manager || markerEventsDisabled) {
      return;
    }

    if (!markerDnDActive) {
      manager.addMarkersEventListener('click', callbacks.onClick);
      manager.addMarkersEventListener('rightclick', callbacks.onRightClick);
    }
    manager.addMarkersEventListener('mouseover', callbacks.onMouseOver);
    manager.addMarkersEventListener('mouseout', callbacks.onMouseOut);

    return () => {
      if (!markerDnDActive) {
        manager.removeMarkersEventListener('click', callbacks.onClick);
        manager.removeMarkersEventListener('rightclick', callbacks.onRightClick);
      }
      manager.removeMarkersEventListener('mouseover', callbacks.onMouseOver);
      manager.removeMarkersEventListener('mouseout', callbacks.onMouseOut);
    };
  }, [manager, markerEventsDisabled, callbacks.onClick, callbacks.onMouseOver,
    callbacks.onMouseOut, callbacks.onRightClick, markerDnDActive]);

  return {
    applyTemporaryDnDMarkerChanges,
  };
};
