import {
  type FC, useEffect, useState,
} from 'react';
import { type Writable } from 'ts-essentials';
import { type TabsData } from '~/_shared/components/tabs/tabs.types';
import { TabsComponent } from '../../_shared/components/tabs/tabs.component';
import { useTranslation } from '../../_shared/utils/hooks';
import { type RangeType } from '../../_shared/utils/range/range.helpers';
import {
  type BoundaryTerritoryGroup, type BoundaryTerritoryGroupStyle,
} from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { type BoundaryGroup } from '../boundary.types';
import {
  type BOUNDARY_MAX_VALUE_ID, type BOUNDARY_MIN_VALUE_ID,
} from './boundarySettings.helpers';
import { BoundarySettingsGroupComponent } from './group/boundarySettingsGroup.component';
import { BoundarySettingsModalComponent } from './modal/boundarySettingsModal.component';
import { BoundarySettingsNumericComponent } from './numeric/boundarySettingsNumeric.component';
import { BoundarySettingsSyncDataContainer } from './syncData/boundarySettingsSyncData.container';

export enum BoundarySettingsLayout {
  Numeric,
  Group,
}

type BoundarySettingsCommonProps = Readonly<{
  boundaryTerritoryGroup: BoundaryTerritoryGroup;
  boundaryGroup: BoundaryGroup;
  valueIsInitial: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onBoundaryTerritoryGroupChange: (boundaryTerritoryGroup: BoundaryTerritoryGroup) => void;
  onClose: () => void;
  onSubmit: () => void;
  isBoundaryTerritoryGroupCustom: boolean;
}>;

export type BoundarySettingsNumericProps = Readonly<{
  disableRangeTypeChange: boolean;
  isDecimal: boolean;
  layout: BoundarySettingsLayout.Numeric;
  max: number | typeof BOUNDARY_MAX_VALUE_ID;
  min: number | typeof BOUNDARY_MIN_VALUE_ID;
  onNumberOfRangesChange: (numberOfRanges: number, style: BoundaryTerritoryGroupStyle, rangeType: RangeType) => void;
  onRecalculateRangesClick: () => void;
}>;

export type BoundarySettingsGroupProps = Readonly<{
  layout: BoundarySettingsLayout.Group;
  boundaryGroupName: string;
  onBoundaryGroupNameChange?: (newName: string) => void;
  onRecalculateRangesClick?: () => void;
  onRestoreDefaultColors: () => void;
}>;

export type BoundarySettingsProps = BoundarySettingsCommonProps &
(BoundarySettingsNumericProps | BoundarySettingsGroupProps);

export const BoundarySettingsComponent: FC<BoundarySettingsProps> = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isNumericFormValid, setIsNumericFormValid] = useState(true);
  const [isGroupFormValid, setIsGroupFormValid] = useState(true);
  const [isSyncFormFormValid, setIsSyncFormValid] = useState(true);
  const [syncSubmitDisabledReason, setSyncSubmitDisabledReason] = useState<string | null>(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (isSyncFormFormValid) {
      setSyncSubmitDisabledReason(null);
    }
  }, [isNumericFormValid, isGroupFormValid, isSyncFormFormValid]);

  let children = null;

  switch (props.layout) {
    case BoundarySettingsLayout.Numeric:
      children = (
        <BoundarySettingsNumericComponent
          key={`boundary-settings-${props.boundaryTerritoryGroup.boundaryTerritoryGroupId}`} // drop component when group changes
          boundaryTerritoryGroup={props.boundaryTerritoryGroup}
          valueIsInitial={props.valueIsInitial}
          disableRangeTypeChange={props.disableRangeTypeChange}
          isDecimal={props.isDecimal}
          max={props.max}
          min={props.min}
          onBoundaryTerritoryGroupChange={props.onBoundaryTerritoryGroupChange}
          onNumberOfRangesChange={props.onNumberOfRangesChange}
          setIsFormValid={setIsNumericFormValid}
        />
      );
      break;
    case BoundarySettingsLayout.Group:
      children = (
        <BoundarySettingsGroupComponent
          key={`boundary-settings-${props.boundaryTerritoryGroup.boundaryTerritoryGroupId}`} // drop component when group changes
          boundaryTerritoryGroup={props.boundaryTerritoryGroup}
          onBoundaryTerritoryGroupChange={props.onBoundaryTerritoryGroupChange}
          boundaryGroupName={props.boundaryGroupName}
          onBoundaryGroupNameChange={props.onBoundaryGroupNameChange}
          setIsFormValid={setIsGroupFormValid}
          restoreDefaultColors={props.onRestoreDefaultColors}
        />
      );
      break;
    default:
      break;
  }

  const tabsData: Writable<TabsData> = [{
    index: 0,
    header: props.isBoundaryTerritoryGroupCustom ? t('Territory Formatting').toUpperCase() : t('Boundary Formatting').toUpperCase(),
    child: children,
  }];

  if (props.boundaryGroup.matchings && (props.boundaryGroup.matchings.matchingColumns.length > 0)) {
    tabsData.push({
      index: 1,
      header: t('Sync Data').toUpperCase(),
      child: (
        <BoundarySettingsSyncDataContainer
          boundaryTerritoryGroup={props.boundaryTerritoryGroup}
          boundaryGroupMatchings={props.boundaryGroup.matchings}
          onBoundaryTerritoryGroupChange={props.onBoundaryTerritoryGroupChange}
          setIsFormValid={setIsSyncFormValid}
          setSubmitDisabledReason={setSyncSubmitDisabledReason}
        />
      ),
    });
  }

  return (
    <BoundarySettingsModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={props.isLoading}
      isSubmitDisabled={!isNumericFormValid || !isSyncFormFormValid || !isGroupFormValid}
      submitTooltipContent={syncSubmitDisabledReason}
      onSubmitClick={props.onSubmit}
      showRecalculateRangesButton={activeTabIndex === 0}
      onRecalculateRangesClick={props.onRecalculateRangesClick}
      caption={props.isBoundaryTerritoryGroupCustom ? t('Territory Group Settings') : t('Boundary Settings')}
    >
      {(tabsData.length > 1) && (
        <TabsComponent
          activeTabIndex={activeTabIndex}
          data={tabsData}
          setActiveTabIndex={setActiveTabIndex}
        />
      )}
      {(tabsData.length === 1) && tabsData[0] && (
        tabsData[0].child
      )}
    </BoundarySettingsModalComponent>
  );
};
