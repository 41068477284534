const getKey = (userId: number, key: string) => `${userId}_${key}`;

export class StorageService {

  private readonly userId: number = -1;

  constructor(userId: number | null) {
    this.userId = userId ?? -1;
  }

  public setLocalStorageItem = (key: string, value: string) => {
    localStorage.setItem(getKey(this.userId, key), value);
  };
  public getLocalStorageItem = (key: string) => {
    return localStorage.getItem(getKey(this.userId, key));
  };
  public removeLocalStorageItem = (key: string) => {
    localStorage.removeItem(getKey(this.userId, key));
  };
  public setSessionStorageItem = (key: string, value: string) => {
    sessionStorage.setItem(getKey(this.userId, key), value);
  };
  public getSessionStorageItem = (key: string) => {
    return sessionStorage.getItem(getKey(this.userId, key));
  };
  public removeSessionStorageItem = (key: string) => {
    sessionStorage.removeItem(getKey(this.userId, key));
  };
}
