import { type Interpolation } from '@emotion/react';
import { inputSuffixCommonStyle } from '../shared.styles';

export const suffixWrapperStyle = ({ width }: {
  readonly width?: number;
}): Interpolation => [inputSuffixCommonStyle, {
  display: 'flex',
  height: '100%',
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  textTransform: 'uppercase',
  width,
}];
