import { type TranslationFnc } from '../hooks';

const DEFAULT_FORMAT_DATE = 'MMM DD, YYYY';
export const MILISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

const getDayWithLeadingZero = (date: Date) => ('0' + date.getDate()).slice(-2);
const getMonthWithLeadingZero = (date: Date) => ('0' + (date.getMonth() + 1)).slice(-2);

/*
 * Takes a UTC date string and returns current timezone Date object with the same date and hour
 * The function disregards time zone offset on purpose
 */
export const fromUTCDate12PMToCurrentTimezone12PM = (date?: string | null) => {
  if (!date) {
    return null;
  }
  const utcDate = new Date(Date.parse(date));
  const newDate = new Date();

  newDate.setFullYear(utcDate.getUTCFullYear());
  newDate.setMonth(utcDate.getUTCMonth());
  newDate.setDate(utcDate.getUTCDate());
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};

/*
 * Takes returns current timezone Date object and returns UTC ISO date string with the same date and hour
 * The function disregards time zone offset on purpose
 */
export const fromCurrentTimezoneDate12PMtoUTC12PM = (date?: Date | null) => {
  if (!date) {
    return null;
  }

  const utcISOString = `${date.getFullYear()}-${getMonthWithLeadingZero(date)}-${getDayWithLeadingZero(date)}T00:00:00.000Z`;

  return new Date(Date.parse(utcISOString)).toISOString();
};

export const parseDate = (date: string): Date | null => {
  const parsedDate = Date.parse(date);

  if (isNaN(parsedDate)) {
    return null;
  }

  return new Date(parsedDate);
};

const formatAmPm = (date: Date) => {
  const hours = date.getHours();
  const minutes = ('0' + date.getMinutes()).slice(-2);

  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12; // 0 hours is 12 o'clock

  return `${formattedHours}:${minutes} ${ampm}`;
};

export const formatDate = (date: Date, format = DEFAULT_FORMAT_DATE): string => {
  const locale = 'en-US';

  return format
    .replace('yyyy', date.getFullYear().toString())
    .replace('YYYY', date.getFullYear().toString())
    .replace('dd', getDayWithLeadingZero(date))
    .replace('DD', getDayWithLeadingZero(date))
    .replace('d', date.getDate().toString())
    .replace('mm', getMonthWithLeadingZero(date))
    .replace('MMM', date.toLocaleString(locale, { month: 'short' }))
    .replace('hh:mm'.toUpperCase(), formatAmPm(date));
};

export const createFormatDateAccordingToBaseDate = (baseDate: Date, date: Date) => {
  if (baseDate.toDateString() === date.toDateString()) {
    return 'HH:MM';
  }
  if (baseDate.getUTCFullYear() === date.getUTCFullYear()) {
    return 'MMM DD HH:MM';
  }

  return 'YYYY MMM DD HH:MM';
};

export const dateToLocaleDateString = (date: Date) => date.toLocaleDateString();

export const getTranslatedCalendarMonths = (t: TranslationFnc) => (
  Array(12).fill('').map((_, index) => t(`calendarMonth${index as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11}`))
);

export const getTranslatedCalendarMonthsAsNameValue = (t: TranslationFnc) => (
  getTranslatedCalendarMonths(t).map((name, index) => ({ name, value: index }))
);
