import { always } from '~/_shared/utils/function.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING } from '~/_shared/utils/markers/markers.constants';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { EMPTY_MESSAGE } from '~/map/settings/structure.constants';
import { moveMarkersIsModalVisible } from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { mapSettingsMarkersSelector } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import {
  mapSettingsIsMarkerClusteringForcedSelector, mapSettingsIsMarkerClusteringForcedUnstackedMarkersSelector,
  mapSettingsMarkerClusterDenseMarkersSelector,
} from '~/store/mapSettings/markers/mapSettingsMarkersClustering.selectors';
import { isMapSettingsUnstackMarkersForcedSelector } from '~/store/mapSettings/markers/mapSettingsMarkersUnstacking.selectors';
import {
  type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from '../settingsAccordionData.type';
import { type MapMarkersGraphicsSettingsStructureProps } from './mapMarkersGraphics.types';

export const mapMarkersGraphicsStructure = (
  t: TranslationFnc, props: MapMarkersGraphicsSettingsStructureProps, isLayeredMapConnected: boolean
): ReadonlyArray<SettingsAccordionSubItem> => {
  const { buttons, toggles, dropdowns } = props;

  const getBlockedByClusteringTooltipMessage = createStateSelector([mapSettingsMarkerClusterDenseMarkersSelector, () => t], (cluster, t) =>
    cluster ? null : t('mapSettingsDisabledInfo.BlockedByClusteringOff'));

  const getClusteringBlockedMessage = createStateSelector([
    mapSettingsIsMarkerClusteringForcedSelector,
    mapSettingsIsMarkerClusteringForcedUnstackedMarkersSelector,
    () => t,
  ],
  (forced, forcedWithoutStacked, t) => (
    forced
      ? t('mapSettingsDisabledInfo.Cluster{{N}}DenseMarkers', { N: MAX_MARKER_LABELS_BEFORE_FORCED_CLUSTERING })
          + (!forcedWithoutStacked
            ? ' ' + t('mapSettingsDisabledInfo.UnblockClusteringByUnstack{{unstackName}}', { unstackName: t('Unstack Markers') })
            : ''
          )
      : null
  ));

  return [{
    label: t('Customize Map Markers'),
    type: SettingsAccordionSubItemType.Button,
    infoTooltip: t('mapSettingsInfo.CustomizeAllMarkers'),
    textSelector: () => t('Open'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...buttons.onCustomizeAll,
  }, {
    label: t('Customize Active Marker Indicator'),
    type: SettingsAccordionSubItemType.Button,
    infoTooltip: null,
    textSelector: () => t('Open').toUpperCase(),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...buttons.onCustomizeActiveMarkerIndicator,
  }, {
    label: t('Cluster Dense Markers'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.ClusterDenseMarkers'),
    disabledTooltipSelector: createStateSelector([getClusteringBlockedMessage], message => ({ message })),
    ...toggles.clusterDenseMarkers,
  }, {
    label: t('Uncluster Below'),
    type: SettingsAccordionSubItemType.DropDown,
    infoTooltip: t('mapSettingsInfo.UnclusterBellowNMarkers'),
    disabledTooltipSelector: createStateSelector([getBlockedByClusteringTooltipMessage], message => ({ message })),
    ...dropdowns.unclusterBelowN,
  }, {
  // TODO: probably should be removed, as we probably don't need support for high volume markers
  //   label: t('High Volume Marker Display'),
  //   type: SettingsAccordionSubItemType.Toggle,
  //   infoTooltip: t('mapSettingsInfo.HighVolumeMarkerDisplay'),
  //   disabledTooltipSelector: always(null),
  //   ...toggles.highVolumeMarkerDisplay,
  // }, {
    label: t('Cluster w/Pie Charts'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.ClusterWithPieCharts'),
    disabledTooltipSelector: createStateSelector([getBlockedByClusteringTooltipMessage], message => ({ message })),
    ...toggles.clusterWPieCharts,
  }, {
    label: t('Labels Inside Markers (from data)'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.LabelsInsideMarkers'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...toggles.useTextLabel,
  }, {
    label: t('Labels Above Markers (from data)'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.LabelsAboveMarkers'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...toggles.useLabelsAboveMarkers,
  }, {
    label: t('Numeric Label Markers (1,2,3...)'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.NumericLabelMarkers'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...toggles.useNumericLabel,
  }, {
    label: t('Marker Label Default Settings'),
    type: SettingsAccordionSubItemType.Button,
    infoTooltip: t('mapSettingsInfo.MarkerLabelDefaultSettings'),
    textSelector: () => t('Open'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...buttons.onCustomizeLabels,
  }, {
    label: t('Labels Above Markers Settings'),
    type: SettingsAccordionSubItemType.Button,
    infoTooltip: t('mapSettingsInfo.LabelAboveMarkersSettings'),
    textSelector: () => t('Open'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...buttons.onCustomizeLabelsAboveMarkers,
  }, {
    label: t('Unstack Markers'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.UnstackMarkers'),
    disabledTooltipSelector: createStateSelector([isMapSettingsUnstackMarkersForcedSelector, () => t], (isForced, t) => ({
      message: isForced
        ? t('mapSettingsDisabledInfo.unstackMarkersForced', {
          unclusterBelowN: t('Uncluster Below'),
        })
        : null,
    })),
    ...toggles.unstackMarkers,
  }, {
    label: t('Stacked Markers w/Pie Charts'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.StackedMarkersWithPieCharts'),
    disabledTooltipSelector: createStateSelector([mapSettingsMarkersSelector, () => t], (markers, t) => ({
      message: markers.unstackMarkers ? t('mapSettingsDisabledInfo.StackedMarkersWithPieCharts') : null,
    })),
    ...toggles.stackedMarkersWPieCharts,
  }, {
    label: t('Open Links in Same Window'),
    type: SettingsAccordionSubItemType.Toggle,
    infoTooltip: t('mapSettingsInfo.OpenLinksInSameWindow'),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...toggles.openLinksInSameWindow,
  },
  ...(isLayeredMapConnected ? [] : [{
    label: t('Move Markers'),
    type: SettingsAccordionSubItemType.Button,
    infoTooltip: null,
    textSelector: createStateSelector([moveMarkersIsModalVisible, () => t], (modalOpen, t) => modalOpen ? t('Deactivate') : t('Activate')),
    disabledTooltipSelector: always(EMPTY_MESSAGE),
    ...buttons.onActivateMoveMarkers,
  } as SettingsAccordionSubItem]),
  ];
};
