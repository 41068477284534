import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.SearchSonar]: {
  views: {
    default: {
      canvasWidth: 300,
      canvasHeight: 200,
      iconWidth: 190,
      iconHeight: 200,
    },
  },
  colors: {
    border: '#E54525',
    arm: '#2C2D4A',
    dot: '#FFFF00',
  },
  gradients: {
    sonar: [{
      offset: 0,
      color: '#FFFFFF',
      startOpacity: 0,
      endOpacity: 0,
    }, {
      offset: 50,
      color: '#F2A292',
      startOpacity: 50,
      endOpacity: 50,
    }, {
      offset: 100,
      color: '#E54525',
      startOpacity: 100,
      endOpacity: 100,
    }],
  },
  frameRate: 29.9700012207031,
  segments: {
    default: [0, 90] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/99946-searching" */
      './99946-searching.json'
    ),
} };

export const { SearchSonar } = config;
