import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { type MapSettingsState } from '../../mapSettings/mapSettings.state';
import {
  addMissingSpreadsheetData,
  type MissingSpreadsheetData,
} from '../spreadsheetData.helpers';
import {
  DataType,
  type SpreadsheetDataData,
  Unfiltered,
} from '../spreadsheetData.state';

export const getMissingHeatmapsSpreadsheetData = (mapSettings: MapSettingsState, spreadsheetState: SpreadsheetDataData): MissingSpreadsheetData => {
  const missingSpreadsheetData: MissingSpreadsheetData = {
    data: {},
  };

  if (!mapSettings.data.heatmaps.heatmaps?.length) {
    return missingSpreadsheetData;
  }

  return mapSettings.data.heatmaps.heatmaps
    .reduce((accum, heatmap) => {
      ensureGroupColumn(accum, spreadsheetState, heatmap);
      ensureNumericColumn(accum, spreadsheetState, heatmap);

      return accum;
    }, missingSpreadsheetData);
};

const ensureGroupColumn = (result: MissingSpreadsheetData, spreadsheetData: SpreadsheetDataData, heatmap: HeatMap) => {
  const data = heatmap.selectedGroupColumn;

  if (data) {
    const currentSpreadsheetState = spreadsheetData.values[data.spreadsheetId];

    if (!currentSpreadsheetState?.[Unfiltered]?.[data.columnId]?.[DataType.GROUP]) {
      addColumn(result, data.spreadsheetId, data.columnId, DataType.GROUP);
    }
  }
};

const ensureNumericColumn = (result: MissingSpreadsheetData, spreadsheetData: SpreadsheetDataData, heatmap: HeatMap) => {
  const data = heatmap.numericalColumnId;

  if (data) {
    const currentSpreadsheetState = spreadsheetData.values[data.spreadsheetId];

    if (!currentSpreadsheetState || !currentSpreadsheetState[Unfiltered]?.[data.columnId]?.[DataType.NUMBER]) {
      addColumn(result, data.spreadsheetId, data.columnId, DataType.NUMBER);
    }
  }
};

const addColumn = (result: MissingSpreadsheetData, spreadsheetId: number, columnId: string, type: DataType) =>
  addMissingSpreadsheetData(result, {
    spreadsheetId,
    columnId,
    filterHashOrUnfiltered: Unfiltered,
    dataType: type,
    spreadsheetDataToFetchExtra: {},
  });
