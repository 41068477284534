import { type SearchMatchingBehaviour } from '../../../_shared/constants/searchMatchingBehaviour.enum';
import { type MapSearchFilterBy } from '../../../map/filter/mapSearchFilterBy.enum';
import {
  MAP_SETTINGS_SEARCH_SET_FILTER_BY, MAP_SETTINGS_SEARCH_SET_SEARCH_SETTINGS,
} from './mapSettingsSearch.actionTypes';

export const setMapSearchSettings = (selectedMatchingBehaviour: SearchMatchingBehaviour, shouldMatchExactWords: boolean, shouldZoomOnMatches: boolean) => ({
  type: MAP_SETTINGS_SEARCH_SET_SEARCH_SETTINGS,
  payload: {
    selectedMatchingBehaviour,
    shouldMatchExactWords,
    shouldZoomOnMatches,
  },
}) as const;

export const setFilterBy = (filterBy: MapSearchFilterBy) => ({
  type: MAP_SETTINGS_SEARCH_SET_FILTER_BY,
  payload: {
    filterBy,
  },
}) as const;
