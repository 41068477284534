import { type LatLng } from '../../../../_shared/types/latLng';
import {
  MAP_SETTINGS_DISTANCE_CALCULATOR_DELETE_DC_INSTANCE, MAP_SETTINGS_DISTANCE_CALCULATOR_OUTLINE_MOVE, MAP_SETTINGS_DISTANCE_CALCULATOR_SUBMIT_DC_INSTANCE,
} from './mapSettingsDistanceCalculator.actionTypes';
import { type DistanceCalculatorInstance } from './mapSettingsDistanceCalculator.state';

export const submitDistanceCalculatorInstance = (instance: DistanceCalculatorInstance) => ({
  type: MAP_SETTINGS_DISTANCE_CALCULATOR_SUBMIT_DC_INSTANCE,
  payload: {
    instance,
  },
}) as const;

export const deleteDistanceCalculatorInstance = (instanceId: Uuid) => ({
  type: MAP_SETTINGS_DISTANCE_CALCULATOR_DELETE_DC_INSTANCE,
  payload: {
    instanceId,
  },
}) as const;

export const moveDistanceCalculatorOutline = (instanceId: Uuid, outlineId: Uuid, position: LatLng) => ({
  type: MAP_SETTINGS_DISTANCE_CALCULATOR_OUTLINE_MOVE,
  payload: {
    instanceId,
    outlineId,
    position,
  },
}) as const;
