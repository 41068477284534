import {
  css, type Interpolation,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type ReactNode, useCallback,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  type TooltipBehavior, TooltipComponent, type TooltipOffset, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { type Theme } from '~/_shared/themes/theme.model';
import { OverlayLoaderComponent } from '../../../components/overlay/overlayLoader.component';

export const SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD = 2;
const defaultBorderRadius = 8;

const activeButtonStyle = ({ isActive }: { isActive?: boolean }) => (theme: Theme) => ({
  backgroundColor: theme.iconColors.backgroundQuaternaryHover,
  border: `2px solid ${theme.borderColors.activeItem}`,
  boxShadow: `inset ${theme.backgroundColors.secondary} 0px 0px 6px ${SQUARE_ICON_BUTTON_HOVER_SHADOW_SPREAD}px`,
  color: theme.iconColors.quaternary,
  ...(isActive && {
    border: 'none',
    boxShadow: `0 0 0 1px ${theme.borderColors.primary}`,
  }),
});

const rootStyle = ({ size, useHover, borderRadius, isActive }: {
  borderRadius?: string | number;
  isActive?: boolean;
  size: number;
  useHover?: boolean;
}) => (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.quinary,
  borderRadius: borderRadius ?? defaultBorderRadius,
  height: size,
  width: size,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 0 0 1px ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  color: theme.iconColors.highContrast,
  fontWeight: 900,
  ...(isActive && {
    backgroundColor: theme.backgroundColors.quaternary,
    color: theme.iconColors.quaternary,
  }),

  '&:active': useHover ? {
    ...activeButtonStyle({ isActive }),
  } : undefined,

  '@media (hover: hover)': {
    '&:hover': {
      ...activeButtonStyle({ isActive }),
    },
  },
});

const loaderStyle = ({ borderRadius, isActive }: {
  borderRadius?: string;
  isActive?: boolean;
}) => (theme: Theme) => css({
  borderRadius: borderRadius ?? defaultBorderRadius,
  ...(isActive ? {
    boxShadow: `0 0 0 1px ${theme.borderColors.primary}`,
    top: -2,
    left: -2,
    boxSizing: 'border-box',
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
  } : undefined),
});

export type SquareIconButtonProps = Readonly<{
  className?: string;
  icon: IconProp;
  isActive?: boolean;
  tooltip?: string;
  tooltipContentStyle?: Interpolation;
  tooltipPlacement?: TooltipPlacement;
  tooltipOffset?: TooltipOffset;
  tooltipBehavior?: TooltipBehavior;
  buttonSize?: number;
  useDefaultHoverStyles?: boolean;
  borderRadius?: string;
  isDisabled?: boolean;
  isLoading?: boolean;

  onClick: () => void;
  renderOverlay?: (css: Interpolation) => ReactNode;
}>;

const defaultOverlayRenderer: SquareIconButtonProps['renderOverlay'] = (style) => (
  <OverlayLoaderComponent
    css={style}
    loaderSize={21}
  />
);

export const SquareIconButtonComponent = ({
  borderRadius,
  buttonSize = 32,
  className,
  icon,
  isActive = false,
  isDisabled,
  isLoading,
  onClick,
  renderOverlay = defaultOverlayRenderer,
  tooltip,
  tooltipBehavior,
  tooltipContentStyle,
  tooltipOffset,
  tooltipPlacement = TooltipPlacement.Left,
  useDefaultHoverStyles,
  ...restProps
}: SquareIconButtonProps) => {
  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onClick();
    }
  }, [isDisabled, onClick]);

  return (
    <TooltipComponent
      behavior={tooltipBehavior}
      tooltipContent={tooltip}
      placement={tooltipPlacement}
      contentStyle={tooltipContentStyle}
      offset={tooltipOffset}
    >
      <div
        className={className}
        css={rootStyle({
          borderRadius,
          isActive,
          size: buttonSize,
          useHover: !isDisabled ? useDefaultHoverStyles : undefined,
        })}
        onClick={handleClick}
        {...restProps}
      >
        {isLoading && renderOverlay(loaderStyle({ borderRadius, isActive }))}
        <FontAwesomeIcon icon={icon} />
      </div>
    </TooltipComponent>
  );
};
