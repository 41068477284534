import { type SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type SpreadsheetColumnServerModel } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import {
  apiDelete, apiPost, apiPut,
} from '../../_shared/utils/api/api.helpers';
import { type MetricsDataAction } from '../../_shared/utils/metric/metrics.types';
import { isTextEmpty } from '../../_shared/utils/text/text.helpers';
import { type BoundaryEtagServerModal } from '../../boundary/etag/etag.types';
import { type BoundaryFillType } from '../../boundary/settings/boundaryFill.type';
import { type BoundaryGroupDemographicServerModel } from '../boundaryGroups/boundaryGroups.repository';
import {
  type BoundaryTerritoryType, type CalculateBucketFunction,
} from './boundaryTerritoryGroup.type';
import { createTerritoryBoundaryGroupFromResponse } from './boundaryTerritoryGroups.factory';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup, type BoundaryTerritoryStyle,
} from './boundaryTerritoryGroups.state';

export type CustomBoundaryTerritoryResponse = {
  boundary_territory_id: string;
  display_name: string;
  bucket: string;
  translate: boolean;
  custom: true;
  style: {
    color: string;
    opacity: number;
  };
};

export type GeneratedBoundaryTerritoryResponse = {
  boundary_territory_id: string;
  display_name: string;
  translate: boolean;
  bucket: string;
  custom: false;
  style: {
    color: string;
    opacity: number;
  };
  is_decimal: boolean;
};

export type BoundaryTerritoryRequest = {
  display_name: string;
  boundary_territory_id: string;
  translate: boolean;
  bucket?: string;
  style: {
    color: string;
    opacity: number;
  };
  custom: boolean;
  is_decimal?: boolean;
};

export type BoundaryTerritoryResponse = CustomBoundaryTerritoryResponse | GeneratedBoundaryTerritoryResponse;

type BoundaryTerritoryStyleGradientColors = {
  low: {
    color: string;
  };
  medium: {
    color: string;
  };
  high: {
    color: string;
  };
};

export type BoundaryTerritoryGroupFilterDefinition = {
  boundary_group_id: number;
  boundary_territory_group_id?: number;
  matchings: BoundaryTerritoryGroupMatchingsRequest;
  settings: BoundaryTerritoryGroupSettingsRequest;
};

export type BoundaryTerritoryStyleResponse = {
  line_width: number;
  line_color: string;
  gradient_colors: BoundaryTerritoryStyleGradientColors;
};

type SpreadsheetColumnMetricServerModel = {
  type: SelectedDataType.SpreadsheetColumn;
  spreadsheetColumn: SpreadsheetColumnServerModel;
  dataAction: MetricsDataAction;
};

type DemographicMetricServerModel = {
  type: SelectedDataType.Demographic;
  demographic: BoundaryGroupDemographicServerModel;
};

export type MetricServerModel = SpreadsheetColumnMetricServerModel | DemographicMetricServerModel;

type BoundaryTerritoryGroupSettingsModel = {
  allow_decimal_ranges: boolean;
  boundary_territory_type: BoundaryTerritoryType;
  bucket_column_id?: string | null;
  bucket_type?: BoundaryBucketType | null;
  demographic_id?: string | null;
  metrics?: ReadonlyArray<MetricServerModel>;
  ranges_count?: number;
  style: BoundaryTerritoryStyleResponse;
  boundary_style?: ReadonlyArray<{ boundaryId: number; style: BoundaryTerritoryStyle }>;
  calculate_bucket_function: CalculateBucketFunction | null;
  hide_labels?: boolean;
  hide_without_data?: boolean;
  ignore_filters?: boolean;
  selection_edit_preferred_boundary_group?: { id: number };
};

export type BoundaryTerritoryGroupSettingsResponse = BoundaryTerritoryGroupSettingsModel & {
  boundaries_as_territories: boolean;
  boundary_fill_type?: BoundaryFillType ;
  boundary_territories: BoundaryTerritoryResponse[];
  calculate_bucket_function: string;
  demographic_id: string | null;
  display_name: string | null;
  empty_is_zero: null;
};

export type BoundaryTerritoryGroupSettingsRequest = BoundaryTerritoryGroupSettingsModel & {
  boundaries_as_territories?: boolean;
  boundary_fill_type: BoundaryFillType;
  boundary_territories: ReadonlyArray<BoundaryTerritoryRequest>;
};

export enum BoundaryMatchingType {
  Geometry = 'geometry',
  Equal = 'equal',
  Multiequal = 'multiequal',
  Zips = 'zips'
}

export type BoundaryTerritoryGroupMatchColumnResponse = {
  column_id: string;
  original_column_id?: string;
  category: BoundaryMatchingCategory;
};

export type BoundaryTerritoryGroupMatchingsResponse = {
  matching_type: BoundaryMatchingType;
  match_columns: BoundaryTerritoryGroupMatchColumnResponse[];
};

export type BoundaryTerritoryGroupCreateRequest = {
  boundary_group_id: number;
  map_id: number;
  matchings: BoundaryTerritoryGroupMatchingsRequest;
  settings: BoundaryTerritoryGroupSettingsRequest;
};

export type BoundaryTerritoryGroupCloneRequest = {
  boundary_territory_group_id: number;
  new_boundary_territory_group_name: string;
  map_id: number;
};

export type BoundaryTerritoryGroupConvertRequest = BoundaryTerritoryGroupCreateRequest & {
  boundary_territory_group_id?: number;
};

export type BoundaryTerritoryGroupModelResponse = {
  boundary_territory_group_id?: number;
  archived_at: string | null;
  boundary_group_id: number;
  map_id: number;
  matchings: BoundaryTerritoryGroupMatchingsResponse;
  settings: BoundaryTerritoryGroupSettingsResponse;
  etag?: BoundaryEtagServerModal;
};

export type BoundaryTerritoryGroupResponse = BoundaryTerritoryGroupModelResponse & {
  boundary_territory_group_id: number;
};

export type BoundaryTerritoryGroupsResponse = {
  boundary_territory_groups: BoundaryTerritoryGroupResponse[];
};

type BoundaryTerritoryGroupQueryRequest = {
  map_id: number;
  include_archived?: boolean;
};

export type BoundaryMatchingCategory = 'city' | 'state' | 'zip' | 'country' | 'county' | 'congressional_district' | 'census_tract' | 'block_group';

export type BoundaryBucketType = 'value' | 'value-range' | 'percentage-range';

export type BoundaryTerritoryGroupMatchingsRequest = {
  matching_type: BoundaryMatchingType;
  match_columns?: BoundaryTerritoryGroupMatchColumnResponse[];
};

export type BoundaryTerritoryGroupUpdateRequest = BoundaryTerritoryGroupCreateRequest & {
  boundary_territory_group_id: number;
  etag?: BoundaryEtagServerModal;
};

export const requestTerritoriesRefresh = (
  boundaryTerritories: ReadonlyArray<BoundaryTerritory>,
  originalBoundaryTerritoryGroupTerritories: ReadonlyArray<BoundaryTerritory>,
): ReadonlyArray<BoundaryTerritory> => {
  return boundaryTerritories
    .filter(territory => territory.custom)
    .map(bt => {
      let displayName = bt.displayName;
      if (isTextEmpty(displayName)) {
        displayName = originalBoundaryTerritoryGroupTerritories.find(
          item => item.boundaryTerritoryId === bt.boundaryTerritoryId
        )?.displayName ?? displayName;
      }

      return {
        ...bt,
        displayName,
      };
    });
};

export const getBoundaryTerritoryGroups = (
  clientId: number,
  request: BoundaryTerritoryGroupQueryRequest
): Promise<BoundaryTerritoryGroupsResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory-group/query`;

  return apiPost(requestUrl, {
    query: request,
  });
};

export const createBoundaryTerritoryGroup = (
  clientId: number,
  request: BoundaryTerritoryGroupCreateRequest
): Promise<BoundaryTerritoryGroupResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory-group`;

  return apiPost(requestUrl, request);
};

export const updateBoundaryTerritoryGroup = (
  clientId: number,
  boundaryTerritoryGroupId: number,
  request: BoundaryTerritoryGroupUpdateRequest
): Promise<BoundaryTerritoryGroup> => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory-group/${boundaryTerritoryGroupId}`;

  const headers: Record<string, string> = {};

  if (request.etag?.timestamp) {
    headers['If-Match'] = request.etag.timestamp.toString();
  }

  return apiPut<BoundaryTerritoryGroupResponse>(requestUrl, request, {
    headers,
  })
    .then(response => createTerritoryBoundaryGroupFromResponse(response));
};

export const removeBoundaryTerritoryGroup = (clientId: number, boundaryTerritoryGroupsId: number) => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory-group/${boundaryTerritoryGroupsId}`;

  return apiDelete(requestUrl);
};

export type UpdateBoundaryTerritoryAssignmentsRequest = {
  boundary_territory_id: string;
  column_id?: string;
  set?: {
    boundary_id: number;
  }[];
  remove?: number[];
};

export const updateBoundaryTerritoryAssignments = (clientId: number, boundaryTerritoryGroupId: number, requests: ReadonlyArray<UpdateBoundaryTerritoryAssignmentsRequest>) => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory-group/${boundaryTerritoryGroupId}/boundary-territory-assignment`;
  const requestParams = {
    params: requests,
  };

  return apiPut(requestUrl, requestParams);
};
