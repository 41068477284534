import { type FC } from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorPageWrapperComponent } from '../wrapper/appErrorPageWrapper.component';

export const AppErrorPageNotFoundComponent: FC = () => {
  const [t] = useTranslation();

  return (
    <AppErrorPageWrapperComponent>
      {t('The page you are looking for doesn\'t exist.')}
    </AppErrorPageWrapperComponent>
  );
};
