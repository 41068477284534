import { type FC } from 'react';
import { useIsMobileScreenSelector } from '../../store/frontendState/deviceInfo/deviceInfo.selector';
import { usePasswordReset } from '../passwordResetPage/usePasswordReset';
import { ForgottenPasswordPageComponent } from './forgottenPasswordPage.component';

export const ForgottenPasswordPageContainer: FC = () => {
  const isMobileScreen = useIsMobileScreenSelector();
  const { isPasswordResetEmailSending, passwordResetEmailError, sendPasswordResetEmail } = usePasswordReset();

  return (
    <ForgottenPasswordPageComponent
      isLoading={isPasswordResetEmailSending}
      onSubmit={sendPasswordResetEmail}
      errorMessage={passwordResetEmailError}
      isMobileScreen={isMobileScreen}
    />
  );
};
