import { css } from '@emotion/react';
import { ACCORDION_HEADER_SIZE_LARGE } from '~/_shared/baseComponents/accordion';
import { UNDERBAR_HEADER_HEIGHT } from '~/mobileTopBar/mobileTopBar.styles';
import { topBarHeaderHeight } from '~/topBar/desktopTopBar.styles';
import { type ThemeProps } from '../../types/themeProps';

const HEADER_HEIGHT = ACCORDION_HEADER_SIZE_LARGE;

export const mainStyles = ({ theme }: ThemeProps) => css({
  height: `calc(100svh - ${topBarHeaderHeight}px)`,
  width: '100%',
  backgroundColor: theme.backgroundColors.primary,
  borderRight: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
});

export const headerStyles = ({ theme }: ThemeProps) => css({
  display: 'flex',
  fontSize: 20,
  width: 'calc(100% - 16px)',
  height: HEADER_HEIGHT,
  backgroundColor: theme.backgroundColors.secondaryAlternative,
  cursor: 'default',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 8px',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

export const leftHeaderStyles = css({
  alignItems: 'center',
  display: 'flex',
});

export const titleStyles = ({ needsIndent }: {needsIndent: boolean}) => css({
  marginRight: needsIndent ? 10 : 0,
  textTransform: 'uppercase',
});

export const rightHeaderStyles = css({
  fontSize: 16,
  flexShrink: 0,
});

export const iconsStyles = css({
  marginRight: 15,
  verticalAlign: 'middle',
});

export const interactiveIconStyles = css(iconsStyles, {
  cursor: 'pointer',
});

export const childrenStyles = ({ theme, isSpaceReduced }: ThemeProps<{isSpaceReduced: boolean}>) => {
  const topOffset = HEADER_HEIGHT + (isSpaceReduced ? UNDERBAR_HEADER_HEIGHT : 0);

  return css({
    height: `calc(100% - ${topOffset}px)`,
    backgroundColor: theme.backgroundColors.primary,
    position: 'relative',
    overflowY: 'auto',
  });
};

export const titleStyle = css({
  fontSize: 18,
});
