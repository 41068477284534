import { isArray } from '../collections/collections';

const getFilteredKeys = (keys: ReadonlyArray<string>, skipKeys?: ReadonlyArray<string>): ReadonlyArray<string> => {
  if (skipKeys && skipKeys.length > 0) {
    return keys.filter((key: string) => !skipKeys.includes(key));
  }

  return keys;
};

export const areShallowEqual = <TObject>(
  a: TObject | null | undefined,
  b: TObject | null | undefined,
  { skipKeys = [], levels = 1 }: { skipKeys?: ReadonlyArray<string>; levels?: number } = {}
): boolean => {
  if (a === b) {
    // handles both same references/values and falsy ones
    return true;
  }

  if ((!a && !b) || !a || !b) {
    return false;
  }

  if (levels === 0) {
    return false;
  }

  if (isArray(a)) {
    if (!isArray(b)) {
      return false;
    }

    return a.length === b.length && a.every((v, i) => areShallowEqual(v, b[i], { skipKeys, levels: levels - 1 }));
  }
  else {
    const aKeys = getFilteredKeys(Object.keys(a), skipKeys);
    const bKeys = getFilteredKeys(Object.keys(b), skipKeys);

    if (aKeys.length !== bKeys.length || aKeys.length === 0) {
    // handles both different set of properties and non-falsy primitive values
      return false;
    }

    return aKeys.every((key: string) => {
      return areShallowEqual((a as any)[key], (b as any)[key], { skipKeys, levels: levels - 1 });
    });
  }
};
