import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  buttonStyle, ButtonStyles,
} from '~/_shared/baseComponents/buttons/button/button.styles';
import {
  ButtonSize, ButtonStyle,
} from '~/_shared/baseComponents/buttons/button/button.types';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { useConfirmationModal } from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { MAPTIVE_SUPPORT_EMAIL } from '~/_shared/constants/maptive';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';

const containerStyle = css({
  width: '100%',
  position: 'relative',
});

const textContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

const messageStyle = (theme: Theme) => css({
  marginTop: 50,
  color: theme.textColors.primary,
  textAlign: 'left',
});

const submitButtonStyle = css({
  cursor: 'default',
  textDecoration: 'none',
});

const buttonBarStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type UnderPaidClientInvitationProps = {
  client: string;
  rejectInvitation: () => void;
};

export const UnderPaidClientInvitation: FC<UnderPaidClientInvitationProps> = ({
  client,
  rejectInvitation,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const onDeclineClick = () => {
    openConfirmationModal({
      title: t('Decline invitation'),
      text: t('Are you sure you want to decline the invitation?'),
      onConfirm: () => {
        closeConfirmationModal();
        rejectInvitation();
      },
      onCancel: closeConfirmationModal,
      isConfirmButtonDestructive: true,
      showLoaderAfterConfirmClick: true,
      confirmCaption: t('Decline'),
    });
  };

  return (
    <div css={containerStyle}>
      <div css={textContainerStyle}>
        <p css={messageStyle(theme)}>
          {t('inviteeInvitation.invitationForExistingAccountNeedTransfer', { client })}
        </p>
      </div>

      <div css={buttonBarStyle}>
        <ButtonComponent
          buttonStyle={ButtonStyle.Danger}
          size={ButtonSize.Large}
          text={t('Decline')}
          onClick={onDeclineClick}
        />
        <a
          css={[buttonStyle({ btnStyle: ButtonStyles(theme)[RoundButtonStyle.Primary], theme, size: ButtonSize.Large }), submitButtonStyle]}
          href={`mailto:${MAPTIVE_SUPPORT_EMAIL}?subject=Resolve%20Invitation&body=Maptive%20Customer%20Support,%0D%0AI%20would%20like%20to%20request%20your%20help%20with%20transferring%20under%20a%20different%20Maptive%20account:%20${client}.`}
          target="_blank"
          rel="noreferrer"
        >
          {t('Contact Support')}
        </a>
      </div>
    </div>
  );
};
