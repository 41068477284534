import { type DrawingCalloutSettingsState } from '../../store/mapSettings/drawing/settings/callout/drawingCalloutSettings.state';
import {
  DRAWING_TOOL_SLIDER_BORDER_RADIUS_RANGE,
  DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE, DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createCalloutDrawingGlobalOptionsFromSettings = (
  settings: DrawingCalloutSettingsState
) => createCalloutDrawingOptionsFromSettings({ settings });

export const createSelectedCalloutDrawingOptionsFromSettings = (settings: DrawingCalloutSettingsState, sizePerPixelRatio: number) =>
  createCalloutDrawingOptionsFromSettings({ settings, sizePerPixelRatio });

type CreateCalloutOptionsArguments = {
  settings: DrawingCalloutSettingsState;
  sizePerPixelRatio?: number;
};

const createCalloutDrawingOptionsFromSettings = (
  { settings, sizePerPixelRatio }: CreateCalloutOptionsArguments
): Partial<DrawingToolOptions> => {
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    booleanOptions: {
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    sliderOptions: {
      borderRadius: {
        value: settings.borderRadius,
        range: DRAWING_TOOL_SLIDER_BORDER_RADIUS_RANGE,
      },
      strokeOpacity: {
        value: settings.strokeOpacity,
      },
      fillOpacity: {
        value: settings.fillOpacity,
      },
      strokeWeight: {
        value: settings.strokeWeight,
        range: DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
        suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
      },
      ...!useScaledSlider ? {
        fontSize: {
          value: settings.fontSize,
          range: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
        },
      } : undefined,
    },
    ...useScaledSlider ? {
      scaledSliderOptions: {
        fontSizeOnCurrentMapZoom: {
          value: settings.fontSize,
          displayValue: Math.round((settings.fontSize) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX,
          ensureValueInRange: true,
        },
      },
    } : undefined,
    colorOptions: {
      strokeColor: settings.strokeColor,
      fillColor: settings.fillColor,
      fontColor: settings.fontColor,
    },
  };
};

export const createCalloutDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingCalloutSettingsState
): DrawingCalloutSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    borderRadius: toolOptions.sliderOptions?.borderRadius?.value ?? currentSettings.borderRadius,
    fillOpacity: toolOptions.sliderOptions?.fillOpacity?.value ?? currentSettings.fillOpacity,
    strokeOpacity: toolOptions.sliderOptions?.strokeOpacity?.value ?? currentSettings.strokeOpacity,
    strokeWeight: toolOptions.sliderOptions?.strokeWeight?.value ?? currentSettings.strokeWeight,
    fontSize: toolOptions.sliderOptions?.fontSize?.value ?? toolOptions.scaledSliderOptions?.fontSizeOnCurrentMapZoom?.value ?? currentSettings.fontSize,
    strokeColor: toolOptions.colorOptions?.strokeColor ?? currentSettings.strokeColor,
    fillColor: toolOptions.colorOptions?.fillColor ?? currentSettings.fillColor,
    fontColor: toolOptions.colorOptions?.fontColor ?? currentSettings.fontColor,
  };
};
