import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { ZOOM_TO_MARKER_MIN_ZOOM_LEVEL } from '~/_shared/utils/markers/markers.constants';
import { mapComponentSetZoom } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useFirstZoomLevelSelector } from '~/store/frontendState/processing/processing.selectors';
import { ZoomControlsComponent } from './zoomControls.component';

export const ZoomControlsContainer: FC = () => {
  const dispatch = useDispatch();
  const currentMapZoom = useMapComponentZoomSelector();
  const initialZoomLevel = useFirstZoomLevelSelector();

  const showZoomToInitial = currentMapZoom !== null && initialZoomLevel !== null
    && currentMapZoom > initialZoomLevel
    && currentMapZoom >= ZOOM_TO_MARKER_MIN_ZOOM_LEVEL;

  const handleZoomClick = useCallback((difference: number) => {
    if (currentMapZoom !== null) {
      dispatch(mapComponentSetZoom(currentMapZoom + difference));
    }
  }, [dispatch, currentMapZoom]);

  const handleZoomToInitial = useCallback(() => {
    if (initialZoomLevel !== null) {
      dispatch(mapComponentSetZoom(initialZoomLevel));
    }
  }, [dispatch, initialZoomLevel]);

  return (
    <ZoomControlsComponent
      onZoomClick={handleZoomClick}
      onZoomToInitial={showZoomToInitial ? handleZoomToInitial : undefined}
    />
  );
};
