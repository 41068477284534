import { MapStylesSliderSettingsName } from '../baseMap.enums';
import { labelSliderOptions } from './baseMapLabels.constants';
import { landmarksSliderOptions } from './baseMapLandmarks.constants';
import { roadsSliderOptions } from './baseMapRoads.constants';
import { type BaseMapStandardSliders } from './baseMapStandardSliders.types';

export const baseMapStandardSliderOptions: BaseMapStandardSliders = {
  [MapStylesSliderSettingsName.labels]: labelSliderOptions,
  [MapStylesSliderSettingsName.landmarks]: landmarksSliderOptions,
  [MapStylesSliderSettingsName.roads]: roadsSliderOptions,
};
