import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

const moveMarkersIsActiveSelector = (state: AppState) => state.frontendState.moveMarkers.isActive;
export const useMoveMarkersIsActiveSelector = () => useSelector(moveMarkersIsActiveSelector);

export const moveMarkersIsModalVisible = (state: AppState) => state.frontendState.moveMarkers.isModalOpen;
export const useMoveMarkersIsModalVisible = () => useSelector(moveMarkersIsModalVisible);

const moveMarkersMarkersPositionsSelector = (state: AppState) => state.frontendState.moveMarkers.markersPositions;
export const useMoveMarkersMarkersPositionsSelector = () => useSelector(moveMarkersMarkersPositionsSelector);
