import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useMarkerEntityOffsets } from '~/_shared/utils/markers/useMarkerEntityOffsets.hook';
import { ActiveMarkerBouncingIndicatorContainer } from './activeMarkerBouncingIndicator.container';
import { ActiveMarkerCircleIndicatorContainer } from './activeMarkerCircleIndicator.container';
import {
  DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE, DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED,
  DEFAULT_INDICATOR_COLOR,
  isLottieActiveMarkerIndicator,
} from './activeMarkerIndicator.constants';
import {
  type ActiveMarkerIndicator, ActiveMarkerIndicators,
} from './activeMarkerIndicator.types';
import { ActiveMarkerLottieIndicatorComponent } from './activeMarkerLottieIndicator.component';

export const indicatorStyles = (props: {
  color: string;
  dimensions: QuadrilateralDimensions;
  svg: string;
}) => css({
  position: 'absolute',
  backgroundColor: guaranteeHash(props.color),
  height: `${props.dimensions.height}px`,
  maskImage: `url(${CSS.escape(props.svg)})`,
  maskRepeat: 'no-repeat',
  maskSize: `${props.dimensions.width}px ${props.dimensions.height}px`,
  width: `${props.dimensions.width}px`,
});

const wrapperStyle = css({
  pointerEvents: 'none',
});

type ActiveMarkerIndicatorComponentProps = {
  applyLabelAboveOffset?: boolean;
  color?: string;
  customizedLabelOffsetRowId?: SpreadsheetRowId;
  currentActiveIndicator: ActiveMarkerIndicator;
  markerVisualSettings?: MarkerSettings;
  size?: number;
  speed?: number;
};

export const ActiveMarkerIndicatorComponent: FC<ActiveMarkerIndicatorComponentProps> = (props) => {
  const markerIndicatorOffsetsProps = useMemo(() => ({
    markerVisualSettings: props.markerVisualSettings,
    applyLabelAboveOffset: props.applyLabelAboveOffset,
    customizedLabelOffsetRowId: props.customizedLabelOffsetRowId,
  }), [props.applyLabelAboveOffset, props.customizedLabelOffsetRowId, props.markerVisualSettings]);

  const markerIndicatorOffsets = useMarkerEntityOffsets(markerIndicatorOffsetsProps);

  const ActiveIndicator = useMemo(() => {
    if (isLottieActiveMarkerIndicator(props.currentActiveIndicator)) {
      return (
        <ActiveMarkerLottieIndicatorComponent
          indicator={props.currentActiveIndicator}
          color={props.color || DEFAULT_INDICATOR_COLOR}
          offsets={markerIndicatorOffsets}
          size={props.size || DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE}
          speed={props.speed ?? DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED}
        />
      );
    }

    switch (props.currentActiveIndicator) {
      case ActiveMarkerIndicators.basicCircleArrow:
      case ActiveMarkerIndicators.chevronArrow:
      case ActiveMarkerIndicators.fatCircleArrow:
      case ActiveMarkerIndicators.neonArrow:
      case ActiveMarkerIndicators.simpleArrow:
      case ActiveMarkerIndicators.slimCircleArrow:
      case ActiveMarkerIndicators.tripleNeonArrow:
        return (
          <ActiveMarkerBouncingIndicatorContainer
            bouncingIndicatorType={props.currentActiveIndicator}
            color={props.color || DEFAULT_INDICATOR_COLOR}
            markerIndicatorOffsets={markerIndicatorOffsets}
            size={props.size || DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE}
            speed={props.speed ?? DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED}
          />
        );

      case ActiveMarkerIndicators.radarCircle:
        return (
          <ActiveMarkerCircleIndicatorContainer
            circleIndicatorType={props.currentActiveIndicator}
            color={props.color || DEFAULT_INDICATOR_COLOR}
            markerIndicatorOffsets={markerIndicatorOffsets}
            size={props.size || DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE}
            speed={props.speed ?? DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED}
          />
        );

      default:
        return (
          <ActiveMarkerBouncingIndicatorContainer
            bouncingIndicatorType={ActiveMarkerIndicators.chevronArrow}
            color={props.color || DEFAULT_INDICATOR_COLOR}
            markerIndicatorOffsets={markerIndicatorOffsets}
            size={props.size || DEFAULT_ACTIVE_MARKER_INDICATOR_SIZE}
            speed={props.speed ?? DEFAULT_ACTIVE_MARKER_INDICATOR_SPEED}
          />
        );
    }
  }, [markerIndicatorOffsets, props.color, props.currentActiveIndicator, props.size, props.speed]);

  return (
    <div css={wrapperStyle}>
      {ActiveIndicator}
    </div>
  );
};
