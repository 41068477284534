import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  useDragLayer, type XYCoord,
} from 'react-dnd';
import { DndType } from '../../../_shared/constants/dndType.enum';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { getStylesForDraggedItem } from '../../../_shared/utils/dnd/dnd.helpers';
import {
  BASIC_PANEL_WIDTH,
  EXTENDED_PANEL_WIDTH,
} from '../../../sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import { type ColumnStyleSettingsData } from '../panelSettings.types';
import { BubbleItemWithIconContainer } from './bubbleItemWithIcon.container';

const positionStyle = (props: {
  currentOffset: XYCoord | null;
  initialOffset: XYCoord | null;
}) => css({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
}, getStylesForDraggedItem(props.initialOffset, props.currentOffset));

const draggableStyle = ({ theme, width }: ThemeProps<{width: number}>) => css({
  backgroundColor: theme.backgroundColors.primaryDragged,
  boxSizing: 'border-box',
  margin: '8px 0px',
  width: `${width}px`,
});

type BubbleItemDragLayerProps = {
  bulkStyles: ColumnStyleSettingsData;
};

export const BubbleItemDragLayerComponent: FC<BubbleItemDragLayerProps> = (props) => {
  const theme = useTheme();
  const dragProps = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!dragProps.isDragging || dragProps.itemType !== DndType.LocalizationBasicItem && dragProps.itemType !== DndType.LocalizationContactItem) {
    return null;
  }

  const isActive = dragProps.item.itemData.isActive;
  const width = isActive ? BASIC_PANEL_WIDTH : EXTENDED_PANEL_WIDTH - 20;
  const item = dragProps.item;

  return (
    <div
      css={positionStyle({
        currentOffset: dragProps.currentOffset,
        initialOffset: dragProps.initialOffset,
      })}
    >
      <div css={draggableStyle({ theme, width })} >
        <BubbleItemWithIconContainer
          item={item}
          showDragIcon
          isDraggable
          showVisibilityIcon={false}
          bulkStyles={props.bulkStyles}
        />
      </div>
    </div>
  );
};
