import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  useLocation, useNavigate,
} from 'react-router-dom';
import { useErrorPageErrorSelector } from '~/store/errorPage/errorPage.selectors';
import { useTranslation } from '../../_shared/utils/hooks';
import { useMapIdSelector } from '../../store/selectors/useMapIdSelector';
import { NavigationComponent } from './navigation.component';
import {
  getActiveNavigationItem, getNavigationItems, type NavigationItem,
} from './navigation.helpers';

export const NavigationContainer: FC = () => {
  const isError = !!useErrorPageErrorSelector();
  const navigate = useNavigate();
  const location = useLocation();
  const mapId = useMapIdSelector();
  const [t] = useTranslation();

  const navigationItems = useMemo(
    () => getNavigationItems(!isError ? mapId : null, t),
    [isError, mapId, t]
  );

  const onSelectNavigationItem = useCallback((itemPath: string) =>
    navigate(itemPath),
  [navigate]);

  const activeNavigationItem: NavigationItem | undefined = useMemo(
    () => getActiveNavigationItem(location.pathname, navigationItems),
    [location.pathname, navigationItems]
  );

  const activeNavigationItemIndex = useMemo(() =>
    navigationItems.findIndex(item => item.path === activeNavigationItem?.path),
  [activeNavigationItem, navigationItems]
  );

  return (
    <NavigationComponent
      activeNavigationItemIndex={activeNavigationItemIndex}
      navigationToMapAllowed={!!mapId}
      onSelectNavigationItem={onSelectNavigationItem}
      navigationItems={navigationItems}
    />
  );
};
