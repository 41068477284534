import {
  type FC, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { getRandomColor } from '~/_shared/constants/grouping.constants';
import {
  createColorWithOpacity, guaranteeHash,
} from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { createUuid } from '../../_shared/utils/createUuid';
import { useTranslation } from '../../_shared/utils/hooks';
import { type ModalProps } from '../../modal/modalType.enum';
import { boundaryTerritoryGroupCreateCustomTerritory } from '../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { boundaryFillDefaultOpacity } from '../settings/defaultBoundarySettings';
import { BoundaryItemEditSettingsComponent } from './boundaryItemEditSettings.component';

export type BoundaryTerritoryCreateContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  onCreateBoundaryTerritorySuccess?: (newBoundaryTerritoryId: string) => void;
}>;

export const BoundaryTerritoryCreateContainer: FC<BoundaryTerritoryCreateContainerProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = ({ displayName, fillColor }: {displayName: string; fillColor: ColorResult}) => {
    const newTerritoryId = createUuid();

    setIsLoading(true);
    dispatch(boundaryTerritoryGroupCreateCustomTerritory(
      props.boundaryTerritoryGroupId, newTerritoryId, displayName, guaranteeHash(fillColor.hex),
      fillColor.rgb.a ? fillColor.rgb.a * 100 : 100, (newBoundaryTerritoryId) => {
        setIsLoading(false);
        props.onCreateBoundaryTerritorySuccess?.(newBoundaryTerritoryId);
        props.onClose();
      },
    ));
  };

  return (
    <BoundaryItemEditSettingsComponent
      {...props}
      isLoading={isLoading}
      heading={t('Create Fill Group')}
      displayName={t('Untitled group')}
      initialFillColor={createColorWithOpacity(getRandomColor(), boundaryFillDefaultOpacity)}
      onSubmit={onSubmit}
      showCreateAnotherButton={false}
    />
  );
};
