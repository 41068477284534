import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { useIsComponentMountedRef } from '../_shared/utils/hooks/useIsComponentMountedRef';
import {
  removeSpreadsheetRows,
  type SpreadsheetTableRemoveRowsRequest,
} from '../spreadsheet/spreadsheet.repository';

export const useRemoveSpreadsheetData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isMountedRef = useIsComponentMountedRef();
  const dispatch = useDispatch();

  const removeSpreadsheetData = (
    clientId: number,
    spreadsheetId: number,
    request: SpreadsheetTableRemoveRowsRequest,
    onSuccess: () => void
  ) => {
    setIsLoading(true);
    setIsError(false);

    return removeSpreadsheetRows(clientId, spreadsheetId, request)
      .then(() => {
        dispatch(spreadsheetResetStateAndRefetchData());
        onSuccess();
      })
      .catch((e) => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        throw e;
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    isError,
    removeSpreadsheetData,
  };
};
