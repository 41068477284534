import type { GoogleApiScope } from '~/_shared/utils/googleApi/googleApi.helpers';
import {
  type GoogleApiOauthStatus, type LimitsResponse, type UsageStatsResponse, type UserDataResponse,
} from './repository/userData.repository';
import { type UserGetDataSuccessPayload } from './userData.actionCreators';
import { createCurrentClientFromResponse } from './userData.factory';
import {
  type UserDataLimits, type UserDataStateUsage,
} from './userData.state';

export const userDataServerToClientModel = (serverModel: UserDataResponse): UserGetDataSuccessPayload => {
  const clientId = serverModel.data.clients?.[0]?.id ?? null;

  let googleApiOauth: GoogleApiOauthStatus | null = null;
  const serverGoogleApiOauth = serverModel.data.google_api_oauth;
  if (serverGoogleApiOauth && serverGoogleApiOauth.available_google_accounts && serverGoogleApiOauth.currently_used_google_acc_id) {
    const currentGoogleAccountInfo = serverGoogleApiOauth.available_google_accounts[serverGoogleApiOauth.currently_used_google_acc_id];

    if (currentGoogleAccountInfo) {
      googleApiOauth = {
        ...currentGoogleAccountInfo,
        displayName: currentGoogleAccountInfo.display_name,
        googleAccId: serverGoogleApiOauth.currently_used_google_acc_id,
        scopes: currentGoogleAccountInfo.scopes as GoogleApiScope[],
      };
    }
  }
  const { limits, usage } = getClientOrUserUsageLimits(serverModel);
  return {
    '2fa': serverModel.data['2fa'],
    clientId,
    clientMembers: serverModel.data.clients?.[0]?.members ?? [],
    clientName: serverModel.data.clients?.[0]?.name ?? null,
    clientRole: serverModel.data.client_role,
    createdAt: serverModel.data.created_at,
    currentClient: serverModel.data.current_client
      ? createCurrentClientFromResponse(serverModel.data.current_client) : null,
    email: serverModel.data.email,
    firstName: serverModel.data.first_name,
    googleApiOauth,
    id: serverModel.data.id,
    intercomHash: serverModel.data.intercom_hash,
    isPublicApiActive: !!serverModel.data.public_api,
    lastName: serverModel.data.last_name,
    limits,
    previousLogin: serverModel.data.usage_stats.previous_login || null,
    role: serverModel.data.role,
    settings: serverModel.data.settings,
    teamId: null,
    usage,
    intercom_hash: serverModel.data.intercom_hash,
  };
};

export const userDataLimitServerToClientModel = (serverModel: LimitsResponse): UserDataLimits => ({
  cachedGeocodes: serverModel.cached_geocodes,
  canExportSharedMapsData: serverModel.can_export_shared_maps,
  directionsApiRequests: serverModel.directions_api_requests,
  distanceMatrixRequests: serverModel.distance_matrix_requests,
  googlePlacesRequests: serverModel.google_places_requests,
  mapView: serverModel.map_view,
  maps: serverModel.maps,
  markers: serverModel.markers,
  realGeocodes: serverModel.real_geocodes,
  userLimit: serverModel.user_limit,
  wmsEnabled: serverModel.wms_enabled,
});

export const userDataUsageServerToClientModel = (serverModel?: UsageStatsResponse): UserDataStateUsage => ({
  realGeocodes: serverModel?.real_geocodes || 0,
  publicMapViews: serverModel?.map_views || 0,
  distanceMatrixRequests: serverModel?.distance_matrix_requests || 0,
});

const getClientOrUserUsageLimits = (serverModel: UserDataResponse): { limits: UserDataLimits; usage: UserDataStateUsage } => {
  const currentClientId = serverModel.data.current_client.id;
  const currentClient = serverModel.data.clients?.find(c => c.id === currentClientId);

  // for now, always prefer client level limits and usage stats
  // this is because no user-level limits functionality is actually used now
  const dataLimits = userDataLimitServerToClientModel(currentClient?.limits || serverModel.data.limits);
  const dataUsage = userDataUsageServerToClientModel(currentClient?.usage_stats || serverModel.data.usage_stats);

  return { limits: dataLimits, usage: dataUsage };
};
