import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '~/_shared/utils/hooks';
import { useRemoveSpreadsheetData } from '~/data/useRemoveSpreadheetData';
import { activeMapElementsClearState } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useActiveMarkerIdSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';

export const useRemoveLocationCallbacks = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const activeMarkerId = useActiveMarkerIdSelector();
  const clientId = useClientIdSelector();
  const { isLoading: isRemoveInProgress, removeSpreadsheetData } = useRemoveSpreadsheetData();
  const { openConfirmationModal: openDeleteConfirmationModal, closeConfirmationModal: closeDeleteConfirmationModal } = useConfirmationModal();

  const removeMarker = useCallback(() => {
    if (!clientId || !activeMarkerId) {
      return;
    }

    removeSpreadsheetData(
      clientId,
      activeMarkerId.spreadsheetId,
      {
        rows: [{
          row_id: activeMarkerId.rowId,
        }],
      },
      () => {
        dispatch(activeMapElementsClearState());
      }
    );
  }, [activeMarkerId, clientId, dispatch, removeSpreadsheetData]);

  const onRemoveMarker = useCallback(() => {
    openDeleteConfirmationModal({
      onCancel: closeDeleteConfirmationModal,
      onConfirm: () => {
        closeDeleteConfirmationModal();
        removeMarker();
      },
      text: t('deleteConfirmation.singleMarker', {
        modalName: t('Undo'),
        tabName: t('resetToVersion'),
      }),
      title: t('Delete Marker'),
      confirmCaption: t('Proceed'),
      isConfirmButtonDestructive: true,
      permanentConfirmSettings: {
        id: 'markerDelete',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeDeleteConfirmationModal, openDeleteConfirmationModal, removeMarker, t]);

  return ({
    removeMarkerCallback: onRemoveMarker,
    isLoading: isRemoveInProgress,
  });
};
