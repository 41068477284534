import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import {
  faArrowLeft,
  faCog,
  faCommentAltLines,
  faDrawPolygon, faFontCase,
  faHorizontalRule, faIcons,
  faImage,
  faListOl,
  faTag,
} from '@fortawesome/pro-solid-svg-icons';
import { type TranslationFnc } from '../_shared/utils/hooks';
import {
  type DrawingToolBooleanOptionName,
  type DrawingToolColorOptionName,
  type DrawingToolOtherOptionNames,
  type DrawingToolScaledSliderOptionName,
  type DrawingToolSliderOptionName,
} from './drawingTool.types';

export enum DrawingTool {
  Management = 'DrawingToolManagement',
  Arrow = 'DrawingToolArrow',
  Callout = 'DrawingToolCallout',
  Circle = 'DrawingToolCircle',
  IconMarker = 'DrawingToolIconMarker',
  Image = 'DrawingToolImage',
  Label = 'DrawingToolLabel',
  Number = 'DrawingToolNumber',
  Polygon = 'DrawingToolPolygon',
  Polyline = 'DrawingToolPolyline',
  Rectangle = 'DrawingToolRectangle',
  Text = 'DrawingToolText',
}

export const DRAWING_TOOLS_LIST: DrawingTool[] = [
  DrawingTool.Management,
  DrawingTool.Arrow,
  DrawingTool.Callout,
  DrawingTool.Circle,
  DrawingTool.IconMarker,
  DrawingTool.Image,
  DrawingTool.Label,
  DrawingTool.Number,
  DrawingTool.Polygon,
  DrawingTool.Polyline,
  DrawingTool.Rectangle,
  DrawingTool.Text,
];

export const DRAWING_TOOL_DEFAULT_STROKE_COLOR_PRIMARY = '#ff0000';
export const DRAWING_TOOL_DEFAULT_STROKE_COLOR_SECONDARY = '#000';
export const DRAWING_TOOL_DEFAULT_FILL_COLOR_PRIMARY = '#FFF';
export const DRAWING_TOOL_DEFAULT_FILL_COLOR_SECONDARY = '#000';
export const DRAWING_TOOL_DEFAULT_FILL_COLOR_TERTIARY = '#ff0000';
export const DRAWING_TOOL_DEFAULT_FONT_COLOR_PRIMARY = '#000';
export const DRAWING_TOOL_DEFAULT_FONT_COLOR_SECONDARY = '#FFF';

export const DRAWING_TOOL_SLIDER_PERCENTAGE_RANGE = {
  from: 0,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_FONT_SIZE_PERCENTAGE_RANGE = {
  from: 10,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_BORDER_RADIUS_RANGE = {
  from: 0,
  to: 30,
};

export const DRAWING_TOOL_SLIDER_RADIUS_RANGE = {
  from: 4,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE = {
  from: 1,
  to: 10,
};

export const DRAWING_TOOL_SLIDER_TRIANGLE_SIZE_RANGE = {
  from: 1,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_ARROW_TOOL_STROKE_WIDTH_RANGE = {
  from: 0,
  to: 15,
};

export const DRAWING_TOOL_SLIDER_ARROW_TOOL_LINE_WIDTH_RANGE = {
  from: 1,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE = {
  from: 0,
  to: 100,
};

export const DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE = {
  from: 10,
  to: 200,
};

export const DRAWING_TOOL_SLIDER_PERCENTAGE_SUFFIX = '%';
export const DRAWING_TOOL_SLIDER_PIXEL_SUFFIX = 'px';

export const DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX = DRAWING_TOOL_SLIDER_PIXEL_SUFFIX;
export const DRAWING_TOOL_SLIDER_FONT_SIZE_SUFFIX = DRAWING_TOOL_SLIDER_PIXEL_SUFFIX;

export const drawingToolEditableSliderOptionsName = (t: TranslationFnc): {
  [key in (DrawingToolSliderOptionName | DrawingToolScaledSliderOptionName)]: string
} => ({
  borderRadius: t('DrawingToolEditableOption.BorderRadius'),
  dotSize: t('DrawingToolEditableOption.DotSize'),
  fillOpacity: t('DrawingToolEditableOption.FillOpacity'),
  fontSize: t('DrawingToolEditableOption.FontSize'),
  fontSizeOnCurrentMapZoom: t('DrawingToolEditableOption.FontSizeOnCurrentMapZoom'),
  fontSizePercentage: t('DrawingToolEditableOption.FontSize'),
  imageSize: t('DrawingToolEditableOption.ImageSize'),
  imageSizeOnCurrentMapZoom: t('DrawingToolEditableOption.ImageSizeOnCurrentMapZoom'),
  lineWeight: t('DrawingToolEditableOption.LineWeight'),
  lineWeightOnCurrentMapZoom: t('DrawingToolEditableOption.LineWeightOnCurrentMapZoom'),
  radius: t('DrawingToolEditableOption.Radius'),
  radiusOnCurrentMapZoom: t('DrawingToolEditableOption.RadiusOnCurrentMapZoom'),
  strokeOpacity: t('DrawingToolEditableOption.StrokeOpacity'),
  strokeWeight: t('DrawingToolEditableOption.StrokeWeight'),
  strokeWeightOnCurrentMapZoom: t('DrawingToolEditableOption.StrokeWeightOnCurrentMapZoom'),
  triangleSize: t('DrawingToolEditableOption.TriangleSize'),
});

export const drawingToolEditableBooleanOptionsName = (t: TranslationFnc): { [key in DrawingToolBooleanOptionName]: string } => ({
  scalesWithMapZoom: t('DrawingToolEditableOption.ScalesWithMapZoom'),
  showImageBorders: t('DrawingToolEditableOption.ShowImageBorders'),
});

export const drawingToolEditableColorOptionsName = (t: TranslationFnc): { [key in DrawingToolColorOptionName]: string } => ({
  backgroundColor: t('DrawingToolEditableOption.BackgroundColor'),
  dotColor: t('DrawingToolEditableOption.DotColor'),
  fillColor: t('DrawingToolEditableOption.FillColor'),
  fontColor: t('DrawingToolEditableOption.FontColor'),
  strokeColor: t('DrawingToolEditableOption.StrokeColor'),
});

export const drawingToolEditableOtherOptionsName = (t: TranslationFnc): { [key in DrawingToolOtherOptionNames]: string } => ({
  number: t('DrawingToolEditableOption.DrawingToolNumber'),
  icon: '',
  image: t('DrawingToolEditableOption.DrawingToolSelectedImage'),
});

export const drawingToolIcons: { [key in DrawingTool]: IconProp} = {
  [DrawingTool.Management]: faCog,
  [DrawingTool.Callout]: faCommentAltLines,
  [DrawingTool.Arrow]: faArrowLeft,
  [DrawingTool.Number]: faListOl,
  [DrawingTool.Polygon]: faDrawPolygon,
  [DrawingTool.Circle]: faCircle,
  [DrawingTool.Polyline]: faHorizontalRule,
  [DrawingTool.Rectangle]: faSquare,
  [DrawingTool.Label]: faTag,
  [DrawingTool.Text]: faFontCase,
  [DrawingTool.IconMarker]: faIcons,
  [DrawingTool.Image]: faImage,
};
