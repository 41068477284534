import { css } from '@emotion/react';
import { type FC } from 'react';
import { type Theme } from '~/_shared/themes/theme.model';
import {
  type ActiveGroupFilters, type GroupingColumn,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTheme } from '../_shared/themes/theme.hooks';
import { noop } from '../_shared/utils/function.helpers';
import { GroupingItemsAccordionComponent } from './components/groupingItemsAccordion.component';

const itemsStyle = (theme: Theme) => css({
  height: '100%',
  backgroundColor: theme.backgroundColors.primary,
  overflowX: 'hidden',
});

type GroupingPanelProps = Readonly<{
  activeColumns: ReadonlyArray<GroupingColumn>;
  columns: ReadonlyArray<SpreadsheetColumn>;
  filterValues: ActiveGroupFilters;
  htmlElementId?: string;
  isLoading: boolean;
}>;

export const GroupingSimpleLegendComponent: FC<GroupingPanelProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={itemsStyle(theme)}
      id={props.htmlElementId}
    >
      <GroupingItemsAccordionComponent
        activeColumns={props.activeColumns}
        activeFilters={props.filterValues}
        allColumns={props.columns}
        isGroupEditingDisabled
        isFilteringDisabled
        onFilterChange={noop}
        onGroupColumnRemove={noop}
        onNumericGroupColumnSettingsClick={noop}
      />
    </div>
  );
};
