export enum MapTool {
  Grouping = 'Grouping',
  MapLegend = 'MapLegend',
  Filter = 'Filter',
  FilterData = 'FilterData', // used on spreadsheet data page
  Layering = 'Layering',
  Routing = 'Routing',
  Boundary = 'Boundary',
  Proximity = 'Proximity',
  HeatMap = 'HeatMap',
  Drawing = 'Drawing',
  Places = 'Places',
}
