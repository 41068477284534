import {
  createContext, type FC, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { type Nullable } from '~/_shared/utils/types/common.type';
import { type MarkerTemplateManager } from './markers/manager/markerTemplateManager';
import {
  type WebglLayerName, type WebglLayers,
} from './webgl/useWebGL';

export type MapContextModel = {
  readonly isMapLoaded: boolean;
  readonly map: google.maps.Map;
  readonly markerTemplateManager: MarkerTemplateManager;
  readonly webglLayers: WebglLayers;
  readonly webglOverlay: WebGLOverlay | undefined;
};

const MapContext = createContext<MapContextModel | null>(null);

type MapContextProviderProps = Partial<Nullable<Omit<MapContextModel, 'isMapLoaded'>>> & { children: ReactNode };

export const MapContextProvider: FC<MapContextProviderProps> = ({
  children,
  map,
  markerTemplateManager,
  webglLayers,
  webglOverlay,
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (map) {
      google.maps.event.addListenerOnce(map, 'idle', () => {
        setIsMapLoaded(true);
      });
    }
  }, [map]);

  const mapContext = useMemo(() =>
    map && webglLayers && markerTemplateManager && webglOverlay ? { isMapLoaded, map, webglLayers, markerTemplateManager, webglOverlay } : null,
  [isMapLoaded, map, webglLayers, markerTemplateManager, webglOverlay]);

  if (!mapContext) {
    return null;
  }

  return (
    <MapContext.Provider value={mapContext}>
      {children}
    </MapContext.Provider>
  );
};

/*
When the nearest <MyContext.Provider> above the component updates, this Hook will trigger a rerender
with the latest context value passed to that MyContext provider. Even if an ancestor uses React.memo
or shouldComponentUpdate, a rerender will still happen starting at the component itself using useContext.
*/
export const useMapContext = (): MapContextModel => {
  const context = useContext(MapContext);

  if (!context) {
    throw new Error('Map components cannot be used without the context provider.');
  }

  return context;
};

type CallOnceRefType = {
  callback?: () => void;
  hasCallbackBeenCalled: boolean;
  isMapLoaded: boolean;
  registerCallback?: (callback: () => void) => void;
};

export const useCallOnceTheMapIsLoaded = () => {
  const { isMapLoaded } = useMapContext();
  const stateRef = useRef<CallOnceRefType>({
    hasCallbackBeenCalled: false,
    isMapLoaded: false,
  });

  const resetCalledFlag = useCallback(() => {
    stateRef.current.hasCallbackBeenCalled = false;
  }, []);

  const { current: state } = stateRef;
  if (!state.registerCallback) {
    state.registerCallback = callback => {
      state.callback = callback;

      if (state.isMapLoaded && state.callback && !state.hasCallbackBeenCalled) {
        state.hasCallbackBeenCalled = true;
        state.callback();
      }
    };
  }

  stateRef.current.isMapLoaded = isMapLoaded;

  useEffect(() => {
    if (isMapLoaded && stateRef.current.callback && !stateRef.current.hasCallbackBeenCalled) {
      stateRef.current.hasCallbackBeenCalled = true;
      stateRef.current.callback();
    }
  }, [isMapLoaded]);

  return {
    registerCallback: state.registerCallback,
    resetCalledFlag,
  };
};
export const useMap = () => useMapContext().map;
export const useWebglLayers = () => useMapContext().webglLayers;
export const useWebglLayer = <T extends WebglLayerName>(layerName: T): WebglLayers[T] => useWebglLayers()[layerName];
