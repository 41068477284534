import { restoreThemeType } from '~/store/theme/theme.helpers';
import { type MapSettingsPublicMapSettingsAction } from './mapSettingsPublicMapSettings.action';
import {
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ALLOWED_EXPORT_DATA_TOOLS,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_THEME,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ZOOM_RESTRICT_LEVELS,
  MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE, MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE_DROPDOWNS,
} from './mapSettingsPublicMapSettings.actionTypes';
import { type MapSettingsPublicMapSettingsState } from './mapSettingsPublicMapSettings.state';

const publicMapSettingsInitialState: MapSettingsPublicMapSettingsState = {
  allowedExportDataTools: [],
  allowSatelliteView: true,
  allowStreetView: true,
  boundaryTool: false,
  displayLegendOnly: false,
  displaySearchBox: true,
  displayTitleAndDescription: true,
  distanceCalculator: true,
  filterMenusOpen: false,
  filterTool: false,
  groupingTool: false,
  heatMappingTool: false,
  lassoTool: false,
  legendReplacesGrouping: false,
  locationFinder: false,
  locationFinderStartsOpen: false,
  locationList: false,
  locationListOpen: false,
  mapImageExport: false,
  mapToolsMenuOpen: false,
  defaultOpenMapToolPresentationalMap: undefined,
  mapZoom: true,
  markersVisibilityButton: true,
  radiusProximity: false,
  restrictMapPanning: false,
  routingDirections: true,
  searchBarAlwaysVisible: false,
  showMyLocation: true,
  showMyLocationDefault: false,
  theme: restoreThemeType().type,
  zoomRestrictLevels: [undefined, undefined],
};

export const getPublicMapSettingsInitialState = () => ({
  ...publicMapSettingsInitialState,
  theme: restoreThemeType().type,
});

export const mapSettingsPublicMapSettingsReducer = (
  state: MapSettingsPublicMapSettingsState = getPublicMapSettingsInitialState(),
  action: MapSettingsPublicMapSettingsAction
): MapSettingsPublicMapSettingsState => {
  switch (action.type) {
    case MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ZOOM_RESTRICT_LEVELS: {
      return {
        ...state,
        zoomRestrictLevels: action.payload.zoomLevels,
      };
    }

    case MAP_SETTINGS_PUBLIC_MAP_SETTINGS_UPDATE_DROPDOWNS: {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    }

    case MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_ALLOWED_EXPORT_DATA_TOOLS: {
      return {
        ...state,
        allowedExportDataTools: Array.from(action.payload.allowedTools),
      };
    }

    case MAP_SETTINGS_PUBLIC_MAP_SETTINGS_SET_THEME: {
      return {
        ...state,
        theme: action.payload.value,
      };
    }

    default:
      return state;
  }
};
