import {
  type FC, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useHasSpreadsheetTableSearchAnyQueries } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { DataPageComponent } from './dataPage.component';

export const DataPageContainer: FC = () => {
  const dispatch = useDispatch();
  const hasSearchAnyQueries = useHasSpreadsheetTableSearchAnyQueries();

  useEffect(() => {
    if (!hasSearchAnyQueries) {
      return;
    }

    setTimeout(() => {
      dispatch(openMapTool(MapTool.FilterData));
    });
  }, [hasSearchAnyQueries, dispatch]);

  return (
    <DataPageComponent />
  );
};
