import { css } from '@emotion/react';
import { type FC } from 'react';
import { hash } from '~/_shared/utils/number/number.helpers';

const blurStyle = css({
  filter: 'blur(3px)',
  userSelect: 'none', // User shouldn't copy the values because they're obfuscated
});

export type FormattedValueProps = {
  showApproximateSymbol?: boolean;
  enforcedNumberOfFractionDigits?: number;
  prefix?: string;
  suffix?: string;
  value: number;
  blur?: boolean;
};

export const FormattedValue: FC<FormattedValueProps> = (props) => {
  const metricValue = props.value.toLocaleString(undefined, {
    minimumFractionDigits: props.enforcedNumberOfFractionDigits,
    maximumFractionDigits: props.enforcedNumberOfFractionDigits ?? 2,
  });
  // hash is used to always have the same result and UI won't change
  const value = props.blur ? metricValue.replace(/\d+/, (replaceValue) => `${hash(replaceValue)}`.substring(0, replaceValue.length)) : metricValue;

  return (
    <>
      {props.showApproximateSymbol ? '≈ ' : null}
      {props.prefix}
      <span css={props.blur && blurStyle}>
        {value}
      </span>
      {props.suffix}
    </>
  );
};
