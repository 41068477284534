import type { Interpolation } from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FocusEvent,
  forwardRef,
  type KeyboardEvent,
  type ReactNode,
  type Ref,
  useRef,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useCombinedRefs } from '~/_shared/utils/hooks/useCombinedRefs';
import {
  type InputSize, TextInputComponent,
} from '../../../../inputs/textInput';
import {
  autocompleteTextInputStyle,
  autocompleteTriggerInputStyle,
  clearIconStyle,
  DropdownTrigger,
  iconWrapperStyle,
} from './autocompleteTrigger.styles';

export type AutocompleteTriggerProps = {
  icon?: IconProp | null;
  iconStyle?: Interpolation;
  inputStyle?: Interpolation;
  inputSize: InputSize;
  hasValue?: boolean;
  isOpen: boolean;
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onChevronClick?: () => void;
  isDisabled?: boolean;
  onClear?: () => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  renderTags?: () => ReactNode;
  inputRef?: Ref<HTMLInputElement>;
};

export const AutocompleteTrigger = forwardRef<HTMLDivElement, AutocompleteTriggerProps>((props, ref) => {
  const {
    icon,
    inputSize,
    iconStyle,
    value,
    onChange,
    isOpen,
    placeholder,
    onClick,
    inputStyle,
    onChevronClick,
    isDisabled,
    onClear,
    onBlur,
    onKeyUp,
    renderTags,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const setInputRefs = useCombinedRefs(inputRef, props.inputRef);

  const onWrapperClick = () => {
    if (isDisabled) {
      return;
    }

    onClick?.();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <DropdownTrigger
      onClick={onWrapperClick}
      ref={ref}
      disabled={isDisabled}
    >
      <TextInputComponent
        ref={setInputRefs}
        css={[autocompleteTriggerInputStyle(isOpen), inputStyle]}
        inputStyle={autocompleteTextInputStyle}
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        size={inputSize}
        icon={icon}
        iconStyle={iconStyle}
        isDisabled={isDisabled}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        leftContent={renderTags?.()}
        rightContent={(
          <>
            {onClear && (
              <div
                css={clearIconStyle({ isClearable: !!props.hasValue && !isDisabled })}
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            )}
            {onChevronClick && (
              <div
                css={iconWrapperStyle(isOpen, isDisabled)}
                onClick={(e) => {
                  if (!isDisabled) {
                    e.stopPropagation();
                    onChevronClick();
                  }
                }}
              >
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            )}
          </>
        )}
      />
    </DropdownTrigger>
  );
});
