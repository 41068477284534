import { isPresentationalMapRoute } from '../utils/tests/router.helpers';

export const EXPIRED_SUBPATH = '/expired';

// Before the redux state is loaded is presentational is default false.
// Sometimes we need to know if we are on a presentational map BEFORE it's in redux.
// For those cases we use this function
export const isPresentationalMapByPathname = (pathname: string) => isPresentationalMapRoute(pathname);

export const isPresentationalExpiredMapByPathname = (pathname: string) => isPresentationalMapByPathname(pathname) && pathname.endsWith(EXPIRED_SUBPATH);
