import { useDispatch } from 'react-redux';
import { useFileUrls } from '~/store/frontendState/fileUrls/fileUrls.selector';
import {
  type GroupingColumn, type GroupingColumnNumeric, GroupingType,
} from '../_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '../_shared/types/grouping/numericActiveGroupColumnType.helpers';
import { type MarkerSettings } from '../_shared/types/markers/visualSettings.types';
import {
  generateMarkersVisualsWithBakedDataGetter,
  getExistingNumericGroupVisualSettingsIfAny, getHierarchyIndicatorIndex,
} from '../_shared/utils/markers/markersVisualSettings.helpers';
import { useMapSettingsFileAttachmentsMapSelector } from '../store/mapSettings/fileAttachments/fileAttachments.selectors';
import { useMapSettingsMarkersGeneralSelector } from '../store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { openNumericalGroupSettings } from '../store/modal/modal.actionCreators';
import { type SpreadsheetDataItemNumber } from '../store/spreadsheetData/spreadsheetData.state';
import { getMarkerVisualSettingsRadioOptions } from './settings/numeric/numericalGroupSettingsNumeric.helpers';
import {
  type NumericalGroupSettingsInitial,
  type NumericalGroupSettingsResults,
} from './settings/numericalGroupSettings.component';

type OpenNumericalGroupSettingsModalArguments = {
  numericColumn: GroupingColumnNumeric;
  // These can be part of active column change request and thus are different from activeColumns in redux store
  groupingColumns: GroupingColumn[];
  numericData: SpreadsheetDataItemNumber;
  initialSettings?: NumericalGroupSettingsInitial;
};

type OpenNumericalGroupSettingsModalResults = {
  newNumericalColumn: GroupingColumnNumeric;
  markerSettings: MarkerSettings[];
};

export const useNumericalGroupSettings = () => {
  const dispatch = useDispatch();
  const visualMarkersSettings = useMapSettingsMarkersGeneralSelector();
  const files = useFileUrls();
  const fileAttachments = useMapSettingsFileAttachmentsMapSelector();

  const openNumericalGroupSettingsModal = ({
    numericColumn,
    numericData,
    groupingColumns,
    initialSettings,
  }: OpenNumericalGroupSettingsModalArguments): Promise<OpenNumericalGroupSettingsModalResults> => {
    const hierarchyIndicator = getHierarchyIndicatorIndex(groupingColumns, numericColumn);

    const numericActiveGroupColumnType = getNumericActiveGroupColumnType(groupingColumns);
    const markerVisualRadioOptions = getMarkerVisualSettingsRadioOptions({
      hierarchyIndicator,
      numericActiveGroupColumnType,
    });

    const getMarkersVisualsWithBakedData = generateMarkersVisualsWithBakedDataGetter({
      groupingColumns,
      requestedNumericColumn: numericColumn,
      visualMarkersSettings,
      fileAttachments, files,
    });

    const getExistingGroupVisualsIfAnyWithBakedData = (bucketId: number, numberOfBuckets: number): MarkerSettings | null =>
      getExistingNumericGroupVisualSettingsIfAny({
        groupingColumns,
        bucketId,
        hierarchyIndicator,
        numberOfBuckets,
        visualMarkersSettings,
      });

    return new Promise((resolve, reject) => {
      const onSubmit = (results: NumericalGroupSettingsResults) => {

        const newNumericalColumn: GroupingColumnNumeric = {
          type: GroupingType.Numeric,
          valueType: results.valueType,
          buckets: results.buckets,
          columnId: numericColumn.columnId,
          spreadsheetId: numericColumn.spreadsheetId,
          isDecimal: results.isDecimal,
        };

        resolve({
          newNumericalColumn,
          markerSettings: results.markers,
        });
      };

      dispatch(openNumericalGroupSettings({
        min: numericData.extra.min,
        max: numericData.extra.max,
        isDecimal: numericColumn.isDecimal,
        onSubmit,
        onCancel: reject,
        getMarkerVisuals: getMarkersVisualsWithBakedData,
        markerVisualRadioOptions,
        getExistingGroupDataIfAny: getExistingGroupVisualsIfAnyWithBakedData,
        initialSettings,
      }));
    });
  };

  return {
    openNumericalGroupSettingsModal,
  };
};
