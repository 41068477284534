import { type MapSettingsRoute } from '../../../store/mapSettings/directions/mapSettingsDirections.state';
import { type HeatMap } from '../heatmap/heatMap.types';
import { type ProximityModel } from '../proximity/proximity.types';

export enum UndoItemType {
  DELETE_MAPS_AND_SNAPSHOTS = 'delete_maps_snapshots',
  // REMOVE_MARKER = 'remove_marker', no suitable data for this, enable once there is
  REMOVE_HEATMAP = 'remove_heatmap',
  REMOVE_PROXIMITY = 'remove_proximity',
  REMOVE_ROUTE = 'remove_route',
}

export enum UndoMapSnapshotsType {
  MAP = 'map',
  SNAPSHOT = 'snapshot',
}

export type UndoMapSnapshotsData = {
  deletedAt: string;
  id: number;
  name: string;
  type: UndoMapSnapshotsType;
};

export type UndoHeatmapData = HeatMap;
export type UndoProximityData = ProximityModel;
export type UndoRouteData = MapSettingsRoute;

export type UndoItemData = UndoHeatmapData | UndoProximityData | UndoRouteData;

type UndoItemMapSnapshotItemPayload = {
  type: UndoItemType.DELETE_MAPS_AND_SNAPSHOTS;
  data: UndoMapSnapshotsData;
  timestamp: string;
};

export type UndoItemHeatmapBase = {
  type: UndoItemType.REMOVE_HEATMAP;
  mapId: number;
};

export type UndoItemHeatmap = UndoItemHeatmapBase & {
  data: UndoHeatmapData;
};

export type UndoItemProximityBase = {
  type: UndoItemType.REMOVE_PROXIMITY;
  mapId: number;
};

export type UndoItemProximity = UndoItemProximityBase & {
  data: UndoProximityData;
};

export type UndoItemRouteBase = {
  type: UndoItemType.REMOVE_ROUTE;
  mapId: number;
};

export type UndoItemRoute = UndoItemRouteBase & {
  data: UndoRouteData;
};

type UndoItemBase =
  UndoItemHeatmapBase |
  UndoItemProximityBase |
  UndoItemRouteBase;

export type LocalStoredUndoItem =
  UndoItemHeatmap |
  UndoItemProximity |
  UndoItemRoute;

export type LocalStoredUndoItemAnyVersion = {
  version: string;
  data: any;
  timestamp: string;
} & UndoItemBase;

export type LocalStorageVersioned<T extends LocalStoredUndoItem> = Readonly<T & {
  version: string;
}>;

export type TimestampedLocalStoredUndoItem = Readonly<{ timestamp: string } & LocalStoredUndoItem>;

export type UndoItem = UndoItemMapSnapshotItemPayload | TimestampedLocalStoredUndoItem;
