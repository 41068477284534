import { css } from '@emotion/react';
import {
  type FC, useCallback, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { LinkComponent } from '~/_shared/baseComponents/links';
import {
  SupportLinkComponent, SupportLinkType,
} from '~/_shared/baseComponents/links/supportLink/supportLink.component';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Theme } from '~/_shared/themes/theme.model';
import { EmailErrorCode } from '~/_shared/types/responseErrors/_shared/emailErrorCodes.types';
import { GeneralErrorCode } from '~/_shared/types/responseErrors/_shared/generalErrorCodes.types';
import { MFAErrorCode } from '~/_shared/types/responseErrors/_shared/MFAErrorCodes.types';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import {
  type UserLoginErrorCode, UserLoginErrorSpecialCode,
} from '~/store/userData/repository/userLogin.types';
import {
  s, Trans,
} from '~/translations/Trans';
import { RefreshTokenErrorCodes } from '../../authContext/auth.utils';
import { authorizationPagesAlertStyle } from '../../authorizationPages.styles';
import { resendEmailVerificationRequest } from '../../verifyEmailPage/verifyEmail.repository';

const alertStyle = css({
  marginTop: 10,
});

const resentContainerStyle = (theme: Theme) => css({
  display: 'flex',
  justifyContent: 'center',
  gap: 8,
  color: theme.contrasts.success,
});

type UserLoginErrorComponentProps = {
  userEmail?: string;
  code: UserLoginErrorCode | string;
};

const UnverifiedMailNotification: FC<{email: string}> = ({ email }) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [hide, setHide] = useState(false);
  const [isResent, setIsResent] = useState(false);

  const onResendClick = useCallback(() => {
    setIsResent(true);
    return resendEmailVerificationRequest(email);
  }, [email]);

  if (hide) {
    return <></>;
  }

  return (
    <AlertComponent
      type="warning"
    >
      <div css={{ marginBottom: '4px' }}>
        {t('You must first verify your email address before you log in.')}
      </div>
      {isResent ? (
        <div css={resentContainerStyle(theme)}>
          {t('Email re-sent')}
          <LottieAnimationComponent
            size={20}
            speed={0.4}
            type={LottieAnimationTypes.MailChecked}
            autoplay
            onAnimationEnd={() => setHide(true)}
          />
        </div>
      ) :
        (
          <LinkComponent
            css={authorizationPagesAlertStyle}
            onClick={onResendClick}
            path={null}
          >
            <u>
              {t('Re-send email verification link')}
            </u>
          </LinkComponent>
        )
      }
    </AlertComponent>
  );
};

const getErrorText = (t: TranslationFnc, errorCode: UserLoginErrorCode | string) => {
  switch (errorCode) {
    case GeneralErrorCode.IP_BLOCKED:
    case UserLoginErrorSpecialCode.INVALID_CREDENTIALS:
    case EmailErrorCode.EMAIL_BLOCKED:
    case MFAErrorCode.MFA_CODE_IS_INVALID:
    case MFAErrorCode.MFA_CODE_SENT_TO_EMAIL:
    case MFAErrorCode.MFA_CODE_SENT_TO_PHONE:
    case GeneralErrorCode.ACCESS_FORBIDDEN:
    case UserLoginErrorSpecialCode.USER_NOT_FOUND:
    case UserLoginErrorSpecialCode.USER_WAS_DISABLED: {
      return t(errorCode);
    }
    case RefreshTokenErrorCodes.INVALID_TOKEN: {
      return <>{t(errorCode)}</>;
    }
    case UserLoginErrorSpecialCode.USER_CLIENT_IS_DISABLED: {
      return (
        <Trans
          i18nKey={errorCode}
        >
          {s('Account Disabled or Deleted. If you need assistance or believe this is an error, contact our ')}
          <SupportLinkComponent type={SupportLinkType.info} />
          {s('.')}
        </Trans>
      );
    }
    default:
      return t('Error trying to log in.');
  }
};

const UserLoginErrorContentComponent: FC<UserLoginErrorComponentProps> = ({ userEmail, code }) => {
  const [t] = useTranslation();
  if (code === EmailErrorCode.UNVERIFIED_EMAIL) {
    return (
      <>
        {userEmail && (
          <UnverifiedMailNotification email={userEmail} />
        )}
      </>
    );
  }
  else {
    return (
      <AlertComponent
        type="danger"
      >
        {getErrorText(t, code)}
      </AlertComponent>
    );

  }
};

export const UserLoginErrorComponent: FC<UserLoginErrorComponentProps> = ({ code, userEmail }) => {
  return (
    <div css={alertStyle}>
      <UserLoginErrorContentComponent
        userEmail={userEmail}
        code={code}
      />
    </div>
  );
};
