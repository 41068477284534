import { css } from '@emotion/react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const headingStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'uppercase',
});

type OneDimensionalTableHeaderProps = Readonly<{
  value: string;
}>;

export const OneDimensionalTableHeader = (props: OneDimensionalTableHeaderProps) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div
      css={headingStyle({ theme })}
      title={props.value}
    >
      {props.value || t('Empty').toUpperCase()}
    </div>
  );
};
