import { combineReducers } from 'redux';
import { baseMapStandardSliderOptions } from '~/map/settings/accordion/baseMap/baseMapStandardSliders/baseMapStandardSliders.constants';
import { BaseMapThemeName } from '~/map/settings/accordion/baseMap/baseMapThemes/baseMapThemes.enums';
import { type MapSettingsMapStylesAction } from './mapSettingsMapStyles.action';
import {
  MAP_SETTINGS_MAP_STYLES_CHANGE,
  MAP_SETTINGS_MAP_STYLES_SET_SATELLITE,
  MAP_SETTINGS_MAP_STYLES_STANDARD_COLORS_CHANGE,
  MAP_SETTINGS_MAP_STYLES_STANDARD_SLIDERS_CHANGE,
  MAP_SETTINGS_MAP_STYLES_THEME_CHANGE,
} from './mapSettingsMapStyles.actionTypes';
import {
  changeStyling,
  getThemeStyling, initialStandardStylingState,
} from './mapSettingsMapStyles.helpers';
import {
  type MapSettingsMapStylesSlidersState,
  type MapSettingsMapStylesState,
  type MapSettingsMapStylesStylingState,
} from './mapSettingsMapStyles.state';

const initialIsSatelliteEnabledState = false;
const initialSlidersState: MapSettingsMapStylesSlidersState = {
  labels: 3,
  landmarks: 3,
  roads: 3,
};

const initialThemeState = BaseMapThemeName.DeepWater;
export const initialStylingState = getThemeStyling(initialThemeState, initialStandardStylingState);

const mapStylesStylingReducer = (state = initialStylingState, action: MapSettingsMapStylesAction): MapSettingsMapStylesStylingState => {
  switch (action.type) {
    case MAP_SETTINGS_MAP_STYLES_CHANGE: {
      let newStyling = state;
      action.payload.stylesChangeData.forEach(options => newStyling = changeStyling(newStyling, options));

      return newStyling;
    }

    case MAP_SETTINGS_MAP_STYLES_THEME_CHANGE: {
      return getThemeStyling(action.payload.themeId, state);
    }

    case MAP_SETTINGS_MAP_STYLES_STANDARD_SLIDERS_CHANGE: {
      const { value, sliderName } = action.payload;
      const sliderOptions = baseMapStandardSliderOptions[sliderName];
      if (sliderOptions.length <= value) {
        return state;
      }
      let newStyling = state;
      sliderOptions[value]?.forEach(options => newStyling = changeStyling(newStyling, options));

      return newStyling;
    }

    case MAP_SETTINGS_MAP_STYLES_STANDARD_COLORS_CHANGE: {
      const { color, elementName, featureNames } = action.payload;

      featureNames.forEach(feature => {
        const changeData = {
          featureType: feature,
          elementType: elementName,
          stylers: {
            color: { isActive: true, value: color },
          },
        };
        state = {
          ...state,
          ...changeStyling(state, changeData),
        };

      });

      return state;
    }

    default:
      return state;
  }
};

const mapStylesThemeReducer = (state = initialThemeState, action: MapSettingsMapStylesAction): BaseMapThemeName => {
  switch (action.type) {
    case MAP_SETTINGS_MAP_STYLES_THEME_CHANGE: {
      return action.payload.themeId;
    }

    default:
      return state;
  }
};

const mapStylesSlidersReducer = (state = initialSlidersState, action: MapSettingsMapStylesAction): MapSettingsMapStylesSlidersState => {
  switch (action.type) {
    case MAP_SETTINGS_MAP_STYLES_STANDARD_SLIDERS_CHANGE: {
      const { value, sliderName } = action.payload;

      const sliderOptions = baseMapStandardSliderOptions[sliderName];
      if (sliderOptions.length <= value) {
        return state;
      }

      return {
        ...state,
        [sliderName]: value,
      };
    }

    case MAP_SETTINGS_MAP_STYLES_THEME_CHANGE: {
      return initialSlidersState;
    }

    default:
      return state;
  }
};

export const isSatelliteEnabledReducer = (state = initialIsSatelliteEnabledState, action: MapSettingsMapStylesAction): boolean => {
  switch (action.type) {
    case MAP_SETTINGS_MAP_STYLES_SET_SATELLITE: {
      return action.payload.isSatelliteEnabled;
    }

    default:
      return state;
  }
};

export const mapSettingsMapStylesReducer = combineReducers({
  styling: mapStylesStylingReducer,
  theme: mapStylesThemeReducer,
  sliders: mapStylesSlidersReducer,
  isSatelliteEnabled: isSatelliteEnabledReducer,
}) satisfies (...args: unknown[]) => MapSettingsMapStylesState;

export const mapSettingsMapStylesInitialState: MapSettingsMapStylesState = {
  styling: initialStylingState,
  theme: initialThemeState,
  sliders: initialSlidersState,
  isSatelliteEnabled: initialIsSatelliteEnabledState,
};
