import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.Confetti]: {
  views: {
    default: {
      canvasHeight: 900,
      canvasWidth: 1125,
      iconHeight: 900,
      iconWidth: 1125,
      offsetTop: 0,
      offsetLeft: 0,
    },
  },
  colors: { },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 180] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/78540-confetti-congratulation-sparkle" */
      './78540-confetti-congratulation-sparkle.json'
    ),
} };

export const { Confetti } = config;
