export const GEOCODING_PROCESSING_SUCCESS = 'GEOCODING/PROCESSING_SUCCESS';

export const GEOCODING_SET_PROGRESS = 'GEOCODING/SET_PROGRESS';
export const GEOCODING_START_TRACKING = 'GEOCODING/START_TRACKING';

export const GEOCODING_PAUSE_REQUEST = 'GEOCODING/PAUSE_REQUEST';
export const GEOCODING_PAUSE_SUCCESS = 'GEOCODING/PAUSE_SUCCESS';
export const GEOCODING_PAUSE_ERROR = 'GEOCODING/PAUSE_ERROR';

export const GEOCODING_RESUME_REQUEST = 'GEOCODING/RESUME_REQUEST';
export const GEOCODING_RESUME_SUCCESS = 'GEOCODING/RESUME_SUCCESS';
export const GEOCODING_RESUME_ERROR = 'GEOCODING/RESUME_ERROR';

export const GEOCODING_SET_IS_PAUSE_RESUME_LOADING = 'GEOCODING/SET_IS_PAUSE_RESUME_LOADING';
