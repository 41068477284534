import { type PaginationResponse } from '../_shared/types/pagination/pagination.response';
import {
  apiDelete,
  apiGet, apiPost,
} from '../_shared/utils/api/api.helpers';

type PolygonCoordinatesResponse = {
  type: string;
  properties: {
    name: string;
  };
  geometry: {
    type: string;
    coordinates: [number, number][ ];
  };
};

type PolygonOptionsResponse = {
  fill_color: string;
  test_color: string;
  border_color: string;
  border_width: string;
  fill_opacity: string;
};

export type PolygonResponse = {
  id: number;
  name: string;
  coordinates: PolygonCoordinatesResponse;
  options: PolygonOptionsResponse;
};

export type PolygonGroupResponse = {
  client_id: number;
  id: number;
  name: string;
  polygons?: PolygonResponse[];
  user_id: number | null;
};

export type PolygonGroupsResponse = {
  data: {
    list: PolygonGroupResponse[];
    meta: PaginationResponse;
  };
};

export type PolygonGroupCreateRequestData = {
  name: string;
  userId: number;
};

export type PolygonGroupCreateResponse = {
  group_id: number;
  message: string;
};

export type PolygonGroupDeleteResponse = {
  message: string;
};

export const getPolygonGroups = (clientId: number, userId?: number, includePolygons?: boolean): Promise<PolygonGroupsResponse> => {
  const userIdIfExists = userId ?? '';
  const requestUrl = `/api/clients/${clientId}/tools/territories/${userIdIfExists}`;
  const params = includePolygons ? { with: 'polygons' } : {};

  return apiGet(requestUrl, params);
};

export const postPolygonGroup = (clientId: number, mapId: number, request: PolygonGroupCreateRequestData): Promise<PolygonGroupCreateResponse> => {
  const requestUrl = `/api/clients/${clientId}/tools/territories/`;
  const data = {
    map_id: mapId,
    name: request.name,
    user_id: request.userId,
  };

  return apiPost(requestUrl, data);
};

export const deletePolygonGroup = (clientId: number, polygonGroupId: number): Promise<PolygonGroupCreateResponse> => {
  const requestUrl = `/api/clients/${clientId}/tools/territories/`;

  return apiDelete(requestUrl, { id: polygonGroupId });
};

export const getActivePolygonGroups = (clientId: number, mapId: number): Promise<PolygonGroupCreateResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/${mapId}/tools/territories`;

  return apiGet(requestUrl);
};

export const activatePolygonGroup = (clientId: number, mapId: number, polygonGroupId: number): Promise<PolygonGroupCreateResponse> => {
  const requestUrl = `/api/clients/${clientId}/tools/territories/map/attach`;
  const data = {
    territory_id: polygonGroupId,
    map_id: mapId,
  };

  return apiPost(requestUrl, data);
};

export const deactivatePolygonGroup = (clientId: number, mapId: number, polygonGroupId: number): Promise<PolygonGroupCreateResponse> => {
  const requestUrl = `/api/clients/${clientId}/tools/territories/map/detach`;
  const data = {
    territory_id: polygonGroupId,
    map_id: mapId,
  };

  return apiPost(requestUrl, data);
};
