export enum FilterAction {
  ArrayContains = 'in',
  ArrayNotContains = 'ni',
  BeginsWith = 'bw',
  Contains = 'cn',
  EndsWith = 'ew',
  Equal = 'eq',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  GreaterThanOrEqualPercentage = 'lte%',
  GreaterThanPercentage = 'gt%',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  LessThanOrEqualPercentage = 'gte%',
  LessThanPercentage = 'lt%',
  NotANumber = 'nan',
  NotBeginWith = 'bn',
  NotContains = 'nc',
  NotEndsWith = 'en',
  NotEqual = 'ne',
  NotNull = 'nn',
  Null = 'nu',
  Range = 'range',
  RangeIncludingMax = 'rangeIncl',
  RangePercentage = 'range%',
}
