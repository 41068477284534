import {
  useEffect,
  useRef,
} from 'react';

export const usePrevious = <T>(value: T, options: { reset?: boolean } = {}): T | undefined => {
  const ref = useRef<T>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return options.reset ? undefined : ref.current;
};
