import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.CircleArrowDown]: {
  views: {
    default: {
      canvasHeight: 568,
      canvasWidth: 568,
      iconWidth: 438,
      iconHeight: 438,
    },
  },
  colors: {
    arrow: '#000000',
    circle: '#E7E7E7',
  },
  gradients: {},
  frameRate: 30,
  segments: {
    default: [0, 90] as const,
    arrow: [10, 72] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/89461-green-arrow" */
      './89461-green-arrow.json'
    ),
} };

export const { CircleArrowDown } = config;
