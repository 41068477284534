import { css } from '@emotion/react';
import {
  faEye, faEyeSlash, faGripLines,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../_shared/types/themeProps';
import { BASIC_PANEL_WIDTH } from '../../../sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import { type BubbleEditorItem } from '../locationData.types';
import { type ColumnStyleSettingsData } from '../panelSettings.types';
import { BubbleItemComponent } from './bubbleItem.component';

type BubbleItemWithIconCommonProps = Readonly<{
  item: BubbleEditorItem;
  openLinksInNewTab?: boolean;
  isDraggable: boolean;
  showDragIcon: boolean;
  bulkStyles: ColumnStyleSettingsData;
}>;

type BubbleItemWithIconVisibilityProps = BubbleItemWithIconCommonProps & Readonly<{
  showVisibilityIcon: true;
  isItemVisible: boolean;
  onVisibilityIconClick: () => void;
}>;

type BubbleItemWithoutVisibilityProps = BubbleItemWithIconCommonProps & Readonly<{
  showVisibilityIcon: false;
}>;

export type BubbleItemWithIconProps = BubbleItemWithIconVisibilityProps | BubbleItemWithoutVisibilityProps;

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: '7px 14px',
  userSelect: 'none',
  minWidth: BASIC_PANEL_WIDTH,
});

const ICON_FONT_SIZE = 16;

const bubbleItemComponentStyle = css({
  width: '100%',
  minWidth: 0,
});

const visibilityButtonStyle = css({
  display: 'block',
  background: 'none',
  margin: 0,
  padding: 0,
  border: 'none',
});

const dragIconStyle = ({ theme, isDraggable }: ThemeProps<{ isDraggable?: boolean }>) => css({
  color: theme.iconColors.primary,
  fontSize: ICON_FONT_SIZE,
  opacity: isDraggable ? 1 : 0,
  transition: 'opacity 0.3s',
  marginRight: isDraggable ? 24 : 0,
});

const visibilityIconStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.contrast,
  fontSize: 14,
});

export const BubbleItemWithIconComponent: FC<BubbleItemWithIconProps> = props => {
  const theme = useTheme();

  return (
    <div css={wrapperStyle}>
      {props.showDragIcon && (
        <FontAwesomeIcon
          css={dragIconStyle({ theme, isDraggable: props.isDraggable })}
          icon={faGripLines}
        />
      )}

      <div css={bubbleItemComponentStyle}>
        <BubbleItemComponent
          item={props.item}
          isDraggable={props.isDraggable}
          openLinksInNewTab={props.openLinksInNewTab}
          bulkStyles={props.bulkStyles}
        />
      </div>

      {props.showVisibilityIcon && (
        <button
          css={visibilityButtonStyle}
          onClick={props.onVisibilityIconClick}
        >
          <FontAwesomeIcon
            css={visibilityIconStyle({ theme })}
            icon={props.isItemVisible ? faEye : faEyeSlash}
          />
        </button>
      )}
    </div>
  );
};
