import { css } from '@emotion/react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ClientUsersDropdownContainer } from '~/_shared/components/clientUsersDropdown/clientUsersDropdown.container';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type Member } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';

const newMemberSectionContentStyle = ({ theme }: ThemeProps) => css({
  background: theme.modalColors.contentBackground,
  padding: '23px 22px',
  position: 'relative',
});

const newMemberFormRowStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
});

const buttonStyle = css({
  height: '40px',
  width: '130px',
  cursor: 'pointer',
});

const formSubTitleStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  fontSize: '16px',
  lineHeight: '21px',
  marginBottom: '20px',
});

const dropdownAndAddButtonContainerStyle = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const dropdownContainerStyle = css({
  flex: '1 1 auto',
  overflow: 'hidden',
});

const dropDownLabelStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  fontSize: '12px',
  lineHeight: '14px',
  marginBottom: 4,
  width: '100%',
});

const addUserButtonContainerStyle = css({
  flex: '0 0 auto',
});

const loaderStyle = ({ theme }: ThemeProps) => css({
  background: theme.loadingOverlay.backgroundColor,
});

type NewMemberFormComponentProps = {
  isLoading: boolean;

  onAddUsers: (newMembers: Member[], onSuccess?: () => void) => void;
  excludeExistingMembersFilter: (item: Member) => boolean;
};

export const NewMemberFormComponent: FC<NewMemberFormComponentProps> = (props) => {
  const { isLoading, onAddUsers, excludeExistingMembersFilter } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  const [newMembers, setNewMembers] = useState<Member[]>([]);

  const handleAddUser = useCallback(() => {
    onAddUsers(newMembers, () => {
      setNewMembers([]);
    });
  }, [newMembers, onAddUsers]);

  return (
    <div css={newMemberSectionContentStyle({ theme })}>
      {isLoading && (
        <OverlayLoaderComponent
          css={loaderStyle({ theme })}
          loaderSize={45}
        />
      )}
      <div css={formSubTitleStyle({ theme })}>
        {t('Select Team Members to Share With:')}
      </div>
      <div css={newMemberFormRowStyle}>
        <div css={dropDownLabelStyle({ theme })}>{t('Name or Email:')}</div>
        <div css={dropdownAndAddButtonContainerStyle}>
          <div css={dropdownContainerStyle}>
            <ClientUsersDropdownContainer
              onItemsChange={setNewMembers}
              excludedMembersFilter={excludeExistingMembersFilter}
              selectedItems={newMembers}
            />
          </div>
          <div css={addUserButtonContainerStyle}>
            <ButtonComponent
              css={buttonStyle}
              prefixIcon={faPlus}
              onClick={handleAddUser}
              text={newMembers.length < 2 ? t('Add User') : t('Add Users')}
              isDisabled={!newMembers.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
