import { DrawingTool } from '../../../../../drawingTool/drawingTool.enums';
import { drawingToolIconMarkers } from '../../../../../drawingTool/iconMarkers/drawingToolIconMarkers';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingIconMarkerSettingsState } from './drawingIconMarkerSettings.state';

export const drawingIconMarkerSettingsInitialState: DrawingIconMarkerSettingsState = {
  icon: drawingToolIconMarkers[0].id,
};

export const drawingIconMarkerSettingsReducer = (
  state = drawingIconMarkerSettingsInitialState, action: DrawingSettingsAction
): DrawingIconMarkerSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.IconMarker) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.iconMarker,
      };
    }

    default:
      return state;
  }
};
