import { generatePath } from 'react-router-dom';
import { CONFIG } from '~/_shared/constants/config';
import {
  isTruthy, notNullsy,
} from '~/_shared/utils/typeGuards';

export type UrlParametersParsed = Record<string, string>;
export type UrlParameters = Record<string, number | string | boolean | undefined | null>;

export const validateUrlParam = (param: string): boolean => {
  return param.length === escape(param).length;
};

const buildUrlParams = (params: UrlParameters): string => {
  const preparedParams = Object.keys(params)
    .map(key => {
      if (notNullsy(params[key]) && params[key] !== '') {
        return `${key}=${params[key]}`;
      }
      return null;
    })
    .filter(notNullsy);

  return preparedParams.join('&');
};

//builds the URL with query params and fragment params
export const buildUrl = (url: string, queryParams: UrlParameters = {}, fragmentParams: UrlParameters = {}): string => {
  if (!url || /[\\<>{}]/.test(url)) {
    return '';
  }

  const parsed = /[?#]/.test(url) ? parseUrl(url) : null;

  const queryParamsString = buildUrlParams({ ...parsed?.queryParameters, ...queryParams });
  const hashParamsString = buildUrlParams({ ...parsed?.fragmentParameters, ...fragmentParams });

  const urlPart = parsed?.urlPart ?? url;
  const queryString = queryParamsString ? `?${queryParamsString}` : '';
  const hashString = hashParamsString ? `#${hashParamsString}` : '';

  return `${urlPart}${queryString}${hashString}`;
};

const parseParams = (paramString?: string): UrlParametersParsed | undefined => {
  if (!paramString) {
    return undefined;
  }

  const paramStrings = paramString.split('&');

  return paramStrings.reduce((resultParams, param) => {
    const [key, value] = param.split('=');

    if (isTruthy(key) && isTruthy(value)) { // we can use isTruthy, key and value are string
      return {
        ...resultParams,
        [key]: value,
      };
    }

    return resultParams;
  }, {});
};

export const parseUrl = (url: string): {
  fragmentParameters?: UrlParametersParsed;
  queryParameters?: UrlParametersParsed;
  urlPart: string;
} => {
  const [urlWithoutFragment, possibleFragmentParams] = url.split('#');

  if (!urlWithoutFragment) {
    return { urlPart: url };
  }

  const [urlPart, possibleQueryParams] = urlWithoutFragment.split('?');

  return {
    fragmentParameters: parseParams(possibleFragmentParams),
    queryParameters: parseParams(possibleQueryParams),
    urlPart: urlPart || url,
  };
};

//Builds the URL with the passed params in the path (not as query params)
export const buildUrlWithPath = <T extends Record<string, any> | never = never>(url: string, params: T) =>
  generatePath(url, params);

export const getIsPageRefreshed = () => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.performance.getEntriesByType('navigation')?.[0]?.type === 'reload'
);

export const getBaseUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const getPublicBaseUrl = () => {
  if (CONFIG.PUBLIC_MAPS_URL) {
    return CONFIG.PUBLIC_MAPS_URL;
  }

  if (window.location.host.startsWith('public')) {
    return getBaseUrl();
  }

  if (window.location.host.startsWith('app')) {
    return `${window.location.protocol}//${window.location.host.replace(/^app/, 'public')}`;
  }

  return `${window.location.protocol}//public.${window.location.host}`;
};

export const isPublicDomain = () => getPublicBaseUrl() === getBaseUrl();

export const getRelativeUrl = (url: Maybe<string>) => {
  if (!url) {
    return null;
  }

  if (url.startsWith('/')) {
    return url;
  }

  try {
    const normalizedUrl = url.startsWith('http') ? url : `https://${url}`;
    const parsedUrl = new URL(normalizedUrl, window.location.origin);
    return parsedUrl.pathname;
  }
  catch (error) {
    return null;
  }
};
