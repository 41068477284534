import {
  type FC, useEffect, useState,
} from 'react';
import { downloadFile } from '~/_shared/utils/document/document.helpers';
import { getExportUrl } from '~/_shared/utils/export/export.helpers';
import {
  ExportDataModalComponent,
  type ExportDataModalResults,
} from '../../../../../_shared/components/exportDataModal/exportDataModal.component';
import { prepareAsyncCopyToClipboard } from '../../../../../_shared/utils/clipboard/clipboard.helpers';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { useTimeout } from '../../../../../_shared/utils/hooks/useTimeout';
import { ExportDataFileType } from '../../../../../data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '../../../../../data/exportDataModal/exportDataMethod.enum';
import {
  type ModalProps, ModalType,
} from '../../../../../modal/modalType.enum';
import { useModal } from '../../../../../modal/useModal.hook';
import { type BoundaryTerritoryFilterGroups } from '../../../../../spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import {
  createRequestFromBoundaryTerritoryGroupSettings,
  getBoundaryTerritoryGroupMatchupRequest,
} from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.factory';
import { useBoundaryTerritoryGroupsSelector } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { usePrimarySpreadsheetId } from '../../../../../store/selectors/usePrimarySpreadsheetId';
import { useExportBoundaryTerritoryGroupData } from '../hooks/useExportBoundaryTerritoryGroupData';

export type ExportBoundaryTerritoryContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
  boundaryTerritoryId: string;
}>;

export const ExportBoundaryTerritoryContainer: FC<ExportBoundaryTerritoryContainerProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCopiedToClipboard, showCopiedToClipboardMessage] = useTimeout(3000);
  const { openModal: openDownloadResultsModal } = useModal(ModalType.DownloadResults);
  const { isLoading, isError, getExportedBoundaryTerritoryGroupData } = useExportBoundaryTerritoryGroupData();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const virtualSpreadsheetId = usePrimarySpreadsheetId();
  const [t] = useTranslation();

  const onSave = (arg: ExportDataModalResults) => {
    if (!virtualSpreadsheetId) {
      return;
    }
    const asyncCopyToClipboard = prepareAsyncCopyToClipboard();
    setErrorMessage(null);

    const selectedBoundaryTerritoryGroup = boundaryTerritoryGroups.find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);

    if (!selectedBoundaryTerritoryGroup) {
      return;
    }

    const boundaryTerritoryFilterGroup: BoundaryTerritoryFilterGroups[] = [{
      type: 'or',
      boundary_territories: [props.boundaryTerritoryId],
      definition: {
        boundary_territory_group_id: props.boundaryTerritoryGroupId,
        boundary_group_id: selectedBoundaryTerritoryGroup.boundaryGroupId,
        matchings: getBoundaryTerritoryGroupMatchupRequest(selectedBoundaryTerritoryGroup.matchings),
        settings: createRequestFromBoundaryTerritoryGroupSettings(selectedBoundaryTerritoryGroup.settings),
      },
    }];

    return getExportedBoundaryTerritoryGroupData({
      spreadsheetId: virtualSpreadsheetId,
      boundaryTerritoriesFilter: boundaryTerritoryFilterGroup,
      extension: arg.method === ExportDataMethod.Clipboard ? ExportDataFileType.Csv : arg.fileType,
      exportAsText: arg.method === ExportDataMethod.Clipboard,
      useMainFilters: !selectedBoundaryTerritoryGroup.settings.ignoreFilters,
    })
      .then((response) => {
        if (arg.method === ExportDataMethod.Clipboard) {
          const fileContent = response.data[0]?.result?.content;

          if (!fileContent) {
            setErrorMessage(t('No locations to export'));
            return;
          }
          asyncCopyToClipboard(fileContent);
          showCopiedToClipboardMessage();
        }
        else {
          const fileName = response.data[0]?.result?.file_name;

          if (!fileName) {
            setErrorMessage(t('No locations to export'));
            return;
          }

          const exportUrl = getExportUrl(fileName);
          downloadFile(exportUrl);

          props.onClose();
          openDownloadResultsModal({
            downloadUrl: exportUrl,
          });
        }
      });
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(t('Error exporting data'));
    }
  }, [isError, t]);

  return (
    <ExportDataModalComponent
      isLoading={isLoading}
      onSubmit={onSave}
      onClose={props.onClose}
      isOpen={props.isOpen}
      error={errorMessage}
      showCopiedToClipboardTooltip={isCopiedToClipboard}
      noticeTrialUsers
    />
  );
};
