import { useMemo } from 'react';
import { type Proximity } from '../../../_shared/types/proximity/proximity.types';
import { useIsMapInteractionActive } from '../../../_shared/utils/hooks/useIsMapInteractionActive';
import { useActiveProximitySelector } from '../../../store/frontendState/activeMapElements/activeMapElements.selectors';
import { useProximityToolSelector } from '../../../store/frontendState/proximityTool/proximityTool.selectors';

export const useProximityStates = <T extends Proximity>(proximity: T) => {
  const { hoveredProximityId } = useProximityToolSelector();
  const activeProximity = useActiveProximitySelector();
  const isMapInteractionActive = useIsMapInteractionActive();

  return useMemo(() => {
    const isHovered = !isMapInteractionActive && hoveredProximityId === proximity.id;
    const isActive = !isMapInteractionActive && activeProximity?.proximityId === proximity.id;

    return {
      isHovered,
      isActive,
    };
  }, [isMapInteractionActive, hoveredProximityId, activeProximity?.proximityId, proximity.id]);
};
