import { type FC } from 'react';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

type CircleArrowSlimProps = {
  circleColor: string;
  arrowColor: string;
  dimensions: QuadrilateralDimensions;
};

export const CircleArrowSlimComponent: FC<CircleArrowSlimProps> = (props) => (
  <svg
    width={props.dimensions.width}
    height={props.dimensions.height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM19.3393 33.0847C18.2546 33.0847 18.2547 34.7119 18.7966 35.2542L30.4627 49.6271C31.2762 50.4407 32.0898 50.4407 32.9033 49.6271L44.8308 35.2542C45.3731 34.7119 45.3731 33.0847 44.2884 33.0847H37.2376L35.6104 16.2712C35.3392 15.1864 33.9833 14.1017 32.8986 14.1017H30.7291C29.6443 14.1017 28.2884 15.1864 28.0172 16.2712L26.3894 33.0847H19.3393Z"
      fill={props.circleColor}
    />
    <path
      d="M19.3395 33.0847C18.2548 33.0847 18.2549 34.7119 18.7968 35.2542L30.4629 49.6271C31.2765 50.4407 32.09 50.4407 32.9036 49.6271L44.831 35.2542C45.3733 34.7119 45.3734 33.0847 44.2886 33.0847H37.2378L35.6107 16.2712C35.3395 15.1864 33.9836 14.1017 32.8988 14.1017H30.7293C29.6446 14.1017 28.2886 15.1864 28.0175 16.2712L26.3897 33.0847H19.3395Z"
      fill={props.arrowColor}
    />
  </svg>
);
