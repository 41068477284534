import { css } from '@emotion/react';
import { type FC } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { InputWithSpinnersComponent } from '~/_shared/baseComponents/inputs';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  ProximityLimitInclusionType, ProximityLimitMarkersType,
} from '~/proximityDetails/export/useExportProximityLimitResults';

const sectionStyle = (theme: Theme) => css({
  padding: 14,
  border: `0px solid ${theme.borderColors.primary}`,
  borderWidth: '0 0 1px',
  '&:last-of-type': {
    borderWidth: 0,
  },
});

const sectionContentStyle = css({
  marginTop: 15,
});

const headingStyle = css({
  textTransform: 'uppercase',
  fontSize: '16px',
  fontWeight: 500,
});

const markersCountWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
});

const markersLabelStyle = css({
  marginLeft: 8,
});

const returnOnceWarningStyle = (theme: Theme) => css({
  color: theme.textColors.secondary,
  margin: '0',
  fontSize: '13px',
});

const returnOnceCheckmarkStyle = css({
  alignSelf: 'flex-start',
  marginTop: 3,
});

export type ExportProximityLimits = {
  markersCount: number;
  markersLimitType: ProximityLimitMarkersType;
  markersInclusionType: ProximityLimitInclusionType;
  isReturnMarkersOnlyOnce: boolean;
};

export type ExportProximityLimitResultsSectionProps = Readonly<ExportProximityLimits> & Readonly<{
  showMarkersLimitOptions: boolean;
  onMarkersCountChange: (count: number) => void;
  onMarkersLimitTypeChange: (limitType: ProximityLimitMarkersType) => void;
  onMarkersInclusionTypeChange: (inclusionType: ProximityLimitInclusionType) => void;
  onIsReturnMarkersOnlyOnceChange: (returnOnlyOnce: boolean) => void;
}>;

export const ExportProximityLimitResultsSectionComponent: FC<ExportProximityLimitResultsSectionProps> = (props) => {
  const [t] = useTranslation();

  const markersLimitItems = [{
    value: ProximityLimitMarkersType.All,
    label: t('Return All'),
  }, {
    value: ProximityLimitMarkersType.ClosestToCenter,
    label: t('Markers Closest to the Center'),
    children: (
      <div css={markersCountWrapperStyle}>
        <InputWithSpinnersComponent
          width={80}
          value={props.markersCount}
          onChange={props.onMarkersCountChange}
        />
        <span css={markersLabelStyle}>{t('Markers')}</span>
      </div>
    ),
  }];

  const markerInclusionItems = [{
    value: ProximityLimitInclusionType.Inside,
    label: t('Markers inside Radii and Polygons'),
  }, {
    value: ProximityLimitInclusionType.Outside,
    label: t('All Markers outside of Results'),
    ...(props.markersLimitType === ProximityLimitMarkersType.ClosestToCenter
      ? {
        isDisabled: true,
        tooltip: t('exportProximity.limitResults.inclusion.allMarkersDisabledWhenClosestSelected'),
      } : {}
    ),
  }];

  return (
    <div>
      {props.showMarkersLimitOptions && (
        <div css={sectionStyle}>
          <strong css={headingStyle}>{t('Limit Markers per Radius')}</strong>

          <div css={sectionContentStyle}>
            <RadioGroupComponent<ProximityLimitMarkersType>
              css={sectionContentStyle}
              selectedValue={props.markersLimitType}
              onValueChange={props.onMarkersLimitTypeChange}
              items={markersLimitItems}
            />
          </div>
        </div>
      )}

      <div css={sectionStyle}>
        <strong css={headingStyle}>{t('Select Marker Inclusion Criteria')}</strong>

        <RadioGroupComponent<ProximityLimitInclusionType>
          css={sectionContentStyle}
          selectedValue={props.markersInclusionType}
          onValueChange={props.onMarkersInclusionTypeChange}
          items={markerInclusionItems}
        />
      </div>

      <div css={sectionStyle}>
        <CheckboxComponent
          checkmarkStyle={returnOnceCheckmarkStyle}
          isChecked={props.isReturnMarkersOnlyOnce}
          checkedSetter={(event) => props.onIsReturnMarkersOnlyOnceChange(event.target.checked)}
          text={(
            <div>
              {t('Return marker only once')}

              <p css={returnOnceWarningStyle}>
                {t('Each marker will only be returned for the radius where it is closest to the center.')}
              </p>
            </div>
          )}
        />
      </div>
    </div>
  );
};
