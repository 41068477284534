import {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { type LatLng } from '~/_shared/types/latLng';
import {
  convertPxDistanceToDifferentZoom, ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { useThrottle } from '~/_shared/utils/hooks/useThrottle';
import { useMap } from '~/map/map/mapContext';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';
import {
  drawingToolItemResizeStarted, drawingToolItemResizeStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import {
  DRAWING_TEXT_AREA_MIN_HEIGHT, DRAWING_TEXT_AREA_MIN_WIDTH,
} from './drawingToolTextArea.constants';
import {
  type DrawingTextAreaSizeProps, type DrawingTextAreaSizeWithText,
} from './useDrawingToolTextAreaScalingToggle';

export const useDrawingToolTextAreaResizeEvents = (
  props: DrawingTextAreaSizeProps,
  onChangeCallback: (props: DrawingTextAreaSizeWithText) => void
) => {
  const dispatch = useDispatch();

  const [resizeStartLatLng, setResizeStartLatLng] = useState<LatLng | null>(null);
  const [resizeStartDimensions, setResizeStartDimensions] = useState<QuadrilateralDimensions | null>(null);

  // we use map.getZoom() to get current zoom because the callbacks are debounced/throttled
  const map = useMap();
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);

  const onChangeRef = useRef(onChangeCallback);
  onChangeRef.current = onChangeCallback;
  const onChange = useCallback((props: DrawingTextAreaSizeWithText) => onChangeRef.current(props), []);

  const onDragMove = useCallback((latLng: LatLng) => {
    if (!resizeStartLatLng || !resizeStartDimensions) {
      return;
    }

    const originalPoint = fromLatLngToDivPixel(resizeStartLatLng);
    const newPoint = fromLatLngToDivPixel(latLng);

    if (!originalPoint || !newPoint || !map) {
      return;
    }

    const moveVector = { x: newPoint.x - originalPoint.x, y: newPoint.y - originalPoint.y };
    const textAreaToBodyRatio = (props.textAreaToBodyPercentage ?? 100) / 100;

    const newDimensions: QuadrilateralDimensions = {
      width: Math.max(DRAWING_TEXT_AREA_MIN_HEIGHT, resizeStartDimensions.width + moveVector.x * textAreaToBodyRatio),
      height: Math.max(DRAWING_TEXT_AREA_MIN_WIDTH, resizeStartDimensions.height + -moveVector.y * textAreaToBodyRatio),
    };

    const mapZoom = map.getZoom();
    if (mapZoom) {
      const scaledDimensions = props.scalesWithMapZoom ? {
        width: convertPxDistanceToDifferentZoom({ distance: newDimensions.width, originalZoom: mapZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS }),
        height: convertPxDistanceToDifferentZoom({ distance: newDimensions.height, originalZoom: mapZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS }),
      } : newDimensions;

      const scaledFontSize = props.scalesWithMapZoom
        ? convertPxDistanceToDifferentZoom({ distance: props.fontSize, originalZoom: mapZoom, newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS })
        : props.fontSize;

      onChange({
        ...scaledDimensions,
        fontSize: scaledFontSize,
      });
    }
  }, [fromLatLngToDivPixel, map, onChange, props.fontSize, props.scalesWithMapZoom,
    props.textAreaToBodyPercentage, resizeStartDimensions, resizeStartLatLng]);

  const onDragMoveThrottled = useThrottle(onDragMove, [onDragMove], 100);

  const onDragEnd = useCallback(() => {
    setResizeStartLatLng(null);
    dispatch(drawingToolItemResizeStopped());
  }, [dispatch]);

  const onDragStart = useCallback((latLng: LatLng) => {
    setResizeStartLatLng(latLng);
    setResizeStartDimensions({ width: props.width, height: props.height });
    dispatch(drawingToolItemResizeStarted('ne-resize'));
  }, [dispatch, props.height, props.width]);

  return {
    onDragStart,
    onDragEnd,
    onDragMove: onDragMoveThrottled,
  };
};
