import {
  type createBrowserRouter, matchPath,
} from 'react-router-dom';
import {
  MAP_ROUTE, PUBLIC_MAP_ROUTE,
} from '../../constants/routes';

declare global {
  interface Window {
    router: ReturnType<typeof createBrowserRouter>;
  }
}

export const isRouteActive = (currentPathname: string, routePath: string): boolean => {
  return !!matchPath({ path: routePath, end: true }, currentPathname);
};

export const isPresentationalMapRoute = (pathname: string): boolean => {
  return !!matchPath({ path: PUBLIC_MAP_ROUTE, end: false }, pathname);
};

export const isMapRoute = (pathname: string): boolean => isRouteActive(pathname, MAP_ROUTE);
