import arialAtlas from '../../../../assets/fonts/arial/atlas.png';
import arialConfig from '../../../../assets/fonts/arial/config.json';
import arialBoldAtlas from '../../../../assets/fonts/arial-bold/atlas.png';
import arialBoldConfig from '../../../../assets/fonts/arial-bold/config.json';
import azeretMonoNumberslAtlas from '../../../../assets/fonts/azeretMono-numbers/atlas.png';
import azeretMonoNumbersConfig from '../../../../assets/fonts/azeretMono-numbers/config.json';
import comicAtlas from '../../../../assets/fonts/comic-sans/atlas.png';
import comicConfig from '../../../../assets/fonts/comic-sans/config.json';
import tahomaAtlas from '../../../../assets/fonts/tahoma/atlas.png';
import tahomaConfig from '../../../../assets/fonts/tahoma/config.json';

/**
 * Consider importing font configs if the values keep changing
 * lineHeight: value copied from font's config.js
 * base: value copied from font's config.js
 * padding: value copied from font's config.js
 * numericalYOffsetPercent: when you know a label is using only numbers, to better vertically align them you can use this offset
 */

export const mapFont = {
  arial: {
    name: 'arial',
    pathAtlas: arialAtlas,
    pathConfig: arialConfig,
    lineHeight: 46,
    base: 33,
    padding: 2,
    numericalYOffsetPercent: 0.12,
  },
  arialBold: {
    name: 'arial-bold',
    pathAtlas: arialBoldAtlas,
    pathConfig: arialBoldConfig,
    lineHeight: 35,
    base: 29,
    padding: 6,
    numericalYOffsetPercent: 0.12,
  },
  comicSans: {
    name: 'comic-sanc',
    pathAtlas: comicAtlas,
    pathConfig: comicConfig,
    lineHeight: 46,
    base: 36,
    padding: 2,
    numericalYOffsetPercent: 0.15,
  },
  tahoma: {
    name: 'tahoma',
    pathAtlas: tahomaAtlas,
    pathConfig: tahomaConfig,
    lineHeight: 42,
    base: 34,
    padding: 2,
    numericalYOffsetPercent: 0.10,
  },
  azeretMonoNumbers: {
    name: 'azeretMono-numbers',
    pathAtlas: azeretMonoNumberslAtlas,
    pathConfig: azeretMonoNumbersConfig,
    lineHeight: 37,
    base: 36,
    padding: 6,
    numericalYOffsetPercent: 0.15,
  },
} as const;

type MapFontKeys = keyof typeof mapFont;
export type MapFont = typeof mapFont[MapFontKeys];
