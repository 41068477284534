import {
  type Territory, type TerritoryGroup,
} from '../_shared/types/territories';
import {
  type PolygonGroupResponse, type PolygonResponse,
} from './polygonGroup.repository';

const defaultNumber = 1;

export const createTerritoryFromPolygonResponse = (
  responsePolygons: PolygonResponse[], groupId: number
): ReadonlyArray<Territory> => responsePolygons.map(polygon => ({
  id: polygon.id,
  name: polygon.name,
  borderColor: polygon.options.border_color,
  borderWidth: +(polygon.options.border_width ?? defaultNumber),
  fillColor: polygon.options.fill_color,
  fillOpacity: +(polygon.options.fill_opacity ?? defaultNumber),
  territoryGroupId: groupId,
  path: polygon.coordinates,
}));

export const createPolygonGroupFromPolygonGroupsResponse = (
  responseData: ReadonlyArray<PolygonGroupResponse>
): ReadonlyMap<number, TerritoryGroup> => {
  const mapProps: ReadonlyArray<[number, TerritoryGroup]> = responseData.map(group => [
    group.id,
    {
      clientId: group.client_id,
      id: group.id,
      name: group.name,
      territories: group.polygons ? createTerritoryFromPolygonResponse(group.polygons, group.id) : [],
      userId: group.user_id,
    },
  ]);

  return new Map<number, TerritoryGroup>(mapProps);
};
