import { apiGet } from '../../_shared/utils/api/api.helpers';
import { type UserResponse } from '../../store/userData/repository/userData.repository';

export type EmailChangeConfirmRequest = {
  expires: string;
  hash: string;
  id: string;
  signature: string;
};

export enum EmailChangeConfirmErrorCodes {
  SERVER_ERROR = 'SERVER_ERROR', // Exception captured in the process of updating.
  BAD_REQUEST = 'BAD_REQUEST',
  EMAIL_NOT_UNIQUE = 'EMAIL_NOT_UNIQUE', //Email has already been taken.
  USER_NOT_FOUND = 'USER_NOT_FOUND', //Failed to found user in the database.
  FAILED = 'FAILED', //Failed to save user data.
  CONFIRMATION_NOT_FOUND = 'CONFIRMATION_NOT_FOUND', //Confirmation URL has already been used.
  USER_MISMATCH = 'USER_MISMATCH' // Another user is logged in
}

export const getEmailChangeConfirmRequest = (request: EmailChangeConfirmRequest): Promise<UserResponse> => {
  const requestUrl = '/api/user/confirm-email-change';
  return apiGet(requestUrl, request);
};
