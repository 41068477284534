export const getNodeContentWidth = (node: HTMLElement): number => {
  let sum = 0;

  for (const child of Array.from(node.children)) {
    sum += child.getBoundingClientRect().width;
    const childStyle = window.getComputedStyle(child);
    sum += parseFloat(childStyle.marginTop)
          + parseFloat(childStyle.marginRight)
          + parseFloat(childStyle.marginBottom)
          + parseFloat(childStyle.marginLeft);
  }

  return sum;
};

const getNodeParents = (elem: (Node & ParentNode) | null): HTMLElement[] => {
  // Set up a parent array
  const parents: HTMLElement[] = [];

  let node = elem;

  // Push each parent element to the array
  for (; node && node !== document; node = node.parentNode) {
    parents.push(node as HTMLElement);
  }

  // Return our parent array
  return parents;
};

const isNodeScrollable = (node: HTMLElement): boolean => {
  const nodeStyle = getComputedStyle(node);
  // added as proper styling to the scrollbar scroller is added after mounting and some calculations
  // so if you are want to add proper scroll event on your component mount then the scroller might not
  // have the proper styling yet
  const isCustomScrollbarContainer = node.classList.contains('ScrollbarsCustom-Scroller');

  return isCustomScrollbarContainer || nodeStyle.overflowY === 'auto' || nodeStyle.overflowY === 'scroll';
};

export const getNodeScrollableParents = (node: HTMLElement): HTMLElement[] => {
  const parents = getNodeParents(node);

  return parents.filter(e => isNodeScrollable(e));
};

export const getNodeFirstScrollableParent = (node: HTMLElement): HTMLElement | undefined => getNodeScrollableParents(node)[0];

type WithStopPropagation = Readonly<{ stopPropagation: () => void }>;
export const stopPropagationAnd = (fnc?: () => void) => (e: WithStopPropagation) => {
  e.stopPropagation();
  fnc?.();
};

type WithPreventDefault = Readonly<{ preventDefault: () => void }>;
export const preventDefault = (arg: WithPreventDefault) => arg.preventDefault();

export const preventDefaultAnd = (fnc: () => void) => (e: WithPreventDefault) => {
  e.preventDefault();
  fnc();
};

export const isDomNode = (object: any): object is Node => object && object instanceof Node;

// autocomplete="off" doesn't work in chromium right now
// we use random value that won't match any value from the specs
// https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
export const autoCompleteDisabled = 'autocomplete-disabled';
