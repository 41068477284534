import {
  type SelectedDataMetric, SelectedDataType,
} from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type MetricServerModel } from '../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  createDemographicFromResponse,
  createServerModelFromDemographic,
} from '../../../store/demographics/demographics.factory';
import {
  createServerModelFromSpreadsheetColumn,
  createSpreadsheetColumnFromServerModel,
} from '../../types/spreadsheetData/spreadsheetColumn.factory';
import {
  createDemographicMetricFromSelectedData,
  createSelectedDataFromDemographicMetric,
  type DemographicMetricModel,
} from './demographicsMetric.factory';
import {
  createSelectedDataFromSpreadsheetColumnMetric,
  createSpreadsheetMetricFromSelectedData,
  type SpreadsheetColumnMetricModel,
} from './spreadsheetMetric.factory';

export type MetricModel = SpreadsheetColumnMetricModel | DemographicMetricModel;

export const createMetricFromSelectedData = (selectedData: SelectedDataMetric): MetricModel | null => {
  switch (selectedData.type) {
    case SelectedDataType.Demographic: {
      return createDemographicMetricFromSelectedData(selectedData);
    }
    case SelectedDataType.SpreadsheetColumn: {
      return createSpreadsheetMetricFromSelectedData(selectedData);
    }
    default: {
      return null;
    }
  }
};

export const createSelectedDataFromMetric = (metric: MetricModel): SelectedDataMetric | null => {
  switch (metric.type) {
    case SelectedDataType.Demographic:
      return createSelectedDataFromDemographicMetric(metric);
    case SelectedDataType.SpreadsheetColumn:
      return createSelectedDataFromSpreadsheetColumnMetric(metric);
    default: {
      return null;
    }
  }
};

export const validateMetricModel = (metric: MetricModel): boolean => {
  switch (metric.type) {
    case SelectedDataType.Demographic: {
      if (typeof metric.demographic.id === 'number') {
        return false; // old model, filter out
      }

      return true;
    }

    case SelectedDataType.SpreadsheetColumn: {
      return true;
    }

    default: { // old model
      return false;
    }
  }
};

// returns null in case of the older MetricModel that didn't have the type property
export const createMetricFromResponse = (response: MetricServerModel): MetricModel | null => {
  switch (response.type) {
    case SelectedDataType.Demographic: {
      if (typeof response.demographic.id === 'number') {
        return null; // old model, filter out
      }

      return {
        type: SelectedDataType.Demographic,
        demographic: createDemographicFromResponse(response.demographic),
      };
    }

    case SelectedDataType.SpreadsheetColumn: {
      return {
        type: SelectedDataType.SpreadsheetColumn,
        dataAction: response.dataAction,
        spreadsheetColumn: createSpreadsheetColumnFromServerModel(response.spreadsheetColumn),
      };
    }

    default: {
      return null;
    }
  }
};

// returns null in case of the older MetricModel that didn't have the type property
export const createServerModelFromMetric = (metric: MetricModel): MetricServerModel | null => {
  if (!validateMetricModel) {
    return null;
  }

  switch (metric.type) {
    case SelectedDataType.Demographic: {
      return ({
        type: SelectedDataType.Demographic,
        demographic: createServerModelFromDemographic(metric.demographic),
      });
    }

    case SelectedDataType.SpreadsheetColumn: {
      return ({
        type: SelectedDataType.SpreadsheetColumn,
        dataAction: metric.dataAction,
        spreadsheetColumn: createServerModelFromSpreadsheetColumn(metric.spreadsheetColumn),
      });
    }

    default: {
      return null;
    }
  }
};

export const mapMetricsToServerModel = (metrics: MetricModel[]): MetricServerModel[] => metrics
  .map((metric) => createServerModelFromMetric(metric))
  .filter((metric): metric is MetricServerModel => metric !== null);
