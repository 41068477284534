import { css } from '@emotion/react';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { type DrawingTool } from '../../../../drawingTool/drawingTool.enums';

type DrawingToolDetailsFooterProps = Readonly<{
  selectedTool: DrawingTool;
  isResetDisabled: boolean;

  onReset: () => void;
}>;

const wrapperStyle = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: 5,
});

const buttonStyle = css({
  padding: '10px 28px 10px 10px',
  height: 40,
});

const buttonIconStyle = css({
  marginRight: 13,
});

export const DrawingToolDetailsFooterComponent: FC<DrawingToolDetailsFooterProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div css={wrapperStyle}>
      <ButtonComponent
        buttonStyle={ButtonStyle.Secondary}
        prefixIcon={faUndo}
        css={buttonStyle}
        prefixIconStyle={buttonIconStyle}
        onClick={props.onReset}
        text={t('Reset')}
        isDisabled={props.isResetDisabled}
      />
    </div>
  );
};
