import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useDistanceCalculatorInstanceZIndex } from '~/map/zIndexes/useMapPolygonInstanceZIndex.hook';
import { type LatLng } from '../../../_shared/types/latLng';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import {
  hoverDistanceCalculatorOutline,
  stopDistanceCalculator,
  stopDistanceCalculatorOutlineHover,
} from '../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.actionCreators';
import {
  useDistanceCalculatorIsActiveSelector,
  useDistanceCalculatorModeSelector, useDistanceCalculatorStartingPointSelector,
} from '../../../store/frontendState/mapTools/distanceCalculator/distanceCalculator.selectors';
import { submitDistanceCalculatorInstance } from '../../../store/mapSettings/toolsState/distanceCalculator/mapSettingsDistanceCalculator.actionCreators';
import { type DistanceCalculatorInstance } from '../../../store/mapSettings/toolsState/distanceCalculator/mapSettingsDistanceCalculator.state';
import { MapOutline } from '../mapObjects/mapOutline/mapOutline.component';
import { type MapObjectOutlineInstance } from '../mapObjects/mapOutline/mapOutlineModel';
import {
  DrawMapShape,
  type ShapeSubmitOption,
} from '../mapObjects/mapShape/drawMapShape.container';
import { MapShapeLine } from '../mapObjects/mapShape/mapShapeLine.component';
import { type MapShapeInstance } from '../mapObjects/mapShape/mapShapeModel';
import { calculateDistances } from './calculateDistance';
import {
  dcLineVisuals,
  dcOutlineHoverVisuals,
  dcOutlineVisuals,
} from './distanceCalculatorVisuals';

export const UnsavedDCInstance: React.FC = () => {
  const unitSystem = useSelector(state => state.map.mapSettings.data.settings.unitSystem);
  const mode = useDistanceCalculatorModeSelector();
  const toolActive = useDistanceCalculatorIsActiveSelector();
  const startingPoint = useDistanceCalculatorStartingPointSelector();
  const isDrawing = mode !== 'pick-mode' && toolActive;

  const [shape, setShape] = useState<MapShapeInstance>();
  const [firstOutlineHover, setFirstOutlineHover] = useState<boolean>(false);

  const dispatch = useDispatch();
  const zIndex = useDistanceCalculatorInstanceZIndex(shape?.id);

  const outlineDistances = useMemo(() => shape?.outlines ? calculateDistances(unitSystem, shape.outlines) : undefined,
    [unitSystem, shape?.outlines]);

  const submit = useCallback((instance: DistanceCalculatorInstance) => {
    setShape(undefined);
    if (toolActive) {
      dispatch(stopDistanceCalculator());
    }

    if (instance.outlines.length > 1) {
      dispatch(submitDistanceCalculatorInstance(instance));
    }
  }, [dispatch, toolActive]);

  const onDone = useCallback((how: ShapeSubmitOption) => {
    const isPolygon = how === 'FirstOutline';
    const minOutlinesCount = isPolygon ? 3 : 2;

    if (!shape || shape.outlines.length < minOutlinesCount) {
      dispatch(stopDistanceCalculator());
      return;
    }

    const instance = {
      ...shape,
      isPolygon,
    };

    submit(instance);
  }, [dispatch, shape, submit]);

  const onChange = useCallback((shape: MapShapeInstance) => {
    if (mode === 'single-point' && shape.outlines.length > 1) {
      const instance = {
        ...shape,
        isPolygon: false,
      };

      submit(instance);
      return;
    }

    setShape(shape);
  }, [mode, submit]);

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (isFirstOutlineHoverEnabled(shape, outlineId)) {
      setFirstOutlineHover(true);
      dispatch(hoverDistanceCalculatorOutline());
    }
  }, [shape, dispatch]);

  const onOutlineMouseOut = useCallback((_outlineId: Uuid) => {
    setFirstOutlineHover(false);
    dispatch(stopDistanceCalculatorOutlineHover());
  }, [dispatch]);

  const renderOutline = useCallback((id: string, outline: MapObjectOutlineInstance) => (
    <MapOutline
      key={id}
      outline={outline}
      visuals={isFirstOutline(shape, outline.id) && firstOutlineHover ? dcOutlineHoverVisuals : dcOutlineVisuals}
      label={outlineDistances?.find(item => item.outlineId === outline.id)?.distance}
      onMouseOver={onOutlineMouseOver}
      onMouseOut={onOutlineMouseOut}
    />
  ), [shape, firstOutlineHover, outlineDistances, onOutlineMouseOver, onOutlineMouseOut]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => (
    <MapShapeLine
      key={id}
      id={id}
      start={start}
      end={end}
      visuals={dcLineVisuals}
    />
  ), []);

  const getCursorLabel = useCallback((cursorLocation: LatLng) => {
    if (firstOutlineHover) {
      return null;
    }

    return shape?.outlines?.length
      ? calculateDistances(unitSystem, shape.outlines, cursorLocation).lastItem.distance
      : null;
  }, [unitSystem, shape?.outlines, firstOutlineHover]);

  // Tool is turned off with unfinished drawing
  useEffect(() => {
    if (!isDrawing && shape) {
      submit({ ...shape, isPolygon: false });
    }
  }, [isDrawing, shape, submit]);

  return (
    <>
      {isDrawing && (
        <DrawMapShape
          zIndex={zIndex}
          onChange={onChange}
          onDone={onDone}
          renderOutline={renderOutline}
          renderLine={renderLine}
          getCursorLabel={getCursorLabel}
          startingPoint={startingPoint}
        />
      )}
    </>
  );
};

const isFirstOutlineHoverEnabled = (shape: MapShapeInstance | undefined, outlineId: string) =>
  isFirstOutline(shape, outlineId) && !!(shape && shape.outlines.length > 2);

const isFirstOutline = (shape: MapShapeInstance | undefined, outlineId: string) =>
  !!(shape?.outlines[0]?.id === outlineId);
