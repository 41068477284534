import {
  faComments,
  faInfoCircle,
  faPaperPlane, faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useMemo,
} from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { MAPTIVE_FAQ } from '~/_shared/constants/maptive';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { useTranslation } from '~/_shared/utils/hooks';
import { openUrlInNewTab } from '~/_shared/utils/link/link';
import {
  bottomPartItems, menuItemFontSize, topPartItems,
} from '~/mainMenu/mainMenu.constants';
import {
  movableMainStyles, movablePartStyles, placeholderStyles, scrollableMainStyles, upperAndLowerPartStyles,
} from '~/mainMenu/mainMenu.styles';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  decideIsEnoughVerticalSpaceForMenuItems, getMainMenuItemIcon, getMainMenuItemTitle,
} from './mainMenu.helpers';
import {
  type MainMenuItem, MainMenuItems,
} from './mainMenuItem.enum';
import {
  MainMenuItemComponent, type MainMenuItemProps, type SingleItemProps,
} from './menuItem/mainMenuItem.component';

export type MainMenuProps = Readonly<{
  disabledItems: ReadonlySet<MainMenuItem>;
  isPresentationalMenuOpen: boolean;
  isViewPresentational: boolean;
  menuHeight: number;
  selectedItems: ReadonlySet<MainMenuItem>;
  visibleMenuItems: ReadonlySet<MainMenuItem>;
  dangerMenuItems: ReadonlySet<MainMenuItem>;

  onItemClick: (newApp: MainMenuItem) => void;
  openChat: () => void;
}>;

export const MainMenuComponent: FC<MainMenuProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { openModal: openContactUs } = useModal(ModalType.ContactUs);
  const { openModal: openAboutUs } = useModal(ModalType.AboutUs);

  const { onItemClick } = props;
  const showAsColumn = props.isPresentationalMenuOpen;

  const handleItemClick = useCallback((clickedItem: MainMenuItem) =>
    () => onItemClick(clickedItem),
  [onItemClick]);

  const getMenuItemProps = useCallback((item: MainMenuItem): MainMenuItemProps => ({
    fontSize: menuItemFontSize[item],
    icon: getMainMenuItemIcon(item),
    isDisabled: props.disabledItems.has(item),
    isDanger: props.dangerMenuItems.has(item),
    isHighlighted: props.selectedItems.has(item) ||
      (item === MainMenuItems.Account && props.selectedItems.has(MainMenuItems.AccountSettings)),
    onClick: handleItemClick(item),
    title: getMainMenuItemTitle(item, t, { useShortVariant: showAsColumn }),
  }), [props.disabledItems, props.dangerMenuItems, props.selectedItems, handleItemClick, t, showAsColumn]);

  const faqClick = useCallback(() => openUrlInNewTab(MAPTIVE_FAQ), []);

  const innerItemsHelp = useMemo(() => {
    const commonInnerItemProps: Pick<SingleItemProps, 'isDisabled' | 'fontSize'> = {
      isDisabled: false,
    };

    return [
      { icon: faInfoCircle, title: t('aboutUs.title'), onClick: openAboutUs, testId: 'open-about-us', ...commonInnerItemProps },
      { icon: faPaperPlane, title: t('Contact'), onClick: openContactUs, ...commonInnerItemProps },
      { icon: faComments, title: t('Live Chat'), onClick: props.openChat, ...commonInnerItemProps },
      { icon: faQuestion, title: t('FAQ'), onClick: faqClick, ...commonInnerItemProps },
      //TODO: include tutorials once we have them
      // { icon: faBooks, title: t('Tutorials'), onClick: unsupportedOnClick, ...commonInnerItemProps },
    ];
  }, [faqClick, openAboutUs, openContactUs, props.openChat, t]);

  const innerItemsAccount = useMemo(() => [
    getMenuItemProps(MainMenuItems.AccountSettings),
    getMenuItemProps(MainMenuItems.LogOut),
  ], [getMenuItemProps]);
  const innerItemsShareAndPresent = useMemo(() => [
    getMenuItemProps(MainMenuItems.Present),
    getMenuItemProps(MainMenuItems.ShareMap),
  ], [getMenuItemProps]);

  const innerItemsAddNewData = useMemo(() => [
    getMenuItemProps(MainMenuItems.AddNewDataRow),
    getMenuItemProps(MainMenuItems.AddNewDataColumn),
  ], [getMenuItemProps]);

  const getInnerItems = useCallback((item: MainMenuItem) => {
    switch (item) {
      case MainMenuItems.Help:
        return innerItemsHelp;

      case MainMenuItems.Account:
        return innerItemsAccount;

      case MainMenuItems.ShareMapAndPresent:
        return innerItemsShareAndPresent;

      case MainMenuItems.AddNew:
        return innerItemsAddNewData;

      default:
        return undefined;
    }
  }, [innerItemsAccount, innerItemsAddNewData, innerItemsHelp, innerItemsShareAndPresent]);

  const movableUpperMenuPart = useMemo(() => {
    const visibleMenuItems = topPartItems.filter(item => props.visibleMenuItems.has(item));
    const isEnoughSpaceForItems = decideIsEnoughVerticalSpaceForMenuItems(props.menuHeight, visibleMenuItems.length);

    return (
      <div
        css={movableMainStyles({
          availableHeight: props.menuHeight,
          itemsCount: visibleMenuItems.length,
          isBackground: isEnoughSpaceForItems,
          isDisplayedAsColumns: showAsColumn,
          theme,
        })}
      >
        {visibleMenuItems.map(item => (
          <MainMenuItemComponent
            css={movablePartStyles({ theme, isFolded: !isEnoughSpaceForItems })}
            {...getMenuItemProps(item)}
            innerMenu={getInnerItems(item)}
            key={getMainMenuItemTitle(item, t, { useShortVariant: showAsColumn })}
          />
        ))}
      </div>
    );
  }, [getInnerItems, getMenuItemProps, showAsColumn, props.menuHeight, props.visibleMenuItems, t, theme]);

  const scrollableUpperMenuPart = useMemo(() => (
    <div css={scrollableMainStyles({ theme })}>
      <ScrollBarComponent
        css={upperAndLowerPartStyles({ flexShrink: 1 })}
        type={ScrollbarType.Vertical}
      >
        {topPartItems
          .filter(item => props.visibleMenuItems.has(item))
          .map(item => (
            <MainMenuItemComponent
              {...getMenuItemProps(item)}
              innerMenu={getInnerItems(item)}
              key={getMainMenuItemTitle(item, t, { useShortVariant: showAsColumn })}
            />
          ))}
      </ScrollBarComponent>

      <div css={upperAndLowerPartStyles({ flexShrink: 0 })}>
        {bottomPartItems
          .filter(item => props.visibleMenuItems.has(item))
          .map(item => (
            <MainMenuItemComponent
              {...getMenuItemProps(item)}
              innerMenu={getInnerItems(item)}
              key={getMainMenuItemTitle(item, t, { useShortVariant: showAsColumn })}
            />
          ))}
      </div>

    </div>
  ), [getInnerItems, getMenuItemProps, props.visibleMenuItems, showAsColumn, t, theme]);

  return (
    <>
      {props.isViewPresentational ? movableUpperMenuPart : scrollableUpperMenuPart}

      <div css={placeholderStyles} />
    </>
  );
};
