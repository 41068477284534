import { type ReactNode } from 'react';
import markerMapPlaceholder from '../../../../../assets/images/markerMapPlaceholder.png';
import {
  getContainerCss, markerMapPlaceholderStyle,
} from './tinyMapPlaceholder.styles';

export const TinyMapPlaceholderSize = {
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
} as const;

export type TinyMapPlaceholderSize = typeof TinyMapPlaceholderSize[keyof typeof TinyMapPlaceholderSize];

export type TinyMapPlaceholderComponentProps = {
  className?: string;
  size?: TinyMapPlaceholderSize;
  children?: ReactNode;
};

export const TinyMapPlaceholderComponent = (props: TinyMapPlaceholderComponentProps) => {
  const { className, size = 'Medium', children } = props;
  return (
    <div
      className={className}
      css={getContainerCss(size)}
    >
      <img
        css={markerMapPlaceholderStyle}
        src={markerMapPlaceholder}
        alt="Marker map placeholder"
      />
      {children}
    </div>
  );
};
