import type {
  UserEvent, UserEventData, UserEventType,
} from '~/store/userEvents/userEvents.type';

type CreateUserEvent =
  <TUserEvent extends UserEventType>(
    eventType: TUserEvent,
    ...args: UserEventData<TUserEvent> extends never ? [] : [UserEventData<TUserEvent>]
  ) => UserEvent<TUserEvent>;

export const createUserEvent: CreateUserEvent = (eventType, ...[eventData]) => {
  const timeStamp = Date.now();
  return {
    eventType,
    eventData: eventData ? eventData : undefined as never,
    timeStamp,
  };
};
