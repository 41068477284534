import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons';
import {
  FadingDots, ProgressBarComponent,
} from '~/_shared/baseComponents/loaders';
import { InlineWarningComponent } from '~/_shared/baseComponents/warning/inlineWarning.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { v4MapImportDialogLayout } from '~/map/mapMigration/migrateMapsDialog.styles';
import type { ModalProps } from '~/modal/modalType.enum';

type CreateNewMapProps = ModalProps;

const progressInfoStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const textStyle = css({
  fontSize: 14,
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  gap: 8,
});

const progressBarWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const linkButtonStyle = ({ theme }: ThemeProps) => css({
  border: 'none',
  color: theme.textColors.link,
  padding: 0,
  background: 'transparent',
  '&:hover': {
    border: 'none',
    background: 'transparent',
    textDecoration: 'underline',
  },
});

const progressStyle = css({
  margin: '2px 0',
});

export const MigrateMapsInProgressComponent: FC<CreateNewMapProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const { processedMaps, totalMaps, percentage } = useSelector(state => state.mapMigration.migrationStatus);

  return (
    <ModalComponent
      onClose={noop}
      isCloseDisabled
      isOpen={props.isOpen}
      caption={t('Map Migration in Progress')}
      maxWidth={1000}
    >
      <div css={v4MapImportDialogLayout}>
        <h1>
          {t('A Migration Is Currently Underway')}
        </h1>
        <p>
          {t('MapMigrationProgressExplanation')}
        </p>
        <div
          css={progressInfoStyles}
        >
          <p>
            <InlineWarningComponent>{t('Note:')}</InlineWarningComponent>{' '}
            {t('It may take a while before you start to see your maps.')}
          </p>

          <div css={progressBarWrapperStyle}>
            <span css={textStyle}>
              {t('MapMigrationMapsMigrated')} {processedMaps}/{totalMaps}<FadingDots />
            </span>

            <ProgressBarComponent
              css={progressStyle}
              progress={Math.max(percentage, 5)}
            />

            <span css={textStyle}>
              <ButtonComponent
                text={t('Click here')}
                css={linkButtonStyle({ theme })}
                onClick={refreshPage}
              /> {t('if it looks like the process is frozen')}
            </span>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
