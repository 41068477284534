import {
  useCallback, useMemo, useState,
} from 'react';
import { type ColumnsWithValuesDataType } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueColumns';
import { useCurrentGoogleAccountIdSelector } from '~/store/selectors/googleUserSelectors.selector';
import {
  type GetGoogleSpreadsheetDataFailResponse,
  type GetGoogleSpreadsheetDataSuccessResponse,
  getGoogleSpreadsheetSheetData, GOOGLE_AUTH_ERROR,
} from '../../../spreadsheet/googleSpreadsheet/googleSheet.repository';
import {
  type DataSource, type DataSourceColumnInfo, DataSourceError,
} from './dataSource';

export type GoogleSheetMetaData = Readonly<{
  sheetId: string;
  spreadsheetId: string;
}>;

export const useGetGoogleSheetDataAsColumnsWithValues = (selectedGoogleSheet: GoogleSheetMetaData | null): DataSource => {
  const currentGoogleAccId = useCurrentGoogleAccountIdSelector();

  const [headerAndData, setHeaderAndData] = useState<ColumnsWithValuesDataType | null>(null);
  const [header, setHeader] = useState<DataSourceColumnInfo[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<DataSourceError | null>(null);

  const getHeaderAndData = useCallback(() => {
    if (!currentGoogleAccId) {
      setError(DataSourceError.GoogleUnauthenticated);
      return;
    }
    else {
      setError(null);
    }

    if (!selectedGoogleSheet) {
      setHeaderAndData(null);
      return;
    }

    const { spreadsheetId: googleSpreadsheetId, sheetId } = selectedGoogleSheet;

    setIsLoading(true);
    getGoogleSpreadsheetSheetData(currentGoogleAccId, googleSpreadsheetId, sheetId).then((response: GetGoogleSpreadsheetDataSuccessResponse) => {
      const rows = response.data?.rows || [];

      if (!rows.length || rows.length === 1) {
        setHeaderAndData(null);
        setIsLoading(false);
        setError(DataSourceError.Empty);
        return;
      }

      if (!rows[0]) {
        setHeaderAndData(null);
        setIsLoading(false);
        setError(DataSourceError.NoHeader);
        return;
      }

      const headerColumns = rows[0].map((value, index) => ({ name: value, id: 'column_' + index }));
      setHeader(headerColumns);

      const resultData: ColumnsWithValuesDataType = headerColumns.reduce((result, columnInfo, columnIndex) => {
        const values = rows
          .slice(1) // remove the column name (is on position 0)
          .map((row) => row[columnIndex]);

        return ({
          ...result,
          [columnInfo.id]: values,
        });
      }, {});

      setHeaderAndData(resultData);
      setIsLoading(false);
    }).catch((err: { response?: GetGoogleSpreadsheetDataFailResponse }) => {
      let newErr = DataSourceError.General;
      if (err.response?.message === GOOGLE_AUTH_ERROR) {
        newErr = DataSourceError.GoogleUnauthenticated;
      }
      setError(newErr);
      setHeaderAndData(null);
      setIsLoading(false);
    });
  }, [currentGoogleAccId, selectedGoogleSheet]);

  return useMemo(() => ({
    fetch: currentGoogleAccId ? getHeaderAndData : null,
    error,
    header,
    data: headerAndData,
    isLoading,
  }), [currentGoogleAccId, error, getHeaderAndData, header, headerAndData, isLoading]);
};
