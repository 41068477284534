import { useCallback } from 'react';
import { useAddDataRow } from '../../../data/addDataRow/useAddDataRow';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetId } from '../../../store/selectors/usePrimarySpreadsheetId';
import { type GeoLocation } from '../../utils/geolocation/geolocation';
import { getAddressFromLatLng } from '../../utils/geolocation/geolocation.helpers';
import { useGetMarkerDataFromGeoResponse } from '../../utils/geolocation/useGetMarkerDataFromGeoResponse.hook';
import { useTranslation } from '../../utils/hooks';

export const useLocationFromGeolocation = () => {
  const clientId = useClientIdSelector();
  const addMarkerCallback = useGetMarkerDataFromGeoResponse();
  const spreadsheetId = usePrimarySpreadsheetId();
  const { addNewDataRowMapPage } = useAddDataRow();
  const [t] = useTranslation();

  const getGeolocationFromLatLng = useCallback((
    params: { callback: (response: GeoLocation) => void; lat: number; lng: number },
    onIsLoadingChanged?: (loading: boolean) => void,
  ) => {
    onIsLoadingChanged?.(true);
    getAddressFromLatLng(params.lat, params.lng).then(response => {
      onIsLoadingChanged?.(false);
      params.callback(response);
    }).catch(() => {
      onIsLoadingChanged?.(false);
      params.callback({
        address: t('Unknown Location'),
        latLng: { lat: params.lat, lng: params.lng },
      });
    });
  }, [t]);

  const addLocationFromLatLng = useCallback((
    params: {
      lat: number;
      lng: number;
    },
    onIsLoadingChanged?: (loading: boolean) => void,
    onIsGeolocationFromLatLngLoadingChanged?: (loading: boolean) => void,
  ) => {
    if (!addMarkerCallback) {
      return;
    }
    onIsLoadingChanged?.(true);

    getGeolocationFromLatLng(
      {
        callback: (response: GeoLocation) => {
          if (spreadsheetId && clientId) {
            const columnValuesForNewDataRow = addMarkerCallback(response, spreadsheetId);
            addNewDataRowMapPage({
              clientId,
              virtualSpreadsheetId: spreadsheetId,
              columnValues: columnValuesForNewDataRow || {},
              lat: params.lat, lng: params.lng,
            }, (newIsLoadingValue) => {
              if (!newIsLoadingValue && onIsLoadingChanged) {
                onIsLoadingChanged(false);
              }
            });
          }
        },
        lat: params.lat, lng: params.lng,
      },
      onIsGeolocationFromLatLngLoadingChanged,
    );
  }, [clientId, spreadsheetId, addNewDataRowMapPage,
    addMarkerCallback, getGeolocationFromLatLng,
  ]);

  return { getGeolocationFromLatLng, addLocationFromLatLng };
};
