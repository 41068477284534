import { css } from '@emotion/react';
import {
  type FC, useMemo,
} from 'react';
import {
  AccordionComponent, type AccordionData, AccordionHeaderSize, accordionStyle,
} from '~/_shared/baseComponents/accordion';
import { DropdownComponent } from '~/_shared/baseComponents/dropdown';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { ToggleComponent } from '~/_shared/baseComponents/toggle';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithWheelAndTileComponent } from '~/_shared/components/colorPicker/colorPickerWithTileAndWheel/colorPickerWithTileAndWheel.component';
import { ColorWheelComponent } from '~/_shared/components/colorPicker/colorPickerWithWheel/colorWheel.component';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import { SliderWithValueLabelsComponent } from '~/_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { type Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { SettingsAccordionButtonComponent } from './settingsAccordionButton.component';
import {
  type SettingsAccordionItem, type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from './settingsAccordionData.type';
import { SettingsAccordionItemComponent } from './settingsAccordionItem.component';

const dropdownWrapperStyle = css({
  position: 'absolute',
  right: 0,
  bottom: 0,
  top: 0,
  width: '50%',
});
const dropdownStyle = css({
  position: 'relative',
  height: '100%',
});

const sliderStyle = css({
  width: '50%',
  marginRight: '14px',
});

const nestedParentStyle = css({
  boxSizing: 'border-box',
  display: 'flex',
  minHeight: 56,
  justifyContent: 'space-between',
  padding: '0 23px 0 10px',
  width: '100%',
});

const colorPickerStyle = css({
  marginRight: '8px',
});

const labelWithWheelStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: 13,
});

const buttonWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});

export type AccordionSubItemActionProps = ThemeProps<{
  isActiveIfDisabled?: boolean;
  isDisabled: boolean;
  item: SettingsAccordionSubItem;
  theme: Theme;
}>;

const renderSubItemAction = ({ item, isDisabled, isActiveIfDisabled, theme }: AccordionSubItemActionProps) => {
  switch (item.type) {
    case SettingsAccordionSubItemType.Button:
      return (
        <div css={buttonWrapperStyle}>
          {item.prefix}
          {item.customButton ?? (
            <SettingsAccordionButtonComponent
              buttonStyle={item.buttonStyle}
              isDisabled={isDisabled}
              onSubmitClick={item.onClick}
              textSelector={item.textSelector}
            />
          )}
        </div>
      );
    case SettingsAccordionSubItemType.Toggle:
      return (
        <ToggleComponent
          onChange={item.onToggle}
          isOn={item.isOn && !isDisabled || !!isActiveIfDisabled}
          isDisabled={isDisabled}
          offIcon={item.offIcon}
          offLabel={item.offLabel}
          onIcon={item.onIcon}
          onLabel={item.onLabel}
        />
      );
    case SettingsAccordionSubItemType.DropDown:
      return (
        <div css={dropdownWrapperStyle} >
          <div css={dropdownStyle} >
            <DropdownComponent
              css={dropdownStyle}
              onClose={item.onClose}
              triggerComponent={item.triggerComponent}
              isDisabled={isDisabled}
              isOpen={item.isOpen && !isDisabled}
              inPortal
            >
              <div css={accordionStyle({ theme })}>
                {item.renderedOptions}
              </div>
            </DropdownComponent>
          </div>
        </div>
      );
    case SettingsAccordionSubItemType.Slider:
      return (
        <SliderWithValueLabelsComponent
          css={sliderStyle}
          max={item.maxValue}
          min={item.minValue}
          onChange={([value]) => notNullOrUndefined(value) && item.onChange(value)}
          value={[item.value]}
          noLabel
          withDots={item.predefined}
          disabled={isDisabled}
        />
      );
    case SettingsAccordionSubItemType.ColorPicker:
      return (
        <DebouncedUpdateComponent
          value={createColor(item.color)}
          onChangeDebounced={(colorResult: ColorResult) => item.onChange(colorResult.hex)}
          wait={500}
          render={(value, onChange) => (
            <ColorPickerWithWheelAndTileComponent
              containerStyle={colorPickerStyle}
              selectedColor={value}
              onChange={onChange}
              isDisabled={isDisabled}
              displayAlpha={false}
            />
          )}
        />
      );
    case SettingsAccordionSubItemType.ColorList: {
      if (item.accordion) {
        return (
          <AccordionComponent
            itemButtonStyle={nestedParentStyle}
            panelStyle={itemsPaneStyle}
            data={prepareAccordionData([{
              ...item.accordion,
              isExpanded: isDisabled ? false : item.accordion.isExpanded,
              title: (
                <div css={labelWithWheelStyle}>
                  {item.label}
                  <ColorWheelComponent />
                </div>
              ),
            }])}
          />
        );
      }
      else {
        throw new Error(`settingsAccordion component: Empty color list, item "${JSON.stringify(item)}"`);
      }
    }
    default:
      throw new Error(`settingsAccordion component: Unknown itemType, item: "${JSON.stringify(item)}"`);
  }
};

const renderSubItems = (subItems: ReadonlyArray<SettingsAccordionSubItem>) =>
  subItems.map((subItem, index) =>
    (
      <SettingsAccordionItemComponent
        key={index}
        renderItemAction={renderSubItemAction}
      >
        {subItem}
      </SettingsAccordionItemComponent>
    ));

const prepareAccordionData = (items: ReadonlyArray<SettingsAccordionItem>): ReadonlyArray<AccordionData> =>
  items.map(i => ({
    header: i.title,
    isExpanded: i.isExpanded,
    onHeadingClick: i.isExpanded ? i.onCollapse : i.onExpand,
    child: renderSubItems(i.subItems),
  }));

const headerButtonStyle = css({
  fontSize: 16,
  paddingLeft: 10,
});

const itemsPaneStyle = css({
  padding: 0,
});

export type SettingsAccordionProps = Readonly<{
  items: ReadonlyArray<SettingsAccordionItem>;
}>;

export const SettingsAccordionComponent: FC<SettingsAccordionProps> = props => {
  const accordionData = useMemo(() =>
    prepareAccordionData(props.items),
  [props.items]);

  return (
    <OutlinePanelComponent>
      <AccordionComponent
        headerSize={AccordionHeaderSize.Large}
        data={accordionData}
        itemButtonStyle={headerButtonStyle}
        panelStyle={itemsPaneStyle}
      />
    </OutlinePanelComponent>
  );
};
