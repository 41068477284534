import {
  type FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { type WebglOverlayLabelWithTextBoxCallout } from '~/_shared/utils/webgl/labelWithCallout';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { type MapFont } from '../../mapLabelFonts';
import {
  type MapObjectLabelCalloutConfig, type MapObjectLabelConfig,
} from '../mapObject.types';
import { useMapObjectContext } from '../private/mapObjectContext';
import { type MapLabelInstance } from './mapLabelModel';

export type MapLabelProps = {
  label: MapLabelInstance;
  isNumber?: boolean;
  visuals: { font?: MapFont; label?: MapObjectLabelConfig; callout?: MapObjectLabelCalloutConfig };
  onDragMove?: (labelId: Uuid, latLng: LatLng) => void;
  onDragStart?: (labelId: Uuid) => void;
  onDragEnd?: (labelId: Uuid) => void;
  onMouseOver?: (labelId: Uuid) => void;
  onMouseOut?: (labelId: Uuid) => void;
  onClick?: (labelId: Uuid, event: MapObjectClickEventArgs) => void;
};

const MapLabelComponent: FC<MapLabelProps> = (props) => {
  const { label, visuals, isNumber } = props;
  const { onDragMove, onDragStart, onDragEnd, onMouseOver, onMouseOut, onClick } = props;
  const { manager, zIndex } = useMapObjectContext();

  const [webglLabel, setWebglLabel] = useState<WebglOverlayLabelWithTextBoxCallout>();

  const labelOnDragMove = useCallback((latLng: LatLng) => onDragMove?.(label.id, latLng), [label.id, onDragMove]);
  const labelOnDragStart = useCallback(() => onDragStart?.(label.id), [label.id, onDragStart]);
  const labelOnDragEnd = useCallback(() => onDragEnd?.(label.id), [label.id, onDragEnd]);
  const labelOnMouseOver = useCallback(() => onMouseOver?.(label.id), [onMouseOver, label.id]);
  const labelOnMouseOut = useCallback(() => onMouseOut?.(label.id), [onMouseOut, label.id]);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: webglLabel?.label,
    dragDisabled: !(onDragMove || onDragStart || onDragEnd),
    onDragMove: labelOnDragMove,
    onDragStart: labelOnDragStart,
    onDragEnd: labelOnDragEnd,
    onMouseOver: labelOnMouseOver,
    onMouseOut: labelOnMouseOut,
  }), [manager.map, webglLabel, onDragEnd, onDragMove, onDragStart, labelOnDragEnd,
    labelOnDragMove, labelOnDragStart, labelOnMouseOut, labelOnMouseOver]);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: webglLabel?.callout,
    dragDisabled: !(onDragMove || onDragStart || onDragEnd),
    onDragMove: labelOnDragMove,
    onDragStart: labelOnDragStart,
    onDragEnd: labelOnDragEnd,
    onMouseOver: labelOnMouseOver,
    onMouseOut: labelOnMouseOut,
  }), [manager.map, webglLabel, onDragEnd, onDragMove, onDragStart, labelOnDragEnd,
    labelOnDragMove, labelOnDragStart, labelOnMouseOut, labelOnMouseOver]);

  // draw label
  useEffect(() => {
    const updatedTextLabel = manager.upsertLabel(label.id, {
      label: visuals.label ? {
        lat: label.lat,
        lng: label.lng,
        zIndex: zIndex.labelText,
        offset: {
          x: visuals.label.offsetX ?? 0,
          y: (visuals.label.offsetY ?? 0)
            - (isNumber && visuals.label.fontSize && visuals.font?.numericalYOffsetPercent
              ? visuals.label.fontSize * visuals.font.numericalYOffsetPercent
              : 0
            ),
        },
        text: {
          value: visuals.label.text,
          fillColor: visuals.label.color,
          fontSize: visuals.label.fontSize,
          letterSpacing: visuals.label.letterSpacing,
          fillWidth: visuals.label.fillWidth,
          ...(visuals.font ? { font: visuals.font.name } : {}),
        },
        verticalAnchor: visuals.label.verticalAnchor ?? 'center',
        horizontalAnchor: visuals.label.horizontalAnchor ?? 'center',
        staticSize: visuals.label.staticSize ?? false,
        sizeOnLevel: visuals.label.sizeOnLevel ?? 0,
        boundaries: visuals.label.boundaries,
        interactive: visuals.label.interactive ?? false,
      } : undefined,
      callout: visuals.callout ? {
        ...visuals.callout,
        lat: label.lat,
        lng: label.lng,
        zIndex: zIndex.labelCallout,
        triangle: visuals.callout.triangle ?? false,
        interactive: visuals.callout.interactive,
      } : undefined,
    });

    if (webglLabel !== updatedTextLabel) {
      setWebglLabel(updatedTextLabel);
    }
  }, [manager, label, visuals, webglLabel, zIndex.labelText, zIndex.labelCallout, isNumber]);

  // web-gl cleanup
  useEffect(() => {
    return () => {
      manager.removeLabel(label.id);
    };
  }, [manager, label.id]);

  // registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addLabelEventListener(label.id, 'click',
      (e) => {
        onClick?.(label.id, e);
      });

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, label.id]);

  return null;
};

const pureComponent = memo(MapLabelComponent);
export { pureComponent as MapLabelComponent };
