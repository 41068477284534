export const MAPTIVE_SUPPORT_EMAIL = 'support@maptive.com';
export const MAPTIVE_SALES_SUPPORT_EMAIL = 'sales@maptive.com';
export const MAPTIVE_BUG_REPORT_EMAIL = 'bugs@maptive.com';

//For now this link is also used for subscription management in v4
export const LINK_TO_V4_ACCOUNT_SETTINGS = 'https://fortress.maptive.com/ver4/new_ui/index.php';

export const MAPTIVE_TERMS_OF_SERVICE = 'https://www.maptive.com/terms-of-service';
export const MAPTIVE_PRIVACY_POLICY = 'https://www.maptive.com/privacy-policy/';
export const MAPTIVE_FAQ = 'https://answers.maptive.com/';
export const MAPTIVE_PHONE_NUMBER = '1-(888)-788-2871';

export const AVAILABILITY_FROM_HOUR = 9;
export const AVAILABILITY_TO_HOUR = 5;
