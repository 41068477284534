import { type DeepWritable } from 'ts-essentials';
import { GroupingType } from '~/_shared/types/grouping/grouping';
import { flatten } from '~/_shared/utils/collections/collections';
import { objectEntryValueNotUndefined } from '~/_shared/utils/typeGuards';
import { type MapSettingsMarkersGeneralState } from './mapSettingsMarkersGeneral.state';

type State = MapSettingsMarkersGeneralState | DeepWritable<MapSettingsMarkersGeneralState>;

export const getGroupMarkerSettingsColumnIds = <T extends State>(state: T) => {
  if (!state.groupMarkerSettings) {
    return [];
  }

  return flatten(Object.entries(state.groupMarkerSettings).map(([spreadsheetId, columnMarkerSettings]) =>
    (Object.keys(columnMarkerSettings).map(columnId => {
      return {
        spreadsheetId: Number(spreadsheetId),
        columnId,
      };
    }))));
};

export const getTextGroupMarkerSettings = <T extends State>(state: T) => {
  if (!state.groupMarkerSettings) {
    return [];
  }

  return flatten(Object.entries(state.groupMarkerSettings).map(([spreadsheetId, columnMarkerSettings]) =>
    flatten(Object.entries(columnMarkerSettings).map(([columnId, columnMarkerSetting]) => {
      const text = columnMarkerSetting[GroupingType.Text];
      if (!text) {
        return [];
      }

      return Object.entries(text).map(([groupId, markerSettings]) => ({
        spreadsheetId: Number(spreadsheetId),
        columnId,
        groupId,
        markerSettings,
        textGroupsSettings: text,
      }));
    }))));
};

export const getNumericGroupMarkerSettings = <T extends State>(state: T) => {
  if (!state.groupMarkerSettings) {
    return [];
  }

  return flatten(Object.entries(state.groupMarkerSettings).map(([spreadsheetId, columnMarkerSettings]) =>
    flatten(Object.entries(columnMarkerSettings).map(([columnId, columnMarkerSetting]) => {
      const numeric = columnMarkerSetting[GroupingType.Numeric];
      if (!numeric) {
        return [];
      }

      return flatten(Object.entries(numeric)
        .filter(objectEntryValueNotUndefined)
        .map(([bucketKey, numericGroupBucketsSettings]) =>
          Object.entries(numericGroupBucketsSettings)
            .filter(objectEntryValueNotUndefined)
            .map(([bucketId, markerSettings]) => ({
              spreadsheetId: Number(spreadsheetId),
              columnId,
              bucketKey,
              bucketId: Number(bucketId),
              markerSettings,
              numericGroupsSettings: numeric,
              numericGroupBucketsSettings,
            }))));
    }))));
};

export const getIndividualMarkerSettings = <T extends State>(state: T) => {
  if (!state.individualMarkerSettings) {
    return [];
  }

  return flatten(Object.entries(state.individualMarkerSettings)
    .filter(objectEntryValueNotUndefined)
    .map(([spreadsheetId, spreadsheetMarkerSettings]) =>
      Object.entries(spreadsheetMarkerSettings).map(([rowId, markerSettings]) => ({
        spreadsheetId: Number(spreadsheetId),
        rowId,
        markerSettings,
        spreadsheetMarkerSettings,
      }))));
};
