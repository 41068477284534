import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';
import {
  DEFAULT_OUTLINES,
  OutlinePanelOptions,
} from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';

const outlineStyle = ({ theme, outlines, borderRadius }: ThemeProps<{
  outlines: Set<OutlinePanelOptions>;
  borderRadius?: number | string;
}>) => {
  const getBorderWidth = (outlineType: OutlinePanelOptions) => outlines.has(outlineType) ? 1 : 0;

  return css({
    border: `solid ${theme.borderColors.primary}`,
    borderRadius: borderRadius || 0,
    borderWidth: `${getBorderWidth(OutlinePanelOptions.Top)}px ${getBorderWidth(OutlinePanelOptions.Right)}px
    ${getBorderWidth(OutlinePanelOptions.Bottom)}px ${getBorderWidth(OutlinePanelOptions.Left)}px`,
    overflow: 'hidden',
  });
};

export type OutlinePanelProps = Readonly<{
  borderRadius?: number | string;
  outlineStyle?: SerializedStyles;
  outlines?: ReadonlyArray<OutlinePanelOptions>;
  children?: React.ReactNode;
}>;

export const OutlinePanelComponent: FC<OutlinePanelProps> = props => {
  const theme = useTheme();
  const guaranteedOutlinesLookup = new Set(props.outlines || DEFAULT_OUTLINES);

  return (
    <div
      css={[outlineStyle({
        theme,
        outlines: guaranteedOutlinesLookup,
        borderRadius: props.borderRadius,
      }), props.outlineStyle]}
    >
      {props.children}
    </div>
  );
};
