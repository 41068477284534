import { css } from '@emotion/react';
import { ColorWheelComponent } from '~/_shared/components/colorPicker/colorPickerWithWheel/colorWheel.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { BubbleMarkerLabelFrameComponent } from './bubbleMarkerLabelFrame.component';

const WHEEL_DIMENSIONS = { X: 24, Y: 26 };

const wheelStyle = css({
  height: WHEEL_DIMENSIONS.Y,
  width: WHEEL_DIMENSIONS.X,
  marginRight: 4,
});

export const MultiColorBubbleMarkerLabelComponent: React.FC = _ => {
  const [t] = useTranslation();

  return (
    <BubbleMarkerLabelFrameComponent title={t('Multi Color Bubble Markers')}>
      <ColorWheelComponent css={wheelStyle} />
    </BubbleMarkerLabelFrameComponent>
  );
};
