import {
  css, type Interpolation,
} from '@emotion/react';
import {
  type CSSProperties, type FC, type MouseEvent,
} from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { scrollerMarginFixProps } from '~/_shared/components/scrollbar/scrollbar.utils';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

const SCROLLBAR_WIDTH = 7;
const SCROLLBAR_ACTIVE_WIDTH = 11;

export enum ScrollbarType {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

type ScrollbarProps = Readonly<{
  className?: string;
  contentStyle?: CSSProperties;
  isScrollbarHidden?: boolean;
  maxHeight?: number | string;
  // Beware! : this props can cause a render loop - scrollbar sets width => content changes based on width => scrollbar sets new widthtranslateContentWidthToHolder?: boolean;
  translateContentWidthToHolder?: boolean;
  translateContentHeightToHolder?: boolean;
  type: ScrollbarType | ScrollbarType[];
  scrollbarStyle?: Interpolation;
  getScrollbarReference?: (ref: Scrollbar) => void;
  onClick?: (e: MouseEvent) => void;
  onScrollStart?: () => void;
  onScrollTop?: () => void;
  onUpdate?: () => void;
  children?: React.ReactNode;
  style?: CSSProperties;
}>;

const scrollThumbStyles = ({ theme }: ThemeProps): CSSProperties => ({
  backgroundColor: theme.scrollbarColors.thumbColor,
  cursor: 'default',
});

const baseTransition = 'visibility 0s 1s, opacity 1s linear, height .15s, width .15s';// fade out
const noVisibilityNorOpacityTransition = 'visibility 0s, opacity 0s, height .15s, width .15s'; // no fade in

// https://github.com/xobotyi/react-scrollbars-custom#generated-html
const scrollbarStyle = (props: ScrollbarProps) => css([
  props.maxHeight ? { maxHeight: props.maxHeight } : {},
  {
    '& > .ScrollbarsCustom-Track': {
      zIndex: 999,
      backgroundColor: 'rgba(0,0,0,0) !important',
      opacity: 0,
      visibility: 'hidden',
      transition: baseTransition, // fade out
      '& > .ScrollbarsCustom-Thumb': {
        transition: baseTransition, // fade out
      },
    },
    '& > .ScrollbarsCustom-TrackY': {
      width: SCROLLBAR_WIDTH,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      '&:hover, &:active': {
        width: SCROLLBAR_ACTIVE_WIDTH,
      },
      '& > .ScrollbarsCustom-ThumbY': {
        width: SCROLLBAR_WIDTH,
        '&:hover, &:active': {
          width: SCROLLBAR_ACTIVE_WIDTH,
        },
      },
    },
    '& > .ScrollbarsCustom-TrackX': {
      height: SCROLLBAR_WIDTH,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      '&:hover, &:active': {
        height: SCROLLBAR_ACTIVE_WIDTH,
      },
      '& > .ScrollbarsCustom-ThumbX': {
        height: SCROLLBAR_WIDTH,
        '&:hover, &:active': {
          height: SCROLLBAR_ACTIVE_WIDTH,
        },
      },
    },
    '& > .ScrollbarsCustom-Wrapper:hover ~ .ScrollbarsCustom-Track, .ScrollbarsCustom-Track:hover, .ScrollbarsCustom-Track:active': {
      transition: noVisibilityNorOpacityTransition, // fade in immediately
      visibility: props.isScrollbarHidden ? 'hidden' : 'visible',
      opacity: 1,
    },
    '.ScrollbarsCustom-Track>.ScrollbarsCustom-Thumb': {
      opacity: 0.5,
      transition: noVisibilityNorOpacityTransition, // fade in immediately
    },
    '& > .ScrollbarsCustom-Track:hover>.ScrollbarsCustom-Thumb': {
      opacity: 1,
      transition: noVisibilityNorOpacityTransition, // fade in immediately
    },
    '& > .ScrollbarsCustom-Wrapper:hover ~ .ScrollbarsCustom-Track > .ScrollbarsCustom-Thumb.dragging, .ScrollbarsCustom-Track:hover>.ScrollbarsCustom-Thumb.dragging': {
      opacity: 0.35,
    },
  }]);

export const ScrollBarComponent: FC<ScrollbarProps> = (props) => {
  const theme = useTheme();

  const scrollbarTypes: ScrollbarType[] = Array.isArray(props.type) ? props.type : [props.type];

  return (
    <Scrollbar
      contentProps={{ className: props.className, style: props.contentStyle }}
      createContext
      css={[props.scrollbarStyle, scrollbarStyle(props)]}
      disableTracksWidthCompensation
      onClick={props.onClick}
      onScrollStart={props.onScrollStart}
      onUpdate={props.onUpdate}
      ref={(el: unknown) => {
        if (el && props.getScrollbarReference) {
          props.getScrollbarReference(el as Scrollbar);
        }
      }}
      noScrollX={!scrollbarTypes.includes(ScrollbarType.Horizontal)}
      noScrollY={!scrollbarTypes.includes(ScrollbarType.Vertical)}
      thumbXProps={{ style: { height: undefined, ...scrollThumbStyles({ theme }) } }}
      thumbYProps={{ style: { width: undefined, ...scrollThumbStyles({ theme }) } }}
      trackXProps={{ style: { height: undefined } }} // remove default height inline style
      trackYProps={{ style: { width: undefined, top: 3, height: 'calc(100% - 6px)' } }} // remove default width inline style
      scrollerProps={scrollerMarginFixProps}
      translateContentSizeXToHolder={props.translateContentWidthToHolder}
      translateContentSizeYToHolder={props.translateContentHeightToHolder}
      maximalThumbSize={150}
      style={props.style}
    >
      {props.children}
    </Scrollbar>
  );
};
