import { css } from '@emotion/react';
import { type ThemeProps } from '../../../types/themeProps';
import { InputSize } from '../textInput';

const getSpinnersWidthForInputSize = (inputSize: InputSize): number => {
  switch (inputSize) {
    case InputSize.Small:
      return 20;

    default:
      return 32;
  }
};

export const inputStyle = ({ width, hasError, theme }: ThemeProps<{ width?: number; hasError?: boolean }>) => css({
  width: width ?? '100%',
  boxSizing: 'border-box',
  padding: '0 0 0 8px',
  ...(hasError ? { border: `1px solid ${theme.borderColors.error}` } : {}),
});

export const suffixWrapperStyle = ({ theme, inputSize }: ThemeProps<{ inputSize: InputSize }>) => css({
  display: 'flex',
  height: '100%',
  borderStyle: 'solid',
  borderColor: theme.borderColors.primary,
  borderWidth: '0 0 0 1px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0 4px 4px 0',
  boxSizing: 'border-box',
  width: getSpinnersWidthForInputSize(inputSize),
  flexShrink: 0,
});
