import {
  useCallback, useState,
} from 'react';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import {
  type ClientDataErrorCode, type ClientErrorResponse, type ClientResponse, updateClientData,
} from '~/store/userData/repository/userData.repository';

export type UpdateClientResponse = {
  data?: ClientResponse;
  error?: ClientErrorResponse;
  code?: string;
};

type UpdateClientError = Readonly<{
  name: ReadonlyArray<ClientDataErrorCode | string>;
}>;

export const useUpdateClient = (clientId: number | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<UpdateClientError | null>(null);
  const isMountedRef = useIsComponentMountedRef();

  const updateClient = useCallback((newClientName: string): Promise<Readonly<{errors?: UpdateClientError}>> => {
    setIsLoading(true);
    setError(null);

    if (clientId === null) {
      return Promise.resolve({});
    }

    return updateClientData(clientId, {
      name: newClientName,
    })
      .then((_data) => {
        setError(null);
        return {};
      })
      .catch((e: ClientErrorResponse) => {
        const clientErrors = { name: e.errors.name };
        setError(clientErrors);

        return { errors: clientErrors };
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [clientId, isMountedRef]);

  return {
    isLoading,
    error,
    updateClientName: updateClient,
  };
};
