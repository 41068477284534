import {
  css, type Interpolation,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  type FC, type MouseEvent, type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { type InputSize } from '../inputs';
import {
  DropDownItemSize, getRegularDropdownItemSize,
} from './regularDropdown.helpers';

const dropdownItemStyle = ({ height, disabled, highlight }: Readonly<{
  height: number;
  disabled?: boolean;
  highlight?: boolean;
}>) => (theme: Theme) => css({
  fontSize: 16,
  lineHeight: '20px',
  padding: '0 8px',
  height,
  textAlign: 'left',
  color: disabled ? theme.textColors.disabled : highlight ? theme.textColors.warning : theme.textColors.primary,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  background: 'none',
  border: `1px solid ${theme.borderColors.primary}`,
  borderWidth: '1px 0 0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: disabled ? 'inherit' : theme.dropdownColors.optionBackgroundHover,
  },
});

const iconStyle = css({
  fontSize: 16,
  marginRight: 3,
});

export type DropdownItemComponentProps = {
  className?: string;
  highlight?: boolean;
  icon?: IconProp;
  isDisabled?: boolean;
  itemIconStyle?: Interpolation;
  name: string;
  prefix?: ReactNode;
  size: InputSize;
  subSize?: DropDownItemSize;

  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const DropdownItemComponent: FC<DropdownItemComponentProps> = ({
  className,
  highlight,
  icon,
  isDisabled,
  itemIconStyle,
  name,
  onClick,
  prefix,
  size,
  subSize = DropDownItemSize.Default,
  ...restProps
}) => {
  const itemHeight = getRegularDropdownItemSize(size, subSize);

  return (
    <button
      {...restProps}
      className={className}
      css={dropdownItemStyle({
        height: itemHeight,
        disabled: isDisabled,
        highlight,
      })}
      onClick={onClick}
    >
      {icon && (
        <>
          <FontAwesomeIcon
            icon={icon}
            css={[iconStyle, itemIconStyle]}
          />
        </>
      )}
      {prefix}
      {name}
    </button>
  );
};
