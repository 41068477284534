import {
  useCallback, useState,
} from 'react';

export const useSections = <SectionType, >(initialState: Readonly<Array<SectionType>>) => {
  const [expandedSections, setExpandedSections] = useState<ReadonlyArray<SectionType>>(() => initialState);

  const expandSection = useCallback(
    (expandedSection: SectionType) =>
      setExpandedSections([...expandedSections, expandedSection]),
    [setExpandedSections, expandedSections]);
  const collapseSection = useCallback((collapsedSection: SectionType) =>
    setExpandedSections(expandedSections.filter(section => section !== collapsedSection)),
  [setExpandedSections, expandedSections]);
  const setExpandedSection = useCallback((section: SectionType) => {
    setExpandedSections([section]);
  }, [setExpandedSections]);
  const isExpanded = useCallback((section: SectionType) => {
    return expandedSections.includes(section);
  }, [expandedSections]);

  const toggleSection = useCallback(
    (section: SectionType) => {
      if (isExpanded(section)) {
        collapseSection(section);
      }
      else {
        expandSection(section);
      }
    }, [isExpanded, collapseSection, expandSection],
  );

  return {
    setExpandedSection,
    isExpanded,
    toggleSection,
  };
};
