import { SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { getValidNumericColumnIds } from '../../../heatMap/panel/form/useIsNumericColumnCheck.helpers';
import { type SpreadsheetDataBulkResponse } from '../../../spreadsheet/spreadsheet.repository';
import { type BoundaryGroupDemographic } from '../../../store/boundaryGroups/boundaryGroups.repository';
import { type MapSettingsFilterSettings } from '../../../store/mapSettings/filtering/mapSettingsFiltering.state';
import { type MatchupDataState } from '../../../store/matchupData/matchupData.state';
import { DataType } from '../../../store/spreadsheetData/spreadsheetData.state';
import { type GroupingColumn } from '../../types/grouping/grouping';
import {
  createSpreadsheetMetricModelForDataActions,
  distinctSpreadsheetColumnMetrics,
  getColumnName,
} from './defaultMetric.helpers';
import {
  type DemographicMetricModel, matchBoundaryGroupDemographic,
} from './demographicsMetric.factory';
import { MetricsDataAction } from './metrics.types';
import { type SpreadsheetColumnMetricModel } from './spreadsheetMetric.factory';

export const createDefaultMetricsFromGroups = (existingGroups: GroupingColumn[], matchupData: MatchupDataState) => {
  const resultMetrics: SpreadsheetColumnMetricModel[] = [];

  for (const group of existingGroups) {
    const columnName = getColumnName(matchupData, group.spreadsheetId, group.columnId);
    const newGroupMetrics = createSpreadsheetMetricModelForDataActions(
      group.columnId,
      group.spreadsheetId,
      columnName,
      [MetricsDataAction.GROUP_COUNT]
    );
    resultMetrics.push(...newGroupMetrics);
  }

  return resultMetrics;
};

export const createDefaultMetricsFromFilters = (existingFilters: MapSettingsFilterSettings, matchupData: MatchupDataState) => {
  const resultMetrics: SpreadsheetColumnMetricModel[] = [];

  for (const [spreadsheetId, filterSettings] of Object.entries(existingFilters)) {
    const spreadsheetIdParsed = parseInt(spreadsheetId, 10);
    for (const [columnId, columnFilterSetting] of Object.entries(filterSettings)) {
      const columnName = getColumnName(matchupData, spreadsheetIdParsed, columnId);
      if (columnFilterSetting.type === DataType.NUMBER) {
        const newNumericFilterMetrics = createSpreadsheetMetricModelForDataActions(
          columnId,
          spreadsheetIdParsed,
          columnName,
          [MetricsDataAction.HIGH_VALUE, MetricsDataAction.LOW_VALUE, MetricsDataAction.SUM, MetricsDataAction.AVERAGE]
        );
        resultMetrics.push(...newNumericFilterMetrics);
      }
      else if (columnFilterSetting.type === DataType.GROUP) {
        const newGroupFilterMetrics = createSpreadsheetMetricModelForDataActions(
          columnId,
          spreadsheetIdParsed,
          columnName,
          [MetricsDataAction.GROUP_COUNT]
        );
        resultMetrics.push(...newGroupFilterMetrics);
      }
    }
  }

  return resultMetrics;
};

export const createDefaultMetricsFromNumericColumns = (numericColumns: SpreadsheetDataBulkResponse[], matchupData: MatchupDataState) => {
  const resultMetrics: SpreadsheetColumnMetricModel[] = [];

  for (const numericCol of numericColumns) {
    const validNumericColumnIds = getValidNumericColumnIds(numericCol);
    validNumericColumnIds.forEach((columnId) => {
      const newNumericFilterMetrics = createSpreadsheetMetricModelForDataActions(
        columnId,
        numericCol.spreadsheet_id,
        getColumnName(matchupData, numericCol.spreadsheet_id, columnId),
        [MetricsDataAction.HIGH_VALUE, MetricsDataAction.LOW_VALUE, MetricsDataAction.SUM, MetricsDataAction.AVERAGE]
      );
      resultMetrics.push(...newNumericFilterMetrics);
    });
  }
  return resultMetrics;
};

export const createDefaultSpreadsheetColumnMetrics = (existingGroups: GroupingColumn[], existingFilters: MapSettingsFilterSettings, numericColumns: SpreadsheetDataBulkResponse[], matchupData: MatchupDataState) => {
  const groupsMetrics = createDefaultMetricsFromGroups(existingGroups, matchupData);
  const filtersMetrics = createDefaultMetricsFromFilters(existingFilters, matchupData);
  const numericColumnsMetrics = createDefaultMetricsFromNumericColumns(numericColumns, matchupData);

  return distinctSpreadsheetColumnMetrics([...groupsMetrics, ...filtersMetrics, ...numericColumnsMetrics]);
};

export const defaultDemographicsMetrics: BoundaryGroupDemographic[] = [
  {
    name: 'Total Population',
    id: 'US_BLOCK_GROUPS|POPULATION|TOTAL_POPULATION',
    preData: '',
    postData: ' People',
    percentage: false,
    category: 'Population',
  },
  {
    name: 'Median Age',
    id: 'US_BLOCK_GROUPS|AGE|MEDIAN_AGE',
    preData: '',
    postData: 'Years Old',
    percentage: false,
    category: 'Age',
  },
  {
    name: 'Median Income',
    id: 'US_CENSUS_TRACTS|INCOME|MEDIAN_INCOME',
    preData: '$',
    postData: '',
    percentage: false,
    category: 'Income',
  },
];

const getBestDemographics = (defaultDemographic: BoundaryGroupDemographic, boundaryGroupDemographic: ReadonlyArray<BoundaryGroupDemographic>) => {
  return boundaryGroupDemographic.find(demographic => matchBoundaryGroupDemographic(defaultDemographic.id, demographic.id)) ?? defaultDemographic;
};

export const createDefaultDemographicsMetrics = (boundaryGroupDemographic: ReadonlyArray<BoundaryGroupDemographic>): ReadonlyArray<DemographicMetricModel> => {
  const validDemographic = defaultDemographicsMetrics.map(defaultDemographic => getBestDemographics(defaultDemographic, boundaryGroupDemographic));
  return validDemographic.map(demographic => ({
    type: SelectedDataType.Demographic,
    demographic,
  }));
};
