import { type MoveMarkersAction } from './moveMarkers.action';
import {
  MOVE_MARKERS_ACTIVATE,
  MOVE_MARKERS_CLOSE_MODAL,
  MOVE_MARKERS_DEACTIVATE,
  MOVE_MARKERS_DISCARD_CHANGES,
  MOVE_MARKERS_OPEN_MODAL,
  MOVE_MARKERS_UPDATE_MARKER_POSITION,
} from './moveMarkers.actionTypes';
import {
  createMarkerNewPositionStore, type MoveMarkersState, upsertMarkerPosition,
} from './moveMarkers.state';

const initialState: MoveMarkersState = {
  isActive: false,
  isModalOpen: false,
  markersPositions: createMarkerNewPositionStore(),
};

export const moveMarkersReducer = (state = initialState, action: MoveMarkersAction): MoveMarkersState => {
  switch (action.type) {
    case MOVE_MARKERS_OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      };
    }

    case MOVE_MARKERS_CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
        isActive: false,
        markersPositions: createMarkerNewPositionStore(),
      };
    }

    case MOVE_MARKERS_ACTIVATE: {
      return {
        ...state,
        isActive: true,
        markersPositions: createMarkerNewPositionStore(),
      };
    }

    case MOVE_MARKERS_DEACTIVATE: {
      return {
        ...state,
        isActive: false,
        markersPositions: createMarkerNewPositionStore(),
      };
    }

    case MOVE_MARKERS_DISCARD_CHANGES: {
      return {
        ...state,
        markersPositions: createMarkerNewPositionStore(),
      };
    }

    case MOVE_MARKERS_UPDATE_MARKER_POSITION: {
      return {
        ...state,
        markersPositions: upsertMarkerPosition(state.markersPositions, action.payload.rowId, action.payload.lat, action.payload.lng),
      };
    }

    default: {
      return state;
    }
  }
};
