import { type TranslationFnc } from '../_shared/utils/hooks';
import { type DrawingSettingsState } from '../store/mapSettings/drawing/settings/drawingSettings.state';
import { DrawingTool } from './drawingTool.enums';
import { type DrawingToolOptions } from './drawingTool.types';
import {
  createArrowDrawingSettingsFromToolOptions,
  createGlobalArrowDrawingOptionsFromSettings,
} from './factories/drawingToolArrowSettings.factory';
import {
  createCalloutDrawingGlobalOptionsFromSettings,
  createCalloutDrawingSettingsFromToolOptions,
} from './factories/drawingToolCalloutSettings.factory';
import {
  createCircleDrawingOptionsFromSettings,
  createCircleDrawingSettingsFromToolOptions,
} from './factories/drawingToolCircleSettings.factory';
import {
  createIconMarkerDrawingOptionsFromSettings,
  createIconMarkerDrawingSettingsFromToolOptions,
} from './factories/drawingToolIconMarkerSettings.factory';
import {
  createImageDrawingGlobalOptionsFromSettings,
  createImageDrawingSettingsFromToolOptions,
} from './factories/drawingToolImageSettings.factory';
import {
  createLabelDrawingGlobalOptionsFromSettings,
  createLabelDrawingSettingsFromToolOptions,
} from './factories/drawingToolLabelSettings.factory';
import {
  createNumberDrawingGlobalOptionsFromSettings,
  createNumberDrawingSettingsFromToolOptions,
} from './factories/drawingToolNumberSettings.factory';
import {
  createPolygonDrawingOptionsFromSettings,
  createPolygonDrawingSettingsFromToolOptions,
} from './factories/drawingToolPolygonSettings.factory';
import {
  createPolylineDrawingOptionsFromSettings,
  createPolylineDrawingSettingsFromToolOptions,
} from './factories/drawingToolPolylineSettings.factory';
import {
  createRectangleDrawingOptionsFromSettings,
  createRectangleDrawingSettingsFromToolOptions,
} from './factories/drawingToolRectangleSettings.factory';
import {
  createTextDrawingGlobalOptionsFromSettings,
  createTextDrawingSettingsFromToolOptions,
} from './factories/drawingToolTextSettings.factory';

export const getDrawingToolItemLabel = (drawingTool: DrawingTool, t: TranslationFnc): string => {
  switch (drawingTool) {
    case DrawingTool.Management:
      return t('Manage Drawing Elements');
    case DrawingTool.Callout:
      return t('drawingTool.calloutToolLabel');
    case DrawingTool.Arrow:
      return t('drawingTool.arrowToolLabel');
    case DrawingTool.Number:
      return t('drawingTool.numberToolLabel');
    case DrawingTool.Polygon:
      return t('drawingTool.polygonToolLabel');
    case DrawingTool.Circle:
      return t('drawingTool.circleToolLabel');
    case DrawingTool.Polyline:
      return t('drawingTool.polylineToolLabel');
    case DrawingTool.Rectangle:
      return t('drawingTool.rectangleToolLabel');
    case DrawingTool.Label:
      return t('drawingTool.labelToolLabel');
    case DrawingTool.Text:
      return t('drawingTool.textToolLabel');
    case DrawingTool.IconMarker:
      return t('drawingTool.iconMarkerToolLabel');
    case DrawingTool.Image:
      return t('drawingTool.imageToolLabel');
    default:
      return '';
  }
};

export const getDrawingToolItemDescription = (drawingTool: DrawingTool, t: TranslationFnc): string[] => {
  switch (drawingTool) {
    case DrawingTool.Management:
      return [t('drawingTool.managementToolDescription')];
    case DrawingTool.Callout:
      return [t('drawingTool.calloutToolDescription')];
    case DrawingTool.Arrow:
      return [t('drawingTool.arrowToolDescription'), t('drawingTool.arrowToolDescriptionAdditional')];
    case DrawingTool.Number:
      return [t('drawingTool.numberToolDescription'), t('drawingTool.numberToolDescriptionAdditional')];
    case DrawingTool.Polygon:
      return [t('drawingTool.polygonToolDescription')];
    case DrawingTool.Circle:
      return [t('drawingTool.circleToolDescription')];
    case DrawingTool.Polyline:
      return [t('drawingTool.polylineToolDescription')];
    case DrawingTool.Rectangle:
      return [t('drawingTool.rectangleToolDescription')];
    case DrawingTool.Label:
      return [t('drawingTool.labelToolDescription')];
    case DrawingTool.Text:
      return [t('drawingTool.textToolDescription')];
    case DrawingTool.IconMarker:
      return [t('drawingTool.iconMarkerToolDescription')];
    case DrawingTool.Image:
      return [t('drawingTool.imageToolDescription')];
    default:
      return [];
  }
};

export const getDrawingsTypeLabel = (drawingType: DrawingTool, t: TranslationFnc): string => {
  switch (drawingType) {
    case DrawingTool.Callout:
      return t('Callouts');
    case DrawingTool.Arrow:
      return t('Arrows');
    case DrawingTool.Number:
      return t('Numbers');
    case DrawingTool.Polygon:
      return t('Polygons');
    case DrawingTool.Circle:
      return t('Circles');
    case DrawingTool.Polyline:
      return t('Polylines');
    case DrawingTool.Rectangle:
      return t('Rectangles');
    case DrawingTool.Label:
      return t('Labels');
    case DrawingTool.Text:
      return t('Texts');
    case DrawingTool.IconMarker:
      return t('Icon Markers');
    case DrawingTool.Image:
      return t('Images');
    default:
      return '';
  }
};

export const getDrawingElementLabel = (drawingTool: DrawingTool, t: TranslationFnc): string => {
  switch (drawingTool) {
    case DrawingTool.Callout:
      return t('Callout Element');
    case DrawingTool.Arrow:
      return t('Arrow Element');
    case DrawingTool.Number:
      return t('Number Element');
    case DrawingTool.Polygon:
      return t('Polygon Element');
    case DrawingTool.Circle:
      return t('Circle Element');
    case DrawingTool.Polyline:
      return t('Polyline Element');
    case DrawingTool.Rectangle:
      return t('Rectangle Element');
    case DrawingTool.Label:
      return t('Label Element');
    case DrawingTool.Text:
      return t('Text Element');
    case DrawingTool.IconMarker:
      return t('Icon Marker Element');
    case DrawingTool.Image:
      return t('Image Element');
    default:
      return '';
  }
};

export const getDrawingDeleteAllItemsLabelForDrawingType = (drawingTool: DrawingTool, t: TranslationFnc): string => {
  switch (drawingTool) {
    case DrawingTool.Callout:
      return t('Delete All Callouts');
    case DrawingTool.Arrow:
      return t('Delete All Arrows');
    case DrawingTool.Number:
      return t('Delete All Numbers');
    case DrawingTool.Polygon:
      return t('Delete All Polygons');
    case DrawingTool.Circle:
      return t('Delete All Circles');
    case DrawingTool.Polyline:
      return t('Delete All Polylines');
    case DrawingTool.Rectangle:
      return t('Delete All Rectangles');
    case DrawingTool.Label:
      return t('Delete All Labels');
    case DrawingTool.Text:
      return t('Delete All Texts');
    case DrawingTool.IconMarker:
      return t('Delete All Icon Markers');
    case DrawingTool.Image:
      return t('Delete All Images');
    default:
      return '';
  }
};

export const createDrawingToolOptionsFromDrawingToolSettings = (
  drawingTool: DrawingTool | null,
  drawingToolSettings: DrawingSettingsState,
): Partial<DrawingToolOptions> => {
  switch (drawingTool) {
    case DrawingTool.Arrow: {
      return createGlobalArrowDrawingOptionsFromSettings(drawingToolSettings.arrow);
    }
    case DrawingTool.Callout: {
      return createCalloutDrawingGlobalOptionsFromSettings(drawingToolSettings.callout);
    }
    case DrawingTool.Circle: {
      return createCircleDrawingOptionsFromSettings(drawingToolSettings.circle);
    }
    case DrawingTool.IconMarker: {
      return createIconMarkerDrawingOptionsFromSettings(drawingToolSettings.iconMarker.icon);
    }
    case DrawingTool.Image: {
      return createImageDrawingGlobalOptionsFromSettings(drawingToolSettings.image);
    }
    case DrawingTool.Label: {
      return createLabelDrawingGlobalOptionsFromSettings(drawingToolSettings.label);
    }
    case DrawingTool.Number: {
      return createNumberDrawingGlobalOptionsFromSettings(drawingToolSettings.number);
    }
    case DrawingTool.Polygon: {
      return createPolygonDrawingOptionsFromSettings(drawingToolSettings.polygon);
    }
    case DrawingTool.Polyline: {
      return createPolylineDrawingOptionsFromSettings(drawingToolSettings.polyline);
    }
    case DrawingTool.Rectangle: {
      return createRectangleDrawingOptionsFromSettings(drawingToolSettings.rectangle);
    }
    case DrawingTool.Text: {
      return createTextDrawingGlobalOptionsFromSettings(drawingToolSettings.text);
    }
    default: {
      return {};
    }
  }
};

export const createDrawingToolSettingsFromToolOptions = (
  drawingTool: DrawingTool,
  currentSettings: DrawingSettingsState,
  toolOptions: Partial<DrawingToolOptions>,
): DrawingSettingsState => {
  return {
    ...currentSettings,
    arrow: drawingTool === DrawingTool.Arrow ?
      createArrowDrawingSettingsFromToolOptions(toolOptions, currentSettings.arrow) : currentSettings.arrow,
    callout: drawingTool === DrawingTool.Callout ?
      createCalloutDrawingSettingsFromToolOptions(toolOptions, currentSettings.callout) : currentSettings.callout,
    circle: drawingTool === DrawingTool.Circle ?
      createCircleDrawingSettingsFromToolOptions(toolOptions, currentSettings.circle) : currentSettings.circle,
    image: drawingTool === DrawingTool.Image ?
      createImageDrawingSettingsFromToolOptions(toolOptions, currentSettings.image) : currentSettings.image,
    iconMarker: drawingTool === DrawingTool.IconMarker ?
      createIconMarkerDrawingSettingsFromToolOptions(toolOptions, currentSettings.iconMarker) : currentSettings.iconMarker,
    label: drawingTool === DrawingTool.Label ?
      createLabelDrawingSettingsFromToolOptions(toolOptions, currentSettings.label) : currentSettings.label,
    number: drawingTool === DrawingTool.Number ?
      createNumberDrawingSettingsFromToolOptions(toolOptions, currentSettings.number) : currentSettings.number,
    polygon: drawingTool === DrawingTool.Polygon ?
      createPolygonDrawingSettingsFromToolOptions(toolOptions, currentSettings.polygon) : currentSettings.polygon,
    polyline: drawingTool === DrawingTool.Polyline ?
      createPolylineDrawingSettingsFromToolOptions(toolOptions, currentSettings.polyline) : currentSettings.polyline,
    rectangle: drawingTool === DrawingTool.Rectangle ?
      createRectangleDrawingSettingsFromToolOptions(toolOptions, currentSettings.rectangle) : currentSettings.rectangle,
    text: drawingTool === DrawingTool.Text ?
      createTextDrawingSettingsFromToolOptions(toolOptions, currentSettings.text) : currentSettings.text,
  };
};
