import memoizee from 'memoizee';
import memoizeeWeak from 'memoizee/weak.js';
import { type AnyFunction } from '../types/common.type';

export function memoizeOne<TFunction extends AnyFunction>(func: TFunction): TFunction {
  return memoizee(func, { max: 1 });
}

type EnsureFirstArgumentIsObject<T extends AnyFunction> =
  Parameters<T>[0] extends object
    ? T
    : never;

/**
 * Uses "weak-references" to prevent memory leak, memoized result is removed from cache
 * when all references to the first argument are removed from the application.
 * see https://github.com/medikoo/memoizee#weakmap-based-configurations for more info
*/
export function memoizeWeak<TFunction extends AnyFunction>(func: EnsureFirstArgumentIsObject<TFunction>): TFunction {
  return memoizeeWeak(func);
}
