import {
  type FC, type MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  MapOutlinePosition,
  type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import {
  outlineHoverVisuals,
  outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { MarkerColor } from '~/_shared/types/marker.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE, DrawingTool,
} from '~/drawingTool/drawingTool.enums';
import { MapObjectOutlineCircleToDotRatio } from '~/map/map/mapObjects/mapObject.types';
import {
  useActiveDrawingInstanceZIndex, useDrawingInstanceZIndex,
} from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  drawingEditPushNewSnapshot,
  drawingEditSetSelectedDrawing,
} from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemMoveStarted,
  drawingToolItemMoveStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemText } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  type DrawingToolScalingItem, useDrawingToolItemScalingToggle,
} from '../hooks/useDrawingToolItemScalingToggle';
import { useDrawingToolItemSizeForCurrentZoom } from '../hooks/useDrawingToolItemSizeForCurrentZoom';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import {
  DrawingToolTextInstanceTextContainer, type DrawingToolTextInstanceTextProps,
} from './drawingToolTextInstanceTextContainer';

const SCALING_MIN_VISIBLE_FONT_SIZE = 3;

const useDrawingToolTextProps = (instance: DrawingItemText): DrawingToolTextInstanceTextProps | null => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dispatch = useDispatch();
  const { onMouseOut, onMouseOver } = useDrawingToolItemMouseEvents();
  const [t] = useTranslation();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const fontSize = useDrawingToolItemSizeForCurrentZoom({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  });

  const onClick = useCallback((e: MouseEvent) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Text,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  // when right click is allowed on text instance then the map context menu is shown
  // in the top left corner of the map because it's missing e.latLng
  const onTextRightClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  if (fontSize < SCALING_MIN_VISIBLE_FONT_SIZE) {
    return null;
  }

  return {
    instance: {
      ...instance,
      // making sure there's always some text so the item is clickable
      text: instance.text ? instance.text : t('Example Text'),
    },
    zIndex,
    disabled: !drawingEventsEnabledRef.current,
    selectionDisabled: true,
    fontSize,
    onClick,
    onRightClick: onTextRightClick,
    onTextMouseOver: onMouseOver,
    onTextMouseOut: onMouseOut,
  };
};

const useDrawingToolSelectedTextProps = (instance: DrawingItemText, etag?: string): DrawingToolTextInstanceTextProps | null => {
  const [startPoint, setStartPoint] = useState(instance.startPoint);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOut, onMouseOver } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();
  const [outlineHovered, setOutlineHovered] = useState(false);

  const prevEtag = usePrevious(etag);

  useEffect(() => {
    if (etag !== prevEtag) {
      setStartPoint(instance.startPoint);
    }
  }, [etag, instance, prevEtag]);

  const pushNewSnapshot = useCallback((text: DrawingItemText) => {
    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Text,
      value: {
        ...text,
      },
    }));
  }, [dispatch]);

  const onFontSizeChange = useCallback((props: DrawingToolScalingItem) => {
    pushNewSnapshot({
      ...instance,
      settings: {
        ...instance.settings,
        fontSize: props.itemSize,
      },
    });
  }, [instance, pushNewSnapshot]);

  const sizeProps = {
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  };

  const fontSize = useDrawingToolItemSizeForCurrentZoom(sizeProps);
  useDrawingToolItemScalingToggle({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  }, onFontSizeChange, etag);

  const onTextChange = useCallback((newText: string) => {
    pushNewSnapshot({
      ...instance,
      text: newText,
    });
  }, [instance, pushNewSnapshot]);

  const onTextDragEnd = useCallback(() => {
    dispatch(drawingToolItemMoveStopped());
    pushNewSnapshot({
      ...instance,
      startPoint,
    });
  }, [dispatch, pushNewSnapshot, instance, startPoint]);

  const onTextDragStart = useCallback(() => {
    dispatch(drawingToolItemMoveStarted());
  }, [dispatch]);

  const onTextDragMove = useCallback((latLng: LatLng) => {
    setStartPoint(latLng);
  }, []);

  const onOutlineMouseOver = useCallback(() => {
    setOutlineHovered(true);
    onMouseOver();
  }, [onMouseOver]);

  const onOutlineMouseOut = useCallback(() => {
    setOutlineHovered(false);
    onMouseOut();
  }, [onMouseOut]);

  const renderOutline = useCallback((outline: MapOutlinePositionInstance, outlineSize: number) => {
    if (outline.position !== MapOutlinePosition.Left) {
      return null;
    }

    const size = Math.max(outlineVisuals.dot.size, outlineSize);

    return (
      <MapOutline
        key={outline.id}
        onMouseOver={onOutlineMouseOver}
        onMouseOut={onOutlineMouseOut}
        onDragStart={onTextDragStart}
        onDragMove={(_id, position) => onTextDragMove(position)}
        onDragEnd={onTextDragEnd}
        outline={outline}
        visuals={{
          dot: {
            color: MarkerColor.White,
            size,
            transparent: true,
          },
          circle: {
            color: outlineHovered ? outlineHoverVisuals.circle.color : outlineVisuals.circle.color,
            size: size * MapObjectOutlineCircleToDotRatio.Thick,
            style: 'thick',
          },
        }}
      />
    );
  }, [onOutlineMouseOver, onOutlineMouseOut, onTextDragStart, onTextDragEnd, outlineHovered, onTextDragMove]);

  // clicking on map clears the selected drawing, this prevents the click event to be passed to the map
  const onClick = useCallback((e: MouseEvent) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  // when right click is allowed on text instance then the map context menu is shown
  // in the top left corner of the map because it's missing e.latLng
  const onTextRightClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const renderInstance = useMemo(() => ({
    ...instance,
    startPoint,
  }), [instance, startPoint]);

  return {
    instance: renderInstance,
    zIndex,
    fontSize,
    onClick,
    onRightClick: onTextRightClick,
    onTextMouseOver: onMouseOver,
    onTextMouseOut: onMouseOut,
    onTextChange,
    renderOutline,
    autoFocusOnInit: true,
    etag,
  };
};

type DrawingToolTextProps = {
  instance: DrawingItemText;
  isSelected?: boolean;
  etag?: string;
};

export const DrawingToolTextInstance: FC<DrawingToolTextProps> = ({ instance, isSelected, etag }) => {
  const drawingToolTextProps = useDrawingToolTextProps(instance);
  const drawingToolSelectedTextProps = useDrawingToolSelectedTextProps(instance, etag);
  const props = isSelected ? drawingToolSelectedTextProps : drawingToolTextProps;

  if (!props) {
    return null;
  }

  return (
    <DrawingToolTextInstanceTextContainer {...props} />
  );
};
