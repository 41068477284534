import { useCallback } from 'react';
import { useConfirmationModal } from '../../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../../_shared/utils/hooks';
import {
  useFilteredLatLngSpreadsheetData, useLatLngSpreadsheetData,
} from '../useSpreadsheetData.hook';
import { type RowsPerSpreadsheetWithBasicData } from './rowsPerSpreadsheet';

export const useAffectedMarkersInvisibleWarning = () => {
  const filteredSpreadsheetData = useFilteredLatLngSpreadsheetData();
  const spreadsheetData = useLatLngSpreadsheetData();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const [t] = useTranslation();

  const confirmAllMarkersVisible = useCallback<(
  markers: ReadonlyArray<RowsPerSpreadsheetWithBasicData>, useFilteredData: boolean
  ) => Promise<boolean>>((markers, useFilteredData) => {
      return new Promise((resolve) => {
        const onVerifyError = () => {
          openConfirmationModal({
            title: t('Spreadsheet Data Out Of Sync'),
            text: t('Markers have been recently added or changed by another user. We recommend you to refresh the map page to see the latest data before making any changes.'),
            confirmCaption: t('Proceed anyway'),
            isConfirmButtonDestructive: true,
            onCancel: () => {
              closeConfirmationModal();
              resolve(false);
            },
            onConfirm: () => {
              closeConfirmationModal();
              resolve(true);
            },
          });
        };

        const spreadsheetDataToCheck = useFilteredData ? filteredSpreadsheetData.filter : spreadsheetData;

        for (const marker of markers) {
          for (const row of marker.rows) {
            const visibleMarkerData = spreadsheetDataToCheck.getRowOriginal({
              spreadsheetId: marker.spreadsheetId,
              rowId: row.id,
            });

            if (!visibleMarkerData || visibleMarkerData.lat !== row.lat || visibleMarkerData.lng !== row.lng) {
              onVerifyError();
              return;
            }
          }
        }

        resolve(true);
      });
    }, [filteredSpreadsheetData, spreadsheetData, openConfirmationModal, closeConfirmationModal, t]);

  return {
    confirmAllMarkersVisible,
  };
};
