import { css } from '@emotion/react';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import type { FC } from 'react';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { RoundButtonWithTextComponent } from '~/_shared/baseComponents/buttons/roundButton/roundButtonWithText.component';
import {
  MultiLevelMenuComponent,
  type MultilevelMenuItem,
} from '~/_shared/components/multilevelMenu/multilevelMenu.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { type ThemeProps } from '~/_shared/types/themeProps';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { type MapSettingsMapStylesStylers } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName,
} from '../baseMap.enums';
import {
  baseMapElementTypePrimary,
  baseMapElementTypeTertiary,
  baseMapFeatureTypePrimary,
  baseMapFeatureTypeTertiary,
} from './advancedBaseMap.constants';
import { BaseMapStylersComponent } from './baseMapStylers.component';
import { type BaseMapOverriddenStylesList } from './useGetBaseMapOverriddenStyles.hook';

const CHANGE_INDICATOR_MARGIN = 8;
const CHANGE_INDICATOR_DIMENSION = 8;
const NOT_CHANGE_INDICATOR_MARGIN = CHANGE_INDICATOR_MARGIN + CHANGE_INDICATOR_DIMENSION;
const MULTILEVEL_INDENT = 20 + NOT_CHANGE_INDICATOR_MARGIN;

const multilevelItemStyle = ({ theme, isPrimary, isOverridden }: ThemeProps<{isPrimary: boolean; isOverridden: boolean}>) => css({
  backgroundColor: isPrimary ? theme.backgroundColors.secondary : 'transparent',
  '&:hover': {
    backgroundColor: isPrimary ? theme.backgroundColors.primaryDragged : theme.backgroundColors.tertiary,
  },
  '&:before': isOverridden && {
    backgroundColor: theme.buttonColors.primaryBackground,
    borderRadius: 4,
    content: '""',
    height: CHANGE_INDICATOR_DIMENSION,
    marginRight: CHANGE_INDICATOR_MARGIN,
    width: CHANGE_INDICATOR_DIMENSION,
  },
});

const breadCrumbsStyle = css({
  borderBottom: 'none',
  width: 'calc(100% - 25px)',
});

const wrapperStyle = (theme: Theme) => css({
  background: theme.backgroundColors.primary,
});

type AdvancedBaseMapStylesProps = Readonly<{
  baseMapSettingsChangelist: BaseMapOverriddenStylesList;
  settings: MapSettingsMapStylesStylers | null;

  onBack: () => void;
  onChange: (settings: Partial<MapSettingsMapStylesStylers>) => void;
  onChangeElementType: (element: BaseMapElementTypeName | null) => void;
  onChangeFeatureType: (feature: BaseMapFeatureTypeName | null) => void;
  onResetStyle: () => void;
}>;

export const AdvancedBaseMapStylesComponent: FC<AdvancedBaseMapStylesProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const menuItems = getFeaturesStylesItems({
    t,
    theme,
    ...props,
  });

  return (
    <MultiLevelMenuComponent
      css={wrapperStyle}
      breadcrumbsStyle={breadCrumbsStyle}
      footer={(
        <RoundButtonWithTextComponent
          buttonStyle={RoundButtonStyle.Primary}
          icon={faUndo}
          onClick={props.onResetStyle}
          text={t('Reset')}
        />
      )}
      items={menuItems}
      onBack={props.onBack}
      rootBreadcrumbName={t('Advanced Settings')}
    />
  );
};

const getElementsStylesItems = (
  props: ThemeProps<{
    getIsStyleOverridden: (elementName: BaseMapElementTypeName) => boolean;
    settings: MapSettingsMapStylesStylers | null;
    t: TranslationFnc;

    onChange: (settings: Partial<MapSettingsMapStylesStylers>) => void;
    onChangeElementType: (element: BaseMapElementTypeName) => void;
  }>
): ReadonlyArray<MultilevelMenuItem> => Object.keys(BaseMapElementTypeName).map((elName: BaseMapElementTypeName) => {
  const { t, theme, onChange, settings } = props;
  const elementType = BaseMapElementTypeName[elName];
  const isOverridden = props.getIsStyleOverridden(elName);

  return ({
    indent: baseMapElementTypeTertiary.has(elementType) ? MULTILEVEL_INDENT : isOverridden ? 0 : NOT_CHANGE_INDICATOR_MARGIN,
    name: t(elementType),
    onClick: () => props.onChangeElementType(elementType),
    renderChild: () => (
      settings && (
        <BaseMapStylersComponent
          onChange={onChange}
          settings={settings}
        />
      )
    ),
    style: multilevelItemStyle({
      theme,
      isPrimary: baseMapElementTypePrimary.has(elementType),
      isOverridden,
    }),
  });
});

const getFeaturesStylesItems = (
  props: ThemeProps<{
    baseMapSettingsChangelist: BaseMapOverriddenStylesList;
    settings: MapSettingsMapStylesStylers | null;
    t: TranslationFnc;

    onChange: (settings: Partial<MapSettingsMapStylesStylers>) => void;
    onChangeElementType: (element: BaseMapElementTypeName | null) => void;
    onChangeFeatureType: (feature: BaseMapFeatureTypeName | null) => void;
  }>
): ReadonlyArray<MultilevelMenuItem> => Object.keys(BaseMapFeatureTypeName).map((featureName: BaseMapFeatureTypeName) => {
  const { t, theme } = props;

  const isOverridden = props.baseMapSettingsChangelist.has(featureName);
  const getIsStyleOverridden = (elementName: BaseMapElementTypeName) =>
    props.baseMapSettingsChangelist.has(`${featureName}_${elementName}`);

  return ({
    children: getElementsStylesItems({ ...props, getIsStyleOverridden }),
    indent: baseMapFeatureTypeTertiary.has(featureName) ? MULTILEVEL_INDENT : isOverridden ? 0 : NOT_CHANGE_INDICATOR_MARGIN,
    name: t(BaseMapFeatureTypeName[featureName]),
    onClick: () => {
      props.onChangeElementType(null);
      props.onChangeFeatureType(featureName);
    },
    style: multilevelItemStyle({
      theme,
      isPrimary: baseMapFeatureTypePrimary.has(featureName),
      isOverridden,
    }),
  });
});
