import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAjaxCall } from '../../../../../_shared/utils/hooks/useAjaxCall';
import { updateBoundary } from '../../../../../store/boundaries/boundaries.repository';
import { boundaryItemsUpdateItemNameSuccess } from '../../../../../store/boundaryItems/boundaryItems.actionCreators';
import { useClientIdSelector } from '../../../../../store/selectors/useClientIdSelector';

type UpdateBoundaryParams = {
  boundaryId: number;
  boundaryGroupId: number;
  name: string;
  color?: string;
  opacity?: number;
};

export const useBoundaryUpdate = () => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();

  const { invokeAjax, isLoading } = useAjaxCall((
    clientId: number, params: UpdateBoundaryParams,
  ) =>
    updateBoundary(clientId, params.boundaryGroupId, params.boundaryId, params.name, {
      color: params.color,
      opacity: params.opacity,
    })
  );

  const updateBoundaryRequest = useCallback((
    params: UpdateBoundaryParams,
    onSuccess?: () => void,
  ): Promise<void> => {
    if (clientId === null) {
      return Promise.reject();
    }

    return invokeAjax(clientId, params)
      .then(() => {
        dispatch(boundaryItemsUpdateItemNameSuccess(params.boundaryGroupId, params.boundaryId, params.name));
        onSuccess?.();
      });
  }, [clientId, dispatch, invokeAjax]);

  return {
    updateBoundary: updateBoundaryRequest,
    isLoading,
  };
};
