import { useSelector } from '../../_shared/utils/hooks/useSelector';
import {
  type FilteredLatLngRowData,
  selectFilteredLatLngSpreadsheetData,
  selectFilteredSpreadsheetRowIds,
  selectLatLngSpreadsheetData,
  selectSpreadsheetData,
  selectVisibleRowsWithoutWaypoints,
  type SpreadSheetData,
  type SpreadsheetLatLngRowData,
} from '../../store/selectors/spreadsheetDataMemoizedSelectors';
import {
  type FilteredRowIdsResults, type LatLngRowData,
} from '../../store/spreadsheetData/spreadsheetData.helpers';

export const useSpreadSheetData: () => SpreadSheetData = () => useSelector(selectSpreadsheetData);

export const useLatLngSpreadsheetData: () => SpreadsheetLatLngRowData = () => useSelector(selectLatLngSpreadsheetData);

export const useFilteredSpreadsheetRowIds: () => FilteredRowIdsResults = () => useSelector(selectFilteredSpreadsheetRowIds);

export const useFilteredLatLngSpreadsheetData: () => FilteredLatLngRowData = () => useSelector(selectFilteredLatLngSpreadsheetData);

export const useVisibleRowsWithoutWaypoints = (): ReadonlyArray<LatLngRowData> => useSelector(selectVisibleRowsWithoutWaypoints);
