import { notNullOrUndefined } from '~/_shared/utils/typeGuards';

export const MAX_FRACTIONAL_PART_LENGTH = 10;
export const MAX_NUMBER_LENGTH = 16;
export const MAX_INT32 = Math.pow(2, 31) - 1;

export const limitValueToMaxPossibleLength = (value: string): string => {
  if (value.includes('.')) {
    const valuesSplit = value.split('.');
    const totalPart = limitValueToLength(valuesSplit[0] || '', MAX_NUMBER_LENGTH);
    let maxFractionalPartLength = MAX_FRACTIONAL_PART_LENGTH;
    const maxFractionalPartLengthInValue = MAX_NUMBER_LENGTH - totalPart.length;

    if (maxFractionalPartLength > maxFractionalPartLengthInValue) {
      maxFractionalPartLength = maxFractionalPartLengthInValue;
    }

    const fractionalPart = limitValueToLength(valuesSplit[1] || '', maxFractionalPartLength);
    return `${totalPart}.${fractionalPart}`;
  }
  else {
    return limitValueToLength(value, MAX_NUMBER_LENGTH);
  }
};

export const limitValueToLength = (value: string, maxLength: number): string => {
  if (value.length <= maxLength) {
    return value;
  }

  const newLastSign = value[value.length - 1];
  const newValue = value.slice(0, maxLength - 1).split('');

  return newValue.join('') + newLastSign;
};

export const roundNumberDecimalPlaces = (value: number, decimalPlaces: number) => {
  const exponent = 10 ** decimalPlaces;
  return Math.round((value + Number.EPSILON) * exponent) / exponent;
};

export const commafy = (num: number) => {
  const str = num.toString().split('.');
  if (notNullOrUndefined(str[0]) && str[0].length > 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (notNullOrUndefined(str[1]) && str[1].length > 3) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
};

export const computePercentage = (base: number, fraction: number): number => fraction / base * 100;

export const hash = (str: string): number => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const clamp = (num: number, range: {readonly min: number; readonly max: number}): number =>
  Math.min(Math.max(num, range.min), range.max);
