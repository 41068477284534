import { css } from '@emotion/react';
import { type FC } from 'react';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import { type TeamShareMapPermission } from '../../_shared/entityAccess/entityAccess.repository';
import { MapNameSectionComponent } from '../../map/basicInfo/mapNameSection';
import { MembersTableComponent } from './membersTable/membersTable.component';
import { NewMemberFormContainer } from './newMemberForm/newMemberForm.container';
import { type MapAccessData } from './teamShareMapModal.container';

const modalContentStyle = css({
  padding: '0',
  width: '100%',
});

type TeamShareMapModalComponentProps = ModalProps<{
  currentUserId: number | null;
  isLoading: boolean;
  isSnapshot: boolean;
  mapAccessData: MapAccessData[];
  mapId: number;
  mapName: string;
  onMapAccessChange: () => void;
  onDeleteAccess: (item: MapAccessData) => void;
  onUpdateAccess: (item: MapAccessData, newPermission: TeamShareMapPermission) => void;
}>;

export const TeamShareMapModalComponent: FC<TeamShareMapModalComponentProps> = (props) => {
  const {
    currentUserId, isLoading, mapAccessData, mapId, mapName,
    onMapAccessChange, onDeleteAccess, onUpdateAccess,
    ...modalProps
  } = props;

  const [t] = useTranslation();

  return (
    <ModalComponent
      {...modalProps}
      caption={t('Share & Collaborate on this Map with Team Members')}
      contentStyle={modalContentStyle}
      maxWidth="980px"
    >
      <MapNameSectionComponent
        name={mapName}
        isSnapshot={props.isSnapshot}
      />
      <NewMemberFormContainer
        mapAccessData={mapAccessData}
        mapId={mapId}
        onNewMemberAdded={onMapAccessChange}
      />
      <MembersTableComponent
        currentUserId={currentUserId}
        isLoading={isLoading || !currentUserId}
        mapAccessData={mapAccessData}
        onDeleteAccess={onDeleteAccess}
        onUpdateAccess={onUpdateAccess}
      />
    </ModalComponent>
  );
};
