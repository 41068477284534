import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import type { CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { areSetsEqual } from '~/_shared/utils/collections/collections';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING, UNCLUSTER_BELOW_LIMITS, type UnclusterBelowLevel,
} from '~/_shared/utils/markers/markers.constants';
import { BoundingBox } from '~/map/map/boundingBox';
import { useMapContext } from '~/map/map/mapContext';
import {
  type ClusterableMarker, type ClusterLabelInfo,
} from '~/map/map/mapOverlays/markerClusterer/ClusterableMarker.type';
import {
  MarkerClusterer, type MarkerClustererOptions,
} from '~/map/map/mapOverlays/markerClusterer/markerclusterer';
import {
  useMarkerLabelAboveData, useMarkerLabelData,
} from '~/map/map/markers/useMarkers/useMarkerLabelData.hook';
import {
  useSpreadSheetData, useVisibleRowsWithoutWaypoints,
} from '~/map/map/useSpreadsheetData.hook';
import { type WebglLayers } from '~/map/map/webgl/useWebGL';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  activeMapElementsClusterHovered, activeMapElementsClusterHoverStopped,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { mapComponentSetZoomToBounds } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import {
  useMoveMarkersIsActiveSelector, useMoveMarkersMarkersPositionsSelector,
} from '~/store/frontendState/moveMarkers/moveMarkers.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMapSettingsAreAnyLabelsActiveSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { usePerGroupVisualSettings } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSelectors.hooks';
import {
  useMapSettingsClusterDenseMarkers, useMapSettingsIsClusteringForced, useMapSettingsMarkerUnclusterBelowNSelector,
} from '~/store/mapSettings/markers/mapSettingsMarkersClustering.selectors';
import { useMapSettingsExportImageSettingsModeSelector } from '~/store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import {
  createWebGlPieChartItems, MapClustersManager, type OnClusterClick,
} from './mapClustersManager';
import { useSelectNumberOfStackedMarkers } from './selectStackedMarkers';
import { type StackedMarkerId } from './StackedMarkerId.type';
import { useGetMarkerStackId } from './useStackedMarkers';

type UseMarkerClustererParams = {
  markers: ReadonlyArray<ClusterableMarker>;
  isFirstZoomed: boolean;
};

export const useMarkerClusterer = (
  { markers, isFirstZoomed }: UseMarkerClustererParams,
) => {
  const { map, webglLayers } = useMapContext();
  const { spreadsheetData } = useSpreadSheetData();
  const isClusteringForced = useMapSettingsIsClusteringForced();
  const isClusterDenseMarkersEnabled = useMapSettingsClusterDenseMarkers();
  const unclusterBelowN = useMapSettingsMarkerUnclusterBelowNSelector();
  const [clusterer, setClusterer] = useState<null | MarkerClusterer>(null);
  const [unclusteredMarkerIds, setUnclusteredMarkerIds] = useState<ReadonlySet<StackedMarkerId>>(new Set());
  const [currentClusters, setCurrentClusters] = useState<ReadonlyArray<ClusterLabelInfo>>([]);
  const [areClustersCreatedAfterFirstZoom, setAreClustersCreatedAfterFirstZoom] = useState(false);
  const [isClustererReady, setIsClustererReady] = useState(false);
  const clustersManager = useClustersManager(webglLayers);
  const shouldShowPieCharts = useSelector(s => s.map.mapSettings.data.markers.clusterWPieCharts);
  const lastBounds = useMapComponentLastBoundsSelector();
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const perGroupVisualSettings = usePerGroupVisualSettings();
  const matchupData = useMatchupDataSelector();
  const { openModal: openChartsDetailsModal } = useModal(ModalType.ChartsDetails);
  const labelsActive = useMapSettingsAreAnyLabelsActiveSelector();
  const isMapInteractionActive = useIsMapInteractionActive();
  const isImageExport = !!useMapSettingsExportImageSettingsModeSelector();
  const [t] = useTranslation();
  const numberOfStackedMarkers = useSelectNumberOfStackedMarkers();
  const { getMarkerStackId } = useGetMarkerStackId();

  const visibleRowsData = useVisibleRowsWithoutWaypoints();
  const labelMarkerTexts = useMarkerLabelData(visibleRowsData);
  const labelAboveTexts = useMarkerLabelAboveData(visibleRowsData);
  const numberOfLabeledMarkers = labelsActive
    ? (labelMarkerTexts.size + labelAboveTexts.size)
    : 0;

  const moveMarkersActive = useMoveMarkersIsActiveSelector();
  const moveMarkersPositions = useMoveMarkersMarkersPositionsSelector();

  const markerIdsToIgnore = useMemo(() => (
    moveMarkersActive ?
      new Set<CombinedRowId>(moveMarkersPositions.newMarkerPositions.keys()) :
      new Set<CombinedRowId>()
  ), [moveMarkersActive, moveMarkersPositions]);
  const { markersWithoutIgnored, ignoredMarkers } = useMemo(() => (
    markers.reduce((acc, marker) => {
      if (markerIdsToIgnore.has(marker.rowId)) { //TODO: add spreadsheet row ID check when markersPositions supports multiple spreadsheets
        acc.ignoredMarkers.add(getMarkerStackId(marker));
      }
      else {
        acc.markersWithoutIgnored.push(marker);
      }
      return acc;
    }, { markersWithoutIgnored: [] as ClusterableMarker[], ignoredMarkers: new Set<StackedMarkerId>() })
  ), [getMarkerStackId, markerIdsToIgnore, markers]);

  const isClusteringEnabled = isClusterDenseMarkersEnabled || isClusteringForced;
  const usePieCharts = shouldShowPieCharts && activeGroupColumns.length;

  const previousUnclusteredMarkerIdsRef = useRef<ReadonlySet<StackedMarkerId>>(new Set());
  previousUnclusteredMarkerIdsRef.current = unclusteredMarkerIds;

  const maxMarkersOnScreen = getUnclusterLimit(unclusterBelowN, labelsActive);

  useEffect(() => {
    if (!map || !isFirstZoomed) {
      return;
    }

    const options: MarkerClustererOptions = {
      maxZoom: 1000,
      gridSize: 90,
    };

    setClusterer(new MarkerClusterer(
      map,
      markers => {
        setAreClustersCreatedAfterFirstZoom(true);

        const uniqueMarkerIds = new Set(markers.map(getMarkerStackId));
        const isTheSame = areSetsEqual(uniqueMarkerIds, previousMarkerIdsToRenderRef.current);

        if (!isTheSame) {
          setUnclusteredMarkerIds(uniqueMarkerIds);
        }
      },
      setCurrentClusters,
      () => setIsClustererReady(true),
      options,
    ));
  }, [map, isFirstZoomed, getMarkerStackId]);

  useEffect(() => {
    if (clusterer && isClustererReady && isClusteringEnabled) {
      clusterer.redrawWith(markersWithoutIgnored);
    }
  }, [markersWithoutIgnored, clusterer, isClustererReady, isClusteringEnabled]);

  useEffect(() => () => {
    clustersManager?.updateClusters([]);
    clusterer?.onRemove();
  }, [clusterer, clustersManager]);

  const markersInBounds = useMemo(() => {
    if (!lastBounds) {
      return markersWithoutIgnored.map(getMarkerStackId);
    }

    return markersWithoutIgnored.filter(marker => lastBounds.bounds.contains(marker)).map(getMarkerStackId);
  }, [lastBounds, markersWithoutIgnored, getMarkerStackId]);

  const hasEnoughMarkersOnScreenToCluster = markersInBounds.length > maxMarkersOnScreen;
  const shouldCluster = isClusteringEnabled && hasEnoughMarkersOnScreenToCluster && markersInBounds.length;

  useEffect(() => {
    if (shouldCluster) {
      clustersManager?.updateClusters(currentClusters
        .map(label => ({
          ...label,
          ...(usePieCharts ?
            createWebGlPieChartItems({
              markerIds: Array.from(label.markers.keys()),
              activeGroupColumns, spreadsheetData, perGroupVisualSettings, matchupData,
            }) : {}),
        })));
    }
  }, [clustersManager,
    currentClusters,
    spreadsheetData,
    usePieCharts,
    shouldCluster,
    perGroupVisualSettings,
    matchupData,
    activeGroupColumns]);

  useEffect(() => {
    if (!isClusteringEnabled) {
      clusterer?.clearMarkers();
    }
    if (!shouldCluster) {
      clustersManager?.updateClusters([]);
    }
  }, [isClusteringEnabled, clusterer, clustersManager, shouldCluster]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isMapInteractionActive) {
      clustersManager?.updateOnClickHandler({
        newOnClick: noop,
        newOnDoubleClick: noop,
        newOnMouseOver: noop,
        newOnMouseOut: noop,
      });
    }
    else {
      const newOnClick: OnClusterClick = usePieCharts
        ?
        (markers) => openChartsDetailsModal({
          spreadsheetRowIds: Array.from(markers.keys()),
          modalCaption: t('Pie chart metrics'),
        })
        :
        (_, bounds) => dispatch(mapComponentSetZoomToBounds(new BoundingBox(bounds)));

      clustersManager?.updateOnClickHandler({
        newOnClick,
        newOnDoubleClick: bounds => dispatch(mapComponentSetZoomToBounds(new BoundingBox(bounds))),
        newOnMouseOver: () => dispatch(activeMapElementsClusterHovered()),
        newOnMouseOut: () => dispatch(activeMapElementsClusterHoverStopped()),
      });
    }
  }, [clustersManager, dispatch, isMapInteractionActive, openChartsDetailsModal, t, usePieCharts]);

  const allMarkerIds = useMemo(() => new Set(Array.from(markers.values()).map(getMarkerStackId)),
    [markers, getMarkerStackId]);
  const limitActiveLabelsCount = (numberOfLabeledMarkers + numberOfStackedMarkers) > CLUSTERED_MARKERS_MAX_LABELS_WITHOUT_BOUNDS_ENFORCING;
  const limitNumberOfVisibleMarkers = isClusteringEnabled && markers.length > maxMarkersOnScreen;
  const forceVisibleUnclusteredMarkersToBounds = limitActiveLabelsCount || isImageExport || limitNumberOfVisibleMarkers;

  const unclusteredMarkerIdsWithIgnored = useMemo(() => (
    ignoredMarkers.size ? new Set([...unclusteredMarkerIds, ...ignoredMarkers]) : unclusteredMarkerIds
  ), [ignoredMarkers, unclusteredMarkerIds]);

  const previousMarkerIdsToRenderRef = useRef<ReadonlySet<StackedMarkerId>>(new Set());
  const markerIdsToRender = useMemo(() => {

    if (shouldCluster && lastBounds?.zoomLevel !== clusterer?.getLastZoom()) {
      return previousMarkerIdsToRenderRef.current;
    }

    const visibleMarkers = shouldCluster ? unclusteredMarkerIdsWithIgnored : allMarkerIds;
    if (!forceVisibleUnclusteredMarkersToBounds) {
      return visibleMarkers;
    }

    const visibleMarkersInBounds = markersInBounds.filter(id => visibleMarkers.has(id));
    const visibleMarkersInBoundsIncludingIgnored = ignoredMarkers.size
      ? visibleMarkersInBounds.concat([...ignoredMarkers]) : visibleMarkersInBounds;

    const uniqueMarkerIds = new Set(visibleMarkersInBoundsIncludingIgnored);
    const isTheSame = areSetsEqual(uniqueMarkerIds, previousMarkerIdsToRenderRef.current);

    return isTheSame ? previousMarkerIdsToRenderRef.current : new Set(visibleMarkersInBoundsIncludingIgnored);
  }, [allMarkerIds, clusterer, forceVisibleUnclusteredMarkersToBounds, ignoredMarkers, lastBounds?.zoomLevel,
    markersInBounds, shouldCluster, unclusteredMarkerIdsWithIgnored]);

  previousMarkerIdsToRenderRef.current = markerIdsToRender;

  return {
    markerIdsToRender,
    areClustersCreatedAfterFirstZoom,
    hideMarkersOnRemove: !forceVisibleUnclusteredMarkersToBounds,
  };
};

const useClustersManager = (webglLayers: WebglLayers | undefined) =>
  useMemo(() => {
    if (!webglLayers) {
      return null;
    }
    return new MapClustersManager(webglLayers);
  }, [webglLayers]);

const getUnclusterLimit = (unclusterBelowN: UnclusterBelowLevel | null, labelsActive: boolean) => {
  if (unclusterBelowN) {
    return labelsActive
      ? UNCLUSTER_BELOW_LIMITS[unclusterBelowN].labels
      : UNCLUSTER_BELOW_LIMITS[unclusterBelowN].markers;
  }
  return -1;
};
