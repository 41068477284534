import { css } from '@emotion/react';
import { type FC } from 'react';
import { blankBoundaryStyle } from '~/boundary/settings/defaultBoundarySettings';
import { OverlayLoaderComponent } from '../../../../../_shared/components/overlay/overlayLoader.component';
import { SkeletonWrapperComponent } from '../../../../../_shared/components/skeletonWrapper/skeletonWrapper.component';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { type BoundaryListItem } from './boundaryListing.container';
import { BoundaryListingItemComponent } from './item/boundaryListingItem.component';
import { BoundaryListingItemSkeletonComponent } from './item/boundaryListingItemSkeleton.component';

type BoundaryListingProps = Readonly<{
  items: ReadonlyArray<BoundaryListItem>;
  isLoading: boolean;
  onBoundaryUpdate: (boundaryId: number, newName: string, color: string, opacity: number) => void;
  onRemove: (boundaryId: number) => void;
}>;

const wrapperStyle = ({ theme }: ThemeProps) => css({
  height: '100%',
  position: 'relative',
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

const firstItemStyle = css({
  border: 'none',
});

const noItemsStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.primary,
  margin: 0,
  fontSize: '14px',
  padding: '8px 14px',
});

export const BoundaryListingComponent: FC<BoundaryListingProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={wrapperStyle({ theme })}>
      {props.isLoading && props.items.length === 0 && (
        <SkeletonWrapperComponent
          animationDurationMs={2000}
          itemsCount={3}
          renderItem={({ durationMs, delayMs }) => (
            <BoundaryListingItemSkeletonComponent
              animationDurationMs={durationMs}
              animationDelayMs={delayMs}
            />
          )}
        />
      )}

      {!props.isLoading && props.items.length === 0 && (
        <p css={noItemsStyle({ theme })}>{t('No territories')}</p>
      )}

      {props.items.map((item, index) => (
        <BoundaryListingItemComponent
          css={index === 0 ? firstItemStyle : undefined}
          name={item.name}
          key={item.id}
          onRemove={() => props.onRemove(item.id)}
          isLoading={props.isLoading}
          onChange={(newName, newColor, newOpacity) => props.onBoundaryUpdate(item.id, newName, newColor, newOpacity * 100)}
          color={item.settings.style?.color ?? blankBoundaryStyle.color}
          opacity={(item.settings.style?.opacity ?? blankBoundaryStyle.opacity) / 100}
        />
      ))}

      {props.isLoading && props.items.length > 0 &&
        <OverlayLoaderComponent />
      }
    </div>
  );
};
