import { css } from '@emotion/react';
import {
  faAngleDoubleDown,
  faCog, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import {
  animated, useSpring,
} from '@react-spring/web';
import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { InputSize } from '~/_shared/baseComponents/inputs';
import {
  hexAndOpacityToRGBA,
  rgbColorToString,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { HoverableIconComponent } from '~/_shared/components/icons/hoverableIcon.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import {
  type GroupingColumn, type GroupingColumnNumeric, GroupingType,
} from '~/_shared/types/grouping/grouping';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type GroupChangeRequest } from '../useGroupColumnData';

export type GroupingSettingColumnProps = Readonly<{
  columns: SpreadsheetColumn[];
  isExpanded: boolean;
  selected: GroupingColumn | null;

  onChange: (columnChange: GroupChangeRequest) => void;
  onGroupColumnRemove?: (column: GroupingColumn) => void;
  onIsExpandedChange: (isExpanded: boolean) => void;
  onNumericGroupColumnSettingsClick?: (column: GroupingColumnNumeric) => void;
}>;

const optionContainerStyle = css({
  marginBottom: '8px',
});

const animatedPanelStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.backgroundColors.secondary,
  borderRadius: 4,
  boxSizing: 'border-box',
  padding: 4,
  width: '100%',
});

const mainPanelStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const dropdownStyle = css({
  flex: 1,
  minWidth: 0,
});

const chevronStyle = (props: ThemeProps<{isDisabled: boolean; areOptionsShown: boolean}>) => css({
  color: props.isDisabled ? props.theme.textColors.disabled : 'inherit',
  cursor: props.isDisabled ? 'not-allowed' : 'default',
  margin: '0 8px',
  transform: props.areOptionsShown ? 'rotate(-180deg)' : undefined,
  transition: 'transform .2s',
  '&:hover': {
    color: props.isDisabled ? props.theme.textColors.disabled : props.theme.iconColors.primary,
  },
});

const optionsPanelStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '4px 8px',
});

const toggleStyle = css({
  display: 'flex',
  cursor: 'default',
  fontSize: 12,
  textTransform: 'uppercase',
});

const toggleItemStyle = ({ theme, isActive }: ThemeProps<{ isActive: boolean }>) => {
  const activeBackgroundColor = hexAndOpacityToRGBA(theme.buttonColors.primaryBackground, 0.7);

  return css({
    borderRadius: 4,
    padding: 4,
    color: isActive ? theme.textColors.quaternary : theme.textColors.primary,
    textAlign: 'center',
    ...(isActive && { backgroundColor: rgbColorToString(activeBackgroundColor) }),
    '&:hover': {
      ...(!isActive && { color: theme.textColors.linkOnHover }),
    },
  });
};

const toggleDividerStyle = css({
  margin: '0 6px',
  padding: '4px 0',
});

const iconOptionStyle = ({ theme }: ThemeProps) => css({
  color: theme.iconColors.highContrast,
  marginRight: 12,
  '&:hover': {
    color: theme.iconColors.primary,
  },
});

const trashIconStyle = css({
  background: 'none',
  border: 'none',
  color: 'inherit',
  fontSize: '16px',
  padding: 0,
});

const animationConfig = { tension: 280, friction: 25, clamp: true };
const initialOptionsStyle = { height: 40, overflow: 'hidden', config: animationConfig };
const finalOptionsStyle = { height: 76, overflow: 'hidden', config: animationConfig };
const DEFAULT_TYPE = GroupingType.Text;

export const GroupingSettingColumn: FC<GroupingSettingColumnProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [optionsStyle, optionsStyleApi] = useSpring(() => initialOptionsStyle);

  const { onChange, selected, columns, onNumericGroupColumnSettingsClick, onGroupColumnRemove, onIsExpandedChange, isExpanded } = props;

  const onChangeSource = useCallback((columnId: string) => {
    const option = columns.find(item => item.id === columnId);

    if (!option) {
      return;
    }

    onChange({
      columnId,
      type: selected?.type ?? DEFAULT_TYPE,
      spreadsheetId: option.spreadsheetId,
    });
  }, [columns, onChange, selected?.type]);

  const onChangeType = useCallback((type: GroupingType) => () =>
    selected && onChange({
      columnId: selected.columnId,
      type,
      spreadsheetId: selected.spreadsheetId,
    }), [onChange, selected]);

  const toggleShowDetailsClick = useCallback(() => {
    if (selected) {
      onIsExpandedChange(!isExpanded);
    }
  }, [selected, isExpanded, onIsExpandedChange]);

  const handleClickNumericSettings = useCallback(() => {
    if (onNumericGroupColumnSettingsClick && selected && selected.type === GroupingType.Numeric) {
      onNumericGroupColumnSettingsClick(selected);
    }
  }, [onNumericGroupColumnSettingsClick, selected]);

  const handleRemoveColumn = useMemo(() =>
    onGroupColumnRemove && selected ? () => onGroupColumnRemove(selected) : undefined
  , [onGroupColumnRemove, selected]);

  useEffect(() => {
    if (isExpanded && selected) {
      optionsStyleApi.start(finalOptionsStyle);
    }
    else {
      optionsStyleApi.start(initialOptionsStyle);
    }
  }, [isExpanded, selected, optionsStyleApi]);

  return (
    <div css={optionContainerStyle}>
      <animated.div
        css={animatedPanelStyle({ theme })}
        style={optionsStyle}
      >
        <div css={mainPanelStyle} >
          <RegularDropdownComponent
            css={dropdownStyle}
            inPortal
            inputSize={InputSize.Small}
            onChange={onChangeSource}
            options={columns.map(c => ({ name: c.name, value: c.id }))}
            placeholder={t('No Group Selected')}
            value={selected?.columnId}
            testid="groupingDropdown"
          />
          <FontAwesomeIcon
            onClick={toggleShowDetailsClick}
            icon={faAngleDoubleDown}
            css={chevronStyle({ theme, isDisabled: !selected, areOptionsShown: isExpanded })}
          />
        </div>

        {selected && (
          <div css={optionsPanelStyle}>
            <div css={toggleStyle}>
              <div
                onClick={onChangeType(GroupingType.Text)}
                css={toggleItemStyle({
                  theme, isActive: selected?.type === GroupingType.Text,
                })}
              >
                {t('Text')}
              </div>
              <div css={toggleDividerStyle}> /</div>
              <div
                onClick={onChangeType(GroupingType.Numeric)}
                css={toggleItemStyle({
                  theme, isActive: selected?.type === GroupingType.Numeric,
                })}
              >
                {t('Numeric')}
              </div>
            </div>

            <div>
              {selected.type === GroupingType.Numeric && (
                <FontAwesomeIcon
                  css={iconOptionStyle({ theme })}
                  icon={faCog}
                  onClick={handleClickNumericSettings}
                />
              )}
              {handleRemoveColumn && (
                <button
                  css={trashIconStyle}
                  onClick={handleRemoveColumn}
                >
                  <HoverableIconComponent
                    hoverColor={theme.iconColors.danger}
                    icon={faTrash}
                    testid="remove-grouping-column"
                  />
                </button>
              )}
            </div>
          </div>
        )}
      </animated.div>
    </div>
  );
};
