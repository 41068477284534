import { css } from '@emotion/react';
import { type Property } from 'csstype';
import { type ThemeProps } from '~/_shared/types/themeProps';

export const tableHeaderCellStyle = ({ theme, colSpan }: ThemeProps<{
  readonly colSpan: number;
}>) => css({
  whiteSpace: 'nowrap',
  fontWeight: 400,
  fontSize: 14,
  height: 28,
  color: theme.textColors.quaternary,
  textTransform: 'uppercase',
  textAlign: 'left',
  alignContent: 'center',
  boxSizing: 'border-box',
  background: theme.backgroundColors.quaternary,
  gridColumn: `span ${colSpan}`,
  padding: '0 10px',

  '&:first-of-type': {
    borderRadius: '8px 0 0 0',
  },
  '&:last-of-type': {
    borderRadius: '0 8px 0 0',
  },
});

export const getNoResultsRowStyles = ({ theme, columnCount }: ThemeProps<{readonly columnCount: number}>) => css({
  padding: '10px 0',
  textAlign: 'center',
  background: theme.backgroundColors.primary,
  gridColumn: `span ${columnCount}`,
});

export const cellStyles = ({ theme }: ThemeProps) => ({
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  background: theme.backgroundColors.secondary,
  height: 36,
  alignContent: 'center',
  padding: '0 10px',

  '.row:hover &': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

export const checkboxCellStyles = ({ theme }: ThemeProps) => css({
  ...cellStyles({ theme }),
  paddingRight: 0,
});

export const dataCellStyle = ({ theme }: ThemeProps) => css({
  ...cellStyles({ theme }),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 0,
});

export const rowStyle = css({
  display: 'contents',
});

export const gridStyle = ({ theme, columnWidths }: ThemeProps<{
  readonly columnWidths: ReadonlyArray<Property.ColumnWidth>;
}>) => css({
  width: '100%',
  display: 'grid',
  justifyContent: 'stretch',
  gridTemplateColumns: `${columnWidths.join(' ')}`,
  gridAutoRows: 'min-content',
  boxShadow: `0 2px 5px ${theme.shadowColors.secondary}`,
});
