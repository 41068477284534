import { createUuid } from '~/_shared/utils/createUuid';
import { type MapListingAction } from './mapListing.action';
import {
  MAP_LISTING_CHANGED, MAP_LISTING_SET_SEARCH_QUERY,
} from './mapListing.actionTypes';
import { type MapListingState } from './mapListing.state';

const initialState: MapListingState = {
  searchQuery: '',
  eTag: createUuid(),
};

type ReducerAction = MapListingAction;

export const mapListingReducer = (state = initialState, action: ReducerAction): MapListingState => {
  switch (action.type) {
    case MAP_LISTING_SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };

    case MAP_LISTING_CHANGED:
      return {
        ...state,
        eTag: createUuid(),
      };

    default:
      return state;
  }
};
