import { css } from '@emotion/react';

export const contentStyle = css({
  padding: 0,
});

export const alertStyle = css({
  marginTop: 20,
});

export const itemButtonStyle = css({
  textTransform: 'uppercase',
});
