import { useMemo } from 'react';
import { isNullsy } from '~/_shared/utils/typeGuards';
import { useZIndexSeries } from './useZIndexSeries.hook';
import {
  calculateEntityZIndexOffset, ZIndexedEntity,
} from './zIndexRanges';

export const proximitySubEntities = ['entity', 'outline', 'label', 'labelText'] as const;
const idMapper = (id: string) => id;

export type ProximityEntity = typeof proximitySubEntities[number];
export type ProximityEntityZIndex = {
  [P in ProximityEntity]: number;
};

const dummyZIndexes = proximitySubEntities.reduce((zIndex, entity) => {
  zIndex[entity] = 0;
  return zIndex;
}, {} as Partial<ProximityEntityZIndex>) as ProximityEntityZIndex;

export const useIndividualOrDriveTimeProximityZIndex = (id: string, entityType: 'circle' | 'polygon') => {
  const orderedEntities = useMemo(() => [id], [id]);

  const zIndexes = useZIndexSeries(() => ({
    orderedEntities,
    orderedSubEntities: proximitySubEntities,
    idMapper,
    zIndexedEntity: entityType === 'circle' ? ZIndexedEntity.ProximityCircle : ZIndexedEntity.DriveTimePolygon,
  }), [entityType, orderedEntities]).subEntityZIndexes[0];

  // Proximity markers are always on top of all proximities in the proximity series
  const markerZIndex = calculateProximityMarkerZIndex(zIndexes);

  return useMemo(() => ({
    ...zIndexes ?? dummyZIndexes,
    marker: markerZIndex,
  }), [markerZIndex, zIndexes]);
};

const calculateProximityMarkerZIndex = (zIndexes: ProximityEntityZIndex | undefined) => {
  // Calculate the base z-index of all proximity entities
  const proximityZIndexOffset = Math.min(
    calculateEntityZIndexOffset(ZIndexedEntity.ProximityCircle),
    calculateEntityZIndexOffset(ZIndexedEntity.DriveTimePolygon)
  );

  // Calculate the base z-index of all proximity markers
  const proximityMarkerZIndexOffset = calculateEntityZIndexOffset(ZIndexedEntity.ProximityMarker);

  // Calculate the position of the proximity entity in the proximity series
  const proximityPosition = !isNullsy(zIndexes)
    ? (zIndexes.entity - proximityZIndexOffset) / proximitySubEntities.length
    : 0;

  // Calculate the z-index of the proximity marker based on the position of the proximity entity
  const markerZIndex = proximityMarkerZIndexOffset + proximityPosition;

  return markerZIndex;
};
