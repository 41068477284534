import { css } from '@emotion/react';
import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { MAPTIVE_TERMS_OF_SERVICE } from '~/_shared/constants/maptive';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';

const modalBodyStyle = css({
  padding: '10px',
});

const titleStyle = css({
  fontSize: '24px',
  marginBottom: '10px',
});

const paragraphStyle = css({
  marginBottom: '10px',
  lineHeight: 1.5,
});

const licensesStyle = css({
  lineHeight: 1.5,
  whiteSpace: 'pre-wrap',
  fontSize: 14,
  // License text is formated for monospace (contains hard line breaks)
  // IBM Plex Mono is externaly hosted font and thus available on all platforms
  // We need to use the same monospace font on all platforms to ensure consitent formatting
  fontFamily: '"IBM Plex Mono", monospace',
  fontWeight: 400,
});

const linkStyle = css({
  fontSize: 'inherit',
});

const hrStyle = (theme: Theme) => css({
  margin: '20px 0',
  border: 0,
  borderTop: `1px solid ${theme.borderColors.primary}`,
});

type AboutUsContentComponentProps = {
  children: ReactNode;
};

export const AboutUsContentComponent = ({ children }: AboutUsContentComponentProps) => {
  const [t] = useTranslation();

  return (
    <div css={modalBodyStyle}>
      <h2 css={titleStyle}>About Maptive</h2>
      <p css={paragraphStyle}>Copyright © 2010 - {new Date().getFullYear()} West Roots, LLC (DBA Maptive)</p>
      <p css={paragraphStyle}>
        <Trans i18nKey="aboutUs.termsOfService">
          {'The use of this product is subject to the terms of the '}
          <LinkComponent
            css={linkStyle}
            path={MAPTIVE_TERMS_OF_SERVICE}
            target="_blank"
          >
            Maptive Terms of Service
          </LinkComponent>
          , unless otherwise specified therein.
        </Trans>
      </p>
      <hr css={hrStyle} />
      <p css={[paragraphStyle, { textTransform: 'uppercase' }]}>
        {t('aboutUs.attributionNoticesHeader')}
      </p>
      <hr css={hrStyle} />
      <p css={licensesStyle}>
        {children}
      </p>
    </div>
  );
};
