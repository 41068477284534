import {
  FRONTEND_STATE_LASSO_DRAWING_FINISHED,
  FRONTEND_STATE_LASSO_OUTLINE_HOVER,
  FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT,
  FRONTEND_STATE_LASSO_POLYGON_HOVER,
  FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT,
  FRONTEND_STATE_LASSO_REMOVE_HOVER, FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT,
  FRONTEND_STATE_LASSO_START,
  FRONTEND_STATE_LASSO_STOP, FRONTEND_STATE_ROUTING_LASSO_START,
} from './lasso.actionTypes';

export const startLassoTool = () => ({
  type: FRONTEND_STATE_LASSO_START,
}) as const;

export const lassoToolDrawingFinished = () => ({
  type: FRONTEND_STATE_LASSO_DRAWING_FINISHED,
}) as const;

export const stopLassoTool = () => ({
  type: FRONTEND_STATE_LASSO_STOP,
}) as const;

export const hoverLassoOutline = () => ({
  type: FRONTEND_STATE_LASSO_OUTLINE_HOVER,
}) as const;

export const stopLassoOutlineHover = () => ({
  type: FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT,
}) as const;

export const startLassoPolygonHover = () => ({
  type: FRONTEND_STATE_LASSO_POLYGON_HOVER,
}) as const;

export const stopLassoPolygonHover = () => ({
  type: FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT,
}) as const;

export const startRoutingLassoSelection = () => ({
  type: FRONTEND_STATE_ROUTING_LASSO_START,
}) as const;

export const hoverLassoRemoveButton = () => ({
  type: FRONTEND_STATE_LASSO_REMOVE_HOVER,
}) as const;

export const stopLassoRemoveButtonHover = () => ({
  type: FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT,
}) as const;
