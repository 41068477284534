import {
  call, put, takeLatest, takeLeading,
} from 'redux-saga/effects';
import { hasActiveLicense } from '~/_shared/types/client/license.helpers';
import { registerMigrationEventBroadcastListener } from '~/_shared/utils/api/broadcast.helpers';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { appStore } from '~/store/app.store';
import { mapReset } from '~/store/map/map.actionCreators';
import type { MapMigrationAction } from '~/store/mapMigration/mapMigration.action';
import {
  v4MapCompleteMigrationInit, v4MapFetchFail, v4MapFetchSuccess, v4MapInitiateCompleteMigrationFail,
  v4MapInitiateCompleteMigrationSuccess, v4MapInitiateMigrationFail, v4MapInitiateMigrationSuccess,
  v4MapMigrationDismissed, v4MapMigrationErrorMessage, v4MapMigrationInit, v4MapMigrationProgressUpdate,
} from '~/store/mapMigration/mapMigration.actionCreators';
import {
  dismissMigrationQueue, getV4Maps, migrateAllV4Maps, migrateV4Maps,
} from '~/store/mapMigration/mapMigration.repository';
import type {
  V4MapListSuccessResponse, V4MapMigrateSuccessResponse,
} from '~/store/mapMigration/types/mapMigrationResponse.types';
import type { UserDataAction } from '~/store/userData/userData.action';
import {
  USER_GET_DATA_SUCCESS, USER_WEB_SOCKET_BROADCAST_INITIALIZED,
} from '~/store/userData/userData.actionTypes';
import { userCurrentClientSelector } from '~/store/userData/userData.selectors';
import { boundaryItemsReset } from '../boundaryItems/boundaryItems.actionCreators';
import type { CurrentClient } from '../userData/repository/userData.repository';

export function* mapMigrationSagas(): any {
  yield takeLatest(USER_WEB_SOCKET_BROADCAST_INITIALIZED, registerMigrationSocketEventsProcessor);
  yield takeLatest(USER_GET_DATA_SUCCESS, v4MapInitialRequest);
  yield takeLatest(v4MapMigrationInit.type, migrateMaps);
  yield takeLatest([
    v4MapMigrationInit.type,
    v4MapCompleteMigrationInit.type,
    v4MapInitiateMigrationSuccess.type,
    v4MapMigrationProgressUpdate.type,
    v4MapInitiateCompleteMigrationSuccess.type,
    v4MapMigrationDismissed.type,
  ], resetRelevantData);
  yield takeLeading(v4MapCompleteMigrationInit.type, migrateAllMaps);
  yield takeLeading(v4MapMigrationDismissed.type, dismissCompleteQueue);
  yield takeLatest(v4MapMigrationProgressUpdate.type, completeMigration);
}

function* resetRelevantData() {
  yield put(mapReset());
  yield put(boundaryItemsReset());
}

function* v4MapInitialRequest(action: PickAction<UserDataAction, typeof USER_GET_DATA_SUCCESS>) {
  try {
    const { clientId, currentClient } = action.payload.data;
    const migrationDone = currentClient?.migrationDone;
    if (clientId && hasActiveLicense(currentClient?.license) && !migrationDone) {
      const v4Maps: V4MapListSuccessResponse = yield call(getV4Maps, clientId);
      yield put(v4MapFetchSuccess(v4Maps));
    }
  }
  catch (e) {
    yield put(v4MapFetchFail(e));
  }
}

function* migrateMaps(action: PickAction<MapMigrationAction, typeof v4MapMigrationInit.type>) {
  try {
    const { v4MapIdsToMigrate, clientId } = action.payload;
    if (notEmpty(v4MapIdsToMigrate) && clientId) {
      const migrationData: V4MapMigrateSuccessResponse = yield call(migrateV4Maps, clientId, v4MapIdsToMigrate);
      yield put(v4MapInitiateMigrationSuccess(migrationData));
    }
  }
  catch (e) {
    yield put(v4MapInitiateMigrationFail(e));
  }
}

function* migrateAllMaps(action: PickAction<MapMigrationAction, typeof v4MapCompleteMigrationInit.type>): Generator<any, any, any> {
  try {
    const { clientId } = action.payload;
    if (clientId) {
      const migrationData = yield call(migrateAllV4Maps, clientId);
      yield put(v4MapInitiateCompleteMigrationSuccess(migrationData));
    }
  }
  catch (e) {
    yield put(v4MapInitiateCompleteMigrationFail(e));
  }
}

function registerMigrationSocketEventsProcessor() {
  registerMigrationEventBroadcastListener({
    onEvent: message => {
      appStore.dispatch(v4MapMigrationProgressUpdate(message));
    },
    onError: message => {
      appStore.dispatch(v4MapMigrationErrorMessage(message));
    },
  });
}

function* dismissCompleteQueue(action: PickAction<MapMigrationAction, typeof v4MapMigrationDismissed.type>): Generator<any, any, any> {
  try {
    const { queueId, clientId } = action.payload;
    if (queueId && clientId) {
      yield call(dismissMigrationQueue, clientId, queueId);
    }
  }
  catch (e) {
    // Do nothing. Just don't make Saga crash.
    console.error(e);
  }
}

function* completeMigration(action: PickAction<MapMigrationAction, typeof v4MapMigrationProgressUpdate.type>): Generator<any, any, any> {
  try {
    const { message: { percent } } = action.payload;
    if (percent >= 100) {
      const client: CurrentClient | null = yield select(userCurrentClientSelector);
      if (client) {
        const { id: clientId, migrationDone, license } = client;
        if (clientId && hasActiveLicense(license) && !migrationDone) {
          const v4Maps: V4MapListSuccessResponse = yield call(getV4Maps, clientId);
          yield put(v4MapFetchSuccess(v4Maps));
        }
      }
    }
  }
  catch (e) {
    // Do nothing. Just don't make Saga crash.
    console.error(e);
  }
}
