import type { CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';

export type MarkerPositions = {
  newMarkerPositions: Map<CombinedRowId, NewPosition>;
};

type NewPosition = {
  lat: number;
  lng: number;
};

export type MoveMarkersState = Readonly<{
  isActive: boolean;
  isModalOpen: boolean;
  markersPositions: MarkerPositions;
}>;

export const createMarkerNewPositionStore = () => {
  return ({
    newMarkerPositions: new Map<string, NewPosition>(),
  });
};

const createMarkerUniqueIdentifier = (rowId: string) => `${rowId}`;

export const upsertMarkerPosition = (markerStore: MarkerPositions, rowId: string, lat: number, lng: number): MarkerPositions => {
  const updatedPosition = new Map(markerStore.newMarkerPositions).set(createMarkerUniqueIdentifier(rowId), {
    lat,
    lng,
  });

  return { newMarkerPositions: updatedPosition };
};

export const markerWasMoved = (markerStore: MarkerPositions, rowId: string): boolean => {
  return markerStore.newMarkerPositions.has(createMarkerUniqueIdentifier(rowId));
};

export const getMarkerNewPosition = (markerStore: MarkerPositions, rowId: string): NewPosition | undefined => {
  return markerStore.newMarkerPositions.get(createMarkerUniqueIdentifier(rowId));
};

export const anyMarkersMoved = (markerStore: MarkerPositions): boolean => {
  return markerStore.newMarkerPositions.size > 0;
};

export function* getAllNewMarkerPositions(markerStore: MarkerPositions) {
  for (const [key, value] of markerStore.newMarkerPositions) {
    yield ({ rowId: key, lat: value.lat, lng: value.lng });
  }
  return;
}
