import { type FC } from 'react';
import type { MapPrivacyClickParams } from '~/map/listing/tableData/mapRow/mapRow.component';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { type MapListingItem } from '../item/mapListingItem';
import { type ChangePrivacyLevelProps } from '../useMapListingItems';
import { TableDataComponent } from './tableData.component';

type TableDataContainerProps = Readonly<{
  data: ReadonlyArray<MapListingItem>;
  onMapPrivacyClick: (data: MapPrivacyClickParams) => void;
  onMapSelectToggle: (mapId: number) => void;
  onShareSuccess: (props: ChangePrivacyLevelProps) => void;
  onShowSnapshotsToggle: (mapId: number) => void;
  onSnapshotSelectToggle: (params: Readonly<{ mapId: number; snapshotId: number }>) => void;
  selectedMapsIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  toggledMapIds: ReadonlyArray<number>;
  width: number;
}>;

export const TableDataContainer: FC<TableDataContainerProps> = (props) => {
  const currentUserId = useUserIdSelector();

  return (
    <TableDataComponent
      currentUserId={currentUserId}
      data={props.data}
      onMapPrivacyClick={props.onMapPrivacyClick}
      onMapSelectToggle={props.onMapSelectToggle}
      onShowSnapshotsToggle={props.onShowSnapshotsToggle}
      onSnapshotSelectToggle={props.onSnapshotSelectToggle}
      selectedMapsIds={props.selectedMapsIds}
      selectedSnapshotIds={props.selectedSnapshotIds}
      toggledMapIds={props.toggledMapIds}
      width={props.width}
      onShareSuccess={props.onShareSuccess}
    />
  );
};
