import { createAction } from '@reduxjs/toolkit';
import {
  type MapInfo, type MapPrivacyLevel,
} from '../../_shared/types/map';
import { type ApiError } from '../../_shared/utils/api/apiError.helpers';
import {
  type MapPresentationalResponse,
  type MapUpdateRequest,
} from '../../map/map.repository';
import { type MapSnapshotUpdateModel } from '../../sidebar/sidebarApps/saveMapView/saveMapView.repository';
import {
  MAP_INFO_FETCH_DATA_ERROR,
  MAP_INFO_FETCH_DATA_REQUEST,
  MAP_INFO_FETCH_DATA_SUCCESS,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS, MAP_INFO_SNAPSHOT_REMOVE_SUCCESS, MAP_INFO_UPDATE_BAD_DATA_COUNT,
  MAP_INFO_UPDATE_ERROR,
  MAP_INFO_UPDATE_PRIVACY,
  MAP_INFO_UPDATE_REQUEST,
  MAP_INFO_UPDATE_SUCCESS,
  MAP_UPDATE_SNAPSHOT_SUCCESS,
} from './mapInfo.actionTypes';

export const mapInfoFetchDataRequest = (clientId: number, mapId: number) => ({
  type: MAP_INFO_FETCH_DATA_REQUEST,
  payload: {
    clientId,
    mapId,
  },
}) as const;

export const mapInfoFetchDataError = () => ({
  type: MAP_INFO_FETCH_DATA_ERROR,
}) as const;

export const mapInfoFetchSuccess = (data: MapInfo) => ({
  type: MAP_INFO_FETCH_DATA_SUCCESS,
  payload: {
    data,
  },
}) as const;

export const mapInfoPresentationalFetchDataRequest = (shareMapId: string, password?: string) => ({
  type: MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST,
  payload: {
    shareMapId,
    password,
  },
}) as const;

export const mapInfoPresentationalFetchDataError = (error: ApiError) => ({
  type: MAP_INFO_PRESENTATIONAL_FETCH_DATA_ERROR,
  payload: {
    error,
  },
}) as const;

export const mapInfoPresentationalFetchSuccess = (data: MapPresentationalResponse) => ({
  type: MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS,
  payload: {
    data,
  },
}) as const;

export const mapInfoUpdateRequest = (clientId: number, mapId: number, request: MapUpdateRequest) => ({
  type: MAP_INFO_UPDATE_REQUEST,
  payload: {
    clientId,
    mapId,
    request,
  },
}) as const;

export const mapInfoUpdateError = (errors?: { readonly [key: string]: ReadonlyArray<string> }) => ({
  type: MAP_INFO_UPDATE_ERROR,
  payload: {
    errors,
  },
}) as const;

export const mapInfoUpdateSuccess = (request: MapUpdateRequest) => ({
  type: MAP_INFO_UPDATE_SUCCESS,
  payload: {
    request,
  },
}) as const;

export const mapSnapshotUpdateSuccessful = (updateModel: MapSnapshotUpdateModel) => ({
  type: MAP_UPDATE_SNAPSHOT_SUCCESS,
  payload: {
    updateModel,
  },
}) as const;

export const mapSnapshotRemoveSuccess = (snapshotId: number) => ({
  type: MAP_INFO_SNAPSHOT_REMOVE_SUCCESS,
  payload: {
    snapshotId,
  },
}) as const;

export const mapInfoUpdateBadDataCount = (virtualSpreadsheetId: number, badDataCount: number) => ({
  type: MAP_INFO_UPDATE_BAD_DATA_COUNT,
  payload: {
    virtualSpreadsheetId,
    badDataCount,
  },
}) as const;

export const mapInfoUpdatePrivacy = (newPrivacyLevel: MapPrivacyLevel) => ({
  type: MAP_INFO_UPDATE_PRIVACY,
  payload: {
    newPrivacyLevel,
  },
}) as const;

export const mapInfoSetV4MigrationDone = createAction(
  'MAP_INFO_SET_MIGRATION_DONE',
  (isDone: boolean) => ({
    payload: { isDone },
  })
);
