import { type FileAttachmentId } from '~/_shared/types/file.types';
import { type MarkerAnchorPosition } from '~/_shared/types/marker.types';
import {
  type CombinedRowId,
  type SpreadsheetId,
  type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  type MarkerSettings, type MarkersVisualSettings,
} from '../../../_shared/types/markers/visualSettings.types';
import {
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR,
  MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
  MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS,
  MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS,
} from './mapSettingsMarkersGeneral.actionTypes';

type PartialMarkersVisualSettings = Omit<Partial<MarkersVisualSettings>, 'groupMarkerSettings' | 'individualMarkerSettings'>;

export const mapSettingsUpdateMarkersGeneral = (settings: PartialMarkersVisualSettings) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_UPDATE_SETTINGS,
  payload: {
    settings,
  },
}) as const;

export const setIndividualMarkerSettings = (id: SpreadsheetRowId, settings: MarkerSettings) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_SET_INDIVIDUAL_SETTINGS,
  payload: {
    id,
    settings,
  },
}) as const;

export const setTextGroupMarkerSettings = (payload: {
  spreadsheetId: SpreadsheetId;
  columnId: string;
  groupKey: string;
  settings: MarkerSettings | null;
}) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_SET_TEXT_GROUP_SETTINGS,
  payload,
}) as const;

export const setNumericGroupBucketMarkerSettings = (payload: {
  spreadsheetId: SpreadsheetId;
  columnId: string;
  bucketKey: string;
  bucketId: number;
  settings: MarkerSettings | null;
}) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_BUCKET_SETTINGS,
  payload,
}) as const;

export const setNumericGroupMarkerSettings = (payload: {
  spreadsheetId: SpreadsheetId;
  columnId: string;
  bucketKey: string;
  settings: ReadonlyArray<MarkerSettings>;
}) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_SET_NUMERIC_GROUP_SETTINGS,
  payload,
}) as const;

export const removeGroupMarkerSettings = (groups: {spreadsheetId: number; columnId: string}[]) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_GROUP_SETTINGS,
  payload: {
    groups,
  },
}) as const;

export const removeIndividualMarkerSettings = (items: {spreadsheetId: number; rowId: CombinedRowId}[]) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_INDIVIDUAL_SETTINGS,
  payload: {
    items,
  },
}) as const;

export const removeAllIndividualMarkerSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_SETTINGS,
  payload: {},
}) as const;

export const removeAllMarkerSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_SETTINGS,
  payload: {},
}) as const;

export const removeAllIndividialMarkerLabelSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_LABEL_SETTINGS,
  payload: {},
}) as const;

export const removeAllIndividialMarkerAboveLabelSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_INDIVIDUAL_ABOVE_LABEL_SETTINGS,
  payload: {},
}) as const;

export const removeAllMarkerLabelSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_LABEL_SETTINGS,
  payload: {},
}) as const;

export const removeAllMarkerAboveLabelSettings = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_ALL_ABOVE_LABEL_SETTINGS,
  payload: {},
}) as const;

export const hideAllMarkers = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_HIDE_ALL_MARKERS,
}) as const;

export const showAllMarkers = () => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_VISIBILITY_SHOW_ALL_MARKERS,
}) as const;

export const removeMapSettingsMarkersFileAttachments = (attachmentIds: ReadonlyArray<FileAttachmentId>) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_REMOVE_FILE_ATTACHMENTS,
  payload: {
    attachmentIds,
  },
}) as const;

export const setMapSettingsMarkerTextureAnchor = (attachmentId: FileAttachmentId, anchor: MarkerAnchorPosition) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_SET_CUSTOM_TEXTURE_ANCHOR,
  payload: {
    attachmentId,
    anchor,
  },
}) as const;

export const removeMapSettingsMarkerTextureAnchor = (attachmentId: FileAttachmentId) => ({
  type: MAP_SETTINGS_MARKERS_GENERAL_DELETE_CUSTOM_TEXTURE_ANCHOR,
  payload: {
    attachmentId,
  },
}) as const;
