import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  type DropdownOption, DropdownPlacement, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { PaginationGoToPageComponent } from '~/_shared/components/pagination/goToPage/paginationGoToPage.component';
import { PaginationComponent } from '~/_shared/components/pagination/pagination.component';
import { PaginationViewInfoComponent } from '~/_shared/components/pagination/paginationViewInfo.component';
import { mq } from '~/_shared/style.mixins';
import type { Pagination } from '~/_shared/types/pagination/pagination';
import { noop } from '~/_shared/utils/function.helpers';
import { notUndefined } from '~/_shared/utils/typeGuards';
import { DataTableBadLocationsComponent } from '~/data/table/dataTableBadLocations.component';

const GOTO_LABEL_MQ_BREAKPOINT = 900;
const LIMIT_NECESSARY_MQ_BREAKPOINT = 490;
const START_WRAPPING_MQ_BREAKPOINT = 820;
const ALL_ITEMS_FIT_INLINE_MQ_BREAKPOINT = 1300;

const wrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
  [mq(START_WRAPPING_MQ_BREAKPOINT)]: {
    justifyContent: 'space-between',
  },
});

const centerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const goToPageStyle = css({
  display: 'none',
  [mq(START_WRAPPING_MQ_BREAKPOINT)]: {
    display: 'block',
  },
});

const goToLabelStyle = css({
  display: 'none',
  [mq(GOTO_LABEL_MQ_BREAKPOINT)]: {
    display: 'block',
  },
});

const paginationStyle = css({
  margin: '0 10px',
  padding: '10px 0',
});

const viewFromToOfBaseStyle = css({
  display: 'none',
  margin: '0 10px',
});

// When no bad data is present - rendered as a separate right-aligned element, keeping it distinct from the centered pagination controls
const viewFromToOfSideStyle = (hasBadData: boolean) => css(viewFromToOfBaseStyle, {
  [mq(START_WRAPPING_MQ_BREAKPOINT)]: {
    display: 'block',
  },
  [mq(ALL_ITEMS_FIT_INLINE_MQ_BREAKPOINT)]: {
    display: hasBadData ? 'none' : 'block',
  },
});

// When no bad is present - rendered centered grouped with the middle section
const viewFromToOfMiddleStyle = (hasBadData: boolean) => css(viewFromToOfBaseStyle, {
  [mq(ALL_ITEMS_FIT_INLINE_MQ_BREAKPOINT)]: {
    display: hasBadData ? 'block' : 'none',
  },
});

const perPageDropdownStyle = css({
  display: 'none',
  [mq(LIMIT_NECESSARY_MQ_BREAKPOINT)]: {
    marginRight: 10,
    display: 'block',
  },
  [mq(ALL_ITEMS_FIT_INLINE_MQ_BREAKPOINT)]: {
    margin: '0 10px',
  },
});

const badLocationsTriggerStyle = ({ hasPagination }: { hasPagination: boolean }) => css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: hasPagination ? 0 : 10,
  textAlign: 'right',
  width: '100%',
  [mq(ALL_ITEMS_FIT_INLINE_MQ_BREAKPOINT)]: {
    width: hasPagination ? 'auto' : '100%',
  },
  [mq(START_WRAPPING_MQ_BREAKPOINT)]: {
    justifyContent: 'end',
  },
});

const perPageDropdownTriggerStyle = css({
  paddingRight: 0,
});

type DataTableBadLocationProps = Readonly<{
  badLocationsCount?: number;
  onBadLocationsClick: (() => void);
}>;

export type DataTablePaginationProps = Readonly<{
  pagination: Pagination;
  perPageOptions?: ReadonlyArray<DropdownOption<number>>;

  onPageSelect: (selectedValue: number) => void;
  onPerPageChange: ((newPerPage: number) => void);
}>;

type DataTableBottomSectionProps = Readonly<{
  badDataProps?: DataTableBadLocationProps;
  paginationProps?: DataTablePaginationProps;
}>;

export const DataTableBottomSectionComponent: FC<DataTableBottomSectionProps> = props => {
  const { badDataProps, paginationProps } = props;
  const hasPagination = !!paginationProps && paginationProps.pagination.lastPage > 1;

  return (
    <div css={wrapperStyle}>
      {hasPagination && (
        <>
          <div css={goToPageStyle}>
            <PaginationGoToPageComponent
              currentPage={paginationProps.pagination.currentPage}
              labelStyle={goToLabelStyle}
              onPageSelect={paginationProps.onPageSelect ?? noop}
              pageCount={paginationProps.pagination.lastPage}
            />
          </div>

          <span css={centerStyle}>
            <PaginationComponent
              css={paginationStyle}
              currentPage={paginationProps.pagination.currentPage}
              onPageSelect={paginationProps.onPageSelect ?? noop}
              pageCount={paginationProps.pagination.lastPage}
            />
            {paginationProps.perPageOptions && (
              <RegularDropdownComponent
                css={perPageDropdownStyle}
                disableAutoHeight
                dropdownPlacement={DropdownPlacement.Top}
                inPortal
                inputSize={InputSize.Small}
                onChange={paginationProps.onPerPageChange ?? noop}
                options={paginationProps.perPageOptions}
                triggerStyle={perPageDropdownTriggerStyle}
                value={paginationProps.pagination.perPage}
              />
            )}
            <PaginationViewInfoComponent
              css={viewFromToOfMiddleStyle(!!badDataProps)}
              pagination={paginationProps.pagination}
            />
          </span>

          <PaginationViewInfoComponent
            css={viewFromToOfSideStyle(!!badDataProps)}
            pagination={paginationProps.pagination}
          />
        </>
      )}

      {notUndefined(badDataProps?.badLocationsCount) && (
        <DataTableBadLocationsComponent
          css={badLocationsTriggerStyle({ hasPagination })}
          badLocationsCount={badDataProps.badLocationsCount}
          onBadLocationsClick={badDataProps.onBadLocationsClick}
        />
      )}
    </div>
  );
};
