/**
 * Copyright 2019 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This is an adjusted version. Basically just the part that sorts markers into clusters remains.
 * The code was adjusted not to render the markers on the map. Instead it just creates clusters as necessary
 * with markers sorted into those clusters accordingly and then provides information about the clusters.
 * Also the data structure for markers was changed for performance reasons.
 */

/**
 * @name MarkerClustererPlus for Google Maps V3
 * @author Gary Little
 * @fileoverview
 * The library creates and manages per-zoom-level clusters for large amounts of markers.
 * <p>
 * This is an enhanced V3 implementation of the V2 MarkerClusterer by Xiaoxi Wu. It is
 * based on the V3 MarkerClusterer port by Luke Mahe. MarkerClustererPlus was created
 * by Gary Little.
 * <p>
 * v2.0 release: MarkerClustererPlus v2.0 is backward compatible with MarkerClusterer v1.0. It
 *  adds support for the `ignoreHidden`, `title`, `batchSizeIE`,
 *  and `calculator` properties as well as support for four more events. It also allows
 *  greater control over the styling of the text that appears on the cluster marker. The
 *  documentation has been significantly improved and the overall code has been simplified and
 *  polished. Very large numbers of markers can now be managed without causing Javascript timeout
 *  errors on Internet Explorer. Note that the name of the `clusterclick` event has been
 *  deprecated. The new name is `click`, so please change your application code now.
 */

import { flatten } from '~/_shared/utils/collections/collections';
import { delay } from '~/_shared/utils/delay';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { SpreadsheetRowIdMap } from '~/_shared/utils/spreadsheet/spreadsheetRowIdMap';
import { type CancellablePromise } from '~/_shared/utils/types/common.type';
import { OverlayViewSafe } from '../_shared/overalyViewSafe';
import { Cluster } from './cluster';
import {
  type ClusterableMarker, type ClusterLabelInfo,
} from './ClusterableMarker.type';

/**
 * This event is fired on the {@link MarkerClusterer} instance when the `MarkerClusterer` stops clustering markers.
 *
 * Example:
 * ```typescript
 *  mc.addListener('clusteringend', (mc: MarkerClusterer) => {})
 * ```
 *
 * @param mc The MarkerClusterer whose markers are being clustered.
 * @event clusteringend
 */
export declare function clusteringend(mc: MarkerClusterer): void;

/**
 * This event is fired on the {@link MarkerClusterer} instance when the `MarkerClusterer` begins clustering markers.
 *
 * Example:
 * ```typescript
 *  mc.addListener('clusteringbegin', (mc: MarkerClusterer) => {})
 * ```
 *
 * @param mc The MarkerClusterer whose markers are being clustered.
 * @event clusteringbegin
 */
export declare function clusteringbegin(mc: MarkerClusterer): void;

/**
 * Optional parameter passed to the {@link MarkerClusterer} constructor.
 */
export interface MarkerClustererOptions {
  /**
   * The grid size of a cluster in pixels. The grid is a square.
   *
   * @default `60`
   */
  gridSize?: number;
  /**
   * The maximum zoom level at which clustering is enabled or
   * `null` if clustering is to be enabled at all zoom levels.
   *
   * @default `null`
   */
  maxZoom?: number;
  /***
   * Whether the position of a cluster marker should be
   * the average position of all markers in the cluster. If set to `false`, the
   * cluster marker is positioned at the location of the first marker added to the cluster.
   *
   * @default `false`
   */
  averageCenter?: boolean;
  /**
   * The minimum number of markers needed in a cluster
   * before the markers are hidden and a cluster marker appears.
   *
   * @default `2`
   */
  minimumClusterSize?: number;
  /**
   * Set this property to the number of markers to be processed in a single batch when using
   * a browser other than Internet Explorer (for Internet Explorer, use the batchSizeIE property instead).
   *
   * @default `MarkerClusterer.BATCH_SIZE`
   */
  batchSize?: number;
  /**
   * When Internet Explorer is
   * being used, markers are processed in several batches with a small delay inserted between
   * each batch in an attempt to avoid Javascript timeout errors. Set this property to the
   * number of markers to be processed in a single batch; select as high a number as you can
   * without causing a timeout error in the browser. This number might need to be as low as 100
   * if 15,000 markers are being managed, for example.
   *
   * @default `MarkerClusterer.BATCH_SIZE_IE`
   */
  batchSizeIE?: number;
}

type MarkersMap = SpreadsheetRowIdMap<ClusterableMarker>;

export class MarkerClusterer extends OverlayViewSafe {
  /**
   * The number of markers to process in one batch.
   */
  static BATCH_SIZE = 2000;

  private markers_ = new SpreadsheetRowIdMap<ClusterableMarker>();
  private clusters_: Cluster[] = [];
  private listeners_: google.maps.MapsEventListener[] = [];

  // private activeMap_: google.maps.Map | null = null;
  private ready_ = false;

  private gridSize_: number;
  private minClusterSize_: number;
  private maxZoom_: number;

  private averageCenter_: boolean;

  private batchSize_: number;

  private lastZoom_: number;
  private timerRefStatic: CancellablePromise | null;

  private markersInClusters: Set<ClusterableMarker>;

  private readonly onRenderedMarkersChange: (renderedMarkers: ReadonlyArray<ClusterableMarker>, zoomLevel: number | null) => void;
  private readonly onClustersInfoChange: (clustersInfo: ReadonlyArray<ClusterLabelInfo>) => void;
  private readonly onAdded?: () => void;

  /**
   * Creates a MarkerClusterer object with the options specified in {@link MarkerClustererOptions}.
   * @param map The Google map to attach to.
   * @param onRenderedMarkersChange
   * @param onClustersInfoChange
   * @param options The optional parameters.
   */
  constructor(
    map: google.maps.Map,
    onRenderedMarkersChange: (renderedMarkers: ReadonlyArray<ClusterableMarker>, zoomLevel: number | null) => void,
    onClustersInfoChange: (clustersInfo: ReadonlyArray<ClusterLabelInfo>) => void,
    onAdded: (() => void) | null,
    options: MarkerClustererOptions = {},
  ) {
    super();

    this.onRenderedMarkersChange = onRenderedMarkersChange;
    this.onClustersInfoChange = onClustersInfoChange;
    this.onAdded = onAdded ?? undefined;

    this.gridSize_ = options.gridSize || 60;
    this.minClusterSize_ = options.minimumClusterSize || 2;
    this.maxZoom_ = options.maxZoom || 1000_000;

    this.averageCenter_ = options.averageCenter ?? false;

    this.batchSize_ = options.batchSize || MarkerClusterer.BATCH_SIZE;

    this.markersInClusters = new Set();

    // this.addMarkers(markers, true);
    this.setMap(map); // Note: this causes onAdd to be called
  }

  public getLastZoom = () => this.lastZoom_;

  /**
   * Implementation of the onAdd interface method.
   * @ignore
   */
  onAdd(): void {
    // this.activeMap_ = this.getMap() as google.maps.Map;
    this.ready_ = true;

    if (this.markers_.size || this.clusters_.length) {
      this.repaint();
    }

    const map = this.getMap();
    if (!map) {
      return;
    }

    this.lastZoom_ = map.getZoom() || 0;

    // Add the map event listeners
    this.listeners_ = [
      google.maps.event.addListener(map, 'zoom_changed', () => {
        const map: google.maps.Map & {
          minZoom: number;
          maxZoom: number;
          mapTypes: { [type: string]: google.maps.MapType };
        } = this.getMap() as any;

        // Fix for bug #407
        // Determines map type and prevents illegal zoom levels
        const minZoom = map.minZoom || 0;
        const maxZoom = Math.min(
          map.maxZoom || 100,
          map.mapTypes[map.getMapTypeId() || 'roadmap']?.maxZoom || 100
        );
        const zoom = clamp(this.getMap()?.getZoom() || 0, { min: minZoom, max: maxZoom });

        if (this.lastZoom_ !== zoom) {
          this.lastZoom_ = zoom;
          this.resetViewport_(false);
        }
      }),
      google.maps.event.addListener(map, 'idle', () => {
        this.redraw_();
      }),
    ];

    if (this.onAdded) {
      this.onAdded();
    }
  }

  /**
   * Implementation of the onRemove interface method.
   * Removes map event listeners and all cluster icons from the DOM.
   * All managed markers are also put back on the map.
   * @ignore
   */
  onRemove(): void {
    // Put all the managed markers back on the map:
    // this.markerIdsToRender = new Set(keys(this.markers_));
    // for (let i = 0; i < this.markers_.length; i++) {
    //   if (this.markers_[i].getMap() !== this.activeMap_) {
    //     this.markers_[i].setMap(this.activeMap_);
    //   }
    // }

    // Remove all clusters:
    for (let i = 0; i < this.clusters_.length; i++) {
      this.clusters_[i]?.remove();
    }
    this.clusters_ = [];

    // Remove map event listeners:
    for (let i = 0; i < this.listeners_.length; i++) {
      const listener = this.listeners_[i];
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    }
    this.listeners_ = [];

    // this.activeMap_ = null;
    this.ready_ = false;
  }

  /**
   * Implementation of the draw interface method.
   * @ignore
   */
  draw(): void {/* empty */}

  /**
   *  Fits the map to the bounds of the markers managed by the clusterer.
   */
  // fitMapToMarkers(padding: number | google.maps.Padding): void {
  //   const markers = this.getMarkers();
  //   const bounds = new google.maps.LatLngBounds();
  //   for (let i = 0; i < markers.length; i++) {
  //     const position = markers[i].getPosition();
  //     // March 3, 2018: Bug fix -- honor the ignoreHidden property
  //     if (position && (markers[i].getVisible() || !this.getIgnoreHidden())) {
  //       bounds.extend(position);
  //     }
  //   }
  //
  //   (this.getMap() as google.maps.Map).fitBounds(bounds, padding);
  // }

  getMarkersToRender(): ReadonlyArray<ClusterableMarker> {
    return flatten(this.clusters_
      .filter(cluster => cluster.getShowMarkers())
      .map(cluster => Array.from(cluster.getMarkers().values())));
  }

  getClusterLabelsInfo(): ReadonlyArray<ClusterLabelInfo> {
    return this.clusters_
      .filter(cluster => !cluster.getShowMarkers() && cluster.getCenter())
      .map((cluster, index) => ({
        markers: cluster.getMarkers(),
        position: cluster.getCenter() || { lat: 0, lng: 0 },
        boundsOfAllMarkers: cluster.getBounds(),
        index,
      }));
  }

  /**
   * Returns the value of the `gridSize` property.
   *
   * @return The grid size.
   */
  getGridSize(): number {
    return this.gridSize_;
  }

  /**
   * Sets the value of the `gridSize` property.
   *
   * @param gridSize The grid size.
   */
  setGridSize(gridSize: number): void {
    this.gridSize_ = gridSize;
  }

  /**
   * Returns the value of the `minimumClusterSize` property.
   *
   * @return The minimum cluster size.
   */
  getMinimumClusterSize(): number {
    return this.minClusterSize_;
  }

  /**
   * Sets the value of the `minimumClusterSize` property.
   *
   * @param minimumClusterSize The minimum cluster size.
   */
  setMinimumClusterSize(minimumClusterSize: number): void {
    this.minClusterSize_ = minimumClusterSize;
  }

  /**
   *  Returns the value of the `maxZoom` property.
   *
   *  @return The maximum zoom level.
   */
  getMaxZoom(): number {
    return this.maxZoom_;
  }

  /**
   *  Sets the value of the `maxZoom` property.
   *
   *  @param maxZoom The maximum zoom level.
   */
  setMaxZoom(maxZoom: number): void {
    this.maxZoom_ = maxZoom;
  }

  /**
   * Returns the value of the `averageCenter` property.
   *
   * @return True if averageCenter property is set.
   */
  getAverageCenter(): boolean {
    return this.averageCenter_ || false;
  }

  /**
   *  Sets the value of the `averageCenter` property.
   *
   *  @param averageCenter The value of the averageCenter property.
   */
  setAverageCenter(averageCenter: boolean): void {
    this.averageCenter_ = averageCenter;
  }

  /**
   *  Returns the array of markers managed by the clusterer.
   *
   *  @return The array of markers managed by the clusterer.
   */
  getMarkers(): MarkersMap {
    return this.markers_;
  }

  /**
   *  Returns the number of markers managed by the clusterer.
   *
   *  @return The number of markers.
   */
  getTotalMarkers(): number {
    return this.markers_.size;
  }

  /**
   * Returns the current array of clusters formed by the clusterer.
   *
   * @return The array of clusters formed by the clusterer.
   */
  getClusters(): Cluster[] {
    return this.clusters_;
  }

  /**
   * Returns the number of clusters formed by the clusterer.
   *
   * @return The number of clusters formed by the clusterer.
   */
  getTotalClusters(): number {
    return this.clusters_.length;
  }

  isReady(): boolean {
    return this.ready_;
  }

  /**
   * Adds an array of markers to the clusterer. The clusters are redrawn unless
   *  `nodraw` is set to `true`.
   *
   * @param markers The markers to add.
   * @param nodraw Set to `true` to prevent redrawing.
   */
  addMarkers(markers: ReadonlyArray<ClusterableMarker>, nodraw?: boolean): Promise<void> {
    for (const marker of markers) {
      if (this.markers_.has(marker)) {
        continue;
      }
      // if (Object.prototype.hasOwnProperty.call(markers, key)) {
      this.pushMarkerTo_(marker);
      // }
    }
    if (!nodraw) {
      return this.redraw_();
    }
    return Promise.resolve();
  }

  redrawWith(markers: ReadonlyArray<ClusterableMarker>): Promise<void> {
    this.resetViewport_(true);
    this.markers_ = new SpreadsheetRowIdMap<ClusterableMarker>();

    return this.addMarkers(markers);
  }

  /**
   * Pushes a marker to the clusterer.
   *
   * @param marker The marker to add.
   */
  private pushMarkerTo_(
    marker: ClusterableMarker
  ): void {
    this.markers_.set(marker, marker);
  }

  /**
   * Removes a marker from the cluster.  The clusters are redrawn unless
   *  `nodraw` is set to `true`. Returns `true` if the
   *  marker was removed from the clusterer.
   *
   * @param marker The marker to remove.
   * @param nodraw Set to `true` to prevent redrawing.
   * @return True if the marker was removed from the clusterer.
   */
  removeMarker(marker: ClusterableMarker, nodraw?: boolean): boolean {
    const removed = this.removeMarker_(marker);

    if (!nodraw && removed) {
      this.repaint();
    }

    return removed;
  }

  /**
   * Removes an array of markers from the cluster. The clusters are redrawn unless
   *  `nodraw` is set to `true`. Returns `true` if markers were removed from the clusterer.
   *
   * @param markers The markers to remove.
   * @param nodraw Set to `true` to prevent redrawing.
   * @return True if markers were removed from the clusterer.
   */
  removeMarkers(markers: ClusterableMarker[], nodraw?: boolean): boolean {
    let removed = false;

    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      if (marker) {
        const r = this.removeMarker_(marker);
        removed = removed || r;
      }
    }

    if (!nodraw && removed) {
      this.repaint();
    }

    return removed;
  }

  /**
   * Removes a marker and returns true if removed, false if not.
   *
   * @param marker The marker to remove
   * @return Whether the marker was removed or not
   */
  private removeMarker_(marker: ClusterableMarker): boolean {
    return this.markers_.delete(marker);
  }

  /**
   * Removes all clusters and markers from the map and also removes all markers
   *  managed by the clusterer.
   */
  clearMarkers(): void {
    this.resetViewport_(true);
    this.markers_ = new SpreadsheetRowIdMap<ClusterableMarker>();
    this.onRenderedMarkersChange([], null);
    this.onClustersInfoChange([]);
  }

  /**
   * Recalculates and redraws all the marker clusters from scratch.
   *  Call this after changing any properties.
   */
  repaint(): void {
    const oldClusters = this.clusters_.slice();
    this.clusters_ = [];
    this.resetViewport_(false);
    this.redraw_();

    // Remove the old clusters.
    // Do it in a timeout to prevent blinking effect.
    setTimeout(() => {
      for (let i = 0; i < oldClusters.length; i++) {
        oldClusters[i]?.remove();
      }
    }, 0);
  }

  /**
   * Returns the current bounds extended by the grid size.
   *
   * @param bounds The bounds to extend.
   * @return The extended bounds.
   * @ignore
   */
  getExtendedBounds(
    bounds: google.maps.LatLngBounds,
    includeScreenWiggle?: boolean,
  ): google.maps.LatLngBounds {
    const projection = this.getProjection();

    // Turn the bounds into latlng.
    const tr = new google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getNorthEast().lng()
    );
    const bl = new google.maps.LatLng(
      bounds.getSouthWest().lat(),
      bounds.getSouthWest().lng()
    );

    // Convert the points to pixels and the extend out by the grid size
    // and a portion of screen size so after panning we can still see some clusters before redrawing
    const screenWidthWiggle = screen.width / 5;
    const screenHeightWiggle = screen.height / 5;
    const trPix = projection.fromLatLngToDivPixel(tr);
    if (trPix) {
      trPix.x += this.gridSize_ + (includeScreenWiggle ? screenWidthWiggle : 0);
      trPix.y -= this.gridSize_ + (includeScreenWiggle ? screenHeightWiggle : 0);
    }

    const blPix = projection.fromLatLngToDivPixel(bl);
    if (blPix) {
      blPix.x -= this.gridSize_ - (includeScreenWiggle ? screenWidthWiggle : 0);
      blPix.y += this.gridSize_ - (includeScreenWiggle ? screenHeightWiggle : 0);
    }

    // Convert the pixel points back to LatLng
    const ne = projection.fromDivPixelToLatLng(trPix);
    const sw = projection.fromDivPixelToLatLng(blPix);

    // Extend the bounds to contain the new bounds.
    if (ne && sw) {
      bounds.extend(ne);
      bounds.extend(sw);
    }

    return bounds;
  }

  /**
   * Redraws all the clusters.
   */
  private redraw_(): Promise<void> {
    return this.createClusters_()
      .then(() => this.onRenderedMarkersChange(this.getMarkersToRender(), this.getLastZoom()))
      .then(() => this.onClustersInfoChange(this.getClusterLabelsInfo()));
  }

  /**
   * Removes all clusters from the map. The markers are also removed from the map
   *  if `hide` is set to `true`.
   *
   * @param hide Set to `true` to also remove the markers from the map.
   */
  private resetViewport_(_?: boolean): void {
    // Remove all the clusters
    for (let i = 0; i < this.clusters_.length; i++) {
      this.clusters_[i]?.remove();
    }
    this.clusters_ = [];
    this.markersInClusters = new Set();

    // Reset the markers to not be added and to be removed from the map.
    // if (hide) {
    //   this.markerIdsToRender = new Set();
    // }
  }

  /**
   * Calculates the distance between two latlng locations in km.
   *
   * @param p1 The first lat lng point.
   * @param p2 The second lat lng point.
   * @return The distance between the two points in km.
   * @link http://www.movable-type.co.uk/scripts/latlong.html
   */
  private distanceBetweenPoints_(
    p1: google.maps.LatLng,
    p2: google.maps.LatLng
  ): number {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
    const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((p1.lat() * Math.PI) / 180) *
      Math.cos((p2.lat() * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  /**
   * Determines if a marker is contained in a bounds.
   *
   * @param marker The marker to check.
   * @param bounds The bounds to check against.
   * @return True if the marker is in the bounds.
   */
  private isMarkerInBounds_(
    marker: ClusterableMarker,
    bounds: google.maps.LatLngBounds
  ): boolean {
    return bounds.contains(marker);
  }

  /**
   * Adds a marker to a cluster, or creates a new cluster.
   *
   * @param marker The marker to add.
   */
  private addToClosestCluster_(marker: ClusterableMarker): void {
    let distance = 40000; // Some large number
    let clusterToAddTo = null;
    const position = new google.maps.LatLng(marker);
    for (let i = 0; i < this.clusters_.length; i++) {
      const cluster = this.clusters_[i];
      const center = cluster?.getCenter();
      if (center) {
        const d = this.distanceBetweenPoints_(new google.maps.LatLng(center), position);
        if (d < distance) {
          distance = d;
          clusterToAddTo = cluster;
        }
      }
    }

    if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
      this.markersInClusters.add(marker);
      clusterToAddTo.addMarker(marker);
    }
    else {
      this.markersInClusters.add(marker);
      const cluster = new Cluster(this);
      cluster.addMarker(marker);
      this.clusters_.push(cluster);
    }
  }

  /**
   * Creates the clusters. This is done in batches to avoid timeout errors
   *  in some browsers when there is a huge number of markers.
   *
   * @param iFirst The index of the first marker in the batch of
   *  markers to be added to clusters.
   * @param onScreenMarkersCount
   */
  private createClusters_(nextBatchIterator?: IterableIterator<ClusterableMarker>): Promise<void> {
    if (!this.ready_) {
      return Promise.reject('Clusterer is not ready yet.');
    }

    // Cancel previous batch processing if we're working on the first batch:
    if (!nextBatchIterator) {
      google.maps.event.trigger(this, 'clusteringbegin', this);

      this.timerRefStatic?.cancel();
      this.timerRefStatic = null;
    }

    // Get our current map view bounds.
    // Create a new bounds object so we don't affect the map.
    //
    // See Comments 9 & 11 on Issue 3651 relating to this workaround for a Google Maps bug:
    let mapBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(85.02070771743472, -178.48388434375),
      new google.maps.LatLng(-85.08136444384544, 178.00048865625)
    );
    const originalBounds = (this.getMap() as google.maps.Map).getBounds();
    if (originalBounds) {
      mapBounds = new google.maps.LatLngBounds(
        originalBounds.getSouthWest(),
        originalBounds.getNorthEast()
      );
    }
    const bounds = this.getExtendedBounds(mapBounds);

    const iterator = nextBatchIterator ?? this.markers_.values();
    let allMarkersProccessed = false;

    for (let i = 0; i < this.batchSize_; i++) {
      const { value: marker, done } = iterator.next();

      if (done) {
        allMarkersProccessed = true;
        break;
      }

      if (!this.markersInClusters.has(marker) && this.isMarkerInBounds_(marker, bounds)) {
        this.addToClosestCluster_(marker);
      }
    }

    if (!allMarkersProccessed) {
      this.timerRefStatic = delay(0)
        .then(() => this.createClusters_(iterator));

      return this.timerRefStatic;
    }
    else {
      this.timerRefStatic?.cancel();
      this.timerRefStatic = null;
      google.maps.event.trigger(this, 'clusteringend', this);

      return Promise.resolve();
    }
  }
}
