import { type TranslationFnc } from '~/_shared/utils/hooks';
import type { CurrentClient } from '~/store/userData/repository/userData.repository';
import {
  ClientLicenseState, ClientLicenseType,
} from './license';

export const getClientLicenseTypeTranslation = (licenseType: ClientLicenseType, t: TranslationFnc) => (
  t(clientLicenseTypeToTranslation[licenseType] as ClientLicenseTypeTranslations)
);

const clientLicenseTypeToTranslation = {
  [ClientLicenseType.TRIAL]: 'SubscriptionManagement.LicenseType.trial',
  [ClientLicenseType.ANNUAL]: 'SubscriptionManagement.LicenseType.annual',
  [ClientLicenseType.MONTHLY]: 'SubscriptionManagement.LicenseType.monthly',
  [ClientLicenseType['45_DAYS']]: 'SubscriptionManagement.LicenseType.45_DAYS',
} as const;

type ClientLicenseTypeTranslations = typeof clientLicenseTypeToTranslation[keyof typeof clientLicenseTypeToTranslation];

export const getClientLicenseStateTranslation = (licenseState: ClientLicenseState, t: TranslationFnc) => (
  t(clientLicenseStateToTranslation[licenseState] as ClientLicenseStateTranslations)
);

const clientLicenseStateToTranslation = {
  [ClientLicenseState.ACTIVE]: 'SubscriptionManagement.LicenseState.active',
  [ClientLicenseState.EXPIRED]: 'SubscriptionManagement.LicenseState.expired',
  [ClientLicenseState.FAILED_PAY_PERIOD]: 'SubscriptionManagement.LicenseState.failedPayPeriod',
  [ClientLicenseState.GRACE_PERIOD]: 'SubscriptionManagement.LicenseState.gracePeriod',
} as const;

type ClientLicenseStateTranslations = typeof clientLicenseStateToTranslation[keyof typeof clientLicenseStateToTranslation];

export const ActiveLicenseStates = [ClientLicenseState.ACTIVE, ClientLicenseState.GRACE_PERIOD];

export const hasActiveLicense = (license: CurrentClient['license'] | null | undefined): boolean => {
  return !!license && !!license.state && ActiveLicenseStates.includes(license.state);
};
