import {
  apiPatch, apiPost,
} from '~/_shared/utils/api/api.helpers';
import { type MapIntersect } from '~/map/map.repository';

export const BASE_MAPS_COLUMN_ID = 'base_maps';

export type CreateLayeredMapData = {
  name: string;
  description?: string;
  integrated: boolean;
  connected: boolean;
  real_time: boolean;
  maps: number[];
  primary_map: number;
  matches: MapIntersect[];
};

export type CreateLayeredMapResponse = {
  data: {
    id: number;
    share_id: string;
    name: string;
    description?: any;
    privacy: string;
    is_layered: boolean;
    is_matchup_required: boolean;
    views: number;
    last_map_view?: any;
    encrypted: boolean;
    importing: boolean;
    secure_in_process: boolean;
    locked?: any;
    created: {
      id: number;
      name: string;
      date: string;
    };
    updated: {
      id?: any;
      name?: any;
      date: string;
    };
    client: {
      id: number;
      name: string;
      type: string;
    };
    team?: any;
    spreadsheets: {
      spreadsheet_id: number;
      is_encrypted: boolean;
      is_primary: boolean;
      privileges: string;
      real_spreadsheets: {
        real_spreadsheet_id: number;
      }[];
    }[];
    snapshots: any[];
    settings: any[];
  };
};

export const createLayeredMapRequest = (clientId: number, request: CreateLayeredMapData): Promise<CreateLayeredMapResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/layering`;

  return apiPost(requestUrl, request);
};

type ApplyChangesToLayeredMapRealSpreadsheetRequest = {
  id: number;
  snapshot: number;
  version: number;
};

export type ApplyChangesToLayeredMapRequest = {
  real_spreadsheets: ReadonlyArray<ApplyChangesToLayeredMapRealSpreadsheetRequest>;
};

export const applyChangesToLayeredMap = (
  clientId: number, layeredMapId: number, request: ApplyChangesToLayeredMapRequest
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/maps/layering/${layeredMapId}/versions`;

  return apiPost(requestUrl, request);
};

export const editLayeredMapRequest = (clientId: number, layeredMapId: number, data: string[]): Promise<CreateLayeredMapResponse> => {
  const requestUrl = `/api/clients/${clientId}/maps/layering/${layeredMapId}`;

  return apiPatch(requestUrl, { data });
};
