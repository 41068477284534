import {
  type FC, useMemo,
} from 'react';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

type NeonArrowProps = {
  color: string;
  dimensions: QuadrilateralDimensions;
};

export const NeonArrowComponent: FC<NeonArrowProps> = (props) => {
  const borderColor = useMemo(() => {
    const baseHsl = createColor(props.color).hsl;
    return createColor({ ...baseHsl, l: baseHsl.l - 0.15 }).hex;
  }, [props.color]);

  return (
    <svg
      width={props.dimensions.width}
      height={props.dimensions.height}
      viewBox="0 0 53 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23645 37.644L3.35703 37.8426L3.51214 38.0156L23.4622 60.2637L23.5048 60.3112L23.5497 60.3564C24.3936 61.206 25.4962 61.5 26.3998 61.5C27.307 61.5 28.3918 61.2052 29.2332 60.387L29.2929 60.329L29.3486 60.2671L49.372 38.019L49.496 37.8813L49.5982 37.7268C50.3233 36.6319 50.4605 35.1726 50.1005 33.9728C49.9028 33.3137 49.5124 32.5858 48.8168 32.017C48.0954 31.427 47.2099 31.144 46.2877 31.144H39.691L39.9299 10.704L39.9301 10.6894V10.6748C39.9301 7.95503 39.2249 5.56842 37.1398 4.06405C35.2789 2.72135 32.8231 2.5 30.5263 2.5H21.9585C19.7025 2.5 17.2813 2.70354 15.5231 4.17335C13.6287 5.75697 13.1907 8.17373 13.1907 10.6748V31.144H6.2949C5.40566 31.144 4.47504 31.4058 3.72293 32.0826C2.99479 32.7378 2.679 33.5619 2.56301 34.239C2.34833 35.4923 2.70727 36.7724 3.23645 37.644Z"
        fill={guaranteeHash(props.color)}
        stroke={guaranteeHash(borderColor)}
        strokeWidth="5"
      />
    </svg>
  );
};
