import {
  type MutableRefObject, useCallback, useState,
} from 'react';

export const useFocusAttachedTextArea = (objectRef: MutableRefObject<HTMLTextAreaElement | HTMLInputElement | null>) => {
  const [objectAttached, setObjectAttached] = useState(false);

  const markTextAreaAsAttached = useCallback(() => setObjectAttached(true), []);

  const focusTextArea = useCallback(() => {
    setTimeout(() => {
      if (objectRef.current && objectAttached) {
        objectRef.current.focus();
        objectRef.current.setSelectionRange(objectRef.current.value.length, objectRef.current.value.length);
      }
    });
  }, [objectAttached, objectRef]);

  return {
    focusTextArea,
    markTextAreaAsAttached,
  };
};
