import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { type ModalProps } from '~/modal/modalType.enum';
import { isBoundaryTerritoryGroupCustom } from '~/sidebar/sidebarApps/mapTools/boundary/boundaryTerritoryGroup.helpers';
import { type BoundaryCombineGeometryRequestItem } from '~/store/boundaries/boundaries.repository';
import type {
  BoundaryGroupId, BoundaryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { boundarySelectEditCustomBoundarySubmitOpenModal } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { type BoundaryEditManualMethod } from '../boundaryEditModal/boundaryEditModal.helpers';
import { useBoundaryEditActions } from './boundaryEditSubmitActions';
import {
  BoundaryEditSubmitModalComponent, type BoundaryEditSubmitResults,
} from './boundaryEditSubmitModal.component';
import { BoundaryEditSubmitModalAction } from './boundaryEditSubmitModal.helpers';

export type BoundaryEditSubmitModalContainerProps = ModalProps<{
  editedBoundaryTerritoryGroupId: number;
  geometry: BoundaryCombineGeometryRequestItem[];
  onSubmit: (editAnother: boolean) => void;
  method: BoundaryEditManualMethod;
  selectedBoundaryId?: BoundaryId;
  submitAction?: BoundaryEditSubmitModalAction;
  isBoundarySelectDisabled: boolean;
  selectBoundaryGroupId?: BoundaryGroupId;
  hideEditAnother?: boolean;
}>;

export const BoundaryEditSubmitModalContainer: FC<BoundaryEditSubmitModalContainerProps> = (props) => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const { isLoading, editBoundary } = useBoundaryEditActions();
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const editedBoundaryTerritoryGroup = boundaryTerritoryGroups
    .find(group => group.boundaryTerritoryGroupId === props.editedBoundaryTerritoryGroupId);
  const boundaryItems = useMapBoundariesSelector();
  const groupBoundaries = editedBoundaryTerritoryGroup ? boundaryItems.get(editedBoundaryTerritoryGroup.boundaryGroupId) : null;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { onClose, onSubmit } = props;

  const onSelectBoundaryFromMapClick = useCallback((action: BoundaryEditSubmitModalAction) => {
    if (!editedBoundaryTerritoryGroup) {
      return;
    }

    onClose();
    dispatch(boundarySelectEditCustomBoundarySubmitOpenModal({
      selectBoundaryGroupId: editedBoundaryTerritoryGroup.boundaryGroupId,
      boundaryTerritoryGroupId: editedBoundaryTerritoryGroup.boundaryTerritoryGroupId,
      configuration: {
        geometry: props.geometry,
        submitAction: action,
        previousBoundaryGroupId: props.selectBoundaryGroupId,
        method: props.method,
      },
    }));
  }, [editedBoundaryTerritoryGroup, onClose, dispatch, props.geometry, props.selectBoundaryGroupId, props.method]);

  const handleSubmit = useCallback((results: BoundaryEditSubmitResults, editAnother: boolean) => {
    if (!editedBoundaryTerritoryGroup || !groupBoundaries) {
      return;
    }

    const onSuccess = () => {
      onSubmit(editAnother);
      onClose();
    };

    const onError = () => {
      let errorMessage: string;

      switch (results.action) {
        case BoundaryEditSubmitModalAction.AddToExistingTerritory:
          errorMessage = t('Error adding area to an existing territory');
          break;
        case BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory:
          errorMessage = t('Error removing area from territory');
          break;
        case BoundaryEditSubmitModalAction.ReassignTerritory:
          errorMessage = t('Error reassigning area');
          break;
        default:
          errorMessage = '';
      }

      dispatch(createAppError({
        type: AppErrorType.General,
        title: errorMessage,
      }));
    };
    editBoundary(results, editedBoundaryTerritoryGroup.boundaryGroupId, Array.from(groupBoundaries.values()), props.geometry, onSuccess, onError);
  }, [dispatch, editBoundary, editedBoundaryTerritoryGroup, groupBoundaries, onClose, onSubmit, props.geometry, t]);

  if (
    !editedBoundaryTerritoryGroup ||
    !groupBoundaries ||
    !isBoundaryTerritoryGroupCustom(editedBoundaryTerritoryGroup, boundaryGroups) ||
    props.geometry.length === 0
  ) {
    return null;
  }

  return (
    <BoundaryEditSubmitModalComponent
      isLoading={isLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={handleSubmit}
      boundaries={Array.from(groupBoundaries.values())}
      method={props.method}
      selectedBoundaryId={props.selectedBoundaryId}
      editedBoundaryTerritoryGroup={editedBoundaryTerritoryGroup}
      geometry={props.geometry}
      submitAction={props.submitAction}
      isBoundarySelectDisabled={props.isBoundarySelectDisabled}
      onSelectBoundaryFromMapClick={onSelectBoundaryFromMapClick}
      hideEditAnother={props.hideEditAnother ?? false}
    />
  );
};
