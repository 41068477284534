import { type AnimationSegment } from 'lottie-web';
import { getOrSet } from '~/_shared/utils/collections/collections';
import { createColor } from '../../components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../components/colorPicker/colorPicker.types';
import { LottieAnimations } from './animations/lottieAnimations';
import {
  type LottieAnimationSegment, type LottieAnimationType, type LottieJsonConfig,
} from './lottieAnimation.types';

const animationJsons: Map<string, unknown> = new Map();

// for debug purposes
(window as any).maptiveLottieAnimationJsons = animationJsons;

const convertColorToLottieColor = (color: ColorResult) =>
  `[${color.rgb.r / 255},${color.rgb.g / 255},${color.rgb.b / 255},${color.rgb.a}]`;

const getLottieAnimationColorKey = (colorName: string) => `"MAPTIVE_${colorName.toUpperCase()}"`;

const getLottieGradientValue = (gradientColors: ReadonlyArray<number>, colorsOpacity: ReadonlyArray<number>): string => {
  const results = gradientColors.concat(colorsOpacity);
  return `[${results.join(',')}]`;
};

const getLottieAnimationGradientKeys = (gradientName: string) => ({
  color: `"MAPTIVE_${gradientName.toUpperCase()}_GRADIENT"`,
  count: `"MAPTIVE_${gradientName.toUpperCase()}_GRADIENT_COUNT"`,
});

const getAnimationJsonKey = (config: LottieJsonConfig) => {
  const colorKey = Object.entries(config.colors ?? {})
    .sort(([keyA, _A], [keyB, _B]) => keyA.localeCompare(keyB))
    .reduce((result, [key, color]) => `${result}|${key}:${color}`, '');

  const gradientKey = Object.entries(config.gradients ?? {})
    .sort(([keyA, _A], [keyB, _B]) => keyA.localeCompare(keyB))
    .reduce((result, [key, gradient]) => `${result}|${key}:${gradient.map(g => Object.values(g).join(',')).join(';')}`, '');

  return `${config.type}${colorKey}${gradientKey}`;
};

export const loadAnimationJson = async (config: LottieJsonConfig) => {
  const jsonKey = getAnimationJsonKey(config);

  return await getOrSet(animationJsons, jsonKey, async () => {
    const rawJson = await config.getJson();
    let animationString = JSON.stringify(rawJson);

    for (const [key, value] of Object.entries(config.colors ?? {})) {
      const colorKey = getLottieAnimationColorKey(key);
      const color = createColor(value);
      const lottieColor = convertColorToLottieColor(color);
      animationString = animationString.replaceAll(colorKey, lottieColor);
    }

    for (const [key, gradients] of Object.entries(config.gradients ?? {})) {
      const gradientColors: number[] = [];
      const colorsOpacity: number[] = [];

      gradients.forEach((gradient, index) => {
        const color = createColor(gradient.color);
        const offset = gradient.offset !== undefined ? gradient.offset / 100 : index / (gradients.length - 1);
        const startOpacity = (gradient.startOpacity ?? 100) / 100;
        const endOpacity = (gradient.endOpacity ?? 100) / 100;

        gradientColors.push(offset, color.rgb.r / 255, color.rgb.g / 255, color.rgb.b / 255);
        colorsOpacity.push(startOpacity, endOpacity);
      });

      const gradientKeys = getLottieAnimationGradientKeys(key);
      const gradientValue = getLottieGradientValue(gradientColors, colorsOpacity);

      animationString = animationString.replaceAll(gradientKeys.color, gradientValue);
      animationString = animationString.replaceAll(gradientKeys.count, gradients.length.toString());
    }

    return JSON.parse(animationString);
  });
};

export const getLottieAnimationSegmentTotalFrames = (segments: ReadonlyArray<LottieAnimationSegment>) =>
  segments.reduce((count, segment) => count + Math.abs(segment[0] - segment[1]), 0);

/***
 * Returns duration in milliseconds
 */
export const getLottieAnimationDuration = (type: LottieAnimationType, segment?: LottieAnimationSegment | ReadonlyArray<LottieAnimationSegment>) =>
  getLottieAnimationSegmentTotalFrames(normalizeSegmentModel(segment ?? LottieAnimations[type].segments.default)) / LottieAnimations[type].frameRate * 1000;

export const normalizeSegmentModel = (segment: LottieAnimationSegment | ReadonlyArray<LottieAnimationSegment>): AnimationSegment[] => {
  if (segment?.length && Array.isArray(segment[0])) {
    return [...segment as AnimationSegment[]];
  }
  else {
    return [segment as AnimationSegment];
  }
};
