import { css } from '@emotion/react';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  type FormEvent, type ReactNode,
  useMemo,
  useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { TextInputAuthComponent } from '~/_shared/baseComponents/inputs';
import { PasswordStrengthIndicatorComponent } from '~/_shared/components/passwordStrengthIndicator/passwordStrengthIndicator.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { FormErrorMessageComponent } from '../../_shared/components/formErrorMessage/formErrorMessage.component';
import { FormFieldType } from '../../_shared/utils/form/formValidation.helpers';
import { useFormFieldValidation } from '../../_shared/utils/form/useFormFieldValidation';
import { validatePasswords } from '../../_shared/utils/throttle/validation/validation.helpers';
import { authorizationPagesErrorStyle } from '../authorizationPages.styles';
import { AuthorizationSubmitButtonComponent } from '../submitButton/authorizationSubmitButton.component';

export type PasswordResetFormProps = Readonly<{
  isLoading: boolean;
  onSubmit: (password: string, confirmPassword: string, callbackToSetFormSuccess?: () => void) => void;
  error: ReactNode;
  submitButtonText?: string;
  submitSuccessText?: string;
  renderButtons?: ReactNode;
}>;

const rowStyle = css({
  marginBottom: 15,
});

const passwordWrapperStyle = css({
  marginBottom: 8,
});

const passwordStrengthWrapperStyle = css({
  fontSize: 14,
  width: '80%',
  margin: '0 auto 15px',
});

const alertStyle = css({
  marginTop: 10,
});

export const PasswordResetFormComponent: FC<PasswordResetFormProps> = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [t] = useTranslation();

  const { value: password, onBlur: onPasswordBlur, onChange: onPasswordChange, errors: passwordErrors } = useFormFieldValidation({
    type: FormFieldType.Password,
  });
  const { value: confirmPassword, onBlur: onConfirmPasswordBlur, onChange: onConfirmPasswordChange, errors: confirmPasswordErrors } = useFormFieldValidation({
    type: FormFieldType.ConfirmPassword,
    confirmValue: password,
  });

  const isFormValid = useMemo(
    () => validatePasswords(password, confirmPassword) && !props.isLoading,
    [password, confirmPassword, props.isLoading]
  );

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      return;
    }
    props.onSubmit(password, confirmPassword, () => {
      setIsSuccess(true);
    });
  };

  return (
    <form
      onSubmit={onFormSubmit}
    >
      <div>
        {!isSuccess ? (
          <div>
            <div css={passwordWrapperStyle}>
              <TextInputAuthComponent
                placeholder={t('Enter New Password')}
                type="password"
                value={password}
                autoComplete="new-password"
                onChange={onPasswordChange}
                onBlur={onPasswordBlur}
                hasError={passwordErrors.length > 0}
                icon={faLockAlt}
                isDisabled={props.isLoading}
              />

              {passwordErrors.length > 0 && (
                <FormErrorMessageComponent
                  css={authorizationPagesErrorStyle}
                  messages={passwordErrors}
                />
              )}
            </div>

            <div css={[rowStyle, passwordStrengthWrapperStyle]}>
              <PasswordStrengthIndicatorComponent password={password} />
            </div>

            <div css={rowStyle}>
              <TextInputAuthComponent
                placeholder={t('Re-enter new password')}
                type="password"
                value={confirmPassword}
                autoComplete="new-password"
                onChange={onConfirmPasswordChange}
                onBlur={onConfirmPasswordBlur}
                hasError={confirmPasswordErrors.length > 0}
                icon={faLockAlt}
                isDisabled={props.isLoading}
              />

              {confirmPasswordErrors.length > 0 && (
                <FormErrorMessageComponent
                  css={authorizationPagesErrorStyle}
                  messages={confirmPasswordErrors}
                />
              )}
            </div>

            {props.renderButtons ? props.renderButtons : (
              <AuthorizationSubmitButtonComponent
                isLoading={props.isLoading}
                isDisabled={!isFormValid}
              >
                {props.submitButtonText || t('Reset Password')}
              </AuthorizationSubmitButtonComponent>
            )}

            {!!props.error && (
              <AlertComponent
                css={alertStyle}
                type="danger"
              >
                {props.error}
              </AlertComponent>
            )}
          </div>
        ) : (
          <AlertComponent
            css={alertStyle}
            type="success"
          >
            {props.submitSuccessText || t('Your password was reset successfully')}
          </AlertComponent>
        )}
      </div>
    </form>
  );
};
