import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { type ProximityDriveTimePathsState } from '~/store/frontendState/proximityDriveTimePaths/proximityDriveTimePaths.state';
import {
  isDriveTimePolygonSettings, isGroupRadius, isIndividualRadius, type Proximity, type ProximityModel,
} from '../_shared/types/proximity/proximity.types';
import {
  useDriveTimeMessagesSelector,
  useDriveTimePathsSelector,
} from '../store/frontendState/proximityDriveTimePaths/proximityDriveTime.selectors';
import { getDriveTimePathsPathId } from '../store/frontendState/proximityDriveTimePaths/proximityDriveTimePaths.helpers';
import { useProximitesSelector } from '../store/mapSettings/proximity/mapSettingsProximity.selectors';

export const useProximities = () => {
  const proximitiesState = useProximitesSelector();
  const driveTimeData = useDriveTimePathsSelector();
  const driveTimeMessages = useDriveTimeMessagesSelector();

  const proximities = combineProximitiesMemoized(proximitiesState, driveTimeData, driveTimeMessages);

  return {
    proximities,
  };
};

const combineProximities = (
  proximitiesState: ReadonlyArray<ProximityModel>,
  driveTimeData: ProximityDriveTimePathsState['paths'],
  driveTimeMessages: ProximityDriveTimePathsState['messages'],
): ReadonlyArray<Proximity> => {
  const results: Proximity[] = [];

  proximitiesState.forEach(item => {
    if (isIndividualRadius(item) || isGroupRadius(item)) {
      results.push(item);
    }

    if (isDriveTimePolygonSettings(item)) {
      const Id = getDriveTimePathsPathId({
        latLng: {
          lat: item.data.lat,
          lng: item.data.lng,
        },
        minutes: item.data.minutes,
        hours: item.data.hours,
      });
      const paths = driveTimeData[Id];
      const message = driveTimeMessages[Id];

      results.push({
        ...item,
        data: {
          ...item.data,
          message,
          paths,
        },
      });
    }
  });

  return results;
};
const combineProximitiesMemoized = memoizeOne(combineProximities);
