import { PageTitle } from '~/_shared/constants/routes';
import { UndoItemType } from '~/_shared/types/undo/undoItem';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import type { RestoreResult } from '~/undo/useRestoreMap';

const getEntityTypeNameCapitalized = (entityType: RestoreResult['type'], t: TranslationFnc) => {
  return entityType === 'map' ? t(PageTitle.MAP) : t('Map View');
};

const getTranslatedMessage = (message: RestoreResult['message'], t: TranslationFnc): string => {
  switch (message) {
    case 'FAILED':
      return t('An unknown error has occurred.');
    case 'NOT_FOUND':
      return t('The entity was not found.');
    case 'PARENT_MAP_NOT_FOUND':
      return t('The parent map was not found.');
    default:
      return '';
  }
};

const getErrorMessage = (message: RestoreResult['message'], entityType: RestoreResult['type'], entityName: string, t: TranslationFnc): string => {
  return `${t('The {{entityTypeName}} {{entityName}} could not be restored.', {
    entityTypeName: getEntityTypeNameCapitalized(entityType, t),
    entityName,
  })} ${getTranslatedMessage(message, t)}`;
};

export const MapRestoreErrors = ({ errors }: { readonly errors: readonly RestoreResult[] }) => {
  const undoItems = useSelector(state => state.map.undo.data);
  const [t] = useTranslation();

  return (
    <>
      {errors.map((error) => {
        const undoItem = undoItems.find(item => item.data.id === error.id);
        if (undoItem?.type === UndoItemType.DELETE_MAPS_AND_SNAPSHOTS) {
          const { data } = undoItem;
          return (
            <p key={error.id}>
              {getErrorMessage(error.message, error.type, data.name, t)}
            </p>
          );
        }

        return null;
      })}
    </>
  );
};
