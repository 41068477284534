import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import { hexColorWithOpacityToString } from '../colorPicker/colorPicker.helpers';

type FloatingBoxProps = Readonly<{
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  heading?: string;
  children: React.ReactNode;
}>;

const wrapperStyle = ({ theme }: ThemeProps) => css({
  position: 'relative',
  background: hexColorWithOpacityToString(theme.modalColors.contentBackground, .7),
  borderRadius: 4,
  boxShadow: `0 0 0 2px ${theme.buttonColors.secondaryBackground}`,
});

const closeButtonStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: 20,
  height: 34,
  width: 34,
  lineHeight: '34px',
  textAlign: 'center',
  outline: 'none',
});

const contentStyle = css({
  position: 'relative',
  padding: '22px',
});

const headingStyle = ({ theme }: ThemeProps) => css({
  lineHeight: 1.25,
  padding: '9px 10px 6px 10px',
  margin: 0,
  background: theme.iconColors.backgroundPrimary,
  color: theme.textColors.primary,
  borderRadius: '4px 0',
  display: 'inline-block',
  fontWeight: 700,
});

const topBarStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

export const FloatingBoxComponent: FC<FloatingBoxProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      className={props.className}
      css={wrapperStyle({ theme })}
    >
      <div css={topBarStyle}>
        {props.heading &&
          <p css={headingStyle(({ theme }))}>{props.heading}</p>
        }

        {props.onClose && (
          <button
            css={closeButtonStyle({ theme })}
            onClick={props.onClose}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        )}
      </div>

      <div css={contentStyle}>
        {props.children}
      </div>
    </div>
  );
};
