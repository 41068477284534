import { type LassoAction } from '../../../mapTools/lasso/lasso.action';
import {
  FRONTEND_STATE_LASSO_DRAWING_FINISHED,
  FRONTEND_STATE_LASSO_OUTLINE_HOVER,
  FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT,
  FRONTEND_STATE_LASSO_POLYGON_HOVER,
  FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT,
  FRONTEND_STATE_LASSO_REMOVE_HOVER,
  FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT,
  FRONTEND_STATE_LASSO_START,
  FRONTEND_STATE_LASSO_STOP,
  FRONTEND_STATE_ROUTING_LASSO_START,
} from '../../../mapTools/lasso/lasso.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyLassoToolMapOptions = (
  state: MapComponentOptionsState,
  action: LassoAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case FRONTEND_STATE_LASSO_START:
    case FRONTEND_STATE_ROUTING_LASSO_START:
      return push(state, {
        changeId: 'LASSO_DRAWING',
        priority,
        change: {
          draggableCursor: 'crosshair',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_LASSO_DRAWING_FINISHED:
    case FRONTEND_STATE_LASSO_STOP:
      return pop(state, 'LASSO_DRAWING');

    case FRONTEND_STATE_LASSO_OUTLINE_HOVER:
      return push(state, {
        changeId: 'LASSO_OUTLINE_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_LASSO_OUTLINE_MOUSEOUT:
      return pop(state, 'LASSO_OUTLINE_HOVER');

    case FRONTEND_STATE_LASSO_POLYGON_HOVER:
      return push(state, {
        changeId: 'LASSO_POLYGON_HOVER',
        priority,
        change: {
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_LASSO_POLYGON_MOUSEOUT:
      return pop(state, 'LASSO_POLYGON_HOVER');

    case FRONTEND_STATE_LASSO_REMOVE_HOVER:
      return push(state, {
        changeId: 'LASSO_REMOVE_HOVER',
        priority,
        change: {
          gestureHandling: 'none',
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_LASSO_REMOVE_MOUSEOUT:
      return pop(state, 'LASSO_REMOVE_HOVER');

    default:
      return undefined;
  }
};
