import { type RouteCollapsingLevel } from '../../../_shared/constants/routeCollapsingLevel.enum';
import { type RouteUiData } from '../../frontendState/mapTools/directions/directions.state';
import {
  MAP_SETTINGS_DIRECTIONS_ADD_ROUTE,
  MAP_SETTINGS_DIRECTIONS_ADD_ROUTE_SETTING,
  MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES,
  MAP_SETTINGS_DIRECTIONS_MODIFY_ROUTE,
  MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE,
  MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS,
} from './mapSettingsDirections.actionTypes';
import { type MapSettingsRoute } from './mapSettingsDirections.state';

export const setMapDirectionSettings = (selectedCollapsing: RouteCollapsingLevel, canDragRoutes: boolean, shouldBeginAtUserLocation: boolean) => ({
  type: MAP_SETTINGS_DIRECTIONS_SET_DIRECTION_SETTINGS,
  payload: {
    selectedCollapsing,
    canDragRoutes,
    shouldBeginAtUserLocation,
  },
}) as const;

export const addRouteToDirections = (route: MapSettingsRoute, routeUiData: RouteUiData, routeCollapsingLevel: RouteCollapsingLevel) => ({
  type: MAP_SETTINGS_DIRECTIONS_ADD_ROUTE,
  payload: {
    route,
    routeUiData,
    routeCollapsingLevel,
  },
}) as const;

export const removeRouteFromDirections = (route: MapSettingsRoute, mapId: number) => ({
  type: MAP_SETTINGS_DIRECTIONS_REMOVE_ROUTE,
  payload: {
    route,
    mapId,
  },
}) as const;

export const modifyRouteInDirections = (routeId: string, route: MapSettingsRoute) => ({
  type: MAP_SETTINGS_DIRECTIONS_MODIFY_ROUTE,
  payload: {
    routeId,
    route,
  },
}) as const;

export const clearDirectionsRoutes = () => ({
  type: MAP_SETTINGS_DIRECTIONS_CLEAR_ROUTES,
}) as const;

export const addRouteSetting = (route: MapSettingsRoute) => ({
  type: MAP_SETTINGS_DIRECTIONS_ADD_ROUTE_SETTING,
  payload: {
    route,
  },
}) as const;
