import md5 from 'md5';
import { type LatLng } from '~/_shared/types/latLng';
import { notEmpty } from '~/_shared/utils/array/array.helpers';
import {
  createUuid, type Uuid,
} from '~/_shared/utils/createUuid';
import { areLatLngEqual } from '~/_shared/utils/latLng/latLng.helpers';
import { ALL_COLUMNS_ID } from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.constants';

type SearchItemBase = {
  id: Uuid;
  text: string;
  spreadsheetId?: number;
  exactWords?: boolean;
  includeAllGeolocated?: boolean;

  columnId?: string;
  columnName?: string;
  latLng?: ReadonlyArray<LatLng>;
};

export type SearchItemWithColumn = SearchItemBase & {
  columnId: string;
  columnName: string;
};

// Represents any type of search item with available / loaded geolocated data.
export type SearchItemWithGeolocatedData = SearchItemBase & {
  latLng: NonEmptyArray<LatLng>;
};

// Represents geolocated only search item with single lanLng location (from suggestions).
export type SearchItemGeolocated = SearchItemBase & {
  latLng: readonly [LatLng];
};

// Combined search item can include column search and geolocated search with multiple geolocated latLngs.
export type CombinedSearchItem = SearchItemWithColumn & {
  // Undefined means the latLngs are not yet fetched.
  // Empty array means no results.
  latLng: ReadonlyArray<LatLng> | undefined;
};

export type SearchItem = SearchItemBase;

export const isSearchItemWithColumn = (item: SearchItem): item is SearchItemWithColumn => !!item.columnId;

export const isSearchItemWithGeolocatedData = (item: SearchItem): item is SearchItemWithGeolocatedData => notEmpty(item.latLng);

export const isSingleGeolocatedSearchItem = (item: SearchItem): item is SearchItemGeolocated => notEmpty(item.latLng) && !item.includeAllGeolocated;

export const isCombinedSearchItem = (item: SearchItem): item is CombinedSearchItem => isSearchItemWithColumn(item) && !!item.includeAllGeolocated;

export const getSearchGeolocatedPointUuid = (item: SearchItem, latLng: LatLng) => (
  '' + md5(item.id) + '-' + md5(latLng.lat + ':' + latLng.lng)
);

export const areSearchItemsSame = (item1: SearchItem, item2: SearchItem): boolean => {
  const bothItemsNotWithColumns = !isSearchItemWithColumn(item1) && !isSearchItemWithColumn(item2);
  const bothItemsWithSameColumns = isSearchItemWithColumn(item1) && isSearchItemWithColumn(item2)
      && item1.columnId === item2.columnId && item1.columnName === item2.columnName;

  const bothItemsNotGeolocated = !isSingleGeolocatedSearchItem(item1) && !isSingleGeolocatedSearchItem(item2);
  const bothItemsGeolocatedWithSameLatLng = isSingleGeolocatedSearchItem(item1) && isSingleGeolocatedSearchItem(item2)
    && areLatLngEqual(item1.latLng[0], item2.latLng[0]);

  return (bothItemsNotWithColumns || bothItemsWithSameColumns)
    && (bothItemsNotGeolocated || bothItemsGeolocatedWithSameLatLng)
    && item1.exactWords === item2.exactWords
    && item1.includeAllGeolocated === item2.includeAllGeolocated
    && item1.text === item2.text
    && item1.spreadsheetId === item2.spreadsheetId;
};

export const createQuerySearchItem = (query: string): SearchItemWithColumn => {
  return {
    id: createUuid(),
    text: query,
    columnId: ALL_COLUMNS_ID,
    columnName: '',
  };
};
