import {
  type FC, Fragment, type ReactNode,
} from 'react';

type SkeletonWrapperProps = Readonly<{
  className?: string;
  itemsCount: number;
  animationDurationMs: number;
  renderItem: (animationParams: AnimationParams) => ReactNode;
}>;

type AnimationParams = {
  durationMs: number;
  delayMs: number;
};

export const SkeletonWrapperComponent: FC<SkeletonWrapperProps> = (props) => {
  const skeletons = [];

  for (let i = 0; i < props.itemsCount; i++) {
    const animationDelay = i * (props.animationDurationMs / (props.itemsCount * 2));

    skeletons.push((
      <Fragment key={i}>
        {props.renderItem({
          delayMs: animationDelay,
          durationMs: props.animationDurationMs,
        })}
      </Fragment>
    ));
  }

  return (
    <div css={props.className}>
      {skeletons}
    </div>
  );
};
