import { type FileAttachment } from '../../../_shared/types/file.types';
import {
  MAP_SETTINGS_FILE_ATTACHMENTS_ADD,
  MAP_SETTINGS_FILE_ATTACHMENTS_REMOVE,
} from './fileAttachments.actionTypes';

export const mapSettingsFileAttachmentsRemove = (ids: Set<string>) => ({
  type: MAP_SETTINGS_FILE_ATTACHMENTS_REMOVE,
  payload: {
    ids,
  },
}) as const;

export const mapSettingsFileAttachmentsAdd = (newAttachment: FileAttachment) => ({
  type: MAP_SETTINGS_FILE_ATTACHMENTS_ADD,
  payload: {
    attachment: newAttachment,
  },
}) as const;
