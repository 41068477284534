import {
  useCallback, useMemo, useState,
} from 'react';
import { useGetMatchupData } from '~/store/matchupData/useGetMatchupData';

export const useGetMapColumns = () => {
  const { getMatchupData, isLoading, data: perSpreadsheetData, error } = useGetMatchupData();
  const [lastSpreadsheetId, setLastSreadsheetId] = useState<number | null>(null);

  const getMapColumns = useCallback((mapId: number, virtualSpreadsheetId: number) => {
    setLastSreadsheetId(virtualSpreadsheetId);
    getMatchupData([{ mapId, virtualSpreadsheetId }]);
  }, [getMatchupData]);

  const columns = useMemo(() => {
    if (!perSpreadsheetData || !lastSpreadsheetId || isLoading || error) {
      return [];
    }

    const columns = perSpreadsheetData.data[lastSpreadsheetId]?.columns ?? {};

    return Object.entries(columns)
      .filter((column): column is [string, string] => !!column[0] && !!column[1])
      .map(([columnId, name]) => ({ columnId, name }));
  }, [error, isLoading, lastSpreadsheetId, perSpreadsheetData]);

  return {
    getMapColumns,
    isLoading,
    columns,
    error,
  };
};
