import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import {
  RadioGroupComponent, type RadioGroupItem,
} from '~/_shared/baseComponents/radio/radioGroup.component';
import { DebouncedUpdateComponent } from '~/_shared/components/delay/debouncedUpdate.component';
import { createColor } from '../../../../../_shared/components/colorPicker/colorPicker.helpers';
import { ColorPickerWithWheelAndTileComponent } from '../../../../../_shared/components/colorPicker/colorPickerWithTileAndWheel/colorPickerWithTileAndWheel.component';
import { SliderWithValueLabelsComponent } from '../../../../../_shared/components/slider/sliderWithValueLabels/sliderWithValueLabels.component';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { type MapSettingsMapStylesStylers } from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapStylerName, BaseMapStylerVisibility,
} from '../baseMap.enums';

const wrapperStyle = css({
  padding: '0 24px 20px',
});

const titleStyle = css({
  fontSize: 16,
});

const visibilityTitleStyle = css({
  marginLeft: 0,
  marginBottom: 12,
}, titleStyle);

const visibilityStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 24,
});

const stylerItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 28,
});

const radioBtnsStyle = css({
  fontSize: 14,
});

const checkMarkStyle = ({ dimension, spacing }: { dimension: number; spacing: number }) => css({
  width: dimension,
  height: dimension,
  marginRight: spacing,
});

const groupStyle = css({
  display: 'flex',
  alignItems: 'center',
}, titleStyle);

const sliderStyle = css({
  width: '50%',
});

type BaseMapStylersProps = Readonly<{
  settings: MapSettingsMapStylesStylers;

  onChange: (settings: Partial<MapSettingsMapStylesStylers>) => void;
}>;

export const BaseMapStylersComponent: React.FC<BaseMapStylersProps> = props => {
  const [t] = useTranslation();
  const { settings } = props;
  const checkboxDimension = 16;
  const checkboxSpacing = 8;

  const items: RadioGroupItem<string>[] = Object.values(BaseMapStylerVisibility).map(styler => ({
    value: styler,
    label: t(BaseMapStylerVisibility[styler]),
  }));

  const handleVisibilityChange = (value: BaseMapStylerVisibility) => props.onChange({ visibility: value });

  type DiscreteOptions = BaseMapStylerName.visibility;
  const handleIsActiveChange = (elementType: Exclude<BaseMapStylerName, DiscreteOptions>) => {
    props.onChange({
      [elementType]: {
        ...settings[elementType],
        isActive: !settings[elementType].isActive,
      },
    });
  };

  const withCheckbox = ({ elementType, children }: {
    elementType: Exclude<BaseMapStylerName, DiscreteOptions>;
    children: ReactNode;
  }): ReactNode => {
    const isZero = elementType === BaseMapStylerName.color ? false : !settings[elementType].value;
    return (
      <div css={stylerItemStyle}>
        <div css={groupStyle}>
          <CheckboxComponent
            checkmarkStyle={checkMarkStyle({ dimension: checkboxDimension, spacing: checkboxSpacing })}
            isChecked={!isZero && settings[elementType].isActive}
            checkedSetter={() => handleIsActiveChange(BaseMapStylerName[elementType])}
          />
          {t(BaseMapStylerName[elementType])}
        </div>
        {children}
      </div>
    );
  };

  const handleColorChange = (color: string) => {
    props.onChange({
      color: {
        isActive: true,
        value: color,
      },
    });
  };

  type NonNumericOptions = BaseMapStylerName.visibility & BaseMapStylerName.color;
  const handleSliderChange = (value: number, elementType: Exclude<BaseMapStylerName, NonNumericOptions>) => {
    props.onChange({
      [elementType]: {
        isActive: true,
        value,
      },
    });
  };

  return (
    <div css={wrapperStyle}>

      <div css={(visibilityStyle)}>
        <div css={visibilityTitleStyle}>
          {t(BaseMapStylerName.visibility)}
        </div>
        <RadioGroupComponent
          css={radioBtnsStyle}
          items={items}
          onValueChange={handleVisibilityChange}
          selectedValue={settings.visibility}
        />
      </div>

      {withCheckbox({
        elementType: BaseMapStylerName.color,
        children: (
          <DebouncedUpdateComponent
            value={createColor(settings.color.value)}
            onChangeDebounced={(color) => handleColorChange(color.hex)}
            wait={500}
            render={(value, onChange) => (
              <ColorPickerWithWheelAndTileComponent
                selectedColor={value}
                onChange={onChange}
                displayAlpha
              />
            )}
          />
        ),
      })}

      {withCheckbox({
        elementType: BaseMapStylerName.weight,
        children: (
          <SliderWithValueLabelsComponent
            css={sliderStyle}
            currentValueOnTheRight
            max={8}
            min={0}
            onChange={([value]) => {
              return handleSliderChange(value, BaseMapStylerName.weight);
            }}
            step={0.5}
            value={[settings.weight.value]}
          />
        ),
      })}

      {withCheckbox({
        elementType: BaseMapStylerName.saturation,
        children: (
          <SliderWithValueLabelsComponent
            css={sliderStyle}
            currentValueOnTheRight
            max={100}
            min={-100}
            onChange={([value]) => {
              return handleSliderChange(value, BaseMapStylerName.saturation);
            }}
            step={5}
            value={[settings.saturation.value]}
          />
        ),
      })}

      {withCheckbox({
        elementType: BaseMapStylerName.lightness,
        children: (
          <SliderWithValueLabelsComponent
            css={sliderStyle}
            currentValueOnTheRight
            max={100}
            min={-100}
            onChange={([value]) => {
              return handleSliderChange(value, BaseMapStylerName.lightness);
            }}
            step={5}
            value={[settings.lightness.value]}
          />
        ),
      })}

    </div>
  );
};
