import {
  useEffect, useState,
} from 'react';
import { type GoogleMapInstance } from '~/_shared/components/google-map/useGetGoogleMapInstance.hook';
import { createMapObjectLayers } from '../mapObjects/private/mapObjectManager';
import { createMarkerLayers } from '../markers/manager/mapMarkerManager';
import { type MarkerTemplateManager } from '../markers/manager/markerTemplateManager';
import { useGetWebGLOverlayInstance } from './useGetWebGLOverlayInstance.hook';

export const useWebGL = (map?: GoogleMapInstance): [WebGLOverlay | undefined, WebglLayers | undefined, MarkerTemplateManager | undefined] => {
  const [isOverlayReady, setIsOverlayReady] = useState(false);

  const instance = useGetWebGLOverlayInstance(map);

  useEffect(() => {
    if (instance?.isReady) {
      setIsOverlayReady(true);
    }
    else {
      setIsOverlayReady(false);
    }
  }, [instance?.isReady]);

  // useEffect(() => {
  //   if (!overlay) {
  //     return;
  //   }
  //   return () => {
  //     // consider going through all layers and call layer.clear() on unmount
  //     // however so far, entities appear to be removed by their hooks/containers without issues
  //   };
  // }, [overlay]);

  const resultInstance = isOverlayReady && instance ? instance : undefined;

  return [
    resultInstance ? resultInstance.instance : undefined,
    resultInstance ? resultInstance.layers : undefined,
    resultInstance ? resultInstance.markerTemplateManager : undefined,
  ];
};

export const createLayers = () => ({
  ...createMapObjectLayers(),
  ...createMarkerLayers(),
  Heatmaps: new WebGLOverlay.HeatmapLayer(),
  BoundaryPolygons: new WebGLOverlay.PolygonLayer(),
  BoundaryPolygonOutlines: new WebGLOverlay.PolygonLayer(),
  BoundaryPolygonLabelsBackground: new WebGLOverlay.TextBoxCalloutLayer(),
  BoundaryPolygonLabelsText: new WebGLOverlay.LabelLayer('BoundaryLabels', { autoHide: false }),
  DriveTimePolygons: new WebGLOverlay.PolygonLayer(),
  ProximityCircles: new WebGLOverlay.AreaLayer(),
  ProximityLabelsBackground: new WebGLOverlay.TextBoxCalloutLayer(),
  ProximityLabelsText: new WebGLOverlay.LabelLayer('ProximityLabels', { autoHide: false }),
  ProximityCenters: new WebGLOverlay.MarkerLayer(),
  HighPriorityMarkers: new WebGLOverlay.MarkerLayer(),
  BoundarySelectPolygons: new WebGLOverlay.PolygonLayer(),
  BoundarySelectPolygonLabelsBackground: new WebGLOverlay.TextBoxCalloutLayer(),
  BoundarySelectPolygonLabelsText: new WebGLOverlay.LabelLayer('BoundaryLabels', { autoHide: false }),
}) as const;

export const setLayerZIndexesInPropertyOrder = (layers: WebglLayers) =>
  Object.values(layers)
    .forEach((layer, index) => {
      layer.zIndex = index + 1;
    });

export const loadFontOnLabelLayers = (overlay: WebGLOverlay, { name, pathAtlas, pathConfig }: Readonly<{ name: string; pathAtlas: string; pathConfig: string | object }>) => {
  overlay.fontManager.load(name, pathAtlas, pathConfig);
};

export const addLayersToOverlay = (layers: WebglLayers, overlay: WebGLOverlay) => {
  Object.values(layers).forEach(l => overlay.add(l));
};

export type WebglLayers = IsValidLayers<ReturnType<typeof createLayers>>;
type IsValidLayers<T extends { readonly [key: string]: MapLayer<any> }> = T;
export type WebglLayerName = keyof WebglLayers;
export type ExtendsWebglLayers<T extends Partial<WebglLayers>> = keyof Omit<T, keyof WebglLayers> extends never ? T : 'You require a layer that doesn\'t exist.';
