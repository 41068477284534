import type { Writable } from 'ts-essentials';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { getMigrationStatusInfoFromQueueInfo } from '~/store/mapMigration/helpers/migrationInfo.helpers';
import type {
  V4MapInfoServer, V4MapListSuccessResponse,
} from '~/store/mapMigration/types/mapMigrationResponse.types';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import type { V4MapMigrationStatusInfo } from '~/store/mapMigration/types/v4MapMigrationProgressInfo.type';

const convertV4MapInfoFromServerModelToDomainModel = (v4MapInfoServer: V4MapInfoServer): V4MapInfo => {
  const {
    created_at,
    description,
    error,
    id,
    map_owner_v5_id,
    migrated_at,
    migration_status: migrationStatus,
    title,
    updated_at,
  } = v4MapInfoServer;

  return {
    id,
    description,
    error,
    migrationStatus,
    title,
    updatedAt: new Date(updated_at),
    createdAt: new Date(created_at),
    migratedAt: migrated_at ? new Date(migrated_at) : undefined,
    mapOwnerId: map_owner_v5_id,
    dependencies: [],
    ...(v4MapInfoServer.map_type === 'LAYERED' ? {
      mapType: 'LAYERED',
      baseMapIds: v4MapInfoServer.base_map_ids,
    } : {
      mapType: 'BASE',
    }),
  };
};

type MapMigrationDTOToDomainModelResult = {
  readonly v4MapInfos: readonly V4MapInfo[];
  readonly migrationStatusInfo?: V4MapMigrationStatusInfo;
};

export const getTransitiveDependencies = (depMap: Readonly<Record<number, readonly number []>>): Readonly<Record<number, readonly number []>> => {
  const result: Record<number, number[]> = {};

  const resolveDependencies = (key: number, visited: Record<number, boolean> = {}): number[] => {
    if (visited[key]) {
      // If we've seen this key before, return an empty array to break circular dependencies
      return [];
    }

    const priorResult = result[key];
    if (priorResult) {
      // If we've already resolved this key, return the result
      return priorResult;
    }

    visited[key] = true; // Add current node to the visited ones.
    const directDependencies = new Set(depMap[key] || []); // Start with the direct dependencies.
    const allDependencies = new Set(directDependencies);

    for (const dep of directDependencies) {
      if (depMap[dep]) {
        const transitiveDeps = resolveDependencies(dep, visited);
        for (const tDep of transitiveDeps) {
          allDependencies.add(tDep); // Add transitive dependencies
        }
      }
    }

    return Array.from(allDependencies);
  };

  for (const key of Object.keys(depMap)) {
    const id = Number(key);
    result[id] = resolveDependencies(id);
  }

  return Object.fromEntries(Object.entries(result).map(([id, deps]) => {
    return [id, deps.filter(dep => dep !== Number(id)).sort((a, b) => a - b)];
  }));
};

const applyDependencies = (v4MapInfosWithoutDeps: ReadonlyArray<V4MapInfo>, dependencyMap: Readonly<Record<number, readonly number []>>) => {
  const emptyIdArray: readonly number[] = [];
  const mapInfoMap = v4MapInfosWithoutDeps.reduce((mapInfoMap, mapInfo) => {
    mapInfoMap[mapInfo.id] = { ...mapInfo } as Writable<V4MapInfo>;

    return mapInfoMap;
  }, {} as Record<number, Writable<V4MapInfo>>);

  return Object.values(mapInfoMap).map(mapInfo => {
    const dependencyIds = dependencyMap[mapInfo.id] ?? emptyIdArray;
    mapInfo.dependencies = dependencyIds.map(mapId => mapInfoMap[mapId]).filter(notNullsy);

    return mapInfo;
  });
};

const getDependencyMap = (v4MapInfosWithoutDeps: ReadonlyArray<V4MapInfo>) => {
  const dependencyMap = v4MapInfosWithoutDeps.reduce((depMap, v4mapInfo) => {
    if (v4mapInfo.mapType === 'LAYERED') {
      depMap[v4mapInfo.id] = v4mapInfo.baseMapIds;
      v4mapInfo.baseMapIds.forEach(baseMapId => {
        depMap[baseMapId] = [...(depMap[baseMapId] ?? []), v4mapInfo.id];
      });
    }

    return depMap;
  }, {} as Record<number, ReadonlyArray<number>>);

  return getTransitiveDependencies(dependencyMap);
};

export const mapMigrationDTOToDomainModel = (serverModel: V4MapListSuccessResponse): MapMigrationDTOToDomainModelResult => {
  const { queue } = serverModel;

  const v4MapInfosWithoutDeps = serverModel.data.map(convertV4MapInfoFromServerModelToDomainModel);
  const dependencyMap = getDependencyMap(v4MapInfosWithoutDeps);
  const v4MapInfos = applyDependencies(v4MapInfosWithoutDeps, dependencyMap);

  return {
    v4MapInfos,
    migrationStatusInfo: queue ? getMigrationStatusInfoFromQueueInfo(queue, v4MapInfos) : undefined,
  };
};
