import { type LatLng } from '../../../_shared/types/latLng';
import {
  type Proximity, toProximityModel,
} from '../../../_shared/types/proximity/proximity.types';
import { type MetricModel } from '../../../_shared/utils/metric/metrics.factory';
import {
  MAP_SETTINGS_PROXIMITY_ADD_METRICS,
  MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS,
  MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY,
  MAP_SETTINGS_PROXIMITY_SET_HIDE_LABELS,
  MAP_SETTINGS_PROXIMITY_SET_HIDE_SMALL_RADII,
  MAP_SETTINGS_PROXIMITY_SET_METRICS,
} from './mapSettingsProximity.actionTypes';

export function addProximity(proximity: Proximity, zoomAfter?: boolean) {
  return {
    type: MAP_SETTINGS_PROXIMITY_ADD_PROXIMITY,
    payload: {
      proximity: toProximityModel(proximity),
      zoomAfter,
    },
  } as const;
}

export function removeProximity(proximity: Proximity, mapId: number) {
  return {
    type: MAP_SETTINGS_PROXIMITY_REMOVE_PROXIMITY,
    payload: {
      proximity: toProximityModel(proximity),
      mapId,
    },
  } as const;
}

export function setHideLabels(hideLabels: boolean) {
  return {
    type: MAP_SETTINGS_PROXIMITY_SET_HIDE_LABELS,
    payload: {
      hideLabels,
    },
  } as const;
}

export function setHideSmallRadii(hideSmallRadii: boolean) {
  return {
    type: MAP_SETTINGS_PROXIMITY_SET_HIDE_SMALL_RADII,
    payload: {
      hideSmallRadii,
    },
  } as const;
}

export function setProximityMetrics(metrics: MetricModel[]) {
  return {
    type: MAP_SETTINGS_PROXIMITY_SET_METRICS,
    payload: {
      metrics,
    },
  } as const;
}

export function addProximityMetrics(metrics: MetricModel[]) {
  return {
    type: MAP_SETTINGS_PROXIMITY_ADD_METRICS,
    payload: {
      metrics,
    },
  } as const;
}

export const modifyProximity = (proximity: Proximity) => ({
  type: MAP_SETTINGS_PROXIMITY_MODIFY_PROXIMITY,
  payload: {
    proximity: toProximityModel(proximity),
  },
}) as const;

export const moveIndividualProximity = (proximityId: string, newLatLng: LatLng) => ({
  type: MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS,
  payload: {
    proximityId,
    newLatLng,
  },
}) as const;
