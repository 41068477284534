import {
  css, type Interpolation,
} from '@emotion/react';
import styled from '@emotion/styled';
import {
  type ChangeEventHandler,
  type FC, type ReactNode,
} from 'react';
import type { Theme } from '~/_shared/themes/theme.model';
import { TriStateRange } from '../../constants/triStateRange.enum';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';

const inputStyle = css({
  display: 'none',
});

const FullCheckmarkStyles = {
  left: '50%',
  top: '50%',
  width: '20%',
  height: '55%',
  transition: 'opacity .2s',
  borderWidth: '0 2px 2px 0',
  transform: 'translate(-50%, -50%) rotate(45deg)',
} as const;

const PartialCheckmarkStyles = {
  left: '20%',
  top: '50%',
  width: '50%',
  height: '0%',
  borderWidth: '1px',
} as const;

const Checkmark = styled.span<ThemeProps<{ checkmark: TriStateRange }>>(({ theme, checkmark }) => {
  const isChecked = checkmark !== TriStateRange.None;
  const color = isChecked ? theme.checkboxColors.checkedFill : theme.checkboxColors.uncheckedBorderTertiary;
  const backgroundColor = isChecked ? theme.checkboxColors.checkedFill : theme.checkboxColors.uncheckedFill;

  const checkmarkStyles = checkmark === TriStateRange.Full ? FullCheckmarkStyles
    : checkmark === TriStateRange.Partial ? PartialCheckmarkStyles
      : {};

  return {
    position: 'relative',
    height: '16px',
    width: '16px',
    display: 'block',
    boxSizing: 'border-box',
    backgroundColor,
    border: '1px solid ' + color,
    borderRadius: '4px',
    transition: 'background .2s',
    flexShrink: 0,
    '&:after': {
      content: '""',
      marginTop: '-8%',
      position: 'absolute',
      opacity: isChecked ? 1 : 0,
      border: 'solid white',
      ...checkmarkStyles,
    },
  };
});

const labelStyle = ({ theme, checkmark, isDisabled }: ThemeProps<{ checkmark: TriStateRange; isDisabled?: boolean }>) => {
  let checkmarkBackground = undefined;

  if (!isDisabled) {
    checkmarkBackground = checkmark !== TriStateRange.None
      ? theme.checkboxColors.checkedFill : theme.checkboxColors.checkedFillHover;
  }

  return css({
    position: 'relative',
    cursor: 'default',
    display: 'flex',
    fontSize: '13px',
    lineHeight: '21px',
    userSelect: 'none',
    alignItems: 'center',
    opacity: isDisabled ? theme.opacity.disabled : undefined,
    '&:hover': {
      [`${Checkmark}`]: {
        backgroundColor: checkmarkBackground,
      },
    },
  });
};

const textStyles = css({
  marginLeft: 15,
});

export type TriStateCheckboxComponentProps = Readonly<{
  id?: string;
  checkmark: TriStateRange;
  text?: ReactNode;
  className?: string;
  checkmarkStyle?: Interpolation<Theme>;
  textStyle?: Interpolation<Theme>;
  name?: string;
  isDisabled?: boolean;

  checkedSetter: ChangeEventHandler<HTMLInputElement>;
}>;

export const TriStateCheckboxComponent: FC<TriStateCheckboxComponentProps> = (props) => {
  const theme = useTheme();

  return (
    <label
      className={props.className}
      css={labelStyle({ checkmark: props.checkmark, isDisabled: props.isDisabled, theme })}
    >
      <input
        id={props.id}
        type="checkbox"
        checked={props.checkmark !== TriStateRange.None}
        onChange={props.checkedSetter}
        css={inputStyle}
        disabled={props.isDisabled}
        name={props.name}
      />

      <Checkmark
        checkmark={props.checkmark}
        theme={theme}
        css={props.checkmarkStyle}
      />

      {props.text &&
        <span css={[textStyles, props.textStyle]}>{props.text}</span>
      }
    </label>
  );
};
