import { css } from '@emotion/react';
import {
  type CSSProperties,
  forwardRef,
  type ReactNode,
} from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { noop } from '../../utils/function.helpers';
import { useClickOutsideListenerRef } from '../../utils/hooks/useClickOutsideListenerRef';
import { useCombinedRefs } from '../../utils/hooks/useCombinedRefs';
import { contextMenuStyle } from '../dropdown';

const Z_INDEX = 1300;
const menuPositionStyle = css({
  position: 'absolute',
  zIndex: Z_INDEX,
});

const menuStyle = ({ maxWidth }: {maxWidth?: number}) => css({
  boxSizing: 'border-box',
  position: 'relative',
  width: 'auto',
  maxWidth,
  zIndex: Z_INDEX,
});

type ContextMenuContentProps = Readonly<{
  children: ReactNode;
  className?: string;
  isVisible: boolean;
  style?: CSSProperties;
  maxWidth?: number;

  onHide?: () => void;
}>;

export const ContextMenuContentComponent = forwardRef<any, ContextMenuContentProps>((props, ref) => {
  const theme = useTheme();
  const innerRef = useClickOutsideListenerRef(props.onHide ?? noop, props.isVisible);
  const combinedRef = useCombinedRefs(ref, innerRef);

  if (!props.isVisible) {
    return null;
  }

  return (
    <div
      css={menuPositionStyle}
      ref={combinedRef}
      style={props.style}
    >
      <div
        css={[menuStyle({ maxWidth: props.maxWidth }), contextMenuStyle({ theme })]}
        className={props.className}
      >
        {props.children}
      </div>
    </div>
  );
});
