import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  type ApiError, isNotFoundApiError,
} from '~/_shared/utils/api/apiError.helpers';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { parseUrl } from '~/_shared/utils/url/url.helpers';
import {
  getMapData, getMapPresentationalData, incrementMapView, type MapInfoResponse, type MapPresentationalResponse, updateMapData,
} from '~/map/map.repository';
import { errorPageSetError } from '../errorPage/errorPage.actionCreators';
import { ErrorPageType } from '../errorPage/errorPage.state';
import { type MapIdAction } from '../mapId/mapId.action';
import { mapIdSet } from '../mapId/mapId.actionCreators';
import { MAP_ID_SET } from '../mapId/mapId.actionTypes';
import {
  userSetClientId, userSetClientLimitWmsEnabled,
} from '../userData/userData.actionCreators';
import { type MapInfoAction } from './mapInfo.action';
import {
  mapInfoFetchDataError, mapInfoFetchDataRequest, mapInfoFetchSuccess, mapInfoPresentationalFetchDataError,
  mapInfoPresentationalFetchSuccess, mapInfoUpdateError, mapInfoUpdateSuccess,
} from './mapInfo.actionCreators';
import {
  MAP_INFO_FETCH_DATA_REQUEST, MAP_INFO_FETCH_DATA_SUCCESS, MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST,
  MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS, MAP_INFO_UPDATE_REQUEST,
} from './mapInfo.actionTypes';
import { createMapInfoFromMapInfoResponse } from './mapInfo.factory';

export function* mapInfoSagas() {
  yield all([
    mapUpdateSagas(),
    mapDataFetchSagas(),
    mapPresentationalFetchSagas(),
  ]);
}

function* mapUpdateSagas() {
  yield takeLatest(MAP_INFO_UPDATE_REQUEST, onMapUpdateRequest);
}

function* mapDataFetchSagas() {
  yield takeLatest(MAP_INFO_FETCH_DATA_REQUEST, onMapDataRequest);
  yield takeLatest(MAP_INFO_FETCH_DATA_SUCCESS, onMapDataLoadSuccess);
  yield takeLatest(MAP_ID_SET, getMapInfoData);
}

function* mapPresentationalFetchSagas() {
  yield takeLatest(MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST, onMapPresentationalDataRequest);
  yield takeLatest(MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS, onMapPresentationalFetchSuccess);
}

function* onMapPresentationalDataRequest(action: PickAction<MapInfoAction, typeof MAP_INFO_PRESENTATIONAL_FETCH_DATA_REQUEST>) {
  const queryParameters = parseUrl(window.location.href).queryParameters;
  const tokenParameter = queryParameters?.['token'];

  try {
    const mapDataResponse: { data: MapPresentationalResponse } = yield call(getMapPresentationalData, action.payload.shareMapId, action.payload.password, tokenParameter);
    yield put(mapInfoPresentationalFetchSuccess(mapDataResponse.data));
  }
  catch (e) {
    yield put(mapInfoPresentationalFetchDataError(e));
  }
}

function* onMapPresentationalFetchSuccess(action: PickAction<MapInfoAction, typeof MAP_INFO_PRESENTATIONAL_FETCH_DATA_SUCCESS>) {
  // client ID needs to be first before map ID so that the remaining requests in chain work fine
  yield put(userSetClientId(action.payload.data.client.id));
  yield put(mapIdSet(action.payload.data.id));
  yield put(userSetClientLimitWmsEnabled(action.payload.data.client.wms_enabled));
  // trigger map info success to run chain of requests
  yield put(mapInfoFetchSuccess(createMapInfoFromMapInfoResponse(action.payload.data)));
}

function* getMapInfoData(action: PickAction<MapIdAction, typeof MAP_ID_SET>) {
  const clientId: number | null = yield select(state => state.userData.clientId);
  const isPresentational: boolean = yield select(state => state.map.mapInfo.isPresentational);

  // if isPresentational then it means that map info was already fetched using map info shared endpoint
  if (action.payload.mapId === null || isPresentational) {
    return;
  }

  if (clientId === null) {
    yield put(errorPageSetError(ErrorPageType.General));
    yield put(mapInfoFetchDataError());
    return;
  }

  yield put(mapInfoFetchDataRequest(clientId, action.payload.mapId));
}

function* onMapDataRequest(action: PickAction<MapInfoAction, typeof MAP_INFO_FETCH_DATA_REQUEST>) {
  try {
    const mapDataResponse: { data: MapInfoResponse } = yield call(getMapData, action.payload.clientId, action.payload.mapId);
    yield put(mapInfoFetchSuccess(createMapInfoFromMapInfoResponse(mapDataResponse.data)));
  }
  catch (e) {
    const error = isNotFoundApiError(e) ? ErrorPageType.MapNotFound : ErrorPageType.General;
    yield put(errorPageSetError(error));
    yield put(mapInfoFetchDataError());
  }
}

function* onMapDataLoadSuccess() {
  const clientId: number | null = yield select(state => state.userData.clientId);
  const mapId: number | null = yield select(state => state.map.mapInfo.data?.id ?? null);

  if (mapId === null || clientId === null) {
    return;
  }

  incrementMapView(clientId, mapId);
}

function* onMapUpdateRequest(action: PickAction<MapInfoAction, typeof MAP_INFO_UPDATE_REQUEST>) {
  try {
    yield call(updateMapData, action.payload.clientId, action.payload.mapId, action.payload.request);
    yield put(mapInfoUpdateSuccess(action.payload.request));
  }
  catch (e) {
    const apiError = e as ApiError;
    yield put(mapInfoUpdateError(apiError.errors));
  }
}
