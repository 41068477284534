import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../data/mapSettingsData.actionTypes';

export const MAP_SETTINGS_MARKERS_TOGGLE = 'MAP_SETTINGS_MARKERS/TOGGLE';
export const MAP_SETTINGS_MARKERS_PANEL_SETTINGS_SAVE = 'MAP_SETTINGS_MARKERS/PANEL_SETTINGS_SAVE';
export const MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES = 'MAP_SETTINGS_MARKERS/UPDATE_MARKER_IMAGES';
export const MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES = 'MAP_SETTINGS_MARKERS/REMOVE_MARKER_IMAGES';
export const MAP_SETTINGS_MARKERS_REMOVE_FILE_ATTACHMENTS = 'MAP_SETTINGS_MARKERS/REMOVE_FILE_ATTACHMENTS';
export const MAP_SETTINGS_ACTIVE_MARKER_INDICATOR_UPDATE = 'MAP_SETTINGS_MARKERS/ACTIVE_MARKER_INDICATOR_UPDATE';
export const MAP_SETTINGS_MARKERS_UPDATE_LABEL_MARKER_OFFSETS = 'MAP_SETTINGS_MARKERS/UPDATE_LABEL_MARKER_OFFSETS';
export const MAP_SETTINGS_MARKERS_REMOVE_LABEL_MARKER_OFFSETS = 'MAP_SETTINGS_MARKERS/REMOVE_LABEL_MARKER_OFFSETS';
export const MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW = 'MAP_SETTINGS_MARKERS/UPDATE_UNCLUSTER_BELOW';
export const MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS = 'MAP_SETTINGS_MARKERS/SET_UNSTACK_MARKERS';

// Consider adding an action to the list if it can update / remove file attachment from marker images.
export const MAP_SETTINGS_MARKERS_MARKER_IMAGES_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS = [
  MAP_SETTINGS_FETCH_DATA_SUCCESS,
  MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES,
  MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES,
];
