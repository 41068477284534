import {
  isCustomMarkerStyle, type MarkerStyle,
} from '~/_shared/types/marker.types';
import { type WebglLayers } from '~/map/map/webgl/useWebGL';
import {
  getTemplateLayersCode,
  type MarkerTemplate, predefinedTemplates, type TemplateLayers,
} from './mapMarkerTemplates';

type EnsureTemplateLoadedListenerType = (args: MarkerCancellableEventArgs) => void;

class MarkerTemplateManagerClass {
  private loaded: Map<string, Promise<void>> = new Map();
  private templates: Map<string, MarkerTemplate> = new Map();
  private perLayerListeners: Map<MarkersLayer, EnsureTemplateLoadedListenerType[]> = new Map();

  constructor(templates?: MarkerTemplate[]) {
    templates?.forEach(template => this.registerTemplate(template));
  }

  public registerTemplate(template: MarkerTemplate) {
    this.templates.set(template.name, template);
  }

  public attachEnsureTemplateLoadedListenersToLayer = (layer: MarkersLayer) => {
    if (!this.perLayerListeners.has(layer)) {
      this.perLayerListeners.set(layer, []);
    }

    const newListener = (args: MarkerCancellableEventArgs) => this.ensureTemplateLoaded(layer, args.marker);
    this.perLayerListeners.get(layer)?.push(newListener);

    layer.addEventListener('BeforeItemAdd', newListener);
    layer.addEventListener('BeforeItemUpdate', newListener);
  };

  private ensureTemplateLoaded = (layer: MarkersLayer, marker: WebglOverlayMarker) => {
    const template = this.templates.get(marker.template);

    const templatePromise = template
      ? this.ensureTemplatePromise(layer, template)
      : Promise.reject(`Template ${marker.template} was not found.`);

    templatePromise.catch((e) => {
      throw new Error(`Cannot load template ${marker.template}: ${e}`);
    });
  };

  private ensureTemplatePromise = (layer: MarkersLayer, template: MarkerTemplate) => {
    if (!this.loaded.has(template.name)) {

      this.loaded.set(template.name, this.addMarkerTemplate(layer, template));
    }

    return Promise.resolve(this.loaded.get(template.name));
  };

  private addMarkerTemplate = (layer: MarkersLayer, template: MarkerTemplate): Promise<void> =>
    layer.addMarkerTemplate(template.name, template);

  public dispose = () => {
    this.perLayerListeners.forEach((listeners, layer) => {
      listeners.forEach((listener) => {
        layer.removeEventListener('BeforeItemAdd', listener);
        layer.removeEventListener('BeforeItemUpdate', listener);
      });
    });
    this.perLayerListeners = new Map();
  };
}

export type MarkerTemplateManager = MarkerTemplateManagerClass;

export const registerTemplateManager = (layers: WebglLayers) => {
  const manager = new MarkerTemplateManagerClass(predefinedTemplates);
  const markerLayers = Object.values(layers).filter(isMarkerLayer);

  markerLayers.forEach(markerLayer => {
    manager.attachEnsureTemplateLoadedListenersToLayer(markerLayer);
  });

  return manager;
};

export const createDynamicTemplateName = (markerStyle: MarkerStyle, markerLayers?: TemplateLayers) =>
  isCustomMarkerStyle(markerStyle) ? `custom-${markerStyle.marker.fileId}`
    : `standard-${markerStyle.marker.id}|${markerLayers ? getTemplateLayersCode(markerLayers) : 0}`;

const isMarkerLayer = (layer: MapLayer<any>): layer is MarkersLayer =>
  layer.hasOwnProperty('templates');
