import {
  type FC, type ReactElement, useEffect, useState,
} from 'react';

type ShowWithTimeoutProps = Readonly<{
  showDelay: number;
  isActive: boolean;
  children: ReactElement;
}>;

export const ShowWithDelayComponent: FC<ShowWithTimeoutProps> = (props) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    let timeoutId: number | null = null;

    if (!showChildren && props.isActive) {
      timeoutId = window.setTimeout(() => {
        setShowChildren(true);
      }, props.showDelay);
    }

    if (!props.isActive) {
      setShowChildren(false);
    }

    return () => {
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [props.isActive, props.showDelay, showChildren]);

  return showChildren ? props.children : null;
};
