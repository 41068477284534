import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { MatchUpDataSection } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import { useStreetViewPanoramaLocation } from '~/_shared/components/streetViewPanorama/useStreetViewPanoramaLocation';
import { type LatLng } from '~/_shared/types/latLng';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  ERASURE_KEYS, KeyboardKeys, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useAddressInfoByRowIds } from '~/_shared/utils/spreadsheet/useAddressInfoByRowIds.hook';
import { useSpreadSheetData } from '~/map/map/useSpreadsheetData.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { RightSidebarOpeneableApps } from '~/sidebar/sidebarApps/rightSidebar/rightSidebarConfiguration';
import { useSpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import {
  activeMapElementsSetActiveMarkerNavigationIndex, deactivateActiveMarker,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import {
  useActiveMarkerIdSelector, useActiveMarkerIdsSelector, useActiveMarkerNavigationIndexSelector,
} from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { frontendStateStreetViewSet } from '~/store/frontendState/mapTools/streetView/streetView.actionCreators';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { useMapMarkerWaypointDataSelector } from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { usePublicMapSettingsIsStreetViewEnabledSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { getLatLngFromSpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.helpers';
import { CustomizeLocationPanelComponent } from './customizeLocationPanel.component';
import { type MenuButtons } from './customizeLocationPanel.enums';
import { useLocationDescriptionData } from './locationData/useLocationDescriptionManagement.hook';
import { usePanelSettingsManagement } from './locationData/usePanelSettingsManagement.hook';
import { useEditLocationCallbacks } from './roundButtonMenu/contextMenuStructure/useEditLocationCallbacks';
import { useRemoveLocationCallbacks } from './roundButtonMenu/contextMenuStructure/useRemoveLocationCallbacks';
import { useToolLocationCallbacks } from './roundButtonMenu/contextMenuStructure/useToolLocationCallbacks';
import { useLocationImages } from './useLocationImages.hook';

type CustomizeLocationPanelContainerProps = Readonly<{
  onClose: () => void;
  openOrCloseApp: (app: RightSidebarOpeneableApps, isOpened: boolean) => void;
}>;

export const CustomizeLocationPanelContainer: FC<CustomizeLocationPanelContainerProps> = ({
  openOrCloseApp,
}) => {
  const dispatch = useDispatch();
  const [isStreetViewActive, setIsStreetViewActive] = useState(false);
  const [location, setLocation] = useState<LatLng | null>(null);
  const [selectedMenuButton, setSelectedMenuButton] = useState<MenuButtons | null>(null);

  const spreadsheetData = useSpreadSheetData().spreadsheetData;
  const matchupData = useMatchupDataSelector();
  const locationOrder = useActiveMarkerIdsSelector();
  const activeMarkerId = useActiveMarkerIdSelector();
  const waypointInfo = useMapMarkerWaypointDataSelector(activeMarkerId);
  const activeMarkerNavigationIndex = useActiveMarkerNavigationIndexSelector();
  const isViewPresentational = useIsMapPresentationalSelector();
  const isStreetViewEnabled = usePublicMapSettingsIsStreetViewEnabledSelector();
  const activeGroupingColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const { oneRow: spreadsheetRowData, spreadsheetCellData } = useSpreadsheetRowData(activeMarkerId);
  const locationDescription = useLocationDescriptionData({
    spreadsheetRowId: activeMarkerId,
    spreadsheetRowData: spreadsheetRowData?.rowData,
  });
  const { images, openImagesView } = useLocationImages(activeMarkerId, spreadsheetRowData.rowData);
  const isMobileScreen = useIsMobileScreenSelector();
  const removeLocationCallbacks = useRemoveLocationCallbacks();
  const { openModal: openChartsDetailsModal } = useModal(ModalType.ChartsDetails);
  const { openModal: openPendingChangesModal } = useModal(ModalType.ChangesPending);
  const { openModal: openMatchupDataModal } = useModal(ModalType.MatchupData);
  const { streetViewLocation, isLoading: streetViewLocationLoading } = useStreetViewPanoramaLocation(location);
  const [t] = useTranslation();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();

  const closeActiveMarker = useCallback(() => {
    dispatch(deactivateActiveMarker());
  }, [dispatch]);

  const addressInfoByRowIdsParams = useMemo(() => ({
    spreadsheetRowIds: activeMarkerId ? [activeMarkerId] : [],
    spreadsheetCellData,
  }), [activeMarkerId, spreadsheetCellData]);

  const addressInfoByRowId = useAddressInfoByRowIds(addressInfoByRowIdsParams);

  const locationAddress = activeMarkerId
    ? addressInfoByRowId.get(activeMarkerId.spreadsheetId)?.get(activeMarkerId.rowId)?.addressFallbackToLatLng
    : null;

  const toolLocationCallbacks = useToolLocationCallbacks(locationAddress || null);

  useEffect(() => {
    if (!spreadsheetRowData.isLoading && !spreadsheetRowData.rowData) {
      closeActiveMarker();
    }
  });

  const panelSettingsManagement = usePanelSettingsManagement({
    spreadsheetRowData: spreadsheetRowData.rowData,
    spreadsheetRowId: activeMarkerId,
  });

  const editLocationCallbacks = useEditLocationCallbacks({
    areBubbleItemsIncluded: !!(activeMarkerId?.spreadsheetId && panelSettingsManagement?.bubbleRecords?.items?.[activeMarkerId.spreadsheetId]),
    selectOpenableApp: openOrCloseApp,
    disableEditMarker: !!waypointInfo,
    disabledEditMarkerTooltip: t('Route waypoints cannot be customized.'),
  });

  const openStreetView = useCallback(() => {
    if (streetViewLocation) {
      dispatch(frontendStateStreetViewSet(streetViewLocation));
    }
  }, [dispatch, streetViewLocation]);

  const changeMarkerNavigationIndex = useCallback((index: number) =>
    dispatch(activeMapElementsSetActiveMarkerNavigationIndex(index)),
  [dispatch]);

  const openStackedMarkersDetails = useMemo(() => {
    if (activeGroupingColumns.length && locationOrder && locationOrder.length > 1) {
      return () => openChartsDetailsModal({
        modalCaption: t('Stacked marker metrics'),
        spreadsheetRowIds: locationOrder,
      });
    }

    return undefined;
  }, [activeGroupingColumns.length, locationOrder, openChartsDetailsModal, t]);

  const headerProps = useMemo(() => ({
    totalLocations: locationOrder?.length ?? 0,
    openLocationDetails: openStackedMarkersDetails,
    selectLocation: changeMarkerNavigationIndex,
    selectedLocationIndex: activeMarkerNavigationIndex,
  }), [activeMarkerNavigationIndex, changeMarkerNavigationIndex, locationOrder?.length, openStackedMarkersDetails]);

  const getCloseEditModeWithCallback = useCallback((callback?: () => void) => () => {
    setSelectedMenuButton(null);
    openOrCloseApp(RightSidebarOpeneableApps.EditPanel, false);
    callback?.();
  }, [openOrCloseApp]);

  const attemptExitEditMode = useCallback((callback?: () => void) => {
    const resetToLastSaved = panelSettingsManagement.resetToLastSaved;
    const saveSettings = panelSettingsManagement.saveSettings;

    if (panelSettingsManagement.areChangesPending) {
      openPendingChangesModal({
        onDiscardChanges: getCloseEditModeWithCallback(() => {
          resetToLastSaved();
          callback?.();
        }),
        onSaveChanges: getCloseEditModeWithCallback(() => {
          saveSettings();
          callback?.();
        }),
      });
    }
    else {
      getCloseEditModeWithCallback(callback)();
    }
  }, [getCloseEditModeWithCallback, openPendingChangesModal, panelSettingsManagement.areChangesPending,
    panelSettingsManagement.resetToLastSaved, panelSettingsManagement.saveSettings]);

  const editMatchupModal = useCallback(() => {
    openMatchupDataModal({
      sections: [MatchUpDataSection.InfoPopup],
      hideHeading: true,
      ensureLocationDataIsSelected: false,
    });
  }, [openMatchupDataModal]);

  const handleCloseSidebar = useCallback((): void =>
    attemptExitEditMode(closeActiveMarker),
  [closeActiveMarker, attemptExitEditMode]);

  const handleExitEditMode = useCallback(() =>
    attemptExitEditMode(),
  [attemptExitEditMode]);

  const onSaveSettings = useMemo(() => (
    getCloseEditModeWithCallback(panelSettingsManagement.saveSettings)
  ), [getCloseEditModeWithCallback, panelSettingsManagement.saveSettings]);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: onSaveSettings },
    options: { allowInputs: true },
    targetKeys: KeyboardKeys.Enter,
  }), [onSaveSettings]));

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: removeLocationCallbacks.removeMarkerCallback },
    targetKeys: ERASURE_KEYS,
  }), [removeLocationCallbacks.removeMarkerCallback]));

  useEffect(() => {
    if (!activeMarkerId || !spreadsheetData || !matchupData || (isViewPresentational && !isStreetViewEnabled)) {
      setIsStreetViewActive(false);
      return;
    }

    const locationDetails = getLatLngFromSpreadsheetData(activeMarkerId.spreadsheetId, spreadsheetData, matchupData)
      .find(d => d.rowId === activeMarkerId.rowId);
    if (!locationDetails || !locationDetails.lat || !locationDetails.lng) {
      setIsStreetViewActive(false);
      return;
    }

    setLocation({
      lat: locationDetails.lat,
      lng: locationDetails.lng,
    });
    setIsStreetViewActive(true);
  }, [spreadsheetData, matchupData, activeMarkerId, isViewPresentational, isStreetViewEnabled]);

  return (
    <CustomizeLocationPanelComponent
      editLocationCallbacksProps={editLocationCallbacks}
      headerProps={headerProps}
      images={images}
      onEditClick={isViewPresentational ? undefined : editMatchupModal}
      isEditingDisabled={isViewPresentational}
      isLayeredMapConnected={isLayeredMapConnected}
      isLoading={spreadsheetRowData.isLoading || removeLocationCallbacks.isLoading}
      isMobileVersion={isMobileScreen}
      isStreetViewActive={isStreetViewActive}
      itemManagementProps={panelSettingsManagement}
      locationDescription={locationDescription}
      onClose={handleCloseSidebar}
      onExitEditMode={handleExitEditMode}
      onImageGalleryItemClicked={openImagesView}
      onSaveSettings={onSaveSettings}
      onSelectMenuButton={setSelectedMenuButton}
      openStreetView={openStreetView}
      removeMarkerCallbacks={removeLocationCallbacks}
      selectedMenuButton={selectedMenuButton}
      streetViewLocation={streetViewLocation}
      streetViewLocationLoading={streetViewLocationLoading}
      toolLocationCallbacks={toolLocationCallbacks}
    />
  );
};
