import {
  buffers, type TakeableChannel,
} from 'redux-saga';
import {
  actionChannel,
  select,
  take,
} from 'redux-saga/effects';
import { MAP_SETTINGS_READY_SET } from '~/store/mapSettings/ready/mapSettingsReady.actionTypes';
import { areMapSettingsReadySelector } from './mapSettingsReady.selectors';

export function* waitForMapSettingsReady() {
  const mapSettingsReadyChannel: TakeableChannel<Action> = yield actionChannel([MAP_SETTINGS_READY_SET], buffers.sliding(1));
  let isMapSettingsReady: boolean = yield select(areMapSettingsReadySelector);

  while (!isMapSettingsReady) {
    yield take(mapSettingsReadyChannel);
    isMapSettingsReady = yield select(areMapSettingsReadySelector);
  }
}
