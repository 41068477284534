import {
  type FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { useMapObjectContext } from '../private/mapObjectContext';
import { type MapCircleInstance } from './mapCircleModel';

export type CircleAreaVisualsConfig = {
  readonly borderColor: WebglColor;
  readonly borderWidth: number;
  readonly fillColor: WebglColor;
  readonly units?: CircleAreaUnit;
  readonly staticSize?: boolean;
  readonly autoScale?: boolean;
  readonly sizeOnLevel?: number;
};

export type MapCircleAreaVisualsConfig = {
  readonly circleArea: CircleAreaVisualsConfig;
};

type MapCircleProps = {
  circle: MapCircleInstance;
  visuals: MapCircleAreaVisualsConfig;
  onDragMove?: (circleId: Uuid, latLng: LatLng) => void;
  onDragStart?: (circleId: Uuid, latLng: LatLng) => void;
  onDragEnd?: (circleId: Uuid) => void;
  onMouseOver?: (circleId: Uuid) => void;
  onMouseOut?: (circleId: Uuid) => void;
  onClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
  onRightClick?: (circleId: Uuid, event: MapObjectClickEventArgs) => void;
};

const MapCircleComponent: FC<MapCircleProps> = (props) => {
  const { manager, zIndex } = useMapObjectContext();
  const { circle, visuals } = props;
  const { onDragMove, onDragStart, onDragEnd, onMouseOver, onMouseOut, onClick, onRightClick } = props;

  const [circleArea, setCircleArea] = useState<WebglOverlayCircleArea>();

  const circleOnDragMove = useMemo(() => onDragMove ? (latLng: LatLng) => onDragMove(circle.id, latLng) : undefined, [circle.id, onDragMove]);
  const circleOnDragStart = useMemo(() => onDragStart ? (latLng: LatLng) => onDragStart(circle.id, latLng) : undefined, [circle.id, onDragStart]);
  const circleOnDragEnd = useMemo(() => onDragEnd ? () => onDragEnd(circle.id) : undefined, [circle.id, onDragEnd]);
  const circleOnMouseOver = useMemo(() => onMouseOver ? () => onMouseOver(circle.id) : undefined, [onMouseOver, circle.id]);
  const circleOnMouseOut = useMemo(() => onMouseOut ? () => onMouseOut(circle.id) : undefined, [onMouseOut, circle.id]);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: circleArea,
    onDragMove: circleOnDragMove,
    onDragStart: circleOnDragStart,
    onDragEnd: circleOnDragEnd,
    onMouseOver: circleOnMouseOver,
    onMouseOut: circleOnMouseOut,
  }), [manager.map, circleArea, circleOnDragEnd, circleOnDragMove, circleOnDragStart, circleOnMouseOut, circleOnMouseOver]);

  // draw circle area
  useEffect(() => {
    const updatedCircleArea = manager.upsertCircleArea(circle, {
      ...visuals.circleArea,
      zIndex: zIndex.circleArea,
      staticSize: visuals.circleArea.staticSize ?? false,
    });

    if (circleArea !== updatedCircleArea) {
      setCircleArea(updatedCircleArea);
    }
  }, [manager, circle, zIndex.circleArea, visuals, circleArea]);

  // web-gl cleanup
  useEffect(() => {
    return () => {
      manager.removeCircleArea(circle.id);
    };
  }, [manager, circle.id]);

  // registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addCircleAreaEventListener(circle.id, 'click',
      (e) => {
        onClick?.(circle.id, e);
      });

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, circle.id]);

  // registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseRightClickCleanup = manager.addCircleAreaEventListener(circle.id, 'rightclick',
      (e) => {
        onRightClick?.(circle.id, e);
      });

    return () => {
      mouseRightClickCleanup();
    };
  }, [manager, onRightClick, circle.id]);

  return null;
};

const pureComponent = memo(MapCircleComponent);
export { pureComponent as MapCircleComponent };
