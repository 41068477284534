import { type LabelVisualizerOffsetProps } from '~/_shared/components/labelVisualizer/labelVisualizer.types';
import { type FileAttachmentId } from '~/_shared/types/file.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { type UnclusterBelowLevel } from '~/_shared/utils/markers/markers.constants';
import { type MapMarkersGraphicsToggleSettingsName } from '~/map/settings/accordion/mapMarkersGraphics/mapMarkersGraphics.enums';
import {
  MAP_SETTINGS_ACTIVE_MARKER_INDICATOR_UPDATE,
  MAP_SETTINGS_MARKERS_PANEL_SETTINGS_SAVE,
  MAP_SETTINGS_MARKERS_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_MARKERS_REMOVE_LABEL_MARKER_OFFSETS,
  MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES,
  MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS, MAP_SETTINGS_MARKERS_TOGGLE,
  MAP_SETTINGS_MARKERS_UPDATE_LABEL_MARKER_OFFSETS,
  MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES,
  MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW,
} from './mapSettingsMarkers.actionTypes';
import {
  type ActiveMarkerIndicatorSettings,
  type MapSettingsMarkersState, type PanelSettingsState,
} from './mapSettingsMarkers.state';

export const mapSettingsMarkersToggle = (property: keyof Omit<MapSettingsMarkersState, MapMarkersGraphicsToggleSettingsName.unstackMarkers>) => ({
  type: MAP_SETTINGS_MARKERS_TOGGLE,
  payload: {
    property,
  },
}) as const;

export const mapSettingsMarkersSavePanelSettings = (panelSettings: PanelSettingsState) => ({
  type: MAP_SETTINGS_MARKERS_PANEL_SETTINGS_SAVE,
  payload: {
    panelSettings,
  },
}) as const;

export const mapSettingsMarkersUpdateLabelMarkerOffsets = (labelOffsets: ReadonlyArray<{marker: SpreadsheetRowId; offset: LabelVisualizerOffsetProps}>) => ({
  type: MAP_SETTINGS_MARKERS_UPDATE_LABEL_MARKER_OFFSETS,
  payload: {
    labelOffsets,
  },
}) as const;

export const mapSettingsMarkersRemoveLabelMarkerOffsets = (markerIds: ReadonlyArray<SpreadsheetRowId>) => ({
  type: MAP_SETTINGS_MARKERS_REMOVE_LABEL_MARKER_OFFSETS,
  payload: {
    markerIds,
  },
}) as const;

export const mapSettingsMarkersUpdateMarkerImages = (activeMaker: SpreadsheetRowId, fileAttachmentIds: string[]) => ({
  type: MAP_SETTINGS_MARKERS_UPDATE_MARKER_IMAGES,
  payload: {
    activeMaker,
    fileAttachmentIds,
  },
}) as const;

export const mapSettingsMarkersRemoveMarkerImages = (markerIds: ReadonlyArray<SpreadsheetRowId>) => ({
  type: MAP_SETTINGS_MARKERS_REMOVE_MARKER_IMAGES,
  payload: {
    markerIds,
  },
}) as const;

export const mapSettingsMarkersImagesRemoveFileAttachments = (attachmentsIds: ReadonlyArray<FileAttachmentId>) => ({
  type: MAP_SETTINGS_MARKERS_REMOVE_FILE_ATTACHMENTS,
  payload: {
    attachmentsIds,
  },
}) as const;

export const mapSettingsMarkersUpdateActiveMarkerIndicator = (settings: Partial<ActiveMarkerIndicatorSettings>) => ({
  type: MAP_SETTINGS_ACTIVE_MARKER_INDICATOR_UPDATE,
  payload: {
    settings,
  },
}) as const;

export const mapSettingsMarkersUpdateUnclusterBelow = (unclusterBelowLevel: UnclusterBelowLevel | null) => ({
  type: MAP_SETTINGS_MARKERS_UPDATE_UNCLUSTER_BELOW,
  payload: {
    unclusterBelowLevel,
  },
}) as const;

export const mapSettingsMarkersSetUnstackMarkers = (newValue: boolean) => ({
  type: MAP_SETTINGS_MARKERS_SET_UNSTACK_MARKERS,
  payload: {
    newValue,
  },
}) as const;
