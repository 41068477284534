import { type FC } from 'react';
import {
  LottieAnimationComponent, type LottieAnimationProps,
} from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.component';
import { LottieAnimationTypes } from '~/_shared/baseComponents/lottieAnimation/lottieAnimation.types';
import { useLottieAnimationDefaultColors } from '~/_shared/baseComponents/lottieAnimation/useLottieAnimationDefaultColors';

type SuccessAnimationProps = Readonly<{
  size: number;
  className?: string;
}> & Pick<LottieAnimationProps, 'onAnimationEnd'>;

export const SuccessAnimationComponent: FC<SuccessAnimationProps> = (props) => {
  const colors = useLottieAnimationDefaultColors().Success;

  return (
    <LottieAnimationComponent
      className={props.className}
      size={props.size}
      type={LottieAnimationTypes.Success}
      autoplay
      loop={false}
      colors={colors}
      onAnimationEnd={props.onAnimationEnd}
    />
  );
};
