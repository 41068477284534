import {
  type FC, useCallback, useEffect, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  convertPxDistanceToDifferentZoom,
  ZOOM_LEVEL_FOR_SCALED_ITEMS,
} from '~/_shared/utils/distance/distance.helpers';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useDrawingImageSelectorNewInstanceFileId } from '~/store/frontendState/mapTools/drawing/drawingImageSelector/drawingImageSelector.selectors';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { drawingImageSettingsInitialState } from '~/store/mapSettings/drawing/settings/image/drawingImageSettings.reducer';
import { type DrawingImageSettingsState } from '~/store/mapSettings/drawing/settings/image/drawingImageSettings.state';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { type DrawingToolManager } from '../drawingToolManager';
import { useDrawingToolImages } from './useDrawingToolImages';

type DrawingToolNumberCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingImageSettingsState;
}>;

export const DrawingToolImageCreateNewInstanceContainer: FC<DrawingToolNumberCreateNewInstanceProps> = ({
  manager, settings,
}) => {
  const drawingToolMode = useDrawingToolModeSelector();
  const { activateDrawingImage } = useDrawingToolImages();
  const selectedImageFileId = useDrawingImageSelectorNewInstanceFileId();
  const dispatch = useDispatch();

  const mapZoom = useMapComponentZoomSelector();
  const mapZoomRef = useRef(mapZoom);

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (selectedImageFileId === null || !mapZoomRef.current || !event.latLng) {
      return;
    }

    const id = createUuid();
    const size = settings.scalesWithMapZoom ? convertPxDistanceToDifferentZoom({
      distance: settings.imageSize ?? drawingImageSettingsInitialState.imageSize, // ensure size is not undefined for old maps, can be removed after release
      newZoom: ZOOM_LEVEL_FOR_SCALED_ITEMS, originalZoom: mapZoomRef.current,
    }) : settings.imageSize;

    dispatch(drawingItemsAddItem(
      id,
      {
        type: DrawingTool.Image,
        value: {
          id,
          center: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
          attachmentId: activateDrawingImage(selectedImageFileId),
          placement: DrawingItemPlacement.Default,
          settings: {
            ...settings,
            imageSize: size,
          },
        },
      }));
  }, [activateDrawingImage, dispatch, settings, selectedImageFileId]);

  useEffect(() => {
    if (!onMapClick || drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);

    return () => {
      clickListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode]);

  return null;
};
