import { useMemo } from 'react';
import { useMapZoomChangeFinished } from '~/_shared/utils/hooks/useMapZoomChangeFinished';
import { useMap } from '~/map/map/mapContext';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import {
  MapOutlinePosition,
  type MapOutlinePositionInstance,
} from '../../../../_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { getArrowTailOffset } from './mapArrow.helpers';
import { type MapArrowInstance } from './mapArrowModel';

export const useMapArrowOutlines = (
  arrow: MapArrowInstance,
  options: {
    predicate: boolean;
    hideDuringZoomChangeAnimation?: boolean;
  },
) => {
  const map = useMap();
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);
  const mapZoom = useMapComponentZoomSelector();
  const { lastZoom } = useMapZoomChangeFinished(options.predicate);

  const tailInOffsetMode = arrow.staticLength > 0;

  return useMemo<MapOutlinePositionInstance[]>(() => {
    // Hide outlines when map is performing zoom animation in LatLng mode.
    if (tailInOffsetMode && options.hideDuringZoomChangeAnimation && mapZoom !== lastZoom) {
      return [];
    }

    const tipLatLng = arrow.tipLatLng;
    const tailLatLng = arrow.tailLatLng;

    const tipOutline = {
      id: `${arrow.id}|tip`,
      lat: tipLatLng.lat,
      lng: tipLatLng.lng,
      position: MapOutlinePosition.Top,
      offsetY: 0,
      offsetX: 0,
    };

    let tailOutline = {
      id: `${arrow.id}|tail`,
      lat: tailLatLng.lat,
      lng: tailLatLng.lng,
      position: MapOutlinePosition.Bottom,
      offsetY: 0,
      offsetX: 0,
    };

    if (tailInOffsetMode) {
      const tipPosition = fromLatLngToDivPixel(tipLatLng);
      const tailPosition = fromLatLngToDivPixel(tailLatLng);

      if (!tipPosition || !tailPosition) {
        return [];
      }

      const tailOffset = getArrowTailOffset(tipPosition, tailPosition, arrow.staticLength);

      tailOutline = {
        id: arrow.id + 'tail',
        lat: tipLatLng.lat,
        lng: tipLatLng.lng,
        position: MapOutlinePosition.Bottom,
        offsetX: tailOffset.x,
        offsetY: tailOffset.y,
      };
    }

    return options.predicate ? [tipOutline, tailOutline] : [];
  }, [tailInOffsetMode, options.hideDuringZoomChangeAnimation, options.predicate, mapZoom, lastZoom,
    arrow.tipLatLng, arrow.tailLatLng, arrow.id, arrow.staticLength, fromLatLngToDivPixel]);
};
