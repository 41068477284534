import {
  type BoundaryGroupDemographic, type BoundaryGroupDemographicServerModel,
} from '../boundaryGroups/boundaryGroups.repository';
import {
  type DemographicsRegion, type DemographicsRegionResponse, type DemographicsResult, type DemographicsResultResponse,
} from './demographics.repository';

export const createDemographicsRegionFromResponse = (region: DemographicsRegionResponse): DemographicsRegion => ({
  id: region.id,
  demographics: region.demographics.map(demographic => createDemographicFromResponse(demographic)),
});

export const createDemographicFromResponse = (
  response: BoundaryGroupDemographicServerModel
): BoundaryGroupDemographic => ({
  name: response.name,
  id: response.codename ?? response.id,
  preData: response.pre_data,
  postData: response.post_data,
  percentage: response.percentage,
  category: response.category,
});

export const createServerModelFromDemographic = (
  demographic: BoundaryGroupDemographic
): BoundaryGroupDemographicServerModel => ({
  id: demographic.id,
  name: demographic.name,
  category: demographic.category,
  percentage: demographic.percentage,
  post_data: demographic.postData,
  pre_data: demographic.preData,
});

export const createDemographicsResultFromResponse = (
  response: DemographicsResultResponse
): DemographicsResult => ({
  name: response.name,
  value: response.value,
  functionUsed: response.function_used,
  category: response.category,
  percentage: response.percentage,
  demographicId: response.codename ?? response.demographics_id,
  preData: response.pre_data,
  postData: response.post_data,
});
