import { useCallback } from 'react';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type AppState } from '../app.store';
import type { BoundaryTerritoryGroupId } from '../boundaries/boundaryIdentifier.type';
import type { BoundaryTerritoryGroup } from './boundaryTerritoryGroups.state';

export const boundaryTerritoryGroupsSelector = (state: AppState) => state.map.boundaryTerritoryGroups.groups;
export const useBoundaryTerritoryGroupsSelector = () => useSelector(boundaryTerritoryGroupsSelector);

const boundaryTerritoryGroupsIsLoadingSelector = (state: AppState) =>
  state.map.boundaryTerritoryGroups.isFetchLoading || state.map.boundaryTerritoryGroups.isActionLoading;
export const useBoundaryTerritoryGroupsIsLoadingSelector = () => useSelector(boundaryTerritoryGroupsIsLoadingSelector);

const findBoundaryTerritoryGroup = (boundaryTerritoryGroups: readonly BoundaryTerritoryGroup[], id?: BoundaryTerritoryGroupId | null) =>
  boundaryTerritoryGroups.find(btg => btg.boundaryTerritoryGroupId === id);

export const useGetBoundaryTerritoryGroup = () => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();

  const getBoundaryTerritoryGroupById = useCallback((id?: BoundaryTerritoryGroupId | null) =>
    findBoundaryTerritoryGroup(boundaryTerritoryGroups, id),
  [boundaryTerritoryGroups]);

  return {
    getBoundaryTerritoryGroupById,
  };
};
