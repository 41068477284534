import { type TranslationFnc } from '../../../_shared/utils/hooks';

export enum EditorState {
  Editing = 'Editing',
  Creating = 'Creating',
}

export const renderEditorState = (state: EditorState, t: TranslationFnc): string => {
  switch (state) {
    case EditorState.Creating:
      return t('Create a new Map View');
    case EditorState.Editing:
      return t('Save to this Map View');
    default:
      throw new Error(`Unknown EditorState ${state}.`);
  }
};
