import {
  faCircleLocationArrow, faMapMarkerEdit, faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { type ContextMenuItemProps } from '~/sidebar/sidebarApps/rightSidebar/sidebarContextMenu/sidebarContextMenu.types';
import { ToolsContextMenuStructure } from '../contextMenuStructure.enums';
import { type ToolLocationCallbackProps } from '../contextMenuStructure.types';

const addToRouteStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.AddToRoute),
  icon: faMapMarkerEdit,
  onClick,
});

const drawRadiusStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.DrawRadius),
  icon: faPencil,
  onClick,
});

const navigateStructure = (onClick: () => void, t: TranslationFnc): ContextMenuItemProps => ({
  text: t(ToolsContextMenuStructure.Navigate),
  icon: faCircleLocationArrow,
  onClick,
});

export const toolsContextMenuStructure = (
  callbacks: ToolLocationCallbackProps, t: TranslationFnc,
): ReadonlyArray<ContextMenuItemProps> => ([
  callbacks.addToRouteCallback ? addToRouteStructure(callbacks.addToRouteCallback, t) : null,
  callbacks.drawRadiusCallback ? drawRadiusStructure(callbacks.drawRadiusCallback, t) : null,
  callbacks.navigate ? navigateStructure(callbacks.navigate.callback, t) : null,
]).filter(notNullsy);
