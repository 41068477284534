import {
  put, takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import { ensureIsNotNullsy } from '~/_shared/utils/typeGuards';
import { type BaseMap } from '~/map/layered/baseMapUpdatedModal/baseMapsUpdateModal.types';
import { ModalType } from '~/modal/modalType.enum';
import { getLinkedBaseMapsSelector } from '../../mapInfo/mapInfo.selectors';
import { openModal } from '../../modal/modal.actionCreators';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../data/mapSettingsData.actionTypes';
import {
  getBaseMapsRequiringUpdate,
  type LinkedBaseMap,
} from './baseMapsUpdate/getBaseMapsRequiringUpdate';
import { getSettingsForLayeredMapBaseMapPair } from './mapSettingsLayered.selector';
import { type MapSettingsLayeredState } from './mapSettingsLayered.state';

export function* mapSettingsLayeredSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, handleBaseMapsUpdate);
}

function* handleBaseMapsUpdate() {
  const isLayered: boolean = yield select(state => state.map.mapInfo.data?.isLayered);
  if (!isLayered) {
    return;
  }
  const layeredMapId: number = yield select(state => state.map.mapId);
  const linkedBaseMaps: LinkedBaseMap[] = yield select(getLinkedBaseMapsSelector);

  const layeredState: MapSettingsLayeredState = yield select(state => state.map.mapSettings.data.layered);
  const mapsRequiringUserAction = getBaseMapsRequiringUpdate(
    linkedBaseMaps, baseMapId => getSettingsForLayeredMapBaseMapPair(layeredState)(baseMapId), new Date()
  );

  if (mapsRequiringUserAction.allHandled) {
    return;
  }

  const maps: BaseMap[] = mapsRequiringUserAction.updatedBaseMaps.map(updatedBaseMap => {
    const baseMap = linkedBaseMaps.find(map => map.id === updatedBaseMap.id);
    ensureIsNotNullsy(baseMap);
    return ({
      id: updatedBaseMap.id,
      name: baseMap.name,
      newUpdates: updatedBaseMap.versionDifference,
      version: baseMap.latestVersion,
      snapshot: baseMap.latestSnapshot,
    });
  });
  yield put(openModal(ModalType.BaseMapsUpdate, { layeredMapId, baseMaps: maps }));
}
