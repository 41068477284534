import memoizee from 'memoizee';
import { mapObjectEntities } from '~/map/map/mapObjects/mapObject.types';
import { markerSubEntityCount } from '~/map/zIndexes/markerZIndex';
import { MAX_RENDERED_GROUP_PROXIMITY_CIRCLES } from '../map/proximity/proximityOverlay.container';
import { directionsWaypointsSubEntities } from './useDirectionsRouteZIndexes.hook';
import { proximitySubEntities } from './useIndividualOrDriveTimeProximityZIndex.hook';
import {
  mapPolygonBaseEntities, mapPolygonPriorityEntities,
} from './useMapPolygonInstanceZIndex.hook';
import {
  createZIndexEntitiesRangesLookup,
  getEntityKeyFromZIndexedEntity,
  type MaxEntityCount,
  type ZIndexesPerEntity,
} from './zIndexRanges.helpers';

export enum ZIndexedEntity {
  ActiveDrawingInstance = 'ActiveDrawingInstance',
  ActiveMarker = 'ActiveMarker',
  BoundaryDrawPolygon = 'BoundaryDrawPolygon',
  BoundaryHoverPolygonBorder = 'BoundaryHoverPolygonBorder',
  BoundaryLabel = 'BoundaryLabel',
  BoundaryOutlinePolygon = 'BoundaryOutlinePolygon',
  BoundaryPolygon = 'BoundaryPolygon',
  BoundarySelectionLabelBackground = 'BoundarySelectionLabelBackground',
  BoundarySelectionLabelText = 'BoundarySelectionLabelText',
  BoundarySelectionLasso = 'BoundarySelectionLasso',
  BoundarySelectionPolygon = 'BoundarySelectionPolygon',
  BoundarySelectionPolygonBorder = 'BoundarySelectionPolygonBorder',
  CoverPolygon = 'CoverPolygon',
  DirectionsWaypoint = 'DirectionsWaypoint',
  DrawingInstance = 'DrawingInstance',
  DrawingInstanceBottom = 'DrawingInstanceBottom ',
  DrawingInstanceTop = 'DrawingInstanceTop',
  DriveTimePolygon = 'DriveTimePolygon',
  Heatmap = 'Heatmap',
  HoverLabelBackground = 'HoverLabelBackground',
  HoverLabelText = 'HoverLabelText',
  LocationFinderMarker = 'LocationFinderMarker',
  MapPolygonBaseEntities = 'MapPolygonEntities',
  MapPolygonPriorityEntities = 'MapPolygonPriorityEntities',
  Marker = 'Marker',
  MarkerClusterMarker = 'MarkerClusterMarker',
  MarkerShadow = 'MarkerShadow',
  MoveMarkerLabelsXMark = 'MoveMarkerLabelsXMark',
  PrimaryBoundaryLabel = 'PrimaryBoundaryLabel',
  PrimaryBoundaryPolygon = 'PrimaryBoundaryPolygon',
  ProximityCircle = 'ProximityCircle',
  ProximityMarker = 'ProximityMarker',
  SearchMarker = 'SearchMarker',
}

/***
 *  Define entity ranges on different layers here
 *  [[ZIndexedEntity.Something]] means the layer contains a single entity Something (single entity has range of size 1
 * and you can use getSingleZIndex)
 *  [[ZIndexedEntity.Something, 1_000_000]] means the layer contains entity Something with a range of size 1_000_000
 *  Z-Index Ordering of entities should be as they are ordered in the array (the first is the lowest layer).
 */

export const Z_INDEXES_PER_BOUNDARY_GROUP = 1000;
const maxNumberOfBoundaryGroups = 30;
const maxMapPolygons = 100; // DC, LASSO, Boundary draw / edit...
const maxDrawings = 1000;
const maxDriveTimePolygons = 200;
const maxProximityCirclesZIndexes = MAX_RENDERED_GROUP_PROXIMITY_CIRCLES * 3;
const maxMarkers = 100_000;
const maxMarkerClusters = 1000;
const maxDirectionsWaypoints = 1000;
const maxSearchMarkerZIndexes = 100;
const maxHeatmapZIndexes = 100;

export const zIndexEntitiesOrdered = [
  [ZIndexedEntity.DrawingInstanceBottom, maxDrawings, mapObjectEntities.length],
  [ZIndexedEntity.MarkerShadow, maxMarkers],
  [ZIndexedEntity.Heatmap, maxHeatmapZIndexes],
  [ZIndexedEntity.BoundaryPolygon, maxNumberOfBoundaryGroups * Z_INDEXES_PER_BOUNDARY_GROUP * 2], // *2 = polygon + border
  [ZIndexedEntity.PrimaryBoundaryPolygon, Z_INDEXES_PER_BOUNDARY_GROUP * 2], // *2 = polygon + border
  [ZIndexedEntity.BoundaryHoverPolygonBorder, Z_INDEXES_PER_BOUNDARY_GROUP * 2], // *2 = active + hovered border
  [ZIndexedEntity.BoundaryOutlinePolygon, Z_INDEXES_PER_BOUNDARY_GROUP * 2], // polygon + border
  [ZIndexedEntity.BoundaryLabel, maxNumberOfBoundaryGroups * Z_INDEXES_PER_BOUNDARY_GROUP * 2], // *2 = label + callout
  [ZIndexedEntity.PrimaryBoundaryLabel, Z_INDEXES_PER_BOUNDARY_GROUP * 2], // *2 = label + callout
  [ZIndexedEntity.DriveTimePolygon, maxDriveTimePolygons, proximitySubEntities.length],
  [ZIndexedEntity.ProximityCircle, maxProximityCirclesZIndexes, proximitySubEntities.length],
  [ZIndexedEntity.ProximityMarker, maxDriveTimePolygons + maxProximityCirclesZIndexes],
  [ZIndexedEntity.CoverPolygon],
  [ZIndexedEntity.DrawingInstance, maxDrawings, mapObjectEntities.length],
  [ZIndexedEntity.MapPolygonBaseEntities, maxMapPolygons, mapPolygonBaseEntities.length],
  [ZIndexedEntity.Marker, maxMarkers, markerSubEntityCount],
  [ZIndexedEntity.SearchMarker, maxSearchMarkerZIndexes],
  [ZIndexedEntity.MarkerClusterMarker, maxMarkerClusters],
  [ZIndexedEntity.DirectionsWaypoint, maxDirectionsWaypoints, directionsWaypointsSubEntities.length],
  [ZIndexedEntity.DrawingInstanceTop, maxDrawings, mapObjectEntities.length],
  [ZIndexedEntity.LocationFinderMarker],
  [ZIndexedEntity.MapPolygonPriorityEntities, maxMapPolygons, mapPolygonPriorityEntities.length],

  // Active marker and hover effects
  [ZIndexedEntity.ActiveMarker, 1, markerSubEntityCount],
  [ZIndexedEntity.HoverLabelBackground],
  [ZIndexedEntity.HoverLabelText],

  // Map interactions / active drawing / DnD / editing on map
  [ZIndexedEntity.BoundaryDrawPolygon, maxMapPolygons, mapObjectEntities.length],
  [ZIndexedEntity.ActiveDrawingInstance, 1, mapObjectEntities.length],
  [ZIndexedEntity.MoveMarkerLabelsXMark, 1, mapObjectEntities.length],
  [ZIndexedEntity.BoundarySelectionPolygon, Z_INDEXES_PER_BOUNDARY_GROUP],
  [ZIndexedEntity.BoundarySelectionPolygonBorder, Z_INDEXES_PER_BOUNDARY_GROUP],
  [ZIndexedEntity.BoundarySelectionLabelBackground, Z_INDEXES_PER_BOUNDARY_GROUP],
  [ZIndexedEntity.BoundarySelectionLabelText, Z_INDEXES_PER_BOUNDARY_GROUP],
  [ZIndexedEntity.BoundarySelectionLasso, 1, mapObjectEntities.length],
] as const;

export type SubEntitySize<T extends ZIndexedEntity> = (typeof zIndexEntitiesOrdered) extends (readonly (infer EntityRegistration)[])
  ? EntityRegistration extends readonly [T, MaxEntityCount, infer Size] ? Size : never
  : never;

export type ZIndexedEntityWithSubEntities = (typeof zIndexEntitiesOrdered) extends (readonly (infer EntityRegistration)[])
  ? EntityRegistration extends readonly [infer TypeWithSubEntities, MaxEntityCount, ZIndexesPerEntity] ? TypeWithSubEntities : never
  : never;

export type ZIndexedEntityWithoutSubEntities = Exclude<ZIndexedEntity, ZIndexedEntityWithSubEntities>;

const zIndexEntitiesRangesLookup = createZIndexEntitiesRangesLookup(zIndexEntitiesOrdered);

export const calculateZIndex = (offset: number, entity: ZIndexedEntity): number => {
  const foundRange = zIndexEntitiesRangesLookup[getEntityKeyFromZIndexedEntity(entity)];
  return Math.min(foundRange[0] + (offset ?? Infinity), foundRange[1] - 1);
};

export const calculateEntityZIndexOffset = (entity: ZIndexedEntity): number => (
  zIndexEntitiesRangesLookup[getEntityKeyFromZIndexedEntity(entity)][0]
);

export const calculateEntityZIndexRangeSize = (entity: ZIndexedEntity): number => {
  const foundRange = zIndexEntitiesRangesLookup[getEntityKeyFromZIndexedEntity(entity)];
  return foundRange[1] - foundRange[0];
};

export const getZIndexesPerEntity = memoizee(
  (entity: ZIndexedEntity) => zIndexEntitiesOrdered.find(i => i[0] === entity)?.[2] ?? 1,
  { max: zIndexEntitiesOrdered.length }
);

// the following type extracts only a union of arrays which don't have the 2nd (range) param in the zIndexEntitiesOrdered array
type SingleEntityArray = Extract<typeof zIndexEntitiesOrdered[number], readonly [string]>;
// the following type just infers the entity names from the union of arrays
type SingleEntity = SingleEntityArray extends readonly [infer TEntity] ? TEntity : never;
// the following function is to be used only by entities which occupy only 1 z-index
export const getSingleZIndex = (entity: SingleEntity) => {
  const foundRange = zIndexEntitiesRangesLookup[getEntityKeyFromZIndexedEntity(entity)];
  if (foundRange[1] - foundRange[0] !== 1) {
    throw new Error(`getSingleZIndex: This should never happen. Entity ${entity} has ${foundRange[1] - foundRange[0]}.`);

  }
  return foundRange[0];
};
