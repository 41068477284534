import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, Fragment,
} from 'react';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import {
  type TranslationFnc,
  useTranslation,
} from '../../utils/hooks';

export type RowProps = Readonly<{
  dropdownOptions: ReadonlyArray<DropdownOption<string | undefined>>;
  selectedOptionId: string | null;
  rowName: string;
  onChange: (newId: string) => void;
  isAutoMatched: boolean;
  triggerStyle?: SerializedStyles;
  triggerIconStyle?: SerializedStyles;
}>;

type MatchUpDataTableProps = Readonly<{
  primaryColumnHeading: string;
  secondaryColumnHeading: string;
  rows: ReadonlyArray<RowProps>;
  bodyStyle?: SerializedStyles;
}>;

const contentPadding = 15;

const displayGridStyle = css(`
  display: -ms-grid;
  display: grid;
`);

const headerStyle = ({ theme }: ThemeProps) => css(displayGridStyle, {
  msGridColumns: '1fr 1fr',
  gridTemplateColumns: '1fr 1fr',
  padding: `0 ${contentPadding}px`,
  height: 40,
  lineHeight: '40px',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
});

const categoriesStyle = css({
  msGridRow: 1,
  msGridColumn: 1,
});

const dataStyle = css({
  msGridRow: 1,
  msGridColumn: 2,
});

const renderHeader = (props: ThemeProps<{ primaryText: string; secondaryText: string }>) => (
  <div css={headerStyle(props)}>
    <div css={categoriesStyle}>{props.primaryText}</div>
    <div css={dataStyle}>{props.secondaryText}</div>
  </div>
);

const rowsContainerStyle = css(displayGridStyle, {
  msGridColumns: '1fr 1fr 2fr 40px',
  gridTemplateColumns: '1fr 1fr 2fr 40px',
});

const rowStyle = ({ theme, borderHidden }: ThemeProps<{borderHidden: boolean}>) => css({
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: borderHidden ? 'none' : `1px solid ${theme.borderColors.primary}`,
  height: 56,
  lineHeight: '56px',
});

const categoryRowStyle = ({ theme, rowNumber, borderHidden }: ThemeProps<{
  rowNumber: number; borderHidden: boolean;
}>) => css(rowStyle({ theme, borderHidden }), {
  msGridRow: rowNumber,
  msGridColumn: 1,
  paddingLeft: 15,
  display: 'flex',
  alignItems: 'center',
  lineHeight: 'initial',
});

const dataLabelRowStyle = ({ theme, rowNumber, borderHidden }: ThemeProps<{
  rowNumber: number; borderHidden: boolean;
}>) => css(rowStyle({ theme, borderHidden }), {
  msGridRow: rowNumber,
  msGridColumn: 2,
  paddingRight: 5,
  textAlign: 'right',
  fontSize: '12px',
  color: theme.textColors.secondary,
});

const dataRowStyle = ({ theme, rowNumber, borderHidden }: ThemeProps<{
  rowNumber: number; borderHidden: boolean;
}>) => css(rowStyle({ theme, borderHidden }), {
  msGridRow: rowNumber,
  msGridColumn: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const checkMarkRowStyle = ({ theme, rowNumber, borderHidden }: ThemeProps<{
  rowNumber: number; borderHidden: boolean;
}>) => css(rowStyle({ theme, borderHidden }), {
  msGridRow: rowNumber,
  msGridColumn: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.iconColors.success,
  paddingRight: 8,
});

const dropdownStyle = css({
  height: 40,
});

const renderRow = ({
  theme, rowNumber, onChange, dropdownOptions, isLastRow: borderHidden, rowName, selectedOptionId, isAutoMatched, triggerStyle, triggerIconStyle, t,
}: ThemeProps<{ rowNumber: number; t: TranslationFnc; isLastRow: boolean } & RowProps>) => (
  <Fragment key={rowNumber}>
    <div css={categoryRowStyle({ theme, rowNumber, borderHidden })}>
      {rowName}
    </div>
    <div css={dataLabelRowStyle({ theme, rowNumber, borderHidden })}>
      {t('MATCHES TO')}
    </div>
    <div css={dataRowStyle({ theme, rowNumber, borderHidden })}>
      <RegularDropdownComponent<string | undefined>
        triggerStyle={css([dropdownStyle, triggerStyle])}
        triggerIconStyle={triggerIconStyle}
        itemStyle={dropdownStyle}
        onChange={onChange}
        value={selectedOptionId}
        options={dropdownOptions}
        fallbackName={t('Select a column')}
        inPortal
      />
    </div>
    <div css={checkMarkRowStyle({ theme, rowNumber, borderHidden })}>
      {isAutoMatched && <FontAwesomeIcon icon={faCheckCircle} />}
    </div>
  </Fragment>
);

export const MatchUpDataTableComponent: FC<MatchUpDataTableProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <>
      {renderHeader({
        theme,
        primaryText: props.primaryColumnHeading,
        secondaryText: props.secondaryColumnHeading,
      })}
      <div css={css(rowsContainerStyle, props.bodyStyle)}>
        {props.rows
          .map((rowProps, index) => renderRow({
            theme, t, rowNumber: index, isLastRow: props.rows.length - 1 === index, ...rowProps,
          }))}
      </div>
    </>
  );
};
