import { useMemo } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { getDirectionsLabelMarkerSettings } from '~/map/map/directions/mapDirectionsManager';
import type { StackedMarker } from '~/map/map/markers/manager/mapMarkerManager';
import { useStackedMarkerStyles } from '~/map/map/markers/useMarkers/useMarkerStyles.hook';
import { useMapSettingsDirectionsRoutesSpreadsheetRowsSelector } from '~/store/mapSettings/directions/mapSettingsDirections.selectors';
import { useMapSettingsStackMarkersWithPieChartsSelector } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import { useGetMarkerVisualsForSpreadsheetRowIds } from './useGetMarkerVisualsForSpreadsheetRowIds.hook';

const GROUPING_TOOL_HIERARCHY_INDICATOR = 0; //means we are interested in the visuals of the first (main) marker when submarkers are present

export const useGetFinalMarkerSettingsForSpreadsheetRow = (spreadsheetRowId?: SpreadsheetRowId, stackInfo?: StackedMarker) => {
  const theme = useTheme();
  const directionsSpreadsheetRows = useMapSettingsDirectionsRoutesSpreadsheetRowsSelector();
  const stackWithPieCharts = useMapSettingsStackMarkersWithPieChartsSelector();
  const stackedMarkersStyles = useStackedMarkerStyles();

  const spreadsheetRowIds = useMemo(() =>
    spreadsheetRowId ? [spreadsheetRowId] : [],
  [spreadsheetRowId]);

  const visualSettings = useGetMarkerVisualsForSpreadsheetRowIds({
    isLegend: false,
    groupHierarchyIndicator: GROUPING_TOOL_HIERARCHY_INDICATOR,
    spreadsheetRowIds,
  });

  const markerVisualSettings: MarkerSettings | undefined = useMemo(() => {
    if (!spreadsheetRowId?.rowId || !spreadsheetRowId?.spreadsheetId || !visualSettings) {
      return undefined;
    }

    const visualSettingsForActiveMarker = visualSettings[spreadsheetRowId?.spreadsheetId]?.[spreadsheetRowId?.rowId];

    const routeWaypointData = directionsSpreadsheetRows
      .get(spreadsheetRowId.spreadsheetId)?.get(spreadsheetRowId.rowId);
    if (routeWaypointData) {
      const directionMarkerVisualSettings = getDirectionsLabelMarkerSettings({
        index: routeWaypointData.waypointIndex,
        totalWaypoints: routeWaypointData.route.waypoints.length,
        theme,
        routeColor: routeWaypointData.route.color,
      });
      return {
        ...directionMarkerVisualSettings,
        aboveLabel: visualSettingsForActiveMarker?.aboveLabel,
      };
    }

    if (stackInfo && stackedMarkersStyles) {
      const stackedMarkerStyle = stackWithPieCharts ? stackedMarkersStyles.pieChartHolderStyle
        : stackedMarkersStyles.perGroup[stackInfo.groupId ?? ''] ?? stackedMarkersStyles.stackedMarkerStyle;

      return {
        ...visualSettingsForActiveMarker,
        useMarker: true,
        marker: {
          styleId: stackedMarkerStyle.marker.id,
          styleType: MarkerStyleType.STANDARD,
          size: stackedMarkerStyle.size,
        },
      };
    }

    return visualSettingsForActiveMarker;
  }, [spreadsheetRowId?.rowId, spreadsheetRowId?.spreadsheetId, visualSettings, directionsSpreadsheetRows, stackInfo, stackedMarkersStyles, theme, stackWithPieCharts]);

  return markerVisualSettings;
};
