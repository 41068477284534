import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons';
import {
  LottieAnimationComponent,
  LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { LogoComponent } from '~/_shared/components/logo/logo.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { s } from '~/translations/Trans';

const containerStyle = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const animationStyle = css({
  marginTop: 12,
});

const textContainerStyle = css({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const congratulationStyle = css({
  fontSize: '36px',
});

const headingSecondaryStyle = css({
  fontSize: '20px',
});

const closeMessageStyle = css({
  fontSize: '16px',
});

const buttonStyle = css({
  marginTop: 20,
});

export const SubscriptionCompletedComponent: FC = () => {
  const [t] = useTranslation();

  const onWindowClose = () => {
    window.close();
  };

  return (
    <div css={containerStyle}>
      <LogoComponent width="250px" />

      <div css={textContainerStyle}>
        <div css={congratulationStyle}>{t('Congratulations!')}</div>
        <div css={headingSecondaryStyle}>{t('You purchased a brand new subscription')}</div>
      </div>

      <LottieAnimationComponent
        css={animationStyle}
        type={LottieAnimationTypes.Confetti}
        size={250}
        autoplay
      />

      <div css={closeMessageStyle}>
        {s('You can now safely close this page')}
      </div>

      <ButtonComponent
        css={buttonStyle}
        text={t('Close Page')}
        onClick={onWindowClose}
      />
    </div>
  );
};
