import {
  useCallback, useState,
} from 'react';

export const useIsOpen = (isInitiallyOpen = false) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleOpen = useCallback(() => {
    setIsOpen(open => !open);
  }, []);

  return {
    isOpen,
    open,
    close,
    toggleOpen,
  };
};
