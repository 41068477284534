import {
  useCallback, useEffect, useState,
} from 'react';

const DEFAULT_NAME = '';

export const useSetBoundaryName = (defaultName: string = DEFAULT_NAME) => {
  const [name, setName] = useState(defaultName);
  const isNameFilled = () => {
    return name !== '';
  };
  const changeName = (newName: string) => {
    setName(newName);
  };
  const [nameIsSet, setNameIsSet] = useState(false);
  const finishEditing = useCallback(() => {
    setNameIsSet(true);
  }, []);
  const resetName = useCallback(
    () => {
      setName(defaultName);
      setNameIsSet(false);
    },
    [defaultName]);
  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  return {
    name, finishEditing, changeName, isNameFilled, nameIsSet, resetName,
  };
};
