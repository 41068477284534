import { css } from '@emotion/react';
import {
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import { type DatePickerProps } from 'react-datepicker';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type ThemeProps } from '../../types/themeProps';
import { DatePickerHeaderComponent } from './datePickerHeader.component';

const rootStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px',
  color: theme.textColors.secondary,
  minWidth: 250,
  boxSizing: 'border-box',
});

const iconWrapperStyle = css({
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
});

export const createRenderDatepickerHeader = (themeProps: ThemeProps): NonNullable<DatePickerProps['renderCustomHeader']> => params => {

  return (
    <div css={rootStyle(themeProps)}>
      <div
        css={iconWrapperStyle}
        onClick={params.prevMonthButtonDisabled ? undefined : params.decreaseMonth}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <DatePickerHeaderComponent
        onMonthChange={params.changeMonth}
        onYearChange={params.changeYear}
        date={params.date}
      />
      <div
        css={iconWrapperStyle}
        onClick={params.nextMonthButtonDisabled ? undefined : params.increaseMonth}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </div>
  );
};
