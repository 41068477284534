import {
  useCallback,
  useEffect, useState,
} from 'react';
import { useDebounce } from '../../utils/hooks/useDebounce';
import {
  ToggleComponent, type ToggleProps,
} from './toggle.component';

export type ToggleWithDelayProps = {
  delayInMS: number;
  onChange: (isSelected: boolean) => void;
} & ToggleProps;

export const ToggleWithDelayComponent = ({ isOn, onChange, delayInMS, ...restProps }: ToggleWithDelayProps) => {
  const [isOnInternal, setIsOnInternal] = useState(isOn);
  const propagate = useDebounce(onChange, delayInMS);

  useEffect(() => {
    setIsOnInternal(isOn);
  }, [isOn, propagate]);

  const handleOnChange = useCallback((isOn: boolean) => {
    setIsOnInternal(isOn);
    propagate(isOn);
  }, [propagate]);

  return (
    <ToggleComponent
      {...restProps}
      isOn={isOnInternal}
      onChange={handleOnChange}
    />
  );
};
