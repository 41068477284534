import {
  type ReactNode,
  useCallback,
} from 'react';
import { type WithoutModalProps } from '~/modal/renderModal';
import { ModalType } from '../../../../modal/modalType.enum';
import { useModal } from '../../../../modal/useModal.hook';
import { useTranslation } from '../../../utils/hooks';
import { useStorageService } from '../../../utils/storageService';
import { type ConfirmationModalProps } from './confirmationModal.component';
import {
  buildConfirmationModalPropsFromParams,
  checkIfModalIsAlreadyConfirmed,
} from './confirmationModal.helpers';

export enum PermanentConfirmStrategy {
  Session = 'session',
  Local = 'local',
}

export type PermanentConfirmSettings = {
  id: string;
  strategy: PermanentConfirmStrategy;
};

export type UseConfirmationModalParams =
  Omit<WithoutModalProps<ConfirmationModalProps>,
  'children' |
  'checkboxLabel' |
  'onConfirm'
  >
  & Readonly<{
    text: ReactNode;
    onConfirm: () => void;
    permanentConfirmSettings?: PermanentConfirmSettings;
  }>;

type ConfirmationModalPromiseParams = Omit<UseConfirmationModalParams, 'onConfirm' | 'onCancel' | 'onClose'>;

export const useConfirmationModal = (customId?: string) => {
  const { openModal, closeModal: closeConfirmationModal, replaceCurrentModal } = useModal(ModalType.Confirmation, customId);
  const [t] = useTranslation();
  const storageService = useStorageService();

  const openConfirmationModal = useCallback((params: UseConfirmationModalParams) => {
    if (params.permanentConfirmSettings && checkIfModalIsAlreadyConfirmed(params.permanentConfirmSettings, storageService)) {
      params.onConfirm();
      return;
    }

    const confirmationModalProps = buildConfirmationModalPropsFromParams(params, t, storageService);
    openModal(confirmationModalProps);
  }, [openModal, t, storageService]);

  const replaceCurrentModalWithConfirmationModal = useCallback((params: UseConfirmationModalParams) => {
    if (params.permanentConfirmSettings && checkIfModalIsAlreadyConfirmed(params.permanentConfirmSettings, storageService)) {
      params.onConfirm();
      return;
    }

    const confirmationModalProps = buildConfirmationModalPropsFromParams(params, t, storageService);
    replaceCurrentModal(confirmationModalProps);
  }, [replaceCurrentModal, t, storageService]);

  const openConfirmationModalAndWait = useCallback((params: ConfirmationModalPromiseParams) => {
    return new Promise<boolean>((resolve) => {
      openConfirmationModal({
        ...params,
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
        onClose: () => resolve(false),
      });
    });
  }, [openConfirmationModal]);

  const replaceCurrentModalWithConfirmationModalAndWait = useCallback((params: ConfirmationModalPromiseParams) => {
    return new Promise<boolean>((resolve) => {
      openConfirmationModal({
        ...params,
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
        onClose: () => resolve(false),
      });
    });
  }, [openConfirmationModal]);

  return {
    openConfirmationModal,
    closeConfirmationModal,
    replaceCurrentModalWithConfirmationModal,
    replaceCurrentModalWithConfirmationModalAndWait,
    openConfirmationModalAndWait,
  };
};
