import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type HeatMap } from '~/_shared/types/heatmap/heatMap.types';
import { useTranslation } from '~/_shared/utils/hooks';
import { mapListingChanged } from '~/store/frontendState/mapListing/mapListing.actionCreators';
import {
  isDriveTimePolygon,
  isGroupRadius,
  isIndividualRadius,
  type Proximity,
} from '../_shared/types/proximity/proximity.types';
import {
  type UndoHeatmapData,
  UndoItemType,
  type UndoMapSnapshotsData,
  type UndoProximityData,
  type UndoRouteData,
} from '../_shared/types/undo/undoItem';
import { type UndoSelectorItem } from '../_shared/types/undo/undoSelectorItem';
import { type SpreadsheetVersionItem } from '../_shared/types/undo/versionItem';
import { useSelector } from '../_shared/utils/hooks/useSelector';
import { getShortUnitSystemLabel } from '../_shared/utils/unitSystem/unitSystem.helpers';
import { getHeatMapItemName } from '../heatMap/listing/heatMapListing.helpers';
import { getDriveTimePolygonShortLabel } from '../proximity/proximity.helpers';
import { mapReset } from '../store/map/map.actionCreators';
import { mapInfoFetchDataRequest } from '../store/mapInfo/mapInfo.actionCreators';
import { addRouteSetting } from '../store/mapSettings/directions/mapSettingsDirections.actionCreators';
import { type MapSettingsRoute } from '../store/mapSettings/directions/mapSettingsDirections.state';
import { addHeatMap } from '../store/mapSettings/heatmaps/mapSettingsHeatmaps.actionCreators';
import { addProximity } from '../store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { useSpreadsheetColumns } from '../store/matchupData/matchupDataSelectors.hook';
import { deleteMapsUndoItem } from '../store/undo/undo.actionCreators';
import { useManageSpreadsheetVersions } from '../undo/useManageSpreadsheetVersions';

type UseUndoSelectorItems = Readonly<{
  clientId: number | null;
  spreadsheetId: number | null;

  onAfterUndoComplete: () => void;
  restoreMap: (ids: ReadonlyArray<number>, onSuccess?: ((() => void) | null)) => Promise<void>;
}>;

export type UndoSelectorItems = Readonly<{
  mapModifications: UndoSelectorItem[];
  mapDeletions: UndoSelectorItem[];
  versions: SpreadsheetVersionItem[] | null;
}>;

export const useUndoSelectorItems = (props: UseUndoSelectorItems) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const columns = useSpreadsheetColumns();
  const { spreadsheetVersions, rollbackVersion, isLoading: isRollbackInProgress }
    = useManageSpreadsheetVersions(props.clientId, props.spreadsheetId);
  const currentMapId = useSelector(state => state.map.mapId);
  const undoItems = useSelector(state => state.map.undo.data);

  const { onAfterUndoComplete } = props;

  const selectorItems: UndoSelectorItems = useMemo(() => {
    const mapModifications: UndoSelectorItem[] = [];
    const mapDeletions: UndoSelectorItem[] = [];

    undoItems.forEach(item => {
      if (item.type !== UndoItemType.DELETE_MAPS_AND_SNAPSHOTS && item.mapId !== currentMapId) {
        return;
      }

      switch (item.type) {
        case UndoItemType.DELETE_MAPS_AND_SNAPSHOTS: {
          const undoSnapShotItem: UndoMapSnapshotsData = item.data;
          mapDeletions.push({
            caption: t('Undo Map/Map View'),
            description: undoSnapShotItem.name,
            date: new Date(undoSnapShotItem.deletedAt),
            id: undoSnapShotItem.id,
            type: UndoItemType.DELETE_MAPS_AND_SNAPSHOTS,
          });
          break;
        }

        case UndoItemType.REMOVE_HEATMAP: {
          const heatMap: UndoHeatmapData = item.data;
          const description = getHeatMapItemName(heatMap, columns, t).replace(':', ' ');
          mapModifications.push({
            caption: t('Undo Remove Heatmap'),
            description,
            date: new Date(item.timestamp),
            id: heatMap.id,
            type: UndoItemType.REMOVE_HEATMAP,
          });
          break;
        }

        case UndoItemType.REMOVE_PROXIMITY: {
          const proximity: UndoProximityData = item.data;
          let description = '';
          if (isDriveTimePolygon(proximity)) {
            description = t('Drive Time Polygon: {{time}}', {
              time: getDriveTimePolygonShortLabel(proximity.data.hours, proximity.data.minutes),
            });
          }
          if (isGroupRadius(proximity) || isIndividualRadius(proximity)) {
            description = t('Proximity Radius{{subtype}}: {{distance}}{{unit}}', {
              distance: proximity.data.radius,
              unit: getShortUnitSystemLabel(proximity.data.unit, t),
              subtype: isGroupRadius(proximity) ? `(${t('Group')})` : '',
            });
          }
          mapModifications.push({
            caption: t('Undo Remove Proximity'),
            description,
            date: new Date(item.timestamp),
            id: proximity.id,
            type: UndoItemType.REMOVE_PROXIMITY,
          });
          break;
        }

        case UndoItemType.REMOVE_ROUTE: {
          const route: UndoRouteData = item.data;
          const description = t('From {{from}} to {{to}}', {
            from: route.waypoints[0]?.address ?? '',
            to: route.waypoints[route.waypoints.length - 1]?.address ?? '',
          });
          mapModifications.push({
            caption: t('Undo Remove Route'),
            description,
            date: new Date(item.timestamp),
            id: route.id,
            type: UndoItemType.REMOVE_ROUTE,
          });
          break;
        }

        default:
          break;
      }
    });

    return { mapModifications, mapDeletions, versions: spreadsheetVersions };
  }, [undoItems, spreadsheetVersions, currentMapId, t, columns]);

  const onUndoModifying = async (selectedItemsIndexes: ReadonlyArray<number>) => {
    const selectedMapIds: number[] = [];
    const selectedActionIndices: number[] = [];

    selectedItemsIndexes.forEach(index => {
      const item = undoItems[index];
      if (item) {
        if (item && (item.type === UndoItemType.DELETE_MAPS_AND_SNAPSHOTS)) {
          selectedMapIds.push(item.data.id);
        }
        else {
          selectedActionIndices.push(index);
        }
      }
    });

    if (selectedMapIds.length) {
      await props.restoreMap(selectedMapIds);
      dispatch(mapListingChanged());
      if (currentMapId !== null && props.clientId !== null) {
        dispatch(mapListingChanged());
        dispatch(mapInfoFetchDataRequest(props.clientId, currentMapId));
      }
    }

    for (const index of selectedActionIndices) {
      const item = undoItems[index];
      if (item) {
        if (!item) {
          continue;
        }

        switch (item.type) {
          case UndoItemType.REMOVE_HEATMAP: {
            const heatMap: HeatMap = item.data;
            dispatch(addHeatMap(heatMap));
            dispatch(deleteMapsUndoItem(item));
            break;
          }
          case UndoItemType.REMOVE_PROXIMITY: {
            const proximity: Proximity = item.data;
            dispatch(addProximity(proximity));
            dispatch(deleteMapsUndoItem(item));
            break;
          }
          case UndoItemType.REMOVE_ROUTE: {
            const route: MapSettingsRoute = item.data;
            dispatch(addRouteSetting(route));
            dispatch(deleteMapsUndoItem(item));
            break;
          }
          default:
            break;
        }
      }
    }
    onAfterUndoComplete();
  };
  const onRollback = useCallback((selectedId: string) => {
    const version = spreadsheetVersions?.find(version => version.id === selectedId);

    if (version) {
      const ids = version.id.split('|');
      const realSpreadsheetId = Number(ids[0]);
      const snapshotIndex = Number(ids[1]);
      const versionIndex = Number(ids[2]);

      if (!isNaN(realSpreadsheetId) && !isNaN(snapshotIndex) && !isNaN(versionIndex)) {
        rollbackVersion({
          realSpreadsheetId,
          snapshotIndex,
          versionIndex,
        }, () => {
          dispatch(mapReset());
          onAfterUndoComplete();
        });
      }
    }
  }, [dispatch, onAfterUndoComplete, rollbackVersion, spreadsheetVersions]);

  return {
    selectorItems,
    onUndoModifying,
    onRollback,
    isInProgress: isRollbackInProgress,
  };
};
