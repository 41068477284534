import {
  useCallback, useMemo,
} from 'react';
import { shareNavigationLink } from '~/_shared/repositories/navigationLink.repository';
import { type LatLng } from '~/_shared/types/latLng';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';

export type SendNavigationLinkProps = Readonly<{
  email: string;
  locationName: string;
  latLng: LatLng;
}>;

export const useSendNavigationLink = () => {
  const { isLoading, error, invokeAjax } = useAjaxCall(shareNavigationLink);

  const sendNavigationLink = useCallback((props: SendNavigationLinkProps) => (
    invokeAjax(props.email, props.locationName, props.latLng.lat, props.latLng.lng)
  ), [invokeAjax]);

  return useMemo(() => ({
    sendNavigationLink,
    isLoading,
    error,
  }), [error, isLoading, sendNavigationLink]);
};
