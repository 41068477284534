import { css } from '@emotion/react';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  type FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AccordionComponent,
  type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { ModalFooterSampleExcelComponent } from '~/_shared/components/modalFooterSampleExcel/modalFooterSampleExcel.component';
import { UploadStatus } from '~/_shared/components/uploadStatus/uploadStatus.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { SelectSpreadsheetWithErrorComponent } from '../selectSpreadsheet/withError/selectSpreadsheetWithError.component';
import { MapNameAndDescriptionComponent } from './mapNameAndDescription.component';
import { UploadMethodComponent } from './uploadMethod/uploadMethod.component';

type CreateNewMapProps = Readonly<{
  isOpen: boolean;
  createBlankMapErrorMessage: string;
  createSpreadsheetMapErrorMessage: string;

  isLoading: boolean;
  prefillFormData?: {[key: string]: string | boolean};

  onCreateBlankMap: (mapName: string, mapDescription: string) => void;
  onClose: () => void;
  onCreateMapFromSpreadsheet: (
    file: File,
    mapName: string,
    mapDescription: string,
    onProgressChange: (progress: number) => void,
    onStatusChange: (status: UploadStatus) => void
  ) => void;
  onCreateSpreadsheetMapErrorMessageClose: () => void;
  onStartGoogleSheetsClick: (
    mapName: string,
    mapDescription: string
  ) => void;
  isNewSalesRepMap?: boolean;
}>;

export type UploadItem = {
  id: string;
  status: UploadStatus;
  file: File;
  progress: number;
};

const accordionContentStyle = css({
  padding: '2px 10px',
});

const noteStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
  fontWeight: 500,
  lineHeight: 1.5,
});

const uploadMethodSeparatorStyle = css({
  display: 'block',
  marginTop: 24,
  textAlign: 'center',
  fontSize: 14,
  textTransform: 'uppercase',
});

const secondaryUploadContainerStyle = css({
  marginTop: 24,
  display: 'flex',
});

const uploadMethodStyle = css({
  width: '50%',
  '&:first-of-type': {
    marginRight: 8,
  },
  '&:last-of-type': {
    marginLeft: 8,
  },
});

const mapNowButtonStyle = css({
  height: 40,
  padding: '0 12px',
});

const alertStyle = css({
  marginTop: 20,
});

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

export const CreateNewMapComponent: FC<CreateNewMapProps> = (props) => {
  const [mapName, setMapName] = useState(props.prefillFormData ? props.prefillFormData.mapName as string : '');
  const [mapDescription, setMapDescription] = useState(props.prefillFormData ? props.prefillFormData.mapDescription as string : '');
  const [isMapOverviewOpen, setIsMapOverviewOpen] = useState(props.prefillFormData ? !!props.prefillFormData?.isMapOverviewOpen : true);
  const [isDataSourceOpen, setIsDataSourceOpen] = useState(props.prefillFormData ? !!props.prefillFormData?.isDataSourceOpen : false);
  const [isMapCreationInProgress, setIsMapCreationInProgress] = useState(false);
  const [mapNameInputRef, setMapNameInputRef] = useHookWithRefCallback<HTMLInputElement>();
  const [t] = useTranslation();
  const theme = useTheme();

  const { onCreateMapFromSpreadsheet, onStartGoogleSheetsClick, onCreateBlankMap } = props;

  const toggleMapOverviewOpen = useCallback(() => {
    setIsMapOverviewOpen(isOpen => !isOpen);
  }, []);

  const onMapOverviewFormSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    setIsMapOverviewOpen(false);
    setIsDataSourceOpen(true);
  }, []);

  const onStartBlankClick = useCallback(() =>
    onCreateBlankMap(mapName, mapDescription),
  [mapDescription, mapName, onCreateBlankMap]);

  const onUploadItemSelect = useCallback((file: File, onProgressChange: (progress: number) => void, onStatusChange: (status: UploadStatus) => void) => {
    const onUploadStatusChange = (status: UploadStatus) => {
      setIsMapCreationInProgress(status === UploadStatus.InProgress);
      onStatusChange(status);
    };
    onCreateMapFromSpreadsheet(file, mapName, mapDescription, onProgressChange, onUploadStatusChange);
  },
  [mapDescription, mapName, onCreateMapFromSpreadsheet]);

  const handleStartGoogleSheetsClick = useCallback(() =>
    onStartGoogleSheetsClick(mapName, mapDescription),
  [mapDescription, mapName, onStartGoogleSheetsClick]);

  useEffect(() => {
    if (isTextEmpty(mapName)) {
      setIsMapOverviewOpen(true);
      setIsDataSourceOpen(false);
    }
  }, [mapName]);

  useEffect(() => {
    if (!props.isOpen) {
      setMapName('');
      setMapDescription('');
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen && mapNameInputRef) {
      mapNameInputRef.focus();
    }
  }, [props.isOpen, mapNameInputRef]);

  const ModalConfirmButton = useMemo(() => (
    <ButtonComponent
      css={mapNowButtonStyle}
      prefixIcon={faMap}
      text={t('Map Now')}
      isDisabled
    />
  ), [t]);

  const isContinueEnabled = !isTextEmpty(mapName);

  const mapOverview: AccordionData = useMemo(() => ({
    header: props.isNewSalesRepMap ? t('Name Your Sales Rep Location Map') : t('Name Your Map'),
    isExpanded: isMapOverviewOpen,
    isLocked: !isContinueEnabled,
    onHeadingClick: toggleMapOverviewOpen,
    child: (
      <MapNameAndDescriptionComponent
        alignContinueButtonToRight={false}
        mapName={mapName}
        mapDescription={mapDescription}
        isContinueEnabled={isContinueEnabled}
        setMapNameInputRef={setMapNameInputRef}
        setMapName={setMapName}
        setMapDescription={setMapDescription}
        onSubmit={onMapOverviewFormSubmit}
        isNewSalesRepMap={props.isNewSalesRepMap}
      />
    ),
  }), [isContinueEnabled, isMapOverviewOpen, mapDescription, mapName, onMapOverviewFormSubmit,
    props.isNewSalesRepMap, setMapNameInputRef, t, toggleMapOverviewOpen,
  ]);

  const dataSource: AccordionData = useMemo(() => ({
    header: props.isNewSalesRepMap ? t('Select data source (of sales people)') : t('Select Data Source'),
    isExpanded: isDataSourceOpen,
    isLocked: true,
    child: (
      <div css={accordionContentStyle}>
        {
          props.isNewSalesRepMap
            && (
              <strong css={noteStyle({ theme })}>
                {t('spreadsheet.note.column.header.sales-map')}
                <br /><br />
              </strong>
            )
        }
        <strong css={noteStyle({ theme })}>{t('spreadsheet.note.column.header')}</strong>

        <SelectSpreadsheetWithErrorComponent
          error={props.createSpreadsheetMapErrorMessage}
          onErrorMessageClose={props.onCreateSpreadsheetMapErrorMessageClose}
          isLoading={props.isLoading}
          onSelect={onUploadItemSelect}
        />

        {props.createBlankMapErrorMessage && (
          <AlertComponent
            css={alertStyle}
            type="danger"
          >
            {props.createBlankMapErrorMessage}
          </AlertComponent>
        )}

        <span css={uploadMethodSeparatorStyle}>{t('or')}</span>

        <div css={secondaryUploadContainerStyle}>
          <UploadMethodComponent
            buttonLabel={t('Start Blank')}
            buttonStyle={ButtonStyle.Secondary}
            css={uploadMethodStyle}
            isDisabled={props.isLoading}
            onClick={onStartBlankClick}
            title={t('Manually create a New Map from scratch with no data set.')}
          />

          <UploadMethodComponent
            buttonLabel={t('Google Sheets')}
            buttonStyle={ButtonStyle.Success}
            css={uploadMethodStyle}
            isDisabled={props.isLoading}
            onClick={handleStartGoogleSheetsClick}
            title={t('Create a New Map with Google Sheets.')}
          />
        </div>
      </div>
    ),
  }), [props.isNewSalesRepMap, props.createSpreadsheetMapErrorMessage, props.onCreateSpreadsheetMapErrorMessageClose,
    props.isLoading, props.createBlankMapErrorMessage, t, isDataSourceOpen, theme, onUploadItemSelect, onStartBlankClick,
    handleStartGoogleSheetsClick,
  ]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={props.isNewSalesRepMap ? t('Create a Map That Identifies Sales Rep Locations') : t('Create New Map')}
      leftFooterContent={<ModalFooterSampleExcelComponent />}
      confirmButton={ModalConfirmButton}
      contentStyle={css({ padding: 0 })}
      isCloseDisabled={isMapCreationInProgress}
    >
      <OutlinePanelComponent outlines={[OutlinePanelOptions.Top]}>
        <AccordionComponent
          data={[mapOverview, dataSource]}
          itemButtonStyle={itemButtonStyle}
          showCount
        />
      </OutlinePanelComponent>
    </ModalComponent>
  );
};
