import {
  css,
  type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import { CSS_CLASSNAME as ROUND_BUTTON_CLASSNAME } from './constants';
import {
  getHoverCss as getRoundButtonHoverCss,
  getRoundButtonFontSize,
  RoundButtonComponent,
  type RoundButtonProps,
  RoundButtonSize,
} from './roundButton.component';
import { RoundButtonStyle } from './roundButton.styles';
import { getTextColorForButtonStyle } from './roundButtonWithText.helpers';

const TEXT_ITEM_OF_ROUND_BUTTON_CLASSNAME = 'text-item-of_round_button';

const wrapperStyle = ({ isDisabled, theme, btnStyle, btnSize, textColor, textHoverColor }: ThemeProps<{
  btnStyle: RoundButtonStyle; btnSize: RoundButtonSize; isDisabled?: boolean; textColor?: string; textHoverColor?: string;
}>) => {
  const hoverStyles = getRoundButtonHoverCss({ btnStyle, theme });
  const textColors = getTextColorForButtonStyle(btnStyle, theme);

  return css({
    alignItems: 'center',
    color: textColor || textColors.color,
    cursor: 'default',
    display: 'flex',
    fontSize: getRoundButtonFontSize(btnSize),
    gap: 8,

    ...(isDisabled ? {} : { '&:hover': {
      [`.${ROUND_BUTTON_CLASSNAME}`]: hoverStyles,
      [`.${TEXT_ITEM_OF_ROUND_BUTTON_CLASSNAME}`]: {
        color: textHoverColor || textColors.colorHover,
      },
    } }),
  });
};

export type RoundButtonWrapperProps = RoundButtonProps & Readonly<{
  isTextHidden?: boolean;
  text: string;
  textColor?: string;
  textHoverColor?: string;
  wrapperStyle?: SerializedStyles;

  onClick?: () => void;
}>;

export const RoundButtonWithTextComponent: FC<RoundButtonWrapperProps> = (props) => {
  const { isDisabled, onClick } = props;

  const theme = useTheme();

  const guaranteedBtnStyle = isDisabled ? RoundButtonStyle.Disabled : props.buttonStyle || RoundButtonStyle.Primary;
  const guaranteedSize = props.size || RoundButtonSize.Large;

  return (
    <span
      css={[wrapperStyle({
        theme,
        btnStyle: guaranteedBtnStyle,
        btnSize: guaranteedSize,
        isDisabled, textColor: props.textColor,
        textHoverColor: props.textHoverColor,
      }), props.wrapperStyle]}
      onClick={isDisabled ? undefined : onClick}
    >
      <RoundButtonComponent
        buttonStyle={guaranteedBtnStyle}
        className={props.className}
        icon={props.icon}
        isDisabled={isDisabled}
        onClick={undefined}
        size={guaranteedSize}
        tooltipLabel={props.tooltipLabel}
        tooltipPlacement={props.tooltipPlacement}
        tooltipBehavior={props.tooltipBehavior}
      />
      {!props.isTextHidden && (
        <div
          className={TEXT_ITEM_OF_ROUND_BUTTON_CLASSNAME}
        >
          {props.text}
        </div>
      )}
    </span>
  );
};
