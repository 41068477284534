import {
  useCallback, useState,
} from 'react';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';
import { MapListingDeleteStatus } from './actionsHeader/actionsHeader.component';
import { type MapListingItem } from './item/mapListingItem';
import { useMapDelete } from './useMapDelete';

type MapListingDeleteWithStatusArguments = {
  selectedMaps: ReadonlyArray<{ id: number; name: string }>;
  selectedMapIds: ReadonlyArray<number>;
  selectedSnapshotIds: ReadonlySet<number>;
  onSuccess: () => void;
  allMapListingItems: ReadonlyArray<MapListingItem>;
};

export const useMapListingDeleteWithStatus = ({ selectedMaps, selectedMapIds, selectedSnapshotIds, onSuccess, allMapListingItems }: MapListingDeleteWithStatusArguments) => {
  const [overrideStatus, setOverrideStatus] = useState<MapListingDeleteStatus | null>(null);

  const clientId = useClientIdSelector();
  const { isLoading: isMapDeleteLoading, deleteMap } = useMapDelete(clientId, allMapListingItems);

  let status: MapListingDeleteStatus = MapListingDeleteStatus.Active;

  if (selectedMapIds.length === 0 && selectedSnapshotIds.size === 0) {
    status = MapListingDeleteStatus.Disabled;
  }

  if (isMapDeleteLoading) {
    status = MapListingDeleteStatus.Loading;
  }

  const onDelete = () => {
    deleteMap(selectedMaps, Array.from(selectedSnapshotIds), () => {
      onSuccess();
      setOverrideStatus(MapListingDeleteStatus.Success);
    });
  };

  const resetStatus = useCallback(() => {
    setOverrideStatus(null);
  }, []);

  return {
    status: overrideStatus !== null ? overrideStatus : status,
    onDelete,
    resetStatus,
  };
};
