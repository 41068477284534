import {
  type FC,
  memo,
  useEffect,
  useMemo,
} from 'react';
import { type GroupRadiusProximity } from '~/_shared/types/proximity/proximity.types';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useGroupProximityZIndexes } from '~/map/zIndexes/useGroupProximityZIndex.hook';
import { type SpreadsheetLatLngRowData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type SpreadsheetDataData } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  type GroupProximityInput,
  type MapProximityManager,
} from './mapProximityManager';
import { type GroupRadiusProximityLocations } from './useProximityLocation';

type ProximityInstanceCircleGroupProps = {
  manager: MapProximityManager;
  proximity: GroupRadiusProximity;
  isVisible: boolean;
  spreadsheetData: SpreadsheetDataData;
  latLngData: SpreadsheetLatLngRowData;
  locations: GroupRadiusProximityLocations;
  zoom: number;
  circlesWithLabels: ReadonlyArray<{ spreadsheetRowId: SpreadsheetRowId }>;
};

const noLabels = [] as const;

export const ProximityInstanceCircleGroup: FC<ProximityInstanceCircleGroupProps> = memo(props => {
  const hideLabel = useSelector(s => s.map.mapSettings.data.proximity.hideLabels);
  // TODO: only add per circle hover support, but it will need to be optimized
  // as the whole collection below cannot be recalculated on every hover, as with 15k circles it will be slow
  // const proximityWithExtraStyles = useProximityExtraStyles(props.proximity);

  const zIndexes = useGroupProximityZIndexes(() => ({
    rowIds: props.locations.map(loc => loc.spreadsheetRowId.rowId),
    proximityId: props.proximity.id,
  }), [props.locations, props.proximity.id]);

  const circlesWithLabels = hideLabel ? noLabels : props.circlesWithLabels;

  const data = useMemo(() => (
    props.locations.map((loc) => ({
      rowId: loc.spreadsheetRowId,
      latLng: loc.latLng,
      zIndex: zIndexes.get(loc.spreadsheetRowId.rowId)?.zIndex,
    })).filter((d): d is GroupProximityInput => !!d.zIndex)
  ), [props.locations, zIndexes]);

  useEffect(() => {
    props.manager.renderProximityGroupRadius(props.proximity, props.isVisible, data);
  }, [data, hideLabel, props.isVisible, props.locations, props.manager, props.proximity, zIndexes]);

  useEffect(() => {
    props.manager.renderProximityGroupRadiusLabels(props.proximity, props.isVisible, data, circlesWithLabels);
  }, [circlesWithLabels, data, props.isVisible, props.manager, props.proximity]);

  // Unmount cleanup
  useEffect(() => {
    return () => {
      props.manager.removeProximityCircle(props.proximity.id);
    };
  }, [props.manager, props.proximity.id]);

  return null;
});
