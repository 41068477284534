import { type AddLocationAction } from './addLocation.action';
import {
  FRONTEND_STATE_ADD_LOCATION_LOADING,
  FRONTEND_STATE_ADD_LOCATION_START,
  FRONTEND_STATE_ADD_LOCATION_STOP,
} from './addLocation.actionTypes';
import { type AddLocationState } from './addLocation.state';

const initialState: AddLocationState = {
  active: false,
  isLoading: false,
};

export const addLocationReducer = (state = initialState, action: AddLocationAction): AddLocationState => {
  switch (action.type) {

    case FRONTEND_STATE_ADD_LOCATION_START: {
      return {
        ...state,
        active: true,
      };
    }

    case FRONTEND_STATE_ADD_LOCATION_STOP: {
      return {
        ...state,
        active: false,
      };
    }

    case FRONTEND_STATE_ADD_LOCATION_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default: {
      return state;
    }
  }
};
