import { parseDate } from '~/_shared/utils/date/date.helpers';
import { MemberRoles } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { userSetDistanceMatrixUsage } from '~/store/userData/userData.actionCreators';
import { type UserDataSettingsAction } from './settings/userDataSettings.action';
import {
  USER_SETTINGS_MAP_DROPDOWN_UPDATE, USER_SETTINGS_SPLIT_PANEL_CLOSE, USER_SETTINGS_SPLIT_PANEL_OPEN,
  USER_SETTINGS_SYNC_FAIL, USER_SETTINGS_SYNC_SUCCESS, USER_SETTINGS_THEME_UPDATE,
} from './settings/userDataSettings.actionTypes';
import { userDataSettingsReducer } from './settings/userDataSettings.reducer';
import { type UserDataAction } from './userData.action';
import {
  USER_AUTH_EXPIRE, USER_DATA_SETTING_READY_SET, USER_GET_DATA_ERROR, USER_GET_DATA_REQUEST, USER_GET_DATA_SUCCESS,
  USER_GOOGLE_UNAUTHORIZED, USER_SET_CLIENT_ID, USER_SET_CLIENT_LIMIT_WMS_ENABLED,
  USER_SET_CURRENT_CLIENT_LICENSE_AUTO_RENEWAL, USER_SIGN_IN_ERROR, USER_SIGN_IN_REQUEST, USER_SIGN_OUT_SUCCESS,
  USER_UPDATE_TWO_FACTOR_SUCCESS,
} from './userData.actionTypes';
import {
  Roles, type UserDataState,
} from './userData.state';

const initialUsageData = {
  realGeocodes: 0,
  publicMapViews: 0,
  distanceMatrixRequests: 0,
};

const initialUserLimits = {
  cachedGeocodes: 0,
  canExportSharedMapsData: false,
  directionsApiRequests: 0,
  distanceMatrixRequests: 0,
  googlePlacesRequests: 0,
  mapView: 0,
  maps: 0,
  markers: 0,
  realGeocodes: 0,
  userLimit: 1,
  wmsEnabled: false,
};

const initialState: UserDataState = {
  '2fa': {
    email: false,
    google: false,
  },
  clientId: null,
  clientMembers: [],
  clientName: null,
  clientRole: MemberRoles.INDEPENDENT_MEMBER,
  createdAt: '',
  currentClient: null,
  email: '',
  errorMessage: '',
  firstName: '',
  googleApiOauth: null,
  id: null,
  intercomHash: '',
  isLoading: false,
  isPublicApiActive: false,
  isReady: false,
  isSignedIn: false,
  lastName: '',
  limits: initialUserLimits,
  previousLogin: null,
  role: Roles.NA,
  settings: {},
  teamId: null,
  usage: initialUsageData,
};

export const userDataReducer = (state: UserDataState = initialState, action: UserDataAction | UserDataSettingsAction): UserDataState => {
  if (userSetDistanceMatrixUsage.match(action)) {
    return {
      ...state,
      usage: {
        ...state.usage,
        distanceMatrixRequests: action.payload.count,
      },
    };
  }

  switch (action.type) {
    case USER_SIGN_IN_REQUEST:
    case USER_GET_DATA_REQUEST: {
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      };
    }

    case USER_GET_DATA_SUCCESS: {
      const previousLoginString = action.payload.data.previousLogin;

      return {
        ...state,
        '2fa': action.payload.data['2fa'],
        clientId: action.payload.data.clientId,
        clientMembers: action.payload.data.clientMembers,
        clientName: action.payload.data.clientName,
        clientRole: action.payload.data.clientRole,
        createdAt: action.payload.data.createdAt,
        currentClient: action.payload.data.currentClient,
        email: action.payload.data.email,
        firstName: action.payload.data.firstName,
        googleApiOauth: action.payload.data.googleApiOauth,
        id: action.payload.data.id,
        intercomHash: action.payload.data.intercomHash,
        isLoading: false,
        isPublicApiActive: action.payload.data.isPublicApiActive,
        isReady: true,
        isSignedIn: true,
        lastName: action.payload.data.lastName,
        limits: action.payload.data.limits,
        previousLogin: previousLoginString ? parseDate(previousLoginString) : null,
        role: action.payload.data.role,
        settings: action.payload.data.settings,
        teamId: action.payload.data.teamId,
        usage: action.payload.data.usage,
      };
    }

    case USER_SET_CLIENT_ID: {
      return {
        ...state,
        clientId: action.payload.clientId,
      };
    }

    case USER_GET_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        isSignedIn: false,
        isReady: false,
      };
    }

    case USER_SIGN_IN_ERROR: {
      return {
        ...state,
        isSignedIn: false,
        isLoading: false,
        isReady: false,
        errorMessage: action.payload.message,
      };
    }

    case USER_SIGN_OUT_SUCCESS: {
      return {
        ...state,
        isSignedIn: false,
        isReady: false,
      };
    }

    case USER_AUTH_EXPIRE: {
      return {
        ...initialState,
        // do not reset clientId, needed for presentational maps where user auth is not required
        clientId: state.clientId,
      };
    }

    case USER_DATA_SETTING_READY_SET: {
      return {
        ...state,
        isReady: action.payload.isReady,
      };
    }

    case USER_SETTINGS_SYNC_SUCCESS:
    case USER_SETTINGS_SYNC_FAIL: {
      return {
        ...state,
        isReady: true,
      };
    }

    case USER_SETTINGS_THEME_UPDATE:
    case USER_SETTINGS_MAP_DROPDOWN_UPDATE:
    case USER_SETTINGS_SPLIT_PANEL_CLOSE:
    case USER_SETTINGS_SPLIT_PANEL_OPEN: {
      return userDataSettingsReducer(state, action);
    }

    case USER_UPDATE_TWO_FACTOR_SUCCESS: {
      return {
        ...state,
        '2fa': action.payload.data,
      };
    }

    case USER_GOOGLE_UNAUTHORIZED: {
      return {
        ...state,
        googleApiOauth: null,
      };
    }

    case USER_SET_CURRENT_CLIENT_LICENSE_AUTO_RENEWAL: {
      return {
        ...state,
        currentClient: state.currentClient ? {
          ...state.currentClient,
          license: {
            ...state.currentClient.license,
            autoRenewal: action.payload.isAutoRenewalEnabled,
          },
        } : null,
      };
    }

    case USER_SET_CLIENT_LIMIT_WMS_ENABLED: {
      return {
        ...state,
        limits: { ...state.limits, wmsEnabled: action.payload.wmsEnabled },
      };
    }

    default: {
      return state;
    }
  }
};
