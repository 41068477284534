import { css } from '@emotion/react';
import {
  type FC,
  useMemo,
  useState,
} from 'react';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { ToolboxContainerComponent } from '~/_shared/components/toolboxContainer/toolboxContainer.component';
import { always } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { type SidebarAppProps } from '~/sidebar/sidebarAppComponentProps.type';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { AdvancedBaseMapStylesComponent } from './accordion/baseMap/advancedBaseMap/advancedBaseMapStyles.component';
import { type BaseMapSettingsProps } from './accordion/baseMap/baseMap.types';
import { type MapMarkersGraphicsSettingsStructureProps } from './accordion/mapMarkersGraphics/mapMarkersGraphics.types';
import { mapSettingsAccordionStructure } from './accordion/mapSettingsAccordion.structure';
import { MapStylesComponent } from './accordion/mapStyles.component';
import { type OtherSettingsStructureProps } from './accordion/otherSettings/otherSettings.types';
import { SettingsAccordionComponent } from './accordion/settingsAccordion.component';
import {
  type SettingsAccordionItem,
  SettingsAccordionSubItemType,
} from './accordion/settingsAccordionData.type';
import { type SharedPresentationalMapsStructureProps } from './accordion/sharedPresentationalMaps/sharedPresentationalMaps.types';
import { SaveMapInfoContainer } from './saveMapInfo/saveMapInfo.container';

const holderStyle = css({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
});

type MapSettingsProps = SidebarAppProps & Readonly<{
  baseMapSettingsProps: BaseMapSettingsProps;
  isLoading: boolean;
  mapMarkersGraphicsProps: MapMarkersGraphicsSettingsStructureProps;
  otherSettingsProps: OtherSettingsStructureProps;
  sharedPresentationMapProps: SharedPresentationalMapsStructureProps;
}>;

export const MapSettingsComponent: FC<MapSettingsProps> = props => {
  const [expandedItemIndices, setExpandedItemIndices] = useState<ReadonlyArray<number>>([]);
  const [expandedColorIndices, setExpandedColorIndices] = useState<Set<string>>(new Set());
  const [t] = useTranslation();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();

  const { areExpandedBaseSettingsOpen, baseMapSettings, isMapThemeSelectorOpen,
    selectedMapTheme, standardBaseMapSettingsDataProps, onChangeBaseMapAdvanced, onElementTypeChange,
    onFeatureTypeChange, onMapAdvancedClose, onMapThemeSelect, onMapThemeSelectorClose, onResetBaseMapStyle } = props.baseMapSettingsProps;

  const preparedData: ReadonlyArray<SettingsAccordionItem> = useMemo(() => (
    mapSettingsAccordionStructure({
      t,
      baseMapProps: standardBaseMapSettingsDataProps,
      mapMarkersGraphicsProps: props.mapMarkersGraphicsProps,
      sharedPresentationMapProps: props.sharedPresentationMapProps,
      otherSettingsProps: props.otherSettingsProps,
      isLayeredMapConnected,
    })
      .map((item, index) => ({
        ...item,
        isExpanded: expandedItemIndices.includes(index),
        onExpand: () => setExpandedItemIndices([...expandedItemIndices, index]),
        onCollapse: () => setExpandedItemIndices(expandedItemIndices.filter(i => i !== index)),
        subItems: item.subItems?.map((subItem, subIndex) => {
          switch (subItem.type) {
            case SettingsAccordionSubItemType.Button:
              return {
                ...subItem,
                onClick: () => {
                  subItem.onClick?.();
                },
              };
            case SettingsAccordionSubItemType.ColorList:
              return {
                ...subItem,
                accordion: {
                  isExpanded: expandedColorIndices.has(`${index}.${subIndex}`),
                  onCollapse: () => setExpandedColorIndices(state => {
                    state.delete(`${index}.${subIndex}`);
                    return new Set(state);
                  }),
                  onExpand: () => setExpandedColorIndices(state => new Set(state.add(`${index}.${subIndex}`))),
                  subItems: Object.values(subItem.colors).map(color => ({
                    ...color,
                    type: SettingsAccordionSubItemType.ColorPicker,
                    infoTooltip: null,
                    disabledTooltipSelector: always({ message: null }),
                  })),
                  title: subItem.label,
                },
              };
            case SettingsAccordionSubItemType.Toggle:
            case SettingsAccordionSubItemType.ColorPicker:
            case SettingsAccordionSubItemType.Slider:
            case SettingsAccordionSubItemType.DropDown:
              return subItem;

            default:
              throw new Error('unknown type inside the mapSettings component');
          }
        }) ?? [],
      }))
  ), [expandedColorIndices, expandedItemIndices, isLayeredMapConnected, props.mapMarkersGraphicsProps,
    props.otherSettingsProps, props.sharedPresentationMapProps, standardBaseMapSettingsDataProps, t,
  ]);

  return (
    <ToolboxContainerComponent
      title={t('Map Settings')}
      onClose={props.onClose}
    >
      <MapStylesComponent
        isOpen={isMapThemeSelectorOpen}
        onClose={onMapThemeSelectorClose}
        isLoading={props.isLoading}
        onImageSelect={onMapThemeSelect}
        selectedId={selectedMapTheme}
      />

      <OverlayWrapperComponent css={holderStyle}>
        <ScrollBarComponent
          type={ScrollbarType.Vertical}
        >
          {areExpandedBaseSettingsOpen ? (
            <AdvancedBaseMapStylesComponent
              baseMapSettingsChangelist={props.baseMapSettingsProps.baseMapSettingsChangelist}
              settings={baseMapSettings}
              onBack={onMapAdvancedClose}
              onChange={onChangeBaseMapAdvanced}
              onChangeElementType={onElementTypeChange}
              onChangeFeatureType={onFeatureTypeChange}
              onResetStyle={onResetBaseMapStyle}
            />
          ) : (
            <>
              <SaveMapInfoContainer />

              <SettingsAccordionComponent items={preparedData} />
            </>
          )}

        </ScrollBarComponent>
        {props.isLoading && <OverlayLoaderComponent />}
      </OverlayWrapperComponent>
    </ToolboxContainerComponent>
  );
};
