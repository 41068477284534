import {
  type FC,
  useCallback, useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  AccordionComponent, type AccordionData,
} from '~/_shared/baseComponents/accordion';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import {
  alertStyle,
  contentStyle,
  itemButtonStyle,
} from '~/_shared/components/exportDataModal/exportDataModal.styles';
import {
  ExportMethodInputsComponent, type Method,
} from '~/_shared/components/exportDataModal/exportMethodInputs.component';
import { ClientLicenseType } from '~/_shared/types/client/license';
import { useTranslation } from '~/_shared/utils/hooks';
import { ExportDataFileType } from '~/data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { closeAllModals } from '~/store/modal/modal.actionCreators';
import { useUserCurrentClientSelector } from '~/store/userData/userData.selectors';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';

export type ExportDataModalResults = Method;

export type ExportDataModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: ExportDataModalResults) => Promise<void> | undefined;
  sectionsBeforeExportMethod?: AccordionData[];
  sectionsAfterExportMethod?: AccordionData[];
  isLoading?: boolean;
  caption?: string;
  isSubmitDisabled?: boolean;
  error?: string | null;
  showCopiedToClipboardTooltip?: boolean;
  noticeTrialUsers?: boolean;
  showCount?: boolean;
}>;

export const ExportDataModalComponent: FC<ExportDataModalProps> = ({ onSubmit, sectionsAfterExportMethod, sectionsBeforeExportMethod = [], ...props }) => {
  const [t] = useTranslation();
  const [method, setMethod] = useState(ExportDataMethod.File);
  const [fileType, setFileType] = useState(ExportDataFileType.Xlsx);
  const [isExportMethodExpanded, setIsExportMethodExpanded] = useState(true);
  const dispatch = useDispatch();
  const { openModal: openTrialExportNoticeModal } = useModal(ModalType.TrialExportNotice);

  const currentClient = useUserCurrentClientSelector();
  const isTrialLicense = currentClient?.license.type === ClientLicenseType.TRIAL;

  const accordionData = useMemo(() => [...sectionsBeforeExportMethod, {
    header: t('Select Export Method'),
    isLocked: (sectionsAfterExportMethod ?? []).length === 0 && (sectionsBeforeExportMethod ?? []).length === 0,
    isExpanded: isExportMethodExpanded,
    onHeadingClick: () => {
      setIsExportMethodExpanded(!isExportMethodExpanded);
    },
    child: (
      <div>
        <ExportMethodInputsComponent
          method={method}
          onMethodChanged={setMethod}
          fileType={fileType}
          onFileTypeChanged={setFileType}
        />

        {props.error && (
          <AlertComponent
            css={alertStyle}
            type="danger"
          >
            {props.error}
          </AlertComponent>
        )}
      </div>
    ),
  }, ...(sectionsAfterExportMethod ?? [])], [sectionsAfterExportMethod, sectionsBeforeExportMethod, props.error, method, fileType, t, isExportMethodExpanded]);

  const handleExport = useCallback(() => {
    if (props.noticeTrialUsers && isTrialLicense) {
      dispatch(closeAllModals());
      openTrialExportNoticeModal({
        exportMethod: method,
        onExport: () => {
          return onSubmit({ method, fileType });
        },
      });
    }
    else {
      onSubmit({ method, fileType });
    }
  }, [dispatch, fileType, isTrialLicense, method, onSubmit, openTrialExportNoticeModal, props.noticeTrialUsers]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={props.caption ?? t('Export Data')}
      maxWidth={600}
      confirmButton={(
        <TooltipComponent
          tooltipContent={props.showCopiedToClipboardTooltip ? t('Copied to Clipboard!') : null}
          behavior={TooltipBehavior.ShowAlways}
        >
          <ButtonComponent
            text={t('Export Now')}
            onClick={handleExport}
            isDisabled={props.isLoading || props.isSubmitDisabled}
          />
        </TooltipComponent>
      )}
      contentStyle={contentStyle}
      additionalContent={(
        <>
          {props.isLoading &&
            <OverlayLoaderComponent />
          }
        </>
      )}
    >
      <AccordionComponent
        data={accordionData}
        itemButtonStyle={itemButtonStyle}
        showCount={props.showCount ?? accordionData.length > 1}
      />
    </ModalComponent>
  );
};
