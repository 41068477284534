import { UnitSystem } from '../../../_shared/types/googleMaps/googleMaps.types';

const MILES_TO_METERS = 1.609347;

export const calculateRadiusInMeters = (radius: number, unit: UnitSystem): number => {
  const result = unit === UnitSystem.metric ? radius : radius * MILES_TO_METERS;

  // request require radius in meters
  return Math.round(result * 1000);
};
