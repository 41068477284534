import { type SortableItem } from '~/_shared/components/sortable/sortable.component';
import {
  type RouteLeg, UnitSystem,
} from '~/_shared/types/googleMaps/googleMaps.types';
import { getLast } from '~/_shared/utils/array/array.helpers';
import { delay } from '~/_shared/utils/delay';
import { getUserPosition } from '~/_shared/utils/geolocation/geolocation.helpers';
import {
  notNull, notNullsy,
} from '~/_shared/utils/typeGuards';
import { parseWaypointId } from '~/directions/directions.helper';
import { type Waypoint } from '~/store/frontendState/mapTools/directions/directions.state';
import { i18n } from '../i18nextSetup';
import { orderWaypointsByApproximation } from './tspSolver/orderWaypointsByApproximation';

type NotFoundData = Readonly<{
  status: google.maps.DirectionsStatus.NOT_FOUND;
  failedAddresses: ReadonlyArray<string>;
}>;
type WithoutData = Readonly<{
  status: Exclude<google.maps.DirectionsStatus, google.maps.DirectionsStatus.NOT_FOUND>;
}>;

type DirectionsPromiseMap = Record<string, Promise<DirectionsResult>>;

export type FindRouteFailedData = NotFoundData | WithoutData;

export const maxRequestWaypoints = 20;
// export const maxRequestWaypoints = 4;
export const maxTotalWaypoints = 73;
export type DirectionsSuccessResult = Readonly<{
  legs: ReadonlyArray<SortableItem<RouteLeg>>;
  copyrights: string;
  apiResponses: ReadonlyArray<google.maps.DirectionsResult>;
  bounds: google.maps.LatLngBounds;
}>;

export type DirectionsResult = {
  readonly success: DirectionsSuccessResult;
  readonly error?: never;
} | {
  readonly success?: never;
  readonly error: FindRouteFailedData;
};

const calculateNextRetryTimeout = (attempt: number) =>
  1000 + attempt * 100 + Math.floor(Math.random() * 150);

const getRequestHash = (waypoints: ReadonlyArray<Waypoint>, optimize: boolean) => {
  return `${waypoints.map(w => parseWaypointId(w.id).pointId).join('|')}|${optimize}`;
};

const getUserPositionWaypoint = (id: string): Promise<Waypoint> =>
  getUserPosition()
    .then(p => ({ id, address: null, latLng: { lat: p.coords.latitude, lng: p.coords.longitude } }));

const countBounds = (legs: ReadonlyArray<SortableItem<RouteLeg>>): google.maps.LatLngBounds =>
  [legs[0], ...legs]
    .filter(notNullsy)
    .map((leg, i) => i === 0 ? leg.data.start_location : leg.data.end_location)
    .reduce((result: google.maps.LatLngBounds, place) => result.extend(place), new google.maps.LatLngBounds());

const getGoogleSdk = (): Promise<typeof google> => {
  return new Promise(resolve => {
    if (google) {
      resolve(google);
    }

    const intervalId = setInterval(() => {
      if (!google) {
        return;
      }

      clearInterval(intervalId);
      resolve(google);
    }, 500);
  });
};

class DirectionsService {
  private directionsService: google.maps.DirectionsService;
  private readonly unitSystem: google.maps.UnitSystem;
  private readonly serviceCallMap: DirectionsPromiseMap = {};

  constructor(unitSystem: UnitSystem) {
    this.unitSystem = unitSystem === UnitSystem.metric ? google.maps.UnitSystem.METRIC : google.maps.UnitSystem.IMPERIAL;
  }

  private getDirectionsService = async (): Promise<google.maps.DirectionsService> => {
    if (this.directionsService) {
      return this.directionsService;
    }
    const googleSdk = await getGoogleSdk();

    return new googleSdk.maps.DirectionsService();
  };

  private callService = (waypoints: ReadonlyArray<Waypoint>, optimize = false, attempt = 1): Promise<DirectionsResult> => {
    const waypointsToSend = waypoints
      .map(w => w.latLng ? new google.maps.LatLng(w.latLng.lat, w.latLng.lng) : w.address);

    const hash = getRequestHash(waypoints, optimize);
    const alreadyRunningPromise = this.serviceCallMap[hash];

    if ((attempt === 1) && alreadyRunningPromise) {
      return alreadyRunningPromise;
    }

    const resultPromise = new Promise<DirectionsResult>((resolve) => {
      this.getDirectionsService()
        .then(directionsService => {
          const origin = waypointsToSend[0];
          const destination = getLast(waypointsToSend);

          if (!origin || !destination) {
            throw new Error(`${__filename}: Direction service called with invalid waypoint array: '${JSON.stringify(waypoints)}'.`);
          }

          return directionsService.route({
            waypoints: waypointsToSend.map(location => ({ location, stopover: true })).slice(1, waypoints.length - 1),
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
            optimizeWaypoints: optimize,
            unitSystem: this.unitSystem,
            language: i18n.language,
          },
          (result, status) => {
            if (result) {
              const firstRoute = result.routes[0];
              switch (status) {
                case google.maps.DirectionsStatus.OVER_QUERY_LIMIT:
                  delay(calculateNextRetryTimeout(attempt))
                    .then(() => this.callService(waypoints, optimize, attempt + 1).then(resolve));
                  return;
                case google.maps.DirectionsStatus.OK: {
                  if (!firstRoute) {
                    throw new Error(`${__filename}: Direction service returned '${status}' with the following routes: '${JSON.stringify(result.routes)}'.`);
                  }
                  // We need to use a different type because of bad google map sdk types (at least in the current version).
                  const routeLegs = firstRoute.legs as unknown as ReadonlyArray<RouteLeg>;
                  if (optimize) {
                    const legs = [-1, ...firstRoute.waypoint_order]
                      .map((waypointIndex, i) => {
                        const leg = routeLegs[i];
                        const waypoint = waypoints[waypointIndex + 1];
                        if (!leg || !waypoint) {
                          return null;
                        }
                        return { id: waypoint.id, data: leg };
                      }).filter(notNull);

                    resolve({
                      success: {
                        legs,
                        copyrights: firstRoute.copyrights,
                        apiResponses: [result],
                        bounds: countBounds(legs),
                      },
                    });
                    return;
                  }
                  const legs = waypoints.slice(0, waypoints.length - 1)
                    .map(({ id }, i) => {
                      const leg = routeLegs[i];
                      if (!leg) {
                        return null;
                      }
                      return { id, data: leg };
                    }).filter(notNull);
                  resolve({
                    success: {
                      legs,
                      copyrights: firstRoute.copyrights,
                      apiResponses: [result],
                      bounds: countBounds(legs),
                    },
                  });

                  return;
                }
                case google.maps.DirectionsStatus.NOT_FOUND: {
                  const rejectData: NotFoundData = {
                    status,
                    failedAddresses: result.geocoded_waypoints ? result.geocoded_waypoints
                      .map((w, i) => {
                        const waypoint = waypoints[i];
                        if (!waypoint) {
                          return null;
                        }
                        return { ...w, name: waypoint.address };
                      })
                      .filter(notNull)
                      .filter(w => (w as any).geocoder_status !== 'OK')
                      .map(w => w.name ?? '') : [],
                  };
                  resolve({ error: rejectData });
                  return;
                }
                default:
                  console.error('Failed to load directions from google maps api. Status: ', status);
                  resolve({ error: { status } });
              }
            }
          });
        });
    });

    this.serviceCallMap[hash] = resultPromise;
    resultPromise.catch(() => {
      delete this.serviceCallMap[hash];
    });
    return resultPromise;
  };

  private findDirectionsInMultipleCalls = (waypoints: ReadonlyArray<Waypoint>, optimize = false): Promise<DirectionsResult> => {
    return waypoints.length < 2
      ? Promise.resolve({ success: { legs: [], copyrights: '', apiResponses: [], bounds: new google.maps.LatLngBounds() } })
      : this.callService(waypoints.slice(0, maxRequestWaypoints), optimize)
        .then(res => {
          if (res.error) {
            return res;
          }
          else {
            return this.findDirectionsInMultipleCalls(waypoints.slice(maxRequestWaypoints - 1), optimize)
              .then(innerRes => {
                if (innerRes.error) {
                  return innerRes;
                }

                return ({
                  success: {
                    legs: [...res.success.legs, ...innerRes.success.legs],
                    apiResponses: [...res.success.apiResponses, ...innerRes.success.apiResponses],
                    copyrights: res.success.copyrights,
                    bounds: res.success.bounds.union(innerRes.success.bounds),
                  },
                });
              });
          }
        });
  };

  private findDirectionsWithoutOptimize = (waypoints: ReadonlyArray<Waypoint>): Promise<DirectionsResult> => {
    return this.findDirectionsInMultipleCalls(waypoints);
  };

  private findDirectionsWithOptimize = (waypoints: ReadonlyArray<Waypoint>): Promise<DirectionsResult> => {
    return orderWaypointsByApproximation(waypoints)
      .then(w => this.findDirectionsInMultipleCalls(w, true));
  };

  public findDirections = async (waypoints: ReadonlyArray<Waypoint>, optimize: boolean, shouldReplaceFirstWithUserLocation: boolean): Promise<DirectionsResult> => {
    const firstWaypoint = waypoints[0];
    if (!firstWaypoint) {
      throw new Error(`${__filename}: No waypoint found in provided array: '${JSON.stringify(waypoints)}'.`);
    }
    const withCurrentLoc = shouldReplaceFirstWithUserLocation
      ? [await getUserPositionWaypoint(firstWaypoint.id), ...waypoints.slice(1)]
      : waypoints;

    if (!optimize) {
      return this.findDirectionsWithoutOptimize(withCurrentLoc);
    }
    else if (withCurrentLoc.length <= maxRequestWaypoints) {
      return this.callService(withCurrentLoc, true);
    }
    else if (withCurrentLoc.length > maxTotalWaypoints) {
      return Promise.reject(`Cannot find optimized directions for more than ${maxTotalWaypoints} waypoints.`);
    }
    else {
      return this.findDirectionsWithOptimize(withCurrentLoc);
    }
  };
}

const directionServiceInstances: Record<UnitSystem, DirectionsService | undefined> = {
  [UnitSystem.imperial]: undefined,
  [UnitSystem.metric]: undefined,
};

export const getDirectionsService = (unitSystem: UnitSystem) => {
  const existingInstance = directionServiceInstances[unitSystem];
  if (existingInstance) {
    return existingInstance;
  }

  const newInstance = new DirectionsService(unitSystem);
  directionServiceInstances[unitSystem] = newInstance;

  return newInstance;
};
