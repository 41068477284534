import numeral from 'numeral';
import { isNullsy } from '~/_shared/utils/typeGuards';
import {
  BOUNDARY_MAX_VALUE_ID, BOUNDARY_MIN_VALUE_ID,
} from '~/boundary/settings/boundarySettings.helpers';
import { MAX_FRACTIONAL_PART_LENGTH } from '../number/number.helpers';

export enum RangeType {
  Value = 'value',
  Percentage = 'percentage'
}

export type NumericalRange = {
  from: number;
  to: number;
};

export const getLowestRequiredResolution = (newValueTo: number, max: number | typeof BOUNDARY_MAX_VALUE_ID, itemIndex: number, itemsCount: number): number | null => {
  if (max === BOUNDARY_MAX_VALUE_ID) {
    return null;
  }

  const distance = max - newValueTo;
  const countOfItemsLeft = itemsCount - (itemIndex + 1);

  if (distance < 0) {
    return null;
  }

  const itemSize = distance / countOfItemsLeft;

  for (let i = 0; i <= MAX_FRACTIONAL_PART_LENGTH; i++) {
    const currentResolution = getResolutionFromDecimalPlace(i);

    if (currentResolution * countOfItemsLeft <= itemSize) {
      return currentResolution;
    }
  }

  return null;
};

export const roundValueToResolution = (value: number, resolution: number): number => {
  const decimalPlace = getDecimalPlaceFromValue(resolution);

  return +value.toFixed(decimalPlace);
};

export const getDecimalPlaceFromValue = (value: number): number => {
  const decimalValue = value.toString().split('.');
  if (decimalValue.length === 2 && decimalValue[1]) {
    return decimalValue[1].length;
  }

  // calculate decimal values for potential scientific notation
  const notationExponent = value.toString().split('e-')[1];
  if (notationExponent) {
    const scientificNotationDecimalPlace = parseInt(notationExponent, 10);

    if (!isNaN(scientificNotationDecimalPlace)) {
      return scientificNotationDecimalPlace;
    }
  }

  return 0;
};

export const getResolutionFromDecimalPlace = (resolutionPower: number): number => {
  if (resolutionPower > MAX_FRACTIONAL_PART_LENGTH) {
    resolutionPower = MAX_FRACTIONAL_PART_LENGTH;
  }

  return +Math.pow(10, -1 * resolutionPower).toFixed(resolutionPower);
};

export const getLowestResolution = (values: number[]): number => {
  let resolutionPower: number = 1;

  for (const value of values) {
    const valueResolution = getDecimalPlaceFromValue(value);

    if (valueResolution > resolutionPower) {
      resolutionPower = valueResolution;
    }
  }

  return getResolutionFromDecimalPlace(resolutionPower);
};

export const formatValue = (value: string | number): string => {
  let format = '0,0.';

  for (let i = 0; i < MAX_FRACTIONAL_PART_LENGTH; i++) {
    format += '0';
  }

  return numeral(value).format(format)
    .replace(/(\.\d*?[1-9])0+$/g, '$1') // remove trailing zeros
    .replace(/\.0+$/g, ''); // remove all decimal zeros if value is an integer
};

export const getDefaultResolution = (min: number | typeof BOUNDARY_MIN_VALUE_ID, max: number | typeof BOUNDARY_MAX_VALUE_ID): number => {
  if (min === BOUNDARY_MIN_VALUE_ID || max === BOUNDARY_MAX_VALUE_ID) {
    return 2;
  }

  const potentialDecimalValues: number[] = [];

  potentialDecimalValues.push(min, max);

  let resolutionPower = 0;

  for (const number of potentialDecimalValues) {
    const currentResolutionPower = getDecimalPlaceFromValue(number);
    if (currentResolutionPower > resolutionPower) {
      resolutionPower = currentResolutionPower;
    }
  }

  return getResolutionFromDecimalPlace(resolutionPower + 2);
};

export const getPercentageValueFromNumerical = (value: number, min: number, max: number): number => {
  const range = max - min;

  if (range === 0) {
    return 0;
  }
  else {
    return (value - min) / range * 100;
  }
};

export const getNumericalValueFromPercentage = (percentage: number, min: number, max: number): number => {
  const range = max - min;

  return (percentage / 100) * range + min;
};

export const recalculateRanges = (ranges: NumericalRange[], min: number, max: number, isDecimalMode: boolean): NumericalRange[] => {
  const prevMax = ranges[ranges.length - 1]?.to;
  const prevMin = ranges[0]?.from;

  if (isNullsy(prevMax) || isNullsy(prevMin)) {
    return [];
  }
  const range = Math.abs(prevMax - prevMin);
  const newDataRows = [...ranges];
  const resolution = isDecimalMode ? getDefaultResolution(min, max) : 1;

  for (let i = 0; i < ranges.length; i++) {
    const rangeI = ranges[i];
    if (!rangeI) {
      continue;
    }
    const { to, from } = rangeI;
    const distance: number = Math.abs(to - from) / range * Math.abs(max - min);
    const newDataRowTo = newDataRows[i - 1]?.to;

    const fromValue: number = isNullsy(newDataRowTo) ? min : roundValueToResolution(newDataRowTo, resolution);
    const toValue: number = i === ranges.length - 1 ?
      max :
      roundValueToResolution(fromValue + distance, resolution);

    newDataRows[i] = {
      from: fromValue,
      to: toValue,
    };
  }

  return newDataRows;
};
