import { type Geometry } from '~/_shared/types/polygon/polygon.types';
import { blankBoundaryStyle } from '~/boundary/settings/defaultBoundarySettings';
import {
  type BoundaryGroupId, type BoundaryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { resolveBoundaryDisplayName } from '~/store/boundaryItems/boundaryItems.factory';
import { type FilterTreeItemRequest } from '~/store/spreadsheetData/filtering/spreadsheetDataFiltering.helpers';
import {
  apiDelete, apiGet, apiPost, apiPut,
} from '../../_shared/utils/api/api.helpers';
import { isNotFoundApiError } from '../../_shared/utils/api/apiError.helpers';
import {
  type BoundaryListItem, type BoundaryListItemStyle,
} from '../../sidebar/sidebarApps/mapTools/boundary/boundaryListing/boundaryListing.container';
import { type MapBoundaryQueryItemResponse } from '../boundaryItems/boundaryItems.repository';
import { type MetricServerModel } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { createBoundariesListItemFromResponse } from './boundaries.factory';

export type BoundaryItemResponseBase = {
  display_name: string;
  boundary_id: number;
  settings: BoundaryItemsSettingsBaseResponse;
};

type BoundaryItemSettingsStyleResponse = {
  line_width: number;
  line_color: string;
  color: string;
  opacity: number;
};

type BoundaryItemsSettingsBaseResponse = {
  is_artificial?: boolean;
  style?: BoundaryItemSettingsStyleResponse;
  translate?: boolean;
  translate_params?: { from?: number; to?: number; unit?: string; position?: number } | null;
};

export enum BoundaryDeleteResult {
  Success = 'Success',
  AlreadyRemoved = 'AlreadyRemoved'
}

export type BoundaryGroupItemsListResponse = {
  list: BoundaryItemResponseBase[];
};

export type BoundaryCombineGeometryBoundaryRequest = {
  type: 'boundary';
  boundary_group_id: number;
  boundary_id: number;
};

export type BoundaryCombineGeometryGeometryRequest = {
  type: 'geometry';
  value: Geometry;
};

export type BoundaryCombineGeometryResponse = {
  geometry: Geometry;
};

export type BoundaryCombineGeometryRequestItem = BoundaryCombineGeometryBoundaryRequest | BoundaryCombineGeometryGeometryRequest;

export type BoundaryCombineGeometryRequest = {
  include: ReadonlyArray<BoundaryCombineGeometryRequestItem>;
  subtract: ReadonlyArray<BoundaryCombineGeometryRequestItem>;
};

type CreateBoundaryRequestParams = {
  boundaryGroupId: number;
  displayName: string;
  geometry: Geometry;
  mapId: number;
  settings?: BoundaryItemsSettingsBaseResponse;
};

export type CreateBoundaryResponse = {
  message: string;
  data: {
    id: number;
  };
};

export type BoundaryResponse = MapBoundaryQueryItemResponse & {
  boundary_id: number;
  zoom_level: number;
};

export enum GeoUnits {
  US_ZIP_CODES = 1,
  US_COUNTIES = 2,
  US_STATES = 3,
}

export type TargetMetric = {
  name: string;
  weighting: number;
};

export type CreateOptimizedTerritoriesRequestParams = {
  allStates: boolean;
  displayName: string;
  mapId: number;
  spreadsheetId: number;
  targetTerritoryCount?: number;
  targetMetrics: TargetMetric[];
  geounitId: GeoUnits;
  respectTerritoryModel?: number;
  datasetZipCodes?: ReadonlyArray<string>;
  datasetStates?: ReadonlyArray<string>;
  constraints?: ReadonlyArray<{
    metric: string;
    operator: string;
    value: number;
  }>;
  salesRepLocationsMap?: {
    mapId: number;
    nameColumnId: string;
  };
  settings?: {
    metrics?: ReadonlyArray<MetricServerModel>;
  };
  filter: FilterTreeItemRequest | null;
};

export type DatasetOptimizedTerritoriesResponse = {
  data: {
    dataset_zip_codes: ReadonlyArray<string>;
    dataset_states: ReadonlyArray<string>;
  };
};

export type DatasetOptimizedTerritoriesRequestParams = {
  spreadsheetId?: number;
  zips?: ReadonlyArray<BoundaryId>;
  states?: ReadonlyArray<BoundaryId>;
};

export type SpreadsheetDatasetOptimizedTerritoriesRequestParams = {
  spreadsheetId?: number;
  zips?: string;
  states?: string;
};

export const getBoundaryGroupItemsList = (clientId: number, boundaryGroupId: BoundaryGroupId): Promise<ReadonlyArray<BoundaryListItem>> => {
  const requestUrl = `/api/clients/${clientId}/boundary-group/${boundaryGroupId}/boundary`;

  return apiGet<BoundaryGroupItemsListResponse>(requestUrl)
    .then(response => {
      return response.list
        .map(decorateDisplayName)
        .map(createBoundariesListItemFromResponse);
    });
};

export const updateBoundary = (clientId: number, boundaryGroupId: BoundaryGroupId, boundaryId: BoundaryId, displayName: string, style?: Partial<BoundaryListItemStyle>): Promise<BoundaryResponse> => {
  // It's not ideal to fetch all items just the update the one, BUT
  // the rename only works on custom boundary groups and the number of items
  // in custom groups should be very low. The items also don't contain geometry data
  // so we probably don't need a dedicated GET ONE endpoint at the moment
  return getBoundaryGroupItemsList(clientId, boundaryGroupId).then(response => {
    const existing = response.find(b => b.id === boundaryId);
    const existingStyle = existing?.settings.style;

    let newStyle: BoundaryListItemStyle | undefined = undefined;

    if (existingStyle) {
      newStyle = existingStyle;
    }

    if (style) {
      newStyle = {
        color: style.color ?? existingStyle?.color ?? blankBoundaryStyle.color,
        opacity: style.opacity ?? existingStyle?.opacity ?? blankBoundaryStyle.opacity,
        lineColor: style.lineColor ?? existingStyle?.lineColor ?? blankBoundaryStyle.lineColor,
        lineWidth: style.lineWidth ?? existingStyle?.lineWidth ?? blankBoundaryStyle.lineWidth,
      };
    }

    const requestUrl = `/api/clients/${clientId}/boundary/${boundaryId}`;
    const parameters: Partial<BoundaryItemResponseBase> = {
      display_name: displayName,
      settings: {
        ...existing?.settings, // merge settings with existing
        style: newStyle ? {
          line_width: newStyle.lineWidth,
          line_color: newStyle.lineColor,
          opacity: newStyle.opacity,
          color: newStyle.color,
        } : undefined,
        translate: false,
        translate_params: null,
      },
    };

    return apiPut(requestUrl, parameters).then(decorateDisplayName);
  });
};

export const updateBoundaryGeometry = (
  clientId: number, boundaryId: BoundaryId, geometry: Geometry, mapId?: number
): Promise<BoundaryResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary/${boundaryId}`;
  const parameters = {
    geometry,
    map_id: mapId, // optional, updates boundary / location relationships on given map synchronously
  };

  return apiPut(requestUrl, parameters).then(decorateDisplayName);
};

export const deleteBoundaryGroupItem = (clientId: number, boundaryId: BoundaryId): Promise<BoundaryDeleteResult> => {
  const requestUrl = `/api/clients/${clientId}/boundary/${boundaryId}`;

  return apiDelete(requestUrl)
    .then(() => {
      return BoundaryDeleteResult.Success;
    })
    .catch(e => {
      // item was already removed
      if (isNotFoundApiError(e)) {
        return BoundaryDeleteResult.AlreadyRemoved;
      }

      throw e;
    });
};

const decorateDisplayName = <TBoundary extends BoundaryItemResponseBase>(boundary: TBoundary): TBoundary => ({
  ...boundary,
  display_name: resolveBoundaryDisplayName(boundary),
});

export const createBoundary = (
  clientId: number, params: CreateBoundaryRequestParams
): Promise<BoundaryResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary`;
  const request = {
    boundary_group_id: params.boundaryGroupId,
    display_name: params.displayName,
    geometry: params.geometry,
    map_id: params.mapId,
    settings: params.settings,
  };
  return apiPost(requestUrl, request);
};

export const createOptimizedTerritories = (
  clientId: number, params: CreateOptimizedTerritoriesRequestParams
): Promise<CreateBoundaryResponse> => {
  const requestUrl = `/api/clients/${clientId}/optimized-territories/`;
  const request = {
    map_id: params.mapId,
    spreadsheet_id: params.spreadsheetId,
    display_name: params.displayName,
    respect_territory_model: params.respectTerritoryModel,
    target_territory_count: params.targetTerritoryCount,
    target_metrics: params.targetMetrics,
    geounit_id: params.geounitId,
    dataset_zip_codes: params.datasetZipCodes,
    dataset_states: params.datasetStates,
    salesrep_locations_map: params.salesRepLocationsMap ? {
      id: params.salesRepLocationsMap.mapId,
      name: params.salesRepLocationsMap.nameColumnId,
    } : undefined,
    constraints: params.constraints,
    filter: params.filter ? {
      filter_tree: params.filter.filterTree,
    } : null,
    all_states: params.allStates,
    settings: params.settings ? {
      metrics: params.settings.metrics,
    } : undefined,
  };
  return apiPost(requestUrl, request);
};

export const getSpreadsheetDatasetOptimizedTerritories = (
  clientId: number, params: SpreadsheetDatasetOptimizedTerritoriesRequestParams
): Promise<DatasetOptimizedTerritoriesResponse> => {
  const requestUrl = `/api/clients/${clientId}/optimized-territories/dataset/spreadsheet`;
  const request = {
    spreadsheet_id: params.spreadsheetId,
    zips: params.zips,
    states: params.states,
  };
  return apiPost(requestUrl, request);
};

export const getDatasetOptimizedTerritories = (
  clientId: number, params: DatasetOptimizedTerritoriesRequestParams
): Promise<DatasetOptimizedTerritoriesResponse> => {
  const requestUrl = `/api/clients/${clientId}/optimized-territories/dataset/boundaries`;
  const request = {
    spreadsheet_id: params.spreadsheetId,
    zips: params.zips,
    states: params.states,
  };
  return apiPost(requestUrl, request);
};

export const getBoundaryCombineGeometry = (
  clientId: number, request: BoundaryCombineGeometryRequest
): Promise<BoundaryCombineGeometryResponse> => {
  const requestUrl = `/api/clients/${clientId}/boundary/combine-geometry`;

  return apiPost(requestUrl, request);
};

export const cancelOptimizedTerritory = (
  clientId: number, territoryId: number
): Promise<BoundaryCombineGeometryResponse> => {
  const requestUrl = `/api/clients/${clientId}/optimized-territories/${territoryId}/stop`;

  return apiPost(requestUrl);
};
