import {
  type FC, memo, type ReactNode, useCallback, useMemo,
} from 'react';
import { type MapOutlinePositionInstance } from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type LatLng } from '~/_shared/types/latLng';
import { MarkerColor } from '~/_shared/types/marker.types';
import { drawingToolIconMarkers } from '~/drawingTool/iconMarkers/drawingToolIconMarkers';
import { type MapObjectZIndex } from '~/map/map/mapObjects/mapObject.types';
import { type DrawingItemIconMarker } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapMarkerComponent } from '../../mapObjects/mapMarker/mapMarker.component';
import { MapMarkerContainer } from '../../mapObjects/mapMarker/mapMarker.container';
import {
  type MapMarkerInstance, MarkerOutlineMode,
} from '../../mapObjects/mapMarker/mapMarkerModel';
import { MapMarkerTemplateLoaderContainer } from '../../mapObjects/mapMarker/mapMarkerTemplateLoader.container';
import {
  type MarkerTemplate, PredefinedTemplate,
} from '../../markers/manager/mapMarkerTemplates';

type MapMarkerInstanceProps = {
  instance: DrawingItemIconMarker;
  zIndex: MapObjectZIndex;
  onClick?: (markerId: Uuid, event: MapObjectClickEventArgs) => void;
  onRightClick?: (markerId: Uuid, event: MapObjectClickEventArgs) => void;
  onMouseOut?: (markerId: Uuid) => void;
  onMouseOver?: (markerId: Uuid) => void;
  renderOutline?: (key: string, outline: MapOutlinePositionInstance) => ReactNode;
  onDragStart?: (markerId: Uuid, latLng: LatLng) => void;
  onDragEnd?: (markerId: Uuid) => void;
  onDragMove?: (markerId: Uuid, latLng: LatLng) => void;
};

const DrawingToolIconMarkerInstanceMarkerContainer: FC<MapMarkerInstanceProps> = ({
  instance, zIndex, onClick, onMouseOut, onMouseOver,
  renderOutline, onDragStart, onDragEnd, onDragMove, onRightClick,
}) => {
  const themeType = useTheme().name;

  const markerTemplate = useMemo<MarkerTemplate | null>(() => {
    const iconMarker = drawingToolIconMarkers.find(item => item.id === instance.settings.icon);

    if (!iconMarker) {
      return null;
    }

    return {
      main: iconMarker.path,
      name: iconMarker.id,
      size: {
        h: 200,
        w: 200,
      },
    };
  }, [instance.settings.icon]);

  const renderIconHolderMarker = useCallback((id: string, markerInstance: MapMarkerInstance) => {
    return (
      <MapMarkerComponent
        key={id}
        marker={{ ...markerInstance, size: 48 }}
        visuals={{
          template: themeType === 'light' ? PredefinedTemplate.IconMarkerHolderLight : PredefinedTemplate.IconMarkerHolderDark,
          offset: { x: 0, y: 0 },
          interactive: true,
        }}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
        onClick={onClick}
        onRightClick={onRightClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      />
    );
  }, [themeType, onMouseOver, onMouseOut, onClick, onDragStart, onDragEnd, onDragMove, onRightClick]);

  const renderIconMarker = useCallback((id: string, markerInstance: MapMarkerInstance) => {
    if (!markerTemplate) {
      return;
    }

    return (
      <MapMarkerComponent
        key={id}
        marker={markerInstance}
        visuals={{
          color: themeType === 'light' ? MarkerColor.White : MarkerColor.White,
          colorOverlay: true,

          template: markerTemplate.name,
          offset: { x: 0, y: -22 },
          interactive: false,
        }}
      />
    );
  }, [themeType, markerTemplate]);

  const mapMarker: MapMarkerInstance = useMemo(() => ({
    id: instance.id,
    size: 22,
    anchor: 'bottom-center',
    lat: instance.center.lat,
    lng: instance.center.lng,
  }), [instance.center.lat, instance.center.lng, instance.id]);

  if (!markerTemplate) {
    return null;
  }

  return (
    <MapMarkerTemplateLoaderContainer
      template={markerTemplate}
    >
      <MapMarkerContainer
        marker={mapMarker}
        zIndex={zIndex}
        outlineMode={MarkerOutlineMode.Offset}
        renderBackground={renderIconHolderMarker}
        renderMarker={renderIconMarker}
        renderOutline={renderOutline}
      />
    </MapMarkerTemplateLoaderContainer>
  );
};

const pureComponent = memo(DrawingToolIconMarkerInstanceMarkerContainer);
export { pureComponent as DrawingToolMarkerInstanceMarkerContainer };
