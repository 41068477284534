import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SelectedDataMetric } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { CustomizeMetricsComponent } from '../../_shared/components/customizeMetrics/customizeMetrics.component';
import { useTranslation } from '../../_shared/utils/hooks';
import {
  createMetricFromSelectedData, createSelectedDataFromMetric, type MetricModel,
} from '../../_shared/utils/metric/metrics.factory';
import { type ModalProps } from '../../modal/modalType.enum';
import { demographicsListGlobalFetchDataRequest } from '../../store/demographics/demographicsListGlobal/demographicsListGlobal.actionCreators';
import {
  useDemographicsListGlobalIsLoadingSelector,
  useDemographicsListGlobalRegionsSelector,
} from '../../store/demographics/demographicsListGlobal/demographicsListGlobal.selectors';
import { useIsMapSettingsSyncInProgressSelector } from '../../store/frontendState/processing/processing.selectors';
import { setProximityMetrics } from '../../store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { useProximityMetricsSelector } from '../../store/mapSettings/proximity/mapSettingsProximity.selectors';
import { useSpreadsheetColumns } from '../../store/matchupData/matchupDataSelectors.hook';

type ProximitySettingsContainerProps = ModalProps;

export const ProximitySettingsContainer: FC<ProximitySettingsContainerProps> = (props) => {
  const matchupColumns = useSpreadsheetColumns();
  const proximityMetrics = useProximityMetricsSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const isDemographicsLoading = useDemographicsListGlobalIsLoadingSelector();
  const demographicsRegions = useDemographicsListGlobalRegionsSelector();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const selectedData: SelectedDataMetric[] = useMemo(() => {
    return proximityMetrics
      .map(metric => createSelectedDataFromMetric(metric))
      .filter((data): data is SelectedDataMetric => !!data);
  }, [proximityMetrics]);

  const dispatchProps = useMemo(() => ({
    setProximityMetrics: (selectedData: SelectedDataMetric[]) => {
      const newMetrics: MetricModel[] = selectedData
        .map(data => createMetricFromSelectedData(data))
        .filter((item): item is MetricModel => !!item);

      dispatch(setProximityMetrics(newMetrics));
    },
  }), [dispatch]);

  useEffect(() => {
    dispatch(demographicsListGlobalFetchDataRequest());
  }, [dispatch]);

  return (
    <CustomizeMetricsComponent
      isLoading={isMapSettingsSyncInProgress || isDemographicsLoading}
      selectedData={selectedData}
      onSelectedDataChange={dispatchProps.setProximityMetrics}
      title={t('Customize Proximity Metrics')}
      addSelectionButtonText={t('Add to Proximity Details')}
      selectedDataCaption={t('Data added to proximity details')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      columnDataSources={matchupColumns}
      demographicsRegions={demographicsRegions}
    />
  );
};
