import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { type ColorResult } from '../../../_shared/components/colorPicker/colorPicker.types';
import { ColorPickerWithColorTileComponent } from '../../../_shared/components/colorPicker/colorPickerWithColorTile/colorPickerWithColorTile.component';
import { ColorPickerWithWheelComponent } from '../../../_shared/components/colorPicker/colorPickerWithWheel/colorPickerWithWheel.component';
import { useDebounce } from '../../../_shared/utils/hooks/useDebounce';

type HeatMapColorRowProps = Readonly<{
  className?: string;
  label: string;
  selectedColor: ColorResult;
  onColorChange: (color: ColorResult) => void;
}>;

const wrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const tilePickerStyle = css({
  fontSize: '14px',
});

const COLOR_TILE_DIMENSION = 16;
export const HeatMapColorRowComponent: FC<HeatMapColorRowProps> = (props) => {
  const [color, setColor] = useState(props.selectedColor);

  const onChangeDebounced = useDebounce(props.onColorChange, 500);

  useEffect(() => {
    onChangeDebounced(color);
  }, [color, props.selectedColor, onChangeDebounced]);

  useEffect(() => {
    return () => {
      onChangeDebounced.now();
    };
  }, [onChangeDebounced]);

  return (
    <div
      className={props.className}
      css={wrapperStyle}
    >
      <ColorPickerWithColorTileComponent
        css={tilePickerStyle}
        displayAlpha={false}
        height={COLOR_TILE_DIMENSION}
        isFixed
        label={props.label}
        onChange={setColor}
        selectedColor={color}
        width={COLOR_TILE_DIMENSION}
      />

      <ColorPickerWithWheelComponent
        isFixed
        displayAlpha={false}
        contentStyle={css({
          justifyContent: 'flex-end',
        })}
        onChange={setColor}
        selectedColor={color}
      />
    </div>
  );
};
