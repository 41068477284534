export const BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST = 'BOUNDARY_TERRITORY_GROUP_FETCH_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS = 'BOUNDARY_TERRITORY_GROUP_FETCH_SUCCESS';
export const BOUNDARY_TERRITORY_GROUP_FETCH_ERROR = 'BOUNDARY_TERRITORY_GROUP_FETCH_ERROR';

export const BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST = 'BOUNDARY_TERRITORY_GROUP_CREATE_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST = 'BOUNDARY_TERRITORY_GROUP_CLONE_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS = 'BOUNDARY_TERRITORY_GROUP_CREATE_SUCCESS';
export const BOUNDARY_TERRITORY_GROUP_CREATE_ERROR = 'BOUNDARY_TERRITORY_GROUP_CREATE_ERROR';
export const BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL = 'BOUNDARY_TERRITORY_GROUP_CREATE_CANCEL';

export const BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST = 'BOUNDARY_TERRITORY_GROUP_UPDATE_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS = 'BOUNDARY_TERRITORY_GROUP_UPDATE_SUCCESS';
export const BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR = 'BOUNDARY_TERRITORY_GROUP_UPDATE_ERROR';
export const BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL = 'BOUNDARY_TERRITORY_GROUP_UPDATE_CANCEL';

export const BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST = 'BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS = 'BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_SUCCESS';
export const BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR = 'BOUNDARY_TERRITORY_GROUP_UPDATE_ASSIGNMENTS_ERROR';

export const BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST = 'BOUNDARY_TERRITORY_GROUP_REMOVE_REQUEST';
export const BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS = 'BOUNDARY_TERRITORY_GROUP_REMOVE_SUCCESS';
export const BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR = 'BOUNDARY_TERRITORY_GROUP_REMOVE_ERROR';

export const BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY = 'BOUNDARY_TERRITORY_GROUPS_CREATE_CUSTOM_TERRITORY';
export const BOUNDARY_TERRITORY_CHANGE_NAME = 'BOUNDARY_TERRITORY_CHANGE_NAME';
export const BOUNDARY_TERRITORY_DELETE = 'BOUNDARY_TERRITORY_DELETE';
export const BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS = 'BOUNDARY_TERRITORY_GROUP_PATCH_SETTINGS';
