import { useEffect } from 'react';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useUserDataSelector } from '~/store/userData/userData.selectors';

const getSeenQuickstartKey = (userId: number) => `seenQuickstart|${userId}`;

export const useShowWelcomeModalOnFirstLogin = () => {
  const { id: userId, previousLogin } = useUserDataSelector();
  const { openModal } = useModal(ModalType.Welcome);

  const key = notNullOrUndefined(userId) && getSeenQuickstartKey(userId);
  const seenQuickstart = key && localStorage.getItem(key);

  useEffect(() => {
    if (!previousLogin && !seenQuickstart && key) {
      localStorage.setItem(key, 'true');
      openModal();
    }
  }, [seenQuickstart, previousLogin, openModal, userId, key]);
};
