import {
  useEffect,
  useState,
} from 'react';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import {
  getSpreadsheetBulkData,
  type SpreadsheetDataBulkRequestGetter,
} from '~/spreadsheet/spreadsheet.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { generateGroupIdByName } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import {
  DataType, type UniqueGroupsDataTypeData,
} from '~/store/spreadsheetData/spreadsheetData.state';

export const useUniqueGroups = (spreadsheetId: number | null, columnId: string | null) => {
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const isMountedRef = useIsComponentMountedRef();
  const [groups, setGroups] = useState<UniqueGroupsDataTypeData>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (columnId === null || clientId === null || spreadsheetId === null || mapId === null) {
      return;
    }
    const params: SpreadsheetDataBulkRequestGetter[] = [{
      map_id: mapId,
      spreadsheet_id: spreadsheetId,
      columns_to_fetch: {
        [DataType.GROUP]: {
          [columnId]: {},
        },
      },
    }];

    setIsLoaded(false);
    setHasError(false);
    setIsLoading(true);

    getSpreadsheetBulkData(clientId, { params })
      .then(response => {
        const result = response?.data?.[0]?.result?.columns_to_fetch?.[DataType.GROUP]?.[columnId] ?? null;
        if (result === null) {
          throw new Error(`Failed to find unique values for the requested column id. response: ${JSON.stringify(response)}`);
        }

        if (isMountedRef.current) {
          if (result.unique_indexes) {
            setGroups(result.unique_values.map((groupName, index) => ({
              id: result.unique_indexes?.[index]?.toString() ?? '',
              label: groupName,
            })));
          }
          else {
            setGroups(result.unique_values.map((groupName) => ({
              id: generateGroupIdByName(groupName),
              label: groupName,
            })));
          }
          setIsLoaded(true);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error(error);

        if (isMountedRef.current) {
          setIsLoading(false);
          setHasError(true);
        }
      });
  }, [columnId, clientId, spreadsheetId, isMountedRef, mapId]);

  return {
    groups,
    isLoading,
    isLoaded,
    hasError,
  };
};
