import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { flatten } from '~/_shared/utils/collections/collections';
import {
  getSingleZIndex, ZIndexedEntity,
} from '~/map/zIndexes/zIndexRanges';
import { useZoomToBoundary } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useZoomToBoundary';
import {
  type BoundaryGroupId, type BoundaryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { useBoundaryTerritoryAssignments } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryLocationsFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { useMapSettingsBoundariesIsolateColor } from '../../../../store/mapSettings/boundaries/mapSettingsBoundaries.selectors';
import { type MapBoundaryManager } from '../mapBoundary.manager';
import { getIsolateBoundaryColorHex } from './mapIsolateBoundaries.helper';

export const useMapIsolateBoundaries = (manager: MapBoundaryManager | undefined) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const boundaryLocationsFilters = useBoundaryLocationsFilters();
  const boundaryTerritoryAssignments = useBoundaryTerritoryAssignments();
  const coverPolygonZIndex = getSingleZIndex(ZIndexedEntity.CoverPolygon);
  const { zoomToBoundary } = useZoomToBoundary();
  const isolateColor = useMapSettingsBoundariesIsolateColor();

  useEffect(() => {
    if (!manager) {
      return;
    }

    manager.updateIsolateColor(getIsolateBoundaryColorHex(theme, isolateColor));
  }, [manager, isolateColor, theme]);

  useEffect(() => {
    if (!manager) {
      return;
    }

    const boundariesMap = new Map<BoundaryGroupId, ReadonlySet<BoundaryId>>();

    boundaryLocationsFilters.entries.forEach(entry => {
      if (entry.filter.isolate) {
        const boundaryTerritoryAssignmentsForGroup = boundaryTerritoryAssignments.get(entry.boundaryGroupId);
        const territoryBoundaryIds = new Set<BoundaryId>();
        if (boundaryTerritoryAssignmentsForGroup) {
          entry.filter.filteredBoundaryTerritories.map((boundaryTerritoryId) => {
            boundaryTerritoryAssignmentsForGroup.territoryAssignments
              .get(boundaryTerritoryId)?.forEach(boundaryId => territoryBoundaryIds.add(boundaryId));
          });
        }

        entry.filter.filteredBoundaries.forEach(boundaryId => territoryBoundaryIds.add(boundaryId));
        if (territoryBoundaryIds.size) {
          boundariesMap.set(entry.boundaryGroupId, territoryBoundaryIds);
        }
      }
    });

    zoomToBoundary(flatten(Array.from(boundariesMap.entries()).map(([boundaryGroupId, boundaryIds]) => (
      Array.from(boundaryIds.values()).map(boundaryId => ({ boundaryGroupId, boundaryId }))
    ))));

    manager.updateIsolateBoundaries(boundariesMap, coverPolygonZIndex);
  }, [boundaryLocationsFilters.entries, boundaryTerritoryAssignments, coverPolygonZIndex, dispatch, manager, zoomToBoundary]);

  useEffect(() => () => {
    if (manager) {
      manager.updateIsolateBoundaries(new Map());
    }
  }, [manager]);
};
