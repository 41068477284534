import { type MapSettingsDataServerModel } from '~/map/map.repository';
import { type AppState } from '../../app.store';
import { mapSettingsDrawingSerializer } from '../drawing/mapSettingsDrawing.serializer';
import { fileAttachmentSerializer } from '../fileAttachments/fileAttachments.serializer';
import { mapSettingsHeatmapSerializer } from '../heatmaps/mapSettingsHeatmaps.serializer';
import { mapSettingsMarkersSerializer } from '../markers/mapSettingsMarkers.serializer';
import { mapSettingsProximitySerializer } from '../proximity/mapSettingsProximity.serializer';
import { mapSettingsToolsStateSerializer } from '../toolsState/mapSettingsToolsState.serializer';

export const mapSettingsDataSelector = (state: AppState) => state.map.mapSettings.data;

export const mapSettingsDataServerModelSelector = (state: AppState): MapSettingsDataServerModel => {
  const { markers, fileAttachments, drawing, proximity, heatmaps, toolsState, ...rest } = mapSettingsDataSelector(state);

  return {
    ...rest,
    markers: mapSettingsMarkersSerializer.stateToJson(markers),
    fileAttachments: fileAttachmentSerializer.stateToJson(fileAttachments),
    drawing: mapSettingsDrawingSerializer.stateToJson(drawing),
    proximity: mapSettingsProximitySerializer.stateToJson(proximity),
    heatmaps: mapSettingsHeatmapSerializer.stateToJson(heatmaps),
    toolsState: mapSettingsToolsStateSerializer.stateToJson(toolsState),
  };
};
