import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type MouseEvent, useState,
} from 'react';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import { useLogOutUser } from '~/authorization/logOutUser.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  s, Trans,
} from '~/translations/Trans';
import { AppErrorPageWrapperComponent } from '../wrapper/appErrorPageWrapper.component';

const buttonsWrapperStyle = css({
  marginTop: 20,
  display: 'flex',
  justifyContent: 'space-between',
});

const animationWrapperStyle = css({
  marginTop: 15,
  display: 'flex',
  justifyContent: 'center',
});

const iconWrapperStyle = css({
  margin: '-20px 0 0',
});

const iconStyle = css({
  fontSize: '55px',
});

const textContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: 620,
});

const headingPrimaryStyle = css({
  marginTop: 10,
  fontSize: '36px',
});

const headingSecondaryStyle = css({
  fontSize: '20px',
});

const customerSupportStyle = (theme: Theme) => css({
  color: theme.textColors.link,
  fontSize: 'inherit',
  fontWeight: 700,
  background: 'none',
  border: 'none',
  display: 'inline',
  margin: 0,
  padding: 0,
  '&:hover': {
    color:
    theme.textColors.linkOnHover,
  },
});

type AppErrorPageContactSupportProps = {
  onTryAgainClick?: () => void;
};

export const AppErrorPageContactSupportComponent: FC<AppErrorPageContactSupportProps> = (props) => {
  const [isLoginLinkDisabled, setIsLoginLinkDisabled] = useState(false);
  const [t] = useTranslation();

  const { openModal: openContactUs } = useModal(ModalType.ContactUs);
  const logUserOut = useLogOutUser();

  const onBackToLoginClick = (e: MouseEvent) => {
    setIsLoginLinkDisabled(true);
    e.preventDefault();
    logUserOut();
  };

  return (
    <AppErrorPageWrapperComponent icon={null}>
      <div css={animationWrapperStyle}>
        <LottieAnimationComponent
          type={LottieAnimationTypes.SmudgeDog}
          size={200}
          autoplay
          loop
        />
      </div>

      <div css={iconWrapperStyle}>
        <FontAwesomeIcon
          css={iconStyle}
          icon={faExclamationTriangle}
        />
      </div>

      <div css={textContainerStyle}>
        <div css={headingPrimaryStyle}>{t('Oh no, something went wrong')}</div>
        <div css={headingSecondaryStyle}>
          <Trans
            i18nKey="Please check your payment details and try again, or contact our <1>customer support</1> for assistance if the problem persists."
          >
            {s('Please check your payment details and try again, or contact our ')}
            <button
              css={customerSupportStyle}
              onClick={() => openContactUs()}
            >
              customer support
            </button>
            {s(' for assistance if the problem persists.')}
          </Trans>
        </div>
      </div>

      <div css={buttonsWrapperStyle}>
        <ButtonComponent
          buttonStyle={ButtonStyle.Secondary}
          isDisabled={isLoginLinkDisabled}
          onClick={onBackToLoginClick}
          text={t('Back to Login')}
        />

        {props.onTryAgainClick && (
          <ButtonComponent text={t('Try Again')} onClick={props.onTryAgainClick} />
        )}
      </div>
    </AppErrorPageWrapperComponent>
  );
};
