import {
  css, type SerializedStyles,
} from '@emotion/react';
import { type FC } from 'react';
import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { GoogleSheetMapSyncType } from '../../map/map.repository';
import {
  s, Trans,
} from '../../translations/Trans';

const googleAccountLoginStatusTextStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  cursor: 'default',
  fontSize: '13px',
  marginTop: '10px',
});

const googleAccountLoginStatusTextBoldStyle = css({
  fontWeight: 700,
});

const underlineOnHoverStyle = css({
  '&:hover': {
    textDecoration: 'underline',
  },
});

const syncMethodDescriptionStyle = css({
  fontSize: '14px',
});

const syncMethodDescriptionBoldStyle = css({
  fontWeight: 700,
  textTransform: 'uppercase',
});

type SyncMethodSectionProps = Readonly<{
  googleAccount?: string;
  radioLabelStyle?: SerializedStyles;
  radioTextStyle?: SerializedStyles;
  syncMethod: GoogleSheetMapSyncType;

  onGoogleLogout?: () => void;
  onMethodChange: (syncMethod: GoogleSheetMapSyncType) => void;
}>;

export const SyncMethodSectionComponent: FC<SyncMethodSectionProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <>
      <RadioGroupComponent<GoogleSheetMapSyncType>
        items={[{
          value: GoogleSheetMapSyncType.GoogleToMaptive,
          label: <div css={props.radioTextStyle}>{t('Sync 1 Direction:  Google Sheets >>> to >>> Maptive')}</div>,
          children: <span css={syncMethodDescriptionStyle}>{t('googleSheets.explanation.for.oneway.sync.direction')}</span>,
        }, {
          value: GoogleSheetMapSyncType.TwoWaySync,
          label: <div css={props.radioTextStyle}>{t('Sync 2 Directions: Google Sheets >> Maptive and Maptive >> Google Sheets')}</div>,
          children: (
            <>
              <span css={syncMethodDescriptionStyle}>{t('googleSheets.explanation.for.bothways.sync.direction')}</span>
              <br />&nbsp;<br />
              <strong css={syncMethodDescriptionBoldStyle}>{t('Note:')}&nbsp;</strong>
              <span css={syncMethodDescriptionStyle}>{t('googleSheets.note.for.bothways.sync.direction')}</span>
            </>
          ),
        }]}
        labelStyle={props.radioLabelStyle}
        onValueChange={props.onMethodChange}
        selectedValue={props.syncMethod}
      />
      {props.googleAccount && props.onGoogleLogout && (
        <div css={googleAccountLoginStatusTextStyle({ theme })}>
          <Trans i18nKey="You are currently logged in under <1>{{GoogleAccount}}</1>.">
            {s('You are currently logged in under ')}
            {props.googleAccount}
            {s('.')}
          </Trans>
          &nbsp;
          <Trans i18nKey="Click <1>here</1> if you wish to logout.">
            {s('Click ')}
            <strong
              css={[googleAccountLoginStatusTextBoldStyle, underlineOnHoverStyle]}
              onClick={props.onGoogleLogout}
            >
              {t('here')}
            </strong>
            {s(' if you wish to logout.')}
          </Trans>
        </div>
      )}
      {!props.googleAccount && (
        <div css={googleAccountLoginStatusTextStyle({ theme })}>
          {t('googleSheets.sync.notLoggedIn')}
        </div>
      )}
    </>
  );
};
