import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { AccordionComponent } from '~/_shared/baseComponents/accordion';
import { getAlternatingColor } from '~/_shared/baseComponents/alternatingStripes/alternatingStripes';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { TabsComponent } from '~/_shared/components/tabs/tabs.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { type MetricValue } from '../createBoundaryGroupFromAIModal.types';
import {
  AdditionalOptionsWarningMessageComponent,
  AdditionalWarningMessageType,
} from './additionalOptionsWarningMessage.component';
import { ConstraintSlider } from './constraintSlider.component';
import { type ConstraintsValidationDataMap } from './constraintsValidation/useConstraintsValidation';
import {
  SelectableMetricsListComponent, type SelectableMetricValue,
} from './selectableMetricsList.component';

const SHOW_ADDITIONAL_CONSTRAINTS = false; // disabled, wms does not support it

const contentContainerStyle = css({
  position: 'relative',
  padding: '10px 0 0 25px',
  marginTop: 10,
});

const constraintsContainer = ({ theme }: ThemeProps) => css({
  border: `1px solid ${theme.borderColors.primary}`,
  padding: '8px 10px',
  borderRadius: 4,
});

const constraintsItemStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: getAlternatingColor(theme),
  border: `1px solid ${theme.borderColors.primary}`,
  borderRadius: 4,
  marginTop: 25,
  '&:first-of-type': {
    marginTop: 0,
  },
  padding: 8,
});

const constraintsWarningStyle = css({
  marginTop: 15,
});

const constraintsContainer2 = css({
  padding: '10px',
});

const accordionPanelStyle = css({
  padding: 0,
  paddingTop: 10,
  border: 'none',
});

const selectLabelStyle = css({
  margin: '0 0 5px',
});

const accordionItemButtonStyle = ({ theme }: ThemeProps) => css({
  padding: 0,
  height: 'auto',
  borderTop: 'none',
  borderBottom: 'none',
  cursor: 'pointer',
  backgroundColor: theme.backgroundColors.primary,
});

const accordionMetricsStyle = ({ theme }: ThemeProps) => css({
  padding: 0,
  border: `1px solid ${theme.borderColors.primary}`,
});

const accordionMetricsItemButtonStyle = css({
  padding: 0,
});

const generalErrorStyle = css({
  marginBottom: 10,
});

type AdditionalOptionsConstraintsProps = Readonly<{
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  generalError: string;
  isLoading: boolean;
  validationData: ConstraintsValidationDataMap;

  onChangeDataMetrics: (dataMetricsValues: MetricValue[]) => void;
  onChangeDemographics: (demographicsValues: MetricValue[]) => void;
}>;

export const AdditionalOptionsConstraintsComponent: FC<AdditionalOptionsConstraintsProps> = (props) => {
  const {
    demographicsValues, dataMetricsValues, validationData, onChangeDataMetrics, onChangeDemographics, generalError,
  } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const selectableDataMetricsValues = useMemo<SelectableMetricValue[]>(() => dataMetricsValues.map(dateMetric => ({
    id: dateMetric.id,
    label: dateMetric.name,
    disabled: dateMetric.checked,
    checked: dateMetric.checked || dateMetric.isConstraint,
  })), [dataMetricsValues]);

  const selectableDemographicsValues = useMemo(() => demographicsValues.map(demographicsValue => ({
    id: demographicsValue.id,
    label: demographicsValue.name,
    disabled: demographicsValue.checked,
    checked: demographicsValue.checked || demographicsValue.isConstraint,
  })), [demographicsValues]);

  const onChangeDataMetric = useCallback((id: string, checked: boolean) => {
    const newMetricValues = dataMetricsValues.map(dataMetricsValue => ({
      ...dataMetricsValue,
      isConstraint: dataMetricsValue.id === id ? checked : dataMetricsValue.isConstraint,
    }));
    onChangeDataMetrics(newMetricValues);
  }, [dataMetricsValues, onChangeDataMetrics]);

  const onChangeDemographicsValue = useCallback((id: string, checked: boolean) => {
    const newMetricValues = demographicsValues.map(demographicsValue => ({
      ...demographicsValue,
      isConstraint: demographicsValue.id === id ? checked : demographicsValue.isConstraint,
    }));
    onChangeDemographics(newMetricValues);
  }, [demographicsValues, onChangeDemographics]);

  const handleConstraintChange = useCallback((
    id: string,
    change: {
      isConstraint?: boolean;
      minConstraint?: number;
      maxConstraint?: number;
    },
    metricsValues: MetricValue[],
    onChange: (metricsValues: MetricValue[]) => void
  ) => {
    const newMetricValues = metricsValues.map(metricsValue => {
      if (metricsValue.id !== id) {
        return metricsValue;
      }

      // Reset min / max on check / uncheck
      if (notNullOrUndefined(change.isConstraint) && change.isConstraint !== metricsValue.isConstraint) {
        return {
          ...metricsValue,
          isConstraint: change.isConstraint,
          minConstraint: change.minConstraint ?? undefined,
          maxConstraint: change.maxConstraint ?? undefined,
        };
      }

      return ({
        ...metricsValue,
        minConstraint: change.minConstraint ?? metricsValue.minConstraint,
        maxConstraint: change.maxConstraint ?? metricsValue.maxConstraint,
      });
    });
    onChange(newMetricValues);
  }, []);

  const onDataConstraintChange = useCallback((
    id: string,
    change: {
      isConstraint?: boolean;
      minConstraint?: number;
      maxConstraint?: number;
    }
  ) => handleConstraintChange(id, change, dataMetricsValues, onChangeDataMetrics),
  [dataMetricsValues, handleConstraintChange, onChangeDataMetrics]);

  const onDemographicsConstraintChange = useCallback((
    id: string,
    change: {
      isConstraint?: boolean;
      minConstraint?: number;
      maxConstraint?: number;
    }
  ) => handleConstraintChange(id, change, demographicsValues, onChangeDemographics),
  [demographicsValues, handleConstraintChange, onChangeDemographics]);

  return (
    <div css={contentContainerStyle}>
      <p css={selectLabelStyle}>{t('Set Constraints')}</p>

      {generalError && (
        <AdditionalOptionsWarningMessageComponent
          css={generalErrorStyle}
          type={AdditionalWarningMessageType.Error}
        >
          {generalError}
        </AdditionalOptionsWarningMessageComponent>
      )}

      <div css={constraintsContainer({ theme })}>
        {dataMetricsValues
          .filter(dataMetricsValue => dataMetricsValue.checked)
          .map(dataMetricsValue => {
            const metricValidationData = validationData.get(dataMetricsValue.id);
            const animation = metricValidationData ? (
              metricValidationData.errorMessages.length ? 'error' : 'success'
            ) : undefined;

            return (
              <div
                css={constraintsItemStyle({ theme })}
                key={dataMetricsValue.id}
              >
                <ConstraintSlider
                  id={dataMetricsValue.id}
                  checkable
                  label={dataMetricsValue.name}
                  removable={false}
                  min={metricValidationData?.min ?? dataMetricsValue.min}
                  max={metricValidationData?.max ?? dataMetricsValue.max}
                  minValue={dataMetricsValue.minConstraint}
                  maxValue={dataMetricsValue.maxConstraint}
                  highestMin={metricValidationData?.highestMin}
                  lowestMax={metricValidationData?.lowestMax}
                  onChange={onDataConstraintChange}
                  checked={dataMetricsValue.isConstraint}
                  animationType={animation}
                />

                {dataMetricsValue.isConstraint && !!metricValidationData?.errorMessages.length && (
                  <AdditionalOptionsWarningMessageComponent
                    css={constraintsWarningStyle}
                    type={AdditionalWarningMessageType.Warning}
                  >
                    {metricValidationData?.errorMessages.join(' ')}
                  </AdditionalOptionsWarningMessageComponent>
                )}
              </div>
            );
          })
        }
        {demographicsValues
          .filter(demographicsValue => demographicsValue.checked)
          .map(demographicsValue => {
            const metricValidationData = validationData.get(demographicsValue.id);
            const animation = metricValidationData ? (
              metricValidationData.errorMessages.length ? 'error' : 'success'
            ) : undefined;

            return (
              <div
                css={constraintsItemStyle({ theme })}
                key={demographicsValue.id}
              >
                <ConstraintSlider
                  id={demographicsValue.id}
                  checkable
                  label={demographicsValue.name}
                  removable={false}
                  min={metricValidationData?.min ?? demographicsValue.min}
                  max={metricValidationData?.max ?? demographicsValue.max}
                  minValue={demographicsValue.minConstraint}
                  maxValue={demographicsValue.maxConstraint}
                  highestMin={metricValidationData?.highestMin}
                  lowestMax={metricValidationData?.lowestMax}
                  onChange={onDemographicsConstraintChange}
                  checked={demographicsValue.isConstraint}
                  animationType={animation}
                />

                {demographicsValue.isConstraint && !!metricValidationData?.errorMessages.length && (
                  <AdditionalOptionsWarningMessageComponent
                    css={constraintsWarningStyle}
                    type={AdditionalWarningMessageType.Warning}
                  >
                    {metricValidationData?.errorMessages.join(' ')}
                  </AdditionalOptionsWarningMessageComponent>
                )}
              </div>
            );
          })
        }
        {SHOW_ADDITIONAL_CONSTRAINTS && (
          <AccordionComponent
            panelStyle={accordionPanelStyle}
            itemButtonStyle={accordionItemButtonStyle({ theme })}
            data={[
              { header: t('boundaryAI.Add additional constraints'),
                isLocked: false,
                child: (
                  <AccordionComponent
                    panelStyle={accordionMetricsStyle({ theme })}
                    itemStyle={accordionMetricsItemButtonStyle}
                    data={[
                      {
                        header: null,
                        isLocked: true,
                        isExpanded: true,
                        child: (
                          <TabsComponent
                            data={[
                              {
                                header: t('Data Metrics').toUpperCase(),
                                index: 0,
                                child: (
                                  <SelectableMetricsListComponent
                                    metrics={selectableDataMetricsValues}
                                    onChange={onChangeDataMetric}
                                  />),
                              },
                              {
                                header: t('DemographicCensusData').toUpperCase(),
                                index: 1,
                                child: (
                                  <SelectableMetricsListComponent
                                    metrics={selectableDemographicsValues}
                                    onChange={onChangeDemographicsValue}
                                  />),
                              },
                            ]}
                          />
                        ),
                      },
                      {
                        header: t('boundaryAI.Summary data for setting additional constraints'),
                        isLocked: true,
                        isExpanded: true,
                        child: (
                          <div css={constraintsContainer2}>
                            {dataMetricsValues
                              .filter(dataMetricsValue => !dataMetricsValue.checked && dataMetricsValue.isConstraint)
                              .map(dataMetricsValue => (
                                <ConstraintSlider
                                  key={dataMetricsValue.id}
                                  id={dataMetricsValue.id}
                                  checkable={false}
                                  label={dataMetricsValue.name}
                                  removable
                                  min={dataMetricsValue.min}
                                  max={dataMetricsValue.max}
                                  minValue={dataMetricsValue.minConstraint}
                                  maxValue={dataMetricsValue.maxConstraint}
                                  onChange={onDataConstraintChange}
                                  onRemove={(id) => onDataConstraintChange(id, { isConstraint: false })}
                                  checked
                                />
                              ))}
                            {demographicsValues
                              .filter(demographicsValue => !demographicsValue.checked && demographicsValue.isConstraint)
                              .map(demographicsValue => (
                                <ConstraintSlider
                                  key={demographicsValue.id}
                                  id={demographicsValue.id}
                                  checkable={false}
                                  label={demographicsValue.name}
                                  removable
                                  min={demographicsValue.min}
                                  max={demographicsValue.max}
                                  minValue={demographicsValue.minConstraint}
                                  maxValue={demographicsValue.maxConstraint}
                                  onChange={onDemographicsConstraintChange}
                                  onRemove={(id) => onDemographicsConstraintChange(id, { isConstraint: false })}
                                  checked
                                />
                              ))}
                          </div>
                        ),
                      }]}
                  />
                ) },
            ]}
          />
        )}
      </div>

      {props.isLoading && (
        <OverlayLoaderComponent />
      )}
    </div>
  );
};
