import {
  type FC, useMemo,
} from 'react';
import { EXPORT_LEGEND_COMPONENT_ID } from '../_shared/types/exportImage/exportImage.constants';
import { type ActiveGroupFilters } from '../_shared/types/grouping/grouping';
import { getActiveGroupFiltersFromFiltersState } from '../_shared/utils/grouping/grouping.helpers';
import {
  useIsMapSettingsSyncInProgressSelector,
  useIsSpreadsheetDataFetchingInProgressSelector,
} from '../store/frontendState/processing/processing.selectors';
import { useMapSettingsColumnsFilterSelector } from '../store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '../store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMapSettingsExportImageSettingsSelector } from '../store/mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';
import { useSpreadsheetColumns } from '../store/matchupData/matchupDataSelectors.hook';
import { GroupingSimpleLegendComponent } from './groupingSimpleLegend.component';
import { useGroupColumnData } from './useGroupColumnData';

export const GroupingSimpleLegendContainer: FC = () => {
  const activeColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const isSpreadsheetDataLoading = useIsSpreadsheetDataFetchingInProgressSelector();
  const legendMode = useMapSettingsExportImageSettingsSelector().legendMode;

  const spreadSheetColumns = useSpreadsheetColumns();
  const { isLoading } = useGroupColumnData();

  const activeFilter = useMemo<ActiveGroupFilters>(() => {
    return getActiveGroupFiltersFromFiltersState(columnsFilter);
  }, [columnsFilter]);

  return (
    <GroupingSimpleLegendComponent
      activeColumns={activeColumns}
      columns={spreadSheetColumns}
      filterValues={activeFilter}
      htmlElementId={legendMode ? EXPORT_LEGEND_COMPONENT_ID : undefined}
      isLoading={isLoading || isSpreadsheetDataLoading || isMapSettingsSyncInProgress}
    />
  );
};
