import {
  type FC,
  useMemo,
} from 'react';
import { type MapPrivacyLevel } from '~/_shared/types/map';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  type MapPrivacyInfo, useManageMapPrivacy,
} from '../mapPrivacySelector/useManageMapPrivacy.hook';
import { MapPrivacySelectorModalComponent } from './mapPrivacySelectorModal.component';

export type MapPrivacySelectorModalContainerProps = ModalProps<{
  isSnapshot: boolean;
  mapName: string;
  mapPrivacyInfo: MapPrivacyInfo;

  onSuccess: (newPrivacyLevel: MapPrivacyLevel) => void;
}>;

export const MapPrivacySelectorModalContainer: FC<MapPrivacySelectorModalContainerProps> = (props) => {
  const mapPrivacyManagerProps = useMemo(() => ({
    mapPrivacyInfo: props.mapPrivacyInfo,
    onSuccess: (newPrivacyLevel: MapPrivacyLevel) => {
      props.onSuccess(newPrivacyLevel);
      props.onClose();
    },
  }), [props]);

  const mapPrivacyManager = useManageMapPrivacy(mapPrivacyManagerProps);

  return (
    <MapPrivacySelectorModalComponent
      {...props}
      manageMapPrivacyProps={mapPrivacyManager}
      isSaveDisabled={!mapPrivacyManager.isFormValid}
      isLoading={mapPrivacyManager.isLoading}
    />
  );
};
