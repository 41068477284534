import { type MapPresentationalAction } from './mapPresentational.action';
import {
  MAP_PRESENTATIONAL_SET_SHOW_PRESENTATIONAL_MENU,
  MAP_PRESENTATIONAL_SET_SHOW_WRONG_PASSWORD_ERROR,
  MAP_PRESENTATIONAL_SET_VIEW,
} from './mapPresentational.actionTypes';
import {
  type MapPresentationalState, MapPresentationalView,
} from './mapPresentational.state';

const initialState: MapPresentationalState = {
  isPresentationalMenuShown: false,
  showWrongPasswordError: false,
  view: MapPresentationalView.Loading,
};

export const mapPresentationalReducer = (state: MapPresentationalState = initialState, action: MapPresentationalAction): MapPresentationalState => {
  switch (action.type) {
    case MAP_PRESENTATIONAL_SET_VIEW: {
      return {
        ...state,
        view: action.payload.view,
      };
    }

    case MAP_PRESENTATIONAL_SET_SHOW_WRONG_PASSWORD_ERROR: {
      return {
        ...state,
        showWrongPasswordError: action.payload.showPasswordError,
      };
    }

    case MAP_PRESENTATIONAL_SET_SHOW_PRESENTATIONAL_MENU: {
      return {
        ...state,
        isPresentationalMenuShown: action.payload.isMenuOpen,
      };
    }

    default: {
      return state;
    }
  }
};
