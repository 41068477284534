import { css } from '@emotion/react';
import { type FC } from 'react';
import { ExpiredSubscriptionBlocker } from '~/_shared/types/client/subscriptionBlocker.component';
import { MobileFooterContainer } from '~/homepage/mobile/mobileFooterContainer';
import { MobileMapListingContainer } from '~/map/listing/mobileMapListing.container';

const mobileContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
});

const renderEmptyDiv = () => (
  <div />
);

export const MobileHomePageComponent: FC = () => (
  <div css={mobileContainerStyle}>
    <ExpiredSubscriptionBlocker renderWhenBlocked={renderEmptyDiv}>
      <MobileMapListingContainer />
    </ExpiredSubscriptionBlocker>
    <MobileFooterContainer />
  </div>
);
