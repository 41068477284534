import { type ErrorPageAction } from './errorPage.action';
import {
  ERROR_PAGE_CLEAR_ERROR, ERROR_PAGE_SET_ERROR,
} from './errorPage.actionTypes';
import { type ErrorPageState } from './errorPage.state';

const initialState: ErrorPageState = {
  error: null,
};

type ReducerAction = ErrorPageAction;

export const errorPageReducer = (state = initialState, action: ReducerAction): ErrorPageState => {
  switch (action.type) {
    case ERROR_PAGE_SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case ERROR_PAGE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
