import { css } from '@emotion/react';
import { AlternatingStripesComponent } from '~/_shared/baseComponents/alternatingStripes/alternatingStripes';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  FormattedValue, type FormattedValueProps,
} from '../formattedValue/formattedValue.component';

export const oneDimensionalTableBodyRowItemStyle = css({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '5px 8px',
  width: '100%',
});

const tableWrapperStyle = css({
  boxSizing: 'border-box',
  width: '100%',
});

const tableStyle = (theme: Theme) => css({
  border: `2px solid ${theme.borderColors.primary}`,
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
});

const dataValueNameStyle = (theme: Theme) => css({
  color: theme.textColors.tertiary,
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
});

const dataValueValueStyle = css({
  fontSize: '16px',
  textAlign: 'center',
});

export type OneDimensionalTableBodyProps = Readonly<{
  rows: {
    name: string;
    value?: null | FormattedValueProps;
  }[];
}>;

export const OneDimensionalTableBody = (props: OneDimensionalTableBodyProps) => {
  const [t] = useTranslation();

  return (
    <div css={tableWrapperStyle}>
      <AlternatingStripesComponent css={tableStyle}>
        {props.rows.map((data, index) => (
          <div
            css={oneDimensionalTableBodyRowItemStyle}
            key={index}
          >
            <TooltipComponent
              tooltipContent={data.name || t('Empty')}
              behavior={TooltipBehavior.ShowOnOverflow}
            >
              <div css={dataValueNameStyle}>
                {data.name || t('Empty')}
              </div>
            </TooltipComponent>
            {data.value !== undefined && data.value !== null && (
              <div css={dataValueValueStyle}>
                <FormattedValue
                  {...data.value}
                />
              </div>
            )}
          </div>
        ))}
      </AlternatingStripesComponent>
    </div>
  );
};
