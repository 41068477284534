import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {
  faLockAlt, faX,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FormEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { MFAField } from '~/_shared/components/accountSettings/modalDialogs/components/MFAField.component';
import { useUpdateAccountInformation } from '~/_shared/components/accountSettings/modalDialogs/hooks/useUpdateAccountInformation';
import {
  accountSettingsModalContentStyle, footerButtonStyle, formStyle, hiddenStyle, scrollContentStyle,
} from '~/_shared/components/accountSettings/modalDialogs/shared.styles';
import {
  createErrorMessagesFromMessage, DEFAULT_EMPTY_ERRORS,
} from '~/_shared/components/accountSettings/modalDialogs/utils';
import { useTwoFactorMethod } from '~/_shared/components/accountSettings/twoFactorModal/useTwoFactorSettingData';
import { EmailSentAnimatedTextComponent } from '~/_shared/components/emailSent/emailSentAnimatedText.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { twoFactorToServerModel } from '~/_shared/constants/twoFactorMode.enum';
import { autoFocus } from '~/_shared/utils/form/form.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { validateEmail } from '~/_shared/utils/throttle/validation/validation.helpers';
import type { ModalProps } from '~/modal/modalType.enum';
import { UserFieldName } from '~/store/userData/repository/userData.types';
import { useUserDataSelector } from '~/store/userData/userData.selectors';

export const ChangeEmailModalComponent = (props: ModalProps) => {
  const [t] = useTranslation();
  const { selectedTwoFactorMode } = useTwoFactorMethod();
  const {
    setPhase,
    phase,
    generalError,
    fieldErrors: errorsPerFieldName,
    changeUserData,
    isLoading,
  } = useUpdateAccountInformation();
  const userData = useUserDataSelector();
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [currentPasswordBlurred, setCurrentPasswordBlurred] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const onEmailChange = useCallback((value: string) => {
    if (validateEmail(value)) {
      setEmailError('');
    }
    setEmail(value);
  }, []);

  const enableResend = useCallback(() => setResendEnabled(true), [setResendEnabled]);

  const isEmailDifferent = userData.email.trim() !== email.trim();

  const isSubmitFormDisabled = isLoading
    || (currentPassword === '')
    || (emailError !== '')
    || (email.trim() === '')
    || !isEmailDifferent;

  const handleCurrentPasswordBlur = useCallback(() => {
    setCurrentPasswordBlurred(true);
  }, []);

  useEffect(() => {
    if (currentPasswordBlurred) {
      if (currentPassword === '') {
        setCurrentPasswordError(t('This field is required'));
      }
      else {
        setCurrentPasswordError('');
      }
    }
  }, [currentPassword, currentPasswordBlurred, t]);

  const validateEmailAddress = useCallback(() => {
    if (!validateEmail(email)) {
      setEmailError(t('Invalid email address'));
    }
    if (!isEmailDifferent) {
      setEmailError(t('Choose a different email address'));
    }
  }, [email, isEmailDifferent, t]);

  const changeEmail = useCallback(() => {
    changeUserData({
      mfa: twoFactorToServerModel(selectedTwoFactorMode),
      code: (phase !== 'Form') ? mfaCode : undefined,
      old_password: currentPassword,
      email,
    });
  }, [changeUserData, currentPassword, email, mfaCode, phase, selectedTwoFactorMode]);

  const resaveChanges = useCallback(() => {
    setResendEnabled(false);
    changeEmail();
  }, [changeEmail]);

  const getPasswordFieldMessage = useCallback((additionalMessage?: ReadonlyArray<string>) =>
    createErrorMessagesFromMessage(currentPasswordError).concat(additionalMessage || DEFAULT_EMPTY_ERRORS),
  [currentPasswordError]);

  const onFormSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resaveChanges();
  }, [resaveChanges]);

  const goBackToForm = useCallback(() => {
    setPhase('Form');
  }, [setPhase]);

  const alertMessage = useMemo(() =>
    generalError ? generalError : errorsPerFieldName?.[UserFieldName.code] ? errorsPerFieldName[UserFieldName.code] : null,
  [errorsPerFieldName, generalError]);

  if (phase === 'Success') {
    return (
      <ModalComponent
        key="success"
        caption={t('Change Email')}
        contentStyle={accountSettingsModalContentStyle}
        isOpen={props.isOpen}
        maxWidth={547}
        onClose={props.onClose}
        preventCloseOnOverlayClick
        footer={(
          <ButtonComponent
            buttonStyle={ButtonStyle.Primary}
            css={footerButtonStyle}
            onClick={props.onClose}
            text={t('Close')}
          />
        )}
      >
        <EmailSentAnimatedTextComponent
          onAnimationEnd={enableResend}
          onResend={resaveChanges}
          playAnimation={!resendEnabled}
          text={t('Email change has to be confirmed, please check your email.')}
        />
      </ModalComponent>
    );
  }

  if (phase === 'MFA') {
    return (
      <ModalComponent
        caption={t('Change Email')}
        contentStyle={accountSettingsModalContentStyle}
        isOpen={props.isOpen}
        key={phase}
        maxWidth={547}
        onClose={goBackToForm}
        leftFooterContent={(
          <ButtonComponent
            buttonStyle={ButtonStyle.Secondary}
            onClick={goBackToForm}
            prefixIcon={faX}
            text={t('Back')}
          />
        )}
        additionalContent={isLoading ? (
          <OverlayLoaderComponent />
        ) : null}
      >
        <MFAField
          isResendEnabled={resendEnabled}
          onChange={setMfaCode}
          value={mfaCode}
          onResendEmail={resaveChanges}
          onSubmit={resaveChanges}
          setInputRef={autoFocus}
        />
        {alertMessage && (
          <AlertComponent
            type="danger"
          >
            {alertMessage}
          </AlertComponent>
        )}
      </ModalComponent>
    );
  }

  return (
    <ModalComponent
      caption={t('Change Email')}
      contentStyle={accountSettingsModalContentStyle}
      isOpen={props.isOpen}
      maxWidth={547}
      onClose={props.onClose}
      footer={(
        <ButtonComponent
          buttonStyle={ButtonStyle.Primary}
          css={footerButtonStyle}
          isDisabled={isSubmitFormDisabled}
          onClick={isSubmitFormDisabled ? undefined : resaveChanges}
          text={t('Confirm')}
        />
      )}
      additionalContent={isLoading ? (
        <OverlayLoaderComponent />
      ) : null}
    >
      <ScrollBarComponent
        type={ScrollbarType.Vertical}
        maxHeight={1000}
        translateContentHeightToHolder
        contentStyle={scrollContentStyle}
      >
        <form
          css={formStyle}
          onSubmit={isSubmitFormDisabled ? undefined : onFormSubmit}
        >
          <FormTextInputComponent
            autoTrim
            icon={faEnvelope}
            isReadOnly
            label={t('Current Email Address')}
            onChange={noop}
            value={userData.email}
          />
          <FormTextInputComponent
            autoTrim
            errorMessages={getPasswordFieldMessage(errorsPerFieldName?.[UserFieldName.email])}
            icon={faEnvelope}
            label={t('New Email Address')}
            onBlur={validateEmailAddress}
            onChange={onEmailChange}
            placeholder={t('New Email Address')}
            setInputRef={autoFocus}
            value={email}
          />
          <FormTextInputComponent
            autoComplete="current-password"
            errorMessages={getPasswordFieldMessage(errorsPerFieldName?.[UserFieldName.old_password])}
            icon={faLockAlt}
            label={t('Current Password')}
            onBlur={handleCurrentPasswordBlur}
            onChange={setCurrentPassword}
            type="password"
            value={currentPassword}
          />

          {generalError && (
            <AlertComponent
              type="danger"
            >
              {generalError}
            </AlertComponent>
          )}
          {!isSubmitFormDisabled && (
            // This makes the form submit on Enter
            <input type="submit" css={hiddenStyle} />
          )}
        </form>
      </ScrollBarComponent>
    </ModalComponent>
  );
};
