import {
  type FC, useEffect, useState,
} from 'react';
import { EditLocationModalComponent } from '~/_shared/components/editLocationModal/editLocationModal.component';
import {
  type ChangeableSettingRow, type SettingTextData,
} from '~/_shared/components/settingsTable/settingsTable.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { copy } from '~/_shared/utils/collections/collections';
import { useTranslation } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import { useSpreadsheetRowData } from '~/spreadsheet/useSpreadsheetRowData';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUpdateSpreadsheetData } from '../../data/useUpdateSpreadheetData';
import {
  type EditDataTableRowWithColumnId, getEditDataTableRowsWithColumnIdsFromMatchup,
} from './editLocation.helpers';

export type EditLocationModalContainerProps = ModalProps<{
  spreadsheetRowId: SpreadsheetRowId;
}>;

export const EditLocationModalContainer: FC<EditLocationModalContainerProps> = (props) => {
  const [rows, setRows] = useState<EditDataTableRowWithColumnId[]>([]);
  const matchupData = useMatchupDataSelector();
  const clientId = useClientIdSelector();
  const [t] = useTranslation();

  const { oneRow: { rowData: spreadsheetRowData, isLoading } } = useSpreadsheetRowData(props.spreadsheetRowId);
  const { isLoading: isUpdateLoading, updateSpreadsheetData, isError: isUpdateError, clearUpdateError } = useUpdateSpreadsheetData();

  const mapNow = () => {
    if (!clientId || !spreadsheetRowData || rows.length === 0) {
      return;
    }

    const requestData = rows.reduce<{ [columnId: string]: string }>((acc, row) => {
      acc[row.columnId] = row.value;
      return acc;
    }, {});

    updateSpreadsheetData(
      clientId,
      props.spreadsheetRowId.spreadsheetId, {
        params: [{
          row_id: props.spreadsheetRowId.rowId,
          version: spreadsheetRowData.version,
          data: requestData,
        }],
      },
      props.onClose,
    );
  };

  const isAnyRowFilled = !isTextEmpty(rows.map(row => row.value).join(''));

  const changeRow = (rowIndex: number, newRow: ChangeableSettingRow) => {
    const currentRow = rows[rowIndex];
    if (currentRow) {
      const newRows = copy.andReplace(rows, rowIndex, {
        ...currentRow,
        value: (newRow.data[1] as SettingTextData).value,
      });
      setRows(newRows);
    }
  };

  useEffect(() => {
    if (!spreadsheetRowData) {
      return;
    }

    setRows(getEditDataTableRowsWithColumnIdsFromMatchup(
      matchupData, props.spreadsheetRowId.spreadsheetId, spreadsheetRowData.columnsData,
    ));
  }, [spreadsheetRowData, matchupData, props.spreadsheetRowId.spreadsheetId]);

  return (
    <EditLocationModalComponent
      {...props}
      caption={t('Edit New Marker Information')}
      onChange={changeRow}
      isSubmitDisabled={!isAnyRowFilled || isLoading}
      onSubmit={mapNow}
      rows={rows}
      isLoading={isLoading || isUpdateLoading}
      errorMessage={isUpdateError ? t('An error occurred while updating the data') : ''}
      onErrorMessageClose={clearUpdateError}
      showAddAnotherLocation={false}
    />
  );
};
