import {
  type FC, useMemo,
} from 'react';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';

type TripleNeonArrowProps = {
  color: string;
  dimensions: QuadrilateralDimensions;
};

export const TripleNeonArrowComponent: FC<TripleNeonArrowProps> = (props) => {
  const [innerBorderColor, outerBorderColor] = useMemo(() => {
    const baseHsl = createColor(props.color).hsl;
    const innerHsl = { ...baseHsl, l: baseHsl.l + 0.2 };
    const outerHsl = { ...baseHsl, l: baseHsl.l - 0.1 };

    return [createColor(innerHsl).hex, createColor(outerHsl).hex];
  }, [props.color]);

  return (
    <svg
      width={props.dimensions.width}
      height={props.dimensions.height}
      viewBox="0 0 56 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="outer-arrow"
        d="M3.30784 37.7129L3.43721 37.9112L3.60147 38.0816L25.0469 60.3297L25.0912 60.3757L25.1379 60.4193C26.0091 61.2353 27.1187 61.5 28.0038 61.5C28.8935 61.5 29.9853 61.2338 30.8533 60.4487L30.915 60.3928L30.9729 60.333L52.4972 38.0849L52.6281 37.9496L52.7372 37.7963C53.5285 36.6847 53.6893 35.1733 53.2864 33.9239C53.0648 33.2368 52.6367 32.5127 51.9136 31.9626C51.1752 31.4009 50.291 31.144 49.3825 31.144H42.104L42.3607 10.7062L42.3609 10.6905V10.6748C42.3609 7.91394 41.2532 5.68918 39.1985 4.26342C37.2916 2.9402 34.8494 2.5 32.4396 2.5H23.2297C20.8316 2.5 18.4693 2.93493 16.679 4.36295C14.7716 5.88431 13.9921 8.13395 13.9921 10.6748V31.144H6.39196C5.51727 31.144 4.58939 31.38 3.82009 32.024C3.05682 32.6629 2.70235 33.4922 2.57049 34.2083C2.32942 35.5176 2.73558 36.8361 3.30784 37.7129Z"
        stroke={guaranteeHash(outerBorderColor)}
        strokeWidth="5"
      />
      <path
        className="inner-arrow"
        d="M6.39196 33.644C4.66027 33.644 4.8197 35.4553 5.40141 36.3466L26.8468 58.5947C27.4239 59.1351 28.5787 59.1351 29.1762 58.5947L50.7005 36.3466C51.2775 35.5359 51.1137 33.644 49.3825 33.644H39.5724L39.8609 10.6748C39.8609 6.62137 36.7676 5 32.4396 5H23.2297C18.9017 5 16.4921 6.62137 16.4921 10.6748V33.644H6.39196Z"
        fill={guaranteeHash(props.color)}
        stroke={guaranteeHash(innerBorderColor)}
        strokeWidth="1.5"
      />
    </svg>
  );
};
