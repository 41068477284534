import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { useMapContext } from '~/map/map/mapContext';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';
import {
  startMoveMarkerLabelDrag, stopMoveMarkerLabelDrag, updateMarkerLabelPosition,
} from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionCreators';
import { useMoveMarkerLabelsIsActiveSelector } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.selectors';
import { type MoveMarkerLabelsOffset } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.state';
import { type SpreadsheetRowId } from '../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { type MarkerWebglMapObjects } from '../manager/mapMarkerManager.helpers';
import { type MapMarkerEventCallback } from '../useMarkerEvents/markerEventCallbacks.type';
import { useMoveMarkerLabelsXMark } from './useMoveMarkerLabelsXMark';

type HoveredMarker = {
  id: SpreadsheetRowId;
  getMapObjects: () => MarkerWebglMapObjects | null;
};

export const useMoveMarkerLabels = () => {
  const { map } = useMapContext();
  const moveMarkerLabelsActive = useMoveMarkerLabelsIsActiveSelector();
  const [hoveredMarker, setHoveredMarker] = useState<HoveredMarker | null>(null);
  const [draggedMarker, setDraggedMarker] = useState<HoveredMarker | null>(null);
  const [xMarkButtonPosition, setXmarkButtonPosition] = useState<{ id: SpreadsheetRowId; anchor: LatLng } | null>(null);
  const [draggedLabelOffset, setDraggedLabelOffset] = useState<MoveMarkerLabelsOffset | null>(null);
  const [startLatLng, setStartLatLng] = useState<LatLng | null>(null);
  const { fromLatLngToDivPixel } = useProjectionOverlay(map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!moveMarkerLabelsActive) {
      setHoveredMarker(null);
      setXmarkButtonPosition(null);
    }
  }, [moveMarkerLabelsActive]);

  const onXMarkClick = useCallback(() => {
    if (xMarkButtonPosition) {
      dispatch(updateMarkerLabelPosition({ id: xMarkButtonPosition.id, type: 'default' }));
      setXmarkButtonPosition(null);
    }
  }, [dispatch, xMarkButtonPosition]);
  useMoveMarkerLabelsXMark({ anchor: xMarkButtonPosition?.anchor, onXMarkClick });

  useMapObjectDragAndDrop(() => ({
    map,
    addDragHandlerInstantly: true,
    dragDisabled: !(moveMarkerLabelsActive && hoveredMarker),
    onDragStart: () => {
      const marker = hoveredMarker?.getMapObjects()?.marker;

      if (!marker || marker?.entity !== 'label' || !marker.object.label) {
        return;
      }

      setDraggedMarker(hoveredMarker);
      setXmarkButtonPosition(null);

      dispatch(startMoveMarkerLabelDrag());
      setStartLatLng({ lat: marker.object.label.lat, lng: marker.object.label.lng });

      if (marker?.entity === 'label' && marker.object.type === 'text') {
        dispatch(updateMarkerLabelPosition({
          id: hoveredMarker.id,
          type: 'custom',
          y: (marker.object.callout?.triangleHeight ?? 0) * -1,
          x: 0,
        }));
      }
    },
    onDragMove: (latLng => {
      if (!draggedMarker || !startLatLng) {
        return;
      }

      const startPosition = fromLatLngToDivPixel(startLatLng);
      const cursorPosition = fromLatLngToDivPixel(latLng);

      if (!startPosition || !cursorPosition) {
        return;
      }

      const offset: MoveMarkerLabelsOffset = {
        id: draggedMarker.id,
        type: 'custom',
        x: cursorPosition.x - startPosition.x,
        y: cursorPosition.y - startPosition.y,
      };

      const marker = draggedMarker.getMapObjects()?.marker;

      if (marker?.entity === 'label' && marker.object.type === 'custom') {
        const { label } = marker.object;

        if (label) {
          label.offset.x = offset.x;
          label.offset.y = offset.y;
        }
      }

      setDraggedLabelOffset(offset);
    }),
    onDragEnd: () => {
      if (draggedLabelOffset && draggedMarker) {
        dispatch(updateMarkerLabelPosition(draggedLabelOffset));

        const marker = draggedMarker.getMapObjects()?.marker;
        if (marker?.entity === 'label' && marker.object.label && marker.object.type === 'custom') {
          setXmarkButtonPosition({
            id: draggedLabelOffset.id,
            anchor: { lat: marker.object.label.lat, lng: marker.object.label.lng },
          });
        }
      }

      dispatch(stopMoveMarkerLabelDrag());
      setDraggedLabelOffset(null);
      setDraggedMarker(null);
    },
  }), [dispatch, draggedLabelOffset, draggedMarker, fromLatLngToDivPixel, hoveredMarker,
    map, moveMarkerLabelsActive, startLatLng]);

  const onMouseOver: MapMarkerEventCallback = useCallback((
    spreadsheetRowIds: SpreadsheetRowId[],
    isStacked: boolean,
    getMapObjects: () => MarkerWebglMapObjects | null
  ) => {
    if (isStacked) {
      return;
    }

    const isNotSingle = spreadsheetRowIds.length !== 1;
    if (isNotSingle) {
      console.error('Something went wrong, calling drag and drop on stacked marker');
      return;
    }

    const markerMapObjects = getMapObjects();
    if (!markerMapObjects || markerMapObjects.marker?.entity !== 'label') {
      return;
    }

    const marker = markerMapObjects.marker;
    const id = spreadsheetRowIds[0];
    setHoveredMarker({ getMapObjects, id });

    if (marker.object.label && markerMapObjects.marker.object.type === 'custom') {
      setXmarkButtonPosition({
        id,
        anchor: { lat: marker.object.label.lat, lng: marker.object.label.lng },
      });
    }
  }, []);

  const onMouseOut: MapMarkerEventCallback = useCallback((
    _spreadsheetRowIds: SpreadsheetRowId[],
    isStacked: boolean,
  ) => {
    if (!isStacked) {
      setHoveredMarker(null);
    }
  }, []);

  const moveMarkerLabelCallbacks = useMemo(() => ({
    onMouseOver,
    onMouseOut,
  }), [onMouseOut, onMouseOver]);

  return {
    moveMarkerLabelsActive,
    moveMarkerLabelCallbacks,
  };
};
