import { css } from '@emotion/react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { type FileRejection } from 'react-dropzone';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  DropzoneComponent, type DropzoneProps,
} from '~/_shared/components/dropzone/dropzone.component';
import { ImageWithDeleteComponent } from '~/_shared/components/image/imageWithDelete.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import { MAX_TILE_DIMENSIONS } from '~/customizeMarkerPopup/customizeMarker/customizeMarker.constants';
import { ImageSelectorComponent } from '../../../../_shared/components/imageSelector/imageSelector.component';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { useElementDimensions } from '../../../../_shared/utils/hooks/useElementDimensions';
import { drawingToolEditableOtherOptionsName } from '../../../drawingTool.enums';

export type DrawingToolSelectorImage = Readonly<{
  height: number;
  id: number;
  url: string;
  width: number;
}>;

const galleryStyle = css({
  boxSizing: 'border-box',
  paddingBottom: '8px',
});

const galleryLabelStyle = css({
  cursor: 'default',
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '8px',
});

type DrawingToolImageOptionsProps = Readonly<{
  images: ReadonlyArray<DrawingToolSelectorImage>;
  selectedImageId: number | null;
  acceptFileExtensions: ReadonlyArray<string>;

  onSelectImage: (id: number) => void;

  onFileAccept: (files: File[]) => void;
  onFileReject: (rejections: FileRejection[]) => void;
  onImageDelete: (fileId: number) => void;
}>;

const TILE_DIMENSIONS = MAX_TILE_DIMENSIONS;
const IMAGE_SELECTOR_DROPZONE_ID = Number.MIN_SAFE_INTEGER;

const rootStyle = css({
  width: '100%',
});

const dropzoneStyle = css({
  boxSizing: 'border-box',
  height: TILE_DIMENSIONS.height,
  marginBottom: '32px',
  width: 250,
});

const dropzoneInSelectorStyle = css({
  boxSizing: 'border-box',
  height: TILE_DIMENSIONS.height,
  width: TILE_DIMENSIONS.width,
});

const imageStyle = css({
  backgroundColor: 'inherit',
});

export const DrawingToolImageOptionsComponent: React.FC<DrawingToolImageOptionsProps> = props => {
  const [t] = useTranslation();
  const { ref, width } = useElementDimensions();

  const defaultTileDimension = 150;
  const tilePadding = 12;
  const defaultTileDimensionWithPadding = defaultTileDimension + tilePadding;
  let tileDimension = width && width > defaultTileDimensionWithPadding
    ? defaultTileDimension + ((width % defaultTileDimensionWithPadding) / (width / defaultTileDimensionWithPadding))
    : defaultTileDimension;
  tileDimension = tileDimension / 3;

  const commonDropzoneProps: DropzoneProps = useMemo(() => ({
    acceptFileExtensions: props.acceptFileExtensions,
    isDisabled: false,
    isMulti: true,
    maxSize: 2000000,
    onFilesReject: props.onFileReject,
    onFilesSelect: props.onFileAccept,
    secondaryLabel: '',
  }), [props.acceptFileExtensions, props.onFileAccept, props.onFileReject]);

  const isEmptyImages = useMemo(() => props.images.length === 0, [props.images]);
  const imageSelectorDropzoneItem = useMemo(() => ({
    id: IMAGE_SELECTOR_DROPZONE_ID,
    render: () => (
      <DropzoneComponent
        {...commonDropzoneProps}
        css={dropzoneInSelectorStyle}
        label={<FontAwesomeIcon icon={faPlus} />}
      />
    ),
  }), [commonDropzoneProps]);

  const { onImageDelete } = props;
  const images = useMemo(() => {
    const imageList = props.images.map(image => ({
      id: image.id,
      render: () => (
        <ImageWithDeleteComponent
          {...image}
          css={imageStyle}
          src={image.url}
          width={tileDimension}
          height={tileDimension}
          onDelete={() => onImageDelete(image.id)}
        />
      ),
    }));

    return [imageSelectorDropzoneItem, ...imageList];
  }, [imageSelectorDropzoneItem, onImageDelete, props.images, tileDimension]);

  return (
    <div
      ref={ref}
      css={rootStyle}
    >
      <OverlayWrapperComponent>
        <div css={galleryLabelStyle}>
          {drawingToolEditableOtherOptionsName(t).image + ':'}
        </div>
        {isEmptyImages && (
          <DropzoneComponent
            {...commonDropzoneProps}
            css={dropzoneStyle}
            primaryLabel={t('Upload New Image')}
          />
        )}
        {!isEmptyImages && (
          <ImageSelectorComponent
            css={galleryStyle}
            imageHeight={tileDimension}
            imageWidth={tileDimension}
            images={images}
            onSelectionChanged={(ids: ReadonlyArray<number>) => {
              if (ids[0]) {
                props.onSelectImage(ids[0]);
              }
            }}
            paddingHorizontal={tilePadding}
            paddingVertical={tilePadding}
            selectedImagesIds={props.selectedImageId !== null ? [props.selectedImageId] : []}
            dontUseScrollbar
          />
        )}
      </OverlayWrapperComponent>
    </div>
  );
};
