import type { NonEmptyArray } from 'ts-essentials';
import {
  apiGet, apiPost, apiPut,
} from '~/_shared/utils/api/api.helpers';
import { stringTemplate } from '~/_shared/utils/string/stringTemplate';
import { v4MapsPerRequest } from '~/store/mapMigration/mapMigration.constants';
import type {
  V4MapListSuccessResponse, V4MapMigrateSuccessResponse,
} from '~/store/mapMigration/types/mapMigrationResponse.types';

const v4MapEndpoint = stringTemplate`/api/clients/${'clientId'}/migration/get-maps-from-v4`;
const v4MapMigrateEndpoint = stringTemplate`/api/clients/${'clientId'}/migration/initiate`;
const v4MapMigrateQueueDismissEndpoint = stringTemplate`/api/clients/${'clientId'}/migration/dismiss`;

export const getV4Maps = (clientId: number): Promise<V4MapListSuccessResponse> => {
  const requestUrl = v4MapEndpoint({ clientId });
  const requestParams = {
    sort: 'created_at_desc',
    per_page: v4MapsPerRequest,
  };

  return apiGet(requestUrl, requestParams);
};

export const migrateV4Maps = (clientId: number, v4MapIdsToMigrate: NonEmptyArray<number>): Promise<V4MapMigrateSuccessResponse> => {
  const requestUrl = v4MapMigrateEndpoint({ clientId });

  return apiPost(requestUrl, {
    map_ids: v4MapIdsToMigrate,
  });
};

export const dismissMigrationQueue = (clientId: number, queueId: string): Promise<V4MapMigrateSuccessResponse> => {
  const requestUrl = v4MapMigrateQueueDismissEndpoint({ clientId });

  return apiPut(requestUrl, {
    queue_id: queueId,
  });
};

export const migrateAllV4Maps = (clientId: number): Promise<V4MapMigrateSuccessResponse> => {
  const requestUrl = v4MapMigrateEndpoint({ clientId });

  return apiPost(requestUrl, {});
};
