import memoizee from 'memoizee';
import tinycolor, { type ColorInputWithoutInstance } from 'tinycolor2';
import { memoizeWeak } from '~/_shared/utils/memoize/memoize';
import { type StorageService } from '../../utils/storageService';
import { isString } from '../../utils/typeGuards';
import {
  type ColorResult,
  type HSLColor, type RGBColor,
} from './colorPicker.types';

export const isValidHex = (hex: string): boolean => {
  const lh = (String(hex).startsWith('#')) ? 1 : 0;
  return hex.length !== (4 + lh) && hex.length < (7 + lh) && tinycolor(hex).isValid();
};

const createHexColor = (color: ColorInputWithoutInstance): string => guaranteeHash(tinycolor(color).toHex());
const createRgbColor = (color: ColorInputWithoutInstance): RGBColor => tinycolor(color).toRgb();
const createHslColor = (color: ColorInputWithoutInstance): HSLColor => tinycolor(color).toHsl();

const createColorInternal = (color: ColorInputWithoutInstance): ColorResult => {
  const hex = createHexColor(color);
  const hsl = createHslColor(color);
  const rgb = createRgbColor(color);

  return { hex, hsl, rgb };
};

type RemoveString<T> = Exclude<T, string>;
const createColorNoString = (color: RemoveString<ColorInputWithoutInstance>) => createColorInternal(color);
const createColorNoStringMemoized = memoizeWeak(createColorNoString);

const createColorFromString = (color: string) => createColorInternal(color);
const createColorFromStringMemoized = memoizee(createColorFromString, { primitive: true });

export const createColor = (color: ColorInputWithoutInstance): ColorResult => {
  if (isString(color)) {
    return createColorFromStringMemoized(color);
  }

  return createColorNoStringMemoized(color);
};

export const removeHash = (hex: string) => hex.replace('#', '');
export const guaranteeHash = (hex: string) => hex.startsWith('#') ? hex : '#' + hex;

export const hexColorWithOpacityToString = (color: string, opacity: number) => {
  const newColor = createColorWithOpacity(color, opacity);
  return rgbColorToString(newColor.rgb);
};

export const rgbColorToString = memoizeWeak((color: RGBColor): string => {
  if (color.a || color.a === 0) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }
  return `rgb(${color.r}, ${color.g}, ${color.b})`;
});

export const hexAndOpacityToRGBA = memoizee((hex: string, opacity?: number): RGBColor => {
  const rgbColor = createRgbColor(hex);

  return { ...rgbColor, a: opacity ?? 1 };
});

export const createColorWithOpacity = (hex: string, opacity?: number): ColorResult => {
  return createColor(hexAndOpacityToRGBA(hex, opacity));
};

const PRESET_COLORS_STORAGE_KEY = 'preset-colors-color-picker';
export const getPresetColors = (storageService: StorageService) => {
  const storedState = storageService.getLocalStorageItem(PRESET_COLORS_STORAGE_KEY);

  if (storedState) {
    const colors: string[] = JSON.parse(storedState);

    return colors;
  }

  return [];
};

const MAX_PRESET_COLORS = 8;
export const getMaxAmountOfColors = (colors: readonly string[], count?: number) =>
  colors.slice(0, count || MAX_PRESET_COLORS);

export const storePresetColor = (storageService: StorageService, hex: string) => {
  const presetColors = getPresetColors(storageService).filter(c => c !== hex);

  presetColors.unshift(hex);

  storageService.setLocalStorageItem(PRESET_COLORS_STORAGE_KEY, JSON.stringify(getMaxAmountOfColors(presetColors)));
};
