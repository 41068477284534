import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  disableLocationTracking, enableLocationTracking,
} from '~/store/frontendState/mapTools/myLocation/myLocation.actionCreators';
import {
  myLocationErrorSelector, myLocationLoadingSelector, myLocationSelector, myLocationTrackingEnabledSelector,
} from '~/store/frontendState/mapTools/myLocation/myLocation.selectors';
import { mapComponentSetCenterAndZoom } from '../../../store/frontendState/mapComponent/mapComponent.actionCreators';
import { useSelector } from './useSelector';
import { useTimeout } from './useTimeout';

export const useFindMyLocationWithErrorCooldown = () => {
  const dispatch = useDispatch();
  const myLocation = useSelector(myLocationSelector);
  const [shouldRecentreMap, setShouldRecentreMap] = useState(false);

  const locationTrackingEnabled = useSelector(myLocationTrackingEnabledSelector);
  const isLoading = useSelector(myLocationLoadingSelector);
  const isError = useSelector(myLocationErrorSelector);

  const [isErrorTooltipShown, startErrorTooltipShown] = useTimeout(4000);

  const handleFindLocationClick = useCallback(() => {
    if (locationTrackingEnabled) {
      dispatch(disableLocationTracking());
    }
    else {
      setShouldRecentreMap(true);
      dispatch(enableLocationTracking());
    }
  }, [dispatch, locationTrackingEnabled]);

  useEffect(() => {
    if (!isLoading && shouldRecentreMap && myLocation) {
      setShouldRecentreMap(false);
      dispatch(mapComponentSetCenterAndZoom(myLocation, 12));
    }
  }, [dispatch, isLoading, myLocation, shouldRecentreMap]);

  useEffect(() => {
    if (isError) {
      setShouldRecentreMap(false);
      dispatch(disableLocationTracking());
      startErrorTooltipShown();
    }
  }, [dispatch, isError, startErrorTooltipShown]);

  return useMemo(() => ({
    handleFindLocationClick,
    isError,
    isErrorTooltipShown,
    isLoading,
    locationTrackingEnabled,
  }), [handleFindLocationClick, isError, isErrorTooltipShown, isLoading, locationTrackingEnabled]);
};
