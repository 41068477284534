import { type UndoItem } from '../../_shared/types/undo/undoItem';
import {
  ALL_UNDO_ITEMS_FETCH_DATA_SUCCESS,
  DELETE_UNDO_ITEM,
  DELETED_MAPS_FETCH_DATA_ERROR,
  DELETED_MAPS_FETCH_DATA_REQUEST,
} from './undo.actionTypes';

export const deletedMapsFetchDataRequest = (clientId: number) => ({
  type: DELETED_MAPS_FETCH_DATA_REQUEST,
  payload: {
    clientId,
  },
}) as const;

export const deletedMapsFetchDataError = () => ({
  type: DELETED_MAPS_FETCH_DATA_ERROR,
}) as const;

export const allUndoItemsFetchDataSuccess = (data: UndoItem[]) => ({
  type: ALL_UNDO_ITEMS_FETCH_DATA_SUCCESS,
  payload: {
    data,
  },
}) as const;

export const deleteMapsUndoItem = (data: UndoItem) => ({
  type: DELETE_UNDO_ITEM,
  payload: {
    data,
  },
}) as const;
