import { useCallback } from 'react';
import { type ColumnRole } from '../../types/columnRole.enum';
import { useCategoryValueResolver } from './useCategoryValueResolver';

export const useMarkerPropertyResolver = (markerPropertyName: ColumnRole) => {
  const { categoryValueResolver } = useCategoryValueResolver();

  return useCallback((spreadsheetId: number, rowId: string) => {
    return categoryValueResolver(markerPropertyName, spreadsheetId, rowId);
  }, [categoryValueResolver, markerPropertyName]);
};
