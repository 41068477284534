import {
  type FC, useCallback, useContext,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import type { GridElement } from '~/_shared/components/spreadsheet/spreadsheet.types';
import ValueViewerProps = ReactDataSheet.ValueViewerProps;
import { SpreadsheetDataEditorDisabledComponent } from '../dataEditor/spreadsheetDataEditorDisabled.component';
import { SpreadsheetContext } from '../spreadsheetContext';

export const SpreadsheetValueViewerComponent: FC<ValueViewerProps<GridElement>> = ({ value, row, col }) => {
  const { getSpreadsheetCellForbiddenMessage, onCloseSpreadsheetCellForbiddenMessage } = useContext(SpreadsheetContext);

  const forbiddenMessage = getSpreadsheetCellForbiddenMessage?.(row, col);

  const handleCloseSpreadsheetCellForbiddenMessage = useCallback(() => {
    onCloseSpreadsheetCellForbiddenMessage(row, col);
  }, [onCloseSpreadsheetCellForbiddenMessage, row, col]);

  if (forbiddenMessage) {
    return (
      <SpreadsheetDataEditorDisabledComponent
        disabledNotice={forbiddenMessage}
        onClose={handleCloseSpreadsheetCellForbiddenMessage}
      />
    );
  }

  return (
    <>
      {value?.toString() ?? ''}
    </>
  );
};
