import { css } from '@emotion/react';
import { type Theme } from '../../themes/theme.model';

export const appStyle = (theme: Theme) => css({
  color: theme.textColors.primary,
  background: theme.backgroundColors.primary,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
