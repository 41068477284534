import { type DrawingItem } from '../../../../mapSettings/drawing/items/drawingItems.state';
import {
  FRONTEND_STATE_DRAWING_EDIT_CLEAR_SELECTED_DRAWING,
  FRONTEND_STATE_DRAWING_EDIT_CONFIRM_CHANGES,
  FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES,
  FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT, FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
  FRONTEND_STATE_DRAWING_EDIT_UNDO_LAST_CHANGE,
} from './drawingEdit.actionTypes';

export const drawingEditSetSelectedDrawing = (
  id: Uuid, drawingItem: DrawingItem
) => ({
  type: FRONTEND_STATE_DRAWING_EDIT_SET_SELECTED_DRAWING,
  payload: {
    id,
    drawingItem,
  },
}) as const;

export const drawingEditClearSelectedDrawing = () => ({
  type: FRONTEND_STATE_DRAWING_EDIT_CLEAR_SELECTED_DRAWING,
}) as const;

export const drawingEditPushNewSnapshot = (snapshot: DrawingItem) => ({
  type: FRONTEND_STATE_DRAWING_EDIT_PUSH_NEW_SNAPSHOT,
  payload: {
    snapshot,
  },
}) as const;

export const drawingEditUndoLastChange = () => ({
  type: FRONTEND_STATE_DRAWING_EDIT_UNDO_LAST_CHANGE,
}) as const;

export const drawingEditDiscardChanges = () => ({
  type: FRONTEND_STATE_DRAWING_EDIT_DISCARD_CHANGES,
}) as const;

export const drawingEditConfirmChanges = () => ({
  type: FRONTEND_STATE_DRAWING_EDIT_CONFIRM_CHANGES,
}) as const;
