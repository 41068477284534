import { type TranslationFnc } from '../../../../../_shared/utils/hooks';

export enum FilterConjunction {
  And = 'and',
  Or = 'or',
}

export const renderFilterConjunction = (conjunction: FilterConjunction, t: TranslationFnc): string => {
  switch (conjunction) {
    case FilterConjunction.And:
      return t('And');
    case FilterConjunction.Or:
      return t('Or');
    default:
      throw new Error(`Unknown conjunction '${conjunction}'`);
  }
};
