import {
  MAP_CHANGE,
  MAP_PAGE_INIT, MAP_RESET,
} from './map.actionTypes';

export const mapReset = () => ({
  type: MAP_RESET,
}) as const;

export const mapPageInit = () => ({
  type: MAP_PAGE_INIT,
}) as const;

export const mapChange = (mapId: number) => ({
  type: MAP_CHANGE,
  payload: { mapId },
}) as const;
