import {
  getLabelPaddingsValues, initialLabelVisualizerBodyProps,
} from '~/_shared/components/labelVisualizer/labelVisualizer.constants';
import { type Theme } from '~/_shared/themes/theme.model';
import { type LatLng } from '~/_shared/types/latLng';
import {
  type LabelVisualSetting, type MarkerSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { copy } from '~/_shared/utils/collections/collections';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { getLabelStroke } from '~/_shared/utils/labels/labels.helpers';
import { notNull } from '~/_shared/utils/typeGuards';
import {
  createLabelWithBodyVirtualLayer, createWebglOverlayLabelWithBody, type LabelWithBodyVirtualLayer,
  type WebglOverlayLabelWithBody,
} from '~/_shared/utils/webgl/labelWithBody';
import {
  getDirectionsTileColors, getDirectionsTilePosition,
} from '~/directions/panel/input/directionsPanelInput.helpers';
import { type RouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { type DirectionsWaypointZIndexSubEntity } from '../../zIndexes/useDirectionsRouteZIndexes.hook';
import { type ExtendsWebglLayers } from '../webgl/useWebGL';

type RenderedRouteData = Readonly<{
  renderers: ReadonlyArray<google.maps.DirectionsRenderer>;
  color: string;
  labels: ReadonlyArray<WebglOverlayLabelWithBody>;
}>;

type RequiredLayers = ExtendsWebglLayers<{
  MarkerLabelsText: LabelsLayer;
  MarkerLabelsTextBoxBackground: TextBoxCalloutLayer;
}>;

type MouseEventCallbacks = Readonly<{
  onClick: (markerId: SpreadsheetRowId) => void;
  onMouseOut: (markerId: SpreadsheetRowId) => void;
  onMouseOver: (markerId: SpreadsheetRowId) => void;
}>;

const labelTriangleWidth = 3.5;
const labelTriangleHeight = 3.5;

const getWaypointsColors = (props: {
  index: number; totalWaypoints: number; theme: Theme; routeColor: string;
}) => {
  const position = getDirectionsTilePosition(props.index, props.totalWaypoints);
  const routeColors = getDirectionsTileColors(position, props.theme, props.routeColor);

  return {
    background: routeColors.background,
    text: routeColors.textColor,
  };
};

export const getDirectionsLabelMarkerSettings = (props: {
  index: number; totalWaypoints: number; theme: Theme; routeColor: string;
}): Readonly<MarkerSettings & { label: LabelVisualSetting }> => {
  const waypointsColors = getWaypointsColors(props);

  return {
    label: {
      arrowProps: {
        dimensions: { height: labelTriangleHeight, width: labelTriangleWidth },
      },
      bodyProps: {
        backgroundColor: { selectedColor: waypointsColors.background },
        dimensions: {},
        fontColor: { selectedColor: waypointsColors.text },
        fontSize: 17,
        padding: initialLabelVisualizerBodyProps.padding,
      },
      borderProps: {
        color: { selectedColor: '#000' },
        radius: 5,
        width: 0.9,
      },
      useLabel: true,
    },
    useMarker: false,
  };
};

export class MapDirectionsManager {
  private readonly map: google.maps.Map;
  private readonly labelsVirtualLayer: LabelWithBodyVirtualLayer;
  private renderedRoutes: ReadonlyMap<string, RenderedRouteData> = new Map();

  constructor(map: google.maps.Map, layers: RequiredLayers) {
    this.map = map;
    this.labelsVirtualLayer = createLabelWithBodyVirtualLayer(layers.MarkerLabelsText, layers.MarkerLabelsTextBoxBackground);
  }

  renderRoute(
    route: MapSettingsRoute,
    routeUiData: RouteUiData,
    mouseEventCallbacks: MouseEventCallbacks,
    waypointZIndexes: ReadonlyArray<{ [key in DirectionsWaypointZIndexSubEntity]: number }>,
    isDraggable: boolean,
    onRouteChange: (routeId: string, routes: ReadonlyArray<google.maps.DirectionsRoute>) => void,
    theme: Theme
  ) {
    const renderers = routeUiData.apiResponses.map(directions => {
      const directionsRenderer = new google.maps.DirectionsRenderer({
        map: this.map,
        suppressMarkers: false,
        markerOptions: { visible: false },
        polylineOptions: { strokeColor: route.color, strokeWeight: 4 },
        draggable: isDraggable,
        preserveViewport: true,
        directions,
      });

      directionsRenderer.addListener('directions_changed', () => {
        const newDirections = directionsRenderer.getDirections();

        if (!newDirections) {
          return;
        }

        onRouteChange(route.id, newDirections.routes);
      });

      return directionsRenderer;
    });

    if (this.renderedRoutes.has(route.id)) {
      this.removeRoute(route.id);
    }

    const lastWaypointIndex = route.waypoints.length - 1;
    const labels = route.waypoints.map((waypoint, index) => {
      const routeDataLatLng = index === lastWaypointIndex
        ? routeUiData.legs[index - 1]?.end_location
        : routeUiData.legs[index]?.start_location;

      const latLng = waypoint.latLng || (routeDataLatLng ? { lat: routeDataLatLng.lat(), lng: routeDataLatLng.lng() } : null);

      if (!latLng) {
        return null;
      }

      const label = createLabel(
        latLng,
        index,
        route.waypoints.length,
        waypointZIndexes[index] || { text: 0, background: 0 },
        theme,
        route.color
      );

      const markerId = waypoint.markerId;
      if (markerId) {
        label.textBoxCallout.addEventListener('click', () => mouseEventCallbacks.onClick(markerId));
        label.textBoxCallout.addEventListener('mouseout', () => mouseEventCallbacks.onMouseOut(markerId));
        label.textBoxCallout.addEventListener('mouseover', () => mouseEventCallbacks.onMouseOver(markerId));
      }
      this.labelsVirtualLayer.add(label);

      return label;
    }).filter(notNull);

    this.renderedRoutes = copy.andAdd(
      this.renderedRoutes,
      [[route.id, { renderers, color: route.color, labels }]],
      (newValue, oldValue) => {
        oldValue.renderers.forEach(removeRendererFromMap);
        return newValue;
      });
  }

  removeRoute(routeId: string) {
    this.renderedRoutes.get(routeId)?.renderers.forEach(removeRendererFromMap);
    this.renderedRoutes.get(routeId)?.labels.forEach(label => this.labelsVirtualLayer.remove(label));
    this.renderedRoutes = copy.andRemove(this.renderedRoutes, [routeId]);
  }

  destroy() {
    this.renderedRoutes.forEach((_, key) => this.removeRoute(key));
  }
}

const removeRendererFromMap = (renderer: google.maps.DirectionsRenderer) => renderer.setMap(null);

const createLabel = (
  latLng: LatLng,
  index: number,
  totalWaypoints: number,
  zIndex: { [key in DirectionsWaypointZIndexSubEntity]: number },
  theme: Theme,
  routeColor: string
): WebglOverlayLabelWithBody => {
  const labelSettings = getDirectionsLabelMarkerSettings({ index, totalWaypoints, theme, routeColor }).label;
  const paddings = getLabelPaddingsValues(labelSettings.bodyProps);
  const stroke = getLabelStroke(labelSettings.bodyProps);

  return createWebglOverlayLabelWithBody({
    lat: latLng.lat,
    lng: latLng.lng,
    text: {
      value: (index + 1).toString(),
      strokeWidth: stroke.strokeWidth,
      strokeColor: stroke.strokeColor,
      fillColor: convertColorToWebGLColor(labelSettings.bodyProps.fontColor.selectedColor),
      fontSize: labelSettings.bodyProps.fontSize,
    },
    borderColor: convertColorToWebGLColor(labelSettings.borderProps.color.selectedColor),
    borderWidth: labelSettings.borderProps.width,
    borderRadius: labelSettings.borderProps.radius,
    fillColor: convertColorToWebGLColor(labelSettings.bodyProps.backgroundColor.selectedColor),
    triangle: true,
    triangleWidth: labelSettings.arrowProps.dimensions.width,
    triangleHeight: labelSettings.arrowProps.dimensions.height,
    offset: { y: -(labelSettings.arrowProps.dimensions.height || 0), x: 0 },
    padding: {
      t: paddings.top,
      r: paddings.right,
      l: paddings.left,
      b: paddings.bottom,
    },
    autoHideWhenCollide: false,
  }, zIndex);
};
