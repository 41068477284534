import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  faChevronUp, faSearch, faSort,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import {
  DropdownComponent, type DropdownOption, DropdownPlacement, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  InputSize, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import { LinkComponent } from '~/_shared/baseComponents/links';
import { hexColorWithOpacityToString } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { HeightTransitionComponent } from '~/_shared/components/heightTransition/heightTransition.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import { PaginationComponent } from '~/_shared/components/pagination/pagination.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { useWindowHeight } from '~/_shared/utils/hooks/useWindowHeight';
import { clamp } from '~/_shared/utils/number/number.helpers';
import { convertNumberToLetters } from '~/_shared/utils/text/text.helpers';
import { type MapListingItemWithPath } from '~/map/listing/item/mapListingItem';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import {
  getSortOptions, type MapListSortType,
} from '../listing/mapListing.helpers';
import { getMapRowIcon } from '../listing/tableData/mapRow/mapRow.helpers';
import {
  compareIdToParentMapOrMapId, getMapUrl,
} from '../map.helpers';

const dropdownStyle = css({
  height: '100%',
});

const listStyle = ({ theme }: ThemeProps) => css({
  background: theme.backgroundColors.quinary,
  border: `1px solid ${theme.borderColors.primary}`,
  borderRadius: 4,
  boxShadow: `0px 2px 15px ${theme.shadowColors.primary}`,
  marginTop: 1,
});

const searchLabelStyle = css({
  display: 'block',
});

const sortDropdownContainerStyle = css({
  '&:before': {
    display: 'none',
  },
});

const listHeaderStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  background: theme.backgroundColors.quaternary,
  color: theme.textColors.quaternary,
  display: 'flex',
  fontSize: 16,
  fontWeight: 'bold',
  height: 40,
  padding: '0 32px',
  textTransform: 'uppercase',
});

const SecondaryListingItem = styled.div({
  overflow: 'hidden',
});

const secondaryListingSpacer = css({
  height: 6,
});

const listingItemStyle = ({ theme }: ThemeProps) => css({
  '&:nth-of-type(2n)': {
    [`${SecondaryListingItem}:nth-of-type(2n + 1)`]: {
      backgroundColor: hexColorWithOpacityToString(theme.backgroundColors.tertiary, .3),
      '&:hover': {
        backgroundColor: theme.backgroundColors.secondaryHover,
      },
    },
  },
  '&:nth-of-type(2n + 1)': {
    backgroundColor: hexColorWithOpacityToString(theme.backgroundColors.tertiary, .3),
    [`${SecondaryListingItem}:nth-of-type(2n+1)`]: {
      backgroundColor: theme.backgroundColors.quinary,
      '&:hover': {
        backgroundColor: theme.backgroundColors.secondaryHover,
      },
    },
  },
});

const listingItemTriggerStyle = ({ theme }: ThemeProps) => css({
  display: 'flex',
  width: '100%',
  padding: '5px 0',
  justifyContent: 'space-between',
  overflowY: 'auto',

  '&:hover': {
    backgroundColor: theme.backgroundColors.secondaryHover,
  },
});

const listingItemTitleStyle = ({ theme, isActive }: ThemeProps<{isActive: boolean}>) => css({
  color: isActive ? theme.textColors.success : theme.textColors.primary,
  whiteSpace: 'nowrap',
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: 'none',
  border: 'none',
  fontSize: '16px',
  lineHeight: '30px',
  textAlign: 'left',

  '&:hover': {
    color: isActive ? theme.textColors.successOnHover : theme.textColors.linkOnHover,
  },
});

const listingNumberStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  fontSize: 14,
  fontWeight: 'normal',
  margin: '0 9px 0 26px',
  textTransform: 'uppercase',
});

const listingItemIconStyle = ({ theme, isActive }: ThemeProps<{isActive?: boolean}>) => css({
  color: isActive ? theme.iconColors.success : theme.iconColors.contrast,
  padding: 0,
  width: 32,
  textAlign: 'center',
  display: 'inline-block',
});

const toggleButtonStyle = ({ theme }: ThemeProps) => css({
  padding: 0,
  width: 24,
  height: 30,
  lineHeight: '30px',
  fontSize: 14,
  background: 'none',
  color: theme.textColors.primary,
  border: 'none',
  margin: '0 2px 0 0',
});

const toggleButtonIconStyle = ({ isToggled }: { isToggled: boolean }) => css({
  transition: 'transform .2s',
  transform: isToggled ? undefined : 'rotate(180deg)',
});

const listingItemSecondaryTitleStyle = ({ theme }: ThemeProps) => css({
  display: 'inline-flex',
  padding: 0,
  margin: '10px 0 10px 26px',
  alignItems: 'center',
  color: theme.textColors.primary,
  border: 'none',
  background: 'none',
  fontSize: '16px',
  lineHeight: 1.25,
  '&:hover': {
    color: theme.textColors.linkOnHover,
  },
});

const headerStyle = css({
  padding: 16,
});

const spaceTopStyle = css({
  paddingTop: 10,
});

const headerButtonsStyle = css({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
});

const headerButtonStyle = css({
  marginLeft: 10,
});

const loaderStyle = css({
  borderRadius: '0 0 4px 4px',
});

const noResultsStyle = css({
  margin: 0,
  textAlign: 'center',
});

type MapDropdownProps = Readonly<{
  isLoading: boolean;
  isOpen: boolean;
  mapListingItems: ReadonlyArray<MapListingItemWithPath>;
  pagination: Pagination | null;
  searchQuery: string;
  sortType?: MapListSortType;
  title: string;
  toggledMapIds: ReadonlyArray<number>;
  triggerComponent: ReactNode;

  onClose: () => void;
  onPageChange: (page: number) => void;
  onSearchQueryChange?: (query: string) => void;
  onSortTypeChange?: (sortType: MapListSortType) => void;
  onToggledMapIdsChange?: (ids: number[]) => void;
}>;

export const MapDropdownComponent: FC<MapDropdownProps> = (props) => {
  const windowHeight = useWindowHeight();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(true);
  const [t] = useTranslation();
  const theme = useTheme();
  const mapInfo = useMapInfoDataSelector();

  const sortOptions: DropdownOption<MapListSortType>[] = useMemo(() => getSortOptions(t), [t]);

  const toggleMap = (mapId: number) => {
    if (props.onToggledMapIdsChange) {
      const newSelectedMapIds = props.toggledMapIds.includes(mapId)
        ? props.toggledMapIds.filter(id => id !== mapId)
        : [...props.toggledMapIds, mapId];

      props.onToggledMapIdsChange(newSelectedMapIds);
    }
  };

  const onListingItemClick = () => {
    props.onClose();
  };

  const dropdownListViewportMaxHeight = windowHeight - 300;
  const dropdownListMaxHeight = clamp(dropdownListViewportMaxHeight, { min: 50, max: 280 });

  const toggleSearch = useCallback(() => {
    setIsSearchOpen(s => !s);
  }, []);
  const toggleSort = useCallback(() => setIsSortOpen(s => !s), []);

  const triggerButtonStyle = (isMenuOpen: boolean) =>
    isMenuOpen ? RoundButtonStyle.Quaternary : RoundButtonStyle.Primary;

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    setIsSearchOpen(props.searchQuery !== '');
  }, [props.searchQuery, props.isOpen]);

  return (
    <DropdownComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      triggerComponent={props.triggerComponent}
      css={dropdownStyle}
      menuStyle={listStyle({ theme })}
      closeOnInsideClick={false}
      dropdownPlacement={DropdownPlacement.BottomEnd}
      inPortal
    >
      <div css={{ width: '468px' }}>
        <div css={headerStyle}>
          <div css={headerButtonsStyle}>
            {props.onSearchQueryChange && (
              <RoundButtonComponent
                buttonStyle={triggerButtonStyle(isSearchOpen)}
                css={headerButtonStyle}
                icon={faSearch}
                onClick={toggleSearch}
                size={RoundButtonSize.Medium}
                tooltipLabel={t('Search')}
              />
            )}
            {props.onSortTypeChange && (
              <RoundButtonComponent
                buttonStyle={triggerButtonStyle(isSortOpen)}
                css={headerButtonStyle}
                icon={faSort}
                onClick={toggleSort}
                size={RoundButtonSize.Medium}
                tooltipLabel={t('Sort')}
              />
            )}
          </div>
          {isSearchOpen && props.onSearchQueryChange && (
            <div css={spaceTopStyle}>
              <label css={searchLabelStyle}>
                <TextInputComponent
                  type="text"
                  onChange={props.onSearchQueryChange}
                  value={props.searchQuery}
                  placeholder={t('Search Your Maps')}
                  icon={faSearch}
                  size={InputSize.Large}
                />
              </label>
            </div>
          )}

          {isSortOpen && props.onSortTypeChange && (
            <div css={spaceTopStyle}>
              <RegularDropdownComponent
                css={sortDropdownContainerStyle}
                inPortal
                inputSize={InputSize.Large}
                onChange={props.onSortTypeChange}
                options={sortOptions}
                prefixTriggerIcon={faSort}
                value={props.sortType}
              />
            </div>
          )}
        </div>

        <OverlayWrapperComponent
          minHeight={80}
        >
          <div css={listHeaderStyle({ theme })}>{props.title}</div>
          <ScrollBarComponent
            type={ScrollbarType.Vertical}
            translateContentHeightToHolder
            maxHeight={dropdownListMaxHeight}
            contentStyle={{
              display: 'block',
            }}
          >
            {props.mapListingItems.map((item, index) => {
              const isToggled = !!props.toggledMapIds?.includes(item.id);
              const isItemActive = compareIdToParentMapOrMapId(item.id, mapInfo);

              return (
                <div
                  css={listingItemStyle({ theme })}
                  key={item.id}
                >
                  <div css={listingItemTriggerStyle({ theme })}>
                    <LinkComponent
                      path={item.path}
                      css={listingItemTitleStyle({ theme, isActive: isItemActive })}
                      onClick={onListingItemClick}
                    >
                      <span css={listingNumberStyle({ theme })}>{index + 1}.</span>
                      <span css={listingItemIconStyle({ theme, isActive: isItemActive })}>
                        <FontAwesomeIcon icon={getMapRowIcon({ isSnapshot: false, item })} />
                      </span>
                      {item.name}
                    </LinkComponent>

                    {item.snapshots.length > 0 && (
                      <button
                        onClick={() => toggleMap(item.id)}
                        css={toggleButtonStyle({ theme })}
                      >
                        <FontAwesomeIcon
                          css={toggleButtonIconStyle({ isToggled })}
                          icon={faChevronUp}
                        />
                      </button>
                    )}
                  </div>

                  <HeightTransitionComponent animateOnMount>
                    {isToggled && (
                      <div>
                        {item.snapshots.map((snapshot, indexSecondary) => (
                          <SecondaryListingItem key={indexSecondary}>
                            <LinkComponent
                              path={getMapUrl(snapshot.id)}
                              css={listingItemSecondaryTitleStyle({ theme })}
                              onClick={onListingItemClick}
                            >
                              <span css={listingNumberStyle({ theme })}>{convertNumberToLetters(indexSecondary + 1)}.</span>
                              <span css={listingItemIconStyle({ theme })}>
                                <FontAwesomeIcon icon={getMapRowIcon({ isSnapshot: true, item: snapshot })} />
                              </span>
                              {snapshot.name}
                            </LinkComponent>
                          </SecondaryListingItem>
                        ))}
                        {/* last item in the snapshots listing should have the opposite background
                        to actual last snapshot, that's why SecondaryListingItem is used
                        as it gets the alternate background for even and odd items */}
                        <SecondaryListingItem css={secondaryListingSpacer} />
                      </div>
                    )}
                  </HeightTransitionComponent>
                </div>
              );
            })}
          </ScrollBarComponent>

          {(!props.isLoading && props.mapListingItems.length === 0) &&
            <p css={noResultsStyle}>{t('No results')}</p>
          }

          {props.pagination && (
            <PaginationComponent
              currentPage={props.pagination.currentPage}
              pageCount={props.pagination.lastPage}
              onPageSelect={props.onPageChange}
            />
          )}

          {props.isLoading && (
            <OverlayLoaderComponent
              css={loaderStyle}
              loaderSize={45}
            />
          )}
        </OverlayWrapperComponent>
      </div>
    </DropdownComponent>
  );
};
