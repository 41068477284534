import {
  createContext,
  type FC, type ReactNode, useContext, useMemo,
} from 'react';
import { type MapShapeInstance } from '../mapShapeModel';

export type MapShapeContextModel = {
  readonly shape: MapShapeInstance;
  readonly isPolygon: boolean;
};

const MapShapeContext = createContext<MapShapeContextModel|null>(null);

type MapShapeContextProviderProps = {
  value: MapShapeContextModel;
  children: ReactNode;
};

export const MapShapeContextProvider: FC<MapShapeContextProviderProps> = ({ value: { shape, isPolygon }, children }) => {
  const mapShapeContext = useMemo(() => shape ? { shape, isPolygon } : null, [shape, isPolygon]);

  if (!mapShapeContext) {
    return null;
  }

  return (
    <MapShapeContext.Provider value={mapShapeContext} >
      {children}
    </MapShapeContext.Provider>
  );
};

/*
When the nearest <MyContext.Provider> above the component updates, this Hook will trigger a rerender
with the latest context value passed to that MyContext provider. Even if an ancestor uses React.memo
or shouldComponentUpdate, a rerender will still happen starting at the component itself using useContext.
*/
export const useMapShapeContext = (): MapShapeContextModel => {
  const context = useContext(MapShapeContext);

  if (!context) {
    throw new Error('MapShape components cannot be used without the context provider.');
  }

  return context;
};
