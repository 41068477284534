import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type LatLng } from '~/_shared/types/latLng';
import { createUuid } from '~/_shared/utils/createUuid';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import { KeyboardKeys } from '~/_shared/utils/hooks/useKeyPress';
import {
  type MapKeyPressCallback, MapKeyPressPriority, useMapKeyPressContext,
} from '~/_shared/utils/hooks/useMapKeyPressContext';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { useDrawingToolSizePerPixelRatio } from '~/drawingTool/hooks/useDrawingToolSizePerPixelRatio';
import {
  DrawingToolMode, useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingArrowSettingsState } from '~/store/mapSettings/drawing/settings/arrow/drawingArrowSettings.state';
import { useActiveDrawingInstanceZIndex } from '../../../zIndexes/useDrawingInstanceZIndex.hook';
import { useMap } from '../../mapContext';
import { useProjectionOverlay } from '../../useProjectionOverlay';
import { type DrawingToolManager } from '../drawingToolManager';
import { DrawingToolArrowInstanceArrowContainer } from './drawingToolArrowInstanceArrow.container';

type DrawingToolArrowCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingArrowSettingsState;
}>;

export const DrawingToolArrowCreateNewInstanceContainer: FC<DrawingToolArrowCreateNewInstanceProps> = (props) => {
  const { manager } = props;
  const [arrowTail, setArrowTail] = useState<LatLng | null>(null);
  const [temporaryTip, setTemporaryTip] = useState<LatLng | null>(null);
  const [staticLength, setStaticLength] = useState(0);
  const map = useMap();
  const { getDistanceBetweenPoints } = useProjectionOverlay(map);
  const drawingToolMode = useDrawingToolModeSelector();
  const dispatch = useDispatch();

  const zIndex = useActiveDrawingInstanceZIndex();
  const scaledSizePerPixelRatio = useDrawingToolSizePerPixelRatio();

  const settings = useMemo(() => ({
    ...props.settings,
    lineWeight: props.settings.scalesWithMapZoom ? props.settings.lineWeight * scaledSizePerPixelRatio : props.settings.lineWeight,
    strokeWeight: props.settings.scalesWithMapZoom ? props.settings.strokeWeight * scaledSizePerPixelRatio : props.settings.strokeWeight,
  }), [props.settings, scaledSizePerPixelRatio]);

  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    if (!e.latLng) {
      return;
    }

    // start drawing - set starting point
    if (!arrowTail) {
      setArrowTail({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });

      return;
    }

    // end drawing - add drawing to the map
    if (arrowTail) {
      const id = createUuid();

      const startPoint = googleLatLngToLocal(e.latLng);
      const newStaticLength = getDistanceBetweenPoints(startPoint, arrowTail) ?? 0;

      dispatch(drawingItemsAddItem(
        id,
        {
          type: DrawingTool.Arrow,
          value: {
            id,
            tipPoint: startPoint,
            staticLength: newStaticLength,
            tailPoint: arrowTail,
            placement: DrawingItemPlacement.Default,
            settings,
          },
        }));

      setArrowTail(null);
      setTemporaryTip(null);
      setStaticLength(0);
    }
  }, [dispatch, arrowTail, settings, getDistanceBetweenPoints]);

  const onMapMouseMove = useCallback((e: google.maps.MapMouseEvent) => {
    if (!arrowTail || !e.latLng) {
      return;
    }

    const tipPosition = googleLatLngToLocal(e.latLng);
    setTemporaryTip(tipPosition);

    const newStaticLength = getDistanceBetweenPoints(tipPosition, arrowTail) ?? 0;
    setStaticLength(newStaticLength);
  }, [arrowTail, getDistanceBetweenPoints]);

  useEffect(() => {
    if (drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);
    const mouseMoveListener = manager.addMapMouseMoveListener(onMapMouseMove);

    return () => {
      clickListener.remove();
      mouseMoveListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode, onMapMouseMove]);

  const { addMapKeyHandler } = useMapKeyPressContext();

  useEffect(() => { // Reset drawing on ESC
    if (!arrowTail) {
      return;
    }

    const onEsc: MapKeyPressCallback = (e) => {
      setArrowTail(null);
      setTemporaryTip(null);

      e.stopPropagation();
    };

    const removeKeyHandler = addMapKeyHandler(KeyboardKeys.Escape, MapKeyPressPriority.ResetDrawing, onEsc);
    return removeKeyHandler;
  }, [addMapKeyHandler, arrowTail]);

  if (!arrowTail || !temporaryTip) {
    return null;
  }

  return (
    <DrawingToolArrowInstanceArrowContainer
      zIndex={zIndex}
      instance={{
        id: 'drawing-tool-drawing-new-arrow',
        tipPoint: temporaryTip,
        tailPoint: arrowTail,
        staticLength,
        placement: DrawingItemPlacement.Default,
        settings,
      }}
    />
  );
};
