import { css } from '@emotion/react';
import {
  useCallback, useState,
} from 'react';
import {
  RoundButtonComponent, RoundButtonSize,
} from '~/_shared/baseComponents/buttons/roundButton/roundButton.component';
import { RoundButtonStyle } from '~/_shared/baseComponents/buttons/roundButton/roundButton.styles';
import { TooltipPlacement } from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { SidebarContextMenuComponent } from '../sidebarContextMenu/sidebarContextMenu.component';
import { type SidebarRoundMenuButtonProps } from './sidebarRoundButtonMenu.types';

const menuStyle = ({ theme, justifyContent }: ThemeProps<{ justifyContent: string }>) => css({
  display: 'flex',
  justifyContent,
  backgroundColor: theme.backgroundColors.secondary,
  borderBottom: '1px solid ' + theme.lineColors.basePrimary,
  boxSizing: 'border-box',
  padding: '12px 8px',
});

const roundButtonRightStyle = css({
  display: 'flex',
  gap: '8px',
});

export type SidebarRoundButtonMenuProps<T> = Readonly<{
  selectedButton: T | null;
  roundButtonLeftDetails?: ReadonlyArray<SidebarRoundMenuButtonProps<T>>;
  roundButtonRightDetails?: ReadonlyArray<SidebarRoundMenuButtonProps<T>>;
}>;

export const SidebarRoundButtonMenuComponent = <T extends unknown> (props: SidebarRoundButtonMenuProps<T>) => {
  const [openContextMenuName, setOpenContextMenuName] = useState<T | null>(null);
  const theme = useTheme();

  const getMenuButtonStyle = useCallback((styleProps: {
    buttonName: T;
    buttonStyle: RoundButtonStyle;
    notSelectable?: boolean;
  }): RoundButtonStyle => {
    if (styleProps.notSelectable) {
      return styleProps.buttonStyle;
    }

    const isSelected = props.selectedButton === styleProps.buttonName || openContextMenuName === styleProps.buttonName;
    if (isSelected) {
      switch (styleProps.buttonStyle) {
        case RoundButtonStyle.Restricted:
          return RoundButtonStyle.RestrictedInverted;
        case RoundButtonStyle.Danger:
          return RoundButtonStyle.DangerInverted;
        default:
          return RoundButtonStyle.Quaternary;
      }
    }
    return styleProps.buttonStyle;
  }, [openContextMenuName, props.selectedButton]);

  const handleClick = useCallback((buttonProps: SidebarRoundMenuButtonProps<T>) => () => {
    if (buttonProps.contextItemMenuStructure) {
      setOpenContextMenuName(buttonProps.buttonName);
    }
    buttonProps.onClick?.();
  }, []);

  const getFlexStyle = useCallback((): string => {
    if (props.roundButtonLeftDetails && props.roundButtonRightDetails) {
      return 'space-between';
    }
    else if (props.roundButtonRightDetails) {
      return 'flex-end';
    }
    else if (props.roundButtonLeftDetails) {
      return 'flex-start';
    }

    return 'center';
  }, [props.roundButtonLeftDetails, props.roundButtonRightDetails]);

  const getRoundButtons = useCallback((
    buttonDetails?: ReadonlyArray<SidebarRoundMenuButtonProps<T>>
  ): ReadonlyArray<React.ReactElement> | null => {
    if (!buttonDetails) {
      return null;
    }
    return buttonDetails.map((buttonProps, index) => {
      const roundButton = (
        <RoundButtonComponent
          key={index}
          buttonStyle={getMenuButtonStyle(buttonProps)}
          icon={buttonProps.icon}
          onClick={handleClick(buttonProps)}
          ref={buttonProps.ref}
          tooltipLabel={buttonProps.tooltipLabel}
          tooltipPlacement={TooltipPlacement.Top}
          isDisabled={buttonProps.notSelectable}
          size={RoundButtonSize.Large}
        />
      );

      if (!buttonProps.contextItemMenuStructure || !buttonProps.ref) {
        return roundButton;
      }

      return (
        <span key={index}>
          <SidebarContextMenuComponent
            isOpen={openContextMenuName === buttonProps.buttonName}
            menuItems={buttonProps.contextItemMenuStructure}
            onHide={() => setOpenContextMenuName(null)}
            preferredPlacement={buttonProps.contextItemMenuPlacement}
            targetRef={buttonProps.ref}
          >
            {roundButton}
          </SidebarContextMenuComponent>
        </span>
      );
    });
  }, [getMenuButtonStyle, handleClick, openContextMenuName]);

  return (
    <div css={menuStyle({ theme, justifyContent: getFlexStyle() })} >
      {getRoundButtons(props.roundButtonLeftDetails)}
      <div css={roundButtonRightStyle}>
        {getRoundButtons(props.roundButtonRightDetails)}
      </div>
    </div>
  );
};
