export const MAP_SETTINGS_COLUMNS_FILTER_CLEAR_FILTERS = 'MAP_SETTINGS_COLUMNS_FILTER/CLEAR_FILTERS';

export const MAP_SETTINGS_COLUMNS_FILTER_REMOVE_FILTER_VALUES = 'MAP_SETTINGS_COLUMNS_FILTER/REMOVE_FILTER_VALUES';

export const MAP_SETTINGS_COLUMNS_FILTER_SET_TEXT = 'MAP_SETTINGS_COLUMNS_FILTER/SET_TEXT';
export const MAP_SETTINGS_COLUMNS_FILTER_CHANGE_TEXT_CONJUNCTION = 'MAP_SETTINGS_COLUMNS_FILTER/CHANGE_TEXT_CONJUNCTION';

export const MAP_SETTINGS_COLUMNS_FILTER_SET_GROUP = 'MAP_SETTINGS_COLUMNS_FILTER/SET_GROUP';

export const MAP_SETTINGS_COLUMNS_FILTER_SET_NUMBER = 'MAP_SETTINGS_COLUMNS_FILTER/SET_NUMBER';
// export const MAP_SETTINGS_COLUMNS_FILTER_CHANGE_NUMBER_CONJUNCTION = 'MAP_SETTINGS_COLUMNS_FILTER/CHANGE_NUMBER_CONJUNCTION';

export const MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_FROM = 'MAP_SETTINGS_COLUMNS_FILTER/SET_DATE_FROM';
export const MAP_SETTINGS_COLUMNS_FILTER_SET_DATE_TO = 'MAP_SETTINGS_COLUMNS_FILTER/SET_DATE_TO';

export const MAP_SETTINGS_COLUMNS_FILTER_SET_ATTRIBUTE = 'MAP_SETTINGS_COLUMNS_FILTER/SET_ATTRIBUTE';
export const MAP_SETTINGS_COLUMNS_FILTER_CHANGE_ATTRIBUTE_CONJUNCTION = 'MAP_SETTINGS_COLUMNS_FILTER/CHANGE_ATTRIBUTE_CONJUNCTION';
