export enum TwoFactorMode {
  email = 'email',
  google = 'google',
}

export enum UserTwoFactorMode {
  TwoFactorViaEmail = 'TwoFactorViaEmail',
  TwoFactorViaGoogle = 'TwoFactorViaGoogle',
  NoTwoFactor = 'NoTwoFactor',
}

export const twoFactorToServerModel = (twoFactor: UserTwoFactorMode) => {
  switch (twoFactor) {
    case UserTwoFactorMode.TwoFactorViaEmail:
      return TwoFactorMode.email;
    case UserTwoFactorMode.TwoFactorViaGoogle:
      return TwoFactorMode.google;
    case UserTwoFactorMode.NoTwoFactor:
    default:
      return '';

  }
};
