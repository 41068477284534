import { type DrawingIconMarkerSettingsState } from '../../store/mapSettings/drawing/settings/iconMarker/drawingIconMarkerSettings.state';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createIconMarkerDrawingOptionsFromSettings = (iconId: string | null): Partial<DrawingToolOptions> => {
  return {
    otherOptions: {
      icon: iconId ?? null,
    },
  };
};

export const createIconMarkerDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingIconMarkerSettingsState
): DrawingIconMarkerSettingsState => {
  return {
    icon: toolOptions.otherOptions?.icon ?? currentSettings.icon,
  };
};
