import type { MapSettingsSettingsAction } from '~/store/mapSettings/settings/mapSettingsSettings.action';
import {
  MAP_SETTINGS_DISABLE_CLICKABLE_ICONS, MAP_SETTINGS_ENABLE_CLICKABLE_ICONS,
} from '~/store/mapSettings/settings/mapSettingsSettings.actionTypes';
import { type MapComponentAction } from '../../mapComponent.action';
import {
  ALLOW_STREET_VIEW, DISALLOW_STREET_VIEW,
} from '../../mapComponent.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig,
  pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyGeneralMapOptions = (
  state: MapComponentOptionsState,
  action: MapComponentAction | MapSettingsSettingsAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case ALLOW_STREET_VIEW:
      return push(state, {
        changeId: 'ALLOW_STREET_VIEW',
        priority,
        change: {
          streetViewControl: true,
        },
      });

    case DISALLOW_STREET_VIEW: {
      return pop(state, 'ALLOW_STREET_VIEW');
    }

    case MAP_SETTINGS_ENABLE_CLICKABLE_ICONS: {
      return push(state, {
        changeId: 'ENABLE_CLICKABLE_ICONS',
        priority,
        change: {
          clickableIcons: true,
        },
      });
    }

    case MAP_SETTINGS_DISABLE_CLICKABLE_ICONS: {
      return pop(state, 'ENABLE_CLICKABLE_ICONS');
    }

    default:
      return undefined;
  }
};
