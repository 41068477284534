import {
  useCallback, useMemo,
} from 'react';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import {
  refreshGoogleAccessToken, type RefreshGoogleAccessTokenSuccessResponse,
} from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';

export const useRefreshGoogleOAuthAccessToken = () => {
  const { isLoading, error, data, invokeAjax } = useAjaxCall(refreshGoogleAccessToken);

  const refreshAccessToken = useCallback((googleAccountId: string) => {
    invokeAjax(googleAccountId);
  }, [invokeAjax]);

  return useMemo(() => ({
    refreshAccessToken,
    isLoading,
    error,
    token: !error && data ? ((data as RefreshGoogleAccessTokenSuccessResponse).data.access_token || null) : null,
  }), [data, error, isLoading, refreshAccessToken]);
};
