import {
  put, takeLatest,
} from 'redux-saga/effects';
import {
  type FileAttachment,
  FileAttachmentTool,
} from '~/_shared/types/file.types';
import { type GroupingColumn } from '~/_shared/types/grouping/grouping';
import { MarkerStyleType } from '~/_shared/types/marker.types';
import { type MarkerSettings } from '~/_shared/types/markers/visualSettings.types';
import { select } from '~/_shared/utils/saga/effects';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { MAP_SETTINGS_DRAWING_ITEMS_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS } from '~/store/mapSettings/drawing/items/drawingItems.actionTypes';
import { type DrawingItemsState } from '~/store/mapSettings/drawing/items/drawingItems.state';
import { drawingItemsSelector } from '~/store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { MAP_SETTINGS_GROUPING_CHECK_FOR_ACTIVE_GROUP_CHANGES_ACTIONS } from '~/store/mapSettings/grouping/mapSettingsGrouping.actionTypes';
import { mapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { MAP_SETTINGS_MARKERS_GENERAL_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionTypes';
import { mapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { type MapSettingsMarkersGeneralState } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.state';
import {
  getIndividualMarkerSettings,
  getNumericGroupMarkerSettings,
  getTextGroupMarkerSettings,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneralSettings.helpers';
import { MAP_SETTINGS_MARKERS_MARKER_IMAGES_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS } from '~/store/mapSettings/markers/mapSettingsMarkers.actionTypes';
import { flattenMarkerImages } from '~/store/mapSettings/markers/mapSettingsMarkers.helpers';
import { mapSettingsMarkerImagesSelector } from '~/store/mapSettings/markers/mapSettingsMarkers.selectors';
import { type ReadonlyMarkerImages } from '~/store/mapSettings/markers/mapSettingsMarkers.state';
import { mapSettingsFileAttachmentsRemove } from '../fileAttachments.actionCreators';
import { mapSettingsFileAttachmentsListSelector } from '../fileAttachments.selectors';

export function* removeUnusedAttachmentsSaga() {
  yield takeLatest([
    ...MAP_SETTINGS_GROUPING_CHECK_FOR_ACTIVE_GROUP_CHANGES_ACTIONS,
    ...MAP_SETTINGS_MARKERS_GENERAL_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS],
  onCustomMarkerSettingsChange);
  yield takeLatest(MAP_SETTINGS_MARKERS_MARKER_IMAGES_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS, onMarkerImageChange);
  yield takeLatest(MAP_SETTINGS_DRAWING_ITEMS_CHECK_FOR_UNUSED_ATTACHMENTS_ACTIONS, onDrawingToolItemChange);
}

function* onCustomMarkerSettingsChange() {

  const markersGeneralSettings: Readonly<MapSettingsMarkersGeneralState> =
    yield select<Readonly<MapSettingsMarkersGeneralState>>(mapSettingsMarkersGeneralSelector);

  const fileAttachments: Readonly<FileAttachment[]> =
    yield select<Readonly<FileAttachment[]>>(mapSettingsFileAttachmentsListSelector);

  const activeGroupColumns: ReadonlyArray<GroupingColumn> = yield select<ReadonlyArray<GroupingColumn>>(mapSettingsGroupingActiveGroupColumnsSelector);

  if (!fileAttachments?.length) {
    return;
  }

  const customMarkerAttachmentIds: Set<string> = new Set();

  const logAttachment = (settings: MarkerSettings | undefined) => {
    const marker = settings?.marker;
    if (marker?.styleType === MarkerStyleType.CUSTOM) {
      customMarkerAttachmentIds.add(marker.fileAttachmentId);
    }
  };

  const isGroupingColumnActive = (({ spreadsheetId, columnId }: { spreadsheetId: number; columnId: string }) => activeGroupColumns.find(
    activeGroup => activeGroup.spreadsheetId === spreadsheetId && activeGroup.columnId === columnId));

  logAttachment(markersGeneralSettings.globalMarkerSettings);
  getTextGroupMarkerSettings(markersGeneralSettings).forEach(item => isGroupingColumnActive(item) && logAttachment(item.markerSettings));
  getNumericGroupMarkerSettings(markersGeneralSettings).forEach(item => isGroupingColumnActive(item) && logAttachment(item.markerSettings));
  getIndividualMarkerSettings(markersGeneralSettings).forEach(item => logAttachment(item.markerSettings));

  const fileAttachmentIdsToDelete = fileAttachments
    .filter(a => a.tool === FileAttachmentTool.CustomMarker)
    .filter(a => !customMarkerAttachmentIds.has(a.id))
    .map(a => a.id);

  if (fileAttachmentIdsToDelete.length) {
    yield put(mapSettingsFileAttachmentsRemove(new Set(fileAttachmentIdsToDelete)));
  }
}

function* onMarkerImageChange() {
  const markerImages: ReadonlyMarkerImages =
    yield select<ReadonlyMarkerImages>(mapSettingsMarkerImagesSelector);

  const fileAttachments: Readonly<FileAttachment[]> =
    yield select<Readonly<FileAttachment[]>>(mapSettingsFileAttachmentsListSelector);

  const markerImagesAttachmentIds: Set<string> = new Set();

  flattenMarkerImages(markerImages).forEach(({ images }) =>
    images.forEach(image => markerImagesAttachmentIds.add(image)));

  const fileAttachmentIdsToDelete = fileAttachments
    .filter(a => a.tool === FileAttachmentTool.MarkerGallery)
    .filter(a => !markerImagesAttachmentIds.has(a.id))
    .map(a => a.id);

  if (fileAttachmentIdsToDelete.length) {
    yield put(mapSettingsFileAttachmentsRemove(new Set(fileAttachmentIdsToDelete)));
  }
}

function* onDrawingToolItemChange() {
  const drawingItems: DrawingItemsState =
    yield select<DrawingItemsState>(drawingItemsSelector);

  const fileAttachments: Readonly<FileAttachment[]> =
    yield select<Readonly<FileAttachment[]>>(mapSettingsFileAttachmentsListSelector);

  const drawingItemsAttachmentIds: Set<string> = new Set();

  drawingItems.forEach(item => {
    if (item.type === DrawingTool.Image) {
      drawingItemsAttachmentIds.add(item.value.attachmentId);
    }
  });

  const fileAttachmentIdsToDelete = fileAttachments
    .filter(a => a.tool === FileAttachmentTool.Drawing)
    .filter(a => !drawingItemsAttachmentIds.has(a.id))
    .map(a => a.id);

  if (fileAttachmentIdsToDelete.length) {
    yield put(mapSettingsFileAttachmentsRemove(new Set(fileAttachmentIdsToDelete)));
  }
}
