import { type DistanceCalculatorAction } from './distanceCalculator.action';
import {
  FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START,
  FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP,
  FRONTEND_STATE_DISTANCE_CALCULATOR_START, FRONTEND_STATE_DISTANCE_CALCULATOR_STOP,
} from './distanceCalculator.actionTypes';
import { type DistanceCalculatorState } from './distanceCalculator.state';

const initialState: DistanceCalculatorState = {
  active: false,
  dragging: false,
  draggedInstanceId: null,
  mode: 'pick-mode',
  startingPoint: null,
};

export const distanceCalculatorReducer = (state = initialState, action: DistanceCalculatorAction): DistanceCalculatorState => {
  switch (action.type) {

    case FRONTEND_STATE_DISTANCE_CALCULATOR_START: {
      return {
        ...state,
        active: true,
        mode: 'pick-mode',
      };
    }

    case FRONTEND_STATE_DISTANCE_CALCULATOR_STOP: {
      return {
        ...state,
        active: false,
      };
    }

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START: {
      return {
        ...state,
        dragging: true,
        draggedInstanceId: action.payload.draggedInstanceId,
      };
    }

    case FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP: {
      return {
        ...state,
        dragging: false,
        draggedInstanceId: null,
      };
    }

    case FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED: {
      return {
        ...state,
        mode: action.payload.mode,
        startingPoint: action.payload.startingPoint || null,
      };
    }

    default: {
      return state;
    }
  }
};
