import { type FileAttachmentId } from '~/_shared/types/file.types';
import {
  DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE, DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
} from '~/drawingTool/drawingTool.enums';
import { type DrawingImageSettingsState } from '../../store/mapSettings/drawing/settings/image/drawingImageSettings.state';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createImageDrawingGlobalOptionsFromSettings = (settings: DrawingImageSettingsState) => createImageDrawingOptionsFromSettings({ settings });

export const createSelectedImageDrawingOptionsFromSettings = (settings: DrawingImageSettingsState, sizePerPixelRatio: number, fileAttachmentId: FileAttachmentId) =>
  createImageDrawingOptionsFromSettings({ settings, sizePerPixelRatio, fileAttachmentId });

const createImageDrawingOptionsFromSettings = ({ settings, sizePerPixelRatio, fileAttachmentId }:
{
  settings: DrawingImageSettingsState;
  sizePerPixelRatio?: number;
  fileAttachmentId?: FileAttachmentId | null;
}): Partial<DrawingToolOptions> => {
  const imageSize = settings.imageSize ?? DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.to / 2;
  const useScaledSlider = settings.scalesWithMapZoom && sizePerPixelRatio !== undefined;

  return {
    ...useScaledSlider ? {
      scaledSliderOptions: {
        imageSizeOnCurrentMapZoom: {
          value: imageSize,
          displayValue: Math.round((imageSize) / sizePerPixelRatio),
          normalizedRange: DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
          ensureValueInRange: true,
        },
      },
    } : {
      sliderOptions: {
        imageSize: {
          value: imageSize,
          range: DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE,
          suffix: DRAWING_TOOL_SLIDER_PIXEL_SUFFIX,
        },
      },
    },
    booleanOptions: {
      showImageBorders: settings.showImageBorders,
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    otherOptions: {
      image: fileAttachmentId ?? null,
    },
  };
};

export const createImageDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingImageSettingsState,
): DrawingImageSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    showImageBorders: toolOptions.booleanOptions?.showImageBorders ?? currentSettings.showImageBorders,
    imageSize: toolOptions.sliderOptions?.imageSize?.value ?? toolOptions.scaledSliderOptions?.imageSizeOnCurrentMapZoom?.value ?? currentSettings.imageSize,
  };
};
