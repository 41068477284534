import { useMemo } from 'react';
import { usePrimarySpreadsheetId } from '../../selectors/usePrimarySpreadsheetId';
import {
  type FilterTreeItemRequest,
  getAllFilterTreeRequestsFromMapSettings,
  getCombinedFilterTreeForSpreadsheetId,
} from './spreadsheetDataFiltering.helpers';
import { useFilterTreeMapSettingsParams } from './useFilterTreeMapSettingsParams';

export const usePrimarySpreadsheetFilterTreeItem = (): FilterTreeItemRequest | null => {
  const filterTreeParams = useFilterTreeMapSettingsParams();
  const primarySpreadsheetId = usePrimarySpreadsheetId();

  return useMemo(() => {
    let filterTree: FilterTreeItemRequest | null = null;

    if (primarySpreadsheetId !== null) {
      const allFilterTreeRequests = getAllFilterTreeRequestsFromMapSettings(filterTreeParams, [primarySpreadsheetId]);
      filterTree = getCombinedFilterTreeForSpreadsheetId(allFilterTreeRequests, primarySpreadsheetId);
    }

    return filterTree;
  }, [primarySpreadsheetId, filterTreeParams]);
};
