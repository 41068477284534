import { css } from '@emotion/react';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useTranslation } from '../../utils/hooks';
import { EmptyStateComponent } from '../emptyState/emptyState.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '../scrollbar/scrollbar.component';
import {
  LocationListingItemComponent,
  type LocationListingItemProps,
} from './item/locationListingItem.component';
import {
  LOCATION_DESCRIPTION_FOOTER_HEIGHT, LocationListingFooterComponent,
} from './locationListingFooter.component';
import {
  LOCATION_DESCRIPTION_HEADER_HEIGHT, LocationListingHeaderComponent,
} from './locationListingHeader.component';
import { LocationListingWarningComponent } from './locationListingWarning.component';

const emptyStateStyle = css({
  padding: '32px 12px',
});

const absoluteListingWrapper = (props: { isMobileScreen: boolean; showFooter: boolean }) => css({
  position: props.isMobileScreen ? 'unset' : 'absolute',
  height: `calc(100% - ${LOCATION_DESCRIPTION_HEADER_HEIGHT
    + (props.showFooter ? LOCATION_DESCRIPTION_FOOTER_HEIGHT : 0)}px)`,
  left: 0,
  top: LOCATION_DESCRIPTION_HEADER_HEIGHT,
  width: '100%',
});

const relativeListingWrapper = css({
  position: 'relative',
  height: '100%',
  width: '100%',
});

type LocationListingProps = Readonly<{
  items: ReadonlyArray<Omit<LocationListingItemProps, 'index'>>;
  limitExceeded: boolean;
  listElementId?: string;
  readOnlyMode?: boolean;
  title: string;

  closeLocationsList: () => void;
  onExportFileClick?: () => void;
}>;

export const LocationListingComponent: FC<LocationListingProps> = props => {
  const isMobileScreen = useIsMobileScreenSelector();
  const [t] = useTranslation();

  const showFooter = !!props.onExportFileClick && !isMobileScreen && !props.limitExceeded;

  const locationListingItems = useMemo(() => props.items.map((item, index) => (
    <LocationListingItemComponent
      key={index}
      index={index}
      readOnlyMode={props.readOnlyMode}
      {...item}
    />
  )), [props.items, props.readOnlyMode]);

  const renderList = () => {
    if (props.limitExceeded) {
      return <LocationListingWarningComponent />;
    }

    if (!props.items.length) {
      return (
        <div css={emptyStateStyle}>
          <EmptyStateComponent
            icon={faLocationDot}
            title={t('No Visible Locations')}
            description={t('Your visible locations will be listed here')}
          />
        </div>
      );
    }

    return !isMobileScreen && !props.readOnlyMode ? (
      <ScrollBarComponent type={ScrollbarType.Vertical}>
        {locationListingItems}
      </ScrollBarComponent>
    ) : (
      <>
        {locationListingItems}
      </>
    );
  };

  return (
    <>
      <LocationListingHeaderComponent
        onCloseClick={props.closeLocationsList}
        title={props.title}
      />
      <div css={absoluteListingWrapper({ isMobileScreen, showFooter })} >
        <div
          id={props.listElementId}
          css={relativeListingWrapper}
        >
          {renderList()}
        </div>
      </div>
      {showFooter && (
        <LocationListingFooterComponent
          onExportFileClick={props.onExportFileClick}
        />
      )}
    </>
  );
};
