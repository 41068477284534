import { type DrawingCircleSettingsState } from '../../store/mapSettings/drawing/settings/circle/drawingCircleSettings.state';
import {
  DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE, DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
} from '../drawingTool.enums';
import { type DrawingToolOptions } from '../drawingTool.types';

export const createCircleDrawingOptionsFromSettings = (settings: DrawingCircleSettingsState): Partial<DrawingToolOptions> => {
  return {
    booleanOptions: {
      scalesWithMapZoom: settings.scalesWithMapZoom,
    },
    sliderOptions: {
      strokeOpacity: {
        value: settings.strokeOpacity,
      },
      fillOpacity: {
        value: settings.fillOpacity,
      },
      strokeWeight: {
        value: settings.strokeWeight,
        range: DRAWING_TOOL_SLIDER_STROKE_WIDTH_RANGE,
        suffix: DRAWING_TOOL_SLIDER_STROKE_WIDTH_SUFFIX,
      },
    },
    colorOptions: {
      strokeColor: settings.strokeColor,
      fillColor: settings.fillColor,
    },
  };
};

export const createCircleDrawingSettingsFromToolOptions = (
  toolOptions: Partial<DrawingToolOptions>,
  currentSettings: DrawingCircleSettingsState
): DrawingCircleSettingsState => {
  return {
    scalesWithMapZoom: toolOptions.booleanOptions?.scalesWithMapZoom ?? currentSettings.scalesWithMapZoom,
    radius: toolOptions.sliderOptions?.radius?.value ?? currentSettings.radius,
    strokeOpacity: toolOptions.sliderOptions?.strokeOpacity?.value ?? currentSettings.strokeOpacity,
    fillOpacity: toolOptions.sliderOptions?.fillOpacity?.value ?? currentSettings.fillOpacity,
    strokeWeight: toolOptions.sliderOptions?.strokeWeight?.value ?? currentSettings.strokeWeight,
    strokeColor: toolOptions.colorOptions?.strokeColor ?? currentSettings.strokeColor,
    fillColor: toolOptions.colorOptions?.fillColor ?? currentSettings.fillColor,
  };
};
