import { useMemo } from 'react';
import type { DropdownOption } from '~/_shared/baseComponents/dropdown';
import {
  getDemographicCategoryDropdownOptions, getDemographicDropdownOptions,
} from '~/_shared/components/customizeMetrics/customizeMetrics.helpers';
import type { BoundaryGroupDemographic } from '~/store/boundaryGroups/boundaryGroups.repository';

export const useDemographicDropdownOptions = (demographics: ReadonlyArray<BoundaryGroupDemographic>, selectedDemographicCategory: string | null) => {

  const demographicCategoryOptions: DropdownOption<string>[] = useMemo(() => (
    getDemographicCategoryDropdownOptions(demographics)
  ), [demographics]);

  const demographicOptions: DropdownOption<string>[] = useMemo(() => (
    getDemographicDropdownOptions(demographics, selectedDemographicCategory)
  ), [demographics, selectedDemographicCategory]);

  return {
    demographicCategoryOptions,
    demographicOptions,
  };
};
