import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TriStateRange } from '~/_shared/constants/triStateRange.enum';
import { useTranslation } from '~/_shared/utils/hooks';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { useBoundaryStateItem } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryStateItem';
import { useBoundaryTerritoryGroupDisplayName } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryTerritoryGroupDisplayName';
import {
  isCustomGroup, useBoundaryGroupsSelector,
} from '~/store/boundaryGroups/boundaryGroups.selectors';
import { boundaryTerritoryGroupsRemoveRequest } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import {
  activeMapElementsSetActiveBoundary, activeMapElementsSetActiveBoundaryTerritory,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { boundaryTerritoryGroupInStatusBarClicked } from '~/store/frontendState/mapTools/boundary/boundarySidebarState/boundarySidebarState.actionCreators';
import {
  clearBoundaryLocationsFilter, setBoundaryFilter,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import {
  useBoundaryFilters, useBoundaryLocationsFilters,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { isBoundaryGroupHidden } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.state';
import {
  createMapStatusBarItemForIsolatePolygons, createMapStatusBarItemFromBoundaryTerritoryGroupName,
} from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useActiveBoundariesStatusBarItems = () => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const boundaryFilters = useBoundaryFilters();
  const boundaryLocationFilters = useBoundaryLocationsFilters();
  const { getBoundaryTerritoryGroupName } = useBoundaryTerritoryGroupDisplayName();
  const { getBoundaryStateItem } = useBoundaryStateItem();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const territoryGroups = useMemo<MapStatusBarItem[]>(() => {
    return boundaryTerritoryGroups
      .map(boundaryTerritoryGroup => {
        const boundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === boundaryTerritoryGroup.boundaryGroupId);
        const groupName = getBoundaryTerritoryGroupName(boundaryTerritoryGroup);

        if (!boundaryGroup) {
          return null;
        }

        const filter = boundaryFilters.get(boundaryTerritoryGroup.boundaryGroupId);

        const isAnyBoundaryVisible = !isBoundaryGroupHidden(filter);

        return createMapStatusBarItemFromBoundaryTerritoryGroupName({
          t, territoryGroupName: groupName,
          isCustom: isCustomGroup(boundaryGroup),
          isVisible: isAnyBoundaryVisible,
          onShowAllChange: (isVisible: boolean) => {
            dispatch(setBoundaryFilter(boundaryTerritoryGroup.boundaryGroupId, {
              ...filter,
              filteredBoundaryTerritories: [],
              filteredBoundaries: [],
              showAll: isVisible ? TriStateRange.Full : TriStateRange.None,
            }));
          },
          onRemove: () => dispatch(boundaryTerritoryGroupsRemoveRequest(boundaryTerritoryGroup.boundaryTerritoryGroupId)),
          onClick: () => dispatch(boundaryTerritoryGroupInStatusBarClicked(boundaryTerritoryGroup.boundaryGroupId)),
        },
        );
      })
      .filter((statusBarItem): statusBarItem is MapStatusBarItem => !!statusBarItem);
  }, [boundaryTerritoryGroups, boundaryGroups, getBoundaryTerritoryGroupName, boundaryFilters, t, dispatch]);

  const isolatedBoundaries = useMemo(() => {
    return boundaryLocationFilters.entries
      .filter(f => f.filter.isolate)
      .map(entry => {
        const boundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === entry.boundaryGroupId);
        const boundaryTerritoryGroup = boundaryTerritoryGroups.find(btg => btg.boundaryGroupId === entry.boundaryGroupId);

        if (!boundaryGroup || !boundaryTerritoryGroup) {
          return;
        }

        return entry.filter.filteredBoundaries.map((boundaryId) => {
          const boundary = getBoundaryStateItem(boundaryId, entry.boundaryGroupId);
          if (!boundary || !boundaryTerritoryGroup) {
            return;
          }

          return createMapStatusBarItemForIsolatePolygons({
            t, name: boundary.displayName,
            onRemove: () => dispatch(clearBoundaryLocationsFilter()),
            onClick: () => dispatch(activeMapElementsSetActiveBoundary({
              boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId, boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId, boundaryId,
            })),
          });
        }).concat(entry.filter.filteredBoundaryTerritories.map(boundaryTerritoryId => {
          return createMapStatusBarItemForIsolatePolygons({
            t, name: boundaryGroup.name,
            onRemove: () => dispatch(clearBoundaryLocationsFilter()),
            onClick: () => dispatch(activeMapElementsSetActiveBoundaryTerritory({
              boundaryTerritoryGroupId: boundaryTerritoryGroup.boundaryTerritoryGroupId, boundaryGroupId: boundaryTerritoryGroup.boundaryGroupId, boundaryTerritoryId,
            })),
          });
        }));
      }).flat().filter(notNullOrUndefined);
  }, [boundaryGroups, boundaryLocationFilters.entries, boundaryTerritoryGroups, dispatch, getBoundaryStateItem, t]);

  return useMemo(() => ([
    ...isolatedBoundaries, ...territoryGroups,
  ]), [isolatedBoundaries, territoryGroups]);
};
