import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { spreadsheetResetStateAndRefetchData } from '~/store/spreadsheetData/spreadsheetData.actionCreators';
import { matchupRefetchData } from '../../store/matchupData/matchupData.actionCreators';
import { useClientIdSelector } from '../../store/selectors/useClientIdSelector';
import { usePrimarySpreadsheetId } from '../../store/selectors/usePrimarySpreadsheetId';
import { AddDataColumnComponent } from './addDataColumn.component';
import { useAddNewDataColumn } from './useAddNewDataColumn';

type AddDataColumnContainer = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const AddDataColumnContainer: FC<AddDataColumnContainer> = (props) => {
  const { isLoading, addNewDataColumn, isError } = useAddNewDataColumn();
  const spreadsheetId = usePrimarySpreadsheetId();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();

  const onFormSubmit = (columnName: string, defaultValue: string) => {
    if (clientId === null || spreadsheetId === null) {
      return;
    }

    addNewDataColumn(clientId, spreadsheetId, columnName, defaultValue)
      .then(() => {
        props.onClose();
        dispatch(spreadsheetResetStateAndRefetchData());
        dispatch(matchupRefetchData(spreadsheetId));
      });
  };

  return (
    <AddDataColumnComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={isLoading}
      isError={isError}
      onSubmit={onFormSubmit}
    />
  );
};
