import type { ActiveMapElementsAction } from '~/store/frontendState/activeMapElements/activeMapElements.action';
import {
  ACTIVE_MAP_ELEMENTS_CLEAR_STATE, ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionTypes';
import type { LocationFinderToolsStateAction } from './locationFinder.action';
import { setLocationFinderToolsState } from './locationFinder.actionCreators';
import type { LocationFinderToolsState } from './locationFinder.state';

export const mapSettingsLocationFinderInitialToolsState: LocationFinderToolsState = {
  latLng: null,
  locationLimit: null,
};

export const locationFinderToolStateReducer = (
  state = mapSettingsLocationFinderInitialToolsState,
  action: LocationFinderToolsStateAction | ActiveMapElementsAction,
): LocationFinderToolsState => {
  if (action.type === ACTIVE_MAP_SET_ACTIVE_LOCATION_LIST || action.type === ACTIVE_MAP_ELEMENTS_CLEAR_STATE) {
    return {
      ...mapSettingsLocationFinderInitialToolsState,
    };
  }

  if (setLocationFinderToolsState.match(action)) {
    return {
      ...state,
      latLng: action.payload.latLng,
      locationLimit: action.payload.locationLimit,
    };
  }

  return state;
};
