import { css } from '@emotion/react';
import {
  forwardRef, useMemo, useState,
} from 'react';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import maneuversSprite from '../../../../assets/images/maneuvers.svg';
import { createColor } from '../../_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '../../_shared/components/colorPicker/colorPicker.types';
import { useTheme } from '../../_shared/themes/theme.hooks';
import {
  type Maneuver, type UnitSystem,
} from '../../_shared/types/googleMaps/googleMaps.types';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { useTranslation } from '../../_shared/utils/hooks';
import { ModalType } from '../../modal/modalType.enum';
import { useModal } from '../../modal/useModal.hook';
import { type RouteUiData } from '../../store/frontendState/mapTools/directions/directions.state';
import { type MapSettingsRoute } from '../../store/mapSettings/directions/mapSettingsDirections.state';
import { getDefaultOrCustomRouteName } from '../panel/directionsPanel.helpers';
import {
  DirectionsWaypointPosition, getDirectionsTileColors,
} from '../panel/input/directionsPanelInput.helpers';
import {
  getDistanceLabel,
  getDurationLabel,
  getManeuverBackgroundPosition,
  getRouteEstimation,
} from './directionsListing.helpers';
import { LegHeaderComponent } from './legHeader/legHeader.component';
import { RouteHeaderComponent } from './routeHeader.component';

type DirectionsListingProps = Readonly<{
  expandedRouteIds: ReadonlySet<string>;
  routes: ReadonlyArray<MapSettingsRoute>;
  routesUiData: ReadonlyMap<string, RouteUiData>;
  unitSystem: UnitSystem;

  onLegToggle: (routeId: string, legIndex: number) => void;
  onLoadDirections: (routeId: string) => void;
  onRouteEdit: (routeId: string, name: string, color: ColorResult) => void;
  onRouteExport?: (routeId: string) => void;
  onRouteRemove?: (route: MapSettingsRoute) => void;
  onRouteToggle: (routeId: string) => void;
  onZoomToRoute: (routeId: string) => void;
  isPrintVersion?: boolean;
}>;

const accordionItemStyle = css({
  position: 'relative', // make the accordion item container of loader
});

const accordionItemButtonStyle = css({
  width: '100%',
  boxSizing: 'border-box',
});

const headerWrapperStyle = css({
  overflow: 'hidden',
});

const accordionPanelStyle = css({
  padding: 0,
});

const routeStepStyle = ({ theme }: ThemeProps) => css({
  position: 'relative',
  padding: '15px 15px 15px 45px',
  background: theme.directionsPanelColors.routeStepBackground,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  display: 'flex',
  fontSize: '14px',
  fontWeight: 400,
});

const stepEstimationStyle = css({
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  marginLeft: 'auto',
});

const stepInstructionsStyle = css({
  marginRight: 8,
});

const copyrightsStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
  borderTop: `1px solid ${theme.borderColors.primary}`,
  padding: '8px 0 8px 30px',
  fontSize: 12,
});

const maneuverStyle = ({ theme, maneuver }: ThemeProps<{ maneuver: Maneuver }>) => css({
  visibility: !maneuver ? 'hidden' : 'visible',
  backgroundSize: '19px 630px',
  position: 'absolute',
  left: 15,
  top: '50%',
  width: 16,
  height: 16,
  marginTop: -8,
  filter: theme.name === 'dark' ? 'invert()' : undefined,
  backgroundImage: `url(${maneuversSprite})`,
  backgroundPosition: getManeuverBackgroundPosition(maneuver),
});

const rootStyle = ({ isPrintVersion }: Readonly<{ isPrintVersion: boolean | undefined }>) => css(isPrintVersion
  ? {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  }
  : {});

export const DirectionsListingComponent = forwardRef<HTMLDivElement, DirectionsListingProps>((props, forwardedRef) => {
  const [routeVisibleSettingsIndex, setRouteVisibleSettingsIndex] = useState<null | number>(null);
  const [t] = useTranslation();
  const theme = useTheme();
  const { openModal, closeModal } = useModal(ModalType.DirectionsEditRoute);
  const { onRouteRemove } = props;

  const hideSettingsMenu = () => setRouteVisibleSettingsIndex(null);

  const onEditRouteClick = (routeId: string) => {
    const onSave = (name: string, color: ColorResult) => {
      props.onRouteEdit(routeId, name, color);
      closeModal();
    };

    openModal({
      routeName: getDefaultOrCustomRouteName(props.routes.find(r => r.id === routeId)?.name ?? '', t),
      routeColor: createColor(props.routes.find(r => r.id === routeId)?.color ?? '#000000'),
      onSave,
    });
  };

  const showSettingsMenu = (routeItemIndex: number) => setRouteVisibleSettingsIndex(routeItemIndex);

  const onDirectionsRemove = useMemo(() =>
    onRouteRemove ? (route: MapSettingsRoute) => () => onRouteRemove(route) : undefined,
  [onRouteRemove]);

  return (
    <div
      ref={forwardedRef}
      css={rootStyle({ isPrintVersion: props.isPrintVersion })}
    >
      <AccordionComponent
        headerSize={AccordionHeaderSize.Flexible}
        itemStyle={accordionItemStyle}
        itemButtonStyle={accordionItemButtonStyle}
        headerWrapperStyle={headerWrapperStyle}
        panelStyle={accordionPanelStyle}
        data={props.routes.map((routeItem, routeItemIndex) => {
          const uiData = props.routesUiData.get(routeItem.id);
          if (!uiData) {
            return {
              header: (
                <RouteHeaderComponent
                  isMenuVisible={false}
                  routeItem={routeItem}
                  totalDistance={0}
                  totalDuration={0}
                  unitSystem={props.unitSystem}
                  onRouteExport={props.onRouteExport ? () => {
                    props.onRouteExport?.(routeItem.id);
                  } : undefined}
                  isLoading
                />
              ),
              child: null,
              isLocked: true,
              isExpanded: false,
            };
          }

          const routeEstimation = getRouteEstimation(uiData.legs);
          const totalDistance = routeEstimation.distance;
          const totalDuration = routeEstimation.duration;

          //it's important that boolean type variable is passed here, because 'undefined' results in conflicting behavior
          const isExpanded: boolean = props.expandedRouteIds.has(routeItem.id) || !!props.isPrintVersion;

          return ({
            isExpanded,
            onHeadingClick: () => props.onRouteToggle(routeItem.id),
            header: (
              <RouteHeaderComponent
                isMenuVisible={routeVisibleSettingsIndex === routeItemIndex}
                onEditRoute={() => onEditRouteClick(routeItem.id)}
                onHideMenu={hideSettingsMenu}
                onLoadDirections={() => props.onLoadDirections(routeItem.id)}
                onRemoveRoute={onDirectionsRemove?.(routeItem)}
                onShowMenu={() => showSettingsMenu(routeItemIndex)}
                onZoomToRoute={() => props.onZoomToRoute(routeItem.id)}
                routeItem={routeItem}
                totalDistance={totalDistance}
                totalDuration={totalDuration}
                unitSystem={props.unitSystem}
                isLoading={false}
                onRouteExport={props.onRouteExport ? () => {
                  props.onRouteExport?.(routeItem.id);
                } : undefined}
              />
            ),
            child: (
              <div>
                {uiData.legs.map((leg, legIndex) => {
                  const isExpanded = uiData.expandedLegsIndexes.has(legIndex) || props.isPrintVersion;
                  const tileColors = getDirectionsTileColors(legIndex === 0 ? DirectionsWaypointPosition.Start : DirectionsWaypointPosition.Middle, theme, routeItem.color);
                  const lastTileColors = getDirectionsTileColors(DirectionsWaypointPosition.End, theme, routeItem.color);

                  return (
                    <div key={legIndex}>
                      <LegHeaderComponent
                        number={legIndex + 1}
                        address={leg.start_address}
                        colorTileBackground={tileColors.background}
                        colorTileColor={tileColors.textColor}
                        isExpandable
                        estimation={leg.distance && leg.duration ?
                          `${getDistanceLabel(leg.distance.value, props.unitSystem)} / ${getDurationLabel(leg.duration.value, t)}`
                          : undefined
                        }
                        isExpanded={isExpanded}
                        onClick={() => props.onLegToggle(routeItem.id, legIndex)}
                      />

                      {isExpanded && leg.steps.map((step, stepIndex) => (
                        <div
                          key={stepIndex}
                          css={routeStepStyle({ theme })}
                        >
                          <span css={maneuverStyle({ theme, maneuver: step.maneuver })} />
                          <div
                            css={stepInstructionsStyle}
                            dangerouslySetInnerHTML={{ __html: step.instructions }}
                          />
                          {step.distance &&
                            <div css={stepEstimationStyle}>{getDistanceLabel(step.distance.value, props.unitSystem)}</div>
                          }
                        </div>
                      ))}

                      {legIndex === uiData.legs.length - 1 && (
                        <LegHeaderComponent
                          address={leg.end_address}
                          colorTileBackground={lastTileColors.background}
                          colorTileColor={lastTileColors.textColor}
                          number={uiData.legs.length + 1}
                          isExpandable={false}
                        />
                      )}
                    </div>
                  );
                })}

                <div css={copyrightsStyle({ theme })}>
                  {uiData.copyrights}
                </div>
              </div>
            ),
          });
        })}
      />
    </div>
  );
});
