import { css } from '@emotion/react';
import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import { ButtonComponent } from '~/_shared/baseComponents/buttons';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import {
  LottieAnimationComponent, LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  boundaryTerritoryGroupsCloneRequest,
  boundaryTerritoryGroupsFetchRequest,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';

export type CloneBoundaryTerritoryGroupModalProps = ModalProps<{
  boundaryTerritoryGroupId: number;
}>;

type CloningStatus = 'INITIAL' | 'IN_PROGRESS' | 'SUCCESS' | 'ERROR';

const contentStyle = css`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const CloneBoundaryTerritoryGroupModal = (props: CloneBoundaryTerritoryGroupModalProps) => {
  const { onClose, boundaryTerritoryGroupId, isOpen } = props;
  const [t] = useTranslation();
  const [newTerritoryGroupName, setNewTerritoryGroupName] = useState('');
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();
  const [cloningStatus, setCloningStatus] = useState<CloningStatus>('INITIAL');

  const cloneBoundaryTerritoryGroup = useCallback(async () => {
    setCloningStatus('IN_PROGRESS');

    if (mapId === null) {
      setCloningStatus('ERROR');
      console.error('Map id is null');
      return;
    }

    await new Promise(() => {
      dispatch(boundaryTerritoryGroupsCloneRequest({
        request: {
          boundary_territory_group_id: boundaryTerritoryGroupId,
          new_boundary_territory_group_name: newTerritoryGroupName,
          map_id: mapId,
        },
        onSuccess: () => {
          dispatch(boundaryTerritoryGroupsFetchRequest(mapId));
          setCloningStatus('SUCCESS');
          Promise.resolve();
        },
        onError: e => {
          setCloningStatus('ERROR');
          console.error(e);
        },
      }));
    });
  }, [newTerritoryGroupName, boundaryTerritoryGroupId, mapId, dispatch]);

  return (
    <ModalComponent
      onClose={onClose}
      isOpen={isOpen}
      caption={t('Copy Territory Group')}
      isCloseDisabled={cloningStatus === 'IN_PROGRESS'}
      contentStyle={contentStyle}
      confirmButton={cloningStatus !== 'SUCCESS' ? (
        <ButtonComponent
          text={t('Copy')}
          isDisabled={newTerritoryGroupName === ''}
          onClick={cloneBoundaryTerritoryGroup}
        />
      ) : undefined}
      additionalContent={cloningStatus === 'IN_PROGRESS' ? (
        <OverlayLoaderComponent />
      ) : undefined}
    >
      {cloningStatus !== 'SUCCESS' && (
        <FormTextInputComponent
          label={t('New Territory Group Name')}
          onChange={setNewTerritoryGroupName}
          value={newTerritoryGroupName}
          placeholder={t('Territory Group Name')}
        />
      )}
      {cloningStatus === 'ERROR' && (
        <AlertComponent
          type="danger"
        >
          {t('Territory group copy error')}
        </AlertComponent>
      )}
      {cloningStatus === 'SUCCESS' && (
        <>
          {t('Territory group was successfully copied')}
          <LottieAnimationComponent
            size={100}
            type={LottieAnimationTypes.Success}
            autoplay
            loop={false}
            onAnimationEnd={onClose}
          />
        </>
      )}
    </ModalComponent>
  );
};
