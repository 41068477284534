import {
  type MapSettingsMapStylesChangeOptions,
  type MapSettingsStylesChangeData,
} from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';
import { makeSliderChangeDataVisible } from './baseMapStandardSliders.helpers';
import { type BaseMapSliderOptions } from './baseMapStandardSliders.types';

const hiddenAdminGeometry: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.geometry,
  featureType: BaseMapFeatureTypeName.administrative,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenPoiAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName.poi,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenPoiLabelText: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName['labels.text'],
  featureType: BaseMapFeatureTypeName.poi,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenPoiBusinessAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['poi.business'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenRoadLabelIcon: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName['labels.icon'],
  featureType: BaseMapFeatureTypeName.road,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenTransitAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName.transit,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenPoiParkLabelText: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName['labels.text'],
  featureType: BaseMapFeatureTypeName['poi.park'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const firstLandmarksSlider: MapSettingsStylesChangeData = [
  hiddenAdminGeometry,
  hiddenPoiAll,
  hiddenRoadLabelIcon,
  hiddenTransitAll,
];

const secondLandmarksSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(firstLandmarksSlider),
  hiddenPoiLabelText,
  hiddenPoiBusinessAll,
  hiddenRoadLabelIcon,
  hiddenTransitAll,
];

const thirdLandmarksSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(secondLandmarksSlider),
  hiddenPoiBusinessAll,
  hiddenPoiParkLabelText,
];

const forthLandmarksSlider: MapSettingsStylesChangeData = makeSliderChangeDataVisible(thirdLandmarksSlider);

export const landmarksSliderOptions: BaseMapSliderOptions = [
  firstLandmarksSlider,
  secondLandmarksSlider,
  thirdLandmarksSlider,
  forthLandmarksSlider,
];
