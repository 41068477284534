import { css } from '@emotion/react';
import { type FC } from 'react';
import { LogoComponent } from '~/_shared/components/logo/logo.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';

type AuthorizationPageWrapperProps = Readonly<{
  maxWidth?: number;
  children?: React.ReactNode;
}>;

const wrapperStyle = (maxWidth: number) => css({
  maxWidth,
  padding: '0 10px 20px',
  margin: '0 auto',
});

const logoWrapperStyle = css({
  display: 'flex',
  margin: '64px 0 32px',
  justifyContent: 'center',
});

export const AuthorizationPageWrapperComponent: FC<AuthorizationPageWrapperProps> = (props) => {
  const maxWidth = props.maxWidth ?? 600;

  return (
    <ScrollBarComponent
      type={ScrollbarType.Vertical}
      contentStyle={{
        height: '100%',
        display: 'block',
      }}
    >
      <div css={wrapperStyle(maxWidth)}>
        <div css={logoWrapperStyle} >
          <LogoComponent width="210px" />
        </div>

        {props.children}
      </div>
    </ScrollBarComponent>
  );
};
