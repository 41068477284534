import {
  put, takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import type { PickAction } from '~/_shared/utils/types/action.type';
import { mapSettingsEnableClickableIcons } from '~/store/mapSettings/settings/mapSettingsSettings.actionCreators';
import { mapSettingsSettingsClickableIconsSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import type { MapSettingsReadyAction } from '../ready/mapSettingsReady.action';
import { MAP_SETTINGS_READY_SET } from '../ready/mapSettingsReady.actionTypes';

export function* mapSettingsSettingsSagas() {
  yield takeLatest(MAP_SETTINGS_READY_SET, enableClickableIcons);
}

function* enableClickableIcons(action: PickAction<MapSettingsReadyAction, typeof MAP_SETTINGS_READY_SET>) {
  if (!action.payload.isReady) {
    return;
  }

  const mapSettingsClickableIcons: boolean = yield select(mapSettingsSettingsClickableIconsSelector);
  if (mapSettingsClickableIcons) {
    yield put(mapSettingsEnableClickableIcons());
  }
}
