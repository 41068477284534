import {
  css, type Interpolation,
} from '@emotion/react';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faPencil, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import React, {
  forwardRef, type ReactNode, useCallback, useMemo,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { Stack } from '~/_shared/baseComponents/layout/Stack.componment';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { guaranteeHash } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import type { Theme } from '~/_shared/themes/theme.model';
import { LOCATION_PANEL_DATA_CLASSNAME } from '~/customizeLocationPanel/customizeLocationPanel.constants';
import {
  SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE, SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE,
  SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE,
} from './sidebarTitle.constants';
import { SidebarTitleType } from './sidebarTitle.enums';
import {
  type SidebarTitle, type SidebarTitleStyle,
} from './sidebarTitle.types';

const TOOLTIP_DELAY = 500;
export const INDICATOR_WIDTH = 48;

const iconWrapperStyle = css({
  lineHeight: '24px',
  margin: '10px 16px',
});

const markerIconStyle = (theme: Theme) => css({
  color: theme.iconColors.contrast,
  fontSize: '28px',
});

const wrapperStyle = (theme: Theme) => css({
  display: 'flex',
  borderBottom: '1px solid ' + theme.lineColors.basePrimary,
  boxSizing: 'border-box',
  padding: '8px 0 8px 4px',
  gap: '5px',
});

const dataWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '20px',
  fontWeight: 500,
  height: '100%',
  lineHeight: '30px',
  width: '100%',
  overflow: 'hidden',
});

const dataPaneStyle = css({
  justifyContent: 'center',
  overflow: 'hidden',
});

type MarkerDescriptionStyleProps = Readonly<{
  fontColor: string;
  fontSize: string;
  isSelected: boolean;
  isVisible: boolean;
  capitalize?: boolean;
}>;

const descriptionItemStyle = (props: MarkerDescriptionStyleProps) => (theme: Theme) => css({
  backgroundColor: props.isSelected ? theme.backgroundColors.secondaryHover : 'inherit',
  color: guaranteeHash(props.fontColor),
  fontSize: `${props.fontSize}px`,
  fontWeight: 500,
  height: props.isVisible ? '' : `${parseInt(props.fontSize, 10) + 4}px`,
  lineHeight: `${Math.floor(parseInt(props.fontSize, 10) * 1.3)}px`,
  padding: '2px',
  width: props.isVisible ? '100%' : '250px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: props.capitalize ? 'uppercase' : undefined,
  boxSizing: 'border-box',
});

const indicatorWrapperStyle = css({
  width: INDICATOR_WIDTH,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ctaWrapperStyle = css({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  margin: '2px 4px 0 0',
  gap: 8,
});

const pencilIconStyle = (theme: Theme) => css({
  fontSize: '14px',
  padding: 4,
  margin: 0,
  color: theme.iconColors.secondary,
  '&:hover': {
    color: theme.iconColors.contrast,
  },
});

const xIconStyle = (theme: Theme) => css({
  fontSize: '18px',
  padding: 4,
  '&:hover': {
    color: theme.iconColors.contrast,
  },
});

export type MarkerDescriptionProps = Readonly<{
  containerStyle?: Interpolation<Theme>;
  icon?: IconProp;
  iconStyle?: Interpolation<Theme>;
  iconWrapperStyle?: Interpolation<Theme>;
  isEditable?: boolean;
  sidebarTitle: SidebarTitle;
  sidebarTitleStyle?: SidebarTitleStyle | null;
  selectedTitleItem?: SidebarTitleType | null;
  indicator?: ReactNode;

  onEditClick?: () => void;
  onXClick: () => void;
  onSelectItem?: (name: SidebarTitleType | null) => void;
}>;

export const SidebarTitleComponent = forwardRef<any, MarkerDescriptionProps>((props, ref) => {
  const theme = useTheme();

  const addressStyleSettings = useMemo(() => ({
    ...SIDEBAR_TITLE_DESCRIPTION_DESCRIPTION_STYLE(theme),
    ...props.sidebarTitleStyle?.description,
  }), [props.sidebarTitleStyle?.description, theme]);

  const headerStyleSettings = useMemo(() => ({
    ...SIDEBAR_TITLE_HEADER_DESCRIPTION_STYLE(theme),
    ...props.sidebarTitleStyle?.header,
  }), [props.sidebarTitleStyle?.header, theme]);

  const semiHeaderStyleSettings = useMemo(() => ({
    ...SIDEBAR_TITLE_HEADER_SEMIHEADER_STYLE(theme),
    ...props.sidebarTitleStyle?.semiHeader,
  }), [props.sidebarTitleStyle?.semiHeader, theme]);

  const handleSelect = useCallback((itemName: SidebarTitleType | null) => () => {
    const onSelectItem = props.onSelectItem;
    onSelectItem?.(itemName);
  }, [props.onSelectItem]);

  const headerText = headerStyleSettings.showValue && props.sidebarTitle?.header;
  const semiHeaderText = semiHeaderStyleSettings.showValue && props.sidebarTitle.semiHeader;

  return (
    <div css={[wrapperStyle, props.containerStyle]}>
      {props.icon && (
        <div css={[iconWrapperStyle, props.iconWrapperStyle]}>
          <FontAwesomeIcon
            css={[markerIconStyle, props.iconStyle]}
            icon={props.icon}
          />
        </div>
      )}

      {props.indicator && (
        <div css={indicatorWrapperStyle}>
          {props.indicator}
        </div>
      )}

      <div css={dataWrapperStyle}>
        <Stack
          gap={2}
          className={LOCATION_PANEL_DATA_CLASSNAME}
          css={dataPaneStyle}
          ref={ref}
        >
          <div
            css={descriptionItemStyle({
              fontColor: headerStyleSettings.valueFontColor,
              fontSize: headerStyleSettings.valueFontSize,
              isSelected: !!props.isEditable && props.selectedTitleItem === SidebarTitleType.header,
              isVisible: headerStyleSettings.showValue,
            })}
            onClick={handleSelect(SidebarTitleType.header)}
          >
            {headerText && (
              <TooltipComponent
                tooltipContent={props.sidebarTitle?.header}
                behavior={TooltipBehavior.ShowOnOverflow}
                delayShow={TOOLTIP_DELAY}
              >
                <LimitNumberOfLinesComponent
                  maxNumberOfLines={4}
                >
                  {headerText}
                </LimitNumberOfLinesComponent>
              </TooltipComponent>
            )}
          </div>

          {React.isValidElement(semiHeaderText) ? semiHeaderText : (
            semiHeaderText && (
              <div
                css={descriptionItemStyle({
                  fontColor: semiHeaderStyleSettings.valueFontColor,
                  fontSize: semiHeaderStyleSettings.valueFontSize,
                  isSelected: !!props.isEditable && props.selectedTitleItem === SidebarTitleType.semiHeader,
                  isVisible: semiHeaderStyleSettings.showValue,
                })}
                onClick={() => handleSelect(SidebarTitleType.semiHeader)}
              >
                <TooltipComponent
                  tooltipContent={semiHeaderText}
                  behavior={TooltipBehavior.ShowOnOverflow}
                  delayShow={TOOLTIP_DELAY}
                >
                  <LimitNumberOfLinesComponent
                    maxNumberOfLines={3}
                  >
                    {semiHeaderText}
                  </LimitNumberOfLinesComponent>
                </TooltipComponent>
              </div>
            )
          )}

          {props.sidebarTitle.description !== undefined && (
            <Stack
              gap={2}
              css={descriptionItemStyle({
                fontColor: addressStyleSettings.valueFontColor,
                fontSize: addressStyleSettings.valueFontSize,
                isSelected: !!props.isEditable && props.selectedTitleItem === SidebarTitleType.description,
                isVisible: addressStyleSettings.showValue,
                capitalize: addressStyleSettings.capitalize,
              })}
              onClick={() => handleSelect(SidebarTitleType.description)}
            >
              {addressStyleSettings.showValue && props.sidebarTitle.description.slice(0, 4).map((line, index) => (
                <TooltipComponent
                  key={index}
                  tooltipContent={line}
                  behavior={TooltipBehavior.ShowOnOverflow}
                  delayShow={TOOLTIP_DELAY}
                >
                  <LimitNumberOfLinesComponent
                    maxNumberOfLines={1}
                  >
                    <div>{line}</div>
                  </LimitNumberOfLinesComponent>
                </TooltipComponent>
              ))}
            </Stack>
          )}
        </Stack>

        <div css={ctaWrapperStyle}>
          {props.onEditClick && (
            <FontAwesomeIcon
              onClick={props.onEditClick}
              css={pencilIconStyle}
              icon={faPencil}
            />
          )}

          <FontAwesomeIcon
            onClick={props.onXClick}
            css={xIconStyle}
            icon={faXmark}
          />
        </div>
      </div>
    </div>
  );
});
