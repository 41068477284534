import {
  type FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { useMapObjectContext } from '../private/mapObjectContext';

export type CircleVisualsConfig = {
  readonly size: number;
  readonly color: WebglColor;
  readonly style: 'thin' | 'thick';
};

export type DotVisualsConfig = {
  readonly size: number;
  readonly color: WebglColor;
  readonly transparent?: boolean;
};

export type MapOutlineVisualsConfig = {
  readonly dot: DotVisualsConfig;
  readonly circle?: CircleVisualsConfig;
};

export type MapOutlineInstance = {
  readonly id: Uuid;
  readonly lat: number;
  readonly lng: number;
  readonly offsetX?: number;
  readonly offsetY?: number;
};

type MapOutlineProps = {
  outline: MapOutlineInstance;
  label?: string;
  visuals: MapOutlineVisualsConfig;
  onDragMove?: (outlineId: Uuid, latLng: LatLng) => void;
  onDragStart?: (outlineId: Uuid, latLng: LatLng) => void;
  onDragEnd?: (outlineId: Uuid) => void;
  onMouseOver?: (outlineId: Uuid) => void;
  onMouseOut?: (outlineId: Uuid) => void;
  onClick?: (outlineId: Uuid, e: MapObjectClickEventArgs) => void;
  onRightClick?: (outlineId: Uuid, e: MapObjectClickEventArgs) => void;
};

const MapOutline: FC<MapOutlineProps> = (props) => {
  const { manager, zIndex } = useMapObjectContext();
  const { outline, label, visuals } = props;
  const { onDragMove, onDragStart, onDragEnd, onMouseOver, onMouseOut, onClick, onRightClick } = props;

  const [marker, setMarker] = useState<WebglOverlayMarker>();

  const outlineOnDragMove = useMemo(() => onDragMove ? (latLng: LatLng) => onDragMove(outline.id, latLng) : undefined, [outline.id, onDragMove]);
  const outlineOnDragStart = useMemo(() => onDragStart ? (latLng: LatLng) => onDragStart(outline.id, latLng) : undefined, [outline.id, onDragStart]);
  const outlineOnDragEnd = useMemo(() => onDragEnd ? () => onDragEnd(outline.id) : undefined, [outline.id, onDragEnd]);
  const outlineOnMouseOver = useMemo(() => onMouseOver ? () => onMouseOver(outline.id) : undefined, [onMouseOver, outline.id]);
  const outlineOnMouseOut = useMemo(() => onMouseOut ? () => onMouseOut(outline.id) : undefined, [onMouseOut, outline.id]);

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: marker,
    onDragMove: outlineOnDragMove,
    onDragStart: outlineOnDragStart,
    onDragEnd: outlineOnDragEnd,
    onMouseOver: outlineOnMouseOver,
    onMouseOut: outlineOnMouseOut,
  }), [manager.map, marker, outlineOnDragEnd, outlineOnDragMove, outlineOnDragStart, outlineOnMouseOut, outlineOnMouseOver]);

  // Draw outline marker
  useEffect(() => {
    const updatedMarker = manager.upsertOutlineMarker(outline, {
      ...visuals.dot,
      offsetX: outline.offsetX,
      offsetY: outline.offsetY,
      zIndex: visuals.dot.transparent ? zIndex.transparentOutlineDot : zIndex.outlineDot,
    });

    if (marker !== updatedMarker) {
      setMarker(updatedMarker);
    }

    if (visuals.circle) {
      manager.upsertOutlineCircleMarker(outline, {
        ...visuals.circle,
        offsetX: outline.offsetX,
        offsetY: outline.offsetY,
        zIndex: zIndex.outlineCircle,
      });
    }
    else {
      manager.removeOutlineCircleMarker(outline.id);
    }
  }, [manager, outline, zIndex.outlineCircle, visuals, zIndex.outlineDot, marker, zIndex.transparentOutlineDot]);

  //Draw label
  useEffect(() => {
    if (label) {
      manager.upsertOutlineLabel(outline.id, label, zIndex.outlineLabel);
    }
    else {
      manager.removeOutlineLabel(outline.id);
    }
  }, [manager, outline.id, label, zIndex.outlineLabel]);

  // Web-gl cleanup
  useEffect(() => {
    return () => {
      manager.removeOutlineMarker(outline.id);
      manager.removeOutlineCircleMarker(outline.id);
      manager.removeOutlineLabel(outline.id);
    };
  }, [manager, outline.id]);

  // Registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addOutlineMarkerEventListener(outline.id, 'click',
      (e) => onClick?.(outline.id, e));

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, outline.id]);

  // Registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseRightClickCleanup = manager.addOutlineMarkerEventListener(outline.id, 'rightclick',
      (e) => onRightClick?.(outline.id, e));

    return () => {
      mouseRightClickCleanup();
    };
  }, [manager, onRightClick, outline.id]);

  return null;
};

const pureComponent = memo(MapOutline);
export { pureComponent as MapOutline };
