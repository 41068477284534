import { type Operation } from 'fast-json-patch';

export const getPathForUserDataSettingsTree = (diff: Operation[]): Operation[] => {
  const mappedDiff: Array<Operation> = [];

  diff.forEach(item => {
    const items: string[] = item.path.split('/').filter(item => item.length > 0); // split by "/" and remove empty

    const pathArray = ['settings', ...items];
    const path = pathArray.join('/');

    mappedDiff.push({
      ...item,
      path,
    });
  });

  return mappedDiff;
};
