import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { MapAreaColors } from '~/_shared/constants/mapObjects/mapAreaColors.constants';
import {
  isDriveTimePolygon, isGroupRadius, isIndividualRadius, type Proximity,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { formatDistance } from '~/_shared/utils/distance/distance.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  ERASURE_KEYS, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useProximityMetricsData } from '~/_shared/utils/metric/useProximityMetricsData';
import { computeProximityStyles } from '~/_shared/utils/proximity';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type ProximityEditProperties } from '~/proximity/edit/proximityEdit.component';
import {
  createProximityFilterArgumentsFromProximity, getDriveTimePolygonLabels, getGroupRadiusProximityDetails,
  getUpdatedProximity,
} from '~/proximity/proximity.helpers';
import { useIsProximityExportAllowed } from '~/proximity/useIsProximityExportAllowed';
import { useProximities } from '~/proximity/useProximities';
import { useProximityRemove } from '~/proximity/useProximityRemove';
import { getGroupProximityLocations } from '~/proximityDetails/proximityDetails.helpers';
import { type ActiveProximity } from '~/sidebar/sidebarApps/rightSidebar/rightSidebarConfiguration';
import { useSidebarIndicatorStyles } from '~/sidebar/sidebarApps/rightSidebar/useSidebarIndicatorStyles';
import { useAreaMarkers } from '~/spreadsheet/useAreaMarkers';
import { useAreaSpreadsheetRowIds } from '~/spreadsheet/useAreaSpreadsheetRowIds';
import {
  activeMapElementsSetActiveProximity, deactivateActiveProximity,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { mapComponentSetCenter } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import { frontendStateDirectionsAddMarkerWaypoint } from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import { modifyProximity } from '~/store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import {
  hideProximity, showProximity,
} from '~/store/mapSettings/toolsState/visibility/visibility.actionCreators';
import { useHiddenProximityIdsSelector } from '~/store/mapSettings/toolsState/visibility/visibility.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useSpreadsheetIdsSelector } from '~/store/selectors/useSpreadsheetIdsSelector';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { ProximityDetailsPanelComponent } from './proximityDetailsPanel.component';

const { borderColor, borderColorAlternative } = MapAreaColors.active;

type ProximitySettingsPanelContainerProps = Readonly<{
  activeMetrics: ActiveProximity;
  onClose: () => void;
}>;

export const ProximityDetailsPanelContainer: FC<ProximitySettingsPanelContainerProps> = ({
  activeMetrics,
}) => {
  const { proximities: proximityList } = useProximities();
  const latLngLookup = useLatLngSpreadsheetData();
  const spreadsheetIds = useSpreadsheetIdsSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const { metricsResults, isLoading } = useProximityMetricsData(activeMetrics);
  const { openModal: openProximityEditModal, closeModal: closeProximityEditModal } = useModal(ModalType.ProximityEdit);
  const { openModal: openExportProximityDataModal } = useModal(ModalType.ExportProximityData);
  const { openModal: openProximitySettingsModal } = useModal(ModalType.ProximitySettings);
  const { openModal: openExportContainedBoundaryDataModal } = useModal(ModalType.ExportContainedBoundaryData);
  const { onProximityRemove } = useProximityRemove();
  const { getAreaData, perSpreadsheetRowIds, isLoading: isMarkerCountLoading } = useAreaSpreadsheetRowIds();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const activeProximity = proximityList.find(proximity => proximity.id === activeMetrics.proximityId);
  const hiddenProximityIds = useHiddenProximityIdsSelector();
  const isActiveProximityVisible = !!activeProximity && !hiddenProximityIds.includes(activeProximity.id);

  const areaMarkers = useAreaMarkers(perSpreadsheetRowIds, activeMetrics.spreadsheetRowId);
  const isExportAllowed = useIsProximityExportAllowed();

  const locationId = activeMetrics.spreadsheetRowId?.rowId.toString() ?? '';

  const closeActiveProximity = useCallback(() => {
    dispatch(deactivateActiveProximity());
  }, [dispatch]);

  const locationsOrder: SpreadsheetRowId[] = useMemo(() => {
    if (!spreadsheetData || !activeProximity) {
      return [];
    }

    return getGroupProximityLocations(spreadsheetData, activeProximity);
  }, [spreadsheetData, activeProximity]);

  const activateGroupProximity = useCallback((rowId: SpreadsheetRowId) => {
    if (!activeProximity) {
      return;
    }
    dispatch(activeMapElementsSetActiveProximity(activeProximity.id, rowId));

    const newLatLng = latLngLookup.getRow(rowId);
    if (newLatLng) {
      dispatch(mapComponentSetCenter(newLatLng));
    }
  }, [activeProximity, dispatch, latLngLookup]);

  const indexOfCurrentLocation = useMemo(() =>
    locationsOrder.findIndex(item => item.rowId.toString() === locationId),
  [locationId, locationsOrder]);

  const updateProximity = useCallback((proximity: Proximity, proximityProperties: ProximityEditProperties) => {
    const newProximity = getUpdatedProximity(proximity, proximityProperties, activeMetrics.spreadsheetRowId);

    dispatch(modifyProximity(newProximity));
  }, [dispatch, activeMetrics.spreadsheetRowId]);

  const onNextArrowClick = useCallback(() => {
    if (indexOfCurrentLocation === -1) {
      return;
    }

    const newIndex = (indexOfCurrentLocation + 1) % locationsOrder.length;
    const spreadsheetRowId = locationsOrder[newIndex];
    if (spreadsheetRowId) {
      activateGroupProximity(spreadsheetRowId);
    }
  }, [activateGroupProximity, indexOfCurrentLocation, locationsOrder]);

  const onPreviousArrowClick = useCallback(() => {
    if (indexOfCurrentLocation === -1) {
      return;
    }

    let newIndex = indexOfCurrentLocation - 1;
    if (newIndex < 0) {
      newIndex = locationsOrder.length - 1;
    }
    const spreadsheetRowId = locationsOrder[newIndex];
    if (spreadsheetRowId) {
      activateGroupProximity(spreadsheetRowId);
    }
  }, [activateGroupProximity, indexOfCurrentLocation, locationsOrder]);

  const arrowProps = useMemo(() => {
    if (locationsOrder.length > 1 && activeProximity && isGroupRadius(activeProximity)) {
      return {
        left: { onClick: onPreviousArrowClick },
        right: { onClick: onNextArrowClick },
      };
    }

    return undefined;
  }, [activeProximity, locationsOrder.length, onNextArrowClick, onPreviousArrowClick]);

  const onDeleteProximityClick = useMemo(() => !isMapPresentational ? () => {
    if (!activeProximity) {
      return;
    }

    onProximityRemove(activeProximity.id);
    closeActiveProximity();
  } : undefined, [activeProximity, isMapPresentational, onProximityRemove, closeActiveProximity]);

  const onProximityEditClick = useMemo(() => !isMapPresentational ? () => {
    if (!activeProximity) {
      return;
    }

    const onSubmit = (proximityProperties: ProximityEditProperties) => {
      updateProximity(activeProximity, proximityProperties);
      closeProximityEditModal();
    };

    openProximityEditModal({
      onSubmit,
      proximity: activeProximity,
      spreadsheetRowId: activeMetrics.spreadsheetRowId,
    });
  } : undefined, [activeProximity, closeProximityEditModal, isMapPresentational,
    openProximityEditModal, activeMetrics.spreadsheetRowId, updateProximity]);

  const onEditMetricsClick = useMemo(() => !isMapPresentational
    ? () => openProximitySettingsModal()
    : undefined,
  [isMapPresentational, openProximitySettingsModal]);

  const onProximityHideClick = useMemo(() => !isMapPresentational || publicMapSettings.radiusProximity ? () => {
    if (!activeProximity) {
      return;
    }

    dispatch(hideProximity(activeProximity.id));
  } : undefined, [activeProximity, dispatch, isMapPresentational, publicMapSettings.radiusProximity]);

  const onProximityShowClick = useMemo(() => !isMapPresentational || publicMapSettings.radiusProximity ? () => {
    if (!activeProximity) {
      return;
    }

    dispatch(showProximity(activeProximity.id));
  } : undefined, [activeProximity, dispatch, isMapPresentational, publicMapSettings.radiusProximity]);

  const handleExportProximityLocations = useCallback(() => {
    if (!activeProximity) {
      return;
    }

    openExportProximityDataModal({
      selectedProximityIds: [activeProximity.id],
      spreadsheetRowId: activeMetrics.spreadsheetRowId,
      showSelectProximitiesSection: false,
    });
  }, [activeProximity, openExportProximityDataModal, activeMetrics.spreadsheetRowId]);

  const onExportLocationsClick = isExportAllowed ? handleExportProximityLocations : undefined;

  const handleExportContainedBoundaries = useCallback(() => {
    if (!activeProximity) {
      return;
    }

    openExportContainedBoundaryDataModal({
      filter: {
        type: 'proximity',
        proximity: activeProximity,
        spreadsheetRowId: activeMetrics.spreadsheetRowId,
      },
    });
  }, [activeProximity, openExportContainedBoundaryDataModal, activeMetrics.spreadsheetRowId]);

  const onExportContainedBoundariesClick = !isMapPresentational ? handleExportContainedBoundaries : undefined;

  const onDirectionsClick = useMemo(() => !isMapPresentational || publicMapSettings.routingDirections ? () => {
    areaMarkers.forEach(marker => {
      const center = latLngLookup.getRow(marker);

      if (!center) {
        return;
      }

      dispatch(frontendStateDirectionsAddMarkerWaypoint({
        address: t('Marker {{rowId}}', { rowId: marker.rowId }),
        latLng: center,
        markerId: marker,
        startsFromUserLocation: false,
      }));
    });
  } : undefined, [areaMarkers, dispatch, isMapPresentational, latLngLookup, publicMapSettings.routingDirections, t]);

  useEffect(() => {
    if (!activeProximity) {
      return;
    }

    const { circles, multiPolygons } = createProximityFilterArgumentsFromProximity(
      activeProximity,
      latLngLookup,
      activeMetrics.spreadsheetRowId ?? null,
    );

    const cancelToken = getAreaData({
      useMainFilters: true,
      spreadsheetIds,
      circles,
      polygons: multiPolygons,
    });

    return () => {
      cancelToken();
    };
  }, [activeProximity, latLngLookup, activeMetrics.spreadsheetRowId, getAreaData, spreadsheetIds]);

  const activeProximityStyles = useMemo(() => {
    if (!activeProximity) {
      return undefined;
    }

    let activeProximityStyles = activeProximity.styles;

    if (isGroupRadius(activeProximity)) {
      const groupRadiusDetails = getGroupRadiusProximityDetails(activeProximity, activeMetrics.spreadsheetRowId);
      activeProximityStyles = groupRadiusDetails.styles;
    }

    const { color, fillOpacity, borderWidth } = computeProximityStyles(activeProximityStyles, false, true);

    return {
      fillColor: color,
      fillOpacity,
      borderWidth,
      borderColor: { value: '', animation: { from: borderColor, to: borderColorAlternative } },
    };
  }, [activeProximity, activeMetrics.spreadsheetRowId]);

  const indicatorStyles = useSidebarIndicatorStyles(activeProximityStyles);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: onDeleteProximityClick },
    targetKeys: ERASURE_KEYS,
  }), [onDeleteProximityClick]));

  if (!activeProximity) {
    closeActiveProximity();
    return null;
  }

  let proximityName = activeProximity.name;
  let additionalInfo = '';
  let additionalBackgroundColor = activeProximity.styles.color;

  if (isIndividualRadius(activeProximity)) {
    additionalInfo = formatDistance(activeProximity.data.radius, 0, activeProximity.data.unit);
  }
  if (isDriveTimePolygon(activeProximity)) {

    const driveTimeLabels = getDriveTimePolygonLabels(activeProximity.data.hours, activeProximity.data.minutes, t);
    additionalInfo = [driveTimeLabels.hours, driveTimeLabels.minutes]
      .filter(time => time !== '')
      .join(' ');

  }

  if (isGroupRadius(activeProximity)) {
    const groupRadiusDetails = getGroupRadiusProximityDetails(activeProximity, activeMetrics.spreadsheetRowId);
    additionalInfo = formatDistance(groupRadiusDetails.radius, 0, groupRadiusDetails.unit);

    proximityName = groupRadiusDetails.name;
    if (groupRadiusDetails.name === activeProximity.data.data.group) {
      proximityName = `${t('Group').toUpperCase()}: ${proximityName}`;
    }

    additionalBackgroundColor = groupRadiusDetails.styles.color;
  }

  return (
    <ProximityDetailsPanelComponent
      additionalInfo={additionalInfo}
      additionalInfoBackgroundColor={additionalBackgroundColor}
      arrowProps={arrowProps}
      isLoading={isLoading || isMarkerCountLoading}
      isProximityVisible={isActiveProximityVisible}
      locationDescription={{
        header: proximityName,
        description: [t('Total Locations: {{locationsCount}}', { locationsCount: areaMarkers.length })],
      }}
      locationIndex={indexOfCurrentLocation}
      metrics={metricsResults}
      menuButtonsCallbacks={{
        onExportLocationsClick,
        onExportContainedBoundariesClick,
        onDirectionsClick,
        onDeleteProximityClick,
        onProximityEditClick,
        onProximityHideClick,
        onProximityShowClick,
      }}
      onCustomizeMetricsClick={onEditMetricsClick}
      onClose={closeActiveProximity}
      totalLocations={locationsOrder.length}
      markersCount={areaMarkers.length}
      isDriveTimePolygon={isDriveTimePolygon(activeProximity)}
      indicatorStyles={indicatorStyles}
    />
  );
};
