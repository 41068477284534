import {
  type CombinedRowId, type SpreadsheetId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { type AppState } from '../../app.store';
import { type MapSettingsRoute } from './mapSettingsDirections.state';

const mapSettingsDirectionsShouldBeginAtUserLocationSelector = (state: AppState) => state.map.mapSettings.data.directions.shouldBeginAtUserLocation;
export const useMapSettingsDirectionsShouldBeginAtUserLocationSelector = () => useSelector(
  mapSettingsDirectionsShouldBeginAtUserLocationSelector
);

const mapSettingsDirectionsRoutesSelector = (state: AppState) => state.map.mapSettings.data.directions.routes;
export const useMapSettingsDirectionsRoutesSelector = () => useSelector(mapSettingsDirectionsRoutesSelector);

export const mapSettingsDirectionsSelectedCollapsingSelector = (state: AppState) => state.map.mapSettings.data.directions.selectedCollapsing;
export const useMapSettingsDirectionsSelectedCollapsingSelector = () => useSelector(mapSettingsDirectionsSelectedCollapsingSelector);

type MarkerWaypointInfo = Readonly<{ route: MapSettingsRoute; waypointIndex: number; markerId: SpreadsheetRowId }>;

export type SpreadsheetRowIdsToWaypoints = Map<SpreadsheetId, Map<CombinedRowId, Readonly<MarkerWaypointInfo>>>;

export const selectMapSettingsDirectionsRoutesSpreadsheetRowsSelector = createStateSelector(
  [mapSettingsDirectionsRoutesSelector],
  (allRoutes: ReadonlyArray<MapSettingsRoute>) => {
    const spreadsheetRowIdsToWaypoints: SpreadsheetRowIdsToWaypoints = new Map();

    allRoutes.forEach(route => {
      route.waypoints.forEach((routeWaypoint, waypointIndex) => {
        if (routeWaypoint.markerId) {
          const mapSpreadsheetEntry = spreadsheetRowIdsToWaypoints.get(routeWaypoint.markerId.spreadsheetId) ?? new Map();
          mapSpreadsheetEntry.set(routeWaypoint.markerId.rowId, { route, waypointIndex, markerId: routeWaypoint.markerId });
          spreadsheetRowIdsToWaypoints.set(routeWaypoint.markerId.spreadsheetId, mapSpreadsheetEntry);
        }
      });
    });

    return spreadsheetRowIdsToWaypoints;
  });

export const useMapSettingsDirectionsRoutesSpreadsheetRowsSelector = () => useSelector(selectMapSettingsDirectionsRoutesSpreadsheetRowsSelector);

export const useMapMarkerWaypointDataSelector = (markerId?: SpreadsheetRowId): MarkerWaypointInfo | null => {
  const markerWaypointsInSpreadsheets = useMapSettingsDirectionsRoutesSpreadsheetRowsSelector();

  if (!markerId) {
    return null;
  }

  return markerWaypointsInSpreadsheets.get(markerId.spreadsheetId)?.get(markerId.rowId) ?? null;
};
