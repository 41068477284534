import {
  type UndoItemType,
  type UndoRouteData,
} from '~/_shared/types/undo/undoItem';
import { type UNDO_MODEL_VERSIONS } from '../undo.constants';

export type AllRouteVersions = typeof UNDO_MODEL_VERSIONS[UndoItemType.REMOVE_ROUTE][number];

const undoRouteDataFromAnyVersionToCurrentVersionConverters: {
  [key in AllRouteVersions]: (anyVersionUndoData: any) => UndoRouteData
} = {
  ['1']: (undoData) => undoData as UndoRouteData,
};

export const convertUndoRouteDataAnyVersionToCurrent = (
  anyVersionUndoData: any,
  version: AllRouteVersions,
): UndoRouteData => (
  undoRouteDataFromAnyVersionToCurrentVersionConverters[version](anyVersionUndoData)
);
