import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { ColumnRole } from '../../types/columnRole.enum';
import {
  type AddressPartComponentNames, type GeoLocation,
} from './geolocation';

export const useGetMarkerDataFromGeoResponse = () => {
  const mapId = useMapIdSelector();
  const matchupData = useMatchupDataSelector();
  const userId = useUserIdSelector();

  if (userId === null || mapId === null) {
    return;
  }

  return (response: GeoLocation, spreadsheetId: number) => {
    const matchupCategories = matchupData?.[spreadsheetId]?.data?.categories;
    const addressComponent = response.addressComponents;

    if (!matchupCategories || !addressComponent) {
      return;
    }

    let columns: { [columnId: string]: string } = {};
    Object.keys(addressComponent)
      .forEach((name: keyof AddressPartComponentNames) => {
        if (name === 'County') {
          //TODO:: County not supported yet. (in ColumnRole)'
        }

        else if (name !== 'StreetNumber' && name !== 'Street') {
          const columnRoleName = ColumnRole[name];

          const addressComponentValue = addressComponent[name];
          const columnId = matchupCategories[columnRoleName].match;
          if (columnId && addressComponentValue) {
            columns = {
              ...columns,
              [columnId]: addressComponentValue,
            };
          }
        }
      });

    const addressColumnId = matchupCategories.address.match;
    if (addressColumnId) {
      const streetName = addressComponent.Street || '';
      const streetNumber = addressComponent.StreetNumber || '';

      const addressValue = streetName + (streetNumber !== '' ? ' ' + streetNumber : '');
      if (addressValue) {
        columns = {
          ...columns,
          [addressColumnId]: addressValue,
        };
      }
    }

    const { lat, lng } = response.latLng;
    const latitudeColumnId = matchupCategories.latitude.match;
    if (latitudeColumnId && lat) {
      columns = {
        ...columns,
        [latitudeColumnId]: lat + '',
      };
    }

    const longitudeColumnId = matchupCategories.longitude.match;
    if (longitudeColumnId && lng) {
      columns = {
        ...columns,
        [longitudeColumnId]: lng + '',
      };
    }

    return columns;
  };
};
