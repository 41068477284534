import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  type DistanceMatrixFastRequest, type DistanceMatrixResultItem, type DistanceMatrixSearchItem, getDistanceMatrixFast,
} from '~/_shared/repositories/travelTime.repository';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { userSetDistanceMatrixUsage } from '~/store/userData/userData.actionCreators';
import type { LatLng } from '../types/latLng';
import { areLatLngEqual } from '../utils/latLng/latLng.helpers';
import { notNull } from '../utils/typeGuards';

export const DISTANCE_MATRIX_MAX_SEARCH_ITEMS = 10;
export const DISTANCE_MATRIX_MAX_TO_LOCATIONS = 10_000;

const ZERO_DISTANCE = 'Z';

export type DistanceMatrixSearchRequest = Readonly<{
  searchItems: ReadonlyArray<DistanceMatrixSearchItem & Readonly<{ toLookup: Readonly<OriginalToToRequestPositionLookup> }>>;
  searchItemsOverLimit: string[];
  searchItemsRequest: DistanceMatrixFastRequest;
}>;

type OriginalToToRequestPositionLookup = Array<typeof ZERO_DISTANCE | number>;

export const useDistanceMatrixService = () => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();

  const getDistanceMatrix = useCallback((request: DistanceMatrixSearchRequest): Promise<ReadonlyArray<DistanceMatrixResultItem>> => {
    if (clientId === null) {
      return Promise.reject();
    }

    if (request.searchItemsRequest.searches.length === 0) {
      return Promise.resolve(mergeDistanceMatrixResultsWithSearchItems(request.searchItems, []));
    }

    return getDistanceMatrixFast(clientId, request.searchItemsRequest)
      .then(response => {
        dispatch(userSetDistanceMatrixUsage(response.distance_matrix_requests_usage));
        return mergeDistanceMatrixResultsWithSearchItems(request.searchItems, response.results);
      });
  }, [clientId, dispatch]);

  return {
    prepareDistanceMatrixSearchRequest,
    getDistanceMatrixFast: getDistanceMatrix,
  };
};

export const prepareDistanceMatrixSearchRequest = (searchItems: ReadonlyArray<DistanceMatrixSearchItem>): DistanceMatrixSearchRequest =>
  searchItems.reduce((accum, searchItem) => {
    const toLookup: OriginalToToRequestPositionLookup = [];
    const toAll: LatLng[] = [];

    searchItem.to.forEach((toItem) => {
      if (areLatLngEqual(toItem, searchItem.from)) {
        toLookup.push(ZERO_DISTANCE);
      }
      else {
        toLookup.push(toAll.length);
        toAll.push(toItem);
      }
    });

    const toInLimit = toAll.slice(0, DISTANCE_MATRIX_MAX_TO_LOCATIONS);

    if (toAll.length > 0) {
      if (toAll.length > DISTANCE_MATRIX_MAX_TO_LOCATIONS) {
        accum.searchItemsOverLimit.push(searchItem.id);
      }
      accum.searchItemsRequest.searches.push({
        id: searchItem.id,
        from: searchItem.from,
        to: toInLimit,
      });
    }

    accum.searchItems.push({
      ...searchItem,
      toLookup: toLookup.slice(0, DISTANCE_MATRIX_MAX_TO_LOCATIONS),
    });

    return accum;
  }, {
    searchItems: [] as Array<DistanceMatrixSearchItem & Readonly<{ toLookup: Readonly<OriginalToToRequestPositionLookup> }>>,
    searchItemsOverLimit: [] as string[],
    searchItemsRequest: { searches: [] as DistanceMatrixSearchItem[] },
  });

export const mergeDistanceMatrixResultsWithSearchItems = (
  searchItems: DistanceMatrixSearchRequest['searchItems'],
  results: ReadonlyArray<DistanceMatrixResultItem>,
) =>
  searchItems.map(searchItem => {
    const responseItem = results.find(result => searchItem.id === result.id);
    if (responseItem) {
      return {
        ...searchItem,
        to: searchItem.toLookup.map(toLookup => {
          if (toLookup === ZERO_DISTANCE) {
            return { ...searchItem.from, distance: 0, travel_time: 0 };
          }
          return responseItem.to[toLookup] && !responseItem.to[toLookup].error ? responseItem.to[toLookup] : {
            ...searchItem.from,
            distance: null,
            travel_time: null,
          };
        }),
      };
    }
    return null;
  }).filter(notNull);
