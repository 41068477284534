export enum BaseMapThemeName {
  Aubergine = 0,
  Courgette,
  Dark,
  DeepWater,
  Ice,
  Mint,
  Night,
  Retro,
  Silver,
  Standard,
  Storm,
  Vintage,
}
