import { createAction } from '@reduxjs/toolkit';
import type {
  V4MapListSuccessResponse, V4MapMigrateFailResponse, V4MapMigrateSuccessResponse,
} from '~/store/mapMigration/types/mapMigrationResponse.types';
import type {
  V4MapMigrationErrorMessage, V4MapMigrationProgressMessage,
} from '~/store/mapMigration/types/v4MapMigrationMessage.type';

export const v4MapFetchSuccess = createAction('MAP_MIGRATION_V4_MAPS_FETCH_SUCCESS',
  (response: V4MapListSuccessResponse) => ({
    payload: {
      response,
    },
  }),
);

export const v4MapFetchFail = createAction('MAP_MIGRATION_V4_MAPS_GET_FAIL',
  (response: any) => ({
    payload: {
      response,
    },
  }),
);

export const v4MapMigrationInit = createAction('MAP_MIGRATION_V4_MIGRATE_MAPS',
  (clientId: number, v4MapIdsToMigrate: ReadonlyArray<number>) => ({
    payload: {
      clientId,
      v4MapIdsToMigrate,
    },
  }),
);

export const v4MapMigrationDismissed = createAction('MAP_MIGRATION_V4_DISMISS_MIGRATION',
  (clientId: number, queueId: string) => ({
    payload: {
      clientId,
      queueId,
    },
  }),
);

export const v4MapInitiateMigrationSuccess = createAction('MAP_MIGRATION_V4_MIGRATE_MAPS_INITIATED',
  (responseData: V4MapMigrateSuccessResponse) => ({
    payload: {
      responseData,
    },
  }),
);

export const v4MapInitiateMigrationFail = createAction('MAP_MIGRATION_V4_MIGRATE_MAPS_INIT_FAILED',
  (responseData: V4MapMigrateFailResponse) => ({
    payload: {
      responseData,
    },
  }),
);

export const v4MapCompleteMigrationInit = createAction('MAP_MIGRATION_V4_MIGRATE_ALL_MAPS',
  (clientId: number) => ({
    payload: {
      clientId,
    },
  }),
);

export const v4MapInitiateCompleteMigrationSuccess = createAction('MAP_MIGRATION_V4_MIGRATE_ALL_MAPS_INITIATED',
  (responseData: V4MapMigrateSuccessResponse) => ({
    payload: {
      responseData,
    },
  }),
);

export const v4MapInitiateCompleteMigrationFail = createAction('MAP_MIGRATION_V4_MIGRATE_ALL_MAPS_INIT_FAILED',
  (responseData: V4MapMigrateFailResponse) => ({
    payload: {
      responseData,
    },
  }),
);

export const v4MapMigrationProgressUpdate = createAction('MAP_MIGRATION_V4_PROGRESS_UPDATE',
  (message: V4MapMigrationProgressMessage) => ({
    payload: {
      message,
    },
  }),
);

export const v4MapMigrationErrorMessage = createAction('MAP_MIGRATION_V4_ERROR_MESSAGE',
  (message?: V4MapMigrationErrorMessage) => ({
    payload: {
      message,
    },
  }),
);
