import { copy } from '~/_shared/utils/collections/collections';
import {
  type RemoveMapAction, type RemoveMapRequest, type RemoveMapResolution,
} from '~/map/map.repository';
import { Resolution } from '../baseMapDeleteDependenciesResolve.helper';

export type HandleBaseMapDeletionModalHelper = {
  resolutionForMapId: ReadonlyMap<number, Resolution>;
};

export const createSelectedResolution = (map: ReadonlyMap<number, Resolution>): HandleBaseMapDeletionModalHelper => ({
  resolutionForMapId: map,
});

export const getResolutionByMapId = (selectedResolutions: HandleBaseMapDeletionModalHelper, mapId: number): Resolution => {
  const resolution = selectedResolutions.resolutionForMapId.get(mapId);
  if (!resolution) {
    throw Error(`Resolution for mapId: ${mapId} not found.`);
  }

  return resolution;
};

export const setResolution = (
  selectedResolutions: HandleBaseMapDeletionModalHelper, mapId: number, resolution: Resolution,
): HandleBaseMapDeletionModalHelper => {
  return createSelectedResolution(copy.andReplace(selectedResolutions.resolutionForMapId, mapId, resolution));
};

export const allSelected = (selectedResolutions: HandleBaseMapDeletionModalHelper): boolean => {
  const resolutions = Array.from(selectedResolutions.resolutionForMapId.values());
  return resolutions.every(restriction => restriction !== Resolution.None);
};

const getMapIdsWithResolutionSelected = (
  selectedResolutions: HandleBaseMapDeletionModalHelper, resolution: Resolution,
): number[] => {
  return baseMapDeletionResolutionsToArray(selectedResolutions)
    .filter(([_, v]) => v === resolution)
    .map(([k, _]) => k);
};

export const getDeletedMapIds = (selectedResolutions: HandleBaseMapDeletionModalHelper): number[] => {
  return getMapIdsWithResolutionSelected(selectedResolutions, Resolution.Delete);
};

export const getDisconnectedMapIds = (selectedResolutions: HandleBaseMapDeletionModalHelper): number[] => {
  return getMapIdsWithResolutionSelected(selectedResolutions, Resolution.Disconnect);
};

const calculateSelectedResolutionsCount = (
  selectedResolutions: HandleBaseMapDeletionModalHelper, resolution: Resolution,
): number => {
  const resolutions = Array.from(selectedResolutions.resolutionForMapId.values());
  return resolutions.filter(restriction => restriction === resolution).length;
};

export const calculateDeleteCount = (selectedResolutions: HandleBaseMapDeletionModalHelper): number => {
  return calculateSelectedResolutionsCount(selectedResolutions, Resolution.Delete);
};

export const anyDelete = (selectedResolutions: HandleBaseMapDeletionModalHelper): boolean => {
  return calculateDeleteCount(selectedResolutions) > 0;
};

export const calculateDisconnectCount = (selectedResolutions: HandleBaseMapDeletionModalHelper): number => {
  return calculateSelectedResolutionsCount(selectedResolutions, Resolution.Disconnect);
};

export const anyDisconnect = (selectedResolutions: HandleBaseMapDeletionModalHelper): boolean => {
  return calculateDisconnectCount(selectedResolutions) > 0;
};

export const createDefaultSelectedResolutions = (layeredMapIds: number[], defaultResolution: Resolution): HandleBaseMapDeletionModalHelper => {
  const mapOfSelectedResolutions = new Map(layeredMapIds.map(id => [id, defaultResolution]));

  return createSelectedResolution(mapOfSelectedResolutions);
};

export const baseMapDeletionResolutionsToArray = (selectedResolutions: HandleBaseMapDeletionModalHelper) => {
  return [...selectedResolutions.resolutionForMapId.entries()];
};

export const convertResolutionToServerModel = (resolution: Resolution): RemoveMapResolution => {
  switch (resolution) {
    case Resolution.Disconnect:
      return 'disconnect';
    case Resolution.Delete:
      return 'delete';
    case Resolution.RemoveLayer:
      return 'remove_layer';
    default:
      throw Error(`Unknown resolution ${resolution}`);
  }
};

export const createResolutionAction = (layerMapId: number, action: RemoveMapResolution): RemoveMapAction => ({
  layered_id: layerMapId,
  action,
});

export const createRemoveMapRequestForConflictingMap = (
  mapId: number, actions: RemoveMapAction[], allowCopiesForDependantUsers: boolean
): RemoveMapRequest => ({
  maps: [{
    id: mapId,
    actions,
    allow_copies_for_dependent_users: allowCopiesForDependantUsers,
  }],
});
