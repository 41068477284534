import { useOnMount } from '~/_shared/hooks/useOnMount';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { getBaseUrl } from '~/_shared/utils/url/url.helpers';
import { webglPaths } from '~/map/map/webgl/useGetWebGLOverlayInstance.hook';
import type { ModalProps } from '~/modal/modalType.enum';
import { AboutUsModalComponent } from './aboutUsModal.component';

export const AboutUsModalContainer = (props: ModalProps) => {
  const { isLoading, data: licenses, invokeAjax: invokeFetchLicenses } = useAjaxCall(fetchLicenses);

  useOnMount(() => {
    invokeFetchLicenses();
  });

  return (
    <AboutUsModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={isLoading || !licenses}
      licenses={licenses}
    />
  );
};

const fetchLicenses = async () => {
  try {
    const [mainResponse, webGlResponse] = await Promise.all([
      fetch(`${getBaseUrl()}/js/main/main.LICENSE.txt`),
      fetch(webglPaths.license),
    ]);

    if (!mainResponse.ok || !webGlResponse.ok) {
      throw new Error(`Failed to fetch licenses. Status: main = ${mainResponse.status} / webGl = ${webGlResponse.status}`);
    }

    const [mainLicense, webGlLicense] = await Promise.all([
      mainResponse.text(),
      webGlResponse.text(),
    ]);

    return `${mainLicense}\n\n${webGlLicense}`;
  }
  catch (error) {
    console.error('Failed to fetch licenses', error);
    throw error;
  }
};
