import {
  useEffect, useMemo,
} from 'react';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { useMapContext } from './mapContext';
import { MapStyleManager } from './mapStyleManager';

export const useMapStyles = () => {
  const { map } = useMapContext();
  const mapStyles = useSelector(state => state.map.mapSettings.data.mapStyles);

  const manager = useMemo(() => {
    if (!map) {
      return;
    }

    return new MapStyleManager(map);
  }, [map]);

  // Reacting to styles change
  useEffect(() => {
    if (!manager || !map) {
      return;
    }
    manager.updateStyles(mapStyles);
  }, [map, manager, mapStyles]);

  useEffect(() => {
    if (!manager) {
      return;
    }
    return () => manager.destroy();
  }, [manager]);
};
