import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from '~/_shared/utils/debounce';
import { type BoundaryIdentifier } from '~/store/boundaries/boundaryIdentifier.type';
import {
  boundarySelectAdd,
  boundarySelectHoverBoundary,
  boundarySelectRemove,
  boundarySelectStopBoundaryHover,
} from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.actionCreators';
import { useBoundarySelectActiveSelector } from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { BoundarySelectType } from '../../../../store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.state';
import { type MapBoundaryManager } from '../mapBoundary.manager';

export const useSelectionMapBoundaryMouseEvents = (manager: MapBoundaryManager | undefined) => {
  const dispatch = useDispatch();

  const selection = useBoundarySelectActiveSelector();
  const selectionRef = useRef(selection);

  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);

  const onClick = useCallback((boundaryIdentifier: BoundaryIdentifier) => {
    if (!selectionRef.current?.isActive) {
      return;
    }

    if (selectionRef.current.selectType === BoundarySelectType.Click) {
      const isBoundarySelected = selectionRef.current.selectedBoundaryIds.has(boundaryIdentifier.boundaryId);
      if (isBoundarySelected) {
        dispatch(boundarySelectRemove(boundaryIdentifier.boundaryId));
      }
      else {
        dispatch(boundarySelectAdd([boundaryIdentifier.boundaryId]));
      }
    }

    // when in hover idle, clicking the boundary to start the "hover active" selection
    // require selecting the clicked boundary as well, since mouseover event won't get called
    if (selectionRef.current.selectType === BoundarySelectType.HoverIdle) {
      dispatch(boundarySelectAdd([boundaryIdentifier.boundaryId]));
    }
  }, [dispatch]);

  const onMouseOver = useCallback((boundaryIdentifier: BoundaryIdentifier) => {
    if (!selectionRef.current?.isActive) {
      return;
    }

    stopHoverDebounced.cancel();

    const selectType = selectionRef.current?.selectType;

    if (selectType === BoundarySelectType.HoverActive) {
      dispatch(boundarySelectAdd([boundaryIdentifier.boundaryId]));
    }

    if (selectType === BoundarySelectType.Click) {
      dispatch(boundarySelectHoverBoundary(boundaryIdentifier.boundaryId));
    }
  }, [dispatch]);

  const onMouseOut = useCallback((_boundaryIdentifier: BoundaryIdentifier) => {
    stopHoverDebounced(() => dispatch(boundarySelectStopBoundaryHover()));
  }, [dispatch]);

  const eventCallbacks = useMemo(() => ({
    onClick,
    onMouseOver,
    onMouseOut,
  }), [onClick, onMouseOver, onMouseOut]);

  useEffect(() => {
    if (!manager) {
      return;
    }

    manager.addBoundaryEventListener('click', eventCallbacks.onClick);
    manager.addBoundaryEventListener('mouseover', eventCallbacks.onMouseOver);
    manager.addBoundaryEventListener('mouseout', eventCallbacks.onMouseOut);

    return () => {
      manager.removeBoundaryEventListener('click', eventCallbacks.onClick);
      manager.removeBoundaryEventListener('mouseover', eventCallbacks.onMouseOver);
      manager.removeBoundaryEventListener('mouseout', eventCallbacks.onMouseOut);
    };
  }, [manager, eventCallbacks]);
};

const stopHoverDebounced = debounce((action: () => void) => action(), 100);
