import { useMemo } from 'react';
import { type WebglLayers } from '~/map/map/webgl/useWebGL';
import { MapMarkerManager } from './mapMarkerManager';

export const useMapMarkerManager = (webglLayers: WebglLayers | undefined) => {
  return useMemo(() => {
    if (!webglLayers) {
      return undefined;
    }

    return new MapMarkerManager(webglLayers);
  }, [webglLayers]);
};
