import { css } from '@emotion/react';
import { type FC } from 'react';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { type TeamMemberLimitsData } from '../teamManagement.repository';
import { type InvitationAndMemberData } from '../teamManagementModal.container';
import { MembersTableItemContainer } from './membersTableItem.container';

const tableContainerStyle = css({
  paddingBottom: '30px',
});

const sectionHeaderStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  background: theme.modalColors.contentBackground,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  borderTop: `1px solid ${theme.borderColors.primary}`,
  cursor: 'default',
  display: 'flex',
  padding: '12px 22px',
});

const sectionTitleStyle = ({ theme }: ThemeProps) => css({
  color: theme.modalColors.contentColor,
  fontSize: '18px',
  fontWeight: 'bold',
});

const memberLimitInfoTextStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.secondary,
  marginLeft: '10px',
  fontSize: '12px',
});

const teamMembersSectionContentStyle = ({ theme }: ThemeProps) => css({
  background: theme.modalColors.contentBackground,
  position: 'relative',
});

const loaderStyle = ({ theme }: ThemeProps) => css({
  background: theme.loadingOverlay.backgroundColor,
});

type MembersTableComponentProps = {
  isLoading?: boolean;
  data: InvitationAndMemberData[];
  limits: TeamMemberLimitsData;
};

export const MembersTableComponent: FC<MembersTableComponentProps> = ({
  isLoading,
  data,
  limits,
}) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div css={tableContainerStyle}>
      <div css={sectionHeaderStyle({ theme })}>
        <span css={sectionTitleStyle({ theme })}>{t('Team Members')}</span>
        <span css={memberLimitInfoTextStyle({ theme })}>
          {t('User Limit: {{limitsUsed}} of {{limitsTotal}}', {
            limitsUsed: limits.used,
            limitsTotal: limits.limit,
          })}
        </span>
      </div>
      <div css={teamMembersSectionContentStyle({ theme })}>
        {isLoading && (
          <OverlayLoaderComponent
            css={loaderStyle({ theme })}
            loaderSize={45}
          />
        )}
        {data.map((member, idx) => (
          <MembersTableItemContainer
            key={idx}
            index={idx}
            member={member}
            limits={limits}
          />
        ))}
      </div>
    </div>
  );
};
