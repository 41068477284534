import { css } from '@emotion/react';
import {
  useEffect,
  useState,
} from 'react';
import { useTheme } from '../../themes/theme.hooks';
import { type ThemeProps } from '../../types/themeProps';
import {
  type TranslationFnc,
  useTranslation,
} from '../../utils/hooks';

type PasswordStrengthIndicatorProps = Readonly<{
  password: string;
}>;

type PasswordStrength = 0 | 1 | 2 | 3 | 4;

const createPasswordStrengthName = (t: TranslationFnc, strength: PasswordStrength) => {
  switch (strength) {
    case 0:
    case 1:
      return t('Weak');
    case 2:
      return t('Poor');
    case 3:
      return t('Good');
    case 4:
      return t('Strong');
    default:
      throw new Error(`Unknown password strength '${strength}'.`);
  }
};

const computePasswordStrength = (password: string): Promise<PasswordStrength> =>
  import(/* webpackChunkName: "zxcvbn-password-strength-estimator" */'zxcvbn')
    .then(zxcvbn => zxcvbn.default(password).score);

const rootStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const segmentRootStyle = css({
  display: 'flex',
  marginTop: 5,
  width: '100%',
  flexShrink: 0,
});

const segmentStyle = css({
  width: '25%',
  height: 5,
  backgroundColor: '#e4eef6',
  marginRight: 1,
});

const firstSegmentStyle = css({
  backgroundColor: '#fa2a31',
});

const secondSegmentStyle = css({
  backgroundColor: '#fb9430',
});

const thirdSegmentStyle = css({
  backgroundColor: '#fce141',
});

const fourthSegmentStyle = css({
  backgroundColor: '#cdfc42',
});

const passwordLabelStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.tertiary,
});

const strengthNameStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.contrast,
});

export const PasswordStrengthIndicatorComponent: React.FC<PasswordStrengthIndicatorProps> = props => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [strength, setStrength] = useState<PasswordStrength>(0);

  useEffect(() => {
    computePasswordStrength(props.password).then(setStrength);
  }, [props.password]);

  return (
    <div css={rootStyle}>
      <span>
        <span css={passwordLabelStyle({ theme })}>{t('Password')}: </span>
        <b css={strengthNameStyle({ theme })}>{createPasswordStrengthName(t, strength)}</b>
      </span>
      <div css={segmentRootStyle}>
        <div css={css(segmentStyle, strength >= 1 && firstSegmentStyle)} />
        <div css={css(segmentStyle, strength >= 2 && secondSegmentStyle)} />
        <div css={css(segmentStyle, strength >= 3 && thirdSegmentStyle)} />
        <div css={css(segmentStyle, strength >= 4 && fourthSegmentStyle)} />
      </div>
    </div>
  );
};
