import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocationFromGeolocation } from '~/_shared/utils/hooks/useLocationFromGeolocation';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import {
  rightPanelSetIsAddMarkerLoading,
  stopAddLocationTool,
} from '~/store/frontendState/mapTools/addLocation/addLocation.actionCreators';

export type useAddLocationByMapClickProp = {
  map?: google.maps.Map;
};

export const useAddLocationByMapClick = ({ map }: useAddLocationByMapClickProp) => {
  const { addLocationFromLatLng } = useLocationFromGeolocation();
  const addLocationActive = useSelector(state => state.frontendState.mapTools.addLocation.active);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!addLocationActive || !map) {
      return;
    }
    const listener = google.maps.event.addListener(map, 'click', (event: google.maps.MapMouseEvent) => {
      if (!event.latLng) {
        return;
      }

      listener?.remove();
      dispatch(stopAddLocationTool());
      addLocationFromLatLng(
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
        (newValue) => {
          dispatch(rightPanelSetIsAddMarkerLoading(newValue));
        },
      );
    });

    return () => {
      listener?.remove();
    };

  }, [dispatch, addLocationFromLatLng, addLocationActive, map]);

};
