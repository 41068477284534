export const PROXIMITY_TOOL_SET_APPLY_TO = 'PROXIMITY_TOOL/SET_APPLY_TO';
export const PROXIMITY_TOOL_SET_TYPE = 'PROXIMITY_TOOL/SET_TYPE';
export const PROXIMITY_TOOL_SET_UNIT = 'PROXIMITY_TOOL/SET_UNIT';
export const PROXIMITY_TOOL_SET_TRAVEL_TIME_HOURS = 'PROXIMITY_TOOL/SET_TRAVEL_TIME_HOURS';
export const PROXIMITY_TOOL_SET_TRAVEL_TIME_MINUTES = 'PROXIMITY_TOOL/SET_TRAVEL_TIME_MINUTES';
export const PROXIMITY_TOOL_SET_PROXIMITY_WITHIN = 'PROXIMITY_TOOL/SET_PROXIMITY_WITHIN';
export const PROXIMITY_TOOL_SET_SELECTED_GROUP = 'PROXIMITY_TOOL/SET_SELECTED_GROUP';
export const PROXIMITY_TOOL_SET_SELECTED_GROUP_COLUMN = 'PROXIMITY_TOOL/SET_SELECTED_GROUP_COLUMN';
export const PROXIMITY_TOOL_SET_PROXIMITY_COLOR = 'PROXIMITY_TOOL/SET_PROXIMITY_COLOR';
export const PROXIMITY_TOOL_SET_BORDER_COLOR = 'PROXIMITY_TOOL/SET_BORDER_COLOR';
export const PROXIMITY_TOOL_SET_BORDER_LINE_WIDTH = 'PROXIMITY_TOOL/SET_BORDER_LINE_WIDTH';
export const PROXIMITY_TOOL_SET_STARTING_LOCATION = 'PROXIMITY_TOOL/SET_STARTING_LOCATION';
export const PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED = 'PROXIMITY_TOOL/SET_PROXIMITY_HOVER_STARTED';
export const PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED = 'PROXIMITY_TOOL/SET_PROXIMITY_HOVER_ENDED';

export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_STARTED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DRAG_ENDED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVERED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_CENTER_HOVER_ENDED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_ENDED';
export const PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED = 'PROXIMITY_TOOL_INDIVIDUAL_RADIUS_HOVER_IN_DND_STARTED';
