import {
  useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type MapPrivacyLevel } from '~/_shared/types/map';
import { type Pagination } from '~/_shared/types/pagination/pagination';
import { createPaginationFromResponse } from '~/_shared/types/pagination/pagination.factory';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import { createCancelToken } from '~/_shared/utils/api/api.helpers';
import {
  type ApiError, isCancelError,
} from '~/_shared/utils/api/apiError.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { usePagination } from '~/_shared/utils/hooks/usePagination';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useMapListETagSelector } from '~/store/frontendState/mapListing/mapListing.selectors';
import { geocodingSetProgress } from '~/store/geocoding/geocoding.actionCreators';
import { type PerSpreadsheetGeocodingUpdates } from '~/store/geocoding/geocoding.state';
import { useMapInfoDataSelector } from '~/store/mapInfo/mapInfo.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useUserIdSelector } from '~/store/userData/userData.selectors';
import { createMapListingItemFromMapResponse } from '../map.factory';
import {
  getMapList, MapListFilter, type MapListRequest,
} from '../map.repository';
import {
  convertMapInfoToMapListingItem, type MapListingItem,
} from './item/mapListingItem';
import { MapListSortType } from './mapListing.helpers';

export type ChangePrivacyLevelProps = {
  mapId: number;
  newPrivacyLevel: MapPrivacyLevel;
  parentMapId?: number;
};

type UseMapListingResult = {
  changePrivacyLevel: (props: ChangePrivacyLevelProps) => void;
  errorMessage: string;
  forceRefresh: () => void;
  isLoading: boolean;
  mapListingItems: ReadonlyArray<MapListingItem>;
  allMapListingItems: ReadonlyArray<MapListingItem>;
  pagination: Pagination | null;
  onPageChange: (page: number) => void;
  onToggleMapIdsChange: (ids: ReadonlyArray<number>) => void;
  toggledMapIds: ReadonlyArray<number>;
};

export const useMapListingItems = ({ isActive, sortType, filterType, includeCurrentMapOnTop, searchQuery = '' }: {
  readonly filterType?: MapListFilter;
  readonly includeCurrentMapOnTop?: boolean;
  readonly isActive: boolean;
  readonly sortType: MapListSortType;
  readonly searchQuery: string;
}): UseMapListingResult => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const clientId = useClientIdSelector();
  const userId = useUserIdSelector();
  const mapListingETag = useMapListETagSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const mapInfo = useMapInfoDataSelector();

  const [toggledMapIds, setToggledMapIds] = useState<ReadonlyArray<number>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [mapListingItems, setMapListingItems] = useState<MapListingItem[]>([]);
  const [allMapListingItems, setAllMapListingItems] = useState<MapListingItem[]>([]);
  const [forceRefreshToggle, setForceRefreshToggle] = useState(false);

  const { pagination, setPage, setPagination } = usePagination();
  const previousSearch = usePrevious(searchQuery);
  const isMountedRef = useIsComponentMountedRef();

  const forceRefresh = () => {
    setForceRefreshToggle(toggle => !toggle);
    setMapListingItems([]);
    setAllMapListingItems([]);
  };

  const changePrivacyLevel = (props: ChangePrivacyLevelProps) => {
    const { mapId, newPrivacyLevel, parentMapId } = props;
    const updatedItems = [...mapListingItems];
    if (parentMapId === undefined) {
      const itemIndex = updatedItems.findIndex(i => i.id === mapId);
      const updatedItem = updatedItems[itemIndex];
      if (!updatedItem) {
        return;
      }
      updatedItem.privacy = newPrivacyLevel;
    }
    else {
      const itemIndex = updatedItems.findIndex(i => i.id === parentMapId);
      const updatedItem = updatedItems[itemIndex];
      if (!updatedItem) {
        return;
      }
      const updatedSnapshot = updatedItem.snapshots.find(i => i.id === mapId);
      if (!updatedSnapshot) {
        return;
      }
      updatedSnapshot.privacy = newPrivacyLevel;
    }
    setMapListingItems(updatedItems);
  };

  useEffect(() => {
    if (searchQuery) {
      setPage(1);
    }
    setAllMapListingItems([]);
  }, [searchQuery, setPage]);

  useEffect(() => {
    if (!isActive || clientId === null) {
      return;
    }

    const cancelToken = createCancelToken();
    const timeout = (previousSearch !== undefined && previousSearch !== searchQuery) ? 400 : 0;

    const timeoutId = setTimeout(() => {
      setIsLoading(true);
      setErrorMessage('');

      const request: MapListRequest = {
        sort: sortType !== MapListSortType.Layered ? sortType : undefined,
        per_page: pagination.perPage,
        page: pagination.currentPage,
        search: searchQuery,
        ...(
          (sortType === MapListSortType.Layered || filterType === MapListFilter.Layered)
            ? { filter: MapListFilter.Layered }
            : filterType === MapListFilter.NonLayered ? { filter: MapListFilter.NonLayered } : {}
        ),
      };

      getMapList(clientId, request, cancelToken.token)
        .then(response => {
          const items = response.data.list.map(item => createMapListingItemFromMapResponse(item));

          const currentMapIncluded = items.some(item => (item.id === (mapInfo?.parentMap?.id || mapInfo?.id)));
          const mapListingItemsAdjusted = [...items];
          if (!isMobileScreen) {
            if (!currentMapIncluded && includeCurrentMapOnTop && mapInfo) {
              const topMap: MapListingItem = convertMapInfoToMapListingItem(mapInfo);
              mapListingItemsAdjusted.unshift(topMap);
            }
            setMapListingItems(mapListingItemsAdjusted);
          }
          else {
            setMapListingItems(mobileMapListingItems => [...mobileMapListingItems, ...mapListingItemsAdjusted]);
          }
          setAllMapListingItems(allItems => {
            const allItemsLookup = new Set(allItems.map(item => item.id));
            const newItems = items.filter(item => !allItemsLookup.has(item.id));
            return allItems.concat(newItems);
          });
          const newPagination = createPaginationFromResponse(response.data.meta);
          setPagination(newPagination);
          // get to the last possible page if request's page is > pages count
          if (request.page !== undefined && request.page > newPagination.lastPage) {
            setPage(newPagination.lastPage);
          }

          if (!isTextEmpty(searchQuery)) {
            const newVisibleSnapshotMapIds = items
              .filter(item => item.snapshots.length > 0)
              .map(item => item.id);
            setToggledMapIds(newVisibleSnapshotMapIds);
          }
          else {
            setToggledMapIds([]);
          }

          // map listing also contains geocoding data, so we will update the geocoding state as well
          dispatch(geocodingSetProgress(items.reduce<PerSpreadsheetGeocodingUpdates>((accum, mapItem) => {
            mapItem.spreadSheets.forEach(virtualSpreadsheet => {
              Object.entries(virtualSpreadsheet.geocoding?.geocodings || {}).forEach(([realSpreadsheetIdString, geocoding]) => {
                const realSpreadsheetId = parseInt(realSpreadsheetIdString, 10);
                accum[realSpreadsheetId] = {
                  realSpreadsheetId,
                  progress: geocoding.percentage,
                  triggeredByUserId: geocoding.caller.id,
                  isPaused: virtualSpreadsheet.geocoding?.is_paused || false,
                };
              });
            });
            return accum;
          }, newPerSpreadsheetMap())));
        })
        .catch((e: ApiError) => {
          if (isCancelError(e)) {
            return;
          }

          console.error(e);

          if (isMountedRef.current) {
            setErrorMessage(t('An unknown error has occurred.'));
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        });
    }, timeout);

    return () => {
      cancelToken.cancel();
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActive, clientId, userId, sortType, searchQuery, pagination.currentPage, pagination.perPage,
    forceRefreshToggle, isMobileScreen, mapListingETag,
  ]);

  return {
    allMapListingItems,
    changePrivacyLevel,
    errorMessage,
    forceRefresh,
    isLoading,
    mapListingItems,
    onPageChange: setPage,
    onToggleMapIdsChange: setToggledMapIds,
    pagination,
    toggledMapIds,
  };
};
