import { MOBILE_VERSION_WIDTH_THRESHOLD } from '../../_shared/utils/deviceDetect/deviceDetect.helpers';

const getMaxFitElements = (totalWidth: number, elementWidth: number, delimiterWidth: number) =>
  Math.floor((totalWidth + delimiterWidth) / (elementWidth + delimiterWidth));

export const getMaxNumberOfPossibleIcons = (props: {
  delimiterWidth: number;
  elementWidth: number;
  horizontalPadding: number;
  minimumNumberOfIcons: number;
  totalHorizontalSpace: number;
}) => {
  const { horizontalPadding, totalHorizontalSpace, elementWidth, delimiterWidth, minimumNumberOfIcons } = props;
  const horizontalBarWithoutPaddingPercentage = (100 - horizontalPadding) / 100;
  const availableBarWidth = totalHorizontalSpace * horizontalBarWithoutPaddingPercentage;
  const maxIconCount = getMaxFitElements(MOBILE_VERSION_WIDTH_THRESHOLD, elementWidth, delimiterWidth);
  const maxPossibleIconCount = getMaxFitElements(availableBarWidth, elementWidth, delimiterWidth);

  if (maxPossibleIconCount > maxIconCount) {
    return maxIconCount - 1; // The 1 is the offset for the Map Tools icon
  }

  if (maxPossibleIconCount < minimumNumberOfIcons) {
    return minimumNumberOfIcons;
  }

  return maxPossibleIconCount;
};
