import { OverlayViewSafe } from './overalyViewSafe';

type GoogleMapsOverlayViewProps = {
  position: google.maps.LatLng;
  content: HTMLElement;
  onAdd?: () => void;
};

export class GoogleMapsOverlayView extends OverlayViewSafe {
  position: google.maps.LatLng;
  content: HTMLElement;
  onAddCallback?: () => void;

  constructor(props: GoogleMapsOverlayViewProps) {
    super();
    this.position = props.position;
    this.content = props.content;
    this.onAddCallback = props.onAdd;
  }

  /** Called when the popup is added to the map. */
  onAdd = () => {
    this.getPanes()?.floatPane.appendChild(this.content);
    this.onAddCallback?.();
  };

  /** Called when the popup is removed from the map. */
  onRemove = () => {
    this.content?.parentElement?.removeChild(this.content);
  };

  /** Called each frame when the popup needs to draw itself. */
  draw = () => {
    const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
    if (divPosition) {
      this.content.style.left = divPosition.x + 'px';
      this.content.style.top = divPosition.y + 'px';
    }
  };
}
