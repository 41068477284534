import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FileAttachmentTool } from '~/_shared/types/file.types';
import {
  createNewFileAttachment, getFileAttachmentId,
} from '~/_shared/utils/files/fileAttachments.helpers';
import { mapSettingsFileAttachmentsAdd } from '~/store/mapSettings/fileAttachments/fileAttachments.actionCreators';
import { useMapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';

export const useDrawingToolImages = () => {
  const dispatch = useDispatch();
  const fileAttachmentMap = useMapSettingsFileAttachmentsMapSelector();

  const activateDrawingImage = useCallback((fileId: number) => {
    const fileAttachment = fileAttachmentMap.get(getFileAttachmentId(fileId, FileAttachmentTool.Drawing));
    if (fileAttachment) {
      return fileAttachment.id;
    }
    else {
      const newFileAttachment = createNewFileAttachment(fileId, FileAttachmentTool.Drawing);
      dispatch(mapSettingsFileAttachmentsAdd(newFileAttachment));
      return newFileAttachment.id;
    }
  },
  [dispatch, fileAttachmentMap],
  );

  return {
    activateDrawingImage,
  };
};
