import { css } from '@emotion/react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { ScrollAndStickyHorizontalSplitComponent } from '~/_shared/components/scrollAndStickyHorizontalSplit/scrollAndStickyHorizontalSplit.component';
import { useConfirmationModal } from '../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../_shared/utils/hooks';
import { MapStatusBarToggleItemComponent } from './item/mapStatusBarToggleItem.component';
import { MapStatusBarItemComponent } from './mapStatusBarItem.component';
import { type MapStatusBarItem } from './mapStatusBarItem.type';

const selectedItemSwitchStyle = css({ margin: '2px' });

type MapStatusBarProps = Readonly<{
  isLoading: boolean;
  items: MapStatusBarItem[];

  onDeleteAllClick: () => void;
}>;

export const MapStatusBarComponent: FC<MapStatusBarProps> = (props) => {
  const [t] = useTranslation();
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();

  const buttonProps = useMemo(() => [{
    buttonStyle: ButtonStyle.DangerInverted,
    isDisabled: props.isLoading,
    onClick: () => openConfirmationModal({
      title: t('Delete element'),
      text: t('You are about to delete everything. This action is irreversible. Do you wish to proceed?'),
      onConfirm: () => {
        props.onDeleteAllClick();
        closeConfirmationModal();
      },
      isConfirmButtonDestructive: true,
      onCancel: closeConfirmationModal,
      confirmCaption: t('Proceed'),
    }),
    prefixIcon: faTrash,
    label: t('Delete all').toUpperCase(),
  }], [closeConfirmationModal, openConfirmationModal, props, t]);

  return (
    <ScrollAndStickyHorizontalSplitComponent
      buttons={buttonProps}
    >
      {props.items.map((item, index) => (
        item.switch
          ? (
            <MapStatusBarToggleItemComponent
              key={index}
              item={item}
              css={selectedItemSwitchStyle}
            />
          )
          : (
            <MapStatusBarItemComponent
              key={index}
              item={item}
              disabled={props.isLoading}
            />
          )
      ))}
    </ScrollAndStickyHorizontalSplitComponent>
  );
};
