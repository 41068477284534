import { createAction } from '@reduxjs/toolkit';
import type { LatLng } from '~/_shared/types/latLng';
import type { DrawingItem } from '~/store/mapSettings/drawing/items/drawingItems.state';

export const showDrawingItemsContextMenu = createAction('DRAWING_ITEMS_CONTEXT_MENU_SHOW', (position: LatLng, drawingItem: DrawingItem) => ({
  payload: {
    position,
    drawingItem,
  },
}));

export const hideDrawingItemsContextMenu = createAction('DRAWING_ITEMS_CONTEXT_MENU_HIDE');
