import { css } from '@emotion/react';
import { type FC } from 'react';
import { type StreetViewState } from '~/store/frontendState/mapTools/streetView/streetView.state';
import { type MapSettingsPublicMapSettingsState } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.state';
import { MapMarkersVisibilityToggleContainer } from '../menuItems/mapMarkersVisibilityToggle.container';
import { MapToolsMyLocationMenuItem } from '../menuItems/roundToolsButtonMenuItem/mapToolsMyLocationMenuItem.container';
import { ZoomControlsContainer } from '../menuItems/zoomControls/zoomControls.container';

// cannot cover the Google logo because of Google Maps TOS!
const BOTTOM_MARGIN = 30;

const leftsideMapToolsStyle = css({
  position: 'absolute',
  bottom: BOTTOM_MARGIN,
  left: 10,
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '8px',
  zIndex: 1,
});

export const zoomControlsContainerStyle = css({
  breakInside: 'avoid',
  margin: '2px',
  position: 'relative',
});

type LeftSideMapToolsComponentProps = Readonly<{
  isMapPresentational: boolean;
  isMobileScreen: boolean;
  isMarkerVisibilityVisible?: boolean;
  publicMapSettings?: MapSettingsPublicMapSettingsState;
  streetView: StreetViewState;
}>;

export const LeftSideMapToolsComponent: FC<LeftSideMapToolsComponentProps> = (props) => {
  const showLocationMenu = props.isMobileScreen && (!props.isMapPresentational
    || (props.isMapPresentational && props.publicMapSettings?.showMyLocation));
  const showZoomControls = !props.isMapPresentational || props.publicMapSettings?.mapZoom;

  if (props.streetView.active) {
    return null;
  }

  return (
    <div css={leftsideMapToolsStyle}>
      {props.isMarkerVisibilityVisible && <MapMarkersVisibilityToggleContainer />}
      {showZoomControls && (
        <div css={zoomControlsContainerStyle}>
          <ZoomControlsContainer />
        </div>
      )}
      {showLocationMenu && <MapToolsMyLocationMenuItem />}
    </div>
  );

};
