import { type BoundaryGroup } from '../../../../boundary/boundary.types';
import { isCustomGroup } from '../../../../store/boundaryGroups/boundaryGroups.selectors';
import {
  BoundaryTerritoryType,
  type CalculateBucketFunction,
  type CalculateBucketFunctionNumeric,
} from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryBucketType } from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { type BoundaryTerritoryGroup } from '../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const isBoundaryTerritoryGroupCustom = (boundaryTerritoryGroup: BoundaryTerritoryGroup, boundaryGroups: ReadonlyArray<BoundaryGroup>): boolean => {
  const matchingBoundaryGroup = boundaryGroups.find(boundaryGroup => boundaryGroup.id === boundaryTerritoryGroup.boundaryGroupId);
  return matchingBoundaryGroup ? isCustomGroup(matchingBoundaryGroup) : false;
};

export const getBoundaryBucketTypeForBoundaryTerritoryType = (territoryType: BoundaryTerritoryType): BoundaryBucketType | null => {
  switch (territoryType) {
    case BoundaryTerritoryType.Groups:
      return 'value';
    case BoundaryTerritoryType.Numeric:
      return 'value-range';
    case BoundaryTerritoryType.Demographic:
      return 'value-range';
    default:
      return null;
  }
};

const numericCalculateBucketFunctions = ['min', 'avg', 'max', 'sum'];
const isNumericBucketFunction = (f: CalculateBucketFunction | null): f is CalculateBucketFunctionNumeric =>
  !!f && !!numericCalculateBucketFunctions.find(i => i === f);

export const getNumericCalculateBucketFunction = (
  boundaryTerritoryGroup: BoundaryTerritoryGroup
): CalculateBucketFunctionNumeric | null => {
  if (boundaryTerritoryGroup.settings.boundaryTerritoryType !== BoundaryTerritoryType.Numeric) {
    return null;
  }

  const current = boundaryTerritoryGroup.settings.calculateBucketFunction;
  return isNumericBucketFunction(current) ? current : 'sum';
};

export const getCalculateBucketFunction = (
  territoryType: BoundaryTerritoryType,
  numericCalculateFunction?: CalculateBucketFunctionNumeric | null,
): CalculateBucketFunction | null => {
  switch (territoryType) {
    case BoundaryTerritoryType.Groups:
      return 'equal';
    case BoundaryTerritoryType.Numeric:
      return numericCalculateFunction ?? 'sum';
    case BoundaryTerritoryType.Demographic:
    case BoundaryTerritoryType.Density:
      return 'sum';
    default:
      return null;
  }
};

export const hasBoundaryFillTypeBoundaryData = (boundaryTerritoryType: BoundaryTerritoryType | null): boolean => {
  const fillDataIsNotShown = [BoundaryTerritoryType.Manual, BoundaryTerritoryType.Density, null];

  return !fillDataIsNotShown.includes(boundaryTerritoryType);
};
