import { useEffect } from 'react';
import { useMapComponentMapOptionsCurrentSelector } from '../../store/frontendState/mapComponent/mapComponent.selectors';
import { useFirstZoomDoneSelector } from '../../store/frontendState/processing/processing.selectors';
import { usePublicMapSettingsIsZoomEnabledSelector } from '../../store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { useMapContext } from './mapContext';

export const useMapOptions = () => {
  const { map } = useMapContext();
  const mapOptions = useMapComponentMapOptionsCurrentSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const zoom = map.getZoom();
  const firstZoomDone = useFirstZoomDoneSelector();
  const publicMapSettingsIsZoomEnabled = usePublicMapSettingsIsZoomEnabledSelector();

  useEffect(() => {
    if (!map || !mapOptions) {
      return;
    }

    const shouldDisableMapZoom = isMapPresentational && !publicMapSettingsIsZoomEnabled && firstZoomDone;
    const disableZoomOptions = {
      disableDoubleClickZoom: true,
      scrollwheel: false,
      maxZoom: zoom, //due to google maps issues, max zoom needs to be before minZoom
      minZoom: zoom,
    };

    map.setOptions({
      ...mapOptions,
      ...(shouldDisableMapZoom ? disableZoomOptions : {}),
    });
  }, [firstZoomDone, isMapPresentational, map, mapOptions, publicMapSettingsIsZoomEnabled, zoom]);
};
