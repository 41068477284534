import { css } from '@emotion/react';
import {
  type FC, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { type ModalProps } from '../../../modal/modalType.enum';
import { CheckboxComponent } from '../../baseComponents/checkbox';
import { type SpreadsheetColumnId } from '../../types/spreadsheetData/spreadsheetColumn';
import { compareSpreadsheetColumnIds } from '../../types/spreadsheetData/spreadsheetColumns.helpers';
import { useTranslation } from '../../utils/hooks';
import { ModalComponent } from '../modal/modal.component';

type ChooseColumnModalProps = ModalProps<{
  showDisplayRowIdIfColumnEmptyCheckbox: boolean;
  columnOptions: Array<DropdownOption<SpreadsheetColumnId>>;
  initialColumnValue?: SpreadsheetColumnId;
  initialDisplayRowIdIfColumnEmpty?: boolean;
  onSubmit: (selectedColumn: SpreadsheetColumnId, displayRowIfColumnEmpty: boolean) => void;
}>;

const checkboxStyle = css({
  marginTop: 10,
});

export const ChooseColumnModalComponent: FC<ChooseColumnModalProps> = (props) => {
  const [t] = useTranslation();
  const [selectedColumnId, setSelectedColumnId] = useState<SpreadsheetColumnId | null>(props.initialColumnValue ?? null);
  const [displayRowIdIfColumnEmpty, setDisplayRowIdIfColumnEmpty] = useState(props.initialDisplayRowIdIfColumnEmpty ?? true);

  const toggleDisplayRowIdIfColumnEmpty = () => {
    setDisplayRowIdIfColumnEmpty(!displayRowIdIfColumnEmpty);
  };

  const onSubmit = () => {
    if (!selectedColumnId) {
      return;
    }

    props.onSubmit(selectedColumnId, displayRowIdIfColumnEmpty);
  };

  useEffect(() => {
    const [firstColumnOption] = props.columnOptions;
    if (!firstColumnOption) {
      return;
    }

    setSelectedColumnId(props.initialColumnValue ?? firstColumnOption.value);
  }, [props.columnOptions, props.initialColumnValue]);

  const compareDropdownOptions = (a: SpreadsheetColumnId | null, b: SpreadsheetColumnId | null) => {
    if (a === null || b === null) {
      return false;
    }

    return compareSpreadsheetColumnIds(a, b);
  };

  if (props.columnOptions.length === 0) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={props.isOpen}
      onClose={props.onClose}
      caption={t('Choose a Column for Marker Label')}
      confirmButton={(
        <ButtonComponent
          text={t('Save')}
          isDisabled={!selectedColumnId}
          onClick={onSubmit}
        />
      )}
    >
      <RegularDropdownComponent<SpreadsheetColumnId>
        onChange={setSelectedColumnId}
        placeholder={t('Choose a column')}
        value={selectedColumnId}
        inPortal
        options={props.columnOptions}
        compareOptions={compareDropdownOptions}
      />

      {props.showDisplayRowIdIfColumnEmptyCheckbox && (
        <CheckboxComponent
          css={checkboxStyle}
          isChecked={displayRowIdIfColumnEmpty}
          checkedSetter={toggleDisplayRowIdIfColumnEmpty}
          text={t('Display marker number if column is empty')}
        />
      )}
    </ModalComponent>
  );
};
