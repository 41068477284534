import type { FC } from 'react';
import { MapToolWrapperComponent } from '../../mapToolWrapper/mapToolWrapper.component';

export const PlacesToolComponent: FC = () => {
  return (
    <MapToolWrapperComponent>
      Places Tool Content
    </MapToolWrapperComponent>
  );
};
