import {
  CLIENT_INVITEE_LIST_CHANGED, CLIENT_MEMBER_LIST_CHANGED, CLIENT_SET_SUBSCRIPTION_LOADING, CLIENT_SUBSCRIPTION_CHANGED,
} from './client.actionTypes';

export const clientInviteeListChanged = () => ({
  type: CLIENT_INVITEE_LIST_CHANGED,
}) as const;

export const clientMemberListChanged = () => ({
  type: CLIENT_MEMBER_LIST_CHANGED,
}) as const;

export const clientSubscriptionChanged = () => ({
  type: CLIENT_SUBSCRIPTION_CHANGED,
}) as const;

export const clientSetSubscriptionLoading = (newValue: boolean) => ({
  type: CLIENT_SET_SUBSCRIPTION_LOADING,
  payload: { newValue },
}) as const;
