import { css } from '@emotion/react';
import { type FC } from 'react';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { type MapListingItem } from '../../item/mapListingItem';

type MapRowToggleProps = Readonly<{
  item: MapListingItem;
  onClick: (mapId: number) => void;
  areSnapshotsExpanded: boolean;
  className?: string;
  children?: React.ReactNode;
}>;

const toggleButtonStyle = css({
  background: 'none',
  border: 'none',
  padding: 0,
  color: 'inherit',
});

export const MapRowToggleComponent: FC<MapRowToggleProps> = (props) => {
  const [t] = useTranslation();

  if (props.item.snapshots.length === 0) {
    return <>{props.children}</>;
  }

  return (
    <button
      className={props.className}
      css={toggleButtonStyle}
      onClick={() => {
        props.onClick(props.item.id);
      }}
      title={t(props.areSnapshotsExpanded ? 'Collapse snapshots' : 'Expand snapshots')}
    >
      {props.children}
    </button>
  );
};
