import {
  type FC, useEffect, useState,
} from 'react';
import { useOnMount } from '~/_shared/hooks/useOnMount';
import { SubscriptionPageComponent } from './subscriptionPage.component';
import { usePaymentPortal } from './usePaymentPortal.hook';
import { usePaymentPortalRefresh } from './usePaymentPortalRefresh.hook';

export const SubscriptionPageContainer: FC = () => {
  const [isError, setError] = useState(false);

  const { paymentPortalRefresh } = usePaymentPortalRefresh();
  const { getPaymentPortalUrl } = usePaymentPortal();

  useEffect(() => () => {
    paymentPortalRefresh();
  }, [paymentPortalRefresh]);

  useOnMount(() => {
    const asyncEffect = async () => {
      try {
        const paymentPortalUrl = (await getPaymentPortalUrl());
        self.location.href = paymentPortalUrl.data.link;
      }
      catch (e) {
        console.error(e);
        setError(true);
      }
    };
    asyncEffect();
  });

  return (
    <SubscriptionPageComponent
      isError={isError}
    />
  );
};
