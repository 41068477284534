import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import type { ModalProps } from '~/modal/modalType.enum';
import { ModalComponent } from '../../../../../_shared/components/modal/modal.component';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type CombinedRowId } from '../../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import { togglePresence } from '../../../../../_shared/utils/collections/collections';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import { maxTotalWaypoints } from '../../../../../directions/getDirections.service';
import { RowComponent } from './row.component';

const modalStyle = (props: ThemeProps) => css({
  fontFamily: '"Work Sans", sans-serif',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  color: props.theme.textColors.primary,
});

const buttonPaneStyle = css({
  marginTop: '16px',
  marginBottom: '4px',
  marginLeft: '24px',
});

const modalContentStyle = css({ padding: '0px' });

const contentStyle = css({
  padding: '24px 24px 0',
  height: '100%',
});

const separatorStyle = (props: ThemeProps) => css({
  height: '1px',
  width: '100%',
  backgroundColor: props.theme.lineColors.basePrimary,
});

const buttonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

const buttonStyle = css({
  margin: '11px 16px 16px',
});

export type Location = {
  rowId: CombinedRowId;
  name: string;
  spreadsheetId: number;
  address: string | null;
};

export type SelectionDialogProps = ModalProps<{
  rows: ReadonlyArray<Location>;

  onSubmit: (selectedRows: ReadonlyArray<CombinedRowId>) => void;
}>;

export const SelectionDialogComponent: React.FC<SelectionDialogProps> = props => {
  const [selectedRowIds, setSelectedRowIds] = useState<ReadonlySet<CombinedRowId>>(new Set());
  const [t] = useTranslation();
  const theme = useTheme();

  const handleToggle = useCallback((rowId: CombinedRowId): void => setSelectedRowIds(prev => togglePresence(prev, rowId)), []);
  const selectedLessThanLimit = selectedRowIds.size <= maxTotalWaypoints;
  const somethingIsSelected = selectedRowIds.size > 0;
  const canSubmit = somethingIsSelected && selectedLessThanLimit;
  const selectFirstLocationsUpToLimit = () => {
    const rowsUpToLimit = props.rows.slice(0, maxTotalWaypoints).map(row => row.rowId);
    setSelectedRowIds(new Set(rowsUpToLimit));
  };

  const submit = () => {
    if (canSubmit) {
      props.onSubmit(Array.from(selectedRowIds));
    }
  };

  return (
    <div css={modalStyle({ theme })}>
      <ModalComponent
        caption={t('EXPORT LOCATIONS TO ROUTING / DIRECTIONS')}
        contentMaxHeight="80vh"
        contentStyle={modalContentStyle}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <div css={buttonPaneStyle}>
          <ButtonComponent
            text={t('Check the first {{maxLocations}} locations', { maxLocations: maxTotalWaypoints.toString() })}
            onClick={selectFirstLocationsUpToLimit}
          />
        </div>
        <div css={contentStyle}>
          {props.rows.map((item, index) => {
            const rowNumber = index + 1;

            return (
              <RowComponent
                key={item.rowId}
                rowId={item.rowId}
                rowNumber={rowNumber}
                isChecked={selectedRowIds.has(item.rowId)}
                name={item.name}
                address={item.address}
                onToggle={handleToggle}
              />
            );
          })}
        </div>
        <div css={separatorStyle({ theme })} />
        <div css={buttonWrapperStyle}>
          <ButtonComponent
            prefixIcon={faCheck}
            buttonStyle={ButtonStyle.Success}
            css={buttonStyle}
            text={t('Done')}
            onClick={submit}
            isDisabled={!canSubmit}
          />
        </div>
      </ModalComponent>
    </div>
  );
};
