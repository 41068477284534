import { MarkerColor } from '~/_shared/types/marker.types';
import {
  MapObjectOutlineSize,
  MapObjectOutlineThickCircleSize,
} from '~/map/map/mapObjects/mapObject.types';

export const outlineVisuals = {
  dot: {
    color: MarkerColor.White,
    size: MapObjectOutlineSize.Small,
  },
  circle: {
    color: MarkerColor.Grey,
    size: MapObjectOutlineThickCircleSize.Small,
    style: 'thick',
  },
} as const;

export const outlineHoverVisuals = {
  dot: {
    color: MarkerColor.White,
    size: MapObjectOutlineSize.Default,
  },
  circle: {
    color: MarkerColor.Blue,
    size: MapObjectOutlineThickCircleSize.Default,
    style: 'thick',
  },
} as const;

export const outlineDisabledVisuals = {
  dot: {
    color: MarkerColor.LightGray,
    size: MapObjectOutlineSize.Small,
  },
  circle: {
    color: MarkerColor.Grey,
    size: MapObjectOutlineThickCircleSize.Small,
    style: 'thick',
  },
} as const;

export const ghostOutlineVisuals = {
  dot: {
    color: MarkerColor.White,
    size: MapObjectOutlineSize.Small,
    transparent: true,
  },
  circle: {
    color: MarkerColor.Grey,
    size: MapObjectOutlineThickCircleSize.Small,
    style: 'thick',
  },
} as const;

export const ghostOutlineHoverVisuals = {
  dot: {
    color: MarkerColor.White,
    size: MapObjectOutlineSize.Default,
    transparent: true,
  },
  circle: {
    color: MarkerColor.Blue,
    size: MapObjectOutlineThickCircleSize.Default,
    style: 'thick',
  },
} as const;
