import { type ColumnsWithValuesDataType } from '~/_shared/utils/spreadsheet/guessUniqueColumns/guessUniqueColumns';

export type DataSourceColumnInfo = {
  id: string;
  name: string;
};

export type DataSource = {
  fetch: ((additionalColumnIds?: string[]) => void) | null;
  error: DataSourceError | null;
  header: DataSourceColumnInfo[] | null;
  data: ColumnsWithValuesDataType | null;
  isLoading: boolean;
};

export enum DataSourceError {
  General = 1,
  NotFound,
  Empty,
  GoogleUnauthenticated,
  NoHeader,
}
