import { type SearchAction } from './search.action';
import {
  FRONTEND_STATE_SEARCH_HIDE, FRONTEND_STATE_SEARCH_SET_SEARCH_TEXT,
  FRONTEND_STATE_SEARCH_SHOW,
} from './search.actionTypes';
import { type SearchState } from './search.state';

const initialState: SearchState = {
  searchActive: false,
  searchText: '',
};

export const searchReducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case FRONTEND_STATE_SEARCH_SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case FRONTEND_STATE_SEARCH_SHOW: {
      return {
        ...state,
        searchActive: true,
      };
    }

    case FRONTEND_STATE_SEARCH_HIDE: {
      return {
        ...state,
        searchActive: false,
      };
    }

    default: {
      return state;
    }
  }
};
