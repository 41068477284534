import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  getInputCommonColors, inputCommonStyle,
} from '~/_shared/baseComponents/inputs/textInput/textInput.styles';
import type { Theme } from '~/_shared/themes/theme.model';
import { changeColorAlpha } from '~/_shared/utils/colors/colors.helpers';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import { ColorCodeHexFieldComponent } from '../colorCodeFields/colorCodeHexField.component';
import { rgbColorToString } from '../colorPicker.helpers';
import { type RGBColor } from '../colorPicker.types';
import { ColorPickerAlignment } from '../colorPickerHolder/colorPickerHolder.component';
import { ColorPickerHolderWithPresetColorsContainer } from '../colorPickerHolder/colorPickerHolderWithPresetColors.container';
import { ColorWheelComponent } from '../colorPickerWithWheel/colorWheel.component';
import { type MaptiveColorPickerProps } from '../maptiveColorPicker/maptiveColorPicker.component';

const getBorderAndBackgroundColors = (isActive: boolean, theme: Theme) => {
  const { borderColorActive, backgroundColor, backgroundColorActive, borderColor } = getInputCommonColors(theme);

  return {
    backgroundColor: isActive ? backgroundColorActive : backgroundColor,
    borderColor: isActive ? borderColorActive : borderColor,
  };
};

const wrapperStyle = ({ isFocusable, isActive }: { isActive: boolean; isFocusable: boolean }) => (theme: Theme) => {
  const { borderColor, backgroundColor } = getBorderAndBackgroundColors(isActive, theme);

  return css(inputCommonStyle(theme, isFocusable), {
    alignItems: 'center',
    backgroundColor, // override
    borderColor, // override
    boxSizing: 'border-box',
    color: theme.textColors.tertiary, // override
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  });
};

const hexInputStyle = ({ isActive, isFocusable }: { isActive: boolean; isFocusable: boolean }) => (theme: Theme) =>
  css(inputCommonStyle(theme, isFocusable), {
    backgroundColor: getBorderAndBackgroundColors(isActive, theme).backgroundColor, // override
    border: 0, // override
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    margin: 0,
    outline: 0,
    paddingBlock: 9,
    paddingInline: 10,
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    width: '100%',
  });

const insideWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const rightWrapperStyle = css({
  alignItems: 'center',
  display: 'flex',
  gap: 8,
  justifyContent: 'flex-end',
});

const colorWheelStyle = (disabled: boolean) => (theme: Theme) => css({
  opacity: disabled ? theme.opacity.disabled : 1,
});

const colorTileWrapperStyle = (theme: Theme) => css({
  backgroundColor: theme.backgroundColors.secondary,
});

const colorTileStyle = (props: ThemeProps<{ color: RGBColor; disabled?: boolean; displayAlpha?: boolean }>) => {
  const opacity = props.disabled ? props.theme.opacity.disabled : props.displayAlpha ? props.color.a : 1;

  return css({
    backgroundColor: rgbColorToString(changeColorAlpha(props.color, opacity).rgb),
    borderLeft: `1px solid ${props.theme.borderColors.primary}`,
    borderRadius: '0px 3px 3px 0px',
    height: '38px',
    width: '48px',
  });
};

type ColorPickerWithInputBarProps = MaptiveColorPickerProps & Readonly<{
  isDisabled?: boolean;
  alignment?: ColorPickerAlignment;

  onOpen?: () => void;
}>;

export const ColorPickerWithInputBarComponent: FC<ColorPickerWithInputBarProps> = ({
  displayNumericalColorsRepresentation = false,
  ...props
}) => {
  const [highlighted, setHighlighted] = useState(false);

  const highlightInput = useCallback(() => {
    setHighlighted(true);
  }, []);

  const dimInput = useCallback(() => {
    setHighlighted(false);
  }, []);

  return (
    <ColorPickerHolderWithPresetColorsContainer
      {...props}
      alignment={props.alignment ?? ColorPickerAlignment.BottomRight}
      displayNumericalColorsRepresentation={displayNumericalColorsRepresentation}
      editable={!props.isDisabled}
      onClose={dimInput}
      onOpen={highlightInput}
    >
      <ColorPickerWithInputBarChild
        {...props}
        isActive={highlighted}
      />
    </ColorPickerHolderWithPresetColorsContainer>
  );
};

const ColorPickerWithInputBarChild: FC<ColorPickerWithInputBarProps & { isActive: boolean }> = props => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.isActive]);

  return (
    <div css={wrapperStyle({ isActive: props.isActive, isFocusable: !props.isDisabled })}>
      <div css={insideWrapperStyle}>
        <ColorCodeHexFieldComponent
          css={hexInputStyle({ isActive: props.isActive, isFocusable: !props.isDisabled })}
          disabled={props.isDisabled}
          onChange={props.onChange}
          ref={inputRef}
          value={props.selectedColor}
        />

        <div css={rightWrapperStyle}>
          <ColorWheelComponent css={colorWheelStyle(!!props.isDisabled)} />
          <div css={colorTileWrapperStyle}>
            <div
              css={colorTileStyle({
                color: props.selectedColor.rgb,
                disabled: props.isDisabled,
                displayAlpha: props.displayAlpha,
                theme,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
