import {
  useCallback, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HOME_ROUTE, LOGIN_ROUTE,
} from '~/_shared/constants/routes';
import { parseUrl } from '~/_shared/utils/url/url.helpers';
import { AppErrorPageInvalidQueryString } from '~/app/pages/errorPage/queryStringMissing/appErrorPageInvalidQueryString.component';
import { SalesforceRegistrationFinishPageComponent } from './salesforceRegistrationFinishPage.component';
import { useSalesforceRegistrationFinish } from './useSalesforceRegistrationFinish.hook';

export const SalesforceRegistrationFinishPageContainer = () => {
  const navigate = useNavigate();
  const salesforceRegistrationFinish = useSalesforceRegistrationFinish();

  const [token] = useState<string | null>(() => {
    const fragments = parseUrl(window.location.href).queryParameters;
    return fragments?.token ?? null;
  });

  const onSubmit = useCallback(async (password: string, confirmPassword: string, callbackToSetFormSuccess?: () => void) => {
    if (!token) {
      return;
    }

    await salesforceRegistrationFinish.invoke(token, password, confirmPassword);
    callbackToSetFormSuccess?.();
    navigate(LOGIN_ROUTE, { state: { from: HOME_ROUTE } });
  }, [navigate, salesforceRegistrationFinish, token]);

  if (!token) {
    return <AppErrorPageInvalidQueryString />;
  }

  return (
    <SalesforceRegistrationFinishPageComponent
      isLoading={salesforceRegistrationFinish.isLoading}
      passwordError={salesforceRegistrationFinish.error}
      onSubmit={onSubmit}
    />
  );
};
