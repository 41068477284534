import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type FC } from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import { useTheme } from '../../themes/theme.hooks';
import { NoteModalComponent } from './noteModal.component';

export type NotificationModalProps = Readonly<ModalProps<{
  icon?: IconProp;
  notificationTitle: string;
  message?: string;
  textColor?: string;
  title: string;
}>>;

export const NotificationModalComponent: FC<NotificationModalProps> = props => {
  const theme = useTheme();

  return (
    <NoteModalComponent
      icon={props.icon}
      isOpen={props.isOpen}
      onClose={props.onClose}
      subTitle={props.notificationTitle}
      text={props.message}
      textColor={props.textColor || theme.textColors.success}
      title={props.title}
    />
  );
};
