import {
  type MapSettingsMapStylesChangeOptions,
  type MapSettingsStylesChangeData,
} from '../../../../../store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName, BaseMapFeatureTypeName, BaseMapStylerVisibility,
} from '../baseMap.enums';
import { makeSliderChangeDataVisible } from './baseMapStandardSliders.helpers';
import { type BaseMapSliderOptions } from './baseMapStandardSliders.types';

const hiddenRoadAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName.road,
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenRoadArterialAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['road.arterial'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenRoadArterialLabels: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.labels,
  featureType: BaseMapFeatureTypeName['road.arterial'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenRoadHighwayLabels: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.labels,
  featureType: BaseMapFeatureTypeName['road.highway'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const hiddenLocalAll: MapSettingsMapStylesChangeOptions = {
  elementType: BaseMapElementTypeName.all,
  featureType: BaseMapFeatureTypeName['road.local'],
  stylers: { visibility: BaseMapStylerVisibility.hidden },
};

const firstLandmarksSlider: MapSettingsStylesChangeData = [
  hiddenRoadAll,
];

const secondLandmarksSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(firstLandmarksSlider),
  hiddenRoadArterialAll,
  hiddenRoadHighwayLabels,
  hiddenLocalAll,
];

const thirdLandmarksSlider: MapSettingsStylesChangeData = [
  ...makeSliderChangeDataVisible(secondLandmarksSlider),
  hiddenRoadArterialLabels,
  hiddenRoadHighwayLabels,
  hiddenLocalAll,
];

const forthLandmarksSlider: MapSettingsStylesChangeData = makeSliderChangeDataVisible(thirdLandmarksSlider);

export const roadsSliderOptions: BaseMapSliderOptions = [
  firstLandmarksSlider,
  secondLandmarksSlider,
  thirdLandmarksSlider,
  forthLandmarksSlider,
];
