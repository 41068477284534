import { css } from '@emotion/react';
import {
  type FC, type ReactNode,
} from 'react';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../_shared/components/scrollbar/scrollbar.component';
import { useIsMobileScreenSelector } from '../../store/frontendState/deviceInfo/deviceInfo.selector';

type MetricsPanelProps = Readonly<{
  children: ReactNode;
}>;

const wrapperStyle = css({
  boxSizing: 'border-box',
  height: '100%',
  paddingBottom: 15,
});

export const MetricsPanel: FC<MetricsPanelProps> = (props) => {
  const isMobileScreen = useIsMobileScreenSelector();

  return (
    <div css={wrapperStyle}>
      {!isMobileScreen ? (
        <ScrollBarComponent type={ScrollbarType.Vertical} >
          {props.children}
        </ScrollBarComponent>
      ) : props.children}
    </div>
  );
};
