import { type FC } from 'react';
import {
  Route, Routes, useLocation,
} from 'react-router-dom';
import { TopBar } from '~/topBar/topBar';
import { MapPresentationalContainer } from '../../../map/presentional/mapPresentational.container';
import { useItemsVisibleInMobileTopUnderbar } from '../../../mobileTopBar/mobileTopUnderbar/useItemsVisibleInMobileTopUnderbar.hook';
import { SidebarContainer } from '../../../sidebar/sidebar.container';
import { useIsMobileScreenSelector } from '../../../store/frontendState/deviceInfo/deviceInfo.selector';
import { AppContent } from '../../maptiveApp/appContent';
import { LeftSidebar } from '../../maptiveApp/leftSidebar';
import { MapContextHoldingPageContainer } from '../../maptiveApp/mapContextHoldingPage.container';
import { useItemsVisibleOnPresentationalMap } from '../../maptiveApp/useItemsVisibleOnPresentationalMap.hook';
import { AppPageStylesWrapper } from '../../pages/appPageStylesWrapper.component';
import { AppErrorPageNotFoundComponent } from '../../pages/errorPage/notFound/appErrorPageNotFound.component';
import { AppErrorPagePresentationalMapUnavailable } from '../../pages/errorPage/publicMapUnavailable/appErrorPagePresentationalMapUnavailable';
import { PublicMapPaths } from './publicMaps.constants';

export const PresentationalMapRoutes: FC = () => (
  <Routes>
    <Route path={PublicMapPaths.Map}>
      <Route index element={<PublicMapContent />} />
      <Route path={PublicMapPaths.Expired} element={<AppErrorPagePresentationalMapUnavailable />} />
    </Route>
    <Route path="*" element={<AppErrorPageNotFoundComponent />} />
  </Routes>
);

const PublicMapContent = () => {
  const location = useLocation();
  const isMobileScreen = useIsMobileScreenSelector();
  const itemsVisibleOnPresentationalMap = useItemsVisibleOnPresentationalMap();
  const itemsAvailableInMobileMode = useItemsVisibleInMobileTopUnderbar();

  return (
    <>
      <TopBar showUndoButton={false} />
      <AppPageStylesWrapper>
        <LeftSidebar>
          <SidebarContainer
            visibleMenuItems={isMobileScreen ? itemsAvailableInMobileMode : itemsVisibleOnPresentationalMap}
            key={location.pathname}
          />
        </LeftSidebar>
        <AppContent>
          <MapContextHoldingPageContainer>
            {() => (<MapPresentationalContainer />)}
          </MapContextHoldingPageContainer>
        </AppContent>
      </AppPageStylesWrapper>
    </>
  );
};
