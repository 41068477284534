import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type ReactNode, useMemo,
} from 'react';
import { OverlayLoaderComponent } from '../../../../_shared/components/overlay/overlayLoader.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../_shared/utils/hooks';
import {
  SidebarRoundButtonMenuComponent,
  type SidebarRoundButtonMenuProps,
} from '../roundButtonMenu/sidebarRoundButtonMenu.component';
import {
  type SidebarHeaderArrowsProps,
  type SidebarHeaderButtonProps,
  SidebarHeaderComponent,
} from '../sidebarHeader/sidebarHeader.component';

const wrapperStyle = ({ theme }: ThemeProps) => css({
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  color: theme.textColors.primary,
  fontFamily: '"Work Sans", sans-serif',
  height: '100%',
});

const loaderWrapperStyle = ({ isMobileScreen }: { isMobileScreen: boolean }) => css({
  position: 'relative',
  height: isMobileScreen ? undefined : '100%',
});

type SidebarHeaderProps = Readonly<{
  additionalInfo?: string;
  additionalInfoStyle?: SerializedStyles;
  arrowProps?: SidebarHeaderArrowsProps;
  buttonProps?: SidebarHeaderButtonProps;
  currentLocationIndex: number;
  totalLocations: number;
}>;

type MenuProps<T> = SidebarRoundButtonMenuProps<T>;

export type SidebarContentProps<T> = Readonly<{
  body?: ReactNode;
  headerProps?: SidebarHeaderProps;
  secondaryHeader?: ReactNode;
  isLoading: boolean;
  isMobileScreen: boolean;
  description: ReactNode;
  menuProps?: MenuProps<T>;
}>;

export const SidebarContentComponent = <T extends unknown>(props: SidebarContentProps<T>) => {
  const theme = useTheme();
  const [t] = useTranslation();

  const headerText = useMemo(() => {
    if (props.headerProps?.totalLocations === undefined) {
      return '';
    }

    return props.headerProps.totalLocations <= 1 ? '' :
      t('Location current of total', {
        current: props.headerProps.currentLocationIndex + 1,
        total: props.headerProps.totalLocations,
      });
  }, [props.headerProps?.currentLocationIndex, props.headerProps?.totalLocations, t]);

  return (
    <div css={wrapperStyle({ theme })} >
      {props.headerProps && (
        <SidebarHeaderComponent
          arrowProps={props.headerProps.arrowProps}
          additionalInfo={props.headerProps.additionalInfo}
          additionalInfoStyle={props.headerProps.additionalInfoStyle}
          buttonProps={props.headerProps.buttonProps}
          headerText={headerText}
        />
      )}
      {props.description}

      {props.menuProps && (
        <SidebarRoundButtonMenuComponent
          {...props.menuProps}
        />
      )}

      {props.secondaryHeader}

      <div css={loaderWrapperStyle({ isMobileScreen: props.isMobileScreen })}>
        {props.body}

        {props.isLoading &&
          <OverlayLoaderComponent />
        }
      </div>
    </div>
  );
};
