import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { AlertComponent } from '~/_shared/baseComponents/alert';
import {
  LottieAnimationComponent,
  LottieAnimationTypes,
} from '~/_shared/baseComponents/lottieAnimation';
import { useTranslation } from '~/_shared/utils/hooks';

const alertBodyStyle = css({
  display: 'flex',
  justifyContent: 'center',
  gap: 8,
});

type EmailSentAlertProps = Readonly<{
  resetFlag: boolean;

  onAnimationEnd?: () => void;
}>;

export const EmailSentAlertComponent: FC<EmailSentAlertProps> = (props) => {
  const [hideAlert, setHideAlert] = useState(false);
  const [t] = useTranslation();

  const handleAnimationEnded = useCallback(() => {
    setHideAlert(true);
    const onAnimationEnd = props.onAnimationEnd;
    onAnimationEnd?.();
  }, [props.onAnimationEnd]);

  useEffect(() => {
    setHideAlert(false);
  }, [props.resetFlag]);

  if (hideAlert) {
    return null;
  }

  return (
    <AlertComponent type="success">
      <div css={alertBodyStyle}>
        {t('Email Sent')}
        <LottieAnimationComponent
          size={20}
          speed={0.4}
          type={LottieAnimationTypes.MailChecked}
          autoplay
          onAnimationEnd={handleAnimationEnded}
        />
      </div>
    </AlertComponent>
  );
};
