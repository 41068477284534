import {
  type UndoItemType,
  type UndoProximityData,
} from '~/_shared/types/undo/undoItem';
import { type UNDO_MODEL_VERSIONS } from '../undo.constants';

export type AllProximityVersions = typeof UNDO_MODEL_VERSIONS[UndoItemType.REMOVE_PROXIMITY][number];

const undoProximityDataFromAnyVersionToCurrentVersionConverters: {
  [key in AllProximityVersions]: (anyVersionUndoData: any) => UndoProximityData
} = {
  ['1']: (undoData) => undoData as UndoProximityData,
};

export const convertUndoProximityDataAnyVersionToCurrent = (
  anyVersionUndoData: any,
  version: AllProximityVersions,
): UndoProximityData => (
  undoProximityDataFromAnyVersionToCurrentVersionConverters[version](anyVersionUndoData)
);
