import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { type ModalProps } from '~/modal/modalType.enum';
import { useDeleteEntityAccess } from '../../../_shared/entityAccess/entityAccess.hooks';
import { useTranslation } from '../../../_shared/utils/hooks';
import { useStorageService } from '../../../_shared/utils/storageService';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { createAppError } from '../../../store/modal/modal.actionCreators';
import { useClientIdSelector } from '../../../store/selectors/useClientIdSelector';
import { RemoveMemberFromMapModalComponent } from './removeMemberFromMapModal.component';

export const REMOVE_USER_FROM_MAP_NOT_SHOW_AGAIN_KEY = 'DO_NOT_ASK_FOR_REMOVE_MAP_SHARE_CONFIRMATION';

export type RemoveMemberFromMapModalContainerProps = ModalProps<{
  entityAccessId: number;
}>;

export const RemoveMemberFromMapModalContainer: FC<RemoveMemberFromMapModalContainerProps> = (props) => {
  const { entityAccessId, ...modalProps } = props;
  const [t] = useTranslation();
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const storageService = useStorageService();

  const {
    delete: deleteEntityAccess,
    isLoading: isDeletingEntityAccess,
  } = useDeleteEntityAccess();

  const onDeleteEntityAccess = useCallback((doNotShowAgain: boolean) => {
    if (!clientId) {
      return;
    }

    if (doNotShowAgain) {
      storageService.setLocalStorageItem(
        REMOVE_USER_FROM_MAP_NOT_SHOW_AGAIN_KEY,
        'YES'
      );
    }

    deleteEntityAccess(clientId, entityAccessId)
      .then(() => {
        modalProps.onClose();
      })
      .catch(() => {
        dispatch(
          createAppError({
            type: AppErrorType.General,
            title: t('Error'),
            content: t('teamShareMap.editError'),
            errorTitle: t('teamShareMap.editErrorTitle'),
          })
        );
      });
  }, [storageService, clientId, deleteEntityAccess, dispatch, entityAccessId, modalProps, t]);

  return (
    <RemoveMemberFromMapModalComponent
      {...modalProps}
      deleteEntityAccess={onDeleteEntityAccess}
      isLoading={isDeletingEntityAccess}
    />
  );
};
