import { faSearchLocation } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { setLocationFinderToolsState } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.actionCreators';
import { useLocationFinderSelector } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.selectors';
import { RightSideMapToolsMenuItem } from './rightSideMapToolsMenuItem.component';

export const RightSideLocationFinderMenuItem: FC = () => {
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.LocationFinder);
  const locationFinder = useLocationFinderSelector();
  const dispatch = useDispatch();

  const onLocationFinderMenuItemClick = () => {
    if (locationFinder.latLng === null) {
      openModal({});
    }
    else {
      dispatch(setLocationFinderToolsState(null, null));
    }
  };

  return (
    <RightSideMapToolsMenuItem
      icon={faSearchLocation}
      active={locationFinder.latLng !== null}
      onClick={() => onLocationFinderMenuItemClick()}
      tooltip={t('Location Finder')}
    />
  );
};
