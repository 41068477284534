import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';

export type CyclicArray = {
  getByIndex(index: number): MapObjectOutlineInstance;
  findIndex(predicate: (outline: MapObjectOutlineInstance) => boolean): number;
};
const positiveModulo = (number: number, mod: number) => {
  // Copied from
  // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
  return ((number % mod) + mod) % mod;
};
export const createCyclicArray = (array: readonly MapObjectOutlineInstance[]): CyclicArray => {
  const arrayLength = array.length;
  const convertCyclicIndexToRegular = (cyclicIndex: number) => positiveModulo(cyclicIndex, arrayLength);
  return {
    getByIndex(index: number): MapObjectOutlineInstance {
      const regularIndex = convertCyclicIndexToRegular(index);
      const resultingMapObjectOutline = array[regularIndex];

      if (!resultingMapObjectOutline) {
        throw new Error(`Cyclic array failed to get index '${index}' which was translated to '${regularIndex}'
        and resolved to '${resultingMapObjectOutline}'. The array is '${array.length}' items long.`);
      }

      return resultingMapObjectOutline;
    },
    findIndex: array.findIndex,
  };
};
