import type { UserDataAction } from '~/store/userData/userData.action';
import { USER_SIGN_OUT_SUCCESS } from '~/store/userData/userData.actionTypes';
import { type ModalAction } from './modal.action';
import {
  MODAL_CLOSE,
  MODAL_CLOSE_ALL,
  MODAL_CLOSE_LAST,
  MODAL_OPEN,
  MODAL_OPEN_AND_CLOSE_LAST,
} from './modal.actionTypes';
import { type ModalState } from './modal.state';

const initialState: ModalState = {
  stack: [],
};

export const modalReducer = (state: ModalState = initialState, action: ModalAction | UserDataAction): ModalState => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        stack: [...state.stack, action.payload],
      };

    case MODAL_CLOSE:
      return {
        ...state,
        stack: state.stack.filter(m => m.id !== action.payload.id),
      };

    case MODAL_OPEN_AND_CLOSE_LAST:
      return {
        ...state,
        stack: [...state.stack.slice(0, state.stack.length - 1), action.payload],
      };

    case MODAL_CLOSE_LAST:
      return {
        ...state,
        stack: state.stack.slice(0, state.stack.length - 1),
      };

    case USER_SIGN_OUT_SUCCESS:
    case MODAL_CLOSE_ALL: {
      return {
        stack: [],
      };
    }

    default: {
      return state;
    }
  }
};
