import { type TranslationFnc } from '~/_shared/utils/hooks';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { MapRestoreErrors } from '~/appError/specificErrors/mapRestoreFail/mapRestoreErrors.component';
import { createAppError } from '~/store/modal/modal.actionCreators';
import type { RestoreResult } from '~/undo/useRestoreMap';

export const createMapRestoreAppError = (restoreResults: readonly RestoreResult[], t: TranslationFnc) => createAppError({
  type: AppErrorType.General,
  title: t('Map restore failed'),
  errorTitle: t('Some maps or snapshots have not been restored'),
  text: (<MapRestoreErrors errors={restoreResults} />),
});
