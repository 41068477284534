import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import {
  useDrag,
  useDrop,
} from 'react-dnd';
import { DndType } from '../../constants/dndType.enum';
import { useTimeout } from '../../utils/hooks/useTimeout';
import { nameOf } from '../../utils/nameOf';
import { ImageComponent } from '../image/image.component';

type SelectorItem<T> = Readonly<{ id: T }>;

export type Image<T> = Readonly<SelectorItem<T> & {
  url: string;
  alt?: string;
  onDelete?: () => void;
}>;

export type RenderableItem<T> = Readonly<SelectorItem<T> & {
  render: () => ReactNode;
}>;

export type ImageSelectorItem<T> = Image<T> | RenderableItem<T>;

export const isImageItem = <T extends unknown>(item: ImageSelectorItem<T>): item is Image<T> =>
  item.hasOwnProperty(nameOf<Image<T>>('url'));

type ImageSelectorItemProps<T> = Readonly<{
  item: ImageSelectorItem<T>;
  className?: string;
  width: number;
  height: number;

  onDrop?: (sourceId: T, targetId: T) => void;
  onClick: () => void;
}>;

const renderableItemStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

export type ImageSelectorDndItem<T> = Readonly<{
  type: DndType.ImageInSelector;
  id: T;
  item: ImageSelectorItem<T>;
}>;

export const ImageSelectorItemComponent = <T extends unknown>(props: ImageSelectorItemProps<T>) => {
  const [isClickDisabled, startDisabledClickTimeout] = useTimeout(0);
  const [, drag] = useDrag({
    type: DndType.ImageInSelector,
    item: props.item,
    canDrag: !!props.onDrop,
  });

  const [, drop] = useDrop<{ type: string; id: T }, void, unknown>({
    accept: [DndType.ImageInSelector],
    hover: (item: ImageSelectorDndItem<T>) => item.id !== props.item.id && props.onDrop?.(item.id, props.item.id),
    drop: () => startDisabledClickTimeout(),
  });

  return isImageItem(props.item)
    ? (
      <ImageComponent
        ref={el => {
          drag(el);
          drop(el);
        }}
        className={props.className}
        src={props.item.url}
        width={props.width}
        height={props.height}
        alt={props.item.alt}
        onClick={isClickDisabled ? undefined : props.onClick}
      />
    )
    : (
      <div
        ref={el => {
          drag(el);
          drop(el);
        }}
        css={css(renderableItemStyle)}
        className={props.className}
        onClick={isClickDisabled ? undefined : props.onClick}
      >
        {props.item.render()}
      </div>
    );
};
