import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { prettyPrint } from '~/_shared/utils/string/prettyPrint';

const mapMigrationErrorSummarySelector = createStateSelector([
  state => state.mapMigration.migrationStatus.errorCoordinates,
  state => state.mapMigration.migrationStatus.queueId,
],
(errors, queueId) => {
  const failedMapDeveloperInfo = Object.entries(errors)
    .map(([queueId, failedMapIds]) => ({ queueId, failedMapIds }));
  const failedQueueDeveloperInfo = [{ queueId, error: 'Unknown error' }];

  return {
    developerInfo: prettyPrint(failedMapDeveloperInfo.length > 0 ? failedMapDeveloperInfo : failedQueueDeveloperInfo),
  };
});

export const useMapMigrationErrorSummarySelector = () => useSelector(mapMigrationErrorSummarySelector);
