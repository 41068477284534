import {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebounce } from '~/_shared/utils/hooks/useDebounce';

type DebouncedUpdateProps<T> = Readonly<{
  wait: number;
  value: T;
  onChangeDebounced: (value: T) => void;
  render: (value: T, onChange: (value: T) => void) => ReactElement<any, any> | null;
}>;

type DebouncedUpdateComponent = <T, >(props: PropsWithChildren<DebouncedUpdateProps<T>>, context?: any) => ReactElement<any, any> | null;

// Internally manages updates of rendered component and applies debouncing to value propagation to parent.
// Reacts to value updates passed down via the props when internal update is not in progress.
export const DebouncedUpdateComponent: DebouncedUpdateComponent = <T, >(props: PropsWithChildren<DebouncedUpdateProps<T>>) => {
  const [internalValue, setInternalValue] = useState(props.value);
  const [internalUpdateInProgress, setInternalUpdateInProgress] = useState(false);

  const propagateChangeRef = useRef(props.onChangeDebounced);
  propagateChangeRef.current = props.onChangeDebounced;

  const propagateChangeDebounced = useDebounce((value: T) => {
    setInternalUpdateInProgress(false);
    propagateChangeRef.current(value);
  }, props.wait);

  const onChange = useCallback((value: T) => {
    setInternalUpdateInProgress(true);
    setInternalValue(value);
    propagateChangeDebounced(value);
  }, [propagateChangeDebounced]);

  const internalUpdateInProgressRef = useRef(internalUpdateInProgress);
  internalUpdateInProgressRef.current = internalUpdateInProgress;
  useEffect(() => {
    if (!internalUpdateInProgressRef.current) {
      setInternalValue(props.value);
    }
  }, [props.value]);

  return props.render(internalValue, onChange);
};
