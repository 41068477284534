import { useMemo } from 'react';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { parseCombinedRowId } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { getMarkerFallbackName } from '~/_shared/utils/spreadsheet/useMarkerNameResolver';
import { isNullsy } from '~/_shared/utils/typeGuards';
import type { AppState } from '~/store/app.store';
import { selectSpreadsheetData } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { type SpreadsheetRowId } from '../../../../_shared/types/spreadsheetData/spreadsheetRow';
import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { SpreadsheetRowIdMap } from '../../../../_shared/utils/spreadsheet/spreadsheetRowIdMap';
import {
  areNumericalLabelsActiveSelector,
  mapSettingsAreLabelsInsideMarkersActiveSelector,
  useMapSettingsUseLabelsAboveMarkersSelector,
} from '../../../../store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import {
  DataType,
  type SpreadsheetDataData,
  Unfiltered,
} from '../../../../store/spreadsheetData/spreadsheetData.state';
import { useSpreadSheetData } from '../../useSpreadsheetData.hook';

const markerLabelTextsSelector = createStateSelector([
  mapSettingsAreLabelsInsideMarkersActiveSelector,
  areNumericalLabelsActiveSelector,
  (s: AppState) => s.map.mapSettings.data.markersGeneral.labelMarkersNameColumn,
  selectSpreadsheetData,
  (_state: AppState, t: TranslationFnc) => t,
  (_state: AppState, _t: TranslationFnc, spreadsheetRowIds: ReadonlyArray<SpreadsheetRowId>) => spreadsheetRowIds,

], (areLabelsInMarkersActive, areNumericLabelsActive, labelTextColumnName, spreadsheetAndMatchupData, t, rowIds) => {
  const { spreadsheetData } = spreadsheetAndMatchupData;
  if (!areLabelsInMarkersActive) {
    return new SpreadsheetRowIdMap<string>().asReadonly();
  }

  if (areNumericLabelsActive) {
    const resultEntries = rowIds.map(rowId => [rowId, parseCombinedRowId(rowId.rowId)?.rowId?.toString() ?? ''] as const);

    return new SpreadsheetRowIdMap(resultEntries).asReadonly();
  }

  const resultEntries = rowIds.map(spreadsheetRowId => {
    const labelColumnValue = tryGetSpreadsheetCellData(labelTextColumnName?.columnId ?? null, spreadsheetRowId, spreadsheetData);

    const isColumnFetching = !isNullsy(labelTextColumnName?.columnId) && labelColumnValue === null;
    if (isColumnFetching) {
      return [spreadsheetRowId, ''] as const;
    }

    if (labelColumnValue?.length) {
      return [spreadsheetRowId, labelColumnValue] as const;
    }

    return [spreadsheetRowId, '   '] as const; // 3 spaces on purpose to enforce specific width
  });

  return new SpreadsheetRowIdMap(resultEntries).asReadonly();
});

export const useMarkerLabelData = (spreadsheetRowIds: ReadonlyArray<SpreadsheetRowId>) => {
  const [t] = useTranslation();

  return useSelector(state => markerLabelTextsSelector(state, t, spreadsheetRowIds));
};

export const useMarkerLabelAboveData = (spreadsheetRowIds: ReadonlyArray<SpreadsheetRowId>) => {
  const areLabelsAboveEnabled = useMapSettingsUseLabelsAboveMarkersSelector();
  const labelsAboveColumnId = useSelector(s => s.map.mapSettings.data.markersGeneral.labelsAboveMarkersNameColumn);
  const shouldDisplayRowIdIfEmpty = useSelector(s => s.map.mapSettings.data.markersGeneral.labelsAboveMarkersDisplayRowIdIfEmpty);
  const { spreadsheetData } = useSpreadSheetData();
  const [t] = useTranslation();

  return useMemo(() => {
    if (!areLabelsAboveEnabled) {
      return new SpreadsheetRowIdMap<string>().asReadonly();
    }

    const resultEntries = spreadsheetRowIds.map(spreadsheetRowId => {
      const labelColumnValue = tryGetSpreadsheetCellData(labelsAboveColumnId?.columnId ?? null, spreadsheetRowId, spreadsheetData);

      const isColumnFetching = labelColumnValue === null;
      if (isColumnFetching) {
        return [spreadsheetRowId, ''] as const;
      }

      if (!labelColumnValue?.length) {
        if (shouldDisplayRowIdIfEmpty) {
          return [spreadsheetRowId, getMarkerFallbackName(spreadsheetRowId.rowId, t)] as const;
        }
        else {
          return [spreadsheetRowId, undefined] as const;
        }
      }
      else {
        return [spreadsheetRowId, labelColumnValue] as const;
      }
    });

    return new SpreadsheetRowIdMap(resultEntries).asReadonly();
  }, [areLabelsAboveEnabled, spreadsheetRowIds, labelsAboveColumnId?.columnId, spreadsheetData, shouldDisplayRowIdIfEmpty, t]);
};

const getSpreadsheetCellData = (columnId: string, rowId: SpreadsheetRowId, spreadsheetData: SpreadsheetDataData) => {
  const groupData = spreadsheetData.values[rowId.spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.GROUP];
  const groupIndex = groupData?.values[rowId.rowId] ?? null;
  return groupIndex === null ? null : groupData?.extra.uniqueGroups?.[groupIndex]?.label ?? null;
};

const tryGetSpreadsheetCellData = (columnId: string | null, rowId: SpreadsheetRowId, spreadsheetData: SpreadsheetDataData) =>
  columnId === null ? null : getSpreadsheetCellData(columnId, rowId, spreadsheetData);
