import { validateMetricModel } from '~/_shared/utils/metric/metrics.factory';
import { type MapSettingsProximityResponse } from '~/map/map.repository';
import { mapSettingsProximityInitialState } from './mapSettingsProximity.reducer';
import { type MapSettingsProximityState } from './mapSettingsProximity.state';

export const mapSettingsProximitySerializer = {
  jsonToState: (json: MapSettingsProximityResponse): MapSettingsProximityState => {
    const { metrics: jsonMetrics, proximities: jsonProximities, ...jsonRest } = json;
    const metrics = jsonMetrics?.filter(validateMetricModel) ?? [];

    return {
      ...mapSettingsProximityInitialState,
      ...jsonRest,
      metrics,
      proximities: jsonProximities?.map((jsonProximity) => ({
        ...jsonProximity,
        styles: {
          ...jsonProximity.styles,
          // We introduced borderOpacity new property later on, so we need to make sure that it is always defined
          borderOpacity: jsonProximity.styles?.borderOpacity ?? 1,
        },
      })) ?? [],
    };
  },
  stateToJson: (state: MapSettingsProximityState): MapSettingsProximityResponse => {
    return state;
  },
};
