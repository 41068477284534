import { css } from '@emotion/react';
import { faMinus } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import {
  DropDownItemSize, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: 180,
});

const fullWidthStyle = css({ width: '100%' });

const triggerStyle = ({ theme }: ThemeProps) => css({
  backgroundColor: theme.backgroundColors.secondary,
});

const headerSectionStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  padding: '0 4px',
  width: '100%',
});

const removerStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.danger,
  fontSize: 16,

  '&:hover': {
    color: theme.textColors.dangerOnHover,
  },
});

type IdentifierDropdownElementProps = Readonly<{
  dropdownOptions: DropdownOption<number | null>[];
  index: number;
  selectedValue: number | null;
  title?: string;

  onChange: (index: number, id: number | null) => void;
  onRemove?: (index: number | null) => void;
}>;

export const IdentifierDropdownElementComponent: React.FC<IdentifierDropdownElementProps> = props => {
  const theme = useTheme();
  const [t] = useTranslation();
  const { onRemove, onChange } = props;

  const handleRemove = useCallback(() =>
    onRemove?.(props.index),
  [onRemove, props.index]);

  const handleChange = useCallback((id: number) =>
    onChange(props.index, id),
  [onChange, props.index]);

  return (
    <div css={wrapperStyle} >
      <div css={headerSectionStyle}>
        {onRemove && (
          <FontAwesomeIcon
            css={removerStyle({ theme })}
            icon={faMinus}
            onClick={handleRemove}
          />
        )}
        {props.title}
      </div>
      <RegularDropdownComponent
        css={fullWidthStyle}
        fallbackName={t('Select a column')}
        inPortal
        inputSize={InputSize.Medium}
        itemSize={DropDownItemSize.Large}
        onChange={handleChange}
        options={props.dropdownOptions}
        triggerStyle={triggerStyle({ theme })}
        value={props.selectedValue}
      />
    </div>
  );
};
