import { type HeatMap } from '../../../_shared/types/heatmap/heatMap.types';
import {
  MAP_SETTINGS_HEATMAP_ADD_HEATMAP,
  MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP,
  MAP_SETTINGS_HEATMAP_RESET_HEATMAPS,
  MAP_SETTINGS_HEATMAP_UPDATE_HEATMAP,
} from './mapSettingsHeatmaps.actionTypes';

export const addHeatMap = (heatmap: HeatMap) => ({
  type: MAP_SETTINGS_HEATMAP_ADD_HEATMAP,
  payload: {
    heatmap,
  },
}) as const;

export const resetHeatMaps = () => ({
  type: MAP_SETTINGS_HEATMAP_RESET_HEATMAPS,
}) as const;

export const updateHeatMap = (heatmap: HeatMap) => ({
  type: MAP_SETTINGS_HEATMAP_UPDATE_HEATMAP,
  payload: {
    heatmap,
  },
}) as const;

export const removeHeatMap = (heatmap: HeatMap, mapId: number) => ({
  type: MAP_SETTINGS_HEATMAP_REMOVE_HEATMAP,
  payload: {
    heatmap,
    mapId,
  },
}) as const;
