import {
  css, keyframes,
} from '@emotion/react';
import { type ThemeProps } from '../../../types/themeProps';
import { INIT_ANIMATION_DURATION_MS } from './progressbar.helpers';

const stripes = (stripeWidth: string) => keyframes`
  to {
    background-position: ${stripeWidth} 100%;
  }
`;

export const containerStyles = ({ theme, height, borderWidth }: ThemeProps<{
  height: number;
  borderWidth: number;
}>) => css({
  borderRadius: height / 2,
  boxSizing: 'border-box',
  width: '100%',
  height,
  position: 'relative',
  border: `${borderWidth}px solid ${theme.switchColors.primaryBorder}`,
  overflow: 'hidden',

});

export const trackWrapperStyle = ({ trackHeight, trackOffset }: { trackHeight: number; trackOffset: number }) => css({
  position: 'absolute',
  top: trackOffset,
  left: trackOffset,
  bottom: trackOffset,
  right: trackOffset,
  borderRadius: trackHeight / 2,
  overflow: 'hidden',
});

export const trackStyles = ({ theme, progress, trackHeight, animationDisabled }: ThemeProps<{
  progress: number;
  trackHeight: number;
  animationDisabled: boolean;
}>) => {
  const stripeWidth = `${trackHeight * 4}px`;
  return css({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${progress}%`,
    background: theme.contrasts.success,
    borderRadius: trackHeight / 2,
    willChange: 'width',
    transition: 'width .3s',
    zIndex: 2, // above the dot
    ':before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: trackHeight / 2,
      background: `linear-gradient(45deg, transparent 30%, ${theme.contrasts.successSecondary} 30%, ${theme.contrasts.successSecondary} 70%, transparent 70%)`,
      backgroundSize: `${stripeWidth} 100%`,
      animation: animationDisabled ? undefined : `${stripes(stripeWidth)} 2s linear infinite`,
      transform: animationDisabled ? undefined : 'scale(-1)',
    },
  });
};

const dotAnimation = keyframes({
  '0%': {
    transform: 'scale(0)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

export const dotStyle = ({ theme, size }: ThemeProps<{ size: number }>) => css({
  animationName: dotAnimation,
  animationDuration: `${INIT_ANIMATION_DURATION_MS}ms`,
  animationIterationCount: 1,
  height: size,
  width: size,
  position: 'absolute',
  background: theme.lineColors.green,
  top: 0,
  left: 0,
  borderRadius: '50%',
});

const indeterminateAnimation = keyframes({
  from: {
    left: '-50%',
  },
  to: {
    left: '100%',
  },
});

export const indeterminate = css({
  animationName: indeterminateAnimation,
  animationDuration: '2s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  transformOrigin: '0% 50%',
  width: '50%',
});
