import { type Theme } from './theme.model';

export const lightTheme: Theme = {
  name: 'light',
  accordionColors: {
    borderColor: '#CFD7DC',
    countBackground: '#383E42',
    countColor: '#FFFFFF',
    headingBackground: '#EFF3F6',
    headingBackgroundActive: '#AED3EE',
    headingBackgroundActiveHover: '#96c6e9',
    headingBackgroundHover: '#CFD7DC',
    itemBackground: '#FFFFFF',
    itemBackgroundHover: '#EFF3F6',
    headingBorderActive: '#0078CE',
  },
  alertColors: {
    dangerPrimary: '#DC3545',
    dangerSecondary: '#FBE5E7',
    successPrimary: '#28A745',
    successSecondary: '#EDF7E9',
    infoPrimary: '#0078CE',
    infoSecondary: '#E0F2FF',
    warningPrimary: '#E76900',
    warningSecondary: '#FFEEE0',
  },
  backgroundColors: {
    secondaryAlternative: '#CFD7DC',
    primary: '#FFFFFF',
    primaryDragged: 'rgba(255, 255, 255, 0.8)',
    secondary: '#EFF3F6',
    secondaryHover: '#E6E6E6',
    tertiary: '#CFD7DC',
    quaternary: '#0078CE',
    quinary: '#FFFFFF',
    highlight: '#AED3EE',
    highlightHover: '#96c6e9',
    infoBackground: '#DFF0D8',
    overlay: 'rgba(255, 255, 255, 0.8)',
    warning: '#E76900',
    warningHover: '#FF9033',
  },
  borderColors: {
    activeItem: '#0078CE',
    error: '#DC3545',
    highContrast: '#324552',
    highlight: '#111B22',
    primary: '#CFD7DC',
    primaryFocused: '#57a5de',
    quaternary: '#0C3756',
    secondary: '#DAE2E8',
    tertiary: '#424A51',
    warningHover: '#A84C00',
  },
  buttonColors: {
    defaultBorder: '#566976',
    primaryBackground: '#0078CE',
    primaryBackgroundOnHover: '#3195de',
    primaryBorderOnHover: '#2e69ae',
    primaryText: '#FFFFFF',
    primaryBorder: '#566976',
    tertiaryBackground: '#FFFFFF',
    tertiaryBorderOnHover: '#2e69ae',
    tertiaryText: '#0078CE',
    tertiaryActiveText: '#FFFFFF',
    tertiaryBorder: '#0078CE',
    quaternaryBackground: '#FFFFFF',
    quaternaryBorder: '#566976',
    quaternaryBorderOnHover: '#324552',
    quaternaryBackgroundOnHover: '#566976',
    quaternaryText: '#566976',
    quaternaryActiveText: '#FFFFFF',
    successBackground: '#28A745',
    successBorderOnHover: '#1C5E21',
    successActiveBackground: '#128C2D',
    successText: '#FFFFFF',
    successBorder: '#566976',
    dangerBackground: '#BD1818',
    dangerBackgroundHover: '#DC3545',
    dangerBorderOnHover: '#812323',
    dangerText: '#FFFFFF',
    upArrowBackground: '#4098d0',
    upArrowText: '#FFFFFF',
    secondaryActiveBackground: '#3E5362',
    secondaryBackground: '#7D92A1',
    secondaryBackgroundHover: '#566976',
    secondaryBorderOnHover: '#3C3C3C',
    secondaryText: '#FFFFFF',
    inheritedBackgroundOnHover: '#d2ecfd',
  },
  checkboxColors: {
    checkedFill: '#0078CE',
    checkedFillHover: 'rgba(0, 120, 206, 0.2)',
    checkedText: '#FFFFFF',
    uncheckedFill: '#FFFFFF',
    uncheckedBorder: '#0078CE',
    uncheckedBorderSecondary: '#324552',
    uncheckedBorderTertiary: '#7D92A1',
  },
  colorPickerColors: {
    backgroundColor: '#EFF3F6',
    backgroundColorInput: '#FFFFFF',
    defaultSelectedColor: '#FFFFFF',
    separatorColor: '#EEEEEE',
    shadowColorInput: '#CCCFFF',
    shadowColorPrimary: 'rgba(0, 0, 0, 0.15)',
    shadowColorSecondary: 'rgba(0, 0, 0, 0.25)',
    textColor: '#324552',
  },
  directionsPanelColors: {
    exportFileButtonIconBackground: '#FFFFFF',
    routeStepBackground: '#EFF3F6',
  },
  dropdownColors: {
    optionBackground: '#E6EEF4',
    optionBackgroundHover: '#CCD4DA',
    shadow: 'rgba(0, 0, 0, 0.3)',
    triggerBackground: '#EFF3F6',
    triggerBackgroundHover: '#DBDFE1',
  },
  contrasts: {
    selectedAccent2: '#0078CE',
    selectedAccent2Faded: 'rgba(0, 120, 206, 0.3)',
    selectedAccent2Background: '#C7E7FF',
    success: '#28A745',
    successSecondary: '#94D3A2',
    successBackground: '#DFF0D8',
  },
  iconColors: {
    backgroundPrimary: '#FFFFFF',
    backgroundPrimaryHover: '#CFD7DC',
    borderPrimaryHover: '#7D92A1',
    backgroundPrimaryInverted: '#566976',
    backgroundPrimaryInvertedHover: '#324552',
    borderPrimaryInvertedHover: '#000000',
    backgroundSecondary: '#EFF3F6',
    backgroundSecondaryHover: '#CFD7DC',
    borderSecondaryHover: '#324552',
    backgroundSecondaryInverted: '#7D92A1',
    backgroundSecondaryInvertedHover: '#566976',
    borderSecondaryInvertedHover: '#000000',
    backgroundTertiary: '#FFFFFF',
    backgroundTertiaryHover: '#CFD7DC',
    borderTertiaryHover: '#324552',
    backgroundQuaternary: '#0078CE',
    backgroundQuaternaryHover: '#3195de',
    borderQuaternaryHover: '#2e69ae',
    backgroundDangerInverted: '#BD1818',
    backgroundDangerInvertedHover: '#DC3545',
    borderDangerInvertedHover: '#812323',
    backgroundSuccessInverted: '#28A745',
    backgroundSuccessInvertedHover: '#128C2D',
    borderSuccessInvertedHover: '#1C5E21',
    focused: '#57a5de',
    primary: '#7D92A1',
    primaryInverted: '#FFFFFF',
    secondary: '#566976',
    secondaryHover: '#324552',
    secondaryInverted: '#FFFFFF',
    tertiary: '#FFFFFF',
    quaternary: '#FFFFFF',
    contrast: '#0078CE',
    highContrast: '#324552',
    lowContrast: '#7D92A1',
    highlight: '#0C3756',
    danger: '#DC3545',
    accentedBackground: '#0078CE',
    accentedBorderHover: '#2e69ae',
    accented: '#FFFFFF',
    success: '#28A745',
    warning: '#FCE141',
    quinary: '#3196DE',
    active: '#B3D7F1',
  },
  lineColors: {
    basePrimary: '#CFD7DC',
    contrastGreen: '#17E915',
    green: '#28A745',
    blue: '#0078CE',
  },
  modalColors: {
    contentBackground: '#FFFFFF',
    contentColor: '#324552',
    overlayBackground: 'rgb(0, 0, 0, .7)',
    closeButton: '#FFFFFF',
    closeButtonBackground: '#111B22',
    titleText: '#FFFFFF',
    titleBackground: '#111B22',
    footerBackground: '#FFFFFF',
    footerText: '#324552',
  },
  percentageIndicatorColors: {
    centerText: '#5A6D7B',
    donutBase: '#0078CE',
    donutEntry: '#9db0bf',
    labelBackground: '#FFFFFF',
    labelTextValueEntry: '#324552',
    labelTextKeyEntry: '#0078CE',
  },
  radioColors: {
    checkedBorder: '#0078CE',
    checkedFillHover: 'rgba(0, 120, 206, 0.2)',
    uncheckedBorder: '#7D92A1',
    checkedFill: 'none',
    uncheckedFill: 'none',
    text: '#324552',
    icon: '#7D92A1',
  },
  shadowColors: {
    primary: 'rgba(18, 27, 33, 0.5)',
    secondary: 'rgba(105, 112, 117, 0.2)',
  },
  sliderColors: {
    railBackground: '#CFD7DC',
    trackBackground: '#0078CE',
    handleShadow: 'rgba(18, 27, 33, 0.5)',
    dotColor: '#FFF',
    activeDotColor: '#FFF',
    dotBorder: '#CFD7DC',
    activeDotBorder: '#0078CE',
    contrastColor: '#31AAB7',
    danger: '#DC3545',
  },
  roundButtonStyle: {
    boxShadow: '0 3px 10px 1px #CFD7DC',
  },
  switchColors: {
    primaryBackground: '#FFFFFF',
    primaryBorder: '#ACBAC3',
    primaryOn: '#324552',
    secondaryOn: '#0078CE',
    primaryOff: '#566976',
  },
  textColors: {
    primary: '#324552',
    secondary: '#566976',
    tertiary: '#7D92A1',
    quaternary: '#FFFFFF',
    link: '#0078CE',
    linkOnHover: '#3195de',
    danger: '#DC3545',
    dangerOnHover: '#BD1818',
    warning: '#E76900',
    disabled: '#7D92A1',
    success: '#28A745',
    successOnHover: '#128C2D',
    contrast: '#000000',
    light: '#324552',
    info: '#0078CE',
  },
  imageGalleryColors: {
    navigationArrowsColor: '#324552',
  },
  scrollbarColors: {
    thumbColor: '#7D92A1',
    trackColor: '#CFD7DC',
  },
  tooltipColors: {
    backgroundColor: '#111B22',
    textColor: '#FFFFFF',
  },
  tagColors: {
    backgroundColor: 'rgba(0, 120, 206, 0.2)',
    textColor: '#0078CE',
    textColorOnHover: '#3195DE',
    primaryBackgroundColor: '#0078CE',
    primaryTextColor: '#FFFFFF',
    primaryTextColorOnHover: '#7D92A1',
    secondaryTextColor: '#FFFFFF',
    secondaryBackgroundColor: 'rgba(0, 120, 206, 0.7)',
  },
  tabberColors: {
    activeTabColor: '#0078CE',
    inActiveTabColor: '#7D92A1',
    activeTabBorderColor: '#0078CE',
  },
  loadingOverlay: {
    backgroundColor: 'rgba(76, 77, 82, 0.5)',
    textColor: '#FFFFFF',
  },
  opacity: {
    disabled: 0.6,
  },
  invoicedColors: {
    background: '#2E3438',
    text: '#FFFFFF',
  },
};
