import { type FileAttachmentId } from '~/_shared/types/file.types';
import { type DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import {
  MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS,
  MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM,
  MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM,
} from './drawingItems.actionTypes';
import { type DrawingItem } from './drawingItems.state';

export const drawingItemsRemoveAllItems = () => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS,
}) as const;

export const drawingItemsRemoveAllItemsOfType = (drawingType: DrawingTool) => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ALL_ITEMS_OF_TYPE,
  payload: {
    type: drawingType,
  },
}) as const;

export const drawingItemsRemoveItem = (id: Uuid) => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_REMOVE_ITEM,
  payload: {
    id,
  },
}) as const;

export const drawingItemsAddItem = (id: Uuid, drawingItem: DrawingItem) => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_ADD_ITEM,
  payload: {
    id,
    drawingItem,
  },
}) as const;

export const drawingItemsUpdateItem = (id: Uuid, drawingItem: DrawingItem) => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_UPDATE_ITEM,
  payload: {
    id,
    drawingItem,
  },
}) as const;

export const drawingItemsRemoveFileAttachments = (attachmentIds: ReadonlyArray<FileAttachmentId>) => ({
  type: MAP_SETTINGS_DRAWING_ITEMS_REMOVE_FILE_ATTACHMENTS,
  payload: {
    attachmentIds,
  },
}) as const;
