import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { matchUpDataAllDataSections } from '~/_shared/components/matchUpDataModal/matchUpDataModal.component';
import { type ColumnRole } from '../../_shared/types/columnRole.enum';
import { type SpreadsheetMatchupData } from '../../_shared/types/spreadsheetData/matchupData';
import { select } from '../../_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { ModalType } from '../../modal/modalType.enum';
import {
  getSpreadsheetMatchup,
  updateSpreadsheetMatchup,
  type UpdateSpreadsheetMatchupRequest,
} from '../../spreadsheet/spreadsheet.repository';
import { errorPageSetError } from '../errorPage/errorPage.actionCreators';
import { ErrorPageType } from '../errorPage/errorPage.state';
import { geocodingStartTracking } from '../geocoding/geocoding.actionCreators';
import { cancelOnMapReset } from '../map/map.sagas';
import { type MapInfoAction } from '../mapInfo/mapInfo.action';
import { MAP_INFO_FETCH_DATA_SUCCESS } from '../mapInfo/mapInfo.actionTypes';
import { openModal } from '../modal/modal.actionCreators';
import { isMapPresentationalSelector } from '../selectors/useMapInfoSelectors';
import { primarySpreadsheetRealSpreadsheetsSelector } from '../selectors/usePrimarySpreadsheetRealSpreadsheets';
import { type MatchupDataAction } from './matchupData.action';
import {
  matchupFetchDataError,
  matchupFetchDataRequest,
  matchupFetchDataSuccess,
  matchupUpdateError,
  matchupUpdateSuccess,
} from './matchupData.actionCreators';
import {
  MATCHUP_FETCH_DATA_REQUEST, MATCHUP_REFETCH_DATA,
  MATCHUP_UPDATE_REQUEST,
} from './matchupData.actionTypes';

export function* matchupDataSagas() {
  yield takeLatest(MAP_INFO_FETCH_DATA_SUCCESS, onMapInfoFetchDataSuccess);
  yield takeLatest(MATCHUP_FETCH_DATA_REQUEST, cancelOnMapReset(fetchMatchupData));
  yield takeLatest(MATCHUP_UPDATE_REQUEST, cancelOnMapReset(updateMatchupData));
  yield takeLatest(MATCHUP_REFETCH_DATA, cancelOnMapReset(refetchMatchupData));
}

function* fetchMatchupData(action: PickAction<MatchupDataAction, typeof MATCHUP_FETCH_DATA_REQUEST>) {
  try {
    const matchup: {data: {[spreadsheetId: number]: SpreadsheetMatchupData}} = yield call(
      getSpreadsheetMatchup,
      action.payload.clientId,
      [{
        spreadsheet_id: action.payload.spreadsheetVirtualId,
        map_id: action.payload.mapId,
        guess: action.payload.isMatchupRequired,
      }],
    );

    const matchUpData = matchup.data[action.payload.spreadsheetVirtualId];

    if (!matchUpData) {
      throw new Error('Missing matchup data');
    }

    yield put(matchupFetchDataSuccess(
      action.payload.spreadsheetVirtualId,
      matchUpData,
      action.payload.isMatchupRequired
    ));

    const isMapPresentational: boolean = yield select(isMapPresentationalSelector);

    if (action.payload.isMatchupRequired) {
      if (isMapPresentational) {
        yield put(errorPageSetError(ErrorPageType.PublicMapUnavailable));
      }
      else {
        yield put(openModal(ModalType.MatchupData, { ensureLocationDataIsSelected: true, sections: matchUpDataAllDataSections }));
      }
    }
  }
  catch (e) {
    yield put(matchupFetchDataError(action.payload.spreadsheetVirtualId, e));
  }
}

function* refetchMatchupData(action: PickAction<MatchupDataAction, typeof MATCHUP_REFETCH_DATA>) {
  const clientId: number | null = yield select(state => state.userData.clientId);
  const mapId: number | null = yield select(state => state.map.mapId);

  if (!clientId || !mapId) {
    return;
  }

  yield put(matchupFetchDataRequest(clientId, action.payload.virtualSpreadsheetId, mapId, false));
}

function* onMapInfoFetchDataSuccess(action: PickAction<MapInfoAction, typeof MAP_INFO_FETCH_DATA_SUCCESS>) {
  const clientId: number | null = yield select(state => state.userData.clientId);

  if (clientId === null || action.payload.data.spreadsheets.length === 0) {
    return;
  }

  for (const spreadsheet of action.payload.data.spreadsheets) {
    // show matchup modal only if is_matchup_required, only for main spreadsheet
    yield put(
      matchupFetchDataRequest(
        clientId,
        spreadsheet.spreadSheetId,
        action.payload.data.id,
        action.payload.data.isMatchupRequired && spreadsheet.isPrimary
      )
    );
  }
}

function* updateMatchupData(action: PickAction<MatchupDataAction, typeof MATCHUP_UPDATE_REQUEST>) {
  const clientId: number | null = yield select(state => state.userData.clientId);
  const userId: number | null = yield select(state => state.userData.id);
  const mapId: number | undefined = yield select(state => state.map.mapInfo.data?.id);
  const primarySpreadsheetRealSpreadsheetIds: { id: number }[] | null = yield select(primarySpreadsheetRealSpreadsheetsSelector);

  if (mapId === undefined || clientId === null) {
    return;
  }

  const request: Mutable<UpdateSpreadsheetMatchupRequest> = {};
  for (const key in action.payload.matchup) {
    if (action.payload.matchup.hasOwnProperty(key)) {
      const value = action.payload.matchup[key as ColumnRole][0];
      if (value !== null) {
        request[key as ColumnRole] = value;
      }
    }
  }

  try {
    yield call(updateSpreadsheetMatchup, clientId, action.payload.spreadsheetVirtualId, mapId, request);

    const matchup: {data: {[spreadsheetId: number]: SpreadsheetMatchupData}} = yield call(
      getSpreadsheetMatchup,
      clientId,
      [{
        spreadsheet_id: action.payload.spreadsheetVirtualId,
        map_id: mapId,
        guess: false,
      }],
    );

    const matchUpData = matchup.data[action.payload.spreadsheetVirtualId];

    if (!matchUpData) {
      throw new Error('Missing matchup data');
    }

    yield put(matchupUpdateSuccess(
      action.payload.spreadsheetVirtualId,
      matchUpData
    ));

    // it might take some time to receive GeocodingStarted event from the backend, so let's initialize the progress to 0 right now
    if (primarySpreadsheetRealSpreadsheetIds && userId) {
      for (const realSpreadsheet of primarySpreadsheetRealSpreadsheetIds) {
        yield put(geocodingStartTracking(realSpreadsheet.id, userId, 0));
      }
    }
  }
  catch (e) {
    yield put(matchupUpdateError(action.payload.spreadsheetVirtualId, e));
  }
}
