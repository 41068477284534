import { apiPost } from '../../_shared/utils/api/api.helpers';

export const startGeocoding = (clientId: number, realSpreadsheetId: number): Promise<{ status: boolean }> => {
  const requestUrl = `/api/clients/${clientId}/geocoding/${realSpreadsheetId}/start`;

  return apiPost(requestUrl);
};

export const pauseGeocoding = (clientId: number, realSpreadsheetId: number): Promise<{ status: boolean }> => {
  const requestUrl = `/api/clients/${clientId}/geocoding/${realSpreadsheetId}/pause`;

  return apiPost(requestUrl);
};
