import { type FC } from 'react';
import { useDrawingEditSelectedDrawingLatestSnapshot } from '../../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { DrawingToolDetailsComponent } from './drawingToolDetails.component';

type DrawingToolDetailsContainerProps = {
  onClose: () => void;
};

export const DrawingToolDetailsContainer: FC<DrawingToolDetailsContainerProps> = (props) => {
  const drawingSnapshot = useDrawingEditSelectedDrawingLatestSnapshot();
  const { onClose } = props;

  if (!drawingSnapshot) {
    return null;
  }

  return (
    <DrawingToolDetailsComponent
      key={drawingSnapshot.value.id}
      selectedDrawingTool={drawingSnapshot.type}
      isLoading={false}
      onClose={onClose}
    />
  );
};
