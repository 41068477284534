import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import { useTranslation } from '~/_shared/utils/hooks';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { DrawingToolContextMenuComponent } from '~/drawingTool/contextMenu/drawingToolContextMenu.component';
import { useMap } from '~/map/map/mapContext';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';
import { hideDrawingItemsContextMenu } from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.actionCreators';
import { useDrawingItemsContextMenuActiveStateSelector } from '~/store/frontendState/drawingItemsContextMenu/drawingItemsContextMenu.selectors';
import { drawingItemsUpdateItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { updateDrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.helpers';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

export const DrawingToolContextMenuContainer: FC = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const isPresentationalView = useIsMapPresentationalSelector();
  const drawingItemsContextMenuState = useDrawingItemsContextMenuActiveStateSelector();
  const mapInteractionActive = useIsMapInteractionActive();
  const { fromLatLngToContainerPixel } = useProjectionOverlay(map);
  const [t] = useTranslation();

  const actionsAllowed = !mapInteractionActive && !isPresentationalView;

  const menuPosition = useMemo(() => {
    const positionKey = createUuid();

    if (!drawingItemsContextMenuState) {
      return null;
    }

    const latToPx = fromLatLngToContainerPixel(drawingItemsContextMenuState.position);

    return latToPx ? {
      key: positionKey,
      x: latToPx.x,
      y: latToPx.y,
    } : null;
  }, [fromLatLngToContainerPixel, drawingItemsContextMenuState]);

  const onHide = useCallback(() => {
    dispatch(hideDrawingItemsContextMenu());
  }, [dispatch]);

  if (!actionsAllowed || !drawingItemsContextMenuState || !menuPosition) {
    return null;
  }

  const itemPlacement = drawingItemsContextMenuState.drawingItem.value.placement ?? DrawingItemPlacement.Default;

  const menuItems = [{
    label: t('Bring to front'),
    isActive: itemPlacement !== DrawingItemPlacement.Top,
    onClick: () => {
      const updatedDrawingItem = updateDrawingItemPlacement(drawingItemsContextMenuState.drawingItem, DrawingItemPlacement.Top);
      dispatch(drawingItemsUpdateItem(updatedDrawingItem.value.id, updatedDrawingItem));
    },
  }, {
    label: itemPlacement === DrawingItemPlacement.Bottom ? t('Bring to the middle') : t('Send to the middle'),
    isActive: itemPlacement !== DrawingItemPlacement.Default,
    onClick: () => {
      const updatedDrawingItem = updateDrawingItemPlacement(drawingItemsContextMenuState.drawingItem, DrawingItemPlacement.Default);
      dispatch(drawingItemsUpdateItem(updatedDrawingItem.value.id, updatedDrawingItem));
    },
  }, {
    label: t('Send to back'),
    isActive: itemPlacement !== DrawingItemPlacement.Bottom,
    onClick: () => {
      const updatedDrawingItem = updateDrawingItemPlacement(drawingItemsContextMenuState.drawingItem, DrawingItemPlacement.Bottom);
      dispatch(drawingItemsUpdateItem(updatedDrawingItem.value.id, updatedDrawingItem));
    },
  }];

  return (
    <DrawingToolContextMenuComponent
      key={menuPosition.key} // reload component when menu position changes
      map={map}
      menuPosition={menuPosition}
      onHide={onHide}
      items={menuItems}
    />
  );
};
