import { css } from '@emotion/react';
import {
  type FC,
  useCallback, useMemo,
} from 'react';
import { useDrop } from 'react-dnd';
import { getAlternatingColor } from '~/_shared/baseComponents/alternatingStripes/alternatingStripes';
import {
  ScrollBarComponent, ScrollbarType,
} from '../../_shared/components/scrollbar/scrollbar.component';
import { DndType } from '../../_shared/constants/dndType.enum';
import { useTheme } from '../../_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { compareSpreadsheetColumnIds } from '../../_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { type ThemeProps } from '../../_shared/types/themeProps';
import { BASIC_PANEL_WIDTH } from '../../sidebar/sidebarApps/rightSidebar/rightSidebarContent.constants';
import { LOCATION_PANEL_DATA_CLASSNAME } from '../customizeLocationPanel.constants';
import { getColumnItemById } from './locationData.helpers';
import { type BubbleItemMap } from './locationData.types';
import { BubbleItemDragLayerComponent } from './locationDataItem/bubbleItemDragLayer.component';
import { DraggableItemComponent } from './locationDataItem/draggableItem.component';
import { type ColumnStyleSettingsData } from './panelSettings.types';

const wrapperStyle = ({ width }: { width: number }) => css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minWidth: `${width}px`,
  width: '100%',
});

const dropAreaStyle = css({
  position: 'relative',
  height: '100%',
  width: '100%',
});

const dataWrapperStyle = css({
  boxSizing: 'border-box',
  width: '100%',
});

const bubbleItemStyle = ({ theme }: ThemeProps) => css({
  '&:nth-of-type(2n)': {
    backgroundColor: getAlternatingColor(theme),
  },
});

type LocationDataProps = Readonly<{
  allBasicItemsOrdered: ReadonlyArray<SpreadsheetColumnId>;
  allContactItemsOrdered: ReadonlyArray<SpreadsheetColumnId>;
  bubbleItems: BubbleItemMap;
  isBeingEdited: boolean;
  isMobileScreen: boolean;
  retiredOrdered: ReadonlyArray<SpreadsheetColumnId>;
  selectedItemId: SpreadsheetColumnId | null;
  bulkStyles: ColumnStyleSettingsData;

  onDrop: (sourceId: SpreadsheetColumnId, targetId: SpreadsheetColumnId) => void;
  onSelectItem: (itemId: SpreadsheetColumnId | null) => void;
  onHideItem: (columnId: SpreadsheetColumnId) => void;
  onShowItem: (columnId: SpreadsheetColumnId) => void;
}>;

export const LocationDataComponent: FC<LocationDataProps> = ({
  allBasicItemsOrdered,
  allContactItemsOrdered,
  bubbleItems,
  bulkStyles,
  isBeingEdited,
  isMobileScreen,
  onDrop,
  onHideItem,
  onSelectItem,
  onShowItem,
  retiredOrdered,
  selectedItemId,
  ...restProps
}) => {
  const theme = useTheme();
  const [, drop] = useDrop<{ type: string; id: SpreadsheetColumnId }, void, unknown>({
    accept: [DndType.LocalizationContactItem, DndType.LocalizationBasicItem],
  });

  const handleItemSelect = useCallback((itemId: SpreadsheetColumnId | null) => {
    // we need that on desktop only, on mobile devices it causes
    // an issue where opening a menu won't close if clicked on locationData component
    if (isMobileScreen) {
      return;
    }

    onSelectItem(itemId);
  }, [onSelectItem, isMobileScreen]);

  const getBubbleItems = useCallback((ids: ReadonlyArray<SpreadsheetColumnId>, alternateColours: boolean) =>
    ids.map((id) => {
      const bubbleItem = getColumnItemById(bubbleItems, id);
      const isVisible = !retiredOrdered.find(item => compareSpreadsheetColumnIds(item, id));

      if (!isVisible && !isBeingEdited) {
        return;
      }

      return bubbleItem ? (
        <div
          css={alternateColours ? bubbleItemStyle({ theme }) : null}
          className={LOCATION_PANEL_DATA_CLASSNAME}
          key={'' + id.spreadsheetId + '_' + id.columnId}
          onMouseDown={() => handleItemSelect(id)}
        >
          <DraggableItemComponent
            isDraggable={isBeingEdited}
            isSelected={isBeingEdited && selectedItemId === id}
            item={bubbleItem}
            bulkStyles={bulkStyles}
            onDrop={onDrop}
            isVisible={isVisible}
            showVisibilityIcon={isBeingEdited}
            onVisibilityIconClick={() => {
              if (isVisible) {
                onHideItem(id);
              }
              else {
                onShowItem(id);
              }
            }}
          />
        </div>
      ) : undefined;
    }),
  [onDrop, handleItemSelect, isBeingEdited, onShowItem, onHideItem, bubbleItems,
    selectedItemId, retiredOrdered, theme, bulkStyles]);

  const commonBody = useMemo(() => (
    <>
      <BubbleItemDragLayerComponent bulkStyles={bulkStyles} />
      <div css={dataWrapperStyle}>
        {allContactItemsOrdered.length ? (
          <div>
            {getBubbleItems(allContactItemsOrdered, false)}
          </div>
        ) : null}

        {getBubbleItems(allBasicItemsOrdered, true)}
      </div>
    </>
  ), [getBubbleItems, allBasicItemsOrdered, allContactItemsOrdered, bulkStyles]);

  return (
    <div {...restProps} css={wrapperStyle({ width: BASIC_PANEL_WIDTH })}>
      <div
        css={dropAreaStyle}
        ref={el => {
          drop(el);
        }}
      >
        {!isMobileScreen ? (
          <ScrollBarComponent type={ScrollbarType.Vertical} >
            {commonBody}
          </ScrollBarComponent>
        ) : (
          <>{commonBody}</>
        )}
      </div>
    </div>
  );
};
