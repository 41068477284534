import {
  useCallback,
  useEffect, useState,
} from 'react';
import { useTranslation } from '../hooks';
import { usePrevious } from '../hooks/usePrevious';
import { isTextEmpty } from '../text/text.helpers';
import {
  type FormFieldType, validateFormFieldOfType,
} from './formValidation.helpers';

type FormFieldValidationArguments = {
  type: FormFieldType;
  confirmValue?: string;
  initialValue?: string;
  additionalErrors?: ReadonlyArray<string>;
  requiredFieldErrorMessage?: string;
};

export const useFormFieldValidation = ({ type, initialValue, confirmValue, additionalErrors, requiredFieldErrorMessage }: FormFieldValidationArguments) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [value, setValue] = useState(initialValue ?? '');
  const prevConfirmValue = usePrevious(confirmValue);
  const [t] = useTranslation();

  const validateValue = useCallback((newValue: string) => {
    setErrors(validateFormFieldOfType(type, newValue, confirmValue ?? null, t, requiredFieldErrorMessage));
  }, [type, confirmValue, t, requiredFieldErrorMessage]);

  const onBlur = () => {
    validateValue(value);
  };

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (errors.length > 0) {
      validateValue(newValue);
    }
  };

  // additional errors like API errors
  useEffect(() => {
    if (!additionalErrors || additionalErrors.length === 0) {
      return;
    }

    setErrors([...additionalErrors]);
  }, [additionalErrors]);

  // for confirm inputs like email or password. If we have the confirm password typed and the email field is changed then we need to revalidate the field
  useEffect(() => {
    if (isTextEmpty(value) || prevConfirmValue === confirmValue) {
      return;
    }

    validateValue(value);
  }, [confirmValue, value, prevConfirmValue, validateValue]);

  return {
    errors,
    onBlur,
    onChange,
    validateValue,
    value,
  };
};
