import { type ThemeAction } from './theme.action';
import { THEME_CHANGE_TYPE } from './theme.actionTypes';
import { restoreThemeType } from './theme.helpers';
import { type ThemeState } from './theme.state';

export const initialThemeState: ThemeState = {
  ...restoreThemeType(),
};

export const themeReducer = (state: ThemeState = initialThemeState, action: ThemeAction): ThemeState => {
  switch (action.type) {
    case THEME_CHANGE_TYPE: {
      if (state.isForced) {
        return state;
      }

      return {
        ...state,
        type: action.payload.type,
      };
    }

    default: {
      return state;
    }
  }
};
