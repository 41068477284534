import { css } from '@emotion/react';
import { type ReactNode } from 'react';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { LimitNumberOfLinesComponent } from '~/_shared/components/limitNumberOfLines/limitNumberOfLines.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  FilterConjunction,
  renderFilterConjunction,
} from './filterConjunction.enum';

export type Attribute<T> = Readonly<{
  id: string;
  value: T;
}>;

export type AttributeFilterSettings<T> = Readonly<{
  attributes: ReadonlyArray<Attribute<T>>;
  renderAttribute: (value: T) => ReactNode;
}>;

export type AttributeFilterValues = Readonly<{
  conjunction: FilterConjunction;
  selectedAttributeIds: Record<string, 1>;
  onChange: (newSelectedIds: Record<string, 1>) => void;
  onConjunctionChange: (newConnector: FilterConjunction) => void;
}>;

type AttributeFilterProps<T> = AttributeFilterSettings<T> & AttributeFilterValues;

const lineSize = 45;
const attributeLineStyle = ({ theme }: ThemeProps) => css({
  alignItems: 'center',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  display: 'flex',
  height: lineSize,
  padding: '0 8px',
});

const dropdownTriggerStyle = css({
  height: lineSize,
});

const dropdownContainerStyle = css({
  padding: 10,
});

const checkboxTextStyle = css({
  marginLeft: 8,
  fontSize: 14,
  lineHeight: 1.15,
});

export const AttributeFilterComponent = <T extends unknown>(props: AttributeFilterProps<T>) => {
  const theme = useTheme();
  const [t] = useTranslation();

  const onChange = (attributeId: string) => {
    const newSelectedAttributeIds = {
      ...props.selectedAttributeIds,
    };

    if (newSelectedAttributeIds[attributeId]) {
      delete newSelectedAttributeIds[attributeId];
    }
    else {
      newSelectedAttributeIds[attributeId] = 1;
    }

    props.onChange(newSelectedAttributeIds);
  };

  return (
    <div>
      <div css={dropdownContainerStyle}>
        <RegularDropdownComponent
          triggerStyle={dropdownTriggerStyle}
          onChange={props.onConjunctionChange}
          value={props.conjunction}
          options={Object.values(FilterConjunction).map(value => ({ value, name: renderFilterConjunction(value, t) }))}
        />
      </div>
      {props.attributes.map(attr => (
        <div
          key={attr.id}
          css={attributeLineStyle({ theme })}
        >
          <CheckboxComponent
            isChecked={props.selectedAttributeIds[attr.id] === 1}
            checkedSetter={() => onChange(attr.id)}
            textStyle={checkboxTextStyle}
            text={(
              <LimitNumberOfLinesComponent maxNumberOfLines={2}>
                {props.renderAttribute(attr.value)}
              </LimitNumberOfLinesComponent>
            )}
          />
        </div>
      ))}
    </div>
  );
};
