import {
  type FC,
  memo, useEffect, useState,
} from 'react';
import * as geometry from 'spherical-geometry-js';
import { type LatLng } from '../../../../_shared/types/latLng';
import { useMapObjectDragAndDrop } from '../../../../_shared/utils/hooks/useMapObjectDragAndDrop';
import { BoundingBox } from '../../boundingBox';
import {
  MapObjectLabelLetterSpacing, MapObjectLabelTextSize,
} from '../mapObject.types';
import { type MapObjectOutlineInstance } from '../mapOutline/mapOutlineModel';
import { useMapObjectContext } from '../private/mapObjectContext';
import { useMapShapeContext } from './private/mapShapeContext';

const fillColor = [88, 125, 215, 1] as const;
const borderColor = [0, 0, 0, 1] as const;

export const calculatePolygonCenter = (outlines: MapObjectOutlineInstance[]): LatLng => {
  // There is a bug in the geometry npm package that requires us to
  // pass at least one point to constructor otherwise the calculation is wrong
  const bounds = new geometry.LatLngBounds(outlines[0]);
  outlines.forEach(outline => bounds.extend(outline));

  const center = bounds.getCenter();
  return { lat: center.lat(), lng: center.lng() };
};

export type MapShapePolygonVisualConfig = {
  readonly fillColor: WebglColor;
  readonly borderWidth?: number;
  readonly borderColor?: WebglColor;
};

type MapShapePolygonProps = {
  label?: string;
  visuals: MapShapePolygonVisualConfig;

  onClick?: (event: MapObjectClickEventArgs) => void;
  onDragEnd?: () => void;
  onDragMove?: (latLng: LatLng) => void;
  onDragStart?: (latLng: LatLng) => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  onRightClick?: (event: MapObjectClickEventArgs) => void;
};

const labelColor: WebglColor = [255, 255, 255, 1];

const MapShapePolygon: FC<MapShapePolygonProps> = ({
  label, visuals, onClick, onMouseOut, onMouseOver,
  onDragMove, onDragStart, onDragEnd, onRightClick,
}) => {
  const [mapPolygon, setMapPolygon] = useState<WebglOverlayPolygon | null>(null);
  const { manager, zIndex } = useMapObjectContext();
  const { shape } = useMapShapeContext();

  useMapObjectDragAndDrop(() => ({
    map: manager.map,
    mapObject: mapPolygon,
    onDragMove,
    onDragStart,
    onDragEnd,
    onMouseOut,
    onMouseOver,
  }), [manager.map, mapPolygon, onDragEnd, onDragMove, onDragStart, onMouseOut, onMouseOver]);

  useEffect(() => { // Draw polygon
    const polygon = manager.upsertPolygon(shape, { ...visuals, zIndex: zIndex.polygon, borderZIndex: zIndex.polygonBorder });

    if (!polygon) {
      return;
    }

    setMapPolygon(polygon);
  }, [manager, shape, visuals, zIndex.polygon, zIndex.polygonBorder]);

  useEffect(() => { // Show area label
    if (!label) {
      manager.removeLabel(shape.id);
      return;
    }

    const polygonCenter = calculatePolygonCenter(shape.outlines);
    const bounds = new BoundingBox();
    shape.outlines.forEach(outline => bounds.extend(outline));

    manager.upsertLabel(shape.id, {
      label: {
        text: {
          value: label,
          fillColor: labelColor,
          fontSize: MapObjectLabelTextSize.Default,
          letterSpacing: MapObjectLabelLetterSpacing.Default,
        },
        lat: polygonCenter.lat,
        lng: polygonCenter.lng,
        horizontalAnchor: 'center',
        verticalAnchor: 'center',
        boundaries: {
          enabled: true,
          ne: bounds.getNorthEast(),
          sw: bounds.getSouthWest(),
          minFontSize: MapObjectLabelTextSize.AreaLabelMin,
          maxFontSize: MapObjectLabelTextSize.Default,
        },
        padding: { b: 5, l: 5, r: 5, t: 5 },
        zIndex: zIndex.labelText,
      },
      callout: {
        fillColor,
        borderColor,
        borderWidth: 1,
        triangle: false,
        lat: polygonCenter.lat,
        lng: polygonCenter.lng,
        zIndex: zIndex.labelCallout,
        borderRadius: 10,
      },
    });
  }, [manager, label, shape.outlines, shape.id, zIndex.labelText, zIndex.labelCallout]);

  // Registers onClick
  useEffect(() => {
    if (!onClick) {
      return;
    }

    const mouseClickCleanup = manager.addPolygonEventListener(shape.id, 'click', onClick);

    return () => {
      mouseClickCleanup();
    };
  }, [manager, onClick, shape.id]);

  // Registers onRightClick
  useEffect(() => {
    if (!onRightClick) {
      return;
    }

    const mouseRightClickCleanup = manager.addPolygonEventListener(shape.id, 'rightclick', onRightClick);

    return () => {
      mouseRightClickCleanup();
    };
  }, [manager, onRightClick, shape.id]);

  useEffect(() => { // Clean up, removes polygon
    return () => {
      manager.removePolygon(shape.id);
      manager.removeLabel(shape.id);
    };
  }, [manager, shape.id]);

  return null;
};

const pureComponent = memo(MapShapePolygon);
export { pureComponent as MapShapePolygon };
