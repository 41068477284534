import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useIsNumericColumnCheck } from '../../../../../heatMap/panel/form/useIsNumericColumnCheck.hook';
import { type ModalProps } from '../../../../../modal/modalType.enum';
import {
  BoundaryTerritoryType, type CalculateBucketFunctionNumeric,
} from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { boundaryTerritoryGroupsUpdateRequest } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import { requestTerritoriesRefresh } from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import {
  useBoundaryTerritoryGroupsIsLoadingSelector,
  useBoundaryTerritoryGroupsSelector,
} from '../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { usePrimarySpreadsheetId } from '../../../../../store/selectors/usePrimarySpreadsheetId';
import {
  getBoundaryBucketTypeForBoundaryTerritoryType,
  getCalculateBucketFunction, getNumericCalculateBucketFunction,
} from '../boundaryTerritoryGroup.helpers';
import { useVerifyNumericFillData } from '../fill/useVerifyNumericFillData';
import { CustomizeBoundaryFillComponent } from './customizeBoundaryFill.component';

export type CustomizeBoundaryFillContainerProps = ModalProps<{
  boundaryTerritoryGroupId: number;
}>;

export const CustomizeBoundaryFillContainer: FC<CustomizeBoundaryFillContainerProps> = (props) => {
  const boundaryTerritoryGroups = useBoundaryTerritoryGroupsSelector();
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const boundaryTerritoryGroup = boundaryTerritoryGroups.find(
    group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId
  );
  const { checkIsNumeric, isLoading: isCheckNumericLoading } = useIsNumericColumnCheck();
  const { verifyNumericFillData } = useVerifyNumericFillData({ checkIsNumeric });
  const isBoundaryTerritoryGroupsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();
  const dispatch = useDispatch();

  if (!boundaryTerritoryGroup) {
    return null;
  }

  let boundaryFillData: SpreadsheetColumnId | null = null;

  if (primarySpreadsheetId !== null && boundaryTerritoryGroup.settings.bucketColumnId) {
    boundaryFillData = {
      columnId: boundaryTerritoryGroup.settings.bucketColumnId,
      spreadsheetId: primarySpreadsheetId,
    };
  }

  const updateBoundaryTerritoryGroup = async (
    boundaryTerritoryType: BoundaryTerritoryType,
    boundaryFillData: SpreadsheetColumnId | null,
    demographicId: string | null,
    numericCalculateBucketFunction: CalculateBucketFunctionNumeric,
  ) => {
    if (boundaryTerritoryType === BoundaryTerritoryType.Numeric) {
      if (!await verifyNumericFillData(boundaryFillData)) {
        return;
      }
    }

    dispatch(boundaryTerritoryGroupsUpdateRequest({
      ...boundaryTerritoryGroup,
      settings: {
        ...boundaryTerritoryGroup.settings,
        boundaryTerritoryType,
        calculateBucketFunction: getCalculateBucketFunction(
          boundaryTerritoryType,
          numericCalculateBucketFunction,
        ),
        bucketColumnId: boundaryFillData?.columnId ?? null,
        bucketType: getBoundaryBucketTypeForBoundaryTerritoryType(boundaryTerritoryType),
        demographicId,
        boundaryTerritories: requestTerritoriesRefresh(boundaryTerritoryGroup.settings.boundaryTerritories, []),
      },
    }, {
      onDone: () => props.onClose(),
    }));
  };

  return (
    <CustomizeBoundaryFillComponent
      boundaryFillData={boundaryFillData}
      boundaryGroupId={boundaryTerritoryGroup.boundaryGroupId}
      boundaryTerritoryType={boundaryTerritoryGroup.settings.boundaryTerritoryType}
      initialDemographicId={boundaryTerritoryGroup.settings.demographicId}
      initialNumericCalculateBucketFunction={getNumericCalculateBucketFunction(boundaryTerritoryGroup)}
      isLoading={isBoundaryTerritoryGroupsLoading || isCheckNumericLoading}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onUpdate={updateBoundaryTerritoryGroup}
    />
  );
};
