import {
  type FC, useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import {
  DrawingToolMode,
  useDrawingToolModeSelector,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.selectors';
import { drawingItemsAddItem } from '~/store/mapSettings/drawing/items/drawingItems.actionCreators';
import { DrawingItemPlacement } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { type DrawingIconMarkerSettingsState } from '~/store/mapSettings/drawing/settings/iconMarker/drawingIconMarkerSettings.state';
import { type DrawingToolManager } from '../drawingToolManager';

type DrawingToolIconMarkerCreateNewInstanceProps = Readonly<{
  manager: DrawingToolManager;
  settings: DrawingIconMarkerSettingsState;
}>;

export const DrawingToolIconMarkerCreateNewInstanceContainer: FC<DrawingToolIconMarkerCreateNewInstanceProps> = ({
  manager, settings,
}) => {
  const drawingToolMode = useDrawingToolModeSelector();
  const dispatch = useDispatch();

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (!event.latLng) {
      return;
    }
    const id = createUuid();
    dispatch(drawingItemsAddItem(
      id,
      {
        type: DrawingTool.IconMarker,
        value: {
          id,
          center: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
          placement: DrawingItemPlacement.Default,
          settings,
        },
      }));
  }, [dispatch, settings]);

  useEffect(() => {
    if (!onMapClick || drawingToolMode !== DrawingToolMode.DrawingItems) {
      return;
    }

    const clickListener = manager.addMapClickListener(onMapClick);

    return () => {
      clickListener.remove();
    };
  }, [onMapClick, manager, drawingToolMode]);

  return null;
};
