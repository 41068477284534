import { type ReactNode } from 'react';
import { suffixWrapperStyle } from './suffixContainer.styles';

export type SuffixContainerProps = {
  children: ReactNode;
  width?: number;
};

export const SuffixContainer = (props: SuffixContainerProps) => {
  return (
    <span css={suffixWrapperStyle({ width: props.width })}>
      {props.children}
    </span>
  );
};
