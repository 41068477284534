import {
  type FC, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../_shared/utils/hooks';
import { type ModalProps } from '../../modal/modalType.enum';
import { presentationalColumnsRestrictionsSetRestriction } from '../../store/presentationalColumnsRestrictions/presentationalColumnsRestrictions.actionCreators';
import { usePrimarySpreadsheetId } from '../../store/selectors/usePrimarySpreadsheetId';
import { PresentationalColumnsRestrictionsContainer } from '../presentationalColumnsRestrictions.container';
import { usePresentationalColumnsRestrictionsSettingsRows } from '../usePresentationalColumnsRestrictionsSettingsRows';

type PublicMapFiltersSettingsContainerProps = ModalProps<void>;

export const ExportDataFunctionalitiesColumnsDisplaySettingsContainer: FC<PublicMapFiltersSettingsContainerProps> = (props) => {
  const primarySpreadsheetId = usePrimarySpreadsheetId();
  const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState(primarySpreadsheetId);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { settingsRows: publicMapFiltersDisplaySettingsDataRows } =
    usePresentationalColumnsRestrictionsSettingsRows(selectedSpreadsheetId, 'export', t);

  const onToggleClick = (spreadsheetColumnId: SpreadsheetColumnId, newValue: boolean) => {
    dispatch(presentationalColumnsRestrictionsSetRestriction(spreadsheetColumnId, 'export', newValue));
  };

  useEffect(() => {
    setSelectedSpreadsheetId(primarySpreadsheetId);
  }, [primarySpreadsheetId]);

  return (
    <PresentationalColumnsRestrictionsContainer
      {...props}
      columnNameLabel={t('Your Data')}
      toggleColumnLabel={t('Allow in Export on Map')}
      modalTitle={t('Show/Hide Data Columns from Export functionalities')}
      settingsDataRows={publicMapFiltersDisplaySettingsDataRows}
      onToggleClick={onToggleClick}
      selectedSpreadsheetId={selectedSpreadsheetId}
      onSelectedSpreadsheetIdChange={setSelectedSpreadsheetId}
    />
  );
};
