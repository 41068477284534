import { css } from '@emotion/react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useEffect, useState,
} from 'react';
import { RegularDropdownComponent } from '~/_shared/baseComponents/dropdown';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { FormButtonComponent } from '../../../../../../_shared/components/formButton/formButton.component';
import {
  SelectedItemComponent, Size,
} from '../../../../../../_shared/components/selectedItem/selectedItem.component';
import { useTheme } from '../../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../../../_shared/utils/hooks';
import {
  FilterConjunction, renderFilterConjunction,
} from '../filterConjunction.enum';
import {
  isArrayTextPredicate, isNullableTextPredicate,
} from '../filters.helpers';
import {
  renderTextFilterPredicate, TextFilterPredicate,
} from './textFilterPredicate.enum';

export type TextFilter = Readonly<{
  filterPredicate: TextFilterPredicate;
  textArgument: string;
}>;

export type TextFilterValues = Readonly<{
  filters: ReadonlyArray<TextFilter>;
  conjunction: FilterConjunction;
  onConjunctionChange: (newConjunction: FilterConjunction) => void;
  onValueChange: (newFilters: ReadonlyArray<TextFilter>) => void;
}>;

export type TextFilterSettings = Readonly<{
  filteredEntityName: string;
}>;

type TextFilterProps = TextFilterValues & TextFilterSettings;

const horizontalRootPadding = 16;
const rootStyle = css({
  padding: `12px ${horizontalRootPadding}px`,
});

const verticalDistance = 8;

const firstLineStyle = css({
  display: 'flex',
  marginBottom: verticalDistance,
});

const conjunctionDropdownStyle = css({
  marginRight: 16,
});

const predicateDropdownStyle = css({
  flexShrink: 1,
  flexGrow: 1,
  minWidth: 0,
});

const FILTER_SPACER_WIDTH = 4;

const filterItemStyle = css({
  marginTop: verticalDistance,
  marginRight: FILTER_SPACER_WIDTH,
});

const filterListWrapperStyle = css({
  overflow: 'hidden',
  display: 'flex',
  flexWrap: 'wrap',
  marginRight: -FILTER_SPACER_WIDTH,
});

const hintStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.warning,
  fontSize: 12,
  lineHeight: 1.15,
  margin: '0 0 10px',
});

export const TextFilterComponent: FC<TextFilterProps> = props => {
  const theme = useTheme();
  const [predicate, setPredicate] = useState(TextFilterPredicate.Contains);
  const [text, setText] = useState('');
  const [t] = useTranslation();

  const onSubmit = () => {
    props.onValueChange(
      [
        ...props.filters,
        { filterPredicate: predicate, textArgument: text },
      ]);
  };

  const isNullablePredicate = isNullableTextPredicate(predicate);

  useEffect(() => {
    if (isNullablePredicate) {
      setText('');
    }
  }, [isNullablePredicate]);

  useEffect(() => {
    setText('');
  }, [props.filters]);

  return (
    <div css={rootStyle}>
      <div css={firstLineStyle}>
        <RegularDropdownComponent
          css={conjunctionDropdownStyle}
          onChange={props.onConjunctionChange}
          value={props.conjunction}
          options={Object.values(FilterConjunction).map(value => ({ name: renderFilterConjunction(value, t), value }))}
        />
        <RegularDropdownComponent
          css={predicateDropdownStyle}
          onChange={setPredicate}
          value={predicate}
          options={Object.values(TextFilterPredicate).map(value => ({ name: renderTextFilterPredicate(value, t), value }))}
        />
      </div>
      <FormTextInputComponent
        onChange={setText}
        value={text}
        placeholder={t('Enter Text')}
        isDisabled={isNullablePredicate}
        button={(
          <FormButtonComponent
            text={t('Add')}
            prefixIcon={faPlus}
            onClick={onSubmit}
            isDisabled={text.trim().length === 0 && !isNullablePredicate}
          />
        )}
      />

      {isArrayTextPredicate(predicate) && (
        <div css={hintStyle({ theme })}>
          {t('Hint: You may enter multiple values separated with commas.')}
        </div>
      )}

      <div css={filterListWrapperStyle}>
        {props.filters.map((filter, index) => (
          <SelectedItemComponent
            css={filterItemStyle}
            key={index}
            onRemove={() => props.onValueChange(
              props.filters.filter((_, i) => i !== index))
            }
            size={Size.Small}
            multiline
            maxNumberOfLines={2}
          >
            <div>
              {props.filteredEntityName} {renderTextFilterPredicate(filter.filterPredicate, t)} {filter.textArgument}
            </div>
          </SelectedItemComponent>
        ))}
      </div>
    </div>
  );
};
