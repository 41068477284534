import { GroupingType } from '../../../_shared/types/grouping/grouping';
import { type MapSettingsGroupingAction } from './mapSettingsGrouping.action';
import {
  MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS,
  MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN,
  MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS,
} from './mapSettingsGrouping.actionTypes';
import { type MapSettingsGroupingState } from './mapSettingsGrouping.state';

export const mapSettingsGroupingInitialState: MapSettingsGroupingState = {
  activeGroupColumns: [],
};

export const mapSettingsGroupingReducer = (
  state = mapSettingsGroupingInitialState,
  action: MapSettingsGroupingAction
): MapSettingsGroupingState => {
  switch (action.type) {
    case MAP_SETTINGS_GROUPING_SET_ACTIVE_GROUP_COLUMNS: {
      let groupColumns = action.payload.activeGroupColumns;

      if (groupColumns.length === 2) {
        const [firstColumn, secondColumn] = groupColumns;
        const isWrongOrder = firstColumn.type === GroupingType.Numeric && secondColumn.type === GroupingType.Text;

        if (isWrongOrder) {
          groupColumns = [secondColumn, firstColumn];
        }
      }

      return {
        ...state,
        activeGroupColumns: groupColumns,
      };
    }

    case MAP_SETTINGS_GROUPING_REMOVE_ACTIVE_GROUP_COLUMN: {
      const newActiveColumns = state.activeGroupColumns.filter(column =>
        column.columnId !== action.payload.activeGroupColumn.columnId ||
        column.spreadsheetId !== action.payload.activeGroupColumn.spreadsheetId
      );

      return {
        ...state,
        activeGroupColumns: newActiveColumns,
      };
    }

    case MAP_SETTINGS_GROUPING_ADD_GROUP_COLUMNS: {
      let groupColumns = [...state.activeGroupColumns, action.payload.newGroupColumn];

      if (groupColumns.length === 2) {
        const [firstColumn, secondColumn] = groupColumns;
        const isWrongOrder = firstColumn.type === GroupingType.Numeric && secondColumn.type === GroupingType.Text;

        if (isWrongOrder) {
          groupColumns = [secondColumn, firstColumn];
        }
      }

      return {
        ...state,
        activeGroupColumns: groupColumns,
      };
    }

    default:
      return state;
  }
};
