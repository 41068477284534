import { type Page } from '~/_shared/constants/routes';

export enum ModalType {
  AboutUs = 'AboutUs',
  AdHoc = 'AdHoc',
  AddDataColumn = 'AddDataColumn',
  AddMultipleLocations = 'AddMultipleLocations',
  AfterMigrationMapPrepare = 'AfterMigrationMapPrepare',
  AppError = 'AppError',
  BadDataTable = 'BadDataTable',
  BaseMapDeletionConflictResolution = 'BaseMapDeletionConflictResolution',
  BaseMapsUpdate = 'BaseMapsUpdate',
  BoundaryCreateSubmit = 'BoundaryCreateSubmit',
  BoundaryCustomizeFill = 'BoundaryCustomizeFill',
  BoundaryCustomizeMetrics = 'BoundaryCustomizeMetrics',
  BoundaryEdit = 'BoundaryEdit',
  BoundaryEditSubmit = 'BoundaryEditSubmit',
  BoundaryGroupsListing = 'BoundaryGroupsListing',
  BoundaryItemSettings = 'BoundaryItemSettings',
  BoundarySettings = 'BoundarySettings',
  BoundaryTerritoryCreate = 'BoundaryTerritoryCreate',
  BoundaryTerritoryEditSubmit = 'BoundaryTerritoryEditSubmit',
  BoundaryZoomLevelDisclaimer = 'BoundaryZoomLevelDisclaimer',
  BulkEditLocationsData = 'BulkEditLocationsData',
  ChangePassword = 'ChangePassword',
  ChangeEmail = 'ChangeEmail',
  ChangesPending = 'ChangesPending',
  ChartsDetails = 'ChartsDetails',
  ChooseSpreadsheetColumn = 'ChooseSpreadsheetColumn',
  ColumnsSettings = 'ColumnsSettings',
  Confirmation = 'Confirmation',
  ConfirmationWithAuthentication = 'ConfirmationWithAuthentication',
  ContactUs = 'ContactUs',
  CopyMap = 'CopyMap',
  CreateBoundaryGroupFromAI = 'CreateBoundaryGroupFromAI',
  CreateGooogleSheetMap = 'CreateGooogleSheetMap',
  CreateLayeredMap = 'CreateLayeredMap',
  CreateNewMap = 'CreateNewMap',
  CreateNewSalesRepMapSuccess = 'CreateNewSalesRepMapSuccess',
  CloneBoundaryTerritoryGroup = 'CloneBoundaryTerritoryGroup',
  CustomizeMapUrl = 'CustomizeMapUrl',
  DirectionSettings = 'DirectionSettings',
  DirectionsEditRoute = 'DirectionsEditRoute',
  DirectionsPanelFormAdd = 'DirectionsPanelFormAdd',
  DownloadResults = 'DownloadResults',
  EditData = 'EditData',
  EditLayeredMap = 'EditLayeredMap',
  EditLocationData = 'EditLocationData',
  EmailNotification = 'EmailNotification',
  EmbedMap = 'EmbedMap',
  ExportBoundaryData = 'ExportBoundaryData',
  ExportBoundaryTerritoryGroupData = 'ExportBoundaryTerritoryGroupData',
  ExportContainedBoundaryData = 'ExportContainedBoundaryData',
  ExportDataFunctionalitiesColumnsDisplaySettings = 'ExportDataFunctionalitiesColumnsDisplaySettings',
  ExportDirections = 'ExportDirections',
  ExportImage = 'ExportImage',
  ExportItemizedSpreadsheetData = 'ExportItemizedSpreadsheetData',
  ExportLassoData = 'ExportLassoData',
  ExportProximityData = 'ExportProximityData',
  ExportSpreadsheetData = 'ExportSpreadsheetData',
  ExportTerritoryData = 'ExportTerritoryData',
  FilterSettings = 'FilterSettings',
  GoogleSpreadsheetSelector = 'GoogleSpreadsheetSelector',
  GroupingToolHint = 'GroupingToolHint',
  ImageLibrary = 'ImageLibrary',
  ImagesView = 'ImagesView',
  ImpersonationChanged = 'ImpersonationChanged',
  ImportData = 'ImportData',
  InitialMapPosition = 'InitialMapPosition',
  LabelAndMarkerSettings = 'LabelAndMarkerSettings',
  Loader = 'Loader',
  LocationFinder = 'LocationFinder',
  MapMarkerColumnsDisplaySettings = 'MapMarkerColumnsDisplaySettings',
  MapPrivacySelector = 'MapPrivacySelector',
  MapSettingsOutOfSync = 'MapSettingsOutOfSync',
  MatchupData = 'MatchupData',
  MigrateMaps = 'MigrateMaps',
  MigrationConfirmation = 'MigrationConfirmation',
  MigrationInProgress = 'MigrationInProgress',
  MigrationFinished = 'MigrationFinished',
  Notification = 'Notification',
  NumericalGroupSettings = 'NumericalGroupSettings',
  OptionsModal = 'OptionsModal',
  PresentationalDataExportAllowTool = 'PresentationalDataExportAllowToolsModalComponent',
  ProximityEdit = 'ProximityEdit',
  ProximitySettings = 'ProximitySettings',
  PublicMapColumnsDisplaySettings = 'PublicMapColumnsDisplaySettings',
  PublicMapFiltersDisplaySettings = 'PublicMapFiltersDisplaySettings',
  ReconnectGoogleSheet = 'ReconnectGoogleSheet',
  RemoveMemberFromMap = 'RemoveMemberFromMap',
  ReplaceSpreadsheetDataMatchup = 'ReplaceSpreadsheetDataMatchup',
  ResolveUnableToTwoWaySync = 'ResolveUnableToTwoWaySync',
  RestoreDefaultMarkerCustomizations = 'RestoreDefaultMarkerCustomizations',
  RestrictZoomLevels = 'RestrictZoomLevels',
  RowSettingsResolve = 'RowSettingsResolve',
  SearchSettings = 'SearchSettings',
  SelectMarkersForOptimizeRoute = 'SelectMarkersForOptimizeRoute',
  SendNavigationLinks = 'SendNavigationLinks',
  ShareMap = 'ShareMap',
  ShareMapSelect = 'ShareMapSelect',
  SubscriptionExpired = 'SubscriptionExpired',
  SubscriptionManagement = 'SubscriptionManagement',
  SyncGoogleSheet = 'SyncGoogleSheet',
  TeamManagement = 'TeamManagement',
  TeamShareMap = 'TeamShareMap',
  SubscriptionManagementUnavailable = 'SubscriptionManagementUnavailable',
  Welcome = 'Welcome',
  WMSUpgradeSubscription = 'WMSUpgradeSubscription',
  TrialExportNotice = 'TrialExportNotice',
  TwoFa = 'TwoFa',
  Undo = 'Undo',
}

export type ModalProps<T = void> = Readonly<T & {
  isOpen: boolean;

  onClose: () => void;
}>;

export type AutoClosableModalProps<T = void> = Readonly<T & ModalProps &{
  allowedPages?: Set<Page>;
  skipCloseOnMapIdChange?: boolean;
}>;
