import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { cancelOptimizedTerritory } from '~/store/boundaries/boundaries.repository';

export const useCancelOptimizedTerritories = () => {
  const { invokeAjax, isLoading } = useAjaxCall((clientId: number, territoryId: number) =>
    cancelOptimizedTerritory(clientId, territoryId)
  );

  return {
    isLoading,
    cancelOptimizedTerritory: invokeAjax,
  };
};
