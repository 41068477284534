import {
  type FC, memo, type MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  MapOutlinePosition, type MapOutlinePositionInstance,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlinePositions';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type LatLng } from '~/_shared/types/latLng';
import { MarkerColor } from '~/_shared/types/marker.types';
import { convertColorToWebGLColor } from '~/_shared/utils/colors/colors.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE, DrawingTool,
} from '~/drawingTool/drawingTool.enums';
import {
  MapObjectOutlineCircleToDotRatio, MapObjectOutlineSize,
} from '~/map/map/mapObjects/mapObject.types';
import {
  useActiveDrawingInstanceZIndex, useDrawingInstanceZIndex,
} from '~/map/zIndexes/useDrawingInstanceZIndex.hook';
import {
  drawingEditPushNewSnapshot,
  drawingEditSetSelectedDrawing,
} from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import {
  drawingToolItemMoveStarted,
  drawingToolItemMoveStopped,
} from '~/store/frontendState/mapTools/drawingTool/drawingTool.actionCreators';
import { type DrawingItemLabel } from '~/store/mapSettings/drawing/items/drawingItems.types';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { useAreDrawingEventsEnabledRef } from '../hooks/useAreDrawingEventsEnabledRef';
import {
  type DrawingToolScalingItem, useDrawingToolItemScalingToggle,
} from '../hooks/useDrawingToolItemScalingToggle';
import { useDrawingToolItemSizeForCurrentZoom } from '../hooks/useDrawingToolItemSizeForCurrentZoom';
import { useDrawingToolItemMouseEvents } from '../useDrawingToolItemMouseEvents';
import {
  DrawingToolLabelInstanceLabelContainer,
  type DrawingToolLabelInstanceLabelProps,
} from './drawingToolLabelInstanceLabelContainer';

const OUTLINE_CIRCLE_COLOR = [100, 255, 0, 1] as const;
const OUTLINE_CIRCLE_HOVER_COLOR = [100, 125, 100, 1] as const;

const SCALING_MIN_VISIBLE_FONT_SIZE = 3;

const useDrawingToolLabelProps = (instance: DrawingItemLabel): DrawingToolLabelInstanceLabelProps | null => {
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const dispatch = useDispatch();
  const { onMouseOut, onMouseOver } = useDrawingToolItemMouseEvents();
  const zIndex = useDrawingInstanceZIndex(instance.id, instance.placement);

  const fontSize = useDrawingToolItemSizeForCurrentZoom({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  });

  const onClick = useCallback((e: {
    stopPropagation: () => void;
    preventDefault?: () => void;
  }) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.preventDefault?.();
    e.stopPropagation();

    dispatch(drawingEditSetSelectedDrawing(
      instance.id,
      {
        type: DrawingTool.Label,
        value: instance,
      })
    );
  }, [dispatch, instance, drawingEventsEnabledRef]);

  // when right click is allowed on label instance then the map context menu is shown
  // in the top left corner of the map because it's missing e.latLng
  const onLabelRightClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const onLabelOutlineRightClick = useCallback((_outlineId: Uuid, e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const renderOutline = useCallback((outline: MapOutlinePositionInstance, size: number) => {
    if (outline.position !== MapOutlinePosition.Left || size === 0) {
      return null;
    }

    const dotColor = convertColorToWebGLColor(instance.settings.dotColor);

    return (
      <MapOutline
        key={outline.id}
        outline={outline}
        onClick={(_outlineId, e) => onClick(e)}
        onRightClick={onLabelOutlineRightClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        visuals={{
          dot: {
            color: dotColor,
            size,
          },
        }}
      />
    );
  }, [onClick, onLabelOutlineRightClick, instance.settings.dotColor, onMouseOver, onMouseOut]);

  if (fontSize < SCALING_MIN_VISIBLE_FONT_SIZE) {
    return null;
  }

  return {
    instance,
    zIndex,
    disabled: !drawingEventsEnabledRef.current,
    fontSize,
    onClick,
    onRightClick: onLabelRightClick,
    onLabelMouseOver: onMouseOver,
    onLabelMouseOut: onMouseOut,
    renderOutline,
    selectionDisabled: true,
  };
};

const useDrawingToolSelectedLabelProps = (instance: DrawingItemLabel, etag?: string): DrawingToolLabelInstanceLabelProps => {
  const [startPoint, setStartPoint] = useState(instance.startPoint);
  const drawingEventsEnabledRef = useAreDrawingEventsEnabledRef();
  const { onMouseOver, onMouseOut } = useDrawingToolItemMouseEvents();
  const dispatch = useDispatch();
  const zIndex = useActiveDrawingInstanceZIndex();
  const [outlineHovered, setOutlineHovered] = useState(false);

  const prevEtag = usePrevious(etag);

  useEffect(() => {
    if (etag !== prevEtag) {
      setStartPoint(instance.startPoint);
    }
  }, [etag, instance, prevEtag]);

  const pushNewSnapshot = useCallback((label: DrawingItemLabel) => {
    dispatch(drawingEditPushNewSnapshot({
      type: DrawingTool.Label,
      value: {
        ...label,
      },
    }));
  }, [dispatch]);

  const onFontSizeChange = useCallback((props: DrawingToolScalingItem) => {
    pushNewSnapshot({
      ...instance,
      settings: {
        ...instance.settings,
        fontSize: props.itemSize,
      },
    });
  }, [instance, pushNewSnapshot]);

  const sizeProps = {
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  };

  const fontSize = useDrawingToolItemSizeForCurrentZoom(sizeProps);
  useDrawingToolItemScalingToggle({
    scalesWithMapZoom: instance.settings.scalesWithMapZoom,
    itemSize: instance.settings.fontSize,
    min: 0,
    max: DRAWING_TOOL_SLIDER_FONT_SIZE_RANGE.to,
  }, onFontSizeChange, etag);

  const onTextChange = useCallback((newText: string) => {
    pushNewSnapshot({
      ...instance,
      text: newText,
    });
  }, [instance, pushNewSnapshot]);

  const onLabelDragEnd = useCallback(() => {
    dispatch(drawingToolItemMoveStopped());
    pushNewSnapshot({
      ...instance,
      startPoint,
    });
  }, [dispatch, instance, pushNewSnapshot, startPoint]);

  const onLabelDragStart = useCallback(() => {
    dispatch(drawingToolItemMoveStarted());
  }, [dispatch]);

  const onLabelDragMove = useCallback((latLng: LatLng) => {
    setStartPoint(latLng);
  }, []);

  const onOutlineMouseOver = useCallback(() => {
    setOutlineHovered(true);
    onMouseOver();
  }, [onMouseOver]);

  const onOutlineMouseOut = useCallback(() => {
    setOutlineHovered(false);
    onMouseOut();
  }, [onMouseOut]);

  const renderOutline = useCallback((outline: MapOutlinePositionInstance, size: number) => {
    if (outline.position !== MapOutlinePosition.Left) {
      return null;
    }

    const useGhostVisuals = size === 0;

    const dotColor = convertColorToWebGLColor(instance.settings.dotColor);

    const circle = useGhostVisuals
      ? (outlineHovered ? outlineHoverVisuals.circle : outlineVisuals.circle)
      : {
        style: size > MapObjectOutlineSize.Enlarged ? 'thin' : 'thick',
        size: size * (size > MapObjectOutlineSize.Enlarged ? MapObjectOutlineCircleToDotRatio.Thin : MapObjectOutlineCircleToDotRatio.Thick),
        color: outlineHovered ? OUTLINE_CIRCLE_HOVER_COLOR : OUTLINE_CIRCLE_COLOR,
      } as const;

    return (
      <MapOutline
        key={outline.id}
        onMouseOver={onOutlineMouseOver}
        onMouseOut={onOutlineMouseOut}
        onDragStart={onLabelDragStart}
        onDragMove={(_id, position) => onLabelDragMove(position)}
        onDragEnd={onLabelDragEnd}
        outline={outline}
        visuals={{
          dot: {
            color: useGhostVisuals ? MarkerColor.White : dotColor,
            size: useGhostVisuals ? outlineVisuals.dot.size : size,
            transparent: useGhostVisuals,
          },
          circle,
        }}
      />
    );
  }, [instance.settings.dotColor, outlineHovered, onOutlineMouseOver, onOutlineMouseOut,
    onLabelDragStart, onLabelDragEnd, onLabelDragMove]);

  // clicking on map clears the selected drawing, this prevents the click event to be passed to the map
  const onClick = useCallback((e: MapObjectClickEventArgs) => {
    if (!drawingEventsEnabledRef.current) {
      return;
    }

    e.stopPropagation();
  }, [drawingEventsEnabledRef]);

  const renderInstance = useMemo(() => ({
    ...instance,
    startPoint,
  }), [instance, startPoint]);

  return {
    instance: renderInstance,
    zIndex,
    fontSize,
    onClick,
    onLabelMouseOver: onMouseOver,
    onLabelMouseOut: onMouseOut,
    onTextChange,
    renderOutline,
    autoFocusOnInit: true,
    etag,
  };
};

type DrawingToolLabelProps = {
  instance: DrawingItemLabel;
  isSelected?: boolean;
  etag?: string;
};

const DrawingToolLabelInstance: FC<DrawingToolLabelProps> = ({ instance, isSelected, etag }) => {
  const drawingToolLabelProps = useDrawingToolLabelProps(instance);
  const drawingToolSelectedLabelProps = useDrawingToolSelectedLabelProps(instance, etag);
  const props = isSelected ? drawingToolSelectedLabelProps : drawingToolLabelProps;

  if (!props) {
    return null;
  }

  return (
    <DrawingToolLabelInstanceLabelContainer {...props} />
  );
};

const pureComponent = memo(DrawingToolLabelInstance);
export { pureComponent as DrawingToolLabel };
