import {
  type FC, type ReactNode,
} from 'react';
import { AnchorLink } from '~/_shared/baseComponents/links';
import { supportLinkStyle } from '~/_shared/baseComponents/links/supportLink/supportLink.styles';
import { MAPTIVE_SUPPORT_EMAIL } from '~/_shared/constants/maptive';
import { useTranslation } from '~/_shared/utils/hooks';

export enum SupportLinkType {
  danger = 'danger',
  info = 'info',
  success = 'success',
}

export type SupportLinkComponentProps = Readonly<{
  alternativeEmail?: string;
  children?: ReactNode;
  subject?: string;
  type?: SupportLinkType;
}>;

export const SupportLinkComponent: FC<SupportLinkComponentProps> = props => {
  const [t] = useTranslation();

  return (
    <AnchorLink
      css={supportLinkStyle(props.type)}
      href={`mailto:${props.alternativeEmail || MAPTIVE_SUPPORT_EMAIL}?subject=${props.subject || ''}`}
    >
      {props.children || t('Maptive Support')}
    </AnchorLink>
  );
};
