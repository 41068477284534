import { type FC } from 'react';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '~/store/app.store';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import {
  usePublicMapSettingsMarkersVisibilityButtonSelector,
  usePublicMapSettingsSelector,
} from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useAreMapSettingsReadySelector } from '../../../store/mapSettings/ready/mapSettingsReady.selectors';
import { useMapSettingsSettingsIsDisableActiveToolPanelSelector } from '../../../store/mapSettings/settings/mapSettingsSettings.selectors';
import { useMapStatusBarItems } from '../../statusBar/hooks/useMapStatusBarItems';
import { LeftSideMapToolsComponent } from './leftSideMapTools.component';

const streetViewSelector = (state: AppState) => state.frontendState.mapTools.streetView;

export const LeftSideMapToolsContainer: FC = () => {
  const isMapPresentational = useIsMapPresentationalSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const isMarkerVisibilityVisible = useMapMarkersVisibilityToggle();
  const publicMapSettings = usePublicMapSettingsSelector();
  const streetView = useSelector(streetViewSelector);

  return (
    <LeftSideMapToolsComponent
      isMapPresentational={isMapPresentational}
      isMobileScreen={isMobileScreen}
      isMarkerVisibilityVisible={isMarkerVisibilityVisible}
      publicMapSettings={publicMapSettings}
      streetView={streetView}
    />
  );
};

const useMapMarkersVisibilityToggle = () => {
  const mapSettingsReady = useAreMapSettingsReadySelector();
  const isDisableActiveToolPanel = useMapSettingsSettingsIsDisableActiveToolPanelSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const presenttationalMapMarkersVisibilityButtonEnabled = usePublicMapSettingsMarkersVisibilityButtonSelector();
  const { isAnyRemovableItem } = useMapStatusBarItems();
  const isMobileScreen = useIsMobileScreenSelector();

  const statusBarVisible = !isMapPresentational && isAnyRemovableItem && !isDisableActiveToolPanel && !isMobileScreen;
  const markersButtonEnabled = !isMapPresentational || (mapSettingsReady && presenttationalMapMarkersVisibilityButtonEnabled);

  const isVisible = !statusBarVisible && markersButtonEnabled;

  return isVisible;
};
