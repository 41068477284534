import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

export const mapSettingsMarkersSelector = (state: AppState) => state.map.mapSettings.data.markers;
export const useMapSettingsMarkersSelector = () => useSelector(mapSettingsMarkersSelector);

const mapSettingsMarkersPanelSettingsSelector = (state: AppState) => state.map.mapSettings.data.markers.panelSettings;
export const useMapSettingsMarkersPanelSettingsSelector = () => useSelector(mapSettingsMarkersPanelSettingsSelector);

export const mapSettingsLabelMarkerOffsetsSelector = (state: AppState) => state.map.mapSettings.data.markers.labelMarkerOffsets;
export const useMapSettingsLabelMarkerOffsets = () => useSelector(mapSettingsLabelMarkerOffsetsSelector);

export const mapSettingsMarkerImagesSelector = (state: AppState) => state.map.mapSettings.data.markers.markerImages;
export const useMapSettingsMarkerImages = () => useSelector(mapSettingsMarkerImagesSelector);

const mapSettingsOpenLinksInSameWindowSelector = (state: AppState) => state.map.mapSettings.data.markers.openLinksInSameWindow;
export const useMapSettingsOpenLinksInSameWindow = () => useSelector(mapSettingsOpenLinksInSameWindowSelector);

const mapSettingsActiveMarkerIndicatorSettingsSelector = (state: AppState) => state.map.mapSettings.data.markers.activeMarkerIndicatorSettings;

export const useMapSettingsActiveMarkerIndicatorSettingsSelector = () => useSelector(mapSettingsActiveMarkerIndicatorSettingsSelector);

export const mapSettingsMarkerClusterDenseMarkersStateSelector = (state: AppState) => state.map.mapSettings.data.markers.clusterDenseMarkers;
export const mapSettingsMarkerUnclusterBelowNStateSelector = (state: AppState) => state.map.mapSettings.data.markers.unclusterBelowN;

export const mapSettingsUnstackMarkerStateSelector = (state: AppState) => state.map.mapSettings.data.markers.unstackMarkers;

export const mapSettingsStackMarkersWithPieChartsSelector = (state: AppState) => state.map.mapSettings.data.markers.stackedMarkersWPieCharts;
export const useMapSettingsStackMarkersWithPieChartsSelector = () => useSelector(mapSettingsStackMarkersWithPieChartsSelector);
