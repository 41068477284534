import { css } from '@emotion/react';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type ColorResult } from '~/_shared/components/colorPicker/colorPicker.types';
import { type RouteLeg } from '~/_shared/types/googleMaps/googleMaps.types';
import { catchApiError } from '~/_shared/utils/api/apiError.helpers';
import { getLast } from '~/_shared/utils/array/array.helpers';
import { zipWith } from '~/_shared/utils/collections/collections';
import { createUuid } from '~/_shared/utils/createUuid';
import { googleLatLngToLocal } from '~/_shared/utils/geolocation/geolocation';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { logError } from '~/_shared/utils/logError';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { getWaypointId } from '~/directions/directions.helper';
import { useFindDirections } from '~/directions/useFindDirections.hook';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { mapComponentSetZoomToBounds } from '~/store/frontendState/mapComponent/mapComponent.actionCreators';
import {
  frontendStateDirectionsAddEmptyWaypoint, frontendStateDirectionsClearWaypoints, frontendStateDirectionsRemoveWaypoint,
  frontendStateDirectionsSetWaypointAddress, frontendStateDirectionsSetWaypoints,
} from '~/store/frontendState/mapTools/directions/directions.actionCreators';
import {
  type CompleteWaypoint, type RouteUiData, type Waypoint,
} from '~/store/frontendState/mapTools/directions/directions.state';
import {
  startRoutingLassoSelection, stopLassoTool,
} from '~/store/frontendState/mapTools/lasso/lasso.actionCreators';
import { useLassoStateSelector } from '~/store/frontendState/mapTools/lasso/lasso.selectors';
import { LassoConfiguration } from '~/store/frontendState/mapTools/lasso/lasso.state';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { AppErrorType } from '../../../appError/appErrorType.enum';
import { BoundingBox } from '../../../map/map/boundingBox';
import {
  type DirectionsSuccessResult, maxRequestWaypoints, maxTotalWaypoints,
} from '../../getDirections.service';
import {
  getRouteColor, getRouteName,
} from '../directionsPanel.helpers';
import { DirectionsPanelFormComponent } from './directionsPanelForm.component';

type DirectionsPanelFormContainerProps = Readonly<{
  routes: ReadonlyArray<MapSettingsRoute>;
  addRoute: (route: MapSettingsRoute, routeUiData: RouteUiData) => void;
  clearRoutes: () => void;
}>;

const confirmationModalStyle = css({ overflow: 'hidden' });

const reorderWaypointsIntoLegsOrder = (waypoints: ReadonlyArray<Waypoint>, legsWithNewOrder: DirectionsSuccessResult['legs']): ReadonlyArray<Waypoint> => {
  const waypointsMap = new Map(waypoints.map(w => [w.id, w] as const));
  return [...legsWithNewOrder, getLast(waypoints)]
    .map((waypoint) => waypoint && waypointsMap.get(waypoint.id) || null)
    .filter(notNullsy);
};

const completeWaypointsFromLegs = (routeId: Uuid, waypoints: ReadonlyArray<Waypoint>, legs: ReadonlyArray<RouteLeg>): ReadonlyArray<CompleteWaypoint> => {
  if (waypoints.length !== legs.length + 1) {
    throw new Error(`The amount of legs should be one less than the amount of waypoints. Waypoints: ${waypoints.length}, legs: ${legs.length}`);
  }
  const lastWaypoint = getLast(waypoints);
  const lastLeg = getLast(legs);
  if (!lastLeg) {
    throw new Error(`There's no last route leg. It's '${lastLeg}'.`);
  }
  if (!lastWaypoint) {
    throw new Error(`There's no last waypoint. It's '${lastWaypoint}'.`);
  }

  return zipWith(waypoints, legs, (waypoint, leg) => {
    return {
      ...waypoint,
      id: getWaypointId(routeId, waypoint.id),
      latLng: waypoint.latLng ?? googleLatLngToLocal(leg.start_location),
      address: waypoint.address ?? leg.start_address,
    };
  }).concat([{
    ...lastWaypoint,
    id: getWaypointId(routeId, lastWaypoint.id),
    latLng: lastWaypoint.latLng ?? googleLatLngToLocal(lastLeg.end_location),
    address: lastWaypoint.address ?? lastLeg.end_address,
  }]);
};

export const DirectionsPanelFormContainer: FC<DirectionsPanelFormContainerProps> = (props) => {
  const [routeColor, setRouteColor] = useState(() => getRouteColor(props.routes.map(route => createColor(route.color).hex)));
  const [isLoading, setIsLoading] = useState(false);

  const lassoState = useLassoStateSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const isMobileScreen = useIsMobileScreenSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { findDirections } = useFindDirections();

  const waypoints = useSelector(state => state.frontendState.mapTools.directions.waypoints);
  const shouldBeginAtUserLocation = useSelector(state => state.map.mapSettings.data.directions.shouldBeginAtUserLocation);

  const isLassoActive = lassoState.active && lassoState.configuration === LassoConfiguration.RoutingSelection;

  useEffect(() => {
    setRouteColor(getRouteColor(props.routes.map(route => createColor(route.color).hex)));
  }, [props.routes]);

  const { openModal: directionsPanelFormAddModal } = useModal(ModalType.DirectionsPanelFormAdd);
  const { openModal: openDirectionSettingsModal } = useModal(ModalType.DirectionSettings);
  const { openModal: openAdHocModal } = useModal(ModalType.AdHoc);

  const openDirectionSettings = () => openDirectionSettingsModal({});

  const addNewLocation = () => dispatch(frontendStateDirectionsAddEmptyWaypoint());

  const removeWaypoint = (waypointIndex: number) => dispatch(frontendStateDirectionsRemoveWaypoint(waypointIndex));

  const setWaypointAddress = (index: number, value: string) => dispatch(frontendStateDirectionsSetWaypointAddress(index, value));

  const clearEndpoints = () => dispatch(frontendStateDirectionsClearWaypoints());

  const setWaypoints = (waypoints: ReadonlyArray<Waypoint>) => dispatch(frontendStateDirectionsSetWaypoints(waypoints, shouldBeginAtUserLocation));

  const changeRouteColor = (color: ColorResult) => setRouteColor(color.hex);

  const showUnknownErrorModal = () => dispatch(createAppError({
    type: AppErrorType.General,
    title: t('No route found.'),
    errorTitle: t('Failed to find route for unknown reason.'),
  }));

  const showNotFoundErrorModal = () => dispatch(createAppError({
    type: AppErrorType.General,
    title: t('No route found.'),
    errorTitle: t('We could not find any route for your locations.'),
  }));

  const showInvalidAddressErrorModal = (invalidAddresses: ReadonlyArray<string>) => dispatch(createAppError({
    type: AppErrorType.General,
    title: t('Issue finding locations'),
    content: (
      <div>
        <p>{t('We were unable to find locations of some of your waypoints.')}</p>
        <ul>
          {invalidAddresses.map((addr, i) => (<li key={i}>{addr}</li>))}
        </ul>
        <p>{t('Please fix the waypoints addresses and try again.')}</p>
      </div>
    ),
  }));

  const handleAddOrReplaceRoute = (optimize: boolean, isConfirmed = false) => {
    if (optimize && waypoints.length > maxRequestWaypoints && !isConfirmed) {
      return confirmApproximations();
    }
    setIsLoading(true);
    const routeFinder = () => (findDirections(waypoints, optimize, shouldReplaceFirstWithUserLocation))
      .then(res => new Promise<DirectionsSuccessResult>((resolve, reject) => {
        if (res.success) {
          resolve(res.success);
        }
        else {
          switch (res.error.status) {
            case google.maps.DirectionsStatus.ZERO_RESULTS:
              showNotFoundErrorModal();
              return reject(res.error);

            case google.maps.DirectionsStatus.NOT_FOUND:
              showInvalidAddressErrorModal(res.error.failedAddresses);
              return reject(res.error);

            default:
              showUnknownErrorModal();
              logError('Failed to find route for unknown reason', res.error);
              return reject(res.error);
          }
        }
      }))
      .then(updateWaypointOrder)
      .then((res) => {
        dispatch(mapComponentSetZoomToBounds(new BoundingBox(res.bounds)));
        return res;
      })
      .catch((e) => {
        if (e instanceof GeolocationPositionError) {
          dispatch(createAppError({
            errorTitle: t('We were not able to find your location. Please check your browser settings and try again.'),
            title: t('Unable To Find Your Location'),
            type: AppErrorType.General,
          }));

          return;
        }

        throw e;
      })
      .catch(catchApiError(apiError => {
        dispatch(createAppError({
          type: AppErrorType.General,
          title: t('Something went wrong, please try again.'),
          content: apiError.message,
        }));
      }))
      .finally(() => setIsLoading(false));

    if (props.routes.length === 0) {
      routeFinder().then(replaceRoutes);
    }
    else {
      directionsPanelFormAddModal({
        onAddAnother: () => routeFinder().then(addRoute),
        onReplace: () => routeFinder().then(replaceRoutes),
        onCancel: () => setIsLoading(false),
      });
    }
  };

  const confirmApproximations = () => openAdHocModal({
    caption: t('Soft limit of {{count}} stops reached', { count: maxRequestWaypoints }),
    content: onClose => (
      <div css={confirmationModalStyle}>
        <p>{t('directionsOverSoftLimitConfirmation{{count}}', { count: maxRequestWaypoints })}</p>
        <ButtonComponent
          text={t('Confirm')}
          onClick={() => {
            onClose();
            handleAddOrReplaceRoute(true, true);
          }}
        />
      </div>
    ),
  });

  const shouldReplaceFirstWithUserLocation = shouldBeginAtUserLocation && !waypoints[0]?.address?.trim();

  const updateWaypointOrder = (directionsResultWithNewOrder: DirectionsSuccessResult) => {
    setWaypoints(reorderWaypointsIntoLegsOrder(waypoints, directionsResultWithNewOrder.legs));
    return directionsResultWithNewOrder;
  };

  const addRoute = (result: DirectionsSuccessResult) => {
    const routeId = createUuid();
    props.addRoute({
      id: routeId,
      color: guaranteeHash(routeColor),
      name: getRouteName(props.routes.length + 1, t),
      waypoints: completeWaypointsFromLegs(routeId, reorderWaypointsIntoLegsOrder(waypoints, result.legs), result.legs.map(l => l.data)),
    }, {
      expandedLegsIndexes: new Set(),
      legs: result.legs.map(l => l.data),
      copyrights: result.copyrights,
      apiResponses: result.apiResponses,
    });
  };

  const replaceRoutes = (result: DirectionsSuccessResult) => {
    props.clearRoutes();
    addRoute(result);
  };

  const getDirections = () => handleAddOrReplaceRoute(false);
  const getOptimizedDirections = () => handleAddOrReplaceRoute(true);

  const isClearFormEnabled = waypoints
    .filter(point => !isTextEmpty(point.address ?? '') || point.latLng !== null)
    .length > 0 || waypoints.length > 2;

  const startLassoSelection = useCallback(() => {
    dispatch(startRoutingLassoSelection());
  }, [dispatch]);

  const stopLassoToolSelection = useCallback(() => {
    dispatch(stopLassoTool());
  }, [dispatch]);

  return (
    <DirectionsPanelFormComponent
      routeDirectionsSelectedColor={createColor(routeColor)}
      onOpenSettingsClick={openDirectionSettings}
      waypoints={waypoints}
      onAddLocationClick={addNewLocation}
      onWaypointCleanClick={removeWaypoint}
      setWaypointAddress={setWaypointAddress}
      onClearForm={clearEndpoints}
      setWaypoints={setWaypoints}
      onGetDirectionsClick={getDirections}
      onOptimizeRouteClick={getOptimizedDirections}
      onRouteColorChange={changeRouteColor}
      onLassoAndFillStart={startLassoSelection}
      onLassoAndFillCancel={stopLassoToolSelection}
      isClearFormEnabled={isClearFormEnabled}
      getDirectionsDisabledMessage={createGetDirectionsDisabledMessage(waypoints, shouldBeginAtUserLocation, t)}
      startWithUserLocation={shouldBeginAtUserLocation}
      showAdminControls={!isMapPresentational}
      isLoading={isLoading}
      isLassoActive={isLassoActive}
      showLassoToolButton={!isMobileScreen}
    />
  );
};

const createGetDirectionsDisabledMessage = (waypoints: ReadonlyArray<Waypoint>, shouldBeginAtUserLocation: boolean, t: TranslationFnc): string | null => {
  if (waypoints.length < 2) {
    return t('Please add at least 2 waypoints to continue.');
  }
  if (waypoints.length > maxTotalWaypoints) {
    return t(
      'Currently this tool supports maximum of {{limit}} locations. Please remove {{overLimit}} locations to continue.',
      { limit: maxTotalWaypoints, overLimit: waypoints.length - maxTotalWaypoints },
    );
  }
  const waypointsValidate = shouldBeginAtUserLocation ? waypoints.slice(1) : waypoints;
  const areWaypointsValid = waypointsValidate.every(point => !isTextEmpty(point.address ?? '') || point.latLng !== null);
  if (!areWaypointsValid) {
    return t('Some waypoint inputs are empty. Please fill in all the waypoints.');
  }

  return null;
};
