import { type ZIndexedEntity } from './zIndexRanges';

export type MaxEntityCount = number;
export type ZIndexesPerEntity = number;

type ZIndexEntityWithRangeSize = Readonly<[ZIndexedEntity] | [ZIndexedEntity, MaxEntityCount] | [ZIndexedEntity, MaxEntityCount, ZIndexesPerEntity]>;
type ZIndexEntitiesOrdered = ReadonlyArray<ZIndexEntityWithRangeSize>;
type ZIndexEntitiesRangesLookup = Readonly<{ [entity in keyof typeof ZIndexedEntity]: readonly [number, number] }>;

export const getEntityKeyFromZIndexedEntity = (entity: ZIndexedEntity): keyof typeof ZIndexedEntity => (
  entity as keyof typeof ZIndexedEntity
);

export const createZIndexEntitiesRangesLookup = (zIndexEntitiesOrdered: ZIndexEntitiesOrdered): ZIndexEntitiesRangesLookup => {
  let rangeStart = 0;
  return zIndexEntitiesOrdered
    .reduce((result, [entity, entityCount = 1, zIndexesPerEntity = 1]) => {
      const rangeSize = entityCount * zIndexesPerEntity;

      result[getEntityKeyFromZIndexedEntity(entity)] = [rangeStart, rangeStart + rangeSize];
      rangeStart += rangeSize;
      return result;
    }, {} as Mutable<ZIndexEntitiesRangesLookup>);
};
