import {
  useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '~/_shared/components/modal/confirmation/useConfirmationModal';
import {
  type ActiveGroupFilters,
  GroupingType, type UniqueGroup,
} from '~/_shared/types/grouping/grouping';
import { filterAddedSpreadsheetColumns } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import {
  getActiveGroupFiltersFromFiltersState,
  getActiveGroupFiltersValueForGroupIdToggle,
} from '~/_shared/utils/grouping/grouping.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useEditLayeredMap } from '~/map/layered/editLayeredMapModal/useEditLayeredMap';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { LayeringToolComponent } from '~/sidebar/sidebarApps/mapTools/layeringTool/layeringTool.component';
import { mapChange } from '~/store/map/map.actionCreators';
import { useBaseMapNames } from '~/store/mapInfo/mapInfo.selectors';
import { mapSettingsColumnsFilterSetGroup } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilter.actionCreators';
import { useMapSettingsColumnsFilterSelector } from '~/store/mapSettings/columnsFilter/mapSettingsColumnsFilters.selectors';
import { mapSettingsFilteringTurnFilterOn } from '~/store/mapSettings/filtering/mapSettingsFiltering.actionCreators';
import { useMapSettingsFilteringSelector } from '~/store/mapSettings/filtering/mapSettingsFiltering.selectors';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { getGroupingColumnsUniqueGroups } from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import {
  DataType, emptySpreadsheetData,
} from '~/store/spreadsheetData/spreadsheetData.state';

export const LayeringToolContainer: React.FC = _ => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const spreadSheetColumns = useSpreadsheetColumns();
  const { closeConfirmationModal, openConfirmationModal } = useConfirmationModal();
  const { editLayeredMap } = useEditLayeredMap();
  const isLayered = useSelector(state => Boolean(state?.map?.mapInfo?.data?.isLayered));
  const layering = useSelector(state => state?.map?.mapInfo?.data?.layering);
  const mapInfo = useSelector(state => state?.map?.mapInfo?.data);
  const [t] = useTranslation();
  const filtering = useMapSettingsFilteringSelector();
  const activeGroupColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const columnsFilter = useMapSettingsColumnsFilterSelector();
  const baseMapsNames = useBaseMapNames();

  const activeGroupFilters = useMemo<ActiveGroupFilters>(() => {
    return getActiveGroupFiltersFromFiltersState(columnsFilter);
  }, [columnsFilter]);

  const baseMapsColumn = useMemo(() => spreadSheetColumns.find(column => column.id === BASE_MAPS_COLUMN_ID), [spreadSheetColumns]);

  const selectedLayersIds: string[] = useMemo(() => {
    if (!baseMapsColumn) {
      return [];
    }
    return Object.keys(activeGroupFilters[baseMapsColumn.spreadsheetId]?.[BASE_MAPS_COLUMN_ID]?.[DataType.TEXT] || {});
  }, [activeGroupFilters, baseMapsColumn]);

  const baseMapsUniqueGroupColumns = useMemo<UniqueGroup[]>(() => {
    if (baseMapsColumn) {
      return getGroupingColumnsUniqueGroups(
        spreadsheetData ?? emptySpreadsheetData,
        matchupData,
        {
          spreadsheetId: baseMapsColumn.spreadsheetId,
          columnId: baseMapsColumn.id,
          type: GroupingType.Text,
        },
      );
    }
    return [];
  }, [baseMapsColumn, spreadsheetData, matchupData]);

  const matches = useMemo(() => {
    if (isLayered) {
      return filterAddedSpreadsheetColumns(layering?.matches ?? []);
    }
    return [];
  }, [isLayered, layering]);

  const sourceColumns = useMemo(() => {
    if (isLayered) {
      return layering?.sourceColumns ?? {};
    }
    return {};
  }, [isLayered, layering]);

  const onSelectLayer = useCallback((layer: UniqueGroup) => () => {
    const spreadsheetId = baseMapsColumn?.spreadsheetId;

    if (spreadsheetId === undefined) {
      return;
    }

    const columnId = BASE_MAPS_COLUMN_ID;
    const filterSettingExists = filtering?.[spreadsheetId]?.[columnId];
    const isActiveGroupColumn: boolean = activeGroupColumns.filter(
      item => item.columnId === columnId && item.spreadsheetId === spreadsheetId
    ).length > 0;

    const groupingColumn = {
      spreadsheetId,
      columnId,
    };

    if (!filterSettingExists && !isActiveGroupColumn) {
      dispatch(mapSettingsFilteringTurnFilterOn(groupingColumn, DataType.GROUP));
    }

    const newValues = getActiveGroupFiltersValueForGroupIdToggle(activeGroupFilters, groupingColumn, layer);

    dispatch(mapSettingsColumnsFilterSetGroup(groupingColumn, newValues));
  }, [baseMapsColumn?.spreadsheetId, filtering, activeGroupFilters, dispatch, activeGroupColumns]);

  const onConfirmDisconnectMap = useCallback(() => {
    if (clientId !== null && mapInfo) {
      editLayeredMap(
        clientId,
        mapInfo.id,
        {
          layeringMapInfo: mapInfo,
          connected: false,
        },
        () => {
          closeConfirmationModal();
          dispatch(mapChange(mapInfo.id));
        }
      );
    }
  }, [clientId, mapInfo, editLayeredMap, closeConfirmationModal, dispatch]);

  const onDisconnectMap = useCallback(() => {
    openConfirmationModal({
      title: t('Disconnect Map'),
      confirmCaption: t('Proceed'),
      text: t('You are about to disconnect a map...'),
      permanentConfirmSettings: {
        id: 'disconnect-map',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      showLoaderAfterConfirmClick: true,
      onCancel: closeConfirmationModal,
      onConfirm: onConfirmDisconnectMap,
    });
  }, [t, openConfirmationModal, closeConfirmationModal, onConfirmDisconnectMap]);

  return (
    <>
      <LayeringToolComponent
        layering={layering}
        matches={matches}
        baseMapsNames={baseMapsNames}
        sourceColumns={sourceColumns}
        baseMapsUniqueGroupColumns={baseMapsUniqueGroupColumns}
        onSelectLayer={onSelectLayer}
        mapInfo={mapInfo}
        onDisconnectMap={onDisconnectMap}
        selectedLayersIds={selectedLayersIds}
      />
    </>
  );
};
