import { apiPost } from '../../_shared/utils/api/api.helpers';
import { type SpreadsheetDataBulkRequestGetterFilter } from '../../spreadsheet/spreadsheet.repository';
import {
  type BoundaryTerritoryGroupCreateRequest,
  type BoundaryTerritoryResponse,
} from '../boundaryTerritoryGroups/boundaryTerritoryGroups.repository';
import { type BoundaryTerritoryGroupModel } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  createBoundaryTerritoryGroupDetailsFromResponse,
  createBoundaryTerritoryGroupDetailsRequestQuery,
} from './boundaryTerritoryDetails.factory';
import { type BoundaryTerritoryGroupDetails } from './boundaryTerritoryGroups.state';

export type BoundaryTerritoryGroupDetailsItemResponse = {
  assignments: {
    [boundary_territory_id: string]: number[];
  };
  manual_assignments: { [boundaryTerritoryId: string]: number[]};
  counts: {
    [assignmentId: number]: number;
  };
  numerical_data: {
    totals: {
      [assignmentId: number]: number;
    };
    total_highest: number;
    total_lowest: number;
  };
  has_empty: boolean;
};

export type BoundaryTerritoryGroupDetailsResponse = {
  map_id: number;
  boundary_territory_group_query_id: string;
  boundary_territory_group_id: null | number;
  boundary_group_id: number;
  boundary_territories: BoundaryTerritoryResponse[];
  boundary_details: BoundaryTerritoryGroupDetailsItemResponse;
};

export type BoundaryTerritoryGroupDetailsFilterRequest = Omit<SpreadsheetDataBulkRequestGetterFilter, 'only_get_filtered'>;

export type BoundaryTerritoryGroupDetailsRequestQuery = BoundaryTerritoryGroupCreateRequest & {
  boundary_territory_group_query_id: string;
  boundary_territory_group_id?: number;
  filter?: BoundaryTerritoryGroupDetailsFilterRequest;
};

export type BoundaryTerritoryGroupDetailsRequestQueryModel = {
  boundaryTerritoryGroup: BoundaryTerritoryGroupModel;
  boundaryTerritoryGroupQueryId: string;
  filter: BoundaryTerritoryGroupDetailsFilterRequest | null;
};

export const getBoundaryTerritoryGroupDetails = (
  clientId: number,
  query: BoundaryTerritoryGroupDetailsRequestQueryModel[],
): Promise<Map<string, BoundaryTerritoryGroupDetails>> => {
  const requestUrl = `/api/clients/${clientId}/boundary-territory/details`;
  const requestParams = {
    query: query.map(request =>
      createBoundaryTerritoryGroupDetailsRequestQuery(
        request.boundaryTerritoryGroup,
        request.boundaryTerritoryGroupQueryId,
        request.filter ?? undefined,
      )
    ),
  };

  return apiPost<{ data: BoundaryTerritoryGroupDetailsResponse[] }>(requestUrl, requestParams)
    .then(response => {
      const results: Map<string, BoundaryTerritoryGroupDetails> = new Map();

      response.data.forEach(details => {
        results.set(details.boundary_territory_group_query_id, createBoundaryTerritoryGroupDetailsFromResponse(details));
      });

      return results;
    });
};
