import {
  css, type SerializedStyles,
} from '@emotion/react';
import styled from '@emotion/styled';
import { type ReactNode } from 'react';
import { TooltipComponent } from '~/_shared/baseComponents/tooltip/tooltip.component';
import type { Theme } from '~/_shared/themes/theme.model';
import { stringify } from '~/_shared/utils/string/stringify';

export type RadioProps<T extends (string | null)> = Readonly<{
  className?: string;
  description?: ReactNode;
  icon?: ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  onChange: (value: T) => void;
  radioIconStyle?: (isActive: boolean) => SerializedStyles;
  selectId?: (value: T) => string;
  value: T;
  tooltip?: string;
}>;

const RadioIndicator = styled.span<Readonly<{ isChecked: boolean }>>(({ isChecked, theme }) => ({
  boxSizing: 'border-box',
  display: 'block',
  width: 20,
  height: 20,
  borderRadius: '50%',
  border: isChecked ? `6px solid ${theme.radioColors.checkedBorder}` : `2px solid ${theme.radioColors.uncheckedBorder}`,
  background: isChecked ? theme.radioColors.checkedFill : theme.radioColors.uncheckedFill,
  transition: 'border .2s, background .2s',
  flexShrink: 0,
}));

const containerStyle = ({ isChecked, isDisabled }: Readonly<{
  isChecked: boolean;
  isDisabled?: boolean;
}>) => (theme: Theme) => {
  let radioIndicatorBackground = undefined;

  if (!isDisabled) {
    radioIndicatorBackground = isChecked
      ? theme.radioColors.checkedFill : theme.radioColors.checkedFillHover;
  }

  return css({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    opacity: isDisabled ? theme.opacity.disabled : undefined,
    '&:hover': {
      [`${RadioIndicator}`]: {
        backgroundColor: radioIndicatorBackground,
      },
    },
  });
};

const radioInputStyle = css({
  display: 'none',
});

const iconStyle = (theme: Theme) => css({
  color: theme.radioColors.icon,
  margin: '0 15px 0 20px',
});

const labelStyle = (theme: Theme) => css({
  color: theme.radioColors.text,
  display: 'block',
});

const descriptionStyle = (theme: Theme) => css({
  color: theme.radioColors.text,
  display: 'block',
});

const textContainerStyle = ({ hasIcon }: { hasIcon: boolean }) => css({
  marginLeft: 8,
  maxWidth: `calc(100% - ${hasIcon ? 90 : 40}px)`,
});

export const RadioComponent = <T extends string>({
  className,
  description,
  icon,
  isChecked,
  isDisabled,
  label,
  onChange,
  radioIconStyle,
  selectId = stringify,
  tooltip,
  value,
  ...restProps
}: RadioProps<T>) => {

  const content = (
    <label css={containerStyle({ isChecked, isDisabled })}>
      <input
        css={radioInputStyle}
        type="radio"
        disabled={isDisabled}
        checked={isChecked}
        onChange={e => {
          onChange(e.target.value as T);
        }}
        value={selectId(value)}
      />
      <RadioIndicator
        isChecked={isChecked}
        css={radioIconStyle?.(isChecked)}
      />

      {icon && (
        <span css={iconStyle}>
          {icon}
        </span>
      )}

      {(label || description) && (
        <div css={textContainerStyle({ hasIcon: !!icon })}>
          <span css={labelStyle}>
            {label}
          </span>
          <span css={descriptionStyle}>
            {description}
          </span>
        </div>
      )}
    </label>
  );

  return (
    <div
      {...restProps}
      className={className}
      css={{ display: 'flex', alignItems: 'center' }}
    >
      {tooltip ? (
        <TooltipComponent
          tooltipContent={tooltip}
          css={{ display: 'flex', flexGrow: 1 }}
        >
          {content}
        </TooltipComponent>
      ) : content}
    </div>
  );
};
