import { getAvailableItemSpace } from '../../../../../topBar/heading/heading.helpers';

export const getAvailableItemSpacesWithMinimumWidths = (availableSpace: number, itemsWidth: number[], minWidth: number): number[] => {
  let space = availableSpace;

  return itemsWidth.map((_, index) => {
    const availableItemSpace = getAvailableItemSpace(space, itemsWidth, index) || 0;
    if (availableItemSpace < minWidth) {
      space = space - minWidth;
      return minWidth;
    }

    space = space - availableItemSpace;
    return availableItemSpace;
  });
};
