import {
  DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE, DrawingTool,
} from '../../../../../drawingTool/drawingTool.enums';
import { type DrawingSettingsAction } from '../drawingSettings.action';
import { DRAWING_SETTINGS_SET_SETTINGS } from '../drawingSettings.actionTypes';
import { type DrawingImageSettingsState } from './drawingImageSettings.state';

export const drawingImageSettingsInitialState: DrawingImageSettingsState = {
  scalesWithMapZoom: false,
  showImageBorders: false,
  imageSize: DRAWING_TOOL_SLIDER_IMAGE_SIZE_RANGE.to / 2,
};

export const drawingImageSettingsReducer = (
  state = drawingImageSettingsInitialState, action: DrawingSettingsAction
): DrawingImageSettingsState => {
  switch (action.type) {
    case DRAWING_SETTINGS_SET_SETTINGS: {
      if (action.payload.drawingTool !== DrawingTool.Image) {
        return state;
      }

      return {
        ...state,
        ...action.payload.settings.image,
      };
    }

    default:
      return state;
  }
};
