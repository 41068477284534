import { css } from '@emotion/react';
import {
  type FC, useEffect,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { ImageSelectorComponent } from '../../../../_shared/components/imageSelector/imageSelector.component';
import { useTheme } from '../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../_shared/types/themeProps';
import { drawingToolIconMarkers } from '../../../iconMarkers/drawingToolIconMarkers';

type DrawingToolIconMarkerProps = Readonly<{
  selectedIconId: string | null;
  onChangeSelect: (id: string) => void;
}>;

const selectorStyle = ({ height }: { height: number }) => css({
  height,
  width: 'calc(100% + 12px)',
});

const iconWrapperStyle = css({
  padding: 8,
  display: 'flex',
  alignItems: 'center',
});

const iconStyle = ({ theme }: ThemeProps) => css({
  color: theme.textColors.primary,
});

export const DrawingToolIconMarkerOptions: FC<DrawingToolIconMarkerProps> = props => {
  const theme = useTheme();

  const selectedIconId = props.selectedIconId ?? drawingToolIconMarkers[0].id;

  const tileMargin = 9;
  const tileDimension = 35;
  const height = (tileMargin + tileDimension) * Math.ceil(drawingToolIconMarkers.length / 8) - tileMargin;

  useEffect(() => {
    if (!props.selectedIconId) {
      props.onChangeSelect(selectedIconId);
    }
  }, [props, selectedIconId]);

  return (
    <div css={selectorStyle({ height })}>
      <ImageSelectorComponent
        imageHeight={tileDimension}
        images={drawingToolIconMarkers.map((image) => ({
          id: image.id,
          render: () => (
            <div css={iconWrapperStyle}>
              <FontAwesomeIcon
                css={iconStyle({ theme })}
                icon={image.icon}
              />
            </div>
          ),
        }))}
        imageWidth={tileDimension}
        onSelectionChanged={(ids) => {
          if (ids[0]) {
            props.onChangeSelect(ids[0]);
          }
        }}
        paddingHorizontal={tileMargin}
        paddingVertical={tileMargin}
        selectedImagesIds={[selectedIconId]}
        dontUseScrollbar
      />
    </div>
  );
};
