import { type LatLng } from '../../../../_shared/types/latLng';

export type DistanceCalculatorState = Readonly<{
  active: boolean;
  dragging: boolean;
  draggedInstanceId: Uuid | null;
  mode: 'pick-mode' | DistanceCalculatorDrawingMode;
  startingPoint: LatLng | null;
}>;

export type DistanceCalculatorDrawingMode = 'single-point' | 'multiple-point';

export enum DistanceCalculatorDrawingModeEnum {
  SinglePoint = 'single-point',
  MultiplePoint = 'multiple-point',
}
