import {
  useEffect, useState,
} from 'react';
import { type BoundaryFilterRequest } from '~/spreadsheet/filter/boundary/spreadsheetFilterBoundary.types';
import { type BoundaryTerritoryFilterRequest } from '~/spreadsheet/filter/boundaryTerritory/spreadsheetFilterBoundaryTerritory.types';
import { type PolygonFilterRequest } from '~/spreadsheet/filter/polygon/spreadsheetPolygonFilter.types';
import { type RadiusFilterRequest } from '~/spreadsheet/filter/radius/spreadsheetFilterRadius.types';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import { createCancelToken } from '../api/api.helpers';
import { isCancelError } from '../api/apiError.helpers';
import { useIsComponentMountedRef } from '../hooks/useIsComponentMountedRef';
import { type MetricModel } from './metrics.factory';
import { type MetricProps } from './metrics.types';
import {
  getAndProcessMetricsData, type MetricsResults,
} from './useMetricData.helpers';

type UseMetricsDataArguments = {
  selectedMetrics: ReadonlyArray<MetricModel>;
  boundaryFilterRequest?: BoundaryFilterRequest;
  boundaryTerritoryFilterRequest?: BoundaryTerritoryFilterRequest;
  radiusFilterRequest?: RadiusFilterRequest;
  polygonFilterRequest?: PolygonFilterRequest;
  ignoreFilterTree?: boolean;
};

export const useMetricsData = ({ selectedMetrics, boundaryFilterRequest, radiusFilterRequest,
  polygonFilterRequest, boundaryTerritoryFilterRequest, ignoreFilterTree }: UseMetricsDataArguments) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [demographicMetrics, setDemographicMetrics] = useState<MetricProps[]>([]);
  const [spreadsheetColumnMetrics, setSpreadsheetColumnMetrics] = useState<MetricProps[]>([]);
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector();
  const mapSettingsFilterTree = useFilterTreeMapSettingsParams();
  const isMountedRef = useIsComponentMountedRef();

  useEffect(() => {
    if (!clientId || !mapId) {
      return;
    }

    setIsLoading(true);
    setIsError(false);
    const cancelToken = createCancelToken();

    getAndProcessMetricsData({
      clientId,
      mapId,
      cancelToken: cancelToken.token,
      selectedMetrics,
      filterTreeParams: ignoreFilterTree ? undefined : mapSettingsFilterTree,
      boundaryFilterRequest,
      radiusFilterRequest,
      polygonFilterRequest,
      boundaryTerritoryFilterRequest,
    })
      .then((newMetrics) => {
        setDemographicMetrics(newMetrics.demographicMetrics);
        setSpreadsheetColumnMetrics(newMetrics.spreadsheetColumnMetrics);
      })
      .catch((e) => {
        if (isCancelError(e)) {
          return;
        }
        if (isMountedRef.current) {
          setDemographicMetrics([]);
          setSpreadsheetColumnMetrics([]);
          setIsError(true);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [boundaryFilterRequest, boundaryTerritoryFilterRequest, clientId, isMountedRef, mapId,
    polygonFilterRequest, radiusFilterRequest, selectedMetrics, ignoreFilterTree, mapSettingsFilterTree]);

  const metricsResults: MetricsResults = {
    demographicMetrics,
    spreadsheetColumnMetrics,
  };

  return {
    metricsResults,
    isLoading,
    isError,
  };
};
