import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelectGroupingToolGroup } from '~/_shared/hooks/grouping/useSelectGroupingToolGroup.hook';
import {
  type BucketKey,
  type GroupingColumn, GroupingType, isGroupingColumnNumeric, isGroupingColumnText,
  type TextGroupKey,
} from '~/_shared/types/grouping/grouping';
import { getNumericActiveGroupColumnType } from '~/_shared/types/grouping/numericActiveGroupColumnType.helpers';
import {
  isCustomMarkerVisualSettings,
  isStandardMarkerVisualSettings, MarkerStyleType,
} from '~/_shared/types/marker.types';
import { MarkerSettingType } from '~/_shared/types/markers/visualSettings.enums';
import {
  type MarkerSettings, type MarkersVisualSettings,
} from '~/_shared/types/markers/visualSettings.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { filterNonNumericBuckets } from '~/_shared/utils/grouping/grouping.helpers';
import {
  isNumericGroupInfo,
  isTextGroupInfo,
  useGetGroupInfoBySpreadsheetRowId,
} from '~/_shared/utils/grouping/useGetGroupInfoBySpreadsheetRowId.hook';
import {
  type GroupInfo,
  type NumericGroupInfo,
  type TextGroupInfo,
  useGetSpreadsheetRowIdsByGroupInfo,
} from '~/_shared/utils/grouping/useGetSpreadsheetRowIdsByGroupInfo.hook';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  createNumericBucketKey,
  createTextGroupKey,
  getDefaultStyleIdForSecondaryTextGroup, getTextGroupKeyHierarchyIndicator, removeAboveLabelVisualSettings,
  syncMarkerColorAndLabelBackgroundVisualSettings,
} from '~/_shared/utils/markers/markersVisualSettings.helpers';
import {
  DEFAULT_MARKER_STYLE_ID, ID_OF_NUMERICAL_MARKER,
} from '~/_shared/utils/markers/markerVisualSettings.constants';
import { useBucketsWithRange } from '~/_shared/utils/markers/useBucketsWithRange.helpers';
import { type ModalProps } from '~/modal/modalType.enum';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useMapSettingsGroupingActiveGroupColumnsSelector } from '~/store/mapSettings/grouping/mapSettingsGrouping.selectors';
import {
  mapSettingsUpdateMarkersGeneral, removeGroupMarkerSettings, removeIndividualMarkerSettings,
  setIndividualMarkerSettings, setNumericGroupBucketMarkerSettings, setTextGroupMarkerSettings,
} from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.actionCreators';
import { useMapSettingsMarkersGeneralSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import {
  getGroupingColumnsUniqueGroups,
  NON_NUMERICAL_VALUE,
  type NumericBucketWithRange,
} from '~/store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { useSpreadsheetDataDataSelector } from '~/store/spreadsheetData/spreadsheetData.selectors';
import { emptySpreadsheetData } from '~/store/spreadsheetData/spreadsheetData.state';
import { trackUserEvent } from '~/store/userEvents/userEvents.actionCreator';
import { createUserEvent } from '~/store/userEvents/userEvents.helper';
import { isGroupPrimary } from '../grouping/groupingColumns.helpers';
import { CustomizationLevel } from './customizeLabelAndMarker.types';
import { CustomizeLabelAndMarkerSettingsModalContainer } from './customizeLabelAndMarkerSettingsModal.container';
import { type CustomizeMarkerTab } from './customizeMarker/customizeMarker.component';

export type CustomizationLevelProps = Readonly<{
  availableCustomizationLevels: { value: CustomizationLevel; name: string }[];
  customizationLevel: CustomizationLevel;
  setCustomizationLevel: (newLevel: CustomizationLevel) => void;
  overrideGroupMarkers: boolean;
  setOverrideGroupMarkers: ((overrideGroupMarkers: boolean) => void) | null;
}>;

type CustomizationLevelDependentData = {
  caption: string;
  groupBuckets?: NumericBucketWithRange[];
  markerSettingsTypes: NonEmptyArray<MarkerSettingType>;
  optionalLabelDisabled?: boolean;

  onReset: (visuals: MarkersVisualSettings) => MarkersVisualSettings; //kept this mechanism here if we wanted to show the marker after reset in the future
  onSave: (settings: MarkerSettings | null) => void;
};

export type CustomizeLabelAndMarkerModalContainerProps = ModalProps<{
  customizationLevel: CustomizationLevel;
  overrideGroupMarkers?: boolean;
  defaultTab?: CustomizeMarkerTab;
  groupInfo?: Readonly<{
    bucketId?: number;
    groupId?: string;
    groupingColumn: GroupingColumn;
    hierarchyIndicator?: number;
  }>;
  markerSettingsTypes: NonEmptyArray<MarkerSettingType>;
  spreadsheetRowId?: SpreadsheetRowId;
}>;

export const CustomizeLabelAndMarkerModalContainer: React.FC<CustomizeLabelAndMarkerModalContainerProps> = props => {
  const { markerSettingsTypes, spreadsheetRowId, onClose } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const markersVisualSettings = useMapSettingsMarkersGeneralSelector();
  const groupingColumns = useMapSettingsGroupingActiveGroupColumnsSelector();
  const spreadsheetData = useSpreadsheetDataDataSelector();
  const matchupData = useMatchupDataSelector();
  const areLabelsActive = !!markersVisualSettings.useTextLabel || !!markersVisualSettings.useNumericLabel;
  const isGroupingActive = !!groupingColumns[0];

  const [selectedCustomizationLevel, setSelectedCustomizationLevel] = useState<CustomizationLevel>(props.customizationLevel);
  const [overrideGroupMarkers, setOverrideGroupMarkers] = useState<boolean>(!!props.overrideGroupMarkers);

  const individualMarkerGroupInfo = useGetGroupInfoBySpreadsheetRowId(spreadsheetRowId, props.groupInfo?.hierarchyIndicator || 0);
  const currentDefaultSecondaryTextMarkerStyleId = getDefaultStyleIdForSecondaryTextGroup(markersVisualSettings);

  const selectGroupProps = useSelectGroupingToolGroup();

  const currentlyUsedGroupingColumn = useMemo(() => (
    props.groupInfo
      ? props.groupInfo.groupingColumn
      : individualMarkerGroupInfo
        ? individualMarkerGroupInfo.groupingColumn
        : selectGroupProps.selectedGroupingColumn || undefined
  ), [individualMarkerGroupInfo, props.groupInfo, selectGroupProps.selectedGroupingColumn]);

  const groupBuckets = useBucketsWithRange(currentlyUsedGroupingColumn);

  const groupInfo: GroupInfo | null = useMemo(() => {
    if (props.groupInfo) {
      if (isGroupingColumnText(props.groupInfo.groupingColumn)) {
        return {
          groupingColumn: props.groupInfo.groupingColumn,
          groupId: props.groupInfo.groupId,
        } as TextGroupInfo;
      }
      if (isGroupingColumnNumeric(props.groupInfo.groupingColumn)) {
        return {
          groupingColumn: props.groupInfo.groupingColumn,
          bucketId: props.groupInfo.bucketId,
        } as NumericGroupInfo;
      }
    }
    else if (individualMarkerGroupInfo) {
      if (isTextGroupInfo(individualMarkerGroupInfo)) {
        return {
          groupingColumn: individualMarkerGroupInfo.groupingColumn,
          uniqueGroupIndex: individualMarkerGroupInfo.group.uniqueGroupId,
          groupId: individualMarkerGroupInfo.group.groupData.id,
        };
      }
      if (isNumericGroupInfo(individualMarkerGroupInfo)) {
        return {
          groupingColumn: individualMarkerGroupInfo.groupingColumn,
          bucketId: individualMarkerGroupInfo.bucket.id,
        };
      }
    }
    else if (selectGroupProps.selectedGroupingColumn && selectGroupProps.isSelectionValid) {
      if (isGroupingColumnText(selectGroupProps.selectedGroupingColumn) && selectGroupProps.selectedGroupId !== null) {
        return {
          groupingColumn: selectGroupProps.selectedGroupingColumn,
          groupId: selectGroupProps.selectedGroupId,
        } as TextGroupInfo;
      }
      if (isGroupingColumnNumeric(selectGroupProps.selectedGroupingColumn) && selectGroupProps.selectedBucketId !== null) {
        return {
          groupingColumn: selectGroupProps.selectedGroupingColumn,
          bucketId: selectGroupProps.selectedBucketId,
        } as NumericGroupInfo;
      }
    }
    return null;
  }, [individualMarkerGroupInfo, props.groupInfo, selectGroupProps]);

  const handleSetCustomizationLevel = useCallback((newCustomizationLevel: CustomizationLevel) => {
    if (newCustomizationLevel === CustomizationLevel.Group && !isGroupingActive) {
      onClose();
      dispatch(openMapTool(MapTool.Grouping));
      return;
    }
    setSelectedCustomizationLevel(newCustomizationLevel);
  }, [dispatch, isGroupingActive, onClose]);

  const customizationLevelData: CustomizationLevelProps = useMemo(() => {
    let availableCustomizationLevels = new Set<CustomizationLevel>([]);
    let customizationLevel = selectedCustomizationLevel;
    switch (props.customizationLevel) {
      case CustomizationLevel.Individual:
        availableCustomizationLevels = new Set<CustomizationLevel>([
          CustomizationLevel.Individual,
          CustomizationLevel.Group,
          CustomizationLevel.All,
        ]);
        break;
      case CustomizationLevel.Group:
        availableCustomizationLevels = new Set<CustomizationLevel>([
          CustomizationLevel.All, CustomizationLevel.Group,
        ]);
        break;
      case CustomizationLevel.All:
      default:
        availableCustomizationLevels = new Set<CustomizationLevel>([
          CustomizationLevel.All,
          CustomizationLevel.Group,
        ]);
    }
    if (!availableCustomizationLevels.has(customizationLevel)) {
      customizationLevel = props.customizationLevel;
    }

    return {
      customizationLevel,
      overrideGroupMarkers,
      availableCustomizationLevels: Array.from(availableCustomizationLevels).map(value => ({
        value,
        name: value !== CustomizationLevel.Group
          ? t(value)
          : !isGroupingActive
            ? t('Add Grouping Column')
            : !props.groupInfo && !individualMarkerGroupInfo
              ? t('Group')
              : t(value),
      })),
      setCustomizationLevel: handleSetCustomizationLevel,
      setOverrideGroupMarkers: isGroupingActive ? setOverrideGroupMarkers : null,
    };
  }, [selectedCustomizationLevel, props.customizationLevel, props.groupInfo, overrideGroupMarkers,
    handleSetCustomizationLevel, isGroupingActive, t, individualMarkerGroupInfo,
  ]);

  const allowGroupSelection = isGroupingActive
      && customizationLevelData.customizationLevel === CustomizationLevel.Group
      && !props.groupInfo
      && !individualMarkerGroupInfo;

  const finalSelectGroupProps = useMemo(() => ({
    ...selectGroupProps,
    ...(!allowGroupSelection ? {
      setSelectedGroupingColumn: undefined,
      setSelectedUniqueGroup: undefined,
    } : {}),
  }), [allowGroupSelection, selectGroupProps]);

  const hierarchyIndicator = props.groupInfo?.hierarchyIndicator || (allowGroupSelection ? finalSelectGroupProps.selectedGroupingColumnHierarchyIndicator || 0 : 0);

  const allIndividualMarkersInGroup = useGetSpreadsheetRowIdsByGroupInfo(groupInfo);
  const groupingActiveAndCustomizingAll = isGroupingActive && customizationLevelData.customizationLevel === CustomizationLevel.All;

  const uniqueGroupColumns = useMemo(() => getGroupingColumnsUniqueGroups(
    spreadsheetData ?? emptySpreadsheetData,
    matchupData,
    groupInfo?.groupingColumn || undefined,
  ), [matchupData, groupInfo?.groupingColumn, spreadsheetData]);

  const numericActiveGroupColumnType = useMemo(() => (
    getNumericActiveGroupColumnType(groupingColumns)
  ), [groupingColumns]);

  const textGroupKey = useMemo(() => (
    groupInfo?.groupingColumn.type === GroupingType.Text
      ? createTextGroupKey(
        (groupInfo as TextGroupInfo).groupId,
        isGroupPrimary(hierarchyIndicator)
      )
      : undefined
  ), [groupInfo, hierarchyIndicator]);

  const bucketKey = useMemo(() => (
    groupInfo?.groupingColumn.type === GroupingType.Numeric && numericActiveGroupColumnType
      ? createNumericBucketKey(
        numericActiveGroupColumnType, hierarchyIndicator, filterNonNumericBuckets(uniqueGroupColumns).length,
      )
      : undefined
  ), [groupInfo, numericActiveGroupColumnType, hierarchyIndicator, uniqueGroupColumns]);

  const handleSaveIndividual = useCallback((settings: MarkerSettings | null) => {
    if (!props.spreadsheetRowId) {
      return;
    }
    if (settings) {
      dispatch(trackUserEvent(createUserEvent('IndividualMarkerCustomizationSaved')));
      dispatch(setIndividualMarkerSettings(props.spreadsheetRowId, removeAboveLabelVisualSettings(settings)));
    }
    else {
      dispatch(removeIndividualMarkerSettings([props.spreadsheetRowId]));
    }
  }, [dispatch, props.spreadsheetRowId]);

  const handleResetIndividual = useCallback((visuals: MarkersVisualSettings) => ({
    ...visuals,
    individualMarkerSettings: undefined,
  }), []);

  const handleSaveGroup = useCallback((settings: MarkerSettings | null) => {
    if (!groupInfo?.groupingColumn) {
      return;
    }

    if (!settings) {
      dispatch(removeGroupMarkerSettings([{
        spreadsheetId: groupInfo.groupingColumn.spreadsheetId,
        columnId: groupInfo.groupingColumn.columnId,
      }]));
      return;
    }

    const newSettingsWithoutIrrelevantLabelVisualSettings = removeAboveLabelVisualSettings(settings);
    const newSettingsWithSynchronizedColors = areLabelsActive ?
      newSettingsWithoutIrrelevantLabelVisualSettings :
      syncMarkerColorAndLabelBackgroundVisualSettings(newSettingsWithoutIrrelevantLabelVisualSettings);

    dispatch(trackUserEvent(createUserEvent('GroupMarkerCustomizationSaved')));

    if (textGroupKey) {
      dispatch(setTextGroupMarkerSettings({
        spreadsheetId: groupInfo.groupingColumn.spreadsheetId,
        columnId: groupInfo.groupingColumn.columnId,
        groupKey: textGroupKey,
        settings: newSettingsWithSynchronizedColors,
      }));
    }
    else if (bucketKey) {
      dispatch(setNumericGroupBucketMarkerSettings({
        spreadsheetId: groupInfo.groupingColumn.spreadsheetId,
        columnId: groupInfo.groupingColumn.columnId,
        bucketKey,
        bucketId: (groupInfo as NumericGroupInfo).bucketId,
        settings: newSettingsWithSynchronizedColors,
      }));
    }

    // remove any individually customized markers in this group
    // removing must be done last, otherwise syncActiveGroupMarkerAttachmentsSaga would remove added textures above
    if (allIndividualMarkersInGroup.length) {
      dispatch(removeIndividualMarkerSettings(allIndividualMarkersInGroup));
    }
  }, [areLabelsActive, groupInfo, textGroupKey, bucketKey, allIndividualMarkersInGroup, dispatch]);

  const handleResetGroup = useCallback((visuals: MarkersVisualSettings) => ({
    ...visuals,
    groupMarkerSettings: undefined,
  }), []);

  const handleSaveCustomizeAll = useCallback((settings: MarkerSettings | null) => {
    const maybeUpdateDefaultMarker = isGroupPrimary(hierarchyIndicator) && settings?.marker?.styleType === MarkerStyleType.STANDARD
      ? { defaultMarkerSetId: settings.marker?.styleId }
      : undefined;

    // if the modal was invoked for non-primary grouping column
    // and "all markers" was selected in the dropdown
    // we want to change the default marker for that grouping column
    let maybeUpdateGroupMarkerDefaultMarkerSetIds = undefined;
    let maybeUpdateGroupsDefaultMarker = undefined;
    const newMarkerSettings = settings?.marker;
    if (groupInfo && !isGroupPrimary(hierarchyIndicator) && newMarkerSettings) {
      const spreadsheetId = groupInfo.groupingColumn.spreadsheetId;
      const columnId = groupInfo.groupingColumn.columnId;

      if (groupInfo?.groupingColumn.type === GroupingType.Text && isStandardMarkerVisualSettings(newMarkerSettings)) {

        maybeUpdateGroupMarkerDefaultMarkerSetIds = {
          groupMarkerDefaultMarkerSetIds: {
            ...markersVisualSettings.groupMarkerDefaultMarkerSetIds,
            [hierarchyIndicator]: {
              ...markersVisualSettings.groupMarkerDefaultMarkerSetIds?.[hierarchyIndicator],
              [GroupingType.Text]: {
                ...markersVisualSettings.groupMarkerDefaultMarkerSetIds?.[hierarchyIndicator]?.[GroupingType.Text],
                styleId: newMarkerSettings.styleId,
              },
            },
          },
        };

        const existing = markersVisualSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Text];
        if (existing) {
          maybeUpdateGroupsDefaultMarker = {
            groupMarkerSettings: {
              ...markersVisualSettings.groupMarkerSettings,
              [spreadsheetId]: {
                ...markersVisualSettings.groupMarkerSettings?.[spreadsheetId],
                [columnId]: {
                  ...markersVisualSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId],
                  [GroupingType.Text]: {
                    ...existing,
                    ...Object.entries(existing).reduce<{ [bucketId: TextGroupKey ]: MarkerSettings }>((acc, [key, value]) => {
                      if (getTextGroupKeyHierarchyIndicator(key) === hierarchyIndicator && value?.marker
                        // if the marker was customized, we don't want to override it, unless overrideGroupMarkers is on
                        && (overrideGroupMarkers
                          || (isStandardMarkerVisualSettings(value.marker) && value.marker.styleId === currentDefaultSecondaryTextMarkerStyleId)
                        )
                      ) {
                        acc[key] = {
                          ...value,
                          marker: {
                            ...value.marker,
                            styleType: MarkerStyleType.STANDARD,
                            styleId: newMarkerSettings.styleId,
                          },
                        };
                      }
                      return acc;
                    }, {}),
                  },
                },
              },
            },
          };
        }
      }
      else if (groupInfo?.groupingColumn.type === GroupingType.Numeric && bucketKey) {

        const existing = markersVisualSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Numeric]?.[bucketKey];

        const mostPrevalentStyle = existing ? Object.values(existing).reduce((acc, value) => {
          if (value?.marker) {
            if (isStandardMarkerVisualSettings(value.marker)) {
              acc.standard[value.marker.styleId] = (acc.standard[value.marker.styleId] || 0) + 1;
            }
            if (isCustomMarkerVisualSettings(value.marker)) {
              acc.custom[value.marker.fileAttachmentId] = (acc.custom[value.marker.fileAttachmentId] || 0) + 1;
            }
          }
          return acc;
        }, { standard: {}, custom: {} } as { standard: {[styleId: number]: number}; custom: {[id: string]: number} }) : { standard: {}, custom: {} };
        const mostPrevalentStandardStyleId = Object.entries(mostPrevalentStyle.standard).reduce((acc, [key, value]) => {
          return value > acc[1] ? [parseInt(key, 10), value] : acc;
        }, [ID_OF_NUMERICAL_MARKER, 0] as const)[0];
        const mostPrevalentCustomId = Object.entries(mostPrevalentStyle.custom).reduce((acc, [key, value]) => {
          return value > acc[1] ? [key, value] : acc;
        }, ['X', 0] as const)[0];

        if (existing) {
          maybeUpdateGroupsDefaultMarker = {
            groupMarkerSettings: {
              ...markersVisualSettings.groupMarkerSettings,
              [spreadsheetId]: {
                ...markersVisualSettings.groupMarkerSettings?.[spreadsheetId],
                [columnId]: {
                  ...markersVisualSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId],
                  [GroupingType.Numeric]: {
                    ...markersVisualSettings.groupMarkerSettings?.[spreadsheetId]?.[columnId]?.[GroupingType.Numeric],
                    [bucketKey]: {
                      ...existing,
                      ...Object.entries(existing).reduce<{[bucketId: BucketKey]: MarkerSettings }>((acc, [key, value]) => {
                        if (value?.marker && parseInt(key, 10) !== NON_NUMERICAL_VALUE
                        // if the marker was customized, we don't want to override it, unless overrideGroupMarkers is on
                        && (overrideGroupMarkers
                          || (isStandardMarkerVisualSettings(value.marker) && [ID_OF_NUMERICAL_MARKER, mostPrevalentStandardStyleId].includes(value.marker.styleId))
                          || (isCustomMarkerVisualSettings(value.marker) && value.marker.fileAttachmentId === mostPrevalentCustomId)
                        )) {
                          acc[key] = {
                            ...value,
                            marker: {
                              ...value.marker,
                              ...(isCustomMarkerVisualSettings(newMarkerSettings) ? {
                                styleType: MarkerStyleType.CUSTOM,
                                fileAttachmentId: newMarkerSettings.fileAttachmentId,
                              } : {
                                styleType: MarkerStyleType.STANDARD,
                                styleId: newMarkerSettings.styleId,
                              }),
                            },
                          };
                        }
                        return acc;
                      }, {}),
                    },
                  },
                },
              },
            },
          };
        }
      }
    }

    dispatch(mapSettingsUpdateMarkersGeneral({
      globalMarkerSettings: isGroupPrimary(hierarchyIndicator) && settings ? {
        ...settings,
        marker: settings.marker ? {
          ...settings.marker,
          // Global marker color is not customizable when groupping is enabled
          ...(groupingActiveAndCustomizingAll ? {
            selectedColor: markersVisualSettings.globalMarkerSettings?.marker?.selectedColor,
            opacity: markersVisualSettings.globalMarkerSettings?.marker?.opacity,
          } : undefined),
        } : undefined,
      } : undefined,
      ...maybeUpdateDefaultMarker,
      ...maybeUpdateGroupsDefaultMarker,
      ...maybeUpdateGroupMarkerDefaultMarkerSetIds,
    }));

    // removing must be done last, otherwise syncActiveGroupMarkerAttachmentsSaga would remove added textures above
    if (overrideGroupMarkers && groupingColumns && !!groupingColumns[0]) {
      dispatch(removeGroupMarkerSettings([{
        spreadsheetId: groupingColumns[0].spreadsheetId,
        columnId: groupingColumns[0].columnId,
      }]));
    }
  }, [bucketKey, dispatch, groupInfo, groupingActiveAndCustomizingAll, groupingColumns,
    hierarchyIndicator, markersVisualSettings.globalMarkerSettings?.marker?.opacity,
    markersVisualSettings.globalMarkerSettings?.marker?.selectedColor,
    markersVisualSettings.groupMarkerDefaultMarkerSetIds,
    markersVisualSettings.groupMarkerSettings, overrideGroupMarkers,
    currentDefaultSecondaryTextMarkerStyleId,
  ]);

  const handleResetAll = useCallback((visuals: MarkersVisualSettings) => ({
    ...visuals,
    defaultMarkerSetId: DEFAULT_MARKER_STYLE_ID,
    globalMarkerSettings: undefined,
  }), []);

  const customizationLevelDependentData = useMemo((): CustomizationLevelDependentData => {
    switch (customizationLevelData.customizationLevel) {
      case CustomizationLevel.Individual:
        return {
          caption: t('Individual Markers Settings'),
          ...(groupInfo?.groupingColumn.type === GroupingType.Numeric && groupBuckets && { groupBuckets }),
          markerSettingsTypes: areLabelsActive
            ? [MarkerSettingType.Marker, MarkerSettingType.Label] : [MarkerSettingType.Marker],
          onReset: handleResetIndividual,
          onSave: handleSaveIndividual,
        };
      case CustomizationLevel.Group:
        return {
          caption: t('Group Markers Settings'),
          ...(groupInfo?.groupingColumn.type === GroupingType.Numeric && groupBuckets && { groupBuckets }),
          markerSettingsTypes: areLabelsActive && isGroupPrimary(hierarchyIndicator)
            ? [MarkerSettingType.Marker, MarkerSettingType.Label] : [MarkerSettingType.Marker],
          optionalLabelDisabled: !isGroupPrimary(hierarchyIndicator),
          onReset: handleResetGroup,
          onSave: handleSaveGroup,
        };
      case CustomizationLevel.All:
      default:
        return {
          caption: markerSettingsTypes?.length === 1 && markerSettingsTypes[0] === MarkerSettingType.LabelAbove
            ? t('General Label Above Markers Settings')
            : markerSettingsTypes?.length === 1 && markerSettingsTypes[0] === MarkerSettingType.Label
              ? t('General Label Marker Settings') : t('General Marker Settings'),
          markerSettingsTypes,
          optionalLabelDisabled: isGroupingActive,
          onReset: handleResetAll,
          onSave: handleSaveCustomizeAll,
        };
    }
  }, [customizationLevelData, groupInfo?.groupingColumn, t, groupBuckets, areLabelsActive,
    handleResetIndividual, handleSaveIndividual, hierarchyIndicator, handleResetGroup, handleSaveGroup,
    markerSettingsTypes, handleResetAll, handleSaveCustomizeAll, isGroupingActive,
  ]);

  // When coming from grouping tool the marker should by default look like the selected group marker
  // When coming from invididual marker, we still need bucket info to display the correct marker
  const forVisualizationGroupInfo = useMemo(() => (
    props.groupInfo ? {
      hierarchyIndicator: props.groupInfo.hierarchyIndicator,
      groupBuckets,
      groupId: props.groupInfo.groupId,
      bucketId: props.groupInfo.bucketId,
    } : props.spreadsheetRowId ? { groupBuckets } : undefined
  ), [groupBuckets, props.groupInfo, props.spreadsheetRowId]);

  return (
    <CustomizeLabelAndMarkerSettingsModalContainer
      caption={customizationLevelDependentData.caption}
      customizationLevelProps={customizationLevelData}
      defaultTab={props.defaultTab}
      groupInfo={forVisualizationGroupInfo}
      isOpen={props.isOpen}
      visualMarkersSettings={markersVisualSettings}
      markerSettingsTypes={customizationLevelDependentData.markerSettingsTypes}
      optionalLabelDisabled={customizationLevelDependentData.optionalLabelDisabled}
      spreadsheetRowId={props.spreadsheetRowId}
      onClose={onClose}
      onReset={customizationLevelDependentData.onReset}
      onSave={!allowGroupSelection || finalSelectGroupProps.isSelectionValid ? customizationLevelDependentData.onSave : undefined}
      colorCustomizationDisabled={groupingActiveAndCustomizingAll}
      selectGroupProps={finalSelectGroupProps}
    />
  );
};
