import {
  createContext, type FC, type ReactNode, useContext, useMemo,
} from 'react';
import { type Nullable } from '~/_shared/utils/types/common.type';
import { type ActiveMarkerIndicatorSettings } from '~/store/mapSettings/markers/mapSettingsMarkers.state';

export type ActiveMarkerIndicatorContextModel = {
  readonly activeMarkerIndicatorSettings: ActiveMarkerIndicatorSettings;
  readonly changeActiveMarkerIndicatorSettings: (newSettings: Partial<ActiveMarkerIndicatorSettings>) => void;
};

const ActiveMarkerIndicatorContext = createContext<ActiveMarkerIndicatorContextModel | null>(null);

export type ActiveMarkerIndicatorContextProviderProps
  = Partial<Nullable<ActiveMarkerIndicatorContextModel>> & { children: ReactNode };

export const ActiveMarkerIndicatorContextProvider: FC<ActiveMarkerIndicatorContextProviderProps> = ({
  activeMarkerIndicatorSettings, changeActiveMarkerIndicatorSettings, children,
}) => {
  const context = useMemo(() => (
    activeMarkerIndicatorSettings && changeActiveMarkerIndicatorSettings
      ? { activeMarkerIndicatorSettings, changeActiveMarkerIndicatorSettings } : null
  ), [activeMarkerIndicatorSettings, changeActiveMarkerIndicatorSettings]);

  if (!context) {
    return null;
  }

  return (
    <ActiveMarkerIndicatorContext.Provider value={context}>
      {children}
    </ActiveMarkerIndicatorContext.Provider>
  );
};

export const useActiveMarkerIndicatorContext = (): ActiveMarkerIndicatorContextModel => {
  const context = useContext(ActiveMarkerIndicatorContext);

  if (!context) {
    throw new Error('Customize Marker Modal Components cannot be used without the context provider.');
  }

  return context;
};
