import { css } from '@emotion/react';
import {
  faGlobe, faRefresh,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { noop } from 'ts-essentials';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import {
  IconButtonComponent, IconButtonStyle,
} from '~/_shared/baseComponents/buttons';
import { CheckboxComponent } from '~/_shared/baseComponents/checkbox';
import {
  DropDownItemSize, getRegularDropdownItemSize,
} from '~/_shared/baseComponents/dropdown';
import {
  InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import {
  TooltipBehavior, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { MapColumnPickerDropdownContainer } from '~/_shared/components/mapColumnPickerDropdown/mapColumnPickerDropdown.container';
import { MapPickerDropdownContainer } from '~/_shared/components/mapPickerDropdown/mapPickerDropdown.container';
import { CREATE_NEW_SALES_REP_MAP_ACTION } from '~/_shared/hooks/appLoad/useCreateSalesRepMapOnAppLoad.hook';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { createUuid } from '~/_shared/utils/createUuid';
import { delay } from '~/_shared/utils/delay';
import {
  type TranslationFnc,
  useTranslation,
} from '~/_shared/utils/hooks';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import {
  isNullOrUndefined, notNullOrUndefined,
} from '~/_shared/utils/typeGuards';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { ContinueButton } from '~/map/layered/createLayeredMapModal/ContinueButton';
import { type MapListingItem } from '~/map/listing/item/mapListingItem';
import {
  type MetricValue,
  NumberOfTerritoriesLastUpdateReason,
  type SalesRepLocationsMap,
} from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/createBoundaryGroupFromAIModal.types';
import { EXTERNAL_ACTION_INDICATOR } from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import { AdditionalOptionsConstraintsComponent } from './additionalOptionsConstraints.component';
import {
  AdditionalOptionsWarningMessageComponent,
  AdditionalWarningMessageType,
} from './additionalOptionsWarningMessage.component';
import { useConstraintsValidation } from './constraintsValidation/useConstraintsValidation';

export const WMS_MIN_NUMBER_OF_TERRITORIES = 2;

const getNumberOfTerritoriesUpdatedReasonMessage = (reason: NumberOfTerritoriesLastUpdateReason, t: TranslationFnc) => {
  if (reason === NumberOfTerritoriesLastUpdateReason.RespectPreviousTerritoriesChange) {
    return t('boundaryAI.We changed your requested number of territories to match the number of the previous territories.');
  }

  if (reason === NumberOfTerritoriesLastUpdateReason.SalesRepLocationsMapIdChange) {
    return t('boundaryAI.We changed your requested number of territories to match the number of sales reps.');
  }

  return null;
};

const SECTION_GAP_MARGIN = 20;

const sectionContainer = css({
  marginBottom: SECTION_GAP_MARGIN,
});

const rowContainer = css({
  display: 'flex',
  alignItems: 'center',
  flexSpacing: 5,
});

const contentContainerStyle = css({
  marginTop: 10,
  paddingLeft: 25,
});

const rowLabel = ({ isDisabled }: {isDisabled: boolean}) => css({
  textTransform: 'uppercase',
  marginLeft: 10,
  cursor: isDisabled ? 'default' : 'pointer',
  opacity: isDisabled ? 0.5 : 1,
});

const selectLabelStyle = css({
  marginBottom: 5,
});

const selectMapContainer = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: 10,
});

const continueButtonStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
});

const dropdownItemStyle = ({ theme, size }: ThemeProps<{ size: InputSize}>) => css({
  fontSize: 16,
  lineHeight: '20px',
  padding: '0 8px',
  height: getRegularDropdownItemSize(size, DropDownItemSize.Default),
  textAlign: 'left',
  color: theme.textColors.secondary,
  display: 'block',
  whiteSpace: 'nowrap',
  background: 'none',
  border: `1px solid ${theme.borderColors.primary}`,
  borderWidth: '1px 0 0',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: theme.backgroundColors.secondary,
  cursor: 'pointer',
});

const refreshTerritoriesIconStyle = css({
  marginLeft: 10,
  marginRight: 10,
});

export type AdditionalOptionsStepComponentProps = Readonly<{
  customBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  dataMetricsValues: MetricValue[];
  demographicsValues: MetricValue[];
  numberOfTerritories: number | null;
  numberOfTerritoriesLoading: boolean;
  numberOfTerritoriesUpdateReason: NumberOfTerritoriesLastUpdateReason;
  onChangeDataMetrics: (dataMetricsValues: MetricValue[]) => void;
  onChangeDemographics: (demographicsValues: MetricValue[]) => void;
  onChangeNumberOfTerritories: (value: number) => void;
  onChangeOptimizationConstraints: (flag: boolean) => void;
  onChangeOverrideTerritory: (flag: boolean) => void;
  onChangePreviouslySavedBoundaryGroupId: (previouslySavedBoundaryGroupId: number) => void;
  onChangeRespectPrevTerritories: (flag: boolean) => void;
  onChangeRespectSalesLocations: (flag: boolean) => void;
  onChangeSalesRepLocationsMap: (map: SalesRepLocationsMap) => void;
  onChangeSalesRepNameColumnId: (columnId: string) => void;
  onChangeUseNumberOfTerritories: (flag: boolean) => void;
  onContinue: () => void;
  onRefreshTerritoriesCount: () => void;
  optimizationConstraints: boolean;
  overrideTerritory: boolean;
  previouslySavedBoundaryGroupId: number | null;
  respectPrevTerritories: boolean;
  respectSalesLocations: boolean;
  salesRepLocationsMap: SalesRepLocationsMap | null;
  salesRepNameColumnId: string | null;
  useNumberOfTerritories: boolean;
  zipCodesSubset?: ReadonlyArray<string>;
  statesSubset?: ReadonlyArray<string>;
}>;

export const AdditionalOptionsStepComponent: FC<AdditionalOptionsStepComponentProps> = ({
  customBoundaryGroups,
  dataMetricsValues,
  demographicsValues,
  numberOfTerritories,
  numberOfTerritoriesUpdateReason,
  onChangeDataMetrics,
  onChangeDemographics,
  onChangeNumberOfTerritories,
  onChangeOptimizationConstraints,
  onChangePreviouslySavedBoundaryGroupId,
  onChangeRespectPrevTerritories,
  onChangeRespectSalesLocations,
  onChangeSalesRepLocationsMap,
  onChangeSalesRepNameColumnId,
  onChangeUseNumberOfTerritories,
  onContinue,
  onRefreshTerritoriesCount,
  numberOfTerritoriesLoading,
  optimizationConstraints,
  previouslySavedBoundaryGroupId,
  respectPrevTerritories,
  respectSalesLocations,
  salesRepLocationsMap,
  salesRepNameColumnId,
  useNumberOfTerritories,
  zipCodesSubset,
  statesSubset,
}: AdditionalOptionsStepComponentProps) => {
  const [salesRepMap, setSalesRepMap] = useState<MapListingItem | null>(null);
  const [lastChangeNumberOfTerritories, setLastChangeNumberOfTerritories] = useState({ reason: NumberOfTerritoriesLastUpdateReason.Other, id: createUuid() });
  const previousNumberOfTerritories = usePrevious(numberOfTerritories);
  const forceRefreshCallbackRef = useRef<() => void>(() => null);
  const [t] = useTranslation();
  const theme = useTheme();

  const numberOfTerritoriesChangedMessage = getNumberOfTerritoriesUpdatedReasonMessage(lastChangeNumberOfTerritories.reason, t);

  const { areConstraintsValid, isLoading: areConstraintsLoading, validationData, generalError: constraintsGeneralError } = useConstraintsValidation({
    optimizationConstraints,
    demographicsValues,
    dataMetricsValues,
    statesSubset,
    zipCodesSubset,
  });

  const onOpenCreateSalesRepMapTab = useCallback(() => {
    window.open(
      `${location.protocol}//${location.host}?${EXTERNAL_ACTION_INDICATOR}=${CREATE_NEW_SALES_REP_MAP_ACTION}`,
      '_blank'
    )?.focus();
  }, []);

  const onRefreshMaps = useCallback(() => {
    forceRefreshCallbackRef.current();
  }, []);

  const onSubmit = useCallback(() => {
    onContinue();
  }, [onContinue]);

  const onSalesRepMapChange = useCallback((map: MapListingItem) => {
    setSalesRepMap(map);
    const spreadsheetId = map.spreadSheets[0]?.spreadsheetId;
    if (!spreadsheetId) {
      return;
    }

    onChangeSalesRepLocationsMap({ mapId: map.id, spreadsheetId });
  }, [onChangeSalesRepLocationsMap]);

  useEffect(() => {
    if (numberOfTerritoriesUpdateReason === NumberOfTerritoriesLastUpdateReason.Other
      || numberOfTerritoriesUpdateReason === NumberOfTerritoriesLastUpdateReason.ManualEdit
      || previousNumberOfTerritories !== undefined && notNullOrUndefined(numberOfTerritories) && previousNumberOfTerritories !== numberOfTerritories) {
      const newReasonId = createUuid();

      // use delay to update the message in next render cycle
      // tooltip needs the component already rendered / updated to calculate position properly
      delay(1).then(() => setLastChangeNumberOfTerritories({ reason: numberOfTerritoriesUpdateReason, id: newReasonId }));

      const clearUpdatedReason = () => {
        setLastChangeNumberOfTerritories(previous => previous.id === newReasonId
          ? { reason: NumberOfTerritoriesLastUpdateReason.Other, id: createUuid() }
          : previous
        );
      };

      // use delay so the reason isn't cleared within the same onclick event that is bubling up
      delay(1).then(() => window.addEventListener('click', clearUpdatedReason, { once: true }));
    }
  }, [numberOfTerritories, numberOfTerritoriesUpdateReason, previousNumberOfTerritories]);

  const respectSalesRepOptionsValid = !respectSalesLocations || (salesRepLocationsMap && salesRepNameColumnId);
  const respectPreviousTerritoriesValid = !respectPrevTerritories || previouslySavedBoundaryGroupId;
  const constraintsActive = optimizationConstraints && (dataMetricsValues.find(i => i.isConstraint) || demographicsValues.find(i => i.isConstraint));
  const numberOfTerritoriesValid = (notNullOrUndefined(numberOfTerritories) && !numberOfTerritoriesLoading) || constraintsActive;
  const isSubmitDisabled = !respectSalesRepOptionsValid || !respectPreviousTerritoriesValid || !numberOfTerritoriesValid || (optimizationConstraints && !areConstraintsValid);

  return (
    <>
      <div css={sectionContainer}>
        <TooltipDeprComponent
          tooltipContent={optimizationConstraints ? t('boundaryAI.You cannot use "{{disabled}}" together with "{{enabled}}"', {
            disabled: t('boundaryAI.Respect Sales Reps Locations'),
            enabled: t('boundaryAI.Optimize territories using constraints'),
          }) : undefined}
        >
          <div css={rowContainer}>
            <CheckboxComponent
              isChecked={respectSalesLocations}
              checkedSetter={() => onChangeRespectSalesLocations(!respectSalesLocations)}
              isDisabled={optimizationConstraints}
            />
            <span
              onClick={!optimizationConstraints ? () => onChangeRespectSalesLocations(!respectSalesLocations) : noop}
              css={rowLabel({ isDisabled: optimizationConstraints })}
            >{t('boundaryAI.Respect Sales Reps Locations')}
            </span>
          </div>
        </TooltipDeprComponent>

        {respectSalesLocations && (
          <div css={contentContainerStyle}>
            <p css={selectLabelStyle}>{t('boundaryAI.Select Map Containing Sales Rep Locations')}</p>
            <div css={selectMapContainer}>
              <MapPickerDropdownContainer
                onMapSelected={onSalesRepMapChange}
                postFetchFilter={() => true}
                selectedMap={null}
                placeholder={t('boundaryAI.Select Map with Sales People...')}
                inputSize={InputSize.Medium}
                additionalAction={(
                  <button
                    css={dropdownItemStyle({ theme, size: InputSize.Medium })}
                    onClick={onOpenCreateSalesRepMapTab}
                  >
                    {t('boundaryAI.+ Add New Map with sales people')}
                  </button>
                )}
                forceRefreshCallbackRef={forceRefreshCallbackRef}
              />
              <IconButtonComponent
                icon={faRefresh}
                onClick={onRefreshMaps}
                buttonStyle={IconButtonStyle.Secondary}
              />
            </div>
            {salesRepMap && salesRepMap.spreadSheets[0] && (
              <>
                <p css={selectLabelStyle}>{t('boundaryAI.Select Data Column with Sales Rep...')}</p>
                <MapColumnPickerDropdownContainer
                  mapId={salesRepMap.id}
                  virtualSpreadsheetId={salesRepMap.spreadSheets[0].spreadsheetId}
                  onChange={onChangeSalesRepNameColumnId}
                  triggerHeight={InputSize.Medium}
                  placeholder={t('Select Name Column')}
                />
              </>
            )}
          </div>
        )}

        {respectSalesLocations && respectPrevTerritories && (
          <div css={contentContainerStyle}>
            <AdditionalOptionsWarningMessageComponent type={AdditionalWarningMessageType.Warning}>
              {t('boundaryAI.Respecting the previous territory is...')}
            </AdditionalOptionsWarningMessageComponent>
          </div>
        )}
      </div>

      <div css={[sectionContainer, { marginBottom: SECTION_GAP_MARGIN - 12 }]}>
        <div css={rowContainer}>
          <CheckboxComponent
            isChecked={respectPrevTerritories}
            checkedSetter={() => onChangeRespectPrevTerritories(!respectPrevTerritories)}
          />
          <span
            onClick={() => onChangeRespectPrevTerritories(!respectPrevTerritories)}
            css={rowLabel({ isDisabled: false })}
          >
            {t('boundaryAI.Respect previous Territories')}
          </span>
        </div>

        {respectPrevTerritories && (
          <div css={contentContainerStyle}>
            <p css={selectLabelStyle}>{t('boundaryAI.Select Previously Saved Territory')}</p>
            <AutocompleteComponent
              onChange={onChangePreviouslySavedBoundaryGroupId}
              options={customBoundaryGroups.map(group => ({
                name: group.name,
                value: group.id,
              }))}
              value={previouslySavedBoundaryGroupId}
              icon={faGlobe}
              placeholder={t('Select Territory Group')}
              inPortal
              isClearable
            />
          </div>
        )}
      </div>

      <div css={[sectionContainer, { marginBottom: SECTION_GAP_MARGIN - 12 }]}>
        <div css={rowContainer}>
          <CheckboxComponent
            isChecked={useNumberOfTerritories}
            checkedSetter={() => onChangeUseNumberOfTerritories(!useNumberOfTerritories)}
          />
          <span
            onClick={() => onChangeUseNumberOfTerritories(!useNumberOfTerritories)}
            css={[rowLabel({ isDisabled: false })]}
          >
            {t('boundaryAI.Number of territories')}
          </span>
          <TooltipDeprComponent
            placement={TooltipPlacement.Bottom}
            tooltipContent={numberOfTerritoriesChangedMessage}
            behavior={TooltipBehavior.ShowAlways}
          >
            <InputWithSpinnersComponent
              isDisabled={!useNumberOfTerritories}
              onChange={newValue => onChangeNumberOfTerritories(newValue)}
              onBlur={() => {
                if (useNumberOfTerritories && (isNullOrUndefined(numberOfTerritories) || numberOfTerritories < WMS_MIN_NUMBER_OF_TERRITORIES)) {
                  onChangeNumberOfTerritories(2);
                }
              }}
              value={numberOfTerritories ?? undefined}
              size={InputSize.Medium}
              css={{ width: 120, marginLeft: 10 }}
              valueMin={useNumberOfTerritories ? WMS_MIN_NUMBER_OF_TERRITORIES : undefined}
              placeholder={useNumberOfTerritories ? undefined : t('N/A')}
            />
          </TooltipDeprComponent>
          {numberOfTerritoriesLoading && (
            <LoaderComponent
              size={30}
              css={refreshTerritoriesIconStyle}
            />
          )}
          {!numberOfTerritoriesLoading && (respectPrevTerritories || respectSalesLocations) && useNumberOfTerritories && (
            <IconButtonComponent
              tooltipLabel={respectSalesLocations ? t('boundaryAI.Will adjust to the number of Sales Reps') : t('boundaryAI.Will adjust the number to the Previously Saved Territory')}
              css={refreshTerritoriesIconStyle}
              icon={faRefresh}
              onClick={onRefreshTerritoriesCount}
              buttonStyle={IconButtonStyle.Secondary}
            />
          )}
        </div>
        {(useNumberOfTerritories && optimizationConstraints) && (
          <AdditionalOptionsWarningMessageComponent
            css={{ marginLeft: 25, marginBottom: SECTION_GAP_MARGIN }}
            type={AdditionalWarningMessageType.Warning}
          >
            {t('boundaryAI.The actual number of territories might be...')}
          </AdditionalOptionsWarningMessageComponent>
        )}
      </div>
      <div css={sectionContainer}>
        <TooltipDeprComponent
          tooltipContent={respectSalesLocations ? t('boundaryAI.You cannot use "{{disabled}}" together with "{{enabled}}"', {
            disabled: t('boundaryAI.Optimize territories using constraints'),
            enabled: t('boundaryAI.Respect Sales Reps Locations'),
          }) : undefined}
        >
          <div css={rowContainer}>
            <CheckboxComponent
              isChecked={optimizationConstraints}
              checkedSetter={() => onChangeOptimizationConstraints(!optimizationConstraints)}
              isDisabled={respectSalesLocations}
            />
            <span
              onClick={!respectSalesLocations ? () => onChangeOptimizationConstraints(!optimizationConstraints) : noop}
              css={rowLabel({ isDisabled: respectSalesLocations })}
            >
              {t('Optimization with constraints')}
            </span>
          </div>
        </TooltipDeprComponent>

        {optimizationConstraints && (
          <AdditionalOptionsConstraintsComponent
            demographicsValues={demographicsValues}
            dataMetricsValues={dataMetricsValues}
            onChangeDataMetrics={onChangeDataMetrics}
            onChangeDemographics={onChangeDemographics}
            isLoading={areConstraintsLoading}
            validationData={validationData}
            generalError={constraintsGeneralError}
          />
        )}
      </div>

      <div css={continueButtonStyle}>
        <ContinueButton
          label="Continue"
          onSubmitClick={onSubmit}
          disabled={isSubmitDisabled}
        />
      </div>
    </>
  );
};
