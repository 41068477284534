import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { useTranslation } from '~/_shared/utils/hooks';

type ContinueButtonProps = Readonly<{
  label?: string;
  onSubmitClick: () => void;
  disabled?: boolean;
  className?: string;
}>;
export const ContinueButton: React.FC<ContinueButtonProps> = (props) => {
  const [t] = useTranslation();
  return (
    <ButtonComponent
      className={props.className}
      text={props.label ?? t('Continue')}
      onClick={props.onSubmitClick}
      isDisabled={props.disabled}
    />
  );
};
