import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { SPREADSHEET_CELL_HEIGHT } from '~/_shared/components/spreadsheet/cell/spreadsheetCell.component';
import type { SpreadsheetRow } from '~/_shared/components/spreadsheet/spreadsheet.types';
import { useDebounce } from '~/_shared/utils/hooks/useDebounce';
import { useElementDimensions } from '~/_shared/utils/hooks/useElementDimensions';
import { getPolygonPathFromLatLngBounds } from '~/_shared/utils/map/latLngBounds.factory';
import {
  dataTableWrapperStyle,
  overridePaddingStyle, splitScreenDataTableWrapperStyle,
} from '~/data/table/dataTableWrapper.styles';
import { useActiveMarkerIdSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { useMapComponentLastBoundsSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { rightPanelHoverMarker } from '~/store/frontendState/mapTools/locationFinder/locationFinder.actionCreators';
import { usePrimarySpreadsheetId } from '~/store/selectors/usePrimarySpreadsheetId';
import { createAreaRequestGetterFilter } from '~/store/spreadsheetData/area/spreadsheetData.area.helpers';
import { useFilterTreeMapSettingsParams } from '~/store/spreadsheetData/filtering/useFilterTreeMapSettingsParams';
import {
  DataTableComponent, META_CONTAINER_HEIGHT,
} from './dataTable.component';
import { useManageDataTableSpreadsheet } from './useManageDataTableSpreadsheet.hook';

export const DataTableSplitScreenContainer: FC = () => {
  const dispatch = useDispatch();
  const spreadsheetId = usePrimarySpreadsheetId();
  const mapSettingsFilterTree = useFilterTreeMapSettingsParams();
  const lastBounds = useMapComponentLastBoundsSelector()?.bounds ?? null;
  const activeMarkerId = useActiveMarkerIdSelector();

  const [currentBounds, setCurrentBounds] = useState(lastBounds);

  const setCurrentBoundsDebounced = useDebounce(setCurrentBounds, 500);

  const lastBoundsFilter = useMemo(() => {
    if (currentBounds) {
      const paths = getPolygonPathFromLatLngBounds(currentBounds);
      return [paths.map(p => ({ path: p, holes: [] }))];
    }
    return undefined;
  }, [currentBounds]);

  const filterTree = useMemo(() => (
    spreadsheetId
      ? createAreaRequestGetterFilter(
        true,
        'and',
        mapSettingsFilterTree,
        spreadsheetId,
        null,
        lastBoundsFilter,
      ).filter_tree
      : undefined
  ), [mapSettingsFilterTree, lastBoundsFilter, spreadsheetId]);

  const originalDataTableSpreadsheet = useManageDataTableSpreadsheet({
    debounceGetSpreadsheetData: true,
    filterTree,
    initialPerPage: 10,
    spreadsheetId,
    activeMarkerId,
  });

  const highlightedRows = useMemo(() => activeMarkerId ? new Set([activeMarkerId.rowId]) : undefined, [activeMarkerId]);

  const manageDataTableSpreadsheet = {
    ...originalDataTableSpreadsheet,
    paginationProps: {
      ...originalDataTableSpreadsheet.paginationProps,
      perPageOptions: undefined,
    },
  };

  const onHoveredRowChange = useCallback((row: SpreadsheetRow | null) => {
    dispatch(rightPanelHoverMarker(
      row
        ? {
          rowId: row.rowId,
          spreadsheetId: row.virtualSpreadsheetId,
        }
        : null
    ));
  }, [dispatch]);

  useEffect(() => {
    setCurrentBoundsDebounced(lastBounds);
  }, [setCurrentBoundsDebounced, lastBounds]);

  const { ref: wrapperRef, height: wrapperHeight } = useElementDimensions();
  const { ref: metaRef, height: metaHeight } = useElementDimensions();

  const { paginationProps: { onPerPageChange, pagination: { perPage } } } = manageDataTableSpreadsheet;

  useEffect(() => {
    if (wrapperHeight) {
      /**
       * Available height = wrapperHeight - Pagination element height - top and bottom paddings (when pagination is
       * present) We need to measure the outside because adding or removing the pagination changes the dimensions of
       * the inside => causes another request.
       * We always calculate the size as if pagination was there: Either we can
       * fit all the rows and there's a bit of extra space which is normal since we list all the items or the
       * pagination is indeed there.
       */
      const availableHeight = wrapperHeight - META_CONTAINER_HEIGHT - (splitScreenDataTableWrapperStyle.padding + overridePaddingStyle.paddingBottom);
      const newPerPage = Math.floor((availableHeight - SPREADSHEET_CELL_HEIGHT) / SPREADSHEET_CELL_HEIGHT)
        - (activeMarkerId ? 1 : 0);
      if (newPerPage !== perPage && newPerPage > 0) {
        onPerPageChange(newPerPage);
      }
    }
  }, [onPerPageChange, wrapperHeight, perPage, activeMarkerId]);

  return (
    <div
      ref={wrapperRef}
      css={[dataTableWrapperStyle, splitScreenDataTableWrapperStyle, metaHeight ? overridePaddingStyle : undefined]}
    >
      <DataTableComponent
        highlightedRows={highlightedRows}
        isLoading={manageDataTableSpreadsheet.isLoading}
        isSearchFilterActive={false}
        metaRef={metaRef}
        onHoveredRowChange={onHoveredRowChange}
        showBadDataSection={false}
        spreadsheetProps={manageDataTableSpreadsheet}
      />
    </div>
  );
};
