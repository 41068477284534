import { css } from '@emotion/react';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ModalComponent } from '../../../_shared/components/modal/modal.component';
import { useTranslation } from '../../../_shared/utils/hooks';
import { type ModalProps } from '../../../modal/modalType.enum';

export type DirectionsPanelFormAddModalProps = ModalProps<{
  onAddAnother: () => void;
  onReplace: () => void;
  onCancel: () => void;
}>;

const confirmModalButtonWrapperStyle = css({
  marginTop: 10,
  '&:first-of-type': {
    marginTop: 0,
  },
});

const confirmModalButtonStyle = css({
  width: '100%',
  height: 40,
});

const modalStyle = css({
  maxWidth: 400,
});

export const DirectionsPanelFormAddModal: FC<DirectionsPanelFormAddModalProps> = props => {
  const [t] = useTranslation();

  const closeAnd = (fnc: () => void) => () => {
    props.onClose();
    fnc();
  };

  return (
    <ModalComponent
      css={modalStyle}
      isOpen={props.isOpen}
      onClose={closeAnd(props.onCancel)}
      caption={t('How do you want to display your directions?')}
    >
      <div css={confirmModalButtonWrapperStyle}>
        <ButtonComponent
          onClick={closeAnd(props.onAddAnother)}
          css={confirmModalButtonStyle}
          text={t('Add another directions instance')}
        />
      </div>

      <div css={confirmModalButtonWrapperStyle}>
        <ButtonComponent
          onClick={closeAnd(props.onReplace)}
          css={confirmModalButtonStyle}
          text={t('Replace existing directions')}
        />
      </div>
    </ModalComponent>
  );
};
