import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ColumnRole } from '~/_shared/types/columnRole.enum';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useMarkerNameResolver } from '~/_shared/utils/spreadsheet/useMarkerNameResolver';
import { useMarkerPropertyResolver } from '~/_shared/utils/spreadsheet/useMarkerPropertyResolver';
import { setStartingLocation } from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import {
  getCellsFromSpreadsheetCellDataRow, getRowFromSpreadsheetCellData,
} from '~/store/spreadsheetCellData/spreadsheetCellData.helpers';
import { useSpreadsheetCellDataSelector } from '~/store/spreadsheetCellData/spreadsheetCellData.selectors';
import { useLatLngSpreadsheetData } from '../useSpreadsheetData.hook';

const PRIMARY_COLUMN_ROLE_TO_USE = ColumnRole.Address1;

export const useDrawRadiusOnMarker = () => {
  const dispatch = useDispatch();
  const latLngLookup = useLatLngSpreadsheetData();
  const { markerNameResolver } = useMarkerNameResolver();
  const matchupData = useMatchupDataSelector();
  const markerAddressResolver = useMarkerPropertyResolver(PRIMARY_COLUMN_ROLE_TO_USE);
  const cachedSpreadsheetCellData = useSpreadsheetCellDataSelector();

  const getRadiusName = useCallback((spreadsheetRowId: SpreadsheetRowId) => {
    const address = markerAddressResolver(spreadsheetRowId.spreadsheetId, spreadsheetRowId.rowId);
    if (address) {
      return address;
    }

    const addressColumnId = matchupData?.[spreadsheetRowId.spreadsheetId]?.data?.categories[PRIMARY_COLUMN_ROLE_TO_USE]?.match;
    const cachedSpreadsheetRow = getRowFromSpreadsheetCellData(cachedSpreadsheetCellData, spreadsheetRowId);
    const cachedAddress = addressColumnId ? getCellsFromSpreadsheetCellDataRow(cachedSpreadsheetRow, addressColumnId) : null;

    if (cachedAddress) {
      return cachedAddress;
    }

    return markerNameResolver(spreadsheetRowId.spreadsheetId, spreadsheetRowId.rowId, true);
  }, [cachedSpreadsheetCellData, markerAddressResolver, markerNameResolver, matchupData]);

  const addMarkerToProximityTool = useCallback(
    (spreadsheetRowId: SpreadsheetRowId) => {
      const finalName = getRadiusName(spreadsheetRowId);

      const rowLatLng = latLngLookup.getRow({
        rowId: spreadsheetRowId.rowId, spreadsheetId: spreadsheetRowId.spreadsheetId,
      });

      if (!finalName || !rowLatLng) {
        return;
      }

      dispatch(setStartingLocation({
        address: finalName,
        latLng: {
          lat: rowLatLng.lat,
          lng: rowLatLng.lng,
        },
      }, true));
    },
    [getRadiusName, latLngLookup, dispatch],
  );
  return { addMarkerToProximityTool };
};
