import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../_shared/components/modal/confirmation/useConfirmationModal';
import { useTranslation } from '../../_shared/utils/hooks';
import { drawingItemsRemoveItem } from '../../store/mapSettings/drawing/items/drawingItems.actionCreators';
import { type DrawingTool } from '../drawingTool.enums';
import { getDrawingElementLabel } from '../drawingTool.helpers';

export const useDrawingItemRemove = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const removeDrawingItemRequest = useCallback((drawingType: DrawingTool, drawingId: Uuid) => {
    const removeDrawingItem = (_drawingType: DrawingTool, drawingId: Uuid) => {
      dispatch(drawingItemsRemoveItem(drawingId));
    };

    const onConfirm = () => {
      removeDrawingItem(drawingType, drawingId);
      closeConfirmationModal();
    };

    openConfirmationModal({
      cancelCaption: t('Cancel'),
      isConfirmButtonDestructive: true,
      confirmCaption: t('Proceed'),
      onCancel: closeConfirmationModal,
      title: t('Remove Element'),
      text: t('You are about to delete this {{drawingElement}} from the map. This action is irreversible. Do you wish to continue?', {
        drawingElement: getDrawingElementLabel(drawingType, t).toLowerCase(),
      }),
      onConfirm,
      permanentConfirmSettings: {
        id: 'remove-drawing',
        strategy: PermanentConfirmStrategy.Session,
      },
    });
  }, [closeConfirmationModal, dispatch, openConfirmationModal, t]);

  return {
    removeDrawingItem: removeDrawingItemRequest,
  };
};
