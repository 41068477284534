import {
  type BoundaryGroup,
  type BoundaryGroupDetails,
} from '../../boundary/boundary.types';
import { createDemographicFromResponse } from '../demographics/demographics.factory';
import {
  type BoundaryGroupBaseResponse,
  type BoundaryGroupDetailsResponse, type BoundaryGroupMatchings, type BoundaryGroupMatchingsResponse,
  type BoundaryGroupResponse, type BoundaryGroupSettingsResponse,
} from './boundaryGroups.repository';

const createBoundaryGroupFromBase = (response: BoundaryGroupBaseResponse) => ({
  clientId: response.client_id,
  id: response.boundary_group_id,
  loadZoomLevel: response.settings.load_zoom_level ?? 0,
  name: response.settings.display_name,
  sort: response.sort,
  userId: response.user_id,
  zoomLevels: response.settings.zoom_levels,
  matchings: createBoundaryGroupMatchingsFromResponse(response.matchings),
  allowOverlap: response.allow_overlap,
  readonly: response.readonly,
  wms: {
    territoriesCount: response.wms?.territories_count ?? 0,
    constraints: response.wms_constraints,
    geometryRemainingCalls: response.wms?.geometry_remaining_calls ?? null,
    wmsTemporary: response.wms?.wms_temporary ?? false,
  },
  createdFrom: createBoundaryGroupCreatedFromResponse(response.settings),
  editBoundaryGroupFallback: response.edit_boundary_group_fallback,
  visible: response.visible ?? true,
});

export const createBoundaryGroupFromResponse = (
  response: BoundaryGroupResponse
): BoundaryGroup => {
  const {
    demographics,
    codename,
    ...baseResponse
  } = response;

  return ({
    ...createBoundaryGroupFromBase(baseResponse),
    codename,
    demographics: demographics?.map(demographic => createDemographicFromResponse(demographic)) ?? [],
  });
};

export const createBoundaryGroupMatchingsFromResponse = (
  response: BoundaryGroupMatchingsResponse | Record<string, never>
): BoundaryGroupMatchings | null => {
  if (!response.type) {
    return null;
  }

  return {
    type: response.type,
    matchingColumns: response.matching_columns.map(column => ({
      id: column.id,
      name: column.name,
    })),
  };
};

export const createBoundaryGroupDetailsFromResponse = (
  response: BoundaryGroupDetailsResponse
): BoundaryGroupDetails => {
  const {
    used_on_maps,
    ...baseResponse
  } = response;

  return ({
    ...createBoundaryGroupFromBase(baseResponse),
    usedOnMaps: used_on_maps ?? [],
  });
};

export const createBoundaryGroupCreatedFromResponse = (responseSettings: BoundaryGroupSettingsResponse) => {
  const createdFrom = responseSettings.created_from;
  if (createdFrom) {
    return ({
      boundaryTerritoryGroupId: createdFrom.boundary_territory_group_id,
      boundaryGroupIds: createdFrom.boundary_group_ids,
    });
  }

  return undefined;
};
