import { css } from '@emotion/react';

const FILE_SELECTOR_APPROX_WIDTH = 200;

export const fileSelectorWrapperStyle = css({
  boxSizing: 'border-box',
  display: 'flex',
  height: 55,
  justifyContent: 'end',
  paddingLeft: 28,
  minWidth: FILE_SELECTOR_APPROX_WIDTH,
  position: 'relative',
  width: '100%',
});

export const fileSelectorStyle = css({
  height: '100%',
  width: FILE_SELECTOR_APPROX_WIDTH,
});

export const fileTypeItemStyle = css({
  display: 'flex',
  whiteSpace: 'nowrap',
});
