import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ProximityType } from '~/_shared/types/proximity/proximity.enums';
import { isDriveTimePolygon } from '~/_shared/types/proximity/proximity.types';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { openMapTool } from '~/store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { setType } from '~/store/frontendState/proximityTool/proximityTool.actionCreators';
import { useTranslation } from '../../../_shared/utils/hooks';
import { useProximities } from '../../../proximity/useProximities';
import { removeProximity } from '../../../store/mapSettings/proximity/mapSettingsProximity.actionCreators';
import {
  hideProximity, showProximity,
} from '../../../store/mapSettings/toolsState/visibility/visibility.actionCreators';
import { useHiddenProximityIdsSelector } from '../../../store/mapSettings/toolsState/visibility/visibility.selectors';
import { useMapIdSelector } from '../../../store/selectors/useMapIdSelector';
import { createMapStatusBarFromProximity } from '../item/mapStatusBarItem.factory';
import { type MapStatusBarItem } from '../mapStatusBarItem.type';

export const useProximityStatusBarItems = () => {
  const { proximities } = useProximities();
  const hiddenProximityIds = useHiddenProximityIdsSelector();
  const mapId = useMapIdSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  return useMemo<MapStatusBarItem[]>(() => {
    if (!mapId) {
      return [];
    }
    return proximities
      .filter(proximity => !isDriveTimePolygon(proximity) || proximity.data.paths)
      .map((proximity, index) => createMapStatusBarFromProximity(
        {
          t, proximity,
          isProximityVisible: !hiddenProximityIds.includes(proximity.id),
          index: index + 1,
          onRemove: () => dispatch(removeProximity(proximity, mapId)),
          onVisibilityChange: (newVisibility) => {
            if (newVisibility) {
              dispatch(showProximity(proximity.id));
            }
            else {
              dispatch(hideProximity(proximity.id));
            }
          },
          onClick: () => {
            dispatch(setType(isDriveTimePolygon(proximity) ? ProximityType.DriveTimePolygon : ProximityType.DistanceRadius));
            dispatch(openMapTool(MapTool.Proximity));
          },
        }));
  }, [t, dispatch, proximities, hiddenProximityIds, mapId]);
};
