import { useMemo } from 'react';
import { type FilterTree } from '../../_shared/types/filterTree.types';
import { useSpreadsheetTableSearchSelector } from '../../store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { type SpreadsheetTableSearchState } from '../../store/frontendState/spreadsheetTable/spreadsheetTable.state';
import { createFilterItemFromTextValue } from '../../store/spreadsheetData/filterTree/text/spreadsheetDataTextFilter.helpers';
import { DataType } from '../../store/spreadsheetData/spreadsheetData.state';

const createFilterTreeForTableSearch = (
  tableSearch: SpreadsheetTableSearchState
): FilterTree | null => {
  const filterItem: FilterTree = {
    type: 'and',
    children: [],
  };

  Object.keys(tableSearch).forEach(spreadsheetIdString => {
    const spreadsheetId = +spreadsheetIdString;

    Object.keys(tableSearch[spreadsheetId]).forEach(columnId => {
      const textValues = tableSearch[spreadsheetId][columnId][DataType.TEXT];

      for (const textValue of textValues) {
        const textFilter = createFilterItemFromTextValue(textValue, columnId);
        filterItem.children.push(textFilter);
      }
    });
  });

  return filterItem.children.length > 0 ? filterItem : null;
};

export const useSpreadsheetTableSearchFilterTree = () => {
  const tableSearch = useSpreadsheetTableSearchSelector();

  return useMemo(() =>
    createFilterTreeForTableSearch(tableSearch) ?? undefined,
  [tableSearch]
  );
};
