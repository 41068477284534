import { type SagaIterator } from 'redux-saga';
import {
  call,
  put, race, take, takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import { type PickAction } from '../../_shared/utils/types/action.type';
import { mapIdSet } from '../mapId/mapId.actionCreators';
import { mapIdSelector } from '../selectors/useMapIdSelector';
import { type MapAction } from './map.action';
import { mapReset } from './map.actionCreators';
import {
  MAP_CHANGE, MAP_RESET,
} from './map.actionTypes';

export function* mapSagas() {
  yield takeLatest(MAP_CHANGE, onMapChange);

}

function* onMapChange(action: PickAction<MapAction, typeof MAP_CHANGE>) {
  const mapId: number | null = yield select<number | null>(mapIdSelector);
  if (mapId !== null) {
    yield put(mapReset());
  }
  yield put(mapIdSet(action.payload.mapId));
}

export function cancelOnMapReset<A extends Action>(saga: (action: A) => SagaIterator) {
  return function* (action: A): SagaIterator<void> {
    yield race({
      task: call(saga, action),
      reset: take(MAP_RESET),
    });
  };
}
