import { type MapSettingsExportImageSettingsStateAction } from './exportImageSettings.action';
import { MAP_SETTINGS_EXPORT_IMAGE_SETTINGS_UPDATE } from './exportImageSettings.actionTypes';
import { type MapSettingsExportImageSettingsState } from './exportImageSettings.state';

export const exportImageSettingsInitialState: MapSettingsExportImageSettingsState = {
  mode: null,
  legendMode: null,
  locationListMode: null,
  activeMarker: null,
  sidebarTool: null,
  isLocationListOpen: false,
};

export const exportImageSettingsReducer = (
  state = exportImageSettingsInitialState,
  action: MapSettingsExportImageSettingsStateAction
): MapSettingsExportImageSettingsState => {
  switch (action.type) {
    case MAP_SETTINGS_EXPORT_IMAGE_SETTINGS_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
