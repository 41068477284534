import { css } from '@emotion/react';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import getScrollParent from '@popperjs/core/lib/dom-utils/getScrollParent';
import React, {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import type { NonEmptyArray } from 'ts-essentials';
import {
  ButtonComponent, ButtonStyle,
} from '~/_shared/baseComponents/buttons';
import {
  type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  InputSize, TextInputComponent,
} from '~/_shared/baseComponents/inputs';
import { Inline } from '~/_shared/baseComponents/layout/Inline.component';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { InlineWarningComponent } from '~/_shared/baseComponents/warning/inlineWarning.component';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { PaginationComponent } from '~/_shared/components/pagination/pagination.component';
import { CONFIG } from '~/_shared/constants/config';
import { formatDate } from '~/_shared/utils/date/date.helpers';
import { autoCompleteDisabled } from '~/_shared/utils/dom/dom.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import {
  type TranslationFnc, useTranslation,
} from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { notNullsy } from '~/_shared/utils/typeGuards';
import { ImpersonationService } from '~/authorization/impersonation';
import { MapDependenciesComponent } from '~/map/mapMigration/components/mapDependencies.component';
import { MapMigrationResultStackComponent } from '~/map/mapMigration/components/mapMigrationResultStack.component';
import { v4MapImportDialogLayout } from '~/map/mapMigration/migrateMapsDialog.styles';
import {
  TableCheckboxCellComponent, TableComponent, TableDataCellComponent, TableHeaderCellComponent, TableHeaderComponent,
  TableNoResultsRowComponent, TableRowComponent,
} from '~/map/mapMigration/table';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { getCurrentMapPageInfo } from '~/store/mapMigration/helpers/mapMigrationPaging.helpers';
import {
  v4MapCompleteMigrationInit, v4MapMigrationInit,
} from '~/store/mapMigration/mapMigration.actionCreators';
import { usersMapsForMigrationSelector } from '~/store/mapMigration/selectors/mapsForMigration.selector';
import type { V4MapInfoServer } from '~/store/mapMigration/types/mapMigrationResponse.types';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import { V4MapListingOrdering } from '~/store/mapMigration/types/v4MapListingOrdering.enum';
import { openModal } from '~/store/modal/modal.actionCreators';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { userIdSelector } from '~/store/userData/userData.selectors';

type CreateNewMapProps = ModalProps;

const columnWidths = ['auto', '1fr', 'auto', 'auto', 'auto'];

const footerWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const tableKnobsWrapperStyle = css({
  display: 'flex',
  justifyItems: 'stretch',
  gap: 24,
});

const dropDownStyles = css({
  minWidth: 284,
});

const getTablePaginationCouplerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const mapNameCellStyles = css({
  textAlign: 'left',
  display: 'flex',
  gap: 8,
  alignItems: 'center',
});

const getV4MapOrderingOptions = memoizeOne((t: TranslationFnc): NonEmptyArray<DropdownOption<V4MapListingOrdering>> => ([
  {
    name: t('All Maps A to Z'),
    value: V4MapListingOrdering.Name_ASC,
  },
  {
    name: t('All Maps Z to A'),
    value: V4MapListingOrdering.Name_DESC,
  },
  {
    name: t('Most Recently Updated Maps'),
    value: V4MapListingOrdering.Updated_DESC,
  },
  {
    name: t('Least Recently Updated Maps'),
    value: V4MapListingOrdering.Updated_ASC,
  },
  {
    name: t('Most Recently Migrated Maps'),
    value: V4MapListingOrdering.Migrated_DESC,
  },
  {
    name: t('Least Recently Migrated Maps'),
    value: V4MapListingOrdering.Migrated_ASC,
  },
  {
    name: t('Most Recently Created Maps'),
    value: V4MapListingOrdering.Created_DESC,
  },
  {
    name: t('Least Recently Created Maps'),
    value: V4MapListingOrdering.Created_ASC,
  },
]));

const getForceCheckedMapIds = (v4Maps: readonly V4MapInfo[], selectedV4MapIds: ReadonlySet<number>): ReadonlySet<number> => {
  const v4MapMap = Object.fromEntries(v4Maps.map(map => ([map.id, map])));
  return new Set([...selectedV4MapIds]
    .flatMap(selectedMapId => v4MapMap[selectedMapId]?.dependencies.map(dep => dep.id))
    .filter(notNullsy),
  );
};

export const MigrateMapsComponent: FC<CreateNewMapProps> = (props) => {
  const [t] = useTranslation();

  const maps = useSelector(usersMapsForMigrationSelector);

  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const userId = useSelector(userIdSelector);

  const [isActionBeingExecuted, setIsActionBeingExecuted] = useState(false);
  const [tableCoupleMinHeight, setTableCoupleMinHeight] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterQuery, setFilterQuery] = useState('');
  const [selectedV4MapIds, setSelectedV4MapIds] = useState<ReadonlySet<number>>(new Set());
  const [ordering, setOrdering] = useState(V4MapListingOrdering.Updated_DESC);
  const forceCheckedV4MapIds = useMemo(() => getForceCheckedMapIds(maps, selectedV4MapIds), [maps, selectedV4MapIds]);
  const mapIdsTobeMigrated = useMemo(() => new Set([...selectedV4MapIds,
    ...forceCheckedV4MapIds]), [forceCheckedV4MapIds, selectedV4MapIds]);

  const { hasNewMaps, newV4MapIds, alreadyMigratedMaps } = useMemo(() => {
    const newV4Maps = maps.filter(mapInfo => !mapInfo.migratedAt);
    return {
      hasNewMaps: newV4Maps.length > 0,
      newV4MapIds: newV4Maps.map(mapInfo => mapInfo.id),
      alreadyMigratedMaps: maps.filter(mapInfo => mapInfo.migratedAt),
    };
  }, [maps]);

  const alreadyMigratedMapsToBeMigrated = useMemo(() => alreadyMigratedMaps.filter(mapInfo => mapIdsTobeMigrated.has(mapInfo.id)),
    [alreadyMigratedMaps, mapIdsTobeMigrated]);
  const otherUsersMapsToBeMigrated = useMemo(() => maps.filter(mapInfo =>
    mapIdsTobeMigrated.has(mapInfo.id) && (mapInfo.mapOwnerId !== userId),
  ), [mapIdsTobeMigrated, maps, userId]);

  const selectedMapMigrationNeedsConfirmation = alreadyMigratedMapsToBeMigrated.length > 0;
  const allMapMigrationNeedsConfirmation = (alreadyMigratedMaps.length > 0) || (otherUsersMapsToBeMigrated.length > 0);

  const { mapsOnTheCurrentPage, pageCount } = useMemo(
    () => getCurrentMapPageInfo(maps, filterQuery, ordering, pageNumber),
    [filterQuery, maps, ordering, pageNumber]);

  const toggleMapChecked = (mapId: V4MapInfoServer['id']) => () => {
    setSelectedV4MapIds(oldSelections => oldSelections.has(mapId) ?
      new Set([...oldSelections].filter(id => id !== mapId)) :
      new Set([...oldSelections, mapId]));
  };

  const changeOrdering = (newOrdering: V4MapListingOrdering) => {
    setPageNumber(1);
    setOrdering(newOrdering);
  };

  const migrateMaps = useCallback(() => {
    if (clientId) {
      setIsActionBeingExecuted(true);
      dispatch(v4MapMigrationInit(clientId, [...selectedV4MapIds]));
    }
  }, [clientId, dispatch, selectedV4MapIds]);

  const migrateAllMaps = useCallback(() => {
    if (clientId) {
      setIsActionBeingExecuted(true);
      dispatch(v4MapCompleteMigrationInit(clientId));
    }
  }, [clientId, dispatch]);

  const selectNewMaps = useCallback(() => {
    setSelectedV4MapIds(oldIds => new Set([...oldIds, ...newV4MapIds]));
  }, [newV4MapIds]);

  const unselectMaps = useCallback(() => {
    setSelectedV4MapIds(new Set());
  }, []);

  const confirmMapMigration = useCallback(() => {
    dispatch(openModal(ModalType.MigrationConfirmation, {
      onConfirm: migrateMaps,
      requiresConfirmation: otherUsersMapsToBeMigrated.length > 0,
      warningText: (
        <>
          {(alreadyMigratedMapsToBeMigrated.length > 0) && (
            <MapMigrationResultStackComponent
              maps={alreadyMigratedMapsToBeMigrated}
              warning={t('MapMigrationConfirmation')}
              instructions={null}
            />
          )}
          {(alreadyMigratedMapsToBeMigrated.length > 0) && (otherUsersMapsToBeMigrated.length > 0) && (
            <hr />
          )}
          {(otherUsersMapsToBeMigrated.length > 0) && (
            <MapMigrationResultStackComponent
              maps={otherUsersMapsToBeMigrated}
              warning={t('MapMigrationOtherUsersMapsConfirmation')}
              instructions={(
                <p>
                  {t('MapMigrationConfirmationInstructions', { phrase: t('MapMigrationConfirmationPhrase') })}
                </p>
              )}
            />
          )}
        </>
      ),
    }));
  }, [alreadyMigratedMapsToBeMigrated, dispatch, migrateMaps, otherUsersMapsToBeMigrated, t]);

  const confirmAllMapMigration = useCallback(() => {
    dispatch(openModal(ModalType.MigrationConfirmation, {
      onConfirm: migrateAllMaps,
      warningText: (
        <p>
          {t('MapMigrationConfirmation')}
        </p>
      ),
    }));
  }, [dispatch, migrateAllMaps, t]);

  // Do not memoize this. It wouldn't update. Height-change does not re-run ref. Callback change does.
  const updateMinHeight = (element: HTMLDivElement | null | undefined) => {
    if (element) {
      const scrollParent = getScrollParent(element);
      // Stabilizing the min-height ensures the pagination element does not jump around.
      const height = element.getBoundingClientRect().height - (scrollParent.scrollHeight - scrollParent.clientHeight);
      setTableCoupleMinHeight(oldHeight => Math.max(oldHeight, height));
    }
  };

  // This effect resets the page number when appropriate.
  useEffect(() => {
    setPageNumber(1);
  }, [filterQuery, ordering, pageCount]);

  // Resets the page when (somehow) we end up on an empty page.
  useEffect(() => {
    if ((mapsOnTheCurrentPage.length === 0) && (pageNumber !== 1)) {
      setPageNumber(1);
    }
  }, [mapsOnTheCurrentPage.length, pageNumber]);

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('MapMigrationMigrateMapsCaption')}
      maxWidth={800}
      footer={(
        <div css={footerWrapperStyle}>
          <Inline gap={8}>
            <TooltipComponent
              tooltipContent={hasNewMaps ?
                t('MapMigrationMigrateNewMapsExplanation') :
                t('MapMigrationMigrateNewMapsDisabled')}
              behavior={TooltipBehavior.ShowOnHover}
            >
              <ButtonComponent
                text={t('MapMigrationMigrateNewMaps')}
                buttonStyle={ButtonStyle.Secondary}
                isDisabled={!hasNewMaps}
                onClick={selectNewMaps}
              />
            </TooltipComponent>
            <ButtonComponent
              text={t('MapMigrationClearSelection')}
              buttonStyle={ButtonStyle.Secondary}
              isDisabled={!selectedV4MapIds.size}
              onClick={unselectMaps}
            />
            {(CONFIG.ENABLE_MIGRATE_ALL || ImpersonationService.isImpersonated()) && (
              <ButtonComponent
                text={t('MapMigrationMigrateAllMaps')}
                buttonStyle={ButtonStyle.Danger}
                isDisabled={isActionBeingExecuted}
                onClick={allMapMigrationNeedsConfirmation ? confirmAllMapMigration : migrateAllMaps}
              />
            )}
          </Inline>
          <TooltipComponent
            tooltipContent={(selectedV4MapIds.size === 0) ? t('Select a map to transfer') : null}
            behavior={TooltipBehavior.ShowOnHover}
          >
            <ButtonComponent
              text={t('MapMigrationMigrateSelectedMaps', { count: mapIdsTobeMigrated.size })}
              buttonStyle={ButtonStyle.Primary}
              isDisabled={(selectedV4MapIds.size === 0) || isActionBeingExecuted}
              onClick={selectedMapMigrationNeedsConfirmation ? confirmMapMigration : migrateMaps}
            />
          </TooltipComponent>
        </div>
      )}
    >
      <div css={v4MapImportDialogLayout}>
        <h1>{t('MapMigrationWelcomeToBetaCaption')}</h1>
        <p>{t('MapMigrationDialogInstructions')}</p>
        <p>
          <InlineWarningComponent>{t('Note:')}</InlineWarningComponent> {t('MapMigrationReImportWarning')}
        </p>

        <div
          css={tableKnobsWrapperStyle}
        >
          <TextInputComponent
            type="text"
            role="searchbox"
            autoComplete={autoCompleteDisabled}
            value={filterQuery}
            placeholder={t('Search...')}
            onChange={setFilterQuery}
            icon={faSearch}
            size={InputSize.Large}
          />

          <RegularDropdownComponent
            css={dropDownStyles}
            value={ordering}
            onChange={changeOrdering}
            options={getV4MapOrderingOptions(t)}
            inputSize={InputSize.Large}
          />
        </div>

        <div
          css={getTablePaginationCouplerStyles}
          // Use style prop. Value of minHeight can have infinite options,
          // if used via css prop it would generate too many css classes.
          style={{ minHeight: tableCoupleMinHeight }}
          ref={updateMinHeight}
        >
          <TableComponent columnWidths={columnWidths}>
            <TableHeaderComponent>
              <TableHeaderCellComponent
                colSpan={2}
                css={{ textAlign: 'left' }}
              >
                {t('My Maps')}
              </TableHeaderCellComponent>
              <TableHeaderCellComponent
                css={{ textAlign: 'left' }}
              >
                {t('MapMigrationLastMigratedColumnName')}
              </TableHeaderCellComponent>
              <TableHeaderCellComponent
                css={{ textAlign: 'left' }}
              >
                {t('Date created')}
              </TableHeaderCellComponent>
              <TableHeaderCellComponent
                css={{ textAlign: 'left' }}
              >
                {t('Last updated')}
              </TableHeaderCellComponent>
            </TableHeaderComponent>
            {mapsOnTheCurrentPage.map(mapInfo => {
              const isChecked = selectedV4MapIds.has(mapInfo.id);
              const isForceChecked = !isChecked && forceCheckedV4MapIds.has(mapInfo.id);

              return (
                <React.Fragment key={mapInfo.id}>
                  <TableRowComponent>
                    {isForceChecked ? (
                      <TooltipComponent
                        tooltipContent={t('MapMigrationForceSelectionExplanation')}
                      >
                        <TableCheckboxCellComponent
                          isChecked
                          isDisabled
                          checkedSetter={noop}
                        />
                      </TooltipComponent>
                    ) : (
                      <TableCheckboxCellComponent
                        isChecked={isChecked}
                        checkedSetter={toggleMapChecked(mapInfo.id)}
                      />
                    )}
                    <TooltipComponent
                      tooltipContent={mapInfo.title}
                      behavior={TooltipBehavior.ShowOnOverflow}
                    >
                      <TableDataCellComponent
                        {...props}
                        css={mapNameCellStyles}
                      >
                        <FontAwesomeIcon
                          icon={mapInfo.mapType === 'LAYERED' ? faLayerGroup : faMap}
                        />
                        {mapInfo.title}
                      </TableDataCellComponent>
                    </TooltipComponent>
                    <TableDataCellComponent
                      css={{ textAlign: 'center' }}
                    >
                      {mapInfo.migratedAt ? formatDate(mapInfo.migratedAt) : t('N/A')}
                    </TableDataCellComponent>
                    <TableDataCellComponent
                      css={{ textAlign: 'center' }}
                    >
                      {formatDate(mapInfo.createdAt)}
                    </TableDataCellComponent>
                    <TableDataCellComponent
                      css={{ textAlign: 'center' }}
                    >
                      {formatDate(mapInfo.updatedAt)}
                    </TableDataCellComponent>
                  </TableRowComponent>
                  {isChecked && (
                    <MapDependenciesComponent mapInfo={mapInfo} />
                  )}
                </React.Fragment>
              );
            })}
            {(mapsOnTheCurrentPage.length) === 0 && (
              <TableNoResultsRowComponent>
                {t('No results')}
              </TableNoResultsRowComponent>
            )}
          </TableComponent>
          <PaginationComponent
            currentPage={pageNumber}
            pageCount={pageCount}
            onPageSelect={setPageNumber}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
