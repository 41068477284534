import { css } from '@emotion/react';
import { type Theme } from '../../themes/theme.model';
import { type ThemeProps } from '../../types/themeProps';
import {
  type AlertSize, type AlertType,
} from './alert.component';

type AlertTheme = {
  primary: string;
  secondary: string;
};

export const getThemeForAlertType = (type: AlertType, theme: Theme): AlertTheme => {
  switch (type) {
    case 'danger':
      return {
        primary: theme.alertColors.dangerPrimary,
        secondary: theme.alertColors.dangerSecondary,
      };
    case 'success':
      return {
        primary: theme.alertColors.successPrimary,
        secondary: theme.alertColors.successSecondary,
      };
    case 'info':
      return {
        primary: theme.alertColors.infoPrimary,
        secondary: theme.alertColors.infoSecondary,
      };
    case 'warning':
      return {
        primary: theme.alertColors.warningPrimary,
        secondary: theme.alertColors.warningSecondary,
      };
    default:
      return {
        primary: theme.alertColors.successPrimary,
        secondary: theme.alertColors.successSecondary,
      };
  }
};

export const alertContainerStyle = ({ theme, type, size, reverseColors, transparentBackground, hasCloseButton }: ThemeProps<{
  type: AlertType;
  size: AlertSize;
  reverseColors: boolean;
  transparentBackground: boolean;
  hasCloseButton: boolean;
}>) => {
  const alertTheme = getThemeForAlertType(type, theme);

  return css({
    background: reverseColors ? alertTheme.primary : (transparentBackground ? undefined : alertTheme.secondary),
    border: `1px solid ${reverseColors ? alertTheme.secondary : alertTheme.primary}`,
    borderRadius: 4,
    color: reverseColors ? alertTheme.secondary : alertTheme.primary,
    cursor: 'default',
    fontSize: size === 'small' ? 14 : 18,
    fontWeight: 600,
    minHeight: size === 'small' ? 16 : 20,
    padding: size === 'small' ? '8px 12px 8px 12px' : '8px 25px',
    paddingRight: hasCloseButton ? (size === 'small' ? 26 : 32) : undefined,
    position: 'relative',
    textAlign: 'center',
  });
};

export const buttonStyle = css({
  position: 'absolute',
  top: 8,
  right: 5,
  background: 'none',
  color: 'inherit',
  border: 'none',
});
