import {
  put, takeLatest,
} from 'redux-saga/effects';
import { MAP_INFO_FETCH_DATA_ERROR } from '../../mapInfo/mapInfo.actionTypes';
import {
  MAP_SETTINGS_FETCH_DATA_ERROR,
  MAP_SETTINGS_FETCH_DATA_REQUEST,
} from '../../mapSettings/data/mapSettingsData.actionTypes';
import { MATCHUP_FETCH_DATA_ERROR } from '../../matchupData/matchupData.actionTypes';
import { SPREADSHEET_FETCH_DATA_ERROR } from '../../spreadsheetData/spreadsheetData.actionTypes';
import { frontendStateMapInitializationFailedSet } from './mapInitializationFailed.actionCreators';

export function* mapInitializationFailedSagas() {
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_REQUEST, mapInitializationFailedFalse);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_ERROR, mapInitializationFailedTrue);
  yield takeLatest(MAP_INFO_FETCH_DATA_ERROR, mapInitializationFailedTrue);

  yield takeLatest(SPREADSHEET_FETCH_DATA_ERROR, mapInitializationFailedTrue);
  yield takeLatest(MATCHUP_FETCH_DATA_ERROR, mapInitializationFailedTrue);
}

function* mapInitializationFailedFalse() {
  yield put(frontendStateMapInitializationFailedSet(false));
}

function* mapInitializationFailedTrue() {
  yield put(frontendStateMapInitializationFailedSet(true));
}
