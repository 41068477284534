import { createUuid } from '~/_shared/utils/createUuid';
import { isNumber } from '~/_shared/utils/typeGuards';
import type { MapSettingsSearchItemsResponse } from '~/map/map.repository';
import type { SearchItem } from '~/map/search/mapSearch.helpers';
import { mapSettingsSearchItemsInitialState } from './mapSettingsSearchItems.reducer';
import type { MapSettingsSearchItemsState } from './mapSettingsSearchItems.state';

export const mapSettingsSearchItemsSerializer = {
  jsonToState: (json?: MapSettingsSearchItemsResponse): MapSettingsSearchItemsState => {
    const { items: jsonItems, ...jsonRest } = json ?? { items: [] };

    return {
      ...mapSettingsSearchItemsInitialState,
      ...jsonRest,
      items: itemsJsonToState(jsonItems),
    };
  },
  stateToJson: (state: MapSettingsSearchItemsState): MapSettingsSearchItemsResponse => state,
};

const itemsJsonToState = (jsonItems: MapSettingsSearchItemsResponse['items']): MapSettingsSearchItemsState['items'] => {
  return (jsonItems ?? []).map(item => ({
    ...item,
    id: item.id ?? createUuid(),
    latLng: itemLatLngToState(item.latLng),
  }));
};

const itemLatLngToState = (jsonLatLng: any): SearchItem['latLng'] => {
  if (jsonLatLng && !Array.isArray(jsonLatLng)) {
    const { lat, lng } = jsonLatLng as { lat: any; lng: any };

    if (isNumber(lat) && isNumber(lng)) {
      return [{ lat, lng }];
    }
  }

  return Array.isArray(jsonLatLng) ? jsonLatLng : undefined;
};
