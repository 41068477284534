import { css } from '@emotion/react';
import {
  faCloudArrowUp, faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback, useMemo,
} from 'react';
import { type FileRejection } from 'react-dropzone';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  DropzoneComponent, type DropzoneProps,
} from '~/_shared/components/dropzone/dropzone.component';
import { ImageSelectorComponent } from '~/_shared/components/imageSelector/imageSelector.component';
import { MarkerComponent } from '~/_shared/components/marker/marker.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '~/_shared/components/overlay/overlayWrapper.component';
import {
  type CustomMarkerSettings,
  isTemporaryCustomMarkerVisualSettings,
  isTemporaryStandardMarkerVisualSettings,
  MarkerStyleType,
  type StandardMarkerSettings,
  type TemporaryMarkerVisualSettings,
} from '~/_shared/types/marker.types';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  IMAGE_SELECTOR_PADDING, MAX_TILE_DIMENSIONS,
} from '../customizeMarker.constants';

type CustomMarkerTextureChange = { styleType: MarkerStyleType.CUSTOM; fileId: number };
type StandardMarkerTextureChange = { styleType: MarkerStyleType.STANDARD; styleId: number};
export type MarkerTextureChange = CustomMarkerTextureChange | StandardMarkerTextureChange;

const TILE_DIMENSIONS = MAX_TILE_DIMENSIONS;
const TILE_MARKER_PADDING = 15;
const MARKER_DIMENSIONS = {
  height: TILE_DIMENSIONS.height - TILE_MARKER_PADDING,
  width: TILE_DIMENSIONS.width - TILE_MARKER_PADDING,
};

const listingStyle = css({
  width: `calc(100% + ${IMAGE_SELECTOR_PADDING}px)`,
});

const labelStyle = css({
  cursor: 'default',
  marginBottom: '8px',
  textTransform: 'uppercase',
});

const dropzoneStyle = css({
  boxSizing: 'border-box',
  height: TILE_DIMENSIONS.height,
  marginBottom: '32px',
  width: 250,
});

const dropzoneInSelectorStyle = css({
  boxSizing: 'border-box',
  height: TILE_DIMENSIONS.height,
  width: TILE_DIMENSIONS.width,
});

const MAX_MARKERS_IN_LINE = 8;
const markerStyle = (props: {
  numberOfMarkers: number;
  tileHeightAndMargin: number;
  marginBottom?: number;
}) => css({
  height: `${Math.ceil(props.numberOfMarkers / MAX_MARKERS_IN_LINE) * props.tileHeightAndMargin}px`,
  width: '100%',
  marginBottom: props.marginBottom ? `${props.marginBottom}px` : 0,
});

const customLoaderWrapperStyle = css({
  minHeight: '100px',
});

const upperPartMarkerTabStyle = css({
  marginTop: 3,
  marginBottom: 3,
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

const upperDropzoneButtonStyle = css({
  height: 40,
  width: 235,
});

type MarkerStyleListProps = Readonly<{
  customMarkers: ReadonlyArray<CustomMarkerSettings>;
  markerSettings: TemporaryMarkerVisualSettings;
  standardMarkers: ReadonlyArray<StandardMarkerSettings>;
  selectedColor?: string;
  acceptFileExtensions: ReadonlyArray<string>;

  onFileAccept: (files: ReadonlyArray<File>) => void;
  onFileReject: (rejections: ReadonlyArray<FileRejection>) => void;
  onMarkerSelect: (markerSettings: MarkerTextureChange) => void;
  isUploading: boolean;
  onImageDelete: (fileId: number) => void;
}>;

const IMAGE_SELECTOR_DROPZONE_ID = Number.MIN_SAFE_INTEGER;
const TILE_PADDING = { horizontal: IMAGE_SELECTOR_PADDING, vertical: IMAGE_SELECTOR_PADDING };
const COMMON_IMAGE_SELECTOR_PROPS = {
  imageWidth: TILE_DIMENSIONS.width,
  imageHeight: TILE_DIMENSIONS.height,
  paddingHorizontal: TILE_PADDING.horizontal,
  paddingVertical: TILE_PADDING.vertical,
};

export const MarkerStyleListComponent: React.FC<MarkerStyleListProps> = props => {
  const areCustomMarkers = useMemo(() => props.customMarkers.length > 0, [props.customMarkers]);
  const [t] = useTranslation();

  const { markerSettings, onMarkerSelect } = props;

  const handleSelect = useCallback((ids: ReadonlyArray<number>, type: MarkerStyleType): void => {
    const idsWithoutDropzone = ids.filter(id => id !== IMAGE_SELECTOR_DROPZONE_ID);

    if (idsWithoutDropzone.length === 0) {
      return;
    }

    if (!ids[0]) {
      return;
    }

    const marker: MarkerTextureChange = type === MarkerStyleType.CUSTOM ? {
      styleType: MarkerStyleType.CUSTOM,
      fileId: ids[0],
    } : {
      styleType: MarkerStyleType.STANDARD,
      styleId: ids[0],
    };

    onMarkerSelect(marker);
  }, [onMarkerSelect]);

  const commonDropzoneProps: DropzoneProps = useMemo(() => ({
    acceptFileExtensions: props.acceptFileExtensions,
    isDisabled: false,
    isMulti: true,
    maxSize: 2000000,
    onFilesReject: props.onFileReject,
    onFilesSelect: props.onFileAccept,
    secondaryLabel: '',
  }), [props.acceptFileExtensions, props.onFileAccept, props.onFileReject]);

  const imageSelectorDropzoneItem = useMemo(() => ({
    id: IMAGE_SELECTOR_DROPZONE_ID,
    render: () => (
      <DropzoneComponent
        {...commonDropzoneProps}
        css={dropzoneInSelectorStyle}
        label={<FontAwesomeIcon icon={faPlus} />}
      />
    ),
  }), [commonDropzoneProps]);

  return (
    <div css={listingStyle}>
      <div css={upperPartMarkerTabStyle}>
        <DropzoneComponent
          {...commonDropzoneProps}
          noDefaultContainerStyle
          label={(
            <ButtonComponent
              css={upperDropzoneButtonStyle}
              buttonStyle={ButtonStyle.Tertiary}
              prefixIcon={faCloudArrowUp}
              text={t('Upload Custom Marker')}
            />
          )}
        />
      </div>
      <div css={labelStyle}>
        {t('Standard markers')}
      </div>
      <div
        css={markerStyle({
          numberOfMarkers: props.standardMarkers.length,
          tileHeightAndMargin: TILE_DIMENSIONS.height + TILE_PADDING.vertical,
          marginBottom: 32 - TILE_PADDING.vertical,
        })}
      >
        <ImageSelectorComponent
          {...COMMON_IMAGE_SELECTOR_PROPS}
          images={props.standardMarkers.map(marker => ({
            id: marker.id,
            render: () => (
              <MarkerComponent
                key={marker.id}
                maxDimensions={MARKER_DIMENSIONS}
                marker={marker}
                markerType={MarkerStyleType.STANDARD}
                selectedColor={props.selectedColor}
              />
            ),
          }))}
          onSelectionChanged={ids => handleSelect(ids, MarkerStyleType.STANDARD)}
          selectedImagesIds={isTemporaryStandardMarkerVisualSettings(markerSettings) ? [markerSettings.styleId] : []}
        />
      </div>
      <OverlayWrapperComponent css={customLoaderWrapperStyle}>
        <div css={labelStyle}>
          {t('Upload Markers')}
        </div>
        {!areCustomMarkers && (
          <DropzoneComponent
            {...commonDropzoneProps}
            css={dropzoneStyle}
            primaryLabel={t('Upload Custom Marker')}
          />
        )}
        {areCustomMarkers && (
          <div
            css={markerStyle({
              numberOfMarkers: props.customMarkers.length + 1,
              tileHeightAndMargin: TILE_DIMENSIONS.height + TILE_PADDING.vertical,
            })}
          >
            <ImageSelectorComponent
              {...COMMON_IMAGE_SELECTOR_PROPS}
              images={[imageSelectorDropzoneItem, ...props.customMarkers.map(marker => ({
                id: marker.fileId,
                render: () => (
                  <MarkerComponent
                    key={marker.fileId}
                    maxDimensions={MARKER_DIMENSIONS}
                    marker={marker}
                    markerType={MarkerStyleType.CUSTOM}
                    onDelete={() => props.onImageDelete(marker.fileId)}
                  />
                ),
              })
              )]}
              onSelectionChanged={ids => handleSelect(ids, MarkerStyleType.CUSTOM)}
              selectedImagesIds={isTemporaryCustomMarkerVisualSettings(markerSettings) ? [markerSettings.fileId] : []}
            />
          </div>
        )}
        {props.isUploading && <OverlayLoaderComponent />}
      </OverlayWrapperComponent>
    </div>
  );
};
