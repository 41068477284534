import {
  computeDistanceBetween, computeOffset,
} from 'spherical-geometry-js';
import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { getOutlineProximityStyles } from '~/_shared/constants/mapObjects/mapAreaBorder.constants';
import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  type LatLng, type LatLngBounds,
} from '~/_shared/types/latLng';
import {
  type DriveTimePolygonProximity, type GroupRadiusProximity, type IndividualRadiusProximity, isDriveTimePolygon,
  isGroupRadius, type ProximityStyles,
} from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  convertColorToWebGLColor, getContrastColor,
} from '~/_shared/utils/colors/colors.helpers';
import { logError } from '~/_shared/utils/logError';
import { nameOf } from '~/_shared/utils/nameOf';
import {
  convertUnitToKilometers, convertUnitToMeters,
} from '~/_shared/utils/unitSystem/unitSystem.helpers';
import {
  createWebglOverlayLabelWithBody, updateWebglOverlayLabelWithBody, type WebglOverlayLabelWithBody,
} from '~/_shared/utils/webgl/labelWithBody';
import { applyIndividualDetailsToProximity } from '~/proximity/proximity.helpers';
import { BoundingBox } from '../boundingBox';
import { PredefinedTemplate } from '../markers/manager/mapMarkerTemplates';
import { type ExtendsWebglLayers } from '../webgl/useWebGL';

const centerMarkerSize = 20;
const centerMarkerSizeHovered = 24;
const defaultProximityCenterMarkerColor = '#0078ce';

type EventCallback = (proximityId: string, rowId?: SpreadsheetRowId, latLng?: LatLng) => void;

type WithCommonData<T> = T & {
  distanceLabel: WebglOverlayLabelWithBody | null;
  registeredCallbacks: ReadonlyArray<RegisteredCallback>;
};

type IndividualProximityData = WithCommonData<{
  circle: WebglOverlayCircleArea;
  proximity: IndividualRadiusProximity;
  centerMarker: WebglOverlayMarker;
}>;
type GroupCircleData = WithCommonData<{
  circle: WebglOverlayCircleArea;
  rowId: SpreadsheetRowId;
}>;
type GroupProximityData = {
  circles: Map<string, GroupCircleData>;
  proximity: GroupRadiusProximity;
  isVisible: boolean;
};
export type GroupProximityInput = Readonly<{ rowId: SpreadsheetRowId; latLng: LatLng; zIndex: ProximityZIndex }>;
type GroupProximityInputData = ReadonlyArray<GroupProximityInput>;
type PolygonData = WithCommonData<{
  polygons: WebglOverlayPolygon[];
  proximity: DriveTimePolygonProximity;
  centerMarker: WebglOverlayMarker;
}>;
const isGroupCircleData = (data: IndividualProximityData | GroupProximityData | PolygonData): data is GroupProximityData =>
  data.hasOwnProperty(nameOf<GroupProximityData>('circles')) && isGroupRadius(data.proximity);
const isIndividualCircleData = (data: IndividualProximityData | GroupCircleData | PolygonData): data is IndividualProximityData =>
  data.hasOwnProperty(nameOf<IndividualProximityData>('centerMarker'));
const isPolygonData = (data: IndividualProximityData | GroupCircleData | PolygonData): data is PolygonData =>
  data.hasOwnProperty(nameOf<PolygonData>('polygons'));

type CirclesMap = Map<string, IndividualProximityData | GroupProximityData>;
type PolygonsMap = Map<string, PolygonData>;
type PolygonsOutlineMap = Map<string, WebglOverlayPolygon[]>;

type BaseUpdateProximityParams<TExisting, TProximity> = {
  existing: TExisting;
  isVisible: boolean;
  overlayLayers: RequiredLayers;
  proximity: TProximity;
  zIndex: ProximityZIndex;
};

type BaseInteractiveUpdateParams<TExisting, TProximity> = BaseUpdateProximityParams<TExisting, TProximity> & {
  hideLabel: boolean;
  isMarkerHovered: boolean;
};

export type ProximityZIndex = Readonly<{
  entity: number;
  outline: number;
  label: number;
  labelText: number;
  marker: number;
}>;

type CenterMarkerOptions ={
  color?: string;
  isDragged: boolean;
  isHidden: boolean;
  isHovered: boolean;
  latLng: LatLng;
  zIndex: number;
};

type ProximityEntityType = 'circle' | 'polygon';

type RegisteredCallback = {
  originalCallback: EventCallback;
} & ({
  eventName: MapElementMousePositionEventName;
  boundCallback: (args: MapObjectMouseEventArgs & { latLng?: undefined }) => void;
} | {
  eventName: MapElementClickEventName;
  boundCallback: (args: MapObjectClickEventArgs) => void;
});

type RequiredLayers = ExtendsWebglLayers<Readonly<{
  DriveTimePolygons: PolygonLayer;
  ProximityCircles: CircleAreaLayer;
  ProximityLabelsText: LabelsLayer;
  ProximityLabelsBackground: TextBoxCalloutLayer;
  ProximityCenters: MarkersLayer;
}>>;

export class MapProximityManager {
  private readonly overlayLayers: RequiredLayers;
  private renderedCircles: CirclesMap = new Map();
  private renderedPolygons: PolygonsMap = new Map();
  private renderedPolygonOutlines: PolygonsOutlineMap = new Map();

  private commonEventListeners: {eventName: MapElementEventName; callback: EventCallback}[] = [];

  constructor(overlayLayers: RequiredLayers) {
    this.overlayLayers = overlayLayers;
  }

  addCommonEventListener = (eventName: MapElementEventName, callback: EventCallback) => {
    this.commonEventListeners = [...this.commonEventListeners, { eventName, callback }];

    this.renderedCircles.forEach((data, id) => {
      if (isGroupCircleData(data)) {
        data.circles
          .forEach(circle => {
            const eventListener = addEntityEventListener(id, [circle.circle], eventName, callback, circle.rowId);
            circle.registeredCallbacks = [...circle.registeredCallbacks, eventListener];
          });
      }
      else {
        const eventListener = addEntityEventListener(id, [data.circle], eventName, callback, undefined);
        data.registeredCallbacks = [...data.registeredCallbacks, eventListener];
      }
    });

    this.renderedPolygons.forEach((data, id) => {
      const eventListener = addEntityEventListener(id, data.polygons, eventName, callback, undefined);
      data.registeredCallbacks = [...data.registeredCallbacks, eventListener];
    });
  };

  removeCommonEventListener = (callback: EventCallback) => {
    this.commonEventListeners = this.commonEventListeners.filter(e => e.callback !== callback);

    this.renderedCircles.forEach(data => {
      (isGroupCircleData(data) ? data.circles : [data])
        .forEach((circle: GroupCircleData | IndividualProximityData) => removeEntityEventListener(circle, callback));
    });
    this.renderedPolygons.forEach(data => removeEntityEventListener(data, callback));
  };

  addSingleProximityEventListener = (proximityId: string, eventName: MapElementEventName, callback: EventCallback, entityType: ProximityEntityType) => {
    const entity = entityType === 'circle' ? this.renderedCircles.get(proximityId) : this.renderedPolygons.get(proximityId);
    if (!entity) {
      return;
    }

    if (isGroupCircleData(entity)) {
      entity.circles.forEach(circle => {
        const eventListener = addEntityEventListener(proximityId, [circle.circle], eventName, callback, circle.rowId);
        circle.registeredCallbacks = [...circle.registeredCallbacks, eventListener];
      });
    }
    else {
      const eventListener = addEntityEventListener(
        proximityId,
        isIndividualCircleData(entity) ? [entity.circle] : entity.polygons,
        eventName,
        callback,
        undefined,
      );

      entity.registeredCallbacks = [...entity.registeredCallbacks, eventListener];
    }
  };

  removeSingleProximityEventListener = (proximityId: string, callback: EventCallback, entityType: ProximityEntityType) => {
    const entity = entityType === 'circle' ? this.renderedCircles.get(proximityId) : this.renderedPolygons.get(proximityId);
    if (!entity) {
      return;
    }

    if (isGroupCircleData(entity)) {
      entity.circles.forEach(circle => removeEntityEventListener(circle, callback));
    }
    else {
      removeEntityEventListener(entity, callback);
    }
  };

  removeProximityCircle(proximityId: string) {
    const circles = this.renderedCircles.get(proximityId);
    this.removeProximityCircles(circles);
    this.renderedCircles.delete(proximityId);
  }

  removeProximityPolygon(proximityId: string) {
    this.removeOutlineForDriveTimePolygon(proximityId);
    const data = this.renderedPolygons.get(proximityId);
    if (data) {
      this.removeEntitiesFromWebgl(data);
    }
    this.renderedPolygons.delete(proximityId);
  }

  renderProximityDriveTimePolygon({ proximity, isVisible, zIndex, hideLabel, isOutlineVisible, isMarkerHovered }: {
    isMarkerHovered: boolean;
    isOutlineVisible: boolean;
    isVisible: boolean;
    proximity: DriveTimePolygonProximity;
    hideLabel: boolean;
    zIndex: ProximityZIndex;
  }) {
    const existing = this.renderedPolygons.get(proximity.id);

    if (!isVisible) {
      this.removeOutlineForDriveTimePolygon(proximity.id);
    }
    else {
      this.renderOutlineForDriveTimePolygon(proximity, zIndex, isOutlineVisible);
    }

    if (existing) {

      if (!isVisible) {
        this.removeProximityPolygon(proximity.id);
        return null;
      }

      updateProximityDriveTimePolygon({
        existing, proximity, isVisible, zIndex, hideLabel, overlayLayers: this.overlayLayers, isMarkerHovered,
      });

      return existing;
    }

    if (isVisible) {
      const newProximity = this.createPolygonProximity(proximity, isVisible, zIndex, hideLabel);
      if (!newProximity) {
        return null;
      }

      this.renderedPolygons.set(proximity.id, newProximity);
      return newProximity;
    }

    return null;
  }

  renderProximitySingleRadius(
    proximity: IndividualRadiusProximity,
    isVisible: boolean,
    zIndex: ProximityZIndex,
    hideLabel: boolean,
    isBeingDragged: boolean,
    isMarkerHovered: boolean,
  ) {
    const existing = this.renderedCircles.get(proximity.id);
    if (existing && isGroupCircleData(existing)) {
      logError(`Tried to render proximity ${proximity.id} as individual while group proximity with the same id already exists.`);
      return null;
    }

    if (existing) {
      updateProximitySingleRadius({
        existing, proximity, isVisible, zIndex, hideLabel, isBeingDragged, isMarkerHovered, overlayLayers: this.overlayLayers,
      });

      return existing;
    }

    const newProximity = this.createSingleRadiusProximity(proximity, isVisible, zIndex, hideLabel, isBeingDragged);

    this.renderedCircles.set(proximity.id, newProximity);

    return newProximity;
  }

  renderProximityGroupRadius(proximity: GroupRadiusProximity, isVisible: boolean, data: GroupProximityInputData) {
    const existing = this.renderedCircles.get(proximity.id);
    if (existing && !isGroupCircleData(existing)) {
      logError(`Tried to render proximity with id ${proximity.id} as group while there already is single proximity with same id.`);
      return;
    }

    if (existing && isGroupCircleData(existing)) {
      this.removeExistingExceptNewGroupProximityCircles(existing.circles, data);
    }

    const newCircles = this.renderNewGroupProximityCircles(existing?.circles ?? new Map(), proximity, isVisible, data);
    const updatedCirclesMap = this.updateExistingGroupProximityCircles(existing?.circles ?? new Map(), proximity, existing?.proximity, isVisible, existing?.isVisible, data);

    newCircles.forEach(c => updatedCirclesMap.set(serializeRowId(c.rowId), c));
    this.renderedCircles.set(proximity.id, { proximity, isVisible, circles: updatedCirclesMap });
  }

  renderProximityGroupRadiusLabels(proximity: GroupRadiusProximity, isVisible: boolean, data: GroupProximityInputData, circlesWithLabelsRowIds: ReadonlyArray<{ spreadsheetRowId: SpreadsheetRowId }>) {
    const existing = this.renderedCircles.get(proximity.id);
    if (existing && !isGroupCircleData(existing)) {
      logError(`Tried to render proximity with id ${proximity.id} as group while there already is single proximity with same id.`);
      return;
    }

    const circlesWithLabelsSet = new Set(circlesWithLabelsRowIds.map(r => serializeRowId(r.spreadsheetRowId)));
    const dataMap = new Map(data.map(c => [serializeRowId(c.rowId), c] as const));

    if (existing && isGroupCircleData(existing)) {
      this.removeExistingExceptNewGroupProximityLabels(circlesWithLabelsSet, existing.circles);
    }

    this.renderNewGroupProximityLabels(circlesWithLabelsSet, existing?.circles ?? new Map(), proximity, isVisible, dataMap);
    this.updateExistingGroupProximityLabels(circlesWithLabelsSet, existing?.circles ?? new Map(), proximity, isVisible, dataMap);
  }

  private renderNewGroupProximityCircles = (
    renderedCircles: Map<string, { rowId: SpreadsheetRowId }>,
    proximity: GroupRadiusProximity,
    isVisible: boolean,
    data: GroupProximityInputData,
  ): ReadonlyArray<GroupCircleData> => {
    const entities: ReadonlyArray<GroupCircleData> = data
      .filter(d => !renderedCircles.has(serializeRowId(d.rowId)))
      .map(d => {
        const circle = createGroupRadiusProximityCircle(proximity, isVisible, d.latLng, d.rowId, d.zIndex.entity);

        const registeredCallbacks = this.commonEventListeners
          .map(e => addEntityEventListener(proximity.id, [circle], e.eventName, e.callback, d.rowId));

        return { circle, distanceLabel: null, rowId: d.rowId, registeredCallbacks };
      });

    entities
      .forEach(data => this.addEntitiesToWebgl(data));

    return entities;
  };

  private renderNewGroupProximityLabels = (
    newCirclesWithLabelsSerializeRowId: Set<string>,
    renderedCircles: Map<string, GroupCircleData>,
    proximity: GroupRadiusProximity,
    isVisible: boolean,
    data: Map<string, GroupProximityInput>,
  ) => {
    newCirclesWithLabelsSerializeRowId.forEach(serializedRowId => {
      const renderedCircle = renderedCircles.get(serializedRowId);
      if (renderedCircle) {
        if (!renderedCircle.distanceLabel) {
          const circleData = data.get(serializedRowId);
          if (circleData) {
            const labelBounds = getCircleLabelBoundingBox(circleData.latLng, proximity.data.radius, proximity.data.unit);
            const withIndividualDetails = applyIndividualDetailsToProximity(proximity, circleData.rowId);
            const distanceLabel = createDistanceLabelEntity(withIndividualDetails, labelBounds, circleData.zIndex, !isVisible);
            addDistanceLabelToLayers(distanceLabel, this.overlayLayers);

            renderedCircle.distanceLabel = distanceLabel;
          }
        }
      }
    });
  };

  private updateExistingGroupProximityCircles = (
    renderedCircles: Map<string, GroupCircleData>,
    newProximity: GroupRadiusProximity,
    currentProximity: GroupRadiusProximity | undefined,
    newIsVisible: boolean,
    currentIsVisible: boolean | undefined,
    data: GroupProximityInputData,
  ): Map<string, GroupCircleData> => {
    const dataMap = new Map(data.map(c => [serializeRowId(c.rowId), c] as const));
    const keysToRemove = new Set<string>();
    renderedCircles.forEach((circle, circleKey) => {
      const circleData = dataMap.get(circleKey);
      if (circleData) {
        // we don't need to update if no parameter's value has changed
        if (currentProximity !== newProximity || newIsVisible !== currentIsVisible || circleData.zIndex.entity !== circle.circle.zIndex) {
          updateGroupRadiusProximityCircle(circle, newProximity, newIsVisible, circleData.zIndex);
        }
      }
      else {
        keysToRemove.add(circleKey);
      }
    });
    keysToRemove.forEach(key => renderedCircles.delete(key));
    return renderedCircles;
  };

  private updateExistingGroupProximityLabels = (
    newCirclesWithLabelsSerializeRowId: Set<string>,
    renderedCircles: Map<string, GroupCircleData>,
    proximity: GroupRadiusProximity,
    isVisible: boolean,
    data: Map<string, GroupProximityInput>,
  ) => {
    renderedCircles.forEach((circle, circleKey) => {
      const circleData = data.get(circleKey);
      const isNewCircleWithLabel = newCirclesWithLabelsSerializeRowId.has(circleKey);
      if (circleData && isNewCircleWithLabel && circle.distanceLabel) {
        updateGroupRadiusProximityLabel({
          existing: circle, proximity, isVisible, zIndex: circleData.zIndex, overlayLayers: this.overlayLayers,
        });
      }
    });
  };

  private removeExistingExceptNewGroupProximityCircles = (
    renderedCircles: Map<string, GroupCircleData>,
    data: GroupProximityInputData,
  ) => {
    const newRowIds = new Set(data.map(d => serializeRowId(d.rowId)));

    renderedCircles.forEach((circle, circleKey) => {
      if (!newRowIds.has(circleKey)) {
        this.removeEntitiesFromWebgl(circle);
      }
    });
  };

  private removeExistingExceptNewGroupProximityLabels = (
    newCirclesWithLabelsSerializeRowId: Set<string>,
    renderedCircles: Map<string, GroupCircleData>,
  ) => {
    renderedCircles.forEach((circle, circleKey) => {
      if (!newCirclesWithLabelsSerializeRowId.has(circleKey)) {
        removeDistanceLabelFromLayers(circle.distanceLabel, this.overlayLayers);
        circle.distanceLabel = null;
      }
    });
  };

  private removeProximityCircles(circleData: GroupProximityData | IndividualProximityData | undefined) {
    if (!circleData) {
      return;
    }
    if (isGroupCircleData(circleData)) {
      return circleData.circles.forEach(c => this.removeEntitiesFromWebgl(c));
    }
    this.removeEntitiesFromWebgl(circleData);
  }

  private removeEntitiesFromWebgl = (data: IndividualProximityData | GroupCircleData | PolygonData) => {
    removeDistanceLabelFromLayers(data.distanceLabel, this.overlayLayers);

    if (isPolygonData(data)) {
      data.polygons.forEach((polygon) => {
        this.overlayLayers.DriveTimePolygons.remove(polygon);
      });
      this.overlayLayers.ProximityCenters.remove(data.centerMarker);
    }
    else {
      if (isIndividualCircleData(data)) {
        this.overlayLayers.ProximityCenters.remove(data.centerMarker);
      }

      this.overlayLayers.ProximityCircles.remove(data.circle);
    }
  };

  private addEntitiesToWebgl = (data: IndividualProximityData | GroupCircleData | PolygonData) => {
    addDistanceLabelToLayers(data.distanceLabel, this.overlayLayers);

    if (isPolygonData(data) || isIndividualCircleData(data)) {
      this.overlayLayers.ProximityCenters.add(data.centerMarker);
    }

    if (isPolygonData(data)) {
      data.polygons.forEach((polygon) => {
        this.overlayLayers.DriveTimePolygons.add(polygon);
      });
      return;
    }

    this.overlayLayers.ProximityCircles.add(data.circle);
  };

  private createPolygonProximity = (proximity: DriveTimePolygonProximity, isVisible: boolean, zIndex: ProximityZIndex, hideLabel: boolean): PolygonData | null => {
    const polygons = createDriveTimePolygonProximityEntity(proximity, isVisible, zIndex.entity);
    if (!polygons) {
      return null;
    }

    const registeredCallbacks = this.commonEventListeners
      .map(e => addEntityEventListener(proximity.id, polygons, e.eventName, e.callback, undefined));
    const labelBounds = getPolygonLabelBounds(proximity);
    const labelHidden = hideLabel || !isVisible || !labelBounds;
    const centerMarker = createCenterMarker({
      color: proximity.styles.color,
      isDragged: false,
      isHidden: !isVisible,
      isHovered: false,
      latLng: proximity.data,
      zIndex: zIndex.marker,
    });
    const distanceLabel = createDistanceLabelEntity(
      proximity, labelBounds.bounds || labelBounds.fallback, zIndex, labelHidden
    );

    const result: PolygonData = { proximity, polygons, distanceLabel, centerMarker, registeredCallbacks };
    this.addEntitiesToWebgl(result);

    return result;
  };

  private createSingleRadiusProximity = (proximity: IndividualRadiusProximity, isVisible: boolean, zIndex: ProximityZIndex, hideLabel: boolean, isBeingDragged: boolean): IndividualProximityData => {
    const circle = createIndividualRadiusProximityCircle(proximity, isVisible, zIndex.entity);
    const labelBounds = getCircleLabelBoundingBox(proximity.data.data, proximity.data.radius, proximity.data.unit);
    const centerMarker = createCenterMarker({
      isDragged: isBeingDragged,
      isHidden: !isVisible,
      isHovered: false,
      latLng: proximity.data.data,
      zIndex: zIndex.marker,
    });
    const distanceLabel = createDistanceLabelEntity(proximity, labelBounds, zIndex, hideLabel || !isVisible);

    const registeredCallbacks = this.commonEventListeners
      .map(e => addEntityEventListener(proximity.id, [circle], e.eventName, e.callback, undefined));

    const result: IndividualProximityData = { circle, proximity, distanceLabel, centerMarker, registeredCallbacks };
    this.addEntitiesToWebgl(result);

    return result;
  };

  private renderOutlineForDriveTimePolygon(proximity: DriveTimePolygonProximity, zIndex: ProximityZIndex, isOutlineVisible: boolean) {
    const existing = this.renderedPolygonOutlines.get(proximity.id);
    if (existing) {
      this.updateOutlineForDriveTimePolygon(existing, zIndex, isOutlineVisible);
      return;
    }
    const paths = proximity.data.paths;
    if (!paths) {
      return;
    }
    const polygons = paths.map(polygon =>
      new WebGLOverlay.Polygon(createCommonProperties(getOutlineProximityStyles(isOutlineVisible), true, zIndex.outline, false), [[polygon.path, ...polygon.holes]]));
    if (!polygons) {
      return;
    }

    polygons.forEach((polygon) => {
      this.overlayLayers.DriveTimePolygons.add(polygon);
    });

    this.renderedPolygonOutlines.set(proximity.id, polygons);
  }

  private updateOutlineForDriveTimePolygon(existingPolygons: WebglOverlayPolygon[], zIndex: ProximityZIndex, isOutlineVisible: boolean) {
    for (const existingPolygon of existingPolygons) {
      updateCommonProperties(existingPolygon, getOutlineProximityStyles(isOutlineVisible), true, zIndex.outline);
    }
  }

  private removeOutlineForDriveTimePolygon(proximityId: string) {
    const polygons = this.renderedPolygonOutlines.get(proximityId);
    if (polygons) {
      polygons.forEach((polygon) => {
        this.overlayLayers.DriveTimePolygons.remove(polygon);
      });
    }
    this.renderedPolygonOutlines.delete(proximityId);
  }
}

const removeEntityEventListener = (data: GroupCircleData | IndividualProximityData | PolygonData, callback: EventCallback) => {
  const registeredCallback = data.registeredCallbacks.find(c => c.originalCallback === callback);
  if (!registeredCallback) {
    return;
  }

  if (isPolygonData(data)) {
    data.polygons.forEach((polygon) => {
      polygon.removeEventListener(registeredCallback.eventName, registeredCallback.boundCallback);
    });
  }
  else {
    data.circle.removeEventListener(registeredCallback.eventName, registeredCallback.boundCallback);
  }

  data.registeredCallbacks = data.registeredCallbacks.filter(c => c !== registeredCallback);
};

const addEntityEventListener = (id: string, entities: WebglOverlayCircleArea[] | WebglOverlayPolygon[], eventName: MapElementEventName, callback: EventCallback, rowId: SpreadsheetRowId | undefined) => {
  const registeredCallback: RegisteredCallback = {
    eventName,
    originalCallback: callback,
    boundCallback: ({ latLng }: MapObjectClickEventArgs | (MapObjectMouseEventArgs & { latLng?: undefined })) => {
      callback(id, rowId, latLng);
    },
  };

  entities.forEach((entity: WebglOverlayCircleArea | WebglOverlayPolygon) => {
    entity.addEventListener(eventName, registeredCallback.boundCallback);
  });

  return registeredCallback;
};

const createIndividualRadiusProximityCircle = (proximity: IndividualRadiusProximity, isVisible: boolean, zIndex: number) => {
  return new WebGLOverlay.CircleArea({
    ...createCommonProperties(proximity.styles, isVisible, zIndex),
    radius: convertUnitToKilometers(proximity.data.radius, proximity.data.unit),
    lat: proximity.data.data.lat,
    lng: proximity.data.data.lng,
    units: 'metric',
  });
};

const updateProximitySingleRadius = ({
  existing, proximity, isVisible, zIndex, hideLabel, isBeingDragged, isMarkerHovered, overlayLayers,
}: BaseInteractiveUpdateParams<IndividualProximityData, IndividualRadiusProximity> & { isBeingDragged: boolean }) => {
  existing.circle.radius = convertUnitToKilometers(proximity.data.radius, proximity.data.unit);
  existing.circle.lat = proximity.data.data.lat;
  existing.circle.lng = proximity.data.data.lng;
  updateCommonProperties(existing.circle, proximity.styles, isVisible, zIndex.entity);

  const labelBounds = getCircleLabelBoundingBox(proximity.data.data, proximity.data.radius, proximity.data.unit);
  existing.distanceLabel = updateDistanceLabelEntity(existing.distanceLabel, proximity, labelBounds, hideLabel || !isVisible, zIndex, overlayLayers);

  const hasCenterHoverStyles = isMarkerHovered && !isBeingDragged;
  updateCenterMarker(existing.centerMarker, {
    isDragged: isBeingDragged,
    isHidden: !isVisible,
    isHovered: hasCenterHoverStyles,
    latLng: proximity.data.data,
    zIndex: zIndex.marker,
  });

  existing.proximity = proximity;
};

const updateProximityDriveTimePolygon = ({
  existing, proximity, isVisible, zIndex, hideLabel, overlayLayers, isMarkerHovered,
}: BaseInteractiveUpdateParams<PolygonData, DriveTimePolygonProximity>) => {
  for (const existingPolygon of existing.polygons) {
    updateCommonProperties(existingPolygon, proximity.styles, isVisible, zIndex.entity);
  }

  const labelBounds = getPolygonLabelBounds(proximity);
  const labelHidden = hideLabel || !isVisible || !labelBounds;
  existing.distanceLabel = updateDistanceLabelEntity(
    existing.distanceLabel,
    proximity,
    labelBounds.bounds || labelBounds.fallback,
    labelHidden,
    zIndex,
    overlayLayers,
  );

  updateCenterMarker(existing.centerMarker, {
    color: proximity.styles.color,
    isDragged: false,
    isHidden: !isVisible,
    isHovered: isMarkerHovered,
    latLng: proximity.data,
    zIndex: zIndex.marker,
  });

  existing.proximity = proximity;
};

const createGroupRadiusProximityCircle = (proximity: GroupRadiusProximity, isVisible: boolean, latLng: LatLng, spreadsheetRowId: SpreadsheetRowId, zIndex: number) => {
  const withIndividualDetails = applyIndividualDetailsToProximity(proximity, spreadsheetRowId);

  return new WebGLOverlay.CircleArea({
    ...createCommonProperties(withIndividualDetails.styles, isVisible, zIndex),
    radius: convertUnitToKilometers(withIndividualDetails.data.radius, withIndividualDetails.data.unit),
    lat: latLng.lat,
    lng: latLng.lng,
    units: 'metric',
  });
};

const updateGroupRadiusProximityCircle = (
  existing: GroupCircleData,
  proximity: GroupRadiusProximity,
  isVisible: boolean,
  zIndex: ProximityZIndex,
) => {
  const withIndividualDetails = applyIndividualDetailsToProximity(proximity, existing.rowId);
  existing.circle.radius = convertUnitToKilometers(withIndividualDetails.data.radius, withIndividualDetails.data.unit);
  updateCommonProperties(existing.circle, withIndividualDetails.styles, isVisible, zIndex.entity);
};

const updateGroupRadiusProximityLabel = ({
  existing, proximity, isVisible, zIndex, overlayLayers,
}: BaseUpdateProximityParams<GroupCircleData, GroupRadiusProximity>) => {
  const withIndividualDetails = applyIndividualDetailsToProximity(proximity, existing.rowId);
  const labelBounds = getCircleLabelBoundingBox(existing.circle, existing.circle.radius, UnitSystem.metric);
  existing.distanceLabel = updateDistanceLabelEntity(existing.distanceLabel, withIndividualDetails, labelBounds, !isVisible, zIndex, overlayLayers);
};

const createDriveTimePolygonProximityEntity = (proximity: DriveTimePolygonProximity, isVisible: boolean, zIndex: number) => {
  const paths = proximity.data.paths;
  if (!paths) {
    return;
  }
  return paths.map(polygon =>
    new WebGLOverlay.Polygon(createCommonProperties(proximity.styles, isVisible, zIndex), [[polygon.path, ...polygon.holes]]));
};

const updateCommonProperties = (
  entity: WebglOverlayPolygon | WebglOverlayCircleArea,
  styles: ProximityStyles,
  isVisible: boolean,
  zIndex: number
) => {
  entity.visible = isVisible;
  entity.fillColor.set(...convertColorToWebGLColor(styles.color, styles.fillOpacity));
  entity.border = styles.borderWidth !== 0;
  entity.borderWidth = styles.borderWidth;
  entity.borderColor.set(...convertColorToWebGLColor(styles.borderColor, styles.borderOpacity));
  entity.zIndex = zIndex;
};

const createCommonProperties = (proximityStyles: ProximityStyles, isVisible: boolean, zIndex: number, isInteractive: boolean = true): WebglOverlayPolygonConfig => ({
  visible: isVisible,
  fillColor: convertColorToWebGLColor(proximityStyles.color, proximityStyles.fillOpacity),
  border: proximityStyles.borderWidth !== 0,
  borderWidth: proximityStyles.borderWidth,
  borderColor: convertColorToWebGLColor(proximityStyles.borderColor, proximityStyles.borderOpacity),
  zIndex,
  interactive: isInteractive,
});

const updateDistanceLabelEntity = (
  existing: WebglOverlayLabelWithBody | null,
  proximity: DriveTimePolygonProximity | IndividualRadiusProximity | GroupRadiusProximity,
  labelBounds: LatLngBounds,
  isHidden: boolean,
  zIndex: ProximityZIndex,
  overlayLayers: RequiredLayers,
): WebglOverlayLabelWithBody | null => {
  if (existing) {
    if (isHidden) {
      removeDistanceLabelFromLayers(existing, overlayLayers);
      return null;
    }
    else {
      updateWebglOverlayLabelWithBody(existing, {
        boundaries: {
          enabled: true,
          minFontSize: 5,
          maxFontSize: 20,
          sw: labelBounds.sw,
          ne: labelBounds.ne,
        },
        text: {
          value: createDistanceLabelText(proximity),
          fillColor: textFillColor(proximity.styles.color),
        },
        fillColor: convertColorToWebGLColor(proximity.styles.color, 1),
      }, { text: zIndex.labelText, background: zIndex.label });

      return existing;
    }
  }
  else {
    const newLabel = createDistanceLabelEntity(proximity, labelBounds, zIndex, isHidden);
    addDistanceLabelToLayers(newLabel, overlayLayers);
    return newLabel;
  }
};

const labelPaddingHorizontal = 5;
const labelPaddingVertical = 1;

const createDistanceLabelEntity = (
  proximity: DriveTimePolygonProximity | IndividualRadiusProximity | GroupRadiusProximity,
  labelBounds: LatLngBounds,
  zIndex: ProximityZIndex,
  isHidden: boolean,
): WebglOverlayLabelWithBody | null => {
  if (isHidden) {
    return null;
  }

  return createWebglOverlayLabelWithBody({
    boundaries: {
      enabled: true,
      minFontSize: 5,
      maxFontSize: 20,
      sw: labelBounds.sw,
      ne: labelBounds.ne,
    },
    interactive: false,
    text: {
      fillColor: textFillColor(proximity.styles.color),
      value: createDistanceLabelText(proximity),
      linePadding: 0,
    },
    triangle: false,
    fillColor: convertColorToWebGLColor(proximity.styles.color, 1),
    borderRadius: 5,
    borderWidth: 0,
    padding: { b: labelPaddingVertical, l: labelPaddingHorizontal, r: labelPaddingHorizontal, t: labelPaddingVertical },
    horizontalAnchor: 'center',
    verticalAnchor: 'top',
    offset: { x: 0, y: -1 }, // move 1 pixel higher so it covers top edge of cirle area
  }, { background: zIndex.label, text: zIndex.labelText });
};

const createDistanceLabelText = (proximity: DriveTimePolygonProximity | IndividualRadiusProximity | GroupRadiusProximity) => {
  if (isDriveTimePolygon(proximity)) {
    return `${proximity.data.hours}H ${proximity.data.minutes}M`;
  }
  else {
    return `${proximity.data.radius}${proximity.data.unit === UnitSystem.metric ? 'KM' : 'MI'}`;
  }
};

const getPolygonLabelBounds = (proximity: DriveTimePolygonProximity): {
  bounds: LatLngBounds | null;
  fallback: LatLngBounds;
} => {
  let pathsBounds = null;
  if (proximity.data.paths && proximity.data.paths[0]?.path[0]) {
    let middleTopPoint = proximity.data.paths[0].path[0];
    const pathsBB = proximity.data.paths.reduce((bb, polygon) => {
      polygon.path.forEach(point => {
        if (point.lat > middleTopPoint.lat) {
          middleTopPoint = point;
        }
        bb.extend(point);
      });
      return bb;
    }, new BoundingBox());

    const northWestPoint = { lat: pathsBB.getNorthEast().lat, lng: pathsBB.getSouthWest().lng };
    const polygonWidthInMeters = computeDistanceBetween(
      pathsBB.getNorthEast(),
      northWestPoint,
    );
    const polygonHeightInMeters = computeDistanceBetween(
      pathsBB.getNorthEast(),
      { lat: pathsBB.getSouthWest().lat, lng: pathsBB.getNorthEast().lng }
    );
    const labelDimensionInMeters = polygonWidthInMeters > polygonHeightInMeters
      ? polygonWidthInMeters : polygonHeightInMeters;
    const labelNorthEastPoint = computeOffset(middleTopPoint, labelDimensionInMeters / 2, 90);
    const labelNorthWestPoint = computeOffset(middleTopPoint, labelDimensionInMeters / 2, 270);
    const labelSouthWestPoint = computeOffset(labelNorthWestPoint, labelDimensionInMeters, 180);

    pathsBounds = {
      ne: { lat: labelNorthEastPoint.lat(), lng: labelNorthEastPoint.lng() },
      sw: { lat: labelSouthWestPoint.lat(), lng: labelSouthWestPoint.lng() },
    };
  }
  return {
    bounds: pathsBounds,
    fallback: {
      ne: { lat: proximity.data.lat, lng: proximity.data.lng },
      sw: { lat: proximity.data.lat, lng: proximity.data.lng },
    },
  };
};

const getCircleLabelBoundingBox = (circleLatLng: LatLng, radius: number, unit: UnitSystem): LatLngBounds => {
  const radiusInMeters = convertUnitToMeters(radius, unit);
  const northPoint = computeOffset(circleLatLng, radiusInMeters, 0);
  const southPoint = computeOffset(circleLatLng, radiusInMeters / 2, 0);
  const northEastPoint = computeOffset(northPoint, radiusInMeters / 2, 90);
  const southWestPoint = computeOffset(southPoint, radiusInMeters / 2, 270);

  return {
    ne: { lat: northEastPoint.lat(), lng: northEastPoint.lng() },
    sw: { lat: southWestPoint.lat(), lng: southWestPoint.lng() },
  };
};

const serializeRowId = (rowId: SpreadsheetRowId) => `${rowId.spreadsheetId};${rowId.rowId}`;

const createCenterMarker = ({
  color = defaultProximityCenterMarkerColor,
  isDragged,
  isHidden,
  latLng,
  zIndex,
}: CenterMarkerOptions) =>
  new WebGLOverlay.Marker({
    color: convertColorToWebGLColor(color),
    interactive: true,
    lat: latLng.lat,
    lng: latLng.lng,
    size: centerMarkerSize,
    template: isDragged ? PredefinedTemplate.AimMarker : PredefinedTemplate.ProximityCenter,
    visible: !isHidden,
    zIndex,
  });

const updateCenterMarker = (
  existing: WebglOverlayMarker, {
    color = defaultProximityCenterMarkerColor,
    isDragged,
    isHidden,
    isHovered,
    latLng,
    zIndex,
  }: CenterMarkerOptions
) => {
  existing.color.set(...convertColorToWebGLColor(color));
  existing.lat = latLng.lat;
  existing.lng = latLng.lng;
  existing.size = isHovered ? centerMarkerSizeHovered : centerMarkerSize;
  existing.template = isDragged ? PredefinedTemplate.AimMarker : PredefinedTemplate.ProximityCenter;
  existing.visible = !isHidden;
  existing.zIndex = zIndex;
};

const textFillColor = (proximityFillColor: string) =>
  convertColorToWebGLColor(getContrastColor(createColor('#fff'), createColor(proximityFillColor)).hex, 1);

const addDistanceLabelToLayers = (distanceLabel: WebglOverlayLabelWithBody | null, overlayLayers: RequiredLayers) => {
  if (distanceLabel) {
    overlayLayers.ProximityLabelsText.add(distanceLabel.label);
    overlayLayers.ProximityLabelsBackground.add(distanceLabel.textBoxCallout);
  }
};

const removeDistanceLabelFromLayers = (distanceLabel: WebglOverlayLabelWithBody | null, overlayLayers: RequiredLayers) => {
  if (distanceLabel) {
    overlayLayers.ProximityLabelsText.remove(distanceLabel.label);
    overlayLayers.ProximityLabelsBackground.remove(distanceLabel.textBoxCallout);
  }
};
