import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  applyChangesToLayeredMap as applyChangesToLayeredMapApiCall,
  type ApplyChangesToLayeredMapRequest,
} from '../layering.repository';
import { type BaseMapsUpdateModalActionForMap } from './baseMapsUpdateModal.types';

export const useApplyChanges = (layeredMapId: number) => {
  const clientId = useClientIdSelector();
  const applyChangesToLayeredMap = async (applyActions: BaseMapsUpdateModalActionForMap[]) => {
    if (!clientId) {
      return Promise.resolve();
    }
    const spreadsheets = applyActions.map(action => ({
      id: action.baseMap.id,
      snapshot: action.baseMap.snapshot,
      version: action.baseMap.version,
    }));

    const request: ApplyChangesToLayeredMapRequest = {
      real_spreadsheets: spreadsheets,
    };

    return applyChangesToLayeredMapApiCall(clientId, layeredMapId, request);
  };
  const { isLoading, invokeAjax } = useAjaxCall(applyChangesToLayeredMap);

  return {
    isLoading, applyChanges: invokeAjax,
  };
};
