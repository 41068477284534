import {
  css, type SerializedStyles,
} from '@emotion/react';
import {
  type FC, type FormEvent, useEffect, useState,
} from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import {
  getTextInputHeight, InputSize, InputWithSpinnersComponent,
} from '~/_shared/baseComponents/inputs';
import { useTheme } from '../../../themes/theme.hooks';
import { type ThemeProps } from '../../../types/themeProps';
import { useTranslation } from '../../../utils/hooks';

type PaginationGoToPageProps = Readonly<{
  className?: string;
  currentPage: number;
  labelStyle?: SerializedStyles;
  onPageSelect: (newPage: number) => void;
  pageCount: number;
}>;

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
});

const labelStyle = ({ theme }: ThemeProps) => css({
  fontSize: 12,
  fontWeight: 400,
  color: theme.textColors.secondary,
  marginRight: 10,
});

const buttonStyle = css({
  marginLeft: 10,
  padding: 5,
  height: getTextInputHeight(InputSize.Small),
});

export const PaginationGoToPageComponent: FC<PaginationGoToPageProps> = (props) => {
  const [page, setPage] = useState(props.currentPage);
  const [t] = useTranslation();
  const theme = useTheme();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onPageSelect(page);
  };

  useEffect(() => {
    setPage(props.currentPage);
  }, [props.currentPage]);

  return (
    <form
      css={wrapperStyle}
      className={props.className}
      onSubmit={onSubmit}
    >
      <span css={[labelStyle({ theme }), props.labelStyle]}>
        {t('Go To Page')}
      </span>

      <InputWithSpinnersComponent
        value={page}
        onChange={setPage}
        valueMin={1}
        valueMax={props.pageCount}
        size={InputSize.Small}
        width={66}
      />

      <ButtonComponent
        isSubmit
        css={buttonStyle}
        text={t('Go')}
        buttonStyle={ButtonStyle.Secondary}
        isDisabled={page === props.currentPage}
      />
    </form>
  );
};
