import {
  memo, useEffect, useMemo,
} from 'react';
import { useZIndex } from '~/map/zIndexes/useZIndex.hook';
import { ZIndexedEntity } from '~/map/zIndexes/zIndexRanges';
import { type HeatMapItem } from '~/store/mapSettings/heatmaps/useHeatmapItems.selector';
import { HeatMapType } from '../../../_shared/types/heatmap/heatMap.enum';
import { type HeatMap } from '../../../_shared/types/heatmap/heatMap.types';
import { type SpreadsheetColumnId } from '../../../_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '../../../_shared/types/spreadsheetData/spreadsheetRow';
import {
  isNullOrUndefined, notNull,
} from '../../../_shared/utils/typeGuards';
import { type SpreadsheetLatLngRowData } from '../../../store/selectors/spreadsheetDataMemoizedSelectors';
import {
  DataType,
  type GroupId,
  type SpreadsheetDataData,
  Unfiltered,
} from '../../../store/spreadsheetData/spreadsheetData.state';
import {
  useFilteredLatLngSpreadsheetData,
  useLatLngSpreadsheetData,
  useSpreadSheetData,
} from '../useSpreadsheetData.hook';
import { type HeatMapsManager } from './heatMapsManager';

const computeNumericalValueWeight = (value: number, size: number, minValue: number): number => {
  if (size === 0) {
    return 1;
  }

  // normalization places all values into specific [x, 1] range
  // coef 1 = [0, 1]
  // coef 1.43 = 0.43 (lowest value - normalized zero) / 1.43 (normalized size) = [0.3, 1]
  // coef 2 = 1 (lowest value - normalized zero) / 2 (normalized size) = [0.5, 1]
  const normalizationCoef = 2;

  const normalizedSize = size * normalizationCoef;
  const normalizedZero = (minValue + size) - normalizedSize;

  return (value - normalizedZero) / normalizedSize;
};

type HeatmapInstanceProps = {
  manager: HeatMapsManager;
  item: HeatMapItem;
};

type WithRowId<T> = T & {
  rowId: SpreadsheetRowId;
};

const HeatmapInstance: React.FC<HeatmapInstanceProps> = (props) => {
  const { manager, item } = props;

  const heatmapData = useHeatMapLatLngData(item.heatmap);
  const zIndex = useZIndex(item.heatmap.id, ZIndexedEntity.Heatmap);

  // Draw heatmap
  useEffect(() => {
    manager.createOrUpdateHeatmapItem(item, heatmapData, zIndex);
  }, [item, heatmapData, manager, zIndex]);

  // Unmount cleanup
  useEffect(() => {
    return () => {
      manager.removeHeatmap(item.heatmap.id);
    };
  }, [manager, item.heatmap.id]);

  return null;
};

const pureComponent = memo(HeatmapInstance);
export { pureComponent as HeatmapInstance };

const getAllMarkerDensityHeatMapsLatLng = (markerLatLngData: Pick<SpreadsheetLatLngRowData, 'data'>): ReadonlyArray<WithRowId<HeatmapPoint>> =>
  markerLatLngData.data.map(marker => ({
    lat: marker.lat,
    lng: marker.lng,
    weight: 0.5,
    visible: true,
    rowId: { spreadsheetId: marker.spreadsheetId, rowId: marker.rowId },
  }));

const getGroupDensityHeatMapsLatLng = (
  selectedGroupColumn: SpreadsheetColumnId | null,
  selectedGroup: string,
  spreadsheetData: SpreadsheetDataData,
  markerLatLngData: Pick<SpreadsheetLatLngRowData, 'getRow'>,
): ReadonlyArray<WithRowId<HeatmapPoint>> => {

  if (!selectedGroupColumn || !selectedGroup) {
    return [];
  }

  const { all, visible } = splitRowIdsByVisibilityInGroup(selectedGroupColumn, selectedGroup, spreadsheetData);
  return all.map(rowId => {
    const latLngRow = markerLatLngData.getRow({ spreadsheetId: selectedGroupColumn.spreadsheetId, rowId });

    if (!latLngRow) {
      return null;
    }

    return {
      lat: latLngRow.lat,
      lng: latLngRow.lng,
      weight: 0.5,
      visible: visible.has(rowId),
      rowId: { spreadsheetId: latLngRow.spreadsheetId, rowId: latLngRow.rowId },
    };
  })
    .filter(hd => !!hd) as Array<WithRowId<HeatmapPoint>>;
};

const getAllMarkerNumericHeatMapsLatLng = (
  numericalColumnId: SpreadsheetColumnId | null,
  spreadsheetData: SpreadsheetDataData,
  markerLatLngData: Pick<SpreadsheetLatLngRowData, 'data'>,
): ReadonlyArray<WithRowId<HeatmapPoint>> => {
  if (!numericalColumnId) {
    return [];
  }
  const { maxWeight, minWeight, values } = getNumericValues(spreadsheetData, numericalColumnId.spreadsheetId, numericalColumnId.columnId);

  const size = maxWeight - minWeight;

  if (!values || size < 0) {
    return [];
  }

  return markerLatLngData.data
    .map(marker => {
      const value = values[marker.rowId];

      if (isNullOrUndefined(value)) {
        return null;
      }

      const weight = computeNumericalValueWeight(value, size, minWeight);

      if (isNaN(weight)) {
        return null;
      }

      return {
        lat: marker.lat,
        lng: marker.lng,
        weight,
        visible: true,
        rowId: { spreadsheetId: marker.spreadsheetId, rowId: marker.rowId },
      };
    })
    .filter(notNull);
};

const getGroupNumericHeatMapsLatLng = (
  numericalColumnId: SpreadsheetColumnId | null,
  selectedGroupColumn: SpreadsheetColumnId | null,
  selectedGroup: string,
  spreadsheetData: SpreadsheetDataData,
  markerLatLngData: Pick<SpreadsheetLatLngRowData, 'getRow'>,
): ReadonlyArray<WithRowId<HeatmapPoint>> => {
  if (!numericalColumnId || !selectedGroupColumn || !selectedGroup) {
    return [];
  }

  const { maxWeight, minWeight, values } = getNumericValues(spreadsheetData, numericalColumnId.spreadsheetId, numericalColumnId.columnId);
  const size = maxWeight - minWeight;

  if (!values || size < 0) {
    return [];
  }

  const { visible, all } = splitRowIdsByVisibilityInGroup(selectedGroupColumn, selectedGroup, spreadsheetData);
  return all.map(rowId => {
    const latLngRow = markerLatLngData.getRow({ spreadsheetId: selectedGroupColumn.spreadsheetId, rowId });

    if (!latLngRow) {
      return null;
    }

    const value = values[rowId];
    if (isNullOrUndefined(value)) {
      return null;
    }

    const weight = computeNumericalValueWeight(value, size, minWeight);

    if (isNaN(weight)) {
      return null;
    }

    return {
      lat: latLngRow.lat,
      lng: latLngRow.lng,
      weight,
      visible: visible.has(rowId),
      rowId: { spreadsheetId: latLngRow.spreadsheetId, rowId: latLngRow.rowId },
    };
  })
    .filter(notNull);
};

type SplitRowIds = Readonly<{ visible: ReadonlySet<CombinedRowId>; all: ReadonlyArray<CombinedRowId> }>;
const splitRowIdsByVisibilityInGroup = (columnId: SpreadsheetColumnId, groupId: GroupId, spreadsheetData: SpreadsheetDataData): SplitRowIds => {
  const data = spreadsheetData.values[columnId.spreadsheetId]?.[Unfiltered]?.[columnId.columnId]?.[DataType.GROUP];
  const groupIndex = data?.extra?.uniqueGroups?.findIndex(g => g.id === groupId) ?? -1;
  const allResults = Object.entries(data?.values ?? {})
    .map(([rowId, groupIndexRef]) => ({ rowId, visible: groupIndexRef === groupIndex }))
    .filter(result => !!result.rowId);

  return {
    visible: new Set(allResults.filter(result => result.visible).map(result => result.rowId)),
    all: allResults.map(result => result.rowId),
  };
};

const getNumericValues = (data: SpreadsheetDataData, spreadsheetId: number, columnId: string) => {
  const minWeight = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.NUMBER]?.extra?.min ?? 0;
  const maxWeight = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.NUMBER]?.extra?.max ?? 0;
  const values = data.values[spreadsheetId]?.[Unfiltered]?.[columnId]?.[DataType.NUMBER]?.values;

  return {
    minWeight,
    maxWeight,
    values,
  };
};

const useHeatMapLatLngData = (
  heatmap: HeatMap,
) => {
  const spreadsheetData = useSpreadSheetData().spreadsheetData;
  const unfilteredLatLngData = useLatLngSpreadsheetData();
  const filteredLatLngData = useFilteredLatLngSpreadsheetData().filter;

  const latLngData = heatmap.unlinkFromOtherTools
    ? unfilteredLatLngData
    : filteredLatLngData;

  return useMemo(() => {
    switch (heatmap.type) {
      case HeatMapType.AllMarkersDensity:
        return getAllMarkerDensityHeatMapsLatLng(latLngData);

      case HeatMapType.AllMarkersNumericalData:
        return getAllMarkerNumericHeatMapsLatLng(heatmap.numericalColumnId, spreadsheetData, latLngData);

      case HeatMapType.GroupDensity:
        return getGroupDensityHeatMapsLatLng(heatmap.selectedGroupColumn, heatmap.selectedGroupId,
          spreadsheetData, latLngData);

      case HeatMapType.GroupNumericalData:
        return getGroupNumericHeatMapsLatLng(heatmap.numericalColumnId, heatmap.selectedGroupColumn,
          heatmap.selectedGroupId, spreadsheetData, latLngData);

      default:
        throw new Error('Invariant exception: this code should be unreachable.');
    }
  }, [heatmap.type, heatmap.numericalColumnId, heatmap.selectedGroupColumn, heatmap.selectedGroupId, latLngData, spreadsheetData]);
};
