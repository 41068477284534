import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import {
  GroupRadiusEditLevel,
  ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import { type ProximityModel } from '~/_shared/types/proximity/proximity.types';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  validateDecimalInputValue, validateValueHasOnlyNumericCharacters,
} from '~/_shared/utils/form/form.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { isTextEmpty } from '~/_shared/utils/text/text.helpers';
import { type GroupData } from '~/store/spreadsheetData/spreadsheetData.state';
import {
  createProximityRequest, type ProximityRequestCreateObject,
} from '../../proximity.factory';
import { MAX_DRIVE_TIME_POLYGON_SECONDS } from '../proximityPanel.helpers';

export const getProximityTypeDropdownOptions = (t: TranslationFnc): DropdownOption<ProximityType>[] => ([{
  name: t('Distance Radius'),
  value: ProximityType.DistanceRadius,
}, {
  name: t('Drive Time Polygon'),
  value: ProximityType.DriveTimePolygon,
}]);

export const createProximityApplyToDropdownOption = (applyTo: GroupRadiusEditLevel, t: TranslationFnc) => {
  switch (applyTo) {
    case GroupRadiusEditLevel.Group:
      return {
        name: t('Group'),
        value: GroupRadiusEditLevel.Group,
      };
    case GroupRadiusEditLevel.IndividualLocation:
      return {
        name: t('Individual Location'),
        value: GroupRadiusEditLevel.IndividualLocation,
      };
    default:
      throw new Error(`proximityPanelForm: unknown RadiusApplyTo: ${applyTo}`);
  }
};

export type ProximityArgumentsObject = {
  type: ProximityType;
  applyTo?: GroupRadiusEditLevel;
  address: string;
  proximityWithin: string;
  selectedGroupColumn: SpreadsheetColumnId | null;
  selectedGroup: GroupData | null;
  travelTimeMinutes: number | null;
  travelTimeHours: number | null;
};

export const validateDriveTimePolygonTime = (hours: number | null, minutes: number | null) => {
  if (!hours && !minutes) {
    return false;
  }

  return (hours ?? 0) * 60 * 60 + (minutes ?? 0) * 60 <= MAX_DRIVE_TIME_POLYGON_SECONDS;
};

export const validateProximity = (proximity: ProximityArgumentsObject): boolean => {
  // parse drive time polygon
  if (proximity.type === ProximityType.DriveTimePolygon) {
    return !isTextEmpty(proximity.address)
      && validateDriveTimePolygonTime(proximity.travelTimeHours, proximity.travelTimeMinutes);
  }

  // parse radius individual
  if (proximity.type === ProximityType.DistanceRadius && proximity.applyTo === GroupRadiusEditLevel.IndividualLocation) {
    return !isTextEmpty(proximity.address)
      && validateValueHasOnlyNumericCharacters(proximity.proximityWithin, { isDecimal: true })
      && validateDecimalInputValue(proximity.proximityWithin);
  }

  // parse radius group
  if (proximity.type === ProximityType.DistanceRadius && proximity.applyTo === GroupRadiusEditLevel.Group) {
    return proximity.selectedGroupColumn !== null
      && proximity.selectedGroup !== null
      && validateValueHasOnlyNumericCharacters(proximity.proximityWithin, { isDecimal: true })
      && validateDecimalInputValue(proximity.proximityWithin);
  }

  return false;
};

export const createProximityRequestFromPropsWithDefaults = ({
  type, applyTo, fillColor,
  fillOpacity, borderColor,
  borderWidth, startingLocation,
  unit, distance, selectedGroup,
  selectedGroupColumn, travelTimeMinutes,
  travelTimeHours, borderOpacity,
}: ProximityRequestCreateObject): ProximityModel => {
  return createProximityRequest({
    type,
    applyTo,
    startingLocation,
    unit,
    distance,
    borderColor,
    borderWidth,
    borderOpacity,
    selectedGroupColumn,
    fillColor,
    fillOpacity,
    selectedGroup,
    travelTimeMinutes,
    travelTimeHours,
  });
};
