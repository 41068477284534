import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';

export const mapSettingsSettingsUnitSystemSelector = (state: AppState) => state.map.mapSettings.data.settings.unitSystem;
export const useMapSettingsSettingsUnitSystemSelector = () => useSelector(mapSettingsSettingsUnitSystemSelector);

const mapSettingsSettingsIsUserLocationShownOnAdminSelector = (state: AppState) => state.map.mapSettings.data.settings.isUserLocationShownOnAdmin;
export const useMapSettingsSettingsIsUserLocationShownSelector = () => useSelector(mapSettingsSettingsIsUserLocationShownOnAdminSelector);

const mapSettingsSettingsIsDisableActiveToolPanelSelector = (state: AppState) => state.map.mapSettings.data.settings.isDisableActiveToolPanel;
export const useMapSettingsSettingsIsDisableActiveToolPanelSelector = () => useSelector(mapSettingsSettingsIsDisableActiveToolPanelSelector);

const mapSettingsSettingsInitialMapCenterAndZoomSelector = (state: AppState) => state.map.mapSettings.data.settings.initialMapCenterAndZoom;
export const useMapSettingsSettingsInitialMapCenterAndZoomSelector = () => useSelector(mapSettingsSettingsInitialMapCenterAndZoomSelector);

const mapSettingsSettingsInitialBoundsSelector = (state: AppState) => state.map.mapSettings.data.settings.initialBounds;
export const useMapSettingsSettingsInitialBoundsSelector = () => useSelector(mapSettingsSettingsInitialBoundsSelector);

export const mapSettingsSettingsClickableIconsSelector = (state: AppState) => !!state.map.mapSettings.data.settings.clickableIcons;
export const useMapSettingsSettingsClickableIconsSelector = () => useSelector(mapSettingsSettingsClickableIconsSelector);
