import {
  isCustomMarkerStyle, isLabelStyle,
  isStandardMarkerStyle,
  MarkerAnchorPosition, type MarkerEntityStyle,
} from '~/_shared/types/marker.types';
import { calculateMarkerLabelBodyHeight } from '~/map/map/markers/manager/mapMarkerManager';
import {
  type LabelVisualizerArrowProps,
  type LabelVisualizerBodyProps,
  type LabelVisualizerBorderProps,
} from '../../components/labelVisualizer/labelVisualizer.types';

const DEFAULT_RADIUS_ENCIRCLING_MARKER = 50;
const RADIUS_ENCIRCLING_STANDARD_MARKER_MULTIPLAYER = 1.45;
const RADIUS_ENCIRCLING_CUSTOM_MARKER_MULTIPLAYER = 1.2;

export type MarkerEntityOffsets = {
  leftOffset: number;
  topOffset: number;
  centerToAnchorOffset: number;
  radius: number;
};

type LabelData = Readonly<{
  arrowProps: LabelVisualizerArrowProps;
  bodyProps: LabelVisualizerBodyProps;
  borderProps: LabelVisualizerBorderProps;
}>;

export const calculateOffsetsForMarkerEntity = (
  markerEntityStyle: MarkerEntityStyle, labelAboveData?: LabelData,
) => {
  const defaultReturnValue = {
    leftOffset: 0,
    topOffset: 0,
    centerToAnchorOffset: 0,
    radius: DEFAULT_RADIUS_ENCIRCLING_MARKER,
  };

  let labelAboveHeight = 0;
  if (labelAboveData) {
    const aboveLabelArrowHeight = labelAboveData.arrowProps.dimensions.height ?? 0;
    labelAboveHeight = calculateMarkerLabelBodyHeight(labelAboveData) + aboveLabelArrowHeight * 2;
  }

  if (isLabelStyle(markerEntityStyle)) {
    const arrowHeight = markerEntityStyle.arrowProps.dimensions.height ?? 0;
    const labelHeight = calculateMarkerLabelBodyHeight(markerEntityStyle) + arrowHeight;

    return {
      ...defaultReturnValue,
      radius: Math.max(labelHeight, 0),
      leftOffset: markerEntityStyle.offsetProps.type === 'custom' ? markerEntityStyle.offsetProps.x : 0,
      topOffset: labelHeight + labelAboveHeight
        - (markerEntityStyle.offsetProps.type === 'custom' ? markerEntityStyle.offsetProps.y + 7 : 0),
    };
  }

  else if (isCustomMarkerStyle(markerEntityStyle)) {
    const isMarkerAnchorBottom = markerEntityStyle.marker.anchor === MarkerAnchorPosition.BOTTOM_CENTER;
    return {
      ...defaultReturnValue,
      topOffset: (isMarkerAnchorBottom ? markerEntityStyle.size : markerEntityStyle.size / 2) + labelAboveHeight,
      centerToAnchorOffset: (isMarkerAnchorBottom ? markerEntityStyle.size / 2 : 0) + labelAboveHeight,
      radius: markerEntityStyle.size * RADIUS_ENCIRCLING_CUSTOM_MARKER_MULTIPLAYER,
    };
  }

  else if (isStandardMarkerStyle(markerEntityStyle)) {
    const standardMarkerSettings = markerEntityStyle.marker;
    const spriteSettings = standardMarkerSettings.spritesheetSettings;
    const markerSize = markerEntityStyle.size;
    const ratio = markerSize / spriteSettings.dimensions.height;
    const anchorXOffset = spriteSettings.anchorOffsets.xOffset;
    const anchorYOffset = spriteSettings.anchorOffsets.yOffset;
    const graphicsTopOffset = standardMarkerSettings.offsets.topOffset;
    const graphicsBottomOffset = standardMarkerSettings.offsets.bottomOffset;
    const graphicLeftTextureStart = standardMarkerSettings.counter.left;
    const markerHeightOnTexture = spriteSettings.dimensions.height - graphicsTopOffset - graphicsBottomOffset;
    const anchorYAndMarkerTextureBottomDiff = graphicsTopOffset + markerHeightOnTexture - anchorYOffset;
    const isMarkerAnchoredAtBottom = !(anchorYAndMarkerTextureBottomDiff > markerHeightOnTexture / 3);
    const graphicsWidth = standardMarkerSettings.counter.width;
    const counterWidth = graphicsWidth * ratio;
    const scaledTextureHeight = markerSize - (graphicsTopOffset + graphicsBottomOffset) * ratio;
    const counterCenter = ratio * (graphicLeftTextureStart - anchorXOffset);
    const radius = Math.max(scaledTextureHeight, counterWidth, counterCenter);
    const finalTopToAnchorOffset = scaledTextureHeight / (isMarkerAnchoredAtBottom ? 1 : 2);

    return {
      leftOffset: counterCenter,
      topOffset: finalTopToAnchorOffset + labelAboveHeight,
      centerToAnchorOffset: (isMarkerAnchoredAtBottom ? scaledTextureHeight / 2 : 0) + labelAboveHeight,
      radius: radius * RADIUS_ENCIRCLING_STANDARD_MARKER_MULTIPLAYER,
    };
  }

  return defaultReturnValue;
};
