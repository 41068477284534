import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  DropdownItemComponent, type DropdownItemComponentProps,
} from '../../../dropdown';

export type AutocompleteItemProps = DropdownItemComponentProps & {
  isSelected?: boolean;
};

export const AutocompleteItem = (props: AutocompleteItemProps) => {
  return (
    <DropdownItemComponent
      {...props}
      icon={props.icon ?? props.isSelected ? faCheck : undefined}
    />
  );
};
