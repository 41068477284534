import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { type TranslationFnc } from '../../../_shared/utils/hooks';
import { BoundaryEditManualMethod } from '../boundaryEditModal/boundaryEditModal.helpers';

export enum BoundaryEditSubmitModalAction {
  AddToExistingTerritory = 'AddToExistingTerritory',
  RemoveBoundariesFromTerritory = 'RemoveBoundariesFromTerritory',
  ReassignTerritory = 'ReassignTerritory',
}

export const getBoundaryEditMethodParam = (t: TranslationFnc, method: BoundaryEditManualMethod) => ({
  method: method === BoundaryEditManualMethod.Selection ? t('selected') : t('drawn'),
});

export const getBoundaryEditSubmitModalActionOptions = (
  t: TranslationFnc, method: BoundaryEditManualMethod, includeAssignToAndRemoveOptions: boolean, includeReassign: boolean
): ReadonlyArray<DropdownOption<BoundaryEditSubmitModalAction>> => {
  const methodParam = getBoundaryEditMethodParam(t, method);

  return ([
    ...includeAssignToAndRemoveOptions ? [{
      name: t('Add {{method}} areas to an existing territory', methodParam),
      value: BoundaryEditSubmitModalAction.AddToExistingTerritory,
    }, {
      name: t('Remove {{method}} areas from an existing territory', methodParam),
      value: BoundaryEditSubmitModalAction.RemoveBoundariesFromTerritory,
    }] : [],
    ...includeReassign ? [{
      name: t('Reassign {{method}} area from territory to a different territory', methodParam),
      value: BoundaryEditSubmitModalAction.ReassignTerritory,
    }] : [],
  ]);
};
