import {
  createContext, type PropsWithChildren,
} from 'react';
import ReactDataSheet from 'react-datasheet';
import type {
  GridElement,
  SpreadsheetHeaderColumn, SpreadsheetRow, SpreadsheetSortType,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import { useSpreadsheetColumnWidth } from '~/_shared/components/spreadsheet/useSpreadsheetColumnsWidth';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import type {
  CombinedRowId, SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { noop } from '~/_shared/utils/function.helpers';
import CellsChangedHandler = ReactDataSheet.CellsChangedHandler;

type SpreadsheetContextProviderProps = Readonly<{
  columnWidthsLocalStorageKey?: string;
  columns: SpreadsheetHeaderColumn[];
  data: SpreadsheetRow[];
  getSpreadsheetCellDisabledMessage?: (spreadsheetRowId: SpreadsheetRowId, columnId?: ColumnId) => string | null;
  getSpreadsheetCellForbiddenMessage?: (row: number, col: number) => string | null;
  highlightedRows?: ReadonlySet<CombinedRowId>;
  onCellsChanged: CellsChangedHandler<GridElement, string>;
  onCloseSpreadsheetCellForbiddenMessage: (row: number, col: number) => void;
  onHoveredRowChange?: (row: SpreadsheetRow | null) => void;
  onRowClick?: (row: SpreadsheetRow) => void;
  onSelectedRowsChange: (newSelections: Record<CombinedRowId, true>) => void;
  onSortChange?: (sortChange: { columnId: string; newSort: SpreadsheetSortType | null }) => void;
  selectableRows: boolean;
  selectedRows: Record<CombinedRowId, true>;
  useResizePlaceholder?: boolean;
}>;

type SpreadsheetContextType = SpreadsheetContextProviderProps &
  Readonly<ReturnType<typeof useSpreadsheetColumnWidth>>;

const defaultSpreadsheetContextValue: SpreadsheetContextType = {
  columns: [],
  data: [],
  getColumnWidth: () => '',
  highlightedRows: new Set([]),
  isColumnResizing: false,
  onCellsChanged: noop,
  onCloseSpreadsheetCellForbiddenMessage: noop,
  onHoveredRowChange: noop,
  onResizerPointerDown: noop,
  onRowClick: noop,
  onSelectedRowsChange: noop,
  onSortChange: noop,
  selectableRows: false,
  selectedRows: {},
  useResizePlaceholder: false,
};

export const SpreadsheetContext = createContext<SpreadsheetContextType>(defaultSpreadsheetContextValue);

export const SpreadsheetContextProvider = (props: PropsWithChildren<SpreadsheetContextProviderProps>) => {
  const {
    children,
    columnWidthsLocalStorageKey,
    columns,
    data,
    getSpreadsheetCellDisabledMessage,
    getSpreadsheetCellForbiddenMessage,
    highlightedRows,
    onCellsChanged,
    onCloseSpreadsheetCellForbiddenMessage,
    onHoveredRowChange,
    onRowClick,
    onSelectedRowsChange,
    onSortChange,
    selectableRows,
    selectedRows,
    useResizePlaceholder,
  } = props;
  const { getColumnWidth, onResizerPointerDown, isColumnResizing } = useSpreadsheetColumnWidth({
    columnsCount: columns.length,
    localStorageKey: columnWidthsLocalStorageKey,
  });
  return (
    <SpreadsheetContext.Provider
      value={{
        columns,
        data,
        getColumnWidth,
        getSpreadsheetCellDisabledMessage,
        getSpreadsheetCellForbiddenMessage,
        highlightedRows,
        isColumnResizing,
        onCellsChanged,
        onCloseSpreadsheetCellForbiddenMessage,
        onHoveredRowChange,
        onResizerPointerDown,
        onRowClick,
        onSelectedRowsChange,
        onSortChange,
        selectableRows,
        selectedRows,
        useResizePlaceholder,
      }}
    >
      {children}
    </SpreadsheetContext.Provider>
  );
};
