import {
  useCallback, useMemo,
} from 'react';
import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { sortAlphabetically } from '../../_shared/utils/sort';
import {
  type BoundaryGroup, type BoundaryGroupBase,
} from '../../boundary/boundary.types';
import { type AppState } from '../app.store';
import type { BoundaryGroupId } from '../boundaries/boundaryIdentifier.type';

export const boundaryGroupsSelector = (state: AppState) => state.boundaries.groups.groups;
export const useBoundaryGroupsSelector = () => useSelector(boundaryGroupsSelector);

const listedBoundaryGroupsSelectorMemoized = memoizeOne((groups: ReadonlyArray<BoundaryGroup>) => groups.filter(g => g.visible));
export const listedBoundaryGroupsSelector = (state: AppState) => listedBoundaryGroupsSelectorMemoized(boundaryGroupsSelector(state));
export const useListedBoundaryGroupsSelector = () => useSelector(listedBoundaryGroupsSelector);

const boundaryGroupsIsLoadingSelector = (state: AppState) => state.boundaries.groups.isLoading;
export const useBoundaryGroupsIsLoadingSelector = () => useSelector(boundaryGroupsIsLoadingSelector);

const boundaryGroupsIsListingLoadingSelector = (state: AppState) => state.boundaries.groups.isListingLoading;
export const useBoundaryGroupsIsListingLoadingSelector = () => useSelector(boundaryGroupsIsListingLoadingSelector);

export const useGlobalBoundaryGroups = () => {
  const boundaryGroups = useListedBoundaryGroupsSelector();

  return useMemo(() => {
    return boundaryGroups.filter(group => group.clientId === null);
  }, [boundaryGroups]);
};

export const isCustomGroup = (group?: BoundaryGroupBase) => {
  return !!group && group.clientId !== null;
};

export const useIsBoundaryGroupCustom = () => {
  const { getBoundaryGroupById } = useGetBoundaryGroup();

  const isBoundaryGroupCustom = useCallback((boundaryGroupId: number) => {
    const boundaryGroup = getBoundaryGroupById(boundaryGroupId);

    if (!boundaryGroup) {
      return false;
    }

    return isCustomGroup(boundaryGroup);
  }, [getBoundaryGroupById]);

  return {
    isBoundaryGroupCustom,
  };
};

export const useIsBoundaryAiGenerated = (boundaryGroupId: number) => {
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  return (getBoundaryGroupById(boundaryGroupId)?.wms.territoriesCount ?? 0) > 0;
};

export const useCustomBoundaryGroupsSelector = () => {
  const boundaryGroups = useListedBoundaryGroupsSelector();

  return useMemo(() => {
    return boundaryGroups
      .filter(group => isCustomGroup(group))
      .sort((a, b) => sortAlphabetically(a.name, b.name));
  }, [boundaryGroups]);
};

// #region Get Boundary Group by ID

const findBoundaryGroup = (boundaryGroups: readonly BoundaryGroup[], boundaryGroupId?: number | null) =>
  boundaryGroups.find(boundaryGroup => boundaryGroup.id === boundaryGroupId);

export const findEditBoundaryGroup = (boundaryGroups: readonly BoundaryGroup[], boundaryGroupId?: number | null) => {
  const original = findBoundaryGroup(boundaryGroups, boundaryGroupId);
  const editFallback = findBoundaryGroup(boundaryGroups, original?.editBoundaryGroupFallback);

  return editFallback ?? original;
};

export const useGetBoundaryGroup = () => {
  const boundaryGroups = useBoundaryGroupsSelector();

  const getBoundaryGroupById = useCallback((boundaryGroupId?: BoundaryGroupId | null) =>
    findBoundaryGroup(boundaryGroups, boundaryGroupId),
  [boundaryGroups]);

  return {
    getBoundaryGroupById,
  };
};

// #endregion
