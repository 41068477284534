import type { GeoLocation } from './geolocation';
import { getLatLngFromAddress3rdPartyApi } from './geolocation.helpers';

const latLngLookupCache: { [address: string]: Promise<ReadonlyArray<GeoLocation>> | undefined } = {};

export const getLatLngFromAddress = (address: string): Promise<ReadonlyArray<GeoLocation>> => {
  if (latLngLookupCache[address]) {
    return latLngLookupCache[address];
  }

  const promiseToGetLatLngFromAddress = getLatLngFromAddress3rdPartyApi(address);
  latLngLookupCache[address] = promiseToGetLatLngFromAddress;

  return promiseToGetLatLngFromAddress;
};
