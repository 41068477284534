import { type MapSettingsLoadingAction } from './mapSettingsLoading.action';
import { MAP_SETTINGS_LOADING_SET } from './mapSettingsLoading.actionTypes';
import { type MapSettingsLoadingState } from './mapSettingsLoading.state';

const initialState: MapSettingsLoadingState = false;

export const mapSettingsLoadingReducer = (state = initialState, action: MapSettingsLoadingAction): MapSettingsLoadingState => {
  switch (action.type) {
    case MAP_SETTINGS_LOADING_SET: {
      return action.payload.isLoading;
    }

    default: {
      return state;
    }
  }
};
