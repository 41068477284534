import { css } from '@emotion/react';
import {
  type ChangeEvent, type FC, memo, type MouseEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { stopPropagationAnd } from '~/_shared/utils/dom/dom.helpers';
import { noop } from '~/_shared/utils/function.helpers';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { throttle } from '~/_shared/utils/throttle/throttle';
import { useFocusAttachedTextArea } from '~/map/map/mapObjects/useFocusAttachedTextArea.hook';
import { useMap } from '../../mapContext';
import { GoogleMapsOverlayViewContainer } from '../../mapOverlays/_shared/googleMapsOverlayViewContainer';
import {
  type MapTextInputInstance, type MapTextInputVisualsConfig,
} from './mapTextInputModel';

type MapTextInputProps = Readonly<{
  instance: MapTextInputInstance;
  visuals: MapTextInputVisualsConfig;
  // focus doesn't work on Safari due to security policy
  autoFocusOnInit?: boolean;
  disabled?: boolean;
  selectionDisabled?: boolean;
  etag?: string;

  onClick?: (event: MouseEvent) => void;
  onRightClick?: (event: MouseEvent) => void;
  onMouseDown?: () => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  onTextChange?: (newText: string) => void;
}>;

type InputStyleArguments = Readonly<{
  width: number;
  left: number;
  fontSize: number;
  padding: number;
  color: string;
  background?: string;
  cursor?: string;
  marginLeft?: number;
  disabled: boolean;
  borderRadius?: number;
  selectionDisabled: boolean;
}>;

const inputStyle = (
  {
    width, left, fontSize, padding, color, background, cursor, marginLeft, disabled, selectionDisabled, borderRadius,
  }: InputStyleArguments,
) => css({
  left,
  // added 2px indent to prevent cut off is text starts with 'j':
  // https://stackoverflow.com/questions/38149704/why-does-the-text-inside-an-input-tag-get-cut-off-even-if-theres-already-a-pa
  width: width + 2,
  textIndent: 2,
  color,
  cursor,
  position: 'absolute',
  minWidth: 5,
  background,
  top: 0,
  border: 'none',
  lineHeight: 1,
  outline: 'none',
  overflow: 'hidden',
  fontSize: `${fontSize}px`,
  resize: 'none',
  padding,
  boxSizing: 'content-box',
  pointerEvents: disabled ? 'none' : undefined,
  userSelect: selectionDisabled ? 'none' : undefined,
  borderRadius,
  marginLeft,
  transform: 'translateY(-50%)',

  '::selection': selectionDisabled ? {
    backgroundColor: 'transparent',
  } : undefined,
});

const inputSizeTester = ({ fontSize }: Readonly<{ fontSize: number }>) => css({
  fontSize: `${fontSize}px`,
  opacity: 0,
  visibility: 'hidden',
  lineHeight: 1,
  position: 'absolute',
  whiteSpace: 'nowrap',
  background: 'blue',
  top: 0,
  overflow: 'hidden',
  pointerEvents: 'none',
});

const MapTextInputComponent: FC<MapTextInputProps> = ({
  visuals,
  autoFocusOnInit,
  instance,
  onClick,
  onRightClick,
  onTextChange,
  onMouseDown,
  onMouseOver,
  onMouseOut,
  ...props
}) => {
  const map = useMap();
  const [textWidth, setTextWidth] = useState(0);
  const [text, setText] = useState(instance.text);

  const inputSizeTesterRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { focusTextArea, markTextAreaAsAttached } = useFocusAttachedTextArea(inputRef);

  const onTextChangeRef = useRef(onTextChange);
  onTextChangeRef.current = onTextChange;

  const inputMargin = 0;

  const onTextChangeThrottled = useMemo(() => throttle((newValue: string) => {
    onTextChangeRef.current?.(newValue);
  }, 200, { leading: false, trailing: true }),
  []);

  const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);

    onTextChangeThrottled(newValue);
  }, [onTextChangeThrottled]);

  const prevEtag = usePrevious(props.etag);

  useEffect(() => {
    if (props.etag !== prevEtag) {
      onTextChangeThrottled.cancel();
      setText(instance.text);
    }
  }, [instance, onTextChangeThrottled, prevEtag, props.etag]);

  useEffect(() => {
    if (!inputSizeTesterRef.current) {
      return;
    }

    const ro = new ResizeObserver(entries => {
      for (const entry of entries) {
        setTextWidth(entry.contentRect.width);
      }
    });

    ro.observe(inputSizeTesterRef.current);

    return () => {
      ro.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!inputSizeTesterRef.current) {
      return;
    }

    // replace & to prevent special chars from being rendered as HTML
    // replace ' ' to make sure the input is being resized even if space is used at the end of the text
    inputSizeTesterRef.current.innerHTML = text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/ /g, '&nbsp;');
  }, [text]);

  useEffect(() => {
    if (autoFocusOnInit) {
      focusTextArea();
    }

  }, [autoFocusOnInit, focusTextArea]);

  return (
    <GoogleMapsOverlayViewContainer
      map={map}
      onAdd={markTextAreaAsAttached}
      position={instance.startPoint}
      width={0}
    >
      <div
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseOver}
        onMouseLeave={onMouseOut}
      >
        <div
          ref={inputSizeTesterRef}
          css={inputSizeTester({
            fontSize: instance.fontSize,
          })}
        />

        <input
          ref={inputRef}
          css={inputStyle({
            width: textWidth,
            color: visuals.fontColor,
            left: inputMargin,
            fontSize: instance.fontSize,
            padding: visuals.padding,
            background: visuals.background,
            cursor: onTextChange ? undefined : 'pointer',
            marginLeft: visuals.offsetX,
            disabled: props.disabled ?? false,
            selectionDisabled: props.selectionDisabled ?? false,
            borderRadius: visuals.borderRadius,
          })}
          readOnly={!onTextChange}
          onChange={handleTextChange}
          value={text}
          onContextMenu={onRightClick}
          onClick={onClick}
          spellCheck={false}
          onMouseDown={stopPropagationAnd(noop)}
        />
      </div>
    </GoogleMapsOverlayViewContainer>
  );
};

const pureComponent = memo(MapTextInputComponent);
export { pureComponent as MapTextInputComponent };
