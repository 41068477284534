import { type CancelToken } from 'axios';
import { FetchableResourceStatus } from '~/_shared/types/fetchableResource/fetchableResource';
import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import {
  type PerColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import {
  type FetchableSpreadsheetCell, type FetchableSpreadsheetRow, type SpreadsheetCellData,
} from '~/store/spreadsheetCellData/spreadsheetCellData.state';
import {
  getSpreadsheetTableData, MAXIMUM_RAW_DATA_MARKERS,
} from './spreadsheet.repository';

type FetchRowDataProps = {
  cancelToken?: CancelToken;
  clientId: number;
  columnIds?: SpreadsheetColumnId[];
  mapId?: number;
  spreadsheetRowIds: SpreadsheetRowId[];
};

export const fetchRowData = async (props: FetchRowDataProps): Promise<SpreadsheetCellData> => {
  const { clientId, spreadsheetRowIds, cancelToken, mapId, columnIds } = props;
  const perSpreadsheetRequestedRows: PerSpreadsheet<Set<CombinedRowId>> = newPerSpreadsheetMap();

  spreadsheetRowIds.forEach(({ spreadsheetId, rowId }) => {
    if (!perSpreadsheetRequestedRows[spreadsheetId]) {
      perSpreadsheetRequestedRows[spreadsheetId] = new Set();
    }
    perSpreadsheetRequestedRows[spreadsheetId]?.add(rowId);
  });

  const requestedRowsBySpreadsheetArray: [number, Set<CombinedRowId>][]
    = Object.entries(perSpreadsheetRequestedRows).map(([spreadsheetId, rowIds]) => ([+spreadsheetId, rowIds || new Set()]));

  const spreadsheetTableDataPromises = requestedRowsBySpreadsheetArray.map(([spreadsheetId, rowIds]) => (
    getSpreadsheetTableData(clientId, +spreadsheetId, {
      per_page: MAXIMUM_RAW_DATA_MARKERS,
      filter: {
        rows: Array.from(rowIds || []),
      },
      map_id: mapId,
      ...(columnIds ? {
        only_columns: columnIds.filter(columnId => columnId.spreadsheetId === +spreadsheetId)
          .map(spreadsheetColumnId => spreadsheetColumnId.columnId),
      } : {}),
    }, {
      cancelToken,
    })
  ));

  const results = await Promise.all(spreadsheetTableDataPromises);

  return requestedRowsBySpreadsheetArray.reduce<SpreadsheetCellData>((acc, [spreadsheetId, requestedRows], index) => {
    const perRowFetchableSpreadsheetRows = new Map<CombinedRowId, FetchableSpreadsheetRow>();

    // process rows which were fetched from backend
    const resultsForSpreadsheet = results[index]?.data;
    if (resultsForSpreadsheet) {
      resultsForSpreadsheet.list.forEach(({ columnData, rowId, version, isBad }) => {
        const columnsData = Object.entries(columnData).reduce<PerColumn<FetchableSpreadsheetCell>>((columnsAcc, [columnId, columnValue]) => {
          const newCell = {
            status: FetchableResourceStatus.Loaded,
            value: columnValue,
          };
          columnsAcc[columnId] = newCell;
          return columnsAcc;
        }, {});
        // if all the requested columns were not received should be marked as not found
        if (columnIds) {
          columnIds.filter(columnId => columnId.spreadsheetId === +spreadsheetId)
            .forEach(columnId => {
              if (columnsData[columnId.columnId] === undefined) {
                columnsData[columnId.columnId] = {
                  status: FetchableResourceStatus.NotFound,
                };
              }
            });
        }
        perRowFetchableSpreadsheetRows.set(rowId, {
          status: FetchableResourceStatus.Loaded,
          value: {
            baseMapId: columnsData[BASE_MAPS_COLUMN_ID]?.value ? parseInt(columnsData[BASE_MAPS_COLUMN_ID]?.value, 10) || null : null,
            columnsData,
            version,
            rowId,
            isBad,
            ...(columnIds ? {} : { allColumnsLoaded: true }),
          },
        });
      });
    }

    // all the requested rows not received should be marked as not found
    requestedRows.forEach(requestedRowId => {
      if (resultsForSpreadsheet
        && resultsForSpreadsheet.pagination.currentPage === resultsForSpreadsheet.pagination.lastPage
        && !perRowFetchableSpreadsheetRows.has(requestedRowId)
      ) {
        perRowFetchableSpreadsheetRows.set(requestedRowId, {
          status: FetchableResourceStatus.NotFound,
        });
      }
    });

    acc[spreadsheetId] = perRowFetchableSpreadsheetRows;

    return acc;
  }, newPerSpreadsheetMap());
};
