import {
  type FC, type ReactNode, useEffect, useRef, useState,
} from 'react';
import { useMapContext } from '../../mapContext';
import { type MarkerTemplate } from '../../markers/manager/mapMarkerTemplates';

type MapMarkerTemplateLoaderProps = Readonly<{
  template: MarkerTemplate;
  children: ReactNode;
}>;

export const MapMarkerTemplateLoaderContainer: FC<MapMarkerTemplateLoaderProps> = (props) => {
  const [_, setForceRender] = useState(false);
  const isTemplateLoaded = useRef(false);
  const prevTemplateName = useRef(props.template.name);

  const { markerTemplateManager } = useMapContext();

  if (prevTemplateName.current !== props.template.name) {
    isTemplateLoaded.current = false;
    prevTemplateName.current = props.template.name;
  }

  useEffect(() => {
    markerTemplateManager.registerTemplate(props.template);

    isTemplateLoaded.current = true;
    setForceRender(flag => !flag);
  }, [props.template, markerTemplateManager]);

  if (!isTemplateLoaded.current) {
    return null;
  }

  return (
    <>
      {props.children}
    </>
  );
};
