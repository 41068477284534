import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { ModalType } from '~/modal/modalType.enum';
import {
  closeAllModals, openModal,
} from '~/store/modal/modal.actionCreators';

export const useMigrationProgressObserver = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.mapMigration.migrationStatus);

  useEffect(() => {
    switch (status) {
      case 'IN_PROGRESS':
        dispatch(openModal(ModalType.MigrationInProgress, {}));
        break;
      case 'NONE':
        dispatch(closeAllModals());
        break;
      case 'FAILED':
      case 'SUCCESS':
        dispatch(closeAllModals());
        dispatch(openModal(ModalType.MigrationFinished, {}));
        break;
      default:
        throw new Error(`Unknown migration status: '${status}'.`);
    }
  }, [dispatch, status]);
};
