import {
  type FC, useCallback, useState,
} from 'react';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { createUuid } from '~/_shared/utils/createUuid';
import {
  type MapShapeInstance, pathToMapShapeOutlines,
} from '../map/mapObjects/mapShape/mapShapeModel';
import { useBoundarySelectionLassoInstanceZIndex } from '../zIndexes/useBoundaryDrawPolygonZIndex.hook';
import { LassoDrawComponent } from './lassoDraw.component';
import { LassoPolygonComponent } from './lassoPolygon.component';

type LassoSelectProps = Readonly<{
  onAddShape: (path: PolygonPath) => void;
  onDrawingStop: () => void;
}>;

export const LassoSelectContainer: FC<LassoSelectProps> = (props) => {
  const { onAddShape, onDrawingStop } = props;

  const [shape, setShape] = useState<MapShapeInstance | null>(null);

  const zIndex = useBoundarySelectionLassoInstanceZIndex();

  const handleAddShape = useCallback((path: PolygonPath) => {
    setShape({
      id: createUuid(),
      outlines: pathToMapShapeOutlines(path),
    });

    onAddShape(path);
  }, [onAddShape]);

  if (shape) {
    return (
      <LassoPolygonComponent
        zIndex={zIndex}
        shape={shape}
      />
    );
  }

  return (
    <LassoDrawComponent
      zIndex={zIndex}
      onDrawingFinished={handleAddShape}
      onDrawingStop={onDrawingStop}
    />
  );
};
