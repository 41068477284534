import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { createUuid } from '~/_shared/utils/createUuid';
import { useIsMapInteractionActive } from '~/_shared/utils/hooks/useIsMapInteractionActive';
import { useMap } from '~/map/map/mapContext';
import { useProjectionOverlay } from '~/map/map/useProjectionOverlay';
import { hideMarkerContextMenu } from '~/store/frontendState/markersContextMenu/markersContextMenu.actionCreators';
import { useMarkersContextMenuActiveSelector } from '~/store/frontendState/markersContextMenu/markersContextMenu.selectors';
import { moveMarkerLabelsOpenModal } from '~/store/frontendState/moveMarkerLabels/moveMarkerLabels.actionCreators';
import { moveMarkersOpenModal } from '~/store/frontendState/moveMarkers/moveMarkers.actionCreators';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { useLabelsInsideMarkersActiveSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { MapMarkerContextMenuComponent } from './mapMarkerContextMenu.component';

export const MapMarkerContextMenuContainer: FC = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const isPresentationalView = useIsMapPresentationalSelector();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const labelsInsideMarkersActive = useLabelsInsideMarkersActiveSelector();
  const markerContextMenu = useMarkersContextMenuActiveSelector();
  const mapInteractionActive = useIsMapInteractionActive();
  const { fromLatLngToContainerPixel } = useProjectionOverlay(map);

  const actionsAllowed = !mapInteractionActive && !isPresentationalView;

  const onMoveMarkers = useMemo(() => actionsAllowed && !isLayeredMapConnected ?
    () => dispatch(moveMarkersOpenModal())
    : undefined
  , [actionsAllowed, dispatch, isLayeredMapConnected]);

  const onMoveMarkerLabels = useMemo(() => actionsAllowed && labelsInsideMarkersActive ?
    () => dispatch(moveMarkerLabelsOpenModal())
    : undefined
  , [actionsAllowed, dispatch, labelsInsideMarkersActive]);

  const isMenuEmpty = !onMoveMarkers && !onMoveMarkerLabels;

  const menuPosition = useMemo(() => {
    const positionKey = createUuid();

    if (!markerContextMenu) {
      return null;
    }

    const latToPx = fromLatLngToContainerPixel(markerContextMenu.position.latLng);

    return latToPx ? {
      key: positionKey,
      x: latToPx.x + markerContextMenu.position.offset.x,
      y: latToPx.y + markerContextMenu.position.offset.y,
    } : null;
  }, [fromLatLngToContainerPixel, markerContextMenu]);

  const onHide = useCallback(() => dispatch(hideMarkerContextMenu()), [dispatch]);

  if (isMenuEmpty || !markerContextMenu || !menuPosition) {
    return null;
  }

  return (
    <MapMarkerContextMenuComponent
      key={menuPosition.key} // reload component when menu position changes
      map={map}
      menuPosition={menuPosition}
      onHide={onHide}
      onMoveMarkers={onMoveMarkers}
      onMoveMarkerLabels={onMoveMarkerLabels}
    />
  );
};
