import {
  type FC, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { drawingEditClearSelectedDrawing } from '~/store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.actionCreators';
import { DrawingTool } from '../../../drawingTool/drawingTool.enums';
import { useDrawingToolVisibility } from '../../../drawingTool/hooks/useDrawingToolVisibility';
import { useSelectedDrawingItemWithOptions } from '../../../drawingTool/hooks/useSelectedDrawingItemWithOptions';
import {
  useDrawingEditSelectedDrawingEtag,
  useIsDrawingEditActiveSelector,
} from '../../../store/frontendState/mapTools/drawing/drawingEdit/drawingEdit.selectors';
import { useDrawingItemsSelector } from '../../../store/mapSettings/drawing/mapSettingsDrawing.selectors';
import { useMap } from '../mapContext';
import { DrawingToolArrowInstanceContainer } from './arrow/drawingToolArrowInstance.container';
import { DrawingToolArrowSelectedInstanceContainer } from './arrow/drawingToolArrowSelectedInstance.container';
import { DrawingToolCallout } from './callout/drawingToolCalloutInstance';
import { DrawingToolCircleInstanceContainer } from './circle/drawingToolCircleInstance.container';
import { DrawingToolCircleSelectedInstanceContainer } from './circle/drawingToolCircleSelectedInstance.container';
import { DrawingToolCreateNewInstanceContainer } from './drawingToolCreateNewInstance.container';
import { DrawingToolManager } from './drawingToolManager';
import { DrawingToolIconMarkerInstanceContainer } from './iconMarker/drawingToolIconMarkerInstance.container';
import { DrawingToolIconMarkerSelectedInstanceContainer } from './iconMarker/drawingToolIconMarkerSelectedInstance.container';
import { DrawingToolImageInstanceContainer } from './image/drawingToolImageInstance.container';
import { DrawingToolImageSelectedInstanceContainer } from './image/drawingToolImageSelectedInstance.container';
import { DrawingToolLabel } from './label/drawingToolLabelInstance';
import { DrawingToolNumberInstanceContainer } from './number/drawingToolNumberInstance.container';
import { DrawingToolNumberSelectedInstanceContainer } from './number/drawingToolNumberSelectedInstance.container';
import { DrawingToolPolygonInstanceContainer } from './polygon/drawingToolPolygonInstance.container';
import { DrawingToolPolygonSelectedInstanceContainer } from './polygon/drawingToolPolygonSelectedInstance.container';
import { DrawingToolPolylineInstanceContainer } from './polyline/drawingToolPolylineInstance.container';
import { DrawingToolPolylineSelectedInstanceContainer } from './polyline/drawingToolPolylineSelectedInstance.container';
import { DrawingToolRectangleInstanceContainer } from './rectangle/drawingToolRectangleInstance.container';
import { DrawingToolRectangleSelectedInstanceContainer } from './rectangle/drawingToolRectangleSelectedInstance.container';
import { DrawingToolTextInstance } from './text/drawingToolTextInstance';

export const DrawingToolOverlay: FC = () => {
  const dispatch = useDispatch();
  const selectedDrawingWithOptions = useSelectedDrawingItemWithOptions();
  const isDrawingEditActive = useIsDrawingEditActiveSelector();
  const selectedDrawingEtag = useDrawingEditSelectedDrawingEtag();
  const drawingItems = useDrawingItemsSelector();
  const map = useMap();
  const { checkDrawingToolVisibility } = useDrawingToolVisibility();

  const drawingToolManager = useMemo(() => new DrawingToolManager(map), [map]);

  useEffect(() => {
    const mapClickCleanUp = isDrawingEditActive ? drawingToolManager.addMapClickListener(() => {
      dispatch(drawingEditClearSelectedDrawing());
    }) : null;

    return () => {
      mapClickCleanUp?.remove();
    };
  }, [drawingToolManager, dispatch, isDrawingEditActive]);

  return (
    <>
      {drawingItems.map(drawingItem => {
        if (selectedDrawingWithOptions?.item.value.id === drawingItem.value.id) {
          switch (selectedDrawingWithOptions.item.type) {
            case DrawingTool.Arrow:
              return (
                <DrawingToolArrowSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Callout:
              return (
                <DrawingToolCallout
                  // we need to re-use the same input for focus to work on safari
                  key={selectedDrawingWithOptions?.item.value.id}
                  instance={selectedDrawingWithOptions.item.value}
                  etag={selectedDrawingEtag}
                  isSelected
                />
              );

            case DrawingTool.Circle:
              return (
                <DrawingToolCircleSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.IconMarker:
              return (
                <DrawingToolIconMarkerSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Image:
              return (
                <DrawingToolImageSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Label:
              return (
                <DrawingToolLabel
                  // we need to re-use the same input for focus to work on safari
                  key={selectedDrawingWithOptions?.item.value.id}
                  instance={selectedDrawingWithOptions.item.value}
                  etag={selectedDrawingEtag}
                  isSelected
                />
              );

            case DrawingTool.Number:
              return (
                <DrawingToolNumberSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Polygon:
              return (
                <DrawingToolPolygonSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Polyline:
              return (
                <DrawingToolPolylineSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Rectangle:
              return (
                <DrawingToolRectangleSelectedInstanceContainer
                  key={selectedDrawingEtag}
                  instance={selectedDrawingWithOptions.item.value}
                />
              );

            case DrawingTool.Text:
              return (
                <DrawingToolTextInstance
                  // we need to re-use the same input for focus to work on safari
                  key={selectedDrawingWithOptions?.item.value.id}
                  instance={selectedDrawingWithOptions.item.value}
                  etag={selectedDrawingEtag}
                  isSelected
                />
              );

            default:
              return null;
          }
        }

        // only hide regular drawing tool instances. If user manages to select the item (for instance when creating
        // a new callout) then the selected instance should be displayed
        if (!checkDrawingToolVisibility(drawingItem.type)) {
          return null;
        }

        switch (drawingItem.type) {
          case DrawingTool.Arrow:
            return (
              <DrawingToolArrowInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Callout: {
            return (
              <DrawingToolCallout
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );
          }

          case DrawingTool.Circle:
            return (
              <DrawingToolCircleInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.IconMarker:
            return (
              <DrawingToolIconMarkerInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Image:
            return (
              <DrawingToolImageInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Label:
            return (
              <DrawingToolLabel
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Number: {
            return (
              <DrawingToolNumberInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );
          }

          case DrawingTool.Polygon:
            return (
              <DrawingToolPolygonInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Polyline:
            return (
              <DrawingToolPolylineInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Rectangle:
            return (
              <DrawingToolRectangleInstanceContainer
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          case DrawingTool.Text:
            return (
              <DrawingToolTextInstance
                key={drawingItem.value.id}
                instance={drawingItem.value}
              />
            );

          default:
            return null;
        }
      })}

      <DrawingToolCreateNewInstanceContainer
        drawingToolManager={drawingToolManager}
      />
    </>
  );
};
