import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  type ComponentProps,
  type FC, type KeyboardEvent, useCallback, useEffect, useState,
} from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FormTextInputComponent } from '~/_shared/baseComponents/inputs';
import { isNullsy } from '~/_shared/utils/typeGuards';
import { useTranslation } from '../../../../utils/hooks';
import { FormButtonComponent } from '../../../formButton/formButton.component';

export type TwoFAConfirmationComponentProps = Pick<ComponentProps<typeof FormTextInputComponent>, 'setInputRef'|'isDisabled'> & Readonly<{
  onChange?: ComponentProps<typeof FormTextInputComponent>['onChange'];
  onSubmit: (mfaCode: string) => void;
  value?: ComponentProps<typeof FormTextInputComponent>['value'];
}>;

export const TwoFAConfirmationComponent: FC<TwoFAConfirmationComponentProps> = ({
  onSubmit,
  setInputRef,
  isDisabled,
  onChange,
  value,
}) => {
  const [t] = useTranslation();
  const [code, setCode] = useState(value ?? '');

  useEffect(() => {
    if (!isNullsy(value) && (value !== code)) {
      setCode(value);
    }
  }, [code, value]);

  const handleValueChange: ComponentProps<typeof FormTextInputComponent>['onChange'] = useCallback((newValue) => {
    setCode(newValue);
    onChange?.(newValue);
  }, [onChange]);

  const keyUpHandler = useCallback((event: KeyboardEvent) => {
    if ((event.key === 'Enter') && (code !== '')) {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(code);
    }
  }, [code, onSubmit]);

  const clickHandler = useCallback(() => {
    onSubmit(code);
  }, [code, onSubmit]);

  return (
    <FormTextInputComponent
      autoComplete="one-time-code"
      autoTrim
      icon={faLockAlt}
      isDisabled={isDisabled}
      inputMode="numeric"
      label={t('2 Factor Authentication Code')}
      marginBottom={24}
      onChange={handleValueChange}
      onKeyUp={keyUpHandler}
      setInputRef={setInputRef}
      value={code}
      button={(
        <FormButtonComponent
          buttonStyle={ButtonStyle.Primary}
          isDisabled={code === ''}
          onClick={clickHandler}
          text={t('Verify')}
        />
      )}
    />
  );
};
