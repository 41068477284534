import { type MapSettingsDistanceCalculatorStateAction } from './mapSettingsDistanceCalculator.action';
import {
  MAP_SETTINGS_DISTANCE_CALCULATOR_DELETE_DC_INSTANCE, MAP_SETTINGS_DISTANCE_CALCULATOR_OUTLINE_MOVE, MAP_SETTINGS_DISTANCE_CALCULATOR_SUBMIT_DC_INSTANCE,
} from './mapSettingsDistanceCalculator.actionTypes';
import { type MapSettingsDistanceCalculatorState } from './mapSettingsDistanceCalculator.state';

export const mapSettingsDistanceCalculatorInitialState: MapSettingsDistanceCalculatorState = {
  instances: [],
};

export const mapSettingsDistanceCalculatorReducer = (
  state = mapSettingsDistanceCalculatorInitialState,
  action: MapSettingsDistanceCalculatorStateAction
): MapSettingsDistanceCalculatorState => {
  switch (action.type) {
    case MAP_SETTINGS_DISTANCE_CALCULATOR_SUBMIT_DC_INSTANCE:
      return {
        ...state,
        instances: [...state.instances, action.payload.instance],
      };

    case MAP_SETTINGS_DISTANCE_CALCULATOR_DELETE_DC_INSTANCE:
      return {
        ...state,
        instances: state.instances
          .filter(instance => instance.id !== action.payload.instanceId),
      };

    case MAP_SETTINGS_DISTANCE_CALCULATOR_OUTLINE_MOVE:
      return {
        ...state,
        instances: state.instances.map(instance => instance.id !== action.payload.instanceId ? instance : {
          ...instance,
          outlines: instance.outlines.map(outline => outline.id !== action.payload.outlineId ? outline : {
            ...outline,
            ...action.payload.position,
          }),
        }),
      };

    default:
      return state;
  }
};
