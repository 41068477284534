import { type Column } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  filterAddedSpreadsheetColumns, SERVER_ADDED_COLUMN_NAMES,
} from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { notNull } from '~/_shared/utils/typeGuards';
import { type ReplaceDataMatchup } from '~/data/replaceData/replaceDataMatchupModal/replaceDataMatchupModal.component';
import {
  type CompositeUniqueIdentifier,
  type MaptiveAndGoogleSpreadsheetIntersectionResponse,
  type MaptiveGoogleSheetsColumnsMatches,
  MaptiveGoogleSheetSyncAction,
  type SyncSpreadsheetDuplicateDetails,
  SyncSpreadsheetErrorMessage, type SyncSpreadsheetResponse,
} from './googleSheet.repository';

export type SyncDuplicateRow = {
  id: CompositeUniqueIdentifier;
  rows: SyncSpreadsheetDuplicateDetails[];
};

export type MaptiveToGoogleSheetColumnInfo = {
  googleColumns: Column[];
  googleColumnsAdded: Column[];
  googleColumnsWithoutAdded: Column[];
  maptiveColumns: Column[];
  maptiveColumnsAdded: Column[];
  maptiveColumnsWithoutAdded: Column[];
  intersects: {
    google: string;
    maptive: string;
  }[];
};

export const hasSpreadsheetSyncMatchingErrors
  = (response: SyncSpreadsheetResponse, realSpreadsheetId: number): boolean => (
    !!response.data?.[realSpreadsheetId]?.matches?.google?.message
  || !!response.data?.[realSpreadsheetId]?.matches?.maptive?.message
  );

export const getSpreadsheetSyncDuplicates = (response: SyncSpreadsheetResponse, realSpreadsheetId: number) => {
  let googleDuplicates: SyncDuplicateRow[] = [];
  let maptiveDuplicates: SyncDuplicateRow[] = [];

  const responseForSpreadsheet = response.data[realSpreadsheetId];
  if (responseForSpreadsheet?.list) {
    if (responseForSpreadsheet.message === SyncSpreadsheetErrorMessage.DuplicateRowsGoogle) {
      googleDuplicates = Object.entries(responseForSpreadsheet.list).map(([compositeId, rows]) => ({
        id: compositeId,
        rows,
      }));
    }
    if (responseForSpreadsheet.message === SyncSpreadsheetErrorMessage.DuplicateRowsMaptive) {
      maptiveDuplicates = Object.entries(responseForSpreadsheet.list).map(([compositeId, rows]) => ({
        id: compositeId,
        rows,
      }));
    }
  }

  return {
    google: googleDuplicates,
    maptive: maptiveDuplicates,
  };
};

export const processTwoWaySyncIntersectionsResponse = (
  response: MaptiveAndGoogleSpreadsheetIntersectionResponse
): MaptiveToGoogleSheetColumnInfo => {
  const serverAddedColumnsIds = response.maptive_columns
    .filter(col => SERVER_ADDED_COLUMN_NAMES.has(col.name))
    .map(col => col.id);

  return ({
    googleColumns: response.google_columns,
    googleColumnsWithoutAdded: filterAddedSpreadsheetColumns(response.google_columns),
    googleColumnsAdded: filterAddedSpreadsheetColumns(response.google_columns, true),
    maptiveColumns: response.maptive_columns,
    maptiveColumnsWithoutAdded: filterAddedSpreadsheetColumns(response.maptive_columns),
    maptiveColumnsAdded: filterAddedSpreadsheetColumns(response.maptive_columns, true),
    intersects: response.intersects.filter(i => !serverAddedColumnsIds.includes(i.maptive)),
  });
};

export const getMaptiveGoogleSheetsColumnsMatchesFromMatchupAndAdded = (
  matchup: ReplaceDataMatchup,
  columnsInfo: MaptiveToGoogleSheetColumnInfo,
): MaptiveGoogleSheetsColumnsMatches[] => {
  const columnMatchesFromMatchup = Object.entries(matchup).map(([maptiveColId, googleColMatchup]) => ({
    maptiveColumn: maptiveColId,
    googleColumn: googleColMatchup.newColumnId as string,
  }));

  const columnMatchesFromMaptiveAndCommonAddedColumns = columnsInfo.maptiveColumnsAdded.map(col => {
    const googleColumnWithSameName = columnsInfo.googleColumnsAdded.findLast(gc => gc.name === col.name);
    if (googleColumnWithSameName) {
      return {
        maptiveColumn: col.id,
        googleColumn: googleColumnWithSameName.id,
      };
    }
    else {
      return {
        maptiveColumn: col.id,
        action: MaptiveGoogleSheetSyncAction.Ignore,
      };
    }
  });

  const columnMatchesFromGoogleAndCommonAddedColumns = columnsInfo.googleColumnsAdded.map(col => {
    const maptiveColumnWithSameName = columnsInfo.maptiveColumnsAdded.findLast(gc => gc.name === col.name);
    if (maptiveColumnWithSameName) {
      return null;
    }
    else {
      return {
        googleColumn: col.id,
        action: MaptiveGoogleSheetSyncAction.Ignore,
      };
    }
  }).filter(notNull);

  return [
    ...columnMatchesFromMatchup,
    ...columnMatchesFromMaptiveAndCommonAddedColumns,
    ...columnMatchesFromGoogleAndCommonAddedColumns,
  ];
};
