export enum MapPresentationalView {
  Loading = 'Loading',
  Regular = 'Regular',
  Password = 'Password',
  PrivateTokenMissing = 'PrivateTokenMissing',
}

export type MapPresentationalState = {
  isPresentationalMenuShown: boolean;
  showWrongPasswordError: boolean;
  view: MapPresentationalView;
};
