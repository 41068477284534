import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import {
  type BoundaryId, type BoundaryTerritoryGroupId, type BoundaryTerritoryId,
} from '~/store/boundaries/boundaryIdentifier.type';
import { usePrimaryBoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/usePrimaryBoundaryTerritoryGroup';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type AppState } from '../app.store';
import { type BoundaryTerritoryGroupDetailsItem } from './boundaryTerritoryGroups.state';

export const boundaryTerritoryDetailsFilterHashSelector = (state: AppState) => state.map.boundaryTerritoryDetails.territoryDetails.filterHash;

const boundaryTerritoryDetailsSelector = (state: AppState) => state.map.boundaryTerritoryDetails.territoryDetails.data;
export const useBoundaryTerritoryDetailsSelector = () => useSelector(boundaryTerritoryDetailsSelector);

const boundaryTerritoryDetailsIsLoadingSelector = (state: AppState) => state.map.boundaryTerritoryDetails.isLoading;
export const useBoundaryTerritoryDetailsIsLoadingSelector = () => useSelector(boundaryTerritoryDetailsIsLoadingSelector);

export type BoundaryTerritoryAssignment = {
  boundaryTerritoryGroupId: BoundaryTerritoryGroupId;
  territoryAssignments: ReadonlyMap<BoundaryTerritoryId, ReadonlySet<BoundaryId>>;
  boundaryAssignments: ReadonlyMap<BoundaryId, BoundaryTerritoryId>;
};

export const boundaryTerritoryAssignmentsSelector = (state: AppState): ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment> =>
  boundaryTerritoryAssignmentsMemoized(boundaryTerritoryDetailsSelector(state));

export const useBoundaryTerritoryAssignments = () => useSelector(boundaryTerritoryAssignmentsSelector);

export const usePrimaryBoundaryTerritoryAssignments = () => {
  const primaryGroup = usePrimaryBoundaryTerritoryGroup();
  const assignments = useBoundaryTerritoryAssignments();

  const groupId = primaryGroup?.boundaryTerritoryGroupId;

  if (!groupId) {
    return undefined;
  }

  return assignments.get(groupId);
};

const boundaryTerritoryAssignmentsMemoized = memoizeOne(
  (details: ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryGroupDetailsItem>): ReadonlyMap<BoundaryTerritoryGroupId, BoundaryTerritoryAssignment> => {
    const boundaryTerritoryGroupIds = Array.from(details.keys());

    return new Map(boundaryTerritoryGroupIds.map(id => {
      const territoryAssignments: Map<BoundaryTerritoryId, Set<BoundaryId>> = new Map();
      const boundaryAssignments: Map<BoundaryId, BoundaryTerritoryId> = new Map();

      const itemDetails = details.get(id);
      const generatedAssignments = itemDetails?.assignments ?? {};
      const manualAssignments = itemDetails?.manualAssignments ?? {};

      // Manual assignments
      for (const [territoryId, assignments] of Object.entries(manualAssignments)) {
        const territoryBoundaries = new Set<number>();

        for (const boundaryId of assignments) {
          territoryBoundaries.add(boundaryId);
          boundaryAssignments.set(boundaryId, territoryId);
        }

        territoryAssignments.set(territoryId, territoryBoundaries);
      }

      // Generated assignments
      for (const [territoryId, assignments] of Object.entries(generatedAssignments)) {
        const territoryBoundaries = territoryAssignments.get(territoryId) ?? new Set<number>();

        for (const boundaryId of assignments) {

          // Manual assignments take precedence.
          // If assignment already exists do not process generated assignment.
          if (!boundaryAssignments.has(boundaryId)) {
            territoryBoundaries.add(boundaryId);
            boundaryAssignments.set(boundaryId, territoryId);
          }
        }

        territoryAssignments.set(territoryId, territoryBoundaries);
      }

      return [id, {
        boundaryTerritoryGroupId: id,
        territoryAssignments,
        boundaryAssignments,
      }];
    }));
  });
