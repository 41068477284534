import {
  useEffect, useMemo,
} from 'react';
import { usePrevious } from '~/_shared/utils/hooks/usePrevious';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import {
  hasBoundaryItemChanged, hasZoomLevelDetailChanged,
} from '~/store/boundaryItems/boundaryItems.helpers';
import { useMapBoundariesSelector } from '~/store/boundaryItems/boundaryItems.selectors';
import { useMapComponentZoomSelector } from '~/store/frontendState/mapComponent/mapComponent.selectors';
import { useBoundarySelectModalOpenSelector } from '~/store/frontendState/mapTools/boundary/boundarySelect/boundarySelect.selectors';
import { useMapBoundaryDistanceToPixels } from '../boundaryView/mapBoundaryPolygons/useMapBoundaryDistanceToPixels';
import { useMapBoundaryLabelFilter } from '../boundaryView/mapBoundaryPolygons/useMapBoundaryLabelFilter';
import { type MapBoundaryManager } from '../mapBoundary.manager';

const emptyLabelFilter = () => false;

export const useSelectionMapBoundaryPolygons = (manager?: MapBoundaryManager, map?: google.maps.Map) => {
  const selection = useBoundarySelectModalOpenSelector();
  const previousSelection = usePrevious(selection);
  const boundaryItems = useMapBoundariesSelector();
  const boundaryGroups = useBoundaryGroupsSelector();
  const mapZoom = useMapComponentZoomSelector();

  const previousMapZoom = usePrevious(mapZoom);
  const previousBoundaryItems = usePrevious(boundaryItems);

  const previousManager = usePrevious(manager);
  const forceUpdate = !previousManager && previousManager !== manager;

  const distanceToPixelsScale = useMapBoundaryDistanceToPixels(map);
  const selectionBoundaryGroupIds = useMemo(() =>
    selection ? [selection.selectBoundaryGroupId] : [], [selection]);

  const labelFilter = useMapBoundaryLabelFilter({
    boundaryGroupIds: selectionBoundaryGroupIds,
    distanceToPixelsScale,
    textDimensionsCalculator: manager?.textDimensionsCalculator,
  }).values().next().value ?? emptyLabelFilter;
  const previousLabelFilter = usePrevious(labelFilter);

  // Remove polygons when boundaries are removed from map
  useEffect(() => {
    if (!manager) {
      return;
    }

    if (previousSelection?.selectBoundaryGroupId) {
      if (previousSelection.selectBoundaryGroupId !== selection?.selectBoundaryGroupId) {
        const usedBoundaries = new Set<number>([]);

        if (selection?.selectBoundaryGroupId !== undefined) {
          usedBoundaries.add(selection.selectBoundaryGroupId);
        }

        manager.removeUnusedBoundaryGroups(usedBoundaries);
      }
    }
  }, [selection?.selectBoundaryGroupId, manager, previousSelection?.selectBoundaryGroupId]);

  useEffect(() => {
    if (!manager || !mapZoom || !selection) {
      return;
    }

    const boundaryGroup = boundaryGroups.find(g => g.id === selection.selectBoundaryGroupId);

    if (!boundaryGroup) {
      return;
    }

    const shouldUpdate = forceUpdate
        || previousSelection?.selectBoundaryGroupId !== selection.selectBoundaryGroupId
        || hasBoundaryItemChanged(boundaryGroup.id, boundaryItems, previousBoundaryItems)
        || hasZoomLevelDetailChanged(boundaryGroup, mapZoom, previousMapZoom)
        || labelFilter !== previousLabelFilter;

    if (!shouldUpdate) {
      return;
    }

    const boundariesData = boundaryItems.get(selection.selectBoundaryGroupId);
    if (!boundariesData) {
      return;
    }

    manager.updateBoundaryGroupPolygons({
      boundaryGroup,
      boundariesData,
      mapZoomLevel: mapZoom,
      filter: () => true,
      labelFilter,
    });

  }, [boundaryGroups, boundaryItems, forceUpdate, labelFilter, manager, mapZoom, previousBoundaryItems,
    previousLabelFilter, previousMapZoom, previousSelection?.selectBoundaryGroupId, selection]);
};
