import { type FileAttachment } from '~/_shared/types/file.types';
import { memoizeOne } from '~/_shared/utils/memoize/memoize';
import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';
import { type MapSettingsFileAttachmentsMap } from './fileAttachments.state';

export const mapSettingsFileAttachmentsMapSelector = (state: AppState): MapSettingsFileAttachmentsMap => state.map.mapSettings.data.fileAttachments.map;
export const useMapSettingsFileAttachmentsMapSelector = (): MapSettingsFileAttachmentsMap => useSelector(mapSettingsFileAttachmentsMapSelector);

const mapSettingsFileAttachmentsListSelectorMemoized = memoizeOne((map: MapSettingsFileAttachmentsMap): ReadonlyArray<FileAttachment> => Array.from(map.values()));

export const mapSettingsFileAttachmentsListSelector = (state: AppState): ReadonlyArray<FileAttachment> =>
  mapSettingsFileAttachmentsListSelectorMemoized(mapSettingsFileAttachmentsMapSelector(state));

export const useMapSettingsFileAttachmentsListSelector = (): ReadonlyArray<FileAttachment> => useSelector(mapSettingsFileAttachmentsListSelector);
