import { css } from '@emotion/react';
import {
  useCallback, useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  mapSettingsMapStylesChangeStandardColors,
  mapSettingsMapStylesChangeStandardSliders,
  mapSettingsMapStylesChangeStylers,
  mapSettingsMapStylesChangeTheme,
} from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.actionCreators';
import { useMapBaseMapStylesSelector } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.selectors';
import { type MapSettingsMapStylesStylers } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  mapSettingsDisableClickableIcons, mapSettingsEnableClickableIcons,
} from '~/store/mapSettings/settings/mapSettingsSettings.actionCreators';
import { useMapSettingsSettingsClickableIconsSelector } from '~/store/mapSettings/settings/mapSettingsSettings.selectors';
import themeAubergine from '../../../../../../assets/images/mapThemes/Aubergine.png';
import themeCourgette from '../../../../../../assets/images/mapThemes/Courgette.png';
import themeDark from '../../../../../../assets/images/mapThemes/Dark.png';
import themeDeepWater from '../../../../../../assets/images/mapThemes/DeepWater.png';
import themeIce from '../../../../../../assets/images/mapThemes/Ice.png';
import themeMint from '../../../../../../assets/images/mapThemes/Mint.png';
import themeNight from '../../../../../../assets/images/mapThemes/Night.png';
import themeRetro from '../../../../../../assets/images/mapThemes/Retro.png';
import themeSilver from '../../../../../../assets/images/mapThemes/Silver.png';
import themeStandard from '../../../../../../assets/images/mapThemes/Standard.png';
import themeStorm from '../../../../../../assets/images/mapThemes/Storm.png';
import themeVintage from '../../../../../../assets/images/mapThemes/Vintage.png';
import {
  type MapColorSettings, type MapColorSettingsWithLabel, type MapSliderSettings,
} from '../settingsAccordion.types';
import { useGetBaseMapOverriddenStyles } from './advancedBaseMap/useGetBaseMapOverriddenStyles.hook';
import {
  BaseMapElementTypeName,
  BaseMapFeatureTypeName,
  MapStylesColorListLabelSettingsName,
  MapStylesColorListLandscapeSettingsName,
  MapStylesColorListRoadSettingsName,
  MapStylesColorListSettingsName,
  MapStylesColorSettingsName,
  MapStylesSliderSettingsName, MapStylesToggleSettingsName,
} from './baseMap.enums';
import { type BaseMapSettingsProps } from './baseMap.types';
import { BaseMapThemeName } from './baseMapThemes/baseMapThemes.enums';
import { type BaseMapStructureProps } from './standardBaseMap/standardBaseMap.types';

type ConditionalMapColorSettings<T> = T extends true ? MapColorSettingsWithLabel : MapColorSettings;

type StandardFeatureColorProps<T> = Readonly<{
  featureType: BaseMapFeatureTypeName;
  withLabel?: T;
  label?: string;
  secondaryFeatureTypes?: ReadonlyArray<BaseMapFeatureTypeName>;
}>;

export const baseMapImages = [
  { url: themeStandard, id: BaseMapThemeName.Standard },
  { url: themeDeepWater, id: BaseMapThemeName.DeepWater },
  { url: themeMint, id: BaseMapThemeName.Mint },
  { url: themeIce, id: BaseMapThemeName.Ice },
  { url: themeSilver, id: BaseMapThemeName.Silver },
  { url: themeRetro, id: BaseMapThemeName.Retro },
  { url: themeVintage, id: BaseMapThemeName.Vintage },
  { url: themeStorm, id: BaseMapThemeName.Storm },
  { url: themeNight, id: BaseMapThemeName.Night },
  { url: themeCourgette, id: BaseMapThemeName.Courgette },
  { url: themeAubergine, id: BaseMapThemeName.Aubergine },
  { url: themeDark, id: BaseMapThemeName.Dark },
];

export const useBaseMapSettings = (): BaseMapSettingsProps => {
  const dispatch = useDispatch();
  const [isBaseMapExpanded, setIsBaseMapExpanded] = useState(false);
  const [isMapThemeOpen, setIsMapThemeOpen] = useState(false);
  const [featureType, setFeatureType] = useState<BaseMapFeatureTypeName | null>(null);
  const [elementType, setElementType] = useState<BaseMapElementTypeName | null>(null);
  const mapStylesState = useMapBaseMapStylesSelector();
  const baseMapSettingsChangelist = useGetBaseMapOverriddenStyles();
  const [t] = useTranslation();
  const { openModal: openInitialMapPosition } = useModal(ModalType.InitialMapPosition);
  const mapSettingsClickableIcons = useMapSettingsSettingsClickableIconsSelector();

  const onChangeBaseMapAdvanced = useCallback((settings: MapSettingsMapStylesStylers) => {
    if (!elementType || !featureType) {
      return;
    }

    dispatch(mapSettingsMapStylesChangeStylers([{
      elementType,
      featureType,
      stylers: settings,
    }]));
  }, [dispatch, elementType, featureType]);

  const onMapThemeSelect = useCallback((themeId: BaseMapThemeName) => {
    if (themeId === undefined) {
      return;
    }
    dispatch(mapSettingsMapStylesChangeTheme(themeId));
  }, [dispatch]);

  const onMapAdvancedClose = useCallback(() => {
    setIsBaseMapExpanded(false);
  }, [setIsBaseMapExpanded]);

  const onMapThemeSelectorClose = useCallback(() => {
    setIsMapThemeOpen(false);
  }, [setIsMapThemeOpen]);

  const createStandardColorProps = useCallback((
    { featureType, elementType, secondaryFeatureTypes }: {
      featureType: BaseMapFeatureTypeName;
      elementType: BaseMapElementTypeName;
      secondaryFeatureTypes?: ReadonlyArray<BaseMapFeatureTypeName>;
    }): MapColorSettings => ({
    color: mapStylesState.styling[featureType][elementType].color.value,
    onChange: (newColor: string) => {
      dispatch(mapSettingsMapStylesChangeStandardColors(
        [featureType, ...secondaryFeatureTypes || []],
        elementType,
        newColor
      ));
    },
  }), [dispatch, mapStylesState]);

  const onResetBaseMapStyle = useCallback(() =>
    dispatch(mapSettingsMapStylesChangeTheme(mapStylesState.theme)),
  [dispatch, mapStylesState.theme]);

  const standardFeatureColorProps = useCallback(<T extends boolean>(
    data: { elementType: BaseMapElementTypeName } & StandardFeatureColorProps<T>
  ): ConditionalMapColorSettings<T> => {
    const { featureType, elementType, withLabel, label, secondaryFeatureTypes } = data;
    const color = createStandardColorProps({
      elementType, featureType, secondaryFeatureTypes,
    });

    if (withLabel) {
      return {
        ...color,
        label: (
          <div css={css({ paddingLeft: 26 })}>
            {label || t(featureType)}
          </div>
        ),
      } as unknown as ConditionalMapColorSettings<T>;
    }

    return color as ConditionalMapColorSettings<T>;
  }, [t, createStandardColorProps]);

  const standardLabelColorProps = useCallback(<T extends boolean>(data: StandardFeatureColorProps<T>): ConditionalMapColorSettings<T> => (
    standardFeatureColorProps({ elementType: BaseMapElementTypeName['labels.text.fill'], ...data })
  ), [standardFeatureColorProps]);

  const standardGeometryColorProps = useCallback(<T extends boolean>(data: StandardFeatureColorProps<T>): ConditionalMapColorSettings<T> => (
    standardFeatureColorProps({ elementType: BaseMapElementTypeName.geometry, ...data })
  ), [standardFeatureColorProps]);

  const createStandardSliderProps = useCallback((sliderName: MapStylesSliderSettingsName): MapSliderSettings => ({
    maxValue: 3,
    minValue: 0,
    value: mapStylesState.sliders[sliderName],
    onChange: (value: number) => dispatch(mapSettingsMapStylesChangeStandardSliders(sliderName, value)),
    predefined: true,
  }), [mapStylesState, dispatch]);

  const standardBaseMapSettingsDataProps: BaseMapStructureProps = useMemo(() => ({
    buttons: {
      onAdvancedClick: { onClick: () => setIsBaseMapExpanded(true) },
      onResetClick: { onClick: onResetBaseMapStyle },
      onThemesClick: { onClick: () => setIsMapThemeOpen(true) },
      onInitialMapClick: { onClick: () => openInitialMapPosition() },
    },
    colors: {
      [MapStylesColorSettingsName.poi]: standardGeometryColorProps({
        featureType: BaseMapFeatureTypeName['poi.park'],
        label: t(BaseMapFeatureTypeName.poi),
        secondaryFeatureTypes: [BaseMapFeatureTypeName.poi],
        withLabel: true,
      }),
      [MapStylesColorSettingsName.water]: standardGeometryColorProps({ featureType: BaseMapFeatureTypeName.water }),
    },
    toggles: {
      [MapStylesToggleSettingsName.enablePOIDescriptions]: {
        onToggle: (isActive) => {
          if (isActive) {
            dispatch(mapSettingsEnableClickableIcons());
          }
          else {
            dispatch(mapSettingsDisableClickableIcons());
          }
        },
        isOn: mapSettingsClickableIcons,
      },
    },
    colorList: {
      [MapStylesColorListSettingsName.labels]: {
        [MapStylesColorListLabelSettingsName['labels.administrative']]: standardLabelColorProps({
          featureType: BaseMapFeatureTypeName.administrative, withLabel: true,
        }),
        [MapStylesColorListLabelSettingsName['labels.poi.recreation']]: standardLabelColorProps({
          featureType: BaseMapFeatureTypeName['poi.park'],
          withLabel: true,
          label: t(MapStylesColorListLabelSettingsName['labels.poi.recreation']),
          secondaryFeatureTypes: [
            BaseMapFeatureTypeName['poi.attraction'],
            BaseMapFeatureTypeName['poi.sportsComplex'],
          ],
        }),
        [MapStylesColorListLabelSettingsName['labels.water']]: standardLabelColorProps({
          featureType: BaseMapFeatureTypeName.water, withLabel: true,
        }),
      },
      [MapStylesColorListSettingsName.landscape]: {
        [MapStylesColorListLandscapeSettingsName['landscape.natural']]: standardGeometryColorProps({
          featureType: BaseMapFeatureTypeName['landscape.natural'], withLabel: true,
        }),
        [MapStylesColorListLandscapeSettingsName['landscape.humanMade']]: standardGeometryColorProps({
          featureType: BaseMapFeatureTypeName['landscape.humanMade'], withLabel: true,
        }),
      },
      [MapStylesColorListSettingsName.roads]: {
        [MapStylesColorListRoadSettingsName['roads.highway']]: standardGeometryColorProps({
          featureType: BaseMapFeatureTypeName['road.highway'], withLabel: true,
        }),
        [MapStylesColorListRoadSettingsName['roads.others']]: standardGeometryColorProps({
          featureType: BaseMapFeatureTypeName['road.local'],
          withLabel: true,
          label: t(MapStylesColorListRoadSettingsName['roads.others']),
          secondaryFeatureTypes: [BaseMapFeatureTypeName['road.arterial']],
        }),
      },
    },
    sliders: {
      [MapStylesSliderSettingsName.labels]: createStandardSliderProps(MapStylesSliderSettingsName.labels),
      [MapStylesSliderSettingsName.roads]: createStandardSliderProps(MapStylesSliderSettingsName.roads),
      [MapStylesSliderSettingsName.landmarks]: createStandardSliderProps(MapStylesSliderSettingsName.landmarks),
    },
  }), [onResetBaseMapStyle, standardGeometryColorProps, t, mapSettingsClickableIcons,
    standardLabelColorProps, createStandardSliderProps, openInitialMapPosition, dispatch]);

  const baseMapSettings = useMemo(() => (
    featureType && elementType && mapStylesState.styling[featureType][elementType]
  ), [featureType, elementType, mapStylesState.styling]);

  return {
    areExpandedBaseSettingsOpen: isBaseMapExpanded,
    baseMapSettings,
    baseMapSettingsChangelist,
    isMapThemeSelectorOpen: isMapThemeOpen,
    onChangeBaseMapAdvanced,
    onElementTypeChange: setElementType,
    onFeatureTypeChange: setFeatureType,
    onMapAdvancedClose,
    onMapThemeSelect,
    onMapThemeSelectorClose,
    onResetBaseMapStyle,
    selectedMapTheme: mapStylesState.theme,
    standardBaseMapSettingsDataProps,
  };
};
