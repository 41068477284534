import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';

type LoadBoundaryPaneSubmitButtonComponentProps = Readonly<{
  text: string;
  isDisabled: boolean;
  onClick?: () => void;
}>;

const addBoundaryButtonStyle = css({
  height: '48px',
  width: '100%',
});

export const LoadBoundaryPaneSubmitButtonComponent = forwardRef<HTMLButtonElement, LoadBoundaryPaneSubmitButtonComponentProps>((props, ref) => {
  return (
    <ButtonComponent
      ref={ref}
      css={addBoundaryButtonStyle}
      text={props.text}
      isDisabled={props.isDisabled}
      onClick={props.onClick}
    />
  );
});
