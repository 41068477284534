import { useMemo } from 'react';
import { SelectedDataType } from '~/_shared/components/customizeMetrics/selectedDataMetric.type';
import { type SpreadsheetColumn } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { useBoundaryGroupsSelector } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { BoundaryTerritoryType } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { type BoundaryTerritoryGroup } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import { useSpreadsheetColumns } from '~/store/matchupData/matchupDataSelectors.hook';
import { matchBoundaryGroupDemographic } from './demographicsMetric.factory';
import { type MetricModel } from './metrics.factory';
import { areMetricsTheSame } from './metrics.helpers';
import { MetricsDataAction } from './metrics.types';

export const useBoundaryMetrics = (boundaryTerritoryGroup: BoundaryTerritoryGroup | undefined) => {
  const boundaryGroups = useBoundaryGroupsSelector();
  const spreadsheetColumns = useSpreadsheetColumns();

  const boundaryGroup = useMemo(() => boundaryGroups.find(g => g.id === boundaryTerritoryGroup?.boundaryGroupId),
    [boundaryGroups, boundaryTerritoryGroup?.boundaryGroupId]);

  const selectedMetrics: ReadonlyArray<MetricModel> = useMemo(() => {
    if (!boundaryTerritoryGroup || !boundaryGroup) {
      return [];
    }

    const metricsFromFill: MetricModel[] = [
      ...getFillDemographics(boundaryTerritoryGroup, boundaryGroup),
      ...getFillNumericMetrics(boundaryTerritoryGroup, spreadsheetColumns),
      ...getFillGroupMetrics(boundaryTerritoryGroup, spreadsheetColumns),
    ];

    const savedMetricsWithoutFillMetrics = boundaryTerritoryGroup.settings.metrics
      .map(metric => getBestDemographic(metric, boundaryGroup))
      .filter(savedMetric => !metricsFromFill.some(fillMetric => areMetricsTheSame(savedMetric, fillMetric)));

    return [
      ...metricsFromFill,
      ...savedMetricsWithoutFillMetrics,
    ];
  }, [boundaryGroup, boundaryTerritoryGroup, spreadsheetColumns]);

  return selectedMetrics;
};

const getFillDemographics = (boundaryTerritoryGroup: BoundaryTerritoryGroup, boundaryGroup: BoundaryGroup) => {
  if (boundaryTerritoryGroup.settings.boundaryTerritoryType !== BoundaryTerritoryType.Demographic) {
    return [];
  }

  const demographicFromFill = boundaryGroup.demographics
    .find(d => d.id === boundaryTerritoryGroup?.settings.demographicId);

  if (demographicFromFill) {
    return [{
      type: SelectedDataType.Demographic,
      demographic: demographicFromFill,
    } as const];
  }

  return [];
};

const getFillNumericMetrics = (boundaryTerritoryGroup: BoundaryTerritoryGroup, spreadsheetColumns: ReadonlyArray<SpreadsheetColumn>) => {
  if (boundaryTerritoryGroup.settings.boundaryTerritoryType !== BoundaryTerritoryType.Numeric) {
    return [];
  }

  const spreadsheetColumn = spreadsheetColumns.find(c => c.id === boundaryTerritoryGroup.settings.bucketColumnId);
  if (!spreadsheetColumn) {
    return [];
  }

  const numericActions = [MetricsDataAction.SUM, MetricsDataAction.AVERAGE, MetricsDataAction.HIGH_VALUE, MetricsDataAction.LOW_VALUE];
  return numericActions.map(dataAction => ({
    type: SelectedDataType.SpreadsheetColumn,
    spreadsheetColumn,
    dataAction,
  } as const));
};

const getFillGroupMetrics = (boundaryTerritoryGroup: BoundaryTerritoryGroup, spreadsheetColumns: ReadonlyArray<SpreadsheetColumn>) => {
  if (boundaryTerritoryGroup.settings.boundaryTerritoryType !== BoundaryTerritoryType.Groups) {
    return [];
  }

  const spreadsheetColumn = spreadsheetColumns.find(c => c.id === boundaryTerritoryGroup.settings.bucketColumnId);
  if (!spreadsheetColumn) {
    return [];
  }

  return [{
    type: SelectedDataType.SpreadsheetColumn,
    spreadsheetColumn,
    dataAction: MetricsDataAction.GROUP_COUNT,
  } as const];
};

const getBestDemographic = (metric: MetricModel, boundaryGroup: BoundaryGroup) => {
  if (metric.type === SelectedDataType.Demographic) {
    const nativeDemographic = boundaryGroup.demographics
      .find(demographic => matchBoundaryGroupDemographic(demographic.id, metric.demographic.id));

    if (nativeDemographic) {
      return {
        ...metric,
        demographic: nativeDemographic,
      };
    }
  }

  return metric;
};
