import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons/faFileExport';
import {
  faArrowsRotate,
  faChartSimple,
  faChartSimpleHorizontal,
  faCloudArrowUp,
  faCog,
  faDiagramCells,
  faFilterList,
  faList,
  faMapMarkedAlt,
  faPlus,
  faPresentation,
  faSave,
  faSearch,
  faShare,
  faShareSquare,
  faSignOut,
  faSync,
  faTools,
  faUserCog,
  faUserHeadset,
  faUsersCog,
  faWindowMaximize,
} from '@fortawesome/pro-solid-svg-icons';
import { itemWidth } from '~/_shared/constants/mainMenuUI.constants';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { mapToolIconMap } from '~/sidebar/sidebarApps/mapTools/mapTools.helpers';
import {
  type MainMenuItem, MainMenuItems,
} from './mainMenuItem.enum';

export const decideIsEnoughVerticalSpaceForMenuItems = (windowHeight: number, numberOfItems: number): boolean => {
  const itemHeight = itemWidth;
  return windowHeight / itemHeight >= numberOfItems;
};

export const getMainMenuItemTitle = (item: MainMenuItem, t: TranslationFnc, options?: { useShortVariant: boolean }): string => {
  const useShortVariant = options?.useShortVariant ?? false;

  switch (item) {
    case MainMenuItems.MapTools:
      return t('Map Tools');
    case MainMenuItems.Account:
      return t('Account');
    case MainMenuItems.AccountSettings:
      return t('Account Settings');
    case MainMenuItems.Help:
      return t('Help');
    case MainMenuItems.LogOut:
      return t('Log Out');
    case MainMenuItems.MapSettings:
      return t('Map Settings');
    case MainMenuItems.Present:
      return t('Shared View');
    case MainMenuItems.SaveMapView:
      return t('Save Map View');
    case MainMenuItems.ShareMap:
      return t('Share Map');
    case MainMenuItems.ShareMapAndPresent:
      return t('Share Map & Present');
    case MainMenuItems.SplitScreen:
      return t('Split Screen');
    case MainMenuItems.FilterData:
      return t('Filter Data');
    case MainMenuItems.ImportNewData:
      return t('Import New Data');
    case MainMenuItems.ExportData:
      return t('Export Data');
    case MainMenuItems.SyncData:
      return t('Sync Data');
    case MainMenuItems.AddNewDataRow:
      return t('Add New Row');
    case MainMenuItems.AddNew:
      return t('Add New');
    case MainMenuItems.RemoveDataRow:
      return t('Delete Rows');
    case MainMenuItems.AddNewDataColumn:
      return t('Add New Column');
    case MainMenuItems.MatchMyLocationData:
      return t('Categorize Data');
    case MainMenuItems.Proximity:
      return t('Radius / Drive Time');
    case MainMenuItems.Boundary:
      return t('Boundary / Territory');
    case MainMenuItems.Filter:
      return useShortVariant ? t('Filter') : t('Filter Data / Locations');
    case MainMenuItems.Grouping:
      return useShortVariant ? t('Grouping') : t('Grouping / Colored Pins');
    case MainMenuItems.HeatMap:
      return t('Heat Mapping');
    case MainMenuItems.MapLegend:
      return t('Map Legend');
    case MainMenuItems.Routing:
      return useShortVariant ? t('Directions') : t('Routing / Directions');

    case MainMenuItems.Location:
      return t('Locations');
    case MainMenuItems.Search:
      return t('Search');
    case MainMenuItems.NearMe:
      return t('Near me');
    default:
      throw createUnknownItemError(item);
  }
};

export const getMainMenuItemIcon = (item: MainMenuItem): IconProp => {
  switch (item) {
    case MainMenuItems.MapTools:
      return faTools;
    case MainMenuItems.MapSettings:
      return faCog;
    case MainMenuItems.SaveMapView:
      return faSave;
    case MainMenuItems.Present:
      return faPresentation;
    case MainMenuItems.ShareMap:
      return faShare;
    case MainMenuItems.ShareMapAndPresent:
      return faShareSquare;
    case MainMenuItems.FilterData:
      return faFilterList;
    case MainMenuItems.ImportNewData:
      return faCloudArrowUp;
    case MainMenuItems.ExportData:
      return faFileExport;
    case MainMenuItems.SyncData:
      return faSync;
    case MainMenuItems.AddNewDataRow:
      return faChartSimpleHorizontal;
    case MainMenuItems.AddNew:
      return faPlus;
    case MainMenuItems.RemoveDataRow:
      return faDiagramCells;
    case MainMenuItems.AddNewDataColumn:
      return faChartSimple;
    case MainMenuItems.MatchMyLocationData:
      return faArrowsRotate;
    case MainMenuItems.SplitScreen:
      return faWindowMaximize;
    case MainMenuItems.Account:
      return faUsersCog;
    case MainMenuItems.AccountSettings:
      return faUserCog;
    case MainMenuItems.Help:
      return faUserHeadset;
    case MainMenuItems.LogOut:
      return faSignOut;

    case MainMenuItems.Boundary:
    case MainMenuItems.Proximity:
    case MainMenuItems.Filter:
    case MainMenuItems.Grouping:
    case MainMenuItems.HeatMap:
    case MainMenuItems.MapLegend:
    case MainMenuItems.Routing:
      return mapToolIconMap[item];

    case MainMenuItems.Location:
      return faList;
    case MainMenuItems.Search:
      return faSearch;
    case MainMenuItems.NearMe:
      return faMapMarkedAlt;
    default:
      throw createUnknownItemError(item);
  }
};

const createUnknownItemError = (item: MainMenuItem): Error => new Error(`MainMenuItems.enum: Unknown item '${item}'.`);
