import { DrawingTool } from '~/drawingTool/drawingTool.enums';
import { type DrawingItem } from '~/store/mapSettings/drawing/items/drawingItems.state';
import { useSelector } from '../../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../../app.store';
import {
  drawingImageSelectorEditedDrawingFileIdSelector,
  drawingImageSelectorEditedDrawingInitialFileIdSelector,
} from '../drawingImageSelector/drawingImageSelector.selectors';

export const drawingEditSelectedDrawingSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingEdit.selectedDrawing;
export const isDrawingEditActiveSelector = (state: AppState) => drawingEditSelectedDrawingSelector(state) !== null;
export const useIsDrawingEditActiveSelector = () => useSelector(isDrawingEditActiveSelector);

export const drawingEditSelectedDrawingLatestSnapshotSelector = (state: AppState): DrawingItem | null => {
  const selectedDrawing = drawingEditSelectedDrawingSelector(state);

  if (!selectedDrawing || selectedDrawing.snapshots.length === 0) {
    return null;
  }

  return selectedDrawing.snapshots[selectedDrawing.snapshots.length - 1] ?? null;
};

export const useDrawingEditSelectedDrawingLatestSnapshot = () => useSelector(drawingEditSelectedDrawingLatestSnapshotSelector);

const drawingEditSelectedDrawingEtagSelector = (state: AppState) => state.frontendState.mapTools.drawing.drawingEdit.selectedDrawing?.Etag;
export const useDrawingEditSelectedDrawingEtag = () => useSelector(drawingEditSelectedDrawingEtagSelector);

export const drawingEditIsSelectedDrawingEditedSelector = (state: AppState) => {
  const selectedDrawing = drawingEditSelectedDrawingSelector(state);

  if (!selectedDrawing) {
    return false;
  }

  if (selectedDrawing.snapshots[0]?.type === DrawingTool.Image) {
    const initialFile = drawingImageSelectorEditedDrawingInitialFileIdSelector(state);
    const currentFile = drawingImageSelectorEditedDrawingFileIdSelector(state);

    const isEdited = !!initialFile && currentFile !== initialFile;
    if (isEdited) {
      return true;
    }
  }

  return !!selectedDrawing && selectedDrawing.snapshots.length > 1;
};

export const useDrawingEditIsSelectedDrawingEdited = () => useSelector(drawingEditIsSelectedDrawingEditedSelector);
