import { type DrawingToolAction } from '../../../mapTools/drawingTool/drawingTool.action';
import {
  FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL,
  FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE,
  FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED,
  FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED,
  FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED, FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED,
  FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED,
  FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED,
} from '../../../mapTools/drawingTool/drawingTool.actionTypes';
import { type MapComponentOptionsState } from '../mapComponentOptions.state';
import {
  type MapOptionsConfig, pop, push,
} from '../mapComponentOptionsStateOperations';

export const applyDrawingToolMapOptions = (
  state: MapComponentOptionsState,
  action: DrawingToolAction,
  { priority }: MapOptionsConfig
) => {
  switch (action.type) {
    case FRONTEND_STATE_DRAWING_TOOL_ENABLE_DRAWING_MODE:
      return push(state, {
        changeId: 'DRAWING_TOOL_DRAWING_MODE',
        priority,
        change: {
          draggableCursor: 'crosshair',
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_DRAWING_TOOL_DISABLE_DRAWING_MODE:
    case FRONTEND_STATE_DRAWING_TOOL_CLEAR_ACTIVE_TOOL: {
      return pop(state, 'DRAWING_TOOL_DRAWING_MODE');
    }

    case FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVERED:
      return push(state, {
        changeId: 'DRAWING_TOOL_ITEM_HOVER',
        priority,
        change: {
          draggableCursor: 'pointer',
          clickableIcons: false,
          disableDoubleClickZoom: true,
        },
      });

    case FRONTEND_STATE_DRAWING_TOOL_ITEM_HOVER_STOPPED: {
      return pop(state, 'DRAWING_TOOL_ITEM_HOVER');
    }

    case FRONTEND_STATE_DRAWING_TOOL_RESIZE_STARTED: {
      return push(state, {
        changeId: 'DRAWING_TOOL_DRAG_START',
        priority,
        change: {
          gestureHandling: 'none',
          clickableIcons: false,
          disableDoubleClickZoom: true,
          draggableCursor: action.payload.draggableCursor,
        },
      });
    }

    case FRONTEND_STATE_DRAWING_TOOL_RESIZE_STOPPED: {
      return pop(state, 'DRAWING_TOOL_DRAG_START');
    }

    case FRONTEND_STATE_DRAWING_TOOL_MOVE_STARTED: {
      return push(state, {
        changeId: 'DRAWING_TOOL_DRAG_START',
        priority,
        change: {
          gestureHandling: 'none',
          clickableIcons: false,
          disableDoubleClickZoom: true,
          draggableCursor: 'pointer',
        },
      });
    }

    case FRONTEND_STATE_DRAWING_TOOL_MOVE_STOPPED: {
      return pop(state, 'DRAWING_TOOL_DRAG_START');
    }

    default:
      return undefined;
  }
};
