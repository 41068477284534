import { DndType } from '~/_shared/constants/dndType.enum';
import {
  type ColumnRole, CONTACT_COLUMNS_ROLES,
} from '~/_shared/types/columnRole.enum';

export const getItemDnDType = (type?: ColumnRole | null) => {
  if (!type) {
    return DndType.LocalizationBasicItem;
  }

  return CONTACT_COLUMNS_ROLES.includes(type) ? DndType.LocalizationContactItem : DndType.LocalizationBasicItem;
};
