import { css } from '@emotion/react';
import { type FC } from 'react';
import { homePageZIndexes } from '~/homepage/homepage.constants';

const leftSidebarStyle = css({
  position: 'relative',
  flexShrink: 0,
  height: '100%',
  zIndex: homePageZIndexes.leftSidebar,
});

export const LeftSidebar: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div
      data-testid="sidebar"
      css={leftSidebarStyle}
    >
      {children}
    </div>
  );
};
