import { useSelector } from '../../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../../app.store';

const distanceCalculatorIsDraggingSelector = (state: AppState) => state.frontendState.mapTools.distanceCalculator.dragging;
export const useDistanceCalculatorIsDraggingSelector = () => useSelector(distanceCalculatorIsDraggingSelector);

const distanceCalculatorIsActiveSelector = (state: AppState) => state.frontendState.mapTools.distanceCalculator.active;
export const useDistanceCalculatorIsActiveSelector = () => useSelector(distanceCalculatorIsActiveSelector);

const distanceCalculatorModeSelector = (state: AppState) => state.frontendState.mapTools.distanceCalculator.mode;
export const useDistanceCalculatorModeSelector = () => useSelector(distanceCalculatorModeSelector);

const distanceCalculatorStartingPointSelector = (state: AppState) => state.frontendState.mapTools.distanceCalculator.startingPoint;
export const useDistanceCalculatorStartingPointSelector = () => useSelector(distanceCalculatorStartingPointSelector);
