import { parseDate } from '../_shared/utils/date/date.helpers';
import {
  type MapListingItem, type SpreadsheetMapInfo,
} from './listing/item/mapListingItem';
import { getMapOwnerUserId } from './map.helpers';
import {
  type MapInfoResponse,
  type MapSettingsKeyOf,
  type MapSettingsUpdateSettingsConcurrencyErrorResponse,
  type MapSnapshotResponse,
  type SpreadsheetDescriptorResponse,
} from './map.repository';
import { type MapSettingsUpdateSettingsConcurrencyErrorReason } from './settings/outOfSync/mapSettingsOutOfSync.types';

const createMapListingItemFromMapSnapshotResponse = (response: MapSnapshotResponse): MapListingItem => {
  return {
    id: response.id,
    name: response.name,
    mapViews: response.views,
    snapshots: [],
    spreadSheets: [],
    privacy: response.privacy,
    shareId: response.share_id,
    dateCreated: parseDate(response.created.date) || new Date(),
    dateUpdated: parseDate(response.updated.date) || new Date(),
    isLayered: false,
    ownerId: getMapOwnerUserId(response),
    source: response.source ?? null,
  };
};

const createRealSpreadSheetFromResponse = (response: ReadonlyArray<{real_spreadsheet_id: number}>): ReadonlyArray<{ realSpreadsheetId: number }> => {
  return response.map((item) => ({ realSpreadsheetId: item.real_spreadsheet_id }));
};

const createMapListingItemFromMapSpreadSheetResponse = (response: SpreadsheetDescriptorResponse): SpreadsheetMapInfo => {
  return {
    spreadsheetId: response.spreadsheet_id,
    isEncrypted: response.is_encrypted,
    isPrimary: response.is_primary,
    privileges: response.privileges,
    realSpreadsheets: createRealSpreadSheetFromResponse(response.real_spreadsheets),
    geocoding: response.geocoding,
  };
};

export const createMapListingItemFromMapResponse = (response: MapInfoResponse): MapListingItem => {
  const snapshots = (response.snapshots ?? []).map(snapshot => createMapListingItemFromMapSnapshotResponse(snapshot));
  const spreadSheets = response.spreadsheets.map(spreadSheets => createMapListingItemFromMapSpreadSheetResponse(spreadSheets));

  return {
    id: response.id,
    name: response.name,
    mapViews: response.views,
    snapshots,
    spreadSheets,
    dateCreated: parseDate(response.created.date) || new Date(),
    dateUpdated: parseDate(response.updated.date) || new Date(),
    isLayered: response.is_layered,
    layering: response?.layering && response?.layering.matches ? {
      baseMaps: response.layering.base_maps?.map(baseMap => ({
        id: baseMap.id,
        name: baseMap.name,
        spreadsheets: baseMap.spreadsheets?.map(s => ({
          realSpreadsheets: s.real_spreadsheets.map(rS => ({
            realSpreadsheetId: rS.real_spreadsheet_id,
          })),
        })),
        sharedUsers: baseMap.shared_users,
      })),
      columns: response.layering.columns,
      connected: response.layering.connected,
      sourceColumns: response.layering.source_columns,
      matches: response.layering.matches.map(match => ({ ...match, columnId: match.column_id })),
      realTime: response.layering.real_time,
    } : undefined,
    isMatchupRequired: response.is_matchup_required,
    isImporting: response.importing,
    privacy: response.privacy,
    shareId: response.share_id,
    ownerId: getMapOwnerUserId(response),
    source: response.source ?? null,
  };
};

export const createUpdateSettingsConcurrencyError = (
  response: MapSettingsUpdateSettingsConcurrencyErrorResponse
): MapSettingsUpdateSettingsConcurrencyErrorReason => {
  const reason: MapSettingsUpdateSettingsConcurrencyErrorReason = {};

  Object.entries(response.reason).forEach(([key, value]) => {
    reason[key as MapSettingsKeyOf] = {
      concurrencyCheck: value.concurency_check,
      timestamp: value.timestamp,
      userId: value.userId,
      userName: value.userName,
    };
  });

  return reason;
};
