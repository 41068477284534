import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { refreshPage } from '~/_shared/utils/document/document.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import {
  type BoundaryCreateOptimizedItem, type BoundaryCreateOptimizedStatus,
} from '~/store/frontendState/mapTools/boundary/boundaryCreateOptimized/boundaryCreateOptimized.state';
import { OptimizedTerritoriesProgressBarComponent } from './optimizedTerritoriesProgressBar.component';

const containerStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '0 16px',
});

const titleStyle = ({ theme }: ThemeProps) => css({
  width: '50%',
  color: theme.textColors.primary,
});

type OptimizedTerritoriesProgressAlertProps = {
  isMobileVersion?: boolean;
  status: BoundaryCreateOptimizedStatus;
  title: string;

  onCancel: () => void;
  onProcessFrozen: () => void;
};

export const TopUnderbarOptimizedTerritoriesComponent: FC<OptimizedTerritoriesProgressAlertProps> = (props) => {
  const theme = useTheme();

  return (
    <div css={containerStyle}>
      {!props.isMobileVersion && <span css={titleStyle({ theme })}>{props.title}</span>}

      <OptimizedTerritoriesProgressBarComponent
        onCancel={props.onCancel}
        onProcessFrozen={props.onProcessFrozen}
        status={props.status}
      />
    </div>
  );
};

export const renderBoundaryOptimizedTerritoryItem = ({ isMobileVersion, item, onCancel, t }: {
  isMobileVersion?: boolean;
  item: BoundaryCreateOptimizedItem;
  onCancel: () => void;
  t: TranslationFnc;
}) => ({
  children: (
    <TopUnderbarOptimizedTerritoriesComponent
      isMobileVersion={isMobileVersion}
      status={item.status}
      title={t('boundaryAI.building.waitTimeInfo')}
      onCancel={onCancel}
      onProcessFrozen={() => refreshPage()}
    />
  ),
  icon: faExclamationTriangle,
  isWarningIcon: true,
  key: `optimizedTerritories-${item.id}`,
});
