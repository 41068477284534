import { type FC } from 'react';
import { type RGBColor } from 'react-color';
import {
  colorTileStyle, outlineStyles, placeholderStyle,
} from './tinyMapColorTile.styles';
import {
  TinyMapPlaceholderComponent, type TinyMapPlaceholderComponentProps,
} from './tinyMapPlaceholder.component';

export type TinyMapColorTileComponentProps = {
  color: RGBColor;
  lineColor?: RGBColor;
  lineWidth?: number;
  fill?: boolean;
  circle?: boolean;
  outline?: boolean;
} & Pick<TinyMapPlaceholderComponentProps, 'className' | 'size'>;

export const TinyMapColorTileComponent: FC<TinyMapColorTileComponentProps> = ({
  size,
  className,
  outline,
  lineWidth = 0,
  ...restProps
}) => (
  <TinyMapPlaceholderComponent
    size={size}
    css={placeholderStyle(restProps)}
    className={className}
  >
    <div css={colorTileStyle({ lineWidth, ...restProps })} />
    {outline && (<div css={outlineStyles({ lineWidth, ...restProps })} />)}
  </TinyMapPlaceholderComponent>
);
