import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  type UndoItem, UndoItemType, type UndoMapSnapshotsType,
} from '../../_shared/types/undo/undoItem';
import { type StorageService } from '../../_shared/utils/storageService';
import { type PickAction } from '../../_shared/utils/types/action.type';
import {
  type DeletedMapsApiData, fetchDeletedMaps,
} from '../../undo/undo.repository';
import { getUserStorageService } from '../userData/userData.sagas';
import { type UndoAction } from './undo.action';
import {
  allUndoItemsFetchDataSuccess,
  deletedMapsFetchDataError,
} from './undo.actionCreators';
import {
  DELETE_UNDO_ITEM,
  DELETED_MAPS_FETCH_DATA_REQUEST,
} from './undo.actionTypes';
import {
  getHistoryItems, limitHistoryItems, removeUndoItemFromHistory, sortHistoryItems,
} from './undo.helpers';

function* onFetchDeletedMapsRequest(action: PickAction<UndoAction, typeof DELETED_MAPS_FETCH_DATA_REQUEST>) {
  try {
    const response: {data: DeletedMapsApiData[]} = yield call(fetchDeletedMaps, action.payload.clientId);
    const undoItems: UndoItem[] = response.data.map(row => ({
      type: UndoItemType.DELETE_MAPS_AND_SNAPSHOTS,
      data: {
        deletedAt: row.deleted_at,
        id: row.id,
        name: row.name,
        type: row.type as UndoMapSnapshotsType,
      },
      timestamp: row.deleted_at,
    }));

    const storageService: StorageService = yield call(getUserStorageService);

    const historyUndoItems = limitHistoryItems(getHistoryItems(storageService));
    const allUndoItems = sortHistoryItems(undoItems.concat(historyUndoItems));
    yield put(allUndoItemsFetchDataSuccess(allUndoItems));
  }
  catch (e) {
    yield put(deletedMapsFetchDataError());
  }
}

function * onDeleteUndoItem(action: PickAction<UndoAction, typeof DELETE_UNDO_ITEM>) {
  const storageService: StorageService = yield call(getUserStorageService);

  yield removeUndoItemFromHistory(storageService, action.payload.data);
}

function* fetchDeletedMapsSaga() {
  yield takeEvery(DELETED_MAPS_FETCH_DATA_REQUEST, onFetchDeletedMapsRequest);
  yield takeEvery(DELETE_UNDO_ITEM, onDeleteUndoItem);
}

export function* undoSagas() {
  yield all([
    fetchDeletedMapsSaga(),
  ]);
}
