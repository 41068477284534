import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import {
  closeMapTool,
  openMapTool,
} from '../../store/frontendState/leftSidebar/leftSidebar.actionCreators';
import { useIsMapPresentationalSelector } from '../../store/selectors/useMapInfoSelectors';
import { type SidebarAppProps } from '../sidebarAppComponentProps.type';
import { MapToolsComponent } from './mapTools.component';
import { standaloneMapTools } from './mapTools/mapTools.helpers';

export const MapToolsContainer: FC<SidebarAppProps> = props => {
  const dispatch = useDispatch();
  const selectedTool = useSelector(s => s.frontendState.leftSidebar.openedMapTool);
  const isMapPresentational = useIsMapPresentationalSelector();
  const isMobileScreen = useIsMobileScreenSelector();

  const isToolSelectEnabled = !isMapPresentational && !isMobileScreen
    && selectedTool !== null && !standaloneMapTools.includes(selectedTool);

  return (
    <MapToolsComponent
      closeTool={() => dispatch(closeMapTool())}
      isToolSelectEnabled={isToolSelectEnabled}
      onClose={props.onClose}
      openTool={(tool) => dispatch(openMapTool(tool))}
      selectedTool={selectedTool}
    />
  );
};
