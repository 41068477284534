import {
  type FC, useCallback, useMemo,
  useState,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { isNullsy } from '~/_shared/utils/typeGuards';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  type ReplaceDataMatchup, ReplaceDataMatchupModalComponent,
} from './replaceDataMatchupModal.component';

export type ReplaceDataMatchupModalContainerProps = ModalProps<{
  initialMatchup: ReplaceDataMatchup;
  newColumns: ReadonlyArray<DropdownOption<string>>;
  newColumnsCaption?: string;
  noIgnoredColumns?: boolean;
  oldColumns: ReadonlyArray<DropdownOption<string>>;
  oldColumnsCaption?: string;

  onSubmit: (matchup: ReplaceDataMatchup) => void;
}>;

export const ReplaceDataMatchupModalContainer: FC<ReplaceDataMatchupModalContainerProps> = (props) => {
  const [matchup, setMatchup] = useState<ReplaceDataMatchup>(props.initialMatchup);
  const [isLoading, setIsLoading] = useState(false);

  const isSubmitDisabled = useMemo(() => {
    const ignoredColumns = Object.values(matchup).filter(item =>
      props.noIgnoredColumns && (item.ignore || item.newColumnId === null)
    );
    if (ignoredColumns.length) {
      return true;
    }

    return props.oldColumns.some(c => isNullsy(matchup[c.value]));
  }, [props.noIgnoredColumns, props.oldColumns, matchup]);

  const submitMatchup = useCallback(() => {
    const onSubmit = props.onSubmit;
    setIsLoading(true);
    onSubmit(matchup);
  }, [matchup, props.onSubmit]);

  return (
    <ReplaceDataMatchupModalComponent
      isOpen={props.isOpen}
      isSubmitDisabled={isSubmitDisabled}
      matchup={matchup}
      newColumns={props.newColumns}
      newColumnsCaption={props.newColumnsCaption}
      noIgnoredColumns={props.noIgnoredColumns}
      oldColumns={props.oldColumns}
      oldColumnsCaption={props.oldColumnsCaption}
      onClose={props.onClose}
      onMatchupChange={setMatchup}
      onSubmit={submitMatchup}
      isLoading={isLoading}
    />
  );
};
