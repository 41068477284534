import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { mapListingSearchQueryChanged } from '~/store/frontendState/mapListing/mapListing.actionCreators';
import { useMapListSearchQuerySelector } from '~/store/frontendState/mapListing/mapListing.selectors';

export const useMapListingSearchQuery = () => {
  const dispatch = useDispatch();

  const onSearchQueryChange = useCallback((query: string) => {
    dispatch(mapListingSearchQueryChanged(query));
  }, [dispatch]);

  const searchQuery = useMapListSearchQuerySelector();

  return {
    searchQuery,
    onSearchQueryChange,
  };
};
