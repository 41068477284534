import { css } from '@emotion/react';
import {
  faPencil, faUserShield, type IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, type ReactNode,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { MemberIndexCircleComponent } from '~/clientTeamManagement/memberIndexCircle.component';
import {
  type MemberRole, MemberRoles,
} from '../memberRole.type';
import {
  InvitationStatus, MemberStatus,
} from '../teamManagement.repository';
import { type InvitationAndMemberData } from '../teamManagementModal.container';

const ROLE_ICONS: Record<MemberRole, IconDefinition> = {
  [MemberRoles.COMPANY_OWNER]: faUserShield,
  [MemberRoles.COMPANY_ADMIN]: faUserShield,
  [MemberRoles.INDEPENDENT_MEMBER]: faPencil,
};

const STATUS_COLORS: Record<InvitationStatus | MemberStatus, string> = {
  [InvitationStatus.PENDING]: '#D7DE04',
  [InvitationStatus.REJECTED]: '#DC3545',
  [InvitationStatus.EXPIRED]: '#DC3545',
  [InvitationStatus.ACCEPTED]: '#2ec229',
  [InvitationStatus.UNKNOWN]: '#ACBAC3',
  [MemberStatus.ACTIVE]: '#52b879',
  [MemberStatus.ENABLED]: '#52b879',
  [MemberStatus.DISABLED]: '#ACBAC3',
};

const itemContainerStyle = ({ theme, index }: ThemeProps & {index: number}) => css({
  alignItems: 'center',
  background: index % 2 ? theme.backgroundColors.secondary : theme.modalColors.contentBackground,
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  display: 'flex',
  gap: 6,
  justifyContent: 'space-between',
  padding: '12px 12px 12px 22px',
  position: 'relative',
});

const ellipsisStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const memberStyle = ({ theme }: ThemeProps) => css(ellipsisStyle, {
  color: theme.textColors.primary,
  cursor: 'default',
  flexBasis: '30%',
});

const memberRoleStyle = ({ theme }: ThemeProps) => css(memberStyle({ theme }), {
  color: theme.iconColors.focused,
});

const memberStatusStyle = (status: InvitationStatus | MemberStatus) => css(ellipsisStyle, {
  color: STATUS_COLORS[status],
  cursor: 'default',
  flexBasis: '10%',
});

const functionIconContainerStyle = css({
  marginLeft: 18,
  position: 'relative',
});

const roleIconStyle = css({
  fontSize: 16,
  marginRight: 10,
});

type MembersTableItemComponentProps = {
  index: number;
  member: InvitationAndMemberData;
  contextMenu: ReactNode;
  isLoading: boolean;
};

export const MembersTableItemComponent: FC<MembersTableItemComponentProps> = ({
  index,
  member,
  contextMenu,
  isLoading,
}) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <div css={itemContainerStyle({ theme, index })}>
      {isLoading && <OverlayLoaderComponent loaderSize={40} />}
      <MemberIndexCircleComponent number={index + 1} />
      <span css={memberStyle({ theme })}>{member.name}</span>
      <span css={memberStyle({ theme })}>{member.email}</span>
      <span css={memberRoleStyle({ theme })}>
        {member.role ? (
          <>
            {ROLE_ICONS[member.role] && (
              <FontAwesomeIcon
                css={roleIconStyle}
                icon={ROLE_ICONS[member.role]}
              />
            )}
            {t(member.role)}
          </>
        ) : (
          <div />
        )}
      </span>
      <span css={memberStatusStyle(member.status || member.invitation)}>
        {member.status || member.invitation}
      </span>

      <span css={functionIconContainerStyle} >
        {contextMenu}
      </span>
    </div>
  );
};
