import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';

export type GenerateSignUpPageUrlSuccessResponse = { sign_up_page: string };

export const getSignUpPages = (): Promise<{ list: {[ pageId: string]: string } }> => {
  const requestUrl = '/api/billing/sign-up-page';

  return apiGet(requestUrl);
};

export const getSignUpPageUrl = (
  clientId: number,
  pageId: string,
): Promise<GenerateSignUpPageUrlSuccessResponse> => {
  const requestUrl = `/api/clients/${clientId}/billing/sign-up-page`;

  return apiPost(requestUrl, { page_id: pageId });
};

export const getCustomerPortalUrl = (
  clientId: number
): Promise<{ data: { link: string } }> => {
  const requestUrl = `/api/clients/${clientId}/billing/customer-portal`;

  return apiGet(requestUrl);
};

export const refresh = (
  clientId: number
): Promise<void> => {
  const requestUrl = `/api/clients/${clientId}/billing/refresh`;
  return apiPost(requestUrl);
};

export const cancelClientSubscriptionRenewal = (clientId: number) => {
  const requestUrl = `/api/clients/${clientId}/billing/subscriptions/cancel`;

  return apiPost(requestUrl);
};

export const restoreClientSubscriptionRenewal = (clientId: number) => {
  const requestUrl = `/api/clients/${clientId}/billing/subscriptions/restore`;

  return apiPost(requestUrl);
};
