import { type RouteLeg } from '~/_shared/types/googleMaps/googleMaps.types';
import { type LatLng } from '~/_shared/types/latLng';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';

export type DirectionsState = Readonly<{
  waypoints: ReadonlyArray<Waypoint>;
  routesUiData: ReadonlyMap<string, RouteUiData>;
  expandedRouteIds: ReadonlySet<string>;
}>;

export type RouteUiData = Readonly<{
  expandedLegsIndexes: ReadonlySet<number>;
  legs: ReadonlyArray<RouteLeg>;
  copyrights: string;
  apiResponses: ReadonlyArray<google.maps.DirectionsResult>;
}>;

export const emptyRouteUiData: RouteUiData = {
  expandedLegsIndexes: new Set(),
  copyrights: '',
  legs: [],
  apiResponses: [],
};

export enum DirectionSourceType {
  From = 'From',
  To = 'To',
}

type CommonWaypointProps = Readonly<{
  id: string;
  markerId?: SpreadsheetRowId;
}>;

type WithAddress = CommonWaypointProps & Readonly<{
  address: string;
  latLng: null;
}>;

type WithLatLng = CommonWaypointProps & Readonly<{
  address: null;
  latLng: LatLng;
}>;

type WithBoth = CommonWaypointProps & Readonly<{
  address: string;
  latLng: LatLng;
}>;

export type Waypoint = WithAddress | WithLatLng | WithBoth;
export type CompleteWaypoint = WithBoth;
