import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type ModalProps } from '~/modal/modalType.enum';
import { MapNameSectionComponent } from '../../../map/basicInfo/mapNameSection';
import { useTranslation } from '../../utils/hooks';
import { MapPrivacySelectorComponent } from '../mapPrivacySelector/mapPrivacySelector.component';
import {
  type ManageMapPrivacyCallbacks, type ManageMapPrivacyErrors, type ManageMapPrivacyState,
} from '../mapPrivacySelector/useManageMapPrivacy.hook';
import { ModalComponent } from '../modal/modal.component';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { MapPrivacySelectInputField } from './mapPrivacySelectorInputField.enum';

const wrapperStyle = css({
  padding: 0,
});

const matchingBehaviourHeaderStyle = css({
  padding: '20px 0',
});

const rootStyle = css({
  fontSize: '14px',
  padding: '0 20px 20px',
});

type MapPrivacySelectorModalProps = ModalProps<{
  isLoading: boolean;
  isSaveDisabled: boolean;
  isSnapshot: boolean;
  manageMapPrivacyProps: ManageMapPrivacyState & ManageMapPrivacyErrors & ManageMapPrivacyCallbacks;
  mapName: string;
}>;

export const MapPrivacySelectorModalComponent: React.FC<MapPrivacySelectorModalProps> = props => {
  const { manageMapPrivacyProps } = props;
  const [t] = useTranslation();

  return (
    <ModalComponent
      onClose={props.onClose}
      isOpen={props.isOpen}
      caption={t('Map Security Status')}
      confirmButton={(
        <ButtonComponent
          isDisabled={props.isSaveDisabled}
          onClick={manageMapPrivacyProps.onSave}
          prefixIcon={faCheck}
          text={t('Save')}
        />
      )}
      contentStyle={wrapperStyle}
      additionalContent={props.isLoading && <OverlayLoaderComponent />}
    >
      <MapNameSectionComponent
        isSnapshot={props.isSnapshot}
        name={props.mapName}
      />
      <div css={rootStyle}>
        <div css={matchingBehaviourHeaderStyle}>{t('Select the Security Status for this map below')}</div>

        <MapPrivacySelectorComponent
          disabledPrivacyLevels={manageMapPrivacyProps.disabledPrivacyLevels}
          onChange={manageMapPrivacyProps.onPrivacyLevelChange}
          onClearPasswordError={() => manageMapPrivacyProps.clearFieldError(MapPrivacySelectInputField.Password)}
          onIsFormValidChanged={manageMapPrivacyProps.setIsFormValid}
          passwordError={manageMapPrivacyProps.fieldErrors.get(MapPrivacySelectInputField.Password)?.[0] ?? undefined}
          privacyLevel={manageMapPrivacyProps.privacyLevel}
          showPrivate
          globalError={manageMapPrivacyProps.globalError}
          onClearGlobalError={manageMapPrivacyProps.clearGlobalError}
        />
      </div>
    </ModalComponent>
  );
};
