import {
  css, type SerializedStyles,
} from '@emotion/react';
import { faRuler } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useMemo,
} from 'react';
import {
  SuffixDropdown, TextInputWithSuffixComponent,
} from '~/_shared/baseComponents/inputs';
import { type UnitSystem } from '../../../../_shared/types/googleMaps/googleMaps.types';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { getUnitSystemOptions } from '../../../../_shared/utils/unitSystem/unitSystem.helpers';

type UnitDropdownProps = Readonly<{
  inputStyle?: SerializedStyles;
  value: string;
  triggerStyle?: SerializedStyles;
  unit: UnitSystem;

  onChange: (newValue: string) => void;
  onUnitChange: (unit: UnitSystem) => void;
}>;

const upperCase = css({
  textTransform: 'uppercase',
});

export const UnitDropdownComponent: FC<UnitDropdownProps> = props => {
  const [t] = useTranslation();
  const unitOptions = useMemo(() => getUnitSystemOptions(t), [t]);

  return (
    <TextInputWithSuffixComponent
      inputStyle={props.inputStyle}
      value={props.value}
      onChange={props.onChange}
      icon={faRuler}
      suffix={(
        <SuffixDropdown
          value={props.unit}
          onChange={props.onUnitChange}
          options={unitOptions}
          triggerStyle={css(props.triggerStyle, upperCase)}
          itemStyle={upperCase}
        />
      )}
    />
  );
};
