import {
  put, takeEvery,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import {
  clickSpreadsheetMarker, deactivateActiveMarker,
} from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { activeMarkerIdsSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM } from '~/store/mapSettings/searchItems/mapSettingsSearchItems.actionTypes';
import { MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionTypes';
import { MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA } from '~/store/mapSettings/toolsState/heatmaps/heatmapToolState.actionTypes';
import { selectVisibleFilteredSpreadsheetRowIds } from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import {
  SPREADSHEET_ADD_FILTER_ITEM, SPREADSHEET_FETCH_DATA_SUCCESS,
} from '~/store/spreadsheetData/spreadsheetData.actionTypes';

export function* activeMapElementsSagas() {
  yield takeEvery([
    MAP_SETTINGS_HEATMAP_UPDATE_TOOLSTATE_DATA,
    MAP_SETTINGS_SEARCH_ITEMS_ADD_ITEM,
    MAP_SETTINGS_TOOLS_STATE_BOUNDARY_SET_LOCATIONS_FILTER,
    SPREADSHEET_ADD_FILTER_ITEM,
    SPREADSHEET_FETCH_DATA_SUCCESS,
  ], onFilter);
}

function* onFilter() {
  try {
    const filteredRowIds: ReturnType<typeof selectVisibleFilteredSpreadsheetRowIds> = yield select(selectVisibleFilteredSpreadsheetRowIds);
    const activeMarkerIds: ReturnType<typeof activeMarkerIdsSelector> = yield select(activeMarkerIdsSelector);

    if (!activeMarkerIds) {
      return;
    }

    const filteredMarkers = activeMarkerIds.filter(marker => {
      const visibleRowIdsForSpreadsheet = filteredRowIds.visibleRowIds[marker.spreadsheetId];

      return visibleRowIdsForSpreadsheet?.[marker.rowId];
    });

    if (filteredMarkers.length === activeMarkerIds.length) {
      return;
    }

    if (filteredMarkers.length === 0) {
      yield put(deactivateActiveMarker());
    }
    else {
      yield put(clickSpreadsheetMarker({ rowIds: filteredMarkers }));
    }
  }
  catch (error) {
    console.error('Error in onFilter saga:', error);
  }
}
