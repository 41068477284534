// copy from https://github.com/googlemaps/google-maps-services-js/blob/v3.1.6/src/common.ts
// used as a copy instead of entire repository as it's causing the IE11 errors (google-maps-services-js are not being transpiled into es5)

/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export interface GoogleMapsMouseDownEvent {
  stop: () => void;
  latLng: google.maps.LatLng;
  pixel: {
    x: number;
    y: number;
  };
}

interface LatLngLiteral {
  lat: number;
  lng: number;
}
/** The bounds parameter defines the latitude/longitude coordinates of the southwest and northeast corners of this bounding box. */
interface LatLngBounds {
  northeast: LatLngLiteral;
  southwest: LatLngLiteral;
}

/**
 * Directions results contain text within distance fields that may be displayed to the user to indicate the distance of
 * a particular "step" of the route. By default, this text uses the unit system of the origin's country or region.
 */
export enum UnitSystem {
  /** specifies usage of the metric system. Textual distances are returned using kilometers and meters. */
  metric = 'metric',
  /** specifies usage of the Imperial (English) system. Textual distances are returned using miles and feet. */
  imperial = 'imperial',
}

/**
 * This route may consist of one or more `legs` depending on whether any waypoints were specified. As well, the route also contains
 * copyright and warning information which must be displayed to the user in addition to the routing information.
 */
export interface DirectionsRoute {
  /** contains a short textual description for the route, suitable for naming and disambiguating the route from alternatives. */
  summary: string;
  /**
   * contains an array which contains information about a leg of the route, between two locations within the given route.
   * A separate leg will be present for each waypoint or destination specified.
   * (A route with no waypoints will contain exactly one leg within the `legs` array.)
   * Each leg consists of a series of `steps`.
   */
  legs: RouteLeg[];
  /**
   * contains an array indicating the order of any waypoints in the calculated route.
   * This waypoints may be reordered if the request was passed `optimize:true` within its `waypoints` parameter.
   */
  waypoint_order: number[];
  /**
   * contains a single `points` object that holds an encoded polyline representation of the route.
   * This polyline is an approximate (smoothed) path of the resulting directions.
   */
  overview_polyline: {
    points: string;
  };
  /** contains the viewport bounding box of the `overview_polyline`. */
  bounds: LatLngBounds;
  /** contains the copyrights text to be displayed for this route. You must handle and display this information yourself. */
  copyrights: string;
  /** contains an array of warnings to be displayed when showing these directions. You must handle and display these warnings yourself. */
  warnings: string[];
  /**
   * If present, contains the total fare (that is, the total ticket costs) on this route.
   * This property is only returned for transit requests and only for routes where fare information is available for all transit legs.
   *
   * **Note:** The Directions API only returns fare information for requests that contain either an API key or a client ID
   * and digital signature.
   */
  fare: TransitFare;
  /**
   * An array of LatLngs representing the entire course of this route. The path is simplified in order to make
   * it suitable in contexts where a small number of vertices is required (such as Static Maps API URLs).
   */
  overview_path: LatLngLiteral[];
}

interface TransitFare {
  /** An [ISO 4217 currency code](https://en.wikipedia.org/wiki/ISO_4217) indicating the currency that the amount is expressed in. */
  currency: string;
  /** The total fare amount, in the currency specified above. */
  value: number;
  /** The total fare amount, formatted in the requested language. */
  text: string;
}

type DirectionsStep = Omit<google.maps.DirectionsStep, 'steps'> & {
  /**
   * contains the action to take for the current step (turn left, merge, straight, etc.).
   * This field is used to determine which icon to display.
   */
  maneuver: Maneuver;
  steps: DirectionsStep;
};

export type RouteLeg = Omit<google.maps.DirectionsLeg, 'steps'> & {
  /** contains an array of steps denoting information about each separate step of the leg of the journey. */
  steps: DirectionsStep[];
};

export enum Maneuver {
  turn_slight_left = 'turn-slight-left',
  turn_sharp_left = 'turn-sharp-left',
  uturn_left = 'uturn-left',
  turn_left = 'turn-left',
  turn_slight_right = 'turn-slight-right',
  turn_sharp_right = 'turn-sharp-right',
  uturn_right = 'uturn-right',
  turn_right = 'turn-right',
  straight = 'straight',
  ramp_left = 'ramp-left',
  ramp_right = 'ramp-right',
  merge = 'merge',
  fork_left = 'fork-left',
  fork_right = 'fork-right',
  ferry = 'ferry',
  ferry_train = 'ferry-train',
  roundabout_left = 'roundabout-left',
  roundabout_right = 'roundabout-right',
}

/**
 * Used with MapOptions, mapTypeId attribute.
 * Can change directly using google.maps.Map.setMapTypeId
 */
export enum MapTypeId {
  Roadmap = 'roadmap', // displays the default road map view. This is the default map type.
  Satellite = 'satellite', // displays Google Earth satellite images.
  Hybrid = 'hybrid', // hybrid displays a mixture of normal and satellite views.
  Terrain = 'Terrain', // displays a physical map based on terrain information.
  Coordinate = 'Coordinate', // displays a grid - for dev and testing purposes
}

/**
 * Identifiers used to specify the placement of controls on the map. Controls
 * are positioned relative to other controls in the same layout position.
 * Controls that are added first are positioned closer to the edge of the map.
 */
export const ControlPosition = {
  BOTTOM_CENTER: 11, // Elements are positioned in the center of the bottom row.
  BOTTOM_LEFT: 10, // Elements are positioned in the bottom left and flow towards the middle. Elements are positioned to the right of the Google logo.
  BOTTOM_RIGHT: 12, // Elements are positioned in the bottom right and flow towards the middle. Elements are positioned to the left of the copyrights.
  LEFT_BOTTOM: 6, // Elements are positioned on the left, above bottom-left elements, and flow upwards.
  LEFT_CENTER: 4, // Elements are positioned in the center of the left side.
  LEFT_TOP: 5, // Elements are positioned on the left, below top-left elements, and flow downwards.
  RIGHT_BOTTOM: 9, // Elements are positioned on the right, above bottom-right elements, and flow upwards.
  RIGHT_CENTER: 8, // Elements are positioned in the center of the right side.
  RIGHT_TOP: 7, // Elements are positioned on the right, below top-right elements, and flow downwards.
  TOP_CENTER: 2, // Elements are positioned in the center of the top row.
  TOP_LEFT: 1, // Elements are positioned in the top left and flow towards the middle.
  TOP_RIGHT: 3, // Elements are positioned in the top right and flow towards the middle.
} as const;

export enum StreetViewStatus {
  OK = 'OK',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

export type MapOptions = google.maps.MapOptions;
