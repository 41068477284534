import { put } from 'redux-saga/effects';
import { drawingImageSelectorEditedDrawingFileIdSelector } from '~/store/frontendState/mapTools/drawing/drawingImageSelector/drawingImageSelector.selectors';
import { mapSettingsFileAttachmentsMapSelector } from '~/store/mapSettings/fileAttachments/fileAttachments.selectors';
import { type MapSettingsFileAttachmentsMap } from '~/store/mapSettings/fileAttachments/fileAttachments.state';
import { FileAttachmentTool } from '../../../../_shared/types/file.types';
import {
  createNewFileAttachment, getFileAttachmentId,
} from '../../../../_shared/utils/files/fileAttachments.helpers';
import { select } from '../../../../_shared/utils/saga/effects';
import { DrawingTool } from '../../../../drawingTool/drawingTool.enums';
import { type DrawingItem } from '../../../../store/mapSettings/drawing/items/drawingItems.state';
import { mapSettingsFileAttachmentsAdd } from '../../../../store/mapSettings/fileAttachments/fileAttachments.actionCreators';

export function* updateDrawingToolImageInterceptor(drawingItem: DrawingItem | null) {
  if (drawingItem === null) {
    return null;
  }

  const editedImageFileId: number | null = yield select<number | null>(drawingImageSelectorEditedDrawingFileIdSelector);

  if (drawingItem.type !== DrawingTool.Image || editedImageFileId === null) {
    return drawingItem;
  }

  const fileAttachmentMap: MapSettingsFileAttachmentsMap = yield select<MapSettingsFileAttachmentsMap>(mapSettingsFileAttachmentsMapSelector);

  // activateDrawingImage
  let attachmentId: string;

  const fileAttachment = fileAttachmentMap.get(getFileAttachmentId(editedImageFileId, FileAttachmentTool.Drawing));
  if (fileAttachment) {
    attachmentId = fileAttachment.id;
  }
  else {
    const newFileAttachment = createNewFileAttachment(editedImageFileId, FileAttachmentTool.Drawing);
    yield put(mapSettingsFileAttachmentsAdd(newFileAttachment));
    attachmentId = newFileAttachment.id;
  }

  const updatedDrawingItem: DrawingItem = {
    type: DrawingTool.Image,
    value: {
      ...drawingItem.value,
      attachmentId,
    },
  };

  return updatedDrawingItem;
}
