import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useLatLngSpreadsheetData } from '~/map/map/useSpreadsheetData.hook';
import { useBoundaryTerritoryGroupsIsLoadingSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { useAreMapSettingsReadySelector } from '~/store/mapSettings/ready/mapSettingsReady.selectors';

export const useWaitForDataReady = () => {
  const areMapSettingsReady = useAreMapSettingsReadySelector();
  const initialMarkersRendered = useSelector(s => s.frontendState.processing.initialMarkersRendered);
  const allLatLngData = useLatLngSpreadsheetData();
  const mapHasNoMarkers = useSelector(s => s.spreadsheet.spreadsheetData.isReady) && allLatLngData.data.length === 0;
  const initialBoundariesRendered = useSelector(s => s.frontendState.processing.initialBoundariesRendered);
  const areBTGsLoading = useBoundaryTerritoryGroupsIsLoadingSelector();

  return (mapHasNoMarkers || initialMarkersRendered) && initialBoundariesRendered && areMapSettingsReady && !areBTGsLoading;
};
