import { combineReducers } from 'redux';
import { copy } from '../../_shared/utils/collections/collections';
import { type BoundaryGroup } from '../../boundary/boundary.types';
import { type BoundaryGroupsActions } from './boundaryGroups.actions';
import {
  BOUNDARY_GROUP_CHANGE_NAME,
  BOUNDARY_GROUP_CHANGE_NAME_ERROR,
  BOUNDARY_GROUP_CHANGE_NAME_SUCCESS,
  BOUNDARY_GROUP_CREATE_ERROR,
  BOUNDARY_GROUP_CREATE_REQUEST,
  BOUNDARY_GROUP_CREATE_SUCCESS,
  BOUNDARY_GROUP_FETCH_ALL_ERROR,
  BOUNDARY_GROUP_FETCH_ALL_REQUEST,
  BOUNDARY_GROUP_FETCH_ALL_SUCCESS,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST,
  BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED,
  BOUNDARY_GROUPS_DELETE_ERROR,
  BOUNDARY_GROUPS_DELETE_REQUEST,
  BOUNDARY_GROUPS_DELETE_SUCCESS,
} from './boundaryGroups.actionTypes';
import { type BoundaryGroupsState } from './boundaryGroups.state';

const updateNameByGroupId = (state: ReadonlyArray<BoundaryGroup>, groupId: number, newName: string): ReadonlyArray<BoundaryGroup> => {
  const existingIndex = state.findIndex(group => group.id === groupId);
  if (existingIndex === -1) {
    return state;
  }

  const existingGroup = state[existingIndex];

  if (!existingGroup) {
    return state;
  }

  const updatedGroup = { ...existingGroup, name: newName };

  return copy.andReplace(state, existingIndex, updatedGroup);
};

const groups = (state: ReadonlyArray<BoundaryGroup> = [], action: BoundaryGroupsActions): ReadonlyArray<BoundaryGroup> => {
  switch (action.type) {
    case BOUNDARY_GROUP_FETCH_ALL_SUCCESS: {
      return action.payload.boundaryGroups;
    }

    case BOUNDARY_GROUP_CREATE_SUCCESS: {
      return [...state, action.payload.boundaryGroup];
    }

    case BOUNDARY_GROUP_CHANGE_NAME_SUCCESS: {
      return updateNameByGroupId(state, action.payload.groupId, action.payload.newName);
    }

    case BOUNDARY_GROUPS_DELETE_SUCCESS: {
      return state.filter(group => !action.payload.groupIds.has(group.id));
    }

    default:
      return state;
  }
};

const isLoading = (state: boolean = true, action: BoundaryGroupsActions): boolean => {
  switch (action.type) {
    case BOUNDARY_GROUP_FETCH_ALL_REQUEST:
    case BOUNDARY_GROUP_CREATE_REQUEST:
    case BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_REQUEST: {
      return true;
    }

    case BOUNDARY_GROUP_FETCH_ALL_ERROR:
    case BOUNDARY_GROUP_FETCH_ALL_SUCCESS:
    case BOUNDARY_GROUP_CREATE_ERROR:
    case BOUNDARY_GROUP_CREATE_SUCCESS:
    case BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_ERROR:
    case BOUNDARY_GROUPS_CONVERT_FROM_TERRITORIES_SCHEDULED: {
      return false;
    }

    default:
      return state;
  }
};

const isListingLoading = (state: boolean = false, action: BoundaryGroupsActions): boolean => {
  switch (action.type) {
    case BOUNDARY_GROUP_CHANGE_NAME:
    case BOUNDARY_GROUPS_DELETE_REQUEST:
      return true;
    case BOUNDARY_GROUP_CHANGE_NAME_SUCCESS:
    case BOUNDARY_GROUP_CHANGE_NAME_ERROR:
    case BOUNDARY_GROUPS_DELETE_SUCCESS:
    case BOUNDARY_GROUPS_DELETE_ERROR:
      return false;
    default:
      return state;
  }
};

export const boundaryGroupsReducer = combineReducers({
  groups,
  isLoading,
  isListingLoading,
}) satisfies (...args: unknown[]) => BoundaryGroupsState;
