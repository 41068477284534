import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { select } from '~/_shared/utils/saga/effects';
import {
  selectLatLngSpreadsheetData, type SpreadsheetLatLngRowData,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { spreadsheetDataCheckForDeletedRows } from '~/store/spreadsheetData/spreadsheetData.actionTypes';
import { mapSettingsMarkersRemoveMarkerImages } from '../mapSettingsMarkers.actionCreators';
import { flattenMarkerImages } from '../mapSettingsMarkers.helpers';
import { mapSettingsMarkerImagesSelector } from '../mapSettingsMarkers.selectors';
import { type ReadonlyMarkerImages } from '../mapSettingsMarkers.state';

export function* removeDeletedRowsMarkerImagesSaga() {
  yield takeLatest(spreadsheetDataCheckForDeletedRows.triggerActions, removeDeletedRowsMarkerImages);
}

function* removeDeletedRowsMarkerImages() {
  const proceed: boolean = yield call(spreadsheetDataCheckForDeletedRows.conditionSaga);
  if (!proceed) {
    return;
  }

  const markerImages: ReadonlyMarkerImages = yield select<ReadonlyMarkerImages>(mapSettingsMarkerImagesSelector);
  const spreadsheetData: SpreadsheetLatLngRowData = yield select<SpreadsheetLatLngRowData>(selectLatLngSpreadsheetData);

  const images = flattenMarkerImages(markerImages);

  const imagesWithoutRows = images
    .map(item => ({ spreadsheetId: item.spreadsheetId, rowId: item.rowId }))
    .filter(item => !spreadsheetData.getRow(item));

  if (imagesWithoutRows.length) {
    yield put(mapSettingsMarkersRemoveMarkerImages(imagesWithoutRows));
  }
}
