import {
  createMetricFromResponse, createServerModelFromMetric, type MetricModel,
} from '~/_shared/utils/metric/metrics.factory';
import {
  createEtagFromServerModal, createEtagServerModel,
} from '~/boundary/etag/etag.factory';
import { BoundaryFill } from '~/boundary/settings/boundaryFill.type';
import {
  type BoundaryTerritoryGroupConvertRequest, type BoundaryTerritoryGroupCreateRequest,
  type BoundaryTerritoryGroupMatchingsRequest, type BoundaryTerritoryGroupMatchingsResponse,
  type BoundaryTerritoryGroupModelResponse, type BoundaryTerritoryGroupResponse,
  type BoundaryTerritoryGroupSettingsRequest, type BoundaryTerritoryGroupSettingsResponse,
  type BoundaryTerritoryGroupUpdateRequest, type BoundaryTerritoryRequest, type BoundaryTerritoryResponse,
  type BoundaryTerritoryStyleResponse, type MetricServerModel,
} from './boundaryTerritoryGroups.repository';
import {
  type BoundaryTerritory, type BoundaryTerritoryGroup, type BoundaryTerritoryGroupMatchings,
  type BoundaryTerritoryGroupModel, type BoundaryTerritoryGroupSettings, type BoundaryTerritoryGroupStyle,
  type BoundaryTerritoryStyle,
} from './boundaryTerritoryGroups.state';

export const getBoundaryTerritoryGroupMatchupRequest = (
  request: BoundaryTerritoryGroupMatchings
): BoundaryTerritoryGroupMatchingsRequest => {
  return {
    matching_type: request.matchingType,
    match_columns: request.matchColumns.map(column => ({
      column_id: column.columnId,
      category: column.category,
    })),
  };
};

export const getBoundaryTerritoryGroupMatchupFromRequest = (
  matchupRequest: BoundaryTerritoryGroupMatchingsRequest
): BoundaryTerritoryGroupMatchingsResponse => {
  return {
    matching_type: matchupRequest.matching_type,
    match_columns: matchupRequest.match_columns ?? [],
  };
};

export const createTerritoryBoundaryMatchingsFromResponse = (
  response: BoundaryTerritoryGroupMatchingsResponse
): BoundaryTerritoryGroupMatchings => {
  return {
    matchingType: response.matching_type,
    matchColumns: response.match_columns.map(column => ({
      columnId: column.column_id,
      category: column.category,
    })),
  };
};

export const createTerritoryBoundaryGroupFromResponse = (
  response: BoundaryTerritoryGroupResponse
): BoundaryTerritoryGroup => {
  return {
    boundaryGroupId: response.boundary_group_id,
    boundaryTerritoryGroupId: response.boundary_territory_group_id,
    archivedAt: response.archived_at,
    mapId: response.map_id,
    matchings: createTerritoryBoundaryMatchingsFromResponse(response.matchings),
    settings: createBoundaryTerritoryGroupSettingsFromResponse(response.settings),
    etag: response.etag ? createEtagFromServerModal(response.etag) : undefined,
  };
};

export const createTerritoryBoundaryGroupModelFromResponse = (
  response: BoundaryTerritoryGroupModelResponse
): BoundaryTerritoryGroupModel => {
  return {
    boundaryGroupId: response.boundary_group_id,
    boundaryTerritoryGroupId: response.boundary_territory_group_id,
    archivedAt: response.archived_at,
    mapId: response.map_id,
    matchings: createTerritoryBoundaryMatchingsFromResponse(response.matchings),
    settings: createBoundaryTerritoryGroupSettingsFromResponse(response.settings),
    etag: response.etag ? createEtagFromServerModal(response.etag) : undefined,
  };
};

export const createRequestFromBoundaryTerritoryGroupSettings = (
  settings: BoundaryTerritoryGroupSettings
): BoundaryTerritoryGroupSettingsRequest => {
  return {
    allow_decimal_ranges: settings.allowDecimalRanges,
    boundaries_as_territories: settings.boundariesAsTerritories,
    boundary_fill_type: settings.boundaryFillType,
    boundary_style: getBoundaryTerritorySettingsBoundaryStyleRequest(settings.boundaryStyle),
    boundary_territories: settings.boundaryTerritories.map(territory => createBoundaryTerritoryRequest(territory)),
    boundary_territory_type: settings.boundaryTerritoryType,
    bucket_column_id: settings.bucketColumnId,
    bucket_type: settings.bucketType,
    calculate_bucket_function: settings.calculateBucketFunction,
    demographic_id: settings.demographicId,
    metrics: settings.metrics.map(createServerModelFromMetric).filter((metric): metric is MetricServerModel => !!metric),
    style: createBoundaryTerritoryStyleResponse(settings.style),
  };
};

const createBoundaryTerritoryGroupSettingsFromResponse = (
  settings: BoundaryTerritoryGroupSettingsResponse
): BoundaryTerritoryGroupSettings => {
  const boundaryTerritories = settings.boundary_territories
    .map(item => createBoundaryTerritoryFromResponse(item))
    .sort(sortBoundaryTerritoriesFromResponse);

  return {
    allowDecimalRanges: settings.allow_decimal_ranges ?? false,
    boundariesAsTerritories: settings.boundaries_as_territories,
    boundaryFillType: settings.boundary_fill_type ?? BoundaryFill.DefaultFill,
    boundaryStyle: new Map(settings.boundary_style?.map(item => ([item.boundaryId, item.style]))),
    boundaryTerritories,
    boundaryTerritoryType: settings.boundary_territory_type,
    bucketColumnId: settings.bucket_column_id ?? null,
    bucketType: settings.bucket_type ?? null,
    calculateBucketFunction: settings.calculate_bucket_function,
    demographicId: settings.demographic_id ?? null,
    displayName: settings.display_name,
    emptyIsZero: settings.empty_is_zero,
    metrics: settings.metrics?.map(createMetricFromResponse).filter((metric): metric is MetricModel => !!metric) ?? [],
    rangesCount: settings.ranges_count ?? null,
    style: createBoundaryTerritoryStyleFromResponse(settings.style),
    hideLabels: settings.hide_labels ?? false,
    hideWithoutData: settings.hide_without_data ?? false,
    ignoreFilters: settings.ignore_filters ?? false,
    selectionEditPreferredBoundaryGroup: settings.selection_edit_preferred_boundary_group,
  };
};

export const createBoundaryTerritoryFromResponse = (
  response: BoundaryTerritoryResponse
): BoundaryTerritory => {
  if (response.custom) {
    return {
      boundaryTerritoryId: response.boundary_territory_id,
      displayName: response.display_name,
      custom: response.custom,
      translate: response.translate ?? false,
      style: response.style,
    };
  }
  return {
    boundaryTerritoryId: response.boundary_territory_id,
    displayName: response.display_name,
    bucket: response.bucket,
    custom: response.custom,
    style: response.style,
    translate: response.translate ?? false,
  };
};

export const createBoundaryTerritoryStyleResponse = (
  boundaryTerritoryStyle: BoundaryTerritoryGroupStyle
): BoundaryTerritoryStyleResponse => {
  return {
    gradient_colors: boundaryTerritoryStyle.gradientColors,
    line_color: boundaryTerritoryStyle.lineColor,
    line_width: boundaryTerritoryStyle.lineWidth,
  };
};

export const createBoundaryTerritoryStyleFromResponse = (
  response: BoundaryTerritoryStyleResponse
): BoundaryTerritoryGroupStyle => {
  return {
    gradientColors: response.gradient_colors,
    lineColor: response.line_color,
    lineWidth: response.line_width,
  };
};

const createBoundaryTerritoryRequest = (
  territory: BoundaryTerritory
): BoundaryTerritoryRequest => {
  const request: BoundaryTerritoryRequest = {
    boundary_territory_id: territory.boundaryTerritoryId,
    style: territory.style,
    custom: territory.custom,
    display_name: territory.displayName,
    translate: territory.translate,
  };

  if (!territory.custom) {
    request.bucket = territory.bucket;
  }

  return request;
};

const getBoundaryTerritorySettingsBoundaryStyleRequest = (boundaryStyle: ReadonlyMap<number, BoundaryTerritoryStyle>) => {
  return Array.from(boundaryStyle).map(([boundaryId, boundaryItemStyle]) => ({
    boundaryId,
    style: boundaryItemStyle,
  }));
};

export const createBoundaryTerritorySettingsRequestFromBoundaryTerritorySettings = (
  settings: BoundaryTerritoryGroupSettings
): BoundaryTerritoryGroupSettingsRequest => {
  return {
    allow_decimal_ranges: settings.allowDecimalRanges,
    boundaries_as_territories: settings.boundariesAsTerritories,
    boundary_fill_type: settings.boundaryFillType,
    boundary_style: getBoundaryTerritorySettingsBoundaryStyleRequest(settings.boundaryStyle),
    boundary_territories: settings.boundaryTerritories.map(territory => createBoundaryTerritoryRequest(territory)),
    boundary_territory_type: settings.boundaryTerritoryType,
    bucket_column_id: settings.bucketColumnId,
    bucket_type: settings.bucketType,
    calculate_bucket_function: settings.calculateBucketFunction,
    demographic_id: settings.demographicId,
    metrics: settings.metrics.map(createServerModelFromMetric).filter((metric): metric is MetricServerModel => !!metric),
    ranges_count: settings.rangesCount ?? undefined,
    style: createBoundaryTerritoryStyleResponse(settings.style),
    hide_labels: settings.hideLabels,
    hide_without_data: settings.hideWithoutData,
    ignore_filters: settings.ignoreFilters,
    selection_edit_preferred_boundary_group: settings.selectionEditPreferredBoundaryGroup,
  };
};

export const createBoundaryTerritoryCreateRequestFromBoundaryTerritoryGroupModel = (
  boundaryTerritoryGroup: BoundaryTerritoryGroupModel
): BoundaryTerritoryGroupCreateRequest => ({
  boundary_group_id: boundaryTerritoryGroup.boundaryGroupId,
  map_id: boundaryTerritoryGroup.mapId,
  matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
  settings: createBoundaryTerritorySettingsRequestFromBoundaryTerritorySettings(boundaryTerritoryGroup.settings),
});

export const createBoundaryTerritoryConvertRequestFromBoundaryTerritoryGroupModel = (
  boundaryTerritoryGroup: BoundaryTerritoryGroupModel
): BoundaryTerritoryGroupConvertRequest => ({
  boundary_territory_group_id: boundaryTerritoryGroup.boundaryTerritoryGroupId,
  boundary_group_id: boundaryTerritoryGroup.boundaryGroupId,
  map_id: boundaryTerritoryGroup.mapId,
  matchings: getBoundaryTerritoryGroupMatchupRequest(boundaryTerritoryGroup.matchings),
  settings: createBoundaryTerritorySettingsRequestFromBoundaryTerritorySettings(boundaryTerritoryGroup.settings),
});

export const createBoundaryTerritoryUpdateRequestFromBoundaryTerritoryGroup = (
  boundaryTerritoryGroup: BoundaryTerritoryGroup,
): BoundaryTerritoryGroupUpdateRequest => ({
  boundary_territory_group_id: boundaryTerritoryGroup.boundaryTerritoryGroupId,
  etag: boundaryTerritoryGroup.etag ? createEtagServerModel(boundaryTerritoryGroup.etag) : undefined,
  ...createBoundaryTerritoryCreateRequestFromBoundaryTerritoryGroupModel(boundaryTerritoryGroup),
});

export const getBoundaryTerritoryGroupSettingsFromRequest = (
  updateRequestSettings: BoundaryTerritoryGroupSettingsRequest,
  boundaryTerritories: BoundaryTerritoryResponse[],
): BoundaryTerritoryGroupSettingsResponse => {
  return {
    ...updateRequestSettings,
    boundaries_as_territories: false,
    calculate_bucket_function: 'sum',
    display_name: null,
    boundary_territories: boundaryTerritories,
    empty_is_zero: null,
    demographic_id: updateRequestSettings.demographic_id ?? null,
  };
};

export const sortBoundaryTerritoriesFromResponse = (a: BoundaryTerritory, b: BoundaryTerritory) =>
  a.custom && !b.custom ? 1 : !a.custom && b.custom ? -1 : 0;
