export enum ClientLicenseType {
  TRIAL = 'trial',
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
  '45_DAYS' = '45_days',
}

export enum ClientLicenseState {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  FAILED_PAY_PERIOD = 'failed_pay_period',
  GRACE_PERIOD = 'grace_period',
}

export type ClientLicenseName = string;

export enum ClientLicenseCode {
  TRIAL = 'trial',
  MAPTIVE_INDIVIDUAL_MONTHLY = 'maptive-individual-monthly-plan',
  MAPTIVE_INDIVIDUAL_ANNUAL = 'maptive-individual-annual-plan',
  MAPTIVE_TEAM_MONTHLY = 'maptive-team-monthly-plan',
  MAPTIVE_TEAM_ANNUAL = 'maptive-team-annual-plan',
  MAPTIVE_INDIVIDUAL_45_DAYS = 'maptive-45-day-plan',
}

export const isPaidLicense = (license: ClientLicenseType | null | undefined): boolean => license !== ClientLicenseType.TRIAL;
