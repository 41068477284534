import { type MapInfo } from '../../../_shared/types/map';
import { select } from '../../../_shared/utils/saga/effects';
import { mapInfoDataSelector } from '../../mapInfo/mapInfo.selectors';
import { mapSettingsDataSelector } from '../../mapSettings/data/mapSettingsData.selectors';
import { type MapSettingsDataState } from '../../mapSettings/data/mapSettingsData.state';
import {
  getAllFilterTreeRequestsFromMapSettings,
  getCombinedFilterTreeForSpreadsheetId,
} from '../filtering/spreadsheetDataFiltering.helpers';
import { getFilterTreeParamsFromMapSettings } from '../filtering/useFilterTreeMapSettingsParams';

export function* getStoreFilterTree() {
  const mapSettingsData: MapSettingsDataState = yield select<MapSettingsDataState>(mapSettingsDataSelector);
  const mapInfoData: MapInfo | null = yield select<MapInfo | null>(mapInfoDataSelector);
  const primarySpreadsheetId = mapInfoData?.spreadsheets.find(item => item.isPrimary)?.spreadSheetId ?? null;

  if (primarySpreadsheetId === null) {
    return null;
  }

  const filterTreeParams = getFilterTreeParamsFromMapSettings(mapSettingsData);
  const allFilterTreeRequests = getAllFilterTreeRequestsFromMapSettings(filterTreeParams, [primarySpreadsheetId]);
  return getCombinedFilterTreeForSpreadsheetId(allFilterTreeRequests, primarySpreadsheetId);
}
