import { useState } from 'react';
import { useIsComponentMountedRef } from '../../_shared/utils/hooks/useIsComponentMountedRef';
import { addSpreadsheetColumn } from '../../spreadsheet/spreadsheet.repository';

export const useAddNewDataColumn = () => {
  const isMountedRef = useIsComponentMountedRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const addNewDataColumn = (clientId: number, spreadsheetId: number, columnName: string, defaultValue: string): Promise<{message: string}> => {
    setIsLoading(true);
    setIsError(false);

    return addSpreadsheetColumn(clientId, spreadsheetId, {
      column: columnName,
      default: defaultValue,
      type: 'text',
    })
      .catch((e) => {
        if (isMountedRef.current) {
          setIsError(true);
        }
        throw Error(e);
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  };

  return {
    isLoading,
    isError,
    addNewDataColumn,
  };
};
