import { useMemo } from 'react';
import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { hasFeatureOrToolAllowedPresentationalMapExport } from '~/_shared/types/toolsAndFeatures/mapToolsAndFeatures.helpers';
import { type ToolOrFeatureWithDataExport } from '~/_shared/types/toolsAndFeatures/toolsAndFeaturesWithDataExport.types';
import { usePublicMapSettingsAllowedExportDataToolsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';

const isExportAllowed = (
  isMapPresentational: boolean,
  allowedExportDataTools: ToolOrFeatureWithDataExport[]
): boolean => (!isMapPresentational || (hasFeatureOrToolAllowedPresentationalMapExport(
  MapTool.Proximity,
  allowedExportDataTools
)));

export const useIsProximityExportAllowed = (): boolean => {
  const allowedPresentationalMapTools = usePublicMapSettingsAllowedExportDataToolsSelector();
  const isMapPresentational = useIsMapPresentationalSelector();

  return useMemo((): boolean => (
    isExportAllowed(isMapPresentational, allowedPresentationalMapTools)
  ), [allowedPresentationalMapTools, isMapPresentational]);
};
