import { type ElementPropsWithElementRefAndRenderer } from 'react-scrollbars-custom/dist/types/types';
import { zoomLevel } from 'zoom-level';
import { isNumber } from '~/_shared/utils/typeGuards';

export const isBrowser =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined';

let pageZoomLevel: number = isBrowser ? zoomLevel() : 1;
if (isBrowser) {
  window.addEventListener(
    'resize',
    () => {
      pageZoomLevel = zoomLevel();
    },
    { passive: true }
  );
}

const extraPadding = () => Number(pageZoomLevel !== 1);
const ensurePx = (cssProp: string | number) => isNumber(cssProp) ? `${cssProp}px` : cssProp;

// fix for https://app.asana.com/0/1203748764886979/1205978188913998
export const scrollerMarginFixProps: ElementPropsWithElementRefAndRenderer = {
  renderer: (props) => {
    const { elementRef, style, key, ...restProps } = props;

    const styleWithMarginFix = style?.overflowY !== 'scroll' ? style : {
      ...style,
      paddingLeft: style.paddingLeft && `calc(${ensurePx(style.paddingLeft)} + ${extraPadding()}px)`,
      paddingRight: style.paddingRight && `calc(${ensurePx(style.paddingRight)} + ${extraPadding()}px)`,
    } as const;

    return (
      <div
        key={key}
        {...restProps}
        style={styleWithMarginFix}
        ref={elementRef}
        className="ScrollbarsCustom-Scroller"
      />
    );
  },
};
