import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { UnitSystem } from '../../types/googleMaps/googleMaps.types';
import { type TranslationFnc } from '../hooks';

export const getUnitSystemOptions = (t: TranslationFnc): DropdownOption<UnitSystem>[] => {
  return [{
    name: getShortUnitSystemLabel(UnitSystem.imperial, t),
    value: UnitSystem.imperial,
  }, {
    name: getShortUnitSystemLabel(UnitSystem.metric, t),
    value: UnitSystem.metric,
  }];
};

export const getShortUnitSystemLabel = (unit: UnitSystem, t: TranslationFnc): string => {
  switch (unit) {
    case UnitSystem.metric:
      return t('km');
    case UnitSystem.imperial:
    default:
      return t('mi');
  }
};

export const convertUnitToMeters = (amount: number, unit: UnitSystem) => {
  switch (unit) {
    case UnitSystem.metric:
      return amount * 1000;
    case UnitSystem.imperial:
      return amount * 1609.344;
    default:
      throw new Error(`MapProximityManager: Unknown unit type ${unit}.`);
  }
};

export const convertMetersToUnit = (amount: number, unit: UnitSystem) => {
  switch (unit) {
    case UnitSystem.metric:
      return amount / 1000;
    case UnitSystem.imperial:
      return amount / 1609.344;
    default:
      throw new Error(`unitSystem helper, convertMetersToUnit: Unknown unit type ${unit}.`);
  }
};

export const convertUnitToKilometers = (amount: number, unit: UnitSystem) => {
  if (unit === UnitSystem.imperial) {
    return amount * 1.60934;
  }
  return amount;
};

type UnitSymbols = {
  readonly unit: string;
  readonly subunit: string;
};
const metricUnits: UnitSymbols = {
  unit: 'km',
  subunit: 'm',
};
const imperialUnits: UnitSymbols = {
  unit: 'mi',
  subunit: 'ft',
};
export const getUnitSymbols = (unitSystem: UnitSystem): UnitSymbols => {
  return (unitSystem === UnitSystem.imperial) ? imperialUnits : metricUnits;
};
