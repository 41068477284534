import {
  ERROR_PAGE_CLEAR_ERROR, ERROR_PAGE_SET_ERROR,
} from './errorPage.actionTypes';
import { type ErrorPageType } from './errorPage.state';

export const errorPageSetError = (error: ErrorPageType) => ({
  type: ERROR_PAGE_SET_ERROR,
  payload: {
    error,
  },
}) as const;

export const errorPageClearError = () => ({
  type: ERROR_PAGE_CLEAR_ERROR,
}) as const;
