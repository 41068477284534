import {
  type GroupingColumn, GroupingType, NumericActiveGroupColumnType,
} from './grouping';

export const getNumericActiveGroupColumnType = (
  activeGroupColumns: readonly GroupingColumn[]
): NumericActiveGroupColumnType | null => {
  if (activeGroupColumns.length === 0) {
    return null;
  }

  if (
    activeGroupColumns.length === 2
    && activeGroupColumns[0].type === GroupingType.Numeric
    && activeGroupColumns[1].type === GroupingType.Numeric
  ) {
    return NumericActiveGroupColumnType.DoubleNumeric;
  }

  if (
    activeGroupColumns[0].type === GroupingType.Numeric
    || activeGroupColumns[1]?.type === GroupingType.Numeric
  ) {
    return NumericActiveGroupColumnType.SingleNumeric;
  }

  return null;
};
