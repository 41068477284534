import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { FetchableResourceStatus } from '~/_shared/types/fetchableResource/fetchableResource';
import {
  getLoadingStateFetchableResource, isResourceLoaded,
} from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import {
  newPerSpreadsheetMap, type PerSpreadsheet,
} from '~/_shared/types/spreadsheet/spreadsheet.types';
import { type SpreadsheetCell } from '~/_shared/types/spreadsheetData/spreadsheetCell';
import {
  type ColumnId,
  type PerColumn, type SpreadsheetColumnId,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import {
  type CombinedRowId, type SpreadsheetRowId,
} from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useIsComponentMountedRef } from '~/_shared/utils/hooks/useIsComponentMountedRef';
import { spreadsheetColumnIdToString } from '~/_shared/utils/spreadsheet/generalSpreadsheet.helpers';
import { isNullsy } from '~/_shared/utils/typeGuards';
import { getBaseMapNameFromId } from '~/map/layered/layering.helpers';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { useSpreadsheetTableDataETagSelector } from '~/store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { useBaseMapNames } from '~/store/mapInfo/mapInfo.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { updateSpreadsheetCellData } from '~/store/spreadsheetCellData/spreadsheetCellData.actionCreators';
import { copySpreadsheetCellDataPerSpreadsheet } from '~/store/spreadsheetCellData/spreadsheetCellData.helpers';
import { useSpreadsheetCellDataSelector } from '~/store/spreadsheetCellData/spreadsheetCellData.selectors';
import {
  type FetchableSpreadsheetCell, type FetchableSpreadsheetRow, type PerRowFetchableSpreadsheetRows, type SpreadsheetCellData,
} from '~/store/spreadsheetCellData/spreadsheetCellData.state';
import { usePresentationalColumnsRestrictionsMatchup } from '../presentationalColumnsRestrictions/usePresentationalColumnsRestrictionsMatchup';
import { fetchRowData } from './fetchRowData';
import { MAXIMUM_RAW_DATA_MARKERS } from './spreadsheet.repository';

export type SpreadsheetRowData = {
  baseMapId: number | null;
  columnsData: PerColumn<SpreadsheetCell>;
  version: number;
  rowId: CombinedRowId;
};

/*
 * The purpose of this hook is to get raw spreadsheet data
 * Currently the limit is MAXIMUM_RAW_DATA_MARKERS locations (per page limit of the BE /raw data call)
 * @param spreadsheetRowIdOrIds can be of types: SpreadsheetRowId
 *                                               SpreadsheetRowId[] (remember to memoize the spreadsheetRowIdOrIds prop!)
 * @param skipDataProcessing indicates whether we want to post-process data like Base Map Id to show Base Map Name instead
 *
 * TODO: either enforce the MAXIMUM_RAW_DATA_MARKERS limit, OR split into multiple BE calls
 */

export const useSpreadsheetRowData = (spreadsheetRowIdOrIds: SpreadsheetRowId | SpreadsheetRowId[] | undefined | null, columnIds?: SpreadsheetColumnId[], skipDataProcessing?: boolean) => {
  const dispatch = useDispatch();
  const clientId = useClientIdSelector();
  const mapId = useMapIdSelector() || undefined;
  const isMountedRef = useIsComponentMountedRef();
  const matchupData = useMatchupDataSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const cachedSpreadsheetCellData = useSpreadsheetCellDataSelector();
  const spreadsheetTableDataETag = useSpreadsheetTableDataETagSelector();
  const restrictionsMatchup = usePresentationalColumnsRestrictionsMatchup();
  const baseMapsIfLayered = useBaseMapNames();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const spreadsheetRowIds: SpreadsheetRowId[] | undefined = useMemo(() => (
    Array.isArray(spreadsheetRowIdOrIds) ? spreadsheetRowIdOrIds.slice(0, MAXIMUM_RAW_DATA_MARKERS)
      : isNullsy(spreadsheetRowIdOrIds) ? undefined
        : (spreadsheetRowIdOrIds.rowId && spreadsheetRowIdOrIds.spreadsheetId) ? [spreadsheetRowIdOrIds]
          : undefined
  ), [spreadsheetRowIdOrIds]);

  const allColumnIdsPerSpreadsheet = useMemo(() => {
    const allSpreadsheetIds = new Set<number>(spreadsheetRowIds?.map(srId => srId.spreadsheetId) || []);
    return Array.from(allSpreadsheetIds).reduce<PerSpreadsheet<ColumnId[]>>((result, spreadsheetId) => {
      const matchupDataForSpreadsheet = matchupData[spreadsheetId];
      if (matchupDataForSpreadsheet && !matchupDataForSpreadsheet.isLoading && matchupDataForSpreadsheet.data) {
        result[spreadsheetId] = Object.entries(matchupDataForSpreadsheet.data.columns).map(([columnId]) => columnId);
      }
      else {
        // if we don't know the column ids for specific spreadsheet, we'll need to ask for all of them
        result[spreadsheetId] = [];
      }
      return result;
    }, newPerSpreadsheetMap());
  }, [matchupData, spreadsheetRowIds]);

  const dataCache = useMemo(() => {
    if (!spreadsheetRowIds) {
      return null;
    }

    // get data for all spreadsheet rows based on props
    const spreadsheetCellData: SpreadsheetCellData = newPerSpreadsheetMap();
    // also figure out which are not even in a loading state yet, so we can fetch them
    const missingSpreadsheetRowIds: SpreadsheetRowId[] = [];
    const loadingSpreadsheetRowIds: SpreadsheetRowId[] = [];

    for (const spreadsheetRowId of spreadsheetRowIds) {
      const spreadsheetId = spreadsheetRowId.spreadsheetId;
      const rowId = spreadsheetRowId.rowId;
      const cachedRow = cachedSpreadsheetCellData[spreadsheetId]?.get(rowId);
      if (cachedRow) {
        if (cachedRow.status !== FetchableResourceStatus.Loaded) {
          // ignore data that are being loaded right now, or are unable to fetch
          // requesting to refetch for such statuses would create a loop and crash the app
          if (cachedRow.status === FetchableResourceStatus.Loading) {
            loadingSpreadsheetRowIds.push(spreadsheetRowId);
          }
          continue;
        }

        // if all the columns are loaded for the row, no more checks are needed, they will be added to the result
        if (!cachedRow.value?.allColumnsLoaded) {
          const columnIdsToRetrive = columnIds?.filter(columnId => columnId.spreadsheetId === spreadsheetId).map(columnId => columnId.columnId)
          || (allColumnIdsPerSpreadsheet[spreadsheetId] || []);
          if (columnIdsToRetrive.length) {
          // if we need only selected columns, or we know all of the columns of a spreadsheet
          // then we can just check if we have them cached, if not return null
            if (cachedRow.value) {
              const hasAllCellValues = columnIdsToRetrive.every(columnId => cachedRow.value?.columnsData[columnId]);
              if (!hasAllCellValues) {
                missingSpreadsheetRowIds.push(spreadsheetRowId);
                continue;
              }
            }
          }
          else {
            // if we don't know columns for a given spreadsheet, and we need all of them
            missingSpreadsheetRowIds.push(spreadsheetRowId);
            continue;
          }
        }
        // if we got here it means we have all the required columnms, so let's add the row to the result
        if (!spreadsheetCellData[spreadsheetId]) {
          spreadsheetCellData[spreadsheetId] = new Map<CombinedRowId, FetchableSpreadsheetRow>();
        }
        spreadsheetCellData[spreadsheetId]?.set(rowId, cachedRow);
      }
      else {
        missingSpreadsheetRowIds.push(spreadsheetRowId);
        continue;
      }
    }
    return {
      spreadsheetCellData,
      missingSpreadsheetRowIds,
      loadingSpreadsheetRowIds,
      loadingOrMissingRowIds: missingSpreadsheetRowIds.concat(loadingSpreadsheetRowIds),
    };
  }, [allColumnIdsPerSpreadsheet, cachedSpreadsheetCellData, columnIds, spreadsheetRowIds]);

  /* spreadsheetCellData consist of
   *   - cached data
   *   - rows from props.spreadsheetRowIdOrIds which are not cached, in Loading state
   */
  const spreadsheetCellData = useMemo(() => (
    dataCache?.loadingOrMissingRowIds.length
      ? dataCache.loadingOrMissingRowIds.reduce((acc, neededColumnId) => {
        if (!acc[neededColumnId.spreadsheetId]) {
          acc[neededColumnId.spreadsheetId] = new Map();
        }
        acc[neededColumnId.spreadsheetId]?.set(neededColumnId.rowId, getLoadingStateFetchableResource());
        return acc;
      }, copySpreadsheetCellDataPerSpreadsheet(dataCache.spreadsheetCellData || {}))
      : dataCache?.spreadsheetCellData
  ), [dataCache]);

  const unrestrictedColumnsSpreadsheetCellData = useMemo(() => {
    if (!spreadsheetCellData) {
      return null;
    }

    return Object.entries(spreadsheetCellData).reduce<SpreadsheetCellData>((perSpreadsheetAcc, [spreadsheetId, spreadsheetDataPerRowMap]) => {
      if (spreadsheetDataPerRowMap) {

        const unrestrictedColumnDataPerRow = Array.from(spreadsheetDataPerRowMap.entries()).reduce<PerRowFetchableSpreadsheetRows>((perRowAcc, [rowId, rowData]) => {
          if (isResourceLoaded(rowData)) {
            // if row is loaded, filter only unrestricted columns for further processing

            const unrestrictedColumnDataPerColumn = Object.entries(rowData.value.columnsData).reduce<PerColumn<FetchableSpreadsheetCell>>((perColumnAcc, [columnId, cellData]) => {
              const spreadsheetColumnId: SpreadsheetColumnId = {
                columnId,
                spreadsheetId: +spreadsheetId,
              };

              const baseMapId = baseMapsIfLayered ? cellData.value : null;
              const processedCellData = !skipDataProcessing && columnId === BASE_MAPS_COLUMN_ID && isResourceLoaded(cellData)
                ? {
                  status: FetchableResourceStatus.Loaded,
                  value: getBaseMapNameFromId(baseMapId, baseMapsIfLayered),
                }
                : cellData;

              const restrictions = restrictionsMatchup.get(spreadsheetColumnIdToString(spreadsheetColumnId));
              const isRestrictionForColumn = isMapPresentational && restrictions && (restrictions.master || restrictions.marker);

              if (!isRestrictionForColumn) {
                // if the column is not restricted, put it in with the results
                perColumnAcc[columnId] = processedCellData;
              }
              else {
                // else mark it as forbidden
                perColumnAcc[columnId] = {
                  status: FetchableResourceStatus.Forbidden,
                };
              }

              return perColumnAcc;
            }, {});

            perRowAcc.set(rowId, {
              ...rowData,
              value: {
                ...rowData.value,
                columnsData: unrestrictedColumnDataPerColumn,
              },
            });
          }
          else {
            // if row is not loaded, return it in it's current state
            perRowAcc.set(rowId, rowData);
          }

          return perRowAcc;
        }, new Map());

        perSpreadsheetAcc[+spreadsheetId] = unrestrictedColumnDataPerRow;
      }

      return perSpreadsheetAcc;
    }, newPerSpreadsheetMap());
  }, [isMapPresentational, restrictionsMatchup, spreadsheetCellData, baseMapsIfLayered, skipDataProcessing]);

  // should contain the rows in the same order as prop.spreadsheetRowIds
  const unrestrictedColumnsSpreadsheetCellDataAsArray: [SpreadsheetRowId, FetchableSpreadsheetRow][] | null = useMemo(() => {
    if (!spreadsheetRowIds || !unrestrictedColumnsSpreadsheetCellData) {
      return null;
    }

    return spreadsheetRowIds.map(spreadsheetRowId => {
      const rowData = unrestrictedColumnsSpreadsheetCellData[spreadsheetRowId.spreadsheetId]
        ?.get(spreadsheetRowId.rowId);
      if (rowData) {
        return [spreadsheetRowId, rowData];
      }
      else {
        // this should not happen, otherwise spreadsheetCellData would return null
        // if it does, we just assume that something failed to load
        return [spreadsheetRowId, {
          status: FetchableResourceStatus.NotFound,
        }];
      }
    });
  }, [spreadsheetRowIds, unrestrictedColumnsSpreadsheetCellData]);

  const oneRow: { isLoading: boolean; rowData: SpreadsheetRowData | null } = useMemo(() => {
    if (spreadsheetCellData && unrestrictedColumnsSpreadsheetCellDataAsArray?.[0]?.[1]) {
      const firstRow = unrestrictedColumnsSpreadsheetCellDataAsArray[0][1];
      if (isResourceLoaded(firstRow)) {
        const columnsData = firstRow.value.columnsData;
        const areAllCellsLoaded = !Object.entries(columnsData).some(
          ([_, fetchableCellValue]) => (
            fetchableCellValue?.status !== FetchableResourceStatus.Loaded
            && fetchableCellValue?.status !== FetchableResourceStatus.NotFound
          )
        );
        if (areAllCellsLoaded) {
          return {
            isLoading: false,
            rowData: {
              baseMapId: firstRow.value.baseMapId,
              columnsData: Object.entries(columnsData).reduce((acc, [columnId, fetchableCellValue]) => {
                acc[columnId] = fetchableCellValue && isResourceLoaded(fetchableCellValue)
                  ? fetchableCellValue.value : null;
                return acc;
              }, {} as PerColumn<SpreadsheetCell>),
              version: firstRow.value.version,
              rowId: firstRow.value.rowId,
            },
          };
        }
      }
      else if (firstRow.status === FetchableResourceStatus.NotFound) {
        return { isLoading: false, rowData: null };
      }
    }

    return { isLoading: true, rowData: null };
  }, [spreadsheetCellData, unrestrictedColumnsSpreadsheetCellDataAsArray]);

  const fetchRows = useCallback((spreadsheetRowIds: SpreadsheetRowId[], columnIds?: SpreadsheetColumnId[]) => {
    if (!clientId) {
      return;
    }

    setIsLoading(true);
    setIsError(false);

    // update the cached rows with rows which are about to be loaded with "loading" status
    const loadingRowsAsSpreadsheetCellData = spreadsheetRowIds.reduce<SpreadsheetCellData>((acc, spreadsheetRowId) => {
      if (!acc[spreadsheetRowId.spreadsheetId]) {
        acc[spreadsheetRowId.spreadsheetId] = new Map<CombinedRowId, FetchableSpreadsheetRow>();
      }
      acc[spreadsheetRowId.spreadsheetId]?.set(spreadsheetRowId.rowId, getLoadingStateFetchableResource());
      return acc;
    }, {});
    dispatch(updateSpreadsheetCellData(loadingRowsAsSpreadsheetCellData));

    fetchRowData({ clientId, mapId, spreadsheetRowIds, columnIds })
      .then(spreadsheetCellData => {
        dispatch(updateSpreadsheetCellData(spreadsheetCellData));
      })
      .catch(() => {
        // TODO: consider dispatch(updateSpreadsheetCellData(
        //  loadingRowsAsSpreadsheetCellData - each row as FetchableResourceStatus.Failed
        // ));
        if (isMountedRef.current) {
          setIsError(true);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      });
  }, [clientId, dispatch, isMountedRef, mapId]);

  useEffect(() => {
    if (isLoading || !dataCache || dataCache.missingSpreadsheetRowIds.length === 0) {
      return;
    }
    fetchRows(dataCache.missingSpreadsheetRowIds, columnIds);
  }, [dataCache, columnIds, fetchRows, isLoading, spreadsheetRowIds, spreadsheetTableDataETag]);

  return {
    isError,
    spreadsheetCellData: unrestrictedColumnsSpreadsheetCellData,
    spreadsheetRowsList: unrestrictedColumnsSpreadsheetCellDataAsArray,
    // this is to be used when we expect only one row and don't care about per-cell loading status
    oneRow,
  };
};
