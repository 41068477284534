import {
  DRAWING_TOOL_SLIDER_RADIUS_RANGE, DrawingTool,
} from './drawingTool.enums';

export const DRAWING_TOOL_DEFAULT_TOOL = DrawingTool.Arrow;

export const DRAWING_TOOL_NUMBER_MIN_VALUE = -9999999999;
export const DRAWING_TOOL_NUMBER_MAX_VALUE = 9999999999;

export const DRAWING_TOOL_NUMBER_MAX_RADIUS = DRAWING_TOOL_SLIDER_RADIUS_RANGE.to;
export const DRAWING_TOOL_NUMBER_MIN_RADIUS = DRAWING_TOOL_SLIDER_RADIUS_RANGE.from;

export const DRAWING_TOOL_CIRCLE_MAX_RADIUS = 2500;
export const DRAWING_TOOL_CIRCLE_MIN_RADIUS = 4;
