import { Page } from '~/_shared/constants/routes';
import { type MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { type SidebarApp } from '~/sidebar/sidebarApp.enum';

export type PageWithMapTools = Page.ADMIN_MAP | Page.ADMIN_DATA;
export const isPageWithMapTools = (page: Maybe<Page>): page is PageWithMapTools => {
  return page === Page.ADMIN_MAP || page === Page.ADMIN_DATA;
};

export type MapToolsPerPage = Record<PageWithMapTools, MapTool | null>;

export type LeftSidebarState = Readonly<{
  lastOpenedMapPageTool: MapToolsPerPage;
  openedApp: SidebarApp | null;
  openedMapTool: MapTool | null;
}>;
