import {
  css, keyframes,
} from '@emotion/react';
import { type FC } from 'react';

const fadingMatrix = [
  [0, 0, 0],
  [1, 0, 0],
  [1, 1, 0],
  [1, 1, 1],
] as const;

const dotFading = (dotIndex: 0 | 1 | 2) => {
  return keyframes({
    from: {
      opacity: fadingMatrix[0][dotIndex].toString(),
    },
    '25%': {
      opacity: fadingMatrix[1][dotIndex].toString(),
    },
    '50%': {
      opacity: fadingMatrix[2][dotIndex].toString(),
    },
    '75%': {
      opacity: fadingMatrix[3][dotIndex].toString(),
    },
    to: {
      opacity: fadingMatrix[0][dotIndex].toString(),
    },
  });
};

const dotStyle = (dotIndex: 0 | 1 | 2) => css({
  animation: `${dotFading(dotIndex)} 3s linear infinite`,
});

export const FadingDots: FC = () => {
  return (
    <>
      <span css={dotStyle(0)}>.</span>
      <span css={dotStyle(1)}>.</span>
      <span css={dotStyle(2)}>.</span>
    </>
  );
};
