import { isTextEmpty } from '../../../_shared/utils/text/text.helpers';
import { validateEmail } from '../../../_shared/utils/throttle/validation/validation.helpers';

export const validateLoginForm = (email: string, password: string): boolean => {
  return !(isTextEmpty(email) || isTextEmpty(password) || !validateEmail(email));
};

export const validate2FaLoginForm = (code: string): boolean => {
  return !(isTextEmpty(code) || code.length !== 6);
};
