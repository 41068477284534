import { TinyMapColorTileComponent } from '~/_shared/baseComponents/tinyMapPlaceholder';
import { hexAndOpacityToRGBA } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type MultiPolygon } from '~/_shared/types/polygon/polygon.types';
import { displayNameComparer } from '~/store/boundaryItems/boundaryItems.helpers';
import { type ZoomLevel } from '~/store/boundaryItems/boundaryItems.state';
import type { BoundaryTerritoryStyle } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.state';

export const getTotalNumberOfBoundaryPathsPolygons = (paths: Map<ZoomLevel, MultiPolygon>) => (Math.max(
  ...Array.from(paths.values()).map((multiPolygon) => multiPolygon.length),
));

type LineStyle = Readonly<{
  color: string;
  width: number;
}>;
const tinyMapStyle = { marginRight: 8 };
const getMapMiniature = (color: string, lineStyle: LineStyle) => (
  <TinyMapColorTileComponent
    color={hexAndOpacityToRGBA(color)}
    lineColor={hexAndOpacityToRGBA(lineStyle.color)}
    lineWidth={Math.ceil(lineStyle.width / 2)}
    css={tinyMapStyle}
    size="Small"
  />
);

type BoundaryDisplayInfo<TId> = {
  displayName: string;
  id: TId;
};
export const boundariesToDropDownOptions = <TId extends string | number>(boundaries: readonly BoundaryDisplayInfo<TId>[], territoryGroupLineStyle: LineStyle, boundaryFillColors: ReadonlyMap<TId, BoundaryTerritoryStyle>) => {

  return [...boundaries]
    .sort(displayNameComparer)
    .map(boundary => {
      const boundaryFillColor = boundaryFillColors.get(boundary.id);
      const prefix = boundaryFillColor ? getMapMiniature(boundaryFillColor.color, territoryGroupLineStyle) : undefined;
      return ({
        name: boundary.displayName,
        value: boundary.id,
        prefix,
      });
    });
};
