import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { type ReactNode } from 'react';
import { type AppState } from '../../../store/app.store';
import {
  type MapButtonsSettings, type MapColorListSettings, type MapColorSettings, type MapDropDownSettings, type MapSliderSettings,
} from './settingsAccordion.types';

export enum SettingsAccordionSubItemType {
  Button = 'Button',
  ColorPicker = 'ColorPicker',
  ColorList = 'ColorList',
  DropDown = 'DropDown',
  Slider = 'Slider',
  Toggle = 'Toggle',
}

type SubItemState = Readonly<{
  type: SettingsAccordionSubItemType.Button;
  textSelector: (state: AppState) => ReactNode;
}> & MapButtonsSettings
| Readonly<{
  type: SettingsAccordionSubItemType.DropDown;
}> & MapDropDownSettings
| Readonly<{
  type: SettingsAccordionSubItemType.Toggle;
  isOn: boolean;
  isOnEvenIfDisabled?: boolean;
  onToggle: (newValue: boolean) => void;
  onIcon?: IconProp;
  onLabel?: string;
  offIcon?: IconProp;
  offLabel?: string;
}>| Readonly<{
  type: SettingsAccordionSubItemType.ColorPicker;
}> & MapColorSettings
| Readonly<{
  type: SettingsAccordionSubItemType.ColorList;
  accordion?: SettingsAccordionItem;
}> & MapColorListSettings
| Readonly<{
  type: SettingsAccordionSubItemType.Slider;
}> & MapSliderSettings;

export type SettingsAccordionSubItem = Readonly<{
  label: ReactNode;
  infoTooltip: string | null;
  disabledTooltipSelector: (state: AppState) => ({
    message: string | null;
    isActiveWhenDisabled?: boolean;
  });
} & SubItemState>;

export type SettingsAccordionItem = Readonly<{
  isExpanded: boolean;
  onCollapse: () => void;
  onExpand: () => void;
  subItems: ReadonlyArray<SettingsAccordionSubItem>;
  title: ReactNode;
}>;
