import {
  defaultMapOptions,
  type MapComponentOptionsState, STATE_EXAMPLE,
} from './mapComponentOptions.state';
import { applyActiveMapElementsMapOptions } from './mapOptionsState/applyActiveMapElementsMapOptions';
import { applyBoundaryDrawMapOptions } from './mapOptionsState/applyBoundaryDrawMapOptions';
import { applyBoundarySelectMapOptions } from './mapOptionsState/applyBoundarySelectMapOptions';
import { applyDistanceCalculatorMapOptions } from './mapOptionsState/applyDistanceCalculatorMapOptions';
import { applyDrawingToolMapOptions } from './mapOptionsState/applyDrawingToolMapOptions';
import { applyExampleFeatureMapOptions } from './mapOptionsState/applyExampleFeatureMapOptions';
import { applyGeneralMapOptions } from './mapOptionsState/applyGeneralMapOptions';
import { applyLassoToolMapOptions } from './mapOptionsState/applyLassoToolMapOptions';
import { applyMarkersSelectMapOptions } from './mapOptionsState/applyMarkersSelectMapOptions';
import { applyMoveMarkersMapOptions } from './mapOptionsState/applyMoveMarkersMapOptions';
import { applyProximityMapOptions } from './mapOptionsState/applyProximityMapOptions';
import { applySidebarMapOptions } from './mapOptionsState/applySidebarMapOptions';

const initialState: MapComponentOptionsState = {
  current: {},
  context: [],
};

// See state model example before contributing
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
STATE_EXAMPLE;

// See how to work with map options state
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
applyExampleFeatureMapOptions;

// See default map options. If not used leave undefined.
// When an option is used, we need to provide a default value
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
defaultMapOptions;

export const mapComponentOptionsReducer = (state: MapComponentOptionsState = initialState, action: Action & any) => {
  return applyExampleFeatureMapOptions(state, action, { priority: -1 }) // Example
    || applyGeneralMapOptions(state, action, { priority: 0 })
    || applyActiveMapElementsMapOptions(state, action, { priority: 1 })
    || applyBoundarySelectMapOptions(state, action, { priority: 1 })
    || applyMarkersSelectMapOptions(state, action, { priority: 1 })
    || applyDistanceCalculatorMapOptions(state, action, { priority: 1 })
    || applyBoundaryDrawMapOptions(state, action, { priority: 1 })
    || applyDrawingToolMapOptions(state, action, { priority: 1 })
    || applyLassoToolMapOptions(state, action, { priority: 1 })
    || applyProximityMapOptions(state, action, { priority: 1 })
    || applyMoveMarkersMapOptions(state, action, { priority: 1 })
    || applySidebarMapOptions(state, action, { priority: 1 })
    || state;
};
