import { type FC } from 'react';
import { useSelector } from '../../_shared/utils/hooks/useSelector';
import { type SidebarAppProps } from '../../sidebar/sidebarAppComponentProps.type';
import { useIsMapSettingsSyncInProgressSelector } from '../../store/frontendState/processing/processing.selectors';
import { useBaseMapSettings } from './accordion/baseMap/useBaseMapSettings';
import { useMapMarkersGraphicsSettings } from './accordion/mapMarkersGraphics/useMapMarkersGraphicsSettings';
import { useOtherSettingsSettings } from './accordion/otherSettings/useOtherSettingsSettings';
import { useSharedPresentationalMapsSettings } from './accordion/sharedPresentationalMaps/useSharedPresentationalMapsSettings';
import { MapSettingsComponent } from './mapSettings.component';

export const MapSettingsContainer: FC<SidebarAppProps> = props => {
  const isMapSettingsLoading = useSelector<boolean>(state => state.map.mapSettings.isLoading);
  const isMapInfoLoading = useSelector<boolean>(state => state.map.mapInfo.isLoading);
  const isMapSettingsSyncInProgress = useIsMapSettingsSyncInProgressSelector();
  const mapStylesProps = useBaseMapSettings();
  const mapMarkersGraphicsProps = useMapMarkersGraphicsSettings();
  const sharedPresentationalMapsProps = useSharedPresentationalMapsSettings();
  const otherSettingsProps = useOtherSettingsSettings();

  return (
    <MapSettingsComponent
      {...props}
      baseMapSettingsProps={mapStylesProps}
      isLoading={isMapSettingsLoading || isMapInfoLoading || isMapSettingsSyncInProgress}
      {...mapMarkersGraphicsProps}
      otherSettingsProps={otherSettingsProps}
      sharedPresentationMapProps={sharedPresentationalMapsProps}
    />
  );
};
