import { css } from '@emotion/react';
import {
  useCallback, useMemo, useState,
} from 'react';
import type Scrollbar from 'react-scrollbars-custom';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { OverlayLoaderComponent } from '~/_shared/components/overlay/overlayLoader.component';
import {
  ScrollBarComponent,
  ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { IdentifierAdditionalElementComponent } from './identifierDropdown/identifierAdditionalElement.component';
import { IdentifierDropdownElementComponent } from './identifierDropdown/identifierDropdownElement.component';

const MINIMUM_ADDRESS_COLUMNS = 2;

const BUTTON_FONT_SIZE = 16;

const sectionStyle = ({ isScrolling }: {isScrolling?: boolean}) => css({
  display: 'flex',
  padding: `2px 0 ${isScrolling ? '12px' : 0}`,
  gap: 16,
});

const checkButtonStyle = css({
  boxSizing: 'border-box',
  fontSize: BUTTON_FONT_SIZE,
  height: 34,
  marginTop: 10,
  position: 'relative',
  width: 70,
});

const spinnerStyle = ({ loaderSize }: {loaderSize: number}) => css({
  left: `calc(50% - ${loaderSize / 2}px)`,
  top: loaderSize / 2,
});

const messageStyle = (props: ThemeProps<{ success: boolean }>) => css({
  marginTop: 10,
  color: props.success ? props.theme.textColors.success : props.theme.textColors.danger,
});

export type UniqueIdentifierSelectorComponentProps = Readonly<{
  allowAddColumns?: boolean;
  columnOptions: ReadonlyArray<DropdownOption<number | null>>;
  checkForUniqueDoneForCurrentColumns: boolean;
  identifierColumnIds: (number| null)[];
  isChecking: boolean;
  isIdentifierUnique: boolean;
  title?: string;

  onCheckIdentifier?: () => void;
  onRemoveDropdown?: (index: number) => void;
  setIdentifierColumnIds: (ids: (number|null)[]) => void;
}>;

export const UniqueIdentifierSelectorComponent: React.FC<UniqueIdentifierSelectorComponentProps> = props => {
  const { onRemoveDropdown, setIdentifierColumnIds, onCheckIdentifier } = props;

  const [t] = useTranslation();
  const theme = useTheme();

  const [isScrolling, setIsScrolling] = useState<boolean|undefined>(false);
  const [scrollReference, setScrollReference] = useState<Scrollbar>();

  const setIdentifierColumnId = useCallback((index: number, id: number) => {
    const ids = props.identifierColumnIds.map((column, i) => i === index ? id : column);
    setIdentifierColumnIds(ids);
  }, [props.identifierColumnIds, setIdentifierColumnIds]);

  const handleAdditionalElementClick = useCallback(() =>
    setIdentifierColumnIds([...props.identifierColumnIds, null]),
  [props.identifierColumnIds, setIdentifierColumnIds]);

  const showAddColumnOption = useMemo(() =>
    props.allowAddColumns && props.identifierColumnIds.every(el => el !== null)
  , [props.identifierColumnIds, props.allowAddColumns]);

  const onScrollUpdate = useCallback(() =>
    setIsScrolling(scrollReference?.getScrollState().trackXVisible),
  [scrollReference]);

  const selectedUnique = useMemo(() =>
    new Set(props.identifierColumnIds),
  [props.identifierColumnIds]);

  const getUnusedAvailableColumns = useCallback((selectedItem: null | number) =>
    props.columnOptions.filter(o => selectedItem === o.value || !selectedUnique.has(o.value)),
  [props.columnOptions, selectedUnique]);

  const showUniqueColumnsCheckResult = useMemo(() => (
    props.checkForUniqueDoneForCurrentColumns && !props.isChecking
  ), [props.checkForUniqueDoneForCurrentColumns, props.isChecking]);

  return (
    <>
      <ScrollBarComponent
        getScrollbarReference={setScrollReference}
        onUpdate={onScrollUpdate}
        translateContentHeightToHolder
        type={ScrollbarType.Horizontal}
      >
        <div css={sectionStyle({ isScrolling })}>
          {props.identifierColumnIds?.map((id, index) => (
            <IdentifierDropdownElementComponent
              dropdownOptions={getUnusedAvailableColumns(id)}
              index={index}
              key={index}
              onChange={setIdentifierColumnId}
              onRemove={props.identifierColumnIds.length > MINIMUM_ADDRESS_COLUMNS ? onRemoveDropdown : undefined}
              selectedValue={id}
              title={index ? t('Additional Column') : props.title}
            />
          ))}
          {showAddColumnOption && (
            <IdentifierAdditionalElementComponent
              onClick={handleAdditionalElementClick}
            />
          )}
        </div>
      </ScrollBarComponent>

      {onCheckIdentifier && !selectedUnique.has(null) && (
        <ButtonComponent
          css={checkButtonStyle}
          isDisabled={props.isChecking}
          onClick={onCheckIdentifier}
          text={(
            <>
              {props.isChecking && (
                <OverlayLoaderComponent
                  loaderSize={BUTTON_FONT_SIZE}
                  spinnerStyle={spinnerStyle({ loaderSize: BUTTON_FONT_SIZE })}
                />
              )}
              {t('Check')}
            </>
          )}
        />
      )}

      {onCheckIdentifier && showUniqueColumnsCheckResult && (
        <div css={messageStyle({ theme, success: props.isIdentifierUnique })}>
          {props.isIdentifierUnique ? t('Unique identifier check OK') : t('Unique identifier check not OK')}
        </div>
      )}
    </>
  );
};
