import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { createStateSelector } from '~/_shared/utils/memoize/createSelector';
import { notNull } from '~/_shared/utils/typeGuards';
import { type AppState } from '~/store/app.store';
import {
  emptySpreadsheetLatLngRowData, getSpreadsheetLatLngRowData, selectLatLngSpreadsheetData,
} from '~/store/selectors/spreadsheetDataMemoizedSelectors';
import { isMarkersSelectActive } from './markersSelect.state';

export const markersSelectSelector = (state: AppState) => state.frontendState.mapTools.markersSelect;
export const useMarkersSelectSelector = () => useSelector(markersSelectSelector);

const markersSelectIsLassoLoadingSelector = (state: AppState) => state.frontendState.mapTools.markersSelect.isLassoSelectLoading;
export const useMarkersSelectIsLassoLoadingSelector = () => useSelector(markersSelectIsLassoLoadingSelector);

const markersSelectActiveSelector = (state: AppState) => {
  const markersSelect = state.frontendState.mapTools.markersSelect;
  return isMarkersSelectActive(markersSelect) ? markersSelect : null;
};
export const useMarkersSelectActiveSelector = () => useSelector(markersSelectActiveSelector);

const selectMarkersSelectActiveRows = createStateSelector([
  state => markersSelectSelector(state).selectedMarkerIds,
  selectLatLngSpreadsheetData,
], (selectedMarkerIds, latLngSpreadsheetData) => {
  if (selectedMarkerIds) {
    const markersSelectActiveLatLngRows = selectedMarkerIds.map(rowId =>
      latLngSpreadsheetData.getRow(rowId) || null
    ).filter(notNull);
    if (markersSelectActiveLatLngRows.length) {
      return getSpreadsheetLatLngRowData(markersSelectActiveLatLngRows);
    }
  }
  return emptySpreadsheetLatLngRowData;
});
export const useSelectMarkersSelectActiveRows = () => useSelector(selectMarkersSelectActiveRows);
