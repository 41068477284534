import { MapTool } from '~/_shared/types/toolsAndFeatures/mapTools.types';
import { SharedPresentationalMapsBooleanToggleSettingsName } from './sharedPresentationalMaps.enums';

export const toggleableSharedPresentationalMapTools = {
  [SharedPresentationalMapsBooleanToggleSettingsName.boundaryTool]: MapTool.Boundary,
  [SharedPresentationalMapsBooleanToggleSettingsName.filterTool]: MapTool.Filter,
  [SharedPresentationalMapsBooleanToggleSettingsName.groupingTool]: MapTool.Grouping,
  [SharedPresentationalMapsBooleanToggleSettingsName.heatMappingTool]: MapTool.HeatMap,
  [SharedPresentationalMapsBooleanToggleSettingsName.radiusProximity]: MapTool.Proximity,
  [SharedPresentationalMapsBooleanToggleSettingsName.routingDirections]: MapTool.Routing,
};

export type ToggleableSharedPresentationalMapTool = keyof typeof toggleableSharedPresentationalMapTools;
