import { type LassoAction } from './lasso.action';
import {
  FRONTEND_STATE_LASSO_START,
  FRONTEND_STATE_LASSO_STOP,
  FRONTEND_STATE_ROUTING_LASSO_START,
} from './lasso.actionTypes';
import {
  LassoConfiguration, type LassoState,
} from './lasso.state';

const initialState: LassoState = {
  active: false,
};

export const lassoReducer = (state = initialState, action: LassoAction): LassoState => {
  switch (action.type) {

    case FRONTEND_STATE_LASSO_START: {
      return {
        ...state,
        active: true,
        configuration: LassoConfiguration.LassoTool,
      };
    }

    case FRONTEND_STATE_ROUTING_LASSO_START: {
      return {
        ...state,
        active: true,
        configuration: LassoConfiguration.RoutingSelection,
      };
    }

    case FRONTEND_STATE_LASSO_STOP: {
      return {
        ...state,
        active: false,
      };
    }

    default:
      return state;
  }
};
