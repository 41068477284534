import {
  type FC, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTerritoryEdit } from '~/_shared/hooks/useTerritoryEdit';
import {
  ERASURE_KEYS, useKeyPress,
} from '~/_shared/utils/hooks/useKeyPress';
import { useBoundaryMetricsData } from '~/_shared/utils/metric/useBoundaryMetricsData';
import { getTotalNumberOfBoundaryPathsPolygons } from '~/boundary/boundary.helpers';
import { useIsBoundaryLocationsExportAllowed } from '~/boundary/useIsBoundaryExportLocationsAllowed';
import { useMapBoundaryBaseStyles } from '~/map/map/boundary/boundaryView/mapBoundaryStyles/useMapBoundaryBaseStyles';
import { useMapBoundaryExtraStyles } from '~/map/map/boundary/boundaryView/mapBoundaryStyles/useMapBoundaryExtraStyles';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { useBoundaryExportData } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryExportData';
import { useBoundaryStateItem } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useBoundaryStateItem';
import { useRemoveCustomBoundary } from '~/sidebar/sidebarApps/mapTools/boundary/hooks/useRemoveCustomBoundary';
import { useIsBoundaryGroupCustom } from '~/store/boundaryGroups/boundaryGroups.selectors';
import { useBoundaryTerritoryDetailsIsLoadingSelector } from '~/store/boundaryTerritoryDetails/boundaryTerritoryDetails.selectors';
import { useBoundaryTerritoryGroupsSelector } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.selectors';
import { deactivateActiveBoundary } from '~/store/frontendState/activeMapElements/activeMapElements.actionCreators';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';
import { useIsConnectedLayeredMapSelector } from '~/store/mapInfo/mapInfo.selectors';
import { usePublicMapSettingsSelector } from '~/store/mapSettings/publicMapSettings/mapSettingsPublicMapSettings.selectors';
import {
  clearBoundaryLocationsFilter, setBoundaryLocationsFilter,
} from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.actionCreators';
import { useBoundaryLocationsFilters } from '~/store/mapSettings/toolsState/boundary/mapSettingsToolsStateBoundary.selectors';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useWmsFeatureBlocker } from '../../mapTools/boundary/activeBoundary/boundaryItem/useWmsFeatureBlocker';
import { useBoundaryEditPolygon } from '../../mapTools/boundary/edit/useBoundaryEditPolygon';
import { useBoundaryBatchEditLocations } from '../../mapTools/boundary/hooks/useBoundaryBatchEditLocations';
import { useBoundaryDirections } from '../../mapTools/boundary/hooks/useBoundaryDirections';
import { useSidebarIndicatorStyles } from '../useSidebarIndicatorStyles';
import { BoundaryDetailsComponent } from './boundaryDetails.component';
import { useBoundaryDetailsMarkersCount } from './useBoundaryDetailsMarkersCount';

type BoundaryDetailsContainerProps = {
  boundaryGroupId: number;
  boundaryId: number;
  boundaryTerritoryGroupId: number;

  onClose: () => void;
};

export const BoundaryDetailsContainer: FC<BoundaryDetailsContainerProps> = (props) => {
  const dispatch = useDispatch();
  const isBoundaryTerritoryDetailsLoading = useBoundaryTerritoryDetailsIsLoadingSelector();
  const isMapPresentational = useIsMapPresentationalSelector();
  const publicMapSettings = usePublicMapSettingsSelector();
  const isBoundaryExportLocationsAllowed = useIsBoundaryLocationsExportAllowed();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();
  const boundaryLocationsFilters = useBoundaryLocationsFilters();
  const { getBoundaryStateItem } = useBoundaryStateItem();
  const { openModal: openBoundaryItemSettingsModal } = useModal(ModalType.BoundaryItemSettings);
  const { openModal: openExportContainedBoundaryDataModal } = useModal(ModalType.ExportContainedBoundaryData);
  const { isBoundaryGroupCustom } = useIsBoundaryGroupCustom();
  const { boundaryIsUnderWmsTrial, blockWhenWmsTrial } = useWmsFeatureBlocker(props.boundaryGroupId);
  const { exportBoundaryData } = useBoundaryExportData();
  const { addBoundaryDirections } = useBoundaryDirections();
  const { removeCustomBoundary, isLoading: isCustomBoundaryRemoveLoading } = useRemoveCustomBoundary();
  const { isEditingBoundaryPath } = useBoundaryEditPolygon();
  const { batchEditLocationsInBoundary } = useBoundaryBatchEditLocations();
  const { openModal: openBoundaryEditModal } = useModal(ModalType.BoundaryEdit);
  const { openModal: openBoundaryCustomizeMetricsModal } = useModal(ModalType.BoundaryCustomizeMetrics);
  const startTerritoryEdit = useTerritoryEdit();
  const baseStyles = useMapBoundaryBaseStyles();
  const { boundaryExtraStyles } = useMapBoundaryExtraStyles(baseStyles);
  const isMobileScreen = useIsMobileScreenSelector();

  const closeActiveBoundary = useCallback(() => {
    dispatch(deactivateActiveBoundary());
  }, [dispatch]);

  const boundaryTerritoryGroup = useBoundaryTerritoryGroupsSelector()
    .find(group => group.boundaryTerritoryGroupId === props.boundaryTerritoryGroupId);

  const boundary = useMemo(() => {
    return getBoundaryStateItem(props.boundaryId, props.boundaryGroupId);
  }, [getBoundaryStateItem, props.boundaryId, props.boundaryGroupId]);

  const { metricsResults, isLoading: isBoundaryMetricsLoading } = useBoundaryMetricsData({
    boundaryGroupId: props.boundaryGroupId,
    boundaryId: props.boundaryId,
    boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
  }, boundaryTerritoryGroup?.settings.ignoreFilters);

  useEffect(() => {
    if (!boundaryTerritoryGroup || !boundary) {
      closeActiveBoundary();
    }
  });

  const isBoundaryIsolated = useMemo(() => (
    boundaryLocationsFilters.get(props.boundaryGroupId)?.filteredBoundaries.includes(props.boundaryId)
  ), [boundaryLocationsFilters, props.boundaryGroupId, props.boundaryId]);

  const boundaryName = boundary?.displayName;

  const boundaryIds = useMemo(() => [props.boundaryId], [props.boundaryId]);
  const { markersCount } = useBoundaryDetailsMarkersCount(props.boundaryTerritoryGroupId, boundaryIds);
  const openBoundaryItemSettings = () => {
    openBoundaryItemSettingsModal({
      boundaryId: props.boundaryId,
      boundaryGroupId: props.boundaryGroupId,
      boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
    });
  };

  const boundaryIsCustom = useMemo(() => isBoundaryGroupCustom(props.boundaryGroupId), [isBoundaryGroupCustom, props.boundaryGroupId]);
  const openEditing = (!isMapPresentational && boundaryIsCustom) ? openBoundaryItemSettings : undefined;

  const handleRemoveBoundary = useMemo(() => {
    if (!isMapPresentational && boundaryIsCustom) {
      return () => removeCustomBoundary(props.boundaryId, props.onClose);
    }

    return undefined;
  }, [boundaryIsCustom, isMapPresentational, props.boundaryId, props.onClose, removeCustomBoundary]);

  const onEditBoundaryPolygonClick = (!isMapPresentational && boundaryIsCustom)
    ? () => startTerritoryEdit(props.boundaryId, props.boundaryGroupId, props.boundaryTerritoryGroupId)
    : undefined;

  const onExportDataClick = isBoundaryExportLocationsAllowed
    ? () => exportBoundaryData(props.boundaryTerritoryGroupId, props.boundaryId)
    : undefined;

  const onExportContainedBoundariesClick = !isMapPresentational && boundaryTerritoryGroup
    ? () => openExportContainedBoundaryDataModal({
      filter: {
        type: 'boundary',
        boundaryGroupId: props.boundaryGroupId,
        boundaryId: props.boundaryId,
        matchings: boundaryTerritoryGroup.matchings,
      },
    }) : undefined;

  const onBatchEditLocations = !isMapPresentational
    ? () => batchEditLocationsInBoundary(props.boundaryTerritoryGroupId, props.boundaryId)
    : undefined;

  const onDirectionsClick = !isMapPresentational || publicMapSettings.routingDirections ?
    () => addBoundaryDirections(props.boundaryTerritoryGroupId, props.boundaryId)
    : undefined;

  const onEditMetrics = useMemo(() => !isMapPresentational ? () => openBoundaryCustomizeMetricsModal({
    boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
    isBoundaryGroupCustom: boundaryIsCustom,
  }) : undefined, [props.boundaryTerritoryGroupId, boundaryIsCustom, isMapPresentational, openBoundaryCustomizeMetricsModal]);

  const onIsolateBoundaryClick = useMemo(() => !isMapPresentational ? () => {
    if (isBoundaryIsolated) {
      dispatch(clearBoundaryLocationsFilter());
    }
    else {
      dispatch(setBoundaryLocationsFilter(props.boundaryGroupId, {
        filteredBoundaries: [props.boundaryId],
        filteredBoundaryTerritories: [],
        isolate: true,
      }));
    }
  } : undefined, [dispatch, isBoundaryIsolated, isMapPresentational, props.boundaryGroupId, props.boundaryId]);

  const onAdvanceEditClick = useMemo(() => !isMapPresentational && boundaryIsCustom ?
    () => openBoundaryEditModal({
      boundaryTerritoryGroupId: props.boundaryTerritoryGroupId,
      boundaryId: props.boundaryId,
    }) : undefined
  , [boundaryIsCustom, isMapPresentational, openBoundaryEditModal, props.boundaryId, props.boundaryTerritoryGroupId]);

  const activeBoundaryStyles = useMemo(() => {
    if (!boundary) {
      return undefined;
    }

    const extraStylesForBoundary = boundaryExtraStyles.get(boundary.id);

    if (!extraStylesForBoundary) {
      return undefined;
    }

    const fillColor = extraStylesForBoundary.style.fillColor;
    const fillOpacity = extraStylesForBoundary.style.fillOpacity.value;
    const borderWidth = extraStylesForBoundary.style.borderWidth;
    const borderColor = extraStylesForBoundary.style.borderColor;

    return {
      fillColor,
      fillOpacity,
      borderWidth,
      borderColor,
    };
  }, [boundary, boundaryExtraStyles]);

  const indicatorStyles = useSidebarIndicatorStyles(activeBoundaryStyles);

  useKeyPress(useMemo(() => ({
    callbacks: { onKeyPress: handleRemoveBoundary },
    targetKeys: ERASURE_KEYS,
  }), [handleRemoveBoundary]));

  return (
    <BoundaryDetailsComponent
      boundaryName={boundaryName}
      boundaryPolygonsTotal={boundary ? getTotalNumberOfBoundaryPathsPolygons(boundary.paths) : 0}
      isCustom={boundaryIsCustom}
      isArtificial={!!boundary?.settings.isArtificial}
      boundaryIsUnderWmsTrial={boundaryIsUnderWmsTrial}
      blockWhenWmsTrial={blockWhenWmsTrial}
      isEditingBoundary={isEditingBoundaryPath}
      isBoundaryIsolated={isBoundaryIsolated}
      isLoading={isBoundaryMetricsLoading || isBoundaryTerritoryDetailsLoading || isCustomBoundaryRemoveLoading}
      markersCount={markersCount}
      metrics={metricsResults}
      ignoreFilters={boundaryTerritoryGroup?.settings.ignoreFilters ?? false}
      onClose={closeActiveBoundary}
      onEditBoundaryPolygonClick={onEditBoundaryPolygonClick}
      onEditClick={openEditing}
      isMobileVersion={isMobileScreen}
      onBoundaryItemSettingsClick={openEditing}
      onExportLocationsClick={onExportDataClick}
      onExportContainedBoundariesClick={onExportContainedBoundariesClick}
      onBoundaryItemRemoveClick={handleRemoveBoundary}
      onBatchEditLocationsClick={onBatchEditLocations}
      onDirectionsClick={onDirectionsClick}
      onIsolateBoundaryClick={onIsolateBoundaryClick}
      isLayeredMapConnected={isLayeredMapConnected}
      onAdvanceEditClick={onAdvanceEditClick}
      onCustomizeMetricsClick={onEditMetrics}
      indicatorStyles={indicatorStyles}
    />
  );
};
