import { useMemo } from 'react';
import { ColumnRole } from '~/_shared/types/columnRole.enum';
import { type SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import type { StackedMarker } from '~/map/map/markers/manager/mapMarkerManager';
import { useMapSettingsUseLabelsAboveMarkersSelector } from '~/store/mapSettings/makersGeneral/mapSettingsMarkersGeneral.selectors';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';
import { useMarkerNameResolver } from '../spreadsheet/useMarkerNameResolver';

export const useHoverMarkerName = (spreadsheetRowId?: SpreadsheetRowId, stackInfo?: StackedMarker) => {
  const [t] = useTranslation();
  const matchupData = useMatchupDataSelector();
  const { markerNameResolver } = useMarkerNameResolver();
  const areLabelsAboveEnabled = useMapSettingsUseLabelsAboveMarkersSelector();

  const isHoverEnabled = spreadsheetRowId
    ? matchupData[spreadsheetRowId.spreadsheetId]?.data?.categories[ColumnRole.Name].match
    : false;

  return useMemo(() => {
    if (!spreadsheetRowId || !isHoverEnabled || areLabelsAboveEnabled) {
      return null;
    }

    const numberOfStackedMarkers = stackInfo?.stackedMarkers?.length ?? 0;

    if ((numberOfStackedMarkers ?? 0) > 1) {
      return t('{{n}} items here', { n: numberOfStackedMarkers });
    }

    const name = markerNameResolver(spreadsheetRowId.spreadsheetId, spreadsheetRowId.rowId, true);
    return /\S/.test(name) ? name : null;
  }, [spreadsheetRowId, isHoverEnabled, areLabelsAboveEnabled, stackInfo?.stackedMarkers?.length, markerNameResolver, t]);
};
