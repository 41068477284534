import {
  type DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type LatLng } from '../../types/latLng';
import { FPS60 } from '../throttle/throttle';
import { usePrevious } from './usePrevious';
import { useThrottle } from './useThrottle';

export type MapObjectDnDParams = {
  dragDisabled?: boolean;
  readonly map?: google.maps.Map;
  readonly mapObject?: MapObject | null;

  /**
   * When set to true, mapObject can be undefined and one hook can be used to move multiple objects.
   * MapObject is only required to register DnD trigger, which is not needed with this option.
   */
  readonly addDragHandlerInstantly?: boolean;

  readonly onDragMove?: (latLng: LatLng) => void;
  readonly onDragStart?: (latLng: LatLng) => void;
  readonly onDragEnd?: () => void;
  readonly onMouseOver?: () => void;

  /**
   * When dragging this event is not triggered immediately, but after the drag is over.
  */
  readonly onMouseOut?: () => void;
};

export const useMapObjectDragAndDrop = (getParams: () => MapObjectDnDParams, deps: DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => getParams(), deps);
  const { mapObject, addDragHandlerInstantly } = params;
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const onDragRef = useRef<google.maps.MapsEventListener | null>(null);
  const onDragStartRef = useRef<google.maps.MapsEventListener | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const isDraggingRef = useRef(false);

  const [mouseOver, setMouseOver] = useState(false);
  const callMouseOutOnDragEndRef = useRef(false);

  const unmountCallbackRef = useRef<() => void>(undefined);

  const dragEnabled = !params.dragDisabled
    && !!(params.onDragMove || params.onDragStart || params.onDragEnd)
    && !!(mapObject || addDragHandlerInstantly);

  const mouseEventsEnabled = dragEnabled || params.onMouseOver || params.onMouseOut;

  // Throttle mouse move event when dragging so it won't affect performance
  const onDragCallback = useThrottle((e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      paramsRef.current.onDragMove?.({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  }, [], FPS60);

  // End drag
  const onMouseUp = useCallback(() => {
    setIsDragging(false);
    isDraggingRef.current = false;

    onDragRef.current?.remove();

    if (callMouseOutOnDragEndRef.current) {
      callMouseOutOnDragEndRef.current = false;
      invokeAsync(() => paramsRef.current.onMouseOut?.());
    }

    invokeAsync(() => paramsRef.current.onDragEnd?.());
  }, []);

  // Start drag
  const onMouseDown = useCallback((e: google.maps.MapMouseEvent) => {
    if (paramsRef.current.map && e.latLng) {
      setIsDragging(true);
      isDraggingRef.current = true;

      document.addEventListener('mouseup', onMouseUp);

      onDragRef.current = google.maps.event.addListener(paramsRef.current.map, 'mousemove', onDragCallback);

      const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      invokeAsync(() => paramsRef.current.onDragStart?.(latLng));
    }
  }, [onDragCallback, onMouseUp]);

  // Removes mouse up listener when drag stops
  const previousIsDragging = usePrevious(isDragging);
  useEffect(() => {
    const dragStopped = previousIsDragging && !isDragging;

    if (dragStopped) {
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, [isDragging, onMouseUp, previousIsDragging]);

  // Map object hover, register drag start (mousedown)
  useEffect(() => {
    if (!mouseEventsEnabled) {
      return;
    }

    const mouseOver = () => {
      setMouseOver(true);
      callMouseOutOnDragEndRef.current = false;

      if (dragEnabled && params.map) {
        onDragStartRef.current?.remove();
        onDragStartRef.current = google.maps.event.addListener(params.map, 'mousedown', onMouseDown);
      }

      invokeAsync(() => paramsRef.current.onMouseOver?.());
    };

    const mouseOut = () => {
      onDragStartRef.current?.remove();
      setMouseOver(false);

      if (isDraggingRef.current) {
        callMouseOutOnDragEndRef.current = true;
      }
      else {
        invokeAsync(() => paramsRef.current.onMouseOut?.());
      }
    };

    if (addDragHandlerInstantly && dragEnabled) {
      mouseOver();
    }
    else if (mapObject) {
      mapObject.addEventListener('mouseover', mouseOver);
      mapObject.addEventListener('mouseout', mouseOut);
    }

    return () => {
      if (addDragHandlerInstantly && dragEnabled) {
        mouseOut();
      }
      else if (mapObject) {
        mapObject.removeEventListener('mouseout', mouseOut);
        mapObject.removeEventListener('mouseover', mouseOver);

        // Clean up mouse down event if registered after removal of mouseOver
        onDragStartRef.current?.remove();
      }
    };

  }, [dragEnabled, mapObject, onMouseDown, params.map, addDragHandlerInstantly, mouseEventsEnabled]);

  // Document mousedown / mouseup cleanup on unmount
  useEffect(() => {
    return () => {
      onDragStartRef.current?.remove();
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseDown, onMouseUp]);

  // Clean map move event on unmount
  useEffect(() => {
    return () => onDragRef.current?.remove();
  }, []);

  // Calls onMouseOut when map object was hovered on unmount.
  unmountCallbackRef.current = useCallback(() => {
    if (mouseOver) {
      paramsRef.current.onMouseOut?.();
    }
  }, [mouseOver]);

  useEffect(() => {
    return () => unmountCallbackRef.current?.();
  }, []);
};

// Callbacks passed as hook params can trigger dispatch, which can synchronously trigger (flush) react effect cleanups from previous react updates.
// If such callback is called from withing our handler it can synchronously trigger cleanup before all code in handler was done,
// which could possibly contain event handler registrations. This could create a race conditon between the registraton and cleanup.
// We use timeout and event loop to execute callback asynchronously to prevent race conditions between our handlers and effect cleanups.
const invokeAsync = (cb: () => void) => {
  setTimeout(cb, 0);
};
