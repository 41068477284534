import {
  useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  outlineHoverVisuals, outlineVisuals,
} from '~/_shared/constants/mapObjects/mapObjectOutline/outlineVisuals.constants';
import { type Polygon } from '~/_shared/types/polygon/polygon.types';
import { type MapObjectOutlineInstance } from '~/map/map/mapObjects/mapOutline/mapOutlineModel';
import { useLassoToolPolygonZIndex as useLassoToolPolygonInstanceZIndex } from '~/map/zIndexes/useMapPolygonInstanceZIndex.hook';
import { type LatLng } from '../../../../_shared/types/latLng';
import { MarkerColor } from '../../../../_shared/types/marker.types';
import {
  hoverLassoOutline,
  hoverLassoRemoveButton,
  startLassoPolygonHover,
  stopLassoOutlineHover,
  stopLassoPolygonHover,
  stopLassoRemoveButtonHover,
} from '../../../../store/frontendState/mapTools/lasso/lasso.actionCreators';
import { MapOutline } from '../../mapObjects/mapOutline/mapOutline.component';
import { DrawMapShape } from '../../mapObjects/mapShape/drawMapShape.container';
import { MapShape } from '../../mapObjects/mapShape/mapShape.container';
import {
  MapShapeLine, type MapShapePolylineVisualsConfig,
} from '../../mapObjects/mapShape/mapShapeLine.component';
import {
  type MapShapeInstance, shapeOutlinesToPath,
} from '../../mapObjects/mapShape/mapShapeModel';
import {
  MapShapePolygon, type MapShapePolygonVisualConfig,
} from '../../mapObjects/mapShape/mapShapePolygon.component';
import {
  MapShapeRemoveButton, type MapShapeRemoveButtonVisualsConfig,
} from '../../mapObjects/mapShape/mapShapeRemoveButton.component';

const lineVisuals: MapShapePolylineVisualsConfig = {
  color: [88, 125, 215, 1],
  style: 'solid',
  width: 3,
};

export const removeButtonVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Black,
};

export const removeButtonHoverVisuals: MapShapeRemoveButtonVisualsConfig = {
  color: MarkerColor.Red,
};

export const polygonVisuals: MapShapePolygonVisualConfig = {
  fillColor: [0, 0, 255, 0.2],
};

export type LassoToolDrawProps = {
  readonly isDrawing: boolean;

  readonly onDrawingFinished: (polygon?: Polygon) => void;
  readonly onPolygonClick: (polygon?: Polygon) => void;
  readonly onRemove: () => void;
};

export const LassoToolDrawComponent: React.FC<LassoToolDrawProps> = ({ onPolygonClick, onDrawingFinished, isDrawing, onRemove }) => {
  const [shape, setShape] = useState<MapShapeInstance | undefined>();
  const [firstOutlineHover, setFirstOutlineHover] = useState<boolean>(false);
  const [removeHovered, setRemoveHovered] = useState(false);
  const dispatch = useDispatch();

  const zIndex = useLassoToolPolygonInstanceZIndex(shape?.id);

  const onShapeChange = useCallback((shape: MapShapeInstance) => {
    setShape(shape);
  }, []);

  const onOutlineMouseOver = useCallback((outlineId: Uuid) => {
    if (isDrawing && isFirstOutlineHoverEnabled(shape, outlineId)) {
      setFirstOutlineHover(true);
      dispatch(hoverLassoOutline());
    }
  }, [dispatch, isDrawing, shape]);

  const onOutlineMouseOut = useCallback(() => {
    setFirstOutlineHover(false);
    dispatch(stopLassoOutlineHover());
  }, [dispatch]);

  const onPolygonMouseOver = useCallback(() => {
    dispatch(startLassoPolygonHover());
  }, [dispatch]);

  const onPolygonMouseOut = useCallback(() => {
    dispatch(stopLassoPolygonHover());
  }, [dispatch]);

  const onPolygonClickCallback = (useCallback(() => {
    if (!shape) {
      onPolygonClick();
      return;
    }
    onPolygonClick({ path: shapeOutlinesToPath(shape.outlines), holes: [] });
  },
  [shape, onPolygonClick],
  ));

  const onRemoveMouseOver = useCallback(() => {
    setRemoveHovered(true);
    dispatch(hoverLassoRemoveButton());
  }, [dispatch]);

  const onRemoveMouseOut = useCallback(() => {
    setRemoveHovered(false);
    dispatch(stopLassoRemoveButtonHover());
  }, [dispatch]);
  const renderOutline = useCallback((id: string, outline: MapObjectOutlineInstance) => (
    <MapOutline
      key={id}
      outline={outline}
      visuals={isFirstOutline(shape, outline.id) && firstOutlineHover ? outlineHoverVisuals : outlineVisuals}
      onMouseOver={onOutlineMouseOver}
      onMouseOut={onOutlineMouseOut}
    />
  ), [onOutlineMouseOut, onOutlineMouseOver, shape, firstOutlineHover]);

  const renderLine = useCallback((id: string, start: LatLng, end: LatLng) => (
    <MapShapeLine
      key={id}
      id={id}
      start={start}
      end={end}
      visuals={lineVisuals}
    />
  ), []);

  const renderRemoveButton = useCallback(() => (
    <MapShapeRemoveButton
      onClick={onRemove}
      onMouseOver={onRemoveMouseOver}
      onMouseOut={onRemoveMouseOut}
      visuals={removeHovered ? removeButtonHoverVisuals : removeButtonVisuals}
    />
  ), [onRemove, onRemoveMouseOut, onRemoveMouseOver, removeHovered]);

  const renderPolygon = useCallback(() => (
    <MapShapePolygon
      visuals={polygonVisuals}
      onClick={onPolygonClickCallback}
      onMouseOut={onPolygonMouseOut}
      onMouseOver={onPolygonMouseOver}
    />
  ), [onPolygonClickCallback, onPolygonMouseOut, onPolygonMouseOver]);

  const onDrawingDone = useCallback(() => {
    if (!shape || shape.outlines.length < 3) {
      onRemove();
      return;
    }

    onDrawingFinished({ path: shapeOutlinesToPath(shape.outlines), holes: [] });
  }, [onDrawingFinished, onRemove, shape]);

  if (!isDrawing && shape && shape.outlines.length > 2) {
    return (
      <MapShape
        shape={shape}
        zIndex={zIndex}
        isPolygon
        renderOutline={renderOutline}
        renderLine={renderLine}
        renderPolygon={renderPolygon}
        renderRemoveButton={renderRemoveButton}
      />
    );
  }

  if (isDrawing) {
    return (
      <DrawMapShape
        zIndex={zIndex}
        onDone={onDrawingDone}
        onChange={onShapeChange}
        renderOutline={renderOutline}
        renderLine={renderLine}
      />
    );
  }

  return null;
};

const isFirstOutline = (shape: MapShapeInstance | undefined, outlineId: string) =>
  shape?.outlines[0]?.id === outlineId;

const isFirstOutlineHoverEnabled = (shape: MapShapeInstance | undefined, outlineId: string) => {
  const isFirst = isFirstOutline(shape, outlineId);
  return isFirst && !!(shape && shape.outlines.length > 2);
};
