import axios, { type CancelToken } from 'axios';
import {
  type FileAttachment,
  type FileId,
  type FileLink,
  type UserFileType,
} from '~/_shared/types/file.types';
import {
  apiDelete, apiGet,
} from '~/_shared/utils/api/api.helpers';

export enum FileOrder{
  Created_DESC = 'created_desc',
  Created_ASC = 'created_asc',
  Updated_DESC = 'updated_desc',
  Updated_ASC = 'updated_asc',
  Id_ASC = 'id_asc',
  Id_DESC = 'id_desc',
}

export type UploadFile = {
  data: FileAttachment;
};

export type UploadRequest = {
  file: File;
  type: UserFileType;
  user?: boolean;
};

export type UploadUserFileParams = {
  onProgress?: (progress: number) => void;
  cancelToken?: CancelToken;
  type?: UserFileType;
  user?: boolean;
};

export enum UserFileErrorCode {
  NotFound = 'not_found',
  Forbidden = 'access_forbidden',
}

export type UserFileSuccessResponse = {
  id: FileId;
  type: UserFileType;
  client_id: number;
  error: false;
  links: {
    'original': FileLink;
    '200p'?: FileLink;
    '600p'?: FileLink;
  };
};

export type UserFileErrorResponse = {
  id: FileId;
  error: true;
  error_code: UserFileErrorCode;
};

export type UserFileResponse = UserFileSuccessResponse | UserFileErrorResponse;
export const isSuccessFile = (file: UserFileResponse): file is UserFileSuccessResponse => !file.error;
export const isErrorFile = (file: UserFileResponse): file is UserFileErrorResponse => file.error;

export type UserFilesResponse = {
  list: UserFileResponse[];
};

export type UserFileParams = {
  personal?: boolean;
  type?: UserFileType ;
  order?: FileOrder;
  per_page?: number;
  page?: number;
};

export type RemoveFileResponse = {
  message: string;
  data: {
    id: number;
  };
};

export type FileMapInfo = {
  map_id: number;
  map_name: string;
  is_snapshot: boolean;
};

export type FileMapListResponse = {
  list: FileMapInfo[];
};

export const uploadUserFile = <T = any>(url: string, file: File, uploadUserFileParams: UploadUserFileParams = {}): Promise<T> => {
  const data = new FormData();
  data.append('file', file);
  if (uploadUserFileParams.type) {
    data.append('type', uploadUserFileParams.type);
  }
  if (uploadUserFileParams.user) {
    data.append('user', uploadUserFileParams.user ? '1' : '0');
  }

  return axios.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      if (uploadUserFileParams.onProgress) {
        uploadUserFileParams?.onProgress(
          progressEvent.event.lengthComputable ? progressEvent.loaded * 100 / progressEvent.event.total : 0
        );
      }
    },
  });
};

export const uploadFileRequest = (clientId: number|null, request: UploadRequest, onProgress: (progress: number) => void): Promise<UploadFile> => {
  const requestUrl = `/api/clients/${clientId}/files`;
  return uploadUserFile(requestUrl, request.file, { onProgress, type: request.type, user: request.user });
};

export const getAllUserFilesRequest = (clientId: number|null, request: UserFileParams): Promise<UserFilesResponse> => {
  const requestUrl = `/api/clients/${clientId}/files`;
  return apiGet(requestUrl, request);
};

export const removeUserFileRequest = (clientId: number|null, fileId: number): Promise<RemoveFileResponse> => {
  const requestUrl = `/api/clients/${clientId}/files/${fileId}`;
  return apiDelete(requestUrl);
};

export const getBulkFilesRequest = (clientId: number|null, ids: number[], mapId: number|null): Promise<UserFilesResponse> => {
  const requestUrl = `/api/clients/${clientId}/files/bulk`;
  return apiGet(requestUrl, {
    ids: ids ? ids.join(',') : undefined,
    map_id: mapId,
  });
};

export const getFileMapListRequest = (clientId: number, fileId: number): Promise<FileMapListResponse> => {
  const requestUrl = `/api/clients/${clientId}/files/${fileId}/attachments`;
  return apiGet(requestUrl);
};
