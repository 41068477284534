import { type AnnouncementsAction } from './announcements.action';
import {
  ANNOUNCEMENT_MARK_AS_SEEN_ERROR,
  ANNOUNCEMENT_MARK_AS_SEEN_REQUEST,
  ANNOUNCEMENT_MARK_AS_SEEN_SUCCESS,
  ANNOUNCEMENTS_FETCH_DATA_ERROR,
  ANNOUNCEMENTS_FETCH_DATA_REQUEST,
  ANNOUNCEMENTS_FETCH_DATA_SUCCESS,
} from './announcements.actionTypes';
import { type AnnouncementsState } from './announcements.state';

const initialState: AnnouncementsState = {
  isLoading: false,
  data: [],
};

export function announcementsReducer(state = initialState, action: AnnouncementsAction): AnnouncementsState {
  switch (action.type) {
    case ANNOUNCEMENTS_FETCH_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        data: [],
      };
    }

    case ANNOUNCEMENTS_FETCH_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ANNOUNCEMENTS_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }

    case ANNOUNCEMENT_MARK_AS_SEEN_REQUEST: {
      const newData = state.data.filter(value => value.id !== action.payload.announcementId);
      return {
        ...state,
        data: newData,
        isLoading: true,
      };
    }

    case ANNOUNCEMENT_MARK_AS_SEEN_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ANNOUNCEMENT_MARK_AS_SEEN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}
