import { type BoundaryTerritoryGroup } from '../boundaryTerritoryGroups/boundaryTerritoryGroups.state';
import {
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_ERROR,
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST,
  BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS, BOUNDARY_TERRITORY_DETAILS_UPDATE_MANUAL_ASSIGNMENTS,
} from './boundaryTerritoryDetails.actionTypes';
import { type BoundaryTerritoryGroupDetailsItem } from './boundaryTerritoryGroups.state';

export const boundaryTerritoryGroupsFetchDetailsRequest = (
  boundaryTerritoryGroups: ReadonlyArray<BoundaryTerritoryGroup>
) => ({
  type: BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_REQUEST,
  payload: {
    boundaryTerritoryGroups,
  },
}) as const;

export const boundaryTerritoryGroupsFetchDetailsSuccess = (
  results: Array<{territoryGroupId: number; details: BoundaryTerritoryGroupDetailsItem}>, filterHash: string
) => ({
  type: BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_SUCCESS,
  payload: {
    results,
    filterHash,
  },
}) as const;

export const boundaryTerritoryGroupsFetchDetailsError = () => ({
  type: BOUNDARY_TERRITORY_DETAILS_FETCH_DETAILS_ERROR,
}) as const;

export const boundaryTerritoryDetailsUpdateManualAssignments = (
  boundaryTerritoryGroupId: number, manualAssignments: {[boundaryTerritoryId: string]: number[]}
) => ({
  type: BOUNDARY_TERRITORY_DETAILS_UPDATE_MANUAL_ASSIGNMENTS,
  payload: {
    boundaryTerritoryGroupId,
    manualAssignments,
  },
}) as const;
