import {
  useCallback, useMemo,
} from 'react';
import {
  type SpreadsheetHeaderColumn, type SpreadsheetSortDescriptor,
} from '~/_shared/components/spreadsheet/spreadsheet.types';
import { type SpreadsheetMatchupData } from '~/_shared/types/spreadsheetData/matchupData';
import type {
  ColumnId, ColumnName,
} from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { SERVER_ADDED_COLUMN_NAMES } from '~/_shared/types/spreadsheetData/spreadsheetColumns.helpers';
import { BASE_MAPS_COLUMN_ID } from '~/map/layered/layering.repository';
import { useMatchupDataSelector } from '~/store/matchupData/matchupData.selectors';

export const useSpreadsheetDataRawColumns = ({ spreadsheetId, sortDescriptor }: {
  spreadsheetId: number | null;
  sortDescriptor?: SpreadsheetSortDescriptor;
}) => {
  const matchupData = useMatchupDataSelector(); // column names

  const spreadsheetMatchupData: SpreadsheetMatchupData | null =
    spreadsheetId !== null ? matchupData[spreadsheetId]?.data ?? null : null;

  const initColumnHeaderData = useCallback((columnId: ColumnId, columnName: ColumnName): SpreadsheetHeaderColumn => {
    const sortDescription = sortDescriptor?.[columnId];
    let index = 0;

    if (sortDescription && sortDescriptor) {
      const sortKeys = Object.keys(sortDescriptor);

      index = sortKeys.indexOf(columnId) + 1;
    }

    const isServerColumn = SERVER_ADDED_COLUMN_NAMES.has(columnName);

    // Base maps column is kindof a server column, but we don't want to flag it as such, so it won't be sorted
    const isDisabled = isServerColumn || (columnId === BASE_MAPS_COLUMN_ID);

    return {
      sort: sortDescription ? {
        index,
        type: sortDescription,
      } : undefined,
      id: columnId,
      name: columnName,
      disabled: isDisabled,
      isServerColumn,
    };
  }, [sortDescriptor]);

  const columns: SpreadsheetHeaderColumn[] = useMemo(() => {
    if (!spreadsheetMatchupData) {
      return [];
    }

    const { basicColumns, serverColumns } = Object.entries(spreadsheetMatchupData.columns)
      .filter((column): column is [string, string] => !!column[0] && !!column[1])
      .reduce((agg: { basicColumns: typeof columns; serverColumns: typeof columns }, [columnId, columnName]) => {
        const headerColumn = initColumnHeaderData(columnId, columnName);
        (headerColumn.isServerColumn ? agg.serverColumns : agg.basicColumns).push(headerColumn);
        return agg;
      }, { basicColumns: [], serverColumns: [] });

    // Sort columns. Server columns are always at the end
    return [...basicColumns, ...serverColumns];
  }, [initColumnHeaderData, spreadsheetMatchupData]);

  const isLoading = spreadsheetId !== null ? matchupData[spreadsheetId]?.isLoading : false;

  return {
    columns,
    isLoading,
  };
};
