import {
  useEffect, useRef,
} from 'react';

export const useOnVisibilityLost = (element: Element | null | undefined, onVisibilityLost: () => void) => {
  const onVisibilityLostRef = useRef(onVisibilityLost);
  onVisibilityLostRef.current = onVisibilityLost;

  useEffect(() => {
    if (!element) {
      return;
    }

    const intersectionCallback = (changes: IntersectionObserverEntry[]) => {
      for (const change of changes) {
        if (!change.isIntersecting) {
          onVisibilityLostRef.current();
        }
      }
    };

    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: [.1, .9],
    });
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [element]);

};
