import { css } from '@emotion/react';
import {
  type FC, useCallback,
} from 'react';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { type MapListingItem } from '../../item/mapListingItem';

type MapRowToggleProps = Readonly<{
  item: MapListingItem;
  onClick: (mapId: number) => void;
  areSnapshotsExpanded: boolean;
  className?: string;
  children?: React.ReactNode;
}>;

const toggleButtonStyle = css({
  background: 'none',
  border: 'none',
  padding: 0,
  color: 'inherit',
});

export const MobileMapRowToggleComponent: FC<MapRowToggleProps> = (props) => {
  const [t] = useTranslation();
  const { onClick } = props;

  const handleClick = useCallback(() =>
    onClick(props.item.id),
  [onClick, props.item.id]);

  if (props.item.snapshots.length === 0) {
    return <>{props.children}</>;
  }

  return (
    <button
      className={props.className}
      css={toggleButtonStyle}
      onClick={handleClick}
      title={t(props.areSnapshotsExpanded ? 'Collapse snapshots' : 'Expand snapshots')}
    >
      {props.children}
    </button>
  );
};
