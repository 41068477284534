import { RadioGroupComponent } from '~/_shared/baseComponents/radio/radioGroup.component';
import {
  fileSelectorStyle, fileSelectorWrapperStyle, fileTypeItemStyle,
} from '~/_shared/components/exportDataModal/exportMethodInputs.styles';
import { ImageSelectorComponent } from '~/_shared/components/imageSelector/imageSelector.component';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  ExportDataFileType, renderFileType,
} from '~/data/exportDataModal/exportDataFileType.enum';
import { ExportDataMethod } from '~/data/exportDataModal/exportDataMethod.enum';

const exportDataMethods: ExportDataMethod[] = [ExportDataMethod.File, ExportDataMethod.Clipboard];

export type Method = Readonly<
{ method: ExportDataMethod.Clipboard } |
{ method: ExportDataMethod.File; fileType: ExportDataFileType }
>;

export type ExportMethodInputsComponentProps = Readonly<{
  method: ExportDataMethod;
  onMethodChanged: (newMethod: ExportDataMethod) => void;
  fileType: ExportDataFileType;
  onFileTypeChanged: (newType: ExportDataFileType) => void;
}>;

export const ExportMethodInputsComponent = (props: ExportMethodInputsComponentProps) => {
  const { onMethodChanged, onFileTypeChanged, fileType, method } = props;
  const [t] = useTranslation();

  const items = exportDataMethods.map(method => {
    if (method === ExportDataMethod.File) {
      return {
        value: ExportDataMethod.File,
        label: t('Export as File'),
        style: fileTypeItemStyle,
        children: (
          <div css={fileSelectorWrapperStyle}>
            <div css={fileSelectorStyle}>
              <ImageSelectorComponent
                images={Object.values(ExportDataFileType).map(type => ({
                  id: type,
                  render: () => renderFileType(type),
                }))}
                selectedImagesIds={[fileType]}
                onSelectionChanged={(newTypes) => {
                  const newType = newTypes[0];
                  if (newType) {
                    onFileTypeChanged(newType);
                  }
                }}
              />
            </div>
          </div>
        ),
      };
    }
    else {
      return {
        value: ExportDataMethod.Clipboard,
        label: t('Copy Data to Clipboard'),
      };
    }
  });

  return (
    <RadioGroupComponent
      selectedValue={method}
      onValueChange={onMethodChanged}
      items={items}
    />
  );
};
