import {
  type FC, useCallback, useState,
} from 'react';
import { copy } from '~/_shared/utils/collections/collections';
import { type ModalProps } from '../../../modal/modalType.enum';
import { type EditDataTableRow } from '../editDataTable/editDataTable.component';
import {
  type ChangeableSettingRow, type SettingColumn, type SettingTextData,
} from '../settingsTable/settingsTable.types';
import { EditDataModalComponent } from './editDataModal.component';

export type EditDataModalContainerProps = ModalProps<{
  columns?: SettingColumn[];
  rows: EditDataTableRow[];
  onConfirm: (newValues: string[]) => void;
}>;

export const EditDataModalContainer: FC<EditDataModalContainerProps> = (props) => {
  const [rows, setRows] = useState(props.rows);

  const onSave = useCallback(() => {
    const newValues = rows.map(row => row.value);
    props.onConfirm(newValues);
    props.onClose();
  }, [props, rows]);

  const changeRow = useCallback((rowIndex: number, newRow: ChangeableSettingRow) => {
    const currentRow = rows[rowIndex];
    if (currentRow) {
      const newRows = copy.andReplace(rows, rowIndex, {
        ...currentRow,
        value: (newRow.data[1] as SettingTextData).value,
      });
      setRows(newRows);
    }
  }, [rows]);

  return (
    <EditDataModalComponent
      {...props}
      onChange={changeRow}
      onSubmit={onSave}
    />
  );
};
