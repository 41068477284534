import {
  type FC, useEffect,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { getMapOwnerUserId } from '~/map/map.helpers';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  useMapInfoDataSelector, useMapInfoPrivacySelector,
} from '~/store/mapInfo/mapInfo.selectors';
import { useMapIdSelector } from '~/store/selectors/useMapIdSelector';
import { useUserDataSelector } from '~/store/userData/userData.selectors';
import { type SidebarAppProps } from '../sidebarAppComponentProps.type';

export const SidebarShareMapContainer: FC<SidebarAppProps> = ({ onClose }) => {
  const [t] = useTranslation();
  const mapId = useMapIdSelector();
  const mapInfo = useMapInfoDataSelector();
  const mapPrivacyInfo = useMapInfoPrivacySelector();
  const currentUserId = useUserDataSelector().id;

  const mapName = mapInfo?.name;
  const isSnapshot = !!mapInfo?.parentMap;

  const { openModal } = useModal(ModalType.ShareMapSelect);
  const { replaceCurrentModal: openShareMapModal } = useModal(ModalType.ShareMap);
  const { replaceCurrentModal: openTeamShareMapModal } = useModal(ModalType.TeamShareMap);

  useEffect(() => {
    if (!mapId || !mapName) {
      return;
    }

    const commonProps = {
      isSnapshot,
      mapId,
      mapName,
      onClose,
    };

    const isCurrentUserOwner = mapInfo && getMapOwnerUserId(mapInfo) === currentUserId;

    openModal({
      isSnapshot,
      mapName,
      onPresentationalShareClick: () => {
        openShareMapModal({
          ...commonProps,
          parentMapPrivacy: mapPrivacyInfo?.parentMapPrivacy || null,
          mapSource: mapInfo?.source,
        });
      },
      onTeamShareClick: isCurrentUserOwner ? () => {
        openTeamShareMapModal(commonProps);
      } : undefined,
      onClose,
      teamShareError: !isCurrentUserOwner ? t('teamShareMap.onlyOwnerCanShare') : undefined,
    });
  }, [onClose, mapId, openModal, mapName, isSnapshot, mapPrivacyInfo?.parentMapPrivacy, openShareMapModal,
    openTeamShareMapModal, t, mapInfo, currentUserId]);

  return null;
};
