import { type UnitSystem } from '../../../_shared/types/googleMaps/googleMaps.types';
import { type LatLng } from '../../../_shared/types/latLng';
import { calculateRadiusInMeters } from './spreadsheetFilterRadius.helpers';
import { type RadiusFilterItemRequest } from './spreadsheetFilterRadius.types';

export type RadiusFilterRequestArguments = {
  radius: number;
  unit: UnitSystem;
  center: LatLng;
};

export const createRadiusFilterItemRequest = ({ radius, unit, center }: RadiusFilterRequestArguments): RadiusFilterItemRequest => {
  const centerRequest = {
    type: 'Point',
    coordinates: [center.lng, center.lat],
  };

  return {
    center: JSON.stringify(centerRequest),
    radius: calculateRadiusInMeters(radius, unit),
  };
};
