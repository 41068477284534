import { faSync } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { STORED_GOOGLE_MAPTIVE_SYNC_METHOD } from '~/_shared/hooks/appLoad/useGoogleOauthAppLoadResolver.hook';
import {
  areGoogleUserScopesSufficient,
  determineRequiredMaptiveScopes,
  GoogleAPIOperation,
} from '~/_shared/utils/googleApi/googleApi.helpers';
import { useTranslation } from '~/_shared/utils/hooks';
import { useCreateMapFromGoogleSheet } from '~/map/createNew/createMapFromGoogleSpreadsheet/useCreateMapFromGoogleSheet';
import { getMapUrl } from '~/map/map.helpers';
import { GoogleSheetMapSyncType } from '~/map/map.repository';
import {
  type ModalProps, ModalType,
} from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import {
  type GoogleSpreadsheetInfo, invokeGoogleOauth,
} from '~/spreadsheet/googleSpreadsheet/googleSheet.repository';
import {
  closeAllModals, createAppError,
} from '~/store/modal/modal.actionCreators';
import { useCurrentGoogleAccountIdSelector } from '~/store/selectors/googleUserSelectors.selector';
import { useGoogleApiOauthUserDataSelector } from '~/store/userData/userData.selectors';
import {
  type StorageService, useStorageService,
} from '../../_shared/utils/storageService';
import { AppErrorType } from '../../appError/appErrorType.enum';
import { useGetCurrentPageInfo } from '../../topBar/navigation/navigation.helpers';
import { useGetGoogleSheetDataAsColumnsWithValues } from './dataSource/useGetGoogleSheetDataAsColumnsWithValues.hook';
import { useHandleDataSourceErrors } from './dataSource/useHandleDataSourceErrors.hook';
import { SyncDataContainer } from './syncData.container';

export const STORED_CREATE_MAP_NAME = 'google_sheet_creating_map_name';
export const STORED_CREATE_MAP_DESCRIPTION = 'google_sheet_creating_map_desc';

export type BasicMapInfo = Readonly<{
  name: string;
  description?: string;
}>;

type SelectedGoogleSpreadsheetAndSheet = Readonly<{
  googleSpreadsheetInfo: GoogleSpreadsheetInfo;
  sheetId: string;
}>;

const getInitialMapInfo = (storageService: StorageService, initialMapInfo?: BasicMapInfo) => {
  if (initialMapInfo) {
    return initialMapInfo;
  }

  // this means that we have been returned from google oauth, so let's use the localStorage(d) data
  const storedMapName = storageService.getLocalStorageItem(STORED_CREATE_MAP_NAME);
  if (storedMapName) {
    const storedDescription = storageService.getLocalStorageItem(STORED_CREATE_MAP_DESCRIPTION);
    storageService.removeLocalStorageItem(STORED_CREATE_MAP_NAME);
    storageService.removeLocalStorageItem(STORED_CREATE_MAP_DESCRIPTION);

    return {
      name: storedMapName,
      description: storedDescription,
    } as BasicMapInfo;
  }

  return null;
};

export type SyncDataModalForCreatingContainerProps = ModalProps<{
  initialMapInfo?: BasicMapInfo;
  preselectedSyncMethod?: GoogleSheetMapSyncType;
  selectedGoogleSpreadsheetAndSheet?: SelectedGoogleSpreadsheetAndSheet;
  skipSyncSelectStep?: boolean;
}>;

export const SyncDataModalForCreatingContainer: FC<SyncDataModalForCreatingContainerProps> = props => {
  const { selectedGoogleSpreadsheetAndSheet } = props;

  const [t] = useTranslation();
  const currentGoogleAccId = useCurrentGoogleAccountIdSelector();
  const currentPageInfo = useGetCurrentPageInfo();
  const dispatch = useDispatch();
  const googleUserScopes = useGoogleApiOauthUserDataSelector()?.scopes;
  const navigate = useNavigate();
  const { createMapFromGoogleSheet, errorMessage: createMapErrorMessage, isLoading: isCreatingMap } = useCreateMapFromGoogleSheet();
  const { replaceCurrentModal: closeCurrentModalAndOpenGoogleSpreadsheetSelector } = useModal(ModalType.GoogleSpreadsheetSelector);
  const { replaceCurrentModal: closeCurrentModalAndOpenCreateNewMap } = useModal(ModalType.CreateNewMap);
  const { replaceCurrentModal: openNewCreateGooogleSheetMapModal } = useModal(ModalType.CreateGooogleSheetMap);
  const storageService = useStorageService();

  const [mapInfo] = useState<BasicMapInfo | null>(() => getInitialMapInfo(storageService, props.initialMapInfo));
  const [lastSelectedSyncMethod, setLastSelectedSyncMethod] = useState<GoogleSheetMapSyncType | null>(props.preselectedSyncMethod || null);

  const dataSourceParams = useMemo(() => (selectedGoogleSpreadsheetAndSheet ? {
    spreadsheetId: selectedGoogleSpreadsheetAndSheet.googleSpreadsheetInfo.spreadsheetId,
    sheetId: selectedGoogleSpreadsheetAndSheet.sheetId,
  } : null), [selectedGoogleSpreadsheetAndSheet]);

  const dataSource = useGetGoogleSheetDataAsColumnsWithValues(dataSourceParams);

  const storeDataAndInvokeGoogleOauth = useCallback((syncMethod: GoogleSheetMapSyncType | null) => {
    if (!mapInfo) {
      return;
    }

    if (!syncMethod) {
      syncMethod = GoogleSheetMapSyncType.GoogleToMaptive;
    }

    storageService.setLocalStorageItem(STORED_GOOGLE_MAPTIVE_SYNC_METHOD, syncMethod);
    storageService.setLocalStorageItem(STORED_CREATE_MAP_NAME, mapInfo.name);
    storageService.setLocalStorageItem(STORED_CREATE_MAP_DESCRIPTION, mapInfo.description || '');

    const maptiveScope = determineRequiredMaptiveScopes(syncMethod);

    invokeGoogleOauth({
      operation: GoogleAPIOperation.CREATE_MAP_FROM_SHEET,
      scopes: maptiveScope,
      callbackUrlSuffix: currentPageInfo.path,
    });
  }, [storageService, currentPageInfo.path, mapInfo]);

  const onGoogleAuthFailedDataSourceFetchError = useCallback(() => {
    storeDataAndInvokeGoogleOauth(lastSelectedSyncMethod);
  }, [lastSelectedSyncMethod, storeDataAndInvokeGoogleOauth]);

  useHandleDataSourceErrors(dataSource, onGoogleAuthFailedDataSourceFetchError);

  const submitSyncButtonProps = useMemo(() => ({
    prefixIcon: faSync,
    text: t('Create New Map'),
  }), [t]);

  const onCreateMapSuccess = useCallback((mapId: number) => {
    dispatch(closeAllModals());
    navigate(getMapUrl(mapId));
  }, [navigate, dispatch]);

  const onSyncMethodSelected = useCallback((syncMethod: GoogleSheetMapSyncType) => {
    setLastSelectedSyncMethod(syncMethod);
    const maptiveScope = determineRequiredMaptiveScopes(syncMethod);
    if (mapInfo && googleUserScopes && areGoogleUserScopesSufficient(maptiveScope, googleUserScopes)) {
      if (!selectedGoogleSpreadsheetAndSheet) {
        closeCurrentModalAndOpenGoogleSpreadsheetSelector({
          onGoogleSpreadsheetSheetSelected: (googleSpreadsheetInfo, sheetId) => {
            openNewCreateGooogleSheetMapModal({
              initialMapInfo: mapInfo,
              preselectedSyncMethod: syncMethod,
              selectedGoogleSpreadsheetAndSheet: { googleSpreadsheetInfo, sheetId },
              skipSyncSelectStep: true,
            });
          },
          onGoogleAuthError: () => {
            storeDataAndInvokeGoogleOauth(syncMethod);
          },
        });
        return false;
      }
    }
    else if (maptiveScope && mapInfo) {
      storeDataAndInvokeGoogleOauth(syncMethod);
      return false;
    }
    return true;
  }, [mapInfo, googleUserScopes, selectedGoogleSpreadsheetAndSheet,
    closeCurrentModalAndOpenGoogleSpreadsheetSelector, openNewCreateGooogleSheetMapModal,
    storeDataAndInvokeGoogleOauth,
  ]);

  const onSyncFormSubmitted = useCallback((syncMethod: GoogleSheetMapSyncType, columnIndexes: number[]) => {
    setLastSelectedSyncMethod(syncMethod);
    if (selectedGoogleSpreadsheetAndSheet && mapInfo) {
      createMapFromGoogleSheet({
        mapDescription: mapInfo.description || '',
        mapName: mapInfo.name,
        selectedGoogleSpreadsheetInfo: selectedGoogleSpreadsheetAndSheet.googleSpreadsheetInfo,
        sheetId: selectedGoogleSpreadsheetAndSheet.sheetId,
        uniqueIdentifierColumnIds: columnIndexes,
        syncMethod,
        successCallback: onCreateMapSuccess,
      });
    }
  }, [selectedGoogleSpreadsheetAndSheet, mapInfo, createMapFromGoogleSheet, onCreateMapSuccess]);

  useEffect(() => {
    if (mapInfo === null) {
      closeCurrentModalAndOpenCreateNewMap({});
    }
  }, [closeCurrentModalAndOpenCreateNewMap, mapInfo]);

  useEffect(() => {
    if (currentGoogleAccId && createMapErrorMessage) {
      dispatch(createAppError({
        type: AppErrorType.General,
        title: t('Unable To Create Your Map'),
        content: createMapErrorMessage,
      }));
    }
  }, [createMapErrorMessage, currentGoogleAccId, dispatch, t]);

  return (
    <SyncDataContainer
      {...props}
      dataSource={dataSource}
      initialSyncMethod={props.preselectedSyncMethod || GoogleSheetMapSyncType.TwoWaySync}
      isLoading={dataSource.isLoading || isCreatingMap}
      modalCaption={t('Create a New Map with Google Sheets.')}
      skipSyncSelectStep={props.skipSyncSelectStep}
      submitSyncButtonProps={submitSyncButtonProps}
      onSyncFormSubmitted={onSyncFormSubmitted}
      onSyncMethodSelected={onSyncMethodSelected}
    />
  );
};
