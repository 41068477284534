import { Trans } from 'react-i18next';
import { AnchorLink } from '~/_shared/baseComponents/links';
import { useClientOwner } from '~/store/userData/userData.selectors';
import { s } from '~/translations/Trans';
import { ErrorPageContentGuardianDogComponent } from '../../errorPageContent/guardianDog';

export const AppErrorPageAccessForbiddenComponent = () => {
  const clientOwner = useClientOwner();

  return (
    <ErrorPageContentGuardianDogComponent>
      <Trans
        i18nKey="AccessForbiddenForCurrentRole"
      >
        {s('This page can be accessed only by users with a certain role. To access this page, contact your ')}
        <AnchorLink
          href={`mailto:${clientOwner?.email ?? ''}`}
        >
          Maptive account owner
        </AnchorLink>
      </Trans>
    </ErrorPageContentGuardianDogComponent>
  );
};
