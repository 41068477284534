import { type TwoFactorMode } from '~/_shared/constants/twoFactorMode.enum';
import {
  type ClientLicenseCode, type ClientLicenseName, type ClientLicenseState, type ClientLicenseType,
} from '~/_shared/types/client/license';
import { type MFAErrorCode } from '~/_shared/types/responseErrors/_shared/MFAErrorCodes.types';
import {
  apiDelete, apiGet, apiPatch, apiPost,
} from '~/_shared/utils/api/api.helpers';
import { type GoogleApiScope } from '~/_shared/utils/googleApi/googleApi.helpers';
import { type UserRegisterData } from '~/authorization/registerPage/form/useUserRegister';
import type { MemberRole } from '~/clientTeamManagement/teamManagementModal/memberRole.type';
import { type Member } from '~/clientTeamManagement/teamManagementModal/teamManagement.repository';
import type {
  UpdateUserErrorResponse, UpdateUserSuccessResponse,
} from '~/store/userData/repository/updateUserData.types';
import { type UserSettingsState } from '../settings/userDataSettings.state';
import { type Role } from '../userData.state';

type AuthenticationResponse = {
  message: string;
  refresh?: string;
  access_token_expiration?: string;
};

export type MFARequiredResponse = {
  message: 'Multi factor authentication is required!';
  data: {
    list: ReadonlyArray<TwoFactorMode>;
  };
};

export const isMFARequiredResponse = (response: any): response is MFARequiredResponse => response?.code === '2FA_REQUIRED';

type GoogleUserResponse = {
  available_google_accounts: {
    [userId: string]: {
      display_name: string;
      email: string;
      scopes: string[];
    };
  };
  currently_used_google_acc_id: string;
};

export type UserResponse = Readonly<{
  '2fa': TwoFactorStats;
  client_role: MemberRole;
  created_at: string;
  current_client: CurrentClientResponse;
  email: string;
  first_name: string;
  google_api_oauth: GoogleUserResponse | null;
  id: number;
  intercom_hash: string;
  last_name: string;
  public_api?: boolean;
  role: Role;
  updated_at: string;
}>;

export enum PaymentType {
  CARD ='card',
  MANUAL ='manual'
}

export type CurrentClientResponse = {
  id: number;
  license: {
    auto_renewal: boolean;
    autopay: boolean;
    code: ClientLicenseCode | null;
    end: string;
    name: ClientLicenseName | null;
    payment_type: PaymentType;
    start: string;
    state: ClientLicenseState;
    type: ClientLicenseType;
    last_state_change: string;
  };
  migration_done: boolean;
};

export type CurrentClient = {
  id: number;
  license: {
    autoRenewal: boolean;
    autopay: boolean;
    code: ClientLicenseCode;
    end: Date | null;
    name: ClientLicenseName;
    paymentType: PaymentType;
    lastStateChange: Date | null;
    start: Date | null;
    state: ClientLicenseState;
    type: ClientLicenseType;
  };
  migrationDone: boolean;
};

export type TwoFactorStats = {
  email: boolean;
  google: boolean;
};

export type UsageStatsResponse = {
  api_views: number;
  cached_geocodes: number;
  clients_count: number;
  created_maps: [];
  directions_api_requests: null;
  google_places_requests: number;
  last_login: string;
  map_views: number;
  markers: number;
  previous_login: string | null;
  real_geocodes: number;
  three_months_logins: number;
  total_geocodes: number;
  distance_matrix_requests: number;
};

export type LimitsResponse = {
  map_view: number;
  maps: number;
  real_geocodes: number;
  cached_geocodes: number;
  markers: number;
  directions_api_requests: number;
  google_places_requests: number;
  user_limit: number;
  can_export_shared_maps: boolean;
  wms_enabled: boolean;
  distance_matrix_requests: number;
};

export type ClientResponse = Readonly<{
  id: number;
  name: string;
  type: string;
  role: string;
  created_at: string;
  updated_at: string;
  limits?: LimitsResponse;
  usage_stats?: UsageStatsResponse;
  members: Member[];
  migration_done: boolean;
}>;

export type UserDataResponse = Readonly<{
  data: UserResponse & {
    clients?: ClientResponse[];
    current_client?: CurrentClientResponse;
    limits: LimitsResponse;
    settings: UserSettingsState;
    usage_stats: UsageStatsResponse;
  };
}>;

type UpdateUserBasicInfoRequest = {
  first_name?: string;
  last_name?: string;
  mfa?: string;
  code?: string;
};

type UpdateUserPassword = {
  password: string;
  password_confirmation: string;
  old_password: string;
};

type UpdateUserEmail = {
  email: string;
  old_password: string;
};

export type UpdateUserRequest = UpdateUserBasicInfoRequest | (UpdateUserBasicInfoRequest & (UpdateUserPassword | UpdateUserEmail));

type AuthenticateUserRequest = {
  email: string;
  password: string;
  remember_me: boolean;
};

type TwoFactorEmailRequest = {
  email: string;
  password: string;
  mfa: string;
};

type TwoFactorRequest = {
  email: string;
  password: string;
  remember_me: boolean;
  mfa: string;
  code: string;
};

type RegisterRequest = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  company_name?: string;
};

export type UserRegisterErrors = {
  email?: string[];
  password?: string[];
};

type UserRegisterResponse = {
  message: string;
  errors: UserRegisterErrors;
};

type DeleteClientRequestData = {
  mfaCode?: string;
  mfaMode?: TwoFactorMode | null;
  password: string;
};

export type DeleteClientResponseCodes = MFAErrorCode | DeleteClientResponseCode;

export enum DeleteClientResponseCode {
  SERVER_ERROR = 'SERVER_ERROR',
  BAD_REQUEST = 'BAD_REQUEST',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum DeleteClientCodeError {
  CODE_WRONG_NUMBER_OF_CHARACTERS = 'The code must be 6 characters.'
}

export type DeleteClientResponse = {
  code: DeleteClientResponseCodes;
  data?: {
    list?: TwoFactorMode[];
  };
  errors?: {
    code?: DeleteClientCodeError[];
  };

};

export type GoogleApiOauthStatus = {
  displayName: string;
  email: string;
  scopes: GoogleApiScope[];
  googleAccId: string;
};

type UpdateClientDataRequest = {
  name?: string;
};

type RefreshAccessTokenResponse = {
  message: string;
  refresh?: string;
  access_token_expiration?: string;
};

export enum ClientDataErrorCode {
  lengthExceeded = 'The name may not be greater than 255 characters.', // This has not been changed yet by the BE
}

export type ClientErrorResponse = Readonly<{
  errors: Readonly<{
    name: ReadonlyArray<ClientDataErrorCode | string>;
  }>;
}>;

export const updateClientData = (clientId: number, request: UpdateClientDataRequest) => {
  const requestUrl = `/api/clients/${clientId}`;

  return apiPatch<ClientResponse | ClientErrorResponse>(requestUrl, request);
};

export const loginEndpointUrl = '/api/auth/login';

export const authenticateUser = (email: string, password: string, rememberMe: boolean): Promise<AuthenticationResponse> => {
  const requestParams: AuthenticateUserRequest = {
    email,
    password,
    remember_me: rememberMe,
  };

  return apiPost(loginEndpointUrl, requestParams);
};

export const sendTwoFactorEmailRequest = (email: string, password: string): Promise<AuthenticationResponse> => {
  const requestParams: TwoFactorEmailRequest = {
    email,
    password,
    mfa: 'email',
  };

  return apiPost(loginEndpointUrl, requestParams);
};

export const authenticateTwoFactorUser = (email: string, password: string, rememberMe: boolean, mfa: string, code: string): Promise<AuthenticationResponse> => {
  const requestParams: TwoFactorRequest = {
    email,
    password,
    remember_me: rememberMe,
    mfa,
    code,
  };

  return apiPost(loginEndpointUrl, requestParams);
};

export const registerUser = (data: UserRegisterData): Promise<UserRegisterResponse> => {
  const requestUrl = '/api/auth/register';
  const requestParams: RegisterRequest = {
    first_name: data.firstName,
    company_name: data.companyName,
    last_name: data.lastName,
    password_confirmation: data.passwordConfirmation,
    email: data.email,
    password: data.password,
  };

  return apiPost(requestUrl, requestParams);
};

export const userDataEndpointUrl = '/api/user';

export const getUserData = (): Promise<UserDataResponse> => {
  const requestUrl = userDataEndpointUrl;
  const requestParams = {
    with: 'clients,limits,usage_stats,settings',
  };

  return apiGet(requestUrl, requestParams);
};

export const signOutUser = (): Promise<void> => {
  const requestUrl = '/api/auth/logout';

  return apiPost(requestUrl);
};

export const updateUserData = (request: UpdateUserRequest): Promise<UpdateUserSuccessResponse | UpdateUserErrorResponse> => {
  return apiPatch(userDataEndpointUrl, request);
};

export const refreshTokensEndpointUrl = '/api/auth/refresh';
export const refreshAccessToken = (refreshToken: string): Promise<RefreshAccessTokenResponse> => {
  const requestParams = {
    token: refreshToken,
  };

  return apiPost(refreshTokensEndpointUrl, requestParams);
};

export const deleteClientData = (clientId: number, params: DeleteClientRequestData): Promise<DeleteClientResponse> => {
  const requestUrl = `/api/clients/${clientId}`;
  const requestParams = {
    password: params.password,
    ...(params.mfaMode ? { mfa: params.mfaMode } : {}),
    ...(params.mfaCode ? { code: params.mfaCode } : {}),
  };

  return apiDelete(requestUrl, requestParams);
};
