import { type MapSettingsStylesChangeData } from '~/store/mapSettings/mapStyles/mapSettingsMapStyles.state';
import {
  BaseMapElementTypeName,
  BaseMapFeatureTypeName,
} from '../baseMap.enums';

export const baseMapThemesMint: MapSettingsStylesChangeData = [
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#87bb6d',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['road.highway'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#87bb6d',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['geometry.stroke'],
    stylers: {
      color: {
        isActive: true,
        value: '#07381a',
      },
      weight: {
        isActive: true,
        value: 0.5,
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.road,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#07381a',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#07af8f',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.water,
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#235451',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName.poi,
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#96ea85',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.landcover'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#c1eed5',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.natural.terrain'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#86ea56',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName.geometry,
    stylers: {
      color: {
        isActive: true,
        value: '#a9eac6',
      },
    },
  },
  {
    featureType: BaseMapFeatureTypeName['landscape.humanMade'],
    elementType: BaseMapElementTypeName['labels.text.fill'],
    stylers: {
      color: {
        isActive: true,
        value: '#1e7246',
      },
    },
  },
];
