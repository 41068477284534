import {
  type SetStateAction, useCallback, useRef, useState,
} from 'react';
import { createUuid } from '~/_shared/utils/createUuid';
import { useAjaxCall } from '~/_shared/utils/hooks/useAjaxCall';
import { notNullOrUndefined } from '~/_shared/utils/typeGuards';
import { useSpreadsheetRowCount } from '~/spreadsheet/useSpreadsheetRowCount';
import { getBoundaryGroupItemsList } from '~/store/boundaries/boundaries.repository';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import {
  type CreateBoundaryGroupFromAIConfig, NumberOfTerritoriesLastUpdateReason, type SalesRepLocationsMap,
} from './createBoundaryGroupFromAIModal.types';
import { WMS_MIN_NUMBER_OF_TERRITORIES } from './modalSteps/additionalOptions.component';

type UseNumberOfTerritoriesCountParams = {
  config: CreateBoundaryGroupFromAIConfig;
  setConfig: (state: SetStateAction<CreateBoundaryGroupFromAIConfig>) => void;
};

const applyComputedTerritoriesCount = (setConfig: (state: SetStateAction<CreateBoundaryGroupFromAIConfig>) => void) =>
  setConfig(config => {
    const numberOfTerritories = config.useNumberOfTerritories
      ? (config.respectSalesLocations ? config.numberOfSalesReps
        : (config.respectPrevTerritories ? config.numberOfPreviousTerritories
          : null)) : null;

    return ({
      ...config,
      ...(notNullOrUndefined(numberOfTerritories) && numberOfTerritories >= WMS_MIN_NUMBER_OF_TERRITORIES ? { numberOfTerritories } : undefined),
    });
  });

const fetchNumberOfPreviousTerritories = (clientId: number, boundaryGroupId: BoundaryGroupId) =>
  getBoundaryGroupItemsList(clientId, boundaryGroupId).then(boundaryList => boundaryList.length);

export const useNumberOfTerritoriesCount = ({ config, setConfig }: UseNumberOfTerritoriesCountParams) => {
  const clientId = useClientIdSelector();

  const [numberOfTerritoriesUpdateReason, setNumberOfTerritoriesUpdateReason] = useState(NumberOfTerritoriesLastUpdateReason.Other);
  const lastInvokeId = useRef<string | null>(null);

  const { isLoading: salesRepsCountLoading, getSpreadsheetRowCount: getSalesRepsCount } = useSpreadsheetRowCount();
  const { isLoading: previousTerritoriesCountLoading, invokeAjax: getPreviousTerritoriesCount } = useAjaxCall(fetchNumberOfPreviousTerritories);

  const refreshSalesRepsCount = useCallback((params?: {
    autoRefresh?: boolean;
    salesRepLocationsMap?: SalesRepLocationsMap | null;
  }) => {
    if (config.respectSalesLocations) {
      const salesRepLocationsMap = params?.salesRepLocationsMap ?? config.salesRepLocationsMap;
      if (!salesRepLocationsMap) {
        return;
      }

      const localInvokeId = createUuid();
      lastInvokeId.current = localInvokeId;

      getSalesRepsCount(salesRepLocationsMap).then(count => {
        if (localInvokeId !== lastInvokeId.current) {
          return;
        }

        if (params?.autoRefresh) {
          setNumberOfTerritoriesUpdateReason(NumberOfTerritoriesLastUpdateReason.SalesRepLocationsMapIdChange);
        }

        setConfig(previous => ({
          ...previous,
          numberOfSalesReps: count,
        }));

        applyComputedTerritoriesCount(setConfig);
      });
    }
  }, [config.respectSalesLocations, config.salesRepLocationsMap, getSalesRepsCount, setConfig]);

  const refreshPreviousNumberOfTerritories = useCallback((params?: {
    autoRefresh?: boolean;
    previouslySavedBoundaryGroupId?: number | null;
  }) => {
    if (config.respectPrevTerritories) {
      const previouslySavedBoundaryGroupId = params?.previouslySavedBoundaryGroupId ?? config.previouslySavedBoundaryGroupId;
      if (!previouslySavedBoundaryGroupId || !clientId) {
        return;
      }

      const localInvokeId = createUuid();
      lastInvokeId.current = localInvokeId;

      getPreviousTerritoriesCount(clientId, previouslySavedBoundaryGroupId).then(count => {
        if (localInvokeId !== lastInvokeId.current) {
          return;
        }

        if (params?.autoRefresh) {
          setNumberOfTerritoriesUpdateReason(NumberOfTerritoriesLastUpdateReason.RespectPreviousTerritoriesChange);
        }

        setConfig(previous => ({
          ...previous,
          numberOfPreviousTerritories: count,
        }));

        applyComputedTerritoriesCount(setConfig);
      });
    }
  }, [clientId, config.previouslySavedBoundaryGroupId, config.respectPrevTerritories, getPreviousTerritoriesCount, setConfig]);

  const handleNumberOfTerritoriesChange = useCallback((update: Partial<CreateBoundaryGroupFromAIConfig>) => {
    const { salesRepLocationsMap, previouslySavedBoundaryGroupId, numberOfTerritories, respectSalesLocations } = update;

    if (numberOfTerritories !== undefined) {
      setNumberOfTerritoriesUpdateReason(NumberOfTerritoriesLastUpdateReason.ManualEdit);
    }

    if (salesRepLocationsMap) {
      refreshSalesRepsCount({ autoRefresh: true, salesRepLocationsMap });
    }

    if (previouslySavedBoundaryGroupId || (respectSalesLocations === false)) {
      refreshPreviousNumberOfTerritories({ autoRefresh: true, previouslySavedBoundaryGroupId });
    }
  }, [refreshPreviousNumberOfTerritories, refreshSalesRepsCount]);

  const onRefreshTerritoriesCount = useCallback(() => {
    if (config.respectSalesLocations) {
      refreshSalesRepsCount();
    }
    else {
      refreshPreviousNumberOfTerritories();
    }
  }, [config.respectSalesLocations, refreshPreviousNumberOfTerritories, refreshSalesRepsCount]);

  const numberOfTerritoriesLoading = (config.respectSalesLocations && salesRepsCountLoading)
    || (config.respectPrevTerritories && previousTerritoriesCountLoading);

  return {
    numberOfTerritoriesUpdateReason,
    onRefreshTerritoriesCount,
    handleNumberOfTerritoriesChange,
    numberOfTerritoriesLoading,
  };
};
