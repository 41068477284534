// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.

import {
  cancalledPromiseError, delay,
} from './delay';
import { type CancellablePromise } from './types/common.type';

type Now<T> = () => T | undefined;

export type DebouncedFunction<A extends any[], T> = {
  readonly now: Now<T>;
  readonly cancel: () => void;
  readonly isPending: () => boolean;
  (..._args: A): {
    readonly now: Now<T>;
  };
};

function debounceInternal<A extends any[], T>(func: (...args: A) => T, wait: number): DebouncedFunction<A, T> {
  let timeout: CancellablePromise<T | undefined> | null;
  let args: unknown[];
  let context: unknown;
  let later: () => T | undefined;

  // Has to be 'function' because of execution context.
  const res = function (this: any, ...latestArgs: unknown[]): { readonly now: Now<T> } {
    context = this;
    args = latestArgs;
    later = (): T | undefined => {
      timeout = null;
      return func.apply(context, args);
    };

    if (timeout) {
      timeout.cancel();
      timeout = null;
    }

    timeout = delay(wait).then(later, e => {
      // ignore errors caused by cancelling the promise
      if (e !== cancalledPromiseError) {
        throw e;
      }

      return undefined;
    });

    return {
      now: (): T | undefined => {
        if (timeout) {
          timeout.cancel();
          timeout = null;
          return func.apply(context, args);
        }
        else {
          return undefined;
        }
      },
    };
  };

  return Object.assign(res, {
    now: (): T | undefined => {
      if (timeout) {
        timeout.cancel();
        timeout = null;
        return later();
      }
      return undefined;
    },
    cancel: (): void => {
      if (timeout) {
        timeout.cancel();
        timeout = null;
      }
    },
    isPending: (): boolean => {
      return !!timeout;
    },
  });
}

export function debounce<A extends any[], T>(func: (...args: A) => T, wait: number): DebouncedFunction<A, T> {
  return debounceInternal<A, T>(func, wait);
}
