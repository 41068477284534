import { isMobileDevice } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { SLIDING_ANIMATION_DURATION } from '../../../map/map/useGraphicSettings.hook';
import { type GraphicSettingsAction } from './graphicSettings.action';
import {
  GRAPHIC_SETTINGS_RESET,
  GRAPHIC_SETTINGS_SET_ALLOW_IDLE_ANIMATIONS,
  GRAPHIC_SETTINGS_SET_FORCED_CLUSTERING,
  GRAPHIC_SETTINGS_SET_MARKER_SHADOWS,
  GRAPHIC_SETTINGS_SET_SLIDE_ANIMATION_DURATION,
} from './graphicSettings.actionTypes';
import { type GraphicSettingsState } from './graphicSettings.state';

// on mobile devices we start with animations off because they have the potential to kill the page
const graphicSettingsInitialState: GraphicSettingsState = {
  allowIdleAnimations: !isMobileDevice(),
  forcedClustering: false,
  showMarkerShadows: true,
  slideAnimationDuration: isMobileDevice() ? 0 : SLIDING_ANIMATION_DURATION,
};

export const graphicSettingsReducer = (
  state = graphicSettingsInitialState,
  action: GraphicSettingsAction
): GraphicSettingsState => {
  switch (action.type) {

    case GRAPHIC_SETTINGS_SET_MARKER_SHADOWS: {
      return {
        ...state,
        showMarkerShadows: action.payload.newValue,
      };
    }

    case GRAPHIC_SETTINGS_SET_FORCED_CLUSTERING: {
      return {
        ...state,
        forcedClustering: action.payload.newValue,
      };
    }

    case GRAPHIC_SETTINGS_SET_ALLOW_IDLE_ANIMATIONS: {
      return {
        ...state,
        allowIdleAnimations: action.payload.newValue,
      };
    }

    case GRAPHIC_SETTINGS_SET_SLIDE_ANIMATION_DURATION: {
      return {
        ...state,
        slideAnimationDuration: action.payload.newValue,
      };
    }

    case GRAPHIC_SETTINGS_RESET: {
      return graphicSettingsInitialState;
    }

    default:
      return state;
  }
};
