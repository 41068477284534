import { css } from '@emotion/react';
import {
  type FC, useCallback, useMemo,
} from 'react';
import { AccordionComponent } from '~/_shared/baseComponents/accordion';
import { OutlinePanelComponent } from '~/_shared/baseComponents/outlinePanel/outlinePanel.component';
import { OutlinePanelOptions } from '~/_shared/baseComponents/outlinePanel/outlinePanel.constants';
import { ModalComponent } from '~/_shared/components/modal/modal.component';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { type ModalProps } from '~/modal/modalType.enum';
import {
  AdditionalOptionsStepComponent, WMS_MIN_NUMBER_OF_TERRITORIES,
} from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/additionalOptions.component';
import { BuildingBlockStepComponent } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/buildingBlockStep.component';
import { VariablesStepComponent } from '~/sidebar/sidebarApps/mapTools/boundary/loadBoundaryPane/createCustomBoundaryPane/createBoundaryGroupFromAIModal/modalSteps/variablesStep.component';
import { type GeoUnits } from '~/store/boundaries/boundaries.repository';
import {
  getTargetNumberOfTerritories, isStepExpanded, isStepLocked,
} from './createBoundaryGroupFromAIModal.helpers';
import {
  BoundaryGroupFromAIModalSteps, BuildingBlockSelections, type BuildingBlockSubsetTypes, type CreateBoundaryGroupFromAIConfig,
  type MetricValue, type NumberOfTerritoriesLastUpdateReason, type SalesRepLocationsMap,
} from './createBoundaryGroupFromAIModal.types';
import { NameStepComponent } from './modalSteps/nameStep.component';

export type CreateBoundaryGroupFromAIComponentProps = Readonly<{
  config: CreateBoundaryGroupFromAIConfig;
  currentStep: BoundaryGroupFromAIModalSteps;
  customBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  isGenerateOptimizedTerritoriesLoading: boolean;
  isLoadingSubsetBlocks?: boolean;
  isLoadingDataMetrics: boolean;
  numberOfTerritoriesLoading: boolean;
  numberOfTerritoriesUpdateReason: NumberOfTerritoriesLastUpdateReason;
  markerFilterActive: boolean;
  onChange: (update: Partial<CreateBoundaryGroupFromAIConfig>) => void;
  onCloseOptimizedTerritoriesRequestError: () => void;
  onGoToStep: (step: BoundaryGroupFromAIModalSteps) => void;
  onRefreshTerritoriesCount: () => void;
  onSelectSubsetFromMap: () => void;
  onSubmit: () => void;
  optimizedTerritoriesRequestError: string | null;
}>;

const itemButtonStyle = css({
  textTransform: 'uppercase',
});

export const CreateBoundaryGroupFromAIComponent: FC<CreateBoundaryGroupFromAIComponentProps> = ({
  config,
  currentStep,
  customBoundaryGroups,
  isGenerateOptimizedTerritoriesLoading,
  isLoadingSubsetBlocks,
  isLoadingDataMetrics,
  numberOfTerritoriesUpdateReason,
  markerFilterActive,
  onChange,
  onCloseOptimizedTerritoriesRequestError,
  onGoToStep,
  onRefreshTerritoriesCount,
  numberOfTerritoriesLoading,
  onSelectSubsetFromMap,
  onSubmit,
  optimizedTerritoriesRequestError,
}: CreateBoundaryGroupFromAIComponentProps) => {
  const [t] = useTranslation();

  const isExpandedPerStep = useMemo(() => isStepExpanded(currentStep), [currentStep]);
  const isLockedPerStep = useMemo(() => isStepLocked(currentStep), [currentStep]);

  const numberOfTerritories = getTargetNumberOfTerritories(config);

  const onChangeGroupName = useCallback((value: string) => {
    onChange({ groupName: value });
  }, [onChange]);

  const onChangeBuildingBlocks = useCallback((value: GeoUnits, allStates: boolean) => {
    onChange({
      buildingBlock: value,
      allStates,
      ...(config.buildingBlock !== value ? { selectedBoundaryIds: [] } : undefined),
    });
  }, [config.buildingBlock, onChange]);

  const onChangeDataMetrics = useCallback((value: MetricValue[]) => {
    onChange({ dataMetricsValues: value });
  }, [onChange]);

  const onChangeDemographics = useCallback((value: MetricValue[]) => {
    onChange({ demographicsValues: value });
  }, [onChange]);

  const onChangeUseNumberOfTerritories = useCallback((flag: boolean) => {
    onChange({
      useNumberOfTerritories: flag,
      ...(flag ? { numberOfTerritories: numberOfTerritories ?? WMS_MIN_NUMBER_OF_TERRITORIES } : { numberOfTerritories: null }),
    });
  }, [numberOfTerritories, onChange]);

  const onChangeApplyFilterOnData = useCallback((flag: boolean) => {
    onChange({ applyFilterOnData: flag });
  }, [onChange]);
  const showApplyDataFilterOption = markerFilterActive && config.dataMetricsValues.some(metric => metric.checked);

  const onChangeRespectSalesLocations = useCallback((flag: boolean) => {
    onChange({
      respectSalesLocations: flag,
      salesRepLocationsMap: null,
      numberOfSalesReps: null,
    });
  }, [onChange]);

  const onChangeSalesRepLocationsMapId = useCallback((map: SalesRepLocationsMap) => {
    onChange({ salesRepLocationsMap: map });
  }, [onChange]);

  const onChangeSalesRepNameColumnId = useCallback((columnId: string) => {
    onChange({ salesRepNameColumnId: columnId });
  }, [onChange]);

  const onChangeRespectPrevTerritories = useCallback((flag: boolean) => {
    onChange({
      respectPrevTerritories: flag,
      previouslySavedBoundaryGroupId: null,
      numberOfPreviousTerritories: null,
    });
  }, [onChange]);

  const onChangePreviouslySavedBoundaryGroupId = useCallback((previouslySavedBoundaryGroupId: number) => {
    onChange({ previouslySavedBoundaryGroupId });
  }, [onChange]);

  const onChangeOptimizationConstraints = useCallback((flag: boolean) => {
    onChange({ optimizationConstraints: flag });
  }, [onChange]);

  const onChangeNumberOfTerritories = useCallback((value: number) => {
    onChange({ numberOfTerritories: value });
  }, [onChange]);

  const onChangeBuildingBlockSelection = useCallback((value: BuildingBlockSelections) => {
    onChange({ buildingBlockSelection: value });
    if (value === BuildingBlockSelections.ALL && config.selectedBoundaryIds?.length > 0) {
      onChange({ selectedBoundaryIds: [] });
    }
  }, [config.selectedBoundaryIds?.length, onChange]);

  const onChangeBuildingBlockSubsetType = useCallback((value: BuildingBlockSubsetTypes) => {
    onChange({ buildingBlockSubsetType: value });
  }, [onChange]);

  const onChangeOverrideTerritory = useCallback((flag: boolean) => {
    onChange({ overrideTerritory: flag });
  }, [onChange]);

  const onGoToStepsCallbacks = useMemo(() => ({
    [BoundaryGroupFromAIModalSteps.Name]: () => onGoToStep(BoundaryGroupFromAIModalSteps.Name),
    [BoundaryGroupFromAIModalSteps.BuildingBlock]: () => onGoToStep(BoundaryGroupFromAIModalSteps.BuildingBlock),
    [BoundaryGroupFromAIModalSteps.Variables]: () => onGoToStep(BoundaryGroupFromAIModalSteps.Variables),
    [BoundaryGroupFromAIModalSteps.AdditionalOptions]: () => onGoToStep(BoundaryGroupFromAIModalSteps.AdditionalOptions),
  }), [onGoToStep]);
  return (
    <>
      <OutlinePanelComponent
        outlines={[OutlinePanelOptions.Top]}
      >
        <AccordionComponent
          isLoading={isLoadingSubsetBlocks || isGenerateOptimizedTerritoriesLoading}
          itemButtonStyle={itemButtonStyle}
          showCount
          data={[{
            header: <span>{t('boundaryAI.Select Building Block Type')}</span>,
            child: (
              <BuildingBlockStepComponent
                allStates={config.allStates}
                onChange={onChangeBuildingBlocks}
                buildingBlock={config.buildingBlock}
                onContinue={onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.Variables]}
                onSelectSubsetFromMap={onSelectSubsetFromMap}
                buildingBlockSelection={config.buildingBlockSelection}
                buildingBlockSubsetType={config.buildingBlockSubsetType}
                selectedBoundaryIds={config.selectedBoundaryIds}
                onChangeBuildingBlockSelection={onChangeBuildingBlockSelection}
                onChangeBuildingBlockSubsetType={onChangeBuildingBlockSubsetType}
              />
            ),
            isExpanded: isExpandedPerStep[BoundaryGroupFromAIModalSteps.BuildingBlock],
            isLocked: isLockedPerStep[BoundaryGroupFromAIModalSteps.BuildingBlock],
            onHeadingClick: onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.BuildingBlock],
          }, {
            header: <span>{t('boundaryAI.Select territory creation variables')}</span>,
            child: (
              <VariablesStepComponent
                applyFilterOnData={config.applyFilterOnData}
                dataMetricsValues={config.dataMetricsValues}
                demographicsValues={config.demographicsValues}
                isLoadingDataMetrics={isLoadingDataMetrics}
                onChangeDataMetrics={onChangeDataMetrics}
                onChangeDemographics={onChangeDemographics}
                onContinue={onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.AdditionalOptions]}
                onChangeApplyFilterOnData={showApplyDataFilterOption ? onChangeApplyFilterOnData : undefined}
              />
            ),
            isExpanded: isExpandedPerStep[BoundaryGroupFromAIModalSteps.Variables],
            isLocked: isLockedPerStep[BoundaryGroupFromAIModalSteps.Variables],
            onHeadingClick: onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.Variables],
          }, {
            header: <span>{t('boundaryAI.Select Territory Creation Method')}</span>,
            child: (
              <AdditionalOptionsStepComponent
                customBoundaryGroups={customBoundaryGroups}
                dataMetricsValues={config.dataMetricsValues}
                demographicsValues={config.demographicsValues}
                numberOfTerritories={numberOfTerritories}
                numberOfTerritoriesLoading={numberOfTerritoriesLoading}
                numberOfTerritoriesUpdateReason={numberOfTerritoriesUpdateReason}
                onChangeDataMetrics={onChangeDataMetrics}
                onChangeDemographics={onChangeDemographics}
                onChangeNumberOfTerritories={onChangeNumberOfTerritories}
                onChangeOptimizationConstraints={onChangeOptimizationConstraints}
                onChangeOverrideTerritory={onChangeOverrideTerritory}
                onChangePreviouslySavedBoundaryGroupId={onChangePreviouslySavedBoundaryGroupId}
                onChangeRespectPrevTerritories={onChangeRespectPrevTerritories}
                onChangeRespectSalesLocations={onChangeRespectSalesLocations}
                onChangeSalesRepLocationsMap={onChangeSalesRepLocationsMapId}
                onChangeSalesRepNameColumnId={onChangeSalesRepNameColumnId}
                onChangeUseNumberOfTerritories={onChangeUseNumberOfTerritories}
                onContinue={onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.Name]}
                onRefreshTerritoriesCount={onRefreshTerritoriesCount}
                optimizationConstraints={config.optimizationConstraints}
                overrideTerritory={config.overrideTerritory}
                previouslySavedBoundaryGroupId={config.previouslySavedBoundaryGroupId}
                respectPrevTerritories={config.respectPrevTerritories}
                respectSalesLocations={config.respectSalesLocations}
                salesRepLocationsMap={config.salesRepLocationsMap}
                salesRepNameColumnId={config.salesRepNameColumnId}
                statesSubset={config.statesSubset}
                useNumberOfTerritories={config.useNumberOfTerritories}
                zipCodesSubset={config.zipCodesSubset}
              />
            ),
            isExpanded: isExpandedPerStep[BoundaryGroupFromAIModalSteps.AdditionalOptions],
            isLocked: isLockedPerStep[BoundaryGroupFromAIModalSteps.AdditionalOptions],
            onHeadingClick: onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.AdditionalOptions],
          }, {
            header: <span>{t('boundaryAI.Name Your territory group')}</span>,
            child: (
              <NameStepComponent
                groupName={config.groupName}
                onChange={onChangeGroupName}
                onContinue={onSubmit}
                isGenerateOptimizedTerritoriesLoading={isGenerateOptimizedTerritoriesLoading}
                optimizedTerritoriesRequestError={optimizedTerritoriesRequestError}
                onCloseOptimizedTerritoriesRequestError={onCloseOptimizedTerritoriesRequestError}
              />
            ),
            isExpanded: isExpandedPerStep[BoundaryGroupFromAIModalSteps.Name],
            isLocked: isLockedPerStep[BoundaryGroupFromAIModalSteps.Name],
            onHeadingClick: onGoToStepsCallbacks[BoundaryGroupFromAIModalSteps.Name],
          }]}
        />
      </OutlinePanelComponent>
    </>
  );
};

export type CreateBoundaryGroupFromAIModalComponentProps = ModalProps<CreateBoundaryGroupFromAIComponentProps>;

export const CreateBoundaryGroupFromAIModalComponent: FC<CreateBoundaryGroupFromAIModalComponentProps> = ({
  onClose,
  isOpen,
  ...restProps
}: CreateBoundaryGroupFromAIModalComponentProps) => {
  const [t] = useTranslation();

  return (
    <ModalComponent
      onClose={onClose}
      isOpen={isOpen}
      caption={t('Auto Territory Builder')}
      contentStyle={css({ padding: 0 })}
      maxWidth="600px"
    >
      <CreateBoundaryGroupFromAIComponent {...restProps} />
    </ModalComponent>
  );
};
