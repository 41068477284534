import { useState } from 'react';
import {
  DISTANCE_MATRIX_MAX_SEARCH_ITEMS, DISTANCE_MATRIX_MAX_TO_LOCATIONS,
} from '~/_shared/repositories/useDistanceMatrixService';
import { isPaidLicense } from '~/_shared/types/client/license';
import { useIsMapPresentationalSelector } from '~/store/selectors/useMapInfoSelectors';
import { useUserDataSelector } from '~/store/userData/userData.selectors';

export enum ProximityDistanceCalculateMethod {
  StraightLine = 'StraightLine',
  DrivingDistance = 'DrivingDistance',
}

export enum ProximityDistanceDisabledReason {
  LimitExceededTrial = 'LimitExceededTrial',
  LimitExceededPaid = 'LimitExceededPaid',
  ProximitySelectedCountExceeded = 'ProximitySelectedCountExceeded',
  MarkersCountExceeded = 'MarkersCountExceeded',
  PresentationalMap = 'PresentationalMap',
}

export const useExportProximityDistanceCalculate = (selectedRadiusCount: number, closestToCenterCount: number | null) => {
  const userData = useUserDataSelector();
  const isPresentationalMap = useIsMapPresentationalSelector();

  const [calculateMethod, setCalculateMethod] = useState(ProximityDistanceCalculateMethod.StraightLine);

  let drivingDisabledReason: ProximityDistanceDisabledReason | null = null;

  if ((closestToCenterCount ?? 0) > DISTANCE_MATRIX_MAX_TO_LOCATIONS) {
    drivingDisabledReason = ProximityDistanceDisabledReason.MarkersCountExceeded;
  }

  if (userData.usage.distanceMatrixRequests >= userData.limits.distanceMatrixRequests) {
    drivingDisabledReason = isPaidLicense(userData.currentClient?.license.type) ? ProximityDistanceDisabledReason.LimitExceededPaid : ProximityDistanceDisabledReason.LimitExceededTrial;
  }

  if (selectedRadiusCount > DISTANCE_MATRIX_MAX_SEARCH_ITEMS) {
    drivingDisabledReason = ProximityDistanceDisabledReason.ProximitySelectedCountExceeded;
  }

  if (isPresentationalMap) {
    drivingDisabledReason = ProximityDistanceDisabledReason.PresentationalMap;
  }

  return {
    calculateMethod: isPresentationalMap ? ProximityDistanceCalculateMethod.StraightLine : calculateMethod,
    onCalculateMethodChange: setCalculateMethod,
    drivingDisabledReason,
    isValid: isPresentationalMap || calculateMethod === ProximityDistanceCalculateMethod.StraightLine || !drivingDisabledReason,
  };
};
