import { css } from '@emotion/react';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import React from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  TooltipBehavior, TooltipComponent,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import type { Theme } from '~/_shared/themes/theme.model';
import {
  TableDataCellComponent, TableRowComponent,
} from '~/map/mapMigration/table';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';

const mapNameCellStyles = (theme: Theme) => css({
  textAlign: 'left',
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  paddingInline: 26,
  background: 'transparent',

  '.row:first-of-type &': {
    borderTop: `1px solid ${theme.borderColors.primary}`,
  },

  '&:hover': {
    background: 'transparent',
  },
});

type MapMigrationResultRowProps = Readonly<{
  map: V4MapInfo;
}>;

export const MapMigrationResultRowComponent = ({ map }: MapMigrationResultRowProps) => {
  return (
    <TableRowComponent key={map.id}>
      <TooltipComponent
        tooltipContent={map.title}
        behavior={TooltipBehavior.ShowOnOverflow}
      >
        <TableDataCellComponent
          css={mapNameCellStyles}
        >
          <FontAwesomeIcon
            icon={map.mapType === 'LAYERED' ? faLayerGroup : faMap}
          />
          {map.title}
        </TableDataCellComponent>
      </TooltipComponent>
    </TableRowComponent>
  );
};
