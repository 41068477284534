import {
  type FC, useMemo,
} from 'react';
import {
  createColor, guaranteeHash,
} from '~/_shared/components/colorPicker/colorPicker.helpers';
import { type QuadrilateralDimensions } from '~/_shared/types/coordinateSystem/coordinateSystem';
import { getDarkOrLightDependingOnBaseColor } from '~/_shared/utils/colors/colors.helpers';
import { ActiveMarkerIndicators } from './activeMarkerIndicator.types';
import { CircleArrowBasicComponent } from './multiColorIndicators/circleArrowBasic.component';
import { CircleArrowFatComponent } from './multiColorIndicators/circleArrowFat.component';
import { CircleArrowSlimComponent } from './multiColorIndicators/circleArrowSlim.component';

const DARK_ARROW_COLOR = createColor('#000000');
const LIGHT_ARROW_COLOR = createColor('#FFFFFF');

type CircleArrowIndicator =
  typeof ActiveMarkerIndicators.basicCircleArrow | typeof ActiveMarkerIndicators.fatCircleArrow
  | typeof ActiveMarkerIndicators.slimCircleArrow;

export type CircleArrowProps = {
  color: string;
  dimensions: QuadrilateralDimensions;
  circleArrowType: CircleArrowIndicator;
};

export const ActiveMarkerCircleArrowIndicatorContainer: FC<CircleArrowProps> = (props) => {

  const circleArrowProps = useMemo(() => {
    const arrowColor = getDarkOrLightDependingOnBaseColor(
      createColor(props.color),
      DARK_ARROW_COLOR,
      LIGHT_ARROW_COLOR
    );

    return {
      dimensions: props.dimensions,
      circleColor: guaranteeHash(props.color),
      arrowColor: guaranteeHash(arrowColor.hex),
    };
  }, [props.color, props.dimensions]);

  return useMemo(() => {
    switch (props.circleArrowType) {
      case ActiveMarkerIndicators.basicCircleArrow :
        return <CircleArrowBasicComponent {...circleArrowProps} />;

      case ActiveMarkerIndicators.fatCircleArrow:
        return <CircleArrowFatComponent {...circleArrowProps} />;

      case ActiveMarkerIndicators.slimCircleArrow:
        return <CircleArrowSlimComponent {...circleArrowProps} />;

      default:
        return null;
    }
  }, [circleArrowProps, props.circleArrowType]);
};
