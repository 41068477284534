import {
  put, select, takeLatest,
} from 'redux-saga/effects';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { isPublicDomain } from '~/_shared/utils/url/url.helpers';
import { type MapSettingsDataAction } from '../mapSettings/data/mapSettingsData.action';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../mapSettings/data/mapSettingsData.actionTypes';
import { isMapPresentationalSelector } from '../selectors/useMapInfoSelectors';
import { type UserDataAction } from '../userData/userData.action';
import { USER_GET_DATA_SUCCESS } from '../userData/userData.actionTypes';
import { type ThemeAction } from './theme.action';
import { changeThemeType } from './theme.actionCreators';
import { THEME_CHANGE_TYPE } from './theme.actionTypes';
import { storeThemeType } from './theme.helpers';

export function* themeSagas() {
  yield takeLatest(THEME_CHANGE_TYPE, onThemeChange);
  yield takeLatest(USER_GET_DATA_SUCCESS, setThemeFromUserSettings);
  yield takeLatest(MAP_SETTINGS_FETCH_DATA_SUCCESS, setThemeFromPublicMapSettings);
}

function onThemeChange(action: PickAction<ThemeAction, typeof THEME_CHANGE_TYPE>) {
  if (action.payload.persist) {
    storeThemeType(action.payload.type);
  }
}

function* setThemeFromUserSettings(action: PickAction<UserDataAction, typeof USER_GET_DATA_SUCCESS>) {
  const isMapPresentational: boolean = yield select(isMapPresentationalSelector);
  const clientId = action.payload.data.clientId;

  if (!isMapPresentational && clientId) {
    const theme = action.payload.data.settings?.[clientId]?.theme;
    if (theme) {
      yield put(changeThemeType(theme));
    }
  }
}

function* setThemeFromPublicMapSettings(action: PickAction<MapSettingsDataAction, typeof MAP_SETTINGS_FETCH_DATA_SUCCESS>) {
  const isMapPresentational: boolean = yield select(isMapPresentationalSelector);

  if (!isMapPresentational) {
    return;
  }

  const theme = action.payload.mapSettingsData.publicMapSettings.theme;
  if (theme) {
    yield put(changeThemeType(theme, { persist: isPublicDomain() }));
  }
}
