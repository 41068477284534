import { getUnitSymbols } from '~/_shared/utils/unitSystem/unitSystem.helpers';
import { type UnitSystem } from '../../types/googleMaps/googleMaps.types';

export const ZOOM_LEVEL_FOR_SCALED_ITEMS = 30; // map zoom for which distance was saved

export const convertPxDistanceToDifferentZoom = ({ distance, newZoom, originalZoom }: { distance: number; originalZoom: number; newZoom: number }) =>
  Math.round(distance * (2 ** (newZoom - originalZoom)));

export const formatDistance = (distance: number, remainder: number, unitSystem: UnitSystem): string => {
  const { unit, subunit } = getUnitSymbols(unitSystem);

  if (distance < 1 && remainder) {
    return `${remainder} ${subunit}`;
  }
  else if (distance < 2) {
    return `${distance.toFixed(2)} ${unit}`;
  }
  else if (distance < 10) {
    return `${distance.toFixed(1)} ${unit}`;
  }
  else {
    return `${Math.round(distance)} ${unit}`;
  }
};
