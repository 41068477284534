import { type LatLng } from '~/_shared/types/latLng';
import type { DrawingItem } from '~/store/mapSettings/drawing/items/drawingItems.state';

type DrawingItemsContextMenuInactiveState = {
  isVisible: false;
  position: null;
  drawingItem: null;
};

type DrawingItemsContextMenuActiveState = {
  isVisible: true;
  position: LatLng;
  drawingItem: DrawingItem;
};

export type DrawingItemsContextMenuState = DrawingItemsContextMenuActiveState | DrawingItemsContextMenuInactiveState;

export const isDrawingItemsContextMenuActive = (
  state: DrawingItemsContextMenuState
): state is DrawingItemsContextMenuActiveState => state.isVisible;
