import { type StreetViewAction } from './streetView.action';
import {
  FRONTEND_STATE_STREETVIEW_OFF, FRONTEND_STATE_STREETVIEW_ON, FRONTEND_STATE_STREETVIEW_SET_LOCATION,
} from './streetView.actionTypes';
import { type StreetViewState } from './streetView.state';

const initialState: StreetViewState = {
  streetViewLocation: null,
  active: false,
};

export const streetViewReducer = (state = initialState, action: StreetViewAction): StreetViewState => {
  const actionType = action.type;
  switch (actionType) {
    case FRONTEND_STATE_STREETVIEW_SET_LOCATION: {
      return {
        ...state,
        streetViewLocation: action.payload.streetViewLocation,
        active: true,
      };
    }

    case FRONTEND_STATE_STREETVIEW_ON: {
      return {
        ...state,
        active: true,
      };
    }

    case FRONTEND_STATE_STREETVIEW_OFF: {
      return {
        ...state,
        streetViewLocation: null,
        active: false,
      };
    }

    default: {
      return state;
    }
  }
};
