import {
  setStatus, updateMigrationActivity, updateMigrationInfoFromErrorMessage, updateMigrationInfoFromProgressMessage,
  updateMigrationInfoFromQueueInit,
} from '~/store/mapMigration/helpers/migrationInfo.helpers';
import type { MapMigrationAction } from '~/store/mapMigration/mapMigration.action';
import {
  v4MapCompleteMigrationInit, v4MapFetchFail, v4MapFetchSuccess, v4MapInitiateCompleteMigrationFail,
  v4MapInitiateCompleteMigrationSuccess, v4MapInitiateMigrationFail, v4MapInitiateMigrationSuccess,
  v4MapMigrationErrorMessage, v4MapMigrationInit,
  v4MapMigrationProgressUpdate,
} from '~/store/mapMigration/mapMigration.actionCreators';
import { mapMigrationDTOToDomainModel } from '~/store/mapMigration/mapMigration.factory';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import type { V4MapMigrationStatusInfo } from '~/store/mapMigration/types/v4MapMigrationProgressInfo.type';
import { type UserDataAction } from '~/store/userData/userData.action';
import {
  USER_AUTH_EXPIRE, USER_SIGN_OUT_SUCCESS,
} from '~/store/userData/userData.actionTypes';

type MapMigrationState = {
  readonly areV4MapsLoaded: boolean;
  readonly migrationStatus: V4MapMigrationStatusInfo;
  readonly v4MapInfos: ReadonlyArray<V4MapInfo>;
};

const initialState: MapMigrationState = {
  areV4MapsLoaded: false,
  migrationStatus: {
    errorCoordinates: { },
    mapMigrationResults: [],
    percentage: 0,
    processedMaps: 0,
    queueId: '',
    status: 'NONE',
    totalMaps: 0,
  },
  v4MapInfos: [],
};

export const mapMigrationReducer = (state = initialState, action: MapMigrationAction | UserDataAction): MapMigrationState => {
  if (v4MapFetchFail.match(action)) {
    return initialState;
  }

  if (v4MapFetchSuccess.match(action)) {
    const { response } = action.payload;
    const { v4MapInfos, migrationStatusInfo } = mapMigrationDTOToDomainModel(response);

    return {
      ...state,
      areV4MapsLoaded: true,
      migrationStatus: updateMigrationActivity(initialState.migrationStatus, v4MapInfos, migrationStatusInfo),
      v4MapInfos,
    };
  }

  if (v4MapInitiateCompleteMigrationFail.match(action) || v4MapInitiateMigrationFail.match(action)) {
    return {
      ...state,
      migrationStatus: setStatus(initialState.migrationStatus, 'FAILED'),
    };
  }

  if (v4MapCompleteMigrationInit.match(action) || v4MapMigrationInit.match(action)) {
    return {
      ...state,
      migrationStatus: setStatus(initialState.migrationStatus, 'IN_PROGRESS'),
    };
  }

  if (v4MapInitiateCompleteMigrationSuccess.match(action) || v4MapInitiateMigrationSuccess.match(action)) {
    const { responseData } = action.payload;
    return {
      ...state,
      migrationStatus: updateMigrationInfoFromQueueInit(state.migrationStatus, responseData),
    };
  }

  if (v4MapMigrationProgressUpdate.match(action)) {
    return {
      ...state,
      migrationStatus: updateMigrationInfoFromProgressMessage(state.migrationStatus, action.payload.message),
    };
  }

  if (v4MapMigrationErrorMessage.match(action)) {
    return {
      ...state,
      migrationStatus: updateMigrationInfoFromErrorMessage(state.migrationStatus, action.payload.message),
    };
  }

  switch (action.type) {
    case USER_AUTH_EXPIRE:
    case USER_SIGN_OUT_SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
