import { css } from '@emotion/react';
import {
  faGripLines, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {
  type FC, useCallback, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import {
  ScrollBarComponent, ScrollbarType,
} from '~/_shared/components/scrollbar/scrollbar.component';
import { SplitComponent } from '~/_shared/components/split/split.component';
import {
  TopUnderbarContainer, TopUnderbarType,
} from '~/_shared/components/topUnderbar/topUnderbar.container';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useHookWithRefCallback } from '~/_shared/utils/hooks/useHookWithRefCallback';
import { DataTableSplitScreenContainer } from '~/data/table/dataTableSplitScreen.container';
import { MapComponent } from '~/map/map/map.component';
import { LeftSideMapToolsContainer } from '~/map/mapToolControls/leftSideMapTools/leftSideMapTools.container';
import { useGetRightSidebarContent } from '../../sidebar/sidebarApps/rightSidebar/helpers/useGetRightSidebarContent';
import { RightSidebarContainer } from '../../sidebar/sidebarApps/rightSidebar/rightSidebar.container';
import { RightSideMapToolsContainer } from '../mapToolControls/rightSideMapTools/rightSideMapTools.container';
import { MapSearchContainer } from '../search/mapSearch.container';
import { MapStatusBarContainer } from '../statusBar/mapStatusBar.container';
import {
  getDefaultSplitPanelContentHeight, getMapMinHeight,
} from './mapPage.helpers';
import { useHandleUserEventSequences } from './useHandleUserEventSequences.hook';
import { useShowUserLocationDefault } from './useShowUserLocationDefault.hook';

export const SPLIT_SCREEN_GUTTER_SIZE = 40;

const wrapperStyle = css({
  overflow: 'hidden',
  overflowY: 'auto',
  height: '100%',
  flex: '1 1 100%',
  display: 'flex',
});

const splitPanelStyle = css({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 100%',
});

const mapWrapperStyle = css({
  position: 'relative',
  overflow: 'hidden',
});

const mapElementsWrapperStyle = css({
  flex: '1',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const mapSearchStyle = css({
  position: 'absolute',
  top: 10,
  left: 'calc(50% - 32px)', // -32px is to account for the button icons on the right
  transform: 'translateX(-50%)',
});

const splitWrapperStyle = css({
  height: '100%',
});

const splitContainerStyle = css({
  flex: 1,
  overflow: 'hidden',
});

const rightSidebarStyle = css({
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'flex-end',
});

const scrollbarContentStyle = ({
  display: 'flex',
  minWidth: 'none',
});

const gutterStyle = ({ theme, isActive }: ThemeProps<{ isActive: boolean }>) => css({
  background: theme.backgroundColors.quinary,
  position: 'relative',
  display: isActive ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  borderColor: theme.borderColors.primary,
  borderStyle: 'solid',
  borderWidth: '1px 0',
});

const gutterLinesIconStyle = css({
  fontSize: '16px',
  fontWeight: 900,
});

const gutterCloseStyle = css({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  width: '40px',
  lineHeight: '100%',
  fontSize: '16px',
  fontWeight: 900,
  background: 'none',
  textAlign: 'center',
  color: 'inherit',
  border: 'none',
});

const topUnderbarVisibleItems = new Set([
  TopUnderbarType.MAP_SETTINGS_STATUS,
  TopUnderbarType.EXPORT_IMAGE_STATUS,
  TopUnderbarType.GEOCODING_PROGRESS_ALERT,
  TopUnderbarType.OPTIMIZE_TERRITORIES_PROGRESS,
  TopUnderbarType.ANNOUNCEMENTS,
  TopUnderbarType.SEARCH_ENTRIES,
]);

type MapPageProps = {
  splitPanelHeight: number | null;
  isMapOverlayUiHidden?: boolean;
  isSplitPanelActive: boolean;
  isMobileScreen: boolean;
  searchActive: boolean;
  showStatusBar: boolean;

  onSplitPanelHeightChange: (size: number) => void;
  onSplitPanelClose: () => void;
};

export const MapPageComponent: FC<MapPageProps> = (props) => {
  const {
    searchActive,
    onSplitPanelHeightChange,
    onSplitPanelClose,
    isMapOverlayUiHidden,
    isMobileScreen,
    isSplitPanelActive,
    showStatusBar,
  } = props;

  const [splitPanelEl, setSplitPanelEl] = useHookWithRefCallback<HTMLDivElement>();
  const [defaultSplitPanelContentHeight, setDefaultSplitPanelContentHeight] = useState(0);
  const [mapMinHeight, setMapMinHeight] = useState(0);
  const [wrapperEl, setWrapperEl] = useHookWithRefCallback<HTMLDivElement>();
  const rightSidebarContents = useGetRightSidebarContent();

  const showSplitter = (isSplitPanelActive && !isMobileScreen) || (isMobileScreen && rightSidebarContents !== null);
  const splitPanelHeight = props.splitPanelHeight ?? defaultSplitPanelContentHeight;
  const splitPanelSize = showSplitter ? [100 - splitPanelHeight, splitPanelHeight] : [100, 0];

  const theme = useTheme();
  useShowUserLocationDefault();
  useHandleUserEventSequences();

  const onSplitDragEnd = useCallback((sizes: number[]) => {
    if (sizes[1] !== undefined) {
      onSplitPanelHeightChange(sizes[1]);
    }
  }, [onSplitPanelHeightChange]);

  useEffect(() => {
    if (!wrapperEl || !splitPanelEl) {
      return;
    }

    setMapMinHeight(getMapMinHeight(splitPanelEl, wrapperEl, isMobileScreen));
    setDefaultSplitPanelContentHeight(getDefaultSplitPanelContentHeight(splitPanelEl, wrapperEl));

    const wrapperResizeObserver = new ResizeObserver(() => {
      setMapMinHeight(getMapMinHeight(splitPanelEl, wrapperEl, isMobileScreen));
      setDefaultSplitPanelContentHeight(getDefaultSplitPanelContentHeight(splitPanelEl, wrapperEl));
    });

    wrapperResizeObserver.observe(wrapperEl);
    wrapperResizeObserver.observe(splitPanelEl);

    return () => {
      wrapperResizeObserver.disconnect();
    };
  }, [wrapperEl, splitPanelEl, isMobileScreen]);

  return (
    <div
      css={wrapperStyle}
      ref={setWrapperEl}
    >
      <div css={mapElementsWrapperStyle}>
        <TopUnderbarContainer visibleItems={topUnderbarVisibleItems} />

        <div css={splitContainerStyle}>
          <SplitComponent
            css={splitWrapperStyle}
            sizes={splitPanelSize}
            minSize={[mapMinHeight, 0]}
            gutterSize={showSplitter ? SPLIT_SCREEN_GUTTER_SIZE : 0}
            direction="vertical"
            onDragEnd={onSplitDragEnd}
            expandToMin
            gutter={(
              <div css={gutterStyle({ theme, isActive: showSplitter })}>
                <FontAwesomeIcon
                  css={gutterLinesIconStyle}
                  icon={faGripLines}
                />

                {!isMobileScreen && (
                  <button
                    css={gutterCloseStyle}
                    onClick={onSplitPanelClose}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
              </div>
            )}
          >
            <div
              css={mapWrapperStyle}
            >
              <MapComponent />

              {!isMapOverlayUiHidden && (
                <>
                  <div>
                    {searchActive && <MapSearchContainer css={mapSearchStyle} />}
                  </div>

                  <div>
                    <RightSideMapToolsContainer />
                    <LeftSideMapToolsContainer />
                  </div>
                </>
              )}
            </div>

            <div>
              {!isMapOverlayUiHidden && (
                <ScrollBarComponent
                  type={ScrollbarType.Vertical}
                  contentStyle={scrollbarContentStyle}
                  maxHeight="100%"
                >
                  <div ref={setSplitPanelEl} css={splitPanelStyle}>
                    {isMobileScreen && rightSidebarContents && <RightSidebarContainer />}
                    {!isMobileScreen && isSplitPanelActive && (
                      <DataTableSplitScreenContainer />
                    )}
                  </div>
                </ScrollBarComponent>
              )}
            </div>
          </SplitComponent>
        </div>

        {showStatusBar && !isMobileScreen && !isMapOverlayUiHidden &&
          <MapStatusBarContainer />
        }
      </div>

      {!isMobileScreen && !isMapOverlayUiHidden && (
        <div css={rightSidebarStyle}>
          <RightSidebarContainer />
        </div>
      )}
    </div>
  );
};
