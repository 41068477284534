import { css } from '@emotion/react';
import {
  faEye, faEyeSlash, faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { type FC } from 'react';
import { ButtonComponent } from '~/_shared/baseComponents/buttons/button/button.component';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { useTheme } from '../../../../../_shared/themes/theme.hooks';
import { type ThemeProps } from '../../../../../_shared/types/themeProps';
import { useTranslation } from '../../../../../_shared/utils/hooks';
import {
  type DrawingTool, drawingToolIcons,
} from '../../../../../drawingTool/drawingTool.enums';
import {
  getDrawingDeleteAllItemsLabelForDrawingType,
  getDrawingsTypeLabel,
} from '../../../../../drawingTool/drawingTool.helpers';

export type DrawingToolManagementItem = {
  drawingTool: DrawingTool;
  itemsCount: number;
  areItemsVisible: boolean;
};

type DrawingToolItemsManagementProps = Readonly<{
  items: ReadonlyArray<DrawingToolManagementItem>;
  onRemove: (drawingTool: DrawingTool) => void;
  onVisibilitySet: (drawingTool: DrawingTool, isVisible: boolean) => void;
}>;

const ctaItemsDistance = 5;

const itemStyle = ({ theme }: ThemeProps) => css({
  height: 40,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.borderColors.primary}`,
  paddingLeft: 15,
  paddingRight: 15 - ctaItemsDistance,
  alignItems: 'center',
});

const labelWrapperStyle = css({
  flexShrink: 0,
  marginRight: 5,
});

const itemCtaStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '8px, 0',
});

const visibilityButtonStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  // set to prevent icon size when toggling eye/eyeSlash icons
  width: 30,
  background: 'none',
  border: 'none',
  padding: `0 ${ctaItemsDistance}px`,
  color: isDisabled ? theme.textColors.disabled : theme.iconColors.contrast,
  '&:hover': {
    color: !isDisabled ? theme.iconColors.focused : undefined,
  },
});

const removeButtonStyle = ({ theme, isDisabled }: ThemeProps<{ isDisabled: boolean }>) => css({
  background: 'none',
  border: 'none',
  padding: `0 ${ctaItemsDistance}px`,
  color: isDisabled ? theme.textColors.disabled : theme.textColors.danger,
  '&:hover': {
    color: !isDisabled ? theme.textColors.dangerOnHover : undefined,
  },
});

const iconStyle = css({
  marginRight: 10,
});

const mapsCountButtonStyle = css({
  fontSize: '12px',
  padding: '6px 4px',
  opacity: 1,
  marginRight: ctaItemsDistance,
});

export const DrawingToolItemsManagementComponent: FC<DrawingToolItemsManagementProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {props.items.map((item) => {
        const drawingToolLabelPlural = getDrawingsTypeLabel(item.drawingTool, t);
        const actionsDisabled = item.itemsCount === 0;

        return (
          <div
            key={item.drawingTool}
            css={itemStyle({ theme })}
          >
            <div css={labelWrapperStyle}>
              <FontAwesomeIcon
                css={iconStyle}
                icon={drawingToolIcons[item.drawingTool]}
              />
              {drawingToolLabelPlural}
            </div>

            <div css={itemCtaStyle}>
              <TooltipDeprComponent
                tooltipContent={t('{{count}} element added to the map', {
                  count: item.itemsCount,
                })}
              >
                <ButtonComponent
                  css={mapsCountButtonStyle}
                  text={t('{{count}} Item', {
                    count: item.itemsCount,
                  })}
                  isDisabled
                />
              </TooltipDeprComponent>

              <TooltipDeprComponent tooltipContent={actionsDisabled ? undefined : item.areItemsVisible ? t('Hide Items') : t('Show Items')}>
                <button
                  css={visibilityButtonStyle({ theme, isDisabled: actionsDisabled })}
                  onClick={() => props.onVisibilitySet(item.drawingTool, !item.areItemsVisible)}
                  disabled={actionsDisabled}
                >
                  <FontAwesomeIcon
                    icon={!item.areItemsVisible || actionsDisabled ? faEyeSlash : faEye}
                  />
                </button>
              </TooltipDeprComponent>

              <TooltipDeprComponent
                tooltipContent={!actionsDisabled ? getDrawingDeleteAllItemsLabelForDrawingType(item.drawingTool, t) : null}
              >
                <button
                  css={removeButtonStyle({ theme, isDisabled: actionsDisabled })}
                  onClick={() => props.onRemove(item.drawingTool)}
                  disabled={actionsDisabled}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </TooltipDeprComponent>
            </div>
          </div>
        );
      })}
    </div>
  );
};
