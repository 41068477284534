import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi, { type HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { CONFIG } from './_shared/constants/config';

const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}/{{ns}}',
  request: (_, url, __, callback) => {
    const [language, namespace] = url.split('/');

    import(
      /* webpackChunkName: "translations-[request]" */
      `./translations/${language}/${namespace}.ts`
    )
      .then(result => callback(null, { status: 200, data: JSON.stringify(result.default) }))
      .catch(err => callback(err, { status: 404, data: null as any }));
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    supportedLngs: CONFIG.MODE === 'production' ? ['en'] : false,
    fallbackLng: 'en',
    fallbackNS: 'common',
    defaultNS: 'common',
    ns: 'common',

    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    backend: backendOptions,
  });

export { i18n };
