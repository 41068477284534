export class DrawingToolManager {
  private readonly map: google.maps.Map;

  constructor(map: google.maps.Map) {
    this.map = map;
  }

  getZoom = () => this.map.getZoom();

  addMapClickListener = (listener: (e: google.maps.MapMouseEvent) => void) => {
    return google.maps.event.addListener(this.map, 'click', listener);
  };

  addMapMouseMoveListener = (listener: (e: google.maps.MapMouseEvent) => void) =>
    google.maps.event.addListener(this.map, 'mousemove', listener);
}
