import { css } from '@emotion/react';
import {
  type FC,
  type ReactNode,
} from 'react';
import {
  TooltipComponent, TooltipPlacement,
} from '~/_shared/baseComponents/tooltip/tooltip.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { type AccordionSubItemActionProps } from './settingsAccordion.component';
import {
  type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from './settingsAccordionData.type';

const subItemStyle = ({ theme, isLeaf, isDisabled }: ThemeProps<{ isLeaf: boolean; isDisabled: boolean }>) => css({
  alignItems: 'center',
  borderTop: `1px solid ${theme.borderColors.primary}`,
  boxSizing: 'border-box',
  cursor: isDisabled ? 'not-allowed' : 'default',
  display: 'flex',
  minHeight: 56,
  justifyContent: 'space-between',
  padding: isLeaf ? '0 10px' : 0,
  position: 'relative',
  width: '100%',
  opacity: isDisabled ? 0.6 : 1,

  '&:first-of-type': {
    borderTop: 'none',
  },
});

const labelStyle = css({
  marginRight: 12,
  display: 'flex',
});

const tooltipContentStyle = css({
  maxWidth: 600,
  textAlign: 'justify',
});

const labelWrapperStyle = ({ width }: { width?: number | string }) => css({
  width: width || undefined,
});

const tooltipWrapperStyle = css({
  display: 'inline',
});

type SettingsAccordionItemProps = Readonly<{
  children: SettingsAccordionSubItem;
  renderItemAction: (props: AccordionSubItemActionProps) => ReactNode;
}>;

export const SettingsAccordionItemComponent: FC<SettingsAccordionItemProps> = (props) => {
  const theme = useTheme();
  const disabledTooltip = useSelector(props.children.disabledTooltipSelector);

  const isLeaf = props.children.type !== SettingsAccordionSubItemType.ColorList;

  return (
    <TooltipComponent tooltipContent={disabledTooltip.message}>
      <div css={subItemStyle({ theme, isLeaf, isDisabled: disabledTooltip.message !== null })}>
        {isLeaf && (
          <div
            css={labelWrapperStyle({
              width: props.children.type === SettingsAccordionSubItemType.DropDown ? '50%' : undefined,
            })}
          >
            <TooltipComponent
              css={tooltipWrapperStyle}
              tooltipContent={disabledTooltip.message !== null ? null : props.children.infoTooltip}
              placement={TooltipPlacement.TopEnd}
              contentStyle={tooltipContentStyle}
            >
              <div css={labelStyle}>
                {props.children.label}
              </div>
            </TooltipComponent>
          </div>
        )}
        {props.renderItemAction({ theme, item: props.children, isDisabled: disabledTooltip.message !== null,
          isActiveIfDisabled: disabledTooltip.isActiveWhenDisabled })}
      </div>
    </TooltipComponent>
  );
};
