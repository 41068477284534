import {
  useEffect, useMemo, useState,
} from 'react';
import { type DropdownOption } from '~/_shared/baseComponents/dropdown';
import { useDemographicDropdownOptions } from '~/_shared/components/customizeMetrics/useDemographicDropdownOptions';
import { useIsGeocodingRunningOrRequired } from '~/store/geocoding/geocoding.selectors';
import { type SpreadsheetColumnId } from '../../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../../../../_shared/utils/hooks';
import { usePrevious } from '../../../../../../_shared/utils/hooks/usePrevious';
import { useGetBoundaryGroup } from '../../../../../../store/boundaryGroups/boundaryGroups.selectors';
import {
  BoundaryTerritoryType,
  type CalculateBucketFunctionNumeric,
  isBoundaryTerritoryTypeAllowedWithoutGeocoding,
} from '../../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { hasBoundaryFillTypeBoundaryData } from '../../boundaryTerritoryGroup.helpers';
import { getBoundaryTerritoryTypeDropdownOptions } from '../../fill/boundaryFill.helpers';
import { useSpreadsheetColumnsOptions } from '../../hooks/useSpreadsheetColumnsOptions';

type UseActiveBoundaryFillProps = {
  boundaryGroupId: number | null;
  hiddenBoundaryTerritoryTypeOptions?: ReadonlyArray<BoundaryTerritoryType>;
  initialBoundaryFillData?: SpreadsheetColumnId | null;
  initialBoundaryTerritoryType?: BoundaryTerritoryType | null;
  initialDemographicId?: string | null;
  initialNumericCalculateBucketFunction?: CalculateBucketFunctionNumeric | null;
};

export const useActiveBoundaryFillEdit = (props: UseActiveBoundaryFillProps) => {
  const [boundaryTerritoryType, setBoundaryTerritoryType] = useState<BoundaryTerritoryType | null>(props.initialBoundaryTerritoryType ?? null);
  const [boundaryFillData, setBoundaryFillData] = useState<SpreadsheetColumnId | null>(props.initialBoundaryFillData ?? null);
  const [numericCalculateBucketFunction, setNumericCalculateBucketFunction] = useState<CalculateBucketFunctionNumeric | null>(props.initialNumericCalculateBucketFunction ?? null);
  const [selectedDemographicId, setSelectedDemographicId] = useState<string | null>(props.initialDemographicId ?? null);
  const [selectedDemographicCategory, setSelectedDemographicCategory] = useState<string | null>(null);
  const prevSelectedDemographyCategory = usePrevious(selectedDemographicCategory);
  const spreadsheetColumnOptions = useSpreadsheetColumnsOptions();
  const { getBoundaryGroupById } = useGetBoundaryGroup();
  const isGeocodingRunning = useIsGeocodingRunningOrRequired();
  const [t] = useTranslation();
  const { hiddenBoundaryTerritoryTypeOptions } = props;

  const showDemographicGroup = boundaryTerritoryType === BoundaryTerritoryType.Demographic;
  const hideFillData = !hasBoundaryFillTypeBoundaryData(boundaryTerritoryType);

  const itemBoundaryGroup = props.boundaryGroupId !== null ? getBoundaryGroupById(props.boundaryGroupId) : null;

  const demographics = useMemo(() => itemBoundaryGroup?.demographics ?? [], [itemBoundaryGroup]);
  const { demographicOptions, demographicCategoryOptions } = useDemographicDropdownOptions(demographics, selectedDemographicCategory);

  const dataChanged =
    props.initialBoundaryTerritoryType !== boundaryTerritoryType ||
    props.initialBoundaryFillData !== boundaryFillData ||
    props.initialDemographicId !== selectedDemographicId ||
    numericCalculateBucketFunction !== null;

  const boundaryTerritoryTypeInvalidForGeocodingState = isGeocodingRunning && boundaryTerritoryType
    && !isBoundaryTerritoryTypeAllowedWithoutGeocoding(boundaryTerritoryType);

  const isUpdateDisabled: boolean = (
    boundaryTerritoryTypeInvalidForGeocodingState ||
    boundaryTerritoryType === null ||
    (showDemographicGroup && (selectedDemographicCategory === null || selectedDemographicId === null)) ||
    (boundaryTerritoryType === BoundaryTerritoryType.Numeric && numericCalculateBucketFunction === null) ||
    (boundaryTerritoryType === BoundaryTerritoryType.Numeric && !boundaryFillData) ||
    (boundaryTerritoryType === BoundaryTerritoryType.Groups && !boundaryFillData)
  ) || !dataChanged;

  const updateDisabledReason = boundaryTerritoryTypeInvalidForGeocodingState ? t('Cannot use selected fill option before geocoding is completed') : null;

  const boundaryTerritoryTypeOptions: ReadonlyArray<DropdownOption<BoundaryTerritoryType>> = useMemo(() => {
    const options = getBoundaryTerritoryTypeDropdownOptions(t);

    const newOptions = (demographics.length > 0) ?
      options :
      options.filter(item => item.value !== BoundaryTerritoryType.Demographic);

    return newOptions.filter(item => !hiddenBoundaryTerritoryTypeOptions?.includes(item.value));
  }, [t, demographics.length, hiddenBoundaryTerritoryTypeOptions]);

  // set boundary type to first available if the current is NOT on a list
  useEffect(() => {
    if (boundaryTerritoryTypeOptions.length === 0 || boundaryTerritoryTypeOptions.find(type => type.value === boundaryTerritoryType)) {
      return;
    }

    setBoundaryTerritoryType(boundaryTerritoryTypeOptions[0]?.value ?? null);
  }, [boundaryTerritoryType, boundaryTerritoryTypeOptions]);

  useEffect(() => {
    if (prevSelectedDemographyCategory !== null && prevSelectedDemographyCategory !== selectedDemographicCategory) {
      setSelectedDemographicId(null);
    }
  }, [selectedDemographicCategory, prevSelectedDemographyCategory]);

  useEffect(() => {
    setBoundaryTerritoryType(props.initialBoundaryTerritoryType ?? null);
  }, [props.initialBoundaryTerritoryType]);

  useEffect(() => {
    setBoundaryFillData(props.initialBoundaryFillData ?? null);
  }, [props.initialBoundaryFillData]);

  useEffect(() => {
    setSelectedDemographicId(null);
  }, [props.boundaryGroupId]);

  useEffect(() => {
    setSelectedDemographicId(props.initialDemographicId ?? null);
    const selectedDemographic = demographics.find(demographic => demographic.id === props.initialDemographicId);

    if (!selectedDemographic || props.initialDemographicId === null) {
      setSelectedDemographicCategory(null);
    }
    else {
      setSelectedDemographicCategory(selectedDemographic.category.toLowerCase());
    }
  }, [props.initialDemographicId, demographics]);

  return {
    isUpdateDisabled,
    updateDisabledReason,
    hideFillData,
    showDemographicGroup,
    onBoundaryTerritoryTypeChange: setBoundaryTerritoryType,
    boundaryTerritoryTypeOptions,
    selectedDemographicCategory,
    onSelectedDemographicCategoryChange: setSelectedDemographicCategory,
    demographicCategoryOptions,
    onBoundaryFillDataChange: setBoundaryFillData,
    spreadsheetColumnOptions,
    onDemographicIdChange: setSelectedDemographicId,
    demographicOptions,
    boundaryFillModel: {
      boundaryTerritoryType,
      boundaryFillData,
      selectedDemographicId,
      numericCalculateBucketFunction,
    },
    onNumericCalculateBucketFunctionChange: setNumericCalculateBucketFunction,
  };
};
