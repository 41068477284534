import { type MapSettingsDataAction } from '../../data/mapSettingsData.action';
import { type MapSettingsHeatmapsAction } from '../../heatmaps/mapSettingsHeatmaps.action';
import { type VisibilityAction } from './visibility.action';
import {
  MAP_SETTINGS_VISIBILITY_HIDE_HEATMAP,
  MAP_SETTINGS_VISIBILITY_HIDE_PROXIMITY,
  MAP_SETTINGS_VISIBILITY_SHOW_HEATMAP,
  MAP_SETTINGS_VISIBILITY_SHOW_PROXIMITY,
} from './visibility.actionTypes';
import { type VisibilityState } from './visibility.state';

export const mapSettingsVisibilityInitialState: VisibilityState = {
  hiddenProximityIds: [],
  hiddenHeatmapIds: [],
};

export const visibilityReducer = (
  state = mapSettingsVisibilityInitialState,
  action: VisibilityAction | MapSettingsHeatmapsAction | MapSettingsDataAction
): VisibilityState => {
  switch (action.type) {

    // Proximities
    case MAP_SETTINGS_VISIBILITY_SHOW_PROXIMITY:
      return {
        ...state,
        hiddenProximityIds: state.hiddenProximityIds.filter(id => id !== action.payload.proximityId),
      };

    case MAP_SETTINGS_VISIBILITY_HIDE_PROXIMITY:
      return {
        ...state,
        hiddenProximityIds: [...state.hiddenProximityIds, action.payload.proximityId],
      };

    // Heatmaps
    case MAP_SETTINGS_VISIBILITY_SHOW_HEATMAP:
      return {
        ...state,
        hiddenHeatmapIds: state.hiddenHeatmapIds.filter(id => id !== action.payload.heatMapId),
      };

    case MAP_SETTINGS_VISIBILITY_HIDE_HEATMAP:
      return {
        ...state,
        hiddenHeatmapIds: [...state.hiddenHeatmapIds, action.payload.heatMapId],
      };

    default:
      return state;
  }
};
