import { css } from '@emotion/react';
import { type FC } from 'react';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { useTheme } from '../../../../../../_shared/themes/theme.hooks';
import { type SpreadsheetColumnId } from '../../../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { type ThemeProps } from '../../../../../../_shared/types/themeProps';
import {
  type TranslationFnc, useTranslation,
} from '../../../../../../_shared/utils/hooks';
import {
  BoundaryTerritoryType,
  type CalculateBucketFunctionNumeric,
} from '../../../../../../store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { BoundarySelectedOption } from '../../fill/boundaryFill.enums';

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const dropdownTriggerStyle = ({ theme, isPlaceholder }: ThemeProps<{ isPlaceholder: boolean }>) => css({
  color: isPlaceholder ? theme.textColors.disabled : undefined,
  height: 40,
});

type ActiveBoundaryEditProps = Readonly<{
  boundaryFillData: SpreadsheetColumnId | null;
  boundaryTerritoryType: BoundaryTerritoryType | null;
  boundaryTerritoryTypeOptions: ReadonlyArray<DropdownOption<BoundaryTerritoryType>>;
  demographicCategoryOptions: ReadonlyArray<DropdownOption<string>>;
  demographicOptions: ReadonlyArray<DropdownOption<string>>;
  hideFillData: boolean;
  labelCountStart: number;
  numericCalculateBucketFunction: CalculateBucketFunctionNumeric | null;
  onBoundaryFillDataChange: (fillData: SpreadsheetColumnId) => void;
  onBoundaryTerritoryTypeChange: (type: BoundaryTerritoryType) => void;
  onDemographicIdChange: (demographicId: string) => void;
  onNumericCalculateBucketFunctionChange: (calculateFunction: CalculateBucketFunctionNumeric) => void;
  onSelectedDemographicCategoryChange: (category: string) => void;
  selectedDemographicCategory: string | null;
  selectedDemographicId: string | null;
  showDemographicGroup: boolean;
  spreadsheetColumnOptions: ReadonlyArray<DropdownOption<SpreadsheetColumnId>>;
}>;

export const ActiveBoundaryFillEditComponent: FC<ActiveBoundaryEditProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();

  let labelCount = props.labelCountStart;

  return (
    <div>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>
          {labelCount++}. {t(BoundarySelectedOption.BoundaryTerritoryType)}
        </div>

        <RegularDropdownComponent
          onChange={props.onBoundaryTerritoryTypeChange}
          options={props.boundaryTerritoryTypeOptions}
          placeholder={t(BoundarySelectedOption.BoundaryTerritoryType)}
          triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: props.boundaryTerritoryType === null })}
          value={props.boundaryTerritoryType}
          inPortal
        />
      </div>

      {props.boundaryTerritoryType && props.showDemographicGroup && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t(BoundarySelectedOption.DemographicGroup)}
          </div>
          <RegularDropdownComponent
            onChange={props.onSelectedDemographicCategoryChange}
            options={props.demographicCategoryOptions}
            placeholder={t(BoundarySelectedOption.DemographicGroup)}
            triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: props.selectedDemographicCategory === null })}
            value={props.selectedDemographicCategory}
            inPortal
          />
        </div>
      )}

      {!props.hideFillData && props.showDemographicGroup && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t(BoundarySelectedOption.BoundaryFillData)}
          </div>
          <RegularDropdownComponent
            onChange={props.onDemographicIdChange}
            options={props.demographicOptions}
            placeholder={t(BoundarySelectedOption.BoundaryFillData)}
            triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: props.onDemographicIdChange === null })}
            value={props.selectedDemographicId}
            inPortal
          />
        </div>
      )}

      {!props.hideFillData && props.boundaryTerritoryType === BoundaryTerritoryType.Numeric && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t(BoundarySelectedOption.SelectCalculateFunction)}
          </div>

          <RegularDropdownComponent<CalculateBucketFunctionNumeric>
            onChange={props.onNumericCalculateBucketFunctionChange}
            options={getCalculateBucketFunctionOptions(t)}
            placeholder={t(BoundarySelectedOption.SelectCalculateFunction)}
            triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: !props.numericCalculateBucketFunction })}
            value={props.numericCalculateBucketFunction}
            inPortal
          />
        </div>
      )}

      {!props.hideFillData && !props.showDemographicGroup && (props.boundaryTerritoryType !== BoundaryTerritoryType.Numeric || props.numericCalculateBucketFunction) && (
        <div css={panelDropdownSectionStyle}>
          <div css={labelStyle}>
            {labelCount++}. {t(BoundarySelectedOption.BoundaryFillData)}
          </div>

          <RegularDropdownComponent<SpreadsheetColumnId>
            onChange={props.onBoundaryFillDataChange}
            options={props.spreadsheetColumnOptions}
            placeholder={t(BoundarySelectedOption.BoundaryFillData)}
            triggerStyle={dropdownTriggerStyle({ theme, isPlaceholder: !props.boundaryFillData })}
            compareOptions={(a, b) => a?.columnId === b?.columnId && a?.spreadsheetId === b?.spreadsheetId}
            value={props.boundaryFillData}
            inPortal
          />
        </div>
      )}
    </div>
  );
};

export const getCalculateBucketFunctionOptions = (
  t: TranslationFnc
): ReadonlyArray<DropdownOption<CalculateBucketFunctionNumeric>> => ([{
  name: t('Sum'),
  value: 'sum',
}, {
  name: t('Average'),
  value: 'avg',
}, {
  name: t('Min'),
  value: 'min',
}, {
  name: t('Max'),
  value: 'max',
}]);
