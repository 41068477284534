export const FRONTEND_STATE_DISTANCE_CALCULATOR_START = 'FRONTEND_STATE_DISTANCE_CALCULATOR_START';
export const FRONTEND_STATE_DISTANCE_CALCULATOR_STOP = 'FRONTEND_STATE_DISTANCE_CALCULATOR_STOP';

export const FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED = 'FRONTEND_STATE_DISTANCE_CALCULATOR_DRAWING_MODE_SELECTED';

export const FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER = 'FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_HOVER';
export const FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT = 'FRONTEND_STATE_DISTANCE_CALCULATOR_REMOVE_MOUSEOUT';

export const FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER = 'FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_HOVER';
export const FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT = 'FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_MOUSEOUT';

export const FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START = 'FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_START';
export const FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP = 'FRONTEND_STATE_DISTANCE_CALCULATOR_OUTLINE_DRAG_STOP';
