import { css } from '@emotion/react';
import { type Placement } from '@popperjs/core';
import { zIndexGeneral } from '~/_shared/constants/zIndexGeneral.enum';
import { type ThemeProps } from '~/_shared/types/themeProps';

const getArrowBorderColor = (placement: Placement, color: string) => {
  switch (placement) {
    case 'bottom':
    case 'bottom-end':
    case 'bottom-start':
      return `transparent transparent ${color} transparent`;
    case 'left':
    case 'left-end':
    case 'left-start':
      return `transparent transparent transparent ${color}`;
    case 'right':
    case 'right-end':
    case 'right-start':
      return `transparent ${color} transparent transparent`;
    case 'top':
    case 'top-end':
    case 'top-start':
      return `${color} transparent transparent transparent`;
    default:
      console.error(`There is no way to create arrow border color for placement '${placement}'`);
      return `${color} transparent transparent transparent`;
  }
};

export const tooltipArrowStyle = ({ theme, placement }: ThemeProps<{ placement: Placement }>) => css({
  height: '1rem',
  position: 'absolute',
  width: '1rem',
  pointerEvents: 'none',
  '&::before': {
    borderStyle: 'solid',
    content: '""',
    display: 'block',
    height: 0,
    margin: 'auto',
    width: 0,
    borderColor: 'transparent transparent transparent transparent',
  },
  '&::after': {
    borderStyle: 'solid',
    content: '""',
    display: 'block',
    height: 0,
    margin: 'auto',
    position: 'absolute',
    width: 0,
    borderColor: getArrowBorderColor(placement, theme.tooltipColors.backgroundColor),
  },
  '&[data-placement*=\'top\']': {
    height: 0,
    marginBottom: 0,
    bottom: 0,
    left: 0,
    width: '1rem',
    '&::before': {
      borderWidth: '0.4rem 0.5rem 0 0.5rem',
      position: 'absolute',
      top: '1px',
    },
    '&::after': {
      top: -1,
      borderWidth: '0.4rem 0.5rem 0 0.5rem',
    },
  },
  '&[data-placement*=\'bottom\']': {
    height: '1rem',
    left: 0,
    marginTop: '-0.3rem',
    top: 0,
    width: '1rem',
    '&::before': {
      borderWidth: '0 0.5rem 0.4rem 0.5rem',
      position: 'absolute',
      top: '-1px',
    },
    '&::after': {
      borderWidth: '0 0.5rem 0.4rem 0.5rem',
    },
  },
  '&[data-placement*=\'right\']': {
    height: '1rem',
    left: 0,
    marginLeft: '-0.7rem',
    width: '1rem',
    '&::before': {
      borderWidth: '0.5rem 0.4rem 0.5rem 0',
    },
    '&::after': {
      borderWidth: '0.5rem 0.4rem 0.5rem 0',
      left: '6px',
      top: 0,
    },
  },
  '&[data-placement*=\'left\']': {
    height: '1rem',
    marginRight: '-0.7rem',
    right: 0,
    width: '1rem',
    '&::before': {
      borderWidth: '0.5rem 0 0.5rem 0.4em',
    },
    '&::after': {
      borderWidth: '0.5rem 0 0.5rem 0.4em',
      left: '3px',
      top: 0,
    },
  },
});

export const tooltipContentStyle = ({ theme, isVisible }: ThemeProps<{ isVisible: boolean }>) => css({
  border: 'none',
  backgroundColor: theme.tooltipColors.backgroundColor,
  color: theme.tooltipColors.textColor,
  visibility: isVisible ? 'visible' : 'hidden',
  zIndex: zIndexGeneral.PopperTooltip,
  pointerEvents: 'none',
  borderRadius: '3px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.4rem',
  transition: 'opacity 0.3s',
});
