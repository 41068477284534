import { LottieAnimationTypes } from '../lottieAnimation.types';

const config = { [LottieAnimationTypes.SmudgeDog]: {
  views: {
    default: {
      canvasHeight: 1000,
      canvasWidth: 1000,
      iconHeight: 848,
      iconWidth: 928,
      offsetTop: 76,
      offsetLeft: 36,
    },
  },
  colors: {},
  gradients: {},
  frameRate: 25,
  segments: {
    default: [0, 100] as const,
    shake: [0, 26] as const,
    happy: [50, 76] as const,
  },
  getJson: () =>
    import(
      /* webpackChunkName: "animations/cuteDog" */
      './cuteDog.json'
    ),
} };

export const { SmudgeDog } = config;
