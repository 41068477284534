import { useSelector } from '../../../_shared/utils/hooks/useSelector';
import { type AppState } from '../../app.store';
import { mapSettingsExportImageSettingsSelector } from '../../mapSettings/toolsState/exportImageSettings/exportImageSettings.selectors';

const isMobileScreenSelector = (state: AppState) => state.frontendState.deviceInfo.isMobileScreen;

export const useIsMobileScreenSelector = () => {
  const isMobileScreen = useSelector(isMobileScreenSelector);
  const isExportMode = !!useSelector(mapSettingsExportImageSettingsSelector)?.mode;

  return isMobileScreen && !isExportMode;
};
