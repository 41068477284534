import {
  initialLabelVisualizerArrowProps,
  initialLabelVisualizerBodyProps,
  initialLabelVisualizerBorderProps,
} from '../../components/labelVisualizer/labelVisualizer.constants';
import { type PRESET_COLORS_PRIMARY } from '../../constants/colors.constants';
import { type LabelVisualSetting } from '../../types/markers/visualSettings.types';
import { type ArrayElement } from '../array/array.helpers';

export const DEFAULT_NUMERIC_ROUND_MARKER_OPACITY = 0.7;
export const DEFAULT_NUMERIC_BLUE_MARKER_COLOR_RGB = { r: 136, g: 193, b: 246, a: DEFAULT_NUMERIC_ROUND_MARKER_OPACITY };
export const DEFAULT_NUMERIC_GREEN_MARKER_COLOR = '7CA640';
export const DEFAULT_STANDARD_MARKER_OPACITY = 1;
export const DEFAULT_MARKER_SIZE = 55;
export const LEGEND_MARKER_SIZE = 45;
export const SIZE_OF_NUMERICAL_MARKER_CENTRE = 51;

export const DEFAULT_MARKER_STYLE_ID = 5;
export const ID_OF_GROWING_NUMERIC_MARKER = 14;
export const ID_OF_NUMERICAL_MARKER = 12;
export const ID_OF_NUMERICAL_MARKER_CENTRE = 13;
export const ID_OF_SECONDARY_GROUP_TEXT_MARKER = 23;
export const DEFAULT_TEXT_MARKER_OPACITY = 0.75;

export const MARKER_DEFAULT_COLOR: ArrayElement<typeof PRESET_COLORS_PRIMARY> = '#09B40F';

export const PATH_TO_NUMERICAL_MARKER_CENTRE = 'maptive/22/l1.png';

export const GROUPING_SECONDARY_MARKER_SUFFIX = '%SECONDARY%';

export const INITIAL_LABEL: LabelVisualSetting = {
  arrowProps: initialLabelVisualizerArrowProps,
  bodyProps: initialLabelVisualizerBodyProps,
  borderProps: initialLabelVisualizerBorderProps,
};
