import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { boundaryTerritoryDelete } from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroups.actionCreators';
import {
  PermanentConfirmStrategy,
  useConfirmationModal,
} from '../../../../../_shared/components/modal/confirmation/useConfirmationModal';

export const useRemoveBoundaryTerritory = () => {
  const { openConfirmationModal, closeConfirmationModal } = useConfirmationModal();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const removeTerritory = useCallback((
    boundaryTerritoryGroupId: number, boundaryTerritoryId: string
  ) => {
    openConfirmationModal({
      title: t('Delete Fill Group'),
      confirmCaption: t('Proceed'),
      text: t('You are about to delete a fill group. This action is irreversible. Do you want to proceed?'),
      permanentConfirmSettings: {
        id: 'remove-boundary-territory',
        strategy: PermanentConfirmStrategy.Session,
      },
      isConfirmButtonDestructive: true,
      onCancel: () => {
        closeConfirmationModal();
      },
      onConfirm: () => {
        dispatch(boundaryTerritoryDelete(boundaryTerritoryGroupId, boundaryTerritoryId));
        closeConfirmationModal();
      },
    });
  }, [dispatch, t, openConfirmationModal, closeConfirmationModal]);

  return {
    removeTerritory,
  };
};
