import { mixColorMemoized } from '~/_shared/utils/colors/colors.helpers';
import {
  type AnimatableColorProperty, type AnimatableNumericalProperty,
} from './animatableProperty.types';

export const getValueOfAnimatableNumericalProperty = (
  animatableProperty: AnimatableNumericalProperty,
  animationPercent: number,
): number => {
  if (animatableProperty.animation) {
    const range = animatableProperty.animation.to - animatableProperty.animation.from;

    return animatableProperty.animation.from + animationPercent * range;
  }
  return animatableProperty.value;
};

export const getValueOfAnimatableColorProperty = (
  animatableProperty: AnimatableColorProperty,
  animationPercent: number,
): string => {
  if (animatableProperty.animation) {
    return mixColorMemoized(
      animatableProperty.animation.from, animatableProperty.animation.to, animationPercent,
    ).toHex();
  }
  return animatableProperty.value;
};
