import {
  BOUNDARY_RENDERING_EXPECTED_RESET,
  BOUNDARY_RENDERING_START,
  BOUNDARY_RENDERING_STOP,
  BOUNDARY_SELECT_RENDERING_START,
  BOUNDARY_SELECT_RENDERING_STOP,
} from './boundaryRendering.actionTypes';

export const boundaryRenderingStart = () => ({
  type: BOUNDARY_RENDERING_START,
}) as const;

export const boundaryRenderingStop = () => ({
  type: BOUNDARY_RENDERING_STOP,
}) as const;

export const boundarySelectRenderingStart = () => ({
  type: BOUNDARY_SELECT_RENDERING_START,
}) as const;

export const boundarySelectRenderingStop = () => ({
  type: BOUNDARY_SELECT_RENDERING_STOP,
}) as const;

export const resetExpectBoundaryRendering = () => ({
  type: BOUNDARY_RENDERING_EXPECTED_RESET,
}) as const;
