import {
  type FC, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { mapChange } from '~/store/map/map.actionCreators';
import {
  useBaseMapNames, useIsConnectedLayeredMapSelector,
} from '~/store/mapInfo/mapInfo.selectors';
import { useClientIdSelector } from '~/store/selectors/useClientIdSelector';
import { MapConnectSettings } from '../mapConnectSettings';
import {
  EditLayeredMapModalComponent, type EditLayeredMapModalSettings,
} from './editLayeredMapModal.component';
import { useEditLayeredMap } from './useEditLayeredMap';

export type EditLayeredMapModalContainerProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const EditLayeredMapModalContainer: FC<EditLayeredMapModalContainerProps> = (props) => {
  const clientId = useClientIdSelector();
  const dispatch = useDispatch();
  const mapInfo = useSelector((state) => state?.map?.mapInfo?.data);
  const { editLayeredMap, isLoading: isEditingLayeredMap } = useEditLayeredMap();
  const baseMaps = useBaseMapNames();
  const isLayeredMapConnected = useIsConnectedLayeredMapSelector();

  const onEditLayeredMapSuccess = useCallback(() => {
    if (mapInfo && mapInfo.id) {
      dispatch(mapChange(mapInfo.id));
    }
    props.onClose();
  }, [mapInfo, props, dispatch]);

  const onEditLayeredMap = useCallback((settings: EditLayeredMapModalSettings, deletedMaps: ReadonlySet<number>) => {
    if (clientId !== null && mapInfo) {
      editLayeredMap(
        clientId,
        mapInfo.id,
        {
          layeringMapInfo: mapInfo,
          name: settings.mapName,
          integrated: true,
          connected: settings.connectionSetting === MapConnectSettings.ConnectedInRealTime
            || settings.connectionSetting === MapConnectSettings.ConnectedWithApproval,
          realTime: settings.connectionSetting === MapConnectSettings.ConnectedInRealTime,
          newMaps: settings.selectedMapListItems.map(sM => sM.id),
          deletedMaps: [...deletedMaps],
          matches: settings.matches,
        },
        onEditLayeredMapSuccess
      );
    }
  }, [mapInfo, editLayeredMap, clientId, onEditLayeredMapSuccess]);

  return (
    <EditLayeredMapModalComponent
      currentBaseMaps={baseMaps}
      isEditingLayeredMap={isEditingLayeredMap}
      isMapLayered={isLayeredMapConnected}
      isOpen={props.isOpen}
      mapInfo={mapInfo}
      onClose={props.onClose}
      onEditLayeredMap={onEditLayeredMap}
    />
  );
};
