import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { CONFIG } from '~/_shared/constants/config';
import { GoogleApiScope } from '~/_shared/utils/googleApi/googleApi.helpers';
import { type GoogleApiOauthStatus } from '~/store/userData/repository/userData.repository';
import { loadGoogleApiScript } from './google-api.loader';

type GooglePickerApiProps = {
  onGoogleUserPermissionsScopeError: () => void;
  onGeneralError: () => void;
  onCanceledByUser?: () => void;
};

export const useGooglePickerApi = (props: GooglePickerApiProps) => {
  const { onGeneralError: onError, onGoogleUserPermissionsScopeError, onCanceledByUser } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestedPickerProps, setRequestedPickerProps] = useState<{ googleOAuthToken: string; onFileSelected: (selectedFileId: string) => void } | null>(null);

  const requestShowPickerAndGetSelectedFile = useCallback((googleOAuthToken: string, googleApiUserInfo: GoogleApiOauthStatus | null, onFileSelected: (selectedFileId: string) => void) => {
    if (!googleApiUserInfo || !googleApiUserInfo.scopes.includes(GoogleApiScope.DriveFile)) {
      onGoogleUserPermissionsScopeError();
      return;
    }

    setRequestedPickerProps({
      googleOAuthToken,
      onFileSelected,
    });
  }, [onGoogleUserPermissionsScopeError]);

  useEffect(() => {
    if (!isLoaded || !requestedPickerProps) {
      return;
    }

    const { googleOAuthToken, onFileSelected } = requestedPickerProps;

    let picker: google.picker.Picker | null = null;

    const pickerCallback = (data: google.picker.ResponseObject) => {
      if (data.action === google.picker.Action.PICKED) {
        const document = data[google.picker.Response.DOCUMENTS][0];
        if (document) {
          const fileId = document[google.picker.Document.ID];
          setRequestedPickerProps(null);
          onFileSelected(fileId);
        }
        if (picker) {
          picker.setVisible(false);
          picker.dispose();
        }
      }
      else if (data.action === google.picker.Action.CANCEL) {
        onCanceledByUser?.();
      }
    };

    try {
      const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);
      view.setMode(google.picker.DocsViewMode.LIST);
      view.setMimeTypes('application/vnd.google-apps.spreadsheet');
      picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .setOAuthToken(googleOAuthToken)
        .setDeveloperKey(CONFIG.GOOGLE_MAPS_API_KEY)
        .setAppId(CONFIG.GOOGLE_APP_ID)
        .addView(view)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }
    catch (e) {
      console.error('Unable to initialize Google Api Picker');
      onError();
    }
  }, [isLoaded, onCanceledByUser, onError, requestedPickerProps]);

  useEffect(() => {
    if (isLoading || isLoaded) {
      return;
    }

    setIsLoading(true);
    loadGoogleApiScript(() => {
      try {
        window.gapi.load('picker', {
          callback: () => {
            setIsLoading(false);
            setIsLoaded(true);
          },
          onerror: (error: any) => {
            console.error('Error Loading Google Picker:', error);
            onError();
          },
        });
      }
      catch (e: any) {
        onError();
      }
    });
  }, [isLoaded, isLoading, onError]);

  return useMemo(() => ({
    isLoading: !isLoaded,
    requestShowPickerAndGetSelectedFile,
  }), [isLoaded, requestShowPickerAndGetSelectedFile]);
};
