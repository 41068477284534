import { css } from '@emotion/react';
import { type FC } from 'react';
import { LoaderComponent } from '~/_shared/baseComponents/loaders';
import { hexColorWithOpacityToString } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useIsMobileScreenSelector } from '~/store/frontendState/deviceInfo/deviceInfo.selector';

const CONTAINER_BORDER_RADIUS = 8;
const containerStyle = ({ theme, isMobile, position }: ThemeProps & {
  isMobile: boolean; position: 'top' | 'bottom';
}) => css({
  position: 'absolute',
  top: position === 'top' ? 0 : undefined,
  bottom: position === 'bottom' ? 0 : undefined,
  left: isMobile ? 0 : '50%',
  right: isMobile ? 0 : undefined,
  transform: isMobile ? undefined : 'translate(-50%, 0)',
  background: hexColorWithOpacityToString(theme.backgroundColors.primary, 0.9),
  padding: '10px',
  display: 'flex',
  justifyContent: 'flex-start',
  color: theme.textColors.primary,
  border: '1px solid' + theme.borderColors.highContrast,
  boxSizing: 'border-box',
  borderRadius: position === 'top' ? `0 0 ${CONTAINER_BORDER_RADIUS}px ${CONTAINER_BORDER_RADIUS}px` : `${CONTAINER_BORDER_RADIUS}px ${CONTAINER_BORDER_RADIUS}px 0 0`,
  zIndex: 100,
});

const titleStyle = ({ isMobile }: { isMobile: boolean }) => css({
  fontWeight: 700,
  fontSize: isMobile ? '16px' : '24px',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  lineHeight: isMobile ? '20px' : '30px',
});

const subTitleStyle = ({ isMobile }: { isMobile: boolean }) => css({
  fontSize: isMobile ? '10px' : '14px',
  whiteSpace: 'nowrap',
  textAlign: 'center',
});

const marginBoxStyle = css({
  width: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const loaderStyle = css({
  margin: 0,
});

export type OverMapLoaderComponentProps = {
  position: 'top' | 'bottom';
  titleText: string;
  subTitleText: string;
};

export const OverMapLoaderComponent: FC<OverMapLoaderComponentProps> = (props) => {
  const theme = useTheme();
  const isMobile = useIsMobileScreenSelector();

  return (
    <div css={containerStyle({ theme, isMobile, position: props.position })}>
      <div css={marginBoxStyle}>
        <LoaderComponent
          css={loaderStyle}
          size={isMobile ? 30 : 40}
        />
      </div>
      <div>
        <div css={titleStyle({ isMobile })}>
          {props.titleText}
        </div>
        <div css={subTitleStyle({ isMobile })}>
          {props.subTitleText}
        </div>
      </div>
      <div css={marginBoxStyle} />
    </div>
  );
};
