import {
  useCallback, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { type PolygonPath } from '~/_shared/types/polygon/polygon.types';
import { createUuid } from '~/_shared/utils/createUuid';
import { LassoDrawComponent } from '~/map/lassoSelect/lassoDraw.component';
import { pathToMapShapeOutlines } from '~/map/map/mapObjects/mapShape/mapShapeModel';
import {
  boundaryDrawAddShape, boundaryDrawDeactivate, boundaryDrawOutlineHover, boundaryDrawOutlineMouseOut, boundaryDrawStartDrawing, boundaryDrawStopDrawing,
} from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.actionCreators';
import { useBoundaryDrawActiveSelector } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.selectors';
import { DrawType } from '~/store/frontendState/mapTools/boundary/boundaryDraw/boundaryDraw.state';
import { useBoundaryDrawPolygonZIndex } from '../../../zIndexes/useBoundaryDrawPolygonZIndex.hook';

export const BoundaryDrawContainer: React.FC = () => {
  const dispatch = useDispatch();

  const drawState = useBoundaryDrawActiveSelector();
  const zIndex = useBoundaryDrawPolygonZIndex();

  const addShape = useCallback((path: PolygonPath) => {
    const shape = { id: createUuid(), outlines: pathToMapShapeOutlines(path) };

    dispatch(boundaryDrawAddShape(shape));
  }, [dispatch]);

  const stopDrawing = useCallback(() => {
    dispatch(boundaryDrawDeactivate());
  }, [dispatch]);

  const onOutlineHover = useCallback(() => dispatch(boundaryDrawOutlineHover()), [dispatch]);
  const onOutlineMouseOut = useCallback(() => dispatch(boundaryDrawOutlineMouseOut()), [dispatch]);

  const isDrawing = drawState?.drawType === DrawType.Draw;

  useEffect(() => {
    if (isDrawing) {
      dispatch(boundaryDrawStartDrawing());
    }

    return () => {
      if (isDrawing) {
        dispatch(boundaryDrawStopDrawing());
      }
    };
  }, [dispatch, isDrawing]);

  if (!isDrawing) {
    return null;
  }

  return (
    <LassoDrawComponent
      zIndex={zIndex}
      onDrawingStop={stopDrawing}
      onDrawingFinished={addShape}
      onFirstOutlineHover={onOutlineHover}
      onFirstOutlineMouseOut={onOutlineMouseOut}
    />
  );
};
