import { type CombinedRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import { useTranslation } from '~/_shared/utils/hooks';
import {
  type MarkerNameResolver, useMarkerNameResolver,
} from '~/_shared/utils/spreadsheet/useMarkerNameResolver';
import { maxTotalWaypoints } from '~/directions/getDirections.service';
import { ModalType } from '~/modal/modalType.enum';
import { useModal } from '~/modal/useModal.hook';
import { type RowsPerSpreadsheet } from '../rowsPerSpreadsheet';
import { useDisplayMarkerSelectionDialog } from './useDisplayMarkerSelectionDialog';
import { useSendToOptimizeRoute } from './useSendToOptimizeRoute';

export type RowWithName = {
  rowId: CombinedRowId;
  name: string;
  spreadsheetId: number;
};

const flattenOneLevel = (arrays: RowWithName[][]): RowWithName[] => {
  return arrays.reduce((acc, array) => acc.concat(array), []);
};

const addMarkerNames = (rowsPerSpreadsheets: ReadonlyArray<RowsPerSpreadsheet>, markerNameResolver: MarkerNameResolver) => {
  const rowsWithNamesPerSpreadsheet = rowsPerSpreadsheets.map(spreadsheet => {

    const rowsWithNames = spreadsheet.rows
      .map((row) => {
        const rowId = row.id;

        const name = markerNameResolver(spreadsheet.spreadsheetId, rowId, true);

        return { rowId, name, spreadsheetId: spreadsheet.spreadsheetId };
      });

    return rowsWithNames;
  });

  return rowsWithNamesPerSpreadsheet;
};

const WAYPOINTS_HARD_LIMIT = 300;

export const useProcessMarkersForRouting = () => {
  const { sendToOptimizeRoute, loading } = useSendToOptimizeRoute();
  const { displayMarkerSelectionDialog } = useDisplayMarkerSelectionDialog();
  const { openModal: displayAlertModal } = useModal(ModalType.AdHoc);
  const { markerNameResolver } = useMarkerNameResolver();
  const [t] = useTranslation();

  const displayTooManyMarkersSelectedAlert = () => {
    displayAlertModal({ caption: t('Too many locations'), content: t('There are too many markers in selected area. Please select area with fewer locations and try again.') });
  };

  const processSelectedData = (rowsPerSpreadsheets: ReadonlyArray<RowsPerSpreadsheet>) => {
    const rowsWithNames = flattenOneLevel(addMarkerNames(rowsPerSpreadsheets, markerNameResolver));

    const rowCountExceedsSoftLimit = rowsWithNames.length > maxTotalWaypoints;
    const rowCountExceedsHardLimit = rowsWithNames.length > WAYPOINTS_HARD_LIMIT;
    if (rowCountExceedsHardLimit) {
      displayTooManyMarkersSelectedAlert();
      return;
    }
    if (rowCountExceedsSoftLimit) {
      displayMarkerSelectionDialog({ rows: rowsWithNames, onSubmit: sendToOptimizeRoute });
      return;
    }

    sendToOptimizeRoute(rowsWithNames);
    return;
  };

  return {
    processSelectedData,
    loading,
  };
};
