import { css } from '@emotion/react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { type ReactNode } from 'react';
import { ButtonStyle } from '~/_shared/baseComponents/buttons/button/button.types';
import { FontAwesomeIcon } from '~/_shared/baseComponents/icon/fontAwesomeIcon.component';
import { type Theme } from '~/_shared/themes/theme.model';
import { always } from '~/_shared/utils/function.helpers';
import { type TranslationFnc } from '~/_shared/utils/hooks';
import { EMPTY_MESSAGE } from '~/map/settings/structure.constants';
import {
  type SettingsAccordionSubItem, SettingsAccordionSubItemType,
} from '../../settingsAccordionData.type';
import { MapStylesColorListSettingsName } from '../baseMap.enums';
import { type BaseMapStructureProps } from './standardBaseMap.types';

const chevronStyle = (theme: Theme) => css({
  borderRadius: 5,
  marginRight: 8,
  padding: '5px 5px 5px 8px',

  '&:hover': {
    backgroundColor: theme.backgroundColors.tertiary,
  },
});

const chevronButton = (onClick?: () => void): ReactNode => (
  <FontAwesomeIcon
    css={chevronStyle}
    icon={faChevronRight}
    onClick={onClick}
  />
);

export const standardBaseMapStructure = (t: TranslationFnc, props: BaseMapStructureProps): ReadonlyArray<SettingsAccordionSubItem> => {
  const { buttons, colors, colorList, sliders, toggles } = props;
  return [
    {
      ...buttons.onThemesClick,
      label: t('Base Map Themes'),
      textSelector: () => t('Open'),
      type: SettingsAccordionSubItemType.Button,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      customButton: chevronButton(buttons.onAdvancedClick.onClick),
      label: t('Advanced Settings'),
      textSelector: () => t('Open'),
      type: SettingsAccordionSubItemType.Button,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      ...colors.water,
      color: colors.water.color,
      onChange: colors.water.onChange,
      label: t('Water Color'),
      type: SettingsAccordionSubItemType.ColorPicker,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      ...colors.poi,
      label: t('Point of Interest Color'),
      type: SettingsAccordionSubItemType.ColorPicker,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      ...toggles.enablePOIDescriptions,
      label: t('Enable POI Click Descriptions'),
      type: SettingsAccordionSubItemType.Toggle,
      infoTooltip: t('When enabled, clicking on Points of Interest (POIs) like restaurants, shops, or landmarks will display additional information provided by Google Maps. Disable this if you want to prevent users from interacting with these built-in map icons.'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      colors: colorList[MapStylesColorListSettingsName.landscape],
      label: t('Landscape Color'),
      type: SettingsAccordionSubItemType.ColorList,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      colors: colorList[MapStylesColorListSettingsName.roads],
      label: t('Roads Color'),
      type: SettingsAccordionSubItemType.ColorList,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      colors: colorList[MapStylesColorListSettingsName.labels],
      label: t('Labels Color'),
      type: SettingsAccordionSubItemType.ColorList,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      label: t('Roads'),
      type: SettingsAccordionSubItemType.Slider,
      ...sliders.roads,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      label: t('Landmarks'),
      type: SettingsAccordionSubItemType.Slider,
      ...sliders.landmarks,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      label: t('Labels'),
      type: SettingsAccordionSubItemType.Slider,
      ...sliders.labels,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      ...buttons.onResetClick,
      buttonStyle: ButtonStyle.Secondary,
      label: t('Reset Changes'),
      textSelector: () => t('Reset'),
      type: SettingsAccordionSubItemType.Button,
      infoTooltip: null,
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
    {
      ...buttons.onInitialMapClick,
      label: t('Set Initial Map Position'),
      textSelector: () => t('Open'),
      type: SettingsAccordionSubItemType.Button,
      infoTooltip: t('This feature allows you to set the initial position. To use, position the map exactly the way you want it to look when someone first opens it (zoom level, etc). Then just click this button and select the options you prefer.'),
      disabledTooltipSelector: always(EMPTY_MESSAGE),
    },
  ];
};
