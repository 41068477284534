import { isResourceLoaded } from '~/_shared/types/fetchableResource/fetchableResource.helpers';
import { newPerSpreadsheetMap } from '~/_shared/types/spreadsheet/spreadsheet.types';
import type { SpreadsheetCell } from '~/_shared/types/spreadsheetData/spreadsheetCell';
import type { ColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import type { SpreadsheetRowId } from '~/_shared/types/spreadsheetData/spreadsheetRow';
import {
  type SpreadsheetCellData, type SpreadsheetCellDataRow,
} from './spreadsheetCellData.state';

/*
 * Creates a shallow copy of a SpreadsheetCellData type object
 * Except on the highest level, it will create a new PerRowFetchableSpreadsheetRows Map for each Spreadsheet
 */
export const copySpreadsheetCellDataPerSpreadsheet = (spreadsheetCellData: SpreadsheetCellData): SpreadsheetCellData => (
  Object.entries(spreadsheetCellData).reduce<SpreadsheetCellData>((perSpreadsheetAccum, [spreadsheetId, perSpreadsheetRowsMap]) => {
    if (perSpreadsheetRowsMap) {
      perSpreadsheetAccum[+spreadsheetId] = new Map(perSpreadsheetRowsMap);
    }
    return perSpreadsheetAccum;
  }, newPerSpreadsheetMap())
);

export const getRowFromSpreadsheetCellData = (
  spreadsheetCellData: SpreadsheetCellData,
  spreadsheetRowId: SpreadsheetRowId,
): SpreadsheetCellDataRow | undefined => {
  const row = spreadsheetCellData[spreadsheetRowId.spreadsheetId]?.get(spreadsheetRowId.rowId);
  if (row && isResourceLoaded(row)) {
    return row.value;
  }
  return undefined;
};

export const getCellsFromSpreadsheetCellDataRow = (
  spreadsheetCellDataRow: SpreadsheetCellDataRow | undefined,
  columnId: ColumnId,
): SpreadsheetCell | undefined => {
  const columnData = spreadsheetCellDataRow?.columnsData[columnId];
  if (columnData && isResourceLoaded(columnData)) {
    return columnData.value;
  }
  return undefined;
};
