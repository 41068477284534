import {
  useCallback, useState,
} from 'react';
import { useTranslation } from '~/_shared/utils/hooks';
import { removeLock } from './unblockAccount.repository';

export const useUnblockAccount = () => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const unblockAccount = useCallback((props: {
    expires: string;
    hash: string;
    signature: string;
    userEmail: string;
  }) => {
    setIsLoading(true);
    return removeLock(props.expires, props.hash, props.userEmail, props.signature)
      .catch((error) => {
        let errorMessage = t('UnblockAccount.linkInvalid');
        if (error.responseStatus === 403) {
          errorMessage = t('This functionality is not available for current user.');
        }
        setErrorMessage(errorMessage);
        return Promise.reject(errorMessage);
      }).finally(() => {
        setIsLoading(false);
      });
  }, [t]);

  return {
    isLoading,
    errorMessage,
    unblockAccount,
  };
};
