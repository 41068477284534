import {
  type FC, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { type SpreadsheetColumnId } from '../../../../_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '../../../../_shared/utils/hooks';
import { AppErrorType } from '../../../../appError/appErrorType.enum';
import { spreadsheetTableSearchSetQuery } from '../../../../store/frontendState/spreadsheetTable/spreadsheetTable.actionCreators';
import { useSpreadsheetTableSearchSelector } from '../../../../store/frontendState/spreadsheetTable/spreadsheetTable.selectors';
import { type MapSettingsFilterTextValue } from '../../../../store/mapSettings/columnsFilter/mapSettingsColumnsFilter.state';
import { getMatchupDataColumnName } from '../../../../store/matchupData/matchupData.helpers';
import { useMatchupDataSelector } from '../../../../store/matchupData/matchupData.selectors';
import { createAppError } from '../../../../store/modal/modal.actionCreators';
import { DataType } from '../../../../store/spreadsheetData/spreadsheetData.state';
import { useSpreadsheetColumnsOptions } from '../boundary/hooks/useSpreadsheetColumnsOptions';
import {
  createFilterTextValuesFromTextFilter, filterTextValueEquals,
} from '../filterTool/filters/filters.helpers';
import { createTextPredicateFromFilterAction } from '../filterTool/filters/textFilter/textFilter.factory';
import { type TextFilterPredicate } from '../filterTool/filters/textFilter/textFilterPredicate.enum';
import {
  type FilterDataQueryItem, FilterDataToolComponent,
} from './filterDataTool.component';

export const FilterDataToolContainer: FC = () => {
  const spreadsheetColumnOptions = useSpreadsheetColumnsOptions({ withServerColumns: true });
  const matchupData = useMatchupDataSelector();
  const tableSearch = useSpreadsheetTableSearchSelector();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const queries = useMemo<FilterDataQueryItem[]>(() => {
    const results: FilterDataQueryItem[] = [];

    Object.keys(tableSearch).forEach(spreadsheetIdAttr => {
      const spreadsheetId: number = parseInt(spreadsheetIdAttr, 10);
      const spreadsheetItem = tableSearch[spreadsheetId];
      if (spreadsheetItem) {
        Object.keys(spreadsheetItem).forEach(columnId => {
          const spreadsheetColumnId = { spreadsheetId, columnId };
          const columnName = getMatchupDataColumnName(matchupData, spreadsheetColumnId);
          if (columnName === null) {
            return;
          }

          const columnTextValues = spreadsheetItem[columnId]?.[DataType.TEXT];
          if (columnTextValues) {
            for (const item of columnTextValues) {
              results.push({
                columnName,
                filterPredicate: createTextPredicateFromFilterAction(item.action),
                textArgument: item.value ?? '',
                onRemoveItem: () => {
                  const newTextItems = columnTextValues.filter(columnValueItem => columnValueItem !== item);
                  dispatch(spreadsheetTableSearchSetQuery(spreadsheetColumnId, newTextItems));
                },
              });
            }
          }
        });
      }
    });

    return results;
  }, [tableSearch, matchupData, dispatch]);

  const onAddItem = useCallback((spreadsheetColumnId: SpreadsheetColumnId, action: TextFilterPredicate, value: string) => {
    const currentValues: ReadonlyArray<MapSettingsFilterTextValue> = tableSearch[spreadsheetColumnId.spreadsheetId]?.[spreadsheetColumnId.columnId]?.[DataType.TEXT] ?? [];
    const addedItem: MapSettingsFilterTextValue = createFilterTextValuesFromTextFilter({
      filterPredicate: action,
      textArgument: value,
    });

    for (const currentValue of currentValues) {
      if (filterTextValueEquals(currentValue, addedItem)) {
        dispatch(
          createAppError({
            type: AppErrorType.General,
            title: t('Duplicate Filter'),
            errorTitle: t('Filter clause with the same value already exists'),
          }),
        );

        return;
      }
    }

    const newTextFilterValues: ReadonlyArray<MapSettingsFilterTextValue> = [...currentValues, addedItem];
    dispatch(
      spreadsheetTableSearchSetQuery(spreadsheetColumnId, newTextFilterValues),
    );
  }, [tableSearch, dispatch, t]);

  return (
    <FilterDataToolComponent
      onAddItem={onAddItem}
      queries={queries}
      spreadsheetColumnOptions={spreadsheetColumnOptions}
    />
  );
};
