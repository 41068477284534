import {
  apiGet, apiPost,
} from '~/_shared/utils/api/api.helpers';
import { type ClientStatus } from '../../_shared/types/client/client';
import { type ClientLicenseType } from '../../_shared/types/client/license';

export type InviteeInvitation = {
  issuedAt: string;
  expireAt: string;
  invitee: {
    firstName: string;
    lastName: string;
  };
  client: {
    name: string;
    license: string;
    status: ClientStatus;
  };
};

export type GetInviteeInvitationDataResponse = {
  data: {
    issued_at: string;
    expire_at: string;
    client: {
      name: string;
      license: ClientLicenseType;
      status: ClientStatus;
    };
    invitee: {
      first_name: string;
      last_name: string;
    };
  };
};

export const inviteeInvitationResponseToInviteeInvitation =
  (response: GetInviteeInvitationDataResponse): InviteeInvitation => (
    {
      issuedAt: response.data.issued_at,
      expireAt: response.data.issued_at,
      invitee: {
        firstName: response.data.invitee.first_name,
        lastName: response.data.invitee.last_name,
      },
      client: {
        name: response.data.client.name,
        license: response.data.client.license,
        status: response.data.client.status,
      },
    }
  );

export type AcceptInvitationResponse = {
  message: string;
};

export type RejectInvitationResponse = {
  message: string;
};

export const invitationEndpointUrl = '/api/invitations';

export const getInvitationDataRequest = (
  email: string,
  hash: string,
  signature: string,
  expires: string
): Promise<GetInviteeInvitationDataResponse> => {
  const requestUrl = `${invitationEndpointUrl}?email=${email}&expires=${expires}&hash=${hash}&signature=${signature}`;
  return apiGet(requestUrl);
};

export const acceptInvitationRequest = (
  email: string,
  hash: string,
  signature: string,
  expires: string,
  password?: string,
  passwordConfirmation?: string
): Promise<AcceptInvitationResponse> => {
  const requestUrl = `${invitationEndpointUrl}/accept?email=${email}&expires=${expires}&hash=${hash}&signature=${signature}`;
  return apiPost(requestUrl, {
    password,
    password_confirmation: passwordConfirmation,
  });
};

export const rejectInvitationRequest = (
  email: string,
  hash: string,
  signature: string,
  expires: string
): Promise<RejectInvitationResponse> => {
  const requestUrl = `${invitationEndpointUrl}/reject?email=${email}&expires=${expires}&hash=${hash}&signature=${signature}`;
  return apiPost(requestUrl);
};
