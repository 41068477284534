import { css } from '@emotion/react';

export const dropdownHeadingStyle = css({
  margin: '0 0 10px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
});

export const BOUNDARY_SETTINGS_MIN_RANGES_COUNT = 2;
