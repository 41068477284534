import { type FC } from 'react';
import { type ModalProps } from '../../../modal/modalType.enum';
import { useSelector } from '../../utils/hooks/useSelector';
import {
  type SavePayload, SearchSettingsModalComponent,
} from './searchSettingsModal.component';

export type SearchSettingsModalContainerProps = ModalProps<{
  onSave: (params: SavePayload) => void;
}>;

export const SearchSettingsModalContainer: FC<SearchSettingsModalContainerProps> = (props) => {
  const selectedMatchingBehaviourState = useSelector(state => state.map.mapSettings.data.search.selectedMatchingBehaviour);
  const shouldZoomOnMatchesState = useSelector(state => state.map.mapSettings.data.search.shouldZoomOnMatches);
  const shouldMatchExactWordsState = useSelector(state => state.map.mapSettings.data.search.shouldMatchExactWords);

  return (
    <SearchSettingsModalComponent
      {...props}
      selectedMatchingBehaviourState={selectedMatchingBehaviourState}
      shouldZoomOnMatchesState={shouldZoomOnMatchesState}
      shouldMatchExactWordsState={shouldMatchExactWordsState}
    />
  );
};
