import {
  useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '~/_shared/utils/hooks';
import { useSelector } from '~/_shared/utils/hooks/useSelector';
import { useGetStackedInfoForSpreadsheetRow } from '~/_shared/utils/markers/useGetStackedInfoForSpreadsheetRow.hook';
import { AppErrorType } from '~/appError/appErrorType.enum';
import { useActiveMarkerIdSelector } from '~/store/frontendState/activeMapElements/activeMapElements.selectors';
import { myLocationSelector } from '~/store/frontendState/mapTools/myLocation/myLocation.selectors';
import { useLocationFinderSelector } from '~/store/mapSettings/toolsState/locationFinder/locationFinder.selectors';
import { createAppError } from '~/store/modal/modal.actionCreators';
import { useLocationListHoveredMarkerIdSelector } from '../../../store/frontendState/mapTools/locationFinder/locationFinder.selectors';
import {
  useMap, useWebglLayer,
} from '../mapContext';
import { ActiveMarkerIndicatorOverlay } from '../mapOverlays/activeMarkerIndicator/activeMarkerIndicator.container.overlay';
import { HoveredMarkerIndicator } from '../mapOverlays/hoveredMarkerIndicator/hoveredMarkerIndicator';
import { RadarMarkerOverlay } from '../mapOverlays/radarMarker/radarMarker.component.overlay';
import { MapToolsManager } from '../mapToolsManager';
import {
  StreetViewManager, StreetViewUpdateStatus,
} from '../streetViewManager';
import {
  useFilteredLatLngSpreadsheetData, useLatLngSpreadsheetData,
} from '../useSpreadsheetData.hook';
import { useAddLocationByMapClick } from './useAddLocationByMapClick.hook';

export const MapToolsOverlay: React.FC = () => {

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const map = useMap();
  const mapTools = useSelector(state => state.frontendState.mapTools);
  const locationFinder = useLocationFinderSelector();
  const highPriorityMarkersLayer = useWebglLayer('HighPriorityMarkers');
  const mapToolsManager = useMemo(() => new MapToolsManager(highPriorityMarkersLayer), [highPriorityMarkersLayer]);
  const streetViewManager = useMemo(() => new StreetViewManager(map), [map]);
  const activeMarkerId = useActiveMarkerIdSelector();
  const hoveredMarkerId = useLocationListHoveredMarkerIdSelector();
  const rowsData = useLatLngSpreadsheetData();
  const myLocationPosition = useSelector(myLocationSelector);
  const filteredMarkersData = useFilteredLatLngSpreadsheetData();

  const isActiveMarkerVisible = filteredMarkersData.visible.getRow({
    rowId: activeMarkerId?.rowId ?? '',
    spreadsheetId: activeMarkerId?.spreadsheetId ?? 0,
  }) !== undefined;

  useAddLocationByMapClick({ map });

  const activeMarkerLatLng = useMemo(() => activeMarkerId && rowsData.getRow(activeMarkerId), [activeMarkerId, rowsData]);
  const hoveredMarkerLatLng = useMemo(() => hoveredMarkerId && rowsData.getRow(hoveredMarkerId), [hoveredMarkerId, rowsData]);

  const stackedMarkerInfo = useGetStackedInfoForSpreadsheetRow(activeMarkerLatLng);

  useEffect(() => {
    if (!mapTools.streetView) {
      return;
    }

    const status = streetViewManager.updateStreetView(mapTools.streetView);

    if (status === StreetViewUpdateStatus.ZERO_RESULTS) {
      dispatch(createAppError({ type: AppErrorType.General, title: t('Street view error'), errorTitle: t('Street view search returned no images.') }));
    }

    if (status === StreetViewUpdateStatus.UNKNOWN_ERROR) {
      dispatch(createAppError({ type: AppErrorType.General, title: t('Street view error'), errorTitle: t('An unknown error has occurred.') }));
    }
  }, [map, streetViewManager, mapTools.streetView, dispatch, t]);

  useEffect(() => {
    if (!locationFinder) {
      return;
    }

    mapToolsManager.updateLocation(locationFinder.latLng);
  }, [locationFinder, mapToolsManager]);

  useEffect(() => () => mapToolsManager.destroy(), [mapToolsManager]);

  return (
    <>
      {!!myLocationPosition && (
        <RadarMarkerOverlay
          position={myLocationPosition}
          map={map}
        />
      )}
      {hoveredMarkerId && hoveredMarkerLatLng && (
        <HoveredMarkerIndicator
          map={map}
          position={hoveredMarkerLatLng}
        />
      )}
      {isActiveMarkerVisible && activeMarkerId && stackedMarkerInfo.latLng && (
        <ActiveMarkerIndicatorOverlay
          map={map}
          position={stackedMarkerInfo.latLng}
          activeMarkerId={activeMarkerId}
          stackInfo={stackedMarkerInfo.data}
        />
      )}
    </>
  );
};
