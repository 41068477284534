import { createColor } from '~/_shared/components/colorPicker/colorPicker.helpers';
import { UnitSystem } from '~/_shared/types/googleMaps/googleMaps.types';
import {
  GroupRadiusEditLevel,
  ProximityType,
} from '~/_shared/types/proximity/proximity.enums';
import { type PickAction } from '~/_shared/utils/types/action.type';
import { type MapSettingsDataAction } from '../../mapSettings/data/mapSettingsData.action';
import { MAP_SETTINGS_FETCH_DATA_SUCCESS } from '../../mapSettings/data/mapSettingsData.actionTypes';
import { type MapSettingsProximityAction } from '../../mapSettings/proximity/mapSettingsProximity.action';
import { MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS } from '../../mapSettings/proximity/mapSettingsProximity.actionTypes';
import { type ProximityToolAction } from './proximityTool.action';
import {
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED,
  PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED,
  PROXIMITY_TOOL_SET_APPLY_TO,
  PROXIMITY_TOOL_SET_BORDER_COLOR,
  PROXIMITY_TOOL_SET_BORDER_LINE_WIDTH,
  PROXIMITY_TOOL_SET_PROXIMITY_COLOR,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED,
  PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED,
  PROXIMITY_TOOL_SET_PROXIMITY_WITHIN,
  PROXIMITY_TOOL_SET_SELECTED_GROUP,
  PROXIMITY_TOOL_SET_SELECTED_GROUP_COLUMN,
  PROXIMITY_TOOL_SET_STARTING_LOCATION,
  PROXIMITY_TOOL_SET_TRAVEL_TIME_HOURS,
  PROXIMITY_TOOL_SET_TRAVEL_TIME_MINUTES,
  PROXIMITY_TOOL_SET_TYPE,
  PROXIMITY_TOOL_SET_UNIT,
} from './proximityTool.actionTypes';
import { type ProximityToolState } from './proximityTool.state';

const initialProximityToolState: ProximityToolState = {
  applyTo: GroupRadiusEditLevel.IndividualLocation,
  type: ProximityType.DistanceRadius,
  unit: UnitSystem.imperial,
  travelTimeHours: null,
  travelTimeMinutes: null,
  proximityWithin: null,
  selectedGroup: null,
  selectedGroupColumn: null,
  proximityColor: createColor('#05BCFF'),
  borderColor: createColor('#000000'),
  borderLineWidth: 1,
  startingLocation: {
    latLng: { lat: 0, lng: 0 },
    address: '',
  },
  proximityIdInDnDMode: null,
  hoveredProximityId: null,
};

export const proximityToolReducer = (state = initialProximityToolState,
  action: ProximityToolAction | MapSettingsProximityAction | PickAction<MapSettingsDataAction, typeof MAP_SETTINGS_FETCH_DATA_SUCCESS>
): ProximityToolState => {
  switch (action.type) {
    case PROXIMITY_TOOL_SET_APPLY_TO: {
      return {
        ...state,
        applyTo: action.payload.applyTo,
      };
    }

    case PROXIMITY_TOOL_SET_TYPE: {
      return {
        ...state,
        type: action.payload.type,
      };
    }

    case MAP_SETTINGS_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        unit: action.payload.mapSettingsData.settings?.unitSystem || UnitSystem.imperial,
      };
    }

    case PROXIMITY_TOOL_SET_UNIT: {
      return {
        ...state,
        unit: action.payload.unit,
      };
    }

    case PROXIMITY_TOOL_SET_TRAVEL_TIME_HOURS: {
      return {
        ...state,
        travelTimeHours: action.payload.travelTimeHours,
      };
    }

    case PROXIMITY_TOOL_SET_TRAVEL_TIME_MINUTES: {
      return {
        ...state,
        travelTimeMinutes: action.payload.travelTimeMinutes,
      };
    }

    case PROXIMITY_TOOL_SET_PROXIMITY_WITHIN: {
      return {
        ...state,
        proximityWithin: action.payload.proximityWithin,
      };
    }

    case PROXIMITY_TOOL_SET_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: action.payload.selectedGroup,
      };
    }

    case PROXIMITY_TOOL_SET_SELECTED_GROUP_COLUMN: {
      return {
        ...state,
        selectedGroupColumn: action.payload.selectedGroupColumn,
      };
    }

    case PROXIMITY_TOOL_SET_PROXIMITY_COLOR: {
      return {
        ...state,
        proximityColor: action.payload.proximityColor,
      };
    }

    case PROXIMITY_TOOL_SET_BORDER_COLOR: {
      return {
        ...state,
        borderColor: action.payload.borderColor,
      };
    }

    case PROXIMITY_TOOL_SET_BORDER_LINE_WIDTH: {
      return {
        ...state,
        borderLineWidth: action.payload.borderLineWidth,
      };
    }

    case PROXIMITY_TOOL_SET_STARTING_LOCATION: {
      return {
        ...state,
        startingLocation: action.payload.startingLocation,
      };
    }

    case MAP_SETTINGS_PROXIMITY_MOVE_INDIVIDUAL_RADIUS: {
      return {
        ...state,
        proximityIdInDnDMode: state.proximityIdInDnDMode === action.payload.proximityId ? null : state.proximityIdInDnDMode,
      };
    }

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_STARTED: {
      return {
        ...state,
        proximityIdInDnDMode: action.payload.proximityId,
      };
    }

    case PROXIMITY_TOOL_INDIVIDUAL_RADIUS_DND_MODE_ENDED: {
      return {
        ...state,
        proximityIdInDnDMode: null,
      };
    }

    case PROXIMITY_TOOL_SET_PROXIMITY_HOVER_STARTED: {
      return {
        ...state,
        hoveredProximityId: action.payload.proximityId,
      };
    }

    case PROXIMITY_TOOL_SET_PROXIMITY_HOVER_ENDED: {
      if (action.payload.proximityId === state.hoveredProximityId) {
        return {
          ...state,
          hoveredProximityId: null,
        };
      }
      return state;
    }

    default:
      return state;
  }
};
