import { css } from '@emotion/react';
import {
  type FC,
  useEffect, useMemo,
} from 'react';
import {
  DropDownItemSize, type DropdownOption, RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { InputSize } from '~/_shared/baseComponents/inputs';
import { ModalType } from '../../../modal/modalType.enum';
import { useModal } from '../../../modal/useModal.hook';
import { generateGroupIdByName } from '../../../store/spreadsheetData/grouping/spreadsheetData.grouping.helpers';
import { type GroupData } from '../../../store/spreadsheetData/spreadsheetData.state';
import { useTranslation } from '../../utils/hooks';
import { OverlayLoaderComponent } from '../overlay/overlayLoader.component';
import { OverlayWrapperComponent } from '../overlay/overlayWrapper.component';
import { useUniqueGroups } from './useUniqueGroups.hook';

type SelectGroupProps = Readonly<{
  spreadsheetId: number | null;
  selectedGroupColumn: string | null;
  onSelectedGroupChange: (group: GroupData | null) => void;
  selectedGroup: GroupData | null;
  label: string;
  placeholder?: string;
}>;

const labelStyle = css({
  margin: '10px 0 5px',
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.2,
});

const overlayLoaderWrapperStyle = css({
  minHeight: 40,
});

export const SelectGroupComponent: FC<SelectGroupProps> = (props) => {
  const [t] = useTranslation();
  const { openModal } = useModal(ModalType.AdHoc);
  const { groups, isLoaded, isLoading, hasError } = useUniqueGroups(props.spreadsheetId, props.selectedGroupColumn);

  const hasMoreThenLimit = groups.length > 500;

  useEffect(() => {
    if (hasMoreThenLimit && isLoaded) {
      openModal({
        caption: t('Notice Group Limit Reached'),
        content: t('This column has over 500 unique values. Please select a different group column and try again.'),
      });
    }
  }, [hasMoreThenLimit, isLoaded, openModal, t]);

  useEffect(() => {
    if (hasError) {
      openModal({
        caption: t('Error getting group column data'),
        content: t('An error occurred while getting the group column data. Please try again later or select a different group column.'),
      });
    }
  }, [hasError, openModal, t]);

  const groupOptions: ReadonlyArray<DropdownOption<string>> = useMemo(() => hasMoreThenLimit
    ? [] // do not render any options if there are too many, it causes huge performance problems
    : groups.map((group) => ({ value: group.id, name: group.label })),
  [groups, hasMoreThenLimit]);

  const { onSelectedGroupChange } = props;
  useEffect(() => {
    if (!isLoaded) {
      onSelectedGroupChange(null);
    }
  }, [isLoaded, onSelectedGroupChange]);

  return (
    <>
      <p css={labelStyle}>{props.label}</p>
      <OverlayWrapperComponent css={overlayLoaderWrapperStyle}>
        <RegularDropdownComponent
          inputSize={InputSize.Medium}
          itemSize={DropDownItemSize.Large}
          onChange={(value) => {
            const group = groupOptions.find(item => item.value === value);
            props.onSelectedGroupChange(group ? {
              id: group.value,
              label: group.name,
            } : null);
          }}
          value={props.selectedGroup ? generateGroupIdByName(props.selectedGroup.id) : undefined}
          options={groupOptions}
          isDisabled={!isLoaded || hasMoreThenLimit}
          placeholder={props.placeholder || props.label}
          inPortal
        />
        {isLoading && (
          <OverlayLoaderComponent loaderSize={24} />
        )}
      </OverlayWrapperComponent>
    </>
  );
};
