import { css } from '@emotion/react';
import {
  type FC, useMemo, useState,
} from 'react';
import { AutocompleteComponent } from '~/_shared/baseComponents/autocomplete';
import {
  type DropdownOption,
  RegularDropdownComponent,
} from '~/_shared/baseComponents/dropdown';
import { TooltipDeprComponent } from '~/_shared/baseComponents/tooltipDepr/tooltipDepr.component';
import { type SpreadsheetColumnId } from '~/_shared/types/spreadsheetData/spreadsheetColumn';
import { useTranslation } from '~/_shared/utils/hooks';
import { type BoundaryGroup } from '~/boundary/boundary.types';
import { type BoundaryGroupId } from '~/store/boundaries/boundaryIdentifier.type';
import {
  BoundaryTerritoryType, type CalculateBucketFunctionNumeric,
} from '~/store/boundaryTerritoryGroups/boundaryTerritoryGroup.type';
import { ActiveBoundaryFillEditComponent } from '../../activeBoundary/edit/activeBoundaryFillEditComponent';
import { useActiveBoundaryFillEdit } from '../../activeBoundary/edit/useActiveBoundaryFillEdit';
import { BoundarySelectedOption } from '../../fill/boundaryFill.enums';
import { LoadBoundaryPaneSubmitButtonComponent } from '../loadBoundaryPaneSubmitButton/boundaryCreateSubmitButton.component';
import {
  getBoundaryFillTypeOptions,
  LoadCustomBoundaryFillType,
} from './loadCustomBoundaryGroupPane.helpers';

const panelDropdownSectionStyle = css({
  marginBottom: 8,
});

const labelStyle = css({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  textTransform: 'uppercase',
});

const addBoundaryTooltipStyle = css({
  display: 'block',
});

type LoadCustomBoundaryGroupDataWithBoundaryFillType = Readonly<{
  boundaryGroupId: number;
  boundaryFillType: LoadCustomBoundaryFillType.DefaultFill | LoadCustomBoundaryFillType.NoTerritoryFill | LoadCustomBoundaryFillType.RandomizedFill;
}>;

type LoadCustomBoundaryGroupDataBasedOnFillData = Readonly<{
  boundaryGroupId: number;
  boundaryFillType: LoadCustomBoundaryFillType.FillBasedOnData;
  boundaryTerritoryType: BoundaryTerritoryType;
  boundaryFillData: SpreadsheetColumnId | null;
  numericCalculateBucketFunction: CalculateBucketFunctionNumeric | null;
}>;

export type LoadCustomBoundaryGroupData = LoadCustomBoundaryGroupDataWithBoundaryFillType | LoadCustomBoundaryGroupDataBasedOnFillData;

type LoadCustomBoundaryGroupPaneComponentProps = Readonly<{
  customBoundaryGroups: ReadonlyArray<BoundaryGroup>;
  onAddBoundary: (results: LoadCustomBoundaryGroupData) => void;
  isBoundaryGroupUsedOnMap: (boundaryGroupId: BoundaryGroupId) => boolean;
}>;

const excludedBoundaryTerritoryTypes = [BoundaryTerritoryType.Manual];

export const LoadCustomBoundaryGroupPaneComponent: FC<LoadCustomBoundaryGroupPaneComponentProps> = props => {
  const [boundaryGroupId, setBoundaryGroupId] = useState<number | null>(null);
  const [boundaryFillType, setBoundaryFillType] = useState<LoadCustomBoundaryFillType>(LoadCustomBoundaryFillType.DefaultFill);
  const [t] = useTranslation();

  const {
    isUpdateDisabled: isfillByDataUpdateDisabled, boundaryFillModel, ...boundaryFillProps
  } = useActiveBoundaryFillEdit({
    boundaryGroupId,
    hiddenBoundaryTerritoryTypeOptions: excludedBoundaryTerritoryTypes,
  });

  const { isBoundaryGroupUsedOnMap } = props;

  const isBoundaryGroupAlreadyUsed = useMemo(() =>
    boundaryGroupId === null ? false : isBoundaryGroupUsedOnMap(boundaryGroupId),
  [isBoundaryGroupUsedOnMap, boundaryGroupId]);

  const customBoundaryGroupsListOptions: DropdownOption<number>[] = useMemo(() =>
    props.customBoundaryGroups.filter((group) => !group.wms.wmsTemporary).map(item => ({
      name: item.name,
      value: item.id,
    })), [props.customBoundaryGroups]);

  const hasValidOptions = customBoundaryGroupsListOptions.length > 0;

  const boundaryFillTypeOptions = useMemo(() => getBoundaryFillTypeOptions(t), [t]);

  const isAddCustomBoundaryGroupDisabled = isBoundaryGroupAlreadyUsed || !boundaryGroupId
    || (boundaryFillType === LoadCustomBoundaryFillType.FillBasedOnData && isfillByDataUpdateDisabled);

  const addCustomBoundaryGroupsDisabledReason = isBoundaryGroupAlreadyUsed
    ? t('Territory Group is already loaded')
    : boundaryFillProps.updateDisabledReason;

  const onSubmit = () => {
    if (boundaryGroupId === null) {
      return;
    }

    if (boundaryFillType === LoadCustomBoundaryFillType.FillBasedOnData) {
      if (boundaryFillModel.boundaryTerritoryType === null) {
        return;
      }
      props.onAddBoundary({
        boundaryGroupId,
        boundaryFillType: LoadCustomBoundaryFillType.FillBasedOnData,
        boundaryTerritoryType: boundaryFillModel.boundaryTerritoryType,
        boundaryFillData: boundaryFillModel.boundaryFillData,
        numericCalculateBucketFunction: boundaryFillModel.numericCalculateBucketFunction,
      });
    }
    else {
      props.onAddBoundary({
        boundaryGroupId,
        boundaryFillType,
      });
    }
  };

  let labelCount = 2;

  return (
    <div>
      <div css={panelDropdownSectionStyle}>
        <div css={labelStyle}>
          {labelCount++}. {t(BoundarySelectedOption.SelectCustomBoundaryGroup)}
        </div>

        <AutocompleteComponent
          onChange={setBoundaryGroupId}
          options={customBoundaryGroupsListOptions}
          placeholder={hasValidOptions ? t('Select...') : t('No territory groups found')}
          value={boundaryGroupId}
          isDisabled={!hasValidOptions}
          inPortal
          isClearable
        />
      </div>

      {boundaryGroupId !== null && (
        <>
          <div css={panelDropdownSectionStyle}>
            <div css={labelStyle}>
              {labelCount++}. {t(BoundarySelectedOption.BoundaryFillType)}
            </div>

            <RegularDropdownComponent
              onChange={setBoundaryFillType}
              options={boundaryFillTypeOptions}
              placeholder={t(BoundarySelectedOption.BoundaryFillType)}
              value={boundaryFillType}
              inPortal
            />
          </div>

          {boundaryFillType === LoadCustomBoundaryFillType.FillBasedOnData && (
            <ActiveBoundaryFillEditComponent
              labelCountStart={labelCount++}
              {...boundaryFillModel}
              {...boundaryFillProps}
            />
          )}
        </>
      )}

      <TooltipDeprComponent
        css={addBoundaryTooltipStyle}
        tooltipContent={addCustomBoundaryGroupsDisabledReason}
        placement="bottom"
      >
        <LoadBoundaryPaneSubmitButtonComponent
          text={t('Load Territories')}
          isDisabled={isAddCustomBoundaryGroupDisabled}
          onClick={isAddCustomBoundaryGroupDisabled ? undefined : onSubmit}
        />
      </TooltipDeprComponent>
    </div>
  );
};
