import { useState } from 'react';
import { type GoogleMapInstance } from '~/_shared/components/google-map/useGetGoogleMapInstance.hook';
import { getOrSet } from '~/_shared/utils/collections/collections';
import { isMobileDevice } from '~/_shared/utils/deviceDetect/deviceDetect.helpers';
import { useScript } from '~/_shared/utils/hooks/useScript';
import { testingModeEnabled } from '~/testingMode/testingMode';
import { mapFont } from '../mapLabelFonts';
import {
  type MarkerTemplateManager, registerTemplateManager,
} from '../markers/manager/markerTemplateManager';
import {
  addLayersToOverlay, createLayers, loadFontOnLabelLayers, setLayerZIndexesInPropertyOrder, type WebglLayers,
} from './useWebGL';

const webGlPath = `/js/web-gl-layer/${BUILD_VERSION}`;

export const webglPaths = {
  root: webGlPath,
  bundle: `${webGlPath}/webgl-overlay.bundle.js`,
  workers: `${webGlPath}/workers`,
  license: `${webGlPath}/webgl-overlay.bundle.LICENSE.txt`,
};

type WebGLOverlayInstance = {
  isReady: boolean;
  instance: WebGLOverlay;
  layers: WebglLayers | undefined;
  markerTemplateManager: MarkerTemplateManager;
};

const webGLOverlayInstances = new Map<GoogleMapInstance, WebGLOverlayInstance>();

const createWebGLOverlay = (map: GoogleMapInstance, onOverlayReady: () => void) => {
  const webGLOverlay = new WebGLOverlay({
    allowPanelUi: false,
    workersPath: webglPaths.workers,
    callback: () => {
      onOverlayReady();
    },
    gMap: map.instance,
    gui: false,
    domElement: map.container,
    rendering: {
      idleAAMethod: 'FXAA',
      notIdleAAMethod: 'FXAA',
      resolution: 2,
    },
    verbosity: testingModeEnabled() ? 2 : 0,
  });

  webGLOverlay.setWatchingResizeParams(true, isMobileDevice() ? 40 : 24);
  const layers = createLayers();
  setLayerZIndexesInPropertyOrder(layers);
  addLayersToOverlay(layers, webGLOverlay);
  loadFontOnLabelLayers(webGLOverlay, mapFont.arial);
  loadFontOnLabelLayers(webGLOverlay, mapFont.arialBold);
  loadFontOnLabelLayers(webGLOverlay, mapFont.azeretMonoNumbers);
  const markerTemplateManager = registerTemplateManager(layers);

  return {
    instance: webGLOverlay,
    isReady: false,
    layers,
    markerTemplateManager,
  };
};

export const useGetWebGLOverlayInstance = (map?: GoogleMapInstance) => {
  const webGlScriptsLoaded = useScript(webglPaths.bundle, !!map);
  const [_, setIsReady] = useState<boolean>(false);

  if (!webGlScriptsLoaded || !map) {
    return undefined;
  }

  return getOrSet(webGLOverlayInstances, map, () => createWebGLOverlay(map, () => {
    const instance = webGLOverlayInstances.get(map);

    if (instance) {
      instance.isReady = true;
      setIsReady(true);
    }
  }));
};
