import {
  type SpreadsheetColumn, type SpreadsheetColumnServerModel,
} from './spreadsheetColumn';

export const createSpreadsheetColumnFromServerModel = (
  response: SpreadsheetColumnServerModel
): SpreadsheetColumn => ({
  spreadsheetId: response.spreadsheet_id,
  id: response.id,
  name: response.name,
});

export const createServerModelFromSpreadsheetColumn = (
  spreadsheetColumn: SpreadsheetColumn
): SpreadsheetColumnServerModel => ({
  spreadsheet_id: spreadsheetColumn.spreadsheetId,
  id: spreadsheetColumn.id,
  name: spreadsheetColumn.name,
});
