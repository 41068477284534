import {
  useCallback,
  useEffect, useMemo,
} from 'react';
import { useAjaxCall } from '../../_shared/utils/hooks/useAjaxCall';
import {
  convertMaptiveGoogleSheetColumnsAndMatchupDataResponse,
  getMaptiveGoogleSpreadsheetMatches,
} from './googleSheet.repository';

export const useGetMaptiveGoogleSpreadsheetMatches = (clientId: number | null, spreadsheetId: number | null) => {
  const ajaxCall = useCallback(() => {
    if (!clientId || !spreadsheetId) {
      return Promise.reject();
    }
    return getMaptiveGoogleSpreadsheetMatches(clientId, spreadsheetId);
  }, [clientId, spreadsheetId]);
  const { isLoading, error, invokeAjax, data } = useAjaxCall(ajaxCall);

  useEffect(() => {
    invokeAjax();
  }, [invokeAjax, clientId, spreadsheetId]);

  const convertedData = useMemo(() =>
    data ? convertMaptiveGoogleSheetColumnsAndMatchupDataResponse(data.data) : null, [data]);

  return useMemo(() => ({
    isLoading,
    error,
    data: convertedData,
  }), [convertedData, error, isLoading]);
};
