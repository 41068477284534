import { notEmpty } from '~/_shared/utils/array/array.helpers';
import { notNullsy } from '~/_shared/utils/typeGuards';
import type {
  V4MapListSuccessResponse, V4MapMigrateSuccessResponse,
} from '~/store/mapMigration/types/mapMigrationResponse.types';
import type { V4MapInfo } from '~/store/mapMigration/types/v4MapInfo.types';
import type {
  V4MapMigrationErrorMessage,
  V4MapMigrationProgressMessage,
} from '~/store/mapMigration/types/v4MapMigrationMessage.type';
import type { V4MapMigrationStatusInfo } from '~/store/mapMigration/types/v4MapMigrationProgressInfo.type';

const isMapMigrationInProgress = (maps: ReadonlyArray<Pick<V4MapInfo, 'migrationStatus'>>) => {
  return maps.some(map => map.migrationStatus === 'IN_PROGRESS');
};

export const updateMigrationActivity = (currentMigrationInfo: V4MapMigrationStatusInfo, maps: ReadonlyArray<Pick<V4MapInfo, 'migrationStatus'>>, receivedMigrationStatusInfo?: V4MapMigrationStatusInfo | null): V4MapMigrationStatusInfo => {
  const isActive = isMapMigrationInProgress(maps);

  return receivedMigrationStatusInfo ?? {
    ...currentMigrationInfo,
    status: isActive ? 'IN_PROGRESS' : currentMigrationInfo.status,
  };
};

export const setStatus = (migrationStatus: V4MapMigrationStatusInfo, status: V4MapMigrationStatusInfo['status']) => {
  return {
    ...migrationStatus,
    status,
  };
};

export const updateMigrationInfoFromProgressMessage = (migrationStatus: V4MapMigrationStatusInfo, message: V4MapMigrationProgressMessage): V4MapMigrationStatusInfo => {
  return {
    ...migrationStatus,
    totalMaps: message.requested,
    processedMaps: message.processed,
    percentage: message.percent,
  };
};

export const updateMigrationInfoFromErrorMessage = (migrationStatus: V4MapMigrationStatusInfo, message?: V4MapMigrationErrorMessage): V4MapMigrationStatusInfo => {
  const queueId = message?.queue_id;
  const queueErrorMapIds = queueId ? migrationStatus.errorCoordinates[queueId] : undefined;
  const messageErrorMapIds = message?.failed_map_ids;
  const mergedQueueMapIds = new Set([...queueErrorMapIds ? queueErrorMapIds : [],
    ...messageErrorMapIds ? messageErrorMapIds : []]);
  const mergedQueueMapIdArray = [...mergedQueueMapIds];
  return {
    ...migrationStatus,
    errorCoordinates: (queueId && notEmpty(mergedQueueMapIdArray)) ? {
      ...migrationStatus.errorCoordinates,
      [queueId]: mergedQueueMapIdArray,
    } : migrationStatus.errorCoordinates,
  };
};

export const getNotFoundMigratedMap = (id: number): V4MapInfo => {
  const now = new Date();
  return {
    id,
    migrationStatus: 'FAILED',
    error: 'UNKNOWN',
    createdAt: now,
    title: 'Unknown map',
    description: '',
    updatedAt: now,
    mapType: 'BASE',
    mapOwnerId: -1,
    dependencies: [],
  };
};

const getMapMigrationResults = (queue: NonNullable<V4MapListSuccessResponse['queue']>, maps: ReadonlyArray<V4MapInfo>): V4MapMigrationStatusInfo['mapMigrationResults'] => {
  const { failed_map_ids, processed_map_ids } = queue;
  const failedMapIdSet = new Set(failed_map_ids);
  const mapMap = maps.reduce((result, map) => {
    result[map.id] = map;
    return result;
  }, {} as Record<V4MapInfo['id'], V4MapInfo>);
  return [...failed_map_ids, ...processed_map_ids].map(v4mapId => {
    const map = mapMap[v4mapId];
    return {
      map: map ?? getNotFoundMigratedMap(v4mapId),
      result: failedMapIdSet.has(v4mapId) ? 'FAILED' : 'SUCCESS',
    } as const;
  }).filter(notNullsy);
};

export const getMigrationStatusInfoFromQueueInfo = (queue: NonNullable<V4MapListSuccessResponse['queue']>, maps: ReadonlyArray<V4MapInfo>): V4MapMigrationStatusInfo => {
  const errorCoordinates = notEmpty(queue.failed_map_ids) ? { [queue.queue_id]: queue.failed_map_ids } : {};
  const mapMigrationResults = getMapMigrationResults(queue, maps);

  return {
    status: queue.status,
    percentage: queue.percent,
    processedMaps: queue.processed,
    queueId: queue.queue_id,
    totalMaps: queue.requested,
    mapMigrationResults,
    errorCoordinates,
  };
};

export const updateMigrationInfoFromQueueInit = (migrationStatus: V4MapMigrationStatusInfo, queueInitResponse: V4MapMigrateSuccessResponse) => {
  return {
    ...setStatus(migrationStatus, ((queueInitResponse.code === 200) && (queueInitResponse.data.code === 'PROCESSING')) ?
      'IN_PROGRESS' :
      'FAILED'),
    queueId: queueInitResponse.data.queue,
  };
};
