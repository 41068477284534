import { combineReducers } from 'redux';
import {
  drawingItemsInitialState,
  drawingItemsReducer,
} from './items/drawingItems.reducer';
import { type MapSettingsDrawingState } from './mapSettingsDrawing.state';
import {
  drawingSettingsReducer, drawingSettingsStateInitialState,
} from './settings/drawingSettings.reducer';

export const mapSettingsDrawingInitialState: MapSettingsDrawingState = {
  items: drawingItemsInitialState,
  settings: drawingSettingsStateInitialState,
};

export const mapSettingsDrawingReducer = combineReducers({
  settings: drawingSettingsReducer,
  items: drawingItemsReducer,
});
