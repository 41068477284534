import { css } from '@emotion/react';
import { faSignsPost } from '@fortawesome/pro-solid-svg-icons';
import {
  type FC,
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  AccordionComponent, AccordionHeaderSize,
} from '~/_shared/baseComponents/accordion';
import { EmptyStateComponent } from '~/_shared/components/emptyState/emptyState.component';
import { ExportFileButtonContainer } from '~/_shared/components/exportFileButton/exportFileButton.container';
import { PrintFileButtonComponent } from '~/_shared/components/printFileButton/printFileButton.component';
import { useTheme } from '~/_shared/themes/theme.hooks';
import { type ThemeProps } from '~/_shared/types/themeProps';
import { useTranslation } from '~/_shared/utils/hooks';
import { MapToolWrapperComponent } from '~/sidebar/sidebarApps/mapToolWrapper/mapToolWrapper.component';
import { useDirectionsExpandedRouteIdsSelector } from '~/store/frontendState/mapTools/directions/directions.selectors';
import { type RouteUiData } from '~/store/frontendState/mapTools/directions/directions.state';
import { type MapSettingsRoute } from '~/store/mapSettings/directions/mapSettingsDirections.state';
import { DirectionsListingContainer } from '../listing/directionsListing.container';
import { DirectionsPanelFormContainer } from './form/directionsPanelForm.container';

const expandedSubItemStyle = ({ theme, hideBorder }: ThemeProps<{hideBorder: boolean}>) => css({
  borderBottom: `1px solid${hideBorder ? theme.accordionColors.borderColor : theme.accordionColors.headingBorderActive}`, //To prevent a double border (header bottom and first child top when active)
});

const emptyStateWrapperStyle = css({
  padding: '50px 30px 16px',
});

const bodyRootStyle = css({
  padding: 0,
});

type DirectionsPanelProps = Readonly<{
  routes: ReadonlyArray<MapSettingsRoute>;

  addRoute: (route: MapSettingsRoute, routeUiData: RouteUiData) => void;
  clearRoutes: () => void;
  exportFile?: () => void;
}>;

export const DirectionsPanelComponent: FC<DirectionsPanelProps> = (props) => {
  const [t] = useTranslation();
  const theme = useTheme();
  const expandedRouteIds = useDirectionsExpandedRouteIdsSelector();
  const listingPrintVersionRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    contentRef: listingPrintVersionRef,
  });
  const firstRouteId = props.routes[0]?.id;

  return (
    <MapToolWrapperComponent
      footer={props.routes.length === 0 || !props.exportFile ? null : (
        <>
          <PrintFileButtonComponent
            onPrintClick={handlePrint}
          />

          <ExportFileButtonContainer
            onExportFileClick={props.exportFile}
          />
        </>
      )}
    >
      <DirectionsPanelFormContainer
        routes={props.routes}
        addRoute={props.addRoute}
        clearRoutes={props.clearRoutes}
      />

      <AccordionComponent
        data={[{
          child: props.routes.length ? (
            <>
              <DirectionsListingContainer />
              <DirectionsListingContainer
                ref={listingPrintVersionRef}
                isPrintVersion
              />
            </>
          ) : (
            <EmptyStateComponent
              css={emptyStateWrapperStyle}
              icon={faSignsPost}
              title={t('directionsTool.emptyStateTitle')}
              description={t('directionsTool.emptyStateDescription')}
            />
          ),
          header: t('directionsTool.listing.header').toUpperCase(),
          isExpanded: true,
          isLocked: true,
        }]}
        expandedItemStyle={expandedSubItemStyle({ theme, hideBorder: !!(firstRouteId && expandedRouteIds.has(firstRouteId)) })}
        headerSize={AccordionHeaderSize.Large}
        panelStyle={bodyRootStyle}
      />
    </MapToolWrapperComponent>
  );
};
