import { truncateMarkerLabelText } from '../labels/labels.helpers';
import { isNullOrUndefined } from '../typeGuards';
import { webglColorChanged } from './webglColor.helpers';

type WebglOverlayLabelWithCalloutBase = {
  label: WebglOverlayLabel | null;
  added: boolean;
  disposed: boolean;
};

export type WebglOverlayLabelWithTextBoxCallout = WebglOverlayLabelWithCalloutBase & {
  callout: WebglOverlayTextBoxCallout | null;
  type: 'text';
};

export type WebglOverlayLabelWithCustomCallout = WebglOverlayLabelWithCalloutBase & {
  callout: WebglOverlayCustomCallout | null;
  type: 'custom';
};

export type WebglOverlayLabelWithCallout = WebglOverlayLabelWithTextBoxCallout | WebglOverlayLabelWithCustomCallout;

export type LabelWithCalloutConfig = {
  label?: WebglOverlayLabelConfig;
  callout?: WebglOverlayCalloutConfig;
};

export type LabelWithTextBoxCalloutConfig = LabelWithCalloutConfig & {
  callout?: WebglOverlayTextBoxCalloutConfig;
};

export type LabelWithCustomCalloutConfig = LabelWithCalloutConfig & {
  callout?: WebglOverlayCustomCalloutConfig;
};

export type LabelWithTextBoxCalloutlLayer = LabelWithCalloutVirtualLayer<WebglOverlayLabelWithTextBoxCallout>;

export const createLabelWithTextBoxCalloutLayer = (textLabelLayer: LabelsLayer, calloutLayer: TextBoxCalloutLayer): LabelWithTextBoxCalloutlLayer =>
  createLabelWithCalloutVirtualLayer('text', textLabelLayer, calloutLayer);

export type LabelWithCustomCalloutlLayer = LabelWithCalloutVirtualLayer<WebglOverlayLabelWithCustomCallout>;

export const createLabelWithCustomCalloutLayer = (textLabelLayer: LabelsLayer, calloutLayer: CustomCalloutLayer): LabelWithCustomCalloutlLayer =>
  createLabelWithCalloutVirtualLayer('custom', textLabelLayer, calloutLayer);

/////////////////
/////////////////
//#region PRIVATE

type Config<T extends WebglOverlayLabelWithCallout> = T extends WebglOverlayLabelWithTextBoxCallout
  ? LabelWithTextBoxCalloutConfig : T extends WebglOverlayLabelWithCustomCallout ? LabelWithCustomCalloutConfig : never;
type CalloutType <T extends WebglOverlayLabelWithCallout> = T extends WebglOverlayLabelWithTextBoxCallout
  ? 'text' : T extends WebglOverlayLabelWithCustomCallout ? 'custom' : never;

type CalloutLayer<T extends WebglOverlayLabelWithCallout> = {
  add: (callout: T['callout']) => void;
  remove: (callout: T['callout']) => void;
};
type Layers<T extends WebglOverlayLabelWithCallout> = readonly [LabelsLayer, CalloutLayer<T>];

type LabelWithCalloutVirtualLayer<T extends WebglOverlayLabelWithCallout> = {
  create: (config: Config<T>) => T;
  remove: (label: T) => void;
  add: (label: T) => void;
  update: (label: T, config: Config<T>) => void;
};

const createLabelWithCalloutVirtualLayer = <T extends WebglOverlayLabelWithCallout>(type: CalloutType<T>, ...layers: Layers<T>) => ({
  create: createLabelWithCallout<T>(type),
  add: createAddLabelWithCallout(...layers),
  remove: createRemoveLabelWithCallout(...layers),
  update: createUpdateLabelWithCallout(...layers),
});

const createAddLabelWithCallout = <T extends WebglOverlayLabelWithCallout>(...[texts, callouts]: Layers<T>) => (instance: T) => {
  if (instance.label) {
    texts.add(instance.label);
  }

  if (instance.callout) {
    callouts.add(instance.callout);
  }

  instance.added = true;
};

const createRemoveLabelWithCallout = <T extends WebglOverlayLabelWithCallout>(...[texts, callouts]: Layers<T>) => (instance: T) => {
  if (instance.label) {
    texts.remove(instance.label);
  }

  if (instance.callout) {
    callouts.remove(instance.callout);
  }

  instance.disposed = true;
};

const createLabelWithCallout = <T extends WebglOverlayLabelWithCallout>(type: CalloutType<T>) => (config: Config<T>): T => {
  const alteredLabelConfig = {
    ...config.label,
    text: {
      ...config.label?.text,
      value: config.label?.text?.value ? truncateMarkerLabelText(config.label.text.value) : '',
    },
  };
  const label = config.label ? new WebGLOverlay.Label(alteredLabelConfig) : null;

  const callout = type === 'text' ? {
    type: 'text',
    callout: config.callout ? new WebGLOverlay.TextBoxCallout(config.callout) : null,
  } as const : {
    type: 'custom',
    callout: config.callout ? new WebGLOverlay.CustomCallout(config.callout) : null,
  } as const;

  const result = ({
    label,
    ...callout,
    added: false,
    disposed: false,
  }) as T;

  if (result.callout) {
    result.label?.setCallout(result.callout);
  }

  return result;
};

const createUpdateLabelWithCallout = <T extends WebglOverlayLabelWithCallout>(...[texts, callouts]: Layers<T>) => (existing: T, config: Config<T>): void => {
  if (existing.disposed) {
    console.error('Label with callout was already disposed');

    return;
  }

  if (existing.callout) {
    if (config.callout) {
      updateTextBoxCallout(existing.callout, config.callout);
    }
    else {
      callouts.remove(existing.callout);
      existing.callout = null;
    }
  }
  else if (config.callout) {
    existing.callout = new WebGLOverlay.TextBoxCallout(config.callout);

    if (existing.label) {
      existing.label.setCallout(existing.callout);
    }

    if (existing.added) {
      callouts.add(existing.callout);
    }
  }

  if (existing.label) {
    if (config.label) {
      updateLabel(existing.label, config.label);
    }
    else {
      texts.remove(existing.label);
      existing.label = null;
    }
  }
  else if (config.label) {
    existing.label = new WebGLOverlay.Label(config.label);

    if (existing.callout) {
      existing.label.setCallout(existing.callout);
    }

    if (existing.added) {
      texts.add(existing.label);
    }
  }
};

const mapObjectKeys = ['visible', 'interactive', 'opacity', 'zIndex'] as const;
const latLngKeys = ['lat', 'lng'] as const;
const offsetKeys = ['x', 'y'] as const;

const updateLabel = (existing: WebglOverlayLabel, config: WebglOverlayLabelConfig) => {
  const directKeys = [...mapObjectKeys, ...latLngKeys, 'horizontalAnchor', 'verticalAnchor', 'autoHideWhenCollide', 'sizeOnLevel', 'staticSize'] as const;

  tryUpdateKeys(existing, directKeys, config);
  tryUpdateKeys(existing.offset, offsetKeys, config.offset);
  tryUpdateKeys(existing.position, offsetKeys, config.position);

  existing.padding.y = config.padding?.t ?? existing.padding.y;
  existing.padding.x = config.padding?.r ?? existing.padding.x;
  existing.padding.z = config.padding?.l ?? existing.padding.z;
  existing.padding.w = config.padding?.b ?? existing.padding.w;

  if (!isNullOrUndefined(config.text)) {
    existing.text.value = config.text?.value ? truncateMarkerLabelText(config.text.value) : '';
  }

  const otherTextKeys = [
    'font',
    'fontSize',
    'fillWidth',
    'fillEdge',
    'strokeWidth',
    'letterSpacing',
    'linePadding',
    'align',
  ] as const;
  tryUpdateKeys(existing.text, otherTextKeys, config.text);

  if (config.text && webglColorChanged(existing.text.fillColor, config.text.fillColor)) {
    existing.text.fillColor.set(...config.text.fillColor);
  }
  if (config.text && webglColorChanged(existing.text.strokeColor, config.text?.strokeColor)) {
    existing.text.strokeColor.set(...config.text.strokeColor);
  }

  const boundariesBaseKeys = ['enabled', 'minFontSize', 'maxFontSize'] as const;
  tryUpdateKeys(existing.boundaries, boundariesBaseKeys, config.boundaries);

  tryUpdateKeys(existing.boundaries.ne, latLngKeys, config.boundaries?.ne);
  tryUpdateKeys(existing.boundaries.sw, latLngKeys, config.boundaries?.sw);
};

const updateTextBoxCallout = (
  existing: WebglOverlayTextBoxCallout | WebglOverlayCustomCallout,
  config: WebglOverlayTextBoxCalloutConfig | WebglOverlayCustomCalloutConfig
) => {
  const directKeys = [
    ...mapObjectKeys,
    ...latLngKeys,
    'borderWidth',
    'borderRadius',
    'triangle',
    'triangleHeight',
    'triangleWidth',
    'zIndex',
    'sizeOnLevel',
    'staticSize',
    'width',
    'height',

    // TextBox Callout
    'triangleSide',

    // Custom Callout
    'placeTriangleEndpoint',
    'triangleBorderUniform',
  ] as const;

  tryUpdateKeys(existing, directKeys, config);

  if (webglColorChanged(existing.fillColor, config.fillColor)) {
    existing.fillColor.set(...config.fillColor);
  }
  if (webglColorChanged(existing.borderColor, config.borderColor)) {
    existing.borderColor.set(...config.borderColor);
  }
};

const tryUpdate = <TObject, TKey extends keyof TObject, TConfig extends { [key in TKey]?: TObject[TKey] }>(obj: TObject, key: TKey, config: TConfig) => {
  obj[key] = config[key] ?? obj[key];
};

const tryUpdateKeys = <TKeys extends readonly string[], TObject extends { [key in TKeys[number]]?: unknown }, TConfig extends { [TKey in TKeys[number]]?: TObject[TKey] }>(obj: TObject, keys: TKeys, config: TConfig | undefined) => {
  if (!config) {
    return;
  }
  Object.keys(config)
    .filter(key => keys.includes(key))
    .forEach((key: TKeys[number]) => tryUpdate(obj, key, config));
};

//#endregion
